// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var TagsMd = require("./tags.md");
var SourcesMd = require("./sources.md");
var CategoriesMd = require("./categories.md");
var EventTypesMd = require("./eventTypes.md");
var TrackEventMd = require("./trackEvent.md");
var UnidentifyMd = require("./unidentify.md");
var EventSendAsMd = require("./eventSendAs.md");
var ScreenshotsMd = require("./screenshots.md");
var CodeSnippetsMd = require("./codeSnippets.md");
var DestinationsMd = require("./destinations.md");
var IdentifyUserMd = require("./identifyUser.md");
var SentInEventsMd = require("./sentInEvents.md");
var TrackRevenueMd = require("./trackRevenue.md");
var TrackingCodeMd = require("./trackingCode.md");
var UpdateGroupsMd = require("./updateGroups.md");
var GetTheAvoFileMd = require("./getTheAvoFile.md");
var SegmentConfigMd = require("./segmentConfig.md");
var TrackPageViewMd = require("./trackPageView.md");
var ExcludeSourcesMd = require("./excludeSources.md");
var PropertySendAsMd = require("./propertySendAs.md");
var RelatedMetricsMd = require("./relatedMetrics.md");
var UserPropertiesMd = require("./userProperties.md");
var AddUserToGroupsMd = require("./addUserToGroups.md");
var EventPropertiesMd = require("./eventProperties.md");
var GroupPropertiesMd = require("./groupProperties.md");
var OptionalApiKeysMd = require("./optionalApiKeys.md");
var TrackingMethodsMd = require("./trackingMethods.md");
var EventDescriptionMd = require("./eventDescription.md");
var SystemPropertiesMd = require("./systemProperties.md");
var PropertyValueTypeMd = require("./propertyValueType.md");
var PropertyValueRulesMd = require("./propertyValueRules.md");
var AutomaticPropertiesMd = require("./automaticProperties.md");
var PropertyDescriptionMd = require("./propertyDescription.md");
var UpdateUserPropertiesMd = require("./updateUserProperties.md");

var eventProperties = EventPropertiesMd;

var userProperties = UserPropertiesMd;

var systemProperties = SystemPropertiesMd;

var sources = SourcesMd;

var destinations = DestinationsMd;

var eventTypes = EventTypesMd;

var automaticProperties = AutomaticPropertiesMd;

var excludeSources = ExcludeSourcesMd;

var segmentConfig = SegmentConfigMd;

var optionalApiKeys = OptionalApiKeysMd;

var eventDescription = EventDescriptionMd;

var screenshots = ScreenshotsMd;

var trackingMethods = TrackingMethodsMd;

var categories = CategoriesMd;

var tags = TagsMd;

var eventSendAs = EventSendAsMd;

var updateGroups = UpdateGroupsMd;

var addUserToGroups = AddUserToGroupsMd;

var groupProperties = GroupPropertiesMd;

var identifyUser = IdentifyUserMd;

var updateUserProperties = UpdateUserPropertiesMd;

var trackEvent = TrackEventMd;

var trackRevenue = TrackRevenueMd;

var trackPageView = TrackPageViewMd;

var propertyDescription = PropertyDescriptionMd;

var propertyValueType = PropertyValueTypeMd;

var sentInEvents = SentInEventsMd;

var propertyValueRules = PropertyValueRulesMd;

var propertySendAs = PropertySendAsMd;

var relatedMetrics = RelatedMetricsMd;

var unidentify = UnidentifyMd;

var trackingCode = TrackingCodeMd;

var codeSnippets = CodeSnippetsMd;

var getTheAvoFile = GetTheAvoFileMd;

exports.eventProperties = eventProperties;
exports.userProperties = userProperties;
exports.systemProperties = systemProperties;
exports.sources = sources;
exports.destinations = destinations;
exports.eventTypes = eventTypes;
exports.automaticProperties = automaticProperties;
exports.excludeSources = excludeSources;
exports.segmentConfig = segmentConfig;
exports.optionalApiKeys = optionalApiKeys;
exports.eventDescription = eventDescription;
exports.screenshots = screenshots;
exports.trackingMethods = trackingMethods;
exports.categories = categories;
exports.tags = tags;
exports.eventSendAs = eventSendAs;
exports.updateGroups = updateGroups;
exports.addUserToGroups = addUserToGroups;
exports.groupProperties = groupProperties;
exports.identifyUser = identifyUser;
exports.updateUserProperties = updateUserProperties;
exports.trackEvent = trackEvent;
exports.trackRevenue = trackRevenue;
exports.trackPageView = trackPageView;
exports.propertyDescription = propertyDescription;
exports.propertyValueType = propertyValueType;
exports.sentInEvents = sentInEvents;
exports.propertyValueRules = propertyValueRules;
exports.propertySendAs = propertySendAs;
exports.relatedMetrics = relatedMetrics;
exports.unidentify = unidentify;
exports.trackingCode = trackingCode;
exports.codeSnippets = codeSnippets;
exports.getTheAvoFile = getTheAvoFile;
/* eventProperties Not a pure module */
