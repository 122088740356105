// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");

function makeOption(param) {
  if (clearbit == null) {
    return ;
  } else {
    return Caml_option.some(clearbit);
  }
}

exports.makeOption = makeOption;
/* No side effect */
