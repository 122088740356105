// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Case = require("../../bs-case/src/case.bs.js");
var $$Text = require("./Text.bs.js");
var Case$1 = require("case");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var JsDiff = require("./externals/JsDiff.bs.js");
var Router = require("./Router.bs.js");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ContextMenu = require("./ContextMenu.bs.js");
var DiffComments = require("./DiffComments.bs.js");
var DiffEventTags = require("./DiffEventTags.bs.js");
var DiffEventGroups = require("./DiffEventGroups.bs.js");
var DiffEventActions = require("./DiffEventActions.bs.js");
var DiffEventMetrics = require("./DiffEventMetrics.bs.js");
var DiffEventSources = require("./DiffEventSources.bs.js");
var DiffEventTriggers = require("./DiffEventTriggers.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var DiffEventProperties = require("./DiffEventProperties.bs.js");
var DiffItemAuditIssues = require("./DiffItemAuditIssues.bs.js");
var DiffEventDescription = require("./DiffEventDescription.bs.js");
var DiffEventNameMapping = require("./DiffEventNameMapping.bs.js");

var maxWidthPx = Css.px(736);

function unarchiveEvent($$event, branchModel, sendActions, branchId, schemaGroup) {
  var archivedName = $$event.name;
  var conflictingEvents = Belt_List.keepMapU(branchModel.events, (function ($$event) {
          if (archivedName === $$event.name || archivedName.toLowerCase() === $$event.name.toLowerCase() || Case.camel(archivedName) === Case.camel($$event.name) || Case$1.snake(archivedName) === Case$1.snake($$event.name)) {
            return $$event.name;
          }
          
        }));
  if (conflictingEvents) {
    if (conflictingEvents.tl) {
      window.alert("Can't unarchive event because events with conflicting names already exist: " + Belt_List.toArray(conflictingEvents).join(", "));
    } else {
      window.alert("Can't unarchive event because an event with a conflicting name already exists: " + conflictingEvents.hd);
    }
  } else {
    Curry.app(sendActions, [
          undefined,
          false,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (_branch) {
              Router.Schema.pushDrawerItem({
                    NAME: "branch",
                    VAL: branchId
                  }, {
                    NAME: "event",
                    VAL: [
                      $$event.id,
                      undefined,
                      undefined,
                      false
                    ]
                  });
              return AnalyticsRe.itemUnarchived(schemaGroup, $$event.id, $$event.name, "Event", "BranchReview", schemaGroup.schemaId, schemaGroup.branchId);
            }),
          undefined,
          [[
              {
                NAME: "Unarchive",
                VAL: {
                  NAME: "Event",
                  VAL: $$event.id
                }
              },
              {
                eventId: $$event.id,
                eventQuery: $$event.id
              }
            ]]
        ]);
  }
  
}

function DiffEvent(Props) {
  var branchStatus = Props.branchStatus;
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var goToCommentId = Props.goToCommentId;
  var events = Props.events;
  var withComments = Props.withComments;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = Belt_Option.map(Belt_List.head(actions), (function (prim) {
          return prim[0];
        }));
  if (match === undefined) {
    return null;
  }
  if (typeof match !== "object") {
    return null;
  }
  if (match.NAME !== "Event") {
    return null;
  }
  var eventId = match.VAL;
  var actions$1 = Belt_List.map(actions, (function (prim) {
          return prim[1];
        }));
  var hasCreate = Belt_List.getBy(actions$1, (function (action) {
          if (typeof action === "object") {
            return action.NAME === "AddEvent";
          } else {
            return false;
          }
        }));
  var hasArchive = Belt_List.getBy(actions$1, (function (action) {
          if (typeof action !== "object") {
            return false;
          }
          if (action.NAME !== "Archive") {
            return false;
          }
          var match = action.VAL;
          if (typeof match === "object") {
            return match.NAME === "Event";
          } else {
            return false;
          }
        }));
  var changeType = hasCreate !== undefined ? "Create" : (
      hasArchive !== undefined ? "Archive" : "Update"
    );
  var $$event = Belt_Option.getWithDefault(Belt_List.getBy(masterModel.events, (function ($$event) {
              return $$event.id === eventId;
            })), TrackingPlanModel.emptyEvent(eventId, ""));
  var nextEvent = Belt_Option.getWithDefault(Belt_List.getBy(branchModel.events, (function ($$event) {
              return $$event.id === eventId;
            })), TrackingPlanModel.emptyEvent(eventId, ""));
  var tmp;
  if (withComments) {
    var tmp$1 = {
      schemaId: schemaId,
      branchId: branchId,
      threadId: $$event.id,
      itemType: "Event",
      itemName: $$event.name,
      events: events
    };
    if (goToCommentId !== undefined) {
      tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp = React.createElement(DiffComments.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: /* [] */0
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.alignSelf("flexStart"),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: {
                            hd: Css.maxWidth(maxWidthPx),
                            tl: {
                              hd: Css.cursor("pointer"),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.backgroundColor(Styles.Color.grey20),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }),
                  onClick: (function (param) {
                      return Router.Schema.pushDrawerItem(undefined, {
                                  NAME: "event",
                                  VAL: [
                                    $$event.id,
                                    undefined,
                                    undefined,
                                    false
                                  ]
                                });
                    })
                }, changeType === "Update" || changeType === "Create" ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("relative"),
                              tl: {
                                hd: Css.width(Css.pct(100)),
                                tl: {
                                  hd: Css.borderRadius(Styles.Border.radius),
                                  tl: changeType === "Create" ? ({
                                        hd: Css.border(Css.px(1), "solid", Styles.Color.mintGreen),
                                        tl: /* [] */0
                                      }) : (
                                      changeType === "Update" ? ({
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                            tl: /* [] */0
                                          }) : ({
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.grapeError),
                                            tl: /* [] */0
                                          })
                                    )
                                }
                              }
                            })
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.borderTopLeftRadius(Styles.Border.radius),
                                  tl: {
                                    hd: Css.borderTopRightRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.regular),
                                        tl: {
                                          hd: Css.padding(Css.px(20)),
                                          tl: {
                                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement(DiffItemAuditIssues.make, {
                                branchStatus: branchStatus,
                                itemId: $$event.id,
                                children: JsDiff.renderStringDiff(undefined, true, undefined, $$event.name, nextEvent.name)
                              })), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(10), Css.px(20)),
                                  tl: /* [] */0
                                })
                          }, React.createElement(DiffEventDescription.make, {
                                event: $$event,
                                actions: actions$1
                              }), React.createElement(DiffEventTriggers.make, {
                                branchModel: branchModel,
                                masterModel: masterModel,
                                event: $$event,
                                nextEvent: nextEvent,
                                actions: actions$1
                              }), React.createElement(DiffEventNameMapping.make, {
                                actions: actions$1,
                                branchModel: branchModel,
                                masterModel: masterModel
                              }), React.createElement(DiffEventGroups.make, {
                                branchModel: branchModel,
                                actions: actions$1
                              }), React.createElement(DiffEventActions.make, {
                                actions: actions$1
                              }), React.createElement(DiffEventTags.make, {
                                event: $$event,
                                actions: actions$1
                              }), React.createElement(DiffEventSources.make, {
                                branchModel: branchModel,
                                masterModel: masterModel,
                                actions: actions$1
                              }), React.createElement(DiffEventMetrics.make, {
                                branchModel: branchModel,
                                masterModel: masterModel,
                                actions: actions$1
                              }), React.createElement(DiffEventProperties.make, {
                                branchModel: branchModel,
                                masterModel: masterModel,
                                nextEvent: nextEvent,
                                actions: actions$1
                              }))) : React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("spaceBetween"),
                                  tl: {
                                    hd: Css.width(Css.pct(100)),
                                    tl: {
                                      hd: Css.borderRadius(Styles.Border.radius),
                                      tl: {
                                        hd: Css.border(Css.px(1), "solid", Styles.Color.grapeError),
                                        tl: {
                                          hd: Css.padding(Css.px(20)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            element: "Div",
                            size: "Medium",
                            weight: "Semi",
                            children: JsDiff.renderStringDiff(undefined, true, undefined, $$event.name, nextEvent.name)
                          }), React.createElement(ContextMenu.make, {
                            options: [{
                                NAME: "Option",
                                VAL: {
                                  label: "Restore Event",
                                  onClick: (function (param) {
                                      return unarchiveEvent($$event, branchModel, sendActions, branchId, schemaGroup);
                                    })
                                }
                              }]
                          }))), tmp);
}

var make = DiffEvent;

exports.maxWidthPx = maxWidthPx;
exports.unarchiveEvent = unarchiveEvent;
exports.make = make;
/* maxWidthPx Not a pure module */
