// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Sentry = require("./externals/Sentry.bs.js");
var Styles = require("./styles.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var TextButton = require("./TextButton.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var DiffViewUtils = require("./DiffViewUtils.bs.js");

function PullMasterWarning(Props) {
  var schemaId = Props.schemaId;
  var masterModel = Props.masterModel;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var masterSinceOpenedDiff = Props.masterSinceOpenedDiff;
  var latestMasterAction = Props.latestMasterAction;
  var branchId = Props.branchId;
  var branchStatus = Props.branchStatus;
  var role = Props.role;
  var events = Props.events;
  var match = React.useState(function () {
        return false;
      });
  var setShowDiff = match[1];
  var showDiff = match[0];
  var match$1 = React.useState(function () {
        return "Ready";
      });
  var setStatus = match$1[1];
  var status = match$1[0];
  var match$2 = Belt_List.length(masterSinceOpenedDiff) > 0;
  if (!match$2) {
    return null;
  }
  if (latestMasterAction === undefined) {
    return null;
  }
  var renderPullButton = function (param) {
    return React.createElement("button", {
                className: Curry._1(Css.merge, {
                      hd: Curry._1(Css.style, Styles.button),
                      tl: {
                        hd: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.padding2(Css.px(10), Css.px(20)),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.orange),
                                    tl: {
                                      hd: Css.color(Styles.Color.white),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.regular),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: {
                                            hd: Css.borderRadius(Styles.Border.radius),
                                            tl: {
                                              hd: Css.transition({
                                                    NAME: "ms",
                                                    VAL: 250.0
                                                  }, undefined, undefined, "all"),
                                              tl: {
                                                hd: Css.hover({
                                                      hd: Css.backgroundColor(Styles.Color.orangeSecondary),
                                                      tl: /* [] */0
                                                    }),
                                                tl: {
                                                  hd: Css.disabled({
                                                        hd: Css.opacity(0.4),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }),
                        tl: /* [] */0
                      }
                    }),
                disabled: status === "Loading" || !Models.Role.canEdit(role),
                onClick: (function (param) {
                    Curry._1(setStatus, (function (param) {
                            return "Loading";
                          }));
                    $$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                                  return fetch(Firebase.apiUrl + "/c/v1/master/pull", Fetch.RequestInit.make(/* Post */2, [
                                                    [
                                                      "Accept",
                                                      "application/json"
                                                    ],
                                                    [
                                                      "Content-Type",
                                                      "application/json"
                                                    ],
                                                    [
                                                      "Authorization",
                                                      "Bearer " + token
                                                    ]
                                                  ], Caml_option.some(JSON.stringify(Js_dict.fromArray([
                                                                [
                                                                  "schemaId",
                                                                  schemaId
                                                                ],
                                                                [
                                                                  "branchId",
                                                                  branchId
                                                                ],
                                                                [
                                                                  "force",
                                                                  true
                                                                ]
                                                              ]))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                                }).then(function (prim) {
                                return prim.json();
                              }).then(function (_response) {
                              Curry._1(setStatus, (function (param) {
                                      return "Ready";
                                    }));
                              return Promise.resolve(undefined);
                            }), (function (error) {
                            console.log("auth error", error);
                            window.alert("Something went wrong when pulling from main, please try again.");
                            Sentry.captureException(error, {});
                            Curry._1(setStatus, (function (param) {
                                    return "Ready";
                                  }));
                            return Promise.resolve(undefined);
                          }));
                    
                  })
              }, status === "Loading" ? "Pulling Main..." : "Pull Main Branch");
  };
  var tmp;
  if (showDiff) {
    var masterActions = AvoUtils.groupActionsByObjectId(AvoUtils.actionByObject(masterSinceOpenedDiff));
    tmp = React.createElement("div", undefined, React.createElement(DiffViewUtils.RenderObjectsDiff.make, {
              branchStatus: branchStatus,
              fromModel: masterModelBeforeBranch,
              toModel: masterModel,
              actions: masterActions,
              schemaId: schemaId,
              branchId: branchId,
              withComments: false,
              events: events
            }), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.justifyContent("flexEnd"),
                      tl: {
                        hd: Css.marginTop(Css.px(15)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, renderPullButton(undefined)));
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(20)),
                    tl: {
                      hd: Css.width(Css.pct(100)),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.border(Css.px(1), Css.solid, Styles.Color.orange),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.grey10),
                            tl: {
                              hd: Css.margin2(Css.px(15), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("spaceBetween"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontSize(Styles.FontSize.regular),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: /* [] */0
                                }
                              })
                        }, "This branch is not up to date with the main branch."), Models.Role.canEdit(role) ? React.createElement(TextButton.make, {
                            onClick: (function (param) {
                                return Curry._1(setShowDiff, (function (showDiff) {
                                              return !showDiff;
                                            }));
                              }),
                            style: "Orange",
                            size: "Small",
                            children: showDiff ? "Hide Main Changes" : "Show Main Changes"
                          }) : React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.orange,
                            children: "Ask someone with editor or admin access to pull the main branch"
                          })), renderPullButton(undefined)), React.createElement("div", undefined, tmp));
}

var make = PullMasterWarning;

exports.make = make;
/* Css Not a pure module */
