// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

function trimTrailingNewlines(str) {
  return str.replace(/\n+$/, "");
}

function quillToPlainText(quill) {
  try {
    var quillOperations = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Js_json.decodeObject(JSON.parse(quill)), (function (item) {
                    return Js_dict.get(item, "ops");
                  })), Js_json.decodeArray), []);
    return trimTrailingNewlines(Belt_Array.reduce(quillOperations, "", (function (description, descriptionQuillOperationJson) {
                      var descriptionQuillOperation = Belt_Option.getWithDefault(Js_json.decodeObject(descriptionQuillOperationJson), {});
                      var insertText = Belt_Option.flatMapU(Js_dict.get(descriptionQuillOperation, "insert"), Js_json.decodeString);
                      if (insertText !== undefined) {
                        return description + insertText;
                      } else {
                        return description;
                      }
                    })));
  }
  catch (_error){
    console.error("Quill parsing error", quill);
    return quill;
  }
}

exports.trimTrailingNewlines = trimTrailingNewlines;
exports.quillToPlainText = quillToPlainText;
/* No side effect */
