// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Router = require("../Router.bs.js");
var Styles = require("../styles.bs.js");
var Actions = require("../actions.bs.js");
var IconAvo = require("../IconAvo.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Sidebar = require("../Sidebar.bs.js");
var Firebase = require("../../../bs-firestore/src/Firebase.bs.js");
var Redirect = require("../Redirect.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var PromiseExt = require("../externals/PromiseExt.bs.js");
var TextButton = require("../TextButton.bs.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var NamedBranch = require("../NamedBranch.bs.js");
var RouterStore = require("../RouterStore.bs.js");
var ImportAction = require("./ImportAction.bs.js");
var ImportReview = require("./ImportReview.bs.js");
var ImportLanding = require("./ImportLanding.bs.js");
var FramerMotion = require("framer-motion");
var AnalyticsUtils = require("../analyticsUtils.bs.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var NewBranchModal = require("../NewBranchModal.bs.js");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");
var SendActionsContext = require("../SendActionsContext.bs.js");
var FirebaseFetcherHooks = require("../FirebaseFetcherHooks.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(-Sidebar.sidebarWidth | 0)),
      tl: /* [] */0
    });

var logoStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("fixed"),
              tl: {
                hd: Css.left(Css.px(22)),
                tl: {
                  hd: Css.top(Css.px(26)),
                  tl: {
                    hd: Css.zIndex(2),
                    tl: /* [] */0
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var closeStyles = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.right(Css.px(22)),
        tl: {
          hd: Css.top(Css.px(20)),
          tl: {
            hd: Css.zIndex(2),
            tl: /* [] */0
          }
        }
      }
    });

var contentStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

function lastActionOnBranchQuery(schemaId, branchId) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions").where("branchId", "==", branchId).orderBy("createdAt", "desc").limit(1);
}

function Import(Props) {
  var schemaId = Props.schemaId;
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var model = Props.model;
  var viewerRole = Props.viewerRole;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useState(function () {
        
      });
  var setParsedTrackingPlan = match[1];
  var parsedTrackingPlan = match[0];
  React.useEffect((function () {
          AnalyticsRe.importStarted(schemaGroup, Belt_List.length(model.events), Belt_List.length(model.properties), schemaGroup.branchId, schemaGroup.schemaId);
          
        }), []);
  var onUploadSuccess = React.useCallback((function (parsedTrackingPlan) {
          Curry._1(setParsedTrackingPlan, (function (param) {
                  return parsedTrackingPlan;
                }));
          return Router.Schema.pushSchemaRoute(undefined, undefined, {
                      NAME: "import",
                      VAL: "review"
                    });
        }), []);
  var onReviewAccepted = function (selectedBranch, parsedTrackingPlan) {
    var newAndUpdatedProperties = Belt_Array.concat(parsedTrackingPlan.newProperties, parsedTrackingPlan.updatedProperties);
    var createBranchAsPromise = function (branchName, sendActions, schemaGroup, schemaId) {
      return new Promise((function (resolve, reject) {
                    return NewBranchModal.createBranch(branchName, "Import", sendActions, schemaGroup, schemaId, (function (error) {
                                  return reject(error);
                                }), (function (branchId) {
                                  return resolve(branchId);
                                }), undefined);
                  }));
    };
    $$Promise.$$catch((
                  typeof selectedBranch === "object" ? (
                      selectedBranch.NAME === "Create" ? createBranchAsPromise(selectedBranch.VAL, sendActions, schemaGroup, schemaId).then(function (branchId) {
                              return Promise.resolve({
                                          NAME: "Branch",
                                          VAL: branchId
                                        });
                            }) : Promise.resolve({
                              NAME: "Branch",
                              VAL: selectedBranch.VAL.id
                            })
                    ) : Promise.resolve("Master")
                ).then(function (writeToBranch) {
                    return new Promise((function (resolve, reject) {
                                  return Curry.app(sendActions, [
                                              writeToBranch,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              (function (branch) {
                                                  return resolve(branch);
                                                }),
                                              (function (error) {
                                                  return reject(error);
                                                }),
                                              [[
                                                  "StartedImport",
                                                  {}
                                                ]]
                                            ]);
                                }));
                  }).then(function (branch) {
                  return lastActionOnBranchQuery(schemaId, NamedBranch.getId(branch)).get().then(function (snapshot) {
                              var lastAction = Belt_Option.mapU(Belt_Array.get(snapshot.docs, 0), (function (doc) {
                                      return FirebaseFetcherHooks.transformAction(doc.data());
                                    }));
                              return [
                                      branch,
                                      lastAction
                                    ];
                            });
                }).then(function (param) {
                var branch = param[0];
                var importActions = Belt_List.toArray(ImportAction.buildActions(parsedTrackingPlan, model));
                var modelWithImport = Belt_Array.reduceU(importActions, model, Actions.reduce);
                return Actions.snapshot(schemaId, NamedBranch.getId(branch), modelWithImport, Belt_Option.getExn(param[1])).then(function (param) {
                            var eventIds = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.map(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
                                            return $$event.id;
                                          }))));
                            var propertyIds = Belt_Array.map(newAndUpdatedProperties, (function (property) {
                                    return property.id;
                                  }));
                            var sourceIds = parsedTrackingPlan.sources;
                            var propertyGroupIds = Belt_Array.map(parsedTrackingPlan.propertyGroups, (function (propertyGroup) {
                                    return propertyGroup.id;
                                  }));
                            return new Promise((function (resolve, reject) {
                                          return Curry.app(sendActions, [
                                                      NamedBranch.toUnnamed(branch),
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      (function (_branch) {
                                                          return resolve(branch);
                                                        }),
                                                      (function (error) {
                                                          return reject(error);
                                                        }),
                                                      [[
                                                          {
                                                            NAME: "CompletedImport",
                                                            VAL: [
                                                              eventIds.length,
                                                              propertyIds.length,
                                                              Belt_Array.keepU(importActions, (function (importAction) {
                                                                      if (typeof importAction === "object") {
                                                                        return importAction.NAME === "CreateSource";
                                                                      } else {
                                                                        return false;
                                                                      }
                                                                    })).length
                                                            ]
                                                          },
                                                          {
                                                            eventIds: eventIds,
                                                            eventsQuery: eventIds,
                                                            propertyIds: propertyIds,
                                                            propertiesQuery: propertyIds,
                                                            sourceIds: sourceIds,
                                                            sourcesQuery: sourceIds,
                                                            propertyGroupIds: propertyGroupIds,
                                                            propertyGroupsQuery: propertyGroupIds
                                                          }
                                                        ]]
                                                    ]);
                                        }));
                          });
              }).then(function (branch) {
              Router.Schema.pushSchemaRoute(branch ? ({
                        NAME: "branch",
                        VAL: branch._0
                      }) : "master", undefined, {
                    NAME: "import",
                    VAL: "success"
                  });
              window.location.reload(false);
              AnalyticsRe.importCompleted(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), Belt_List.length(model.events) + Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents).length | 0, Belt_List.length(model.properties) + newAndUpdatedProperties.length | 0, newAndUpdatedProperties.length, Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents).length, parsedTrackingPlan.newEvents.length, parsedTrackingPlan.updatedEvents.length, NamedBranch.getId(branch), schemaGroup.schemaId);
              return Promise.resolve(undefined);
            }), (function (error) {
            console.error(error);
            return Promise.resolve((window.alert("Something went wrong when importing the tracking plan.\n" + PromiseExt.getErrorMessage(error)), undefined));
          }));
    
  };
  var handleClose = function (param) {
    if (schemaRoute !== ({
          NAME: "import",
          VAL: "review"
        }) || window.confirm("You have an unfinished import draft. Are you sure you want to discard it?")) {
      return Router.Schema.pushSchemaRoute(undefined, undefined, "dashboard");
    }
    
  };
  var hasEventsOnModel = React.useMemo((function () {
          return model.events !== /* [] */0;
        }), []);
  var tmp;
  var exit = 0;
  if (typeof schemaRoute === "object" && schemaRoute.NAME === "import") {
    var importSubRoute = schemaRoute.VAL;
    if (importSubRoute === "success") {
      tmp = React.createElement(Redirect.make, {
            path: Router.Link.getSchemaRouteLink(undefined, "events")
          });
    } else if (importSubRoute === "index") {
      tmp = React.createElement(ImportLanding.make, {
            model: model,
            onUploadSuccess: onUploadSuccess,
            schemaId: schemaId,
            branchId: Actions.branchToId(currentBranch)
          });
    } else if (parsedTrackingPlan !== undefined) {
      var importActions = Belt_List.toArray(ImportAction.buildActions(parsedTrackingPlan, model));
      tmp = React.createElement(ImportReview.make, {
            parsedTrackingPlan: parsedTrackingPlan,
            onContinue: onReviewAccepted,
            hasEventsOnModel: hasEventsOnModel,
            currentBranch: currentBranch,
            openBranches: openBranches,
            schemaId: schemaId,
            branchId: Actions.branchToId(currentBranch),
            actionsCount: importActions.length,
            model: model,
            viewerRole: viewerRole
          });
    } else {
      tmp = React.createElement(Redirect.make, {
            path: Router.Link.getSchemaRouteLink(undefined, {
                  NAME: "import",
                  VAL: "index"
                })
          });
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Redirect.make, {
          path: Router.Link.getSchemaRouteLink(undefined, "dashboard")
        });
  }
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("button", {
                  className: logoStyles,
                  onClick: handleClose
                }, React.createElement(IconAvo.make, {
                      size: 60
                    })), React.createElement("div", {
                  className: closeStyles
                }, React.createElement(TextButton.make, {
                      onClick: handleClose,
                      size: "Small",
                      children: "Close"
                    })), React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  children: React.createElement(FramerMotion.motion.div, {
                        animate: {
                          opacity: 1,
                          x: 0
                        },
                        transition: {
                          duration: 0.3
                        },
                        initial: {
                          opacity: 0,
                          x: 100
                        },
                        exit: {
                          opacity: 0,
                          x: -100
                        },
                        className: contentStyles,
                        key: schemaRoute === ({
                            NAME: "import",
                            VAL: "index"
                          }) ? "index" : "review",
                        children: tmp
                      })
                }));
}

var make = Import;

exports.rootStyles = rootStyles;
exports.logoStyles = logoStyles;
exports.closeStyles = closeStyles;
exports.contentStyles = contentStyles;
exports.lastActionOnBranchQuery = lastActionOnBranchQuery;
exports.make = make;
/* rootStyles Not a pure module */
