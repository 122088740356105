// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("./Icons.bs.js");
var Modal = require("./Modal.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Shortid = require("shortid");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var DocsLink = require("./DocsLink.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var TextInput = require("./TextInput.bs.js");
var Workspace = require("../../model/src/Workspace.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var PlanLightning = require("./PlanLightning.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var ListLimitsInfo = require("./ListLimitsInfo.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");

var content = Curry._1(Css.style, {
      hd: Css.minWidth(Css.px(400)),
      tl: {
        hd: Css.paddingTop(Css.px(20)),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.paddingBottom(Css.px(16)),
            tl: {
              hd: Css.paddingLeft(Css.px(16)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var label = Curry._1(Css.style, {
      hd: Css.fontSize(Css.px(11)),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.lineHeight(Css.pct(140.0)),
          tl: {
            hd: Css.color(Styles.Color.grey70),
            tl: {
              hd: Css.marginBottom(Css.px(2)),
              tl: {
                hd: Css.display("block"),
                tl: {
                  hd: Css.paddingLeft(Css.px(8)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var platforms = Curry._1(Css.style, {
      hd: Css.gridTemplateColumns({
            hd: {
              NAME: "repeat",
              VAL: [
                "autoFill",
                {
                  NAME: "minmax",
                  VAL: [
                    Css.px(64),
                    Css.fr(1.0)
                  ]
                }
              ]
            },
            tl: /* [] */0
          }),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.gridGap(Css.px(1)),
          tl: {
            hd: Css.columnGap(Css.px(8)),
            tl: {
              hd: Css.marginBottom(Css.px(8)),
              tl: {
                hd: Css.maxWidth(Css.px(440)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function platformButton(selected) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.width(Css.px(64)),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.borderRadius(Css.px(12)),
                        tl: {
                          hd: Css.backgroundColor(selected ? Styles.Color.white : "transparent"),
                          tl: {
                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(10), Css.px(20), undefined, undefined, selected ? Css.rgba(0, 0, 0, {
                                            NAME: "num",
                                            VAL: 0.05
                                          }) : "transparent")),
                            tl: {
                              hd: Css.margin(Css.px(0)),
                              tl: {
                                hd: Css.paddingTop(Css.px(12)),
                                tl: {
                                  hd: Css.paddingBottom(Css.px(12)),
                                  tl: {
                                    hd: Css.cursor("pointer"),
                                    tl: {
                                      hd: Css.color(selected ? Styles.Color.grey90 : Styles.Color.grey70),
                                      tl: {
                                        hd: Css.transition({
                                              NAME: "ms",
                                              VAL: Styles.Duration.$$default
                                            }, undefined, undefined, "all"),
                                        tl: {
                                          hd: Css.focus({
                                                hd: Css.outlineStyle("none"),
                                                tl: {
                                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box("zero", "zero", Css.px(0), Css.px(2), true, Styles.Color.blueSecondary)),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: {
                                            hd: Css.hover(selected ? ({
                                                      hd: Css.focus({
                                                            hd: Css.boxShadows({
                                                                  hd: Css_Legacy_Core.Shadow.box("zero", Css.px(4), Css.px(16), undefined, undefined, {
                                                                        NAME: "rgba",
                                                                        VAL: [
                                                                          0,
                                                                          0,
                                                                          0,
                                                                          {
                                                                            NAME: "num",
                                                                            VAL: 0.05
                                                                          }
                                                                        ]
                                                                      }),
                                                                  tl: {
                                                                    hd: Css_Legacy_Core.Shadow.box("zero", "zero", Css.px(0), Css.px(2), true, Styles.Color.blueSecondary),
                                                                    tl: /* [] */0
                                                                  }
                                                                }),
                                                            tl: /* [] */0
                                                          }),
                                                      tl: /* [] */0
                                                    }) : ({
                                                      hd: Css.color(Styles.Color.grey90),
                                                      tl: {
                                                        hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.5)),
                                                        tl: /* [] */0
                                                      }
                                                    })),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var footer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("flexEnd"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey30),
                tl: {
                  hd: Css.padding(Css.px(12)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var ModuleStyles = {
  content: content,
  label: label,
  platforms: platforms,
  platformButton: platformButton,
  footer: footer
};

function SettingsDestinations$CreateDestinationModal(Props) {
  var onClose = Props.onClose;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useState(function () {
        return "";
      });
  var setName = match[1];
  var name = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setSelectedDestination = match$1[1];
  var selectedDestination = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setNameEdited = match$2[1];
  var nameEdited = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setLoading = match$3[1];
  var isLoading = match$3[0];
  var handleCreate = function (param) {
    if (selectedDestination === undefined) {
      return ;
    }
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    var destinationId = Shortid();
    var context = {
      destinationId: destinationId,
      destinationQuery: destinationId
    };
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    Curry._1(setLoading, (function (param) {
                            return false;
                          }));
                    Curry._1(onClose, undefined);
                    Router.Schema.pushDrawerItem(undefined, {
                          NAME: "destination",
                          VAL: destinationId
                        });
                    return AnalyticsRe.dataDestinationUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "DestinationCreated", name, undefined, true, "None", undefined, "None", undefined, undefined, false, "None", undefined, false, undefined, NamedBranch.getId(branch), schemaGroup.schemaId);
                  }),
                undefined,
                [
                  [
                    {
                      NAME: "CreateDestination",
                      VAL: destinationId
                    },
                    context
                  ],
                  [
                    {
                      NAME: "UpdateDestinationName",
                      VAL: [
                        destinationId,
                        name
                      ]
                    },
                    context
                  ],
                  [
                    {
                      NAME: "UpdateDestinationAnalyticsTool",
                      VAL: [
                        destinationId,
                        selectedDestination
                      ]
                    },
                    context
                  ]
                ]
              ]);
  };
  return React.createElement(Modal.make, {
              title: "New Destination",
              onClose: onClose,
              footer: React.createElement("footer", {
                    className: footer
                  }, React.createElement(Button.make, {
                        disabled: isLoading,
                        label: "Cancel",
                        onClick: (function (param) {
                            return Curry._1(onClose, undefined);
                          }),
                        style: "ghost"
                      }), React.createElement(Spacer.make, {
                        width: 8
                      }), React.createElement(Button.make, {
                        disabled: Belt_Option.isNone(selectedDestination) || name === "" || isLoading,
                        label: "Create Destination",
                        loading: isLoading,
                        onClick: handleCreate
                      })),
              children: React.createElement("div", {
                    className: content
                  }, Belt_Array.map(AvoConfig.groupedAnalyticsTools, (function (param) {
                          return React.createElement(React.Fragment, undefined, React.createElement("div", {
                                          className: label
                                        }, param[0]), React.createElement("div", {
                                          className: platforms
                                        }, Belt_Array.map(param[1], (function (destination) {
                                                var selected = selectedDestination !== undefined ? destination === selectedDestination : false;
                                                return React.createElement("button", {
                                                            key: AvoConfig.analyticsToolsLabel(destination),
                                                            className: platformButton(selected),
                                                            onClick: (function (param) {
                                                                Curry._1(setSelectedDestination, (function (param) {
                                                                        return destination;
                                                                      }));
                                                                if (!nameEdited) {
                                                                  return Curry._1(setName, (function (param) {
                                                                                return AvoConfig.analyticsToolsLabel(destination);
                                                                              }));
                                                                }
                                                                
                                                              })
                                                          }, React.createElement(Icons.Destinations.make, {
                                                                destination: destination,
                                                                size: 40
                                                              }), React.createElement(Spacer.make, {
                                                                height: 8
                                                              }), React.createElement($$Text.make, {
                                                                size: "Tiny",
                                                                weight: "Semi",
                                                                lineHeight: Css.pct(100.0),
                                                                children: AvoConfig.analyticsToolsLabel(destination)
                                                              }));
                                              }))));
                        })), React.createElement("label", {
                        className: label,
                        htmlFor: "name"
                      }, "Name"), React.createElement(TextInput.make, {
                        id: "name",
                        onChange: (function (value) {
                            Curry._1(setNameEdited, (function (param) {
                                    return value !== "";
                                  }));
                            return Curry._1(setName, (function (param) {
                                          return value;
                                        }));
                          }),
                        stretch: true,
                        value: name
                      }))
            });
}

var CreateDestinationModal = {
  ModuleStyles: ModuleStyles,
  make: SettingsDestinations$CreateDestinationModal
};

function onAddDestination(globalSend) {
  return Curry._1(globalSend, {
              TAG: /* OpenModal */4,
              _0: "CreateDestination"
            });
}

function SettingsDestinations(Props) {
  var model = Props.model;
  var onHover = Props.onHover;
  var onLeave = Props.onLeave;
  var globalSend = Props.globalSend;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var workspaceLimits = AvoLimits.computeLimits(workspace, model);
  var availability = AvoLimits.Destination.createActionStatus(workspaceLimits);
  var handleAddDestination = function (param) {
    if (availability === "AvailableDuringTrial" || availability === "Available") {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: "CreateDestination"
                });
    } else {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "BillingPrompt",
                    VAL: "AddDestinations"
                  }
                });
    }
  };
  var destinations = model.destinations;
  var match = Workspace.Destination.getUsedSlots(workspace, model);
  var match$1 = workspace.trial;
  var match$2 = match[1];
  var usedCount = match[0];
  var tmp;
  if (typeof match$2 === "object" && match$2.NAME === "Limited") {
    var availableCount = match$2.VAL;
    var exit = 0;
    if (match$1 !== undefined) {
      if (usedCount > availableCount) {
        tmp = React.createElement(ListLimitsInfo.make, {
              title: "You are using " + String(usedCount) + " destinations with a limit of " + String(availableCount) + " on the " + Workspace.printPlanName(workspace.plan.name) + " plan.",
              description: "To continue using all your destinations after the trial expires, you'll need to upgrade your plan.",
              globalSend: globalSend,
              paywallName: "AddDestinations"
            });
      } else {
        exit = 1;
      }
    } else if (usedCount > availableCount) {
      tmp = React.createElement(ListLimitsInfo.make, {
            title: "You are using " + String(usedCount) + " destinations with a limit of " + String(availableCount) + " on the " + Workspace.printPlanName(workspace.plan.name) + " plan.",
            description: "A plan upgrade is needed to keep using all your destinations.",
            globalSend: globalSend,
            paywallName: "AddDestinations"
          });
    } else if (usedCount === availableCount) {
      tmp = React.createElement(ListLimitsInfo.make, {
            title: "You are using " + String(usedCount) + " out of " + String(availableCount) + " destinations on the " + Workspace.printPlanName(workspace.plan.name) + " plan.",
            description: "To add more destinations, you'll need to upgrade your plan.",
            globalSend: globalSend,
            paywallName: "AddDestinations"
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = usedCount > 0 ? React.createElement(ListLimitsInfo.make, {
              title: "You are using " + String(usedCount) + " out of " + String(availableCount) + " destinations on the " + Workspace.printPlanName(workspace.plan.name) + " plan.",
              globalSend: globalSend,
              paywallName: "AddDestinations"
            }) : null;
    }
    
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.overflow("hidden"),
                          tl: {
                            hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                            tl: {
                              hd: Css.minWidth(Css.px(350)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("spaceBetween"),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: {
                                hd: Css.padding2(Css.px(10), Css.px(15)),
                                tl: {
                                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Large",
                      weight: "Semi",
                      children: "Destinations"
                    }), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement(DocsLink.make, {
                      path: "workspace/connections#destinations"
                    }), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), React.createElement(Button.make, {
                      icon: availability === "Available" ? "plus" : "lightning",
                      label: "New Destination",
                      onClick: handleAddDestination
                    })), React.createElement("div", undefined, destinations ? Belt_List.toArray(Belt_List.mapWithIndex(destinations, (function (index, destination) {
                              var destinationType = destination.type_;
                              var match = AvoLimits.Destination.entityStatus(workspaceLimits, destination.id);
                              var destinationAvailableOnPlan = match[1];
                              var destinationAvailability = match[0];
                              var disabled = destinationAvailability === "Unavailable";
                              var sources = AvoConfig.sourcesByDestination(model, destination.id);
                              var validSources = destinationType !== undefined ? AvoUtils.filterInvalidSources(destinationType, sources) : /* [] */0;
                              var match$1 = Belt_List.length(validSources);
                              var tmp;
                              var exit = 0;
                              if (destinationAvailability === "Unavailable" || match$1 === 0) {
                                exit = 1;
                              } else {
                                tmp = match$1 !== 1 ? React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.color(Styles.Color.grey70),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.small),
                                                tl: {
                                                  hd: Css.textAlign("right"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, "Receiving from " + String(match$1) + " sources") : React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.color(Styles.Color.grey70),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.small),
                                                tl: {
                                                  hd: Css.textAlign("right"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, "Receiving from 1 source");
                              }
                              if (exit === 1) {
                                tmp = React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: {
                                              hd: Css.color(Styles.Color.grapeError),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.small),
                                                tl: {
                                                  hd: Css.textAlign("right"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          })
                                    }, "Not receiving from any sources");
                              }
                              return React.createElement("button", {
                                          key: destination.id,
                                          className: Curry._1(Css.merge, {
                                                hd: Curry._1(Css.style, Styles.buttonReset),
                                                tl: {
                                                  hd: Curry._1(Css.style, {
                                                        hd: Css.display("flex"),
                                                        tl: {
                                                          hd: Css.alignItems("center"),
                                                          tl: {
                                                            hd: Css.justifyContent("spaceBetween"),
                                                            tl: {
                                                              hd: Css.padding2(Css.px(0), Css.px(15)),
                                                              tl: {
                                                                hd: Css.borderBottom(Css.px(Belt_List.length(destinations) === (index + 1 | 0) ? 0 : 1), "solid", Styles.Color.grey20),
                                                                tl: {
                                                                  hd: Css.fontSize(Styles.FontSize.regular),
                                                                  tl: {
                                                                    hd: Css.width(Css.pct(100)),
                                                                    tl: {
                                                                      hd: Css.height(Css.px(70)),
                                                                      tl: {
                                                                        hd: Css.hover({
                                                                              hd: Css.backgroundColor(Styles.Color.grey20),
                                                                              tl: /* [] */0
                                                                            }),
                                                                        tl: {
                                                                          hd: Css.cursor(disabled ? "default" : "pointer"),
                                                                          tl: {
                                                                            hd: Css.textAlign("left"),
                                                                            tl: /* [] */0
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          disabled: disabled,
                                          onClick: (function (param) {
                                              return Router.Schema.pushDrawerItem(undefined, {
                                                          NAME: "destination",
                                                          VAL: destination.id
                                                        });
                                            }),
                                          onMouseEnter: (function (param) {
                                              return Curry._1(onHover, destination.id);
                                            }),
                                          onMouseLeave: (function (param) {
                                              return Curry._1(onLeave, destination.id);
                                            })
                                        }, React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.display("flex"),
                                                    tl: {
                                                      hd: Css.alignItems("center"),
                                                      tl: /* [] */0
                                                    }
                                                  })
                                            }, destinationType !== undefined ? React.createElement(Icons.Destinations.make, {
                                                    destination: destinationType,
                                                    size: 28
                                                  }) : React.createElement("div", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.width(Css.px(28)),
                                                          tl: {
                                                            hd: Css.height(Css.px(28)),
                                                            tl: {
                                                              hd: Css.backgroundColor(Styles.Color.grey30),
                                                              tl: {
                                                                hd: Css.borderRadius(Css.px(8)),
                                                                tl: {
                                                                  hd: Css.display("flex"),
                                                                  tl: {
                                                                    hd: Css.alignItems("center"),
                                                                    tl: {
                                                                      hd: Css.justifyContent("center"),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        })
                                                  }, React.createElement($$Text.make, {
                                                        size: "Medium",
                                                        weight: "Bold",
                                                        color: Styles.Color.white,
                                                        children: "?"
                                                      })), React.createElement(Spacer.make, {
                                                  width: 12
                                                }), React.createElement("div", undefined, React.createElement($$Text.make, {
                                                      size: "Medium",
                                                      weight: "Semi",
                                                      color: Styles.Color.grey90,
                                                      children: StateUtils.getDestinationName(destination)
                                                    })), destinationAvailability === "Available" ? null : (
                                                destinationAvailability === "AvailableDuringTrial" ? React.createElement("span", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.margin2(Css.px(0), Css.px(10)),
                                                              tl: {
                                                                hd: Css_Legacy_Core.SVG.fill("transparent"),
                                                                tl: {
                                                                  hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey50),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            })
                                                      }, React.createElement(PlanLightning.make, {
                                                            tooltipText: "Available during trial and on the " + (Belt_Option.mapWithDefault(destinationAvailableOnPlan, "", (function (plan) {
                                                                      return Workspace.printPlanName(plan.name);
                                                                    })) + " plan"),
                                                            tooltipDirection: "right"
                                                          })) : React.createElement("span", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.margin2(Css.px(0), Css.px(10)),
                                                              tl: {
                                                                hd: Css_Legacy_Core.SVG.fill("transparent"),
                                                                tl: {
                                                                  hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey50),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            })
                                                      }, React.createElement(PlanLightning.make, {
                                                            tooltipText: "Available on the " + (Belt_Option.mapWithDefault(destinationAvailableOnPlan, "", (function (plan) {
                                                                      return Workspace.printPlanName(plan.name);
                                                                    })) + " plan"),
                                                            tooltipDirection: "right"
                                                          }))
                                              )), tmp);
                            }))) : React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding(Css.px(20)),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.justifyContent("center"),
                                    tl: {
                                      hd: Css.flexDirection("column"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Large",
                            weight: "Semi",
                            textAlign: "center",
                            color: Styles.Color.grey50,
                            children: "No destinations"
                          }), React.createElement(Spacer.make, {
                            height: 12
                          }), React.createElement($$Text.make, {
                            size: "Medium",
                            textAlign: "center",
                            color: Styles.Color.grey50,
                            children: "Destinations represent where your data should be sent."
                          }))), tmp);
}

var make = SettingsDestinations;

exports.CreateDestinationModal = CreateDestinationModal;
exports.onAddDestination = onAddDestination;
exports.make = make;
/* content Not a pure module */
