// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Models = require("../../app/src/Models.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");

var _map = {"Merged":"Merged","Open":"Open","Closed":"Closed","Draft":"Draft","ReadyForReview":"ReadyForReview","ChangesRequested":"ChangesRequested","Approved":"Approved","Unknown":"Unknown"};

function branchStatusToJs(param) {
  return param;
}

function branchStatusFromJs(param) {
  return _map[param];
}

var branchStatuses = [
  "Merged",
  "Open",
  "Closed",
  "Draft",
  "ReadyForReview",
  "ChangesRequested",
  "Approved",
  "Unknown"
];

function inClosedState(branchStatus) {
  if (branchStatus === "Merged") {
    return true;
  } else {
    return branchStatus === "Closed";
  }
}

var closedStateBranchStatuses = Belt_Array.keep(branchStatuses, inClosedState);

function branchStatusModelToBranchStateBranchStatus(branchStatus) {
  if (typeof branchStatus === "number") {
    if (branchStatus === /* Master */0) {
      return "Approved";
    } else {
      return "Unknown";
    }
  }
  switch (branchStatus.TAG | 0) {
    case /* Merged */1 :
        return "Merged";
    case /* Closed */2 :
        return "Closed";
    case /* Open */0 :
    case /* Draft */3 :
        return "Draft";
    case /* ReadyForReview */4 :
        return "ReadyForReview";
    case /* ChangesRequested */5 :
        return "ChangesRequested";
    case /* Approved */6 :
        return "Approved";
    
  }
}

function encodeOptional(value, isShallow) {
  if (value !== undefined) {
    return Caml_option.valFromOption(value);
  } else if (isShallow) {
    return ;
  } else {
    return null;
  }
}

function parseFirebaseType(firebaseBranchModel) {
  return {
          branchId: firebaseBranchModel.branchId,
          lastActivityDate: Models.toDateOption(firebaseBranchModel.lastActivityDate),
          lastActiveUserId: Caml_option.nullable_to_opt(firebaseBranchModel.lastActiveUserId),
          branchStatus: Belt_Option.getWithDefault(branchStatusFromJs(firebaseBranchModel.branchStatus), "Draft"),
          approvalsCount: Caml_option.nullable_to_opt(firebaseBranchModel.approvalsCount),
          creatorUserId: firebaseBranchModel.creatorUserId,
          impactedSourceIds: Caml_option.nullable_to_opt(firebaseBranchModel.impactedSourceIds),
          creationDate: Belt_Option.getWithDefault(Models.toDateOption(firebaseBranchModel.creationDate), new Date()),
          branchName: firebaseBranchModel.branchName,
          branchLongName: firebaseBranchModel.branchLongName,
          commentsCount: Caml_option.nullable_to_opt(firebaseBranchModel.commentsCount),
          collaboratorIds: Caml_option.nullable_to_opt(firebaseBranchModel.collaboratorIds),
          lastCodegenBySource: Belt_Option.mapU(Caml_option.nullable_to_opt(firebaseBranchModel.lastCodegenBySource), (function (lastCodegenInfo) {
                  return Belt_MapString.fromArray(Belt_Array.mapU(Js_dict.entries(lastCodegenInfo), (function (param) {
                                    var lastCodegenInfo = param[1];
                                    return [
                                            param[0],
                                            {
                                              lastCodegenDate: Belt_Option.getExn(Models.toDateOption(lastCodegenInfo.lastCodegenDate)),
                                              lastCodegenUserId: lastCodegenInfo.lastCodegenUserId
                                            }
                                          ];
                                  })));
                })),
          lastPublishedAt: Models.toDateOption(firebaseBranchModel.lastPublishedAt),
          isShallow: firebaseBranchModel.isShallow
        };
}

function createShallowState(branchId, branchName, creatorUserId, createdAt, branchStatus, branchApprovalsCount) {
  return {
          branchId: branchId,
          lastActivityDate: undefined,
          lastActiveUserId: undefined,
          branchStatus: branchStatus,
          approvalsCount: branchApprovalsCount,
          creatorUserId: creatorUserId,
          impactedSourceIds: undefined,
          creationDate: createdAt,
          branchName: branchName,
          branchLongName: branchName,
          commentsCount: undefined,
          collaboratorIds: undefined,
          lastCodegenBySource: undefined,
          lastPublishedAt: undefined,
          isShallow: true
        };
}

exports.branchStatusToJs = branchStatusToJs;
exports.branchStatusFromJs = branchStatusFromJs;
exports.branchStatuses = branchStatuses;
exports.inClosedState = inClosedState;
exports.closedStateBranchStatuses = closedStateBranchStatuses;
exports.branchStatusModelToBranchStateBranchStatus = branchStatusModelToBranchStateBranchStatus;
exports.encodeOptional = encodeOptional;
exports.parseFirebaseType = parseFirebaseType;
exports.createShallowState = createShallowState;
/* closedStateBranchStatuses Not a pure module */
