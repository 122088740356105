// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Icon = require("./Icon.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");

var rootStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.width(Css.px(32)),
              tl: {
                hd: Css.height(Css.px(32)),
                tl: {
                  hd: Css.borderRadius(Css.px(16)),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.grey30),
                          tl: {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: Styles.Duration.$$short
                                }, undefined, undefined, "background-color"),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.backgroundColor(Styles.Color.grey40),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.flexShrink(0.0),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function ModalCloseButton(Props) {
  var onClick = Props.onClick;
  return React.createElement("button", {
              "aria-label": "Close",
              className: rootStyles,
              onClick: onClick
            }, React.createElement(Icon.make, {
                  type_: "close",
                  size: "large",
                  color: Styles.Color.white
                }));
}

var make = ModalCloseButton;

exports.rootStyles = rootStyles;
exports.make = make;
/* rootStyles Not a pure module */
