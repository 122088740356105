// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var PropertyType = require("./PropertyType.bs.js");
var PropertyValidations = require("./PropertyValidations.bs.js");

function GroupType(Props) {
  var model = Props.model;
  var groupType = Props.groupType;
  var idDescription = Props.idDescription;
  return React.createElement(React.Fragment, undefined, React.createElement(PropertyType.TypePill.make, {
                  children: "string"
                }), React.createElement(Spacer.make, {
                  width: 4
                }), React.createElement("span", {
                  className: PropertyValidations.styles
                }, "id of the " + (StateUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupType.id, model) + (" group " + idDescription))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("inlineFlex"),
                        tl: {
                          hd: Css.flexShrink(0.0),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Small",
                      weight: "Regular",
                      color: Styles.Color.grey70,
                      children: "Always sent"
                    })));
}

var make = GroupType;

exports.make = make;
/* Css Not a pure module */
