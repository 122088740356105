// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Title = require("./Title.bs.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var SheetInfoRow = require("./SheetInfoRow.bs.js");
var CreateButtonIcon = require("./emptystates/CreateButtonIcon.bs.js");
var EmptyStatesStyles = require("./emptystates/EmptyStatesStyles.bs.js");

var container = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.padding(Css.px(32)),
        tl: {
          hd: Css.paddingLeft(Css.px(16)),
          tl: {
            hd: Css.borderRadius(Css.px(4)),
            tl: {
              hd: Css.marginRight(Css.px(16)),
              tl: {
                hd: Css.height(Css.pct(100.0)),
                tl: {
                  hd: Css.width(Css.pct(100.0)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var button = Curry._1(Css.merge, {
      hd: EmptyStatesStyles.actionStyles,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.paddingTop(Css.px(16)),
              tl: {
                hd: Css.flexShrink(0.0),
                tl: {
                  hd: Css.marginRight(Css.px(0)),
                  tl: /* [] */0
                }
              }
            }),
        tl: /* [] */0
      }
    });

var link = Curry._1(Css.style, {
      hd: Css.textDecoration("none"),
      tl: {
        hd: Css.position("relative"),
        tl: {
          hd: Css.after({
                hd: Css.position("absolute"),
                tl: {
                  hd: Css.unsafe("content", "' '"),
                  tl: {
                    hd: Css.marginLeft("auto"),
                    tl: {
                      hd: Css.marginRight("auto"),
                      tl: {
                        hd: Css.left(Css.px(0)),
                        tl: {
                          hd: Css.right(Css.px(0)),
                          tl: {
                            hd: Css.bottom(Css.px(0)),
                            tl: {
                              hd: Css.height(Css.px(2)),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.blueSecondary),
                                tl: {
                                  hd: Css.width(Css.pct(100.0)),
                                  tl: {
                                    hd: Css.opacity(0.0),
                                    tl: {
                                      hd: Css.transition({
                                            NAME: "ms",
                                            VAL: Styles.Duration.$$default
                                          }, undefined, undefined, "opacity"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }),
          tl: {
            hd: Css.hover({
                  hd: Css.after({
                        hd: Css.opacity(1.0),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.selector(" > span", {
                          hd: Css.color(Styles.Color.blueSecondary),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }),
            tl: {
              hd: Css.selector(" > span", {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: Styles.Duration.$$default
                        }, undefined, undefined, "color"),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var Style = {
  container: container,
  button: button,
  link: link
};

function PropertiesSheetEmptyGroupTypes(Props) {
  var globalSend = Props.globalSend;
  var columns = Props.columns;
  var totalColumnWidth = Belt_Array.reduceU(Belt_Array.mapU(Belt_Array.keepU(columns, (function (param) {
                  return param.visible;
                })), (function (param) {
              return param.width;
            })), 0, (function (acc, width) {
          return acc + width | 0;
        }));
  return React.createElement(SheetInfoRow.make, {
              rowType: /* Transparent */1,
              totalColumnWidth: totalColumnWidth,
              children: React.createElement("div", {
                    className: container
                  }, React.createElement("div", {
                        className: button,
                        role: "button",
                        onClick: (function (param) {
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "NewGroupType",
                                          VAL: [
                                            undefined,
                                            undefined
                                          ]
                                        }
                                      });
                          })
                      }, React.createElement("div", {
                            className: EmptyStatesStyles.iconStyles
                          }, CreateButtonIcon.icon), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            textAlign: "center",
                            color: Styles.Color.grey80,
                            children: "Create Your First Group Type Property"
                          })), React.createElement(Spacer.make, {
                        width: 32
                      }), React.createElement("div", undefined, React.createElement(Title.make, {
                            children: "Group Properties",
                            size: "Medium"
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.maxWidth(Css.px(500)),
                                  tl: /* [] */0
                                })
                          }, React.createElement($$Text.make, {
                                size: "Large",
                                color: Styles.Color.grey70,
                                children: null
                              }, "Group properties describe the state of a group you defined previously. Similar to User properties, this information is usually relevant in a larger scope than for exactly that event. Read more about Group Property Types in the ", React.createElement("a", {
                                    className: link,
                                    href: "https://www.avo.app/docs/data-design/groups"
                                  }, React.createElement($$Text.make, {
                                        element: "Span",
                                        weight: "Medium",
                                        color: Styles.Color.blue,
                                        children: "Avo docs"
                                      })), "."))))
            });
}

var make = PropertiesSheetEmptyGroupTypes;

exports.Style = Style;
exports.make = make;
/* container Not a pure module */
