// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var CmdK = require("./CmdK.bs.js");
var Icon = require("./Icon.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Styles = require("./styles.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var IconSendAs = require("./IconSendAs.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ActivityItem = require("./ActivityItem.bs.js");

var wrapper = Curry._1(Css.style, {
      hd: Css.width(Css.px(18)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.alignItems("center"),
            tl: /* [] */0
          }
        }
      }
    });

function CmdKActions$CmdKIcon(Props) {
  var type_ = Props.type_;
  var size = Props.size;
  return React.createElement("span", {
              className: wrapper
            }, React.createElement(Icon.make, {
                  type_: type_,
                  size: size,
                  color: Styles.Color.grey40
                }));
}

var CmdKIcon = {
  wrapper: wrapper,
  make: CmdKActions$CmdKIcon
};

function getActions(globalSend, schemaGroup, groupTypes) {
  return Belt_Array.concatMany([
              [
                Curry.app(CmdK.Action.t, [
                      "create_branch",
                      {
                        TAG: /* Create */1,
                        _0: "Branch..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  return Curry._1(globalSend, {
                                              TAG: /* OpenModal */4,
                                              _0: {
                                                NAME: "NewBranch",
                                                VAL: [
                                                  undefined,
                                                  ""
                                                ]
                                              }
                                            });
                                }), 0);
                          
                        }),
                      "create",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "add_event",
                      {
                        TAG: /* Create */1,
                        _0: "Event..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  return Curry._1(globalSend, {
                                              TAG: /* OpenModal */4,
                                              _0: {
                                                NAME: "NewEvent",
                                                VAL: [
                                                  "",
                                                  (function (eventId, _name) {
                                                      return Router.Schema.pushDrawerItem(undefined, {
                                                                  NAME: "event",
                                                                  VAL: [
                                                                    eventId,
                                                                    undefined,
                                                                    undefined,
                                                                    false
                                                                  ]
                                                                });
                                                    }),
                                                  "CommandPalette"
                                                ]
                                              }
                                            });
                                }), 0);
                          
                        }),
                      "create",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "add_event_property",
                      {
                        TAG: /* Create */1,
                        _0: "Event Property..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  return Curry._1(globalSend, {
                                              TAG: /* OpenModal */4,
                                              _0: {
                                                NAME: "NewProperty",
                                                VAL: [
                                                  "",
                                                  /* EventProperty */1,
                                                  undefined,
                                                  (function (propertyId, _propertyName) {
                                                      return Router.Schema.pushDrawerItem(undefined, {
                                                                  NAME: "property",
                                                                  VAL: [
                                                                    propertyId,
                                                                    undefined
                                                                  ]
                                                                });
                                                    }),
                                                  "CommandPalette"
                                                ]
                                              }
                                            });
                                }), 0);
                          
                        }),
                      "create",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "add_user_property",
                      {
                        TAG: /* Create */1,
                        _0: "User Property..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  return Curry._1(globalSend, {
                                              TAG: /* OpenModal */4,
                                              _0: {
                                                NAME: "NewProperty",
                                                VAL: [
                                                  "",
                                                  /* UserProperty */2,
                                                  undefined,
                                                  (function (propertyId, _propertyName) {
                                                      return Router.Schema.pushDrawerItem(undefined, {
                                                                  NAME: "property",
                                                                  VAL: [
                                                                    propertyId,
                                                                    undefined
                                                                  ]
                                                                });
                                                    }),
                                                  "CommandPalette"
                                                ]
                                              }
                                            });
                                }), 0);
                          
                        }),
                      "create",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "add_system_property",
                      {
                        TAG: /* Create */1,
                        _0: "System Property..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  return Curry._1(globalSend, {
                                              TAG: /* OpenModal */4,
                                              _0: {
                                                NAME: "NewProperty",
                                                VAL: [
                                                  "",
                                                  /* SystemProperty */0,
                                                  undefined,
                                                  (function (propertyId, _propertyName) {
                                                      return Router.Schema.pushDrawerItem(undefined, {
                                                                  NAME: "property",
                                                                  VAL: [
                                                                    propertyId,
                                                                    undefined
                                                                  ]
                                                                });
                                                    }),
                                                  "CommandPalette"
                                                ]
                                              }
                                            });
                                }), 0);
                          
                        }),
                      "create",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "add_event_property_group",
                      {
                        TAG: /* Create */1,
                        _0: "Event Property Bundle..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  return Curry._1(globalSend, {
                                              TAG: /* OpenModal */4,
                                              _0: {
                                                NAME: "NewPropertyGroup",
                                                VAL: [
                                                  undefined,
                                                  undefined
                                                ]
                                              }
                                            });
                                }), 0);
                          
                        }),
                      "create",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "add_category",
                      {
                        TAG: /* Create */1,
                        _0: "Category..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "CreateCategory",
                                          VAL: [
                                            undefined,
                                            undefined
                                          ]
                                        }
                                      });
                                  return AnalyticsRe.categoryInitiated(schemaGroup, schemaGroup.branchId, schemaGroup.schemaId);
                                }), 0);
                          
                        }),
                      "create",
                      undefined
                    ])
              ],
              Belt_Array.map(groupTypes, (function (param) {
                      var id = param.id;
                      return Curry.app(CmdK.Action.t, [
                                  "add_group_property_" + id,
                                  {
                                    TAG: /* Create */1,
                                    _0: param.name + " Property..."
                                  },
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (param) {
                                      return React.createElement(CmdKActions$CmdKIcon, {
                                                  type_: "plus",
                                                  size: "medium"
                                                });
                                    }),
                                  (function (param) {
                                      setTimeout((function (param) {
                                              return Curry._1(globalSend, {
                                                          TAG: /* OpenModal */4,
                                                          _0: {
                                                            NAME: "NewProperty",
                                                            VAL: [
                                                              "",
                                                              /* GroupProperty */{
                                                                _0: id
                                                              },
                                                              undefined,
                                                              (function (propertyId, _propertyName) {
                                                                  return Router.Schema.pushDrawerItem(undefined, {
                                                                              NAME: "property",
                                                                              VAL: [
                                                                                propertyId,
                                                                                undefined
                                                                              ]
                                                                            });
                                                                }),
                                                              "CommandPalette"
                                                            ]
                                                          }
                                                        });
                                            }), 0);
                                      
                                    }),
                                  "create",
                                  undefined
                                ]);
                    })),
              [Curry.app(CmdK.Action.t, [
                      "add_group_type",
                      {
                        TAG: /* Create */1,
                        _0: "Group Type..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  return Curry._1(globalSend, {
                                              TAG: /* OpenModal */4,
                                              _0: {
                                                NAME: "NewGroupType",
                                                VAL: [
                                                  undefined,
                                                  undefined
                                                ]
                                              }
                                            });
                                }), 0);
                          
                        }),
                      "create",
                      undefined
                    ])]
            ]);
}

var Create = {
  getActions: getActions
};

function getSections(param) {
  return [
          Curry.app(CmdK.Action.t, [
                "events",
                {
                  TAG: /* Command */2,
                  _0: "Events..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "search",
                                size: "small"
                              });
                  }),
                undefined,
                "search",
                undefined
              ]),
          Curry.app(CmdK.Action.t, [
                "properties",
                {
                  TAG: /* Command */2,
                  _0: "Properties..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "search",
                                size: "small"
                              });
                  }),
                undefined,
                "search",
                undefined
              ]),
          Curry.app(CmdK.Action.t, [
                "propertyGroups",
                {
                  TAG: /* Command */2,
                  _0: "Property Bundles..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "search",
                                size: "small"
                              });
                  }),
                undefined,
                "search",
                undefined
              ]),
          Curry.app(CmdK.Action.t, [
                "categories",
                {
                  TAG: /* Command */2,
                  _0: "Categories..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "search",
                                size: "small"
                              });
                  }),
                undefined,
                "search",
                undefined
              ]),
          Curry.app(CmdK.Action.t, [
                "sources",
                {
                  TAG: /* Command */2,
                  _0: "Sources..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "search",
                                size: "small"
                              });
                  }),
                undefined,
                "search",
                undefined
              ]),
          Curry.app(CmdK.Action.t, [
                "destinations",
                {
                  TAG: /* Command */2,
                  _0: "Destinations..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "search",
                                size: "small"
                              });
                  }),
                undefined,
                "search",
                undefined
              ]),
          Curry.app(CmdK.Action.t, [
                "metrics",
                {
                  TAG: /* Command */2,
                  _0: "Metrics..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "search",
                                size: "small"
                              });
                  }),
                undefined,
                "search",
                undefined
              ]),
          Curry.app(CmdK.Action.t, [
                "integrations",
                {
                  TAG: /* Command */2,
                  _0: "Integrations..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "search",
                                size: "small"
                              });
                  }),
                undefined,
                "search",
                undefined
              ])
        ];
}

function getEventActions(model) {
  return Belt_List.toArray(Belt_List.map(model.events, (function ($$event) {
                    return Curry.app(CmdK.Action.t, [
                                $$event.id,
                                {
                                  TAG: /* Event */4,
                                  _0: $$event.name,
                                  _1: $$event.description
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    return Router.Schema.pushDrawerItem(undefined, {
                                                NAME: "event",
                                                VAL: [
                                                  $$event.id,
                                                  undefined,
                                                  undefined,
                                                  false
                                                ]
                                              });
                                  }),
                                "search",
                                undefined
                              ]);
                  })));
}

function nameMappedActions(model) {
  return Belt_List.toArray(Belt_List.keepMap(model.rules, (function (rule) {
                    var match = rule.item;
                    if (typeof match !== "object") {
                      return ;
                    }
                    var variant = match.NAME;
                    if (variant === "Property") {
                      var match$1 = rule.definition;
                      if (typeof match$1 !== "object") {
                        return ;
                      }
                      if (match$1.NAME !== "NameMapping") {
                        return ;
                      }
                      var name = match$1.VAL.name;
                      var propertyId = match.VAL;
                      return Belt_Option.map(StateUtils.resolvePropertyById(propertyId, model), (function (resolvedProperty) {
                                    return Curry.app(CmdK.Action.t, [
                                                propertyId + "-namemapped",
                                                {
                                                  TAG: /* NameMappedProperty */7,
                                                  _0: name,
                                                  _1: resolvedProperty.name,
                                                  _2: resolvedProperty.description
                                                },
                                                undefined,
                                                undefined,
                                                "mapping " + Belt_Option.mapWithDefault(name, "", (function (name) {
                                                        return name + " ";
                                                      })) + resolvedProperty.name,
                                                (function (param) {
                                                    return React.createElement(IconSendAs.make, {
                                                                size: 12,
                                                                color: Styles.Color.grey50
                                                              });
                                                  }),
                                                (function (param) {
                                                    return Router.Schema.pushDrawerItem(undefined, {
                                                                NAME: "property",
                                                                VAL: [
                                                                  propertyId,
                                                                  undefined
                                                                ]
                                                              });
                                                  }),
                                                "search",
                                                undefined
                                              ]);
                                  }));
                    }
                    if (variant !== "Event") {
                      return ;
                    }
                    var match$2 = rule.definition;
                    if (typeof match$2 !== "object") {
                      return ;
                    }
                    if (match$2.NAME !== "NameMapping") {
                      return ;
                    }
                    var name$1 = match$2.VAL.name;
                    var eventId = match.VAL;
                    return Belt_Option.map(StateUtils.getEventById(eventId, model), (function (resolvedEvent) {
                                  return Curry.app(CmdK.Action.t, [
                                              eventId + "-namemapped",
                                              {
                                                TAG: /* NameMappedEvent */5,
                                                _0: name$1,
                                                _1: resolvedEvent.name,
                                                _2: resolvedEvent.description
                                              },
                                              undefined,
                                              undefined,
                                              "mapping " + Belt_Option.mapWithDefault(name$1, "", (function (name) {
                                                      return name + " ";
                                                    })) + resolvedEvent.name,
                                              (function (param) {
                                                  return React.createElement(IconSendAs.make, {
                                                              size: 12,
                                                              color: Styles.Color.grey50
                                                            });
                                                }),
                                              (function (param) {
                                                  return Router.Schema.pushDrawerItem(undefined, {
                                                              NAME: "event",
                                                              VAL: [
                                                                eventId,
                                                                undefined,
                                                                undefined,
                                                                false
                                                              ]
                                                            });
                                                }),
                                              "search",
                                              undefined
                                            ]);
                                }));
                  })));
}

function getPropertyActions(model) {
  return Belt_List.toArray(Belt_List.keepMap(model.properties, (function (property) {
                    if (property.TAG === /* PropertyRef */0) {
                      return ;
                    }
                    var property$1 = property._0;
                    var groupTypeId = property$1.sendAs;
                    if (typeof groupTypeId === "number") {
                      return Caml_option.some(Curry.app(CmdK.Action.t, [
                                      property$1.id,
                                      {
                                        TAG: /* Property */6,
                                        _0: property$1.name,
                                        _1: property$1.sendAs,
                                        _2: undefined,
                                        _3: property$1.description
                                      },
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (param) {
                                          return Router.Schema.pushDrawerItem(undefined, {
                                                      NAME: "property",
                                                      VAL: [
                                                        property$1.id,
                                                        undefined
                                                      ]
                                                    });
                                        }),
                                      "search",
                                      undefined
                                    ]));
                    } else {
                      return Belt_Option.map(StateUtils.getGroupType(groupTypeId._0, model), (function (param) {
                                    return Curry.app(CmdK.Action.t, [
                                                property$1.id,
                                                {
                                                  TAG: /* Property */6,
                                                  _0: property$1.name,
                                                  _1: property$1.sendAs,
                                                  _2: param.name,
                                                  _3: property$1.description
                                                },
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (param) {
                                                    return Router.Schema.pushDrawerItem(undefined, {
                                                                NAME: "property",
                                                                VAL: [
                                                                  property$1.id,
                                                                  undefined
                                                                ]
                                                              });
                                                  }),
                                                "search",
                                                undefined
                                              ]);
                                  }));
                    }
                  })));
}

function getPropertyGroupActions(model) {
  return Belt_List.toArray(Belt_List.map(model.propertyBundles, (function (propertyGroup) {
                    return Curry.app(CmdK.Action.t, [
                                propertyGroup.id,
                                {
                                  TAG: /* PropertyGroup */13,
                                  _0: propertyGroup.name
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    return Router.Schema.pushDrawerItem(undefined, {
                                                NAME: "propertyGroup",
                                                VAL: [
                                                  propertyGroup.id,
                                                  undefined
                                                ]
                                              });
                                  }),
                                "search",
                                undefined
                              ]);
                  })));
}

function getSourceActions(model) {
  return Belt_List.toArray(Belt_List.map(model.sources, (function (source) {
                    return Curry.app(CmdK.Action.t, [
                                source.id,
                                {
                                  TAG: /* Source */8,
                                  _0: source.name
                                },
                                undefined,
                                undefined,
                                "source",
                                undefined,
                                (function (param) {
                                    return Router.Schema.pushDrawerItem(undefined, {
                                                NAME: "source",
                                                VAL: [
                                                  source.id,
                                                  "overview"
                                                ]
                                              });
                                  }),
                                "search",
                                undefined
                              ]);
                  })));
}

function getDestinationActions(model) {
  return Belt_List.toArray(Belt_List.map(model.destinations, (function (destination) {
                    return Curry.app(CmdK.Action.t, [
                                destination.id,
                                {
                                  TAG: /* Destination */9,
                                  _0: destination.name
                                },
                                undefined,
                                undefined,
                                "destination",
                                undefined,
                                (function (param) {
                                    return Router.Schema.pushDrawerItem(undefined, {
                                                NAME: "destination",
                                                VAL: destination.id
                                              });
                                  }),
                                "search",
                                undefined
                              ]);
                  })));
}

function getGoalActions(model) {
  return Belt_List.toArray(Belt_List.map(model.goals, (function (goal) {
                    return Curry.app(CmdK.Action.t, [
                                goal.id,
                                {
                                  TAG: /* Category */12,
                                  _0: goal.name
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    return Router.Schema.pushDrawerItem(undefined, {
                                                NAME: "category",
                                                VAL: [
                                                  goal.id,
                                                  undefined
                                                ]
                                              });
                                  }),
                                "search",
                                undefined
                              ]);
                  })));
}

function getMetricActions(model) {
  return Belt_List.toArray(Belt_List.map(model.metrics, (function (metric) {
                    return Curry.app(CmdK.Action.t, [
                                metric.id,
                                {
                                  TAG: /* Metric */11,
                                  _0: metric.name
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    return Router.Schema.pushDrawerItem(undefined, {
                                                NAME: "metric",
                                                VAL: [
                                                  metric.id,
                                                  undefined
                                                ]
                                              });
                                  }),
                                "search",
                                undefined
                              ]);
                  })));
}

function getIntegrationActions(model) {
  return Belt_List.toArray(Belt_List.map(model.integrations, (function (integration) {
                    return Curry.app(CmdK.Action.t, [
                                integration.id,
                                {
                                  TAG: /* Integration */10,
                                  _0: integration.name
                                },
                                undefined,
                                undefined,
                                "integration",
                                undefined,
                                (function (param) {
                                    return Router.Schema.pushDrawerItem(undefined, {
                                                NAME: "integration",
                                                VAL: [
                                                  integration.id,
                                                  undefined
                                                ]
                                              });
                                  }),
                                "search",
                                undefined
                              ]);
                  })));
}

function getActions$1(model) {
  return Belt_Array.concatMany([
              getSections(undefined),
              getEventActions(model),
              getPropertyActions(model),
              nameMappedActions(model),
              getPropertyGroupActions(model),
              getSourceActions(model),
              getDestinationActions(model),
              getGoalActions(model),
              getMetricActions(model),
              getIntegrationActions(model)
            ]);
}

var TrackingPlan = {
  getSections: getSections,
  getEventActions: getEventActions,
  nameMappedActions: nameMappedActions,
  getPropertyActions: getPropertyActions,
  getPropertyGroupActions: getPropertyGroupActions,
  getSourceActions: getSourceActions,
  getDestinationActions: getDestinationActions,
  getGoalActions: getGoalActions,
  getMetricActions: getMetricActions,
  getIntegrationActions: getIntegrationActions,
  getActions: getActions$1
};

function getSections$1(param) {
  return [
          Curry.app(CmdK.Action.t, [
                "unarchiveEvents",
                {
                  TAG: /* Command */2,
                  _0: "Events..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "folder",
                                size: "small"
                              });
                  }),
                undefined,
                "unarchive",
                undefined
              ]),
          Curry.app(CmdK.Action.t, [
                "unarchiveProperties",
                {
                  TAG: /* Command */2,
                  _0: "Properties..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "folder",
                                size: "small"
                              });
                  }),
                undefined,
                "unarchive",
                undefined
              ]),
          Curry.app(CmdK.Action.t, [
                "unarchivePropertyGroups",
                {
                  TAG: /* Command */2,
                  _0: "Property Bundles..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "folder",
                                size: "small"
                              });
                  }),
                undefined,
                "unarchive",
                undefined
              ]),
          Curry.app(CmdK.Action.t, [
                "unarchiveCategories",
                {
                  TAG: /* Command */2,
                  _0: "Categories..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "folder",
                                size: "small"
                              });
                  }),
                undefined,
                "unarchive",
                undefined
              ]),
          Curry.app(CmdK.Action.t, [
                "unarchiveSources",
                {
                  TAG: /* Command */2,
                  _0: "Sources..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "folder",
                                size: "small"
                              });
                  }),
                undefined,
                "unarchive",
                undefined
              ]),
          Curry.app(CmdK.Action.t, [
                "unarchiveDestinations",
                {
                  TAG: /* Command */2,
                  _0: "Destinations..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "folder",
                                size: "small"
                              });
                  }),
                undefined,
                "unarchive",
                undefined
              ]),
          Curry.app(CmdK.Action.t, [
                "unarchiveMetrics",
                {
                  TAG: /* Command */2,
                  _0: "Metrics..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "folder",
                                size: "small"
                              });
                  }),
                undefined,
                "unarchive",
                undefined
              ]),
          Curry.app(CmdK.Action.t, [
                "unarchiveGroupTypes",
                {
                  TAG: /* Command */2,
                  _0: "Group Types..."
                },
                1.2,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement(CmdKActions$CmdKIcon, {
                                type_: "folder",
                                size: "small"
                              });
                  }),
                undefined,
                "unarchive",
                undefined
              ])
        ];
}

function handleUnarchive(item, itemId, itemName, itemType, schemaGroup, sendActions, param) {
  return Curry.app(sendActions, [
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              (function (param) {
                  AnalyticsRe.itemUnarchived(schemaGroup, itemId, itemName, itemType, "CommandPalette", schemaGroup.schemaId, schemaGroup.branchId);
                  var variant = item.NAME;
                  if (variant === "Destination") {
                    return Router.Schema.pushDrawerItem(undefined, {
                                NAME: "destination",
                                VAL: item.VAL
                              });
                  } else if (variant === "Integration") {
                    return Router.Schema.pushDrawerItem(undefined, {
                                NAME: "integration",
                                VAL: [
                                  item.VAL,
                                  undefined
                                ]
                              });
                  } else if (variant === "GroupType") {
                    return Router.Schema.pushSchemaRoute(undefined, undefined, "properties");
                  } else if (variant === "Property") {
                    return Router.Schema.pushDrawerItem(undefined, {
                                NAME: "property",
                                VAL: [
                                  item.VAL,
                                  undefined
                                ]
                              });
                  } else if (variant === "Event") {
                    return Router.Schema.pushDrawerItem(undefined, {
                                NAME: "event",
                                VAL: [
                                  item.VAL,
                                  undefined,
                                  undefined,
                                  false
                                ]
                              });
                  } else if (variant === "PropertyGroup") {
                    return Router.Schema.pushDrawerItem(undefined, {
                                NAME: "propertyGroup",
                                VAL: [
                                  item.VAL,
                                  undefined
                                ]
                              });
                  } else if (variant === "Goal") {
                    return Router.Schema.pushDrawerItem(undefined, {
                                NAME: "category",
                                VAL: [
                                  item.VAL,
                                  undefined
                                ]
                              });
                  } else if (variant === "Metric") {
                    return Router.Schema.pushDrawerItem(undefined, {
                                NAME: "metric",
                                VAL: [
                                  item.VAL,
                                  undefined
                                ]
                              });
                  } else {
                    return Router.Schema.pushDrawerItem(undefined, {
                                NAME: "source",
                                VAL: [
                                  item.VAL,
                                  "overview"
                                ]
                              });
                  }
                }),
              undefined,
              [[
                  {
                    NAME: "Unarchive",
                    VAL: item
                  },
                  ActivityItem.getActionContext(item)
                ]]
            ]);
}

function getEventActions$1(model, sendActions, schemaGroup) {
  return Belt_List.toArray(Belt_List.map(model.archive.events, (function ($$event) {
                    return Curry.app(CmdK.Action.t, [
                                "unarchive-" + $$event.id,
                                {
                                  TAG: /* Event */4,
                                  _0: $$event.name,
                                  _1: $$event.description
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    return handleUnarchive({
                                                NAME: "Event",
                                                VAL: $$event.id
                                              }, $$event.id, $$event.name, "Event", schemaGroup, sendActions, undefined);
                                  }),
                                "unarchive",
                                undefined
                              ]);
                  })));
}

function nameMappedActions$1(model, sendActions, schemaGroup) {
  return Belt_List.toArray(Belt_List.keepMap(model.rules, (function (rule) {
                    var match = rule.item;
                    if (typeof match !== "object") {
                      return ;
                    }
                    var variant = match.NAME;
                    if (variant === "Property") {
                      var match$1 = rule.definition;
                      if (typeof match$1 !== "object") {
                        return ;
                      }
                      if (match$1.NAME !== "NameMapping") {
                        return ;
                      }
                      var name = match$1.VAL.name;
                      var propertyId = match.VAL;
                      return Belt_Option.map(StateUtils.resolvePropertyByIdFromArchive(propertyId, model), (function (resolvedProperty) {
                                    return Curry.app(CmdK.Action.t, [
                                                "unarchive-" + propertyId + "-namemapped",
                                                {
                                                  TAG: /* NameMappedProperty */7,
                                                  _0: name,
                                                  _1: resolvedProperty.name,
                                                  _2: resolvedProperty.description
                                                },
                                                undefined,
                                                undefined,
                                                "mapping " + Belt_Option.mapWithDefault(name, "", (function (name) {
                                                        return name + " ";
                                                      })) + resolvedProperty.name,
                                                (function (param) {
                                                    return React.createElement(IconSendAs.make, {
                                                                size: 12,
                                                                color: Styles.Color.grey50
                                                              });
                                                  }),
                                                (function (param) {
                                                    return handleUnarchive({
                                                                NAME: "Property",
                                                                VAL: propertyId
                                                              }, propertyId, resolvedProperty.name, "Property", schemaGroup, sendActions, undefined);
                                                  }),
                                                "unarchive",
                                                undefined
                                              ]);
                                  }));
                    }
                    if (variant !== "Event") {
                      return ;
                    }
                    var match$2 = rule.definition;
                    if (typeof match$2 !== "object") {
                      return ;
                    }
                    if (match$2.NAME !== "NameMapping") {
                      return ;
                    }
                    var name$1 = match$2.VAL.name;
                    var eventId = match.VAL;
                    return Belt_Option.map(StateUtils.getEventByIdFromArchive(eventId, model), (function (resolvedEvent) {
                                  return Curry.app(CmdK.Action.t, [
                                              "unarchive-" + eventId + "-namemapped",
                                              {
                                                TAG: /* NameMappedEvent */5,
                                                _0: name$1,
                                                _1: resolvedEvent.name,
                                                _2: resolvedEvent.description
                                              },
                                              undefined,
                                              undefined,
                                              "mapping " + Belt_Option.mapWithDefault(name$1, "", (function (name) {
                                                      return name + " ";
                                                    })) + resolvedEvent.name,
                                              (function (param) {
                                                  return React.createElement(IconSendAs.make, {
                                                              size: 12,
                                                              color: Styles.Color.grey50
                                                            });
                                                }),
                                              (function (param) {
                                                  return handleUnarchive({
                                                              NAME: "Event",
                                                              VAL: eventId
                                                            }, eventId, resolvedEvent.name, "Event", schemaGroup, sendActions, undefined);
                                                }),
                                              "unarchive",
                                              undefined
                                            ]);
                                }));
                  })));
}

function getPropertyActions$1(model, sendActions, schemaGroup) {
  return Belt_List.toArray(Belt_List.keepMap(model.archive.properties, (function (property) {
                    if (property.TAG === /* PropertyRef */0) {
                      return ;
                    }
                    var property$1 = property._0;
                    var groupTypeId = property$1.sendAs;
                    if (typeof groupTypeId === "number") {
                      return Caml_option.some(Curry.app(CmdK.Action.t, [
                                      "unarchive-" + property$1.id,
                                      {
                                        TAG: /* Property */6,
                                        _0: property$1.name,
                                        _1: property$1.sendAs,
                                        _2: undefined,
                                        _3: property$1.description
                                      },
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (param) {
                                          return handleUnarchive({
                                                      NAME: "Property",
                                                      VAL: property$1.id
                                                    }, property$1.id, property$1.name, "Property", schemaGroup, sendActions, undefined);
                                        }),
                                      "unarchive",
                                      undefined
                                    ]));
                    } else {
                      return Belt_Option.map(StateUtils.getGroupTypeWithArchive(groupTypeId._0, model), (function (param) {
                                    return Curry.app(CmdK.Action.t, [
                                                "unarchive-" + property$1.id,
                                                {
                                                  TAG: /* Property */6,
                                                  _0: property$1.name,
                                                  _1: property$1.sendAs,
                                                  _2: param.name,
                                                  _3: property$1.description
                                                },
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (param) {
                                                    return handleUnarchive({
                                                                NAME: "GroupType",
                                                                VAL: property$1.id
                                                              }, property$1.id, property$1.name, "GroupType", schemaGroup, sendActions, undefined);
                                                  }),
                                                "unarchive",
                                                undefined
                                              ]);
                                  }));
                    }
                  })));
}

function getPropertyGroupActions$1(model, sendActions, schemaGroup) {
  return Belt_List.toArray(Belt_List.map(model.archive.propertyBundles, (function (propertyGroup) {
                    return Curry.app(CmdK.Action.t, [
                                "unarchive-" + propertyGroup.id,
                                {
                                  TAG: /* PropertyGroup */13,
                                  _0: propertyGroup.name
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    return handleUnarchive({
                                                NAME: "PropertyGroup",
                                                VAL: propertyGroup.id
                                              }, propertyGroup.id, propertyGroup.name, "PropertyGroup", schemaGroup, sendActions, undefined);
                                  }),
                                "unarchive",
                                undefined
                              ]);
                  })));
}

function getSourceActions$1(model, sendActions, schemaGroup) {
  return Belt_List.toArray(Belt_List.map(model.archive.sources, (function (source) {
                    return Curry.app(CmdK.Action.t, [
                                "unarchive-" + source.id,
                                {
                                  TAG: /* Source */8,
                                  _0: source.name
                                },
                                undefined,
                                undefined,
                                "source",
                                undefined,
                                (function (param) {
                                    return handleUnarchive({
                                                NAME: "Source",
                                                VAL: source.id
                                              }, source.id, AvoConfig.getSourceName(source), "Source", schemaGroup, sendActions, undefined);
                                  }),
                                "unarchive",
                                undefined
                              ]);
                  })));
}

function getDestinationActions$1(model, sendActions, schemaGroup) {
  return Belt_List.toArray(Belt_List.map(model.archive.destinations, (function (destination) {
                    return Curry.app(CmdK.Action.t, [
                                "unarchive-" + destination.id,
                                {
                                  TAG: /* Destination */9,
                                  _0: destination.name
                                },
                                undefined,
                                undefined,
                                "destination",
                                undefined,
                                (function (param) {
                                    return handleUnarchive({
                                                NAME: "Destination",
                                                VAL: destination.id
                                              }, destination.id, Belt_Option.getWithDefault(destination.name, "Untitled Destination"), "Destination", schemaGroup, sendActions, undefined);
                                  }),
                                "unarchive",
                                undefined
                              ]);
                  })));
}

function getGoalActions$1(model, sendActions, schemaGroup) {
  return Belt_List.toArray(Belt_List.map(model.archive.goals, (function (goal) {
                    return Curry.app(CmdK.Action.t, [
                                "unarchive-" + goal.id,
                                {
                                  TAG: /* Category */12,
                                  _0: goal.name
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    return handleUnarchive({
                                                NAME: "Goal",
                                                VAL: goal.id
                                              }, goal.id, goal.name, "Goal", schemaGroup, sendActions, undefined);
                                  }),
                                "unarchive",
                                undefined
                              ]);
                  })));
}

function getMetricActions$1(model, sendActions, schemaGroup) {
  return Belt_List.toArray(Belt_List.map(model.archive.metrics, (function (metric) {
                    return Curry.app(CmdK.Action.t, [
                                "unarchive-" + metric.id,
                                {
                                  TAG: /* Metric */11,
                                  _0: metric.name === "" ? "Untitled Metric" : metric.name
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    return handleUnarchive({
                                                NAME: "Metric",
                                                VAL: metric.id
                                              }, metric.id, metric.name, "Metric", schemaGroup, sendActions, undefined);
                                  }),
                                "unarchive",
                                undefined
                              ]);
                  })));
}

function getGroupTypeActions(model, sendActions, schemaGroup) {
  return Belt_Array.map(model.archive.groupTypes, (function (groupType) {
                return Curry.app(CmdK.Action.t, [
                            "unarchive-" + groupType.id,
                            {
                              TAG: /* GroupType */14,
                              _0: groupType.name === "" ? "Untitled Group Type" : groupType.name
                            },
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            (function (param) {
                                return handleUnarchive({
                                            NAME: "GroupType",
                                            VAL: groupType.id
                                          }, groupType.id, groupType.name, "GroupType", schemaGroup, sendActions, undefined);
                              }),
                            "unarchive",
                            undefined
                          ]);
              }));
}

function getActions$2(model, sendActions, schemaGroup) {
  return Belt_Array.concatMany([
              getSections$1(undefined),
              getEventActions$1(model, sendActions, schemaGroup),
              getPropertyActions$1(model, sendActions, schemaGroup),
              nameMappedActions$1(model, sendActions, schemaGroup),
              getPropertyGroupActions$1(model, sendActions, schemaGroup),
              getSourceActions$1(model, sendActions, schemaGroup),
              getDestinationActions$1(model, sendActions, schemaGroup),
              getGoalActions$1(model, sendActions, schemaGroup),
              getMetricActions$1(model, sendActions, schemaGroup),
              getGroupTypeActions(model, sendActions, schemaGroup)
            ]);
}

var TrackingPlanArchive = {
  getSections: getSections$1,
  handleUnarchive: handleUnarchive,
  getEventActions: getEventActions$1,
  nameMappedActions: nameMappedActions$1,
  getPropertyActions: getPropertyActions$1,
  getPropertyGroupActions: getPropertyGroupActions$1,
  getSourceActions: getSourceActions$1,
  getDestinationActions: getDestinationActions$1,
  getGoalActions: getGoalActions$1,
  getMetricActions: getMetricActions$1,
  getGroupTypeActions: getGroupTypeActions,
  getActions: getActions$2
};

function getOpenBranches(openBranches) {
  return Belt_List.toArray({
              hd: [
                "master",
                "main"
              ],
              tl: Belt_List.reverse(openBranches)
            });
}

function getActions$3(openBranches) {
  return Belt_Array.map(getOpenBranches(openBranches), (function (param) {
                var id = param[0];
                return Curry.app(CmdK.Action.t, [
                            id,
                            {
                              TAG: /* Branch */3,
                              _0: param[1]
                            },
                            undefined,
                            undefined,
                            undefined,
                            (function (param) {
                                return React.createElement(Icon.make, {
                                            type_: "branch",
                                            size: "small",
                                            color: Styles.Color.grey50
                                          });
                              }),
                            (function (param) {
                                var match = Router.getStaticRoute(undefined);
                                return Router.push(undefined, Curry._4(Router.Link.get, match.baseRoute, id === "master" ? "master" : ({
                                                    NAME: "branch",
                                                    VAL: id
                                                  }), match.schemaRoute, /* [] */0));
                              }),
                            "branch",
                            undefined
                          ]);
              }));
}

var Branch = {
  getOpenBranches: getOpenBranches,
  getActions: getActions$3
};

function getActions$4(globalSend, schemaGroup, role) {
  return Belt_Array.concatMany([
              [
                Curry.app(CmdK.Action.t, [
                      "search",
                      {
                        TAG: /* Command */2,
                        _0: "Search..."
                      },
                      2.0,
                      ["/"],
                      "s",
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "search",
                                      size: "small"
                                    });
                        }),
                      undefined,
                      undefined,
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "create",
                      {
                        TAG: /* Command */2,
                        _0: "Create..."
                      },
                      2.0,
                      ["c"],
                      "c",
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      undefined,
                      undefined,
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "branch",
                      {
                        TAG: /* Command */2,
                        _0: "Switch Branch..."
                      },
                      2.0,
                      ["b"],
                      "b",
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "branch",
                                      size: "small"
                                    });
                        }),
                      undefined,
                      undefined,
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "unarchive",
                      {
                        TAG: /* Command */2,
                        _0: "Unarchive..."
                      },
                      2.0,
                      ["u"],
                      "u",
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "folder",
                                      size: "small"
                                    });
                        }),
                      undefined,
                      undefined,
                      undefined
                    ])
              ],
              Models.Role.canEdit(role) ? [Curry.app(CmdK.Action.t, [
                        "import",
                        {
                          TAG: /* Command */2,
                          _0: "Import..."
                        },
                        2.0,
                        undefined,
                        "i",
                        (function (param) {
                            return React.createElement(CmdKActions$CmdKIcon, {
                                        type_: "import",
                                        size: "small"
                                      });
                          }),
                        (function (param) {
                            return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                        NAME: "import",
                                        VAL: "index"
                                      });
                          }),
                        undefined,
                        undefined
                      ])] : [],
              [Curry.app(CmdK.Action.t, [
                      "settings",
                      {
                        TAG: /* Command */2,
                        _0: "Workspace Settings..."
                      },
                      2.0,
                      undefined,
                      "w",
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "settings",
                                      size: "small"
                                    });
                        }),
                      (function (param) {
                          AnalyticsRe.membersClicked(schemaGroup, schemaGroup.branchId, schemaGroup.schemaId);
                          return Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "Settings",
                                        VAL: undefined
                                      }
                                    });
                        }),
                      undefined,
                      undefined
                    ])]
            ]);
}

var Root = {
  getActions: getActions$4
};

function getActions$5(globalSend, model, openBranches, schemaGroup, sendActions, role) {
  return Belt_Array.concatMany([
              getActions$4(globalSend, schemaGroup, role),
              getActions(globalSend, schemaGroup, model.groupTypes),
              getActions$3(openBranches),
              getActions$1(model),
              getActions$2(model, sendActions, schemaGroup)
            ]);
}

exports.CmdKIcon = CmdKIcon;
exports.Create = Create;
exports.TrackingPlan = TrackingPlan;
exports.TrackingPlanArchive = TrackingPlanArchive;
exports.Branch = Branch;
exports.Root = Root;
exports.getActions = getActions$5;
/* wrapper Not a pure module */
