// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Actions = require("./actions.bs.js");
var Mantine = require("./Mantine.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var FetchActivity = require("./FetchActivity.bs.js");
var LoadingCircle = require("./LoadingCircle.bs.js");
var ViewerContext = require("./ViewerContext.bs.js");
var EntityLightning = require("./EntityLightning.bs.js");
var GoalActivityItem = require("./GoalActivityItem.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var EventActivityItem = require("./EventActivityItem.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");
var BranchActivityItem = require("./BranchActivityItem.bs.js");
var MetricActivityItem = require("./MetricActivityItem.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var PropertyActivityItem = require("./PropertyActivityItem.bs.js");
var IntegrationActivityItem = require("./integration/IntegrationActivityItem.bs.js");
var PropertyGroupActivityItem = require("./PropertyGroupActivityItem.bs.js");

function isCommentAction(action) {
  var match = action.contents;
  if (typeof match !== "object") {
    return false;
  }
  var variant = match.NAME;
  if (variant === "PropertyComment" || variant === "GoalComment" || variant === "IntegrationComment" || variant === "MetricComment" || variant === "PropertyGroupComment" || variant === "EventComment") {
    return true;
  } else {
    return variant === "BranchComment";
  }
}

function ObjectActivityLog$ActivityLog(Props) {
  var object_ = Props.object_;
  var objectId = Props.objectId;
  var $$event = Props.event;
  var model = Props.model;
  var schema = Props.schema;
  var events = Props.events;
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var showCommentsOpt = Props.showComments;
  var goToId = Props.goToId;
  var showComments = showCommentsOpt !== undefined ? showCommentsOpt : true;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = ViewerContext.use(undefined);
  var userId = match.id;
  var globalSend = GlobalSendContext.use(undefined);
  var availability = AvoLimits.ActivityLogs.computeAvailability(workspace);
  var planHint = AvoLimits.ActivityLogs.availableOnPlan(workspace);
  var variant = object_.NAME;
  return React.createElement(FetchActivity.make, {
              schema: schema,
              currentBranch: currentBranch,
              queryBy: {
                hd: [
                  variant === "Integration" ? "context.integrationQuery" : (
                      variant === "Property" ? "context.propertyQuery" : (
                          variant === "Event" ? "context.eventQuery" : (
                              variant === "PropertyGroup" ? "context.propertyGroupQuery" : (
                                  variant === "Goal" ? "context.goalQuery" : (
                                      variant === "Metric" ? "context.metricQuery" : "context.branchQuery"
                                    )
                                )
                            )
                        )
                    ),
                  objectId
                ],
                tl: /* [] */0
              },
              pageSize: 25,
              children: (function (items, status, param, getMore) {
                  var actions = Belt_List.flatten(Belt_List.mapU(Actions.groupActionsByDate(Belt_List.reverse(Actions.filterSystemActions(items))), Actions.groupActions));
                  var limitedActions = availability === "Unavailable" ? Belt_List.reduce(Belt_List.reverse(actions), /* [] */0, (function (selected, actionGroup) {
                            var action = Belt_List.headExn(actionGroup);
                            if (Belt_List.length(selected) < 4 || isCommentAction(action)) {
                              return {
                                      hd: actionGroup,
                                      tl: selected
                                    };
                            } else {
                              return selected;
                            }
                          })) : actions;
                  var hasHiddenActions = availability === "Unavailable" && Belt_List.length(actions) > 4;
                  var tmp;
                  var exit = 0;
                  if (status === "Loading") {
                    tmp = React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.justifyContent("center"),
                                    tl: {
                                      hd: Css.padding2(Css.px(10), Css.px(0)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, React.createElement(LoadingCircle.make, {
                              color: Styles.Color.blue
                            }));
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    tmp = hasHiddenActions ? React.createElement("button", {
                            className: Curry._1(Css.merge, {
                                  hd: Curry._1(Css.style, Styles.button),
                                  tl: {
                                    hd: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: {
                                              hd: Css.fontSize(Styles.FontSize.small),
                                              tl: {
                                                hd: Css.color(Styles.Color.blue),
                                                tl: {
                                                  hd: Css.padding2(Css.px(15), Css.px(0)),
                                                  tl: {
                                                    hd: Css.justifyContent("center"),
                                                    tl: {
                                                      hd: Css.width(Css.pct(100)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }),
                            onClick: (function (param) {
                                if (availability === "Unavailable") {
                                  return Curry._1(globalSend, {
                                              TAG: /* OpenModal */4,
                                              _0: {
                                                NAME: "BillingPrompt",
                                                VAL: "ExpandActivityLog"
                                              }
                                            });
                                } else {
                                  return Curry._1(getMore, undefined);
                                }
                              })
                          }, React.createElement(EntityLightning.make, {
                                availability: availability,
                                planHint: planHint,
                                stroke: Styles.Color.blue
                              }), React.createElement(Spacer.make, {
                                display: "inlineBlock",
                                width: 4
                              }), "Load older activity...") : null;
                  }
                  return React.createElement(React.Fragment, undefined, tmp, Belt_List.toArray(Belt_List.mapU(limitedActions, (function (actionGroup) {
                                        var action = Belt_List.headExn(actionGroup);
                                        var firstAction = Belt_List.headExn(Belt_List.reverse(actionGroup));
                                        var userIds = Belt_List.toArray(Belt_List.mapU(BeltListExtensions.groupConsecutive(actionGroup, (function (a, b) {
                                                        return a.createdBy === b.createdBy;
                                                      })), (function (userActions) {
                                                    return Belt_List.headExn(userActions).createdBy;
                                                  })));
                                        var variant = object_.NAME;
                                        if (variant === "Integration") {
                                          var tmp = {
                                            schema: schema,
                                            events: events,
                                            action: action,
                                            userId: userId,
                                            userIds: userIds,
                                            openBranches: openBranches,
                                            integrationName: object_.VAL[1],
                                            showComments: showComments,
                                            key: action.id
                                          };
                                          if (goToId !== undefined) {
                                            tmp.goToId = Caml_option.valFromOption(goToId);
                                          }
                                          return React.createElement(IntegrationActivityItem.make, tmp);
                                        }
                                        if (variant === "Property") {
                                          var tmp$1 = {
                                            schema: schema,
                                            property: object_.VAL,
                                            events: events,
                                            model: model,
                                            action: action,
                                            userId: userId,
                                            userIds: userIds,
                                            openBranches: openBranches,
                                            showComments: showComments,
                                            key: action.id
                                          };
                                          if ($$event !== undefined) {
                                            tmp$1.event = Caml_option.valFromOption($$event);
                                          }
                                          if (goToId !== undefined) {
                                            tmp$1.goToId = Caml_option.valFromOption(goToId);
                                          }
                                          return React.createElement(PropertyActivityItem.make, tmp$1);
                                        }
                                        if (variant === "Event") {
                                          var tmp$2 = {
                                            schema: schema,
                                            event: object_.VAL,
                                            events: events,
                                            model: model,
                                            action: action,
                                            userId: userId,
                                            userIds: userIds,
                                            openBranches: openBranches,
                                            showComments: showComments,
                                            key: firstAction.id
                                          };
                                          if (goToId !== undefined) {
                                            tmp$2.goToId = Caml_option.valFromOption(goToId);
                                          }
                                          return React.createElement(EventActivityItem.make, tmp$2);
                                        }
                                        if (variant === "PropertyGroup") {
                                          var tmp$3 = {
                                            schema: schema,
                                            group: object_.VAL,
                                            events: events,
                                            model: model,
                                            action: action,
                                            userId: userId,
                                            userIds: userIds,
                                            openBranches: openBranches,
                                            showComments: showComments,
                                            key: action.id
                                          };
                                          if ($$event !== undefined) {
                                            tmp$3.event = Caml_option.valFromOption($$event);
                                          }
                                          if (goToId !== undefined) {
                                            tmp$3.goToId = Caml_option.valFromOption(goToId);
                                          }
                                          return React.createElement(PropertyGroupActivityItem.make, tmp$3);
                                        }
                                        if (variant === "Goal") {
                                          var tmp$4 = {
                                            schema: schema,
                                            events: events,
                                            model: model,
                                            action: action,
                                            userId: userId,
                                            userIds: userIds,
                                            openBranches: openBranches,
                                            goalName: object_.VAL[1],
                                            showComments: showComments,
                                            key: action.id
                                          };
                                          if (goToId !== undefined) {
                                            tmp$4.goToId = Caml_option.valFromOption(goToId);
                                          }
                                          return React.createElement(GoalActivityItem.make, tmp$4);
                                        }
                                        if (variant === "Metric") {
                                          var tmp$5 = {
                                            schema: schema,
                                            events: events,
                                            model: model,
                                            action: action,
                                            userId: userId,
                                            userIds: userIds,
                                            openBranches: openBranches,
                                            metricName: object_.VAL[1],
                                            showComments: showComments,
                                            key: action.id
                                          };
                                          if (goToId !== undefined) {
                                            tmp$5.goToId = Caml_option.valFromOption(goToId);
                                          }
                                          return React.createElement(MetricActivityItem.make, tmp$5);
                                        }
                                        var tmp$6 = {
                                          schema: schema,
                                          events: events,
                                          model: model,
                                          action: action,
                                          userId: userId,
                                          userIds: userIds,
                                          openBranches: openBranches,
                                          branchName: object_.VAL[1],
                                          showComments: showComments,
                                          key: action.id
                                        };
                                        if (goToId !== undefined) {
                                          tmp$6.goToId = Caml_option.valFromOption(goToId);
                                        }
                                        return React.createElement(BranchActivityItem.make, tmp$6);
                                      }))));
                })
            });
}

var ActivityLog = {
  make: ObjectActivityLog$ActivityLog
};

function ObjectActivityLog$ActivityLogLoadedWhenVisible(Props) {
  var object_ = Props.object_;
  var objectId = Props.objectId;
  var $$event = Props.event;
  var model = Props.model;
  var schema = Props.schema;
  var events = Props.events;
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var showComments = Props.showComments;
  var goToId = Props.goToId;
  var match = Curry._1(Mantine.Hooks.Intersection.useWithoutOptions, undefined);
  var entry = match.entry;
  var match$1 = React.useState(function () {
        return false;
      });
  var setHasSeenActivityLog = match$1[1];
  React.useEffect((function () {
          if (entry !== undefined && entry.isIntersecting) {
            Curry._1(setHasSeenActivityLog, (function (param) {
                    return true;
                  }));
          }
          
        }), [entry]);
  var tmp;
  if (match$1[0]) {
    var tmp$1 = {
      object_: object_,
      objectId: objectId,
      model: model,
      schema: schema,
      events: events,
      currentBranch: currentBranch,
      openBranches: openBranches
    };
    if ($$event !== undefined) {
      tmp$1.event = Caml_option.valFromOption($$event);
    }
    if (showComments !== undefined) {
      tmp$1.showComments = Caml_option.valFromOption(showComments);
    }
    if (goToId !== undefined) {
      tmp$1.goToId = Caml_option.valFromOption(goToId);
    }
    tmp = React.createElement(ObjectActivityLog$ActivityLog, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement("span", {
              ref: match.ref
            }, tmp);
}

var ActivityLogLoadedWhenVisible = {
  make: ObjectActivityLog$ActivityLogLoadedWhenVisible
};

function ObjectActivityLog$GoToCommentActivityLog(Props) {
  var object_ = Props.object_;
  var objectId = Props.objectId;
  var $$event = Props.event;
  var model = Props.model;
  var schema = Props.schema;
  var events = Props.events;
  var commentId = Props.commentId;
  var currentBranch = Props.currentBranch;
  var showComments = Props.showComments;
  var openBranches = Props.openBranches;
  var variant = object_.NAME;
  var action = FirebaseFetcherHooks.useActionFromCommentId(schema.id, commentId, variant === "Integration" ? "Integration" : (
          variant === "Property" ? "Property" : (
              variant === "Event" ? "Event" : (
                  variant === "PropertyGroup" ? "PropertyGroup" : (
                      variant === "Goal" ? "Goal" : (
                          variant === "Metric" ? "Metric" : "Branch"
                        )
                    )
                )
            )
        ));
  if (typeof action === "object") {
    var match = action.VAL;
    if (match && !match.tl) {
      var tmp = {
        object_: object_,
        objectId: objectId,
        model: model,
        schema: schema,
        events: events,
        currentBranch: currentBranch,
        openBranches: openBranches,
        goToId: match.hd.id
      };
      if ($$event !== undefined) {
        tmp.event = Caml_option.valFromOption($$event);
      }
      if (showComments !== undefined) {
        tmp.showComments = Caml_option.valFromOption(showComments);
      }
      return React.createElement(ObjectActivityLog$ActivityLogLoadedWhenVisible, tmp);
    }
    
  } else if (action === "Initial") {
    return React.createElement(LoadingCircle.make, {
                color: Styles.Color.blue
              });
  }
  var tmp$1 = {
    object_: object_,
    objectId: objectId,
    model: model,
    schema: schema,
    events: events,
    currentBranch: currentBranch,
    openBranches: openBranches
  };
  if ($$event !== undefined) {
    tmp$1.event = Caml_option.valFromOption($$event);
  }
  if (showComments !== undefined) {
    tmp$1.showComments = Caml_option.valFromOption(showComments);
  }
  return React.createElement(ObjectActivityLog$ActivityLog, tmp$1);
}

var GoToCommentActivityLog = {
  make: ObjectActivityLog$GoToCommentActivityLog
};

function ObjectActivityLog(Props) {
  var object_ = Props.object_;
  var objectId = Props.objectId;
  var $$event = Props.event;
  var model = Props.model;
  var schema = Props.schema;
  var events = Props.events;
  var goToCommentId = Props.goToCommentId;
  var currentBranch = Props.currentBranch;
  var showComments = Props.showComments;
  var openBranches = Props.openBranches;
  if (goToCommentId !== undefined) {
    var tmp = {
      object_: object_,
      objectId: objectId,
      model: model,
      schema: schema,
      events: events,
      commentId: goToCommentId,
      currentBranch: currentBranch,
      openBranches: openBranches
    };
    if ($$event !== undefined) {
      tmp.event = Caml_option.valFromOption($$event);
    }
    if (showComments !== undefined) {
      tmp.showComments = Caml_option.valFromOption(showComments);
    }
    return React.createElement(ObjectActivityLog$GoToCommentActivityLog, tmp);
  }
  var tmp$1 = {
    object_: object_,
    objectId: objectId,
    model: model,
    schema: schema,
    events: events,
    currentBranch: currentBranch,
    openBranches: openBranches
  };
  if ($$event !== undefined) {
    tmp$1.event = Caml_option.valFromOption($$event);
  }
  if (showComments !== undefined) {
    tmp$1.showComments = Caml_option.valFromOption(showComments);
  }
  return React.createElement(ObjectActivityLog$ActivityLog, tmp$1);
}

var make = ObjectActivityLog;

exports.isCommentAction = isCommentAction;
exports.ActivityLog = ActivityLog;
exports.ActivityLogLoadedWhenVisible = ActivityLogLoadedWhenVisible;
exports.GoToCommentActivityLog = GoToCommentActivityLog;
exports.make = make;
/* Css Not a pure module */
