// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconTags(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey40;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("svg", {
                  width: String(size),
                  viewBox: "0 0 16 16",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("g", {
                      className: "nc-icon-wrapper",
                      strokeWidth: "1"
                    }, React.createElement("path", {
                          d: "M9.121,15.207l4.086-4.086a1,1,0,0,0,0-1.414L6,2.5H.5V8l7.207,7.207A1,1,0,0,0,9.121,15.207Z",
                          fill: "none",
                          strokeLinecap: "round"
                        }), React.createElement("polyline", {
                          fill: "none",
                          points: "14.5 8 7 0.5 1.5 0.5",
                          strokeLinecap: "round"
                        }), React.createElement("circle", {
                          cx: "4",
                          cy: "6",
                          fill: "none",
                          r: "1.5",
                          strokeLinecap: "round"
                        }))));
}

var make = IconTags;

exports.make = make;
/* Css Not a pure module */
