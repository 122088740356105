// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var IconX = require("./IconX.bs.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Dom_storage = require("rescript/lib/js/dom_storage.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var Is_future = require("date-fns/is_future");

var expiryDate = new Date("2023-10-10T15:15:00.000Z");

var bannerColor = Styles.Color.mintGreenSecondary;

var path = "/data-quality-avoiding-pitfalls";

var href = "https://www.avo.app/data-quality-avoiding-pitfalls";

var rootStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(8)),
        tl: {
          hd: Css.left(Css.px(8)),
          tl: {
            hd: Css.right(Css.px(8)),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.zIndex(Styles.ZIndex.aboveAll),
                    tl: {
                      hd: Css.pointerEvents("none"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function announcementStyles(visibility) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.backgroundColor(bannerColor),
                    tl: {
                      hd: Css.borderRadius(Css.px(100)),
                      tl: {
                        hd: Css.paddingTop(Css.px(16)),
                        tl: {
                          hd: Css.paddingRight(Css.px(20)),
                          tl: {
                            hd: Css.paddingBottom(Css.px(16)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(32)),
                              tl: {
                                hd: Css.pointerEvents("auto"),
                                tl: {
                                  hd: Css.opacity(visibility === /* Visible */2 ? 1.0 : 0.0),
                                  tl: {
                                    hd: Css.transform(Css.translateY(Css.px(visibility === /* Visible */2 ? 0 : -60))),
                                    tl: {
                                      hd: Css.transition({
                                            NAME: "ms",
                                            VAL: 300.0
                                          }, undefined, visibility === /* Entering */1 ? ({
                                                NAME: "cubicBezier",
                                                VAL: [
                                                  0,
                                                  0.8,
                                                  0.9,
                                                  1
                                                ]
                                              }) : "easeIn", "all"),
                                      tl: {
                                        hd: Css.maxWidth(Css.px(600)),
                                        tl: {
                                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(8), Css.px(32), undefined, undefined, Styles.Color.setAlpha(bannerColor, 0.5))),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var linkStyles = Curry._1(Css.style, {
      hd: Css.fontWeight($$Text.getFontWeight("Bold")),
      tl: {
        hd: Css.textDecoration("none"),
        tl: {
          hd: Css.hover({
                hd: Css.textDecoration("underline"),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var underlinedLinkStyles = Curry._1(Css.style, {
      hd: Css.fontWeight($$Text.getFontWeight("Bold")),
      tl: {
        hd: Css.textDecoration("none"),
        tl: {
          hd: Css.textDecoration("underline"),
          tl: /* [] */0
        }
      }
    });

var closeButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.flexShrink(0.0),
                    tl: {
                      hd: Css.height(Css.px(32)),
                      tl: {
                        hd: Css.width(Css.px(32)),
                        tl: {
                          hd: Css.marginLeft(Css.px(8)),
                          tl: {
                            hd: Css.borderRadius(Css.px(16)),
                            tl: {
                              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.white),
                              tl: {
                                hd: Css.backgroundColor("transparent"),
                                tl: {
                                  hd: Css.cursor("pointer"),
                                  tl: {
                                    hd: Css.transition({
                                          NAME: "ms",
                                          VAL: Styles.Duration.$$short
                                        }, undefined, undefined, "background-color"),
                                    tl: {
                                      hd: Css.hover({
                                            hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.2)),
                                            tl: /* [] */0
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function Announcement(Props) {
  var match = React.useState(function () {
        return /* Hidden */0;
      });
  var setVisibility = match[1];
  var visibility = match[0];
  var schemaGroup = SchemaGroupContext.use(undefined);
  React.useEffect((function () {
          var hasDismissed = Dom_storage.getItem(AnalyticsRe.overlayItemNameToJs("DataQualityWebinarOctober102023"), localStorage);
          var isNotExpired = Is_future(expiryDate);
          if (!(Belt_Option.isNone(hasDismissed) && isNotExpired)) {
            return ;
          }
          AnalyticsRe.overlayItemDisplayed(schemaGroup, "Banner", "DataQualityWebinarOctober102023", "Announcement", schemaGroup.branchId, schemaGroup.schemaId);
          Curry._1(setVisibility, (function (param) {
                  return /* Entering */1;
                }));
          var timeoutId = setTimeout((function (param) {
                  return Curry._1(setVisibility, (function (param) {
                                return /* Visible */2;
                              }));
                }), 300);
          return (function (param) {
                    clearTimeout(timeoutId);
                    
                  });
        }), []);
  React.useEffect((function () {
          if (visibility !== /* Leaving */3) {
            return ;
          }
          var timeoutId = setTimeout((function (param) {
                  return Curry._1(setVisibility, (function (param) {
                                return /* Hidden */0;
                              }));
                }), 300);
          return (function (param) {
                    clearTimeout(timeoutId);
                    
                  });
        }), [visibility]);
  var handleClose = function (param) {
    AnalyticsRe.overlayItemInteraction(schemaGroup, "Dismiss", undefined, "Banner", "DataQualityWebinarOctober102023", "Announcement", schemaGroup.branchId, schemaGroup.schemaId);
    AnalyticsRe.promoBannerInteracted("InMinusapp", path, Router.getPathName(undefined), "Dismiss");
    Dom_storage.setItem(AnalyticsRe.overlayItemNameToJs("DataQualityWebinarOctober102023"), "dismissed", localStorage);
    return Curry._1(setVisibility, (function (param) {
                  return /* Leaving */3;
                }));
  };
  var handleLinkClick = function (linkName) {
    AnalyticsRe.overlayItemInteraction(schemaGroup, "ClickLink", linkName, "Banner", "DataQualityWebinarOctober102023", "Announcement", schemaGroup.branchId, schemaGroup.schemaId);
    return AnalyticsRe.promoBannerInteracted("InMinusapp", path, Router.getPathName(undefined), "ClickLink");
  };
  if (visibility !== 0) {
    return React.createElement("div", {
                className: rootStyles
              }, React.createElement("aside", {
                    className: announcementStyles(visibility)
                  }, React.createElement("div", undefined, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Medium",
                            color: Styles.Color.white,
                            children: React.createElement("a", {
                                  className: linkStyles,
                                  href: href,
                                  rel: "noopener",
                                  target: "_blank",
                                  onClick: (function (param) {
                                      return handleLinkClick("Announcement");
                                    })
                                }, "Discover why setting up your data correctly from the start is a proactive, cross-functional team effort, not just a reactive task for analysts.")
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.height(Css.px(4)),
                                  tl: /* [] */0
                                })
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Regular",
                            color: Styles.Color.white,
                            children: null
                          }, "Join us on October 10th @ 8 AM PDT / 17.00 CET to learn how 👉 ", React.createElement("a", {
                                className: underlinedLinkStyles,
                                href: href,
                                rel: "noopener",
                                target: "_blank",
                                onClick: (function (param) {
                                    return handleLinkClick("RegisterNow");
                                  })
                              }, "Register Now!"))), React.createElement(Spacer.make, {
                        width: 16
                      }), React.createElement("button", {
                        className: closeButtonStyles,
                        onClick: handleClose
                      }, React.createElement(IconX.make, {}))));
  } else {
    return null;
  }
}

var announcementName = "DataQualityWebinarOctober102023";

var make = Announcement;

exports.announcementName = announcementName;
exports.expiryDate = expiryDate;
exports.bannerColor = bannerColor;
exports.path = path;
exports.href = href;
exports.rootStyles = rootStyles;
exports.announcementStyles = announcementStyles;
exports.linkStyles = linkStyles;
exports.underlinedLinkStyles = underlinedLinkStyles;
exports.closeButtonStyles = closeButtonStyles;
exports.make = make;
/* expiryDate Not a pure module */
