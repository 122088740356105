// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function getSize(size) {
  if (typeof size === "object") {
    return size.VAL;
  } else if (size === "medium") {
    return 20;
  } else if (size === "small") {
    return 16;
  } else {
    return 24;
  }
}

function isMulticolor(icon) {
  return icon === "slack";
}

function color(color$1) {
  return Css.selector(" path", {
              hd: Css_Legacy_Core.SVG.fill(color$1),
              tl: /* [] */0
            });
}

function styles(styles$1) {
  return Css.selector(" path", styles$1);
}

function Icon(Props) {
  var type_ = Props.type_;
  var sizeOpt = Props.size;
  var color = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "small";
  var fill = Belt_Option.map(color, Styles.Color.toString);
  var tmp;
  if (type_ === "book") {
    var tmp$1 = {
      d: "M10.8 17.5v1.7H9.2v-1.7H2.5a.8.8 0 0 1-.8-.8V3.3c0-.4.3-.8.8-.8h5c1 0 1.9.4 2.5 1.1.6-.7 1.5-1.1 2.5-1.1h5c.5 0 .8.4.8.8v13.4c0 .4-.3.8-.8.8h-6.7Zm5.9-1.7V4.2h-4.2c-1 0-1.7.7-1.7 1.6v10h5.9Zm-7.5 0v-10c0-.9-.8-1.6-1.7-1.6H3.3v11.6h5.9Z"
    };
    if (fill !== undefined) {
      tmp$1.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$1);
  } else if (type_ === "code") {
    var tmp$2 = {
      d: "m19.17 10-5.9 5.9-1.17-1.19L16.8 10 12.1 5.29l1.17-1.18 5.9 5.89ZM3.19 10l4.71 4.71-1.17 1.18L.83 10l5.9-5.9L7.9 5.3 3.2 10Z"
    };
    if (fill !== undefined) {
      tmp$2.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$2);
  } else if (type_ === "copy") {
    var tmp$3 = {
      d: "M5.83 5V2.5a.83.83 0 0 1 .84-.83h10a.83.83 0 0 1 .83.83v11.67a.83.83 0 0 1-.83.83h-2.5v2.5c0 .46-.38.83-.84.83h-10a.83.83 0 0 1-.83-.83V5.83c0-.46.38-.83.84-.83h2.5ZM4.17 6.67v10h8.33v-10H4.17ZM7.5 5h6.67v8.33h1.66v-10H7.5V5Z"
    };
    if (fill !== undefined) {
      tmp$3.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$3);
  } else if (type_ === "splitDiff") {
    var tmp$4 = {
      clipRule: "evenodd",
      d: "M17.5 2.5h-15a.83.83 0 0 0-.83.83v13.34a.83.83 0 0 0 .83.83h15a.83.83 0 0 0 .83-.83V3.33a.83.83 0 0 0-.83-.83ZM3.33 15.83V4.17h5.84v11.66H3.33Zm7.5 0h5.84V4.17h-5.84v11.66Z",
      fillRule: "evenodd"
    };
    if (fill !== undefined) {
      tmp$4.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$4);
  } else if (type_ === "link") {
    var tmp$5 = {
      d: "m15.3033 12.9467-1.1783-1.18 1.1783-1.1784a4.16737 4.16737 0 0 0 .9109-1.35198 4.1675 4.1675 0 0 0 .3224-1.59803 4.16634 4.16634 0 0 0-1.2204-2.9554 4.16726 4.16726 0 0 0-1.3559-.90496 4.16685 4.16685 0 0 0-3.1975.007 4.16733 4.16733 0 0 0-1.352.91089L8.23247 5.87499 7.0533 4.69666l1.18-1.17834a5.83362 5.83362 0 0 1 4.125-1.70863 5.83359 5.83359 0 0 1 5.8336 5.83363c0 1.54718-.6146 3.03098-1.7086 4.12498l-1.1792 1.1784h-.0008Zm-2.3567 2.3566-1.1791 1.1784c-1.094 1.094-2.57786 1.7086-4.12503 1.7086a5.83367 5.83367 0 0 1-4.125-1.7086c-1.09402-1.0941-1.70863-2.5779-1.70863-4.125 0-1.5472.61461-3.03103 1.70863-4.12504l1.17917-1.17834 1.17833 1.18-1.17833 1.17834a4.1662 4.1662 0 0 0-.9109 1.35204 4.16646 4.16646 0 0 0-.007 3.1974c.20943.5072.51697.968.90496 1.356.388.388.84881.6955 1.35598.905a4.16721 4.16721 0 0 0 1.59943.3154 4.16749 4.16749 0 0 0 1.59802-.3224 4.16891 4.16891 0 0 0 1.35197-.9109l1.1784-1.1784 1.1791 1.1784v-.0009Zm-.59-8.83914 1.1792 1.17916L7.6433 13.535l-1.17916-1.1783 5.89246-5.89171v-.00083Z"
    };
    if (fill !== undefined) {
      tmp$5.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$5);
  } else if (type_ === "more") {
    var tmp$6 = {
      d: "M10 2.5c-.69 0-1.25.56-1.25 1.25S9.31 5 10 5s1.25-.56 1.25-1.25S10.69 2.5 10 2.5ZM10 15c-.69 0-1.25.56-1.25 1.25S9.31 17.5 10 17.5s1.25-.56 1.25-1.25S10.69 15 10 15Zm0-6.25c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25Z"
    };
    if (fill !== undefined) {
      tmp$6.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$6);
  } else if (type_ === "close") {
    var tmp$7 = {
      d: "m10 8.82 4.13-4.12 1.17 1.17L11.18 10l4.12 4.13-1.18 1.17L10 11.18 5.87 15.3 4.7 14.12 8.82 10 4.7 5.87 5.87 4.7 10 8.82Z"
    };
    if (fill !== undefined) {
      tmp$7.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$7);
  } else if (type_ === "plus") {
    var tmp$8 = {
      d: "M9.16669 9.16667V4.16667H10.8334V9.16667H15.8334V10.8333H10.8334V15.8333H9.16669V10.8333H4.16669V9.16667H9.16669Z"
    };
    if (fill !== undefined) {
      tmp$8.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$8);
  } else if (type_ === "avoSmall") {
    tmp = React.createElement("svg", {
          fill: "none",
          viewBox: "0 0 128 128",
          xmlns: "http://www.w3.org/2000/svg"
        }, React.createElement("path", {
              d: "M0 0h128v128H0z",
              fill: "#232628"
            }), React.createElement("path", {
              clipRule: "evenodd",
              d: "M63.21 35.58a28.42 28.42 0 0 0-28.39 28.39 28.42 28.42 0 0 0 28.4 28.39 28.42 28.42 0 0 0 28.38-28.4 28.42 28.42 0 0 0-28.39-28.38Zm0 64.13a35.79 35.79 0 0 1-35.74-35.74A35.79 35.79 0 0 1 63.2 28.22a35.79 35.79 0 0 1 35.75 35.75A35.79 35.79 0 0 1 63.21 99.7Z",
              fill: "#fff",
              fillRule: "evenodd"
            }), React.createElement("path", {
              clipRule: "evenodd",
              d: "M89.5 99.78h11.03V28.22H89.5v71.56Z",
              fill: "#EC008C",
              fillRule: "evenodd"
            }));
  } else if (type_ === "simpleCheckmark") {
    var tmp$9 = {
      d: "M8.3 12.6 16 5l1.2 1.2L8.3 15 3 9.7l1.2-1.2 4.1 4.1Z"
    };
    if (fill !== undefined) {
      tmp$9.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$9);
  } else if (type_ === "verticalMinus") {
    var tmp$10 = {
      d: "M11 4v12H9V4z"
    };
    if (fill !== undefined) {
      tmp$10.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$10);
  } else if (type_ === "time") {
    var tmp$11 = {
      d: "M10 18.33a8.33 8.33 0 1 1 0-16.66 8.33 8.33 0 0 1 0 16.66Zm0-1.66a6.67 6.67 0 1 0 0-13.34 6.67 6.67 0 0 0 0 13.34Zm.83-6.67h3.34v1.67h-5V5.83h1.66V10Z"
    };
    if (fill !== undefined) {
      tmp$11.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$11);
  } else if (type_ === "chevronLeft" || type_ === "chevronDown" || type_ === "chevronRight" || type_ === "chevronUp") {
    var tmp$12 = {
      d: "M10.98 10 6.85 5.87 8.03 4.7l5.3 5.3-5.3 5.3-1.18-1.18L10.98 10Z"
    };
    if (fill !== undefined) {
      tmp$12.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$12);
  } else if (type_ === "lightning") {
    var tmp$13 = {
      d: "M10.8 7.5h6.7L9.2 20v-7.5H3.3L10.8 0v7.5ZM9.2 9.2V6l-3 4.8h4.6v3.7l3.6-5.3H9.2Z"
    };
    if (fill !== undefined) {
      tmp$13.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$13);
  } else if (type_ === "arrowTopLeft" || type_ === "arrowTopRight" || type_ === "arrowLeft" || type_ === "arrowDownRight" || type_ === "arrowDown" || type_ === "arrowTop" || type_ === "arrowRight" || type_ === "arrowDownLeft") {
    var tmp$14 = {
      d: "M13.48 9.17 9 4.7l1.18-1.18L16.66 10l-6.49 6.48-1.17-1.18 4.47-4.47H3.33V9.17h10.15Z"
    };
    if (fill !== undefined) {
      tmp$14.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$14);
  } else if (type_ === "search") {
    var tmp$15 = {
      d: "m15.03 13.85 3.56 3.57-1.17 1.17-3.57-3.56a7.5 7.5 0 1 1 1.18-1.18Zm-1.68-.62a5.83 5.83 0 1 0-.13.13l.13-.13Z"
    };
    if (fill !== undefined) {
      tmp$15.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement("g", {
              clipPath: "url(#iconSearch)"
            }, React.createElement("path", tmp$15)), React.createElement("defs", undefined, React.createElement("clipPath", {
                  id: "iconSearch"
                }, React.createElement("path", {
                      d: "M0 0h20v20H0z",
                      fill: "#fff"
                    }))));
  } else if (type_ === "folder") {
    var tmp$16 = {
      clipRule: "evenodd",
      d: "M4.5 3.33h11V5h-11V3.33ZM4.53 8.33 5.87 15h8.26l1.34-6.67H4.53Zm11.9-1.66c.55 0 .97.5.86 1.04l-1.65 8.25c-.08.41-.44.7-.86.7H5.22a.87.87 0 0 1-.86-.7L2.7 7.71a.87.87 0 0 1 .86-1.04h12.86Z",
      fillRule: "evenodd"
    };
    if (fill !== undefined) {
      tmp$16.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement("path", tmp$16));
  } else if (type_ === "share") {
    var tmp$17 = {
      d: "M10.83 11.67H9.17a7.5 7.5 0 0 0-6.64 4 8.33 8.33 0 0 1 8.3-9V2.08l8.75 7.09-8.75 7.08v-4.58ZM9.17 10h3.33v2.76l4.43-3.6-4.43-3.58v2.75h-1.67a6.65 6.65 0 0 0-5.04 2.31A9.16 9.16 0 0 1 9.17 10Z"
    };
    if (fill !== undefined) {
      tmp$17.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$17);
  } else if (type_ === "arrowDownRightTurn") {
    var tmp$18 = {
      d: "M5 12.5h8.48l-2.16 2.15 1.18 1.18 4.17-4.16L12.5 7.5l-1.18 1.18 2.16 2.15H6.67V4.17H5v8.33Z"
    };
    if (fill !== undefined) {
      tmp$18.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$18);
  } else if (type_ === "slack") {
    tmp = React.createElement(React.Fragment, undefined, React.createElement("path", {
              d: "M4.20196 12.6385C4.20196 13.7948 3.25733 14.7394 2.10098 14.7394C0.944626 14.7394 0 13.7948 0 12.6385C0 11.4821 0.944626 10.5375 2.10098 10.5375H4.20196V12.6385Z",
              fill: "#E01E5A"
            }), React.createElement("path", {
              d: "M5.26059 12.6385C5.26059 11.4821 6.20521 10.5375 7.36157 10.5375C8.51792 10.5375 9.46254 11.4821 9.46254 12.6385V17.899C9.46254 19.0554 8.51792 20 7.36157 20C6.20521 20 5.26059 19.0554 5.26059 17.899V12.6385Z",
              fill: "#E01E5A"
            }), React.createElement("path", {
              d: "M7.36157 4.20195C6.20521 4.20195 5.26059 3.25733 5.26059 2.10098C5.26059 0.944625 6.20521 0 7.36157 0C8.51792 0 9.46254 0.944625 9.46254 2.10098V4.20195H7.36157Z",
              fill: "#36C5F0"
            }), React.createElement("path", {
              d: "M7.36156 5.26056C8.51791 5.26056 9.46254 6.20519 9.46254 7.36154C9.46254 8.51789 8.51791 9.46252 7.36156 9.46252H2.10098C0.944625 9.46252 0 8.51789 0 7.36154C0 6.20519 0.944625 5.26056 2.10098 5.26056H7.36156Z",
              fill: "#36C5F0"
            }), React.createElement("path", {
              d: "M15.798 7.36154C15.798 6.20519 16.7427 5.26056 17.899 5.26056C19.0554 5.26056 20 6.20519 20 7.36154C20 8.51789 19.0554 9.46252 17.899 9.46252H15.798V7.36154Z",
              fill: "#2EB67D"
            }), React.createElement("path", {
              d: "M14.7394 7.36157C14.7394 8.51792 13.7948 9.46254 12.6384 9.46254C11.4821 9.46254 10.5374 8.51792 10.5374 7.36157V2.10098C10.5374 0.944626 11.4821 0 12.6384 0C13.7948 0 14.7394 0.944626 14.7394 2.10098V7.36157Z",
              fill: "#2EB67D"
            }), React.createElement("path", {
              d: "M12.6384 15.7981C13.7948 15.7981 14.7394 16.7427 14.7394 17.8991C14.7394 19.0554 13.7948 20 12.6384 20C11.4821 20 10.5374 19.0554 10.5374 17.8991V15.7981H12.6384Z",
              fill: "#ECB22E"
            }), React.createElement("path", {
              d: "M12.6384 14.7394C11.4821 14.7394 10.5374 13.7948 10.5374 12.6385C10.5374 11.4821 11.4821 10.5375 12.6384 10.5375H17.899C19.0554 10.5375 20 11.4821 20 12.6385C20 13.7948 19.0554 14.7394 17.899 14.7394H12.6384Z",
              fill: "#ECB22E"
            }));
  } else if (type_ === "calendar") {
    var tmp$19 = {
      d: "M14.17 2.5h3.33a.83.83 0 0 1 .83.83v13.34a.83.83 0 0 1-.83.83h-15a.83.83 0 0 1-.83-.83V3.33a.83.83 0 0 1 .83-.83h3.33V.83H7.5V2.5h5V.83h1.67V2.5ZM12.5 4.17h-5v1.66H5.83V4.17h-2.5V7.5h13.34V4.17h-2.5v1.66H12.5V4.17Zm4.17 5H3.33v6.66h13.34V9.17Z"
    };
    if (fill !== undefined) {
      tmp$19.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement("g", {
              clipPath: "url(#iconCalendar)"
            }, React.createElement("path", tmp$19)), React.createElement("defs", undefined, React.createElement("clipPath", {
                  id: "iconCalendar"
                }, React.createElement("path", {
                      d: "M0 0h20v20H0z",
                      fill: "#fff"
                    }))));
  } else if (type_ === "sidebar") {
    var tmp$20 = {
      d: "M2.5 2.5h15a.83.83 0 0 1 .83.83v13.34a.83.83 0 0 1-.83.83h-15a.83.83 0 0 1-.83-.83V3.33a.83.83 0 0 1 .83-.83Zm5 1.67v11.66h9.17V4.17H7.5Z"
    };
    if (fill !== undefined) {
      tmp$20.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$20);
  } else if (type_ === "filter") {
    var tmp$21 = {
      d: "M2.5 3.3h15V5h-15V3.3ZM5 9.2h10v1.6H5V9.2ZM7.5 15h5v1.7h-5V15Z"
    };
    if (fill !== undefined) {
      tmp$21.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$21);
  } else if (type_ === "unifiedDiff") {
    var tmp$22 = {
      clipRule: "evenodd",
      d: "M17.5 2.5h-15a.83.83 0 0 0-.83.83v13.34a.83.83 0 0 0 .83.83h15a.83.83 0 0 0 .83-.83V3.33a.83.83 0 0 0-.83-.83ZM3.33 9.17v-5h13.34v5H3.33Zm0 1.66v5h13.34v-5H3.33Z",
      fillRule: "evenodd"
    };
    if (fill !== undefined) {
      tmp$22.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$22);
  } else if (type_ === "minus") {
    var tmp$23 = {
      d: "M4 9h12v2H4z"
    };
    if (fill !== undefined) {
      tmp$23.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$23);
  } else if (type_ === "import") {
    var tmp$24 = {
      d: "M15.33 12.83h-1.66V17h-10V3.67h10v4.16h1.66v-5A.83.83 0 0 0 14.5 2H2.83a.83.83 0 0 0-.83.83v15c0 .46.37.84.83.84H14.5c.46 0 .83-.38.83-.84v-5Zm-5-3.33V7l-4.16 3.33 4.16 3.34v-2.5H17V9.5h-6.67Z"
    };
    if (fill !== undefined) {
      tmp$24.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$24);
  } else if (type_ === "warning") {
    var tmp$25 = {
      d: "m10.72 2.5 7.94 13.75a.83.83 0 0 1-.72 1.25H2.06a.83.83 0 0 1-.72-1.25L9.28 2.5a.83.83 0 0 1 1.44 0ZM3.5 15.83h13L10 4.58 3.5 15.83Zm5.67-2.5h1.66V15H9.17v-1.67Zm0-5.83h1.66v4.17H9.17V7.5Z"
    };
    if (fill !== undefined) {
      tmp$25.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$25);
  } else if (type_ === "table") {
    var tmp$26 = {
      d: "M3.3 6.7h13.4V4.2H3.3v2.5Zm8.4 9.1V8.3H8.3v7.5h3.4Zm1.6 0h3.4V8.3h-3.4v7.5Zm-6.6 0V8.3H3.3v7.5h3.4ZM2.5 2.5h15a.8.8 0 0 1 .8.8v13.4a.8.8 0 0 1-.8.8h-15a.8.8 0 0 1-.8-.8V3.3a.8.8 0 0 1 .8-.8Z"
    };
    if (fill !== undefined) {
      tmp$26.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$26);
  } else if (type_ === "settings") {
    var tmp$27 = {
      d: "M2.78 14.17a8.35 8.35 0 0 1-.81-1.94 2.5 2.5 0 0 0 0-4.46 8.32 8.32 0 0 1 2.08-3.61 2.5 2.5 0 0 0 3.86-2.23 8.33 8.33 0 0 1 4.18 0 2.5 2.5 0 0 0 3.86 2.23 8.33 8.33 0 0 1 2.08 3.61 2.5 2.5 0 0 0 0 4.46 8.33 8.33 0 0 1-2.08 3.61 2.5 2.5 0 0 0-3.86 2.23c-1.37.35-2.8.35-4.18 0a2.5 2.5 0 0 0-3.86-2.23c-.49-.5-.92-1.06-1.27-1.67Zm4.72.16a4.16 4.16 0 0 1 1.87 2.3c.42.05.84.05 1.25 0a4.16 4.16 0 0 1 4.82-2.77c.24-.34.45-.7.62-1.08a4.16 4.16 0 0 1 0-5.56 6.77 6.77 0 0 0-.62-1.08 4.16 4.16 0 0 1-4.81-2.78 6.65 6.65 0 0 0-1.25 0 4.16 4.16 0 0 1-4.82 2.78c-.24.34-.45.7-.62 1.08a4.16 4.16 0 0 1 0 5.56c.17.38.38.74.62 1.08 1-.21 2.05-.04 2.94.47ZM10 12.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Zm0-1.67a.83.83 0 1 0 0-1.66.83.83 0 0 0 0 1.66Z"
    };
    if (fill !== undefined) {
      tmp$27.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$27);
  } else if (type_ === "noDiff") {
    var tmp$28 = {
      d: "M2.5 2.5h15a.83.83 0 0 1 .84.83v13.34a.83.83 0 0 1-.84.83h-15a.83.83 0 0 1-.83-.83V3.33a.83.83 0 0 1 .83-.83Zm.83 1.67v11.66h13.34V4.17H3.33Z"
    };
    if (fill !== undefined) {
      tmp$28.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$28);
  } else if (type_ === "braces") {
    var tmp$29 = {
      d: "M3.33 15v-3.08a1.25 1.25 0 0 0-1.25-1.25h-.41V9.33h.41a1.25 1.25 0 0 0 1.25-1.25V5a2.5 2.5 0 0 1 2.5-2.5h.84v1.67h-.84A.83.83 0 0 0 5 5v3.42A1.67 1.67 0 0 1 3.86 10 1.67 1.67 0 0 1 5 11.58V15a.83.83 0 0 0 .83.83h.84v1.67h-.84a2.5 2.5 0 0 1-2.5-2.5Zm13.34-3.08V15a2.5 2.5 0 0 1-2.5 2.5h-.84v-1.67h.84A.83.83 0 0 0 15 15v-3.42A1.67 1.67 0 0 1 16.14 10 1.67 1.67 0 0 1 15 8.42V5a.83.83 0 0 0-.83-.83h-.84V2.5h.84a2.5 2.5 0 0 1 2.5 2.5v3.08a1.25 1.25 0 0 0 1.25 1.25h.41v1.34h-.41a1.25 1.25 0 0 0-1.25 1.25Z"
    };
    if (fill !== undefined) {
      tmp$29.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$29);
  } else if (type_ === "branch") {
    var tmp$30 = {
      d: "M5.92 12.68a2.5 2.5 0 1 1-1.75-.04V7.36a2.5 2.5 0 1 1 1.66 0V10c.7-.52 1.56-.83 2.5-.83h3.34a2.5 2.5 0 0 0 2.4-1.85 2.5 2.5 0 1 1 1.7.06 4.17 4.17 0 0 1-4.1 3.45H8.33a2.5 2.5 0 0 0-2.4 1.85ZM5 14.17a.83.83 0 1 0 0 1.66.83.83 0 0 0 0-1.66Zm0-10a.83.83 0 1 0 0 1.66.83.83 0 0 0 0-1.66Zm10 0a.83.83 0 1 0 0 1.66.83.83 0 0 0 0-1.66Z"
    };
    if (fill !== undefined) {
      tmp$30.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$30);
  } else if (type_ === "information") {
    var tmp$31 = {
      d: "M10 18.33a8.33 8.33 0 1 1 0-16.66 8.33 8.33 0 0 1 0 16.66Zm0-1.66a6.67 6.67 0 1 0 0-13.34 6.67 6.67 0 0 0 0 13.34ZM9.17 5.83h1.66V7.5H9.17V5.83Zm0 3.34h1.66v5H9.17v-5Z"
    };
    if (fill !== undefined) {
      tmp$31.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$31);
  } else if (type_ === "roundedCheckmark") {
    tmp = React.createElement("path", {
          d: "M10 18.33a8.33 8.33 0 1 1 0-16.66 8.33 8.33 0 0 1 0 16.66Zm0-1.66a6.67 6.67 0 1 0 0-13.34 6.67 6.67 0 0 0 0 13.34Zm-.83-3.34L5.63 9.8l1.18-1.18 2.36 2.36 4.71-4.72 1.18 1.18-5.9 5.9Z"
        });
  } else if (type_ === "addImage") {
    var tmp$32 = {
      d: "M17.5 12.5V15H20v1.67h-2.5v2.5h-1.67v-2.5h-2.5V15h2.5v-2.5h1.67Zm0-10c.46 0 .83.37.83.83v7.5h-1.66V4.17H3.33v11.66l8.34-8.33 2.5 2.5v2.36l-2.5-2.5-5.98 5.97h5.98v1.67H2.49a.83.83 0 0 1-.82-.83V3.33a.83.83 0 0 1 .82-.83h15.02ZM6.68 5.83a1.67 1.67 0 1 1 0 3.34 1.67 1.67 0 0 1 0-3.34Z"
    };
    if (fill !== undefined) {
      tmp$32.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$32);
  } else if (type_ === "equalizer") {
    var tmp$33 = {
      d: "M5.14 15a2.5 2.5 0 0 1 4.72 0h8.47v1.67H9.86a2.5 2.5 0 0 1-4.72 0H1.67V15h3.47Zm5-5.83a2.5 2.5 0 0 1 4.72 0h3.47v1.66h-3.47a2.5 2.5 0 0 1-4.72 0H1.67V9.17h8.47Zm-5-5.84a2.5 2.5 0 0 1 4.72 0h8.47V5H9.86a2.5 2.5 0 0 1-4.72 0H1.67V3.33h3.47ZM7.5 5a.83.83 0 1 0 0-1.67.83.83 0 0 0 0 1.67Zm5 5.83a.83.83 0 1 0 0-1.66.83.83 0 0 0 0 1.66Zm-5 5.84a.83.83 0 1 0 0-1.67.83.83 0 0 0 0 1.67Z"
    };
    if (fill !== undefined) {
      tmp$33.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$33);
  } else if (type_ === "presentation") {
    var tmp$34 = {
      d: "M10.8 15v1.7h3.4v1.6H5.8v-1.6h3.4V15H2.5a.8.8 0 0 1-.8-.8V3.3c0-.4.3-.8.8-.8h15c.5 0 .8.4.8.8v10.9c0 .4-.3.8-.8.8h-6.7ZM3.3 4.2v9.1h13.4V4.2H3.3Zm5 2 4.2 2.5-4.2 2.6v-5Z"
    };
    if (fill !== undefined) {
      tmp$34.fill = Caml_option.valFromOption(fill);
    }
    tmp = React.createElement("path", tmp$34);
  } else {
    tmp = React.createElement(React.Fragment, undefined, React.createElement("g", {
              clipPath: "url(#bell)"
            }, React.createElement("path", {
                  d: "M18.3 16.7H1.7V15h.8V9.2a7.5 7.5 0 1 1 15 0V15h.8v1.7ZM4.2 15h11.6V9.2a5.8 5.8 0 1 0-11.6 0V15Zm3.7 2.5h4.2a2 2 0 1 1-4.2 0Z",
                  fill: "#434A54"
                })), React.createElement("defs", undefined, React.createElement("clipPath", {
                  id: "bell"
                }, React.createElement("path", {
                      d: "M0 0h20v20H0z",
                      fill: "#fff"
                    }))));
  }
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css.flexShrink(0.0),
                    tl: {
                      hd: Css.transform(Css.rotate(Css.deg(type_ === "chevronUp" ? 270.0 : (
                                      type_ === "arrowDownLeft" ? 135.0 : (
                                          type_ === "arrowRight" ? 0.0 : (
                                              type_ === "arrowDown" || type_ === "chevronDown" ? 90.0 : (
                                                  type_ === "arrowLeft" || type_ === "chevronLeft" ? 180.0 : (
                                                      type_ === "arrowTop" ? -90.0 : (
                                                          type_ === "arrowDownRight" ? 45.0 : (
                                                              type_ === "arrowTopRight" ? -45.0 : (
                                                                  type_ === "arrowTopLeft" ? -135.0 : 0.0
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )))),
                      tl: /* [] */0
                    }
                  }),
              height: String(getSize(size)),
              width: String(getSize(size)),
              fill: "none",
              viewBox: "0 0 20 20",
              xmlns: "http://www.w3.org/2000/svg"
            }, tmp);
}

var make = Icon;

exports.getSize = getSize;
exports.isMulticolor = isMulticolor;
exports.color = color;
exports.styles = styles;
exports.make = make;
/* Css Not a pure module */
