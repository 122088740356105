// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.padding2(Css.px(0), Css.px(10)),
        tl: /* [] */0
      }
    });

var linkStyles = Curry._1(Css.style, {
      hd: Css.display("block"),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.padding2(Css.px(10), Css.px(15)),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
            tl: {
              hd: Css.backgroundColor("transparent"),
              tl: {
                hd: Css.color(Styles.Color.grey90),
                tl: {
                  hd: Css.hover({
                        hd: Css.borderColor(Styles.Color.blue),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.blueBg),
                          tl: {
                            hd: Css.color(Styles.Color.blueSecondary),
                            tl: /* [] */0
                          }
                        }
                      }),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.regular),
                    tl: {
                      hd: Css.margin2(Css.px(5), Css.px(0)),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$default
                            }, undefined, undefined, "all"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function EventMetrics(Props) {
  var metrics = Props.metrics;
  return React.createElement("div", {
              className: rootStyles
            }, Belt_List.toArray(Belt_List.mapU(metrics, (function (metric) {
                        return React.createElement(Link.make, {
                                    path: Router.Link.addDrawerItem(undefined, {
                                          NAME: "metric",
                                          VAL: [
                                            metric.id,
                                            undefined
                                          ]
                                        }),
                                    className: linkStyles,
                                    children: React.createElement($$Text.make, {
                                          size: "Medium",
                                          weight: "Semi",
                                          children: metric.name === "" ? "Untitled Metric" : metric.name
                                        }),
                                    key: metric.id
                                  });
                      }))));
}

var make = EventMetrics;

exports.rootStyles = rootStyles;
exports.linkStyles = linkStyles;
exports.make = make;
/* rootStyles Not a pure module */
