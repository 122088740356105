// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Case = require("../../../bs-case/src/case.bs.js");
var Icon = require("../Icon.bs.js");
var Link = require("../Link.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Toast = require("../Toast.bs.js");
var React = require("react");
var Models = require("../Models.bs.js");
var Router = require("../Router.bs.js");
var Select = require("../Select.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var Tooltip = require("../Tooltip.bs.js");
var Shortid = require("shortid");
var AvoUtils = require("../../../shared/utils/AvoUtils.bs.js");
var Firebase = require("../../../bs-firestore/src/Firebase.bs.js");
var Markdown = require("../Markdown.bs.js");
var Tooltips = require("../markdown/tooltips/tooltips.bs.js");
var AvoConfig = require("../../../shared/utils/AvoConfig.bs.js");
var AvoLimits = require("../AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameInput = require("../NameInput.bs.js");
var Workspace = require("../../../model/src/Workspace.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("../stateUtils.bs.js");
var TextButton = require("../TextButton.bs.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ContextMenu = require("../ContextMenu.bs.js");
var FeatureFlag = require("../../../shared/FeatureFlag.bs.js");
var NamedBranch = require("../NamedBranch.bs.js");
var CommentInput = require("../CommentInput.bs.js");
var DrawerHeader = require("./DrawerHeader.bs.js");
var OpsWithState = require("../OpsWithState.bs.js");
var PropertyRule = require("../PropertyRule.bs.js");
var EventAutofill = require("../EventAutofill.bs.js");
var FirebaseUtils = require("../firebaseUtils.bs.js");
var PlanLightning = require("../PlanLightning.bs.js");
var PropertyUtils = require("../PropertyUtils.bs.js");
var ViewerContext = require("../ViewerContext.bs.js");
var ActionsReducer = require("../actionsReducer.bs.js");
var AnalyticsUtils = require("../analyticsUtils.bs.js");
var AppFeatureFlag = require("../AppFeatureFlag.bs.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var IconDescription = require("../IconDescription.bs.js");
var WorkspaceContext = require("../WorkspaceContext.bs.js");
var GlobalSendContext = require("../GlobalSendContext.bs.js");
var ObjectActivityLog = require("../ObjectActivityLog.bs.js");
var TrackingPlanModel = require("../../../model/src/TrackingPlanModel.bs.js");
var BeltListExtensions = require("../BeltListExtensions.bs.js");
var DiscrepancyContext = require("../DiscrepancyContext.bs.js");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");
var SendActionsContext = require("../SendActionsContext.bs.js");
var PropertyModalEvents = require("../PropertyModalEvents.bs.js");
var PropertyModalSources = require("../PropertyModalSources.bs.js");
var ModalConstraintEditor = require("../ModalConstraintEditor.bs.js");
var SystemPropertySources = require("./SystemPropertySources.bs.js");
var CopyTextToClipboard = require("copy-text-to-clipboard");
var ReactTextareaAutosize = require("react-textarea-autosize").default;

var rootStyles = Curry._1(Css.style, {
      hd: Css.borderRadius(Styles.Border.radius),
      tl: {
        hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
        tl: {
          hd: Css.marginLeft(Css.px(60)),
          tl: {
            hd: Css.marginRight(Css.px(20)),
            tl: /* [] */0
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("baseline"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.grey20),
            tl: {
              hd: Css.padding4(Css.px(12), Css.px(16), Css.px(12), Css.px(16)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var inputStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(8)),
      tl: {
        hd: Css.marginTop(Css.px(0)),
        tl: {
          hd: Css.marginBottom(Css.px(0)),
          tl: {
            hd: Css.marginRight(Css.px(0)),
            tl: /* [] */0
          }
        }
      }
    });

var rowStyles = Curry._1(Css.style, {
      hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey30),
      tl: {
        hd: Css.padding4(Css.px(12), Css.px(16), Css.px(12), Css.px(16)),
        tl: /* [] */0
      }
    });

function PropertyDetails$SentInObjectProperties(Props) {
  var model = Props.model;
  var property = Props.property;
  var inProperties = Props.inProperties;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var handleChange = function (param) {
    var newOptional = !property.optionalWhenInObject;
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    return AnalyticsRe.propertyConfigured(AvoUtils.getAnalyticsPropertyGroup(model, property), AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.propertyConfiguration("Presence", "UpdateNestedObjectKeyPresence", undefined, undefined, undefined, undefined, undefined, undefined, undefined, property.optionalWhenInObject ? "SometimesSent" : "AlwaysSent", newOptional ? "SometimesSent" : "AlwaysSent"), NamedBranch.getId(branch), schemaGroup.schemaId);
                  }),
                undefined,
                [[
                    {
                      NAME: "UpdatePropertyOptionalWhenInObject",
                      VAL: [
                        property.id,
                        newOptional
                      ]
                    },
                    {
                      propertyId: property.id,
                      propertyQuery: property.id
                    }
                  ]]
              ]);
  };
  return React.createElement("section", {
              className: rootStyles
            }, React.createElement("header", {
                  className: headerStyles
                }, React.createElement($$Text.make, {
                      element: "H1",
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.grey80,
                      children: "Sent as part of " + (String(Belt_List.length(inProperties)) + (" Object " + (
                            Belt_List.length(inProperties) === 1 ? "Property" : "Properties"
                          )))
                    }), React.createElement($$Text.make, {
                      element: "Label",
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.grey70,
                      children: React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: /* [] */0
                                  }
                                })
                          }, "Optional", React.createElement("input", {
                                className: inputStyles,
                                checked: property.optionalWhenInObject,
                                type: "checkbox",
                                onChange: handleChange
                              }))
                    })), Belt_List.toArray(Belt_List.map(inProperties, (function (param) {
                        return React.createElement("div", {
                                    className: rowStyles
                                  }, React.createElement($$Text.make, {
                                        size: "Medium",
                                        weight: "Semi",
                                        color: Styles.Color.grey70,
                                        children: React.createElement(Link.make, {
                                              path: Router.Link.addDrawerItem(undefined, {
                                                    NAME: "property",
                                                    VAL: [
                                                      param.id,
                                                      undefined
                                                    ]
                                                  }),
                                              children: param.name
                                            })
                                      }));
                      }))));
}

var SentInObjectProperties = {
  rootStyles: rootStyles,
  headerStyles: headerStyles,
  inputStyles: inputStyles,
  rowStyles: rowStyles,
  make: PropertyDetails$SentInObjectProperties
};

function getPropertyTypeName(propertyType, model) {
  if (typeof propertyType !== "number") {
    return Belt_Option.mapWithDefault(StateUtils.getGroupTypeName(propertyType._0, model), "", (function (name) {
                  return name + " ";
                })) + "Group";
  }
  switch (propertyType) {
    case /* SystemProperty */0 :
        return "System";
    case /* EventProperty */1 :
        return "Event";
    case /* UserProperty */2 :
        return "User";
    
  }
}

function PropertyDetails(Props) {
  var currentBranch = Props.currentBranch;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var eventId = Props.eventId;
  var goToCommentId = Props.goToCommentId;
  var member = Props.member;
  var model = Props.model;
  var openBranches = Props.openBranches;
  var propertyId = Props.propertyId;
  var role = Props.role;
  var schema = Props.schema;
  var match = ViewerContext.use(undefined);
  var userId = match.id;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var hasAnyValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("AnyValues");
  var hasLongValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("LongValues");
  var hasObjectsFeatureFlag = AppFeatureFlag.useFeatureFlag("Objects");
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var addToast = Toast.useAddToast(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var match$1 = React.useContext(DiscrepancyContext.context);
  var expectedPropertyCase = match$1.expectedPropertyCase;
  var config = match$1.config;
  var property = Belt_List.head(Belt_List.keepMap(model.properties, (function (x) {
              if (x.TAG === /* PropertyRef */0) {
                return ;
              }
              var property = x._0;
              if (property.id === propertyId) {
                return property;
              }
              
            })));
  var $$event = Belt_Option.flatMap(eventId, (function (id) {
          return Belt_List.getBy(model.events, (function ($$event) {
                        return $$event.id === id;
                      }));
        }));
  var tmp;
  if (property !== undefined) {
    var propertyGroup = AvoUtils.getAnalyticsPropertyGroup(model, property);
    var propertyEvents = StateUtils.eventsSendingProperty(model, undefined, property.id);
    var inObjectProperties = StateUtils.getPropertyParents(property.id, model);
    var filter = member.filter;
    var propertyEvents$1 = filter !== undefined ? Belt_List.keep(propertyEvents, (function ($$event) {
              return Belt_List.some($$event.tags, (function (item) {
                            return Belt_List.some(Belt_List.fromArray(filter.tags), (function (tag) {
                                          return item === tag;
                                        }));
                          }));
            })) : propertyEvents;
    var tmp$1 = {
      propertyId: property.id,
      propertyQuery: property.id
    };
    if (eventId !== undefined) {
      tmp$1.eventId = Caml_option.valFromOption(eventId);
    }
    var context = tmp$1;
    var hasMigratedToAbsence = ActionsReducer.hasMigrated(model, "PropertyOptionalAndExcludedSourcesToAbsence");
    var fromItem_itemId = property.id;
    var fromItem_itemName = property.name;
    var fromItem = {
      itemId: fromItem_itemId,
      itemName: fromItem_itemName,
      itemType: "Property"
    };
    var template = schema.onboardingTemplate;
    var hasSnowplowDestination = Belt_List.someU(model.destinations, (function (destination) {
            var match = destination.type_;
            if (match !== undefined) {
              return match === "Snowplow";
            } else {
              return false;
            }
          }));
    var match$2 = property.type_;
    var tmp$2 = match$2 === "object" && hasSnowplowDestination ? React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.marginRight(Css.px(15)),
                  tl: {
                    hd: Css.fontWeight(Styles.FontWeight.semi),
                    tl: {
                      hd: Css.fontSize(Styles.FontSize.regular),
                      tl: {
                        hd: Css.color(Styles.Color.grey80),
                        tl: /* [] */0
                      }
                    }
                  }
                })
          }, React.createElement("input", {
                id: "snowplow-context",
                checked: property.operation === "SnowplowContext",
                disabled: !Models.Role.canEdit(role),
                type: "checkbox",
                onChange: (function ($$event) {
                    var isSnowplowContext = $$event.target.checked;
                    return Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                [[
                                    {
                                      NAME: "UpdatePropertyOperation",
                                      VAL: [
                                        property.id,
                                        isSnowplowContext ? "SnowplowContext" : "Set"
                                      ]
                                    },
                                    context
                                  ]]
                              ]);
                  })
              }), React.createElement("label", {
                htmlFor: "snowplow-context"
              }, "Snowplow Context")) : null;
    var match$3 = property.sendAs;
    var tmp$3;
    if (match$3 === 0) {
      tmp$3 = React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.fontWeight(Styles.FontWeight.semi),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.regular),
                    tl: {
                      hd: Css.paddingLeft(Css.px(60)),
                      tl: {
                        hd: Css.paddingRight(Css.px(20)),
                        tl: /* [] */0
                      }
                    }
                  }
                })
          }, React.createElement(SystemPropertySources.make, {
                model: model,
                sources: model.sources,
                viewerCanEdit: Models.Role.canEdit(role),
                property: property,
                sendActions: sendActions
              }));
    } else {
      var propertyEventIds = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.mapU(propertyEvents$1, (function (propertyEvent) {
                      return propertyEvent.id;
                    }))));
      tmp$3 = React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.paddingRight(Css.px(20)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(60)),
                    tl: /* [] */0
                  }
                })
          }, hasMigratedToAbsence ? (
              propertyEvents$1 ? React.createElement(PropertyModalEvents.make, {
                      viewerCanEdit: Models.Role.canEdit(role),
                      property: property,
                      sendActions: sendActions,
                      events: propertyEvents$1,
                      sources: model.sources,
                      model: model
                    }) : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          children: "Sent in 0 events"
                        }), React.createElement(Tooltip.WithAnalytics.make, {
                          fromItem: fromItem,
                          title: "Sending the Property to Events",
                          children: React.createElement(Tooltip.Content.make, {
                                children: React.createElement(Markdown.make, {
                                      source: Tooltips.sentInEvents
                                    })
                              })
                        }))
            ) : React.createElement(React.Fragment, undefined, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.regular),
                              tl: {
                                hd: Css.paddingBottom(Css.px(5)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, "Sent In " + String(Belt_List.length(propertyEvents$1)) + " Events", React.createElement(Tooltip.WithAnalytics.make, {
                          fromItem: fromItem,
                          title: "Sending the Property to Events",
                          children: React.createElement(Tooltip.Content.make, {
                                children: React.createElement(Markdown.make, {
                                      source: Tooltips.sentInEvents
                                    })
                              })
                        })), React.createElement("div", undefined, Belt_List.toArray(Belt_List.mapU(propertyEvents$1, (function ($$event) {
                                return React.createElement("div", {
                                            key: $$event.id,
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.width(Css.pct(100)),
                                                    tl: {
                                                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                                                      tl: {
                                                        hd: Css.alignItems("center"),
                                                        tl: {
                                                          hd: Css.justifyContent("spaceBetween"),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                })
                                          }, React.createElement(Link.make, {
                                                path: Router.Link.addDrawerItem(undefined, {
                                                      NAME: "event",
                                                      VAL: [
                                                        $$event.id,
                                                        undefined,
                                                        undefined,
                                                        false
                                                      ]
                                                    }),
                                                className: Curry._1(Css.style, {
                                                      hd: Css.display("block"),
                                                      tl: {
                                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                                        tl: {
                                                          hd: Css.color(Styles.Color.grey70),
                                                          tl: {
                                                            hd: Css.hover({
                                                                  hd: Css.color(Styles.Color.blue),
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: {
                                                              hd: Css.padding2(Css.px(8), Css.px(0)),
                                                              tl: {
                                                                hd: Css.fontSize(Styles.FontSize.regular),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }),
                                                children: $$event.name
                                              }), Models.Role.canEdit(role) ? React.createElement(ContextMenu.make, {
                                                  options: [{
                                                      NAME: "Option",
                                                      VAL: {
                                                        label: "Remove Event",
                                                        onClick: (function (param) {
                                                            var eventId = $$event.id;
                                                            var eventName = $$event.name;
                                                            Curry.app(sendActions, [
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  [[
                                                                      {
                                                                        NAME: "RemovePropertyRefV2",
                                                                        VAL: [
                                                                          eventId,
                                                                          property.id
                                                                        ]
                                                                      },
                                                                      {
                                                                        eventId: eventId,
                                                                        eventQuery: eventId,
                                                                        propertyId: property.id,
                                                                        propertyQuery: property.id
                                                                      }
                                                                    ]]
                                                                ]);
                                                            return AnalyticsRe.propertyRemoved(schemaGroup, propertyGroup, eventId, eventName, "PropertyDetails", schemaGroup.branchId, schemaGroup.schemaId);
                                                          })
                                                      }
                                                    }]
                                                }) : null);
                              }))))), React.createElement(EventAutofill.make, {
                events: Belt_List.keepU(model.events, (function ($$event) {
                        return !Belt_SetString.has(propertyEventIds, $$event.id);
                      })),
                onSelectSuccess: (function (eventId, eventName, branch) {
                    return AnalyticsRe.propertyAdded(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), propertyGroup, currentFilters, eventId, eventName, property.name.length, "New", Case.to_("Sentence", property.name).split(" ").length, property.name.length, "PropertyDetails", NamedBranch.getId(branch), schemaGroup.schemaId);
                  }),
                model: model,
                renderButton: (function (onClick, buttonRef) {
                    return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    height: 10
                                  }), React.createElement(TextButton.make, {
                                    onClick: onClick,
                                    style: "Blue",
                                    size: "Medium",
                                    children: "+ Add Event",
                                    ref: buttonRef
                                  }));
                  }),
                sendActions: sendActions,
                currentFilters: currentFilters,
                eventOrigin: "PropertyDetails",
                selectActions: (function (eventId) {
                    return [[
                              {
                                NAME: "AddPropertyRef",
                                VAL: [
                                  eventId,
                                  property.id
                                ]
                              },
                              {
                                eventId: eventId,
                                eventQuery: eventId,
                                propertyId: property.id,
                                propertyQuery: property.id
                              }
                            ]];
                  })
              }));
    }
    var tmp$4;
    if (Models.Role.canEdit(role)) {
      var match$4 = AvoLimits.NameMapping.computeAvailability(workspace);
      tmp$4 = React.createElement("button", {
            className: Curry._1(Css.merge, {
                  hd: Curry._1(Css.style, Styles.button),
                  tl: {
                    hd: Curry._1(Css.style, {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.color(Styles.Color.blue),
                            tl: {
                              hd: Css.marginTop(Css.px(5)),
                              tl: {
                                hd: Css.padding2(Css.px(2), Css.px(0)),
                                tl: {
                                  hd: Css.width(Css.pct(100)),
                                  tl: {
                                    hd: Css.textAlign("left"),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.regular),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.color(Styles.Color.blueSecondary),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }),
                    tl: /* [] */0
                  }
                }),
            onClick: (function (param) {
                if (AvoLimits.NameMapping.isAvailable(workspace)) {
                  return Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              (function (branch) {
                                  var propertyNameMappingsInSchema = Belt_List.concat(Belt_List.keepMap(model.rules, (function (rule) {
                                              var match = rule.item;
                                              var match$1 = rule.definition;
                                              if (typeof match === "object" && match.NAME === "Property" && typeof match$1 === "object" && match$1.NAME === "NameMapping") {
                                                return match.VAL;
                                              }
                                              
                                            })), {
                                        hd: propertyId,
                                        tl: /* [] */0
                                      });
                                  AnalyticsRe.propertyUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), propertyGroup, "AddRule", undefined, "NameMapping", Belt_List.length(BeltListExtensions.dedupeString(propertyNameMappingsInSchema)), Belt_List.length(propertyNameMappingsInSchema), Belt_List.length(Belt_List.keep(model.rules, (function (rule) {
                                                  var match = rule.item;
                                                  var match$1 = rule.definition;
                                                  if (typeof match === "object" && match.NAME === "Property" && typeof match$1 === "object" && match$1.NAME === "NameMapping") {
                                                    return match.VAL === property.id;
                                                  } else {
                                                    return false;
                                                  }
                                                }))) + 1 | 0, Belt_List.length(model.properties), NamedBranch.getId(branch), schemaGroup.schemaId);
                                  
                                }),
                              undefined,
                              [[
                                  {
                                    NAME: "AddRule",
                                    VAL: [
                                      Shortid(),
                                      {
                                        NAME: "Property",
                                        VAL: property.id
                                      },
                                      {
                                        NAME: "NameMapping",
                                        VAL: {
                                          destination: undefined,
                                          name: undefined
                                        }
                                      }
                                    ]
                                  },
                                  context
                                ]]
                            ]);
                } else {
                  return Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: {
                                NAME: "BillingPrompt",
                                VAL: "NameMapping"
                              }
                            });
                }
              })
          }, "+ Add Mapping", match$4 === "Available" ? null : React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingTop(Css.px(1)),
                        tl: {
                          hd: Css.marginLeft(Css.px(8)),
                          tl: {
                            hd: Css_Legacy_Core.SVG.fill("transparent"),
                            tl: {
                              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey70),
                              tl: {
                                hd: Css.zIndex(1),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(PlanLightning.make, {
                      tooltipText: match$4 === "AvailableDuringTrial" ? "Name mapping is available during trial and on the " + (Workspace.printPlanName(AvoLimits.NameMapping.availableOnPlan(workspace).name) + " plan") : "Name mapping is available on the " + (Workspace.printPlanName(AvoLimits.NameMapping.availableOnPlan(workspace).name) + " plan. "),
                      tooltipDirection: "right"
                    })));
    } else {
      tmp$4 = null;
    }
    var tmp$5 = {
      object_: {
        NAME: "Property",
        VAL: property
      },
      objectId: property.id,
      model: model,
      schema: schema,
      events: model.events,
      currentBranch: currentBranch,
      openBranches: openBranches
    };
    if ($$event !== undefined) {
      tmp$5.event = Caml_option.valFromOption($$event);
    }
    if (goToCommentId !== undefined) {
      tmp$5.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    var tmp$6 = {
      userId: userId,
      userExternalId: userId,
      schemaId: schema.id,
      threadId: property.id,
      events: model.events,
      itemType: "Property",
      itemName: property.name,
      mini: true,
      currentBranch: currentBranch,
      role: role,
      schemaGroup: schemaGroup,
      location: "Property"
    };
    if (eventId !== undefined) {
      tmp$6.eventId = Caml_option.valFromOption(eventId);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(DrawerHeader.make, {
              kind: getPropertyTypeName(property.sendAs, model) + " Property",
              options: Belt_Array.concatMany([
                    Models.Role.canEdit(role) ? [{
                          NAME: "Option",
                          VAL: {
                            label: "Archive Property",
                            onClick: (function (param) {
                                var match = Router.getStaticRoute(undefined);
                                var newDrawerItems = Belt_List.keep(match.drawerItems, (function (item) {
                                        if (typeof item === "object" && item.NAME === "property") {
                                          return item.VAL[0] !== property.id;
                                        } else {
                                          return true;
                                        }
                                      }));
                                if (Belt_List.length(propertyEvents$1) > 0) {
                                  if (!window.confirm("Property is used in " + String(Belt_List.length(propertyEvents$1)) + " events, are you sure you want to remove it?")) {
                                    return ;
                                  }
                                  var actions = Belt_Array.concat(Belt_Array.map(Belt_List.toArray(propertyEvents$1), (function ($$event) {
                                              return [
                                                      {
                                                        NAME: "RemovePropertyRefV2",
                                                        VAL: [
                                                          $$event.id,
                                                          property.id
                                                        ]
                                                      },
                                                      {
                                                        eventId: $$event.id,
                                                        eventQuery: $$event.id,
                                                        propertyId: property.id,
                                                        propertyQuery: property.id
                                                      }
                                                    ];
                                            })), [[
                                          {
                                            NAME: "Archive",
                                            VAL: {
                                              NAME: "Property",
                                              VAL: property.id
                                            }
                                          },
                                          context
                                        ]]);
                                  Router.Schema.replaceDrawerItems(newDrawerItems);
                                  return Curry.app(sendActions, [
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              (function (branch) {
                                                  Curry._1(addToast, {
                                                        message: "The property has been archived"
                                                      });
                                                  return AnalyticsRe.propertyDeleted(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), Belt_Option.mapWithDefault($$event, "N/A", (function ($$event) {
                                                                    return $$event.name;
                                                                  })), property.name, Belt_Option.mapWithDefault($$event, "N/A", (function ($$event) {
                                                                    return $$event.id;
                                                                  })), property.id, AnalyticsUtils.sendAsToPropertyType(property.sendAs), NamedBranch.getId(branch), schemaGroup.schemaId);
                                                }),
                                              undefined,
                                              actions
                                            ]);
                                }
                                Router.Schema.replaceDrawerItems(newDrawerItems);
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            (function (branch) {
                                                Curry._1(addToast, {
                                                      message: "The property has been archived"
                                                    });
                                                return AnalyticsRe.propertyDeleted(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), Belt_Option.mapWithDefault($$event, "N/A", (function ($$event) {
                                                                  return $$event.name;
                                                                })), property.name, Belt_Option.mapWithDefault($$event, "N/A", (function ($$event) {
                                                                  return $$event.id;
                                                                })), property.id, AnalyticsUtils.sendAsToPropertyType(property.sendAs), NamedBranch.getId(branch), schemaGroup.schemaId);
                                              }),
                                            undefined,
                                            [[
                                                {
                                                  NAME: "Archive",
                                                  VAL: {
                                                    NAME: "Property",
                                                    VAL: property.id
                                                  }
                                                },
                                                context
                                              ]]
                                          ]);
                              })
                          }
                        }] : [],
                    [
                      {
                        NAME: "Option",
                        VAL: {
                          label: "Copy URL",
                          onClick: (function (param) {
                              CopyTextToClipboard(Router.Link.getSimple(undefined));
                              
                            })
                        }
                      },
                      {
                        NAME: "Option",
                        VAL: {
                          label: "Replace property with...",
                          onClick: (function (param) {
                              return Curry._1(globalSend, {
                                          TAG: /* OpenModal */4,
                                          _0: {
                                            NAME: "MergeProperties",
                                            VAL: property.id
                                          }
                                        });
                            })
                        }
                      }
                    ]
                  ]),
              children: React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.height(Css.px(60)),
                            tl: {
                              hd: Css.marginLeft(Css.px(-20)),
                              tl: {
                                hd: Css.marginTop(Css.px(-19)),
                                tl: {
                                  hd: Css.marginBottom(Css.px(-19)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(NameInput.make, {
                        name: property.name,
                        existingNames: StateUtils.getPropertyNamespace(model),
                        expectedCase: expectedPropertyCase,
                        forceCase: config.forcePropertyCase,
                        autoFocus: false,
                        onChange: (function (propertyName, namingConvention, correctCase) {
                            if (propertyName !== property.name) {
                              return Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (branch) {
                                              return AnalyticsRe.propertyUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), {
                                                          propertyId: propertyGroup.propertyId,
                                                          propertyName: propertyGroup.propertyName,
                                                          namingConvention: namingConvention,
                                                          propertyType: propertyGroup.propertyType,
                                                          correctCase: correctCase
                                                        }, "Rename", property.name, undefined, undefined, undefined, undefined, Belt_List.length(model.properties), NamedBranch.getId(branch), schemaGroup.schemaId);
                                            }),
                                          undefined,
                                          [[
                                              {
                                                NAME: "UpdatePropertyName",
                                                VAL: [
                                                  property.id,
                                                  propertyName
                                                ]
                                              },
                                              context
                                            ]]
                                        ]);
                            }
                            
                          }),
                        submitLabel: "Update Name",
                        placeholder: "Property Name",
                        fullscreen: false,
                        disabled: !Models.Role.canEdit(role),
                        inputStyles: {
                          hd: Css.padding2(Css.px(0), Css.px(20)),
                          tl: /* [] */0
                        },
                        itemType: "Property",
                        actionType: "Rename",
                        getPossibleItemLink: (function (propertyName) {
                            return Belt_Option.map(StateUtils.getPropertyByName(propertyName, model), (function (param) {
                                          return Router.Link.addDrawerItem(undefined, {
                                                      NAME: "property",
                                                      VAL: [
                                                        param.id,
                                                        undefined
                                                      ]
                                                    });
                                        }));
                          })
                      }))
            }), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.maxHeight(Css_Legacy_Core.Calc.$neg(Css.vh(100), DrawerHeader.heightInPixels)),
                    tl: {
                      hd: Css.overflowY("auto"),
                      tl: {
                        hd: Css.paddingTop(Css.px(16)),
                        tl: {
                          hd: Css.paddingBottom(Css.px(16)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, !(template == null) && Belt_Array.some(template.properties, (function (id) {
                    return id === property.id;
                  })) ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding2(Css.px(10), Css.px(35)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.grey20),
                            tl: {
                              hd: Css.margin2(Css.px(10), Css.px(0)),
                              tl: {
                                hd: Css.color(Styles.Color.grey70),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.justifyContent("spaceBetween"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, "This is an example property", Models.Role.canEdit(role) ? React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: /* [] */0
                              })
                        }, React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.bold),
                                              tl: {
                                                hd: Css.color(Styles.Color.grey70),
                                                tl: {
                                                  hd: Css.margin2(Css.px(0), Css.px(2)),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.mintGreenSecondary),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  var schemaRef = Firebase.app(undefined).firestore().collection("schemas").doc(schema.id);
                                  schemaRef.set({
                                        onboardingTemplate: {
                                          events: template.events,
                                          properties: Belt_Array.keep(template.properties, (function (id) {
                                                  return id !== property.id;
                                                })),
                                          propertyGroups: template.propertyGroups,
                                          goals: template.goals,
                                          metrics: template.metrics
                                        }
                                      }, {"merge": true});
                                  return AnalyticsRe.exampleInteraction(schemaGroup, "KeepCurrentExample", "Property", schemaGroup.branchId, schemaGroup.schemaId);
                                })
                            }, "Keep Property"), React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.bold),
                                              tl: {
                                                hd: Css.color(Styles.Color.grey70),
                                                tl: {
                                                  hd: Css.margin2(Css.px(0), Css.px(2)),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.grapeErrorSecondary),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  var match = Router.getStaticRoute(undefined);
                                  var newDrawerItems = Belt_List.keep(match.drawerItems, (function (item) {
                                          if (typeof item === "object" && item.NAME === "property") {
                                            return item.VAL[0] !== property.id;
                                          } else {
                                            return true;
                                          }
                                        }));
                                  Router.Schema.replaceDrawerItems(newDrawerItems);
                                  return Curry.app(sendActions, [
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              (function (branch) {
                                                  return AnalyticsRe.exampleInteraction(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "RemoveCurrentExample", "Property", NamedBranch.getId(branch), schemaGroup.schemaId);
                                                }),
                                              undefined,
                                              [[
                                                  {
                                                    NAME: "Archive",
                                                    VAL: {
                                                      NAME: "Property",
                                                      VAL: property.id
                                                    }
                                                  },
                                                  {
                                                    propertyId: property.id,
                                                    propertyQuery: property.id
                                                  }
                                                ]]
                                            ]);
                                })
                            }, "Remove Property"), React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.bold),
                                              tl: {
                                                hd: Css.backgroundColor(Styles.Color.grapeError),
                                                tl: {
                                                  hd: Css.color(Styles.Color.white),
                                                  tl: {
                                                    hd: Css.margin2(Css.px(0), Css.px(5)),
                                                    tl: {
                                                      hd: Css.padding2(Css.px(5), Css.px(10)),
                                                      tl: {
                                                        hd: Css.borderRadius(Styles.Border.radius),
                                                        tl: {
                                                          hd: Css.hover({
                                                                hd: Css.backgroundColor(Styles.Color.grapeErrorSecondary),
                                                                tl: /* [] */0
                                                              }),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  FirebaseUtils.archiveTemplate(template, (function (eta) {
                                          return Curry.app(sendActions, [
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      eta
                                                    ]);
                                        }));
                                  return AnalyticsRe.exampleInteraction(schemaGroup, "RemoveAllExamples", "Property", schemaGroup.branchId, schemaGroup.schemaId);
                                })
                            }, "Remove All Examples")) : null) : null, Belt_Option.mapWithDefault(property.uniqueName, null, (function (uniqueName) {
                    var propertiesWithSameName = Belt_List.keepMapU(model.properties, (function (item) {
                            if (item.TAG === /* PropertyRef */0) {
                              return ;
                            }
                            var item$1 = item._0;
                            if (Case.camel(item$1.name) === Case.camel(property.name) && item$1.id !== property.id) {
                              return item$1;
                            }
                            
                          }));
                    var tmp;
                    if (propertiesWithSameName) {
                      if (propertiesWithSameName.tl) {
                        tmp = React.createElement("div", undefined, React.createElement("span", undefined, "There are " + String(Belt_List.length(propertiesWithSameName)) + " properties with the same or similar name:"), React.createElement("span", undefined, Belt_List.toArray(Belt_List.map(propertiesWithSameName, (function (property) {
                                            return React.createElement("span", undefined, React.createElement(Link.make, {
                                                            path: Router.Link.addDrawerItem(undefined, {
                                                                  NAME: "property",
                                                                  VAL: [
                                                                    property.id,
                                                                    undefined
                                                                  ]
                                                                }),
                                                            className: Curry._1(Css.style, {
                                                                  hd: Css.color(Styles.Color.blue),
                                                                  tl: {
                                                                    hd: Css.paddingLeft(Css.px(5)),
                                                                    tl: {
                                                                      hd: Css.hover({
                                                                            hd: Css.color(Styles.Color.blueSecondary),
                                                                            tl: /* [] */0
                                                                          }),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                }),
                                                            children: property.name
                                                          }), ",");
                                          })))));
                      } else {
                        var property$1 = propertiesWithSameName.hd;
                        tmp = React.createElement("div", undefined, React.createElement("span", undefined, "There is one property with same or similar name:"), React.createElement(Link.make, {
                                  path: Router.Link.addDrawerItem(undefined, {
                                        NAME: "property",
                                        VAL: [
                                          property$1.id,
                                          undefined
                                        ]
                                      }),
                                  className: Curry._1(Css.style, {
                                        hd: Css.color(Styles.Color.blue),
                                        tl: {
                                          hd: Css.paddingLeft(Css.px(5)),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.color(Styles.Color.blueSecondary),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }),
                                  onClick: (function (param) {
                                      return Router.Schema.pushDrawerItem(undefined, {
                                                  NAME: "property",
                                                  VAL: [
                                                    property$1.id,
                                                    undefined
                                                  ]
                                                });
                                    }),
                                  children: property$1.name
                                }));
                      }
                    } else {
                      tmp = React.createElement("div", undefined, "It looks like the naming conflict has been resolved. Feel free to remove the identifier. Note that removing the identifier will change how the property is represented in the code Avo generates.", React.createElement("button", {
                                className: Curry._1(Css.merge, {
                                      hd: Curry._1(Css.style, Styles.button),
                                      tl: {
                                        hd: Curry._1(Css.style, {
                                              hd: Css.color(Styles.Color.grapeError),
                                              tl: {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: {
                                                  hd: Css.marginLeft(Css.px(2)),
                                                  tl: {
                                                    hd: Css.fontSize(Styles.FontSize.regular),
                                                    tl: {
                                                      hd: Css.hover({
                                                            hd: Css.color(Styles.Color.grapeErrorSecondary),
                                                            tl: /* [] */0
                                                          }),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }),
                                onClick: (function (param) {
                                    return Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "UpdatePropertyUniqueName",
                                                      VAL: [
                                                        property.id,
                                                        ""
                                                      ]
                                                    },
                                                    {
                                                      propertyId: property.id,
                                                      propertyQuery: property.id
                                                    }
                                                  ]]
                                              ]);
                                  })
                              }, "Remove"));
                    }
                    return React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("flexStart"),
                                        tl: {
                                          hd: Css.margin4(Css.px(5), Css.px(0), Css.px(15), Css.px(0)),
                                          tl: {
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.grey20),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    })
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.width(Css.pct(100)),
                                          tl: {
                                            hd: Css.paddingTop(Css.px(15)),
                                            tl: {
                                              hd: Css.paddingRight(Css.px(20)),
                                              tl: {
                                                hd: Css.paddingBottom(Css.px(15)),
                                                tl: {
                                                  hd: Css.paddingLeft(Css.px(60)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: {
                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                  tl: {
                                                    hd: Css.color(Styles.Color.grey90),
                                                    tl: {
                                                      hd: Css.fontSize(Styles.FontSize.regular),
                                                      tl: {
                                                        hd: Css.marginTop(Css.px(1)),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            })
                                      }, "Property Identifier"), React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.fontWeight(Styles.FontWeight.regular),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.regular),
                                                tl: {
                                                  hd: Css.color(Styles.Color.grey70),
                                                  tl: {
                                                    hd: Css.marginTop(Css.px(5)),
                                                    tl: {
                                                      hd: Css.marginBottom(Css.px(15)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            })
                                      }, React.createElement("div", undefined, "The property identifier is used to differentiate between properties that have the same name. It does not affect how the property is sent but does change how the property is represented in the code Avo generates. We recommend changing the property identifier to something that describes how this property is different from other properties with the same name."), React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                  tl: {
                                                    hd: Css.marginTop(Css.px(4)),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, tmp)), React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.position("relative"),
                                              tl: {
                                                hd: Css.margin4(Css.px(5), Css.px(0), Css.px(0), Css.px(0)),
                                                tl: {
                                                  hd: Css.height(Css.px(50)),
                                                  tl: {
                                                    hd: Css.width(Css.pct(100)),
                                                    tl: {
                                                      hd: Css.borderRadius(Styles.Border.radius),
                                                      tl: {
                                                        hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            })
                                      }, React.createElement(NameInput.make, {
                                            name: uniqueName,
                                            existingNames: StateUtils.getPropertyNamespace(model),
                                            expectedCase: expectedPropertyCase,
                                            forceCase: config.forcePropertyCase,
                                            onChange: (function (value, param, param$1) {
                                                if (value !== uniqueName) {
                                                  Curry.app(sendActions, [
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        [[
                                                            {
                                                              NAME: "UpdatePropertyUniqueName",
                                                              VAL: [
                                                                property.id,
                                                                value
                                                              ]
                                                            },
                                                            {
                                                              propertyId: property.id,
                                                              propertyQuery: property.id
                                                            }
                                                          ]]
                                                      ]);
                                                  return ;
                                                }
                                                
                                              }),
                                            disabled: !Models.Role.canEdit(role),
                                            inputStyles: {
                                              hd: Css.fontSize(Styles.FontSize.regular),
                                              tl: {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: /* [] */0
                                              }
                                            },
                                            itemType: "Property",
                                            actionType: "Rename",
                                            getPossibleItemLink: (function (propertyName) {
                                                return Belt_Option.map(StateUtils.getPropertyByName(propertyName, model), (function (param) {
                                                              return Router.Link.addDrawerItem(undefined, {
                                                                          NAME: "property",
                                                                          VAL: [
                                                                            param.id,
                                                                            undefined
                                                                          ]
                                                                        });
                                                            }));
                                              })
                                          }))));
                  })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding4(Css.px(0), Css.px(20), Css.px(0), Css.px(60)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: {
                              hd: Css.flexDirection("column"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement("label", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("row"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.color(Styles.Color.grey90),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.regular),
                                      tl: {
                                        hd: Css.padding4(Css.px(11), Css.px(0), Css.px(5), Css.px(10)),
                                        tl: {
                                          hd: Css.marginLeft(Css.px(-39)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }),
                      htmlFor: property.name + "-description"
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginTop(Css.px(3)),
                                tl: {
                                  hd: Css.marginRight(Css.px(5)),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement(IconDescription.make, {})), React.createElement(Spacer.make, {
                          width: 8
                        }), "Description", React.createElement(Tooltip.WithAnalytics.make, {
                          fromItem: fromItem,
                          title: "Description",
                          children: React.createElement(Tooltip.Content.make, {
                                children: React.createElement(Markdown.make, {
                                      source: Tooltips.propertyDescription
                                    })
                              })
                        })), React.createElement(OpsWithState.make, {
                      value: property.description,
                      onFlush: (function (description) {
                          return Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdatePropertyDescription",
                                            VAL: [
                                              property.id,
                                              description
                                            ]
                                          },
                                          context
                                        ]]
                                    ]);
                        }),
                      onChangeCompleted: (function (value, valueBefore) {
                          AnalyticsRe.descriptionUpdated(schemaGroup, valueBefore, "Property", value.split(" ").length, value, value.length, property.name, schemaGroup.branchId, schemaGroup.schemaId);
                          
                        }),
                      children: (function (value, onChange, onBlur, onFocus) {
                          return React.createElement(ReactTextareaAutosize, {
                                      className: Curry._1(Css.merge, {
                                            hd: Curry._1(Css.style, Styles.input),
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.fontWeight(Styles.FontWeight.regular),
                                                    tl: {
                                                      hd: Css.fontSize(Styles.FontSize.regular),
                                                      tl: {
                                                        hd: Css.color(Styles.Color.grey90),
                                                        tl: {
                                                          hd: Css.cursor(property.builtIn !== "NotBuiltIn" ? "default" : "text"),
                                                          tl: {
                                                            hd: Css.backgroundColor(Css.transparent),
                                                            tl: {
                                                              hd: Css.zIndex(100),
                                                              tl: {
                                                                hd: Css.padding2(Css.px(4), Css.px(0)),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      maxRows: 15,
                                      minRows: 1,
                                      onChange: (function (domEvent) {
                                          return Curry._1(onChange, domEvent.target.value);
                                        }),
                                      placeholder: "Describe the property...",
                                      onFocus: (function (domEvent) {
                                          return Curry._1(onFocus, domEvent.target.value);
                                        }),
                                      onBlur: (function (domEvent) {
                                          return Curry._1(onBlur, domEvent.target.value);
                                        }),
                                      value: value,
                                      id: property.name + "-description",
                                      disabled: property.builtIn !== "NotBuiltIn" || !Models.Role.canEdit(role)
                                    });
                        })
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.grey20),
                            tl: {
                              hd: Css.margin2(Css.px(15), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.padding4(Css.px(0), Css.px(20), Css.px(0), Css.px(60)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.marginLeft(Css.px(-29)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginTop(Css.px(3)),
                                tl: {
                                  hd: Css.marginRight(Css.px(5)),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement(Icon.make, {
                              type_: "braces",
                              color: Styles.Color.grey40
                            })), React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          children: "Property value type"
                        }), React.createElement(Tooltip.WithAnalytics.make, {
                          fromItem: fromItem,
                          title: "Property value type",
                          children: React.createElement(Tooltip.Content.make, {
                                children: React.createElement(Markdown.make, {
                                      source: Tooltips.propertyValueType
                                    })
                              })
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("flexStart"),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement(Select.make, {
                          disabled: !Models.Role.canEdit(role),
                          onSelect: (function (nextType) {
                              var lastType = property.type_;
                              return Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (branch) {
                                              if (lastType !== nextType) {
                                                return AnalyticsRe.typeChanged(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "N/A", Belt_Option.getExn(AnalyticsRe.to_FromJs(nextType)), Belt_Option.getExn(AnalyticsRe.fromFromJs(lastType)), property.name, NamedBranch.getId(branch), schemaGroup.schemaId);
                                              }
                                              
                                            }),
                                          undefined,
                                          [[
                                              {
                                                NAME: "UpdatePropertyType",
                                                VAL: [
                                                  property.id,
                                                  nextType
                                                ]
                                              },
                                              context
                                            ]]
                                        ]);
                            }),
                          options: Belt_List.map(PropertyUtils.getPropertyTypes(hasObjectsFeatureFlag, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, property), (function (type_) {
                                  return [
                                          {
                                            NAME: "Label",
                                            VAL: type_
                                          },
                                          type_
                                        ];
                                })),
                          value: property.type_
                        }), React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginRight(Css.px(15)),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.color(Styles.Color.grey80),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, React.createElement("input", {
                              id: "list",
                              checked: property.list,
                              disabled: !Models.Role.canEdit(role),
                              type: "checkbox",
                              onChange: (function ($$event) {
                                  var isList = $$event.target.checked;
                                  return Curry.app(sendActions, [
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              (function (branch) {
                                                  return AnalyticsRe.listToggled(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), isList, "N/A", property.name, NamedBranch.getId(branch), schemaGroup.schemaId);
                                                }),
                                              undefined,
                                              [[
                                                  {
                                                    NAME: "UpdatePropertyList",
                                                    VAL: [
                                                      property.id,
                                                      isList
                                                    ]
                                                  },
                                                  context
                                                ]]
                                            ]);
                                })
                            }), React.createElement("label", {
                              className: Curry._1(Css.style, {
                                    hd: Css.marginLeft(Css.px(2)),
                                    tl: /* [] */0
                                  }),
                              htmlFor: "list"
                            }, "List"))), tmp$2, property.sendAs === /* UserProperty */2 && FeatureFlag.featureEnabled(schema, "UserPropertyOperations") ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.marginRight(Css.px(15)),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: {
                                    hd: Css.color(Styles.Color.grey80),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement(Select.make, {
                            onSelect: (function (nextOperation) {
                                var operation = TrackingPlanModel.operationFromJs(nextOperation);
                                if (operation !== undefined) {
                                  return Curry.app(sendActions, [
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              [[
                                                  {
                                                    NAME: "UpdatePropertyOperation",
                                                    VAL: [
                                                      property.id,
                                                      operation
                                                    ]
                                                  },
                                                  context
                                                ]]
                                            ]);
                                }
                                
                              }),
                            options: Belt_List.map(AvoConfig.supportedOperations, (function (operation) {
                                    return [
                                            {
                                              NAME: "Label",
                                              VAL: AvoConfig.operationLabel(operation)
                                            },
                                            TrackingPlanModel.operationToJs(operation)
                                          ];
                                  })),
                            value: TrackingPlanModel.operationToJs(property.operation)
                          })) : null), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(12)),
                        tl: /* [] */0
                      })
                }), tmp$3, FeatureFlag.featureEnabled(schema, "PropertySources") && !hasMigratedToAbsence ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.width(Css.pct(100)),
                              tl: {
                                hd: Css.height(Css.px(1)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.grey20),
                                  tl: {
                                    hd: Css.margin2(Css.px(15), Css.px(0)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding4(Css.px(0), Css.px(20), Css.px(0), Css.px(60)),
                              tl: /* [] */0
                            })
                      }, React.createElement(PropertyModalSources.make, {
                            model: model,
                            property: property,
                            sendActions: sendActions
                          }))) : null, hasMigratedToAbsence && inObjectProperties !== /* [] */0 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.width(Css.pct(100)),
                              tl: {
                                hd: Css.height(Css.px(1)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.grey20),
                                  tl: {
                                    hd: Css.margin2(Css.px(15), Css.px(0)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }), React.createElement(PropertyDetails$SentInObjectProperties, {
                        model: model,
                        property: property,
                        inProperties: inObjectProperties
                      })) : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.grey20),
                            tl: {
                              hd: Css.margin2(Css.px(15), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding4(Css.px(0), Css.px(20), Css.px(0), Css.px(60)),
                        tl: /* [] */0
                      })
                }, React.createElement(ModalConstraintEditor.make, {
                      property: property,
                      sendActions: sendActions,
                      schema: schema,
                      role: role,
                      addToast: addToast,
                      model: model,
                      schemaGroup: schemaGroup,
                      currentFilters: currentFilters,
                      filters: filters
                    })), React.createElement("div", undefined, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.height(Css.px(1)),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.grey20),
                                tl: {
                                  hd: Css.margin2(Css.px(15), Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.paddingLeft(Css.px(60)),
                            tl: {
                              hd: Css.paddingRight(Css.px(20)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: {
                                    hd: Css.paddingBottom(Css.px(5)),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        }, "Name Mappings", React.createElement(Tooltip.WithAnalytics.make, {
                              fromItem: fromItem,
                              title: "Name Mappings",
                              children: React.createElement(Tooltip.Content.make, {
                                    children: React.createElement(Markdown.make, {
                                          source: Tooltips.propertySendAs
                                        })
                                  })
                            })), React.createElement("div", undefined, Belt_List.toArray(Belt_List.map(StateUtils.getPropertyNameMappings(model, property.id), (function (rule) {
                                    return React.createElement(PropertyRule.make, {
                                                rule: rule,
                                                model: model,
                                                sendActions: sendActions,
                                                property: property,
                                                context: context,
                                                schemaGroup: schemaGroup,
                                                role: role
                                              });
                                  })))), tmp$4)), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.grey20),
                            tl: {
                              hd: Css.margin2(Css.px(15), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingLeft(Css.px(16)),
                        tl: {
                          hd: Css.paddingRight(Css.px(10)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(ObjectActivityLog.make, tmp$5), React.createElement(CommentInput.make, tmp$6))));
  } else {
    tmp = "Property not found";
  }
  return React.createElement("div", {
              key: propertyId,
              className: Curry._1(Css.style, {
                    hd: Css.cursor("default"),
                    tl: /* [] */0
                  }),
              onClick: (function ($$event) {
                  $$event.stopPropagation();
                  
                })
            }, tmp);
}

var make = PropertyDetails;

exports.SentInObjectProperties = SentInObjectProperties;
exports.getPropertyTypeName = getPropertyTypeName;
exports.make = make;
/* rootStyles Not a pure module */
