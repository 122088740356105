// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var BranchStateFirebaseModel = require("../../shared/models/BranchStateFirebaseModel.bs.js");

function enrichStatusWithApprovals(status, approvalsCount, workspace) {
  var match = AvoLimits.ApprovalWorkflow.computeAvailability(workspace);
  var approvalWorkflow = match === "AvailableDuringTrial" || match === "Available";
  if (status === "Closed") {
    return "Closed";
  } else if (status === "ChangesRequested") {
    if (approvalWorkflow) {
      return "ChangesRequested";
    } else {
      return "Open";
    }
  } else if (status === "Approved") {
    if (approvalWorkflow) {
      if (workspace.settings.numRequiredApprovals <= approvalsCount) {
        return "Approved";
      } else {
        return "ReadyForReview";
      }
    } else {
      return "Open";
    }
  } else if (status === "Draft") {
    if (approvalWorkflow) {
      return "Draft";
    } else {
      return "Open";
    }
  } else if (status === "Unknown") {
    return "Unknown";
  } else if (status === "Open") {
    return "Open";
  } else if (status === "Merged") {
    return "Merged";
  } else if (approvalWorkflow) {
    return "ReadyForReview";
  } else {
    return "Open";
  }
}

function getStatusBackgroundColor(status) {
  if (status === "Closed") {
    return Styles.Color.red20;
  } else if (status === "Approved") {
    return Styles.Color.green20;
  } else if (status === "Draft") {
    return Styles.Color.grey20;
  } else if (status === "Open" || status === "Unknown") {
    return Styles.Color.grey30;
  } else if (status === "Merged") {
    return Styles.Color.blue20;
  } else {
    return Styles.Color.yellow20;
  }
}

function BranchStatusPill(Props) {
  var status = Props.status;
  var approvalsCount = Props.approvalsCount;
  var workspace = Props.workspace;
  var finalStatus = enrichStatusWithApprovals(status, approvalsCount, workspace);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width("fitContent"),
                    tl: {
                      hd: Css.marginRight(Css.px(4)),
                      tl: {
                        hd: Css.borderRadius(Css.px(32)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(8)),
                          tl: {
                            hd: Css.paddingRight(Css.px(8)),
                            tl: {
                              hd: Css.paddingTop(Css.px(4)),
                              tl: {
                                hd: Css.paddingBottom(Css.px(3)),
                                tl: {
                                  hd: Css.backgroundColor(getStatusBackgroundColor(finalStatus)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  singleLine: true,
                  color: Styles.Color.grey80,
                  children: BranchStateFirebaseModel.branchStatusToJs(finalStatus)
                }));
}

var make = BranchStatusPill;

exports.enrichStatusWithApprovals = enrichStatusWithApprovals;
exports.getStatusBackgroundColor = getStatusBackgroundColor;
exports.make = make;
/* Css Not a pure module */
