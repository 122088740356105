// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Router = require("../Router.bs.js");
var Styles = require("../styles.bs.js");
var $$Comment = require("../Comment.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var IconArrowUp = require("../IconArrowUp.bs.js");
var IconArrowDown = require("../IconArrowDown.bs.js");
var CollapsedContent = require("../CollapsedContent.bs.js");
var IconArrowTopRight = require("../IconArrowTopRight.bs.js");
var TrackingPlanModel = require("../../../model/src/TrackingPlanModel.bs.js");
var FirebaseFetcherHooks = require("../FirebaseFetcherHooks.bs.js");
var ObjectActivityLogAction = require("../ObjectActivityLogAction.bs.js");

var docsLinkStyles = Curry._1(Css.style, {
      hd: Css.textDecoration("none"),
      tl: {
        hd: Css.marginTop(Css.px(4)),
        tl: /* [] */0
      }
    });

var docsLinkIconStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.blue),
      tl: /* [] */0
    });

function IntegrationActivityItem$DocsLinkOnError(Props) {
  var publishingMessagesType = Props.publishingMessagesType;
  return React.createElement("a", {
              className: docsLinkStyles,
              href: "https://www.avo.app/docs/publishing/publishing/overview",
              rel: "noopener",
              target: "_blank"
            }, React.createElement($$Text.make, {
                  element: "Span",
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.blue,
                  children: publishingMessagesType === /* Errors */0 ? "Learn more about publishing errors in the docs" : "Learn more about publishing warnings in the docs"
                }), React.createElement("span", {
                  className: docsLinkIconStyles
                }, React.createElement(IconArrowTopRight.make, {
                      size: 10,
                      color: Styles.Color.blue
                    })));
}

var DocsLinkOnError = {
  make: IntegrationActivityItem$DocsLinkOnError
};

function containerStyle(publishingMessagesType) {
  return {
          hd: Css.border(Css.px(1), "solid", publishingMessagesType === /* Errors */0 ? Styles.Color.grapeErrorBg : Styles.Color.orangeBg),
          tl: {
            hd: Css.borderRadius(Styles.Border.radius),
            tl: {
              hd: Css.marginBottom(Css.px(8)),
              tl: {
                hd: Css.margin4(Css.px(4), Css.px(45), Css.px(12), Css.px(45)),
                tl: /* [] */0
              }
            }
          }
        };
}

function toggleButtonStyles(collapsed, publishingMessagesType) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.button),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.medium),
                            tl: {
                              hd: Css.lineHeight(Styles.LineHeight.regular),
                              tl: {
                                hd: Css.color(publishingMessagesType === /* Errors */0 ? Styles.Color.grapeError : Styles.Color.orange),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.small),
                                  tl: {
                                    hd: Css.padding2(Css.px(8), Css.px(12)),
                                    tl: {
                                      hd: Css.width(Css.pct(100)),
                                      tl: {
                                        hd: Css.textAlign("left"),
                                        tl: {
                                          hd: Css.transition({
                                                NAME: "ms",
                                                VAL: Styles.Duration.$$short
                                              }, undefined, undefined, "all"),
                                          tl: {
                                            hd: Css.borderBottom(Css.px(collapsed ? 0 : 1), "solid", publishingMessagesType === /* Errors */0 ? Styles.Color.grapeErrorBg : Styles.Color.orangeBg),
                                            tl: {
                                              hd: Css.hover({
                                                    hd: Css.backgroundColor(publishingMessagesType === /* Errors */0 ? Styles.Color.grapeErrorBg : Styles.Color.orangeBg),
                                                    tl: /* [] */0
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var toggleButtonLabelStyles = Curry._1(Css.style, {
      hd: Css.marginRight(Css.px(4)),
      tl: /* [] */0
    });

function IntegrationActivityItem$PublishingMessages(Props) {
  var messages = Props.messages;
  var publishingMessagesType = Props.publishingMessagesType;
  return React.createElement(CollapsedContent.make, {
              button: (function (onClick, collapsed) {
                  return React.createElement("button", {
                              className: toggleButtonStyles(collapsed, publishingMessagesType),
                              onClick: (function (param) {
                                  return Curry._1(onClick, undefined);
                                })
                            }, React.createElement("div", {
                                  className: toggleButtonLabelStyles
                                }, React.createElement($$Text.make, {
                                      weight: "Semi",
                                      children: publishingMessagesType ? (
                                          collapsed ? "View warnings" : "Hide warnings"
                                        ) : (
                                          collapsed ? "View errors" : "Hide errors"
                                        )
                                    })), collapsed ? React.createElement(IconArrowDown.make, {
                                    size: 8
                                  }) : React.createElement(IconArrowUp.make, {
                                    size: 8
                                  }));
                }),
              containerStyle: containerStyle(publishingMessagesType),
              children: (function (param) {
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding2(Css.px(8), Css.px(12)),
                                    tl: /* [] */0
                                  })
                            }, Belt_Array.map(messages, (function (message) {
                                    return React.createElement($$Text.make, {
                                                size: "Small",
                                                weight: "Regular",
                                                color: publishingMessagesType === /* Errors */0 ? Styles.Color.grapeError : Styles.Color.grey100,
                                                children: message,
                                                key: message
                                              });
                                  })), React.createElement(IntegrationActivityItem$DocsLinkOnError, {
                                  publishingMessagesType: publishingMessagesType
                                }));
                })
            });
}

var PublishingMessages = {
  containerStyle: containerStyle,
  toggleButtonStyles: toggleButtonStyles,
  toggleButtonLabelStyles: toggleButtonLabelStyles,
  make: IntegrationActivityItem$PublishingMessages
};

function printSuccessMessage(param, auto) {
  var version = param.version;
  if (version !== undefined) {
    return "successfully " + (
            auto ? "auto-" : ""
          ) + "published version " + version;
  } else {
    return "successfully " + (
            auto ? "auto-" : ""
          ) + "published and updated " + String(param.numSuccessfulEvents) + " events, " + String(param.numSuccessfulProperties) + " properties and " + String(param.numSuccessfulCategories) + " categories";
  }
}

function IntegrationActivityItem(Props) {
  var schema = Props.schema;
  var events = Props.events;
  var action = Props.action;
  var userId = Props.userId;
  var userIds = Props.userIds;
  var goToId = Props.goToId;
  var openBranches = Props.openBranches;
  var integrationName = Props.integrationName;
  var showComments = Props.showComments;
  var users = FirebaseFetcherHooks.useUsers(userIds);
  if (typeof users !== "object") {
    return null;
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var match = action.contents;
  if (typeof match !== "object") {
    return null;
  }
  var variant = match.NAME;
  if (variant === "CompletedImport" || variant === "ImportDeprecated") {
    return null;
  }
  if (variant === "UpdateIntegrationAutoPublish") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: (
                  match.VAL[1] ? "enabled" : "disabled"
                ) + " auto publishing"
              });
  }
  if (variant === "UpdateIntegrationConfig") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "changed the integration config"
              });
  }
  if (variant === "RemoveIntegration") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "deleted the integration"
              });
  }
  if (variant === "IntegrationComment") {
    if (!showComments) {
      return null;
    }
    var match$1 = match.VAL;
    var commentId = match$1[1];
    var integrationId = match$1[0];
    return React.createElement($$Comment.make, {
                schema: schema,
                threadId: integrationId,
                itemType: "Integration",
                itemName: integrationName,
                commentId: commentId,
                events: events,
                userId: userId,
                goToId: goToId,
                action: action,
                link: (function (param) {
                    return Router.Link.addDrawerItem(undefined, {
                                NAME: "integration",
                                VAL: [
                                  integrationId,
                                  commentId
                                ]
                              });
                  }),
                openBranches: openBranches
              });
  }
  if (variant === "GenerateWebhookSecret") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: React.createElement(React.Fragment, undefined, "Generated Webhook Secret")
              });
  }
  if (variant === "CreateIntegration") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "created the integration"
              });
  }
  if (variant === "PublishIntegration") {
    var match$2 = match.VAL;
    if (match$2[1]) {
      var errors = match$2[3];
      var warnings = match$2[2];
      var tmp;
      var exit = 0;
      if (warnings.length !== 0 || errors.length !== 0) {
        exit = 1;
      } else {
        tmp = null;
      }
      if (exit === 1) {
        tmp = React.createElement(IntegrationActivityItem$PublishingMessages, {
              messages: Belt_Array.concat(warnings, errors),
              publishingMessagesType: /* Warnings */1
            });
      }
      return React.createElement(React.Fragment, undefined, React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: Belt_Option.mapWithDefault(match$2[4], "successfully published the integration", (function (successStats) {
                              return printSuccessMessage(successStats, false);
                            }))
                    }), tmp);
    }
    var errors$1 = match$2[3];
    var warnings$1 = match$2[2];
    return React.createElement(React.Fragment, undefined, React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: Belt_Option.mapWithDefault(match$2[4], "published the integration with errors", (function (successStats) {
                            return printSuccessMessage(successStats, false);
                          }))
                  }), warnings$1.length !== 0 ? React.createElement(IntegrationActivityItem$PublishingMessages, {
                      messages: warnings$1,
                      publishingMessagesType: /* Warnings */1
                    }) : null, errors$1.length !== 0 ? React.createElement(IntegrationActivityItem$PublishingMessages, {
                      messages: errors$1,
                      publishingMessagesType: /* Errors */0
                    }) : null);
  }
  if (variant === "UpdateIntegrationName") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: React.createElement(React.Fragment, undefined, "changed the integration name to", React.createElement("b", undefined, match.VAL[1]))
              });
  }
  if (variant === "UpdateIntegrationType") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: React.createElement(React.Fragment, undefined, "changed the integration type to", React.createElement("b", undefined, TrackingPlanModel.integrationTypeHumanReadableName(match.VAL[1])))
              });
  }
  if (variant === "UpdateIntegrationFilters") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "changed the events filter"
              });
  }
  if (variant !== "AutoPublishIntegration") {
    return null;
  }
  var match$3 = match.VAL;
  if (match$3[1]) {
    var errors$2 = match$3[3];
    var warnings$2 = match$3[2];
    var tmp$1;
    var exit$1 = 0;
    if (warnings$2.length !== 0 || errors$2.length !== 0) {
      exit$1 = 1;
    } else {
      tmp$1 = null;
    }
    if (exit$1 === 1) {
      tmp$1 = React.createElement(IntegrationActivityItem$PublishingMessages, {
            messages: Belt_Array.concat(warnings$2, errors$2),
            publishingMessagesType: /* Warnings */1
          });
    }
    return React.createElement(React.Fragment, undefined, React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: [],
                    openBranches: openBranches,
                    children: Belt_Option.mapWithDefault(match$3[4], "Auto Publish succeeded", (function (successStats) {
                            return printSuccessMessage(successStats, true);
                          }))
                  }), tmp$1);
  }
  var errors$3 = match$3[3];
  var warnings$3 = match$3[2];
  return React.createElement(React.Fragment, undefined, React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: [],
                  openBranches: openBranches,
                  children: Belt_Option.mapWithDefault(match$3[4], "Auto Publish failed", (function (successStats) {
                          return printSuccessMessage(successStats, true);
                        }))
                }), warnings$3.length !== 0 ? React.createElement(IntegrationActivityItem$PublishingMessages, {
                    messages: warnings$3,
                    publishingMessagesType: /* Warnings */1
                  }) : null, errors$3.length !== 0 ? React.createElement(IntegrationActivityItem$PublishingMessages, {
                    messages: errors$3,
                    publishingMessagesType: /* Errors */0
                  }) : null);
}

var make = IntegrationActivityItem;

exports.docsLinkStyles = docsLinkStyles;
exports.docsLinkIconStyles = docsLinkIconStyles;
exports.DocsLinkOnError = DocsLinkOnError;
exports.PublishingMessages = PublishingMessages;
exports.printSuccessMessage = printSuccessMessage;
exports.make = make;
/* docsLinkStyles Not a pure module */
