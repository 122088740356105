// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function IconCopy(Props) {
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  return React.createElement("svg", {
              width: String(size),
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  strokeWidth: "1"
                }, React.createElement("rect", {
                      height: "11",
                      width: "11",
                      fill: "none",
                      strokeLinecap: "round",
                      x: "0.5",
                      y: "0.5"
                    }), React.createElement("polyline", {
                      fill: "none",
                      points: "13.5 4.5 15.5 4.5 15.5 15.5 4.5 15.5 4.5 13.5",
                      strokeLinecap: "round"
                    })));
}

var make = IconCopy;

exports.make = make;
/* react Not a pure module */
