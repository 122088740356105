// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var IconX = require("./IconX.bs.js");
var Title = require("./Title.bs.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function root(background, enableOverflow) {
  return Curry._1(Css.style, Belt_List.concat({
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.flexDirection("column"),
                    tl: {
                      hd: Css.minWidth(Css.px(400)),
                      tl: {
                        hd: Css.backgroundColor(background === "OffWhite" ? Styles.Color.grey10 : (
                                background === "Grey" ? Styles.Color.grey20 : Styles.Color.white
                              )),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(32), undefined, undefined, Css.rgba(0, 0, 0, {
                                          NAME: "num",
                                          VAL: 0.10
                                        }))),
                            tl: {
                              hd: Css.maxHeight(Css.vh(85.0)),
                              tl: {
                                hd: Css.maxWidth(Css.vw(90.0)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }, enableOverflow ? /* [] */0 : ({
                      hd: Css.overflow("hidden"),
                      tl: /* [] */0
                    })));
}

function headerStyles(background, headerBackground, toolbar) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.backgroundColor(headerBackground === "OffWhite" ? Styles.Color.grey10 : (
                          headerBackground === "Grey" ? Styles.Color.grey20 : Styles.Color.white
                        )),
                  tl: {
                    hd: Css.width(Css.pct(100.0)),
                    tl: {
                      hd: Css.flexShrink(0.0),
                      tl: {
                        hd: Css.borderBottom(Css.px(toolbar === undefined ? 1 : 0), "solid", background === "OffWhite" ? Styles.Color.grey10 : (
                                background === "Grey" ? Styles.Color.grey30 : Styles.Color.grey20
                              )),
                        tl: {
                          hd: Css.paddingTop(Css.px(10)),
                          tl: {
                            hd: Css.paddingRight(Css.px(16)),
                            tl: {
                              hd: Css.paddingBottom(Css.px(10)),
                              tl: {
                                hd: Css.paddingLeft(Css.px(16)),
                                tl: {
                                  hd: Css.borderTopLeftRadius(Styles.Border.radius),
                                  tl: {
                                    hd: Css.borderTopRightRadius(Styles.Border.radius),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var headerTop = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.width(Css.pct(100.0)),
            tl: /* [] */0
          }
        }
      }
    });

var headerClose = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.width(Css.px(32)),
            tl: {
              hd: Css.height(Css.px(32)),
              tl: {
                hd: Css.borderWidth(Css.px(0)),
                tl: {
                  hd: Css.backgroundColor("transparent"),
                  tl: {
                    hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey50),
                    tl: {
                      hd: Css.padding(Css.px(0)),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: {
                          hd: Css.borderRadius(Css.px(100)),
                          tl: {
                            hd: Css.unsafe("appearance", "none"),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$short
                                  }, undefined, undefined, "all"),
                              tl: {
                                hd: Css.focus({
                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.blue)),
                                      tl: {
                                        hd: Css.outlineStyle("none"),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.backgroundColor(Styles.Color.grey20),
                                        tl: {
                                          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey70),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var headerDescription = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(-2)),
      tl: {
        hd: Css.maxWidth(Css.px(500)),
        tl: /* [] */0
      }
    });

function content(enableOverflow) {
  if (enableOverflow) {
    return Curry._1(Css.style, {
                hd: Css.flexShrink(1.0),
                tl: /* [] */0
              });
  } else {
    return Curry._1(Css.style, {
                hd: Css.overflowY("auto"),
                tl: {
                  hd: Css.flexShrink(1.0),
                  tl: /* [] */0
                }
              });
  }
}

function Modal(Props) {
  var title = Props.title;
  var descriptionOpt = Props.description;
  var backgroundOpt = Props.background;
  var headerBackgroundOpt = Props.headerBackground;
  var onClose = Props.onClose;
  var toolbar = Props.toolbar;
  var footer = Props.footer;
  var enableOverflowOpt = Props.enableOverflow;
  var children = Props.children;
  var description = descriptionOpt !== undefined ? Caml_option.valFromOption(descriptionOpt) : undefined;
  var background = backgroundOpt !== undefined ? backgroundOpt : "Grey";
  var headerBackground = headerBackgroundOpt !== undefined ? headerBackgroundOpt : "White";
  var enableOverflow = enableOverflowOpt !== undefined ? enableOverflowOpt : false;
  return React.createElement("section", {
              className: root(background, enableOverflow)
            }, React.createElement("header", {
                  className: headerStyles(background, headerBackground, toolbar)
                }, React.createElement("div", {
                      className: headerTop
                    }, React.createElement(Title.make, {
                          children: title,
                          size: "Tiny"
                        }), React.createElement("button", {
                          className: headerClose,
                          onClick: (function (param) {
                              return Curry._1(onClose, undefined);
                            })
                        }, React.createElement(IconX.make, {}))), Belt_Option.mapWithDefault(description, null, (function (description) {
                        return React.createElement("div", {
                                    className: headerDescription
                                  }, React.createElement($$Text.make, {
                                        size: "Small",
                                        color: Styles.Color.grey70,
                                        children: description
                                      }));
                      }))), Belt_Option.getWithDefault(toolbar, null), React.createElement("div", {
                  className: content(enableOverflow)
                }, children), Belt_Option.getWithDefault(footer, null));
}

var make = Modal;

exports.root = root;
exports.headerStyles = headerStyles;
exports.headerTop = headerTop;
exports.headerClose = headerClose;
exports.headerDescription = headerDescription;
exports.content = content;
exports.make = make;
/* headerTop Not a pure module */
