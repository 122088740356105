// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("./Icons.bs.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Select = require("./Select.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Printer = require("../../model/src/Printer.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Workspace = require("../../model/src/Workspace.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var StateUtils = require("./stateUtils.bs.js");
var TagsSelect = require("./TagsSelect.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ContextMenu = require("./ContextMenu.bs.js");
var StripeUtils = require("./StripeUtils.bs.js");
var ProfilePhoto = require("./ProfilePhoto.bs.js");
var TrialRequest = require("./TrialRequest.bs.js");
var App = require("firebase/app");
var PlanLightning = require("./PlanLightning.bs.js");
var AppFeatureFlag = require("./AppFeatureFlag.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");

function removeMember(schemaId, member, memberName, memberEmail, viewerId, schemaGroup, globalSend) {
  return Curry._1(globalSend, {
              TAG: /* OpenModal */4,
              _0: {
                NAME: "AreYouSureModal",
                VAL: [
                  "Remove Member?",
                  "Are you sure you want to remove " + memberName + " from this workspace?",
                  "Remove Member",
                  (function (param) {
                      var memberId = member.id;
                      var role = member.role;
                      var aclRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("acls").doc(memberId);
                      var memberSchemaRef = Firebase.app(undefined).firestore().collection("users").doc(memberId).collection("schemas").doc(schemaId);
                      var removeUserBatch = Firebase.app(undefined).firestore().batch();
                      removeUserBatch.delete(aclRef);
                      removeUserBatch.delete(memberSchemaRef);
                      var actionRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions").doc();
                      removeUserBatch.set(actionRef, {
                            id: actionRef.id,
                            contentsJson: Printer.printAction({
                                  NAME: "RemoveMember",
                                  VAL: [
                                    memberId,
                                    memberEmail,
                                    role
                                  ]
                                }),
                            createdAt: App.default.firestore.FieldValue.serverTimestamp(),
                            createdBy: viewerId,
                            branchId: "master",
                            audit: true,
                            order: 0
                          });
                      removeUserBatch.commit().then(function (param) {
                            return AnalyticsRe.memberRemoved(schemaGroup, Belt_Option.getExn(AnalyticsRe.roleFromJs(member.role)), memberId, schemaGroup.branchId, schemaGroup.schemaId);
                          });
                      
                    }),
                  (function (param) {
                      
                    })
                ]
              }
            });
}

function updateMemberRole(schemaId, member, newRole, memberEmail, viewerId, schemaGroup) {
  var memberId = member.id;
  var currentRole = member.role;
  var batch = Firebase.app(undefined).firestore().batch();
  var aclRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("acls").doc(memberId);
  batch.set(aclRef, {
        role: newRole
      }, {"merge": true});
  var actionRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions").doc();
  batch.set(actionRef, {
        id: actionRef.id,
        contentsJson: Printer.printAction({
              NAME: "UpdateMemberRole",
              VAL: [
                memberId,
                memberEmail,
                currentRole,
                newRole
              ]
            }),
        createdAt: App.default.firestore.FieldValue.serverTimestamp(),
        createdBy: viewerId,
        branchId: "master",
        audit: true,
        order: 0
      });
  batch.commit().then(function (param) {
        var match = AnalyticsRe.fromRoleFromJs(currentRole);
        var match$1 = AnalyticsRe.toRoleFromJs(newRole);
        if (match !== undefined && match$1 !== undefined) {
          AnalyticsRe.memberRoleChanged(schemaGroup, match, memberId, match$1, schemaGroup.branchId, schemaGroup.schemaId);
        }
        return Promise.resolve(undefined);
      });
  
}

function updateUserFilter(schemaId, member, filter) {
  var userId = member.id;
  var aclRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("acls").doc(userId);
  var tmp = {
    id: member.id,
    role: member.role,
    type_: member.type_,
    createdAt: App.default.firestore.FieldValue.serverTimestamp()
  };
  var tmp$1 = Caml_option.undefined_to_opt(member.jobFunction);
  if (tmp$1 !== undefined) {
    tmp.jobFunction = Caml_option.valFromOption(tmp$1);
  }
  if (filter !== undefined) {
    tmp.filter = Caml_option.valFromOption(filter);
  }
  aclRef.set(tmp);
  
}

function MembersRow(Props) {
  var schema = Props.schema;
  var model = Props.model;
  var member = Props.member;
  var workspaceLimits = Props.workspaceLimits;
  var fetchedUser = Props.user;
  var viewerRole = Props.viewerRole;
  var viewerId = Props.viewerId;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = Workspace.User.getUsedSlots(workspace);
  var userCount = match[0];
  var schemaGroup = SchemaGroupContext.use(undefined);
  var commentOnlyFreeSlots = Workspace.User.commentOnlyFreeSlots(workspace);
  var globalSend = GlobalSendContext.use(undefined);
  var hasCollaboration = AvoLimits.Collaboration.isAvailable(workspace);
  var hasTags = StateUtils.getAllTags(model.events) !== /* [] */0;
  var match$1 = AvoLimits.Editors.computeAvailability(workspace);
  var editorsAvailable = match$1 === "AvailableDuringTrial" || match$1 === "Available";
  var match$2 = TrialRequest.useTrialRequest(schema.id);
  var trialStatus = match$2[0];
  var hasCodegenAccessRole = AppFeatureFlag.useFeatureFlag("CodegenAccessRole");
  if (typeof fetchedUser !== "object") {
    if (fetchedUser === "NotFound") {
      return "Could not load user.";
    } else {
      return React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.px(73)),
                        tl: {
                          hd: Css.width(Css.pct(100)),
                          tl: {
                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
                            tl: /* [] */0
                          }
                        }
                      })
                });
    }
  }
  var user = fetchedUser.VAL;
  var match$3 = AvoLimits.User.entityStatus(workspaceLimits, user.id);
  var userAvailableOnPlan = match$3[1];
  var userAvailability = match$3[0];
  var disabled = userAvailability === "Unavailable";
  var isAvoTeamMember = user.email.endsWith("@avo.sh");
  var match$4 = user.name;
  var tmp;
  if (viewerRole === "Admin") {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Select.make, {
              disabled: member.id === viewerId,
              onSelect: (function (value) {
                  var role = Models.Role.tFromJs(value);
                  if (role === undefined) {
                    return Pervasives.failwith("invalid role option");
                  }
                  var currentRole = AnalyticsRe.roleFromJs(member.role);
                  var match = AvoLimits.User.createActionStatus(workspaceLimits);
                  var userMayHaveSeat = match === "AvailableDuringTrial" || match === "Available";
                  var match$1 = AvoLimits.User.entityStatus(workspaceLimits, user.id)[0];
                  var seatTakenAnyway = match$1 === "Available" && currentRole !== undefined ? currentRole === "Admin" || currentRole === "CommentOnly" || currentRole === "Editor" : false;
                  var seatAvailableAsViewer = (role === "Viewer" || role === "CommentOnly" || role === "BillingOnly") && (seatTakenAnyway || !workspace.plan.countsViewersAsUsers);
                  var needsCollaboration = role === "CommentOnly" && !hasCollaboration;
                  var match$2 = workspace.plan.usersIncluded;
                  var match$3 = (userMayHaveSeat || seatTakenAnyway || seatAvailableAsViewer) && !needsCollaboration;
                  var exit = 0;
                  if (editorsAvailable) {
                    exit = 2;
                  } else {
                    if (role === "Editor") {
                      return Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "BillingPrompt",
                                    VAL: "ChangeMemberRole"
                                  }
                                });
                    }
                    exit = 2;
                  }
                  if (exit === 2 && currentRole !== undefined && (currentRole === "CommentOnly" || currentRole === "Viewer") && typeof match$2 === "object" && match$2.NAME === "Limited" && role !== "Viewer" && !(role === "CommentOnly" && workspace.plan.commentOnlyMembers === /* CountAsEditors */1) && workspace.plan.costPerExtraSeat > 0 && userCount >= match$2.VAL) {
                    var membersCount = StripeUtils.countPayingAndAdditionalWorkspaceMembers(Belt_List.toArray(workspace.members), workspace.plan.usersIncluded, workspace.plan);
                    var extraCommenterSeatCost = workspace.plan.commentOnlyMembers;
                    var tmp;
                    tmp = typeof extraCommenterSeatCost === "number" ? 0 : (
                        extraCommenterSeatCost.TAG === /* AdditionalCost */0 ? extraCommenterSeatCost._0 : 0
                      );
                    return Curry._1(globalSend, {
                                TAG: /* OpenModal */4,
                                _0: {
                                  NAME: "AddExtraSeat",
                                  VAL: {
                                    submitInvite: (function (param) {
                                        return updateMemberRole(schema.id, member, value, user.email, viewerId, schemaGroup);
                                      }),
                                    email: user.email,
                                    plan: workspace.plan,
                                    extraAdminEditorSeatCost: workspace.plan.costPerExtraSeat,
                                    existingAdminEditorExtraSeats: (membersCount.adminsOverBaseline + membersCount.editorsOverBaseline | 0) + (
                                      workspace.plan.commentOnlyMembers === /* CountAsEditors */1 ? membersCount.commentersOverBaseline : 0
                                    ) | 0,
                                    extraCommenterSeatCost: tmp,
                                    existingCommenterExtraSeats: workspace.plan.commentOnlyMembers === /* CountAsEditors */1 ? 0 : membersCount.commentersOverBaseline,
                                    role: role
                                  }
                                }
                              });
                  }
                  if (match$3) {
                    return updateMemberRole(schema.id, member, value, user.email, viewerId, schemaGroup);
                  } else if (role === "CommentOnly" && workspace.plan.commentOnlyMembers === /* NotAvailable */0) {
                    return Curry._1(globalSend, {
                                TAG: /* OpenModal */4,
                                _0: {
                                  NAME: "BillingPrompt",
                                  VAL: "InviteCommentOnly"
                                }
                              });
                  } else {
                    return Curry._1(globalSend, {
                                TAG: /* OpenModal */4,
                                _0: {
                                  NAME: "BillingPrompt",
                                  VAL: "ChangeMemberRole"
                                }
                              });
                  }
                }),
              options: Belt_List.map(Models.roles(hasCodegenAccessRole), (function (role) {
                      var optionIsSelected = member.role === Models.Role.tToJs(role);
                      var match = AvoLimits.User.createActionStatus(workspaceLimits);
                      var userMayHaveSeatOnPlan = match === "AvailableDuringTrial" || match === "Available";
                      var seatTakenAnyway = AvoLimits.User.entityStatus(workspaceLimits, user.id)[0] === "Available";
                      var seatAvailableAsViewer = seatTakenAnyway || !workspace.plan.countsViewersAsUsers;
                      var match$1 = workspace.plan.usersIncluded;
                      var nextSeatRequiresExtraBilling = typeof match$1 === "object" && match$1.NAME === "Limited" && workspace.plan.costPerExtraSeat > 0 && trialStatus !== /* Ongoing */1 ? userCount >= match$1.VAL : false;
                      var match$2 = AnalyticsRe.roleFromJs(member.role);
                      var isAvailable;
                      if (optionIsSelected || match$2 === undefined) {
                        isAvailable = true;
                      } else {
                        var exit = 0;
                        if (role === "BillingOnly") {
                          isAvailable = true;
                        } else if (role === "Viewer") {
                          isAvailable = seatAvailableAsViewer;
                        } else if (role === "CommentOnly") {
                          if (hasCollaboration) {
                            if (commentOnlyFreeSlots > 0) {
                              isAvailable = true;
                            } else {
                              exit = 1;
                            }
                          } else {
                            isAvailable = false;
                          }
                        } else {
                          exit = 1;
                        }
                        if (exit === 1) {
                          isAvailable = match$2 === "CommentOnly" || match$2 === "Viewer" ? userMayHaveSeatOnPlan && !nextSeatRequiresExtraBilling : (
                              role === "Editor" && !editorsAvailable ? false : seatTakenAnyway
                            );
                        }
                        
                      }
                      return [
                              {
                                NAME: "Label",
                                VAL: Models.Role.getLabel(role) + (
                                  isAvailable ? "" : " ⚡️"
                                )
                              },
                              Models.Role.tToJs(role)
                            ];
                    })),
              value: member.role
            }), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.px(8)),
                    tl: /* [] */0
                  })
            }), member.id === viewerId && !isAvoTeamMember ? React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.minWidth(Css.px(26)),
                      tl: /* [] */0
                    })
              }, React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.grey70,
                    children: "You"
                  })) : React.createElement(ContextMenu.make, {
                options: [{
                    NAME: "Option",
                    VAL: {
                      label: "Remove Member",
                      onClick: (function (param) {
                          return removeMember(schema.id, member, Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), user.email), user.email, viewerId, schemaGroup, globalSend);
                        })
                    }
                  }]
              }));
  } else {
    var match$5 = member.id;
    var exit = 0;
    if (isAvoTeamMember && match$5 === viewerId) {
      tmp = React.createElement(ContextMenu.make, {
            options: [{
                NAME: "Option",
                VAL: {
                  label: "Remove Member",
                  onClick: (function (param) {
                      return removeMember(schema.id, member, Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), user.email), user.email, viewerId, schemaGroup, globalSend);
                    })
                }
              }]
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = match$5 === viewerId ? React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.grey70,
              children: member.role + " (You)"
            }) : React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.grey70,
              children: member.role
            });
    }
    
  }
  var match$6 = Models.Role.tFromJs(member.role);
  var tmp$1;
  if (match$6 !== undefined && match$6 === "CommentOnly" && (hasTags || Belt_Option.isSome(Caml_option.undefined_to_opt(member.filter)))) {
    var filter = member.filter;
    tmp$1 = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.small),
                    tl: {
                      hd: Css.color(Styles.Color.grey70),
                      tl: {
                        hd: Css.fontWeight(Styles.FontWeight.semi),
                        tl: {
                          hd: Css.paddingTop(Css.px(2)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(48)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              })
        }, React.createElement("input", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(0), Css.px(15)),
                    tl: {
                      hd: Css.marginRight(Css.px(10)),
                      tl: /* [] */0
                    }
                  }),
              checked: Belt_Option.isSome(Caml_option.undefined_to_opt(member.filter)),
              type: "checkbox",
              onChange: (function (domEvent) {
                  var isChecked = domEvent.target.checked;
                  return updateUserFilter(schema.id, member, isChecked ? ({
                                  tags: []
                                }) : undefined);
                })
            }), filter !== undefined ? React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    })
              }, "Limited to tags:", React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginLeft(Css.px(5)),
                          tl: /* [] */0
                        })
                  }, React.createElement(TagsSelect.make, {
                        events: model.events,
                        selectedTags: Belt_List.fromArray(filter.tags),
                        onSelect: (function (tag) {
                            var filter = member.filter;
                            var nextFilters = filter !== undefined ? ({
                                  tags: Belt_Array.concat(filter.tags, [tag])
                                }) : ({
                                  tags: [tag]
                                });
                            return updateUserFilter(schema.id, member, nextFilters);
                          }),
                        onRemove: (function (tag) {
                            var filter = member.filter;
                            var nextFilters = filter !== undefined ? ({
                                  tags: Belt_Array.keep(filter.tags, (function (item) {
                                          return item !== tag;
                                        }))
                                }) : undefined;
                            return updateUserFilter(schema.id, member, nextFilters);
                          })
                      }))) : "Limit to tags");
  } else {
    tmp$1 = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingTop(Css.px(16)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(16)),
                      tl: {
                        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("spaceBetween"),
                            tl: {
                              hd: Css.flexGrow(1.0),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.opacity(disabled ? 0.4 : 1),
                            tl: /* [] */0
                          })
                    }, React.createElement(ProfilePhoto.make, {
                          user: user,
                          size: 40
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("column"),
                              tl: {
                                hd: Css.marginLeft(Css.px(12)),
                                tl: {
                                  hd: Css.flexGrow(1.0),
                                  tl: {
                                    hd: Css.opacity(disabled ? 0.4 : 1),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("baseline"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              children: AvoConfig.getUserDisplayName(user)
                            }), isAvoTeamMember ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    width: 6
                                  }), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.marginTop(Css.px(-3)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(Icons.AvoLogo.make, {
                                        width: 22,
                                        fill: Styles.Color.grey90
                                      }))) : null, viewerId === user.id && viewerRole !== "Admin" ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    width: 6
                                  }), React.createElement($$Text.make, {
                                    size: "Small",
                                    color: Styles.Color.grey70,
                                    children: "(You)"
                                  })) : null), (match$4 == null) ? null : React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.grey50,
                            children: user.email
                          })), userAvailability === "Available" ? null : (
                    userAvailability === "AvailableDuringTrial" ? React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.margin2(Css.px(0), Css.px(10)),
                                  tl: {
                                    hd: Css_Legacy_Core.SVG.fill("transparent"),
                                    tl: {
                                      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey50),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(PlanLightning.make, {
                                tooltipText: "Available during trial and on the " + (Belt_Option.mapWithDefault(userAvailableOnPlan, "", (function (plan) {
                                          return Workspace.printPlanName(plan.name);
                                        })) + " plan")
                              })) : React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.margin2(Css.px(0), Css.px(10)),
                                  tl: {
                                    hd: Css_Legacy_Core.SVG.fill("transparent"),
                                    tl: {
                                      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey50),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(PlanLightning.make, {
                                tooltipText: "Available on the " + (Belt_Option.mapWithDefault(userAvailableOnPlan, "", (function (plan) {
                                          return Workspace.printPlanName(plan.name);
                                        })) + " plan")
                              }))
                  ), tmp), tmp$1);
}

var make = MembersRow;

exports.removeMember = removeMember;
exports.updateMemberRole = updateMemberRole;
exports.updateUserFilter = updateUserFilter;
exports.make = make;
/* Css Not a pure module */
