// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var SettingsSources = require("./SettingsSources.bs.js");
var SettingsDestinations = require("./SettingsDestinations.bs.js");

function Connections(Props) {
  var schema = Props.schema;
  var model = Props.model;
  var globalSend = Props.globalSend;
  var match = React.useState(function () {
        
      });
  var setActiveItem = match[1];
  var activeItem = match[0];
  var itemCount = Belt_List.length(model.sources) > Belt_List.length(model.destinations) ? Belt_List.length(model.sources) : Belt_List.length(model.destinations);
  var graphHeight = Math.imul(itemCount, 70);
  var destinations = Belt_List.toArray(model.destinations);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("flexStart"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.overflow("auto"),
                          tl: {
                            hd: Css.minWidth(Css.px(880)),
                            tl: {
                              hd: Css.margin4(Css.px(0), Css.px(0), Css.px(50), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(SettingsSources.make, {
                  schema: schema,
                  model: model,
                  onHover: (function (sourceId) {
                      return Curry._1(setActiveItem, (function (param) {
                                    return {
                                            NAME: "Source",
                                            VAL: sourceId
                                          };
                                  }));
                    }),
                  onLeave: (function (sourceId) {
                      return Curry._1(setActiveItem, (function (activeItem) {
                                    if (activeItem !== undefined && Caml_obj.caml_equal(activeItem, {
                                            NAME: "Source",
                                            VAL: sourceId
                                          })) {
                                      return ;
                                    } else {
                                      return activeItem;
                                    }
                                  }));
                    }),
                  globalSend: globalSend
                }), React.createElement("svg", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(65)),
                        tl: {
                          hd: Css.height(Css.px(graphHeight)),
                          tl: {
                            hd: Css.width(Css.px(150)),
                            tl: {
                              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey40Bg),
                              tl: {
                                hd: Css_Legacy_Core.SVG.fill("transparent"),
                                tl: {
                                  hd: Css.flexShrink(0.0),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, Belt_List.toArray(Belt_List.flatten(Belt_List.mapWithIndexU(model.sources, (function (sourceIndex, source) {
                                return Belt_List.keepMapU(source.destinations, (function (param) {
                                              var destinationId = param.destinationId;
                                              var maybeDestination = Belt_List.getByU(model.destinations, (function (destination) {
                                                      return destination.id === destinationId;
                                                    }));
                                              if (maybeDestination === undefined) {
                                                return ;
                                              }
                                              if (!(Belt_Option.isSome(maybeDestination.type_) && AvoUtils.isDestinationSupportedForSource(source.platform, source.language, Belt_Option.getExn(maybeDestination.type_)))) {
                                                return ;
                                              }
                                              var destinationIndex = Belt_Option.getExn(Belt_Array.getIndexByU(destinations, (function (destination) {
                                                          return destination.id === destinationId;
                                                        })));
                                              return Caml_option.some(React.createElement("path", {
                                                              key: source.id + destinationId,
                                                              className: Curry._1(Css.style, activeItem !== undefined ? (
                                                                      activeItem.NAME === "Destination" ? (
                                                                          activeItem.VAL === destinationId ? ({
                                                                                hd: Css_Legacy_Core.SVG.stroke(Styles.Color.magenta),
                                                                                tl: /* [] */0
                                                                              }) : /* [] */0
                                                                        ) : (
                                                                          activeItem.VAL === source.id ? ({
                                                                                hd: Css_Legacy_Core.SVG.stroke(Styles.Color.magenta),
                                                                                tl: /* [] */0
                                                                              }) : /* [] */0
                                                                        )
                                                                    ) : /* [] */0),
                                                              d: "M0," + String(35 + Math.imul(sourceIndex, 70) | 0) + " C100," + String(35 + Math.imul(sourceIndex, 70) | 0) + "  100," + String(35 + Math.imul(destinationIndex, 70) | 0) + " 150," + String(35 + Math.imul(destinationIndex, 70) | 0),
                                                              strokeWidth: "4"
                                                            }));
                                            }));
                              }))))), React.createElement(SettingsDestinations.make, {
                  model: model,
                  onHover: (function (sourceId) {
                      return Curry._1(setActiveItem, (function (param) {
                                    return {
                                            NAME: "Destination",
                                            VAL: sourceId
                                          };
                                  }));
                    }),
                  onLeave: (function (sourceId) {
                      return Curry._1(setActiveItem, (function (activeItem) {
                                    if (activeItem !== undefined && Caml_obj.caml_equal(activeItem, {
                                            NAME: "Destination",
                                            VAL: sourceId
                                          })) {
                                      return ;
                                    } else {
                                      return activeItem;
                                    }
                                  }));
                    }),
                  globalSend: globalSend
                }));
}

var make = Connections;

exports.make = make;
/* Css Not a pure module */
