// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Snippet = require("./Snippet.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var AppFeatureFlag = require("./AppFeatureFlag.bs.js");
var CodegenCommons = require("../../codegen/src/CodegenCommons.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");

function SourceSnippet(Props) {
  var model = Props.model;
  var source = Props.source;
  var $$event = Props.event;
  var useServerTrackingWithDeviceId = AppFeatureFlag.useFeatureFlag("ServerTrackingWithDeviceId");
  var schemaGroup = SchemaGroupContext.use(undefined);
  var snippet = CodegenCommons.snippet(useServerTrackingWithDeviceId, model, source, $$event);
  return React.createElement("div", undefined, source.platform !== "Gtm" ? React.createElement("pre", {
                    className: Curry._1(Css.style, {
                          hd: Css.fontFamily(Styles.monoFontFamily),
                          tl: {
                            hd: Css.padding2(Css.px(10), Css.px(20)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.grey20),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.regular),
                                  tl: {
                                    hd: Css.color(Styles.Color.grey90),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, "avo pull --branch " + schemaGroup.branchName + " \"" + source.name + "\"") : null, React.createElement(Snippet.make, {
                  onClick: (function (param) {
                      return AnalyticsRe.codeSnippetInteracted(schemaGroup, AnalyticsRe.Group.source(source.id, AvoConfig.getSourceValidName(source)), $$event.id, $$event.name, "CopyButtonClick", schemaGroup.branchId, schemaGroup.schemaId);
                    }),
                  language: source.language,
                  snippet: snippet
                }));
}

var make = SourceSnippet;

exports.make = make;
/* Css Not a pure module */
