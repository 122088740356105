// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconLogout(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey70;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("svg", {
                  width: String(size),
                  viewBox: "0 0 16 16",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("g", {
                      className: "nc-icon-wrapper",
                      strokeWidth: "1"
                    }, React.createElement("path", {
                          d: "M6.5,5.5v-3 c0-0.552,0.448-1,1-1h7c0.552,0,1,0.448,1,1v12c0,0.552-0.448,1-1,1h-7c-0.552,0-1-0.448-1-1v-3",
                          fill: "none",
                          strokeLinecap: "round"
                        }), React.createElement("line", {
                          fill: "none",
                          strokeLinecap: "round",
                          x1: "11.5",
                          x2: "0.5",
                          y1: "8.5",
                          y2: "8.5"
                        }), React.createElement("polyline", {
                          fill: "none",
                          points: "3.5,5.5 0.5,8.5 3.5,11.5 ",
                          strokeLinecap: "round"
                        }))));
}

var make = IconLogout;

exports.make = make;
/* Css Not a pure module */
