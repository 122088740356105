// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Uuid = require("uuid");
var React = require("react");

function IconWebhookIntegration(Props) {
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var match = React.useState(function () {
        return Uuid.v4();
      });
  var id = match[0];
  return React.createElement("svg", {
              height: String(size),
              width: String(size),
              fill: "none",
              viewBox: "0 0 40 40",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("rect", {
                  height: "40",
                  width: "40",
                  fill: "url(#" + (id + ")"),
                  rx: "10"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M19.5972 18.2595C18.5219 20.0672 17.4916 21.8178 16.4399 23.5552C16.1698 24.0012 16.0362 24.3644 16.252 24.9314C16.8476 26.4977 16.0073 28.0219 14.4278 28.4356C12.9383 28.8259 11.4871 27.8469 11.1916 26.2522C10.9297 24.8407 12.0251 23.457 13.5813 23.2364C13.7117 23.2177 13.8448 23.2155 14.064 23.1991C14.8266 21.9204 15.6078 20.6106 16.4313 19.2295C14.9424 17.749 14.0562 16.0183 14.2523 13.8738C14.391 12.3578 14.9871 11.0479 16.0766 9.97411C18.1634 7.91798 21.347 7.58498 23.8041 9.16324C26.1639 10.6792 27.2447 13.6321 26.3234 16.1595C25.6288 15.9712 24.9293 15.7812 24.1603 15.5727C24.4496 14.1675 24.2356 12.9056 23.2879 11.8247C22.6618 11.111 21.8583 10.7369 20.9447 10.599C19.1131 10.3223 17.3149 11.4991 16.7813 13.2967C16.1756 15.337 17.0923 17.0037 19.5972 18.2595Z",
                  fill: "#EC008C",
                  fillRule: "evenodd"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M22.6684 16.1216C23.426 17.458 24.1952 18.8147 24.9576 20.1589C28.811 18.9668 31.7162 21.0998 32.7585 23.3836C34.0174 26.1422 33.1568 29.4095 30.6844 31.1114C28.1465 32.8586 24.9371 32.56 22.6886 30.3157C23.2617 29.8361 23.8375 29.3542 24.4528 28.8395C26.6737 30.2779 28.616 30.2102 30.058 28.5069C31.2876 27.0538 31.261 24.8873 29.9957 23.4647C28.5355 21.8231 26.5795 21.773 24.2152 23.3489C23.2344 21.609 22.2366 19.8828 21.2869 18.1302C20.9667 17.5396 20.6131 17.1969 19.8914 17.0719C18.686 16.8629 17.9078 15.8279 17.8611 14.6682C17.8152 13.5213 18.4908 12.4846 19.5469 12.0807C20.593 11.6805 21.8207 12.0035 22.5243 12.8929C23.0994 13.6197 23.2821 14.4375 22.9796 15.3338C22.8954 15.5836 22.7864 15.8255 22.6684 16.1216Z",
                  fill: "white",
                  fillRule: "evenodd"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M24.4793 27.0472C22.9556 27.0472 21.4262 27.0472 19.8405 27.0472C19.3959 28.8759 18.4356 30.3524 16.7808 31.2913C15.4944 32.021 14.1079 32.2685 12.6322 32.0302C9.91532 31.592 7.69369 29.1462 7.49813 26.392C7.27673 23.2721 9.42122 20.4989 12.2796 19.876C12.477 20.5927 12.6763 21.3162 12.8737 22.0311C10.2511 23.3691 9.34348 25.055 10.0774 27.163C10.7236 29.0182 12.5589 30.035 14.5517 29.6417C16.5868 29.2402 17.6129 27.5489 17.4877 24.8346C19.417 24.8346 21.3479 24.8145 23.2774 24.8444C24.0307 24.8562 24.6124 24.7781 25.18 24.1139C26.1145 23.0209 27.8344 23.1195 28.8409 24.1517C29.8693 25.2066 29.82 26.9041 28.7317 27.9146C27.6816 28.8895 26.0225 28.8374 25.0402 27.7869C24.8382 27.5703 24.6791 27.3133 24.4793 27.0472Z",
                  fill: "white",
                  fillRule: "evenodd"
                }), React.createElement("defs", undefined, React.createElement("linearGradient", {
                      id: id,
                      gradientUnits: "userSpaceOnUse",
                      x1: "20",
                      x2: "20",
                      y1: "0",
                      y2: "40"
                    }, React.createElement("stop", {
                          stopColor: "#242424"
                        }), React.createElement("stop", {
                          offset: "1"
                        }))));
}

var make = IconWebhookIntegration;

exports.make = make;
/* uuid Not a pure module */
