// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var defaultOrder = [
  "firstSeen",
  "desc"
];

exports.defaultOrder = defaultOrder;
/* No side effect */
