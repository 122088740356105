// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Traditional = require("zustand/traditional");

var cmp = Object.is;

var Default = {
  cmp: cmp
};

var Compare = {
  Default: Default
};

function MakeStore(C) {
  var getState = function (store) {
    return Curry._1(store.getState, undefined);
  };
  var use = function (store, selector) {
    return store(selector);
  };
  return {
          getState: getState,
          use: use
        };
}

function MakeEqualityFnStore(C, Cmp) {
  var getState = function (store) {
    return Curry._1(store.getState, undefined);
  };
  var use = function (store, selector) {
    return store(selector, Cmp.cmp);
  };
  var useWithCustomEqualityFn = function (store, selector, cmp) {
    return store(selector, cmp);
  };
  var create = function (storeFn) {
    return Traditional.createWithEqualityFn(storeFn, Cmp.cmp);
  };
  return {
          getState: getState,
          use: use,
          useWithCustomEqualityFn: useWithCustomEqualityFn,
          create: create
        };
}

exports.Compare = Compare;
exports.MakeStore = MakeStore;
exports.MakeEqualityFnStore = MakeEqualityFnStore;
/* cmp Not a pure module */
