// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var CmdK = require("./CmdK.bs.js");
var Kbar = require("kbar");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Metric = require("./Metric.bs.js");
var Portal = require("./Portal.bs.js");
var Router = require("./Router.bs.js");
var Styles = require("./styles.bs.js");
var $$Window = require("./externals/window.bs.js");
var Category = require("./Category.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Destination = require("./Destination.bs.js");
var EventsSheet = require("./EventsSheet.bs.js");
var Integration = require("./integration/Integration.bs.js");
var RouterStore = require("./RouterStore.bs.js");
var DrawerHeader = require("./detailviews/DrawerHeader.bs.js");
var EventDetails = require("./detailviews/EventDetails.bs.js");
var SourceConfig = require("./SourceConfig.bs.js");
var FramerMotion = require("framer-motion");
var PropertyDetails = require("./detailviews/PropertyDetails.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var CodegenSourceDetails = require("./CodegenSourceDetails.bs.js");
var PropertyGroupDetails = require("./detailviews/PropertyGroupDetails.bs.js");
var TriggerFullscreenModal = require("./TriggerFullscreenModal.bs.js");
var InspectorIssueDetailsV2 = require("./InspectorIssueDetailsV2.bs.js");

function DrawerContainer$ItemNotFound(Props) {
  var kind = Props.kind;
  return React.createElement(React.Fragment, undefined, React.createElement(DrawerHeader.make, {
                  kind: kind,
                  children: "Not Found"
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.pct(100.0)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, "Oh noes, this item wasn’t found"));
}

var ItemNotFound = {
  make: DrawerContainer$ItemNotFound
};

var slideOverStyles = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.width(Css.px(700)),
              tl: {
                hd: Css.zIndex(1),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: {
                    hd: Css.boxShadow(Styles.Shadow.standard),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function getItemName(model, itemType, itemId) {
  if (itemType === "Destination") {
    return Belt_Option.map(StateUtils.getDestinationById(itemId, model), StateUtils.getDestinationName);
  } else if (itemType === "Integration") {
    return Belt_Option.map(StateUtils.getIntegrationById(itemId, model), AvoConfig.getIntegrationName);
  } else if (itemType === "Property") {
    return Belt_Option.map(StateUtils.resolvePropertyById(itemId, model), (function (param) {
                  return param.name;
                }));
  } else if (itemType === "Event") {
    return Belt_Option.map(StateUtils.getEventById(itemId, model), (function (param) {
                  return param.name;
                }));
  } else if (itemType === "PropertyGroup") {
    return Belt_Option.map(StateUtils.getPropertyGroupById(itemId, model), (function (param) {
                  return param.name;
                }));
  } else if (itemType === "Goal") {
    return Belt_Option.map(StateUtils.getGoalById(itemId, model), (function (param) {
                  return param.name;
                }));
  } else if (itemType === "InspectorIssue") {
    return ;
  } else if (itemType === "Metric") {
    return Belt_Option.map(StateUtils.getMetricById(itemId, model), (function (param) {
                  return param.name;
                }));
  } else {
    return Belt_Option.map(StateUtils.getSourceById(itemId, model), AvoConfig.getSourceName);
  }
}

function useAnalyticsForDrawerContainer(topDrawerItem, model) {
  var schemaGroup = SchemaGroupContext.use(undefined);
  React.useEffect((function () {
          var maybeAnalyticsItem;
          if (topDrawerItem !== undefined) {
            var variant = topDrawerItem.NAME;
            if (variant === "codegenSource") {
              var itemId = topDrawerItem.VAL;
              maybeAnalyticsItem = [
                itemId,
                "CodegenSource",
                getItemName(model, "CodegenSource", itemId)
              ];
            } else if (variant === "event") {
              var itemId$1 = topDrawerItem.VAL[0];
              maybeAnalyticsItem = [
                itemId$1,
                "Event",
                getItemName(model, "Event", itemId$1)
              ];
            } else if (variant === "inspectorIssue") {
              var itemId$2 = topDrawerItem.VAL;
              maybeAnalyticsItem = [
                itemId$2,
                "InspectorIssues",
                getItemName(model, "InspectorIssue", itemId$2)
              ];
            } else if (variant === "metric") {
              var itemId$3 = topDrawerItem.VAL[0];
              maybeAnalyticsItem = [
                itemId$3,
                "Metric",
                getItemName(model, "Metric", itemId$3)
              ];
            } else if (variant === "destination") {
              var itemId$4 = topDrawerItem.VAL;
              maybeAnalyticsItem = [
                itemId$4,
                "Destination",
                getItemName(model, "Destination", itemId$4)
              ];
            } else if (variant === "integration") {
              var itemId$5 = topDrawerItem.VAL[0];
              maybeAnalyticsItem = [
                itemId$5,
                "Integration",
                getItemName(model, "Integration", itemId$5)
              ];
            } else if (variant === "property") {
              var itemId$6 = topDrawerItem.VAL[0];
              maybeAnalyticsItem = [
                itemId$6,
                "Property",
                getItemName(model, "Property", itemId$6)
              ];
            } else if (variant === "category") {
              var itemId$7 = topDrawerItem.VAL[0];
              maybeAnalyticsItem = [
                itemId$7,
                "Goal",
                getItemName(model, "Goal", itemId$7)
              ];
            } else if (variant === "source") {
              var itemId$8 = topDrawerItem.VAL[0];
              maybeAnalyticsItem = [
                itemId$8,
                "Source",
                getItemName(model, "Source", itemId$8)
              ];
            } else {
              var itemId$9 = topDrawerItem.VAL[0];
              maybeAnalyticsItem = [
                itemId$9,
                "PropertyGroup",
                getItemName(model, "PropertyGroup", itemId$9)
              ];
            }
          } else {
            maybeAnalyticsItem = undefined;
          }
          if (maybeAnalyticsItem !== undefined) {
            AnalyticsRe.itemDetailsOpened(schemaGroup, maybeAnalyticsItem[0], Belt_Option.getWithDefault(maybeAnalyticsItem[2], ""), maybeAnalyticsItem[1], schemaGroup.schemaId);
          }
          
        }), [topDrawerItem]);
  
}

function DrawerContainer(Props) {
  var branchStatus = Props.branchStatus;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var masterModel = Props.masterModel;
  var member = Props.member;
  var model = Props.model;
  var renderModel = Props.renderModel;
  var role = Props.role;
  var schema = Props.schema;
  var match = RouterStore.Schema.useSlices(function (param) {
        return [
                param.branch,
                param.drawerItems
              ];
      });
  var drawerItems = match[1];
  var branch = match[0];
  var currentBranch = React.useMemo((function () {
          if (typeof branch === "object") {
            return {
                    NAME: "Branch",
                    VAL: branch.VAL
                  };
          } else {
            return "Master";
          }
        }), [branch]);
  var branchModel = typeof currentBranch === "object" ? ({
        TAG: /* Branch */1,
        _0: renderModel,
        _1: masterModel
      }) : ({
        TAG: /* Main */0,
        _0: renderModel
      });
  var match$1;
  if (drawerItems) {
    var otherDrawerItems = drawerItems.tl;
    var topDrawerItem = drawerItems.hd;
    match$1 = otherDrawerItems ? [
        topDrawerItem,
        otherDrawerItems
      ] : [
        topDrawerItem,
        /* [] */0
      ];
  } else {
    match$1 = [
      undefined,
      /* [] */0
    ];
  }
  var otherDrawerItems$1 = match$1[1];
  var topDrawerItem$1 = match$1[0];
  var currentBranch$1;
  if (typeof branch === "object" && branch.NAME === "branch") {
    var branchId = branch.VAL;
    currentBranch$1 = branchId !== "master" ? ({
          NAME: "Branch",
          VAL: branchId
        }) : "Master";
  } else {
    currentBranch$1 = "Master";
  }
  var disableHotKeys = Belt_Option.mapWithDefault(topDrawerItem$1, false, (function (item) {
          if (typeof item === "object" && item.NAME === "event") {
            return item.VAL[2] !== undefined;
          } else {
            return false;
          }
        }));
  useAnalyticsForDrawerContainer(topDrawerItem$1, model);
  Kbar.useRegisterActions(Belt_List.length(drawerItems) > 0 && !disableHotKeys ? [Curry.app(CmdK.Action.t, [
                "drawer_close",
                {
                  TAG: /* RootItem */0,
                  _0: "Close Drawer"
                },
                1.1,
                ["escape"],
                undefined,
                undefined,
                (function (param) {
                    var closeButtonElement = $$Window.$$Document.getElementById("drawer-container-close");
                    return Belt_Option.forEach(closeButtonElement, (function (prim) {
                                  prim.click();
                                  
                                }));
                  }),
                undefined,
                undefined
              ])] : [], [
        drawerItems,
        disableHotKeys
      ]);
  Kbar.useRegisterActions(Belt_List.length(drawerItems) > 1 && !disableHotKeys ? [Curry.app(CmdK.Action.t, [
                "drawer_prev",
                {
                  TAG: /* RootItem */0,
                  _0: "Go to previous drawer item"
                },
                1.1,
                ["arrowleft"],
                undefined,
                undefined,
                (function (param) {
                    return Router.Schema.popDrawerItem(undefined);
                  }),
                undefined,
                undefined
              ])] : [], [
        drawerItems,
        disableHotKeys
      ]);
  var tmp;
  if (topDrawerItem$1 !== undefined && typeof topDrawerItem$1 === "object" && topDrawerItem$1.NAME === "event") {
    var match$2 = topDrawerItem$1.VAL;
    var triggerId = match$2[2];
    if (triggerId !== undefined) {
      var commentId = match$2[1];
      var eventId = match$2[0];
      var $$event = StateUtils.getEventById(eventId, model);
      var triggerExists = Belt_Option.flatMap($$event, (function ($$event) {
              return Belt_Array.getBy($$event.triggers, (function (param) {
                            return param.id === triggerId;
                          }));
            })) !== undefined || triggerId === "new";
      if ($$event !== undefined) {
        if (triggerExists) {
          tmp = React.createElement(TriggerFullscreenModal.make, {
                currentBranch: currentBranch$1,
                event: $$event,
                triggerId: triggerId,
                modelSources: model.sources,
                numEvents: Belt_List.length(model.events),
                onClose: (function (param) {
                    return Router.Schema.swapTopDrawerItem({
                                NAME: "event",
                                VAL: [
                                  eventId,
                                  commentId,
                                  undefined,
                                  false
                                ]
                              });
                  }),
                viewerCanEdit: role === "Admin" || role === "Editor"
              });
        } else {
          Router.Schema.swapTopDrawerItem({
                NAME: "event",
                VAL: [
                  eventId,
                  commentId,
                  undefined,
                  false
                ]
              });
          tmp = null;
        }
      } else {
        Router.Schema.popDrawerItem(undefined);
        tmp = null;
      }
    } else {
      tmp = null;
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Portal.make, {
                  children: React.createElement(FramerMotion.AnimatePresence, {
                        children: Belt_Option.isSome(topDrawerItem$1) ? React.createElement(FramerMotion.motion.div, {
                                id: "drawer-container",
                                animate: {
                                  opacity: 1,
                                  x: 0
                                },
                                transition: {
                                  duration: 0.3
                                },
                                initial: {
                                  opacity: 0,
                                  x: 50
                                },
                                exit: {
                                  opacity: 0,
                                  x: 50
                                },
                                className: slideOverStyles,
                                key: "slideOver",
                                children: Belt_Option.mapWithDefault(topDrawerItem$1, React.createElement(DrawerContainer$ItemNotFound, {
                                          kind: "Item"
                                        }), (function (drawerItem) {
                                        var variant = drawerItem.NAME;
                                        if (variant === "codegenSource") {
                                          return React.createElement(CodegenSourceDetails.make, {
                                                      model: renderModel,
                                                      sourceId: drawerItem.VAL,
                                                      schema: schema,
                                                      rawModel: model,
                                                      role: role
                                                    });
                                        }
                                        if (variant === "event") {
                                          return Belt_Option.mapWithDefault(StateUtils.getEventById(drawerItem.VAL[0], renderModel), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Event"
                                                        }), (function ($$event) {
                                                        return React.createElement(EventDetails.make, {
                                                                    branchStatus: branchStatus,
                                                                    event: $$event,
                                                                    model: renderModel,
                                                                    schema: schema,
                                                                    events: renderModel.events,
                                                                    currentBranch: currentBranch$1,
                                                                    openBranches: masterModel.openBranches,
                                                                    currentFilters: currentFilters,
                                                                    filters: filters,
                                                                    role: role,
                                                                    isEventModified: EventsSheet.isEventModified(branchModel, $$event)
                                                                  });
                                                      }));
                                        }
                                        if (variant === "inspectorIssue") {
                                          var id = drawerItem.VAL;
                                          return React.createElement(InspectorIssueDetailsV2.make, {
                                                      id: id,
                                                      key: id
                                                    });
                                        }
                                        if (variant === "metric") {
                                          var match = drawerItem.VAL;
                                          var maybeCommentId = match[1];
                                          return Belt_Option.mapWithDefault(StateUtils.getMetricById(match[0], model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Metric"
                                                        }), (function (metric) {
                                                        return React.createElement(Metric.make, {
                                                                    currentBranch: currentBranch$1,
                                                                    currentFilters: currentFilters,
                                                                    filters: filters,
                                                                    goToCommentId: maybeCommentId,
                                                                    metric: metric,
                                                                    model: model,
                                                                    openBranches: masterModel.openBranches,
                                                                    role: role,
                                                                    schema: schema,
                                                                    key: metric.id
                                                                  });
                                                      }));
                                        }
                                        if (variant === "destination") {
                                          var destinationId = drawerItem.VAL;
                                          return Belt_Option.mapWithDefault(StateUtils.getDestinationById(destinationId, model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Destination"
                                                        }), (function (param) {
                                                        return React.createElement(Destination.make, {
                                                                    destinationId: destinationId,
                                                                    model: model
                                                                  });
                                                      }));
                                        }
                                        if (variant === "integration") {
                                          var match$1 = drawerItem.VAL;
                                          var goToCommentId = match$1[1];
                                          var integrationId = match$1[0];
                                          return Belt_Option.mapWithDefault(StateUtils.getIntegrationById(integrationId, model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Integration"
                                                        }), (function (param) {
                                                        return React.createElement(Integration.make, {
                                                                    integrationId: integrationId,
                                                                    goToCommentId: goToCommentId,
                                                                    integrations: renderModel.integrations,
                                                                    schema: schema,
                                                                    openBranches: masterModel.openBranches,
                                                                    currentBranch: currentBranch$1,
                                                                    model: model,
                                                                    role: role,
                                                                    key: integrationId
                                                                  });
                                                      }));
                                        }
                                        if (variant === "property") {
                                          var match$2 = drawerItem.VAL;
                                          var maybeCommentId$1 = match$2[1];
                                          var propertyId = match$2[0];
                                          return Belt_Option.mapWithDefault(StateUtils.getPropertyById(propertyId, model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Property"
                                                        }), (function (param) {
                                                        return React.createElement(PropertyDetails.make, {
                                                                    currentBranch: currentBranch$1,
                                                                    currentFilters: currentFilters,
                                                                    filters: filters,
                                                                    eventId: undefined,
                                                                    goToCommentId: maybeCommentId$1,
                                                                    member: member,
                                                                    model: renderModel,
                                                                    openBranches: masterModel.openBranches,
                                                                    propertyId: propertyId,
                                                                    role: role,
                                                                    schema: schema
                                                                  });
                                                      }));
                                        }
                                        if (variant === "category") {
                                          var match$3 = drawerItem.VAL;
                                          var maybeCommentId$2 = match$3[1];
                                          return Belt_Option.mapWithDefault(StateUtils.getGoalById(match$3[0], model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Category"
                                                        }), (function (category) {
                                                        return React.createElement(Category.make, {
                                                                    category: category,
                                                                    currentBranch: currentBranch$1,
                                                                    goToCommentId: maybeCommentId$2,
                                                                    schema: schema,
                                                                    openBranches: masterModel.openBranches,
                                                                    model: model,
                                                                    role: role,
                                                                    currentFilters: currentFilters,
                                                                    key: category.id
                                                                  });
                                                      }));
                                        }
                                        if (variant === "source") {
                                          var match$4 = drawerItem.VAL;
                                          var tab = match$4[1];
                                          var sourceId = match$4[0];
                                          return Belt_Option.mapWithDefault(StateUtils.getSourceById(sourceId, model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Source"
                                                        }), (function (param) {
                                                        return React.createElement(SourceConfig.make, {
                                                                    currentBranch: currentBranch$1,
                                                                    tab: tab,
                                                                    model: renderModel,
                                                                    schema: schema,
                                                                    sourceId: sourceId,
                                                                    viewerRole: role
                                                                  });
                                                      }));
                                        }
                                        var match$5 = drawerItem.VAL;
                                        var goToCommentId$1 = match$5[1];
                                        var groupId = match$5[0];
                                        return Belt_Option.mapWithDefault(StateUtils.getPropertyGroupById(groupId, model), React.createElement(DrawerContainer$ItemNotFound, {
                                                        kind: "Property Bundle"
                                                      }), (function (param) {
                                                      return React.createElement(PropertyGroupDetails.make, {
                                                                  currentBranch: currentBranch$1,
                                                                  currentFilters: currentFilters,
                                                                  filters: filters,
                                                                  event: Belt_Option.flatMapU(Belt_List.get(otherDrawerItems$1, 0), (function (nextItem) {
                                                                          if (typeof nextItem === "object" && nextItem.NAME === "event") {
                                                                            return StateUtils.getEventById(nextItem.VAL[0], model);
                                                                          }
                                                                          
                                                                        })),
                                                                  goToCommentId: goToCommentId$1,
                                                                  groupId: groupId,
                                                                  member: member,
                                                                  model: model,
                                                                  openBranches: masterModel.openBranches,
                                                                  role: role,
                                                                  schema: schema
                                                                });
                                                    }));
                                      }))
                              }) : null
                      })
                }), tmp);
}

var drawerWidth = 700;

var make = DrawerContainer;

exports.ItemNotFound = ItemNotFound;
exports.drawerWidth = drawerWidth;
exports.slideOverStyles = slideOverStyles;
exports.getItemName = getItemName;
exports.useAnalyticsForDrawerContainer = useAnalyticsForDrawerContainer;
exports.make = make;
/* slideOverStyles Not a pure module */
