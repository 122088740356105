// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hover = require("./Hover.bs.js");
var Input = require("./Input.bs.js");
var React = require("react");
var Sentry = require("./externals/Sentry.bs.js");
var Styles = require("./styles.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var DidMount = require("./DidMount.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var IconPhoto = require("./IconPhoto.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ProfilePhoto = require("./ProfilePhoto.bs.js");
var FirebaseUtils = require("./firebaseUtils.bs.js");
var LoadingCircle = require("./LoadingCircle.bs.js");
var ViewerContext = require("./ViewerContext.bs.js");
var InputWithState = require("./InputWithState.bs.js");
var ReactDropzone = require("react-dropzone").default;
var Debounce = require("lodash/debounce");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

function updateName(userId, name) {
  return $$Promise.$$catch(Firebase.app(undefined).firestore().collection("users").doc(userId).set({
                  name: name
                }, {"merge": true}), (function (error) {
                Sentry.captureException(error, {
                      message: "Failed to update user name"
                    });
                return Promise.reject(error);
              }));
}

var debouncedUpdateName = Debounce((function (userId, name) {
        updateName(userId, name);
        
      }), 500, {
      leading: false,
      trailing: true
    });

function ProfileModal(Props) {
  var userId = Props.userId;
  var match = ViewerContext.use(undefined);
  var match$1 = React.useState(function () {
        return /* Idle */0;
      });
  var setUploadStatus = match$1[1];
  var uploadStatus = match$1[0];
  var userState = FirebaseFetcherHooks.useUser(userId);
  var isViewer = userId === match.id;
  var tmp;
  if (typeof userState === "object") {
    var user = userState.VAL;
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("column"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.marginTop(Css.px(-84)),
                      tl: /* [] */0
                    }
                  }
                }
              })
        }, React.createElement(DidMount.make, {
              didMount: (function (param) {
                  AnalyticsRe.profileViewed(user.id, Belt_Option.flatMap(Caml_option.nullable_to_opt(user.picture), (function (picture) {
                              return picture.url;
                            })), Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), user.email), isViewer);
                  
                })
            }), React.createElement(Hover.make, {
              children: (function (isHover) {
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.position("relative"),
                                    tl: {
                                      hd: Css.padding(Css.px(0)),
                                      tl: {
                                        hd: Css.margin(Css.px(0)),
                                        tl: {
                                          hd: Css.borderRadius(Css.pct(50)),
                                          tl: {
                                            hd: Css.backgroundColor(Styles.Color.white),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement(ProfilePhoto.make, {
                                  user: user,
                                  size: 112
                                }), isViewer ? React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.position("absolute"),
                                          tl: {
                                            hd: Css.top(Css.px(0)),
                                            tl: {
                                              hd: Css.right(Css.px(0)),
                                              tl: {
                                                hd: Css.bottom(Css.px(0)),
                                                tl: {
                                                  hd: Css.left(Css.px(0)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, React.createElement(ReactDropzone, {
                                        className: Curry._1(Css.style, {
                                              hd: Css.height(Css.pct(100)),
                                              tl: {
                                                hd: Css.width(Css.pct(100)),
                                                tl: {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.alignItems("center"),
                                                    tl: {
                                                      hd: Css.justifyContent("center"),
                                                      tl: {
                                                        hd: Css.borderRadius(Css.pct(50)),
                                                        tl: {
                                                          hd: Css.backgroundColor(Styles.Color.grey70),
                                                          tl: {
                                                            hd: Css.opacity(uploadStatus ? 0.75 : (
                                                                    isHover ? 0.75 : 0
                                                                  )),
                                                            tl: {
                                                              hd: Css.transition({
                                                                    NAME: "ms",
                                                                    VAL: Styles.Duration.$$default
                                                                  }, undefined, undefined, "all"),
                                                              tl: {
                                                                hd: Css.cursor("pointer"),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }),
                                        onDrop: (function (files) {
                                            var file = Belt_Array.get(files, 0);
                                            if (file !== undefined) {
                                              var file$1 = Caml_option.valFromOption(file);
                                              var userName = Caml_option.nullable_to_opt(user.name);
                                              var hadProfilePic = Belt_Option.isSome(Caml_option.nullable_to_opt(user.picture));
                                              Curry._1(setUploadStatus, (function (param) {
                                                      return /* Uploading */1;
                                                    }));
                                              var storagePath = "/users/" + userId + "/pictures/";
                                              var dbRef = Firebase.app(undefined).firestore().collection("users").doc(userId);
                                              FirebaseUtils.uploadPicture(storagePath, dbRef, file$1).then(function (picture) {
                                                    AnalyticsRe.profileUpdated(userId, userName, "ProfilePic", undefined, undefined, hadProfilePic, true, true, picture.url, picture.url);
                                                    Curry._1(setUploadStatus, (function (param) {
                                                            return /* Idle */0;
                                                          }));
                                                    return Promise.resolve(undefined);
                                                  });
                                              return ;
                                            }
                                            
                                          }),
                                        children: uploadStatus ? React.createElement(LoadingCircle.make, {}) : React.createElement(IconPhoto.make, {})
                                      })) : null);
                })
            }), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginTop(Css.px(10)),
                    tl: {
                      hd: Css.textAlign("center"),
                      tl: /* [] */0
                    }
                  })
            }, isViewer ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.textAlign("center"),
                          tl: /* [] */0
                        })
                  }, React.createElement(InputWithState.make, {
                        value: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), ""),
                        onChange: (function (name) {
                            return debouncedUpdateName(userId, name);
                          }),
                        onChangeCompleted: (function (value, valueBefore) {
                            AnalyticsRe.profileUpdated(user.id, value, "UserName", valueBefore.length === 0 ? undefined : valueBefore, value, undefined, undefined, Belt_Option.isSome(Caml_option.nullable_to_opt(user.picture)), Belt_Option.flatMap(Caml_option.nullable_to_opt(user.picture), (function (picture) {
                                        return picture.url;
                                      })), undefined);
                            
                          }),
                        children: (function (value, onChange, onBlur, onFocus) {
                            return React.createElement(Input.make, {
                                        value: value,
                                        onChange: onChange,
                                        placeholder: "Add name...",
                                        onBlur: onBlur,
                                        onFocus: onFocus,
                                        className: {
                                          hd: Css.borderColor(Styles.Color.grey20),
                                          tl: {
                                            hd: Css.textAlign("center"),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.huge),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      });
                          })
                      })) : React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.huge),
                            tl: {
                              hd: Css.paddingTop(Css.px(5)),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, user.email), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontWeight(Styles.FontWeight.regular),
                        tl: {
                          hd: Css.fontSize(Styles.FontSize.medium),
                          tl: {
                            hd: Css.color(Styles.Color.grey50),
                            tl: /* [] */0
                          }
                        }
                      })
                }, user.email)));
  } else {
    tmp = userState === "NotFound" ? null : React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.justifyContent("center"),
                      tl: /* [] */0
                    }
                  }
                })
          }, React.createElement(LoadingCircle.make, {
                color: Styles.Color.magenta
              }));
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.maxWidth(Css.pct(90)),
                    tl: {
                      hd: Css.width(Css.px(250)),
                      tl: {
                        hd: Css.maxHeight(Css.pct(100)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.padding2(Css.px(20), Css.px(10)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.boxShadow(Styles.Shadow.box),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.cursor("default"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, tmp), isViewer ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginTop(Css.px(5)),
                          tl: /* [] */0
                        })
                  }, React.createElement("button", {
                        className: Curry._1(Css.merge, {
                              hd: Curry._1(Css.style, Styles.button),
                              tl: {
                                hd: Curry._1(Css.style, {
                                      hd: Css.width(Css.pct(100)),
                                      tl: {
                                        hd: Css.padding2(Css.px(10), Css.px(15)),
                                        tl: {
                                          hd: Css.backgroundColor(Styles.Color.white),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: {
                                              hd: Css.fontSize(Styles.FontSize.regular),
                                              tl: {
                                                hd: Css.color(Styles.Color.grapeError),
                                                tl: {
                                                  hd: Css.boxShadow(Styles.Shadow.box),
                                                  tl: {
                                                    hd: Css.borderRadius(Styles.Border.radius),
                                                    tl: {
                                                      hd: Css.hover({
                                                            hd: Css.color(Styles.Color.grapeErrorSecondary),
                                                            tl: /* [] */0
                                                          }),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        onClick: (function (param) {
                            return FirebaseUtils.signOut(Firebase.app(undefined).auth());
                          })
                      }, "Sign Out")) : null);
}

var make = ProfileModal;

exports.updateName = updateName;
exports.debouncedUpdateName = debouncedUpdateName;
exports.make = make;
/* debouncedUpdateName Not a pure module */
