// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Hooks = require("./Hooks.bs.js");
var React = require("react");
var $$Window = require("./externals/window.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateTypes = require("./StateTypes.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var QueryString = require("query-string");
var StateFilterUtils = require("./StateFilterUtils.bs.js");

function getValues(keyValues) {
  return Belt_Option.mapWithDefault((keyValues == null) ? undefined : Caml_option.some(keyValues), [], (function (items) {
                return items.split(",");
              }));
}

function parseQuery(param) {
  var query = window.location.search.replace("?", "");
  var querySort = Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(QueryString.parse(query).sort), "NoSorting", (function (sort) {
          return Belt_Option.getWithDefault(StateTypes.eventSortFromJs(sort), "NoSorting");
        }));
  return [
          getValues(QueryString.parse(query).sources),
          getValues(QueryString.parse(query).destinations),
          getValues(QueryString.parse(query).tags),
          getValues(QueryString.parse(query).property_groups),
          getValues(QueryString.parse(query).properties),
          getValues(QueryString.parse(query).actions),
          getValues(QueryString.parse(query).categories),
          querySort
        ];
}

function renderQueryStringForFilter(filterName, filters) {
  if (filters.length !== 0) {
    return filterName + "=" + filters.join(",");
  }
  
}

function renderQueryString(filters, sort, emptyStateOpt, param) {
  var emptyState = emptyStateOpt !== undefined ? emptyStateOpt : "";
  var sourceFilters = StateFilterUtils.getSourceFilters(filters);
  var tagFilters = StateFilterUtils.getTagFilters(filters);
  var destinationFilters = StateFilterUtils.getDestinationFilters(filters);
  var propertyGroupFilters = StateFilterUtils.getPropertyGroupFilters(filters);
  var propertyFilters = StateFilterUtils.getPropertyFilters(filters);
  var actionFilters = StateFilterUtils.getActionFilters(filters);
  var categoryFilters = StateFilterUtils.getCategoryFilters(filters);
  var renderSortQuery = function (x) {
    if (x === "NoSorting") {
      return ;
    } else {
      return "sort=" + StateTypes.eventSortToJs(x);
    }
  };
  var maybeQueries = [
    renderQueryStringForFilter("sources", sourceFilters),
    renderQueryStringForFilter("destinations", destinationFilters),
    renderQueryStringForFilter("tags", tagFilters),
    renderQueryStringForFilter("property_groups", propertyGroupFilters),
    renderQueryStringForFilter("properties", propertyFilters),
    renderQueryStringForFilter("actions", actionFilters),
    renderQueryStringForFilter("categories", categoryFilters),
    renderSortQuery(sort)
  ];
  var queries = Belt_Array.keep(maybeQueries, Belt_Option.isSome);
  if (queries.length !== 0) {
    return encodeURI("?" + queries.join("&"));
  } else {
    return emptyState;
  }
}

function useSetFilterQueryParams(filters, sort) {
  var match = Hooks.usePrevious2([
        filters,
        sort
      ]);
  var previousSort = match[1];
  var previousFilters = match[0];
  React.useEffect((function () {
          if (Caml_obj.caml_notequal(filters, previousFilters) || sort !== previousSort) {
            $$Window.$$History.replaceStateSilent(renderQueryString(filters, sort, "?", undefined));
          }
          
        }), [
        filters,
        sort
      ]);
  React.useEffect((function () {
          $$Window.$$History.replaceStateSilent(renderQueryString(filters, sort, undefined, undefined));
          
        }), []);
  
}

exports.getValues = getValues;
exports.parseQuery = parseQuery;
exports.renderQueryStringForFilter = renderQueryStringForFilter;
exports.renderQueryString = renderQueryString;
exports.useSetFilterQueryParams = useSetFilterQueryParams;
/* Hooks Not a pure module */
