// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Switch = require("./Switch.bs.js");

var modalStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.justifyContent("stretch"),
            tl: {
              hd: Css.maxHeight(Css.pct(90)),
              tl: {
                hd: Css.boxShadow(Styles.Shadow.standard),
                tl: {
                  hd: Css.borderRadius(Css.px(10)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.width(Css.px(400)),
                        tl: {
                          hd: Css.padding(Css.px(48)),
                          tl: {
                            hd: Css.paddingTop(Css.px(72)),
                            tl: {
                              hd: Css.wordWrap("breakWord"),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var bottomContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.gap(Css.px(32)),
          tl: /* [] */0
        }
      }
    });

var toggleContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.gap(Css.px(4)),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

var buttonStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("flexEnd"),
        tl: {
          hd: Css.flexWrap("wrap"),
          tl: {
            hd: Css.gap(Css.px(16)),
            tl: /* [] */0
          }
        }
      }
    });

function ConfirmModal$Base(Props) {
  var title = Props.title;
  var message = Props.message;
  var ctaText = Props.ctaText;
  var onConfirm = Props.onConfirm;
  var onCancel = Props.onCancel;
  var onClose = Props.onClose;
  return React.createElement("div", {
              className: modalStyles
            }, React.createElement($$Text.make, {
                  element: "H1",
                  size: "Huge",
                  color: Styles.Color.grey80,
                  children: title
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  color: Styles.Color.grey80,
                  children: message
                }), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement("div", {
                  className: buttonStyles
                }, React.createElement(Button.make, {
                      label: "Cancel",
                      onClick: (function (param) {
                          Curry._1(onCancel, undefined);
                          return Curry._1(onClose, undefined);
                        }),
                      size: "large",
                      style: "outline"
                    }), React.createElement(Button.make, {
                      label: ctaText,
                      onClick: (function (param) {
                          Curry._1(onConfirm, undefined);
                          return Curry._1(onClose, undefined);
                        }),
                      size: "large"
                    })));
}

var Base = {
  make: ConfirmModal$Base
};

function ConfirmModal$WithToggle(Props) {
  var ctaText = Props.ctaText;
  var message = Props.message;
  var onCancel = Props.onCancel;
  var onClose = Props.onClose;
  var onConfirm = Props.onConfirm;
  var title = Props.title;
  var toggleMessage = Props.toggleMessage;
  var toggleText = Props.toggleText;
  var match = React.useState(function () {
        return false;
      });
  var setToggled = match[1];
  var toggled = match[0];
  return React.createElement("div", {
              className: modalStyles
            }, React.createElement($$Text.make, {
                  element: "H1",
                  size: "Huge",
                  color: Styles.Color.grey80,
                  children: title
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  color: Styles.Color.grey80,
                  children: message
                }), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.grey80,
                  children: toggleMessage
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement("div", {
                  className: toggleContainer
                }, React.createElement(Switch.make, {
                      value: toggled,
                      onChange: (function (toggled) {
                          return Curry._1(setToggled, (function (param) {
                                        return toggled;
                                      }));
                        }),
                      size: "Small"
                    }), React.createElement($$Text.make, {
                      size: "Tiny",
                      color: Styles.Color.grey80,
                      children: toggleText
                    })), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement("div", {
                  className: buttonStyles
                }, React.createElement(Button.make, {
                      label: "Cancel",
                      onClick: (function (param) {
                          Curry._1(onCancel, undefined);
                          return Curry._1(onClose, undefined);
                        }),
                      size: "large",
                      style: "outline"
                    }), React.createElement(Button.make, {
                      label: ctaText,
                      onClick: (function (param) {
                          Curry._1(onConfirm, toggled);
                          return Curry._1(onClose, undefined);
                        }),
                      size: "large"
                    })));
}

var WithToggle = {
  make: ConfirmModal$WithToggle
};

function ConfirmModal$AreYouSure(Props) {
  var title = Props.title;
  var message = Props.message;
  var ctaText = Props.ctaText;
  var onConfirm = Props.onConfirm;
  var onCancel = Props.onCancel;
  var onClose = Props.onClose;
  return React.createElement(ConfirmModal$Base, {
              title: title,
              message: message,
              ctaText: ctaText,
              onConfirm: onConfirm,
              onCancel: onCancel,
              onClose: onClose
            });
}

var AreYouSure = {
  make: ConfirmModal$AreYouSure
};

var make = ConfirmModal$Base;

exports.modalStyles = modalStyles;
exports.bottomContainer = bottomContainer;
exports.toggleContainer = toggleContainer;
exports.buttonStyles = buttonStyles;
exports.Base = Base;
exports.WithToggle = WithToggle;
exports.AreYouSure = AreYouSure;
exports.make = make;
/* modalStyles Not a pure module */
