// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");

function OnChangeDebounce(Props) {
  var initialValue = Props.initialValue;
  var onChange = Props.onChange;
  var children = Props.children;
  var match = React.useState(function () {
        return initialValue;
      });
  var setValue = match[1];
  var value = match[0];
  var debouncedValue = Hooks.useDebounced((function (param) {
          return value;
        }), 500);
  React.useEffect((function () {
          Curry._1(onChange, value);
          
        }), [debouncedValue]);
  return Curry._2(children, value, (function (value) {
                return Curry._1(setValue, (function (param) {
                              return value;
                            }));
              }));
}

var make = OnChangeDebounce;

exports.make = make;
/* Hooks Not a pure module */
