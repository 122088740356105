// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");

function IconChevronLight(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var directionOpt = Props.direction;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey70;
  var direction = directionOpt !== undefined ? directionOpt : "Right";
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css.transform({
                          NAME: "rotate",
                          VAL: {
                            NAME: "deg",
                            VAL: direction === "Up" ? 90.0 : (
                                direction === "Down" ? -90.0 : (
                                    direction === "Left" ? 0.0 : 180.0
                                  )
                              )
                          }
                        }),
                    tl: /* [] */0
                  }),
              width: String((size << 1)),
              fill: "none",
              viewBox: "0 0 32 32",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M20 24L12 16L20 8",
                  stroke: Styles.Color.toString(color),
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeMiterlimit: "10",
                  strokeWidth: "2"
                }));
}

var make = IconChevronLight;

exports.make = make;
/* Css Not a pure module */
