// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function encode(status) {
  var tmp;
  if (typeof status === "number") {
    tmp = {
      hd: [
        "type",
        "NeverSeenEvent"
      ],
      tl: /* [] */0
    };
  } else {
    switch (status.TAG | 0) {
      case /* AlwaysSeenCorrectlyLatestVersion */0 :
          tmp = {
            hd: [
              "type",
              "AlwaysSeenCorrectlyLatestVersion"
            ],
            tl: {
              hd: [
                "correctCount",
                status._0
              ],
              tl: {
                hd: [
                  "totalCount",
                  status._1
                ],
                tl: {
                  hd: [
                    "lastSeenCorrectly",
                    Json_encode.date(status._2)
                  ],
                  tl: /* [] */0
                }
              }
            }
          };
          break;
      case /* SometimesSeenCorrectlyLatestVersion */1 :
          tmp = {
            hd: [
              "type",
              "SometimesSeenCorrectlyLatestVersion"
            ],
            tl: {
              hd: [
                "correctCount",
                status._0
              ],
              tl: {
                hd: [
                  "totalCount",
                  status._1
                ],
                tl: {
                  hd: [
                    "lastSeenCorrectly",
                    Json_encode.date(status._2)
                  ],
                  tl: {
                    hd: [
                      "lastSeenIncorrectly",
                      Json_encode.date(status._3)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            }
          };
          break;
      case /* NeverSeenCorrectlyLatestVersion */2 :
          tmp = {
            hd: [
              "type",
              "NeverSeenCorrectlyLatestVersion"
            ],
            tl: {
              hd: [
                "correctCount",
                status._0
              ],
              tl: {
                hd: [
                  "totalCount",
                  status._1
                ],
                tl: {
                  hd: [
                    "lastSeenIncorrectly",
                    Json_encode.date(status._2)
                  ],
                  tl: /* [] */0
                }
              }
            }
          };
          break;
      case /* AlwaysSeenCorrectlyInOlderVersion */3 :
          tmp = {
            hd: [
              "type",
              "AlwaysSeenCorrectlyInOlderVersion"
            ],
            tl: {
              hd: [
                "correctCount",
                status._0
              ],
              tl: {
                hd: [
                  "totalCount",
                  status._1
                ],
                tl: {
                  hd: [
                    "version",
                    status._2
                  ],
                  tl: {
                    hd: [
                      "lastSeenCorrectly",
                      Json_encode.date(status._3)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            }
          };
          break;
      case /* SometimesSeenCorrectlyInOlderVersion */4 :
          tmp = {
            hd: [
              "type",
              "SometimesSeenCorrectlyInOlderVersion"
            ],
            tl: {
              hd: [
                "correctCount",
                status._0
              ],
              tl: {
                hd: [
                  "totalCount",
                  status._1
                ],
                tl: {
                  hd: [
                    "version",
                    status._2
                  ],
                  tl: {
                    hd: [
                      "lastSeenCorrectly",
                      Json_encode.date(status._3)
                    ],
                    tl: {
                      hd: [
                        "lastSeenIncorrectly",
                        Json_encode.date(status._4)
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          };
          break;
      case /* NeverSeenCorrectlyInOlderVersion */5 :
          tmp = {
            hd: [
              "type",
              "NeverSeenCorrectlyInOlderVersion"
            ],
            tl: {
              hd: [
                "correctCount",
                status._0
              ],
              tl: {
                hd: [
                  "totalCount",
                  status._1
                ],
                tl: {
                  hd: [
                    "version",
                    status._2
                  ],
                  tl: {
                    hd: [
                      "lastSeenIncorrectly",
                      Json_encode.date(status._3)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            }
          };
          break;
      
    }
  }
  return Json_encode.object_(tmp);
}

function decode(json) {
  var match = Json_decode.field("type", Json_decode.string, json);
  switch (match) {
    case "AlwaysSeenCorrectlyInOlderVersion" :
        return {
                TAG: /* AlwaysSeenCorrectlyInOlderVersion */3,
                _0: Json_decode.field("correctCount", Json_decode.$$int, json),
                _1: Json_decode.field("totalCount", Json_decode.$$int, json),
                _2: Json_decode.field("version", Json_decode.string, json),
                _3: new Date(Json_decode.field("lastSeenCorrectly", Json_decode.string, json))
              };
    case "AlwaysSeenCorrectlyLatestVersion" :
        return {
                TAG: /* AlwaysSeenCorrectlyLatestVersion */0,
                _0: Json_decode.field("correctCount", Json_decode.$$int, json),
                _1: Json_decode.field("totalCount", Json_decode.$$int, json),
                _2: new Date(Json_decode.field("lastSeenCorrectly", Json_decode.string, json))
              };
    case "NeverSeenCorrectlyInOlderVersion" :
        return {
                TAG: /* NeverSeenCorrectlyInOlderVersion */5,
                _0: Json_decode.field("correctCount", Json_decode.$$int, json),
                _1: Json_decode.field("totalCount", Json_decode.$$int, json),
                _2: Json_decode.field("version", Json_decode.string, json),
                _3: new Date(Json_decode.field("lastSeenIncorrectly", Json_decode.string, json))
              };
    case "NeverSeenCorrectlyLatestVersion" :
        return {
                TAG: /* NeverSeenCorrectlyLatestVersion */2,
                _0: Json_decode.field("correctCount", Json_decode.$$int, json),
                _1: Json_decode.field("totalCount", Json_decode.$$int, json),
                _2: new Date(Json_decode.field("lastSeenIncorrectly", Json_decode.string, json))
              };
    case "NeverSeenEvent" :
        return /* NeverSeenEvent */0;
    case "SometimesSeenCorrectlyInOlderVersion" :
        return {
                TAG: /* SometimesSeenCorrectlyInOlderVersion */4,
                _0: Json_decode.field("correctCount", Json_decode.$$int, json),
                _1: Json_decode.field("totalCount", Json_decode.$$int, json),
                _2: Json_decode.field("version", Json_decode.string, json),
                _3: new Date(Json_decode.field("lastSeenCorrectly", Json_decode.string, json)),
                _4: new Date(Json_decode.field("lastSeenIncorrectly", Json_decode.string, json))
              };
    case "SometimesSeenCorrectlyLatestVersion" :
        return {
                TAG: /* SometimesSeenCorrectlyLatestVersion */1,
                _0: Json_decode.field("correctCount", Json_decode.$$int, json),
                _1: Json_decode.field("totalCount", Json_decode.$$int, json),
                _2: new Date(Json_decode.field("lastSeenCorrectly", Json_decode.string, json)),
                _3: new Date(Json_decode.field("lastSeenIncorrectly", Json_decode.string, json))
              };
    default:
      console.error("Failed to decode Event Status Json", json);
      return /* NeverSeenEvent */0;
  }
}

exports.encode = encode;
exports.decode = decode;
/* No side effect */
