// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Styles = require("./styles.bs.js");
var Actions = require("./actions.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var FileView = require("./FileView.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppFeatureFlag = require("./AppFeatureFlag.bs.js");

function fetchAvoFile(schemaId, branchId, source) {
  var auth = Firebase.getAuth(undefined);
  return Firebase.Auth.unsafeGetCurrentUser(auth).getIdToken().then(function (token) {
                return fetch(Firebase.apiUrl + "/c/v1/pull", Fetch.RequestInit.make(/* Post */2, [
                                  [
                                    "Accept",
                                    "application/json"
                                  ],
                                  [
                                    "Content-Type",
                                    "application/json"
                                  ],
                                  [
                                    "Authorization",
                                    "Bearer " + token
                                  ]
                                ], Caml_option.some(JSON.stringify(Js_dict.fromArray([
                                              [
                                                "schemaId",
                                                schemaId
                                              ],
                                              [
                                                "branchId",
                                                branchId
                                              ],
                                              [
                                                "sources",
                                                [Js_dict.fromArray([
                                                        [
                                                          "id",
                                                          source.id
                                                        ],
                                                        [
                                                          "path",
                                                          AvoConfig.filenameHint(source.language) + ("." + AvoConfig.languageExtensionHint(source.language))
                                                        ]
                                                      ])]
                                              ],
                                              [
                                                "requestClient",
                                                "web"
                                              ]
                                            ]))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
              }).then(function (prim) {
              return prim.json();
            });
}

function ImplementFile(Props) {
  var currentBranch = Props.currentBranch;
  var schema = Props.schema;
  var source = Props.source;
  var viewerRole = Props.viewerRole;
  var hasCodegenAccessRole = AppFeatureFlag.useFeatureFlag("CodegenAccessRole");
  var match = React.useState(function () {
        return /* Initial */0;
      });
  var setStatus = match[1];
  var status = match[0];
  var handleFetchImplementFile = function (param) {
    Curry._1(setStatus, (function (param) {
            return /* Loading */1;
          }));
    $$Promise.$$catch(fetchAvoFile(schema.id, Actions.branchToId(currentBranch), source).then(function (response) {
              var error = response.error;
              if (error !== undefined) {
                Curry._1(setStatus, (function (param) {
                        return {
                                TAG: /* Error */0,
                                _0: error
                              };
                      }));
              } else {
                Curry._1(setStatus, (function (param) {
                        return {
                                TAG: /* Success */1,
                                _0: Belt_Array.concatMany(Belt_Array.map(response.sources, (function (source) {
                                            var code = source.code;
                                            return Belt_Array.map(code, (function (code) {
                                                          return [
                                                                  code.path,
                                                                  code.content
                                                                ];
                                                        }));
                                          }))),
                                _1: Belt_Option.getWithDefault(response.warnings, [])
                              };
                      }));
              }
              return Promise.resolve(undefined);
            }), (function (param) {
            return Promise.resolve(Curry._1(setStatus, (function (param) {
                              return {
                                      TAG: /* Error */0,
                                      _0: "unknown error, please contact support."
                                    };
                            })));
          }));
    
  };
  if (typeof status === "number") {
    if (status === /* Initial */0) {
      return React.createElement(FileView.make, {
                  filename: "Click to generate your Avo file",
                  code: "",
                  loading: true,
                  onExpand: Models.Role.canCodegen(viewerRole) ? handleFetchImplementFile : (function (param) {
                        return Curry._1(setStatus, (function (param) {
                                      return {
                                              TAG: /* Error */0,
                                              _0: hasCodegenAccessRole ? "To run Codegen you must be a workspace editor or admin, or have Codegen access. Ask an admin to upgrade your role." : "To run Codegen you must be a workspace editor or admin. Ask an admin to upgrade your role."
                                            };
                                    }));
                      })
                });
    } else {
      return React.createElement(FileView.make, {
                  filename: "Generating your Avo file...",
                  code: "",
                  loading: true
                });
    }
  }
  if (status.TAG === /* Error */0) {
    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.marginBottom(Css.px(10)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.red20),
                              tl: {
                                hd: Css.padding(Css.px(15)),
                                tl: {
                                  hd: Css.borderRadius(Styles.Border.radius),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.bold),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.medium),
                                tl: {
                                  hd: Css.margin(Css.px(4)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, "Error:"), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.margin(Css.px(4)),
                              tl: /* [] */0
                            })
                      }, status._0)), React.createElement(FileView.make, {
                    filename: "Click to try generating your Avo file again",
                    code: "",
                    loading: true,
                    onExpand: Models.Role.canCodegen(viewerRole) ? handleFetchImplementFile : (function (param) {
                          return Curry._1(setStatus, (function (param) {
                                        return {
                                                TAG: /* Error */0,
                                                _0: hasCodegenAccessRole ? "To run Codegen you must be a workspace editor or admin, or have Codegen access. Ask an admin to upgrade your role." : "To run Codegen you must be a workspace editor or admin. Ask an admin to upgrade your role."
                                              };
                                      }));
                        })
                  }));
  }
  var warnings = status._1;
  return React.createElement(React.Fragment, undefined, warnings.length !== 0 ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("block"),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                tl: /* [] */0
                              }
                            }
                          }
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.margin(Css.px(10)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.orangeBg),
                                  tl: {
                                    hd: Css.padding(Css.px(15)),
                                    tl: {
                                      hd: Css.borderRadius(Styles.Border.radius),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            })
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.fontWeight(Styles.FontWeight.bold),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.medium),
                                    tl: {
                                      hd: Css.margin(Css.px(4)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, warnings.length === 1 ? "Warning:" : "Warnings:"), Belt_Array.map(warnings, (function (warning) {
                              return React.createElement("div", {
                                          key: warning,
                                          className: Curry._1(Css.style, {
                                                hd: Css.margin(Css.px(4)),
                                                tl: /* [] */0
                                              })
                                        }, warning);
                            })))) : null, Belt_Option.mapWithDefault(Belt_Array.get(status._0, 0), null, (function (param) {
                    return React.createElement(FileView.make, {
                                filename: param[0],
                                code: param[1],
                                startExpanded: true
                              });
                  })));
}

var make = ImplementFile;

exports.fetchAvoFile = fetchAvoFile;
exports.make = make;
/* Css Not a pure module */
