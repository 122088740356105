// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Fetch = require("bs-fetch/src/Fetch.bs.js");
var Router = require("../Router.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Firebase = require("../../../bs-firestore/src/Firebase.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var App = require("firebase/app");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var InspectorIssueV2 = require("../../../shared/models/InspectorIssueV2.bs.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");
var InspectorViewModel = require("./InspectorViewModel.bs.js");
var InspectorIssuesCount = require("../../../shared/models/InspectorIssuesCount.bs.js");

function getEvents(schemaId, environment, timeWindow, overview, fromEventNameOpt, toEventNameOpt, limitOpt, param) {
  var fromEventName = fromEventNameOpt !== undefined ? Caml_option.valFromOption(fromEventNameOpt) : undefined;
  var toEventName = toEventNameOpt !== undefined ? Caml_option.valFromOption(toEventNameOpt) : undefined;
  var limit = limitOpt !== undefined ? Caml_option.valFromOption(limitOpt) : undefined;
  return $$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                    var envString = environment === "Development" ? "dev" : (
                        environment === "Production" ? "prod" : "staging"
                      );
                    var timeString = timeWindow === "Last1Hour" ? "1h" : (
                        timeWindow === "Last24Hours" ? "1d" : (
                            timeWindow === "Last7Days" ? "7d" : "30d"
                          )
                      );
                    return fetch("/api/inspector/v1/events?schemaId=" + schemaId + "&env=" + envString + "&timeWindow=" + timeString + "&overview=" + (
                                overview ? "true" : "false"
                              ) + Belt_Option.mapWithDefault(fromEventName, "", (function (fromEventName) {
                                      return "&fromEventName=" + fromEventName;
                                    })) + Belt_Option.mapWithDefault(toEventName, "", (function (toEventName) {
                                      return "&toEventName=" + toEventName;
                                    })) + Belt_Option.mapWithDefault(limit, "", (function (limit) {
                                      return "&limit=" + String(limit);
                                    })), Fetch.RequestInit.make(/* Get */0, [
                                      [
                                        "Accept",
                                        "application/json"
                                      ],
                                      [
                                        "Content-Type",
                                        "application/json"
                                      ],
                                      [
                                        "Authorization",
                                        "Bearer " + token
                                      ]
                                    ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                  }).then(function (res) {
                  var internalError = res.status;
                  if (internalError !== 200) {
                    if (internalError >= 500) {
                      console.error("Internal error recevied from inspector API");
                      return Promise.resolve({
                                  NAME: "Error",
                                  VAL: /* Internal */0
                                });
                    } else if (internalError !== 400 && internalError !== 404) {
                      console.warn("Received an unfamiliar status code from Inspector API", internalError);
                      return Promise.resolve({
                                  NAME: "Error",
                                  VAL: /* Internal */0
                                });
                    } else {
                      console.warn("Table does not exist");
                      return Promise.resolve({
                                  NAME: "Error",
                                  VAL: /* NotFound */1
                                });
                    }
                  }
                  try {
                    return res.json().then(function (json) {
                                  return Promise.resolve(InspectorViewModel.parseResponse(json));
                                }).then(function (events) {
                                return Promise.resolve({
                                            NAME: "Success",
                                            VAL: events
                                          });
                              });
                  }
                  catch (raw_error){
                    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
                    console.error("Error parsing response from inspector API: 200", error);
                    return Promise.resolve({
                                NAME: "Error",
                                VAL: /* Internal */0
                              });
                  }
                }), (function (error) {
                console.error("/events fetch error", error);
                return Promise.resolve({
                            NAME: "Error",
                            VAL: /* Internal */0
                          });
              }));
}

function updateStatsCache(schemaId, json) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("inspectorStats").doc("stats").set({
              stats: json,
              lastUpdatedAt: new Date()
            });
}

function getFreshStats(schemaId) {
  return $$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                        return fetch("/api/inspector/v1/stats?schemaId=" + schemaId, Fetch.RequestInit.make(/* Get */0, [
                                          [
                                            "Accept",
                                            "application/json"
                                          ],
                                          [
                                            "Content-Type",
                                            "application/json"
                                          ],
                                          [
                                            "Authorization",
                                            "Bearer " + token
                                          ]
                                        ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                      }).then(function (prim) {
                      return prim.json();
                    }).then(function (json) {
                    return Promise.resolve([
                                Json_decode.array(InspectorViewModel.InspectorStats.decode, json),
                                json
                              ]);
                  }).then(function (param) {
                  updateStatsCache(schemaId, param[1]);
                  return Promise.resolve({
                              NAME: "Success",
                              VAL: param[0]
                            });
                }), (function (error) {
                console.error("/stats fetch error", error);
                return Promise.resolve("Error");
              }));
}

function getStatsWithCache(schemaId) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("inspectorStats").doc("stats").get().then(function (snapshot) {
                if (!snapshot.exists) {
                  return Promise.resolve(undefined);
                }
                var data = snapshot.data();
                var tmp;
                try {
                  tmp = Json_decode.array(InspectorViewModel.InspectorStats.decode, data.stats);
                }
                catch (exn){
                  tmp = undefined;
                }
                return Promise.resolve(tmp);
              }).then(function (maybeStatsCache) {
              if (maybeStatsCache === undefined) {
                return getFreshStats(schemaId);
              }
              var hasSeenProd = Belt_Array.some(Belt_Array.concatMany(Belt_Array.map(maybeStatsCache, (function (sourceStat) {
                              return sourceStat.envs;
                            }))), (function (env) {
                      return env.env === "prod";
                    }));
              if (!hasSeenProd) {
                getFreshStats(schemaId);
              }
              return Promise.resolve({
                          NAME: "Success",
                          VAL: maybeStatsCache
                        });
            });
}

function getApiKey(schemaId, sourceId) {
  return Firebase.app(undefined).firestore().collection("apiKeys").where("schemaId", "==", schemaId).where("sourceId", "==", sourceId).get().then(function (snapshot) {
              return Promise.resolve(Belt_Option.map(Belt_Array.get(Belt_Array.map(snapshot.docs, (function (prim) {
                                        return prim.data();
                                      })), 0), (function (apiKey) {
                                return apiKey.id;
                              })));
            });
}

function generateApiKey(schemaId, sourceId, schemaName, sourceName) {
  var keyRef = Firebase.app(undefined).firestore().collection("apiKeys").doc();
  var id = keyRef.id;
  var key = {
    createdAt: App.default.firestore.FieldValue.serverTimestamp(),
    createdBy: Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid,
    id: id,
    schemaId: schemaId,
    sourceId: sourceId
  };
  return keyRef.set(key).then(function (param) {
              AnalyticsRe.inspectorApiKeyGenerated(AnalyticsRe.Group.source(sourceId, sourceName), Date.now(), id, schemaId, schemaName, new Date().toISOString(), schemaId);
              return Promise.resolve(id);
            });
}

function getApiOrGenerateKey(schemaId, sourceId, schemaName, sourceName) {
  return getApiKey(schemaId, sourceId).then(function (maybeApiKey) {
              if (maybeApiKey !== undefined) {
                return Promise.resolve(maybeApiKey);
              } else {
                return generateApiKey(schemaId, sourceId, schemaName, sourceName);
              }
            });
}

function getIssues(schemaId) {
  return $$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                    return fetch("/api/workspaces/" + schemaId + "/inspector/issues/v4", Fetch.RequestInit.make(/* Get */0, [
                                      [
                                        "Accept",
                                        "application/json"
                                      ],
                                      [
                                        "Accept-Encoding",
                                        "gzip"
                                      ],
                                      [
                                        "Content-Type",
                                        "application/json"
                                      ],
                                      [
                                        "Authorization",
                                        "Bearer " + token
                                      ]
                                    ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                  }).then(function (res) {
                  var internalError = res.status;
                  if (internalError !== 200) {
                    if (internalError >= 500) {
                      console.error("Internal error recevied from inspector API");
                      return Promise.resolve({
                                  NAME: "error",
                                  VAL: /* Internal */0
                                });
                    } else if (internalError !== 400 && internalError !== 404) {
                      console.warn("Received an unfamiliar status code from Inspector API", internalError);
                      return Promise.resolve({
                                  NAME: "error",
                                  VAL: /* Internal */0
                                });
                    } else {
                      console.warn("Table does not exist");
                      return Promise.resolve({
                                  NAME: "error",
                                  VAL: /* NotFound */1
                                });
                    }
                  } else {
                    return res.json().then(function (json) {
                                return {
                                        NAME: "success",
                                        VAL: Json_decode.field("issues", (function (param) {
                                                return Json_decode.array(InspectorIssueV2.IssueWithAppVersionStats.decode, param);
                                              }), json)
                                      };
                              });
                  }
                }), (function (error) {
                console.error("/inspector/issues fetch error", error);
                return Promise.resolve({
                            NAME: "error",
                            VAL: /* Internal */0
                          });
              }));
}

function getIssuesCount(schemaId) {
  return $$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                    return fetch("/api/workspaces/" + schemaId + "/inspector/issuesCount", Fetch.RequestInit.make(/* Get */0, [
                                      [
                                        "Accept",
                                        "application/json"
                                      ],
                                      [
                                        "Content-Type",
                                        "application/json"
                                      ],
                                      [
                                        "Authorization",
                                        "Bearer " + token
                                      ]
                                    ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                  }).then(function (res) {
                  var internalError = res.status;
                  if (internalError !== 200) {
                    if (internalError >= 500) {
                      console.error("Internal error recevied from inspector API");
                      return Promise.resolve({
                                  NAME: "error",
                                  VAL: /* Internal */0
                                });
                    } else if (internalError !== 400 && internalError !== 404) {
                      console.warn("Received an unfamiliar status code from Inspector API", internalError);
                      return Promise.resolve({
                                  NAME: "error",
                                  VAL: /* Internal */0
                                });
                    } else {
                      console.warn("Table does not exist");
                      return Promise.resolve({
                                  NAME: "error",
                                  VAL: /* NotFound */1
                                });
                    }
                  } else {
                    return res.json().then(function (json) {
                                return {
                                        NAME: "success",
                                        VAL: InspectorIssuesCount.decode(json)
                                      };
                              });
                  }
                }), (function (error) {
                console.error("/inspector/issuesCount fetch error", error);
                return Promise.resolve({
                            NAME: "error",
                            VAL: /* Internal */0
                          });
              }));
}

var ClientError = /* @__PURE__ */Caml_exceptions.create("InspectorViewApi.ClientError");

var ServerError = /* @__PURE__ */Caml_exceptions.create("InspectorViewApi.ServerError");

function getIssue(issueId) {
  var schemaId = Router.Schema.getSchemaId(undefined);
  var url = "/api/workspaces/" + schemaId + "/inspector/issues/v3/" + issueId;
  return Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
              return fetch(url, Fetch.RequestInit.make(/* Get */0, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + token
                                    ]
                                  ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (result) {
                            var status = result.status;
                            if (status >= 400 && status < 500) {
                              throw {
                                    RE_EXN_ID: ClientError,
                                    _1: status,
                                    Error: new Error()
                                  };
                            }
                            if (status >= 500) {
                              throw {
                                    RE_EXN_ID: ServerError,
                                    _1: status,
                                    Error: new Error()
                                  };
                            }
                            return result.json();
                          }).then(InspectorIssueV2.IssueWithAppVersionStats.decode);
            });
}

function getRelatedIssues(sharedIssueId) {
  var schemaId = Router.Schema.getSchemaId(undefined);
  var url = "/api/workspaces/" + schemaId + "/inspector/issues/sharedId/" + sharedIssueId;
  return Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
              return fetch(url, Fetch.RequestInit.make(/* Get */0, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + token
                                    ]
                                  ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
                            return prim.json();
                          }).then(function (json) {
                          return Json_decode.array(InspectorIssueV2.IssueWithAppVersionStats.decode, json);
                        });
            });
}

exports.getEvents = getEvents;
exports.updateStatsCache = updateStatsCache;
exports.getFreshStats = getFreshStats;
exports.getStatsWithCache = getStatsWithCache;
exports.getApiKey = getApiKey;
exports.generateApiKey = generateApiKey;
exports.getApiOrGenerateKey = getApiOrGenerateKey;
exports.getIssues = getIssues;
exports.getIssuesCount = getIssuesCount;
exports.ClientError = ClientError;
exports.ServerError = ServerError;
exports.getIssue = getIssue;
exports.getRelatedIssues = getRelatedIssues;
/* Router Not a pure module */
