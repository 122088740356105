// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function encode(shareType) {
  return "BranchImplementation";
}

function decode(json) {
  var match = Json_decode.string(json);
  if (match === "BranchImplementation") {
    return /* BranchImplementation */0;
  } else {
    return Pervasives.failwith("Unexepcted share type");
  }
}

var ShareType = {
  encode: encode,
  decode: decode
};

function encode$1(t) {
  return Json_encode.object_({
              hd: [
                "shareType",
                "BranchImplementation"
              ],
              tl: {
                hd: [
                  "shareId",
                  t.shareId
                ],
                tl: {
                  hd: [
                    "schemaId",
                    t.schemaId
                  ],
                  tl: {
                    hd: [
                      "branchId",
                      t.branchId
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function decode$1(json) {
  var jsonDict = Js_json.classify(json);
  if (typeof jsonDict === "number") {
    return Pervasives.failwith("Sharing.decode expected object but got " + JSON.stringify(json));
  }
  if (jsonDict.TAG !== /* JSONObject */2) {
    return Pervasives.failwith("Sharing.decode expected object but got " + JSON.stringify(json));
  }
  var shareType = Belt_Option.map(Js_dict.get(jsonDict._0, "shareType"), decode);
  if (shareType !== undefined) {
    return /* BranchImplementation */{
            shareId: Json_decode.field("shareId", Json_decode.string, json),
            schemaId: Json_decode.field("schemaId", Json_decode.string, json),
            branchId: Json_decode.field("branchId", Json_decode.string, json)
          };
  } else {
    return Pervasives.failwith("Sharing.decode got unexpected shareType " + JSON.stringify(json));
  }
}

exports.ShareType = ShareType;
exports.encode = encode$1;
exports.decode = decode$1;
/* No side effect */
