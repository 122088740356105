// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Snippet = require("./Snippet.bs.js");

function SetupInstructions$Gtm(Props) {
  var schemaId = Props.schemaId;
  var sourceId = Props.sourceId;
  var actionId = Props.actionId;
  var snippet = "<script src=\"https://cdn.avo.app/lib/" + schemaId + "/" + sourceId + "/" + actionId + ".js\"></script>\n<script>\n  Avo.initAvo({env: {{Debug Mode}} ? 'dev' : 'prod'})\n</script>";
  return React.createElement("div", undefined, React.createElement(Snippet.make, {
                  wrap: true,
                  language: "html",
                  snippet: snippet
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontFamily(Styles.monoFontFamily),
                        tl: {
                          hd: Css.margin2(Css.px(0), Css.px(15)),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, "Avo.initAvo"), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontSize(Styles.FontSize.regular),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.regular),
                          tl: {
                            hd: Css.margin2(Css.px(15), Css.px(0)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("flexStart"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.textAlign("right"),
                                tl: {
                                  hd: Css.padding2(Css.px(10), Css.px(15)),
                                  tl: {
                                    hd: Css.width(Css.px(220)),
                                    tl: {
                                      hd: Css.flexShrink(0.0),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.wordBreak("breakAll"),
                                    tl: {
                                      hd: Css.fontFamily(Styles.monoFontFamily),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, "options")), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.padding2(Css.px(10), Css.px(15)),
                                tl: {
                                  hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey20),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement("div", undefined, "Avo initialization options"), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.marginTop(Css.px(10)),
                                    tl: {
                                      hd: Css.marginBottom(Css.px(15)),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("flexStart"),
                                          tl: {
                                            hd: Css.minWidth(Css.px(400)),
                                            tl: /* [] */0
                                          }
                                        }
                                      })
                                }, React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.textAlign("right"),
                                            tl: {
                                              hd: Css.padding2(Css.px(10), Css.px(15)),
                                              tl: {
                                                hd: Css.width(Css.px(220)),
                                                tl: {
                                                  hd: Css.flexShrink(0.0),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          })
                                    }, React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.wordBreak("breakAll"),
                                                tl: {
                                                  hd: Css.fontFamily(Styles.monoFontFamily),
                                                  tl: {
                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              })
                                        }, "env")), React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.padding2(Css.px(10), Css.px(15)),
                                            tl: {
                                              hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey20),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.fontWeight(Styles.FontWeight.regular),
                                                tl: {
                                                  hd: Css.fontSize(Styles.FontSize.regular),
                                                  tl: {
                                                    hd: Css.color(Styles.Color.grey90),
                                                    tl: {
                                                      hd: Css.marginBottom(Css.px(5)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              })
                                        }, React.createElement("span", undefined, React.createElement("span", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                                        tl: {
                                                          hd: Css.paddingRight(Css.px(4)),
                                                          tl: /* [] */0
                                                        }
                                                      })
                                                }, "string"))), React.createElement("div", undefined, "which environment Avo is running - dev, staging or prod"))))))));
}

var Gtm = {
  make: SetupInstructions$Gtm
};

exports.Gtm = Gtm;
/* Css Not a pure module */
