// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Case = require("../../../bs-case/src/case.bs.js");
var CmdK = require("../CmdK.bs.js");
var Link = require("../Link.bs.js");
var List = require("rescript/lib/js/list.js");
var $$Text = require("../Text.bs.js");
var Kbar = require("kbar");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("../Hooks.bs.js");
var Toast = require("../Toast.bs.js");
var React = require("react");
var Models = require("../Models.bs.js");
var Router = require("../Router.bs.js");
var Styles = require("../styles.bs.js");
var Tooltip = require("../Tooltip.bs.js");
var Shortid = require("shortid");
var Firebase = require("../../../bs-firestore/src/Firebase.bs.js");
var IconSend = require("../IconSend.bs.js");
var IconTags = require("../IconTags.bs.js");
var Markdown = require("../Markdown.bs.js");
var Tooltips = require("../markdown/tooltips/tooltips.bs.js");
var AvoLimits = require("../AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var EventRule = require("../EventRule.bs.js");
var NameInput = require("../NameInput.bs.js");
var NameUtils = require("../../../shared/utils/NameUtils.bs.js");
var TagsInput = require("../TagsInput.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var IconMetric = require("../IconMetric.bs.js");
var IconSendAs = require("../IconSendAs.bs.js");
var StateUtils = require("../stateUtils.bs.js");
var TextButton = require("../TextButton.bs.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var NamedBranch = require("../NamedBranch.bs.js");
var RouterStore = require("../RouterStore.bs.js");
var CommentInput = require("../CommentInput.bs.js");
var DrawerHeader = require("./DrawerHeader.bs.js");
var EventMetrics = require("../EventMetrics.bs.js");
var EventSources = require("../EventSources.bs.js");
var IconCategory = require("../IconCategory.bs.js");
var OpsWithState = require("../OpsWithState.bs.js");
var CategoryInput = require("../CategoryInput.bs.js");
var FirebaseUtils = require("../firebaseUtils.bs.js");
var ViewerContext = require("../ViewerContext.bs.js");
var AnalyticsUtils = require("../analyticsUtils.bs.js");
var IconIdentifier = require("../IconIdentifier.bs.js");
var IconSideEffect = require("../IconSideEffect.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var IconDescription = require("../IconDescription.bs.js");
var EventSideEffects = require("../EventSideEffects.bs.js");
var WorkspaceContext = require("../WorkspaceContext.bs.js");
var GlobalSendContext = require("../GlobalSendContext.bs.js");
var ObjectActivityLog = require("../ObjectActivityLog.bs.js");
var BeltListExtensions = require("../BeltListExtensions.bs.js");
var DiscrepancyContext = require("../DiscrepancyContext.bs.js");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");
var SendActionsContext = require("../SendActionsContext.bs.js");
var EventDetailTriggers = require("../EventDetailTriggers.bs.js");
var EventImplementation = require("./EventImplementation.bs.js");
var CopyTextToClipboard = require("copy-text-to-clipboard");
var ReactTextareaAutosize = require("react-textarea-autosize").default;

function scrollToDomRef(domRef) {
  return Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(domRef.current), undefined, (function (anchor) {
                anchor.scrollIntoView({
                      inline: "center"
                    });
                
              }));
}

function EventDetails(Props) {
  var branchStatus = Props.branchStatus;
  var $$event = Props.event;
  var model = Props.model;
  var schema = Props.schema;
  var events = Props.events;
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var role = Props.role;
  var isEventModified = Props.isEventModified;
  var user = ViewerContext.use(undefined);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var maybeDrawerItem = Belt_List.head(RouterStore.Schema.useDrawerItems(undefined));
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var correctCase = NameUtils.isCorrectCase(match.expectedEventCase, $$event.name);
  var isFullscreen = Belt_Option.mapWithDefault(maybeDrawerItem, false, (function (item) {
          if (typeof item === "object" && item.NAME === "event") {
            return item.VAL[3];
          } else {
            return false;
          }
        }));
  var screenIsOnTop;
  if (maybeDrawerItem !== undefined && typeof maybeDrawerItem === "object" && maybeDrawerItem.NAME === "event") {
    var match$1 = maybeDrawerItem.VAL;
    screenIsOnTop = match$1[2] !== undefined ? false : match$1[0] === $$event.id;
  } else {
    screenIsOnTop = false;
  }
  Kbar.useRegisterActions(screenIsOnTop ? [Curry.app(CmdK.Action.t, [
                "event_fullscreen",
                {
                  TAG: /* RootItem */0,
                  _0: "Toggle Full Screen on Event"
                },
                1.1,
                ["f"],
                undefined,
                undefined,
                (function (param) {
                    if (maybeDrawerItem === undefined) {
                      return ;
                    }
                    if (typeof maybeDrawerItem !== "object") {
                      return ;
                    }
                    if (maybeDrawerItem.NAME !== "event") {
                      return ;
                    }
                    var match = maybeDrawerItem.VAL;
                    return Router.Schema.swapTopDrawerItem({
                                NAME: "event",
                                VAL: [
                                  match[0],
                                  match[1],
                                  undefined,
                                  !match[3]
                                ]
                              });
                  }),
                undefined,
                undefined
              ])] : [], [
        $$event.id,
        screenIsOnTop,
        isFullscreen
      ]);
  var match$2 = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match$2.expectedEventCase;
  var config = match$2.config;
  var tagsAnchor = React.useRef(null);
  var hasIncludedSource = Belt_List.some($$event.includeSources, (function (param) {
          return StateUtils.getSourceById(param.id, model) !== undefined;
        }));
  React.useEffect((function () {
          Curry._1(globalSend, {
                TAG: /* SetLastSeenEventId */1,
                _0: $$event.id
              });
          var eventHandler = function ($$event) {
            var match = $$event.altKey;
            var match$1 = $$event.keyCode;
            if (match && match$1 === 84) {
              scrollToDomRef(tagsAnchor);
              $$event.preventDefault();
              return ;
            }
            
          };
          window.addEventListener("keydown", eventHandler);
          return (function (param) {
                    window.removeEventListener("keydown", eventHandler);
                    
                  });
        }), []);
  var previousEventId = Hooks.usePrevious1($$event.id);
  React.useEffect((function () {
          if (previousEventId !== $$event.id) {
            Curry._1(globalSend, {
                  TAG: /* SetLastSeenEventId */1,
                  _0: $$event.id
                });
          }
          
        }), [previousEventId]);
  var eventMetrics = Belt_List.keepU(model.metrics, (function (metric) {
          return Belt_List.some(metric.items, (function (x) {
                        return x.VAL.eventId === $$event.id;
                      }));
        }));
  var handleDuplicateEvent = function (param) {
    var match = AvoLimits.DuplicateEvents.computeAvailability(workspace);
    if (!(match === "AvailableDuringTrial" || match === "Available")) {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "BillingPrompt",
                    VAL: "DuplicateEvents"
                  }
                });
    }
    var toEventId = Shortid();
    var triggerIdTuples = Belt_Array.map($$event.triggers, (function (param) {
            return [
                    param.id,
                    Shortid()
                  ];
          }));
    var ruleIdTuples = Belt_List.toArray(Belt_List.keepMap(model.rules, (function (param) {
                var item = param.item;
                var eventId;
                if (typeof item !== "object") {
                  return ;
                }
                var variant = item.NAME;
                if (variant === "PropertyRef" || variant === "EventObjectField") {
                  eventId = item.VAL[0];
                } else {
                  if (variant !== "Event") {
                    return ;
                  }
                  eventId = item.VAL;
                }
                if (eventId === $$event.id) {
                  return [
                          param.id,
                          Shortid()
                        ];
                }
                
              })));
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (_branch) {
                    Curry._1(addToast, {
                          message: "The event was duplicated",
                          toastType: /* Success */0
                        });
                    return Router.Schema.pushDrawerItem(undefined, {
                                NAME: "event",
                                VAL: [
                                  toEventId,
                                  undefined,
                                  undefined,
                                  false
                                ]
                              });
                  }),
                (function (branch) {
                    return AnalyticsRe.duplicateEvent(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), $$event.id, $$event.name, correctCase, expectedEventCase, NamedBranch.getId(branch), schemaGroup.schemaId);
                  }),
                (function (param) {
                    return Curry._1(addToast, {
                                message: "There was an error duplicating the event",
                                toastType: /* Error */1
                              });
                  }),
                [[
                    {
                      NAME: "DuplicateEvent",
                      VAL: [
                        [
                          $$event.id,
                          toEventId
                        ],
                        triggerIdTuples,
                        ruleIdTuples
                      ]
                    },
                    {
                      eventId: toEventId,
                      eventQuery: toEventId
                    }
                  ]]
              ]);
  };
  var handleArchive = function (param) {
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (_branch) {
                    var match = Router.getStaticRoute(undefined);
                    return Router.Schema.replaceDrawerItems(Belt_List.keep(match.drawerItems, (function (drawerItem) {
                                      if (typeof drawerItem === "object" && drawerItem.NAME === "event") {
                                        return drawerItem.VAL[0] !== $$event.id;
                                      } else {
                                        return true;
                                      }
                                    })));
                  }),
                (function (branch) {
                    Curry._1(addToast, {
                          message: "The event has been archived"
                        });
                    return AnalyticsRe.eventRemoved(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), currentFilters, $$event.id, $$event.name, List.length($$event.properties), correctCase, expectedEventCase, NamedBranch.getId(branch), schemaGroup.schemaId);
                  }),
                undefined,
                [[
                    {
                      NAME: "Archive",
                      VAL: {
                        NAME: "Event",
                        VAL: $$event.id
                      }
                    },
                    {
                      eventId: $$event.id,
                      eventQuery: $$event.id
                    }
                  ]]
              ]);
  };
  var fromItem_itemId = $$event.id;
  var fromItem_itemName = $$event.name;
  var fromItem = {
    itemId: fromItem_itemId,
    itemName: fromItem_itemName,
    itemType: "Event"
  };
  var tmp;
  if (Models.Role.canEdit(role)) {
    var match$3 = AvoLimits.NameMapping.computeAvailability(workspace);
    tmp = [{
        NAME: "Option",
        VAL: {
          label: "Add Name Mapping" + (
            match$3 === "Available" ? "" : " ⚡️"
          ),
          onClick: (function (param) {
              if (AvoLimits.NameMapping.isAvailable(workspace)) {
                return Curry.app(sendActions, [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            (function (branch) {
                                var eventsWithMapping = Belt_List.concat(StateUtils.eventsWithNameMapping(model), {
                                      hd: $$event.id,
                                      tl: /* [] */0
                                    });
                                return AnalyticsRe.eventUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.source("N/A", "N/A"), $$event.id, $$event.name, "AddRule", undefined, undefined, undefined, undefined, undefined, Belt_List.length(BeltListExtensions.dedupeString(eventsWithMapping)), Belt_List.length(eventsWithMapping), Belt_List.length(Belt_List.keepU(eventsWithMapping, (function (id) {
                                                      return id === $$event.id;
                                                    }))), Belt_List.length(model.events), "NameMapping", undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, expectedEventCase, $$event.id, schemaGroup.schemaId, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
                              }),
                            undefined,
                            [[
                                {
                                  NAME: "AddRule",
                                  VAL: [
                                    Shortid(),
                                    {
                                      NAME: "Event",
                                      VAL: $$event.id
                                    },
                                    {
                                      NAME: "NameMapping",
                                      VAL: {
                                        destination: undefined,
                                        name: undefined
                                      }
                                    }
                                  ]
                                },
                                {
                                  eventId: $$event.id,
                                  eventQuery: $$event.id
                                }
                              ]]
                          ]);
              } else {
                return Curry._1(globalSend, {
                            TAG: /* OpenModal */4,
                            _0: {
                              NAME: "BillingPrompt",
                              VAL: "NameMapping"
                            }
                          });
              }
            })
        }
      }];
  } else {
    tmp = [];
  }
  var tmp$1;
  if (Models.Role.canEdit(role)) {
    var match$4 = AvoLimits.DuplicateEvents.computeAvailability(workspace);
    tmp$1 = [{
        NAME: "Option",
        VAL: {
          label: "Duplicate event" + (
            match$4 === "Available" ? "" : " ⚡️"
          ),
          onClick: handleDuplicateEvent
        }
      }];
  } else {
    tmp$1 = [];
  }
  var template = schema.onboardingTemplate;
  var matchesNoDestination = function (id) {
    return Belt_List.every(model.destinations, (function (destination) {
                  return destination.id !== id;
                }));
  };
  var eventNameMappings = Belt_List.keepU(model.rules, (function (rule) {
          var match = rule.item;
          var match$1 = rule.definition;
          if (typeof match !== "object") {
            return false;
          }
          if (match.NAME !== "Event") {
            return false;
          }
          if (typeof match$1 !== "object") {
            return false;
          }
          if (match$1.NAME !== "NameMapping") {
            return false;
          }
          var destination = match$1.VAL.destination;
          var destinationMissing = destination !== undefined && typeof destination === "object" && destination.NAME === "Destination" ? matchesNoDestination(destination.VAL) : false;
          if (match.VAL === $$event.id) {
            return !destinationMissing;
          } else {
            return false;
          }
        }));
  var goals = model.goals;
  var tmp$2;
  if (maybeDrawerItem !== undefined && typeof maybeDrawerItem === "object" && maybeDrawerItem.NAME === "event") {
    var commentId = maybeDrawerItem.VAL[1];
    tmp$2 = commentId !== undefined ? commentId : undefined;
  } else {
    tmp$2 = undefined;
  }
  var tmp$3 = {
    object_: {
      NAME: "Event",
      VAL: $$event
    },
    objectId: $$event.id,
    event: $$event,
    model: model,
    schema: schema,
    events: events,
    currentBranch: currentBranch,
    openBranches: openBranches
  };
  if (tmp$2 !== undefined) {
    tmp$3.goToCommentId = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, maybeDrawerItem !== undefined && typeof maybeDrawerItem === "object" && maybeDrawerItem.NAME === "event" && maybeDrawerItem.VAL[3] ? ({
                        hd: Css.position("fixed"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.bottom(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.grey10),
                                  tl: {
                                    hd: Css.padding4(Css.px(50), Css.vw(5), Css.px(0), Css.vw(5)),
                                    tl: {
                                      hd: Css.zIndex(Styles.ZIndex.eventDetailsFullScreen),
                                      tl: {
                                        hd: Css.overflowY("auto"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }) : /* [] */0)
            }, React.createElement("div", {
                  key: $$event.id,
                  className: Curry._1(Css.style, {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.width(Css.pct(100)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderBottomWidth(Css.px(0)),
                              tl: isFullscreen ? ({
                                    hd: Css.borderRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                      tl: /* [] */0
                                    }
                                  }) : /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement(DrawerHeader.make, {
                      kind: "Event",
                      options: Belt_Array.concatMany([
                            tmp,
                            [
                              {
                                NAME: "Option",
                                VAL: {
                                  label: "Full Screen",
                                  onClick: (function (param) {
                                      return Router.Schema.pushDrawerItem(undefined, {
                                                  NAME: "event",
                                                  VAL: [
                                                    $$event.id,
                                                    undefined,
                                                    undefined,
                                                    true
                                                  ]
                                                });
                                    })
                                }
                              },
                              {
                                NAME: "Option",
                                VAL: {
                                  label: "Copy URL",
                                  onClick: (function (param) {
                                      CopyTextToClipboard(Router.Link.getSimple(undefined));
                                      
                                    })
                                }
                              }
                            ],
                            tmp$1,
                            Models.Role.canEdit(role) ? [{
                                  NAME: "Option",
                                  VAL: {
                                    label: "Archive Event",
                                    onClick: (function (param) {
                                        if (eventMetrics === /* [] */0) {
                                          return handleArchive(undefined);
                                        }
                                        var count = String(Belt_List.length(eventMetrics));
                                        var plural = count === "1" ? "" : "s";
                                        return Curry._1(globalSend, {
                                                    TAG: /* OpenModal */4,
                                                    _0: {
                                                      NAME: "AreYouSureModal",
                                                      VAL: [
                                                        "Are you sure you want to archive this event?",
                                                        "This event belongs to " + count + " metric" + plural + " and archiving it will remove it from the metric" + plural + ". You can restore the event from the activity log which will also restore its place in the metric" + plural + ".",
                                                        "Archive",
                                                        handleArchive,
                                                        (function (param) {
                                                            
                                                          })
                                                      ]
                                                    }
                                                  });
                                      })
                                  }
                                }] : []
                          ]),
                      children: React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.position("relative"),
                                  tl: {
                                    hd: Css.height(Css.px(60)),
                                    tl: {
                                      hd: Css.marginLeft(Css.px(-20)),
                                      tl: {
                                        hd: Css.marginTop(Css.px(-19)),
                                        tl: {
                                          hd: Css.marginBottom(Css.px(-19)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement(NameInput.make, {
                                name: $$event.name,
                                existingNames: StateUtils.getEventNamespace(events),
                                expectedCase: expectedEventCase,
                                forceCase: config.forceEventCase,
                                onChange: (function (value, param, correctCase) {
                                    if (value !== $$event.name) {
                                      return Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  (function (branch) {
                                                      return AnalyticsRe.eventNameUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), currentFilters, $$event.id, $$event.name, Case.getCasingWithWorkspaceCase(value, expectedEventCase), $$event.name, value.length, Case.to_("Sentence", value).split(" ").length, correctCase, $$event.id, NamedBranch.getId(branch), schemaGroup.schemaId, $$event.id, $$event.name);
                                                    }),
                                                  undefined,
                                                  [[
                                                      {
                                                        NAME: "UpdateEventName",
                                                        VAL: [
                                                          $$event.id,
                                                          value
                                                        ]
                                                      },
                                                      {
                                                        eventId: $$event.id,
                                                        eventQuery: $$event.id
                                                      }
                                                    ]]
                                                ]);
                                    }
                                    
                                  }),
                                placeholder: "Event Name",
                                disabled: !Models.Role.canEdit(role),
                                itemType: "Event",
                                actionType: "Rename",
                                getPossibleItemLink: (function (eventName) {
                                    return Belt_Option.map(StateUtils.getEventByName(eventName, model), (function (param) {
                                                  return Router.Link.addDrawerItem(undefined, {
                                                              NAME: "event",
                                                              VAL: [
                                                                param.id,
                                                                undefined,
                                                                undefined,
                                                                false
                                                              ]
                                                            });
                                                }));
                                  })
                              }))
                    }), React.createElement("div", {
                      className: "branchContainerTour " + Curry._1(Css.style, {
                            hd: Css.overflowY("auto"),
                            tl: {
                              hd: Css.maxHeight(Css_Legacy_Core.Calc.$neg(Css.vh(100), DrawerHeader.heightInPixels)),
                              tl: /* [] */0
                            }
                          })
                    }, !(template == null) && Belt_Array.some(template.events, (function (id) {
                            return id === $$event.id;
                          })) ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(10), Css.px(35)),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.grey20),
                                    tl: {
                                      hd: Css.color(Styles.Color.grey70),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.regular),
                                          tl: {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.justifyContent("spaceBetween"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                          }, "This is an example event", Models.Role.canEdit(role) ? React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: /* [] */0
                                      })
                                }, React.createElement("button", {
                                      className: Curry._1(Css.merge, {
                                            hd: Curry._1(Css.style, Styles.button),
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.fontSize(Styles.FontSize.small),
                                                    tl: {
                                                      hd: Css.fontWeight(Styles.FontWeight.bold),
                                                      tl: {
                                                        hd: Css.color(Styles.Color.grey70),
                                                        tl: {
                                                          hd: Css.margin2(Css.px(0), Css.px(2)),
                                                          tl: {
                                                            hd: Css.hover({
                                                                  hd: Css.color(Styles.Color.mintGreenSecondary),
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      onClick: (function (param) {
                                          var schemaRef = Firebase.app(undefined).firestore().collection("schemas").doc(schema.id);
                                          schemaRef.set({
                                                onboardingTemplate: {
                                                  events: Belt_Array.keep(template.events, (function (id) {
                                                          return id !== $$event.id;
                                                        })),
                                                  properties: template.properties,
                                                  propertyGroups: template.propertyGroups,
                                                  goals: template.goals,
                                                  metrics: template.metrics
                                                }
                                              }, {"merge": true});
                                          return AnalyticsRe.exampleInteraction(schemaGroup, "KeepCurrentExample", "Event", schemaGroup.branchId, schemaGroup.schemaId);
                                        })
                                    }, "Keep Event"), React.createElement("button", {
                                      className: Curry._1(Css.merge, {
                                            hd: Curry._1(Css.style, Styles.button),
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.fontSize(Styles.FontSize.small),
                                                    tl: {
                                                      hd: Css.fontWeight(Styles.FontWeight.bold),
                                                      tl: {
                                                        hd: Css.color(Styles.Color.grey70),
                                                        tl: {
                                                          hd: Css.margin2(Css.px(0), Css.px(2)),
                                                          tl: {
                                                            hd: Css.hover({
                                                                  hd: Css.color(Styles.Color.grapeErrorSecondary),
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      onClick: (function (param) {
                                          return Curry.app(sendActions, [
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      (function (branch) {
                                                          return AnalyticsRe.exampleInteraction(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "RemoveCurrentExample", "Event", NamedBranch.getId(branch), schemaGroup.schemaId);
                                                        }),
                                                      undefined,
                                                      [[
                                                          {
                                                            NAME: "Archive",
                                                            VAL: {
                                                              NAME: "Event",
                                                              VAL: $$event.id
                                                            }
                                                          },
                                                          {
                                                            eventId: $$event.id,
                                                            eventQuery: $$event.id
                                                          }
                                                        ]]
                                                    ]);
                                        })
                                    }, "Remove Action"), React.createElement("button", {
                                      className: Curry._1(Css.merge, {
                                            hd: Curry._1(Css.style, Styles.button),
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.fontSize(Styles.FontSize.small),
                                                    tl: {
                                                      hd: Css.fontWeight(Styles.FontWeight.bold),
                                                      tl: {
                                                        hd: Css.backgroundColor(Styles.Color.grapeError),
                                                        tl: {
                                                          hd: Css.color(Styles.Color.white),
                                                          tl: {
                                                            hd: Css.margin2(Css.px(0), Css.px(5)),
                                                            tl: {
                                                              hd: Css.padding2(Css.px(5), Css.px(10)),
                                                              tl: {
                                                                hd: Css.borderRadius(Styles.Border.radius),
                                                                tl: {
                                                                  hd: Css.hover({
                                                                        hd: Css.backgroundColor(Styles.Color.grapeErrorSecondary),
                                                                        tl: /* [] */0
                                                                      }),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      onClick: (function (param) {
                                          FirebaseUtils.archiveTemplate(template, (function (eta) {
                                                  return Curry.app(sendActions, [
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              eta
                                                            ]);
                                                }));
                                          return AnalyticsRe.exampleInteraction(schemaGroup, "RemoveAllExamples", "Event", schemaGroup.branchId, schemaGroup.schemaId);
                                        })
                                    }, "Remove All Examples")) : null) : null, React.createElement("div", undefined, Belt_Option.mapWithDefault($$event.uniqueName, null, (function (uniqueName) {
                                var eventsWithSameName = Belt_List.keepU(events, (function (item) {
                                        if (Case.camel(item.name) === Case.camel($$event.name)) {
                                          return item.id !== $$event.id;
                                        } else {
                                          return false;
                                        }
                                      }));
                                var tmp;
                                if (eventsWithSameName) {
                                  if (eventsWithSameName.tl) {
                                    tmp = React.createElement("div", undefined, React.createElement("span", undefined, "There are " + String(Belt_List.length(eventsWithSameName)) + " events with the same or similar name:"), React.createElement("span", undefined, Belt_List.toArray(Belt_List.map(eventsWithSameName, (function ($$event) {
                                                        return React.createElement("span", undefined, React.createElement(Link.make, {
                                                                        path: Router.Link.addDrawerItem(undefined, {
                                                                              NAME: "event",
                                                                              VAL: [
                                                                                $$event.id,
                                                                                undefined,
                                                                                undefined,
                                                                                false
                                                                              ]
                                                                            }),
                                                                        className: Curry._1(Css.style, {
                                                                              hd: Css.color(Styles.Color.blue),
                                                                              tl: {
                                                                                hd: Css.paddingLeft(Css.px(5)),
                                                                                tl: {
                                                                                  hd: Css.hover({
                                                                                        hd: Css.color(Styles.Color.blueSecondary),
                                                                                        tl: /* [] */0
                                                                                      }),
                                                                                  tl: /* [] */0
                                                                                }
                                                                              }
                                                                            }),
                                                                        children: $$event.name
                                                                      }), ",");
                                                      })))));
                                  } else {
                                    var $$event$1 = eventsWithSameName.hd;
                                    tmp = React.createElement("div", undefined, React.createElement("span", undefined, "There is one event with same or similar name:"), React.createElement(Link.make, {
                                              path: Router.Link.addDrawerItem(undefined, {
                                                    NAME: "event",
                                                    VAL: [
                                                      $$event$1.id,
                                                      undefined,
                                                      undefined,
                                                      false
                                                    ]
                                                  }),
                                              className: Curry._1(Css.style, {
                                                    hd: Css.color(Styles.Color.blue),
                                                    tl: {
                                                      hd: Css.paddingLeft(Css.px(5)),
                                                      tl: {
                                                        hd: Css.hover({
                                                              hd: Css.color(Styles.Color.blueSecondary),
                                                              tl: /* [] */0
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }),
                                              children: $$event$1.name
                                            }));
                                  }
                                } else {
                                  tmp = React.createElement(React.Fragment, undefined, "It looks like the naming conflict has been resolved. You can safely remove it but that will change how the event is represented in Codegen. ", React.createElement(TextButton.make, {
                                            onClick: (function (param) {
                                                return Curry.app(sendActions, [
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            [[
                                                                {
                                                                  NAME: "UpdateEventUniqueNameV2",
                                                                  VAL: [
                                                                    $$event.id,
                                                                    ""
                                                                  ]
                                                                },
                                                                {
                                                                  eventId: $$event.id,
                                                                  eventQuery: $$event.id
                                                                }
                                                              ]]
                                                          ]);
                                              }),
                                            style: "Blue",
                                            size: "Medium",
                                            children: "Remove identifier"
                                          }));
                                }
                                return React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.alignItems("flexStart"),
                                                    tl: {
                                                      hd: Css.margin4(Css.px(10), Css.px(20), Css.px(0), Css.px(30)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                })
                                          }, React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.marginTop(Css.px(11)),
                                                      tl: {
                                                        hd: Css.marginRight(Css.px(5)),
                                                        tl: /* [] */0
                                                      }
                                                    })
                                              }, React.createElement(IconIdentifier.make, {})), React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.width(Css.pct(100)),
                                                      tl: {
                                                        hd: Css.padding(Css.px(10)),
                                                        tl: /* [] */0
                                                      }
                                                    })
                                              }, React.createElement("div", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.display("flex"),
                                                          tl: {
                                                            hd: Css.alignItems("center"),
                                                            tl: {
                                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                                              tl: {
                                                                hd: Css.color(Styles.Color.grey90),
                                                                tl: {
                                                                  hd: Css.fontSize(Styles.FontSize.regular),
                                                                  tl: {
                                                                    hd: Css.marginTop(Css.px(1)),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        })
                                                  }, "Action Identifier"), React.createElement("div", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.fontWeight(Styles.FontWeight.regular),
                                                          tl: {
                                                            hd: Css.fontSize(Styles.FontSize.regular),
                                                            tl: {
                                                              hd: Css.color(Styles.Color.grey70),
                                                              tl: {
                                                                hd: Css.marginTop(Css.px(5)),
                                                                tl: {
                                                                  hd: Css.marginBottom(Css.px(15)),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }
                                                          }
                                                        })
                                                  }, React.createElement("div", undefined, "The event identifier is used to differentiate between events that have the same name. It does not affect how the event is sent but does change how the event is represented in the code Avo generates. We recommend changing the event identifier to something that describes how this event is different from other events with the same name."), React.createElement("div", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                                              tl: {
                                                                hd: Css.marginTop(Css.px(4)),
                                                                tl: /* [] */0
                                                              }
                                                            })
                                                      }, tmp)), React.createElement("div", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.position("relative"),
                                                          tl: {
                                                            hd: Css.margin4(Css.px(5), Css.px(0), Css.px(0), Css.px(0)),
                                                            tl: {
                                                              hd: Css.height(Css.px(50)),
                                                              tl: {
                                                                hd: Css.width(Css.pct(100)),
                                                                tl: {
                                                                  hd: Css.borderRadius(Styles.Border.radius),
                                                                  tl: {
                                                                    hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        })
                                                  }, React.createElement(NameInput.make, {
                                                        name: uniqueName,
                                                        existingNames: StateUtils.getEventNamespace(events),
                                                        expectedCase: expectedEventCase,
                                                        forceCase: config.forceEventCase,
                                                        onChange: (function (value, param, param$1) {
                                                            if (value !== uniqueName) {
                                                              return Curry.app(sendActions, [
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          [[
                                                                              {
                                                                                NAME: "UpdateEventUniqueNameV2",
                                                                                VAL: [
                                                                                  $$event.id,
                                                                                  value
                                                                                ]
                                                                              },
                                                                              {
                                                                                eventId: $$event.id,
                                                                                eventQuery: $$event.id
                                                                              }
                                                                            ]]
                                                                        ]);
                                                            }
                                                            
                                                          }),
                                                        placeholder: "Event Name",
                                                        disabled: !Models.Role.canEdit(role),
                                                        inputStyles: {
                                                          hd: Css.fontSize(Styles.FontSize.regular),
                                                          tl: {
                                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                                            tl: /* [] */0
                                                          }
                                                        },
                                                        itemType: "Event",
                                                        actionType: "Rename",
                                                        getPossibleItemLink: (function (eventName) {
                                                            return Belt_Option.map(StateUtils.getEventByName(eventName, model), (function (param) {
                                                                          return Router.Link.addDrawerItem(undefined, {
                                                                                      NAME: "event",
                                                                                      VAL: [
                                                                                        param.id,
                                                                                        undefined,
                                                                                        undefined,
                                                                                        false
                                                                                      ]
                                                                                    });
                                                                        }));
                                                          })
                                                      }))));
                              })), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("flexStart"),
                                      tl: {
                                        hd: Css.padding4(Css.px(15), Css.px(20), Css.px(15), Css.px(30)),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.marginTop(Css.px(11)),
                                        tl: {
                                          hd: Css.marginRight(Css.px(5)),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement(IconDescription.make, {})), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.width(Css.pct(100)),
                                        tl: /* [] */0
                                      })
                                }, React.createElement("label", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: {
                                                  hd: Css.color(Styles.Color.grey90),
                                                  tl: {
                                                    hd: Css.fontSize(Styles.FontSize.regular),
                                                    tl: {
                                                      hd: Css.padding4(Css.px(11), Css.px(0), Css.px(5), Css.px(10)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      htmlFor: "description-input"
                                    }, "Description", React.createElement(Tooltip.WithAnalytics.make, {
                                          fromItem: fromItem,
                                          title: "Description",
                                          children: React.createElement(Tooltip.Content.make, {
                                                children: React.createElement(Markdown.make, {
                                                      source: Tooltips.eventDescription
                                                    })
                                              })
                                        })), React.createElement(OpsWithState.make, {
                                      value: $$event.description,
                                      onFlush: (function (description) {
                                          return Curry.app(sendActions, [
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      [[
                                                          {
                                                            NAME: "UpdateEventDescription",
                                                            VAL: [
                                                              $$event.id,
                                                              description
                                                            ]
                                                          },
                                                          {
                                                            eventId: $$event.id,
                                                            eventQuery: $$event.id
                                                          }
                                                        ]]
                                                    ]);
                                        }),
                                      onChangeCompleted: (function (value, valueBefore) {
                                          AnalyticsRe.descriptionUpdated(schemaGroup, valueBefore, "Event", value.split(" ").length, value, value.length, $$event.name, schemaGroup.branchId, schemaGroup.schemaId);
                                          
                                        }),
                                      children: (function (value, onChange, onBlur, onFocus) {
                                          return React.createElement(ReactTextareaAutosize, {
                                                      className: Curry._1(Css.merge, {
                                                            hd: Curry._1(Css.style, Styles.input),
                                                            tl: {
                                                              hd: Curry._1(Css.style, {
                                                                    hd: Css.display("block"),
                                                                    tl: {
                                                                      hd: Css.flex({
                                                                            NAME: "num",
                                                                            VAL: 1.0
                                                                          }),
                                                                      tl: {
                                                                        hd: Css.padding(Css.px(10)),
                                                                        tl: {
                                                                          hd: Css.marginLeft(Css.px(0)),
                                                                          tl: {
                                                                            hd: Css.borderRadius(Styles.Border.radius),
                                                                            tl: {
                                                                              hd: Css.border(Css.px(1), "solid", "transparent"),
                                                                              tl: {
                                                                                hd: Css.fontWeight(Styles.FontWeight.regular),
                                                                                tl: {
                                                                                  hd: Css.fontSize(Styles.FontSize.regular),
                                                                                  tl: {
                                                                                    hd: Css.color(Styles.Color.grey90),
                                                                                    tl: {
                                                                                      hd: Css.focus({
                                                                                            hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                                                            tl: /* [] */0
                                                                                          }),
                                                                                      tl: {
                                                                                        hd: Css.hover({
                                                                                              hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                                                              tl: /* [] */0
                                                                                            }),
                                                                                        tl: /* [] */0
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }),
                                                              tl: /* [] */0
                                                            }
                                                          }),
                                                      maxRows: 15,
                                                      minRows: 1,
                                                      onChange: (function (domEvent) {
                                                          return Curry._1(onChange, domEvent.target.value);
                                                        }),
                                                      placeholder: "Describe the event...",
                                                      onFocus: (function (domEvent) {
                                                          return Curry._1(onFocus, domEvent.target.value);
                                                        }),
                                                      onBlur: (function (domEvent) {
                                                          return Curry._1(onBlur, domEvent.target.value);
                                                        }),
                                                      value: value,
                                                      autoFocus: value === "",
                                                      id: "description-input",
                                                      disabled: !Models.Role.canEdit(role)
                                                    });
                                        })
                                    }))), React.createElement(EventDetailTriggers.make, {
                              event: $$event,
                              modelSources: model.sources,
                              schemaGroup: schemaGroup,
                              viewerCanEdit: Models.Role.canEdit(role)
                            }), Belt_List.length(eventNameMappings) > 0 ? React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.padding4(Css.px(0), Css.px(30), Css.px(20), Css.px(30)),
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.marginTop(Css.px(10)),
                                          tl: {
                                            hd: Css.marginRight(Css.px(5)),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement(IconSendAs.make, {})), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.width(Css.pct(100)),
                                          tl: {
                                            hd: Css.paddingLeft(Css.px(10)),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: {
                                                  hd: Css.paddingTop(Css.px(8)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, React.createElement($$Text.make, {
                                            size: "Medium",
                                            weight: "Semi",
                                            color: Styles.Color.grey90,
                                            children: "Send As"
                                          }), React.createElement(Tooltip.WithAnalytics.make, {
                                            fromItem: fromItem,
                                            title: "Name mapping",
                                            children: React.createElement(Tooltip.Content.make, {
                                                  children: React.createElement(Markdown.make, {
                                                        source: Tooltips.eventSendAs
                                                      })
                                                })
                                          })), Belt_List.toArray(Belt_List.mapU(eventNameMappings, (function (rule) {
                                              return React.createElement(EventRule.make, {
                                                          rule: rule,
                                                          event: $$event,
                                                          sendActions: sendActions,
                                                          model: model,
                                                          schemaGroup: schemaGroup,
                                                          role: role,
                                                          schema: schema
                                                        });
                                            }))))) : null, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("flexStart"),
                                      tl: {
                                        hd: Css.padding4(Css.px(0), Css.px(10), Css.px(20), Css.px(30)),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.marginTop(Css.px(16)),
                                        tl: {
                                          hd: Css.marginRight(Css.px(5)),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement(IconSend.make, {
                                      color: Styles.Color.grey40
                                    })), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.width(Css.pct(100)),
                                        tl: {
                                          hd: Css.marginTop(Css.px(14)),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: /* [] */0
                                          })
                                    }, React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.alignItems("center"),
                                                  tl: {
                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                    tl: {
                                                      hd: Css.color(Styles.Color.grey90),
                                                      tl: {
                                                        hd: Css.fontSize(Styles.FontSize.regular),
                                                        tl: {
                                                          hd: Css.paddingLeft(Css.px(10)),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              })
                                        }, "Sources"), React.createElement(Tooltip.WithAnalytics.make, {
                                          fromItem: fromItem,
                                          title: "Adding a source",
                                          children: React.createElement(Tooltip.Content.make, {
                                                children: React.createElement(Markdown.make, {
                                                      source: Tooltips.excludeSources
                                                    })
                                              })
                                        })), React.createElement(EventSources.make, {
                                      event: $$event,
                                      model: model,
                                      role: role,
                                      schemaGroup: schemaGroup,
                                      sendActions: sendActions,
                                      isEventModified: isEventModified
                                    }))), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.padding4(Css.px(0), Css.px(30), Css.px(20), Css.px(30)),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.marginTop(Css.px(16)),
                                        tl: {
                                          hd: Css.marginRight(Css.px(5)),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement(IconSideEffect.make, {})), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.width(Css.pct(100)),
                                        tl: /* [] */0
                                      })
                                }, React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.marginTop(Css.px(14)),
                                              tl: {
                                                hd: Css.marginBottom(Css.px(4)),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.alignItems("center"),
                                                  tl: {
                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                    tl: {
                                                      hd: Css.color(Styles.Color.grey90),
                                                      tl: {
                                                        hd: Css.fontSize(Styles.FontSize.regular),
                                                        tl: {
                                                          hd: Css.paddingLeft(Css.px(10)),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              })
                                        }, "Actions"), React.createElement(Tooltip.WithAnalytics.make, {
                                          fromItem: fromItem,
                                          title: "Actions",
                                          children: React.createElement(Tooltip.Content.make, {
                                                children: React.createElement(Markdown.make, {
                                                      source: Tooltips.trackingMethods
                                                    })
                                              })
                                        })), React.createElement(EventSideEffects.make, {
                                      currentFilters: currentFilters,
                                      event: $$event,
                                      filters: filters,
                                      model: model,
                                      role: role,
                                      schemaGroup: schemaGroup,
                                      sendActions: sendActions
                                    })))), eventMetrics !== /* [] */0 ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("flexStart"),
                                    tl: {
                                      hd: Css.padding4(Css.px(0), Css.px(30), Css.px(10), Css.px(30)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.marginTop(Css.px(16)),
                                      tl: {
                                        hd: Css.marginRight(Css.px(5)),
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.createElement(IconMetric.make, {
                                    color: Styles.Color.grey40
                                  })), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.width(Css.pct(100)),
                                      tl: /* [] */0
                                    })
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.color(Styles.Color.grey90),
                                                tl: {
                                                  hd: Css.fontSize(Styles.FontSize.regular),
                                                  tl: {
                                                    hd: Css.padding4(Css.px(12), Css.px(0), Css.px(5), Css.px(10)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, "Related Metrics", React.createElement(Tooltip.WithAnalytics.make, {
                                        fromItem: fromItem,
                                        title: "Related Metrics",
                                        children: React.createElement(Tooltip.Content.make, {
                                              children: React.createElement(Markdown.make, {
                                                    source: Tooltips.relatedMetrics
                                                  })
                                            })
                                      })), React.createElement(EventMetrics.make, {
                                    metrics: eventMetrics
                                  }))) : null, goals ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("flexStart"),
                                    tl: {
                                      hd: Css.padding4(Css.px(5), Css.px(30), Css.px(0), Css.px(30)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.marginTop(Css.px(5)),
                                      tl: {
                                        hd: Css.marginRight(Css.px(5)),
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.createElement(IconCategory.make, {})), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.width(Css.pct(100)),
                                      tl: /* [] */0
                                    })
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.color(Styles.Color.grey90),
                                                tl: {
                                                  hd: Css.fontSize(Styles.FontSize.regular),
                                                  tl: {
                                                    hd: Css.padding4(Css.px(4), Css.px(0), Css.px(8), Css.px(10)),
                                                    tl: {
                                                      hd: Css.marginTop(Css.px(-2)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, "Categories", React.createElement(Tooltip.WithAnalytics.make, {
                                        fromItem: fromItem,
                                        title: "Categories",
                                        children: React.createElement(Tooltip.Content.make, {
                                              children: React.createElement(Markdown.make, {
                                                    source: Tooltips.categories
                                                  })
                                            })
                                      })), React.createElement(CategoryInput.make, {
                                    categories: goals,
                                    currentCategories: StateUtils.getGoalsWithEvent($$event, model),
                                    role: role,
                                    onRemove: (function (category) {
                                        return Curry.app(sendActions, [
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [[
                                                        {
                                                          NAME: "RemoveEventFromGoal",
                                                          VAL: [
                                                            category.id,
                                                            $$event.id
                                                          ]
                                                        },
                                                        {
                                                          eventId: $$event.id,
                                                          eventQuery: $$event.id,
                                                          goalId: category.id,
                                                          goalQuery: category.id
                                                        }
                                                      ]]
                                                  ]);
                                      }),
                                    onAdd: (function (category) {
                                        return Curry.app(sendActions, [
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [[
                                                        {
                                                          NAME: "AddEventToGoal",
                                                          VAL: [
                                                            category.id,
                                                            $$event.id
                                                          ]
                                                        },
                                                        {
                                                          eventId: $$event.id,
                                                          eventQuery: $$event.id,
                                                          goalId: category.id,
                                                          goalQuery: category.id
                                                        }
                                                      ]]
                                                  ]);
                                      }),
                                    sendActions: sendActions,
                                    onCreateActions: (function (categoryId) {
                                        return [[
                                                  {
                                                    NAME: "AddEventToGoal",
                                                    VAL: [
                                                      categoryId,
                                                      $$event.id
                                                    ]
                                                  },
                                                  {
                                                    eventId: $$event.id,
                                                    eventQuery: $$event.id,
                                                    goalId: categoryId,
                                                    goalQuery: categoryId
                                                  }
                                                ]];
                                      }),
                                    onAddAnalytics: (function (categoryId, categoryName) {
                                        return AnalyticsRe.categoryUpdated(schemaGroup, categoryName, categoryId, "EventAdded", "N/A", undefined, $$event.name, $$event.id, schemaGroup.branchId, schemaGroup.schemaId);
                                      }),
                                    onRemoveAnalytics: (function (category) {
                                        return AnalyticsRe.categoryUpdated(schemaGroup, category.name, category.id, "EventRemoved", "N/A", undefined, $$event.name, $$event.id, schemaGroup.branchId, schemaGroup.schemaId);
                                      })
                                  }))) : null, React.createElement("div", {
                          ref: tagsAnchor
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("flexStart"),
                                  tl: {
                                    hd: Css.padding4(Css.px(15), Css.px(30), Css.px(15), Css.px(30)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.marginTop(Css.px(11)),
                                    tl: {
                                      hd: Css.marginRight(Css.px(5)),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement(IconTags.make, {})), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontWeight(Styles.FontWeight.bold),
                                    tl: {
                                      hd: Css.color(Styles.Color.grey70),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.small),
                                        tl: {
                                          hd: Css.width(Css.pct(100)),
                                          tl: {
                                            hd: Css.padding2(Css.px(0), Css.px(10)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: {
                                              hd: Css.color(Styles.Color.grey90),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.regular),
                                                tl: {
                                                  hd: Css.padding4(Css.px(10), Css.px(0), Css.px(3), Css.px(3)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      })
                                }, "Tags", React.createElement(Tooltip.WithAnalytics.make, {
                                      fromItem: fromItem,
                                      title: "Tags",
                                      children: React.createElement(Tooltip.Content.make, {
                                            children: React.createElement(Markdown.make, {
                                                  source: Tooltips.tags
                                                })
                                          })
                                    })), React.createElement(TagsInput.make, {
                                  event: $$event,
                                  events: events,
                                  role: role
                                }))), hasIncludedSource ? React.createElement(EventImplementation.make, {
                            branchStatus: branchStatus,
                            event: $$event,
                            model: model
                          }) : null, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginTop(Css.px(15)),
                                tl: {
                                  hd: Css.padding4(Css.px(10), Css.px(0), Css.px(25), Css.px(0)),
                                  tl: {
                                    hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey20),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement(ObjectActivityLog.make, tmp$3)), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.flexGrow(1.0),
                                tl: /* [] */0
                              })
                        }), React.createElement(CommentInput.make, {
                          userId: user.id,
                          userExternalId: user.id,
                          schemaId: schema.id,
                          eventId: $$event.id,
                          threadId: $$event.id,
                          events: events,
                          itemType: "Event",
                          itemName: $$event.name,
                          mini: false,
                          currentBranch: currentBranch,
                          role: role,
                          schemaGroup: schemaGroup,
                          location: "Event"
                        }))));
}

var make = EventDetails;

exports.scrollToDomRef = scrollToDomRef;
exports.make = make;
/* Css Not a pure module */
