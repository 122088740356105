// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var PropertyType = require("./PropertyType.bs.js");
var PropertyInput = require("./PropertyInput.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");

function MergePropertiesModal(Props) {
  var model = Props.model;
  var propertyId = Props.propertyId;
  var onClose = Props.onClose;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useState(function () {
        
      });
  var setReplacementProperty = match[1];
  var replacementProperty = match[0];
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(500)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: Css.padding4(Css.px(30), Css.px(30), Css.px(30), Css.px(30)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.cursor("default"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontWeight(Styles.FontWeight.semi),
                        tl: {
                          hd: Css.fontSize(Styles.FontSize.large),
                          tl: {
                            hd: Css.paddingBottom(Css.px(15)),
                            tl: {
                              hd: Css.textAlign("center"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, "Archive and Replace Property"), Belt_Option.mapWithDefault(Belt_List.head(Belt_List.keepMap(model.properties, (function (property) {
                            if (property.TAG === /* PropertyRef */0) {
                              return ;
                            }
                            var property$1 = property._0;
                            if (property$1.id === propertyId) {
                              return property$1;
                            }
                            
                          }))), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding(Css.px(25)),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.color(Styles.Color.grapeError),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, "Property not found."), (function (property) {
                    return React.createElement("div", undefined, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.padding2(Css.px(10), Css.px(0)),
                                          tl: {
                                            hd: Css.fontSize(Styles.FontSize.regular),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, "This will archive the property and replace it on all events its part of with the property you select below."), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.padding4(Css.px(15), Css.px(0), Css.px(10), Css.px(0)),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: {
                                              hd: Css.fontSize(Styles.FontSize.regular),
                                              tl: /* [] */0
                                            }
                                          }
                                        })
                                  }, "Archive..."), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: {
                                            hd: Css.fontSize(Styles.FontSize.medium),
                                            tl: {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: {
                                                  hd: Css.padding2(Css.px(10), Css.px(15)),
                                                  tl: {
                                                    hd: Css.borderRadius(Styles.Border.radius),
                                                    tl: {
                                                      hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, property.name, React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.flexWrap("wrap"),
                                                tl: {
                                                  hd: Css.alignItems("baseline"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, React.createElement(PropertyType.make, {
                                            model: model,
                                            property: property
                                          }))), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.padding4(Css.px(15), Css.px(0), Css.px(10), Css.px(0)),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: {
                                              hd: Css.fontSize(Styles.FontSize.regular),
                                              tl: /* [] */0
                                            }
                                          }
                                        })
                                  }, "and replace with..."), React.createElement(PropertyInput.make, {
                                    propertyType: property.sendAs,
                                    options: {
                                      hd: [
                                        undefined,
                                        Belt_List.keepMap(model.properties, (function (item) {
                                                if (item.TAG === /* PropertyRef */0) {
                                                  return ;
                                                }
                                                var item$1 = item._0;
                                                if (Caml_obj.caml_equal(item$1.sendAs, property.sendAs) && item$1.id !== property.id) {
                                                  return {
                                                          NAME: "Property",
                                                          VAL: item$1
                                                        };
                                                }
                                                
                                              }))
                                      ],
                                      tl: /* [] */0
                                    },
                                    onSelect: (function (item, param) {
                                        if (item.NAME === "Group") {
                                          return ;
                                        }
                                        var property = item.VAL;
                                        return Curry._1(setReplacementProperty, (function (param) {
                                                      return property;
                                                    }));
                                      }),
                                    model: model,
                                    currentFilters: currentFilters,
                                    renderButton: (function (onClick, buttonRef) {
                                        return React.createElement("div", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.textAlign("left"),
                                                          tl: /* [] */0
                                                        })
                                                  }, React.createElement("button", {
                                                        ref: buttonRef,
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                                              tl: {
                                                                hd: Css.fontSize(Styles.FontSize.medium),
                                                                tl: {
                                                                  hd: Css.display("flex"),
                                                                  tl: {
                                                                    hd: Css.alignItems("center"),
                                                                    tl: {
                                                                      hd: Css.padding2(Css.px(10), Css.px(15)),
                                                                      tl: {
                                                                        hd: Css.borderRadius(Styles.Border.radius),
                                                                        tl: {
                                                                          hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                                          tl: {
                                                                            hd: Css.width(Css.pct(100)),
                                                                            tl: {
                                                                              hd: Css.cursor("pointer"),
                                                                              tl: /* [] */0
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }),
                                                        onClick: onClick
                                                      }, replacementProperty !== undefined ? React.createElement("div", {
                                                              className: Curry._1(Css.style, {
                                                                    hd: Css.display("flex"),
                                                                    tl: /* [] */0
                                                                  })
                                                            }, replacementProperty.name, React.createElement("div", {
                                                                  className: Curry._1(Css.style, {
                                                                        hd: Css.display("flex"),
                                                                        tl: {
                                                                          hd: Css.flexWrap("wrap"),
                                                                          tl: {
                                                                            hd: Css.alignItems("baseline"),
                                                                            tl: /* [] */0
                                                                          }
                                                                        }
                                                                      })
                                                                }, React.createElement(PropertyType.make, {
                                                                      model: model,
                                                                      property: replacementProperty
                                                                    }))) : React.createElement("div", {
                                                              className: Curry._1(Css.style, {
                                                                    hd: Css.color(Styles.Color.blue),
                                                                    tl: {
                                                                      hd: Css.fontSize(Styles.FontSize.regular),
                                                                      tl: {
                                                                        hd: Css.textAlign("center"),
                                                                        tl: {
                                                                          hd: Css.hover({
                                                                                hd: Css.color(Styles.Color.blueSecondary),
                                                                                tl: /* [] */0
                                                                              }),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  })
                                                            }, "Select property...")));
                                      }),
                                    sendActions: sendActions,
                                    filters: filters,
                                    propertyLocation: "PropertyDetails"
                                  }), React.createElement(Spacer.make, {
                                    height: 24
                                  }), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.justifyContent("center"),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement(Button.make, {
                                        label: "Cancel",
                                        onClick: (function (param) {
                                            return Curry._1(onClose, undefined);
                                          }),
                                        style: "ghost"
                                      }), React.createElement(Spacer.make, {
                                        width: 16
                                      }), React.createElement(Button.make, {
                                        disabled: Belt_Option.isNone(replacementProperty),
                                        label: "Archive and Replace",
                                        onClick: (function (param) {
                                            if (replacementProperty === undefined) {
                                              return ;
                                            }
                                            if (!window.confirm("Are you sure you would like to archive " + property.name + " and replace it with " + replacementProperty.name)) {
                                              return ;
                                            }
                                            var eventsWithProperty = Belt_List.keepU(model.events, (function ($$event) {
                                                    return Belt_List.someU($$event.properties, (function (item) {
                                                                  if (item.TAG === /* PropertyRef */0) {
                                                                    return item._0.id === property.id;
                                                                  } else {
                                                                    return false;
                                                                  }
                                                                }));
                                                  }));
                                            var replaceActions = Belt_Array.mapU(Belt_List.toArray(Belt_List.keepU(eventsWithProperty, (function ($$event) {
                                                            return !Belt_List.someU($$event.properties, (function (item) {
                                                                          if (item.TAG === /* PropertyRef */0) {
                                                                            return item._0.id === replacementProperty.id;
                                                                          } else {
                                                                            return false;
                                                                          }
                                                                        }));
                                                          }))), (function ($$event) {
                                                    return [
                                                            {
                                                              NAME: "AddPropertyRef",
                                                              VAL: [
                                                                $$event.id,
                                                                replacementProperty.id
                                                              ]
                                                            },
                                                            {
                                                              eventId: $$event.id,
                                                              eventQuery: $$event.id,
                                                              propertyId: replacementProperty.id,
                                                              propertyQuery: replacementProperty.id
                                                            }
                                                          ];
                                                  }));
                                            Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  Belt_Array.concat([[
                                                          {
                                                            NAME: "Archive",
                                                            VAL: {
                                                              NAME: "Property",
                                                              VAL: property.id
                                                            }
                                                          },
                                                          {
                                                            propertyId: property.id,
                                                            propertyQuery: property.id
                                                          }
                                                        ]], replaceActions)
                                                ]);
                                            Router.Schema.pushDrawerItem(undefined, {
                                                  NAME: "property",
                                                  VAL: [
                                                    replacementProperty.id,
                                                    undefined
                                                  ]
                                                });
                                            return Curry._1(onClose, undefined);
                                          })
                                      })));
                  })));
}

var make = MergePropertiesModal;

exports.make = make;
/* Css Not a pure module */
