// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var IconX = require("./IconX.bs.js");
var Icons = require("./Icons.bs.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var DidMount = require("./DidMount.bs.js");
var Intercom = require("./externals/intercom.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var TextButton = require("./TextButton.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var TrialRequest = require("./TrialRequest.bs.js");
var IconCheckmark = require("./IconCheckmark.bs.js");
var ViewerContext = require("./ViewerContext.bs.js");
var FramerMotion = require("framer-motion");
var ReactConfetti = require("react-confetti");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var IconChevronLight = require("./IconChevronLight.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var TrialProgressHooks = require("./TrialProgressHooks.bs.js");
var TrialProgressSteps = require("./TrialProgressSteps.bs.js");
var OnboardingProgressHooks = require("./OnboardingProgressHooks.bs.js");
var OnboardingProgressSteps = require("./OnboardingProgressSteps.bs.js");

var containerStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.width(Css.px(420)),
        tl: {
          hd: Css.height(Css.pct(100)),
          tl: /* [] */0
        }
      }
    });

function contentStyles(scrollable) {
  return Curry._1(Css.style, Belt_List.concatMany([
                  {
                    hd: Css.position("absolute"),
                    tl: {
                      hd: Css.top(Css.px(0)),
                      tl: {
                        hd: Css.right(Css.px(0)),
                        tl: {
                          hd: Css.bottom(Css.px(0)),
                          tl: {
                            hd: Css.width(Css.pct(100.0)),
                            tl: {
                              hd: Css.height(Css.pct(100)),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("column"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  },
                  scrollable ? ({
                        hd: Css.overflow("auto"),
                        tl: /* [] */0
                      }) : ({
                        hd: Css.overflow("hidden"),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: /* [] */0
                          }
                        }
                      })
                ]));
}

var closeButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.top(Css.px(16)),
                tl: {
                  hd: Css.padding(Css.px(8)),
                  tl: {
                    hd: Css.right(Css.px(16)),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey70),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var headerSectionStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(24)),
      tl: {
        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
        tl: /* [] */0
      }
    });

var contentSectionStyles = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(24)),
      tl: {
        hd: Css.paddingRight(Css.px(16)),
        tl: {
          hd: Css.paddingBottom(Css.px(24)),
          tl: {
            hd: Css.paddingLeft(Css.px(16)),
            tl: /* [] */0
          }
        }
      }
    });

var footerSectionStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: {
        hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey30),
        tl: /* [] */0
      }
    });

var scrollableSectionStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: {
        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
        tl: {
          hd: Css.lastChild({
                hd: Css.borderBottomWidth(Css.px(0)),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.overflow("auto"),
            tl: {
              hd: Css.flexGrow(1.0),
              tl: /* [] */0
            }
          }
        }
      }
    });

var stepsContainerStyles = Curry._1(Css.style, {
      hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.overflow("hidden"),
          tl: /* [] */0
        }
      }
    });

var stepStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.padding(Css.px(12)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("spaceBetween"),
                    tl: {
                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: {
                          hd: Css.textAlign("left"),
                          tl: {
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.backgroundColor(Styles.Color.grey20),
                                    tl: {
                                      hd: Css.disabled({
                                            hd: Css.backgroundColor(Styles.Color.white),
                                            tl: /* [] */0
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: {
                                hd: Css.lastChild({
                                      hd: Css.borderBottomWidth(Css.px(0)),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function stepIndexBubbleStyles(disabled, stepless, isCompleted) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.flexShrink(0.0),
                    tl: {
                      hd: Css.width(Css.px(22)),
                      tl: {
                        hd: Css.height(Css.px(22)),
                        tl: {
                          hd: Css.paddingTop(Css.px(1)),
                          tl: {
                            hd: Css.borderRadius(Css.pct(100)),
                            tl: {
                              hd: Css.border(Css.px(2), "solid", isCompleted ? Styles.Color.mintGreen : (
                                      stepless ? Styles.Color.setAlpha(Styles.Color.blue, 0.5) : (
                                          disabled ? Styles.Color.grey30 : Styles.Color.blue
                                        )
                                    )),
                              tl: {
                                hd: Css.backgroundColor(isCompleted ? Styles.Color.mintGreen : (
                                        stepless ? "transparent" : (
                                            disabled ? Styles.Color.grey30 : Styles.Color.blue
                                          )
                                      )),
                                tl: {
                                  hd: Css.margin(Css.px(4)),
                                  tl: {
                                    hd: Css.flexShrink(0.0),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var stepTitleContainerStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(8)),
      tl: /* [] */0
    });

var detailsViewHeader = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: /* [] */0
          }
        }
      }
    });

var contactSupportBottomStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.linkReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.width(Css.pct(100)),
              tl: {
                hd: Css.padding(Css.px(16)),
                tl: {
                  hd: Css.flexShrink(0.0),
                  tl: {
                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(16), undefined, undefined, Styles.Color.grey40)),
                    tl: {
                      hd: Css.display("grid"),
                      tl: {
                        hd: Css.gridTemplateColumns({
                              hd: Css.px(26),
                              tl: {
                                hd: "auto",
                                tl: {
                                  hd: Css.px(16),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        tl: {
                          hd: Css.gridGap(Css.px(8)),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.backgroundColor(Styles.Color.grey20),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.cursor("pointer"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var helpfulLinkStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.linkReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.padding(Css.px(12)),
              tl: {
                hd: Css.display("grid"),
                tl: {
                  hd: Css.gridTemplateColumns({
                        hd: Css.px(26),
                        tl: {
                          hd: "auto",
                          tl: {
                            hd: Css.px(16),
                            tl: /* [] */0
                          }
                        }
                      }),
                  tl: {
                    hd: Css.gridGap(Css.px(8)),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                        tl: {
                          hd: Css.cursor("pointer"),
                          tl: {
                            hd: Css.textAlign("left"),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.backgroundColor(Styles.Color.grey20),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.marginBottom(Css.px(8)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var Style = {
  containerStyles: containerStyles,
  contentStyles: contentStyles,
  closeButtonStyles: closeButtonStyles,
  headerSectionStyles: headerSectionStyles,
  contentSectionStyles: contentSectionStyles,
  footerSectionStyles: footerSectionStyles,
  scrollableSectionStyles: scrollableSectionStyles,
  stepsContainerStyles: stepsContainerStyles,
  stepStyles: stepStyles,
  stepIndexBubbleStyles: stepIndexBubbleStyles,
  stepTitleContainerStyles: stepTitleContainerStyles,
  detailsViewHeader: detailsViewHeader,
  contactSupportBottomStyles: contactSupportBottomStyles,
  helpfulLinkStyles: helpfulLinkStyles
};

function OnboardingAndTrialProgressSlideover$Step(Props) {
  var onClick = Props.onClick;
  var step = Props.step;
  var disabledOpt = Props.disabled;
  var steplessOpt = Props.stepless;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var stepless = steplessOpt !== undefined ? steplessOpt : false;
  var match = step.completed;
  return React.createElement("button", {
              key: step.title,
              className: stepStyles,
              disabled: disabled,
              onClick: onClick
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: stepIndexBubbleStyles(disabled, stepless, step.completed)
                    }, match ? React.createElement(IconCheckmark.make, {
                            size: 12,
                            color: Styles.Color.white
                          }) : (
                        stepless ? null : React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Bold",
                                color: Styles.Color.white,
                                children: String(step.index + 1 | 0)
                              })
                      )), React.createElement("div", {
                      className: stepTitleContainerStyles
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: disabled ? Styles.Color.grey50 : Styles.Color.grey80,
                          children: step.title
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Regular",
                          color: disabled ? Styles.Color.grey40 : Styles.Color.grey70,
                          children: step.subtitle
                        }))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexShrink(0.0),
                        tl: /* [] */0
                      })
                }, React.createElement(IconChevronLight.make, {
                      size: 12,
                      color: Styles.Color.grey40,
                      direction: "Right"
                    })));
}

var Step = {
  make: OnboardingAndTrialProgressSlideover$Step
};

function OnboardingAndTrialProgressSlideover$Onboarding(Props) {
  var schema = Props.schema;
  var setDetailsView = Props.setDetailsView;
  var onboardingProgress = OnboardingProgressHooks.useOnboardingProgress(schema);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: headerSectionStyles
                }, React.createElement($$Text.make, {
                      element: "H1",
                      size: "Large",
                      weight: "Semi",
                      children: "Getting Started with Avo"
                    }), React.createElement(Spacer.make, {
                      height: 4
                    }), React.createElement($$Text.make, {
                      element: "Span",
                      size: "Medium",
                      weight: "Regular",
                      color: Styles.Color.grey70,
                      children: null
                    }, "Here are some steps to help you on your journey toward data freedom. If you have any questions please ", React.createElement("a", {
                          className: Curry._1(Css.style, {
                                hd: Css.color(Styles.Color.blue),
                                tl: {
                                  hd: Css.textDecoration("none"),
                                  tl: {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: {
                                      hd: Css.hover({
                                            hd: Css.color(Styles.Color.blueSecondary),
                                            tl: /* [] */0
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }),
                          href: "mailto:support@avo.app",
                          rel: "noopener",
                          target: "_blank",
                          onClick: (function ($$event) {
                              if (Intercom.isAvailable(undefined)) {
                                $$event.preventDefault();
                                return Intercom.showNewMessage("");
                              }
                              
                            })
                        }, "reach out"), ".")), React.createElement("div", {
                  className: contentSectionStyles
                }, typeof onboardingProgress === "object" ? React.createElement("div", {
                        className: stepsContainerStyles
                      }, Belt_Array.mapU(onboardingProgress.VAL[0], (function (step) {
                              return React.createElement(OnboardingAndTrialProgressSlideover$Step, {
                                          onClick: (function (param) {
                                              return Curry._1(setDetailsView, (function (param) {
                                                            return {
                                                                    NAME: "Onboarding",
                                                                    VAL: step
                                                                  };
                                                          }));
                                            }),
                                          step: step
                                        });
                            }))) : (
                    onboardingProgress === "Initial" ? "Loading..." : "Error!"
                  )));
}

var Onboarding = {
  make: OnboardingAndTrialProgressSlideover$Onboarding
};

function OnboardingAndTrialProgressSlideover$Trial(Props) {
  var schema = Props.schema;
  var setDetailsView = Props.setDetailsView;
  var onClose = Props.onClose;
  var match = ViewerContext.use(undefined);
  var viewerId = match.id;
  var schemaId = WorkspaceContext.use(undefined).id;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var trialProgress = TrialProgressHooks.useTrialProgress(schema);
  var match$1 = TrialRequest.useTrialRequest(schema.id);
  var requestTrial = match$1[1];
  var trialStatus = match$1[0];
  var height = window.innerHeight;
  var handleStartTrial = function (param) {
    Curry._1(requestTrial, viewerId).then(function (param) {
          AnalyticsRe.workspaceTrialStarted(schemaId, Date.now() / 1000.0, workspace.name);
          return Promise.resolve(undefined);
        });
    
  };
  var tmp;
  switch (trialStatus) {
    case /* Expired */0 :
        tmp = "Your trial has expired";
        break;
    case /* Ongoing */1 :
        tmp = "Get to know the team plan";
        break;
    case /* Available */2 :
    case /* Unavailable */3 :
    case /* Pending */4 :
        tmp = "Team plan features";
        break;
    case /* Success */5 :
        tmp = "Trial Activated!";
        break;
    
  }
  var tmp$1;
  var exit = 0;
  switch (trialStatus) {
    case /* Expired */0 :
        tmp$1 = React.createElement(React.Fragment, undefined, "We hope you were able to try some exciting features out in your trial. ", React.createElement(Link.make, {
                  path: Router.Link.getSchemaRouteLink(undefined, "billing"),
                  onClick: (function (param) {
                      return Curry._1(onClose, undefined);
                    }),
                  children: React.createElement($$Text.make, {
                        element: "Span",
                        weight: "Medium",
                        color: Styles.Color.magenta,
                        children: "Upgrade now!"
                      })
                }));
        break;
    case /* Ongoing */1 :
        tmp$1 = "Make the most of your trial and learn about all the features available to you";
        break;
    case /* Available */2 :
    case /* Unavailable */3 :
    case /* Pending */4 :
        exit = 1;
        break;
    case /* Success */5 :
        tmp$1 = "Your trial is now active and you can now start using the full Avo experience!";
        break;
    
  }
  if (exit === 1) {
    tmp$1 = React.createElement(React.Fragment, undefined, "Get to know the team plan with no strings attached for a full 14 days. ", React.createElement(TextButton.make, {
              onClick: handleStartTrial,
              disabled: trialStatus === /* Pending */4,
              children: React.createElement($$Text.make, {
                    element: "Span",
                    weight: "Medium",
                    color: Styles.Color.magenta,
                    children: trialStatus !== 4 ? "Start your free trial now!" : "Requesting trial..."
                  })
            }));
  }
  return React.createElement(React.Fragment, undefined, React.createElement(ReactConfetti, {
                  numberOfPieces: 600,
                  width: 400,
                  height: height,
                  colors: Belt_List.toArray(Belt_List.map(Styles.Color.primeColors, Styles.Color.toString)),
                  run: trialStatus === /* Success */5,
                  recycle: false
                }), React.createElement("div", {
                  className: headerSectionStyles
                }, React.createElement($$Text.make, {
                      size: "Large",
                      weight: "Semi",
                      children: tmp
                    }), React.createElement($$Text.make, {
                      element: "Span",
                      size: "Medium",
                      weight: "Regular",
                      color: Styles.Color.grey70,
                      children: tmp$1
                    })), React.createElement(FramerMotion.AnimatePresence, {
                  children: React.createElement("div", {
                        className: contentSectionStyles
                      }, typeof trialProgress === "object" ? React.createElement("div", {
                              className: stepsContainerStyles
                            }, Belt_Array.mapU(trialProgress.VAL[0], (function (step) {
                                    return React.createElement(OnboardingAndTrialProgressSlideover$Step, {
                                                onClick: (function (param) {
                                                    return Curry._1(setDetailsView, (function (param) {
                                                                  return {
                                                                          NAME: "Trial",
                                                                          VAL: step
                                                                        };
                                                                }));
                                                  }),
                                                step: step,
                                                disabled: trialStatus !== /* Ongoing */1 && trialStatus !== /* Success */5,
                                                stepless: true
                                              });
                                  }))) : (
                          trialProgress === "Initial" ? null : "Error!"
                        ))
                }));
}

var Trial = {
  make: OnboardingAndTrialProgressSlideover$Trial
};

function OnboardingAndTrialProgressSlideover(Props) {
  var schema = Props.schema;
  var onClose = Props.onClose;
  var globalSend = Props.globalSend;
  var integrations = Props.integrations;
  var openBranches = Props.openBranches;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var match = React.useState(function () {
        
      });
  var setDetailsView = match[1];
  var detailsView = match[0];
  var prevDetailsView = Hooks.useRetained(detailsView);
  var hasDismissedOnboarding = Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_option.nullable_to_opt(schema.onboardingV2), (function (onboarding) {
              return Caml_option.nullable_to_opt(onboarding.dismissed);
            })), false);
  var checklistType = hasDismissedOnboarding ? "TrialOnboarding" : "Onboarding";
  React.useEffect((function () {
          AnalyticsRe.onboardingChecklistOpened(schemaGroup, checklistType, schemaGroup.branchId, schemaGroup.schemaId);
          return (function (param) {
                    return AnalyticsRe.onboardingChecklistClosed(schemaGroup, Belt_Option.isSome(detailsView) ? "ChecklistStepDetails" : "ChecklistOverview", checklistType, schemaGroup.branchId, schemaGroup.schemaId);
                  });
        }), []);
  var handleOnboardingDetailInteraction = function (label, step, interaction) {
    return AnalyticsRe.onboardingChecklistStepDetailsInteraction(schemaGroup, Caml_array.get(OnboardingProgressSteps.analyticsSteps, step.index), step.completed ? "Finished" : "ToDo", interaction, label, "Onboarding", schemaGroup.branchId, schemaGroup.schemaId);
  };
  var handleTrialOnboardingDetailInteraction = function (label, step, interaction) {
    return AnalyticsRe.onboardingChecklistStepDetailsInteraction(schemaGroup, Caml_array.get(TrialProgressSteps.analyticsSteps, step.index), step.completed ? "Finished" : "ToDo", interaction, label, "TrialOnboarding", schemaGroup.branchId, schemaGroup.schemaId);
  };
  var tmp;
  if (detailsView !== undefined) {
    if (detailsView.NAME === "Trial") {
      var step = detailsView.VAL;
      tmp = React.createElement(FramerMotion.motion.div, {
            animate: {
              x: 0
            },
            transition: {
              duration: 0.3
            },
            initial: {
              x: 420
            },
            exit: {
              x: 420
            },
            className: contentStyles(false),
            key: "stepDetails",
            children: null
          }, React.createElement(DidMount.make, {
                didMount: (function (param) {
                    return AnalyticsRe.onboardingChecklistStepDetailsOpened(schemaGroup, Caml_array.get(TrialProgressSteps.analyticsSteps, step.index), step.completed ? "Finished" : "ToDo", "TrialOnboarding", schemaGroup.branchId, schemaGroup.schemaId);
                  })
              }), React.createElement("div", {
                className: headerSectionStyles
              }, React.createElement("div", {
                    className: detailsViewHeader
                  }, React.createElement("button", {
                        className: Curry._1(Css.merge, {
                              hd: Curry._1(Css.style, Styles.buttonReset),
                              tl: {
                                hd: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.marginLeft(Css.px(-8)),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        onClick: (function (param) {
                            Curry._1(setDetailsView, (function (param) {
                                    
                                  }));
                            return handleTrialOnboardingDetailInteraction("X", step, "ClickBack");
                          })
                      }, React.createElement(IconChevronLight.make, {
                            direction: "Left"
                          })), React.createElement($$Text.make, {
                        size: "Large",
                        weight: "Semi",
                        color: Styles.Color.blue,
                        children: "Step " + String(step.index + 1 | 0)
                      }), React.createElement("button", {
                        className: Curry._1(Css.merge, {
                              hd: Curry._1(Css.style, Styles.buttonReset),
                              tl: {
                                hd: Curry._1(Css.style, {
                                      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey70),
                                      tl: {
                                        hd: Css.height(Css.px(16)),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        onClick: (function (param) {
                            return Curry._1(onClose, undefined);
                          })
                      }, React.createElement(IconX.make, {})))), React.createElement("div", {
                className: scrollableSectionStyles
              }, Belt_Array.getExn(TrialProgressSteps.stepsContent((function (param) {
                          return Curry._1(onClose, undefined);
                        }), (function (param) {
                          return function (param$1) {
                            return handleTrialOnboardingDetailInteraction(param, step, param$1);
                          };
                        }), globalSend, integrations), step.index)), React.createElement("a", {
                className: contactSupportBottomStyles,
                href: "mailto:support@avo.sh",
                onClick: (function ($$event) {
                    if (Intercom.isAvailable(undefined)) {
                      $$event.preventDefault();
                      return Intercom.showNewMessage("");
                    }
                    
                  })
              }, React.createElement(Icons.Lifesaver.make, {}), React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    children: "Contact support"
                  }), React.createElement(IconChevronLight.make, {
                    size: 12,
                    color: Styles.Color.grey40,
                    direction: "Right"
                  })));
    } else {
      var step$1 = detailsView.VAL;
      tmp = React.createElement(FramerMotion.motion.div, {
            animate: {
              x: 0
            },
            transition: {
              duration: 0.3
            },
            initial: {
              x: 420
            },
            exit: {
              x: 420
            },
            className: contentStyles(false),
            key: "stepDetails",
            children: null
          }, React.createElement(DidMount.make, {
                didMount: (function (param) {
                    return AnalyticsRe.onboardingChecklistStepDetailsOpened(schemaGroup, Caml_array.get(OnboardingProgressSteps.analyticsSteps, step$1.index), step$1.completed ? "Finished" : "ToDo", "Onboarding", schemaGroup.branchId, schemaGroup.schemaId);
                  })
              }), React.createElement("div", {
                className: headerSectionStyles
              }, React.createElement("div", {
                    className: detailsViewHeader
                  }, React.createElement("button", {
                        className: Curry._1(Css.merge, {
                              hd: Curry._1(Css.style, Styles.buttonReset),
                              tl: {
                                hd: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.marginLeft(Css.px(-8)),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        onClick: (function (param) {
                            Curry._1(setDetailsView, (function (param) {
                                    
                                  }));
                            return handleOnboardingDetailInteraction("X", step$1, "ClickBack");
                          })
                      }, React.createElement(IconChevronLight.make, {
                            direction: "Left"
                          })), React.createElement($$Text.make, {
                        size: "Large",
                        weight: "Semi",
                        color: Styles.Color.blue,
                        children: "Step " + String(step$1.index + 1 | 0)
                      }), React.createElement("button", {
                        className: Curry._1(Css.merge, {
                              hd: Curry._1(Css.style, Styles.buttonReset),
                              tl: {
                                hd: Curry._1(Css.style, {
                                      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey70),
                                      tl: {
                                        hd: Css.height(Css.px(16)),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        onClick: (function (param) {
                            return Curry._1(onClose, undefined);
                          })
                      }, React.createElement(IconX.make, {})))), React.createElement("div", {
                className: scrollableSectionStyles
              }, Belt_Array.getExn(OnboardingProgressSteps.stepsContent((function (param) {
                          return Curry._1(onClose, undefined);
                        }), globalSend, openBranches, (function (param) {
                          return function (param$1) {
                            return handleOnboardingDetailInteraction(param, step$1, param$1);
                          };
                        })), step$1.index)), React.createElement("a", {
                className: contactSupportBottomStyles,
                href: "mailto:support@avo.sh",
                onClick: (function ($$event) {
                    if (Intercom.isAvailable(undefined)) {
                      $$event.preventDefault();
                      return Intercom.showNewMessage("");
                    }
                    
                  })
              }, React.createElement(Icons.Lifesaver.make, {}), React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    children: "Contact support"
                  }), React.createElement(IconChevronLight.make, {
                    size: 12,
                    color: Styles.Color.grey40,
                    direction: "Right"
                  })));
    }
  } else {
    tmp = React.createElement(FramerMotion.motion.div, {
          animate: {
            x: 0
          },
          transition: {
            duration: 0.3
          },
          initial: {
            x: Belt_Option.isSome(prevDetailsView) ? -420 : 0
          },
          exit: {
            x: -420
          },
          className: contentStyles(true),
          key: "steps",
          children: null
        }, React.createElement("button", {
              className: closeButtonStyles,
              onClick: (function (param) {
                  return Curry._1(onClose, undefined);
                })
            }, React.createElement(IconX.make, {})), hasDismissedOnboarding ? null : React.createElement(OnboardingAndTrialProgressSlideover$Onboarding, {
                schema: schema,
                setDetailsView: setDetailsView
              }), React.createElement(OnboardingAndTrialProgressSlideover$Trial, {
              schema: schema,
              setDetailsView: setDetailsView,
              onClose: onClose
            }), React.createElement(Spacer.make, {
              height: 16,
              grow: 1.0
            }), React.createElement("div", {
              className: footerSectionStyles
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  children: "HELPFUL LINKS"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("a", {
                  className: helpfulLinkStyles,
                  href: "https://www.avo.app/docs/",
                  rel: "noopener",
                  target: "_blank"
                }, React.createElement(Icons.Lifesaver.make, {}), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      children: "Read the Avo Docs"
                    }), React.createElement(IconChevronLight.make, {
                      size: 12,
                      color: Styles.Color.grey40,
                      direction: "Right"
                    })), React.createElement("a", {
                  className: helpfulLinkStyles,
                  href: "https://www.avo.app/docs/workspace/inspector",
                  rel: "noopener",
                  target: "_blank"
                }, React.createElement(Icons.MagnifyingGlass.make, {}), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      children: "Learn about Inspector"
                    }), React.createElement(IconChevronLight.make, {
                      size: 12,
                      color: Styles.Color.grey40,
                      direction: "Right"
                    })), React.createElement("a", {
                  className: helpfulLinkStyles,
                  href: "https://www.avo.app/docs/implementation/start-using-avo-functions",
                  rel: "noopener",
                  target: "_blank"
                }, React.createElement(Icons.Code.make, {}), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      children: "What is Avo Codegen?"
                    }), React.createElement(IconChevronLight.make, {
                      size: 12,
                      color: Styles.Color.grey40,
                      direction: "Right"
                    }))));
  }
  return React.createElement("div", {
              className: containerStyles
            }, React.createElement(FramerMotion.AnimatePresence, {
                  children: tmp
                }));
}

var make = OnboardingAndTrialProgressSlideover;

exports.Style = Style;
exports.Step = Step;
exports.Onboarding = Onboarding;
exports.Trial = Trial;
exports.make = make;
/* containerStyles Not a pure module */
