// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var QuillDelta = require("quill-delta");
var QuillWithToolbar = require("./QuillWithToolbar.bs.js");
var OpsWithDeltaState = require("./OpsWithDeltaState.bs.js");

var wrapper = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: {
        hd: Css.paddingRight(Css.px(12)),
        tl: {
          hd: Css.flexShrink(1.0),
          tl: {
            hd: Css.overflowX("scroll"),
            tl: {
              hd: Css.paddingBottom(Css.px(12)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var editorStyles = Curry._1(Css.style, {
      hd: Css.selector(" div.ql-editor", {
            hd: Css.padding(Css.px(0)),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.selector(" div.ql-toolbar", {
              hd: Css.marginLeft(Css.px(-4)),
              tl: {
                hd: Css.padding(Css.px(0)),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var S = {
  wrapper: wrapper,
  editorStyles: editorStyles
};

function TriggerModalDescription(Props) {
  var $$event = Props.event;
  var description = Props.description;
  var onUpdate = Props.onUpdate;
  var triggerId = Props.triggerId;
  var schemaGroup = Props.schemaGroup;
  var viewerCanEdit = Props.viewerCanEdit;
  return React.createElement("div", {
              className: wrapper
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.grey70,
                  children: "Trigger Description"
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement("div", {
                  className: editorStyles
                }, React.createElement(OpsWithDeltaState.make, {
                      value: new QuillDelta(JSON.parse(description)),
                      onFlush: (function (description) {
                          return Curry._1(onUpdate, JSON.stringify(description));
                        }),
                      onChangeCompleted: (function (param, param$1) {
                          return AnalyticsRe.eventTriggerConfigured(schemaGroup, $$event.id, $$event.name, "UpdateDescription", "Description", $$event.triggers.length, schemaGroup.branchId, schemaGroup.schemaId);
                        }),
                      children: (function (value, onChange, onBlur, param) {
                          return React.createElement(QuillWithToolbar.make, {
                                      value: value,
                                      onChange: onChange,
                                      id: "trigger-" + triggerId,
                                      placeholder: "Trigger description...",
                                      onBlur: (function (param) {
                                          return Curry._1(onBlur, value);
                                        }),
                                      scrollOnFocus: false,
                                      readOnly: !viewerCanEdit,
                                      key: triggerId
                                    });
                        })
                    })));
}

var make = TriggerModalDescription;

exports.S = S;
exports.make = make;
/* wrapper Not a pure module */
