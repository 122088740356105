// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Select = require("../Select.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var Tooltip = require("../Tooltip.bs.js");
var AvoUtils = require("../../../shared/utils/AvoUtils.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Markdown = require("../Markdown.bs.js");
var Tooltips = require("../markdown/tooltips/tooltips.bs.js");
var AvoLimits = require("../AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var NamedBranch = require("../NamedBranch.bs.js");
var AnalyticsUtils = require("../analyticsUtils.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var WorkspaceContext = require("../WorkspaceContext.bs.js");
var GlobalSendContext = require("../GlobalSendContext.bs.js");
var TrackingPlanModel = require("../../../model/src/TrackingPlanModel.bs.js");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");
var PropertyAbsenceViewHelpers = require("../PropertyAbsenceViewHelpers.bs.js");

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding4(Css.px(12), Css.px(16), Css.px(12), Css.px(16)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.grey20),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                tl: {
                  hd: Css.borderTopRightRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.borderTopLeftRadius(Styles.Border.radius),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function tableBoxStyles(useBottomRadius) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.pct(100.0)),
              tl: {
                hd: Css.overflow("auto"),
                tl: {
                  hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                  tl: {
                    hd: Css.borderRight(Css.px(1), "solid", Styles.Color.grey30),
                    tl: {
                      hd: Css.borderBottomLeftRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                      tl: {
                        hd: Css.borderBottomRightRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function tableStyles(useBottomRadius) {
  return Curry._1(Css.style, {
              hd: Css.borderSpacing(Css.px(0)),
              tl: {
                hd: Css.minWidth(Css.pct(100.0)),
                tl: {
                  hd: Css.selector(" tbody tr:last-child td:first-child", {
                        hd: Css.borderBottomLeftRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.selector(" tbody tr:last-child td:last-child", {
                          hd: Css.borderBottomRightRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var tableHeaderCellStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(8), Css.px(16), Css.px(8), Css.px(16)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.grey20),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.grey30),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
            tl: {
              hd: Css.selector(":first-child", {
                    hd: Css.position("sticky"),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: /* [] */0
                    }
                  }),
              tl: {
                hd: Css.selector(":last-child", {
                      hd: Css.borderRightWidth(Css.px(0)),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.selector(":not(:first-child)", {
                        hd: Css.textAlign("center"),
                        tl: {
                          hd: Css.verticalAlign("middle"),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var tableNameCellStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(8), Css.px(16), Css.px(8), Css.px(16)),
      tl: {
        hd: Css.borderLeftWidth(Css.px(0)),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.grey30),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
            tl: {
              hd: Css.position("sticky"),
              tl: {
                hd: Css.left(Css.px(0)),
                tl: {
                  hd: Css.background(Styles.Color.white),
                  tl: {
                    hd: Css.zIndex(1),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var tableSelectCellStyles = Curry._1(Css.style, {
      hd: Css.selector(":last-child", {
            hd: Css.borderRightWidth(Css.px(0)),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.padding(Css.px(4)),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.grey30),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
            tl: {
              hd: Css.textAlign("center"),
              tl: {
                hd: Css.verticalAlign("middle"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function SystemPropertySources(Props) {
  var model = Props.model;
  var sources = Props.sources;
  var viewerCanEdit = Props.viewerCanEdit;
  var property = Props.property;
  var sendActions = Props.sendActions;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var globalSend = GlobalSendContext.use(undefined);
  var match = AvoLimits.PropertyAbsenceByEventAndSource.computeAvailability(workspace);
  var available = match === "AvailableDuringTrial" || match === "Available";
  var match$1 = React.useState(function () {
        return PropertyAbsenceViewHelpers.getDefaultModeForAbsenceSystemProp(sources, property.absence);
      });
  var setMode = match$1[1];
  var mode = match$1[0];
  var ParseMode = /* @__PURE__ */Caml_exceptions.create("ParseMode");
  var parseModeString = function (mode) {
    switch (mode) {
      case "Always sent" :
          return /* Always */0;
      case "Depending on source" :
          return /* BySource */2;
      case "Sometimes sent" :
          return /* Sometimes */1;
      default:
        throw {
              RE_EXN_ID: ParseMode,
              Error: new Error()
            };
    }
  };
  var tmp;
  if (mode >= 2) {
    var absenceMap = PropertyAbsenceViewHelpers.getFullSysAbsenceMap(sources, property.absence);
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: tableBoxStyles(true)
            }, React.createElement("table", {
                  className: tableStyles(true)
                }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("td", {
                              className: tableHeaderCellStyles
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  color: Styles.Color.grey80,
                                  children: "Source Name"
                                })), React.createElement("td", {
                              className: tableHeaderCellStyles
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  color: Styles.Color.grey80,
                                  children: "Presence"
                                })))), React.createElement("tbody", undefined, Belt_MapString.valuesToArray(Belt_MapString.mapWithKey(absenceMap, (function (sourceId, state) {
                                return React.createElement("tr", undefined, React.createElement("td", {
                                                className: tableNameCellStyles
                                              }, React.createElement($$Text.make, {
                                                    size: "Small",
                                                    weight: "Semi",
                                                    children: Belt_Option.mapWithDefault(Belt_List.getBy(sources, (function (source) {
                                                                return source.id === sourceId;
                                                              })), "Untitled", (function (source) {
                                                            return Belt_Option.getWithDefault(source.name, "Untitled");
                                                          }))
                                                  })), React.createElement("td", {
                                                className: tableSelectCellStyles
                                              }, React.createElement(Select.make, {
                                                    disabled: state === /* Unavailable */0 || !available,
                                                    onSelect: (function (newState) {
                                                        var state = PropertyAbsenceViewHelpers.parseEventAndSourceStateString(newState);
                                                        return Curry.app(sendActions, [
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    (function (branch) {
                                                                        var match = property.absence;
                                                                        var tmp;
                                                                        tmp = match !== undefined && typeof match !== "number" ? (
                                                                            match.TAG === /* SometimesSent */0 ? "SometimesSent" : "DependingOnSource"
                                                                          ) : "AlwaysSent";
                                                                        return AnalyticsRe.propertyConfigured(AvoUtils.getAnalyticsPropertyGroup(model, property), AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.propertyConfiguration("Presence", "UpdatePropertyPresenceByEventAndSource", undefined, tmp, undefined, "DependingOnSource", undefined, undefined, undefined, undefined, undefined), NamedBranch.getId(branch), schemaGroup.schemaId);
                                                                      }),
                                                                    undefined,
                                                                    [[
                                                                        {
                                                                          NAME: "UpdatePropertyAbsence",
                                                                          VAL: [
                                                                            property.id,
                                                                            {
                                                                              TAG: /* EventSource */2,
                                                                              _0: TrackingPlanModel.propertyAbsenceAllEventsKey,
                                                                              _1: sourceId
                                                                            },
                                                                            state !== 2 ? (
                                                                                state >= 3 ? undefined : /* NeverSent */0
                                                                              ) : /* SometimesSent */({
                                                                                  _0: ""
                                                                                })
                                                                          ]
                                                                        },
                                                                        {
                                                                          eventId: TrackingPlanModel.propertyAbsenceAllEventsKey,
                                                                          eventQuery: TrackingPlanModel.propertyAbsenceAllEventsKey,
                                                                          propertyId: property.id,
                                                                          propertyQuery: property.id
                                                                        }
                                                                      ]]
                                                                  ]);
                                                      }),
                                                    options: Belt_List.map(state === /* Unavailable */0 ? ({
                                                              hd: /* Unavailable */0,
                                                              tl: /* [] */0
                                                            }) : ({
                                                              hd: /* Never */1,
                                                              tl: {
                                                                hd: /* Sometimes */2,
                                                                tl: {
                                                                  hd: /* Always */3,
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }), (function (mode) {
                                                            return [
                                                                    {
                                                                      NAME: "Label",
                                                                      VAL: PropertyAbsenceViewHelpers.getEventAndSourceStateString(mode)
                                                                    },
                                                                    PropertyAbsenceViewHelpers.getEventAndSourceStateString(mode)
                                                                  ];
                                                          })),
                                                    value: PropertyAbsenceViewHelpers.getEventAndSourceStateString(state)
                                                  })));
                              })))))));
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: headerStyles
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("row"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          element: "H1",
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.grey80,
                          children: "Sent with all events"
                        }), React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement(Tooltip.WithAnalytics.make, {
                          fromItem: {
                            itemId: property.id,
                            itemName: property.name,
                            itemType: "Property"
                          },
                          title: "System Properties",
                          children: React.createElement(Tooltip.Content.make, {
                                children: React.createElement(Markdown.make, {
                                      source: Tooltips.systemProperties
                                    })
                              })
                        })), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement(Select.make, {
                      disabled: !viewerCanEdit,
                      onSelect: (function (modeString) {
                          var newMode = parseModeString(modeString);
                          if (!available && newMode === /* BySource */2) {
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "ConfigureEventsPerPlatform"
                                        }
                                      });
                          }
                          var absenceDefaultMode = PropertyAbsenceViewHelpers.getDefaultModeForAbsenceSystemProp(sources, property.absence);
                          var confirmationCopy = "Switching to " + (PropertyAbsenceViewHelpers.getModeString(newMode) + " will cause your config to change. Are you sure you want to proceed?");
                          var systemPropsAbsencesMap = PropertyAbsenceViewHelpers.getFullSysAbsenceMap(sources, property.absence);
                          var showConfirm = !PropertyAbsenceViewHelpers.sourcesEmptyOrEverythingSometimes(systemPropsAbsencesMap) && PropertyAbsenceViewHelpers.getMoreComplex(absenceDefaultMode, newMode) !== newMode;
                          var shouldProceed = showConfirm ? window.confirm(confirmationCopy) : true;
                          if (!shouldProceed) {
                            return ;
                          }
                          var exit = 0;
                          switch (mode) {
                            case /* Always */0 :
                                if (newMode === 1) {
                                  exit = 1;
                                }
                                break;
                            case /* Sometimes */1 :
                                if (newMode === 0) {
                                  exit = 2;
                                }
                                break;
                            case /* BySource */2 :
                                switch (newMode) {
                                  case /* Always */0 :
                                      if (!Caml_obj.caml_equal(property.absence, /* AlwaysSent */0)) {
                                        exit = 2;
                                      }
                                      break;
                                  case /* Sometimes */1 :
                                      var match = property.absence;
                                      var tmp;
                                      tmp = match !== undefined && !(typeof match === "number" || match.TAG !== /* SometimesSent */0) ? true : false;
                                      if (!tmp) {
                                        exit = 1;
                                      }
                                      break;
                                  case /* BySource */2 :
                                      break;
                                  
                                }
                                break;
                            
                          }
                          switch (exit) {
                            case 1 :
                                var id = property.id;
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          return AnalyticsRe.propertyConfigured(AvoUtils.getAnalyticsPropertyGroup(model, property), AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.propertyConfiguration("Presence", "UpdatePropertyPresenceGlobally", undefined, mode === /* Always */0 ? "AlwaysSent" : "DependingOnSource", undefined, "SometimesSent", undefined, undefined, undefined, undefined, undefined), NamedBranch.getId(branch), schemaGroup.schemaId);
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdatePropertyAbsence",
                                            VAL: [
                                              id,
                                              {
                                                TAG: /* Event */0,
                                                _0: TrackingPlanModel.propertyAbsenceAllEventsKey
                                              },
                                              /* SometimesSent */{
                                                _0: ""
                                              }
                                            ]
                                          },
                                          {
                                            propertyId: id,
                                            propertyQuery: id
                                          }
                                        ]]
                                    ]);
                                break;
                            case 2 :
                                var id$1 = property.id;
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          return AnalyticsRe.propertyConfigured(AvoUtils.getAnalyticsPropertyGroup(model, property), AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.propertyConfiguration("Presence", "UpdatePropertyPresenceGlobally", undefined, mode === /* Sometimes */1 ? "SometimesSent" : "DependingOnSource", undefined, "AlwaysSent", undefined, undefined, undefined, undefined, undefined), NamedBranch.getId(branch), schemaGroup.schemaId);
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdatePropertyAbsence",
                                            VAL: [
                                              id$1,
                                              {
                                                TAG: /* Event */0,
                                                _0: TrackingPlanModel.propertyAbsenceAllEventsKey
                                              },
                                              undefined
                                            ]
                                          },
                                          {
                                            propertyId: id$1,
                                            propertyQuery: id$1
                                          }
                                        ]]
                                    ]);
                                break;
                            
                          }
                          return Curry._1(setMode, (function (param) {
                                        return newMode;
                                      }));
                        }),
                      options: Belt_List.map(PropertyAbsenceViewHelpers.modes, (function (mode) {
                              return [
                                      {
                                        NAME: "Label",
                                        VAL: available || mode < 2 ? PropertyAbsenceViewHelpers.getModeString(mode) : "⚡️ " + PropertyAbsenceViewHelpers.getModeString(/* BySource */2)
                                      },
                                      PropertyAbsenceViewHelpers.getModeString(mode)
                                    ];
                            })),
                      value: PropertyAbsenceViewHelpers.getModeString(mode)
                    })), tmp);
}

var make = SystemPropertySources;

exports.headerStyles = headerStyles;
exports.tableBoxStyles = tableBoxStyles;
exports.tableStyles = tableStyles;
exports.tableHeaderCellStyles = tableHeaderCellStyles;
exports.tableNameCellStyles = tableNameCellStyles;
exports.tableSelectCellStyles = tableSelectCellStyles;
exports.make = make;
/* headerStyles Not a pure module */
