// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("./Icons.bs.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function button(direction) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.width(Css.px(24)),
                      tl: {
                        hd: Css.height(Css.px(24)),
                        tl: {
                          hd: Css.minWidth(Css.px(24)),
                          tl: {
                            hd: Css.minHeight(Css.px(24)),
                            tl: {
                              hd: Css.border(Css.px(1), "solid", Styles.Color.grey40),
                              tl: {
                                hd: Css.borderRadius(Css.pct(50)),
                                tl: {
                                  hd: Css.padding(Css.px(0)),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.transform({
                                                NAME: "rotate",
                                                VAL: Css.deg(direction === /* Left */0 ? 0.0 : 180.0)
                                              }),
                                          tl: {
                                            hd: Css.selector(" path", {
                                                  hd: Css_Legacy_Core.SVG.fill(Styles.Color.magenta),
                                                  tl: /* [] */0
                                                }),
                                            tl: {
                                              hd: Css.selector(":hover:not([disabled])", {
                                                    hd: Css.selector(" path", {
                                                          hd: Css_Legacy_Core.SVG.fill(Styles.Color.white),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: {
                                                      hd: Css.backgroundColor(Styles.Color.magenta),
                                                      tl: {
                                                        hd: Css.borderColor(Styles.Color.magenta),
                                                        tl: {
                                                          hd: Css.color(Styles.Color.white),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: {
                                                hd: Css.disabled({
                                                      hd: Css.color(Styles.Color.grey40),
                                                      tl: {
                                                        hd: Css.cursor("default"),
                                                        tl: {
                                                          hd: Css.borderColor(Styles.Color.grey20),
                                                          tl: {
                                                            hd: Css.selector(" path", {
                                                                  hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey40),
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }),
                                                tl: {
                                                  hd: Css.transition({
                                                        NAME: "ms",
                                                        VAL: Styles.Duration.$$default
                                                      }, undefined, undefined, "all"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var S = {
  button: button
};

function TriggerModalNavigation$Arrow$DefaultButton(Props) {
  var onClick = Props.onClick;
  var disabled = Props.disabled;
  var direction = Props.direction;
  return React.createElement("button", {
              className: button(direction),
              disabled: disabled,
              onClick: onClick
            }, React.createElement(Icons.ArrowLeft.make, {
                  size: 12
                }));
}

var DefaultButton = {
  make: TriggerModalNavigation$Arrow$DefaultButton
};

function TriggerModalNavigation$Arrow$Left(Props) {
  var onClick = Props.onClick;
  var disabled = Props.disabled;
  return React.createElement(TriggerModalNavigation$Arrow$DefaultButton, {
              onClick: onClick,
              disabled: disabled,
              direction: /* Left */0
            });
}

var Left = {
  make: TriggerModalNavigation$Arrow$Left
};

function TriggerModalNavigation$Arrow$Right(Props) {
  var onClick = Props.onClick;
  var disabled = Props.disabled;
  return React.createElement(TriggerModalNavigation$Arrow$DefaultButton, {
              onClick: onClick,
              disabled: disabled,
              direction: /* Right */1
            });
}

var Right = {
  make: TriggerModalNavigation$Arrow$Right
};

var Arrow = {
  S: S,
  DefaultButton: DefaultButton,
  Left: Left,
  Right: Right
};

var wrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingTop(Css.px(14)),
          tl: {
            hd: Css.paddingRight(Css.px(18)),
            tl: {
              hd: Css.paddingBottom(Css.px(13)),
              tl: {
                hd: Css.paddingLeft(Css.px(18)),
                tl: {
                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var infoWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.width(Css.pct(100)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var eventName = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.grey30),
      tl: {
        hd: Css.borderRadius(Css.px(16)),
        tl: {
          hd: Css.padding2(Css.px(4), Css.px(8)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: {
                hd: Css.alignItems("center"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var S$1 = {
  wrapper: wrapper,
  infoWrapper: infoWrapper,
  eventName: eventName
};

function TriggerModalNavigation(Props) {
  var currentIndex = Props.currentIndex;
  var draftsToGo = Props.draftsToGo;
  var eventName$1 = Props.eventName;
  var inDraftMode = Props.inDraftMode;
  var onNavigate = Props.onNavigate;
  var total = Props.total;
  return React.createElement("span", {
              className: wrapper
            }, inDraftMode ? React.createElement("span", {
                    className: infoWrapper
                  }, React.createElement($$Text.make, {
                        size: "Large",
                        weight: "Semi",
                        children: "New trigger" + (
                          draftsToGo > 0 ? " (" + String(draftsToGo) + " more)" : ""
                        )
                      }), React.createElement(Spacer.make, {
                        height: 4
                      }), React.createElement("div", {
                        className: eventName
                      }, React.createElement($$Text.make, {
                            size: "Tiny",
                            weight: "Medium",
                            singleLine: true,
                            color: Styles.Color.grey70,
                            children: eventName$1
                          }))) : React.createElement(React.Fragment, undefined, React.createElement(TriggerModalNavigation$Arrow$Left, {
                        onClick: (function (param) {
                            return Curry._1(onNavigate, currentIndex - 1 | 0);
                          }),
                        disabled: currentIndex === 0
                      }), React.createElement(Spacer.make, {
                        width: 4,
                        grow: 1.0
                      }), React.createElement("span", {
                        className: infoWrapper
                      }, React.createElement($$Text.make, {
                            size: "Large",
                            weight: "Semi",
                            children: "Trigger " + (String(currentIndex + 1 | 0) + (" of " + String(total)))
                          }), React.createElement(Spacer.make, {
                            height: 4
                          }), React.createElement("span", {
                            className: eventName
                          }, React.createElement($$Text.make, {
                                element: "Span",
                                size: "Tiny",
                                weight: "Semi",
                                color: Styles.Color.grey70,
                                children: eventName$1
                              }))), React.createElement(Spacer.make, {
                        width: 4,
                        grow: 1.0
                      }), React.createElement(TriggerModalNavigation$Arrow$Right, {
                        onClick: (function (param) {
                            return Curry._1(onNavigate, currentIndex + 1 | 0);
                          }),
                        disabled: currentIndex === (total - 1 | 0)
                      })));
}

var make = TriggerModalNavigation;

exports.Arrow = Arrow;
exports.S = S$1;
exports.make = make;
/* wrapper Not a pure module */
