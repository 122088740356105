// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("../Hooks.bs.js");
var React = require("react");
var AvoUtils = require("../../../shared/utils/AvoUtils.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var InspectorViewApi = require("./InspectorViewApi.bs.js");

var context = React.createContext([
      /* Initial */0,
      (function (_status) {
          
        })
    ]);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var ContextProvider = {
  makeProps: makeProps,
  make: make
};

function InspectorStats$Context(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return /* Initial */0;
      });
  return React.createElement(make, makeProps([
                  match[0],
                  match[1]
                ], children, undefined));
}

var Context = {
  make: InspectorStats$Context
};

function useStatsWithCache(schemaId) {
  var match = React.useContext(context);
  var setStats = match[1];
  var stats = match[0];
  var previousSchemaId = Hooks.usePrevious1(schemaId);
  var fetchStats = function (param) {
    InspectorViewApi.getStatsWithCache(schemaId).then(function (response) {
          if (typeof response !== "object") {
            return Promise.resolve(Curry._1(setStats, (function (currentStats) {
                              if (typeof currentStats === "number" && currentStats < 2) {
                                return /* Error */2;
                              } else {
                                return currentStats;
                              }
                            })));
          }
          var payload = response.VAL;
          Curry._1(setStats, (function (param) {
                  return /* Success */{
                          _0: payload
                        };
                }));
          return Promise.resolve(undefined);
        });
    
  };
  React.useEffect((function () {
          var exit = 0;
          if (typeof stats === "number" && stats !== 1) {
            fetchStats(undefined);
          } else {
            exit = 1;
          }
          if (exit === 1) {
            if (schemaId === previousSchemaId) {
              
            } else {
              fetchStats(undefined);
            }
          }
          
        }), [schemaId]);
  var interval = typeof stats === "number" || !Caml_obj.caml_notequal(stats._0, []) ? 15000 : 0;
  Hooks.useInterval(interval, fetchStats);
  return stats;
}

function useStatsWithPolling(schemaId, initialStats, param) {
  var match = React.useState(function () {
        return Belt_Option.mapWithDefault(initialStats, /* Loading */1, (function (stats) {
                      return /* Success */{
                              _0: stats
                            };
                    }));
      });
  var setStats = match[1];
  var documentVisibility = Hooks.useDocumentVisibility(undefined);
  var fetchStats = React.useCallback((function (param) {
          InspectorViewApi.getFreshStats(schemaId).then(function (response) {
                if (typeof response === "object") {
                  var payload = response.VAL;
                  Curry._1(setStats, (function (param) {
                          return /* Success */{
                                  _0: payload
                                };
                        }));
                  return Promise.resolve(undefined);
                }
                Curry._1(setStats, (function (currentStats) {
                        if (typeof currentStats === "number" && currentStats < 2) {
                          return /* Error */2;
                        } else {
                          return currentStats;
                        }
                      }));
                return Promise.resolve(undefined);
              });
          
        }), [schemaId]);
  React.useEffect((function () {
          Curry._1(fetchStats, undefined);
          
        }), [schemaId]);
  Hooks.useInterval(documentVisibility === /* Visible */0 ? 15000 : 0, fetchStats);
  return match[0];
}

function getSourceStatus(model, source, inspectorStats) {
  if (typeof inspectorStats === "number") {
    if (inspectorStats >= 2) {
      if (AvoUtils.hasInspectorSupport(model, source)) {
        return /* Available */2;
      } else {
        return /* Unavailable */1;
      }
    } else {
      return /* Loading */0;
    }
  } else if (Belt_Option.isSome(Belt_Array.getByU(inspectorStats._0, (function (param) {
                return param.sourceId === source.id;
              })))) {
    return /* Receiving */3;
  } else if (AvoUtils.hasInspectorSupport(model, source)) {
    return /* Available */2;
  } else {
    return /* Unavailable */1;
  }
}

exports.context = context;
exports.ContextProvider = ContextProvider;
exports.Context = Context;
exports.useStatsWithCache = useStatsWithCache;
exports.useStatsWithPolling = useStatsWithPolling;
exports.getSourceStatus = getSourceStatus;
/* context Not a pure module */
