// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Caml = require("rescript/lib/js/caml.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var ActionDiff = require("../../shared/ActionDiff.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var TextButton = require("./TextButton.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");

function SourceEventsModal(Props) {
  var sourceId = Props.sourceId;
  var model = Props.model;
  var onClose = Props.onClose;
  var sendActions = SendActionsContext.use(undefined);
  var events = Belt_List.mapU(StateUtils.getSourceEvents(undefined, model, sourceId), (function ($$event) {
          return $$event.id;
        }));
  var match = React.useState(function () {
        return events;
      });
  var setSelectedEvents = match[1];
  var selectedEvents = match[0];
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var workspaceLimits = AvoLimits.computeLimits(workspace, model);
  var codegenAvailability = AvoLimits.CodegenEvents.status(workspaceLimits);
  var sourcesWithCodegenSeen = WorkspaceContext.useLastSeenCodegenForSourceIds(undefined);
  var includeCodegenForAddedSource = function (sourceId) {
    if (codegenAvailability !== "Unavailable") {
      return Belt_MapString.has(sourcesWithCodegenSeen, sourceId);
    } else {
      return false;
    }
  };
  var source = StateUtils.getSourceById(sourceId, model);
  var tmp;
  if (source !== undefined) {
    var isSelectAll = Caml_obj.caml_equal(Belt_List.sort(selectedEvents, Caml.caml_string_compare), Belt_List.sort(Belt_List.mapU(model.events, (function ($$event) {
                    return $$event.id;
                  })), Caml.caml_string_compare));
    var match$1 = Belt_List.partitionU(ActionDiff.diffStringList(events, selectedEvents), (function (diff) {
            return diff.NAME !== "Remove";
          }));
    var remove = match$1[1];
    var add = match$1[0];
    var label = add ? (
        remove ? "Add " + String(Belt_List.length(add)) + " Events and Remove " + String(Belt_List.length(remove)) + " Events" : "Add " + String(Belt_List.length(add)) + " Events to " + AvoConfig.getSourceName(source)
      ) : (
        remove ? "Remove " + String(Belt_List.length(remove)) + " Events from " + AvoConfig.getSourceName(source) : "Close"
      );
    tmp = React.createElement("div", undefined, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.fontWeight(Styles.FontWeight.semi),
                    tl: {
                      hd: Css.textAlign("center"),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.large),
                        tl: /* [] */0
                      }
                    }
                  })
            }, "Events sent from " + AvoConfig.getSourceName(source)), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(5), Css.px(0)),
                    tl: {
                      hd: Css.textAlign("center"),
                      tl: /* [] */0
                    }
                  })
            }, "Which events in your tracking plan should be sent from " + AvoConfig.getSourceName(source) + "?"), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginTop(Css.px(15)),
                    tl: /* [] */0
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: {
                              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                              tl: {
                                hd: Css.width(Css.pct(100)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("input", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding2(Css.px(10), Css.px(5)),
                            tl: /* [] */0
                          }),
                      id: "all-events",
                      checked: isSelectAll,
                      type: "checkbox",
                      onChange: (function (domEvent) {
                          var isSelectAll = domEvent.target.checked;
                          return Curry._1(setSelectedEvents, (function (param) {
                                        if (isSelectAll) {
                                          return Belt_List.mapU(model.events, (function ($$event) {
                                                        return $$event.id;
                                                      }));
                                        } else {
                                          return /* [] */0;
                                        }
                                      }));
                        })
                    }), React.createElement("label", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.display("block"),
                              tl: {
                                hd: Css.padding2(Css.px(10), Css.px(15)),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.medium),
                                  tl: {
                                    hd: Css.fontWeight(Styles.FontWeight.bold),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }),
                      htmlFor: "all-events"
                    }, "Send all events in tracking plan")), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxHeight(Css.px(300)),
                        tl: {
                          hd: Css.overflow("auto"),
                          tl: /* [] */0
                        }
                      })
                }, Belt_List.toArray(Belt_List.mapU(Belt_List.sortU(model.events, (function (a, b) {
                                var a$1 = a.name;
                                var b$1 = b.name;
                                if (a$1 > b$1) {
                                  return 1;
                                } else if (a$1 < b$1) {
                                  return -1;
                                } else {
                                  return 0;
                                }
                              })), (function ($$event) {
                            var match = Belt_List.someU(events, (function (id) {
                                    return id === $$event.id;
                                  }));
                            var match$1 = Belt_List.someU(selectedEvents, (function (id) {
                                    return id === $$event.id;
                                  }));
                            return React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: {
                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                  tl: {
                                                    hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
                                                    tl: {
                                                      hd: Css.width(Css.pct(100)),
                                                      tl: match ? (
                                                          match$1 ? /* [] */0 : ({
                                                                hd: Css.color(Styles.Color.grapeError),
                                                                tl: {
                                                                  hd: Css.backgroundColor(Styles.Color.grapeErrorBg),
                                                                  tl: /* [] */0
                                                                }
                                                              })
                                                        ) : (
                                                          match$1 ? ({
                                                                hd: Css.color(Styles.Color.mintGreen),
                                                                tl: {
                                                                  hd: Css.backgroundColor(Styles.Color.mintGreenBg),
                                                                  tl: /* [] */0
                                                                }
                                                              }) : /* [] */0
                                                        )
                                                    }
                                                  }
                                                }
                                              }
                                            })
                                      }, React.createElement("input", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.padding2(Css.px(10), Css.px(5)),
                                                  tl: /* [] */0
                                                }),
                                            id: "event-" + $$event.id,
                                            checked: Belt_List.some(selectedEvents, (function (id) {
                                                    return id === $$event.id;
                                                  })),
                                            type: "checkbox",
                                            onChange: (function (param) {
                                                return Curry._1(setSelectedEvents, (function (events) {
                                                              if (Belt_List.someU(events, (function (id) {
                                                                        return id === $$event.id;
                                                                      }))) {
                                                                return Belt_List.keepU(selectedEvents, (function (id) {
                                                                              return id !== $$event.id;
                                                                            }));
                                                              } else {
                                                                return Belt_List.concat(selectedEvents, {
                                                                            hd: $$event.id,
                                                                            tl: /* [] */0
                                                                          });
                                                              }
                                                            }));
                                              })
                                          }), React.createElement("label", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.width(Css.pct(100)),
                                                  tl: {
                                                    hd: Css.display("block"),
                                                    tl: {
                                                      hd: Css.padding2(Css.px(10), Css.px(15)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }),
                                            htmlFor: "event-" + $$event.id
                                          }, $$event.name));
                          }))))), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.marginTop(Css.px(16)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(Button.make, {
                  label: label,
                  onClick: (function (param) {
                      var diff = Belt_List.concat(add, remove);
                      if (diff) {
                        var actions = Belt_List.toArray(Belt_List.map(diff, (function (diff) {
                                    if (diff.NAME === "Remove") {
                                      var eventId = diff.VAL;
                                      return [
                                              {
                                                NAME: "ExcludeEventFromSourceV2",
                                                VAL: [
                                                  eventId,
                                                  source.id
                                                ]
                                              },
                                              {
                                                eventId: eventId,
                                                eventQuery: eventId,
                                                sourceId: source.id,
                                                sourceQuery: source.id
                                              }
                                            ];
                                    }
                                    var eventId$1 = diff.VAL;
                                    return [
                                            {
                                              NAME: "IncludeEventInSourceV2",
                                              VAL: [
                                                eventId$1,
                                                source.id,
                                                StateUtils.getSourceDestinationIds(source.destinations),
                                                includeCodegenForAddedSource(source.id)
                                              ]
                                            },
                                            {
                                              eventId: eventId$1,
                                              eventQuery: eventId$1,
                                              sourceId: source.id,
                                              sourceQuery: source.id
                                            }
                                          ];
                                  })));
                        if (window.confirm(label + "?")) {
                          Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                actions
                              ]);
                        }
                        
                      } else {
                        Curry._1(onClose, undefined);
                      }
                      return Curry._1(onClose, undefined);
                    })
                }), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement(TextButton.make, {
                  onClick: (function (param) {
                      return Curry._1(onClose, undefined);
                    }),
                  size: "Medium",
                  children: "Cancel"
                })));
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(500)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: Css.padding4(Css.px(35), Css.px(30), Css.px(20), Css.px(30)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.cursor("default"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, tmp);
}

var make = SourceEventsModal;

exports.make = make;
/* Css Not a pure module */
