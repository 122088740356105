// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconFunnel(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey70;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: {
                        hd: Css.transform(Css.scale(-1, 1)),
                        tl: /* [] */0
                      }
                    }
                  }),
              width: String(size),
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", undefined, React.createElement("rect", {
                      height: "14",
                      width: "4",
                      x: "12",
                      y: "1"
                    }), React.createElement("rect", {
                      height: "10",
                      width: "4",
                      x: "6",
                      y: "5"
                    }), React.createElement("rect", {
                      height: "6",
                      width: "4",
                      y: "9"
                    })));
}

var make = IconFunnel;

exports.make = make;
/* Css Not a pure module */
