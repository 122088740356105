// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Uuid = require("uuid");
var Curry = require("rescript/lib/js/curry.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameInput = require("./NameInput.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var DiscrepancyContext = require("./DiscrepancyContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");

function isValid(name, expectedPropertyCase, model) {
  return NameInput.isValid(NameInput.validateInput(StateUtils.getPropertyNamespace(model), name, name, expectedPropertyCase));
}

function handleSubmit(name, id, sendActions, onSuccess, addToast) {
  return Curry.app(sendActions, [
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              (function (_toBranch) {
                  Curry._1(onSuccess, undefined);
                  return Curry._1(addToast, {
                              message: "Group type \"" + name + "\" created 🎉",
                              toastType: /* Success */0,
                              action: {
                                message: "View",
                                toastCallback: (function (param) {
                                    return Router.Schema.pushDrawerItem(undefined, {
                                                NAME: "propertyGroup",
                                                VAL: [
                                                  id,
                                                  undefined
                                                ]
                                              });
                                  })
                              }
                            });
                }),
              undefined,
              [[
                  {
                    NAME: "CreateGroupType",
                    VAL: [
                      id,
                      name,
                      ""
                    ]
                  },
                  {
                    groupTypeId: name,
                    groupTypeQuery: name
                  }
                ]]
            ]);
}

function NewGroupTypeModal(Props) {
  var afterCreateCallback = Props.afterCreateCallback;
  var onClose = Props.onClose;
  var groupTypeName = Props.groupTypeName;
  var model = Props.model;
  var addToast = Toast.useAddToast(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var sendActions = SendActionsContext.use(undefined);
  var newId = React.useMemo((function () {
          return Uuid.v4();
        }), []);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(600)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(75)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(NameInput.make, {
                  name: Belt_Option.getWithDefault(groupTypeName, ""),
                  existingNames: Belt_List.fromArray(Belt_Array.map(model.groupTypes, (function (group) {
                              return group.name;
                            }))),
                  expectedCase: match.expectedPropertyCase,
                  forceCase: match.config.forcePropertyCase,
                  autoFocus: true,
                  onChange: (function (name, _namingConvention, _correctCase) {
                      return handleSubmit(name, newId, sendActions, (function (param) {
                                    Curry._1(onClose, undefined);
                                    return Belt_Option.forEach(afterCreateCallback, (function (cb) {
                                                  return Curry._2(cb, newId, name);
                                                }));
                                  }), addToast);
                    }),
                  submitLabel: "Create Group Type",
                  placeholder: "Group Type",
                  onClose: (function (param) {
                      return Curry._1(onClose, undefined);
                    }),
                  fullscreen: true,
                  itemType: "GroupType",
                  actionType: "Create"
                }));
}

var make = NewGroupTypeModal;

exports.isValid = isValid;
exports.handleSubmit = handleSubmit;
exports.make = make;
/* Css Not a pure module */
