// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Invite = require("./Invite.bs.js");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Sentry = require("./externals/Sentry.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var IconAvo = require("./IconAvo.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var DidMount = require("./DidMount.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Redirect = require("./Redirect.bs.js");
var SsoUtils = require("./SsoUtils.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var SsoChecks = require("./SsoChecks.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var FetchModel = require("./FetchModel.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var App = require("firebase/app");
var QueryString = require("query-string");
var FirebaseUtils = require("./firebaseUtils.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var IconNewWorkspace = require("./IconNewWorkspace.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var IconJoinWorkspace = require("./IconJoinWorkspace.bs.js");
var LoadingFullscreen = require("./LoadingFullscreen.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

function Welcome$AutoAcceptingInvite(Props) {
  var invite = Props.invite;
  var onAccept = Props.onAccept;
  var workspace = WorkspaceContext.use(undefined);
  var schemaGroup = AnalyticsUtils.makeSchemaGroup(workspace, "master", "main");
  React.useEffect((function () {
          Curry._2(onAccept, invite, schemaGroup);
          
        }), []);
  return React.createElement(LoadingFullscreen.make, {
              message: "Accepting invite..."
            });
}

var AutoAcceptingInvite = {
  make: Welcome$AutoAcceptingInvite
};

function Welcome$AutoAcceptingInviteWrapper(Props) {
  var invite = Props.invite;
  var onAccept = Props.onAccept;
  var fb = Firebase.app(undefined);
  var schemaState = FirebaseFetcherHooks.useSchema(fb, invite.schemaId, false);
  if (typeof schemaState === "object") {
    return React.createElement(WorkspaceContext.make, {
                schema: schemaState.VAL,
                children: React.createElement(Welcome$AutoAcceptingInvite, {
                      invite: invite,
                      onAccept: onAccept
                    })
              });
  } else if (schemaState === "NotFound") {
    return null;
  } else {
    return React.createElement(LoadingFullscreen.make, {
                message: "Loading workspace..."
              });
  }
}

var AutoAcceptingInviteWrapper = {
  make: Welcome$AutoAcceptingInviteWrapper
};

var emptyContainerStyle = Curry._1(Css.style, {
      hd: Css.width(Css.px(900)),
      tl: {
        hd: Css.maxWidth(Css.vw(100)),
        tl: {
          hd: Css.padding(Css.px(50)),
          tl: {
            hd: Css.margin2(Css.px(0), "auto"),
            tl: /* [] */0
          }
        }
      }
    });

var emptyHeaderStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: /* [] */0
        }
      }
    });

var emptyHeaderBodyStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var emptyHeaderBodyTextStyle = Curry._1(Css.style, {
      hd: Css.marginRight(Css.px(20)),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.color(Styles.Color.grey70),
          tl: /* [] */0
        }
      }
    });

var emptyBodyContainerStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.height(Css.vh(50)),
              tl: {
                hd: Css.marginTop(Css.px(50)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var emptyBodyContainerFlex = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.padding2(Css.px(25), Css.px(0)),
        tl: /* [] */0
      }
    });

var optionContainerStyle = Curry._1(Css.style, {
      hd: Css.flex({
            NAME: "num",
            VAL: 1.0
          }),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.padding2(Css.px(15), Css.px(0)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var optionTitleStyle = Curry._1(Css.style, {
      hd: Css.fontWeight(Styles.FontWeight.bold),
      tl: {
        hd: Css.fontSize(Styles.FontSize.large),
        tl: /* [] */0
      }
    });

var optionBodyStyle = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(10), Css.px(25)),
      tl: {
        hd: Css.textAlign("center"),
        tl: {
          hd: Css.fontSize(Styles.FontSize.regular),
          tl: /* [] */0
        }
      }
    });

var optionSeparatorStyle = Curry._1(Css.style, {
      hd: Css.height(Css.px(300)),
      tl: {
        hd: Css.width(Css.px(1)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.grey30),
          tl: /* [] */0
        }
      }
    });

function getSsoDefaultRole(schemaId) {
  return SsoChecks.getSsoProviderForSchemaFromFirestore(schemaId).then(function (sso) {
              return Promise.resolve(Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(sso, (function (prim) {
                                        return Caml_option.undefined_to_opt(prim.defaultRole);
                                      })), Models.Role.tFromJs), "Editor"));
            });
}

function addUserToSchema(schemaId, userId, role) {
  var batch = Firebase.app(undefined).firestore().batch();
  var schemaRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId);
  var schemaAclRef = schemaRef.collection("acls").doc(userId);
  var newUserSchemaRef = Firebase.app(undefined).firestore().collection("users").doc(userId).collection("schemas").doc(schemaId);
  batch.set(schemaAclRef, {
        id: userId,
        role: Models.Role.tToJs(role),
        type_: "user",
        createdAt: App.default.firestore.FieldValue.serverTimestamp()
      });
  batch.set(newUserSchemaRef, {
        id: schemaId,
        lastUsedAt: App.default.firestore.FieldValue.serverTimestamp()
      });
  return $$Promise.$$catch(batch.commit().then(function (param) {
                  return Promise.resolve(schemaId);
                }), (function (error) {
                console.log("addUserToSchema error", schemaId, error);
                return Promise.resolve(schemaId);
              }));
}

function Welcome(Props) {
  var userId = Props.userId;
  var auth = Firebase.getAuth(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setAcceptingInvite = match[1];
  var currentUserEmail = Belt_Option.getExn(Caml_option.nullable_to_opt(auth.currentUser)).email;
  var query = Router.getSearch(undefined).replace("?", "");
  var maybeRedirect = Belt_Option.map(Caml_option.nullable_to_opt(QueryString.parse(query).redirect), (function (param) {
          var redirectStartAt = query.indexOf("redirect=") + "redirect=".length | 0;
          return query.substring(redirectStartAt);
        }));
  var userSchemasState = FirebaseFetcherHooks.useUserSchemas(userId);
  var userSchemaInvitesState = FirebaseFetcherHooks.useUserSchemaInvites(currentUserEmail);
  var ssoSchemasState = FirebaseFetcherHooks.useSsoSchemasForDomain(SsoUtils.getEmailDomain(currentUserEmail));
  var handleAcceptInvite = function (invite, schemaGroup) {
    Curry._1(setAcceptingInvite, (function (param) {
            return true;
          }));
    $$Promise.$$catch(Invite.acceptInvite(invite, userId, schemaGroup).then(function (param) {
              Curry._1(setAcceptingInvite, (function (param) {
                      return false;
                    }));
              return Promise.resolve(undefined);
            }), (function (error) {
            console.log("Failed to accept invite", error);
            Sentry.captureException(error, {});
            return Promise.resolve(undefined);
          }));
    
  };
  var searchParams = Router.getSearch(undefined);
  var exit = 0;
  var exit$1 = 0;
  if (match[0]) {
    return React.createElement(LoadingFullscreen.make, {
                message: "Joining workspace..."
              });
  }
  if (ssoSchemasState === "Initial" || userSchemaInvitesState === "Initial") {
    exit = 2;
  } else if (typeof userSchemasState === "object") {
    var userSchemas = userSchemasState.VAL;
    var exit$2 = 0;
    var exit$3 = 0;
    if (userSchemas || !(typeof userSchemaInvitesState === "object" && userSchemaInvitesState.NAME === "Loaded" && !(userSchemaInvitesState.VAL || !(typeof ssoSchemasState === "object" && ssoSchemasState.NAME === "Loaded")))) {
      exit$3 = 6;
    } else {
      if (!ssoSchemasState.VAL) {
        return React.createElement("div", {
                    className: emptyContainerStyle
                  }, React.createElement("div", {
                        className: emptyHeaderStyle
                      }, React.createElement(IconAvo.make, {
                            size: 100
                          }), React.createElement("div", {
                            className: emptyHeaderBodyStyle
                          }, React.createElement("div", {
                                className: emptyHeaderBodyTextStyle
                              }, "Welcome " + currentUserEmail + "!"), React.createElement(Spacer.make, {
                                height: 24
                              }), React.createElement(Button.make, {
                                label: "Log out",
                                onClick: (function (param) {
                                    return FirebaseUtils.signOut(auth);
                                  })
                              }))), React.createElement("div", {
                        className: emptyBodyContainerStyle
                      }, React.createElement("h2", {
                            className: Curry._1(Css.style, {
                                  hd: Css.color(Styles.Color.grey90),
                                  tl: /* [] */0
                                })
                          }, "What would you like to do?"), React.createElement("div", {
                            className: emptyBodyContainerFlex
                          }, React.createElement("div", {
                                className: optionContainerStyle
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.margin2(Css.px(15), Css.px(0)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(IconNewWorkspace.make, {
                                        size: 72,
                                        color: Styles.Color.magenta
                                      })), React.createElement("div", {
                                    className: optionTitleStyle
                                  }, "Set Up a New Workspace"), React.createElement("p", {
                                    className: optionBodyStyle
                                  }, "Set up a new workspace to kick off your data plan, invite team members and start collaborating."), React.createElement(Spacer.make, {
                                    height: 16
                                  }), React.createElement(Button.make, {
                                    label: "Create New Workspace",
                                    onClick: (function (param) {
                                        return Router.push(undefined, "onboarding?new_workspace=true&email=" + currentUserEmail);
                                      })
                                  })), React.createElement("div", {
                                className: optionSeparatorStyle
                              }), React.createElement("div", {
                                className: optionContainerStyle
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.margin2(Css.px(15), Css.px(0)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(IconJoinWorkspace.make, {
                                        size: 72,
                                        color: Styles.Color.magenta
                                      })), React.createElement("div", {
                                    className: optionTitleStyle
                                  }, "Join Existing Workspace"), React.createElement("p", {
                                    className: optionBodyStyle
                                  }, "To start collaborating, find someone in your team that can send you an invite to the Avo workspace.")))));
      }
      exit$3 = 6;
    }
    if (exit$3 === 6) {
      if (typeof ssoSchemasState === "object" && ssoSchemasState.NAME === "Loaded") {
        var ssoSchemaIds = ssoSchemasState.VAL;
        if (Belt_List.some(ssoSchemaIds, (function (schemaId) {
                  return !Belt_List.some(userSchemas, (function (userSchema) {
                                return Models.UserSchema.getId(userSchema) === schemaId;
                              }));
                }))) {
          return React.createElement(DidMount.make, {
                      didMount: (function (param) {
                          Curry._1(setAcceptingInvite, (function (param) {
                                  return true;
                                }));
                          var ssoSchemaIdsToJoin = Belt_List.keep(ssoSchemaIds, (function (schemaId) {
                                  return !Belt_List.some(userSchemas, (function (userSchema) {
                                                return Models.UserSchema.getId(userSchema) === schemaId;
                                              }));
                                }));
                          Promise.all(Belt_Array.map(Belt_List.toArray(ssoSchemaIdsToJoin), (function (schemaId) {
                                        return getSsoDefaultRole(schemaId).then(function (defaultRole) {
                                                    return addUserToSchema(schemaId, userId, defaultRole);
                                                  });
                                      }))).then(function (param) {
                                Curry._1(setAcceptingInvite, (function (param) {
                                        return false;
                                      }));
                                return Promise.resolve(undefined);
                              });
                          
                        })
                    });
        }
        exit$2 = 5;
      } else {
        exit$2 = 5;
      }
    }
    if (exit$2 === 5) {
      if (userSchemas) {
        if (typeof userSchemaInvitesState === "object") {
          if (userSchemaInvitesState.NAME === "Loaded") {
            if (!userSchemaInvitesState.VAL) {
              return React.createElement(Redirect.make, {
                          path: Belt_Option.getWithDefault(maybeRedirect, Curry._4(Router.Link.get, {
                                    NAME: "schema",
                                    VAL: userSchemas.hd.id
                                  }, "master", "dashboard", /* [] */0) + searchParams)
                        });
            }
            exit$1 = 4;
          } else if (userSchemaInvitesState === "Error") {
            exit = 3;
          } else {
            exit$1 = 4;
          }
        } else if (userSchemaInvitesState === "Error") {
          exit = 3;
        } else {
          exit$1 = 4;
        }
      } else {
        var exit$4 = 0;
        if (typeof userSchemaInvitesState === "object" && userSchemaInvitesState.NAME === "Loaded") {
          var match$1 = userSchemaInvitesState.VAL;
          if (match$1) {
            if (!match$1.tl) {
              return React.createElement(Welcome$AutoAcceptingInviteWrapper, {
                          invite: match$1.hd,
                          onAccept: handleAcceptInvite
                        });
            }
            exit$4 = 6;
          } else {
            exit$4 = 6;
          }
        } else {
          exit$4 = 6;
        }
        if (exit$4 === 6) {
          return React.createElement(Redirect.make, {
                      path: "/schemas" + searchParams
                    });
        }
        
      }
    }
    
  } else {
    exit = userSchemasState === "Initial" ? 2 : 3;
  }
  if (exit$1 === 4) {
    exit = typeof ssoSchemasState === "object" && ssoSchemasState.NAME === "Errored" ? 3 : 1;
  }
  switch (exit) {
    case 1 :
        return React.createElement(Redirect.make, {
                    path: Belt_Option.getWithDefault(maybeRedirect, "/schemas" + searchParams)
                  });
    case 2 :
        return React.createElement(LoadingFullscreen.make, {
                    message: "Loading your workspaces..."
                  });
    case 3 :
        return React.createElement(FetchModel.$$Error.make, {
                    message: "Could not fetch your workspace"
                  });
    
  }
}

var make = Welcome;

exports.AutoAcceptingInvite = AutoAcceptingInvite;
exports.AutoAcceptingInviteWrapper = AutoAcceptingInviteWrapper;
exports.emptyContainerStyle = emptyContainerStyle;
exports.emptyHeaderStyle = emptyHeaderStyle;
exports.emptyHeaderBodyStyle = emptyHeaderBodyStyle;
exports.emptyHeaderBodyTextStyle = emptyHeaderBodyTextStyle;
exports.emptyBodyContainerStyle = emptyBodyContainerStyle;
exports.emptyBodyContainerFlex = emptyBodyContainerFlex;
exports.optionContainerStyle = optionContainerStyle;
exports.optionTitleStyle = optionTitleStyle;
exports.optionBodyStyle = optionBodyStyle;
exports.optionSeparatorStyle = optionSeparatorStyle;
exports.getSsoDefaultRole = getSsoDefaultRole;
exports.addUserToSchema = addUserToSchema;
exports.make = make;
/* emptyContainerStyle Not a pure module */
