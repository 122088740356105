// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("../Link.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var IconX = require("../IconX.bs.js");
var Toast = require("../Toast.bs.js");
var React = require("react");
var Models = require("../Models.bs.js");
var Router = require("../Router.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var AvoModel = require("../avoModel.bs.js");
var Firebase = require("../../../bs-firestore/src/Firebase.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameInput = require("../NameInput.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var StateUtils = require("../stateUtils.bs.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ContextMenu = require("../ContextMenu.bs.js");
var NamedBranch = require("../NamedBranch.bs.js");
var CommentInput = require("../CommentInput.bs.js");
var DrawerHeader = require("./DrawerHeader.bs.js");
var OpsWithState = require("../OpsWithState.bs.js");
var PropertyType = require("../PropertyType.bs.js");
var FirebaseUtils = require("../firebaseUtils.bs.js");
var PropertyInput = require("../PropertyInput.bs.js");
var ViewerContext = require("../ViewerContext.bs.js");
var AnalyticsUtils = require("../analyticsUtils.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var GlobalSendContext = require("../GlobalSendContext.bs.js");
var ObjectActivityLog = require("../ObjectActivityLog.bs.js");
var DiscrepancyContext = require("../DiscrepancyContext.bs.js");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");
var SendActionsContext = require("../SendActionsContext.bs.js");
var CopyTextToClipboard = require("copy-text-to-clipboard");
var ReactTextareaAutosize = require("react-textarea-autosize").default;

function PropertyGroupDetails(Props) {
  var currentBranch = Props.currentBranch;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var $$event = Props.event;
  var goToCommentId = Props.goToCommentId;
  var groupId = Props.groupId;
  var member = Props.member;
  var model = Props.model;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var schema = Props.schema;
  var match = ViewerContext.use(undefined);
  var userId = match.id;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match$1 = React.useContext(DiscrepancyContext.context);
  var group = Belt_List.getBy(model.propertyBundles, (function (group) {
          return group.id === groupId;
        }));
  var tmp;
  if (group !== undefined) {
    var groupPropertyGroup = AnalyticsRe.Group.propertyGroup(group.id, group.name, Belt_List.length(group.properties));
    var groupProperties = Belt_List.sort(Belt_List.keepMap(Belt_List.keepMap(model.properties, (function (property) {
                    if (Belt_List.some(group.properties, (function (propertyId) {
                              return property.TAG === /* PropertyRef */0 ? false : property._0.id === propertyId;
                            }))) {
                      return property;
                    }
                    
                  })), (function (x) {
                if (x.TAG === /* PropertyRef */0) {
                  return ;
                } else {
                  return x._0;
                }
              })), (function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            } else {
              return 0;
            }
          }));
    var groupEvents = Belt_List.keep(model.events, (function ($$event) {
            return Belt_List.some($$event.propertyBundles, (function (group) {
                          return group.id === groupId;
                        }));
          }));
    var filter = member.filter;
    var groupEvents$1 = filter !== undefined ? Belt_List.keep(groupEvents, (function ($$event) {
              return Belt_List.some($$event.tags, (function (item) {
                            return Belt_List.some(Belt_List.fromArray(filter.tags), (function (tag) {
                                          return item === tag;
                                        }));
                          }));
            })) : groupEvents;
    var tmp$1 = {
      propertyGroupId: group.id,
      propertyGroupQuery: group.id
    };
    var tmp$2 = Belt_Option.map($$event, (function ($$event) {
            return $$event.id;
          }));
    if (tmp$2 !== undefined) {
      tmp$1.eventId = Caml_option.valFromOption(tmp$2);
    }
    var context = tmp$1;
    var handleArchivePropertyBundle = function (param) {
      var match = Router.getStaticRoute(undefined);
      var newDrawerItems = Belt_List.keep(match.drawerItems, (function (item) {
              if (typeof item === "object" && item.NAME === "propertyGroup") {
                return item.VAL[0] !== group.id;
              } else {
                return true;
              }
            }));
      Router.Schema.replaceDrawerItems(newDrawerItems);
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      Curry._1(addToast, {
                            message: "The property bundle has been archived"
                          });
                      return AnalyticsRe.propertyGroupArchived(groupPropertyGroup, AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), NamedBranch.getId(branch), schemaGroup.schemaId);
                    }),
                  undefined,
                  [[
                      {
                        NAME: "Archive",
                        VAL: {
                          NAME: "PropertyGroup",
                          VAL: group.id
                        }
                      },
                      context
                    ]]
                ]);
    };
    var template = schema.onboardingTemplate;
    var tmp$3 = {
      object_: {
        NAME: "PropertyGroup",
        VAL: group
      },
      objectId: group.id,
      model: model,
      schema: schema,
      events: model.events,
      currentBranch: currentBranch,
      openBranches: openBranches
    };
    if ($$event !== undefined) {
      tmp$3.event = Caml_option.valFromOption($$event);
    }
    if (goToCommentId !== undefined) {
      tmp$3.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    var tmp$4 = {
      userId: userId,
      userExternalId: userId,
      schemaId: schema.id,
      threadId: group.id,
      events: model.events,
      itemType: "PropertyGroup",
      itemName: group.name,
      mini: true,
      currentBranch: currentBranch,
      role: role,
      schemaGroup: schemaGroup,
      location: "PropertyGroup"
    };
    var tmp$5 = Belt_Option.map($$event, (function ($$event) {
            return $$event.id;
          }));
    if (tmp$5 !== undefined) {
      tmp$4.eventId = Caml_option.valFromOption(tmp$5);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(DrawerHeader.make, {
              kind: "Property Bundle",
              options: [
                {
                  NAME: "Option",
                  VAL: {
                    label: "Copy URL",
                    onClick: (function (param) {
                        CopyTextToClipboard(Router.Link.getSimple(undefined));
                        
                      })
                  }
                },
                {
                  NAME: "WarningOption",
                  VAL: {
                    label: "Remove from all events and archive",
                    onClick: (function (param) {
                        return Curry._1(globalSend, {
                                    TAG: /* OpenModal */4,
                                    _0: {
                                      NAME: "ConfirmModal",
                                      VAL: [
                                        "Archive property bundle?",
                                        "Are you sure you want to remove the bundle from all events and archive it?",
                                        "Archive",
                                        handleArchivePropertyBundle,
                                        (function (param) {
                                            
                                          })
                                      ]
                                    }
                                  });
                      })
                  }
                }
              ],
              children: React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.height(Css.px(60)),
                            tl: {
                              hd: Css.marginLeft(Css.px(-20)),
                              tl: {
                                hd: Css.marginTop(Css.px(-19)),
                                tl: {
                                  hd: Css.marginBottom(Css.px(-19)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(NameInput.make, {
                        name: group.name,
                        existingNames: Belt_List.map(model.propertyBundles, (function (group) {
                                return group.name;
                              })),
                        expectedCase: match$1.expectedPropertyCase,
                        forceCase: match$1.config.forcePropertyCase,
                        autoFocus: false,
                        onChange: (function (groupName, param, param$1) {
                            if (groupName !== group.name) {
                              return Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (branch) {
                                              var groupPropertyGroup = AnalyticsRe.Group.propertyGroup(group.id, groupName, Belt_List.length(group.properties));
                                              return AnalyticsRe.propertyGroupUpdated(groupPropertyGroup, AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "UpdateName", group.name, undefined, NamedBranch.getId(branch), schemaGroup.schemaId);
                                            }),
                                          undefined,
                                          [[
                                              {
                                                NAME: "UpdatePropertyGroupName",
                                                VAL: [
                                                  group.id,
                                                  groupName
                                                ]
                                              },
                                              context
                                            ]]
                                        ]);
                            }
                            
                          }),
                        submitLabel: "Update Name",
                        placeholder: "Property Bundle Name",
                        fullscreen: false,
                        disabled: !Models.Role.canEdit(role),
                        inputStyles: {
                          hd: Css.padding2(Css.px(0), Css.px(20)),
                          tl: /* [] */0
                        },
                        itemType: "PropertyGroup",
                        actionType: "Rename",
                        getPossibleItemLink: (function (propertyGroupName) {
                            return Belt_Option.map(StateUtils.getPropertyGroupByName(propertyGroupName, model), (function (param) {
                                          return Router.Link.addDrawerItem(undefined, {
                                                      NAME: "propertyGroup",
                                                      VAL: [
                                                        param.id,
                                                        undefined
                                                      ]
                                                    });
                                        }));
                          })
                      }))
            }), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.justifyContent("spaceBetween"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.overflowY("auto"),
                          tl: {
                            hd: Css.zIndex(0),
                            tl: {
                              hd: Css.paddingTop(Css.px(20)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, !(template == null) && Belt_Array.some(template.propertyGroups, (function (id) {
                    return id === group.id;
                  })) ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding2(Css.px(10), Css.px(35)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.grey20),
                            tl: {
                              hd: Css.margin2(Css.px(10), Css.px(0)),
                              tl: {
                                hd: Css.color(Styles.Color.grey70),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.justifyContent("spaceBetween"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, "This is an example property bundle", Models.Role.canEdit(role) ? React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: /* [] */0
                              })
                        }, React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.bold),
                                              tl: {
                                                hd: Css.color(Styles.Color.grey70),
                                                tl: {
                                                  hd: Css.margin2(Css.px(0), Css.px(2)),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.mintGreenSecondary),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  var schemaRef = Firebase.app(undefined).firestore().collection("schemas").doc(schema.id);
                                  schemaRef.set({
                                        onboardingTemplate: {
                                          events: template.events,
                                          properties: template.properties,
                                          propertyGroups: Belt_Array.keep(template.propertyGroups, (function (id) {
                                                  return id !== group.id;
                                                })),
                                          goals: template.goals,
                                          metrics: template.metrics
                                        }
                                      }, {"merge": true});
                                  return AnalyticsRe.exampleInteraction(schemaGroup, "KeepCurrentExample", "PropertyGroup", schemaGroup.branchId, schemaGroup.schemaId);
                                })
                            }, "Keep Property Bundle"), React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.bold),
                                              tl: {
                                                hd: Css.color(Styles.Color.grey70),
                                                tl: {
                                                  hd: Css.margin2(Css.px(0), Css.px(2)),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.grapeErrorSecondary),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  var match = Router.getStaticRoute(undefined);
                                  var newDrawerItems = Belt_List.keep(match.drawerItems, (function (item) {
                                          if (typeof item === "object" && item.NAME === "propertyGroup") {
                                            return item.VAL[0] !== group.id;
                                          } else {
                                            return true;
                                          }
                                        }));
                                  Router.Schema.replaceDrawerItems(newDrawerItems);
                                  return Curry.app(sendActions, [
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              (function (branch) {
                                                  Curry._1(addToast, {
                                                        message: "The property bundle has been archived"
                                                      });
                                                  return AnalyticsRe.exampleInteraction(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "RemoveCurrentExample", "PropertyGroup", NamedBranch.getId(branch), schemaGroup.schemaId);
                                                }),
                                              undefined,
                                              [[
                                                  {
                                                    NAME: "Archive",
                                                    VAL: {
                                                      NAME: "PropertyGroup",
                                                      VAL: group.id
                                                    }
                                                  },
                                                  {
                                                    propertyGroupId: group.id,
                                                    propertyGroupQuery: group.id
                                                  }
                                                ]]
                                            ]);
                                })
                            }, "Remove Property Bundle"), React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.bold),
                                              tl: {
                                                hd: Css.backgroundColor(Styles.Color.grapeError),
                                                tl: {
                                                  hd: Css.color(Styles.Color.white),
                                                  tl: {
                                                    hd: Css.margin2(Css.px(0), Css.px(5)),
                                                    tl: {
                                                      hd: Css.padding2(Css.px(5), Css.px(10)),
                                                      tl: {
                                                        hd: Css.borderRadius(Styles.Border.radius),
                                                        tl: {
                                                          hd: Css.hover({
                                                                hd: Css.backgroundColor(Styles.Color.grapeErrorSecondary),
                                                                tl: /* [] */0
                                                              }),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  Router.Schema.popDrawerItem(undefined);
                                  FirebaseUtils.archiveTemplate(template, (function (eta) {
                                          return Curry.app(sendActions, [
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      eta
                                                    ]);
                                        }));
                                  return AnalyticsRe.exampleInteraction(schemaGroup, "RemoveAllExamples", "PropertyGroup", schemaGroup.branchId, schemaGroup.schemaId);
                                })
                            }, "Remove All Examples")) : null) : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingRight(Css.px(30)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(30)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(OpsWithState.make, {
                      value: group.description,
                      onFlush: (function (description) {
                          return Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdatePropertyGroupDescription",
                                            VAL: [
                                              group.id,
                                              description
                                            ]
                                          },
                                          context
                                        ]]
                                    ]);
                        }),
                      onChangeCompleted: (function (param, param$1) {
                          AnalyticsRe.propertyGroupUpdated(groupPropertyGroup, schemaGroup, "UpdateDescription", undefined, undefined, schemaGroup.branchId, schemaGroup.schemaId);
                          
                        }),
                      children: (function (value, onChange, onBlur, onFocus) {
                          return React.createElement(ReactTextareaAutosize, {
                                      className: Curry._1(Css.merge, {
                                            hd: Curry._1(Css.style, Styles.input),
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.flex({
                                                          NAME: "num",
                                                          VAL: 3.0
                                                        }),
                                                    tl: {
                                                      hd: Css.fontWeight(Styles.FontWeight.regular),
                                                      tl: {
                                                        hd: Css.fontSize(Styles.FontSize.regular),
                                                        tl: {
                                                          hd: Css.color(Styles.Color.grey90),
                                                          tl: {
                                                            hd: Css.backgroundColor(Css.transparent),
                                                            tl: {
                                                              hd: Css.zIndex(100),
                                                              tl: {
                                                                hd: Css.padding2(Css.px(4), Css.px(0)),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      onChange: (function (domEvent) {
                                          return Curry._1(onChange, domEvent.target.value);
                                        }),
                                      placeholder: "Describe the property bundle...",
                                      onFocus: (function (domEvent) {
                                          return Curry._1(onFocus, domEvent.target.value);
                                        }),
                                      onBlur: (function (domEvent) {
                                          return Curry._1(onBlur, domEvent.target.value);
                                        }),
                                      value: value,
                                      id: group.id + "-description",
                                      disabled: !Models.Role.canEdit(role)
                                    });
                        })
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.grey20),
                            tl: {
                              hd: Css.margin2(Css.px(15), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding4(Css.px(0), Css.px(30), Css.px(0), Css.px(30)),
                        tl: {
                          hd: Css.marginTop(Css.px(15)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.regular),
                              tl: {
                                hd: Css.color(Styles.Color.grey90),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, "Properties in bundle (" + String(Belt_List.length(groupProperties)) + ")"), Belt_List.toArray(Belt_List.map(groupProperties, (function (property) {
                            return React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("flexStart"),
                                                tl: {
                                                  hd: Css.paddingTop(Css.px(12)),
                                                  tl: {
                                                    hd: Css.paddingBottom(Css.px(12)),
                                                    tl: {
                                                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            })
                                      }, React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.flexGrow(1.0),
                                                  tl: {
                                                    hd: Css.flexShrink(1.0),
                                                    tl: {
                                                      hd: Css.width(Css.pct(100.0)),
                                                      tl: {
                                                        hd: Css.minWidth(Css.px(0)),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                })
                                          }, React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.flexWrap("wrap"),
                                                        tl: {
                                                          hd: Css.alignItems("baseline"),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    })
                                              }, React.createElement(Link.make, {
                                                    path: Router.Link.addDrawerItem(undefined, {
                                                          NAME: "property",
                                                          VAL: [
                                                            property.id,
                                                            undefined
                                                          ]
                                                        }),
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.color(Styles.Color.grey90),
                                                          tl: {
                                                            hd: Css.hover({
                                                                  hd: Css.color(Styles.Color.blue),
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: /* [] */0
                                                          }
                                                        }),
                                                    children: React.createElement($$Text.make, {
                                                          element: "Span",
                                                          size: "Medium",
                                                          weight: "Semi",
                                                          singleLine: true,
                                                          children: property.name
                                                        })
                                                  }), React.createElement(Spacer.make, {
                                                    width: 4
                                                  }), React.createElement(PropertyType.make, {
                                                    model: model,
                                                    property: property
                                                  })), React.createElement(Spacer.make, {
                                                height: 2
                                              }), property.description === "" ? React.createElement($$Text.make, {
                                                  size: "Small",
                                                  color: Styles.Color.grey50,
                                                  children: "No description"
                                                }) : React.createElement($$Text.make, {
                                                  size: "Small",
                                                  lines: 3,
                                                  color: Styles.Color.grey80,
                                                  children: property.description
                                                })), Models.Role.canEdit(role) ? React.createElement(ContextMenu.make, {
                                              options: [{
                                                  NAME: "Option",
                                                  VAL: {
                                                    label: "Remove Property",
                                                    onClick: (function (param) {
                                                        return Curry.app(sendActions, [
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    (function (branch) {
                                                                        return AnalyticsRe.propertyGroupUpdated(groupPropertyGroup, AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "RemoveProperty", undefined, property.id, NamedBranch.getId(branch), schemaGroup.schemaId);
                                                                      }),
                                                                    undefined,
                                                                    [[
                                                                        {
                                                                          NAME: "RemovePropertyFromGroup",
                                                                          VAL: [
                                                                            groupId,
                                                                            property.id
                                                                          ]
                                                                        },
                                                                        context
                                                                      ]]
                                                                  ]);
                                                      })
                                                  }
                                                }]
                                            }) : null);
                          }))), Models.Role.canEdit(role) ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.width(Css.pct(100)),
                              tl: /* [] */0
                            })
                      }, React.createElement(PropertyInput.make, {
                            propertyType: /* EventProperty */1,
                            currentProperties: Belt_List.flatten(Belt_List.map(model.propertyBundles, (function (group) {
                                        return Belt_List.map(group.properties, (function (propertyId) {
                                                      return {
                                                              TAG: /* PropertyRef */0,
                                                              _0: {
                                                                id: propertyId,
                                                                description: "",
                                                                pinnedValue: undefined
                                                              }
                                                            };
                                                    }));
                                      }))),
                            currentGroups: /* [] */0,
                            options: {
                              hd: [
                                undefined,
                                Belt_List.map(AvoModel.splitProperties(model.properties).props, (function (item) {
                                        return {
                                                NAME: "Property",
                                                VAL: item
                                              };
                                      }))
                              ],
                              tl: /* [] */0
                            },
                            onSelect: (function (item, param) {
                                if (item.NAME === "Group") {
                                  return Pervasives.failwith("Property bundles cannot be added to other property bundles.");
                                }
                                var property = item.VAL;
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            (function (branch) {
                                                return AnalyticsRe.propertyGroupUpdated(groupPropertyGroup, AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "AddProperty", undefined, property.id, NamedBranch.getId(branch), schemaGroup.schemaId);
                                              }),
                                            undefined,
                                            [[
                                                {
                                                  NAME: "AddPropertyToGroup",
                                                  VAL: [
                                                    groupId,
                                                    property.id
                                                  ]
                                                },
                                                {
                                                  propertyId: property.id,
                                                  propertyQuery: property.id,
                                                  propertyGroupId: groupId,
                                                  propertyGroupQuery: groupId
                                                }
                                              ]]
                                          ]);
                              }),
                            model: model,
                            currentFilters: currentFilters,
                            renderButton: (function (onClick, buttonRef) {
                                return React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.textAlign("left"),
                                                  tl: /* [] */0
                                                })
                                          }, React.createElement("button", {
                                                ref: buttonRef,
                                                className: Curry._1(Css.merge, {
                                                      hd: Curry._1(Css.style, Styles.button),
                                                      tl: {
                                                        hd: Curry._1(Css.style, {
                                                              hd: Css.width(Css.px(200)),
                                                              tl: {
                                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                tl: {
                                                                  hd: Css.fontSize(Styles.FontSize.regular),
                                                                  tl: {
                                                                    hd: Css.color(Styles.Color.blue),
                                                                    tl: {
                                                                      hd: Css.hover({
                                                                            hd: Css.color(Styles.Color.blueSecondary),
                                                                            tl: /* [] */0
                                                                          }),
                                                                      tl: {
                                                                        hd: Css.focus({
                                                                              hd: Css.textDecoration("underline"),
                                                                              tl: /* [] */0
                                                                            }),
                                                                        tl: {
                                                                          hd: Css.margin(Css.px(0)),
                                                                          tl: {
                                                                            hd: Css.padding2(Css.px(15), Css.px(5)),
                                                                            tl: {
                                                                              hd: Css.textAlign("left"),
                                                                              tl: /* [] */0
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                onClick: onClick
                                              }, "+ Add Property"));
                              }),
                            parent: {
                              NAME: "PropertyGroup",
                              VAL: groupId
                            },
                            sendActions: sendActions,
                            filters: filters,
                            propertyLocation: "PropertyGroupDetails"
                          })) : null), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.grey20),
                            tl: {
                              hd: Css.margin2(Css.px(15), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(0), Css.px(30)),
                        tl: /* [] */0
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.regular),
                              tl: {
                                hd: Css.paddingBottom(Css.px(5)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, "Sent In " + String(Belt_List.length(groupEvents$1)) + " Events"), React.createElement("div", undefined, groupEvents$1 ? Belt_List.toArray(Belt_List.map(groupEvents$1, (function ($$event) {
                                  return React.createElement(Link.make, {
                                              path: Router.Link.addDrawerItem(undefined, {
                                                    NAME: "event",
                                                    VAL: [
                                                      $$event.id,
                                                      undefined,
                                                      undefined,
                                                      false
                                                    ]
                                                  }),
                                              className: Curry._1(Css.style, {
                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                    tl: {
                                                      hd: Css.color(Styles.Color.blue),
                                                      tl: {
                                                        hd: Css.hover({
                                                              hd: Css.color(Styles.Color.blueSecondary),
                                                              tl: /* [] */0
                                                            }),
                                                        tl: {
                                                          hd: Css.padding2(Css.px(2), Css.px(0)),
                                                          tl: {
                                                            hd: Css.fontSize(Styles.FontSize.regular),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              children: React.createElement("div", undefined, $$event.name),
                                              key: $$event.id
                                            });
                                }))) : React.createElement("button", {
                            className: Curry._1(Css.merge, {
                                  hd: Curry._1(Css.style, Styles.button),
                                  tl: {
                                    hd: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.color(Styles.Color.grapeError),
                                                tl: {
                                                  hd: Css.fontSize(Styles.FontSize.regular),
                                                  tl: {
                                                    hd: Css.padding(Css.px(0)),
                                                    tl: {
                                                      hd: Css.hover({
                                                            hd: Css.color(Styles.Color.grapeErrorSecondary),
                                                            tl: /* [] */0
                                                          }),
                                                      tl: {
                                                        hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grapeError),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }),
                            onClick: (function (param) {
                                return handleArchivePropertyBundle(undefined);
                              })
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.marginRight(Css.px(2)),
                                      tl: /* [] */0
                                    })
                              }, React.createElement(IconX.make, {
                                    size: 10
                                  })), "Archive Property Bundle"))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.grey20),
                            tl: {
                              hd: Css.margin2(Css.px(15), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(0), Css.px(25)),
                        tl: /* [] */0
                      })
                }, React.createElement(ObjectActivityLog.make, tmp$3), React.createElement(CommentInput.make, tmp$4))));
  } else {
    tmp = "Property not found";
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.cursor("default"),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.height(Css.pct(100.0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              onClick: (function ($$event) {
                  $$event.stopPropagation();
                  
                })
            }, tmp);
}

var make = PropertyGroupDetails;

exports.make = make;
/* Css Not a pure module */
