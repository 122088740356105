// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Tag = require("./Tag.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var Fuzzaldrin = require("fuzzaldrin");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var AutofillInput = require("./AutofillInput.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");

var TagsAutofillInput = AutofillInput.Component({});

function TagsInput(Props) {
  var $$event = Props.event;
  var events = Props.events;
  var role = Props.role;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var context = {
    eventId: $$event.id,
    eventQuery: $$event.id
  };
  var tags = Belt_List.keep(StateUtils.getAllTags(events), (function (tag) {
          return !Belt_List.has($$event.tags, tag, (function (a, b) {
                        return a === b;
                      }));
        }));
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.flexWrap("wrap"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, Belt_List.toArray(Belt_List.map($$event.tags, (function (tag) {
                        var tmp = {
                          tag: tag,
                          key: tag
                        };
                        var tmp$1 = Models.Role.canEdit(role) ? (function (param) {
                              return Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (branch) {
                                              return AnalyticsRe.eventTagsUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "Remove", tag, Belt_Array.keep(Belt_List.toArray($$event.tags), (function (existingTag) {
                                                                return existingTag !== tag;
                                                              })), $$event.id, $$event.name, NamedBranch.getId(branch), schemaGroup.schemaId);
                                            }),
                                          undefined,
                                          [[
                                              {
                                                NAME: "RemoveTag",
                                                VAL: [
                                                  $$event.id,
                                                  tag
                                                ]
                                              },
                                              context
                                            ]]
                                        ]);
                            }) : undefined;
                        if (tmp$1 !== undefined) {
                          tmp.onRemove = Caml_option.valFromOption(tmp$1);
                        }
                        return React.createElement(Tag.make, tmp);
                      }))), Models.Role.canEdit(role) ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.flexGrow(1.0),
                          tl: {
                            hd: Css.maxWidth(Css.px(250)),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(TagsAutofillInput.make, {
                        onSelect: (function (tag, param, param$1) {
                            return Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        (function (branch) {
                                            return AnalyticsRe.eventTagsUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "Add", tag, Belt_Array.concat(Belt_List.toArray($$event.tags), [tag]), $$event.id, $$event.name, NamedBranch.getId(branch), schemaGroup.schemaId);
                                          }),
                                        undefined,
                                        [[
                                            {
                                              NAME: "AddTag",
                                              VAL: [
                                                $$event.id,
                                                tag
                                              ]
                                            },
                                            context
                                          ]]
                                      ]);
                          }),
                        onCreate: (function (tag) {
                            return Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        (function (branch) {
                                            return AnalyticsRe.eventTagsUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "Create", tag, Belt_Array.concat(Belt_List.toArray($$event.tags), [tag]), $$event.id, $$event.name, NamedBranch.getId(branch), schemaGroup.schemaId);
                                          }),
                                        undefined,
                                        [[
                                            {
                                              NAME: "AddTag",
                                              VAL: [
                                                $$event.id,
                                                tag
                                              ]
                                            },
                                            context
                                          ]]
                                      ]);
                          }),
                        placeholder: "Add tag...",
                        search: (function (query) {
                            var results = Belt_List.map(Belt_List.fromArray(Fuzzaldrin.filter(Belt_List.toArray(tags), query, {
                                          maxResults: 5
                                        })), (function (tag) {
                                    return {
                                            NAME: "Item",
                                            VAL: tag
                                          };
                                  }));
                            var tagExists = Belt_List.has(tags, query, (function (a, b) {
                                    return a === b;
                                  }));
                            var tagIsEmpty = query.length === 0;
                            var tagAlreadyAdded = Belt_List.has($$event.tags, query, (function (a, b) {
                                    return a === b;
                                  }));
                            if (tagAlreadyAdded) {
                              return Belt_List.concat(results, {
                                          hd: {
                                            NAME: "Message",
                                            VAL: query + " already added"
                                          },
                                          tl: /* [] */0
                                        });
                            } else if (tagExists || tagIsEmpty) {
                              return results;
                            } else {
                              return Belt_List.concat(results, {
                                          hd: {
                                            NAME: "CreateNew",
                                            VAL: query
                                          },
                                          tl: /* [] */0
                                        });
                            }
                          }),
                        renderItem: (function (item, isActive) {
                            var variant = item.NAME;
                            return React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.padding(Css.px(10)),
                                              tl: {
                                                hd: Css.backgroundColor(isActive ? Styles.Color.blueBg : Styles.Color.white),
                                                tl: {
                                                  hd: Css.hover({
                                                        hd: Css.backgroundColor(Styles.Color.blueBg),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, variant === "CreateNew" ? React.createElement("div", undefined, React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.fontWeight(Styles.FontWeight.regular),
                                                        tl: {
                                                          hd: Css.fontStyle("italic"),
                                                          tl: {
                                                            hd: Css.color(Styles.Color.grey70),
                                                            tl: {
                                                              hd: Css.fontSize(Css.px(10)),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      })
                                                }, "New Tag"), React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                                        tl: /* [] */0
                                                      })
                                                }, item.VAL)) : (
                                          variant === "Item" ? React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.display("inlineBlock"),
                                                        tl: /* [] */0
                                                      })
                                                }, React.createElement(Tag.make, {
                                                      tag: item.VAL
                                                    })) : React.createElement("div", undefined, item.VAL)
                                        ));
                          }),
                        refreshKey: [
                          $$event.tags,
                          tags
                        ]
                      })) : null);
}

var make = TagsInput;

exports.TagsAutofillInput = TagsAutofillInput;
exports.make = make;
/* TagsAutofillInput Not a pure module */
