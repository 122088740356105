// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var FramerMotion = require("framer-motion");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function setLabelRef(labelRefs, index) {
  return function (ref) {
    labelRefs.current = Belt_Array.mapWithIndex(labelRefs.current, (function (labelRefIndex, labelRef) {
            if (labelRefIndex === index) {
              return ref;
            } else {
              return labelRef;
            }
          }));
    
  };
}

function getAnimation(position, opacity) {
  return {
          x: position.left,
          y: position.top,
          width: position.width,
          height: position.height,
          opacity: opacity
        };
}

function getPosition(containerRef, labelRefs, tabIndex) {
  var match = containerRef.current;
  var match$1 = Belt_Option.flatMap(Belt_Array.get(labelRefs.current, tabIndex), (function (prim) {
          if (prim == null) {
            return ;
          } else {
            return Caml_option.some(prim);
          }
        }));
  if (match == null) {
    return ;
  }
  if (match$1 === undefined) {
    return ;
  }
  var labelRect = Caml_option.valFromOption(match$1).getBoundingClientRect();
  var labelWidth = labelRect.width;
  var labelHeight = labelRect.height;
  var labelTop = labelRect.top;
  var labelLeft = labelRect.left;
  var containerRect = match.getBoundingClientRect();
  var containerTop = containerRect.top;
  var containerLeft = containerRect.left;
  return {
          width: labelWidth + 0.5,
          height: labelHeight,
          top: (labelTop - containerTop | 0) - 1 | 0,
          left: (labelLeft - containerLeft | 0) - 1 | 0
        };
}

var Utils = {
  setLabelRef: setLabelRef,
  getAnimation: getAnimation,
  getPosition: getPosition
};

var activeTransition = {
  type: "spring",
  stiffness: 350,
  damping: 27,
  mass: 0.85,
  duration: 0
};

var inactiveTransition = {
  type: "ease",
  stiffness: 0,
  damping: 0,
  mass: 0.0,
  duration: 0
};

var root = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.gap(Css.px(2)),
          tl: {
            hd: Css.flexDirection("row"),
            tl: {
              hd: Css.width("fitContent"),
              tl: {
                hd: Css.padding("zero"),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                  tl: {
                    hd: Css.borderRadius(Css.px(5)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.grey20),
                      tl: {
                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(3), undefined, true, Styles.Color.setAlpha(Styles.Color.grey90, 0.05))),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var activeSegment = Curry._1(Css.style, {
      hd: Css.zIndex(0),
      tl: {
        hd: Css.position("absolute"),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.left(Css.px(0)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.white),
              tl: {
                hd: Css.borderRadius(Css.px(4)),
                tl: {
                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(1), Css.px(4), undefined, undefined, Styles.Color.setAlpha(Styles.Color.grey90, 0.15))),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var input = Curry._1(Css.style, {
      hd: Css.display("none"),
      tl: {
        hd: Css.selector(":checked + label", {
              hd: Css.color(Styles.Color.grey90),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.selector(":not(:checked) + label:hover", {
                hd: Css.color(Styles.Color.grey90),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.grey10),
                  tl: /* [] */0
                }
              }),
          tl: {
            hd: Css.disabled({
                  hd: Css.selector(" + label", {
                        hd: Css.cursor("default"),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.selector(":not(:checked) + label", {
                          hd: Css.color(Styles.Color.grey60),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

var label = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.color(Styles.Color.grey70),
          tl: {
            hd: Css.paddingLeft(Css.px(12)),
            tl: {
              hd: Css.paddingRight(Css.px(12)),
              tl: {
                hd: Css.paddingTop(Css.px(4)),
                tl: {
                  hd: Css.paddingBottom(Css.px(6)),
                  tl: {
                    hd: Css.borderRadius(Css.px(4)),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$default
                          }, undefined, undefined, "background-color"),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: {
                          hd: Css.textAlign("center"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var Style = {
  activeTransition: activeTransition,
  inactiveTransition: inactiveTransition,
  root: root,
  activeSegment: activeSegment,
  input: input,
  label: label
};

function SegmentControl(Props) {
  var options = Props.options;
  var onSelect = Props.onSelect;
  var selected = Props.selected;
  var disabledOpt = Props.disabled;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var labelRefs = React.useRef(Belt_Array.make(options.length, null));
  var containerRef = React.useRef(null);
  var match = React.useState(function () {
        
      });
  var setActivePosition = match[1];
  var activePosition = match[0];
  var match$1 = React.useState(function () {
        return Belt_Option.getWithDefault(Belt_Array.getIndexBy(options, (function (param) {
                          return Caml_obj.caml_equal(param[1], selected);
                        })), 0);
      });
  var setActiveIndex = match$1[1];
  var activeIndex = match$1[0];
  React.useEffect((function () {
          Curry._1(setActivePosition, (function (param) {
                  return getPosition(containerRef, labelRefs, activeIndex);
                }));
          
        }), []);
  var initial = React.useRef(true);
  var match$2 = React.useState(function () {
        
      });
  var setWindowWidth = match$2[1];
  React.useEffect((function () {
          var resizeHandler = function (param) {
            return Curry._1(setWindowWidth, (function (param) {
                          return window.innerWidth;
                        }));
          };
          window.addEventListener("resize", resizeHandler);
          return (function (param) {
                    window.removeEventListener("resize", resizeHandler);
                    
                  });
        }), []);
  React.useEffect((function () {
          setTimeout((function (param) {
                  initial.current = true;
                  return Curry._1(setActivePosition, (function (param) {
                                return getPosition(containerRef, labelRefs, activeIndex);
                              }));
                }), 0);
          
        }), [match$2[0]]);
  var tmp;
  if (activePosition !== undefined) {
    var activePosition$1 = Caml_option.valFromOption(activePosition);
    tmp = React.createElement(FramerMotion.motion.div, {
          animate: getAnimation(activePosition$1, 1),
          transition: initial.current ? (initial.current = false, inactiveTransition) : activeTransition,
          exit: getAnimation(activePosition$1, 0),
          className: activeSegment,
          key: "hover"
        });
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              ref: containerRef,
              className: root
            }, React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  initial: false,
                  children: tmp
                }), Belt_Array.mapWithIndexU(options, (function (index, param) {
                    var value = param[1];
                    var label$1 = param[0];
                    var key = String(index) + "-" + label$1;
                    return React.createElement(React.Fragment, {
                                children: null,
                                key: key
                              }, React.createElement("input", {
                                    className: input,
                                    id: key,
                                    checked: Caml_obj.caml_equal(value, selected),
                                    disabled: disabled,
                                    name: label$1,
                                    type: "radio",
                                    onChange: (function (param) {
                                        if (Caml_obj.caml_notequal(value, selected)) {
                                          Curry._1(setActivePosition, (function (param) {
                                                  return getPosition(containerRef, labelRefs, index);
                                                }));
                                          Curry._1(setActiveIndex, (function (param) {
                                                  return index;
                                                }));
                                          return Curry._1(onSelect, value);
                                        }
                                        
                                      })
                                  }), React.createElement("label", {
                                    ref: setLabelRef(labelRefs, index),
                                    className: label,
                                    htmlFor: key
                                  }, React.createElement("span", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.zIndex(0),
                                              tl: /* [] */0
                                            })
                                      }, React.createElement($$Text.make, {
                                            element: "Span",
                                            size: "Small",
                                            weight: "Semi",
                                            lineHeight: Css.px(16),
                                            children: label$1
                                          }))));
                  })));
}

var make = SegmentControl;

exports.Utils = Utils;
exports.Style = Style;
exports.make = make;
/* root Not a pure module */
