// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("./Icons.bs.js");
var Quill = require("./externals/quill.bs.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var QuillDelta = require("quill-delta");

function getBackgroundColor(state, outline) {
  if (state === "Existing") {
    if (outline) {
      return Styles.Color.white;
    } else {
      return Styles.Color.grey30;
    }
  } else if (state === "New") {
    if (outline) {
      return Styles.Color.white;
    } else {
      return Styles.Color.setAlpha(Styles.Color.mintGreen, 0.2);
    }
  } else if (outline) {
    return Styles.Color.white;
  } else {
    return Styles.Color.setAlpha(Styles.Color.grapeError, 0.2);
  }
}

function getBorderColor(state, outline) {
  if (outline) {
    if (state === "Existing") {
      return Styles.Color.grey40;
    } else if (state === "New") {
      return Styles.Color.setAlpha(Styles.Color.mintGreen, 0.4);
    } else {
      return Styles.Color.setAlpha(Styles.Color.grapeError, 0.4);
    }
  } else {
    return "transparent";
  }
}

function getTextColor(state, outline) {
  if (state === "Existing") {
    if (outline) {
      return Styles.Color.grey20;
    } else {
      return Styles.Color.grey30;
    }
  } else if (state === "New") {
    if (outline) {
      return Styles.Color.mintGreen;
    } else {
      return Styles.Color.mintGreenSecondary;
    }
  } else if (outline) {
    return Styles.Color.grapeError;
  } else {
    return Styles.Color.grapeErrorSecondary;
  }
}

function DiffEventTriggers$Pill(Props) {
  var state = Props.state;
  var label = Props.label;
  var outlineOpt = Props.outline;
  var outline = outlineOpt !== undefined ? outlineOpt : false;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.backgroundColor(getBackgroundColor(state, outline)),
                    tl: {
                      hd: Css.border(Css.px(1), "solid", getBorderColor(state, outline)),
                      tl: {
                        hd: Css.borderRadius(Css.px(99)),
                        tl: {
                          hd: Css.paddingRight(Css.px(7)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(7)),
                            tl: {
                              hd: Css.paddingTop(Css.px(1)),
                              tl: {
                                hd: Css.paddingBottom(Css.px(1)),
                                tl: {
                                  hd: Css.marginRight(Css.px(8)),
                                  tl: {
                                    hd: Css.marginBottom(Css.px(4)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: getTextColor(state, outline),
                  children: label
                }));
}

var Pill = {
  getBackgroundColor: getBackgroundColor,
  getBorderColor: getBorderColor,
  getTextColor: getTextColor,
  make: DiffEventTriggers$Pill
};

var root = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.paddingLeft(Css.px(26)),
        tl: {
          hd: Css.paddingRight(Css.px(10)),
          tl: /* [] */0
        }
      }
    });

var icon = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(10)),
        tl: {
          hd: Css.left(Css.px(0)),
          tl: /* [] */0
        }
      }
    });

function trigger(frame) {
  return Curry._1(Css.style, {
              hd: Css.border(Css.px(1), "solid", frame),
              tl: {
                hd: Css.borderRadius(Css.px(5)),
                tl: {
                  hd: Css.padding(Css.px(10)),
                  tl: {
                    hd: Css.marginBottom(Css.px(10)),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var contents = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: /* [] */0
      }
    });

function contentImage(borderColor) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.px(100)),
              tl: {
                hd: Css.height(Css.px(100)),
                tl: {
                  hd: Css.marginTop(Css.px(10)),
                  tl: {
                    hd: Css.marginRight(Css.px(10)),
                    tl: {
                      hd: Css.border(Css.px(1), "solid", borderColor),
                      tl: {
                        hd: Css.borderRadius(Css.px(4)),
                        tl: {
                          hd: Css.objectFit("contain"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

var sources = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.marginTop(Css.px(10)),
          tl: /* [] */0
        }
      }
    });

var Style = {
  root: root,
  icon: icon,
  trigger: trigger,
  contents: contents,
  contentImage: contentImage,
  sources: sources
};

function DiffEventTriggers(Props) {
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var $$event = Props.event;
  var nextEvent = Props.nextEvent;
  var actions = Props.actions;
  var newTriggers = Belt_List.keepMapU(actions, (function (action) {
          if (typeof action !== "object") {
            return ;
          }
          if (action.NAME !== "CreateEventTrigger") {
            return ;
          }
          var match = action.VAL;
          return [
                  match[1],
                  match[2],
                  match[3],
                  match[4]
                ];
        }));
  var triggersWithChanges = Belt_Array.keepMapU(Belt_Array.keepU(Belt_Array.mapWithIndex(nextEvent.triggers, (function (index, trigger) {
                  return [
                          trigger,
                          index
                        ];
                })), (function (param) {
              var id = param[0].id;
              return Belt_List.someU(actions, (function (action) {
                            if (typeof action !== "object") {
                              return false;
                            }
                            var variant = action.NAME;
                            if (variant === "UpdateTriggerDescription" || variant === "UpdateTriggerContent") {
                              return action.VAL[1] === id;
                            } else if (variant === "RemoveTriggerSource" || variant === "AddTriggerSource" || variant === "UpdateTriggerSources") {
                              return action.VAL[0] === id;
                            } else {
                              return false;
                            }
                          }));
            })), (function (param) {
          var index = param[1];
          var nextTrigger = param[0];
          return Belt_Option.mapU(Belt_Array.getByU($$event.triggers, (function (param) {
                            return param.id === nextTrigger.id;
                          })), (function (trigger) {
                        return [
                                trigger,
                                nextTrigger,
                                index
                              ];
                      }));
        }));
  var removedTriggers = Belt_List.keepMapU(actions, (function (action) {
          if (typeof action !== "object") {
            return ;
          }
          if (action.NAME !== "RemoveEventTrigger") {
            return ;
          }
          var triggerId = action.VAL[1];
          return Belt_Array.getByU($$event.triggers, (function (param) {
                        return param.id === triggerId;
                      }));
        }));
  if (newTriggers === /* [] */0 && triggersWithChanges.length === 0 && removedTriggers === /* [] */0) {
    return null;
  } else {
    return React.createElement("div", {
                className: root
              }, React.createElement("div", {
                    className: icon
                  }, React.createElement(Icons.TapScreen.make, {
                        size: 16,
                        color: Styles.Color.grey40
                      })), Belt_List.toArray(Belt_List.mapU(removedTriggers, (function (trigger$1) {
                          var ids = trigger$1.sources;
                          return React.createElement("div", {
                                      key: trigger$1.id,
                                      className: trigger(Styles.Color.grapeError)
                                    }, React.createElement($$Text.make, {
                                          size: "Small",
                                          weight: "Semi",
                                          color: Styles.Color.grapeError,
                                          children: "Deleted Trigger"
                                        }), Belt_Option.mapWithDefault(trigger$1.content, null, (function (content) {
                                            if (content.TAG === /* Image */0) {
                                              return React.createElement("img", {
                                                          className: contentImage(Styles.Color.grapeError),
                                                          src: content._0.url
                                                        });
                                            } else {
                                              return null;
                                            }
                                          })), React.createElement("div", {
                                          className: sources
                                        }, ids ? Belt_Array.mapU(Belt_Array.keepMapU(ids._0, (function (id) {
                                                      return Belt_List.getByU(masterModel.sources, (function (source) {
                                                                    return source.id === id;
                                                                  }));
                                                    })), (function (source) {
                                                  return React.createElement(DiffEventTriggers$Pill, {
                                                              state: "Removed",
                                                              label: AvoConfig.getSourceName(source),
                                                              key: source.id
                                                            });
                                                })) : React.createElement(DiffEventTriggers$Pill, {
                                                state: "Removed",
                                                label: "Source Independent",
                                                outline: true
                                              })), React.createElement($$Text.make, {
                                          size: "Small",
                                          children: React.createElement("span", {
                                                dangerouslySetInnerHTML: {
                                                  __html: Quill.toHTMLWithDiff(new QuillDelta(JSON.parse(trigger$1.description)), new QuillDelta(), undefined, undefined)
                                                }
                                              })
                                        }));
                        }))), Belt_List.toArray(Belt_List.mapU(newTriggers, (function (param) {
                          var sources$1 = param[1];
                          return React.createElement("div", {
                                      key: param[0],
                                      className: trigger(Styles.Color.mintGreen)
                                    }, React.createElement($$Text.make, {
                                          size: "Small",
                                          weight: "Semi",
                                          color: Styles.Color.mintGreen,
                                          children: "New Trigger"
                                        }), Belt_Option.mapWithDefault(param[3], null, (function (content) {
                                            if (content.TAG === /* Image */0) {
                                              return React.createElement("img", {
                                                          className: contentImage(Styles.Color.mintGreen),
                                                          src: content._0.url
                                                        });
                                            } else {
                                              return null;
                                            }
                                          })), React.createElement("div", {
                                          className: sources
                                        }, sources$1 ? Belt_Array.mapU(Belt_Array.keepMapU(sources$1._0, (function (id) {
                                                      return Belt_List.getByU(branchModel.sources, (function (source) {
                                                                    return source.id === id;
                                                                  }));
                                                    })), (function (source) {
                                                  return React.createElement(DiffEventTriggers$Pill, {
                                                              state: "New",
                                                              label: AvoConfig.getSourceName(source),
                                                              key: source.id
                                                            });
                                                })) : React.createElement(DiffEventTriggers$Pill, {
                                                state: "New",
                                                label: "Source Independent",
                                                outline: true
                                              })), React.createElement($$Text.make, {
                                          size: "Small",
                                          children: React.createElement("span", {
                                                dangerouslySetInnerHTML: {
                                                  __html: Quill.toHTMLWithDiff(new QuillDelta(), new QuillDelta(JSON.parse(param[2])), undefined, undefined)
                                                }
                                              })
                                        }));
                        }))), Belt_Array.mapU(triggersWithChanges, (function (param) {
                      var nextTrigger = param[1];
                      var trigger$1 = param[0];
                      var sourceChangeActions = Belt_List.keep(actions, (function (action) {
                              if (typeof action !== "object") {
                                return false;
                              }
                              var variant = action.NAME;
                              if (variant === "RemoveTriggerSource" || variant === "AddTriggerSource" || variant === "UpdateTriggerSources") {
                                return action.VAL[1] === trigger$1.id;
                              } else {
                                return false;
                              }
                            }));
                      return React.createElement("div", {
                                  key: nextTrigger.id,
                                  className: trigger(Styles.Color.grey20)
                                }, React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Semi",
                                      color: Styles.Color.grey80,
                                      children: "Trigger " + (String(param[2] + 1 | 0) + ("/" + String(nextEvent.triggers.length)))
                                    }), Belt_List.some(actions, (function (action) {
                                        if (typeof action === "object" && action.NAME === "UpdateTriggerContent") {
                                          return action.VAL[1] === trigger$1.id;
                                        } else {
                                          return false;
                                        }
                                      })) ? React.createElement("div", {
                                        className: contents
                                      }, Belt_Option.mapWithDefault(trigger$1.content, null, (function (content) {
                                              if (content.TAG === /* Image */0) {
                                                return React.createElement("img", {
                                                            className: contentImage(Styles.Color.grapeError),
                                                            src: content._0.url
                                                          });
                                              } else {
                                                return null;
                                              }
                                            })), Belt_Option.mapWithDefault(nextTrigger.content, null, (function (content) {
                                              if (content.TAG === /* Image */0) {
                                                return React.createElement("img", {
                                                            className: contentImage(Styles.Color.mintGreen),
                                                            src: content._0.url
                                                          });
                                              } else {
                                                return null;
                                              }
                                            }))) : null, sourceChangeActions === /* [] */0 ? null : React.createElement("div", {
                                        className: sources
                                      }, Belt_List.toArray(Belt_List.mapU(sourceChangeActions, (function (action) {
                                                  if (typeof action !== "object") {
                                                    return null;
                                                  }
                                                  var variant = action.NAME;
                                                  if (variant === "UpdateTriggerSources") {
                                                    var sources = action.VAL[2];
                                                    if (sources) {
                                                      return Belt_Array.concat([React.createElement(DiffEventTriggers$Pill, {
                                                                        state: "Removed",
                                                                        label: "Source Independent",
                                                                        outline: true
                                                                      })], Belt_Array.mapU(Belt_Array.keepMapU(sources._0, (function (id) {
                                                                            return Belt_List.getByU(branchModel.sources, (function (source) {
                                                                                          return source.id === id;
                                                                                        }));
                                                                          })), (function (source) {
                                                                        return React.createElement(DiffEventTriggers$Pill, {
                                                                                    state: "New",
                                                                                    label: AvoConfig.getSourceName(source),
                                                                                    key: source.id
                                                                                  });
                                                                      })));
                                                    }
                                                    var ids = trigger$1.sources;
                                                    return Belt_Array.concat(ids ? Belt_Array.mapU(Belt_Array.keepMapU(ids._0, (function (id) {
                                                                            return Belt_List.getByU(masterModel.sources, (function (source) {
                                                                                          return source.id === id;
                                                                                        }));
                                                                          })), (function (source) {
                                                                        return React.createElement(DiffEventTriggers$Pill, {
                                                                                    state: "Removed",
                                                                                    label: AvoConfig.getSourceName(source),
                                                                                    key: source.id
                                                                                  });
                                                                      })) : [], [React.createElement(DiffEventTriggers$Pill, {
                                                                      state: "New",
                                                                      label: "Source Independent",
                                                                      outline: true
                                                                    })]);
                                                  }
                                                  if (variant === "AddTriggerSource") {
                                                    var id = action.VAL[2];
                                                    return Belt_Option.mapWithDefault(Belt_List.getByU(branchModel.sources, (function (source) {
                                                                      return source.id === id;
                                                                    })), null, (function (source) {
                                                                  return React.createElement(DiffEventTriggers$Pill, {
                                                                              state: "New",
                                                                              label: AvoConfig.getSourceName(source),
                                                                              key: source.id
                                                                            });
                                                                }));
                                                  }
                                                  if (variant !== "RemoveTriggerSource") {
                                                    return null;
                                                  }
                                                  var id$1 = action.VAL[2];
                                                  return Belt_Option.mapWithDefault(Belt_List.getByU(masterModel.sources, (function (source) {
                                                                    return source.id === id$1;
                                                                  })), null, (function (source) {
                                                                return React.createElement(DiffEventTriggers$Pill, {
                                                                            state: "Removed",
                                                                            label: AvoConfig.getSourceName(source),
                                                                            key: source.id
                                                                          });
                                                              }));
                                                })))), Belt_List.someU(actions, (function (action) {
                                        if (typeof action === "object" && action.NAME === "UpdateTriggerDescription") {
                                          return action.VAL[1] === trigger$1.id;
                                        } else {
                                          return false;
                                        }
                                      })) ? React.createElement($$Text.make, {
                                        size: "Small",
                                        children: React.createElement("span", {
                                              dangerouslySetInnerHTML: {
                                                __html: Quill.toHTMLWithDiff(new QuillDelta(JSON.parse(trigger$1.description)), new QuillDelta(JSON.parse(nextTrigger.description)), undefined, undefined)
                                              }
                                            })
                                      }) : null);
                    })));
  }
}

var make = DiffEventTriggers;

exports.Pill = Pill;
exports.Style = Style;
exports.make = make;
/* root Not a pure module */
