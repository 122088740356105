// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hover = require("./Hover.bs.js");
var Icons = require("./Icons.bs.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var IconWeb = require("./IconWeb.bs.js");
var Shortid = require("shortid");
var AvoModel = require("./avoModel.bs.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var IconExpo = require("./IconExpo.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var IconMobile = require("./IconMobile.bs.js");
var IconServer = require("./IconServer.bs.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var ContextMenu = require("./ContextMenu.bs.js");
var NamedBranch = require("./NamedBranch.bs.js");
var PropertyRule = require("./PropertyRule.bs.js");
var PropertyType = require("./PropertyType.bs.js");
var PropertyUtils = require("./PropertyUtils.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var IconArrowDownEast = require("./IconArrowDownEast.bs.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");
var PropertyValidations = require("./PropertyValidations.bs.js");
var PropertyAbsenceConfig = require("./PropertyAbsenceConfig.bs.js");
var CopyTextToClipboard = require("copy-text-to-clipboard");

function Property$GroupTypePill(Props) {
  var groupTypeName = Props.groupTypeName;
  return React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.marginBottom(Css.px(2)),
                    tl: {
                      hd: Css.padding2(Css.px(2), Css.px(8)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.grey70),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: {
                                  hd: Css.textTransform("uppercase"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(Icons.Group.make, {
                  color: Styles.Color.grey30
                }), React.createElement(Spacer.make, {
                  width: 6
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Bold",
                  color: Styles.Color.grey20,
                  children: groupTypeName
                }));
}

var GroupTypePill = {
  make: Property$GroupTypePill
};

function offerArchivingPropertyIfUnused(model, propertyId, eventId, schemaGroup, sendActions) {
  var propertyStillInUse = Belt_List.length(StateUtils.eventsSendingProperty(model, undefined, propertyId)) > 0;
  var propertyName = Belt_Option.mapWithDefault(Belt_List.getBy(model.properties, (function (x) {
              if (x.TAG === /* PropertyRef */0) {
                return false;
              } else {
                return x._0.id === propertyId;
              }
            })), "Unknown Name", (function (x) {
          if (x.TAG === /* PropertyRef */0) {
            return "Unknown Name";
          } else {
            return x._0.name;
          }
        }));
  var $$event = Belt_Option.getExn(StateUtils.getEventById(eventId, model));
  var property = Belt_Option.getExn(StateUtils.getPropertyById(propertyId, model));
  var propertyType;
  propertyType = property.TAG === /* PropertyRef */0 ? Pervasives.failwith("unsupported property type") : AnalyticsUtils.sendAsToPropertyType(property._0.sendAs);
  var promptRemoveProperty = function (param) {
    AnalyticsRe.propertyDeletePrompted(schemaGroup, $$event.name, propertyName, propertyId, eventId, propertyType, schemaGroup.branchId, schemaGroup.schemaId);
    return window.confirm("The property '" + propertyName + "' is not being used anymore, would you like to permanently delete it from your schema?");
  };
  if (!propertyStillInUse && promptRemoveProperty(undefined)) {
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    return AnalyticsRe.propertyDeleted(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), $$event.name, propertyName, eventId, propertyId, propertyType, NamedBranch.getId(branch), schemaGroup.schemaId);
                  }),
                undefined,
                [[
                    {
                      NAME: "Archive",
                      VAL: {
                        NAME: "Property",
                        VAL: propertyId
                      }
                    },
                    {
                      eventId: $$event.id,
                      propertyId: propertyId,
                      propertyQuery: propertyId
                    }
                  ]]
              ]);
  }
  
}

function Property(Props) {
  var property = Props.property;
  var $$event = Props.event;
  var sendActions = Props.sendActions;
  var unnecessaryOpt = Props.unnecessary;
  var inGroupOpt = Props.inGroup;
  var model = Props.model;
  var role = Props.role;
  var schemaGroup = Props.schemaGroup;
  var unnecessary = unnecessaryOpt !== undefined ? unnecessaryOpt : false;
  var inGroup = inGroupOpt !== undefined ? inGroupOpt : false;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var globalSend = GlobalSendContext.use(undefined);
  var containerRef = React.useRef(null);
  var eventDestinations = AvoModel.destinationsReceivingEvent(model, $$event);
  var propertyRef = Belt_List.head(Belt_List.keepMapU($$event.properties, (function (propRef) {
              if (propRef.TAG !== /* PropertyRef */0) {
                return ;
              }
              var propRef$1 = propRef._0;
              if (propRef$1.id === property.id) {
                return propRef$1;
              }
              
            })));
  var pinnedValues = PropertyUtils.getPinnedValues(propertyRef, property.id, $$event.id, model);
  var hasGroupType = React.useMemo((function () {
          var groupTypeId = property.sendAs;
          if (typeof groupTypeId === "number") {
            return ;
          } else {
            return groupTypeId._0;
          }
        }), [property.sendAs]);
  var isUserProperty = property.sendAs === /* UserProperty */2;
  var isGroupProperty = Belt_Option.isSome(hasGroupType);
  var notBuiltIn = property.builtIn === "NotBuiltIn";
  var isSentToIntercom = Belt_List.some(eventDestinations, (function (destination) {
          return Caml_obj.caml_equal(destination.type_, "Intercom");
        }));
  var whitelist = Belt_List.keep($$event.propertyWhitelist, (function (param) {
          return param[0] === "Intercom";
        }));
  var isWhitelisted = isUserProperty || Belt_List.some(whitelist, (function (x) {
          if (x[0] === "Intercom") {
            return x[1] === property.id;
          } else {
            return false;
          }
        }));
  var disabled = isUserProperty || !isWhitelisted && Belt_List.length(whitelist) >= AvoConfig.intercomPropertyLimit;
  var isObject = property.type_ === "object";
  var destinations = Belt_List.keepMap(eventDestinations, (function (destination) {
          return destination.type_;
        }));
  var unsupportedDestinations = BeltListExtensions.dedupeSlow(Belt_List.keepMap(destinations, (function (destination) {
              if (AvoConfig.supportsObjects(destination, property.name)) {
                return ;
              } else {
                return destination;
              }
            })));
  var isSetOnce = property.operation === "SetOnce";
  var destinations$1 = Belt_List.keepMap(eventDestinations, (function (destination) {
          return destination.type_;
        }));
  var unsupportedDestinationLabels = Belt_List.toArray(BeltListExtensions.dedupeString(Belt_List.keepMap(destinations$1, AvoConfig.getUnsupportedSetOnceDestinationLabels)));
  var tmp;
  if (!inGroup && property.builtIn === "NotBuiltIn") {
    var match = AvoLimits.NameMapping.computeAvailability(workspace);
    var tmp$1;
    if (!Models.Role.canEdit(role) || property.sendAs === /* SystemProperty */0) {
      tmp$1 = [];
    } else {
      var match$1 = AvoLimits.PinnedProperties.computeAvailability(workspace);
      tmp$1 = [{
          NAME: "Option",
          VAL: {
            label: (
              pinnedValues ? "Update Pinned Value" : "Pin Property Value"
            ) + (
              match$1 === "Available" ? "" : " ⚡️"
            ),
            onClick: (function (param) {
                var match = AvoLimits.PinnedProperties.computeAvailability(workspace);
                return Curry._1(globalSend, {
                            TAG: /* OpenModal */4,
                            _0: match === "AvailableDuringTrial" || match === "Available" ? ({
                                  NAME: "PinPropertyValue",
                                  VAL: [
                                    $$event.id,
                                    property.id
                                  ]
                                }) : ({
                                  NAME: "BillingPrompt",
                                  VAL: "PinnedProperties"
                                })
                          });
              })
          }
        }];
    }
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.margin2(Css.px(10), Css.px(0)),
                tl: /* [] */0
              })
        }, React.createElement(ContextMenu.make, {
              options: Belt_Array.concatMany([
                    [{
                        NAME: "Option",
                        VAL: {
                          label: "Copy URL",
                          onClick: (function (param) {
                              CopyTextToClipboard(Router.Link.getSimple(undefined));
                              
                            })
                        }
                      }],
                    [{
                        NAME: "Option",
                        VAL: {
                          label: "Add Name Mapping" + (
                            match === "Available" ? "" : " ⚡️"
                          ),
                          onClick: (function (param) {
                              if (!AvoLimits.NameMapping.isAvailable(workspace)) {
                                return Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "BillingPrompt",
                                              VAL: "NameMapping"
                                            }
                                          });
                              }
                              var ruleId = Shortid();
                              return Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          [[
                                              {
                                                NAME: "AddRule",
                                                VAL: [
                                                  ruleId,
                                                  {
                                                    NAME: "PropertyRef",
                                                    VAL: [
                                                      $$event.id,
                                                      property.id
                                                    ]
                                                  },
                                                  {
                                                    NAME: "NameMapping",
                                                    VAL: {
                                                      destination: undefined,
                                                      name: undefined
                                                    }
                                                  }
                                                ]
                                              },
                                              {
                                                eventId: $$event.id,
                                                eventQuery: $$event.id,
                                                propertyId: property.id,
                                                propertyQuery: property.id
                                              }
                                            ]]
                                        ]);
                            })
                        }
                      }],
                    tmp$1,
                    Models.Role.canEdit(role) ? [
                        {
                          NAME: "Option",
                          VAL: {
                            label: "Edit Property",
                            onClick: (function (param) {
                                return Router.Schema.pushDrawerItem(undefined, {
                                            NAME: "property",
                                            VAL: [
                                              property.id,
                                              undefined
                                            ]
                                          });
                              })
                          }
                        },
                        {
                          NAME: "Option",
                          VAL: {
                            label: "Remove Property",
                            onClick: (function (param) {
                                var match = property.sendAs;
                                if (match === 0 && !window.confirm("This will delete \"" + property.name + "\" from all events. Are you sure you want to continue?")) {
                                  return ;
                                } else {
                                  return Curry.app(sendActions, [
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              (function (branch) {
                                                  offerArchivingPropertyIfUnused(model, property.id, $$event.id, schemaGroup, sendActions);
                                                  return AnalyticsRe.propertyRemoved(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AvoUtils.getAnalyticsPropertyGroup(model, property), $$event.id, $$event.name, "PropertyDetails", NamedBranch.getId(branch), schemaGroup.schemaId);
                                                }),
                                              undefined,
                                              [[
                                                  {
                                                    NAME: "RemovePropertyRefV2",
                                                    VAL: [
                                                      $$event.id,
                                                      property.id
                                                    ]
                                                  },
                                                  {
                                                    eventId: $$event.id,
                                                    eventQuery: $$event.id,
                                                    propertyId: property.id,
                                                    propertyQuery: property.id
                                                  }
                                                ]]
                                            ]);
                                }
                              })
                          }
                        }
                      ] : []
                  ])
            }));
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              ref: containerRef,
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("flexStart"),
                      tl: {
                        hd: Css.justifyContent("spaceBetween"),
                        tl: {
                          hd: Css.borderBottomWidth(Css.px(1)),
                          tl: {
                            hd: Css.borderBottomColor(Styles.Color.grey30),
                            tl: {
                              hd: Css.borderBottomStyle("solid"),
                              tl: {
                                hd: Css.width(Css.pct(100)),
                                tl: {
                                  hd: Css.paddingBottom(Css.px(8)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth({
                              NAME: "substract",
                              VAL: [
                                Css.pct(100),
                                Css.px(24)
                              ]
                            }),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.flexGrow(1.0),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, notBuiltIn && isSentToIntercom && !isGroupProperty ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("relative"),
                              tl: /* [] */0
                            })
                      }, React.createElement(Hover.make, {
                            children: (function (isHover) {
                                return React.createElement("div", undefined, React.createElement("button", {
                                                className: Curry._1(Css.merge, {
                                                      hd: Curry._1(Css.style, Styles.button),
                                                      tl: {
                                                        hd: Curry._1(Css.style, {
                                                              hd: Css.opacity(isWhitelisted ? 1.0 : (
                                                                      disabled ? 0.0 : 0.5
                                                                    )),
                                                              tl: {
                                                                hd: Css.cursor(disabled ? "default" : "pointer"),
                                                                tl: {
                                                                  hd: Css.filter({
                                                                        hd: {
                                                                          NAME: "grayscale",
                                                                          VAL: isWhitelisted ? 0.0 : 100.0
                                                                        },
                                                                        tl: /* [] */0
                                                                      }),
                                                                  tl: {
                                                                    hd: Css.marginRight(Css.px(2)),
                                                                    tl: {
                                                                      hd: Css.marginLeft(Css.px(-6)),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                disabled: disabled,
                                                onClick: (function (param) {
                                                    return Curry.app(sendActions, [
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                [[
                                                                    isWhitelisted ? ({
                                                                          NAME: "RemovePropertyFromWhitelist",
                                                                          VAL: [
                                                                            $$event.id,
                                                                            property.id,
                                                                            "Intercom"
                                                                          ]
                                                                        }) : ({
                                                                          NAME: "AddPropertyToWhitelist",
                                                                          VAL: [
                                                                            $$event.id,
                                                                            property.id,
                                                                            "Intercom"
                                                                          ]
                                                                        }),
                                                                    {
                                                                      eventId: $$event.id,
                                                                      eventQuery: $$event.id,
                                                                      propertyId: property.id,
                                                                      propertyQuery: property.id
                                                                    }
                                                                  ]]
                                                              ]);
                                                  })
                                              }, React.createElement(Icons.Destinations.Intercom.make, {
                                                    size: 16
                                                  })), isHover ? React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.position("absolute"),
                                                        tl: {
                                                          hd: Css.width(Css.px(270)),
                                                          tl: {
                                                            hd: Css.left(Css.px(5)),
                                                            tl: {
                                                              hd: Css.bottom(Css.pct(130)),
                                                              tl: {
                                                                hd: Css.backgroundColor(isWhitelisted ? Css.hex("286efa") : Styles.Color.grey70),
                                                                tl: {
                                                                  hd: Css.borderRadius(Styles.Border.radius),
                                                                  tl: {
                                                                    hd: Css.color(Styles.Color.white),
                                                                    tl: {
                                                                      hd: Css.fontSize(Styles.FontSize.small),
                                                                      tl: {
                                                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                        tl: {
                                                                          hd: Css.padding2(Css.px(5), Css.px(10)),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      })
                                                }, React.createElement("div", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.position("absolute"),
                                                            tl: {
                                                              hd: Css.left(Css.px(5)),
                                                              tl: {
                                                                hd: Css.bottom(Css.px(-10)),
                                                                tl: {
                                                                  hd: Css.border(Css.px(5), "solid", "transparent"),
                                                                  tl: {
                                                                    hd: Css.borderTop(Css.px(5), "solid", isWhitelisted ? Css.hex("286efa") : Styles.Color.grey70),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          })
                                                    }), isUserProperty ? null : React.createElement("div", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.fontSize(Styles.FontSize.tiny),
                                                              tl: /* [] */0
                                                            })
                                                      }, "Intercom accepts 5 properties with each event."), isUserProperty ? "All user properties are sent to Intercom." : (
                                                    isWhitelisted ? "This one is currently sent to Intercom." : "This one is currently not sent to Intercom."
                                                  )) : null);
                              }),
                            key: property.id
                          })) : null, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100)),
                            tl: /* [] */0
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.justifyContent("spaceBetween"),
                                    tl: {
                                      hd: Css.padding4(Css.px(10), Css.px(4), Css.px(0), Css.px(0)),
                                      tl: {
                                        hd: Css.color(Styles.Color.grey90),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.width(Css.pct(100)),
                                    tl: /* [] */0
                                  })
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.flexWrap("wrap"),
                                            tl: {
                                              hd: Css.position("relative"),
                                              tl: {
                                                hd: Css.minWidth(Css.px(0)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      })
                                }, Belt_List.toArray(Belt_List.map(property.analyticsTools, (function (destination) {
                                            return React.createElement("div", {
                                                        key: AvoConfig.analyticsToolsLabel(destination),
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.marginRight(Css.px(5)),
                                                              tl: /* [] */0
                                                            }),
                                                        title: "Sent to " + AvoConfig.analyticsToolsLabel(destination)
                                                      }, React.createElement(Icons.Destinations.make, {
                                                            destination: destination,
                                                            size: 12
                                                          }));
                                          }))), property.auto ? Belt_List.toArray(Belt_List.map(property.devPlatforms, (function (platform) {
                                              return React.createElement("div", {
                                                          key: AvoConfig.devPlatformLabel(platform),
                                                          className: Curry._1(Css.style, {
                                                                hd: Css.marginRight(Css.px(5)),
                                                                tl: /* [] */0
                                                              })
                                                        }, platform === "ReactNative" ? React.createElement("div", {
                                                                title: "Sent from all React Native sources"
                                                              }, React.createElement(IconMobile.make, {
                                                                    size: 12
                                                                  })) : (
                                                            platform === "DotNet" ? React.createElement("div", {
                                                                    title: "Sent from all .NET sources"
                                                                  }, React.createElement(IconServer.make, {
                                                                        size: 12
                                                                      })) : (
                                                                platform === "Android" ? React.createElement("div", {
                                                                        title: "Sent from all Android sources"
                                                                      }, React.createElement(IconMobile.make, {
                                                                            size: 12
                                                                          })) : (
                                                                    platform === "Python" ? React.createElement("div", {
                                                                            title: "Sent from all Python sources"
                                                                          }, React.createElement(IconServer.make, {
                                                                                size: 12
                                                                              })) : (
                                                                        platform === "JsonSchema" ? React.createElement("div", {
                                                                                title: "Included in all JSON Schema sources"
                                                                              }, React.createElement(IconWeb.make, {
                                                                                    size: 12
                                                                                  })) : (
                                                                            platform === "Gtm" ? React.createElement("div", {
                                                                                    title: "Included in all Google Tag Manager sources"
                                                                                  }, React.createElement(IconWeb.make, {
                                                                                        size: 12
                                                                                      })) : (
                                                                                platform === "IOS" ? React.createElement("div", {
                                                                                        title: "Sent from all iOS sources"
                                                                                      }, React.createElement(IconMobile.make, {
                                                                                            size: 12
                                                                                          })) : (
                                                                                    platform === "Php" ? React.createElement("div", {
                                                                                            title: "Sent from all PHP sources"
                                                                                          }, React.createElement(IconServer.make, {
                                                                                                size: 12
                                                                                              })) : (
                                                                                        platform === "Web" ? React.createElement("div", {
                                                                                                title: "Sent from all web sources"
                                                                                              }, React.createElement(IconWeb.make, {
                                                                                                    size: 12
                                                                                                  })) : (
                                                                                            platform === "MacOS" ? React.createElement("div", {
                                                                                                    title: "Sent from all macOS sources"
                                                                                                  }, React.createElement(IconWeb.make, {
                                                                                                        size: 12
                                                                                                      })) : (
                                                                                                platform === "Expo" ? React.createElement("div", {
                                                                                                        title: "Sent from all Expo sources"
                                                                                                      }, React.createElement(IconExpo.make, {
                                                                                                            size: 12
                                                                                                          })) : (
                                                                                                    platform === "Java" ? React.createElement("div", {
                                                                                                            title: "Sent from all Java sources"
                                                                                                          }, React.createElement(IconServer.make, {
                                                                                                                size: 12
                                                                                                              })) : (
                                                                                                        platform === "Node" ? React.createElement("div", {
                                                                                                                title: "Sent from all Node sources"
                                                                                                              }, React.createElement(IconServer.make, {
                                                                                                                    size: 12
                                                                                                                  })) : (
                                                                                                            platform === "Ruby" ? React.createElement("div", {
                                                                                                                    title: "Sent from all Ruby sources"
                                                                                                                  }, React.createElement(IconServer.make, {
                                                                                                                        size: 12
                                                                                                                      })) : (
                                                                                                                platform === "Unity" ? React.createElement("div", {
                                                                                                                        title: "Sent from all Unity sources"
                                                                                                                      }, React.createElement(IconMobile.make, {
                                                                                                                            size: 12
                                                                                                                          })) : null
                                                                                                              )
                                                                                                          )
                                                                                                      )
                                                                                                  )
                                                                                              )
                                                                                          )
                                                                                      )
                                                                                  )
                                                                              )
                                                                          )
                                                                      )
                                                                  )
                                                              )
                                                          ));
                                            }))) : null, Belt_Option.mapWithDefault(hasGroupType, null, (function (groupTypeId) {
                                        return React.createElement(React.Fragment, undefined, React.createElement(Property$GroupTypePill, {
                                                        groupTypeName: Belt_Option.getWithDefault(StateUtils.getGroupTypeName(groupTypeId, model), groupTypeId)
                                                      }), React.createElement(Spacer.make, {
                                                        width: 6
                                                      }));
                                      })), React.createElement(Link.make, {
                                      path: property.builtIn === "NotBuiltIn" ? Router.Link.addDrawerItem(undefined, {
                                              NAME: "property",
                                              VAL: [
                                                property.id,
                                                undefined
                                              ]
                                            }) : "",
                                      className: Curry._1(Css.style, {
                                            hd: Css.marginRight(Css.px(4)),
                                            tl: {
                                              hd: Css.color(Styles.Color.grey90),
                                              tl: {
                                                hd: Css.hover({
                                                      hd: Css.color(Styles.Color.blue),
                                                      tl: /* [] */0
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }
                                          }),
                                      children: React.createElement($$Text.make, {
                                            element: "Span",
                                            size: "Medium",
                                            weight: "Semi",
                                            singleLine: true,
                                            children: property.name
                                          })
                                    }), Belt_Option.mapWithDefault(property.uniqueName, null, (function (uniqueName) {
                                        return React.createElement("div", {
                                                    key: uniqueName,
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.paddingRight(Css.px(10)),
                                                          tl: {
                                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                                            tl: {
                                                              hd: Css.fontSize(Styles.FontSize.small),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        })
                                                  }, "(" + uniqueName + ")");
                                      })), React.createElement(PropertyType.TypeAnnotation.make, {
                                      property: property,
                                      model: model,
                                      language: "js",
                                      event: undefined
                                    }), property.operation !== "Set" && Belt_List.someU(eventDestinations, (function (destination) {
                                        return Caml_obj.caml_equal(destination.type_, "Snowplow");
                                      })) ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                            width: 4
                                          }), React.createElement(PropertyType.TypePill.make, {
                                            children: AvoConfig.operationLabel(property.operation)
                                          })) : null, pinnedValues ? (
                                    property.type_ === "object" ? React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.fontSize(Styles.FontSize.small),
                                                  tl: {
                                                    hd: Css.color(Styles.Color.grey90),
                                                    tl: {
                                                      hd: Css.width(Css.pct(100)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                })
                                          }, React.createElement($$Text.make, {
                                                element: "Span",
                                                size: "Small",
                                                color: Styles.Color.grey80,
                                                children: "Pinned:"
                                              }), React.createElement(Spacer.make, {
                                                display: "inlineBlock",
                                                width: 4
                                              }), React.createElement("ul", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.margin(Css.px(0)),
                                                      tl: {
                                                        hd: Css.paddingLeft(Css.px(16)),
                                                        tl: /* [] */0
                                                      }
                                                    })
                                              }, Belt_List.toArray(Belt_List.map(pinnedValues, (function (pinnedValue) {
                                                          return React.createElement("li", undefined, React.createElement($$Text.make, {
                                                                          element: "Span",
                                                                          size: "Small",
                                                                          color: Styles.Color.grey80,
                                                                          children: pinnedValue
                                                                        }));
                                                        }))))) : React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                                display: "inlineBlock",
                                                width: 4
                                              }), React.createElement($$Text.make, {
                                                element: "Span",
                                                size: "Small",
                                                color: Styles.Color.grey80,
                                                children: "Pinned to"
                                              }), React.createElement(Spacer.make, {
                                                display: "inlineBlock",
                                                width: 4
                                              }), React.createElement($$Text.make, {
                                                element: "Span",
                                                size: "Small",
                                                color: Styles.Color.grey80,
                                                children: Belt_List.toArray(pinnedValues).join(", ")
                                              }))
                                  ) : React.createElement(PropertyAbsenceConfig.make, {
                                        model: model,
                                        property: property,
                                        event: $$event,
                                        sendActions: sendActions,
                                        sources: model.sources,
                                        viewerCanEdit: Models.Role.canEdit(role)
                                      }), property.validations === /* [] */0 ? null : React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                            width: 4
                                          }), React.createElement(PropertyValidations.make, {
                                            validations: property.validations
                                          })), unnecessary ? React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.color(Styles.Color.orangeSecondary),
                                              tl: {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: {
                                                  hd: Css.fontSize(Styles.FontSize.small),
                                                  tl: {
                                                    hd: Css.paddingLeft(Css.px(10)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            })
                                      }, "Unnecessary property, already in bundle") : null, isObject && Belt_List.length(unsupportedDestinations) > 0 ? React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.color(Styles.Color.orangeSecondary),
                                              tl: {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: {
                                                  hd: Css.fontSize(Styles.FontSize.small),
                                                  tl: {
                                                    hd: Css.paddingLeft(Css.px(10)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            })
                                      }, Belt_List.toArray(Belt_List.map(unsupportedDestinations, AvoConfig.analyticsToolsLabel)).join(", ") + "does not support objects as properties.") : null, isSetOnce && unsupportedDestinationLabels.length !== 0 ? React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.color(Styles.Color.orangeSecondary),
                                              tl: {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: {
                                                  hd: Css.fontSize(Styles.FontSize.small),
                                                  tl: {
                                                    hd: Css.paddingLeft(Css.px(10)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            })
                                      }, unsupportedDestinationLabels.join(", ") + " does not support set-once properties.") : null), property.description === "" ? React.createElement($$Text.make, {
                                    size: "Small",
                                    color: Styles.Color.grey50,
                                    children: "No description"
                                  }) : React.createElement($$Text.make, {
                                    size: "Small",
                                    lines: 3,
                                    color: Styles.Color.grey80,
                                    children: property.description
                                  }))), React.createElement(Link.make, {
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "property",
                                VAL: [
                                  property.id,
                                  undefined
                                ]
                              }),
                          className: Curry._1(Css.style, {
                                hd: Css.width(Css.pct(100)),
                                tl: /* [] */0
                              }),
                          children: React.createElement("div", undefined, Belt_List.toArray(Belt_List.map(Belt_List.keepMapU(model.rules, (function (rule) {
                                              var match = rule.item;
                                              var match$1 = rule.definition;
                                              if (typeof match !== "object") {
                                                return ;
                                              }
                                              if (match.NAME !== "Property") {
                                                return ;
                                              }
                                              if (typeof match$1 !== "object") {
                                                return ;
                                              }
                                              if (match$1.NAME !== "NameMapping") {
                                                return ;
                                              }
                                              var nameMapping = match$1.VAL;
                                              if (nameMapping.destination !== undefined && nameMapping.name !== undefined && match.VAL === property.id) {
                                                return nameMapping;
                                              }
                                              
                                            })), (function (nameMapping) {
                                          var match = nameMapping.destination;
                                          var match$1 = nameMapping.destination;
                                          return React.createElement("div", {
                                                      key: Belt_Option.getWithDefault(nameMapping.name, "no-name") + (
                                                        match !== undefined ? (
                                                            typeof match === "object" ? match.VAL : "all-destinations"
                                                          ) : "no-destinations"
                                                      ),
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.display("flex"),
                                                            tl: {
                                                              hd: Css.padding4(Css.px(6), Css.px(0), Css.px(5), Css.px(5)),
                                                              tl: {
                                                                hd: Css.width(Css.pct(100)),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          })
                                                    }, React.createElement("div", {
                                                          className: Curry._1(Css.style, {
                                                                hd: Css.marginTop(Css.px(0)),
                                                                tl: {
                                                                  hd: Css.marginRight(Css.px(5)),
                                                                  tl: /* [] */0
                                                                }
                                                              })
                                                        }, React.createElement(IconArrowDownEast.make, {
                                                              size: 12
                                                            })), React.createElement("div", {
                                                          className: Curry._1(Css.style, {
                                                                hd: Css.margin2(Css.px(0), Css.px(0)),
                                                                tl: {
                                                                  hd: Css.fontSize(Styles.FontSize.small),
                                                                  tl: {
                                                                    hd: Css.display("flex"),
                                                                    tl: {
                                                                      hd: Css.alignItems("center"),
                                                                      tl: {
                                                                        hd: Css.width(Css.pct(100)),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              })
                                                        }, React.createElement($$Text.make, {
                                                              size: "Small",
                                                              color: Styles.Color.grey80,
                                                              children: "Send as"
                                                            }), React.createElement(Spacer.make, {
                                                              width: 3
                                                            }), React.createElement($$Text.make, {
                                                              size: "Small",
                                                              weight: "Semi",
                                                              color: Styles.Color.grey80,
                                                              children: Belt_Option.getWithDefault(nameMapping.name, "")
                                                            }), React.createElement(Spacer.make, {
                                                              width: 3
                                                            }), React.createElement($$Text.make, {
                                                              size: "Small",
                                                              color: Styles.Color.grey80,
                                                              children: "to"
                                                            }), React.createElement(Spacer.make, {
                                                              width: 3
                                                            }), React.createElement($$Text.make, {
                                                              size: "Small",
                                                              weight: "Semi",
                                                              color: Styles.Color.grey80,
                                                              children: match$1 !== undefined ? (
                                                                  typeof match$1 === "object" ? Belt_Option.mapWithDefault(StateUtils.getDestinationById(match$1.VAL, model), "No Destinations", StateUtils.getDestinationName) : "All Destinations"
                                                                ) : "No Destinations"
                                                            }), React.createElement(Spacer.make, {
                                                              width: 3
                                                            }), React.createElement($$Text.make, {
                                                              size: "Small",
                                                              color: Styles.Color.grey80,
                                                              children: "on all events"
                                                            })));
                                        }))))
                        }), Belt_List.toArray(Belt_List.map(Belt_List.keepU(model.rules, (function (rule) {
                                    var match = rule.item;
                                    var match$1 = rule.definition;
                                    if (typeof match !== "object") {
                                      return false;
                                    }
                                    if (match.NAME !== "PropertyRef") {
                                      return false;
                                    }
                                    if (typeof match$1 !== "object") {
                                      return false;
                                    }
                                    if (match$1.NAME !== "NameMapping") {
                                      return false;
                                    }
                                    var destination = match$1.VAL.destination;
                                    var match$2 = match.VAL;
                                    var destinationMissing;
                                    if (destination !== undefined && typeof destination === "object" && destination.NAME === "Destination") {
                                      var id = destination.VAL;
                                      destinationMissing = Belt_List.every(model.destinations, (function (destination) {
                                              return destination.id !== id;
                                            }));
                                    } else {
                                      destinationMissing = false;
                                    }
                                    if (match$2[0] === $$event.id && match$2[1] === property.id) {
                                      return !destinationMissing;
                                    } else {
                                      return false;
                                    }
                                  })), (function (rule) {
                                return React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.padding4(Css.px(0), Css.px(0), Css.px(5), Css.px(5)),
                                                    tl: {
                                                      hd: Css.width(Css.pct(100)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                })
                                          }, React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.marginTop(Css.px(6)),
                                                      tl: {
                                                        hd: Css.marginRight(Css.px(5)),
                                                        tl: /* [] */0
                                                      }
                                                    })
                                              }, React.createElement(IconArrowDownEast.make, {
                                                    size: 12
                                                  })), React.createElement(PropertyRule.make, {
                                                rule: rule,
                                                model: model,
                                                sendActions: sendActions,
                                                property: property,
                                                context: {
                                                  eventId: $$event.id,
                                                  eventQuery: $$event.id,
                                                  propertyId: property.id,
                                                  propertyQuery: property.id
                                                },
                                                schemaGroup: schemaGroup,
                                                role: role
                                              }));
                              }))))), tmp);
}

var make = Property;

exports.GroupTypePill = GroupTypePill;
exports.offerArchivingPropertyIfUnused = offerArchivingPropertyIfUnused;
exports.make = make;
/* Css Not a pure module */
