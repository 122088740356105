// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Int32 = require("rescript/lib/js/int32.js");
var Models = require("../../app/src/Models.bs.js");
var DateFns = require("../../app/src/DateFns.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var AnalyticsRe = require("../../app/src/analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Js_null_undefined = require("rescript/lib/js/js_null_undefined.js");
var InspectorViewModel = require("../../app/src/inspector/InspectorViewModel.bs.js");
var Is_future = require("date-fns/is_future");

function printPlanName(planName) {
  switch (planName) {
    case /* Empty */0 :
    case /* Free */1 :
    case /* FreeV2 */2 :
        return "Free";
    case /* Scholarship */3 :
        return "Scholarship";
    case /* Growth */4 :
    case /* GrowthV2 */5 :
        return "Growth";
    case /* Enterprise */6 :
        return "Enterprise";
    case /* Starter */7 :
        return "Starter";
    case /* TeamV2 */8 :
    case /* TeamV2Annual */9 :
        return "Team";
    
  }
}

function printExplicitPlanName(planName) {
  switch (planName) {
    case /* Empty */0 :
        return "Legacy";
    case /* Free */1 :
        return "Free";
    case /* FreeV2 */2 :
        return "FreeV2";
    case /* Scholarship */3 :
        return "Scholarship";
    case /* Growth */4 :
        return "Growth";
    case /* GrowthV2 */5 :
        return "GrowthV2";
    case /* Enterprise */6 :
        return "Enterprise";
    case /* Starter */7 :
        return "Starter";
    case /* TeamV2 */8 :
        return "TeamV2";
    case /* TeamV2Annual */9 :
        return "TeamV2Annual";
    
  }
}

function printMaxThreshold(maxSources) {
  if (typeof maxSources === "object") {
    return maxSources.VAL.toString();
  } else {
    return "Unlimited";
  }
}

function featureSettingToJs(param) {
  return param + 0 | 0;
}

function featureSettingFromJs(param) {
  if (param <= 3 && 0 <= param) {
    return param - 0 | 0;
  }
  
}

function printSettingName(setting) {
  switch (setting) {
    case /* ProtectedMainBranch */0 :
        return "ProtectedMainBranch";
    case /* RequireAdminApproval */1 :
        return "RequireAdminApproval";
    case /* NumRequiredApprovals */2 :
        return "NumRequiredApprovals";
    case /* ForceBranchAudit */3 :
        return "ForceBranchAudit";
    
  }
}

function isV2Plan(workspace) {
  var match = workspace.plan.name;
  switch (match) {
    case /* FreeV2 */2 :
    case /* GrowthV2 */5 :
    case /* Starter */7 :
        return true;
    case /* Empty */0 :
    case /* Free */1 :
    case /* Scholarship */3 :
    case /* Growth */4 :
    case /* Enterprise */6 :
    case /* TeamV2 */8 :
    case /* TeamV2Annual */9 :
        return false;
    
  }
}

function parseSettings(schema) {
  var maybeSettingsJson = schema.settings;
  var decodeSetting = function (json, setting, decoder) {
    var partial_arg = printSettingName(setting);
    return Json_decode.optional((function (param) {
                  return Json_decode.field(partial_arg, decoder, param);
                }), json);
  };
  return Belt_Option.getWithDefault(Belt_Option.map((maybeSettingsJson == null) ? undefined : Caml_option.some(maybeSettingsJson), (function (json) {
                    var protectedMainBranch = Belt_Option.getWithDefault(decodeSetting(json, /* ProtectedMainBranch */0, Json_decode.bool), false);
                    var forceBranchAudit = Belt_Option.getWithDefault(decodeSetting(json, /* ForceBranchAudit */3, Json_decode.bool), false);
                    return {
                            protectedMainBranch: protectedMainBranch,
                            requireAdminApproval: protectedMainBranch && Belt_Option.getWithDefault(decodeSetting(json, /* RequireAdminApproval */1, Json_decode.bool), false),
                            numRequiredApprovals: protectedMainBranch ? Belt_Option.getWithDefault(decodeSetting(json, /* NumRequiredApprovals */2, Json_decode.$$int), 1) : 1,
                            forceBranchAudit: forceBranchAudit
                          };
                  })), {
              protectedMainBranch: false,
              requireAdminApproval: false,
              numRequiredApprovals: 1,
              forceBranchAudit: false
            });
}

function featureSettingToAnalyticsSetting(setting) {
  switch (setting) {
    case /* ProtectedMainBranch */0 :
        return "ProtectedMainBranch";
    case /* RequireAdminApproval */1 :
        return "RequireAdminApproval";
    case /* NumRequiredApprovals */2 :
        return "NumberOfRequiredApprovals";
    case /* ForceBranchAudit */3 :
        return "ForceBranchAudit";
    
  }
}

function toggleSetting(workspace, firebase, setting, schemaGroup) {
  var settingEnabled;
  switch (setting) {
    case /* ProtectedMainBranch */0 :
        settingEnabled = workspace.settings.protectedMainBranch;
        break;
    case /* RequireAdminApproval */1 :
        settingEnabled = workspace.settings.requireAdminApproval;
        break;
    case /* NumRequiredApprovals */2 :
        settingEnabled = Pervasives.failwith("NumRequiredApprovals has integer value and can't be toggled.");
        break;
    case /* ForceBranchAudit */3 :
        settingEnabled = workspace.settings.forceBranchAudit;
        break;
    
  }
  var toggledSetting = !settingEnabled;
  var toggledSettingJson = Json_encode.object_({
        hd: [
          printSettingName(setting),
          toggledSetting
        ],
        tl: /* [] */0
      });
  firebase.firestore().collection("schemas").doc(workspace.id).set({
        settings: toggledSettingJson
      }, {"merge": true});
  AnalyticsRe.workspaceSettingUpdated(schemaGroup, toggledSetting ? "On" : "Off", featureSettingToAnalyticsSetting(setting), undefined, schemaGroup.branchId, schemaGroup.schemaId, workspace.id, workspace.settings.protectedMainBranch ? "ProtectedMainBranch" : "None", undefined, workspace.settings.protectedMainBranch ? (
          workspace.settings.requireAdminApproval ? "Admin" : "Editor"
        ) : "None", workspace.settings.numRequiredApprovals);
  
}

function privateUpdateIntSetting(workspace, firebase, setting, value, schemaGroup) {
  var updatedSettingJson = Json_encode.object_({
        hd: [
          printSettingName(setting),
          value
        ],
        tl: /* [] */0
      });
  firebase.firestore().collection("schemas").doc(workspace.id).set({
        settings: updatedSettingJson
      }, {"merge": true});
  AnalyticsRe.workspaceSettingUpdated(schemaGroup, undefined, featureSettingToAnalyticsSetting(setting), value, schemaGroup.branchId, schemaGroup.schemaId, workspace.id, workspace.settings.protectedMainBranch ? "ProtectedMainBranch" : "None", undefined, workspace.settings.protectedMainBranch ? (
          workspace.settings.requireAdminApproval ? "Admin" : "Editor"
        ) : "None", workspace.settings.numRequiredApprovals);
  
}

function updateNumRequiredApprovals(workspace, firebase, value, schemaGroup) {
  return privateUpdateIntSetting(workspace, firebase, /* NumRequiredApprovals */2, value, schemaGroup);
}

var emptyPlan = {
  name: /* Empty */0,
  maxSources: "Unlimited",
  maxDestinations: "Unlimited",
  maxIntegrations: "Unlimited",
  maxUsers: "Unlimited",
  usersIncluded: "Unlimited",
  basePricePerMonth: 0.0,
  costPerExtraSeat: 0.0,
  countsViewersAsUsers: false,
  commentOnlyMembers: /* CountAsEditors */1,
  editors: true,
  auditLog: true,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 1
  },
  collaboration: true,
  configEventSourcesAndDestinations: true,
  sso: true,
  canProtectMainBranch: true,
  advancedProtectedMainBranch: true,
  approvalWorkflow: true,
  pinnedProperties: true,
  nameMapping: true,
  propertyAbsenceByEventAndSource: true,
  propertyMatchesBySource: false,
  activityLog: true,
  inspectorLookback: "Last30Days",
  maxCodegenEvents: "Unlimited",
  triggers: true,
  discrepancyConfig: true,
  forcePreferredCasing: true,
  canDuplicateEvents: true,
  forceBranchAudit: false,
  expandedBranchAudit: false,
  closedBranches: false
};

var empty_settings = {
  protectedMainBranch: false,
  requireAdminApproval: false,
  numRequiredApprovals: 1,
  forceBranchAudit: false
};

var empty_serviceAccounts = [];

var empty = {
  id: "",
  name: "",
  createdAt: undefined,
  plan: emptyPlan,
  trial: undefined,
  avoSchemaVersion: 2,
  members: /* [] */0,
  authMethod: "Default",
  settings: empty_settings,
  serviceAccounts: empty_serviceAccounts,
  lastSeenCodegen: "unset"
};

var secretMenu = {
  hd: /* Free */1,
  tl: {
    hd: /* FreeV2 */2,
    tl: {
      hd: /* Starter */7,
      tl: {
        hd: /* TeamV2 */8,
        tl: {
          hd: /* Scholarship */3,
          tl: {
            hd: /* Growth */4,
            tl: {
              hd: /* GrowthV2 */5,
              tl: {
                hd: /* Enterprise */6,
                tl: /* [] */0
              }
            }
          }
        }
      }
    }
  }
};

function getPlanIndex(plan) {
  return Belt_Option.getWithDefault(Belt_List.head(Belt_List.flatten(Belt_List.mapWithIndexU(secretMenu, (function (index, item) {
                            if (plan.name === item) {
                              return {
                                      hd: index,
                                      tl: /* [] */0
                                    };
                            } else {
                              return /* [] */0;
                            }
                          })))), -1);
}

var free = {
  name: /* Free */1,
  maxSources: {
    NAME: "Limited",
    VAL: 3
  },
  maxDestinations: {
    NAME: "Limited",
    VAL: 1
  },
  maxIntegrations: {
    NAME: "Limited",
    VAL: 1
  },
  maxUsers: {
    NAME: "Limited",
    VAL: 5
  },
  usersIncluded: {
    NAME: "Limited",
    VAL: 5
  },
  basePricePerMonth: 0.0,
  costPerExtraSeat: 0.0,
  countsViewersAsUsers: true,
  commentOnlyMembers: /* NotAvailable */0,
  editors: true,
  auditLog: false,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 1
  },
  collaboration: false,
  configEventSourcesAndDestinations: false,
  sso: false,
  canProtectMainBranch: false,
  advancedProtectedMainBranch: false,
  approvalWorkflow: false,
  pinnedProperties: false,
  nameMapping: false,
  propertyAbsenceByEventAndSource: false,
  propertyMatchesBySource: false,
  activityLog: true,
  inspectorLookback: "Last1Hour",
  maxCodegenEvents: "Unlimited",
  triggers: false,
  discrepancyConfig: false,
  forcePreferredCasing: false,
  canDuplicateEvents: false,
  forceBranchAudit: false,
  expandedBranchAudit: false,
  closedBranches: false
};

var freeV2 = {
  name: /* FreeV2 */2,
  maxSources: "Unlimited",
  maxDestinations: "Unlimited",
  maxIntegrations: {
    NAME: "Limited",
    VAL: 1
  },
  maxUsers: {
    NAME: "Limited",
    VAL: 2
  },
  usersIncluded: {
    NAME: "Limited",
    VAL: 2
  },
  basePricePerMonth: 0.0,
  costPerExtraSeat: 0.0,
  countsViewersAsUsers: false,
  commentOnlyMembers: /* NotAvailable */0,
  editors: false,
  auditLog: false,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 1
  },
  collaboration: false,
  configEventSourcesAndDestinations: true,
  sso: false,
  canProtectMainBranch: false,
  advancedProtectedMainBranch: false,
  approvalWorkflow: false,
  pinnedProperties: false,
  nameMapping: false,
  propertyAbsenceByEventAndSource: true,
  propertyMatchesBySource: false,
  activityLog: false,
  inspectorLookback: "Last1Hour",
  maxCodegenEvents: "Unlimited",
  triggers: false,
  discrepancyConfig: false,
  forcePreferredCasing: false,
  canDuplicateEvents: false,
  forceBranchAudit: false,
  expandedBranchAudit: false,
  closedBranches: false
};

var starter = {
  name: /* Starter */7,
  maxSources: "Unlimited",
  maxDestinations: "Unlimited",
  maxIntegrations: {
    NAME: "Limited",
    VAL: 2
  },
  maxUsers: {
    NAME: "Limited",
    VAL: 30
  },
  usersIncluded: {
    NAME: "Limited",
    VAL: 5
  },
  basePricePerMonth: 300.0,
  costPerExtraSeat: 40.0,
  countsViewersAsUsers: false,
  commentOnlyMembers: {
    TAG: /* AdditionalCost */0,
    _0: 20
  },
  editors: true,
  auditLog: false,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 1
  },
  collaboration: true,
  configEventSourcesAndDestinations: true,
  sso: false,
  canProtectMainBranch: false,
  advancedProtectedMainBranch: false,
  approvalWorkflow: false,
  pinnedProperties: false,
  nameMapping: false,
  propertyAbsenceByEventAndSource: true,
  propertyMatchesBySource: true,
  activityLog: false,
  inspectorLookback: "Last24Hours",
  maxCodegenEvents: "Unlimited",
  triggers: true,
  discrepancyConfig: false,
  forcePreferredCasing: false,
  canDuplicateEvents: false,
  forceBranchAudit: false,
  expandedBranchAudit: false,
  closedBranches: false
};

var scholarship = {
  name: /* Scholarship */3,
  maxSources: {
    NAME: "Limited",
    VAL: 4
  },
  maxDestinations: {
    NAME: "Limited",
    VAL: 2
  },
  maxIntegrations: {
    NAME: "Limited",
    VAL: 2
  },
  maxUsers: "Unlimited",
  usersIncluded: "Unlimited",
  basePricePerMonth: 0.0,
  costPerExtraSeat: 0.0,
  countsViewersAsUsers: true,
  commentOnlyMembers: /* NotAvailable */0,
  editors: true,
  auditLog: false,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 1
  },
  collaboration: true,
  configEventSourcesAndDestinations: true,
  sso: false,
  canProtectMainBranch: false,
  advancedProtectedMainBranch: false,
  approvalWorkflow: false,
  pinnedProperties: false,
  nameMapping: false,
  propertyAbsenceByEventAndSource: false,
  propertyMatchesBySource: false,
  activityLog: true,
  inspectorLookback: "Last24Hours",
  maxCodegenEvents: "Unlimited",
  triggers: true,
  discrepancyConfig: false,
  forcePreferredCasing: false,
  canDuplicateEvents: false,
  forceBranchAudit: false,
  expandedBranchAudit: false,
  closedBranches: false
};

var teamV2 = {
  name: /* TeamV2 */8,
  maxSources: "Unlimited",
  maxDestinations: "Unlimited",
  maxIntegrations: {
    NAME: "Limited",
    VAL: 5
  },
  maxUsers: {
    NAME: "Limited",
    VAL: 100
  },
  usersIncluded: {
    NAME: "Limited",
    VAL: 5
  },
  basePricePerMonth: 300.0,
  costPerExtraSeat: 60,
  countsViewersAsUsers: false,
  commentOnlyMembers: {
    TAG: /* Limited */1,
    _0: 20
  },
  editors: true,
  auditLog: false,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 3
  },
  collaboration: true,
  configEventSourcesAndDestinations: true,
  sso: false,
  canProtectMainBranch: true,
  advancedProtectedMainBranch: false,
  approvalWorkflow: true,
  pinnedProperties: true,
  nameMapping: true,
  propertyAbsenceByEventAndSource: true,
  propertyMatchesBySource: true,
  activityLog: true,
  inspectorLookback: "Last7Days",
  maxCodegenEvents: "Unlimited",
  triggers: true,
  discrepancyConfig: true,
  forcePreferredCasing: false,
  canDuplicateEvents: true,
  forceBranchAudit: false,
  expandedBranchAudit: true,
  closedBranches: true
};

var teamV2Annual_maxIntegrations = {
  NAME: "Limited",
  VAL: 5
};

var teamV2Annual_maxUsers = {
  NAME: "Limited",
  VAL: 100
};

var teamV2Annual_usersIncluded = {
  NAME: "Limited",
  VAL: 5
};

var teamV2Annual_commentOnlyMembers = {
  TAG: /* Limited */1,
  _0: 20
};

var teamV2Annual_maxServiceAccounts = {
  NAME: "Limited",
  VAL: 3
};

var teamV2Annual = {
  name: /* TeamV2Annual */9,
  maxSources: "Unlimited",
  maxDestinations: "Unlimited",
  maxIntegrations: teamV2Annual_maxIntegrations,
  maxUsers: teamV2Annual_maxUsers,
  usersIncluded: teamV2Annual_usersIncluded,
  basePricePerMonth: 250,
  costPerExtraSeat: 50,
  countsViewersAsUsers: false,
  commentOnlyMembers: teamV2Annual_commentOnlyMembers,
  editors: true,
  auditLog: false,
  maxServiceAccounts: teamV2Annual_maxServiceAccounts,
  collaboration: true,
  configEventSourcesAndDestinations: true,
  sso: false,
  canProtectMainBranch: true,
  advancedProtectedMainBranch: false,
  approvalWorkflow: true,
  pinnedProperties: true,
  nameMapping: true,
  propertyAbsenceByEventAndSource: true,
  propertyMatchesBySource: true,
  activityLog: true,
  inspectorLookback: "Last7Days",
  maxCodegenEvents: "Unlimited",
  triggers: true,
  discrepancyConfig: true,
  forcePreferredCasing: false,
  canDuplicateEvents: true,
  forceBranchAudit: false,
  expandedBranchAudit: true,
  closedBranches: true
};

var growth = {
  name: /* Growth */4,
  maxSources: {
    NAME: "Limited",
    VAL: 5
  },
  maxDestinations: {
    NAME: "Limited",
    VAL: 5
  },
  maxIntegrations: {
    NAME: "Limited",
    VAL: 5
  },
  maxUsers: "Unlimited",
  usersIncluded: "Unlimited",
  basePricePerMonth: 0.0,
  costPerExtraSeat: 0.0,
  countsViewersAsUsers: true,
  commentOnlyMembers: /* Unlimited */2,
  editors: true,
  auditLog: false,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 3
  },
  collaboration: true,
  configEventSourcesAndDestinations: true,
  sso: false,
  canProtectMainBranch: false,
  advancedProtectedMainBranch: false,
  approvalWorkflow: true,
  pinnedProperties: false,
  nameMapping: false,
  propertyAbsenceByEventAndSource: false,
  propertyMatchesBySource: true,
  activityLog: true,
  inspectorLookback: "Last7Days",
  maxCodegenEvents: "Unlimited",
  triggers: true,
  discrepancyConfig: true,
  forcePreferredCasing: false,
  canDuplicateEvents: true,
  forceBranchAudit: false,
  expandedBranchAudit: false,
  closedBranches: false
};

var growthV2 = {
  name: /* GrowthV2 */5,
  maxSources: "Unlimited",
  maxDestinations: "Unlimited",
  maxIntegrations: {
    NAME: "Limited",
    VAL: 5
  },
  maxUsers: "Unlimited",
  usersIncluded: {
    NAME: "Limited",
    VAL: 30
  },
  basePricePerMonth: 2000.0,
  costPerExtraSeat: 60.0,
  countsViewersAsUsers: false,
  commentOnlyMembers: /* Unlimited */2,
  editors: true,
  auditLog: false,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 3
  },
  collaboration: true,
  configEventSourcesAndDestinations: true,
  sso: false,
  canProtectMainBranch: true,
  advancedProtectedMainBranch: true,
  approvalWorkflow: true,
  pinnedProperties: false,
  nameMapping: false,
  propertyAbsenceByEventAndSource: true,
  propertyMatchesBySource: true,
  activityLog: true,
  inspectorLookback: "Last7Days",
  maxCodegenEvents: "Unlimited",
  triggers: true,
  discrepancyConfig: true,
  forcePreferredCasing: false,
  canDuplicateEvents: true,
  forceBranchAudit: false,
  expandedBranchAudit: true,
  closedBranches: true
};

var enterprise = {
  name: /* Enterprise */6,
  maxSources: "Unlimited",
  maxDestinations: "Unlimited",
  maxIntegrations: "Unlimited",
  maxUsers: "Unlimited",
  usersIncluded: "Unlimited",
  basePricePerMonth: 0.0,
  costPerExtraSeat: 0.0,
  countsViewersAsUsers: false,
  commentOnlyMembers: /* Unlimited */2,
  editors: true,
  auditLog: true,
  maxServiceAccounts: "Unlimited",
  collaboration: true,
  configEventSourcesAndDestinations: true,
  sso: true,
  canProtectMainBranch: true,
  advancedProtectedMainBranch: true,
  approvalWorkflow: true,
  pinnedProperties: true,
  nameMapping: true,
  propertyAbsenceByEventAndSource: true,
  propertyMatchesBySource: true,
  activityLog: true,
  inspectorLookback: "Last30Days",
  maxCodegenEvents: "Unlimited",
  triggers: true,
  discrepancyConfig: true,
  forcePreferredCasing: true,
  canDuplicateEvents: true,
  forceBranchAudit: true,
  expandedBranchAudit: true,
  closedBranches: true
};

function toJson(plan) {
  var costPerExtraSeat = plan.commentOnlyMembers;
  var tmp;
  if (typeof costPerExtraSeat === "number") {
    switch (costPerExtraSeat) {
      case /* NotAvailable */0 :
          tmp = [[
              "availability",
              "NotAvailable"
            ]];
          break;
      case /* CountAsEditors */1 :
          tmp = [[
              "availability",
              "CountAsEditors"
            ]];
          break;
      case /* Unlimited */2 :
          tmp = [[
              "availability",
              "Unlimited"
            ]];
          break;
      
    }
  } else {
    tmp = costPerExtraSeat.TAG === /* AdditionalCost */0 ? [
        [
          "availability",
          "AdditionalCost"
        ],
        [
          "costPerExtraSeat",
          costPerExtraSeat._0.toString()
        ]
      ] : [
        [
          "availability",
          "Limited"
        ],
        [
          "limit",
          String(costPerExtraSeat._0)
        ]
      ];
  }
  return Js_dict.fromArray([
              [
                "name",
                printExplicitPlanName(plan.name)
              ],
              [
                "maxSources",
                printMaxThreshold(plan.maxSources)
              ],
              [
                "maxDestinations",
                printMaxThreshold(plan.maxDestinations)
              ],
              [
                "maxIntegrations",
                printMaxThreshold(plan.maxIntegrations)
              ],
              [
                "maxUsers",
                printMaxThreshold(plan.maxUsers)
              ],
              [
                "usersIncluded",
                printMaxThreshold(plan.usersIncluded)
              ],
              [
                "basePricePerMonth",
                plan.basePricePerMonth.toString()
              ],
              [
                "costPerExtraSeat",
                plan.costPerExtraSeat.toString()
              ],
              [
                "collaboration",
                plan.collaboration
              ],
              [
                "activityLog",
                plan.activityLog
              ],
              [
                "auditLog",
                plan.auditLog
              ],
              [
                "maxServiceAccounts",
                printMaxThreshold(plan.maxServiceAccounts)
              ],
              [
                "configEventSourcesAndDestinations",
                plan.configEventSourcesAndDestinations
              ],
              [
                "sso",
                plan.sso
              ],
              [
                "canProtectMainBranch",
                plan.canProtectMainBranch
              ],
              [
                "advancedProtectedMainBranch",
                plan.advancedProtectedMainBranch
              ],
              [
                "approvalWorkflow",
                plan.approvalWorkflow
              ],
              [
                "propertyAbsenceByEventAndSource",
                plan.propertyAbsenceByEventAndSource
              ],
              [
                "propertyMatchesBySource",
                plan.propertyMatchesBySource
              ],
              [
                "inspectorLookback",
                InspectorViewModel.timeWindowToJs(plan.inspectorLookback)
              ],
              [
                "maxCodegenEvents",
                printMaxThreshold(plan.maxCodegenEvents)
              ],
              [
                "commentOnlyMembers",
                Js_dict.fromArray(tmp)
              ],
              [
                "triggers",
                plan.triggers
              ],
              [
                "discrepancyConfig",
                plan.discrepancyConfig
              ],
              [
                "forcePreferredCasing",
                plan.forcePreferredCasing
              ],
              [
                "pinnedProperties",
                plan.pinnedProperties
              ],
              [
                "nameMapping",
                plan.nameMapping
              ],
              [
                "canDuplicateEvents",
                plan.canDuplicateEvents
              ],
              [
                "forceBranchAudit",
                plan.forceBranchAudit
              ],
              [
                "expandedBranchAudit",
                plan.expandedBranchAudit
              ],
              [
                "closedBranches",
                plan.closedBranches
              ]
            ]);
}

function allPlansJson(param) {
  return [
          toJson(free),
          toJson(freeV2),
          toJson(starter),
          toJson(scholarship),
          toJson(growth),
          toJson(growthV2),
          toJson(teamV2),
          toJson(teamV2Annual),
          toJson(enterprise)
        ];
}

function nextHint(plan) {
  switch (plan.name) {
    case /* Free */1 :
    case /* FreeV2 */2 :
    case /* Scholarship */3 :
    case /* Starter */7 :
        return teamV2;
    case /* Empty */0 :
    case /* Growth */4 :
    case /* GrowthV2 */5 :
    case /* Enterprise */6 :
    case /* TeamV2 */8 :
    case /* TeamV2Annual */9 :
        return enterprise;
    
  }
}

function parseOverrideMaxThresholdField(fieldName, json) {
  var partial_arg_0 = function (json) {
    return {
            NAME: "Limited",
            VAL: Json_decode.$$int(json)
          };
  };
  var partial_arg_1 = {
    hd: (function (json) {
        var limit = Json_decode.string(json).toLowerCase();
        if (limit === "unlimited") {
          return "Unlimited";
        } else {
          return {
                  NAME: "Limited",
                  VAL: Caml_format.caml_int_of_string(limit)
                };
        }
      }),
    tl: /* [] */0
  };
  var partial_arg = {
    hd: partial_arg_0,
    tl: partial_arg_1
  };
  var partial_arg$1 = function (param) {
    return Json_decode.oneOf(partial_arg, param);
  };
  var partial_arg$2 = function (param) {
    return Json_decode.field(fieldName, partial_arg$1, param);
  };
  return Json_decode.optional(partial_arg$2, json);
}

function parseOverrideIsEnabledField(fieldName, json) {
  return Json_decode.optional((function (param) {
                return Json_decode.field(fieldName, Json_decode.bool, param);
              }), json);
}

function parseOverrideFloatField(fieldName, json) {
  return Belt_Option.flatMap(Json_decode.optional((function (param) {
                    return Json_decode.field(fieldName, (function (json) {
                                  return Number(Json_decode.string(json));
                                }), param);
                  }), json), (function (f) {
                if (Number.isNaN(f)) {
                  return ;
                } else {
                  return f;
                }
              }));
}

function parseOverrideLadderField(fieldName, stepParser, json) {
  return Belt_Option.flatMap(Json_decode.optional((function (param) {
                    return Json_decode.field(fieldName, Json_decode.string, param);
                  }), json), stepParser);
}

function parseOverrides(plan, json) {
  var maxSources = parseOverrideMaxThresholdField("maxSources", json);
  var plan$1;
  if (maxSources !== undefined) {
    var newrecord = Caml_obj.caml_obj_dup(plan);
    newrecord.maxSources = maxSources;
    plan$1 = newrecord;
  } else {
    plan$1 = plan;
  }
  var maxDestinations = parseOverrideMaxThresholdField("maxDestinations", json);
  var plan$2;
  if (maxDestinations !== undefined) {
    var newrecord$1 = Caml_obj.caml_obj_dup(plan$1);
    newrecord$1.maxDestinations = maxDestinations;
    plan$2 = newrecord$1;
  } else {
    plan$2 = plan$1;
  }
  var maxIntegrations = parseOverrideMaxThresholdField("maxIntegrations", json);
  var plan$3;
  if (maxIntegrations !== undefined) {
    var newrecord$2 = Caml_obj.caml_obj_dup(plan$2);
    newrecord$2.maxIntegrations = maxIntegrations;
    plan$3 = newrecord$2;
  } else {
    plan$3 = plan$2;
  }
  var maxUsers = parseOverrideMaxThresholdField("maxUsers", json);
  var plan$4;
  if (maxUsers !== undefined) {
    var newrecord$3 = Caml_obj.caml_obj_dup(plan$3);
    newrecord$3.maxUsers = maxUsers;
    plan$4 = newrecord$3;
  } else {
    plan$4 = plan$3;
  }
  var usersIncluded = parseOverrideMaxThresholdField("usersIncluded", json);
  var plan$5;
  if (usersIncluded !== undefined) {
    var newrecord$4 = Caml_obj.caml_obj_dup(plan$4);
    newrecord$4.usersIncluded = usersIncluded;
    plan$5 = newrecord$4;
  } else {
    plan$5 = plan$4;
  }
  var basePricePerMonth = parseOverrideFloatField("basePricePerMonth", json);
  var plan$6;
  if (basePricePerMonth !== undefined) {
    var newrecord$5 = Caml_obj.caml_obj_dup(plan$5);
    newrecord$5.basePricePerMonth = basePricePerMonth;
    plan$6 = newrecord$5;
  } else {
    plan$6 = plan$5;
  }
  var costPerExtraSeat = parseOverrideFloatField("costPerExtraSeat", json);
  var plan$7;
  if (costPerExtraSeat !== undefined) {
    var newrecord$6 = Caml_obj.caml_obj_dup(plan$6);
    newrecord$6.costPerExtraSeat = costPerExtraSeat;
    plan$7 = newrecord$6;
  } else {
    plan$7 = plan$6;
  }
  var collaboration = parseOverrideIsEnabledField("collaboration", json);
  var plan$8;
  if (collaboration !== undefined) {
    var newrecord$7 = Caml_obj.caml_obj_dup(plan$7);
    newrecord$7.collaboration = collaboration;
    plan$8 = newrecord$7;
  } else {
    plan$8 = plan$7;
  }
  var configEventSourcesAndDestinations = parseOverrideIsEnabledField("configEventSourcesAndDestinations", json);
  var plan$9;
  if (configEventSourcesAndDestinations !== undefined) {
    var newrecord$8 = Caml_obj.caml_obj_dup(plan$8);
    newrecord$8.configEventSourcesAndDestinations = configEventSourcesAndDestinations;
    plan$9 = newrecord$8;
  } else {
    plan$9 = plan$8;
  }
  var sso = parseOverrideIsEnabledField("sso", json);
  var plan$10;
  if (sso !== undefined) {
    var newrecord$9 = Caml_obj.caml_obj_dup(plan$9);
    newrecord$9.sso = sso;
    plan$10 = newrecord$9;
  } else {
    plan$10 = plan$9;
  }
  var canProtectMainBranch = parseOverrideIsEnabledField("canProtectMainBranch", json);
  var plan$11;
  if (canProtectMainBranch !== undefined) {
    var newrecord$10 = Caml_obj.caml_obj_dup(plan$10);
    newrecord$10.canProtectMainBranch = canProtectMainBranch;
    plan$11 = newrecord$10;
  } else {
    plan$11 = plan$10;
  }
  var advancedProtectedMainBranch = parseOverrideIsEnabledField("advancedProtectedMainBranch", json);
  var plan$12;
  if (advancedProtectedMainBranch !== undefined) {
    var newrecord$11 = Caml_obj.caml_obj_dup(plan$11);
    newrecord$11.advancedProtectedMainBranch = advancedProtectedMainBranch;
    plan$12 = newrecord$11;
  } else {
    plan$12 = plan$11;
  }
  var approvalWorkflow = parseOverrideIsEnabledField("approvalWorkflow", json);
  var plan$13;
  if (approvalWorkflow !== undefined) {
    var newrecord$12 = Caml_obj.caml_obj_dup(plan$12);
    newrecord$12.approvalWorkflow = approvalWorkflow;
    plan$13 = newrecord$12;
  } else {
    plan$13 = plan$12;
  }
  var activityLog = parseOverrideIsEnabledField("activityLog", json);
  var plan$14;
  if (activityLog !== undefined) {
    var newrecord$13 = Caml_obj.caml_obj_dup(plan$13);
    newrecord$13.activityLog = activityLog;
    plan$14 = newrecord$13;
  } else {
    plan$14 = plan$13;
  }
  var auditLog = parseOverrideIsEnabledField("auditLog", json);
  var plan$15;
  if (auditLog !== undefined) {
    var newrecord$14 = Caml_obj.caml_obj_dup(plan$14);
    newrecord$14.auditLog = auditLog;
    plan$15 = newrecord$14;
  } else {
    plan$15 = plan$14;
  }
  var maxServiceAccounts = parseOverrideMaxThresholdField("maxServiceAccounts", json);
  var plan$16;
  if (maxServiceAccounts !== undefined) {
    var newrecord$15 = Caml_obj.caml_obj_dup(plan$15);
    newrecord$15.maxServiceAccounts = maxServiceAccounts;
    plan$16 = newrecord$15;
  } else {
    plan$16 = plan$15;
  }
  var inspectorLookback = parseOverrideLadderField("inspectorLookback", InspectorViewModel.timeWindowFromJs, json);
  var plan$17;
  if (inspectorLookback !== undefined) {
    var newrecord$16 = Caml_obj.caml_obj_dup(plan$16);
    newrecord$16.inspectorLookback = inspectorLookback;
    plan$17 = newrecord$16;
  } else {
    plan$17 = plan$16;
  }
  var propertyAbsenceByEventAndSource = parseOverrideIsEnabledField("propertyAbsenceByEventAndSource", json);
  var plan$18;
  if (propertyAbsenceByEventAndSource !== undefined) {
    var newrecord$17 = Caml_obj.caml_obj_dup(plan$17);
    newrecord$17.propertyAbsenceByEventAndSource = propertyAbsenceByEventAndSource;
    plan$18 = newrecord$17;
  } else {
    plan$18 = plan$17;
  }
  var propertyMatchesBySource = parseOverrideIsEnabledField("propertyMatchesBySource", json);
  var plan$19;
  if (propertyMatchesBySource !== undefined) {
    var newrecord$18 = Caml_obj.caml_obj_dup(plan$18);
    newrecord$18.propertyMatchesBySource = propertyMatchesBySource;
    plan$19 = newrecord$18;
  } else {
    plan$19 = plan$18;
  }
  var maxCodegenEvents = parseOverrideMaxThresholdField("maxCodegenEvents", json);
  var plan$20;
  if (maxCodegenEvents !== undefined) {
    var newrecord$19 = Caml_obj.caml_obj_dup(plan$19);
    newrecord$19.maxCodegenEvents = maxCodegenEvents;
    plan$20 = newrecord$19;
  } else {
    plan$20 = plan$19;
  }
  var maxCodegenEvents$1 = parseOverrideMaxThresholdField("maxCodegenEvents", json);
  var plan$21;
  if (maxCodegenEvents$1 !== undefined) {
    var newrecord$20 = Caml_obj.caml_obj_dup(plan$20);
    newrecord$20.maxCodegenEvents = maxCodegenEvents$1;
    plan$21 = newrecord$20;
  } else {
    plan$21 = plan$20;
  }
  var triggers = parseOverrideIsEnabledField("triggers", json);
  var plan$22;
  if (triggers !== undefined) {
    var newrecord$21 = Caml_obj.caml_obj_dup(plan$21);
    newrecord$21.triggers = triggers;
    plan$22 = newrecord$21;
  } else {
    plan$22 = plan$21;
  }
  var discrepancyConfig = parseOverrideIsEnabledField("discrepancyConfig", json);
  var plan$23;
  if (discrepancyConfig !== undefined) {
    var newrecord$22 = Caml_obj.caml_obj_dup(plan$22);
    newrecord$22.discrepancyConfig = discrepancyConfig;
    plan$23 = newrecord$22;
  } else {
    plan$23 = plan$22;
  }
  var forcePreferredCasing = parseOverrideIsEnabledField("forcePreferredCasing", json);
  var plan$24;
  if (forcePreferredCasing !== undefined) {
    var newrecord$23 = Caml_obj.caml_obj_dup(plan$23);
    newrecord$23.forcePreferredCasing = forcePreferredCasing;
    plan$24 = newrecord$23;
  } else {
    plan$24 = plan$23;
  }
  var pinnedProperties = parseOverrideIsEnabledField("pinnedProperties", json);
  var plan$25;
  if (pinnedProperties !== undefined) {
    var newrecord$24 = Caml_obj.caml_obj_dup(plan$24);
    newrecord$24.pinnedProperties = pinnedProperties;
    plan$25 = newrecord$24;
  } else {
    plan$25 = plan$24;
  }
  var nameMapping = parseOverrideIsEnabledField("nameMapping", json);
  var plan$26;
  if (nameMapping !== undefined) {
    var newrecord$25 = Caml_obj.caml_obj_dup(plan$25);
    newrecord$25.nameMapping = nameMapping;
    plan$26 = newrecord$25;
  } else {
    plan$26 = plan$25;
  }
  var canDuplicateEvents = parseOverrideIsEnabledField("canDuplicateEvents", json);
  var plan$27;
  if (canDuplicateEvents !== undefined) {
    var newrecord$26 = Caml_obj.caml_obj_dup(plan$26);
    newrecord$26.canDuplicateEvents = canDuplicateEvents;
    plan$27 = newrecord$26;
  } else {
    plan$27 = plan$26;
  }
  var forceBranchAudit = parseOverrideIsEnabledField("forceBranchAudit", json);
  var plan$28;
  if (forceBranchAudit !== undefined) {
    var newrecord$27 = Caml_obj.caml_obj_dup(plan$27);
    newrecord$27.forceBranchAudit = forceBranchAudit;
    plan$28 = newrecord$27;
  } else {
    plan$28 = plan$27;
  }
  var expandedBranchAudit = parseOverrideIsEnabledField("expandedBranchAudit", json);
  var plan$29;
  if (expandedBranchAudit !== undefined) {
    var newrecord$28 = Caml_obj.caml_obj_dup(plan$28);
    newrecord$28.expandedBranchAudit = expandedBranchAudit;
    plan$29 = newrecord$28;
  } else {
    plan$29 = plan$28;
  }
  var closedBranches = parseOverrideIsEnabledField("closedBranches", json);
  if (closedBranches === undefined) {
    return plan$29;
  }
  var newrecord$29 = Caml_obj.caml_obj_dup(plan$29);
  newrecord$29.closedBranches = closedBranches;
  return newrecord$29;
}

function planFromPlanName(planName) {
  switch (planName) {
    case /* Empty */0 :
        return emptyPlan;
    case /* Free */1 :
        return free;
    case /* FreeV2 */2 :
        return freeV2;
    case /* Scholarship */3 :
        return scholarship;
    case /* Growth */4 :
        return growth;
    case /* GrowthV2 */5 :
        return growthV2;
    case /* Enterprise */6 :
        return enterprise;
    case /* Starter */7 :
        return starter;
    case /* TeamV2 */8 :
        return teamV2;
    case /* TeamV2Annual */9 :
        return teamV2Annual;
    
  }
}

function planNameFromString(planName) {
  var match = planName.toLowerCase();
  switch (match) {
    case "empty" :
        return /* Empty */0;
    case "enterprise" :
        return /* Enterprise */6;
    case "free" :
        return /* Free */1;
    case "freev2" :
        return /* FreeV2 */2;
    case "growth" :
        return /* Growth */4;
    case "growthv2" :
        return /* GrowthV2 */5;
    case "scholarship" :
        return /* Scholarship */3;
    case "starter" :
        return /* Starter */7;
    case "teamv2" :
        return /* TeamV2 */8;
    case "teamv2annual" :
        return /* TeamV2Annual */9;
    default:
      return Pervasives.failwith("unknown plan name: " + planName);
  }
}

function planWithOverridesFromString(planName, json) {
  if (json !== undefined) {
    return parseOverrides(planFromPlanName(planNameFromString(planName)), Caml_option.valFromOption(json));
  } else {
    return planFromPlanName(planNameFromString(planName));
  }
}

function planWithOverrides(planName, json) {
  if (json !== undefined) {
    return parseOverrides(planFromPlanName(planName), Caml_option.valFromOption(json));
  } else {
    return planFromPlanName(planName);
  }
}

function planNameFromSchemaPlan(maybePlanName) {
  return Belt_Option.map(maybePlanName, planNameFromString);
}

var Instances_publicPlans = {
  hd: /* FreeV2 */2,
  tl: {
    hd: /* TeamV2 */8,
    tl: {
      hd: /* Enterprise */6,
      tl: /* [] */0
    }
  }
};

var Instances = {
  publicPlans: Instances_publicPlans,
  secretMenu: secretMenu,
  getPlanIndex: getPlanIndex,
  free: free,
  freeV2: freeV2,
  starter: starter,
  scholarship: scholarship,
  teamV2: teamV2,
  teamV2Annual: teamV2Annual,
  growth: growth,
  growthV2: growthV2,
  enterprise: enterprise,
  toJson: toJson,
  allPlansJson: allPlansJson,
  nextHint: nextHint,
  parseOverrideMaxThresholdField: parseOverrideMaxThresholdField,
  parseOverrideIsEnabledField: parseOverrideIsEnabledField,
  parseOverrideFloatField: parseOverrideFloatField,
  parseOverrideLadderField: parseOverrideLadderField,
  parseOverrides: parseOverrides,
  planFromPlanName: planFromPlanName,
  planNameFromString: planNameFromString,
  planWithOverridesFromString: planWithOverridesFromString,
  planWithOverrides: planWithOverrides,
  planNameFromSchemaPlan: planNameFromSchemaPlan
};

function getUsedSlots(workspace, model) {
  return [
          Belt_List.length(model.sources),
          workspace.plan.maxSources
        ];
}

var Source = {
  getUsedSlots: getUsedSlots
};

function getUsedSlots$1(workspace, model) {
  return [
          Belt_List.length(model.destinations),
          workspace.plan.maxDestinations
        ];
}

var Destination = {
  getUsedSlots: getUsedSlots$1
};

function getUsedSlots$2(workspace, model) {
  return [
          Belt_List.length(model.integrations),
          workspace.plan.maxIntegrations
        ];
}

var Integration = {
  getUsedSlots: getUsedSlots$2
};

function membersExcludingAvo(workspace) {
  return Belt_List.keep(workspace.members, (function (member) {
                return Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(member.email), true, (function (email) {
                              if (email.endsWith("@avo.sh")) {
                                return false;
                              } else {
                                return !email.endsWith("@avo.app");
                              }
                            }));
              }));
}

function commentOnlyPaidSlots(workspace) {
  var freeLimit = workspace.plan.commentOnlyMembers;
  if (typeof freeLimit === "number") {
    switch (freeLimit) {
      case /* CountAsEditors */1 :
          break;
      case /* NotAvailable */0 :
      case /* Unlimited */2 :
          return 0;
      
    }
  } else if (freeLimit.TAG !== /* AdditionalCost */0) {
    return Math.max(Belt_List.length(Belt_List.keep(membersExcludingAvo(workspace), (function (member) {
                          return Caml_obj.caml_equal(AnalyticsRe.roleFromJs(member.role), "CommentOnly");
                        }))) - freeLimit._0 | 0, 0);
  }
  return Belt_List.length(Belt_List.keep(membersExcludingAvo(workspace), (function (member) {
                    return Caml_obj.caml_equal(AnalyticsRe.roleFromJs(member.role), "CommentOnly");
                  })));
}

function commentOnlyFreeSlots(workspace) {
  var freeLimit = workspace.plan.commentOnlyMembers;
  if (typeof freeLimit === "number") {
    if (freeLimit === /* Unlimited */2) {
      return Int32.max_int;
    } else {
      return 0;
    }
  } else if (freeLimit.TAG === /* Limited */1) {
    return Math.max(freeLimit._0 - Belt_List.length(Belt_List.keep(membersExcludingAvo(workspace), (function (member) {
                          return Caml_obj.caml_equal(AnalyticsRe.roleFromJs(member.role), "CommentOnly");
                        }))) | 0, 0);
  } else {
    return 0;
  }
}

function getUsedSlots$3(workspace) {
  var membersExcludingAvo$1 = membersExcludingAvo(workspace);
  Belt_List.forEach(membersExcludingAvo$1, (function (member) {
          var match = member.email;
          if (match !== undefined) {
            return ;
          } else {
            console.error("Cannot calculate limits without emails");
            return ;
          }
        }));
  var tmp;
  if (workspace.plan.countsViewersAsUsers) {
    tmp = Belt_List.length(membersExcludingAvo$1);
  } else {
    var commentOnlyExceeded = commentOnlyPaidSlots(workspace);
    tmp = Belt_List.length(Belt_List.keep(membersExcludingAvo$1, (function (member) {
                var match = AnalyticsRe.roleFromJs(member.role);
                if (match !== undefined) {
                  return !(match === "CommentOnly" || match === "Viewer" || match === "BillingOnly");
                } else {
                  return true;
                }
              }))) + commentOnlyExceeded | 0;
  }
  return [
          tmp,
          workspace.plan.maxUsers
        ];
}

var User = {
  membersExcludingAvo: membersExcludingAvo,
  commentOnlyPaidSlots: commentOnlyPaidSlots,
  commentOnlyFreeSlots: commentOnlyFreeSlots,
  getUsedSlots: getUsedSlots$3
};

function getUsedSlots$4(workspace, model, entityType) {
  if (entityType === "Destination") {
    return getUsedSlots$1(workspace, model);
  } else if (entityType === "Integration") {
    return getUsedSlots$2(workspace, model);
  } else if (entityType === "User") {
    return getUsedSlots$3(workspace);
  } else {
    return getUsedSlots(workspace, model);
  }
}

function fromSchema(schema, maybeTrial, members, authMethod, serviceAccounts) {
  var members$1 = Belt_List.sortU(members, (function (a, b) {
          return Caml.caml_float_compare(Belt_Option.mapWithDefault(Models.toDateOption(a.createdAt), 0, (function (prim) {
                            return prim.getTime();
                          })), Belt_Option.mapWithDefault(Models.toDateOption(b.createdAt), 0, (function (prim) {
                            return prim.getTime();
                          })));
        }));
  var avoSchemaVersion = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(schema.avoSchemaVersion), 1);
  var settings = parseSettings(schema);
  var trial;
  if (avoSchemaVersion !== 1) {
    trial = Belt_Option.map(maybeTrial, (function (trial) {
            var planName = Belt_Option.getWithDefault(Belt_Option.map(trial.plan, planNameFromString), /* Enterprise */6);
            var plan = planWithOverrides(planName, Caml_option.nullable_to_opt(trial.planOverrides));
            return {
                    id: trial.id,
                    createdAt: Belt_Option.getExn(Caml_option.nullable_to_opt(Js_null_undefined.bind(trial.createdAt, (function (ts) {
                                    return ts.toDate();
                                  })))),
                    endAt: Belt_Option.getExn(Caml_option.nullable_to_opt(Js_null_undefined.bind(trial.endAt, (function (ts) {
                                    return ts.toDate();
                                  })))),
                    requesterId: undefined,
                    plan: plan
                  };
          }));
  } else {
    var isTrialing = schema.billingStatus === "trialing";
    var createdAt = Models.toDate(schema.trialStarted);
    var endAt = Belt_Option.map((createdAt == null) ? undefined : Caml_option.some(createdAt), (function (param) {
            return DateFns.addDays(14, param);
          }));
    if (isTrialing && !((createdAt == null) || endAt === undefined)) {
      var endAt$1 = Caml_option.valFromOption(endAt);
      trial = Is_future(endAt$1) ? ({
            id: "legacy-trial",
            createdAt: createdAt,
            endAt: endAt$1,
            requesterId: undefined,
            plan: planWithOverrides(/* Enterprise */6, Caml_option.some(Json_encode.object_(/* [] */0)))
          }) : undefined;
    } else {
      trial = undefined;
    }
  }
  var match = schema.billingStatus;
  var planName = avoSchemaVersion !== 1 || match !== "subscribed" ? Belt_Option.getWithDefault(Belt_Option.map(Caml_option.nullable_to_opt(schema.plan), planNameFromString), /* Free */1) : Belt_Option.getWithDefault(Belt_Option.map(Caml_option.nullable_to_opt(schema.plan), planNameFromString), /* Enterprise */6);
  var plan = planWithOverrides(planName, Caml_option.nullable_to_opt(schema.planOverrides));
  var lastSeenCodegen = schema.lastSeenCodegen;
  var lastSeenCodegen$1;
  if (lastSeenCodegen == null) {
    lastSeenCodegen$1 = "unset";
  } else {
    var sourceIdDates = Belt_Array.keepMap(Object.keys(lastSeenCodegen), (function (sourceId) {
            return Belt_Option.flatMap(Js_dict.get(lastSeenCodegen, sourceId), (function (fbTimestamp) {
                          return Belt_Option.map(Caml_option.nullable_to_opt(Models.toDate(fbTimestamp)), (function (date) {
                                        return [
                                                sourceId,
                                                date
                                              ];
                                      }));
                        }));
          }));
    lastSeenCodegen$1 = sourceIdDates.length !== 0 ? ({
          NAME: "lastSeen",
          VAL: sourceIdDates
        }) : "neverSeen";
  }
  return {
          id: schema.id,
          name: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(schema.name), ""),
          createdAt: Caml_option.nullable_to_opt(Js_null_undefined.bind(schema.createdAt, (function (ts) {
                      return ts.toDate();
                    }))),
          plan: plan,
          trial: trial,
          avoSchemaVersion: avoSchemaVersion,
          members: members$1,
          authMethod: authMethod,
          settings: settings,
          serviceAccounts: serviceAccounts,
          lastSeenCodegen: lastSeenCodegen$1
        };
}

var maxRequiredApprovals = 3;

exports.printPlanName = printPlanName;
exports.printExplicitPlanName = printExplicitPlanName;
exports.printMaxThreshold = printMaxThreshold;
exports.featureSettingToJs = featureSettingToJs;
exports.featureSettingFromJs = featureSettingFromJs;
exports.printSettingName = printSettingName;
exports.maxRequiredApprovals = maxRequiredApprovals;
exports.isV2Plan = isV2Plan;
exports.parseSettings = parseSettings;
exports.featureSettingToAnalyticsSetting = featureSettingToAnalyticsSetting;
exports.toggleSetting = toggleSetting;
exports.privateUpdateIntSetting = privateUpdateIntSetting;
exports.updateNumRequiredApprovals = updateNumRequiredApprovals;
exports.emptyPlan = emptyPlan;
exports.empty = empty;
exports.Instances = Instances;
exports.Source = Source;
exports.Destination = Destination;
exports.Integration = Integration;
exports.User = User;
exports.getUsedSlots = getUsedSlots$4;
exports.fromSchema = fromSchema;
/* Models Not a pure module */
