// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var AvoModel = require("../../app/src/avoModel.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("../../app/src/stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var BeltListExtensions = require("../../app/src/BeltListExtensions.bs.js");
var PropertyValidationUtils = require("../../app/src/PropertyValidationUtils.bs.js");

function getEventSpecificNestedPropertyPinnedValue(nestedPropertyId, parentPropertyId, eventId, model) {
  return Belt_List.head(Belt_List.keepMap(model.rules, (function (rule) {
                    var match = rule.item;
                    if (typeof match !== "object") {
                      return ;
                    }
                    if (match.NAME !== "EventObjectField") {
                      return ;
                    }
                    var match$1 = rule.definition;
                    if (typeof match$1 !== "object") {
                      return ;
                    }
                    if (match$1.NAME !== "PinnedValue") {
                      return ;
                    }
                    var match$2 = match.VAL;
                    if (match$2[0] === eventId && match$2[1] === parentPropertyId && match$2[2] === nestedPropertyId) {
                      return match$1.VAL;
                    }
                    
                  })));
}

function getNestedPropertyPinnedValue(nestedPropertyRef, parentPropertyId, eventId, model) {
  var eventSpecificNestedPropertyPinnedValue = getEventSpecificNestedPropertyPinnedValue(nestedPropertyRef.id, parentPropertyId, eventId, model);
  var maybeNestedProperty = StateUtils.resolvePropertyById(nestedPropertyRef.id, model);
  var match = nestedPropertyRef.pinnedValue;
  if (maybeNestedProperty === undefined) {
    return ;
  }
  if (eventSpecificNestedPropertyPinnedValue !== undefined && PropertyValidationUtils.validatePinnedValue(eventSpecificNestedPropertyPinnedValue, maybeNestedProperty)) {
    return [
            eventSpecificNestedPropertyPinnedValue,
            /* EventSpecific */0
          ];
  }
  if (match !== undefined && PropertyValidationUtils.validatePinnedValue(match, maybeNestedProperty)) {
    return [
            match,
            /* Global */1
          ];
  }
  
}

function hasPropertyNameMapping(model, eventAndUserProperties, systemProperties, pinnedProperties, $$event) {
  var properties = Belt_List.concat(Belt_List.concat(eventAndUserProperties, systemProperties), pinnedProperties);
  return Belt_List.some(model.rules, (function (rule) {
                return Belt_List.some(properties, (function (property) {
                              var match = rule.item;
                              var match$1 = rule.definition;
                              if (typeof match !== "object") {
                                return false;
                              }
                              var variant = match.NAME;
                              if (variant === "PropertyRef") {
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "NameMapping") {
                                  return false;
                                }
                                var match$2 = match$1.VAL;
                                if (match$2.destination === undefined) {
                                  return false;
                                }
                                if (match$2.name === undefined) {
                                  return false;
                                }
                                var match$3 = match.VAL;
                                if (match$3[0] === $$event.id) {
                                  return match$3[1] === property.id;
                                } else {
                                  return false;
                                }
                              }
                              if (variant !== "Property") {
                                return false;
                              }
                              if (typeof match$1 !== "object") {
                                return false;
                              }
                              if (match$1.NAME !== "NameMapping") {
                                return false;
                              }
                              var match$4 = match$1.VAL;
                              if (match$4.destination !== undefined && match$4.name !== undefined) {
                                return match.VAL === property.id;
                              } else {
                                return false;
                              }
                            }));
              }));
}

function hasSpecificEventNameMappingForParticularDestinationId(model, destinationId, eventId) {
  return Belt_List.someU(model.rules, (function (rule) {
                var match = rule.item;
                var match$1 = rule.definition;
                if (typeof match !== "object") {
                  return false;
                }
                if (match.NAME !== "Event") {
                  return false;
                }
                if (typeof match$1 !== "object") {
                  return false;
                }
                if (match$1.NAME !== "NameMapping") {
                  return false;
                }
                var match$2 = match$1.VAL.destination;
                if (match$2 !== undefined && typeof match$2 === "object" && match$2.NAME === "Destination" && match.VAL === eventId) {
                  return destinationId === match$2.VAL;
                } else {
                  return false;
                }
              }));
}

function getNestedProperties(property, model) {
  return BeltListExtensions.flatMapU(property.validations, (function (validation) {
                if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                  return Belt_List.keepMapU(validation.VAL, (function (propertyRef) {
                                return Belt_Option.map(AvoModel.getPropertyById(model, propertyRef.id), (function (property) {
                                              return [
                                                      propertyRef,
                                                      property
                                                    ];
                                            }));
                              }));
                } else {
                  return /* [] */0;
                }
              }));
}

function getNestedPropertiesForPropertyList(props, model) {
  return Belt_List.mapU(Belt_List.reduceU(props, /* [] */0, (function (carry, property) {
                    return Belt_List.concat(carry, getNestedProperties(property, model));
                  })), (function (param) {
                return param[1];
              }));
}

function hasSpecificPropertyNameMappingForParticularDestinationId(model, destinationId, properties, $$event) {
  return Belt_List.someU(model.rules, (function (rule) {
                return Belt_List.someU(properties, (function (property) {
                              var match = rule.item;
                              var match$1 = rule.definition;
                              if (typeof match !== "object") {
                                return false;
                              }
                              var variant = match.NAME;
                              if (variant === "PropertyRef") {
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "NameMapping") {
                                  return false;
                                }
                                var match$2 = match$1.VAL;
                                var match$3 = match$2.destination;
                                if (match$3 === undefined) {
                                  return false;
                                }
                                if (typeof match$3 !== "object") {
                                  return false;
                                }
                                if (match$3.NAME !== "Destination") {
                                  return false;
                                }
                                if (match$2.name === undefined) {
                                  return false;
                                }
                                var match$4 = match.VAL;
                                if (match$4[0] === $$event.id && match$4[1] === property.id) {
                                  return match$3.VAL === destinationId;
                                } else {
                                  return false;
                                }
                              }
                              if (variant !== "Property") {
                                return false;
                              }
                              if (typeof match$1 !== "object") {
                                return false;
                              }
                              if (match$1.NAME !== "NameMapping") {
                                return false;
                              }
                              var match$5 = match$1.VAL;
                              var match$6 = match$5.destination;
                              if (match$6 !== undefined && typeof match$6 === "object" && match$6.NAME === "Destination" && match$5.name !== undefined && match.VAL === property.id) {
                                return match$6.VAL === destinationId;
                              } else {
                                return false;
                              }
                            }));
              }));
}

function getSpecificPropertyNameMappingForParticularDestinationId(model, destinationId, properties, $$event) {
  var propertyIdToHasNestedPropertiesNameMapping = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.mapU(properties, (function (property) {
                  var nestedProperties = getNestedPropertiesForPropertyList({
                        hd: property,
                        tl: /* [] */0
                      }, model);
                  var hasNestedPropertiesNameMapping = hasSpecificPropertyNameMappingForParticularDestinationId(model, destinationId, nestedProperties, $$event);
                  return [
                          property.id,
                          hasNestedPropertiesNameMapping
                        ];
                }))));
  var nameMappingWithoutNested = Belt_List.toArray(BeltListExtensions.flatMapU(model.rules, (function (rule) {
              return Belt_List.keepMapU(properties, (function (property) {
                            var hasNestedPropertiesNameMapping = Belt_Option.getWithDefault(Belt_MapString.get(propertyIdToHasNestedPropertiesNameMapping, property.id), false);
                            var match = rule.item;
                            var match$1 = rule.definition;
                            if (typeof match !== "object") {
                              return ;
                            }
                            var variant = match.NAME;
                            if (variant === "PropertyRef") {
                              if (typeof match$1 !== "object") {
                                return ;
                              }
                              if (match$1.NAME !== "NameMapping") {
                                return ;
                              }
                              var match$2 = match$1.VAL;
                              var match$3 = match$2.destination;
                              if (match$3 === undefined) {
                                return ;
                              }
                              if (typeof match$3 !== "object") {
                                return ;
                              }
                              if (match$3.NAME !== "Destination") {
                                return ;
                              }
                              var mappedName = match$2.name;
                              if (mappedName === undefined) {
                                return ;
                              }
                              var match$4 = match.VAL;
                              if (match$4[0] === $$event.id && match$4[1] === property.id && match$3.VAL === destinationId) {
                                return {
                                        property: property,
                                        oldName: property.name,
                                        newName: mappedName,
                                        hasNestedPropertiesNameMapping: hasNestedPropertiesNameMapping
                                      };
                              } else {
                                return ;
                              }
                            }
                            if (variant !== "Property") {
                              return ;
                            }
                            if (typeof match$1 !== "object") {
                              return ;
                            }
                            if (match$1.NAME !== "NameMapping") {
                              return ;
                            }
                            var match$5 = match$1.VAL;
                            var match$6 = match$5.destination;
                            if (match$6 === undefined) {
                              return ;
                            }
                            if (typeof match$6 !== "object") {
                              return ;
                            }
                            if (match$6.NAME !== "Destination") {
                              return ;
                            }
                            var mappedName$1 = match$5.name;
                            if (mappedName$1 !== undefined && match.VAL === property.id && match$6.VAL === destinationId) {
                              return {
                                      property: property,
                                      oldName: property.name,
                                      newName: mappedName$1,
                                      hasNestedPropertiesNameMapping: hasNestedPropertiesNameMapping
                                    };
                            }
                            
                          }));
            })));
  return Belt_Array.concat(nameMappingWithoutNested, Belt_List.toArray(Belt_List.keepMapU(properties, (function (property) {
                        var nestedProperties = getNestedPropertiesForPropertyList({
                              hd: property,
                              tl: /* [] */0
                            }, model);
                        var hasNestedPropertiesNameMapping = hasSpecificPropertyNameMappingForParticularDestinationId(model, destinationId, nestedProperties, $$event);
                        if (hasNestedPropertiesNameMapping && !Belt_Array.someU(nameMappingWithoutNested, (function (nameMapping) {
                                  return Caml_obj.caml_equal(nameMapping.property, property);
                                }))) {
                          return {
                                  property: property,
                                  oldName: property.name,
                                  newName: property.name,
                                  hasNestedPropertiesNameMapping: true
                                };
                        }
                        
                      }))));
}

function getNameMappedPropertyNameWithType(model, destinationId, eventId, property) {
  return Belt_List.head(Belt_List.keepMap(model.rules, (function (rule) {
                    var match = rule.item;
                    var match$1 = rule.definition;
                    if (typeof match !== "object") {
                      return ;
                    }
                    var variant = match.NAME;
                    if (variant === "PropertyRef") {
                      if (typeof match$1 !== "object") {
                        return ;
                      }
                      if (match$1.NAME !== "NameMapping") {
                        return ;
                      }
                      var match$2 = match$1.VAL;
                      var match$3 = match$2.destination;
                      if (match$3 === undefined) {
                        return ;
                      }
                      var match$4 = match.VAL;
                      var propertyId = match$4[1];
                      var refEventId = match$4[0];
                      if (typeof match$3 === "object") {
                        var mappedName = match$2.name;
                        if (mappedName === undefined) {
                          return ;
                        }
                        if (!(Caml_obj.caml_equal(refEventId, eventId) && propertyId === property.id && Caml_obj.caml_equal(match$3.VAL, destinationId))) {
                          return ;
                        }
                        var destinationName = StateUtils.getDestinationNameById(model, Belt_Option.getExn(destinationId));
                        return {
                                TAG: /* DestinationSpecific */1,
                                _0: [[
                                    destinationName,
                                    mappedName
                                  ]]
                              };
                      }
                      var mappedName$1 = match$2.name;
                      if (mappedName$1 !== undefined && Caml_obj.caml_equal(refEventId, eventId) && propertyId === property.id) {
                        return {
                                TAG: /* AllDestinations */0,
                                _0: mappedName$1
                              };
                      } else {
                        return ;
                      }
                    }
                    if (variant !== "Property") {
                      return ;
                    }
                    if (typeof match$1 !== "object") {
                      return ;
                    }
                    if (match$1.NAME !== "NameMapping") {
                      return ;
                    }
                    var match$5 = match$1.VAL;
                    var match$6 = match$5.destination;
                    if (match$6 === undefined) {
                      return ;
                    }
                    var propertyId$1 = match.VAL;
                    if (typeof match$6 === "object") {
                      var mappedName$2 = match$5.name;
                      if (mappedName$2 === undefined) {
                        return ;
                      }
                      if (!(propertyId$1 === property.id && Caml_obj.caml_equal(match$6.VAL, destinationId))) {
                        return ;
                      }
                      var destinationName$1 = StateUtils.getDestinationNameById(model, Belt_Option.getExn(destinationId));
                      return {
                              TAG: /* DestinationSpecific */1,
                              _0: [[
                                  destinationName$1,
                                  mappedName$2
                                ]]
                            };
                    }
                    var mappedName$3 = match$5.name;
                    if (mappedName$3 !== undefined && propertyId$1 === property.id) {
                      return {
                              TAG: /* AllDestinations */0,
                              _0: mappedName$3
                            };
                    }
                    
                  })));
}

function getPropertyNameMappingWithType(model, destinationIds, eventId, property) {
  var nameMaps = Belt_List.keepMap(destinationIds, (function (destinationId) {
          return getNameMappedPropertyNameWithType(model, destinationId, eventId, property);
        }));
  if (!nameMaps) {
    return /* NoMapping */0;
  }
  var name = nameMaps.hd;
  if (typeof name === "number") {
    return /* NoMapping */0;
  }
  if (name.TAG === /* AllDestinations */0) {
    return {
            TAG: /* AllDestinations */0,
            _0: name._0
          };
  }
  var destinationMaps = Belt_Array.concatMany(Belt_List.toArray(Belt_List.keepMap(nameMaps, (function (nameMap) {
                  if (typeof nameMap === "number" || nameMap.TAG === /* AllDestinations */0) {
                    return ;
                  } else {
                    return nameMap._0;
                  }
                }))));
  return {
          TAG: /* DestinationSpecific */1,
          _0: destinationMaps
        };
}

function remapPropertyName(model, destinationId, eventId, property) {
  var match = getNameMappedPropertyNameWithType(model, destinationId, eventId, property);
  var maybeNewName;
  if (match !== undefined && typeof match !== "number") {
    if (match.TAG === /* AllDestinations */0) {
      maybeNewName = match._0;
    } else {
      var arrayWithDestinationToName = match._0;
      if (arrayWithDestinationToName.length !== 0) {
        var match$1 = Belt_Array.getExn(arrayWithDestinationToName, 0);
        maybeNewName = match$1[1];
      } else {
        maybeNewName = undefined;
      }
    }
  } else {
    maybeNewName = undefined;
  }
  return Belt_Option.getWithDefault(maybeNewName, property.name);
}

function keepProperty(destination, property) {
  var match = destination.type_;
  var match$1 = property.name;
  if (match$1 === "mparticle_event_type_id" || match === "MParticle" && match$1 === "schema") {
    return false;
  } else {
    return true;
  }
}

function getNameMappedNameForDestination(model, destinationId, $$event) {
  return Belt_List.head(Belt_List.keepMap(model.rules, (function (rule) {
                    var match = rule.item;
                    var match$1 = rule.definition;
                    if (typeof match !== "object") {
                      return ;
                    }
                    if (match.NAME !== "Event") {
                      return ;
                    }
                    if (typeof match$1 !== "object") {
                      return ;
                    }
                    if (match$1.NAME !== "NameMapping") {
                      return ;
                    }
                    var match$2 = match$1.VAL;
                    var match$3 = match$2.destination;
                    if (match$3 === undefined) {
                      return ;
                    }
                    if (typeof match$3 !== "object") {
                      return ;
                    }
                    if (match$3.NAME !== "Destination") {
                      return ;
                    }
                    var name = match$2.name;
                    if (name !== undefined && match.VAL === $$event.id && match$3.VAL === destinationId) {
                      return name;
                    }
                    
                  })));
}

function remapEventName(model, destinationId, $$event) {
  var maybeNewName = getNameMappedNameForDestination(model, destinationId, $$event);
  return Belt_Option.getWithDefault(maybeNewName, $$event.name);
}

function getSegmentIntegrations(model, source, $$event) {
  var options = source.segmentDestinationOptions;
  return Belt_List.map(options, (function (param) {
                var key = param[0];
                var override = Belt_List.head(Belt_List.keepMap(model.rules, (function (rule) {
                            var match = rule.item;
                            var match$1 = rule.definition;
                            if (typeof match !== "object") {
                              return ;
                            }
                            if (match.NAME !== "Event") {
                              return ;
                            }
                            if (typeof match$1 !== "object") {
                              return ;
                            }
                            if (match$1.NAME !== "SegmentIntegrationOption") {
                              return ;
                            }
                            var match$2 = match$1.VAL;
                            if ($$event.id === match.VAL && key === match$2[0]) {
                              return match$2[1];
                            }
                            
                          })));
                return [
                        key,
                        Belt_Option.getWithDefault(override, param[1])
                      ];
              }));
}

function getPropertyNameMaps(properties, eventId, destinations, model) {
  return Belt_Array.reduce(properties, undefined, (function (propertyMap, property) {
                var destinationNameMap = getPropertyNameMappingWithType(model, destinations, eventId, property);
                return Belt_MapString.set(propertyMap, property.id, destinationNameMap);
              }));
}

exports.getEventSpecificNestedPropertyPinnedValue = getEventSpecificNestedPropertyPinnedValue;
exports.getNestedPropertyPinnedValue = getNestedPropertyPinnedValue;
exports.hasPropertyNameMapping = hasPropertyNameMapping;
exports.hasSpecificEventNameMappingForParticularDestinationId = hasSpecificEventNameMappingForParticularDestinationId;
exports.getNestedProperties = getNestedProperties;
exports.getNestedPropertiesForPropertyList = getNestedPropertiesForPropertyList;
exports.hasSpecificPropertyNameMappingForParticularDestinationId = hasSpecificPropertyNameMappingForParticularDestinationId;
exports.getSpecificPropertyNameMappingForParticularDestinationId = getSpecificPropertyNameMappingForParticularDestinationId;
exports.getNameMappedPropertyNameWithType = getNameMappedPropertyNameWithType;
exports.getPropertyNameMappingWithType = getPropertyNameMappingWithType;
exports.remapPropertyName = remapPropertyName;
exports.keepProperty = keepProperty;
exports.getNameMappedNameForDestination = getNameMappedNameForDestination;
exports.remapEventName = remapEventName;
exports.getSegmentIntegrations = getSegmentIntegrations;
exports.getPropertyNameMaps = getPropertyNameMaps;
/* AvoModel Not a pure module */
