// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var ProfilePhoto = require("./ProfilePhoto.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

var moreCounterStyles = Curry._1(Css.style, {
      hd: Css.width(Css.px(24)),
      tl: {
        hd: Css.height(Css.px(24)),
        tl: {
          hd: Css.color(Styles.Color.blue),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.blue),
            tl: {
              hd: Css.borderRadius(Css.pct(50)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.marginLeft(Css.px(-4)),
                        tl: {
                          hd: Css.zIndex(1),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function SchemaMemberHeads(Props) {
  var schemaId = Props.schemaId;
  var limit = Props.limit;
  var members = FirebaseFetcherHooks.useMembers(schemaId);
  var limitedMembers = Belt_List.take(members, limit);
  var membersToDisplay = limitedMembers !== undefined ? limitedMembers : members;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: /* [] */0
                  })
            }, Belt_List.toArray(Belt_List.mapU(membersToDisplay, (function (member) {
                        return React.createElement("div", {
                                    key: member.id,
                                    className: Curry._1(Css.style, {
                                          hd: Css.marginLeft(Css.px(-4)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(ProfilePhoto.FromUserId.make, {
                                        id: member.id,
                                        size: 24
                                      }));
                      }))), Belt_List.length(members) > limit ? React.createElement("div", {
                    className: moreCounterStyles
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        children: "+" + String(Belt_List.length(members) - limit | 0)
                      })) : null);
}

var make = SchemaMemberHeads;

exports.moreCounterStyles = moreCounterStyles;
exports.make = make;
/* moreCounterStyles Not a pure module */
