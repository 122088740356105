// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Semver = require("semver");
var Version = require("./version.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var LoggedIn = require("./LoggedIn.bs.js");
var FetchModel = require("./FetchModel.bs.js");
var UpdatePrompt = require("./UpdatePrompt.bs.js");
var LoadingFullscreen = require("./LoadingFullscreen.bs.js");
var SsoAllowedChecker = require("./SsoAllowedChecker.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var UnauthenticatedSchemaAccessWall = require("./UnauthenticatedSchemaAccessWall.bs.js");

function LoggedInForceUpdateWrapper(Props) {
  var userId = Props.userId;
  var authState = Props.authState;
  var baseRoute = Props.baseRoute;
  var settingsQuery = Props.settingsQuery;
  var config = FirebaseFetcherHooks.useAvoConfig(undefined);
  if (typeof config !== "object") {
    if (config === "NotFound") {
      return React.createElement(FetchModel.$$Error.make, {
                  message: "Could not fetch your workspace"
                });
    } else {
      return React.createElement(LoadingFullscreen.make, {
                  message: "Checking for updates..."
                });
    }
  }
  var config$1 = config.VAL;
  var tmp;
  if (Semver.satisfies(config$1.requiredVersion, "<= " + Version.current)) {
    var exit = 0;
    if (typeof baseRoute === "object" && baseRoute.NAME === "schema") {
      var schemaId = baseRoute.VAL;
      tmp = React.createElement(SsoAllowedChecker.make, {
            schemaId: schemaId,
            children: (function (state) {
                if (state === "NotAllowed") {
                  return React.createElement(UnauthenticatedSchemaAccessWall.make, {
                              schemaId: schemaId
                            });
                } else if (state === "Allowed") {
                  return React.createElement(LoggedIn.make, {
                              userId: userId,
                              authState: authState,
                              baseRoute: baseRoute,
                              settingsQuery: settingsQuery
                            });
                } else {
                  return React.createElement(LoadingFullscreen.make, {
                              message: "Authenticating..."
                            });
                }
              })
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = React.createElement(LoggedIn.make, {
            userId: userId,
            authState: authState,
            baseRoute: baseRoute,
            settingsQuery: settingsQuery
          });
    }
    
  } else {
    tmp = React.createElement(UpdatePrompt.make, {
          toVersion: config$1.requiredVersion
        });
  }
  return React.createElement("div", undefined, tmp);
}

var make = React.memo(LoggedInForceUpdateWrapper, (function (props, nextProps) {
        if (props.userId === nextProps.userId && Caml_obj.caml_equal(props.authState, nextProps.authState) && Caml_obj.caml_equal(props.baseRoute, nextProps.baseRoute)) {
          return Caml_obj.caml_equal(props.settingsQuery.value, nextProps.settingsQuery.value);
        } else {
          return false;
        }
      }));

exports.make = make;
/* make Not a pure module */
