// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Icon = require("./Icon.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var $$Number = require("./Number.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var DateFns = require("./DateFns.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Redirect = require("./Redirect.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Monospace = require("./Monospace.bs.js");
var StringExt = require("./StringExt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var ModelStore = require("./ModelStore.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var TextButton = require("./TextButton.bs.js");
var React$1 = require("@dopt/react");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Collapsible = require("./Collapsible.bs.js");
var ProductTour = require("./ProductTour.bs.js");
var DrawerHeader = require("./detailviews/DrawerHeader.bs.js");
var PropertyType = require("./PropertyType.bs.js");
var SimpleTooltip = require("./SimpleTooltip.bs.js");
var FramerMotion = require("framer-motion");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var AppVersionsTypes = require("../../shared/models/AppVersionsTypes.bs.js");
var InspectorViewApi = require("./inspector/InspectorViewApi.bs.js");
var InspectorIssueType = require("../../shared/models/InspectorIssueType.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var InspectorIssuesStore = require("./inspector/InspectorIssuesStore.bs.js");
var InspectorIssuesHelpers = require("./inspector/InspectorIssuesHelpers.bs.js");
var InspectorIssueViewModel = require("./inspector/InspectorIssueViewModel.bs.js");
var TrackingPlanMappedModel = require("../../model/src/TrackingPlanMappedModel.bs.js");
var InspectorIssuesFilterHooks = require("./inspector/InspectorIssuesFilterHooks.bs.js");
var InspectorIssuesFilterUtils = require("../../shared/utils/InspectorIssuesFilterUtils.bs.js");
var PropertyAbsenceViewHelpers = require("./PropertyAbsenceViewHelpers.bs.js");

function getSimplestAbsence(maybeProperty, maybeEvent, modelSources) {
  return PropertyAbsenceViewHelpers.getSimplestModeForStateMatrix(maybeProperty !== undefined && maybeEvent !== undefined ? PropertyAbsenceViewHelpers.getFullStateMatrix({
                    hd: maybeEvent,
                    tl: /* [] */0
                  }, modelSources, maybeProperty.absence) : undefined);
}

function getSharedIssueSourceIds(sharedIssues) {
  return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.map(sharedIssues, (function (sharedIssue) {
                        return sharedIssue.sourceId;
                      }))));
}

function InspectorIssueDetailsV2$Section(Props) {
  var label = Props.label;
  var labelTopOffsetOpt = Props.labelTopOffset;
  var children = Props.children;
  var labelTopOffset = labelTopOffsetOpt !== undefined ? labelTopOffsetOpt : 0;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("row"),
                      tl: {
                        hd: Css.marginBottom(Css.px(16)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.px(84)),
                        tl: {
                          hd: Css.marginRight(Css.px(8)),
                          tl: {
                            hd: Css.marginTop(Css.px(labelTopOffset)),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.grey70,
                      children: label
                    })), React.createElement("div", undefined, children));
}

function InspectorIssueDetailsV2$DescriptionContainer(Props) {
  var children = Props.children;
  var linesToShowOpt = Props.linesToShow;
  var linesToShow = linesToShowOpt !== undefined ? linesToShowOpt : 3;
  var ref = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setDisplayShowMore = match[1];
  var match$1 = Hooks.useDisclosure(false);
  var actions = match$1[1];
  var opened = match$1[0];
  React.useEffect(function () {
        var el = Belt_Option.flatMap(Caml_option.nullable_to_opt(ref.current), (function (el) {
                return Caml_option.nullable_to_opt(el.firstChild);
              }));
        if (el !== undefined) {
          var el$1 = Caml_option.valFromOption(el);
          if (el$1.clientHeight < el$1.scrollHeight) {
            Curry._1(setDisplayShowMore, (function (param) {
                    return true;
                  }));
          }
          
        }
        
      });
  var tmp = {
    size: "Small",
    color: Styles.Color.grey90,
    children: children
  };
  var tmp$1 = opened ? undefined : linesToShow;
  if (tmp$1 !== undefined) {
    tmp.lines = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  ref: ref
                }, React.createElement($$Text.make, tmp)), match[0] ? React.createElement(TextButton.make, {
                    onClick: (function (param) {
                        return Curry._1(actions.toggle, undefined);
                      }),
                    style: "Grey",
                    size: "Small",
                    children: opened ? "Show less" : "Show more"
                  }) : null);
}

var content = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.padding(Css.px(32)),
        tl: {
          hd: Css.overflowY("auto"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: /* [] */0
          }
        }
      }
    });

var simpleButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: $$Text.sizeStyles("Tiny"),
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.color(Styles.Color.blue100),
                tl: {
                  hd: Css.textDecoration("underline"),
                  tl: {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: Styles.Duration.$$short
                        }, undefined, undefined, "all"),
                    tl: {
                      hd: Css.hover({
                            hd: Css.color(Styles.Color.blue120),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

var tags = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexWrap("wrap"),
        tl: {
          hd: Css.gap(Css.px(8)),
          tl: {
            hd: Css.marginBottom(Css.px(8)),
            tl: {
              hd: Css.marginTop(Css.px(8)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var category = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(4), Css.px(8)),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.border(Css.px(1), "solid", Styles.Color.grey40),
          tl: {
            hd: Css.color(Styles.Color.grey80),
            tl: {
              hd: Css.transition({
                    NAME: "ms",
                    VAL: Styles.Duration.$$short
                  }, undefined, undefined, "all"),
              tl: {
                hd: Css.hover({
                      hd: Css.color(Styles.Color.grey100),
                      tl: {
                        hd: Css.borderColor(Styles.Color.grey60),
                        tl: /* [] */0
                      }
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function tag(bgColor) {
  return Curry._1(Css.style, {
              hd: Css.padding2(Css.px(4), Css.px(8)),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.border(Css.px(1), "solid", "transparent"),
                  tl: {
                    hd: Css.color(Styles.Color.grey80),
                    tl: {
                      hd: Css.backgroundColor(bgColor),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var largePill = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(100)),
      tl: {
        hd: Css.border(Css.px(1), "solid", Styles.Color.grey40),
        tl: {
          hd: Css.padding2(Css.px(5), Css.px(10)),
          tl: /* [] */0
        }
      }
    });

var clickableLargePill = Curry._1(Css.merge, {
      hd: largePill,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.justifyContent("center"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.color(Styles.Color.grey80),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$short
                          }, undefined, undefined, "all"),
                      tl: {
                        hd: Css.hover({
                              hd: Css.color(Styles.Color.grey100),
                              tl: {
                                hd: Css.borderColor(Styles.Color.grey60),
                                tl: /* [] */0
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function pill(impacted) {
  return Curry._1(Css.style, {
              hd: Css.borderRadius(Css.px(100)),
              tl: {
                hd: Css.border(Css.px(1), "solid", impacted ? Styles.Color.red100 : Styles.Color.grey40),
                tl: {
                  hd: Css.padding2(Css.px(4), Css.px(8)),
                  tl: {
                    hd: Css.backgroundColor(impacted ? Styles.Color.red20 : Styles.Color.white),
                    tl: {
                      hd: Css.display("inlineFlex"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var clickablePill = Curry._1(Css.merge, {
      hd: pill(false),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.justifyContent("center"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.color(Styles.Color.grey80),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$short
                          }, undefined, undefined, "all"),
                      tl: {
                        hd: Css.hover({
                              hd: Css.color(Styles.Color.grey100),
                              tl: {
                                hd: Css.borderColor(Styles.Color.grey60),
                                tl: /* [] */0
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var dataTable = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: /* [] */0
    });

function dataTableHeader(cols) {
  return Curry._1(Css.style, {
              hd: Css.display(Css.grid),
              tl: {
                hd: Css.gridTemplateColumns({
                      hd: {
                        NAME: "repeat",
                        VAL: [
                          {
                            NAME: "num",
                            VAL: cols
                          },
                          {
                            NAME: "minmax",
                            VAL: [
                              "zero",
                              Css.fr(1.0)
                            ]
                          }
                        ]
                      },
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.height(Css.px(38)),
                  tl: /* [] */0
                }
              }
            });
}

function dataTableRow(cols) {
  return Curry._1(Css.style, {
              hd: Css.display(Css.grid),
              tl: {
                hd: Css.gridTemplateColumns({
                      hd: {
                        NAME: "repeat",
                        VAL: [
                          {
                            NAME: "num",
                            VAL: cols
                          },
                          {
                            NAME: "minmax",
                            VAL: [
                              "zero",
                              Css.fr(1.0)
                            ]
                          }
                        ]
                      },
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.height(Css.px(38)),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.marginLeft(Css.px(8)),
                      tl: {
                        hd: Css.marginRight(Css.px(8)),
                        tl: {
                          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                          tl: {
                            hd: Css.selector(":last-child", {
                                  hd: Css.borderBottomWidth(Css.px(0)),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.selector(" > div", {
                                    hd: Css.overflow("hidden"),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var dataTableRowCurrent = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.grey20),
      tl: {
        hd: Css.borderRadius(Css.px(100)),
        tl: {
          hd: Css.paddingLeft(Css.px(8)),
          tl: {
            hd: Css.paddingRight(Css.px(8)),
            tl: {
              hd: Css.margin(Css.px(0)),
              tl: {
                hd: Css.borderBottomWidth(Css.px(0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var tableButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: $$Text.sizeStyles("Tiny"),
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.color(Styles.Color.blue120),
                tl: {
                  hd: Css.padding2(Css.px(4), Css.px(8)),
                  tl: {
                    hd: Css.borderRadius(Css.px(100)),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$short
                          }, undefined, undefined, "all"),
                      tl: {
                        hd: Css.hover({
                              hd: Css.color(Styles.Color.white),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.blue100),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

function textHover(from, to_) {
  return Curry._1(Css.style, {
              hd: Css.color(from),
              tl: {
                hd: Css.hover({
                      hd: Css.color(to_),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$short
                      }, undefined, undefined, "all"),
                  tl: /* [] */0
                }
              }
            });
}

var collapser = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.marginTop(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

var chevronContainer = Curry._1(Css.style, {
      hd: Css.hover({
            hd: Css.cursor("pointer"),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

var seenContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.gap(Css.px(6)),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginTop(Css.px(-4)),
            tl: /* [] */0
          }
        }
      }
    });

function percentageBar(total, fraction) {
  var pctWidth = Caml_int32.div(Math.imul(fraction, 60), total);
  var n = pctWidth / 60.0;
  return Curry._1(Css.style, {
              hd: Css.height(Css.px(8)),
              tl: {
                hd: Css.width(Css.px(60)),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.grey30),
                  tl: {
                    hd: Css.borderRadius(Css.px(4)),
                    tl: {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.after({
                              hd: Css.position("absolute"),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.top(Css.px(0)),
                                  tl: {
                                    hd: Css.height(Css.px(8)),
                                    tl: {
                                      hd: Css.minWidth(Css.px(fraction === 0 ? 0 : 3)),
                                      tl: {
                                        hd: Css.maxWidth(Css.px(fraction === total ? 60 : 57)),
                                        tl: {
                                          hd: Css.width(Css.px(pctWidth)),
                                          tl: {
                                            hd: Css.unsafe("content", "''"),
                                            tl: {
                                              hd: Css.display("block"),
                                              tl: {
                                                hd: Css.borderRadius(Css.px(4)),
                                                tl: {
                                                  hd: Css.backgroundColor(n > 0.5 ? Styles.Color.orange100 : Styles.Color.yellow100),
                                                  tl: {
                                                    hd: pctWidth < 60 ? Css.borderTopRightRadius("zero") : Styles.emptyStyle,
                                                    tl: {
                                                      hd: pctWidth < 60 ? Css.borderBottomRightRadius("zero") : Styles.emptyStyle,
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function useRelatedIssues(sharedId, issueId) {
  var match = React.useState(function () {
        return "initial";
      });
  var setRelatedIssues = match[1];
  React.useEffect((function () {
          Curry._1(setRelatedIssues, (function (param) {
                  return "loading";
                }));
          $$Promise.$$catch(InspectorViewApi.getRelatedIssues(sharedId).then(function (sharedIssues) {
                    var isCurrentSharedId = Belt_Option.mapWithDefault(Belt_Array.get(sharedIssues, 0), false, (function (issue) {
                            return issue.sharedIssueId === sharedId;
                          }));
                    if (isCurrentSharedId) {
                      return Curry._1(setRelatedIssues, (function (param) {
                                    return {
                                            NAME: "success",
                                            VAL: sharedIssues
                                          };
                                  }));
                    }
                    
                  }), (function (exn) {
                  Curry._1(setRelatedIssues, (function (param) {
                          return "error";
                        }));
                  return Promise.reject(exn);
                }));
          
        }), [
        sharedId,
        issueId
      ]);
  return match[0];
}

function InspectorIssueDetailsV2$Content(Props) {
  var issue = Props.issue;
  var onIssueInteracted = Props.onIssueInteracted;
  var maybeEvent = Props.maybeEvent;
  var maybeProperty = Props.maybeProperty;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var model = ModelStore.useModel(undefined);
  var mappedSources = ModelStore.useMappedSources(undefined);
  var relatedIssues = useRelatedIssues(issue.sharedId, issue.id);
  var match = InspectorIssuesStore.useAppVersionsInSyncWithIssues(undefined, undefined);
  var appVersions = match[0];
  var match$1 = Hooks.useDisclosure(issue.source.versions.length <= 3);
  var releasesActions = match$1[1];
  var releasesOpened = match$1[0];
  React.useEffect((function () {
          if (issue.source.versions.length <= 3) {
            Curry._1(releasesActions.open_, undefined);
          } else {
            Curry._1(releasesActions.close, undefined);
          }
          
        }), [issue]);
  var tmp;
  if (maybeEvent !== undefined) {
    var categories = StateUtils.getGoalsWithEvent(maybeEvent, model);
    tmp = React.createElement(InspectorIssueDetailsV2$Section, {
          label: "Event",
          labelTopOffset: 2,
          children: null
        }, React.createElement(Link.make, {
              path: Router.Link.addDrawerItem(undefined, {
                    NAME: "event",
                    VAL: [
                      maybeEvent.id,
                      undefined,
                      undefined,
                      false
                    ]
                  }),
              className: textHover(Styles.Color.grey90, Styles.Color.blue100),
              onClick: (function (param) {
                  return Curry._2(onIssueInteracted, issue, "ClickViewEventInTrackingPlan");
                }),
              children: React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    singleLine: true,
                    children: maybeEvent.name
                  })
            }), React.createElement(Spacer.make, {
              height: 2
            }), React.createElement(InspectorIssueDetailsV2$DescriptionContainer, {
              children: maybeEvent.description,
              key: issue.id
            }), Caml_obj.caml_notequal(categories, []) && maybeEvent.tags !== /* [] */0 ? React.createElement(Spacer.make, {
                height: 4
              }) : null, Caml_obj.caml_notequal(categories, []) ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                    height: 4
                  }), React.createElement($$Text.make, {
                    size: "Tiny",
                    weight: "Semi",
                    color: Styles.Color.grey70,
                    children: "Event Categories"
                  }), React.createElement("div", {
                    className: tags
                  }, Belt_Array.mapU(categories, (function (category$1) {
                          return React.createElement(Link.make, {
                                      path: Router.Link.addDrawerItem(undefined, {
                                            NAME: "category",
                                            VAL: [
                                              category$1.id,
                                              undefined
                                            ]
                                          }),
                                      className: category,
                                      onClick: (function (param) {
                                          return Curry._2(onIssueInteracted, issue, "ClickViewCategoryInTrackingPlan");
                                        }),
                                      children: React.createElement($$Text.make, {
                                            size: "Tiny",
                                            children: category$1.name === "" ? "Untitled Category" : category$1.name
                                          }),
                                      key: category$1.id
                                    });
                        })))) : null, maybeEvent.tags !== /* [] */0 ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                    height: 4
                  }), React.createElement($$Text.make, {
                    size: "Tiny",
                    weight: "Semi",
                    color: Styles.Color.grey70,
                    children: "Event Tags"
                  }), React.createElement("div", {
                    className: tags
                  }, Belt_List.toArray(Belt_List.mapU(maybeEvent.tags, (function (tag$1) {
                              return React.createElement("div", {
                                          key: tag$1,
                                          className: tag(Styles.Color.getLightColorFromString(tag$1))
                                        }, React.createElement($$Text.make, {
                                              size: "Tiny",
                                              color: Styles.Color.grey80,
                                              children: tag$1
                                            }));
                            }))))) : null, React.createElement("button", {
              className: simpleButton,
              onClick: (function (param) {
                  Router.Schema.pushDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          maybeEvent.id,
                          undefined,
                          undefined,
                          false
                        ]
                      });
                  return Curry._2(onIssueInteracted, issue, "ClickViewEventInTrackingPlan");
                })
            }, "View event in tracking plan"));
  } else {
    tmp = React.createElement(InspectorIssueDetailsV2$Section, {
          label: "Event",
          labelTopOffset: 2,
          children: React.createElement($$Text.make, {
                size: "Medium",
                weight: "Semi",
                color: Styles.Color.grey90,
                children: issue.eventName
              })
        });
  }
  var match$2 = issue.propertyName;
  var tmp$1;
  if (maybeProperty !== undefined) {
    var tmp$2 = {
      model: model,
      property: maybeProperty,
      skipMatches: true
    };
    if (maybeEvent !== undefined) {
      tmp$2.event = Caml_option.valFromOption(maybeEvent);
    }
    tmp$1 = React.createElement(InspectorIssueDetailsV2$Section, {
          label: "Property",
          labelTopOffset: 3,
          children: null
        }, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.gap(Css.px(4)),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement(Link.make, {
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          maybeProperty.id,
                          undefined
                        ]
                      }),
                  className: textHover(Styles.Color.grey90, Styles.Color.blue100),
                  onClick: (function (param) {
                      return Curry._2(onIssueInteracted, issue, "ClickViewPropertyInTrackingPlan");
                    }),
                  children: React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Semi",
                        singleLine: true,
                        children: maybeProperty.name
                      })
                }), React.createElement(PropertyType.make, tmp$2)), React.createElement(Spacer.make, {
              height: 2
            }), React.createElement(InspectorIssueDetailsV2$DescriptionContainer, {
              children: maybeProperty.description,
              key: issue.id
            }), React.createElement("button", {
              className: simpleButton,
              onClick: (function (param) {
                  Router.Schema.pushDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          maybeProperty.id,
                          undefined
                        ]
                      });
                  return Curry._2(onIssueInteracted, issue, "ClickViewPropertyInTrackingPlan");
                })
            }, "View property in tracking plan"));
  } else {
    tmp$1 = match$2 !== undefined ? React.createElement(InspectorIssueDetailsV2$Section, {
            label: "Property",
            labelTopOffset: 2,
            children: React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.grey90,
                  children: match$2
                })
          }) : null;
  }
  var match$3 = issue.issueType;
  var tmp$3;
  if (typeof match$3 === "number") {
    tmp$3 = match$3 === /* EventNotInTrackingPlan */0 ? "Event unexpected by tracking plan" : "Event unexpected on " + issue.source.name + " by tracking plan";
  } else {
    switch (match$3.TAG | 0) {
      case /* MissingExpectedProperty */0 :
          var presence = getSimplestAbsence(maybeProperty, maybeEvent, model.sources);
          var tmp$4;
          switch (presence) {
            case /* Always */0 :
                tmp$4 = "always sent";
                break;
            case /* Sometimes */1 :
                tmp$4 = "sometimes sent";
                break;
            case /* BySource */2 :
                tmp$4 = "depending on source";
                break;
            
          }
          tmp$3 = React.createElement(React.Fragment, undefined, "Property set as ", React.createElement(Monospace.make, {
                    weight: "semi",
                    children: tmp$4
                  }), " on \"" + issue.eventName + "\" in tracking plan but missing on event");
          break;
      case /* PropertyTypeInconsistentWithTrackingPlan */1 :
          var match$4 = match$3._0;
          tmp$3 = React.createElement(React.Fragment, undefined, "Property seen as", React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.backgroundColor(Styles.Color.red20),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.marginLeft(Css.px(4)),
                              tl: {
                                hd: Css.marginRight(Css.px(4)),
                                tl: {
                                  hd: Css.fontSize(Css.px(11)),
                                  tl: {
                                    hd: Css.padding2(Css.px(4), Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(Monospace.make, {
                        color: Styles.Color.red120,
                        children: match$4.actualPropertyType
                      })), "but expected as", React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.backgroundColor(Styles.Color.grey20),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.marginLeft(Css.px(4)),
                              tl: {
                                hd: Css.marginRight(Css.px(4)),
                                tl: {
                                  hd: Css.fontSize(Css.px(11)),
                                  tl: {
                                    hd: Css.padding2(Css.px(4), Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(Monospace.make, {
                        color: Styles.Color.grey80,
                        children: match$4.expectedPropertyType
                      })));
          break;
      case /* UnexpectedProperty */2 :
          tmp$3 = maybeProperty !== undefined ? "Property found in tracking plan but unexpected on this event" : "Property unexpected on event by tracking plan";
          break;
      case /* InconsistentType */3 :
          var propertyTypes = match$3._0.propertyTypes;
          tmp$3 = React.createElement(React.Fragment, undefined, "Property types seen: ", React.createElement(Monospace.make, {
                    weight: "semi",
                    children: Belt_Array.getExn(propertyTypes, 0)
                  }), ", ", React.createElement(Monospace.make, {
                    weight: "semi",
                    children: Belt_Array.getExn(propertyTypes, 1)
                  }));
          break;
      
    }
  }
  var match$5 = Curry._2(TrackingPlanMappedModel.Sources.get, mappedSources, issue.source.id);
  var releases = issue.source.versions;
  var tmp$5;
  if (releases.length !== 1) {
    var impactedReleasesCount = issue.source.impactedVersions.length;
    tmp$5 = impactedReleasesCount === releases.length ? React.createElement(React.Fragment, undefined, "impacted ", React.createElement($$Text.make, {
                element: "Span",
                weight: "Semi",
                children: "all releases"
              }), " where this event was seen") : (
        impactedReleasesCount > 0 ? React.createElement(React.Fragment, undefined, "impacted ", React.createElement($$Text.make, {
                    element: "Span",
                    weight: "Semi",
                    children: String(impactedReleasesCount) + " out of " + AvoUtils.plural(releases.length, "release")
                  }), " where this event was seen") : React.createElement(React.Fragment, undefined, "been seen in ", React.createElement($$Text.make, {
                    element: "Span",
                    weight: "Semi",
                    children: AvoUtils.plural(releases.length, "release")
                  }))
      );
  } else {
    var _release = releases[0];
    tmp$5 = _release.issueCount > 0 ? React.createElement(React.Fragment, undefined, "impacted ", React.createElement($$Text.make, {
                element: "Span",
                weight: "Semi",
                children: "1 release"
              }), " where this event was seen") : React.createElement(React.Fragment, undefined, "been seen in ", React.createElement($$Text.make, {
                element: "Span",
                weight: "Semi",
                children: "1 release"
              }));
  }
  var tmp$6;
  if (typeof relatedIssues === "object") {
    var otherSourceCount = getSharedIssueSourceIds(relatedIssues.VAL).length - 1 | 0;
    tmp$6 = otherSourceCount === 0 ? "This issue has only been seen on " + issue.source.name : "In addition to " + issue.source.name + ", this issue was seen on " + AvoUtils.plural(otherSourceCount, "other source") + ".";
  } else {
    tmp$6 = relatedIssues === "error" ? "Error finding other sources, please contact support if this error persists." : "Finding other sources...";
  }
  var match$6 = Curry._2(TrackingPlanMappedModel.Sources.get, mappedSources, issue.source.id);
  var tmp$7;
  if (typeof relatedIssues === "object") {
    var sharedIssues = relatedIssues.VAL;
    tmp$7 = getSharedIssueSourceIds(sharedIssues).length > 1 ? Belt_Array.map(Belt_Array.keep(sharedIssues, (function (sharedIssue) {
                  return sharedIssue.sourceId !== issue.source.id;
                })), (function (sharedIssue) {
              var source = Curry._2(TrackingPlanMappedModel.Sources.get, mappedSources, sharedIssue.sourceId);
              return React.createElement("div", {
                          key: sharedIssue.issueId,
                          className: dataTableRow(5)
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: /* [] */0
                                  })
                            }, source !== undefined ? React.createElement(Link.make, {
                                    path: Router.Link.addDrawerItem(undefined, {
                                          NAME: "source",
                                          VAL: [
                                            source.id,
                                            "overview"
                                          ]
                                        }),
                                    className: clickablePill,
                                    onClick: (function (param) {
                                        return Curry._2(onIssueInteracted, issue, "ClickViewSourceInTrackingPlan");
                                      }),
                                    children: React.createElement($$Text.make, {
                                          size: "Tiny",
                                          singleLine: true,
                                          children: TrackingPlanMappedModel.Sources.getName(source)
                                        })
                                  }) : React.createElement("div", {
                                    className: pill(false)
                                  }, React.createElement($$Text.make, {
                                        size: "Tiny",
                                        color: Styles.Color.grey80,
                                        children: Belt_Option.getWithDefault(TrackingPlanMappedModel.Sources.getNameById(mappedSources, sharedIssue.sourceId), "Untitled Source")
                                      }))), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.grey80,
                                  children: $$Number.toLocaleString(sharedIssue.eventCount)
                                })), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.grey80,
                                  children: $$Number.toLocaleString(sharedIssue.issueCount)
                                })), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.grey80,
                                  children: InspectorIssueViewModel.Utils.getDisplayPercentageString(sharedIssue.eventCount | 0, sharedIssue.issueCount | 0) + "%"
                                })), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement("button", {
                                  className: tableButton,
                                  onClick: (function (param) {
                                      Curry._2(onIssueInteracted, issue, "ClickRelevantIssue");
                                      return Router.Schema.pushDrawerItem(undefined, {
                                                  NAME: "inspectorIssue",
                                                  VAL: sharedIssue.issueId
                                                });
                                    })
                                }, "View issue")));
            })) : null;
  } else if (relatedIssues === "error") {
    tmp$7 = null;
  } else {
    var loadingStyles = Curry._1(Css.merge, {
          hd: Curry._1(Css.style, Styles.shimmer),
          tl: {
            hd: Curry._1(Css.style, {
                  hd: Css.height(Css.px(38)),
                  tl: {
                    hd: Css.borderTop(Css.px(2), "solid", Styles.Color.white),
                    tl: {
                      hd: Css.borderRadius(Css.px(100)),
                      tl: {
                        hd: Css.opacity(0.8),
                        tl: {
                          hd: Css.selector(":nth-child(4)", {
                                hd: Css.opacity(0.3),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }),
            tl: /* [] */0
          }
        });
    tmp$7 = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: loadingStyles
            }), React.createElement("div", {
              className: loadingStyles
            }));
  }
  return React.createElement("div", {
              className: content
            }, tmp, tmp$1, React.createElement(InspectorIssueDetailsV2$Section, {
                  label: "Issue",
                  children: React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.grey90,
                        children: tmp$3
                      })
                }), React.createElement(InspectorIssueDetailsV2$Section, {
                  label: "Source",
                  labelTopOffset: 5,
                  children: match$5 !== undefined ? React.createElement(Link.make, {
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "source",
                                VAL: [
                                  match$5.id,
                                  "overview"
                                ]
                              }),
                          className: clickableLargePill,
                          onClick: (function (param) {
                              return Curry._2(onIssueInteracted, issue, "ClickViewSourceInTrackingPlan");
                            }),
                          children: React.createElement($$Text.make, {
                                element: "Span",
                                size: "Small",
                                children: issue.source.name
                              })
                        }) : React.createElement("div", {
                          className: largePill
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.grey80,
                              children: issue.source.name
                            }))
                }), React.createElement(InspectorIssueDetailsV2$Section, {
                  label: "First seen",
                  children: React.createElement("span", {
                        className: seenContainer
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.grey90,
                            title: issue.firstSeen.toLocaleString(),
                            children: DateFns.distanceInWords(undefined, undefined, new Date(), issue.firstSeen) + " ago "
                          }), React.createElement("span", {
                            className: pill(false)
                          }, React.createElement($$Text.make, {
                                size: "Tiny",
                                singleLine: true,
                                color: Styles.Color.grey80,
                                children: issue.oldestAppVersion
                              })))
                }), React.createElement(InspectorIssueDetailsV2$Section, {
                  label: "Last seen",
                  children: React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.grey90,
                        title: issue.lastSeen.toLocaleString(),
                        children: DateFns.distanceInWords(undefined, undefined, new Date(), issue.lastSeen) + " ago "
                      })
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.grey80,
                  children: "RELEASES"
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(2)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.grey30),
                            tl: {
                              hd: Css.marginBottom(Css.px(16)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement($$Text.make, {
                  element: "Span",
                  size: "Small",
                  color: Styles.Color.grey80,
                  children: null
                }, "In the past 24 hours, this issue has ", tmp$5), React.createElement(Collapsible.make, {
                  collapsed: !releasesOpened,
                  collapsedHeight: 189,
                  onChange: (function (param) {
                      return AnalyticsRe.collapsibleItemInteracted(schemaGroup, releasesOpened ? "Expanded" : "Collapsed", "Releases", "InspectorIssue");
                    }),
                  withFade: false,
                  children: React.createElement("div", {
                        className: dataTable
                      }, React.createElement("div", {
                            className: dataTableHeader(4)
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.justifyContent("flexStart"),
                                        tl: {
                                          hd: Css.alignItems("flexEnd"),
                                          tl: {
                                            hd: Css.marginBottom(Css.px(6)),
                                            tl: {
                                              hd: Css.paddingLeft(Css.px(16)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    })
                              }, React.createElement($$Text.make, {
                                    size: "Tiny",
                                    weight: "Semi",
                                    color: Styles.Color.grey70,
                                    children: "Release"
                                  })), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.justifyContent("center"),
                                        tl: {
                                          hd: Css.alignItems("flexEnd"),
                                          tl: {
                                            hd: Css.marginBottom(Css.px(6)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    })
                              }, React.createElement($$Text.make, {
                                    size: "Tiny",
                                    weight: "Semi",
                                    color: Styles.Color.grey70,
                                    children: "Event volume"
                                  })), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.justifyContent("center"),
                                        tl: {
                                          hd: Css.alignItems("flexEnd"),
                                          tl: {
                                            hd: Css.marginBottom(Css.px(6)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    })
                              }, React.createElement($$Text.make, {
                                    size: "Tiny",
                                    weight: "Semi",
                                    color: Styles.Color.grey70,
                                    children: "Issue volume"
                                  })), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.justifyContent("flexEnd"),
                                        tl: {
                                          hd: Css.alignItems("flexEnd"),
                                          tl: {
                                            hd: Css.marginBottom(Css.px(6)),
                                            tl: {
                                              hd: Css.paddingRight(Css.px(52)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    })
                              }, React.createElement($$Text.make, {
                                    size: "Tiny",
                                    weight: "Semi",
                                    color: Styles.Color.grey70,
                                    children: "Issue %"
                                  }))), issue.source.versions.length > 1 ? React.createElement("div", {
                              className: Curry._1(Css.merge, {
                                    hd: dataTableRow(4),
                                    tl: {
                                      hd: dataTableRowCurrent,
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.marginLeft(Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Semi",
                                      color: Styles.Color.grey80,
                                      children: "All (" + String(issue.source.versions.length) + ")"
                                    })), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.justifyContent("center"),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Semi",
                                      color: Styles.Color.grey80,
                                      children: $$Number.toLocaleString(issue.eventCount)
                                    })), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.justifyContent("center"),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Semi",
                                      color: Styles.Color.grey80,
                                      children: $$Number.toLocaleString(issue.issueCount)
                                    })), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.justifyContent("flexEnd"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.paddingRight(Css.px(12)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Semi",
                                      color: Styles.Color.grey80,
                                      children: InspectorIssueViewModel.Utils.getDisplayPercentageString(issue.eventCount, issue.issueCount) + "%"
                                    }), React.createElement(Spacer.make, {
                                      width: 4
                                    }), React.createElement("div", {
                                      className: percentageBar(issue.eventCount, issue.issueCount)
                                    }))) : null, Belt_Array.map(issue.source.versions, (function (version) {
                              var isLatest = Caml_obj.caml_equal(Belt_Option.map(AppVersionsTypes.getLatestVersion(undefined, appVersions, issue.source.id), AppVersionsTypes.print), version.release);
                              var isImpacted = version.issueCount > 0;
                              var match = Belt_Option.flatMapU(Belt_MapString.get(appVersions, issue.source.id), (function (appVersions) {
                                      return Belt_Array.getByU(appVersions, (function (param) {
                                                    return AppVersionsTypes.print(param.appVersion) === version.release;
                                                  }));
                                    }));
                              return React.createElement("div", {
                                          key: version.release,
                                          className: Curry._1(Css.merge, {
                                                hd: dataTableRow(4),
                                                tl: {
                                                  hd: issue.source.versions.length === 1 ? dataTableRowCurrent : "",
                                                  tl: /* [] */0
                                                }
                                              })
                                        }, React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.display("flex"),
                                                    tl: {
                                                      hd: Css.alignItems("center"),
                                                      tl: {
                                                        hd: Icon.styles({
                                                              hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey60),
                                                              tl: {
                                                                hd: Css.transition({
                                                                      NAME: "ms",
                                                                      VAL: Styles.Duration.$$short
                                                                    }, undefined, undefined, "all"),
                                                                tl: /* [] */0
                                                              }
                                                            }),
                                                        tl: {
                                                          hd: Css.selector(" span", {
                                                                hd: Css.height(Css.px(16)),
                                                                tl: {
                                                                  hd: Css.hover({
                                                                        hd: Icon.color(Styles.Color.grey80),
                                                                        tl: /* [] */0
                                                                      }),
                                                                  tl: /* [] */0
                                                                }
                                                              }),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  })
                                            }, match !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(SimpleTooltip.WithComponent.make, {
                                                        arrowSize: 8,
                                                        arrowOffset: 12,
                                                        offset: 4,
                                                        position: "bottom-start",
                                                        tooltip: React.createElement("div", undefined, React.createElement($$Text.make, {
                                                                  size: "Tiny",
                                                                  weight: "Semi",
                                                                  children: "Release: " + version.release + (
                                                                    isLatest ? " (latest)" : ""
                                                                  )
                                                                }), React.createElement($$Text.make, {
                                                                  element: "Span",
                                                                  size: "Tiny",
                                                                  children: null
                                                                }, "First seen ", React.createElement($$Text.make, {
                                                                      element: "Span",
                                                                      weight: "Semi",
                                                                      children: DateFns.distanceInWordsToNow(false, true, match.firstSeen)
                                                                    }))),
                                                        children: React.createElement(Icon.make, {
                                                              type_: "time",
                                                              size: "small"
                                                            })
                                                      }), React.createElement(Spacer.make, {
                                                        width: 4
                                                      })) : null, React.createElement("div", {
                                                  className: pill(isImpacted)
                                                }, React.createElement($$Text.make, {
                                                      size: "Tiny",
                                                      lines: 1,
                                                      color: Styles.Color.grey80,
                                                      title: version.release,
                                                      children: version.release + (
                                                        isLatest ? " (latest)" : ""
                                                      )
                                                    }))), React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.display("flex"),
                                                    tl: {
                                                      hd: Css.justifyContent("center"),
                                                      tl: /* [] */0
                                                    }
                                                  })
                                            }, React.createElement($$Text.make, {
                                                  size: "Small",
                                                  color: Styles.Color.grey80,
                                                  children: $$Number.toLocaleString(version.eventCount)
                                                })), React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.display("flex"),
                                                    tl: {
                                                      hd: Css.justifyContent("center"),
                                                      tl: /* [] */0
                                                    }
                                                  })
                                            }, React.createElement($$Text.make, {
                                                  size: "Small",
                                                  color: Styles.Color.grey80,
                                                  children: $$Number.toLocaleString(version.issueCount)
                                                })), React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.display("flex"),
                                                    tl: {
                                                      hd: Css.justifyContent("flexEnd"),
                                                      tl: {
                                                        hd: Css.alignItems("center"),
                                                        tl: {
                                                          hd: Css.paddingRight(Css.px(12)),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  })
                                            }, React.createElement($$Text.make, {
                                                  size: "Small",
                                                  color: Styles.Color.grey80,
                                                  children: InspectorIssueViewModel.Utils.getDisplayPercentageString(version.eventCount, version.issueCount) + "%"
                                                }), React.createElement(Spacer.make, {
                                                  width: 4
                                                }), React.createElement("div", {
                                                  className: percentageBar(version.eventCount, version.issueCount)
                                                })));
                            }))),
                  key: issue.id
                }), issue.source.versions.length > 3 ? React.createElement("div", {
                    className: collapser
                  }, React.createElement(TextButton.make, {
                        onClick: (function (param) {
                            return Curry._1(releasesActions.toggle, undefined);
                          }),
                        children: null
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.grey70,
                            children: releasesOpened ? "Collapse table to show less" : "Expand table to show " + String(issue.source.versions.length - 3 | 0) + " more"
                          }), React.createElement(Spacer.make, {
                            width: 4
                          }), React.createElement(FramerMotion.AnimatePresence, {
                            initial: false,
                            children: React.createElement(FramerMotion.motion.div, {
                                  animate: releasesOpened ? "expanded" : "collapsed",
                                  transition: {
                                    duration: Styles.Duration.$$default / 1000.0
                                  },
                                  initial: "collapsed",
                                  variants: {
                                    expanded: {
                                      rotateZ: -180
                                    },
                                    collapsed: {
                                      rotateZ: 0
                                    }
                                  },
                                  className: chevronContainer,
                                  key: "openingIcon",
                                  children: React.createElement(Icon.make, {
                                        type_: "chevronDown",
                                        size: "small",
                                        color: Styles.Color.grey60
                                      })
                                })
                          }))) : React.createElement(Spacer.make, {
                    height: 16
                  }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.grey80,
                  children: "ISSUE ACROSS SOURCES"
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(2)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.grey30),
                            tl: {
                              hd: Css.marginBottom(Css.px(16)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.grey80,
                  children: tmp$6
                }), React.createElement("div", {
                  className: dataTable
                }, React.createElement("div", {
                      className: dataTableHeader(5)
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("flexStart"),
                                  tl: {
                                    hd: Css.alignItems("flexEnd"),
                                    tl: {
                                      hd: Css.marginBottom(Css.px(6)),
                                      tl: {
                                        hd: Css.paddingLeft(Css.px(16)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Tiny",
                              weight: "Semi",
                              color: Styles.Color.grey70,
                              children: "Source"
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.alignItems("flexEnd"),
                                    tl: {
                                      hd: Css.marginBottom(Css.px(6)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Tiny",
                              weight: "Semi",
                              color: Styles.Color.grey70,
                              children: "Event volume"
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.alignItems("flexEnd"),
                                    tl: {
                                      hd: Css.marginBottom(Css.px(6)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Tiny",
                              weight: "Semi",
                              color: Styles.Color.grey70,
                              children: "Issue volume"
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.alignItems("flexEnd"),
                                    tl: {
                                      hd: Css.marginBottom(Css.px(6)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Tiny",
                              weight: "Semi",
                              color: Styles.Color.grey70,
                              children: "Issue %"
                            })), React.createElement("div", undefined)), React.createElement("div", {
                      className: Curry._1(Css.merge, {
                            hd: dataTableRow(5),
                            tl: {
                              hd: dataTableRowCurrent,
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: /* [] */0
                              })
                        }, match$6 !== undefined ? React.createElement(Link.make, {
                                path: Router.Link.addDrawerItem(undefined, {
                                      NAME: "source",
                                      VAL: [
                                        match$6.id,
                                        "overview"
                                      ]
                                    }),
                                className: Curry._1(Css.merge, {
                                      hd: clickablePill,
                                      tl: {
                                        hd: Curry._1(Css.style, Styles.maxLines(1)),
                                        tl: /* [] */0
                                      }
                                    }),
                                onClick: (function (param) {
                                    return Curry._2(onIssueInteracted, issue, "ClickViewSourceInTrackingPlan");
                                  }),
                                children: React.createElement($$Text.make, {
                                      size: "Tiny",
                                      singleLine: true,
                                      title: issue.source.name,
                                      children: issue.source.name
                                    })
                              }) : React.createElement("div", {
                                className: Curry._1(Css.merge, {
                                      hd: pill(false),
                                      tl: {
                                        hd: Curry._1(Css.style, Styles.maxLines(1)),
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.createElement($$Text.make, {
                                    size: "Tiny",
                                    singleLine: true,
                                    color: Styles.Color.grey80,
                                    title: issue.source.name,
                                    children: issue.source.name
                                  }))), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.grey80,
                              children: $$Number.toLocaleString(issue.eventCount)
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.grey80,
                              children: $$Number.toLocaleString(issue.issueCount)
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.grey80,
                              children: InspectorIssueViewModel.Utils.getDisplayPercentageString(issue.eventCount, issue.issueCount) + "%"
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Tiny",
                              color: Styles.Color.grey70,
                              children: "Current issue"
                            }))), tmp$7));
}

function useIssueDetailsOpenedTracking(maybeIssue, id, savedViewStatus) {
  var schemaGroup = SchemaGroupContext.use(undefined);
  var hasSentDetailsOpenedEvent = React.useRef(false);
  var previousId = Hooks.usePrevious1(id);
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var orderedBy = match.orderedBy;
  var filters = match.filters;
  var match$1 = InspectorIssuesStore.useFilteredIssues(undefined, undefined);
  var filteredIssues = match$1.filteredIssues;
  React.useEffect((function () {
          if (typeof maybeIssue === "object" && maybeIssue.NAME === "success" && (!hasSentDetailsOpenedEvent.current || Caml_obj.caml_notequal(previousId, id))) {
            AnalyticsRe.inspectorIssueDetailsOpened(schemaGroup, InspectorIssuesHelpers.createInspectorIssuesDetailsGroup("OpenIssueDetails", maybeIssue.VAL), InspectorIssuesHelpers.createInspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.groupFilters(filters), orderedBy, filteredIssues.length), savedViewStatus === "success" || savedViewStatus === "notFound" || savedViewStatus === "loading" ? "SavedView" : "AvoCreated", schemaGroup.schemaId);
            hasSentDetailsOpenedEvent.current = true;
          }
          
        }), [maybeIssue]);
  
}

function InspectorIssueDetailsV2$SingleSourceIssueDetailsFlow(Props) {
  var pointerId = Props.pointerId;
  var match = React$1.useFlow("single-source-issues");
  var intent = match[1];
  var flow = match[0];
  React.useEffect((function () {
          if (flow.state.finished === false) {
            intent.start({force: true});
          }
          
        }), [flow]);
  return React.createElement(ProductTour.make, {
              tourId: "single-source-issues",
              children: React.createElement(ProductTour.Step.make, {
                    stepId: "step",
                    stepType: {
                      TAG: /* OnElement */0,
                      _0: pointerId
                    },
                    delay: 0,
                    position: "left",
                    transitions: [{
                        key: "complete",
                        label: "Complete",
                        icon: undefined
                      }],
                    children: React.createElement(ProductTour.GenericStep.make, {})
                  })
            });
}

function InspectorIssueDetailsV2(Props) {
  var id = Props.id;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var maybeIssue = InspectorIssuesStore.useIssue(id);
  var match = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var savedViewStatus = match.savedViewStatus;
  var model = ModelStore.useModel(undefined);
  useIssueDetailsOpenedTracking(maybeIssue, id, savedViewStatus);
  var onIssueInteracted = React.useCallback((function (issue) {
          return function (inspectorIssueDetailsInteractionType) {
            return AnalyticsRe.inspectorIssueDetailsInteraction(schemaGroup, InspectorIssuesHelpers.createInspectorIssuesDetailsGroup(inspectorIssueDetailsInteractionType, issue), savedViewStatus === "success" || savedViewStatus === "notFound" || savedViewStatus === "loading" ? "SavedView" : "AvoCreated", schemaGroup.schemaId);
          };
        }), [
        schemaGroup,
        savedViewStatus
      ]);
  if (typeof maybeIssue !== "object") {
    if (maybeIssue === "notFound") {
      return React.createElement(React.Fragment, undefined, React.createElement(DrawerHeader.make, {
                      kind: "Inspector issue",
                      children: React.createElement($$Text.make, {
                            size: "Large",
                            weight: "Semi",
                            color: Styles.Color.grey90,
                            children: "Not found"
                          })
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.height(Css.pct(100.0)),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.flexDirection("column"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Medium",
                          textAlign: "center",
                          color: Styles.Color.grey80,
                          children: "Oh noes, this Inspector issue wasn’t found."
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          textAlign: "center",
                          color: Styles.Color.grey80,
                          children: "It might have been resolved already!"
                        })));
    } else {
      return React.createElement(React.Fragment, undefined, React.createElement(DrawerHeader.make, {
                      kind: "Inspector Issue",
                      children: React.createElement($$Text.make, {
                            size: "Large",
                            weight: "Semi",
                            color: Styles.Color.grey90,
                            children: "Fetching issue..."
                          })
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.height(Css.pct(100.0)),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.flexDirection("column"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Medium",
                          textAlign: "center",
                          color: Styles.Color.grey60,
                          children: "Fetching issue..."
                        })));
    }
  }
  if (maybeIssue.NAME !== "success") {
    return React.createElement(Redirect.make, {
                path: Router.Link.swapTopDrawerItem(undefined, {
                      NAME: "inspectorIssue",
                      VAL: maybeIssue.VAL
                    })
              });
  }
  var issue = maybeIssue.VAL;
  var maybeEvent = StateUtils.getEventByName(issue.eventName, model);
  var maybeProperty = Belt_Option.flatMap(issue.propertyName, (function (propertyName) {
          return StateUtils.getPropertyByName(propertyName, model);
        }));
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.pct(100.0)),
                          tl: {
                            hd: Css.marginRight(Css.px(16)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.zIndex(1),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              id: "inspector-issue-details"
            }, React.createElement(DrawerHeader.make, {
                  kind: "Inspector issue",
                  linkToCopy: Router.Link.getSimple(undefined),
                  onBack: (function (param) {
                      return Curry._2(onIssueInteracted, issue, "ClickBackButton");
                    }),
                  onExit: (function (param) {
                      return Curry._2(onIssueInteracted, issue, "CloseIssueDetails");
                    }),
                  onLinkCopy: (function (param) {
                      return Curry._2(onIssueInteracted, issue, "CopyIssueLink");
                    }),
                  children: React.createElement($$Text.make, {
                        size: "Large",
                        weight: "Semi",
                        color: Styles.Color.grey90,
                        children: StringExt.widowless(InspectorIssueType.getIssueTypeAsText(issue.issueType))
                      })
                }), React.createElement(InspectorIssueDetailsV2$Content, {
                  issue: issue,
                  onIssueInteracted: onIssueInteracted,
                  maybeEvent: maybeEvent,
                  maybeProperty: maybeProperty
                }), React.createElement(InspectorIssueDetailsV2$SingleSourceIssueDetailsFlow, {
                  pointerId: "inspector-issue-details"
                }));
}

var make = InspectorIssueDetailsV2;

exports.make = make;
/* content Not a pure module */
