// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function getId(category) {
  return category.id;
}

function getName(category) {
  return category.name;
}

var Categories = {
  popupTitle: "Categories",
  getId: getId,
  getName: getName
};

function getId$1(property) {
  return property.id;
}

function getName$1(property) {
  return property.name;
}

var EventProperties = {
  popupTitle: "Event Properties",
  getId: getId$1,
  getName: getName$1
};

function getId$2(tuple) {
  return tuple[0].id;
}

function getName$2(tuple) {
  return tuple[0].name;
}

var GroupProperties = {
  popupTitle: "Group Properties",
  getId: getId$2,
  getName: getName$2
};

function getId$3(propertyGroup) {
  return propertyGroup.id;
}

function getName$3(propertyGroup) {
  return propertyGroup.name;
}

var PropertyGroups = {
  popupTitle: "Property Bundles",
  getId: getId$3,
  getName: getName$3
};

function getId$4(tag) {
  return tag;
}

function getName$4(tag) {
  return tag;
}

var Tags = {
  popupTitle: "Tags",
  getId: getId$4,
  getName: getName$4,
  eventAttribute: "Tag",
  eventsViewColumnName: "Tags"
};

function getId$5($$event) {
  return $$event.id;
}

function getName$5($$event) {
  return $$event.name;
}

var PropertyEvents = {
  popupTitle: "Events",
  getId: getId$5,
  getName: getName$5
};

exports.Categories = Categories;
exports.EventProperties = EventProperties;
exports.GroupProperties = GroupProperties;
exports.PropertyGroups = PropertyGroups;
exports.Tags = Tags;
exports.PropertyEvents = PropertyEvents;
/* No side effect */
