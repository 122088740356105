// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Caml = require("rescript/lib/js/caml.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");
var DiffEventPropertyGroups = require("./DiffEventPropertyGroups.bs.js");
var DiffEventPropertiesProperty = require("./DiffEventPropertiesProperty.bs.js");

function filterBySendAs(properties, sendAs) {
  return Belt_List.keepMapU(properties, (function (property) {
                if (Caml_obj.caml_equal(property.sendAs, sendAs)) {
                  return property;
                }
                
              }));
}

function DiffEventProperties$PropertyTypeDiff(Props) {
  var added = Props.added;
  var removed = Props.removed;
  var updated = Props.updated;
  var actions = Props.actions;
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var sendAs = Props.sendAs;
  var eventId = Props.eventId;
  var addedOrRemoved = Belt_List.concat(added, removed);
  var updated$1 = Belt_List.keepU(updated, (function (property) {
          return Belt_List.every(addedOrRemoved, (function (addedProperty) {
                        return addedProperty.id !== property.id;
                      }));
        }));
  var addedPropertyGroups = Belt_List.keepMapU(Belt_List.keepMapU(actions, (function (action) {
              if (typeof action === "object" && action.NAME === "AddPropertyGroupToEvent") {
                return action.VAL[1];
              }
              
            })), (function (groupId) {
          return StateUtils.getPropertyGroupById(groupId, branchModel);
        }));
  var removedPropertyGroups = Belt_List.keepMapU(Belt_List.keepMapU(actions, (function (action) {
              if (typeof action === "object" && action.NAME === "RemovePropertyGroupFromEvent") {
                return action.VAL[1];
              }
              
            })), (function (groupId) {
          return StateUtils.getPropertyGroupById(groupId, masterModel);
        }));
  var addedOrRemovedPropertyGroups = Belt_List.concat(addedPropertyGroups, removedPropertyGroups);
  if (!(Belt_List.length(removed) !== 0 || Belt_List.length(added) !== 0 || Belt_List.length(updated$1) !== 0 || sendAs === /* EventProperty */1 && Belt_List.length(addedOrRemovedPropertyGroups) !== 0)) {
    return null;
  }
  var tmp;
  if (typeof sendAs === "number") {
    switch (sendAs) {
      case /* SystemProperty */0 :
          tmp = "System properties";
          break;
      case /* EventProperty */1 :
          tmp = "Event properties";
          break;
      case /* UserProperty */2 :
          tmp = "User properties";
          break;
      
    }
  } else {
    var groupTypeId = sendAs._0;
    var match = StateUtils.getGroupTypeName(groupTypeId, branchModel);
    var match$1 = StateUtils.getGroupTypeName(groupTypeId, masterModel);
    tmp = match !== undefined ? match + " group properties" : (
        match$1 !== undefined ? match$1 + " group properties" : "Group properties"
      );
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.marginTop(Css.px(10)),
                      tl: {
                        hd: Css.marginLeft(Css.px(22)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.grey80,
                  children: tmp
                }), sendAs === /* EventProperty */1 ? React.createElement(DiffEventPropertyGroups.make, {
                    added: addedPropertyGroups,
                    removed: removedPropertyGroups,
                    branchModel: branchModel
                  }) : null, Belt_List.toArray(Belt_List.concatMany([
                      Belt_List.mapU(removed, (function (property) {
                              return React.createElement(DiffEventPropertiesProperty.make, {
                                          property: property,
                                          colors: [
                                            Styles.Color.grapeErrorSecondary,
                                            Styles.Color.grapeError
                                          ],
                                          eventId: eventId,
                                          branchModel: branchModel,
                                          masterModel: masterModel,
                                          actions: actions,
                                          key: property.id + "-removed"
                                        });
                            })),
                      Belt_List.mapU(added, (function (property) {
                              return React.createElement(DiffEventPropertiesProperty.make, {
                                          property: property,
                                          colors: [
                                            Styles.Color.mintGreenSecondary,
                                            Styles.Color.mintGreen
                                          ],
                                          eventId: eventId,
                                          branchModel: branchModel,
                                          masterModel: masterModel,
                                          actions: actions,
                                          key: property.id + "-added"
                                        });
                            })),
                      Belt_List.mapU(updated$1, (function (property) {
                              return React.createElement(DiffEventPropertiesProperty.make, {
                                          property: property,
                                          colors: [
                                            Styles.Color.grey70,
                                            Styles.Color.grey40
                                          ],
                                          eventId: eventId,
                                          branchModel: branchModel,
                                          masterModel: masterModel,
                                          actions: actions,
                                          key: property.id + "-updated"
                                        });
                            }))
                    ])));
}

var PropertyTypeDiff = {
  make: DiffEventProperties$PropertyTypeDiff
};

function DiffEventProperties$GroupTypesDiff(Props) {
  var added = Props.added;
  var removed = Props.removed;
  var updated = Props.updated;
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var actions = Props.actions;
  var eventId = Props.eventId;
  var printGroypTypeDiff = function (properties, isRemoved) {
    var __x = Belt_List.keepMapU(properties, (function (property) {
            if (typeof property.sendAs === "number") {
              return ;
            } else {
              return property;
            }
          }));
    return Belt_List.toArray(Belt_List.keepMapU(BeltListExtensions.group((function (a, b) {
                          var groupTypeIdA = a.sendAs;
                          if (typeof groupTypeIdA === "number") {
                            return 0;
                          }
                          var groupTypeIdB = b.sendAs;
                          if (typeof groupTypeIdB === "number") {
                            return 0;
                          } else {
                            return Caml.caml_string_compare(groupTypeIdA._0, groupTypeIdB._0);
                          }
                        }), __x), (function (groupProperties) {
                      var headProperty = Belt_List.headExn(groupProperties);
                      var groupTypeId = headProperty.sendAs;
                      var maybeGroupTypeId = typeof groupTypeId === "number" ? undefined : groupTypeId._0;
                      var maybeGroupType = Belt_Option.flatMapU(maybeGroupTypeId, (function (groupTypeId) {
                              if (isRemoved) {
                                return StateUtils.getGroupTypeWithArchive(groupTypeId, branchModel);
                              } else {
                                return StateUtils.getGroupType(groupTypeId, branchModel);
                              }
                            }));
                      return Belt_Option.mapU(maybeGroupType, (function (groupType) {
                                    return React.createElement(DiffEventProperties$PropertyTypeDiff, {
                                                added: filterBySendAs(added, headProperty.sendAs),
                                                removed: filterBySendAs(removed, headProperty.sendAs),
                                                updated: filterBySendAs(updated, headProperty.sendAs),
                                                actions: actions,
                                                branchModel: branchModel,
                                                masterModel: masterModel,
                                                sendAs: headProperty.sendAs,
                                                eventId: eventId,
                                                key: groupType.id
                                              });
                                  }));
                    })));
  };
  return Belt_Array.concatMany([
              printGroypTypeDiff(added, false),
              printGroypTypeDiff(removed, true),
              printGroypTypeDiff(updated, false)
            ]);
}

var GroupTypesDiff = {
  make: DiffEventProperties$GroupTypesDiff
};

function DiffEventProperties(Props) {
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var nextEvent = Props.nextEvent;
  var actions = Props.actions;
  var addedProperties = Belt_List.keepMapU(BeltListExtensions.dedupeString(Belt_List.keepMapU(actions, (function (action) {
                  if (typeof action === "object" && action.NAME === "AddPropertyRef") {
                    return action.VAL[1];
                  }
                  
                }))), (function (propertyId) {
          return StateUtils.resolvePropertyById(propertyId, branchModel);
        }));
  var removedProperties = Belt_List.keepMapU(BeltListExtensions.dedupeString(Belt_List.keepMapU(actions, (function (action) {
                  if (typeof action !== "object") {
                    return ;
                  }
                  var variant = action.NAME;
                  if (variant === "RemovePropertyRefV2" || variant === "LegacyRemovePropertyRef") {
                    return action.VAL[1];
                  }
                  
                }))), (function (propertyId) {
          return StateUtils.resolvePropertyById(propertyId, masterModel);
        }));
  var updatedProperties = Belt_List.keepMapU(BeltListExtensions.dedupeString(Belt_List.keepMapU(actions, (function (action) {
                  if (typeof action !== "object") {
                    return ;
                  }
                  var variant = action.NAME;
                  if (variant === "RemovePropertyPinnedValue" || variant === "UpdatePropertyPinnedValue" || variant === "AddPropertyToWhitelist" || variant === "RemovePropertyFromWhitelist") {
                    return action.VAL[1];
                  }
                  if (variant === "AddRule") {
                    var match = action.VAL[1];
                    if (typeof match !== "object") {
                      return ;
                    }
                    var variant$1 = match.NAME;
                    if (variant$1 === "PropertyRef" || variant$1 === "EventObjectField") {
                      return match.VAL[1];
                    } else {
                      return ;
                    }
                  }
                  if (variant === "UpdateRule") {
                    var match$1 = action.VAL[1];
                    if (typeof match$1 !== "object") {
                      return ;
                    }
                    var variant$2 = match$1.NAME;
                    if (variant$2 === "PropertyRef" || variant$2 === "EventObjectField") {
                      return match$1.VAL[1];
                    } else {
                      return ;
                    }
                  }
                  if (variant !== "RemoveRule") {
                    return ;
                  }
                  var match$2 = action.VAL[1];
                  if (typeof match$2 !== "object") {
                    return ;
                  }
                  var variant$3 = match$2.NAME;
                  if (variant$3 === "PropertyRef" || variant$3 === "EventObjectField") {
                    return match$2.VAL[1];
                  }
                  
                }))), (function (propertyId) {
          return StateUtils.resolvePropertyById(propertyId, branchModel);
        }));
  return React.createElement(React.Fragment, undefined, React.createElement(DiffEventProperties$PropertyTypeDiff, {
                  added: filterBySendAs(addedProperties, /* EventProperty */1),
                  removed: filterBySendAs(removedProperties, /* EventProperty */1),
                  updated: filterBySendAs(updatedProperties, /* EventProperty */1),
                  actions: actions,
                  branchModel: branchModel,
                  masterModel: masterModel,
                  sendAs: /* EventProperty */1,
                  eventId: nextEvent.id
                }), React.createElement(DiffEventProperties$PropertyTypeDiff, {
                  added: filterBySendAs(addedProperties, /* UserProperty */2),
                  removed: filterBySendAs(removedProperties, /* UserProperty */2),
                  updated: filterBySendAs(updatedProperties, /* UserProperty */2),
                  actions: actions,
                  branchModel: branchModel,
                  masterModel: masterModel,
                  sendAs: /* UserProperty */2,
                  eventId: nextEvent.id
                }), React.createElement(DiffEventProperties$PropertyTypeDiff, {
                  added: filterBySendAs(addedProperties, /* SystemProperty */0),
                  removed: filterBySendAs(removedProperties, /* SystemProperty */0),
                  updated: filterBySendAs(updatedProperties, /* SystemProperty */0),
                  actions: actions,
                  branchModel: branchModel,
                  masterModel: masterModel,
                  sendAs: /* SystemProperty */0,
                  eventId: nextEvent.id
                }), React.createElement(DiffEventProperties$GroupTypesDiff, {
                  added: addedProperties,
                  removed: removedProperties,
                  updated: updatedProperties,
                  branchModel: branchModel,
                  masterModel: masterModel,
                  actions: actions,
                  eventId: nextEvent.id
                }));
}

var make = DiffEventProperties;

exports.filterBySendAs = filterBySendAs;
exports.PropertyTypeDiff = PropertyTypeDiff;
exports.GroupTypesDiff = GroupTypesDiff;
exports.make = make;
/* Css Not a pure module */
