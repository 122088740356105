// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Router = require("./Router.bs.js");
var $$Comment = require("./Comment.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var PropertyUtils = require("./PropertyUtils.bs.js");
var AvoConfigTypes = require("../../shared/models/AvoConfigTypes.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var ObjectActivityLogAction = require("./ObjectActivityLogAction.bs.js");

function PropertyActivityItem(Props) {
  var schema = Props.schema;
  var $$event = Props.event;
  var property = Props.property;
  var events = Props.events;
  var model = Props.model;
  var action = Props.action;
  var userId = Props.userId;
  var userIds = Props.userIds;
  var goToId = Props.goToId;
  var openBranches = Props.openBranches;
  var showComments = Props.showComments;
  var users = FirebaseFetcherHooks.useUsers(userIds);
  if (typeof users !== "object") {
    return null;
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var match = action.contents;
  if (typeof match === "object") {
    var variant = match.NAME;
    if (variant === "AddProperty") {
      var $$event$1 = StateUtils.getEventByIdWithArchive(match.VAL[0], model);
      if ($$event$1 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "created the property and added it to the event", React.createElement("b", undefined, $$event$1.name));
      } else {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "created the property"
                  });
      }
    }
    if (variant === "RemovePropertyValidationMinOrMax") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: React.createElement(React.Fragment, undefined, "removed min or max constraint")
                });
    }
    if (variant === "TogglePropertyValidationMatchSource") {
      var match$1 = match.VAL;
      var source = StateUtils.getSourceById(match$1[2], model);
      if (source !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, match$1[3] ? "enabled " : "disabled ", React.createElement("b", undefined, match$1[1]), " to be sent as the value of the property from ", React.createElement("b", undefined, AvoConfig.getSourceName(source)))
                  });
      } else {
        return null;
      }
    }
    if (variant === "UpdatePropertyUniqueName") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: React.createElement(React.Fragment, undefined, "set unique name to " + match.VAL[1])
                });
    }
    if (variant === "RemovePropertyPermanently") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: React.createElement(React.Fragment, undefined, "deleted the property")
                });
    }
    if (variant === "RemovePropertyFromWhitelist") {
      var match$2 = match.VAL;
      var $$event$2 = StateUtils.getEventByIdWithArchive(match$2[0], model);
      if ($$event$2 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "stopped sending the property to", React.createElement("b", undefined, AvoConfigTypes.analyticsToolToJs(match$2[2])), "with the event", React.createElement("b", undefined, $$event$2.name))
                  });
      } else {
        return null;
      }
    }
    if (!(variant === "CompletedImport" || variant === "ImportDeprecated")) {
      if (variant === "CreateDemoBranch") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "created the property on a demo branch"
                  });
      }
      if (variant === "UpdatePropertyList") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "changed the property to", React.createElement("b", undefined, match.VAL[1] ? "list" : "single item"))
                  });
      }
      if (variant === "UpdatePropertyName") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "updated the name to " + match.VAL[1])
                  });
      }
      if (variant === "UpdatePropertyDescription") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "updated the description to " + match.VAL[1])
                  });
      }
      if (variant === "UpdatePropertySendAs") {
        var sendAs = match.VAL[1];
        var tmp;
        if (typeof sendAs === "number") {
          switch (sendAs) {
            case /* SystemProperty */0 :
                tmp = "system property";
                break;
            case /* EventProperty */1 :
                tmp = "event property";
                break;
            case /* UserProperty */2 :
                tmp = "user property";
                break;
            
          }
        } else {
          tmp = sendAs._0 + " group property";
        }
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "changed the property to", React.createElement("b", undefined, tmp))
                  });
      }
      if (variant === "AddRule") {
        var match$3 = match.VAL;
        var match$4 = match$3[1];
        if (typeof match$4 !== "object") {
          return null;
        }
        if (match$4.NAME !== "Property") {
          return null;
        }
        var match$5 = match$3[2];
        if (typeof match$5 === "object" && match$5.NAME === "NameMapping") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "added a name mapping")
                    });
        } else {
          return null;
        }
      }
      if (variant === "UpdatePropertyType") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "set the type to", React.createElement("b", undefined, match.VAL[1]))
                  });
      }
      if (variant === "AddPropertyRef") {
        var match$6 = match.VAL;
        var $$event$3 = StateUtils.getEventByIdWithArchive(match$6[0], model);
        if ($$event$3 !== undefined) {
          return React.createElement(ObjectActivityLogAction.make, {
                      action: action,
                      users: users$1,
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "property",
                            VAL: [
                              match$6[1],
                              undefined
                            ]
                          }),
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "added property to the event", React.createElement("b", undefined, $$event$3.name))
                    });
        } else {
          return null;
        }
      }
      if (variant === "AddPropertyValidationMinOrMax") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "added " + TrackingPlanModel.constraint_ToJs(match.VAL[1]).toLowerCase() + " constraint")
                  });
      }
      if (variant === "RemovePropertyRefV2" || variant === "LegacyRemovePropertyRef") {
        var match$7 = match.VAL;
        var $$event$4 = StateUtils.getEventByIdWithArchive(match$7[0], model);
        if ($$event$4 !== undefined) {
          return React.createElement(ObjectActivityLogAction.make, {
                      action: action,
                      users: users$1,
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "property",
                            VAL: [
                              match$7[1],
                              undefined
                            ]
                          }),
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "removed property from the event", React.createElement("b", undefined, $$event$4.name))
                    });
        } else {
          return null;
        }
      }
      if (variant === "RemovePropertyValidationMatch") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "removed the match", React.createElement("b", undefined, match.VAL[1]))
                  });
      }
      if (variant === "Archive") {
        var match$8 = match.VAL;
        if (typeof match$8 === "object" && match$8.NAME === "Property") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "archived the property")
                    });
        } else {
          return null;
        }
      }
      if (variant === "UpdatePropertyOperation") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "made the property", React.createElement("b", undefined, AvoConfig.operationLabel(match.VAL[1])))
                  });
      }
      if (variant === "TogglePropertyExcludedSource") {
        var match$9 = match.VAL;
        var source$1 = StateUtils.getSourceById(match$9[1], model);
        if (source$1 !== undefined) {
          return React.createElement(ObjectActivityLogAction.make, {
                      action: action,
                      users: users$1,
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "property",
                            VAL: [
                              match$9[0],
                              undefined
                            ]
                          }),
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, (
                            match$9[2] ? "started " : "stopped "
                          ) + "sending property to ", React.createElement("b", undefined, AvoConfig.getSourceName(source$1)))
                    });
        } else {
          return null;
        }
      }
      if (variant === "UpdatePropertyOptional") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "made the property", React.createElement("b", undefined, match.VAL[1] ? "optional" : "required"))
                  });
      }
      if (variant === "CreateProperty" || variant === "AddSystemProperty") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "created the property"
                  });
      }
      if (variant === "UpdateRule") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "updated a name mapping")
                  });
      }
      if (variant === "AddPropertyValidationMatch") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "added the match", React.createElement("b", undefined, match.VAL[1]))
                  });
      }
      if (variant === "RemovePropertyValidationMatches") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "removed all matches validation")
                  });
      }
      if (variant === "AddPropertyToWhitelist") {
        var match$10 = match.VAL;
        var $$event$5 = StateUtils.getEventByIdWithArchive(match$10[0], model);
        if ($$event$5 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "started sending the property to", React.createElement("b", undefined, AvoConfigTypes.analyticsToolToJs(match$10[2])), "with the event", React.createElement("b", undefined, $$event$5.name))
                    });
        } else {
          return null;
        }
      }
      if (variant === "RemovePropertyFromGroup") {
        var group = StateUtils.getPropertyGroupByIdWithArchive(match.VAL[0], model);
        if (group !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "removed property from the group", React.createElement("b", undefined, group.name))
                    });
        } else {
          return null;
        }
      }
      if (variant === "UpdatePropertyValidationMinOrMax") {
        var match$11 = match.VAL;
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "set " + TrackingPlanModel.constraint_ToJs(match$11[2]).toLowerCase() + " constraint to", React.createElement("b", undefined, match$11[1]))
                  });
      }
      if (variant === "UpdatePropertyPinnedValue") {
        var match$12 = match.VAL;
        var $$event$6 = StateUtils.getEventById(match$12[0], model);
        if ($$event$6 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "pinned the value on ", React.createElement("b", undefined, $$event$6.name), "to", React.createElement("b", undefined, PropertyUtils.printPinnedValue(match$12[2])))
                    });
        } else {
          return null;
        }
      }
      if (variant === "RemovePropertyValidation") {
        var validation = match.VAL[1];
        var variant$1 = validation.NAME;
        var tmp$1;
        if (variant$1 === "EndsWith" || variant$1 === "NestedProperty" || variant$1 === "Contains" || variant$1 === "StartsWith") {
          tmp$1 = null;
        } else if (variant$1 === "Max") {
          var match$13 = validation.VAL;
          if (typeof match$13 === "object") {
            var variant$2 = match$13.NAME;
            tmp$1 = variant$2 === "IntLit" || variant$2 === "FloatLit" ? React.createElement(React.Fragment, undefined, "removed the max", React.createElement("b", undefined, match$13.VAL.toString()), "constraint") : null;
          } else {
            tmp$1 = null;
          }
        } else if (variant$1 === "Min") {
          var match$14 = validation.VAL;
          if (typeof match$14 === "object") {
            var variant$3 = match$14.NAME;
            tmp$1 = variant$3 === "IntLit" || variant$3 === "FloatLit" ? React.createElement(React.Fragment, undefined, "removed the min", React.createElement("b", undefined, match$14.VAL.toString()), "constraint") : null;
          } else {
            tmp$1 = null;
          }
        } else if (variant$1 === "Shape") {
          var match$15 = validation.VAL;
          tmp$1 = match$15 && !match$15.tl ? React.createElement(React.Fragment, undefined, "removed the object key", React.createElement("b", undefined, match$15.hd.key)) : null;
        } else {
          var match$16 = validation.VAL;
          if (match$16) {
            var match$17 = match$16.hd[0];
            if (typeof match$17 === "object" && match$17.NAME === "Literal") {
              var match$18 = match$17.VAL;
              tmp$1 = typeof match$18 === "object" && match$18.NAME === "StringLit" && !match$16.tl ? React.createElement(React.Fragment, undefined, "removed the match", React.createElement("b", undefined, match$18.VAL)) : null;
            } else {
              tmp$1 = null;
            }
          } else {
            tmp$1 = React.createElement(React.Fragment, undefined, "removed all constraints");
          }
        }
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: tmp$1
                  });
      }
      if (variant === "AddPropertyValidation") {
        var validation$1 = match.VAL[1];
        var variant$4 = validation$1.NAME;
        var tmp$2;
        if (variant$4 === "EndsWith" || variant$4 === "Contains" || variant$4 === "StartsWith") {
          tmp$2 = null;
        } else if (variant$4 === "Max") {
          var match$19 = validation$1.VAL;
          if (typeof match$19 === "object") {
            var variant$5 = match$19.NAME;
            tmp$2 = variant$5 === "IntLit" || variant$5 === "FloatLit" ? React.createElement(React.Fragment, undefined, "set max constraint to", React.createElement("b", undefined, match$19.VAL.toString())) : null;
          } else {
            tmp$2 = null;
          }
        } else if (variant$4 === "Min") {
          var match$20 = validation$1.VAL;
          if (typeof match$20 === "object") {
            var variant$6 = match$20.NAME;
            tmp$2 = variant$6 === "IntLit" || variant$6 === "FloatLit" ? React.createElement(React.Fragment, undefined, "set min constraint to", React.createElement("b", undefined, match$20.VAL.toString())) : null;
          } else {
            tmp$2 = null;
          }
        } else if (variant$4 === "NestedProperty") {
          var match$21 = validation$1.VAL;
          tmp$2 = match$21 ? (
              match$21.tl ? null : React.createElement(React.Fragment, undefined, "added", React.createElement("b", undefined, Belt_Option.getWithDefault(StateUtils.getPropertyNameById(match$21.hd.id, model), "unknown property")), "to object")
            ) : React.createElement(React.Fragment, undefined, "added object validation");
        } else if (variant$4 === "Shape") {
          var match$22 = validation$1.VAL;
          if (match$22 && !match$22.tl) {
            var item = match$22.hd;
            tmp$2 = React.createElement(React.Fragment, undefined, "set the object key", React.createElement("b", undefined, item.key), "to be a " + PropertyUtils.printValue(item));
          } else {
            tmp$2 = React.createElement(React.Fragment, undefined, "added object validation");
          }
        } else {
          var match$23 = validation$1.VAL;
          if (match$23) {
            var match$24 = match$23.hd[0];
            if (typeof match$24 === "object" && match$24.NAME === "Literal") {
              var match$25 = match$24.VAL;
              tmp$2 = typeof match$25 === "object" && match$25.NAME === "StringLit" && !match$23.tl ? React.createElement(React.Fragment, undefined, "added the match", React.createElement("b", undefined, match$25.VAL)) : null;
            } else {
              tmp$2 = null;
            }
          } else {
            tmp$2 = null;
          }
        }
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: tmp$2
                  });
      }
      if (variant === "ClearPropertyExcludedSources") {
        return React.createElement(ObjectActivityLogAction.make, {
                    action: action,
                    users: users$1,
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "property",
                          VAL: [
                            match.VAL,
                            undefined
                          ]
                        }),
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "started sending the property to ", React.createElement("b", undefined, "all sources"))
                  });
      }
      if (variant === "PropertyComment") {
        if (!showComments) {
          return null;
        }
        var match$26 = match.VAL;
        var propertyId = match$26[0];
        var tmp$3 = {
          schema: schema,
          threadId: propertyId,
          itemType: "Property",
          itemName: property.name,
          commentId: match$26[1],
          events: events,
          userId: userId,
          goToId: goToId,
          action: action,
          link: (function (commentId) {
              return Router.Link.addDrawerItem(undefined, {
                          NAME: "property",
                          VAL: [
                            propertyId,
                            commentId
                          ]
                        });
            }),
          openBranches: openBranches
        };
        var tmp$4 = Belt_Option.map($$event, (function ($$event) {
                return $$event.id;
              }));
        if (tmp$4 !== undefined) {
          tmp$3.eventId = Caml_option.valFromOption(tmp$4);
        }
        return React.createElement($$Comment.make, tmp$3);
      }
      if (variant === "UpdatePropertyOptionalWhenInObject") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "made the property", React.createElement("b", undefined, match.VAL[1] ? "optional" : "required"), " when in object")
                  });
      }
      if (variant === "AddPropertyToGroup") {
        var group$1 = StateUtils.getPropertyGroupByIdWithArchive(match.VAL[0], model);
        if (group$1 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "added property to the group", React.createElement("b", undefined, group$1.name))
                    });
        } else {
          return null;
        }
      }
      if (variant === "RemovePropertyPinnedValue") {
        var $$event$7 = StateUtils.getEventById(match.VAL[0], model);
        if ($$event$7 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "removed the pinned value on ", React.createElement("b", undefined, $$event$7.name))
                    });
        } else {
          return null;
        }
      }
      if (variant === "UpdatePropertyAbsence") {
        var match$27 = match.VAL;
        var maybeEventSourceAbsence = match$27[2];
        var propertyAbsenceUpdateType = match$27[1];
        var tmp$5;
        if (typeof propertyAbsenceUpdateType === "number") {
          tmp$5 = React.createElement(React.Fragment, undefined, "on", React.createElement("b", undefined, "all events"));
        } else {
          switch (propertyAbsenceUpdateType.TAG | 0) {
            case /* Event */0 :
                var eventId = propertyAbsenceUpdateType._0;
                if (eventId === TrackingPlanModel.propertyAbsenceAllEventsKey) {
                  tmp$5 = React.createElement(React.Fragment, undefined, "on", React.createElement("b", undefined, "all events"));
                } else {
                  var $$event$8 = StateUtils.getEventByIdWithArchive(eventId, model);
                  tmp$5 = $$event$8 !== undefined ? React.createElement(React.Fragment, undefined, "on the", React.createElement("b", undefined, $$event$8.name), "event") : "on one event";
                }
                break;
            case /* Source */1 :
                var source$2 = StateUtils.getSourceByIdWithArchive(propertyAbsenceUpdateType._0, model);
                tmp$5 = source$2 !== undefined ? React.createElement(React.Fragment, undefined, "from the", React.createElement("b", undefined, AvoConfig.getSourceName(source$2)), "source") : "from one source";
                break;
            case /* EventSource */2 :
                var sourceId = propertyAbsenceUpdateType._1;
                var eventId$1 = propertyAbsenceUpdateType._0;
                if (eventId$1 === TrackingPlanModel.propertyAbsenceAllEventsKey) {
                  var source$3 = StateUtils.getSourceByIdWithArchive(sourceId, model);
                  tmp$5 = source$3 !== undefined ? React.createElement(React.Fragment, undefined, "on all events when sent from the", React.createElement("b", undefined, AvoConfig.getSourceName(source$3)), "source") : "on all events on one source";
                } else {
                  var match$28 = StateUtils.getEventByIdWithArchive(eventId$1, model);
                  var match$29 = StateUtils.getSourceByIdWithArchive(sourceId, model);
                  tmp$5 = match$28 !== undefined && match$29 !== undefined ? React.createElement(React.Fragment, undefined, "on the", React.createElement("b", undefined, match$28.name), "event when sent from the", React.createElement("b", undefined, AvoConfig.getSourceName(match$29)), "source") : "on one event and source";
                }
                break;
            
          }
        }
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "set the property to be", React.createElement("b", undefined, maybeEventSourceAbsence !== undefined ? (
                                maybeEventSourceAbsence ? "sometimes sent" : "never sent"
                              ) : "always sent"), tmp$5)
                  });
      }
      if (variant !== "Unarchive") {
        if (variant === "RemoveRule") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "removed a name mapping")
                    });
        } else {
          return null;
        }
      }
      var match$30 = match.VAL;
      if (typeof match$30 === "object" && match$30.NAME === "Property") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "unarchived the property "
                  });
      } else {
        return null;
      }
    }
    
  } else if (match !== "StartedImport") {
    return null;
  }
  return React.createElement(ObjectActivityLogAction.Content.make, {
              action: action,
              users: users$1,
              openBranches: openBranches,
              children: "imported the property"
            });
}

var make = PropertyActivityItem;

exports.make = make;
/* react Not a pure module */
