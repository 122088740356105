// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");

function EventBlot(Props) {
  var eventId = Props.eventId;
  var schemaId = Props.schemaId;
  var eventName = Props.eventName;
  return React.createElement("a", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(4), Css.px(2)),
                    tl: {
                      hd: Css.color(Styles.Color.blueSecondary),
                      tl: {
                        hd: Css.hover({
                              hd: Css.textDecoration("underline"),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.textDecoration("none"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              href: "/schemas/" + schemaId + "/events/" + eventId,
              target: "_blank"
            }, eventName);
}

var make = EventBlot;

var jsComponent = EventBlot;

exports.make = make;
exports.jsComponent = jsComponent;
/* Css Not a pure module */
