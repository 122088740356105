// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_null = require("rescript/lib/js/js_null.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var InspectorIssuesFilter = require("./InspectorIssuesFilter.bs.js");

var SavedView = {};

function encode(t) {
  return {
          id: t.id,
          name: t.name,
          createdBy: t.createdBy,
          createdAt: t.createdAt,
          lastModifiedBy: Js_null.fromOption(t.lastModifiedBy),
          lastModifiedAt: Js_null.fromOption(t.lastModifiedAt),
          filters: Belt_Array.map(t.filters, InspectorIssuesFilter.Filter.toKeyValue),
          order: Js_null.fromOption(t.order),
          isArchived: t.isArchived
        };
}

function decode(data) {
  return {
          id: data.id,
          name: data.name,
          createdBy: data.createdBy,
          createdAt: data.createdAt.toDate(),
          lastModifiedBy: Caml_option.null_to_opt(data.lastModifiedBy),
          lastModifiedAt: Belt_Option.map(Caml_option.null_to_opt(data.lastModifiedAt), (function (prim) {
                  return prim.toDate();
                })),
          filters: Belt_Array.keepMap(data.filters, InspectorIssuesFilter.Filter.fromString),
          order: Caml_option.null_to_opt(data.order),
          isArchived: data.isArchived
        };
}

exports.SavedView = SavedView;
exports.encode = encode;
exports.decode = decode;
/* InspectorIssuesFilter Not a pure module */
