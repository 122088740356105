// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Case = require("../../bs-case/src/case.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Shortid = require("shortid");
var AvoModel = require("./avoModel.bs.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Property = require("./Property.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameUtils = require("../../shared/utils/NameUtils.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var StateUtils = require("./stateUtils.bs.js");
var Fuzzaldrin = require("fuzzaldrin");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var PropertyUtils = require("./PropertyUtils.bs.js");
var AppFeatureFlag = require("./AppFeatureFlag.bs.js");
var AutofillInputNew = require("./AutofillInputNew.bs.js");
var NewPropertyModal = require("./NewPropertyModal.bs.js");
var StateFilterUtils = require("./StateFilterUtils.bs.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");
var DiscrepancyContext = require("./DiscrepancyContext.bs.js");
var GlobalStateContext = require("./GlobalStateContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var AutoFillCasingDetail = require("./AutoFillCasingDetail.bs.js");
var AutoFillInputCreateItem = require("./AutoFillInputCreateItem.bs.js");

function getId(item) {
  return item.VAL.id;
}

function messageToString(msg) {
  if (msg.NAME === "AlreadyExists") {
    var match = msg.VAL;
    return match[0] + "-" + getId(match[1]);
  }
  var match$1 = msg.VAL;
  return match$1[0] + "-" + match$1[1].id;
}

var PropertyAutofillInput = AutofillInputNew.Make({
      getId: getId,
      messageToString: messageToString
    });

var rowHoverStyle_0 = Css.backgroundColor(Styles.Color.blueBg);

var rowHoverStyle_1 = {
  hd: Css.color(Styles.Color.blueSecondary),
  tl: /* [] */0
};

var rowHoverStyle = {
  hd: rowHoverStyle_0,
  tl: rowHoverStyle_1
};

function getAllOptions(options) {
  return BeltListExtensions.flatMap(options, (function (param) {
                return param[1];
              }));
}

function queryProperties(options, currentProperties, currentGroups, propertyType, isGroupProperty, groupTypes, expectedPropertyCase, query) {
  var options$1 = Belt_List.mapU(options, (function (param) {
          return [
                  param[0],
                  Belt_List.keepU(param[1], (function (option) {
                          if (option.NAME === "Group") {
                            var group = option.VAL;
                            return !Belt_List.someU(currentGroups, (function (item) {
                                          return item.id === group.id;
                                        }));
                          }
                          var property = option.VAL;
                          return !Belt_List.some(currentProperties, (function (prop) {
                                        if (prop.id === property.id) {
                                          return true;
                                        } else {
                                          return prop.name === property.name;
                                        }
                                      }));
                        }))
                ];
        }));
  var results = Belt_List.map(options$1, (function (param) {
          var options = param[1];
          return [
                  param[0],
                  Belt_List.map(Belt_List.fromArray(Belt_Array.keepMap(Fuzzaldrin.filter(Belt_List.toArray(Belt_List.map(Belt_List.keep(options, (function (option) {
                                                  if (propertyType !== undefined) {
                                                    if (option.NAME === "Group") {
                                                      return /* EventProperty */1 === propertyType;
                                                    } else {
                                                      return Caml_obj.caml_equal(option.VAL.sendAs, propertyType);
                                                    }
                                                  } else {
                                                    return true;
                                                  }
                                                })), (function (x) {
                                              if (x.NAME === "Group") {
                                                var group = x.VAL;
                                                return {
                                                        type_: "group",
                                                        id: group.id,
                                                        name: group.name
                                                      };
                                              }
                                              var property = x.VAL;
                                              return {
                                                      type_: "property",
                                                      id: property.id,
                                                      name: property.name
                                                    };
                                            }))), NameUtils.getCorrectCase(expectedPropertyCase, query), {
                                    key: "name",
                                    maxResults: 100
                                  }), (function (item) {
                                  var match = item.type_;
                                  switch (match) {
                                    case "group" :
                                        return Belt_List.getBy(options, (function (x) {
                                                      if (typeof x === "object" && x.NAME === "Group") {
                                                        return item.id === x.VAL.id;
                                                      } else {
                                                        return false;
                                                      }
                                                    }));
                                    case "property" :
                                        return Belt_List.getBy(options, (function (x) {
                                                      if (typeof x === "object" && x.NAME === "Property") {
                                                        return item.id === x.VAL.id;
                                                      } else {
                                                        return false;
                                                      }
                                                    }));
                                    default:
                                      return ;
                                  }
                                }))), (function (item) {
                          return {
                                  NAME: "Item",
                                  VAL: item
                                };
                        }))
                ];
        }));
  var alreadyDefined = Belt_Option.isSome(Belt_List.getBy(getAllOptions(options$1), (function (x) {
              if (x.NAME === "Group") {
                return false;
              } else {
                return Case.camel(x.VAL.name) === Case.camel(query);
              }
            })));
  var nameConflict = Belt_List.getBy(getAllOptions(options$1), (function (x) {
          if (x.NAME === "Group") {
            return false;
          }
          var property = x.VAL;
          var match = property.sendAs;
          var match$1 = Case.camel(property.name) === Case.camel(query);
          if (propertyType !== undefined && typeof propertyType === "number") {
            switch (propertyType) {
              case /* SystemProperty */0 :
                  if (match !== 0) {
                    if (match$1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  break;
              case /* EventProperty */1 :
                  if (match !== 1) {
                    if (match$1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  break;
              case /* UserProperty */2 :
                  if (typeof match !== "number") {
                    if (match$1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  if (match < 2) {
                    if (match$1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  break;
              
            }
          }
          if (typeof match === "number" && match$1 && isGroupProperty) {
            return true;
          } else {
            return false;
          }
        }));
  var alreadyAdded = Belt_List.getBy(currentProperties, (function (prop) {
          return Case.camel(prop.name) === Case.camel(query);
        }));
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  if (alreadyDefined || nameConflict !== undefined) {
    exit$1 = 2;
  } else if (alreadyAdded !== undefined) {
    exit = 1;
  } else {
    tmp = query !== "" && isGroupProperty ? Belt_List.fromArray(Belt_Array.map(groupTypes, (function (param) {
                  return {
                          NAME: "CreateNewWithGrouping",
                          VAL: [
                            query,
                            param.id
                          ]
                        };
                }))) : (
        query !== "" ? ({
              hd: {
                NAME: "CreateNew",
                VAL: query
              },
              tl: /* [] */0
            }) : /* [] */0
      );
  }
  if (exit$1 === 2) {
    if (nameConflict !== undefined) {
      tmp = {
        hd: {
          NAME: "Message",
          VAL: {
            NAME: "AlreadyExists",
            VAL: [
              query,
              nameConflict
            ]
          }
        },
        tl: /* [] */0
      };
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tmp = alreadyAdded !== undefined ? ({
          hd: {
            NAME: "Message",
            VAL: {
              NAME: "AlreadyAdded",
              VAL: [
                query,
                alreadyAdded
              ]
            }
          },
          tl: /* [] */0
        }) : /* [] */0;
  }
  return Belt_List.concat(results, {
              hd: [
                undefined,
                tmp
              ],
              tl: /* [] */0
            });
}

function PropertyInput(Props) {
  var propertyType = Props.propertyType;
  var isGroupPropertyOpt = Props.isGroupProperty;
  var currentPropertiesOpt = Props.currentProperties;
  var currentGroupsOpt = Props.currentGroups;
  var eventName = Props.eventName;
  var eventId = Props.eventId;
  var options = Props.options;
  var onSelect = Props.onSelect;
  var canCreateOpt = Props.canCreate;
  var extraCreateActions = Props.extraCreateActions;
  var onCreateStartOpt = Props.onCreateStart;
  var onCreateSuccessOpt = Props.onCreateSuccess;
  var model = Props.model;
  var currentFilters = Props.currentFilters;
  var getIndexOfValue = Props.getIndexOfValue;
  var renderButton = Props.renderButton;
  var keepExpandedOpt = Props.keepExpanded;
  var parent = Props.parent;
  var sendActions = Props.sendActions;
  var filters = Props.filters;
  var propertyLocation = Props.propertyLocation;
  var isGroupProperty = isGroupPropertyOpt !== undefined ? isGroupPropertyOpt : false;
  var currentProperties = currentPropertiesOpt !== undefined ? currentPropertiesOpt : /* [] */0;
  var currentGroups = currentGroupsOpt !== undefined ? currentGroupsOpt : /* [] */0;
  var canCreate = canCreateOpt !== undefined ? canCreateOpt : false;
  var onCreateStart = onCreateStartOpt !== undefined ? onCreateStartOpt : (function (param) {
        
      });
  var onCreateSuccess = onCreateSuccessOpt !== undefined ? onCreateSuccessOpt : (function (_id, _name, _branch) {
        
      });
  var keepExpanded = keepExpandedOpt !== undefined ? keepExpandedOpt : false;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedPropertyCase = match.expectedPropertyCase;
  var hasAnyValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("AnyValues");
  var hasLongValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("LongValues");
  var match$1 = GlobalStateContext.use(undefined);
  var sourceFilterNames = StateFilterUtils.getSourceFilterNames(match$1.filters, model);
  var currentProperties$1 = React.useMemo((function () {
          return AvoModel.resolveProperties(model, currentProperties);
        }), [
        model.properties,
        currentProperties
      ]);
  var tmp;
  if (propertyType !== undefined) {
    if (typeof propertyType === "number") {
      switch (propertyType) {
        case /* SystemProperty */0 :
            tmp = "Add System Property...";
            break;
        case /* EventProperty */1 :
            tmp = "Add Event Property...";
            break;
        case /* UserProperty */2 :
            tmp = "Add User Property...";
            break;
        
      }
    } else {
      tmp = "Add Group Property...";
    }
  } else {
    tmp = "Add Property...";
  }
  var partial_arg = model.groupTypes;
  var tmp$1 = {
    onSelect: (function (item, query, param) {
        if (item.NAME === "Group") {
          return Curry._2(onSelect, {
                      NAME: "Group",
                      VAL: item.VAL
                    }, query);
        }
        var property = item.VAL;
        Curry._2(onSelect, {
              NAME: "Property",
              VAL: property
            }, NameUtils.getCorrectCase(expectedPropertyCase, query));
        return AnalyticsRe.propertyAdded(schemaGroup, AvoUtils.getAnalyticsPropertyGroup(model, property), currentFilters, Belt_Option.getWithDefault(eventId, "N/A"), Belt_Option.getWithDefault(eventName, "N/A"), property.name.length, "Existing", NameUtils.getCorrectCase("Sentence", property.name).split(" ").length, query.length, "EventDetails", schemaGroup.branchId, schemaGroup.schemaId);
      }),
    onCreate: (function (name, maybeGroupTypeId) {
        var sendAs = maybeGroupTypeId !== undefined ? (
            isGroupProperty ? /* GroupProperty */({
                  _0: maybeGroupTypeId
                }) : (
                propertyType !== undefined ? propertyType : /* EventProperty */1
              )
          ) : (
            propertyType !== undefined ? propertyType : /* EventProperty */1
          );
        var name$1 = NameUtils.getCorrectCase(expectedPropertyCase, name);
        if (!NewPropertyModal.isValid(name$1, expectedPropertyCase, model)) {
          return Pervasives.failwith("Can't create property from autofillinput with invalid name");
        }
        var propertyId = Shortid();
        var correctCase = NameUtils.isCorrectCase(expectedPropertyCase, name$1);
        Curry._1(onCreateStart, undefined);
        return NewPropertyModal.createProperty(propertyId, parent, sendActions, sendAs, NameUtils.getCorrectCase(expectedPropertyCase, name$1), expectedPropertyCase, correctCase, addToast, schemaGroup, filters, sourceFilterNames, propertyLocation, model, onCreateSuccess, extraCreateActions, currentFilters, undefined);
      }),
    placeholder: tmp,
    search: (function (param) {
        return queryProperties(options, currentProperties$1, currentGroups, propertyType, isGroupProperty, partial_arg, expectedPropertyCase, param);
      }),
    renderItem: (function (item, isActive) {
        var variant = item.NAME;
        if (variant === "CreateNew") {
          if (canCreate) {
            return React.createElement(AutoFillInputCreateItem.make, {
                        name: item.VAL,
                        correctCasing: expectedPropertyCase,
                        isActive: isActive
                      });
          } else {
            return null;
          }
        }
        if (variant === "CreateNewWithGrouping") {
          if (!canCreate) {
            return null;
          }
          var match = item.VAL;
          return React.createElement("div", {
                      className: Curry._1(Css.merge, {
                            hd: Curry._1(Css.style, Styles.button),
                            tl: {
                              hd: Curry._1(Css.style, Belt_List.concat(isActive ? rowHoverStyle : ({
                                            hd: Css.hover(rowHoverStyle),
                                            tl: /* [] */0
                                          }), {
                                        hd: Css.width(Css.pct(100)),
                                        tl: {
                                          hd: Css.padding4(Css.px(12), Css.px(15), Css.px(10), Css.px(15)),
                                          tl: {
                                            hd: Css.backgroundColor(Styles.Color.grey10),
                                            tl: {
                                              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey10),
                                              tl: {
                                                hd: Css.textAlign("left"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      })),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.regular),
                                tl: {
                                  hd: Css.color(Styles.Color.grey70),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.small),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, "\"" + match[0] + "\" not found"), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.fontSize(Css.px(14)),
                                  tl: {
                                    hd: Css.paddingTop(Css.px(2)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, "Create New " + Belt_Option.getWithDefault(StateUtils.getGroupTypeName(match[1], model), "") + " Property"));
        }
        if (variant === "Item") {
          var match$1 = item.VAL;
          if (match$1.NAME === "Group") {
            var group = match$1.VAL;
            return React.createElement("div", {
                        className: Curry._1(Css.style, Belt_List.concat({
                                  hd: Css.padding(Css.px(12)),
                                  tl: {
                                    hd: Css.borderRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.border(Css.px(1), "solid", isActive ? Styles.Color.blueBg : Styles.Color.grey20),
                                      tl: {
                                        hd: Css.margin2(Css.px(4), Css.px(8)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }, isActive ? rowHoverStyle : ({
                                      hd: Css.hover(rowHoverStyle),
                                      tl: /* [] */0
                                    })))
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("row"),
                                    tl: {
                                      hd: Css.alignItems("baseline"),
                                      tl: {
                                        hd: Css.flexWrap("wrap"),
                                        tl: {
                                          hd: Css.color(isActive ? Styles.Color.blue : Styles.Color.grey90),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                children: group.name
                              }), React.createElement(Spacer.make, {
                                width: 4
                              }), React.createElement($$Text.make, {
                                size: "Tiny",
                                weight: "Semi",
                                color: isActive ? Styles.Color.blue : Styles.Color.grey50,
                                children: "(" + String(Belt_List.length(group.properties)) + " Properties)"
                              })), group.description === "" ? null : React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                  height: 2
                                }), React.createElement($$Text.make, {
                                  size: "Small",
                                  children: group.description
                                })));
          }
          var item$1 = match$1.VAL;
          var groupTypeId = item$1.sendAs;
          var tmp;
          if (typeof groupTypeId === "number") {
            switch (groupTypeId) {
              case /* SystemProperty */0 :
                  tmp = "System Property";
                  break;
              case /* EventProperty */1 :
                  tmp = "Event Property";
                  break;
              case /* UserProperty */2 :
                  tmp = "User Property";
                  break;
              
            }
          } else {
            tmp = Belt_Option.mapWithDefault(StateUtils.getGroupTypeName(groupTypeId._0, model), "", (function (groupTypeName) {
                    return groupTypeName + " ";
                  })) + "Group Property";
          }
          return React.createElement("div", {
                      className: Curry._1(Css.style, Belt_List.concat({
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.flexDirection("row"),
                                  tl: {
                                    hd: Css.alignItems("baseline"),
                                    tl: {
                                      hd: Css.flexWrap("wrap"),
                                      tl: {
                                        hd: Css.paddingTop(Css.px(12)),
                                        tl: {
                                          hd: Css.paddingRight(Css.px(12)),
                                          tl: {
                                            hd: Css.paddingBottom(Css.px(12)),
                                            tl: {
                                              hd: Css.paddingLeft(Css.px(20)),
                                              tl: {
                                                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey20),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }, isActive ? rowHoverStyle : ({
                                    hd: Css.hover(rowHoverStyle),
                                    tl: /* [] */0
                                  })))
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          children: item$1.name
                        }), Belt_Option.mapWithDefault(item$1.uniqueName, null, (function (uniqueName) {
                            return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                            width: 4
                                          }), React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Semi",
                                            color: Styles.Color.grey50,
                                            children: "(" + uniqueName + ")"
                                          }));
                          })), React.createElement(Spacer.make, {
                          width: 4
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.grey40,
                          children: "– " + tmp
                        }));
        }
        var message = item.VAL;
        var tmp$1;
        if (message.NAME === "AlreadyExists") {
          var match$2 = message.VAL[1];
          if (match$2.NAME === "Group") {
            tmp$1 = "\"" + match$2.VAL.name + "\" already exists as a property bundle.";
          } else {
            var property = match$2.VAL;
            var groupTypeId$1 = property.sendAs;
            var type_;
            if (typeof groupTypeId$1 === "number") {
              switch (groupTypeId$1) {
                case /* SystemProperty */0 :
                    type_ = "a system";
                    break;
                case /* EventProperty */1 :
                    type_ = "an event";
                    break;
                case /* UserProperty */2 :
                    type_ = "an user";
                    break;
                
              }
            } else {
              var groupTypeName = StateUtils.getGroupTypeName(groupTypeId$1._0, model);
              type_ = groupTypeName !== undefined ? "a " + groupTypeName + " group" : "a group";
            }
            tmp$1 = "\"" + property.name + "\" already exists as " + type_ + " property.";
          }
        } else {
          var match$3 = message.VAL;
          var property$1 = match$3[1];
          if (match$3[0] === property$1.name) {
            var groupTypeId$2 = property$1.sendAs;
            var type_$1;
            if (typeof groupTypeId$2 === "number") {
              switch (groupTypeId$2) {
                case /* SystemProperty */0 :
                    type_$1 = "system";
                    break;
                case /* EventProperty */1 :
                    type_$1 = "event";
                    break;
                case /* UserProperty */2 :
                    type_$1 = "user";
                    break;
                
              }
            } else {
              var groupTypeName$1 = StateUtils.getGroupTypeName(groupTypeId$2._0, model);
              type_$1 = groupTypeName$1 !== undefined ? groupTypeName$1 + " group" : "group";
            }
            tmp$1 = "The " + type_$1 + " property \"" + property$1.name + "\" has already been added.";
          } else {
            var groupTypeId$3 = property$1.sendAs;
            var type_$2;
            if (typeof groupTypeId$3 === "number") {
              switch (groupTypeId$3) {
                case /* SystemProperty */0 :
                    type_$2 = "system";
                    break;
                case /* EventProperty */1 :
                    type_$2 = "event";
                    break;
                case /* UserProperty */2 :
                    type_$2 = "user";
                    break;
                
              }
            } else {
              var groupTypeName$2 = StateUtils.getGroupTypeName(groupTypeId$3._0, model);
              type_$2 = groupTypeName$2 !== undefined ? groupTypeName$2 + " group" : "group";
            }
            tmp$1 = "The " + type_$2 + " property \"" + property$1.name + "\" has already been added.";
          }
        }
        return React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding4(Css.px(12), Css.px(15), Css.px(10), Css.px(15)),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.grey10),
                                tl: /* [] */0
                              }
                            }
                          }
                        })
                  }, tmp$1);
      }),
    renderDetails: (function (item) {
        var variant = item.NAME;
        var tmp;
        if (variant === "CreateNew") {
          var label = item.VAL;
          tmp = NameUtils.isCorrectCase(expectedPropertyCase, label) ? null : React.createElement(AutoFillCasingDetail.make, {
                  expectedCase: expectedPropertyCase,
                  name: label,
                  kind: /* Property */0
                });
        } else if (variant === "CreateNewWithGrouping") {
          var match = item.VAL;
          var groupTypeId = match[1];
          tmp = React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: {
                            hd: Css.height(Css.pct(100)),
                            tl: /* [] */0
                          }
                        }
                      }
                    })
              }, React.createElement("div", undefined, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.width("fitContent"),
                              tl: /* [] */0
                            })
                      }, React.createElement(Property.GroupTypePill.make, {
                            groupTypeName: Belt_Option.getWithDefault(StateUtils.getGroupTypeName(groupTypeId, model), groupTypeId)
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.regular),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.small),
                                tl: {
                                  hd: Css.padding2(Css.px(5), Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, "Create New Property \"" + (match[0] + "\""))));
        } else if (variant === "Item") {
          var match$1 = item.VAL;
          if (match$1.NAME === "Group") {
            tmp = null;
          } else {
            var item$1 = match$1.VAL;
            var usageCount = Belt_List.length(StateUtils.eventsSendingProperty(model, undefined, item$1.id));
            tmp = React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.justifyContent("spaceBetween"),
                            tl: {
                              hd: Css.height(Css.pct(100)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: /* [] */0
                                }
                              })
                        }, item$1.name), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.padding2(Css.px(5), Css.px(0)),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.small),
                                  tl: {
                                    hd: Css.fontWeight(Styles.FontWeight.regular),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, PropertyUtils.printPropertyType(item$1, undefined, undefined, model, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, undefined)), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.regular),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.small),
                                  tl: {
                                    hd: Css.padding2(Css.px(5), Css.px(0)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, item$1.description)), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.padding2(Css.px(5), Css.px(0)),
                              tl: /* [] */0
                            }
                          })
                    }, usageCount !== 0 ? (
                        usageCount !== 1 ? "Used in " + String(usageCount) + " Events" : "Used in 1 Event"
                      ) : "Never used"));
          }
        } else {
          tmp = item.VAL.NAME === "AlreadyExists" ? React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontSize(Styles.FontSize.small),
                        tl: /* [] */0
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: /* [] */0
                          })
                    }, "Why can't different types of properties have same or similar names in Avo?"), React.createElement("p", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.regular),
                            tl: /* [] */0
                          })
                    }, "In some analytics platforms and databases it can be very confusing to have different types of properties with the same or similar name. Therefor Avo is preventing you from creating multiple properties with same or similar names."), React.createElement("p", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.regular),
                            tl: /* [] */0
                          })
                    }, "If you'd like to opt out of this default behavior, please reach out to us.")) : null;
        }
        return React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding(Css.px(15)),
                          tl: {
                            hd: Css.height(Css.pct(100)),
                            tl: /* [] */0
                          }
                        })
                  }, tmp);
      }),
    refreshKey: [
      currentProperties$1,
      currentGroups,
      getAllOptions(options)
    ],
    keepExpanded: keepExpanded
  };
  if (getIndexOfValue !== undefined) {
    tmp$1.getIndexOfValue = Caml_option.valFromOption(getIndexOfValue);
  }
  if (renderButton !== undefined) {
    tmp$1.renderButton = Caml_option.valFromOption(renderButton);
  }
  return React.createElement(PropertyAutofillInput.make, tmp$1);
}

var make = PropertyInput;

exports.PropertyAutofillInput = PropertyAutofillInput;
exports.rowHoverStyle = rowHoverStyle;
exports.getAllOptions = getAllOptions;
exports.queryProperties = queryProperties;
exports.make = make;
/* PropertyAutofillInput Not a pure module */
