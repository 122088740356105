// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var DidMount = require("./DidMount.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactQuill = require("react-quill");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var QuillMagicUrl = require("quill-magic-url").default;

require('react-quill/dist/quill.snow.css')
;

require('../../quill-mention/src/quill.mention.css')
;

Curry._2(Css.$$global, " .quill", {
      hd: Css.flex({
            NAME: "num",
            VAL: 1.0
          }),
      tl: {
        hd: Css.borderWidth(Css.px(0)),
        tl: {
          hd: Css.height(Css.pct(100)),
          tl: {
            hd: Css.overflow("visible"),
            tl: /* [] */0
          }
        }
      }
    });

Curry._2(Css.$$global, " .ql-container.ql-snow", {
      hd: Css.borderWidth(Css.px(0)),
      tl: {
        hd: Css.height(Css.pct(100)),
        tl: /* [] */0
      }
    });

Curry._2(Css.$$global, " .ql-snow .ql-stroke", {
      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey40),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-snow.ql-toolbar button:hover .ql-stroke", {
      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.blue),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-snow.ql-toolbar button.ql-active .ql-stroke", {
      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.blueSecondary),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-snow .ql-fill", {
      hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey40),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-snow.ql-toolbar button:hover .ql-fill", {
      hd: Css_Legacy_Core.SVG.fill(Styles.Color.blue),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-snow.ql-toolbar button.ql-active .ql-fill", {
      hd: Css_Legacy_Core.SVG.fill(Styles.Color.blueSecondary),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-snow.ql-toolbar:after", {
      hd: Css.display("none"),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-mention-list-container", {
      hd: Css.zIndex(Styles.ZIndex.aboveAll),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.boxShadow(Styles.Shadow.box),
          tl: {
            hd: Css.overflow("hidden"),
            tl: /* [] */0
          }
        }
      }
    });

Curry._2(Css.$$global, " .ql-mention-list-item", {
      hd: Css.padding2(Css.px(0), Css.px(5)),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-mention-list-item.selected", {
      hd: Css.backgroundColor(Styles.Color.blueBg),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-tooltip", {
      hd: Css.zIndex(Styles.ZIndex.aboveAll),
      tl: /* [] */0
    });

ReactQuill.Quill.register("modules/magicUrl", QuillMagicUrl);

function QuillWithToolbar(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var id = Props.id;
  var placeholder = Props.placeholder;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var scrollOnFocusOpt = Props.scrollOnFocus;
  var classNameOpt = Props.className;
  var readOnlyOpt = Props.readOnly;
  var scrollOnFocus = scrollOnFocusOpt !== undefined ? scrollOnFocusOpt : true;
  var className = classNameOpt !== undefined ? classNameOpt : /* [] */0;
  var readOnly = readOnlyOpt !== undefined ? readOnlyOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setHasFocus = match[1];
  var hasFocus = match[0];
  var inputRef = React.useRef(null);
  var bottomRef = React.useRef(null);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.height(Css.pct(100)),
                      tl: {
                        hd: Css.position("relative"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  ref: bottomRef,
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.bottom(Css.px(0)),
                          tl: {
                            hd: Css.width(Css.px(0)),
                            tl: {
                              hd: Css.height(Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, Belt_List.concat({
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.height(Css.pct(100)),
                              tl: {
                                hd: Css.minHeight(Css.px(60)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.white),
                                  tl: {
                                    hd: Css.borderRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.overflow("hidden"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }, className)),
                  id: "editor-container"
                }, React.createElement(ReactQuill, {
                      onChange: (function (_html, _delta, source, editor) {
                          if (source === "user") {
                            return Curry._1(onChange, editor.getContents());
                          }
                          
                        }),
                      value: value,
                      modules: {
                        toolbar: {
                          container: "#toolbar-container-" + id
                        },
                        magicUrl: true
                      },
                      onFocus: (function () {
                          if (readOnly) {
                            return Belt_Option.forEach(Caml_option.nullable_to_opt(inputRef.current), (function (prim) {
                                          prim.blur();
                                          
                                        }));
                          } else {
                            Curry._1(setHasFocus, (function (param) {
                                    return true;
                                  }));
                            return Belt_Option.forEach(onFocus, (function (onFocus) {
                                          return Curry._1(onFocus, undefined);
                                        }));
                          }
                        }),
                      onBlur: (function () {
                          Curry._1(setHasFocus, (function (param) {
                                  return false;
                                }));
                          return Belt_Option.forEach(onBlur, (function (onBlur) {
                                        return Curry._1(onBlur, undefined);
                                      }));
                        }),
                      placeholder: placeholder,
                      readOnly: readOnly,
                      autoFocus: false,
                      ref: inputRef
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.height(Css.px(hasFocus ? 45 : 0)),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "all"),
                              tl: /* [] */0
                            }
                          })
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.right(Css.px(0)),
                          tl: {
                            hd: Css.bottom(Css.px(0)),
                            tl: {
                              hd: Css.left(Css.px(0)),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("spaceBetween"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.padding(Css.px(0)),
                                      tl: {
                                        hd: Css.margin(Css.px(0)),
                                        tl: {
                                          hd: Css.important(Css.borderWidth(Css.px(0))),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  id: "toolbar-container-" + id
                }, readOnly ? null : React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.opacity(hasFocus ? 1 : 0),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$default
                                    }, undefined, undefined, "all"),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement("button", {
                            className: "ql-bold"
                          }), React.createElement("button", {
                            className: "ql-italic"
                          }), React.createElement("button", {
                            className: "ql-underline"
                          }), React.createElement("button", {
                            className: "ql-list",
                            value: "ordered"
                          }), React.createElement("button", {
                            className: "ql-list",
                            value: "bullet"
                          }))), hasFocus ? React.createElement(DidMount.make, {
                    didMount: (function (param) {
                        if (scrollOnFocus) {
                          setTimeout((function (param) {
                                  return Belt_Option.forEach(Caml_option.nullable_to_opt(bottomRef.current), (function (bottomRef) {
                                                bottomRef.scrollIntoView({
                                                      block: "end",
                                                      inline: "nearest",
                                                      behavior: "smooth"
                                                    });
                                                
                                              }));
                                }), Styles.Duration.$$default | 0);
                          return ;
                        }
                        
                      })
                  }) : React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.top(Css.px(0)),
                            tl: {
                              hd: Css.right(Css.px(0)),
                              tl: {
                                hd: Css.bottom(Css.px(0)),
                                tl: {
                                  hd: Css.left(Css.px(0)),
                                  tl: {
                                    hd: Css.backgroundColor("transparent"),
                                    tl: {
                                      hd: Css.cursor("text"),
                                      tl: {
                                        hd: Css.pointerEvents("none"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }),
                    onClick: (function (param) {
                        return Belt_Option.forEach(Caml_option.nullable_to_opt(inputRef.current), (function (prim) {
                                      prim.focus();
                                      
                                    }));
                      })
                  }));
}

var make = QuillWithToolbar;

exports.make = make;
/*  Not a pure module */
