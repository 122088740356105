// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Quill = require("./externals/quill.bs.js");
var React = require("react");
var JsDiff = require("./externals/JsDiff.bs.js");
var Router = require("./Router.bs.js");
var Styles = require("./styles.bs.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var DiffEvent = require("./DiffEvent.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var QuillDelta = require("quill-delta");
var DiffComments = require("./DiffComments.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");

function DiffMetric(Props) {
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var goToCommentId = Props.goToCommentId;
  var withComments = Props.withComments;
  var events = Props.events;
  var match = Belt_Option.map(Belt_List.head(actions), (function (prim) {
          return prim[0];
        }));
  if (match === undefined) {
    return null;
  }
  if (typeof match !== "object") {
    return null;
  }
  if (match.NAME !== "Metric") {
    return null;
  }
  var metricId = match.VAL;
  var actions$1 = Belt_List.map(actions, (function (prim) {
          return prim[1];
        }));
  if (!actions$1) {
    return null;
  }
  var hasCreate = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "AddMetric";
          } else {
            return false;
          }
        }));
  var hasArchive = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          if (x.NAME !== "Archive") {
            return false;
          }
          var match = x.VAL;
          if (typeof match === "object") {
            return match.NAME === "Metric";
          } else {
            return false;
          }
        }));
  var changeType = hasCreate !== undefined ? "Create" : (
      hasArchive !== undefined ? "Archive" : "Update"
    );
  var metric = Belt_Option.getWithDefault(Belt_List.getBy(masterModel.metrics, (function (metric) {
              return metric.id === metricId;
            })), TrackingPlanModel.emptyMetric(metricId));
  var nextMetric = Belt_Option.getWithDefault(Belt_List.getBy(branchModel.metrics, (function (metric) {
              return metric.id === metricId;
            })), TrackingPlanModel.emptyMetric(metricId));
  var maybeAction = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "UpdateMetricDescription";
          } else {
            return false;
          }
        }));
  var maybeAction$1 = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "UpdateMetricType";
          } else {
            return false;
          }
        }));
  var addActions = Belt_List.keepMap(actions$1, (function (x) {
          if (typeof x === "object" && x.NAME === "AddEventToMetric") {
            return x;
          }
          
        }));
  var removeActions = Belt_List.keepMap(actions$1, (function (x) {
          if (typeof x === "object" && x.NAME === "RemoveEventFromMetric") {
            return x;
          }
          
        }));
  var actions$2 = Belt_List.concat(addActions, removeActions);
  var renderEvent = function ($$event, eventColor) {
    return React.createElement("div", {
                key: $$event.id,
                className: Curry._1(Css.style, {
                      hd: Css.fontWeight(Styles.FontWeight.semi),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.small),
                        tl: {
                          hd: Css.padding2(Css.px(10), Css.px(15)),
                          tl: {
                            hd: Css.margin4(Css.px(5), Css.px(20), Css.px(5), Css.px(0)),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", eventColor),
                                tl: {
                                  hd: Css.color(eventColor),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    })
              }, $$event.name);
  };
  var tmp;
  if (withComments) {
    var tmp$1 = {
      schemaId: schemaId,
      branchId: branchId,
      threadId: metric.id,
      itemType: "Metric",
      itemName: metric.name,
      events: events
    };
    if (goToCommentId !== undefined) {
      tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp = React.createElement(DiffComments.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              key: metricId,
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.alignSelf("flexStart"),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: {
                            hd: Css.maxWidth(DiffEvent.maxWidthPx),
                            tl: {
                              hd: Css.position("sticky"),
                              tl: {
                                hd: Css.top(Css.px(20)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: {
                                    hd: Css.padding(Css.px(15)),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.backgroundColor(Styles.Color.grey20),
                                              tl: /* [] */0
                                            }),
                                        tl: changeType === "Create" ? ({
                                              hd: Css.border(Css.px(1), "solid", Styles.Color.mintGreen),
                                              tl: /* [] */0
                                            }) : (
                                            changeType === "Update" ? ({
                                                  hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                  tl: /* [] */0
                                                }) : ({
                                                  hd: Css.border(Css.px(1), "solid", Styles.Color.grapeError),
                                                  tl: /* [] */0
                                                })
                                          )
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }),
                      onClick: (function (param) {
                          return Router.Schema.pushDrawerItem(undefined, {
                                      NAME: "metric",
                                      VAL: [
                                        metric.id,
                                        undefined
                                      ]
                                    });
                        })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: /* [] */0
                              })
                        }, JsDiff.renderStringDiff(undefined, undefined, undefined, metric.name, nextMetric.name)), maybeAction !== undefined ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(5), Css.px(0)),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.small),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement("span", {
                                dangerouslySetInnerHTML: {
                                  __html: Quill.toHTMLWithDiff(new QuillDelta(JSON.parse(metric.description)), new QuillDelta(JSON.parse(nextMetric.description)), undefined, undefined)
                                }
                              })) : null, maybeAction$1 !== undefined ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(5), Css.px(0)),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.small),
                                    tl: /* [] */0
                                  }
                                })
                          }, JsDiff.renderStringDiff(undefined, undefined, undefined, AvoUtils.getMetricTypeLabel(metric.type_), AvoUtils.getMetricTypeLabel(nextMetric.type_))) : null, Belt_List.length(actions$2) > 0 ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(5), Css.px(0)),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.small),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontSize(Styles.FontSize.regular),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.color(Styles.Color.grey90),
                                          tl: {
                                            hd: Css.marginTop(Css.px(10)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    })
                              }, "Events"), Belt_List.toArray(Belt_List.map(addActions, (function (x) {
                                      if (typeof x !== "object") {
                                        return null;
                                      }
                                      if (x.NAME !== "AddEventToMetric") {
                                        return null;
                                      }
                                      var eventId = x.VAL[2];
                                      return Belt_Option.mapWithDefault(Belt_List.getBy(branchModel.events, (function ($$event) {
                                                        return $$event.id === eventId;
                                                      })), null, (function ($$event) {
                                                    return renderEvent($$event, Styles.Color.mintGreen);
                                                  }));
                                    }))), Belt_List.toArray(Belt_List.map(removeActions, (function (x) {
                                      if (typeof x !== "object") {
                                        return null;
                                      }
                                      if (x.NAME !== "RemoveEventFromMetric") {
                                        return null;
                                      }
                                      var eventId = x.VAL[2];
                                      return Belt_Option.mapWithDefault(Belt_List.getBy(branchModel.events, (function ($$event) {
                                                        return $$event.id === eventId;
                                                      })), null, (function ($$event) {
                                                    return renderEvent($$event, Styles.Color.grapeError);
                                                  }));
                                    })))) : null)), tmp);
}

var make = DiffMetric;

exports.make = make;
/* Css Not a pure module */
