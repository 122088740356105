// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Input = require("./Input.bs.js");
var React = require("react");
var Select = require("./Select.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var PropertyValidationUtils = require("./PropertyValidationUtils.bs.js");

function PinPropertyValueModalTypeInput(Props) {
  var pinnedValue = Props.pinnedValue;
  var onChange = Props.onChange;
  var property = Props.property;
  var model = Props.model;
  var match = property.type_;
  var match$1 = PropertyValidationUtils.getPropertyMatches(property);
  switch (match) {
    case "bool" :
        return React.createElement(Select.make, {
                    onSelect: (function (value) {
                        return Curry._1(onChange, (function (param) {
                                      if (value === "") {
                                        return ;
                                      } else {
                                        return {
                                                NAME: "BooleanLit",
                                                VAL: Pervasives.bool_of_string(value)
                                              };
                                      }
                                    }));
                      }),
                    options: Belt_List.concat(pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "BooleanLit" ? ({
                              hd: [
                                {
                                  NAME: "Label",
                                  VAL: "Remove value"
                                },
                                ""
                              ],
                              tl: /* [] */0
                            }) : ({
                              hd: [
                                {
                                  NAME: "Label",
                                  VAL: "Pick a value"
                                },
                                ""
                              ],
                              tl: /* [] */0
                            }), {
                          hd: [
                            {
                              NAME: "Label",
                              VAL: "true"
                            },
                            "true"
                          ],
                          tl: {
                            hd: [
                              {
                                NAME: "Label",
                                VAL: "false"
                              },
                              "false"
                            ],
                            tl: /* [] */0
                          }
                        }),
                    value: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "BooleanLit" ? Pervasives.string_of_bool(pinnedValue.VAL) : "",
                    autoFocus: true
                  });
    case "float" :
        var tmp = {
          value: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "FloatLit" ? pinnedValue.VAL.toString() : "",
          onChange: (function (value) {
              return Curry._1(onChange, (function (param) {
                            if (value === "") {
                              return ;
                            } else {
                              return {
                                      NAME: "FloatLit",
                                      VAL: Caml_format.caml_float_of_string(value)
                                    };
                            }
                          }));
            }),
          autoFocus: true,
          number: true,
          placeholder: Belt_Option.getWithDefault(Belt_List.head(Belt_List.keepMap(property.validations, (function (validation) {
                          if (typeof validation !== "object") {
                            return ;
                          }
                          if (validation.NAME !== "Min") {
                            return ;
                          }
                          var match = validation.VAL;
                          if (typeof match === "object" && match.NAME === "FloatLit") {
                            return match.VAL.toString();
                          }
                          
                        }))), "42"),
          step: 0.001
        };
        var tmp$1 = PropertyValidationUtils.getMinFloat(property);
        if (tmp$1 !== undefined) {
          tmp.min = tmp$1;
        }
        var tmp$2 = PropertyValidationUtils.getMaxFloat(property);
        if (tmp$2 !== undefined) {
          tmp.max = tmp$2;
        }
        return React.createElement(Input.make, tmp);
    case "int" :
        var tmp$3 = {
          value: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "IntLit" ? String(pinnedValue.VAL) : "",
          onChange: (function (value) {
              return Curry._1(onChange, (function (param) {
                            if (value === "") {
                              return ;
                            } else {
                              return {
                                      NAME: "IntLit",
                                      VAL: Caml_format.caml_int_of_string(value)
                                    };
                            }
                          }));
            }),
          autoFocus: true,
          number: true,
          placeholder: Belt_Option.getWithDefault(Belt_Option.map(PropertyValidationUtils.getMinInt(property), (function (prim) {
                      return String(prim);
                    })), "42"),
          step: 1
        };
        var tmp$4 = PropertyValidationUtils.getMinFloat(property);
        if (tmp$4 !== undefined) {
          tmp$3.min = tmp$4;
        }
        var tmp$5 = PropertyValidationUtils.getMaxFloat(property);
        if (tmp$5 !== undefined) {
          tmp$3.max = tmp$5;
        }
        return React.createElement(Input.make, tmp$3);
    case "object" :
        var propertyRefs = Belt_Option.getWithDefault(Belt_List.head(Belt_List.keepMapU(property.validations, (function (validation) {
                        if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                          return validation.VAL;
                        }
                        
                      }))), /* [] */0);
        if (propertyRefs) {
          return React.createElement("div", undefined, Belt_List.toArray(Belt_List.keepMap(propertyRefs, (function (propertyRef) {
                                var maybeProperty = StateUtils.getPropertyById(propertyRef.id, model);
                                if (maybeProperty === undefined) {
                                  return ;
                                }
                                if (maybeProperty.TAG === /* PropertyRef */0) {
                                  return ;
                                }
                                var property = maybeProperty._0;
                                return Caml_option.some(React.createElement("div", undefined, React.createElement("div", undefined, property.name), React.createElement("div", undefined, property.type_)));
                              }))));
        } else {
          return "No child properties defined for this object";
        }
    case "string" :
        if (match$1) {
          return React.createElement(Select.make, {
                      onSelect: (function (value) {
                          return Curry._1(onChange, (function (param) {
                                        if (value === "") {
                                          return ;
                                        } else {
                                          return {
                                                  NAME: "StringLit",
                                                  VAL: value
                                                };
                                        }
                                      }));
                        }),
                      options: Belt_List.add(Belt_List.map(match$1, (function (match_) {
                                  return [
                                          {
                                            NAME: "Label",
                                            VAL: match_
                                          },
                                          match_
                                        ];
                                })), [
                            {
                              NAME: "Label",
                              VAL: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "StringLit" ? "Remove pinned value" : "Pick a value"
                            },
                            ""
                          ]),
                      value: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "StringLit" ? pinnedValue.VAL : "",
                      autoFocus: true
                    });
        } else {
          return React.createElement(Input.make, {
                      value: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "StringLit" ? pinnedValue.VAL : "",
                      onChange: (function (value) {
                          return Curry._1(onChange, (function (param) {
                                        if (value === "") {
                                          return ;
                                        } else {
                                          return {
                                                  NAME: "StringLit",
                                                  VAL: value
                                                };
                                        }
                                      }));
                        }),
                      autoFocus: true,
                      placeholder: "Pinned Value"
                    });
        }
    default:
      return "Cannot pin property of type " + match;
  }
}

var make = PinPropertyValueModalTypeInput;

exports.make = make;
/* Input Not a pure module */
