// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var IconX = require("../IconX.bs.js");
var Icons = require("../Icons.bs.js");
var Title = require("../Title.bs.js");
var React = require("react");
var Button = require("../Button.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Firebase = require("../../../bs-firestore/src/Firebase.bs.js");
var Intercom = require("../externals/intercom.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var IconImport = require("../IconImport.bs.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var NavigatorOs = require("../navigatorOs.bs.js");
var ImportParser = require("./ImportParser.bs.js");
var IconCheckmark = require("../IconCheckmark.bs.js");
var FramerMotion = require("framer-motion");
var ReactDropzone = require("react-dropzone").default;
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var CollapsedContent = require("../CollapsedContent.bs.js");
var ImportParserTypes = require("./ImportParserTypes.bs.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.vh(20.0)),
      tl: {
        hd: Css.width(Css.px(580)),
        tl: {
          hd: Css.maxWidth(Css.pct(100)),
          tl: /* [] */0
        }
      }
    });

function dropStyles(dragActive) {
  return Curry._1(Css.style, {
              hd: Css.overflow("hidden"),
              tl: {
                hd: Css.width(Css.pct(100.0)),
                tl: {
                  hd: Css.height(Css.px(200)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.borderRadius(Css.px(20)),
                      tl: {
                        hd: Css.border(Css.px(2), "dashed", dragActive ? Styles.Color.blue : Styles.Color.grey30),
                        tl: {
                          hd: Css.cursor("pointer"),
                          tl: {
                            hd: Css.hover({
                                  hd: Css.borderColor(Styles.Color.blue),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$short
                                  }, undefined, undefined, "border-color"),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var dropContentStyles = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.height(Css.pct(100.0)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.justifyContent("center"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var toolsStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexWrap("wrap"),
        tl: {
          hd: Css.marginTop(Css.px(8)),
          tl: /* [] */0
        }
      }
    });

var toolStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.padding(Css.px(4)),
          tl: {
            hd: Css.background(Styles.Color.white),
            tl: {
              hd: Css.borderRadius(Css.px(10)),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.grey40),
                tl: {
                  hd: Css.textDecoration("none"),
                  tl: {
                    hd: Css.color(Styles.Color.grey80),
                    tl: {
                      hd: Css.hover({
                            hd: Css.borderColor(Styles.Color.grey50),
                            tl: {
                              hd: Css.color(Styles.Color.grey90),
                              tl: /* [] */0
                            }
                          }),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$short
                            }, undefined, undefined, "border-color"),
                        tl: {
                          hd: Css.marginRight(Css.px(8)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var toolArrowStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(2)),
      tl: {
        hd: Css.marginRight(Css.px(4)),
        tl: /* [] */0
      }
    });

var errorContainerStyles = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.padding(Css.px(12)),
        tl: {
          hd: Css.borderRadius(Styles.Border.radius),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.grey50),
            tl: /* [] */0
          }
        }
      }
    });

var errorMessageStyles = Curry._1(Css.style, {
      hd: Css.borderLeft(Css.px(2), "solid", Styles.Color.grapeError),
      tl: {
        hd: Css.padding2(Css.px(0), Css.px(8)),
        tl: {
          hd: Css.margin2(Css.px(8), Css.px(0)),
          tl: {
            hd: Css.color(Styles.Color.grapeError),
            tl: /* [] */0
          }
        }
      }
    });

var errorLinkStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.color(Styles.Color.blue),
        tl: {
          hd: Css.hover({
                hd: Css.color(Styles.Color.blue),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var headerClose = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.width(Css.px(32)),
            tl: {
              hd: Css.height(Css.px(32)),
              tl: {
                hd: Css.borderWidth(Css.px(0)),
                tl: {
                  hd: Css.backgroundColor("transparent"),
                  tl: {
                    hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey50),
                    tl: {
                      hd: Css.padding(Css.px(0)),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: {
                          hd: Css.borderRadius(Css.px(100)),
                          tl: {
                            hd: Css.unsafe("appearance", "none"),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$short
                                  }, undefined, undefined, "all"),
                              tl: {
                                hd: Css.focus({
                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.blue)),
                                      tl: {
                                        hd: Css.outlineStyle("none"),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.backgroundColor(Styles.Color.grey20),
                                        tl: {
                                          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey70),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function getParsedTrackingPlan(model, file) {
  try {
    return {
            TAG: /* Ok */0,
            _0: ImportParser.parse(model, file)
          };
  }
  catch (raw_errors){
    var errors = Caml_js_exceptions.internalToOCamlException(raw_errors);
    if (errors.RE_EXN_ID === ImportParserTypes.ParseError) {
      return {
              TAG: /* Error */1,
              _0: errors._1
            };
    }
    throw errors;
  }
}

function uploadImportedFile(schemaId, branchId, file, success) {
  $$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (idToken) {
              return fetch(Firebase.apiUrl + "/import/upload", Fetch.RequestInit.make(/* Post */2, [
                                [
                                  "Accept",
                                  "application/json"
                                ],
                                [
                                  "Content-Type",
                                  "application/json"
                                ],
                                [
                                  "Authorization",
                                  "Bearer " + idToken
                                ]
                              ], Caml_option.some(JSON.stringify(Json_encode.object_({
                                            hd: [
                                              "schemaId",
                                              schemaId
                                            ],
                                            tl: {
                                              hd: [
                                                "branchId",
                                                branchId
                                              ],
                                              tl: {
                                                hd: [
                                                  "file",
                                                  file
                                                ],
                                                tl: {
                                                  hd: [
                                                    "success",
                                                    success
                                                  ],
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
            }).then(function (param) {
            return Promise.resolve(undefined);
          }), (function (error) {
          return Promise.resolve((console.error("Could not upload import file: ", error), undefined));
        }));
  
}

function uploadStatusToString(status) {
  if (typeof status !== "number") {
    return "error";
  }
  switch (status) {
    case /* Idle */0 :
        return "idle";
    case /* Uploading */1 :
        return "uploading";
    case /* Success */2 :
        return "success";
    
  }
}

function hasCsvExtension(file) {
  return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(file.name.split(".").pop()), "").toLowerCase() === "csv";
}

function hasCsvMimeType(file) {
  return file.type.toLowerCase() === "text/csv";
}

function ImportLanding(Props) {
  var model = Props.model;
  var onUploadSuccess = Props.onUploadSuccess;
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var match = React.useState(function () {
        return /* Idle */0;
      });
  var setUploadStatus = match[1];
  var uploadStatus = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setDragActive = match$1[1];
  var dragActive = match$1[0];
  var accept = NavigatorOs.os === "Windows" ? undefined : "text/csv";
  var handleDrop = React.useCallback((function (files) {
          Curry._1(setDragActive, (function (param) {
                  return false;
                }));
          var file = Belt_Array.get(files, 0);
          if (file !== undefined) {
            var file$1 = Caml_option.valFromOption(file);
            if (hasCsvExtension(file$1) || hasCsvMimeType(file$1)) {
              Curry._1(setUploadStatus, (function (param) {
                      return /* Uploading */1;
                    }));
              setTimeout((function (param) {
                      var fileReader = new FileReader(window);
                      fileReader.onload = (function ($$event) {
                          var file = $$event.target.result;
                          var parsedTrackingPlan = getParsedTrackingPlan(model, file);
                          if (parsedTrackingPlan.TAG === /* Ok */0) {
                            var parsedTrackingPlan$1 = parsedTrackingPlan._0;
                            uploadImportedFile(schemaId, branchId, file, true);
                            Curry._1(setUploadStatus, (function (param) {
                                    return /* Success */2;
                                  }));
                            setTimeout((function (param) {
                                    return Curry._1(onUploadSuccess, parsedTrackingPlan$1);
                                  }), 1000);
                            return AnalyticsRe.importFileSelected(schemaGroup, Belt_List.length(model.events), Belt_List.length(model.properties), "Success", parsedTrackingPlan$1.formatName, parsedTrackingPlan$1.warnings.parseWarnings.length, parsedTrackingPlan$1.newEvents.length + parsedTrackingPlan$1.updatedEvents.length | 0, Belt_Array.concat(parsedTrackingPlan$1.newProperties, parsedTrackingPlan$1.updatedProperties).length, parsedTrackingPlan$1.newEvents.length, parsedTrackingPlan$1.updatedEvents.length, schemaGroup.branchId, schemaGroup.schemaId);
                          }
                          var errors = parsedTrackingPlan._0;
                          console.error(errors);
                          uploadImportedFile(schemaId, branchId, file, false);
                          Curry._1(setUploadStatus, (function (param) {
                                  return /* Error */{
                                          _0: errors
                                        };
                                }));
                          return AnalyticsRe.importFileSelected(schemaGroup, Belt_List.length(model.events), Belt_List.length(model.properties), "Error", undefined, undefined, 0, 0, 0, 0, schemaGroup.branchId, schemaGroup.schemaId);
                        });
                      fileReader.readAsText(file$1, "utf-8");
                      
                    }), 350);
            } else {
              console.log("ImportLanding handleDrop: Unsupported file format!");
              window.alert("Oops! Please check that your file is a CSV file, other file formats are not supported. If the problem persists please contact support.");
            }
            return ;
          }
          console.log("ImportLanding handleDrop: No files!");
          
        }), []);
  var tmp;
  var exit = 0;
  if (typeof uploadStatus === "number") {
    switch (uploadStatus) {
      case /* Idle */0 :
          exit = 1;
          break;
      case /* Uploading */1 :
          tmp = React.createElement(Title.make, {
                children: "Uploading...",
                color: Styles.Color.grey80,
                size: "Tiny"
              });
          break;
      case /* Success */2 :
          tmp = React.createElement(IconCheckmark.make, {
                size: 40,
                color: Styles.Color.mintGreen
              });
          break;
      
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = dragActive ? React.createElement(React.Fragment, undefined, React.createElement(IconImport.make, {
                color: Styles.Color.blue,
                size: 60
              }), React.createElement(Title.make, {
                children: "Drop to upload",
                color: Styles.Color.grey80,
                size: "Tiny"
              })) : React.createElement(React.Fragment, undefined, React.createElement(IconImport.make, {
                color: Styles.Color.grey50,
                size: 60
              }), React.createElement(Title.make, {
                children: "Select a CSV file to import",
                color: Styles.Color.grey80,
                size: "Tiny"
              }), React.createElement($$Text.make, {
                size: "Small",
                weight: "Semi",
                color: Styles.Color.grey70,
                children: "or drag and drop it here"
              }));
  }
  var tmp$1 = {
    className: dropStyles(dragActive),
    onDragEnter: (function (param) {
        return Curry._1(setDragActive, (function (param) {
                      return true;
                    }));
      }),
    onDragLeave: (function (param) {
        return Curry._1(setDragActive, (function (param) {
                      return false;
                    }));
      }),
    onDrop: handleDrop,
    children: React.createElement(FramerMotion.AnimatePresence, {
          exitBeforeEnter: true,
          children: React.createElement(FramerMotion.motion.div, {
                animate: {
                  opacity: 1,
                  y: 0
                },
                transition: {
                  duration: 0.1
                },
                initial: {
                  opacity: 0,
                  y: 100
                },
                exit: {
                  opacity: 0,
                  y: -100
                },
                className: dropContentStyles,
                key: uploadStatusToString(uploadStatus) + (
                  uploadStatus === /* Idle */0 && dragActive ? "dragActive" : ""
                ),
                children: tmp
              })
        })
  };
  if (accept !== undefined) {
    tmp$1.accept = accept;
  }
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement(Title.make, {
                  children: "Import your existing tracking plan",
                  size: "Medium"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), typeof uploadStatus === "number" ? React.createElement($$Text.make, {
                    size: "Medium",
                    color: Styles.Color.grey70,
                    children: "Import your tracking plan from another tool to get a head start on designing, implementing and validating using Avo."
                  }) : React.createElement("div", {
                    className: errorContainerStyles
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("spaceBetween"),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.grey70,
                            children: "Uh-Oh! We had trouble parsing your CSV file."
                          }), React.createElement("button", {
                            className: headerClose,
                            onClick: (function (param) {
                                return Curry._1(setUploadStatus, (function (param) {
                                              return /* Idle */0;
                                            }));
                              })
                          }, React.createElement(IconX.make, {}))), React.createElement("div", {
                        className: errorMessageStyles
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            children: "File did not match any of the expected formats:"
                          }), Belt_Array.mapU(uploadStatus._0, (function (param) {
                              var errorMessage = param[1];
                              var errorPreview = param[0];
                              return React.createElement("div", {
                                          key: "parser-error-" + errorPreview,
                                          className: Curry._1(Css.style, {
                                                hd: Css.padding2(Css.px(2), Css.px(0)),
                                                tl: /* [] */0
                                              })
                                        }, React.createElement(CollapsedContent.make, {
                                              button: (function (onClick, isExpanded) {
                                                  return React.createElement("div", {
                                                              className: Curry._1(Css.style, {
                                                                    hd: Css.display("flex"),
                                                                    tl: {
                                                                      hd: Css.alignItems("center"),
                                                                      tl: /* [] */0
                                                                    }
                                                                  })
                                                            }, React.createElement($$Text.make, {
                                                                  size: "Small",
                                                                  children: errorPreview
                                                                }), React.createElement(Spacer.make, {
                                                                  width: 4
                                                                }), React.createElement(Button.make, {
                                                                  label: isExpanded ? "Show Errors" : "Hide Errors",
                                                                  onClick: (function (param) {
                                                                      return Curry._1(onClick, undefined);
                                                                    })
                                                                }));
                                                }),
                                              children: (function (param) {
                                                  return React.createElement("p", {
                                                              className: Curry._1(Css.style, {
                                                                    hd: Css.fontSize(Css.px(11)),
                                                                    tl: {
                                                                      hd: Css.margin2(Css.px(4), Css.px(0)),
                                                                      tl: {
                                                                        hd: Css.fontFamily(Styles.monoFontFamily),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  })
                                                            }, Belt_Array.mapWithIndexU(errorMessage.split("\n"), (function (index, errorLine) {
                                                                    return React.createElement(React.Fragment, undefined, index === 0 ? null : React.createElement(Spacer.make, {
                                                                                      height: 4
                                                                                    }), errorLine);
                                                                  })));
                                                })
                                            }));
                            }))), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.flexWrap("wrap"),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement("a", {
                            className: errorLinkStyles,
                            href: "https://www.avo.app/docs/workspace/tracking-plan/importing",
                            rel: "noopener",
                            target: "_blank"
                          }, React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                children: "Learn more about CSV formatting"
                              }), React.createElement("svg", {
                                height: "15",
                                width: "11",
                                fill: Styles.Color.toString(Styles.Color.blue),
                                xmlns: "http://www.w3.org/2000/svg"
                              }, React.createElement("path", {
                                    d: "M2.95 10.1l4.14-4.14-.01 3.13h1.14V4h-5.1v1.13h3.14L2.13 9.27l.82.83z"
                                  }))), React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.grey70,
                            children: ","
                          }), React.createElement(Spacer.make, {
                            width: 4
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.grey70,
                            children: "Try another file below or"
                          }), React.createElement(Spacer.make, {
                            width: 4
                          }), Intercom.isAvailable(undefined) ? React.createElement(React.Fragment, undefined, React.createElement("a", {
                                  className: errorLinkStyles,
                                  href: "#",
                                  rel: "noopener",
                                  target: "_blank",
                                  onClick: (function ($$event) {
                                      $$event.preventDefault();
                                      return Intercom.showNewMessage("Hey! I'm having trouble importing my CSV file, can you help?");
                                    })
                                }, React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Semi",
                                      children: "let us help you import"
                                    }))) : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.grey70,
                                  children: "let us help you import"
                                }), React.createElement(Spacer.make, {
                                  width: 2
                                }), React.createElement("a", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.color(Styles.Color.blue),
                                        tl: /* [] */0
                                      }),
                                  href: "mailto:support@avo.app",
                                  rel: "noopener",
                                  target: "_blank"
                                }, React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Semi",
                                      children: "support@avo.app"
                                    }))))), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(ReactDropzone, tmp$1), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.grey70,
                  children: "You’ll get a chance to review your data before finishing the import process."
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.grey70,
                  children: "Learn How to Export from Other Tools:"
                }), React.createElement("div", {
                  className: toolsStyles
                }, React.createElement("a", {
                      className: toolStyles,
                      href: "https://www.avo.app/docs/workspace/tracking-plan/importing#importing-from-amplitude",
                      rel: "noopener",
                      target: "_blank"
                    }, React.createElement(Icons.Destinations.Amplitude.make, {
                          size: 24
                        }), React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          children: "Amplitude"
                        }), React.createElement("div", {
                          className: toolArrowStyles
                        }, React.createElement("svg", {
                              height: "15",
                              width: "11",
                              fill: Styles.Color.toString(Styles.Color.grey50),
                              xmlns: "http://www.w3.org/2000/svg"
                            }, React.createElement("path", {
                                  d: "M2.95 10.1l4.14-4.14-.01 3.13h1.14V4h-5.1v1.13h3.14L2.13 9.27l.82.83z"
                                })))), React.createElement("a", {
                      className: toolStyles,
                      href: "https://www.avo.app/docs/workspace/tracking-plan/importing#importing-from-mixpanel",
                      rel: "noopener",
                      target: "_blank"
                    }, React.createElement(Icons.Destinations.Mixpanel.make, {
                          size: 24
                        }), React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          children: "Mixpanel"
                        }), React.createElement("div", {
                          className: toolArrowStyles
                        }, React.createElement("svg", {
                              height: "15",
                              width: "11",
                              fill: Styles.Color.toString(Styles.Color.grey50),
                              xmlns: "http://www.w3.org/2000/svg"
                            }, React.createElement("path", {
                                  d: "M2.95 10.1l4.14-4.14-.01 3.13h1.14V4h-5.1v1.13h3.14L2.13 9.27l.82.83z"
                                })))), React.createElement("a", {
                      className: toolStyles,
                      href: "https://www.avo.app/docs/workspace/tracking-plan/importing#importing-from-a-google-sheet",
                      rel: "noopener",
                      target: "_blank"
                    }, React.createElement(Icons.GoogleSheets.make, {
                          size: 24
                        }), React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          children: "Google Sheets"
                        }), React.createElement("div", {
                          className: toolArrowStyles
                        }, React.createElement("svg", {
                              height: "15",
                              width: "11",
                              fill: Styles.Color.toString(Styles.Color.grey50),
                              xmlns: "http://www.w3.org/2000/svg"
                            }, React.createElement("path", {
                                  d: "M2.95 10.1l4.14-4.14-.01 3.13h1.14V4h-5.1v1.13h3.14L2.13 9.27l.82.83z"
                                }))))));
}

var make = ImportLanding;

exports.rootStyles = rootStyles;
exports.dropStyles = dropStyles;
exports.dropContentStyles = dropContentStyles;
exports.toolsStyles = toolsStyles;
exports.toolStyles = toolStyles;
exports.toolArrowStyles = toolArrowStyles;
exports.errorContainerStyles = errorContainerStyles;
exports.errorMessageStyles = errorMessageStyles;
exports.errorLinkStyles = errorLinkStyles;
exports.headerClose = headerClose;
exports.getParsedTrackingPlan = getParsedTrackingPlan;
exports.uploadImportedFile = uploadImportedFile;
exports.uploadStatusToString = uploadStatusToString;
exports.hasCsvExtension = hasCsvExtension;
exports.hasCsvMimeType = hasCsvMimeType;
exports.make = make;
/* rootStyles Not a pure module */
