// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");

var context = React.createContext(undefined);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Provider = {
  makeProps: makeProps,
  make: make
};

function use(param) {
  return Belt_Option.getExn(React.useContext(context));
}

function SchemaGroupContext(Props) {
  var currentBranchId = Props.currentBranchId;
  var currentBranchName = Props.currentBranchName;
  var children = Props.children;
  var workspace = WorkspaceContext.use(undefined);
  var schemaGroup = AnalyticsUtils.makeSchemaGroup(workspace, currentBranchId, currentBranchName);
  return React.createElement(make, makeProps(schemaGroup, children, undefined));
}

var make$1 = SchemaGroupContext;

exports.context = context;
exports.Provider = Provider;
exports.use = use;
exports.make = make$1;
/* context Not a pure module */
