// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Case = require("../../bs-case/src/case.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Models = require("./Models.bs.js");
var AvoModel = require("./avoModel.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameUtils = require("../../shared/utils/NameUtils.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Debounce = require("lodash/debounce");
var BeltListExtensions = require("./BeltListExtensions.bs.js");

var emptyDiscrepanciesData_discrepancies = [];

var emptyDiscrepanciesData_config = Models.ValidationConfig.parse({});

var emptyDiscrepanciesData = {
  discrepancies: emptyDiscrepanciesData_discrepancies,
  config: emptyDiscrepanciesData_config,
  warningCount: 0,
  expectedEventCase: "None",
  expectedPropertyCase: "None"
};

var context = React.createContext(emptyDiscrepanciesData);

function DiscrepancyContext$Provider(Props) {
  var value = Props.value;
  var children = Props.children;
  var provider = context.Provider;
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

var Provider = {
  make: DiscrepancyContext$Provider
};

var debounceDiscrepencyCheck = Debounce((function (fn, param) {
        return Curry._3(fn, param[0], param[1], param[2]);
      }), 5000, {
      leading: false,
      trailing: true
    });

function getWarningsCount(discrepancy) {
  if (typeof discrepancy !== "object") {
    return 0;
  }
  var variant = discrepancy.NAME;
  if (!(variant === "PropertiesWithoutDescription" || variant === "PropertyConflictOnEvent" || variant === "EventsWithUnexpectedCase" || variant === "EventsWithoutDescription" || variant === "PropertiesWithAnyType" || variant === "SamePropertyName" || variant === "SameEventName" || variant === "PropertiesWithUnexpectedCase")) {
    return 0;
  }
  var match = discrepancy.VAL;
  if (match[0]) {
    return Belt_List.length(match[1]);
  } else {
    return 0;
  }
}

function getTotalWarningsCount(discrepancies) {
  return Belt_Array.reduce(Belt_Array.map(discrepancies, getWarningsCount), 0, (function (sum, count) {
                return sum + count | 0;
              }));
}

function hasDiscrepancies(discrepancies) {
  return getTotalWarningsCount(discrepancies) > 0;
}

function getDiscrepancies(config, model) {
  var sendAsToString = function (sendAs) {
    if (typeof sendAs === "number") {
      switch (sendAs) {
        case /* SystemProperty */0 :
            return "system";
        case /* EventProperty */1 :
            return "event";
        case /* UserProperty */2 :
            return "user";
        
      }
    } else {
      var groupName = Belt_Option.getWithDefault(StateUtils.getGroupTypeName(sendAs._0, model), "Untitled");
      return groupName + " group";
    }
  };
  var propertyConflicts = Belt_List.keepMapU(model.events, (function ($$event) {
          var __x = AvoModel.resolveProperties(model, $$event.properties);
          var hasPropertyConflicts = Belt_List.someU(BeltListExtensions.group((function (a, b) {
                      var a$1 = sendAsToString(a.sendAs) + a.name;
                      var b$1 = sendAsToString(b.sendAs) + b.name;
                      return Caml.caml_string_compare(a$1, b$1);
                    }), __x), (function (props) {
                  return Belt_List.length(props) > 1;
                }));
          if (hasPropertyConflicts) {
            return $$event;
          }
          
        }));
  var $$case = config.preferredEventCase;
  var expectedEventCase = $$case === "None" ? NameUtils.getCommonCase(StateUtils.getEventNames(model.events)) : $$case;
  var eventCaseWarnings = Belt_List.keepMapU(model.events, (function ($$event) {
          var notExpectedCase = $$event.name !== Case.toWithSeperators(expectedEventCase, $$event.name) && Case.of_($$event.name) !== expectedEventCase;
          if (notExpectedCase) {
            return $$event;
          }
          
        }));
  var $$case$1 = config.preferredPropertyCase;
  var expectedPropertyCase = $$case$1 === "None" ? NameUtils.getCommonCase(StateUtils.getPropertyNames(model.properties)) : $$case$1;
  var propertyCaseWarnings = Belt_List.keepMapU(model.properties, (function (property) {
          if (property.TAG === /* PropertyRef */0) {
            return ;
          }
          var property$1 = property._0;
          var notExpectedCase = property$1.name !== Case.to_(expectedPropertyCase, property$1.name) && Case.of_(property$1.name) !== expectedPropertyCase;
          if (notExpectedCase) {
            return property$1;
          }
          
        }));
  var propertyTypeWarnings = Belt_List.keepMapU(model.properties, (function (property) {
          if (property.TAG === /* PropertyRef */0) {
            return ;
          }
          var property$1 = property._0;
          var hasAnyType = property$1.type_ === "any";
          if (hasAnyType) {
            return property$1;
          }
          
        }));
  var eventNameConflicts = Belt_List.flatten(Belt_List.keepMapU(BeltListExtensions.group((function (a, b) {
                  var a$1 = Case.camel(a.name);
                  var b$1 = Case.camel(b.name);
                  if (a$1 === b$1) {
                    return 0;
                  } else if (a$1 > b$1) {
                    return -1;
                  } else {
                    return 1;
                  }
                }), model.events), (function (events) {
              if (Belt_List.length(events) > 1) {
                return events;
              }
              
            })));
  var __x = Belt_List.keepMapU(model.properties, (function (property) {
          if (property.TAG === /* PropertyRef */0) {
            return ;
          } else {
            return property._0;
          }
        }));
  var propertyNameConflicts = Belt_List.flatten(Belt_List.keepMapU(BeltListExtensions.group((function (a, b) {
                  var a$1 = Case.camel(a.name);
                  var b$1 = Case.camel(b.name);
                  if (a$1 === b$1) {
                    return 0;
                  } else if (a$1 > b$1) {
                    return -1;
                  } else {
                    return 1;
                  }
                }), __x), (function (properties) {
              if (Belt_List.length(properties) > 1) {
                return properties;
              }
              
            })));
  var eventsWithoutDescription = Belt_List.keepU(model.events, (function ($$event) {
          return $$event.description.trim() === "";
        }));
  var propertiesWithoutDescription = Belt_List.keepMapU(model.properties, (function (property) {
          if (property.TAG === /* PropertyRef */0) {
            return ;
          }
          var property$1 = property._0;
          if (property$1.description.trim() === "") {
            return property$1;
          }
          
        }));
  var discrepancies = [
    {
      NAME: "PropertyConflictOnEvent",
      VAL: [
        config.propertyConflictOnEvent,
        propertyConflicts
      ]
    },
    {
      NAME: "SameEventName",
      VAL: [
        config.sameEventName,
        eventNameConflicts
      ]
    },
    {
      NAME: "EventsWithUnexpectedCase",
      VAL: [
        config.eventsWithUnexpectedCase,
        eventCaseWarnings
      ]
    },
    {
      NAME: "SamePropertyName",
      VAL: [
        config.samePropertyName,
        propertyNameConflicts
      ]
    },
    {
      NAME: "PropertiesWithUnexpectedCase",
      VAL: [
        config.propertiesWithUnexpectedCase,
        propertyCaseWarnings
      ]
    },
    {
      NAME: "PropertiesWithAnyType",
      VAL: [
        config.propertiesWithAnyType,
        propertyTypeWarnings
      ]
    },
    {
      NAME: "EventsWithoutDescription",
      VAL: [
        config.eventsWithoutDescription,
        eventsWithoutDescription
      ]
    },
    {
      NAME: "PropertiesWithoutDescription",
      VAL: [
        config.propertiesWithoutDescription,
        propertiesWithoutDescription
      ]
    }
  ];
  return {
          discrepancies: discrepancies,
          config: config,
          warningCount: getTotalWarningsCount(discrepancies),
          expectedEventCase: expectedEventCase,
          expectedPropertyCase: expectedPropertyCase
        };
}

function checkDiscrepancies(config, model, setState) {
  return Curry._1(setState, (function (param) {
                return getDiscrepancies(config, model);
              }));
}

function DiscrepancyContext(Props) {
  var config = Props.config;
  var model = Props.model;
  var children = Props.children;
  var match = React.useState(function () {
        return emptyDiscrepanciesData;
      });
  var setValue = match[1];
  var parsedConfig = Models.ValidationConfig.parse(Belt_Option.getWithDefault((config == null) ? undefined : Caml_option.some(config), {}));
  React.useEffect((function () {
          var timeoutId = setTimeout((function (param) {
                  return Curry._1(setValue, (function (param) {
                                return getDiscrepancies(parsedConfig, model);
                              }));
                }), 0);
          return (function (param) {
                    clearTimeout(timeoutId);
                    
                  });
        }), []);
  React.useEffect((function () {
          debounceDiscrepencyCheck(checkDiscrepancies, [
                parsedConfig,
                model,
                setValue
              ]);
          
        }), [model]);
  React.useEffect((function () {
          Curry._1(setValue, (function (param) {
                  return getDiscrepancies(parsedConfig, model);
                }));
          
        }), [config]);
  return React.createElement(DiscrepancyContext$Provider, {
              value: match[0],
              children: children
            });
}

var make = DiscrepancyContext;

exports.emptyDiscrepanciesData = emptyDiscrepanciesData;
exports.context = context;
exports.Provider = Provider;
exports.debounceDiscrepencyCheck = debounceDiscrepencyCheck;
exports.getWarningsCount = getWarningsCount;
exports.getTotalWarningsCount = getTotalWarningsCount;
exports.hasDiscrepancies = hasDiscrepancies;
exports.getDiscrepancies = getDiscrepancies;
exports.checkDiscrepancies = checkDiscrepancies;
exports.make = make;
/* emptyDiscrepanciesData Not a pure module */
