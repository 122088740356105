// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var IconX = require("./IconX.bs.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Js_math = require("rescript/lib/js/js_math.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var TrialRequest = require("./TrialRequest.bs.js");
var FramerMotion = require("framer-motion");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var TrialProgressHooks = require("./TrialProgressHooks.bs.js");
var TrialProgressSteps = require("./TrialProgressSteps.bs.js");
var OnboardingProgressHooks = require("./OnboardingProgressHooks.bs.js");
var OnboardingProgressSteps = require("./OnboardingProgressSteps.bs.js");

function dismissOnboardingV2(schemaId) {
  Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).set({
        onboardingV2: {
          dismissed: true
        }
      }, {"merge": true});
  
}

function dismissTrialOnboarding(schemaId) {
  Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).set({
        onboardingV2: {
          trialSidebarDismissed: true
        }
      }, {"merge": true});
  
}

function containerStyles(trials) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.color(Styles.Color.white),
                      tl: {
                        hd: Css.border(Css.px(1), "solid", trials ? Styles.Color.magenta : Styles.Color.blue),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.setAlpha(trials ? Styles.Color.magenta : Styles.Color.blue, 0.5)),
                            tl: {
                              hd: Css.padding(Css.px(12)),
                              tl: {
                                hd: Css.cursor("pointer"),
                                tl: {
                                  hd: Css.textAlign("left"),
                                  tl: {
                                    hd: Css.marginBottom(Css.px(8)),
                                    tl: {
                                      hd: Css.width(Css.pct(100)),
                                      tl: {
                                        hd: Css.transition({
                                              NAME: "ms",
                                              VAL: Styles.Duration.$$default
                                            }, undefined, undefined, "all"),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.borderColor(trials ? Styles.Color.magentaSecondary : Styles.Color.blueSecondary),
                                                tl: {
                                                  hd: Css.transform(Css.translateY(Css.px(-4))),
                                                  tl: {
                                                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(20), Css.px(40), undefined, undefined, Styles.Color.setAlpha(trials ? Styles.Color.magenta : Styles.Color.blue, 0.2))),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }),
                                          tl: {
                                            hd: Css.position("relative"),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function progressBarContainerStyles(trials) {
  return Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.backgroundColor(Styles.Color.setAlpha(trials ? Styles.Color.magentaSecondary : Styles.Color.blueSecondary, 0.5)),
                tl: {
                  hd: Css.borderRadius(Css.px(100)),
                  tl: {
                    hd: Css.padding2(Css.px(2), Css.px(8)),
                    tl: {
                      hd: Css.marginTop(Css.px(4)),
                      tl: {
                        hd: Css.overflow("hidden"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var progressBarStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.zIndex(0),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function progressBarFillStyles(progress, trials) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.pct(progress)),
              tl: {
                hd: Css.backgroundColor(trials ? Styles.Color.magenta : Styles.Color.blue),
                tl: {
                  hd: Css.height(Css.pct(100)),
                  tl: /* [] */0
                }
              }
            });
}

var progressBarLabelStyles = Curry._1(Css.style, {
      hd: Css.zIndex(1),
      tl: {
        hd: Css.position("relative"),
        tl: /* [] */0
      }
    });

function stepIndexBubbleStyles(trials) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.width(Css.px(24)),
                    tl: {
                      hd: Css.height(Css.px(24)),
                      tl: {
                        hd: Css.borderRadius(Css.pct(100)),
                        tl: {
                          hd: Css.backgroundColor(trials ? Styles.Color.magenta : Styles.Color.blue),
                          tl: {
                            hd: Css.marginRight(Css.px(8)),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function closeProgressIconStyles(trials) {
  return Curry._1(Css.style, {
              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.white),
              tl: {
                hd: Css.padding(Css.px(8)),
                tl: {
                  hd: Css.position("absolute"),
                  tl: {
                    hd: Css.top(Css.px(0)),
                    tl: {
                      hd: Css.right(Css.px(0)),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$short
                            }, undefined, undefined, "all"),
                        tl: {
                          hd: Css.focus({
                                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, trials ? Styles.Color.magenta : Styles.Color.blue)),
                                tl: {
                                  hd: Css.outlineStyle("none"),
                                  tl: /* [] */0
                                }
                              }),
                          tl: {
                            hd: Css.hover({
                                  hd: Css_Legacy_Core.SVG.stroke(trials ? Styles.Color.magentaSecondary : Styles.Color.blueSecondary),
                                  tl: /* [] */0
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function OnboardingAndTrialProgressInSidebar$Progress(Props) {
  var globalSend = Props.globalSend;
  var viewerIsAdmin = Props.viewerIsAdmin;
  var steps = Props.steps;
  var currentStep = Props.currentStep;
  var onDismiss = Props.onDismiss;
  var title = Props.title;
  var secondaryOpt = Props.secondary;
  var secondary = secondaryOpt !== undefined ? secondaryOpt : false;
  var progress = Belt_Array.keep(steps, (function (step) {
          return step.completed;
        })).length / steps.length * 100;
  return React.createElement(FramerMotion.motion.div, {
              animate: {
                opacity: 1
              },
              transition: {
                duration: 0.15
              },
              initial: {
                opacity: 0
              },
              exit: {
                opacity: 0
              },
              key: "steps",
              children: React.createElement("button", {
                    className: containerStyles(secondary),
                    onClick: (function (param) {
                        return Curry._1(globalSend, {
                                    TAG: /* OpenSlideOver */6,
                                    _0: "OnboardingProgress"
                                  });
                      })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        children: title
                      }), viewerIsAdmin ? React.createElement("div", {
                          className: closeProgressIconStyles(secondary),
                          onClick: Curry._1(onDismiss, currentStep)
                        }, React.createElement(IconX.make, {
                              size: 8
                            })) : null, React.createElement("div", {
                        className: progressBarContainerStyles(secondary)
                      }, React.createElement("div", {
                            className: progressBarStyles
                          }, React.createElement("div", {
                                className: progressBarFillStyles(progress, secondary)
                              })), React.createElement("div", {
                            className: progressBarLabelStyles
                          }, React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.white,
                                children: String(Js_math.floor(progress)) + "% COMPLETE"
                              }))), currentStep !== undefined ? React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.marginTop(Css.px(12)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement("div", {
                              className: stepIndexBubbleStyles(secondary)
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  color: Styles.Color.white,
                                  children: String(currentStep.index + 1 | 0)
                                })), React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Regular",
                              children: currentStep.title
                            })) : null)
            });
}

var Progress = {
  make: OnboardingAndTrialProgressInSidebar$Progress
};

function useChecklistProgressedAnalytics(onboardingProgress, trialOnboardingProgress) {
  var schemaGroup = SchemaGroupContext.use(undefined);
  var previousOnboardingProgress = Hooks.usePrevious1(onboardingProgress);
  var previousTrialOnboardingProgress = Hooks.usePrevious1(trialOnboardingProgress);
  React.useEffect((function () {
          if (typeof previousOnboardingProgress === "object" && previousOnboardingProgress.NAME === "Loaded" && typeof onboardingProgress === "object" && onboardingProgress.NAME === "Loaded") {
            Belt_Array.mapWithIndex(Belt_Array.zip(previousOnboardingProgress.VAL[0], onboardingProgress.VAL[0]), (function (index, param) {
                    var match = Belt_Array.get(OnboardingProgressSteps.analyticsSteps, index);
                    if (match !== undefined && !(param[0].completed || !param[1].completed)) {
                      return AnalyticsRe.onboardingChecklistProgressed(schemaGroup, index / OnboardingProgressSteps.analyticsSteps.length, OnboardingProgressSteps.analyticsSteps.length - index | 0, match, Belt_Array.sliceToEnd(OnboardingProgressSteps.analyticsSteps, index), "Onboarding", schemaGroup.branchId, schemaGroup.schemaId);
                    }
                    
                  }));
          }
          
        }), [onboardingProgress]);
  React.useEffect((function () {
          if (typeof previousTrialOnboardingProgress === "object" && previousTrialOnboardingProgress.NAME === "Loaded" && typeof trialOnboardingProgress === "object" && trialOnboardingProgress.NAME === "Loaded") {
            Belt_Array.mapWithIndex(Belt_Array.zip(previousTrialOnboardingProgress.VAL[0], trialOnboardingProgress.VAL[0]), (function (index, param) {
                    var match = Belt_Array.get(TrialProgressSteps.analyticsSteps, index);
                    if (match !== undefined && !(param[0].completed || !param[1].completed)) {
                      return AnalyticsRe.onboardingChecklistProgressed(schemaGroup, index / TrialProgressSteps.analyticsSteps.length, TrialProgressSteps.analyticsSteps.length - index | 0, match, Belt_Array.sliceToEnd(TrialProgressSteps.analyticsSteps, index), "TrialOnboarding", schemaGroup.branchId, schemaGroup.schemaId);
                    }
                    
                  }));
          }
          
        }), [trialOnboardingProgress]);
  
}

function OnboardingAndTrialProgressInSidebar(Props) {
  var schema = Props.schema;
  var globalSend = Props.globalSend;
  var viewerIsAdmin = Props.viewerIsAdmin;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var onboardingProgress = OnboardingProgressHooks.useOnboardingProgress(schema);
  var onboardingComplete;
  if (typeof onboardingProgress === "object" && onboardingProgress.NAME === "Loaded") {
    var steps = onboardingProgress.VAL[0];
    onboardingComplete = Belt_Array.keep(steps, (function (step) {
            return step.completed;
          })).length === steps.length;
  } else {
    onboardingComplete = false;
  }
  var trialOnboardingProgress = TrialProgressHooks.useTrialProgress(schema);
  var trialOnboardingComplete;
  if (typeof trialOnboardingProgress === "object" && trialOnboardingProgress.NAME === "Loaded") {
    var steps$1 = trialOnboardingProgress.VAL[0];
    trialOnboardingComplete = Belt_Array.keep(steps$1, (function (step) {
            return step.completed;
          })).length === steps$1.length;
  } else {
    trialOnboardingComplete = false;
  }
  var match = TrialRequest.useTrialRequest(schema.id);
  var trialStatus = match[0];
  var trialIsActive = trialStatus === /* Ongoing */1 || trialStatus === /* Success */5;
  useChecklistProgressedAnalytics(onboardingProgress, trialOnboardingProgress);
  var hasDismissedOnboarding = Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_option.nullable_to_opt(schema.onboardingV2), (function (onboarding) {
              return Caml_option.nullable_to_opt(onboarding.dismissed);
            })), false);
  var hasDismissedTrialOnboarding = Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_option.nullable_to_opt(schema.onboardingV2), (function (onboarding) {
              return Caml_option.nullable_to_opt(onboarding.trialSidebarDismissed);
            })), false);
  var handleExitOnboarding = function (currentStep, $$event) {
    $$event.stopPropagation();
    var checklistStepsLeft = currentStep !== undefined ? Belt_Array.sliceToEnd(OnboardingProgressSteps.analyticsSteps, currentStep.index) : [];
    var numChecklistStepsLeft = checklistStepsLeft.length;
    if (numChecklistStepsLeft > 0) {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "ConfirmModal",
                    VAL: [
                      "Dismiss Onboarding Checklist?",
                      "It looks like you're not quite done with your onboarding checklist yet. Are you sure you want to dismiss the checklist for everyone in this workspace?",
                      "OK",
                      (function (param) {
                          AnalyticsRe.onboardingChecklistDismissed(schemaGroup, checklistStepsLeft, numChecklistStepsLeft, "Onboarding", schemaGroup.branchId, schemaGroup.schemaId);
                          return dismissOnboardingV2(schema.id);
                        }),
                      (function (param) {
                          
                        })
                    ]
                  }
                });
    } else {
      AnalyticsRe.onboardingChecklistDismissed(schemaGroup, checklistStepsLeft, numChecklistStepsLeft, "Onboarding", schemaGroup.branchId, schemaGroup.schemaId);
      return dismissOnboardingV2(schema.id);
    }
  };
  var handleExitTrialOnboarding = function (_currentStep, $$event) {
    $$event.stopPropagation();
    var checklistStepsLeft = typeof trialOnboardingProgress === "object" ? (
        trialOnboardingProgress.NAME === "Loaded" ? Belt_Array.map(Belt_Array.keep(Belt_Array.zip(trialOnboardingProgress.VAL[0], TrialProgressSteps.analyticsSteps), (function (param) {
                      return !param[0].completed;
                    })), (function (param) {
                  return param[1];
                })) : []
      ) : [];
    var numChecklistStepsLeft = checklistStepsLeft.length;
    if (numChecklistStepsLeft > 0) {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "ConfirmModal",
                    VAL: [
                      "Dismiss Trial Checklist?",
                      "It looks like you're not quite done with your trial checklist yet. Are you sure you want to dismiss the checklist for everyone in this workspace?",
                      "OK",
                      (function (param) {
                          AnalyticsRe.onboardingChecklistDismissed(schemaGroup, checklistStepsLeft, numChecklistStepsLeft, "TrialOnboarding", schemaGroup.branchId, schemaGroup.schemaId);
                          return dismissTrialOnboarding(schema.id);
                        }),
                      (function (param) {
                          
                        })
                    ]
                  }
                });
    } else {
      AnalyticsRe.onboardingChecklistDismissed(schemaGroup, checklistStepsLeft, numChecklistStepsLeft, "TrialOnboarding", schemaGroup.branchId, schemaGroup.schemaId);
      return dismissTrialOnboarding(schema.id);
    }
  };
  var tmp;
  var exit = 0;
  if (hasDismissedOnboarding || !(typeof onboardingProgress === "object" && onboardingProgress.NAME === "Loaded" && !onboardingComplete)) {
    exit = 1;
  } else {
    var match$1 = onboardingProgress.VAL;
    tmp = React.createElement(OnboardingAndTrialProgressInSidebar$Progress, {
          globalSend: globalSend,
          viewerIsAdmin: viewerIsAdmin,
          steps: match$1[0],
          currentStep: match$1[1],
          onDismiss: handleExitOnboarding,
          title: "Onboarding checklist"
        });
  }
  if (exit === 1) {
    if (hasDismissedTrialOnboarding || !(typeof trialOnboardingProgress === "object" && trialOnboardingProgress.NAME === "Loaded" && !trialOnboardingComplete && trialIsActive)) {
      tmp = null;
    } else {
      var match$2 = trialOnboardingProgress.VAL;
      tmp = React.createElement(OnboardingAndTrialProgressInSidebar$Progress, {
            globalSend: globalSend,
            viewerIsAdmin: viewerIsAdmin,
            steps: match$2[0],
            currentStep: match$2[1],
            onDismiss: handleExitTrialOnboarding,
            title: "Trial checklist",
            secondary: true
          });
    }
  }
  return React.createElement(FramerMotion.AnimatePresence, {
              children: tmp
            });
}

var make = OnboardingAndTrialProgressInSidebar;

exports.dismissOnboardingV2 = dismissOnboardingV2;
exports.dismissTrialOnboarding = dismissTrialOnboarding;
exports.containerStyles = containerStyles;
exports.progressBarContainerStyles = progressBarContainerStyles;
exports.progressBarStyles = progressBarStyles;
exports.progressBarFillStyles = progressBarFillStyles;
exports.progressBarLabelStyles = progressBarLabelStyles;
exports.stepIndexBubbleStyles = stepIndexBubbleStyles;
exports.closeProgressIconStyles = closeProgressIconStyles;
exports.Progress = Progress;
exports.useChecklistProgressedAnalytics = useChecklistProgressedAnalytics;
exports.make = make;
/* progressBarStyles Not a pure module */
