// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Zustand = require("./Zustand.bs.js");
var Shallow = require("zustand/shallow");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var TrackingPlanMappedModel = require("../../model/src/TrackingPlanMappedModel.bs.js");

var StoreConfig = {};

var ZustandStore = Zustand.MakeEqualityFnStore(StoreConfig, Zustand.Compare.Default);

var store = Curry._1(ZustandStore.create, (function (set, _get) {
        return {
                model: TrackingPlanModel.empty,
                mappedModel: TrackingPlanMappedModel.empty,
                latestActionId: undefined,
                updateModels: (function (model, mappedModel, latestActionId) {
                    return set(function (state) {
                                return {
                                        model: model,
                                        mappedModel: mappedModel,
                                        latestActionId: latestActionId,
                                        updateModels: state.updateModels
                                      };
                              });
                  })
              };
      }));

function useModelIsLoading(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.latestActionId === undefined;
              }));
}

function useUpdateModels(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.updateModels;
              }));
}

function useMappedModel(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.mappedModel;
              }));
}

function useMappedDestinations(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.mappedModel.destinations;
              }));
}

function useMappedEvents(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.mappedModel.events;
              }));
}

function useMappedCategories(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.mappedModel.categories;
              }));
}

function useMappedGroupTypes(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.mappedModel.groupTypes;
              }));
}

function useMappedIntegrations(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.mappedModel.integrations;
              }));
}

function useMappedMetrics(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.mappedModel.metrics;
              }));
}

function useMappedOpenBranches(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.mappedModel.openBranches;
              }));
}

function useMappedProperties(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.mappedModel.properties;
              }));
}

function useMappedPropertyBundles(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.mappedModel.propertyBundles;
              }));
}

function useMappedSources(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.mappedModel.sources;
              }));
}

function useModel(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.model;
              }));
}

function useLatestActionId(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.latestActionId;
              }));
}

function useDestinations(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.model.destinations;
              }));
}

function useEvents(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.model.events;
              }));
}

function useGoals(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.model.goals;
              }));
}

function useGroupTypes(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.model.groupTypes;
              }));
}

function useIntegrations(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.model.integrations;
              }));
}

function useMetrics(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.model.metrics;
              }));
}

function useOpenBranches(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.model.openBranches;
              }));
}

function useProperties(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.model.properties;
              }));
}

function usePropertyBundles(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.model.propertyBundles;
              }));
}

function useRules(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.model.rules;
              }));
}

function useSources(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.model.sources;
              }));
}

function use(param) {
  return Curry._3(ZustandStore.useWithCustomEqualityFn, store, (function (state) {
                return {
                        model: state.model,
                        mappedModel: state.mappedModel,
                        latestActionId: state.latestActionId
                      };
              }), Shallow.shallow);
}

exports.StoreConfig = StoreConfig;
exports.ZustandStore = ZustandStore;
exports.store = store;
exports.useModelIsLoading = useModelIsLoading;
exports.useUpdateModels = useUpdateModels;
exports.useMappedModel = useMappedModel;
exports.useMappedDestinations = useMappedDestinations;
exports.useMappedEvents = useMappedEvents;
exports.useMappedCategories = useMappedCategories;
exports.useMappedGroupTypes = useMappedGroupTypes;
exports.useMappedIntegrations = useMappedIntegrations;
exports.useMappedMetrics = useMappedMetrics;
exports.useMappedOpenBranches = useMappedOpenBranches;
exports.useMappedProperties = useMappedProperties;
exports.useMappedPropertyBundles = useMappedPropertyBundles;
exports.useMappedSources = useMappedSources;
exports.useModel = useModel;
exports.useLatestActionId = useLatestActionId;
exports.useDestinations = useDestinations;
exports.useEvents = useEvents;
exports.useGoals = useGoals;
exports.useGroupTypes = useGroupTypes;
exports.useIntegrations = useIntegrations;
exports.useMetrics = useMetrics;
exports.useOpenBranches = useOpenBranches;
exports.useProperties = useProperties;
exports.usePropertyBundles = usePropertyBundles;
exports.useRules = useRules;
exports.useSources = useSources;
exports.use = use;
/* ZustandStore Not a pure module */
