// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

var label = Curry._1(Css.merge, {
      hd: $$Text.baseStyles,
      tl: {
        hd: $$Text.sizeStyles("Small"),
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.paddingTop(Css.px(2)),
                tl: {
                  hd: Css.marginBottom(Css.px(4)),
                  tl: {
                    hd: Css.fontWeight(Styles.FontWeight.semi),
                    tl: {
                      hd: Css.color(Styles.Color.grey70),
                      tl: /* [] */0
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

function InputLabel(Props) {
  var id = Props.id;
  var htmlFor = Props.htmlFor;
  var children = Props.children;
  var tmp = {
    className: label
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (htmlFor !== undefined) {
    tmp.htmlFor = Caml_option.valFromOption(htmlFor);
  }
  return React.createElement("label", tmp, children);
}

var make = InputLabel;

exports.label = label;
exports.make = make;
/* label Not a pure module */
