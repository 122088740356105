// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var $$Window = require("./externals/window.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Intercom = require("./externals/intercom.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var RouterStore = require("./RouterStore.bs.js");
var RouterTypes = require("./RouterTypes.bs.js");
var QueryString = require("query-string");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var SharedRouterUtils = require("../../shared/utils/SharedRouterUtils.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

function schemaRouteToAnalyticsLocation(schemaRoute, drawerItems) {
  var exit = 0;
  if (schemaRoute !== undefined) {
    if (typeof schemaRoute === "object") {
      var variant = schemaRoute.NAME;
      if (variant === "inspector") {
        var match = schemaRoute.VAL;
        if (typeof match === "object") {
          if (!drawerItems) {
            return "InspectorSavedView";
          }
          exit = 1;
        } else if (match === "issues") {
          if (!drawerItems) {
            return "InspectorIssues";
          }
          exit = 1;
        } else if (match === "events") {
          if (!drawerItems) {
            return "InspectorEvents";
          }
          exit = 1;
        } else {
          if (!drawerItems) {
            return "InspectorSavedViews";
          }
          exit = 1;
        }
      } else if (variant === "import") {
        if (drawerItems) {
          exit = 1;
        } else {
          var screen = schemaRoute.VAL;
          if (screen === "success") {
            return "ImportSuccess";
          } else if (screen === "index") {
            return "ImportUpload";
          } else {
            return "ImportReview";
          }
        }
      } else if (drawerItems) {
        exit = 1;
      } else {
        var part = schemaRoute.VAL;
        if (typeof part === "object") {
          if (part.NAME === "comment") {
            return "BranchActivity";
          } else if (typeof part.VAL === "object") {
            return "BranchImplementationSource";
          } else {
            return "BranchImplementation";
          }
        } else {
          return "Diff";
        }
      }
    } else {
      if (schemaRoute === "billing") {
        return "Billing";
      }
      if (schemaRoute === "properties") {
        if (!drawerItems) {
          return "Properties";
        }
        exit = 1;
      } else {
        if (schemaRoute === "auditLog") {
          return "AuditLog";
        }
        if (schemaRoute === "settings") {
          if (!drawerItems) {
            return "Settings";
          }
          exit = 1;
        } else {
          if (schemaRoute === "dashboard") {
            return "SchemaDashboard";
          }
          if (schemaRoute === "integrations") {
            if (!drawerItems) {
              return "Integrations";
            }
            exit = 1;
          } else if (schemaRoute === "events") {
            if (!drawerItems) {
              return "Events";
            }
            exit = 1;
          } else if (schemaRoute === "metrics") {
            if (!drawerItems) {
              return "Metric";
            }
            exit = 1;
          } else if (schemaRoute === "implement") {
            if (!drawerItems) {
              return "Implement";
            }
            exit = 1;
          } else {
            if (!drawerItems) {
              return "EventPropertyGroups";
            }
            exit = 1;
          }
        }
      }
    }
  } else {
    if (!drawerItems) {
      return "SchemaDashboard";
    }
    exit = 1;
  }
  if (exit === 1) {
    var drawerItem = drawerItems.hd;
    var variant$1 = drawerItem.NAME;
    if (variant$1 === "codegenSource") {
      return "Implement";
    }
    if (variant$1 === "event") {
      var match$1 = drawerItem.VAL;
      var exit$1 = 0;
      if (match$1[1] !== undefined && match$1[2] === undefined) {
        if (!match$1[3]) {
          return "EventComment";
        }
        exit$1 = 2;
      } else {
        exit$1 = 2;
      }
      if (exit$1 === 2) {
        if (match$1[2] !== undefined) {
          if (match$1[3]) {
            if (match$1[3]) {
              return "EventFullscreen";
            } else {
              return "Event";
            }
          } else {
            return "EventTrigger";
          }
        } else if (match$1[3]) {
          return "EventFullscreen";
        } else {
          return "Event";
        }
      }
      
    } else {
      if (variant$1 === "inspectorIssue") {
        return "InspectorIssues";
      }
      if (variant$1 === "metric") {
        return "Metric";
      }
      if (variant$1 === "destination") {
        return "DestinationSettings";
      }
      if (variant$1 === "integration") {
        if (drawerItem.VAL[1] !== undefined) {
          return "IntegrationComment";
        } else {
          return "Integration";
        }
      }
      if (variant$1 === "property") {
        return "Property";
      }
      if (variant$1 === "category") {
        return "Category";
      }
      if (variant$1 !== "source") {
        return "PropertyGroup";
      }
      var sourceTab = drawerItem.VAL[1];
      if (sourceTab === "overview") {
        return "SourceSettings";
      } else if (sourceTab === "codegenSetup") {
        return "SourceFunctionsSetup";
      } else {
        return "SourceInspectorSetup";
      }
    }
  }
  
}

function baseRouteLocationToAnalyticsLocation(baseRoute, schemaRoute, drawerItems) {
  if (typeof baseRoute !== "object") {
    if (baseRoute === "passwordReset") {
      return "PasswordReset";
    } else if (baseRoute === "notFound") {
      return "NotFound";
    } else if (baseRoute === "invite") {
      return "Invite";
    } else if (baseRoute === "welcome") {
      return "Welcome";
    } else if (baseRoute === "index" || baseRoute === "allSchemas") {
      return "Schemas";
    } else if (baseRoute === "signUp") {
      return "SignUp";
    } else {
      return "SignIn";
    }
  }
  var variant = baseRoute.NAME;
  if (variant === "schema") {
    return schemaRouteToAnalyticsLocation(schemaRoute, drawerItems);
  }
  if (variant === "share") {
    return "PublicBranchImplementation";
  }
  if (variant === "onboarding") {
    return "Onboarding";
  }
  var match = baseRoute.VAL;
  if (match === "success") {
    return "AuthenticateCLISuccess";
  } else if (match === "index") {
    return "AuthenticateCLI";
  } else {
    return "AuthenticateCLIError";
  }
}

function getOrigin(param) {
  return window.location.origin;
}

function getPathName(param) {
  return window.location.pathname;
}

function getSearch(param) {
  return window.location.search;
}

function buildRedirectPath(param) {
  var pathname = window.location.pathname;
  var search = window.location.search;
  return encodeURIComponent(pathname + search);
}

function getRescriptReactRouterUrl(urlConstructorUrl) {
  var raw = urlConstructorUrl.pathname;
  var tmp;
  switch (raw) {
    case "" :
    case "/" :
        tmp = /* [] */0;
        break;
    default:
      var raw$1 = raw.slice(1);
      var match = raw$1[raw$1.length - 1 | 0];
      var raw$2 = match === "/" ? raw$1.slice(0, -1) : raw$1;
      tmp = Belt_List.fromArray(raw$2.split("/"));
  }
  var raw$3 = urlConstructorUrl.hash;
  var tmp$1;
  switch (raw$3) {
    case "" :
    case "#" :
        tmp$1 = "";
        break;
    default:
      tmp$1 = raw$3.slice(1);
  }
  var raw$4 = urlConstructorUrl.search;
  var tmp$2;
  switch (raw$4) {
    case "" :
    case "?" :
        tmp$2 = "";
        break;
    default:
      tmp$2 = raw$4.slice(1);
  }
  return {
          path: tmp,
          hash: tmp$1,
          search: tmp$2
        };
}

function getUrlParts(url) {
  var currentOrigin = window.location.origin;
  var url$1 = url.startsWith(currentOrigin) ? url : (
      url.startsWith("/") ? currentOrigin + url : currentOrigin + "/" + url
    );
  return getRescriptReactRouterUrl(new URL(url$1));
}

function getRouteFromPath(path) {
  var baseRoute = RouterTypes.BaseRoute.get(path);
  var branch = typeof baseRoute === "object" && baseRoute.NAME === "schema" ? RouterTypes.Branch.get(path) : undefined;
  var schemaRoute = typeof baseRoute === "object" && baseRoute.NAME === "schema" ? RouterTypes.SchemaRoute.get(path) : undefined;
  var drawerItems = typeof baseRoute === "object" && baseRoute.NAME === "schema" ? RouterTypes.DrawerItems.get(path) : /* [] */0;
  return {
          baseRoute: baseRoute,
          branch: branch,
          schemaRoute: schemaRoute,
          drawerItems: drawerItems
        };
}

function getStaticRoute(param) {
  var origin = window.location.origin;
  var pathName = window.location.pathname;
  var pathName$1 = pathName.startsWith("/") ? pathName : "/" + pathName;
  var search = window.location.search;
  var url = getUrlParts(origin + pathName$1 + search);
  return getRouteFromPath(url.path);
}

function getCurrentUrl(param) {
  return window.location.origin + window.location.pathname;
}

var get = SharedRouterUtils.Link.get;

function getSchemaLink(baseRoute, branch, schemaRoute, drawerItems) {
  return Curry._4(get, baseRoute, branch, schemaRoute, drawerItems);
}

function getSchemaRouteLink(branch, schemaRoute) {
  var uri = getCurrentUrl(undefined);
  var url = getUrlParts(uri);
  var route = getRouteFromPath(url.path);
  return Curry._4(get, route.baseRoute, Belt_Option.mapWithDefault(branch, route.branch, (function (branch) {
                    return branch;
                  })), schemaRoute, /* [] */0);
}

function getNewFormatPathString(url, route) {
  var oldFormatPathString = "/" + Belt_List.toArray(url.path).join("/") + ((
      url.search === "" ? "" : "?" + url.search
    ) + (
      url.hash === "" ? "" : "#" + url.hash
    ));
  var newFormatPathString = Curry._4(get, route.baseRoute, route.branch, route.schemaRoute, route.drawerItems) + ((
      url.search === "" ? "" : "?" + url.search
    ) + (
      url.hash === "" ? "" : "#" + url.hash
    ));
  var isOfOldFormat = newFormatPathString !== oldFormatPathString;
  if (isOfOldFormat) {
    return newFormatPathString;
  }
  
}

function addDrawerItem(branch, drawerItem) {
  var route = getStaticRoute(undefined);
  return Curry._4(get, route.baseRoute, Belt_Option.mapWithDefault(branch, route.branch, (function (branch) {
                    return branch;
                  })), route.schemaRoute, {
              hd: drawerItem,
              tl: route.drawerItems
            });
}

function swapTopDrawerItem(branch, drawerItem) {
  var route = getStaticRoute(undefined);
  var match = route.drawerItems;
  return Curry._4(get, route.baseRoute, Belt_Option.mapWithDefault(branch, route.branch, (function (branch) {
                    return branch;
                  })), route.schemaRoute, match ? ({
                  hd: drawerItem,
                  tl: match.tl
                }) : ({
                  hd: drawerItem,
                  tl: /* [] */0
                }));
}

function getSimple(param) {
  var uri = getCurrentUrl(undefined);
  var url = getUrlParts(uri);
  var route = getRouteFromPath(url.path);
  var drawerItem = Belt_List.head(route.drawerItems);
  return window.location.origin + Curry._4(get, route.baseRoute, route.branch, Belt_Option.mapWithDefault(drawerItem, route.schemaRoute, (function (drawerItem) {
                    return RouterTypes.DrawerItem.getParentSchemaRoute(drawerItem);
                  })), Belt_Option.mapWithDefault(drawerItem, /* [] */0, (function (drawerItem) {
                    return {
                            hd: drawerItem,
                            tl: /* [] */0
                          };
                  })));
}

function getBaseRoute(branch, baseRoute) {
  return Curry._4(get, baseRoute, Belt_Option.mapWithDefault(branch, undefined, (function (branch) {
                    return branch;
                  })), undefined, /* [] */0);
}

var Link = {
  getCurrentUrl: getCurrentUrl,
  get: get,
  getSchemaLink: getSchemaLink,
  getSchemaRouteLink: getSchemaRouteLink,
  getNewFormatPathString: getNewFormatPathString,
  addDrawerItem: addDrawerItem,
  swapTopDrawerItem: swapTopDrawerItem,
  getSimple: getSimple,
  getBaseRoute: getBaseRoute
};

function reParsePath(path) {
  var url = getUrlParts(path);
  var match = getRouteFromPath(url.path);
  var s = url.search;
  var search = s === "" ? "" : "?" + s;
  var h = url.hash;
  var hash = h === "" ? "" : "#" + h;
  return Curry._4(get, match.baseRoute, match.branch, match.schemaRoute, match.drawerItems) + search + hash;
}

function withQueryParams(uri) {
  var currentPath = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).path;
  var match = getUrlParts(uri);
  var path = match.path;
  var pathString = Belt_List.toArray(path).join("/");
  var newPathString = pathString.startsWith("/") ? pathString : "/" + pathString;
  var currentBaseRoute = RouterTypes.BaseRoute.get(currentPath);
  var newBaseRoute = RouterTypes.BaseRoute.get(path);
  var currentSchemaRoute = RouterTypes.SchemaRoute.get(currentPath);
  var newSchemaRoute = RouterTypes.SchemaRoute.get(path);
  var shouldResetQueryParams = Caml_obj.caml_notequal(currentSchemaRoute, newSchemaRoute) || Caml_obj.caml_notequal(currentBaseRoute, newBaseRoute);
  var oldQueryParams = Js_dict.entries(QueryString.parse(window.location.search));
  var newQueryParams = Js_dict.entries(QueryString.parse(match.search));
  var combinedQueryParams = QueryString.stringify(Js_dict.fromArray(Belt_Array.concat(oldQueryParams, newQueryParams)));
  var newQueryParamsString = combinedQueryParams === "" ? "" : "?" + combinedQueryParams;
  var newUriWithQueryParams = newPathString + newQueryParamsString;
  if (shouldResetQueryParams) {
    return uri;
  } else {
    return newUriWithQueryParams;
  }
}

function getLowestRouteChange(a, b) {
  var aParts = getUrlParts(a);
  var bParts = getUrlParts(b);
  var match = getRouteFromPath(aParts.path);
  var match$1 = getRouteFromPath(bParts.path);
  if (Caml_obj.caml_notequal(match.baseRoute, match$1.baseRoute)) {
    return "baseRoute";
  } else if (Caml_obj.caml_notequal(match.branch, match$1.branch)) {
    return "branch";
  } else if (Caml_obj.caml_notequal(match.schemaRoute, match$1.schemaRoute)) {
    return "schemaRoute";
  } else if (Caml_obj.caml_notequal(match.drawerItems, match$1.drawerItems)) {
    return "drawerItem";
  } else if (aParts.search !== bParts.search) {
    return "search";
  } else if (aParts.hash !== bParts.hash) {
    return "hash";
  } else {
    return ;
  }
}

function push(withoutQueryParamsOpt, path) {
  var withoutQueryParams = withoutQueryParamsOpt !== undefined ? withoutQueryParamsOpt : false;
  var match = RouterStore.Schema.getState(undefined);
  var routeChangePreventionSubscribers = match.routeChangePreventionSubscribers;
  var mayPush;
  if (routeChangePreventionSubscribers.length !== 0) {
    var routeChange = getLowestRouteChange(getCurrentUrl(undefined), path);
    mayPush = routeChange !== undefined ? Belt_Option.mapWithDefault(Belt_Array.getByU(Belt_Array.reverse(routeChangePreventionSubscribers), (function (param) {
                  return RouterTypes.Route.routingMeaningfully(param[1], routeChange);
                })), true, (function (param) {
              Curry._1(param[2], (function (param) {
                      return push(withoutQueryParams, path);
                    }));
              return false;
            })) : true;
  } else {
    mayPush = true;
  }
  if (!mayPush) {
    return ;
  }
  var path$1 = reParsePath(path);
  if (withoutQueryParams) {
    return RescriptReactRouter.push(path$1);
  } else {
    return RescriptReactRouter.push(withQueryParams(path$1));
  }
}

function replace(path) {
  return $$Window.$$History.replaceState(withQueryParams(reParsePath(path)));
}

function use(param) {
  var search = RescriptReactRouter.useUrl(undefined, undefined).search;
  return React.useMemo((function () {
                return QueryString.parse(search);
              }), [search]);
}

function getQueryParam(queryParams, key) {
  return Belt_Option.map(Js_dict.get(queryParams, key), (function (value) {
                if (value !== undefined) {
                  return value;
                } else {
                  return "";
                }
              }));
}

function useKey(key) {
  var queryParams = use(undefined);
  var getUrlForSettingQueryParam = function (value) {
    var search = QueryString.parse(window.location.search);
    search[key] = value;
    var pathName = window.location.pathname;
    var pathName$1 = pathName.startsWith("/") ? pathName : "/" + pathName;
    return pathName$1 + "?" + QueryString.stringify(search);
  };
  var setQueryParam = function (value) {
    return RescriptReactRouter.push(getUrlForSettingQueryParam(value));
  };
  var replaceQueryParam = function (value) {
    return RescriptReactRouter.replace(getUrlForSettingQueryParam(value));
  };
  var getUrlForRemovingQueryParam = function (param) {
    var search = QueryString.parse(window.location.search);
    Belt_Option.forEach(Js_dict.get(search, key), (function (param) {
            return Js_dict.unsafeDeleteKey(search, key);
          }));
    var pathName = window.location.pathname;
    var pathName$1 = pathName.startsWith("/") ? pathName : "/" + pathName;
    return pathName$1 + (
            Caml_obj.caml_equal(Object.keys(search), []) ? "" : "?" + QueryString.stringify(search)
          );
  };
  var removeQueryParam = function (param) {
    return RescriptReactRouter.push(getUrlForRemovingQueryParam(undefined));
  };
  var removeQueryParamByReplace = function (param) {
    return RescriptReactRouter.replace(getUrlForRemovingQueryParam(undefined));
  };
  return {
          value: getQueryParam(queryParams, key),
          set: setQueryParam,
          replace: replaceQueryParam,
          remove: removeQueryParam,
          removeByReplace: removeQueryParamByReplace
        };
}

var QueryParams = {
  use: use,
  getQueryParam: getQueryParam,
  useKey: useKey
};

function use$1(param) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var pathJoined = Belt_List.toArray(url.path).join("/");
  var baseRoute = React.useMemo((function () {
          return RouterTypes.BaseRoute.get(url.path);
        }), [pathJoined]);
  var route = React.useMemo((function () {
          if (typeof baseRoute === "object" && baseRoute.NAME === "schema") {
            return {
                    baseRoute: baseRoute,
                    branch: RouterTypes.Branch.get(url.path),
                    schemaRoute: RouterTypes.SchemaRoute.get(url.path),
                    drawerItems: RouterTypes.DrawerItems.get(url.path)
                  };
          } else {
            return {
                    baseRoute: baseRoute,
                    branch: undefined,
                    schemaRoute: undefined,
                    drawerItems: /* [] */0
                  };
          }
        }), [pathJoined]);
  var drawerItems = route.drawerItems;
  var schemaRoute = route.schemaRoute;
  var branch = route.branch;
  var baseRoute$1 = route.baseRoute;
  var prevRoute = Hooks.usePrevious1(route);
  React.useEffect((function () {
          AnalyticsRe.navigated(AnalyticsRe.toScreenToJs(baseRouteLocationToAnalyticsLocation(baseRoute$1, schemaRoute, drawerItems)), AnalyticsRe.toScreenToJs(baseRouteLocationToAnalyticsLocation(baseRoute$1, schemaRoute, drawerItems)), baseRouteLocationToAnalyticsLocation(baseRoute$1, schemaRoute, drawerItems), undefined, undefined, typeof baseRoute$1 === "object" && baseRoute$1.NAME === "schema" ? baseRoute$1.VAL : undefined, undefined, branch !== undefined && typeof branch === "object" && branch.NAME === "branch" ? branch.VAL : undefined);
          
        }), []);
  return Hooks.useDidUpdate1((function (param) {
                Intercom.update({});
                var match = prevRoute.baseRoute;
                var match$1 = prevRoute.branch;
                AnalyticsRe.navigated(AnalyticsRe.toScreenToJs(baseRouteLocationToAnalyticsLocation(baseRoute$1, schemaRoute, drawerItems)), AnalyticsRe.toScreenToJs(baseRouteLocationToAnalyticsLocation(baseRoute$1, schemaRoute, drawerItems)), baseRouteLocationToAnalyticsLocation(baseRoute$1, schemaRoute, drawerItems), baseRouteLocationToAnalyticsLocation(prevRoute.baseRoute, prevRoute.schemaRoute, prevRoute.drawerItems), typeof match === "object" && match.NAME === "schema" ? match.VAL : undefined, typeof baseRoute$1 === "object" && baseRoute$1.NAME === "schema" ? baseRoute$1.VAL : undefined, match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "branch" ? match$1.VAL : undefined, branch !== undefined && typeof branch === "object" && branch.NAME === "branch" ? branch.VAL : undefined);
                
              }), [pathJoined]);
}

var AnalyticsHook = {
  use: use$1
};

function useUrlManager(url) {
  var match = React.useState(function () {
        return RouterTypes.BaseRoute.get(url.path);
      });
  var setBaseRoute = match[1];
  var baseRoute = match[0];
  var match$1 = React.useState(function () {
        if (typeof baseRoute === "object" && baseRoute.NAME === "schema") {
          return RouterTypes.Branch.get(url.path);
        }
        
      });
  var setBranch = match$1[1];
  var branch = match$1[0];
  var match$2 = React.useState(function () {
        if (typeof baseRoute === "object" && baseRoute.NAME === "schema") {
          return RouterTypes.SchemaRoute.get(url.path);
        }
        
      });
  var setSchemaRoute = match$2[1];
  var schemaRoute = match$2[0];
  var match$3 = React.useState(function () {
        if (typeof baseRoute === "object" && baseRoute.NAME === "schema") {
          return RouterTypes.DrawerItems.get(url.path);
        } else {
          return /* [] */0;
        }
      });
  var setDrawerItems = match$3[1];
  var drawerItems = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setReplacePath = match$4[1];
  var replacePath = match$4[0];
  React.useEffect((function () {
          if (replacePath !== undefined) {
            replace(replacePath);
          }
          
        }), [replacePath]);
  React.useEffect((function () {
          Curry._1(setReplacePath, (function (param) {
                  return getNewFormatPathString(url, {
                              baseRoute: baseRoute,
                              branch: branch,
                              schemaRoute: schemaRoute,
                              drawerItems: drawerItems
                            });
                }));
          
        }), []);
  Hooks.useDidUpdate1((function (param) {
          var newBaseRoute = RouterTypes.BaseRoute.get(url.path);
          if (Caml_obj.caml_equal(baseRoute, newBaseRoute)) {
            
          } else {
            Curry._1(setBaseRoute, (function (param) {
                    return newBaseRoute;
                  }));
          }
          var exit = 0;
          if (typeof newBaseRoute === "object" && newBaseRoute.NAME === "schema") {
            var newBranch = RouterTypes.Branch.get(url.path);
            if (Caml_obj.caml_equal(branch, newBranch)) {
              
            } else {
              Curry._1(setBranch, (function (param) {
                      return newBranch;
                    }));
            }
            var newSchemaRoute = RouterTypes.SchemaRoute.get(url.path);
            if (Caml_obj.caml_equal(schemaRoute, newSchemaRoute)) {
              
            } else {
              Curry._1(setSchemaRoute, (function (param) {
                      return newSchemaRoute;
                    }));
            }
            var newDrawerItems = RouterTypes.DrawerItems.get(url.path);
            if (Caml_obj.caml_equal(drawerItems, newDrawerItems)) {
              
            } else {
              Curry._1(setDrawerItems, (function (param) {
                      return RouterTypes.DrawerItems.get(url.path);
                    }));
            }
            var newFormatString = getNewFormatPathString(url, {
                  baseRoute: newBaseRoute,
                  branch: newBranch,
                  schemaRoute: newSchemaRoute,
                  drawerItems: newDrawerItems
                });
            Curry._1(setReplacePath, (function (param) {
                    return newFormatString;
                  }));
          } else {
            exit = 1;
          }
          if (exit === 1) {
            Curry._1(setReplacePath, (function (param) {
                    return getNewFormatPathString(url, {
                                baseRoute: newBaseRoute,
                                branch: branch,
                                schemaRoute: schemaRoute,
                                drawerItems: drawerItems
                              });
                  }));
          }
          
        }), [url]);
  return {
          baseRoute: baseRoute,
          branch: branch,
          schemaRoute: schemaRoute,
          drawerItems: drawerItems
        };
}

var ProviderHooks = {
  useUrlManager: useUrlManager
};

var initialValue = {
  baseRoute: "welcome",
  branch: undefined,
  schemaRoute: undefined,
  drawerItems: /* [] */0
};

var context = React.createContext(initialValue);

var provider = context.Provider;

function Router$Context$Provider(Props) {
  var children = Props.children;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = useUrlManager(url);
  use$1(undefined);
  return React.createElement(provider, {
              value: {
                baseRoute: match.baseRoute,
                branch: match.branch,
                schemaRoute: match.schemaRoute,
                drawerItems: match.drawerItems
              },
              children: children
            });
}

var Provider = {
  initialValue: initialValue,
  context: context,
  provider: provider,
  make: Router$Context$Provider
};

var Context = {
  AnalyticsHook: AnalyticsHook,
  ProviderHooks: ProviderHooks,
  Provider: Provider
};

function useBaseRoute(param) {
  return RouterTypes.BaseRoute.get(RescriptReactRouter.useUrl(undefined, undefined).path);
}

var NotInSchemaException = /* @__PURE__ */Caml_exceptions.create("Router.Schema.NotInSchemaException");

function pushSchemaRoute(branch, queryString, schemaRoute) {
  var route = getStaticRoute(undefined);
  return push(undefined, Curry._4(get, route.baseRoute, Belt_Option.mapWithDefault(branch, route.branch, (function (branch) {
                        return branch;
                      })), schemaRoute, /* [] */0) + Belt_Option.mapWithDefault(queryString, "", (function (qs) {
                    return "?" + qs;
                  })));
}

function pushDrawerItem(branch, drawerItem) {
  var route = getStaticRoute(undefined);
  var verifiedSchemaRoute = route.schemaRoute === undefined ? RouterTypes.DrawerItem.getParentSchemaRoute(drawerItem) : route.schemaRoute;
  return push(undefined, Curry._4(get, route.baseRoute, Belt_Option.mapWithDefault(branch, route.branch, (function (branch) {
                        return branch;
                      })), verifiedSchemaRoute, {
                  hd: drawerItem,
                  tl: route.drawerItems
                }));
}

function popDrawerItem(param) {
  var route = getStaticRoute(undefined);
  var match = route.drawerItems;
  return push(undefined, Curry._4(get, route.baseRoute, route.branch, route.schemaRoute, match ? match.tl : /* [] */0));
}

function swapTopDrawerItem$1(drawerItem) {
  var route = getStaticRoute(undefined);
  var match = route.drawerItems;
  return push(undefined, Curry._4(get, route.baseRoute, route.branch, route.schemaRoute, match ? ({
                      hd: drawerItem,
                      tl: match.tl
                    }) : ({
                      hd: drawerItem,
                      tl: /* [] */0
                    })));
}

function replaceDrawerItems(drawerItems) {
  var route = getStaticRoute(undefined);
  return push(undefined, Curry._4(get, route.baseRoute, route.branch, route.schemaRoute, drawerItems));
}

function clearDrawerItems(param) {
  return replaceDrawerItems(/* [] */0);
}

function getBranch(param) {
  var match = getStaticRoute(undefined);
  var branch = match.branch;
  var baseRoute = match.baseRoute;
  if (typeof baseRoute === "object") {
    if (baseRoute.NAME === "schema") {
      if (branch !== undefined && typeof branch === "object") {
        return {
                NAME: "branch",
                VAL: branch.VAL
              };
      } else {
        return "master";
      }
    }
    throw {
          RE_EXN_ID: NotInSchemaException,
          Error: new Error()
        };
  }
  throw {
        RE_EXN_ID: NotInSchemaException,
        Error: new Error()
      };
}

function getBranchId(param) {
  var match = getBranch(undefined);
  if (typeof match === "object") {
    return match.VAL;
  } else {
    return "master";
  }
}

function getSchemaId(param) {
  var match = getStaticRoute(undefined);
  var baseRoute = match.baseRoute;
  if (typeof baseRoute === "object") {
    if (baseRoute.NAME === "schema") {
      return baseRoute.VAL;
    }
    throw {
          RE_EXN_ID: NotInSchemaException,
          Error: new Error()
        };
  }
  throw {
        RE_EXN_ID: NotInSchemaException,
        Error: new Error()
      };
}

var Schema = {
  NotInSchemaException: NotInSchemaException,
  pushSchemaRoute: pushSchemaRoute,
  pushDrawerItem: pushDrawerItem,
  popDrawerItem: popDrawerItem,
  swapTopDrawerItem: swapTopDrawerItem$1,
  replaceDrawerItems: replaceDrawerItems,
  clearDrawerItems: clearDrawerItems,
  getBranch: getBranch,
  getBranchId: getBranchId,
  getSchemaId: getSchemaId
};

var logError = RouterTypes.logError;

var BaseRoute = RouterTypes.BaseRoute;

var Branch = RouterTypes.Branch;

var SchemaRoute = RouterTypes.SchemaRoute;

var DrawerItem = RouterTypes.DrawerItem;

var DrawerItems = RouterTypes.DrawerItems;

var Route = RouterTypes.Route;

exports.logError = logError;
exports.BaseRoute = BaseRoute;
exports.Branch = Branch;
exports.SchemaRoute = SchemaRoute;
exports.DrawerItem = DrawerItem;
exports.DrawerItems = DrawerItems;
exports.Route = Route;
exports.schemaRouteToAnalyticsLocation = schemaRouteToAnalyticsLocation;
exports.baseRouteLocationToAnalyticsLocation = baseRouteLocationToAnalyticsLocation;
exports.getOrigin = getOrigin;
exports.getPathName = getPathName;
exports.getSearch = getSearch;
exports.buildRedirectPath = buildRedirectPath;
exports.getRescriptReactRouterUrl = getRescriptReactRouterUrl;
exports.getUrlParts = getUrlParts;
exports.getRouteFromPath = getRouteFromPath;
exports.getStaticRoute = getStaticRoute;
exports.Link = Link;
exports.reParsePath = reParsePath;
exports.withQueryParams = withQueryParams;
exports.getLowestRouteChange = getLowestRouteChange;
exports.push = push;
exports.replace = replace;
exports.QueryParams = QueryParams;
exports.Context = Context;
exports.useBaseRoute = useBaseRoute;
exports.Schema = Schema;
/* context Not a pure module */
