// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

var DragDropContext = {};

var Droppable = {};

var Draggable = {};

var make = React.forwardRef(function (Props, ref_) {
      var droppableProps = Props.droppableProps;
      var children = Props.children;
      var tmp = {};
      var tmp$1 = Belt_Option.map((ref_ == null) ? undefined : Caml_option.some(ref_), (function (prim) {
              return prim;
            }));
      if (tmp$1 !== undefined) {
        tmp.ref = Caml_option.valFromOption(tmp$1);
      }
      return React.cloneElement(React.createElement("div", tmp, children), droppableProps);
    });

var DraggableWrapper = {
  make: make
};

var make$1 = React.forwardRef(function (Props, ref_) {
      var dragHandleProps = Props.dragHandleProps;
      var draggableProps = Props.draggableProps;
      var children = Props.children;
      var tmp = {};
      var tmp$1 = Belt_Option.map((ref_ == null) ? undefined : Caml_option.some(ref_), (function (prim) {
              return prim;
            }));
      if (tmp$1 !== undefined) {
        tmp.ref = Caml_option.valFromOption(tmp$1);
      }
      return React.cloneElement(React.createElement("div", tmp, children), Object.assign(dragHandleProps, draggableProps));
    });

var ItemWrapper = {
  make: make$1
};

exports.DragDropContext = DragDropContext;
exports.Droppable = Droppable;
exports.Draggable = Draggable;
exports.DraggableWrapper = DraggableWrapper;
exports.ItemWrapper = ItemWrapper;
/* make Not a pure module */
