// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function decode(param) {
  return Json_decode.field("issuesCount", Json_decode.$$int, param);
}

exports.decode = decode;
/* No side effect */
