// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var List = require("rescript/lib/js/list.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Accordion = require("./Accordion.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameUtils = require("../../shared/utils/NameUtils.bs.js");
var SourceRow = require("./SourceRow.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var ContextBox = require("./ContextBox.bs.js");
var SourceIcon = require("./SourceIcon.bs.js");
var SourcePill = require("./SourcePill.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var TextButton = require("./TextButton.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");
var DiscrepancyContext = require("./DiscrepancyContext.bs.js");
var ImplementationStatusContext = require("./ImplementationStatusContext.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.fontWeight(Styles.FontWeight.bold),
      tl: {
        hd: Css.color(Styles.Color.grey70),
        tl: {
          hd: Css.fontSize(Styles.FontSize.small),
          tl: {
            hd: Css.width(Css.pct(100)),
            tl: {
              hd: Css.padding2(Css.px(0), Css.px(10)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var emptyStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.padding4(Css.px(10), Css.px(5), Css.px(10), Css.px(15)),
            tl: {
              hd: Css.margin2(Css.px(5), Css.px(0)),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.orange),
                tl: {
                  hd: Css.borderRadius(Styles.Border.radius),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var addSourcePopupStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.bottom(Css.px(0)),
          tl: {
            hd: Css.transform(Css.translateY(Css.pct(100))),
            tl: {
              hd: Css.padding(Css.px(4)),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                    tl: {
                      hd: Css.width(Css.pct(100)),
                      tl: {
                        hd: Css.minWidth(Css.px(300)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var addSourcePopupItemStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.padding2(Css.px(10), Css.px(15)),
                  tl: {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.textAlign("left"),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: {
                              hd: Css.color(Styles.Color.grey70),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.backgroundColor(Styles.Color.blueBg),
                                      tl: {
                                        hd: Css.color(Styles.Color.blue),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var newSourceButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.padding2(Css.px(10), Css.px(18)),
                  tl: {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.textAlign("left"),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.color(Styles.Color.blue),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.color(Styles.Color.blueSecondary),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function EventSources$AccordionSource(Props) {
  var source = Props.source;
  var $$event = Props.event;
  var model = Props.model;
  var sendActions = Props.sendActions;
  var schemaGroup = Props.schemaGroup;
  var role = Props.role;
  var eventSourcesAndDestinationsConfigAvailability = Props.eventSourcesAndDestinationsConfigAvailability;
  var isEventModified = Props.isEventModified;
  var eventInvocations = Props.eventInvocations;
  var codegenInvocationsFetcherState = Props.codegenInvocationsFetcherState;
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var globalSend = GlobalSendContext.use(undefined);
  var correctCase = NameUtils.isCorrectCase(expectedEventCase, $$event.name);
  var includedDestinations = Belt_List.toArray(Belt_List.flatten(Belt_List.keepMap($$event.includeDestinations, (function (x) {
                  var match = x.VAL;
                  if (match[0] === source.id) {
                    return match[1];
                  }
                  
                }))));
  var sourceDestinations = Belt_List.toArray(BeltListExtensions.dedupeOrdered(Belt_List.keepMapU(source.destinations, (function (param) {
                  return StateUtils.getDestinationById(param.destinationId, model);
                })), (function (destination) {
              return destination.id;
            })));
  var disabledDestinationTuples = Belt_Array.map(Belt_Array.keep(sourceDestinations, (function (param) {
              var id = param.id;
              return !Belt_Array.some(includedDestinations, (function (includedDestinationId) {
                            return includedDestinationId === id;
                          }));
            })), (function (destination) {
          return [
                  destination.id,
                  StateUtils.getDestinationName(destination)
                ];
        }));
  var subtitle = Caml_obj.caml_equal(disabledDestinationTuples, []) ? undefined : ({
        NAME: "Component",
        VAL: React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("row"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.gap(Css.px(4)),
                          tl: {
                            hd: Css.flexWrap("wrap"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Regular",
                  color: Styles.Color.grey80,
                  children: "Disabled for"
                }), Belt_Array.map(disabledDestinationTuples, (function (param) {
                    return React.createElement("div", {
                                key: param[0],
                                className: Curry._1(Css.style, {
                                      hd: Css.paddingTop(Css.px(4)),
                                      tl: {
                                        hd: Css.paddingBottom(Css.px(4)),
                                        tl: {
                                          hd: Css.paddingLeft(Css.px(8)),
                                          tl: {
                                            hd: Css.paddingRight(Css.px(8)),
                                            tl: {
                                              hd: Css.borderRadius(Css.px(20)),
                                              tl: {
                                                hd: Css.backgroundColor(Styles.Color.grey30),
                                                tl: {
                                                  hd: Css.border(Css.px(1), "solid", Styles.Color.grey20),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    })
                              }, React.createElement($$Text.make, {
                                    size: "Tiny",
                                    weight: "Semi",
                                    lines: 1,
                                    color: Styles.Color.grey60,
                                    children: param[1]
                                  }));
                  })))
      });
  var tmp = {
    title: {
      NAME: "String",
      VAL: AvoConfig.getSourceName(source)
    },
    menuItems: Models.Role.canEdit(role) ? [
        {
          NAME: "Option",
          VAL: {
            label: "View Source details",
            onClick: (function (param) {
                return Router.Schema.pushDrawerItem(undefined, {
                            NAME: "source",
                            VAL: [
                              source.id,
                              "overview"
                            ]
                          });
              })
          }
        },
        {
          NAME: "Option",
          VAL: {
            label: eventSourcesAndDestinationsConfigAvailability === "Available" ? "Remove Source" : "⚡ Remove Source",
            onClick: (function (param) {
                if (eventSourcesAndDestinationsConfigAvailability === "AvailableDuringTrial" || eventSourcesAndDestinationsConfigAvailability === "Available") {
                  return Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              (function (branch) {
                                  return AnalyticsRe.eventUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.source(source.id, AvoConfig.getSourceName(source)), $$event.id, $$event.name, "RemoveSource", undefined, undefined, Belt_List.toArray(Belt_List.keepU(StateUtils.getIncludedSourceIds($$event.includeSources), (function (id) {
                                                        return id !== source.id;
                                                      }))), Belt_List.toArray(StateUtils.getIncludedSourceIds($$event.includeSources)), undefined, undefined, undefined, undefined, Belt_List.length(model.events), undefined, undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, expectedEventCase, $$event.id, schemaGroup.schemaId, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
                                }),
                              undefined,
                              [[
                                  {
                                    NAME: "ExcludeEventFromSourceV2",
                                    VAL: [
                                      $$event.id,
                                      source.id
                                    ]
                                  },
                                  {
                                    eventId: $$event.id,
                                    eventQuery: $$event.id,
                                    sourceId: source.id,
                                    sourceQuery: source.id
                                  }
                                ]]
                            ]);
                } else {
                  return Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: {
                                NAME: "BillingPrompt",
                                VAL: "ConfigureEventsPerPlatform"
                              }
                            });
                }
              })
          }
        }
      ] : [],
    extra: React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.height(Css.px(16)),
                tl: /* [] */0
              })
        }, React.createElement(SourcePill.Simple.make, {
              event: $$event,
              isEventModified: isEventModified,
              eventInvocations: eventInvocations,
              codegenInvocationsFetcherState: codegenInvocationsFetcherState,
              source: source,
              size: 16,
              _timeWindowStart: undefined
            }))
  };
  if (subtitle !== undefined) {
    tmp.subtitle = Caml_option.valFromOption(subtitle);
  }
  return React.createElement(Accordion.Item.make, {
              children: null
            }, React.createElement(Accordion.ItemHeader.make, tmp), React.createElement(Accordion.ItemCollapsible.make, {
                  onChange: (function (isOpen) {
                      return AnalyticsRe.collapsibleItemInteracted(schemaGroup, isOpen ? "Expanded" : "Collapsed", "Source", "Event");
                    }),
                  children: React.createElement(SourceRow.make, {
                        event: $$event,
                        model: model,
                        role: role,
                        schemaGroup: schemaGroup,
                        sendActions: sendActions,
                        source: source,
                        isEventModified: isEventModified
                      })
                }));
}

var AccordionSource = {
  make: EventSources$AccordionSource
};

function EventSources(Props) {
  var $$event = Props.event;
  var model = Props.model;
  var role = Props.role;
  var schemaGroup = Props.schemaGroup;
  var sendActions = Props.sendActions;
  var isEventModified = Props.isEventModified;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var workspaceLimits = AvoLimits.computeLimits(workspace, model);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var correctCase = NameUtils.isCorrectCase(expectedEventCase, $$event.name);
  var match$1 = AvoLimits.CodegenEvents.entityStatus(workspaceLimits, $$event.id);
  var codegenAvailability = match$1[0];
  var addSourcesDomRef = React.useRef(null);
  var match$2 = ImplementationStatusContext.useEventWithRefetchStatusSubscription($$event.id);
  var eventInvocations = match$2[1];
  var codegenInvocationsFetcherState = match$2[0];
  var eventSourcesAndDestinationsConfigAvailability = AvoLimits.ConfigEventSourcesAndDestinations.computeAvailability(workspace);
  var sourcesWithCodegenSeen = WorkspaceContext.useLastSeenCodegenForSourceIds(undefined);
  var includeCodegenForAddedSource = function (sourceId) {
    if (codegenAvailability !== "Unavailable") {
      return Belt_MapString.has(sourcesWithCodegenSeen, sourceId);
    } else {
      return false;
    }
  };
  var clickAddSource = function (param) {
    var el = addSourcesDomRef.current;
    if (!(el == null)) {
      return Curry._1(el.click, undefined);
    }
    
  };
  var sending = AvoConfig.determineSendingToSources(model.sources, $$event);
  var sendingSources = typeof sending === "object" ? sending.VAL : (
      sending === "None" ? /* [] */0 : model.sources
    );
  return React.createElement("div", {
              className: rootStyles
            }, sendingSources === /* [] */0 ? React.createElement("div", {
                    className: emptyStyles,
                    onClick: clickAddSource
                  }, React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Semi",
                        color: Styles.Color.orangeSecondary,
                        children: "This event is not sent from any source yet"
                      })) : React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                        height: 5
                      }), React.createElement(Accordion.make, {
                        allowMultipleOpens: true,
                        children: Belt_Array.map(Belt_List.toArray(sendingSources), (function (source) {
                                return React.createElement(EventSources$AccordionSource, {
                                            source: source,
                                            event: $$event,
                                            model: model,
                                            sendActions: sendActions,
                                            schemaGroup: schemaGroup,
                                            role: role,
                                            eventSourcesAndDestinationsConfigAvailability: eventSourcesAndDestinationsConfigAvailability,
                                            isEventModified: isEventModified,
                                            eventInvocations: eventInvocations,
                                            codegenInvocationsFetcherState: codegenInvocationsFetcherState,
                                            key: source.id
                                          });
                              }))
                      }), React.createElement(Spacer.make, {
                        height: 8
                      })), Models.Role.canEdit(role) ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(ContextBox.make, {
                        children: (function (param, param$1, close, buttonRef) {
                            return [
                                    React.createElement(TextButton.make, {
                                          style: "Blue",
                                          size: "Medium",
                                          children: "+ Add Source",
                                          ref: buttonRef
                                        }),
                                    React.createElement("div", {
                                          className: addSourcePopupStyles
                                        }, Belt_List.toArray(Belt_List.map(Belt_List.keep(model.sources, (function (source) {
                                                        return !AvoConfig.isEventIncludedInSource(source.id, $$event);
                                                      })), (function (source) {
                                                    return React.createElement("button", {
                                                                key: source.id,
                                                                className: addSourcePopupItemStyles,
                                                                onClick: (function (param) {
                                                                    var implementWithAvo = includeCodegenForAddedSource(source.id);
                                                                    var eventsImplementedWithAvoOnSource = List.length(StateUtils.getSourceEvents(true, model, source.id));
                                                                    var eventsNotImplementedWithAvoOnSource = List.length(StateUtils.getSourceEvents(undefined, model, source.id)) - eventsImplementedWithAvoOnSource | 0;
                                                                    return Curry.app(sendActions, [
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                (function (branch) {
                                                                                    AnalyticsRe.eventUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.source(source.id, AvoConfig.getSourceName(source)), $$event.id, $$event.name, "AddSource", undefined, undefined, Belt_List.toArray(Belt_List.add(StateUtils.getIncludedSourceIds($$event.includeSources), source.id)), Belt_List.toArray(StateUtils.getIncludedSourceIds($$event.includeSources)), undefined, undefined, undefined, undefined, Belt_List.length(model.events), undefined, implementWithAvo, eventsNotImplementedWithAvoOnSource + (
                                                                                          implementWithAvo ? 0 : 1
                                                                                        ) | 0, eventsImplementedWithAvoOnSource + (
                                                                                          implementWithAvo ? 1 : 0
                                                                                        ) | 0, "Event", $$event.triggers.length, correctCase, expectedEventCase, $$event.id, schemaGroup.schemaId, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
                                                                                    return Curry._1(close, undefined);
                                                                                  }),
                                                                                undefined,
                                                                                [[
                                                                                    {
                                                                                      NAME: "IncludeEventInSourceV2",
                                                                                      VAL: [
                                                                                        $$event.id,
                                                                                        source.id,
                                                                                        StateUtils.getDefaultDestinationsForSource(source, model),
                                                                                        implementWithAvo
                                                                                      ]
                                                                                    },
                                                                                    {
                                                                                      eventId: $$event.id,
                                                                                      eventQuery: $$event.id,
                                                                                      sourceId: source.id,
                                                                                      sourceQuery: source.id
                                                                                    }
                                                                                  ]]
                                                                              ]);
                                                                  })
                                                              }, React.createElement("div", {
                                                                    className: Curry._1(Css.style, {
                                                                          hd: Css.marginRight(Css.px(10)),
                                                                          tl: /* [] */0
                                                                        })
                                                                  }, React.createElement(SourceIcon.make, {
                                                                        source: source
                                                                      })), AvoConfig.getSourceName(source));
                                                  }))), React.createElement("button", {
                                              className: newSourceButtonStyles,
                                              onClick: (function (param) {
                                                  return Router.Schema.pushSchemaRoute(undefined, undefined, "settings");
                                                })
                                            }, "+ Setup New Source"))
                                  ];
                          }),
                        ref: addSourcesDomRef
                      })) : null);
}

var make = EventSources;

exports.rootStyles = rootStyles;
exports.emptyStyles = emptyStyles;
exports.addSourcePopupStyles = addSourcePopupStyles;
exports.addSourcePopupItemStyles = addSourcePopupItemStyles;
exports.newSourceButtonStyles = newSourceButtonStyles;
exports.AccordionSource = AccordionSource;
exports.make = make;
/* rootStyles Not a pure module */
