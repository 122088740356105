// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function getInvitePrompt(plan, adminAndEditorAvailability, addCommenterStatus, userCount, role, billingStats) {
  var hasAvailableAdminOrEditorSeatsInStripe;
  if (typeof billingStats === "object") {
    var match = billingStats.VAL;
    hasAvailableAdminOrEditorSeatsInStripe = match.currentEditorSeats < match.planEditorSeats;
  } else {
    hasAvailableAdminOrEditorSeatsInStripe = false;
  }
  var exit = 0;
  var exit$1 = 0;
  var match$1 = plan.usersIncluded;
  var exit$2 = 0;
  if (typeof match$1 === "object") {
    var extraCommenterSeatCost = plan.commentOnlyMembers;
    var includedUsers = match$1.VAL;
    if (typeof extraCommenterSeatCost === "number") {
      if (extraCommenterSeatCost === /* CountAsEditors */1 && role === "CommentOnly") {
        if (userCount >= includedUsers) {
          if (hasAvailableAdminOrEditorSeatsInStripe) {
            return /* NoPrompt */0;
          } else {
            return /* AddEditor */1;
          }
        }
        exit = 1;
      } else {
        exit$2 = 3;
      }
    } else if (extraCommenterSeatCost.TAG === /* AdditionalCost */0 && role === "CommentOnly") {
      if (userCount >= includedUsers) {
        return /* AddCommentOnly */{
                _0: extraCommenterSeatCost._0
              };
      }
      exit = 1;
    } else {
      exit$2 = 3;
    }
  } else {
    var match$2 = plan.commentOnlyMembers;
    if (typeof match$2 === "number" && match$2 >= 2 && role === "CommentOnly") {
      exit$1 = 2;
    } else {
      exit$2 = 3;
    }
  }
  if (exit$2 === 3) {
    var exit$3 = 0;
    if (plan.countsViewersAsUsers) {
      exit$3 = 4;
    } else {
      if (role === "Viewer") {
        return /* NoPrompt */0;
      }
      exit$3 = 4;
    }
    if (exit$3 === 4) {
      if (role === "BillingOnly") {
        return /* NoPrompt */0;
      }
      var tmp = plan.commentOnlyMembers;
      if (typeof tmp === "number" || !(tmp.TAG === /* Limited */1 && role === "CommentOnly")) {
        exit = 1;
      } else {
        exit$1 = 2;
      }
    }
    
  }
  if (exit$1 === 2) {
    if (addCommenterStatus === "Available" || addCommenterStatus === "AvailableDuringTrial") {
      return /* NoPrompt */0;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match$3 = plan.usersIncluded;
    var exit$4 = 0;
    if (typeof match$3 === "object") {
      var includedUsers$1 = match$3.VAL;
      var exit$5 = 0;
      var tmp$1 = plan.commentOnlyMembers;
      if (typeof tmp$1 === "number" || !(tmp$1.TAG === /* Limited */1 && (adminAndEditorAvailability === "AvailableDuringTrial" || adminAndEditorAvailability === "Available") && role === "CommentOnly")) {
        exit$5 = 3;
      } else {
        if (userCount < includedUsers$1 && addCommenterStatus === "Unavailable") {
          return /* NoPrompt */0;
        }
        exit$5 = 3;
      }
      if (exit$5 === 3) {
        if (adminAndEditorAvailability === "Available") {
          if (plan.costPerExtraSeat > 0 && userCount >= includedUsers$1) {
            if (hasAvailableAdminOrEditorSeatsInStripe) {
              return /* NoPrompt */0;
            } else {
              return /* AddEditor */1;
            }
          }
          exit$4 = 2;
        } else {
          exit$4 = 2;
        }
      }
      
    } else {
      if (adminAndEditorAvailability === "AvailableDuringTrial" || adminAndEditorAvailability === "Available") {
        return /* NoPrompt */0;
      }
      exit$4 = 2;
    }
    if (exit$4 === 2) {
      if (adminAndEditorAvailability === "AvailableDuringTrial" || adminAndEditorAvailability === "Available") {
        return /* NoPrompt */0;
      } else {
        return /* Upgrade */2;
      }
    }
    
  }
  
}

exports.getInvitePrompt = getInvitePrompt;
/* No side effect */
