// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var IconLock = require("./IconLock.bs.js");
var Intercom = require("./externals/intercom.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var CopyButton = require("./CopyButton.bs.js");
var PromiseExt = require("./externals/PromiseExt.bs.js");
var TextButton = require("./TextButton.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var LoadingCircle = require("./LoadingCircle.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var WebhookSecretEndpoint = require("../../shared/endpoints/WebhookSecretEndpoint.bs.js");

var labelStyle = Curry._1(Css.style, {
      hd: Css.fontWeight(Styles.FontWeight.semi),
      tl: {
        hd: Css.color(Styles.Color.grey70),
        tl: {
          hd: Css.fontSize(Styles.FontSize.small),
          tl: /* [] */0
        }
      }
    });

function generateSecret(schemaId, integrationId) {
  var request = {
    schemaId: schemaId,
    integrationId: integrationId
  };
  var body = WebhookSecretEndpoint.$$Request.encode(request);
  return PromiseExt.resultFlatMap($$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                        return fetch("/api/webhook/generate-secret", Fetch.RequestInit.make(/* Post */2, [
                                          [
                                            "Accept",
                                            "application/json"
                                          ],
                                          [
                                            "Content-Type",
                                            "application/json"
                                          ],
                                          [
                                            "Authorization",
                                            "Bearer " + token
                                          ]
                                        ], Caml_option.some(JSON.stringify(body)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                      }).then(function (resp) {
                      return Promise.resolve({
                                  TAG: /* Ok */0,
                                  _0: resp
                                });
                    }), (function (err) {
                    console.error("/https_webhookSecretGenerator fetch error", err);
                    return Promise.resolve({
                                TAG: /* Error */1,
                                _0: "Insufficient permissions"
                              });
                  })), (function (resp) {
                return resp.json().then(function (json) {
                            return Promise.resolve({
                                        TAG: /* Ok */0,
                                        _0: WebhookSecretEndpoint.$$Response.decode(json)
                                      });
                          });
              }));
}

var secretKeyBackground_0 = Css.position("relative");

var secretKeyBackground_1 = {
  hd: Css.fontWeight(Styles.FontWeight.regular),
  tl: {
    hd: Css.backgroundColor(Styles.Color.grey20),
    tl: {
      hd: Css.borderRadius(Styles.Border.radius),
      tl: {
        hd: Css.color(Styles.Color.grey90),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.regular),
          tl: {
            hd: Css.fontFamily(Styles.monoFontFamily),
            tl: /* [] */0
          }
        }
      }
    }
  }
};

var secretKeyBackground = {
  hd: secretKeyBackground_0,
  tl: secretKeyBackground_1
};

var rootStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.width(Css.px(300)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.justifyContent("spaceBetween"),
              tl: {
                hd: Css.maxWidth(Css.pct(100)),
                tl: {
                  hd: Css.padding4(Css.px(35), Css.px(30), Css.px(24), Css.px(30)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.cursor("default"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function WebhookSecretModal(Props) {
  var schemaId = Props.schemaId;
  var integrationId = Props.integrationId;
  var integrationConfig = Props.integrationConfig;
  var onClose = Props.onClose;
  var onEncryptedToken = Props.onEncryptedToken;
  var hasSecret = Props.hasSecret;
  var userIsEditor = Props.userIsEditor;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useState(function () {
        if (userIsEditor && hasSecret) {
          return "Confirmation";
        } else {
          return "SecretInput";
        }
      });
  var setStep = match[1];
  var match$1 = React.useState(function () {
        if (userIsEditor) {
          return "Loading";
        } else {
          return "Error";
        }
      });
  var setToken = match$1[1];
  var secretToken = match$1[0];
  var match$2 = React.useState(function () {
        if (userIsEditor && !hasSecret) {
          return /* GeneratingToken */0;
        } else {
          return /* Idle */1;
        }
      });
  var setTokenStatus = match$2[1];
  var tokenStatus = match$2[0];
  React.useEffect((function () {
          if (tokenStatus) {
            
          } else {
            generateSecret(schemaId, integrationId).then(function (response) {
                  if (response.TAG !== /* Ok */0) {
                    return Curry._1(setToken, (function (param) {
                                  return "Error";
                                }));
                  }
                  var token = Buffer.from(response._0.key, "base64").toString();
                  Curry._1(setToken, (function (param) {
                          return {
                                  NAME: "Loaded",
                                  VAL: token
                                };
                        }));
                  Curry._1(setTokenStatus, (function (param) {
                          return /* Idle */1;
                        }));
                  return Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              true,
                              undefined,
                              undefined,
                              undefined,
                              (function (param) {
                                  return Curry._1(setToken, (function (param) {
                                                return "Error";
                                              }));
                                }),
                              [[
                                  {
                                    NAME: "GenerateWebhookSecret",
                                    VAL: integrationId
                                  },
                                  {
                                    integrationId: integrationId,
                                    integrationQuery: integrationId
                                  }
                                ]]
                            ]);
                });
          }
          
        }), [tokenStatus]);
  var tmp;
  if (match[0] === "Confirmation") {
    tmp = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
              color: Styles.Color.grey80,
              children: "Generating a new secret key will disable your old secret. Are you sure you want to proceed?"
            }), React.createElement(Spacer.make, {
              height: 24
            }), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement(Button.make, {
                  label: "Generate a new secret",
                  onClick: (function (param) {
                      Curry._1(setStep, (function (param) {
                              return "SecretInput";
                            }));
                      return Curry._1(setTokenStatus, (function (param) {
                                    return /* GeneratingToken */0;
                                  }));
                    }),
                  size: "large"
                }), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement(TextButton.make, {
                  onClick: (function (param) {
                      return Curry._1(onClose, undefined);
                    }),
                  size: "Medium",
                  children: "Cancel"
                })));
  } else {
    var tmp$1;
    if (typeof secretToken === "object") {
      var secretToken$1 = secretToken.VAL;
      AnalyticsRe.webhookSecretModalInteraction(schemaGroup, integrationConfig, "NewSecretKeyGenerated", schemaId);
      tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("label", {
                className: labelStyle,
                htmlFor: "accessToken"
              }, "Webhook Secret Key"), React.createElement(Spacer.make, {
                height: 4
              }), React.createElement("div", {
                className: Curry._1(Css.style, Belt_List.concat(secretKeyBackground, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.textOverflow("ellipsis"),
                            tl: {
                              hd: Css.padding(Css.px(8)),
                              tl: /* [] */0
                            }
                          }
                        }))
              }, React.createElement(CopyButton.make, {
                    copyString: secretToken$1
                  }), React.createElement("pre", {
                    className: Curry._1(Css.style, {
                          hd: Css.overflowX("scroll"),
                          tl: {
                            hd: Css.color(Styles.Color.blueSecondary),
                            tl: {
                              hd: Css.fontFamily(Styles.monoFontFamily),
                              tl: {
                                hd: Css.margin("zero"),
                                tl: {
                                  hd: Css.paddingLeft(Css.px(8)),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, secretToken$1)));
    } else if (secretToken === "Error") {
      if (userIsEditor) {
        AnalyticsRe.webhookSecretModalInteraction(schemaGroup, integrationConfig, "NewSecretKeyGenerationFailed", schemaId);
      }
      tmp$1 = React.createElement("div", {
            className: Curry._1(Css.style, Belt_List.concat(secretKeyBackground, {
                      hd: Css.padding2(Css.px(18), Css.px(8)),
                      tl: /* [] */0
                    }))
          }, userIsEditor ? "There was a problem generating your secret key" : "Only workspace admins can generate secret");
    } else {
      tmp$1 = null;
    }
    var tmp$2;
    if (typeof secretToken === "object") {
      var secretToken$2 = secretToken.VAL;
      tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                label: "Close, I saved the key",
                onClick: (function (param) {
                    Curry._1(onClose, undefined);
                    return Curry._1(onEncryptedToken, secretToken$2);
                  })
              }));
    } else {
      tmp$2 = secretToken === "Error" ? React.createElement(Button.make, {
              label: "Contact support",
              onClick: (function (param) {
                  Curry._1(onClose, undefined);
                  return Intercom.showNewMessage("Hey! I'm facing problems with generating a webhook secret key");
                })
            }) : React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.height(Css.pct(55.0)),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(LoadingCircle.make, {
                  color: Styles.Color.magenta
                }));
    }
    tmp = React.createElement(React.Fragment, undefined, tmp$1, typeof secretToken === "object" ? React.createElement($$Text.make, {
                color: Styles.Color.grey80,
                children: "Make sure to note down the secret key, you won't be able to see it in Avo again"
              }) : null, React.createElement(Spacer.make, {
              height: 16
            }), tmp$2);
  }
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.margin4(Css.px(-100), "auto", Css.px(5), "auto"),
                        tl: {
                          hd: Css.textAlign("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(IconLock.make, {
                      size: 100
                    })), tmp);
}

var make = WebhookSecretModal;

exports.labelStyle = labelStyle;
exports.generateSecret = generateSecret;
exports.secretKeyBackground = secretKeyBackground;
exports.rootStyles = rootStyles;
exports.make = make;
/* labelStyle Not a pure module */
