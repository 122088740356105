// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var KeyListener = require("./KeyListener.bs.js");
var PortalTooltip = require("./PortalTooltip.bs.js");
var FramerMotion = require("framer-motion");
var PropertyValidations = require("./PropertyValidations.bs.js");

function EditableCellListItem$Checkbox(Props) {
  var checked = Props.checked;
  return React.createElement(React.Fragment, undefined, React.createElement("input", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.opacity(0),
                          tl: {
                            hd: Css.cursor("pointer"),
                            tl: {
                              hd: Css.height(Css.px(0)),
                              tl: {
                                hd: Css.width(Css.px(0)),
                                tl: {
                                  hd: Css.margin(Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }),
                  checked: checked,
                  type: "checkbox",
                  onChange: (function (param) {
                      
                    })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.before({
                                hd: Css.display("block"),
                                tl: {
                                  hd: Css.position("absolute"),
                                  tl: {
                                    hd: Css.top(Css.px(0)),
                                    tl: {
                                      hd: Css.right(Css.px(0)),
                                      tl: {
                                        hd: Css.bottom(Css.px(0)),
                                        tl: {
                                          hd: Css.left(Css.px(0)),
                                          tl: {
                                            hd: Css.zIndex(1),
                                            tl: {
                                              hd: Css.opacity(checked ? 1 : 0),
                                              tl: {
                                                hd: Css.backgroundColor(Styles.Color.blue),
                                                tl: {
                                                  hd: Css.borderRadius(Css.pct(50)),
                                                  tl: {
                                                    hd: Css.unsafe("content", "' '"),
                                                    tl: {
                                                      hd: Css.width(Css.px(32)),
                                                      tl: {
                                                        hd: Css.height(Css.px(32)),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: {
                            hd: Css.after({
                                  hd: Css.display("block"),
                                  tl: {
                                    hd: Css.position("absolute"),
                                    tl: {
                                      hd: Css.top(Css.px(0)),
                                      tl: {
                                        hd: Css.right(Css.px(0)),
                                        tl: {
                                          hd: Css.bottom(Css.px(0)),
                                          tl: {
                                            hd: Css.left(Css.px(0)),
                                            tl: {
                                              hd: Css.opacity(checked ? 1 : 0),
                                              tl: {
                                                hd: Css.zIndex(2),
                                                tl: {
                                                  hd: Css.backgroundImage({
                                                        NAME: "url",
                                                        VAL: "'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjQ4IDYuNThMMTAuOS4yMmEuNzUuNzUgMCAwMTEuMDUgMGwuODMuODJjLjMuMjkuMy43NSAwIDEuMDNMNS4wMiA5Ljc4YS43NS43NSAwIDAxLTEuMDUgMEwuMiA2LjA1YS43NC43NCAwIDAxMC0xLjAzbC44NC0uODJhLjczLjczIDAgMDExLjA0IDBsMi40IDIuMzh6IiBmaWxsPSIjZmZmIi8+PC9zdmc+'"
                                                      }),
                                                  tl: {
                                                    hd: Css.backgroundPosition({
                                                          NAME: "hv",
                                                          VAL: [
                                                            Css.pct(50),
                                                            Css.pct(50)
                                                          ]
                                                        }),
                                                    tl: {
                                                      hd: Css.backgroundRepeat("noRepeat"),
                                                      tl: {
                                                        hd: Css.borderRadius(Css.pct(50)),
                                                        tl: {
                                                          hd: Css.unsafe("content", "' '"),
                                                          tl: {
                                                            hd: Css.width(Css.px(32)),
                                                            tl: {
                                                              hd: Css.height(Css.px(32)),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: {
                                  hd: Css.flexShrink(0.0),
                                  tl: {
                                    hd: Css.width(Css.px(32)),
                                    tl: {
                                      hd: Css.height(Css.px(32)),
                                      tl: {
                                        hd: Css.borderRadius(Css.pct(50)),
                                        tl: {
                                          hd: Css.border(Css.px(1), "solid", Styles.Color.grey40),
                                          tl: {
                                            hd: Css.overflow("hidden"),
                                            tl: {
                                              hd: Css.position("relative"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    })));
}

var Checkbox = {
  make: EditableCellListItem$Checkbox
};

var buttonStyles = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.pct(100)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.width(Css.pct(100)),
          tl: /* [] */0
        }
      }
    });

function tagInfoContainerStyles(mode) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: 150.0
                        }, undefined, undefined, "width"),
                    tl: {
                      hd: Css.width(mode === /* Viewing */0 ? Css.pct(100) : ({
                                NAME: "substract",
                                VAL: [
                                  Css.pct(100),
                                  Css.px(36)
                                ]
                              })),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function EditableCellListItem$Category(Props) {
  var active = Props.active;
  var checkedOrigin = Props.checked;
  var disabled = Props.disabled;
  var category = Props.item;
  var modeOpt = Props.mode;
  var onClick = Props.onClick;
  var onToggle = Props.onToggle;
  var mode = modeOpt !== undefined ? modeOpt : /* Editing */1;
  var match = React.useState(function () {
        return checkedOrigin;
      });
  var setChecked = match[1];
  React.useEffect((function () {
          Curry._1(setChecked, (function (param) {
                  return checkedOrigin;
                }));
          
        }), [checkedOrigin]);
  var onKeyPressToggle = React.useCallback((function (e) {
          Curry._1(setChecked, (function (state) {
                  Curry._2(onToggle, category.id, !state);
                  return !state;
                }));
          e.preventDefault();
          e.stopPropagation();
          
        }), []);
  var onClick$1 = React.useCallback((function (e) {
          e.preventDefault();
          e.stopPropagation();
          if (mode === /* Viewing */0) {
            return Curry._1(onClick, e);
          } else {
            return Curry._1(setChecked, (function (state) {
                          var toggled = !state;
                          Curry._2(onToggle, category.id, toggled);
                          return !state;
                        }));
          }
        }), [
        onClick,
        onToggle
      ]);
  var tmp = {
    className: buttonStyles,
    role: "button",
    onClick: onClick$1
  };
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  return React.createElement("div", tmp, active ? React.createElement(React.Fragment, undefined, React.createElement(KeyListener.make, {
                        keyName: " ",
                        onPress: onKeyPressToggle
                      }), React.createElement(KeyListener.make, {
                        keyName: "Enter",
                        onPress: onKeyPressToggle
                      })) : null, React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  initial: false,
                  children: mode === /* Editing */1 ? React.createElement(FramerMotion.motion.div, {
                          animate: {
                            opacity: 1,
                            width: "44px"
                          },
                          transition: {
                            duration: 0.15
                          },
                          initial: {
                            opacity: 0,
                            width: "0px"
                          },
                          exit: {
                            opacity: 0,
                            width: "44px"
                          },
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: /* [] */0
                              }),
                          children: null
                        }, React.createElement(EditableCellListItem$Checkbox, {
                              checked: match[0]
                            }), React.createElement(Spacer.make, {
                              width: 16
                            })) : null
                }), React.createElement("div", {
                  className: tagInfoContainerStyles(mode)
                }, React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          size: "Medium",
                          weight: "Medium",
                          singleLine: true,
                          color: Styles.Color.grey80,
                          children: category.name
                        }))));
}

var Category = {
  Cell: undefined,
  buttonStyles: buttonStyles,
  tagInfoContainerStyles: tagInfoContainerStyles,
  make: EditableCellListItem$Category
};

var buttonStyles$1 = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.pct(100)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.width(Css.pct(100)),
          tl: /* [] */0
        }
      }
    });

function propertyGroupInfoContainerStyles(mode) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: 150.0
                        }, undefined, undefined, "width"),
                    tl: {
                      hd: Css.width(mode === /* Viewing */0 ? Css.pct(100) : ({
                                NAME: "substract",
                                VAL: [
                                  Css.pct(100),
                                  Css.px(36)
                                ]
                              })),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.padding(Css.px(4)),
            tl: {
              hd: Css.height(Css.px(48)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function typePillStyles(stringLength) {
  return Curry._1(Css.style, {
              hd: Css.color(Styles.Color.white),
              tl: {
                hd: Css.fontFamily(Styles.monoFontFamily),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.regular),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.tiny),
                    tl: {
                      hd: Css.padding4(Css.px(1), Css.px(4), Css.px(0), Css.px(4)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.2)),
                        tl: {
                          hd: Css.borderRadius(Css.px(4)),
                          tl: {
                            hd: Css.display("inlineBlock"),
                            tl: {
                              hd: Css.marginTop(Css.px(2)),
                              tl: {
                                hd: Css.textOverflow(stringLength > 6 ? "ellipsis" : ({
                                          NAME: "string",
                                          VAL: "nowrap"
                                        })),
                                tl: {
                                  hd: Css.whiteSpace("nowrap"),
                                  tl: {
                                    hd: Css.overflow("hidden"),
                                    tl: {
                                      hd: Css.minWidth(stringLength > 6 ? Css.px(0) : "maxContent"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function EditableCellListItem$PropertyGroup(Props) {
  var active = Props.active;
  var checkedOrigin = Props.checked;
  var disabled = Props.disabled;
  var propertyGroup = Props.item;
  var modeOpt = Props.mode;
  var onClick = Props.onClick;
  var onToggle = Props.onToggle;
  var mode = modeOpt !== undefined ? modeOpt : /* Editing */1;
  var match = React.useState(function () {
        return checkedOrigin;
      });
  var setChecked = match[1];
  React.useEffect((function () {
          Curry._1(setChecked, (function (param) {
                  return checkedOrigin;
                }));
          
        }), [checkedOrigin]);
  var onKeyPressToggle = React.useCallback((function (e) {
          Curry._1(setChecked, (function (state) {
                  Curry._2(onToggle, propertyGroup.id, !state);
                  return !state;
                }));
          e.preventDefault();
          e.stopPropagation();
          
        }), []);
  var onClick$1 = React.useCallback((function (e) {
          e.preventDefault();
          e.stopPropagation();
          if (mode === /* Viewing */0) {
            return Curry._1(onClick, e);
          } else {
            return Curry._1(setChecked, (function (state) {
                          var toggled = !state;
                          Curry._2(onToggle, propertyGroup.id, toggled);
                          return !state;
                        }));
          }
        }), [
        onClick,
        onToggle
      ]);
  var tmp = {
    className: buttonStyles$1,
    role: "button",
    onClick: onClick$1
  };
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  return React.createElement("div", tmp, active ? React.createElement(React.Fragment, undefined, React.createElement(KeyListener.make, {
                        keyName: " ",
                        onPress: onKeyPressToggle
                      }), React.createElement(KeyListener.make, {
                        keyName: "Enter",
                        onPress: onKeyPressToggle
                      })) : null, React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  initial: false,
                  children: mode === /* Editing */1 ? React.createElement(FramerMotion.motion.div, {
                          animate: {
                            opacity: 1,
                            width: "44px"
                          },
                          transition: {
                            duration: 0.15
                          },
                          initial: {
                            opacity: 0,
                            width: "0px"
                          },
                          exit: {
                            opacity: 0,
                            width: "44px"
                          },
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: /* [] */0
                              }),
                          children: null
                        }, React.createElement(EditableCellListItem$Checkbox, {
                              checked: match[0]
                            }), React.createElement(Spacer.make, {
                              width: 16
                            })) : null
                }), React.createElement("div", {
                  className: propertyGroupInfoContainerStyles(mode)
                }, React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          size: "Medium",
                          weight: "Medium",
                          singleLine: true,
                          color: Styles.Color.grey80,
                          children: propertyGroup.name + " (" + String(propertyGroup.properties.length) + ")"
                        })), React.createElement(PortalTooltip.make, {
                      renderTooltip: (function (param) {
                          return React.createElement("aside", undefined, React.createElement("header", {
                                          className: headerStyles
                                        }, React.createElement($$Text.make, {
                                              element: "H1",
                                              size: "Medium",
                                              weight: "Medium",
                                              singleLine: true,
                                              color: Styles.Color.grey20,
                                              children: "Properties in bundle"
                                            })), Belt_Array.mapU(propertyGroup.properties, (function (property) {
                                            var typeString = (
                                              property.list ? "list of " : ""
                                            ) + property.type_;
                                            return React.createElement("span", {
                                                        key: property.id,
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.display("flex"),
                                                              tl: {
                                                                hd: Css.width(Css.pct(100)),
                                                                tl: {
                                                                  hd: Css.marginBottom(Css.px(4)),
                                                                  tl: {
                                                                    hd: Css.lastChild({
                                                                          hd: Css.marginBottom(Css.px(0)),
                                                                          tl: /* [] */0
                                                                        }),
                                                                    tl: {
                                                                      hd: Css.padding(Css.px(4)),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            })
                                                      }, React.createElement($$Text.make, {
                                                            size: "Medium",
                                                            weight: "Medium",
                                                            singleLine: true,
                                                            color: Styles.Color.grey10,
                                                            children: property.name
                                                          }), React.createElement(Spacer.make, {
                                                            width: 6
                                                          }), React.createElement("span", {
                                                            className: typePillStyles(typeString.length)
                                                          }, typeString));
                                          })));
                        }),
                      children: React.createElement("span", undefined, React.createElement($$Text.make, {
                                size: "Small",
                                singleLine: true,
                                color: Styles.Color.grey50,
                                children: Belt_Array.mapU(propertyGroup.properties, (function (param) {
                                          return param.name;
                                        })).join(", ")
                              }))
                    })));
}

var PropertyGroup = {
  Cell: undefined,
  buttonStyles: buttonStyles$1,
  propertyGroupInfoContainerStyles: propertyGroupInfoContainerStyles,
  headerStyles: headerStyles,
  typePillStyles: typePillStyles,
  make: EditableCellListItem$PropertyGroup
};

var buttonStyles$2 = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.pct(100)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.width(Css.pct(100)),
          tl: /* [] */0
        }
      }
    });

function tagInfoContainerStyles$1(mode) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: 150.0
                        }, undefined, undefined, "width"),
                    tl: {
                      hd: Css.width(mode === /* Viewing */0 ? Css.pct(100) : ({
                                NAME: "substract",
                                VAL: [
                                  Css.pct(100),
                                  Css.px(36)
                                ]
                              })),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function EditableCellListItem$Tag(Props) {
  var active = Props.active;
  var checkedOrigin = Props.checked;
  var disabled = Props.disabled;
  var tag = Props.item;
  var modeOpt = Props.mode;
  var onClick = Props.onClick;
  var onToggle = Props.onToggle;
  var mode = modeOpt !== undefined ? modeOpt : /* Editing */1;
  var match = React.useState(function () {
        return checkedOrigin;
      });
  var setChecked = match[1];
  React.useEffect((function () {
          Curry._1(setChecked, (function (param) {
                  return checkedOrigin;
                }));
          
        }), [checkedOrigin]);
  var onKeyPressToggle = React.useCallback((function (e) {
          Curry._1(setChecked, (function (state) {
                  Curry._2(onToggle, tag, !state);
                  return !state;
                }));
          e.preventDefault();
          e.stopPropagation();
          
        }), []);
  var onClick$1 = React.useCallback((function (e) {
          e.preventDefault();
          e.stopPropagation();
          if (mode === /* Viewing */0) {
            return Curry._1(onClick, e);
          } else {
            return Curry._1(setChecked, (function (state) {
                          var toggled = !state;
                          Curry._2(onToggle, tag, toggled);
                          return !state;
                        }));
          }
        }), [
        onClick,
        onToggle
      ]);
  var tmp = {
    className: buttonStyles$2,
    role: "button",
    onClick: onClick$1
  };
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  return React.createElement("div", tmp, active ? React.createElement(React.Fragment, undefined, React.createElement(KeyListener.make, {
                        keyName: " ",
                        onPress: onKeyPressToggle
                      }), React.createElement(KeyListener.make, {
                        keyName: "Enter",
                        onPress: onKeyPressToggle
                      })) : null, React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  initial: false,
                  children: mode === /* Editing */1 ? React.createElement(FramerMotion.motion.div, {
                          animate: {
                            opacity: 1,
                            width: "44px"
                          },
                          transition: {
                            duration: 0.15
                          },
                          initial: {
                            opacity: 0,
                            width: "0px"
                          },
                          exit: {
                            opacity: 0,
                            width: "44px"
                          },
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: /* [] */0
                              }),
                          children: null
                        }, React.createElement(EditableCellListItem$Checkbox, {
                              checked: match[0]
                            }), React.createElement(Spacer.make, {
                              width: 16
                            })) : null
                }), React.createElement("div", {
                  className: tagInfoContainerStyles$1(mode)
                }, React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          size: "Medium",
                          weight: "Medium",
                          singleLine: true,
                          color: Styles.Color.grey80,
                          children: tag
                        }))));
}

var Tag = {
  Cell: undefined,
  buttonStyles: buttonStyles$2,
  tagInfoContainerStyles: tagInfoContainerStyles$1,
  make: EditableCellListItem$Tag
};

var buttonStyles$3 = Curry._1(Css.style, {
      hd: Css.cursor("pointer"),
      tl: {
        hd: Css.maxWidth(Css.pct(100)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.width(Css.pct(100)),
            tl: /* [] */0
          }
        }
      }
    });

function propertyInfoContainerStyles(mode) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: 150.0
                      }, undefined, undefined, "width"),
                  tl: {
                    hd: Css.width(mode === /* Viewing */0 ? Css.pct(100) : ({
                              NAME: "substract",
                              VAL: [
                                Css.pct(100),
                                Css.px(36)
                              ]
                            })),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var validationStringStyles = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.monoFontFamily),
      tl: {
        hd: Css.color(Styles.Color.white),
        tl: {
          hd: Css.fontSize(Styles.FontSize.tiny),
          tl: /* [] */0
        }
      }
    });

function typePillStyles$1(stringLength) {
  return Curry._1(Css.style, {
              hd: Css.color(Styles.Color.grey80),
              tl: {
                hd: Css.fontFamily(Styles.monoFontFamily),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.regular),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.tiny),
                    tl: {
                      hd: Css.padding4(Css.px(1), Css.px(4), Css.px(0), Css.px(4)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.grey70, 0.1)),
                        tl: {
                          hd: Css.borderRadius(Css.px(4)),
                          tl: {
                            hd: Css.display("inlineBlock"),
                            tl: {
                              hd: Css.marginTop(Css.px(2)),
                              tl: {
                                hd: Css.textOverflow(stringLength > 6 ? "ellipsis" : ({
                                          NAME: "string",
                                          VAL: "nowrap"
                                        })),
                                tl: {
                                  hd: Css.whiteSpace("nowrap"),
                                  tl: {
                                    hd: Css.overflow("hidden"),
                                    tl: {
                                      hd: Css.minWidth(stringLength > 6 ? Css.px(0) : "maxContent"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function EditableCellListItem$EventProperty(Props) {
  var active = Props.active;
  var checkedOrigin = Props.checked;
  var disabled = Props.disabled;
  var item = Props.item;
  var modeOpt = Props.mode;
  var onClick = Props.onClick;
  var onToggle = Props.onToggle;
  var mode = modeOpt !== undefined ? modeOpt : /* Editing */1;
  var match = React.useState(function () {
        return checkedOrigin;
      });
  var setChecked = match[1];
  var typeString = (
    item.list ? "list of " : ""
  ) + item.type_;
  var validationStrings = PropertyValidations.getMatches(item.validations);
  React.useEffect((function () {
          Curry._1(setChecked, (function (param) {
                  return checkedOrigin;
                }));
          
        }), [checkedOrigin]);
  var onKeyPressToggle = React.useCallback((function (e) {
          Curry._1(setChecked, (function (state) {
                  var toggled = !state;
                  Curry._2(onToggle, item.id, toggled);
                  return !state;
                }));
          e.preventDefault();
          e.stopPropagation();
          
        }), []);
  var onClick$1 = React.useCallback((function (e) {
          e.preventDefault();
          e.stopPropagation();
          if (mode === /* Viewing */0) {
            return Curry._1(onClick, e);
          } else {
            return Curry._1(setChecked, (function (state) {
                          var toggled = !state;
                          Curry._2(onToggle, item.id, toggled);
                          return !state;
                        }));
          }
        }), [
        onClick,
        onToggle
      ]);
  var tmp = {
    className: buttonStyles$3,
    role: "button",
    onClick: onClick$1
  };
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  return React.createElement("div", tmp, active ? React.createElement(React.Fragment, undefined, React.createElement(KeyListener.make, {
                        keyName: " ",
                        onPress: onKeyPressToggle
                      }), React.createElement(KeyListener.make, {
                        keyName: "Enter",
                        onPress: onKeyPressToggle
                      })) : null, React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  initial: false,
                  children: mode === /* Editing */1 ? React.createElement(FramerMotion.motion.div, {
                          animate: {
                            opacity: 1,
                            width: "44px"
                          },
                          transition: {
                            duration: 0.15
                          },
                          initial: {
                            opacity: 0,
                            width: "0px"
                          },
                          exit: {
                            opacity: 0,
                            width: "44px"
                          },
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: /* [] */0
                              }),
                          children: null
                        }, React.createElement(EditableCellListItem$Checkbox, {
                              checked: match[0]
                            }), React.createElement(Spacer.make, {
                              width: 16
                            })) : null
                }), React.createElement("div", {
                  className: propertyInfoContainerStyles(mode)
                }, React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          size: "Medium",
                          weight: "Medium",
                          singleLine: true,
                          color: Styles.Color.grey80,
                          children: item.name
                        }), React.createElement(Spacer.make, {
                          width: 4
                        }), validationStrings ? React.createElement(PortalTooltip.make, {
                            renderTooltip: (function (param) {
                                var validationStrings = PropertyValidations.getMatches(item.validations);
                                return Belt_List.toArray(Belt_List.mapWithIndexU(validationStrings, (function (index, match_) {
                                                  return React.createElement("span", {
                                                              key: "match-" + index.toString(),
                                                              className: validationStringStyles
                                                            }, match_);
                                                })));
                              }),
                            children: React.createElement("span", {
                                  className: typePillStyles$1(typeString.length)
                                }, typeString + "…")
                          }) : React.createElement("span", {
                            className: typePillStyles$1(typeString.length)
                          }, typeString)), React.createElement($$Text.make, {
                      element: item.description.trim() === "" ? "Em" : "Span",
                      size: "Small",
                      singleLine: true,
                      color: Styles.Color.grey50,
                      children: item.description.trim() === "" ? "No description" : item.description
                    })));
}

var EventProperty = {
  Cell: undefined,
  buttonStyles: buttonStyles$3,
  propertyInfoContainerStyles: propertyInfoContainerStyles,
  validationStringStyles: validationStringStyles,
  typePillStyles: typePillStyles$1,
  make: EditableCellListItem$EventProperty
};

var buttonStyles$4 = Curry._1(Css.style, {
      hd: Css.cursor("pointer"),
      tl: {
        hd: Css.maxWidth(Css.pct(100)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.width(Css.pct(100)),
            tl: /* [] */0
          }
        }
      }
    });

function propertyInfoContainerStyles$1(mode) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: 150.0
                      }, undefined, undefined, "width"),
                  tl: {
                    hd: Css.width(mode === /* Viewing */0 ? Css.pct(100) : ({
                              NAME: "substract",
                              VAL: [
                                Css.pct(100),
                                Css.px(36)
                              ]
                            })),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var validationStringStyles$1 = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.monoFontFamily),
      tl: {
        hd: Css.color(Styles.Color.white),
        tl: {
          hd: Css.fontSize(Styles.FontSize.tiny),
          tl: /* [] */0
        }
      }
    });

function typePillStyles$2(stringLength) {
  return Curry._1(Css.style, {
              hd: Css.color(Styles.Color.grey80),
              tl: {
                hd: Css.fontFamily(Styles.monoFontFamily),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.regular),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.tiny),
                    tl: {
                      hd: Css.padding4(Css.px(1), Css.px(4), Css.px(0), Css.px(4)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.grey70, 0.1)),
                        tl: {
                          hd: Css.borderRadius(Css.px(4)),
                          tl: {
                            hd: Css.display("inlineBlock"),
                            tl: {
                              hd: Css.marginTop(Css.px(2)),
                              tl: {
                                hd: Css.textOverflow(stringLength > 6 ? "ellipsis" : ({
                                          NAME: "string",
                                          VAL: "nowrap"
                                        })),
                                tl: {
                                  hd: Css.whiteSpace("nowrap"),
                                  tl: {
                                    hd: Css.overflow("hidden"),
                                    tl: {
                                      hd: Css.minWidth(stringLength > 6 ? Css.px(0) : "maxContent"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function EditableCellListItem$GroupProperty(Props) {
  var active = Props.active;
  var checkedOrigin = Props.checked;
  var disabled = Props.disabled;
  var item = Props.item;
  var modeOpt = Props.mode;
  var onClick = Props.onClick;
  var onToggle = Props.onToggle;
  var mode = modeOpt !== undefined ? modeOpt : /* Editing */1;
  var item$1 = item[0];
  var groupTypeName = Belt_Option.getWithDefault(item[1], "Unkown Group Type");
  var match = React.useState(function () {
        return checkedOrigin;
      });
  var setChecked = match[1];
  var typeString = (
    item$1.list ? "list of " : ""
  ) + item$1.type_;
  var validationStrings = PropertyValidations.getMatches(item$1.validations);
  React.useEffect((function () {
          Curry._1(setChecked, (function (param) {
                  return checkedOrigin;
                }));
          
        }), [checkedOrigin]);
  var onKeyPressToggle = React.useCallback((function (e) {
          Curry._1(setChecked, (function (state) {
                  var toggled = !state;
                  Curry._2(onToggle, item$1.id, toggled);
                  return !state;
                }));
          e.preventDefault();
          e.stopPropagation();
          
        }), []);
  var onClick$1 = React.useCallback((function (e) {
          e.preventDefault();
          e.stopPropagation();
          if (mode === /* Viewing */0) {
            return Curry._1(onClick, e);
          } else {
            return Curry._1(setChecked, (function (state) {
                          var toggled = !state;
                          Curry._2(onToggle, item$1.id, toggled);
                          return !state;
                        }));
          }
        }), [
        onClick,
        onToggle
      ]);
  var tmp = {
    className: buttonStyles$4,
    role: "button",
    onClick: onClick$1
  };
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  return React.createElement("div", tmp, active ? React.createElement(React.Fragment, undefined, React.createElement(KeyListener.make, {
                        keyName: " ",
                        onPress: onKeyPressToggle
                      }), React.createElement(KeyListener.make, {
                        keyName: "Enter",
                        onPress: onKeyPressToggle
                      })) : null, React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  initial: false,
                  children: mode === /* Editing */1 ? React.createElement(FramerMotion.motion.div, {
                          animate: {
                            opacity: 1,
                            width: "44px"
                          },
                          transition: {
                            duration: 0.15
                          },
                          initial: {
                            opacity: 0,
                            width: "0px"
                          },
                          exit: {
                            opacity: 0,
                            width: "44px"
                          },
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: /* [] */0
                              }),
                          children: null
                        }, React.createElement(EditableCellListItem$Checkbox, {
                              checked: match[0]
                            }), React.createElement(Spacer.make, {
                              width: 16
                            })) : null
                }), React.createElement("div", {
                  className: propertyInfoContainerStyles$1(mode)
                }, React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          size: "Medium",
                          weight: "Medium",
                          singleLine: true,
                          color: Styles.Color.grey80,
                          children: null
                        }, React.createElement("b", undefined, groupTypeName + "/"), item$1.name), React.createElement(Spacer.make, {
                          width: 4
                        }), validationStrings ? React.createElement(PortalTooltip.make, {
                            renderTooltip: (function (param) {
                                var validationStrings = PropertyValidations.getMatches(item$1.validations);
                                return Belt_List.toArray(Belt_List.mapWithIndexU(validationStrings, (function (index, match_) {
                                                  return React.createElement("span", {
                                                              key: "match-" + index.toString(),
                                                              className: validationStringStyles$1
                                                            }, match_);
                                                })));
                              }),
                            children: React.createElement("span", {
                                  className: typePillStyles$2(typeString.length)
                                }, typeString + "…")
                          }) : React.createElement("span", {
                            className: typePillStyles$2(typeString.length)
                          }, typeString)), React.createElement($$Text.make, {
                      element: item$1.description.trim() === "" ? "Em" : "Span",
                      size: "Small",
                      singleLine: true,
                      color: Styles.Color.grey50,
                      children: item$1.description.trim() === "" ? "No description" : item$1.description
                    })));
}

var GroupProperty = {
  Cell: undefined,
  buttonStyles: buttonStyles$4,
  propertyInfoContainerStyles: propertyInfoContainerStyles$1,
  validationStringStyles: validationStringStyles$1,
  typePillStyles: typePillStyles$2,
  make: EditableCellListItem$GroupProperty
};

function EditableCellListItem$PropertyEvents(Props) {
  var active = Props.active;
  var checkedOrigin = Props.checked;
  var disabled = Props.disabled;
  var item = Props.item;
  var modeOpt = Props.mode;
  var onClick = Props.onClick;
  var onToggle = Props.onToggle;
  var mode = modeOpt !== undefined ? modeOpt : /* Editing */1;
  var match = React.useState(function () {
        return checkedOrigin;
      });
  var setChecked = match[1];
  React.useEffect((function () {
          Curry._1(setChecked, (function (param) {
                  return checkedOrigin;
                }));
          
        }), [checkedOrigin]);
  var onKeyPressToggle = React.useCallback((function (e) {
          Curry._1(setChecked, (function (state) {
                  var toggled = !state;
                  Curry._2(onToggle, item.id, toggled);
                  return !state;
                }));
          e.preventDefault();
          e.stopPropagation();
          
        }), []);
  var onClick$1 = React.useCallback((function (e) {
          e.preventDefault();
          e.stopPropagation();
          if (mode === /* Viewing */0) {
            return Curry._1(onClick, e);
          } else {
            return Curry._1(setChecked, (function (state) {
                          var toggled = !state;
                          Curry._2(onToggle, item.id, toggled);
                          return !state;
                        }));
          }
        }), [
        onClick,
        onToggle
      ]);
  var tmp = {
    className: buttonStyles$3,
    role: "button",
    onClick: onClick$1
  };
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  return React.createElement("div", tmp, active ? React.createElement(React.Fragment, undefined, React.createElement(KeyListener.make, {
                        keyName: " ",
                        onPress: onKeyPressToggle
                      }), React.createElement(KeyListener.make, {
                        keyName: "Enter",
                        onPress: onKeyPressToggle
                      })) : null, React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  initial: false,
                  children: mode === /* Editing */1 ? React.createElement(FramerMotion.motion.div, {
                          animate: {
                            opacity: 1,
                            width: "44px"
                          },
                          transition: {
                            duration: 0.15
                          },
                          initial: {
                            opacity: 0,
                            width: "0px"
                          },
                          exit: {
                            opacity: 0,
                            width: "44px"
                          },
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: /* [] */0
                              }),
                          children: null
                        }, React.createElement(EditableCellListItem$Checkbox, {
                              checked: match[0]
                            }), React.createElement(Spacer.make, {
                              width: 16
                            })) : null
                }), React.createElement("div", {
                  className: propertyInfoContainerStyles(mode ? /* Editing */1 : /* Viewing */0)
                }, React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          size: "Medium",
                          weight: "Medium",
                          singleLine: true,
                          color: Styles.Color.grey80,
                          children: item.name
                        }), React.createElement(Spacer.make, {
                          width: 4
                        })), React.createElement($$Text.make, {
                      element: item.description.trim() === "" ? "Em" : "Span",
                      size: "Small",
                      singleLine: true,
                      color: Styles.Color.grey50,
                      children: item.description.trim() === "" ? "No description" : item.description
                    })));
}

var PropertyEvents = {
  Cell: undefined,
  make: EditableCellListItem$PropertyEvents
};

exports.Checkbox = Checkbox;
exports.Category = Category;
exports.PropertyGroup = PropertyGroup;
exports.Tag = Tag;
exports.EventProperty = EventProperty;
exports.GroupProperty = GroupProperty;
exports.PropertyEvents = PropertyEvents;
/* buttonStyles Not a pure module */
