// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var _map = {"asc":"asc","desc":"desc"};

function sortDirectionToJs(param) {
  return param;
}

function sortDirectionFromJs(param) {
  return _map[param];
}

exports.sortDirectionToJs = sortDirectionToJs;
exports.sortDirectionFromJs = sortDirectionFromJs;
/* No side effect */
