// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var BillingStats = require("../../shared/models/BillingStats.bs.js");

function useBillingStats(schemaId, workspaceMembers) {
  var match = React.useState(function () {
        return "Loading";
      });
  var setBillingStats = match[1];
  var workspaceMembersRefetchKey = Belt_List.toArray(Belt_List.map(workspaceMembers, (function (member) {
                return member.role;
              }))).join(",");
  React.useEffect((function () {
          $$Promise.$$catch(Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).getIdToken().then(function (userToken) {
                        return fetch(Firebase.apiUrl + "/billing/stats" + (
                                    process.env.NODE_ENV === "development" ? "?dev=true" : ""
                                  ), Fetch.RequestInit.make(/* Post */2, [
                                          [
                                            "Accept",
                                            "application/json"
                                          ],
                                          [
                                            "Content-Type",
                                            "application/json"
                                          ],
                                          [
                                            "Authorization",
                                            "Bearer " + userToken
                                          ]
                                        ], Caml_option.some(JSON.stringify(Js_dict.fromArray([[
                                                        "schemaId",
                                                        schemaId
                                                      ]]))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                      }).then(function (response) {
                      return response.json();
                    }).then(function (json) {
                    return Curry._1(setBillingStats, (function (param) {
                                  return {
                                          NAME: "Loaded",
                                          VAL: BillingStats.decode(json)
                                        };
                                }));
                  }), (function (_error) {
                  return Promise.resolve(Curry._1(setBillingStats, (function (param) {
                                    return "Error";
                                  })));
                }));
          
        }), [workspaceMembersRefetchKey]);
  return match[0];
}

exports.useBillingStats = useBillingStats;
/* react Not a pure module */
