// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var FileView = require("../FileView.bs.js");
var Firebase = require("../../../bs-firestore/src/Firebase.bs.js");
var AvoConfig = require("../../../shared/utils/AvoConfig.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Debounce = require("lodash/debounce");

function fetchIntegrationExport(schemaId, branchId, integrationId, filename) {
  return Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                  return fetch(Firebase.apiUrl + "/c/v1/export", Fetch.RequestInit.make(/* Post */2, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + token
                                    ]
                                  ], Caml_option.some(JSON.stringify(Js_dict.fromArray([
                                                [
                                                  "schemaId",
                                                  schemaId
                                                ],
                                                [
                                                  "branchId",
                                                  branchId
                                                ],
                                                [
                                                  "integrations",
                                                  [Js_dict.fromArray([
                                                          [
                                                            "id",
                                                            integrationId
                                                          ],
                                                          [
                                                            "path",
                                                            filename
                                                          ]
                                                        ])]
                                                ],
                                                [
                                                  "requestClient",
                                                  "web"
                                                ]
                                              ]))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                }).then(function (response) {
                return response.json().then(function (json) {
                            return Promise.resolve([
                                        response,
                                        json
                                      ]);
                          });
              }).then(function (param) {
              if (param[0].status < 300) {
                return Promise.resolve(param[1]);
              } else {
                return Pervasives.failwith("Could not fetch export");
              }
            });
}

function IntegrationExport(Props) {
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var integration = Props.integration;
  var availability = Props.availability;
  var match = React.useState(function () {
        if (availability === "Unavailable") {
          return /* Unavailable */3;
        } else {
          return /* Initial */0;
        }
      });
  var setStatus = match[1];
  var status = match[0];
  var debouncedUpdateExport = Debounce((function (schemaId, branchId, integration) {
          Curry._1(setStatus, (function (param) {
                  return /* Loading */1;
                }));
          $$Promise.$$catch(fetchIntegrationExport(schemaId, branchId, integration.id, AvoConfig.getIntegrationFilename(integration)).then(function (response) {
                    return Promise.resolve(Curry._1(setStatus, (function (param) {
                                      return /* Success */{
                                              _0: Belt_Array.concatMany(Belt_Array.mapU(response.integrations, (function (integration) {
                                                          return Belt_Array.mapU(integration.code, (function ($$export) {
                                                                        return [
                                                                                $$export.path,
                                                                                $$export.content
                                                                              ];
                                                                      }));
                                                        })))
                                            };
                                    })));
                  }), (function (param) {
                  return Promise.resolve(Curry._1(setStatus, (function (param) {
                                    return /* Error */2;
                                  })));
                }));
          
        }), 1000, {
        leading: false,
        trailing: true
      });
  var handleFetchExport = function (param) {
    if (availability === "AvailableDuringTrial" || availability === "Available") {
      return debouncedUpdateExport(schemaId, branchId, integration);
    } else {
      return Curry._1(setStatus, (function (param) {
                    return /* Unavailable */3;
                  }));
    }
  };
  React.useEffect((function () {
          if (availability === "Unavailable") {
            Curry._1(setStatus, (function (param) {
                    return /* Unavailable */3;
                  }));
          } else if (typeof status === "number" && !(status === 2 || status === 1)) {
            
          } else {
            handleFetchExport(undefined);
          }
          
        }), [
        integration,
        availability
      ]);
  var match$1 = integration.config;
  if (match$1 === undefined) {
    return null;
  }
  if (typeof status !== "number") {
    return Belt_Option.mapWithDefault(Belt_Array.get(status._0, 0), null, (function (param) {
                  return React.createElement(FileView.make, {
                              mimeType: AvoConfig.getIntegrationMimeType(integration),
                              filename: param[0],
                              code: param[1],
                              startExpanded: true,
                              key: "fileview"
                            });
                }));
  }
  switch (status) {
    case /* Initial */0 :
        return React.createElement(FileView.make, {
                    filename: "Click to view payload preview...",
                    code: "",
                    loading: true,
                    onExpand: handleFetchExport,
                    key: "fileview"
                  });
    case /* Loading */1 :
        return React.createElement(FileView.make, {
                    filename: "Generating your payload preview...",
                    code: "",
                    loading: true,
                    key: "fileview"
                  });
    case /* Error */2 :
        return "Error";
    case /* Unavailable */3 :
        return React.createElement(FileView.make, {
                    filename: "Payload Preview",
                    code: "Upgrade to payload previewing...",
                    key: "fileview"
                  });
    
  }
}

var make = IntegrationExport;

exports.fetchIntegrationExport = fetchIntegrationExport;
exports.make = make;
/* react Not a pure module */
