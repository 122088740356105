// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Select = require("./Select.bs.js");
var Styles = require("./styles.bs.js");
var Printer = require("../../model/src/Printer.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ContextMenu = require("./ContextMenu.bs.js");
var ProfilePhoto = require("./ProfilePhoto.bs.js");
var App = require("firebase/app");
var AppFeatureFlag = require("./AppFeatureFlag.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

function resendInvite(schemaId, invite, viewerId, schemaGroup, globalSend) {
  return Belt_Option.forEach(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser), (function (user) {
                user.getIdToken().then(function (token) {
                      return fetch(Firebase.apiUrl + "/sendInvite", Fetch.RequestInit.make(/* Post */2, [
                                          [
                                            "Accept",
                                            "application/json"
                                          ],
                                          [
                                            "Content-Type",
                                            "application/json"
                                          ],
                                          [
                                            "Authorization",
                                            "Bearer " + token
                                          ]
                                        ], Caml_option.some(Belt_Option.getWithDefault(JSON.stringify({
                                                      emailToInvite: invite.id,
                                                      schemaId: schemaId
                                                    }), "")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (response) {
                                  var match = response.status;
                                  if (match !== 200) {
                                    if (match !== 429) {
                                      Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "AlertModal",
                                              VAL: [
                                                "Failed to send invite",
                                                "It looks like something unexpected occurred, please try again. If this issue persists, please contact support.",
                                                "Ok",
                                                (function (param) {
                                                    
                                                  })
                                              ]
                                            }
                                          });
                                      return Promise.resolve(undefined);
                                    } else {
                                      Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "AlertModal",
                                              VAL: [
                                                "Failed to send invite",
                                                "You've just recently resent an invite to this user. Please wait a moment before trying again.",
                                                "Ok",
                                                (function (param) {
                                                    
                                                  })
                                              ]
                                            }
                                          });
                                      return Promise.resolve(undefined);
                                    }
                                  }
                                  var match$1 = invite.role;
                                  var tmp;
                                  switch (match$1) {
                                    case "Admin" :
                                        tmp = "Admin";
                                        break;
                                    case "Comment Only" :
                                        tmp = "CommentOnly";
                                        break;
                                    case "Editor" :
                                        tmp = "Editor";
                                        break;
                                    case "Viewer" :
                                        tmp = "Viewer";
                                        break;
                                    default:
                                      tmp = "Editor";
                                  }
                                  AnalyticsRe.memberInviteResent(schemaGroup, tmp, invite.id, schemaGroup.branchId, schemaGroup.schemaId);
                                  var actionRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions").doc();
                                  return actionRef.set({
                                              id: actionRef.id,
                                              contentsJson: Printer.printAction({
                                                    NAME: "ResendInvite",
                                                    VAL: [
                                                      invite.id,
                                                      invite.role
                                                    ]
                                                  }),
                                              createdAt: App.default.firestore.FieldValue.serverTimestamp(),
                                              createdBy: viewerId,
                                              branchId: "master",
                                              audit: true,
                                              order: 0
                                            });
                                });
                    });
                
              }));
}

function removeInvite(schemaId, invite, viewerId, schemaGroup) {
  var batch = Firebase.app(undefined).firestore().batch();
  var inviteRef = Firebase.app(undefined).firestore().collection("invites").doc(invite.inviteId);
  var schemaInviteRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("invites").doc(invite.id);
  batch.delete(inviteRef);
  batch.delete(schemaInviteRef);
  var actionRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions").doc();
  batch.set(actionRef, {
        id: actionRef.id,
        contentsJson: Printer.printAction({
              NAME: "RemoveInvite",
              VAL: [
                invite.id,
                invite.role
              ]
            }),
        createdAt: App.default.firestore.FieldValue.serverTimestamp(),
        createdBy: viewerId,
        branchId: "master",
        audit: true,
        order: 0
      });
  $$Promise.$$catch(batch.commit(), (function (error) {
          return Promise.resolve((console.log("error", error), undefined));
        }));
  var match = invite.role;
  var tmp;
  switch (match) {
    case "Admin" :
        tmp = "Admin";
        break;
    case "Comment Only" :
        tmp = "CommentOnly";
        break;
    case "Editor" :
        tmp = "Editor";
        break;
    case "Viewer" :
        tmp = "Viewer";
        break;
    default:
      tmp = "Editor";
  }
  AnalyticsRe.memberInviteRemoved(schemaGroup, invite.id, tmp, schemaGroup.branchId, schemaGroup.schemaId);
  
}

var rootStyles = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(16)),
      tl: {
        hd: Css.paddingBottom(Css.px(16)),
        tl: {
          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
          tl: /* [] */0
        }
      }
    });

var infoStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.marginLeft(Css.px(12)),
          tl: {
            hd: Css.flexGrow(1.0),
            tl: {
              hd: Css.flexShrink(1.0),
              tl: {
                hd: Css.marginRight(Css.px(8)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function SchemaInvites(Props) {
  var schema = Props.schema;
  var viewerId = Props.viewerId;
  var viewerRole = Props.viewerRole;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var schemaInvites = FirebaseFetcherHooks.useSchemaInvites(schema.id);
  var hasCodegenAccessRole = AppFeatureFlag.useFeatureFlag("CodegenAccessRole");
  var globalSend = GlobalSendContext.use(undefined);
  return Belt_List.toArray(Belt_List.map(schemaInvites, (function (invite) {
                    var filter = invite.filter;
                    var match = Models.Role.tFromJs(invite.role);
                    var tmp;
                    var exit = 0;
                    if (viewerRole === "Admin" && match !== undefined) {
                      tmp = React.createElement(Select.make, {
                            disabled: true,
                            onSelect: (function (param) {
                                
                              }),
                            options: Belt_List.map(Models.roles(hasCodegenAccessRole), (function (inviteRole) {
                                    return [
                                            {
                                              NAME: "Label",
                                              VAL: Models.Role.getLabel(inviteRole)
                                            },
                                            Models.Role.tToJs(inviteRole)
                                          ];
                                  })),
                            value: Models.Role.tToJs(match)
                          });
                    } else {
                      exit = 1;
                    }
                    if (exit === 1) {
                      tmp = match !== undefined ? React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.grey70,
                              children: Models.Role.getLabel(match)
                            }) : null;
                    }
                    return React.createElement("div", {
                                key: invite.id,
                                className: rootStyles
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement(ProfilePhoto.Base.make, {
                                        picture: undefined,
                                        name: undefined,
                                        email: invite.id,
                                        size: 40
                                      }), React.createElement("div", {
                                        className: infoStyles
                                      }, React.createElement($$Text.make, {
                                            size: "Medium",
                                            weight: "Semi",
                                            children: invite.id
                                          }), React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Semi",
                                            color: Styles.Color.grey50,
                                            children: null
                                          }, "Invited", filter !== undefined && Caml_obj.caml_notequal(filter.tags, []) ? " - Limited to tags: " + filter.tags.join(", ") : null)), tmp, viewerRole === "Admin" ? React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.marginLeft(Css.px(8)),
                                                tl: /* [] */0
                                              })
                                        }, React.createElement(ContextMenu.make, {
                                              options: [
                                                {
                                                  NAME: "Option",
                                                  VAL: {
                                                    label: "Resend Invite",
                                                    onClick: (function (param) {
                                                        return resendInvite(schema.id, invite, viewerId, schemaGroup, globalSend);
                                                      })
                                                  }
                                                },
                                                {
                                                  NAME: "Option",
                                                  VAL: {
                                                    label: "Remove Invite",
                                                    onClick: (function (param) {
                                                        return removeInvite(schema.id, invite, viewerId, schemaGroup);
                                                      })
                                                  }
                                                }
                                              ]
                                            })) : null));
                  })));
}

var make = SchemaInvites;

exports.resendInvite = resendInvite;
exports.removeInvite = removeInvite;
exports.rootStyles = rootStyles;
exports.infoStyles = infoStyles;
exports.make = make;
/* rootStyles Not a pure module */
