// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function input(size, classNameOverwrites) {
  return Curry._1(Css.style, {
              hd: Css.unsafe("appearance", "none"),
              tl: {
                hd: Css.display("block"),
                tl: {
                  hd: Css.fontSize(size === "Small" ? Styles.FontSize.small : Styles.FontSize.regular),
                  tl: {
                    hd: Css.color(Styles.Color.grey90),
                    tl: {
                      hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: {
                            hd: Css.flexShrink(1.0),
                            tl: {
                              hd: Css.paddingTop(Css.px(6)),
                              tl: {
                                hd: Css.paddingRight(Css.px(8)),
                                tl: {
                                  hd: Css.paddingBottom(Css.px(6)),
                                  tl: {
                                    hd: Css.paddingLeft(Css.px(8)),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.transition({
                                              NAME: "ms",
                                              VAL: Styles.Duration.$$short
                                            }, undefined, undefined, "all"),
                                        tl: {
                                          hd: Css.lineHeight(Css.pct(140.0)),
                                          tl: {
                                            hd: Css.placeholder({
                                                  hd: Css.color(Styles.Color.grey50),
                                                  tl: {
                                                    hd: Css.fontWeight(Styles.FontWeight.medium),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            tl: {
                                              hd: Css.disabled({
                                                    hd: Css.borderColor(Styles.Color.grey30),
                                                    tl: {
                                                      hd: Css.backgroundColor(Styles.Color.grey10),
                                                      tl: {
                                                        hd: Css.color(Styles.Color.grey50),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }),
                                              tl: {
                                                hd: Css.focus({
                                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.blue)),
                                                      tl: {
                                                        hd: Css.outlineStyle("none"),
                                                        tl: {
                                                          hd: Css.borderColor(Styles.Color.grey40),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }),
                                                tl: {
                                                  hd: Css.hover({
                                                        hd: Css.borderColor(Styles.Color.grey40),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: Belt_Option.getWithDefault(classNameOverwrites, /* [] */0)
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function Textarea(Props) {
  var value = Props.value;
  var placeholder = Props.placeholder;
  var onChange = Props.onChange;
  var sizeOpt = Props.size;
  var classNameOverwrites = Props.classNameOverwrites;
  var id = Props.id;
  var disabledOpt = Props.disabled;
  var size = sizeOpt !== undefined ? sizeOpt : "Regular";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var tmp = {
    className: input(size, classNameOverwrites),
    disabled: disabled,
    value: value,
    onChange: (function ($$event) {
        return Curry._1(onChange, $$event.target.value);
      })
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(placeholder);
  }
  return React.createElement("textarea", tmp);
}

var make = Textarea;

exports.input = input;
exports.make = make;
/* Css Not a pure module */
