// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hover = require("./Hover.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Tooltip = require("./Tooltip.bs.js");
var AvoModel = require("./avoModel.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Markdown = require("./Markdown.bs.js");
var Tooltips = require("./markdown/tooltips/tooltips.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var ContextMenu = require("./ContextMenu.bs.js");
var NamedBranch = require("./NamedBranch.bs.js");
var OpsWithState = require("./OpsWithState.bs.js");
var PropertyInput = require("./PropertyInput.bs.js");
var PropertyUtils = require("./PropertyUtils.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var AppFeatureFlag = require("./AppFeatureFlag.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var IconArrowDownEast = require("./IconArrowDownEast.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var PropertyValidationUtils = require("./PropertyValidationUtils.bs.js");
var ModalConstraintEditorMatches = require("./ModalConstraintEditorMatches.bs.js");

var _map = {"Matches":"Matches","Min":"Min","Max":"Max","Shape":"Shape","NestedProperty":"NestedProperty","None":"None"};

function constraintTypeToJs(param) {
  return param;
}

function constraintTypeFromJs(param) {
  return _map[param];
}

function valueValidations(item) {
  var match = item.value;
  return match.VAL.validations;
}

function mapValidationType(x) {
  if (typeof x !== "object") {
    return Pervasives.failwith("Will never happen.");
  }
  var variant = x.NAME;
  if (variant === "Max") {
    return "Max";
  } else if (variant === "Min") {
    return "Min";
  } else {
    return Pervasives.failwith("Will never happen.");
  }
}

function emptyShape(key) {
  return {
          NAME: "Shape",
          VAL: {
            hd: {
              key: key,
              list: false,
              optional: false,
              value: {
                NAME: "Value",
                VAL: {
                  type_: "String",
                  validations: /* [] */0
                }
              }
            },
            tl: /* [] */0
          }
        };
}

function shapeRemover(validations, removeItem) {
  return Belt_List.keepMap(validations, (function (x) {
                if (typeof x !== "object") {
                  return x;
                }
                if (x.NAME !== "Shape") {
                  return x;
                }
                var nextItems = Belt_List.keep(x.VAL, (function (item) {
                        return removeItem.key !== item.key;
                      }));
                if (Belt_List.length(nextItems) === 0) {
                  return ;
                } else {
                  return {
                          NAME: "Shape",
                          VAL: nextItems
                        };
                }
              }));
}

function shapeReducer(validation, item) {
  if (typeof validation !== "object") {
    return valueValidations(item);
  }
  if (validation.NAME !== "Shape") {
    return valueValidations(item);
  }
  var newItemTypes = validation.VAL;
  var maybeShape = Belt_List.getBy(valueValidations(item), (function (item) {
          if (typeof item === "object") {
            return item.NAME === "Shape";
          } else {
            return false;
          }
        }));
  if (maybeShape !== undefined) {
    return Belt_List.map(valueValidations(item), (function (item) {
                  if (typeof item !== "object") {
                    return item;
                  }
                  if (item.NAME !== "Shape") {
                    return item;
                  }
                  var currentItemTypes = item.VAL;
                  var addingItemTypes = Belt_List.keep(newItemTypes, (function (newItemType) {
                          return !Belt_List.some(currentItemTypes, (function (currentItemType) {
                                        return currentItemType.key === newItemType.key;
                                      }));
                        }));
                  var currentItemTypes$1 = Belt_List.map(currentItemTypes, (function (currentItemType) {
                          var updatedItemType = Belt_List.getBy(newItemTypes, (function (newItemType) {
                                  return currentItemType.key === newItemType.key;
                                }));
                          return Belt_Option.getWithDefault(updatedItemType, currentItemType);
                        }));
                  var nextItemTypes = Belt_List.concat(currentItemTypes$1, addingItemTypes);
                  return {
                          NAME: "Shape",
                          VAL: nextItemTypes
                        };
                }));
  } else {
    return Belt_List.concat(valueValidations(item), {
                hd: validation,
                tl: /* [] */0
              });
  }
}

function renderItem(schema, parentItems, item, role, sendActions, property, context, hasLongValuesFeatureFlag) {
  var tmp;
  if (PropertyUtils.valueType(item) === "Object") {
    var itemTypes = Belt_List.head(Belt_List.keepMap(valueValidations(item), (function (x) {
                if (typeof x === "object" && x.NAME === "Shape") {
                  return x.VAL;
                }
                
              })));
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.padding4(Css.px(0), Css.px(2), Css.px(0), Css.px(20)),
                tl: /* [] */0
              })
        }, React.createElement("div", undefined, itemTypes !== undefined ? Belt_List.toArray(Belt_List.map(itemTypes, (function (subItem) {
                          return renderItem(schema, {
                                      hd: item,
                                      tl: parentItems
                                    }, subItem, role, sendActions, property, context, hasLongValuesFeatureFlag);
                        }))) : null), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(0), Css.px(2)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement(OpsWithState.make, {
                  value: "",
                  onFlush: (function (param) {
                      
                    }),
                  children: (function (value, onChange, param, param$1) {
                      var handleAddValue = function (param) {
                        if (value.length !== 0) {
                          Curry._1(onChange, "");
                          Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                [[
                                    {
                                      NAME: "AddPropertyValidation",
                                      VAL: [
                                        property.id,
                                        Belt_List.reduce(parentItems, {
                                              NAME: "Shape",
                                              VAL: {
                                                hd: {
                                                  key: item.key,
                                                  list: item.list,
                                                  optional: item.optional,
                                                  value: {
                                                    NAME: "Value",
                                                    VAL: {
                                                      type_: "Object",
                                                      validations: shapeReducer(emptyShape(value), item)
                                                    }
                                                  }
                                                },
                                                tl: /* [] */0
                                              }
                                            }, (function (validations, parentItem) {
                                                return {
                                                        NAME: "Shape",
                                                        VAL: {
                                                          hd: {
                                                            key: parentItem.key,
                                                            list: parentItem.list,
                                                            optional: parentItem.optional,
                                                            value: {
                                                              NAME: "Value",
                                                              VAL: {
                                                                type_: PropertyUtils.valueType(parentItem),
                                                                validations: shapeReducer(validations, parentItem)
                                                              }
                                                            }
                                                          },
                                                          tl: /* [] */0
                                                        }
                                                      };
                                              }))
                                      ]
                                    },
                                    context
                                  ]]
                              ]);
                          return ;
                        }
                        
                      };
                      return React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.margin(Css.px(2)),
                                            tl: /* [] */0
                                          }
                                        }
                                      })
                                }, React.createElement("input", {
                                      className: Curry._1(Css.merge, {
                                            hd: Curry._1(Css.style, Styles.input),
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.margin4(Css.px(5), Css.px(5), Css.px(5), Css.px(0)),
                                                    tl: {
                                                      hd: Css.padding2(Css.px(5), Css.px(10)),
                                                      tl: {
                                                        hd: Css.fontSize(Styles.FontSize.regular),
                                                        tl: {
                                                          hd: Css.borderRadius(Styles.Border.radius),
                                                          tl: {
                                                            hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      placeholder: "Add a key...",
                                      value: value,
                                      onKeyDown: (function ($$event) {
                                          var match = $$event.key;
                                          if (match === "Enter") {
                                            return handleAddValue(undefined);
                                          }
                                          
                                        }),
                                      onChange: (function ($$event) {
                                          return Curry._1(onChange, $$event.target.value);
                                        })
                                    }), React.createElement("div", undefined, React.createElement(Button.make, {
                                          label: "Add",
                                          onClick: (function (param) {
                                              return handleAddValue(undefined);
                                            })
                                        })));
                    })
                })));
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              key: item.key
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(0), Css.px(2)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.margin(Css.px(5)),
                            tl: /* [] */0
                          })
                    }, React.createElement(IconArrowDownEast.make, {
                          size: 12,
                          color: Styles.Color.grey50
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: /* [] */0
                          })
                    }, item.key), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.flexGrow(1.0),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("flexStart"),
                                  tl: {
                                    hd: Css.padding4(Css.px(0), Css.px(15), Css.px(0), Css.px(15)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement("select", {
                          className: Curry._1(Css.merge, {
                                hd: Curry._1(Css.style, Styles.select),
                                tl: {
                                  hd: Curry._1(Css.style, {
                                        hd: Css.fontSize(Css.px(12)),
                                        tl: {
                                          hd: Css.backgroundColor(Css.transparent),
                                          tl: {
                                            hd: Css.maxWidth(Css.px(100)),
                                            tl: {
                                              hd: Css.marginRight(Css.px(15)),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.regular),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }),
                          disabled: !Models.Role.canEdit(role),
                          value: PropertyUtils.printValueType(item),
                          onChange: (function (domEvent) {
                              var nextType = domEvent.target.value;
                              var type_ = TrackingPlanModel.Type.tFromJs(nextType);
                              if (type_ !== undefined) {
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            [[
                                                {
                                                  NAME: "AddPropertyValidation",
                                                  VAL: [
                                                    property.id,
                                                    Belt_List.reduce(parentItems, {
                                                          NAME: "Shape",
                                                          VAL: {
                                                            hd: {
                                                              key: item.key,
                                                              list: item.list,
                                                              optional: item.optional,
                                                              value: {
                                                                NAME: "Value",
                                                                VAL: {
                                                                  type_: type_,
                                                                  validations: /* [] */0
                                                                }
                                                              }
                                                            },
                                                            tl: /* [] */0
                                                          }
                                                        }, (function (validations, parentItem) {
                                                            return {
                                                                    NAME: "Shape",
                                                                    VAL: {
                                                                      hd: {
                                                                        key: parentItem.key,
                                                                        list: parentItem.list,
                                                                        optional: parentItem.optional,
                                                                        value: {
                                                                          NAME: "Value",
                                                                          VAL: {
                                                                            type_: PropertyUtils.valueType(parentItem),
                                                                            validations: shapeReducer(validations, parentItem)
                                                                          }
                                                                        }
                                                                      },
                                                                      tl: /* [] */0
                                                                    }
                                                                  };
                                                          }))
                                                  ]
                                                },
                                                context
                                              ]]
                                          ]);
                              }
                              
                            })
                        }, React.createElement("option", {
                              key: "string",
                              value: "string"
                            }, "string"), React.createElement("option", {
                              key: "int",
                              value: "int"
                            }, "int"), hasLongValuesFeatureFlag ? React.createElement("option", {
                                key: "long",
                                value: "long"
                              }, "long") : null, React.createElement("option", {
                              key: "float",
                              value: "float"
                            }, "float"), React.createElement("option", {
                              key: "bool",
                              value: "bool"
                            }, "bool"), React.createElement("option", {
                              key: "object",
                              value: "object"
                            }, "object")), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginRight(Css.px(15)),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.color(Styles.Color.grey80),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, React.createElement("input", {
                              id: "optional-key-" + item.key,
                              checked: item.optional,
                              disabled: !Models.Role.canEdit(role),
                              type: "checkbox",
                              onChange: (function ($$event) {
                                  var isOptional = $$event.target.checked;
                                  Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [[
                                            {
                                              NAME: "AddPropertyValidation",
                                              VAL: [
                                                property.id,
                                                Belt_List.reduce(parentItems, {
                                                      NAME: "Shape",
                                                      VAL: {
                                                        hd: {
                                                          key: item.key,
                                                          list: item.list,
                                                          optional: isOptional,
                                                          value: item.value
                                                        },
                                                        tl: /* [] */0
                                                      }
                                                    }, (function (validations, parentItem) {
                                                        return {
                                                                NAME: "Shape",
                                                                VAL: {
                                                                  hd: {
                                                                    key: parentItem.key,
                                                                    list: parentItem.list,
                                                                    optional: parentItem.optional,
                                                                    value: {
                                                                      NAME: "Value",
                                                                      VAL: {
                                                                        type_: PropertyUtils.valueType(parentItem),
                                                                        validations: shapeReducer(validations, parentItem)
                                                                      }
                                                                    }
                                                                  },
                                                                  tl: /* [] */0
                                                                }
                                                              };
                                                      }))
                                              ]
                                            },
                                            context
                                          ]]
                                      ]);
                                  
                                })
                            }), React.createElement("label", {
                              htmlFor: "optional-key-" + item.key
                            }, "Optional")), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginRight(Css.px(15)),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.color(Styles.Color.grey80),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, React.createElement("input", {
                              id: "list-key-" + item.key,
                              checked: item.list,
                              type: "checkbox",
                              onChange: (function ($$event) {
                                  var isList = $$event.target.checked;
                                  Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [[
                                            {
                                              NAME: "AddPropertyValidation",
                                              VAL: [
                                                property.id,
                                                Belt_List.reduce(parentItems, {
                                                      NAME: "Shape",
                                                      VAL: {
                                                        hd: {
                                                          key: item.key,
                                                          list: isList,
                                                          optional: item.optional,
                                                          value: item.value
                                                        },
                                                        tl: /* [] */0
                                                      }
                                                    }, (function (validations, parentItem) {
                                                        return {
                                                                NAME: "Shape",
                                                                VAL: {
                                                                  hd: {
                                                                    key: parentItem.key,
                                                                    list: parentItem.list,
                                                                    optional: parentItem.optional,
                                                                    value: {
                                                                      NAME: "Value",
                                                                      VAL: {
                                                                        type_: PropertyUtils.valueType(parentItem),
                                                                        validations: shapeReducer(validations, parentItem)
                                                                      }
                                                                    }
                                                                  },
                                                                  tl: /* [] */0
                                                                }
                                                              };
                                                      }))
                                              ]
                                            },
                                            context
                                          ]]
                                      ]);
                                  
                                })
                            }), React.createElement("label", {
                              htmlFor: "list-key-" + item.key
                            }, "List")), React.createElement("button", {
                          className: Curry._1(Css.merge, {
                                hd: Curry._1(Css.style, Styles.button),
                                tl: {
                                  hd: Curry._1(Css.style, {
                                        hd: Css.padding2(Css.px(5), Css.px(5)),
                                        tl: {
                                          hd: Css.marginLeft(Css.px(5)),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: {
                                              hd: Css.fontSize(Styles.FontSize.tiny),
                                              tl: {
                                                hd: Css.color(Styles.Color.grey70),
                                                tl: {
                                                  hd: Css.hover({
                                                        hd: Css.color(Styles.Color.grapeError),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }),
                          onClick: (function (param) {
                              if (Belt_List.length(parentItems) === 0) {
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [[
                                          {
                                            NAME: "RemovePropertyValidation",
                                            VAL: [
                                              property.id,
                                              {
                                                NAME: "Shape",
                                                VAL: {
                                                  hd: item,
                                                  tl: /* [] */0
                                                }
                                              }
                                            ]
                                          },
                                          context
                                        ]]
                                    ]);
                              } else {
                                var parentItem = Belt_List.headExn(parentItems);
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [[
                                          {
                                            NAME: "AddPropertyValidation",
                                            VAL: [
                                              property.id,
                                              Belt_List.reduce(Belt_List.tailExn(parentItems), {
                                                    NAME: "Shape",
                                                    VAL: {
                                                      hd: {
                                                        key: parentItem.key,
                                                        list: parentItem.list,
                                                        optional: parentItem.optional,
                                                        value: {
                                                          NAME: "Value",
                                                          VAL: {
                                                            type_: PropertyUtils.valueType(parentItem),
                                                            validations: shapeRemover(valueValidations(parentItem), item)
                                                          }
                                                        }
                                                      },
                                                      tl: /* [] */0
                                                    }
                                                  }, (function (validations, parentItem) {
                                                      return {
                                                              NAME: "Shape",
                                                              VAL: {
                                                                hd: {
                                                                  key: parentItem.key,
                                                                  list: parentItem.list,
                                                                  optional: parentItem.optional,
                                                                  value: {
                                                                    NAME: "Value",
                                                                    VAL: {
                                                                      type_: PropertyUtils.valueType(parentItem),
                                                                      validations: shapeReducer(validations, parentItem)
                                                                    }
                                                                  }
                                                                },
                                                                tl: /* [] */0
                                                              }
                                                            };
                                                    }))
                                            ]
                                          },
                                          context
                                        ]]
                                    ]);
                              }
                              
                            })
                        }, "Remove"))), tmp);
}

function ModalConstraintEditor(Props) {
  var property = Props.property;
  var sendActions = Props.sendActions;
  var schema = Props.schema;
  var role = Props.role;
  var addToast = Props.addToast;
  var model = Props.model;
  var schemaGroup = Props.schemaGroup;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var context = {
    propertyId: property.id,
    propertyQuery: property.id
  };
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var hasAnyValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("AnyValues");
  var hasLongValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("LongValues");
  var globalSend = GlobalSendContext.use(undefined);
  var match = property.type_;
  var supportedValidations;
  switch (match) {
    case "bool" :
        supportedValidations = /* [] */0;
        break;
    case "float" :
    case "int" :
        supportedValidations = {
          hd: "Min",
          tl: {
            hd: "Max",
            tl: /* [] */0
          }
        };
        break;
    case "object" :
        supportedValidations = {
          hd: "Shape",
          tl: {
            hd: "NestedProperty",
            tl: /* [] */0
          }
        };
        break;
    case "string" :
        supportedValidations = {
          hd: "Matches",
          tl: /* [] */0
        };
        break;
    default:
      supportedValidations = /* [] */0;
  }
  var availableValidations = Belt_List.keep(supportedValidations, (function (x) {
          if (x === "Max") {
            return !Belt_Option.isSome(Belt_List.head(Belt_List.keep(property.validations, (function (x) {
                                  if (typeof x === "object") {
                                    return x.NAME === "Max";
                                  } else {
                                    return false;
                                  }
                                }))));
          } else if (x === "Min") {
            return !Belt_Option.isSome(Belt_List.head(Belt_List.keep(property.validations, (function (x) {
                                  if (typeof x === "object") {
                                    return x.NAME === "Min";
                                  } else {
                                    return false;
                                  }
                                }))));
          } else if (x === "NestedProperty") {
            return !Belt_Option.isSome(Belt_List.head(Belt_List.keep(property.validations, (function (x) {
                                  if (typeof x === "object") {
                                    return x.NAME === "NestedProperty";
                                  } else {
                                    return false;
                                  }
                                }))));
          } else if (x === "None" || x === "Shape") {
            return false;
          } else {
            return !Belt_Option.isSome(Belt_List.head(Belt_List.keep(property.validations, (function (x) {
                                  if (typeof x === "object") {
                                    return x.NAME === "Matches";
                                  } else {
                                    return false;
                                  }
                                }))));
          }
        }));
  if (Belt_List.length(supportedValidations) === 0) {
    return null;
  } else {
    return React.createElement("div", undefined, Belt_List.toArray(Belt_List.mapWithIndex(Belt_List.keep(property.validations, (function (x) {
                              var variant = x.NAME;
                              if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
                                return false;
                              }
                              if (variant === "Max") {
                                var match = x.VAL;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var variant$1 = match.NAME;
                                if (variant$1 === "IntLit") {
                                  if (property.type_ === "int") {
                                    return Belt_List.has(supportedValidations, "Max", Caml_obj.caml_equal);
                                  } else {
                                    return false;
                                  }
                                } else if (variant$1 === "FloatLit" && property.type_ === "float") {
                                  return Belt_List.has(supportedValidations, "Max", Caml_obj.caml_equal);
                                } else {
                                  return false;
                                }
                              }
                              if (variant !== "Min") {
                                if (variant === "NestedProperty") {
                                  if (property.type_ === "object") {
                                    return Belt_List.has(supportedValidations, "NestedProperty", Caml_obj.caml_equal);
                                  } else {
                                    return false;
                                  }
                                } else if (variant === "Shape") {
                                  if (property.type_ === "object") {
                                    return Belt_List.has(supportedValidations, "Shape", Caml_obj.caml_equal);
                                  } else {
                                    return false;
                                  }
                                } else {
                                  return Belt_List.has(supportedValidations, "Matches", Caml_obj.caml_equal);
                                }
                              }
                              var match$1 = x.VAL;
                              if (typeof match$1 !== "object") {
                                return false;
                              }
                              var variant$2 = match$1.NAME;
                              if (variant$2 === "IntLit") {
                                if (property.type_ === "int") {
                                  return Belt_List.has(supportedValidations, "Min", Caml_obj.caml_equal);
                                } else {
                                  return false;
                                }
                              } else if (variant$2 === "FloatLit" && property.type_ === "float") {
                                return Belt_List.has(supportedValidations, "Min", Caml_obj.caml_equal);
                              } else {
                                return false;
                              }
                            })), (function (index, validation) {
                          var exit = 0;
                          var value;
                          var validation$1;
                          var value$1;
                          var validation$2;
                          var variant = validation.NAME;
                          if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
                            return Pervasives.failwith("Validation type not supported.");
                          }
                          if (variant === "Max") {
                            var match = validation.VAL;
                            if (typeof match !== "object") {
                              return Pervasives.failwith("Validation type not supported.");
                            }
                            var variant$1 = match.NAME;
                            if (variant$1 === "IntLit") {
                              value = match.VAL;
                              validation$1 = validation;
                              exit = 1;
                            } else {
                              if (variant$1 !== "FloatLit") {
                                return Pervasives.failwith("Validation type not supported.");
                              }
                              value$1 = match.VAL;
                              validation$2 = validation;
                              exit = 2;
                            }
                          } else if (variant === "Min") {
                            var match$1 = validation.VAL;
                            if (typeof match$1 !== "object") {
                              return Pervasives.failwith("Validation type not supported.");
                            }
                            var variant$2 = match$1.NAME;
                            if (variant$2 === "IntLit") {
                              value = match$1.VAL;
                              validation$1 = validation;
                              exit = 1;
                            } else {
                              if (variant$2 !== "FloatLit") {
                                return Pervasives.failwith("Validation type not supported.");
                              }
                              value$1 = match$1.VAL;
                              validation$2 = validation;
                              exit = 2;
                            }
                          } else {
                            if (variant !== "NestedProperty") {
                              if (variant === "Shape") {
                                return React.createElement("div", {
                                            key: "shape" + String(index)
                                          }, React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.alignItems("center"),
                                                        tl: {
                                                          hd: Css.justifyContent("spaceBetween"),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    })
                                              }, React.createElement("div", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                                          tl: {
                                                            hd: Css.fontSize(Styles.FontSize.regular),
                                                            tl: /* [] */0
                                                          }
                                                        })
                                                  }, "Object:")), React.createElement("div", undefined, Belt_List.toArray(Belt_List.map(validation.VAL, (function (item) {
                                                          return renderItem(schema, /* [] */0, item, role, sendActions, property, context, hasLongValuesFeatureFlag);
                                                        })))), React.createElement(OpsWithState.make, {
                                                value: "",
                                                onFlush: (function (param) {
                                                    
                                                  }),
                                                children: (function (value, onChange, param, param$1) {
                                                    var handleAddValue = function (param) {
                                                      if (value.length !== 0) {
                                                        Curry._1(onChange, "");
                                                        Curry.app(sendActions, [
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              [[
                                                                  {
                                                                    NAME: "AddPropertyValidation",
                                                                    VAL: [
                                                                      property.id,
                                                                      emptyShape(value)
                                                                    ]
                                                                  },
                                                                  context
                                                                ]]
                                                            ]);
                                                        return ;
                                                      }
                                                      
                                                    };
                                                    return React.createElement("div", {
                                                                className: Curry._1(Css.style, {
                                                                      hd: Css.display("flex"),
                                                                      tl: {
                                                                        hd: Css.alignItems("center"),
                                                                        tl: {
                                                                          hd: Css.margin(Css.px(2)),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    })
                                                              }, React.createElement("input", {
                                                                    className: Curry._1(Css.merge, {
                                                                          hd: Curry._1(Css.style, Styles.input),
                                                                          tl: {
                                                                            hd: Curry._1(Css.style, {
                                                                                  hd: Css.margin4(Css.px(5), Css.px(5), Css.px(5), Css.px(0)),
                                                                                  tl: {
                                                                                    hd: Css.padding2(Css.px(5), Css.px(10)),
                                                                                    tl: {
                                                                                      hd: Css.fontSize(Styles.FontSize.regular),
                                                                                      tl: {
                                                                                        hd: Css.borderRadius(Styles.Border.radius),
                                                                                        tl: {
                                                                                          hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                                                          tl: /* [] */0
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }),
                                                                            tl: /* [] */0
                                                                          }
                                                                        }),
                                                                    placeholder: "Add a key...",
                                                                    value: value,
                                                                    onKeyDown: (function ($$event) {
                                                                        var match = $$event.key;
                                                                        if (match === "Enter") {
                                                                          return handleAddValue(undefined);
                                                                        }
                                                                        
                                                                      }),
                                                                    onChange: (function ($$event) {
                                                                        return Curry._1(onChange, $$event.target.value);
                                                                      })
                                                                  }), React.createElement("div", undefined, React.createElement(Button.make, {
                                                                        label: "Add",
                                                                        onClick: (function (param) {
                                                                            return handleAddValue(undefined);
                                                                          })
                                                                      })));
                                                  })
                                              }));
                              } else {
                                return React.createElement(ModalConstraintEditorMatches.make, {
                                            property: property,
                                            sendActions: sendActions,
                                            schemaGroup: schemaGroup,
                                            role: role,
                                            model: model,
                                            addToast: addToast,
                                            key: "matches" + String(index)
                                          });
                              }
                            }
                            var propertyRefs = validation.VAL;
                            var items = Belt_List.keepMap(propertyRefs, (function (propertyRef) {
                                    return Belt_Option.map(AvoModel.getPropertyById(model, propertyRef.id), (function (property) {
                                                  return [
                                                          propertyRef,
                                                          property
                                                        ];
                                                }));
                                  }));
                            var tmp;
                            if (Models.Role.canEdit(role)) {
                              var match$2 = property.sendAs;
                              tmp = React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.width(Css.pct(100)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(PropertyInput.make, {
                                        propertyType: match$2 === 0 ? /* EventProperty */1 : property.sendAs,
                                        currentProperties: Belt_List.concat({
                                              hd: {
                                                TAG: /* Property */1,
                                                _0: property
                                              },
                                              tl: /* [] */0
                                            }, Belt_List.map(propertyRefs, (function (propRef) {
                                                    return {
                                                            TAG: /* PropertyRef */0,
                                                            _0: propRef
                                                          };
                                                  }))),
                                        currentGroups: /* [] */0,
                                        options: {
                                          hd: [
                                            undefined,
                                            Belt_List.map(AvoModel.splitProperties(model.properties).props, (function (item) {
                                                    return {
                                                            NAME: "Property",
                                                            VAL: item
                                                          };
                                                  }))
                                          ],
                                          tl: /* [] */0
                                        },
                                        onSelect: (function (item, param) {
                                            if (item.NAME === "Group") {
                                              return Pervasives.failwith("Property bundles cannot be added to other property bundles.");
                                            } else {
                                              return Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [[
                                                              {
                                                                NAME: "AddPropertyValidation",
                                                                VAL: [
                                                                  property.id,
                                                                  {
                                                                    NAME: "NestedProperty",
                                                                    VAL: {
                                                                      hd: {
                                                                        id: item.VAL.id,
                                                                        description: "",
                                                                        pinnedValue: undefined
                                                                      },
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                ]
                                                              },
                                                              {
                                                                propertyId: property.id,
                                                                propertyQuery: property.id
                                                              }
                                                            ]]
                                                        ]);
                                            }
                                          }),
                                        model: model,
                                        currentFilters: currentFilters,
                                        renderButton: (function (onClick, buttonRef) {
                                            return React.createElement("div", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.textAlign("left"),
                                                              tl: /* [] */0
                                                            })
                                                      }, React.createElement("button", {
                                                            ref: buttonRef,
                                                            className: Curry._1(Css.merge, {
                                                                  hd: Curry._1(Css.style, Styles.button),
                                                                  tl: {
                                                                    hd: Curry._1(Css.style, {
                                                                          hd: Css.width(Css.px(200)),
                                                                          tl: {
                                                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                            tl: {
                                                                              hd: Css.fontSize(Styles.FontSize.regular),
                                                                              tl: {
                                                                                hd: Css.color(Styles.Color.blue),
                                                                                tl: {
                                                                                  hd: Css.hover({
                                                                                        hd: Css.color(Styles.Color.blueSecondary),
                                                                                        tl: /* [] */0
                                                                                      }),
                                                                                  tl: {
                                                                                    hd: Css.focus({
                                                                                          hd: Css.textDecoration("underline"),
                                                                                          tl: /* [] */0
                                                                                        }),
                                                                                    tl: {
                                                                                      hd: Css.margin(Css.px(0)),
                                                                                      tl: {
                                                                                        hd: Css.padding2(Css.px(15), Css.px(5)),
                                                                                        tl: {
                                                                                          hd: Css.textAlign("left"),
                                                                                          tl: /* [] */0
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }),
                                                                    tl: /* [] */0
                                                                  }
                                                                }),
                                                            onClick: onClick
                                                          }, "+ Add Property"));
                                          }),
                                        parent: {
                                          NAME: "Property",
                                          VAL: property.id
                                        },
                                        sendActions: sendActions,
                                        filters: filters,
                                        propertyLocation: "PropertyDetails"
                                      }));
                            } else {
                              tmp = null;
                            }
                            return React.createElement("div", {
                                        key: "nestedProperty" + String(index)
                                      }, React.createElement("div", undefined, React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.alignItems("center"),
                                                        tl: {
                                                          hd: Css.justifyContent("spaceBetween"),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    })
                                              }, React.createElement("div", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                                          tl: {
                                                            hd: Css.fontSize(Styles.FontSize.regular),
                                                            tl: /* [] */0
                                                          }
                                                        })
                                                  }, "Object should contain:")), React.createElement("div", undefined, Belt_List.toArray(Belt_List.map(items, (function (param) {
                                                          var item = param[1];
                                                          var propertyRef = param[0];
                                                          var pinnedValue = propertyRef.pinnedValue;
                                                          var maybePinnedValue = pinnedValue !== undefined && PropertyValidationUtils.validatePinnedValue(pinnedValue, item) ? pinnedValue : undefined;
                                                          var tmp;
                                                          if (Models.Role.canEdit(role)) {
                                                            var match = AvoLimits.PinnedProperties.computeAvailability(workspace);
                                                            tmp = React.createElement(ContextMenu.make, {
                                                                  options: Belt_Array.concatMany([
                                                                        Belt_Array.concat([{
                                                                                NAME: "Option",
                                                                                VAL: {
                                                                                  label: (
                                                                                    maybePinnedValue !== undefined ? "Update Pinned Value" : "Pin Property Value"
                                                                                  ) + (
                                                                                    match === "Available" ? "" : " ⚡️"
                                                                                  ),
                                                                                  onClick: (function (param) {
                                                                                      var match = AvoLimits.PinnedProperties.computeAvailability(workspace);
                                                                                      return Curry._1(globalSend, {
                                                                                                  TAG: /* OpenModal */4,
                                                                                                  _0: match === "AvailableDuringTrial" || match === "Available" ? ({
                                                                                                        NAME: "PinNestedPropertyValue",
                                                                                                        VAL: [
                                                                                                          property.id,
                                                                                                          item.id
                                                                                                        ]
                                                                                                      }) : ({
                                                                                                        NAME: "BillingPrompt",
                                                                                                        VAL: "PinnedProperties"
                                                                                                      })
                                                                                                });
                                                                                    })
                                                                                }
                                                                              }], maybePinnedValue !== undefined ? [{
                                                                                  NAME: "Option",
                                                                                  VAL: {
                                                                                    label: "Remove Pinned Value",
                                                                                    onClick: (function (param) {
                                                                                        return Curry.app(sendActions, [
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    undefined,
                                                                                                    [[
                                                                                                        {
                                                                                                          NAME: "RemoveNestedPropertyPinnedValue",
                                                                                                          VAL: [
                                                                                                            property.id,
                                                                                                            item.id
                                                                                                          ]
                                                                                                        },
                                                                                                        {
                                                                                                          propertyId: item.id,
                                                                                                          propertyQuery: item.id
                                                                                                        }
                                                                                                      ]]
                                                                                                  ]);
                                                                                      })
                                                                                  }
                                                                                }] : []),
                                                                        [{
                                                                            NAME: "Option",
                                                                            VAL: {
                                                                              label: "Remove Property",
                                                                              onClick: (function (param) {
                                                                                  return Curry.app(sendActions, [
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              undefined,
                                                                                              [[
                                                                                                  {
                                                                                                    NAME: "RemovePropertyValidation",
                                                                                                    VAL: [
                                                                                                      property.id,
                                                                                                      {
                                                                                                        NAME: "NestedProperty",
                                                                                                        VAL: {
                                                                                                          hd: {
                                                                                                            id: item.id,
                                                                                                            description: "",
                                                                                                            pinnedValue: undefined
                                                                                                          },
                                                                                                          tl: /* [] */0
                                                                                                        }
                                                                                                      }
                                                                                                    ]
                                                                                                  },
                                                                                                  {
                                                                                                    propertyId: property.id,
                                                                                                    propertyQuery: property.id
                                                                                                  }
                                                                                                ]]
                                                                                            ]);
                                                                                })
                                                                            }
                                                                          }]
                                                                      ])
                                                                });
                                                          } else {
                                                            tmp = null;
                                                          }
                                                          return React.createElement("div", {
                                                                      key: propertyRef.id + item.id,
                                                                      className: Curry._1(Css.style, {
                                                                            hd: Css.display("flex"),
                                                                            tl: {
                                                                              hd: Css.width(Css.pct(100)),
                                                                              tl: {
                                                                                hd: Css.maxWidth(Css.pct(100)),
                                                                                tl: {
                                                                                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                                                                                  tl: {
                                                                                    hd: Css.alignItems("center"),
                                                                                    tl: {
                                                                                      hd: Css.justifyContent("spaceBetween"),
                                                                                      tl: /* [] */0
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          })
                                                                    }, React.createElement(Hover.make, {
                                                                          className: {
                                                                            hd: Css.width(Css.pct(90)),
                                                                            tl: {
                                                                              hd: Css.flexGrow(0.0),
                                                                              tl: /* [] */0
                                                                            }
                                                                          },
                                                                          children: (function (isHover) {
                                                                              return React.createElement(Link.make, {
                                                                                          path: Router.Link.addDrawerItem(undefined, {
                                                                                                NAME: "property",
                                                                                                VAL: [
                                                                                                  item.id,
                                                                                                  undefined
                                                                                                ]
                                                                                              }),
                                                                                          className: Curry._1(Css.style, {
                                                                                                hd: Css.flexGrow(0.0),
                                                                                                tl: {
                                                                                                  hd: Css.width(Css.pct(100)),
                                                                                                  tl: /* [] */0
                                                                                                }
                                                                                              }),
                                                                                          children: React.createElement("div", {
                                                                                                className: Curry._1(Css.style, {
                                                                                                      hd: Css.display("flex"),
                                                                                                      tl: {
                                                                                                        hd: Css.alignItems("center"),
                                                                                                        tl: /* [] */0
                                                                                                      }
                                                                                                    })
                                                                                              }, React.createElement("span", {
                                                                                                    className: Curry._1(Css.style, {
                                                                                                          hd: Css.marginRight(Css.px(4)),
                                                                                                          tl: /* [] */0
                                                                                                        })
                                                                                                  }, React.createElement(IconArrowDownEast.make, {
                                                                                                        size: 12
                                                                                                      })), React.createElement("div", {
                                                                                                    className: Curry._1(Css.style, {
                                                                                                          hd: Css.display("flex"),
                                                                                                          tl: {
                                                                                                            hd: Css.alignItems("center"),
                                                                                                            tl: {
                                                                                                              hd: Css.padding2(Css.px(15), Css.px(5)),
                                                                                                              tl: {
                                                                                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                                                                tl: {
                                                                                                                  hd: Css.fontSize(Styles.FontSize.regular),
                                                                                                                  tl: {
                                                                                                                    hd: Css.color(isHover ? Styles.Color.blue : Styles.Color.grey90),
                                                                                                                    tl: /* [] */0
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        })
                                                                                                  }, React.createElement($$Text.make, {
                                                                                                        size: "Medium",
                                                                                                        weight: "Semi",
                                                                                                        children: item.name
                                                                                                      })), maybePinnedValue !== undefined ? React.createElement("div", {
                                                                                                      className: Curry._1(Css.style, {
                                                                                                            hd: Css.padding2(Css.px(15), Css.px(10)),
                                                                                                            tl: {
                                                                                                              hd: Css.color(isHover ? Styles.Color.blue : Styles.Color.grey70),
                                                                                                              tl: {
                                                                                                                hd: Css.textOverflow("ellipsis"),
                                                                                                                tl: {
                                                                                                                  hd: Css.whiteSpace("nowrap"),
                                                                                                                  tl: {
                                                                                                                    hd: Css.overflow("hidden"),
                                                                                                                    tl: /* [] */0
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          })
                                                                                                    }, React.createElement($$Text.make, {
                                                                                                          element: "Span",
                                                                                                          size: "Small",
                                                                                                          weight: "Regular",
                                                                                                          children: "pinned to"
                                                                                                        }), React.createElement(Spacer.make, {
                                                                                                          display: "inlineBlock",
                                                                                                          width: 4
                                                                                                        }), React.createElement($$Text.make, {
                                                                                                          element: "Span",
                                                                                                          size: "Small",
                                                                                                          weight: "Semi",
                                                                                                          children: PropertyUtils.printPinnedValue(maybePinnedValue)
                                                                                                        }), React.createElement(Spacer.make, {
                                                                                                          display: "inlineBlock",
                                                                                                          width: 4
                                                                                                        }), React.createElement($$Text.make, {
                                                                                                          element: "Span",
                                                                                                          size: "Small",
                                                                                                          weight: "Regular",
                                                                                                          children: "on all events"
                                                                                                        })) : React.createElement("div", {
                                                                                                      className: Curry._1(Css.style, {
                                                                                                            hd: Css.padding2(Css.px(15), Css.px(10)),
                                                                                                            tl: {
                                                                                                              hd: Css.color(isHover ? Styles.Color.blue : Styles.Color.grey70),
                                                                                                              tl: {
                                                                                                                hd: Css.textOverflow("ellipsis"),
                                                                                                                tl: {
                                                                                                                  hd: Css.whiteSpace("nowrap"),
                                                                                                                  tl: {
                                                                                                                    hd: Css.overflow("hidden"),
                                                                                                                    tl: /* [] */0
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          })
                                                                                                    }, React.createElement($$Text.make, {
                                                                                                          size: "Small",
                                                                                                          weight: "Regular",
                                                                                                          children: PropertyUtils.printPropertyType(item, item.optionalWhenInObject, undefined, model, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, undefined)
                                                                                                        })))
                                                                                        });
                                                                            })
                                                                        }), tmp);
                                                        }))))), tmp);
                          }
                          switch (exit) {
                            case 1 :
                                return React.createElement("div", {
                                            key: String(value) + ((
                                                validation$1.NAME === "Min" ? "-min-" : "-max-"
                                              ) + String(index)),
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.marginBottom(Css.px(7)),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement("select", {
                                                className: Curry._1(Css.merge, {
                                                      hd: Curry._1(Css.style, Styles.select),
                                                      tl: {
                                                        hd: Curry._1(Css.style, {
                                                              hd: Css.fontSize(Styles.FontSize.small),
                                                              tl: {
                                                                hd: Css.backgroundColor(Css.transparent),
                                                                tl: {
                                                                  hd: Css.maxWidth(Css.px(100)),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                disabled: !Models.Role.canEdit(role),
                                                value: mapValidationType(validation$1),
                                                onChange: (function (domEvent) {
                                                    var nextValidationType = Belt_Option.getWithDefault(TrackingPlanModel.constraint_FromJs(domEvent.target.value), "Min");
                                                    var handleSuccess = function (branch) {
                                                      var tmp;
                                                      if (typeof validation$1 === "object") {
                                                        var variant = validation$1.NAME;
                                                        tmp = variant === "Max" ? "Max" : (
                                                            variant === "Min" ? "Min" : "None"
                                                          );
                                                      } else {
                                                        tmp = "None";
                                                      }
                                                      return AnalyticsRe.constraintTypeUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), Belt_Option.getWithDefault(AnalyticsRe.toConstraintTypeFromJs(domEvent.target.value), "None"), "Change", tmp, property.name, NamedBranch.getId(branch), schemaGroup.schemaId);
                                                    };
                                                    if (nextValidationType === "Max") {
                                                      return Curry.app(sendActions, [
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  handleSuccess,
                                                                  undefined,
                                                                  [
                                                                    [
                                                                      {
                                                                        NAME: "RemovePropertyValidation",
                                                                        VAL: [
                                                                          property.id,
                                                                          validation$1
                                                                        ]
                                                                      },
                                                                      context
                                                                    ],
                                                                    [
                                                                      {
                                                                        NAME: "AddPropertyValidation",
                                                                        VAL: [
                                                                          property.id,
                                                                          {
                                                                            NAME: "Max",
                                                                            VAL: {
                                                                              NAME: "IntLit",
                                                                              VAL: value
                                                                            }
                                                                          }
                                                                        ]
                                                                      },
                                                                      context
                                                                    ]
                                                                  ]
                                                                ]);
                                                    } else if (nextValidationType === "Min") {
                                                      return Curry.app(sendActions, [
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  handleSuccess,
                                                                  undefined,
                                                                  [
                                                                    [
                                                                      {
                                                                        NAME: "RemovePropertyValidation",
                                                                        VAL: [
                                                                          property.id,
                                                                          validation$1
                                                                        ]
                                                                      },
                                                                      context
                                                                    ],
                                                                    [
                                                                      {
                                                                        NAME: "AddPropertyValidation",
                                                                        VAL: [
                                                                          property.id,
                                                                          {
                                                                            NAME: "Min",
                                                                            VAL: {
                                                                              NAME: "IntLit",
                                                                              VAL: value
                                                                            }
                                                                          }
                                                                        ]
                                                                      },
                                                                      context
                                                                    ]
                                                                  ]
                                                                ]);
                                                    } else {
                                                      return ;
                                                    }
                                                  })
                                              }, React.createElement("option", {
                                                    value: mapValidationType(validation$1)
                                                  }, mapValidationType(validation$1)), Belt_List.toArray(Belt_List.map(availableValidations, (function (validation) {
                                                          return React.createElement("option", {
                                                                      key: validation,
                                                                      value: validation
                                                                    }, validation);
                                                        })))), React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.width(Css.pct(100)),
                                                      tl: /* [] */0
                                                    })
                                              }, React.createElement(OpsWithState.make, {
                                                    value: value.toString(),
                                                    onFlush: (function (value) {
                                                        if (value.length === 0) {
                                                          return ;
                                                        }
                                                        try {
                                                          var match = Caml_format.caml_int_of_string(value);
                                                          if (typeof validation$1 !== "object") {
                                                            return Pervasives.failwith("Will never happen.");
                                                          }
                                                          var variant = validation$1.NAME;
                                                          if (variant === "Max") {
                                                            return Curry.app(sendActions, [
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        [[
                                                                            {
                                                                              NAME: "AddPropertyValidation",
                                                                              VAL: [
                                                                                property.id,
                                                                                {
                                                                                  NAME: "Max",
                                                                                  VAL: {
                                                                                    NAME: "IntLit",
                                                                                    VAL: match
                                                                                  }
                                                                                }
                                                                              ]
                                                                            },
                                                                            context
                                                                          ]]
                                                                      ]);
                                                          } else if (variant === "Min") {
                                                            return Curry.app(sendActions, [
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        [[
                                                                            {
                                                                              NAME: "AddPropertyValidation",
                                                                              VAL: [
                                                                                property.id,
                                                                                {
                                                                                  NAME: "Min",
                                                                                  VAL: {
                                                                                    NAME: "IntLit",
                                                                                    VAL: match
                                                                                  }
                                                                                }
                                                                              ]
                                                                            },
                                                                            context
                                                                          ]]
                                                                      ]);
                                                          } else {
                                                            return Pervasives.failwith("Will never happen.");
                                                          }
                                                        }
                                                        catch (exn){
                                                          return ;
                                                        }
                                                      }),
                                                    children: (function (value, onChange, onFocus, onBlur) {
                                                        return React.createElement("input", {
                                                                    className: Curry._1(Css.merge, {
                                                                          hd: Curry._1(Css.style, Styles.button),
                                                                          tl: {
                                                                            hd: Curry._1(Css.style, {
                                                                                  hd: Css.display("block"),
                                                                                  tl: {
                                                                                    hd: Css.padding2(Css.px(5), Css.px(10)),
                                                                                    tl: {
                                                                                      hd: Css.marginLeft(Css.px(5)),
                                                                                      tl: {
                                                                                        hd: Css.fontSize(Styles.FontSize.regular),
                                                                                        tl: {
                                                                                          hd: Css.textAlign("left"),
                                                                                          tl: {
                                                                                            hd: Css.overflow("hidden"),
                                                                                            tl: {
                                                                                              hd: Css.textOverflow("ellipsis"),
                                                                                              tl: {
                                                                                                hd: Css.whiteSpace("nowrap"),
                                                                                                tl: {
                                                                                                  hd: Css.borderRadius(Styles.Border.radius),
                                                                                                  tl: {
                                                                                                    hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                                                                    tl: /* [] */0
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }),
                                                                            tl: /* [] */0
                                                                          }
                                                                        }),
                                                                    disabled: !Models.Role.canEdit(role),
                                                                    step: 1,
                                                                    type: "number",
                                                                    value: value,
                                                                    onFocus: (function ($$event) {
                                                                        return Curry._1(onFocus, $$event.target.value);
                                                                      }),
                                                                    onBlur: (function ($$event) {
                                                                        return Curry._1(onBlur, $$event.target.value);
                                                                      }),
                                                                    onChange: (function ($$event) {
                                                                        return Curry._1(onChange, $$event.target.value);
                                                                      })
                                                                  });
                                                      })
                                                  })), Models.Role.canEdit(role) ? React.createElement("button", {
                                                  className: Curry._1(Css.merge, {
                                                        hd: Curry._1(Css.style, Styles.button),
                                                        tl: {
                                                          hd: Curry._1(Css.style, {
                                                                hd: Css.padding2(Css.px(5), Css.px(10)),
                                                                tl: {
                                                                  hd: Css.marginLeft(Css.px(5)),
                                                                  tl: {
                                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                    tl: {
                                                                      hd: Css.fontSize(Styles.FontSize.small),
                                                                      tl: {
                                                                        hd: Css.color(Styles.Color.grapeError),
                                                                        tl: {
                                                                          hd: Css.hover({
                                                                                hd: Css.color(Styles.Color.grapeErrorSecondary),
                                                                                tl: /* [] */0
                                                                              }),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }),
                                                          tl: /* [] */0
                                                        }
                                                      }),
                                                  onClick: (function (param) {
                                                      Curry.app(sendActions, [
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            [[
                                                                {
                                                                  NAME: "RemovePropertyValidation",
                                                                  VAL: [
                                                                    property.id,
                                                                    validation$1
                                                                  ]
                                                                },
                                                                context
                                                              ]]
                                                          ]);
                                                      var variant = validation$1.NAME;
                                                      AnalyticsRe.constraintTypeUpdated(schemaGroup, "None", "Remove", variant === "Max" ? "Max" : (
                                                              variant === "Min" ? "Min" : "Matches"
                                                            ), property.name, schemaGroup.branchId, schemaGroup.schemaId);
                                                      
                                                    })
                                                }, "Remove") : null);
                            case 2 :
                                return React.createElement("div", {
                                            key: value$1.toString() + ((
                                                validation$2.NAME === "Min" ? "-min" : "-max"
                                              ) + String(index)),
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.marginBottom(Css.px(7)),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement("select", {
                                                className: Curry._1(Css.merge, {
                                                      hd: Curry._1(Css.style, Styles.select),
                                                      tl: {
                                                        hd: Curry._1(Css.style, {
                                                              hd: Css.fontSize(Styles.FontSize.small),
                                                              tl: {
                                                                hd: Css.backgroundColor(Css.transparent),
                                                                tl: {
                                                                  hd: Css.maxWidth(Css.px(100)),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                disabled: !Models.Role.canEdit(role),
                                                value: mapValidationType(validation$2),
                                                onChange: (function (domEvent) {
                                                    var nextValidationType = Belt_Option.getWithDefault(TrackingPlanModel.constraint_FromJs(domEvent.target.value), "Min");
                                                    if (nextValidationType === "Max") {
                                                      Curry.app(sendActions, [
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            [[
                                                                {
                                                                  NAME: "RemovePropertyValidation",
                                                                  VAL: [
                                                                    property.id,
                                                                    validation$2
                                                                  ]
                                                                },
                                                                context
                                                              ]]
                                                          ]);
                                                      Curry.app(sendActions, [
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            [[
                                                                {
                                                                  NAME: "AddPropertyValidation",
                                                                  VAL: [
                                                                    property.id,
                                                                    {
                                                                      NAME: "Max",
                                                                      VAL: {
                                                                        NAME: "FloatLit",
                                                                        VAL: value$1
                                                                      }
                                                                    }
                                                                  ]
                                                                },
                                                                context
                                                              ]]
                                                          ]);
                                                    } else if (nextValidationType === "Min") {
                                                      Curry.app(sendActions, [
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            [[
                                                                {
                                                                  NAME: "RemovePropertyValidation",
                                                                  VAL: [
                                                                    property.id,
                                                                    validation$2
                                                                  ]
                                                                },
                                                                context
                                                              ]]
                                                          ]);
                                                      Curry.app(sendActions, [
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            [[
                                                                {
                                                                  NAME: "AddPropertyValidation",
                                                                  VAL: [
                                                                    property.id,
                                                                    {
                                                                      NAME: "Min",
                                                                      VAL: {
                                                                        NAME: "FloatLit",
                                                                        VAL: value$1
                                                                      }
                                                                    }
                                                                  ]
                                                                },
                                                                context
                                                              ]]
                                                          ]);
                                                    }
                                                    var tmp;
                                                    if (typeof validation$2 === "object") {
                                                      var variant = validation$2.NAME;
                                                      tmp = variant === "Max" ? "Max" : (
                                                          variant === "Min" ? "Min" : "None"
                                                        );
                                                    } else {
                                                      tmp = "None";
                                                    }
                                                    AnalyticsRe.constraintTypeUpdated(schemaGroup, Belt_Option.getWithDefault(AnalyticsRe.toConstraintTypeFromJs(domEvent.target.value), "None"), "Change", tmp, property.name, schemaGroup.branchId, schemaGroup.schemaId);
                                                    
                                                  })
                                              }, React.createElement("option", {
                                                    value: mapValidationType(validation$2)
                                                  }, mapValidationType(validation$2)), Belt_List.toArray(Belt_List.map(availableValidations, (function (validation) {
                                                          return React.createElement("option", {
                                                                      key: validation,
                                                                      value: validation
                                                                    }, validation);
                                                        })))), React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.width(Css.pct(100)),
                                                      tl: /* [] */0
                                                    })
                                              }, React.createElement(OpsWithState.make, {
                                                    value: value$1.toString(),
                                                    onFlush: (function (value) {
                                                        if (value.length === 0) {
                                                          return ;
                                                        }
                                                        try {
                                                          var match = Number(value);
                                                          if (typeof validation$2 !== "object") {
                                                            return Pervasives.failwith("Will never happen.");
                                                          }
                                                          var variant = validation$2.NAME;
                                                          if (variant === "Max") {
                                                            return Curry.app(sendActions, [
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        [[
                                                                            {
                                                                              NAME: "AddPropertyValidation",
                                                                              VAL: [
                                                                                property.id,
                                                                                {
                                                                                  NAME: "Max",
                                                                                  VAL: {
                                                                                    NAME: "FloatLit",
                                                                                    VAL: match
                                                                                  }
                                                                                }
                                                                              ]
                                                                            },
                                                                            context
                                                                          ]]
                                                                      ]);
                                                          } else if (variant === "Min") {
                                                            return Curry.app(sendActions, [
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        [[
                                                                            {
                                                                              NAME: "AddPropertyValidation",
                                                                              VAL: [
                                                                                property.id,
                                                                                {
                                                                                  NAME: "Min",
                                                                                  VAL: {
                                                                                    NAME: "FloatLit",
                                                                                    VAL: match
                                                                                  }
                                                                                }
                                                                              ]
                                                                            },
                                                                            context
                                                                          ]]
                                                                      ]);
                                                          } else {
                                                            return Pervasives.failwith("Will never happen.");
                                                          }
                                                        }
                                                        catch (exn){
                                                          return ;
                                                        }
                                                      }),
                                                    children: (function (value, onChange, onFocus, onBlur) {
                                                        return React.createElement("input", {
                                                                    className: Curry._1(Css.merge, {
                                                                          hd: Curry._1(Css.style, Styles.button),
                                                                          tl: {
                                                                            hd: Curry._1(Css.style, {
                                                                                  hd: Css.display("block"),
                                                                                  tl: {
                                                                                    hd: Css.padding2(Css.px(5), Css.px(10)),
                                                                                    tl: {
                                                                                      hd: Css.marginLeft(Css.px(5)),
                                                                                      tl: {
                                                                                        hd: Css.fontSize(Styles.FontSize.regular),
                                                                                        tl: {
                                                                                          hd: Css.textAlign("left"),
                                                                                          tl: {
                                                                                            hd: Css.overflow("hidden"),
                                                                                            tl: {
                                                                                              hd: Css.textOverflow("ellipsis"),
                                                                                              tl: {
                                                                                                hd: Css.whiteSpace("nowrap"),
                                                                                                tl: {
                                                                                                  hd: Css.borderRadius(Styles.Border.radius),
                                                                                                  tl: {
                                                                                                    hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                                                                    tl: /* [] */0
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }),
                                                                            tl: /* [] */0
                                                                          }
                                                                        }),
                                                                    disabled: !Models.Role.canEdit(role),
                                                                    type: "number",
                                                                    value: value,
                                                                    onFocus: (function ($$event) {
                                                                        return Curry._1(onFocus, $$event.target.value);
                                                                      }),
                                                                    onBlur: (function ($$event) {
                                                                        return Curry._1(onBlur, $$event.target.value);
                                                                      }),
                                                                    onChange: (function ($$event) {
                                                                        return Curry._1(onChange, $$event.target.value);
                                                                      })
                                                                  });
                                                      })
                                                  })), Models.Role.canEdit(role) ? React.createElement("button", {
                                                  className: Curry._1(Css.merge, {
                                                        hd: Curry._1(Css.style, Styles.button),
                                                        tl: {
                                                          hd: Curry._1(Css.style, {
                                                                hd: Css.marginLeft(Css.px(5)),
                                                                tl: {
                                                                  hd: Css.padding2(Css.px(5), Css.px(10)),
                                                                  tl: {
                                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                    tl: {
                                                                      hd: Css.fontSize(Styles.FontSize.small),
                                                                      tl: {
                                                                        hd: Css.color(Styles.Color.grapeError),
                                                                        tl: {
                                                                          hd: Css.hover({
                                                                                hd: Css.color(Styles.Color.grapeErrorSecondary),
                                                                                tl: /* [] */0
                                                                              }),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }),
                                                          tl: /* [] */0
                                                        }
                                                      }),
                                                  onClick: (function (param) {
                                                      return Curry.app(sendActions, [
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  (function (branch) {
                                                                      var variant = validation$2.NAME;
                                                                      return AnalyticsRe.constraintTypeUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "None", "Remove", variant === "Max" ? "Max" : (
                                                                                    variant === "Min" ? "Min" : "Matches"
                                                                                  ), property.name, NamedBranch.getId(branch), schemaGroup.schemaId);
                                                                    }),
                                                                  undefined,
                                                                  [[
                                                                      {
                                                                        NAME: "RemovePropertyValidation",
                                                                        VAL: [
                                                                          property.id,
                                                                          validation$2
                                                                        ]
                                                                      },
                                                                      context
                                                                    ]]
                                                                ]);
                                                    })
                                                }, "Remove") : null);
                            
                          }
                        }))), !Models.Role.canEdit(role) || Belt_List.length(availableValidations) === 0 ? null : React.createElement(React.Fragment, undefined, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              children: "Property value rules"
                            }), React.createElement(Tooltip.WithAnalytics.make, {
                              fromItem: {
                                itemId: property.id,
                                itemName: property.name,
                                itemType: "Property"
                              },
                              title: "Property value rules",
                              children: React.createElement(Tooltip.Content.make, {
                                    children: React.createElement(Markdown.make, {
                                          source: Tooltips.propertyValueRules
                                        })
                                  })
                            })), React.createElement("button", {
                          className: Curry._1(Css.merge, {
                                hd: Curry._1(Css.style, Styles.button),
                                tl: {
                                  hd: Curry._1(Css.style, {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.color(Styles.Color.blue),
                                          tl: {
                                            hd: Css.padding2(Css.px(2), Css.px(0)),
                                            tl: {
                                              hd: Css.width(Css.pct(100)),
                                              tl: {
                                                hd: Css.textAlign("left"),
                                                tl: {
                                                  hd: Css.fontSize(Styles.FontSize.regular),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.blueSecondary),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }),
                          onClick: (function (param) {
                              var constraintType = Belt_List.head(availableValidations);
                              if (constraintType === undefined) {
                                return ;
                              }
                              var handleSuccess = function (branch) {
                                return AnalyticsRe.constraintTypeUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), constraintType, "Add", "None", property.name, NamedBranch.getId(branch), schemaGroup.schemaId);
                              };
                              if (constraintType === "Max") {
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            handleSuccess,
                                            undefined,
                                            [[
                                                {
                                                  NAME: "AddPropertyValidation",
                                                  VAL: [
                                                    property.id,
                                                    {
                                                      NAME: "Max",
                                                      VAL: property.type_ === "int" ? ({
                                                            NAME: "IntLit",
                                                            VAL: 0
                                                          }) : ({
                                                            NAME: "FloatLit",
                                                            VAL: 0
                                                          })
                                                    }
                                                  ]
                                                },
                                                context
                                              ]]
                                          ]);
                              } else if (constraintType === "Min") {
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            handleSuccess,
                                            undefined,
                                            [[
                                                {
                                                  NAME: "AddPropertyValidation",
                                                  VAL: [
                                                    property.id,
                                                    {
                                                      NAME: "Min",
                                                      VAL: property.type_ === "int" ? ({
                                                            NAME: "IntLit",
                                                            VAL: 0
                                                          }) : ({
                                                            NAME: "FloatLit",
                                                            VAL: 0
                                                          })
                                                    }
                                                  ]
                                                },
                                                context
                                              ]]
                                          ]);
                              } else if (constraintType === "NestedProperty") {
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            handleSuccess,
                                            undefined,
                                            [[
                                                {
                                                  NAME: "AddPropertyValidation",
                                                  VAL: [
                                                    property.id,
                                                    {
                                                      NAME: "NestedProperty",
                                                      VAL: /* [] */0
                                                    }
                                                  ]
                                                },
                                                context
                                              ]]
                                          ]);
                              } else if (constraintType === "Shape") {
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            handleSuccess,
                                            undefined,
                                            [[
                                                {
                                                  NAME: "AddPropertyValidation",
                                                  VAL: [
                                                    property.id,
                                                    {
                                                      NAME: "Shape",
                                                      VAL: /* [] */0
                                                    }
                                                  ]
                                                },
                                                context
                                              ]]
                                          ]);
                              } else if (constraintType === "None") {
                                return ;
                              } else {
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            handleSuccess,
                                            undefined,
                                            [[
                                                {
                                                  NAME: "AddPropertyValidation",
                                                  VAL: [
                                                    property.id,
                                                    {
                                                      NAME: "Matches",
                                                      VAL: /* [] */0
                                                    }
                                                  ]
                                                },
                                                context
                                              ]]
                                          ]);
                              }
                            })
                        }, "+ Add rule")));
  }
}

var make = ModalConstraintEditor;

exports.constraintTypeToJs = constraintTypeToJs;
exports.constraintTypeFromJs = constraintTypeFromJs;
exports.valueValidations = valueValidations;
exports.mapValidationType = mapValidationType;
exports.emptyShape = emptyShape;
exports.shapeRemover = shapeRemover;
exports.shapeReducer = shapeReducer;
exports.renderItem = renderItem;
exports.make = make;
/* Css Not a pure module */
