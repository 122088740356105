// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconAvo(Props) {
  var sizeOpt = Props.size;
  var grayOpt = Props.gray;
  var size = sizeOpt !== undefined ? sizeOpt : 140;
  var gray = grayOpt !== undefined ? grayOpt : false;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.px(size)),
                    tl: /* [] */0
                  }),
              version: "1.1",
              viewBox: "0 0 361 126",
              xmlns: "http://www.w3.org/2000/svg",
              xmlnsXlink: "http://www.w3.org/1999/xlink"
            }, React.createElement("g", {
                  id: "Page-1",
                  fill: "none",
                  fillRule: "evenodd",
                  stroke: "none",
                  strokeWidth: "1"
                }, React.createElement("g", {
                      id: "avo_template",
                      transform: "translate(-1853.000000, -356.000000)"
                    }, React.createElement("g", {
                          id: "Group-9",
                          transform: "translate(1853.000000, 356.000000)"
                        }, React.createElement("path", {
                              className: Curry._1(Css.style, {
                                    hd: Css_Legacy_Core.SVG.fill(gray ? Styles.Color.grey30 : Styles.Color.grey90),
                                    tl: /* [] */0
                                  }),
                              id: "Fill-1",
                              d: "M297.8602,13.1708 C270.4982,13.1708 248.2352,35.4328 248.2352,62.7948 C248.2352,90.1578 270.4982,112.4198 297.8602,112.4198 C325.2222,112.4198 347.4832,90.1578 347.4832,62.7948 C347.4832,35.4328 325.2222,13.1708 297.8602,13.1708 M297.8602,125.2768 C263.4082,125.2768 235.3782,97.2478 235.3782,62.7948 C235.3782,28.3428 263.4082,0.3148 297.8602,0.3148 C332.3112,0.3148 360.3392,28.3428 360.3392,62.7948 C360.3392,97.2478 332.3112,125.2768 297.8602,125.2768"
                            }), React.createElement("polygon", {
                              className: Curry._1(Css.style, {
                                    hd: Css_Legacy_Core.SVG.fill(gray ? Styles.Color.grey30 : Styles.Color.grey90),
                                    tl: /* [] */0
                                  }),
                              id: "Fill-3",
                              points: "241.5009 0.2792 227.7809 0.2792 187.8109 104.8622 147.7529 0.2792 133.9439 0.2792 181.8059 125.1592 187.8109 125.1592 193.8149 125.1592"
                            }), React.createElement("path", {
                              className: Curry._1(Css.style, {
                                    hd: Css_Legacy_Core.SVG.fill(gray ? Styles.Color.grey30 : Styles.Color.grey90),
                                    tl: /* [] */0
                                  }),
                              id: "Fill-5",
                              d: "M62.7137,13.0536 C35.3517,13.0536 13.0897,35.3156 13.0897,62.6776 C13.0897,90.0406 35.3517,112.3026 62.7137,112.3026 C90.0767,112.3026 112.3387,90.0406 112.3387,62.6776 C112.3387,35.3156 90.0767,13.0536 62.7137,13.0536 M62.7137,125.1596 C28.2617,125.1596 0.2337,97.1306 0.2337,62.6776 C0.2337,28.2256 28.2617,0.1976 62.7137,0.1976 C97.1667,0.1976 125.1947,28.2256 125.1947,62.6776 C125.1947,97.1306 97.1667,125.1596 62.7137,125.1596"
                            }), React.createElement("polygon", {
                              className: Curry._1(Css.style, {
                                    hd: Css.unsafe("fill", gray ? Styles.Color.toString(Styles.Color.grey40) : "color(display-p3 0.925 0 0.549)"),
                                    tl: /* [] */0
                                  }),
                              id: "Fill-7",
                              fill: Styles.Color.toString(gray ? Styles.Color.grey40 : Styles.Color.magenta),
                              points: "108.66 125.277 127.945 125.277 127.945 0.197 108.66 0.197"
                            })))));
}

var make = IconAvo;

exports.make = make;
/* Css Not a pure module */
