// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Icon = require("./Icon.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Mantine = require("./Mantine.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

var itemWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.minHeight(Css.px(40)),
          tl: {
            hd: Css.paddingLeft(Css.px(10)),
            tl: {
              hd: Css.paddingRight(Css.px(44)),
              tl: {
                hd: Css.paddingTop(Css.px(4)),
                tl: {
                  hd: Css.paddingBottom(Css.px(4)),
                  tl: {
                    hd: Css.margin("zero"),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var input_0 = Css.borderColor(Styles.Color.grey30);

var input_1 = {
  hd: Css.textOverflow("ellipsis"),
  tl: {
    hd: Css.whiteSpace("nowrap"),
    tl: {
      hd: Css.overflow("hidden"),
      tl: {
        hd: Css.paddingRight(Css.px(20)),
        tl: /* [] */0
      }
    }
  }
};

var input = {
  hd: input_0,
  tl: input_1
};

var dropdown_0 = Css.borderWidth(Css.px(1));

var dropdown_1 = {
  hd: Css.borderColor(Styles.Color.grey30),
  tl: {
    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                  NAME: "num",
                  VAL: 0.10
                }))),
    tl: {
      hd: Css.minWidth("maxContent"),
      tl: {
        hd: Css.marginTop(Css.px(0)),
        tl: /* [] */0
      }
    }
  }
};

var dropdown = {
  hd: dropdown_0,
  tl: dropdown_1
};

var itemsWrapper_0 = Css.padding("zero");

var itemsWrapper_1 = {
  hd: Css.paddingTop(Css.px(6)),
  tl: {
    hd: Css.paddingBottom(Css.px(8)),
    tl: /* [] */0
  }
};

var itemsWrapper = {
  hd: itemsWrapper_0,
  tl: itemsWrapper_1
};

var item_0 = Css.display("flex");

var item_1 = {
  hd: Css.alignItems("center"),
  tl: {
    hd: Css.margin("zero"),
    tl: {
      hd: Css.marginBottom(Css.px(1)),
      tl: {
        hd: Css.padding("zero"),
        tl: {
          hd: Css.borderRadius("zero"),
          tl: {
            hd: Css.transitions({
                  hd: Css_Legacy_Core.Transition.shorthand({
                        NAME: "ms",
                        VAL: Styles.Duration.$$short
                      }, undefined, undefined, "background-color"),
                  tl: {
                    hd: Css_Legacy_Core.Transition.shorthand({
                          NAME: "ms",
                          VAL: Styles.Duration.$$default
                        }, undefined, undefined, "color"),
                    tl: /* [] */0
                  }
                }),
            tl: {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.grey20),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.fontSize(Styles.FontSize.small),
                tl: {
                  hd: Css.selector("&[data-disabled]", {
                        hd: Css.color(Styles.Color.grey50),
                        tl: {
                          hd: Css.hover({
                                hd: Css.backgroundColor("transparent"),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    }
  }
};

var item = {
  hd: item_0,
  tl: item_1
};

var itemSelected_0 = Css.color(Styles.Color.grey90);

var itemSelected_1 = {
  hd: Css.backgroundColor(Styles.Color.blue10),
  tl: {
    hd: Css.hover({
          hd: Css.backgroundColor(Styles.Color.grey20),
          tl: /* [] */0
        }),
    tl: /* [] */0
  }
};

var itemSelected = {
  hd: itemSelected_0,
  tl: itemSelected_1
};

var separatorLabel_0 = Css.color(Styles.Color.grey80);

var separatorLabel_1 = {
  hd: Css.fontWeight(Styles.FontWeight.bold),
  tl: {
    hd: Css.fontSize(Styles.FontSize.small),
    tl: {
      hd: Css.letterSpacing(Css.em(-0.04)),
      tl: {
        hd: Css.textTransform("uppercase"),
        tl: {
          hd: Css.height(Css.px(30)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.margin("zero"),
              tl: {
                hd: Css.padding("zero"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.after({
                          hd: Css.display("none"),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.paddingLeft(Css.px(10)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var separatorLabel = {
  hd: separatorLabel_0,
  tl: separatorLabel_1
};

var separator_0 = Css.margin("zero");

var separator_1 = {
  hd: Css.marginBottom(Css.px(1)),
  tl: {
    hd: Css.padding("zero"),
    tl: {
      hd: Css.selector(".mantine-Divider-horizontal", {
            hd: Css.margin("zero"),
            tl: {
              hd: Css.marginLeft(Css.px(-10)),
              tl: {
                hd: Css.padding("zero"),
                tl: {
                  hd: Css.paddingLeft(Css.px(10)),
                  tl: {
                    hd: Css.borderColor(Styles.Color.grey30),
                    tl: {
                      hd: Css.borderTopWidth(Css.pxFloat(0.5)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }),
      tl: {
        hd: Css.selector(":not(:has(.mantine-Select-separatorLabel))", {
              hd: Css.marginTop(Css.pxFloat(7.5)),
              tl: {
                hd: Css.marginBottom(Css.pxFloat(7.5)),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    }
  }
};

var separator = {
  hd: separator_0,
  tl: separator_1
};

function wrapper(customMinWidth, customMaxWidth) {
  return {
          hd: Belt_Option.mapWithDefault(customMinWidth, Styles.emptyStyle, (function (w) {
                  return Css.minWidth(Css.px(w));
                })),
          tl: {
            hd: Belt_Option.mapWithDefault(customMaxWidth, Styles.emptyStyle, (function (w) {
                    return Css.maxWidth(Css.px(w));
                  })),
            tl: /* [] */0
          }
        };
}

var Style = {
  itemWrapper: itemWrapper,
  input: input,
  dropdown: dropdown,
  itemsWrapper: itemsWrapper,
  item: item,
  itemSelected: itemSelected,
  separatorLabel: separatorLabel,
  separator: separator,
  wrapper: wrapper
};

function AvoSelect$ItemComponent(Props) {
  var item = Props.item;
  var description = item.description;
  return React.createElement("div", {
              className: itemWrapper
            }, React.createElement("div", undefined, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(item.disabled), Styles.Color.grey70, (function (disabled) {
                              if (disabled) {
                                return Styles.Color.grey60;
                              } else {
                                return Styles.Color.grey70;
                              }
                            })),
                      maxWidth: Css.pct(100.0),
                      children: item.label
                    }), description !== undefined ? React.createElement("div", {
                        className: "avo-select-item-description"
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.grey50,
                            withWordBreak: true,
                            children: description
                          })) : null));
}

var ItemComponent = Mantine.Select.Item({
      make: AvoSelect$ItemComponent
    });

function AvoSelect(Props) {
  var data = Props.data;
  var defaultValue = Props.defaultValue;
  var disabled = Props.disabled;
  var maxHeightOpt = Props.maxHeight;
  var minWidth = Props.minWidth;
  var maxWidth = Props.maxWidth;
  var onChange = Props.onChange;
  var value = Props.value;
  var maxHeight = maxHeightOpt !== undefined ? maxHeightOpt : 500;
  var tmp = {
    data: data,
    disabled: disabled,
    dropdownStyles: dropdown,
    inputStyles: input,
    itemComponent: ItemComponent.make,
    itemStyles: item,
    itemSelectedStyles: itemSelected,
    itemsWrapperStyles: itemsWrapper,
    maxDropdownHeight: maxHeight,
    onChange: onChange,
    rightSection: React.createElement(Icon.make, {
          type_: "chevronDown",
          size: "small",
          color: Styles.Color.grey70
        }),
    rightSectionWidth: 32,
    separatorStyles: separator,
    separatorLabelStyles: separatorLabel,
    size: "xs",
    transition: "fade",
    transitionDuration: 130,
    wrapperStyles: wrapper(minWidth, maxWidth)
  };
  if (defaultValue !== undefined) {
    tmp.defaultValue = Caml_option.valFromOption(defaultValue);
  }
  if (value !== undefined) {
    tmp.value = Caml_option.valFromOption(value);
  }
  return React.createElement(Mantine.Select.make, tmp);
}

var make = AvoSelect;

exports.Style = Style;
exports.ItemComponent = ItemComponent;
exports.make = make;
/* itemWrapper Not a pure module */
