// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var FeatureFlag = require("./FeatureFlag.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");

var featureFlagValues = {
  contents: undefined
};

function emptyFeatureFlagsCliParameters(param) {
  return {
          warnings: [],
          successMessages: [],
          flagsToForce: []
        };
}

function codegenFeatureFlags(param) {
  return Belt_Array.keepU(FeatureFlag.featureFlags, (function (flag) {
                if (flag === "ServerTrackingWithDeviceId" || flag === "SwiftTrackInterface" || flag === "SplitSwiftFiles" || flag === "ImprovedPropertyGroupsUnfolding" || flag === "ReduceSizeIfNotDev" || flag === "KotlinCoroutines" || flag === "DotNet6" || flag === "KotlinTrackInterface" || flag === "SplitKtFiles" || flag === "UseFetchInNode") {
                  return true;
                } else {
                  return flag === "SkipEmptySystemPropsInJavascript";
                }
              }));
}

function printAvailableCodegenFeatureFlags(param) {
  return Belt_Array.mapU(codegenFeatureFlags(undefined), FeatureFlag.featureFlagToJs).join(",");
}

function featureEnabledWithOverrides(schema, feature, flagsToForce) {
  if (Belt_Array.someU(flagsToForce, (function (forceFlag) {
            return forceFlag === feature;
          }))) {
    return true;
  } else {
    return FeatureFlag.featureEnabled(schema, feature);
  }
}

function initFeatureFlags(schema, flagsToForce) {
  featureFlagValues.contents = Caml_option.some(Belt_MapString.fromArray(Belt_Array.mapU(FeatureFlag.featureFlags, (function (featureFlag) {
                  return [
                          FeatureFlag.featureFlagToString(featureFlag),
                          featureEnabledWithOverrides(schema, featureFlag, flagsToForce)
                        ];
                }))));
  
}

function parseForceFeaturesCliParameter(schema, forceFeatures) {
  var features = forceFeatures.trim() !== "" ? forceFeatures.split(",") : [];
  var parseResult = Belt_Array.reduceU(features, {
        warnings: [],
        successMessages: [],
        flagsToForce: []
      }, (function (featureFlagsResult, feature) {
          var featureFlag = FeatureFlag.featureFlagFromJs(feature);
          var parsedFeatureFlag = featureFlag !== undefined && Belt_Option.isSome(Belt_Array.getBy(codegenFeatureFlags(undefined), (function (ff) {
                      return ff === featureFlag;
                    }))) ? featureFlag : undefined;
          if (parsedFeatureFlag !== undefined) {
            if (FeatureFlag.featureEnabled(schema, parsedFeatureFlag)) {
              return {
                      warnings: featureFlagsResult.warnings,
                      successMessages: Belt_Array.concat(featureFlagsResult.successMessages, ["Feature '" + feature + "' already enabled in your workspace"]),
                      flagsToForce: featureFlagsResult.flagsToForce
                    };
            } else {
              return {
                      warnings: featureFlagsResult.warnings,
                      successMessages: Belt_Array.concat(featureFlagsResult.successMessages, ["Feature '" + feature + "' force enabled successfully"]),
                      flagsToForce: Belt_Array.concat(featureFlagsResult.flagsToForce, [parsedFeatureFlag])
                    };
            }
          } else {
            return {
                    warnings: Belt_Array.concat(featureFlagsResult.warnings, ["Feature '" + feature + "' not found"]),
                    successMessages: featureFlagsResult.successMessages,
                    flagsToForce: featureFlagsResult.flagsToForce
                  };
          }
        }));
  if (Caml_obj.caml_notequal(parseResult.warnings, [])) {
    return {
            warnings: Belt_Array.concat(parseResult.warnings, ["Available features: " + printAvailableCodegenFeatureFlags(undefined)]),
            successMessages: parseResult.successMessages,
            flagsToForce: parseResult.flagsToForce
          };
  } else {
    return parseResult;
  }
}

exports.featureFlagValues = featureFlagValues;
exports.emptyFeatureFlagsCliParameters = emptyFeatureFlagsCliParameters;
exports.codegenFeatureFlags = codegenFeatureFlags;
exports.printAvailableCodegenFeatureFlags = printAvailableCodegenFeatureFlags;
exports.featureEnabledWithOverrides = featureEnabledWithOverrides;
exports.initFeatureFlags = initFeatureFlags;
exports.parseForceFeaturesCliParameter = parseForceFeaturesCliParameter;
/* No side effect */
