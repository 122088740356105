// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var Icons = require("./Icons.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Spacer = require("./Spacer.bs.js");
var Stripe = require("./Stripe.bs.js");
var Styles = require("./styles.bs.js");
var $$Window = require("./externals/window.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var StripeCouponInput = require("./StripeCouponInput.bs.js");

function createTeamV2Subscription(schemaId, token, annual, promoCode) {
  return Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).getIdToken().then(function (userToken) {
                  return fetch(Firebase.apiUrl + ("/createSubscription?plan=" + ((
                                  annual ? "teamv2Annual" : "teamv2"
                                ) + (
                                  process.env.NODE_ENV === "development" ? "&dev=true" : ""
                                ))), Fetch.RequestInit.make(/* Post */2, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + userToken
                                    ]
                                  ], Caml_option.some(JSON.stringify(Js_dict.fromArray(Belt_Array.concat([
                                                    [
                                                      "token",
                                                      token.id
                                                    ],
                                                    [
                                                      "schemaId",
                                                      schemaId
                                                    ]
                                                  ], promoCode !== undefined ? [[
                                                        "promotionCode",
                                                        promoCode
                                                      ]] : [])))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                }).then(function (prim) {
                return prim.json();
              }).then(function (resJson) {
              if (Json_decode.field("ok", Json_decode.bool, resJson)) {
                return Promise.resolve({
                            TAG: /* Ok */0,
                            _0: undefined
                          });
              } else {
                return Promise.resolve({
                            TAG: /* Error */1,
                            _0: Json_decode.field("error", (function (json) {
                                    return Json_decode.field("message", Json_decode.string, json);
                                  }), resJson)
                          });
              }
            });
}

function updatePayingMethod(schemaId, token, subscription, promoCode) {
  return Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).getIdToken().then(function (userToken) {
              return fetch(Firebase.apiUrl + "/updateSubscriptionBillingDetails" + (
                          process.env.NODE_ENV === "development" ? "?dev=true" : ""
                        ), Fetch.RequestInit.make(/* Post */2, [
                                [
                                  "Accept",
                                  "application/json"
                                ],
                                [
                                  "Content-Type",
                                  "application/json"
                                ],
                                [
                                  "Authorization",
                                  "Bearer " + userToken
                                ]
                              ], Caml_option.some(JSON.stringify(Js_dict.fromArray(Belt_Array.concat([
                                                [
                                                  "token",
                                                  token.id
                                                ],
                                                [
                                                  "schemaId",
                                                  schemaId
                                                ],
                                                [
                                                  "customerId",
                                                  subscription.customer
                                                ],
                                                [
                                                  "subscriptionId",
                                                  subscription.id
                                                ]
                                              ], promoCode !== undefined ? [[
                                                    "promotionCode",
                                                    promoCode
                                                  ]] : [])))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
            });
}

function cancelSubscription(schemaId, subscriptionId) {
  return Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).getIdToken().then(function (userToken) {
                  return fetch(Firebase.apiUrl + "/cancelSubscription" + (
                              process.env.NODE_ENV === "development" ? "?dev=true" : ""
                            ), Fetch.RequestInit.make(/* Post */2, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + userToken
                                    ]
                                  ], Caml_option.some(JSON.stringify(Js_dict.fromArray([
                                                [
                                                  "subscriptionId",
                                                  subscriptionId
                                                ],
                                                [
                                                  "schemaId",
                                                  schemaId
                                                ]
                                              ]))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                }).then(function (prim) {
                return prim.json();
              }).then(function (param) {
              return Promise.resolve(undefined);
            });
}

function StripeBilling$PaymentInput(Props) {
  var schemaId = Props.schemaId;
  var onSubmitStart = Props.onSubmitStart;
  var onSubmitError = Props.onSubmitError;
  var onSubmitSuccess = Props.onSubmitSuccess;
  var isLoading = Props.isLoading;
  var label = Props.label;
  var promoCode = React.useRef(undefined);
  React.useEffect((function () {
          var stripe = window.Stripe(Stripe.getApiKey(undefined));
          var elements = stripe.elements();
          var style = {
            base: {
              color: Styles.Color.toString(Styles.Color.grey90),
              fontSmoothing: "antialiased",
              fontSize: "20px",
              "::placeholder": {
                color: Styles.Color.toString(Styles.Color.grey80)
              }
            },
            invalid: {
              color: Styles.Color.toString(Styles.Color.grapeErrorSecondary),
              iconColor: Styles.Color.toString(Styles.Color.grapeError)
            }
          };
          var card = elements.create("card", Object.assign(style, {
                    hidePostalCode: true
                  }));
          card.mount("#card-element");
          card.addEventListener("change", (function ($$event) {
                  var displayError = $$Window.$$Document.getElementById("card-errors");
                  var match = $$event.error;
                  if (match == null) {
                    if (displayError !== undefined) {
                      Caml_option.valFromOption(displayError).textContent = "";
                      return ;
                    } else {
                      return ;
                    }
                  } else if (displayError !== undefined) {
                    Caml_option.valFromOption(displayError).textContent = match.message;
                    return ;
                  } else {
                    return ;
                  }
                }));
          var form = $$Window.$$Document.getElementById("payment-form");
          var eventHandler = function ($$event) {
            $$event.preventDefault();
            Curry._1(onSubmitStart, undefined);
            $$Promise.$$catch(stripe.createToken(card), (function (param) {
                      Curry._1(onSubmitError, undefined);
                      return Promise.reject(Js_exn.raiseError("Token creation unsuccessful"));
                    })).then(function (result) {
                  var error = result.error;
                  if (error !== undefined) {
                    Curry._1(onSubmitError, undefined);
                    var displayError = $$Window.$$Document.getElementById("card-errors");
                    Belt_Option.forEach(displayError, (function (element) {
                            element.textContent = error.message;
                            
                          }));
                    return Promise.resolve(undefined);
                  }
                  var token = result.token;
                  var hiddenInput = document.createElement("input");
                  hiddenInput.setAttribute("type", "hidden");
                  hiddenInput.setAttribute("name", "stripeToken");
                  hiddenInput.setAttribute("value", token.id);
                  Belt_Option.forEach(form, (function (element) {
                          element.appendChild(hiddenInput);
                          
                        }));
                  return Curry._2(onSubmitSuccess, token, promoCode.current);
                });
            
          };
          if (form !== undefined) {
            Caml_option.valFromOption(form).addEventListener("submit", eventHandler);
          }
          return (function (param) {
                    return Belt_Option.forEach(form, (function (element) {
                                  element.removeEventListener("submit", eventHandler);
                                  
                                }));
                  });
        }), []);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginBottom(Css.px(50)),
                    tl: /* [] */0
                  })
            }, React.createElement("form", {
                  id: "payment-form",
                  method: "post"
                }, React.createElement("div", {
                      className: "form-row"
                    }, React.createElement("label", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("block"),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.color(Styles.Color.grey90),
                                      tl: {
                                        hd: Css.paddingTop(Css.px(12)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }),
                          htmlFor: "card-element"
                        }, "Credit or debit card"), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: {
                                  hd: Css.marginTop(Css.px(8)),
                                  tl: {
                                    hd: Css.marginBottom(Css.px(8)),
                                    tl: {
                                      hd: Css.borderRadius(Styles.Border.radius),
                                      tl: {
                                        hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                        tl: {
                                          hd: Css.paddingTop(Css.px(12)),
                                          tl: {
                                            hd: Css.paddingRight(Css.px(16)),
                                            tl: {
                                              hd: Css.paddingBottom(Css.px(12)),
                                              tl: {
                                                hd: Css.paddingLeft(Css.px(16)),
                                                tl: {
                                                  hd: Css.borderRadius(Styles.Border.radius),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          id: "card-element"
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.color(Styles.Color.grapeErrorSecondary),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.margin4(Css.px(8), Css.px(15), Css.px(4), Css.px(15)),
                                      tl: {
                                        hd: Css.textAlign("center"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }),
                          id: "card-errors",
                          role: "alert"
                        })), React.createElement(StripeCouponInput.make, {
                      schemaId: schemaId,
                      onValidPromoCode: (function (promoCodeId) {
                          promoCode.current = promoCodeId;
                          
                        })
                    }), React.createElement(Button.make, {
                      label: label,
                      loading: isLoading
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.marginTop(Css.px(8)),
                              tl: {
                                hd: Css.textAlign("center"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(Icons.Lock.make, {}), React.createElement(Spacer.make, {
                      width: 4
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.grey70,
                      children: "Payments are processed securely via"
                    }), React.createElement("a", {
                      className: Curry._1(Css.style, {
                            hd: Css.paddingLeft(Css.px(4)),
                            tl: {
                              hd: Css.color(Styles.Color.blue),
                              tl: {
                                hd: Css.display("inlineFlex"),
                                tl: /* [] */0
                              }
                            }
                          }),
                      href: "https://stripe.com/",
                      rel: "noopener",
                      target: "_blank"
                    }, React.createElement(Icons.Stripe.make, {}))));
}

var PaymentInput = {
  make: StripeBilling$PaymentInput
};

exports.createTeamV2Subscription = createTeamV2Subscription;
exports.updatePayingMethod = updatePayingMethod;
exports.cancelSubscription = cancelSubscription;
exports.PaymentInput = PaymentInput;
/* Css Not a pure module */
