// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var IconAvo = require("./IconAvo.bs.js");

function LoadingFullscreen(Props) {
  var message = Props.message;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.minHeight(Css.vh(100)),
                            tl: {
                              hd: Css.color(Styles.Color.grey50),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.medium),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(IconAvo.make, {
                  gray: true
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(25)),
                        tl: /* [] */0
                      })
                }, message));
}

var make = LoadingFullscreen;

exports.make = make;
/* Css Not a pure module */
