// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Semver = require("semver");
var Caml_option = require("rescript/lib/js/caml_option.js");

function valid(str) {
  return Caml_option.nullable_to_opt(Semver.valid(str));
}

function t_valid(t) {
  return Caml_option.nullable_to_opt(Semver.valid(t));
}

function coerceAndValidate(str) {
  var t = Semver.coerce(str, {});
  return Caml_option.nullable_to_opt(Semver.valid(t));
}

exports.valid = valid;
exports.t_valid = t_valid;
exports.coerceAndValidate = coerceAndValidate;
/* semver Not a pure module */
