// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Case = require("../../bs-case/src/case.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Input = require("./Input.bs.js");
var React = require("react");
var Select = require("./Select.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Switch = require("./Switch.bs.js");
var $$Window = require("./externals/window.bs.js");
var AvoModel = require("./avoModel.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var DidMount = require("./DidMount.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var OpsWithState = require("./OpsWithState.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var AppFeatureFlag = require("./AppFeatureFlag.bs.js");
var AvoConfigTypes = require("../../shared/models/AvoConfigTypes.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var SourceSettingsEvents = require("./SourceSettingsEvents.bs.js");
var DestinationSegmentConfig = require("./DestinationSegmentConfig.bs.js");
var SourceSettingsDestinations = require("./SourceSettingsDestinations.bs.js");

function modelSourceConfigToAnalyticsSourceConfig(sourceConfig) {
  return Belt_List.toArray(Belt_List.map(sourceConfig, (function (modelSourceConfig) {
                    if (modelSourceConfig === "IosSwiftUseAmplitudeOver5") {
                      return "IosSwiftUseAmplitude5";
                    } else {
                      return "ValidateAvoEventFunction";
                    }
                  })));
}

var root = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.padding4(Css.px(40), Css.px(20), Css.px(20), Css.px(48)),
          tl: /* [] */0
        }
      }
    });

var label = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(12)),
      tl: /* [] */0
    });

var row = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding2(Css.px(15), Css.px(20)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
              tl: /* [] */0
            }
          }
        }
      }
    });

var label2 = Curry._1(Css.style, {
      hd: Css.flex({
            NAME: "num",
            VAL: 1.0
          }),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.fontSize(Styles.FontSize.regular),
          tl: /* [] */0
        }
      }
    });

var value = Curry._1(Css.style, {
      hd: Css.flex({
            NAME: "num",
            VAL: 2.0
          }),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent("flexEnd"),
          tl: /* [] */0
        }
      }
    });

var Style = {
  root: root,
  label: label,
  row: row,
  label2: label2,
  value: value
};

function SourceConfigOverview(Props) {
  var source = Props.source;
  var schema = Props.schema;
  var model = Props.model;
  var sendActions = Props.sendActions;
  var schemaGroup = Props.schemaGroup;
  var globalSend = GlobalSendContext.use(undefined);
  var hasJsonSchemaFeatureFlag = AppFeatureFlag.useFeatureFlag("JsonSchema");
  var hasGtmFeatureFlag = AppFeatureFlag.useFeatureFlag("Gtm");
  var hasSegmentDestinationIntegrationsFeatureFlag = AppFeatureFlag.useFeatureFlag("SegmentDestinationIntegrations");
  var sourceId = source.id;
  var analyticsLanguage = AnalyticsUtils.analyticsLanguage(source.language);
  var libraryName = source.filename;
  var name = Belt_Option.getWithDefault(source.name, "");
  var context = {
    sourceId: sourceId,
    sourceQuery: sourceId
  };
  var schemaId = schema.id;
  var onUpdateName = function (name) {
    if (name.trim() === "") {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "AlertModal",
                    VAL: [
                      "Name cannot be empty",
                      "Please choose a unique name for your source",
                      "OK",
                      (function (param) {
                          
                        })
                    ]
                  }
                });
    }
    if (Caml_obj.caml_equal(source.name, name)) {
      return ;
    }
    var isUnique = Belt_List.everyU(Belt_List.keepU(model.sources, (function (param) {
                return param.id !== sourceId;
              })), (function (source) {
            return Caml_obj.caml_notequal(Belt_Option.map(source.name, Case.camel), Case.camel(name));
          }));
    if (isUnique) {
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  [[
                      {
                        NAME: "UpdateSourceName",
                        VAL: [
                          sourceId,
                          name.trim()
                        ]
                      },
                      context
                    ]]
                ]);
    } else {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "AlertModal",
                    VAL: [
                      "\"" + name + "\" already exists",
                      "Please choose a unique name for your source",
                      "OK",
                      (function (param) {
                          
                        })
                    ]
                  }
                });
    }
  };
  return React.createElement("div", {
              className: root
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginBottom(Css.px(16)),
                        tl: /* [] */0
                      })
                }, React.createElement("div", {
                      className: label
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.grey70,
                          children: "Name"
                        })), React.createElement(OpsWithState.make, {
                      value: name,
                      onFlush: onUpdateName,
                      onChangeCompleted: (function (value, valueBefore) {
                          AnalyticsRe.dataSourceUpdated(schemaGroup, value, valueBefore, source.platform, undefined, analyticsLanguage, undefined, libraryName, undefined, source.path, undefined, "SourceName", undefined, modelSourceConfigToAnalyticsSourceConfig(source.config), undefined, undefined, undefined, undefined, undefined, schemaGroup.branchId, schemaGroup.schemaId);
                          
                        }),
                      children: (function (value, onChange, onBlur, onFocus) {
                          return React.createElement(Input.make, {
                                      value: value,
                                      onChange: onChange,
                                      placeholder: "iOS, Android, Web, Server...",
                                      onBlur: onBlur,
                                      onFocus: onFocus,
                                      className: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.medium),
                                          tl: /* [] */0
                                        }
                                      }
                                    });
                        })
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("grid"),
                        tl: {
                          hd: Css.gridTemplateColumns({
                                hd: {
                                  NAME: "fr",
                                  VAL: 1
                                },
                                tl: {
                                  hd: {
                                    NAME: "fr",
                                    VAL: 1
                                  },
                                  tl: /* [] */0
                                }
                              }),
                          tl: {
                            hd: Css.gridGap(Css.px(8)),
                            tl: {
                              hd: Css.marginBottom(Css.px(24)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: label
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.grey70,
                              children: "Development Platform"
                            })), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement(Select.make, {
                          additionalStyles: {
                            hd: Css.width(Css.pct(100)),
                            tl: /* [] */0
                          },
                          onSelect: (function (value) {
                              var toValue = AvoConfigTypes.devPlatformFromJs(value);
                              if (toValue !== undefined) {
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            (function (branch) {
                                                return AnalyticsRe.dataSourceUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), name, undefined, toValue, source.platform, analyticsLanguage, undefined, libraryName, undefined, source.path, undefined, "DevelopmentPlatform", undefined, modelSourceConfigToAnalyticsSourceConfig(source.config), undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaGroup.schemaId);
                                              }),
                                            undefined,
                                            [[
                                                {
                                                  NAME: "UpdateSourcePlatformV3",
                                                  VAL: [
                                                    sourceId,
                                                    toValue
                                                  ]
                                                },
                                                context
                                              ]]
                                          ]);
                              }
                              
                            }),
                          options: Belt_List.concatMany([
                                Belt_Option.isNone(source.platform) ? ({
                                      hd: [
                                        {
                                          NAME: "Label",
                                          VAL: "Select Development Platform"
                                        },
                                        ""
                                      ],
                                      tl: /* [] */0
                                    }) : /* [] */0,
                                Belt_List.map(Belt_List.concatMany([
                                          AvoConfig.devPlatforms,
                                          hasJsonSchemaFeatureFlag ? ({
                                                hd: "JsonSchema",
                                                tl: /* [] */0
                                              }) : /* [] */0,
                                          hasGtmFeatureFlag ? ({
                                                hd: "Gtm",
                                                tl: /* [] */0
                                              }) : /* [] */0
                                        ]), (function (platform) {
                                        return [
                                                {
                                                  NAME: "Label",
                                                  VAL: AvoConfig.devPlatformLabel(platform)
                                                },
                                                AvoConfigTypes.devPlatformToJs(platform)
                                              ];
                                      }))
                              ]),
                          value: Belt_Option.mapWithDefault(source.platform, "", AvoConfigTypes.devPlatformToJs)
                        }))), TrackingPlanModel.Source.hasConfig(source.config, "ValidateAvoEventFunction") && (Caml_obj.caml_equal(source.language, "js_v2") || Caml_obj.caml_equal(source.language, "ts")) ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginBottom(Css.px(12)),
                          tl: {
                            hd: Css.padding(Css.px(12)),
                            tl: {
                              hd: Css.borderRadius(Css.px(12)),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.grey20),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.marginBottom(Css.px(12)),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement("div", {
                            className: label2
                          }, "Event validation function"), React.createElement("div", {
                            className: value
                          }, React.createElement(Switch.make, {
                                value: TrackingPlanModel.Source.hasConfig(source.config, "ValidateAvoEventFunction"),
                                onChange: (function (isActive) {
                                    if (isActive) {
                                      Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            (function (branch) {
                                                return AnalyticsRe.dataSourceUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), name, undefined, source.platform, undefined, analyticsLanguage, undefined, libraryName, undefined, source.path, undefined, "SourceConfig", modelSourceConfigToAnalyticsSourceConfig(source.config), modelSourceConfigToAnalyticsSourceConfig({
                                                                hd: "ValidateAvoEventFunction",
                                                                tl: source.config
                                                              }), undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaGroup.schemaId);
                                              }),
                                            undefined,
                                            [[
                                                {
                                                  NAME: "AddSourceConfig",
                                                  VAL: [
                                                    sourceId,
                                                    "ValidateAvoEventFunction"
                                                  ]
                                                },
                                                context
                                              ]]
                                          ]);
                                    } else {
                                      Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            (function (branch) {
                                                return AnalyticsRe.dataSourceUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), name, undefined, source.platform, undefined, analyticsLanguage, undefined, libraryName, undefined, source.path, undefined, "SourceConfig", modelSourceConfigToAnalyticsSourceConfig(source.config), modelSourceConfigToAnalyticsSourceConfig(Belt_List.keep(source.config, (function (config) {
                                                                      return config !== "ValidateAvoEventFunction";
                                                                    }))), undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaGroup.schemaId);
                                              }),
                                            undefined,
                                            [[
                                                {
                                                  NAME: "RemoveSourceConfig",
                                                  VAL: [
                                                    sourceId,
                                                    "ValidateAvoEventFunction"
                                                  ]
                                                },
                                                context
                                              ]]
                                          ]);
                                    }
                                    
                                  })
                              }))), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontSize(Css.px(12)),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.regular),
                                tl: {
                                  hd: Css.marginTop(Css.px(-5)),
                                  tl: {
                                    hd: Css.lineHeight(Css.pct(150.0)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, "The Avo.js file will include a function called validateAvoEvent which can be used to validate existing tracking calls. ", React.createElement("a", {
                            href: "/docs/data-validation",
                            target: "blank"
                          }, "See docs"), "  for more info.")) : (
                Caml_obj.caml_equal(source.language, "swift") && Caml_obj.caml_equal(source.platform, "IOS") && Belt_List.some(AvoModel.resolveDestinations(model, Belt_List.mapU(source.destinations, (function (param) {
                                return param.destinationId;
                              }))), (function (destination) {
                        return Caml_obj.caml_equal(destination.type_, "Amplitude");
                      })) ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
                              tl: /* [] */0
                            })
                      }, React.createElement("div", {
                            className: Curry._1(Css.merge, {
                                  hd: row,
                                  tl: {
                                    hd: Curry._1(Css.style, {
                                          hd: Css.borderBottomWidth(Css.px(0)),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement("div", {
                                className: label2
                              }, "Use Amplitude 5+"), React.createElement("div", {
                                className: value
                              }, React.createElement(Switch.make, {
                                    value: TrackingPlanModel.Source.hasConfig(source.config, "IosSwiftUseAmplitudeOver5"),
                                    onChange: (function (isActive) {
                                        if (isActive) {
                                          Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (branch) {
                                                    return AnalyticsRe.dataSourceUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), name, undefined, source.platform, undefined, analyticsLanguage, undefined, libraryName, undefined, source.path, undefined, "SourceConfig", modelSourceConfigToAnalyticsSourceConfig(source.config), modelSourceConfigToAnalyticsSourceConfig({
                                                                    hd: "IosSwiftUseAmplitudeOver5",
                                                                    tl: source.config
                                                                  }), undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaGroup.schemaId);
                                                  }),
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "AddSourceConfig",
                                                      VAL: [
                                                        sourceId,
                                                        "IosSwiftUseAmplitudeOver5"
                                                      ]
                                                    },
                                                    context
                                                  ]]
                                              ]);
                                        } else {
                                          Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (branch) {
                                                    return AnalyticsRe.dataSourceUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), name, undefined, source.platform, undefined, analyticsLanguage, undefined, libraryName, undefined, source.path, undefined, "SourceConfig", modelSourceConfigToAnalyticsSourceConfig(source.config), modelSourceConfigToAnalyticsSourceConfig(Belt_List.keep(source.config, (function (config) {
                                                                          return config !== "IosSwiftUseAmplitudeOver5";
                                                                        }))), undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaGroup.schemaId);
                                                  }),
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "RemoveSourceConfig",
                                                      VAL: [
                                                        sourceId,
                                                        "IosSwiftUseAmplitudeOver5"
                                                      ]
                                                    },
                                                    context
                                                  ]]
                                              ]);
                                        }
                                        
                                      })
                                  }))), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.fontSize(Css.px(12)),
                                  tl: {
                                    hd: Css.fontWeight(Styles.FontWeight.regular),
                                    tl: {
                                      hd: Css.padding4(Css.px(0), Css.px(20), Css.px(10), Css.px(20)),
                                      tl: {
                                        hd: Css.marginTop(Css.px(-5)),
                                        tl: {
                                          hd: Css.lineHeight(Css.pct(150.0)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                })
                          }, "The Avo.swift file will use module provided by the `Amplitude` pod instead of the `Amplitude-iOS` pod. ")) : null
              ), React.createElement(SourceSettingsDestinations.make, {
                  model: model,
                  source: source,
                  schemaGroup: schemaGroup
                }), React.createElement(SourceSettingsEvents.make, {
                  schemaGroup: schemaGroup,
                  model: model,
                  schemaId: schemaId,
                  source: source
                }), hasSegmentDestinationIntegrationsFeatureFlag && Belt_List.someU(AvoModel.resolveDestinations(model, Belt_List.mapU(source.destinations, (function (param) {
                            return param.destinationId;
                          }))), (function (destination) {
                    return Caml_obj.caml_equal(destination.type_, "Segment");
                  })) ? React.createElement(DestinationSegmentConfig.make, {
                    source: source,
                    context: context
                  }) : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(25)),
                        tl: /* [] */0
                      }),
                  id: "setup"
                }, React.createElement(DidMount.make, {
                      didMount: (function (param) {
                          if (RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).hash === "setup") {
                            return Belt_Option.forEach($$Window.$$Document.getElementById("setup"), (function (prim) {
                                          prim.scrollIntoView();
                                          
                                        }));
                          }
                          
                        })
                    })));
}

var make = SourceConfigOverview;

exports.modelSourceConfigToAnalyticsSourceConfig = modelSourceConfigToAnalyticsSourceConfig;
exports.Style = Style;
exports.make = make;
/* root Not a pure module */
