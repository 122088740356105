// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var PortalMenu = require("./PortalMenu.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function PortalTooltip(Props) {
  var renderTooltip = Props.renderTooltip;
  var portalPositionOpt = Props.position;
  var children = Props.children;
  var portalPosition = portalPositionOpt !== undefined ? portalPositionOpt : "Bottom";
  var spanRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setIsHovering = match[1];
  var match$1 = React.useState(function () {
        return "Bottom";
      });
  var setActualPosition = match$1[1];
  var actualPosition = match$1[0];
  var timeoutId = React.useRef(undefined);
  React.useEffect((function () {
          return (function (param) {
                    return Belt_Option.forEach(timeoutId.current, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), []);
  return React.createElement(React.Fragment, undefined, React.cloneElement(children, {
                  ref: spanRef,
                  onMouseEnter: (function (param) {
                      timeoutId.current = Caml_option.some(setTimeout((function (param) {
                                  return Curry._1(setIsHovering, (function (param) {
                                                return true;
                                              }));
                                }), 350));
                      
                    }),
                  onMouseLeave: (function (param) {
                      Belt_Option.forEach(timeoutId.current, (function (prim) {
                              clearTimeout(prim);
                              
                            }));
                      return Curry._1(setIsHovering, (function (param) {
                                    return false;
                                  }));
                    })
                }), React.createElement(PortalMenu.make, {
                  position: portalPosition,
                  distance: 6,
                  clickOutsideToDismiss: false,
                  visible: match[0],
                  onClose: (function (param) {
                      return Curry._1(setIsHovering, (function (param) {
                                    return false;
                                  }));
                    }),
                  getCorrectPosition: (function (actualPosition) {
                      return Curry._1(setActualPosition, (function (param) {
                                    return actualPosition;
                                  }));
                    }),
                  referenceItemRef: spanRef,
                  children: React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.borderRadius(Css.px(4)),
                              tl: {
                                hd: Css.padding2(Css.px(4), Css.px(8)),
                                tl: {
                                  hd: Css.paddingTop(Css.px(0)),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.grey100),
                                    tl: {
                                      hd: Css.maxWidth(Css.px(400)),
                                      tl: {
                                        hd: Css.after({
                                              hd: Css.display("block"),
                                              tl: {
                                                hd: Css.position("absolute"),
                                                tl: {
                                                  hd: Css.height(Css.px(0)),
                                                  tl: {
                                                    hd: Css.width(Css.px(0)),
                                                    tl: {
                                                      hd: Css.unsafe("content", "' '"),
                                                      tl: {
                                                        hd: Css.border(Css.px(6), "solid", "transparent"),
                                                        tl: {
                                                          hd: Css.left(actualPosition === "Right" ? Css.pct(0) : (
                                                                  actualPosition === "Left" ? ({
                                                                        NAME: "substract",
                                                                        VAL: [
                                                                          Css.pct(100),
                                                                          Css.px(12)
                                                                        ]
                                                                      }) : Css.pct(50)
                                                                )),
                                                          tl: {
                                                            hd: Css.top(actualPosition === "Top" ? ({
                                                                      NAME: "substract",
                                                                      VAL: [
                                                                        Css.pct(100),
                                                                        Css.px(12)
                                                                      ]
                                                                    }) : (
                                                                    actualPosition === "Bottom" ? Css.pct(0) : Css.pct(50)
                                                                  )),
                                                            tl: {
                                                              hd: Css.marginTop(Css.px(actualPosition === "Top" ? 12 : (
                                                                          actualPosition === "Bottom" ? -12 : -6
                                                                        ))),
                                                              tl: {
                                                                hd: Css.marginLeft(Css.px(actualPosition === "Right" ? -12 : (
                                                                            actualPosition === "Left" ? 12 : -6
                                                                          ))),
                                                                tl: {
                                                                  hd: Css.borderBottomColor(actualPosition === "Bottom" ? Styles.Color.grey100 : "transparent"),
                                                                  tl: {
                                                                    hd: Css.borderTopColor(actualPosition === "Top" ? Styles.Color.grey100 : "transparent"),
                                                                    tl: {
                                                                      hd: Css.borderLeftColor(actualPosition === "Left" ? Styles.Color.grey100 : "transparent"),
                                                                      tl: {
                                                                        hd: Css.borderRightColor(actualPosition === "Right" ? Styles.Color.grey100 : "transparent"),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }),
                                        tl: {
                                          hd: Css.boxShadow(Styles.Shadow.subtle),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      }, Curry._1(renderTooltip, undefined))
                }));
}

var make = PortalTooltip;

exports.make = make;
/* Css Not a pure module */
