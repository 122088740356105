// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Models = require("./Models.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var Compare_asc = require("date-fns/compare_asc");

function FirebaseFetchers$MergeBranchIds(Props) {
  var children = Props.children;
  var fetchToDate = Props.fetchToDate;
  var schemaId = Props.schemaId;
  return Curry._1(children, FirebaseFetcherHooks.useMergedBranchIds(fetchToDate, schemaId));
}

var MergeBranchIds = {
  make: FirebaseFetchers$MergeBranchIds
};

function FirebaseFetchers$BranchOpenAction(Props) {
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var includeActions = Props.includeActions;
  var children = Props.children;
  return Curry._1(children, FirebaseFetcherHooks.useBranchOpenAction(schemaId, branchId, includeActions, undefined, undefined));
}

var BranchOpenAction = {
  make: FirebaseFetchers$BranchOpenAction
};

var PagedStreamHook = FirebaseFetcherHooks.MakePagedStreamHook(Models.Action);

function useStreamResult(goToId, initialPageSize, pageSize, queryBy, queryByOpOpt, fetchToDate, fetchFromDate, fetchToAndEqualDate, branchId, disabled, audit, collection) {
  var queryByOp = queryByOpOpt !== undefined ? queryByOpOpt : "==";
  return Curry._8(PagedStreamHook.usePagedStream, pageSize, initialPageSize, goToId, (function (query) {
                var query$1 = query.orderBy("createdAt", "desc");
                var query$2 = query$1.orderBy("order", "desc");
                var query$3 = fetchToDate !== undefined ? query$2.where("createdAt", "<", Caml_option.valFromOption(fetchToDate)) : query$2;
                var query$4 = fetchToAndEqualDate !== undefined ? query$3.where("createdAt", "<=", Caml_option.valFromOption(fetchToAndEqualDate)) : query$3;
                var query$5 = fetchFromDate !== undefined ? query$4.where("createdAt", ">", Caml_option.valFromOption(fetchFromDate)) : query$4;
                var query$6 = queryBy !== undefined && Belt_List.length(queryBy) > 0 ? Belt_List.reduce(queryBy, query$5, (function (query, param) {
                          return query.where(param[0], queryByOp, param[1]);
                        })) : query$5;
                var query$7 = branchId === "master" ? query$6 : query$6.where("branchId", "==", branchId);
                if (audit !== undefined) {
                  return query$7.where("audit", "==", audit);
                } else {
                  return query$7;
                }
              }), FirebaseFetcherHooks.transformAction, undefined, disabled, collection);
}

function FirebaseFetchers$PagedActionsStream(Props) {
  var schemaId = Props.schemaId;
  var queryBy = Props.queryBy;
  var goToId = Props.goToId;
  var pageSizeOpt = Props.pageSize;
  var initialPageSizeOpt = Props.initialPageSize;
  var fetchFromDate = Props.fetchFromDate;
  var fetchToDate = Props.fetchToDate;
  var fetchToAndEqualDate = Props.fetchToAndEqualDate;
  var branchIdOpt = Props.branchId;
  var audit = Props.audit;
  var children = Props.children;
  var pageSize = pageSizeOpt !== undefined ? pageSizeOpt : 100;
  var initialPageSize = initialPageSizeOpt !== undefined ? initialPageSizeOpt : 100;
  var branchId = branchIdOpt !== undefined ? branchIdOpt : "master";
  var collection = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions");
  var streamResult = useStreamResult(goToId, initialPageSize, pageSize, queryBy, undefined, fetchToDate, fetchFromDate, fetchToAndEqualDate, branchId, false, audit, collection);
  var importQueryBy = Belt_Option.map(queryBy, (function (queryBy) {
          return Belt_List.keepMap(queryBy, (function (param) {
                        var id = param[1];
                        var maybeNewKey;
                        switch (param[0]) {
                          case "context.eventQuery" :
                              maybeNewKey = "context.eventsQuery";
                              break;
                          case "context.propertyGroupQuery" :
                              maybeNewKey = "context.propertyGroupsQuery";
                              break;
                          case "context.propertyQuery" :
                              maybeNewKey = "context.propertiesQuery";
                              break;
                          case "context.sourceQuery" :
                              maybeNewKey = "context.sourcesQuery";
                              break;
                          default:
                            maybeNewKey = undefined;
                        }
                        return Belt_Option.map(maybeNewKey, (function (newKey) {
                                      return [
                                              newKey,
                                              id
                                            ];
                                    }));
                      }));
        }));
  var importQueryBy$1 = importQueryBy !== undefined && importQueryBy ? importQueryBy : undefined;
  var importResult = useStreamResult(goToId, initialPageSize, pageSize, importQueryBy$1, "array-contains", fetchToDate, fetchFromDate, fetchToAndEqualDate, branchId, importQueryBy$1 === undefined, audit, collection);
  return Curry._4(children, Belt_List.sort(Belt_List.concat(importResult.items, streamResult.items), (function (a, b) {
                    return Compare_asc(Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(a.createdAt), new Date(), (function (prim) {
                                      return prim.toDate();
                                    })), Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(b.createdAt), new Date(), (function (prim) {
                                      return prim.toDate();
                                    })));
                  })), streamResult.status, streamResult.lastPageStartPosition, streamResult.getMore);
}

var PagedActionsStream = {
  PagedStreamHook: PagedStreamHook,
  useStreamResult: useStreamResult,
  make: FirebaseFetchers$PagedActionsStream
};

function fetchDeletedCommentActionsOnBranch(schemaId, branchId) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions").where("branchId", "==", branchId).where("contentsJson.DeleteComment", ">", {}).get();
}

function fetchObjectCommentActionsOnBranch(schemaId, branchId, itemType) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions").where("branchId", "==", branchId).where(itemType === "Integration" ? "contentsJson.IntegrationComment" : (
                  itemType === "Property" ? "contentsJson.PropertyComment" : (
                      itemType === "Event" ? "contentsJson.EventComment" : (
                          itemType === "PropertyGroup" ? "contentsJson.PropertyGroupComment" : (
                              itemType === "Goal" ? "contentsJson.GoalComment" : (
                                  itemType === "Metric" ? "contentsJson.MetricComment" : "contentsJson.BranchComment"
                                )
                            )
                        )
                    )
                ), ">", {}).get();
}

function fetchAllCommentActionsOnBranch(schemaId, branchId) {
  var allCommentActions = [
    "Event",
    "PropertyGroup",
    "Branch",
    "Metric",
    "Goal",
    "Property",
    "Integration"
  ];
  return Promise.all(Belt_Array.mapU(allCommentActions, (function (commentAction) {
                    return fetchObjectCommentActionsOnBranch(schemaId, branchId, commentAction);
                  })));
}

exports.MergeBranchIds = MergeBranchIds;
exports.BranchOpenAction = BranchOpenAction;
exports.PagedActionsStream = PagedActionsStream;
exports.fetchDeletedCommentActionsOnBranch = fetchDeletedCommentActionsOnBranch;
exports.fetchObjectCommentActionsOnBranch = fetchObjectCommentActionsOnBranch;
exports.fetchAllCommentActionsOnBranch = fetchAllCommentActionsOnBranch;
/* PagedStreamHook Not a pure module */
