// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Case = require("../../bs-case/src/case.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Shortid = require("shortid");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameUtils = require("../../shared/utils/NameUtils.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var Fuzzaldrin = require("fuzzaldrin");
var NewEventModal = require("./NewEventModal.bs.js");
var AutofillInputNew = require("./AutofillInputNew.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var DiscrepancyContext = require("./DiscrepancyContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var AutoFillCasingDetail = require("./AutoFillCasingDetail.bs.js");
var AutoFillInputCreateItem = require("./AutoFillInputCreateItem.bs.js");

function getId(item) {
  return item.id;
}

function messageToString(msg) {
  return msg;
}

var EventAutofillInput = AutofillInputNew.Make({
      getId: getId,
      messageToString: messageToString
    });

function EventAutofill(Props) {
  var events = Props.events;
  var selectedEventsOpt = Props.selectedEvents;
  var onSelectSuccess = Props.onSelectSuccess;
  var model = Props.model;
  var renderButton = Props.renderButton;
  var sendActions = Props.sendActions;
  var currentFilters = Props.currentFilters;
  var eventOrigin = Props.eventOrigin;
  var selectActions = Props.selectActions;
  var selectedEvents = selectedEventsOpt !== undefined ? selectedEventsOpt : /* [] */0;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  return React.createElement(EventAutofillInput.make, {
              onSelect: (function (item, param, param$1) {
                  return Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              (function (branch) {
                                  return Curry._3(onSelectSuccess, item.id, item.name, branch);
                                }),
                              undefined,
                              Curry._1(selectActions, item.id)
                            ]);
                }),
              onCreate: (function (name, _var) {
                  var name$1 = NameUtils.getCorrectCase(expectedEventCase, name);
                  var eventId = Shortid();
                  return NewEventModal.createEvent(eventId, name$1, expectedEventCase, true, workspace, model, sendActions, addToast, currentFilters, (function (branch) {
                                return Curry._3(onSelectSuccess, eventId, name$1, branch);
                              }), selectActions, schemaGroup, eventOrigin, undefined);
                }),
              placeholder: "Add event...",
              search: (function (query) {
                  var availableEvents = Belt_List.keep(events, (function ($$event) {
                          return !Belt_List.some(selectedEvents, (function (selectedEventId) {
                                        return selectedEventId === $$event.id;
                                      }));
                        }));
                  var results = Belt_List.map(Belt_List.fromArray(Fuzzaldrin.filter(Belt_Array.map(Belt_List.toArray(availableEvents), (function ($$event) {
                                      return {
                                              id: $$event.id,
                                              name: $$event.name
                                            };
                                    })), NameUtils.getCorrectCase(expectedEventCase, query), {
                                key: "name",
                                maxResults: 100
                              })), (function ($$event) {
                          return {
                                  NAME: "Item",
                                  VAL: $$event
                                };
                        }));
                  var nameToCreate = Case.to_(expectedEventCase, query);
                  var canCreate = !Belt_List.some(events, (function ($$event) {
                          return Case.to_("Camel", $$event.name) === Case.to_("Camel", query);
                        })) && Case.to_("Camel", query) !== "" && NewEventModal.isValid(nameToCreate, expectedEventCase, events);
                  return {
                          hd: [
                            undefined,
                            Belt_List.concatMany([
                                  results,
                                  canCreate ? ({
                                        hd: {
                                          NAME: "CreateNew",
                                          VAL: query
                                        },
                                        tl: /* [] */0
                                      }) : /* [] */0
                                ])
                          ],
                          tl: /* [] */0
                        };
                }),
              renderItem: (function (item, isActive) {
                  var variant = item.NAME;
                  if (variant === "CreateNew") {
                    return React.createElement(AutoFillInputCreateItem.make, {
                                name: item.VAL,
                                correctCasing: expectedEventCase,
                                isActive: isActive
                              });
                  } else if (variant === "CreateNewWithGrouping") {
                    return null;
                  } else if (variant === "Item") {
                    return React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.padding(Css.px(15)),
                                      tl: {
                                        hd: Css.backgroundColor(isActive ? Styles.Color.blueBg : Styles.Color.white),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.backgroundColor(Styles.Color.blueBg),
                                                tl: /* [] */0
                                              }),
                                          tl: {
                                            hd: Css.color(Styles.Color.grey80),
                                            tl: {
                                              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey30),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    })
                              }, React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Semi",
                                    children: item.VAL.name
                                  }));
                  } else {
                    return React.createElement("div", undefined, item.VAL);
                  }
                }),
              renderDetails: (function (x) {
                  var variant = x.NAME;
                  if (variant === "CreateNew") {
                    var name = x.VAL;
                    if (NameUtils.isCorrectCase(expectedEventCase, name)) {
                      return null;
                    } else {
                      return React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.padding(Css.px(16)),
                                        tl: /* [] */0
                                      })
                                }, React.createElement(AutoFillCasingDetail.make, {
                                      expectedCase: expectedEventCase,
                                      name: name,
                                      kind: /* Event */1
                                    }));
                    }
                  }
                  if (variant !== "Item") {
                    return null;
                  }
                  var $$event = x.VAL;
                  var maybeEvent = Belt_List.getBy(events, (function (item) {
                          return $$event.id === item.id;
                        }));
                  var tmp;
                  if (maybeEvent !== undefined) {
                    var sources = maybeEvent.includeSources;
                    tmp = React.createElement("div", undefined, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.regular),
                                      tl: /* [] */0
                                    }
                                  })
                            }, maybeEvent.name), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontSize(Styles.FontSize.small),
                                    tl: {
                                      hd: Css.padding2(Css.px(10), Css.px(0)),
                                      tl: /* [] */0
                                    }
                                  })
                            }, maybeEvent.description), sources ? React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.small),
                                        tl: {
                                          hd: Css.wordWrap("breakWord"),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, "Sent from " + Belt_List.toArray(Belt_List.map(Belt_List.keepMap(sources, (function (includedSource) {
                                                return StateUtils.getSourceById(includedSource.id, model);
                                              })), AvoConfig.getSourceName)).join(", ")) : React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.color(Styles.Color.grapeError),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.small),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, "Not sent from any source"));
                  } else {
                    tmp = React.createElement("div", undefined, $$event.name);
                  }
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding(Css.px(15)),
                                    tl: {
                                      hd: Css.overflow("auto"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, tmp);
                }),
              refreshKey: [
                events,
                selectedEvents
              ],
              renderButton: renderButton,
              resultsContainerStyles: {
                hd: Css.left(Css.px(0)),
                tl: {
                  hd: Css.width("auto"),
                  tl: {
                    hd: Css.right(Css.px(0)),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var make = EventAutofill;

exports.EventAutofillInput = EventAutofillInput;
exports.make = make;
/* EventAutofillInput Not a pure module */
