// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Semver = require("../bindings/semver.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");

function print(appVersion) {
  return appVersion.VAL;
}

function appVersion(json) {
  var fieldValue = Json_decode.field("appVersion", Json_decode.string, json);
  var match = Semver.valid(fieldValue);
  var match$1 = Semver.coerceAndValidate(fieldValue);
  return {
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          sourceId: Json_decode.field("sourceId", Json_decode.string, json),
          appVersion: match !== undefined ? ({
                NAME: "semver",
                VAL: match
              }) : (
              match$1 !== undefined ? ({
                    NAME: "version",
                    VAL: fieldValue
                  }) : ({
                    NAME: "invalid",
                    VAL: fieldValue
                  })
            ),
          firstSeen: new Date(Json_decode.field("firstSeen", Json_decode.string, json)),
          lastSeen: new Date(Json_decode.field("lastSeen", Json_decode.string, json))
        };
}

function appVersions(json) {
  return Json_decode.array(appVersion, json);
}

function response(json) {
  return Belt_MapString.fromArray(Js_dict.entries(Json_decode.field("appVersions", (function (param) {
                        return Json_decode.dict(appVersions, param);
                      }), json)));
}

var Decode = {
  appVersion: appVersion,
  appVersions: appVersions,
  response: response
};

function getLatestVersion(offsetOpt, appVersions, sourceId) {
  var offset = offsetOpt !== undefined ? offsetOpt : 0;
  return Belt_Option.map(Belt_Option.flatMap(Belt_MapString.get(appVersions, sourceId), (function (versions) {
                    return Belt_Array.get(versions, offset);
                  })), (function (param) {
                return param.appVersion;
              }));
}

function getLatestVersions(lenOpt, appVersions, sourceId) {
  var len = lenOpt !== undefined ? lenOpt : 3;
  return Belt_Option.mapWithDefault(Belt_Option.map(Belt_MapString.get(appVersions, sourceId), (function (param) {
                    return Belt_Array.slice(param, 0, len);
                  })), [], (function (versions) {
                return Belt_Array.map(versions, (function (param) {
                              return param.appVersion;
                            }));
              }));
}

var $$Map;

exports.print = print;
exports.$$Map = $$Map;
exports.Decode = Decode;
exports.getLatestVersion = getLatestVersion;
exports.getLatestVersions = getLatestVersions;
/* Semver Not a pure module */
