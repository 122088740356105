// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");
var TrackingPlanMappedModel = require("../../model/src/TrackingPlanMappedModel.bs.js");

function updateEvent(model, eventId, modifier) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: Belt_List.mapU(model.events, (function ($$event) {
                  if (eventId === $$event.id) {
                    return Curry._1(modifier, $$event);
                  } else {
                    return $$event;
                  }
                })),
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations
        };
}

function updateEvents(events, eventId, modifier) {
  return Belt_List.mapU(events, (function ($$event) {
                if (eventId === $$event.id) {
                  return Curry._1(modifier, $$event);
                } else {
                  return $$event;
                }
              }));
}

function updateProperty(model, propertyId, modifier) {
  return {
          types: model.types,
          properties: Belt_List.mapU(model.properties, (function (property) {
                  if (property.TAG === /* PropertyRef */0) {
                    return property;
                  }
                  var item = property._0;
                  if (item.id === propertyId) {
                    return {
                            TAG: /* Property */1,
                            _0: Curry._1(modifier, item)
                          };
                  } else {
                    return {
                            TAG: /* Property */1,
                            _0: item
                          };
                  }
                })),
          propertyBundles: model.propertyBundles,
          events: model.events,
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations
        };
}

function updatePropertyValidations(model, propertyId, modifier) {
  return updateProperty(model, propertyId, (function (property) {
                return {
                        id: property.id,
                        name: property.name,
                        uniqueName: property.uniqueName,
                        description: property.description,
                        type_: property.type_,
                        sendAs: property.sendAs,
                        validations: Curry._2(modifier, property.validations, property),
                        auto: property.auto,
                        builtIn: property.builtIn,
                        analyticsTools: property.analyticsTools,
                        devPlatforms: property.devPlatforms,
                        list: property.list,
                        operation: property.operation,
                        optionalDeprecated: property.optionalDeprecated,
                        excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                        optionalWhenInObject: property.optionalWhenInObject,
                        absence: property.absence
                      };
              }));
}

function getMatchesFromProperty(property) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_List.get(Belt_List.keepU(property.validations, (function (validation) {
                            if (typeof validation === "object") {
                              return validation.NAME === "Matches";
                            } else {
                              return false;
                            }
                          })), 0), (function (matches) {
                    if (typeof matches === "object" && matches.NAME === "Matches") {
                      return matches.VAL;
                    }
                    
                  })), /* [] */0);
}

function updateEventTrigger(model, eventId, triggerId, modifier) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: Belt_List.mapU(model.events, (function ($$event) {
                  if (eventId === $$event.id) {
                    return {
                            id: $$event.id,
                            name: $$event.name,
                            uniqueName: $$event.uniqueName,
                            description: $$event.description,
                            properties: $$event.properties,
                            propertyBundles: $$event.propertyBundles,
                            variants: $$event.variants,
                            types: $$event.types,
                            tags: $$event.tags,
                            excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                            includeSources: $$event.includeSources,
                            includeDestinations: $$event.includeDestinations,
                            hashes: $$event.hashes,
                            propertyWhitelist: $$event.propertyWhitelist,
                            eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                            userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                            triggers: Belt_Array.mapU($$event.triggers, (function (trigger) {
                                    if (triggerId === trigger.id) {
                                      return Curry._1(modifier, trigger);
                                    } else {
                                      return trigger;
                                    }
                                  }))
                          };
                  } else {
                    return $$event;
                  }
                })),
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations
        };
}

function destinationIdsWithoutArchived(destinationIds, model) {
  return Belt_List.keepU(destinationIds, (function (inputDestinationId) {
                return Belt_List.someU(model.destinations, (function (modelDestination) {
                              return modelDestination.id === inputDestinationId;
                            }));
              }));
}

function metricIdsWithoutArchived(metricIds, model) {
  return Belt_List.keepU(metricIds, (function (inputMetricId) {
                return Belt_List.someU(model.metrics, (function (modelMetric) {
                              return modelMetric.id === inputMetricId;
                            }));
              }));
}

function eventIdsWithoutArchived(eventIds, model) {
  return Belt_List.keepU(eventIds, (function (inputEventId) {
                return Belt_List.someU(model.events, (function (modelEvent) {
                              return modelEvent.id === inputEventId;
                            }));
              }));
}

function getEventById(id, model) {
  return Belt_List.getByU(model.events, (function ($$event) {
                return $$event.id === id;
              }));
}

function getEventByIdWithArchive(id, model) {
  return Belt_List.getByU(Belt_List.concat(model.events, model.archive.events), (function ($$event) {
                return $$event.id === id;
              }));
}

function getEventByIdFromArchive(id, model) {
  return Belt_List.getByU(model.archive.events, (function ($$event) {
                return $$event.id === id;
              }));
}

function getEventByIdFromArchive_mappedModel(model, id) {
  var $$event = Curry._2(TrackingPlanMappedModel.Events.get, model.events, id);
  if ($$event !== undefined) {
    return $$event;
  } else {
    return Curry._2(TrackingPlanMappedModel.Events.get, model.archive.events, id);
  }
}

function getEventByName(name, model) {
  return Belt_List.getByU(model.events, (function ($$event) {
                return $$event.name === name;
              }));
}

function duplicateEventAndEnrichModel(fromEventId, toEventId, triggerIdTuples, ruleIdTuples, model) {
  var eventToDuplicate = getEventByIdWithArchive(fromEventId, model);
  if (eventToDuplicate === undefined) {
    return model;
  }
  var duplicatedEvent = TrackingPlanModel.duplicateEvent(eventToDuplicate, toEventId, triggerIdTuples, model.events);
  var rulesForDuplicatedEvent = Belt_List.fromArray(Belt_Array.keepMap(ruleIdTuples, (function (param) {
              var toRuleId = param[1];
              var fromRuleId = param[0];
              return Belt_Option.flatMap(Belt_List.getBy(model.rules, (function (param) {
                                return param.id === fromRuleId;
                              })), (function (rule) {
                            var item = rule.item;
                            var variant = item.NAME;
                            if (variant === "PropertyRef") {
                              var match = item.VAL;
                              if (match[0] === fromEventId) {
                                return {
                                        id: toRuleId,
                                        item: {
                                          NAME: "PropertyRef",
                                          VAL: [
                                            duplicatedEvent.id,
                                            match[1]
                                          ]
                                        },
                                        definition: rule.definition
                                      };
                              } else {
                                return ;
                              }
                            }
                            if (variant === "Property") {
                              return ;
                            }
                            if (variant === "Event") {
                              if (item.VAL === fromEventId) {
                                return {
                                        id: toRuleId,
                                        item: {
                                          NAME: "Event",
                                          VAL: duplicatedEvent.id
                                        },
                                        definition: rule.definition
                                      };
                              } else {
                                return ;
                              }
                            }
                            var match$1 = item.VAL;
                            if (match$1[0] === fromEventId) {
                              return {
                                      id: toRuleId,
                                      item: {
                                        NAME: "EventObjectField",
                                        VAL: [
                                          duplicatedEvent.id,
                                          match$1[1],
                                          match$1[2]
                                        ]
                                      },
                                      definition: rule.definition
                                    };
                            }
                            
                          }));
            })));
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: Belt_List.concat(model.events, {
                hd: duplicatedEvent,
                tl: /* [] */0
              }),
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: Belt_List.map(model.goals, (function (goal) {
                  if (Belt_List.has(goal.events, fromEventId, (function (prim0, prim1) {
                            return prim0 === prim1;
                          }))) {
                    return {
                            id: goal.id,
                            name: goal.name,
                            description: goal.description,
                            metrics: goal.metrics,
                            events: Belt_List.concat(goal.events, {
                                  hd: duplicatedEvent.id,
                                  tl: /* [] */0
                                })
                          };
                  } else {
                    return goal;
                  }
                })),
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: Belt_List.concat(model.rules, rulesForDuplicatedEvent),
          integrations: model.integrations
        };
}

function getTriggerFromEvent(id, $$event) {
  return Belt_Array.getBy($$event.triggers, (function (trigger) {
                return trigger.id === id;
              }));
}

function getTriggerByIdAndEventId(id, eventId, model) {
  return Belt_Option.flatMap(getEventById(eventId, model), (function (param) {
                return getTriggerFromEvent(id, param);
              }));
}

function getTriggersByEventId(eventId, model) {
  return Belt_Option.getWithDefault(Belt_Option.map(getEventById(eventId, model), (function (it) {
                    return it.triggers;
                  })), []);
}

function getSourceById(id, model) {
  return Belt_List.getByU(model.sources, (function (source) {
                return source.id === id;
              }));
}

function getSourceByIdWithArchive(id, model) {
  return Belt_List.getByU(Belt_List.concat(model.sources, model.archive.sources), (function (source) {
                return source.id === id;
              }));
}

function getDestinationById(id, model) {
  return Belt_List.getByU(model.destinations, (function (destination) {
                return destination.id === id;
              }));
}

var defaultDestinationName = "Untitled Destination";

function getDestinationName(destination) {
  return Belt_Option.mapWithDefault(destination.name, defaultDestinationName, (function (name) {
                if (name === "") {
                  return defaultDestinationName;
                } else {
                  return name;
                }
              }));
}

function getDestinationNameById(model, destinationId) {
  var destination = getDestinationById(destinationId, model);
  if (destination !== undefined) {
    return getDestinationName(destination);
  } else {
    return defaultDestinationName;
  }
}

function getDestinationByIdWithArchive(id, model) {
  return Belt_List.getByU(Belt_List.concat(model.destinations, model.archive.destinations), (function (destination) {
                return destination.id === id;
              }));
}

function getGoalById(id, model) {
  return Belt_List.getByU(model.goals, (function (goal) {
                return goal.id === id;
              }));
}

function getGoalByIdWithArchive(id, model) {
  return Belt_List.getByU(Belt_List.concat(model.goals, model.archive.goals), (function (goal) {
                return goal.id === id;
              }));
}

function getGoalsWithEvent($$event, model) {
  return Belt_List.toArray(Belt_List.keep(model.goals, (function (goal) {
                    return Belt_List.some(goal.events, (function (eventId) {
                                  return eventId === $$event.id;
                                }));
                  })));
}

function getGoalNamespace(model) {
  return Belt_List.map(model.goals, (function (goal) {
                return goal.name;
              }));
}

function getMetricById(id, model) {
  return Belt_List.getByU(model.metrics, (function (metric) {
                return metric.id === id;
              }));
}

function getMetricByIdWithArchive(id, model) {
  return Belt_List.getByU(Belt_List.concat(model.metrics, model.archive.metrics), (function (metric) {
                return metric.id === id;
              }));
}

function getMetricsWithEvent($$event, model) {
  return Belt_List.toArray(Belt_List.keep(model.metrics, (function (metric) {
                    return Belt_List.some(metric.items, (function (metricItem) {
                                  return metricItem.VAL.eventId === $$event.id;
                                }));
                  })));
}

function getPropertyPinnedValueFromEvent($$event, propertyId) {
  return Belt_List.head(Belt_List.keepMapU($$event.properties, (function (property) {
                    if (property.TAG !== /* PropertyRef */0) {
                      return ;
                    }
                    var propRef = property._0;
                    if (propRef.id === propertyId) {
                      return propRef.pinnedValue;
                    }
                    
                  })));
}

function getPropertyById(id, model) {
  return Belt_List.getByU(model.properties, (function (property) {
                if (property.TAG === /* PropertyRef */0) {
                  return Pervasives.failwith("PropertyRefs not allowed in root properties");
                } else {
                  return property._0.id === id;
                }
              }));
}

function getPropertyByIdFromArchive(id, model) {
  return Belt_List.getByU(model.archive.properties, (function (property) {
                if (property.TAG === /* PropertyRef */0) {
                  return Pervasives.failwith("PropertyRefs not allowed in root properties");
                } else {
                  return property._0.id === id;
                }
              }));
}

function getModelPropertyById(propertyId, model) {
  return Belt_Option.flatMap(Belt_List.getByU(model.properties, (function (property) {
                    if (property.TAG === /* PropertyRef */0) {
                      return false;
                    } else {
                      return property._0.id === propertyId;
                    }
                  })), (function (property) {
                if (property.TAG === /* PropertyRef */0) {
                  return ;
                } else {
                  return property._0;
                }
              }));
}

function getPropertyRefById(eventId, propertyId, model) {
  return Belt_Option.flatMap(Belt_List.getByU(model.events, (function ($$event) {
                    return $$event.id === eventId;
                  })), (function ($$event) {
                return Belt_List.head(Belt_List.keepMapU($$event.properties, (function (property) {
                                  if (property.TAG !== /* PropertyRef */0) {
                                    return ;
                                  }
                                  var propRef = property._0;
                                  if (propRef.id === propertyId) {
                                    return propRef;
                                  }
                                  
                                })));
              }));
}

function getPropertyByName(name, model) {
  return Belt_Option.flatMap(Belt_List.getByU(model.properties, (function (property) {
                    if (property.TAG === /* PropertyRef */0) {
                      return false;
                    } else {
                      return property._0.name.trim() === name.trim();
                    }
                  })), (function (property) {
                if (property.TAG === /* PropertyRef */0) {
                  return ;
                } else {
                  return property._0;
                }
              }));
}

function getPropertyId(property) {
  return property._0.id;
}

function getNestedPropertyRefById(propertyId, nestedPropertyId, model) {
  return Belt_Option.flatMap(Belt_List.head(Belt_List.keepMap(model.properties, (function (x) {
                        if (x.TAG === /* PropertyRef */0) {
                          return ;
                        }
                        var property = x._0;
                        if (property.id === propertyId) {
                          return property;
                        }
                        
                      }))), (function (property) {
                return Belt_List.head(BeltListExtensions.flatMap(property.validations, (function (x) {
                                  if (typeof x === "object" && x.NAME === "NestedProperty") {
                                    return Belt_Option.mapWithDefault(Belt_List.getBy(x.VAL, (function (propertyRef) {
                                                      return propertyRef.id === nestedPropertyId;
                                                    })), /* [] */0, (function (propertyRef) {
                                                  return {
                                                          hd: propertyRef,
                                                          tl: /* [] */0
                                                        };
                                                }));
                                  } else {
                                    return /* [] */0;
                                  }
                                })));
              }));
}

function resolvePropertyById(_id, model) {
  while(true) {
    var id = _id;
    var match = getPropertyById(id, model);
    if (match === undefined) {
      return ;
    }
    if (match.TAG !== /* PropertyRef */0) {
      return match._0;
    }
    _id = match._0.id;
    continue ;
  };
}

function resolvePropertyByIdFromArchive(_id, model) {
  while(true) {
    var id = _id;
    var match = getPropertyByIdFromArchive(id, model);
    if (match === undefined) {
      return ;
    }
    if (match.TAG !== /* PropertyRef */0) {
      return match._0;
    }
    _id = match._0.id;
    continue ;
  };
}

function resolveProperty(model, modelProperty) {
  if (modelProperty.TAG === /* PropertyRef */0) {
    return resolvePropertyById(modelProperty._0.id, model);
  } else {
    return modelProperty._0;
  }
}

function resolvePropertyRef(model, propertyRef) {
  return resolvePropertyById(propertyRef.id, model);
}

function getPropertyParents(nestedPropertyId, model) {
  return Belt_List.keepMapU(model.properties, (function (modelProperty) {
                if (modelProperty.TAG === /* PropertyRef */0) {
                  return ;
                }
                var modelProperty$1 = modelProperty._0;
                if (modelProperty$1.type_ === "object" && Belt_List.someU(modelProperty$1.validations, (function (validation) {
                          return typeof validation === "object" && validation.NAME === "NestedProperty" ? Belt_List.someU(validation.VAL, (function (nestedPropertyRef) {
                                          return nestedPropertyRef.id === nestedPropertyId;
                                        })) : false;
                        }))) {
                  return modelProperty$1;
                }
                
              }));
}

function getObjectPropertyChildren(propertyId, model) {
  var property = resolvePropertyById(propertyId, model);
  if (property !== undefined && property.type_ === "object") {
    return Belt_List.keepMap(Belt_List.flatten(Belt_List.keepMap(property.validations, (function (validation) {
                          if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                            return validation.VAL;
                          }
                          
                        }))), (function (childPropertyRef) {
                  return resolvePropertyById(childPropertyRef.id, model);
                }));
  } else {
    return /* [] */0;
  }
}

function getArchivedPropertyById(id, model) {
  return Belt_List.getByU(model.archive.properties, (function (property) {
                if (property.TAG === /* PropertyRef */0) {
                  return Pervasives.failwith("PropertyRefs not allowed in root properties");
                } else {
                  return property._0.id === id;
                }
              }));
}

function getPropertyByIdWithArchive(id, model) {
  return Belt_List.getByU(Belt_List.concat(model.properties, model.archive.properties), (function (property) {
                if (property.TAG === /* PropertyRef */0) {
                  return Pervasives.failwith("PropertyRefs not allowed in root properties");
                } else {
                  return property._0.id === id;
                }
              }));
}

function getPropertyNameById(id, model) {
  return Belt_Option.flatMap(getPropertyById(id, model), (function (x) {
                if (x.TAG === /* PropertyRef */0) {
                  return ;
                } else {
                  return x._0.name;
                }
              }));
}

function getPropertyGroupById(id, model) {
  return Belt_List.getByU(model.propertyBundles, (function (group) {
                return group.id === id;
              }));
}

function getPropertyGroupByName(name, model) {
  return Belt_List.getByU(model.propertyBundles, (function (group) {
                return group.name === name;
              }));
}

function getPropertyGroupByIdWithArchive(id, model) {
  return Belt_List.getByU(Belt_List.concat(model.propertyBundles, model.archive.propertyBundles), (function (group) {
                return group.id === id;
              }));
}

function getPropertyGroupNamespace(model) {
  return Belt_List.map(model.propertyBundles, (function (group) {
                return group.name;
              }));
}

function getPropertyNameMappings(model, propertyId) {
  return Belt_List.keepU(model.rules, (function (rule) {
                var match = rule.item;
                var match$1 = rule.definition;
                if (typeof match !== "object") {
                  return false;
                }
                if (match.NAME !== "Property") {
                  return false;
                }
                if (typeof match$1 !== "object") {
                  return false;
                }
                if (match$1.NAME !== "NameMapping") {
                  return false;
                }
                var destination = match$1.VAL.destination;
                var destinationMissing;
                if (destination !== undefined && typeof destination === "object" && destination.NAME === "Destination") {
                  var id = destination.VAL;
                  destinationMissing = Belt_List.everyU(model.destinations, (function (destination) {
                          return destination.id !== id;
                        }));
                } else {
                  destinationMissing = false;
                }
                if (match.VAL === propertyId) {
                  return !destinationMissing;
                } else {
                  return false;
                }
              }));
}

function getPropertyIdFromNameMappingName(model, eventIdOpt, propertyName) {
  var eventId = eventIdOpt !== undefined ? Caml_option.valFromOption(eventIdOpt) : undefined;
  return Belt_List.head(Belt_List.keepMapU(model.rules, (function (rule) {
                    var match = rule.item;
                    var match$1 = rule.definition;
                    if (typeof match !== "object") {
                      return ;
                    }
                    var variant = match.NAME;
                    if (variant === "PropertyRef") {
                      if (typeof match$1 !== "object") {
                        return ;
                      }
                      if (match$1.NAME !== "NameMapping") {
                        return ;
                      }
                      var match$2 = match$1.VAL;
                      var destination = match$2.destination;
                      var match$3 = match.VAL;
                      var destinationMissing;
                      if (destination !== undefined && typeof destination === "object" && destination.NAME === "Destination") {
                        var id = destination.VAL;
                        destinationMissing = Belt_List.everyU(model.destinations, (function (destination) {
                                return destination.id !== id;
                              }));
                      } else {
                        destinationMissing = false;
                      }
                      if (Caml_obj.caml_equal(match$2.name, propertyName) && !destinationMissing && Caml_obj.caml_equal(match$3[0], eventId)) {
                        return match$3[1];
                      } else {
                        return ;
                      }
                    }
                    if (variant !== "Property") {
                      return ;
                    }
                    if (typeof match$1 !== "object") {
                      return ;
                    }
                    if (match$1.NAME !== "NameMapping") {
                      return ;
                    }
                    var match$4 = match$1.VAL;
                    var destination$1 = match$4.destination;
                    var destinationMissing$1;
                    if (destination$1 !== undefined && typeof destination$1 === "object" && destination$1.NAME === "Destination") {
                      var id$1 = destination$1.VAL;
                      destinationMissing$1 = Belt_List.everyU(model.destinations, (function (destination) {
                              return destination.id !== id$1;
                            }));
                    } else {
                      destinationMissing$1 = false;
                    }
                    if (Caml_obj.caml_equal(match$4.name, propertyName) && !destinationMissing$1) {
                      return match.VAL;
                    }
                    
                  })));
}

function getPropertyByNameOrNameMapping(name, model, eventId) {
  var property = getPropertyByName(name, model);
  if (property !== undefined) {
    return property;
  } else {
    return Belt_Option.flatMap(getPropertyIdFromNameMappingName(model, Caml_option.some(eventId), name), (function (id) {
                  return getModelPropertyById(id, model);
                }));
  }
}

function getEventPossibleNames(modelEvent, model) {
  return Belt_List.add(Belt_List.keepMapU(model.rules, (function (rule) {
                    var match = rule.item;
                    if (match.NAME !== "Event") {
                      return ;
                    }
                    var match$1 = rule.definition;
                    if (match$1.NAME !== "NameMapping") {
                      return ;
                    }
                    var name = match$1.VAL.name;
                    if (name !== undefined && match.VAL === modelEvent.id) {
                      return name;
                    }
                    
                  })), modelEvent.name);
}

function getRuleById(id, model) {
  return Belt_List.getByU(model.rules, (function (rule) {
                return rule.id === id;
              }));
}

function getAllTags(events) {
  return BeltListExtensions.dedupeString(BeltListExtensions.flatMap(events, (function ($$event) {
                    return $$event.tags;
                  })));
}

function getPropertyNamespaceWithIds(model) {
  var propertyRulesWithoutArchive = Belt_List.keepU(model.rules, (function (rule) {
          var match = rule.item;
          if (typeof match !== "object") {
            return false;
          }
          if (match.NAME !== "Property") {
            return false;
          }
          var propertyId = match.VAL;
          return Belt_List.someU(model.properties, (function (property) {
                        if (property.TAG === /* PropertyRef */0) {
                          return false;
                        } else {
                          return property._0.id === propertyId;
                        }
                      }));
        }));
  var mappingNames = Belt_List.keepMapU(propertyRulesWithoutArchive, (function (rule) {
          var match = rule.item;
          var match$1 = rule.definition;
          if (typeof match !== "object") {
            return ;
          }
          if (match.NAME !== "Property") {
            return ;
          }
          if (typeof match$1 !== "object") {
            return ;
          }
          if (match$1.NAME !== "NameMapping") {
            return ;
          }
          var propertyId = match.VAL;
          return Belt_Option.map(match$1.VAL.name, (function (name) {
                        return [
                                name,
                                propertyId
                              ];
                      }));
        }));
  var propertyNames = Belt_List.keepMapU(model.properties, (function (property) {
          if (property.TAG === /* PropertyRef */0) {
            return ;
          }
          var property$1 = property._0;
          return [
                  property$1.name,
                  property$1.id
                ];
        }));
  var propertyGroupNames = Belt_List.mapU(model.propertyBundles, (function (group) {
          return [
                  group.name,
                  group.id
                ];
        }));
  var propertyUniqueNames = Belt_List.keepMapU(model.properties, (function (property) {
          if (property.TAG === /* PropertyRef */0) {
            return ;
          }
          var match = property._0;
          var id = match.id;
          return Belt_Option.map(match.uniqueName, (function (uniqueName) {
                        return [
                                uniqueName,
                                id
                              ];
                      }));
        }));
  return Belt_List.concatMany([
              mappingNames,
              propertyNames,
              propertyGroupNames,
              propertyUniqueNames
            ]);
}

function getPropertyNamespace(model) {
  return Belt_List.map(getPropertyNamespaceWithIds(model), (function (param) {
                return param[0];
              }));
}

function getEventNamespaceWithIds(events) {
  var uniqueNames = Belt_List.keepMapU(events, (function ($$event) {
          return Belt_Option.map($$event.uniqueName, (function (uniqueName) {
                        return [
                                uniqueName,
                                $$event.id
                              ];
                      }));
        }));
  var eventNames = Belt_List.mapU(events, (function ($$event) {
          return [
                  $$event.name,
                  $$event.id
                ];
        }));
  return Belt_List.concat(uniqueNames, eventNames);
}

function getEventNamespace(events) {
  return Belt_List.map(getEventNamespaceWithIds(events), (function (param) {
                return param[0];
              }));
}

function updateSource(model, sourceId, modifier) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: model.events,
          migrations: model.migrations,
          sources: Belt_List.mapU(model.sources, (function (source) {
                  if (sourceId === source.id) {
                    return Curry._1(modifier, source);
                  } else {
                    return source;
                  }
                })),
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations
        };
}

function updateDestination(model, destinationId, modifier) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: model.events,
          migrations: model.migrations,
          sources: model.sources,
          destinations: Belt_List.mapU(model.destinations, (function (destination) {
                  if (destinationId === destination.id) {
                    return Curry._1(modifier, destination);
                  } else {
                    return destination;
                  }
                })),
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations
        };
}

function updateGoal(model, goalId, modifier) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: model.events,
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: Belt_List.mapU(model.goals, (function (goal) {
                  if (goalId === goal.id) {
                    return Curry._1(modifier, goal);
                  } else {
                    return goal;
                  }
                })),
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations
        };
}

function updateMetric(model, metricId, modifier) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: model.events,
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: Belt_List.mapU(model.metrics, (function (metric) {
                  if (metricId === metric.id) {
                    return Curry._1(modifier, metric);
                  } else {
                    return metric;
                  }
                })),
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations
        };
}

function updatePropertyGroup(model, groupId, modifier) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: Belt_List.mapU(model.propertyBundles, (function (group) {
                  if (groupId === group.id) {
                    return Curry._1(modifier, group);
                  } else {
                    return group;
                  }
                })),
          events: model.events,
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations
        };
}

function updateIntegration(model, integrationId, modifier) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: model.events,
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: Belt_List.mapU(model.integrations, (function (integration) {
                  if (integrationId === integration.id) {
                    return Curry._1(modifier, integration);
                  } else {
                    return integration;
                  }
                }))
        };
}

function getIntegrationById(id, model) {
  return Belt_List.getByU(model.integrations, (function (integration) {
                return integration.id === id;
              }));
}

function getIntegrationConfigById(id, model) {
  return Belt_Option.flatMapU(getIntegrationById(id, model), (function (integration) {
                return integration.config;
              }));
}

function resolvePropertyGroupRefs(model, groupRefs) {
  return Belt_List.keepMapU(groupRefs, (function (groupRef) {
                return Belt_List.getByU(model.propertyBundles, (function (group) {
                              return group.id === groupRef.id;
                            }));
              }));
}

function resolvePropertyGroupRefs_mappedModel(model, groupRefs) {
  return Belt_List.keepMapU(groupRefs, (function (groupRef) {
                return Curry._2(TrackingPlanMappedModel.PropertyBundles.get, model.propertyBundles, groupRef.id);
              }));
}

function getSourcesEvents(codegenEventsOnlyOpt, model, sourceIds) {
  var codegenEventsOnly = codegenEventsOnlyOpt !== undefined ? codegenEventsOnlyOpt : false;
  var sourceIds$1 = Belt_SetString.fromArray(Belt_List.toArray(sourceIds));
  return Belt_List.keepU(model.events, (function ($$event) {
                return Belt_List.someU($$event.includeSources, (function (includedSource) {
                              if (Belt_SetString.has(sourceIds$1, includedSource.id)) {
                                if (codegenEventsOnly) {
                                  return includedSource.includeInCodegen;
                                } else {
                                  return true;
                                }
                              } else {
                                return false;
                              }
                            }));
              }));
}

function getSourcesEvents_mappedModel(codegenEventsOnlyOpt, model, sourceIds) {
  var codegenEventsOnly = codegenEventsOnlyOpt !== undefined ? codegenEventsOnlyOpt : false;
  var sourceIds$1 = Belt_SetString.fromArray(Belt_List.toArray(sourceIds));
  return Curry._2(TrackingPlanMappedModel.Events.keep, model.events, (function ($$event) {
                return Belt_List.someU($$event.includeSources, (function (includedSource) {
                              if (Belt_SetString.has(sourceIds$1, includedSource.id)) {
                                if (codegenEventsOnly) {
                                  return includedSource.includeInCodegen;
                                } else {
                                  return true;
                                }
                              } else {
                                return false;
                              }
                            }));
              }));
}

function getSourceEvents(codegenEventsOnlyOpt, model, sourceId) {
  var codegenEventsOnly = codegenEventsOnlyOpt !== undefined ? codegenEventsOnlyOpt : false;
  return getSourcesEvents(codegenEventsOnly, model, {
              hd: sourceId,
              tl: /* [] */0
            });
}

function getSourceEvents_mappedModel(codegenEventsOnlyOpt, model, sourceId) {
  var codegenEventsOnly = codegenEventsOnlyOpt !== undefined ? codegenEventsOnlyOpt : false;
  return getSourcesEvents_mappedModel(codegenEventsOnly, model, {
              hd: sourceId,
              tl: /* [] */0
            });
}

function eventsSendingPropertyFromSources(model, sources, propertyId) {
  return Belt_List.keepU(Belt_Option.mapWithDefault(sources, model.events, (function (filterBySources) {
                    return getSourcesEvents(undefined, model, filterBySources);
                  })), (function ($$event) {
                var eventUsingProperty = Belt_List.someU($$event.properties, (function (property) {
                        if (property.TAG === /* PropertyRef */0) {
                          return property._0.id === propertyId;
                        } else {
                          return false;
                        }
                      }));
                if (eventUsingProperty) {
                  return true;
                } else {
                  return Belt_List.someU(resolvePropertyGroupRefs(model, $$event.propertyBundles), (function (group) {
                                return Belt_List.someU(group.properties, (function (groupPropertyId) {
                                              return groupPropertyId === propertyId;
                                            }));
                              }));
                }
              }));
}

function eventsSendingPropertyFromSources_mappedModel(model, sources, propertyId) {
  return Curry._2(TrackingPlanMappedModel.Events.keep, Belt_Option.mapWithDefault(sources, model.events, (function (filterBySources) {
                    return getSourcesEvents_mappedModel(undefined, model, filterBySources);
                  })), (function ($$event) {
                var eventUsingProperty = Belt_List.someU($$event.properties, (function (property) {
                        if (property.TAG === /* PropertyRef */0) {
                          return property._0.id === propertyId;
                        } else {
                          return false;
                        }
                      }));
                if (eventUsingProperty) {
                  return true;
                } else {
                  return Belt_List.someU(resolvePropertyGroupRefs_mappedModel(model, $$event.propertyBundles), (function (group) {
                                return Belt_List.someU(group.properties, (function (groupPropertyId) {
                                              return groupPropertyId === propertyId;
                                            }));
                              }));
                }
              }));
}

function eventsSendingProperty(model, sourceId, propertyId) {
  return eventsSendingPropertyFromSources(model, Belt_Option.map(sourceId, (function (sourceId) {
                    return {
                            hd: sourceId,
                            tl: /* [] */0
                          };
                  })), propertyId);
}

function eventsSendingProperty_mappedModel(model, sourceId, propertyId) {
  return eventsSendingPropertyFromSources_mappedModel(model, Belt_Option.map(sourceId, (function (sourceId) {
                    return {
                            hd: sourceId,
                            tl: /* [] */0
                          };
                  })), propertyId);
}

function eventsSendingGroupType(model, groupTypeId) {
  return Belt_List.keep(model.events, (function ($$event) {
                if ($$event.userGroupTypeIdsWithArchive.includes(groupTypeId)) {
                  return true;
                } else {
                  return $$event.eventGroupTypeIdsWithArchive.includes(groupTypeId);
                }
              }));
}

function getIncludedSourceIds(includeSources) {
  return Belt_List.mapU(includeSources, (function (includedSource) {
                return includedSource.id;
              }));
}

function isPropertySentFromSource(model, propertyId, sourceId) {
  var includeSources = BeltListExtensions.flatMap(eventsSendingProperty(model, undefined, propertyId), (function ($$event) {
          return $$event.includeSources;
        }));
  return Belt_List.someU(Belt_List.mapU(includeSources, (function (includedSource) {
                    return includedSource.id;
                  })), (function (includedSourceId) {
                return includedSourceId === sourceId;
              }));
}

function isPropertySentToDestination(model, propertyId, destinationId) {
  var includeSources = BeltListExtensions.flatMap(eventsSendingProperty(model, undefined, propertyId), (function ($$event) {
          return $$event.includeSources;
        }));
  return Belt_List.someU(BeltListExtensions.flatMap(Belt_List.keepMap(BeltListExtensions.dedupeString(Belt_List.mapU(includeSources, (function (includedSource) {
                                return includedSource.id;
                              }))), (function (sourceId) {
                        return getSourceById(sourceId, model);
                      })), (function (source) {
                    return source.destinations;
                  })), (function (sourceDestination) {
                return sourceDestination.destinationId === destinationId;
              }));
}

function getEventSourceIdsByEventId(model, eventId) {
  var $$event = getEventById(eventId, model);
  if ($$event !== undefined) {
    return Belt_List.mapU($$event.includeSources, (function (includedSource) {
                  return includedSource.id;
                }));
  } else {
    return /* [] */0;
  }
}

function getEventSourceIdsByEventId_mappedModel(model, eventId) {
  var $$event = Curry._2(TrackingPlanMappedModel.Events.get, model.events, eventId);
  if ($$event !== undefined) {
    return Belt_List.mapU($$event.includeSources, (function (includedSource) {
                  return includedSource.id;
                }));
  } else {
    return /* [] */0;
  }
}

function isEventSentToDestination(model, eventId, destinationId) {
  var $$event = getEventById(eventId, model);
  if ($$event !== undefined) {
    return Belt_List.someU($$event.includeDestinations, (function (includeDestination) {
                  var match = includeDestination.VAL;
                  var sourceId = match[0];
                  if (Belt_List.someU($$event.includeSources, (function (includedSource) {
                            return includedSource.id === sourceId;
                          }))) {
                    return Belt_List.some(match[1], (function (id) {
                                  return id === destinationId;
                                }));
                  } else {
                    return false;
                  }
                }));
  } else {
    return false;
  }
}

function eventsWithNameMapping(model) {
  return Belt_List.keepMapU(model.rules, (function (rule) {
                var match = rule.item;
                var match$1 = rule.definition;
                if (typeof match === "object" && match.NAME === "Event" && typeof match$1 === "object" && match$1.NAME === "NameMapping") {
                  return match.VAL;
                }
                
              }));
}

function getAllDestinationsOfType(model, destinationType) {
  return Belt_List.keepMapU(model.destinations, (function (destination) {
                if (Caml_obj.caml_equal(destination.type_, destinationType)) {
                  return destination;
                }
                
              }));
}

function getAllDestinationsIdsOfType(model, destinationType) {
  return Belt_List.mapU(getAllDestinationsOfType(model, destinationType), (function (destination) {
                return destination.id;
              }));
}

function getPropertyNameMappingForDestinationType(model, eventId, propertyId, destinationType, includedDestinationIds) {
  var destinationIdsOfType = getAllDestinationsIdsOfType(model, destinationType);
  var destinationIdsOfType$1 = includedDestinationIds ? Belt_List.keep(destinationIdsOfType, (function (destinationId) {
            return Belt_List.some(includedDestinationIds, (function (includedDestinationId) {
                          return includedDestinationId === destinationId;
                        }));
          })) : destinationIdsOfType;
  return Belt_List.head(Belt_List.keepMap(model.rules, (function (rule) {
                    var match = rule.item;
                    var match$1 = rule.definition;
                    if (typeof match !== "object") {
                      return ;
                    }
                    var variant = match.NAME;
                    if (variant === "PropertyRef") {
                      if (typeof match$1 !== "object") {
                        return ;
                      }
                      if (match$1.NAME !== "NameMapping") {
                        return ;
                      }
                      var match$2 = match$1.VAL;
                      var match$3 = match$2.destination;
                      if (match$3 === undefined) {
                        return ;
                      }
                      var match$4 = match.VAL;
                      var rulePropertyId = match$4[1];
                      var ruleEventId = match$4[0];
                      if (typeof match$3 === "object") {
                        var name = match$2.name;
                        if (name === undefined) {
                          return ;
                        }
                        var ruleDestinationId = match$3.VAL;
                        if (ruleEventId === eventId && rulePropertyId === propertyId && Belt_List.some(destinationIdsOfType$1, (function (id) {
                                  return id === ruleDestinationId;
                                }))) {
                          return name;
                        } else {
                          return ;
                        }
                      }
                      var name$1 = match$2.name;
                      if (name$1 !== undefined && ruleEventId === eventId && rulePropertyId === propertyId) {
                        return name$1;
                      } else {
                        return ;
                      }
                    }
                    if (variant !== "Property") {
                      return ;
                    }
                    if (typeof match$1 !== "object") {
                      return ;
                    }
                    if (match$1.NAME !== "NameMapping") {
                      return ;
                    }
                    var match$5 = match$1.VAL;
                    var match$6 = match$5.destination;
                    if (match$6 === undefined) {
                      return ;
                    }
                    var rulePropertyId$1 = match.VAL;
                    if (typeof match$6 === "object") {
                      var name$2 = match$5.name;
                      if (name$2 === undefined) {
                        return ;
                      }
                      var ruleDestinationId$1 = match$6.VAL;
                      if (rulePropertyId$1 === propertyId && Belt_List.some(destinationIdsOfType$1, (function (id) {
                                return id === ruleDestinationId$1;
                              }))) {
                        return name$2;
                      } else {
                        return ;
                      }
                    }
                    var name$3 = match$5.name;
                    if (name$3 !== undefined && rulePropertyId$1 === propertyId) {
                      return name$3;
                    }
                    
                  })));
}

function getEventNameMappingForDestinationType(model, eventId, destinationType, includedDestinationIds) {
  var destinationIdsOfType = getAllDestinationsIdsOfType(model, destinationType);
  var destinationIdsOfType$1 = includedDestinationIds ? Belt_List.keep(destinationIdsOfType, (function (destinationId) {
            return Belt_List.some(includedDestinationIds, (function (includedDestinationId) {
                          return includedDestinationId === destinationId;
                        }));
          })) : destinationIdsOfType;
  return Belt_List.head(Belt_List.keepMap(model.rules, (function (rule) {
                    var match = rule.item;
                    var match$1 = rule.definition;
                    if (typeof match !== "object") {
                      return ;
                    }
                    if (match.NAME !== "Event") {
                      return ;
                    }
                    if (typeof match$1 !== "object") {
                      return ;
                    }
                    if (match$1.NAME !== "NameMapping") {
                      return ;
                    }
                    var match$2 = match$1.VAL;
                    var match$3 = match$2.destination;
                    if (match$3 === undefined) {
                      return ;
                    }
                    if (typeof match$3 !== "object") {
                      return ;
                    }
                    if (match$3.NAME !== "Destination") {
                      return ;
                    }
                    var name = match$2.name;
                    if (name === undefined) {
                      return ;
                    }
                    var ruleDestinationId = match$3.VAL;
                    if (match.VAL === eventId && Belt_List.some(destinationIdsOfType$1, (function (id) {
                              return id === ruleDestinationId;
                            }))) {
                      return name;
                    }
                    
                  })));
}

function makePropertyAbsenceFromOptionalAndExcludedSources(property, model) {
  var match = property.optionalDeprecated;
  var match$1 = property.excludedSourcesDeprecated;
  var match$2 = property.sendAs;
  if (match) {
    if (!match$1) {
      return {
              TAG: /* SometimesSent */0,
              _0: ""
            };
    }
    if (match$2 === 0) {
      return {
              TAG: /* Mixed */1,
              _0: Belt_MapString.fromArray([[
                      TrackingPlanModel.propertyAbsenceAllEventsKey,
                      {
                        TAG: /* MixedSources */1,
                        _0: Belt_MapString.fromArray(Belt_List.toArray(Belt_List.mapU(model.sources, (function (source) {
                                        return [
                                                source.id,
                                                Belt_List.has(match$1, source.id, (function (prim0, prim1) {
                                                        return prim0 === prim1;
                                                      })) ? /* NeverSent */0 : /* SometimesSent */({
                                                      _0: ""
                                                    })
                                              ];
                                      }))))
                      }
                    ]])
            };
    }
    var excludedSources = match$1;
    var eventsSendingProperty$1 = eventsSendingProperty(model, undefined, property.id);
    return {
            TAG: /* Mixed */1,
            _0: Belt_MapString.fromArray(Belt_List.toArray(Belt_List.mapU(eventsSendingProperty$1, (function ($$event) {
                            return [
                                    $$event.id,
                                    {
                                      TAG: /* MixedSources */1,
                                      _0: Belt_MapString.fromArray(Belt_List.toArray(Belt_List.mapU($$event.includeSources, (function (includedSource) {
                                                      return [
                                                              includedSource.id,
                                                              Belt_List.has(excludedSources, includedSource.id, (function (prim0, prim1) {
                                                                      return prim0 === prim1;
                                                                    })) ? /* NeverSent */0 : /* SometimesSent */({
                                                                    _0: ""
                                                                  })
                                                            ];
                                                    }))))
                                    }
                                  ];
                          }))))
          };
  }
  if (!match$1) {
    return /* AlwaysSent */0;
  }
  if (match$2 === 0) {
    return {
            TAG: /* Mixed */1,
            _0: Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(Belt_List.keepU({
                              hd: [
                                TrackingPlanModel.propertyAbsenceAllEventsKey,
                                Belt_MapString.fromArray(Belt_List.toArray(Belt_List.keepMapU(model.sources, (function (source) {
                                                if (Belt_List.has(match$1, source.id, (function (prim0, prim1) {
                                                          return prim0 === prim1;
                                                        }))) {
                                                  return [
                                                          source.id,
                                                          /* NeverSent */0
                                                        ];
                                                }
                                                
                                              }))))
                              ],
                              tl: /* [] */0
                            }, (function (param) {
                                return !Belt_MapString.isEmpty(param[1]);
                              })), (function (param) {
                            return [
                                    param[0],
                                    {
                                      TAG: /* MixedSources */1,
                                      _0: param[1]
                                    }
                                  ];
                          }))))
          };
  }
  var excludedSources$1 = match$1;
  var eventsSendingProperty$2 = eventsSendingProperty(model, undefined, property.id);
  return {
          TAG: /* Mixed */1,
          _0: Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(Belt_List.keepU(Belt_List.mapU(eventsSendingProperty$2, (function ($$event) {
                                  return [
                                          $$event.id,
                                          Belt_MapString.fromArray(Belt_List.toArray(Belt_List.keepMapU($$event.includeSources, (function (includedSource) {
                                                          if (Belt_List.has(excludedSources$1, includedSource.id, (function (prim0, prim1) {
                                                                    return prim0 === prim1;
                                                                  }))) {
                                                            return [
                                                                    includedSource.id,
                                                                    /* NeverSent */0
                                                                  ];
                                                          }
                                                          
                                                        }))))
                                        ];
                                })), (function (param) {
                              return !Belt_MapString.isEmpty(param[1]);
                            })), (function (param) {
                          return [
                                  param[0],
                                  {
                                    TAG: /* MixedSources */1,
                                    _0: param[1]
                                  }
                                ];
                        }))))
        };
}

function makePropertyAbsenceFromOptionalAndExcludedSources_mappedModel(property, model) {
  var match = property.optionalDeprecated;
  var match$1 = property.excludedSourcesDeprecated;
  var match$2 = property.sendAs;
  if (match) {
    if (!match$1) {
      return {
              TAG: /* SometimesSent */0,
              _0: ""
            };
    }
    if (match$2 === 0) {
      return {
              TAG: /* Mixed */1,
              _0: Belt_MapString.fromArray([[
                      TrackingPlanModel.propertyAbsenceAllEventsKey,
                      {
                        TAG: /* MixedSources */1,
                        _0: Curry._2(TrackingPlanMappedModel.Sources.mapToMap, model.sources, (function (source) {
                                if (Belt_List.has(match$1, source.id, (function (prim0, prim1) {
                                          return prim0 === prim1;
                                        }))) {
                                  return /* NeverSent */0;
                                } else {
                                  return /* SometimesSent */{
                                          _0: ""
                                        };
                                }
                              }))
                      }
                    ]])
            };
    }
    var excludedSources = match$1;
    var eventsSendingProperty = eventsSendingProperty_mappedModel(model, undefined, property.id);
    return {
            TAG: /* Mixed */1,
            _0: Curry._2(TrackingPlanMappedModel.Events.mapToMap, eventsSendingProperty, (function ($$event) {
                    return {
                            TAG: /* MixedSources */1,
                            _0: Belt_MapString.fromArray(Belt_List.toArray(Belt_List.mapU($$event.includeSources, (function (includedSource) {
                                            return [
                                                    includedSource.id,
                                                    Belt_List.has(excludedSources, includedSource.id, (function (prim0, prim1) {
                                                            return prim0 === prim1;
                                                          })) ? /* NeverSent */0 : /* SometimesSent */({
                                                          _0: ""
                                                        })
                                                  ];
                                          }))))
                          };
                  }))
          };
  }
  if (!match$1) {
    return /* AlwaysSent */0;
  }
  if (match$2 === 0) {
    return {
            TAG: /* Mixed */1,
            _0: Belt_MapString.mapU(Belt_MapString.keepU(Belt_MapString.fromArray([[
                            TrackingPlanModel.propertyAbsenceAllEventsKey,
                            Belt_MapString.fromArray(Curry._2(TrackingPlanMappedModel.Sources.keepMap, model.sources, (function (source) {
                                        if (Belt_List.has(match$1, source.id, (function (prim0, prim1) {
                                                  return prim0 === prim1;
                                                }))) {
                                          return [
                                                  source.id,
                                                  /* NeverSent */0
                                                ];
                                        }
                                        
                                      })))
                          ]]), (function (_eventId, sourceMap) {
                        return !Belt_MapString.isEmpty(sourceMap);
                      })), (function (sources) {
                    return {
                            TAG: /* MixedSources */1,
                            _0: sources
                          };
                  }))
          };
  }
  var excludedSources$1 = match$1;
  var eventsSendingProperty$1 = eventsSendingProperty_mappedModel(model, undefined, property.id);
  return {
          TAG: /* Mixed */1,
          _0: Belt_MapString.mapU(Belt_MapString.keepU(Curry._2(TrackingPlanMappedModel.Events.mapToMap, eventsSendingProperty$1, (function ($$event) {
                          return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.keepMapU($$event.includeSources, (function (includedSource) {
                                                if (Belt_List.has(excludedSources$1, includedSource.id, (function (prim0, prim1) {
                                                          return prim0 === prim1;
                                                        }))) {
                                                  return [
                                                          includedSource.id,
                                                          /* NeverSent */0
                                                        ];
                                                }
                                                
                                              }))));
                        })), (function (_eventId, sourceMap) {
                      return !Belt_MapString.isEmpty(sourceMap);
                    })), (function (sources) {
                  return {
                          TAG: /* MixedSources */1,
                          _0: sources
                        };
                }))
        };
}

function isPropertyIncludedOnEventAndSources(includeSystemPropertiesOpt, property, eventId, sourceIds, model, param) {
  var includeSystemProperties = includeSystemPropertiesOpt !== undefined ? includeSystemPropertiesOpt : true;
  var isSystemProperty = property.sendAs === /* SystemProperty */0;
  var eventId$1 = isSystemProperty ? TrackingPlanModel.propertyAbsenceAllEventsKey : eventId;
  return Belt_List.someU(sourceIds, (function (sourceId) {
                var sourceSendsEvent = Belt_List.someU(getSourceEvents(undefined, model, sourceId), (function ($$event) {
                        return $$event.id === eventId$1;
                      })) || isSystemProperty && includeSystemProperties;
                var match = property.absence;
                var propertyAtLeastSometimeSent;
                if (match !== undefined) {
                  if (typeof match === "number" || match.TAG === /* SometimesSent */0) {
                    propertyAtLeastSometimeSent = true;
                  } else {
                    var match$1 = Belt_MapString.get(match._0, eventId$1);
                    if (match$1 !== undefined) {
                      if (match$1.TAG === /* AllSources */0) {
                        propertyAtLeastSometimeSent = match$1._0 ? true : false;
                      } else {
                        var match$2 = Belt_MapString.get(match$1._0, sourceId);
                        propertyAtLeastSometimeSent = (
                          match$2 !== undefined ? (
                              match$2 ? true : false
                            ) : (
                              isSystemProperty ? true : Belt_List.someU(getSourceEvents(undefined, model, sourceId), (function ($$event) {
                                        return $$event.id === eventId$1;
                                      }))
                            )
                        ) === true;
                      }
                    } else {
                      propertyAtLeastSometimeSent = true;
                    }
                  }
                } else {
                  propertyAtLeastSometimeSent = !Belt_List.someU(property.excludedSourcesDeprecated, (function (excludedSourceId) {
                          return excludedSourceId === sourceId;
                        }));
                }
                if (sourceSendsEvent) {
                  return propertyAtLeastSometimeSent;
                } else {
                  return false;
                }
              }));
}

function legacyIsPropertyIncludedOnEventAndSources(property, eventId, sourceIds, model) {
  var eventId$1 = property.sendAs === /* SystemProperty */0 ? TrackingPlanModel.propertyAbsenceAllEventsKey : eventId;
  var match = property.absence;
  if (match === undefined) {
    return Belt_List.someU(sourceIds, (function (sourceId) {
                  return !Belt_List.someU(property.excludedSourcesDeprecated, (function (excludedSourceId) {
                                return excludedSourceId === sourceId;
                              }));
                }));
  }
  if (typeof match === "number") {
    return true;
  }
  if (match.TAG === /* SometimesSent */0) {
    return true;
  }
  var match$1 = Belt_MapString.get(match._0, eventId$1);
  if (match$1 === undefined) {
    return true;
  }
  if (match$1.TAG === /* AllSources */0) {
    if (match$1._0) {
      return true;
    } else {
      return false;
    }
  }
  var sourceMap = match$1._0;
  return Belt_List.has(Belt_List.mapU(sourceIds, (function (sourceId) {
                    var match = Belt_MapString.get(sourceMap, sourceId);
                    if (match !== undefined) {
                      if (match) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return Belt_List.someU(getSourceEvents(undefined, model, sourceId), (function ($$event) {
                                    return $$event.id === eventId$1;
                                  }));
                    }
                  })), true, (function (prim0, prim1) {
                return prim0 === prim1;
              }));
}

function isPropertyIncludedOnEventAndSource(property, eventId, sourceId, model) {
  var arg = {
    hd: sourceId,
    tl: /* [] */0
  };
  return function (param) {
    return function (param$1) {
      return isPropertyIncludedOnEventAndSources(param, property, eventId, arg, model, param$1);
    };
  };
}

function isPropertyOptionalOnEventsAndSources(eventIds, sourceIds, property, model) {
  var eventIds$1 = Belt_Option.map(eventIds, (function (eventIds) {
          return Belt_List.map(eventIds, (function (eventId) {
                        if (property.sendAs === /* SystemProperty */0) {
                          return TrackingPlanModel.propertyAbsenceAllEventsKey;
                        } else {
                          return eventId;
                        }
                      }));
        }));
  var isEventIdSentFromSourceIds = function (eventId, sourceIds) {
    var maybeEvent = getEventById(eventId, model);
    if (eventId === TrackingPlanModel.propertyAbsenceAllEventsKey) {
      return true;
    } else if (maybeEvent !== undefined) {
      if (sourceIds !== undefined) {
        return Belt_List.someU(maybeEvent.includeSources, (function (includedSource) {
                      return Belt_List.someU(sourceIds, (function (sourceId) {
                                    return sourceId === includedSource.id;
                                  }));
                    }));
      } else {
        return Belt_List.length(maybeEvent.includeSources) > 0;
      }
    } else {
      return false;
    }
  };
  var match = property.absence;
  if (match === undefined) {
    return property.optionalDeprecated;
  }
  if (typeof match === "number") {
    return false;
  }
  if (match.TAG === /* SometimesSent */0) {
    return true;
  }
  var eventMap = match._0;
  return Belt_MapString.some(Belt_Option.mapWithDefault(eventIds$1, eventMap, (function (eventIds) {
                    return Belt_MapString.keep(eventMap, (function (eventId, _absence) {
                                  return Belt_List.has(eventIds, eventId, (function (prim0, prim1) {
                                                return prim0 === prim1;
                                              }));
                                }));
                  })), (function (eventId, eventIdsAbsences) {
                var match = isEventIdSentFromSourceIds(eventId, sourceIds);
                if (!match) {
                  return false;
                }
                if (eventIdsAbsences.TAG === /* AllSources */0) {
                  return true;
                }
                var sourceMap = eventIdsAbsences._0;
                return Belt_MapString.someU(Belt_Option.mapWithDefault(sourceIds, sourceMap, (function (sourceIds) {
                                  return Belt_MapString.keep(sourceMap, (function (sourceId, _absence) {
                                                return Belt_List.has(sourceIds, sourceId, (function (prim0, prim1) {
                                                              return prim0 === prim1;
                                                            }));
                                              }));
                                })), (function (_sourceId, eventSourceAbsence) {
                              if (eventSourceAbsence) {
                                return true;
                              } else {
                                return false;
                              }
                            }));
              }));
}

function isPropertyOptionalOnEventAndSource(property, eventId, sourceId, model) {
  return isPropertyOptionalOnEventsAndSources({
              hd: eventId,
              tl: /* [] */0
            }, {
              hd: sourceId,
              tl: /* [] */0
            }, property, model);
}

function isPropertyOptionalOnEvent(property, eventId, model) {
  return isPropertyOptionalOnEventsAndSources({
              hd: eventId,
              tl: /* [] */0
            }, undefined, property, model);
}

function isPropertyOptionalOnSource(property, sourceId, model) {
  return isPropertyOptionalOnEventsAndSources(undefined, {
              hd: sourceId,
              tl: /* [] */0
            }, property, model);
}

function isPropertyOptionalSomewhere(property, model) {
  return isPropertyOptionalOnEventsAndSources(undefined, undefined, property, model);
}

function propertyExcludedSources(property, model) {
  var excludedSourcesForPropertyNotCountingParentProps = function (property) {
    var match = property.absence;
    var tmp;
    if (match !== undefined) {
      if (typeof match === "number" || match.TAG === /* SometimesSent */0) {
        tmp = /* [] */0;
      } else {
        var eventMap = match._0;
        if (property.sendAs === /* SystemProperty */0) {
          var sourceAbsence = Belt_MapString.get(eventMap, TrackingPlanModel.propertyAbsenceAllEventsKey);
          if (sourceAbsence !== undefined) {
            if (sourceAbsence.TAG === /* AllSources */0) {
              tmp = sourceAbsence._0 ? /* [] */0 : Belt_List.map(model.sources, (function (source) {
                        return source.id;
                      }));
            } else {
              var sourceMap = sourceAbsence._0;
              tmp = Belt_List.keepMap(model.sources, (function (source) {
                      var match = Belt_MapString.get(sourceMap, source.id);
                      if (match !== undefined && !match) {
                        return source.id;
                      }
                      
                    }));
            }
          } else {
            tmp = /* [] */0;
          }
        } else {
          tmp = Belt_List.keepMap(model.sources, (function (source) {
                  var eventsSentFromSource = eventsSendingProperty(model, source.id, property.id);
                  var sometimesIncluded = Belt_List.some(eventsSentFromSource, (function (sourceEvent) {
                          var eventAbsence = Belt_MapString.get(eventMap, sourceEvent.id);
                          if (eventAbsence === undefined) {
                            return true;
                          }
                          if (eventAbsence.TAG === /* AllSources */0) {
                            if (eventAbsence._0) {
                              return true;
                            } else {
                              return false;
                            }
                          }
                          var eventSourceAbsence = Belt_MapString.get(eventAbsence._0, source.id);
                          if (eventSourceAbsence !== undefined && !eventSourceAbsence) {
                            return false;
                          } else {
                            return true;
                          }
                        }));
                  if (sometimesIncluded) {
                    return ;
                  } else {
                    return source.id;
                  }
                }));
        }
      }
    } else {
      tmp = property.excludedSourcesDeprecated;
    }
    return Belt_List.toArray(tmp);
  };
  var parentObjectProperties = getPropertyParents(property.id, model);
  var excludedSourceOnPropertyItself = excludedSourcesForPropertyNotCountingParentProps(property);
  return Belt_List.fromArray(Belt_List.reduceU(parentObjectProperties, excludedSourceOnPropertyItself, (function (excludedSources, parentProperty) {
                    var excludedSourceOnParentProperty = excludedSourcesForPropertyNotCountingParentProps(parentProperty);
                    return Belt_SetString.toArray(Belt_SetString.intersect(Belt_SetString.fromArray(excludedSources), Belt_SetString.fromArray(excludedSourceOnParentProperty)));
                  })));
}

function allPropertyOptionalitiesForSource(property, sourceId, model) {
  var absence = property.absence;
  var optionalities;
  if (absence !== undefined) {
    if (typeof absence === "number") {
      optionalities = [false];
    } else if (absence.TAG === /* SometimesSent */0) {
      optionalities = [true];
    } else {
      var eventMap = absence._0;
      if (property.sendAs === /* SystemProperty */0) {
        optionalities = [isPropertyOptionalOnEventAndSource(property, TrackingPlanModel.propertyAbsenceAllEventsKey, sourceId, model)];
      } else {
        var eventsSentFromSource = eventsSendingProperty(model, sourceId, property.id);
        optionalities = Belt_List.toArray(Belt_List.keepMapU(eventsSentFromSource, (function (sourceEvent) {
                    var eventAbsence = Belt_MapString.get(eventMap, sourceEvent.id);
                    if (eventAbsence === undefined) {
                      return false;
                    }
                    if (eventAbsence.TAG === /* AllSources */0) {
                      if (eventAbsence._0) {
                        return true;
                      } else {
                        return ;
                      }
                    }
                    var eventSourceAbsence = Belt_MapString.get(eventAbsence._0, sourceId);
                    if (eventSourceAbsence !== undefined) {
                      if (eventSourceAbsence) {
                        return true;
                      } else {
                        return ;
                      }
                    } else {
                      return false;
                    }
                  })));
      }
    }
  } else {
    optionalities = Belt_List.has(property.excludedSourcesDeprecated, sourceId, (function (prim0, prim1) {
            return prim0 === prim1;
          })) ? Pervasives.failwith("Event not included on source: NeverSent properties should be filtered out before we get here") : [property.optionalDeprecated];
  }
  var propertyInObject = Belt_List.keepU(getPropertyParents(property.id, model), (function (parentProperty) {
          return eventsSendingProperty(model, sourceId, parentProperty.id) !== /* [] */0;
        })) !== /* [] */0;
  var hasMigratedToAbsence = Belt_List.some(model.migrations, (function (migration) {
          return migration === "PropertyOptionalAndExcludedSourcesToAbsence";
        }));
  var optionalities$1 = propertyInObject ? optionalities.concat([hasMigratedToAbsence ? property.optionalWhenInObject : property.optionalDeprecated]) : optionalities;
  return Belt_List.toArray(BeltListExtensions.dedupeSlow(Belt_List.fromArray(optionalities$1)));
}

function allPropertyOptionalities(prop, model) {
  var absence = prop.absence;
  if (absence === undefined) {
    return [prop.optionalDeprecated];
  }
  if (typeof absence === "number") {
    return [false];
  }
  if (absence.TAG === /* SometimesSent */0) {
    return [true];
  }
  var eventIdsAbsences = absence._0;
  if (prop.sendAs === /* SystemProperty */0) {
    return [isPropertyOptionalOnEvent(prop, TrackingPlanModel.propertyAbsenceAllEventsKey, model)];
  }
  var hasAlwaysSent = Belt_List.someU(model.events, (function ($$event) {
          if (!Belt_MapString.has(eventIdsAbsences, $$event.id)) {
            return true;
          }
          var sourceAbsences = Belt_MapString.get(eventIdsAbsences, $$event.id);
          if (sourceAbsences === undefined) {
            return false;
          }
          if (sourceAbsences.TAG === /* AllSources */0) {
            return false;
          }
          var eventSourceAbsences = sourceAbsences._0;
          return Belt_List.someU($$event.includeSources, (function (includedSource) {
                        return Belt_MapString.has(eventSourceAbsences, includedSource.id) === false;
                      }));
        }));
  var hasSometimesSent = Belt_MapString.someU(eventIdsAbsences, (function (eventId, sourceAbsences) {
          if (sourceAbsences.TAG === /* AllSources */0) {
            if (sourceAbsences._0) {
              return true;
            } else {
              return false;
            }
          }
          var eventSourceAbsences = sourceAbsences._0;
          var $$event = getEventById(eventId, model);
          if ($$event !== undefined) {
            return Belt_List.someU($$event.includeSources, (function (includedSource) {
                          var match = Belt_MapString.get(eventSourceAbsences, includedSource.id);
                          if (match !== undefined && match) {
                            return true;
                          } else {
                            return false;
                          }
                        }));
          } else {
            return false;
          }
        }));
  var result = [];
  if (hasAlwaysSent) {
    result.push(false);
  }
  if (hasSometimesSent) {
    result.push(true);
  }
  return result;
}

function isPropertyGroupConsistentAcrossAllEvents(sources, improvedPropertyBundlesUnfolding, propertyBundleId, model) {
  return Belt_Option.mapWithDefault(getPropertyGroupById(propertyBundleId, model), false, (function (propertyGroup) {
                return !Belt_List.someU(Belt_List.keepMapU(propertyGroup.properties, (function (propertyId) {
                                  return getPropertyById(propertyId, model);
                                })), (function (property) {
                              if (property.TAG === /* PropertyRef */0) {
                                return false;
                              }
                              var property$1 = property._0;
                              var eventIdsSendingPropertyInPropertyGroup = improvedPropertyBundlesUnfolding ? Belt_List.map(Belt_List.keepU(eventsSendingPropertyFromSources(model, sources, property$1.id), (function ($$event) {
                                            return Belt_List.someU($$event.propertyBundles, (function (propertyGroup) {
                                                          return propertyGroup.id === propertyBundleId;
                                                        }));
                                          })), (function ($$event) {
                                        return $$event.id;
                                      })) : Belt_List.map(eventsSendingPropertyFromSources(model, sources, property$1.id), (function ($$event) {
                                        return $$event.id;
                                      }));
                              var commonPresence = isPropertyOptionalOnEventsAndSources(eventIdsSendingPropertyInPropertyGroup, sources, property$1, model);
                              return Belt_List.someU(eventIdsSendingPropertyInPropertyGroup, (function (eventId) {
                                            return isPropertyOptionalOnEventsAndSources({
                                                        hd: eventId,
                                                        tl: /* [] */0
                                                      }, sources, property$1, model) !== commonPresence;
                                          }));
                            }));
              }));
}

function isPropertyBundleConsistentAcrossAllEventsWithCache(sources, improvedPropertyBundlesUnfolding, propertyBundleId, cache, model) {
  var isPropertyGroupConsistentAcrossAllEvents$1 = Belt_MapString.get(cache.contents, propertyBundleId);
  if (isPropertyGroupConsistentAcrossAllEvents$1 !== undefined) {
    return isPropertyGroupConsistentAcrossAllEvents$1;
  }
  var isPropertyGroupConsistentAcrossAllEvents$2 = isPropertyGroupConsistentAcrossAllEvents(sources, improvedPropertyBundlesUnfolding, propertyBundleId, model);
  cache.contents = Belt_MapString.set(cache.contents, propertyBundleId, isPropertyGroupConsistentAcrossAllEvents$2);
  return isPropertyGroupConsistentAcrossAllEvents$2;
}

function getDefaultDestinationsForSource(source, model) {
  return Belt_List.keepMapU(Belt_List.keepMapU(source.destinations, (function (param) {
                    return getDestinationById(param.destinationId, model);
                  })), (function (destination) {
                if (destination.disabledByDefault) {
                  return ;
                } else {
                  return destination.id;
                }
              }));
}

function getModelEventsByName(model) {
  return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.mapU(model.events, (function ($$event) {
                        return [
                                $$event.name,
                                $$event
                              ];
                      }))));
}

function getGroupType(groupTypeId, model) {
  return Belt_Array.getBy(model.groupTypes, (function (param) {
                return param.id === groupTypeId;
              }));
}

function getGroupTypeWithArchive(groupTypeId, model) {
  return Belt_Array.getBy(Belt_Array.concat(model.groupTypes, model.archive.groupTypes), (function (param) {
                return param.id === groupTypeId;
              }));
}

function getArchivedGroupType(groupTypeId, model) {
  return Belt_Array.getBy(model.archive.groupTypes, (function (param) {
                return param.id === groupTypeId;
              }));
}

function getGroupTypeName(groupTypeId, model) {
  return Belt_Option.map(getGroupType(groupTypeId, model), (function (group) {
                return group.name;
              }));
}

function getGroupTypeNameWithArchive(groupTypeId, model) {
  var match = getGroupType(groupTypeId, model);
  var match$1 = getArchivedGroupType(groupTypeId, model);
  if (match !== undefined) {
    return match.name + "(" + groupTypeId + ")";
  } else if (match$1 !== undefined) {
    return match$1.name + "(" + groupTypeId + ")";
  } else {
    return ;
  }
}

function getGroupTypeNames(model) {
  return Belt_Array.map(model.groupTypes, (function (param) {
                return param.name;
              }));
}

function updateGroupType(model, groupTypeId, modifier) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: model.events,
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: Belt_Array.mapU(model.groupTypes, (function (groupType) {
                  if (groupTypeId === groupType.id) {
                    return Curry._1(modifier, groupType);
                  } else {
                    return groupType;
                  }
                })),
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations
        };
}

function getGroupTypeById(groupTypeId, model) {
  return Belt_Array.getByU(model.groupTypes, (function (groupType) {
                return groupType.id === groupTypeId;
              }));
}

function getGroupTypeByIdWithArchive(groupTypeId, model) {
  return Belt_Array.getByU(Belt_Array.concat(model.groupTypes, model.archive.groupTypes), (function (groupType) {
                return groupType.id === groupTypeId;
              }));
}

function getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model) {
  return Belt_Option.getWithDefault(Belt_Option.mapU(getGroupTypeByIdWithArchive(groupTypeId, model), (function (groupType) {
                    return groupType.name;
                  })), groupTypeId);
}

function getGroupTypeNameWithArchiveWithDefaultDeletedName(groupTypeId, model) {
  return Belt_Option.getWithDefault(Belt_Option.mapU(getGroupTypeByIdWithArchive(groupTypeId, model), (function (groupType) {
                    return groupType.name;
                  })), "deleted");
}

function getGroupTypeDescription(groupTypeId, model) {
  return Belt_Option.getWithDefault(Belt_Option.mapU(getGroupTypeById(groupTypeId, model), (function (groupType) {
                    return groupType.description;
                  })), "");
}

function getSourceDestinationIds(destinations) {
  return Belt_List.mapU(destinations, (function (param) {
                return param.destinationId;
              }));
}

function getSourceDestinationRef(source, destinationId) {
  return Belt_List.getByU(source.destinations, (function (destinationRef) {
                return destinationRef.destinationId === destinationId;
              }));
}

function getEventNames(modelEvents) {
  return Belt_List.mapU(modelEvents, (function ($$event) {
                return $$event.name;
              }));
}

function getPropertyNames(modelProperties) {
  return Belt_List.keepMapU(modelProperties, (function (property) {
                if (property.TAG === /* PropertyRef */0) {
                  return ;
                } else {
                  return property._0.name;
                }
              }));
}

function isDestinationInterfaceEnabledForSourceDestination(source, destinationId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(source.destinations, (function (destinationRef) {
                    return destinationRef.destinationId === destinationId;
                  })), false, (function (destinationRef) {
                return destinationRef.destinationMode === /* DestinationInterface */1;
              }));
}

function isEventIncludedInSource(model, sourceId, eventId) {
  return Belt_List.someU(getSourceEvents(undefined, model, sourceId), (function (sourceEvent) {
                return sourceEvent.id === eventId;
              }));
}

function getEventActions(actions) {
  return Belt_List.keep(actions, (function (action) {
                if (!action) {
                  return false;
                }
                var match = action.hd[0];
                if (typeof match === "object") {
                  return match.NAME === "Event";
                } else {
                  return false;
                }
              }));
}

function getSourcesToAttachedEventsActions(branchModel, eventActions) {
  if (branchModel.sources === /* [] */0) {
    return [];
  } else {
    return Belt_List.toArray(Belt_List.mapU(branchModel.sources, (function (source) {
                      return [
                              source,
                              Belt_List.keepU(eventActions, (function (eventActionsById) {
                                      if (!eventActionsById) {
                                        return false;
                                      }
                                      var match = eventActionsById.hd[0];
                                      if (typeof match === "object" && match.NAME === "Event") {
                                        return isEventIncludedInSource(branchModel, source.id, match.VAL);
                                      } else {
                                        return false;
                                      }
                                    }))
                            ];
                    })));
  }
}

function getNameMappingNameAndDestination(rule, model) {
  return Belt_Option.getWithDefault(Belt_Option.map(rule, (function (rule) {
                    var match = rule.definition;
                    var name;
                    if (match.NAME === "NameMapping") {
                      var name$1 = match.VAL.name;
                      name = name$1 !== undefined ? name$1 : "";
                    } else {
                      name = "";
                    }
                    var match$1 = rule.definition;
                    var destination;
                    if (match$1.NAME === "NameMapping") {
                      var match$2 = match$1.VAL.destination;
                      destination = match$2 !== undefined ? (
                          typeof match$2 === "object" ? getDestinationNameById(model, match$2.VAL) : "all destinations"
                        ) : "";
                    } else {
                      destination = "";
                    }
                    return [
                            name,
                            destination
                          ];
                  })), [
              "",
              ""
            ]);
}

function getNameMappingsForPropertyOnEvent(model, eventId, propertyId) {
  return Belt_List.keep(model.rules, (function (rule) {
                var match = rule.item;
                if (typeof match !== "object") {
                  return false;
                }
                if (match.NAME !== "PropertyRef") {
                  return false;
                }
                var match$1 = rule.definition;
                if (typeof match$1 !== "object") {
                  return false;
                }
                if (match$1.NAME !== "NameMapping") {
                  return false;
                }
                var match$2 = match.VAL;
                if (match$2[1] === propertyId) {
                  return match$2[0] === eventId;
                } else {
                  return false;
                }
              }));
}

exports.updateEvent = updateEvent;
exports.updateEvents = updateEvents;
exports.updateProperty = updateProperty;
exports.updatePropertyValidations = updatePropertyValidations;
exports.getMatchesFromProperty = getMatchesFromProperty;
exports.updateEventTrigger = updateEventTrigger;
exports.destinationIdsWithoutArchived = destinationIdsWithoutArchived;
exports.metricIdsWithoutArchived = metricIdsWithoutArchived;
exports.eventIdsWithoutArchived = eventIdsWithoutArchived;
exports.getEventById = getEventById;
exports.getEventByIdWithArchive = getEventByIdWithArchive;
exports.getEventByIdFromArchive = getEventByIdFromArchive;
exports.getEventByIdFromArchive_mappedModel = getEventByIdFromArchive_mappedModel;
exports.getEventByName = getEventByName;
exports.duplicateEventAndEnrichModel = duplicateEventAndEnrichModel;
exports.getTriggerFromEvent = getTriggerFromEvent;
exports.getTriggerByIdAndEventId = getTriggerByIdAndEventId;
exports.getTriggersByEventId = getTriggersByEventId;
exports.getSourceById = getSourceById;
exports.getSourceByIdWithArchive = getSourceByIdWithArchive;
exports.getDestinationById = getDestinationById;
exports.defaultDestinationName = defaultDestinationName;
exports.getDestinationName = getDestinationName;
exports.getDestinationNameById = getDestinationNameById;
exports.getDestinationByIdWithArchive = getDestinationByIdWithArchive;
exports.getGoalById = getGoalById;
exports.getGoalByIdWithArchive = getGoalByIdWithArchive;
exports.getGoalsWithEvent = getGoalsWithEvent;
exports.getGoalNamespace = getGoalNamespace;
exports.getMetricById = getMetricById;
exports.getMetricByIdWithArchive = getMetricByIdWithArchive;
exports.getMetricsWithEvent = getMetricsWithEvent;
exports.getPropertyPinnedValueFromEvent = getPropertyPinnedValueFromEvent;
exports.getPropertyById = getPropertyById;
exports.getPropertyByIdFromArchive = getPropertyByIdFromArchive;
exports.getModelPropertyById = getModelPropertyById;
exports.getPropertyRefById = getPropertyRefById;
exports.getPropertyByName = getPropertyByName;
exports.getPropertyId = getPropertyId;
exports.getNestedPropertyRefById = getNestedPropertyRefById;
exports.resolvePropertyById = resolvePropertyById;
exports.resolvePropertyByIdFromArchive = resolvePropertyByIdFromArchive;
exports.resolveProperty = resolveProperty;
exports.resolvePropertyRef = resolvePropertyRef;
exports.getPropertyParents = getPropertyParents;
exports.getObjectPropertyChildren = getObjectPropertyChildren;
exports.getArchivedPropertyById = getArchivedPropertyById;
exports.getPropertyByIdWithArchive = getPropertyByIdWithArchive;
exports.getPropertyNameById = getPropertyNameById;
exports.getPropertyGroupById = getPropertyGroupById;
exports.getPropertyGroupByName = getPropertyGroupByName;
exports.getPropertyGroupByIdWithArchive = getPropertyGroupByIdWithArchive;
exports.getPropertyGroupNamespace = getPropertyGroupNamespace;
exports.getPropertyNameMappings = getPropertyNameMappings;
exports.getPropertyIdFromNameMappingName = getPropertyIdFromNameMappingName;
exports.getPropertyByNameOrNameMapping = getPropertyByNameOrNameMapping;
exports.getEventPossibleNames = getEventPossibleNames;
exports.getRuleById = getRuleById;
exports.getAllTags = getAllTags;
exports.getPropertyNamespaceWithIds = getPropertyNamespaceWithIds;
exports.getPropertyNamespace = getPropertyNamespace;
exports.getEventNamespaceWithIds = getEventNamespaceWithIds;
exports.getEventNamespace = getEventNamespace;
exports.updateSource = updateSource;
exports.updateDestination = updateDestination;
exports.updateGoal = updateGoal;
exports.updateMetric = updateMetric;
exports.updatePropertyGroup = updatePropertyGroup;
exports.updateIntegration = updateIntegration;
exports.getIntegrationById = getIntegrationById;
exports.getIntegrationConfigById = getIntegrationConfigById;
exports.resolvePropertyGroupRefs = resolvePropertyGroupRefs;
exports.resolvePropertyGroupRefs_mappedModel = resolvePropertyGroupRefs_mappedModel;
exports.getSourcesEvents = getSourcesEvents;
exports.getSourcesEvents_mappedModel = getSourcesEvents_mappedModel;
exports.getSourceEvents = getSourceEvents;
exports.getSourceEvents_mappedModel = getSourceEvents_mappedModel;
exports.eventsSendingPropertyFromSources = eventsSendingPropertyFromSources;
exports.eventsSendingPropertyFromSources_mappedModel = eventsSendingPropertyFromSources_mappedModel;
exports.eventsSendingProperty = eventsSendingProperty;
exports.eventsSendingProperty_mappedModel = eventsSendingProperty_mappedModel;
exports.eventsSendingGroupType = eventsSendingGroupType;
exports.getIncludedSourceIds = getIncludedSourceIds;
exports.isPropertySentFromSource = isPropertySentFromSource;
exports.isPropertySentToDestination = isPropertySentToDestination;
exports.getEventSourceIdsByEventId = getEventSourceIdsByEventId;
exports.getEventSourceIdsByEventId_mappedModel = getEventSourceIdsByEventId_mappedModel;
exports.isEventSentToDestination = isEventSentToDestination;
exports.eventsWithNameMapping = eventsWithNameMapping;
exports.getAllDestinationsOfType = getAllDestinationsOfType;
exports.getAllDestinationsIdsOfType = getAllDestinationsIdsOfType;
exports.getPropertyNameMappingForDestinationType = getPropertyNameMappingForDestinationType;
exports.getEventNameMappingForDestinationType = getEventNameMappingForDestinationType;
exports.makePropertyAbsenceFromOptionalAndExcludedSources = makePropertyAbsenceFromOptionalAndExcludedSources;
exports.makePropertyAbsenceFromOptionalAndExcludedSources_mappedModel = makePropertyAbsenceFromOptionalAndExcludedSources_mappedModel;
exports.isPropertyIncludedOnEventAndSources = isPropertyIncludedOnEventAndSources;
exports.legacyIsPropertyIncludedOnEventAndSources = legacyIsPropertyIncludedOnEventAndSources;
exports.isPropertyIncludedOnEventAndSource = isPropertyIncludedOnEventAndSource;
exports.isPropertyOptionalOnEventsAndSources = isPropertyOptionalOnEventsAndSources;
exports.isPropertyOptionalOnEventAndSource = isPropertyOptionalOnEventAndSource;
exports.isPropertyOptionalOnEvent = isPropertyOptionalOnEvent;
exports.isPropertyOptionalOnSource = isPropertyOptionalOnSource;
exports.isPropertyOptionalSomewhere = isPropertyOptionalSomewhere;
exports.propertyExcludedSources = propertyExcludedSources;
exports.allPropertyOptionalitiesForSource = allPropertyOptionalitiesForSource;
exports.allPropertyOptionalities = allPropertyOptionalities;
exports.isPropertyGroupConsistentAcrossAllEvents = isPropertyGroupConsistentAcrossAllEvents;
exports.isPropertyBundleConsistentAcrossAllEventsWithCache = isPropertyBundleConsistentAcrossAllEventsWithCache;
exports.getDefaultDestinationsForSource = getDefaultDestinationsForSource;
exports.getModelEventsByName = getModelEventsByName;
exports.getGroupType = getGroupType;
exports.getGroupTypeWithArchive = getGroupTypeWithArchive;
exports.getArchivedGroupType = getArchivedGroupType;
exports.getGroupTypeName = getGroupTypeName;
exports.getGroupTypeNameWithArchive = getGroupTypeNameWithArchive;
exports.getGroupTypeNames = getGroupTypeNames;
exports.updateGroupType = updateGroupType;
exports.getGroupTypeById = getGroupTypeById;
exports.getGroupTypeByIdWithArchive = getGroupTypeByIdWithArchive;
exports.getGroupTypeNameWithArchiveWithDefaultGroupTypeId = getGroupTypeNameWithArchiveWithDefaultGroupTypeId;
exports.getGroupTypeNameWithArchiveWithDefaultDeletedName = getGroupTypeNameWithArchiveWithDefaultDeletedName;
exports.getGroupTypeDescription = getGroupTypeDescription;
exports.getSourceDestinationIds = getSourceDestinationIds;
exports.getSourceDestinationRef = getSourceDestinationRef;
exports.getEventNames = getEventNames;
exports.getPropertyNames = getPropertyNames;
exports.isDestinationInterfaceEnabledForSourceDestination = isDestinationInterfaceEnabledForSourceDestination;
exports.isEventIncludedInSource = isEventIncludedInSource;
exports.getEventActions = getEventActions;
exports.getSourcesToAttachedEventsActions = getSourcesToAttachedEventsActions;
exports.getNameMappingNameAndDestination = getNameMappingNameAndDestination;
exports.getNameMappingsForPropertyOnEvent = getNameMappingsForPropertyOnEvent;
/* TrackingPlanModel Not a pure module */
