// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var KeyListener = require("./KeyListener.bs.js");

var modalStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.justifyContent("stretch"),
            tl: {
              hd: Css.maxHeight(Css.pct(90)),
              tl: {
                hd: Css.boxShadow(Styles.Shadow.standard),
                tl: {
                  hd: Css.borderRadius(Css.px(10)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.width(Css.px(400)),
                        tl: {
                          hd: Css.padding(Css.px(48)),
                          tl: {
                            hd: Css.paddingTop(Css.px(72)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var buttonStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("flexEnd"),
        tl: {
          hd: Css.flexWrap("wrap"),
          tl: {
            hd: Css.gap(Css.px(16)),
            tl: /* [] */0
          }
        }
      }
    });

function AlertModal(Props) {
  var title = Props.title;
  var message = Props.message;
  var ctaText = Props.ctaText;
  var onConfirm = Props.onConfirm;
  var onClose = Props.onClose;
  return React.createElement("div", {
              className: modalStyles
            }, React.createElement(KeyListener.make, {
                  keyName: "Enter",
                  onPress: (function (e) {
                      e.preventDefault();
                      Curry._1(onConfirm, undefined);
                      return Curry._1(onClose, undefined);
                    })
                }), React.createElement($$Text.make, {
                  element: "H1",
                  size: "Huge",
                  color: Styles.Color.grey80,
                  children: title
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  color: Styles.Color.grey80,
                  children: message
                }), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement("div", {
                  className: buttonStyles
                }, React.createElement(Button.make, {
                      label: ctaText,
                      onClick: (function (param) {
                          Curry._1(onConfirm, undefined);
                          return Curry._1(onClose, undefined);
                        }),
                      size: "large"
                    })));
}

var make = AlertModal;

exports.modalStyles = modalStyles;
exports.buttonStyles = buttonStyles;
exports.make = make;
/* modalStyles Not a pure module */
