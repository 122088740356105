// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Router = require("./Router.bs.js");
var $$Comment = require("./Comment.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var ObjectActivityLogAction = require("./ObjectActivityLogAction.bs.js");

function PropertyGroupActivityItem(Props) {
  var schema = Props.schema;
  var $$event = Props.event;
  var group = Props.group;
  var events = Props.events;
  var model = Props.model;
  var action = Props.action;
  var userId = Props.userId;
  var userIds = Props.userIds;
  var goToId = Props.goToId;
  var openBranches = Props.openBranches;
  var showComments = Props.showComments;
  var users = FirebaseFetcherHooks.useUsers(userIds);
  if (typeof users !== "object") {
    return null;
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var match = action.contents;
  if (typeof match !== "object") {
    return null;
  }
  var variant = match.NAME;
  if (variant === "CreateDemoBranch") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "created the bundle on a demo branch"
              });
  }
  if (variant === "PropertyGroupComment") {
    if (!showComments) {
      return null;
    }
    var match$1 = match.VAL;
    var groupId = match$1[0];
    var tmp = {
      schema: schema,
      threadId: groupId,
      itemType: "PropertyGroup",
      itemName: group.name,
      commentId: match$1[1],
      events: events,
      userId: userId,
      goToId: goToId,
      action: action,
      link: (function (commentId) {
          return Router.Link.addDrawerItem(undefined, {
                      NAME: "propertyGroup",
                      VAL: [
                        groupId,
                        commentId
                      ]
                    });
        }),
      openBranches: openBranches
    };
    var tmp$1 = Belt_Option.map($$event, (function ($$event) {
            return $$event.id;
          }));
    if (tmp$1 !== undefined) {
      tmp.eventId = Caml_option.valFromOption(tmp$1);
    }
    return React.createElement($$Comment.make, tmp);
  }
  if (variant === "Archive") {
    var match$2 = match.VAL;
    if (typeof match$2 === "object" && match$2.NAME === "PropertyGroup") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "archived the property bundle"
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdatePropertyGroupDescription") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "updated the description"
              });
  }
  if (variant === "RemovePropertyFromGroup") {
    var match$3 = StateUtils.getPropertyByIdWithArchive(match.VAL[1], model);
    if (match$3 !== undefined && match$3.TAG !== /* PropertyRef */0) {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: React.createElement(React.Fragment, undefined, "removed the property", React.createElement("b", undefined, match$3._0.name), "from the bundle")
                });
    } else {
      return null;
    }
  }
  if (variant === "CreatePropertyGroup") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: React.createElement(React.Fragment, undefined, "created the bundle")
              });
  }
  if (variant === "UpdatePropertyGroupName") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: React.createElement(React.Fragment, undefined, "updated the name to", React.createElement("b", undefined, match.VAL[1]))
              });
  }
  if (variant === "RemovePropertyGroupFromEvent") {
    var $$event$1 = StateUtils.getEventByIdWithArchive(match.VAL[0], model);
    if ($$event$1 !== undefined) {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: React.createElement(React.Fragment, undefined, "removed bundle from the event", React.createElement("b", undefined, $$event$1.name))
                });
    } else {
      return null;
    }
  }
  if (variant === "AddPropertyToGroup") {
    var match$4 = StateUtils.getPropertyByIdWithArchive(match.VAL[1], model);
    if (match$4 !== undefined && match$4.TAG !== /* PropertyRef */0) {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: React.createElement(React.Fragment, undefined, "added the property", React.createElement("b", undefined, match$4._0.name), "to the bundle")
                });
    } else {
      return null;
    }
  }
  if (variant === "Unarchive") {
    var match$5 = match.VAL;
    if (typeof match$5 === "object" && match$5.NAME === "PropertyGroup") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "unarchived the property bundle"
                });
    } else {
      return null;
    }
  }
  if (variant === "CompletedImport") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "imported the bundle"
              });
  }
  if (variant !== "AddPropertyGroupToEvent") {
    return null;
  }
  var $$event$2 = StateUtils.getEventByIdWithArchive(match.VAL[0], model);
  if ($$event$2 !== undefined) {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: React.createElement(React.Fragment, undefined, "added bundle to the event", React.createElement("b", undefined, $$event$2.name))
              });
  } else {
    return null;
  }
}

var make = PropertyGroupActivityItem;

exports.make = make;
/* react Not a pure module */
