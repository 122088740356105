// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Caml = require("rescript/lib/js/caml.js");
var Dopt = require("./externals/Dopt.bs.js");
var $$Text = require("./Text.bs.js");
var Case = require("case");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var $$Number = require("./Number.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var $$Window = require("./externals/window.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Mantine = require("./Mantine.bs.js");
var Shortid = require("shortid");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Markdown = require("./Markdown.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var StringExt = require("./StringExt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var TextButton = require("./TextButton.bs.js");
var React$1 = require("@dopt/react");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core = require("@mantine/core");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SortArray = require("rescript/lib/js/belt_SortArray.js");
var ReactConfetti = require("react-confetti");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var ReactRichText = require("@dopt/react-rich-text");

var defaultContext_blocks = [];

function defaultContext_getStepNumber(param) {
  
}

function defaultContext_onComplete(param) {
  
}

var defaultContext = {
  blocks: defaultContext_blocks,
  getStepNumber: defaultContext_getStepNumber,
  onComplete: defaultContext_onComplete,
  resetOnExit: false,
  showStepCount: true,
  totalSteps: 0,
  tourId: "",
  tourMayShow: false
};

var context = React.createContext(defaultContext);

var provider = context.Provider;

function use(param) {
  return React.useContext(context);
}

function ProductTour$TourProvider(Props) {
  var blocks = Props.blocks;
  var getStepNumber = Props.getStepNumber;
  var onComplete = Props.onComplete;
  var resetOnExit = Props.resetOnExit;
  var showStepCountOpt = Props.showStepCount;
  var tourId = Props.tourId;
  var tourMayShow = Props.tourMayShow;
  var children = Props.children;
  var showStepCount = showStepCountOpt !== undefined ? showStepCountOpt : false;
  return React.createElement(provider, {
              value: {
                blocks: blocks,
                getStepNumber: getStepNumber,
                onComplete: onComplete,
                resetOnExit: resetOnExit,
                showStepCount: showStepCount,
                totalSteps: React.Children.count(children),
                tourId: tourId,
                tourMayShow: tourMayShow
              },
              children: children
            });
}

var TourProvider = {
  defaultContext: defaultContext,
  context: context,
  provider: provider,
  use: use,
  make: ProductTour$TourProvider
};

var defaultContext_block = Dopt.Block.emptyBlock;

function defaultContext_onComplete$1(param) {
  
}

function defaultContext_transition(param) {
  
}

var defaultContext_transitions = [];

var defaultContext$1 = {
  advanceByCTA: true,
  block: defaultContext_block,
  intent: undefined,
  onComplete: defaultContext_onComplete$1,
  stepNumber: 0,
  transition: defaultContext_transition,
  transitions: defaultContext_transitions
};

var context$1 = React.createContext(defaultContext$1);

var provider$1 = context$1.Provider;

function use$1(param) {
  return React.useContext(context$1);
}

function ProductTour$StepProvider(Props) {
  var advanceByCTA = Props.advanceByCTA;
  var block = Props.block;
  var intent = Props.intent;
  var onComplete = Props.onComplete;
  var stepNumber = Props.stepNumber;
  var transition = Props.transition;
  var transitions = Props.transitions;
  var children = Props.children;
  return React.createElement(provider$1, {
              value: {
                advanceByCTA: advanceByCTA,
                block: block,
                intent: intent,
                onComplete: onComplete,
                stepNumber: stepNumber,
                transition: transition,
                transitions: transitions
              },
              children: children
            });
}

var StepProvider = {
  defaultContext: defaultContext$1,
  context: context$1,
  provider: provider$1,
  use: use$1,
  make: ProductTour$StepProvider
};

function useBlockHasTransitioned(flow, block) {
  var match = React.useContext(context);
  var getStepNumber = match.getStepNumber;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var hasTransitioned = React.useMemo((function () {
          return Belt_Array.some(Belt_Array.keep(Object.keys(block.transitioned), (function (key) {
                            if (key !== "prev") {
                              return key !== "previous";
                            } else {
                              return false;
                            }
                          })), (function (key) {
                        return Caml_obj.caml_equal(Js_dict.get(block.transitioned, key), true);
                      }));
        }), [block.transitioned]);
  var previousHasTransitioned = Hooks.usePrevious1(hasTransitioned);
  React.useEffect((function () {
          if (previousHasTransitioned !== hasTransitioned && hasTransitioned) {
            AnalyticsRe.productFlowStepCompleted(AnalyticsRe.Group.productFlows(Case.title(flow.uid), flow.uid, String(flow.version)), schemaGroup, block.sid.replace(flow.uid + ".", ""), Belt_Option.getWithDefault(Curry._1(getStepNumber, block.sid.replace(flow.uid + ".", "")), 0), schemaGroup.schemaId);
          }
          
        }), [hasTransitioned]);
  
}

function useFlowStarted(flow, tourMayShow) {
  var schemaGroup = SchemaGroupContext.use(undefined);
  var tourStartedEventSent = React.useRef(false);
  var tourIsActive = React.useMemo((function () {
          if (Belt_Array.someU(flow.blocks, (function (block) {
                    return block.state.active;
                  }))) {
            return tourMayShow;
          } else {
            return false;
          }
        }), [
        flow.blocks,
        tourMayShow
      ]);
  React.useEffect((function () {
          if (!tourStartedEventSent.current && tourIsActive) {
            tourStartedEventSent.current = true;
            AnalyticsRe.productFlowStarted(AnalyticsRe.Group.productFlows(Case.title(flow.uid), flow.uid, String(flow.version)), schemaGroup, schemaGroup.schemaId);
          }
          
        }), [flow]);
  
}

function useFlowExited(flow, idMap) {
  var schemaGroup = SchemaGroupContext.use(undefined);
  var tourEnded = Hooks.usePrevious1(flow.state.stopped);
  var activeStep = React.useMemo((function () {
          return Belt_Array.getBy(flow.blocks, (function (param) {
                        return param.state.active;
                      }));
        }), [flow.blocks]);
  var activeStepNumber = React.useMemo((function () {
          return Belt_Option.flatMap(activeStep, (function (param) {
                        return Belt_MapString.get(idMap, param.sid.replace(flow.uid + ".", ""));
                      }));
        }), [activeStep]);
  var match = React.useState(function () {
        return [
                activeStep,
                activeStepNumber
              ];
      });
  var setLastActiveStepAttributes = match[1];
  var match$1 = match[0];
  var lastActiveStepNumber = match$1[1];
  var lastActiveStep = match$1[0];
  React.useEffect((function () {
          if (Belt_Option.isSome(activeStep)) {
            Curry._1(setLastActiveStepAttributes, (function (param) {
                    return [
                            activeStep,
                            activeStepNumber
                          ];
                  }));
          }
          
        }), [activeStep]);
  React.useEffect((function () {
          if (lastActiveStep !== undefined && lastActiveStepNumber !== undefined && !tourEnded && flow.state.stopped) {
            AnalyticsRe.productFlowExited(schemaGroup, AnalyticsRe.Group.productFlows(Case.title(flow.uid), flow.uid, String(flow.version)), lastActiveStep.sid.replace(flow.uid + ".", ""), lastActiveStepNumber, schemaGroup.schemaId);
          }
          
        }), [flow.state.stopped]);
  
}

function useFlowCompleted(flow) {
  var schemaGroup = SchemaGroupContext.use(undefined);
  var previousCompleted = Hooks.usePrevious1(flow.state.finished);
  React.useEffect((function () {
          if (!previousCompleted && flow.state.finished) {
            AnalyticsRe.productFlowCompleted(AnalyticsRe.Group.productFlows(Case.title(flow.uid), flow.uid, String(flow.version)), schemaGroup, schemaGroup.schemaId);
          }
          
        }), [flow.state.finished]);
  
}

var AnalyticsHooks = {
  useBlockHasTransitioned: useBlockHasTransitioned,
  useFlowStarted: useFlowStarted,
  useFlowExited: useFlowExited,
  useFlowCompleted: useFlowCompleted
};

var circle = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.left(Css.pct(50.0)),
        tl: {
          hd: Css.top(Css.pct(50.0)),
          tl: {
            hd: Css.transforms({
                  hd: Css.translateX(Css.pct(-50.0)),
                  tl: {
                    hd: Css.translateY(Css.pct(-50.0)),
                    tl: /* [] */0
                  }
                }),
            tl: {
              hd: Css.width(Css.px(10)),
              tl: {
                hd: Css.height(Css.px(10)),
                tl: {
                  hd: Css.before({
                        hd: Css.unsafe("content", "''"),
                        tl: {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.display("block"),
                            tl: {
                              hd: Css.width(Css.pct(600.0)),
                              tl: {
                                hd: Css.height(Css.pct(600.0)),
                                tl: {
                                  hd: Css.boxSizing("borderBox"),
                                  tl: {
                                    hd: Css.marginLeft(Css.pct(-250.0)),
                                    tl: {
                                      hd: Css.marginTop(Css.pct(-250.0)),
                                      tl: {
                                        hd: Css.borderRadius(Css.px(45)),
                                        tl: {
                                          hd: Css.backgroundColor(Styles.Color.magenta120),
                                          tl: {
                                            hd: Css.animation({
                                                  NAME: "ms",
                                                  VAL: 2000.0
                                                }, undefined, undefined, {
                                                  NAME: "cubicBezier",
                                                  VAL: [
                                                    0.215,
                                                    0.61,
                                                    0.355,
                                                    1.0
                                                  ]
                                                }, undefined, undefined, "infinite", Curry._1(Css.keyframes, {
                                                      hd: [
                                                        0,
                                                        {
                                                          hd: Css.transform(Css.scale(0.33, 0.33)),
                                                          tl: /* [] */0
                                                        }
                                                      ],
                                                      tl: {
                                                        hd: [
                                                          80,
                                                          {
                                                            hd: Css.opacity(0.0),
                                                            tl: /* [] */0
                                                          }
                                                        ],
                                                        tl: {
                                                          hd: [
                                                            100,
                                                            {
                                                              hd: Css.opacity(0.0),
                                                              tl: /* [] */0
                                                            }
                                                          ],
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    })),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  tl: {
                    hd: Css.after({
                          hd: Css.unsafe("content", "''"),
                          tl: {
                            hd: Css.position("absolute"),
                            tl: {
                              hd: Css.left("zero"),
                              tl: {
                                hd: Css.top("zero"),
                                tl: {
                                  hd: Css.display("block"),
                                  tl: {
                                    hd: Css.width(Css.pct(100.0)),
                                    tl: {
                                      hd: Css.height(Css.pct(100.0)),
                                      tl: {
                                        hd: Css.backgroundColor("transparent"),
                                        tl: {
                                          hd: Css.borderRadius(Css.px(15)),
                                          tl: {
                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(10), undefined, undefined, Css.rgba(0, 0, 0, {
                                                          NAME: "num",
                                                          VAL: 0.8
                                                        }))),
                                            tl: {
                                              hd: Css.animation({
                                                    NAME: "ms",
                                                    VAL: 2000.0
                                                  }, {
                                                    NAME: "ms",
                                                    VAL: -400.0
                                                  }, undefined, {
                                                    NAME: "cubicBezier",
                                                    VAL: [
                                                      0.455,
                                                      0.03,
                                                      0.515,
                                                      0.955
                                                    ]
                                                  }, undefined, undefined, "infinite", Curry._1(Css.keyframes, {
                                                        hd: [
                                                          0,
                                                          {
                                                            hd: Css.transform(Css.scale(0.7, 0.7)),
                                                            tl: /* [] */0
                                                          }
                                                        ],
                                                        tl: {
                                                          hd: [
                                                            50,
                                                            {
                                                              hd: Css.transform(Css.scale(1.0, 1.0)),
                                                              tl: {
                                                                hd: Css.backgroundColor(Styles.Color.magenta100),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          ],
                                                          tl: {
                                                            hd: [
                                                              100,
                                                              {
                                                                hd: Css.transform(Css.scale(0.7, 0.7)),
                                                                tl: /* [] */0
                                                              }
                                                            ],
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      })),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function ProductTour$ClickSimulator(Props) {
  var posOpt = Props.pos;
  var pos = posOpt !== undefined ? posOpt : "center";
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.pointerEvents("none"),
                    tl: {
                      hd: Css.position("absolute"),
                      tl: {
                        hd: Css.width(Css.pct(100.0)),
                        tl: {
                          hd: Css.height(Css.pct(100.0)),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("flexStart"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.pct(50.0)),
                          tl: {
                            hd: Css.left(typeof pos === "object" ? pos.VAL : Css.pct(50.0)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: circle
                    })));
}

var ClickSimulator = {
  circle: circle,
  make: ProductTour$ClickSimulator
};

function getPulseKeyframeStyle(step) {
  var color = Styles.Color.magenta120;
  var alpha0 = Styles.Color.setAlpha(color, 0.7);
  if (step === 1) {
    return {
            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(24), undefined, undefined, alpha0)),
            tl: /* [] */0
          };
  } else if (step === 2) {
    return {
            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(5), undefined, undefined, alpha0)),
            tl: /* [] */0
          };
  } else {
    return {
            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(5), undefined, undefined, color)),
            tl: /* [] */0
          };
  }
}

function buttonWrapper(opened) {
  return Curry._1(Css.style, {
              hd: Css.transition({
                    NAME: "ms",
                    VAL: 300.0
                  }, undefined, undefined, "outline"),
              tl: {
                hd: opened ? Css.outline(Css.px(1), "solid", Styles.Color.magenta100) : Styles.emptyStyle,
                tl: {
                  hd: opened ? Css.animation({
                          NAME: "ms",
                          VAL: 3000.0
                        }, undefined, undefined, "easeInOut", "forwards", undefined, "infinite", Curry._1(Css.keyframes, {
                              hd: [
                                0,
                                getPulseKeyframeStyle(0)
                              ],
                              tl: {
                                hd: [
                                  70,
                                  getPulseKeyframeStyle(1)
                                ],
                                tl: {
                                  hd: [
                                    100,
                                    getPulseKeyframeStyle(2)
                                  ],
                                  tl: /* [] */0
                                }
                              }
                            })) : Styles.emptyStyle,
                  tl: /* [] */0
                }
              }
            });
}

function targetWrapperStyles(elementClickable, leftFloat, topFloat, widthFloat, heightFloat, elementPaddingFloat) {
  return Curry._1(Css.style, {
              hd: Css.position("fixed"),
              tl: {
                hd: Css.transition({
                      NAME: "ms",
                      VAL: 150.0
                    }, undefined, undefined, "all"),
                tl: {
                  hd: elementClickable ? Css.pointerEvents("none") : Styles.emptyStyle,
                  tl: {
                    hd: Css.left(Css.pxFloat(leftFloat - elementPaddingFloat)),
                    tl: {
                      hd: Css.top(Css.pxFloat(topFloat - elementPaddingFloat)),
                      tl: {
                        hd: Css.width(Css.pxFloat(widthFloat + elementPaddingFloat * 2.0)),
                        tl: {
                          hd: Css.height(Css.pxFloat(heightFloat + elementPaddingFloat * 2.0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

var dropdownAdditionalStyles_0 = Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(8), Css.px(32), undefined, undefined, Styles.Color.setAlpha(Styles.Color.grey100, 0.2)));

var dropdownAdditionalStyles = {
  hd: dropdownAdditionalStyles_0,
  tl: /* [] */0
};

var stepWrapperStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.left(Css.px(-16)),
        tl: {
          hd: Css.top(Css.px(-22)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.gap(Css.px(4)),
              tl: {
                hd: Css.alignItems("center"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var durationWrapperStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(-12)),
        tl: {
          hd: Css.top(Css.px(-20)),
          tl: /* [] */0
        }
      }
    });

var itemStyles = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.grey100, 0.4)),
        tl: /* [] */0
      }
    });

function aboveStyles(topFloat, elementPaddingFloat) {
  return Curry._1(Css.merge, {
              hd: itemStyles,
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.top(Css.px(0)),
                        tl: {
                          hd: Css.width(Css.vw(100.0)),
                          tl: {
                            hd: Css.height(Css.pxFloat(topFloat - elementPaddingFloat)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function leftStyles(topFloat, leftFloat, heightFloat, elementPaddingFloat) {
  return Curry._1(Css.merge, {
              hd: itemStyles,
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.top(Css.pxFloat(topFloat - elementPaddingFloat)),
                        tl: {
                          hd: Css.width(Css.pxFloat(leftFloat - elementPaddingFloat)),
                          tl: {
                            hd: Css.height(Css.pxFloat(heightFloat + elementPaddingFloat * 2.0)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function rightStyles(rightFloat, topFloat, heightFloat, elementPaddingFloat) {
  return Curry._1(Css.merge, {
              hd: itemStyles,
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.left(Css.pxFloat(rightFloat + elementPaddingFloat)),
                      tl: {
                        hd: Css.top(Css.pxFloat(topFloat - elementPaddingFloat)),
                        tl: {
                          hd: Css.width({
                                NAME: "substract",
                                VAL: [
                                  Css.vw(100.0),
                                  Css.pxFloat(rightFloat + elementPaddingFloat)
                                ]
                              }),
                          tl: {
                            hd: Css.height(Css.pxFloat(heightFloat + elementPaddingFloat * 2.0)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function bottomStyles(bottomFloat, elementPaddingFloat) {
  return Curry._1(Css.merge, {
              hd: itemStyles,
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.top(Css.pxFloat(bottomFloat + elementPaddingFloat)),
                        tl: {
                          hd: Css.width(Css.vw(100.0)),
                          tl: {
                            hd: Css.height({
                                  NAME: "substract",
                                  VAL: [
                                    Css.vh(100.0),
                                    Css.pxFloat(bottomFloat - elementPaddingFloat)
                                  ]
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function ProductTour$OnElement$Surrounder(Props) {
  var bottomFloat = Props.bottomFloat;
  var delayedOpened = Props.delayedOpened;
  var elementPaddingFloat = Props.elementPaddingFloat;
  var heightFloat = Props.heightFloat;
  var leftFloat = Props.leftFloat;
  var lockBackground = Props.lockBackground;
  var rightFloat = Props.rightFloat;
  var topFloat = Props.topFloat;
  return React.createElement(Mantine.Transition.make, {
              transition: "fade",
              mounted: lockBackground && delayedOpened,
              duration: 350,
              children: (function (style) {
                  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                                  className: aboveStyles(topFloat, elementPaddingFloat),
                                  style: style
                                }), React.createElement("div", {
                                  className: leftStyles(topFloat, leftFloat, heightFloat, elementPaddingFloat),
                                  style: style
                                }), React.createElement("div", {
                                  className: rightStyles(rightFloat, topFloat, heightFloat, elementPaddingFloat),
                                  style: style
                                }), React.createElement("div", {
                                  className: bottomStyles(bottomFloat, elementPaddingFloat),
                                  style: style
                                }));
                })
            });
}

var Surrounder = {
  itemStyles: itemStyles,
  aboveStyles: aboveStyles,
  leftStyles: leftStyles,
  rightStyles: rightStyles,
  bottomStyles: bottomStyles,
  make: ProductTour$OnElement$Surrounder
};

function useElementFound(forId, waitForId, opened) {
  var match = React.useState(function () {
        return $$Window.$$Document.getElementById(forId);
      });
  var setElement = match[1];
  var element = match[0];
  var match$1 = React.useState(function () {
        return Belt_Option.mapWithDefault(waitForId, true, (function (id) {
                      return Belt_Option.isSome($$Window.$$Document.getElementById(id));
                    }));
      });
  var setWaitForElementFound = match$1[1];
  var waitForElementFound = match$1[0];
  var match$2 = React.useState(function () {
        return 0;
      });
  var setWaitForElementFinderCounter = match$2[1];
  var waitForElementFinderCounter = match$2[0];
  var match$3 = React.useState(function () {
        return 0;
      });
  var setElementFinderCounter = match$3[1];
  var elementFinderCounter = match$3[0];
  React.useEffect((function () {
          var timeout = {
            contents: undefined
          };
          var handleFindElement = function (param) {
            Curry._1(setWaitForElementFinderCounter, (function (param) {
                    return waitForElementFinderCounter + 1 | 0;
                  }));
            return Curry._1(setWaitForElementFound, (function (param) {
                          return Belt_Option.mapWithDefault(waitForId, true, (function (id) {
                                        return Belt_Option.isSome($$Window.$$Document.getElementById(id));
                                      }));
                        }));
          };
          if (!waitForElementFound && opened) {
            timeout.contents = Caml_option.some(setTimeout(handleFindElement, 200));
          }
          return (function (param) {
                    return Belt_Option.forEach(timeout.contents, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), [
        opened,
        waitForElementFound,
        waitForElementFinderCounter
      ]);
  React.useEffect((function () {
          var timeout = {
            contents: undefined
          };
          var handleFindElement = function (param) {
            Curry._1(setElementFinderCounter, (function (param) {
                    return elementFinderCounter + 1 | 0;
                  }));
            return Curry._1(setElement, (function (param) {
                          return $$Window.$$Document.getElementById(forId);
                        }));
          };
          if (Belt_Option.isNone(element) && opened) {
            timeout.contents = Caml_option.some(setTimeout(handleFindElement, 200));
          }
          return (function (param) {
                    return Belt_Option.forEach(timeout.contents, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), [
        element,
        opened,
        elementFinderCounter
      ]);
  return {
          element: element,
          elementFound: waitForElementFound
        };
}

function useHandleClickOnElement(delayedOpened, opened, forId, onClick) {
  React.useEffect((function () {
          var handleClick = function (e) {
            var clientX = e.clientX;
            var clientY = e.clientY;
            var el = $$Window.$$Document.getElementById(forId);
            if (el === undefined) {
              return ;
            }
            var rect = Caml_option.valFromOption(el).getBoundingClientRect();
            if (clientX >= rect.left && clientX <= rect.right && clientY >= rect.top && clientY <= rect.bottom) {
              return Belt_Option.forEach(onClick, (function (fn) {
                            return Curry._1(fn, undefined);
                          }));
            }
            
          };
          if (opened && Belt_Option.isSome(onClick)) {
            window.addEventListener("click", handleClick);
          }
          return (function (param) {
                    window.removeEventListener("click", handleClick);
                    
                  });
        }), [delayedOpened]);
  
}

function findScrollingParents(element) {
  var _found = [];
  var _element = element;
  while(true) {
    var element$1 = _element;
    var found = _found;
    if (element$1 === undefined) {
      return found;
    }
    var el = Caml_option.valFromOption(element$1);
    if (el.scrollHeight > el.clientHeight) {
      _element = Caml_option.nullable_to_opt(el.parentNode);
      _found = Belt_Array.concat(found, [el]);
      continue ;
    }
    _element = Caml_option.nullable_to_opt(el.parentNode);
    continue ;
  };
}

function useDelayedOpen(forId, waitForId, positionDependencies, opened, delay) {
  var match = React.useState(function () {
        return false;
      });
  var setDelayedOpened = match[1];
  var match$1 = useElementFound(forId, waitForId, opened);
  var elementFound = match$1.elementFound;
  var element = match$1.element;
  var match$2 = React.useState(function () {
        var el = $$Window.$$Document.getElementById(forId);
        if (el !== undefined) {
          return Caml_option.some(Caml_option.valFromOption(el).getBoundingClientRect());
        }
        
      });
  var setDomRect = match$2[1];
  var domRect = match$2[0];
  var match$3 = React.useState(function () {
        return [
                window.innerWidth,
                window.innerHeight
              ];
      });
  var setDimensions = match$3[1];
  var match$4 = match$3[0];
  var windowHeight = match$4[1];
  var windowWidth = match$4[0];
  React.useEffect((function () {
          var resizeHandler = function (param) {
            return Curry._1(setDimensions, (function (param) {
                          return [
                                  window.innerWidth,
                                  window.innerHeight
                                ];
                        }));
          };
          if (opened) {
            window.addEventListener("resize", resizeHandler);
          }
          return (function (param) {
                    window.removeEventListener("resize", resizeHandler);
                    
                  });
        }), [opened]);
  React.useEffect((function () {
          var scrollingParents = findScrollingParents(element);
          var scrollHandler = function (param) {
            return Curry._1(setDomRect, (function (param) {
                          var el = $$Window.$$Document.getElementById(forId);
                          if (el !== undefined) {
                            return Caml_option.some(Caml_option.valFromOption(el).getBoundingClientRect());
                          }
                          
                        }));
          };
          if (opened) {
            Belt_Array.forEach(scrollingParents, (function (scrollingParent) {
                    scrollingParent.addEventListener("scroll", scrollHandler);
                    
                  }));
          }
          return (function (param) {
                    return Belt_Array.forEach(scrollingParents, (function (scrollingParent) {
                                  scrollingParent.removeEventListener("scroll", scrollHandler);
                                  
                                }));
                  });
        }), [
        opened,
        elementFound
      ]);
  React.useEffect((function () {
          var timeout = setTimeout((function (param) {
                  Curry._1(setDomRect, (function (param) {
                          var el = $$Window.$$Document.getElementById(forId);
                          if (el !== undefined) {
                            return Caml_option.some(Caml_option.valFromOption(el).getBoundingClientRect());
                          }
                          
                        }));
                  return Curry._1(setDelayedOpened, (function (param) {
                                if (elementFound) {
                                  return opened;
                                } else {
                                  return false;
                                }
                              }));
                }), delay);
          return (function (param) {
                    clearTimeout(timeout);
                    
                  });
        }), [
        opened,
        delay,
        element,
        elementFound,
        windowWidth,
        windowHeight,
        positionDependencies
      ]);
  var positionDependencies$1 = React.useMemo((function () {
          return Belt_Array.concat([
                      String(Belt_Option.mapWithDefault(domRect, 0.0, (function (prim) {
                                  return prim.left;
                                }))),
                      String(Belt_Option.mapWithDefault(domRect, 0.0, (function (prim) {
                                  return prim.top;
                                }))),
                      String(Belt_Option.mapWithDefault(domRect, 0.0, (function (prim) {
                                  return prim.width;
                                }))),
                      String(Belt_Option.mapWithDefault(domRect, 0.0, (function (prim) {
                                  return prim.height;
                                })))
                    ], Belt_Option.getWithDefault(positionDependencies, []));
        }), [
        domRect,
        windowWidth,
        windowHeight
      ]);
  return {
          delayedOpened: match[0],
          positionDependencies: positionDependencies$1,
          domRect: domRect
        };
}

function ProductTour$OnElement(Props) {
  var arrowOffset = Props.arrowOffset;
  var delayOpt = Props.delay;
  var elementClickableOpt = Props.elementClickable;
  var elementPadding = Props.elementPadding;
  var forId = Props.forId;
  var lockBackgroundOpt = Props.lockBackground;
  var offset = Props.offset;
  var onClick = Props.onClick;
  var opened = Props.opened;
  var position = Props.position;
  var positionDependencies = Props.positionDependencies;
  var onStepActive = Props.onStepActive;
  var simulateClick = Props.simulateClick;
  var transition = Props.transition;
  var transitionDuration = Props.transitionDuration;
  var transitionTimingFunction = Props.transitionTimingFunction;
  var waitForId = Props.waitForId;
  var withOutlineOpt = Props.withOutline;
  var children = Props.children;
  var delay = delayOpt !== undefined ? delayOpt : 300;
  var elementClickable = elementClickableOpt !== undefined ? elementClickableOpt : true;
  var lockBackground = lockBackgroundOpt !== undefined ? lockBackgroundOpt : true;
  var withOutline = withOutlineOpt !== undefined ? withOutlineOpt : true;
  React.useEffect((function () {
          if (opened) {
            Belt_Option.forEach(onStepActive, (function (fn) {
                    return Curry._1(fn, undefined);
                  }));
          }
          
        }), []);
  var match = useDelayedOpen(forId, waitForId, positionDependencies, opened, delay);
  var domRect = match.domRect;
  var delayedOpened = match.delayedOpened;
  useHandleClickOnElement(delayedOpened, opened, forId, onClick);
  var elementPaddingFloat = Belt_Option.mapWithDefault(elementPadding, 0.0, (function (prim) {
          return prim;
        }));
  if (domRect === undefined) {
    return null;
  }
  var domRect$1 = Caml_option.valFromOption(domRect);
  var topFloat = domRect$1.top;
  var bottomFloat = domRect$1.bottom;
  var leftFloat = domRect$1.left;
  var rightFloat = domRect$1.right;
  var heightFloat = domRect$1.height;
  var widthFloat = domRect$1.width;
  if (!opened) {
    return null;
  }
  var tmp = {
    arrowSize: 10,
    opened: delayedOpened,
    positionDependencies: match.positionDependencies,
    withArrow: true,
    children: null
  };
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = Caml_option.valFromOption(arrowOffset);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (transition !== undefined) {
    tmp.transition = Caml_option.valFromOption(transition);
  }
  if (transitionDuration !== undefined) {
    tmp.transitionDuration = Caml_option.valFromOption(transitionDuration);
  }
  if (transitionTimingFunction !== undefined) {
    tmp.transitionTimingFunction = Caml_option.valFromOption(transitionTimingFunction);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(ProductTour$OnElement$Surrounder, {
                  bottomFloat: bottomFloat,
                  delayedOpened: delayedOpened,
                  elementPaddingFloat: elementPaddingFloat,
                  heightFloat: heightFloat,
                  leftFloat: leftFloat,
                  lockBackground: lockBackground,
                  rightFloat: rightFloat,
                  topFloat: topFloat
                }), React.createElement(Mantine.Popover.make, tmp, React.createElement(Core.Popover.Target, {
                      children: React.createElement("div", {
                            className: targetWrapperStyles(elementClickable, leftFloat, topFloat, widthFloat, heightFloat, elementPaddingFloat)
                          }, simulateClick !== undefined ? React.createElement(ProductTour$ClickSimulator, {
                                  pos: simulateClick
                                }) : null, React.createElement("div", {
                                className: Curry._1(Css.merge, {
                                      hd: withOutline ? buttonWrapper(delayedOpened) : Curry._1(Css.style, /* [] */0),
                                      tl: {
                                        hd: Curry._1(Css.style, {
                                              hd: Css.width(Css.pct(100.0)),
                                              tl: {
                                                hd: Css.height(Css.pct(100.0)),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    })
                              }))
                    }), React.createElement(Mantine.Popover.Dropdown.make, {
                      additionalStyles: dropdownAdditionalStyles,
                      children: children
                    })));
}

var OnElement = {
  getPulseKeyframeStyle: getPulseKeyframeStyle,
  buttonWrapper: buttonWrapper,
  targetWrapperStyles: targetWrapperStyles,
  dropdownAdditionalStyles: dropdownAdditionalStyles,
  stepWrapperStyles: stepWrapperStyles,
  durationWrapperStyles: durationWrapperStyles,
  Surrounder: Surrounder,
  useElementFound: useElementFound,
  useHandleClickOnElement: useHandleClickOnElement,
  findScrollingParents: findScrollingParents,
  useDelayedOpen: useDelayedOpen,
  make: ProductTour$OnElement
};

function ProductTour$Windowed(Props) {
  var arrowOffset = Props.arrowOffset;
  var delay = Props.delay;
  var elementClickable = Props.elementClickable;
  var customHeight = Props.height;
  var customLeft = Props.left;
  var lockBackground = Props.lockBackground;
  var onClick = Props.onClick;
  var offset = Props.offset;
  var opened = Props.opened;
  var position = Props.position;
  var positionDependencies = Props.positionDependencies;
  var onStepActive = Props.onStepActive;
  var simulateClick = Props.simulateClick;
  var customTop = Props.top;
  var transition = Props.transition;
  var transitionDuration = Props.transitionDuration;
  var waitForId = Props.waitForId;
  var customWidth = Props.width;
  var withOutline = Props.withOutline;
  var children = Props.children;
  var forId = React.useMemo((function () {
          return Shortid();
        }), []);
  var tmp = {
    forId: forId,
    opened: opened,
    children: children
  };
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = Caml_option.valFromOption(arrowOffset);
  }
  if (delay !== undefined) {
    tmp.delay = Caml_option.valFromOption(delay);
  }
  if (elementClickable !== undefined) {
    tmp.elementClickable = Caml_option.valFromOption(elementClickable);
  }
  if (lockBackground !== undefined) {
    tmp.lockBackground = Caml_option.valFromOption(lockBackground);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (positionDependencies !== undefined) {
    tmp.positionDependencies = Caml_option.valFromOption(positionDependencies);
  }
  if (onStepActive !== undefined) {
    tmp.onStepActive = Caml_option.valFromOption(onStepActive);
  }
  if (simulateClick !== undefined) {
    tmp.simulateClick = Caml_option.valFromOption(simulateClick);
  }
  if (transition !== undefined) {
    tmp.transition = Caml_option.valFromOption(transition);
  }
  if (transitionDuration !== undefined) {
    tmp.transitionDuration = Caml_option.valFromOption(transitionDuration);
  }
  if (waitForId !== undefined) {
    tmp.waitForId = Caml_option.valFromOption(waitForId);
  }
  if (withOutline !== undefined) {
    tmp.withOutline = Caml_option.valFromOption(withOutline);
  }
  return React.createElement(React.Fragment, undefined, opened ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Caml_obj.caml_equal(elementClickable, true) || elementClickable === undefined ? Css.pointerEvents("none") : Styles.emptyStyle,
                          tl: {
                            hd: Css.position("fixed"),
                            tl: {
                              hd: Css.left(customLeft),
                              tl: {
                                hd: Css.top(customTop),
                                tl: {
                                  hd: Css.width(customWidth),
                                  tl: {
                                    hd: Css.height(customHeight),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }),
                    id: forId
                  }) : null, React.createElement(ProductTour$OnElement, tmp));
}

var Windowed = {
  make: ProductTour$Windowed
};

function ProductTour$Modal(Props) {
  var closeOnClickOutside = Props.closeOnClickOutside;
  var delayOpt = Props.delay;
  var onStepActive = Props.onStepActive;
  var opened = Props.opened;
  var size = Props.size;
  var children = Props.children;
  var delay = delayOpt !== undefined ? delayOpt : 300;
  var match = React.useState(function () {
        return false;
      });
  var setDelayedOpened = match[1];
  var delayedOpened = match[0];
  React.useEffect((function () {
          var timeout = setTimeout((function (param) {
                  return Curry._1(setDelayedOpened, (function (param) {
                                return opened;
                              }));
                }), delay);
          return (function (param) {
                    clearTimeout(timeout);
                    
                  });
        }), [
        opened,
        delay
      ]);
  React.useEffect((function () {
          if (opened) {
            Belt_Option.forEach(onStepActive, (function (fn) {
                    return Curry._1(fn, undefined);
                  }));
          }
          
        }), [delayedOpened]);
  var tmp = {
    centered: true,
    opened: delayedOpened,
    overlayProps: {
      color: Styles.Color.toString(Styles.Color.grey70Bg)
    },
    radius: {
      NAME: "num",
      VAL: 10
    },
    shadow: Css_Legacy_Core.Shadow.toString(Styles.Shadow.standard),
    zIndex: Styles.ZIndex.aboveAll,
    children: children
  };
  if (closeOnClickOutside !== undefined) {
    tmp.closeOnClickOutside = Caml_option.valFromOption(closeOnClickOutside);
  }
  if (size !== undefined) {
    tmp.size = Caml_option.valFromOption(size);
  }
  return React.createElement(Mantine.Modal.make, tmp);
}

var Modal = {
  make: ProductTour$Modal
};

function ProductTour$Step(Props) {
  var stepId = Props.stepId;
  var stepType = Props.stepType;
  var arrowOffset = Props.arrowOffset;
  var delay = Props.delay;
  var elementClickable = Props.elementClickable;
  var elementPadding = Props.elementPadding;
  var lockBackground = Props.lockBackground;
  var offset = Props.offset;
  var onClick = Props.onClick;
  var onTransition = Props.onTransition;
  var onStepActive = Props.onStepActive;
  var position = Props.position;
  var positionDependencies = Props.positionDependencies;
  var simulateClick = Props.simulateClick;
  var transition = Props.transition;
  var transitions = Props.transitions;
  var transitionDuration = Props.transitionDuration;
  var waitForId = Props.waitForId;
  var withOutline = Props.withOutline;
  var children = Props.children;
  var match = React.useContext(context);
  var tourId = match.tourId;
  var onComplete = match.onComplete;
  var match$1 = React$1.useBlock(tourId + "." + stepId);
  var blockTransition = match$1[1];
  var block = match$1[0];
  var match$2 = React$1.useFlow(tourId);
  var intent = match$2[1];
  var opened = block.state.active && match.tourMayShow;
  var stepNumber = Belt_Option.getWithDefault(Curry._1(match.getStepNumber, stepId), 0);
  var transitionCallback = React.useCallback((function (transitionName) {
          Belt_Option.forEach(onTransition, (function (cb) {
                  return Curry._1(cb, transitionName);
                }));
          return Curry._1(blockTransition, transitionName);
        }), [
        blockTransition,
        onTransition
      ]);
  var onClick$1 = Belt_Option.map(onClick, (function (onClick, param) {
          return Curry._1(onClick, transitionCallback);
        }));
  useBlockHasTransitioned(match$2[0], block);
  var advanceByCTA = simulateClick !== undefined ? !(Caml_obj.caml_equal(elementClickable, true) || Belt_Option.isNone(elementClickable)) : true;
  if (typeof stepType === "number") {
    stepType === /* Disclaimer */0;
  } else {
    switch (stepType.TAG | 0) {
      case /* OnElement */0 :
          if (!opened) {
            return null;
          }
          var tmp = {
            forId: stepType._0,
            opened: opened,
            children: children
          };
          if (arrowOffset !== undefined) {
            tmp.arrowOffset = Caml_option.valFromOption(arrowOffset);
          }
          if (delay !== undefined) {
            tmp.delay = Caml_option.valFromOption(delay);
          }
          if (elementClickable !== undefined) {
            tmp.elementClickable = Caml_option.valFromOption(elementClickable);
          }
          if (elementPadding !== undefined) {
            tmp.elementPadding = Caml_option.valFromOption(elementPadding);
          }
          if (lockBackground !== undefined) {
            tmp.lockBackground = Caml_option.valFromOption(lockBackground);
          }
          if (offset !== undefined) {
            tmp.offset = Caml_option.valFromOption(offset);
          }
          if (onClick$1 !== undefined) {
            tmp.onClick = Caml_option.valFromOption(onClick$1);
          }
          if (position !== undefined) {
            tmp.position = Caml_option.valFromOption(position);
          }
          if (positionDependencies !== undefined) {
            tmp.positionDependencies = Caml_option.valFromOption(positionDependencies);
          }
          if (onStepActive !== undefined) {
            tmp.onStepActive = Caml_option.valFromOption(onStepActive);
          }
          if (simulateClick !== undefined) {
            tmp.simulateClick = Caml_option.valFromOption(simulateClick);
          }
          if (transition !== undefined) {
            tmp.transition = Caml_option.valFromOption(transition);
          }
          if (transitionDuration !== undefined) {
            tmp.transitionDuration = Caml_option.valFromOption(transitionDuration);
          }
          if (waitForId !== undefined) {
            tmp.waitForId = Caml_option.valFromOption(waitForId);
          }
          if (withOutline !== undefined) {
            tmp.withOutline = Caml_option.valFromOption(withOutline);
          }
          return React.createElement(ProductTour$StepProvider, {
                      advanceByCTA: advanceByCTA,
                      block: block,
                      intent: Caml_option.some(intent),
                      onComplete: onComplete,
                      stepNumber: stepNumber,
                      transition: transitionCallback,
                      transitions: transitions,
                      children: React.createElement(ProductTour$OnElement, tmp),
                      key: block.uid
                    });
      case /* Window */1 :
          if (!opened) {
            return null;
          }
          var match$3 = stepType._0;
          var tmp$1 = {
            height: match$3.height,
            left: match$3.left,
            opened: opened,
            top: match$3.top,
            width: match$3.width,
            children: children
          };
          if (arrowOffset !== undefined) {
            tmp$1.arrowOffset = Caml_option.valFromOption(arrowOffset);
          }
          if (delay !== undefined) {
            tmp$1.delay = Caml_option.valFromOption(delay);
          }
          if (elementClickable !== undefined) {
            tmp$1.elementClickable = Caml_option.valFromOption(elementClickable);
          }
          if (lockBackground !== undefined) {
            tmp$1.lockBackground = Caml_option.valFromOption(lockBackground);
          }
          if (onClick$1 !== undefined) {
            tmp$1.onClick = Caml_option.valFromOption(onClick$1);
          }
          if (offset !== undefined) {
            tmp$1.offset = Caml_option.valFromOption(offset);
          }
          if (position !== undefined) {
            tmp$1.position = Caml_option.valFromOption(position);
          }
          if (positionDependencies !== undefined) {
            tmp$1.positionDependencies = Caml_option.valFromOption(positionDependencies);
          }
          if (onStepActive !== undefined) {
            tmp$1.onStepActive = Caml_option.valFromOption(onStepActive);
          }
          if (simulateClick !== undefined) {
            tmp$1.simulateClick = Caml_option.valFromOption(simulateClick);
          }
          if (transition !== undefined) {
            tmp$1.transition = Caml_option.valFromOption(transition);
          }
          if (transitionDuration !== undefined) {
            tmp$1.transitionDuration = Caml_option.valFromOption(transitionDuration);
          }
          if (waitForId !== undefined) {
            tmp$1.waitForId = Caml_option.valFromOption(waitForId);
          }
          if (withOutline !== undefined) {
            tmp$1.withOutline = Caml_option.valFromOption(withOutline);
          }
          return React.createElement(ProductTour$StepProvider, {
                      advanceByCTA: advanceByCTA,
                      block: block,
                      intent: Caml_option.some(intent),
                      onComplete: onComplete,
                      stepNumber: stepNumber,
                      transition: transitionCallback,
                      transitions: transitions,
                      children: React.createElement(ProductTour$Windowed, tmp$1),
                      key: block.uid
                    });
      case /* Modal */2 :
          if (!opened) {
            return null;
          }
          var tmp$2 = {
            opened: opened,
            children: children
          };
          if (lockBackground !== undefined) {
            tmp$2.closeOnClickOutside = Caml_option.valFromOption(lockBackground);
          }
          if (delay !== undefined) {
            tmp$2.delay = Caml_option.valFromOption(delay);
          }
          if (onStepActive !== undefined) {
            tmp$2.onStepActive = Caml_option.valFromOption(onStepActive);
          }
          if (stepType._0 !== undefined) {
            tmp$2.size = Caml_option.valFromOption(stepType._0);
          }
          return React.createElement(ProductTour$StepProvider, {
                      advanceByCTA: advanceByCTA,
                      block: block,
                      intent: Caml_option.some(intent),
                      onComplete: onComplete,
                      stepNumber: stepNumber,
                      transition: transitionCallback,
                      transitions: transitions,
                      children: React.createElement(ProductTour$Modal, tmp$2),
                      key: block.uid
                    });
      
    }
  }
  if (opened) {
    return React.createElement(ProductTour$StepProvider, {
                advanceByCTA: advanceByCTA,
                block: block,
                intent: Caml_option.some(intent),
                onComplete: onComplete,
                stepNumber: stepNumber,
                transition: transitionCallback,
                transitions: transitions,
                children: children,
                key: block.uid
              });
  } else {
    return null;
  }
}

var Step = {
  make: ProductTour$Step
};

function extractField(block, field) {
  var match = Dopt.Block.field(block, field);
  if (match === undefined) {
    return null;
  }
  if (typeof match !== "object") {
    return null;
  }
  var variant = match.NAME;
  if (variant === "string") {
    return React.createElement(Markdown.make, {
                source: StringExt.widowless(match.VAL)
              });
  } else if (variant === "number") {
    return $$Number.toLocaleString(match.VAL);
  } else if (variant === "richText") {
    return React.createElement(ReactRichText.RichText, {
                children: match.VAL
              });
  } else {
    return Pervasives.string_of_bool(match.VAL);
  }
}

function ProductTour$GenericStep(Props) {
  var id = Props.id;
  var match = React.useContext(context$1);
  var transition = match.transition;
  var onComplete = match.onComplete;
  var intent = match.intent;
  var block = match.block;
  var advanceByCTA = match.advanceByCTA;
  var match$1 = React.useContext(context);
  var resetOnExit = match$1.resetOnExit;
  var tmp = {
    className: Curry._1(Css.style, {
          hd: Css.padding(Css.px(32)),
          tl: {
            hd: Css.paddingRight(Css.px(24)),
            tl: {
              hd: Css.paddingBottom(Css.px(24)),
              tl: {
                hd: Css.maxWidth(Css.px(320)),
                tl: /* [] */0
              }
            }
          }
        })
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement("div", tmp, React.createElement($$Text.make, {
                  size: "Large",
                  weight: "Semi",
                  children: extractField(block, "title")
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginRight(Css.px(4)),
                        tl: /* [] */0
                      })
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  children: extractField(block, "body")
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.gap(Css.px(8)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, match$1.showStepCount ? React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.grey50,
                            children: String(match.stepNumber) + "/" + String(match$1.totalSteps)
                          }) : null, React.createElement(TextButton.make, {
                          onClick: (function (param) {
                              return Belt_Option.forEach(intent, (function (intent) {
                                            if (resetOnExit) {
                                              intent.reset();
                                            } else {
                                              intent.stop();
                                            }
                                            
                                          }));
                            }),
                          children: React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Regular",
                                children: "Exit Tour"
                              })
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.gap(Css.px(4)),
                              tl: /* [] */0
                            }
                          })
                    }, Belt_Array.map(match.transitions, (function (param) {
                            var key = param.key;
                            var tmp = {
                              onClick: (function (param) {
                                  if (key === "complete") {
                                    Curry._1(onComplete, undefined);
                                  }
                                  return Curry._1(transition, key);
                                }),
                              style: key === "prev" || key === "previous" || !advanceByCTA && key === "next" ? "ghost" : "primary",
                              key: key
                            };
                            if (param.icon !== undefined) {
                              tmp.icon = Caml_option.valFromOption(param.icon);
                            }
                            if (param.label !== undefined) {
                              tmp.label = param.label;
                            }
                            return React.createElement(Button.make, tmp);
                          })))));
}

var GenericStep = {
  make: ProductTour$GenericStep
};

function ProductTour$GenericModal(Props) {
  var id = Props.id;
  var match = React.useContext(context$1);
  var transition = match.transition;
  var onComplete = match.onComplete;
  var block = match.block;
  var tmp = {
    className: Curry._1(Css.style, {
          hd: Css.padding(Css.px(48)),
          tl: {
            hd: Css.paddingTop(Css.px(72)),
            tl: {
              hd: Css.wordWrap("breakWord"),
              tl: /* [] */0
            }
          }
        })
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement("div", tmp, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingBottom(Css.px(8)),
                        tl: /* [] */0
                      })
                }, React.createElement($$Text.make, {
                      element: "H1",
                      size: "Huge",
                      color: Styles.Color.grey80,
                      children: extractField(block, "title")
                    })), React.createElement($$Text.make, {
                  color: Styles.Color.grey80,
                  children: Belt_Array.map(Belt_SortArray.stableSortBy(Belt_Array.keep(block.fields, (function (param) {
                                  return param.sid.startsWith("body-");
                                })), (function (a, b) {
                              return Caml.caml_string_compare(a.sid, b.sid);
                            })), (function (param) {
                          return React.createElement(Markdown.make, {
                                      source: param.value
                                    });
                        }))
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("row"),
                          tl: {
                            hd: Css.justifyContent("flexEnd"),
                            tl: {
                              hd: Css.gap(Css.px(16)),
                              tl: {
                                hd: Css.paddingTop(Css.px(32)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, Belt_Array.map(match.transitions, (function (param) {
                        var key = param.key;
                        var tmp = {
                          onClick: (function (param) {
                              if (key === "complete") {
                                Curry._1(onComplete, undefined);
                              }
                              return Curry._1(transition, key);
                            }),
                          size: "large",
                          key: key
                        };
                        if (param.icon !== undefined) {
                          tmp.icon = Caml_option.valFromOption(param.icon);
                        }
                        if (param.label !== undefined) {
                          tmp.label = param.label;
                        }
                        return React.createElement(Button.make, tmp);
                      }))));
}

var GenericModal = {
  make: ProductTour$GenericModal
};

function ProductTour$GenericDisclaimer(Props) {
  var customBackgroundColorOpt = Props.backgroundColor;
  var id = Props.id;
  var customBackgroundColor = customBackgroundColorOpt !== undefined ? customBackgroundColorOpt : Styles.Color.blue20;
  var match = React.useContext(context$1);
  var transition = match.transition;
  var onComplete = match.onComplete;
  var block = match.block;
  var tmp = {
    className: Curry._1(Css.style, {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("row"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.padding(Css.px(24)),
                tl: {
                  hd: Css.backgroundColor(customBackgroundColor),
                  tl: {
                    hd: Css.borderRadius(Css.px(10)),
                    tl: {
                      hd: Css.border(Css.px(1), "solid", Styles.Color.setAlpha(Styles.Color.magenta100, 0.1)),
                      tl: {
                        hd: Css.boxShadow(Styles.Shadow.standard),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        })
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  var match$1 = Dopt.Block.field(block, "body");
  var tmp$1;
  if (match$1 !== undefined && typeof match$1 === "object") {
    var variant = match$1.NAME;
    tmp$1 = variant === "string" ? React.createElement(Markdown.make, {
            source: match$1.VAL
          }) : (
        variant === "number" ? $$Number.toLocaleString(match$1.VAL) : (
            variant === "richText" ? React.createElement(ReactRichText.RichText, {
                    children: match$1.VAL
                  }) : Pervasives.string_of_bool(match$1.VAL)
          )
      );
  } else {
    tmp$1 = null;
  }
  return React.createElement("aside", tmp, React.createElement("section", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.gap(Css.px(4)),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      color: Styles.Color.grey90,
                      children: null
                    }, React.createElement($$Text.make, {
                          size: "Large",
                          weight: "Semi",
                          children: Dopt.Block.fieldWithDefault(block, "title", "")
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          children: tmp$1
                        }))), React.createElement(Spacer.make, {
                  width: 4,
                  grow: 1.0
                }), Belt_Array.map(match.transitions, (function (param) {
                    var key = param.key;
                    var tmp = {
                      onClick: (function (param) {
                          if (key === "complete") {
                            Curry._1(onComplete, undefined);
                          }
                          return Curry._1(transition, key);
                        }),
                      size: "small",
                      style: "ghost",
                      key: key
                    };
                    if (param.icon !== undefined) {
                      tmp.icon = Caml_option.valFromOption(param.icon);
                    }
                    if (param.label !== undefined) {
                      tmp.label = param.label;
                    }
                    return React.createElement(Button.make, tmp);
                  })));
}

var GenericDisclaimer = {
  make: ProductTour$GenericDisclaimer
};

function ProductTour(Props) {
  var onComplete = Props.onComplete;
  var resetOnExitOpt = Props.resetOnExit;
  var showStepCount = Props.showStepCount;
  var tourId = Props.tourId;
  var tourMayShowOpt = Props.tourMayShow;
  var withConfettiOpt = Props.withConfetti;
  var children = Props.children;
  var resetOnExit = resetOnExitOpt !== undefined ? resetOnExitOpt : false;
  var tourMayShow = tourMayShowOpt !== undefined ? tourMayShowOpt : true;
  var withConfetti = withConfettiOpt !== undefined ? withConfettiOpt : false;
  var match = React$1.useFlow(tourId);
  var flow = match[0];
  var idMap = Belt_MapString.fromArray(Belt_Array.mapWithIndexU(Belt_SortArray.stableSortByU(React.Children.toArray(children), (function (a, b) {
                  return Caml_obj.caml_compare(a.props.stepId, b.props.stepId);
                })), (function (index, child) {
              return [
                      child.props.stepId,
                      index + 1 | 0
                    ];
            })));
  useFlowStarted(flow, tourMayShow);
  useFlowExited(flow, idMap);
  useFlowCompleted(flow);
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowConfetti = match$1[1];
  var showConfetti = match$1[0];
  React.useEffect((function () {
          var timeout = {
            contents: undefined
          };
          if (showConfetti) {
            timeout.contents = Caml_option.some(setTimeout((function (param) {
                        return Curry._1(setShowConfetti, (function (param) {
                                      return false;
                                    }));
                      }), 10000));
          }
          return (function (param) {
                    return Belt_Option.forEach(timeout.contents, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), [showConfetti]);
  var tmp;
  if (!flow.state.finished && !flow.state.stopped) {
    var tmp$1 = {
      blocks: flow.blocks,
      getStepNumber: (function (stepId) {
          return Belt_MapString.get(idMap, stepId);
        }),
      onComplete: (function (_e) {
          if (withConfetti) {
            Curry._1(setShowConfetti, (function (param) {
                    return true;
                  }));
          }
          setTimeout((function (param) {
                  return Belt_Option.forEach(onComplete, (function (fn) {
                                return Curry._1(fn, undefined);
                              }));
                }), 0);
          
        }),
      resetOnExit: resetOnExit,
      tourId: tourId,
      tourMayShow: tourMayShow,
      children: children
    };
    if (showStepCount !== undefined) {
      tmp$1.showStepCount = Caml_option.valFromOption(showStepCount);
    }
    tmp = React.createElement(ProductTour$TourProvider, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, showConfetti ? React.createElement(ReactConfetti, {
                    numberOfPieces: 600,
                    width: window.innerWidth,
                    height: window.innerHeight,
                    colors: Belt_List.toArray(Belt_List.map(Styles.Color.primeColors, Styles.Color.toString)),
                    run: showConfetti,
                    recycle: false
                  }) : null, tmp);
}

var make = ProductTour;

exports.TourProvider = TourProvider;
exports.StepProvider = StepProvider;
exports.AnalyticsHooks = AnalyticsHooks;
exports.ClickSimulator = ClickSimulator;
exports.OnElement = OnElement;
exports.Windowed = Windowed;
exports.Modal = Modal;
exports.Step = Step;
exports.extractField = extractField;
exports.GenericStep = GenericStep;
exports.GenericModal = GenericModal;
exports.GenericDisclaimer = GenericDisclaimer;
exports.make = make;
/* context Not a pure module */
