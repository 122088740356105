// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("../Button.bs.js");
var Router = require("../Router.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var DiffEvent = require("../DiffEvent.bs.js");
var HoverTitle = require("./HoverTitle.bs.js");
var StateUtils = require("../stateUtils.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var BranchHeader = require("../BranchHeader.bs.js");
var ChangedEvent = require("./ChangedEvent.bs.js");
var DiffComments = require("../DiffComments.bs.js");
var SnippetUtils = require("../detailviews/SnippetUtils.bs.js");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");
var SendActionsContext = require("../SendActionsContext.bs.js");
var BranchImplementationUtils = require("./BranchImplementationUtils.bs.js");
var EventImplementationSnippet = require("../detailviews/EventImplementationSnippet.bs.js");

var container = Curry._1(Css.merge, {
      hd: ChangedEvent.Style.container,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("static"),
              tl: {
                hd: Css.minWidth(Css.px(480)),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var modificationStatus = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(10)),
      tl: {
        hd: Css.padding2(Css.px(2), Css.px(8)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.red10),
          tl: {
            hd: Css.color(Styles.Color.red100),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  container: container,
  modificationStatus: modificationStatus
};

function DeletedEvent(Props) {
  var branchStatus = Props.branchStatus;
  var $$event = Props.event;
  var goToCommentId = Props.goToCommentId;
  var toModel = Props.toModel;
  var sourceId = Props.sourceId;
  var enrichedModel = Props.enrichedModel;
  var fromModel = Props.fromModel;
  var diffViewType = Props.diffViewType;
  var containerRef = React.useRef(null);
  var eventExists = StateUtils.getEventById($$event.id, toModel) !== undefined;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  SnippetUtils.useScrollIntoView($$event.id, "BranchImplementation", (BranchHeader.branchHeaderHeight + BranchImplementationUtils.codeChangesHeaderHeight | 0) + 2 | 0, containerRef);
  var tmp;
  if (eventExists) {
    var tmp$1 = {
      schemaId: Router.Schema.getSchemaId(undefined),
      branchId: Router.Schema.getBranchId(undefined),
      threadId: $$event.id,
      itemType: "Event",
      itemName: $$event.name,
      stickyTop: (BranchHeader.branchHeaderHeight + BranchImplementationUtils.codeChangesHeaderHeight | 0) + 4 | 0,
      events: toModel.events
    };
    if (goToCommentId !== undefined) {
      tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp = React.createElement(DiffComments.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: eventExists ? Styles.emptyStyle : Css.marginRight(Css.px(BranchImplementationUtils.commentMarginWidth)),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("section", {
                  ref: containerRef,
                  className: container
                }, React.createElement("header", {
                      className: ChangedEvent.Style.header
                    }, React.createElement("span", {
                          className: modificationStatus
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              children: eventExists ? "Removed" : "Deleted"
                            })), React.createElement(HoverTitle.make, {
                          label: $$event.name
                        }), React.createElement(Spacer.make, {
                          width: 0,
                          grow: 1.0
                        }), eventExists ? React.createElement(Button.make, {
                            icon: "sidebar",
                            label: "View",
                            onClick: (function (param) {
                                return Router.Schema.pushDrawerItem(undefined, {
                                            NAME: "event",
                                            VAL: [
                                              $$event.id,
                                              undefined,
                                              undefined,
                                              false
                                            ]
                                          });
                              }),
                            style: "outline"
                          }) : React.createElement(Button.make, {
                            label: "Unarchive",
                            onClick: (function (param) {
                                return DiffEvent.unarchiveEvent($$event, toModel, sendActions, Router.Schema.getBranchId(undefined), schemaGroup);
                              }),
                            style: "outline"
                          })), React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.grey60,
                      children: "All references to this event should be removed"
                    }), React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement(EventImplementationSnippet.make, {
                      branchStatus: branchStatus,
                      eventDiffState: "deleted",
                      enrichedToModel: enrichedModel,
                      event: $$event,
                      fromModel: fromModel,
                      toModel: toModel,
                      pageName: "BranchImplementation",
                      viewType: /* SingleSource */{
                        _0: sourceId
                      },
                      diffViewType: diffViewType,
                      key: sourceId
                    })), tmp);
}

var make = DeletedEvent;

exports.Style = Style;
exports.make = make;
/* container Not a pure module */
