// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("../../app/src/stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");

function operationLabel(operation) {
  if (operation === "Increment") {
    return "increment";
  } else if (operation === "SetOnce") {
    return "set once";
  } else if (operation === "Set") {
    return "set";
  } else if (operation === "Prepend") {
    return "prepend";
  } else if (operation === "SnowplowContext") {
    return "Snowplow Context";
  } else {
    return "append";
  }
}

function devPlatformLabel(platform) {
  if (platform === "ReactNative") {
    return "React Native";
  } else if (platform === "DotNet") {
    return ".NET";
  } else if (platform === "Android") {
    return "Android";
  } else if (platform === "Python") {
    return "Python";
  } else if (platform === "JsonSchema") {
    return "JSON Schema";
  } else if (platform === "Go") {
    return "Go";
  } else if (platform === "Gtm") {
    return "Google Tag Manager";
  } else if (platform === "IOS") {
    return "iOS";
  } else if (platform === "Php") {
    return "PHP";
  } else if (platform === "Web") {
    return "Web";
  } else if (platform === "MacOS") {
    return "macOS";
  } else if (platform === "Expo") {
    return "Expo";
  } else if (platform === "Java") {
    return "Java";
  } else if (platform === "Node") {
    return "Node.js";
  } else if (platform === "Ruby") {
    return "Ruby";
  } else if (platform === "Flutter") {
    return "Flutter";
  } else if (platform === "Unity") {
    return "Unity";
  } else {
    return "Other";
  }
}

function inspectorSupportedLanguages(platform) {
  if (platform !== undefined) {
    if (platform === "Expo" || platform === "Web" || platform === "ReactNative") {
      return {
              hd: "js_v2",
              tl: {
                hd: "ts",
                tl: {
                  hd: "re_v2",
                  tl: /* [] */0
                }
              }
            };
    } else if (platform === "Android") {
      return {
              hd: "java",
              tl: {
                hd: "kt",
                tl: /* [] */0
              }
            };
    } else if (platform === "Go") {
      return {
              hd: "go",
              tl: /* [] */0
            };
    } else if (platform === "IOS") {
      return {
              hd: "swift",
              tl: {
                hd: "objc",
                tl: /* [] */0
              }
            };
    } else if (platform === "Java") {
      return {
              hd: "java",
              tl: /* [] */0
            };
    } else if (platform === "Node") {
      return {
              hd: "js_v2",
              tl: {
                hd: "ts",
                tl: /* [] */0
              }
            };
    } else if (platform === "Flutter") {
      return {
              hd: "dart",
              tl: /* [] */0
            };
    } else {
      return /* [] */0;
    }
  } else {
    return /* [] */0;
  }
}

var analyticsTools = {
  hd: "Amplitude",
  tl: {
    hd: "AppsFlyer",
    tl: {
      hd: "FacebookAnalytics",
      tl: {
        hd: "FirebaseAnalytics",
        tl: {
          hd: "FullStory",
          tl: {
            hd: "Intercom",
            tl: {
              hd: "Mixpanel",
              tl: {
                hd: "Segment",
                tl: {
                  hd: "Rudderstack",
                  tl: {
                    hd: "GoogleAnalytics4",
                    tl: {
                      hd: "Freshpaint",
                      tl: {
                        hd: "AdobeAnalytics",
                        tl: {
                          hd: "PostHog",
                          tl: {
                            hd: "Heap",
                            tl: {
                              hd: "Pendo",
                              tl: {
                                hd: "MParticle",
                                tl: {
                                  hd: "Snowplow",
                                  tl: {
                                    hd: "Permutive",
                                    tl: {
                                      hd: "Braze",
                                      tl: {
                                        hd: "ZendeskConnect",
                                        tl: {
                                          hd: "Custom",
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var groupedAnalyticsTools = [
  [
    "Analytics Tools",
    [
      "AdobeAnalytics",
      "Amplitude",
      "FirebaseAnalytics",
      "FullStory",
      "GoogleAnalytics4",
      "Heap",
      "Mixpanel",
      "Pendo",
      "PostHog"
    ]
  ],
  [
    "Customer Data Platforms",
    [
      "Freshpaint",
      "MParticle",
      "Rudderstack",
      "Segment",
      "Snowplow"
    ]
  ],
  [
    "Other",
    [
      "AppsFlyer",
      "Intercom",
      "Permutive",
      "Braze",
      "ZendeskConnect",
      "Custom"
    ]
  ]
];

function analyticsToolsLabel(tool) {
  if (tool === "Braze") {
    return "Braze";
  } else if (tool === "Pendo") {
    return "Pendo";
  } else if (tool === "AppsFlyer") {
    return "AppsFlyer";
  } else if (tool === "PostHog") {
    return "PostHog";
  } else if (tool === "Rudderstack") {
    return "RudderStack";
  } else if (tool === "AdobeAnalytics") {
    return "Adobe Analytics";
  } else if (tool === "Segment") {
    return "Segment";
  } else if (tool === "Custom") {
    return "Other";
  } else if (tool === "ZendeskConnect") {
    return "Zendesk Connect";
  } else if (tool === "Mixpanel") {
    return "Mixpanel";
  } else if (tool === "Intercom") {
    return "Intercom";
  } else if (tool === "GoogleAnalytics4") {
    return "Google Analytics 4";
  } else if (tool === "Amplitude") {
    return "Amplitude";
  } else if (tool === "FirebaseAnalytics") {
    return "Firebase Analytics";
  } else if (tool === "Freshpaint") {
    return "Freshpaint";
  } else if (tool === "Snowplow") {
    return "Snowplow";
  } else if (tool === "FullStory") {
    return "FullStory";
  } else if (tool === "Heap") {
    return "Heap";
  } else if (tool === "FacebookAnalytics") {
    return "Facebook Analytics";
  } else if (tool === "MParticle") {
    return "mParticle";
  } else {
    return "Permutive";
  }
}

function supportsObjects(destination, propertyName) {
  if (destination === "FullStory" || destination === "Snowplow" || destination === "GoogleAnalytics4" || destination === "Mixpanel" || destination === "Custom" || destination === "Segment" || destination === "Rudderstack") {
    return true;
  }
  if (destination !== "Intercom") {
    return false;
  }
  switch (propertyName) {
    case "article" :
    case "companies" :
    case "pages" :
    case "price" :
        return true;
    default:
      return false;
  }
}

var languagePlatformDestinationsThatSupportGroupAnalytics = [
  [
    "re_v2",
    "Web",
    "Custom"
  ],
  [
    "ts",
    "Web",
    "Custom"
  ],
  [
    "ts",
    "ReactNative",
    "Custom"
  ],
  [
    "ts",
    "Expo",
    "Custom"
  ],
  [
    "ts",
    "Node",
    "Custom"
  ],
  [
    "js_v2",
    "Node",
    "Custom"
  ],
  [
    "js_v2",
    "Web",
    "Custom"
  ],
  [
    "cs",
    "DotNet",
    "Custom"
  ],
  [
    "py3",
    "Python",
    "Custom"
  ],
  [
    "py",
    "Python",
    "Custom"
  ],
  [
    "rb",
    "Ruby",
    "Custom"
  ],
  [
    "re_v2",
    "Node",
    "Mixpanel"
  ],
  [
    "re_v2",
    "Web",
    "Mixpanel"
  ],
  [
    "ts",
    "Web",
    "Mixpanel"
  ],
  [
    "js_v2",
    "Web",
    "Mixpanel"
  ],
  [
    "py3",
    "Python",
    "Mixpanel"
  ],
  [
    "py",
    "Python",
    "Mixpanel"
  ],
  [
    "java",
    "Android",
    "Mixpanel"
  ],
  [
    "kt",
    "Android",
    "Mixpanel"
  ],
  [
    "swift",
    "IOS",
    "Mixpanel"
  ]
];

function doesLanguagePlatformDestinationSupportGroups(language, platform, destinationType, destinationInterface) {
  return Belt_Array.someU(languagePlatformDestinationsThatSupportGroupAnalytics, (function (param) {
                var dest = param[2];
                if (param[0] === language && param[1] === platform) {
                  if (dest === destinationType && !destinationInterface) {
                    return true;
                  } else if (dest === "Custom") {
                    return destinationInterface;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              }));
}

function areGroupPropertiesSupported(source, destinations) {
  return Belt_List.someU(destinations, (function (destination) {
                return doesLanguagePlatformDestinationSupportGroups(source.language, source.platform, destination.type_, Belt_Option.mapWithDefault(Belt_List.getBy(source.destinations, (function (destinationRef) {
                                      return destinationRef.destinationId === destination.id;
                                    })), false, (function (destinationRef) {
                                  return destinationRef.destinationMode === /* DestinationInterface */1;
                                })));
              }));
}

function hasAtLeastOneDestinationThatDoesNotSupportGroupProperties(source, destinations) {
  return Belt_List.someU(destinations, (function (destination) {
                return !doesLanguagePlatformDestinationSupportGroups(source.language, source.platform, destination.type_, Belt_Option.mapWithDefault(Belt_List.getBy(source.destinations, (function (destinationRef) {
                                      return destinationRef.destinationId === destination.id;
                                    })), false, (function (destinationRef) {
                                  return destinationRef.destinationMode === /* DestinationInterface */1;
                                })));
              }));
}

function getUnsupportedSetOnceDestinationLabels(destination) {
  if (destination === "Mixpanel") {
    return "Mixpanel (except JavaScript and Reason sources)";
  } else if (destination === "Amplitude") {
    return ;
  } else {
    return analyticsToolsLabel(destination);
  }
}

function getSupportedLanguages(platform) {
  if (platform !== undefined) {
    if (platform === "Node" || platform === "Web" || platform === "ReactNative") {
      return {
              hd: "js_v2",
              tl: {
                hd: "re_v2",
                tl: {
                  hd: "ts",
                  tl: {
                    hd: "js",
                    tl: {
                      hd: "re",
                      tl: /* [] */0
                    }
                  }
                }
              }
            };
    } else if (platform === "Unity" || platform === "DotNet") {
      return {
              hd: "cs",
              tl: /* [] */0
            };
    } else if (platform === "Android") {
      return {
              hd: "java",
              tl: {
                hd: "kt",
                tl: /* [] */0
              }
            };
    } else if (platform === "Python") {
      return {
              hd: "py",
              tl: {
                hd: "py3",
                tl: /* [] */0
              }
            };
    } else if (platform === "JsonSchema") {
      return {
              hd: "json",
              tl: /* [] */0
            };
    } else if (platform === "Go") {
      return {
              hd: "go",
              tl: /* [] */0
            };
    } else if (platform === "Gtm") {
      return {
              hd: "js_v2",
              tl: /* [] */0
            };
    } else if (platform === "IOS") {
      return {
              hd: "swift",
              tl: {
                hd: "objc",
                tl: /* [] */0
              }
            };
    } else if (platform === "Php") {
      return {
              hd: "php",
              tl: /* [] */0
            };
    } else if (platform === "MacOS") {
      return {
              hd: "swift",
              tl: /* [] */0
            };
    } else if (platform === "Expo") {
      return {
              hd: "js_v2",
              tl: {
                hd: "re_v2",
                tl: {
                  hd: "ts",
                  tl: /* [] */0
                }
              }
            };
    } else if (platform === "Java") {
      return {
              hd: "java",
              tl: /* [] */0
            };
    } else if (platform === "Ruby") {
      return {
              hd: "rb",
              tl: /* [] */0
            };
    } else if (platform === "Flutter") {
      return {
              hd: "dart",
              tl: /* [] */0
            };
    } else {
      return /* [] */0;
    }
  } else {
    return /* [] */0;
  }
}

function supportsInvocations(source) {
  var match = source.platform;
  var match$1 = source.language;
  if (match !== undefined) {
    if (match === "Node" || match === "Expo" || match === "Web" || match === "ReactNative") {
      if (match$1 !== undefined) {
        if (match$1 === "ts" || match$1 === "re_v2") {
          return true;
        } else {
          return match$1 === "js_v2";
        }
      } else {
        return false;
      }
    } else if (match === "Android") {
      if (match$1 !== undefined) {
        if (match$1 === "kt") {
          return true;
        } else {
          return match$1 === "java";
        }
      } else {
        return false;
      }
    } else if (match === "Python") {
      if (match$1 !== undefined) {
        if (match$1 === "py3") {
          return true;
        } else {
          return match$1 === "py";
        }
      } else {
        return false;
      }
    } else if (match === "IOS") {
      if (match$1 !== undefined) {
        if (match$1 === "swift") {
          return true;
        } else {
          return match$1 === "objc";
        }
      } else {
        return false;
      }
    } else if (match === "Php") {
      if (match$1 !== undefined) {
        return match$1 === "php";
      } else {
        return false;
      }
    } else if (match === "Java") {
      if (match$1 !== undefined) {
        return match$1 === "java";
      } else {
        return false;
      }
    } else if (match === "Ruby") {
      if (match$1 !== undefined) {
        return match$1 === "rb";
      } else {
        return false;
      }
    } else if (match === "Unity" && match$1 !== undefined) {
      return match$1 === "cs";
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function filenameHint(x) {
  if (x === "json") {
    return "AvoSchema";
  } else if (x === "py3" || x === "rb" || x === "py") {
    return "avo";
  } else {
    return "Avo";
  }
}

function languageExtensionHint(x) {
  if (x === "java") {
    return "java";
  } else if (x === "json") {
    return "json";
  } else if (x === "objc") {
    return "m";
  } else if (x === "js" || x === "js_v2") {
    return "js";
  } else if (x === "re" || x === "re_v2") {
    return "re";
  } else if (x === "cs") {
    return "cs";
  } else if (x === "go") {
    return "go";
  } else if (x === "kt") {
    return "kt";
  } else if (x === "py3" || x === "py") {
    return "py";
  } else if (x === "rb") {
    return "rb";
  } else if (x === "ts") {
    return "ts";
  } else if (x === "php") {
    return "php";
  } else if (x === "swift") {
    return "swift";
  } else {
    return "dart";
  }
}

function languageLabel(language) {
  if (language === "java") {
    return "Java";
  } else if (language === "json") {
    return "JSON Schema";
  } else if (language === "objc") {
    return "Objective-C";
  } else if (language === "js_v2") {
    return "JavaScript";
  } else if (language === "re_v2") {
    return "Reason";
  } else if (language === "cs") {
    return "C#";
  } else if (language === "go") {
    return "Go";
  } else if (language === "js") {
    return "JavaScript (deprecated)";
  } else if (language === "kt") {
    return "Kotlin";
  } else if (language === "py") {
    return "Python2";
  } else if (language === "rb") {
    return "Ruby";
  } else if (language === "re") {
    return "Reason (deprecated)";
  } else if (language === "ts") {
    return "TypeScript";
  } else if (language === "php") {
    return "PHP";
  } else if (language === "py3") {
    return "Python3";
  } else if (language === "swift") {
    return "Swift";
  } else {
    return "Dart";
  }
}

function languagePlatformDestinationLabel(param) {
  var destinationsString = Belt_Array.mapU(param[1], (function (destination) {
            var destinationName = destination.name;
            if (destinationName !== undefined) {
              return destinationName;
            } else {
              return "Unnamed destination";
            }
          })).join(", ");
  return "\n- " + param[0] + " sending to " + destinationsString;
}

function getSupportedAvoManagedDestinations(platform, lang) {
  if (platform !== undefined) {
    if (platform === "ReactNative") {
      if (lang !== undefined) {
        if (lang === "ts" || lang === "js_v2") {
          return {
                  hd: "Amplitude",
                  tl: {
                    hd: "Segment",
                    tl: {
                      hd: "FirebaseAnalytics",
                      tl: {
                        hd: "AppsFlyer",
                        tl: {
                          hd: "Snowplow",
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                };
        } else if (lang === "re_v2") {
          return {
                  hd: "Amplitude",
                  tl: {
                    hd: "Segment",
                    tl: {
                      hd: "FirebaseAnalytics",
                      tl: {
                        hd: "AppsFlyer",
                        tl: /* [] */0
                      }
                    }
                  }
                };
        } else if (lang === "re" || lang === "js") {
          return {
                  hd: "Segment",
                  tl: /* [] */0
                };
        } else {
          return /* [] */0;
        }
      } else {
        return /* [] */0;
      }
    } else if (platform === "DotNet") {
      if (lang === "cs") {
        return {
                hd: "Mixpanel",
                tl: /* [] */0
              };
      } else {
        return /* [] */0;
      }
    } else if (platform === "Android") {
      if (lang !== undefined) {
        if (lang === "java") {
          return {
                  hd: "Amplitude",
                  tl: {
                    hd: "Segment",
                    tl: {
                      hd: "Mixpanel",
                      tl: /* [] */0
                    }
                  }
                };
        } else if (lang === "kt") {
          return {
                  hd: "Amplitude",
                  tl: {
                    hd: "Segment",
                    tl: {
                      hd: "FirebaseAnalytics",
                      tl: {
                        hd: "Braze",
                        tl: {
                          hd: "Snowplow",
                          tl: {
                            hd: "Mixpanel",
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                };
        } else {
          return /* [] */0;
        }
      } else {
        return /* [] */0;
      }
    } else if (platform === "Python") {
      if (lang !== undefined && (lang === "py3" || lang === "py")) {
        return {
                hd: "Mixpanel",
                tl: /* [] */0
              };
      } else {
        return /* [] */0;
      }
    } else if (platform === "JsonSchema") {
      return /* [] */0;
    } else if (platform === "Gtm") {
      return {
              hd: "Amplitude",
              tl: {
                hd: "Mixpanel",
                tl: {
                  hd: "Segment",
                  tl: {
                    hd: "FacebookAnalytics",
                    tl: {
                      hd: "MParticle",
                      tl: {
                        hd: "FullStory",
                        tl: {
                          hd: "Intercom",
                          tl: {
                            hd: "Permutive",
                            tl: {
                              hd: "Snowplow",
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            };
    } else if (platform === "IOS") {
      if (lang !== undefined) {
        if (lang === "objc") {
          return {
                  hd: "Amplitude",
                  tl: {
                    hd: "FirebaseAnalytics",
                    tl: {
                      hd: "Segment",
                      tl: {
                        hd: "Mixpanel",
                        tl: /* [] */0
                      }
                    }
                  }
                };
        } else if (lang === "swift") {
          return {
                  hd: "Amplitude",
                  tl: {
                    hd: "Snowplow",
                    tl: {
                      hd: "Mixpanel",
                      tl: {
                        hd: "Segment",
                        tl: {
                          hd: "MParticle",
                          tl: {
                            hd: "FirebaseAnalytics",
                            tl: {
                              hd: "Braze",
                              tl: {
                                hd: "MParticle",
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                };
        } else {
          return /* [] */0;
        }
      } else {
        return /* [] */0;
      }
    } else if (platform === "Php") {
      if (lang === "php") {
        return {
                hd: "MParticle",
                tl: {
                  hd: "Snowplow",
                  tl: {
                    hd: "Custom",
                    tl: /* [] */0
                  }
                }
              };
      } else {
        return /* [] */0;
      }
    } else if (platform === "Web") {
      if (lang !== undefined) {
        if (lang === "js_v2") {
          return {
                  hd: "Amplitude",
                  tl: {
                    hd: "Mixpanel",
                    tl: {
                      hd: "Segment",
                      tl: {
                        hd: "FacebookAnalytics",
                        tl: {
                          hd: "MParticle",
                          tl: {
                            hd: "FullStory",
                            tl: {
                              hd: "Intercom",
                              tl: {
                                hd: "Permutive",
                                tl: {
                                  hd: "Snowplow",
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                };
        } else if (lang === "re_v2") {
          return {
                  hd: "Amplitude",
                  tl: {
                    hd: "Mixpanel",
                    tl: {
                      hd: "Segment",
                      tl: {
                        hd: "FullStory",
                        tl: {
                          hd: "Intercom",
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                };
        } else if (lang === "js") {
          return {
                  hd: "Amplitude",
                  tl: {
                    hd: "Segment",
                    tl: {
                      hd: "Mixpanel",
                      tl: {
                        hd: "FacebookAnalytics",
                        tl: /* [] */0
                      }
                    }
                  }
                };
        } else if (lang === "re") {
          return {
                  hd: "Amplitude",
                  tl: {
                    hd: "Segment",
                    tl: /* [] */0
                  }
                };
        } else if (lang === "ts") {
          return {
                  hd: "Amplitude",
                  tl: {
                    hd: "Mixpanel",
                    tl: {
                      hd: "Segment",
                      tl: {
                        hd: "FacebookAnalytics",
                        tl: {
                          hd: "MParticle",
                          tl: {
                            hd: "Snowplow",
                            tl: {
                              hd: "FullStory",
                              tl: {
                                hd: "Intercom",
                                tl: {
                                  hd: "ZendeskConnect",
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                };
        } else {
          return /* [] */0;
        }
      } else {
        return /* [] */0;
      }
    } else if (platform === "MacOS") {
      return {
              hd: "Amplitude",
              tl: {
                hd: "Custom",
                tl: /* [] */0
              }
            };
    } else if (platform === "Expo") {
      return {
              hd: "Amplitude",
              tl: {
                hd: "Segment",
                tl: /* [] */0
              }
            };
    } else if (platform === "Java") {
      if (lang === "java") {
        return {
                hd: "Segment",
                tl: {
                  hd: "Custom",
                  tl: /* [] */0
                }
              };
      } else {
        return /* [] */0;
      }
    } else if (platform === "Node") {
      if (lang !== undefined) {
        if (lang === "js_v2") {
          return {
                  hd: "Amplitude",
                  tl: {
                    hd: "Mixpanel",
                    tl: {
                      hd: "Segment",
                      tl: {
                        hd: "Intercom",
                        tl: {
                          hd: "Snowplow",
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                };
        } else if (lang === "re_v2") {
          return {
                  hd: "Amplitude",
                  tl: {
                    hd: "Segment",
                    tl: {
                      hd: "Mixpanel",
                      tl: /* [] */0
                    }
                  }
                };
        } else if (lang === "re" || lang === "js") {
          return {
                  hd: "Amplitude",
                  tl: {
                    hd: "Segment",
                    tl: /* [] */0
                  }
                };
        } else if (lang === "ts") {
          return {
                  hd: "Amplitude",
                  tl: {
                    hd: "Segment",
                    tl: {
                      hd: "Custom",
                      tl: {
                        hd: "Snowplow",
                        tl: /* [] */0
                      }
                    }
                  }
                };
        } else {
          return /* [] */0;
        }
      } else {
        return /* [] */0;
      }
    } else if (platform === "Ruby") {
      if (lang === "rb") {
        return {
                hd: "Segment",
                tl: {
                  hd: "Amplitude",
                  tl: {
                    hd: "Custom",
                    tl: /* [] */0
                  }
                }
              };
      } else {
        return /* [] */0;
      }
    } else if (platform === "Unity" && lang === "cs") {
      return {
              hd: "Mixpanel",
              tl: /* [] */0
            };
    } else {
      return /* [] */0;
    }
  } else {
    return /* [] */0;
  }
}

function getSupportedDestinations(platform, lang) {
  var hasSnowplowSupport = Belt_List.has(getSupportedAvoManagedDestinations(platform, lang), "Snowplow", (function (prim0, prim1) {
          return prim0 === prim1;
        }));
  if (hasSnowplowSupport) {
    return analyticsTools;
  } else {
    return Belt_List.keep(analyticsTools, (function (tool) {
                  return tool !== "Snowplow";
                }));
  }
}

function setDestinationModeForCodegen(source, destinations) {
  return Belt_List.keepMapU(destinations, (function (destination) {
                var destinationMode = Belt_Option.mapU(StateUtils.getSourceDestinationRef(source, destination.id), (function (destinationRef) {
                        return destinationRef.destinationMode;
                      }));
                var isSnowplowDestination = destination.type_ === "Snowplow";
                if (destinationMode === 1) {
                  if (isSnowplowDestination) {
                    return destination;
                  } else {
                    return {
                            id: destination.id,
                            name: destination.name,
                            type_: "Custom",
                            apiKeys: destination.apiKeys,
                            includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                            disabledByDefault: destination.disabledByDefault
                          };
                  }
                }
                if (destination.type_ === "Custom" || Belt_List.someU(getSupportedAvoManagedDestinations(source.platform, source.language), (function (analyticsTool) {
                          return analyticsTool === destination.type_;
                        }))) {
                  return destination;
                }
                
              }));
}

function convertDestinationInterfacesToCustomInModel(model, source) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: model.events,
          migrations: model.migrations,
          sources: model.sources,
          destinations: Belt_List.map(model.destinations, (function (destination) {
                  var destinationMode = Belt_Option.map(Belt_List.getBy(source.destinations, (function (destinationRef) {
                              return destinationRef.destinationId === destination.id;
                            })), (function (destinationRef) {
                          return destinationRef.destinationMode;
                        }));
                  if (destinationMode !== undefined && !(destinationMode !== 1 || Caml_obj.caml_equal(destination.type_, "Snowplow"))) {
                    return {
                            id: destination.id,
                            name: destination.name,
                            type_: "Custom",
                            apiKeys: destination.apiKeys,
                            includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                            disabledByDefault: destination.disabledByDefault
                          };
                  } else {
                    return destination;
                  }
                })),
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations
        };
}

function hasAnalyticsTool(destinations, tool) {
  return Belt_List.some(destinations, (function (dest) {
                return dest.type_ === tool;
              }));
}

function hasDevPlatform(sources, platform) {
  return Belt_List.some(sources, (function (source) {
                return source.platform === platform;
              }));
}

function nonEmptyStringOrError(maybeString, error) {
  return Belt_Option.mapWithDefaultU(maybeString, {
              hd: error,
              tl: /* [] */0
            }, (function (value) {
                if (value.length !== 0) {
                  return /* [] */0;
                } else {
                  return {
                          hd: error,
                          tl: /* [] */0
                        };
                }
              }));
}

function nonOptionalOrError(maybeValue, error) {
  return Belt_Option.mapWithDefault(maybeValue, {
              hd: error,
              tl: /* [] */0
            }, (function (param) {
                return /* [] */0;
              }));
}

function sourcesByDestination(model, destinationId) {
  return Belt_List.keep(model.sources, (function (source) {
                var destinationMap = source.destinations;
                return Belt_List.hasU(destinationMap, destinationId, (function (sourceDestination, destinationId) {
                              return sourceDestination.destinationId === destinationId;
                            }));
              }));
}

function sourceById(model, sourceId) {
  return Belt_List.getBy(model.sources, (function (source) {
                return source.id === sourceId;
              }));
}

function destinationById(model, destinationId) {
  return Belt_List.getBy(model.destinations, (function (destination) {
                return destination.id === destinationId;
              }));
}

function validateSource(source, model) {
  var maybeDestinationError = Caml_obj.caml_equal(source.platform, "Gtm") || Caml_obj.caml_equal(source.platform, "JsonSchema") || Belt_List.length(StateUtils.destinationIdsWithoutArchived(Belt_List.map(source.destinations, (function (param) {
                  return param.destinationId;
                })), model)) !== 0 ? /* [] */0 : ({
        hd: "DestinationsMissing",
        tl: /* [] */0
      });
  var maybeDevPlatformError = nonOptionalOrError(source.platform, "DevPlatformMissing");
  var maybeNameError = nonEmptyStringOrError(source.name, "NameMissing");
  var maybeLanguageError = nonOptionalOrError(source.language, "LanguageMissing");
  var errors = Belt_List.concatMany([
        maybeDestinationError,
        maybeDevPlatformError,
        maybeNameError,
        maybeLanguageError
      ]);
  if (Belt_List.length(errors) > 0) {
    return {
            TAG: /* Error */1,
            _0: errors
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: TrackingPlanModel.Source.makeValid(source)
          };
  }
}

function validateCustom(destination) {
  var maybeNameError = nonEmptyStringOrError(destination.name, "NameMissing");
  var maybeAnalyticsPlatformError = nonOptionalOrError(destination.type_, "AnalyticsPlatformMissing");
  return Belt_List.concatMany([
              maybeNameError,
              maybeAnalyticsPlatformError
            ]);
}

function validateApiKeys(apiKeys) {
  return Belt_List.concat(Belt_Option.mapWithDefaultU(apiKeys.dev, {
                  hd: {
                    NAME: "ApiKeyMissing",
                    VAL: "Dev"
                  },
                  tl: /* [] */0
                }, (function (apiKey) {
                    if (apiKey.length !== 0) {
                      return /* [] */0;
                    } else {
                      return {
                              hd: {
                                NAME: "ApiKeyMissing",
                                VAL: "Dev"
                              },
                              tl: /* [] */0
                            };
                    }
                  })), Belt_Option.mapWithDefaultU(apiKeys.prod, {
                  hd: {
                    NAME: "ApiKeyMissing",
                    VAL: "Prod"
                  },
                  tl: /* [] */0
                }, (function (apiKey) {
                    if (apiKey.length !== 0) {
                      return /* [] */0;
                    } else {
                      return {
                              hd: {
                                NAME: "ApiKeyMissing",
                                VAL: "Prod"
                              },
                              tl: /* [] */0
                            };
                    }
                  })));
}

function validateFacebookAnalytics(destination) {
  var maybeApiKeyErrors = validateApiKeys(destination.apiKeys);
  var maybeNameError = nonEmptyStringOrError(destination.name, "NameMissing");
  var maybeAnalyticsPlatformError = nonOptionalOrError(destination.type_, "AnalyticsPlatformMissing");
  return Belt_List.concatMany([
              maybeApiKeyErrors,
              maybeNameError,
              maybeAnalyticsPlatformError
            ]);
}

function validateFirebaseAnalytics(destination) {
  var maybeNameError = nonEmptyStringOrError(destination.name, "NameMissing");
  var maybeAnalyticsPlatformError = nonOptionalOrError(destination.type_, "AnalyticsPlatformMissing");
  return Belt_List.concatMany([
              maybeNameError,
              maybeAnalyticsPlatformError
            ]);
}

function validateAmplitude(destination) {
  var maybeApiKeyErrors = validateApiKeys(destination.apiKeys);
  var maybeNameError = nonEmptyStringOrError(destination.name, "NameMissing");
  var maybeAnalyticsPlatformError = nonOptionalOrError(destination.type_, "AnalyticsPlatformMissing");
  return Belt_List.concatMany([
              maybeApiKeyErrors,
              maybeNameError,
              maybeAnalyticsPlatformError
            ]);
}

function validateMixpanel(destination) {
  var maybeApiKeyErrors = validateApiKeys(destination.apiKeys);
  var maybeNameError = nonEmptyStringOrError(destination.name, "NameMissing");
  var maybeAnalyticsPlatformError = nonOptionalOrError(destination.type_, "AnalyticsPlatformMissing");
  return Belt_List.concatMany([
              maybeApiKeyErrors,
              maybeNameError,
              maybeAnalyticsPlatformError
            ]);
}

function validateSegment(destination) {
  var maybeApiKeyErrors = validateApiKeys(destination.apiKeys);
  var maybeNameError = nonEmptyStringOrError(destination.name, "NameMissing");
  var maybeAnalyticsPlatformError = nonOptionalOrError(destination.type_, "AnalyticsPlatformMissing");
  return Belt_List.concatMany([
              maybeApiKeyErrors,
              maybeNameError,
              maybeAnalyticsPlatformError
            ]);
}

function validateMParticle(destination) {
  var maybeNameError = nonEmptyStringOrError(destination.name, "NameMissing");
  var maybeAnalyticsPlatformError = nonOptionalOrError(destination.type_, "AnalyticsPlatformMissing");
  return Belt_List.concatMany([
              maybeNameError,
              maybeAnalyticsPlatformError
            ]);
}

function validateDestination(sources, destination) {
  var isAvoManagedonGivenSources = Belt_List.someU(sources, (function (source) {
          var sourceDestinations = source.destinations;
          return Belt_List.someU(sourceDestinations, (function (param) {
                        var destinationMode = param.destinationMode;
                        if (destination.id === param.destinationId) {
                          if (/* AvoManaged */0 === destinationMode) {
                            return true;
                          } else {
                            return /* Legacy */2 === destinationMode;
                          }
                        } else {
                          return false;
                        }
                      }));
        }));
  var errors;
  if (isAvoManagedonGivenSources) {
    var match = destination.type_;
    errors = match !== undefined ? (
        match === "Segment" ? validateSegment(destination) : (
            match === "Mixpanel" ? validateMixpanel(destination) : (
                match === "Amplitude" ? validateAmplitude(destination) : (
                    match === "FirebaseAnalytics" ? validateFirebaseAnalytics(destination) : (
                        match === "FacebookAnalytics" ? validateFacebookAnalytics(destination) : (
                            match === "MParticle" ? validateMParticle(destination) : validateCustom(destination)
                          )
                      )
                  )
              )
          )
      ) : ({
          hd: "AnalyticsPlatformMissing",
          tl: /* [] */0
        });
  } else {
    var match$1 = destination.type_;
    errors = match$1 !== undefined ? validateCustom(destination) : ({
          hd: "AnalyticsPlatformMissing",
          tl: /* [] */0
        });
  }
  if (errors === /* [] */0) {
    return {
            TAG: /* Ok */0,
            _0: TrackingPlanModel.Destination.makeValid(destination)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: errors
          };
  }
}

function isProjectSettingsValid(sources, destinations, model) {
  var sourceValidations = Belt_List.mapU(sources, (function (source) {
          return validateSource(source, model);
        }));
  var destinationValidations = Belt_List.map(destinations, (function (param) {
          return validateDestination(sources, param);
        }));
  if (Belt_List.length(sources) === 0 || Belt_List.length(destinations) === 0 || Belt_List.some(sourceValidations, Belt_Result.isError)) {
    return false;
  } else {
    return !Belt_List.some(destinationValidations, Belt_Result.isError);
  }
}

function getValidSources(sources, model) {
  return Belt_List.keepMapU(Belt_List.mapU(sources, (function (source) {
                    return validateSource(source, model);
                  })), (function (source) {
                if (Belt_Result.isOk(source)) {
                  return Belt_Result.getExn(source);
                }
                
              }));
}

function getSourceName(source) {
  return Belt_Option.mapWithDefault(source.name, "Untitled Source", (function (name) {
                if (name === "") {
                  return "Untitled Source";
                } else {
                  return name;
                }
              }));
}

function getSourceNameById(model, sourceId) {
  var source = sourceById(model, sourceId);
  if (source !== undefined) {
    return getSourceName(source);
  } else {
    return "";
  }
}

function getSourceValidName(source) {
  if (source.name === "") {
    return "Untitled Source";
  } else {
    return source.name;
  }
}

function getIntegrationName(integration) {
  return Belt_Option.mapWithDefault(integration.name, "Untitled Integration", (function (x) {
                if (x === "") {
                  return "Untitled Integration";
                } else {
                  return x;
                }
              }));
}

function getIntegrationFilename(integration) {
  var match = integration.config;
  if (match !== undefined) {
    if (match.TAG === /* Taxonomy */1) {
      return Belt_Option.getWithDefault(integration.name, "avo-schema") + ".csv";
    } else {
      return Belt_Option.getWithDefault(integration.name, "avo-schema") + ".json";
    }
  } else {
    return Belt_Option.getWithDefault(integration.name, "avo-schema");
  }
}

function getIntegrationMimeType(integration) {
  var match = integration.config;
  if (match === undefined) {
    return "text/plain";
  }
  switch (match.TAG | 0) {
    case /* Taxonomy */1 :
        return "text/csv;charset=UTF-8";
    case /* Webhook */3 :
    case /* SnowplowDataStructures */4 :
    case /* MParticleDataMaster */5 :
        return "text/plain";
    default:
      return "application/json;charset=UTF-8";
  }
}

function getSourceById(id, sources) {
  return Belt_List.getByU(sources, (function (source) {
                return source.id === id;
              }));
}

function sourceIdToName(id, sources) {
  return Belt_Option.map(getSourceById(id, sources), getSourceName);
}

function isEventIncludedInSource(sourceId, $$event) {
  return Belt_List.someU($$event.includeSources, (function (includedSource) {
                return includedSource.id === sourceId;
              }));
}

function determineSendingToSources(sources, $$event) {
  var filteredSources = Belt_List.keepU(sources, (function (source) {
          return isEventIncludedInSource(source.id, $$event);
        }));
  if (sources === /* [] */0) {
    return "None";
  } else if (Caml_obj.caml_equal(filteredSources, sources)) {
    return "All";
  } else if (filteredSources === /* [] */0) {
    return "None";
  } else {
    return {
            NAME: "Some",
            VAL: filteredSources
          };
  }
}

function getValidDestinations(sources, destinations) {
  return Belt_List.keepMapU(Belt_List.map(destinations, (function (param) {
                    return validateDestination(sources, param);
                  })), (function (destination) {
                if (Belt_Result.isOk(destination)) {
                  return Belt_Result.getExn(destination);
                }
                
              }));
}

function tryToConvertSourceToValidSource(source, model) {
  var maybeValidSource = validateSource(source, model);
  return Belt_Result.mapWithDefaultU(maybeValidSource, {
              TAG: /* Error */1,
              _0: source
            }, (function (validSource) {
                return {
                        TAG: /* Ok */0,
                        _0: validSource
                      };
              }));
}

function tryToConvertSourceToValidSourceMaybe(source, model) {
  var validSource = tryToConvertSourceToValidSource(source, model);
  if (validSource.TAG === /* Ok */0) {
    return validSource._0;
  }
  
}

function tryToConvertDestinationToValidDestination(sources, destination) {
  var maybeValidDestination = validateDestination(sources, destination);
  return Belt_Result.mapWithDefaultU(maybeValidDestination, {
              TAG: /* Error */1,
              _0: destination
            }, (function (validDestination) {
                return {
                        TAG: /* Ok */0,
                        _0: validDestination
                      };
              }));
}

function tryToConvertDestinationToValidDestinationMaybe(model, destination) {
  var validDestination = tryToConvertDestinationToValidDestination(model, destination);
  if (validDestination.TAG === /* Ok */0) {
    return validDestination._0;
  }
  
}

function canLanguageHaveInterfaceFile(language) {
  if (language === "swift" || language === "kt") {
    return true;
  } else {
    return language === "objc";
  }
}

function getUserDisplayName(user) {
  return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), user.email);
}

var supportedOperations = {
  hd: "Set",
  tl: {
    hd: "SetOnce",
    tl: /* [] */0
  }
};

var devPlatforms = {
  hd: "Web",
  tl: {
    hd: "IOS",
    tl: {
      hd: "MacOS",
      tl: {
        hd: "Android",
        tl: {
          hd: "ReactNative",
          tl: {
            hd: "Expo",
            tl: {
              hd: "Node",
              tl: {
                hd: "Python",
                tl: {
                  hd: "Php",
                  tl: {
                    hd: "Unity",
                    tl: {
                      hd: "Ruby",
                      tl: {
                        hd: "DotNet",
                        tl: {
                          hd: "Java",
                          tl: {
                            hd: "Flutter",
                            tl: {
                              hd: "Go",
                              tl: {
                                hd: "Other",
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var inspectorSupportedDevPlatforms = {
  hd: "IOS",
  tl: {
    hd: "Android",
    tl: {
      hd: "Web",
      tl: {
        hd: "ReactNative",
        tl: {
          hd: "Expo",
          tl: {
            hd: "Node",
            tl: {
              hd: "Java",
              tl: {
                hd: "Go",
                tl: {
                  hd: "Flutter",
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    }
  }
};

var legacyCodegenSupportsObjects = {
  hd: [
    "js_v2",
    {
      hd: /* EventProperty */1,
      tl: {
        hd: /* UserProperty */2,
        tl: {
          hd: /* SystemProperty */0,
          tl: /* [] */0
        }
      }
    }
  ],
  tl: {
    hd: [
      "ts",
      {
        hd: /* EventProperty */1,
        tl: {
          hd: /* UserProperty */2,
          tl: /* [] */0
        }
      }
    ],
    tl: {
      hd: [
        "php",
        {
          hd: /* EventProperty */1,
          tl: /* [] */0
        }
      ],
      tl: {
        hd: [
          "swift",
          {
            hd: /* EventProperty */1,
            tl: /* [] */0
          }
        ],
        tl: {
          hd: [
            "kt",
            {
              hd: /* EventProperty */1,
              tl: /* [] */0
            }
          ],
          tl: /* [] */0
        }
      }
    }
  }
};

var codegenSupportsPlainObjects = {
  hd: [
    "kt",
    {
      hd: /* EventProperty */1,
      tl: {
        hd: /* UserProperty */2,
        tl: {
          hd: /* SystemProperty */0,
          tl: /* [] */0
        }
      }
    }
  ],
  tl: {
    hd: [
      "swift",
      {
        hd: /* EventProperty */1,
        tl: {
          hd: /* UserProperty */2,
          tl: {
            hd: /* SystemProperty */0,
            tl: /* [] */0
          }
        }
      }
    ],
    tl: {
      hd: [
        "js_v2",
        {
          hd: /* EventProperty */1,
          tl: {
            hd: /* UserProperty */2,
            tl: {
              hd: /* SystemProperty */0,
              tl: /* [] */0
            }
          }
        }
      ],
      tl: {
        hd: [
          "ts",
          {
            hd: /* EventProperty */1,
            tl: {
              hd: /* UserProperty */2,
              tl: {
                hd: /* SystemProperty */0,
                tl: /* [] */0
              }
            }
          }
        ],
        tl: {
          hd: [
            "php",
            {
              hd: /* EventProperty */1,
              tl: {
                hd: /* UserProperty */2,
                tl: {
                  hd: /* SystemProperty */0,
                  tl: /* [] */0
                }
              }
            }
          ],
          tl: {
            hd: [
              "py",
              {
                hd: /* EventProperty */1,
                tl: {
                  hd: /* UserProperty */2,
                  tl: {
                    hd: /* SystemProperty */0,
                    tl: /* [] */0
                  }
                }
              }
            ],
            tl: {
              hd: [
                "py3",
                {
                  hd: /* EventProperty */1,
                  tl: {
                    hd: /* UserProperty */2,
                    tl: {
                      hd: /* SystemProperty */0,
                      tl: /* [] */0
                    }
                  }
                }
              ],
              tl: {
                hd: [
                  "rb",
                  {
                    hd: /* EventProperty */1,
                    tl: {
                      hd: /* UserProperty */2,
                      tl: {
                        hd: /* SystemProperty */0,
                        tl: /* [] */0
                      }
                    }
                  }
                ],
                tl: {
                  hd: [
                    "re_v2",
                    {
                      hd: /* EventProperty */1,
                      tl: {
                        hd: /* UserProperty */2,
                        tl: {
                          hd: /* SystemProperty */0,
                          tl: /* [] */0
                        }
                      }
                    }
                  ],
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    }
  }
};

var codegenSupportsNameMappingAndPinningInObjects = {
  hd: [
    "kt",
    {
      hd: /* EventProperty */1,
      tl: {
        hd: /* UserProperty */2,
        tl: {
          hd: /* SystemProperty */0,
          tl: /* [] */0
        }
      }
    }
  ],
  tl: {
    hd: [
      "swift",
      {
        hd: /* EventProperty */1,
        tl: {
          hd: /* UserProperty */2,
          tl: {
            hd: /* SystemProperty */0,
            tl: /* [] */0
          }
        }
      }
    ],
    tl: {
      hd: [
        "js_v2",
        {
          hd: /* EventProperty */1,
          tl: {
            hd: /* UserProperty */2,
            tl: {
              hd: /* SystemProperty */0,
              tl: /* [] */0
            }
          }
        }
      ],
      tl: {
        hd: [
          "ts",
          {
            hd: /* EventProperty */1,
            tl: /* [] */0
          }
        ],
        tl: {
          hd: [
            "php",
            {
              hd: /* EventProperty */1,
              tl: /* [] */0
            }
          ],
          tl: /* [] */0
        }
      }
    }
  }
};

var codegenSupportsNameMappingAndPinningInListsOfObjects = {
  hd: [
    "kt",
    {
      hd: /* EventProperty */1,
      tl: {
        hd: /* UserProperty */2,
        tl: {
          hd: /* SystemProperty */0,
          tl: /* [] */0
        }
      }
    }
  ],
  tl: {
    hd: [
      "swift",
      {
        hd: /* EventProperty */1,
        tl: {
          hd: /* UserProperty */2,
          tl: {
            hd: /* SystemProperty */0,
            tl: /* [] */0
          }
        }
      }
    ],
    tl: {
      hd: [
        "js_v2",
        {
          hd: /* EventProperty */1,
          tl: {
            hd: /* UserProperty */2,
            tl: {
              hd: /* SystemProperty */0,
              tl: /* [] */0
            }
          }
        }
      ],
      tl: {
        hd: [
          "ts",
          {
            hd: /* EventProperty */1,
            tl: /* [] */0
          }
        ],
        tl: /* [] */0
      }
    }
  }
};

var codegenSupportsNameMappingAndPinningInNestedObjects = {
  hd: [
    "kt",
    {
      hd: /* EventProperty */1,
      tl: {
        hd: /* UserProperty */2,
        tl: {
          hd: /* SystemProperty */0,
          tl: /* [] */0
        }
      }
    }
  ],
  tl: {
    hd: [
      "swift",
      {
        hd: /* EventProperty */1,
        tl: {
          hd: /* UserProperty */2,
          tl: {
            hd: /* SystemProperty */0,
            tl: /* [] */0
          }
        }
      }
    ],
    tl: {
      hd: [
        "js_v2",
        {
          hd: /* EventProperty */1,
          tl: {
            hd: /* UserProperty */2,
            tl: {
              hd: /* SystemProperty */0,
              tl: /* [] */0
            }
          }
        }
      ],
      tl: /* [] */0
    }
  }
};

var intercomPropertyLimit = 5;

var defaultFilename = "AvoAnalytics";

var defaultPath = "./src";

var segmentIntegrations = {
  hd: "Redshift",
  tl: {
    hd: "BigQuery",
    tl: {
      hd: "Postgres",
      tl: {
        hd: "Snowflake",
        tl: {
          hd: "IBM Db2 Warehouse",
          tl: {
            hd: "Azure SQL Data Warehouse",
            tl: {
              hd: "Apptimize",
              tl: {
                hd: "Evergage",
                tl: {
                  hd: "Leanplum",
                  tl: {
                    hd: "Monetate",
                    tl: {
                      hd: "Optimizely Web",
                      tl: {
                        hd: "Optimizely Full Stack",
                        tl: {
                          hd: "Split",
                          tl: {
                            hd: "Taplytics",
                            tl: {
                              hd: "Visual Website Optimizer",
                              tl: {
                                hd: "AdLearn Open Platform",
                                tl: {
                                  hd: "AdRoll",
                                  tl: {
                                    hd: "AdWords",
                                    tl: {
                                      hd: "AdWords Remarketing Lists",
                                      tl: {
                                        hd: "AppNexus",
                                        tl: {
                                          hd: "Bing Ads",
                                          tl: {
                                            hd: "Criteo",
                                            tl: {
                                              hd: "DoubleClick Floodlight",
                                              tl: {
                                                hd: "Facebook App Events",
                                                tl: {
                                                  hd: "Facebook Offline Conversions",
                                                  tl: {
                                                    hd: "Facebook Pixel",
                                                    tl: {
                                                      hd: "Google AdWords New",
                                                      tl: {
                                                        hd: "HasOffers",
                                                        tl: {
                                                          hd: "LinkedIn Insight Tag",
                                                          tl: {
                                                            hd: "MediaMath",
                                                            tl: {
                                                              hd: "Millennial Media",
                                                              tl: {
                                                                hd: "Nanigans",
                                                                tl: {
                                                                  hd: "Perfect Audience",
                                                                  tl: {
                                                                    hd: "Personas Facebook Custom Audiences",
                                                                    tl: {
                                                                      hd: "Pinterest Tag",
                                                                      tl: {
                                                                        hd: "Quantcast",
                                                                        tl: {
                                                                          hd: "QuanticMind",
                                                                          tl: {
                                                                            hd: "Quora Conversion Pixel",
                                                                            tl: {
                                                                              hd: "RadiumOne Connect",
                                                                              tl: {
                                                                                hd: "ShareASale",
                                                                                tl: {
                                                                                  hd: "SimpleReach",
                                                                                  tl: {
                                                                                    hd: "TV Squared",
                                                                                    tl: {
                                                                                      hd: "Twitter Ads",
                                                                                      tl: {
                                                                                        hd: "Yellowhammer",
                                                                                        tl: {
                                                                                          hd: "Adobe Analytics",
                                                                                          tl: {
                                                                                            hd: "Alexa",
                                                                                            tl: {
                                                                                              hd: "Amazon Kinesis",
                                                                                              tl: {
                                                                                                hd: "Amazon Kinesis Firehose",
                                                                                                tl: {
                                                                                                  hd: "Amazon S3",
                                                                                                  tl: {
                                                                                                    hd: "Amplitude",
                                                                                                    tl: {
                                                                                                      hd: "Aptrinsic",
                                                                                                      tl: {
                                                                                                        hd: "Calq",
                                                                                                        tl: {
                                                                                                          hd: "Clicky",
                                                                                                          tl: {
                                                                                                            hd: "Countly",
                                                                                                            tl: {
                                                                                                              hd: "EPICA",
                                                                                                              tl: {
                                                                                                                hd: "Firebase",
                                                                                                                tl: {
                                                                                                                  hd: "Flurry",
                                                                                                                  tl: {
                                                                                                                    hd: "FoxMetrics",
                                                                                                                    tl: {
                                                                                                                      hd: "Gauges",
                                                                                                                      tl: {
                                                                                                                        hd: "goedle.io",
                                                                                                                        tl: {
                                                                                                                          hd: "Google Analytics",
                                                                                                                          tl: {
                                                                                                                            hd: "GoSquared",
                                                                                                                            tl: {
                                                                                                                              hd: "Heap",
                                                                                                                              tl: {
                                                                                                                                hd: "HitTail",
                                                                                                                                tl: {
                                                                                                                                  hd: "HubSpot",
                                                                                                                                  tl: {
                                                                                                                                    hd: "IBM UBX",
                                                                                                                                    tl: {
                                                                                                                                      hd: "Indicative",
                                                                                                                                      tl: {
                                                                                                                                        hd: "Infinario",
                                                                                                                                        tl: {
                                                                                                                                          hd: "Keen",
                                                                                                                                          tl: {
                                                                                                                                            hd: "KISSmetrics",
                                                                                                                                            tl: {
                                                                                                                                              hd: "Librato",
                                                                                                                                              tl: {
                                                                                                                                                hd: "Localytics",
                                                                                                                                                tl: {
                                                                                                                                                  hd: "Lytics",
                                                                                                                                                  tl: {
                                                                                                                                                    hd: "Madkudu",
                                                                                                                                                    tl: {
                                                                                                                                                      hd: "Mixpanel",
                                                                                                                                                      tl: {
                                                                                                                                                        hd: "MoEngage",
                                                                                                                                                        tl: {
                                                                                                                                                          hd: "New Relic",
                                                                                                                                                          tl: {
                                                                                                                                                            hd: "Pendo",
                                                                                                                                                            tl: {
                                                                                                                                                              hd: "Piwik",
                                                                                                                                                              tl: {
                                                                                                                                                                hd: "Pointillist",
                                                                                                                                                                tl: {
                                                                                                                                                                  hd: "ScopeAI",
                                                                                                                                                                  tl: {
                                                                                                                                                                    hd: "Sherlock",
                                                                                                                                                                    tl: {
                                                                                                                                                                      hd: "Swrve",
                                                                                                                                                                      tl: {
                                                                                                                                                                        hd: "Tractionboard",
                                                                                                                                                                        tl: {
                                                                                                                                                                          hd: "Unwaffle",
                                                                                                                                                                          tl: {
                                                                                                                                                                            hd: "Whale Watch",
                                                                                                                                                                            tl: {
                                                                                                                                                                              hd: "Wigzo",
                                                                                                                                                                              tl: {
                                                                                                                                                                                hd: "Woopra",
                                                                                                                                                                                tl: {
                                                                                                                                                                                  hd: "Yandex Metrica",
                                                                                                                                                                                  tl: {
                                                                                                                                                                                    hd: "Adjust",
                                                                                                                                                                                    tl: {
                                                                                                                                                                                      hd: "AppsFlyer",
                                                                                                                                                                                      tl: {
                                                                                                                                                                                        hd: "Attribution",
                                                                                                                                                                                        tl: {
                                                                                                                                                                                          hd: "Convertro",
                                                                                                                                                                                          tl: {
                                                                                                                                                                                            hd: "Dreamdata IO",
                                                                                                                                                                                            tl: {
                                                                                                                                                                                              hd: "Rockerbox",
                                                                                                                                                                                              tl: {
                                                                                                                                                                                                hd: "Improvely",
                                                                                                                                                                                                tl: {
                                                                                                                                                                                                  hd: "Kochava",
                                                                                                                                                                                                  tl: {
                                                                                                                                                                                                    hd: "Tapstream",
                                                                                                                                                                                                    tl: {
                                                                                                                                                                                                      hd: "TUNE",
                                                                                                                                                                                                      tl: {
                                                                                                                                                                                                        hd: "Freshsales",
                                                                                                                                                                                                        tl: {
                                                                                                                                                                                                          hd: "Sailthru",
                                                                                                                                                                                                          tl: {
                                                                                                                                                                                                            hd: "Salesforce",
                                                                                                                                                                                                            tl: {
                                                                                                                                                                                                              hd: "Whale Alerts",
                                                                                                                                                                                                              tl: {
                                                                                                                                                                                                                hd: "Wishpond",
                                                                                                                                                                                                                tl: {
                                                                                                                                                                                                                  hd: "ChurnZero",
                                                                                                                                                                                                                  tl: {
                                                                                                                                                                                                                    hd: "ClientSuccess",
                                                                                                                                                                                                                    tl: {
                                                                                                                                                                                                                      hd: "Elevio",
                                                                                                                                                                                                                      tl: {
                                                                                                                                                                                                                        hd: "Gainsight",
                                                                                                                                                                                                                        tl: {
                                                                                                                                                                                                                          hd: "Help Scout",
                                                                                                                                                                                                                          tl: {
                                                                                                                                                                                                                            hd: "Kustomer",
                                                                                                                                                                                                                            tl: {
                                                                                                                                                                                                                              hd: "Natero",
                                                                                                                                                                                                                              tl: {
                                                                                                                                                                                                                                hd: "Planhat",
                                                                                                                                                                                                                                tl: {
                                                                                                                                                                                                                                  hd: "Ramen",
                                                                                                                                                                                                                                  tl: {
                                                                                                                                                                                                                                    hd: "Salesmachine",
                                                                                                                                                                                                                                    tl: {
                                                                                                                                                                                                                                      hd: "Slack",
                                                                                                                                                                                                                                      tl: {
                                                                                                                                                                                                                                        hd: "Totango",
                                                                                                                                                                                                                                        tl: {
                                                                                                                                                                                                                                          hd: "Vitally",
                                                                                                                                                                                                                                          tl: {
                                                                                                                                                                                                                                            hd: "Zendesk",
                                                                                                                                                                                                                                            tl: {
                                                                                                                                                                                                                                              hd: "Zopim",
                                                                                                                                                                                                                                              tl: {
                                                                                                                                                                                                                                                hd: "Branch Metrics",
                                                                                                                                                                                                                                                tl: {
                                                                                                                                                                                                                                                  hd: "ActiveCampaign",
                                                                                                                                                                                                                                                  tl: {
                                                                                                                                                                                                                                                    hd: "AutopilotHQ",
                                                                                                                                                                                                                                                    tl: {
                                                                                                                                                                                                                                                      hd: "Bronto",
                                                                                                                                                                                                                                                      tl: {
                                                                                                                                                                                                                                                        hd: "Customer.io",
                                                                                                                                                                                                                                                        tl: {
                                                                                                                                                                                                                                                          hd: "Drip",
                                                                                                                                                                                                                                                          tl: {
                                                                                                                                                                                                                                                            hd: "Eloqua",
                                                                                                                                                                                                                                                            tl: {
                                                                                                                                                                                                                                                              hd: "Email Aptitude",
                                                                                                                                                                                                                                                              tl: {
                                                                                                                                                                                                                                                                hd: "Klaviyo",
                                                                                                                                                                                                                                                                tl: {
                                                                                                                                                                                                                                                                  hd: "MailChimp",
                                                                                                                                                                                                                                                                  tl: {
                                                                                                                                                                                                                                                                    hd: "Mailjet",
                                                                                                                                                                                                                                                                    tl: {
                                                                                                                                                                                                                                                                      hd: "Marketo V2",
                                                                                                                                                                                                                                                                      tl: {
                                                                                                                                                                                                                                                                        hd: "Moosend",
                                                                                                                                                                                                                                                                        tl: {
                                                                                                                                                                                                                                                                          hd: "Nudgespot",
                                                                                                                                                                                                                                                                          tl: {
                                                                                                                                                                                                                                                                            hd: "Pardot",
                                                                                                                                                                                                                                                                            tl: {
                                                                                                                                                                                                                                                                              hd: "Responsys",
                                                                                                                                                                                                                                                                              tl: {
                                                                                                                                                                                                                                                                                hd: "Salesforce Marketing Cloud",
                                                                                                                                                                                                                                                                                tl: {
                                                                                                                                                                                                                                                                                  hd: "Seg",
                                                                                                                                                                                                                                                                                  tl: {
                                                                                                                                                                                                                                                                                    hd: "Vero",
                                                                                                                                                                                                                                                                                    tl: {
                                                                                                                                                                                                                                                                                      hd: "Clearbit Enrichment",
                                                                                                                                                                                                                                                                                      tl: {
                                                                                                                                                                                                                                                                                        hd: "Clearbit Reveal",
                                                                                                                                                                                                                                                                                        tl: {
                                                                                                                                                                                                                                                                                          hd: "Crazy Egg",
                                                                                                                                                                                                                                                                                          tl: {
                                                                                                                                                                                                                                                                                            hd: "FullStory",
                                                                                                                                                                                                                                                                                            tl: {
                                                                                                                                                                                                                                                                                              hd: "Hotjar",
                                                                                                                                                                                                                                                                                              tl: {
                                                                                                                                                                                                                                                                                                hd: "Inspectlet",
                                                                                                                                                                                                                                                                                                tl: {
                                                                                                                                                                                                                                                                                                  hd: "Lucky Orange",
                                                                                                                                                                                                                                                                                                  tl: {
                                                                                                                                                                                                                                                                                                    hd: "Mouseflow",
                                                                                                                                                                                                                                                                                                    tl: {
                                                                                                                                                                                                                                                                                                      hd: "MouseStats",
                                                                                                                                                                                                                                                                                                      tl: {
                                                                                                                                                                                                                                                                                                        hd: "Navilytics",
                                                                                                                                                                                                                                                                                                        tl: {
                                                                                                                                                                                                                                                                                                          hd: "Drift",
                                                                                                                                                                                                                                                                                                          tl: {
                                                                                                                                                                                                                                                                                                            hd: "Intercom",
                                                                                                                                                                                                                                                                                                            tl: {
                                                                                                                                                                                                                                                                                                              hd: "LiveChat",
                                                                                                                                                                                                                                                                                                              tl: {
                                                                                                                                                                                                                                                                                                                hd: "Olark",
                                                                                                                                                                                                                                                                                                                tl: {
                                                                                                                                                                                                                                                                                                                  hd: "SnapEngage",
                                                                                                                                                                                                                                                                                                                  tl: {
                                                                                                                                                                                                                                                                                                                    hd: "Userlike",
                                                                                                                                                                                                                                                                                                                    tl: {
                                                                                                                                                                                                                                                                                                                      hd: "PersistIQ",
                                                                                                                                                                                                                                                                                                                      tl: {
                                                                                                                                                                                                                                                                                                                        hd: "Upcall",
                                                                                                                                                                                                                                                                                                                        tl: {
                                                                                                                                                                                                                                                                                                                          hd: "Atatus",
                                                                                                                                                                                                                                                                                                                          tl: {
                                                                                                                                                                                                                                                                                                                            hd: "BugHerd",
                                                                                                                                                                                                                                                                                                                            tl: {
                                                                                                                                                                                                                                                                                                                              hd: "Bugsnag",
                                                                                                                                                                                                                                                                                                                              tl: {
                                                                                                                                                                                                                                                                                                                                hd: "Crittercism",
                                                                                                                                                                                                                                                                                                                                tl: {
                                                                                                                                                                                                                                                                                                                                  hd: "Errorception",
                                                                                                                                                                                                                                                                                                                                  tl: {
                                                                                                                                                                                                                                                                                                                                    hd: "Pingdom",
                                                                                                                                                                                                                                                                                                                                    tl: {
                                                                                                                                                                                                                                                                                                                                      hd: "Rollbar",
                                                                                                                                                                                                                                                                                                                                      tl: {
                                                                                                                                                                                                                                                                                                                                        hd: "Sentry",
                                                                                                                                                                                                                                                                                                                                        tl: {
                                                                                                                                                                                                                                                                                                                                          hd: "Track JS",
                                                                                                                                                                                                                                                                                                                                          tl: {
                                                                                                                                                                                                                                                                                                                                            hd: "Appcues",
                                                                                                                                                                                                                                                                                                                                            tl: {
                                                                                                                                                                                                                                                                                                                                              hd: "Chameleon",
                                                                                                                                                                                                                                                                                                                                              tl: {
                                                                                                                                                                                                                                                                                                                                                hd: "ClearBrain",
                                                                                                                                                                                                                                                                                                                                                tl: {
                                                                                                                                                                                                                                                                                                                                                  hd: "Hello Bar",
                                                                                                                                                                                                                                                                                                                                                  tl: {
                                                                                                                                                                                                                                                                                                                                                    hd: "Hull",
                                                                                                                                                                                                                                                                                                                                                    tl: {
                                                                                                                                                                                                                                                                                                                                                      hd: "Intellimize",
                                                                                                                                                                                                                                                                                                                                                      tl: {
                                                                                                                                                                                                                                                                                                                                                        hd: "Mutiny",
                                                                                                                                                                                                                                                                                                                                                        tl: {
                                                                                                                                                                                                                                                                                                                                                          hd: "Personyze",
                                                                                                                                                                                                                                                                                                                                                          tl: {
                                                                                                                                                                                                                                                                                                                                                            hd: "Proof Experiences",
                                                                                                                                                                                                                                                                                                                                                            tl: {
                                                                                                                                                                                                                                                                                                                                                              hd: "Spinnakr",
                                                                                                                                                                                                                                                                                                                                                              tl: {
                                                                                                                                                                                                                                                                                                                                                                hd: "tray.io",
                                                                                                                                                                                                                                                                                                                                                                tl: {
                                                                                                                                                                                                                                                                                                                                                                  hd: "UserEngage",
                                                                                                                                                                                                                                                                                                                                                                  tl: {
                                                                                                                                                                                                                                                                                                                                                                    hd: "Blendo",
                                                                                                                                                                                                                                                                                                                                                                    tl: {
                                                                                                                                                                                                                                                                                                                                                                      hd: "Google Cloud PubSub",
                                                                                                                                                                                                                                                                                                                                                                      tl: {
                                                                                                                                                                                                                                                                                                                                                                        hd: "Iron.io",
                                                                                                                                                                                                                                                                                                                                                                        tl: {
                                                                                                                                                                                                                                                                                                                                                                          hd: "Repeater",
                                                                                                                                                                                                                                                                                                                                                                          tl: {
                                                                                                                                                                                                                                                                                                                                                                            hd: "Stitch Data",
                                                                                                                                                                                                                                                                                                                                                                            tl: {
                                                                                                                                                                                                                                                                                                                                                                              hd: "Treasure Data",
                                                                                                                                                                                                                                                                                                                                                                              tl: {
                                                                                                                                                                                                                                                                                                                                                                                hd: "Webhooks",
                                                                                                                                                                                                                                                                                                                                                                                tl: {
                                                                                                                                                                                                                                                                                                                                                                                  hd: "Xplenty",
                                                                                                                                                                                                                                                                                                                                                                                  tl: {
                                                                                                                                                                                                                                                                                                                                                                                    hd: "Zapier",
                                                                                                                                                                                                                                                                                                                                                                                    tl: {
                                                                                                                                                                                                                                                                                                                                                                                      hd: "Ambassador",
                                                                                                                                                                                                                                                                                                                                                                                      tl: {
                                                                                                                                                                                                                                                                                                                                                                                        hd: "Curebit",
                                                                                                                                                                                                                                                                                                                                                                                        tl: {
                                                                                                                                                                                                                                                                                                                                                                                          hd: "Friendbuy",
                                                                                                                                                                                                                                                                                                                                                                                          tl: {
                                                                                                                                                                                                                                                                                                                                                                                            hd: "SaaSquatch",
                                                                                                                                                                                                                                                                                                                                                                                            tl: {
                                                                                                                                                                                                                                                                                                                                                                                              hd: "Trustpilot",
                                                                                                                                                                                                                                                                                                                                                                                              tl: {
                                                                                                                                                                                                                                                                                                                                                                                                hd: "Braze",
                                                                                                                                                                                                                                                                                                                                                                                                tl: {
                                                                                                                                                                                                                                                                                                                                                                                                  hd: "Batch",
                                                                                                                                                                                                                                                                                                                                                                                                  tl: {
                                                                                                                                                                                                                                                                                                                                                                                                    hd: "Blueshift",
                                                                                                                                                                                                                                                                                                                                                                                                    tl: {
                                                                                                                                                                                                                                                                                                                                                                                                      hd: "CleverTap",
                                                                                                                                                                                                                                                                                                                                                                                                      tl: {
                                                                                                                                                                                                                                                                                                                                                                                                        hd: "Iterable",
                                                                                                                                                                                                                                                                                                                                                                                                        tl: {
                                                                                                                                                                                                                                                                                                                                                                                                          hd: "Kahuna",
                                                                                                                                                                                                                                                                                                                                                                                                          tl: {
                                                                                                                                                                                                                                                                                                                                                                                                            hd: "OneSignal",
                                                                                                                                                                                                                                                                                                                                                                                                            tl: {
                                                                                                                                                                                                                                                                                                                                                                                                              hd: "Outbound",
                                                                                                                                                                                                                                                                                                                                                                                                              tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                hd: "Urban Airship",
                                                                                                                                                                                                                                                                                                                                                                                                                tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                  hd: "WebEngage",
                                                                                                                                                                                                                                                                                                                                                                                                                  tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                    hd: "Zaius",
                                                                                                                                                                                                                                                                                                                                                                                                                    tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                      hd: "Castle",
                                                                                                                                                                                                                                                                                                                                                                                                                      tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                        hd: "Watchtower",
                                                                                                                                                                                                                                                                                                                                                                                                                        tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                          hd: "Delighted",
                                                                                                                                                                                                                                                                                                                                                                                                                          tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                            hd: "Promoter.io",
                                                                                                                                                                                                                                                                                                                                                                                                                            tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                              hd: "Qualaroo",
                                                                                                                                                                                                                                                                                                                                                                                                                              tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                                hd: "SatisMeter",
                                                                                                                                                                                                                                                                                                                                                                                                                                tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                                  hd: "Savio",
                                                                                                                                                                                                                                                                                                                                                                                                                                  tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                                    hd: "UserVoice",
                                                                                                                                                                                                                                                                                                                                                                                                                                    tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                                      hd: "Wootric",
                                                                                                                                                                                                                                                                                                                                                                                                                                      tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                                        hd: "Google Tag Manager",
                                                                                                                                                                                                                                                                                                                                                                                                                                        tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                                          hd: "Chartbeat",
                                                                                                                                                                                                                                                                                                                                                                                                                                          tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                                            hd: "comScore",
                                                                                                                                                                                                                                                                                                                                                                                                                                            tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                                              hd: "Parsely",
                                                                                                                                                                                                                                                                                                                                                                                                                                              tl: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                hd: "Youbora",
                                                                                                                                                                                                                                                                                                                                                                                                                                                tl: /* [] */0
                                                                                                                                                                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                              }
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                              }
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                          }
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                      }
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                  }
                                                                                                                                                                                                                }
                                                                                                                                                                                                              }
                                                                                                                                                                                                            }
                                                                                                                                                                                                          }
                                                                                                                                                                                                        }
                                                                                                                                                                                                      }
                                                                                                                                                                                                    }
                                                                                                                                                                                                  }
                                                                                                                                                                                                }
                                                                                                                                                                                              }
                                                                                                                                                                                            }
                                                                                                                                                                                          }
                                                                                                                                                                                        }
                                                                                                                                                                                      }
                                                                                                                                                                                    }
                                                                                                                                                                                  }
                                                                                                                                                                                }
                                                                                                                                                                              }
                                                                                                                                                                            }
                                                                                                                                                                          }
                                                                                                                                                                        }
                                                                                                                                                                      }
                                                                                                                                                                    }
                                                                                                                                                                  }
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

exports.supportedOperations = supportedOperations;
exports.operationLabel = operationLabel;
exports.devPlatforms = devPlatforms;
exports.devPlatformLabel = devPlatformLabel;
exports.inspectorSupportedDevPlatforms = inspectorSupportedDevPlatforms;
exports.inspectorSupportedLanguages = inspectorSupportedLanguages;
exports.analyticsTools = analyticsTools;
exports.groupedAnalyticsTools = groupedAnalyticsTools;
exports.analyticsToolsLabel = analyticsToolsLabel;
exports.supportsObjects = supportsObjects;
exports.legacyCodegenSupportsObjects = legacyCodegenSupportsObjects;
exports.codegenSupportsPlainObjects = codegenSupportsPlainObjects;
exports.codegenSupportsNameMappingAndPinningInObjects = codegenSupportsNameMappingAndPinningInObjects;
exports.codegenSupportsNameMappingAndPinningInListsOfObjects = codegenSupportsNameMappingAndPinningInListsOfObjects;
exports.codegenSupportsNameMappingAndPinningInNestedObjects = codegenSupportsNameMappingAndPinningInNestedObjects;
exports.languagePlatformDestinationsThatSupportGroupAnalytics = languagePlatformDestinationsThatSupportGroupAnalytics;
exports.doesLanguagePlatformDestinationSupportGroups = doesLanguagePlatformDestinationSupportGroups;
exports.areGroupPropertiesSupported = areGroupPropertiesSupported;
exports.hasAtLeastOneDestinationThatDoesNotSupportGroupProperties = hasAtLeastOneDestinationThatDoesNotSupportGroupProperties;
exports.getUnsupportedSetOnceDestinationLabels = getUnsupportedSetOnceDestinationLabels;
exports.getSupportedLanguages = getSupportedLanguages;
exports.supportsInvocations = supportsInvocations;
exports.filenameHint = filenameHint;
exports.languageExtensionHint = languageExtensionHint;
exports.languageLabel = languageLabel;
exports.languagePlatformDestinationLabel = languagePlatformDestinationLabel;
exports.intercomPropertyLimit = intercomPropertyLimit;
exports.getSupportedAvoManagedDestinations = getSupportedAvoManagedDestinations;
exports.getSupportedDestinations = getSupportedDestinations;
exports.setDestinationModeForCodegen = setDestinationModeForCodegen;
exports.convertDestinationInterfacesToCustomInModel = convertDestinationInterfacesToCustomInModel;
exports.hasAnalyticsTool = hasAnalyticsTool;
exports.hasDevPlatform = hasDevPlatform;
exports.defaultFilename = defaultFilename;
exports.defaultPath = defaultPath;
exports.nonEmptyStringOrError = nonEmptyStringOrError;
exports.nonOptionalOrError = nonOptionalOrError;
exports.sourcesByDestination = sourcesByDestination;
exports.sourceById = sourceById;
exports.destinationById = destinationById;
exports.validateSource = validateSource;
exports.validateCustom = validateCustom;
exports.validateApiKeys = validateApiKeys;
exports.validateFacebookAnalytics = validateFacebookAnalytics;
exports.validateFirebaseAnalytics = validateFirebaseAnalytics;
exports.validateAmplitude = validateAmplitude;
exports.validateMixpanel = validateMixpanel;
exports.validateSegment = validateSegment;
exports.validateMParticle = validateMParticle;
exports.validateDestination = validateDestination;
exports.isProjectSettingsValid = isProjectSettingsValid;
exports.getValidSources = getValidSources;
exports.getSourceName = getSourceName;
exports.getSourceNameById = getSourceNameById;
exports.getSourceValidName = getSourceValidName;
exports.getIntegrationName = getIntegrationName;
exports.getIntegrationFilename = getIntegrationFilename;
exports.getIntegrationMimeType = getIntegrationMimeType;
exports.getSourceById = getSourceById;
exports.sourceIdToName = sourceIdToName;
exports.isEventIncludedInSource = isEventIncludedInSource;
exports.determineSendingToSources = determineSendingToSources;
exports.getValidDestinations = getValidDestinations;
exports.tryToConvertSourceToValidSource = tryToConvertSourceToValidSource;
exports.tryToConvertSourceToValidSourceMaybe = tryToConvertSourceToValidSourceMaybe;
exports.tryToConvertDestinationToValidDestination = tryToConvertDestinationToValidDestination;
exports.tryToConvertDestinationToValidDestinationMaybe = tryToConvertDestinationToValidDestinationMaybe;
exports.canLanguageHaveInterfaceFile = canLanguageHaveInterfaceFile;
exports.segmentIntegrations = segmentIntegrations;
exports.getUserDisplayName = getUserDisplayName;
/* StateUtils Not a pure module */
