// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Actions = require("./actions.bs.js");
var DateFns = require("./DateFns.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Sharing = require("../../shared/models/Sharing.bs.js");
var Shortid = require("shortid");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Workspace = require("../../model/src/Workspace.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var SavedViews = require("../../shared/models/SavedViews.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var App = require("firebase/app");
var React = require("@sentry/react");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var OnboardingConfig = require("./OnboardingConfig.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");
var PublicEmailDomains = require("../../shared/utils/publicEmailDomains.bs.js");
var InspectorVersionValidationIntent = require("./InspectorVersionValidationIntent.bs.js");

function makeId(param) {
  return Firebase.app(undefined).firestore().collection("keygen").doc().id;
}

function uploadPicture(storagePath, dbRef, file) {
  return new Promise((function (resolvePromise, reject) {
                var img = new Image();
                img.onload = (function (param) {
                    var width = img.naturalWidth;
                    var height = img.naturalHeight;
                    URL.revokeObjectURL(img.src);
                    var key = makeId(undefined);
                    var imageUrl = storagePath + key;
                    var storageRef = Firebase.app(undefined).storage().ref(imageUrl);
                    var metadata = {
                      cacheControl: "public,max-age=31557600"
                    };
                    $$Promise.$$catch(storageRef.put(file, metadata).then(function (param) {
                                  return fetch("https://avo-web-app.appspot.com/servingurl", Fetch.RequestInit.make(/* Post */2, {
                                                    Accept: "application/json",
                                                    "Content-Type": "application/x-www-form-urlencoded"
                                                  }, Caml_option.some("image=" + imageUrl), undefined, undefined, /* CORS */3, undefined, undefined, /* Follow */0, undefined, undefined, undefined)(undefined));
                                }).then(function (prim) {
                                return prim.json();
                              }).then(function (response) {
                              var payload = Js_json.decodeObject(response);
                              if (payload === undefined) {
                                return Promise.resolve(reject(Js_exn.raiseError("Failed to fetch serving url")));
                              }
                              var servingUrl = Js_dict.get(Caml_option.valFromOption(payload), "servingUrl");
                              if (servingUrl === undefined) {
                                return Promise.resolve(reject(Js_exn.raiseError("Failed to parse serving url response")));
                              }
                              var picture = {
                                height: height,
                                width: width,
                                storagePath: imageUrl,
                                url: Json_decode.string(Caml_option.valFromOption(servingUrl))
                              };
                              var userUpdate = {
                                picture: picture
                              };
                              return dbRef.set(userUpdate, {"merge": true}).then(function (param) {
                                          return Promise.resolve(resolvePromise(picture));
                                        });
                            }), (function (param) {
                            return Promise.resolve(reject(Js_exn.raiseError("error")));
                          }));
                    
                  });
                img.src = URL.createObjectURL(file);
                
              }));
}

function uploadScreenshot(path, file) {
  return new Promise((function (resolvePromise, reject) {
                var img = new Image();
                img.onload = (function (param) {
                    var width = img.naturalWidth;
                    var height = img.naturalHeight;
                    URL.revokeObjectURL(img.src);
                    var key = makeId(undefined);
                    var storagePath = path + key;
                    var storageRef = Firebase.app(undefined).storage().ref(storagePath);
                    var metadata = {
                      cacheControl: "public,max-age=31557600"
                    };
                    $$Promise.$$catch(storageRef.put(file, metadata).then(function (param) {
                                  return fetch("https://avo-web-app.appspot.com/servingurl", Fetch.RequestInit.make(/* Post */2, {
                                                    Accept: "application/json",
                                                    "Content-Type": "application/x-www-form-urlencoded"
                                                  }, Caml_option.some("image=" + storagePath), undefined, undefined, /* CORS */3, undefined, undefined, /* Follow */0, undefined, undefined, undefined)(undefined));
                                }).then(function (prim) {
                                return prim.json();
                              }).then(function (response) {
                              var payload = Js_json.decodeObject(response);
                              if (payload === undefined) {
                                return Promise.resolve(reject(Js_exn.raiseError("Failed to fetch serving url")));
                              }
                              var servingUrl = Js_dict.get(Caml_option.valFromOption(payload), "servingUrl");
                              if (servingUrl === undefined) {
                                return Promise.resolve(reject(Js_exn.raiseError("Failed to parse serving url response")));
                              }
                              var picture = {
                                height: height,
                                width: width,
                                storagePath: storagePath,
                                url: Json_decode.string(Caml_option.valFromOption(servingUrl))
                              };
                              return Promise.resolve(resolvePromise(picture));
                            }), (function (param) {
                            return Promise.resolve(reject(Js_exn.raiseError("Error uploading screenshot")));
                          }));
                    
                  });
                img.src = URL.createObjectURL(file);
                
              }));
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function createSchema(userId) {
  var batch = Firebase.app(undefined).firestore().batch();
  var schemaRef = Firebase.app(undefined).firestore().collection("schemas").doc();
  var schemaId = schemaRef.id;
  var email = Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).email;
  var now = App.default.firestore.Timestamp.fromDate(new Date());
  var match = email.split("@");
  var schemaName;
  if (match.length !== 2) {
    schemaName = "Untitled Tracking Plan";
  } else {
    var name = match[0];
    var domain = match[1];
    schemaName = Belt_Array.some(PublicEmailDomains.domains, (function (d) {
            return d === domain;
          })) ? capitalize(name) + "'s Tracking Plan" : capitalize(Belt_Array.getExn(domain.split("."), 0)) + "'s Tracking Plan";
  }
  var schema = {
    id: schemaId,
    defaultBranch: null,
    branches: null,
    name: schemaName,
    createdAt: now,
    billingStatus: "free",
    avoSchemaVersion: 2,
    onboardingV2: null,
    validationConfig: null,
    plan: "freev2",
    featureFlags: {
      InvocationMetrics: true,
      UseConstructorBasedAnalytics: true,
      RequiredPageMethodInCutomDestinationInterfaceKt: true,
      PhpMigratedToSnakeCase: true,
      UseNewSegmentReactNative: true,
      ImprovedPropertyGroupsUnfolding: true,
      ES6: true,
      ServerTrackingWithDeviceId: true
    },
    paymentPlan: {
      description: "<div><div><b>Includes:</b></div> <div>Code generated custom tracking libraries</div> <div>Unlimited events and properties</div> <div>Unlimited goals and metrics</div> <div>Unlimited branches</div> <br /> <div><b>$15/user per month</b></div> </div>",
      paddleId: "547587",
      pricePerPerson: "$15",
      quantity: 1,
      title: "Team Plan"
    }
  };
  var aclRef = schemaRef.collection("acls").doc(userId);
  var member = {
    id: userId,
    role: Models.Role.tToJs("Admin"),
    type_: "user",
    createdAt: App.default.firestore.FieldValue.serverTimestamp()
  };
  var userSchemaRef = Firebase.app(undefined).firestore().collection("users").doc(userId).collection("schemas").doc(schemaId);
  var userSchema = {
    id: schemaId,
    lastUsedAt: App.default.firestore.FieldValue.serverTimestamp()
  };
  batch.set(schemaRef, schema);
  batch.set(aclRef, member);
  batch.set(userSchemaRef, userSchema);
  return $$Promise.$$catch(batch.commit().then(function (param) {
                  var workspace = Workspace.fromSchema(schema, undefined, {
                        hd: member,
                        tl: /* [] */0
                      }, "Default", []);
                  var schemaGroup = AnalyticsUtils.makeSchemaGroup(workspace, "master", "main");
                  AnalyticsRe.schemaCreated(schemaGroup, schemaId, schemaGroup.branchId, schemaGroup.schemaId, schemaId, schemaId, schemaName === null ? undefined : Caml_option.some(schemaName), "Free", undefined, "Free");
                  return Promise.resolve(schema);
                }), (function (e) {
                console.log("ERROR CREATING SCHEMA", e);
                return Promise.resolve("FAIL");
              }));
}

function signOut(auth) {
  auth.signOut().then(function (param) {
        React.setUser(null);
        AnalyticsRe.signedOut(undefined);
        return Promise.resolve(undefined);
      });
  
}

function buildSchemaFromOnboarding(auth, sourceGroups, destinations, jobFunction, hasSeenJoinInstructions) {
  var userId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  var buildSchemaBatch = Firebase.app(undefined).firestore().batch();
  $$Promise.$$catch(Firebase.Auth.getCurrentUserToken(auth).then(function (_token) {
              return Promise.all([
                          createSchema(userId),
                          Promise.resolve(TrackingPlanModel.empty)
                        ]);
            }).then(function (param) {
            var model = param[1];
            var schema = param[0];
            var schemaId = schema.id;
            AnalyticsRe.onboardingCompleted(AnalyticsRe.Group.onboarding(Belt_Array.map(Belt_Set.toArray(sourceGroups), OnboardingConfig.sourceGroupToJs), Belt_List.toArray(destinations), jobFunction, hasSeenJoinInstructions), AnalyticsRe.Group.schema(schema.id, Caml_option.null_to_opt(schema.name), "Free", "master", "main", "Free", undefined, "Default", "Freev2", undefined), userId, jobFunction, "master", schema.id);
            var events = Belt_List.map(model.events, (function ($$event) {
                    return $$event.id;
                  }));
            var sourceGroupsWithPlatformAndIds = Belt_Array.map(Belt_Set.toArray(sourceGroups), (function (sourceGroup) {
                    return {
                            id: Shortid(),
                            sourceGroup: sourceGroup,
                            devPlatform: sourceGroup === "Android" ? "Android" : (
                                sourceGroup === "Web" ? "Web" : (
                                    sourceGroup === "iOS" ? "IOS" : undefined
                                  )
                              )
                          };
                  }));
            var sourceActions = Belt_List.fromArray(Belt_Array.concatMany(Belt_Array.map(sourceGroupsWithPlatformAndIds, (function (sourceGroupWithPlatformAndId) {
                            var sourceId = sourceGroupWithPlatformAndId.id;
                            var sourceGroup = sourceGroupWithPlatformAndId.sourceGroup;
                            var devPlatform = sourceGroupWithPlatformAndId.devPlatform;
                            var context = {
                              sourceId: sourceId,
                              sourceQuery: sourceId
                            };
                            return Belt_Array.concatMany([
                                        [
                                          [
                                            {
                                              NAME: "CreateSource",
                                              VAL: sourceId
                                            },
                                            context
                                          ],
                                          [
                                            {
                                              NAME: "UpdateSourceName",
                                              VAL: [
                                                sourceId,
                                                OnboardingConfig.sourceGroupToJs(sourceGroup)
                                              ]
                                            },
                                            context
                                          ],
                                          [
                                            {
                                              NAME: "UpdateSourcePlatformV3",
                                              VAL: [
                                                sourceId,
                                                devPlatform
                                              ]
                                            },
                                            context
                                          ]
                                        ],
                                        Belt_List.toArray(Belt_List.map(events, (function (eventId) {
                                                    return [
                                                            {
                                                              NAME: "IncludeEventInSourceV2",
                                                              VAL: [
                                                                eventId,
                                                                sourceId,
                                                                /* [] */0,
                                                                true
                                                              ]
                                                            },
                                                            context
                                                          ];
                                                  })))
                                      ]);
                          }))));
            var destinationsWithId = Belt_List.map(destinations, (function (destination) {
                    return [
                            Shortid(),
                            destination
                          ];
                  }));
            var destinationActions = BeltListExtensions.flatMap(destinationsWithId, (function (param) {
                    var analyticsTool = param[1];
                    var destinationId = param[0];
                    var context = {
                      destinationId: destinationId,
                      destinationQuery: destinationId
                    };
                    var destinationActions_0 = [
                      {
                        NAME: "CreateDestination",
                        VAL: destinationId
                      },
                      context
                    ];
                    var destinationActions_1 = {
                      hd: [
                        {
                          NAME: "UpdateDestinationName",
                          VAL: [
                            destinationId,
                            AvoConfig.analyticsToolsLabel(analyticsTool)
                          ]
                        },
                        context
                      ],
                      tl: {
                        hd: [
                          {
                            NAME: "UpdateDestinationAnalyticsTool",
                            VAL: [
                              destinationId,
                              analyticsTool
                            ]
                          },
                          context
                        ],
                        tl: /* [] */0
                      }
                    };
                    var destinationActions = {
                      hd: destinationActions_0,
                      tl: destinationActions_1
                    };
                    var sourceDestinationActions = Belt_List.fromArray(Belt_Array.keepMap(sourceGroupsWithPlatformAndIds, (function (sourceGroupWithPlatformAndId) {
                                if (Belt_List.some(AvoConfig.getSupportedDestinations(sourceGroupWithPlatformAndId.devPlatform, undefined), (function (tool) {
                                          return tool === analyticsTool;
                                        }))) {
                                  return [
                                          {
                                            NAME: "IncludeDestinationInSourceV2",
                                            VAL: [
                                              sourceGroupWithPlatformAndId.id,
                                              destinationId,
                                              events
                                            ]
                                          },
                                          {
                                            sourceId: sourceGroupWithPlatformAndId.id,
                                            sourceQuery: sourceGroupWithPlatformAndId.id,
                                            destinationId: destinationId,
                                            destinationQuery: destinationId
                                          }
                                        ];
                                }
                                
                              })));
                    return Belt_List.concat(destinationActions, sourceDestinationActions);
                  }));
            var actions = Belt_List.concatMany([
                  sourceActions,
                  destinationActions
                ]);
            Belt_List.reduce(actions, App.default.firestore.Timestamp.now().toDate(), (function (timestamp, param) {
                    var now = DateFns.addMilliseconds(10, timestamp);
                    var match = Actions.buildAction(Caml_option.some(App.default.firestore.Timestamp.fromDate(now)), param[0], schemaId, "master", userId, param[1], false, false, 0);
                    buildSchemaBatch.set(match[0], match[1]);
                    return now;
                  }));
            var schemaRef = Firebase.app(undefined).firestore().collection("schemas").doc(schema.id);
            buildSchemaBatch.update(schemaRef, {
                  onboardingTemplate: {
                    events: Belt_List.toArray(Belt_List.map(model.events, (function ($$event) {
                                return $$event.id;
                              }))),
                    properties: Belt_List.toArray(Belt_List.map(model.properties, (function (property) {
                                return property._0.id;
                              }))),
                    propertyGroups: Belt_List.toArray(Belt_List.map(model.propertyBundles, (function (group) {
                                return group.id;
                              }))),
                    goals: Belt_List.toArray(Belt_List.map(model.goals, (function (goal) {
                                return goal.id;
                              }))),
                    metrics: Belt_List.toArray(Belt_List.map(model.metrics, (function (metric) {
                                return metric.id;
                              })))
                  }
                });
            buildSchemaBatch.set(schemaRef.collection("acls").doc(userId), {
                  jobFunction: jobFunction
                }, {"merge": true});
            buildSchemaBatch.commit().then(function (param) {
                  Router.push(undefined, Curry._4(Router.Link.get, {
                            NAME: "schema",
                            VAL: schema.id
                          }, undefined, "events", /* [] */0));
                  var user = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser));
                  user.getIdToken().then(function (token) {
                        return fetch(Firebase.apiUrl + "/createContact", Fetch.RequestInit.make(/* Post */2, [
                                          [
                                            "Accept",
                                            "application/json"
                                          ],
                                          [
                                            "Content-Type",
                                            "application/json"
                                          ],
                                          [
                                            "Authorization",
                                            "Bearer " + token
                                          ]
                                        ], Caml_option.some(Belt_Option.getExn(JSON.stringify({
                                                      tools: Belt_List.toArray(Belt_List.map(destinations, AvoConfig.analyticsToolsLabel)),
                                                      platforms: Belt_Array.keepMap(Belt_Set.toArray(sourceGroups), (function (sourceGroup) {
                                                              if (sourceGroup === "Android") {
                                                                return AvoConfig.devPlatformLabel("Android");
                                                              } else if (sourceGroup === "Web") {
                                                                return AvoConfig.devPlatformLabel("Web");
                                                              } else if (sourceGroup === "iOS") {
                                                                return AvoConfig.devPlatformLabel("IOS");
                                                              } else {
                                                                return ;
                                                              }
                                                            })),
                                                      creatorJobFunction: jobFunction
                                                    }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                      });
                  return Promise.resolve(undefined);
                });
            return Promise.resolve(undefined);
          }), (function (error) {
          console.log("error", error);
          return Promise.resolve(undefined);
        }));
  
}

function archiveTemplate(template, sendActions) {
  var actions = Belt_Array.map(template.events, (function (eventId) {
          return [
                  {
                    NAME: "Archive",
                    VAL: {
                      NAME: "Event",
                      VAL: eventId
                    }
                  },
                  {
                    eventId: eventId,
                    eventQuery: eventId
                  }
                ];
        }));
  var actions$1 = Belt_Array.concat(actions, Belt_Array.map(template.properties, (function (propertyId) {
              return [
                      {
                        NAME: "Archive",
                        VAL: {
                          NAME: "Property",
                          VAL: propertyId
                        }
                      },
                      {
                        propertyId: propertyId,
                        propertyQuery: propertyId
                      }
                    ];
            })));
  var actions$2 = Belt_Array.concat(actions$1, Belt_Array.map(template.propertyGroups, (function (propertyGroupId) {
              return [
                      {
                        NAME: "Archive",
                        VAL: {
                          NAME: "PropertyGroup",
                          VAL: propertyGroupId
                        }
                      },
                      {
                        propertyGroupId: propertyGroupId,
                        propertyGroupQuery: propertyGroupId
                      }
                    ];
            })));
  var actions$3 = Belt_Array.concat(actions$2, Belt_Array.map(template.goals, (function (goalId) {
              return [
                      {
                        NAME: "Archive",
                        VAL: {
                          NAME: "Goal",
                          VAL: goalId
                        }
                      },
                      {
                        goalId: goalId,
                        goalQuery: goalId
                      }
                    ];
            })));
  return Curry._1(sendActions, Belt_Array.concat(actions$3, Belt_Array.map(template.metrics, (function (metricId) {
                        return [
                                {
                                  NAME: "Archive",
                                  VAL: {
                                    NAME: "Metric",
                                    VAL: metricId
                                  }
                                },
                                {
                                  metricId: metricId,
                                  metricQuery: metricId
                                }
                              ];
                      }))));
}

function setImplementationDetailsGrabbed(schemaId) {
  Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).set({
        onboardingV2: {
          branchDevInstructionsDone: true
        }
      }, {"merge": true});
  
}

function setColumnSettings(viewerId, columnSettings) {
  return Firebase.app(undefined).firestore().collection("users").doc(viewerId).set({
              columnSettings: columnSettings
            }, {"merge": true});
}

function setPropertyColumnSettings(viewerId, columnSettings) {
  return Firebase.app(undefined).firestore().collection("users").doc(viewerId).set({
              propertyColumnSettings: columnSettings
            }, {"merge": true});
}

function setEventsViewPreference(viewerId, view) {
  return Firebase.app(undefined).firestore().collection("users").doc(viewerId).set({
              eventsView: view
            }, {"merge": true});
}

function setValidationConfig(schemaId, validationConfig) {
  var batch = Firebase.app(undefined).firestore().batch();
  var __x = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId);
  batch.set(__x, {
        validationConfig: validationConfig
      }, {"merge": true});
  var __x$1 = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId);
  batch.set(__x$1, {
        onboardingV2: {
          hasConfiguredDiscrepancies: true
        }
      }, {"merge": true});
  batch.commit();
  
}

function setShowIssuesPreference(viewerId, showIssuesOnly) {
  var preferences = {
    showIssuesOnly: showIssuesOnly
  };
  return Firebase.app(undefined).firestore().collection("users").doc(viewerId).set({
              branchAuditSettings: preferences
            }, {"merge": true});
}

function setSkipDemoPreference(userId, value) {
  return Firebase.app(undefined).firestore().collection("users").doc(userId).set({
              demoBranchSkipped: value
            }, {"merge": true});
}

function setWorkspaceName(schemaId, newName) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).set({
              name: newName
            }, {"merge": true});
}

function setLastSeenCodegen(schemaId, seenSources) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).set({
              lastSeenCodegen: Js_dict.fromArray(seenSources)
            }, {"merge": true});
}

function setGlobalNamespacePopupSeen(param) {
  var userId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  return Firebase.app(undefined).firestore().collection("users").doc(userId).set({
              globalNamespacePopupSeen: true
            }, {"merge": true});
}

function generatePublicLink(shareId, schemaId, branchId) {
  var sharingRef = Firebase.app(undefined).firestore().collection("sharing").doc(shareId);
  sharingRef.set(Sharing.encode(/* BranchImplementation */{
            shareId: shareId,
            schemaId: schemaId,
            branchId: branchId
          }));
  
}

function deletePublicLink(shareId) {
  var sharingRef = Firebase.app(undefined).firestore().collection("sharing").doc(shareId);
  sharingRef.delete();
  
}

function createSavedView(sendActions, schemaId, name, order, filters, onSuccess, onError) {
  var batch = Firebase.app(undefined).firestore().batch();
  var ref = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("savedViews").doc();
  var savedView_id = ref.id;
  var savedView_createdBy = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  var savedView_createdAt = new Date();
  var savedView = {
    id: savedView_id,
    name: name,
    createdBy: savedView_createdBy,
    createdAt: savedView_createdAt,
    lastModifiedBy: undefined,
    lastModifiedAt: undefined,
    filters: filters,
    order: order,
    isArchived: false
  };
  batch.set(ref, SavedViews.encode(savedView));
  var action_1 = [
    ref.id,
    name
  ];
  var action = {
    NAME: "CreateSavedView",
    VAL: action_1
  };
  var context = {
    branchId: "master",
    branchQuery: ref.id
  };
  return Curry.app(sendActions, [
              "Master",
              true,
              Caml_option.some(batch),
              undefined,
              true,
              undefined,
              undefined,
              (function (_branch) {
                  return Curry._1(onSuccess, ref.id);
                }),
              onError,
              [[
                  action,
                  context
                ]]
            ]);
}

function updateSavedView(sendActions, schemaId, savedView, viewerId, onSuccess, onError) {
  var batch = Firebase.app(undefined).firestore().batch();
  batch.update(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("savedViews").doc(savedView.id), SavedViews.encode({
            id: savedView.id,
            name: savedView.name,
            createdBy: savedView.createdBy,
            createdAt: savedView.createdAt,
            lastModifiedBy: viewerId,
            lastModifiedAt: Caml_option.some(new Date()),
            filters: savedView.filters,
            order: savedView.order,
            isArchived: savedView.isArchived
          }));
  return Curry.app(sendActions, [
              "Master",
              true,
              Caml_option.some(batch),
              undefined,
              true,
              undefined,
              undefined,
              onSuccess,
              onError,
              [[
                  {
                    NAME: "UpdateSavedView",
                    VAL: [
                      savedView.id,
                      savedView.name
                    ]
                  },
                  {
                    branchId: "master",
                    branchQuery: savedView.id
                  }
                ]]
            ]);
}

function archiveSavedView(sendActions, schemaId, id, name, viewerId) {
  var batch = Firebase.app(undefined).firestore().batch();
  var ref = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("savedViews").doc(id);
  batch.update(ref, {
        isArchived: true,
        lastModifiedBy: viewerId,
        lastModifiedAt: App.default.firestore.FieldValue.serverTimestamp()
      });
  return Curry.app(sendActions, [
              "Master",
              true,
              Caml_option.some(batch),
              undefined,
              true,
              undefined,
              undefined,
              (function (_branch) {
                  
                }),
              undefined,
              [[
                  {
                    NAME: "ArchiveSavedView",
                    VAL: [
                      id,
                      name
                    ]
                  },
                  {
                    branchId: "master",
                    branchQuery: id
                  }
                ]]
            ]);
}

function fetchInspectorValidationIntents(branchId, schemaId) {
  return $$Promise.$$catch(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection(InspectorVersionValidationIntent.collection).doc(Belt_Option.getWithDefault(branchId, "default")).get().then(function (snapshot) {
                  if (!snapshot.exists) {
                    return Promise.resolve(undefined);
                  }
                  var data = snapshot.data();
                  return Promise.resolve(InspectorVersionValidationIntent.Decode.intents(data));
                }), (function (param) {
                return Promise.resolve(undefined);
              }));
}

function setInspectorValidationIntentsForBranch(schemaId, branchId, intents) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection(InspectorVersionValidationIntent.collection).doc(branchId).set(Js_dict.fromArray([[
                    InspectorVersionValidationIntent.intentsKey,
                    InspectorVersionValidationIntent.Encode.intents(intents)
                  ]]), {"merge": true});
}

function setInspectorValidationIntentsDefaults(schemaId, intents) {
  var defaultIntentRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection(InspectorVersionValidationIntent.collection).doc("default");
  return $$Promise.$$catch(defaultIntentRef.get().then(function (snapshot) {
                    if (!snapshot.exists) {
                      return Promise.resolve(undefined);
                    }
                    var data = snapshot.data();
                    return Promise.resolve(InspectorVersionValidationIntent.Decode.intents(data));
                  }), (function (param) {
                  return Promise.resolve(undefined);
                })).then(function (defaultIntents) {
              var newIntents = Belt_MapString.toArray(Belt_MapString.mergeMany(defaultIntents, intents));
              return defaultIntentRef.set(Js_dict.fromArray([[
                                InspectorVersionValidationIntent.intentsKey,
                                InspectorVersionValidationIntent.Encode.intents(newIntents)
                              ]]), {"merge": true});
            });
}

exports.makeId = makeId;
exports.uploadPicture = uploadPicture;
exports.uploadScreenshot = uploadScreenshot;
exports.capitalize = capitalize;
exports.createSchema = createSchema;
exports.signOut = signOut;
exports.buildSchemaFromOnboarding = buildSchemaFromOnboarding;
exports.archiveTemplate = archiveTemplate;
exports.setImplementationDetailsGrabbed = setImplementationDetailsGrabbed;
exports.setColumnSettings = setColumnSettings;
exports.setPropertyColumnSettings = setPropertyColumnSettings;
exports.setEventsViewPreference = setEventsViewPreference;
exports.setValidationConfig = setValidationConfig;
exports.setShowIssuesPreference = setShowIssuesPreference;
exports.setSkipDemoPreference = setSkipDemoPreference;
exports.setWorkspaceName = setWorkspaceName;
exports.setLastSeenCodegen = setLastSeenCodegen;
exports.setGlobalNamespacePopupSeen = setGlobalNamespacePopupSeen;
exports.generatePublicLink = generatePublicLink;
exports.deletePublicLink = deletePublicLink;
exports.createSavedView = createSavedView;
exports.updateSavedView = updateSavedView;
exports.archiveSavedView = archiveSavedView;
exports.fetchInspectorValidationIntents = fetchInspectorValidationIntents;
exports.setInspectorValidationIntentsForBranch = setInspectorValidationIntentsForBranch;
exports.setInspectorValidationIntentsDefaults = setInspectorValidationIntentsDefaults;
/* Models Not a pure module */
