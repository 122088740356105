// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Json = require("@glennsl/bs-json/src/Json.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Button = require("../Button.bs.js");
var Styles = require("../styles.bs.js");
var Actions = require("../actions.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Firebase = require("../../../bs-firestore/src/Firebase.bs.js");
var AvoConfig = require("../../../shared/utils/AvoConfig.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var AnalyticsUtils = require("../analyticsUtils.bs.js");
var IntegrationInput = require("./IntegrationInput.bs.js");
var IntegrationUtils = require("../../../api/functions/src/IntegrationUtils.bs.js");
var GlobalSendContext = require("../GlobalSendContext.bs.js");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");
var SendActionsContext = require("../SendActionsContext.bs.js");
var IntegrationAnalytics = require("./IntegrationAnalytics.bs.js");
var IntegrationActivityItem = require("./IntegrationActivityItem.bs.js");
var IntegrationHeaderPlanVersionPicker = require("./IntegrationHeaderPlanVersionPicker.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("flexEnd"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.marginBottom(Css.px(16)),
            tl: /* [] */0
          }
        }
      }
    });

var publishButtonStyles = Curry._1(Css.style, {
      hd: Css.flexShrink(0.0),
      tl: {
        hd: Css.marginLeft(Css.px(8)),
        tl: /* [] */0
      }
    });

var errorMessageContainerStyles = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.grapeErrorBg),
      tl: {
        hd: Css.padding2(Css.px(8), Css.px(16)),
        tl: /* [] */0
      }
    });

var errorMessageContentWrapperStyles = Curry._1(Css.style, {
      hd: Css.maxHeight(Css.px(142)),
      tl: {
        hd: Css.overflow("auto"),
        tl: /* [] */0
      }
    });

function publishIntegration(schemaId, branchId, integrationId, mParticlePlanVersion) {
  return Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
              return fetch(Firebase.apiUrl + "/integration/publish", Fetch.RequestInit.make(/* Post */2, [
                                [
                                  "Accept",
                                  "application/json"
                                ],
                                [
                                  "Content-Type",
                                  "application/json"
                                ],
                                [
                                  "Authorization",
                                  "Bearer " + token
                                ]
                              ], Caml_option.some(JSON.stringify(Js_dict.fromArray(Belt_Array.concat([
                                                [
                                                  "schemaId",
                                                  schemaId
                                                ],
                                                [
                                                  "branchId",
                                                  branchId
                                                ],
                                                [
                                                  "integrationId",
                                                  integrationId
                                                ]
                                              ], Belt_Option.getWithDefault(Belt_Option.map(mParticlePlanVersion, (function (version) {
                                                          return [[
                                                                    "mParticlePlanVersion",
                                                                    version
                                                                  ]];
                                                        })), []))))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
            });
}

function decodePublishResponse(json) {
  return {
          errors: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("messages", (function (param) {
                                    return Json_decode.array(Json_decode.string, param);
                                  }), param);
                    }), json), []),
          warnings: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("warnings", (function (param) {
                                    return Json_decode.array(Json_decode.string, param);
                                  }), param);
                    }), json), [])
        };
}

function IntegrationHeader(Props) {
  var role = Props.role;
  var schemaId = Props.schemaId;
  var currentBranch = Props.currentBranch;
  var integrations = Props.integrations;
  var integration = Props.integration;
  var availability = Props.availability;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var match = React.useState(function () {
        
      });
  var setMparticlePlanVersion = match[1];
  var mParticlePlanVersion = match[0];
  var match$1 = React.useState(function () {
        return "Initial";
      });
  var setPublishStatus = match$1[1];
  var publishStatus = match$1[0];
  var handlePublishPress = function (param) {
    if (availability === "Unavailable") {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "BillingPrompt",
                    VAL: "PublishIntegration"
                  }
                });
    }
    Curry._1(setPublishStatus, (function (param) {
            return "Loading";
          }));
    var match = integration.config;
    var tmp;
    tmp = match !== undefined && match.TAG === /* MParticleDataMaster */5 ? mParticlePlanVersion : undefined;
    $$Promise.$$catch(publishIntegration(schemaId, Actions.branchToId(currentBranch), integration.id, tmp).then(function (res) {
                var statusCode = res.status;
                return res.text().then(function (text) {
                            try {
                              var json = Json.parseOrRaise(text);
                              return Promise.resolve([
                                          statusCode,
                                          decodePublishResponse(json)
                                        ]);
                            }
                            catch (_err){
                              return Promise.resolve([
                                          statusCode,
                                          {
                                            errors: [text],
                                            warnings: []
                                          }
                                        ]);
                            }
                          });
              }).then(function (param) {
              var payload = param[1];
              var statusCode = param[0];
              if (statusCode === 202) {
                Curry._1(setPublishStatus, (function (param) {
                        return "Scheduled";
                      }));
              } else if (statusCode < 300) {
                Curry._1(setPublishStatus, (function (param) {
                        return "Success";
                      }));
              } else {
                Curry._1(setPublishStatus, (function (param) {
                        return {
                                NAME: "Error",
                                VAL: [
                                  statusCode !== 400 ? (
                                      statusCode !== 408 ? (
                                          statusCode !== 500 ? /* Other */2 : /* Internal */1
                                        ) : /* Timeout */0
                                    ) : /* Other */2,
                                  payload.warnings,
                                  payload.errors
                                ]
                              };
                      }));
              }
              return Promise.resolve(undefined);
            }), (function (error) {
            console.log(error);
            return Promise.resolve(Curry._1(setPublishStatus, (function (param) {
                              return {
                                      NAME: "Error",
                                      VAL: [
                                        /* Internal */1,
                                        [],
                                        []
                                      ]
                                    };
                            })));
          }));
    
  };
  var printMessages = function (messages, color) {
    return Belt_Array.map(messages, (function (message) {
                  return React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: color,
                              children: message,
                              key: message
                            });
                }));
  };
  var canPublish = publishStatus !== "Loading" && role === "Admin" && IntegrationUtils.hasPublishableConfig(integration.config);
  var match$2 = integration.config;
  var tmp;
  if (typeof publishStatus === "object") {
    var exit = 0;
    if (match$2 !== undefined && match$2.TAG === /* Taxonomy */1) {
      tmp = React.createElement($$Text.make, {
            size: "Small",
            weight: "Semi",
            color: Styles.Color.grapeError,
            lineHeight: Css.px(32),
            children: "Could not complete the publishing"
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = React.createElement($$Text.make, {
            size: "Small",
            weight: "Semi",
            color: Styles.Color.grapeError,
            lineHeight: Css.px(32),
            children: "Could not publish. View error in activity log below"
          });
    }
    
  } else {
    tmp = publishStatus === "Success" ? React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.minWidth(Css.px(320)),
                  tl: /* [] */0
                })
          }, React.createElement($$Text.make, {
                size: "Small",
                weight: "Semi",
                textAlign: "right",
                color: Styles.Color.mintGreen,
                lineHeight: Css.px(32),
                children: "Publish successfully completed"
              })) : (
        publishStatus === "Scheduled" ? React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.minWidth(Css.px(320)),
                      tl: /* [] */0
                    })
              }, React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    textAlign: "right",
                    color: Styles.Color.mintGreen,
                    lineHeight: Css.px(32),
                    children: "Publish successfully started..."
                  }), React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    textAlign: "right",
                    color: Styles.Color.grey70,
                    lineHeight: Css.px(32),
                    children: "Results will appear in the activity log below when finished"
                  })) : null
      );
  }
  var match$3 = integration.config;
  var tmp$1;
  if (match$3 !== undefined && match$3.TAG === /* MParticleDataMaster */5) {
    var match$4 = match$3._0;
    tmp$1 = match$4.clientId !== undefined && match$4.clientSecret !== undefined && match$4.workspaceId !== undefined && match$4.planId !== undefined ? React.createElement(IntegrationHeaderPlanVersionPicker.make, {
            integration: integration,
            schemaId: schemaId,
            branchId: Actions.branchToId(currentBranch),
            integrationId: integration.id,
            mParticlePlanVersion: mParticlePlanVersion,
            handleChange: (function (maybeVersion) {
                return Curry._1(setMparticlePlanVersion, (function (param) {
                              return maybeVersion;
                            }));
              })
          }) : null;
  } else {
    tmp$1 = null;
  }
  var match$5 = integration.config;
  var tmp$2;
  if (match$5 !== undefined) {
    switch (match$5.TAG | 0) {
      case /* Protocols */0 :
          tmp$2 = " to Protocols";
          break;
      case /* Lexicon */2 :
          tmp$2 = " to Lexicon";
          break;
      case /* Taxonomy */1 :
      case /* Webhook */3 :
          tmp$2 = "";
          break;
      case /* SnowplowDataStructures */4 :
          tmp$2 = " to Snowplow";
          break;
      case /* MParticleDataMaster */5 :
          tmp$2 = " to mParticle";
          break;
      case /* RudderstackTrackingPlans */6 :
          tmp$2 = " to RudderStack";
          break;
      
    }
  } else {
    tmp$2 = "";
  }
  var tmp$3 = {
    disabled: !canPublish,
    label: "Publish" + tmp$2,
    loading: publishStatus === "Loading",
    onClick: handlePublishPress
  };
  var tmp$4 = availability === "Available" ? undefined : "lightning";
  if (tmp$4 !== undefined) {
    tmp$3.icon = Caml_option.valFromOption(tmp$4);
  }
  var match$6 = integration.config;
  var tmp$5;
  if (typeof publishStatus === "object" && publishStatus.NAME === "Error") {
    var match$7 = publishStatus.VAL;
    switch (match$7[0]) {
      case /* Timeout */0 :
          tmp$5 = match$6 !== undefined && match$6.TAG === /* Taxonomy */1 ? React.createElement("div", {
                  className: errorMessageContainerStyles
                }, React.createElement("div", {
                      className: errorMessageContentWrapperStyles
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.grapeError,
                          children: null
                        }, "Please click Publish again to resume the publishing process error=408", printMessages(match$7[1], Styles.Color.grey90), printMessages(match$7[2], Styles.Color.grapeError))), React.createElement(IntegrationActivityItem.DocsLinkOnError.make, {
                      publishingMessagesType: /* Errors */0
                    })) : null;
          break;
      case /* Internal */1 :
          tmp$5 = match$6 !== undefined && match$6.TAG === /* Taxonomy */1 ? React.createElement("div", {
                  className: errorMessageContainerStyles
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.grapeError,
                      children: "Unexpected error occurred."
                    }), React.createElement("div", {
                      className: errorMessageContentWrapperStyles
                    }, printMessages(match$7[1], Styles.Color.grey90), printMessages(match$7[2], Styles.Color.grapeError)), React.createElement(IntegrationActivityItem.DocsLinkOnError.make, {
                      publishingMessagesType: /* Errors */0
                    })) : null;
          break;
      case /* Other */2 :
          tmp$5 = match$6 !== undefined && match$6.TAG === /* Taxonomy */1 ? React.createElement("div", {
                  className: errorMessageContainerStyles
                }, React.createElement("div", {
                      className: errorMessageContentWrapperStyles
                    }, printMessages(match$7[1], Styles.Color.grey90), printMessages(match$7[2], Styles.Color.grapeError)), React.createElement(IntegrationActivityItem.DocsLinkOnError.make, {
                      publishingMessagesType: /* Errors */0
                    })) : null;
          break;
      
    }
  } else {
    tmp$5 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: rootStyles
                }, React.createElement("div", undefined, React.createElement(IntegrationInput.IntegrationTextInput.make, {
                          id: "mparticle-workspace-id",
                          value: AvoConfig.getIntegrationName(integration),
                          onFlush: (function (name) {
                              return Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (branch) {
                                              return IntegrationAnalytics.trackIntegrationConfigured(integrations, {
                                                          id: integration.id,
                                                          name: name,
                                                          config: integration.config,
                                                          filters: integration.filters,
                                                          autoPublish: integration.autoPublish
                                                        }, AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "IntegrationName");
                                            }),
                                          undefined,
                                          [[
                                              {
                                                NAME: "UpdateIntegrationName",
                                                VAL: [
                                                  integration.id,
                                                  name
                                                ]
                                              },
                                              {
                                                integrationId: integration.id,
                                                integrationQuery: integration.id
                                              }
                                            ]]
                                        ]);
                            }),
                          placeholder: "Integration name",
                          onChangeCompleted: (function (param, param$1) {
                              
                            }),
                          children: "Name"
                        })), tmp, tmp$1, React.createElement("div", {
                      className: publishButtonStyles
                    }, React.createElement(Button.make, tmp$3))), tmp$5);
}

var make = IntegrationHeader;

exports.rootStyles = rootStyles;
exports.publishButtonStyles = publishButtonStyles;
exports.errorMessageContainerStyles = errorMessageContainerStyles;
exports.errorMessageContentWrapperStyles = errorMessageContentWrapperStyles;
exports.publishIntegration = publishIntegration;
exports.decodePublishResponse = decodePublishResponse;
exports.make = make;
/* rootStyles Not a pure module */
