// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");

function Hover(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : /* [] */0;
  var match = React.useState(function () {
        return false;
      });
  var setHover = match[1];
  return React.createElement("span", {
              className: Curry._1(Css.style, className),
              onMouseEnter: (function (param) {
                  return Curry._1(setHover, (function (param) {
                                return true;
                              }));
                }),
              onMouseLeave: (function (param) {
                  return Curry._1(setHover, (function (param) {
                                return false;
                              }));
                })
            }, children(match[0]));
}

var make = Hover;

exports.make = make;
/* Css Not a pure module */
