// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var FramerMotion = require("framer-motion");

function container(hasCollapsedHeight, collapsed, faderColor, duration, withFade) {
  return Curry._1(Css.style, {
              hd: Css.overflowY("hidden"),
              tl: {
                hd: Css.position("relative"),
                tl: {
                  hd: Css.after({
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.unsafe("content", "' '"),
                          tl: {
                            hd: Css.width(Css.pct(100.0)),
                            tl: {
                              hd: Css.pointerEvents("none"),
                              tl: {
                                hd: Css.display(hasCollapsedHeight ? "block" : "none"),
                                tl: {
                                  hd: Css.opacity(collapsed ? 1.0 : 0.0),
                                  tl: {
                                    hd: Css.bottom(Css.px(0)),
                                    tl: {
                                      hd: Css.left(Css.px(0)),
                                      tl: {
                                        hd: Css.height(Css.px(24)),
                                        tl: {
                                          hd: Css.transition(duration, undefined, undefined, "opacity"),
                                          tl: {
                                            hd: withFade ? Css.background({
                                                    NAME: "linearGradient",
                                                    VAL: [
                                                      {
                                                        NAME: "deg",
                                                        VAL: 180.0
                                                      },
                                                      [
                                                        [
                                                          Css.pct(0.0),
                                                          Styles.Color.setAlpha(faderColor, 0.0)
                                                        ],
                                                        [
                                                          Css.pct(100.0),
                                                          Styles.Color.setAlpha(faderColor, 1.0)
                                                        ]
                                                      ]
                                                    ]
                                                  }) : Styles.emptyStyle,
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              }
            });
}

var Style = {
  container: container
};

function getDurationToMs(duration) {
  if (typeof duration === "object") {
    return duration.VAL;
  } else if (duration === "short") {
    return Styles.Duration.$$short;
  } else if (duration === "default") {
    return Styles.Duration.$$default;
  } else {
    return Styles.Duration.$$long;
  }
}

function durationToSeconds(duration) {
  return getDurationToMs(duration) / 1000.0;
}

function getTransition(transition, duration) {
  if (transition === "default") {
    return {
            duration: getDurationToMs(duration) / 1000.0
          };
  } else {
    return {
            type: "spring",
            stiffness: 250,
            damping: 30,
            mass: 1
          };
  }
}

function Collapsible(Props) {
  var id = Props.id;
  var collapsedOpt = Props.collapsed;
  var collapsedHeightOpt = Props.collapsedHeight;
  var durationOpt = Props.duration;
  var faderColorOpt = Props.faderColor;
  var loadingStyleOpt = Props.loadingStyle;
  var onAnimationComplete = Props.onAnimationComplete;
  var onAnimationStart = Props.onAnimationStart;
  var onChange = Props.onChange;
  var transitionOpt = Props.transition;
  var withFadeOpt = Props.withFade;
  var children = Props.children;
  var collapsed = collapsedOpt !== undefined ? collapsedOpt : true;
  var collapsedHeight = collapsedHeightOpt !== undefined ? collapsedHeightOpt : 0;
  var duration = durationOpt !== undefined ? durationOpt : "default";
  var faderColor = faderColorOpt !== undefined ? faderColorOpt : Styles.Color.grey10;
  var loadingStyle = loadingStyleOpt !== undefined ? loadingStyleOpt : "lazy";
  var transition = transitionOpt !== undefined ? transitionOpt : "default";
  var withFade = withFadeOpt !== undefined ? withFadeOpt : true;
  var match = React.useState(function () {
        if (loadingStyle === "eager") {
          return true;
        } else {
          return collapsedHeight > 0;
        }
      });
  var setLoaded = match[1];
  var loaded = match[0];
  React.useEffect((function () {
          if (!loaded && !collapsed) {
            Curry._1(setLoaded, (function (param) {
                    return true;
                  }));
          }
          
        }), [collapsed]);
  Hooks.useDidUpdate1((function (param) {
          Belt_Option.forEach(onChange, (function (cb) {
                  return Curry._1(cb, undefined);
                }));
          
        }), [collapsed]);
  var tmp;
  if (loaded) {
    var tmp$1 = {
      animate: collapsed ? "collapsed" : "expanded",
      transition: getTransition(transition, duration),
      initial: "collapsed",
      variants: {
        collapsed: {
          opacity: collapsedHeight,
          height: collapsedHeight.toString() + "px"
        },
        expanded: {
          opacity: 1,
          height: "auto"
        }
      },
      className: container(collapsedHeight > 0, collapsed, faderColor, {
            NAME: "ms",
            VAL: getDurationToMs(duration)
          }, withFade),
      key: Belt_Option.getWithDefault(id, "collapsible"),
      onClick: (function (prim) {
          prim.stopPropagation();
          
        }),
      children: children
    };
    if (id !== undefined) {
      tmp$1.id = id;
    }
    if (onAnimationStart !== undefined) {
      tmp$1.onAnimationStart = Caml_option.valFromOption(onAnimationStart);
    }
    if (onAnimationComplete !== undefined) {
      tmp$1.onAnimationComplete = Caml_option.valFromOption(onAnimationComplete);
    }
    tmp = React.createElement(FramerMotion.motion.div, tmp$1);
  } else {
    var tmp$2 = {
      hidden: true
    };
    if (id !== undefined) {
      tmp$2.id = Caml_option.valFromOption(id);
    }
    tmp = React.createElement("div", tmp$2);
  }
  return React.createElement(FramerMotion.AnimatePresence, {
              initial: false,
              children: tmp
            });
}

var make = Collapsible;

exports.Style = Style;
exports.getDurationToMs = getDurationToMs;
exports.durationToSeconds = durationToSeconds;
exports.getTransition = getTransition;
exports.make = make;
/* Css Not a pure module */
