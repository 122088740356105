// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Curry = require("rescript/lib/js/curry.js");
var Models = require("./Models.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Workspace = require("../../model/src/Workspace.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var InspectorViewModel = require("./inspector/InspectorViewModel.bs.js");
var Difference_in_hours = require("date-fns/difference_in_hours");

function BooleanLimits(C) {
  var computeAvailability = function (workspace) {
    var match = Curry._1(C.getLimit, workspace.plan);
    var match$1 = workspace.trial;
    if (match) {
      return "Available";
    } else if (match$1 !== undefined && Curry._1(C.getLimit, match$1.plan) === true) {
      return "AvailableDuringTrial";
    } else {
      return "Unavailable";
    }
  };
  var availableOnPlan = function (workspace) {
    var _plan = workspace.plan;
    while(true) {
      var plan = _plan;
      if (Curry._1(C.getLimit, plan) === true || plan.name === /* Enterprise */6) {
        return plan;
      }
      if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
        return Workspace.Instances.enterprise;
      }
      _plan = Workspace.Instances.nextHint(plan);
      continue ;
    };
  };
  var isAvailable = function (workspace) {
    return computeAvailability(workspace) !== "Unavailable";
  };
  return {
          computeAvailability: computeAvailability,
          availableOnPlan: availableOnPlan,
          isAvailable: isAvailable
        };
}

function LadderLimits(C) {
  var computeAvailability = function (workspace, step) {
    var match = Curry._1(C.getLimit, workspace.plan);
    var match$1 = workspace.trial;
    if (Caml_obj.caml_greaterequal(Belt_Array.getIndexBy(C.ladder, (function (ladderStep) {
                  return Caml_obj.caml_equal(ladderStep, match);
                })), Belt_Array.getIndexBy(C.ladder, (function (ladderStep) {
                  return Caml_obj.caml_equal(ladderStep, step);
                })))) {
      return "Available";
    } else if (match$1 !== undefined && Caml_obj.caml_greaterequal(Belt_Array.getIndexBy(C.ladder, (function (ladderStep) {
                  return Caml_obj.caml_equal(ladderStep, Curry._1(C.getLimit, match$1.plan));
                })), Belt_Array.getIndexBy(C.ladder, (function (ladderStep) {
                  return Caml_obj.caml_equal(ladderStep, step);
                })))) {
      return "AvailableDuringTrial";
    } else {
      return "Unavailable";
    }
  };
  var availableOnPlan = function (workspace, step) {
    var _plan = workspace.plan;
    while(true) {
      var plan = _plan;
      if (Caml_obj.caml_equal(Curry._1(C.getLimit, plan), step) || plan.name === /* Enterprise */6) {
        return plan.name;
      }
      if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
        return Workspace.Instances.enterprise.name;
      }
      _plan = Workspace.Instances.nextHint(plan);
      continue ;
    };
  };
  return {
          computeAvailability: computeAvailability,
          availableOnPlan: availableOnPlan
        };
}

function NumericLimits(C) {
  var constructLimitExceeded = function (trial, limit, planHint) {
    if (trial === undefined) {
      return {
              NAME: "LimitExceeded",
              VAL: [
                limit,
                planHint
              ]
            };
    } else {
      return {
              NAME: "AvailableDuringTrial",
              VAL: [
                limit,
                planHint
              ]
            };
    }
  };
  var computeEntityNumericLimits = function (workspace, model) {
    var match = Curry._1(C.getLimit, workspace.plan);
    var limits;
    if (typeof match === "object" && match.NAME === "Limited") {
      var planEntityCountLimit = match.VAL;
      if (Belt_List.length(Curry._2(C.getItems, workspace, model)) > planEntityCountLimit) {
        var entityCount = Belt_List.length(Curry._2(C.getItems, workspace, model));
        var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
        var trial = workspace.trial;
        var trialEntityCount;
        if (trial !== undefined) {
          var match$1 = Curry._1(C.getLimit, trial.plan);
          trialEntityCount = typeof match$1 === "object" ? Caml.caml_int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
        } else {
          trialEntityCount = 0;
        }
        var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
        var limitToInt = function (limit) {
          if (typeof limit === "object") {
            return limit.VAL;
          } else {
            return Pervasives.max_int;
          }
        };
        var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(Curry._2(C.getItems, workspace, model), (function (entityIndex, entity) {
                    var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                            return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                          }));
                    var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                            var match = Curry._1(C.getLimit, publicPlan);
                            if (typeof match === "object") {
                              return match.VAL > entityIndex;
                            } else {
                              return true;
                            }
                          }));
                    var currentPlanLimit = limitToInt(Curry._1(C.getLimit, workspace.plan));
                    var trial = Belt_Option.map(workspace.trial, (function (trial) {
                            return trial.plan;
                          }));
                    var currentTrialLimit = trial !== undefined ? limitToInt(Curry._1(C.getLimit, trial)) : 0;
                    var exit = 0;
                    if (minimumRequiredPlan !== undefined) {
                      if (entityIndex === (currentPlanLimit - 1 | 0)) {
                        return {
                                hd: {
                                  NAME: "LimitReached",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        C.entity,
                                        Curry._1(C.getId, entity)
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit = 1;
                    } else {
                      exit = 1;
                    }
                    if (exit === 1) {
                      if (entityIndex < currentPlanLimit) {
                        return /* [] */0;
                      }
                      if (minimumRequiredPlan === undefined) {
                        return {
                                hd: {
                                  NAME: "LimitExceeded",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        C.entity,
                                        Curry._1(C.getId, entity)
                                      ]
                                    },
                                    Workspace.Instances.enterprise
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      var exit$1 = 0;
                      if (trial !== undefined) {
                        if (entityIndex < currentTrialLimit) {
                          return {
                                  hd: {
                                    NAME: "AvailableDuringTrial",
                                    VAL: [
                                      {
                                        NAME: "NumericLimit",
                                        VAL: [
                                          C.entity,
                                          Curry._1(C.getId, entity)
                                        ]
                                      },
                                      minimumRequiredPlan
                                    ]
                                  },
                                  tl: /* [] */0
                                };
                        }
                        exit$1 = 2;
                      } else {
                        exit$1 = 2;
                      }
                      if (exit$1 === 2) {
                        return {
                                hd: {
                                  NAME: "LimitExceeded",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        C.entity,
                                        Curry._1(C.getId, entity)
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      
                    }
                    
                  })));
        var match$2 = trialEntityCount$1 > 0;
        var match$3 = workspace.trial;
        var exit = 0;
        if (match$2 && match$3 !== undefined) {
          var match$4 = Curry._1(C.getLimit, match$3.plan);
          limits = typeof match$4 === "object" ? ({
                hd: constructLimitExceeded(workspace.trial, {
                      NAME: "NumericListLimit",
                      VAL: [
                        C.entity,
                        Belt_List.length(Curry._2(C.getItems, workspace, model)),
                        match$4.VAL
                      ]
                    }, Workspace.Instances.nextHint(workspace.plan)),
                tl: limits$1
              }) : limits$1;
        } else {
          exit = 1;
        }
        if (exit === 1) {
          limits = {
            hd: constructLimitExceeded(workspace.trial, {
                  NAME: "NumericListLimit",
                  VAL: [
                    C.entity,
                    Belt_List.length(Curry._2(C.getItems, workspace, model)),
                    planEntityCountLimit
                  ]
                }, Workspace.Instances.nextHint(workspace.plan)),
            tl: limits$1
          };
        }
        
      } else {
        limits = /* [] */0;
      }
    } else {
      limits = /* [] */0;
    }
    var match$5 = Curry._1(C.getLimit, workspace.plan);
    var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
            return Curry._1(C.getLimit, trial.plan);
          }));
    var tmp;
    if (typeof match$5 === "object") {
      var maxPlanEntities = match$5.VAL;
      if (Belt_List.length(Curry._2(C.getItems, workspace, model)) < maxPlanEntities) {
        tmp = /* [] */0;
      } else if (match$6 !== undefined) {
        if (typeof match$6 === "object") {
          var maxTrialEntities = match$6.VAL;
          tmp = Belt_List.length(Curry._2(C.getItems, workspace, model)) < maxTrialEntities ? ({
                hd: {
                  NAME: "AvailableDuringTrial",
                  VAL: [
                    {
                      NAME: "CreateEntityStatus",
                      VAL: C.entity
                    },
                    Workspace.Instances.nextHint(workspace.plan)
                  ]
                },
                tl: /* [] */0
              }) : (
              Belt_List.length(Curry._2(C.getItems, workspace, model)) === maxTrialEntities ? ({
                    hd: {
                      NAME: "LimitReached",
                      VAL: [
                        {
                          NAME: "CreateEntityStatus",
                          VAL: C.entity
                        },
                        Workspace.Instances.nextHint(workspace.plan)
                      ]
                    },
                    tl: /* [] */0
                  }) : ({
                    hd: {
                      NAME: "LimitExceeded",
                      VAL: [
                        {
                          NAME: "CreateEntityStatus",
                          VAL: C.entity
                        },
                        Workspace.Instances.nextHint(workspace.plan)
                      ]
                    },
                    tl: /* [] */0
                  })
            );
        } else {
          tmp = {
            hd: {
              NAME: "AvailableDuringTrial",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: C.entity
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          };
        }
      } else {
        tmp = Belt_List.length(Curry._2(C.getItems, workspace, model)) === maxPlanEntities ? ({
              hd: {
                NAME: "LimitReached",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: C.entity
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : ({
              hd: {
                NAME: "LimitExceeded",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: C.entity
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            });
      }
    } else {
      tmp = /* [] */0;
    }
    return Belt_List.concat(limits, tmp);
  };
  var createActionStatus = function (workspaceLimits) {
    return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                      var match = limit.VAL[0];
                      if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                        return match.VAL === C.entity;
                      } else {
                        return false;
                      }
                    })), "Available", (function (limit) {
                  if (limit.NAME === "AvailableDuringTrial") {
                    return "AvailableDuringTrial";
                  } else {
                    return "Unavailable";
                  }
                }));
  };
  var entityStatus = function (workspaceLimits, entityId) {
    return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                      var entity;
                      var id;
                      var variant = limit.NAME;
                      if (variant === "LimitReached") {
                        var match = limit.VAL[0];
                        if (typeof match !== "object") {
                          return false;
                        }
                        if (match.NAME !== "NumericLimit") {
                          return false;
                        }
                        var match$1 = match.VAL;
                        entity = match$1[0];
                        id = match$1[1];
                      } else if (variant === "AvailableDuringTrial") {
                        var match$2 = limit.VAL[0];
                        if (typeof match$2 !== "object") {
                          return false;
                        }
                        if (match$2.NAME !== "NumericLimit") {
                          return false;
                        }
                        var match$3 = match$2.VAL;
                        entity = match$3[0];
                        id = match$3[1];
                      } else {
                        var match$4 = limit.VAL[0];
                        if (typeof match$4 !== "object") {
                          return false;
                        }
                        if (match$4.NAME !== "NumericLimit") {
                          return false;
                        }
                        var match$5 = match$4.VAL;
                        entity = match$5[0];
                        id = match$5[1];
                      }
                      if (entityId === id) {
                        return entity === C.entity;
                      } else {
                        return false;
                      }
                    })), [
                "Available",
                undefined
              ], (function (limit) {
                  var variant = limit.NAME;
                  if (variant === "LimitReached") {
                    return [
                            "Available",
                            limit.VAL[1]
                          ];
                  } else if (variant === "AvailableDuringTrial") {
                    return [
                            "AvailableDuringTrial",
                            limit.VAL[1]
                          ];
                  } else {
                    return [
                            "Unavailable",
                            limit.VAL[1]
                          ];
                  }
                }));
  };
  var status = function (workspaceLimits) {
    return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                      if (typeof limit !== "object") {
                        return false;
                      }
                      var variant = limit.NAME;
                      if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                        return false;
                      }
                      var match = limit.VAL[0];
                      if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                        return match.VAL === C.entity;
                      } else {
                        return false;
                      }
                    })), "Available", (function (limit) {
                  if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                    return "AvailableDuringTrial";
                  } else {
                    return "Unavailable";
                  }
                }));
  };
  return {
          constructLimitExceeded: constructLimitExceeded,
          computeEntityNumericLimits: computeEntityNumericLimits,
          createActionStatus: createActionStatus,
          entityStatus: entityStatus,
          status: status
        };
}

function computeAvailability(workspace) {
  var match = workspace.plan.collaboration;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.collaboration === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.collaboration === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable(workspace) {
  return computeAvailability(workspace) !== "Unavailable";
}

var Collaboration = {
  computeAvailability: computeAvailability,
  availableOnPlan: availableOnPlan,
  isAvailable: isAvailable
};

var getId = Models.Member.getId;

function getItems(workspace, _model) {
  var membersExcludingAvo = Belt_List.keep(workspace.members, (function (member) {
          return Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(member.email), true, (function (email) {
                        if (email.endsWith("@avo.sh")) {
                          return false;
                        } else {
                          return !email.endsWith("@avo.app");
                        }
                      }));
        }));
  return Belt_List.keep(membersExcludingAvo, (function (member) {
                var match = AnalyticsRe.roleFromJs(member.role);
                if (match !== undefined) {
                  return match === "CommentOnly";
                } else {
                  return false;
                }
              }));
}

function getLimit(plan) {
  var count = plan.commentOnlyMembers;
  if (typeof count === "number") {
    if (count === /* NotAvailable */0) {
      return {
              NAME: "Limited",
              VAL: 0
            };
    } else {
      return "Unlimited";
    }
  } else if (count.TAG === /* Limited */1) {
    return {
            NAME: "Limited",
            VAL: count._0
          };
  } else {
    return "Unlimited";
  }
}

function constructLimitExceeded(trial, limit, planHint) {
  if (trial === undefined) {
    return {
            NAME: "LimitExceeded",
            VAL: [
              limit,
              planHint
            ]
          };
  } else {
    return {
            NAME: "AvailableDuringTrial",
            VAL: [
              limit,
              planHint
            ]
          };
  }
}

function computeEntityNumericLimits(workspace, model) {
  var match = getLimit(workspace.plan);
  var limits;
  if (typeof match === "object" && match.NAME === "Limited") {
    var planEntityCountLimit = match.VAL;
    if (Belt_List.length(getItems(workspace, model)) > planEntityCountLimit) {
      var entityCount = Belt_List.length(getItems(workspace, model));
      var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
      var trial = workspace.trial;
      var trialEntityCount;
      if (trial !== undefined) {
        var match$1 = getLimit(trial.plan);
        trialEntityCount = typeof match$1 === "object" ? Caml.caml_int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
      } else {
        trialEntityCount = 0;
      }
      var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
      var limitToInt = function (limit) {
        if (typeof limit === "object") {
          return limit.VAL;
        } else {
          return Pervasives.max_int;
        }
      };
      var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(getItems(workspace, model), (function (entityIndex, entity) {
                  var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                          return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                        }));
                  var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                          var match = getLimit(publicPlan);
                          if (typeof match === "object") {
                            return match.VAL > entityIndex;
                          } else {
                            return true;
                          }
                        }));
                  var currentPlanLimit = limitToInt(getLimit(workspace.plan));
                  var trial = Belt_Option.map(workspace.trial, (function (trial) {
                          return trial.plan;
                        }));
                  var currentTrialLimit = trial !== undefined ? limitToInt(getLimit(trial)) : 0;
                  var exit = 0;
                  if (minimumRequiredPlan !== undefined) {
                    if (entityIndex === (currentPlanLimit - 1 | 0)) {
                      return {
                              hd: {
                                NAME: "LimitReached",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "User",
                                      Curry._1(getId, entity)
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    exit = 1;
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    if (entityIndex < currentPlanLimit) {
                      return /* [] */0;
                    }
                    if (minimumRequiredPlan === undefined) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "User",
                                      Curry._1(getId, entity)
                                    ]
                                  },
                                  Workspace.Instances.enterprise
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    var exit$1 = 0;
                    if (trial !== undefined) {
                      if (entityIndex < currentTrialLimit) {
                        return {
                                hd: {
                                  NAME: "AvailableDuringTrial",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        "User",
                                        Curry._1(getId, entity)
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit$1 = 2;
                    } else {
                      exit$1 = 2;
                    }
                    if (exit$1 === 2) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "User",
                                      Curry._1(getId, entity)
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    
                  }
                  
                })));
      var match$2 = trialEntityCount$1 > 0;
      var match$3 = workspace.trial;
      var exit = 0;
      if (match$2 && match$3 !== undefined) {
        var match$4 = getLimit(match$3.plan);
        limits = typeof match$4 === "object" ? ({
              hd: constructLimitExceeded(workspace.trial, {
                    NAME: "NumericListLimit",
                    VAL: [
                      "User",
                      Belt_List.length(getItems(workspace, model)),
                      match$4.VAL
                    ]
                  }, Workspace.Instances.nextHint(workspace.plan)),
              tl: limits$1
            }) : limits$1;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        limits = {
          hd: constructLimitExceeded(workspace.trial, {
                NAME: "NumericListLimit",
                VAL: [
                  "User",
                  Belt_List.length(getItems(workspace, model)),
                  planEntityCountLimit
                ]
              }, Workspace.Instances.nextHint(workspace.plan)),
          tl: limits$1
        };
      }
      
    } else {
      limits = /* [] */0;
    }
  } else {
    limits = /* [] */0;
  }
  var match$5 = getLimit(workspace.plan);
  var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
          return getLimit(trial.plan);
        }));
  var tmp;
  if (typeof match$5 === "object") {
    var maxPlanEntities = match$5.VAL;
    if (Belt_List.length(getItems(workspace, model)) < maxPlanEntities) {
      tmp = /* [] */0;
    } else if (match$6 !== undefined) {
      if (typeof match$6 === "object") {
        var maxTrialEntities = match$6.VAL;
        tmp = Belt_List.length(getItems(workspace, model)) < maxTrialEntities ? ({
              hd: {
                NAME: "AvailableDuringTrial",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: "User"
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : (
            Belt_List.length(getItems(workspace, model)) === maxTrialEntities ? ({
                  hd: {
                    NAME: "LimitReached",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "User"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                }) : ({
                  hd: {
                    NAME: "LimitExceeded",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "User"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                })
          );
      } else {
        tmp = {
          hd: {
            NAME: "AvailableDuringTrial",
            VAL: [
              {
                NAME: "CreateEntityStatus",
                VAL: "User"
              },
              Workspace.Instances.nextHint(workspace.plan)
            ]
          },
          tl: /* [] */0
        };
      }
    } else {
      tmp = Belt_List.length(getItems(workspace, model)) === maxPlanEntities ? ({
            hd: {
              NAME: "LimitReached",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "User"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          }) : ({
            hd: {
              NAME: "LimitExceeded",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "User"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          });
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.concat(limits, tmp);
}

function createActionStatus(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "User";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

function entityStatus(workspaceLimits, entityId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var entity;
                    var id;
                    var variant = limit.NAME;
                    if (variant === "LimitReached") {
                      var match = limit.VAL[0];
                      if (typeof match !== "object") {
                        return false;
                      }
                      if (match.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$1 = match.VAL;
                      entity = match$1[0];
                      id = match$1[1];
                    } else if (variant === "AvailableDuringTrial") {
                      var match$2 = limit.VAL[0];
                      if (typeof match$2 !== "object") {
                        return false;
                      }
                      if (match$2.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$3 = match$2.VAL;
                      entity = match$3[0];
                      id = match$3[1];
                    } else {
                      var match$4 = limit.VAL[0];
                      if (typeof match$4 !== "object") {
                        return false;
                      }
                      if (match$4.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$5 = match$4.VAL;
                      entity = match$5[0];
                      id = match$5[1];
                    }
                    if (entityId === id) {
                      return entity === "User";
                    } else {
                      return false;
                    }
                  })), [
              "Available",
              undefined
            ], (function (limit) {
                var variant = limit.NAME;
                if (variant === "LimitReached") {
                  return [
                          "Available",
                          limit.VAL[1]
                        ];
                } else if (variant === "AvailableDuringTrial") {
                  return [
                          "AvailableDuringTrial",
                          limit.VAL[1]
                        ];
                } else {
                  return [
                          "Unavailable",
                          limit.VAL[1]
                        ];
                }
              }));
}

function status(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    if (typeof limit !== "object") {
                      return false;
                    }
                    var variant = limit.NAME;
                    if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                      return false;
                    }
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "User";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

var Commenters = {
  constructLimitExceeded: constructLimitExceeded,
  computeEntityNumericLimits: computeEntityNumericLimits,
  createActionStatus: createActionStatus,
  entityStatus: entityStatus,
  status: status
};

function computeAvailability$1(workspace) {
  var match = workspace.plan.configEventSourcesAndDestinations;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.configEventSourcesAndDestinations === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$1(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.configEventSourcesAndDestinations === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$1(workspace) {
  return computeAvailability$1(workspace) !== "Unavailable";
}

var ConfigEventSourcesAndDestinations = {
  computeAvailability: computeAvailability$1,
  availableOnPlan: availableOnPlan$1,
  isAvailable: isAvailable$1
};

function computeAvailability$2(workspace) {
  var match = workspace.plan.sso;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.sso === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$2(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.sso === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$2(workspace) {
  return computeAvailability$2(workspace) !== "Unavailable";
}

var Sso = {
  computeAvailability: computeAvailability$2,
  availableOnPlan: availableOnPlan$2,
  isAvailable: isAvailable$2
};

function computeAvailability$3(workspace) {
  var match = workspace.plan.canProtectMainBranch;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.canProtectMainBranch === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$3(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.canProtectMainBranch === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$3(workspace) {
  return computeAvailability$3(workspace) !== "Unavailable";
}

var CanProtectMainBranch = {
  computeAvailability: computeAvailability$3,
  availableOnPlan: availableOnPlan$3,
  isAvailable: isAvailable$3
};

function computeAvailability$4(workspace) {
  var match = workspace.plan.advancedProtectedMainBranch;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.advancedProtectedMainBranch === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$4(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.advancedProtectedMainBranch === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$4(workspace) {
  return computeAvailability$4(workspace) !== "Unavailable";
}

var AdvancedProtectedMainBranch = {
  computeAvailability: computeAvailability$4,
  availableOnPlan: availableOnPlan$4,
  isAvailable: isAvailable$4
};

function computeAvailability$5(workspace) {
  var match = workspace.plan.forceBranchAudit;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.forceBranchAudit === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$5(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.forceBranchAudit === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$5(workspace) {
  return computeAvailability$5(workspace) !== "Unavailable";
}

var ForceBranchAudit = {
  computeAvailability: computeAvailability$5,
  availableOnPlan: availableOnPlan$5,
  isAvailable: isAvailable$5
};

function computeAvailability$6(workspace) {
  var match = workspace.plan.approvalWorkflow;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.approvalWorkflow === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$6(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.approvalWorkflow === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$6(workspace) {
  return computeAvailability$6(workspace) !== "Unavailable";
}

var ApprovalWorkflow = {
  computeAvailability: computeAvailability$6,
  availableOnPlan: availableOnPlan$6,
  isAvailable: isAvailable$6
};

function computeAvailability$7(workspace) {
  var match = workspace.plan.propertyAbsenceByEventAndSource;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.propertyAbsenceByEventAndSource === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$7(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.propertyAbsenceByEventAndSource === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$7(workspace) {
  return computeAvailability$7(workspace) !== "Unavailable";
}

var PropertyAbsenceByEventAndSource = {
  computeAvailability: computeAvailability$7,
  availableOnPlan: availableOnPlan$7,
  isAvailable: isAvailable$7
};

function computeAvailability$8(workspace) {
  var match = workspace.plan.propertyMatchesBySource;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.propertyMatchesBySource === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$8(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.propertyMatchesBySource === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$8(workspace) {
  return computeAvailability$8(workspace) !== "Unavailable";
}

var PropertyMatchesBySource = {
  computeAvailability: computeAvailability$8,
  availableOnPlan: availableOnPlan$8,
  isAvailable: isAvailable$8
};

function computeAvailability$9(workspace) {
  var match = workspace.plan.activityLog;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.activityLog === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$9(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.activityLog === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$9(workspace) {
  return computeAvailability$9(workspace) !== "Unavailable";
}

var ActivityLogs = {
  computeAvailability: computeAvailability$9,
  availableOnPlan: availableOnPlan$9,
  isAvailable: isAvailable$9
};

function computeAvailability$10(workspace) {
  var match = workspace.plan.auditLog;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.auditLog === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$10(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.auditLog === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$10(workspace) {
  return computeAvailability$10(workspace) !== "Unavailable";
}

var AuditLog = {
  computeAvailability: computeAvailability$10,
  availableOnPlan: availableOnPlan$10,
  isAvailable: isAvailable$10
};

function constructLimitExceeded$1(trial, limit, planHint) {
  if (trial === undefined) {
    return {
            NAME: "LimitExceeded",
            VAL: [
              limit,
              planHint
            ]
          };
  } else {
    return {
            NAME: "AvailableDuringTrial",
            VAL: [
              limit,
              planHint
            ]
          };
  }
}

function computeEntityNumericLimits$1(workspace, model) {
  var match = workspace.plan.maxServiceAccounts;
  var limits;
  if (typeof match === "object" && match.NAME === "Limited") {
    var planEntityCountLimit = match.VAL;
    if (Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts)) > planEntityCountLimit) {
      var entityCount = Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts));
      var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
      var trial = workspace.trial;
      var trialEntityCount;
      if (trial !== undefined) {
        var match$1 = trial.plan.maxServiceAccounts;
        trialEntityCount = typeof match$1 === "object" ? Caml.caml_int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
      } else {
        trialEntityCount = 0;
      }
      var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
      var limitToInt = function (limit) {
        if (typeof limit === "object") {
          return limit.VAL;
        } else {
          return Pervasives.max_int;
        }
      };
      var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(Belt_List.fromArray(workspace.serviceAccounts), (function (entityIndex, entity) {
                  var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                          return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                        }));
                  var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                          var match = publicPlan.maxServiceAccounts;
                          if (typeof match === "object") {
                            return match.VAL > entityIndex;
                          } else {
                            return true;
                          }
                        }));
                  var currentPlanLimit = limitToInt(workspace.plan.maxServiceAccounts);
                  var trial = Belt_Option.map(workspace.trial, (function (trial) {
                          return trial.plan;
                        }));
                  var currentTrialLimit = trial !== undefined ? limitToInt(trial.maxServiceAccounts) : 0;
                  var exit = 0;
                  if (minimumRequiredPlan !== undefined) {
                    if (entityIndex === (currentPlanLimit - 1 | 0)) {
                      return {
                              hd: {
                                NAME: "LimitReached",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "ServiceAccount",
                                      entity.name
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    exit = 1;
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    if (entityIndex < currentPlanLimit) {
                      return /* [] */0;
                    }
                    if (minimumRequiredPlan === undefined) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "ServiceAccount",
                                      entity.name
                                    ]
                                  },
                                  Workspace.Instances.enterprise
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    var exit$1 = 0;
                    if (trial !== undefined) {
                      if (entityIndex < currentTrialLimit) {
                        return {
                                hd: {
                                  NAME: "AvailableDuringTrial",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        "ServiceAccount",
                                        entity.name
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit$1 = 2;
                    } else {
                      exit$1 = 2;
                    }
                    if (exit$1 === 2) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "ServiceAccount",
                                      entity.name
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    
                  }
                  
                })));
      var match$2 = trialEntityCount$1 > 0;
      var match$3 = workspace.trial;
      var exit = 0;
      if (match$2 && match$3 !== undefined) {
        var match$4 = match$3.plan.maxServiceAccounts;
        limits = typeof match$4 === "object" ? ({
              hd: constructLimitExceeded$1(workspace.trial, {
                    NAME: "NumericListLimit",
                    VAL: [
                      "ServiceAccount",
                      Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts)),
                      match$4.VAL
                    ]
                  }, Workspace.Instances.nextHint(workspace.plan)),
              tl: limits$1
            }) : limits$1;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        limits = {
          hd: constructLimitExceeded$1(workspace.trial, {
                NAME: "NumericListLimit",
                VAL: [
                  "ServiceAccount",
                  Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts)),
                  planEntityCountLimit
                ]
              }, Workspace.Instances.nextHint(workspace.plan)),
          tl: limits$1
        };
      }
      
    } else {
      limits = /* [] */0;
    }
  } else {
    limits = /* [] */0;
  }
  var match$5 = workspace.plan.maxServiceAccounts;
  var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
          return trial.plan.maxServiceAccounts;
        }));
  var tmp;
  if (typeof match$5 === "object") {
    var maxPlanEntities = match$5.VAL;
    if (Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts)) < maxPlanEntities) {
      tmp = /* [] */0;
    } else if (match$6 !== undefined) {
      if (typeof match$6 === "object") {
        var maxTrialEntities = match$6.VAL;
        tmp = Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts)) < maxTrialEntities ? ({
              hd: {
                NAME: "AvailableDuringTrial",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: "ServiceAccount"
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : (
            Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts)) === maxTrialEntities ? ({
                  hd: {
                    NAME: "LimitReached",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "ServiceAccount"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                }) : ({
                  hd: {
                    NAME: "LimitExceeded",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "ServiceAccount"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                })
          );
      } else {
        tmp = {
          hd: {
            NAME: "AvailableDuringTrial",
            VAL: [
              {
                NAME: "CreateEntityStatus",
                VAL: "ServiceAccount"
              },
              Workspace.Instances.nextHint(workspace.plan)
            ]
          },
          tl: /* [] */0
        };
      }
    } else {
      tmp = Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts)) === maxPlanEntities ? ({
            hd: {
              NAME: "LimitReached",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "ServiceAccount"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          }) : ({
            hd: {
              NAME: "LimitExceeded",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "ServiceAccount"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          });
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.concat(limits, tmp);
}

function createActionStatus$1(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "ServiceAccount";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

function entityStatus$1(workspaceLimits, entityId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var entity;
                    var id;
                    var variant = limit.NAME;
                    if (variant === "LimitReached") {
                      var match = limit.VAL[0];
                      if (typeof match !== "object") {
                        return false;
                      }
                      if (match.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$1 = match.VAL;
                      entity = match$1[0];
                      id = match$1[1];
                    } else if (variant === "AvailableDuringTrial") {
                      var match$2 = limit.VAL[0];
                      if (typeof match$2 !== "object") {
                        return false;
                      }
                      if (match$2.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$3 = match$2.VAL;
                      entity = match$3[0];
                      id = match$3[1];
                    } else {
                      var match$4 = limit.VAL[0];
                      if (typeof match$4 !== "object") {
                        return false;
                      }
                      if (match$4.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$5 = match$4.VAL;
                      entity = match$5[0];
                      id = match$5[1];
                    }
                    if (entityId === id) {
                      return entity === "ServiceAccount";
                    } else {
                      return false;
                    }
                  })), [
              "Available",
              undefined
            ], (function (limit) {
                var variant = limit.NAME;
                if (variant === "LimitReached") {
                  return [
                          "Available",
                          limit.VAL[1]
                        ];
                } else if (variant === "AvailableDuringTrial") {
                  return [
                          "AvailableDuringTrial",
                          limit.VAL[1]
                        ];
                } else {
                  return [
                          "Unavailable",
                          limit.VAL[1]
                        ];
                }
              }));
}

function status$1(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    if (typeof limit !== "object") {
                      return false;
                    }
                    var variant = limit.NAME;
                    if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                      return false;
                    }
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "ServiceAccount";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

var ServiceAccount = {
  constructLimitExceeded: constructLimitExceeded$1,
  computeEntityNumericLimits: computeEntityNumericLimits$1,
  createActionStatus: createActionStatus$1,
  entityStatus: entityStatus$1,
  status: status$1
};

function constructLimitExceeded$2(trial, limit, planHint) {
  if (trial === undefined) {
    return {
            NAME: "LimitExceeded",
            VAL: [
              limit,
              planHint
            ]
          };
  } else {
    return {
            NAME: "AvailableDuringTrial",
            VAL: [
              limit,
              planHint
            ]
          };
  }
}

function computeEntityNumericLimits$2(workspace, model) {
  var match = workspace.plan.maxSources;
  var limits;
  if (typeof match === "object" && match.NAME === "Limited") {
    var planEntityCountLimit = match.VAL;
    if (Belt_List.length(model.sources) > planEntityCountLimit) {
      var entityCount = Belt_List.length(model.sources);
      var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
      var trial = workspace.trial;
      var trialEntityCount;
      if (trial !== undefined) {
        var match$1 = trial.plan.maxSources;
        trialEntityCount = typeof match$1 === "object" ? Caml.caml_int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
      } else {
        trialEntityCount = 0;
      }
      var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
      var limitToInt = function (limit) {
        if (typeof limit === "object") {
          return limit.VAL;
        } else {
          return Pervasives.max_int;
        }
      };
      var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(model.sources, (function (entityIndex, entity) {
                  var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                          return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                        }));
                  var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                          var match = publicPlan.maxSources;
                          if (typeof match === "object") {
                            return match.VAL > entityIndex;
                          } else {
                            return true;
                          }
                        }));
                  var currentPlanLimit = limitToInt(workspace.plan.maxSources);
                  var trial = Belt_Option.map(workspace.trial, (function (trial) {
                          return trial.plan;
                        }));
                  var currentTrialLimit = trial !== undefined ? limitToInt(trial.maxSources) : 0;
                  var exit = 0;
                  if (minimumRequiredPlan !== undefined) {
                    if (entityIndex === (currentPlanLimit - 1 | 0)) {
                      return {
                              hd: {
                                NAME: "LimitReached",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Source",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    exit = 1;
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    if (entityIndex < currentPlanLimit) {
                      return /* [] */0;
                    }
                    if (minimumRequiredPlan === undefined) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Source",
                                      entity.id
                                    ]
                                  },
                                  Workspace.Instances.enterprise
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    var exit$1 = 0;
                    if (trial !== undefined) {
                      if (entityIndex < currentTrialLimit) {
                        return {
                                hd: {
                                  NAME: "AvailableDuringTrial",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        "Source",
                                        entity.id
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit$1 = 2;
                    } else {
                      exit$1 = 2;
                    }
                    if (exit$1 === 2) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Source",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    
                  }
                  
                })));
      var match$2 = trialEntityCount$1 > 0;
      var match$3 = workspace.trial;
      var exit = 0;
      if (match$2 && match$3 !== undefined) {
        var match$4 = match$3.plan.maxSources;
        limits = typeof match$4 === "object" ? ({
              hd: constructLimitExceeded$2(workspace.trial, {
                    NAME: "NumericListLimit",
                    VAL: [
                      "Source",
                      Belt_List.length(model.sources),
                      match$4.VAL
                    ]
                  }, Workspace.Instances.nextHint(workspace.plan)),
              tl: limits$1
            }) : limits$1;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        limits = {
          hd: constructLimitExceeded$2(workspace.trial, {
                NAME: "NumericListLimit",
                VAL: [
                  "Source",
                  Belt_List.length(model.sources),
                  planEntityCountLimit
                ]
              }, Workspace.Instances.nextHint(workspace.plan)),
          tl: limits$1
        };
      }
      
    } else {
      limits = /* [] */0;
    }
  } else {
    limits = /* [] */0;
  }
  var match$5 = workspace.plan.maxSources;
  var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
          return trial.plan.maxSources;
        }));
  var tmp;
  if (typeof match$5 === "object") {
    var maxPlanEntities = match$5.VAL;
    if (Belt_List.length(model.sources) < maxPlanEntities) {
      tmp = /* [] */0;
    } else if (match$6 !== undefined) {
      if (typeof match$6 === "object") {
        var maxTrialEntities = match$6.VAL;
        tmp = Belt_List.length(model.sources) < maxTrialEntities ? ({
              hd: {
                NAME: "AvailableDuringTrial",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: "Source"
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : (
            Belt_List.length(model.sources) === maxTrialEntities ? ({
                  hd: {
                    NAME: "LimitReached",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "Source"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                }) : ({
                  hd: {
                    NAME: "LimitExceeded",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "Source"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                })
          );
      } else {
        tmp = {
          hd: {
            NAME: "AvailableDuringTrial",
            VAL: [
              {
                NAME: "CreateEntityStatus",
                VAL: "Source"
              },
              Workspace.Instances.nextHint(workspace.plan)
            ]
          },
          tl: /* [] */0
        };
      }
    } else {
      tmp = Belt_List.length(model.sources) === maxPlanEntities ? ({
            hd: {
              NAME: "LimitReached",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "Source"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          }) : ({
            hd: {
              NAME: "LimitExceeded",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "Source"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          });
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.concat(limits, tmp);
}

function createActionStatus$2(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "Source";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

function entityStatus$2(workspaceLimits, entityId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var entity;
                    var id;
                    var variant = limit.NAME;
                    if (variant === "LimitReached") {
                      var match = limit.VAL[0];
                      if (typeof match !== "object") {
                        return false;
                      }
                      if (match.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$1 = match.VAL;
                      entity = match$1[0];
                      id = match$1[1];
                    } else if (variant === "AvailableDuringTrial") {
                      var match$2 = limit.VAL[0];
                      if (typeof match$2 !== "object") {
                        return false;
                      }
                      if (match$2.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$3 = match$2.VAL;
                      entity = match$3[0];
                      id = match$3[1];
                    } else {
                      var match$4 = limit.VAL[0];
                      if (typeof match$4 !== "object") {
                        return false;
                      }
                      if (match$4.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$5 = match$4.VAL;
                      entity = match$5[0];
                      id = match$5[1];
                    }
                    if (entityId === id) {
                      return entity === "Source";
                    } else {
                      return false;
                    }
                  })), [
              "Available",
              undefined
            ], (function (limit) {
                var variant = limit.NAME;
                if (variant === "LimitReached") {
                  return [
                          "Available",
                          limit.VAL[1]
                        ];
                } else if (variant === "AvailableDuringTrial") {
                  return [
                          "AvailableDuringTrial",
                          limit.VAL[1]
                        ];
                } else {
                  return [
                          "Unavailable",
                          limit.VAL[1]
                        ];
                }
              }));
}

function status$2(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    if (typeof limit !== "object") {
                      return false;
                    }
                    var variant = limit.NAME;
                    if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                      return false;
                    }
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "Source";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

var Source = {
  constructLimitExceeded: constructLimitExceeded$2,
  computeEntityNumericLimits: computeEntityNumericLimits$2,
  createActionStatus: createActionStatus$2,
  entityStatus: entityStatus$2,
  status: status$2
};

function constructLimitExceeded$3(trial, limit, planHint) {
  if (trial === undefined) {
    return {
            NAME: "LimitExceeded",
            VAL: [
              limit,
              planHint
            ]
          };
  } else {
    return {
            NAME: "AvailableDuringTrial",
            VAL: [
              limit,
              planHint
            ]
          };
  }
}

function computeEntityNumericLimits$3(workspace, model) {
  var match = workspace.plan.maxDestinations;
  var limits;
  if (typeof match === "object" && match.NAME === "Limited") {
    var planEntityCountLimit = match.VAL;
    if (Belt_List.length(model.destinations) > planEntityCountLimit) {
      var entityCount = Belt_List.length(model.destinations);
      var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
      var trial = workspace.trial;
      var trialEntityCount;
      if (trial !== undefined) {
        var match$1 = trial.plan.maxDestinations;
        trialEntityCount = typeof match$1 === "object" ? Caml.caml_int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
      } else {
        trialEntityCount = 0;
      }
      var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
      var limitToInt = function (limit) {
        if (typeof limit === "object") {
          return limit.VAL;
        } else {
          return Pervasives.max_int;
        }
      };
      var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(model.destinations, (function (entityIndex, entity) {
                  var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                          return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                        }));
                  var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                          var match = publicPlan.maxDestinations;
                          if (typeof match === "object") {
                            return match.VAL > entityIndex;
                          } else {
                            return true;
                          }
                        }));
                  var currentPlanLimit = limitToInt(workspace.plan.maxDestinations);
                  var trial = Belt_Option.map(workspace.trial, (function (trial) {
                          return trial.plan;
                        }));
                  var currentTrialLimit = trial !== undefined ? limitToInt(trial.maxDestinations) : 0;
                  var exit = 0;
                  if (minimumRequiredPlan !== undefined) {
                    if (entityIndex === (currentPlanLimit - 1 | 0)) {
                      return {
                              hd: {
                                NAME: "LimitReached",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Destination",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    exit = 1;
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    if (entityIndex < currentPlanLimit) {
                      return /* [] */0;
                    }
                    if (minimumRequiredPlan === undefined) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Destination",
                                      entity.id
                                    ]
                                  },
                                  Workspace.Instances.enterprise
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    var exit$1 = 0;
                    if (trial !== undefined) {
                      if (entityIndex < currentTrialLimit) {
                        return {
                                hd: {
                                  NAME: "AvailableDuringTrial",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        "Destination",
                                        entity.id
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit$1 = 2;
                    } else {
                      exit$1 = 2;
                    }
                    if (exit$1 === 2) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Destination",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    
                  }
                  
                })));
      var match$2 = trialEntityCount$1 > 0;
      var match$3 = workspace.trial;
      var exit = 0;
      if (match$2 && match$3 !== undefined) {
        var match$4 = match$3.plan.maxDestinations;
        limits = typeof match$4 === "object" ? ({
              hd: constructLimitExceeded$3(workspace.trial, {
                    NAME: "NumericListLimit",
                    VAL: [
                      "Destination",
                      Belt_List.length(model.destinations),
                      match$4.VAL
                    ]
                  }, Workspace.Instances.nextHint(workspace.plan)),
              tl: limits$1
            }) : limits$1;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        limits = {
          hd: constructLimitExceeded$3(workspace.trial, {
                NAME: "NumericListLimit",
                VAL: [
                  "Destination",
                  Belt_List.length(model.destinations),
                  planEntityCountLimit
                ]
              }, Workspace.Instances.nextHint(workspace.plan)),
          tl: limits$1
        };
      }
      
    } else {
      limits = /* [] */0;
    }
  } else {
    limits = /* [] */0;
  }
  var match$5 = workspace.plan.maxDestinations;
  var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
          return trial.plan.maxDestinations;
        }));
  var tmp;
  if (typeof match$5 === "object") {
    var maxPlanEntities = match$5.VAL;
    if (Belt_List.length(model.destinations) < maxPlanEntities) {
      tmp = /* [] */0;
    } else if (match$6 !== undefined) {
      if (typeof match$6 === "object") {
        var maxTrialEntities = match$6.VAL;
        tmp = Belt_List.length(model.destinations) < maxTrialEntities ? ({
              hd: {
                NAME: "AvailableDuringTrial",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: "Destination"
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : (
            Belt_List.length(model.destinations) === maxTrialEntities ? ({
                  hd: {
                    NAME: "LimitReached",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "Destination"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                }) : ({
                  hd: {
                    NAME: "LimitExceeded",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "Destination"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                })
          );
      } else {
        tmp = {
          hd: {
            NAME: "AvailableDuringTrial",
            VAL: [
              {
                NAME: "CreateEntityStatus",
                VAL: "Destination"
              },
              Workspace.Instances.nextHint(workspace.plan)
            ]
          },
          tl: /* [] */0
        };
      }
    } else {
      tmp = Belt_List.length(model.destinations) === maxPlanEntities ? ({
            hd: {
              NAME: "LimitReached",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "Destination"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          }) : ({
            hd: {
              NAME: "LimitExceeded",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "Destination"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          });
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.concat(limits, tmp);
}

function createActionStatus$3(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "Destination";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

function entityStatus$3(workspaceLimits, entityId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var entity;
                    var id;
                    var variant = limit.NAME;
                    if (variant === "LimitReached") {
                      var match = limit.VAL[0];
                      if (typeof match !== "object") {
                        return false;
                      }
                      if (match.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$1 = match.VAL;
                      entity = match$1[0];
                      id = match$1[1];
                    } else if (variant === "AvailableDuringTrial") {
                      var match$2 = limit.VAL[0];
                      if (typeof match$2 !== "object") {
                        return false;
                      }
                      if (match$2.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$3 = match$2.VAL;
                      entity = match$3[0];
                      id = match$3[1];
                    } else {
                      var match$4 = limit.VAL[0];
                      if (typeof match$4 !== "object") {
                        return false;
                      }
                      if (match$4.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$5 = match$4.VAL;
                      entity = match$5[0];
                      id = match$5[1];
                    }
                    if (entityId === id) {
                      return entity === "Destination";
                    } else {
                      return false;
                    }
                  })), [
              "Available",
              undefined
            ], (function (limit) {
                var variant = limit.NAME;
                if (variant === "LimitReached") {
                  return [
                          "Available",
                          limit.VAL[1]
                        ];
                } else if (variant === "AvailableDuringTrial") {
                  return [
                          "AvailableDuringTrial",
                          limit.VAL[1]
                        ];
                } else {
                  return [
                          "Unavailable",
                          limit.VAL[1]
                        ];
                }
              }));
}

function status$3(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    if (typeof limit !== "object") {
                      return false;
                    }
                    var variant = limit.NAME;
                    if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                      return false;
                    }
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "Destination";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

var Destination = {
  constructLimitExceeded: constructLimitExceeded$3,
  computeEntityNumericLimits: computeEntityNumericLimits$3,
  createActionStatus: createActionStatus$3,
  entityStatus: entityStatus$3,
  status: status$3
};

function constructLimitExceeded$4(trial, limit, planHint) {
  if (trial === undefined) {
    return {
            NAME: "LimitExceeded",
            VAL: [
              limit,
              planHint
            ]
          };
  } else {
    return {
            NAME: "AvailableDuringTrial",
            VAL: [
              limit,
              planHint
            ]
          };
  }
}

function computeEntityNumericLimits$4(workspace, model) {
  var match = workspace.plan.maxIntegrations;
  var limits;
  if (typeof match === "object" && match.NAME === "Limited") {
    var planEntityCountLimit = match.VAL;
    if (Belt_List.length(model.integrations) > planEntityCountLimit) {
      var entityCount = Belt_List.length(model.integrations);
      var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
      var trial = workspace.trial;
      var trialEntityCount;
      if (trial !== undefined) {
        var match$1 = trial.plan.maxIntegrations;
        trialEntityCount = typeof match$1 === "object" ? Caml.caml_int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
      } else {
        trialEntityCount = 0;
      }
      var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
      var limitToInt = function (limit) {
        if (typeof limit === "object") {
          return limit.VAL;
        } else {
          return Pervasives.max_int;
        }
      };
      var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(model.integrations, (function (entityIndex, entity) {
                  var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                          return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                        }));
                  var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                          var match = publicPlan.maxIntegrations;
                          if (typeof match === "object") {
                            return match.VAL > entityIndex;
                          } else {
                            return true;
                          }
                        }));
                  var currentPlanLimit = limitToInt(workspace.plan.maxIntegrations);
                  var trial = Belt_Option.map(workspace.trial, (function (trial) {
                          return trial.plan;
                        }));
                  var currentTrialLimit = trial !== undefined ? limitToInt(trial.maxIntegrations) : 0;
                  var exit = 0;
                  if (minimumRequiredPlan !== undefined) {
                    if (entityIndex === (currentPlanLimit - 1 | 0)) {
                      return {
                              hd: {
                                NAME: "LimitReached",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Integration",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    exit = 1;
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    if (entityIndex < currentPlanLimit) {
                      return /* [] */0;
                    }
                    if (minimumRequiredPlan === undefined) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Integration",
                                      entity.id
                                    ]
                                  },
                                  Workspace.Instances.enterprise
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    var exit$1 = 0;
                    if (trial !== undefined) {
                      if (entityIndex < currentTrialLimit) {
                        return {
                                hd: {
                                  NAME: "AvailableDuringTrial",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        "Integration",
                                        entity.id
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit$1 = 2;
                    } else {
                      exit$1 = 2;
                    }
                    if (exit$1 === 2) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Integration",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    
                  }
                  
                })));
      var match$2 = trialEntityCount$1 > 0;
      var match$3 = workspace.trial;
      var exit = 0;
      if (match$2 && match$3 !== undefined) {
        var match$4 = match$3.plan.maxIntegrations;
        limits = typeof match$4 === "object" ? ({
              hd: constructLimitExceeded$4(workspace.trial, {
                    NAME: "NumericListLimit",
                    VAL: [
                      "Integration",
                      Belt_List.length(model.integrations),
                      match$4.VAL
                    ]
                  }, Workspace.Instances.nextHint(workspace.plan)),
              tl: limits$1
            }) : limits$1;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        limits = {
          hd: constructLimitExceeded$4(workspace.trial, {
                NAME: "NumericListLimit",
                VAL: [
                  "Integration",
                  Belt_List.length(model.integrations),
                  planEntityCountLimit
                ]
              }, Workspace.Instances.nextHint(workspace.plan)),
          tl: limits$1
        };
      }
      
    } else {
      limits = /* [] */0;
    }
  } else {
    limits = /* [] */0;
  }
  var match$5 = workspace.plan.maxIntegrations;
  var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
          return trial.plan.maxIntegrations;
        }));
  var tmp;
  if (typeof match$5 === "object") {
    var maxPlanEntities = match$5.VAL;
    if (Belt_List.length(model.integrations) < maxPlanEntities) {
      tmp = /* [] */0;
    } else if (match$6 !== undefined) {
      if (typeof match$6 === "object") {
        var maxTrialEntities = match$6.VAL;
        tmp = Belt_List.length(model.integrations) < maxTrialEntities ? ({
              hd: {
                NAME: "AvailableDuringTrial",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: "Integration"
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : (
            Belt_List.length(model.integrations) === maxTrialEntities ? ({
                  hd: {
                    NAME: "LimitReached",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "Integration"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                }) : ({
                  hd: {
                    NAME: "LimitExceeded",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "Integration"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                })
          );
      } else {
        tmp = {
          hd: {
            NAME: "AvailableDuringTrial",
            VAL: [
              {
                NAME: "CreateEntityStatus",
                VAL: "Integration"
              },
              Workspace.Instances.nextHint(workspace.plan)
            ]
          },
          tl: /* [] */0
        };
      }
    } else {
      tmp = Belt_List.length(model.integrations) === maxPlanEntities ? ({
            hd: {
              NAME: "LimitReached",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "Integration"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          }) : ({
            hd: {
              NAME: "LimitExceeded",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "Integration"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          });
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.concat(limits, tmp);
}

function createActionStatus$4(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "Integration";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

function entityStatus$4(workspaceLimits, entityId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var entity;
                    var id;
                    var variant = limit.NAME;
                    if (variant === "LimitReached") {
                      var match = limit.VAL[0];
                      if (typeof match !== "object") {
                        return false;
                      }
                      if (match.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$1 = match.VAL;
                      entity = match$1[0];
                      id = match$1[1];
                    } else if (variant === "AvailableDuringTrial") {
                      var match$2 = limit.VAL[0];
                      if (typeof match$2 !== "object") {
                        return false;
                      }
                      if (match$2.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$3 = match$2.VAL;
                      entity = match$3[0];
                      id = match$3[1];
                    } else {
                      var match$4 = limit.VAL[0];
                      if (typeof match$4 !== "object") {
                        return false;
                      }
                      if (match$4.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$5 = match$4.VAL;
                      entity = match$5[0];
                      id = match$5[1];
                    }
                    if (entityId === id) {
                      return entity === "Integration";
                    } else {
                      return false;
                    }
                  })), [
              "Available",
              undefined
            ], (function (limit) {
                var variant = limit.NAME;
                if (variant === "LimitReached") {
                  return [
                          "Available",
                          limit.VAL[1]
                        ];
                } else if (variant === "AvailableDuringTrial") {
                  return [
                          "AvailableDuringTrial",
                          limit.VAL[1]
                        ];
                } else {
                  return [
                          "Unavailable",
                          limit.VAL[1]
                        ];
                }
              }));
}

function status$4(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    if (typeof limit !== "object") {
                      return false;
                    }
                    var variant = limit.NAME;
                    if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                      return false;
                    }
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "Integration";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

var Integration = {
  constructLimitExceeded: constructLimitExceeded$4,
  computeEntityNumericLimits: computeEntityNumericLimits$4,
  createActionStatus: createActionStatus$4,
  entityStatus: entityStatus$4,
  status: status$4
};

var getId$1 = Models.Member.getId;

function getItems$1(workspace, _model) {
  var membersExcludingAvo = Belt_List.keep(workspace.members, (function (member) {
          return Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(member.email), true, (function (email) {
                        if (email.endsWith("@avo.sh")) {
                          return false;
                        } else {
                          return !email.endsWith("@avo.app");
                        }
                      }));
        }));
  return Belt_List.keep(membersExcludingAvo, (function (member) {
                var match = AnalyticsRe.roleFromJs(member.role);
                if (match !== undefined) {
                  if (match === "Viewer") {
                    return workspace.plan.countsViewersAsUsers;
                  } else {
                    return match !== "CommentOnly";
                  }
                } else {
                  return true;
                }
              }));
}

function constructLimitExceeded$5(trial, limit, planHint) {
  if (trial === undefined) {
    return {
            NAME: "LimitExceeded",
            VAL: [
              limit,
              planHint
            ]
          };
  } else {
    return {
            NAME: "AvailableDuringTrial",
            VAL: [
              limit,
              planHint
            ]
          };
  }
}

function computeEntityNumericLimits$5(workspace, model) {
  var match = workspace.plan.maxUsers;
  var limits;
  if (typeof match === "object" && match.NAME === "Limited") {
    var planEntityCountLimit = match.VAL;
    if (Belt_List.length(getItems$1(workspace, model)) > planEntityCountLimit) {
      var entityCount = Belt_List.length(getItems$1(workspace, model));
      var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
      var trial = workspace.trial;
      var trialEntityCount;
      if (trial !== undefined) {
        var match$1 = trial.plan.maxUsers;
        trialEntityCount = typeof match$1 === "object" ? Caml.caml_int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
      } else {
        trialEntityCount = 0;
      }
      var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
      var limitToInt = function (limit) {
        if (typeof limit === "object") {
          return limit.VAL;
        } else {
          return Pervasives.max_int;
        }
      };
      var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(getItems$1(workspace, model), (function (entityIndex, entity) {
                  var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                          return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                        }));
                  var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                          var match = publicPlan.maxUsers;
                          if (typeof match === "object") {
                            return match.VAL > entityIndex;
                          } else {
                            return true;
                          }
                        }));
                  var currentPlanLimit = limitToInt(workspace.plan.maxUsers);
                  var trial = Belt_Option.map(workspace.trial, (function (trial) {
                          return trial.plan;
                        }));
                  var currentTrialLimit = trial !== undefined ? limitToInt(trial.maxUsers) : 0;
                  var exit = 0;
                  if (minimumRequiredPlan !== undefined) {
                    if (entityIndex === (currentPlanLimit - 1 | 0)) {
                      return {
                              hd: {
                                NAME: "LimitReached",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "User",
                                      Curry._1(getId$1, entity)
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    exit = 1;
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    if (entityIndex < currentPlanLimit) {
                      return /* [] */0;
                    }
                    if (minimumRequiredPlan === undefined) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "User",
                                      Curry._1(getId$1, entity)
                                    ]
                                  },
                                  Workspace.Instances.enterprise
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    var exit$1 = 0;
                    if (trial !== undefined) {
                      if (entityIndex < currentTrialLimit) {
                        return {
                                hd: {
                                  NAME: "AvailableDuringTrial",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        "User",
                                        Curry._1(getId$1, entity)
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit$1 = 2;
                    } else {
                      exit$1 = 2;
                    }
                    if (exit$1 === 2) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "User",
                                      Curry._1(getId$1, entity)
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    
                  }
                  
                })));
      var match$2 = trialEntityCount$1 > 0;
      var match$3 = workspace.trial;
      var exit = 0;
      if (match$2 && match$3 !== undefined) {
        var match$4 = match$3.plan.maxUsers;
        limits = typeof match$4 === "object" ? ({
              hd: constructLimitExceeded$5(workspace.trial, {
                    NAME: "NumericListLimit",
                    VAL: [
                      "User",
                      Belt_List.length(getItems$1(workspace, model)),
                      match$4.VAL
                    ]
                  }, Workspace.Instances.nextHint(workspace.plan)),
              tl: limits$1
            }) : limits$1;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        limits = {
          hd: constructLimitExceeded$5(workspace.trial, {
                NAME: "NumericListLimit",
                VAL: [
                  "User",
                  Belt_List.length(getItems$1(workspace, model)),
                  planEntityCountLimit
                ]
              }, Workspace.Instances.nextHint(workspace.plan)),
          tl: limits$1
        };
      }
      
    } else {
      limits = /* [] */0;
    }
  } else {
    limits = /* [] */0;
  }
  var match$5 = workspace.plan.maxUsers;
  var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
          return trial.plan.maxUsers;
        }));
  var tmp;
  if (typeof match$5 === "object") {
    var maxPlanEntities = match$5.VAL;
    if (Belt_List.length(getItems$1(workspace, model)) < maxPlanEntities) {
      tmp = /* [] */0;
    } else if (match$6 !== undefined) {
      if (typeof match$6 === "object") {
        var maxTrialEntities = match$6.VAL;
        tmp = Belt_List.length(getItems$1(workspace, model)) < maxTrialEntities ? ({
              hd: {
                NAME: "AvailableDuringTrial",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: "User"
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : (
            Belt_List.length(getItems$1(workspace, model)) === maxTrialEntities ? ({
                  hd: {
                    NAME: "LimitReached",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "User"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                }) : ({
                  hd: {
                    NAME: "LimitExceeded",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "User"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                })
          );
      } else {
        tmp = {
          hd: {
            NAME: "AvailableDuringTrial",
            VAL: [
              {
                NAME: "CreateEntityStatus",
                VAL: "User"
              },
              Workspace.Instances.nextHint(workspace.plan)
            ]
          },
          tl: /* [] */0
        };
      }
    } else {
      tmp = Belt_List.length(getItems$1(workspace, model)) === maxPlanEntities ? ({
            hd: {
              NAME: "LimitReached",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "User"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          }) : ({
            hd: {
              NAME: "LimitExceeded",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "User"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          });
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.concat(limits, tmp);
}

function createActionStatus$5(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "User";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

function entityStatus$5(workspaceLimits, entityId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var entity;
                    var id;
                    var variant = limit.NAME;
                    if (variant === "LimitReached") {
                      var match = limit.VAL[0];
                      if (typeof match !== "object") {
                        return false;
                      }
                      if (match.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$1 = match.VAL;
                      entity = match$1[0];
                      id = match$1[1];
                    } else if (variant === "AvailableDuringTrial") {
                      var match$2 = limit.VAL[0];
                      if (typeof match$2 !== "object") {
                        return false;
                      }
                      if (match$2.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$3 = match$2.VAL;
                      entity = match$3[0];
                      id = match$3[1];
                    } else {
                      var match$4 = limit.VAL[0];
                      if (typeof match$4 !== "object") {
                        return false;
                      }
                      if (match$4.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$5 = match$4.VAL;
                      entity = match$5[0];
                      id = match$5[1];
                    }
                    if (entityId === id) {
                      return entity === "User";
                    } else {
                      return false;
                    }
                  })), [
              "Available",
              undefined
            ], (function (limit) {
                var variant = limit.NAME;
                if (variant === "LimitReached") {
                  return [
                          "Available",
                          limit.VAL[1]
                        ];
                } else if (variant === "AvailableDuringTrial") {
                  return [
                          "AvailableDuringTrial",
                          limit.VAL[1]
                        ];
                } else {
                  return [
                          "Unavailable",
                          limit.VAL[1]
                        ];
                }
              }));
}

function status$5(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    if (typeof limit !== "object") {
                      return false;
                    }
                    var variant = limit.NAME;
                    if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                      return false;
                    }
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "User";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

var User = {
  constructLimitExceeded: constructLimitExceeded$5,
  computeEntityNumericLimits: computeEntityNumericLimits$5,
  createActionStatus: createActionStatus$5,
  entityStatus: entityStatus$5,
  status: status$5
};

function computeAvailability$11(workspace, step) {
  var match = workspace.plan.inspectorLookback;
  var match$1 = workspace.trial;
  if (Caml_obj.caml_greaterequal(Belt_Array.getIndexBy(InspectorViewModel.timeWindowLadder, (function (ladderStep) {
                return Caml_obj.caml_equal(ladderStep, match);
              })), Belt_Array.getIndexBy(InspectorViewModel.timeWindowLadder, (function (ladderStep) {
                return Caml_obj.caml_equal(ladderStep, step);
              })))) {
    return "Available";
  } else if (match$1 !== undefined && Caml_obj.caml_greaterequal(Belt_Array.getIndexBy(InspectorViewModel.timeWindowLadder, (function (ladderStep) {
                return Caml_obj.caml_equal(ladderStep, match$1.plan.inspectorLookback);
              })), Belt_Array.getIndexBy(InspectorViewModel.timeWindowLadder, (function (ladderStep) {
                return Caml_obj.caml_equal(ladderStep, step);
              })))) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$11(workspace, step) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (Caml_obj.caml_equal(plan.inspectorLookback, step) || plan.name === /* Enterprise */6) {
      return plan.name;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise.name;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

var InspectorLookback = {
  computeAvailability: computeAvailability$11,
  availableOnPlan: availableOnPlan$11
};

function constructLimitExceeded$6(trial, limit, planHint) {
  if (trial === undefined) {
    return {
            NAME: "LimitExceeded",
            VAL: [
              limit,
              planHint
            ]
          };
  } else {
    return {
            NAME: "AvailableDuringTrial",
            VAL: [
              limit,
              planHint
            ]
          };
  }
}

function computeEntityNumericLimits$6(workspace, model) {
  var match = workspace.plan.maxCodegenEvents;
  var limits;
  if (typeof match === "object" && match.NAME === "Limited") {
    var planEntityCountLimit = match.VAL;
    if (Belt_List.length(model.events) > planEntityCountLimit) {
      var entityCount = Belt_List.length(model.events);
      var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
      var trial = workspace.trial;
      var trialEntityCount;
      if (trial !== undefined) {
        var match$1 = trial.plan.maxCodegenEvents;
        trialEntityCount = typeof match$1 === "object" ? Caml.caml_int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
      } else {
        trialEntityCount = 0;
      }
      var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
      var limitToInt = function (limit) {
        if (typeof limit === "object") {
          return limit.VAL;
        } else {
          return Pervasives.max_int;
        }
      };
      var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(model.events, (function (entityIndex, entity) {
                  var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                          return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                        }));
                  var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                          var match = publicPlan.maxCodegenEvents;
                          if (typeof match === "object") {
                            return match.VAL > entityIndex;
                          } else {
                            return true;
                          }
                        }));
                  var currentPlanLimit = limitToInt(workspace.plan.maxCodegenEvents);
                  var trial = Belt_Option.map(workspace.trial, (function (trial) {
                          return trial.plan;
                        }));
                  var currentTrialLimit = trial !== undefined ? limitToInt(trial.maxCodegenEvents) : 0;
                  var exit = 0;
                  if (minimumRequiredPlan !== undefined) {
                    if (entityIndex === (currentPlanLimit - 1 | 0)) {
                      return {
                              hd: {
                                NAME: "LimitReached",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "CodegenEvent",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    exit = 1;
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    if (entityIndex < currentPlanLimit) {
                      return /* [] */0;
                    }
                    if (minimumRequiredPlan === undefined) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "CodegenEvent",
                                      entity.id
                                    ]
                                  },
                                  Workspace.Instances.enterprise
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    var exit$1 = 0;
                    if (trial !== undefined) {
                      if (entityIndex < currentTrialLimit) {
                        return {
                                hd: {
                                  NAME: "AvailableDuringTrial",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        "CodegenEvent",
                                        entity.id
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit$1 = 2;
                    } else {
                      exit$1 = 2;
                    }
                    if (exit$1 === 2) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "CodegenEvent",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    
                  }
                  
                })));
      var match$2 = trialEntityCount$1 > 0;
      var match$3 = workspace.trial;
      var exit = 0;
      if (match$2 && match$3 !== undefined) {
        var match$4 = match$3.plan.maxCodegenEvents;
        limits = typeof match$4 === "object" ? ({
              hd: constructLimitExceeded$6(workspace.trial, {
                    NAME: "NumericListLimit",
                    VAL: [
                      "CodegenEvent",
                      Belt_List.length(model.events),
                      match$4.VAL
                    ]
                  }, Workspace.Instances.nextHint(workspace.plan)),
              tl: limits$1
            }) : limits$1;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        limits = {
          hd: constructLimitExceeded$6(workspace.trial, {
                NAME: "NumericListLimit",
                VAL: [
                  "CodegenEvent",
                  Belt_List.length(model.events),
                  planEntityCountLimit
                ]
              }, Workspace.Instances.nextHint(workspace.plan)),
          tl: limits$1
        };
      }
      
    } else {
      limits = /* [] */0;
    }
  } else {
    limits = /* [] */0;
  }
  var match$5 = workspace.plan.maxCodegenEvents;
  var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
          return trial.plan.maxCodegenEvents;
        }));
  var tmp;
  if (typeof match$5 === "object") {
    var maxPlanEntities = match$5.VAL;
    if (Belt_List.length(model.events) < maxPlanEntities) {
      tmp = /* [] */0;
    } else if (match$6 !== undefined) {
      if (typeof match$6 === "object") {
        var maxTrialEntities = match$6.VAL;
        tmp = Belt_List.length(model.events) < maxTrialEntities ? ({
              hd: {
                NAME: "AvailableDuringTrial",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: "CodegenEvent"
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : (
            Belt_List.length(model.events) === maxTrialEntities ? ({
                  hd: {
                    NAME: "LimitReached",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "CodegenEvent"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                }) : ({
                  hd: {
                    NAME: "LimitExceeded",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "CodegenEvent"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                })
          );
      } else {
        tmp = {
          hd: {
            NAME: "AvailableDuringTrial",
            VAL: [
              {
                NAME: "CreateEntityStatus",
                VAL: "CodegenEvent"
              },
              Workspace.Instances.nextHint(workspace.plan)
            ]
          },
          tl: /* [] */0
        };
      }
    } else {
      tmp = Belt_List.length(model.events) === maxPlanEntities ? ({
            hd: {
              NAME: "LimitReached",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "CodegenEvent"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          }) : ({
            hd: {
              NAME: "LimitExceeded",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "CodegenEvent"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          });
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.concat(limits, tmp);
}

function createActionStatus$6(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "CodegenEvent";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

function entityStatus$6(workspaceLimits, entityId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var entity;
                    var id;
                    var variant = limit.NAME;
                    if (variant === "LimitReached") {
                      var match = limit.VAL[0];
                      if (typeof match !== "object") {
                        return false;
                      }
                      if (match.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$1 = match.VAL;
                      entity = match$1[0];
                      id = match$1[1];
                    } else if (variant === "AvailableDuringTrial") {
                      var match$2 = limit.VAL[0];
                      if (typeof match$2 !== "object") {
                        return false;
                      }
                      if (match$2.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$3 = match$2.VAL;
                      entity = match$3[0];
                      id = match$3[1];
                    } else {
                      var match$4 = limit.VAL[0];
                      if (typeof match$4 !== "object") {
                        return false;
                      }
                      if (match$4.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$5 = match$4.VAL;
                      entity = match$5[0];
                      id = match$5[1];
                    }
                    if (entityId === id) {
                      return entity === "CodegenEvent";
                    } else {
                      return false;
                    }
                  })), [
              "Available",
              undefined
            ], (function (limit) {
                var variant = limit.NAME;
                if (variant === "LimitReached") {
                  return [
                          "Available",
                          limit.VAL[1]
                        ];
                } else if (variant === "AvailableDuringTrial") {
                  return [
                          "AvailableDuringTrial",
                          limit.VAL[1]
                        ];
                } else {
                  return [
                          "Unavailable",
                          limit.VAL[1]
                        ];
                }
              }));
}

function status$6(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    if (typeof limit !== "object") {
                      return false;
                    }
                    var variant = limit.NAME;
                    if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                      return false;
                    }
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "CodegenEvent";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

var CodegenEvents = {
  constructLimitExceeded: constructLimitExceeded$6,
  computeEntityNumericLimits: computeEntityNumericLimits$6,
  createActionStatus: createActionStatus$6,
  entityStatus: entityStatus$6,
  status: status$6
};

function computeAvailability$12(workspace) {
  var match = workspace.plan.triggers;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.triggers === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$12(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.triggers === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$11(workspace) {
  return computeAvailability$12(workspace) !== "Unavailable";
}

var Triggers = {
  computeAvailability: computeAvailability$12,
  availableOnPlan: availableOnPlan$12,
  isAvailable: isAvailable$11
};

function computeAvailability$13(workspace) {
  var match = workspace.plan.discrepancyConfig;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.discrepancyConfig === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$13(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.discrepancyConfig === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$12(workspace) {
  return computeAvailability$13(workspace) !== "Unavailable";
}

var DiscrepancyConfig = {
  computeAvailability: computeAvailability$13,
  availableOnPlan: availableOnPlan$13,
  isAvailable: isAvailable$12
};

function computeAvailability$14(workspace) {
  var match = workspace.plan.forcePreferredCasing;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.forcePreferredCasing === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$14(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.forcePreferredCasing === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$13(workspace) {
  return computeAvailability$14(workspace) !== "Unavailable";
}

var ForcePreferredCasing = {
  computeAvailability: computeAvailability$14,
  availableOnPlan: availableOnPlan$14,
  isAvailable: isAvailable$13
};

function computeLimits(workspace, model) {
  return Belt_List.concatMany([
              computeEntityNumericLimits$2(workspace, model),
              computeEntityNumericLimits$3(workspace, model),
              computeEntityNumericLimits$4(workspace, model),
              computeEntityNumericLimits$5(workspace, model),
              computeEntityNumericLimits$6(workspace, model)
            ]);
}

function entityStatus$7(limits, entityId, entityType) {
  if (entityType === "Source") {
    return entityStatus$2(limits, entityId);
  } else if (entityType === "Destination") {
    return entityStatus$3(limits, entityId);
  } else if (entityType === "Integration") {
    return entityStatus$4(limits, entityId);
  } else {
    return entityStatus$5(limits, entityId);
  }
}

function hoursLeftOfTrial(workspace) {
  return Belt_Option.mapWithDefault(workspace.trial, 0, (function (trial) {
                return Difference_in_hours(trial.endAt, new Date()) | 0;
              }));
}

function computeAvailability$15(workspace) {
  var match = workspace.plan.pinnedProperties;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.pinnedProperties === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$15(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.pinnedProperties === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$14(workspace) {
  return computeAvailability$15(workspace) !== "Unavailable";
}

var PinnedProperties = {
  computeAvailability: computeAvailability$15,
  availableOnPlan: availableOnPlan$15,
  isAvailable: isAvailable$14
};

function computeAvailability$16(workspace) {
  var match = workspace.plan.nameMapping;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.nameMapping === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$16(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.nameMapping === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$15(workspace) {
  return computeAvailability$16(workspace) !== "Unavailable";
}

var NameMapping = {
  computeAvailability: computeAvailability$16,
  availableOnPlan: availableOnPlan$16,
  isAvailable: isAvailable$15
};

function computeAvailability$17(workspace) {
  var match = workspace.plan.editors;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.editors === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$17(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.editors === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$16(workspace) {
  return computeAvailability$17(workspace) !== "Unavailable";
}

var Editors = {
  computeAvailability: computeAvailability$17,
  availableOnPlan: availableOnPlan$17,
  isAvailable: isAvailable$16
};

function computeAvailability$18(workspace) {
  var match = workspace.plan.canDuplicateEvents;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.canDuplicateEvents === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$18(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.canDuplicateEvents === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$17(workspace) {
  return computeAvailability$18(workspace) !== "Unavailable";
}

var DuplicateEvents = {
  computeAvailability: computeAvailability$18,
  availableOnPlan: availableOnPlan$18,
  isAvailable: isAvailable$17
};

function computeAvailability$19(workspace) {
  var match = workspace.plan.expandedBranchAudit;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.expandedBranchAudit === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$19(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.expandedBranchAudit === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$18(workspace) {
  return computeAvailability$19(workspace) !== "Unavailable";
}

var ExpandedBranchAudit = {
  computeAvailability: computeAvailability$19,
  availableOnPlan: availableOnPlan$19,
  isAvailable: isAvailable$18
};

function computeAvailability$20(workspace) {
  var match = workspace.plan.closedBranches;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.closedBranches === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$20(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.closedBranches === true || plan.name === /* Enterprise */6) {
      return plan;
    }
    if (Caml_obj.caml_equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$19(workspace) {
  return computeAvailability$20(workspace) !== "Unavailable";
}

var ClosedBranches = {
  computeAvailability: computeAvailability$20,
  availableOnPlan: availableOnPlan$20,
  isAvailable: isAvailable$19
};

exports.BooleanLimits = BooleanLimits;
exports.LadderLimits = LadderLimits;
exports.NumericLimits = NumericLimits;
exports.Collaboration = Collaboration;
exports.Commenters = Commenters;
exports.ConfigEventSourcesAndDestinations = ConfigEventSourcesAndDestinations;
exports.Sso = Sso;
exports.CanProtectMainBranch = CanProtectMainBranch;
exports.AdvancedProtectedMainBranch = AdvancedProtectedMainBranch;
exports.ForceBranchAudit = ForceBranchAudit;
exports.ApprovalWorkflow = ApprovalWorkflow;
exports.PropertyAbsenceByEventAndSource = PropertyAbsenceByEventAndSource;
exports.PropertyMatchesBySource = PropertyMatchesBySource;
exports.ActivityLogs = ActivityLogs;
exports.AuditLog = AuditLog;
exports.ServiceAccount = ServiceAccount;
exports.Source = Source;
exports.Destination = Destination;
exports.Integration = Integration;
exports.User = User;
exports.InspectorLookback = InspectorLookback;
exports.CodegenEvents = CodegenEvents;
exports.Triggers = Triggers;
exports.DiscrepancyConfig = DiscrepancyConfig;
exports.ForcePreferredCasing = ForcePreferredCasing;
exports.computeLimits = computeLimits;
exports.entityStatus = entityStatus$7;
exports.hoursLeftOfTrial = hoursLeftOfTrial;
exports.PinnedProperties = PinnedProperties;
exports.NameMapping = NameMapping;
exports.Editors = Editors;
exports.DuplicateEvents = DuplicateEvents;
exports.ExpandedBranchAudit = ExpandedBranchAudit;
exports.ClosedBranches = ClosedBranches;
/* Models Not a pure module */
