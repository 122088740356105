// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var SsoUtils = require("./SsoUtils.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Workspace = require("../../model/src/Workspace.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

var workspaceContext = React.createContext(Workspace.empty);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = workspaceContext.Provider;

var Provider = {
  makeProps: makeProps,
  make: make
};

function WorkspaceContext(Props) {
  var schema = Props.schema;
  var children = Props.children;
  var trial = FirebaseFetcherHooks.useTrial(schema.id);
  var members = FirebaseFetcherHooks.useMembers(schema.id);
  var serviceAccounts = FirebaseFetcherHooks.useServiceAccounts(schema.id);
  var ssoConfig = FirebaseFetcherHooks.useCurrentSsoConfig(schema.id);
  var authMethod = typeof ssoConfig === "object" ? SsoUtils.ssoModeToAuthMethod(ssoConfig.VAL) : "Default";
  var workspace = Workspace.fromSchema(schema, trial, members, authMethod, serviceAccounts);
  return React.createElement(make, makeProps(workspace, children, undefined));
}

function use(param) {
  return React.useContext(workspaceContext);
}

function useMember(userId) {
  return Belt_List.getBy(React.useContext(workspaceContext).members, (function (member) {
                return member.id === userId;
              }));
}

function useLastSeenCodegenForSourceIds(param) {
  var match = React.useContext(workspaceContext);
  var lastSeenCodegen = match.lastSeenCodegen;
  if (typeof lastSeenCodegen === "object") {
    return Belt_MapString.fromArray(lastSeenCodegen.VAL);
  }
  
}

var make$1 = WorkspaceContext;

exports.workspaceContext = workspaceContext;
exports.Provider = Provider;
exports.make = make$1;
exports.use = use;
exports.useMember = useMember;
exports.useLastSeenCodegenForSourceIds = useLastSeenCodegenForSourceIds;
/* workspaceContext Not a pure module */
