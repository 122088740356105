// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var VersionJson = require("../version.json");

var current = VersionJson;

var current$1 = Json_decode.field("version", Json_decode.string, current);

exports.current = current$1;
/* current Not a pure module */
