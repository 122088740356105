// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var FramerMotion = require("framer-motion");

var defaultStyles = Curry._1(Css.style, {
      hd: Css.boxShadow(Styles.Shadow.subtle),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: /* [] */0
      }
    });

function PortalMenuAnimator(Props) {
  var key_ = Props.key_;
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : defaultStyles;
  return React.createElement(FramerMotion.motion.div, {
              animate: "open",
              transition: {
                duration: 0.1
              },
              initial: "closed",
              exit: "closed",
              variants: {
                open: {
                  opacity: 1
                },
                closed: {
                  opacity: 0
                }
              },
              className: className,
              key: key_,
              children: children
            });
}

var make = PortalMenuAnimator;

exports.defaultStyles = defaultStyles;
exports.make = make;
/* defaultStyles Not a pure module */
