// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var ReactMarkdown = require("react-markdown");

function Markdown(Props) {
  var source = Props.source;
  var stylesOpt = Props.styles;
  var styles = stylesOpt !== undefined ? stylesOpt : /* [] */0;
  return React.createElement("div", {
              className: Curry._1(Css.style, Belt_List.concat({
                        hd: Css.selector(" .MarkdownBody pre", {
                              hd: Css.backgroundColor(Styles.Color.grey20),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                tl: {
                                  hd: Css.padding2(Css.px(10), Css.px(15)),
                                  tl: {
                                    hd: Css.borderRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.whiteSpace("preWrap"),
                                      tl: {
                                        hd: Css.wordBreak("breakAll"),
                                        tl: {
                                          hd: Css.margin2(Css.px(8), Css.px(0)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }),
                        tl: {
                          hd: Css.selector(" .MarkdownBody code", {
                                hd: Css.fontFamily(Styles.monoFontFamily),
                                tl: {
                                  hd: Css.fontSize(Css.pct(85.0)),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.grey70),
                                    tl: {
                                      hd: Css.color(Styles.Color.grey10),
                                      tl: {
                                        hd: Css.padding2(Css.px(0), Css.px(4)),
                                        tl: {
                                          hd: Css.borderRadius(Styles.Border.radius),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: {
                            hd: Css.selector(" .MarkdownBody pre code", {
                                  hd: Css.padding(Css.px(0)),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.selector(" .MarkdownBody p", {
                                    hd: Css.margin4(Css.px(10), Css.px(0), Css.px(4), Css.px(0)),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.selector(" .MarkdownBody h1, .MarkdownBody h2, .MarkdownBody h3, .MarkdownBody h4, .MarkdownBody h5, .MarkdownBody h6", {
                                      hd: Css.width(Css.pct(100)),
                                      tl: {
                                        hd: Css.paddingTop(Css.px(8)),
                                        tl: {
                                          hd: Css.margin4(Css.px(20), Css.px(0), Css.px(5), Css.px(0)),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }),
                                tl: {
                                  hd: Css.selector(" .MarkdownBody ul", {
                                        hd: Css.paddingLeft(Css.px(24)),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.selector(" .MarkdownBody li", {
                                          hd: Css.padding2(Css.px(0), Css.px(6)),
                                          tl: {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: {
                                      hd: Css.selector(" .MarkdownBody blockquote", {
                                            hd: Css.unsafe("marginInlineStart", "16px"),
                                            tl: {
                                              hd: Css.position("relative"),
                                              tl: {
                                                hd: Css.after({
                                                      hd: Css.unsafe("content", "' '"),
                                                      tl: {
                                                        hd: Css.position("absolute"),
                                                        tl: {
                                                          hd: Css.width(Css.px(4)),
                                                          tl: {
                                                            hd: Css.height(Css.pct(100.0)),
                                                            tl: {
                                                              hd: Css.left(Css.px(-18)),
                                                              tl: {
                                                                hd: Css.top("zero"),
                                                                tl: {
                                                                  hd: Css.backgroundColor(Styles.Color.grey60),
                                                                  tl: {
                                                                    hd: Css.borderRadius(Css.px(2)),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }, styles))
            }, React.createElement(ReactMarkdown, {
                  className: "MarkdownBody",
                  children: source
                }));
}

var make = Markdown;

exports.make = make;
/* Css Not a pure module */
