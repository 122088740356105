// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var CopyButton = require("../CopyButton.bs.js");

function code(packageNameOpt, docsCodeBoxStyles, docsCodeStyles, docsCodeLightBlueStyles, docsCodeMintGreenStyles, docsCodeOrangeStyles, copyButtonBoxStyles, apiKey, language) {
  var packageName = packageNameOpt !== undefined ? packageNameOpt : "avo-inspector";
  if (language !== undefined) {
    if (language === "ts" || language === "js" || language === "js_v2") {
      return React.createElement("div", {
                  className: docsCodeBoxStyles
                }, React.createElement("pre", {
                      className: docsCodeStyles
                    }, "import ", React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, "*"), " as ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "Inspector"), " from ", React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, "\"" + packageName + "\""), ";\n", "let ", React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, "inspector"), " = new ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "Inspector.AvoInspector"), "({\n    apiKey: ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"" + apiKey + "\""), ",\n    env: ", React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, "Inspector.AvoInspectorEnv.Dev"), ",\n    version: ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"1.0.0\""), ",\n    appName: ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"My App\""), "\n});"), React.createElement("div", {
                      className: copyButtonBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: "import * as Inspector from \"" + packageName + "\";\nlet inspector = new Inspector.AvoInspector({\n     apiKey: \"" + apiKey + "\", env: \"dev\", version: \"1.0.0\", appName: \"My App\"\n});"
                        })));
    } else if (language === "re" || language === "re_v2") {
      return React.createElement("div", {
                  className: docsCodeBoxStyles
                }, React.createElement("pre", {
                      className: docsCodeStyles
                    }, React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, "AvoInspector"), ".make(~apiKey=", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"" + apiKey + "\""), ", ~env=", React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, "`Dev"), ", ~version=", React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, "Version.current"), ", ())"), React.createElement("div", {
                      className: copyButtonBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: "AvoInspector.make(~apiKey=\"" + apiKey + "\", ~env=`Dev, ~version=Version.current, ())"
                        })));
    } else {
      return null;
    }
  } else {
    return null;
  }
}

exports.code = code;
/* react Not a pure module */
