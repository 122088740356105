// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconEmail(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 138;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey70;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("svg", {
                  width: String(size),
                  viewBox: "0 0 48 48",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("g", undefined, React.createElement("path", {
                          d: "M24,37c-0.187,0-0.373-0.052-0.537-0.156l-22-14c-0.304-0.193-0.48-0.536-0.462-0.896 c0.019-0.359,0.229-0.682,0.552-0.843L24,7l22.447,14.105c0.322,0.161,0.533,0.483,0.552,0.843c0.019,0.36-0.158,0.702-0.462,0.896 l-22,14C24.373,36.948,24.187,37,24,37z",
                          fill: Styles.Color.toString(Styles.Color.magentaSecondary),
                          stroke: Styles.Color.toString(Styles.Color.magentaSecondary)
                        }), React.createElement("path", {
                          d: "M39,38H9V2c0-0.552,0.448-1,1-1h28c0.552,0,1,0.448,1,1V38z",
                          fill: Styles.Color.toString(Styles.Color.grey20),
                          stroke: Styles.Color.toString(Styles.Color.grey40)
                        }), React.createElement("path", {
                          d: "M46,47c-0.084,0-0.169-0.011-0.252-0.032l-23-6L23,34l24-12v24c0,0.31-0.144,0.602-0.389,0.792 C46.435,46.928,46.219,47,46,47z",
                          fill: Styles.Color.toString(Styles.Color.magenta),
                          stroke: Styles.Color.toString(Styles.Color.magentaSecondary)
                        }), React.createElement("path", {
                          d: "M46,47H2c-0.553,0-1-0.448-1-1V22l45.479,23.122c0.401,0.219,0.604,0.682,0.49,1.125 C46.856,46.69,46.457,47,46,47z",
                          fill: Styles.Color.toString(Styles.Color.magenta),
                          stroke: Styles.Color.toString(Styles.Color.magentaSecondary)
                        }), React.createElement("path", {
                          d: "M32,10H16c-0.552,0-1-0.447-1-1s0.448-1,1-1h16c0.552,0,1,0.447,1,1S32.552,10,32,10z",
                          fill: Styles.Color.toString(Styles.Color.grey30),
                          stroke: Styles.Color.toString(Styles.Color.grey40)
                        }), React.createElement("path", {
                          d: "M32,17H16c-0.552,0-1-0.447-1-1s0.448-1,1-1h16c0.552,0,1,0.447,1,1S32.552,17,32,17z",
                          fill: Styles.Color.toString(Styles.Color.grey30),
                          stroke: Styles.Color.toString(Styles.Color.grey40)
                        }), React.createElement("path", {
                          d: "M25,24h-9c-0.552,0-1-0.447-1-1s0.448-1,1-1h9c0.552,0,1,0.447,1,1S25.552,24,25,24z",
                          fill: Styles.Color.toString(Styles.Color.grey30),
                          stroke: Styles.Color.toString(Styles.Color.grey40)
                        }))));
}

var make = IconEmail;

exports.make = make;
/* Css Not a pure module */
