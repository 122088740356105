// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Format = require("rescript/lib/js/format.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var AvoModel = require("../../app/src/avoModel.bs.js");
var Printing = require("./Printing.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("../../app/src/stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var AvoConfigTypes = require("../../shared/models/AvoConfigTypes.bs.js");
var CodegenCommons = require("./CodegenCommons.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var BeltListExtensions = require("../../app/src/BeltListExtensions.bs.js");
var Belt_MutableMapString = require("rescript/lib/js/belt_MutableMapString.js");
var JsonSchemaPropertiesPrinter = require("./JsonSchemaPropertiesPrinter.bs.js");

function printProperties(model, eventProperties, systemProperties, $$event, includedSources) {
  var includedSources$1 = Belt_List.keep(includedSources, (function (includedSource) {
          return Belt_List.someU($$event.includeSources, (function (includedEventSource) {
                        return includedEventSource.id === includedSource;
                      }));
        }));
  var allProperties = Belt_List.concat(eventProperties, systemProperties);
  return Json_encode.object_({
              hd: [
                "type",
                "object"
              ],
              tl: {
                hd: [
                  "properties",
                  Json_encode.object_(Belt_List.concat(Belt_List.mapWithIndex(eventProperties, (function (index, property) {
                                  return [
                                          property.name,
                                          JsonSchemaPropertiesPrinter.printProperty(model, /* AvoJsonSchema */2, false, $$event.id, undefined, StateUtils.getPropertyRefById($$event.id, property.id, model), property, StateUtils.isPropertyOptionalOnEventsAndSources({
                                                    hd: $$event.id,
                                                    tl: /* [] */0
                                                  }, includedSources$1, property, model), index, undefined)
                                        ];
                                })), Belt_List.mapWithIndex(systemProperties, (function (index, property) {
                                  return [
                                          property.name,
                                          JsonSchemaPropertiesPrinter.printProperty(model, /* AvoJsonSchema */2, false, $$event.id, undefined, StateUtils.getPropertyRefById($$event.id, property.id, model), property, StateUtils.isPropertyOptionalOnEventsAndSources({
                                                    hd: $$event.id,
                                                    tl: /* [] */0
                                                  }, includedSources$1, property, model), Belt_List.length(eventProperties) + index | 0, undefined)
                                        ];
                                }))))
                ],
                tl: {
                  hd: [
                    "additionalProperties",
                    false
                  ],
                  tl: {
                    hd: [
                      "required",
                      Belt_List.toArray(Belt_List.keepMap(allProperties, (function (property) {
                                  if (!StateUtils.isPropertyOptionalOnEventsAndSources({
                                          hd: $$event.id,
                                          tl: /* [] */0
                                        }, includedSources$1, property, model)) {
                                    return Caml_option.some(property.name);
                                  }
                                  
                                })))
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function printEvent(model, systemProperties, $$event, filterIncludedSources) {
  var inputProperties = AvoModel.resolveProperties(model, $$event.properties);
  var match = CodegenCommons.classifyProperties(inputProperties);
  var match$1 = Belt_List.partition(match[0], (function (property) {
          return property.builtIn === "NotBuiltIn";
        }));
  var eventProperties = match$1[0];
  var allProperties = Belt_List.concat(eventProperties, systemProperties);
  var hasAnyProperties = Belt_List.length(allProperties) > 0;
  var eventNameMappings = Belt_List.keepMapU(model.rules, (function (rule) {
          var match = rule.item;
          var match$1 = rule.definition;
          if (typeof match !== "object") {
            return ;
          }
          if (match.NAME !== "Event") {
            return ;
          }
          if (typeof match$1 !== "object") {
            return ;
          }
          if (match$1.NAME !== "NameMapping") {
            return ;
          }
          if (match.VAL !== $$event.id) {
            return ;
          }
          var match$2 = match$1.VAL;
          var name = match$2.name;
          var destination = match$2.destination;
          if (destination === undefined) {
            return ;
          }
          if (typeof destination !== "object") {
            return [
                    Belt_Option.getWithDefault(name, $$event.name),
                    "all"
                  ];
          }
          var id = destination.VAL;
          if (JsonSchemaPropertiesPrinter.matchesAtLeastOneDestination(model, id)) {
            return [
                    Belt_Option.getWithDefault(name, $$event.name),
                    id
                  ];
          }
          
        }));
  return Json_encode.object_({
              hd: [
                "$schema",
                "http://json-schema.org/draft-06/schema#"
              ],
              tl: {
                hd: [
                  "type",
                  "object"
                ],
                tl: {
                  hd: [
                    "required",
                    [
                      "properties",
                      "nameMapping"
                    ]
                  ],
                  tl: {
                    hd: [
                      "nameMapping",
                      Belt_List.toArray(Belt_List.mapU(eventNameMappings, (function (param) {
                                  return Json_encode.object_({
                                              hd: [
                                                "name",
                                                param[0]
                                              ],
                                              tl: {
                                                hd: [
                                                  "destinationId",
                                                  param[1]
                                                ],
                                                tl: /* [] */0
                                              }
                                            });
                                })))
                    ],
                    tl: {
                      hd: [
                        "properties",
                        Json_encode.object_(hasAnyProperties ? ({
                                  hd: [
                                    "properties",
                                    printProperties(model, eventProperties, systemProperties, $$event, filterIncludedSources)
                                  ],
                                  tl: /* [] */0
                                }) : /* [] */0)
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function printMetricWhere(metricWhere) {
  return Json_encode.object_({
              hd: [
                "propertyId",
                metricWhere.propertyId
              ],
              tl: {
                hd: [
                  "operation",
                  TrackingPlanModel.encodeBinOp(metricWhere.binOp)
                ],
                tl: {
                  hd: [
                    "values",
                    Json_encode.array(JsonSchemaPropertiesPrinter.printLiteral, Belt_List.toArray(metricWhere.literals))
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function printMetricGroupBy(metricGroupBy) {
  return Json_encode.object_({
              hd: [
                "propertyId",
                metricGroupBy.propertyId
              ],
              tl: /* [] */0
            });
}

function printMetricEvent(item) {
  var metricEvent = item.VAL;
  return Json_encode.object_({
              hd: [
                "eventId",
                metricEvent.eventId
              ],
              tl: {
                hd: [
                  "where",
                  Json_encode.array(printMetricWhere, Belt_List.toArray(metricEvent.where))
                ],
                tl: {
                  hd: [
                    "groupBy",
                    Json_encode.array(printMetricGroupBy, Belt_List.toArray(metricEvent.groupBy))
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function printMetric(metric, metricGoals) {
  return Json_encode.object_({
              hd: [
                "id",
                metric.id
              ],
              tl: {
                hd: [
                  "name",
                  metric.name
                ],
                tl: {
                  hd: [
                    "description",
                    metric.description
                  ],
                  tl: {
                    hd: [
                      "categories",
                      Json_encode.array((function (prim) {
                              return prim;
                            }), Belt_MutableMapString.getWithDefault(metricGoals, metric.id, []))
                    ],
                    tl: {
                      hd: [
                        "type",
                        TrackingPlanModel.metricTypeToJs(metric.type_)
                      ],
                      tl: {
                        hd: [
                          "items",
                          Json_encode.array(printMetricEvent, Belt_List.toArray(metric.items))
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function printSource(model, source) {
  return Json_encode.object_({
              hd: [
                "id",
                source.id
              ],
              tl: {
                hd: [
                  "name",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), source.name)
                ],
                tl: {
                  hd: [
                    "programmingLanguage",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), Belt_Option.map(source.language, AvoConfigTypes.languageToJs))
                  ],
                  tl: {
                    hd: [
                      "developmentPlatform",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), Belt_Option.map(source.platform, AvoConfigTypes.devPlatformToJs))
                    ],
                    tl: {
                      hd: [
                        "destinations",
                        Json_encode.array((function (prim) {
                                return prim;
                              }), Belt_List.toArray(StateUtils.destinationIdsWithoutArchived(StateUtils.getSourceDestinationIds(source.destinations), model)))
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function printDestination(destination) {
  return Json_encode.object_({
              hd: [
                "id",
                destination.id
              ],
              tl: {
                hd: [
                  "name",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), destination.name)
                ],
                tl: {
                  hd: [
                    "type",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), Belt_Option.map(destination.type_, AvoConfigTypes.analyticsToolToJs))
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function printGoal(model, goal) {
  return Json_encode.object_({
              hd: [
                "id",
                goal.id
              ],
              tl: {
                hd: [
                  "name",
                  goal.name
                ],
                tl: {
                  hd: [
                    "description",
                    goal.description
                  ],
                  tl: {
                    hd: [
                      "metrics",
                      Json_encode.array((function (prim) {
                              return prim;
                            }), Belt_List.toArray(StateUtils.metricIdsWithoutArchived(goal.metrics, model)))
                    ],
                    tl: {
                      hd: [
                        "events",
                        Json_encode.array((function (prim) {
                                return prim;
                              }), Belt_List.toArray(StateUtils.eventIdsWithoutArchived(goal.events, model)))
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function getCategoriesForMetricsAndEvents(goals) {
  var eventGoals = Belt_MutableMapString.make(undefined);
  var metricGoals = Belt_MutableMapString.make(undefined);
  Belt_List.forEach(goals, (function (goal) {
          Belt_List.forEach(goal.events, (function (eventId) {
                  var a = Belt_Option.getWithDefault(Belt_Option.map(Belt_MutableMapString.get(eventGoals, eventId), (function (arr) {
                              return Belt_Array.concat(arr, [goal.id]);
                            })), [goal.id]);
                  return Belt_MutableMapString.set(eventGoals, eventId, a);
                }));
          Belt_List.forEach(goal.metrics, (function (metricId) {
                  var a = Belt_Option.getWithDefault(Belt_Option.map(Belt_MutableMapString.get(metricGoals, metricId), (function (arr) {
                              return Belt_Array.concat(arr, [goal.id]);
                            })), [goal.id]);
                  return Belt_MutableMapString.set(metricGoals, metricId, a);
                }));
          return goal;
        }));
  return [
          eventGoals,
          metricGoals
        ];
}

function encodeEventSourceDestination(destinationId) {
  return Json_encode.object_({
              hd: [
                "id",
                destinationId
              ],
              tl: /* [] */0
            });
}

function encodeEventSource($$event, param) {
  var source = param[1];
  return Json_encode.object_({
              hd: [
                "id",
                source.id
              ],
              tl: {
                hd: [
                  "name",
                  AvoConfig.getSourceName(source)
                ],
                tl: {
                  hd: [
                    "implementWithCodegen",
                    param[0].includeInCodegen
                  ],
                  tl: {
                    hd: [
                      "destinations",
                      Json_encode.array(encodeEventSourceDestination, Belt_List.toArray(Belt_List.map(BeltListExtensions.dedupeOrdered(Belt_List.flatten(Belt_List.mapU($$event.includeDestinations, (function (destinationConfig) {
                                                  var match = destinationConfig.VAL;
                                                  if (match[0] === source.id) {
                                                    return match[1];
                                                  } else {
                                                    return /* [] */0;
                                                  }
                                                }))), (function (id) {
                                          return id;
                                        })), (function (destinationId) {
                                      return destinationId;
                                    }))))
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function printSchema(schemaName, model, systemProperties, filterIncludedSources, schemaId, branchId, additionalContext) {
  var match = getCategoriesForMetricsAndEvents(model.goals);
  var metricGoals = match[1];
  var eventGoals = match[0];
  return Json_encode.object_(Belt_List.concat({
                  hd: [
                    "name",
                    schemaName
                  ],
                  tl: {
                    hd: [
                      "events",
                      Belt_List.toArray(Belt_List.map(model.events, (function ($$event) {
                                  var triggersJson = CodegenCommons.eventTriggersForSourcesJson(model, $$event.id, filterIncludedSources, schemaId, branchId);
                                  var includedSources = BeltListExtensions.dedupeOrdered(Belt_List.keepMap($$event.includeSources, (function (sourceRef) {
                                              return Belt_Option.map(StateUtils.getSourceById(sourceRef.id, model), (function (source) {
                                                            return [
                                                                    sourceRef,
                                                                    source
                                                                  ];
                                                          }));
                                            })), (function (param) {
                                          return param[0].id;
                                        }));
                                  return Json_encode.object_({
                                              hd: [
                                                "id",
                                                $$event.id
                                              ],
                                              tl: {
                                                hd: [
                                                  "name",
                                                  $$event.name
                                                ],
                                                tl: {
                                                  hd: [
                                                    "description",
                                                    $$event.description
                                                  ],
                                                  tl: {
                                                    hd: [
                                                      "triggers",
                                                      triggersJson
                                                    ],
                                                    tl: {
                                                      hd: [
                                                        "tags",
                                                        Json_encode.array((function (prim) {
                                                                return prim;
                                                              }), Belt_List.toArray($$event.tags))
                                                      ],
                                                      tl: {
                                                        hd: [
                                                          "categories",
                                                          Json_encode.array((function (prim) {
                                                                  return prim;
                                                                }), Belt_MutableMapString.getWithDefault(eventGoals, $$event.id, []))
                                                        ],
                                                        tl: {
                                                          hd: [
                                                            "sources",
                                                            Json_encode.array((function (param) {
                                                                    return encodeEventSource($$event, param);
                                                                  }), Belt_List.toArray(Belt_List.map(includedSources, (function (param) {
                                                                            return [
                                                                                    param[0],
                                                                                    param[1]
                                                                                  ];
                                                                          }))))
                                                          ],
                                                          tl: {
                                                            hd: [
                                                              "rules",
                                                              printEvent(model, systemProperties, $$event, filterIncludedSources)
                                                            ],
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            });
                                })))
                    ],
                    tl: {
                      hd: [
                        "metrics",
                        Json_encode.dict((function (__x) {
                                return printMetric(__x, metricGoals);
                              }), Js_dict.fromList(Belt_List.map(model.metrics, (function (metric) {
                                        return [
                                                metric.id,
                                                metric
                                              ];
                                      }))))
                      ],
                      tl: {
                        hd: [
                          "sources",
                          Json_encode.dict((function (param) {
                                  return printSource(model, param);
                                }), Js_dict.fromList(Belt_List.map(model.sources, (function (source) {
                                          return [
                                                  source.id,
                                                  source
                                                ];
                                        }))))
                        ],
                        tl: {
                          hd: [
                            "destinations",
                            Json_encode.dict(printDestination, Js_dict.fromList(Belt_List.map(model.destinations, (function (destination) {
                                            return [
                                                    destination.id,
                                                    destination
                                                  ];
                                          }))))
                          ],
                          tl: {
                            hd: [
                              "categories",
                              Json_encode.dict((function (param) {
                                      return printGoal(model, param);
                                    }), Js_dict.fromList(Belt_List.map(model.goals, (function (goal) {
                                              return [
                                                      goal.id,
                                                      goal
                                                    ];
                                            }))))
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }, Belt_Option.mapWithDefault(additionalContext, /* [] */0, (function (context) {
                        return {
                                hd: [
                                  "context",
                                  context
                                ],
                                tl: /* [] */0
                              };
                      }))));
}

function generateLib(schemaName, model, includedSources, schemaId, branchId, additionalContext) {
  var match = Printing.str_formatter(undefined);
  var ppf = match[1];
  Format.fprintf(ppf, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v>",
                _1: /* End_of_format */0
              },
              _1: "<v>"
            }
          },
          _1: /* End_of_format */0
        },
        _1: "@[<v>"
      });
  var allSystemProperties = AvoModel.getSystemProperties(model.properties);
  var match$1 = Belt_List.partition(allSystemProperties, (function (property) {
          return property.auto;
        }));
  Curry._1(Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            },
            _1: "%s"
          }), JSON.stringify(printSchema(schemaName, model, match$1[1], includedSources, schemaId, branchId, additionalContext), null, 2));
  Format.fprintf(ppf, /* Format */{
        _0: {
          TAG: /* Formatting_lit */17,
          _0: /* Close_box */0,
          _1: /* End_of_format */0
        },
        _1: "@]"
      });
  return Printing.flush_str_formatter(match[0], ppf);
}

function generateLibForCodegen(schemaName, source, model) {
  var model$1 = AvoModel.enrichPropertyBundlesForCodegen(AvoModel.enrichModelForCodegen(undefined, undefined, undefined, AvoModel.enrichModel(AvoModel.filterUnusedPropertyBundles(AvoModel.filterExcludedEventsFromModel(model, source)), {
                hd: source,
                tl: /* [] */0
              }), source), undefined, {
        hd: source.id,
        tl: /* [] */0
      });
  var partial_arg_0 = source.id;
  var partial_arg = {
    hd: partial_arg_0,
    tl: /* [] */0
  };
  var func = function (param, param$1, param$2) {
    return generateLib(schemaName, model$1, partial_arg, param, param$1, param$2);
  };
  return function (param) {
    var func$1 = Curry._1(func, param);
    return function (param) {
      return Curry._2(func$1, param, undefined);
    };
  };
}

function generateJsonSchema(schemaName, sources, context, filteredSourcesOpt, forceIncludeAllPropertyBundlesOpt, model) {
  var filteredSources = filteredSourcesOpt !== undefined ? filteredSourcesOpt : /* [] */0;
  var forceIncludeAllPropertyBundles = forceIncludeAllPropertyBundlesOpt !== undefined ? forceIncludeAllPropertyBundlesOpt : false;
  var model$1 = AvoModel.filterUnusedPropertyBundles(model);
  var model$2 = AvoModel.enrichModel(model$1, sources);
  var includedSources = filteredSources !== /* [] */0 ? filteredSources : Belt_List.map(sources, (function (source) {
            return source.id;
          }));
  var model$3 = AvoModel.enrichPropertyBundlesForCodegen(model$2, forceIncludeAllPropertyBundles, includedSources);
  return function (param) {
    return function (param$1) {
      return generateLib(schemaName, model$3, includedSources, param, param$1, context);
    };
  };
}

function generateLibForWebhook(schemaName, sources, filters, context, model) {
  var model$1 = filters ? ({
        types: model.types,
        properties: model.properties,
        propertyBundles: model.propertyBundles,
        events: AvoModel.filterExcludedEvents(model.events, filters),
        migrations: model.migrations,
        sources: model.sources,
        destinations: model.destinations,
        groupTypes: model.groupTypes,
        goals: model.goals,
        metrics: model.metrics,
        archive: model.archive,
        openBranches: model.openBranches,
        branchPointer: model.branchPointer,
        rules: model.rules,
        integrations: model.integrations
      }) : AvoModel.filterExcludedEventsBySourcesFromModel(model, sources);
  var filteredSources = Belt_List.keepMap(filters, (function (filter) {
          if (typeof filter === "object" && filter.NAME === "Source") {
            return filter.VAL;
          }
          
        }));
  return generateJsonSchema(schemaName, sources, context, filteredSources, undefined, model$1);
}

exports.printProperties = printProperties;
exports.printEvent = printEvent;
exports.printMetricWhere = printMetricWhere;
exports.printMetricGroupBy = printMetricGroupBy;
exports.printMetricEvent = printMetricEvent;
exports.printMetric = printMetric;
exports.printSource = printSource;
exports.printDestination = printDestination;
exports.printGoal = printGoal;
exports.getCategoriesForMetricsAndEvents = getCategoriesForMetricsAndEvents;
exports.encodeEventSourceDestination = encodeEventSourceDestination;
exports.encodeEventSource = encodeEventSource;
exports.printSchema = printSchema;
exports.generateLib = generateLib;
exports.generateLibForCodegen = generateLibForCodegen;
exports.generateJsonSchema = generateJsonSchema;
exports.generateLibForWebhook = generateLibForWebhook;
/* Format Not a pure module */
