// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("../Link.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("../Icons.bs.js");
var Title = require("../Title.bs.js");
var React = require("react");
var Models = require("../Models.bs.js");
var Router = require("../Router.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var CreateButtonIcon = require("./CreateButtonIcon.bs.js");
var EmptyStatesStyles = require("./EmptyStatesStyles.bs.js");
var GlobalSendContext = require("../GlobalSendContext.bs.js");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");

function PropertyGroupsEmpty(Props) {
  var role = Props.role;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  return React.createElement("main", {
              className: EmptyStatesStyles.rootStyles
            }, React.createElement(Link.make, {
                  path: Router.Link.getSchemaRouteLink(undefined, "properties"),
                  children: React.createElement($$Text.make, {
                        element: "Span",
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.blue,
                        children: null
                      }, React.createElement(Icons.ArrowLeft.make, {
                            size: 9,
                            color: Styles.Color.blue
                          }), " View All Properties")
                }), React.createElement(Title.make, {
                  children: "Tame the chaos with Event Property Bundles",
                  size: "Medium"
                }), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement($$Text.make, {
                  size: "Large",
                  color: Styles.Color.grey70,
                  maxWidth: Css.px(460),
                  children: null
                }, "Event property bundles are a way to bundle two or more related event properties to quickly and consistently add them to all related events. Read more about Event Property Bundles in the ", React.createElement("a", {
                      className: EmptyStatesStyles.linkStyles,
                      href: "https://www.avo.app/docs/workspace/tracking-plan/properties#a-nameproperty-bundlesa-event-property-bundles",
                      rel: "noopener",
                      target: "_blank"
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "Avo docs"
                        })), "."), Models.Role.canEdit(role) ? React.createElement("div", {
                    className: EmptyStatesStyles.actionsStyles
                  }, React.createElement("button", {
                        className: EmptyStatesStyles.actionStyles,
                        onClick: (function (param) {
                            AnalyticsRe.emptyStateInteracted(schemaGroup, "PropertyGroups", "Setup", undefined);
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "NewPropertyGroup",
                                          VAL: [
                                            undefined,
                                            undefined
                                          ]
                                        }
                                      });
                          })
                      }, React.createElement("div", {
                            className: EmptyStatesStyles.iconStyles
                          }, CreateButtonIcon.icon), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            children: "Create a Property Bundle"
                          }))) : null);
}

var make = PropertyGroupsEmpty;

exports.make = make;
/* Css Not a pure module */
