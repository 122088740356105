// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconArrowDownWest(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey40;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.stroke(color),
                    tl: {
                      hd: Css.transform(Css.rotateY(Css.deg(180.0))),
                      tl: /* [] */0
                    }
                  }),
              width: String(size),
              fill: "none",
              viewBox: "0 0 15 14",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M14.5 8.5H0.5V0.5",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeMiterlimit: "10"
                }), React.createElement("path", {
                  d: "M9.5 13.5L14.5 8.5L9.5 3.5",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeMiterlimit: "10"
                }));
}

var make = IconArrowDownWest;

exports.make = make;
/* Css Not a pure module */
