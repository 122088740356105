// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var IconErase = require("./IconErase.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Fuzzaldrin = require("fuzzaldrin");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ProfilePhoto = require("./ProfilePhoto.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.minHeight(Css.px(44)),
          tl: {
            hd: Css.padding2(Css.px(5), Css.px(20)),
            tl: {
              hd: Css.transition({
                    NAME: "ms",
                    VAL: Styles.Duration.$$default
                  }, undefined, undefined, "background-color"),
              tl: {
                hd: Css.cursor("pointer"),
                tl: {
                  hd: Css.hover({
                        hd: Css.backgroundColor(Styles.Color.grey20),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.active({
                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.focus({
                            hd: Css.outlineStyle("none"),
                            tl: {
                              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), true, Styles.Color.blue)),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: /* [] */0
                              }
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var inputStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.opacity(0),
        tl: {
          hd: Css.cursor("pointer"),
          tl: {
            hd: Css.height(Css.px(0)),
            tl: {
              hd: Css.width(Css.px(0)),
              tl: {
                hd: Css.margin(Css.px(0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function pictureStyles(checked) {
  return Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.before({
                      hd: Css.display("block"),
                      tl: {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.bottom(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.zIndex(1),
                                  tl: {
                                    hd: Css.opacity(checked ? 1 : 0),
                                    tl: {
                                      hd: Css.backgroundColor(Styles.Color.blue),
                                      tl: {
                                        hd: Css.borderRadius(Css.px(16)),
                                        tl: {
                                          hd: Css.unsafe("content", "' '"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: {
                  hd: Css.after({
                        hd: Css.display("block"),
                        tl: {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.top(Css.px(0)),
                            tl: {
                              hd: Css.right(Css.px(0)),
                              tl: {
                                hd: Css.bottom(Css.px(0)),
                                tl: {
                                  hd: Css.left(Css.px(0)),
                                  tl: {
                                    hd: Css.opacity(checked ? 1 : 0),
                                    tl: {
                                      hd: Css.zIndex(2),
                                      tl: {
                                        hd: Css.backgroundImage({
                                              NAME: "url",
                                              VAL: "'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjQ4IDYuNThMMTAuOS4yMmEuNzUuNzUgMCAwMTEuMDUgMGwuODMuODJjLjMuMjkuMy43NSAwIDEuMDNMNS4wMiA5Ljc4YS43NS43NSAwIDAxLTEuMDUgMEwuMiA2LjA1YS43NC43NCAwIDAxMC0xLjAzbC44NC0uODJhLjczLjczIDAgMDExLjA0IDBsMi40IDIuMzh6IiBmaWxsPSIjZmZmIi8+PC9zdmc+'"
                                            }),
                                        tl: {
                                          hd: Css.backgroundPosition({
                                                NAME: "hv",
                                                VAL: [
                                                  Css.pct(50),
                                                  Css.pct(50)
                                                ]
                                              }),
                                          tl: {
                                            hd: Css.backgroundRepeat("noRepeat"),
                                            tl: {
                                              hd: Css.borderRadius(Css.px(16)),
                                              tl: {
                                                hd: Css.unsafe("content", "' '"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              }
            });
}

var infoStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(12)),
      tl: /* [] */0
    });

function EditCollaboratorsPopup$Item(Props) {
  var user = Props.user;
  var onToggle = Props.onToggle;
  var checked = Props.checked;
  return React.createElement("label", {
              className: rootStyles
            }, React.createElement("input", {
                  className: inputStyles,
                  checked: checked,
                  type: "checkbox",
                  onChange: (function (param) {
                      return Curry._2(onToggle, user, !checked);
                    })
                }), React.createElement("div", {
                  className: pictureStyles(checked)
                }, React.createElement(ProfilePhoto.make, {
                      user: user,
                      size: 32
                    })), React.createElement("div", {
                  className: infoStyles
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      children: AvoConfig.getUserDisplayName(user)
                    }), Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(user.name), null, (function (param) {
                        return React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Medium",
                                    color: Styles.Color.grey50,
                                    children: user.email
                                  });
                      }))));
}

var Item = {
  rootStyles: rootStyles,
  inputStyles: inputStyles,
  pictureStyles: pictureStyles,
  infoStyles: infoStyles,
  make: EditCollaboratorsPopup$Item
};

var rootStyles$1 = Curry._1(Css.style, {
      hd: Css.width(Css.px(280)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.overflow("hidden"),
          tl: /* [] */0
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
      tl: {
        hd: Css.padding4(Css.px(16), Css.px(12), Css.px(8), Css.px(20)),
        tl: /* [] */0
      }
    });

var headerInputRowStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingBottom(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

var filterStyles = Curry._1(Css.style, {
      hd: Css.minWidth(Css.px(0)),
      tl: {
        hd: Css.width(Css.px(100)),
        tl: {
          hd: Css.flexShrink(1.0),
          tl: {
            hd: Css.flexGrow(1.0),
            tl: {
              hd: Css.borderWidth(Css.px(0)),
              tl: {
                hd: Css.fontSize(Styles.FontSize.regular),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.medium),
                  tl: {
                    hd: Css.lineHeight(Styles.LineHeight.regular),
                    tl: {
                      hd: Css.color(Styles.Color.grey70),
                      tl: {
                        hd: Css.paddingTop(Css.px(0)),
                        tl: {
                          hd: Css.paddingRight(Css.px(8)),
                          tl: {
                            hd: Css.paddingBottom(Css.px(0)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(0)),
                              tl: {
                                hd: Css.placeholder({
                                      hd: Css.color(Styles.Color.grey50),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.focus({
                                        hd: Css.outlineStyle("none"),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function eraseButtonStyles(disabled) {
  return Curry._1(Css.style, {
              hd: Css.padding(Css.px(0)),
              tl: {
                hd: Css.marginTop(Css.px(0)),
                tl: {
                  hd: Css.marginBottom(Css.px(0)),
                  tl: {
                    hd: Css.marginLeft(Css.px(0)),
                    tl: {
                      hd: Css.marginRight(Css.px(8)),
                      tl: {
                        hd: Css.borderWidth(Css.px(0)),
                        tl: {
                          hd: Css.borderRadius(Css.px(2)),
                          tl: {
                            hd: Css.background("transparent"),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "opacity"),
                              tl: {
                                hd: Css.opacity(disabled ? 0 : 1),
                                tl: {
                                  hd: Css.unsafe("appearance", "none"),
                                  tl: {
                                    hd: Css.outlineStyle("none"),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.active({
                                              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.focus({
                                                hd: Css.outlineStyle("none"),
                                                tl: {
                                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.blue)),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var listStyles = Curry._1(Css.style, {
      hd: Css.maxHeight({
            NAME: "substract",
            VAL: [
              Css.vh(50),
              Css.px(50)
            ]
          }),
      tl: {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.paddingTop(Css.px(6)),
          tl: {
            hd: Css.paddingBottom(Css.px(6)),
            tl: /* [] */0
          }
        }
      }
    });

var emptyStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.padding2(Css.px(16), Css.px(20)),
            tl: /* [] */0
          }
        }
      }
    });

var profilePlaceholderStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.shimmer),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.height(Css.px(32)),
              tl: {
                hd: Css.width(Css.px(32)),
                tl: {
                  hd: Css.borderRadius(Css.px(16)),
                  tl: {
                    hd: Css.marginTop(Css.px(6)),
                    tl: {
                      hd: Css.marginBottom(Css.px(12)),
                      tl: {
                        hd: Css.marginLeft(Css.px(20)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function EditCollaboratorsPopup(Props) {
  var collaboratorIds = Props.collaboratorIds;
  var onToggle = Props.onToggle;
  var match = React.useState(function () {
        return "";
      });
  var setFilter = match[1];
  var filter = match[0];
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var memberIds = Belt_List.toArray(Belt_List.map(workspace.members, (function (member) {
              return member.id;
            })));
  var users = FirebaseFetcherHooks.useUsers(memberIds);
  var filteredItems;
  if (typeof users === "object") {
    if (users.NAME === "Loaded") {
      var users$1 = users.VAL;
      filteredItems = Belt_Array.keepMap(Fuzzaldrin.filter(Belt_Array.map(users$1, (function (user) {
                      return {
                              id: user.id,
                              key: user.email + Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), "")
                            };
                    })), filter, {
                key: "key",
                maxResults: 100
              }), (function (item) {
              return Belt_Array.getBy(users$1, (function (user) {
                            return user.id === item.id;
                          }));
            }));
    } else {
      filteredItems = [];
    }
  } else {
    filteredItems = [];
  }
  return React.createElement("div", {
              className: rootStyles$1
            }, React.createElement("div", {
                  className: headerStyles
                }, React.createElement("div", {
                      className: headerInputRowStyles
                    }, React.createElement("input", {
                          className: filterStyles,
                          autoFocus: true,
                          placeholder: "Find member...",
                          value: filter,
                          onChange: (function ($$event) {
                              var value = $$event.target.value;
                              return Curry._1(setFilter, (function (param) {
                                            return value;
                                          }));
                            })
                        }), React.createElement("button", {
                          className: eraseButtonStyles(filter === ""),
                          disabled: filter === "",
                          onClick: (function (param) {
                              return Curry._1(setFilter, (function (param) {
                                            return "";
                                          }));
                            })
                        }, React.createElement(IconErase.make, {
                              color: Styles.Color.grey40,
                              hoverColor: Styles.Color.grapeError
                            }))), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.grey70,
                      children: "Collaborators get notified about updates on this branch"
                    })), typeof users === "object" ? (
                users.NAME === "Errored" ? React.createElement("div", {
                        className: emptyStyles
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.grey50,
                            children: "Failed to load members"
                          })) : (
                    users.VAL.length !== 0 ? (
                        filteredItems.length !== 0 ? React.createElement("div", {
                                className: listStyles
                              }, Belt_Array.mapU(filteredItems, (function (user) {
                                      return React.createElement(EditCollaboratorsPopup$Item, {
                                                  user: user,
                                                  onToggle: onToggle,
                                                  checked: Belt_List.has(collaboratorIds, user.id, (function (prim0, prim1) {
                                                          return prim0 === prim1;
                                                        })),
                                                  key: user.id
                                                });
                                    }))) : React.createElement("div", {
                                className: emptyStyles
                              }, React.createElement($$Text.make, {
                                    size: "Medium",
                                    weight: "Semi",
                                    color: Styles.Color.grey50,
                                    children: "Found no matches"
                                  }))
                      ) : React.createElement("div", {
                            className: emptyStyles
                          }, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                color: Styles.Color.grey50,
                                children: "Found no members"
                              }))
                  )
              ) : React.createElement("div", {
                    className: listStyles
                  }, Belt_Array.map(memberIds, (function (id) {
                          return React.createElement("div", {
                                      key: id,
                                      className: profilePlaceholderStyles
                                    });
                        }))));
}

var make = EditCollaboratorsPopup;

exports.Item = Item;
exports.rootStyles = rootStyles$1;
exports.headerStyles = headerStyles;
exports.headerInputRowStyles = headerInputRowStyles;
exports.filterStyles = filterStyles;
exports.eraseButtonStyles = eraseButtonStyles;
exports.listStyles = listStyles;
exports.emptyStyles = emptyStyles;
exports.profilePlaceholderStyles = profilePlaceholderStyles;
exports.make = make;
/* rootStyles Not a pure module */
