// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Case = require("case");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");

var _map = {"Sentence":"sentence","Title":"title","Pascal":"pascal","Header":"header","Capital":"capital","Upper":"upper","Kebab":"kebab","Camel":"camel","Constant":"constant","Snake":"snake","Lower":"lower","None":"None"};

var _revMap = {"sentence":"Sentence","title":"Title","pascal":"Pascal","header":"Header","capital":"Capital","upper":"Upper","kebab":"Kebab","camel":"Camel","constant":"Constant","snake":"Snake","lower":"Lower","None":"None"};

function tToJs(param) {
  return _map[param];
}

function tFromJs(param) {
  return _revMap[param];
}

function camel(word) {
  return Case.camel(Case.sentence(word));
}

function titleOverCapital(string, $$case) {
  if ($$case === "Capital" && Case.title(string) === string) {
    return "Title";
  } else {
    return $$case;
  }
}

function internalGetCase(string) {
  return titleOverCapital(string, Belt_Option.getWithDefault(tFromJs(Belt_Option.getWithDefault(Caml_option.nullable_to_opt(Case.of(string)), "")), "None"));
}

var commonStringSeparators = [
  " : ",
  ":",
  ".",
  " . "
];

function of_(string) {
  var $$case = internalGetCase(string);
  if ($$case === "None" || $$case === "Lower") {
    return Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMapU(commonStringSeparators, (function (separator) {
                          var subStrings = string.split(separator);
                          var subStringsCase = Belt_Array.mapU(subStrings, (function (subString) {
                                  return tToJs(internalGetCase(subString));
                                }));
                          var caseVariance = Belt_Array.keepMap(Belt_SetString.toArray(Belt_SetString.fromArray(subStringsCase)), tFromJs);
                          var exit = 0;
                          var len = caseVariance.length;
                          if (len >= 3) {
                            return ;
                          }
                          switch (len) {
                            case 0 :
                                return ;
                            case 1 :
                                var $$case = caseVariance[0];
                                if ($$case === "None") {
                                  return ;
                                } else {
                                  return $$case;
                                }
                            case 2 :
                                var match = caseVariance[0];
                                if (match === "Camel") {
                                  var match$1 = caseVariance[1];
                                  if (match$1 !== "Lower") {
                                    return ;
                                  }
                                  exit = 1;
                                } else if (match === "Lower") {
                                  var match$2 = caseVariance[1];
                                  if (match$2 === "Camel") {
                                    exit = 1;
                                  } else {
                                    if (match$2 !== "Snake") {
                                      return ;
                                    }
                                    exit = 2;
                                  }
                                } else {
                                  if (match !== "Snake") {
                                    return ;
                                  }
                                  var match$3 = caseVariance[1];
                                  if (match$3 !== "Lower") {
                                    return ;
                                  }
                                  exit = 2;
                                }
                                break;
                            
                          }
                          switch (exit) {
                            case 1 :
                                if (Belt_Array.everyU(subStrings, (function (subString) {
                                          return Case.camel(Case.sentence(subString)) === subString;
                                        }))) {
                                  return "Camel";
                                } else {
                                  return ;
                                }
                            case 2 :
                                if (Belt_Array.everyU(subStrings, (function (subString) {
                                          return Case.snake(subString) === subString;
                                        }))) {
                                  return "Snake";
                                } else {
                                  return ;
                                }
                            
                          }
                        })), 0), "None");
  } else {
    return $$case;
  }
}

function to_($$case, string) {
  if ($$case === "Capital") {
    return Case.capital(string);
  } else if ($$case === "Kebab") {
    return Case.kebab(string);
  } else if ($$case === "Sentence") {
    return Case.sentence(string);
  } else if ($$case === "Constant") {
    return Case.constant(string);
  } else if ($$case === "Lower") {
    return Case.lower(string);
  } else if ($$case === "Snake") {
    return Case.snake(string);
  } else if ($$case === "Pascal") {
    return Case.pascal(string);
  } else if ($$case === "Title") {
    return Case.title(string);
  } else if ($$case === "Header") {
    return Case.header(string);
  } else if ($$case === "None") {
    return string;
  } else if ($$case === "Upper") {
    return Case.upper(string);
  } else {
    return Case.camel(Case.sentence(string));
  }
}

function toWithSeperators($$case, string) {
  return Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMapU(commonStringSeparators, (function (separator) {
                        if (!string.includes(separator)) {
                          return ;
                        }
                        var subStrings = string.split(separator);
                        var convertedStrings = Belt_Array.map(subStrings, (function (subString) {
                                return to_($$case, subString);
                              }));
                        return Belt_Array.joinWith(convertedStrings, separator, (function (i) {
                                      return i;
                                    }));
                      })), 0), to_($$case, string));
}

function getCasingWithWorkspaceCase(word, workspaceExpectedCase) {
  var $$case = of_(word);
  if ($$case === "Lower" && workspaceExpectedCase !== "None" && word === toWithSeperators(workspaceExpectedCase, word)) {
    return workspaceExpectedCase;
  } else {
    return $$case;
  }
}

function getLabel($$case) {
  return to_("Title", tToJs($$case));
}

exports.tToJs = tToJs;
exports.tFromJs = tFromJs;
exports.camel = camel;
exports.titleOverCapital = titleOverCapital;
exports.internalGetCase = internalGetCase;
exports.commonStringSeparators = commonStringSeparators;
exports.of_ = of_;
exports.to_ = to_;
exports.toWithSeperators = toWithSeperators;
exports.getCasingWithWorkspaceCase = getCasingWithWorkspaceCase;
exports.getLabel = getLabel;
/* case Not a pure module */
