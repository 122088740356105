// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("../Link.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Router = require("../Router.bs.js");
var Styles = require("../styles.bs.js");
var CopyButton = require("../CopyButton.bs.js");
var InspectorViewInstallDocsGoInitCodeSnippet = require("./InspectorViewInstallDocsGoInitCodeSnippet.bs.js");
var InspectorViewInstallDocsGoTrackEventCodeSnippet = require("./InspectorViewInstallDocsGoTrackEventCodeSnippet.bs.js");

var docsContentStyles = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(24), Css.px(32)),
      tl: /* [] */0
    });

var docsDescriptionStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(24)),
      tl: {
        hd: Css.marginBottom(Css.px(0)),
        tl: {
          hd: Css.display("block"),
          tl: {
            hd: Css.color(Styles.Color.grey90),
            tl: {
              hd: Css.fontSize(Styles.FontSize.medium),
              tl: {
                hd: Css.lineHeight(Css.pct(140.0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var docsCodeBoxStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: /* [] */0
    });

var docsCodeStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.marginTop(Css.px(12)),
          tl: {
            hd: Css.display("block"),
            tl: {
              hd: Css.backgroundColor(Styles.Color.grey80),
              tl: {
                hd: Css.paddingTop(Css.px(16)),
                tl: {
                  hd: Css.paddingRight(Css.px(70)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(16)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(24)),
                      tl: {
                        hd: Css.fontFamily(Styles.monoFontFamily),
                        tl: {
                          hd: Css.fontSize(Styles.FontSize.small),
                          tl: {
                            hd: Css.color(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Css.px(3)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var docsCodeLightBlueStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.blueSecondary),
      tl: {
        hd: Css.fontFamily(Styles.monoFontFamily),
        tl: /* [] */0
      }
    });

var docsCodeMintGreenStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.mintGreen),
      tl: {
        hd: Css.fontFamily(Styles.monoFontFamily),
        tl: /* [] */0
      }
    });

var copyButtonBoxStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(10)),
        tl: {
          hd: Css.top(Css.px(10)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var docsLinkStyles = Curry._1(Css.style, {
      hd: Css.display("inlineBlock"),
      tl: {
        hd: Css.marginTop(Css.px(32)),
        tl: {
          hd: Css.color(Styles.Color.blue),
          tl: {
            hd: Css.textDecoration("none"),
            tl: {
              hd: Css.fontWeight(Styles.FontWeight.semi),
              tl: {
                hd: Css.transition({
                      NAME: "ms",
                      VAL: 200.0
                    }, undefined, undefined, "color"),
                tl: {
                  hd: Css.hover({
                        hd: Css.color(Styles.Color.blueSecondary),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function InspectorViewInstallDocsGo(Props) {
  var apiKey = Props.apiKey;
  var language = Props.language;
  var onClose = Props.onClose;
  return React.createElement("div", {
              className: docsContentStyles
            }, React.createElement("p", {
                  className: docsDescriptionStyles
                }, React.createElement("strong", undefined, "1. "), "Avo Inspector SDK is available as a Go module ", React.createElement("strong", undefined, "JitPack.io"), ". To install it, use the following command:"), React.createElement("div", {
                  className: docsCodeBoxStyles
                }, React.createElement("pre", {
                      className: docsCodeStyles
                    }, React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "go get github.com/avohq/go-avo-inspector")), React.createElement("div", {
                      className: copyButtonBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: "go get github.com/avohq/go-avo-inspector"
                        }))), React.createElement("p", {
                  className: docsDescriptionStyles
                }, React.createElement("strong", undefined, "2. "), "Initialize with your Go source api key "), InspectorViewInstallDocsGoInitCodeSnippet.code(docsCodeBoxStyles, docsCodeStyles, docsCodeLightBlueStyles, docsCodeMintGreenStyles, copyButtonBoxStyles, apiKey, language), React.createElement("p", {
                  className: docsDescriptionStyles
                }, React.createElement("strong", undefined, "3. "), "Send event schemas to Avo"), InspectorViewInstallDocsGoTrackEventCodeSnippet.code(docsCodeBoxStyles, docsCodeStyles, docsCodeLightBlueStyles, docsCodeMintGreenStyles, copyButtonBoxStyles, language), React.createElement("p", {
                  className: docsDescriptionStyles
                }, React.createElement("strong", undefined, "4. "), "View your first events on the ", React.createElement(Link.make, {
                      path: Router.Link.getSchemaRouteLink(undefined, {
                            NAME: "inspector",
                            VAL: "events"
                          }),
                      className: docsLinkStyles,
                      onClick: (function (param) {
                          return Curry._1(onClose, undefined);
                        }),
                      children: "events page"
                    })), React.createElement("a", {
                  className: docsLinkStyles,
                  href: "https://github.com/avohq/go-avo-inspector",
                  target: "_blank"
                }, "View on GitHub"), React.createElement("div", undefined, React.createElement("a", {
                      className: docsLinkStyles,
                      href: "https://www.avo.app/docs/implementation/inspector/sdk/go",
                      target: "_blank"
                    }, "View Documentation")));
}

var make = InspectorViewInstallDocsGo;

exports.docsContentStyles = docsContentStyles;
exports.docsDescriptionStyles = docsDescriptionStyles;
exports.docsCodeBoxStyles = docsCodeBoxStyles;
exports.docsCodeStyles = docsCodeStyles;
exports.docsCodeLightBlueStyles = docsCodeLightBlueStyles;
exports.docsCodeMintGreenStyles = docsCodeMintGreenStyles;
exports.copyButtonBoxStyles = copyButtonBoxStyles;
exports.docsLinkStyles = docsLinkStyles;
exports.make = make;
/* docsContentStyles Not a pure module */
