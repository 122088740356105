// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconSend(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey40;
  return React.createElement("svg", {
              width: String(size),
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  className: Curry._1(Css.style, {
                        hd: Css_Legacy_Core.SVG.fill(color),
                        tl: {
                          hd: Css_Legacy_Core.SVG.stroke(color),
                          tl: /* [] */0
                        }
                      }),
                  strokeWidth: "1"
                }, React.createElement("polyline", {
                      fill: "none",
                      points: " 4.504,9.503 12.5,15.5 15.5,0.5 0.5,6.5 2.502,8.001 ",
                      strokeLinecap: "round"
                    }), React.createElement("polyline", {
                      fill: "none",
                      points: " 7.63,11.848 4.5,15.5 4.504,9.503 9,6.5 ",
                      strokeLinecap: "round"
                    })));
}

var make = IconSend;

exports.make = make;
/* Css Not a pure module */
