// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var TextButton = require("../TextButton.bs.js");

function ExpandImplementationStatusListButton(Props) {
  var expanded = Props.expanded;
  var setExpanded = Props.setExpanded;
  return React.createElement(TextButton.make, {
              onClick: (function (param) {
                  return Curry._1(setExpanded, (function (param) {
                                return !expanded;
                              }));
                }),
              style: "Blue",
              size: "Small",
              noFocus: true,
              children: null
            }, expanded ? React.createElement(React.Fragment, undefined, React.createElement("span", {
                        className: Curry._1(Css.style, {
                              hd: Css.width(Css.px(12)),
                              tl: /* [] */0
                            })
                      }, "-"), "Show ") : React.createElement(React.Fragment, undefined, React.createElement("span", {
                        className: Curry._1(Css.style, {
                              hd: Css.width(Css.px(12)),
                              tl: /* [] */0
                            })
                      }, "+"), "Show "), "other environments");
}

var make = ExpandImplementationStatusListButton;

exports.make = make;
/* Css Not a pure module */
