// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Title = require("./Title.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var ReactDom = require("react-dom");
var KeyListener = require("./KeyListener.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var IconNewWorkspace = require("./IconNewWorkspace.bs.js");
var ModalCloseButton = require("./ModalCloseButton.bs.js");
var IconJoinWorkspace = require("./IconJoinWorkspace.bs.js");

var root = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.top(Css.px(0)),
              tl: {
                hd: Css.right(Css.px(0)),
                tl: {
                  hd: Css.left(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(0)),
                    tl: {
                      hd: Css.zIndex(1),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var backdrop = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.left(Css.px(0)),
            tl: {
              hd: Css.bottom(Css.px(0)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.grey90, 0.2)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var modal = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.borderRadius(Css.px(10)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.paddingTop(Css.px(60)),
                  tl: {
                    hd: Css.paddingRight(Css.px(40)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(40)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(40)),
                        tl: {
                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(8), Css.px(32), undefined, undefined, Styles.Color.setAlpha(Styles.Color.grey90, 0.2))),
                          tl: {
                            hd: Css.zIndex(1),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var options = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("stretch"),
        tl: {
          hd: Css.marginTop(Css.px(32)),
          tl: /* [] */0
        }
      }
    });

var option = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.width(Css.px(320)),
            tl: {
              hd: Css.padding(Css.px(20)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var separator = Curry._1(Css.style, {
      hd: Css.width(Css.px(1)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.grey30),
        tl: {
          hd: Css.flexShrink(0.0),
          tl: {
            hd: Css.marginLeft(Css.px(20)),
            tl: {
              hd: Css.marginRight(Css.px(20)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var Style = {
  root: root,
  backdrop: backdrop,
  modal: modal,
  options: options,
  option: option,
  separator: separator
};

function OnboardingNewOrJoinModal(Props) {
  var onClose = Props.onClose;
  var onCreate = Props.onCreate;
  var onInvite = Props.onInvite;
  return ReactDom.createPortal(React.createElement("div", {
                  className: root
                }, React.createElement(KeyListener.make, {
                      keyName: "Escape",
                      onPress: (function (param) {
                          return Curry._1(onClose, undefined);
                        })
                    }), React.createElement("div", {
                      className: backdrop,
                      onClick: (function (param) {
                          return Curry._1(onClose, undefined);
                        })
                    }), React.createElement("div", {
                      className: modal
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.position("absolute"),
                                tl: {
                                  hd: Css.top(Css.px(20)),
                                  tl: {
                                    hd: Css.right(Css.px(20)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement(ModalCloseButton.make, {
                              onClick: (function (param) {
                                  return Curry._1(onClose, undefined);
                                })
                            })), React.createElement(Title.make, {
                          children: "What would you like to do?",
                          size: "Medium"
                        }), React.createElement("div", {
                          className: options
                        }, React.createElement("div", {
                              className: option
                            }, React.createElement(IconNewWorkspace.make, {
                                  size: 40,
                                  color: Styles.Color.magenta
                                }), React.createElement(Spacer.make, {
                                  height: 8
                                }), React.createElement(Title.make, {
                                  children: "Set up a new workspace",
                                  size: "Small"
                                }), React.createElement(Spacer.make, {
                                  height: 12
                                }), React.createElement($$Text.make, {
                                  size: "Medium",
                                  textAlign: "center",
                                  color: Styles.Color.grey70,
                                  children: "Set up your tracking plan, invite team members and collabora te on changes."
                                }), React.createElement(Spacer.make, {
                                  height: 36
                                }), React.createElement(Button.make, {
                                  icon: "plus",
                                  label: "Create a New Workspace",
                                  onClick: onCreate,
                                  size: "large"
                                })), React.createElement("div", {
                              className: separator
                            }), React.createElement("div", {
                              className: option
                            }, React.createElement(IconJoinWorkspace.make, {
                                  size: 40,
                                  color: Styles.Color.magenta
                                }), React.createElement(Spacer.make, {
                                  height: 8
                                }), React.createElement(Title.make, {
                                  children: "Join an existing workspace",
                                  size: "Small"
                                }), React.createElement(Spacer.make, {
                                  height: 16
                                }), React.createElement($$Text.make, {
                                  size: "Medium",
                                  textAlign: "center",
                                  color: Styles.Color.grey70,
                                  children: "To start collaborating, find someone in your team that can send you an invite to the Avo workspace."
                                }), React.createElement(Spacer.make, {
                                  height: 16
                                }), React.createElement(Button.make, {
                                  label: "Ask teammates for invite",
                                  onClick: onInvite,
                                  size: "large"
                                }))))), document.body);
}

var make = OnboardingNewOrJoinModal;

exports.Style = Style;
exports.make = make;
/* root Not a pure module */
