// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Text = require("../Text.bs.js");
var React = require("react");
var Mantine = require("../Mantine.bs.js");
var Tooltip = require("../Tooltip.bs.js");

function RingProgress(Props) {
  var size = Props.size;
  var thickness = Props.thickness;
  var rootColor = Props.rootColor;
  var sections = Props.sections;
  var tooltip = Props.tooltip;
  return React.createElement(Tooltip.make, {
              position: "top",
              title: "",
              tooltipPadding: 8,
              withAskUsAnything: false,
              withDismiss: false,
              withTitle: false,
              children: null
            }, React.createElement(Tooltip.Target.make, {
                  children: React.createElement(Mantine.RingProgress.make, {
                        rootColor: rootColor,
                        sections: sections,
                        size: size,
                        thickness: thickness
                      })
                }), React.createElement(Tooltip.Content.make, {
                  children: React.createElement($$Text.make, {
                        size: "Small",
                        children: tooltip
                      })
                }));
}

var make = RingProgress;

exports.make = make;
/* Text Not a pure module */
