// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function DocsLink(Props) {
  var path = Props.path;
  var onClick = Props.onClick;
  var labelOpt = Props.label;
  var label = labelOpt !== undefined ? labelOpt : "Docs";
  var tmp = {
    className: Curry._1(Css.style, {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.color(Styles.Color.blue),
              tl: {
                hd: Css.fontWeight(Styles.FontWeight.semi),
                tl: {
                  hd: Css.textDecoration("none"),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.small),
                    tl: {
                      hd: Css.marginTop(Css.px(2)),
                      tl: {
                        hd: Css.selector(" path", {
                              hd: Css_Legacy_Core.SVG.fill(Styles.Color.blue),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.hover({
                                hd: Css.color(Styles.Color.blueSecondary),
                                tl: {
                                  hd: Css.selector(" path", {
                                        hd: Css_Legacy_Core.SVG.fill(Styles.Color.blueSecondary),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }),
    href: "https://www.avo.app/docs/" + path,
    target: "_blank"
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("a", tmp, label, React.createElement("svg", {
                  height: "15",
                  width: "11",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("path", {
                      d: "M2.95 10.1l4.14-4.14-.01 3.13h1.14V4h-5.1v1.13h3.14L2.13 9.27l.82.83z"
                    })));
}

var make = DocsLink;

exports.make = make;
/* Css Not a pure module */
