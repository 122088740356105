// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var AvoModel = require("./avoModel.bs.js");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var CodegenRules = require("../../codegen/src/CodegenRules.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");
var PropertyValidationUtils = require("./PropertyValidationUtils.bs.js");
var PropertyAbsenceViewHelpers = require("./PropertyAbsenceViewHelpers.bs.js");

function valueType(item) {
  var match = item.value;
  return match.VAL.type_;
}

function printValueType(item) {
  return TrackingPlanModel.Type.tToJs(valueType(item));
}

function printValue(item) {
  var type_ = TrackingPlanModel.Type.tToJs(valueType(item));
  return (
          item.list ? "list of " : ""
        ) + (
          item.optional ? "optional " : ""
        ) + type_;
}

function printPinnedValue(literal) {
  var variant = literal.NAME;
  if (variant === "BooleanLit") {
    if (literal.VAL) {
      return "TRUE";
    } else {
      return "FALSE";
    }
  } else if (variant === "StringLit") {
    return "\"" + literal.VAL + "\"";
  } else if (variant === "FloatLit") {
    return literal.VAL.toString();
  } else {
    return String(literal.VAL);
  }
}

function getSystemProps(props) {
  return Belt_List.keepMap(props, (function (prop) {
                if (prop.TAG === /* PropertyRef */0) {
                  return ;
                }
                var p = prop._0;
                if (p.sendAs === /* SystemProperty */0) {
                  return p;
                }
                
              }));
}

function collectNestedProperties(model, property) {
  var go = function (acc, property) {
    if (property.type_ !== "object") {
      return {
              hd: property,
              tl: acc
            };
    }
    var nestedProperties = BeltListExtensions.flatMap(property.validations, (function (x) {
            if (typeof x === "object" && x.NAME === "NestedProperty") {
              return Belt_List.keepMap(x.VAL, (function (propertyRef) {
                            return AvoModel.getPropertyById(model, propertyRef.id);
                          }));
            } else {
              return /* [] */0;
            }
          }));
    return Belt_List.reduce(nestedProperties, {
                hd: property,
                tl: acc
              }, (function (acc, property) {
                  if (Belt_List.some(acc, (function (p) {
                            return p.id === property.id;
                          }))) {
                    return acc;
                  } else {
                    return {
                            hd: property,
                            tl: go(acc, property)
                          };
                  }
                }));
  };
  return go(/* [] */0, property);
}

function deduplicateProperties(properties) {
  var cmp = function (a, b) {
    return Caml.caml_string_compare(a.id, b.id);
  };
  var PropertyComparator = Belt_Id.MakeComparable({
        cmp: cmp
      });
  return Belt_Set.toList(Belt_Set.fromArray(Belt_List.toArray(properties), PropertyComparator));
}

function getAllPropertiesFromEvents(eventFilterOpt, model) {
  var eventFilter = eventFilterOpt !== undefined ? eventFilterOpt : (function (param) {
        return true;
      });
  return deduplicateProperties(BeltListExtensions.flatMap(Belt_List.concat(BeltListExtensions.flatMap(Belt_List.keep(model.events, eventFilter), (function ($$event) {
                            var propertyGroups = BeltListExtensions.dedupeOrdered(BeltListExtensions.flatMap(AvoModel.resolvePropertyBundleRefs(model.propertyBundles, $$event.propertyBundles), (function (group) {
                                        return AvoModel.resolvePropertyIds(model.properties, group.properties);
                                      })), (function (property) {
                                    return property.id;
                                  }));
                            var match = AvoModel.getProperties(model, $$event.properties);
                            return Belt_List.concatMany([
                                        match.eventProperties,
                                        match.userProperties,
                                        match.groupProperties,
                                        propertyGroups
                                      ]);
                          })), getSystemProps(model.properties)), (function (property) {
                    return collectNestedProperties(model, property);
                  })));
}

function printPropertyType(property, optionalOverwrite, eventId, model, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, param) {
  var validations = property.validations;
  var validations$1 = validations ? Belt_List.toArray(Belt_List.map(validations, (function (x) {
                  if (typeof x !== "object") {
                    return "";
                  }
                  var variant = x.NAME;
                  if (variant === "Matches") {
                    var matches = x.VAL;
                    if (Belt_List.length(matches) <= 0) {
                      return "";
                    }
                    var matches$1 = Belt_List.toArray(Belt_List.map(matches, (function (param) {
                                  var exp = param[0];
                                  if (typeof exp !== "object") {
                                    return "";
                                  }
                                  if (exp.NAME !== "Literal") {
                                    return "";
                                  }
                                  var match = exp.VAL;
                                  if (typeof match === "object" && match.NAME === "StringLit") {
                                    return match.VAL;
                                  } else {
                                    return "";
                                  }
                                }))).join(", ");
                    return "matches " + matches$1;
                  }
                  if (variant === "Max") {
                    var match = x.VAL;
                    if (typeof match !== "object") {
                      return "";
                    }
                    var variant$1 = match.NAME;
                    if (variant$1 === "IntLit") {
                      return "max " + String(match.VAL);
                    } else if (variant$1 === "FloatLit") {
                      return "max " + String(match.VAL);
                    } else {
                      return "";
                    }
                  }
                  if (variant === "Min") {
                    var match$1 = x.VAL;
                    if (typeof match$1 !== "object") {
                      return "";
                    }
                    var variant$2 = match$1.NAME;
                    if (variant$2 === "IntLit") {
                      return "min " + String(match$1.VAL);
                    } else if (variant$2 === "FloatLit") {
                      return "min " + String(match$1.VAL);
                    } else {
                      return "";
                    }
                  }
                  if (variant === "NestedProperty") {
                    var nestedPropertyRefs = x.VAL;
                    if (nestedPropertyRefs) {
                      return "{" + Belt_List.toArray(Belt_List.keepMap(nestedPropertyRefs, (function (nestedPropertyRef) {
                                          var maybeNestedProperty = StateUtils.resolvePropertyById(nestedPropertyRef.id, model);
                                          if (maybeNestedProperty === undefined) {
                                            return ;
                                          }
                                          var maybeValidPinnedValue = eventId !== undefined ? Belt_Option.map(CodegenRules.getNestedPropertyPinnedValue(nestedPropertyRef, property.id, eventId, model), (function (param) {
                                                    return printPinnedValue(param[0]) + (
                                                            param[1] === /* EventSpecific */0 ? " (on this event)" : " (on all events)"
                                                          );
                                                  })) : Belt_Option.flatMap(nestedPropertyRef.pinnedValue, (function (pinnedValue) {
                                                    if (PropertyValidationUtils.validatePinnedValue(pinnedValue, maybeNestedProperty)) {
                                                      return printPinnedValue(pinnedValue) + " (pinned)";
                                                    }
                                                    
                                                  }));
                                          var value = Belt_Option.getWithDefault(maybeValidPinnedValue, printPropertyType(maybeNestedProperty, maybeNestedProperty.optionalWhenInObject, undefined, model, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, undefined));
                                          return maybeNestedProperty.name + ": " + value;
                                        }))).join(", ") + "}";
                    } else {
                      return "";
                    }
                  }
                  if (variant !== "Shape") {
                    return "";
                  }
                  var items = x.VAL;
                  if (items) {
                    return "{" + Belt_List.toArray(Belt_List.map(items, (function (item) {
                                        return item.key + ": " + printValue(item);
                                      }))).join(", ") + "}";
                  } else {
                    return "";
                  }
                }))).join(" ") : "";
  var optionality = optionalOverwrite !== undefined ? (
      optionalOverwrite ? "optional " : ""
    ) : Belt_Option.mapWithDefault(PropertyAbsenceViewHelpers.getPropertyAbsenceString(model, undefined, property, undefined), "", (function (string) {
            return string + ", ";
          }));
  return optionality + (
          property.list ? "list of " : ""
        ) + property.type_ + (
          validations$1 === "" ? "" : " (" + validations$1 + ")"
        );
}

function printPropertyTypeWithoutValidations(property, optional) {
  return (
          optional ? "optional " : ""
        ) + (
          property.list ? "list of " : ""
        ) + property.type_;
}

function getPropertyTypes(hasObjectsFeatureFlag, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, property) {
  var tmp = true;
  if (property.type_ !== "object") {
    var tmp$1 = false;
    if (hasObjectsFeatureFlag) {
      var match = property.sendAs;
      tmp$1 = typeof match === "number" ? true : false;
    }
    tmp = tmp$1;
  }
  return Belt_List.concatMany([
              hasAnyValuesFeatureFlag || property.type_ === "any" ? ({
                    hd: "any",
                    tl: /* [] */0
                  }) : /* [] */0,
              {
                hd: "string",
                tl: {
                  hd: "int",
                  tl: /* [] */0
                }
              },
              hasLongValuesFeatureFlag || property.type_ === "long" ? ({
                    hd: "long",
                    tl: /* [] */0
                  }) : /* [] */0,
              {
                hd: "float",
                tl: {
                  hd: "bool",
                  tl: /* [] */0
                }
              },
              tmp ? ({
                    hd: "object",
                    tl: /* [] */0
                  }) : /* [] */0
            ]);
}

function getPinnedValues(propertyRef, propertyId, eventId, model) {
  var maybeProperty = StateUtils.resolvePropertyById(propertyId, model);
  var maybeEvent = StateUtils.getEventById(eventId, model);
  var propertyRef$1 = propertyRef !== undefined ? propertyRef : (
      maybeEvent !== undefined ? Belt_List.head(Belt_List.keepMapU(maybeEvent.properties, (function (property) {
                    if (property.TAG !== /* PropertyRef */0) {
                      return ;
                    }
                    var propRef = property._0;
                    if (propRef.id === propertyId) {
                      return propRef;
                    }
                    
                  }))) : undefined
    );
  if (maybeEvent !== undefined) {
    if (maybeProperty === undefined) {
      return /* [] */0;
    }
    if (maybeProperty.type_ === "object") {
      var propertyRefs = Belt_Option.getWithDefault(Belt_List.head(Belt_List.keepMapU(maybeProperty.validations, (function (validation) {
                      if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                        return validation.VAL;
                      }
                      
                    }))), /* [] */0);
      return Belt_List.keepMap(propertyRefs, (function (propertyRef) {
                    var maybeNestedProperty = StateUtils.resolvePropertyById(propertyRef.id, model);
                    var maybePinnedValue = CodegenRules.getNestedPropertyPinnedValue(propertyRef, maybeProperty.id, maybeEvent.id, model);
                    if (maybePinnedValue === undefined) {
                      return ;
                    }
                    if (maybeNestedProperty === undefined) {
                      return ;
                    }
                    var pinnedValue = maybePinnedValue[0];
                    if (PropertyValidationUtils.validatePinnedValue(pinnedValue, maybeNestedProperty)) {
                      return maybeNestedProperty.name + (": " + (printPinnedValue(pinnedValue) + (
                                  maybePinnedValue[1] === /* EventSpecific */0 ? " (on this event)" : " (on all events)"
                                )));
                    }
                    
                  }));
    }
    
  }
  if (maybeProperty === undefined) {
    return /* [] */0;
  }
  if (propertyRef$1 === undefined) {
    return /* [] */0;
  }
  var pinnedValue = propertyRef$1.pinnedValue;
  if (pinnedValue !== undefined && PropertyValidationUtils.validatePinnedValue(pinnedValue, maybeProperty)) {
    return {
            hd: printPinnedValue(pinnedValue) + " (on this event)",
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

exports.valueType = valueType;
exports.printValueType = printValueType;
exports.printValue = printValue;
exports.printPinnedValue = printPinnedValue;
exports.getSystemProps = getSystemProps;
exports.collectNestedProperties = collectNestedProperties;
exports.deduplicateProperties = deduplicateProperties;
exports.getAllPropertiesFromEvents = getAllPropertiesFromEvents;
exports.printPropertyType = printPropertyType;
exports.printPropertyTypeWithoutValidations = printPropertyTypeWithoutValidations;
exports.getPropertyTypes = getPropertyTypes;
exports.getPinnedValues = getPinnedValues;
/* AvoModel Not a pure module */
