// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Caml = require("rescript/lib/js/caml.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("../Hooks.bs.js");
var Toast = require("../Toast.bs.js");
var React = require("react");
var Styles = require("../styles.bs.js");
var $$Window = require("../externals/window.bs.js");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Shortid = require("shortid");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("../../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("../stateUtils.bs.js");
var Fuzzaldrin = require("fuzzaldrin");
var AnalyticsRe = require("../analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var QueryString = require("query-string");
var ReactWindow = require("react-window");
var AppFeatureFlag = require("../AppFeatureFlag.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("../WorkspaceContext.bs.js");
var GlobalSendContext = require("../GlobalSendContext.bs.js");
var Js_null_undefined = require("rescript/lib/js/js_null_undefined.js");
var DiscrepancyContext = require("../DiscrepancyContext.bs.js");
var InspectorViewCells = require("./InspectorViewCells.bs.js");
var InspectorViewModel = require("./InspectorViewModel.bs.js");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");
var SendActionsContext = require("../SendActionsContext.bs.js");
var InspectorViewConfig = require("./InspectorViewConfig.bs.js");
var InspectorViewSelectors = require("./InspectorViewSelectors.bs.js");
var InspectorViewContextSidebar = require("./InspectorViewContextSidebar.bs.js");
var ReactVirtualizedAutoSizer = require("react-virtualized-auto-sizer").default;
var InspectorToTrackingPlanHelpers = require("./InspectorToTrackingPlanHelpers.bs.js");

var cmp = Caml_obj.caml_compare;

var ExpandedEventsSet = Belt_Id.MakeComparable({
      cmp: cmp
    });

function isRowExpanded(row, isExpanded) {
  if (typeof row !== "object") {
    return false;
  }
  var variant = row.NAME;
  if (variant === "Error") {
    return false;
  } else if (variant === "Event") {
    return Belt_Option.isSome(Belt_SetString.get(isExpanded, row.VAL.eventName));
  } else {
    return Belt_Option.isSome(Belt_SetString.get(isExpanded, row.VAL[0].eventName));
  }
}

function makeCellKey(a, b, c) {
  return a + "-" + String(b) + "-" + String(c);
}

function cellMemo(component) {
  return React.memo(component, (function (before, after) {
                return makeCellKey(before.requestId, before.rowIndex, before.columnIndex) === makeCellKey(after.requestId, after.rowIndex, after.columnIndex);
              }));
}

var make = cellMemo(function (Props) {
      var environment = Props.environment;
      var rowIndex = Props.rowIndex;
      var columnIndex = Props.columnIndex;
      var style = Props.style;
      var data = Props.data;
      var onClick = Props.onClick;
      var onAddEventToTrackingPlan = Props.onAddEventToTrackingPlan;
      var onAddPropertyToTrackingPlan = Props.onAddPropertyToTrackingPlan;
      var model = Props.model;
      var cell = InspectorViewSelectors.getCell(data.list, data.sources, rowIndex, columnIndex);
      var row = Belt_Option.getWithDefault(Belt_Array.get(data.list, rowIndex), "Loading");
      var isExpanded = isRowExpanded(row, data.isExpanded);
      var match = data.activeCell;
      var isActiveCell;
      if (match !== undefined) {
        var column = match.column;
        var propertyName = match.propertyName;
        var eventName = match.eventName;
        isActiveCell = (function ($$event, maybePropertyName, overlap) {
            if ((column === columnIndex || column === (columnIndex + overlap | 0)) && eventName === $$event.eventName) {
              return Caml_obj.caml_equal(propertyName, maybePropertyName);
            } else {
              return false;
            }
          });
      } else {
        isActiveCell = (function (param, param$1, param$2) {
            return false;
          });
      }
      var tmp;
      var exit = 0;
      if (typeof cell === "object") {
        var variant = cell.NAME;
        if (variant === "EventSourceVolume") {
          var match$1 = cell.VAL;
          var $$event = match$1[0];
          tmp = React.createElement(InspectorViewCells.EventSourceVolumeCell.make, {
                source: match$1[1],
                isActiveCell: Curry._3(isActiveCell, $$event, undefined, 1),
                onClick: (function (param) {
                    return Curry._3(onClick, columnIndex, $$event, undefined);
                  })
              });
        } else if (variant === "EventSourceIssues") {
          var match$2 = cell.VAL;
          var $$event$1 = match$2[0];
          tmp = React.createElement(InspectorViewCells.EventSourceIssuesCell.make, {
                event: $$event$1,
                source: match$2[1],
                isActiveCell: Curry._3(isActiveCell, $$event$1, undefined, -1),
                onClick: (function (param) {
                    return Curry._3(onClick, columnIndex, $$event$1, undefined);
                  })
              });
        } else if (variant === "Error") {
          if (columnIndex === 0) {
            var errorType = cell.VAL[1];
            console.log("ERROR", errorType);
            var tmp$1;
            var exit$1 = 0;
            if (errorType !== undefined && errorType) {
              var env = InspectorViewModel.environmentToJs(environment);
              tmp$1 = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.grapeError,
                        children: null
                      }, "Inspector for env: " + env + " has not been initialized.", React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Regular",
                            color: Styles.Color.grey90,
                            children: "To Initialize, start sending events to the inspector API on " + env
                          })));
            } else {
              exit$1 = 2;
            }
            if (exit$1 === 2) {
              tmp$1 = React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.grapeError,
                    children: "Could not complete loading events."
                  });
            }
            tmp = React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("flexStart"),
                            tl: {
                              hd: Css.height(Css.px(InspectorViewConfig.eventRowHeight)),
                              tl: {
                                hd: Css.paddingLeft(columnIndex === 0 ? Css.px(40) : Css.px(0)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, tmp$1);
          } else {
            tmp = null;
          }
        } else if (variant === "EventVolume") {
          var $$event$2 = cell.VAL;
          tmp = React.createElement(InspectorViewCells.EventVolumeCell.make, {
                event: $$event$2,
                isActiveCell: Curry._3(isActiveCell, $$event$2, undefined, 1),
                onClick: (function (param) {
                    return Curry._3(onClick, columnIndex, $$event$2, undefined);
                  })
              });
        } else if (variant === "EventName") {
          var $$event$3 = cell.VAL;
          tmp = React.createElement(InspectorViewCells.EventNameCell.make, {
                event: $$event$3,
                isExpanded: isExpanded,
                isActive: Belt_Option.mapWithDefault(data.activeCell, false, (function (param) {
                        return param.eventName === $$event$3.eventName;
                      })),
                isActiveCell: Curry._3(isActiveCell, $$event$3, undefined, 0),
                onClick: (function (param) {
                    return Curry._3(onClick, columnIndex, $$event$3, undefined);
                  }),
                onAddToTrackingPlan: (function (param) {
                    return Curry._1(onAddEventToTrackingPlan, $$event$3);
                  }),
                model: model
              });
        } else if (variant === "EventIssues") {
          var $$event$4 = cell.VAL;
          tmp = React.createElement(InspectorViewCells.EventIssuesCell.make, {
                event: $$event$4,
                isActiveCell: Curry._3(isActiveCell, $$event$4, undefined, -1),
                onClick: (function (param) {
                    return Curry._3(onClick, columnIndex, $$event$4, undefined);
                  })
              });
        } else {
          exit = 1;
        }
      } else if (cell === "Loading") {
        tmp = React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("flexStart"),
                        tl: {
                          hd: Css.height(Css.px(InspectorViewConfig.eventRowHeight)),
                          tl: {
                            hd: Css.paddingLeft(columnIndex === 0 ? Css.px(40) : Css.px(0)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, Belt_List.concat(Styles.shimmer, {
                            hd: Css.width(Css.px(columnIndex === 0 ? 250 : 50)),
                            tl: {
                              hd: Css.height(Css.px(5)),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: /* [] */0
                              }
                            }
                          }))
                }));
      } else if (cell === "NotFound") {
        tmp = null;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        if (isExpanded) {
          var variant$1 = cell.NAME;
          if (variant$1 === "PropertySourceIssues") {
            var match$3 = cell.VAL;
            var property = match$3[1];
            var $$event$5 = match$3[0];
            tmp = React.createElement(InspectorViewCells.PropertySourceIssuesCell.make, {
                  event: $$event$5,
                  isActiveCell: Curry._3(isActiveCell, $$event$5, property.propertyName, -1),
                  source: match$3[2],
                  onClick: (function (param) {
                      return Curry._3(onClick, columnIndex, $$event$5, property);
                    })
                });
          } else if (variant$1 === "PropertyName") {
            var match$4 = cell.VAL;
            var property$1 = match$4[1];
            var $$event$6 = match$4[0];
            tmp = React.createElement(InspectorViewCells.PropertyNameCell.make, {
                  event: $$event$6,
                  property: property$1,
                  isActiveCell: Curry._3(isActiveCell, $$event$6, property$1.propertyName, 0),
                  onClick: (function (param) {
                      return Curry._3(onClick, columnIndex, $$event$6, property$1);
                    }),
                  onAddToTrackingPlan: (function (param) {
                      return Curry._2(onAddPropertyToTrackingPlan, $$event$6, property$1);
                    }),
                  model: model
                });
          } else if (variant$1 === "PropertyVolume") {
            var match$5 = cell.VAL;
            var property$2 = match$5[1];
            var $$event$7 = match$5[0];
            tmp = React.createElement(InspectorViewCells.PropertyVolumeCell.make, {
                  event: $$event$7,
                  property: property$2,
                  isActiveCell: Curry._3(isActiveCell, $$event$7, property$2.propertyName, 1),
                  onClick: (function (param) {
                      return Curry._3(onClick, columnIndex, $$event$7, property$2);
                    })
                });
          } else if (variant$1 === "PropertySourceVolume") {
            var match$6 = cell.VAL;
            var property$3 = match$6[1];
            var $$event$8 = match$6[0];
            tmp = React.createElement(InspectorViewCells.PropertySourceVolumeCell.make, {
                  event: $$event$8,
                  isActiveCell: Curry._3(isActiveCell, $$event$8, property$3.propertyName, 1),
                  source: match$6[2],
                  onClick: (function (param) {
                      return Curry._3(onClick, columnIndex, $$event$8, property$3);
                    })
                });
          } else {
            var match$7 = cell.VAL;
            var property$4 = match$7[1];
            var $$event$9 = match$7[0];
            tmp = React.createElement(InspectorViewCells.PropertyIssuesCell.make, {
                  property: property$4,
                  isActiveCell: Curry._3(isActiveCell, $$event$9, property$4.propertyName, -1),
                  onClick: (function (param) {
                      return Curry._3(onClick, columnIndex, $$event$9, property$4);
                    })
                });
          }
        } else {
          tmp = null;
        }
      }
      return React.createElement("div", {
                  style: style
                }, tmp);
    });

var make$1 = React.memo(make, (function (prim0, prim1) {
        return ReactWindow.areEqual(prim0, prim1);
      }));

var Cell = {
  make: make$1
};

var orderLabelBase = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.textTransform("uppercase"),
              tl: {
                hd: Css.textAlign("left"),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.semi),
                  tl: {
                    hd: Css.padding(Css.px(0)),
                    tl: {
                      hd: Css.hover({
                            hd: Css.color(Styles.Color.blueSecondary),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexShrink(0.0),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var activeOrderLabel = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.blue),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.bold),
        tl: /* [] */0
      }
    });

function InspectorViewEventsList$LabelRowButton(Props) {
  var smallOpt = Props.small;
  var label = Props.label;
  var orderBy = Props.orderBy;
  var ordering = Props.ordering;
  var customWidth = Props.customWidth;
  var customPaddingLeftOpt = Props.customPaddingLeft;
  var setOrdering = Props.setOrdering;
  var orderBySourceId = Props.orderBySourceId;
  var small = smallOpt !== undefined ? smallOpt : false;
  var customPaddingLeft = customPaddingLeftOpt !== undefined ? customPaddingLeftOpt : 8;
  var selectedOrder = ordering[0];
  var isSelected = Caml_obj.caml_equal(orderBy, ordering[1]) && Caml_obj.caml_equal(orderBySourceId, ordering[2]);
  return React.createElement("button", {
              className: Curry._1(Css.merge, {
                    hd: orderLabelBase,
                    tl: {
                      hd: Curry._1(Css.style, {
                            hd: Css.width(Css.px(customWidth)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(small ? 0 : customPaddingLeft)),
                              tl: {
                                hd: Css.fontSize(small ? Css.px(9) : Styles.FontSize.tiny),
                                tl: {
                                  hd: Css.color(small ? Styles.Color.grey50 : Styles.Color.grey90),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }),
                      tl: {
                        hd: isSelected ? activeOrderLabel : Curry._1(Css.style, /* [] */0),
                        tl: /* [] */0
                      }
                    }
                  }),
              onClick: (function (param) {
                  return Curry._1(setOrdering, (function (param) {
                                return [
                                        isSelected ? (
                                            selectedOrder === "Descending" ? "Ascending" : "Descending"
                                          ) : (
                                            orderBy === "EventName" ? "Ascending" : "Descending"
                                          ),
                                        orderBy,
                                        orderBySourceId
                                      ];
                              }));
                })
            }, label, isSelected ? (
                selectedOrder === "Descending" ? " ↑" : " ↓"
              ) : null);
}

var LabelRowButton = {
  orderLabelBase: orderLabelBase,
  activeOrderLabel: activeOrderLabel,
  make: InspectorViewEventsList$LabelRowButton
};

function InspectorViewEventsList$LabelRow(Props) {
  var rowHeight = Props.rowHeight;
  var getColumnWidth = Props.getColumnWidth;
  var sources = Props.sources;
  var sourceIds = Props.sourceIds;
  var ordering = Props.ordering;
  var setOrdering = Props.setOrdering;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("sticky"),
                    tl: {
                      hd: Css.top(Css.px(0)),
                      tl: {
                        hd: Css.zIndex(2),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.tiny),
                                  tl: {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: {
                                      hd: Css.padding2(Css.px(10), Css.px(0)),
                                      tl: {
                                        hd: Css.height(Css.px(rowHeight)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(InspectorViewEventsList$LabelRowButton, {
                  label: "Event Name",
                  orderBy: "EventName",
                  ordering: ordering,
                  customWidth: Curry._1(getColumnWidth, 0),
                  customPaddingLeft: 40,
                  setOrdering: setOrdering
                }), React.createElement(InspectorViewEventsList$LabelRowButton, {
                  label: "Event Volume",
                  orderBy: "EventVolume",
                  ordering: ordering,
                  customWidth: Curry._1(getColumnWidth, 1),
                  setOrdering: setOrdering
                }), React.createElement(InspectorViewEventsList$LabelRowButton, {
                  label: "Issues",
                  orderBy: "Issues",
                  ordering: ordering,
                  customWidth: Curry._1(getColumnWidth, 2),
                  setOrdering: setOrdering
                }), Belt_Array.mapWithIndexU(sourceIds, (function (index, sourceId) {
                    var volumeCellWidth = Curry._1(getColumnWidth, InspectorViewConfig.fixedColumns + (index << 1) | 0);
                    var issuesCellWidth = Curry._1(getColumnWidth, (InspectorViewConfig.fixedColumns + (index << 1) | 0) + 1 | 0);
                    return React.createElement(React.Fragment, {
                                children: React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.width(Css.px(volumeCellWidth + issuesCellWidth | 0)),
                                            tl: {
                                              hd: Css.paddingLeft(Css.px(8)),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.fontSize(Styles.FontSize.small),
                                                tl: /* [] */0
                                              })
                                        }, Belt_Option.mapWithDefault(Belt_List.getByU(sources, (function (source) {
                                                    return sourceId === source.id;
                                                  })), "Unknown Source", AvoConfig.getSourceName)), React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.paddingTop(Css.px(4)),
                                                  tl: /* [] */0
                                                }
                                              })
                                        }, React.createElement(InspectorViewEventsList$LabelRowButton, {
                                              small: true,
                                              label: "Event Volume",
                                              orderBy: "SourceEventVolume",
                                              ordering: ordering,
                                              customWidth: volumeCellWidth,
                                              setOrdering: setOrdering,
                                              orderBySourceId: sourceId
                                            }), React.createElement(InspectorViewEventsList$LabelRowButton, {
                                              small: true,
                                              label: "Issues",
                                              orderBy: "SourceIssues",
                                              ordering: ordering,
                                              customWidth: issuesCellWidth,
                                              setOrdering: setOrdering,
                                              orderBySourceId: sourceId
                                            }))),
                                key: sourceId
                              });
                  })));
}

var LabelRow = {
  make: InspectorViewEventsList$LabelRow
};

function orderEvents(events, param) {
  var orderBySourceId = param[2];
  var orderBy = param[1];
  var order = param[0];
  var getSourceEventVolume = function ($$event, sourceId) {
    return Belt_Option.mapWithDefault(Belt_Array.getByU($$event.sources, (function (source) {
                      return source.sourceId === sourceId;
                    })), 0, (function (source) {
                  return source.count.current.total;
                }));
  };
  var getSourceIssues = function ($$event, sourceId) {
    return Belt_Option.mapWithDefault(Belt_Array.getByU($$event.sources, (function (source) {
                      return source.sourceId === sourceId;
                    })), 0, (function (source) {
                  return InspectorViewSelectors.countEventIssueTypes([source], $$event);
                }));
  };
  return events.slice().sort(function (a, b) {
              if (order === "Ascending") {
                if (orderBy === "EventVolume") {
                  return Caml.caml_int_compare(a.count.current.total, b.count.current.total);
                }
                if (orderBy !== "EventName") {
                  if (orderBy === "SourceEventVolume") {
                    if (orderBySourceId !== undefined) {
                      return Caml.caml_int_compare(getSourceEventVolume(a, orderBySourceId), getSourceEventVolume(b, orderBySourceId));
                    }
                    
                  } else {
                    if (orderBy === "Issues") {
                      return Caml.caml_int_compare(InspectorViewSelectors.countEventIssueTypes(a.sources, a), InspectorViewSelectors.countEventIssueTypes(b.sources, b));
                    }
                    if (orderBy === "SourceIssues" && orderBySourceId !== undefined) {
                      return Caml.caml_int_compare(getSourceIssues(a, orderBySourceId), getSourceIssues(b, orderBySourceId));
                    }
                    
                  }
                }
                
              } else if (order === "Descending") {
                if (orderBy === "EventVolume") {
                  return Caml.caml_int_compare(b.count.current.total, a.count.current.total);
                }
                if (orderBy === "EventName") {
                  return Caml.caml_string_compare(b.eventName.toLowerCase(), a.eventName.toLowerCase());
                }
                if (orderBy === "SourceEventVolume") {
                  if (orderBySourceId !== undefined) {
                    return Caml.caml_int_compare(getSourceEventVolume(b, orderBySourceId), getSourceEventVolume(a, orderBySourceId));
                  }
                  
                } else {
                  if (orderBy === "Issues") {
                    return Caml.caml_int_compare(InspectorViewSelectors.countEventIssueTypes(b.sources, b), InspectorViewSelectors.countEventIssueTypes(a.sources, a));
                  }
                  if (orderBy === "SourceIssues" && orderBySourceId !== undefined) {
                    return Caml.caml_int_compare(getSourceIssues(b, orderBySourceId), getSourceIssues(a, orderBySourceId));
                  }
                  
                }
              }
              return Caml.caml_string_compare(a.eventName, b.eventName);
            });
}

function filterEvents(events, filter) {
  var isQuoted = filter.startsWith("\"") && filter.endsWith("\"");
  if (isQuoted) {
    return Belt_Array.keepU(events, (function ($$event) {
                  return "\"" + ($$event.eventName + "\"") === filter;
                }));
  }
  if (filter === "") {
    return events;
  }
  var lowerCaseFilter = filter.toLowerCase();
  return Belt_Array.keepU(events, (function ($$event) {
                if (Fuzzaldrin.score($$event.eventName, filter) > 0.1) {
                  return true;
                } else {
                  return $$event.eventName.toLowerCase().includes(lowerCaseFilter);
                }
              }));
}

function buildList(events, loadingStatus, expandedEvents) {
  var tmp;
  if (typeof loadingStatus === "object") {
    var variant = loadingStatus.NAME;
    if (variant === "loading" || variant === "inProgress") {
      tmp = ["Loading"];
    } else if (variant === "error") {
      var match = loadingStatus.VAL;
      tmp = [{
          NAME: "Error",
          VAL: [
            match[0],
            match[1],
            match[2]
          ]
        }];
    } else {
      tmp = [];
    }
  } else {
    tmp = loadingStatus === "initial" ? ["Loading"] : [];
  }
  return Belt_Array.concat(Belt_Array.concatMany(Belt_Array.mapU(events, (function ($$event) {
                        return Belt_Array.concat([{
                                      NAME: "Event",
                                      VAL: $$event
                                    }], Belt_SetString.has(expandedEvents, $$event.eventName) ? Belt_Array.mapU($$event.properties, (function (property) {
                                            return {
                                                    NAME: "Property",
                                                    VAL: [
                                                      $$event,
                                                      property
                                                    ]
                                                  };
                                          })) : []);
                      }))), tmp);
}

function getColumnWidth(columnIndex) {
  if (columnIndex > 0) {
    if (columnIndex >= 3) {
      return 90;
    } else {
      return 120;
    }
  } else if (columnIndex >= 0) {
    return 400;
  } else {
    return 90;
  }
}

function getParsedUrlQuery(param) {
  return QueryString.parse(decodeURI(window.location.search.replace("?", "")));
}

function updateUrlQueryEffect(updates) {
  return $$Window.$$History.replaceStateSilent("?" + encodeURI(QueryString.stringify(Object.assign(Object.assign({}, getParsedUrlQuery(undefined)), updates))));
}

function useQueryOrder(param) {
  var defaultOrder = Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_option.nullable_to_opt(getParsedUrlQuery(undefined).order), InspectorViewModel.orderFromJs), "Ascending");
  var defaultOrderBy = Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_option.nullable_to_opt(getParsedUrlQuery(undefined).orderBy), InspectorViewModel.orderByFromJs), "EventName");
  var defaultSourceId = getParsedUrlQuery(undefined).sourceId;
  var defaultSourceId$1 = (defaultSourceId == null) ? undefined : Caml_option.some(defaultSourceId);
  var match = React.useState(function () {
        return [
                defaultOrder,
                defaultOrderBy,
                defaultSourceId$1
              ];
      });
  var match$1 = match[0];
  var sourceId = match$1[2];
  var orderBy = match$1[1];
  var order = match$1[0];
  React.useEffect((function () {
          updateUrlQueryEffect({
                order: InspectorViewModel.orderToJs(order),
                orderBy: InspectorViewModel.orderByToJs(orderBy),
                sourceId: Js_null_undefined.fromOption(sourceId)
              });
          
        }), [
        order,
        orderBy,
        sourceId
      ]);
  return [
          [
            order,
            orderBy,
            sourceId
          ],
          match[1]
        ];
}

function stringifyActiveCell(x) {
  if (x === undefined) {
    return ;
  }
  var propName = x.propertyName;
  var eventName = x.eventName;
  if (propName !== undefined) {
    return eventName + ("," + (propName + ("," + String(x.column))));
  } else {
    return eventName + (",," + String(x.column));
  }
}

function useActiveCellQuery(param) {
  var stringToActiveCell = function (activeCellString) {
    if (activeCellString === undefined) {
      return ;
    }
    var match = activeCellString.split(",");
    if (match.length !== 3) {
      return ;
    }
    var eventName = match[0];
    var propName = match[1];
    var y = match[2];
    if (!Number.isNaN(Number(y))) {
      return {
              eventName: eventName,
              propertyName: propName === "" ? undefined : propName,
              column: Number(y) | 0
            };
    }
    
  };
  return Hooks.useUrlQueryParam("selected", stringToActiveCell, stringifyActiveCell);
}

function inspectorViewModelOrderByToAnalyticsInspectorFilterTypeUpdated(orderBy) {
  if (orderBy === "EventName") {
    return "OrderByEventName";
  } else if (orderBy === "SourceEventVolume") {
    return "OrderBySourceEventVolume";
  } else if (orderBy === "Issues") {
    return "OrderByIssues";
  } else if (orderBy === "SourceIssues") {
    return "OrderBySourceIssues";
  } else {
    return "OrderByEventVolume";
  }
}

function currentInspectorFilters(model, order, orderBy, sourceId, eventNameFilter, timeWindow, environment) {
  return {
          inspectorEventNameFilter: eventNameFilter === "" ? undefined : eventNameFilter,
          inspectorOrder: order,
          inspectorColumnOrderedBy: orderBy,
          inspectorSourceIdOrderedBy: sourceId,
          inspectorTimeWindow: timeWindow,
          inspectorEnvironment: environment,
          inspectorSourceNameOrderedBy: Belt_Option.map(sourceId, (function (sourceId) {
                  return AvoConfig.getSourceNameById(model, sourceId);
                }))
        };
}

function selectedCellIndexToAnalyticsInspectorColumn(selectedCellIndex) {
  switch (selectedCellIndex) {
    case 0 :
        return "EventName";
    case 1 :
        return "EventVolume";
    case 2 :
        return "Issues";
    default:
      if (selectedCellIndex % 2 === 0) {
        return "SourceEventVolume";
      } else {
        return "SourceIssues";
      }
  }
}

function currentInspectorTablePosition(activeCell, sourceIds, sources, numPropertiesNotFoundOnEventInTrackingPlan) {
  return {
          inspectorEventNameSelected: Belt_Option.map(activeCell, (function (cell) {
                  return cell.eventName;
                })),
          inspectorColumnSelected: Belt_Option.map(activeCell, (function (cell) {
                  return selectedCellIndexToAnalyticsInspectorColumn(cell.column);
                })),
          inspectorPropertyNameSelected: Belt_Option.flatMap(activeCell, (function (cell) {
                  return cell.propertyName;
                })),
          inspectorSourceIdSelected: Belt_Option.flatMap(activeCell, (function (cell) {
                  var sourceIndex = (cell.column - InspectorViewConfig.fixedColumns | 0) / 2 | 0;
                  return Belt_Array.get(sourceIds, sourceIndex);
                })),
          inspectorSourceNameSelected: Belt_Option.flatMap(activeCell, (function (cell) {
                  var sourceIndex = (cell.column - InspectorViewConfig.fixedColumns | 0) / 2 | 0;
                  return Belt_Option.flatMap(Belt_Array.get(sourceIds, sourceIndex), (function (sourceId) {
                                return AvoConfig.sourceIdToName(sourceId, sources);
                              }));
                })),
          numPropertiesNotFoundOnEventInTrackingPlan: numPropertiesNotFoundOnEventInTrackingPlan
        };
}

function inspectorFilterTypeUpdated(param, param$1) {
  if (Caml_obj.caml_notequal(param$1[0], param[0])) {
    return "SearchForEvent";
  }
  var orderBy = param$1[1];
  if (orderBy !== param[1] || Caml_obj.caml_notequal(param$1[2], param[2])) {
    return inspectorViewModelOrderByToAnalyticsInspectorFilterTypeUpdated(orderBy);
  } else if (Caml_obj.caml_notequal(param$1[3], param[3])) {
    return "Environment";
  } else if (Caml_obj.caml_notequal(param$1[4], param[4])) {
    return "TimeWindow";
  } else {
    return ;
  }
}

function getNumPropertiesNotFoundOnEventInTrackingPlan(activeInspectorEvent, model) {
  if (activeInspectorEvent === undefined) {
    return ;
  }
  var modelEvent = StateUtils.getEventByName(activeInspectorEvent.eventName, model);
  return Belt_Option.map(modelEvent, (function ($$event) {
                var propertiesSet = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.keepMap($$event.properties, (function (property) {
                                if (property.TAG === /* PropertyRef */0) {
                                  return Belt_Option.map(StateUtils.getModelPropertyById(property._0.id, model), (function (property) {
                                                return [
                                                        property.name,
                                                        property
                                                      ];
                                              }));
                                }
                                var p = property._0;
                                return [
                                        p.name,
                                        p
                                      ];
                              }))));
                return Belt_Array.keepU(activeInspectorEvent.properties, (function (inspectorProp) {
                              return Belt_MapString.get(propertiesSet, inspectorProp.propertyName) === undefined;
                            })).length;
              }));
}

function registerHooksForTracking(model, order, orderBy, sourceId, activeCell, activeInspectorEvent, filterByEventName, timeWindow, environment, events, debounceFilterByEventName, sourceIds, sources, initialShareId, generatedShareId, utmMedium, utmCampaign) {
  var match = Hooks.usePrevious2([
        events,
        sourceIds
      ]);
  var prevSourceIds = match[1];
  var prevEvents = match[0];
  var schemaGroup = SchemaGroupContext.use(undefined);
  var numPropertiesNotFoundOnEventInTrackingPlan = getNumPropertiesNotFoundOnEventInTrackingPlan(activeInspectorEvent, model);
  React.useEffect((function () {
          var justLoadedLastArrayOfTwoAndBothAreNotEmpty = Caml_obj.caml_equal(prevEvents, []) && Caml_obj.caml_notequal(events, []) && Caml_obj.caml_notequal(sourceIds, []) || Caml_obj.caml_equal(prevSourceIds, []) && Caml_obj.caml_notequal(sourceIds, []) && Caml_obj.caml_notequal(events, []);
          var eventsMap = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(model.events, (function ($$event) {
                          return [
                                  $$event.name,
                                  $$event
                                ];
                        }))));
          var numInspectorEventsNotFoundInTrackingPlan = Belt_Array.keepU(events, (function (inspectorEvent) {
                  return Belt_MapString.get(eventsMap, inspectorEvent.eventName) === undefined;
                })).length;
          var proportionOfInspectorEventsNotFoundInTrackingPlan = numInspectorEventsNotFoundInTrackingPlan / events.length;
          if (justLoadedLastArrayOfTwoAndBothAreNotEmpty) {
            AnalyticsRe.inspectorEventsViewed(currentInspectorFilters(model, order, orderBy, sourceId, filterByEventName, timeWindow, environment), currentInspectorTablePosition(activeCell, sourceIds, sources, numPropertiesNotFoundOnEventInTrackingPlan), schemaGroup, "EventsList", events.length, initialShareId, generatedShareId, utmMedium, utmCampaign, numInspectorEventsNotFoundInTrackingPlan, proportionOfInspectorEventsNotFoundInTrackingPlan, schemaGroup.branchId, schemaGroup.schemaId);
            if (environment === "Development") {
              AnalyticsRe.updateNumInspectorDevelopmentSources(schemaGroup.schemaId, sourceIds.length);
            } else if (environment === "Production") {
              AnalyticsRe.updateNumInspectorProductionSources(schemaGroup.schemaId, sourceIds.length);
            } else {
              AnalyticsRe.updateNumInspectorStagingSources(schemaGroup.schemaId, sourceIds.length);
            }
          }
          
        }), [
        events,
        sourceIds
      ]);
  var match$1 = Hooks.usePrevious5([
        debounceFilterByEventName,
        orderBy,
        order,
        environment,
        timeWindow
      ]);
  var prevTimeWindow = match$1[4];
  var prevEnvironment = match$1[3];
  var prevOrder = match$1[2];
  var prevOrderBy = match$1[1];
  var prevDebounceFilterByEventName = match$1[0];
  React.useEffect((function () {
          var maybeInspectorFilterTypeUpdated = inspectorFilterTypeUpdated([
                prevDebounceFilterByEventName,
                prevOrderBy,
                prevOrder,
                prevEnvironment,
                prevTimeWindow
              ], [
                debounceFilterByEventName,
                orderBy,
                order,
                environment,
                timeWindow
              ]);
          if (maybeInspectorFilterTypeUpdated !== undefined) {
            AnalyticsRe.inspectorFilterUpdated(currentInspectorFilters(model, order, orderBy, sourceId, filterByEventName, timeWindow, environment), schemaGroup, maybeInspectorFilterTypeUpdated, schemaGroup.branchId, schemaGroup.schemaId);
          }
          
        }), [
        debounceFilterByEventName,
        orderBy,
        order,
        environment,
        timeWindow
      ]);
  var prevActiveCell = Hooks.usePrevious1(activeCell);
  React.useEffect((function () {
          if (Caml_obj.caml_notequal(activeCell, prevActiveCell)) {
            AnalyticsRe.inspectorEventsNavigated(currentInspectorFilters(model, order, orderBy, sourceId, filterByEventName, timeWindow, environment), currentInspectorTablePosition(activeCell, sourceIds, sources, numPropertiesNotFoundOnEventInTrackingPlan), schemaGroup, events.length, schemaGroup.branchId, schemaGroup.schemaId);
          }
          
        }), [activeCell]);
  
}

function readShareIdFromQueryAndGenerateIfMissing(param) {
  var initialShareIdRef = React.useRef(Js_dict.get(Hooks.getQueryParams(undefined), "shareId"));
  var initialShareId = initialShareIdRef.current;
  var generatedShareId;
  if (initialShareId !== undefined) {
    generatedShareId = undefined;
  } else {
    var generatedShareId$1 = Shortid();
    var params = Hooks.getQueryParams(undefined);
    params["shareId"] = generatedShareId$1;
    $$Window.$$History.replaceStateSilent("?" + encodeURI(QueryString.stringify(params)));
    generatedShareId = generatedShareId$1;
  }
  return [
          initialShareId,
          generatedShareId
        ];
}

function InspectorViewEventsList(Props) {
  var model = Props.model;
  var timeWindow = Props.timeWindow;
  var environment = Props.environment;
  var enrichedEvents = Props.enrichedEvents;
  var loadingStatus = Props.loadingStatus;
  var filterByEventName = Props.filterByEventName;
  var match = useQueryOrder(undefined);
  var setOrdering = match[1];
  var ordering = match[0];
  var match$1 = readShareIdFromQueryAndGenerateIfMissing(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var match$2 = React.useContext(DiscrepancyContext.context);
  var expectedPropertyCase = match$2.expectedPropertyCase;
  var expectedEventCase = match$2.expectedEventCase;
  var addToast = Toast.useAddToast(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var match$3 = Hooks.useUrlQueryParam("utm_medium", (function (a) {
          return a;
        }), (function (a) {
          return a;
        }));
  var match$4 = Hooks.useUrlQueryParam("utm_campaign", (function (a) {
          return a;
        }), (function (a) {
          return a;
        }));
  var eventsSet = Belt_MapString.fromArray(Belt_Array.map(enrichedEvents, (function ($$event) {
              return [
                      $$event.eventName,
                      $$event
                    ];
            })));
  var debounceFilterByEventName = Hooks.useDebounced((function (param) {
          return filterByEventName;
        }), 500);
  var sourceId = ordering[2];
  var orderBy = ordering[1];
  var order = ordering[0];
  var orderedEnrichedEvents = React.useMemo((function () {
          return orderEvents(enrichedEvents, ordering);
        }), [
        enrichedEvents,
        enrichedEvents,
        order,
        orderBy,
        sourceId
      ]);
  var filteredOrderedEnrichedEvents = React.useMemo((function () {
          return filterEvents(orderedEnrichedEvents, filterByEventName);
        }), [
        orderedEnrichedEvents,
        debounceFilterByEventName
      ]);
  var match$5 = useActiveCellQuery(undefined);
  var setActiveCell = match$5[1];
  var activeCell = match$5[0];
  var match$6 = React.useState(function () {
        if (activeCell !== undefined && activeCell.propertyName !== undefined) {
          return Belt_SetString.fromArray([activeCell.eventName]);
        }
        
      });
  var setIsExpanded = match$6[1];
  var isExpanded = match$6[0];
  var match$7 = React.useState(function () {
        return buildList(filteredOrderedEnrichedEvents, loadingStatus, isExpanded);
      });
  var updateList = match$7[1];
  var list = match$7[0];
  var shouldCreateAsSometimesSent = AppFeatureFlag.useFeatureFlag("InspectorAddToTrackingPlanAsSometimesSent");
  React.useEffect((function () {
          Curry._1(updateList, (function (param) {
                  return buildList(filteredOrderedEnrichedEvents, loadingStatus, isExpanded);
                }));
          
        }), [
        loadingStatus,
        filteredOrderedEnrichedEvents,
        isExpanded
      ]);
  var rowCount = list.length;
  var sourceIds = React.useMemo((function () {
          return Belt_SetString.toArray(Belt_Array.reduceU(Belt_Array.concatMany(Belt_Array.mapU(list, (function (row) {
                                    if (typeof row === "object") {
                                      if (row.NAME === "Event") {
                                        return row.VAL.sources;
                                      } else {
                                        return [];
                                      }
                                    } else {
                                      return [];
                                    }
                                  }))), undefined, (function (uniqueSourceIds, source) {
                            return Belt_SetString.add(uniqueSourceIds, source.sourceId);
                          })));
        }), [list]);
  registerHooksForTracking(model, order, orderBy, sourceId, activeCell, Belt_Option.flatMap(activeCell, (function (cell) {
              return Belt_MapString.get(eventsSet, cell.eventName);
            })), filterByEventName, timeWindow, environment, enrichedEvents, debounceFilterByEventName, sourceIds, model.sources, match$1[0], match$1[1], match$3[0], match$4[0]);
  var columnCount = InspectorViewConfig.fixedColumns + (sourceIds.length << 1) | 0;
  var cellData_activeCell = Belt_Option.map(activeCell, (function (param) {
          return {
                  eventName: param.eventName,
                  propertyName: param.propertyName,
                  column: Math.min(param.column, Math.max(columnCount - 1 | 0, 0))
                };
        }));
  var cellData = {
    activeCell: cellData_activeCell,
    isExpanded: isExpanded,
    list: list,
    sources: sourceIds,
    model: model,
    columnCount: columnCount
  };
  var sidebarCell = React.useMemo((function () {
          return Belt_Option.flatMap(cellData_activeCell, (function (param) {
                        var column = param.column;
                        var propertyName = param.propertyName;
                        var eventName = param.eventName;
                        var row = Belt_Option.map(Belt_Option.flatMap(Belt_Array.getByU(Belt_Array.mapWithIndexU(list, (function (index, row) {
                                            if (typeof row !== "object") {
                                              return ;
                                            }
                                            var variant = row.NAME;
                                            if (variant !== "Property") {
                                              if (variant === "Event") {
                                                return [
                                                        index,
                                                        row.VAL.eventName,
                                                        undefined
                                                      ];
                                              } else {
                                                return ;
                                              }
                                            }
                                            var match = row.VAL;
                                            return [
                                                    index,
                                                    match[0].eventName,
                                                    match[1].propertyName
                                                  ];
                                          })), (function (item) {
                                        if (item !== undefined && item[1] === eventName) {
                                          return Caml_obj.caml_equal(item[2], propertyName);
                                        } else {
                                          return false;
                                        }
                                      })), (function (maybeMaybeRow) {
                                    return maybeMaybeRow;
                                  })), (function (param) {
                                return param[0];
                              }));
                        return Belt_Option.map(row, (function (row) {
                                      return InspectorViewSelectors.getCell(list, sourceIds, row, column);
                                    }));
                      }));
        }), [
        activeCell,
        list,
        sourceIds
      ]);
  var match$8 = React.useState(function () {
        return false;
      });
  var setListIsMounted = match$8[1];
  var listIsMounted = match$8[0];
  var tmp;
  var exit = 0;
  if (typeof loadingStatus === "object" && loadingStatus.NAME !== "loading") {
    var requestId = loadingStatus.VAL[0];
    tmp = React.createElement(ReactVirtualizedAutoSizer, {
          children: (function (size) {
              return React.createElement(ReactWindow.VariableSizeGrid, {
                          width: size.width,
                          height: size.height,
                          columnCount: columnCount,
                          columnWidth: getColumnWidth,
                          rowCount: rowCount,
                          rowHeight: (function (rowIndex) {
                              var row = Belt_Option.getWithDefault(Belt_Array.get(list, rowIndex), "Loading");
                              if (typeof row !== "object") {
                                return InspectorViewConfig.eventRowHeight;
                              }
                              var variant = row.NAME;
                              if (variant === "Error" || variant === "Event") {
                                return InspectorViewConfig.eventRowHeight;
                              } else {
                                return InspectorViewConfig.propertyRowHeight;
                              }
                            }),
                          itemData: cellData,
                          overscanColumnCount: 10,
                          overscanRowCount: 100,
                          ref: (function (domRef) {
                              if (!listIsMounted) {
                                Curry._1(setListIsMounted, (function (param) {
                                        return true;
                                      }));
                                var match = cellData_activeCell;
                                if (match === undefined) {
                                  return ;
                                }
                                var propertyName = match.propertyName;
                                var eventName = match.eventName;
                                var maybeRowIndex = list.findIndex(function (row) {
                                      if (propertyName === undefined) {
                                        if (typeof row === "object" && row.NAME === "Event") {
                                          return row.VAL.eventName === eventName;
                                        } else {
                                          return false;
                                        }
                                      }
                                      if (typeof row !== "object") {
                                        return false;
                                      }
                                      if (row.NAME !== "Property") {
                                        return false;
                                      }
                                      var match = row.VAL;
                                      if (match[0].eventName === eventName) {
                                        return match[1].propertyName === propertyName;
                                      } else {
                                        return false;
                                      }
                                    });
                                if (maybeRowIndex !== -1) {
                                  var columnIndex = match.column;
                                  Belt_Option.map((domRef == null) ? undefined : Caml_option.some(domRef), (function (gridRef) {
                                          gridRef.scrollToItem({
                                                align: "start",
                                                columnIndex: columnIndex,
                                                rowIndex: maybeRowIndex
                                              });
                                          
                                        }));
                                  return ;
                                } else {
                                  return ;
                                }
                              }
                              
                            }),
                          innerElementType: React.forwardRef(function (props, reactRef) {
                                return React.cloneElement(React.createElement("div", {
                                                ref: reactRef,
                                                style: props.style
                                              }, React.createElement(InspectorViewEventsList$LabelRow, {
                                                    rowHeight: 51,
                                                    getColumnWidth: getColumnWidth,
                                                    sources: model.sources,
                                                    sourceIds: sourceIds,
                                                    ordering: ordering,
                                                    setOrdering: setOrdering
                                                  }), React.createElement("div", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.position("relative"),
                                                          tl: /* [] */0
                                                        })
                                                  }, props.children)), []);
                              }),
                          children: (function (cell) {
                              return React.createElement(make$1, {
                                          environment: environment,
                                          rowIndex: cell.rowIndex,
                                          columnIndex: cell.columnIndex,
                                          style: cell.style,
                                          data: cell.data,
                                          onClick: (function (column, $$event, property) {
                                              Curry._1(setActiveCell, (function (param) {
                                                      return {
                                                              eventName: $$event.eventName,
                                                              propertyName: Belt_Option.map(property, (function (property) {
                                                                      return property.propertyName;
                                                                    })),
                                                              column: column
                                                            };
                                                    }));
                                              if (Belt_Option.isNone(property) && column === 0) {
                                                return Curry._1(setIsExpanded, (function (expanded) {
                                                              if (Belt_SetString.has(isExpanded, $$event.eventName)) {
                                                                return Belt_SetString.remove(expanded, $$event.eventName);
                                                              } else {
                                                                return Belt_SetString.add(expanded, $$event.eventName);
                                                              }
                                                            }));
                                              }
                                              
                                            }),
                                          requestId: requestId,
                                          onAddEventToTrackingPlan: (function ($$event) {
                                              return InspectorToTrackingPlanHelpers.$$Event.onCreateEvent(workspace, $$event, model, sendActions, schemaGroup, expectedEventCase, expectedPropertyCase, addToast, globalSend, "InspectorTable", shouldCreateAsSometimesSent);
                                            }),
                                          onAddPropertyToTrackingPlan: (function ($$event, property) {
                                              return InspectorToTrackingPlanHelpers.Property.onCreateProperty($$event, property, model, sendActions, schemaGroup, expectedPropertyCase, addToast, globalSend, "InspectorTable", shouldCreateAsSometimesSent);
                                            }),
                                          model: model
                                        });
                            })
                        });
            })
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.height(Css.px(InspectorViewConfig.eventRowHeight)),
                    tl: {
                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
                      tl: {
                        hd: Css.paddingLeft(Css.px(40)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        }, React.createElement("div", {
              className: Curry._1(Css.style, Belt_List.concat(Styles.shimmer, {
                        hd: Css.width(Css.px(250)),
                        tl: {
                          hd: Css.height(Css.px(5)),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: /* [] */0
                          }
                        }
                      }))
            }));
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.marginTop(Css.px(63)),
                      tl: {
                        hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey30),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.height(Css_Legacy_Core.Calc.$neg(Css.vh(100), Css.px(63))),
                          tl: {
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, tmp), React.createElement(InspectorViewContextSidebar.make, {
                  cell: sidebarCell,
                  model: model
                }));
}

var make$2 = InspectorViewEventsList;

exports.ExpandedEventsSet = ExpandedEventsSet;
exports.isRowExpanded = isRowExpanded;
exports.makeCellKey = makeCellKey;
exports.cellMemo = cellMemo;
exports.Cell = Cell;
exports.LabelRowButton = LabelRowButton;
exports.LabelRow = LabelRow;
exports.orderEvents = orderEvents;
exports.filterEvents = filterEvents;
exports.buildList = buildList;
exports.getColumnWidth = getColumnWidth;
exports.getParsedUrlQuery = getParsedUrlQuery;
exports.updateUrlQueryEffect = updateUrlQueryEffect;
exports.useQueryOrder = useQueryOrder;
exports.stringifyActiveCell = stringifyActiveCell;
exports.useActiveCellQuery = useActiveCellQuery;
exports.inspectorViewModelOrderByToAnalyticsInspectorFilterTypeUpdated = inspectorViewModelOrderByToAnalyticsInspectorFilterTypeUpdated;
exports.currentInspectorFilters = currentInspectorFilters;
exports.selectedCellIndexToAnalyticsInspectorColumn = selectedCellIndexToAnalyticsInspectorColumn;
exports.currentInspectorTablePosition = currentInspectorTablePosition;
exports.inspectorFilterTypeUpdated = inspectorFilterTypeUpdated;
exports.getNumPropertiesNotFoundOnEventInTrackingPlan = getNumPropertiesNotFoundOnEventInTrackingPlan;
exports.registerHooksForTracking = registerHooksForTracking;
exports.readShareIdFromQueryAndGenerateIfMissing = readShareIdFromQueryAndGenerateIfMissing;
exports.make = make$2;
/* ExpandedEventsSet Not a pure module */
