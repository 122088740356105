// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var CmdK = require("./CmdK.bs.js");
var $$Text = require("./Text.bs.js");
var Kbar = require("kbar");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("./Icons.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Portal = require("./Portal.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Shortid = require("shortid");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameUtils = require("../../shared/utils/NameUtils.bs.js");
var Workspace = require("../../model/src/Workspace.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var QuillDelta = require("../../model/src/externals/quillDelta.bs.js");
var TextButton = require("./TextButton.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var ContextMenu = require("./ContextMenu.bs.js");
var NamedBranch = require("./NamedBranch.bs.js");
var QuillDelta$1 = require("quill-delta");
var PlanLightning = require("./PlanLightning.bs.js");
var FramerMotion = require("framer-motion");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var Debounce = require("lodash/debounce");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var TriggerModalImage = require("./TriggerModalImage.bs.js");
var DiscrepancyContext = require("./DiscrepancyContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var TriggerModalSources = require("./TriggerModalSources.bs.js");
var TriggerModalNavigation = require("./TriggerModalNavigation.bs.js");
var TriggerModalDescription = require("./TriggerModalDescription.bs.js");

function TriggerFullscreenModal$Lightning(Props) {
  var color = Props.color;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = AvoLimits.Triggers.computeAvailability(workspace);
  if (match === "Available") {
    return null;
  } else {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css_Legacy_Core.SVG.fill("transparent"),
                      tl: {
                        hd: Css_Legacy_Core.SVG.stroke(color),
                        tl: {
                          hd: Css.marginRight(Css.px(2)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement(PlanLightning.make, {
                    tooltipText: (
                      match === "Unavailable" ? "Available " : "Available during trial and "
                    ) + ("on the " + (Workspace.printPlanName(AvoLimits.Triggers.availableOnPlan(workspace).name) + " plan"))
                  }));
  }
}

var Lightning = {
  make: TriggerFullscreenModal$Lightning
};

var wrapper = Curry._1(Css.style, {
      hd: Css.zIndex(Styles.ZIndex.eventTriggerModal),
      tl: {
        hd: Css.position("absolute"),
        tl: {
          hd: Css.left(Css.px(0)),
          tl: {
            hd: Css.top(Css.px(0)),
            tl: {
              hd: Css.width(Css.vw(100)),
              tl: {
                hd: Css.height(Css.vh(100)),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.grey20),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.justifyContent("flexEnd"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var sidebar = Curry._1(Css.style, {
      hd: Css.width(Css.px(300)),
      tl: {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.backgroundColor(Styles.Color.white),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.flexDirection("column"),
              tl: {
                hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                tl: {
                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(32), undefined, undefined, Css.rgba(0, 0, 0, {
                                NAME: "num",
                                VAL: 0.04
                              }))),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var closeButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.left(Css.px(16)),
                tl: {
                  hd: Css.top(Css.px(16)),
                  tl: {
                    hd: Css.flexShrink(0.0),
                    tl: {
                      hd: Css.borderRadius(Css.px(16)),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.padding2(Css.px(4), Css.px(8)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.grey20),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$default
                                    }, undefined, undefined, "all"),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.backgroundColor(Styles.Color.white),
                                        tl: {
                                          hd: Css.boxShadow(Styles.Shadow.subtle),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function sidebarFooter(withBorder) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.paddingTop(Css.px(15)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(16)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(24)),
                        tl: {
                          hd: Css.paddingRight(Css.px(24)),
                          tl: {
                            hd: Css.borderTop(Css.px(withBorder ? 1 : 0), "solid", Styles.Color.grey30),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Style = {
  wrapper: wrapper,
  sidebar: sidebar,
  closeButton: closeButton,
  sidebarFooter: sidebarFooter
};

function updateDescription(sendActions, eventId, triggerId, description) {
  return Curry.app(sendActions, [
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              [[
                  {
                    NAME: "UpdateTriggerDescription",
                    VAL: [
                      eventId,
                      triggerId,
                      description
                    ]
                  },
                  {
                    eventId: eventId,
                    eventQuery: eventId,
                    triggerId: triggerId,
                    triggerQuery: triggerId
                  }
                ]]
            ]);
}

var debouncedUpdateDescription = Debounce(updateDescription, 500, {
      leading: false,
      trailing: true
    });

function TriggerFullscreenModal(Props) {
  var currentBranch = Props.currentBranch;
  var $$event = Props.event;
  var triggerId = Props.triggerId;
  var modelSources = Props.modelSources;
  var numEvents = Props.numEvents;
  var onClose = Props.onClose;
  var viewerCanEdit = Props.viewerCanEdit;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var availability = AvoLimits.Triggers.computeAvailability(workspace);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var correctCase = NameUtils.isCorrectCase(expectedEventCase, $$event.name);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var match$1 = React.useState(function () {
        if (triggerId === "new") {
          return TrackingPlanModel.emptyTrigger(undefined, undefined, undefined, Shortid());
        }
        
      });
  var setDraft = match$1[1];
  var draft = match$1[0];
  React.useEffect((function () {
          Curry._1(setDraft, (function (draft) {
                  if (draft === undefined && triggerId === "new") {
                    return TrackingPlanModel.emptyTrigger(undefined, undefined, undefined, Shortid());
                  } else {
                    return draft;
                  }
                }));
          
        }), [triggerId]);
  var inDraftMode = draft !== undefined;
  var match$2 = React.useState(function () {
        return "Idle";
      });
  var uploadStatus = match$2[0];
  var currentTriggerIndex = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_Array.getIndexBy($$event.triggers, (function (trigger) {
                            return trigger.id === triggerId;
                          })), 0);
        }), [
        triggerId,
        $$event.triggers
      ]);
  var currentTrigger = inDraftMode ? draft : Belt_Array.get($$event.triggers, currentTriggerIndex);
  var routeToTrigger = React.useCallback((function (triggerId) {
          return Router.Schema.swapTopDrawerItem({
                      NAME: "event",
                      VAL: [
                        $$event.id,
                        undefined,
                        triggerId,
                        false
                      ]
                    });
        }), [
        workspace.id,
        currentBranch,
        $$event.id
      ]);
  var onNewTrigger = function (_event) {
    if (availability === "Unavailable") {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "BillingPrompt",
                    VAL: "AddEventTrigger"
                  }
                });
    }
    var match = Router.getStaticRoute(undefined);
    return Belt_Option.forEach(Belt_List.head(match.drawerItems), (function (drawerItem) {
                  if (typeof drawerItem === "object" && drawerItem.NAME === "event") {
                    return Curry._1(routeToTrigger, "new");
                  }
                  
                }));
  };
  Kbar.useRegisterActions(Belt_Array.concatMany([
            [Curry.app(CmdK.Action.t, [
                    "close_triggers",
                    {
                      TAG: /* RootItem */0,
                      _0: "Close triggers"
                    },
                    1.1,
                    ["escape"],
                    undefined,
                    undefined,
                    (function (param) {
                        if (inDraftMode) {
                          return Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "ConfirmModal",
                                        VAL: [
                                          "Discard changes?",
                                          "Are you sure you want to discard your draft?",
                                          "Discard",
                                          (function (param) {
                                              Curry._1(setDraft, (function (param) {
                                                      
                                                    }));
                                              return Curry._1(onClose, undefined);
                                            }),
                                          (function (param) {
                                              
                                            })
                                        ]
                                      }
                                    });
                        } else {
                          return Curry._1(onClose, undefined);
                        }
                      }),
                    undefined,
                    undefined
                  ])],
            $$event.triggers.length > 1 && currentTriggerIndex !== 0 && !inDraftMode ? [Curry.app(CmdK.Action.t, [
                      "go_to_prev_trigger",
                      {
                        TAG: /* RootItem */0,
                        _0: "Previous Trigger"
                      },
                      1.1,
                      ["arrowleft"],
                      undefined,
                      undefined,
                      (function (param) {
                          return Belt_Option.forEach(Belt_Array.get($$event.triggers, currentTriggerIndex - 1 | 0), (function (param) {
                                        return Curry._1(routeToTrigger, param.id);
                                      }));
                        }),
                      undefined,
                      undefined
                    ])] : [],
            $$event.triggers.length > 1 && currentTriggerIndex !== ($$event.triggers.length - 1 | 0) && !inDraftMode ? [Curry.app(CmdK.Action.t, [
                      "go_to_next_trigger",
                      {
                        TAG: /* RootItem */0,
                        _0: "Next Trigger"
                      },
                      1.1,
                      ["arrowright"],
                      undefined,
                      undefined,
                      (function (param) {
                          return Belt_Option.forEach(Belt_Array.get($$event.triggers, currentTriggerIndex + 1 | 0), (function (param) {
                                        return Curry._1(routeToTrigger, param.id);
                                      }));
                        }),
                      undefined,
                      undefined
                    ])] : []
          ]), [
        inDraftMode,
        currentTriggerIndex,
        $$event.triggers.length
      ]);
  var onCreate = function (createAnotherOpt, trigger) {
    var createAnother = createAnotherOpt !== undefined ? createAnotherOpt : false;
    Curry.app(sendActions, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (branch) {
              AnalyticsRe.eventUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.source("N/A", "N/A"), $$event.id, $$event.name, "AddEventTrigger", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, numEvents, undefined, undefined, undefined, undefined, "Event", $$event.triggers.length + 1 | 0, correctCase, expectedEventCase, $$event.id, schemaGroup.schemaId, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
              var ids = trigger.sources;
              var match = trigger.content;
              var tmp;
              tmp = match !== undefined && match.TAG === /* Image */0 ? "Image" : "None";
              AnalyticsRe.eventTriggerCreated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), $$event.id, $$event.name, ids ? ids._0.length : 0, tmp, NamedBranch.getId(branch), schemaGroup.schemaId);
              if (createAnother) {
                return AnalyticsRe.eventTriggerInitiated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), $$event.id, $$event.name, $$event.triggers.length + 1 | 0, NamedBranch.getId(branch), schemaGroup.schemaId);
              }
              
            }),
          undefined,
          [[
              {
                NAME: "CreateEventTrigger",
                VAL: [
                  $$event.id,
                  trigger.id,
                  trigger.sources,
                  trigger.description,
                  trigger.content
                ]
              },
              {
                eventId: $$event.id,
                eventQuery: $$event.id,
                triggerId: trigger.id,
                triggerQuery: trigger.id
              }
            ]]
        ]);
    Curry._1(setDraft, (function (param) {
            if (createAnother) {
              return TrackingPlanModel.emptyTrigger(undefined, undefined, undefined, Shortid());
            }
            
          }));
    return Curry._1(routeToTrigger, createAnother ? "new" : trigger.id);
  };
  var onRemove = function (trigger) {
    return Curry._1(globalSend, {
                TAG: /* OpenModal */4,
                _0: {
                  NAME: "ConfirmModal",
                  VAL: [
                    "Remove this trigger?",
                    "Are you sure you want to remove this trigger?",
                    "Remove",
                    (function (param) {
                        Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              (function (branch) {
                                  return AnalyticsRe.eventUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.source("N/A", "N/A"), $$event.id, $$event.name, "RemoveEventTrigger", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, numEvents, undefined, undefined, undefined, undefined, "Event", $$event.triggers.length - 1 | 0, correctCase, expectedEventCase, $$event.id, schemaGroup.schemaId, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
                                }),
                              undefined,
                              [[
                                  {
                                    NAME: "RemoveEventTrigger",
                                    VAL: [
                                      $$event.id,
                                      trigger.id
                                    ]
                                  },
                                  {
                                    eventId: $$event.id,
                                    eventQuery: $$event.id,
                                    triggerId: trigger.id,
                                    triggerQuery: trigger.id
                                  }
                                ]]
                            ]);
                        var newTriggers = Belt_Array.keep($$event.triggers, (function (param) {
                                return param.id !== trigger.id;
                              }));
                        var previousTriggerIndex = 0 > currentTriggerIndex ? 0 : currentTriggerIndex;
                        var match = Belt_Array.get(newTriggers, previousTriggerIndex);
                        if (match !== undefined) {
                          return Curry._1(routeToTrigger, match.id);
                        } else {
                          return Curry._1(onClose, undefined);
                        }
                      }),
                    (function (param) {
                        
                      })
                  ]
                }
              });
  };
  var onUpdateDescription = function (description) {
    return Belt_Option.forEach(currentTrigger, (function (currentTrigger) {
                  if (inDraftMode) {
                    return Curry._1(setDraft, (function (draft) {
                                  return Belt_Option.map(draft, (function (draft) {
                                                return {
                                                        id: draft.id,
                                                        sources: draft.sources,
                                                        description: description,
                                                        content: draft.content
                                                      };
                                              }));
                                }));
                  } else {
                    return debouncedUpdateDescription(sendActions, $$event.id, currentTrigger.id, description);
                  }
                }));
  };
  var onUpdateSource = function (sourceId, newValue) {
    return Belt_Option.forEach(currentTrigger, (function (currentTrigger) {
                  if (inDraftMode) {
                    return Curry._1(setDraft, (function (draft) {
                                  var match = currentTrigger.sources;
                                  var updatedSources;
                                  if (match) {
                                    var sourceIds = match._0;
                                    if (newValue) {
                                      updatedSources = /* SourceIds */{
                                        _0: Belt_Array.concat(sourceIds, [sourceId])
                                      };
                                    } else {
                                      var ids = Belt_Array.keep(sourceIds, (function (id) {
                                              return id !== sourceId;
                                            }));
                                      updatedSources = ids.length !== 0 ? /* SourceIds */({
                                            _0: ids
                                          }) : /* SourceIndependent */0;
                                    }
                                  } else {
                                    updatedSources = newValue ? /* SourceIds */({
                                          _0: [sourceId]
                                        }) : /* SourceIndependent */0;
                                  }
                                  return Belt_Option.map(draft, (function (draft) {
                                                return {
                                                        id: draft.id,
                                                        sources: updatedSources,
                                                        description: draft.description,
                                                        content: draft.content
                                                      };
                                              }));
                                }));
                  } else {
                    return Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (branch) {
                                    return AnalyticsRe.eventTriggerConfigured(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), $$event.id, $$event.name, newValue ? "AddSource" : "RemoveSource", "Sources", $$event.triggers.length, NamedBranch.getId(branch), schemaGroup.schemaId);
                                  }),
                                undefined,
                                [[
                                    newValue ? ({
                                          NAME: "AddTriggerSource",
                                          VAL: [
                                            $$event.id,
                                            currentTrigger.id,
                                            sourceId
                                          ]
                                        }) : ({
                                          NAME: "RemoveTriggerSource",
                                          VAL: [
                                            $$event.id,
                                            currentTrigger.id,
                                            sourceId
                                          ]
                                        }),
                                    {
                                      eventId: $$event.id,
                                      eventQuery: $$event.id,
                                      triggerId: currentTrigger.id,
                                      triggerQuery: currentTrigger.id
                                    }
                                  ]]
                              ]);
                  }
                }));
  };
  var onUpdateContent = function (content) {
    return Belt_Option.forEach(currentTrigger, (function (currentTrigger) {
                  if (inDraftMode) {
                    return Curry._1(setDraft, (function (draft) {
                                  return Belt_Option.map(draft, (function (draft) {
                                                return {
                                                        id: draft.id,
                                                        sources: draft.sources,
                                                        description: draft.description,
                                                        content: content
                                                      };
                                              }));
                                }));
                  } else {
                    return Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (branch) {
                                    return AnalyticsRe.eventTriggerConfigured(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), $$event.id, $$event.name, content === undefined ? "RemoveImage" : "AddImage", "Image", $$event.triggers.length, NamedBranch.getId(branch), schemaGroup.schemaId);
                                  }),
                                undefined,
                                [[
                                    {
                                      NAME: "UpdateTriggerContent",
                                      VAL: [
                                        $$event.id,
                                        currentTrigger.id,
                                        content
                                      ]
                                    },
                                    {
                                      eventId: $$event.id,
                                      eventQuery: $$event.id,
                                      triggerId: currentTrigger.id,
                                      triggerQuery: currentTrigger.id
                                    }
                                  ]]
                              ]);
                  }
                }));
  };
  var tmp;
  if (currentTrigger !== undefined) {
    var tmp$1;
    if (viewerCanEdit) {
      if (inDraftMode) {
        var disabled = uploadStatus === "Uploading" || (
          QuillDelta.toStr(new QuillDelta$1(JSON.parse(currentTrigger.description))) !== "" || Belt_Option.isSome(currentTrigger.content) ? false : true
        );
        tmp$1 = React.createElement("footer", {
              className: sidebarFooter(false)
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: /* [] */0
                      })
                }, React.createElement(Button.make, {
                      disabled: disabled,
                      label: "Create Trigger",
                      loading: uploadStatus === "Uploading",
                      onClick: (function (param) {
                          return onCreate(undefined, currentTrigger);
                        }),
                      size: "large"
                    }), React.createElement(Spacer.make, {
                      width: 8
                    }), inDraftMode ? React.createElement(ContextMenu.make, {
                        button: React.createElement(Button.make, {
                              disabled: disabled || uploadStatus === "Uploading",
                              icon: "more",
                              size: "large",
                              style: "outline"
                            }),
                        options: [{
                            NAME: "Option",
                            VAL: {
                              label: "Create and add another",
                              onClick: (function (param) {
                                  return onCreate(true, currentTrigger);
                                })
                            }
                          }]
                      }) : null), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement(TextButton.make, {
                  onClick: (function (param) {
                      Curry._1(setDraft, (function (param) {
                              
                            }));
                      return Curry._1(onClose, undefined);
                    }),
                  size: "Small",
                  children: "Cancel"
                }));
      } else {
        tmp$1 = React.createElement("footer", {
              className: sidebarFooter(true)
            }, React.createElement(TextButton.make, {
                  onClick: onNewTrigger,
                  style: "Magenta",
                  size: "Small",
                  children: null
                }, React.createElement(TriggerFullscreenModal$Lightning, {
                      color: Styles.Color.magenta
                    }), "+ New Trigger"));
      }
    } else {
      tmp$1 = null;
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(TriggerModalSources.make, {
              currentTrigger: currentTrigger,
              event: $$event,
              inDraftMode: inDraftMode,
              modelSources: modelSources,
              onRemove: onRemove,
              onUpdate: onUpdateSource,
              sources: currentTrigger.sources,
              viewerCanEdit: viewerCanEdit,
              key: "$sources-" + triggerId
            }), React.createElement(Spacer.make, {
              height: 16
            }), React.createElement(TriggerModalDescription.make, {
              event: $$event,
              description: currentTrigger.description,
              onUpdate: onUpdateDescription,
              triggerId: currentTrigger.id,
              schemaGroup: schemaGroup,
              viewerCanEdit: viewerCanEdit,
              key: "$description-" + triggerId
            }), React.createElement(Spacer.make, {
              height: 0,
              grow: 1.0
            }), tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement(Portal.make, {
              children: React.createElement(FramerMotion.motion.section, {
                    animate: {
                      opacity: 1,
                      y: 0
                    },
                    transition: {
                      duration: 0.15
                    },
                    initial: {
                      opacity: 0,
                      y: 50
                    },
                    exit: {
                      opacity: 0,
                      y: 50
                    },
                    className: wrapper,
                    key: "trigger-modal",
                    children: null
                  }, currentTrigger !== undefined ? React.createElement(TriggerModalImage.make, {
                          content: currentTrigger.content,
                          onUpdateContent: onUpdateContent,
                          setUploadStatus: match$2[1],
                          triggerId: currentTrigger.id,
                          uploadStatus: uploadStatus,
                          viewerCanEdit: viewerCanEdit,
                          key: triggerId
                        }) : null, inDraftMode ? null : React.createElement("button", {
                          className: closeButton,
                          onClick: (function (param) {
                              return Curry._1(onClose, undefined);
                            })
                        }, React.createElement(Icons.ArrowLeft.make, {
                              size: 10,
                              color: Styles.Color.magenta
                            }), React.createElement(Spacer.make, {
                              width: 4
                            }), React.createElement($$Text.make, {
                              weight: "Semi",
                              children: "Back to event"
                            })), React.createElement("aside", {
                        className: sidebar
                      }, React.createElement(TriggerModalNavigation.make, {
                            currentIndex: currentTriggerIndex,
                            draftsToGo: 0,
                            eventName: $$event.name,
                            inDraftMode: inDraftMode,
                            onNavigate: (function (newIndex) {
                                return Belt_Option.forEach(Belt_Array.get($$event.triggers, newIndex), (function (param) {
                                              return Curry._1(routeToTrigger, param.id);
                                            }));
                              }),
                            total: $$event.triggers.length,
                            key: triggerId
                          }), React.createElement(Spacer.make, {
                            height: 12
                          }), tmp))
            });
}

var make = TriggerFullscreenModal;

exports.Lightning = Lightning;
exports.Style = Style;
exports.updateDescription = updateDescription;
exports.debouncedUpdateDescription = debouncedUpdateDescription;
exports.make = make;
/* wrapper Not a pure module */
