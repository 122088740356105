// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");

function UserBlot(Props) {
  var userName = Props.userName;
  return React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(4), Css.px(2)),
                    tl: {
                      hd: Css.color(Styles.Color.magenta),
                      tl: /* [] */0
                    }
                  })
            }, userName);
}

var make = UserBlot;

var jsComponent = UserBlot;

exports.make = make;
exports.jsComponent = jsComponent;
/* Css Not a pure module */
