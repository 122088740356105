// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Router = require("./Router.bs.js");
var $$Comment = require("./Comment.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var PropertyUtils = require("./PropertyUtils.bs.js");
var AvoConfigTypes = require("../../shared/models/AvoConfigTypes.bs.js");
var QuillToPlainText = require("../../shared/utils/QuillToPlainText.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var ObjectActivityLogAction = require("./ObjectActivityLogAction.bs.js");

function EventActivityItem(Props) {
  var schema = Props.schema;
  var $$event = Props.event;
  var events = Props.events;
  var model = Props.model;
  var action = Props.action;
  var userId = Props.userId;
  var userIds = Props.userIds;
  var goToId = Props.goToId;
  var openBranches = Props.openBranches;
  var showComments = Props.showComments;
  var users = FirebaseFetcherHooks.useUsers(userIds);
  if (typeof users !== "object") {
    return null;
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var match = action.contents;
  var name;
  if (typeof match !== "object") {
    return null;
  }
  var variant = match.NAME;
  if (variant === "AddProperty") {
    var match$1 = match.VAL;
    return React.createElement(ObjectActivityLogAction.make, {
                action: action,
                users: users$1,
                path: Router.Link.addDrawerItem(undefined, {
                      NAME: "property",
                      VAL: [
                        match$1[1],
                        undefined
                      ]
                    }),
                openBranches: openBranches,
                children: React.createElement(React.Fragment, undefined, "added the property", React.createElement("b", undefined, match$1[2]))
              });
  }
  if (variant === "AddEventType") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: React.createElement(React.Fragment, undefined, "added the event type", React.createElement("b", undefined, TrackingPlanModel.eventTypeToJs(match.VAL[1])))
              });
  }
  if (variant === "UpdateTriggerContent") {
    var triggerId = match.VAL[1];
    var match$2 = StateUtils.getTriggerByIdAndEventId(triggerId, $$event.id, model);
    if (match$2 !== undefined) {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          $$event.id,
                          undefined,
                          triggerId,
                          false
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "updated content for the trigger", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$2.description)));
    } else {
      return null;
    }
  }
  if (variant === "AddEvent") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "created the event"
              });
  }
  if (variant === "RemovePropertyFromWhitelist") {
    var match$3 = match.VAL;
    var match$4 = StateUtils.getPropertyByIdWithArchive(match$3[1], model);
    if (match$4 !== undefined && match$4.TAG !== /* PropertyRef */0) {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: React.createElement(React.Fragment, undefined, "stopped sending the property", React.createElement("b", undefined, match$4._0.name), "to", React.createElement("b", undefined, AvoConfigTypes.analyticsToolToJs(match$3[2])), "with this event")
                });
    } else {
      return null;
    }
  }
  if (variant === "CreateDemoBranch") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "created the event on a demo branch"
              });
  }
  if (variant === "AddTag") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: React.createElement(React.Fragment, undefined, "added the tag", React.createElement("b", undefined, match.VAL[1]))
              });
  }
  if (variant === "AddPropertyRef") {
    var match$5 = match.VAL;
    var propertyId = match$5[1];
    var match$6 = StateUtils.getEventByIdWithArchive(match$5[0], model);
    var match$7 = StateUtils.getPropertyByIdWithArchive(propertyId, model);
    if (match$6 !== undefined && match$7 !== undefined && match$7.TAG !== /* PropertyRef */0) {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId,
                          undefined
                        ]
                      }),
                  openBranches: openBranches,
                  children: React.createElement(React.Fragment, undefined, "added the property", React.createElement("b", undefined, match$7._0.name))
                });
    } else {
      return null;
    }
  }
  if (variant === "RemoveEvent") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "deleted the event"
              });
  }
  if (variant === "EventComment") {
    if (!showComments) {
      return null;
    }
    var match$8 = match.VAL;
    var eventId = match$8[0];
    return React.createElement($$Comment.make, {
                schema: schema,
                threadId: $$event.id,
                itemType: "Event",
                itemName: $$event.name,
                commentId: match$8[1],
                events: events,
                userId: userId,
                eventId: eventId,
                goToId: goToId,
                action: action,
                link: (function (commentId) {
                    return Router.Link.addDrawerItem(undefined, {
                                NAME: "event",
                                VAL: [
                                  eventId,
                                  commentId,
                                  undefined,
                                  false
                                ]
                              });
                  }),
                openBranches: openBranches
              });
  }
  if (variant === "ExcludeEventFromSource" || variant === "ExcludeEventFromSourceV2") {
    var source = StateUtils.getSourceByIdWithArchive(match.VAL[1], model);
    if (source !== undefined) {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: React.createElement(React.Fragment, undefined, "removed the source", React.createElement("b", undefined, AvoConfig.getSourceName(source)))
                });
    } else {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "updated the sources this event is sent from"
                });
    }
  }
  if (variant === "RemovePropertyRefV2" || variant === "LegacyRemovePropertyRef") {
    var match$9 = match.VAL;
    var match$10 = StateUtils.getEventByIdWithArchive(match$9[0], model);
    var match$11 = StateUtils.getPropertyByIdWithArchive(match$9[1], model);
    if (match$10 !== undefined && match$11 !== undefined && match$11.TAG !== /* PropertyRef */0) {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: React.createElement(React.Fragment, undefined, "removed the property", React.createElement("b", undefined, match$11._0.name))
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdateTriggerSources") {
    var match$12 = match.VAL;
    var sources = match$12[2];
    var triggerId$1 = match$12[1];
    var sourceNames = sources ? Belt_Array.map(Belt_Array.keepMap(sources._0, (function (sourceId) {
                    return StateUtils.getSourceByIdWithArchive(sourceId, model);
                  })), AvoConfig.getSourceName).join(", ") : "Source Independent";
    var match$13 = StateUtils.getTriggerFromEvent(triggerId$1, $$event);
    if (match$13 !== undefined) {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          $$event.id,
                          undefined,
                          triggerId$1,
                          false
                        ]
                      }),
                  openBranches: openBranches,
                  children: React.createElement(React.Fragment, undefined, "updated a trigger with the description ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$13.description)), " to be triggered with ", React.createElement("b", undefined, sourceNames))
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdateEventUniqueNameV2") {
    var name$1 = match.VAL[1];
    if (name$1 === "") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: React.createElement(React.Fragment, undefined, "removed the unique name")
                });
    }
    name = name$1;
  } else {
    if (variant === "CreateEventTrigger") {
      var match$14 = match.VAL;
      var sources$1 = match$14[2];
      var triggerId$2 = match$14[1];
      var sourceNames$1 = sources$1 ? Belt_Array.map(Belt_Array.keepMap(sources$1._0, (function (sourceId) {
                      return StateUtils.getSourceByIdWithArchive(sourceId, model);
                    })), AvoConfig.getSourceName).join(", ") : "Source Independent";
      var match$15 = StateUtils.getTriggerFromEvent(triggerId$2, $$event);
      if (match$15 !== undefined) {
        return React.createElement(ObjectActivityLogAction.make, {
                    action: action,
                    users: users$1,
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "event",
                          VAL: [
                            $$event.id,
                            undefined,
                            triggerId$2,
                            false
                          ]
                        }),
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "created a trigger for ", React.createElement("b", undefined, sourceNames$1), " with the description ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$15.description)))
                  });
      } else {
        return null;
      }
    }
    if (variant === "Archive") {
      var match$16 = match.VAL;
      if (typeof match$16 === "object" && match$16.NAME === "Event") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "archived the event")
                  });
      } else {
        return null;
      }
    }
    if (variant === "RemoveTag") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: React.createElement(React.Fragment, undefined, "removed the tag", React.createElement("b", undefined, match.VAL[1]))
                });
    }
    if (variant === "AddUserToGroup") {
      var groupTypeName = StateUtils.getGroupTypeName(match.VAL[0], model);
      if (groupTypeName !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "started associating the user with the ", React.createElement("b", undefined, groupTypeName), " group type")
                  });
      } else {
        return null;
      }
    }
    if (variant === "ToggleIncludeEventInCodegenForSource") {
      var match$17 = match.VAL;
      var includeInCodegen = match$17[2];
      var source$1 = StateUtils.getSourceByIdWithArchive(match$17[1], model);
      if (source$1 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, (
                    includeInCodegen ? "included the event in" : "excluded the event from"
                  ) + " Codegen for the source", React.createElement("b", undefined, AvoConfig.getSourceName(source$1)));
      } else {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: (
                      includeInCodegen ? "included the event in" : "excluded the event from"
                    ) + " Codegen for a source"
                  });
      }
    }
    if (variant === "IncludeEventInSource" || variant === "IncludeEventInSourceV2") {
      var source$2 = StateUtils.getSourceByIdWithArchive(match.VAL[1], model);
      if (source$2 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "added the source", React.createElement("b", undefined, AvoConfig.getSourceName(source$2)))
                  });
      } else {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "updated the sources this event is sent from"
                  });
      }
    }
    if (variant === "DeleteComment" || variant === "AddSystemProperty") {
      return null;
    }
    if (variant === "UpdateEventDescription") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "updated the description to " + match.VAL[1]
                });
    }
    if (variant === "AddEventToMetric") {
      var metric = StateUtils.getMetricByIdWithArchive(match.VAL[0], model);
      if (metric !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "added the event to the metric", React.createElement("b", undefined, metric.name))
                  });
      } else {
        return null;
      }
    }
    if (variant === "ExcludeDestinationFromEventSource") {
      var match$18 = match.VAL;
      var sourceId = match$18[1];
      var match$19 = StateUtils.getSourceByIdWithArchive(sourceId, model);
      var match$20 = StateUtils.getDestinationByIdWithArchive(match$18[2], model);
      if (match$19 !== undefined && match$20 !== undefined) {
        return React.createElement(ObjectActivityLogAction.make, {
                    action: action,
                    users: users$1,
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "source",
                          VAL: [
                            sourceId,
                            "overview"
                          ]
                        }),
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "stopped sending this event to", React.createElement("b", undefined, StateUtils.getDestinationName(match$20)), "from", React.createElement("b", undefined, AvoConfig.getSourceName(match$19)))
                  });
      } else {
        return null;
      }
    }
    if (variant === "UpdateTriggerDescription") {
      var match$21 = match.VAL;
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          $$event.id,
                          undefined,
                          match$21[1],
                          false
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "updated the description for the trigger", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$21[2])));
    }
    if (variant === "AddPropertyToWhitelist") {
      var match$22 = match.VAL;
      var match$23 = StateUtils.getPropertyByIdWithArchive(match$22[1], model);
      if (match$23 !== undefined && match$23.TAG !== /* PropertyRef */0) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "started sending the property", React.createElement("b", undefined, match$23._0.name), "to", React.createElement("b", undefined, AvoConfigTypes.analyticsToolToJs(match$22[2])), "with this event")
                  });
      } else {
        return null;
      }
    }
    if (variant === "AddTriggerSource") {
      var match$24 = match.VAL;
      var triggerId$3 = match$24[1];
      var match$25 = StateUtils.getSourceByIdWithArchive(match$24[2], model);
      var match$26 = StateUtils.getTriggerByIdAndEventId(triggerId$3, $$event.id, model);
      if (match$25 !== undefined && match$26 !== undefined) {
        return React.createElement(ObjectActivityLogAction.make, {
                    action: action,
                    users: users$1,
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "event",
                          VAL: [
                            $$event.id,
                            undefined,
                            triggerId$3,
                            false
                          ]
                        }),
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "added ", React.createElement("b", undefined, AvoConfig.getSourceName(match$25)), " as a source for the trigger with the description ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$26.description)))
                  });
      } else {
        return null;
      }
    }
    if (variant === "IncludeDestinationInEventSource") {
      var match$27 = match.VAL;
      var sourceId$1 = match$27[1];
      var match$28 = StateUtils.getSourceByIdWithArchive(sourceId$1, model);
      var match$29 = StateUtils.getDestinationByIdWithArchive(match$27[2], model);
      if (match$28 !== undefined && match$29 !== undefined) {
        return React.createElement(ObjectActivityLogAction.make, {
                    action: action,
                    users: users$1,
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "source",
                          VAL: [
                            sourceId$1,
                            "overview"
                          ]
                        }),
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "started sending this event to", React.createElement("b", undefined, StateUtils.getDestinationName(match$29)), "from", React.createElement("b", undefined, AvoConfig.getSourceName(match$28)))
                  });
      } else {
        return null;
      }
    }
    if (variant === "AddGroupTypeToLogEvent") {
      var groupTypeName$1 = StateUtils.getGroupTypeName(match.VAL[0], model);
      if (groupTypeName$1 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "started associating this event with the ", React.createElement("b", undefined, groupTypeName$1), " group type")
                  });
      } else {
        return null;
      }
    }
    if (variant === "UpdatePropertyPinnedValue") {
      var match$30 = match.VAL;
      var match$31 = StateUtils.getPropertyById(match$30[1], model);
      if (match$31 !== undefined && match$31.TAG !== /* PropertyRef */0) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "pinned the value of ", React.createElement("b", undefined, match$31._0.name), "to", React.createElement("b", undefined, PropertyUtils.printPinnedValue(match$30[2])))
                  });
      } else {
        return null;
      }
    }
    if (variant === "RemoveUserFromGroup") {
      var groupTypeName$2 = StateUtils.getGroupTypeName(match.VAL[0], model);
      if (groupTypeName$2 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "removed associating the user with the ", React.createElement("b", undefined, groupTypeName$2), " group type")
                  });
      } else {
        return null;
      }
    }
    if (variant === "DuplicateEvent") {
      return Belt_Option.mapWithDefault(StateUtils.getEventByIdWithArchive(match.VAL[0][0], model), null, (function (param) {
                    return React.createElement(ObjectActivityLogAction.Content.make, {
                                action: action,
                                users: users$1,
                                openBranches: openBranches,
                                children: React.createElement(React.Fragment, undefined, "duplicated the event from ", React.createElement("i", undefined, param.name))
                              });
                  }));
    }
    if (variant === "RemoveEventType") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: React.createElement(React.Fragment, undefined, "removed the event type", React.createElement("b", undefined, TrackingPlanModel.eventTypeToJs(match.VAL[1])))
                });
    }
    if (variant === "UpdateEventUniqueName") {
      name = match.VAL[1];
    } else {
      if (variant === "UpdateEventName") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "changed the name to", React.createElement("b", undefined, match.VAL[1]))
                  });
      }
      if (variant === "RemoveGroupTypeFromLogEvent") {
        var groupTypeName$3 = StateUtils.getGroupTypeName(match.VAL[0], model);
        if (groupTypeName$3 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "stopped associating this event with the ", React.createElement("b", undefined, groupTypeName$3), " group type")
                    });
        } else {
          return null;
        }
      }
      if (variant === "RemovePropertyGroupFromEvent") {
        var group = StateUtils.getPropertyGroupByIdWithArchive(match.VAL[1], model);
        if (group !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "removed the property bundle", React.createElement("b", undefined, group.name))
                    });
        } else {
          return null;
        }
      }
      if (variant === "ConfigureInspectorValidation") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "configured the event for Inspector validation"
                  });
      }
      if (variant === "RemoveEventFromMetric") {
        var metric$1 = StateUtils.getMetricByIdWithArchive(match.VAL[0], model);
        if (metric$1 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "removed the event from the metric", React.createElement("b", undefined, metric$1.name))
                    });
        } else {
          return null;
        }
      }
      if (variant === "RemovePropertyPinnedValue") {
        var match$32 = StateUtils.getPropertyById(match.VAL[1], model);
        if (match$32 !== undefined && match$32.TAG !== /* PropertyRef */0) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "removed the pinned value of ", React.createElement("b", undefined, match$32._0.name))
                    });
        } else {
          return null;
        }
      }
      if (variant === "RemoveEventTrigger") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "removed a trigger"
                  });
      }
      if (variant === "RemoveTriggerSource") {
        var match$33 = match.VAL;
        var triggerId$4 = match$33[1];
        var match$34 = StateUtils.getSourceByIdWithArchive(match$33[2], model);
        var match$35 = StateUtils.getTriggerByIdAndEventId(triggerId$4, $$event.id, model);
        if (match$34 !== undefined && match$35 !== undefined) {
          return React.createElement(ObjectActivityLogAction.make, {
                      action: action,
                      users: users$1,
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "event",
                            VAL: [
                              $$event.id,
                              undefined,
                              triggerId$4,
                              false
                            ]
                          }),
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "removed ", React.createElement("b", undefined, AvoConfig.getSourceName(match$34)), " as a source for the trigger with the description ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$35.description)))
                    });
        } else {
          return null;
        }
      }
      if (variant === "UpdatePropertyAbsence") {
        var match$36 = match.VAL;
        var maybeEventSourceAbsence = match$36[2];
        var propertyAbsenceUpdateType = match$36[1];
        var match$37 = StateUtils.getPropertyByIdWithArchive(match$36[0], model);
        if (match$37 === undefined) {
          return null;
        }
        if (match$37.TAG === /* PropertyRef */0) {
          return null;
        }
        var tmp;
        if (typeof propertyAbsenceUpdateType === "number") {
          tmp = React.createElement(React.Fragment, undefined, "on", React.createElement("b", undefined, "all events"));
        } else {
          switch (propertyAbsenceUpdateType.TAG | 0) {
            case /* Event */0 :
                tmp = "on this event";
                break;
            case /* Source */1 :
                var source$3 = StateUtils.getSourceByIdWithArchive(propertyAbsenceUpdateType._0, model);
                tmp = source$3 !== undefined ? React.createElement(React.Fragment, undefined, "from the", React.createElement("b", undefined, AvoConfig.getSourceName(source$3)), "source") : "from one source";
                break;
            case /* EventSource */2 :
                var match$38 = StateUtils.getEventByIdWithArchive(propertyAbsenceUpdateType._0, model);
                var match$39 = StateUtils.getSourceByIdWithArchive(propertyAbsenceUpdateType._1, model);
                tmp = match$38 !== undefined && match$39 !== undefined ? React.createElement(React.Fragment, undefined, "on this event when sent from the", React.createElement("b", undefined, AvoConfig.getSourceName(match$39)), "source") : "on one event and source";
                break;
            
          }
        }
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "set the property", React.createElement("b", undefined, match$37._0.name), "to be", React.createElement("b", undefined, maybeEventSourceAbsence !== undefined ? (
                                maybeEventSourceAbsence ? "sometimes sent" : "never sent"
                              ) : "always sent"), tmp)
                  });
      }
      if (variant === "Unarchive") {
        var match$40 = match.VAL;
        if (typeof match$40 === "object" && match$40.NAME === "Event") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: React.createElement(React.Fragment, undefined, "unarchived the event")
                    });
        } else {
          return null;
        }
      }
      if (variant === "CompletedImport") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "imported the event"
                  });
      }
      if (variant !== "AddPropertyGroupToEvent") {
        return null;
      }
      var group$1 = StateUtils.getPropertyGroupByIdWithArchive(match.VAL[1], model);
      if (group$1 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: React.createElement(React.Fragment, undefined, "added the property bundle", React.createElement("b", undefined, group$1.name))
                  });
      } else {
        return null;
      }
    }
  }
  return React.createElement(ObjectActivityLogAction.Content.make, {
              action: action,
              users: users$1,
              openBranches: openBranches,
              children: React.createElement(React.Fragment, undefined, "set unique name to", React.createElement("b", undefined, name))
            });
}

var make = EventActivityItem;

exports.make = make;
/* react Not a pure module */
