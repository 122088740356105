// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Sentry = require("../externals/Sentry.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var ImportParserTypes = require("./ImportParserTypes.bs.js");
var ImportFormatsCustomSpreadsheetFormatOneParser = require("./ImportFormatsCustomSpreadsheetFormatOneParser.bs.js");

function parsePropertyType(type_) {
  var propertyType = type_.toLocaleLowerCase();
  switch (propertyType) {
    case "bool" :
    case "boolean" :
        return [
                "bool",
                undefined
              ];
    case "date" :
        return [
                "string",
                "'date' is imported as 'string'"
              ];
    case "datetime" :
        return [
                "string",
                "'datetime' is imported as 'string'"
              ];
    case "double" :
        return [
                "float",
                "'double' is imported as 'float'"
              ];
    case "incremental" :
        return [
                "int",
                "'incremental' is imported as 'int'"
              ];
    case "int" :
    case "integer" :
        return [
                "int",
                undefined
              ];
    case "" :
    case "#N/A" :
    case "any" :
    case "list" :
        return [
                "any",
                undefined
              ];
    case "long" :
        return [
                "int",
                "'long' is imported as 'int'"
              ];
    case "float" :
    case "number" :
    case "numeric" :
        return [
                "float",
                undefined
              ];
    case "enum" :
    case "string" :
        return [
                "string",
                undefined
              ];
    default:
      Sentry.captureException({
            RE_EXN_ID: ImportParserTypes.ParseWarning,
            _1: "type parsing warning"
          }, {
            unrecognizedType: propertyType
          });
      return [
              "any",
              "Unsupported property type: '" + (propertyType + "'. Defaulting to 'any'.")
            ];
  }
}

function parsePropertyRequired(required) {
  var isRequired = required.toLocaleLowerCase().trim();
  switch (isRequired) {
    case "false" :
    case "n" :
    case "no" :
    case "optional" :
        return false;
    case "" :
    case "required" :
    case "true" :
    case "y" :
    case "yes" :
        return true;
    default:
      console.error("Unsupported property required: " + isRequired);
      return Pervasives.failwith("Unsupported property required: " + isRequired);
  }
}

function parsePropertyList(isPropertyList) {
  var isArray = isPropertyList.toLocaleLowerCase().trim();
  switch (isArray) {
    case "" :
    case "false" :
    case "n" :
    case "no" :
        return false;
    case "array" :
    case "true" :
    case "y" :
    case "yes" :
        return true;
    default:
      console.error("Unsupported property is array value: " + isArray);
      return Pervasives.failwith("Unsupported property is array value: " + isArray);
  }
}

function parseStringArray(stringArray) {
  return Belt_Array.keepU(Belt_Array.map(stringArray.includes(",") ? stringArray.split(",") : (
                    stringArray.includes("\n") ? stringArray.split("\n") : stringArray.split(";")
                  ), (function (prim) {
                    return prim.trim();
                  })), (function (item) {
                if (item !== "" && item !== "\"\"") {
                  return item !== "#N/A";
                } else {
                  return false;
                }
              }));
}

var amplitudeExport_eventNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Event"
};

var amplitudeExport_eventDescriptionParser = {
  TAG: /* Read */0,
  _0: "Event Description"
};

var amplitudeExport_eventCategoriesParser = {
  TAG: /* MapStringArray */0,
  _0: "Category",
  _1: parseStringArray
};

var amplitudeExport_eventTagsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var amplitudeExport_eventSourcesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var amplitudeExport_eventNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var amplitudeExport_propertyNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Event Property"
};

var amplitudeExport_propertyDescriptionParser = {
  TAG: /* Read */0,
  _0: "Property Description"
};

var amplitudeExport_propertyTypeParser = {
  TAG: /* Map */0,
  _0: "Property Type",
  _1: parsePropertyType
};

var amplitudeExport_propertyRequiredParser = {
  TAG: /* MapBoolean */0,
  _0: "Property Is Required",
  _1: parsePropertyRequired
};

var amplitudeExport_propertyListParser = {
  TAG: /* MapBoolean */0,
  _0: "Property Is Array",
  _1: parsePropertyList
};

var amplitudeExport_propertyMatchesParser = {
  TAG: /* MapStringArray */0,
  _0: "Property Enum Options",
  _1: parseStringArray
};

var amplitudeExport_propertySendAsParser = {
  TAG: /* SkipSendAs */1,
  _0: /* EventProperty */1
};

var amplitudeExport_propertyGroupParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var amplitudeExport_propertyEventsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var amplitudeExport_propertyNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var amplitudeExport_propertyPinnedValueParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var amplitudeExport = {
  formatName: "Amplitude CSV Export",
  eventNameParser: amplitudeExport_eventNameParser,
  eventDescriptionParser: amplitudeExport_eventDescriptionParser,
  eventCategoriesParser: amplitudeExport_eventCategoriesParser,
  eventTagsParser: amplitudeExport_eventTagsParser,
  eventSourcesParser: amplitudeExport_eventSourcesParser,
  eventNameMappingParser: amplitudeExport_eventNameMappingParser,
  propertyNameParser: amplitudeExport_propertyNameParser,
  propertyDescriptionParser: amplitudeExport_propertyDescriptionParser,
  propertyTypeParser: amplitudeExport_propertyTypeParser,
  propertyRequiredParser: amplitudeExport_propertyRequiredParser,
  propertyListParser: amplitudeExport_propertyListParser,
  propertyMatchesParser: amplitudeExport_propertyMatchesParser,
  propertySendAsParser: amplitudeExport_propertySendAsParser,
  propertyGroupParser: amplitudeExport_propertyGroupParser,
  propertyEventsParser: amplitudeExport_propertyEventsParser,
  propertyNameMappingParser: amplitudeExport_propertyNameMappingParser,
  propertyPinnedValueParser: amplitudeExport_propertyPinnedValueParser,
  customParser: undefined
};

var mixpanelExport_eventNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Entity Name"
};

var mixpanelExport_eventDescriptionParser = {
  TAG: /* Read */0,
  _0: "Entity Description"
};

var mixpanelExport_eventCategoriesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var mixpanelExport_eventTagsParser = {
  TAG: /* MapStringArray */0,
  _0: "Event Tags",
  _1: parseStringArray
};

var mixpanelExport_eventSourcesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var mixpanelExport_eventNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var mixpanelExport_propertyNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Property Name"
};

var mixpanelExport_propertyDescriptionParser = {
  TAG: /* Read */0,
  _0: "Property Description"
};

var mixpanelExport_propertyTypeParser = {
  TAG: /* Map */0,
  _0: "Property Type",
  _1: parsePropertyType
};

var mixpanelExport_propertyRequiredParser = {
  TAG: /* MapBoolean */0,
  _0: "Property Required",
  _1: parsePropertyRequired
};

var mixpanelExport_propertyListParser = {
  TAG: /* MapBoolean */0,
  _0: "Property Type",
  _1: (function (type_) {
      if (type_ === "list") {
        return true;
      } else {
        return type_ === "array";
      }
    })
};

var mixpanelExport_propertyMatchesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var mixpanelExport_propertySendAsParser = {
  TAG: /* SkipSendAs */1,
  _0: /* EventProperty */1
};

var mixpanelExport_propertyGroupParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var mixpanelExport_propertyEventsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var mixpanelExport_propertyNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var mixpanelExport_propertyPinnedValueParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var mixpanelExport = {
  formatName: "Mixpanel CSV Export",
  eventNameParser: mixpanelExport_eventNameParser,
  eventDescriptionParser: mixpanelExport_eventDescriptionParser,
  eventCategoriesParser: mixpanelExport_eventCategoriesParser,
  eventTagsParser: mixpanelExport_eventTagsParser,
  eventSourcesParser: mixpanelExport_eventSourcesParser,
  eventNameMappingParser: mixpanelExport_eventNameMappingParser,
  propertyNameParser: mixpanelExport_propertyNameParser,
  propertyDescriptionParser: mixpanelExport_propertyDescriptionParser,
  propertyTypeParser: mixpanelExport_propertyTypeParser,
  propertyRequiredParser: mixpanelExport_propertyRequiredParser,
  propertyListParser: mixpanelExport_propertyListParser,
  propertyMatchesParser: mixpanelExport_propertyMatchesParser,
  propertySendAsParser: mixpanelExport_propertySendAsParser,
  propertyGroupParser: mixpanelExport_propertyGroupParser,
  propertyEventsParser: mixpanelExport_propertyEventsParser,
  propertyNameMappingParser: mixpanelExport_propertyNameMappingParser,
  propertyPinnedValueParser: mixpanelExport_propertyPinnedValueParser,
  customParser: undefined
};

var mixpanelLegacyExport_eventNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Event"
};

var mixpanelLegacyExport_eventDescriptionParser = {
  TAG: /* Read */0,
  _0: "Event Description"
};

var mixpanelLegacyExport_eventCategoriesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var mixpanelLegacyExport_eventTagsParser = {
  TAG: /* MapStringArray */0,
  _0: "Event Tags",
  _1: parseStringArray
};

var mixpanelLegacyExport_eventSourcesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var mixpanelLegacyExport_eventNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var mixpanelLegacyExport_propertyNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Event Property"
};

var mixpanelLegacyExport_propertyDescriptionParser = {
  TAG: /* Read */0,
  _0: "Property Description"
};

var mixpanelLegacyExport_propertyTypeParser = {
  TAG: /* Map */0,
  _0: "Property Type",
  _1: parsePropertyType
};

var mixpanelLegacyExport_propertyRequiredParser = {
  TAG: /* SkipBoolean */1,
  _0: false
};

var mixpanelLegacyExport_propertyListParser = {
  TAG: /* MapBoolean */0,
  _0: "Property Type",
  _1: (function (type_) {
      if (type_ === "list") {
        return true;
      } else {
        return type_ === "array";
      }
    })
};

var mixpanelLegacyExport_propertyMatchesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var mixpanelLegacyExport_propertySendAsParser = {
  TAG: /* SkipSendAs */1,
  _0: /* EventProperty */1
};

var mixpanelLegacyExport_propertyGroupParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var mixpanelLegacyExport_propertyEventsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var mixpanelLegacyExport_propertyNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var mixpanelLegacyExport_propertyPinnedValueParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var mixpanelLegacyExport = {
  formatName: "Mixpanel Legacy CSV Export",
  eventNameParser: mixpanelLegacyExport_eventNameParser,
  eventDescriptionParser: mixpanelLegacyExport_eventDescriptionParser,
  eventCategoriesParser: mixpanelLegacyExport_eventCategoriesParser,
  eventTagsParser: mixpanelLegacyExport_eventTagsParser,
  eventSourcesParser: mixpanelLegacyExport_eventSourcesParser,
  eventNameMappingParser: mixpanelLegacyExport_eventNameMappingParser,
  propertyNameParser: mixpanelLegacyExport_propertyNameParser,
  propertyDescriptionParser: mixpanelLegacyExport_propertyDescriptionParser,
  propertyTypeParser: mixpanelLegacyExport_propertyTypeParser,
  propertyRequiredParser: mixpanelLegacyExport_propertyRequiredParser,
  propertyListParser: mixpanelLegacyExport_propertyListParser,
  propertyMatchesParser: mixpanelLegacyExport_propertyMatchesParser,
  propertySendAsParser: mixpanelLegacyExport_propertySendAsParser,
  propertyGroupParser: mixpanelLegacyExport_propertyGroupParser,
  propertyEventsParser: mixpanelLegacyExport_propertyEventsParser,
  propertyNameMappingParser: mixpanelLegacyExport_propertyNameMappingParser,
  propertyPinnedValueParser: mixpanelLegacyExport_propertyPinnedValueParser,
  customParser: undefined
};

var avoSpreadsheetWithTypo_eventNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Event Name"
};

var avoSpreadsheetWithTypo_eventDescriptionParser = {
  TAG: /* Read */0,
  _0: "Event Description"
};

var avoSpreadsheetWithTypo_eventCategoriesParser = {
  TAG: /* MapStringArray */0,
  _0: "Event Category",
  _1: parseStringArray
};

var avoSpreadsheetWithTypo_eventTagsParser = {
  TAG: /* MapStringArrayFromOptionalColumn */1,
  _0: "Event Tags",
  _1: parseStringArray
};

var avoSpreadsheetWithTypo_eventSourcesParser = {
  TAG: /* MapStringArray */0,
  _0: "Platforms",
  _1: parseStringArray
};

var avoSpreadsheetWithTypo_eventNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var avoSpreadsheetWithTypo_propertyNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Event Property Name"
};

var avoSpreadsheetWithTypo_propertyDescriptionParser = {
  TAG: /* Read */0,
  _0: "Property Description"
};

var avoSpreadsheetWithTypo_propertyTypeParser = {
  TAG: /* Map */0,
  _0: "Property Value Type",
  _1: parsePropertyType
};

var avoSpreadsheetWithTypo_propertyRequiredParser = {
  TAG: /* MapBoolean */0,
  _0: "Is Property Requierd?",
  _1: parsePropertyRequired
};

var avoSpreadsheetWithTypo_propertyListParser = {
  TAG: /* MapBoolean */0,
  _0: "Is Property Array?",
  _1: parsePropertyList
};

var avoSpreadsheetWithTypo_propertyMatchesParser = {
  TAG: /* MapStringArray */0,
  _0: "Property Enumeration options",
  _1: parseStringArray
};

var avoSpreadsheetWithTypo_propertySendAsParser = {
  TAG: /* SkipSendAs */1,
  _0: /* EventProperty */1
};

var avoSpreadsheetWithTypo_propertyGroupParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var avoSpreadsheetWithTypo_propertyEventsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var avoSpreadsheetWithTypo_propertyNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var avoSpreadsheetWithTypo_propertyPinnedValueParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var avoSpreadsheetWithTypo = {
  formatName: "Avo Ultimate Tracking Plan Template Legacy",
  eventNameParser: avoSpreadsheetWithTypo_eventNameParser,
  eventDescriptionParser: avoSpreadsheetWithTypo_eventDescriptionParser,
  eventCategoriesParser: avoSpreadsheetWithTypo_eventCategoriesParser,
  eventTagsParser: avoSpreadsheetWithTypo_eventTagsParser,
  eventSourcesParser: avoSpreadsheetWithTypo_eventSourcesParser,
  eventNameMappingParser: avoSpreadsheetWithTypo_eventNameMappingParser,
  propertyNameParser: avoSpreadsheetWithTypo_propertyNameParser,
  propertyDescriptionParser: avoSpreadsheetWithTypo_propertyDescriptionParser,
  propertyTypeParser: avoSpreadsheetWithTypo_propertyTypeParser,
  propertyRequiredParser: avoSpreadsheetWithTypo_propertyRequiredParser,
  propertyListParser: avoSpreadsheetWithTypo_propertyListParser,
  propertyMatchesParser: avoSpreadsheetWithTypo_propertyMatchesParser,
  propertySendAsParser: avoSpreadsheetWithTypo_propertySendAsParser,
  propertyGroupParser: avoSpreadsheetWithTypo_propertyGroupParser,
  propertyEventsParser: avoSpreadsheetWithTypo_propertyEventsParser,
  propertyNameMappingParser: avoSpreadsheetWithTypo_propertyNameMappingParser,
  propertyPinnedValueParser: avoSpreadsheetWithTypo_propertyPinnedValueParser,
  customParser: undefined
};

var avoSpreadsheet_eventNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Event Name"
};

var avoSpreadsheet_eventDescriptionParser = {
  TAG: /* Read */0,
  _0: "Event Description"
};

var avoSpreadsheet_eventCategoriesParser = {
  TAG: /* MapStringArray */0,
  _0: "Event Category",
  _1: parseStringArray
};

var avoSpreadsheet_eventTagsParser = {
  TAG: /* MapStringArrayFromOptionalColumn */1,
  _0: "Event Tags",
  _1: parseStringArray
};

var avoSpreadsheet_eventSourcesParser = {
  TAG: /* MapStringArray */0,
  _0: "Platforms",
  _1: parseStringArray
};

var avoSpreadsheet_eventNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var avoSpreadsheet_propertyNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Event Property Name"
};

var avoSpreadsheet_propertyDescriptionParser = {
  TAG: /* Read */0,
  _0: "Property Description"
};

var avoSpreadsheet_propertyTypeParser = {
  TAG: /* Map */0,
  _0: "Property Value Type",
  _1: parsePropertyType
};

var avoSpreadsheet_propertyRequiredParser = {
  TAG: /* MapBoolean */0,
  _0: "Is Property Required?",
  _1: parsePropertyRequired
};

var avoSpreadsheet_propertyListParser = {
  TAG: /* MapBoolean */0,
  _0: "Is Property Array?",
  _1: parsePropertyList
};

var avoSpreadsheet_propertyMatchesParser = {
  TAG: /* MapStringArray */0,
  _0: "Property Enumeration Options",
  _1: parseStringArray
};

var avoSpreadsheet_propertySendAsParser = {
  TAG: /* SkipSendAs */1,
  _0: /* EventProperty */1
};

var avoSpreadsheet_propertyGroupParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var avoSpreadsheet_propertyEventsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var avoSpreadsheet_propertyNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var avoSpreadsheet_propertyPinnedValueParser = {
  TAG: /* ReadFromOptionalColumn */1,
  _0: "Pinned Value"
};

var avoSpreadsheet = {
  formatName: "Avo Ultimate Tracking Plan Template",
  eventNameParser: avoSpreadsheet_eventNameParser,
  eventDescriptionParser: avoSpreadsheet_eventDescriptionParser,
  eventCategoriesParser: avoSpreadsheet_eventCategoriesParser,
  eventTagsParser: avoSpreadsheet_eventTagsParser,
  eventSourcesParser: avoSpreadsheet_eventSourcesParser,
  eventNameMappingParser: avoSpreadsheet_eventNameMappingParser,
  propertyNameParser: avoSpreadsheet_propertyNameParser,
  propertyDescriptionParser: avoSpreadsheet_propertyDescriptionParser,
  propertyTypeParser: avoSpreadsheet_propertyTypeParser,
  propertyRequiredParser: avoSpreadsheet_propertyRequiredParser,
  propertyListParser: avoSpreadsheet_propertyListParser,
  propertyMatchesParser: avoSpreadsheet_propertyMatchesParser,
  propertySendAsParser: avoSpreadsheet_propertySendAsParser,
  propertyGroupParser: avoSpreadsheet_propertyGroupParser,
  propertyEventsParser: avoSpreadsheet_propertyEventsParser,
  propertyNameMappingParser: avoSpreadsheet_propertyNameMappingParser,
  propertyPinnedValueParser: avoSpreadsheet_propertyPinnedValueParser,
  customParser: undefined
};

var avoExport_eventNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Event Name"
};

var avoExport_eventDescriptionParser = {
  TAG: /* Read */0,
  _0: "Event Description"
};

var avoExport_eventCategoriesParser = {
  TAG: /* MapStringArray */0,
  _0: "Event Category",
  _1: parseStringArray
};

var avoExport_eventTagsParser = {
  TAG: /* MapStringArrayFromOptionalColumn */1,
  _0: "Event Tags",
  _1: parseStringArray
};

var avoExport_eventSourcesParser = {
  TAG: /* MapStringArray */0,
  _0: "Sources",
  _1: parseStringArray
};

var avoExport_eventNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var avoExport_propertyNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Property Name"
};

var avoExport_propertyDescriptionParser = {
  TAG: /* Read */0,
  _0: "Property Description"
};

var avoExport_propertyTypeParser = {
  TAG: /* Map */0,
  _0: "Property Value Type",
  _1: parsePropertyType
};

var avoExport_propertyRequiredParser = {
  TAG: /* MapBoolean */0,
  _0: "Is Property Required?",
  _1: parsePropertyRequired
};

var avoExport_propertyListParser = {
  TAG: /* MapBoolean */0,
  _0: "Is Property Array?",
  _1: parsePropertyList
};

var avoExport_propertyMatchesParser = {
  TAG: /* MapStringArray */0,
  _0: "Property Enumeration Options",
  _1: parseStringArray
};

var avoExport_propertySendAsParser = {
  TAG: /* SkipSendAs */1,
  _0: /* EventProperty */1
};

var avoExport_propertyGroupParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var avoExport_propertyEventsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var avoExport_propertyNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var avoExport_propertyPinnedValueParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var avoExport = {
  formatName: "Avo CSV Export",
  eventNameParser: avoExport_eventNameParser,
  eventDescriptionParser: avoExport_eventDescriptionParser,
  eventCategoriesParser: avoExport_eventCategoriesParser,
  eventTagsParser: avoExport_eventTagsParser,
  eventSourcesParser: avoExport_eventSourcesParser,
  eventNameMappingParser: avoExport_eventNameMappingParser,
  propertyNameParser: avoExport_propertyNameParser,
  propertyDescriptionParser: avoExport_propertyDescriptionParser,
  propertyTypeParser: avoExport_propertyTypeParser,
  propertyRequiredParser: avoExport_propertyRequiredParser,
  propertyListParser: avoExport_propertyListParser,
  propertyMatchesParser: avoExport_propertyMatchesParser,
  propertySendAsParser: avoExport_propertySendAsParser,
  propertyGroupParser: avoExport_propertyGroupParser,
  propertyEventsParser: avoExport_propertyEventsParser,
  propertyNameMappingParser: avoExport_propertyNameMappingParser,
  propertyPinnedValueParser: avoExport_propertyPinnedValueParser,
  customParser: undefined
};

var amplitudeTrackingPlanTemplate_eventNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Event Name"
};

var amplitudeTrackingPlanTemplate_eventDescriptionParser = {
  TAG: /* Read */0,
  _0: "Trigger + Event Description"
};

var amplitudeTrackingPlanTemplate_eventCategoriesParser = {
  TAG: /* MapStringArray */0,
  _0: "Event Category",
  _1: parseStringArray
};

var amplitudeTrackingPlanTemplate_eventTagsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var amplitudeTrackingPlanTemplate_eventSourcesParser = {
  TAG: /* MapStringArray */0,
  _0: "Platform",
  _1: parseStringArray
};

var amplitudeTrackingPlanTemplate_eventNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var amplitudeTrackingPlanTemplate_propertyNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Property Name"
};

var amplitudeTrackingPlanTemplate_propertyDescriptionParser = {
  TAG: /* Read */0,
  _0: "Property Description"
};

var amplitudeTrackingPlanTemplate_propertyTypeParser = {
  TAG: /* Map */0,
  _0: "Sample Values",
  _1: (function (value) {
      if (Caml_obj.caml_equal(parseStringArray(value), [])) {
        return [
                "any",
                undefined
              ];
      } else {
        return [
                "string",
                undefined
              ];
      }
    })
};

var amplitudeTrackingPlanTemplate_propertyRequiredParser = {
  TAG: /* SkipBoolean */1,
  _0: false
};

var amplitudeTrackingPlanTemplate_propertyListParser = {
  TAG: /* SkipBoolean */1,
  _0: false
};

var amplitudeTrackingPlanTemplate_propertyMatchesParser = {
  TAG: /* MapStringArray */0,
  _0: "Sample Values",
  _1: parseStringArray
};

var amplitudeTrackingPlanTemplate_propertySendAsParser = {
  TAG: /* MapSendAs */0,
  _0: "Property Type",
  _1: (function (value) {
      var match = value.toLocaleLowerCase();
      switch (match) {
        case "event" :
            return /* EventProperty */1;
        case "system" :
            return /* SystemProperty */0;
        case "user" :
            return /* UserProperty */2;
        default:
          return /* EventProperty */1;
      }
    })
};

var amplitudeTrackingPlanTemplate_propertyGroupParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var amplitudeTrackingPlanTemplate_propertyEventsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var amplitudeTrackingPlanTemplate_propertyNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var amplitudeTrackingPlanTemplate_propertyPinnedValueParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var amplitudeTrackingPlanTemplate = {
  formatName: "Amplitude Taxonomy Template",
  eventNameParser: amplitudeTrackingPlanTemplate_eventNameParser,
  eventDescriptionParser: amplitudeTrackingPlanTemplate_eventDescriptionParser,
  eventCategoriesParser: amplitudeTrackingPlanTemplate_eventCategoriesParser,
  eventTagsParser: amplitudeTrackingPlanTemplate_eventTagsParser,
  eventSourcesParser: amplitudeTrackingPlanTemplate_eventSourcesParser,
  eventNameMappingParser: amplitudeTrackingPlanTemplate_eventNameMappingParser,
  propertyNameParser: amplitudeTrackingPlanTemplate_propertyNameParser,
  propertyDescriptionParser: amplitudeTrackingPlanTemplate_propertyDescriptionParser,
  propertyTypeParser: amplitudeTrackingPlanTemplate_propertyTypeParser,
  propertyRequiredParser: amplitudeTrackingPlanTemplate_propertyRequiredParser,
  propertyListParser: amplitudeTrackingPlanTemplate_propertyListParser,
  propertyMatchesParser: amplitudeTrackingPlanTemplate_propertyMatchesParser,
  propertySendAsParser: amplitudeTrackingPlanTemplate_propertySendAsParser,
  propertyGroupParser: amplitudeTrackingPlanTemplate_propertyGroupParser,
  propertyEventsParser: amplitudeTrackingPlanTemplate_propertyEventsParser,
  propertyNameMappingParser: amplitudeTrackingPlanTemplate_propertyNameMappingParser,
  propertyPinnedValueParser: amplitudeTrackingPlanTemplate_propertyPinnedValueParser,
  customParser: undefined
};

var amplitudeDataExport_eventNameParser = {
  TAG: /* ReadRequiredWithAnotherColumnConditional */6,
  _0: "Object Name",
  _1: "Object Type",
  _2: (function (value) {
      return value === "Event";
    })
};

var amplitudeDataExport_eventDescriptionParser = {
  TAG: /* Read */0,
  _0: "Object Description"
};

var amplitudeDataExport_eventCategoriesParser = {
  TAG: /* MapStringArray */0,
  _0: "Event Category",
  _1: parseStringArray
};

var amplitudeDataExport_eventTagsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var amplitudeDataExport_eventSourcesParser = {
  TAG: /* MapStringArray */0,
  _0: "Event Source",
  _1: parseStringArray
};

var amplitudeDataExport_eventNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var amplitudeDataExport_propertyNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Event Property Name"
};

var amplitudeDataExport_propertyDescriptionParser = {
  TAG: /* Read */0,
  _0: "Property Description"
};

var amplitudeDataExport_propertyTypeParser = {
  TAG: /* Map */0,
  _0: "Const Value",
  _1: (function (value) {
      if (Caml_obj.caml_equal(parseStringArray(value), [])) {
        return [
                "any",
                undefined
              ];
      } else {
        return [
                "string",
                undefined
              ];
      }
    })
};

var amplitudeDataExport_propertyRequiredParser = {
  TAG: /* SkipBoolean */1,
  _0: false
};

var amplitudeDataExport_propertyListParser = {
  TAG: /* SkipBoolean */1,
  _0: false
};

var amplitudeDataExport_propertyMatchesParser = {
  TAG: /* MapStringArray */0,
  _0: "Const Value",
  _1: parseStringArray
};

var amplitudeDataExport_propertySendAsParser = {
  TAG: /* MapSendAs */0,
  _0: "Property Type",
  _1: (function (value) {
      var match = value.toLocaleLowerCase();
      switch (match) {
        case "event" :
            return /* EventProperty */1;
        case "system" :
            return /* SystemProperty */0;
        case "user" :
            return /* UserProperty */2;
        default:
          return /* EventProperty */1;
      }
    })
};

var amplitudeDataExport_propertyGroupParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var amplitudeDataExport_propertyEventsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var amplitudeDataExport_propertyNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var amplitudeDataExport_propertyPinnedValueParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var amplitudeDataExport = {
  formatName: "Amplitude Data Template",
  eventNameParser: amplitudeDataExport_eventNameParser,
  eventDescriptionParser: amplitudeDataExport_eventDescriptionParser,
  eventCategoriesParser: amplitudeDataExport_eventCategoriesParser,
  eventTagsParser: amplitudeDataExport_eventTagsParser,
  eventSourcesParser: amplitudeDataExport_eventSourcesParser,
  eventNameMappingParser: amplitudeDataExport_eventNameMappingParser,
  propertyNameParser: amplitudeDataExport_propertyNameParser,
  propertyDescriptionParser: amplitudeDataExport_propertyDescriptionParser,
  propertyTypeParser: amplitudeDataExport_propertyTypeParser,
  propertyRequiredParser: amplitudeDataExport_propertyRequiredParser,
  propertyListParser: amplitudeDataExport_propertyListParser,
  propertyMatchesParser: amplitudeDataExport_propertyMatchesParser,
  propertySendAsParser: amplitudeDataExport_propertySendAsParser,
  propertyGroupParser: amplitudeDataExport_propertyGroupParser,
  propertyEventsParser: amplitudeDataExport_propertyEventsParser,
  propertyNameMappingParser: amplitudeDataExport_propertyNameMappingParser,
  propertyPinnedValueParser: amplitudeDataExport_propertyPinnedValueParser,
  customParser: undefined
};

var mixpanelTrackingPlanTemplate_eventNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Event Name"
};

var mixpanelTrackingPlanTemplate_eventDescriptionParser = {
  TAG: /* Read */0,
  _0: "Event Definition"
};

var mixpanelTrackingPlanTemplate_eventCategoriesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var mixpanelTrackingPlanTemplate_eventTagsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var mixpanelTrackingPlanTemplate_eventSourcesParser = {
  TAG: /* MapStringArray */0,
  _0: "Platform",
  _1: parseStringArray
};

var mixpanelTrackingPlanTemplate_eventNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var mixpanelTrackingPlanTemplate_propertyNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Property Name"
};

var mixpanelTrackingPlanTemplate_propertyDescriptionParser = {
  TAG: /* Read */0,
  _0: "Property Definition"
};

var mixpanelTrackingPlanTemplate_propertyTypeParser = {
  TAG: /* Map */0,
  _0: "Data Type",
  _1: parsePropertyType
};

var mixpanelTrackingPlanTemplate_propertyRequiredParser = {
  TAG: /* SkipBoolean */1,
  _0: false
};

var mixpanelTrackingPlanTemplate_propertyListParser = {
  TAG: /* MapBoolean */0,
  _0: "Data Type",
  _1: (function (value) {
      var value$1 = value.toLocaleLowerCase();
      if (value$1 === "list") {
        return true;
      } else {
        return value$1 === "array";
      }
    })
};

var mixpanelTrackingPlanTemplate_propertyMatchesParser = {
  TAG: /* MapStringArray */0,
  _0: "Sample Values",
  _1: parseStringArray
};

var mixpanelTrackingPlanTemplate_propertySendAsParser = {
  TAG: /* SkipSendAs */1,
  _0: /* EventProperty */1
};

var mixpanelTrackingPlanTemplate_propertyGroupParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var mixpanelTrackingPlanTemplate_propertyEventsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var mixpanelTrackingPlanTemplate_propertyNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var mixpanelTrackingPlanTemplate_propertyPinnedValueParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var mixpanelTrackingPlanTemplate = {
  formatName: "Mixpanel Tracking Plan Template",
  eventNameParser: mixpanelTrackingPlanTemplate_eventNameParser,
  eventDescriptionParser: mixpanelTrackingPlanTemplate_eventDescriptionParser,
  eventCategoriesParser: mixpanelTrackingPlanTemplate_eventCategoriesParser,
  eventTagsParser: mixpanelTrackingPlanTemplate_eventTagsParser,
  eventSourcesParser: mixpanelTrackingPlanTemplate_eventSourcesParser,
  eventNameMappingParser: mixpanelTrackingPlanTemplate_eventNameMappingParser,
  propertyNameParser: mixpanelTrackingPlanTemplate_propertyNameParser,
  propertyDescriptionParser: mixpanelTrackingPlanTemplate_propertyDescriptionParser,
  propertyTypeParser: mixpanelTrackingPlanTemplate_propertyTypeParser,
  propertyRequiredParser: mixpanelTrackingPlanTemplate_propertyRequiredParser,
  propertyListParser: mixpanelTrackingPlanTemplate_propertyListParser,
  propertyMatchesParser: mixpanelTrackingPlanTemplate_propertyMatchesParser,
  propertySendAsParser: mixpanelTrackingPlanTemplate_propertySendAsParser,
  propertyGroupParser: mixpanelTrackingPlanTemplate_propertyGroupParser,
  propertyEventsParser: mixpanelTrackingPlanTemplate_propertyEventsParser,
  propertyNameMappingParser: mixpanelTrackingPlanTemplate_propertyNameMappingParser,
  propertyPinnedValueParser: mixpanelTrackingPlanTemplate_propertyPinnedValueParser,
  customParser: undefined
};

var amplitudeUserPropertiesExport_eventNameParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var amplitudeUserPropertiesExport_eventDescriptionParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var amplitudeUserPropertiesExport_eventCategoriesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var amplitudeUserPropertiesExport_eventTagsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var amplitudeUserPropertiesExport_eventSourcesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var amplitudeUserPropertiesExport_eventNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var amplitudeUserPropertiesExport_propertyNameParser = {
  TAG: /* ReadRequired */3,
  _0: "User Property"
};

var amplitudeUserPropertiesExport_propertyDescriptionParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var amplitudeUserPropertiesExport_propertyTypeParser = {
  TAG: /* SkipWithDefault */1,
  _0: "any"
};

var amplitudeUserPropertiesExport_propertyRequiredParser = {
  TAG: /* SkipBoolean */1,
  _0: true
};

var amplitudeUserPropertiesExport_propertyListParser = {
  TAG: /* SkipBoolean */1,
  _0: false
};

var amplitudeUserPropertiesExport_propertyMatchesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var amplitudeUserPropertiesExport_propertySendAsParser = {
  TAG: /* SkipSendAs */1,
  _0: /* UserProperty */2
};

var amplitudeUserPropertiesExport_propertyGroupParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var amplitudeUserPropertiesExport_propertyEventsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var amplitudeUserPropertiesExport_propertyNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var amplitudeUserPropertiesExport_propertyPinnedValueParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var amplitudeUserPropertiesExport = {
  formatName: "Amplitude User Properties CSV Export",
  eventNameParser: amplitudeUserPropertiesExport_eventNameParser,
  eventDescriptionParser: amplitudeUserPropertiesExport_eventDescriptionParser,
  eventCategoriesParser: amplitudeUserPropertiesExport_eventCategoriesParser,
  eventTagsParser: amplitudeUserPropertiesExport_eventTagsParser,
  eventSourcesParser: amplitudeUserPropertiesExport_eventSourcesParser,
  eventNameMappingParser: amplitudeUserPropertiesExport_eventNameMappingParser,
  propertyNameParser: amplitudeUserPropertiesExport_propertyNameParser,
  propertyDescriptionParser: amplitudeUserPropertiesExport_propertyDescriptionParser,
  propertyTypeParser: amplitudeUserPropertiesExport_propertyTypeParser,
  propertyRequiredParser: amplitudeUserPropertiesExport_propertyRequiredParser,
  propertyListParser: amplitudeUserPropertiesExport_propertyListParser,
  propertyMatchesParser: amplitudeUserPropertiesExport_propertyMatchesParser,
  propertySendAsParser: amplitudeUserPropertiesExport_propertySendAsParser,
  propertyGroupParser: amplitudeUserPropertiesExport_propertyGroupParser,
  propertyEventsParser: amplitudeUserPropertiesExport_propertyEventsParser,
  propertyNameMappingParser: amplitudeUserPropertiesExport_propertyNameMappingParser,
  propertyPinnedValueParser: amplitudeUserPropertiesExport_propertyPinnedValueParser,
  customParser: undefined
};

var groupPropertiesTemplate_eventNameParser = {
  TAG: /* SkipWithDefault */5,
  _0: "skip-event"
};

var groupPropertiesTemplate_eventDescriptionParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var groupPropertiesTemplate_eventCategoriesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var groupPropertiesTemplate_eventTagsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var groupPropertiesTemplate_eventSourcesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var groupPropertiesTemplate_eventNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var groupPropertiesTemplate_propertyNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Property Name"
};

var groupPropertiesTemplate_propertyDescriptionParser = {
  TAG: /* ReadRequired */3,
  _0: "Property Description"
};

var groupPropertiesTemplate_propertyTypeParser = {
  TAG: /* Map */0,
  _0: "Property Value Type",
  _1: parsePropertyType
};

var groupPropertiesTemplate_propertyRequiredParser = {
  TAG: /* SkipBoolean */1,
  _0: true
};

var groupPropertiesTemplate_propertyListParser = {
  TAG: /* MapBoolean */0,
  _0: "Is Property Array?",
  _1: parsePropertyList
};

var groupPropertiesTemplate_propertyMatchesParser = {
  TAG: /* MapStringArray */0,
  _0: "Property Enumeration Options",
  _1: parseStringArray
};

var groupPropertiesTemplate_propertySendAsParser = {
  TAG: /* MapSendAs */0,
  _0: "Group Type",
  _1: (function (groupTypeName) {
      return /* GroupProperty */{
              _0: groupTypeName
            };
    })
};

var groupPropertiesTemplate_propertyGroupParser = {
  TAG: /* Read */0,
  _0: "Group Type"
};

var groupPropertiesTemplate_propertyEventsParser = {
  TAG: /* MapStringArray */0,
  _0: "Sent with Events",
  _1: (function (string) {
      return Belt_Array.map(string.split("/"), (function (prim) {
                    return prim.trim();
                  }));
    })
};

var groupPropertiesTemplate_propertyNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var groupPropertiesTemplate_propertyPinnedValueParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var groupPropertiesTemplate = {
  formatName: "Group Properties Template",
  eventNameParser: groupPropertiesTemplate_eventNameParser,
  eventDescriptionParser: groupPropertiesTemplate_eventDescriptionParser,
  eventCategoriesParser: groupPropertiesTemplate_eventCategoriesParser,
  eventTagsParser: groupPropertiesTemplate_eventTagsParser,
  eventSourcesParser: groupPropertiesTemplate_eventSourcesParser,
  eventNameMappingParser: groupPropertiesTemplate_eventNameMappingParser,
  propertyNameParser: groupPropertiesTemplate_propertyNameParser,
  propertyDescriptionParser: groupPropertiesTemplate_propertyDescriptionParser,
  propertyTypeParser: groupPropertiesTemplate_propertyTypeParser,
  propertyRequiredParser: groupPropertiesTemplate_propertyRequiredParser,
  propertyListParser: groupPropertiesTemplate_propertyListParser,
  propertyMatchesParser: groupPropertiesTemplate_propertyMatchesParser,
  propertySendAsParser: groupPropertiesTemplate_propertySendAsParser,
  propertyGroupParser: groupPropertiesTemplate_propertyGroupParser,
  propertyEventsParser: groupPropertiesTemplate_propertyEventsParser,
  propertyNameMappingParser: groupPropertiesTemplate_propertyNameMappingParser,
  propertyPinnedValueParser: groupPropertiesTemplate_propertyPinnedValueParser,
  customParser: undefined
};

var customSpreadsheetFormatOne_eventNameParser = {
  TAG: /* SkipWithDefault */5,
  _0: "skip-event"
};

var customSpreadsheetFormatOne_eventDescriptionParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var customSpreadsheetFormatOne_eventCategoriesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var customSpreadsheetFormatOne_eventTagsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var customSpreadsheetFormatOne_eventSourcesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var customSpreadsheetFormatOne_eventNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var customSpreadsheetFormatOne_propertyNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Parameter Name"
};

var customSpreadsheetFormatOne_propertyDescriptionParser = {
  TAG: /* ReadAndConcat */2,
  _0: [
    "Definition",
    "Example Value (Goal)",
    "Default Value (GTM)",
    "DQD Requirement (regex)",
    "GTM Name"
  ]
};

var customSpreadsheetFormatOne_propertyTypeParser = {
  TAG: /* Map */0,
  _0: "Format",
  _1: parsePropertyType
};

var customSpreadsheetFormatOne_propertyRequiredParser = {
  TAG: /* SkipBoolean */1,
  _0: false
};

var customSpreadsheetFormatOne_propertyListParser = {
  TAG: /* SkipBoolean */1,
  _0: false
};

var customSpreadsheetFormatOne_propertyMatchesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var customSpreadsheetFormatOne_propertySendAsParser = {
  TAG: /* SkipSendAs */1,
  _0: /* EventProperty */1
};

var customSpreadsheetFormatOne_propertyGroupParser = {
  TAG: /* Read */0,
  _0: "Property Group"
};

var customSpreadsheetFormatOne_propertyEventsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var customSpreadsheetFormatOne_propertyNameMappingParser = {
  TAG: /* Map */0,
  _0: "nameMapping:GoogleAnalytics",
  _1: (function (nameMapping) {
      return [
              nameMapping,
              nameMapping !== "" ? "Google Analytics" : undefined
            ];
    })
};

var customSpreadsheetFormatOne_propertyPinnedValueParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var customSpreadsheetFormatOne_customParser = ImportFormatsCustomSpreadsheetFormatOneParser.eventsParser;

var customSpreadsheetFormatOne = {
  formatName: "Custom spreadsheet format #1",
  eventNameParser: customSpreadsheetFormatOne_eventNameParser,
  eventDescriptionParser: customSpreadsheetFormatOne_eventDescriptionParser,
  eventCategoriesParser: customSpreadsheetFormatOne_eventCategoriesParser,
  eventTagsParser: customSpreadsheetFormatOne_eventTagsParser,
  eventSourcesParser: customSpreadsheetFormatOne_eventSourcesParser,
  eventNameMappingParser: customSpreadsheetFormatOne_eventNameMappingParser,
  propertyNameParser: customSpreadsheetFormatOne_propertyNameParser,
  propertyDescriptionParser: customSpreadsheetFormatOne_propertyDescriptionParser,
  propertyTypeParser: customSpreadsheetFormatOne_propertyTypeParser,
  propertyRequiredParser: customSpreadsheetFormatOne_propertyRequiredParser,
  propertyListParser: customSpreadsheetFormatOne_propertyListParser,
  propertyMatchesParser: customSpreadsheetFormatOne_propertyMatchesParser,
  propertySendAsParser: customSpreadsheetFormatOne_propertySendAsParser,
  propertyGroupParser: customSpreadsheetFormatOne_propertyGroupParser,
  propertyEventsParser: customSpreadsheetFormatOne_propertyEventsParser,
  propertyNameMappingParser: customSpreadsheetFormatOne_propertyNameMappingParser,
  propertyPinnedValueParser: customSpreadsheetFormatOne_propertyPinnedValueParser,
  customParser: customSpreadsheetFormatOne_customParser
};

var customSpreadsheetFormatTwo_eventNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Event_Name"
};

var customSpreadsheetFormatTwo_eventDescriptionParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var customSpreadsheetFormatTwo_eventCategoriesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var customSpreadsheetFormatTwo_eventTagsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var customSpreadsheetFormatTwo_eventSourcesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var customSpreadsheetFormatTwo_eventNameMappingParser = {
  TAG: /* Map */0,
  _0: "Name_Mapping",
  _1: (function (nameMapping) {
      return [
              nameMapping,
              nameMapping !== "" ? "Firebase Analytics" : undefined
            ];
    })
};

var customSpreadsheetFormatTwo_propertyNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Event_property_Name"
};

var customSpreadsheetFormatTwo_propertyDescriptionParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var customSpreadsheetFormatTwo_propertyTypeParser = {
  TAG: /* Map */0,
  _0: "Event_Property_Type",
  _1: parsePropertyType
};

var customSpreadsheetFormatTwo_propertyRequiredParser = {
  TAG: /* MapBoolean */0,
  _0: "Is_Property_Required",
  _1: parsePropertyRequired
};

var customSpreadsheetFormatTwo_propertyListParser = {
  TAG: /* MapBoolean */0,
  _0: "Is_Property_Array",
  _1: parsePropertyList
};

var customSpreadsheetFormatTwo_propertyMatchesParser = {
  TAG: /* MapStringArray */0,
  _0: "Event_Property_Allowed_Values",
  _1: parseStringArray
};

var customSpreadsheetFormatTwo_propertySendAsParser = {
  TAG: /* SkipSendAs */1,
  _0: /* EventProperty */1
};

var customSpreadsheetFormatTwo_propertyGroupParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var customSpreadsheetFormatTwo_propertyEventsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var customSpreadsheetFormatTwo_propertyNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var customSpreadsheetFormatTwo_propertyPinnedValueParser = {
  TAG: /* Map */4,
  _0: "Event_Property_Pinned_Value",
  _1: (function (pinnedValue) {
      if (pinnedValue !== "") {
        return pinnedValue;
      } else {
        return "";
      }
    })
};

var customSpreadsheetFormatTwo = {
  formatName: "Custom spreadsheet format #2",
  eventNameParser: customSpreadsheetFormatTwo_eventNameParser,
  eventDescriptionParser: customSpreadsheetFormatTwo_eventDescriptionParser,
  eventCategoriesParser: customSpreadsheetFormatTwo_eventCategoriesParser,
  eventTagsParser: customSpreadsheetFormatTwo_eventTagsParser,
  eventSourcesParser: customSpreadsheetFormatTwo_eventSourcesParser,
  eventNameMappingParser: customSpreadsheetFormatTwo_eventNameMappingParser,
  propertyNameParser: customSpreadsheetFormatTwo_propertyNameParser,
  propertyDescriptionParser: customSpreadsheetFormatTwo_propertyDescriptionParser,
  propertyTypeParser: customSpreadsheetFormatTwo_propertyTypeParser,
  propertyRequiredParser: customSpreadsheetFormatTwo_propertyRequiredParser,
  propertyListParser: customSpreadsheetFormatTwo_propertyListParser,
  propertyMatchesParser: customSpreadsheetFormatTwo_propertyMatchesParser,
  propertySendAsParser: customSpreadsheetFormatTwo_propertySendAsParser,
  propertyGroupParser: customSpreadsheetFormatTwo_propertyGroupParser,
  propertyEventsParser: customSpreadsheetFormatTwo_propertyEventsParser,
  propertyNameMappingParser: customSpreadsheetFormatTwo_propertyNameMappingParser,
  propertyPinnedValueParser: customSpreadsheetFormatTwo_propertyPinnedValueParser,
  customParser: undefined
};

var customSpreadsheetFormatThree_eventNameParser = {
  TAG: /* ReadRequired */3,
  _0: "Event name"
};

var customSpreadsheetFormatThree_eventDescriptionParser = {
  TAG: /* ReadRequired */3,
  _0: "Event"
};

var customSpreadsheetFormatThree_eventCategoriesParser = {
  TAG: /* MapStringArray */0,
  _0: "User Flow",
  _1: parseStringArray
};

var customSpreadsheetFormatThree_eventTagsParser = {
  TAG: /* MapStringArrayFromOptionalColumn */1,
  _0: "Tags",
  _1: parseStringArray
};

var customSpreadsheetFormatThree_eventSourcesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var customSpreadsheetFormatThree_eventNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var customSpreadsheetFormatThree_propertyNameParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var customSpreadsheetFormatThree_propertyDescriptionParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var customSpreadsheetFormatThree_propertyTypeParser = {
  TAG: /* SkipWithDefault */1,
  _0: "string"
};

var customSpreadsheetFormatThree_propertyRequiredParser = {
  TAG: /* SkipBoolean */1,
  _0: true
};

var customSpreadsheetFormatThree_propertyListParser = {
  TAG: /* SkipBoolean */1,
  _0: false
};

var customSpreadsheetFormatThree_propertyMatchesParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var customSpreadsheetFormatThree_propertySendAsParser = {
  TAG: /* SkipSendAs */1,
  _0: /* EventProperty */1
};

var customSpreadsheetFormatThree_propertyGroupParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var customSpreadsheetFormatThree_propertyEventsParser = {
  TAG: /* SkipStringArray */2,
  _0: []
};

var customSpreadsheetFormatThree_propertyNameMappingParser = {
  TAG: /* SkipWithDefault */1,
  _0: ""
};

var customSpreadsheetFormatThree_propertyPinnedValueParser = {
  TAG: /* SkipWithDefault */5,
  _0: ""
};

var customSpreadsheetFormatThree = {
  formatName: "Custom spreadsheet format #3",
  eventNameParser: customSpreadsheetFormatThree_eventNameParser,
  eventDescriptionParser: customSpreadsheetFormatThree_eventDescriptionParser,
  eventCategoriesParser: customSpreadsheetFormatThree_eventCategoriesParser,
  eventTagsParser: customSpreadsheetFormatThree_eventTagsParser,
  eventSourcesParser: customSpreadsheetFormatThree_eventSourcesParser,
  eventNameMappingParser: customSpreadsheetFormatThree_eventNameMappingParser,
  propertyNameParser: customSpreadsheetFormatThree_propertyNameParser,
  propertyDescriptionParser: customSpreadsheetFormatThree_propertyDescriptionParser,
  propertyTypeParser: customSpreadsheetFormatThree_propertyTypeParser,
  propertyRequiredParser: customSpreadsheetFormatThree_propertyRequiredParser,
  propertyListParser: customSpreadsheetFormatThree_propertyListParser,
  propertyMatchesParser: customSpreadsheetFormatThree_propertyMatchesParser,
  propertySendAsParser: customSpreadsheetFormatThree_propertySendAsParser,
  propertyGroupParser: customSpreadsheetFormatThree_propertyGroupParser,
  propertyEventsParser: customSpreadsheetFormatThree_propertyEventsParser,
  propertyNameMappingParser: customSpreadsheetFormatThree_propertyNameMappingParser,
  propertyPinnedValueParser: customSpreadsheetFormatThree_propertyPinnedValueParser,
  customParser: undefined
};

var formats_1 = {
  hd: amplitudeExport,
  tl: {
    hd: mixpanelExport,
    tl: {
      hd: mixpanelLegacyExport,
      tl: {
        hd: avoSpreadsheetWithTypo,
        tl: {
          hd: avoSpreadsheet,
          tl: {
            hd: amplitudeTrackingPlanTemplate,
            tl: {
              hd: amplitudeDataExport,
              tl: {
                hd: mixpanelTrackingPlanTemplate,
                tl: {
                  hd: amplitudeUserPropertiesExport,
                  tl: {
                    hd: groupPropertiesTemplate,
                    tl: {
                      hd: customSpreadsheetFormatOne,
                      tl: {
                        hd: customSpreadsheetFormatTwo,
                        tl: {
                          hd: customSpreadsheetFormatThree,
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var formats = {
  hd: avoExport,
  tl: formats_1
};

exports.parsePropertyType = parsePropertyType;
exports.parsePropertyRequired = parsePropertyRequired;
exports.parsePropertyList = parsePropertyList;
exports.parseStringArray = parseStringArray;
exports.amplitudeExport = amplitudeExport;
exports.mixpanelExport = mixpanelExport;
exports.mixpanelLegacyExport = mixpanelLegacyExport;
exports.avoSpreadsheetWithTypo = avoSpreadsheetWithTypo;
exports.avoSpreadsheet = avoSpreadsheet;
exports.avoExport = avoExport;
exports.amplitudeTrackingPlanTemplate = amplitudeTrackingPlanTemplate;
exports.amplitudeDataExport = amplitudeDataExport;
exports.mixpanelTrackingPlanTemplate = mixpanelTrackingPlanTemplate;
exports.amplitudeUserPropertiesExport = amplitudeUserPropertiesExport;
exports.groupPropertiesTemplate = groupPropertiesTemplate;
exports.customSpreadsheetFormatOne = customSpreadsheetFormatOne;
exports.customSpreadsheetFormatTwo = customSpreadsheetFormatTwo;
exports.customSpreadsheetFormatThree = customSpreadsheetFormatThree;
exports.formats = formats;
/* Sentry Not a pure module */
