// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Styles = require("./styles.bs.js");

function IconChevron(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.white;
  return React.createElement("svg", {
              width: String(size),
              fill: "none",
              viewBox: "0 0 14 8",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M12.7923 0.604792C12.4123 0.261134 11.834 0.26028 11.453 0.602815L7 4.60606L2.547 0.602815C2.16598 0.260279 1.58771 0.261132 1.2077 0.604789L0.821918 0.95367C0.382271 1.35126 0.383016 2.04185 0.823519 2.43849L6.33086 7.39748C6.71122 7.73997 7.28878 7.73997 7.66914 7.39748L13.1765 2.4385C13.617 2.04185 13.6177 1.35126 13.1781 0.953671L12.7923 0.604792Z",
                  fill: Styles.Color.toString(color)
                }));
}

var make = IconChevron;

exports.make = make;
/* react Not a pure module */
