// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var EventStatus = require("./implementationStatus/EventStatus.bs.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");

var empty = {
  events: undefined
};

function encodeSource(source) {
  return Json_encode.object_({
              hd: [
                "sourceId",
                source.sourceId
              ],
              tl: {
                hd: [
                  "highestVersion",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), source.highestVersion)
                ],
                tl: {
                  hd: [
                    "status",
                    EventStatus.encode(source.status)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decodeSource(json) {
  return {
          sourceId: Json_decode.field("sourceId", Json_decode.string, json),
          highestVersion: Json_decode.optional((function (param) {
                  return Json_decode.field("highestVersion", Json_decode.string, param);
                }), json),
          status: Json_decode.field("status", EventStatus.decode, json)
        };
}

function encodeEvent($$event) {
  var tupleEncoder = function (tuple) {
    return Json_encode.tuple2((function (prim) {
                  return prim;
                }), encodeSource, tuple);
  };
  return Json_encode.object_({
              hd: [
                "eventName",
                $$event.eventName
              ],
              tl: {
                hd: [
                  "sources",
                  Json_encode.array(tupleEncoder, Belt_MapString.toArray($$event.sources))
                ],
                tl: /* [] */0
              }
            });
}

function decodeEvent(json) {
  var tupleDecoder = function (json) {
    return Json_decode.tuple2(Json_decode.string, decodeSource, json);
  };
  return {
          eventName: Json_decode.field("eventName", Json_decode.string, json),
          sources: Belt_MapString.fromArray(Json_decode.field("sources", (function (param) {
                      return Json_decode.array(tupleDecoder, param);
                    }), json))
        };
}

function encode(t) {
  var tupleEncoder = function (tuple) {
    return Json_encode.tuple2((function (prim) {
                  return prim;
                }), encodeEvent, tuple);
  };
  return Json_encode.object_({
              hd: [
                "events",
                Json_encode.array(tupleEncoder, Belt_MapString.toArray(t.events))
              ],
              tl: /* [] */0
            });
}

function decode(json) {
  var tupleDecoder = function (json) {
    return Json_decode.tuple2(Json_decode.string, decodeEvent, json);
  };
  return {
          events: Belt_MapString.fromArray(Json_decode.field("events", (function (param) {
                      return Json_decode.array(tupleDecoder, param);
                    }), json))
        };
}

exports.empty = empty;
exports.encodeSource = encodeSource;
exports.decodeSource = decodeSource;
exports.encodeEvent = encodeEvent;
exports.decodeEvent = decodeEvent;
exports.encode = encode;
exports.decode = decode;
/* No side effect */
