import Quill from 'quill';
import React from 'react';
import ReactDOM from 'react-dom';
import UserBlot from '../../../app/src/UserBlot.bs';

const Embed = Quill.import('blots/embed');

class UserMentionBlot extends Embed {
  static create(data) {
    const node = super.create();
    node.dataset.id = data.id;
    node.dataset.value = data.value;
    node.dataset.denotationChar = data.denotationChar;
    node.dataset.type = data.type;
    ReactDOM.render(
      React.createElement(UserBlot.jsComponent, {
        userName: data.value,
        userId: data.id,
        schemaId: data.schemaId,
      }),
      node,
    );
    return node;
  }

  static value(domNode) {
    return {
      id: domNode.dataset.id,
      value: domNode.dataset.value,
      denotationChar: domNode.dataset.denotationChar,
      type: domNode.dataset.type,
      schemaId: domNode.dataset.schemaId,
    };
  }
}

UserMentionBlot.blotName = 'user-mention';
UserMentionBlot.tagName = 'span';
UserMentionBlot.className = 'user-mention';

Quill.register(UserMentionBlot);
