// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");

function OpsWithDeltaState(Props) {
  var value = Props.value;
  var onFlush = Props.onFlush;
  var onChangeCompleted = Props.onChangeCompleted;
  var children = Props.children;
  var match = Hooks.useOpsWithDeltaState(value, onFlush, onChangeCompleted);
  return Curry._4(children, match[0], match[1], match[2], match[3]);
}

var make = OpsWithDeltaState;

exports.make = make;
/* Hooks Not a pure module */
