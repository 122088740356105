// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function encode(promotionCode) {
  return Json_encode.object_({
              hd: [
                "id",
                promotionCode.id
              ],
              tl: {
                hd: [
                  "amount_off",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), promotionCode.amount_off)
                ],
                tl: {
                  hd: [
                    "duration",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), promotionCode.duration)
                  ],
                  tl: {
                    hd: [
                      "duration_in_months",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), promotionCode.duration_in_months)
                    ],
                    tl: {
                      hd: [
                        "livemode",
                        promotionCode.livemode
                      ],
                      tl: {
                        hd: [
                          "name",
                          promotionCode.name
                        ],
                        tl: {
                          hd: [
                            "percent_off",
                            Json_encode.nullable((function (prim) {
                                    return prim;
                                  }), promotionCode.percent_off)
                          ],
                          tl: {
                            hd: [
                              "valid",
                              promotionCode.valid
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          amount_off: Json_decode.optional((function (param) {
                  return Json_decode.field("amount_off", Json_decode.$$float, param);
                }), json),
          duration: Json_decode.optional((function (param) {
                  return Json_decode.field("duration", Json_decode.string, param);
                }), json),
          duration_in_months: Json_decode.optional((function (param) {
                  return Json_decode.field("duration_in_months", Json_decode.$$int, param);
                }), json),
          livemode: Json_decode.field("livemode", Json_decode.bool, json),
          name: Json_decode.field("name", Json_decode.string, json),
          percent_off: Json_decode.optional((function (param) {
                  return Json_decode.field("percent_off", Json_decode.$$float, param);
                }), json),
          valid: Json_decode.field("valid", Json_decode.bool, json)
        };
}

var Coupon = {
  encode: encode,
  decode: decode
};

function encode$1(promotionCode) {
  return Json_encode.object_({
              hd: [
                "id",
                promotionCode.id
              ],
              tl: {
                hd: [
                  "active",
                  promotionCode.active
                ],
                tl: {
                  hd: [
                    "coupon",
                    encode(promotionCode.coupon)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode$1(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          active: Json_decode.field("active", Json_decode.bool, json),
          coupon: Json_decode.field("coupon", decode, json)
        };
}

var PromotionCode = {
  encode: encode$1,
  decode: decode$1
};

function decodeSubscriptionItemPrice(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          recurring: Json_decode.field("recurring", (function (json) {
                  return {
                          interval: Json_decode.field("interval", Json_decode.string, json),
                          interval_count: Json_decode.field("interval_count", Json_decode.$$int, json)
                        };
                }), json)
        };
}

function decodeSubscriptionItem(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          quantity: Json_decode.field("quantity", Json_decode.$$int, json),
          price: Json_decode.field("price", decodeSubscriptionItemPrice, json)
        };
}

function decode$2(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          customer: Json_decode.field("customer", Json_decode.string, json),
          status: Json_decode.field("status", Json_decode.string, json),
          livemode: Json_decode.field("livemode", Json_decode.bool, json),
          items: Json_decode.field("items", (function (json) {
                  return {
                          data: Json_decode.field("data", (function (param) {
                                  return Json_decode.array(decodeSubscriptionItem, param);
                                }), json)
                        };
                }), json)
        };
}

var Subscription = {
  decodeSubscriptionItemPrice: decodeSubscriptionItemPrice,
  decodeSubscriptionItem: decodeSubscriptionItem,
  decode: decode$2
};

exports.Coupon = Coupon;
exports.PromotionCode = PromotionCode;
exports.Subscription = Subscription;
/* No side effect */
