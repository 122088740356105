// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconSideEffect(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey40;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.stroke(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.fill(color),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("svg", {
                  height: String(size),
                  viewBox: "0 0 16 16",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("g", {
                      strokeWidth: "1"
                    }, React.createElement("polygon", {
                          fill: "none",
                          points: "9.5,0.5 7.5,6.5 15.5,6.5 6.5,15.5 7.5,9.5 0.5,9.5 ",
                          strokeLinecap: "round"
                        }))));
}

var make = IconSideEffect;

exports.make = make;
/* Css Not a pure module */
