// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Caml = require("rescript/lib/js/caml.js");
var Icon = require("./Icon.bs.js");
var $$Text = require("./Text.bs.js");
var Uuid = require("uuid");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var Title = require("./Title.bs.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var $$Number = require("./Number.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var DateFns = require("./DateFns.bs.js");
var Mantine = require("./Mantine.bs.js");
var AvoState = require("./AvoState.bs.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Intercom = require("./externals/intercom.bs.js");
var Markdown = require("./Markdown.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var StringExt = require("./StringExt.bs.js");
var TextInput = require("./TextInput.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var InputLabel = require("./InputLabel.bs.js");
var ModelStore = require("./ModelStore.bs.js");
var TextButton = require("./TextButton.bs.js");
var Downloadjs = require("downloadjs");
var React$1 = require("@dopt/react");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var IssuesTable = require("./inspector/IssuesTable.bs.js");
var ProductTour = require("./ProductTour.bs.js");
var RouterStore = require("./RouterStore.bs.js");
var ExportFormat = require("../../shared/models/ExportFormat.bs.js");
var Core = require("@mantine/core");
var FirebaseUtils = require("./firebaseUtils.bs.js");
var SimpleTooltip = require("./SimpleTooltip.bs.js");
var Hooks$1 = require("@mantine/hooks");
var AppFeatureFlag = require("./AppFeatureFlag.bs.js");
var Belt_SortArray = require("rescript/lib/js/belt_SortArray.js");
var InspectorStats = require("./inspector/InspectorStats.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var Debounce = require("lodash/debounce");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var IssuesTableConfig = require("./inspector/IssuesTableConfig.bs.js");
var InspectorIssueType = require("../../shared/models/InspectorIssueType.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var InspectorEmptyState = require("./inspector/InspectorEmptyState.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ServerJs = require("react-dom/server.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var InspectorEventsStore = require("./inspector/InspectorEventsStore.bs.js");
var InspectorIssuesStore = require("./inspector/InspectorIssuesStore.bs.js");
var ConfigureAlertsButton = require("./inspector/ConfigureAlertsButton.bs.js");
var InspectorIssuesFilter = require("../../shared/models/InspectorIssuesFilter.bs.js");
var InspectorIssuesFilters = require("./InspectorIssuesFilters.bs.js");
var InspectorIssuesHelpers = require("./inspector/InspectorIssuesHelpers.bs.js");
var CopyTextToClipboard = require("copy-text-to-clipboard");
var InspectorIssueViewModel = require("./inspector/InspectorIssueViewModel.bs.js");
var InspectorIssuesFilterHooks = require("./inspector/InspectorIssuesFilterHooks.bs.js");
var InspectorIssuesFilterUtils = require("../../shared/utils/InspectorIssuesFilterUtils.bs.js");

var intToLocaleString = (x => x.toLocaleString());

function InspectorIssuesView$EmptyState$Imagery$NoIssues(Props) {
  return React.createElement("svg", {
              height: "102",
              width: "118",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M78.201 0H7.931C3.55 0 0 3.139 0 7.01h86.132c0-3.871-3.55-7.01-7.93-7.01Z",
                  fill: "#CCD1D9"
                }), React.createElement("path", {
                  d: "M0 7.012h86.132v59.613c0 2.65-1.159 5.192-3.22 7.066-2.063 1.875-4.86 2.927-7.777 2.927H10.997c-2.917 0-5.714-1.052-7.776-2.927C1.159 71.817 0 69.275 0 66.625V7.012Z",
                  fill: "url(#a)"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "7.011",
                  y: "14.521"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "7.011",
                  y: "29.545"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "7.011",
                  y: "44.568"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "7.011",
                  y: "59.592"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.031",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "33.051",
                  y: "14.521"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "58.59",
                  y: "14.521"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.031",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "33.051",
                  y: "29.545"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "58.59",
                  y: "29.545"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.031",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "33.051",
                  y: "44.568"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "58.59",
                  y: "44.568"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.031",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "33.051",
                  y: "59.592"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "58.59",
                  y: "59.592"
                }), React.createElement("path", {
                  d: "m91.981 69.892-7.391 8.149 20.834 18.898 7.392-8.149-20.835-18.898Z",
                  fill: "#F87CBC"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M99.793 56.406c0 17.048-13.82 30.867-30.867 30.867-17.048 0-30.867-13.82-30.867-30.867S51.879 25.54 68.926 25.54s30.867 13.82 30.867 30.867Zm-4.735 0c0 14.433-11.7 26.133-26.132 26.133-14.433 0-26.133-11.7-26.133-26.133 0-14.432 11.7-26.132 26.133-26.132 14.432 0 26.132 11.7 26.132 26.133Z",
                  fill: "#F87CBC",
                  fillRule: "evenodd"
                }), React.createElement("g", {
                  filter: "url(#b)"
                }, React.createElement("path", {
                      d: "M68.925 82.538c14.433 0 26.133-11.7 26.133-26.132 0-14.433-11.7-26.133-26.133-26.133-14.432 0-26.132 11.7-26.132 26.133 0 14.432 11.7 26.132 26.133 26.132Z",
                      fill: "url(#c)",
                      fillOpacity: ".2"
                    })), React.createElement("path", {
                  d: "m105.423 96.941 7.391-8.148 1.114 1.01a5.497 5.497 0 1 1-7.389 8.146l-1.113-1.01-.003.002Z",
                  fill: "#F87CBC"
                }), React.createElement("path", {
                  d: "M66.046 65.891h6.129v6.13h-6.13v-6.13Zm6.129-5.04v1.976h-6.13V58.23a3.065 3.065 0 0 1 3.065-3.064 4.597 4.597 0 1 0-4.508-5.498l-6.012-1.204A10.73 10.73 0 1 1 72.175 60.85Z",
                  fill: "#EC008C"
                }), React.createElement("defs", undefined, React.createElement("linearGradient", {
                      id: "a",
                      gradientUnits: "userSpaceOnUse",
                      x1: "-2.586",
                      x2: "121.837",
                      y1: "59.208",
                      y2: "61.642"
                    }, React.createElement("stop", {
                          stopColor: "#fff"
                        }), React.createElement("stop", {
                          offset: "1",
                          stopColor: "#E6E9ED"
                        })), React.createElement("linearGradient", {
                      id: "c",
                      gradientUnits: "userSpaceOnUse",
                      x1: "68.925",
                      x2: "95.258",
                      y1: "30.273",
                      y2: "98.605"
                    }, React.createElement("stop", {
                          stopColor: "#fff"
                        }), React.createElement("stop", {
                          offset: "1",
                          stopColor: "#E6E9ED"
                        })), React.createElement("filter", {
                      id: "b",
                      height: "68.966",
                      width: "68.965",
                      colorInterpolationFilters: "sRGB",
                      filterUnits: "userSpaceOnUse",
                      x: "34.443",
                      y: "21.923"
                    }, React.createElement("feFlood", {
                          floodOpacity: "0",
                          result: "BackgroundImageFix"
                        }), React.createElement("feGaussianBlur", {
                          in: "BackgroundImageFix",
                          stdDeviation: "4.175"
                        }), React.createElement("feComposite", {
                          in2: "SourceAlpha",
                          operator: "in",
                          result: "effect1_backgroundBlur_589_7213"
                        }), React.createElement("feBlend", {
                          in: "SourceGraphic",
                          in2: "effect1_backgroundBlur_589_7213",
                          result: "shape"
                        }))));
}

function InspectorIssuesView$EmptyState$Imagery$Loading(Props) {
  var match = React.useState(function () {
        return Uuid.v4();
      });
  var id = match[0];
  return React.createElement("svg", {
              height: "119",
              width: "119",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("circle", {
                  cx: "59.5",
                  cy: "59.5",
                  fill: "url(#" + id + "-a)",
                  r: "59.5"
                }), React.createElement("path", {
                  d: "M82.8 26.2V39c0 9.5-5.8 17.4-14 21a23 23 0 0 1 14 21v12.7H37V81c0-9.4 5.8-17.4 14-21A23 23 0 0 1 37 39V26.2h45.7Z",
                  fill: "url(#" + id + "-b)"
                }), React.createElement("path", {
                  d: "M59.9 60s-13.6-5.8-13.6-12.8h27.3c-.2 7-13.7 12.8-13.7 12.8ZM44.7 75.4c-1.8 3.9-3 8.7-3 13.6h36.4c0-5.1-1-9.7-3-13.6H44.8Z",
                  fill: "#EC008C"
                }), React.createElement("path", {
                  d: "M84.9 93.7H35c-2 0-3.7.8-3.7 2v1.7c0 1 1.7 2 3.7 2H85c2 0 3.7-1 3.7-2v-1.8c0-1-1.7-2-3.7-2ZM84.9 20.5H35c-2 0-3.7 1-3.7 2v1.8c0 1 1.7 2 3.7 2H85c2 0 3.7-1 3.7-2v-1.8c0-1-1.7-2-3.7-2Z",
                  fill: "#AAB2BD"
                }), React.createElement("path", {
                  d: "M82.8 26.2H37V29h45.7v-2.8Z",
                  fill: "#CCD1D9"
                }), React.createElement("circle", {
                  cx: "60",
                  cy: "64.5",
                  fill: "#EC008C",
                  r: "1.8"
                }), React.createElement("circle", {
                  cx: "60",
                  cy: "70.9",
                  fill: "#EC008C",
                  r: "1.8"
                }), React.createElement("defs", undefined, React.createElement("linearGradient", {
                      id: id + "-a",
                      gradientUnits: "userSpaceOnUse",
                      x1: "59.5",
                      x2: "59.5",
                      y1: "0",
                      y2: "119"
                    }, React.createElement("stop", {
                          stopColor: "#FAFBFD"
                        }), React.createElement("stop", {
                          offset: "1",
                          stopColor: "#E6E9ED"
                        })), React.createElement("linearGradient", {
                      id: id + "-b",
                      gradientUnits: "userSpaceOnUse",
                      x1: "60",
                      x2: "60",
                      y1: "26.2",
                      y2: "93.7"
                    }, React.createElement("stop", {
                          stopColor: "#fff"
                        }), React.createElement("stop", {
                          offset: "1",
                          stopColor: "#F5F7FA"
                        }))));
}

function InspectorIssuesView$EmptyState$Imagery$GetAlerted(Props) {
  return React.createElement("svg", {
              height: "102",
              width: "118",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M45.9 19C30.3 26.3 12.4 37.3 4.7 54.5c-1.3 3-2.3 6-2.9 9.2-1.1 8-1.6 17.5 4.6 24.1 5.6 5.9 14 4 18.2-1.2 10.2-12.9 24.6-17.2 40.8-15.9C70 61.5 58.5 27 46 19.1Z",
                  fill: "#E6E9ED"
                }), React.createElement("path", {
                  d: "M65.5 69.6c3.6-1.4 2-13.5-3.4-27C56.6 29 49.3 19.3 45.8 20.8c-3.6 1.4-2 13.5 3.4 27C54.7 61.3 62 71 65.5 69.6Z",
                  fill: "#CCD1D9",
                  stroke: "#6D7783",
                  strokeMiterlimit: "10",
                  strokeWidth: "4.7"
                }), React.createElement("path", {
                  d: "M72.2 92a2.3 2.3 0 1 0 4.3-1.8l-4.3 1.7Zm4.3-1.8-8.7-21.7-4.3 1.8 8.7 21.6 4.3-1.7Z",
                  fill: "#6D7783"
                }), React.createElement("path", {
                  d: "M69.5 60.5a7.6 7.6 0 1 0 0-15.2 7.6 7.6 0 0 0 0 15.2Z",
                  fill: "#EC008C"
                }), React.createElement("path", {
                  d: "M108.1 14.2a3.1 3.1 0 1 0 0-6.2 3.1 3.1 0 0 0 0 6.2Z",
                  fill: "#C90077"
                }), React.createElement("path", {
                  d: "M66.1 20.2a3.1 3.1 0 1 0 0-6.2 3.1 3.1 0 0 0 0 6.2ZM109.5 52a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z",
                  fill: "#EC008C"
                }), React.createElement("path", {
                  d: "M90.1 49.2a3.1 3.1 0 1 0 0-6.2 3.1 3.1 0 0 0 0 6.2Z",
                  fill: "#FEEBF2"
                }), React.createElement("path", {
                  d: "M73.8 36.6a3.8 3.8 0 1 0 0-7.6 3.8 3.8 0 0 0 0 7.6Z",
                  fill: "#EC008C"
                }), React.createElement("path", {
                  d: "M55.4 38.6a4 4 0 1 0 0-7.9 4 4 0 0 0 0 7.9ZM87.2 26.2a6.1 6.1 0 1 0 0-12.3 6.1 6.1 0 0 0 0 12.3Z",
                  fill: "#F87CBC"
                }), React.createElement("path", {
                  d: "M105.1 37.3a6.1 6.1 0 1 0 0-12.3 6.1 6.1 0 0 0 0 12.3Z",
                  fill: "#EC008C"
                }));
}

function InspectorIssuesView$EmptyState$Loading(Props) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(80)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(InspectorIssuesView$EmptyState$Imagery$Loading, {}), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement($$Text.make, {
                  size: "Huge",
                  weight: "Semi",
                  color: Styles.Color.grey80,
                  children: "Loading issues..."
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.grey70,
                  children: "This might take a few seconds"
                }));
}

function InspectorIssuesView$EmptyState$Error(Props) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(64)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(InspectorIssuesView$EmptyState$Imagery$NoIssues, {}), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement($$Text.make, {
                  size: "Huge",
                  weight: "Bold",
                  textAlign: "center",
                  color: Styles.Color.grey80,
                  maxWidth: Css.px(300),
                  children: StringExt.widowless("There was a problem loading your issues")
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(388)),
                        tl: {
                          hd: Css.textAlign("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.grey70,
                      children: null
                    }, "Inspector could not load your issues. Try reloading this page. If the issue persists please ", React.createElement(TextButton.make, {
                          onClick: (function (param) {
                              return Intercom.showNewMessage(undefined);
                            }),
                          style: "Blue",
                          children: "reach out"
                        }), ".")));
}

function InspectorIssuesView$EmptyState$NoEventsSeen(Props) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(64)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(InspectorIssuesView$EmptyState$Imagery$NoIssues, {}), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement($$Text.make, {
                  size: "Huge",
                  weight: "Bold",
                  textAlign: "center",
                  color: Styles.Color.grey80,
                  maxWidth: Css.px(300),
                  children: "No events seen"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(388)),
                        tl: {
                          hd: Css.textAlign("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.grey70,
                      children: null
                    }, "Inspector found no events in production in the last 24 hours. If this is unexpected, we recommend checking your Inspector setup (please ", React.createElement(TextButton.make, {
                          onClick: (function (param) {
                              return Intercom.showNewMessage(undefined);
                            }),
                          style: "Blue",
                          children: "reach out"
                        }), StringExt.widowless(" if we can help)."))));
}

function InspectorIssuesView$EmptyState$NoIssuesUnderCurrentFilters(Props) {
  var channels = Props.channels;
  var globalSend = Props.globalSend;
  var match = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var currentSavedView = match.savedView;
  var match$1 = InspectorIssuesFilterHooks.useDraftState(undefined);
  var isDraft = match$1.isDraft;
  var match$2 = React$1.useFlow("how-to-save-a-view-and-get-alerts");
  var intent = match$2[1];
  var match$3 = InspectorEventsStore.useEvents(undefined);
  var events = match$3.events;
  var eventsLoadingState = match$3.loadingState;
  var eventsLoadingState$1;
  if (typeof eventsLoadingState === "object") {
    var variant = eventsLoadingState.NAME;
    if (variant === "error") {
      var match$4 = eventsLoadingState.VAL[1];
      eventsLoadingState$1 = match$4 !== undefined && match$4 ? ({
            NAME: "error",
            VAL: /* NotFound */1
          }) : ({
            NAME: "error",
            VAL: /* Internal */0
          });
    } else {
      eventsLoadingState$1 = variant === "success" ? "success" : "loading";
    }
  } else {
    eventsLoadingState$1 = "initial";
  }
  if (typeof eventsLoadingState$1 === "object") {
    return React.createElement(InspectorIssuesView$EmptyState$Error, {});
  }
  if (eventsLoadingState$1 !== "success") {
    return React.createElement(InspectorIssuesView$EmptyState$Loading, {});
  }
  if (Caml_obj.caml_equal(events, [])) {
    return React.createElement(InspectorIssuesView$EmptyState$NoEventsSeen, {});
  }
  var totalEventsVolume = Belt_Array.reduce(Belt_Array.map(Belt_Array.concatMany(Belt_Array.map(events, (function (param) {
                      return param.sources;
                    }))), (function (param) {
              return param.count.current.total;
            })), 0, (function (sum, count) {
          return sum + count | 0;
        }));
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(64)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, channels ? React.createElement(InspectorIssuesView$EmptyState$Imagery$NoIssues, {}) : React.createElement(InspectorIssuesView$EmptyState$Imagery$GetAlerted, {}), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement($$Text.make, {
                  size: "Huge",
                  weight: "Bold",
                  textAlign: "center",
                  color: Styles.Color.grey80,
                  maxWidth: Css.px(300),
                  children: currentSavedView !== undefined ? (
                      channels ? StringExt.widowless("No issues for this view") : StringExt.widowless("Get alerted on new issues for this view")
                    ) : (
                      channels && !isDraft ? StringExt.widowless("No issues for these filters") : StringExt.widowless("Get alerted on new issues for these filters")
                    )
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(388)),
                        tl: {
                          hd: Css.textAlign("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.grey70,
                      children: null
                    }, "We found ", React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "no issues on the " + AvoUtils.plural(totalEventsVolume, "event")
                        }), " seen in production in the last 24 hours for these filters.", channels ? (
                        isDraft ? StringExt.widowless(" Set up alerts to get notified when Inspector detects an issue matching your filter criteria.") : StringExt.widowless(" You’ll get notified on Slack when Inspector detects an issue matching your filter criteria.")
                      ) : StringExt.widowless(" Set up alerts to get notified when Inspector detects an issue matching your filter criteria.")), React.createElement(Spacer.make, {
                      height: 20
                    }), currentSavedView !== undefined ? (
                    channels ? null : React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                                icon: "bell",
                                id: "set-up-alerts-empty-state",
                                label: "Set up alerts",
                                onClick: (function (param) {
                                    return Curry._1(globalSend, {
                                                TAG: /* OpenModal */4,
                                                _0: {
                                                  NAME: "IssueAlertsSetup",
                                                  VAL: currentSavedView
                                                }
                                              });
                                  })
                              }), React.createElement(Spacer.make, {
                                height: 20
                              }))
                  ) : (
                    isDraft ? React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                                icon: "bell",
                                id: "set-up-alerts-empty-state",
                                label: "Set up alerts",
                                onClick: (function (param) {
                                    return Curry._1(globalSend, {
                                                TAG: /* OpenModal */4,
                                                _0: {
                                                  NAME: "IssueAlertsSetup",
                                                  VAL: undefined
                                                }
                                              });
                                  })
                              }), React.createElement(Spacer.make, {
                                height: 20
                              })) : React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                                label: "Show me how",
                                onClick: (function (param) {
                                    intent.reset({force: true});
                                    intent.start({force: true});
                                    
                                  })
                              }), React.createElement(Spacer.make, {
                                height: 20
                              }))
                  ), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.grey70,
                      children: null
                    }, "If this is unexpected, please ", React.createElement(TextButton.make, {
                          onClick: (function (param) {
                              return Intercom.showNewMessage(undefined);
                            }),
                          style: "Blue",
                          children: "reach out"
                        }), " so we can resolve it with you")));
}

function InspectorIssuesView$EmptyState$NoIssues(Props) {
  var channels = Props.channels;
  var globalSend = Props.globalSend;
  var match = InspectorEventsStore.useEvents(undefined);
  var events = match.events;
  var eventsLoadingState = match.loadingState;
  var eventsLoadingState$1;
  if (typeof eventsLoadingState === "object") {
    var variant = eventsLoadingState.NAME;
    if (variant === "error") {
      var match$1 = eventsLoadingState.VAL[1];
      eventsLoadingState$1 = match$1 !== undefined && match$1 ? ({
            NAME: "error",
            VAL: /* NotFound */1
          }) : ({
            NAME: "error",
            VAL: /* Internal */0
          });
    } else {
      eventsLoadingState$1 = variant === "success" ? "success" : "loading";
    }
  } else {
    eventsLoadingState$1 = "initial";
  }
  if (typeof eventsLoadingState$1 === "object") {
    if (eventsLoadingState$1.VAL) {
      return React.createElement(InspectorIssuesView$EmptyState$NoEventsSeen, {});
    } else {
      return React.createElement(InspectorIssuesView$EmptyState$Error, {});
    }
  }
  if (eventsLoadingState$1 !== "success") {
    return React.createElement(InspectorIssuesView$EmptyState$Loading, {});
  }
  if (Caml_obj.caml_equal(events, [])) {
    return React.createElement(InspectorIssuesView$EmptyState$NoEventsSeen, {});
  }
  var totalEventsVolume = Belt_Array.reduce(Belt_Array.map(Belt_Array.concatMany(Belt_Array.map(events, (function (param) {
                      return param.sources;
                    }))), (function (param) {
              return param.count.current.total;
            })), 0, (function (sum, count) {
          return sum + count | 0;
        }));
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(64)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(InspectorIssuesView$EmptyState$Imagery$NoIssues, {}), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement($$Text.make, {
                  size: "Huge",
                  weight: "Bold",
                  textAlign: "center",
                  color: Styles.Color.grey80,
                  maxWidth: Css.px(300),
                  children: "No issues found"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(388)),
                        tl: {
                          hd: Css.textAlign("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.grey70,
                      children: null
                    }, "We found ", React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "no issues on the " + AvoUtils.plural(totalEventsVolume, "event")
                        }), " seen in production in the last 24 hours.", channels === /* [] */0 ? StringExt.widowless(" Set up alerts to get notified when Inspector detects an issue.") : StringExt.widowless(" You’ll get notified on Slack when Inspector detects an issue.")), React.createElement(Spacer.make, {
                      height: 20
                    }), channels === /* [] */0 ? React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                            icon: "bell",
                            label: "Set up alerts",
                            onClick: (function (param) {
                                return Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "IssueAlertsSetup",
                                              VAL: undefined
                                            }
                                          });
                              })
                          }), React.createElement(Spacer.make, {
                            height: 20
                          })) : null, React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.grey70,
                      children: null
                    }, "If this is unexpected, please ", React.createElement(TextButton.make, {
                          onClick: (function (param) {
                              return Intercom.showNewMessage(undefined);
                            }),
                          style: "Blue",
                          children: "reach out"
                        }), " so we can resolve it with you")));
}

function InspectorIssuesView$EmptyState(Props) {
  var filtersCount = Props.filtersCount;
  var globalSend = Props.globalSend;
  var loadingState = Props.loadingState;
  var match = WorkspaceContext.use(undefined);
  var match$1 = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var currentSavedView = match$1.savedView;
  var savedViewStatus = match$1.savedViewStatus;
  var eventType = React.useMemo((function () {
          if (currentSavedView !== undefined) {
            return /* SavedView */{
                    _0: currentSavedView.id
                  };
          } else {
            return /* InspectorIssues */0;
          }
        }), [currentSavedView]);
  var channelsMode = React.useMemo((function () {
          if (savedViewStatus === "success" || savedViewStatus === "notFound" || savedViewStatus === "noSelection") {
            return "Fetch";
          } else {
            return "Wait";
          }
        }), [savedViewStatus]);
  var channels = FirebaseFetcherHooks.useNotificationChannels(channelsMode, match.id, eventType);
  if (typeof loadingState === "object") {
    if (loadingState.NAME === "error") {
      return React.createElement(InspectorIssuesView$EmptyState$Error, {});
    }
    
  } else if (loadingState === "success" && typeof channels === "object" && channels.NAME === "Loaded") {
    var channels$1 = channels.VAL;
    if (filtersCount > 0 || Belt_Option.isSome(currentSavedView)) {
      return React.createElement(InspectorIssuesView$EmptyState$NoIssuesUnderCurrentFilters, {
                  channels: channels$1,
                  globalSend: globalSend
                });
    } else {
      return React.createElement(InspectorIssuesView$EmptyState$NoIssues, {
                  channels: channels$1,
                  globalSend: globalSend
                });
    }
  }
  if (channels === "Error") {
    return React.createElement(InspectorIssuesView$EmptyState$Error, {});
  } else if (channels === "Initial") {
    return React.createElement(InspectorIssuesView$EmptyState$Loading, {});
  } else {
    return React.createElement(InspectorIssuesView$EmptyState$Loading, {});
  }
}

function getColumns(appVersions, releaseFilter) {
  return [
          {
            NAME: "component",
            VAL: [
              {
                id: "issue",
                name: "Issue",
                width: "grow",
                compare: (function (a, b) {
                    return Caml.caml_string_compare(InspectorIssueType.getIssueTypeAsText(a.issueType), InspectorIssueType.getIssueTypeAsText(b.issueType));
                  }),
                defaultOrder: "asc",
                alignment: "left"
              },
              (function (issue) {
                  return React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.grey80,
                              children: StringExt.widowless(InspectorIssuesFilter.FilterIssueType.toDisplayString(InspectorIssuesFilter.FilterIssueType.fromNewIssue(issue)))
                            });
                })
            ]
          },
          {
            NAME: "disambiguatedString",
            VAL: [
              {
                id: "event",
                name: "Event",
                width: "grow",
                compare: (function (a, b) {
                    return Caml.caml_string_compare(a.eventName.toLocaleLowerCase(), b.eventName.toLocaleLowerCase());
                  }),
                defaultOrder: "asc",
                alignment: "left"
              },
              (function (issue) {
                  return issue.eventName;
                })
            ]
          },
          {
            NAME: "disambiguatedString",
            VAL: [
              {
                id: "property",
                name: "Property",
                width: "grow",
                compare: (function (a, b) {
                    return Caml_obj.caml_compare(a.propertyName, b.propertyName);
                  }),
                defaultOrder: "asc",
                alignment: "left"
              },
              (function (issue) {
                  return Belt_Option.getWithDefault(issue.propertyName, "");
                })
            ]
          },
          {
            NAME: "component",
            VAL: [
              {
                id: "source",
                name: "Source",
                width: "grow",
                compare: (function (a, b) {
                    return Caml.caml_string_compare(a.source.name, b.source.name);
                  }),
                defaultOrder: "desc",
                alignment: "left"
              },
              (function (issue) {
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.marginLeft(Css.px(-8)),
                                    tl: /* [] */0
                                  })
                            }, React.createElement("span", {
                                  className: Curry._1(Css.style, Styles.maxLines(2))
                                }, React.createElement(IssuesTableConfig.Pill.make, {
                                      label: issue.source.name
                                    })));
                })
            ]
          },
          {
            NAME: "component",
            VAL: [
              {
                id: "impacted-releases",
                name: "Impacted releases",
                width: "grow",
                compare: (function (a, b) {
                    if (a.source.name !== b.source.name) {
                      return Caml.caml_string_compare(a.source.name, b.source.name);
                    }
                    var a$1 = Belt_Option.getExn(Belt_Array.get(a.source.impactedVersions, 0));
                    var b$1 = Belt_Option.getExn(Belt_Array.get(b.source.impactedVersions, 0));
                    return InspectorIssueViewModel.Version.compare(a$1, b$1);
                  }),
                defaultOrder: "desc",
                alignment: "left"
              },
              (function (issue) {
                  return React.createElement(IssuesTableConfig.Releases.make, {
                              issue: issue,
                              appVersions: appVersions,
                              releaseFilter: releaseFilter
                            });
                })
            ]
          },
          {
            NAME: "component",
            VAL: [
              {
                id: "firstSeen",
                name: "First Seen",
                width: {
                  NAME: "fixed",
                  VAL: Css.px(100)
                },
                compare: (function (a, b) {
                    return Caml_obj.caml_compare(a.firstSeen, b.firstSeen);
                  }),
                defaultOrder: "desc",
                alignment: "left"
              },
              (function (issue) {
                  return React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.grey80,
                              title: issue.firstSeen.toLocaleString(),
                              children: DateFns.distanceInWords(undefined, true, new Date(), issue.firstSeen).replace("about ", "")
                            });
                })
            ]
          },
          {
            NAME: "component",
            VAL: [
              {
                id: "lastSeen",
                name: "Last Seen",
                width: {
                  NAME: "fixed",
                  VAL: Css.px(100)
                },
                compare: (function (a, b) {
                    return Caml_obj.caml_compare(a.lastSeen, b.lastSeen);
                  }),
                defaultOrder: "desc",
                alignment: "left"
              },
              (function (issue) {
                  return React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.grey80,
                              title: issue.lastSeen.toLocaleString(),
                              children: DateFns.distanceInWords(undefined, true, new Date(), issue.lastSeen).replace("about ", "")
                            });
                })
            ]
          },
          {
            NAME: "component",
            VAL: [
              {
                id: "eventVolume",
                name: "Event Vol.",
                width: {
                  NAME: "fixed",
                  VAL: Css.px(76)
                },
                compare: (function (a, b) {
                    return Caml.caml_int_compare(a.eventCount, b.eventCount);
                  }),
                defaultOrder: "desc",
                alignment: "right"
              },
              (function (issue) {
                  var tooltip = releaseFilter !== undefined ? (
                      releaseFilter === "threeLatestReleases" ? "Event volume across the three latest releases sending this event" : (
                          releaseFilter === "twoLatestReleases" ? "Event volume across the two latest releases sending this event" : "Event volume on the latest release sending this event"
                        )
                    ) : "Event volume across all releases sending this event";
                  return React.createElement(IssuesTableConfig.Count.make, {
                              count: issue.eventCount,
                              tooltip: tooltip
                            });
                })
            ]
          },
          {
            NAME: "component",
            VAL: [
              {
                id: "issueVolume",
                name: "Issue Vol.",
                width: {
                  NAME: "fixed",
                  VAL: Css.px(76)
                },
                compare: (function (a, b) {
                    return Caml.caml_int_compare(a.issueCount, b.issueCount);
                  }),
                defaultOrder: "desc",
                alignment: "right"
              },
              (function (issue) {
                  var tooltip = releaseFilter !== undefined ? (
                      releaseFilter === "threeLatestReleases" ? "Issue volume across the three latest releases sending this event" : (
                          releaseFilter === "twoLatestReleases" ? "Issue volume across the two latest releases sending this event" : "Issue volume on the latest release sending this event"
                        )
                    ) : "Issue volume across all releases sending this event";
                  return React.createElement(IssuesTableConfig.Count.make, {
                              count: issue.issueCount,
                              tooltip: tooltip
                            });
                })
            ]
          },
          {
            NAME: "string",
            VAL: [
              {
                id: "issuePercentage",
                name: "Issue %",
                width: {
                  NAME: "fixed",
                  VAL: Css.px(66)
                },
                compare: (function (a, b) {
                    return Caml.caml_float_compare(a.issueCount / a.eventCount, b.issueCount / b.eventCount);
                  }),
                defaultOrder: "desc",
                alignment: "right"
              },
              (function (issue) {
                  var match = issue.issueCount;
                  var match$1 = issue.eventCount;
                  if (match !== 0 && match$1 !== 0) {
                    return $$Number.getRoughPercentageString(100.0 * match / match$1) + "%";
                  } else {
                    return "0%";
                  }
                })
            ]
          }
        ];
}

function InspectorIssuesView$Table(Props) {
  var inspectorSources = Props.inspectorSources;
  var globalSend = Props.globalSend;
  var betaDisclaimerHeight = Props.betaDisclaimerHeight;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var filters = match.filters;
  var match$1 = InspectorIssuesStore.useFilteredIssues(undefined, undefined);
  var filteredIssues = match$1.filteredIssues;
  var issues = match$1.issues;
  var loadingState = match$1.loadingState;
  var hasDrawerItems = RouterStore.Schema.useHasDrawerItems(undefined);
  var match$2 = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var savedView = match$2.savedView;
  var savedViewStatus = match$2.savedViewStatus;
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var model = ModelStore.useModel(undefined);
  var match$3 = InspectorIssuesStore.useAppVersionsInSyncWithIssues(3, undefined);
  var appVersions = match$3[0];
  var countedAvailableFilters = React.useMemo((function () {
          return InspectorIssuesFilterUtils.FilterCounter.get(inspectorSources, model, issues);
        }), [issues]);
  var getCountedAvailableFilters = React.useCallback((function (ignoring) {
          return InspectorIssuesFilterUtils.FilterCounter.get(inspectorSources, model, InspectorIssuesFilterUtils.filterIssueViewModels(ignoring, appVersions, issues, filters));
        }), [
        inspectorSources,
        model,
        issues,
        appVersions
      ]);
  var orderedBy = InspectorIssuesFilterHooks.useOrderedBy(undefined);
  var onOrderByChange = InspectorIssuesFilterHooks.useSetOrderedBy(undefined);
  var inspectorIssuesViewEventSent = React.useRef(false);
  var savedViewEventSent = React.useRef(undefined);
  React.useEffect((function () {
          var exit = 0;
          if (typeof loadingState === "object") {
            if (loadingState.NAME === "error") {
              exit = 1;
            }
            
          } else if (loadingState === "success" && (savedViewStatus === "success" || savedViewStatus === "noSelection")) {
            exit = 1;
          }
          if (exit === 1) {
            if (!inspectorIssuesViewEventSent.current) {
              inspectorIssuesViewEventSent.current = true;
              AnalyticsRe.inspectorIssuesViewed(InspectorIssuesHelpers.createInspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.groupFilters(filters), orderedBy, filteredIssues.length), schemaGroup, Belt_Option.isSome(savedView) ? "SavedView" : "AvoCreated", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) : -1, schemaGroup.schemaId);
            }
            
          }
          
        }), [
        loadingState,
        savedViewStatus
      ]);
  React.useEffect((function () {
          if (savedView !== undefined && Caml_obj.caml_notequal(savedView.id, savedViewEventSent.current)) {
            savedViewEventSent.current = savedView.id;
            AnalyticsRe.inspectorSavedViewOpened(schemaGroup, InspectorIssuesHelpers.createInspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.groupFilters(filters), orderedBy, issues.length), AnalyticsRe.Group.inspectorSavedViews(savedView.name, savedView.id, Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid === savedView.createdBy ? "Creator" : "Visitor", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) : -1), schemaGroup.schemaId);
          }
          
        }), [savedView]);
  Hooks.useDidUpdate1((function (param) {
          if (Router.getSearch(undefined) !== "") {
            var tmp;
            if (orderedBy !== undefined) {
              switch (orderedBy[0]) {
                case "event" :
                    tmp = "EventName";
                    break;
                case "eventVolume" :
                    tmp = "EventVolume";
                    break;
                case "firstSeen" :
                    tmp = "FirstSeen";
                    break;
                case "issue" :
                    tmp = "IssueType";
                    break;
                case "issuePercentage" :
                    tmp = "IssuePercentage";
                    break;
                case "issueVolume" :
                    tmp = "IssueVolume";
                    break;
                case "lastSeen" :
                    tmp = "LastSeen";
                    break;
                case "property" :
                    tmp = "PropertyName";
                    break;
                default:
                  tmp = "FirstSeen";
              }
            } else {
              tmp = "FirstSeen";
            }
            AnalyticsRe.inspectorIssuesViewConfigured(schemaGroup, InspectorIssuesHelpers.createInspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.groupFilters(filters), orderedBy, issues.length), "OrderView", tmp, savedViewStatus === "success" || savedViewStatus === "notFound" || savedViewStatus === "loading" ? "SavedView" : "AvoCreated", schemaGroup.schemaId);
          }
          
        }), [orderedBy]);
  var drawerItems = RouterStore.Schema.useDrawerItems(undefined);
  var activeRowId = React.useMemo((function () {
          if (!drawerItems) {
            return ;
          }
          var match = drawerItems.hd;
          if (typeof match === "object" && match.NAME === "inspectorIssue") {
            return match.VAL;
          }
          
        }), [drawerItems]);
  var columns = React.useMemo((function () {
          var match = Belt_Array.get(InspectorIssuesFilterUtils.getFilters(filters, "release"), 0);
          return getColumns(appVersions, match !== undefined && typeof match === "object" && match.NAME === "release" ? match.VAL : undefined);
        }), [
        appVersions,
        filters
      ]);
  var sourceReport = React.useMemo((function () {
          return InspectorIssueViewModel.Utils.getSourceReport(issues);
        }), [issues]);
  var match$4 = filters.length;
  var match$5 = filteredIssues.length;
  var tmp = {
    columns: columns,
    id: "issues-table",
    maxHeight: {
      NAME: "substract",
      VAL: [
        Css.vh(100.0),
        Css.px(326 + betaDisclaimerHeight | 0)
      ]
    },
    minWidth: 1100,
    onRowClick: (function (id) {
        return Router.Schema.pushDrawerItem(undefined, {
                    NAME: "inspectorIssue",
                    VAL: id
                  });
      }),
    rowSize: 56,
    data: filteredIssues,
    orderBy: orderedBy,
    onOrderByChange: onOrderByChange,
    emptyState: React.createElement(InspectorIssuesView$EmptyState, {
          filtersCount: filters.length,
          globalSend: globalSend,
          loadingState: loadingState
        }),
    showScrollToTopButton: !hasDrawerItems
  };
  if (activeRowId !== undefined) {
    tmp.activeRowId = Caml_option.valFromOption(activeRowId);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(InspectorIssuesFilters.make, {
                  countedAvailableFilters: countedAvailableFilters,
                  getCountedAvailableFilters: getCountedAvailableFilters,
                  inspectorDisplayedIssueCount: filteredIssues.length
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("section", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.gap(Css.px(8)),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.paddingLeft(Css.px(40)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      children: null
                    }, match$4 !== 0 ? (
                        match$5 !== 1 ? React.createElement(React.Fragment, undefined, "Displaying ", React.createElement($$Text.make, {
                                    element: "Span",
                                    weight: "Semi",
                                    children: intToLocaleString(match$5)
                                  }), " issues seen in ") : React.createElement(React.Fragment, undefined, "Displaying ", React.createElement($$Text.make, {
                                    element: "Span",
                                    weight: "Semi",
                                    children: "1"
                                  }), " issue seen in ")
                      ) : "Issues seen in ", React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "production"
                        }), " in the past ", React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "24 hours"
                        })), loadingState === "success" ? React.createElement("button", {
                        className: Curry._1(Css.merge, {
                              hd: Curry._1(Css.style, Styles.buttonReset),
                              tl: {
                                hd: Curry._1(Css.style, {
                                      hd: Css.padding2(Css.px(4), Css.px(8)),
                                      tl: {
                                        hd: Css.marginTop(Css.px(-4)),
                                        tl: {
                                          hd: Css.marginBottom(Css.px(-4)),
                                          tl: {
                                            hd: Css.borderRadius(Css.px(16)),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.grey30),
                                              tl: {
                                                hd: Css.transition({
                                                      NAME: "ms",
                                                      VAL: Styles.Duration.$$short
                                                    }, undefined, undefined, "all"),
                                                tl: {
                                                  hd: Css.hover({
                                                        hd: Css.backgroundColor(Styles.Color.grey40),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        onClick: (function (param) {
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "IssueSourcesBreakdown",
                                          VAL: sourceReport
                                        }
                                      });
                          })
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            children: AvoUtils.plural(sourceReport.releasesCount, "release") + " on " + AvoUtils.plural(sourceReport.sourcesCount, "source")
                          })) : null), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.margin2("zero", Css.px(24)),
                        tl: /* [] */0
                      })
                }, React.createElement(IssuesTable.make, tmp)));
}

function InspectorIssuesView$DuplicateButton(Props) {
  var filteredIssuesCount = Props.filteredIssuesCount;
  var sendActions = SendActionsContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var orderedBy = match.orderedBy;
  var filters = match.filters;
  var addToast = Toast.useAddToast(undefined);
  var match$1 = Hooks.useDisclosure(false);
  var actions = match$1[1];
  var opened = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setNameInput = match$2[1];
  var nameInput = match$2[0];
  var duplicate = RescriptReactRouter.useUrl(undefined, undefined).search === "";
  var match$3 = React.useState(function () {
        return false;
      });
  var setIsSaving = match$3[1];
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var handleSave = function (param) {
    Curry._1(setIsSaving, (function (param) {
            return true;
          }));
    return FirebaseUtils.createSavedView(sendActions, workspace.id, nameInput, orderedBy, filters, (function (id) {
                  Curry._1(setIsSaving, (function (param) {
                          return false;
                        }));
                  Curry._1(setNameInput, (function (param) {
                          return "";
                        }));
                  Curry._1(actions.close, undefined);
                  Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                            NAME: "inspector",
                            VAL: {
                              NAME: "savedView",
                              VAL: id
                            }
                          }));
                  Curry._1(addToast, {
                        message: duplicate ? "View Duplicated!" : "View Saved!",
                        toastType: /* Success */0
                      });
                  return AnalyticsRe.inspectorSavedViewInteraction(schemaGroup, InspectorIssuesHelpers.createInspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.groupFilters(filters), orderedBy, filteredIssuesCount), AnalyticsRe.Group.inspectorSavedViews(nameInput, id, "Creator", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) : -1), duplicate ? "Duplicate" : "SaveViewAs", schemaGroup.schemaId);
                }), (function (err) {
                  Curry._1(setIsSaving, (function (param) {
                          return false;
                        }));
                  Curry._1(setNameInput, (function (param) {
                          return "";
                        }));
                  return Curry._1(addToast, {
                              message: err.RE_EXN_ID === AvoState.PermissionsException ? (
                                  duplicate ? "Permissions needed to duplicate view..." : "Permissions needed to save view..."
                                ) : (
                                  duplicate ? "Failed to duplicate view..." : "Failed to save view..."
                                ),
                              toastType: /* Error */1
                            });
                }));
  };
  var disabled = nameInput === "";
  var tmp = {
    disabled: Caml_obj.caml_equal(filters, []),
    onClick: (function (param) {
        return Curry._1(actions.toggle, undefined);
      }),
    style: "outline"
  };
  var tmp$1 = duplicate ? "copy" : undefined;
  if (tmp$1 !== undefined) {
    tmp.icon = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = duplicate ? undefined : "Save As...";
  if (tmp$2 !== undefined) {
    tmp.label = tmp$2;
  }
  return React.createElement(SimpleTooltip.make, {
              disabled: opened,
              tooltip: "Duplicate view",
              children: React.createElement(Mantine.Menu.make, {
                    onChange: (function (param) {
                        Curry._1(setNameInput, (function (param) {
                                return "";
                              }));
                        return Curry._1(actions.close, undefined);
                      }),
                    opened: opened,
                    position: "bottom-end",
                    children: null
                  }, React.createElement(Core.Menu.Target, {
                        children: React.createElement(Button.make, tmp)
                      }), React.createElement(Mantine.Menu.Dropdown.make, {
                        additionalStyles: {
                          hd: Css.padding(Css.px(20)),
                          tl: {
                            hd: Css.maxWidth(Css.px(300)),
                            tl: /* [] */0
                          }
                        },
                        children: null
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.grey80,
                            children: duplicate ? "Duplicate filtered view" : "Save filtered view"
                          }), React.createElement(Spacer.make, {
                            height: 12
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.grey70,
                            children: duplicate ? "Duplicate this filtered view and make changes based on the existing filters." : "Save this filtered view to quickly access it when you need it."
                          }), React.createElement(Spacer.make, {
                            height: 12
                          }), React.createElement(InputLabel.make, {
                            htmlFor: "name",
                            children: "Saved view name"
                          }), React.createElement(Spacer.make, {
                            height: 4
                          }), React.createElement(TextInput.make, {
                            autoFocus: true,
                            id: "name",
                            onChange: (function (name) {
                                return Curry._1(setNameInput, (function (param) {
                                              return name;
                                            }));
                              }),
                            placeholder: "My saved view",
                            stretch: true,
                            onEnter: (function (param) {
                                if (disabled) {
                                  return ;
                                } else {
                                  return handleSave(undefined);
                                }
                              }),
                            value: nameInput
                          }), React.createElement(Spacer.make, {
                            height: 12
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("column"),
                                    tl: {
                                      hd: Css.justifyContent("stretch"),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(Button.make, {
                                disabled: disabled,
                                label: duplicate ? "Duplicate filtered view" : "Save filtered view",
                                loading: match$3[0],
                                onClick: (function (param) {
                                    return handleSave(undefined);
                                  })
                              }))))
            });
}

function InspectorIssuesView$SaveButton(Props) {
  var target = Props.target;
  var filteredIssuesCount = Props.filteredIssuesCount;
  var positionOpt = Props.position;
  var currentSavedView = Props.currentSavedView;
  var position = positionOpt !== undefined ? positionOpt : "bottom-end";
  var sendActions = SendActionsContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var orderedBy = match.orderedBy;
  var filters = match.filters;
  var match$1 = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var savedViewStatus = match$1.savedViewStatus;
  var addToast = Toast.useAddToast(undefined);
  var match$2 = Hooks.useDisclosure(false);
  var actions = match$2[1];
  var opened = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setNameInput = match$3[1];
  var nameInput = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setIsSaving = match$4[1];
  var match$5 = React.useState(function () {
        return false;
      });
  var setIsResaving = match$5[1];
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var match$6 = InspectorIssuesFilterHooks.useDraftState(undefined);
  var isDraft = match$6.isDraft;
  React.useEffect((function () {
          if (opened) {
            AnalyticsRe.inspectorSavedViewInitiated(schemaGroup, InspectorIssuesHelpers.createInspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.groupFilters(filters), orderedBy, filteredIssuesCount), schemaGroup.schemaId);
          }
          
        }), [opened]);
  var handleSave = function (param) {
    if (currentSavedView !== undefined) {
      Curry._1(setIsSaving, (function (param) {
              return true;
            }));
      var viewerId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
      return FirebaseUtils.updateSavedView(sendActions, workspace.id, {
                  id: currentSavedView.id,
                  name: currentSavedView.name,
                  createdBy: currentSavedView.createdBy,
                  createdAt: currentSavedView.createdAt,
                  lastModifiedBy: currentSavedView.lastModifiedBy,
                  lastModifiedAt: currentSavedView.lastModifiedAt,
                  filters: filters,
                  order: orderedBy,
                  isArchived: currentSavedView.isArchived
                }, viewerId, (function (param) {
                    Curry._1(actions.close, undefined);
                    Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                              NAME: "inspector",
                              VAL: {
                                NAME: "savedView",
                                VAL: currentSavedView.id
                              }
                            }));
                    Curry._1(addToast, {
                          message: "View Saved",
                          toastType: /* Success */0
                        });
                    Curry._1(setIsResaving, (function (param) {
                            return false;
                          }));
                    Curry._1(setIsSaving, (function (param) {
                            return false;
                          }));
                    Curry._1(setNameInput, (function (param) {
                            return "";
                          }));
                    return AnalyticsRe.inspectorSavedViewInteraction(schemaGroup, InspectorIssuesHelpers.createInspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.groupFilters(filters), orderedBy, filteredIssuesCount), AnalyticsRe.Group.inspectorSavedViews(currentSavedView.name, currentSavedView.id, viewerId === currentSavedView.createdBy ? "Creator" : "Visitor", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) : -1), "Save", schemaGroup.schemaId);
                  }), (function (err) {
                    Curry._1(addToast, {
                          message: err.RE_EXN_ID === AvoState.PermissionsException ? "Permissions needed to save view..." : "Failed to save view...",
                          toastType: /* Error */1
                        });
                    Curry._1(setIsResaving, (function (param) {
                            return false;
                          }));
                    Curry._1(setIsSaving, (function (param) {
                            return false;
                          }));
                    return Curry._1(setNameInput, (function (param) {
                                  return "";
                                }));
                  }));
    }
    Curry._1(setIsSaving, (function (param) {
            return true;
          }));
    return FirebaseUtils.createSavedView(sendActions, workspace.id, nameInput, orderedBy, filters, (function (id) {
                  Curry._1(actions.close, undefined);
                  Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                            NAME: "inspector",
                            VAL: {
                              NAME: "savedView",
                              VAL: id
                            }
                          }));
                  Curry._1(addToast, {
                        message: "View Saved",
                        toastType: /* Success */0
                      });
                  AnalyticsRe.inspectorSavedViewCreated(InspectorIssuesHelpers.createInspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.groupFilters(filters), orderedBy, filteredIssuesCount), schemaGroup, AnalyticsRe.Group.inspectorSavedViews(nameInput, id, "Creator", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) + 1 | 0 : -1), savedViewStatus === "success" || savedViewStatus === "notFound" || savedViewStatus === "loading" ? "SavedView" : "AvoCreated", schemaGroup.schemaId, schemaGroup.schemaId, schemaGroup.schemaId, typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) + 1 | 0 : -1);
                  Curry._1(setIsResaving, (function (param) {
                          return false;
                        }));
                  Curry._1(setIsSaving, (function (param) {
                          return false;
                        }));
                  return Curry._1(setNameInput, (function (param) {
                                return "";
                              }));
                }), (function (param) {
                  Curry._1(addToast, {
                        message: "Failed to save view...",
                        toastType: /* Error */1
                      });
                  Curry._1(setIsResaving, (function (param) {
                          return false;
                        }));
                  Curry._1(setIsSaving, (function (param) {
                          return false;
                        }));
                  return Curry._1(setNameInput, (function (param) {
                                return "";
                              }));
                }));
  };
  var disabled = nameInput === "";
  return React.createElement(SimpleTooltip.make, {
              disabled: isDraft,
              tooltip: "No changes to save",
              children: React.createElement(Mantine.Menu.make, {
                    onChange: (function (param) {
                        Curry._1(setNameInput, (function (param) {
                                return "";
                              }));
                        return Curry._1(actions.close, undefined);
                      }),
                    opened: opened,
                    position: position,
                    children: null
                  }, React.createElement(Core.Menu.Target, {
                        children: target !== undefined ? React.cloneElement(Caml_option.valFromOption(target), {
                                onClick: (function (param) {
                                    if (currentSavedView !== undefined) {
                                      Curry._1(setIsResaving, (function (param) {
                                              return true;
                                            }));
                                      return handleSave(undefined);
                                    } else {
                                      return Curry._1(actions.toggle, undefined);
                                    }
                                  })
                              }) : React.createElement("div", undefined, React.createElement(Button.make, {
                                    disabled: !isDraft,
                                    id: "save-view-for-issues",
                                    label: "Save View",
                                    loading: match$5[0],
                                    onClick: (function (param) {
                                        if (currentSavedView !== undefined) {
                                          Curry._1(setIsResaving, (function (param) {
                                                  return true;
                                                }));
                                          return handleSave(undefined);
                                        } else {
                                          return Curry._1(actions.toggle, undefined);
                                        }
                                      })
                                  }))
                      }), React.createElement(Mantine.Menu.Dropdown.make, {
                        additionalStyles: {
                          hd: Css.padding(Css.px(20)),
                          tl: {
                            hd: Css.maxWidth(Css.px(300)),
                            tl: /* [] */0
                          }
                        },
                        children: null
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.grey80,
                            children: "Save filtered view"
                          }), React.createElement(Spacer.make, {
                            height: 12
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.grey70,
                            children: "Save this filtered view to quickly access it when you need it."
                          }), React.createElement(Spacer.make, {
                            height: 20
                          }), React.createElement(TextInput.make, {
                            autoFocus: true,
                            onChange: (function (name) {
                                return Curry._1(setNameInput, (function (param) {
                                              return name;
                                            }));
                              }),
                            placeholder: "My New View",
                            stretch: true,
                            onEnter: (function (param) {
                                if (disabled) {
                                  return ;
                                } else {
                                  return handleSave(undefined);
                                }
                              }),
                            value: nameInput
                          }), React.createElement(Spacer.make, {
                            height: 12
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("column"),
                                    tl: {
                                      hd: Css.justifyContent("stretch"),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(Button.make, {
                                disabled: disabled,
                                label: "Save filtered view",
                                loading: match$4[0],
                                onClick: handleSave
                              }))))
            });
}

function extractText(node) {
  var extractText$1 = function (output, node) {
    var match = node.children.length;
    if (match !== 0) {
      var textContent = node.textContent.trim();
      var childText = Belt_Array.map(node.children, (function (childNode) {
                return childNode.textContent.trim();
              })).join("");
      if (textContent === childText) {
        return Belt_Array.concatMany([
                    output,
                    Belt_Array.concatMany(Belt_Array.map(node.children, (function (childNode) {
                                return extractText$1([], childNode);
                              })))
                  ]);
      } else {
        return Belt_Array.concatMany([
                    output,
                    [textContent.replace(childText, "")],
                    Belt_Array.concatMany(Belt_Array.map(node.children, (function (childNode) {
                                return extractText$1([], childNode);
                              })))
                  ]);
      }
    }
    var textContent$1 = node.textContent.trim();
    if (textContent$1 === "") {
      return output;
    } else {
      return Belt_Array.concat(output, [textContent$1]);
    }
  };
  return extractText$1([], node);
}

function getCell(column, issue) {
  var re = /\n+/g;
  var tmp;
  if (column.NAME === "component") {
    var html = ServerJs.renderToString(Curry._1(column.VAL[1], issue));
    var element = document.createElement("div");
    element.innerHTML = html.trim();
    var element$1 = Belt_Array.get(element.children, 0);
    tmp = element$1 !== undefined ? extractText(Caml_option.valFromOption(element$1)).join(",") : "";
  } else {
    tmp = Curry._1(column.VAL[1], issue);
  }
  return tmp.replace(re, ",");
}

function getCsv(issues, columns) {
  var header = Belt_Array.map(columns, (function (column) {
            return String.fromCharCode(34) + column.VAL[0].name + String.fromCharCode(34);
          })).join(",") + String.fromCharCode(10);
  var issues$1 = Belt_Array.map(issues, (function (issue) {
            return Belt_Array.map(columns, (function (column) {
                            return String.fromCharCode(34) + getCell(column, issue) + String.fromCharCode(34);
                          })).join(",");
          })).join(String.fromCharCode(10));
  return header + issues$1;
}

function InspectorIssuesView$ExportButton(Props) {
  var match = InspectorIssuesStore.useFilteredIssues(undefined, undefined);
  var filteredIssues = match.filteredIssues;
  var match$1 = InspectorIssuesFilterHooks.useFilters(undefined);
  var filters = match$1.filters;
  var orderBy = InspectorIssuesFilterHooks.useOrderedBy(undefined);
  var match$2 = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var savedView = match$2.savedView;
  var match$3 = InspectorIssuesStore.useAppVersionsInSyncWithIssues(3, undefined);
  var appVersions = match$3[0];
  var columns = React.useMemo((function () {
          var match = Belt_Array.get(InspectorIssuesFilterUtils.getFilters(filters, "release"), 0);
          return getColumns(appVersions, match !== undefined && typeof match === "object" && match.NAME === "release" ? match.VAL : undefined);
        }), [
        appVersions,
        filters
      ]);
  return React.createElement(SimpleTooltip.make, {
              tooltip: "Use to export the issues below into CSV format",
              children: React.createElement(Button.make, {
                    icon: "share",
                    label: "Export as CSV",
                    onClick: (function (param) {
                        var viewName = Belt_Option.mapWithDefault(savedView, "issues", (function (view) {
                                return view.name;
                              }));
                        var orderedIssues;
                        if (orderBy !== undefined) {
                          var compare = Curry._2(IssuesTable.getColumnCompareFromId, columns, orderBy[0]);
                          if (compare !== undefined) {
                            var compare$1 = orderBy[1] === "asc" ? Curry.__2(compare) : (function (a, b) {
                                  return Curry._2(compare, b, a);
                                });
                            orderedIssues = Belt_SortArray.stableSortBy(filteredIssues, compare$1);
                          } else {
                            orderedIssues = filteredIssues;
                          }
                        } else {
                          orderedIssues = filteredIssues;
                        }
                        var csv = getCsv(orderedIssues, columns);
                        var match = ExportFormat.toHeader(/* Csv */2);
                        var mimeType = match[0];
                        Downloadjs(new Blob([csv], {
                                  encoding: "UTF-8",
                                  type: mimeType
                                }), viewName + "." + match[1], mimeType);
                        
                      }),
                    style: "outline"
                  })
            });
}

function InspectorIssuesView$CreatedBy(Props) {
  var userId = Props.userId;
  var createdAt = Props.createdAt;
  var user = FirebaseFetcherHooks.useUser(userId);
  var createdAt$1 = DateFns.distanceInWords(false, undefined, createdAt, new Date());
  if (typeof user === "object" && user.NAME === "Success") {
    return React.createElement($$Text.make, {
                element: "Span",
                size: "Small",
                color: Styles.Color.grey70,
                children: null
              }, "Created by ", React.createElement($$Text.make, {
                    element: "Span",
                    weight: "Semi",
                    children: AvoConfig.getUserDisplayName(user.VAL)
                  }), " " + createdAt$1 + " ago");
  } else {
    return null;
  }
}

function InspectorIssuesView$SavedViewName(Props) {
  var view = Props.view;
  var filteredIssuesCount = Props.filteredIssuesCount;
  var sendActions = SendActionsContext.use(undefined);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setIsSaving = match[1];
  var match$1 = React.useState(function () {
        return view.name;
      });
  var setNameInput = match$1[1];
  var nameInput = match$1[0];
  var match$2 = InspectorIssuesFilterHooks.useFilters(undefined);
  var orderedBy = match$2.orderedBy;
  var filters = match$2.filters;
  var match$3 = Hooks.useDisclosure(false);
  var actions = match$3[1];
  var addToast = Toast.useAddToast(undefined);
  var match$4 = WorkspaceContext.use(undefined);
  var schemaId = match$4.id;
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var match$5 = InspectorIssuesFilterHooks.useDraftState(undefined);
  var isDraft = match$5.isDraft;
  React.useEffect((function () {
          Curry._1(setNameInput, (function (param) {
                  return view.name;
                }));
          
        }), [view]);
  var handleSave = function (param) {
    Curry._1(setIsSaving, (function (param) {
            return true;
          }));
    return FirebaseUtils.updateSavedView(sendActions, schemaId, {
                id: view.id,
                name: nameInput,
                createdBy: view.createdBy,
                createdAt: view.createdAt,
                lastModifiedBy: view.lastModifiedBy,
                lastModifiedAt: view.lastModifiedAt,
                filters: view.filters,
                order: view.order,
                isArchived: view.isArchived
              }, Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid, (function (param) {
                  Curry._1(actions.close, undefined);
                  Curry._1(addToast, {
                        message: "Name Updated",
                        toastType: /* Success */0
                      });
                  Curry._1(setIsSaving, (function (param) {
                          return false;
                        }));
                  return AnalyticsRe.inspectorSavedViewInteraction(schemaGroup, InspectorIssuesHelpers.createInspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.groupFilters(filters), orderedBy, filteredIssuesCount), AnalyticsRe.Group.inspectorSavedViews(nameInput, view.id, "Creator", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) : -1), "Rename", schemaGroup.schemaId);
                }), (function (err) {
                  Curry._1(addToast, {
                        message: err.RE_EXN_ID === AvoState.PermissionsException ? "Permissions needed to save view..." : "Failed to save view...",
                        toastType: /* Error */1
                      });
                  return Curry._1(setIsSaving, (function (param) {
                                return false;
                              }));
                }));
  };
  var disabled = nameInput === "" || nameInput === view.name;
  var tmp = {
    size: "Large",
    weight: "Semi",
    color: isDraft ? Styles.Color.grey70 : Styles.Color.grey90,
    children: view.name
  };
  var tmp$1 = isDraft ? "Em" : undefined;
  if (tmp$1 !== undefined) {
    tmp.element = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Mantine.Menu.make, {
              onChange: (function (param) {
                  return Curry._1(actions.close, undefined);
                }),
              opened: match$3[0],
              position: "bottom-start",
              children: null
            }, React.createElement(Core.Menu.Target, {
                  children: React.createElement("span", {
                        role: "button",
                        onClick: (function (param) {
                            return Curry._1(actions.toggle, undefined);
                          })
                      }, React.createElement($$Text.make, tmp))
                }), React.createElement(Mantine.Menu.Dropdown.make, {
                  additionalStyles: {
                    hd: Css.padding(Css.px(20)),
                    tl: {
                      hd: Css.maxWidth(Css.px(300)),
                      tl: /* [] */0
                    }
                  },
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.grey80,
                      children: "Rename filtered view"
                    }), React.createElement(Spacer.make, {
                      height: 20
                    }), React.createElement(TextInput.make, {
                      onChange: (function (newName) {
                          return Curry._1(setNameInput, (function (param) {
                                        return newName;
                                      }));
                        }),
                      stretch: true,
                      onEnter: (function (param) {
                          if (disabled) {
                            return ;
                          } else {
                            return handleSave(undefined);
                          }
                        }),
                      value: nameInput
                    }), React.createElement(Spacer.make, {
                      height: 12
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("column"),
                              tl: {
                                hd: Css.justifyContent("stretch"),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement(Button.make, {
                          disabled: disabled,
                          label: "Rename filtered view",
                          loading: match[0],
                          onClick: (function (param) {
                              return handleSave(undefined);
                            })
                        }))));
}

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingRight(Css.px(24)),
          tl: {
            hd: Css.paddingLeft(Css.px(40)),
            tl: {
              hd: Css.height(Css.px(64)),
              tl: {
                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                tl: {
                  hd: Css.marginBottom(Css.px(20)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var subHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingRight(Css.px(24)),
          tl: {
            hd: Css.paddingLeft(Css.px(40)),
            tl: {
              hd: Css.marginBottom(Css.px(20)),
              tl: {
                hd: Css.justifyContent("spaceBetween"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var subHeaderTitle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("baseline"),
          tl: /* [] */0
        }
      }
    });

var toolbar = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.gap(Css.px(8)),
          tl: /* [] */0
        }
      }
    });

function InspectorIssuesView$CopyButton(Props) {
  var savedView = Props.savedView;
  var issueCount = Props.issueCount;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var orderedBy = match.orderedBy;
  var filters = match.filters;
  var addToast = Toast.useAddToast(undefined);
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var handleCopy = function (param) {
    CopyTextToClipboard(window.location.href);
    Curry._1(addToast, {
          message: "Link copied!",
          toastType: /* Success */0
        });
    return Belt_Option.forEach(savedView, (function (savedView) {
                  return AnalyticsRe.inspectorSavedViewInteraction(schemaGroup, InspectorIssuesHelpers.createInspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.groupFilters(filters), orderedBy, issueCount), AnalyticsRe.Group.inspectorSavedViews(savedView.name, savedView.id, Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid === savedView.createdBy ? "Creator" : "Visitor", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) : -1), "CopyShareLink", schemaGroup.schemaId);
                }));
  };
  return React.createElement(SimpleTooltip.make, {
              tooltip: "Copy link to this view",
              children: React.createElement(Button.make, {
                    icon: "link",
                    onClick: handleCopy,
                    size: "small",
                    style: "outline"
                  })
            });
}

function InspectorIssuesView$IntercomTour(Props) {
  var match = Router.QueryParams.useKey("start_tour");
  var removeByReplace = match.removeByReplace;
  var value = match.value;
  React.useEffect((function () {
          if (value === "saved_views") {
            Intercom.startTour("441571");
            Curry._1(removeByReplace, undefined);
          }
          
        }), []);
  return null;
}

function InspectorIssuesView$DraftBadge(Props) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.borderRadius(Css.px(10)),
                    tl: {
                      hd: Css.padding2(Css.px(2), Css.px(8)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.green20),
                        tl: {
                          hd: Css.color(Styles.Color.green120),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Tiny",
                  weight: "Semi",
                  children: "DRAFT"
                }));
}

var debounceFunction = Debounce((function (fn) {
        return Curry._1(fn, undefined);
      }), 150, {
      leading: false,
      trailing: true
    });

function InspectorIssuesView$AlertsBetaDisclaimer(Props) {
  var reportHeight = Props.reportHeight;
  var wrapperRef = React.useRef(null);
  var match = React$1.useFlow("alerts-beta-disclaimer");
  var flow = match[0];
  var runReporter = flow.state.started && !flow.state.finished && !flow.state.stopped;
  var match$1 = React.useState(function () {
        if (!runReporter) {
          return 0;
        }
        var el = wrapperRef.current;
        if (!(el == null)) {
          return el.getBoundingClientRect().height;
        } else {
          return 0;
        }
      });
  var setHeight = match$1[1];
  var height = match$1[0];
  React.useEffect((function () {
          if (flow.state.finished || flow.state.stopped) {
            Curry._1(setHeight, (function (param) {
                    return 0;
                  }));
          }
          var resizeHandler = function (param) {
            if (!runReporter) {
              return ;
            }
            var el = wrapperRef.current;
            if (!(el == null)) {
              return Curry._1(setHeight, (function (param) {
                            return el.getBoundingClientRect().height;
                          }));
            }
            
          };
          var debouncedResizeHandler = function (param) {
            return debounceFunction(resizeHandler);
          };
          if (runReporter) {
            var el = wrapperRef.current;
            if (!(el == null)) {
              Curry._1(setHeight, (function (param) {
                      return el.getBoundingClientRect().height;
                    }));
            }
            window.addEventListener("resize", debouncedResizeHandler);
          }
          return (function (param) {
                    window.removeEventListener("resize", debouncedResizeHandler);
                    
                  });
        }), [
        flow.state.started,
        flow.state.stopped,
        flow.state.finished
      ]);
  Hooks.useDidUpdate1((function (param) {
          Belt_Option.forEach(reportHeight, (function (cb) {
                  return Curry._1(cb, height);
                }));
          
        }), [height]);
  return React.createElement(ProductTour.make, {
              tourId: "alerts-beta-disclaimer",
              tourMayShow: true,
              children: React.createElement(ProductTour.Step.make, {
                    stepId: "issues-view-disclaimer",
                    stepType: /* Disclaimer */0,
                    delay: 0,
                    transitions: [{
                        key: "complete",
                        label: undefined,
                        icon: "close"
                      }],
                    children: React.createElement("div", {
                          ref: wrapperRef,
                          className: Curry._1(Css.style, {
                                hd: Css.marginLeft(Css.px(24)),
                                tl: {
                                  hd: Css.marginRight(Css.px(24)),
                                  tl: {
                                    hd: Css.selector(" a", {
                                          hd: Css.textDecoration("none"),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: {
                                              hd: Css.color(Styles.Color.blue100),
                                              tl: /* [] */0
                                            }
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }),
                          onClick: (function (e) {
                              var target = e.target;
                              try {
                                if (target.innerText === "reach out" && target.parentNode.href.includes("openIntercom")) {
                                  e.preventDefault();
                                  return Intercom.showNewMessage(undefined);
                                } else {
                                  return ;
                                }
                              }
                              catch (exn){
                                return ;
                              }
                            })
                        }, React.createElement(ProductTour.GenericDisclaimer.make, {
                              id: "alerts-beta-disclaimer"
                            }), React.createElement(Spacer.make, {
                              height: 32
                            }))
                  })
            });
}

function InspectorIssuesView$WhatAreIssues(Props) {
  var match = React$1.useFlow("inspector-issues-onboarding-flow");
  var intent = match[1];
  var match$1 = Hooks.useDisclosure(false);
  var actions = match$1[1];
  var opened = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setJustClosed = match$2[1];
  var justClosed = match$2[0];
  React.useEffect((function () {
          if (!justClosed) {
            return ;
          }
          var timeoutId = setTimeout((function (param) {
                  return Curry._1(setJustClosed, (function (param) {
                                return false;
                              }));
                }), 100);
          return (function (param) {
                    clearTimeout(timeoutId);
                    
                  });
        }), [justClosed]);
  var ref = Hooks$1.useClickOutside(function (param) {
        Curry._1(actions.close, undefined);
        return Curry._1(setJustClosed, (function (param) {
                      return true;
                    }));
      });
  return React.createElement(Mantine.Popover.make, {
              arrowSize: 12,
              arrowOffset: 82,
              arrowStyles: {
                hd: Css.backgroundColor(Styles.Color.grey80),
                tl: {
                  hd: Css.boxSizing("unset"),
                  tl: {
                    hd: Css.borderColor("transparent"),
                    tl: {
                      hd: Css.zIndex(-1),
                      tl: /* [] */0
                    }
                  }
                }
              },
              closeOnClickOutside: true,
              dropdownStyles: {
                hd: Css.borderRadius(Css.px(6)),
                tl: {
                  hd: Css.backgroundColor("transparent"),
                  tl: {
                    hd: Css.boxShadow(Styles.Shadow.box),
                    tl: /* [] */0
                  }
                }
              },
              opened: opened,
              position: "bottom-end",
              withArrow: true,
              zIndex: Styles.ZIndex.aboveAll + 1 | 0,
              children: null
            }, React.createElement(Core.Popover.Target, {
                  children: React.createElement(Button.make, {
                        label: "What are Inspector issues?",
                        onClick: (function (param) {
                            if (opened || !justClosed) {
                              return Curry._1(actions.toggle, undefined);
                            }
                            
                          }),
                        style: "ghost"
                      })
                }), React.createElement(Mantine.Popover.Dropdown.make, {
                  children: React.createElement("div", {
                        ref: ref,
                        className: Curry._1(Css.style, {
                              hd: Css.backgroundColor(Styles.Color.grey80),
                              tl: {
                                hd: Css.padding(Css.px(32)),
                                tl: {
                                  hd: Css.borderRadius(Styles.Border.radius),
                                  tl: {
                                    hd: Css.maxWidth(Css.px(320)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement(Title.make, {
                            children: "Inspector Issues",
                            color: Styles.Color.white,
                            size: "Tiny"
                          }), React.createElement(Spacer.make, {
                            height: 8
                          }), React.createElement($$Text.make, {
                            element: "Div",
                            size: "Medium",
                            color: Styles.Color.white,
                            children: React.createElement(Markdown.make, {
                                  source: "\nInspector issues are anomalies and discrepancies found on events and properties in your production data.\n\nIn this view you can:\n\n- Filter your issues to those most relevant to you\n- Save your filtered view for fast access\n- Set up custom alerts based on your filtering, to never miss a critical data bug again"
                                })
                          }), React.createElement(Spacer.make, {
                            height: 8
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("row"),
                                    tl: {
                                      hd: Css.gap(Css.px(8)),
                                      tl: {
                                        hd: Css.justifyContent("flexEnd"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement("a", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.flexDirection("row"),
                                        tl: {
                                          hd: Css.color(Styles.Color.grey40),
                                          tl: {
                                            hd: Css.textDecoration("none"),
                                            tl: {
                                              hd: Css.border(Css.px(1), "solid", Styles.Color.grey40),
                                              tl: {
                                                hd: Css.borderRadius(Styles.Border.radius),
                                                tl: {
                                                  hd: Button.getPadding("small", true, true),
                                                  tl: {
                                                    hd: Icon.styles({
                                                          hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey40),
                                                          tl: {
                                                            hd: Css.transition({
                                                                  NAME: "ms",
                                                                  VAL: 300.0
                                                                }, undefined, undefined, "all"),
                                                            tl: /* [] */0
                                                          }
                                                        }),
                                                    tl: {
                                                      hd: Css.transition({
                                                            NAME: "ms",
                                                            VAL: 300.0
                                                          }, undefined, undefined, "all"),
                                                      tl: {
                                                        hd: Css.hover({
                                                              hd: Css.color(Styles.Color.white),
                                                              tl: {
                                                                hd: Icon.color(Styles.Color.white),
                                                                tl: {
                                                                  hd: Css.borderColor(Styles.Color.white),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }),
                                href: "https://www.avo.app/docs/inspector/inspector-issues-view"
                              }, React.createElement(Icon.make, {
                                    type_: "book"
                                  }), React.createElement(Spacer.make, {
                                    width: 8
                                  }), React.createElement($$Text.make, {
                                    element: "Span",
                                    size: "Small",
                                    weight: "Semi",
                                    children: "Read Docs"
                                  })), React.createElement(Button.make, {
                                icon: "presentation",
                                label: "Take tour",
                                onClick: (function (param) {
                                    Curry._1(actions.toggle, undefined);
                                    intent.reset({force: true});
                                    intent.start({force: true});
                                    
                                  })
                              })))
                }));
}

function InspectorIssuesView(Props) {
  var model = Props.model;
  var match = WorkspaceContext.use(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var match$1 = InspectorIssuesStore.useFilteredIssues(undefined, undefined);
  var filteredIssues = match$1.filteredIssues;
  var addToast = Toast.useAddToast(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var match$2 = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var currentSavedView = match$2.savedView;
  var previousSavedView = Hooks.usePrevious1(currentSavedView);
  var hasExportIssuesToCSV = AppFeatureFlag.useFeatureFlag("ExportIssuesToCSV");
  var match$3 = InspectorIssuesFilterHooks.useDraftState(undefined);
  var isDraft = match$3.isDraft;
  React.useEffect((function () {
          if (previousSavedView !== undefined && currentSavedView === undefined && typeof savedViews === "object" && savedViews.NAME === "Loaded" && typeof schemaRoute === "object" && schemaRoute.NAME === "inspector") {
            var match = schemaRoute.VAL;
            if (typeof match === "object" && match.NAME === "savedView" && previousSavedView.id === match.VAL) {
              Curry._1(addToast, {
                    message: "\"" + previousSavedView.name + "\" was just archived",
                    toastType: /* Info */2,
                    persist: true
                  });
              Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                        NAME: "inspector",
                        VAL: "issues"
                      }));
            }
            
          }
          
        }), [
        currentSavedView,
        savedViews
      ]);
  var inspectorStats = InspectorStats.useStatsWithCache(match.id);
  var hasSourcesWithoutInspector = Belt_List.some(model.sources, (function (source) {
          var match = InspectorStats.getSourceStatus(model, source, inspectorStats);
          return match === 2 || match === 1;
        }));
  var inspectorSources = Belt_List.toArray(Belt_List.keep(model.sources, (function (source) {
              var match = InspectorStats.getSourceStatus(model, source, inspectorStats);
              return !(match === 2 || match === 1);
            })));
  var match$4 = React.useState(function () {
        return 0;
      });
  var setBetaDisclaimerHeight = match$4[1];
  if (typeof inspectorStats !== "number" && inspectorStats._0.length === 0) {
    return React.createElement(InspectorEmptyState.make, {
                hasSourcesWithoutInspector: hasSourcesWithoutInspector,
                inspectorView: "issues"
              });
  }
  return React.createElement("div", undefined, React.createElement(InspectorIssuesView$IntercomTour, {}), React.createElement("header", {
                  className: header
                }, React.createElement(Title.make, {
                      children: "Inspector Issues",
                      size: "Tiny"
                    }), React.createElement(Spacer.make, {
                      width: 16
                    }), React.createElement(Button.make, {
                      icon: "plus",
                      label: "Connect Source",
                      onClick: (function (param) {
                          return Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: hasSourcesWithoutInspector ? ({
                                            NAME: "SourceSetup",
                                            VAL: [
                                              "inspectorSetup",
                                              {
                                                NAME: "inspector",
                                                VAL: "events"
                                              }
                                            ]
                                          }) : ({
                                            NAME: "CreateSource",
                                            VAL: "inspectorSetup"
                                          })
                                    });
                        }),
                      style: "outline"
                    }), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), React.createElement(InspectorIssuesView$WhatAreIssues, {})), React.createElement(InspectorIssuesView$AlertsBetaDisclaimer, {
                  reportHeight: (function (height) {
                      return Curry._1(setBetaDisclaimerHeight, (function (param) {
                                    return height;
                                  }));
                    })
                }), React.createElement("div", {
                  className: subHeader
                }, React.createElement("div", {
                      className: subHeaderTitle
                    }, currentSavedView !== undefined ? React.createElement(InspectorIssuesView$SavedViewName, {
                            view: currentSavedView,
                            filteredIssuesCount: filteredIssues.length
                          }) : (
                        isDraft ? React.createElement(InspectorIssuesView$SaveButton, {
                                target: React.createElement("button", {
                                      className: Curry._1(Css.merge, {
                                            hd: Curry._1(Css.style, Styles.buttonReset),
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.cursor("text"),
                                                    tl: /* [] */0
                                                  }),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, React.createElement($$Text.make, {
                                          element: "Em",
                                          size: "Large",
                                          weight: "Semi",
                                          color: Styles.Color.grey70,
                                          children: "Untitled Draft"
                                        })),
                                filteredIssuesCount: filteredIssues.length,
                                position: "bottom",
                                currentSavedView: currentSavedView
                              }) : React.createElement($$Text.make, {
                                size: "Large",
                                weight: "Semi",
                                color: Styles.Color.grey90,
                                children: "All issues"
                              })
                      ), React.createElement(Spacer.make, {
                          width: 4
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.grey70,
                          children: "(" + AvoUtils.plural(filteredIssues.length, "issue") + ")"
                        }), React.createElement(Spacer.make, {
                          width: 4
                        }), currentSavedView !== undefined ? React.createElement(InspectorIssuesView$CreatedBy, {
                            userId: currentSavedView.createdBy,
                            createdAt: currentSavedView.createdAt
                          }) : null, React.createElement(Spacer.make, {
                          width: 4
                        }), isDraft ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Belt_Option.isSome(currentSavedView) ? Styles.emptyStyle : Css.alignSelf("flexStart"),
                                  tl: {
                                    hd: Css.marginTop(Css.px(5)),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement(InspectorIssuesView$DraftBadge, {})) : null, hasExportIssuesToCSV ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                width: 8
                              }), React.createElement(InspectorIssuesView$ExportButton, {})) : null), React.createElement("div", {
                      className: toolbar
                    }, React.createElement("span", {
                          id: "set-up-alerts"
                        }, React.createElement(ConfigureAlertsButton.make, {
                              globalSend: globalSend,
                              savedView: currentSavedView,
                              key: currentSavedView !== undefined ? currentSavedView.id : "all"
                            })), React.createElement(InspectorIssuesView$CopyButton, {
                          savedView: currentSavedView,
                          issueCount: filteredIssues.length
                        }), currentSavedView !== undefined ? React.createElement(InspectorIssuesView$DuplicateButton, {
                            filteredIssuesCount: filteredIssues.length
                          }) : null, React.createElement(InspectorIssuesView$SaveButton, {
                          filteredIssuesCount: filteredIssues.length,
                          currentSavedView: currentSavedView
                        }))), React.createElement(InspectorIssuesView$Table, {
                  inspectorSources: inspectorSources,
                  globalSend: globalSend,
                  betaDisclaimerHeight: match$4[0]
                }));
}

var make = InspectorIssuesView;

exports.make = make;
/* header Not a pure module */
