// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Kbar = require("kbar");
var Canny = require("./externals/canny.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("./Icons.bs.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var DateFns = require("./DateFns.bs.js");
var AvoAdmin = require("./AvoAdmin.bs.js");
var Workspace = require("../../model/src/Workspace.bs.js");
var IconSearch = require("./IconSearch.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RouterStore = require("./RouterStore.bs.js");
var ProductTours = require("./ProductTours.bs.js");
var ProfilePhoto = require("./ProfilePhoto.bs.js");
var TrialRequest = require("./TrialRequest.bs.js");
var IconLightning = require("./IconLightning.bs.js");
var ViewerContext = require("./ViewerContext.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var AppFeatureFlag = require("./AppFeatureFlag.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var SidebarNavigation = require("./SidebarNavigation.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SidebarSettingsCog = require("./SidebarSettingsCog.bs.js");
var SidebarBranchControl = require("./SidebarBranchControl.bs.js");
var SetupIssuesAlertsFlow = require("./SetupIssuesAlertsFlow.bs.js");
var OnboardingAndTrialProgressInSidebar = require("./OnboardingAndTrialProgressInSidebar.bs.js");

var bannerStyle = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.padding2(Css.px(6), Css.px(6)),
                  tl: {
                    hd: Css.borderRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.magenta),
                      tl: {
                        hd: Css.color(Styles.Color.white),
                        tl: {
                          hd: Css.textAlign("left"),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.medium),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: {
                                hd: Css.marginBottom(Css.px(16)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var upgradeStyle = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(5)),
      tl: {
        hd: Css.textDecoration("underline"),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.bold),
          tl: /* [] */0
        }
      }
    });

function Sidebar$SidebarTrialBanner(Props) {
  var role = Props.role;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var trial = workspace.trial;
  if (trial !== undefined) {
    return React.createElement("button", {
                className: bannerStyle,
                onClick: (function (param) {
                    Router.Schema.pushSchemaRoute(undefined, undefined, "billing");
                    return AnalyticsRe.upgradeBannerClicked(schemaGroup, AnalyticsUtils.roleToAnalyticsRole(role), schemaGroup.branchId, schemaGroup.schemaId);
                  })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css_Legacy_Core.SVG.fill(Styles.Color.white),
                          tl: {
                            hd: Css.margin4(Css.px(0), Css.px(10), Css.px(0), Css.px(5)),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(IconLightning.make, {
                        size: 16
                      })), React.createElement("div", undefined, DateFns.distanceInWordsToNow(false, false, trial.endAt) + " left of your free trial.", React.createElement("span", {
                        className: upgradeStyle
                      }, "Upgrade now!")));
  } else {
    return null;
  }
}

var SidebarTrialBanner = {
  bannerStyle: bannerStyle,
  upgradeStyle: upgradeStyle,
  make: Sidebar$SidebarTrialBanner
};

function containerStyle(hidden) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.position("fixed"),
                  tl: {
                    hd: Css.top(Css.px(0)),
                    tl: {
                      hd: Css.bottom(Css.px(0)),
                      tl: {
                        hd: Css.left(Css.px(0)),
                        tl: {
                          hd: Css.padding2("zero", Css.px(8)),
                          tl: {
                            hd: Css.transform(Css.translateX(Css.px(hidden ? -250 : 0))),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$long
                                  }, undefined, undefined, "transform"),
                              tl: {
                                hd: Css.width(Css.px(250)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.grey100),
                                  tl: {
                                    hd: Css.zIndex(Styles.ZIndex.sidebar),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function Sidebar$SidebarContainer(Props) {
  var hidden = Props.hidden;
  var children = Props.children;
  return React.createElement("aside", {
              className: containerStyle(hidden),
              id: "sidebar-tour"
            }, children);
}

var SidebarContainer = {
  containerStyle: containerStyle,
  make: Sidebar$SidebarContainer
};

var headerStyle = Curry._1(Css.style, {
      hd: Css.media("(max-height: 660px)", {
            hd: Css.padding2(Css.px(12), Css.px(0)),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.display("grid"),
        tl: {
          hd: Css.gridTemplateColumns({
                hd: "auto",
                tl: {
                  hd: Css.px(32),
                  tl: /* [] */0
                }
              }),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.padding2(Css.px(16), "zero"),
              tl: {
                hd: Css.margin2("zero", Css.px(12)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var profileContainerStyle = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.cursor("pointer"),
              tl: {
                hd: Css.margin(Css.px(0)),
                tl: {
                  hd: Css.padding(Css.px(0)),
                  tl: {
                    hd: Css.backgroundColor(Css.transparent),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: {
                          hd: Css.borderRadius(Css.px(100)),
                          tl: {
                            hd: Css.active({
                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.focus({
                                    hd: Css.outlineStyle("none"),
                                    tl: {
                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.blue)),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: {
                                hd: Css.unsafe("appearance", "none"),
                                tl: {
                                  hd: Css.transition({
                                        NAME: "ms",
                                        VAL: Styles.Duration.$$default
                                      }, undefined, undefined, "box-shadow"),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.boxShadow(Styles.Shadow.glow(Styles.Color.grey80)),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function Sidebar$SidebarHeader(Props) {
  var globalSend = Props.globalSend;
  var role = Props.role;
  var user = ViewerContext.use(undefined);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var hideTrialBanner = AppFeatureFlag.useFeatureFlag("HideTrialBanner");
  var match = RouterStore.Schema.useSlices(function (param) {
        return [
                param.baseRoute,
                param.schemaRoute,
                param.drawerItems
              ];
      });
  var drawerItems = match[2];
  var schemaRoute = match[1];
  var baseRoute = match[0];
  return React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: headerStyle
                }, React.createElement(Link.make, {
                      path: Router.Link.getSchemaRouteLink(undefined, "dashboard"),
                      onClick: (function (_event) {
                          return AnalyticsRe.logoClicked(schemaGroup, Router.baseRouteLocationToAnalyticsLocation(baseRoute, schemaRoute, drawerItems));
                        }),
                      id: "sidebar-avo-logo",
                      children: React.createElement(Icons.AvoLogo.make, {
                            width: 60,
                            fill: Styles.Color.white
                          })
                    }), React.createElement("button", {
                      className: profileContainerStyle,
                      id: "sidebar-tour-profile",
                      onClick: (function (param) {
                          return Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "Profile",
                                        VAL: user.id
                                      }
                                    });
                        })
                    }, React.createElement(ProfilePhoto.make, {
                          withBorder: false,
                          user: user,
                          size: 32
                        }))), hideTrialBanner ? null : React.createElement(Sidebar$SidebarTrialBanner, {
                    role: role
                  }));
}

var SidebarHeader = {
  headerStyle: headerStyle,
  profileContainerStyle: profileContainerStyle,
  make: Sidebar$SidebarHeader
};

var footerContainerStyle = Curry._1(Css.style, {
      hd: Css.media("(max-height: 660px)", {
            hd: Css.marginTop(Css.px(12)),
            tl: {
              hd: Css.marginBottom(Css.px(12)),
              tl: /* [] */0
            }
          }),
      tl: {
        hd: Css.width(Css.pct(100)),
        tl: {
          hd: Css.marginTop(Css.px(22)),
          tl: {
            hd: Css.marginBottom(Css.px(22)),
            tl: {
              hd: Css.flexGrow(1.0),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.alignItems("stretch"),
                  tl: {
                    hd: Css.display("flex"),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var footerItemStyle = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.media("(max-height: 660px)", {
                    hd: Css.fontSize(Styles.FontSize.small),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.display("block"),
                tl: {
                  hd: Css.margin(Css.px(0)),
                  tl: {
                    hd: Css.padding2(Css.px(4), Css.px(0)),
                    tl: {
                      hd: Css.color(Styles.Color.grey50),
                      tl: {
                        hd: Css.fontWeight(Styles.FontWeight.medium),
                        tl: {
                          hd: Css.fontSize(Styles.FontSize.regular),
                          tl: {
                            hd: Css.borderRadius(Css.px(2)),
                            tl: {
                              hd: Css.active({
                                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.focus({
                                      hd: Css.outlineStyle("none"),
                                      tl: {
                                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.blue)),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.color(Styles.Color.grey30),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

Curry._2(Css.$$global, " .Canny_BadgeContainer .Canny_Badge", {
      hd: Css.top(Css.px(-1)),
      tl: {
        hd: Css.right(Css.px(-9)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.magenta),
          tl: {
            hd: Css.borderColor(Styles.Color.grey100),
            tl: /* [] */0
          }
        }
      }
    });

function Sidebar$SidebarFooter(Props) {
  var schema = Props.schema;
  var globalSend = Props.globalSend;
  var role = Props.role;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = TrialRequest.useTrialRequest(workspace.id);
  React.useEffect((function () {
          Canny.initChangelog({
                appID: Canny.appID,
                position: "right",
                align: "bottom"
              });
          
        }), []);
  return React.createElement("footer", {
              className: footerContainerStyle
            }, React.createElement(Spacer.make, {
                  height: 8,
                  grow: 1.0
                }), React.createElement(SetupIssuesAlertsFlow.make, {}), Workspace.isV2Plan(workspace) ? React.createElement(OnboardingAndTrialProgressInSidebar.make, {
                    schema: schema,
                    globalSend: globalSend,
                    viewerIsAdmin: role === "Admin"
                  }) : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(8)),
                        tl: /* [] */0
                      })
                }, React.createElement("button", {
                      className: footerItemStyle,
                      id: "product-tour-invite-people",
                      onClick: (function (param) {
                          return SidebarSettingsCog.openMembersModal(schemaGroup, globalSend);
                        })
                    }, role === "Admin" ? "Invite people" : "Workspace members"), role === "Admin" && match[0] === /* Available */2 ? React.createElement("button", {
                        className: footerItemStyle,
                        id: "sidebar-start-team-trial",
                        onClick: (function (param) {
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "TryForFree"
                                        }
                                      });
                          })
                      }, "Start Team Trial") : null, React.cloneElement(React.createElement("button", {
                          className: footerItemStyle
                        }, "What's new?"), {
                      "data-canny-changelog": ""
                    })));
}

var SidebarFooter = {
  footerContainerStyle: footerContainerStyle,
  footerItemStyle: footerItemStyle,
  make: Sidebar$SidebarFooter
};

var searchContainerStyle = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(0)),
      tl: {
        hd: Css.marginRight(Css.px(0)),
        tl: {
          hd: Css.marginBottom(Css.px(12)),
          tl: {
            hd: Css.marginLeft(Css.px(0)),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("spaceBetween"),
                  tl: {
                    hd: Css.borderRadius(Css.px(16)),
                    tl: {
                      hd: Css.padding2(Css.px(8), Css.px(8)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.grey90),
                        tl: {
                          hd: Css.color(Styles.Color.grey50),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.medium),
                              tl: {
                                hd: Css.cursor("text"),
                                tl: {
                                  hd: Css.backgroundColor(Css.transparent),
                                  tl: {
                                    hd: Css.border(Css.px(1), "solid", Styles.Color.grey80),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.borderRadius(Css.px(100)),
                                        tl: {
                                          hd: Css.active({
                                                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                                tl: /* [] */0
                                              }),
                                          tl: {
                                            hd: Css.focus({
                                                  hd: Css.outlineStyle("none"),
                                                  tl: {
                                                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.blue)),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var searchContentStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var searchTextStyle = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(8)),
      tl: /* [] */0
    });

var searchShortcutStyle = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.grey40),
      tl: {
        hd: Css.marginRight(Css.px(2)),
        tl: /* [] */0
      }
    });

function Sidebar$SidebarSearch(Props) {
  var match = Kbar.useKBar();
  var query = match.query;
  return React.createElement("button", {
              className: searchContainerStyle,
              id: "sidebar-tour-search",
              onClick: (function (param) {
                  return Curry._1(query.toggle, undefined);
                })
            }, React.createElement("div", {
                  className: searchContentStyle
                }, React.createElement(IconSearch.make, {
                      size: 16,
                      color: Styles.Color.grey50
                    }), React.createElement("span", {
                      className: searchTextStyle
                    }, "Search")), React.createElement("span", {
                  className: searchShortcutStyle
                }, "cmd+k"));
}

var SidebarSearch = {
  searchContainerStyle: searchContainerStyle,
  searchContentStyle: searchContentStyle,
  searchTextStyle: searchTextStyle,
  searchShortcutStyle: searchShortcutStyle,
  make: Sidebar$SidebarSearch
};

var containerStyles = Curry._1(Css.style, {
      hd: Css.marginBottom(Css.px(12)),
      tl: {
        hd: Css.border(Css.px(1), "solid", Styles.Color.grey80),
        tl: {
          hd: Css.borderRadius(Css.px(10)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.grey90),
            tl: /* [] */0
          }
        }
      }
    });

var topStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.padding(Css.px(8)),
        tl: {
          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey80),
          tl: /* [] */0
        }
      }
    });

function Sidebar$SidebarWorkspace(Props) {
  var currentBranch = Props.currentBranch;
  var globalSend = Props.globalSend;
  var model = Props.model;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var schema = Props.schema;
  var branchStatus = Props.branchStatus;
  return React.createElement("div", {
              className: containerStyles
            }, React.createElement("div", {
                  className: topStyles
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.white,
                      children: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(schema.name), "Untitled Workspace")
                    }), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), React.createElement(SidebarSettingsCog.make, {
                      globalSend: globalSend,
                      model: model,
                      schema: schema,
                      role: role
                    })), React.createElement(SidebarBranchControl.make, {
                  currentBranch: currentBranch,
                  openBranches: openBranches,
                  schema: schema,
                  globalSend: globalSend,
                  role: role,
                  branchStatus: branchStatus
                }));
}

var SidebarWorkspace = {
  containerStyles: containerStyles,
  topStyles: topStyles,
  make: Sidebar$SidebarWorkspace
};

function Sidebar(Props) {
  var currentBranch = Props.currentBranch;
  var globalSend = Props.globalSend;
  var hidden = Props.hidden;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var schema = Props.schema;
  var model = Props.model;
  var branchStatus = Props.branchStatus;
  return React.createElement(Sidebar$SidebarContainer, {
              hidden: hidden,
              children: null
            }, React.createElement(Sidebar$SidebarHeader, {
                  globalSend: globalSend,
                  role: role
                }), React.createElement(Sidebar$SidebarWorkspace, {
                  currentBranch: currentBranch,
                  globalSend: globalSend,
                  model: model,
                  openBranches: openBranches,
                  role: role,
                  schema: schema,
                  branchStatus: branchStatus
                }), React.createElement(Sidebar$SidebarSearch, {}), React.createElement(SidebarNavigation.make, {
                  globalSend: globalSend
                }), React.createElement(Sidebar$SidebarFooter, {
                  schema: schema,
                  globalSend: globalSend,
                  role: role
                }), React.createElement(AvoAdmin.make, {
                  globalSend: globalSend
                }), React.createElement(ProductTours.make, {}));
}

var sidebarWidth = 250;

var make = Sidebar;

exports.sidebarWidth = sidebarWidth;
exports.SidebarTrialBanner = SidebarTrialBanner;
exports.SidebarContainer = SidebarContainer;
exports.SidebarHeader = SidebarHeader;
exports.SidebarFooter = SidebarFooter;
exports.SidebarSearch = SidebarSearch;
exports.SidebarWorkspace = SidebarWorkspace;
exports.make = make;
/* bannerStyle Not a pure module */
