// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

function Spacer(Props) {
  var displayOpt = Props.display;
  var x = Props.width;
  var y = Props.height;
  var grow = Props.grow;
  var display = displayOpt !== undefined ? displayOpt : "block";
  return React.createElement("span", {
              className: Curry._1(Css.style, Belt_List.concatMany([
                        {
                          hd: Css.display(display),
                          tl: {
                            hd: Css.flexShrink(0.0),
                            tl: /* [] */0
                          }
                        },
                        Belt_Option.mapWithDefault(x, /* [] */0, (function (x) {
                                return {
                                        hd: Css.width(Css.px(x)),
                                        tl: /* [] */0
                                      };
                              })),
                        Belt_Option.mapWithDefault(y, /* [] */0, (function (y) {
                                return {
                                        hd: Css.height(Css.px(y)),
                                        tl: /* [] */0
                                      };
                              })),
                        Belt_Option.mapWithDefault(grow, /* [] */0, (function (grow) {
                                return {
                                        hd: Css.flexGrow(grow),
                                        tl: /* [] */0
                                      };
                              }))
                      ]))
            });
}

var make = Spacer;

exports.make = make;
/* Css Not a pure module */
