// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconCsv(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 32;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey20;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.stroke(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.fill(color),
                      tl: /* [] */0
                    }
                  }),
              height: String(size),
              viewBox: "0 0 64 64",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  strokeLinecap: "square",
                  strokeWidth: "1",
                  transform: "translate(0.5 0.5)"
                }, React.createElement("path", {
                      d: "M24.9,39.2c-0.7,0-1.3,0.3-1.7,0.8s-0.6,1.3-0.6,2.3c0,2.1,0.8,3.1,2.3,3.1 c0.7,0,1.4-0.2,2.4-0.5v1.7c-0.8,0.3-1.6,0.5-2.6,0.5c-1.4,0-2.4-0.4-3.1-1.2c-0.7-0.8-1.1-2-1.1-3.5c0-1,0.2-1.8,0.5-2.5 s0.9-1.3,1.5-1.7c0.7-0.4,1.4-0.6,2.3-0.6c0.9,0,1.8,0.2,2.7,0.7L27,39.8c-0.3-0.2-0.7-0.3-1-0.4S25.2,39.2,24.9,39.2z",
                      stroke: "none",
                      strokeLinecap: "square"
                    }), React.createElement("path", {
                      d: "M35,44.4c0,0.8-0.3,1.5-0.9,2c-0.6,0.5-1.4,0.7-2.5,0.7c-1,0-1.9-0.2-2.6-0.6 v-1.8c0.6,0.3,1.2,0.5,1.6,0.6c0.4,0.1,0.8,0.2,1.2,0.2c0.4,0,0.8-0.1,1-0.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.2-0.1-0.3-0.2-0.5 s-0.3-0.3-0.4-0.4c-0.2-0.1-0.6-0.3-1.2-0.6c-0.6-0.3-1-0.5-1.3-0.8s-0.5-0.5-0.7-0.9C29.1,41,29,40.7,29,40.2 c0-0.8,0.3-1.5,0.8-1.9c0.6-0.5,1.3-0.7,2.3-0.7c0.5,0,0.9,0.1,1.4,0.2c0.4,0.1,0.9,0.3,1.4,0.5l-0.6,1.5c-0.5-0.2-0.9-0.3-1.2-0.4 c-0.3-0.1-0.6-0.1-1-0.1c-0.4,0-0.7,0.1-0.9,0.3S31,39.9,31,40.2c0,0.2,0,0.3,0.1,0.5s0.2,0.3,0.4,0.4c0.2,0.1,0.6,0.3,1.2,0.7 c0.9,0.4,1.5,0.8,1.8,1.2S35,43.8,35,44.4z",
                      stroke: "none",
                      strokeLinecap: "square"
                    }), React.createElement("path", {
                      d: "M42.2,37.7h2L41.1,47h-2.1l-3.1-9.3h2l1.7,5.5c0.1,0.3,0.2,0.7,0.3,1.1 c0.1,0.4,0.2,0.7,0.2,0.9c0-0.4,0.2-1.1,0.5-2L42.2,37.7z",
                      stroke: "none",
                      strokeLinecap: "square"
                    }), React.createElement("polyline", {
                      fill: "none",
                      points: "40,2 40,18 56,18 ",
                      strokeLinecap: "butt"
                    }), React.createElement("polyline", {
                      fill: "none",
                      points: "56,32 56,18 40,2 8,2 8,32 "
                    }), React.createElement("polyline", {
                      fill: "none",
                      points: "8,53 8,62 56,62 56,53 "
                    }), React.createElement("line", {
                      fill: "none",
                      strokeLinecap: "butt",
                      x1: "61",
                      x2: "3",
                      y1: "32",
                      y2: "32"
                    }), React.createElement("polyline", {
                      fill: "none",
                      points: "8,27 3,32 3,53 61,53 61,32 56,27 ",
                      strokeLinecap: "butt"
                    })));
}

var make = IconCsv;

exports.make = make;
/* Css Not a pure module */
