// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Dopt = require("./externals/Dopt.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Process = require("./Process.bs.js");
var Schemas = require("./Schemas.bs.js");
var Welcome = require("./Welcome.bs.js");
var AvoState = require("./AvoState.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var NoAccess = require("./NoAccess.bs.js");
var Redirect = require("./Redirect.bs.js");
var AvoRoutes = require("./AvoRoutes.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var FetchModel = require("./FetchModel.bs.js");
var ModelStore = require("./ModelStore.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var RouterStore = require("./RouterStore.bs.js");
var CmdKProvider = require("./CmdKProvider.bs.js");
var App = require("firebase/app");
var ViewerContext = require("./ViewerContext.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var AppFeatureFlag = require("./AppFeatureFlag.bs.js");
var InspectorStats = require("./inspector/InspectorStats.bs.js");
var DrawerContainer = require("./DrawerContainer.bs.js");
var StateFilterUtils = require("./StateFilterUtils.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var LoadingFullscreen = require("./LoadingFullscreen.bs.js");
var DiscrepancyContext = require("./DiscrepancyContext.bs.js");
var GlobalStateContext = require("./GlobalStateContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var FetchImplementationStatus = require("./implementationStatus/FetchImplementationStatus.bs.js");

function LoggedIn$RoutesWithDrawer(Props) {
  var branchStatus = Props.branchStatus;
  var latestAction = Props.latestAction;
  var latestMasterAction = Props.latestMasterAction;
  var masterModel = Props.masterModel;
  var member = Props.member;
  var model = Props.model;
  var renderModel = Props.renderModel;
  var role = Props.role;
  var schema = Props.schema;
  var globalState = GlobalStateContext.use(undefined);
  var currentFilters = AnalyticsUtils.currentFilters(model, globalState.filters, globalState.eventSort);
  var filters = React.useMemo((function () {
          return Belt_List.fromArray(Belt_Array.keepMapU(globalState.filters, StateFilterUtils.toModel));
        }), [globalState.filters]);
  return React.createElement(React.Fragment, undefined, React.createElement(AvoRoutes.make, {
                  branchStatus: branchStatus,
                  latestAction: latestAction,
                  latestMasterAction: latestMasterAction,
                  masterModel: masterModel,
                  model: model,
                  renderModel: renderModel,
                  role: role,
                  schema: schema
                }), React.createElement(DrawerContainer.make, {
                  branchStatus: branchStatus,
                  currentFilters: currentFilters,
                  filters: filters,
                  masterModel: masterModel,
                  member: member,
                  model: model,
                  renderModel: renderModel,
                  role: role,
                  schema: schema
                }));
}

var RoutesWithDrawer = {
  make: LoggedIn$RoutesWithDrawer
};

function LoggedIn$RenderModel(Props) {
  var schema = Props.schema;
  var branch = Props.branch;
  var mappedModel = Props.mappedModel;
  var model = Props.model;
  var masterModel = Props.masterModel;
  var latestAction = Props.latestAction;
  var latestMasterAction = Props.latestMasterAction;
  var role = Props.role;
  var numberOfActions = Props.numberOfActions;
  var numberOfMasterActions = Props.numberOfMasterActions;
  var member = Props.member;
  var branchStatus = Props.branchStatus;
  var settingsQuery = Props.settingsQuery;
  var schemaId = Models.Schema.getId(schema);
  var currentBranchId = typeof branch === "object" ? branch.VAL : "master";
  var currentBranchName;
  if (typeof branch === "object") {
    var branchId = branch.VAL;
    currentBranchName = Belt_Option.mapWithDefault(Belt_List.getBy(masterModel.openBranches, (function (param) {
                return branchId === param[0];
              })), "N/A", (function (param) {
            return param[1];
          }));
  } else {
    currentBranchName = "main";
  }
  var updateModels = ModelStore.useUpdateModels(undefined);
  React.useEffect((function () {
          Curry._3(updateModels, model, mappedModel, Belt_Option.map(latestAction, Models.Action.getId));
          
        }), [model]);
  return React.createElement(DiscrepancyContext.make, {
              config: schema.validationConfig,
              model: model,
              children: React.createElement(FetchImplementationStatus.make, {
                    schema: schema,
                    model: model,
                    schemaId: schemaId,
                    branchId: currentBranchId,
                    children: React.createElement(InspectorStats.Context.make, {
                          children: React.createElement(SchemaGroupContext.make, {
                                currentBranchId: currentBranchId,
                                currentBranchName: currentBranchName,
                                children: React.createElement(CmdKProvider.make, {
                                      children: React.createElement(AppFeatureFlag.Provider.make, {
                                            schema: schema,
                                            children: React.createElement(AvoState.make, {
                                                  schema: schema,
                                                  model: model,
                                                  masterModel: masterModel,
                                                  latestAction: latestAction,
                                                  latestMasterAction: latestMasterAction,
                                                  numberOfActions: numberOfActions,
                                                  numberOfMasterActions: numberOfMasterActions,
                                                  role: role,
                                                  member: member,
                                                  branchStatus: branchStatus,
                                                  children: (function (renderModel) {
                                                      return React.createElement(LoggedIn$RoutesWithDrawer, {
                                                                  branchStatus: branchStatus,
                                                                  latestAction: latestAction,
                                                                  latestMasterAction: latestMasterAction,
                                                                  masterModel: masterModel,
                                                                  member: member,
                                                                  model: model,
                                                                  renderModel: renderModel,
                                                                  role: role,
                                                                  schema: schema
                                                                });
                                                    }),
                                                  settingsQuery: settingsQuery
                                                })
                                          })
                                    })
                              })
                        })
                  })
            });
}

var make = React.memo(LoggedIn$RenderModel, (function (props, nextProps) {
        if (Caml_obj.caml_equal(props.schema, nextProps.schema) && Caml_obj.caml_equal(props.branch, nextProps.branch) && Caml_obj.caml_equal(props.mappedModel, nextProps.mappedModel) && Caml_obj.caml_equal(props.model, nextProps.model) && Caml_obj.caml_equal(props.masterModel, nextProps.masterModel) && Caml_obj.caml_equal(props.latestAction, nextProps.latestAction) && Caml_obj.caml_equal(props.latestMasterAction, nextProps.latestMasterAction) && props.role === nextProps.role && props.numberOfActions === nextProps.numberOfActions && props.numberOfMasterActions === nextProps.numberOfMasterActions && Caml_obj.caml_equal(props.member, nextProps.member) && Caml_obj.caml_equal(props.branchStatus, nextProps.branchStatus)) {
          return Caml_obj.caml_equal(props.settingsQuery, nextProps.settingsQuery);
        } else {
          return false;
        }
      }));

var RenderModel = {
  make: make
};

function LoggedIn$Branch(Props) {
  var branch = Props.branch;
  var latestMasterAction = Props.latestMasterAction;
  var masterModel = Props.masterModel;
  var member = Props.member;
  var numberOfMasterActions = Props.numberOfMasterActions;
  var role = Props.role;
  var schema = Props.schema;
  var settingsQuery = Props.settingsQuery;
  var fetchModel = FetchModel.use(undefined, undefined, branch);
  var branchId = typeof branch === "object" ? branch.VAL : "master";
  switch (fetchModel.TAG | 0) {
    case /* Loading */0 :
        return React.createElement(LoadingFullscreen.make, {
                    message: fetchModel._0
                  });
    case /* Error */1 :
        return React.createElement(FetchModel.$$Error.make, {
                    message: fetchModel._0
                  });
    case /* Result */2 :
        return React.createElement(make, {
                    schema: schema,
                    branch: branch,
                    mappedModel: fetchModel._1,
                    model: fetchModel._0,
                    masterModel: masterModel,
                    latestAction: fetchModel._2,
                    latestMasterAction: latestMasterAction,
                    role: role,
                    numberOfActions: fetchModel._3,
                    numberOfMasterActions: numberOfMasterActions,
                    member: member,
                    branchStatus: fetchModel._4,
                    settingsQuery: settingsQuery,
                    key: "RenderModel-" + branchId
                  });
    
  }
}

var Branch = {
  make: LoggedIn$Branch
};

function LoggedIn$Schema(Props) {
  var role = Props.role;
  var member = Props.member;
  var user = Props.user;
  var schemaId = Props.schemaId;
  var branch = Props.branch;
  var settingsQuery = Props.settingsQuery;
  var fb = Firebase.app(undefined);
  var state = FirebaseFetcherHooks.useSchema(fb, schemaId, true);
  var fetchModel = FetchModel.use(undefined, undefined, "master");
  if (typeof state !== "object") {
    if (state === "NotFound") {
      return null;
    } else {
      return React.createElement(LoadingFullscreen.make, {
                  message: "Loading workspace..."
                });
    }
  }
  var schema = state.VAL;
  switch (fetchModel.TAG | 0) {
    case /* Loading */0 :
    case /* Error */1 :
        break;
    case /* Result */2 :
        var numberOfMasterActions = fetchModel._3;
        var latestMasterAction = fetchModel._2;
        var masterModel = fetchModel._0;
        return React.createElement(Dopt.CustomProvider.make, {
                    apiKey: Dopt.getBlocksApiKey(Process.getEnv(undefined)),
                    flowVersions: {
                      "test-flow": 0,
                      "demo-branch-product-tour": 5,
                      "demo-branch-warning": 3,
                      "inspector-issues-onboarding-flow": 7,
                      "alerts-beta-disclaimer": "latest",
                      "how-to-save-a-view-and-get-alerts": 1,
                      "ignore-on-branch-merge": 1,
                      "issues-flow-v2": 1,
                      "single-source-issues": 1
                    },
                    userId: user.id,
                    groupId: schemaId,
                    children: React.createElement(WorkspaceContext.make, {
                          schema: schema,
                          children: React.createElement(ViewerContext.make, {
                                user: user,
                                children: typeof branch === "object" ? React.createElement(LoggedIn$Branch, {
                                        branch: branch,
                                        latestMasterAction: latestMasterAction,
                                        masterModel: masterModel,
                                        member: member,
                                        numberOfMasterActions: numberOfMasterActions,
                                        role: role,
                                        schema: schema,
                                        settingsQuery: settingsQuery,
                                        key: "Branch-" + schemaId + "-" + branch.VAL
                                      }) : React.createElement(make, {
                                        schema: schema,
                                        branch: branch,
                                        mappedModel: fetchModel._1,
                                        model: masterModel,
                                        masterModel: masterModel,
                                        latestAction: latestMasterAction,
                                        latestMasterAction: latestMasterAction,
                                        role: role,
                                        numberOfActions: numberOfMasterActions,
                                        numberOfMasterActions: numberOfMasterActions,
                                        member: member,
                                        branchStatus: fetchModel._4,
                                        settingsQuery: settingsQuery,
                                        key: "RenderModel-" + schemaId + "-main"
                                      })
                              })
                        })
                  });
    
  }
  if (fetchModel.TAG === /* Loading */0) {
    return React.createElement(LoadingFullscreen.make, {
                message: fetchModel._0
              });
  } else {
    return React.createElement(FetchModel.$$Error.make, {
                message: fetchModel._0
              });
  }
}

var Schema = {
  make: LoggedIn$Schema
};

function isAvoAdmin(authUser) {
  if (authUser.email.endsWith("@avo.sh")) {
    return authUser.emailVerified;
  } else {
    return false;
  }
}

function LoggedIn$SchemaWrapperMainRedirect(Props) {
  var match = RouterStore.Schema.useSlices(function (state) {
        return [
                state.baseRoute,
                state.schemaRoute,
                state.drawerItems
              ];
      });
  return React.createElement(Redirect.make, {
              path: Curry._4(Router.Link.get, match[0], "master", match[1], match[2])
            });
}

var SchemaWrapperMainRedirect = {
  make: LoggedIn$SchemaWrapperMainRedirect
};

function LoggedIn$SchemaWrapper(Props) {
  var authState = Props.authState;
  var userId = Props.userId;
  var settingsQuery = Props.settingsQuery;
  var schemaId = RouterStore.Schema.useSchemaId(undefined);
  var branch = RouterStore.Schema.useBranch(undefined);
  var userState = FirebaseFetcherHooks.useUser(userId);
  var memberState = FirebaseFetcherHooks.useMember(schemaId, userId, true);
  var exit = 0;
  if (typeof branch === "object" && branch.NAME === "branch") {
    if (branch.VAL === "master") {
      return React.createElement(LoggedIn$SchemaWrapperMainRedirect, {});
    }
    exit = 1;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var exit$1 = 0;
    var exit$2 = 0;
    if (typeof memberState === "object") {
      if (typeof userState === "object") {
        if (userState.NAME === "Success") {
          var member = memberState.VAL;
          var role = Belt_Option.getWithDefault(Models.Role.tFromJs(member.role), "NoAccess");
          if (role === "NoAccess") {
            return React.createElement(NoAccess.make, {
                        schemaId: schemaId,
                        userId: userId
                      });
          } else {
            return React.createElement(LoggedIn$Schema, {
                        role: role,
                        member: member,
                        user: userState.VAL,
                        schemaId: schemaId,
                        branch: branch,
                        settingsQuery: settingsQuery,
                        key: "Schema-" + schemaId
                      });
          }
        }
        exit$2 = 3;
      } else {
        exit$2 = 3;
      }
    } else {
      if (memberState !== "NotFound") {
        return React.createElement(LoadingFullscreen.make, {
                    message: "Authenticating..."
                  });
      }
      if (typeof userState === "object" && userState.NAME === "Success") {
        if (typeof authState === "object" && authState.NAME === "Authenticated") {
          var authUser = authState.VAL;
          var user = userState.VAL;
          if (isAvoAdmin(authUser)) {
            var godMember = {
              id: authUser.uid,
              role: "Admin",
              type_: "user",
              createdAt: App.default.firestore.Timestamp.fromDate(new Date()),
              email: user.email
            };
            return React.createElement(LoggedIn$Schema, {
                        role: "Admin",
                        member: godMember,
                        user: user,
                        schemaId: schemaId,
                        branch: branch,
                        settingsQuery: settingsQuery,
                        key: "Schema-" + schemaId
                      });
          }
          exit$1 = 2;
        } else {
          exit$1 = 2;
        }
      } else {
        exit$2 = 3;
      }
    }
    if (exit$2 === 3) {
      if (userState === "Loading") {
        return React.createElement(LoadingFullscreen.make, {
                    message: "Authenticating..."
                  });
      }
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      return React.createElement(NoAccess.make, {
                  schemaId: schemaId,
                  userId: userId
                });
    }
    
  }
  
}

var SchemaWrapper = {
  make: LoggedIn$SchemaWrapper
};

function LoggedIn(Props) {
  var userId = Props.userId;
  var authState = Props.authState;
  var baseRoute = Props.baseRoute;
  var settingsQuery = Props.settingsQuery;
  if (typeof baseRoute === "object") {
    if (baseRoute.NAME === "schema") {
      return React.createElement(LoggedIn$SchemaWrapper, {
                  authState: authState,
                  userId: userId,
                  settingsQuery: settingsQuery
                });
    }
    
  } else {
    if (baseRoute === "welcome") {
      return React.createElement(Welcome.make, {
                  userId: userId
                });
    }
    if (baseRoute === "allSchemas") {
      return React.createElement(Schemas.make, {
                  userId: userId
                });
    }
    
  }
  return React.createElement(Redirect.make, {
              path: "/welcome"
            });
}

var make$1 = LoggedIn;

exports.RoutesWithDrawer = RoutesWithDrawer;
exports.RenderModel = RenderModel;
exports.Branch = Branch;
exports.Schema = Schema;
exports.isAvoAdmin = isAvoAdmin;
exports.SchemaWrapperMainRedirect = SchemaWrapperMainRedirect;
exports.SchemaWrapper = SchemaWrapper;
exports.make = make$1;
/* make Not a pure module */
