// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var IconGoogle = require("./IconGoogle.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function ContinueWithGoogleButton(Props) {
  var onClick = Props.onClick;
  var disabled = Props.disabled;
  var labelOpt = Props.label;
  var colorsOpt = Props.colors;
  var label = labelOpt !== undefined ? labelOpt : "Continue with Google";
  var colors = colorsOpt !== undefined ? colorsOpt : [
      Styles.Color.magenta,
      Styles.Color.magentaSecondary
    ];
  var primary = colors[0];
  var tmp = {
    className: Curry._1(Css.style, {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("spaceBetween"),
              tl: {
                hd: Css.width(Css.pct(100)),
                tl: {
                  hd: Css.margin2(Css.px(5), Css.px(0)),
                  tl: {
                    hd: Css.borderRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.backgroundColor(primary),
                        tl: {
                          hd: Css.color(Styles.Color.white),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.bold),
                            tl: {
                              hd: Css.outlineColor(primary),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$default
                                    }, undefined, undefined, "all"),
                                tl: {
                                  hd: Css.cursor("pointer"),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.backgroundColor(colors[1]),
                                          tl: /* [] */0
                                        }),
                                    tl: {
                                      hd: Css.padding2(Css.px(8), Css.px(15)),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.regular),
                                        tl: {
                                          hd: Css.height("auto"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        })
  };
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("button", tmp, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: {
                              hd: Css.borderRadius(Css.pct(100)),
                              tl: {
                                hd: Css.width(Css.px(24)),
                                tl: {
                                  hd: Css.height(Css.px(24)),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.white),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(IconGoogle.make, {
                      size: 18
                    })), label, React.createElement("div", undefined));
}

var make = ContinueWithGoogleButton;

exports.make = make;
/* Css Not a pure module */
