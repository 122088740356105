// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Title = require("../Title.bs.js");
var React = require("react");
var Models = require("../Models.bs.js");
var Router = require("../Router.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var DidMount = require("../DidMount.bs.js");
var IconImport = require("../IconImport.bs.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var CreateButtonIcon = require("./CreateButtonIcon.bs.js");
var EmptyStatesStyles = require("./EmptyStatesStyles.bs.js");

function EventsEmpty(Props) {
  var globalSend = Props.globalSend;
  var role = Props.role;
  var schemaGroup = Props.schemaGroup;
  var propertiesCount = Props.propertiesCount;
  return React.createElement("main", {
              className: EmptyStatesStyles.rootStyles
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(420)),
                        tl: /* [] */0
                      })
                }, React.createElement(Title.make, {
                      children: "Align your team behind industry analytics leading workflows",
                      size: "Medium"
                    })), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement($$Text.make, {
                  size: "Large",
                  color: Styles.Color.grey70,
                  maxWidth: Css.px(520),
                  children: null
                }, "Your Tracking Plan is the source of truth for what data you track, where you track it and how. To get started, import your existing tracking plan (we support most common formats) or create an event. Learn more in our ", React.createElement("a", {
                      className: EmptyStatesStyles.linkStyles,
                      href: "https://www.avo.app/docs/workflow/overview",
                      rel: "noopener",
                      target: "_blank"
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "docs"
                        })), "."), Models.Role.canEdit(role) ? React.createElement("div", {
                    className: EmptyStatesStyles.actionsStyles,
                    id: "empty-events-actions"
                  }, React.createElement("button", {
                        className: EmptyStatesStyles.actionStyles,
                        onClick: (function (param) {
                            AnalyticsRe.emptyStateInteracted(schemaGroup, "Events", "Setup", undefined);
                            return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                        NAME: "import",
                                        VAL: "index"
                                      });
                          })
                      }, React.createElement("div", {
                            className: EmptyStatesStyles.iconStyles
                          }, React.createElement(IconImport.make, {
                                color: Styles.Color.grey50,
                                size: 60
                              })), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.grey80,
                            children: "Import Existing Tracking Plan"
                          })), React.createElement(DidMount.make, {
                        didMount: (function (param) {
                            return AnalyticsRe.importCallToActionSeen(schemaGroup, 0, propertiesCount, schemaGroup.branchId, schemaGroup.schemaId);
                          })
                      }), React.createElement("button", {
                        className: EmptyStatesStyles.actionStyles,
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "NewEvent",
                                    VAL: [
                                      "",
                                      (function (eventId, _name) {
                                          return Router.Schema.pushDrawerItem(undefined, {
                                                      NAME: "event",
                                                      VAL: [
                                                        eventId,
                                                        undefined,
                                                        undefined,
                                                        false
                                                      ]
                                                    });
                                        }),
                                      "EventsView"
                                    ]
                                  }
                                });
                            return AnalyticsRe.emptyStateInteracted(schemaGroup, "Events", "Setup", undefined);
                          })
                      }, React.createElement("div", {
                            className: EmptyStatesStyles.iconStyles
                          }, CreateButtonIcon.icon), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.grey80,
                            children: "Create Your First Event"
                          }))) : null);
}

var make = EventsEmpty;

exports.make = make;
/* Css Not a pure module */
