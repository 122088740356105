// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Title = require("./Title.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Invite = require("./Invite.bs.js");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var IconAvo = require("./IconAvo.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var UserFlag = require("./UserFlag.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var FetchModel = require("./FetchModel.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var FirebaseUtils = require("./firebaseUtils.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var LoadingFullscreen = require("./LoadingFullscreen.bs.js");
var SchemaMemberHeads = require("./SchemaMemberHeads.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

var rootStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.alignItems("stretch"),
                  tl: {
                    hd: Css.padding(Css.px(0)),
                    tl: {
                      hd: Css.margin(Css.px(0)),
                      tl: {
                        hd: Css.marginBottom(Css.px(10)),
                        tl: {
                          hd: Css.marginRight(Css.px(10)),
                          tl: {
                            hd: Css.width(Css.px(250)),
                            tl: {
                              hd: Css.height(Css.px(150)),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.white),
                                  tl: {
                                    hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.transition({
                                              NAME: "ms",
                                              VAL: 200.0
                                            }, undefined, undefined, "box-shadow"),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(8), Css.px(24), Css.px(0), undefined, Css.rgba(0, 0, 0, {
                                                              NAME: "num",
                                                              VAL: 0.08
                                                            }))),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexGrow(1.0),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.padding(Css.px(8)),
              tl: {
                hd: Css.paddingBottom(Css.px(4)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var footerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey20),
              tl: {
                hd: Css.padding(Css.px(8)),
                tl: {
                  hd: Css.minHeight(Css.px(41)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function Schemas$Workspace(Props) {
  var schemaId = Props.schemaId;
  var fb = Firebase.app(undefined);
  var schemaState = FirebaseFetcherHooks.useSchema(fb, schemaId, false);
  if (typeof schemaState !== "object") {
    return null;
  }
  var schema = schemaState.VAL;
  var createdAt = Models.toDateOption(schema.createdAt);
  return React.createElement("button", {
              className: rootStyles,
              onClick: (function (param) {
                  return Router.push(undefined, Router.Link.getBaseRoute(undefined, {
                                  NAME: "schema",
                                  VAL: schemaId
                                }));
                })
            }, React.createElement("div", {
                  className: headerStyles
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      textAlign: "center",
                      color: Styles.Color.grey80,
                      children: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(schema.name), "Untitled")
                    })), React.createElement("div", {
                  className: footerStyles
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Medium",
                      color: Styles.Color.grey40,
                      children: createdAt !== undefined ? Caml_option.valFromOption(createdAt).toDateString() : null
                    }), React.createElement(SchemaMemberHeads.make, {
                      schemaId: schemaId,
                      limit: 3
                    })));
}

var Workspace = {
  rootStyles: rootStyles,
  headerStyles: headerStyles,
  footerStyles: footerStyles,
  make: Schemas$Workspace
};

function Schemas(Props) {
  var userId = Props.userId;
  var auth = Firebase.getAuth(undefined);
  var currentUser = Belt_Option.getExn(Caml_option.nullable_to_opt(auth.currentUser));
  var userSchemasState = FirebaseFetcherHooks.useUserSchemas(userId);
  var userSchemaInvitesState = FirebaseFetcherHooks.useUserSchemaInvites(currentUser.email);
  var canCreateAdditionalWorkspaces = UserFlag.flagEnabledForUserId(userId, "CanCreateAdditionalWorkspaces");
  if (typeof userSchemasState !== "object") {
    if (userSchemasState === "Initial") {
      return React.createElement(LoadingFullscreen.make, {
                  message: "Loading your schemas..."
                });
    } else {
      return React.createElement(FetchModel.$$Error.make, {
                  message: "Could not fetch workspaces"
                });
    }
  }
  var userSchemas = userSchemasState.VAL;
  var tmp;
  if (typeof userSchemaInvitesState === "object") {
    var invites = userSchemaInvitesState.VAL;
    tmp = Belt_List.length(invites) > 0 ? React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.margin2(Css.px(50), Css.px(0)),
                  tl: /* [] */0
                })
          }, React.createElement(Title.make, {
                children: "Invites",
                size: "Medium"
              }), React.createElement(Spacer.make, {
                height: 20
              }), React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexWrap("wrap"),
                        tl: /* [] */0
                      }
                    })
              }, Belt_List.toArray(Belt_List.map(invites, (function (invite) {
                          return React.createElement(Invite.make, {
                                      invite: invite,
                                      userId: userId,
                                      key: invite.id
                                    });
                        }))))) : null;
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.px(900)),
                    tl: {
                      hd: Css.maxWidth(Css.vw(100)),
                      tl: {
                        hd: Css.padding(Css.px(50)),
                        tl: {
                          hd: Css.marginLeft("auto"),
                          tl: {
                            hd: Css.marginRight("auto"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("spaceBetween"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(IconAvo.make, {
                      size: 100
                    }), React.createElement(Spacer.make, {
                      width: 20,
                      grow: 1.0
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.grey70,
                      children: "Welcome " + currentUser.email + "!"
                    }), React.createElement(Spacer.make, {
                      width: 20
                    }), React.createElement(Button.make, {
                      label: "Log out",
                      onClick: (function (param) {
                          return FirebaseUtils.signOut(auth);
                        }),
                      style: "outline"
                    })), tmp, Belt_List.length(userSchemas) > 0 ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.margin2(Css.px(50), Css.px(0)),
                          tl: /* [] */0
                        })
                  }, React.createElement(Title.make, {
                        children: "Your Workspaces",
                        size: "Medium"
                      }), React.createElement(Spacer.make, {
                        height: 20
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.flexWrap("wrap"),
                                tl: /* [] */0
                              }
                            })
                      }, Belt_List.toArray(Belt_List.map(userSchemas, (function (schema) {
                                  return React.createElement(Schemas$Workspace, {
                                              schemaId: schema.id,
                                              key: schema.id
                                            });
                                }))))) : null, canCreateAdditionalWorkspaces || currentUser.emailVerified && currentUser.email.indexOf("@avo.sh") > 0 ? React.createElement(Button.make, {
                    icon: "plus",
                    label: "New Workspace",
                    onClick: (function (param) {
                        FirebaseUtils.createSchema(currentUser.uid).then(function (schema) {
                              return Promise.resolve(Router.push(undefined, Curry._4(Router.Link.get, {
                                                  NAME: "schema",
                                                  VAL: schema.id
                                                }, "master", "dashboard", /* [] */0)));
                            });
                        
                      }),
                    size: "large"
                  }) : null);
}

var make = Schemas;

exports.Workspace = Workspace;
exports.make = make;
/* rootStyles Not a pure module */
