// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Tag = require("./Tag.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var StateUtils = require("./stateUtils.bs.js");
var Fuzzaldrin = require("fuzzaldrin");
var AutofillInput = require("./AutofillInput.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.flexWrap("wrap"),
          tl: /* [] */0
        }
      }
    });

var TagsAutofillInput = AutofillInput.Component({});

function TagsSelect(Props) {
  var events = Props.events;
  var selectedTags = Props.selectedTags;
  var onSelect = Props.onSelect;
  var onRemove = Props.onRemove;
  var tags = Belt_List.keepU(StateUtils.getAllTags(events), (function (tag) {
          return !Belt_List.has(selectedTags, tag, (function (a, b) {
                        return a === b;
                      }));
        }));
  return React.createElement("div", {
              className: rootStyles
            }, Belt_List.toArray(Belt_List.mapU(selectedTags, (function (tag) {
                        return React.createElement(Tag.make, {
                                    tag: tag,
                                    onRemove: (function (param) {
                                        return Curry._1(onRemove, tag);
                                      })
                                  });
                      }))), tags === /* [] */0 ? null : React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.flexShrink(1.0),
                          tl: /* [] */0
                        })
                  }, React.createElement(TagsAutofillInput.make, {
                        onSelect: (function (tag, param, param$1) {
                            return Curry._1(onSelect, tag);
                          }),
                        onCreate: (function (param) {
                            
                          }),
                        placeholder: "Enter tag...",
                        search: (function (query) {
                            var results = Belt_List.map(Belt_List.fromArray(Fuzzaldrin.filter(Belt_List.toArray(tags), query, {
                                          maxResults: 5
                                        })), (function (tag) {
                                    return {
                                            NAME: "Item",
                                            VAL: tag
                                          };
                                  }));
                            var tagAlreadyAdded = Belt_List.hasU(selectedTags, query, (function (a, b) {
                                    return a === b;
                                  }));
                            if (tagAlreadyAdded) {
                              return Belt_List.concat(results, {
                                          hd: {
                                            NAME: "Message",
                                            VAL: query + " already added"
                                          },
                                          tl: /* [] */0
                                        });
                            } else {
                              return results;
                            }
                          }),
                        renderItem: (function (item, isActive) {
                            var variant = item.NAME;
                            return React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.padding(Css.px(10)),
                                              tl: {
                                                hd: Css.backgroundColor(isActive ? Styles.Color.blueBg : Styles.Color.white),
                                                tl: {
                                                  hd: Css.hover({
                                                        hd: Css.backgroundColor(Styles.Color.blueBg),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, variant === "CreateNew" ? null : (
                                          variant === "Item" ? React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.display("inlineBlock"),
                                                        tl: /* [] */0
                                                      })
                                                }, React.createElement(Tag.make, {
                                                      tag: item.VAL
                                                    })) : React.createElement("div", undefined, item.VAL)
                                        ));
                          }),
                        refreshKey: tags
                      })));
}

var make = TagsSelect;

exports.rootStyles = rootStyles;
exports.TagsAutofillInput = TagsAutofillInput;
exports.make = make;
/* rootStyles Not a pure module */
