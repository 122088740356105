// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core = require("@mantine/core");
var Hooks = require("@mantine/hooks");
var Css_AtomicTypes = require("bs-css/src/Css_AtomicTypes.bs.js");
var Js_null_undefined = require("rescript/lib/js/js_null_undefined.js");

var Types = {};

function unwrapSizeNum(sn) {
  if (typeof sn === "object" && sn.NAME === "num") {
    return sn.VAL;
  } else {
    return sn;
  }
}

function unwrapReTransition(transition) {
  if (typeof transition !== "object") {
    return transition;
  }
  if (transition.NAME !== "custom") {
    return transition;
  }
  var match = transition.VAL;
  return {
          in: Css.toJson(match.in),
          out: Css.toJson(match.out),
          common: match.common,
          transitionProperty: match.transitionProperty
        };
}

function unwrapReTransitionProps(transitionProps) {
  var transition = transitionProps.transition;
  var duration = transitionProps.duration;
  var timingFunction = transitionProps.timingFunction;
  var tmp = {};
  var tmp$1 = Belt_Option.map(transition === undefined ? undefined : Caml_option.some(transition), unwrapReTransition);
  if (tmp$1 !== undefined) {
    tmp.transition = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = duration === undefined ? undefined : Caml_option.some(duration);
  if (tmp$2 !== undefined) {
    tmp.duration = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = timingFunction === undefined ? undefined : Caml_option.some(timingFunction);
  if (tmp$3 !== undefined) {
    tmp.timingFunction = Caml_option.valFromOption(tmp$3);
  }
  return tmp;
}

function generateStableColor(color) {
  return Belt_Array.makeBy(10, (function (param) {
                return Styles.Color.toString(color);
              }));
}

function getTheme(param) {
  return {
          colors: {
            magenta100: generateStableColor(Styles.Color.magenta100)
          }
        };
}

var Utils = {
  unwrapSizeNum: unwrapSizeNum,
  unwrapReTransition: unwrapReTransition,
  unwrapReTransitionProps: unwrapReTransitionProps,
  generateStableColor: generateStableColor,
  getTheme: getTheme
};

var Provider = {};

var Internal = {};

function Mantine$Menu(Props) {
  var closeOnItemClick = Props.closeOnItemClick;
  var defaultOpened = Props.defaultOpened;
  var onChange = Props.onChange;
  var onClose = Props.onClose;
  var onOpen = Props.onOpen;
  var opened = Props.opened;
  var position = Props.position;
  var withinPortalOpt = Props.withinPortal;
  var zIndexOpt = Props.zIndex;
  var children = Props.children;
  var withinPortal = withinPortalOpt !== undefined ? withinPortalOpt : true;
  var zIndex = zIndexOpt !== undefined ? zIndexOpt : Styles.ZIndex.aboveAll;
  var tmp = {
    withinPortal: withinPortal,
    zIndex: zIndex,
    children: children
  };
  if (closeOnItemClick !== undefined) {
    tmp.closeOnItemClick = closeOnItemClick;
  }
  if (defaultOpened !== undefined) {
    tmp.defaultOpened = defaultOpened;
  }
  if (onChange !== undefined) {
    tmp.onChange = Caml_option.valFromOption(onChange);
  }
  if (onClose !== undefined) {
    tmp.onClose = Caml_option.valFromOption(onClose);
  }
  if (onOpen !== undefined) {
    tmp.onOpen = Caml_option.valFromOption(onOpen);
  }
  if (opened !== undefined) {
    tmp.opened = opened;
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  return React.createElement(Core.Menu, tmp);
}

var Target = {};

var Internal$1 = {};

var paddingReset_0 = Css.padding("zero");

var paddingReset = {
  hd: paddingReset_0,
  tl: /* [] */0
};

function getSx(additionalStyles) {
  return Belt_Option.mapWithDefault(additionalStyles, Css.toJson(paddingReset), (function (styles) {
                return Css.toJson(Belt_List.concat(paddingReset, styles));
              }));
}

function Mantine$Menu$Dropdown(Props) {
  var additionalStyles = Props.additionalStyles;
  var children = Props.children;
  return React.createElement(Core.Menu.Dropdown, {
              children: children,
              sx: getSx(additionalStyles)
            });
}

var Dropdown = {
  Internal: Internal$1,
  paddingReset: paddingReset,
  getSx: getSx,
  make: Mantine$Menu$Dropdown
};

var Internal$2 = {};

var resetStyles_0 = Css.padding("zero");

var resetStyles = {
  hd: resetStyles_0,
  tl: /* [] */0
};

function getSx$1(additionalStyles) {
  return Belt_Option.mapWithDefault(additionalStyles, Css.toJson(resetStyles), (function (styles) {
                return Css.toJson(Belt_List.concat(resetStyles, styles));
              }));
}

function Mantine$Menu$Item(Props) {
  var closeMenuOnClickOpt = Props.closeMenuOnClick;
  var icon = Props.icon;
  var rightSection = Props.rightSection;
  var additionalStyles = Props.additionalStyles;
  var onClick = Props.onClick;
  var disabled = Props.disabled;
  var children = Props.children;
  var closeMenuOnClick = closeMenuOnClickOpt !== undefined ? closeMenuOnClickOpt : true;
  var tmp = {
    closeMenuOnClick: closeMenuOnClick,
    sx: getSx$1(additionalStyles),
    children: children
  };
  if (icon !== undefined) {
    tmp.icon = Caml_option.valFromOption(icon);
  }
  if (rightSection !== undefined) {
    tmp.rightSection = Caml_option.valFromOption(rightSection);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (disabled !== undefined) {
    tmp.disabled = disabled;
  }
  return React.createElement(Core.Menu.Item, tmp);
}

var Item = {
  Internal: Internal$2,
  resetStyles: resetStyles,
  getSx: getSx$1,
  make: Mantine$Menu$Item
};

var Internal$3 = {};

function Mantine$Menu$Divider(Props) {
  var additionalStyles = Props.additionalStyles;
  return React.createElement(Core.Menu.Divider, {
              sx: Css.toJson(additionalStyles)
            });
}

var Divider = {
  Internal: Internal$3,
  make: Mantine$Menu$Divider
};

var Label = {};

var Menu = {
  Internal: Internal,
  make: Mantine$Menu,
  Target: Target,
  Dropdown: Dropdown,
  Item: Item,
  Divider: Divider,
  Label: Label
};

var Internal$4 = {};

function Mantine$Popover(Props) {
  var arrowSize = Props.arrowSize;
  var arrowOffset = Props.arrowOffset;
  var arrowStylesOpt = Props.arrowStyles;
  var closeOnClickOutside = Props.closeOnClickOutside;
  var dropdownStylesOpt = Props.dropdownStyles;
  var forwardRef = Props.forwardRef;
  var offset = Props.offset;
  var onChange = Props.onChange;
  var opened = Props.opened;
  var position = Props.position;
  var positionDependencies = Props.positionDependencies;
  var transition = Props.transition;
  var transitionDuration = Props.transitionDuration;
  var transitionTimingFunction = Props.transitionTimingFunction;
  var withinPortalOpt = Props.withinPortal;
  var withArrowOpt = Props.withArrow;
  var zIndexOpt = Props.zIndex;
  var children = Props.children;
  var arrowStyles = arrowStylesOpt !== undefined ? arrowStylesOpt : /* [] */0;
  var dropdownStyles = dropdownStylesOpt !== undefined ? dropdownStylesOpt : /* [] */0;
  var withinPortal = withinPortalOpt !== undefined ? withinPortalOpt : true;
  var withArrow = withArrowOpt !== undefined ? withArrowOpt : false;
  var zIndex = zIndexOpt !== undefined ? zIndexOpt : Styles.ZIndex.aboveAll;
  var tmp = {};
  if (transition !== undefined) {
    tmp.transition = Caml_option.valFromOption(transition);
  }
  if (transitionDuration !== undefined) {
    tmp.duration = Caml_option.valFromOption(transitionDuration);
  }
  if (transitionTimingFunction !== undefined) {
    tmp.timingFunction = Caml_option.valFromOption(transitionTimingFunction);
  }
  var tmp$1 = {
    styles: {
      dropdown: Css.toJson(dropdownStyles),
      arrow: Css.toJson(arrowStyles)
    },
    transitionProps: unwrapReTransitionProps(tmp),
    withinPortal: withinPortal,
    withArrow: withArrow,
    zIndex: zIndex,
    children: children
  };
  if (arrowSize !== undefined) {
    tmp$1.arrowSize = arrowSize;
  }
  if (arrowOffset !== undefined) {
    tmp$1.arrowOffset = arrowOffset;
  }
  if (closeOnClickOutside !== undefined) {
    tmp$1.closeOnClickOutside = closeOnClickOutside;
  }
  if (offset !== undefined) {
    tmp$1.offset = offset;
  }
  if (onChange !== undefined) {
    tmp$1.onChange = Caml_option.valFromOption(onChange);
  }
  if (opened !== undefined) {
    tmp$1.opened = opened;
  }
  if (position !== undefined) {
    tmp$1.position = Caml_option.valFromOption(position);
  }
  if (positionDependencies !== undefined) {
    tmp$1.positionDependencies = Caml_option.valFromOption(positionDependencies);
  }
  if (forwardRef !== undefined) {
    tmp$1.ref = Caml_option.valFromOption(forwardRef);
  }
  return React.createElement(Core.Popover, tmp$1);
}

var Target$1 = {};

var Internal$5 = {};

var paddingReset_0$1 = Css.padding("zero");

var paddingReset_1 = {
  hd: Css.borderWidth("zero"),
  tl: /* [] */0
};

var paddingReset$1 = {
  hd: paddingReset_0$1,
  tl: paddingReset_1
};

function getSx$2(additionalStyles) {
  return Belt_Option.mapWithDefault(additionalStyles, Css.toJson(paddingReset$1), (function (styles) {
                return Css.toJson(Belt_List.concat(paddingReset$1, styles));
              }));
}

function Mantine$Popover$Dropdown(Props) {
  var additionalStyles = Props.additionalStyles;
  var children = Props.children;
  return React.createElement(Core.Popover.Dropdown, {
              children: children,
              sx: getSx$2(additionalStyles)
            });
}

var Dropdown$1 = {
  Internal: Internal$5,
  paddingReset: paddingReset$1,
  getSx: getSx$2,
  make: Mantine$Popover$Dropdown
};

var Popover = {
  Internal: Internal$4,
  make: Mantine$Popover,
  Target: Target$1,
  Dropdown: Dropdown$1
};

var Internal$6 = {};

function collectKebabProps(props) {
  return {
          ariaSelected: Caml_option.nullable_to_opt(props["aria-selected"]),
          dataHovered: Caml_option.nullable_to_opt(props["data-hovered"]),
          dataSelected: Caml_option.nullable_to_opt(props["data-selected"]),
          dataDisabled: Caml_option.nullable_to_opt(props["data-disabled"])
        };
}

function Mantine$Select(Props) {
  var clearable = Props.clearable;
  var data = Props.data;
  var defaultValue = Props.defaultValue;
  var disabled = Props.disabled;
  var dropdownStylesOpt = Props.dropdownStyles;
  var forwardRef = Props.forwardRef;
  var icon = Props.icon;
  var inputStylesOpt = Props.inputStyles;
  var itemComponent = Props.itemComponent;
  var itemStylesOpt = Props.itemStyles;
  var itemSelectedStylesOpt = Props.itemSelectedStyles;
  var itemsWrapperStylesOpt = Props.itemsWrapperStyles;
  var maxDropdownHeight = Props.maxDropdownHeight;
  var nothingFound = Props.nothingFound;
  var onChange = Props.onChange;
  var onDropdownClose = Props.onDropdownClose;
  var onDropdownOpen = Props.onDropdownOpen;
  var placeholder = Props.placeholder;
  var rightSection = Props.rightSection;
  var rightSectionPropsOpt = Props.rightSectionProps;
  var rightSectionWidth = Props.rightSectionWidth;
  var searchable = Props.searchable;
  var separatorStylesOpt = Props.separatorStyles;
  var separatorLabelStylesOpt = Props.separatorLabelStyles;
  var shadow = Props.shadow;
  var size = Props.size;
  var transition = Props.transition;
  var transitionDurationOpt = Props.transitionDuration;
  var transitionTimingFunction = Props.transitionTimingFunction;
  var value = Props.value;
  var withinPortal = Props.withinPortal;
  var wrapperStylesOpt = Props.wrapperStyles;
  var zIndex = Props.zIndex;
  var dropdownStyles = dropdownStylesOpt !== undefined ? dropdownStylesOpt : /* [] */0;
  var inputStyles = inputStylesOpt !== undefined ? inputStylesOpt : /* [] */0;
  var itemStyles = itemStylesOpt !== undefined ? itemStylesOpt : /* [] */0;
  var itemSelectedStyles = itemSelectedStylesOpt !== undefined ? itemSelectedStylesOpt : /* [] */0;
  var itemsWrapperStyles = itemsWrapperStylesOpt !== undefined ? itemsWrapperStylesOpt : /* [] */0;
  var rightSectionProps = rightSectionPropsOpt !== undefined ? Caml_option.valFromOption(rightSectionPropsOpt) : ({
        style: Css.toJson({
              hd: Css.pointerEvents("none"),
              tl: /* [] */0
            })
      });
  var separatorStyles = separatorStylesOpt !== undefined ? separatorStylesOpt : /* [] */0;
  var separatorLabelStyles = separatorLabelStylesOpt !== undefined ? separatorLabelStylesOpt : /* [] */0;
  var transitionDuration = transitionDurationOpt !== undefined ? transitionDurationOpt : 50;
  var wrapperStyles = wrapperStylesOpt !== undefined ? wrapperStylesOpt : /* [] */0;
  var styles = {
    input: Css.toJson(inputStyles),
    dropdown: Css.toJson(dropdownStyles),
    itemsWrapper: Css.toJson(itemsWrapperStyles),
    item: Css.toJson(Belt_List.flatten({
              hd: itemStyles,
              tl: {
                hd: {
                  hd: Css.selector("&[data-selected]", itemSelectedStyles),
                  tl: /* [] */0
                },
                tl: /* [] */0
              }
            })),
    separatorLabel: Css.toJson(separatorLabelStyles),
    separator: Css.toJson(separatorStyles),
    wrapper: Css.toJson(wrapperStyles)
  };
  var tmp = {
    duration: transitionDuration
  };
  if (transition !== undefined) {
    tmp.transition = Caml_option.valFromOption(transition);
  }
  if (transitionTimingFunction !== undefined) {
    tmp.timingFunction = Caml_option.valFromOption(transitionTimingFunction);
  }
  var tmp$1 = {
    data: data,
    onChange: (function (nullableValue) {
        return Belt_Option.forEach(onChange, (function (onChange) {
                      return Curry._1(onChange, (nullableValue == null) ? undefined : Caml_option.some(nullableValue));
                    }));
      }),
    rightSectionProps: rightSectionProps,
    styles: styles,
    transitionProps: unwrapReTransitionProps(tmp)
  };
  if (clearable !== undefined) {
    tmp$1.clearable = clearable;
  }
  if (defaultValue !== undefined) {
    tmp$1.defaultValue = Caml_option.valFromOption(defaultValue);
  }
  if (disabled !== undefined) {
    tmp$1.disabled = disabled;
  }
  if (icon !== undefined) {
    tmp$1.icon = Caml_option.valFromOption(icon);
  }
  if (itemComponent !== undefined) {
    tmp$1.itemComponent = Caml_option.valFromOption(itemComponent);
  }
  if (maxDropdownHeight !== undefined) {
    tmp$1.maxDropdownHeight = maxDropdownHeight;
  }
  if (nothingFound !== undefined) {
    tmp$1.nothingFound = nothingFound;
  }
  if (onDropdownClose !== undefined) {
    tmp$1.onDropdownClose = Caml_option.valFromOption(onDropdownClose);
  }
  if (onDropdownOpen !== undefined) {
    tmp$1.onDropdownOpen = Caml_option.valFromOption(onDropdownOpen);
  }
  if (placeholder !== undefined) {
    tmp$1.placeholder = placeholder;
  }
  if (rightSection !== undefined) {
    tmp$1.rightSection = Caml_option.valFromOption(rightSection);
  }
  if (rightSectionWidth !== undefined) {
    tmp$1.rightSectionWidth = Caml_option.valFromOption(rightSectionWidth);
  }
  if (searchable !== undefined) {
    tmp$1.searchable = searchable;
  }
  if (shadow !== undefined) {
    tmp$1.shadow = Caml_option.valFromOption(shadow);
  }
  if (size !== undefined) {
    tmp$1.size = Caml_option.valFromOption(size);
  }
  if (value !== undefined) {
    tmp$1.value = Caml_option.valFromOption(value);
  }
  if (withinPortal !== undefined) {
    tmp$1.withinPortal = withinPortal;
  }
  if (zIndex !== undefined) {
    tmp$1.zIndex = zIndex;
  }
  if (forwardRef !== undefined) {
    tmp$1.ref = Caml_option.valFromOption(forwardRef);
  }
  return React.createElement(Core.Select, tmp$1);
}

function Item$1(ItemComponent) {
  var make = React.forwardRef(function (Props, ref_) {
        var className = Props.className;
        var description = Props.description;
        var disabled = Props.disabled;
        var group = Props.group;
        var icon = Props.icon;
        var id = Props.id;
        var label = Props.label;
        var onMouseDown = Props.onMouseDown;
        var onMouseEnter = Props.onMouseEnter;
        var role = Props.role;
        var tabIndex = Props.tabIndex;
        var value = Props.value;
        var match = collectKebabProps(Props);
        var tmp = {
          label: label,
          value: value
        };
        if (description !== undefined) {
          tmp.description = Caml_option.valFromOption(description);
        }
        if (icon !== undefined) {
          tmp.icon = Caml_option.valFromOption(icon);
        }
        if (group !== undefined) {
          tmp.group = Caml_option.valFromOption(group);
        }
        if (disabled !== undefined) {
          tmp.disabled = Caml_option.valFromOption(disabled);
        }
        var item = tmp;
        var tmp$1 = {};
        var tmp$2 = Belt_Option.map((ref_ == null) ? undefined : Caml_option.some(ref_), (function (prim) {
                return prim;
              }));
        if (tmp$2 !== undefined) {
          tmp$1.ref = Caml_option.valFromOption(tmp$2);
        }
        if (match.ariaSelected !== undefined) {
          tmp$1["aria-selected"] = Caml_option.valFromOption(match.ariaSelected);
        }
        if (className !== undefined) {
          tmp$1.className = Caml_option.valFromOption(className);
        }
        if (id !== undefined) {
          tmp$1.id = Caml_option.valFromOption(id);
        }
        if (role !== undefined) {
          tmp$1.role = Caml_option.valFromOption(role);
        }
        if (tabIndex !== undefined) {
          tmp$1.tabIndex = Caml_option.valFromOption(tabIndex);
        }
        if (disabled !== undefined) {
          tmp$1.disabled = Caml_option.valFromOption(disabled);
        }
        if (onMouseDown !== undefined) {
          tmp$1.onMouseDown = Caml_option.valFromOption(onMouseDown);
        }
        if (onMouseEnter !== undefined) {
          tmp$1.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
        }
        return React.cloneElement(React.createElement("div", tmp$1, React.createElement(ItemComponent.make, {
                            item: item
                          })), {
                    "data-disabled": match.dataDisabled,
                    "data-hovered": match.dataHovered,
                    "data-selected": match.dataSelected
                  });
      });
  return {
          make: make
        };
}

var Select = {
  Internal: Internal$6,
  make: Mantine$Select,
  Item: Item$1
};

var Internal$7 = {};

function Mantine$MultiSelect(Props) {
  var clearable = Props.clearable;
  var clearButtonProps = Props.clearButtonProps;
  var data = Props.data;
  var dropdownStylesOpt = Props.dropdownStyles;
  var inputStylesOpt = Props.inputStyles;
  var itemComponent = Props.itemComponent;
  var itemStylesOpt = Props.itemStyles;
  var itemsWrapperStylesOpt = Props.itemsWrapperStyles;
  var label = Props.label;
  var onChange = Props.onChange;
  var placeholder = Props.placeholder;
  var rightSection = Props.rightSection;
  var rightSectionPropsOpt = Props.rightSectionProps;
  var rightSectionWidth = Props.rightSectionWidth;
  var searchInputStylesOpt = Props.searchInputStyles;
  var value = Props.value;
  var valueComponent = Props.valueComponent;
  var valuesStylesOpt = Props.valuesStyles;
  var wrapperStylesOpt = Props.wrapperStyles;
  var dropdownStyles = dropdownStylesOpt !== undefined ? dropdownStylesOpt : /* [] */0;
  var inputStyles = inputStylesOpt !== undefined ? inputStylesOpt : /* [] */0;
  var itemStyles = itemStylesOpt !== undefined ? itemStylesOpt : /* [] */0;
  var itemsWrapperStyles = itemsWrapperStylesOpt !== undefined ? itemsWrapperStylesOpt : /* [] */0;
  var rightSectionProps = rightSectionPropsOpt !== undefined ? Caml_option.valFromOption(rightSectionPropsOpt) : ({
        style: Css.toJson({
              hd: Css.pointerEvents("none"),
              tl: /* [] */0
            })
      });
  var searchInputStyles = searchInputStylesOpt !== undefined ? searchInputStylesOpt : /* [] */0;
  var valuesStyles = valuesStylesOpt !== undefined ? valuesStylesOpt : /* [] */0;
  var wrapperStyles = wrapperStylesOpt !== undefined ? wrapperStylesOpt : /* [] */0;
  var styles = {
    input: Css.toJson(inputStyles),
    searchInput: Css.toJson(searchInputStyles),
    values: Css.toJson(valuesStyles),
    item: Css.toJson(itemStyles),
    dropdown: Css.toJson(dropdownStyles),
    itemsWrapper: Css.toJson(itemsWrapperStyles),
    wrapper: Css.toJson(wrapperStyles)
  };
  var tmp = {
    data: data,
    rightSectionProps: rightSectionProps,
    styles: styles
  };
  if (clearable !== undefined) {
    tmp.clearable = clearable;
  }
  if (clearButtonProps !== undefined) {
    tmp.clearButtonProps = Caml_option.valFromOption(clearButtonProps);
  }
  if (itemComponent !== undefined) {
    tmp.itemComponent = Caml_option.valFromOption(itemComponent);
  }
  if (label !== undefined) {
    tmp.label = label;
  }
  if (onChange !== undefined) {
    tmp.onChange = Caml_option.valFromOption(onChange);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = placeholder;
  }
  if (rightSection !== undefined) {
    tmp.rightSection = Caml_option.valFromOption(rightSection);
  }
  if (rightSectionWidth !== undefined) {
    tmp.rightSectionWidth = Caml_option.valFromOption(rightSectionWidth);
  }
  if (value !== undefined) {
    tmp.value = Caml_option.valFromOption(value);
  }
  if (valueComponent !== undefined) {
    tmp.valueComponent = Caml_option.valFromOption(valueComponent);
  }
  return React.createElement(Core.MultiSelect, tmp);
}

var MultiSelect = {
  Internal: Internal$7,
  make: Mantine$MultiSelect
};

var Internal$8 = {};

function Mantine$RingProgress(Props) {
  var label = Props.label;
  var rootColor = Props.rootColor;
  var sections = Props.sections;
  var size = Props.size;
  var thickness = Props.thickness;
  var tmp = {
    sections: Belt_Array.map(sections, (function (section) {
            var tmp = {
              value: section.value,
              color: Styles.Color.toString(section.color)
            };
            var tmp$1 = Caml_option.undefined_to_opt(section.tooltip);
            if (tmp$1 !== undefined) {
              tmp.tooltip = Caml_option.valFromOption(tmp$1);
            }
            return tmp;
          })),
    size: size,
    thickness: thickness
  };
  if (label !== undefined) {
    tmp.label = Caml_option.valFromOption(label);
  }
  var tmp$1 = Belt_Option.map(rootColor, Styles.Color.toString);
  if (tmp$1 !== undefined) {
    tmp.rootColor = tmp$1;
  }
  return React.createElement(Core.RingProgress, tmp);
}

var RingProgress = {
  Internal: Internal$8,
  make: Mantine$RingProgress
};

var Internal$9 = {};

function Mantine$Loader(Props) {
  var variant = Props.variant;
  var size = Props.size;
  var color = Props.color;
  var tmp = {};
  if (variant !== undefined) {
    tmp.variant = Caml_option.valFromOption(variant);
  }
  if (size !== undefined) {
    tmp.size = size;
  }
  var tmp$1 = Belt_Option.map(color, Styles.Color.toString);
  if (tmp$1 !== undefined) {
    tmp.color = tmp$1;
  }
  return React.createElement(Core.Loader, tmp);
}

var Loader = {
  Internal: Internal$9,
  make: Mantine$Loader
};

function $$eval(pos) {
  return pos._0;
}

function getPosition(positionRe) {
  return {
          bottom: positionRe.bottom._0,
          right: positionRe.right._0
        };
}

var Internal$10 = {};

function Mantine$Affix(Props) {
  var position = Props.position;
  var children = Props.children;
  return React.createElement(Core.Affix, {
              position: getPosition(position),
              children: children
            });
}

var Affix = {
  $$eval: $$eval,
  getPosition: getPosition,
  Internal: Internal$10,
  make: Mantine$Affix
};

var Internal$11 = {};

function Mantine$Modal(Props) {
  var centered = Props.centered;
  var closeOnClickOutside = Props.closeOnClickOutside;
  var onClose = Props.onClose;
  var opened = Props.opened;
  var overlayProps = Props.overlayProps;
  var radius = Props.radius;
  var shadow = Props.shadow;
  var size = Props.size;
  var title = Props.title;
  var withCloseButtonOpt = Props.withCloseButton;
  var withinPortalOpt = Props.withinPortal;
  var zIndex = Props.zIndex;
  var children = Props.children;
  var withCloseButton = withCloseButtonOpt !== undefined ? withCloseButtonOpt : false;
  var withinPortal = withinPortalOpt !== undefined ? withinPortalOpt : true;
  var tmp = {
    opened: opened,
    withCloseButton: withCloseButton,
    withinPortal: withinPortal,
    children: children
  };
  if (centered !== undefined) {
    tmp.centered = centered;
  }
  if (closeOnClickOutside !== undefined) {
    tmp.closeOnClickOutside = closeOnClickOutside;
  }
  if (onClose !== undefined) {
    tmp.onClose = Caml_option.valFromOption(onClose);
  }
  if (overlayProps !== undefined) {
    tmp.overlayProps = Caml_option.valFromOption(overlayProps);
  }
  var tmp$1 = Belt_Option.map(radius, unwrapSizeNum);
  if (tmp$1 !== undefined) {
    tmp.radius = Caml_option.valFromOption(tmp$1);
  }
  if (shadow !== undefined) {
    tmp.shadow = Caml_option.valFromOption(shadow);
  }
  var tmp$2 = Belt_Option.map(size, unwrapSizeNum);
  if (tmp$2 !== undefined) {
    tmp.size = Caml_option.valFromOption(tmp$2);
  }
  if (title !== undefined) {
    tmp.title = title;
  }
  if (zIndex !== undefined) {
    tmp.zIndex = zIndex;
  }
  return React.createElement(Core.Modal, tmp);
}

var Modal = {
  Internal: Internal$11,
  make: Mantine$Modal
};

var subtleUpSlide_1 = {
  in: {
    hd: Css.transform(Css.translateY(Css.px(0))),
    tl: {
      hd: Css.opacity(1.0),
      tl: /* [] */0
    }
  },
  out: {
    hd: Css.transform(Css.translateY(Css.px(5))),
    tl: {
      hd: Css.opacity(0.0),
      tl: /* [] */0
    }
  },
  common: {
    transformOrigin: "bottom"
  },
  transitionProperty: "transform, opacity"
};

var subtleUpSlide = {
  NAME: "custom",
  VAL: subtleUpSlide_1
};

var subtleDownSlide_1 = {
  in: {
    hd: Css.transform(Css.translateY(Css.px(0))),
    tl: {
      hd: Css.opacity(1.0),
      tl: /* [] */0
    }
  },
  out: {
    hd: Css.transform(Css.translateY(Css.px(-5))),
    tl: {
      hd: Css.opacity(0.0),
      tl: /* [] */0
    }
  },
  common: {
    transformOrigin: "top"
  },
  transitionProperty: "transform, opacity"
};

var subtleDownSlide = {
  NAME: "custom",
  VAL: subtleDownSlide_1
};

var subtleLeftSlide_1 = {
  in: {
    hd: Css.transform(Css.translateX(Css.px(0))),
    tl: {
      hd: Css.opacity(1.0),
      tl: /* [] */0
    }
  },
  out: {
    hd: Css.transform(Css.translateX(Css.px(5))),
    tl: {
      hd: Css.opacity(0.0),
      tl: /* [] */0
    }
  },
  common: {
    transformOrigin: "left"
  },
  transitionProperty: "transform, opacity"
};

var subtleLeftSlide = {
  NAME: "custom",
  VAL: subtleLeftSlide_1
};

var subtleRightSlide_1 = {
  in: {
    hd: Css.transform(Css.translateX(Css.px(0))),
    tl: {
      hd: Css.opacity(1.0),
      tl: /* [] */0
    }
  },
  out: {
    hd: Css.transform(Css.translateX(Css.px(-5))),
    tl: {
      hd: Css.opacity(0.0),
      tl: /* [] */0
    }
  },
  common: {
    transformOrigin: "left"
  },
  transitionProperty: "transform, opacity"
};

var subtleRightSlide = {
  NAME: "custom",
  VAL: subtleRightSlide_1
};

var Internal$12 = {};

function Mantine$Transition(Props) {
  var transition = Props.transition;
  var mounted = Props.mounted;
  var duration = Props.duration;
  var exitDuration = Props.exitDuration;
  var onEnter = Props.onEnter;
  var onEntered = Props.onEntered;
  var onExit = Props.onExit;
  var onExited = Props.onExited;
  var children = Props.children;
  var tmp = {
    transition: unwrapReTransition(transition),
    mounted: mounted,
    children: children
  };
  if (duration !== undefined) {
    tmp.duration = duration;
  }
  if (exitDuration !== undefined) {
    tmp.exitDuration = exitDuration;
  }
  if (onEnter !== undefined) {
    tmp.onEnter = Caml_option.valFromOption(onEnter);
  }
  if (onEntered !== undefined) {
    tmp.onEntered = Caml_option.valFromOption(onEntered);
  }
  if (onExit !== undefined) {
    tmp.onExit = Caml_option.valFromOption(onExit);
  }
  if (onExited !== undefined) {
    tmp.onExited = Caml_option.valFromOption(onExited);
  }
  return React.createElement(Core.Transition, tmp);
}

var Transition = {
  subtleUpSlide: subtleUpSlide,
  subtleDownSlide: subtleDownSlide,
  subtleLeftSlide: subtleLeftSlide,
  subtleRightSlide: subtleRightSlide,
  Internal: Internal$12,
  make: Mantine$Transition
};

var Internal$13 = {};

function Mantine$Checkbox(Props) {
  var checked = Props.checked;
  var color = Props.color;
  var disabled = Props.disabled;
  var indeterminate = Props.indeterminate;
  var label = Props.label;
  var labelPosition = Props.labelPosition;
  var onChange = Props.onChange;
  var radius = Props.radius;
  var size = Props.size;
  var transitionDuration = Props.transitionDuration;
  var tmp = {};
  if (checked !== undefined) {
    tmp.checked = checked;
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (disabled !== undefined) {
    tmp.disabled = disabled;
  }
  if (indeterminate !== undefined) {
    tmp.indeterminate = indeterminate;
  }
  if (label !== undefined) {
    tmp.label = Caml_option.valFromOption(label);
  }
  if (labelPosition !== undefined) {
    tmp.labelPosition = Caml_option.valFromOption(labelPosition);
  }
  if (onChange !== undefined) {
    tmp.onChange = Caml_option.valFromOption(onChange);
  }
  if (radius !== undefined) {
    tmp.radius = Caml_option.valFromOption(radius);
  }
  var tmp$1 = Belt_Option.map(size, unwrapSizeNum);
  if (tmp$1 !== undefined) {
    tmp.size = Caml_option.valFromOption(tmp$1);
  }
  if (transitionDuration !== undefined) {
    tmp.transitionDuration = transitionDuration;
  }
  return React.createElement(Core.Checkbox, tmp);
}

var Checkbox = {
  Internal: Internal$13,
  make: Mantine$Checkbox
};

var T = {};

function useWithoutOptions(param) {
  var intersectionStuff = Hooks.useIntersection(null);
  return {
          ref: intersectionStuff.ref,
          entry: Belt_Option.map(Caml_option.nullable_to_opt(intersectionStuff.entry), (function (entry) {
                  return {
                          isIntersecting: entry.isIntersecting
                        };
                }))
        };
}

function use(i) {
  var tmp = {};
  var tmp$1 = Caml_option.undefined_to_opt(i.root);
  if (tmp$1 !== undefined) {
    tmp.root = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Belt_Option.map(Caml_option.undefined_to_opt(i.rootMargin), Css_AtomicTypes.Length.toString);
  if (tmp$2 !== undefined) {
    tmp.rootMargin = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = Caml_option.undefined_to_opt(i.threshold);
  if (tmp$3 !== undefined) {
    tmp.threshold = Caml_option.valFromOption(tmp$3);
  }
  var input = Js_null_undefined.fromOption(Caml_option.some(tmp));
  var intersectionStuff = Hooks.useIntersection(input);
  return {
          ref: intersectionStuff.ref,
          entry: Belt_Option.map(Caml_option.nullable_to_opt(intersectionStuff.entry), (function (entry) {
                  return {
                          isIntersecting: entry.isIntersecting
                        };
                }))
        };
}

var Intersection = {
  T: T,
  useWithoutOptions: useWithoutOptions,
  use: use
};

var Hooks$1 = {
  Intersection: Intersection
};

exports.Types = Types;
exports.Utils = Utils;
exports.Provider = Provider;
exports.Menu = Menu;
exports.Popover = Popover;
exports.Select = Select;
exports.MultiSelect = MultiSelect;
exports.RingProgress = RingProgress;
exports.Loader = Loader;
exports.Affix = Affix;
exports.Modal = Modal;
exports.Transition = Transition;
exports.Checkbox = Checkbox;
exports.Hooks = Hooks$1;
/* paddingReset Not a pure module */
