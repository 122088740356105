// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Styles = require("./styles.bs.js");
var Mantine = require("./Mantine.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core = require("@mantine/core");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function menuItemStyles(customStyle) {
  return {
          hd: Css.padding2(Css.px(8), Css.px(16)),
          tl: {
            hd: Css.textAlign("left"),
            tl: {
              hd: Css.fontSize(Styles.FontSize.regular),
              tl: {
                hd: Css.fontWeight(Styles.FontWeight.medium),
                tl: {
                  hd: Css.selector(" p", {
                        hd: Css.color(customStyle === "Default" ? Styles.Color.grey80 : Styles.Color.grapeError),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.minWidth(Css.px(240)),
                      tl: {
                        hd: Css.maxWidth(Css.px(300)),
                        tl: {
                          hd: Css.transition({
                                NAME: "ms",
                                VAL: Styles.Duration.$$short
                              }, undefined, undefined, "all"),
                          tl: {
                            hd: Css.hover({
                                  hd: Css.backgroundColor(Styles.Color.grey20),
                                  tl: {
                                    hd: Css.selector(" p", {
                                          hd: Css.color(customStyle === "Default" ? Styles.Color.grey90 : Styles.Color.grapeErrorSecondary),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }),
                            tl: {
                              hd: Css.focus({
                                    hd: Css.backgroundColor(Styles.Color.grey20),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.disabled({
                                      hd: Css.selector(" p", {
                                            hd: Css.color(Styles.Color.grey50),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.backgroundColor("transparent"),
                                              tl: {
                                                hd: Css.color(Styles.Color.grey50),
                                                tl: {
                                                  hd: Css.cursor("default"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: {
                                  hd: Css.borderRadius(Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        };
}

var buttonStyles = Curry._1(Css.style, {
      hd: Css.maxHeight(Css.px(28)),
      tl: /* [] */0
    });

function ContextMenu$V2$Item(Props) {
  var label = Props.label;
  var onClick = Props.onClick;
  var customStyleOpt = Props.style;
  var icon = Props.icon;
  var rightSection = Props.rightSection;
  var disabledOpt = Props.disabled;
  var customStyle = customStyleOpt !== undefined ? customStyleOpt : "Default";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var tmp = {
    additionalStyles: menuItemStyles(customStyle),
    disabled: disabled,
    children: React.createElement($$Text.make, {
          size: "Medium",
          weight: "Medium",
          color: Styles.Color.grey80,
          children: label
        })
  };
  if (icon !== undefined) {
    tmp.icon = Caml_option.valFromOption(icon);
  }
  if (rightSection !== undefined) {
    tmp.rightSection = Caml_option.valFromOption(rightSection);
  }
  var tmp$1 = Belt_Option.map(onClick, (function (cb) {
          if (disabled) {
            return function (param) {
              
            };
          } else {
            return cb;
          }
        }));
  if (tmp$1 !== undefined) {
    tmp.onClick = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Mantine.Menu.Item.make, tmp);
}

var Item = {
  make: ContextMenu$V2$Item
};

function ContextMenu$V2$Label(Props) {
  var label = Props.label;
  return React.createElement(Core.Menu.Label, {
              children: label
            });
}

var Label = {
  make: ContextMenu$V2$Label
};

function ContextMenu$V2$Divider(Props) {
  return React.createElement(Mantine.Menu.Divider.make, {
              additionalStyles: {
                hd: Css.borderTopColor(Styles.Color.grey20),
                tl: {
                  hd: Css.margin2(Css.px(3), "zero"),
                  tl: /* [] */0
                }
              }
            });
}

var Divider = {
  make: ContextMenu$V2$Divider
};

function dropdownStyles(offset) {
  var variant = offset.NAME;
  var tmp;
  if (variant === "y") {
    tmp = {
      hd: Css.translateY(Css.px(offset.VAL)),
      tl: /* [] */0
    };
  } else if (variant === "xy") {
    var match = offset.VAL;
    tmp = {
      hd: Css.translateX(Css.px(match[0])),
      tl: {
        hd: Css.translateY(Css.px(match[1])),
        tl: /* [] */0
      }
    };
  } else {
    tmp = {
      hd: Css.translateX(Css.px(offset.VAL)),
      tl: /* [] */0
    };
  }
  return {
          hd: Css.borderRadius(Css.px(5)),
          tl: {
            hd: Css.padding2(Css.px(4), Css.px(0)),
            tl: {
              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                            NAME: "num",
                            VAL: 0.10
                          }))),
              tl: {
                hd: Css.transforms(tmp),
                tl: /* [] */0
              }
            }
          }
        };
}

function ContextMenu$V2(Props) {
  var withBorderOpt = Props.withBorder;
  var position = Props.position;
  var offsetOpt = Props.offset;
  var additionalStylesOpt = Props.additionalStyles;
  var button = Props.button;
  var children = Props.children;
  var withBorder = withBorderOpt !== undefined ? withBorderOpt : false;
  var offset = offsetOpt !== undefined ? offsetOpt : ({
        NAME: "xy",
        VAL: [
          0,
          0
        ]
      });
  var additionalStyles = additionalStylesOpt !== undefined ? additionalStylesOpt : /* [] */0;
  var tmp = {
    children: null
  };
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  return React.createElement(Mantine.Menu.make, tmp, React.createElement(Core.Menu.Target, {
                  children: Belt_Option.getWithDefault(button, React.createElement("div", {
                            className: buttonStyles
                          }, React.createElement(Button.make, {
                                icon: "more",
                                size: "tiny",
                                style: withBorder ? "outline" : "ghost"
                              })))
                }), React.createElement(Mantine.Menu.Dropdown.make, {
                  additionalStyles: Belt_List.concat(dropdownStyles(offset), additionalStyles),
                  children: children
                }));
}

var V2 = {
  menuItemStyles: menuItemStyles,
  buttonStyles: buttonStyles,
  Item: Item,
  Label: Label,
  Divider: Divider,
  dropdownStyles: dropdownStyles,
  make: ContextMenu$V2
};

function ContextMenu(Props) {
  var withBorderOpt = Props.withBorder;
  var positionOpt = Props.align;
  var placementOpt = Props.placement;
  var offsetOpt = Props.offset;
  var button = Props.button;
  var options = Props.options;
  var withBorder = withBorderOpt !== undefined ? withBorderOpt : false;
  var position = positionOpt !== undefined ? positionOpt : "Bottom";
  var placement = placementOpt !== undefined ? placementOpt : "End";
  var offset = offsetOpt !== undefined ? Caml_option.valFromOption(offsetOpt) : ({
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      });
  var mantinePosition = position === "Top" ? (
      placement === "Start" ? "top-start" : (
          placement === "Center" ? "top" : "top-end"
        )
    ) : (
      position === "Bottom" ? (
          placement === "Start" ? "bottom-start" : (
              placement === "Center" ? "bottom" : "bottom-end"
            )
        ) : (
          position === "Left" ? (
              placement === "Start" ? "left-start" : (
                  placement === "Center" ? "left" : "left-end"
                )
            ) : (
              placement === "Start" ? "right-start" : (
                  placement === "Center" ? "right" : "right-end"
                )
            )
        )
    );
  var match = offset.left;
  var match$1 = offset.top;
  var x = match !== undefined ? (
      match$1 !== undefined ? match - match$1 | 0 : match
    ) : (
      match$1 !== undefined ? match$1 : undefined
    );
  var match$2 = offset.top;
  var match$3 = offset.bottom;
  var y = match$2 !== undefined ? (
      match$3 !== undefined ? match$2 - match$3 | 0 : match$2
    ) : (
      match$3 !== undefined ? match$3 : undefined
    );
  var mantineOffset = x !== undefined ? (
      y !== undefined ? ({
            NAME: "xy",
            VAL: [
              x,
              y
            ]
          }) : ({
            NAME: "x",
            VAL: x
          })
    ) : (
      y !== undefined ? ({
            NAME: "y",
            VAL: y
          }) : undefined
    );
  var items = Belt_Array.mapWithIndex(options, (function (index, item) {
          if (typeof item !== "object") {
            return React.createElement(ContextMenu$V2$Divider, {
                        key: "separator-" + String(index)
                      });
          }
          var variant = item.NAME;
          if (variant === "Option") {
            var match = item.VAL;
            var label = match.label;
            return React.createElement(ContextMenu$V2$Item, {
                        label: label,
                        onClick: match.onClick,
                        key: label
                      });
          }
          if (variant === "DisabledOption") {
            var match$1 = item.VAL;
            var label$1 = match$1.label;
            return React.createElement(ContextMenu$V2$Item, {
                        label: label$1,
                        onClick: match$1.onClick,
                        disabled: true,
                        key: label$1
                      });
          }
          var match$2 = item.VAL;
          var label$2 = match$2.label;
          return React.createElement(ContextMenu$V2$Item, {
                      label: label$2,
                      onClick: match$2.onClick,
                      style: "Warning",
                      key: label$2
                    });
        }));
  var tmp = {
    withBorder: withBorder,
    position: mantinePosition,
    children: items
  };
  if (mantineOffset !== undefined) {
    tmp.offset = Caml_option.valFromOption(mantineOffset);
  }
  if (button !== undefined) {
    tmp.button = Caml_option.valFromOption(button);
  }
  return React.createElement(ContextMenu$V2, tmp);
}

var make = ContextMenu;

exports.V2 = V2;
exports.make = make;
/* buttonStyles Not a pure module */
