// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function cleanNonInsert(delta) {
  var ops = Js_dict.get(delta, "ops");
  if (ops === undefined) {
    return delta;
  }
  var newOps = Belt_Array.keep(Caml_option.valFromOption(ops), (function (op) {
          return Belt_Option.isSome(Belt_Array.getBy(Object.keys(op), (function (key) {
                            return key === "insert";
                          })));
        }));
  delta["ops"] = newOps;
  return delta;
}

function diff(t1, t2) {
  return cleanNonInsert(t1).diff(cleanNonInsert(t2));
}

function emptyOps(param) {
  return [];
}

function toStr(delta) {
  return Belt_Array.map(delta.filter(function (op) {
                    return Belt_Option.mapWithDefault(Json_decode.optional((function (param) {
                                      return Json_decode.field("insert", Json_decode.string, param);
                                    }), op), false, (function (param) {
                                  return true;
                                }));
                  }), (function (op) {
                  return Json_decode.field("insert", Json_decode.string, op);
                })).join("");
}

exports.cleanNonInsert = cleanNonInsert;
exports.diff = diff;
exports.emptyOps = emptyOps;
exports.toStr = toStr;
/* No side effect */
