// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var ReactDom = require("react-dom");

function Portal(Props) {
  var children = Props.children;
  return ReactDom.createPortal(children, document.body);
}

var make = Portal;

exports.make = make;
/* react-dom Not a pure module */
