// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var userAgent = window.navigator.userAgent.toLowerCase();

var os = userAgent.includes("windows") ? "Windows" : (
    /(mac|ipod|iphone|ipad)/g.test(userAgent) ? "MacOS" : "Linux"
  );

exports.os = os;
/* userAgent Not a pure module */
