// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("./Link.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var IconNext = require("./IconNext.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var LoadingCircle = require("./LoadingCircle.bs.js");
var OnboardingConfig = require("./OnboardingConfig.bs.js");

function OnboardingStep(Props) {
  var step = Props.step;
  var title = Props.title;
  var subtitle = Props.subtitle;
  var comfortTextOpt = Props.comfortText;
  var isValid = Props.isValid;
  var onNext = Props.onNext;
  var loadingOpt = Props.loading;
  var maySkipOpt = Props.maySkip;
  var children = Props.children;
  var comfortText = comfortTextOpt !== undefined ? comfortTextOpt : "";
  var loading = loadingOpt !== undefined ? loadingOpt : false;
  var maySkip = maySkipOpt !== undefined ? maySkipOpt : false;
  var match = OnboardingConfig.getNext(step);
  var tmp;
  if (onNext !== undefined) {
    var disabled = maySkip && isValid === "ValidEmptyState" ? false : isValid !== "Valid" || loading;
    tmp = React.createElement("button", {
          className: Curry._1(Css.merge, {
                hd: Curry._1(Css.style, Styles.button),
                tl: {
                  hd: Curry._1(Css.style, {
                        hd: Css.cursor(disabled ? "default" : "pointer"),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.medium),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.bold),
                                tl: {
                                  hd: Css.margin(Css.px(0)),
                                  tl: {
                                    hd: Css.padding(Css.px(0)),
                                    tl: {
                                      hd: Css.color(disabled ? Styles.Color.grey40 : OnboardingConfig.getColor(step)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              }),
          disabled: disabled,
          onClick: (function (param) {
              return Curry._1(onNext, undefined);
            })
        }, loading ? React.createElement(React.Fragment, undefined, React.createElement(LoadingCircle.make, {
                    color: Styles.Color.grey40
                  }), React.createElement(Spacer.make, {
                    width: 16
                  })) : null, maySkip && isValid !== "Valid" ? "Skip for now" : "Next", React.createElement(Spacer.make, {
              width: 16
            }), React.createElement(IconNext.make, {
              size: 24,
              color: disabled ? Styles.Color.grey40 : OnboardingConfig.getColor(step)
            }));
  } else if (match !== undefined) {
    var disabled$1 = maySkip && isValid === "ValidEmptyState" ? false : isValid !== "Valid" || loading;
    tmp = React.createElement(Link.make, {
          path: match,
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.cursor(disabled$1 ? "default" : "pointer"),
                    tl: {
                      hd: Css.fontWeight(Styles.FontWeight.bold),
                      tl: {
                        hd: Css.color(disabled$1 ? Styles.Color.grey40 : OnboardingConfig.getColor(step)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }),
          disabled: disabled$1,
          children: null
        }, loading ? React.createElement(React.Fragment, undefined, React.createElement(LoadingCircle.make, {
                    color: Styles.Color.grey40
                  }), React.createElement(Spacer.make, {
                    width: 16
                  })) : null, maySkip && isValid !== "Valid" ? "Skip for now" : "Next", React.createElement(Spacer.make, {
              width: 15
            }), React.createElement(IconNext.make, {
              size: 24,
              color: disabled$1 ? Styles.Color.grey40 : OnboardingConfig.getColor(step)
            }));
  } else {
    tmp = React.createElement("span", undefined);
  }
  return React.createElement("div", {
              key: OnboardingConfig.stepToJs(step)
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.zIndex(1),
                        tl: /* [] */0
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.position("fixed"),
                            tl: {
                              hd: Css.top(Css.px(0)),
                              tl: {
                                hd: Css.right(Css.pct(50)),
                                tl: {
                                  hd: Css.bottom(Css.px(0)),
                                  tl: {
                                    hd: Css.left(Css.px(0)),
                                    tl: {
                                      hd: Css.padding4(Css.vh(25.0), Css.px(100), Css.px(0), Css.px(100)),
                                      tl: {
                                        hd: Css.backgroundColor(OnboardingConfig.getColor(step)),
                                        tl: {
                                          hd: Css.color(Styles.Color.white),
                                          tl: {
                                            hd: Css.media("(max-width: 900px)", {
                                                  hd: Css.position("relative"),
                                                  tl: {
                                                    hd: Css.width(Css.pct(100)),
                                                    tl: {
                                                      hd: Css.padding4(Css.vh(10), Css.px(50), Css.vh(10), Css.px(50)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }),
                                            tl: {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.flexDirection("column"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontSize(Css.px(40)),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.bold),
                                  tl: {
                                    hd: Css.media("(max-width: 900px)", {
                                          hd: Css.fontSize(Css.px(22)),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, title), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontSize(Css.px(24)),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.bold),
                                  tl: {
                                    hd: Css.marginTop(Css.px(48)),
                                    tl: {
                                      hd: Css.media("(max-width: 900px)", {
                                            hd: Css.marginTop(Css.px(10)),
                                            tl: {
                                              hd: Css.fontSize(Css.px(14)),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, subtitle), React.createElement(Spacer.make, {
                          height: 16,
                          grow: 1.0
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontSize(Css.px(18)),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.regular),
                                  tl: {
                                    hd: Css.marginTop(Css.px(48)),
                                    tl: {
                                      hd: Css.media("(max-width: 900px)", {
                                            hd: Css.marginTop(Css.px(10)),
                                            tl: {
                                              hd: Css.fontSize(Css.px(14)),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: {
                                        hd: Css.color(Styles.Color.lighten(OnboardingConfig.getColor(step), 30)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        }, comfortText), React.createElement(Spacer.make, {
                          height: 70
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginLeft(Css.pct(50)),
                            tl: {
                              hd: Css.padding4(Css.px(32), Css.px(50), Css.px(90), Css.px(50)),
                              tl: {
                                hd: Css.width(Css.pct(50)),
                                tl: {
                                  hd: Css.media("(max-width: 900px)", {
                                        hd: Css.marginLeft(Css.px(0)),
                                        tl: {
                                          hd: Css.width(Css.pct(100)),
                                          tl: {
                                            hd: Css.paddingTop(Css.px(0)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }),
                                  tl: {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.flexDirection("column"),
                                      tl: {
                                        hd: Css.minHeight(Css.vh(100.0)),
                                        tl: {
                                          hd: Css.justifyContent("center"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, children)), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("fixed"),
                        tl: {
                          hd: Css.right(Css.px(0)),
                          tl: {
                            hd: Css.bottom(Css.px(0)),
                            tl: {
                              hd: Css.left(Css.pct(50)),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("spaceBetween"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.padding2(Css.px(20), Css.px(50)),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.white),
                                        tl: {
                                          hd: Css.zIndex(1),
                                          tl: {
                                            hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey30),
                                            tl: {
                                              hd: Css.media("(max-width: 900px)", {
                                                    hd: Css.left(Css.px(0)),
                                                    tl: /* [] */0
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, Belt_Option.mapWithDefault(OnboardingConfig.getBack(step), React.createElement("span", undefined), (function (back) {
                        return React.createElement(Link.make, {
                                    path: back,
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.bold),
                                              tl: {
                                                hd: Css.color(OnboardingConfig.getColor(step)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }),
                                    children: null
                                  }, React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.transform(Css.rotate(Css.deg(180.0))),
                                              tl: {
                                                hd: Css.marginRight(Css.px(15)),
                                                tl: /* [] */0
                                              }
                                            })
                                      }, React.createElement(IconNext.make, {
                                            size: 24,
                                            color: OnboardingConfig.getColor(step)
                                          })), "Back");
                      })), tmp));
}

var make = OnboardingStep;

exports.make = make;
/* Css Not a pure module */
