// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Semver = require("../../shared/bindings/semver.bs.js");
var Semver$1 = require("semver");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

function compareDotAsNumbers(version1, version2) {
  if (version1 > version2) {
    return /* Greater */0;
  } else if (version1 === version2) {
    return /* Equal */1;
  } else {
    return /* Smaller */2;
  }
}

function compareDot(version1, version2) {
  var match = Pervasives.int_of_string_opt(version1);
  var match$1 = Pervasives.int_of_string_opt(version2);
  if (match !== undefined && match$1 !== undefined) {
    return compareDotAsNumbers(match, match$1);
  }
  if (version1 > version2) {
    return /* Greater */0;
  } else if (version1 === version2) {
    return /* Equal */1;
  } else {
    return /* Smaller */2;
  }
}

function compareSemanticArrays(array1, array2) {
  var length1 = array1.length;
  var length2 = array2.length;
  if (length1 > length2) {
    return /* Greater */0;
  } else if (length1 === length2) {
    return /* Equal */1;
  } else {
    return /* Smaller */2;
  }
}

function isSemantic(version) {
  return version.indexOf(".") > -1;
}

function compareToAppVersion(appVersion, compareAppVersion) {
  var appVersionSemantic = Belt_Option.flatMap(appVersion, Semver.valid);
  var compareAppVersionSemantic = Belt_Option.flatMap(compareAppVersion, Semver.valid);
  if (appVersionSemantic !== undefined) {
    if (compareAppVersionSemantic !== undefined && !Semver$1.gt(appVersionSemantic, compareAppVersionSemantic)) {
      if (Semver$1.eq(appVersionSemantic, compareAppVersionSemantic)) {
        return /* Equal */1;
      } else {
        return /* Smaller */2;
      }
    } else {
      return /* Greater */0;
    }
  } else if (compareAppVersionSemantic !== undefined) {
    return /* Smaller */2;
  } else if (appVersion !== undefined) {
    if (compareAppVersion !== undefined) {
      return compareDot(appVersion, compareAppVersion);
    } else {
      return /* Greater */0;
    }
  } else if (compareAppVersion !== undefined) {
    return /* Smaller */2;
  } else {
    return /* Equal */1;
  }
}

exports.compareDotAsNumbers = compareDotAsNumbers;
exports.compareDot = compareDot;
exports.compareSemanticArrays = compareSemanticArrays;
exports.isSemantic = isSemantic;
exports.compareToAppVersion = compareToAppVersion;
/* Semver Not a pure module */
