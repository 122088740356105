// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var App = require("firebase/app");
var FirebaseConfigJs = require("app/firebaseConfig.js");

var Transaction = {};

var $$Storage = {};

function onAuthStateChanged(auth, cb) {
  return auth.onAuthStateChanged(function (maybeUser) {
              return Curry._1(cb, (maybeUser == null) ? undefined : Caml_option.some(maybeUser));
            });
}

function unsafeGetCurrentUser(auth) {
  return Belt_Option.getExn(Caml_option.nullable_to_opt(auth.currentUser));
}

function getCurrentUserToken(auth) {
  return Belt_Option.getExn(Caml_option.nullable_to_opt(auth.currentUser)).getIdToken();
}

var Auth_googleOauthScopes = {
  hd: "profile",
  tl: {
    hd: "email",
    tl: /* [] */0
  }
};

var Auth = {
  googleOauthScopes: Auth_googleOauthScopes,
  onAuthStateChanged: onAuthStateChanged,
  unsafeGetCurrentUser: unsafeGetCurrentUser,
  getCurrentUserToken: getCurrentUserToken
};

function Collection(Config) {
  var get = function (fb) {
    return fb.collection(Config.name);
  };
  return {
          get: get
        };
}

var Query = {};

function app(param) {
  if (App.default.apps.length !== 0) {
    return App.default.app();
  }
  var app$1 = App.default.initializeApp(FirebaseConfigJs);
  app$1.firestore().settings({
        experimentalAutoDetectLongPolling: true,
        merge: true
      });
  return app$1;
}

function getAuth(param) {
  return app(undefined).auth();
}

require('firebase/auth')
;

require('firebase/firestore')
;

require('firebase/storage')
;

var apiUrl = "/api";

exports.Transaction = Transaction;
exports.apiUrl = apiUrl;
exports.$$Storage = $$Storage;
exports.Auth = Auth;
exports.Collection = Collection;
exports.Query = Query;
exports.app = app;
exports.getAuth = getAuth;
/*  Not a pure module */
