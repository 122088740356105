// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var AvoConfig = require("../../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var IntegrationUtils = require("../../../api/functions/src/IntegrationUtils.bs.js");
var WebhookPayloadFormat = require("../../../shared/models/WebhookPayloadFormat.bs.js");

function trackIntegrationConfigured(integrations, integration, schemaGroup, configureType) {
  var match = integration.config;
  var tmp;
  tmp = match !== undefined && match.TAG === /* Webhook */3 ? WebhookPayloadFormat.toAnalytics(match._0.payloadFormat) : undefined;
  return AnalyticsRe.integrationConfigured(AnalyticsRe.Group.integrationConfig(IntegrationUtils.configToAnalyticsType(integration.config), integration.filters === /* [] */0 ? ["None"] : Belt_Array.concatMany([
                        Belt_Option.mapWithDefault(Belt_List.getByU(integration.filters, (function (filter) {
                                    if (typeof filter === "object") {
                                      return filter.NAME === "Source";
                                    } else {
                                      return false;
                                    }
                                  })), [], (function (param) {
                                return ["Sources"];
                              })),
                        Belt_Option.mapWithDefault(Belt_List.getByU(integration.filters, (function (filter) {
                                    if (typeof filter === "object") {
                                      return filter.NAME === "Destination";
                                    } else {
                                      return false;
                                    }
                                  })), [], (function (param) {
                                return ["Destinations"];
                              })),
                        Belt_Option.mapWithDefault(Belt_List.getByU(integration.filters, (function (filter) {
                                    if (typeof filter === "object") {
                                      return filter.NAME === "Tag";
                                    } else {
                                      return false;
                                    }
                                  })), [], (function (param) {
                                return ["Tags"];
                              }))
                      ]), AvoConfig.getIntegrationName(integration), integration.id, tmp), schemaGroup, "Created", configureType, Belt_List.length(integrations), schemaGroup.branchId, schemaGroup.schemaId);
}

exports.trackIntegrationConfigured = trackIntegrationConfigured;
/* AvoConfig Not a pure module */
