// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Zustand = require("../Zustand.bs.js");
var Belt_Map = require("rescript/lib/js/belt_Map.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var ModelStore = require("../ModelStore.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SortArray = require("rescript/lib/js/belt_SortArray.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var Shallow = require("zustand/shallow");
var AppVersionsStore = require("../AppVersionsStore.bs.js");
var InspectorViewApi = require("./InspectorViewApi.bs.js");
var WorkspaceContext = require("../WorkspaceContext.bs.js");
var InspectorIssuesHelpers = require("./InspectorIssuesHelpers.bs.js");
var InspectorIssueViewModel = require("./InspectorIssueViewModel.bs.js");
var InspectorIssuesFilterHooks = require("./InspectorIssuesFilterHooks.bs.js");
var InspectorIssuesFilterUtils = require("../../../shared/utils/InspectorIssuesFilterUtils.bs.js");
var Difference_in_seconds = require("date-fns/difference_in_seconds");

var FetchError = /* @__PURE__ */Caml_exceptions.create("InspectorIssuesStore.FetchError");

var cmp = Caml_obj.caml_compare;

var RequestComparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

function empty(param) {
  return Belt_Map.make(RequestComparator);
}

var $$Map = {
  Int: Belt_Map.Int,
  $$String: Belt_Map.$$String,
  Dict: Belt_Map.Dict,
  make: Belt_Map.make,
  isEmpty: Belt_Map.isEmpty,
  has: Belt_Map.has,
  cmpU: Belt_Map.cmpU,
  cmp: Belt_Map.cmp,
  eqU: Belt_Map.eqU,
  eq: Belt_Map.eq,
  findFirstByU: Belt_Map.findFirstByU,
  findFirstBy: Belt_Map.findFirstBy,
  forEachU: Belt_Map.forEachU,
  forEach: Belt_Map.forEach,
  reduceU: Belt_Map.reduceU,
  reduce: Belt_Map.reduce,
  everyU: Belt_Map.everyU,
  every: Belt_Map.every,
  someU: Belt_Map.someU,
  some: Belt_Map.some,
  size: Belt_Map.size,
  toArray: Belt_Map.toArray,
  toList: Belt_Map.toList,
  fromArray: Belt_Map.fromArray,
  keysToArray: Belt_Map.keysToArray,
  valuesToArray: Belt_Map.valuesToArray,
  minKey: Belt_Map.minKey,
  minKeyUndefined: Belt_Map.minKeyUndefined,
  maxKey: Belt_Map.maxKey,
  maxKeyUndefined: Belt_Map.maxKeyUndefined,
  minimum: Belt_Map.minimum,
  minUndefined: Belt_Map.minUndefined,
  maximum: Belt_Map.maximum,
  maxUndefined: Belt_Map.maxUndefined,
  get: Belt_Map.get,
  getUndefined: Belt_Map.getUndefined,
  getWithDefault: Belt_Map.getWithDefault,
  getExn: Belt_Map.getExn,
  remove: Belt_Map.remove,
  removeMany: Belt_Map.removeMany,
  set: Belt_Map.set,
  updateU: Belt_Map.updateU,
  update: Belt_Map.update,
  mergeMany: Belt_Map.mergeMany,
  mergeU: Belt_Map.mergeU,
  merge: Belt_Map.merge,
  keepU: Belt_Map.keepU,
  keep: Belt_Map.keep,
  partitionU: Belt_Map.partitionU,
  partition: Belt_Map.partition,
  split: Belt_Map.split,
  mapU: Belt_Map.mapU,
  map: Belt_Map.map,
  mapWithKeyU: Belt_Map.mapWithKeyU,
  mapWithKey: Belt_Map.mapWithKey,
  getData: Belt_Map.getData,
  getId: Belt_Map.getId,
  packIdData: Belt_Map.packIdData,
  checkInvariantInternal: Belt_Map.checkInvariantInternal,
  empty: empty
};

var $$Request = {
  RequestComparator: RequestComparator,
  $$Map: $$Map
};

var StoreConfig = {};

var ZustandStore = Zustand.MakeEqualityFnStore(StoreConfig, Zustand.Compare.Default);

function makeNewRequestConfig(schemaId) {
  return {
          schemaId: schemaId,
          timestamp: Date.now()
        };
}

function updateLoadingState(state, requestConfig, loadingState) {
  return {
          requests: Belt_Map.updateU(state.requests, requestConfig, (function (maybeRequest) {
                  return {
                          enrichedWithActionId: Belt_Option.mapWithDefault(maybeRequest, "", (function (param) {
                                  return param.enrichedWithActionId;
                                })),
                          inspectorIssues: Belt_Option.mapWithDefault(maybeRequest, [], (function (param) {
                                  return param.inspectorIssues;
                                })),
                          issues: Belt_Option.mapWithDefault(maybeRequest, [], (function (param) {
                                  return param.issues;
                                })),
                          issuesById: Belt_Option.mapWithDefault(maybeRequest, undefined, (function (param) {
                                  return param.issuesById;
                                })),
                          loadingState: loadingState
                        };
                })),
          updateOnModelChange: state.updateOnModelChange,
          fetch: state.fetch
        };
}

function getOrderedRequestConfigsForSchemaId(schemaId, m) {
  return Belt_SortArray.stableSortByU(Belt_Map.keysToArray(Belt_Map.keepU(m, (function (param, _value) {
                        return param.schemaId === schemaId;
                      }))), (function (a, b) {
                return Caml.caml_float_compare(a.timestamp, b.timestamp);
              }));
}

function getLatestRequestForSchemaId(schemaId, m) {
  var orderedRequestConfigs = getOrderedRequestConfigsForSchemaId(schemaId, m);
  return Belt_Option.flatMap(Belt_Array.get(orderedRequestConfigs, orderedRequestConfigs.length - 1 | 0), (function (rq) {
                return Belt_Option.map(Belt_Map.get(m, rq), (function (value) {
                              return [
                                      rq,
                                      value
                                    ];
                            }));
              }));
}

function getLatestSuccessfulRequestForSchemaId(schemaId, m) {
  var successfulRequests = Belt_Map.keepU(m, (function (_key, param) {
          return param.loadingState === "success";
        }));
  return getLatestRequestForSchemaId(schemaId, successfulRequests);
}

var store = Curry._1(ZustandStore.create, (function (set, get) {
        return {
                requests: Belt_Map.make(RequestComparator),
                updateOnModelChange: (function (requestConfig, latestActionId) {
                    return set(function (state) {
                                return {
                                        requests: Belt_Map.updateU(state.requests, requestConfig, (function (maybeRequest) {
                                                if (maybeRequest === undefined) {
                                                  return ;
                                                }
                                                if (maybeRequest.enrichedWithActionId === latestActionId) {
                                                  return maybeRequest;
                                                }
                                                var match = Curry._1(ModelStore.ZustandStore.getState, ModelStore.store);
                                                var issues = InspectorIssueViewModel.Utils.fromIssues(match.model, maybeRequest.inspectorIssues);
                                                return {
                                                        enrichedWithActionId: latestActionId,
                                                        inspectorIssues: maybeRequest.inspectorIssues,
                                                        issues: issues,
                                                        issuesById: InspectorIssuesHelpers.issuesToMap(issues),
                                                        loadingState: maybeRequest.loadingState
                                                      };
                                              })),
                                        updateOnModelChange: state.updateOnModelChange,
                                        fetch: state.fetch
                                      };
                              });
                  }),
                fetch: (function (schemaId, model, latestActionId) {
                    var requestConfig_timestamp = Date.now();
                    var requestConfig = {
                      schemaId: schemaId,
                      timestamp: requestConfig_timestamp
                    };
                    var match = getLatestRequestForSchemaId(schemaId, Curry._1(get, undefined).requests);
                    if (match !== undefined && match[1].loadingState === "loading") {
                      return Promise.resolve(undefined);
                    }
                    set(function (state) {
                          return updateLoadingState(state, requestConfig, "loading");
                        });
                    return $$Promise.$$catch(InspectorViewApi.getIssues(schemaId).then(function (res) {
                                      if (res.NAME === "success") {
                                        return res.VAL;
                                      }
                                      throw {
                                            RE_EXN_ID: FetchError,
                                            _1: res.VAL,
                                            Error: new Error()
                                          };
                                    }).then(function (inspectorIssues) {
                                    var issues = InspectorIssueViewModel.Utils.fromIssues(model, inspectorIssues);
                                    return set(function (state) {
                                                return {
                                                        requests: Belt_Map.set(state.requests, requestConfig, {
                                                              enrichedWithActionId: Belt_Option.getWithDefault(latestActionId, ""),
                                                              inspectorIssues: inspectorIssues,
                                                              issues: issues,
                                                              issuesById: InspectorIssuesHelpers.issuesToMap(issues),
                                                              loadingState: "success"
                                                            }),
                                                        updateOnModelChange: state.updateOnModelChange,
                                                        fetch: state.fetch
                                                      };
                                              });
                                  }), (function (error) {
                                  console.warn("Error fetching issues", error);
                                  if (error.RE_EXN_ID === FetchError) {
                                    if (error._1) {
                                      set(function (state) {
                                            return updateLoadingState(state, requestConfig, {
                                                        NAME: "error",
                                                        VAL: /* NotFound */1
                                                      });
                                          });
                                    } else {
                                      set(function (state) {
                                            return updateLoadingState(state, requestConfig, {
                                                        NAME: "error",
                                                        VAL: /* Internal */0
                                                      });
                                          });
                                    }
                                  } else {
                                    set(function (state) {
                                          return updateLoadingState(state, requestConfig, {
                                                      NAME: "error",
                                                      VAL: /* NotFound */1
                                                    });
                                        });
                                  }
                                  return Promise.resolve(undefined);
                                }));
                  })
              };
      }));

function useLatestConfig(param) {
  var match = WorkspaceContext.use(undefined);
  var schemaId = match.id;
  return Curry._2(ZustandStore.use, store, (function (state) {
                return Belt_Option.map(getLatestSuccessfulRequestForSchemaId(schemaId, state.requests), (function (prim) {
                              return prim[0];
                            }));
              }));
}

function useIssues(withoutRefetchOnErrorOpt, param) {
  var withoutRefetchOnError = withoutRefetchOnErrorOpt !== undefined ? withoutRefetchOnErrorOpt : false;
  var match = WorkspaceContext.use(undefined);
  var schemaId = match.id;
  var match$1 = ModelStore.use(undefined);
  var latestActionId = match$1.latestActionId;
  var model = match$1.model;
  var match$2 = Curry._3(ZustandStore.useWithCustomEqualityFn, store, (function (state) {
          return [
                  state.requests,
                  state.fetch
                ];
        }), Shallow.shallow);
  var $$fetch = match$2[1];
  var requests = match$2[0];
  var latestRequest = React.useMemo((function () {
          return getLatestRequestForSchemaId(schemaId, requests);
        }), [
        schemaId,
        requests
      ]);
  var match$3 = React.useMemo((function () {
          if (latestRequest === undefined) {
            return [
                    "initial",
                    [],
                    undefined
                  ];
          }
          var match = latestRequest[1];
          var loadingState = match.loadingState;
          if (loadingState !== "loading") {
            return [
                    loadingState,
                    match.issues,
                    match.issuesById
                  ];
          }
          var match$1 = getLatestSuccessfulRequestForSchemaId(schemaId, requests);
          if (match$1 === undefined) {
            return [
                    loadingState,
                    [],
                    undefined
                  ];
          }
          var match$2 = match$1[1];
          return [
                  loadingState,
                  match$2.issues,
                  match$2.issuesById
                ];
        }), [latestRequest]);
  var $$fetch$1 = React.useCallback((function (param) {
          if (Belt_Option.isSome(latestActionId)) {
            Curry._3($$fetch, schemaId, model, latestActionId);
            return ;
          }
          
        }), [
        schemaId,
        latestActionId
      ]);
  React.useEffect((function () {
          if (Belt_Option.isSome(latestActionId)) {
            if (latestRequest !== undefined) {
              var loadingState = latestRequest[1].loadingState;
              if (typeof loadingState === "object") {
                if (withoutRefetchOnError) {
                  
                } else {
                  Curry._1($$fetch$1, undefined);
                }
              } else if (loadingState === "success" && Difference_in_seconds(new Date(), new Date(latestRequest[0].timestamp)) >= 300.0) {
                Curry._1($$fetch$1, undefined);
              }
              
            } else {
              Curry._1($$fetch$1, undefined);
            }
          }
          
        }), [
        schemaId,
        latestActionId
      ]);
  var updateOnModelChange = Curry._2(ZustandStore.use, store, (function (state) {
          return state.updateOnModelChange;
        }));
  React.useEffect((function () {
          if (latestRequest !== undefined) {
            var match = latestRequest[1].loadingState;
            var exit = 0;
            if (typeof match === "object") {
              if (match.NAME === "error") {
                exit = 1;
              }
              
            } else if (match === "success") {
              exit = 1;
            }
            if (exit === 1) {
              if (latestActionId !== undefined) {
                Curry._2(updateOnModelChange, latestRequest[0], latestActionId);
              }
              
            }
            
          }
          
        }), [latestActionId]);
  return {
          loadingState: match$3[0],
          issues: match$3[1],
          issuesById: match$3[2]
        };
}

function useIssue(id) {
  var match = React.useState(function () {
        return "initial";
      });
  var setIssue = match[1];
  var issue = match[0];
  var match$1 = useIssues(undefined, undefined);
  var issuesById = match$1.issuesById;
  var loadingState = match$1.loadingState;
  var idRef = React.useRef(id);
  React.useEffect((function () {
          idRef.current = id;
          Curry._1(setIssue, (function (param) {
                  return "initial";
                }));
          
        }), [id]);
  var maybeIssueFromList = React.useMemo((function () {
          return Belt_MapString.get(issuesById, id);
        }), [
        issuesById,
        id
      ]);
  var events = ModelStore.useEvents(undefined);
  var goals = ModelStore.useGoals(undefined);
  var sources = ModelStore.useSources(undefined);
  var modelIsLoading = ModelStore.useModelIsLoading(undefined);
  var handleIssue = function (issue) {
    if (idRef.current !== issue.issueId) {
      return ;
    }
    var eventsByNameMap = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(events, (function ($$event) {
                    return [
                            $$event.name,
                            $$event
                          ];
                  }))));
    var categoryIdsByEventIdMap = Belt_List.reduce(goals, undefined, (function (map, param) {
            var categoryId = param.id;
            return Belt_List.reduce(param.events, map, (function (map, eventId) {
                          return Belt_MapString.update(map, eventId, (function (maybeItem) {
                                        if (maybeItem !== undefined) {
                                          return Belt_Array.concat([categoryId], maybeItem);
                                        } else {
                                          return [categoryId];
                                        }
                                      }));
                        }));
          }));
    var sourceNamesByIdMap = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(sources, (function (param) {
                    return [
                            param.id,
                            Belt_Option.getWithDefault(param.name, "Untitled source")
                          ];
                  }))));
    return Curry._1(setIssue, (function (param) {
                  return {
                          NAME: "success",
                          VAL: InspectorIssueViewModel.Utils.fromIssue(categoryIdsByEventIdMap, eventsByNameMap, sourceNamesByIdMap, issue)
                        };
                }));
  };
  var handleError = React.useCallback((function (error) {
          if (error.RE_EXN_ID === InspectorViewApi.ClientError && error._1 === 404) {
            return $$Promise.$$catch(InspectorViewApi.getRelatedIssues(id).then(function (issues) {
                            return Curry._1(setIssue, (function (param) {
                                          var match = Belt_Array.get(issues, 0);
                                          if (match !== undefined) {
                                            return {
                                                    NAME: "replaceSharedId",
                                                    VAL: match.issueId
                                                  };
                                          } else {
                                            return {
                                                    NAME: "error",
                                                    VAL: /* NotFound */1
                                                  };
                                          }
                                        }));
                          }), (function (param) {
                          Curry._1(setIssue, (function (param) {
                                  return {
                                          NAME: "error",
                                          VAL: /* NotFound */1
                                        };
                                }));
                          return Promise.resolve(undefined);
                        }));
          }
          Curry._1(setIssue, (function (param) {
                  return {
                          NAME: "error",
                          VAL: /* Internal */0
                        };
                }));
          return Promise.resolve(undefined);
        }), [id]);
  React.useEffect((function () {
          if (maybeIssueFromList === undefined) {
            var exit = 0;
            if (typeof loadingState === "object" || loadingState === "success") {
              exit = 1;
            }
            if (exit === 1) {
              if (!modelIsLoading) {
                Curry._1(setIssue, (function (param) {
                        return "initial";
                      }));
                $$Promise.$$catch(InspectorViewApi.getIssue(id).then(handleIssue), handleError);
              }
              
            }
            
          }
          
        }), [
        id,
        modelIsLoading,
        loadingState
      ]);
  if (typeof issue === "object" && issue.NAME === "success") {
    return {
            NAME: "success",
            VAL: issue.VAL
          };
  }
  if (maybeIssueFromList !== undefined) {
    return {
            NAME: "success",
            VAL: maybeIssueFromList
          };
  } else if (typeof issue === "object") {
    if (issue.NAME === "replaceSharedId") {
      return {
              NAME: "replaceSharedId",
              VAL: issue.VAL
            };
    } else if (loadingState === "loading") {
      return "loading";
    } else {
      return "notFound";
    }
  } else if (issue === "loading" || issue === "initial" || loadingState === "loading") {
    return "loading";
  } else {
    return "notFound";
  }
}

function useAppVersionsInSyncWithIssues(takeLast, param) {
  var latestTimestamp = Belt_Option.map(useLatestConfig(undefined), (function (config) {
          return config.timestamp;
        }));
  return AppVersionsStore.use(latestTimestamp, takeLast, undefined);
}

function useFilteredIssues(withoutRefetchOnErrorOpt, param) {
  var withoutRefetchOnError = withoutRefetchOnErrorOpt !== undefined ? withoutRefetchOnErrorOpt : false;
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var filters = match.filters;
  var match$1 = useIssues(withoutRefetchOnError, undefined);
  var issues = match$1.issues;
  var match$2 = useAppVersionsInSyncWithIssues(3, undefined);
  var appVersions = match$2[0];
  var filteredIssues = React.useMemo((function () {
          if (filters.length !== 0) {
            return InspectorIssuesFilterUtils.filterIssueViewModels(undefined, appVersions, issues, filters);
          } else {
            return issues;
          }
        }), [
        issues,
        filters,
        appVersions
      ]);
  return {
          loadingState: match$1.loadingState,
          issues: issues,
          filteredIssues: filteredIssues
        };
}

var IssueMap;

exports.FetchError = FetchError;
exports.IssueMap = IssueMap;
exports.$$Request = $$Request;
exports.StoreConfig = StoreConfig;
exports.ZustandStore = ZustandStore;
exports.makeNewRequestConfig = makeNewRequestConfig;
exports.updateLoadingState = updateLoadingState;
exports.getOrderedRequestConfigsForSchemaId = getOrderedRequestConfigsForSchemaId;
exports.getLatestRequestForSchemaId = getLatestRequestForSchemaId;
exports.getLatestSuccessfulRequestForSchemaId = getLatestSuccessfulRequestForSchemaId;
exports.store = store;
exports.useLatestConfig = useLatestConfig;
exports.useIssues = useIssues;
exports.useIssue = useIssue;
exports.useAppVersionsInSyncWithIssues = useAppVersionsInSyncWithIssues;
exports.useFilteredIssues = useFilteredIssues;
/* RequestComparator Not a pure module */
