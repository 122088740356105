// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Actions = require("./actions.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Shortid = require("shortid");
var AvoModel = require("./avoModel.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateTypes = require("./StateTypes.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var RouterStore = require("./RouterStore.bs.js");
var QueryString = require("query-string");
var ViewerContext = require("./ViewerContext.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var FilterQueryParams = require("./FilterQueryParams.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var GlobalStateContext = require("./GlobalStateContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");

var PermissionsException = /* @__PURE__ */Caml_exceptions.create("AvoState.PermissionsException");

function computeCurrentModelFromActions(model, actions) {
  return Belt_List.reduceU(actions, model, (function (nextModel, action) {
                return Actions.reduce(nextModel, action.contents);
              }));
}

function wantsToCommentOnBranch(actions) {
  if (actions.length !== 1) {
    return false;
  }
  var match = actions[0];
  var match$1 = match[0];
  if (typeof match$1 === "object") {
    return match$1.NAME === "BranchComment";
  } else {
    return false;
  }
}

function wantsToComment(actions) {
  if (actions.length !== 1) {
    return false;
  }
  var match = actions[0];
  var match$1 = match[0];
  if (typeof match$1 !== "object") {
    return false;
  }
  var variant = match$1.NAME;
  if (variant === "PropertyComment" || variant === "GoalComment" || variant === "IntegrationComment" || variant === "MetricComment" || variant === "PropertyGroupComment" || variant === "EventComment") {
    return true;
  } else {
    return variant === "BranchComment";
  }
}

function wantsToImport(actions) {
  if (actions.length !== 1) {
    return false;
  }
  var match = actions[0];
  var match$1 = match[0];
  if (typeof match$1 !== "object") {
    return match$1 === "StartedImport";
  }
  var variant = match$1.NAME;
  if (variant === "CompletedImport") {
    return true;
  } else {
    return variant === "ImportDeprecated";
  }
}

function AvoState(Props) {
  var schema = Props.schema;
  var model = Props.model;
  var masterModel = Props.masterModel;
  var latestAction = Props.latestAction;
  var latestMasterAction = Props.latestMasterAction;
  var numberOfActions = Props.numberOfActions;
  var numberOfMasterActions = Props.numberOfMasterActions;
  var role = Props.role;
  var member = Props.member;
  var branchStatus = Props.branchStatus;
  var children = Props.children;
  var settingsQuery = Props.settingsQuery;
  var removeSettingsQueryParam = settingsQuery.remove;
  var settingsQueryParamValue = settingsQuery.value;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var match = ViewerContext.use(undefined);
  var userId = match.id;
  var schemaId = schema.id;
  var branch = RouterStore.Schema.useBranch(undefined);
  var currentBranch = typeof branch === "object" ? ({
        NAME: "Branch",
        VAL: branch.VAL
      }) : "Master";
  var sendActions = function (masterEditingEnabled, send, writeToBranchOpt, auditOpt, batch, batchSize, skipMasterEditingModalOpt, isCreateDemoBranchAutoActionOpt, onWriteStart, onSuccess, onError, actions) {
    var writeToBranch = writeToBranchOpt !== undefined ? writeToBranchOpt : currentBranch;
    var audit = auditOpt !== undefined ? auditOpt : false;
    var skipMasterEditingModal = skipMasterEditingModalOpt !== undefined ? skipMasterEditingModalOpt : false;
    var isCreateDemoBranchAutoAction = isCreateDemoBranchAutoActionOpt !== undefined ? isCreateDemoBranchAutoActionOpt : false;
    var writeActions = function (toBranchOpt, actionsOpt, param) {
      var toBranch = toBranchOpt !== undefined ? toBranchOpt : NamedBranch.fromUnnamed(masterModel, writeToBranch);
      var actions$1 = actionsOpt !== undefined ? actionsOpt : actions;
      Belt_Option.forEach(onWriteStart, (function (cb) {
              return Curry._1(cb, toBranch);
            }));
      $$Promise.$$catch(Actions.writeToFirebase(userId, schemaId, NamedBranch.toUnnamed(toBranch), isCreateDemoBranchAutoAction, numberOfActions, numberOfMasterActions, latestAction, latestMasterAction, model, masterModel, audit, batch, batchSize, actions$1).then(function (param) {
                Belt_Option.forEach(onSuccess, (function (cb) {
                        return Curry._1(cb, toBranch);
                      }));
                return Promise.resolve(undefined);
              }), (function (e) {
              Belt_Option.forEach(onError, (function (cb) {
                      return Curry._1(cb, e);
                    }));
              return Promise.reject(e);
            }));
      
    };
    var workspaceLimits = AvoLimits.computeLimits(workspace, model);
    var match = AvoLimits.User.entityStatus(workspaceLimits, userId);
    var protectedMainBranch = workspace.settings.protectedMainBranch && role !== "Admin";
    var showNoPermissionsAlert = function (param) {
      return Curry._1(send, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "AlertModal",
                    VAL: [
                      "Workspace permissions",
                      "You don't have permission to edit this workspace.",
                      "OK",
                      (function (param) {
                          
                        })
                    ]
                  }
                });
    };
    var match$1 = masterEditingEnabled || skipMasterEditingModal;
    var exit = 0;
    var exit$1 = 0;
    var exit$2 = 0;
    var exit$3 = 0;
    if (match[0] === "Unavailable") {
      return Curry._1(send, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "BillingPrompt",
                    VAL: "EditingDisabled"
                  }
                });
    }
    if (wantsToCommentOnBranch(actions) && Models.Role.canComment(role)) {
      return writeActions(undefined, undefined, undefined);
    }
    if (typeof branchStatus === "number") {
      exit$2 = 4;
    } else {
      switch (branchStatus.TAG | 0) {
        case /* Merged */1 :
        case /* Closed */2 :
            exit$3 = 5;
            break;
        default:
          exit$2 = 4;
      }
    }
    if (exit$3 === 5) {
      if (wantsToComment(actions) && Models.Role.canComment(role)) {
        return Curry._1(send, {
                    TAG: /* OpenModal */4,
                    _0: {
                      NAME: "LockedBranch",
                      VAL: branchStatus
                    }
                  });
      }
      exit$2 = 4;
    }
    if (exit$2 === 4) {
      if (wantsToComment(actions) && Models.Role.canComment(role)) {
        return writeActions(undefined, undefined, undefined);
      }
      if (typeof branchStatus === "number") {
        exit$1 = 3;
      } else {
        switch (branchStatus.TAG | 0) {
          case /* Merged */1 :
          case /* Closed */2 :
              return Curry._1(send, {
                          TAG: /* OpenModal */4,
                          _0: {
                            NAME: "LockedBranch",
                            VAL: branchStatus
                          }
                        });
          default:
            exit$1 = 3;
        }
      }
    }
    if (exit$1 === 3 && writeToBranch === "Master") {
      if (Actions.wantsToWriteBranchLifecycleActionToMain(actions) && Models.Role.canEdit(role)) {
        return writeActions(undefined, undefined, undefined);
      }
      if (wantsToImport(actions) && Models.Role.canEdit(role)) {
        return writeActions(undefined, undefined, undefined);
      }
      if (wantsToComment(actions) && Models.Role.canComment(role)) {
        return writeActions(undefined, undefined, undefined);
      }
      if (protectedMainBranch || !match$1) {
        exit = 2;
      }
      
    }
    if (exit === 2 && Models.Role.canEdit(role)) {
      return Curry._1(send, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "MasterEditing",
                    VAL: [
                      (function (param) {
                          Curry._1(send, /* EditMaster */1);
                          return writeActions(undefined, undefined, undefined);
                        }),
                      (function (branchId, branchName) {
                          return writeActions(/* Branch */{
                                      _0: branchId,
                                      _1: branchName
                                    }, undefined, undefined);
                        })
                    ]
                  }
                });
    }
    if (Models.Role.canEdit(role)) {
      return writeActions(undefined, undefined, undefined);
    } else {
      showNoPermissionsAlert(undefined);
      return Belt_Option.forEach(onError, (function (cb) {
                    return Curry._1(cb, {
                                RE_EXN_ID: PermissionsException
                              });
                  }));
    }
  };
  var match$1 = FilterQueryParams.parseQuery(undefined);
  var querySort = match$1[7];
  var filter = member.filter;
  var filters;
  var exit = 0;
  if (filter !== undefined && filter.tags.length !== 0) {
    filters = Belt_Array.mapU(filter.tags, (function (tag) {
            return {
                    NAME: "Tag",
                    VAL: tag
                  };
          }));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    filters = Belt_Array.concatMany([
          Belt_Array.mapU(match$1[0], (function (sourceId) {
                  return {
                          NAME: "Source",
                          VAL: sourceId
                        };
                })),
          Belt_Array.mapU(match$1[1], (function (destinationId) {
                  return {
                          NAME: "Destination",
                          VAL: destinationId
                        };
                })),
          Belt_Array.mapU(match$1[2], (function (tag) {
                  return {
                          NAME: "Tag",
                          VAL: tag
                        };
                })),
          Belt_Array.mapU(match$1[3], (function (propertyGroupId) {
                  return {
                          NAME: "PropertyGroup",
                          VAL: propertyGroupId
                        };
                })),
          Belt_Array.mapU(match$1[4], (function (propertyId) {
                  return {
                          NAME: "Property",
                          VAL: propertyId
                        };
                })),
          Belt_Array.mapU(match$1[5], (function (action) {
                  return {
                          NAME: "Action",
                          VAL: action
                        };
                })),
          Belt_Array.mapU(match$1[6], (function (goalId) {
                  return {
                          NAME: "Category",
                          VAL: goalId
                        };
                }))
        ]);
  }
  var match$2 = React.useReducer((function (state, action) {
          if (typeof action === "number") {
            if (action === /* CloseSlideOver */0) {
              return {
                      filters: state.filters,
                      lastSeenEventId: state.lastSeenEventId,
                      cwd: state.cwd,
                      showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                      eventSort: state.eventSort,
                      modals: state.modals,
                      masterEditingEnabled: state.masterEditingEnabled,
                      searchEventHandler: state.searchEventHandler,
                      metricsSort: state.metricsSort,
                      writeStatus: state.writeStatus,
                      slideOver: undefined
                    };
            } else {
              return {
                      filters: state.filters,
                      lastSeenEventId: state.lastSeenEventId,
                      cwd: state.cwd,
                      showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                      eventSort: state.eventSort,
                      modals: state.modals,
                      masterEditingEnabled: true,
                      searchEventHandler: state.searchEventHandler,
                      metricsSort: state.metricsSort,
                      writeStatus: state.writeStatus,
                      slideOver: state.slideOver
                    };
            }
          }
          switch (action.TAG | 0) {
            case /* UpdateFilters */0 :
                var action$1 = action._0;
                var updatedFilters;
                if (typeof action$1 === "number") {
                  switch (action$1) {
                    case /* ClearSources */0 :
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME !== "Source";
                                } else {
                                  return true;
                                }
                              }));
                        break;
                    case /* ClearTags */1 :
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME !== "Tag";
                                } else {
                                  return true;
                                }
                              }));
                        break;
                    case /* ClearDestinations */2 :
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME !== "Destination";
                                } else {
                                  return true;
                                }
                              }));
                        break;
                    case /* ClearPropertyGroups */3 :
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME !== "PropertyGroup";
                                } else {
                                  return true;
                                }
                              }));
                        break;
                    case /* ClearProperties */4 :
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME !== "Property";
                                } else {
                                  return true;
                                }
                              }));
                        break;
                    case /* ClearActions */5 :
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME !== "Action";
                                } else {
                                  return true;
                                }
                              }));
                        break;
                    case /* ClearCategories */6 :
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME !== "Category";
                                } else {
                                  return true;
                                }
                              }));
                        break;
                    case /* Reset */7 :
                        updatedFilters = [];
                        break;
                    
                  }
                } else {
                  switch (action$1.TAG | 0) {
                    case /* SetSource */0 :
                        var sourceId = action$1._0;
                        updatedFilters = Belt_Array.someU(state.filters, (function (filter) {
                                return Caml_obj.caml_equal(filter, {
                                            NAME: "Source",
                                            VAL: sourceId
                                          });
                              })) ? state.filters : Belt_Array.concat(state.filters, [{
                                  NAME: "Source",
                                  VAL: sourceId
                                }]);
                        break;
                    case /* RemoveSource */1 :
                        var sourceId$1 = action$1._0;
                        updatedFilters = Belt_Array.keepU(state.filters, (function (sourceFilterItem) {
                                return Caml_obj.caml_notequal(sourceFilterItem, {
                                            NAME: "Source",
                                            VAL: sourceId$1
                                          });
                              }));
                        break;
                    case /* SetTag */2 :
                        var tag = action$1._0;
                        updatedFilters = Belt_Array.someU(state.filters, (function (filter) {
                                return Caml_obj.caml_equal(filter, {
                                            NAME: "Tag",
                                            VAL: tag
                                          });
                              })) ? state.filters : Belt_Array.concat(state.filters, [{
                                  NAME: "Tag",
                                  VAL: tag
                                }]);
                        break;
                    case /* RemoveTag */3 :
                        var tag$1 = action$1._0;
                        updatedFilters = Belt_Array.keepU(state.filters, (function (sourceFilterItem) {
                                return Caml_obj.caml_notequal(sourceFilterItem, {
                                            NAME: "Tag",
                                            VAL: tag$1
                                          });
                              }));
                        break;
                    case /* SetDestination */4 :
                        var destinationId = action$1._0;
                        updatedFilters = Belt_Array.someU(state.filters, (function (filter) {
                                return Caml_obj.caml_equal(filter, {
                                            NAME: "Destination",
                                            VAL: destinationId
                                          });
                              })) ? state.filters : Belt_Array.concat(state.filters, [{
                                  NAME: "Destination",
                                  VAL: destinationId
                                }]);
                        break;
                    case /* RemoveDestination */5 :
                        var destinationId$1 = action$1._0;
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                return Caml_obj.caml_notequal(filter, {
                                            NAME: "Destination",
                                            VAL: destinationId$1
                                          });
                              }));
                        break;
                    case /* SetPropertyGroup */6 :
                        var propertyGroupId = action$1._0;
                        updatedFilters = Belt_Array.someU(state.filters, (function (filter) {
                                return Caml_obj.caml_equal(filter, {
                                            NAME: "PropertyGroup",
                                            VAL: propertyGroupId
                                          });
                              })) ? state.filters : Belt_Array.concat(state.filters, [{
                                  NAME: "PropertyGroup",
                                  VAL: propertyGroupId
                                }]);
                        break;
                    case /* RemovePropertyGroup */7 :
                        var propertyGroupId$1 = action$1._0;
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                return Caml_obj.caml_notequal(filter, {
                                            NAME: "PropertyGroup",
                                            VAL: propertyGroupId$1
                                          });
                              }));
                        break;
                    case /* SetProperty */8 :
                        var propertyId = action$1._0;
                        updatedFilters = Belt_Array.someU(state.filters, (function (filter) {
                                return Caml_obj.caml_equal(filter, {
                                            NAME: "Property",
                                            VAL: propertyId
                                          });
                              })) ? state.filters : Belt_Array.concat(state.filters, [{
                                  NAME: "Property",
                                  VAL: propertyId
                                }]);
                        break;
                    case /* RemoveProperty */9 :
                        var propertyId$1 = action$1._0;
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                return Caml_obj.caml_notequal(filter, {
                                            NAME: "Property",
                                            VAL: propertyId$1
                                          });
                              }));
                        break;
                    case /* SetAction */10 :
                        var action$2 = action$1._0;
                        updatedFilters = Belt_Array.someU(state.filters, (function (filter) {
                                return Caml_obj.caml_equal(filter, {
                                            NAME: "Action",
                                            VAL: action$2
                                          });
                              })) ? state.filters : Belt_Array.concat(state.filters, [{
                                  NAME: "Action",
                                  VAL: action$2
                                }]);
                        break;
                    case /* RemoveAction */11 :
                        var action$3 = action$1._0;
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                return Caml_obj.caml_notequal(filter, {
                                            NAME: "Action",
                                            VAL: action$3
                                          });
                              }));
                        break;
                    case /* SetCategory */12 :
                        var goalId = action$1._0;
                        updatedFilters = Belt_Array.someU(state.filters, (function (filter) {
                                return Caml_obj.caml_equal(filter, {
                                            NAME: "Category",
                                            VAL: goalId
                                          });
                              })) ? state.filters : Belt_Array.concat(state.filters, [{
                                  NAME: "Category",
                                  VAL: goalId
                                }]);
                        break;
                    case /* RemoveCategory */13 :
                        var goalId$1 = action$1._0;
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                return Caml_obj.caml_notequal(filter, {
                                            NAME: "Category",
                                            VAL: goalId$1
                                          });
                              }));
                        break;
                    
                  }
                }
                var tmp;
                if (typeof action$1 === "number") {
                  switch (action$1) {
                    case /* ClearSources */0 :
                        tmp = "ClearSources";
                        break;
                    case /* ClearTags */1 :
                        tmp = "ClearTags";
                        break;
                    case /* ClearDestinations */2 :
                        tmp = "ClearDestinations";
                        break;
                    case /* ClearPropertyGroups */3 :
                        tmp = "ClearPropertyGroups";
                        break;
                    case /* ClearProperties */4 :
                        tmp = "ClearProperties";
                        break;
                    case /* ClearActions */5 :
                        tmp = "ClearActions";
                        break;
                    case /* ClearCategories */6 :
                        tmp = "ClearCategories";
                        break;
                    case /* Reset */7 :
                        tmp = "Reset";
                        break;
                    
                  }
                } else {
                  switch (action$1.TAG | 0) {
                    case /* SetSource */0 :
                        tmp = "SelectSource";
                        break;
                    case /* RemoveSource */1 :
                        tmp = "DeselectSource";
                        break;
                    case /* SetTag */2 :
                        tmp = "SelectTag";
                        break;
                    case /* RemoveTag */3 :
                        tmp = "DeselectTag";
                        break;
                    case /* SetDestination */4 :
                        tmp = "SelectDestination";
                        break;
                    case /* RemoveDestination */5 :
                        tmp = "DeselectDestination";
                        break;
                    case /* SetPropertyGroup */6 :
                        tmp = "SelectPropertyGroup";
                        break;
                    case /* RemovePropertyGroup */7 :
                        tmp = "DeselectPropertyGroup";
                        break;
                    case /* SetProperty */8 :
                        tmp = "SelectProperty";
                        break;
                    case /* RemoveProperty */9 :
                        tmp = "DeselectProperty";
                        break;
                    case /* SetAction */10 :
                        tmp = "SelectAction";
                        break;
                    case /* RemoveAction */11 :
                        tmp = "DeselectAction";
                        break;
                    case /* SetCategory */12 :
                        tmp = "SelectCategory";
                        break;
                    case /* RemoveCategory */13 :
                        tmp = "DeselectCategory";
                        break;
                    
                  }
                }
                var tmp$1;
                var exit = 0;
                if (typeof action$1 === "number") {
                  tmp$1 = "";
                } else {
                  switch (action$1.TAG | 0) {
                    case /* SetSource */0 :
                    case /* RemoveSource */1 :
                        tmp$1 = AvoConfig.getSourceNameById(model, action$1._0);
                        break;
                    case /* SetDestination */4 :
                    case /* RemoveDestination */5 :
                        tmp$1 = StateUtils.getDestinationNameById(model, action$1._0);
                        break;
                    case /* SetPropertyGroup */6 :
                    case /* RemovePropertyGroup */7 :
                        exit = 1;
                        break;
                    case /* SetProperty */8 :
                    case /* RemoveProperty */9 :
                        tmp$1 = Belt_Option.getWithDefault(StateUtils.getPropertyNameById(action$1._0, model), "");
                        break;
                    case /* SetTag */2 :
                    case /* RemoveTag */3 :
                    case /* SetAction */10 :
                    case /* RemoveAction */11 :
                        tmp$1 = action$1._0;
                        break;
                    case /* SetCategory */12 :
                    case /* RemoveCategory */13 :
                        exit = 2;
                        break;
                    
                  }
                }
                switch (exit) {
                  case 1 :
                      tmp$1 = Belt_Option.mapWithDefaultU(StateUtils.getPropertyGroupById(action$1._0, model), "", (function (param) {
                              return param.name;
                            }));
                      break;
                  case 2 :
                      tmp$1 = Belt_Option.mapWithDefaultU(StateUtils.getGoalById(action$1._0, model), "", (function (goal) {
                              return goal.name;
                            }));
                      break;
                  
                }
                AnalyticsRe.eventsFilterUpdated(schemaGroup, AnalyticsUtils.currentFilters(model, updatedFilters, state.eventSort), action._1, tmp, tmp$1, schemaGroup.branchId, schemaGroup.schemaId);
                return {
                        filters: updatedFilters,
                        lastSeenEventId: state.lastSeenEventId,
                        cwd: state.cwd,
                        showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                        eventSort: state.eventSort,
                        modals: state.modals,
                        masterEditingEnabled: state.masterEditingEnabled,
                        searchEventHandler: state.searchEventHandler,
                        metricsSort: state.metricsSort,
                        writeStatus: state.writeStatus,
                        slideOver: state.slideOver
                      };
            case /* SetLastSeenEventId */1 :
                return {
                        filters: state.filters,
                        lastSeenEventId: action._0,
                        cwd: state.cwd,
                        showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                        eventSort: state.eventSort,
                        modals: state.modals,
                        masterEditingEnabled: state.masterEditingEnabled,
                        searchEventHandler: state.searchEventHandler,
                        metricsSort: state.metricsSort,
                        writeStatus: state.writeStatus,
                        slideOver: state.slideOver
                      };
            case /* SortEvents */2 :
                return {
                        filters: state.filters,
                        lastSeenEventId: state.lastSeenEventId,
                        cwd: state.cwd,
                        showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                        eventSort: action._0,
                        modals: state.modals,
                        masterEditingEnabled: state.masterEditingEnabled,
                        searchEventHandler: state.searchEventHandler,
                        metricsSort: state.metricsSort,
                        writeStatus: state.writeStatus,
                        slideOver: state.slideOver
                      };
            case /* SortMetrics */3 :
                return {
                        filters: state.filters,
                        lastSeenEventId: state.lastSeenEventId,
                        cwd: state.cwd,
                        showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                        eventSort: state.eventSort,
                        modals: state.modals,
                        masterEditingEnabled: state.masterEditingEnabled,
                        searchEventHandler: state.searchEventHandler,
                        metricsSort: action._0,
                        writeStatus: state.writeStatus,
                        slideOver: state.slideOver
                      };
            case /* OpenModal */4 :
                return {
                        filters: state.filters,
                        lastSeenEventId: state.lastSeenEventId,
                        cwd: state.cwd,
                        showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                        eventSort: state.eventSort,
                        modals: Belt_List.add(state.modals, {
                              id: Shortid(),
                              modal: action._0
                            }),
                        masterEditingEnabled: state.masterEditingEnabled,
                        searchEventHandler: state.searchEventHandler,
                        metricsSort: state.metricsSort,
                        writeStatus: state.writeStatus,
                        slideOver: state.slideOver
                      };
            case /* CloseModal */5 :
                var maybeModalId = action._0;
                return {
                        filters: state.filters,
                        lastSeenEventId: state.lastSeenEventId,
                        cwd: state.cwd,
                        showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                        eventSort: state.eventSort,
                        modals: maybeModalId !== undefined ? Belt_List.keep(state.modals, (function (modal) {
                                  return modal.id !== maybeModalId;
                                })) : Belt_Option.getWithDefault(Belt_List.tail(state.modals), /* [] */0),
                        masterEditingEnabled: state.masterEditingEnabled,
                        searchEventHandler: state.searchEventHandler,
                        metricsSort: state.metricsSort,
                        writeStatus: state.writeStatus,
                        slideOver: state.slideOver
                      };
            case /* OpenSlideOver */6 :
                return {
                        filters: state.filters,
                        lastSeenEventId: state.lastSeenEventId,
                        cwd: state.cwd,
                        showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                        eventSort: state.eventSort,
                        modals: state.modals,
                        masterEditingEnabled: state.masterEditingEnabled,
                        searchEventHandler: state.searchEventHandler,
                        metricsSort: state.metricsSort,
                        writeStatus: state.writeStatus,
                        slideOver: action._0
                      };
            
          }
        }), (filters.length !== 0 || querySort !== "NoSorting" ? (AnalyticsRe.filterLinkOpened(schemaGroup, AnalyticsUtils.currentFilters(model, filters, querySort), schemaGroup.branchId, schemaGroup.schemaId), undefined) : undefined, {
          filters: filters,
          lastSeenEventId: undefined,
          cwd: undefined,
          showCodegenSourcePickerModal: undefined,
          eventSort: querySort,
          modals: /* [] */0,
          masterEditingEnabled: false,
          searchEventHandler: {
            contents: undefined
          },
          metricsSort: "NoSorting",
          writeStatus: "Initial",
          slideOver: undefined
        }));
  var send = match$2[1];
  var state = match$2[0];
  var prevEventsSort = Hooks.useRetained(state.eventSort);
  React.useEffect((function () {
          if (state.eventSort !== prevEventsSort) {
            AnalyticsRe.eventListSorted(schemaGroup, prevEventsSort, state.eventSort, schemaGroup.branchId, schemaGroup.schemaId);
          }
          
        }), [state.eventSort]);
  React.useEffect((function () {
          if (settingsQueryParamValue !== undefined) {
            switch (settingsQueryParamValue) {
              case "danger-zone" :
                  Curry._1(send, {
                        TAG: /* OpenModal */4,
                        _0: {
                          NAME: "Settings",
                          VAL: /* DangerZone */3
                        }
                      });
                  break;
              case "permissions" :
                  Curry._1(send, {
                        TAG: /* OpenModal */4,
                        _0: {
                          NAME: "Settings",
                          VAL: /* Permissions */1
                        }
                      });
                  break;
              case "service-accounts" :
                  Curry._1(send, {
                        TAG: /* OpenModal */4,
                        _0: {
                          NAME: "Settings",
                          VAL: /* ServiceAccounts */4
                        }
                      });
                  break;
              case "slack-integration" :
                  Curry._1(send, {
                        TAG: /* OpenModal */4,
                        _0: {
                          NAME: "Settings",
                          VAL: /* Notifications */2
                        }
                      });
                  break;
              default:
                Curry._1(send, {
                      TAG: /* OpenModal */4,
                      _0: {
                        NAME: "Settings",
                        VAL: undefined
                      }
                    });
            }
          }
          if (settingsQueryParamValue !== undefined) {
            Curry._1(removeSettingsQueryParam, undefined);
          }
          
        }), [settingsQueryParamValue]);
  React.useEffect((function () {
          var query = Router.getSearch(undefined).replace("?", "");
          var maybeNewQuery = QueryString.parse(query).new;
          if (!(maybeNewQuery == null)) {
            switch (maybeNewQuery) {
              case "branch" :
                  Router.Schema.pushSchemaRoute(undefined, undefined, "events");
                  Curry._1(send, {
                        TAG: /* OpenModal */4,
                        _0: {
                          NAME: "NewBranch",
                          VAL: [
                            undefined,
                            ""
                          ]
                        }
                      });
                  break;
              case "event" :
                  Router.Schema.pushSchemaRoute(undefined, undefined, "events");
                  Curry._1(send, {
                        TAG: /* OpenModal */4,
                        _0: {
                          NAME: "NewEvent",
                          VAL: [
                            "",
                            (function (eventId, _name) {
                                return Router.Schema.pushDrawerItem(undefined, {
                                            NAME: "event",
                                            VAL: [
                                              eventId,
                                              undefined,
                                              undefined,
                                              false
                                            ]
                                          });
                              }),
                            "NewQueryParameter"
                          ]
                        }
                      });
                  break;
              case "metric" :
                  Router.Schema.pushSchemaRoute(undefined, undefined, "metrics");
                  var metricId = Shortid();
                  sendActions(state.masterEditingEnabled, send, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (branch) {
                          Router.Schema.pushDrawerItem(undefined, {
                                NAME: "metric",
                                VAL: [
                                  metricId,
                                  undefined
                                ]
                              });
                          return AnalyticsRe.metricInitiated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), metricId, "", NamedBranch.getId(branch), schemaGroup.schemaId);
                        }), undefined, [[
                          {
                            NAME: "AddMetric",
                            VAL: metricId
                          },
                          {
                            metricId: metricId,
                            metricQuery: metricId
                          }
                        ]]);
                  break;
              case "property" :
                  Router.Schema.pushSchemaRoute(undefined, undefined, "properties");
                  Curry._1(send, {
                        TAG: /* OpenModal */4,
                        _0: {
                          NAME: "NewProperty",
                          VAL: [
                            "",
                            /* EventProperty */1,
                            undefined,
                            undefined,
                            "NewQueryParameter"
                          ]
                        }
                      });
                  break;
              default:
                
            }
          }
          
        }), []);
  var renderModel = React.useMemo((function () {
          return AvoModel.enrichModel(AvoModel.softEnrichModel(model, AvoConfig.getValidSources(model.sources, model)), AvoConfig.getValidSources(model.sources, model));
        }), [model]);
  var partial_arg = state.masterEditingEnabled;
  var sendActions$1 = function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9) {
    return sendActions(partial_arg, send, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9);
  };
  return React.createElement(GlobalSendContext.make, {
              globalSend: send,
              children: React.createElement(GlobalStateContext.make, {
                    globalState: state,
                    children: React.createElement(SendActionsContext.make, {
                          sendActions: sendActions$1,
                          children: Curry._1(children, renderModel)
                        })
                  })
            });
}

var eventSortToJs = StateTypes.eventSortToJs;

var eventSortFromJs = StateTypes.eventSortFromJs;

var metricsSortToJs = StateTypes.metricsSortToJs;

var metricsSortFromJs = StateTypes.metricsSortFromJs;

var metricsSortOptions = StateTypes.metricsSortOptions;

var make = AvoState;

exports.eventSortToJs = eventSortToJs;
exports.eventSortFromJs = eventSortFromJs;
exports.metricsSortToJs = metricsSortToJs;
exports.metricsSortFromJs = metricsSortFromJs;
exports.metricsSortOptions = metricsSortOptions;
exports.PermissionsException = PermissionsException;
exports.computeCurrentModelFromActions = computeCurrentModelFromActions;
exports.wantsToCommentOnBranch = wantsToCommentOnBranch;
exports.wantsToComment = wantsToComment;
exports.wantsToImport = wantsToImport;
exports.make = make;
/* Hooks Not a pure module */
