'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var $$String = require("rescript/lib/js/string.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function $$char(c) {
  return $$String.make(1, c);
}

function date(d) {
  return d.toJSON();
}

function nullable(encode, v) {
  if (v !== undefined) {
    return Curry._1(encode, Caml_option.valFromOption(v));
  } else {
    return null;
  }
}

function withDefault(d, encode, v) {
  if (v !== undefined) {
    return Curry._1(encode, Caml_option.valFromOption(v));
  } else {
    return d;
  }
}

function dict(encode, d) {
  var pairs = Js_dict.entries(d);
  return Js_dict.fromArray($$Array.map((function (param) {
                    return [
                            param[0],
                            Curry._1(encode, param[1])
                          ];
                  }), pairs));
}

var object_ = Js_dict.fromList;

var array = $$Array.map;

function list(encode, l) {
  if (!l) {
    return [];
  }
  var a = Caml_array.make(List.length(l), Curry._1(encode, l.hd));
  var _i = 1;
  var _param = l.tl;
  while(true) {
    var param = _param;
    var i = _i;
    if (!param) {
      return a;
    }
    a[i] = Curry._1(encode, param.hd);
    _param = param.tl;
    _i = i + 1 | 0;
    continue ;
  };
}

function pair(encodeA, encodeB, param) {
  return [
          Curry._1(encodeA, param[0]),
          Curry._1(encodeB, param[1])
        ];
}

function tuple3(encodeA, encodeB, encodeC, param) {
  return [
          Curry._1(encodeA, param[0]),
          Curry._1(encodeB, param[1]),
          Curry._1(encodeC, param[2])
        ];
}

function tuple4(encodeA, encodeB, encodeC, encodeD, param) {
  return [
          Curry._1(encodeA, param[0]),
          Curry._1(encodeB, param[1]),
          Curry._1(encodeC, param[2]),
          Curry._1(encodeD, param[3])
        ];
}

var tuple2 = pair;

exports.$$char = $$char;
exports.date = date;
exports.nullable = nullable;
exports.withDefault = withDefault;
exports.pair = pair;
exports.tuple2 = tuple2;
exports.tuple3 = tuple3;
exports.tuple4 = tuple4;
exports.dict = dict;
exports.object_ = object_;
exports.array = array;
exports.list = list;
/* No side effect */
