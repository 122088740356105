// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

var context = React.createContext(undefined);

var provider = context.Provider;

function use(param) {
  return Belt_Option.getExn(React.useContext(context));
}

function ViewerContext(Props) {
  var user = Props.user;
  var children = Props.children;
  return React.createElement(provider, {
              value: user,
              children: children
            });
}

var make = ViewerContext;

exports.context = context;
exports.provider = provider;
exports.use = use;
exports.make = make;
/* context Not a pure module */
