// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var SheetInfoRow = require("./SheetInfoRow.bs.js");
var CreateButtonIcon = require("./emptystates/CreateButtonIcon.bs.js");
var EmptyStatesStyles = require("./emptystates/EmptyStatesStyles.bs.js");

function PropertiesSheetCreateGroupType(Props) {
  var columns = Props.columns;
  var globalSend = Props.globalSend;
  var totalColumnWidth = Belt_Array.reduceU(Belt_Array.mapU(Belt_Array.keepU(columns, (function (param) {
                  return param.visible;
                })), (function (param) {
              return param.width;
            })), 0, (function (acc, width) {
          return acc + width | 0;
        }));
  return React.createElement(SheetInfoRow.make, {
              onClick: (function (param) {
                  return Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: {
                                NAME: "NewGroupType",
                                VAL: [
                                  undefined,
                                  undefined
                                ]
                              }
                            });
                }),
              totalColumnWidth: totalColumnWidth,
              children: null
            }, React.createElement("i", {
                  className: Curry._1(Css.merge, {
                        hd: EmptyStatesStyles.iconStyles,
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.width(Css.px(32)),
                                tl: {
                                  hd: Css.height(Css.px(32)),
                                  tl: {
                                    hd: Css.margin(Css.px(0)),
                                    tl: /* [] */0
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      })
                }, CreateButtonIcon.icon), React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement($$Text.make, {
                  weight: "Semi",
                  color: Styles.Color.grey90,
                  children: "Create a new Group Type"
                }));
}

var make = PropertiesSheetCreateGroupType;

exports.make = make;
/* Css Not a pure module */
