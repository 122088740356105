// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function toAnalytics(status) {
  if (typeof status === "number") {
    return "Open";
  }
  switch (status.TAG | 0) {
    case /* Merged */1 :
        return "Merged";
    case /* Closed */2 :
        return "Deleted";
    case /* ReadyForReview */4 :
        return "ReadyForReview";
    case /* ChangesRequested */5 :
        return "ChangesRequested";
    case /* Approved */6 :
        return "Approved";
    default:
      return "Open";
  }
}

exports.toAnalytics = toAnalytics;
/* No side effect */
