// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Icon = require("./Icon.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Shortid = require("shortid");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Collapsible = require("./Collapsible.bs.js");
var ContextMenu = require("./ContextMenu.bs.js");
var FramerMotion = require("framer-motion");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");

function initial_isOpen(param) {
  return false;
}

function initial_toggleOpenState(param) {
  
}

function initial_expand(param) {
  
}

var initial = {
  isOpen: initial_isOpen,
  toggleOpenState: initial_toggleOpenState,
  expand: initial_expand,
  chevronPosition: "left",
  size: "small",
  nested: false
};

var context = React.createContext(initial);

var provider = context.Provider;

function use(param) {
  return React.useContext(context);
}

function Accordion$AccordionContext(Props) {
  var allowMultipleOpens = Props.allowMultipleOpens;
  var chevronPosition = Props.chevronPosition;
  var nestedOpt = Props.nested;
  var size = Props.size;
  var children = Props.children;
  var nested = nestedOpt !== undefined ? nestedOpt : false;
  var match = React.useState(function () {
        
      });
  var setOpenSections = match[1];
  var openSections = match[0];
  var toggleOpenState = function (id) {
    if (allowMultipleOpens) {
      return Curry._1(setOpenSections, (function (openSections) {
                    if (Belt_SetString.has(openSections, id)) {
                      return Belt_SetString.remove(openSections, id);
                    } else {
                      return Belt_SetString.add(openSections, id);
                    }
                  }));
    } else {
      return Curry._1(setOpenSections, (function (openSections) {
                    if (Belt_SetString.has(openSections, id)) {
                      return ;
                    } else {
                      return Belt_SetString.add(undefined, id);
                    }
                  }));
    }
  };
  var expand = function (id) {
    if (allowMultipleOpens) {
      return Curry._1(setOpenSections, (function (openSections) {
                    return Belt_SetString.add(openSections, id);
                  }));
    } else {
      return Curry._1(setOpenSections, (function (openSections) {
                    if (Belt_SetString.has(openSections, id)) {
                      return openSections;
                    } else {
                      return Belt_SetString.add(undefined, id);
                    }
                  }));
    }
  };
  var isOpen = function (id) {
    return Belt_SetString.has(openSections, id);
  };
  return React.createElement(provider, {
              value: {
                isOpen: isOpen,
                toggleOpenState: toggleOpenState,
                expand: expand,
                chevronPosition: chevronPosition,
                size: size,
                nested: nested
              },
              children: children
            });
}

var AccordionContext = {
  initialChevronPosition: "left",
  initialSize: "small",
  initial: initial,
  context: context,
  provider: provider,
  use: use,
  make: Accordion$AccordionContext
};

function initial_onToggle(param) {
  
}

function initial_registerItemId(param) {
  
}

var initial$1 = {
  isOpen: false,
  onToggle: initial_onToggle,
  id: "",
  registerItemId: initial_registerItemId,
  registeredItemIds: undefined
};

var context$1 = React.createContext(initial$1);

var provider$1 = context$1.Provider;

function use$1(param) {
  return React.useContext(context$1);
}

function Accordion$AccordionItemContext(Props) {
  var id = Props.id;
  var initiallyExpanded = Props.initiallyExpanded;
  var disabled = Props.disabled;
  var onDisabledClick = Props.onDisabledClick;
  var children = Props.children;
  var match = React.useContext(context);
  var expand = match.expand;
  var toggleOpenState = match.toggleOpenState;
  var match$1 = React.useState(function () {
        
      });
  var setRegisteredItemIds = match$1[1];
  React.useEffect((function () {
          if (initiallyExpanded && !disabled) {
            Curry._1(expand, id);
          }
          
        }), []);
  var registerItemId = function (id) {
    return Curry._1(setRegisteredItemIds, (function (currentIds) {
                  return Belt_SetString.add(currentIds, id);
                }));
  };
  return React.createElement(provider$1, {
              value: {
                isOpen: Curry._1(match.isOpen, id),
                onToggle: (function (param) {
                    if (disabled) {
                      return Belt_Option.forEach(onDisabledClick, (function (cb) {
                                    return Curry._1(cb, undefined);
                                  }));
                    } else {
                      return Curry._1(toggleOpenState, id);
                    }
                  }),
                id: id,
                registerItemId: registerItemId,
                registeredItemIds: match$1[0]
              },
              children: children
            });
}

var AccordionItemContext = {
  initial: initial$1,
  context: context$1,
  provider: provider$1,
  use: use$1,
  make: Accordion$AccordionItemContext
};

var chevronContainer = Curry._1(Css.style, {
      hd: Css.hover({
            hd: Css.cursor("pointer"),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

function Accordion$OpeningIcon(Props) {
  var iconTypeOpt = Props.iconType;
  var sizeOpt = Props.size;
  var positionOpt = Props.position;
  var isOpen = Props.isOpen;
  var iconType = iconTypeOpt !== undefined ? iconTypeOpt : "chevronRight";
  var size = sizeOpt !== undefined ? sizeOpt : "small";
  var position = positionOpt !== undefined ? positionOpt : "left";
  var duration = Styles.Duration.$$short / 1000.0;
  return React.createElement(FramerMotion.AnimatePresence, {
              initial: false,
              children: position === "right" ? React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginLeft(Css.px(size === "small" ? 20 : 16)),
                            tl: {
                              hd: size === "small" ? Css.marginRight(Css.px(4)) : Styles.emptyStyle,
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(FramerMotion.motion.div, {
                          animate: isOpen ? "expanded" : "collapsed",
                          transition: {
                            duration: duration
                          },
                          initial: "collapsed",
                          variants: {
                            expanded: {
                              rotateZ: 90
                            },
                            collapsed: {
                              rotateZ: 0
                            }
                          },
                          className: chevronContainer,
                          key: "openingIcon",
                          children: React.createElement(Icon.make, {
                                type_: iconType,
                                size: size,
                                color: Styles.Color.grey60
                              })
                        })) : React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginRight(Css.px(4)),
                            tl: {
                              hd: Css.marginLeft(Css.px(size === "small" ? -2 : -6)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(FramerMotion.motion.div, {
                          animate: isOpen ? "expanded" : "collapsed",
                          transition: {
                            duration: duration
                          },
                          initial: "collapsed",
                          variants: {
                            expanded: {
                              rotateZ: 90
                            },
                            collapsed: {
                              rotateZ: 0
                            }
                          },
                          className: chevronContainer,
                          key: "openingIcon",
                          children: React.createElement(Icon.make, {
                                type_: iconType,
                                size: size,
                                color: Styles.Color.grey60
                              })
                        }))
            });
}

var OpeningIcon = {
  chevronContainer: chevronContainer,
  make: Accordion$OpeningIcon
};

function container(theme, mayExpand) {
  return Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.transition({
                      NAME: "ms",
                      VAL: Styles.Duration.$$short
                    }, undefined, undefined, "background-color"),
                tl: {
                  hd: Css.backgroundColor(theme === "yellow" ? Styles.Color.setAlpha(Styles.Color.orange, 0.1) : (
                          theme === "green" ? Styles.Color.green10 : (
                              theme === "default" ? Styles.Color.grey20 : Styles.Color.white
                            )
                        )),
                  tl: {
                    hd: mayExpand ? Css.hover({
                            hd: Css.backgroundColor(theme === "yellow" ? Styles.Color.setAlpha(Styles.Color.orange, 0.2) : (
                                    theme === "green" ? Styles.Color.setAlpha(Styles.Color.green70, 0.3) : (
                                        theme === "default" ? Styles.Color.grey30 : Styles.Color.grey10
                                      )
                                  )),
                            tl: /* [] */0
                          }) : Styles.emptyStyle,
                    tl: /* [] */0
                  }
                }
              }
            });
}

function header(size, nested, mayExpand) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: mayExpand ? Css.cursor("pointer") : Css.cursor("default"),
                        tl: {
                          hd: Css.paddingLeft(Css.px(12)),
                          tl: {
                            hd: Css.paddingRight(Css.px(16)),
                            tl: {
                              hd: Css.paddingBottom(Css.px(nested || size !== "small" ? 16 : 10)),
                              tl: {
                                hd: Css.paddingTop(Css.px(nested || size !== "small" ? 16 : 10)),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("row"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.textAlign("left"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var icon = Curry._1(Css.style, {
      hd: Css.height(Css.pct(100.0)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

function extraItems(chevronPosition) {
  return Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.height(Css.pct(100.0)),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.gap(Css.px(4)),
                      tl: {
                        hd: Css.top(Css.px(0)),
                        tl: {
                          hd: Css.right(Css.px(chevronPosition === "right" ? 52 : 16)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Style = {
  container: container,
  header: header,
  icon: icon,
  extraItems: extraItems
};

var AriaLabelMissingWhenUsingTitleComponentException = /* @__PURE__ */Caml_exceptions.create("Accordion.ItemHeader.AriaLabelMissingWhenUsingTitleComponentException");

function Accordion$ItemHeader(Props) {
  var title = Props.title;
  var ariaLabel = Props.ariaLabel;
  var icon$1 = Props.icon;
  var themeOpt = Props.theme;
  var subtitle = Props.subtitle;
  var menuItems = Props.menuItems;
  var extra = Props.extra;
  var theme = themeOpt !== undefined ? themeOpt : "default";
  var match = React.useContext(context$1);
  var id = match.id;
  var onToggle = match.onToggle;
  var isOpen = match.isOpen;
  var match$1 = React.useContext(context);
  var nested = match$1.nested;
  var size = match$1.size;
  var chevronPosition = match$1.chevronPosition;
  var mayExpand = Belt_SetString.size(match.registeredItemIds) > 0;
  var iconSize = nested ? "small" : (
      size === "small" ? "medium" : "large"
    );
  var tmp;
  if (title.NAME === "Component") {
    if (ariaLabel !== undefined) {
      tmp = ariaLabel;
    } else {
      throw {
            RE_EXN_ID: AriaLabelMissingWhenUsingTitleComponentException,
            Error: new Error()
          };
    }
  } else {
    tmp = title.VAL;
  }
  return React.createElement("div", {
              className: container(theme, mayExpand),
              id: id + "-accordion-item-header"
            }, React.createElement("button", {
                  "aria-label": tmp,
                  "aria-expanded": isOpen,
                  "aria-controls": id + "-accordion-collapsible",
                  className: header(size, nested, mayExpand),
                  onClick: (function (e) {
                      if (mayExpand) {
                        return Curry._1(onToggle, e);
                      }
                      
                    })
                }, chevronPosition === "left" && mayExpand ? React.createElement(Accordion$OpeningIcon, {
                        iconType: "chevronRight",
                        size: iconSize,
                        position: "left",
                        isOpen: isOpen
                      }) : null, icon$1 !== undefined ? React.createElement("div", {
                        className: icon
                      }, Caml_option.valFromOption(icon$1), React.createElement(Spacer.make, {
                            width: 10
                          })) : null, React.createElement("div", undefined, title.NAME === "Component" ? title.VAL : React.createElement($$Text.make, {
                            size: nested ? "Small" : "Medium",
                            weight: "Semi",
                            color: Styles.Color.grey90,
                            children: title.VAL
                          }), subtitle !== undefined ? (
                        subtitle.NAME === "Component" ? subtitle.VAL : React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Regular",
                                color: Styles.Color.grey80,
                                children: subtitle.VAL
                              })
                      ) : null), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), chevronPosition === "right" && mayExpand ? React.createElement(Accordion$OpeningIcon, {
                        iconType: "chevronRight",
                        size: iconSize,
                        position: "right",
                        isOpen: isOpen
                      }) : null), React.createElement("div", {
                  className: extraItems(chevronPosition)
                }, Belt_Option.isSome(extra) || Belt_Option.isSome(menuItems) ? React.createElement("div", undefined, React.createElement(Spacer.make, {
                            width: 8,
                            grow: 1.0
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("row"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, extra !== undefined ? React.createElement("div", {
                                  onClick: (function (prim) {
                                      prim.stopPropagation();
                                      
                                    })
                                }, Caml_option.valFromOption(extra)) : null)) : null, menuItems !== undefined && menuItems.length !== 0 ? React.createElement("div", {
                        onClick: (function (prim) {
                            prim.stopPropagation();
                            
                          })
                      }, React.createElement(ContextMenu.make, {
                            options: menuItems
                          })) : null));
}

var ItemHeader = {
  Style: Style,
  AriaLabelMissingWhenUsingTitleComponentException: AriaLabelMissingWhenUsingTitleComponentException,
  make: Accordion$ItemHeader
};

function Accordion$ItemCollapsible(Props) {
  var onChange = Props.onChange;
  var children = Props.children;
  var match = React.useContext(context$1);
  var registerItemId = match.registerItemId;
  var id = match.id;
  var isOpen = match.isOpen;
  React.useEffect((function () {
          Curry._1(registerItemId, id);
          
        }), []);
  return React.createElement(Collapsible.make, {
              id: id + "-accordion-collapsible",
              collapsed: !isOpen,
              loadingStyle: "lazy",
              onChange: (function (param) {
                  return Belt_Option.forEach(onChange, (function (cb) {
                                return Curry._1(cb, isOpen);
                              }));
                }),
              children: children
            });
}

var ItemCollapsible = {
  make: Accordion$ItemCollapsible
};

function Accordion$Item(Props) {
  var initiallyExpandedOpt = Props.initiallyExpanded;
  var disabledOpt = Props.disabled;
  var onDisabledClick = Props.onDisabledClick;
  var children = Props.children;
  var initiallyExpanded = initiallyExpandedOpt !== undefined ? initiallyExpandedOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var id = React.useMemo((function () {
          return Shortid();
        }), []);
  var tmp = {
    id: id,
    initiallyExpanded: initiallyExpanded,
    disabled: disabled,
    children: React.createElement("li", undefined, children)
  };
  if (onDisabledClick !== undefined) {
    tmp.onDisabledClick = Caml_option.valFromOption(onDisabledClick);
  }
  return React.createElement(Accordion$AccordionItemContext, tmp);
}

var Item = {
  make: Accordion$Item
};

var resetUnorderedList = Curry._1(Css.style, {
      hd: Css.listStyleType("none"),
      tl: {
        hd: Css.textIndent(Css.px(0)),
        tl: {
          hd: Css.padding("zero"),
          tl: {
            hd: Css.margin("zero"),
            tl: /* [] */0
          }
        }
      }
    });

var accordionItems = Curry._1(Css.style, {
      hd: Css.children({
            hd: Css.overflow("hidden"),
            tl: {
              hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
              tl: {
                hd: Css.borderBottomWidth(Css.px(0)),
                tl: {
                  hd: Css.firstChild({
                        hd: Css.borderTopLeftRadius(Css.px(5)),
                        tl: {
                          hd: Css.borderTopRightRadius(Css.px(5)),
                          tl: /* [] */0
                        }
                      }),
                  tl: {
                    hd: Css.lastChild({
                          hd: Css.borderBottomLeftRadius(Css.px(5)),
                          tl: {
                            hd: Css.borderBottomRightRadius(Css.px(5)),
                            tl: {
                              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                              tl: /* [] */0
                            }
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }),
      tl: /* [] */0
    });

var nestedAccordionItems = Curry._1(Css.style, {
      hd: Css.children({
            hd: Css.borderWidth("zero"),
            tl: {
              hd: Css.firstChild({
                    hd: Css.borderTopLeftRadius("zero"),
                    tl: {
                      hd: Css.borderTopRightRadius("zero"),
                      tl: /* [] */0
                    }
                  }),
              tl: {
                hd: Css.lastChild({
                      hd: Css.borderBottomLeftRadius("zero"),
                      tl: {
                        hd: Css.borderBottomRightRadius("zero"),
                        tl: {
                          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
                          tl: /* [] */0
                        }
                      }
                    }),
                tl: /* [] */0
              }
            }
          }),
      tl: /* [] */0
    });

var accordion = Curry._1(Css.merge, {
      hd: resetUnorderedList,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.backgroundColor(Styles.Color.white),
              tl: /* [] */0
            }),
        tl: {
          hd: accordionItems,
          tl: /* [] */0
        }
      }
    });

var nestedAccordion = Curry._1(Css.merge, {
      hd: resetUnorderedList,
      tl: {
        hd: accordion,
        tl: {
          hd: nestedAccordionItems,
          tl: /* [] */0
        }
      }
    });

var Style$1 = {
  resetUnorderedList: resetUnorderedList,
  accordionItems: accordionItems,
  nestedAccordionItems: nestedAccordionItems,
  accordion: accordion,
  nestedAccordion: nestedAccordion
};

function Accordion$Nested(Props) {
  var allowMultipleOpensOpt = Props.allowMultipleOpens;
  var chevronPositionOpt = Props.chevronPosition;
  var children = Props.children;
  var allowMultipleOpens = allowMultipleOpensOpt !== undefined ? allowMultipleOpensOpt : true;
  var chevronPosition = chevronPositionOpt !== undefined ? chevronPositionOpt : "left";
  return React.createElement(Accordion$AccordionContext, {
              allowMultipleOpens: allowMultipleOpens,
              chevronPosition: chevronPosition,
              nested: true,
              size: "small",
              children: React.createElement("ul", {
                    className: nestedAccordion
                  }, children)
            });
}

var Nested = {
  make: Accordion$Nested
};

function Accordion(Props) {
  var allowMultipleOpensOpt = Props.allowMultipleOpens;
  var sizeOpt = Props.size;
  var chevronPositionOpt = Props.chevronPosition;
  var children = Props.children;
  var allowMultipleOpens = allowMultipleOpensOpt !== undefined ? allowMultipleOpensOpt : false;
  var size = sizeOpt !== undefined ? sizeOpt : "small";
  var chevronPosition = chevronPositionOpt !== undefined ? chevronPositionOpt : "left";
  return React.createElement(Accordion$AccordionContext, {
              allowMultipleOpens: allowMultipleOpens,
              chevronPosition: chevronPosition,
              size: size,
              children: React.createElement("ul", {
                    className: accordion
                  }, children)
            });
}

var make = Accordion;

exports.AccordionContext = AccordionContext;
exports.AccordionItemContext = AccordionItemContext;
exports.OpeningIcon = OpeningIcon;
exports.ItemHeader = ItemHeader;
exports.ItemCollapsible = ItemCollapsible;
exports.Item = Item;
exports.Style = Style$1;
exports.Nested = Nested;
exports.make = make;
/* context Not a pure module */
