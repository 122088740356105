// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function styles(maxWidth) {
  return Curry._1(Css.style, {
              hd: Css.borderColor(Styles.Color.grey30),
              tl: {
                hd: Css.borderWidth(Css.px(1)),
                tl: {
                  hd: Css.borderRadius(Css.px(4)),
                  tl: {
                    hd: Css.borderStyle("solid"),
                    tl: {
                      hd: Css.paddingTop(Css.px(5)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(4)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(8)),
                          tl: {
                            hd: Css.paddingRight(Css.px(20)),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.medium),
                                tl: {
                                  hd: Css.color(Styles.Color.grey70),
                                  tl: {
                                    hd: Css.lineHeight(Css.pct(140)),
                                    tl: {
                                      hd: Css.backgroundColor(Styles.Color.white),
                                      tl: {
                                        hd: Css.maxWidth(maxWidth),
                                        tl: {
                                          hd: Css.backgroundImage({
                                                NAME: "url",
                                                VAL: "'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS4zIDMuNUwzLjggMWwyLjUgMi41TTEuMyA2LjVMMy44IDlsMi41LTIuNSIgc3Ryb2tlPSIjQUFCMkJEIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg=='"
                                              }),
                                          tl: {
                                            hd: Css.backgroundRepeat("noRepeat"),
                                            tl: {
                                              hd: Css.backgroundPosition({
                                                    NAME: "hv",
                                                    VAL: [
                                                      Css.pct(92),
                                                      Css.pct(50)
                                                    ]
                                                  }),
                                              tl: {
                                                hd: Css.cursor("pointer"),
                                                tl: {
                                                  hd: Css.unsafe("appearance", "none"),
                                                  tl: {
                                                    hd: Css.transition({
                                                          NAME: "ms",
                                                          VAL: Styles.Duration.$$short
                                                        }, undefined, undefined, "all"),
                                                    tl: {
                                                      hd: Css.margin(Css.px(0)),
                                                      tl: {
                                                        hd: Css.disabled({
                                                              hd: Css.borderColor(Styles.Color.grey30),
                                                              tl: {
                                                                hd: Css.backgroundColor(Styles.Color.grey10),
                                                                tl: {
                                                                  hd: Css.color(Styles.Color.grey50),
                                                                  tl: {
                                                                    hd: Css.cursor("default"),
                                                                    tl: {
                                                                      hd: Css.opacity(1),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }),
                                                        tl: {
                                                          hd: Css.hover({
                                                                hd: Css.color(Styles.Color.grey90),
                                                                tl: {
                                                                  hd: Css.borderColor(Styles.Color.grey40),
                                                                  tl: /* [] */0
                                                                }
                                                              }),
                                                          tl: {
                                                            hd: Css.active({
                                                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: {
                                                              hd: Css.focus({
                                                                    hd: Css.outlineStyle("none"),
                                                                    tl: {
                                                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.blue)),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function Select(Props) {
  var additionalStylesOpt = Props.additionalStyles;
  var disabledOpt = Props.disabled;
  var id = Props.id;
  var maxWidthOpt = Props.maxWidth;
  var onSelect = Props.onSelect;
  var options = Props.options;
  var value = Props.value;
  var autoFocus = Props.autoFocus;
  var additionalStyles = additionalStylesOpt !== undefined ? additionalStylesOpt : /* [] */0;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var maxWidth = maxWidthOpt !== undefined ? maxWidthOpt : "none";
  var handleChange = function ($$event) {
    return Curry._1(onSelect, $$event.target.value);
  };
  var tmp = {
    className: Curry._1(Css.merge, {
          hd: Curry._1(Css.style, additionalStyles),
          tl: {
            hd: styles(maxWidth),
            tl: /* [] */0
          }
        }),
    disabled: disabled,
    value: value,
    onChange: handleChange
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (autoFocus !== undefined) {
    tmp.autoFocus = Caml_option.valFromOption(autoFocus);
  }
  return React.createElement("select", tmp, Belt_List.toArray(Belt_List.mapU(options, (function (param) {
                        var value = param[1];
                        var label = param[0];
                        return React.createElement("option", {
                                    key: value,
                                    disabled: typeof label === "object" && label.NAME === "Disabled" ? true : disabled,
                                    value: value
                                  }, label.VAL);
                      }))));
}

var make = Select;

exports.styles = styles;
exports.make = make;
/* Css Not a pure module */
