// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var FirebaseFetchers = require("./FirebaseFetchers.bs.js");

function actionSorter(a, b) {
  var a$1 = Belt_Option.mapWithDefault(Models.toDateOption(a.createdAt), Date.now(), (function (prim) {
          return prim.getTime();
        }));
  var b$1 = Belt_Option.mapWithDefault(Models.toDateOption(b.createdAt), Date.now(), (function (prim) {
          return prim.getTime();
        }));
  if (a$1 > b$1) {
    return 1;
  } else if (a$1 < b$1) {
    return -1;
  } else {
    return 0;
  }
}

function filterUnmergedActions(actions, mergedBranches, param) {
  var mergedBranches$1 = Belt_Option.getWithDefault(mergedBranches, Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keepMapU(actions, (function (action) {
                      var match = action.contents;
                      if (typeof match === "object" && match.NAME === "MergeBranch") {
                        return match.VAL[0];
                      }
                      
                    })))));
  return Belt_List.keepU(actions, (function (action) {
                var branchId = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(action.branchId), "master");
                if (branchId === "master") {
                  return true;
                } else {
                  return Belt_SetString.has(mergedBranches$1, branchId);
                }
              }));
}

function FetchActivity(Props) {
  var schema = Props.schema;
  var currentBranch = Props.currentBranch;
  var queryBy = Props.queryBy;
  var pageSizeOpt = Props.pageSize;
  var children = Props.children;
  var pageSize = pageSizeOpt !== undefined ? pageSizeOpt : 100;
  if (typeof currentBranch === "object") {
    var branchId = currentBranch.VAL;
    return React.createElement(FirebaseFetchers.PagedActionsStream.make, {
                schemaId: schema.id,
                queryBy: Belt_Option.getWithDefault(queryBy, /* [] */0),
                pageSize: pageSize,
                initialPageSize: (pageSize << 1),
                branchId: branchId,
                audit: false,
                children: (function (branchActions, status, lastPageStartPosition, getMore) {
                    if (status === "Done") {
                      return React.createElement(FirebaseFetchers.BranchOpenAction.make, {
                                  schemaId: schema.id,
                                  branchId: branchId,
                                  includeActions: "All",
                                  children: (function (state) {
                                      if (state === undefined) {
                                        return Curry._4(children, branchActions, status, lastPageStartPosition, getMore);
                                      }
                                      var branchOpenedAt = Belt_Option.getWithDefault(Models.toDateOption(Caml_option.valFromOption(state).createdAt), new Date());
                                      var tmp = {
                                        schemaId: schema.id,
                                        pageSize: pageSize,
                                        initialPageSize: (pageSize << 1),
                                        fetchToAndEqualDate: branchOpenedAt,
                                        audit: false,
                                        children: (function (masterActions, status, lastPageStartPosition, getMore) {
                                            if (queryBy === undefined) {
                                              return Curry._4(children, Belt_List.concat(filterUnmergedActions(masterActions, undefined, undefined), branchActions), status, lastPageStartPosition, getMore);
                                            }
                                            var oldestMasterAction = Belt_List.head(masterActions);
                                            var oldestMasterActionTimestamp = Belt_Option.flatMap(oldestMasterAction, (function (action) {
                                                    return Models.toDateOption(action.createdAt);
                                                  }));
                                            var tmp = {
                                              schemaId: schema.id,
                                              children: (function (state) {
                                                  if (typeof state !== "object") {
                                                    return Curry._4(children, branchActions, status, lastPageStartPosition, getMore);
                                                  }
                                                  if (state.NAME !== "Loaded") {
                                                    return Curry._4(children, branchActions, status, lastPageStartPosition, getMore);
                                                  }
                                                  var filteredMasterActions = filterUnmergedActions(masterActions, Caml_option.some(state.VAL), undefined);
                                                  return Curry._4(children, Belt_List.concat(filteredMasterActions, branchActions), status, lastPageStartPosition, getMore);
                                                })
                                            };
                                            if (oldestMasterActionTimestamp !== undefined) {
                                              tmp.fetchToDate = Caml_option.valFromOption(oldestMasterActionTimestamp);
                                            }
                                            return React.createElement(FirebaseFetchers.MergeBranchIds.make, tmp);
                                          })
                                      };
                                      if (queryBy !== undefined) {
                                        tmp.queryBy = Caml_option.valFromOption(queryBy);
                                      }
                                      return React.createElement(FirebaseFetchers.PagedActionsStream.make, tmp);
                                    })
                                });
                    } else {
                      return Curry._4(children, branchActions, status, lastPageStartPosition, getMore);
                    }
                  }),
                key: branchId
              });
  }
  var tmp = {
    schemaId: schema.id,
    pageSize: pageSize,
    initialPageSize: pageSize,
    audit: false,
    children: (function (actions, status, lastPageStartPosition, getMore) {
        if (queryBy === undefined) {
          return Curry._4(children, filterUnmergedActions(actions, undefined, undefined), status, lastPageStartPosition, getMore);
        }
        var oldestAction = Belt_List.head(actions);
        var oldestActionTimestamp = Belt_Option.flatMap(oldestAction, (function (action) {
                return Models.toDateOption(action.createdAt);
              }));
        var tmp = {
          schemaId: schema.id,
          children: (function (state) {
              if (typeof state === "object" && state.NAME === "Loaded") {
                return Curry._4(children, filterUnmergedActions(actions, Caml_option.some(state.VAL), undefined), status, lastPageStartPosition, getMore);
              } else {
                return null;
              }
            })
        };
        if (oldestActionTimestamp !== undefined) {
          tmp.fetchToDate = Caml_option.valFromOption(oldestActionTimestamp);
        }
        return React.createElement(FirebaseFetchers.MergeBranchIds.make, tmp);
      })
  };
  if (queryBy !== undefined) {
    tmp.queryBy = Caml_option.valFromOption(queryBy);
  }
  return React.createElement(FirebaseFetchers.PagedActionsStream.make, tmp);
}

var make = FetchActivity;

exports.actionSorter = actionSorter;
exports.filterUnmergedActions = filterUnmergedActions;
exports.make = make;
/* react Not a pure module */
