// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var SsoChecks = require("./SsoChecks.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function SsoAllowedChecker(Props) {
  var schemaId = Props.schemaId;
  var children = Props.children;
  var match = React.useState(function () {
        return "InitialLoading";
      });
  var setState = match[1];
  var user = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.getAuth(undefined).currentUser));
  React.useEffect((function () {
          var isAvoShEmail = user.emailVerified && user.email.endsWith("avo.sh");
          if (isAvoShEmail) {
            Promise.resolve(Curry._1(setState, (function (param) {
                        return "Allowed";
                      })));
          } else {
            SsoChecks.checkIsCurrentProviderAllowed(user, schemaId).then(function (providerAllowed) {
                  if (providerAllowed) {
                    return Promise.resolve(Curry._1(setState, (function (param) {
                                      return "NotAllowed";
                                    })));
                  } else {
                    return Promise.resolve(Curry._1(setState, (function (param) {
                                      return "Allowed";
                                    })));
                  }
                });
          }
          
        }), [schemaId]);
  return children(match[0]);
}

var make = SsoAllowedChecker;

exports.make = make;
/* react Not a pure module */
