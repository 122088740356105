// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Shortid = require("shortid");
var NameInput = require("./NameInput.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var DiscrepancyContext = require("./DiscrepancyContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");

function NewPropertyGroupModal(Props) {
  var onClose = Props.onClose;
  var name = Props.name;
  var onCreate = Props.onCreate;
  var model = Props.model;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var sendActions = SendActionsContext.use(undefined);
  var propertyGroupId = React.useMemo((function () {
          return Shortid();
        }), []);
  React.useEffect((function () {
          AnalyticsRe.propertyGroupInitiated(schemaGroup, AnalyticsRe.Group.propertyGroup(propertyGroupId, undefined, 0), schemaGroup.branchId, schemaGroup.schemaId);
          
        }), []);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(600)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(75)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(NameInput.make, {
                  name: Belt_Option.getWithDefault(name, ""),
                  existingNames: StateUtils.getPropertyNamespace(model),
                  expectedCase: match.expectedPropertyCase,
                  forceCase: match.config.forcePropertyCase,
                  autoFocus: true,
                  onChange: (function (name, _namingConvention, _correctCase) {
                      return Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      Belt_Option.forEach(onCreate, (function (cb) {
                                              return Curry._1(cb, propertyGroupId);
                                            }));
                                      Router.Schema.pushDrawerItem(undefined, {
                                            NAME: "propertyGroup",
                                            VAL: [
                                              propertyGroupId,
                                              undefined
                                            ]
                                          });
                                      AnalyticsRe.propertyGroupCreated(AnalyticsRe.Group.propertyGroup(propertyGroupId, name, 0), AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), NamedBranch.getId(branch), schemaGroup.schemaId);
                                      return Curry._1(onClose, undefined);
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "CreatePropertyGroup",
                                        VAL: [
                                          propertyGroupId,
                                          name
                                        ]
                                      },
                                      {
                                        propertyGroupId: propertyGroupId,
                                        propertyGroupQuery: propertyGroupId
                                      }
                                    ]]
                                ]);
                    }),
                  submitLabel: "Create Property Bundle",
                  placeholder: "Property Bundle Name",
                  onClose: (function (param) {
                      return Curry._1(onClose, undefined);
                    }),
                  fullscreen: true,
                  itemType: "PropertyGroup",
                  actionType: "Create",
                  getPossibleItemLink: (function (propertyGroupName) {
                      return Belt_Option.map(StateUtils.getPropertyGroupByName(propertyGroupName, model), (function (param) {
                                    return Router.Link.addDrawerItem(undefined, {
                                                NAME: "propertyGroup",
                                                VAL: [
                                                  param.id,
                                                  undefined
                                                ]
                                              });
                                  }));
                    })
                }));
}

var make = NewPropertyGroupModal;

exports.make = make;
/* Css Not a pure module */
