// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("rescript/lib/js/pervasives.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

var empty = {
  sourceId: "",
  propertyType: ""
};

function encode(t) {
  return Json_encode.object_({
              hd: [
                "sourceId",
                t.sourceId
              ],
              tl: {
                hd: [
                  "propertyType",
                  t.propertyType
                ],
                tl: /* [] */0
              }
            });
}

function decode(json) {
  return {
          sourceId: Json_decode.field("sourceId", Json_decode.string, json),
          propertyType: Json_decode.field("propertyType", Json_decode.string, json)
        };
}

var SourcePropertyType = {
  empty: empty,
  encode: encode,
  decode: decode
};

var empty_propertyTypes = [];

var empty$1 = {
  propertyName: "",
  propertyTypes: empty_propertyTypes
};

function encode$1(t) {
  return Json_encode.object_({
              hd: [
                "propertyName",
                t.propertyName
              ],
              tl: {
                hd: [
                  "propertyTypes",
                  Json_encode.array((function (prim) {
                          return prim;
                        }), t.propertyTypes)
                ],
                tl: /* [] */0
              }
            });
}

function decode$1(json) {
  return {
          propertyName: Json_decode.field("propertyName", Json_decode.string, json),
          propertyTypes: Json_decode.field("propertyTypes", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        };
}

var InconsistentType = {
  empty: empty$1,
  encode: encode$1,
  decode: decode$1
};

var empty$2 = {
  sourcePropertyTypeA: empty,
  sourcePropertyTypeB: empty,
  eventName: "",
  propertyName: ""
};

function encode$2(t) {
  return Json_encode.object_({
              hd: [
                "sourcePropertyTypeA",
                encode(t.sourcePropertyTypeA)
              ],
              tl: {
                hd: [
                  "sourcePropertyTypeB",
                  encode(t.sourcePropertyTypeB)
                ],
                tl: {
                  hd: [
                    "eventName",
                    t.eventName
                  ],
                  tl: {
                    hd: [
                      "propertyName",
                      t.propertyName
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function decode$2(json) {
  return {
          sourcePropertyTypeA: Json_decode.field("sourcePropertyTypeA", decode, json),
          sourcePropertyTypeB: Json_decode.field("sourcePropertyTypeB", decode, json),
          eventName: Json_decode.field("eventName", Json_decode.string, json),
          propertyName: Json_decode.field("propertyName", Json_decode.string, json)
        };
}

var InconsistentPropertyTypeAcrossSources = {
  empty: empty$2,
  encode: encode$2,
  decode: decode$2
};

function encode$3(t) {
  return Json_encode.object_({
              hd: [
                "eventId",
                t.eventId
              ],
              tl: {
                hd: [
                  "propertyId",
                  t.propertyId
                ],
                tl: {
                  hd: [
                    "propertyName",
                    t.propertyName
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode$3(json) {
  return {
          eventId: Json_decode.field("eventId", Json_decode.string, json),
          propertyId: Json_decode.field("propertyId", Json_decode.string, json),
          propertyName: Json_decode.field("propertyName", Json_decode.string, json)
        };
}

var MissingExpectedProperty_empty = {
  eventId: "",
  propertyId: "",
  propertyName: ""
};

var MissingExpectedProperty = {
  empty: MissingExpectedProperty_empty,
  encode: encode$3,
  decode: decode$3
};

function encode$4(t) {
  return Json_encode.object_({
              hd: [
                "eventId",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), t.eventId)
              ],
              tl: {
                hd: [
                  "propertyId",
                  t.propertyId
                ],
                tl: {
                  hd: [
                    "propertyName",
                    t.propertyName
                  ],
                  tl: {
                    hd: [
                      "expectedPropertyType",
                      t.expectedPropertyType
                    ],
                    tl: {
                      hd: [
                        "actualPropertyType",
                        t.actualPropertyType
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function decode$4(json) {
  return {
          eventId: Json_decode.optional((function (param) {
                  return Json_decode.field("eventId", Json_decode.string, param);
                }), json),
          propertyId: Json_decode.field("propertyId", Json_decode.string, json),
          propertyName: Json_decode.field("propertyName", Json_decode.string, json),
          expectedPropertyType: Json_decode.field("expectedPropertyType", Json_decode.string, json),
          actualPropertyType: Json_decode.field("actualPropertyType", Json_decode.string, json)
        };
}

var PropertyTypeInconsistentWithTrackingPlan_empty = {
  eventId: undefined,
  propertyId: "",
  propertyName: "",
  expectedPropertyType: "",
  actualPropertyType: ""
};

var PropertyTypeInconsistentWithTrackingPlan = {
  empty: PropertyTypeInconsistentWithTrackingPlan_empty,
  encode: encode$4,
  decode: decode$4
};

function encode$5(t) {
  return Json_encode.object_({
              hd: [
                "eventId",
                t.eventId
              ],
              tl: {
                hd: [
                  "propertyName",
                  t.propertyName
                ],
                tl: {
                  hd: [
                    "propertyType",
                    t.propertyType
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode$5(json) {
  return {
          eventId: Json_decode.field("eventId", Json_decode.string, json),
          propertyName: Json_decode.field("propertyName", Json_decode.string, json),
          propertyType: Json_decode.field("propertyType", Json_decode.string, json)
        };
}

var UnexpectedProperty_empty = {
  eventId: "",
  propertyName: "",
  propertyType: ""
};

var UnexpectedProperty = {
  empty: UnexpectedProperty_empty,
  encode: encode$5,
  decode: decode$5
};

function encode$6(t) {
  if (typeof t === "number") {
    if (t === /* EventNotInTrackingPlan */0) {
      return Json_encode.object_({
                  hd: [
                    "type",
                    "EventNotInTrackingPlan"
                  ],
                  tl: /* [] */0
                });
    } else {
      return Json_encode.object_({
                  hd: [
                    "type",
                    "UnexpectedEvent"
                  ],
                  tl: /* [] */0
                });
    }
  }
  switch (t.TAG | 0) {
    case /* MissingExpectedProperty */0 :
        return Json_encode.object_({
                    hd: [
                      "type",
                      "MissingExpectedProperty"
                    ],
                    tl: {
                      hd: [
                        "missingExpectedProperty",
                        encode$3(t._0)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* PropertyTypeInconsistentWithTrackingPlan */1 :
        return Json_encode.object_({
                    hd: [
                      "type",
                      "PropertyTypeInconsistentWithTrackingPlan"
                    ],
                    tl: {
                      hd: [
                        "PropertyTypeInconsistentWithTrackingPlan",
                        encode$4(t._0)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* UnexpectedProperty */2 :
        return Json_encode.object_({
                    hd: [
                      "type",
                      "UnexpectedProperty"
                    ],
                    tl: {
                      hd: [
                        "unexpectedProperty",
                        encode$5(t._0)
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* InconsistentType */3 :
        return Json_encode.object_({
                    hd: [
                      "type",
                      "InconsistentType"
                    ],
                    tl: {
                      hd: [
                        "inconsistentType",
                        encode$1(t._0)
                      ],
                      tl: /* [] */0
                    }
                  });
    
  }
}

function decode$6(json) {
  var issueType = Json_decode.field("type", Json_decode.string, json);
  switch (issueType) {
    case "EventNotInTrackingPlan" :
        return /* EventNotInTrackingPlan */0;
    case "InconsistentType" :
        return {
                TAG: /* InconsistentType */3,
                _0: Json_decode.field("inconsistentType", decode$1, json)
              };
    case "MissingExpectedProperty" :
        return {
                TAG: /* MissingExpectedProperty */0,
                _0: Json_decode.field("missingExpectedProperty", decode$3, json)
              };
    case "PropertyMistypedInTrackingPlan" :
        return {
                TAG: /* PropertyTypeInconsistentWithTrackingPlan */1,
                _0: Json_decode.field("propertyMistypedInTrackingPlan", decode$4, json)
              };
    case "PropertyTypeInconsistentWithTrackingPlan" :
        return {
                TAG: /* PropertyTypeInconsistentWithTrackingPlan */1,
                _0: Json_decode.field("PropertyTypeInconsistentWithTrackingPlan", decode$4, json)
              };
    case "UnexpectedEvent" :
        return /* UnexpectedEvent */1;
    case "UnexpectedProperty" :
        return {
                TAG: /* UnexpectedProperty */2,
                _0: Json_decode.field("unexpectedProperty", decode$5, json)
              };
    default:
      return Pervasives.failwith("Unknown issue type " + issueType);
  }
}

function getPropertyName(issue) {
  if (typeof issue === "number") {
    return ;
  }
  switch (issue.TAG | 0) {
    case /* MissingExpectedProperty */0 :
    case /* PropertyTypeInconsistentWithTrackingPlan */1 :
        return issue._0.propertyName;
    case /* UnexpectedProperty */2 :
        return issue._0.propertyName;
    case /* InconsistentType */3 :
        return issue._0.propertyName;
    
  }
}

function getIssueTypeAsText(issueType) {
  if (typeof issueType === "number") {
    if (issueType === /* EventNotInTrackingPlan */0) {
      return "Event unexpected by tracking plan";
    } else {
      return "Event unexpected on source";
    }
  }
  switch (issueType.TAG | 0) {
    case /* MissingExpectedProperty */0 :
        return "Required property sometimes missing from event";
    case /* PropertyTypeInconsistentWithTrackingPlan */1 :
        return "Property type does not match tracking plan";
    case /* UnexpectedProperty */2 :
        return "Property unexpected on event";
    case /* InconsistentType */3 :
        return "Property type inconsistent";
    
  }
}

function getEventId(issueType) {
  if (typeof issueType === "number") {
    return ;
  }
  switch (issueType.TAG | 0) {
    case /* PropertyTypeInconsistentWithTrackingPlan */1 :
        return issueType._0.eventId;
    case /* MissingExpectedProperty */0 :
    case /* UnexpectedProperty */2 :
        return issueType._0.eventId;
    default:
      return ;
  }
}

exports.SourcePropertyType = SourcePropertyType;
exports.InconsistentType = InconsistentType;
exports.InconsistentPropertyTypeAcrossSources = InconsistentPropertyTypeAcrossSources;
exports.MissingExpectedProperty = MissingExpectedProperty;
exports.PropertyTypeInconsistentWithTrackingPlan = PropertyTypeInconsistentWithTrackingPlan;
exports.UnexpectedProperty = UnexpectedProperty;
exports.encode = encode$6;
exports.decode = decode$6;
exports.getPropertyName = getPropertyName;
exports.getIssueTypeAsText = getIssueTypeAsText;
exports.getEventId = getEventId;
/* No side effect */
