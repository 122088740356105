// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Title = require("../Title.bs.js");
var React = require("react");
var Button = require("../Button.bs.js");
var Router = require("../Router.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var GenericModal = require("../GenericModal.bs.js");
var IconCheckmarkCircle = require("../IconCheckmarkCircle.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginBottom(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

function ImportSuccessModal$SuccessRow(Props) {
  var label = Props.label;
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement(IconCheckmarkCircle.make, {
                  size: 16,
                  color: Styles.Color.grassGreen
                }), React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.grey90,
                  children: null
                }, React.createElement($$Text.make, {
                      element: "Span",
                      weight: "Semi",
                      children: label
                    }), " imported successfully"));
}

var SuccessRow = {
  rootStyles: rootStyles,
  make: ImportSuccessModal$SuccessRow
};

function ImportSuccessModal(Props) {
  var eventCount = Props.eventCount;
  var propertyCount = Props.propertyCount;
  var sourceCount = Props.sourceCount;
  var handleClose = function (param) {
    return Router.Schema.pushSchemaRoute(undefined, undefined, "events");
  };
  return React.createElement(GenericModal.make, {
              onClose: handleClose,
              children: (function (param) {
                  return React.createElement("aside", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding(Css.px(50)),
                                    tl: /* [] */0
                                  })
                            }, React.createElement(Title.make, {
                                  children: "Import Complete 🎉",
                                  size: "Medium"
                                }), React.createElement(Spacer.make, {
                                  height: 20
                                }), React.createElement($$Text.make, {
                                  size: "Medium",
                                  color: Styles.Color.grey80,
                                  children: "Your imported tracking plan is ready to be explored. Here is a quick review of what was done:"
                                }), React.createElement(Spacer.make, {
                                  height: 20
                                }), eventCount !== 0 ? (
                                eventCount !== 1 ? React.createElement(ImportSuccessModal$SuccessRow, {
                                        label: eventCount.toString() + " events"
                                      }) : React.createElement(ImportSuccessModal$SuccessRow, {
                                        label: "1 event"
                                      })
                              ) : null, propertyCount !== 0 ? (
                                propertyCount !== 1 ? React.createElement(ImportSuccessModal$SuccessRow, {
                                        label: propertyCount.toString() + " properties"
                                      }) : React.createElement(ImportSuccessModal$SuccessRow, {
                                        label: "1 property"
                                      })
                              ) : null, sourceCount !== 0 ? (
                                sourceCount !== 1 ? React.createElement(ImportSuccessModal$SuccessRow, {
                                        label: sourceCount.toString() + " sources"
                                      }) : React.createElement(ImportSuccessModal$SuccessRow, {
                                        label: "1 source"
                                      })
                              ) : null, React.createElement(Spacer.make, {
                                  height: 20
                                }), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.justifyContent("center"),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement(Button.make, {
                                      label: "Get started",
                                      onClick: handleClose,
                                      size: "large"
                                    })));
                })
            });
}

var make = ImportSuccessModal;

exports.SuccessRow = SuccessRow;
exports.make = make;
/* rootStyles Not a pure module */
