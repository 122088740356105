// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("./Icons.bs.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var IconLogin = require("./IconLogin.bs.js");
var IconMoney = require("./IconMoney.bs.js");
var IconLogout = require("./IconLogout.bs.js");
var IconLogEvent = require("./IconLogEvent.bs.js");
var IconNavigate = require("./IconNavigate.bs.js");
var IconAddMember = require("./IconAddMember.bs.js");
var SideEffectUtils = require("./SideEffectUtils.bs.js");

function EventTypeIcon(Props) {
  var sideEffect = Props.sideEffect;
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey70;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.marginTop(Css.px(2)),
                        tl: /* [] */0
                      }
                    }
                  }),
              title: SideEffectUtils.getSideEffectLabel(sideEffect)
            }, typeof sideEffect === "object" ? React.createElement(IconAddMember.make, {
                    size: size,
                    color: color
                  }) : (
                sideEffect === "Revenue" ? React.createElement(IconMoney.make, {
                        size: size,
                        color: color
                      }) : (
                    sideEffect === "Alias" || sideEffect === "Identify" ? React.createElement(IconLogin.make, {
                            size: size,
                            color: color
                          }) : (
                        sideEffect === "Page" ? React.createElement(IconNavigate.make, {
                                size: size,
                                color: color
                              }) : (
                            sideEffect === "Unidentify" ? React.createElement(IconLogout.make, {
                                    size: size,
                                    color: color
                                  }) : (
                                sideEffect === "LogEvent" ? React.createElement(IconLogEvent.make, {
                                        size: size,
                                        color: color
                                      }) : React.createElement(Icons.UpdateGroup.make, {
                                        size: size * 1.42 | 0,
                                        color: color
                                      })
                              )
                          )
                      )
                  )
              ));
}

var make = EventTypeIcon;

exports.make = make;
/* Css Not a pure module */
