// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Select = require("./Select.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameInput = require("./NameInput.bs.js");
var NameUtils = require("../../shared/utils/NameUtils.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");
var DiscrepancyContext = require("./DiscrepancyContext.bs.js");

function EventRule(Props) {
  var rule = Props.rule;
  var $$event = Props.event;
  var sendActions = Props.sendActions;
  var model = Props.model;
  var schemaGroup = Props.schemaGroup;
  var role = Props.role;
  var schema = Props.schema;
  var match = React.useState(function () {
        return /* Closed */0;
      });
  var setStatus = match[1];
  var match$1 = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match$1.expectedEventCase;
  var correctCase = NameUtils.isCorrectCase(expectedEventCase, $$event.name);
  var eventId = $$event.id;
  var context = {
    eventId: eventId,
    eventQuery: eventId
  };
  var match$2 = rule.definition;
  if (typeof match$2 !== "object") {
    return null;
  }
  if (match$2.NAME !== "NameMapping") {
    return null;
  }
  var match$3 = match$2.VAL;
  var name = match$3.name;
  var destination = match$3.destination;
  var destinationId = destination !== undefined && typeof destination === "object" && destination.NAME === "Destination" ? destination.VAL : undefined;
  var matchesNoDestination = function (id) {
    return Belt_List.every(model.destinations, (function (destination) {
                  return destination.id !== id;
                }));
  };
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.margin2(Css.px(6), Css.px(0)),
                    tl: {
                      hd: Css.fontSize(Styles.FontSize.small),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, "Send as", React.createElement("button", {
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.button),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.margin2(Css.px(0), Css.px(4)),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.borderRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.padding2(Css.px(4), Css.px(8)),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.blueBg),
                                        tl: {
                                          hd: Css.color(Styles.Color.blue),
                                          tl: {
                                            hd: Css.fontSize(Styles.FontSize.regular),
                                            tl: {
                                              hd: Css.textAlign("center"),
                                              tl: {
                                                hd: Css.position("relative"),
                                                tl: {
                                                  hd: Css.hover({
                                                        hd: Css.color(Styles.Color.blueSecondary),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: {
                                                    hd: Css.cursor("text"),
                                                    tl: {
                                                      hd: Css.minHeight(Css.px(25)),
                                                      tl: {
                                                        hd: Css.minWidth(Css.px(100)),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      }),
                  disabled: !Models.Role.canEdit(role),
                  onClick: (function (param) {
                      return Curry._1(setStatus, (function (param) {
                                    return /* EditingName */1;
                                  }));
                    })
                }, Belt_Option.getWithDefault(name, "")), "to", React.createElement(Spacer.make, {
                  width: 4
                }), React.createElement(Select.make, {
                  disabled: !Models.Role.canEdit(role),
                  maxWidth: Css.px(200),
                  onSelect: (function (nextDestinationId) {
                      var nextDestinationId$1 = nextDestinationId === "None" ? undefined : nextDestinationId;
                      if (Caml_obj.caml_notequal(nextDestinationId$1, destinationId)) {
                        return Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (branch) {
                                        return AnalyticsRe.eventUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.source("N/A", "N/A"), $$event.id, $$event.name, "UpdateRule", undefined, undefined, undefined, undefined, undefined, Belt_List.length(BeltListExtensions.dedupeString(StateUtils.eventsWithNameMapping(model))), Belt_List.length(StateUtils.eventsWithNameMapping(model)), Belt_List.length(Belt_List.keep(StateUtils.eventsWithNameMapping(model), (function (id) {
                                                              return id === eventId;
                                                            }))), Belt_List.length(model.events), "NameMapping", undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, expectedEventCase, $$event.id, schema.id, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
                                      }),
                                    undefined,
                                    [[
                                        {
                                          NAME: "UpdateRule",
                                          VAL: [
                                            rule.id,
                                            {
                                              NAME: "Event",
                                              VAL: eventId
                                            },
                                            {
                                              NAME: "NameMapping",
                                              VAL: {
                                                destination: Belt_Option.map(nextDestinationId$1, (function (id) {
                                                        return {
                                                                NAME: "Destination",
                                                                VAL: id
                                                              };
                                                      })),
                                                name: name
                                              }
                                            }
                                          ]
                                        },
                                        context
                                      ]]
                                  ]);
                      }
                      
                    }),
                  options: Belt_List.concatMany([
                        destinationId !== undefined && !matchesNoDestination(destinationId) ? /* [] */0 : ({
                              hd: [
                                {
                                  NAME: "Label",
                                  VAL: "Select Destination"
                                },
                                "None"
                              ],
                              tl: /* [] */0
                            }),
                        Belt_List.map(Belt_List.keep(model.destinations, (function (destination) {
                                    return !Belt_List.some(model.rules, (function (rule) {
                                                  var match = rule.item;
                                                  var match$1 = rule.definition;
                                                  if (typeof match !== "object") {
                                                    return false;
                                                  }
                                                  if (match.NAME !== "Event") {
                                                    return false;
                                                  }
                                                  if (typeof match$1 !== "object") {
                                                    return false;
                                                  }
                                                  if (match$1.NAME !== "NameMapping") {
                                                    return false;
                                                  }
                                                  var match$2 = match$1.VAL.destination;
                                                  if (match$2 === undefined) {
                                                    return false;
                                                  }
                                                  if (typeof match$2 !== "object") {
                                                    return false;
                                                  }
                                                  if (match$2.NAME !== "Destination") {
                                                    return false;
                                                  }
                                                  var id = match$2.VAL;
                                                  var isCurrentlySelected = Caml_obj.caml_equal(id, destinationId);
                                                  var isSameDestination = id === destination.id;
                                                  var isSameEvent = match.VAL === $$event.id;
                                                  if (!isCurrentlySelected && isSameDestination) {
                                                    return isSameEvent;
                                                  } else {
                                                    return false;
                                                  }
                                                }));
                                  })), (function (destination) {
                                return [
                                        {
                                          NAME: "Label",
                                          VAL: StateUtils.getDestinationName(destination) + (
                                            StateUtils.isEventSentToDestination(model, eventId, destination.id) ? "" : " (event never sent here)"
                                          )
                                        },
                                        destination.id
                                      ];
                              }))
                      ]),
                  value: Belt_Option.getWithDefault(destinationId, "None")
                }), Models.Role.canEdit(role) ? React.createElement("button", {
                    className: Curry._1(Css.merge, {
                          hd: Curry._1(Css.style, Styles.button),
                          tl: {
                            hd: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(5), Css.px(10)),
                                  tl: {
                                    hd: Css.marginLeft(Css.px(5)),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.small),
                                        tl: {
                                          hd: Css.color(Styles.Color.grey50),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.color(Styles.Color.grapeError),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }),
                    onClick: (function (param) {
                        return Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (branch) {
                                        var eventsWithMapping = Belt_List.keepMap(model.rules, (function (item) {
                                                var match = item.item;
                                                var match$1 = item.definition;
                                                var match$2 = item.id;
                                                if (typeof match === "object" && match.NAME === "Event" && typeof match$1 === "object" && match$1.NAME === "NameMapping" && match$2 !== rule.id) {
                                                  return match.VAL;
                                                }
                                                
                                              }));
                                        return AnalyticsRe.eventUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.source("N/A", "N/A"), $$event.id, $$event.name, "RemoveRule", undefined, undefined, undefined, undefined, undefined, Belt_List.length(BeltListExtensions.dedupeString(eventsWithMapping)), Belt_List.length(eventsWithMapping), Belt_List.length(Belt_List.keep(eventsWithMapping, (function (id) {
                                                              return id === eventId;
                                                            }))), Belt_List.length(model.events), "NameMapping", undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, expectedEventCase, $$event.id, schema.id, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
                                      }),
                                    undefined,
                                    [[
                                        {
                                          NAME: "RemoveRule",
                                          VAL: [
                                            rule.id,
                                            {
                                              NAME: "Event",
                                              VAL: eventId
                                            }
                                          ]
                                        },
                                        context
                                      ]]
                                  ]);
                      })
                  }, "Remove") : null, match[0] === /* EditingName */1 ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("fixed"),
                          tl: {
                            hd: Css.top(Css.px(0)),
                            tl: {
                              hd: Css.right(Css.px(0)),
                              tl: {
                                hd: Css.bottom(Css.px(0)),
                                tl: {
                                  hd: Css.left(Css.px(0)),
                                  tl: {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.justifyContent("center"),
                                        tl: {
                                          hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.width(Css.px(500)),
                              tl: {
                                hd: Css.maxWidth(Css.pct(100)),
                                tl: {
                                  hd: Css.height(Css.px(70)),
                                  tl: {
                                    hd: Css.position("relative"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement(NameInput.make, {
                            name: Belt_Option.getWithDefault(name, ""),
                            autoFocus: true,
                            onChange: (function (nextName, _namingConvention, _correctCase) {
                                if (Caml_obj.caml_notequal(name, nextName)) {
                                  Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        (function (branch) {
                                            return AnalyticsRe.eventUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.source("N/A", "N/A"), $$event.id, $$event.name, "UpdateRule", undefined, undefined, undefined, undefined, undefined, Belt_List.length(BeltListExtensions.dedupeString(StateUtils.eventsWithNameMapping(model))), Belt_List.length(StateUtils.eventsWithNameMapping(model)), Belt_List.length(Belt_List.keep(StateUtils.eventsWithNameMapping(model), (function (id) {
                                                                  return id === eventId;
                                                                }))), Belt_List.length(model.events), "NameMapping", undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, expectedEventCase, $$event.id, schema.id, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
                                          }),
                                        undefined,
                                        [[
                                            {
                                              NAME: "UpdateRule",
                                              VAL: [
                                                rule.id,
                                                {
                                                  NAME: "Event",
                                                  VAL: eventId
                                                },
                                                {
                                                  NAME: "NameMapping",
                                                  VAL: {
                                                    destination: Belt_Option.map(destinationId, (function (id) {
                                                            return {
                                                                    NAME: "Destination",
                                                                    VAL: id
                                                                  };
                                                          })),
                                                    name: nextName
                                                  }
                                                }
                                              ]
                                            },
                                            context
                                          ]]
                                      ]);
                                }
                                return Curry._1(setStatus, (function (param) {
                                              return /* Closed */0;
                                            }));
                              }),
                            submitLabel: "Update Mapping",
                            placeholder: "Event Name",
                            onClose: (function (param) {
                                return Curry._1(setStatus, (function (param) {
                                              return /* Closed */0;
                                            }));
                              }),
                            fullscreen: true,
                            dirty: false,
                            itemType: "PropertyNameMapping",
                            actionType: Belt_Option.isSome(name) ? "Rename" : "Create",
                            getPossibleItemLink: (function (eventName) {
                                return Belt_Option.map(StateUtils.getEventByName(eventName, model), (function (param) {
                                              return Router.Link.addDrawerItem(undefined, {
                                                          NAME: "event",
                                                          VAL: [
                                                            param.id,
                                                            undefined,
                                                            undefined,
                                                            false
                                                          ]
                                                        });
                                            }));
                              })
                          }))) : null);
}

var make = EventRule;

exports.make = make;
/* Css Not a pure module */
