// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var StateFilterUtils = require("./StateFilterUtils.bs.js");

function planToAnalyticsPlan(planName) {
  switch (planName) {
    case /* Empty */0 :
    case /* Free */1 :
    case /* FreeV2 */2 :
        return "Free";
    case /* Scholarship */3 :
        return "Scholarship";
    case /* Growth */4 :
    case /* GrowthV2 */5 :
        return "Growth";
    case /* Enterprise */6 :
        return "Enterprise";
    case /* Starter */7 :
        return "Starter";
    case /* TeamV2 */8 :
    case /* TeamV2Annual */9 :
        return "Team";
    
  }
}

function planToAnalyticsPlanSlug(planName) {
  switch (planName) {
    case /* Empty */0 :
    case /* Free */1 :
        return "Free";
    case /* FreeV2 */2 :
        return "Freev2";
    case /* Scholarship */3 :
        return "Scholarship";
    case /* Growth */4 :
        return "Growth";
    case /* GrowthV2 */5 :
        return "Growthv2";
    case /* Enterprise */6 :
        return "Enterprise";
    case /* Starter */7 :
        return "Starter";
    case /* TeamV2 */8 :
    case /* TeamV2Annual */9 :
        return "Teamv2";
    
  }
}

function analyticsLanguage(language) {
  if (language !== undefined) {
    if (language === "java") {
      return "Java";
    } else if (language === "json") {
      return "JsonSchema";
    } else if (language === "objc") {
      return "ObjectiveMinusC";
    } else if (language === "js" || language === "js_v2") {
      return "JavaScript";
    } else if (language === "re" || language === "re_v2") {
      return "Reason";
    } else if (language === "cs") {
      return "CSharp";
    } else if (language === "go") {
      return "Go";
    } else if (language === "kt") {
      return "Kotlin";
    } else if (language === "py") {
      return "Python";
    } else if (language === "rb") {
      return "Ruby";
    } else if (language === "ts") {
      return "TypeScript";
    } else if (language === "php") {
      return "Php";
    } else if (language === "py3") {
      return "Python3";
    } else if (language === "swift") {
      return "Swift";
    } else {
      return "Dart";
    }
  }
  
}

function getLegacySchemaBillingStatus(workspace) {
  var match = workspace.trial;
  var match$1 = workspace.plan.name;
  if (match !== undefined) {
    return "Trial";
  } else if (match$1 >= 3) {
    return "Team";
  } else {
    return "Free";
  }
}

function makeSchemaGroup(workspace, currentBranchId, currentBranchName) {
  var match = workspace.authMethod;
  return AnalyticsRe.Group.schema(workspace.id, workspace.name, getLegacySchemaBillingStatus(workspace), currentBranchId, currentBranchName, planToAnalyticsPlan(workspace.plan.name), Belt_Option.map(workspace.trial, (function (trial) {
                    return planToAnalyticsPlan(trial.plan.name);
                  })), match === "Google" ? "GoogleSSO" : (
                match === "Saml" ? "SamlSso" : "Default"
              ), planToAnalyticsPlanSlug(workspace.plan.name), Belt_Option.map(workspace.trial, (function (trial) {
                    return planToAnalyticsPlanSlug(trial.plan.name);
                  })));
}

function countSourceEventsImplementWithAvoAfterCurrentChange(model, sourceId, includeInCodegen) {
  var match = Belt_List.partitionU(Belt_List.keepMapU(model.events, (function ($$event) {
              return Belt_List.getByU($$event.includeSources, (function (includedSource) {
                            return includedSource.id === sourceId;
                          }));
            })), (function (includedEvent) {
          return includedEvent.includeInCodegen;
        }));
  return [
          Belt_List.length(match[0]) + (
            includeInCodegen ? 1 : -1
          ) | 0,
          Belt_List.length(match[1]) + (
            includeInCodegen ? -1 : 1
          ) | 0
        ];
}

function columnTypeToEventColumnsName(columnType) {
  if (columnType === "EventProperties") {
    return "EventProperties";
  } else if (columnType === "Destinations") {
    return "Destinations";
  } else if (columnType === "PropertyGroups") {
    return "PropertyGroups";
  } else if (columnType === "Metrics") {
    return "Metrics";
  } else if (columnType === "Sources") {
    return "Sources";
  } else if (columnType === "GroupProperties") {
    return "GroupProperties";
  } else if (columnType === "Category") {
    return "Category";
  } else if (columnType === "EventName") {
    return "EventName";
  } else if (columnType === "Actions") {
    return "Actions";
  } else if (columnType === "Tags") {
    return "Tags";
  } else {
    return ;
  }
}

function columnTypeToPropertyColumnsName(columnType) {
  if (columnType === "PropertyIsList") {
    return "ValueIsList";
  } else if (columnType === "EventPropertyGroup") {
    return "EventPropertyGroup";
  } else if (columnType === "PropertyConstraints") {
    return "PropertyConstraints";
  } else if (columnType === "PropertyNameMapping") {
    return "NameMapping";
  } else if (columnType === "PropertyName") {
    return "PropertyName";
  } else if (columnType === "PropertyType") {
    return "PropertyType";
  } else if (columnType === "PropertyEvents") {
    return "PropertyEvents";
  } else if (columnType === "PropertyAbsence") {
    return "PropertyPresence";
  } else {
    return ;
  }
}

function currentFilters(model, filters, sorting) {
  return AnalyticsRe.Group.currentFilters(StateFilterUtils.getTagFilters(filters), Belt_Array.map(StateFilterUtils.getSourceFilters(filters), (function (param) {
                    return AvoConfig.getSourceNameById(model, param);
                  })), Belt_Array.map(StateFilterUtils.getDestinationFilters(filters), (function (param) {
                    return StateUtils.getDestinationNameById(model, param);
                  })), sorting, Belt_Array.keepMapU(StateFilterUtils.getPropertyGroupFilters(filters), (function (propertyGroupId) {
                    return Belt_Option.mapU(StateUtils.getPropertyGroupById(propertyGroupId, model), (function (param) {
                                  return param.name;
                                }));
                  })), Belt_Array.keepMapU(StateFilterUtils.getPropertyFilters(filters), (function (propertyId) {
                    return StateUtils.getPropertyNameById(propertyId, model);
                  })), StateFilterUtils.getActionFilters(filters), Belt_Array.keepMapU(StateFilterUtils.getCategoryFilters(filters), (function (goalId) {
                    return Belt_Option.mapU(StateUtils.getGoalById(goalId, model), (function (param) {
                                  return param.name;
                                }));
                  })));
}

function instructionsFormatToAnalyticsFormat(instructionsFormat) {
  if (instructionsFormat) {
    return "Markdown";
  } else {
    return "JiraMarkdown";
  }
}

function destinationModeToAnalyticsDestinationMode(destinationMode) {
  if (destinationMode !== 1) {
    return "AvoManaged";
  } else {
    return "DestinationInterface";
  }
}

function roleToAnalyticsRole(role) {
  if (role === "Editor") {
    return "Editor";
  } else if (role === "CodegenAccess") {
    return "CodegenAccess";
  } else if (role === "NoAccess" || role === "Viewer") {
    return "Viewer";
  } else if (role === "CommentOnly") {
    return "CommentOnly";
  } else if (role === "Admin") {
    return "Admin";
  } else {
    return "BillingOnly";
  }
}

function configToConfigsOnAnalytics(config) {
  return Belt_Array.keepMapU([
              [
                "AllEventsHaveDescriptions",
                config.eventsWithoutDescription
              ],
              [
                "AllPropertiesHaveDescriptions",
                config.propertiesWithoutDescription
              ],
              [
                "AllPropertiesHaveTypes",
                config.propertiesWithAnyType
              ],
              [
                "ConsistentEventNameCasing",
                config.eventsWithUnexpectedCase
              ],
              [
                "ConsistentPropertyNameCasing",
                config.propertiesWithUnexpectedCase
              ],
              [
                "ForceEventCasing",
                config.forceEventCase
              ],
              [
                "ForcePropertyCasing",
                config.forcePropertyCase
              ],
              [
                "NoConflictingProperties",
                config.propertyConflictOnEvent
              ],
              [
                "PreferredEventNameCasingOtherThanInherit",
                config.preferredEventCase !== "None"
              ],
              [
                "PreferredPropertyNameCasingOtherThanInherit",
                config.preferredPropertyCase !== "None"
              ],
              [
                "UniqueEventNames",
                config.sameEventName
              ],
              [
                "UniquePropertyNames",
                config.samePropertyName
              ]
            ], (function (param) {
                if (param[1]) {
                  return param[0];
                }
                
              }));
}

function enrichSchemaGroupWithNamedBranch(schemaGroup, branch) {
  return {
          schemaId: schemaGroup.schemaId,
          schemaName: schemaGroup.schemaName,
          schemaBillingStatus: schemaGroup.schemaBillingStatus,
          branchId: NamedBranch.getId(branch),
          branchName: NamedBranch.getName(branch),
          schemaSubscriptionPlan: schemaGroup.schemaSubscriptionPlan,
          schemaTrialPlan: schemaGroup.schemaTrialPlan,
          authenticationConfig: schemaGroup.authenticationConfig,
          schemaSubscriptionPlanSlug: schemaGroup.schemaSubscriptionPlanSlug,
          schemaTrialPlanSlug: schemaGroup.schemaTrialPlanSlug
        };
}

function sendAsToPropertyType(sendAs) {
  if (typeof sendAs !== "number") {
    return "GroupProperty";
  }
  switch (sendAs) {
    case /* SystemProperty */0 :
        return "SystemProperty";
    case /* EventProperty */1 :
        return "EventProperty";
    case /* UserProperty */2 :
        return "UserProperty";
    
  }
}

function propertyLocationToOrigin($$location) {
  return $$location;
}

exports.planToAnalyticsPlan = planToAnalyticsPlan;
exports.planToAnalyticsPlanSlug = planToAnalyticsPlanSlug;
exports.analyticsLanguage = analyticsLanguage;
exports.getLegacySchemaBillingStatus = getLegacySchemaBillingStatus;
exports.makeSchemaGroup = makeSchemaGroup;
exports.countSourceEventsImplementWithAvoAfterCurrentChange = countSourceEventsImplementWithAvoAfterCurrentChange;
exports.columnTypeToEventColumnsName = columnTypeToEventColumnsName;
exports.columnTypeToPropertyColumnsName = columnTypeToPropertyColumnsName;
exports.currentFilters = currentFilters;
exports.instructionsFormatToAnalyticsFormat = instructionsFormatToAnalyticsFormat;
exports.destinationModeToAnalyticsDestinationMode = destinationModeToAnalyticsDestinationMode;
exports.roleToAnalyticsRole = roleToAnalyticsRole;
exports.configToConfigsOnAnalytics = configToConfigsOnAnalytics;
exports.enrichSchemaGroupWithNamedBranch = enrichSchemaGroupWithNamedBranch;
exports.sendAsToPropertyType = sendAsToPropertyType;
exports.propertyLocationToOrigin = propertyLocationToOrigin;
/* AvoConfig Not a pure module */
