// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Case = require("../../bs-case/src/case.bs.js");
var Icon = require("./Icon.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Case$1 = require("case");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var DidMount = require("./DidMount.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameUtils = require("../../shared/utils/NameUtils.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var TextButton = require("./TextButton.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var LoadingCircle = require("./LoadingCircle.bs.js");
var AppFeatureFlag = require("./AppFeatureFlag.bs.js");
var Debounce = require("lodash/debounce");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var StringSimilarity = require("string-similarity");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var IntelligenceNameFeedbackEndpoint = require("../../shared/endpoints/IntelligenceNameFeedbackEndpoint.bs.js");

function isValid(validationResult) {
  if (validationResult.isIllegal === false && validationResult.inconsistentCase === false && validationResult.isEmpty === false) {
    return validationResult.conflictingName === undefined;
  } else {
    return false;
  }
}

function validateInput(existingNames, name, stateName, commonCase) {
  var existingNames$1 = Belt_List.keepU(existingNames, (function (item) {
          return Case.to_("Camel", item) !== Case.to_("Camel", name);
        }));
  var conflictingName = NameUtils.getConflictingNames(existingNames$1, stateName);
  var isEmpty = stateName === "" || stateName.toLowerCase() === "" || Case.camel(stateName) === "" || Case$1.snake(stateName) === "";
  var isIllegal = Belt_Option.isSome(conflictingName) || isEmpty;
  var similarNames = Belt_List.mapU(Belt_List.sortU(Belt_List.keepU(Belt_List.mapU(existingNames$1, (function (name) {
                      return [
                              name,
                              StringSimilarity.compareTwoStrings(name, stateName)
                            ];
                    })), (function (param) {
                  return param[1] >= 0.8;
                })), (function (param, param$1) {
              var b = param$1[1];
              var a = param[1];
              if (a > b) {
                return 1;
              } else if (a < b) {
                return -1;
              } else {
                return 0;
              }
            })), (function (param) {
          return param[0];
        }));
  var inconsistentCase = !NameUtils.isCorrectCase(commonCase, stateName);
  return {
          isIllegal: isIllegal,
          inconsistentCase: inconsistentCase,
          isEmpty: isEmpty,
          conflictingName: conflictingName,
          similarNames: similarNames
        };
}

function validateInputWithAction(send, existingNames, name, stateName, commonCase) {
  return Curry._1(send, {
              TAG: /* UpdateValidation */2,
              _0: {
                NAME: "Result",
                VAL: validateInput(existingNames, name, stateName, commonCase)
              }
            });
}

var debouncedValidation = Debounce(validateInputWithAction, 400, {
      leading: false,
      trailing: true
    });

var debouncedIntelligenceFeedback = Debounce((function (send, param, param$1, existingNames, stateName, commonCase) {
        var namingActionType = param$1[1];
        var itemType = param$1[0];
        var schemaId = param[1];
        var schemaGroup = param[0];
        if (stateName === "") {
          return Curry._1(send, {
                      TAG: /* UpdateIntelligenceFeedback */3,
                      _0: "Init"
                    });
        } else {
          if (param[2]) {
            Curry._1(send, {
                  TAG: /* UpdateIntelligenceFeedback */3,
                  _0: "Loading"
                });
            AnalyticsRe.intelligenceNamingFeedbackInitiated(schemaGroup, itemType, namingActionType, Belt_List.toArray(existingNames).join("\n").length / 4 | 0, "UnlimitedAccess", schemaGroup.branchId, schemaId);
            $$Promise.$$catch(Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).getIdToken().then(function (userToken) {
                          return fetch(Firebase.apiUrl + "/intelligence/name", Fetch.RequestInit.make(/* Post */2, [
                                            [
                                              "Accept",
                                              "application/json"
                                            ],
                                            [
                                              "Content-Type",
                                              "application/json"
                                            ],
                                            [
                                              "Authorization",
                                              "Bearer " + userToken
                                            ]
                                          ], Caml_option.some(JSON.stringify(IntelligenceNameFeedbackEndpoint.$$Request.encode({
                                                        schemaId: schemaId,
                                                        nameSpace: Belt_List.toArray(existingNames),
                                                        itemType: itemType === "Property" ? /* Property */1 : /* Event */0,
                                                        case: commonCase,
                                                        inputName: stateName
                                                      }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                        }).then(function (prim) {
                        return prim.json();
                      }).then(function (responseJson) {
                      try {
                        var result = IntelligenceNameFeedbackEndpoint.$$Response.decode(responseJson);
                        return Curry._1(send, {
                                    TAG: /* UpdateIntelligenceFeedback */3,
                                    _0: {
                                      NAME: "Result",
                                      VAL: result.feedback
                                    }
                                  });
                      }
                      catch (raw_error){
                        var error = Caml_js_exceptions.internalToOCamlException(raw_error);
                        console.error("Intelligence feedback error", error);
                        return Curry._1(send, {
                                    TAG: /* UpdateIntelligenceFeedback */3,
                                    _0: "Init"
                                  });
                      }
                    }), (function (error) {
                    console.error("Intelligence feedback error", error);
                    return Promise.resolve(Curry._1(send, {
                                    TAG: /* UpdateIntelligenceFeedback */3,
                                    _0: "Init"
                                  }));
                  }));
          } else {
            AnalyticsRe.intelligenceNamingFeedbackInitiated(schemaGroup, itemType, namingActionType, Belt_List.toArray(existingNames).join("\n").length / 4 | 0, "NoAccess", schemaGroup.branchId, schemaId);
          }
          return ;
        }
      }), 1000, {
      leading: false,
      trailing: true
    });

function NameInput(Props) {
  var name = Props.name;
  var existingNamesOpt = Props.existingNames;
  var expectedCase = Props.expectedCase;
  var forceCaseOpt = Props.forceCase;
  var autoFocusOpt = Props.autoFocus;
  var onChange = Props.onChange;
  var submitLabelOpt = Props.submitLabel;
  var placeholderOpt = Props.placeholder;
  var onCloseOpt = Props.onClose;
  var fullscreenOpt = Props.fullscreen;
  var dirtyOpt = Props.dirty;
  var disabledOpt = Props.disabled;
  var inputStylesOpt = Props.inputStyles;
  var itemType = Props.itemType;
  var actionType = Props.actionType;
  var getPossibleItemLink = Props.getPossibleItemLink;
  var existingNames = existingNamesOpt !== undefined ? existingNamesOpt : /* [] */0;
  var forceCase = forceCaseOpt !== undefined ? forceCaseOpt : false;
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
  var submitLabel = submitLabelOpt !== undefined ? submitLabelOpt : "Update Name";
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "Event Name";
  var onClose = onCloseOpt !== undefined ? onCloseOpt : (function (param) {
        
      });
  var fullscreen = fullscreenOpt !== undefined ? fullscreenOpt : false;
  var dirty = dirtyOpt !== undefined ? dirtyOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var inputStyles = inputStylesOpt !== undefined ? inputStylesOpt : /* [] */0;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var hasAvoIntelligence = AppFeatureFlag.useFeatureFlag("AvoIntelligence") && (itemType === "Event" || itemType === "Property");
  var forceCasingAvailability = AvoLimits.ForcePreferredCasing.computeAvailability(workspace);
  var forceCase$1 = forceCase && forceCasingAvailability !== "Unavailable";
  var expectedCase$1 = React.useMemo((function () {
          return Belt_Option.getWithDefault(expectedCase, NameUtils.getCommonCase(existingNames));
        }), [expectedCase]);
  var name$1 = NameUtils.replaceNewlinesWithWhitespace(name);
  var match = React.useReducer((function (state, action) {
          switch (action.TAG | 0) {
            case /* ToggleFocus */0 :
                if (disabled) {
                  return state;
                }
                var hasFocus = action._0;
                return {
                        hasFocus: fullscreen || hasFocus,
                        name: state.name,
                        inputRef: state.inputRef,
                        interactedWith: fullscreen || hasFocus ? state.interactedWith : false,
                        numberOfSuggestions: state.numberOfSuggestions,
                        validation: state.validation,
                        intelligenceFeedback: state.intelligenceFeedback
                      };
            case /* UpdateName */1 :
                return {
                        hasFocus: state.hasFocus,
                        name: action._0,
                        inputRef: state.inputRef,
                        interactedWith: true,
                        numberOfSuggestions: state.numberOfSuggestions,
                        validation: "Loading",
                        intelligenceFeedback: "Init"
                      };
            case /* UpdateValidation */2 :
                return {
                        hasFocus: state.hasFocus,
                        name: state.name,
                        inputRef: state.inputRef,
                        interactedWith: state.interactedWith,
                        numberOfSuggestions: state.numberOfSuggestions,
                        validation: action._0,
                        intelligenceFeedback: state.intelligenceFeedback
                      };
            case /* UpdateIntelligenceFeedback */3 :
                return {
                        hasFocus: state.hasFocus,
                        name: state.name,
                        inputRef: state.inputRef,
                        interactedWith: state.interactedWith,
                        numberOfSuggestions: state.numberOfSuggestions,
                        validation: state.validation,
                        intelligenceFeedback: action._0
                      };
            
          }
        }), {
        hasFocus: autoFocus,
        name: name$1,
        inputRef: {
          contents: undefined
        },
        interactedWith: false,
        numberOfSuggestions: {
          contents: 0
        },
        validation: "Init",
        intelligenceFeedback: "Init"
      });
  var send = match[1];
  var state = match[0];
  var schemaGroup = SchemaGroupContext.use(undefined);
  React.useEffect((function () {
          if (fullscreen) {
            validateInputWithAction(send, existingNames, name$1, state.name, expectedCase$1);
          }
          
        }), []);
  React.useEffect((function () {
          if (state.interactedWith && state.validation === "Loading") {
            debouncedValidation(send, existingNames, name$1, state.name, expectedCase$1);
            if (hasAvoIntelligence) {
              debouncedIntelligenceFeedback(send, [
                    schemaGroup,
                    workspace.id,
                    hasAvoIntelligence
                  ], [
                    itemType,
                    actionType
                  ], existingNames, state.name, expectedCase$1);
            }
            
          }
          
        }), [state.name]);
  var isDirty = dirty || state.name !== name$1;
  var focusInput = function (param) {
    var inputRef = state.inputRef.contents;
    if (inputRef !== undefined) {
      Caml_option.valFromOption(inputRef).focus();
      return ;
    }
    
  };
  var handleBlur = function (param) {
    var inputRef = state.inputRef.contents;
    if (inputRef !== undefined) {
      Caml_option.valFromOption(inputRef).blur();
    }
    return Curry._1(send, {
                TAG: /* ToggleFocus */0,
                _0: false
              });
  };
  var handleSubmit = function (param) {
    var match = state.validation;
    if (typeof match !== "object") {
      return ;
    }
    if (match.NAME !== "Result") {
      return ;
    }
    var match$1 = match.VAL;
    if (!match$1.isIllegal) {
      Curry._3(onChange, state.name.trim(), Case.getCasingWithWorkspaceCase(state.name, expectedCase$1), !match$1.inconsistentCase);
      return handleBlur(undefined);
    }
    
  };
  var handleEscape = function (explicit) {
    if (explicit || state.name === "") {
      Curry._1(send, {
            TAG: /* UpdateName */1,
            _0: name$1
          });
    }
    handleBlur(undefined);
    return Curry._1(onClose, undefined);
  };
  var logClose = function (closingMethod, changeMade) {
    return AnalyticsRe.nameEditorClosed(schemaGroup, closingMethod, itemType, actionType, Case.getCasingWithWorkspaceCase(state.name, expectedCase$1), state.numberOfSuggestions.contents, state.interactedWith ? "Keystroke" : "None", changeMade, schemaGroup.branchId, schemaGroup.schemaId);
  };
  var tmp;
  if (state.hasFocus) {
    var match$1 = state.validation;
    var tmp$1;
    if (match$1 === "Loading") {
      tmp$1 = React.createElement(LoadingCircle.make, {
            size: 12,
            color: Styles.Color.grey50
          });
    } else if (match$1 === "Init" || !(isDirty && typeof match$1 === "object" && match$1.NAME === "Result")) {
      tmp$1 = null;
    } else {
      var match$2 = match$1.VAL;
      var name$2 = match$2.conflictingName;
      if (match$2.isEmpty) {
        tmp$1 = React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.grapeError,
              children: null
            }, React.createElement(DidMount.make, {
                  didMount: (function (param) {
                      state.numberOfSuggestions = {
                        contents: state.numberOfSuggestions.contents + 1 | 0
                      };
                      return AnalyticsRe.namingFeedbackReceived(schemaGroup, "Error", itemType, actionType, undefined, "EmptyName", schemaGroup.branchId, schemaGroup.schemaId);
                    })
                }), "Name can't be empty.");
      } else if (name$2 !== undefined) {
        tmp$1 = React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement($$Text.make, {
                  element: "Span",
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.grapeError,
                  children: null,
                  key: name$2
                }, React.createElement(DidMount.make, {
                      didMount: (function (param) {
                          state.numberOfSuggestions = {
                            contents: state.numberOfSuggestions.contents + 1 | 0
                          };
                          return AnalyticsRe.namingFeedbackReceived(schemaGroup, "Error", itemType, actionType, undefined, "AlreadyExists", schemaGroup.branchId, schemaGroup.schemaId);
                        })
                    }), "\"" + name$2 + "\" already exists."), getPossibleItemLink !== undefined ? Belt_Option.mapWithDefault(Curry._1(getPossibleItemLink, name$2), null, (function (path) {
                      return React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.color(Styles.Color.blue100),
                                          tl: {
                                            hd: Icon.color(Styles.Color.blue100),
                                            tl: {
                                              hd: Css.hover({
                                                    hd: Css.color(Styles.Color.blue120),
                                                    tl: {
                                                      hd: Icon.color(Styles.Color.blue120),
                                                      tl: /* [] */0
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement(Spacer.make, {
                                      width: 6
                                    }), React.createElement(Link.make, {
                                      path: path,
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: /* [] */0
                                            }
                                          }),
                                      onClick: (function (param) {
                                          return handleEscape(true);
                                        }),
                                      children: null
                                    }, React.createElement($$Text.make, {
                                          element: "Span",
                                          size: "Small",
                                          children: "Go to"
                                        }), React.createElement(Icon.make, {
                                          type_: "arrowRight",
                                          size: "small"
                                        })));
                    })) : null);
      } else {
        var similarNames = match$2.similarNames;
        var tmp$2;
        var exit = 0;
        var similarName;
        if (similarNames) {
          var similarName$1 = similarNames.hd;
          similarName = similarName$1;
          exit = 1;
        } else {
          tmp$2 = /* [] */0;
        }
        if (exit === 1) {
          tmp$2 = {
            hd: React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.grey80,
                      children: null,
                      key: similarName
                    }, React.createElement(DidMount.make, {
                          didMount: (function (param) {
                              state.numberOfSuggestions = {
                                contents: state.numberOfSuggestions.contents + 1 | 0
                              };
                              return AnalyticsRe.namingFeedbackReceived(schemaGroup, "Warning", itemType, actionType, undefined, "SimilarToExisting", schemaGroup.branchId, schemaGroup.schemaId);
                            })
                        }), "Similar to \"" + similarName + "\"."), getPossibleItemLink !== undefined ? Belt_Option.mapWithDefault(Curry._1(getPossibleItemLink, similarName), null, (function (path) {
                          return React.createElement("span", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.color(Styles.Color.blue100),
                                              tl: {
                                                hd: Icon.color(Styles.Color.blue100),
                                                tl: {
                                                  hd: Css.hover({
                                                        hd: Css.color(Styles.Color.blue120),
                                                        tl: {
                                                          hd: Icon.color(Styles.Color.blue120),
                                                          tl: /* [] */0
                                                        }
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          })
                                    }, React.createElement(Spacer.make, {
                                          width: 6
                                        }), React.createElement(Link.make, {
                                          path: path,
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.alignItems("center"),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          onClick: (function (param) {
                                              return handleEscape(true);
                                            }),
                                          children: null
                                        }, React.createElement($$Text.make, {
                                              element: "Span",
                                              size: "Small",
                                              children: "Go to"
                                            }), React.createElement(Icon.make, {
                                              type_: "arrowRight",
                                              size: "small"
                                            })));
                        })) : null),
            tl: /* [] */0
          };
        }
        var warnings = Belt_List.concatMany([
              match$2.inconsistentCase ? ({
                    hd: React.createElement(TextButton.make, {
                          onClick: (function (param) {
                              Curry._1(send, {
                                    TAG: /* UpdateName */1,
                                    _0: NameUtils.getCorrectCase(expectedCase$1, state.name)
                                  });
                              focusInput(undefined);
                              AnalyticsRe.namingFeedbackApplied(schemaGroup, "Suggestion", itemType, actionType, "ConsistentCasing", NameUtils.getCorrectCase(expectedCase$1, state.name), state.name, schemaGroup.branchId, schemaGroup.schemaId);
                              
                            }),
                          style: "Blue",
                          size: "Small",
                          children: null
                        }, React.createElement(DidMount.make, {
                              didMount: (function (param) {
                                  state.numberOfSuggestions = {
                                    contents: state.numberOfSuggestions.contents + 1 | 0
                                  };
                                  return AnalyticsRe.namingFeedbackReceived(schemaGroup, "Suggestion", itemType, actionType, expectedCase$1, "ConsistentCasing", schemaGroup.branchId, schemaGroup.schemaId);
                                })
                            }), (
                          forceCase$1 ? "Use" : "Try"
                        ) + " \"" + NameUtils.getCorrectCase(expectedCase$1, state.name) + "\" for consistent casing."),
                    tl: /* [] */0
                  }) : /* [] */0,
              tmp$2
            ]);
        tmp$1 = React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.grey70,
              children: Belt_List.toArray(Belt_List.map(warnings, (function (warning) {
                          return React.createElement("div", undefined, warning);
                        })))
            });
      }
    }
    var match$3 = state.validation;
    var tmp$3;
    if (typeof match$3 === "object") {
      var match$4 = match$3.VAL;
      tmp$3 = match$4.isIllegal || forceCase$1 && match$4.inconsistentCase ? true : false;
    } else {
      tmp$3 = true;
    }
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.position("absolute"),
                tl: {
                  hd: Css.right(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(hasAvoIntelligence ? -100 : -50)),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.height(Css.px(50)),
                        tl: {
                          hd: Css.padding(Css.px(20)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.grey20),
                            tl: {
                              hd: Css.borderBottomRightRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                  tl: {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              })
        }, tmp$1, React.createElement(Spacer.make, {
              width: 8,
              grow: 1.0
            }), React.createElement(Button.make, {
              label: "Cancel",
              onClick: (function (param) {
                  handleEscape(true);
                  return logClose("PressCancelButton", false);
                }),
              style: "ghost"
            }), React.createElement(Spacer.make, {
              width: 4
            }), React.createElement(Button.make, {
              disabled: tmp$3,
              label: submitLabel,
              onClick: (function (param) {
                  handleSubmit(undefined);
                  return logClose("PressConfirmButton", name$1 !== state.name);
                })
            }));
  } else {
    tmp = null;
  }
  var tmp$4;
  if (state.hasFocus && hasAvoIntelligence) {
    var match$5 = state.intelligenceFeedback;
    var tmp$5;
    if (typeof match$5 === "object") {
      var feedbackChunks = Belt_Array.keepMap(match$5.VAL.split(/(<.+?>)/g), (function (chunk) {
              return chunk;
            }));
      tmp$5 = React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.width(Css.px(500)),
                  tl: /* [] */0
                })
          }, React.createElement($$Text.make, {
                size: "Small",
                weight: "Semi",
                color: Styles.Color.grey80,
                children: Belt_Array.map(feedbackChunks, (function (chunk) {
                        if (!chunk.startsWith("<")) {
                          return chunk;
                        }
                        var cleanName = chunk.replace("<", "").replace(">", "");
                        var displayName = chunk.replace("<", "\"").replace(">", "\"");
                        return React.createElement("a", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("inlineBlock"),
                                          tl: {
                                            hd: Css.padding2(Css.px(0), Css.px(2)),
                                            tl: {
                                              hd: Css.cursor("pointer"),
                                              tl: {
                                                hd: Css.color(Styles.Color.blue),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }),
                                    onClick: (function (param) {
                                        AnalyticsRe.namingFeedbackApplied(schemaGroup, "Suggestion", itemType, actionType, "Intelligence", cleanName, state.name, schemaGroup.branchId, schemaGroup.schemaId);
                                        return Curry._1(send, {
                                                    TAG: /* UpdateName */1,
                                                    _0: cleanName
                                                  });
                                      })
                                  }, React.createElement($$Text.make, {
                                        size: "Small",
                                        weight: "Semi",
                                        color: Styles.Color.blue,
                                        children: displayName
                                      }));
                      }))
              }), React.createElement(DidMount.make, {
                didMount: (function (param) {
                    state.numberOfSuggestions = {
                      contents: state.numberOfSuggestions.contents + 1 | 0
                    };
                    return AnalyticsRe.namingFeedbackReceived(schemaGroup, "Suggestion", itemType, actionType, undefined, "Intelligence", schemaGroup.branchId, schemaGroup.schemaId);
                  })
              }));
    } else {
      tmp$5 = match$5 === "Init" ? React.createElement($$Text.make, {
              size: "Small",
              color: Styles.Color.grey60,
              children: "Start typing to receive feedback from Avo Intelligence"
            }) : React.createElement(LoadingCircle.make, {
              size: 12,
              color: Styles.Color.blue70
            });
    }
    tmp$4 = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.position("absolute"),
                tl: {
                  hd: Css.right(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(-50)),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.height(Css.px(50)),
                        tl: {
                          hd: Css.padding(Css.px(20)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.grey20),
                            tl: {
                              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                              tl: {
                                hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              })
        }, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(1), Css.px(6)),
                    tl: {
                      hd: Css.marginRight(Css.px(8)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.blue),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Tiny",
                  weight: "Semi",
                  color: Styles.Color.white,
                  children: "AI BETA"
                })), tmp$5);
  } else {
    tmp$4 = null;
  }
  return React.createElement("div", undefined, state.hasFocus ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("fixed"),
                          tl: {
                            hd: Css.top(Css.px(0)),
                            tl: {
                              hd: Css.right(Css.px(0)),
                              tl: {
                                hd: Css.bottom(Css.px(0)),
                                tl: {
                                  hd: Css.left(Css.px(0)),
                                  tl: {
                                    hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                    tl: {
                                      hd: Css.backgroundColor(Styles.Color.grey70Bg),
                                      tl: {
                                        hd: Css.cursor("pointer"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }),
                    onClick: (function (param) {
                        handleEscape(false);
                        return logClose("ClickOutside", false);
                      })
                  }) : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.bottom(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.width(Css.pct(100)),
                                  tl: {
                                    hd: Css.zIndex(state.hasFocus ? Styles.ZIndex.aboveAll : 0),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  onClick: (function ($$event) {
                      $$event.preventDefault();
                      
                    })
                }, React.createElement("input", {
                      ref: (function (inputRef) {
                          state.inputRef.contents = (inputRef == null) ? undefined : Caml_option.some(inputRef);
                          
                        }),
                      className: Curry._1(Css.style, Belt_List.concatMany([
                                Styles.input,
                                {
                                  hd: Css.position("absolute"),
                                  tl: {
                                    hd: Css.top(Css.px(0)),
                                    tl: {
                                      hd: Css.right(Css.px(0)),
                                      tl: {
                                        hd: Css.bottom(Css.px(0)),
                                        tl: {
                                          hd: Css.left(Css.px(0)),
                                          tl: {
                                            hd: Css.width(Css.pct(100)),
                                            tl: {
                                              hd: Css.padding(Css.px(20)),
                                              tl: {
                                                hd: Css.outlineWidth(Css.px(0)),
                                                tl: {
                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                  tl: {
                                                    hd: Css.fontSize(Styles.FontSize.medium),
                                                    tl: {
                                                      hd: Css.backgroundColor(state.hasFocus ? Styles.Color.white : "transparent"),
                                                      tl: {
                                                        hd: Css.borderTopLeftRadius(Styles.Border.radius),
                                                        tl: {
                                                          hd: Css.borderTopRightRadius(Styles.Border.radius),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                },
                                inputStyles
                              ])),
                      autoFocus: autoFocus,
                      disabled: disabled,
                      placeholder: name$1 === "" ? Case.to_(expectedCase$1, placeholder) : name$1,
                      value: state.hasFocus ? state.name : name$1,
                      onKeyDown: (function ($$event) {
                          var match = $$event.key;
                          switch (match) {
                            case "Enter" :
                                handleSubmit(undefined);
                                return logClose("PressEnterKey", name$1 !== state.name);
                            case "Escape" :
                                $$event.stopPropagation();
                                handleEscape(true);
                                return logClose("PressEscapeKey", false);
                            default:
                              return ;
                          }
                        }),
                      onFocus: (function (param) {
                          return Curry._1(send, {
                                      TAG: /* ToggleFocus */0,
                                      _0: true
                                    });
                        }),
                      onChange: (function ($$event) {
                          return Curry._1(send, {
                                      TAG: /* UpdateName */1,
                                      _0: $$event.target.value
                                    });
                        })
                    })), tmp, tmp$4);
}

var make = NameInput;

exports.isValid = isValid;
exports.validateInput = validateInput;
exports.validateInputWithAction = validateInputWithAction;
exports.debouncedValidation = debouncedValidation;
exports.debouncedIntelligenceFeedback = debouncedIntelligenceFeedback;
exports.make = make;
/* debouncedValidation Not a pure module */
