// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Title = require("./Title.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var EmptyState = require("./EmptyState.bs.js");
var SourceIcon = require("./SourceIcon.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var LoadingCircle = require("./LoadingCircle.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var EmptyStatesStyles = require("./emptystates/EmptyStatesStyles.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var ImplementationStatusContext = require("./ImplementationStatusContext.bs.js");
var FetchImplementationStatusUtils = require("../../shared/utils/FetchImplementationStatusUtils.bs.js");

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.paddingTop(Css.px(12)),
            tl: {
              hd: Css.paddingBottom(Css.px(12)),
              tl: {
                hd: Css.minHeight(Css.px(65)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var Style = {
  headerStyles: headerStyles
};

function Implement$EmptyState(Props) {
  var hasSources = Props.hasSources;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  React.useEffect((function () {
          AnalyticsRe.emptyStateDisplayed(schemaGroup, "Codegen", schemaGroup.schemaId);
          
        }), []);
  return React.createElement(EmptyState.Container.make, {
              artwork: "codegen",
              children: null
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(320)),
                        tl: /* [] */0
                      })
                }, React.createElement(Title.make, {
                      children: "Ship tracking 10x faster using Avo Codegen",
                      size: "Small"
                    })), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  children: "Avo Codegen generates type safe analytics wrappers based on your tracking plan in Avo. If you want to spend fewer dev hours implementing analytics, it’s a powerful addition to your workflow."
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement(Button.make, {
                  label: "Set up Codegen",
                  onClick: (function (e) {
                      AnalyticsRe.emptyStateInteracted(schemaGroup, "Codegen", "Setup", undefined);
                      return Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: hasSources ? ({
                                        NAME: "SourceSetup",
                                        VAL: [
                                          "codegenSetup",
                                          "implement"
                                        ]
                                      }) : ({
                                        NAME: "CreateSource",
                                        VAL: "codegenSetup"
                                      })
                                });
                    })
                }), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement($$Text.make, {
                  size: "Small",
                  children: null
                }, "Head to ", React.createElement("a", {
                      className: Curry._1(Css.merge, {
                            hd: EmptyStatesStyles.linkStyles,
                            tl: {
                              hd: Curry._1(Css.style, {
                                    hd: Css.textDecoration("none"),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }),
                      href: "https://www.avo.app/docs/implementation/avo-codegen-overview",
                      rel: "noopener",
                      target: "_blank",
                      onClick: (function (param) {
                          return AnalyticsRe.emptyStateInteracted(schemaGroup, "Codegen", "OpenDocs", "https://www.avo.app/docs/implementation/avo-codegen-overview");
                        })
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "our docs"
                        })), " for more details on how to set up and make the most of our publishing integrations."));
}

var EmptyState$1 = {
  make: Implement$EmptyState
};

function Implement(Props) {
  var model = Props.model;
  var match = React.useContext(ImplementationStatusContext.context).codegen;
  var invocationSources = match[2];
  var events = match[1];
  var invocationStatus = match[0];
  var match$1 = WorkspaceContext.use(undefined);
  var _lastSeenCodegen = match$1.lastSeenCodegen;
  var lastSeenCodegenForCurrentSources;
  if (typeof _lastSeenCodegen === "object") {
    var currentSourceIdDates = Belt_Array.keep(_lastSeenCodegen.VAL, (function (param) {
            var sourceId = param[0];
            return Belt_Option.isSome(Belt_List.getBy(model.sources, (function (param) {
                              return param.id === sourceId;
                            })));
          }));
    lastSeenCodegenForCurrentSources = currentSourceIdDates.length !== 0 ? ({
          NAME: "lastSeen",
          VAL: currentSourceIdDates
        }) : "neverSeen";
  } else {
    lastSeenCodegenForCurrentSources = _lastSeenCodegen === "unset" ? "unset" : "neverSeen";
  }
  var route = Router.getStaticRoute(undefined);
  var drawerItems = route.drawerItems;
  var maybeOpenSourceId = Belt_List.head(Belt_List.keepMap(drawerItems, (function (item) {
              if (typeof item === "object" && item.NAME === "codegenSource") {
                return item.VAL;
              }
              
            })));
  React.useEffect((function () {
          if (lastSeenCodegenForCurrentSources !== "unset" && lastSeenCodegenForCurrentSources !== "neverSeen" && maybeOpenSourceId === undefined) {
            var maybeSourceId = Belt_Option.mapU(Belt_List.head(model.sources), (function (source) {
                    return source.id;
                  }));
            if (maybeSourceId !== undefined) {
              var drawerItem = {
                NAME: "codegenSource",
                VAL: maybeSourceId
              };
              Router.Schema.pushDrawerItem(undefined, drawerItem);
            }
            
          }
          
        }), [
        invocationStatus,
        lastSeenCodegenForCurrentSources
      ]);
  return React.createElement("div", {
              key: "source-instructions",
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.paddingLeft(Css.px(24)),
                        tl: {
                          hd: Css.paddingRight(Css.px(40)),
                          tl: {
                            hd: Css.maxWidth(Css.pct(100.0)),
                            tl: {
                              hd: Css.marginTop(Css.px(-15)),
                              tl: {
                                hd: Css.height("auto"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("header", {
                  className: headerStyles
                }, React.createElement(Title.make, {
                      children: "Codegen",
                      size: "Tiny"
                    }), lastSeenCodegenForCurrentSources === "unset" ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.paddingLeft(Css.px(16)),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("flexEnd"),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement(LoadingCircle.make, {
                            color: Styles.Color.grey60
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            children: "Loading data..."
                          })) : null), lastSeenCodegenForCurrentSources === "unset" ? null : (
                model.sources === /* [] */0 ? React.createElement(Implement$EmptyState, {
                        hasSources: model.sources !== /* [] */0
                      }) : (
                    typeof lastSeenCodegenForCurrentSources === "object" ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.width(Css.px(350)),
                                  tl: {
                                    hd: Css.flexShrink(0.0),
                                    tl: /* [] */0
                                  }
                                })
                          }, Belt_List.toArray(Belt_List.mapU(model.sources, (function (source) {
                                      var isActive = Caml_obj.caml_equal(source.id, maybeOpenSourceId);
                                      var match = AvoConfig.supportsInvocations(source);
                                      var match$1 = FetchImplementationStatusUtils.getInvocationsWithoutStatus([
                                            invocationStatus,
                                            events,
                                            invocationSources
                                          ]);
                                      var tmp;
                                      var exit = 0;
                                      if (match) {
                                        if (invocationStatus === "Ready") {
                                          if (typeof match$1 === "object") {
                                            if (match$1.NAME === "Success") {
                                              var invocations = match$1.VAL;
                                              if (Belt_Option.isSome(Js_dict.get(invocationSources, source.id))) {
                                                var sourceEvents = StateUtils.getSourceEvents(true, model, source.id);
                                                var seenSourceEvents = Belt_List.keepU(sourceEvents, (function (sourceEvent) {
                                                        return Belt_List.length(FetchImplementationStatusUtils.getInvocations(sourceEvent.id, source.id, undefined, undefined, undefined, invocations)) > 0;
                                                      }));
                                                tmp = React.createElement($$Text.make, {
                                                      size: "Small",
                                                      textAlign: "right",
                                                      children: String(Belt_List.length(seenSourceEvents)) + " of " + String(Belt_List.length(sourceEvents)) + " events seen valid in dev"
                                                    });
                                              } else {
                                                exit = 1;
                                              }
                                            } else {
                                              exit = match$1 === "Loading" ? 2 : 1;
                                            }
                                          } else {
                                            exit = match$1 === "Loading" ? 2 : 1;
                                          }
                                        } else {
                                          exit = 2;
                                        }
                                      } else {
                                        tmp = null;
                                      }
                                      switch (exit) {
                                        case 1 :
                                            tmp = React.createElement($$Text.make, {
                                                  size: "Small",
                                                  weight: "Semi",
                                                  color: isActive ? Styles.Color.white : Styles.Color.grapeError,
                                                  children: "Not set up"
                                                });
                                            break;
                                        case 2 :
                                            tmp = React.createElement(LoadingCircle.make, {
                                                  size: 12,
                                                  color: Styles.Color.grey40
                                                });
                                            break;
                                        
                                      }
                                      return React.createElement("button", {
                                                  key: source.id,
                                                  className: Curry._1(Css.merge, {
                                                        hd: Curry._1(Css.style, Styles.button),
                                                        tl: {
                                                          hd: Curry._1(Css.style, {
                                                                hd: Css.display("flex"),
                                                                tl: {
                                                                  hd: Css.justifyContent("spaceBetween"),
                                                                  tl: {
                                                                    hd: Css.alignItems("center"),
                                                                    tl: {
                                                                      hd: Css.width(Css_Legacy_Core.Calc.$neg(Css.pct(100), Css.px(10))),
                                                                      tl: {
                                                                        hd: Css.margin4(Css.px(0), Css.px(5), Css.px(5), Css.px(0)),
                                                                        tl: {
                                                                          hd: Css.padding(Css.px(15)),
                                                                          tl: {
                                                                            hd: Css.color(isActive ? Styles.Color.white : Styles.Color.grey90),
                                                                            tl: {
                                                                              hd: Css.backgroundColor(isActive ? Styles.Color.blue : Styles.Color.white),
                                                                              tl: {
                                                                                hd: Css.borderRadius(Styles.Border.radius),
                                                                                tl: {
                                                                                  hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                                                  tl: /* [] */0
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }),
                                                          tl: /* [] */0
                                                        }
                                                      }),
                                                  onClick: (function (param) {
                                                      return Router.Schema.pushDrawerItem(undefined, {
                                                                  NAME: "codegenSource",
                                                                  VAL: source.id
                                                                });
                                                    })
                                                }, React.createElement("div", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.display("flex"),
                                                            tl: {
                                                              hd: Css.alignItems("center"),
                                                              tl: {
                                                                hd: Css.justifyContent("flexStart"),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          })
                                                    }, React.createElement(SourceIcon.make, {
                                                          source: source,
                                                          size: 16,
                                                          color: isActive ? Styles.Color.white : Styles.Color.grey90
                                                        }), React.createElement("div", {
                                                          className: Curry._1(Css.style, {
                                                                hd: Css.marginLeft(Css.px(10)),
                                                                tl: /* [] */0
                                                              })
                                                        }), React.createElement($$Text.make, {
                                                          weight: "Semi",
                                                          textAlign: "left",
                                                          children: AvoConfig.getSourceName(source)
                                                        })), tmp);
                                    })))) : React.createElement(Implement$EmptyState, {
                            hasSources: model.sources !== /* [] */0
                          })
                  )
              ));
}

var make = Implement;

exports.Style = Style;
exports.EmptyState = EmptyState$1;
exports.make = make;
/* headerStyles Not a pure module */
