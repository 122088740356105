// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_math = require("rescript/lib/js/js_math.js");
var Shortid = require("shortid");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Downloadjs = require("downloadjs");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_SetInt = require("rescript/lib/js/belt_SetInt.js");
var AvoCsvExport = require("../codegen/src/AvoCsvExport.bs.js");
var ExportFormat = require("../shared/models/ExportFormat.bs.js");
var ActionsReducer = require("./src/actionsReducer.bs.js");
var TrackingPlanModel = require("../model/src/TrackingPlanModel.bs.js");

var propertyTypes = [
  "string",
  "bool",
  "int",
  "float"
];

function generateEventProperties(numProperties) {
  var dict = {};
  for(var i = 1; i <= numProperties; ++i){
    var id = Shortid();
    var type_ = Belt_Option.getWithDefault(Belt_Array.get(propertyTypes, Js_math.random_int(0, propertyTypes.length)), "string");
    var init = TrackingPlanModel.emptyProperty(id, "Event Property #" + String(i), /* EventProperty */1, type_);
    var property_id = init.id;
    var property_name = init.name;
    var property_uniqueName = init.uniqueName;
    var property_description = "Event Property #" + String(i) + " description";
    var property_type_ = init.type_;
    var property_sendAs = init.sendAs;
    var property_validations = init.validations;
    var property_auto = init.auto;
    var property_builtIn = init.builtIn;
    var property_analyticsTools = init.analyticsTools;
    var property_devPlatforms = init.devPlatforms;
    var property_list = init.list;
    var property_operation = init.operation;
    var property_optionalDeprecated = init.optionalDeprecated;
    var property_excludedSourcesDeprecated = init.excludedSourcesDeprecated;
    var property_optionalWhenInObject = init.optionalWhenInObject;
    var property_absence = init.absence;
    var property = {
      id: property_id,
      name: property_name,
      uniqueName: property_uniqueName,
      description: property_description,
      type_: property_type_,
      sendAs: property_sendAs,
      validations: property_validations,
      auto: property_auto,
      builtIn: property_builtIn,
      analyticsTools: property_analyticsTools,
      devPlatforms: property_devPlatforms,
      list: property_list,
      operation: property_operation,
      optionalDeprecated: property_optionalDeprecated,
      excludedSourcesDeprecated: property_excludedSourcesDeprecated,
      optionalWhenInObject: property_optionalWhenInObject,
      absence: property_absence
    };
    dict[id] = property;
  }
  console.log("Generated " + String(numProperties) + " event properties");
  return dict;
}

function generateUserProperties(numProperties) {
  var dict = {};
  for(var i = 1; i <= numProperties; ++i){
    var id = Shortid();
    var type_ = Belt_Option.getWithDefault(Belt_Array.get(propertyTypes, Js_math.random_int(0, propertyTypes.length)), "string");
    var init = TrackingPlanModel.emptyProperty(id, "User Property #" + String(i), /* UserProperty */2, type_);
    var property_id = init.id;
    var property_name = init.name;
    var property_uniqueName = init.uniqueName;
    var property_description = "User Property #" + String(i) + " description";
    var property_type_ = init.type_;
    var property_sendAs = init.sendAs;
    var property_validations = init.validations;
    var property_auto = init.auto;
    var property_builtIn = init.builtIn;
    var property_analyticsTools = init.analyticsTools;
    var property_devPlatforms = init.devPlatforms;
    var property_list = init.list;
    var property_operation = init.operation;
    var property_optionalDeprecated = init.optionalDeprecated;
    var property_excludedSourcesDeprecated = init.excludedSourcesDeprecated;
    var property_optionalWhenInObject = init.optionalWhenInObject;
    var property_absence = init.absence;
    var property = {
      id: property_id,
      name: property_name,
      uniqueName: property_uniqueName,
      description: property_description,
      type_: property_type_,
      sendAs: property_sendAs,
      validations: property_validations,
      auto: property_auto,
      builtIn: property_builtIn,
      analyticsTools: property_analyticsTools,
      devPlatforms: property_devPlatforms,
      list: property_list,
      operation: property_operation,
      optionalDeprecated: property_optionalDeprecated,
      excludedSourcesDeprecated: property_excludedSourcesDeprecated,
      optionalWhenInObject: property_optionalWhenInObject,
      absence: property_absence
    };
    dict[id] = property;
  }
  console.log("Generated " + String(numProperties) + " user properties");
  return dict;
}

function generateSystemProperties(numProperties) {
  var dict = {};
  for(var i = 1; i <= numProperties; ++i){
    var id = Shortid();
    var type_ = Belt_Option.getWithDefault(Belt_Array.get(propertyTypes, Js_math.random_int(0, propertyTypes.length)), "string");
    var init = TrackingPlanModel.emptyProperty(id, "System Property #" + String(i), /* SystemProperty */0, type_);
    var property_id = init.id;
    var property_name = init.name;
    var property_uniqueName = init.uniqueName;
    var property_description = "System Property #" + String(i) + " description";
    var property_type_ = init.type_;
    var property_sendAs = init.sendAs;
    var property_validations = init.validations;
    var property_auto = init.auto;
    var property_builtIn = init.builtIn;
    var property_analyticsTools = init.analyticsTools;
    var property_devPlatforms = init.devPlatforms;
    var property_list = init.list;
    var property_operation = init.operation;
    var property_optionalDeprecated = init.optionalDeprecated;
    var property_excludedSourcesDeprecated = init.excludedSourcesDeprecated;
    var property_optionalWhenInObject = init.optionalWhenInObject;
    var property_absence = init.absence;
    var property = {
      id: property_id,
      name: property_name,
      uniqueName: property_uniqueName,
      description: property_description,
      type_: property_type_,
      sendAs: property_sendAs,
      validations: property_validations,
      auto: property_auto,
      builtIn: property_builtIn,
      analyticsTools: property_analyticsTools,
      devPlatforms: property_devPlatforms,
      list: property_list,
      operation: property_operation,
      optionalDeprecated: property_optionalDeprecated,
      excludedSourcesDeprecated: property_excludedSourcesDeprecated,
      optionalWhenInObject: property_optionalWhenInObject,
      absence: property_absence
    };
    dict[id] = property;
  }
  console.log("Generated " + String(numProperties) + " system properties");
  return dict;
}

function generateEvents(numEvents) {
  var dict = {};
  for(var i = 1; i <= numEvents; ++i){
    var id = Shortid();
    var init = TrackingPlanModel.emptyEvent(id, "Event #" + String(i));
    var event_id = init.id;
    var event_name = init.name;
    var event_uniqueName = init.uniqueName;
    var event_description = "Event #" + String(i) + " description";
    var event_properties = init.properties;
    var event_propertyBundles = init.propertyBundles;
    var event_variants = init.variants;
    var event_types = init.types;
    var event_tags = init.tags;
    var event_excludeSourcesDeprecated = init.excludeSourcesDeprecated;
    var event_includeSources = init.includeSources;
    var event_includeDestinations = init.includeDestinations;
    var event_hashes = init.hashes;
    var event_propertyWhitelist = init.propertyWhitelist;
    var event_eventGroupTypeIdsWithArchive = init.eventGroupTypeIdsWithArchive;
    var event_userGroupTypeIdsWithArchive = init.userGroupTypeIdsWithArchive;
    var event_triggers = init.triggers;
    var $$event = {
      id: event_id,
      name: event_name,
      uniqueName: event_uniqueName,
      description: event_description,
      properties: event_properties,
      propertyBundles: event_propertyBundles,
      variants: event_variants,
      types: event_types,
      tags: event_tags,
      excludeSourcesDeprecated: event_excludeSourcesDeprecated,
      includeSources: event_includeSources,
      includeDestinations: event_includeDestinations,
      hashes: event_hashes,
      propertyWhitelist: event_propertyWhitelist,
      eventGroupTypeIdsWithArchive: event_eventGroupTypeIdsWithArchive,
      userGroupTypeIdsWithArchive: event_userGroupTypeIdsWithArchive,
      triggers: event_triggers
    };
    dict[id] = $$event;
  }
  console.log("Generated " + String(numEvents) + " events", dict);
  return dict;
}

function generateRandomPositions(numPositions, range) {
  var _setOfPositions = Belt_SetInt.fromArray([]);
  while(true) {
    var setOfPositions = _setOfPositions;
    if (Belt_SetInt.size(setOfPositions) >= numPositions) {
      return Belt_SetInt.toArray(setOfPositions);
    }
    _setOfPositions = Belt_SetInt.add(setOfPositions, Js_math.random_int(0, range));
    continue ;
  };
}

function generateAddEventPropertiesToEventsActions(eventsDict, eventPropertiesDict) {
  console.log("Generating actions to add properties to events");
  var events = Js_dict.values(eventsDict);
  var properties = Js_dict.values(eventPropertiesDict);
  return Belt_Array.concatMany(Belt_Array.map(events, (function ($$event) {
                    var randomNumProperties = Caml.caml_int_min(properties.length, Js_math.random_int(4, 35));
                    var propertyPositions = generateRandomPositions(randomNumProperties, properties.length);
                    var randomProperties = Belt_Array.keepMap(propertyPositions, (function (propertyPosition) {
                            return Belt_Array.get(properties, propertyPosition);
                          }));
                    return Belt_Array.map(randomProperties, (function (property) {
                                  return {
                                          NAME: "AddPropertyRef",
                                          VAL: [
                                            $$event.id,
                                            property.id
                                          ]
                                        };
                                }));
                  })));
}

function getMinMax(num, min, max) {
  return Caml_obj.caml_min(Caml_obj.caml_max(num, max), min);
}

function handleExport(model) {
  var content = AvoCsvExport.generateCsvSchema(Belt_List.map(model.sources, (function (source) {
              return TrackingPlanModel.Source.makeValidWithDefaultValues(source, "Untitled Source", "json", "JsonSchema");
            })), "generated-schema", "main", model);
  var match = ExportFormat.toHeader(/* Csv */2);
  var mimeType = match[0];
  Downloadjs(new Blob([content], {
            encoding: "UTF-8",
            type: mimeType
          }), "AvoTrackingPlan." + match[1], mimeType);
  
}

function generateModel(numEvents, numProperties) {
  console.log("Generating model with " + String(numEvents) + " events and " + String(numProperties) + " properties");
  var eventsDict = generateEvents(numEvents);
  var numOfSystemProperties = getMinMax(numProperties * 0.03 | 0, 1, 15);
  var systemPropertiesDict = generateSystemProperties(numOfSystemProperties);
  var numOfUserProperties = getMinMax(numProperties * 0.07 | 0, 1, 25);
  var userPropertiesDict = generateUserProperties(numOfUserProperties);
  var numOfEventProperties = (numProperties - numOfUserProperties | 0) - numOfSystemProperties | 0;
  var eventPropertiesDict = generateEventProperties(numOfEventProperties);
  var properties = Belt_List.fromArray(Belt_Array.map(Belt_Array.concatMany([
                Js_dict.values(systemPropertiesDict),
                Js_dict.values(userPropertiesDict),
                Js_dict.values(eventPropertiesDict)
              ]), (function (property) {
              return {
                      TAG: /* Property */1,
                      _0: property
                    };
            })));
  var model_types = TrackingPlanModel.empty.types;
  var model_propertyBundles = TrackingPlanModel.empty.propertyBundles;
  var model_events = Belt_List.fromArray(Js_dict.values(eventsDict));
  var model_migrations = TrackingPlanModel.empty.migrations;
  var model_sources = TrackingPlanModel.empty.sources;
  var model_destinations = TrackingPlanModel.empty.destinations;
  var model_groupTypes = TrackingPlanModel.empty.groupTypes;
  var model_goals = TrackingPlanModel.empty.goals;
  var model_metrics = TrackingPlanModel.empty.metrics;
  var model_archive = TrackingPlanModel.empty.archive;
  var model_openBranches = TrackingPlanModel.empty.openBranches;
  var model_branchPointer = TrackingPlanModel.empty.branchPointer;
  var model_rules = TrackingPlanModel.empty.rules;
  var model_integrations = TrackingPlanModel.empty.integrations;
  var model = {
    types: model_types,
    properties: properties,
    propertyBundles: model_propertyBundles,
    events: model_events,
    migrations: model_migrations,
    sources: model_sources,
    destinations: model_destinations,
    groupTypes: model_groupTypes,
    goals: model_goals,
    metrics: model_metrics,
    archive: model_archive,
    openBranches: model_openBranches,
    branchPointer: model_branchPointer,
    rules: model_rules,
    integrations: model_integrations
  };
  var addEventPropertiesToEventsActions = generateAddEventPropertiesToEventsActions(eventsDict, eventPropertiesDict);
  console.log("Adding properties randomly to events");
  return Belt_Array.reduce(addEventPropertiesToEventsActions, model, ActionsReducer.reduce);
}

exports.propertyTypes = propertyTypes;
exports.generateEventProperties = generateEventProperties;
exports.generateUserProperties = generateUserProperties;
exports.generateSystemProperties = generateSystemProperties;
exports.generateEvents = generateEvents;
exports.generateRandomPositions = generateRandomPositions;
exports.generateAddEventPropertiesToEventsActions = generateAddEventPropertiesToEventsActions;
exports.getMinMax = getMinMax;
exports.handleExport = handleExport;
exports.generateModel = generateModel;
/* shortid Not a pure module */
