// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var User = {};

var IdentifyOptions = {
  User: User
};

var ChangelogOptions = {};

function identify(options) {
  Canny("identify", options);
  
}

function initChangelog(options) {
  Canny("initChangelog", options);
  
}

var appID = "5d19e8b067da142623784aa2";

exports.appID = appID;
exports.IdentifyOptions = IdentifyOptions;
exports.ChangelogOptions = ChangelogOptions;
exports.identify = identify;
exports.initChangelog = initChangelog;
/* No side effect */
