// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var App = require("../../app/src/App.bs.js");
var Dopt = require("../../app/src/externals/Dopt.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Router = require("../../app/src/Router.bs.js");
var Iubenda = require("../../app/src/externals/iubenda.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Posthog = require("../../app/src/externals/Posthog.bs.js");
var Version = require("../../app/src/version.bs.js");
var Clearbit = require("../../app/src/externals/clearbit.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Intercom = require("../../app/src/externals/intercom.bs.js");
var ReactDom = require("react-dom");
var PosthogJs = require("posthog-js").default;
var AnalyticsRe = require("../../app/src/analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var AvoInspector = require("avo-inspector/reason/AvoInspector.bs.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

((require('app/src/index.css')));

var fb = Firebase.app(undefined);

try {
  Iubenda.init(undefined);
}
catch (raw_error){
  var error = Caml_js_exceptions.internalToOCamlException(raw_error);
  console.error("Could not initialize Iubenda", error);
}

((window.fb = fb));

((window.AVO_ENV = 'web'));

var posthog = PosthogJs;

Posthog.init(posthog, "phc_KuimORATdTX1DNZhtMqD0PxV9M48VNHq9qybNfH0rEO");

var inspector = process.env.NODE_ENV === "production" ? AvoInspector.make("vROIBfqRYqc4W8KLyfGf", "Prod", Version.current, undefined) : AvoInspector.make("vROIBfqRYqc4W8KLyfGf", "Dev", Version.current, undefined);

function inspectorCustomDestination_make(_env, _apiKey) {
  
}

function inspectorCustomDestination_logEvent(eventName, eventProperties) {
  AvoInspector.trackSchemaFromEvent(inspector, eventName, eventProperties);
  
}

function inspectorCustomDestination_logPage(_eventName, _eventProperties) {
  
}

function inspectorCustomDestination_revenue(_eventName, _eventProperties) {
  
}

function inspectorCustomDestination_setUserProperties(_userId, _userProperties) {
  
}

function inspectorCustomDestination_identify(_userId) {
  
}

function inspectorCustomDestination_unidentify(param) {
  
}

function inspectorCustomDestination_setGroupProperties(_groupType, _groupId, _groupProperties) {
  
}

function inspectorCustomDestination_addCurrentUserToGroup(_groupType, _groupId, _groupProperties) {
  
}

function inspectorCustomDestination_logEventWithGroups(eventName, eventProperties, _groupTypesToGroupIds) {
  AvoInspector.trackSchemaFromEvent(inspector, eventName, eventProperties);
  
}

var inspectorCustomDestination = {
  make: inspectorCustomDestination_make,
  logEvent: inspectorCustomDestination_logEvent,
  logPage: inspectorCustomDestination_logPage,
  revenue: inspectorCustomDestination_revenue,
  setUserProperties: inspectorCustomDestination_setUserProperties,
  identify: inspectorCustomDestination_identify,
  unidentify: inspectorCustomDestination_unidentify,
  setGroupProperties: inspectorCustomDestination_setGroupProperties,
  addCurrentUserToGroup: inspectorCustomDestination_addCurrentUserToGroup,
  logEventWithGroups: inspectorCustomDestination_logEventWithGroups
};

function updateIntercomCompanyProps(companyId, companyPropertiesOpt, companyName) {
  var companyProperties = companyPropertiesOpt !== undefined ? Caml_option.valFromOption(companyPropertiesOpt) : undefined;
  if (companyProperties === undefined) {
    return Intercom.update({
                company: {
                  id: companyId,
                  name: companyName
                }
              });
  }
  var intercomUpdate = (function (companyId, companyName, companyProperties) {
          if (typeof window === 'undefined') { return; }
          window.Intercom('update', {"company": Object.assign(companyProperties, {
            "id": companyId,
            "name": companyName
          })});
        });
  return intercomUpdate(companyId, Belt_Option.getWithDefault(companyName, companyId), Caml_option.valFromOption(companyProperties));
}

function trackCompanyGroupProps(groupName, groupProperties, onDetected) {
  if (groupName.toLowerCase() !== "workspace") {
    return ;
  }
  var groupPropertiesDict = Js_json.decodeObject(groupProperties);
  if (groupPropertiesDict === undefined) {
    return ;
  }
  var workspaceName = Js_dict.get(Caml_option.valFromOption(groupPropertiesDict), "Workspace Name");
  if (workspaceName !== undefined) {
    return Curry._1(onDetected, Js_json.decodeString(Caml_option.valFromOption(workspaceName)));
  }
  
}

function intercomGroupAnalyticsCustomDestination_make(_env, _apiKey) {
  
}

function intercomGroupAnalyticsCustomDestination_logEvent(_eventName, _eventProperties) {
  
}

function intercomGroupAnalyticsCustomDestination_logPage(_eventName, _eventProperties) {
  
}

function intercomGroupAnalyticsCustomDestination_revenue(_eventName, _eventProperties) {
  
}

function intercomGroupAnalyticsCustomDestination_setUserProperties(_userId, _userProperties) {
  
}

function intercomGroupAnalyticsCustomDestination_identify(_userId) {
  
}

function intercomGroupAnalyticsCustomDestination_unidentify(param) {
  
}

function intercomGroupAnalyticsCustomDestination_setGroupProperties(groupName, groupId, groupProperties) {
  return trackCompanyGroupProps(groupName, groupProperties, (function (companyName) {
                updateIntercomCompanyProps(groupId, Caml_option.some(Caml_option.some(groupProperties)), companyName);
                
              }));
}

function intercomGroupAnalyticsCustomDestination_addCurrentUserToGroup(groupName, groupId, groupProperties) {
  trackCompanyGroupProps(groupName, groupProperties, (function (companyName) {
          updateIntercomCompanyProps(groupId, Caml_option.some(Caml_option.some(groupProperties)), companyName);
          
        }));
  
}

function intercomGroupAnalyticsCustomDestination_logEventWithGroups(_eventName, eventProperties, groupTypesToGroupIds) {
  var match = Js_json.decodeObject(groupTypesToGroupIds);
  var match$1 = Js_json.decodeObject(eventProperties);
  if (match !== undefined && match$1 !== undefined) {
    var match$2 = Js_dict.get(Caml_option.valFromOption(match), "Workspace");
    var match$3 = Js_dict.get(Caml_option.valFromOption(match$1), "Schema Name");
    if (match$2 !== undefined && match$3 !== undefined) {
      Intercom.update({
            company: {
              id: Caml_option.valFromOption(match$2),
              name: Caml_option.valFromOption(match$3)
            }
          });
    }
    
  }
  
}

var intercomGroupAnalyticsCustomDestination = {
  make: intercomGroupAnalyticsCustomDestination_make,
  logEvent: intercomGroupAnalyticsCustomDestination_logEvent,
  logPage: intercomGroupAnalyticsCustomDestination_logPage,
  revenue: intercomGroupAnalyticsCustomDestination_revenue,
  setUserProperties: intercomGroupAnalyticsCustomDestination_setUserProperties,
  identify: intercomGroupAnalyticsCustomDestination_identify,
  unidentify: intercomGroupAnalyticsCustomDestination_unidentify,
  setGroupProperties: intercomGroupAnalyticsCustomDestination_setGroupProperties,
  addCurrentUserToGroup: intercomGroupAnalyticsCustomDestination_addCurrentUserToGroup,
  logEventWithGroups: intercomGroupAnalyticsCustomDestination_logEventWithGroups
};

function postHogDestination_make(_env, _apiKey) {
  
}

function postHogDestination_logEvent(eventName, eventProperties) {
  posthog.capture(eventName, Caml_option.some(eventProperties));
  
}

function postHogDestination_logPage(_eventName, _eventProperties) {
  posthog.capture("$pageview", undefined);
  
}

function postHogDestination_revenue(_eventName, _eventProperties) {
  
}

function postHogDestination_setUserProperties(userId, userProperties) {
  posthog.identify(userId, Caml_option.some(userProperties), undefined);
  
}

function postHogDestination_identify(userId) {
  posthog.identify(userId, undefined, undefined);
  
}

function postHogDestination_unidentify(param) {
  
}

function postHogDestination_setGroupProperties(_eventName, _groupId, _groupProperties) {
  
}

function postHogDestination_addCurrentUserToGroup(_groupType, _groupId, _groupProperties) {
  
}

function postHogDestination_logEventWithGroups(_eventName, _eventProperties, _groupTypesToGroupIds) {
  
}

var postHogDestination = {
  make: postHogDestination_make,
  logEvent: postHogDestination_logEvent,
  logPage: postHogDestination_logPage,
  revenue: postHogDestination_revenue,
  setUserProperties: postHogDestination_setUserProperties,
  identify: postHogDestination_identify,
  unidentify: postHogDestination_unidentify,
  setGroupProperties: postHogDestination_setGroupProperties,
  addCurrentUserToGroup: postHogDestination_addCurrentUserToGroup,
  logEventWithGroups: postHogDestination_logEventWithGroups
};

function clearbitDestination_make(_env, _apiKey) {
  
}

function clearbitDestination_logEvent(_eventName, _eventProperties) {
  
}

function clearbitDestination_logPage(_eventName, _eventProperties) {
  
}

function clearbitDestination_revenue(_eventName, _eventProperties) {
  
}

function clearbitDestination_setUserProperties(_userId, userProperties) {
  var maybeEmail = Json_decode.optional((function (param) {
          return Json_decode.field("Email", Json_decode.string, param);
        }), userProperties);
  var match = Clearbit.makeOption(undefined);
  if (maybeEmail !== undefined && match !== undefined) {
    Caml_option.valFromOption(match).identify(maybeEmail, {
          email: maybeEmail
        });
    return ;
  }
  
}

function clearbitDestination_identify(_userId) {
  
}

function clearbitDestination_unidentify(param) {
  
}

function clearbitDestination_setGroupProperties(_eventName, _groupId, _groupProperties) {
  
}

function clearbitDestination_addCurrentUserToGroup(_groupType, _groupId, _groupProperties) {
  
}

function clearbitDestination_logEventWithGroups(_eventName, _eventProperties, _groupTypesToGroupIds) {
  
}

var clearbitDestination = {
  make: clearbitDestination_make,
  logEvent: clearbitDestination_logEvent,
  logPage: clearbitDestination_logPage,
  revenue: clearbitDestination_revenue,
  setUserProperties: clearbitDestination_setUserProperties,
  identify: clearbitDestination_identify,
  unidentify: clearbitDestination_unidentify,
  setGroupProperties: clearbitDestination_setGroupProperties,
  addCurrentUserToGroup: clearbitDestination_addCurrentUserToGroup,
  logEventWithGroups: clearbitDestination_logEventWithGroups
};

function doptDestination_make(_env, apiKey) {
  return Belt_Option.forEach(apiKey, (function (apiKey) {
                return Dopt.UsersClient.init(apiKey);
              }));
}

function doptDestination_logEvent(_eventName, _eventProperties) {
  
}

function doptDestination_logPage(_eventName, _eventProperties) {
  
}

function doptDestination_revenue(_eventName, _eventProperties) {
  
}

function doptDestination_setUserProperties(userId, userProperties) {
  return Dopt.UsersClient.setUserProperties(userId, userProperties);
}

function doptDestination_identify(userId) {
  return Dopt.UsersClient.identifyUser(userId);
}

function doptDestination_unidentify(param) {
  
}

function doptDestination_setGroupProperties(_eventName, groupId, groupProperties) {
  return Dopt.UsersClient.setGroupProperties(groupId, groupProperties);
}

function doptDestination_addCurrentUserToGroup(_groupType, groupId, groupProperties) {
  return Dopt.UsersClient.associateUserWithGroup(groupId, groupProperties);
}

function doptDestination_logEventWithGroups(_eventName, _eventProperties, _groupTypesToGroupIds) {
  
}

var doptDestination = {
  make: doptDestination_make,
  logEvent: doptDestination_logEvent,
  logPage: doptDestination_logPage,
  revenue: doptDestination_revenue,
  setUserProperties: doptDestination_setUserProperties,
  identify: doptDestination_identify,
  unidentify: doptDestination_unidentify,
  setGroupProperties: doptDestination_setGroupProperties,
  addCurrentUserToGroup: doptDestination_addCurrentUserToGroup,
  logEventWithGroups: doptDestination_logEventWithGroups
};

if (process.env.NODE_ENV === "production") {
  AnalyticsRe.initAvo(undefined, {
        position: {
          TAG: /* BottomRight */0,
          _0: {
            bottom: 76,
            right: 26
          }
        }
      }, undefined, undefined, "prod", {
        client: "Web",
        version: Version.current
      }, undefined, postHogDestination, doptDestination, clearbitDestination, intercomGroupAnalyticsCustomDestination, inspectorCustomDestination, undefined);
} else {
  AnalyticsRe.initAvo(undefined, {
        position: {
          TAG: /* BottomRight */0,
          _0: {
            bottom: 76,
            right: 26
          }
        }
      }, undefined, undefined, "dev", {
        client: "Web",
        version: Version.current
      }, undefined, postHogDestination, doptDestination, clearbitDestination, intercomGroupAnalyticsCustomDestination, inspectorCustomDestination, undefined);
}

var root = document.querySelector("#root");

if (!(root == null)) {
  ReactDom.render(React.createElement(Router.Context.Provider.make, {
            children: React.createElement(App.make, {})
          }), root);
}

exports.fb = fb;
exports.posthog = posthog;
exports.inspector = inspector;
exports.inspectorCustomDestination = inspectorCustomDestination;
exports.updateIntercomCompanyProps = updateIntercomCompanyProps;
exports.trackCompanyGroupProps = trackCompanyGroupProps;
exports.intercomGroupAnalyticsCustomDestination = intercomGroupAnalyticsCustomDestination;
exports.postHogDestination = postHogDestination;
exports.clearbitDestination = clearbitDestination;
exports.doptDestination = doptDestination;
/*  Not a pure module */
