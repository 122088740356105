// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Icon = require("../Icon.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("../Hooks.bs.js");
var React = require("react");
var Router = require("../Router.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var Mantine = require("../Mantine.bs.js");
var Tooltip = require("../Tooltip.bs.js");
var Markdown = require("../Markdown.bs.js");
var Tooltips = require("../markdown/tooltips/tooltips.bs.js");
var AvoConfig = require("../../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var BranchHeader = require("../BranchHeader.bs.js");
var RingProgress = require("./RingProgress.bs.js");
var SnippetTypes = require("../detailviews/SnippetTypes.bs.js");
var IconCheckmark = require("../IconCheckmark.bs.js");
var FramerMotion = require("framer-motion");
var Debounce = require("lodash/debounce");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");
var BranchImplementStore = require("./BranchImplementStore.bs.js");
var DiffViewTypeDropdown = require("./DiffViewTypeDropdown.bs.js");
var SourceSelectDropdown = require("./SourceSelectDropdown.bs.js");
var BranchImplementationHooks = require("./BranchImplementationHooks.bs.js");
var BranchImplementationUtils = require("./BranchImplementationUtils.bs.js");
var BranchImplementationShareButton = require("./BranchImplementationShareButton.bs.js");

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.gap(Css.px(4)),
          tl: {
            hd: Css.width(Css.pct(100.0)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.93)),
              tl: {
                hd: Css.backdropFilter({
                      hd: {
                        NAME: "blur",
                        VAL: Css.px(3)
                      },
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.paddingLeft(Css.px(20)),
                  tl: {
                    hd: Css.paddingRight(Css.px(16)),
                    tl: {
                      hd: Css.marginTop(Css.px(-32)),
                      tl: {
                        hd: Css.paddingTop(Css.px(24)),
                        tl: {
                          hd: Css.paddingBottom(Css.px(20)),
                          tl: {
                            hd: Css.position("sticky"),
                            tl: {
                              hd: Css.top(Css.px(BranchHeader.branchHeaderHeight)),
                              tl: {
                                hd: Css.zIndex(1),
                                tl: {
                                  hd: Css.maxHeight(Css.px(BranchImplementationUtils.codeChangesMaximizedHeaderHeight)),
                                  tl: {
                                    hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var headerContent = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.width(Css.pct(100.0)),
          tl: /* [] */0
        }
      }
    });

var heading = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.minWidth(Css.px(480)),
          tl: {
            hd: Css.maxWidth(Css.px(934)),
            tl: {
              hd: Css.flexGrow(1.0),
              tl: /* [] */0
            }
          }
        }
      }
    });

var rightSection = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent("flexEnd"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.height(Css.pct(100.0)),
              tl: {
                hd: Css.minWidth(Css.px(BranchImplementationUtils.commentMarginWidth - 20 | 0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function getRingProgressSection(value, total, color) {
  var value$1 = value / total * 100.0 | 0;
  return {
          value: value$1,
          color: color
        };
}

var debounceScrollDetector = Debounce((function (fn) {
        return Curry._1(fn, undefined);
      }), 100, {
      leading: false,
      trailing: true
    });

function BranchImplementationHeader(Props) {
  var sourceUpdates = Props.sourceUpdates;
  var sourceUpdatesLoadingState = Props.sourceUpdatesLoadingState;
  var subRoute = Props.subRoute;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var diffViewType = BranchImplementStore.useDiffViewType(undefined);
  var setDiffViewType = BranchImplementStore.useSetDiffViewType(undefined);
  var onDiffViewTypeSelect = function (maybeDiffViewType) {
    return Belt_Option.forEach(maybeDiffViewType, setDiffViewType);
  };
  var implementedWithCodegen = Belt_List.length(Belt_List.flatten(Belt_List.map(sourceUpdates, (function (param) {
                  return BranchImplementationUtils.filterEvents("implementedWithCodegen", "updated", "completed", param[0].id, param[1]);
                }))));
  var toImplementWithCodegen = Belt_List.length(Belt_List.flatten(Belt_List.map(sourceUpdates, (function (param) {
                  return BranchImplementationUtils.filterEvents("implementedWithCodegen", "updated", "any", param[0].id, param[1]);
                }))));
  var totalEvents = Belt_List.length(Belt_List.flatten(Belt_List.map(sourceUpdates, (function (param) {
                  return BranchImplementationUtils.filterEvents("any", undefined, undefined, param[0].id, param[1]);
                }))));
  var eventCount;
  if (typeof subRoute === "object") {
    var sourceId = subRoute.VAL[0];
    eventCount = Belt_Option.mapWithDefault(Belt_List.getBy(sourceUpdates, (function (param) {
                return param[0].id === sourceId;
              })), 0, (function (param) {
            return Belt_List.length(param[1]);
          }));
  } else {
    eventCount = totalEvents;
  }
  var diffViewTypeOptions = [
    {
      label: "Unified",
      value: "unified",
      icon: React.createElement(Icon.make, {
            type_: "unifiedDiff",
            size: "small",
            color: Styles.Color.grey60
          })
    },
    {
      label: "Split",
      value: "split",
      icon: React.createElement(Icon.make, {
            type_: "splitDiff",
            size: "small",
            color: Styles.Color.grey60
          })
    },
    {
      label: "No diff",
      value: "noDiff",
      icon: React.createElement(Icon.make, {
            type_: "noDiff",
            size: "small",
            color: Styles.Color.grey60
          })
    }
  ];
  var sourceOptions = Belt_List.toArray(Belt_List.map(sourceUpdates, (function (param) {
              var sourceEvents = param[1];
              var source = param[0];
              var implementedWithCodegen = Belt_List.length(BranchImplementationUtils.filterEvents("implementedWithCodegen", "updated", "completed", source.id, sourceEvents));
              var toImplementWithCodegen = Belt_List.length(BranchImplementationUtils.filterEvents("implementedWithCodegen", "updated", "any", source.id, sourceEvents));
              var toImplementManually = Belt_List.length(BranchImplementationUtils.filterEvents("implementedManually", "updated", undefined, source.id, sourceEvents));
              var count = Belt_List.length(sourceEvents);
              return {
                      label: AvoConfig.getSourceName(source),
                      value: source.id,
                      description: count !== 1 ? String(count) + " events" : "1 event",
                      icon: sourceUpdatesLoadingState === "loading" ? React.createElement(Mantine.Loader.make, {
                              size: 22,
                              color: Styles.Color.blue
                            }) : (
                          toImplementManually > 0 && toImplementWithCodegen === 0 ? React.createElement(Icon.make, {
                                  type_: "code",
                                  size: {
                                    NAME: "int",
                                    VAL: 16
                                  },
                                  color: Styles.Color.grey50
                                }) : (
                              implementedWithCodegen === toImplementWithCodegen && toImplementWithCodegen > 0 ? React.createElement(IconCheckmark.make, {
                                      size: 14,
                                      color: Styles.Color.mintGreen
                                    }) : React.createElement(RingProgress.make, {
                                      size: 28,
                                      thickness: 3,
                                      rootColor: Styles.Color.blue20,
                                      sections: [getRingProgressSection(implementedWithCodegen, toImplementWithCodegen, Styles.Color.blue)],
                                      tooltip: String(implementedWithCodegen) + " of " + String(toImplementWithCodegen) + " event" + (
                                        toImplementWithCodegen === 1 ? " has" : "s have"
                                      ) + " been implemented using Codegen"
                                    })
                            )
                        )
                    };
            })));
  var sourceOptionsWithOverview = Belt_Array.concat([{
          label: "Overview",
          value: "Overview",
          description: String(totalEvents) + " events",
          icon: sourceUpdatesLoadingState === "loading" ? React.createElement(Mantine.Loader.make, {
                  size: 22,
                  color: Styles.Color.blue
                }) : React.createElement(RingProgress.make, {
                  size: 28,
                  thickness: 3,
                  rootColor: Styles.Color.blue20,
                  sections: [getRingProgressSection(implementedWithCodegen, toImplementWithCodegen, Styles.Color.blue)],
                  tooltip: String(implementedWithCodegen) + " of " + String(toImplementWithCodegen) + " event" + (
                    toImplementWithCodegen === 1 ? " has" : "s have"
                  ) + " been implemented using Codegen"
                })
        }], sourceOptions);
  var match = React.useState(function () {
        return false;
      });
  var setShouldMinimizeHeader = match[1];
  var shouldMinimizeHeader = match[0];
  var lastScrollY = React.useRef(0);
  React.useEffect((function () {
          var eventHandler = function (param) {
            var currentScrollY = window.scrollY;
            if (currentScrollY < lastScrollY.current) {
              Curry._1(setShouldMinimizeHeader, (function (param) {
                      return false;
                    }));
            } else {
              debounceScrollDetector(function (param) {
                    return Curry._1(setShouldMinimizeHeader, (function (param) {
                                  return currentScrollY > 100;
                                }));
                  });
            }
            lastScrollY.current = currentScrollY;
            
          };
          window.addEventListener("scroll", eventHandler);
          return (function (param) {
                    window.removeEventListener("scroll", eventHandler);
                    
                  });
        }), []);
  var setDiffViewType$1 = BranchImplementStore.useSetDiffViewType(undefined);
  var previousDiffViewType = Hooks.usePrevious1(diffViewType);
  BranchImplementationHooks.useManageDiffViewType_useOnce(diffViewType, setDiffViewType$1, (function (param) {
          var sourceName;
          if (typeof subRoute === "object") {
            var sourceId = subRoute.VAL[0];
            sourceName = Belt_Option.mapWithDefault(Belt_List.getBy(sourceUpdates, (function (param) {
                        return param[0].id === sourceId;
                      })), "N/A", (function (param) {
                    return param[0].id;
                  }));
          } else {
            sourceName = "N/A";
          }
          return AnalyticsRe.branchImplementViewConfigured(schemaGroup, SnippetTypes.DiffViewType.toAnalyticsDiffType(diffViewType), "DiffTypeChanged", SnippetTypes.DiffViewType.toAnalyticsDiffType(previousDiffViewType), sourceName, sourceName, "Authenticated", "Workspace", schemaGroup.branchId, schemaGroup.schemaId);
        }));
  var count = Belt_List.length(sourceUpdates);
  return React.createElement(FramerMotion.AnimatePresence, {
              initial: false,
              children: React.createElement(FramerMotion.motion.section, {
                    animate: shouldMinimizeHeader ? "minimized" : "expanded",
                    transition: {
                      duration: shouldMinimizeHeader ? 0.05 : 0.2
                    },
                    initial: "expanded",
                    variants: {
                      expanded: {
                        top: BranchHeader.branchHeaderHeight,
                        paddingTop: 24,
                        paddingBottom: 20
                      },
                      minimized: {
                        top: BranchHeader.branchHeaderHeight - 30 | 0,
                        paddingTop: 36,
                        paddingBottom: 8
                      }
                    },
                    className: header,
                    children: null
                  }, React.createElement("div", {
                        className: headerContent
                      }, React.createElement("div", {
                            className: heading
                          }, React.createElement("div", undefined, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.gap(Css.px(4)),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: /* [] */0
                                            }
                                          }
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Large",
                                        weight: "Semi",
                                        children: "Branch Code Changes"
                                      }), React.createElement(Tooltip.WithAnalytics.make, {
                                        fromItem: {
                                          itemId: undefined,
                                          itemName: "Code Changes",
                                          itemType: "BranchImplementation"
                                        },
                                        size: "small",
                                        title: "Code Changes",
                                        children: React.createElement(Tooltip.Content.make, {
                                              children: React.createElement(Markdown.make, {
                                                    source: Tooltips.codeSnippets
                                                  })
                                            })
                                      })), React.createElement(FramerMotion.motion.div, {
                                    animate: shouldMinimizeHeader ? "hide" : "show",
                                    transition: {
                                      duration: shouldMinimizeHeader ? 0.05 : 0.2
                                    },
                                    initial: "show",
                                    variants: {
                                      hide: {
                                        opacity: 0,
                                        height: "0px"
                                      },
                                      show: {
                                        opacity: 1,
                                        height: "auto"
                                      }
                                    },
                                    key: "extraInfo",
                                    children: React.createElement($$Text.make, {
                                          size: "Small",
                                          color: Styles.Color.grey70,
                                          children: (
                                            totalEvents !== 1 ? String(totalEvents) + " events" : "1 event"
                                          ) + " across " + (
                                            count !== 1 ? String(count) + " sources" : "1 source"
                                          ) + " " + (
                                            totalEvents !== 1 ? "are" : "is"
                                          ) + " affected by this branch"
                                        })
                                  })), React.createElement(Spacer.make, {
                                width: 4,
                                grow: 1.0
                              }), subRoute !== "overview" ? React.createElement(DiffViewTypeDropdown.make, {
                                  data: diffViewTypeOptions,
                                  icon: React.createElement(Icon.make, {
                                        type_: diffViewType === "noDiff" ? "noDiff" : (
                                            diffViewType === "unified" ? "unifiedDiff" : "splitDiff"
                                          ),
                                        size: "small",
                                        color: Styles.Color.grey70
                                      }),
                                  value: diffViewType,
                                  onChange: onDiffViewTypeSelect
                                }) : null, React.createElement(Spacer.make, {
                                width: 4
                              }), React.createElement(SourceSelectDropdown.make, {
                                data: sourceOptionsWithOverview,
                                value: typeof subRoute === "object" ? subRoute.VAL[0] : "Overview",
                                zIndex: Styles.ZIndex.sidebar,
                                eventCount: eventCount,
                                onChange: (function (value) {
                                    var tmp;
                                    tmp = value !== undefined && value !== "Overview" ? ({
                                          NAME: "source",
                                          VAL: [
                                            value,
                                            undefined
                                          ]
                                        }) : "overview";
                                    return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                                NAME: "diff",
                                                VAL: {
                                                  NAME: "implementation",
                                                  VAL: tmp
                                                }
                                              });
                                  })
                              }))), React.createElement("div", {
                        className: rightSection
                      }, React.createElement(BranchImplementationShareButton.make, {
                            schemaId: schemaGroup.schemaId,
                            branchId: schemaGroup.branchId
                          })))
            });
}

var make = BranchImplementationHeader;

exports.make = make;
/* header Not a pure module */
