// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function printBinOp(x) {
  if (x === "GT") {
    return ">";
  } else if (x === "LE") {
    return "<";
  } else if (x === "GTE") {
    return ">=";
  } else if (x === "LEQ") {
    return "<=";
  } else if (x === "NEQ") {
    return "!=";
  } else {
    return "=";
  }
}

var binOps = {
  hd: "EQ",
  tl: {
    hd: "NEQ",
    tl: {
      hd: "LE",
      tl: {
        hd: "LEQ",
        tl: {
          hd: "GT",
          tl: {
            hd: "GTE",
            tl: /* [] */0
          }
        }
      }
    }
  }
};

exports.binOps = binOps;
exports.printBinOp = printBinOp;
/* No side effect */
