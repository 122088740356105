// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var NavigatorOs = require("./navigatorOs.bs.js");

function use(metaOpt, ctrlKeyOpt, onPress, keyName) {
  var meta = metaOpt !== undefined ? metaOpt : false;
  var ctrlKey = ctrlKeyOpt !== undefined ? ctrlKeyOpt : false;
  React.useEffect((function () {
          var eventHandler = function ($$event) {
            if ($$event.key === keyName && $$event.metaKey === meta && $$event.ctrlKey === ctrlKey) {
              return Curry._1(onPress, $$event);
            }
            
          };
          window.addEventListener("keydown", eventHandler);
          return (function (param) {
                    window.removeEventListener("keydown", eventHandler);
                    
                  });
        }), [
        keyName,
        onPress
      ]);
  
}

function useWithOperatingSystemModKey(onPress, keyName) {
  var meta = NavigatorOs.os === "MacOS";
  var ctrlKey = NavigatorOs.os !== "MacOS";
  return use(meta, ctrlKey, onPress, keyName);
}

function KeyListener$WithOperatingSystemModKey(Props) {
  var onPress = Props.onPress;
  var keyName = Props.keyName;
  useWithOperatingSystemModKey(onPress, keyName);
  return null;
}

var WithOperatingSystemModKey = {
  make: KeyListener$WithOperatingSystemModKey
};

function KeyListener(Props) {
  var keyName = Props.keyName;
  var meta = Props.meta;
  var ctrlKey = Props.ctrlKey;
  var onPress = Props.onPress;
  use(meta, ctrlKey, onPress, keyName);
  return null;
}

var make = KeyListener;

exports.use = use;
exports.useWithOperatingSystemModKey = useWithOperatingSystemModKey;
exports.WithOperatingSystemModKey = WithOperatingSystemModKey;
exports.make = make;
/* react Not a pure module */
