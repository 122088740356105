// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Tag = require("./Tag.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var IconTags = require("./IconTags.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");

function DiffEventTags(Props) {
  var $$event = Props.event;
  var actions = Props.actions;
  var addedItems = Belt_List.keepMapU(actions, (function (action) {
          if (typeof action === "object" && action.NAME === "AddTag") {
            return action.VAL[1];
          }
          
        }));
  var removedItems = Belt_List.keepMapU(actions, (function (action) {
          if (typeof action === "object" && action.NAME === "RemoveTag") {
            return action.VAL[1];
          }
          
        }));
  var updatedItems = Belt_List.concat(addedItems, removedItems);
  if (updatedItems) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.padding2(Css.px(10), Css.px(0)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.margin4(Css.px(0), Css.px(10), Css.px(0), Css.px(0)),
                          tl: /* [] */0
                        })
                  }, React.createElement(IconTags.make, {
                        size: 14
                      })), React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: /* [] */0
                        })
                  }, Belt_List.toArray(Belt_List.concatMany([
                            Belt_List.mapU(Belt_List.keep($$event.tags, (function (tag) {
                                        return !Belt_List.has(updatedItems, tag, (function (prim0, prim1) {
                                                      return prim0 === prim1;
                                                    }));
                                      })), (function (tag) {
                                    return React.createElement(Tag.make, {
                                                tag: tag,
                                                tagColor: Styles.Color.grey30,
                                                key: tag + "-updated"
                                              });
                                  })),
                            Belt_List.mapU(removedItems, (function (tag) {
                                    return React.createElement(Tag.make, {
                                                tag: tag,
                                                tagColor: Styles.Color.grapeError,
                                                key: tag + "-removed"
                                              });
                                  })),
                            Belt_List.mapU(addedItems, (function (tag) {
                                    return React.createElement(Tag.make, {
                                                tag: tag,
                                                tagColor: Styles.Color.mintGreen,
                                                key: tag + "-added"
                                              });
                                  }))
                          ]))));
  } else {
    return null;
  }
}

var make = DiffEventTags;

exports.make = make;
/* Css Not a pure module */
