// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var Hooks = require("./Hooks.bs.js");
var Input = require("./Input.bs.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var $$Window = require("./externals/window.bs.js");
var Iubenda = require("./externals/iubenda.bs.js");
var Js_json = require("rescript/lib/js/js_json.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var IconSend = require("./IconSend.bs.js");
var Intercom = require("./externals/intercom.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var SsoChecks = require("./SsoChecks.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var IconPassword = require("./IconPassword.bs.js");
var App = require("firebase/app");
var QueryString = require("query-string");
var React$1 = require("@sentry/react");
var FirebaseUtils = require("./firebaseUtils.bs.js");
var IconCheckmark = require("./IconCheckmark.bs.js");
var LoadingCircle = require("./LoadingCircle.bs.js");
var OnboardingStep = require("./OnboardingStep.bs.js");
var OnboardingConfig = require("./OnboardingConfig.bs.js");
var ContinueWithGoogleButton = require("./ContinueWithGoogleButton.bs.js");
var OnboardingNewOrJoinModal = require("./OnboardingNewOrJoinModal.bs.js");
var Cookie$JustgageReasonCookie = require("@justgage/reason-cookie/src/Cookie.bs.js");

function simpleLangButtonStyles(isActive) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.button),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.width(Css.px(175)),
                        tl: {
                          hd: Css.height(Css.px(100)),
                          tl: {
                            hd: Css.border(Css.px(1), "solid", isActive ? OnboardingConfig.getColor("sources") : Styles.Color.grey30),
                            tl: {
                              hd: Css.margin4(Css.px(15), Css.px(15), Css.px(0), Css.px(0)),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.medium),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.bold),
                                  tl: {
                                    hd: Css.color(isActive ? Styles.Color.white : OnboardingConfig.getColor("sources")),
                                    tl: {
                                      hd: Css.backgroundColor(isActive ? OnboardingConfig.getColor("sources") : Styles.Color.white),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.backgroundColor(OnboardingConfig.getColor("sources")),
                                              tl: {
                                                hd: Css.color(Styles.Color.white),
                                                tl: {
                                                  hd: Css.borderColor("transparent"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function jobFunctionFromState(state) {
  var jobFunction = state.jobFunction;
  if (jobFunction !== undefined) {
    if (jobFunction >= 7 && state.customJobFunction !== "") {
      return state.customJobFunction;
    } else {
      return OnboardingConfig.getJobFunctionLabel(jobFunction);
    }
  } else {
    return "Other";
  }
}

function trackOnboardingAction(state, action) {
  return AnalyticsRe.onboardingAction(AnalyticsRe.Group.onboarding(Belt_Array.map(Belt_Set.toArray(state.sourceGroups), OnboardingConfig.sourceGroupToJs), Belt_List.toArray(state.destinations), jobFunctionFromState(state), state.hasSeenJoinInstructions), action, state.email === "" ? undefined : state.email);
}

function shouldEmailAskToJoin(emailDomain) {
  var emailDomain$1 = encodeURIComponent(Belt_Option.getWithDefault(Belt_Array.get(emailDomain.split("@"), 1), ""));
  return fetch("/api/signup/shouldEmailAskToJoin?emailDomain=" + emailDomain$1, Fetch.RequestInit.make(/* Get */0, [[
                          "Accept",
                          "application/json"
                        ]], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
                return prim.json();
              }).then(function (result) {
              return Promise.resolve(Belt_Option.getWithDefault(Js_json.decodeBoolean(result), false));
            });
}

function getInitialState(onboardingRoute) {
  var query = window.location.search.replace("?", "");
  var email = QueryString.parse(query).email;
  return {
          email: Belt_Option.mapWithDefault((email == null) ? undefined : Caml_option.some(email), "", (function (prim) {
                  return decodeURIComponent(prim);
                })),
          password: "",
          signUpStatus: "Initial",
          showNewOrJoinModal: false,
          loadingJoinInstructions: false,
          hasSeenJoinInstructions: false,
          jobFunction: undefined,
          customJobFunction: "",
          position: {
            NAME: "Step",
            VAL: onboardingRoute
          },
          emailSso: /* Unknown */0,
          sourceGroups: Belt_Set.make(OnboardingConfig.SourceGroupCmp),
          destinations: /* [] */0
        };
}

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* UpdateEmail */0 :
        var email = action._0;
        return {
                email: email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                customJobFunction: state.customJobFunction,
                position: state.position,
                emailSso: email === state.email ? state.emailSso : /* Unknown */0,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* SetJoinInstructionsLoading */1 :
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: action._0,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                customJobFunction: state.customJobFunction,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* ShowNewOrJoinModal */2 :
        var showNewOrJoinModal = action._0;
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.showNewOrJoinModal ? true : showNewOrJoinModal,
                jobFunction: state.jobFunction,
                customJobFunction: state.customJobFunction,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* UpdatePassword */3 :
        return {
                email: state.email,
                password: action._0,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                customJobFunction: state.customJobFunction,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* SsoDetected */4 :
        var match = action._0 === state.email;
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                customJobFunction: state.customJobFunction,
                position: state.position,
                emailSso: match ? (
                    action._1 ? /* HasSso */1 : /* HasNoSso */2
                  ) : state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* UpdateSignUpStatus */5 :
        return {
                email: state.email,
                password: state.password,
                signUpStatus: action._0,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                customJobFunction: state.customJobFunction,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* UpdateJobFunction */6 :
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: action._0,
                customJobFunction: state.customJobFunction,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* UpdateCustomJobFunction */7 :
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                customJobFunction: action._0,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* UpdatePosition */8 :
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                customJobFunction: state.customJobFunction,
                position: action._0,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: state.destinations
              };
    case /* ToggleSourceGroup */9 :
        var sourceGroup = action._0;
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                customJobFunction: state.customJobFunction,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: Belt_Set.has(state.sourceGroups, sourceGroup) ? Belt_Set.remove(state.sourceGroups, sourceGroup) : Belt_Set.add(state.sourceGroups, sourceGroup),
                destinations: state.destinations
              };
    case /* ToggleDestination */10 :
        var destination = action._0;
        return {
                email: state.email,
                password: state.password,
                signUpStatus: state.signUpStatus,
                showNewOrJoinModal: state.showNewOrJoinModal,
                loadingJoinInstructions: state.loadingJoinInstructions,
                hasSeenJoinInstructions: state.hasSeenJoinInstructions,
                jobFunction: state.jobFunction,
                customJobFunction: state.customJobFunction,
                position: state.position,
                emailSso: state.emailSso,
                sourceGroups: state.sourceGroups,
                destinations: Belt_List.some(state.destinations, (function (item) {
                        return item === destination;
                      })) ? Belt_List.keep(state.destinations, (function (item) {
                          return item !== destination;
                        })) : Belt_List.concat(state.destinations, {
                        hd: destination,
                        tl: /* [] */0
                      })
              };
    
  }
}

function Onboarding(Props) {
  var onboardingRoute = Props.onboardingRoute;
  var match = React.useReducer(reducer, getInitialState(onboardingRoute));
  var send = match[1];
  var state = match[0];
  var auth = Firebase.getAuth(undefined);
  var previousOnboardingRoute = Hooks.usePrevious1(onboardingRoute);
  var previousSignupStatus = Hooks.usePrevious1(state.signUpStatus);
  var pushStepToRouter = function (step) {
    return Router.push(undefined, Curry._4(Router.Link.get, {
                    NAME: "onboarding",
                    VAL: step
                  }, undefined, undefined, /* [] */0));
  };
  var initialReferrer = Cookie$JustgageReasonCookie.getAsString("initialReferrer");
  React.useEffect((function () {
          var query = window.location.search.replace("?", "");
          var email = QueryString.parse(query).email;
          var newWorkspace = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(QueryString.parse(query).new_workspace), false);
          var match = auth.currentUser;
          if (match == null) {
            if (email == null) {
              Router.replace(Curry._4(Router.Link.get, {
                        NAME: "onboarding",
                        VAL: "job"
                      }, undefined, undefined, /* [] */0));
              AnalyticsRe.onboardingStarted(undefined);
            } else {
              pushStepToRouter("job");
              AnalyticsRe.onboardingStarted(undefined);
            }
          } else if (newWorkspace) {
            pushStepToRouter("job");
          } else {
            Router.push(undefined, Router.Link.getBaseRoute(undefined, "welcome"));
          }
          Intercom.update({
                alignment: "left"
              });
          
        }), []);
  Hooks.useDidUpdate1((function (param) {
          var match = OnboardingConfig.validateStep(state, previousOnboardingRoute);
          var currentStepValid = match === "ValidEmptyState" || match === "Valid";
          var routeDirection = OnboardingConfig.getDirection(previousOnboardingRoute, onboardingRoute);
          var exit = 0;
          if (routeDirection === "Back" || routeDirection === "Forward" && currentStepValid) {
            exit = 1;
          }
          if (exit === 1) {
            Curry._1(send, {
                  TAG: /* UpdatePosition */8,
                  _0: {
                    NAME: "StartTransition",
                    VAL: [
                      previousOnboardingRoute,
                      onboardingRoute
                    ]
                  }
                });
          }
          
        }), [onboardingRoute]);
  Hooks.useDidUpdate1((function (param) {
          var match = state.signUpStatus;
          var exit = 0;
          if (previousSignupStatus === "Success") {
            if (match !== "Success") {
              exit = 1;
            }
            
          } else if (!(previousSignupStatus === "SigningUpViaGoogle" && match === "SigningUpViaGoogle")) {
            exit = 1;
          }
          if (exit === 1) {
            var match$1 = state.signUpStatus;
            if (typeof match$1 !== "object" && match$1 !== "Initial") {
              if (match$1 === "SigningUpViaEmail") {
                var formElement = document.createElement("form");
                formElement.innerHTML = document.querySelector("#form").innerHTML;
                var passwordInputElement = Curry._2($$Window.Dom.$$Element.querySelector, formElement, "input[type=password]");
                Belt_Option.forEach(passwordInputElement, (function (passwordInputElement) {
                        passwordInputElement.parentNode.removeChild(passwordInputElement);
                        
                      }));
                var formString = formElement.innerHTML;
                $$Promise.$$catch(auth.createUserWithEmailAndPassword(state.email, state.password).then(function (user) {
                          React$1.setUser({
                                id: user.user.uid
                              });
                          Curry._1(send, {
                                TAG: /* UpdateSignUpStatus */5,
                                _0: "Success"
                              });
                          AnalyticsRe.signedUp(user.user.uid, user.user.email, "Email", initialReferrer, Caml_option.nullable_to_opt(user.user.displayName));
                          AnalyticsRe.signedUpIntercom(user.user.uid, "Email", user.user.email, Caml_option.nullable_to_opt(user.user.displayName));
                          Iubenda.submit(user.user.uid, user.user.email, formString);
                          return Promise.resolve(undefined);
                        }), (function (error) {
                        var match = error.code;
                        var match$1;
                        switch (match) {
                          case "auth/email-already-in-use" :
                              match$1 = [
                                "EmailAlreadyInUse",
                                "Oh noes, that email is already taken 🦅"
                              ];
                              break;
                          case "auth/invalid-email" :
                              match$1 = [
                                "InvalidEmail",
                                "Hmm, the email address is not valid 🤔"
                              ];
                              break;
                          case "auth/operation-not-allowed" :
                              match$1 = [
                                "OperationNotAllowed",
                                "Hmm, we couldn't finish the sign up process ⏳. Please try again."
                              ];
                              break;
                          case "auth/weak-password" :
                              match$1 = [
                                "WeakPassword",
                                "That password isn't strong enough, please use a stronger password 🏋️‍"
                              ];
                              break;
                          default:
                            match$1 = [
                              "Unknown",
                              "Hmm, this is awkward, something unexpected happened while trying to sign up 🙈"
                            ];
                        }
                        return Promise.resolve((AnalyticsRe.signUpFailed(state.email, match$1[0]), pushStepToRouter("password"), Curry._1(send, {
                                          TAG: /* UpdateSignUpStatus */5,
                                          _0: {
                                            NAME: "Error",
                                            VAL: match$1[1]
                                          }
                                        })));
                      }));
              } else if (match$1 === "SigningUpViaGoogle") {
                var provider = new (App.default.auth.GoogleAuthProvider)();
                Belt_List.forEach(Firebase.Auth.googleOauthScopes, (function (scope) {
                        provider.addScope(scope);
                        
                      }));
                provider.setCustomParameters({
                      login_hint: state.email
                    });
                var formElement$1 = document.createElement("form");
                formElement$1.innerHTML = document.querySelector("#form").innerHTML;
                var passwordInputElement$1 = Curry._2($$Window.Dom.$$Element.querySelector, formElement$1, "input[type=password]");
                Belt_Option.forEach(passwordInputElement$1, (function (passwordInputElement) {
                        passwordInputElement.parentNode.removeChild(passwordInputElement);
                        
                      }));
                var formString$1 = formElement$1.innerHTML;
                $$Promise.$$catch(auth.signInWithPopup(provider).then(function (user) {
                          Curry._1(send, {
                                TAG: /* UpdateSignUpStatus */5,
                                _0: "Success"
                              });
                          React$1.setUser({
                                id: user.user.uid
                              });
                          if (user.additionalUserInfo.isNewUser) {
                            AnalyticsRe.signedUp(user.user.uid, user.user.email, "Google", initialReferrer, Caml_option.nullable_to_opt(user.user.displayName));
                            AnalyticsRe.signedUpIntercom(user.user.uid, "Google", user.user.email, Caml_option.nullable_to_opt(user.user.displayName));
                            Iubenda.submit(user.user.uid, user.user.email, formString$1);
                          } else {
                            AnalyticsRe.signedIn(user.user.uid, user.user.email, "Google");
                          }
                          return Promise.resolve(undefined);
                        }), (function (error) {
                        console.log("error", error);
                        var match = error.code;
                        var match$1;
                        switch (match) {
                          case "auth/email-already-in-use" :
                              match$1 = [
                                "EmailAlreadyInUse",
                                "Oh noes, that email is already taken 🦅"
                              ];
                              break;
                          case "auth/invalid-email" :
                              match$1 = [
                                "InvalidEmail",
                                "Hmm, the email address is not valid 🤔"
                              ];
                              break;
                          case "auth/operation-not-allowed" :
                              match$1 = [
                                "OperationNotAllowed",
                                "Hmm, we couldn't finish the sign up process ⏳. Please try again."
                              ];
                              break;
                          case "auth/weak-password" :
                              match$1 = [
                                "WeakPassword",
                                "That password isn't strong enough, please use a stronger password 🏋️‍"
                              ];
                              break;
                          default:
                            match$1 = [
                              "Unknown",
                              "Hmm, this is awkward, something unexpected happened while trying to sign up 🙈"
                            ];
                        }
                        return Promise.resolve((AnalyticsRe.signUpFailed(state.email, match$1[0]), pushStepToRouter("password"), Curry._1(send, {
                                          TAG: /* UpdateSignUpStatus */5,
                                          _0: {
                                            NAME: "Error",
                                            VAL: match$1[1]
                                          }
                                        })));
                      }));
              } else {
                pushStepToRouter("loading");
                FirebaseUtils.buildSchemaFromOnboarding(auth, state.sourceGroups, state.destinations, jobFunctionFromState(state), state.hasSeenJoinInstructions);
              }
            }
            
          }
          
        }), [state.signUpStatus]);
  var transitionTimeoutId = React.useRef(undefined);
  Hooks.useDidUpdate1((function (param) {
          var match = state.position;
          if (typeof match === "object") {
            var variant = match.NAME;
            if (variant === "StartTransition") {
              var match$1 = match.VAL;
              var toStep = match$1[1];
              var fromStep = match$1[0];
              if (toStep !== "loading") {
                var timeoutId = setTimeout((function (param) {
                        return Curry._1(send, {
                                    TAG: /* UpdatePosition */8,
                                    _0: {
                                      NAME: "FinishTransition",
                                      VAL: [
                                        fromStep,
                                        toStep
                                      ]
                                    }
                                  });
                      }), 500);
                transitionTimeoutId.current = Caml_option.some(timeoutId);
              }
              
            } else if (variant === "FinishTransition") {
              var toStep$1 = match.VAL[1];
              var timeoutId$1 = setTimeout((function (param) {
                      return Curry._1(send, {
                                  TAG: /* UpdatePosition */8,
                                  _0: {
                                    NAME: "Step",
                                    VAL: toStep$1
                                  }
                                });
                    }), 500);
              transitionTimeoutId.current = Caml_option.some(timeoutId$1);
            }
            
          }
          var match$2 = state.position;
          if (typeof match$2 === "object" && match$2.NAME === "StartTransition") {
            var match$3 = match$2.VAL[0];
            if (match$3 === "job") {
              trackOnboardingAction(state, "SelectRole");
            } else if (match$3 === "destinations") {
              trackOnboardingAction(state, "DefineDestination");
            } else if (match$3 === "sources") {
              trackOnboardingAction(state, "DefineSource");
            }
            
          }
          return (function (param) {
                    return Belt_Option.forEach(transitionTimeoutId.current, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), [state.position]);
  var match$1 = state.position;
  var variant = match$1.NAME;
  var currentStep;
  if (variant === "FinishTransition") {
    var match$2 = match$1.VAL[1];
    currentStep = match$2 === "job" ? "job" : (
        match$2 === "loading" ? "loading" : (
            match$2 === "destinations" ? "destinations" : (
                match$2 === "sources" ? "sources" : "password"
              )
          )
      );
  } else if (variant === "Step") {
    var match$3 = match$1.VAL;
    currentStep = match$3 === "job" ? "job" : (
        match$3 === "loading" ? "loading" : (
            match$3 === "destinations" ? "destinations" : (
                match$3 === "sources" ? "sources" : "password"
              )
          )
      );
  } else {
    var match$4 = match$1.VAL[0];
    currentStep = match$4 === "job" ? "job" : (
        match$4 === "loading" ? "loading" : (
            match$4 === "destinations" ? "destinations" : (
                match$4 === "sources" ? "sources" : "password"
              )
          )
      );
  }
  var isValid = OnboardingConfig.validateStep(state, currentStep);
  var tmp;
  if (currentStep === "job") {
    tmp = React.createElement(OnboardingStep.make, {
          step: "job",
          title: "What best describes your job function?",
          subtitle: "This helps Avo personalize your onboarding experience",
          isValid: isValid,
          onNext: (function (param) {
              return pushStepToRouter("sources");
            }),
          children: React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.marginTop(Css.px(10)),
                      tl: {
                        hd: Css.media("(max-width: 900px)", {
                              hd: Css.marginTop(Css.px(50)),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.width("maxContent"),
                          tl: /* [] */0
                        }
                      }
                    })
              }, Belt_Array.map(OnboardingConfig.jobFunctions, (function (jobFunction) {
                      var isActive = Caml_obj.caml_equal(jobFunction, state.jobFunction);
                      return React.createElement("div", {
                                  key: OnboardingConfig.getJobFunctionLabel(jobFunction),
                                  className: Curry._1(Css.merge, {
                                        hd: Curry._1(Css.style, Styles.button),
                                        tl: {
                                          hd: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.flexDirection("row"),
                                                  tl: {
                                                    hd: Css.alignItems("center"),
                                                    tl: {
                                                      hd: Css.paddingLeft(Css.px(16)),
                                                      tl: {
                                                        hd: Css.paddingRight(Css.px(16)),
                                                        tl: {
                                                          hd: Css.marginBottom(Css.px(10)),
                                                          tl: {
                                                            hd: Css.height(Css.px(52)),
                                                            tl: {
                                                              hd: Css.border(Css.px(1), "solid", isActive ? OnboardingConfig.getColor("job") : Styles.Color.grey30),
                                                              tl: {
                                                                hd: Css.color(isActive ? Styles.Color.white : OnboardingConfig.getColor("job")),
                                                                tl: {
                                                                  hd: Css.backgroundColor(isActive ? OnboardingConfig.getColor("job") : "transparent"),
                                                                  tl: {
                                                                    hd: Css.fontSize(Styles.FontSize.large),
                                                                    tl: {
                                                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                      tl: {
                                                                        hd: Css.width(Css.pct(100)),
                                                                        tl: {
                                                                          hd: Css.textAlign("left"),
                                                                          tl: {
                                                                            hd: Css.hover({
                                                                                  hd: Css.border(Css.px(1), "solid", OnboardingConfig.getColor("job")),
                                                                                  tl: /* [] */0
                                                                                }),
                                                                            tl: {
                                                                              hd: Css.focus({
                                                                                    hd: Css.border(Css.px(1), "solid", OnboardingConfig.getColor("job")),
                                                                                    tl: /* [] */0
                                                                                  }),
                                                                              tl: {
                                                                                hd: Css.borderRadius(Css.px(26)),
                                                                                tl: /* [] */0
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }),
                                  role: "button",
                                  tabIndex: 0,
                                  onClick: (function (param) {
                                      return Curry._1(send, {
                                                  TAG: /* UpdateJobFunction */6,
                                                  _0: jobFunction
                                                });
                                    })
                                }, React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.width(Css.px(28)),
                                            tl: {
                                              hd: Css.height(Css.px(28)),
                                              tl: {
                                                hd: Css.borderRadius(Css.px(14)),
                                                tl: {
                                                  hd: Css.backgroundColor(Styles.Color.white),
                                                  tl: {
                                                    hd: Css.border(Css.px(isActive ? 0 : 1), "solid", Styles.Color.grey30),
                                                    tl: {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.alignItems("center"),
                                                        tl: {
                                                          hd: Css.justifyContent("center"),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          })
                                    }, isActive ? React.createElement(IconCheckmark.make, {
                                            size: 12,
                                            color: OnboardingConfig.getColor(currentStep)
                                          }) : null), React.createElement(Spacer.make, {
                                      width: 8
                                    }), OnboardingConfig.getJobFunctionLabel(jobFunction), jobFunction === /* Other */7 && isActive ? React.createElement(React.Fragment, undefined, ":", React.createElement("form", {
                                            onSubmit: (function ($$event) {
                                                $$event.preventDefault();
                                                return Router.push(undefined, Router.Link.getBaseRoute(undefined, {
                                                                NAME: "onboarding",
                                                                VAL: "sources"
                                                              }));
                                              })
                                          }, React.createElement("input", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.marginLeft(Css.px(8)),
                                                      tl: {
                                                        hd: Css.border(Css.px(0), "solid", "transparent"),
                                                        tl: {
                                                          hd: Css.borderBottom(Css.px(2), "solid", Styles.Color.setAlpha(Styles.Color.white, 0.8)),
                                                          tl: {
                                                            hd: Css.color(Styles.Color.white),
                                                            tl: {
                                                              hd: Css.placeholder({
                                                                    hd: Css.color(Styles.Color.setAlpha(Styles.Color.white, 0.5)),
                                                                    tl: /* [] */0
                                                                  }),
                                                              tl: {
                                                                hd: Css.fontSize(Styles.FontSize.large),
                                                                tl: {
                                                                  hd: Css.background("transparent"),
                                                                  tl: {
                                                                    hd: Css.paddingBottom(Css.px(4)),
                                                                    tl: {
                                                                      hd: Css.paddingTop(Css.px(6)),
                                                                      tl: {
                                                                        hd: Css.focus({
                                                                              hd: Css.outlineStyle("none"),
                                                                              tl: {
                                                                                hd: Css.borderColor(Styles.Color.white),
                                                                                tl: /* [] */0
                                                                              }
                                                                            }),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }),
                                                autoFocus: true,
                                                placeholder: "What's your role?",
                                                type: "text",
                                                value: state.customJobFunction,
                                                onChange: (function ($$event) {
                                                    var value = $$event.target.value;
                                                    return Curry._1(send, {
                                                                TAG: /* UpdateCustomJobFunction */7,
                                                                _0: value
                                                              });
                                                  })
                                              }))) : null);
                    })))
        });
  } else if (currentStep === "loading") {
    tmp = null;
  } else if (currentStep === "destinations") {
    var skipAction = function (param) {
      var match = auth.currentUser;
      if (match == null) {
        return pushStepToRouter("password");
      } else {
        pushStepToRouter("loading");
        return FirebaseUtils.buildSchemaFromOnboarding(auth, state.sourceGroups, state.destinations, jobFunctionFromState(state), state.hasSeenJoinInstructions);
      }
    };
    tmp = React.createElement(OnboardingStep.make, {
          step: "destinations",
          title: "What are the destinations for your data?",
          subtitle: "Avo helps you document where you send your events and customize them per destination if needed",
          comfortText: "Don’t worry you can always edit your destinations later",
          isValid: isValid,
          onNext: skipAction,
          maySkip: true,
          children: React.createElement("div", undefined, Belt_Array.map(AvoConfig.groupedAnalyticsTools, (function (param) {
                      var groupName = param[0];
                      return React.createElement("div", {
                                  key: groupName,
                                  className: Curry._1(Css.style, {
                                        hd: Css.marginTop(Css.px(32)),
                                        tl: /* [] */0
                                      })
                                }, React.createElement($$Text.make, {
                                      size: "Large",
                                      weight: "Semi",
                                      color: Styles.Color.grey90,
                                      children: groupName
                                    }), React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.flexWrap("wrap"),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, Belt_Array.map(param[1], (function (tool) {
                                            var isActive = Belt_List.some(state.destinations, (function (destination) {
                                                    return destination === tool;
                                                  }));
                                            return React.createElement("button", {
                                                        key: AvoConfig.analyticsToolsLabel(tool),
                                                        className: Curry._1(Css.merge, {
                                                              hd: Curry._1(Css.style, Styles.button),
                                                              tl: {
                                                                hd: Curry._1(Css.style, {
                                                                      hd: Css.position("relative"),
                                                                      tl: {
                                                                        hd: Css.display("flex"),
                                                                        tl: {
                                                                          hd: Css.flexDirection("column"),
                                                                          tl: {
                                                                            hd: Css.alignItems("center"),
                                                                            tl: {
                                                                              hd: Css.justifyContent("center"),
                                                                              tl: {
                                                                                hd: Css.width(Css.px(175)),
                                                                                tl: {
                                                                                  hd: Css.height(Css.px(100)),
                                                                                  tl: {
                                                                                    hd: Css.border(Css.px(1), "solid", isActive ? OnboardingConfig.getColor("destinations") : Styles.Color.grey30),
                                                                                    tl: {
                                                                                      hd: Css.margin4(Css.px(15), Css.px(15), Css.px(0), Css.px(0)),
                                                                                      tl: {
                                                                                        hd: Css.fontSize(Styles.FontSize.medium),
                                                                                        tl: {
                                                                                          hd: Css.fontWeight(Styles.FontWeight.bold),
                                                                                          tl: {
                                                                                            hd: Css.color(isActive ? Styles.Color.white : OnboardingConfig.getColor("destinations")),
                                                                                            tl: {
                                                                                              hd: Css.backgroundColor(isActive ? OnboardingConfig.getColor("destinations") : Styles.Color.white),
                                                                                              tl: {
                                                                                                hd: Css.hover({
                                                                                                      hd: Css.border(Css.px(1), "solid", OnboardingConfig.getColor("destinations")),
                                                                                                      tl: /* [] */0
                                                                                                    }),
                                                                                                tl: {
                                                                                                  hd: Css.zIndex(1),
                                                                                                  tl: /* [] */0
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }),
                                                                tl: /* [] */0
                                                              }
                                                            }),
                                                        onClick: (function (param) {
                                                            return Curry._1(send, {
                                                                        TAG: /* ToggleDestination */10,
                                                                        _0: tool
                                                                      });
                                                          })
                                                      }, AvoConfig.analyticsToolsLabel(tool));
                                          }))));
                    })))
        });
  } else if (currentStep === "sources") {
    tmp = React.createElement(OnboardingStep.make, {
          step: "sources",
          title: "What are the sources of your data?",
          subtitle: "Avo makes sure the events in your tracking plan will be consistent across all your platforms",
          comfortText: "Don’t worry you can always edit your sources later",
          isValid: isValid,
          maySkip: true,
          children: React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.media("(max-width: 900px)", {
                            hd: Css.marginTop(Css.px(50)),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexWrap("wrap"),
                          tl: /* [] */0
                        }
                      }
                    })
              }, Belt_Array.map(OnboardingConfig.sourceGroups, (function (sourceGroup) {
                      var isActive = Belt_Set.has(state.sourceGroups, sourceGroup);
                      return React.createElement("button", {
                                  key: OnboardingConfig.sourceGroupToJs(sourceGroup),
                                  className: Curry._1(Css.merge, {
                                        hd: Curry._1(Css.style, Styles.button),
                                        tl: {
                                          hd: Curry._1(Css.style, {
                                                hd: Css.position("relative"),
                                                tl: {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.flexDirection("column"),
                                                    tl: {
                                                      hd: Css.alignItems("center"),
                                                      tl: {
                                                        hd: Css.justifyContent("center"),
                                                        tl: {
                                                          hd: Css.width(Css.px(175)),
                                                          tl: {
                                                            hd: Css.height(Css.px(100)),
                                                            tl: {
                                                              hd: Css.border(Css.px(1), "solid", isActive ? OnboardingConfig.getColor("sources") : Styles.Color.grey30),
                                                              tl: {
                                                                hd: Css.margin4(Css.px(15), Css.px(15), Css.px(0), Css.px(0)),
                                                                tl: {
                                                                  hd: Css.fontSize(Styles.FontSize.medium),
                                                                  tl: {
                                                                    hd: Css.fontWeight(Styles.FontWeight.bold),
                                                                    tl: {
                                                                      hd: Css.color(isActive ? Styles.Color.white : OnboardingConfig.getColor("sources")),
                                                                      tl: {
                                                                        hd: Css.backgroundColor(isActive ? OnboardingConfig.getColor("sources") : Styles.Color.white),
                                                                        tl: {
                                                                          hd: Css.hover({
                                                                                hd: Css.border(Css.px(1), "solid", OnboardingConfig.getColor("sources")),
                                                                                tl: /* [] */0
                                                                              }),
                                                                          tl: {
                                                                            hd: Css.zIndex(1),
                                                                            tl: /* [] */0
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }),
                                  onClick: (function (param) {
                                      return Curry._1(send, {
                                                  TAG: /* ToggleSourceGroup */9,
                                                  _0: sourceGroup
                                                });
                                    })
                                }, OnboardingConfig.sourceGroupToJs(sourceGroup));
                    })))
        });
  } else {
    var handleCheckForEmailDomain = function (email) {
      Curry._1(send, {
            TAG: /* SetJoinInstructionsLoading */1,
            _0: true
          });
      $$Promise.$$catch(shouldEmailAskToJoin(email).then(function (shouldAskToJoin) {
                Curry._1(send, {
                      TAG: /* SetJoinInstructionsLoading */1,
                      _0: false
                    });
                trackOnboardingAction({
                      email: email,
                      password: state.password,
                      signUpStatus: state.signUpStatus,
                      showNewOrJoinModal: state.showNewOrJoinModal,
                      loadingJoinInstructions: state.loadingJoinInstructions,
                      hasSeenJoinInstructions: state.hasSeenJoinInstructions ? true : shouldAskToJoin,
                      jobFunction: state.jobFunction,
                      customJobFunction: state.customJobFunction,
                      position: state.position,
                      emailSso: state.emailSso,
                      sourceGroups: state.sourceGroups,
                      destinations: state.destinations
                    }, "AddEMinusmail");
                if (shouldAskToJoin) {
                  Curry._1(send, {
                        TAG: /* ShowNewOrJoinModal */2,
                        _0: true
                      });
                } else {
                  Curry._1(send, {
                        TAG: /* UpdateSignUpStatus */5,
                        _0: "SigningUpViaEmail"
                      });
                }
                return Promise.resolve(undefined);
              }), (function (param) {
              Curry._1(send, {
                    TAG: /* SetJoinInstructionsLoading */1,
                    _0: false
                  });
              trackOnboardingAction(state, "AddEMinusmail");
              Curry._1(send, {
                    TAG: /* UpdateSignUpStatus */5,
                    _0: "SigningUpViaEmail"
                  });
              return Promise.resolve(undefined);
            }));
      
    };
    var match$5 = state.signUpStatus;
    var match$6 = state.emailSso;
    tmp = React.createElement(OnboardingStep.make, {
          step: "password",
          title: "Complete your account",
          subtitle: "Sign in with Google or pick a password to login",
          isValid: isValid,
          onNext: (function (param) {
              var email = state.email;
              var match = state.emailSso;
              if (typeof isValid === "object") {
                return ;
              }
              switch (match) {
                case /* Unknown */0 :
                    SsoChecks.ssoEmailCheck(email).then(function (ssoMode) {
                          if (ssoMode === /* NotSso */0) {
                            handleCheckForEmailDomain(email);
                          } else {
                            Router.push(undefined, "/login?email=" + email);
                          }
                          return Promise.resolve(undefined);
                        });
                    return ;
                case /* HasSso */1 :
                    return Router.push(undefined, Router.Link.getBaseRoute(undefined, "login") + "?email=" + state.email);
                case /* HasNoSso */2 :
                    return handleCheckForEmailDomain(email);
                
              }
            }),
          loading: state.loadingJoinInstructions,
          children: null
        }, state.showNewOrJoinModal ? React.createElement(OnboardingNewOrJoinModal.make, {
                onClose: (function (param) {
                    Curry._1(send, {
                          TAG: /* ShowNewOrJoinModal */2,
                          _0: false
                        });
                    return trackOnboardingAction(state, "DismissExistingWorkspacePrompt");
                  }),
                onCreate: (function (param) {
                    Curry._1(send, {
                          TAG: /* UpdateSignUpStatus */5,
                          _0: "SigningUpViaEmail"
                        });
                    return trackOnboardingAction(state, "ClickCreateWorkspaceOnExistingWorkspacePrompt");
                  }),
                onInvite: (function (param) {
                    return Router.push(undefined, Router.Link.getBaseRoute(undefined, "login"));
                  })
              }) : null, React.createElement("div", undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(0), Css.px(20)),
                        tl: /* [] */0
                      })
                }, React.createElement(ContinueWithGoogleButton.make, {
                      onClick: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(send, {
                                      TAG: /* UpdateSignUpStatus */5,
                                      _0: "SigningUpViaGoogle"
                                    });
                        }),
                      disabled: state.signUpStatus === "SigningUpViaGoogle",
                      colors: [
                        Styles.Color.magenta,
                        Styles.Color.magentaSecondary
                      ]
                    })), React.createElement("form", {
                  id: "form",
                  onSubmit: (function (param) {
                      return Curry._1(send, {
                                  TAG: /* UpdateSignUpStatus */5,
                                  _0: "SigningUpViaEmail"
                                });
                    })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding2(Css.px(0), Css.px(20)),
                            tl: /* [] */0
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.marginTop(Css.px(30)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.height(Css.px(1)),
                                    tl: {
                                      hd: Css.width(Css.pct(100)),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.grey40),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.margin2(Css.px(10), Css.px(30)),
                                    tl: {
                                      hd: Css.color(Styles.Color.grey50),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.flexShrink(0.0),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  })
                            }, "or use email"), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.height(Css.px(1)),
                                    tl: {
                                      hd: Css.width(Css.pct(100)),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.grey40),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }))), React.createElement("div", undefined, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey40),
                                    tl: {
                                      hd: Css.margin2(Css.px(20), Css.px(20)),
                                      tl: {
                                        hd: Css.media("(max-width: 900px)", {
                                              hd: Css.margin2(Css.px(50), Css.px(10)),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        }, React.createElement(IconSend.make, {
                              size: 20,
                              color: Styles.Color.magenta
                            }), React.createElement(Input.make, {
                              value: state.email,
                              onChange: (function (email) {
                                  Curry._1(send, {
                                        TAG: /* UpdateEmail */0,
                                        _0: email
                                      });
                                  return SsoChecks.debouncedSsoEmailCheck(email, (function (ssoMode) {
                                                return Curry._1(send, {
                                                            TAG: /* SsoDetected */4,
                                                            _0: email,
                                                            _1: ssoMode !== /* NotSso */0
                                                          });
                                              }));
                                }),
                              name: "email",
                              autoFocus: state.email === "",
                              onEnter: (function (param) {
                                  return Curry._1(send, {
                                              TAG: /* UpdateSignUpStatus */5,
                                              _0: "SigningUpViaEmail"
                                            });
                                }),
                              placeholder: "name@work.com",
                              className: Belt_List.reverse({
                                    hd: Css.padding4(Css.px(10), Css.px(0), Css.px(10), Css.px(15)),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.huge),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.borderRadius(Css.px(0)),
                                          tl: {
                                            hd: Css.display("block"),
                                            tl: {
                                              hd: Css.color(Styles.Color.grey80),
                                              tl: {
                                                hd: Css.media("(max-width: 900px)", {
                                                      hd: Css.fontSize(Css.px(20)),
                                                      tl: {
                                                        hd: Css.padding4(Css.px(10), Css.px(0), Css.px(10), Css.px(10)),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                tl: Styles.input
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  })
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey40),
                                    tl: {
                                      hd: Css.margin2(Css.px(50), Css.px(20)),
                                      tl: {
                                        hd: Css.media("(max-width: 900px)", {
                                              hd: Css.margin2(Css.px(50), Css.px(10)),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        }, React.createElement(IconPassword.make, {
                              size: 20,
                              color: Styles.Color.magenta
                            }), React.createElement(Input.make, {
                              value: state.password,
                              onChange: (function (email) {
                                  return Curry._1(send, {
                                              TAG: /* UpdatePassword */3,
                                              _0: email
                                            });
                                }),
                              name: "password",
                              autoFocus: state.email !== "",
                              password: true,
                              onEnter: (function (param) {
                                  return Curry._1(send, {
                                              TAG: /* UpdateSignUpStatus */5,
                                              _0: "SigningUpViaEmail"
                                            });
                                }),
                              placeholder: "password",
                              className: Belt_List.reverse({
                                    hd: Css.padding4(Css.px(10), Css.px(0), Css.px(10), Css.px(15)),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.huge),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.borderRadius(Css.px(0)),
                                          tl: {
                                            hd: Css.display("block"),
                                            tl: {
                                              hd: Css.color(Styles.Color.grey80),
                                              tl: {
                                                hd: Css.media("(max-width: 900px)", {
                                                      hd: Css.fontSize(Css.px(20)),
                                                      tl: {
                                                        hd: Css.padding4(Css.px(10), Css.px(0), Css.px(10), Css.px(10)),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                tl: Styles.input
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  })
                            }))), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding2(Css.px(0), Css.px(20)),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: {
                                hd: Css.color(Styles.Color.grey70),
                                tl: {
                                  hd: Css.media("(max-width: 900px)", {
                                        hd: Css.marginTop(Css.px(20)),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, "By continuing you agree to our ", React.createElement("a", {
                          href: "https://www.avo.app/terms",
                          target: "_blank"
                        }, "Terms and Conditions"), ", ", React.createElement("a", {
                          href: "https://www.avo.app/privacy",
                          target: "_blank"
                        }, "Privacy Policy"), " and ", React.createElement("a", {
                          href: "https://www.avo.app/cookies",
                          target: "_blank"
                        }, "Cookie Policy"), "."), typeof match$5 === "object" ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.color(Styles.Color.grapeError),
                                tl: {
                                  hd: Css.padding(Css.px(20)),
                                  tl: {
                                    hd: Css.marginTop(Css.px(30)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, match$5.VAL) : (
                    match$5 === "SigningUpViaGoogle" || match$5 === "SigningUpViaEmail" ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.padding(Css.px(20)),
                                    tl: {
                                      hd: Css.marginTop(Css.px(30)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement("div", undefined, React.createElement(LoadingCircle.make, {
                                    size: 16,
                                    color: Styles.Color.magenta
                                  })), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.marginLeft(Css.px(10)),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.color(Styles.Color.grey70),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, "Creating user...")) : null
                  ), match$6 !== 1 ? null : React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.color(Styles.Color.grey70),
                                tl: {
                                  hd: Css.padding(Css.px(20)),
                                  tl: {
                                    hd: Css.marginTop(Css.px(30)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, "We detected SSO enabled for this email."))));
  }
  var match$7 = state.position;
  var tmp$1;
  var exit = 0;
  if (typeof match$7 === "object") {
    var variant$1 = match$7.NAME;
    if (variant$1 === "StartTransition") {
      var match$8 = match$7.VAL;
      if (OnboardingConfig.getDirection(match$8[0], match$8[1]) === "Forward") {
        tmp$1 = {
          hd: Css.transform(Css.translate3d(Css.pct(0), Css.px(0), Css.px(0))),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: 500.0
                }, undefined, "easeInOut", "transform"),
            tl: /* [] */0
          }
        };
      } else {
        exit = 1;
      }
    } else if (variant$1 === "FinishTransition") {
      var match$9 = match$7.VAL;
      if (OnboardingConfig.getDirection(match$9[0], match$9[1]) === "Forward") {
        tmp$1 = {
          hd: Css.transform(Css.translate3d(Css.pct(-100), Css.px(0), Css.px(0))),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: 500.0
                }, undefined, "easeInOut", "transform"),
            tl: /* [] */0
          }
        };
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$1 = {
      hd: Css.transform(Css.translate3d(Css.pct(100), Css.px(0), Css.px(0))),
      tl: {
        hd: Css.transition({
              NAME: "ms",
              VAL: 0.0
            }, undefined, "easeInOut", "transform"),
        tl: /* [] */0
      }
    };
  }
  var match$10 = state.position;
  var match$11 = state.position;
  var tmp$2;
  var exit$1 = 0;
  if (typeof match$11 === "object") {
    var variant$2 = match$11.NAME;
    if (variant$2 === "StartTransition") {
      var match$12 = match$11.VAL;
      if (OnboardingConfig.getDirection(match$12[0], match$12[1]) === "Back") {
        tmp$2 = {
          hd: Css.transform(Css.translate3d(Css.pct(0), Css.px(0), Css.px(0))),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: 500.0
                }, undefined, "easeInOut", "transform"),
            tl: /* [] */0
          }
        };
      } else {
        exit$1 = 1;
      }
    } else if (variant$2 === "FinishTransition") {
      var match$13 = match$11.VAL;
      if (OnboardingConfig.getDirection(match$13[0], match$13[1]) === "Back") {
        tmp$2 = {
          hd: Css.transform(Css.translate3d(Css.pct(100), Css.px(0), Css.px(0))),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: 500.0
                }, undefined, "easeInOut", "transform"),
            tl: /* [] */0
          }
        };
      } else {
        exit$1 = 1;
      }
    } else {
      exit$1 = 1;
    }
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    tmp$2 = {
      hd: Css.transform(Css.translate3d(Css.pct(-100), Css.px(0), Css.px(0))),
      tl: {
        hd: Css.transition({
              NAME: "ms",
              VAL: 0.0
            }, undefined, "easeInOut", "transform"),
        tl: /* [] */0
      }
    };
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.minHeight(Css.vh(100)),
                      tl: {
                        hd: Css.overflow("hidden"),
                        tl: {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, tmp, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.bottom(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.backgroundColor(OnboardingConfig.getColor(onboardingRoute)),
                                  tl: {
                                    hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                    tl: tmp$1
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, typeof match$10 === "object" && match$10.NAME === "StartTransition" && match$10.VAL[1] === "loading" ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("absolute"),
                              tl: {
                                hd: Css.top(Css.px(0)),
                                tl: {
                                  hd: Css.right(Css.px(0)),
                                  tl: {
                                    hd: Css.bottom(Css.px(0)),
                                    tl: {
                                      hd: Css.left(Css.px(0)),
                                      tl: {
                                        hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                        tl: {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.justifyContent("center"),
                                              tl: {
                                                hd: Css.flexDirection("column"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }),
                        id: "creating schema"
                      }, React.createElement(LoadingCircle.make, {
                            color: Styles.Color.white
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.fontWeight(Styles.FontWeight.bold),
                                  tl: {
                                    hd: Css.color(Styles.Color.white),
                                    tl: {
                                      hd: Css.marginTop(Css.px(50)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, "Building your Avo workspace...")) : null), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.bottom(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.backgroundColor(OnboardingConfig.getColor(onboardingRoute)),
                                  tl: {
                                    hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                    tl: tmp$2
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }));
}

var make = Onboarding;

exports.simpleLangButtonStyles = simpleLangButtonStyles;
exports.jobFunctionFromState = jobFunctionFromState;
exports.trackOnboardingAction = trackOnboardingAction;
exports.shouldEmailAskToJoin = shouldEmailAskToJoin;
exports.getInitialState = getInitialState;
exports.reducer = reducer;
exports.make = make;
/* Css Not a pure module */
