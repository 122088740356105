// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("./Icons.bs.js");
var Title = require("./Title.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var SheetUtils = require("./SheetUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var SheetHeader = require("./SheetHeader.bs.js");
var ReactWindow = require("react-window");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var SheetContainer = require("./SheetContainer.bs.js");
var StateFilterUtils = require("./StateFilterUtils.bs.js");
var GlobalStateContext = require("./GlobalStateContext.bs.js");
var PropertiesSheetRow = require("./PropertiesSheetRow.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var ReactVirtualizedAutoSizer = require("react-virtualized-auto-sizer").default;

var headerStyles = Curry._1(Css.style, {
      hd: Css.height(Css.px(64)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingLeft(Css.px(30)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var sheet = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.height({
              NAME: "substract",
              VAL: [
                Css.vh(100),
                Css.px(104)
              ]
            }),
        tl: {
          hd: Css.paddingLeft(Css.px(16)),
          tl: {
            hd: Css.position("relative"),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  headerHeight: 64,
  columnsHeaderHeight: 40,
  headerStyles: headerStyles,
  sheet: sheet
};

function PropertyGroupsSheet(Props) {
  var currentBranch = Props.currentBranch;
  var globalSend = Props.globalSend;
  var model = Props.model;
  var schema = Props.schema;
  var user = Props.user;
  var viewerRole = Props.viewerRole;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = GlobalStateContext.use(undefined);
  var globalStateFilters = match.filters;
  var currentFilters = AnalyticsUtils.currentFilters(model, globalStateFilters, match.eventSort);
  var filters = Belt_List.fromArray(Belt_Array.keepMapU(globalStateFilters, StateFilterUtils.toModel));
  var sourceFilterNames = StateFilterUtils.getSourceFilterNames(globalStateFilters, model);
  var match$1 = SheetUtils.Properties.usePropertySheetColumns(user);
  var columns = match$1[0];
  var match$2 = SheetUtils.Properties.useScrollSync(undefined);
  var handleReactWindowRef = match$2.handleReactWindowRef;
  var handleOuterRef = match$2.handleOuterRef;
  var items = SheetUtils.Properties.getPropertyGroups(model.propertyBundles, model.properties);
  var hasEmptyState = model.propertyBundles === /* [] */0;
  React.useEffect((function () {
          if (hasEmptyState) {
            AnalyticsRe.emptyStateDisplayed(schemaGroup, "PropertyGroups", schema.id);
          }
          
        }), []);
  var makeItem = function (item) {
    return {
            item: item,
            columns: columns,
            schema: schema,
            currentBranch: currentBranch,
            model: model,
            globalSend: globalSend,
            sendActions: sendActions,
            schemaGroup: schemaGroup,
            currentFilters: currentFilters,
            viewerRole: viewerRole,
            filters: filters,
            sourceFilterNames: sourceFilterNames
          };
  };
  var itemData = hasEmptyState ? [makeItem(/* PropertyGroupEmptyState */2)] : Belt_Array.map(items, makeItem);
  return React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: headerStyles
                }, React.createElement(Title.make, {
                      children: null,
                      shrink: 0.0,
                      size: "Tiny"
                    }, "Event Property Bundles ", React.createElement($$Text.make, {
                          element: "Span",
                          size: "Medium",
                          color: Styles.Color.grey70,
                          children: "(" + Belt_List.length(model.propertyBundles).toString() + ")"
                        })), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement(Button.make, {
                      label: "Add Event Property Bundle",
                      onClick: (function (param) {
                          if (Belt_List.length(model.propertyBundles) === 0) {
                            AnalyticsRe.emptyStateInteracted(schemaGroup, "PropertyGroups", "Setup", undefined);
                          }
                          return Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "NewPropertyGroup",
                                        VAL: [
                                          undefined,
                                          undefined
                                        ]
                                      }
                                    });
                        }),
                      size: "small"
                    }), React.createElement(Spacer.make, {
                      width: 16,
                      grow: 1
                    }), React.createElement(Link.make, {
                      path: Router.Link.getSchemaRouteLink(undefined, "properties"),
                      children: null
                    }, React.createElement(Icons.ArrowLeft.make, {
                          size: 8,
                          color: Styles.Color.blue
                        }), React.createElement($$Text.make, {
                          element: "Span",
                          size: "Small",
                          weight: "Medium",
                          color: Styles.Color.blue,
                          children: " View All Properties"
                        })), React.createElement(Spacer.make, {
                      width: 16
                    })), React.createElement(SheetHeader.make, {
                  columns: columns,
                  setColumns: match$1[1],
                  view: /* ByCategory */0,
                  onWidthChange: (function (direction, param, columnType) {
                      return AnalyticsRe.propertiesViewConfigured(schemaGroup, direction === "Increase" ? "IncreaseColumnWidth" : "DecreaseColumnWidth", undefined, undefined, AnalyticsUtils.columnTypeToPropertyColumnsName(columnType), schemaGroup.branchId, schemaGroup.schemaId);
                    }),
                  ref: match$2.handleSheetHeaderRef
                }), React.createElement("div", {
                  className: sheet
                }, React.createElement(ReactVirtualizedAutoSizer, {
                      children: (function (size) {
                          return React.createElement(ReactWindow.VariableSizeList, {
                                      estimatedItemSize: 50,
                                      height: size.height,
                                      innerElementType: SheetContainer.make,
                                      itemCount: hasEmptyState ? itemData.length : items.length,
                                      itemData: itemData,
                                      itemKey: (function (index) {
                                          return index.toString();
                                        }),
                                      itemSize: (function (param) {
                                          return 50;
                                        }),
                                      outerRef: handleOuterRef,
                                      overscanCount: 30,
                                      ref: handleReactWindowRef,
                                      width: size.width,
                                      children: PropertiesSheetRow.make
                                    });
                        })
                    })));
}

var make = PropertyGroupsSheet;

exports.Style = Style;
exports.make = make;
/* headerStyles Not a pure module */
