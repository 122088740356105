// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function encode(t) {
  return Json_encode.object_({
              hd: [
                "schemaId",
                t.schemaId
              ],
              tl: {
                hd: [
                  "serviceAccountName",
                  t.serviceAccountName
                ],
                tl: /* [] */0
              }
            });
}

function decode(json) {
  return {
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          serviceAccountName: Json_decode.field("serviceAccountName", Json_decode.string, json)
        };
}

var $$Request = {
  encode: encode,
  decode: decode
};

function encode$1(resp) {
  return Json_encode.object_({
              hd: [
                "name",
                resp.name
              ],
              tl: {
                hd: [
                  "secret",
                  resp.secret
                ],
                tl: /* [] */0
              }
            });
}

function decode$1(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          secret: Json_decode.field("secret", Json_decode.string, json)
        };
}

var $$Response = {
  encode: encode$1,
  decode: decode$1
};

exports.$$Request = $$Request;
exports.$$Response = $$Response;
/* No side effect */
