// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var JsDiff = require("./externals/JsDiff.bs.js");
var Router = require("./Router.bs.js");
var Styles = require("./styles.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var DiffEvent = require("./DiffEvent.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var DiffComments = require("./DiffComments.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");

function renderRow(label, value) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("spaceBetween"),
                        tl: {
                          hd: Css.padding2(Css.px(15), Css.px(20)),
                          tl: {
                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flex({
                              NAME: "num",
                              VAL: 1.0
                            }),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: /* [] */0
                          }
                        }
                      })
                }, label), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flex({
                              NAME: "num",
                              VAL: 2.0
                            }),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.justifyContent("flexEnd"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, value));
}

function DiffSource(Props) {
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var match = Belt_Option.map(Belt_List.head(actions), (function (prim) {
          return prim[0];
        }));
  if (match === undefined) {
    return null;
  }
  if (typeof match !== "object") {
    return null;
  }
  if (match.NAME !== "Source") {
    return null;
  }
  var sourceId = match.VAL;
  var actions$1 = Belt_List.map(actions, (function (prim) {
          return prim[1];
        }));
  if (!actions$1) {
    return null;
  }
  var hasCreate = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "CreateSource";
          } else {
            return false;
          }
        }));
  var hasArchive = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          if (x.NAME !== "Archive") {
            return false;
          }
          var match = x.VAL;
          if (typeof match === "object") {
            return match.NAME === "Source";
          } else {
            return false;
          }
        }));
  var changeType = hasCreate !== undefined ? "Create" : (
      hasArchive !== undefined ? "Archive" : "Update"
    );
  var source = Belt_Option.getWithDefault(Belt_List.getBy(masterModel.sources, (function (source) {
              return source.id === sourceId;
            })), TrackingPlanModel.emptySource(undefined, sourceId, "", ""));
  var nextSource = Belt_Option.getWithDefault(Belt_List.getBy(branchModel.sources, (function (source) {
              return source.id === sourceId;
            })), TrackingPlanModel.emptySource(undefined, sourceId, "", ""));
  var addedOrModifiedDestinations = Belt_List.toArray(Belt_List.map(Belt_List.keep(nextSource.destinations, (function (param) {
                  var destinationId = param.destinationId;
                  return Belt_List.some(actions$1, (function (action) {
                                if (typeof action !== "object") {
                                  return false;
                                }
                                var variant = action.NAME;
                                if (variant === "ExcludeDestinationFromSource" || variant === "IncludeDestinationInSourceV2" || variant === "LegacyToggleDestination" || variant === "LegacyIncludeDestinationInSource" || variant === "UpdateSourceDestinationMode") {
                                  return action.VAL[1] === destinationId;
                                } else {
                                  return false;
                                }
                              }));
                })), (function (nextDestinationRef) {
              var nextDestinationId = nextDestinationRef.destinationId;
              return [
                      Belt_List.getBy(source.destinations, (function (param) {
                              return param.destinationId === nextDestinationId;
                            })),
                      nextDestinationRef
                    ];
            })));
  var removedDestinations = Belt_List.toArray(Belt_List.keep(source.destinations, (function (param) {
              var destinationId = param.destinationId;
              return Belt_List.every(nextSource.destinations, (function (nextDestinationRef) {
                            return destinationId !== nextDestinationRef.destinationId;
                          }));
            })));
  var destinationModeToString = function (destinationMode) {
    if (destinationMode !== 1) {
      return "Avo Managed";
    } else {
      return "Destination Interface";
    }
  };
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.maxWidth(DiffEvent.maxWidthPx),
                    tl: {
                      hd: Css.marginRight(Css.px(DiffComments.commentsWidth + 20 | 0)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: {
                              hd: Css.padding(Css.px(15)),
                              tl: {
                                hd: Css.cursor("pointer"),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.backgroundColor(Styles.Color.grey20),
                                        tl: /* [] */0
                                      }),
                                  tl: changeType === "Create" ? ({
                                        hd: Css.border(Css.px(1), "solid", Styles.Color.mintGreen),
                                        tl: /* [] */0
                                      }) : (
                                      changeType === "Update" ? ({
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                            tl: /* [] */0
                                          }) : ({
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.grapeError),
                                            tl: /* [] */0
                                          })
                                    )
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              onClick: (function (param) {
                  return Router.Schema.pushDrawerItem(undefined, {
                              NAME: "source",
                              VAL: [
                                source.id,
                                "overview"
                              ]
                            });
                })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(DiffEvent.maxWidthPx),
                        tl: /* [] */0
                      })
                }, renderRow("Source Name", JsDiff.renderStringDiff(undefined, undefined, undefined, Belt_Option.getWithDefault(source.name, ""), Belt_Option.getWithDefault(nextSource.name, ""))), Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                            if (typeof x !== "object") {
                              return false;
                            }
                            var variant = x.NAME;
                            if (variant === "UpdateSourcePlatform" || variant === "UpdateSourcePlatformV3") {
                              return true;
                            } else {
                              return variant === "UpdateSourcePlatformV2";
                            }
                          }))) ? renderRow("Development Platform", JsDiff.renderStringDiff(undefined, undefined, undefined, Belt_Option.mapWithDefault(source.platform, "", AvoConfig.devPlatformLabel), Belt_Option.mapWithDefault(nextSource.platform, "", AvoConfig.devPlatformLabel))) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                            if (typeof x === "object") {
                              return x.NAME === "UpdateProgrammingLanguage";
                            } else {
                              return false;
                            }
                          }))) ? renderRow("Programming Language", JsDiff.renderStringDiff(undefined, undefined, undefined, Belt_Option.mapWithDefault(source.language, "", AvoConfig.languageLabel), Belt_Option.mapWithDefault(nextSource.language, "", AvoConfig.languageLabel))) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                            if (typeof x === "object") {
                              return x.NAME === "UpdateLibraryName";
                            } else {
                              return false;
                            }
                          }))) ? renderRow("Library Name", JsDiff.renderStringDiff(undefined, undefined, undefined, source.filename, nextSource.filename)) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                            if (typeof x === "object") {
                              return x.NAME === "UpdateLibraryDestination";
                            } else {
                              return false;
                            }
                          }))) ? renderRow("Library Destination", JsDiff.renderStringDiff(undefined, undefined, undefined, source.path, nextSource.path)) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                            if (typeof x !== "object") {
                              return false;
                            }
                            var variant = x.NAME;
                            if (variant === "ExcludeDestinationFromSource" || variant === "IncludeDestinationInSourceV2" || variant === "LegacyToggleDestination" || variant === "LegacyIncludeDestinationInSource") {
                              return true;
                            } else {
                              return variant === "UpdateSourceDestinationMode";
                            }
                          }))) ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding2(Css.px(5), Css.px(20)),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.color(Styles.Color.grey80),
                                  tl: {
                                    hd: Css.marginTop(Css.px(15)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, "Destinations") : null), Belt_Array.map(addedOrModifiedDestinations, (function (param) {
                    var nextDestination = param[1];
                    var maybePreviousDestination = param[0];
                    return Belt_Option.mapWithDefault(Belt_List.getBy(branchModel.destinations, (function (destination) {
                                      return destination.id === nextDestination.destinationId;
                                    })), null, (function (destination) {
                                  return React.createElement(React.Fragment, {
                                              children: renderRow(StateUtils.getDestinationName(destination), JsDiff.renderStringDiff("inline", true, false, Belt_Option.mapWithDefault(maybePreviousDestination, "", (function (param) {
                                                              return destinationModeToString(param.destinationMode);
                                                            })), destinationModeToString(nextDestination.destinationMode))),
                                              key: destination.id + "-addedOrModified"
                                            });
                                }));
                  })), Belt_Array.map(removedDestinations, (function (removedDestination) {
                    return Belt_Option.mapWithDefault(Belt_List.getBy(masterModel.destinations, (function (destination) {
                                      return destination.id === removedDestination.destinationId;
                                    })), null, (function (destination) {
                                  return React.createElement(React.Fragment, {
                                              children: renderRow(StateUtils.getDestinationName(destination), React.createElement("span", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.color(Styles.Color.grapeError),
                                                              tl: {
                                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                tl: /* [] */0
                                                              }
                                                            })
                                                      }, "Off")),
                                              key: destination.id + "-toggle"
                                            });
                                }));
                  })));
}

var make = DiffSource;

exports.renderRow = renderRow;
exports.make = make;
/* Css Not a pure module */
