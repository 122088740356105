// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("./utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("../app/src/stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ActionsReducer = require("../app/src/actionsReducer.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var TrackingPlanModel = require("../model/src/TrackingPlanModel.bs.js");
var BeltListExtensions = require("../app/src/BeltListExtensions.bs.js");

function diffList(fromList, toList, compare) {
  var adds = Belt_List.keepMapU(toList, (function (toItem) {
          var match = Belt_List.getByU(fromList, (function (fromItem) {
                  return Curry._2(compare, toItem, fromItem);
                }));
          if (match !== undefined) {
            return ;
          } else {
            return {
                    NAME: "Add",
                    VAL: toItem
                  };
          }
        }));
  var removes = Belt_List.keepMapU(fromList, (function (fromItem) {
          var match = Belt_List.getByU(toList, (function (toItem) {
                  return Curry._2(compare, fromItem, toItem);
                }));
          if (match !== undefined) {
            return ;
          } else {
            return {
                    NAME: "Remove",
                    VAL: fromItem
                  };
          }
        }));
  return Belt_List.concat(removes, adds);
}

function diffArray(fromArray, toArray, compare) {
  var adds = Belt_Array.keepMapU(toArray, (function (toItem) {
          var match = Belt_Array.getByU(fromArray, (function (fromItem) {
                  return Curry._2(compare, toItem, fromItem);
                }));
          if (match !== undefined) {
            return ;
          } else {
            return {
                    NAME: "Add",
                    VAL: toItem
                  };
          }
        }));
  var removes = Belt_Array.keepMapU(fromArray, (function (fromItem) {
          var match = Belt_Array.getByU(toArray, (function (toItem) {
                  return Curry._2(compare, fromItem, toItem);
                }));
          if (match !== undefined) {
            return ;
          } else {
            return {
                    NAME: "Remove",
                    VAL: fromItem
                  };
          }
        }));
  return Belt_Array.concat(removes, adds);
}

function diffStringArray(fromArray, toArray) {
  return diffArray(fromArray, toArray, Caml_obj.caml_equal);
}

function diffListOrder(fromList, toList, getIndex) {
  return Belt_List.keepMapU(Belt_List.mapWithIndex(toList, (function (index, item) {
                    return [
                            index,
                            item
                          ];
                  })), (function (param) {
                var item = param[1];
                var fromListWithIndex = Belt_List.mapWithIndexU(fromList, (function (item, index) {
                        return [
                                item,
                                index
                              ];
                      }));
                var prevIndex = Curry._2(getIndex, fromListWithIndex, item);
                var prevPredecessor = Belt_Option.flatMapU(prevIndex, (function (prevIndex) {
                        return Belt_List.get(Belt_List.keepMapU(fromListWithIndex, (function (param) {
                                          if (param[0] === (prevIndex - 1 | 0)) {
                                            return Caml_option.some(param[1]);
                                          }
                                          
                                        })), 0);
                      }));
                var nextPredecessor = Belt_List.get(toList, param[0] - 1 | 0);
                if (Caml_obj.caml_equal(prevPredecessor, nextPredecessor)) {
                  return ;
                } else {
                  return {
                          NAME: "Reorder",
                          VAL: [
                            item,
                            nextPredecessor
                          ]
                        };
                }
              }));
}

function diffStringList(fromList, toList) {
  return diffList(fromList, toList, Caml_obj.caml_equal);
}

function diffOption(from, to_) {
  if (from === undefined) {
    if (to_ !== undefined) {
      return {
              hd: {
                NAME: "Update",
                VAL: Caml_option.valFromOption(to_)
              },
              tl: /* [] */0
            };
    } else {
      return /* [] */0;
    }
  }
  if (to_ === undefined) {
    return /* [] */0;
  }
  var to_$1 = Caml_option.valFromOption(to_);
  if (Caml_obj.caml_equal(Caml_option.valFromOption(from), to_$1)) {
    return /* [] */0;
  } else {
    return {
            hd: {
              NAME: "Update",
              VAL: to_$1
            },
            tl: /* [] */0
          };
  }
}

function diffEventProperties(fromProperties, toProperties, eventId) {
  var addedAndRemovedRefActions = BeltListExtensions.flatMap(diffList(fromProperties, toProperties, (function (a, b) {
              if (a.TAG === /* PropertyRef */0 && b.TAG === /* PropertyRef */0) {
                return a._0.id === b._0.id;
              } else {
                return false;
              }
            })), (function (change) {
          if (typeof change !== "object") {
            return /* [] */0;
          }
          var variant = change.NAME;
          if (variant === "Add") {
            var propertyRef = change.VAL;
            if (propertyRef.TAG !== /* PropertyRef */0) {
              return /* [] */0;
            }
            var propertyRef$1 = propertyRef._0;
            var pinnedValue = propertyRef$1.pinnedValue;
            return Belt_List.concat({
                        hd: {
                          NAME: "AddPropertyRef",
                          VAL: [
                            eventId,
                            propertyRef$1.id
                          ]
                        },
                        tl: /* [] */0
                      }, pinnedValue !== undefined ? ({
                            hd: {
                              NAME: "UpdatePropertyPinnedValue",
                              VAL: [
                                eventId,
                                propertyRef$1.id,
                                pinnedValue
                              ]
                            },
                            tl: /* [] */0
                          }) : /* [] */0);
          }
          if (variant !== "Remove") {
            return /* [] */0;
          }
          var propertyRef$2 = change.VAL;
          if (propertyRef$2.TAG === /* PropertyRef */0) {
            return {
                    hd: {
                      NAME: "RemovePropertyRefV2",
                      VAL: [
                        eventId,
                        propertyRef$2._0.id
                      ]
                    },
                    tl: /* [] */0
                  };
          } else {
            return /* [] */0;
          }
        }));
  var updatedRefActions = Belt_List.keepMap(toProperties, (function (toProperty) {
          if (toProperty.TAG !== /* PropertyRef */0) {
            return ;
          }
          var toPropertyRef = toProperty._0;
          var maybeFromPropertyRef = Belt_List.head(Belt_List.keepMap(fromProperties, (function (fromProperty) {
                      if (fromProperty.TAG !== /* PropertyRef */0) {
                        return ;
                      }
                      var fromPropertyRef = fromProperty._0;
                      if (fromPropertyRef.id === toPropertyRef.id) {
                        return fromPropertyRef;
                      }
                      
                    })));
          return Belt_Option.flatMap(maybeFromPropertyRef, (function (fromPropertyRef) {
                        var match = fromPropertyRef.pinnedValue;
                        var match$1 = toPropertyRef.pinnedValue;
                        var toPinnedValue;
                        if (match !== undefined) {
                          if (match$1 === undefined) {
                            return {
                                    NAME: "RemovePropertyPinnedValue",
                                    VAL: [
                                      eventId,
                                      toPropertyRef.id
                                    ]
                                  };
                          }
                          if (Caml_obj.caml_equal(match, match$1)) {
                            return ;
                          }
                          toPinnedValue = match$1;
                        } else {
                          if (match$1 === undefined) {
                            return ;
                          }
                          toPinnedValue = match$1;
                        }
                        return {
                                NAME: "UpdatePropertyPinnedValue",
                                VAL: [
                                  eventId,
                                  toPropertyRef.id,
                                  toPinnedValue
                                ]
                              };
                      }));
        }));
  return Belt_List.concat(addedAndRemovedRefActions, updatedRefActions);
}

function diffEventPropertyGroups(fromPropertyGroups, toPropertyGroups, eventId) {
  return Belt_List.keepMap(diffList(fromPropertyGroups, toPropertyGroups, (function (a, b) {
                    return a.id === b.id;
                  })), (function (change) {
                if (typeof change !== "object") {
                  return ;
                }
                var variant = change.NAME;
                if (variant === "Add") {
                  return {
                          NAME: "AddPropertyGroupToEvent",
                          VAL: [
                            eventId,
                            change.VAL.id
                          ]
                        };
                } else if (variant === "Remove") {
                  return {
                          NAME: "RemovePropertyGroupFromEvent",
                          VAL: [
                            eventId,
                            change.VAL.id
                          ]
                        };
                } else {
                  return ;
                }
              }));
}

function diffEventPropertyWhitelist(fromPropertyWhitelist, toPropertyWhitelist, eventId) {
  return Belt_List.keepMap(diffList(fromPropertyWhitelist, toPropertyWhitelist, Caml_obj.caml_equal), (function (change) {
                if (typeof change !== "object") {
                  return ;
                }
                var variant = change.NAME;
                if (variant === "Add") {
                  var match = change.VAL;
                  return {
                          NAME: "AddPropertyToWhitelist",
                          VAL: [
                            eventId,
                            match[1],
                            match[0]
                          ]
                        };
                }
                if (variant !== "Remove") {
                  return ;
                }
                var match$1 = change.VAL;
                return {
                        NAME: "RemovePropertyFromWhitelist",
                        VAL: [
                          eventId,
                          match$1[1],
                          match$1[0]
                        ]
                      };
              }));
}

function diffEventGroupTypeIds(fromEventGroupTypeIds, toEventGroupTypeIds, eventId) {
  return Belt_List.fromArray(Belt_Array.keepMap(diffStringArray(fromEventGroupTypeIds, toEventGroupTypeIds), (function (change) {
                    if (typeof change !== "object") {
                      return ;
                    }
                    var variant = change.NAME;
                    if (variant === "Add") {
                      return {
                              NAME: "AddGroupTypeToLogEvent",
                              VAL: [
                                eventId,
                                change.VAL
                              ]
                            };
                    } else if (variant === "Remove") {
                      return {
                              NAME: "RemoveGroupTypeFromLogEvent",
                              VAL: [
                                eventId,
                                change.VAL
                              ]
                            };
                    } else {
                      return ;
                    }
                  })));
}

function diffUserGroupTypeIds(fromUserGroupTypeIdsWithArchive, toUserGroupTypeIdsWithArchive, eventId) {
  return Belt_List.fromArray(Belt_Array.keepMap(diffStringArray(fromUserGroupTypeIdsWithArchive, toUserGroupTypeIdsWithArchive), (function (change) {
                    if (typeof change !== "object") {
                      return ;
                    }
                    var variant = change.NAME;
                    if (variant === "Add") {
                      return {
                              NAME: "AddUserToGroup",
                              VAL: [
                                eventId,
                                change.VAL
                              ]
                            };
                    } else if (variant === "Remove") {
                      return {
                              NAME: "RemoveUserFromGroup",
                              VAL: [
                                eventId,
                                change.VAL
                              ]
                            };
                    } else {
                      return ;
                    }
                  })));
}

function diffEventTypes(fromEventTypes, toEventTypes, eventId) {
  return Belt_List.map(diffStringList(fromEventTypes, toEventTypes), (function (change) {
                if (change.NAME === "Remove") {
                  return {
                          NAME: "RemoveEventType",
                          VAL: [
                            eventId,
                            change.VAL
                          ]
                        };
                } else {
                  return {
                          NAME: "AddEventType",
                          VAL: [
                            eventId,
                            change.VAL
                          ]
                        };
                }
              }));
}

function diffEventTags(fromTags, toTags, eventId) {
  return Belt_List.map(diffStringList(fromTags, toTags), (function (change) {
                if (change.NAME === "Remove") {
                  return {
                          NAME: "RemoveTag",
                          VAL: [
                            eventId,
                            change.VAL
                          ]
                        };
                } else {
                  return {
                          NAME: "AddTag",
                          VAL: [
                            eventId,
                            change.VAL
                          ]
                        };
                }
              }));
}

function diffEventSources(fromSources, toSources, toDestinations, eventId) {
  var addedAndRemoved = Belt_List.reverse(Belt_List.map(diffList(fromSources, toSources, (function (a, b) {
                  return a.id === b.id;
                })), (function (change) {
              if (change.NAME === "Remove") {
                return {
                        NAME: "ExcludeEventFromSourceV2",
                        VAL: [
                          eventId,
                          change.VAL.id
                        ]
                      };
              }
              var addedIncludedSource = change.VAL;
              return {
                      NAME: "IncludeEventInSourceV2",
                      VAL: [
                        eventId,
                        addedIncludedSource.id,
                        Belt_List.flatten(Belt_List.keepMap(toDestinations, (function (includeDestinations) {
                                    var match = includeDestinations.VAL;
                                    if (match[0] === addedIncludedSource.id) {
                                      return match[1];
                                    }
                                    
                                  }))),
                        addedIncludedSource.includeInCodegen
                      ]
                    };
            })));
  var updatedIncludedIncodegen = Belt_List.mapU(Belt_List.keepU(toSources, (function (toSource) {
              return Belt_List.someU(fromSources, (function (fromSource) {
                            if (fromSource.id === toSource.id) {
                              return fromSource.includeInCodegen !== toSource.includeInCodegen;
                            } else {
                              return false;
                            }
                          }));
            })), (function (updatedSource) {
          return {
                  NAME: "ToggleIncludeEventInCodegenForSource",
                  VAL: [
                    eventId,
                    updatedSource.id,
                    updatedSource.includeInCodegen
                  ]
                };
        }));
  var updatedInspectorValidation = Belt_List.mapU(Belt_List.keepU(toSources, (function (toSource) {
              return Belt_List.someU(fromSources, (function (fromSource) {
                            if (fromSource.id === toSource.id) {
                              return Caml_obj.caml_notequal(fromSource.inspectorValidation, toSource.inspectorValidation);
                            } else {
                              return false;
                            }
                          }));
            })), (function (updatedSource) {
          return {
                  NAME: "ConfigureInspectorValidation",
                  VAL: [
                    {
                      hd: eventId,
                      tl: /* [] */0
                    },
                    Belt_MapString.fromArray([[
                            updatedSource.id,
                            updatedSource.inspectorValidation
                          ]])
                  ]
                };
        }));
  return Belt_List.concatMany([
              addedAndRemoved,
              updatedIncludedIncodegen,
              updatedInspectorValidation
            ]);
}

function diffEventDestinations(fromDestinations, toDestinations, eventId) {
  var diff = Belt_List.map(fromDestinations, (function (fromDestination) {
          return [
                  fromDestination,
                  Belt_List.getBy(toDestinations, (function (toDestination) {
                          return Caml_obj.caml_equal(fromDestination.VAL[0], toDestination.VAL[0]);
                        }))
                ];
        }));
  var diff$1 = Belt_List.concat(Belt_List.keepMap(toDestinations, (function (toDestination) {
              if (Belt_List.some(fromDestinations, (function (fromDestination) {
                        return Caml_obj.caml_equal(fromDestination.VAL[0], toDestination.VAL[0]);
                      }))) {
                return ;
              } else {
                return [
                        undefined,
                        toDestination
                      ];
              }
            })), diff);
  var diff$2 = Belt_List.keepMap(diff$1, (function (param) {
          var maybeTo = param[1];
          var maybeFrom = param[0];
          if (maybeFrom !== undefined) {
            var match = maybeFrom.VAL;
            var fromDestinations = match[1];
            var sourceId = match[0];
            if (maybeTo !== undefined) {
              return [
                      sourceId,
                      diffStringList(fromDestinations, maybeTo.VAL[1])
                    ];
            } else {
              return [
                      sourceId,
                      diffStringList(fromDestinations, /* [] */0)
                    ];
            }
          }
          if (maybeTo === undefined) {
            return ;
          }
          var match$1 = maybeTo.VAL;
          return [
                  match$1[0],
                  diffStringList(/* [] */0, match$1[1])
                ];
        }));
  return BeltListExtensions.flatMap(diff$2, (function (param) {
                var sourceId = param[0];
                return Belt_List.map(param[1], (function (change) {
                              if (change.NAME === "Remove") {
                                return {
                                        NAME: "ExcludeDestinationFromEventSource",
                                        VAL: [
                                          eventId,
                                          sourceId,
                                          change.VAL
                                        ]
                                      };
                              } else {
                                return {
                                        NAME: "IncludeDestinationInEventSource",
                                        VAL: [
                                          eventId,
                                          sourceId,
                                          change.VAL
                                        ]
                                      };
                              }
                            }));
              }));
}

function diffEventName(fromName, toName, eventId) {
  if (Caml_obj.caml_notequal(fromName, toName)) {
    return {
            hd: {
              NAME: "UpdateEventName",
              VAL: [
                eventId,
                toName
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffEventUniqueName(fromName, toName, eventId) {
  if (Caml_obj.caml_notequal(fromName, toName)) {
    return {
            hd: {
              NAME: "UpdateEventUniqueNameV2",
              VAL: [
                eventId,
                Belt_Option.getWithDefault(toName, "")
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffEventDescription(fromDescription, toDescription, eventId) {
  if (Caml_obj.caml_notequal(fromDescription, toDescription)) {
    return {
            hd: {
              NAME: "UpdateEventDescription",
              VAL: [
                eventId,
                toDescription
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffEventTriggerDescription(fromDescription, toDescription, eventId, triggerId) {
  if (Caml_obj.caml_notequal(fromDescription, toDescription)) {
    return {
            hd: {
              NAME: "UpdateTriggerDescription",
              VAL: [
                eventId,
                triggerId,
                toDescription
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffEventTriggerSources(fromSources, toSources, eventId, triggerId) {
  if (fromSources) {
    if (toSources) {
      var toIds = toSources._0;
      var fromIds = fromSources._0;
      if (Caml_obj.caml_notequal(fromIds, toIds)) {
        return Belt_List.fromArray(Belt_Array.concat(Belt_Array.map(Belt_Array.keep(fromIds, (function (fromId) {
                                  return !toIds.includes(fromId);
                                })), (function (id) {
                              return {
                                      NAME: "RemoveTriggerSource",
                                      VAL: [
                                        eventId,
                                        triggerId,
                                        id
                                      ]
                                    };
                            })), Belt_Array.map(Belt_Array.keep(toIds, (function (fromId) {
                                  return !fromIds.includes(fromId);
                                })), (function (id) {
                              return {
                                      NAME: "AddTriggerSource",
                                      VAL: [
                                        eventId,
                                        triggerId,
                                        id
                                      ]
                                    };
                            }))));
      } else {
        return /* [] */0;
      }
    }
    
  } else if (!toSources) {
    return /* [] */0;
  }
  return {
          hd: {
            NAME: "UpdateTriggerSources",
            VAL: [
              eventId,
              triggerId,
              toSources
            ]
          },
          tl: /* [] */0
        };
}

function diffEventTriggerContent(fromContent, toContent, eventId, triggerId) {
  if (Caml_obj.caml_notequal(fromContent, toContent)) {
    return {
            hd: {
              NAME: "UpdateTriggerContent",
              VAL: [
                eventId,
                triggerId,
                toContent
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffEventTrigger(fromTrigger, toTrigger, eventId) {
  if (Caml_obj.caml_notequal(fromTrigger, toTrigger)) {
    return Belt_List.concatMany([
                diffEventTriggerDescription(fromTrigger.description, toTrigger.description, eventId, fromTrigger.id),
                diffEventTriggerSources(fromTrigger.sources, toTrigger.sources, eventId, fromTrigger.id),
                diffEventTriggerContent(fromTrigger.content, toTrigger.content, eventId, fromTrigger.id)
              ]);
  } else {
    return /* [] */0;
  }
}

function diffEventTriggers(fromTriggers, toTriggers, eventId) {
  var newAndModified = Belt_List.flatten(Belt_List.map(Belt_List.fromArray(toTriggers), (function (toTrigger) {
              return Belt_Option.mapWithDefault(Belt_Array.getBy(fromTriggers, (function (trigger) {
                                return trigger.id === toTrigger.id;
                              })), {
                          hd: {
                            NAME: "CreateEventTrigger",
                            VAL: [
                              eventId,
                              toTrigger.id,
                              toTrigger.sources,
                              toTrigger.description,
                              toTrigger.content
                            ]
                          },
                          tl: /* [] */0
                        }, (function (fromTrigger) {
                            return diffEventTrigger(fromTrigger, toTrigger, eventId);
                          }));
            })));
  var removed = Belt_List.fromArray(Belt_Array.map(Belt_Array.keep(fromTriggers, (function (fromTrigger) {
                  return Belt_Array.every(toTriggers, (function (param) {
                                return param.id !== fromTrigger.id;
                              }));
                })), (function (param) {
              return {
                      NAME: "RemoveEventTrigger",
                      VAL: [
                        eventId,
                        param.id
                      ]
                    };
            })));
  return Belt_List.concat(newAndModified, removed);
}

function diffEvent(fromEvent, toEvent) {
  var eventId = toEvent.id;
  return Belt_List.concatMany([
              diffEventName(fromEvent.name, toEvent.name, eventId),
              diffEventDescription(fromEvent.description, toEvent.description, eventId),
              diffEventUniqueName(fromEvent.uniqueName, toEvent.uniqueName, eventId),
              diffEventTypes(fromEvent.types, toEvent.types, eventId),
              diffEventTags(fromEvent.tags, toEvent.tags, eventId),
              diffEventSources(fromEvent.includeSources, toEvent.includeSources, toEvent.includeDestinations, eventId),
              diffEventDestinations(fromEvent.includeDestinations, toEvent.includeDestinations, eventId),
              diffEventProperties(fromEvent.properties, toEvent.properties, eventId),
              diffEventPropertyGroups(fromEvent.propertyBundles, toEvent.propertyBundles, eventId),
              diffEventPropertyWhitelist(fromEvent.propertyWhitelist, toEvent.propertyWhitelist, eventId),
              diffEventGroupTypeIds(fromEvent.eventGroupTypeIdsWithArchive, toEvent.eventGroupTypeIdsWithArchive, eventId),
              diffUserGroupTypeIds(fromEvent.userGroupTypeIdsWithArchive, toEvent.userGroupTypeIdsWithArchive, eventId),
              diffEventTriggers(fromEvent.triggers, toEvent.triggers, eventId)
            ]);
}

function diffEvents(fromEvents, toEvents, fromEventsArchive, toEventsArchive, onError) {
  var newAndModified = BeltListExtensions.flatMapU(toEvents, (function (toEvent) {
          var eventId = toEvent.id;
          var maybeFromEvent = Belt_List.getByU(fromEvents, (function ($$event) {
                  return $$event.id === eventId;
                }));
          var maybeArchivedEvent = Belt_List.getByU(fromEventsArchive, (function ($$event) {
                  return $$event.id === eventId;
                }));
          if (maybeFromEvent !== undefined) {
            return diffEvent(maybeFromEvent, toEvent);
          } else if (maybeArchivedEvent !== undefined) {
            return Belt_List.concatMany([
                        {
                          hd: {
                            NAME: "Unarchive",
                            VAL: {
                              NAME: "Event",
                              VAL: eventId
                            }
                          },
                          tl: /* [] */0
                        },
                        diffEvent(maybeArchivedEvent, toEvent)
                      ]);
          } else {
            return Belt_List.concatMany([
                        {
                          hd: {
                            NAME: "AddEvent",
                            VAL: [
                              eventId,
                              toEvent.name
                            ]
                          },
                          tl: /* [] */0
                        },
                        diffEvent(TrackingPlanModel.emptyEvent(eventId, ""), toEvent)
                      ]);
          }
        }));
  var removed = Belt_List.flatten(Belt_List.mapU(fromEvents, (function (fromEvent) {
              var eventId = fromEvent.id;
              var maybeToEvent = Belt_List.getByU(toEvents, (function ($$event) {
                      return $$event.id === eventId;
                    }));
              var maybeArchivedEvent = Belt_List.getByU(toEventsArchive, (function ($$event) {
                      return $$event.id === eventId;
                    }));
              if (maybeToEvent !== undefined) {
                return /* [] */0;
              } else if (maybeArchivedEvent !== undefined) {
                return Belt_List.concatMany([
                            diffEvent(fromEvent, maybeArchivedEvent),
                            {
                              hd: {
                                NAME: "Archive",
                                VAL: {
                                  NAME: "Event",
                                  VAL: eventId
                                }
                              },
                              tl: /* [] */0
                            }
                          ]);
              } else {
                Curry._1(onError, "Illegal state in ActionDiff. Couldn't find an archived event: " + fromEvent.name + " (" + fromEvent.id + ")");
                return {
                        hd: {
                          NAME: "Archive",
                          VAL: {
                            NAME: "Event",
                            VAL: eventId
                          }
                        },
                        tl: /* [] */0
                      };
              }
            })));
  return Belt_List.concat(newAndModified, removed);
}

function diffPropertyName(fromName, toName, propertyId) {
  if (Caml_obj.caml_notequal(fromName, toName)) {
    return {
            hd: {
              NAME: "UpdatePropertyName",
              VAL: [
                propertyId,
                toName
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyUniqueName(fromName, toName, propertyId) {
  if (Caml_obj.caml_notequal(fromName, toName)) {
    return {
            hd: {
              NAME: "UpdatePropertyUniqueName",
              VAL: [
                propertyId,
                Belt_Option.getWithDefault(toName, "")
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyDescription(fromDescription, toDescription, propertyId) {
  if (Caml_obj.caml_notequal(fromDescription, toDescription)) {
    return {
            hd: {
              NAME: "UpdatePropertyDescription",
              VAL: [
                propertyId,
                toDescription
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyOptional(fromOptional, toOptional, propertyId) {
  if (Caml_obj.caml_notequal(fromOptional, toOptional)) {
    return {
            hd: {
              NAME: "UpdatePropertyOptional",
              VAL: [
                propertyId,
                toOptional
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyOptionalWhenInObject(fromOptional, toOptional, propertyId) {
  if (Caml_obj.caml_notequal(fromOptional, toOptional)) {
    return {
            hd: {
              NAME: "UpdatePropertyOptionalWhenInObject",
              VAL: [
                propertyId,
                toOptional
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyAbsence(fromAbsence, toAbsence, propertyId) {
  var getActionsFromSourceAbsence = function (eventId, sourceAbsence) {
    if (sourceAbsence.TAG !== /* AllSources */0) {
      return Belt_List.fromArray(Belt_Array.map(Belt_MapString.toArray(sourceAbsence._0), (function (param) {
                        var eventSourceAbsence = param[1];
                        var sourceId = param[0];
                        if (eventSourceAbsence) {
                          return {
                                  NAME: "UpdatePropertyAbsence",
                                  VAL: [
                                    propertyId,
                                    {
                                      TAG: /* EventSource */2,
                                      _0: eventId,
                                      _1: sourceId
                                    },
                                    /* SometimesSent */{
                                      _0: eventSourceAbsence._0
                                    }
                                  ]
                                };
                        } else {
                          return {
                                  NAME: "UpdatePropertyAbsence",
                                  VAL: [
                                    propertyId,
                                    {
                                      TAG: /* EventSource */2,
                                      _0: eventId,
                                      _1: sourceId
                                    },
                                    /* NeverSent */0
                                  ]
                                };
                        }
                      })));
    }
    var toDescription = sourceAbsence._0;
    if (toDescription) {
      return {
              hd: {
                NAME: "UpdatePropertyAbsence",
                VAL: [
                  propertyId,
                  {
                    TAG: /* Event */0,
                    _0: eventId
                  },
                  /* SometimesSent */{
                    _0: toDescription._0
                  }
                ]
              },
              tl: /* [] */0
            };
    } else {
      return {
              hd: {
                NAME: "UpdatePropertyAbsence",
                VAL: [
                  propertyId,
                  {
                    TAG: /* Event */0,
                    _0: eventId
                  },
                  /* NeverSent */0
                ]
              },
              tl: /* [] */0
            };
    }
  };
  var exit = 0;
  var toDescription;
  var toAbsence$1;
  if (fromAbsence !== undefined) {
    if (typeof fromAbsence === "number") {
      if (toAbsence === undefined) {
        return /* [] */0;
      }
      if (typeof toAbsence === "number") {
        return /* [] */0;
      }
      if (toAbsence.TAG === /* SometimesSent */0) {
        toDescription = toAbsence._0;
        exit = 2;
      } else {
        toAbsence$1 = toAbsence._0;
        exit = 3;
      }
    } else if (fromAbsence.TAG === /* SometimesSent */0) {
      if (toAbsence === undefined) {
        return /* [] */0;
      }
      var fromDescription = fromAbsence._0;
      if (typeof toAbsence === "number") {
        exit = 1;
      } else {
        if (toAbsence.TAG !== /* SometimesSent */0) {
          return Belt_List.keep(Belt_List.flatten(Belt_List.map(Belt_MapString.toList(toAbsence._0), (function (param) {
                                return getActionsFromSourceAbsence(param[0], param[1]);
                              }))), (function (action) {
                        if (typeof action !== "object") {
                          return true;
                        }
                        if (action.NAME !== "UpdatePropertyAbsence") {
                          return true;
                        }
                        var match = action.VAL[2];
                        if (match !== undefined && match) {
                          return fromDescription !== match._0;
                        } else {
                          return true;
                        }
                      }));
        }
        var toDescription$1 = toAbsence._0;
        if (fromDescription === toDescription$1) {
          return /* [] */0;
        } else {
          return {
                  hd: {
                    NAME: "UpdatePropertyAbsence",
                    VAL: [
                      propertyId,
                      /* All */0,
                      /* SometimesSent */{
                        _0: toDescription$1
                      }
                    ]
                  },
                  tl: /* [] */0
                };
        }
      }
    } else {
      if (toAbsence === undefined) {
        return /* [] */0;
      }
      var eventAbsencesMap = fromAbsence._0;
      if (typeof toAbsence === "number") {
        var wasAlwaysSent = Belt_Array.every(Belt_MapString.toArray(eventAbsencesMap), (function (param) {
                var sourceAbsenceMap = param[1];
                if (sourceAbsenceMap.TAG === /* AllSources */0) {
                  return false;
                } else {
                  return Belt_MapString.isEmpty(sourceAbsenceMap._0);
                }
              }));
        if (wasAlwaysSent) {
          return /* [] */0;
        } else {
          return {
                  hd: {
                    NAME: "UpdatePropertyAbsence",
                    VAL: [
                      propertyId,
                      /* All */0,
                      undefined
                    ]
                  },
                  tl: /* [] */0
                };
        }
      }
      if (toAbsence.TAG === /* SometimesSent */0) {
        toDescription = toAbsence._0;
        exit = 2;
      } else {
        var toAbsence$2 = toAbsence._0;
        if (Belt_MapString.isEmpty(eventAbsencesMap) && Belt_MapString.isEmpty(toAbsence$2)) {
          return /* [] */0;
        }
        if (toAbsence$2 === undefined) {
          return {
                  hd: {
                    NAME: "UpdatePropertyAbsence",
                    VAL: [
                      propertyId,
                      /* All */0,
                      undefined
                    ]
                  },
                  tl: /* [] */0
                };
        }
        var fromEventIds = Belt_MapString.keysToArray(eventAbsencesMap);
        var toEventIds = Belt_MapString.keysToArray(toAbsence$2);
        var newEventIds = Belt_Array.keep(toEventIds, (function (toEventId) {
                return !fromEventIds.includes(toEventId);
              }));
        var removedEventIds = Belt_Array.keep(fromEventIds, (function (fromEventId) {
                return !toEventIds.includes(fromEventId);
              }));
        var changedEventIds = Belt_Array.keep(toEventIds, (function (eventId) {
                if (fromEventIds.includes(eventId)) {
                  return Caml_obj.caml_notequal(Belt_MapString.get(eventAbsencesMap, eventId), Belt_MapString.get(toAbsence$2, eventId));
                } else {
                  return false;
                }
              }));
        var absencesAdded = Belt_List.flatten(Belt_List.fromArray(Belt_Array.keepMap(newEventIds, (function (newEventId) {
                        return Belt_Option.map(Belt_MapString.get(toAbsence$2, newEventId), (function (sourceAbsence) {
                                      return getActionsFromSourceAbsence(newEventId, sourceAbsence);
                                    }));
                      }))));
        var absencesRemoved = Belt_List.fromArray(Belt_Array.map(removedEventIds, (function (removedEventId) {
                    return {
                            NAME: "UpdatePropertyAbsence",
                            VAL: [
                              propertyId,
                              {
                                TAG: /* Event */0,
                                _0: removedEventId
                              },
                              undefined
                            ]
                          };
                  })));
        var absencesChanged = Belt_List.flatten(Belt_List.fromArray(Belt_Array.map(Belt_Array.keepMap(changedEventIds, (function (eventId) {
                            var match = Belt_MapString.get(eventAbsencesMap, eventId);
                            var match$1 = Belt_MapString.get(toAbsence$2, eventId);
                            if (match !== undefined && match$1 !== undefined) {
                              return [
                                      eventId,
                                      match,
                                      match$1
                                    ];
                            }
                            
                          })), (function (param) {
                        var toEventAbsence = param[2];
                        var fromEventAbsence = param[1];
                        var eventId = param[0];
                        if (fromEventAbsence.TAG === /* AllSources */0) {
                          var fromEventAbsence$1 = fromEventAbsence._0;
                          if (toEventAbsence.TAG !== /* AllSources */0) {
                            return Belt_List.fromArray(Belt_Array.map(Belt_Array.keep(Belt_MapString.toArray(toEventAbsence._0), (function (param) {
                                                  return Caml_obj.caml_notequal(param[1], fromEventAbsence$1);
                                                })), (function (param) {
                                              return {
                                                      NAME: "UpdatePropertyAbsence",
                                                      VAL: [
                                                        propertyId,
                                                        {
                                                          TAG: /* EventSource */2,
                                                          _0: eventId,
                                                          _1: param[0]
                                                        },
                                                        param[1]
                                                      ]
                                                    };
                                            })));
                          }
                          var toEventAbsence$1 = toEventAbsence._0;
                          if (Caml_obj.caml_notequal(fromEventAbsence$1, toEventAbsence$1)) {
                            return {
                                    hd: {
                                      NAME: "UpdatePropertyAbsence",
                                      VAL: [
                                        propertyId,
                                        {
                                          TAG: /* Event */0,
                                          _0: eventId
                                        },
                                        toEventAbsence$1
                                      ]
                                    },
                                    tl: /* [] */0
                                  };
                          } else {
                            return /* [] */0;
                          }
                        }
                        var fromSourceAbsence = fromEventAbsence._0;
                        if (toEventAbsence.TAG === /* AllSources */0) {
                          return {
                                  hd: {
                                    NAME: "UpdatePropertyAbsence",
                                    VAL: [
                                      propertyId,
                                      {
                                        TAG: /* Event */0,
                                        _0: eventId
                                      },
                                      toEventAbsence._0
                                    ]
                                  },
                                  tl: /* [] */0
                                };
                        }
                        var toSourceAbsence = toEventAbsence._0;
                        var removed = Belt_Array.map(Belt_Array.keep(Belt_MapString.toArray(fromSourceAbsence), (function (param) {
                                    return Belt_Option.isNone(Belt_MapString.get(toSourceAbsence, param[0]));
                                  })), (function (param) {
                                return {
                                        NAME: "UpdatePropertyAbsence",
                                        VAL: [
                                          propertyId,
                                          {
                                            TAG: /* EventSource */2,
                                            _0: eventId,
                                            _1: param[0]
                                          },
                                          undefined
                                        ]
                                      };
                              }));
                        var addedOrChanged = Belt_Array.map(Belt_Array.keep(Belt_MapString.toArray(toSourceAbsence), (function (param) {
                                    var toSourceAbsence = param[1];
                                    return Belt_Option.mapWithDefault(Belt_MapString.get(fromSourceAbsence, param[0]), true, (function (fromSourceAbsence) {
                                                  return Caml_obj.caml_notequal(fromSourceAbsence, toSourceAbsence);
                                                }));
                                  })), (function (param) {
                                return {
                                        NAME: "UpdatePropertyAbsence",
                                        VAL: [
                                          propertyId,
                                          {
                                            TAG: /* EventSource */2,
                                            _0: eventId,
                                            _1: param[0]
                                          },
                                          param[1]
                                        ]
                                      };
                              }));
                        return Belt_List.fromArray(Belt_Array.concat(removed, addedOrChanged));
                      }))));
        return Belt_List.concatMany([
                    absencesAdded,
                    absencesRemoved,
                    absencesChanged
                  ]);
      }
    }
  } else {
    if (toAbsence === undefined) {
      return /* [] */0;
    }
    if (typeof toAbsence === "number") {
      exit = 1;
    } else if (toAbsence.TAG === /* SometimesSent */0) {
      toDescription = toAbsence._0;
      exit = 2;
    } else {
      toAbsence$1 = toAbsence._0;
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        return {
                hd: {
                  NAME: "UpdatePropertyAbsence",
                  VAL: [
                    propertyId,
                    /* All */0,
                    undefined
                  ]
                },
                tl: /* [] */0
              };
    case 2 :
        return {
                hd: {
                  NAME: "UpdatePropertyAbsence",
                  VAL: [
                    propertyId,
                    /* All */0,
                    /* SometimesSent */{
                      _0: toDescription
                    }
                  ]
                },
                tl: /* [] */0
              };
    case 3 :
        return Belt_List.flatten(Belt_List.map(Belt_MapString.toList(toAbsence$1), (function (param) {
                          return getActionsFromSourceAbsence(param[0], param[1]);
                        })));
    
  }
}

function diffPropertyOperation(fromOperation, toOperation, propertyId) {
  if (Caml_obj.caml_notequal(fromOperation, toOperation)) {
    return {
            hd: {
              NAME: "UpdatePropertyOperation",
              VAL: [
                propertyId,
                toOperation
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyList(fromList, toList, propertyId) {
  if (Caml_obj.caml_notequal(fromList, toList)) {
    return {
            hd: {
              NAME: "UpdatePropertyList",
              VAL: [
                propertyId,
                toList
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyType(fromType, toType, propertyId) {
  if (Caml_obj.caml_notequal(fromType, toType)) {
    return {
            hd: {
              NAME: "UpdatePropertyType",
              VAL: [
                propertyId,
                toType
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffPropertyValidations(fromValidations, toValidations, propertyId) {
  var getMin = function (validations) {
    return Belt_List.getBy(validations, (function (validation) {
                  if (typeof validation === "object") {
                    return validation.NAME === "Min";
                  } else {
                    return false;
                  }
                }));
  };
  var fromMin = getMin(fromValidations);
  var toMin = getMin(toValidations);
  var minDiff = fromMin !== undefined ? (
      toMin !== undefined ? (
          Caml_obj.caml_notequal(fromMin, toMin) ? ({
                hd: {
                  NAME: "RemovePropertyValidation",
                  VAL: [
                    propertyId,
                    fromMin
                  ]
                },
                tl: {
                  hd: {
                    NAME: "AddPropertyValidation",
                    VAL: [
                      propertyId,
                      toMin
                    ]
                  },
                  tl: /* [] */0
                }
              }) : /* [] */0
        ) : ({
            hd: {
              NAME: "RemovePropertyValidation",
              VAL: [
                propertyId,
                fromMin
              ]
            },
            tl: /* [] */0
          })
    ) : (
      toMin !== undefined ? ({
            hd: {
              NAME: "AddPropertyValidation",
              VAL: [
                propertyId,
                toMin
              ]
            },
            tl: /* [] */0
          }) : /* [] */0
    );
  var getMax = function (validations) {
    return Belt_List.getBy(validations, (function (validation) {
                  if (typeof validation === "object") {
                    return validation.NAME === "Max";
                  } else {
                    return false;
                  }
                }));
  };
  var fromMax = getMax(fromValidations);
  var toMax = getMax(toValidations);
  var maxDiff = fromMax !== undefined ? (
      toMax !== undefined ? (
          Caml_obj.caml_notequal(fromMax, toMax) ? ({
                hd: {
                  NAME: "RemovePropertyValidation",
                  VAL: [
                    propertyId,
                    fromMax
                  ]
                },
                tl: {
                  hd: {
                    NAME: "AddPropertyValidation",
                    VAL: [
                      propertyId,
                      toMax
                    ]
                  },
                  tl: /* [] */0
                }
              }) : /* [] */0
        ) : ({
            hd: {
              NAME: "RemovePropertyValidation",
              VAL: [
                propertyId,
                fromMax
              ]
            },
            tl: /* [] */0
          })
    ) : (
      toMax !== undefined ? ({
            hd: {
              NAME: "AddPropertyValidation",
              VAL: [
                propertyId,
                toMax
              ]
            },
            tl: /* [] */0
          }) : /* [] */0
    );
  var getMatches = function (validations) {
    return Belt_List.getBy(validations, (function (validation) {
                  if (typeof validation === "object") {
                    return validation.NAME === "Matches";
                  } else {
                    return false;
                  }
                }));
  };
  var fromMatch = getMatches(fromValidations);
  var toMatch = getMatches(toValidations);
  var matchDiff;
  if (fromMatch !== undefined) {
    var exit = 0;
    if (typeof fromMatch === "object" && fromMatch.NAME === "Matches" && toMatch !== undefined) {
      matchDiff = typeof toMatch === "object" && toMatch.NAME === "Matches" ? Belt_List.map(diffList(fromMatch.VAL, toMatch.VAL, (function (from, to_) {
                    return Caml_obj.caml_equal(from[0], to_[0]);
                  })), (function (change) {
                if (change.NAME === "Remove") {
                  return {
                          NAME: "RemovePropertyValidation",
                          VAL: [
                            propertyId,
                            {
                              NAME: "Matches",
                              VAL: {
                                hd: change.VAL,
                                tl: /* [] */0
                              }
                            }
                          ]
                        };
                } else {
                  return {
                          NAME: "AddPropertyValidation",
                          VAL: [
                            propertyId,
                            {
                              NAME: "Matches",
                              VAL: {
                                hd: change.VAL,
                                tl: /* [] */0
                              }
                            }
                          ]
                        };
                }
              })) : /* [] */0;
    } else {
      exit = 1;
    }
    if (exit === 1) {
      matchDiff = toMatch !== undefined ? /* [] */0 : ({
            hd: {
              NAME: "RemovePropertyValidation",
              VAL: [
                propertyId,
                {
                  NAME: "Matches",
                  VAL: /* [] */0
                }
              ]
            },
            tl: /* [] */0
          });
    }
    
  } else {
    matchDiff = toMatch !== undefined ? ({
          hd: {
            NAME: "AddPropertyValidation",
            VAL: [
              propertyId,
              toMatch
            ]
          },
          tl: /* [] */0
        }) : /* [] */0;
  }
  var matchSourceToggleDiff;
  if (fromMatch !== undefined && typeof fromMatch === "object" && fromMatch.NAME === "Matches" && toMatch !== undefined && typeof toMatch === "object" && toMatch.NAME === "Matches") {
    var fromMatchList = fromMatch.VAL;
    matchSourceToggleDiff = BeltListExtensions.flatMap(toMatch.VAL, (function (param) {
            var toMatchExp = param[0];
            var maybeFromMatch = Belt_List.getBy(fromMatchList, (function (param) {
                    return Caml_obj.caml_equal(param[0], toMatchExp);
                  }));
            if (maybeFromMatch === undefined) {
              return /* [] */0;
            }
            var match = maybeFromMatch[0];
            if (typeof match !== "object") {
              return /* [] */0;
            }
            if (match.NAME !== "Literal") {
              return /* [] */0;
            }
            var match$1 = match.VAL;
            if (typeof match$1 !== "object") {
              return /* [] */0;
            }
            if (match$1.NAME !== "StringLit") {
              return /* [] */0;
            }
            var stringMatch = match$1.VAL;
            var diffSourceExclusion = diffList(maybeFromMatch[1], param[1], (function (prim0, prim1) {
                    return prim0 === prim1;
                  }));
            return Belt_List.map(diffSourceExclusion, (function (change) {
                          if (change.NAME === "Remove") {
                            return {
                                    NAME: "TogglePropertyValidationMatchSource",
                                    VAL: [
                                      propertyId,
                                      stringMatch,
                                      change.VAL,
                                      true
                                    ]
                                  };
                          } else {
                            return {
                                    NAME: "TogglePropertyValidationMatchSource",
                                    VAL: [
                                      propertyId,
                                      stringMatch,
                                      change.VAL,
                                      false
                                    ]
                                  };
                          }
                        }));
          }));
  } else {
    matchSourceToggleDiff = /* [] */0;
  }
  var getShapes = function (validations) {
    return Belt_List.getBy(validations, (function (validation) {
                  if (typeof validation === "object") {
                    return validation.NAME === "Shape";
                  } else {
                    return false;
                  }
                }));
  };
  var fromShape = getShapes(fromValidations);
  var toShape = getShapes(toValidations);
  var shapeDiff;
  if (fromShape !== undefined) {
    var exit$1 = 0;
    if (typeof fromShape === "object" && fromShape.NAME === "Shape" && toShape !== undefined) {
      shapeDiff = typeof toShape === "object" && toShape.NAME === "Shape" ? Belt_List.map(diffList(fromShape.VAL, toShape.VAL, (function (a, b) {
                    return a.key === b.key;
                  })), (function (change) {
                if (change.NAME === "Remove") {
                  return {
                          NAME: "RemovePropertyValidation",
                          VAL: [
                            propertyId,
                            {
                              NAME: "Shape",
                              VAL: {
                                hd: change.VAL,
                                tl: /* [] */0
                              }
                            }
                          ]
                        };
                } else {
                  return {
                          NAME: "AddPropertyValidation",
                          VAL: [
                            propertyId,
                            {
                              NAME: "Shape",
                              VAL: {
                                hd: change.VAL,
                                tl: /* [] */0
                              }
                            }
                          ]
                        };
                }
              })) : /* [] */0;
    } else {
      exit$1 = 1;
    }
    if (exit$1 === 1) {
      shapeDiff = toShape !== undefined ? /* [] */0 : ({
            hd: {
              NAME: "RemovePropertyValidation",
              VAL: [
                propertyId,
                {
                  NAME: "Shape",
                  VAL: /* [] */0
                }
              ]
            },
            tl: /* [] */0
          });
    }
    
  } else {
    shapeDiff = toShape !== undefined ? ({
          hd: {
            NAME: "AddPropertyValidation",
            VAL: [
              propertyId,
              toShape
            ]
          },
          tl: /* [] */0
        }) : /* [] */0;
  }
  var getNestedProperty = function (validations) {
    return Belt_List.getBy(validations, (function (validation) {
                  if (typeof validation === "object") {
                    return validation.NAME === "NestedProperty";
                  } else {
                    return false;
                  }
                }));
  };
  var fromNestedProperty = getNestedProperty(fromValidations);
  var toNestedProperty = getNestedProperty(toValidations);
  var nestedPropertyDiff;
  if (fromNestedProperty !== undefined) {
    var exit$2 = 0;
    if (typeof fromNestedProperty === "object" && fromNestedProperty.NAME === "NestedProperty" && toNestedProperty !== undefined) {
      nestedPropertyDiff = typeof toNestedProperty === "object" && toNestedProperty.NAME === "NestedProperty" ? BeltListExtensions.flatMap(diffList(fromNestedProperty.VAL, toNestedProperty.VAL, Caml_obj.caml_equal), (function (change) {
                if (change.NAME === "Remove") {
                  return {
                          hd: {
                            NAME: "RemovePropertyValidation",
                            VAL: [
                              propertyId,
                              {
                                NAME: "NestedProperty",
                                VAL: {
                                  hd: change.VAL,
                                  tl: /* [] */0
                                }
                              }
                            ]
                          },
                          tl: /* [] */0
                        };
                }
                var propertyRef = change.VAL;
                var pinnedValue = propertyRef.pinnedValue;
                return Belt_List.concat({
                            hd: {
                              NAME: "AddPropertyValidation",
                              VAL: [
                                propertyId,
                                {
                                  NAME: "NestedProperty",
                                  VAL: {
                                    hd: propertyRef,
                                    tl: /* [] */0
                                  }
                                }
                              ]
                            },
                            tl: /* [] */0
                          }, pinnedValue !== undefined ? ({
                                hd: {
                                  NAME: "UpdateNestedPropertyPinnedValue",
                                  VAL: [
                                    propertyId,
                                    propertyRef.id,
                                    pinnedValue
                                  ]
                                },
                                tl: /* [] */0
                              }) : ({
                                hd: {
                                  NAME: "RemoveNestedPropertyPinnedValue",
                                  VAL: [
                                    propertyId,
                                    propertyRef.id
                                  ]
                                },
                                tl: /* [] */0
                              }));
              })) : /* [] */0;
    } else {
      exit$2 = 1;
    }
    if (exit$2 === 1) {
      nestedPropertyDiff = toNestedProperty !== undefined ? /* [] */0 : ({
            hd: {
              NAME: "RemovePropertyValidation",
              VAL: [
                propertyId,
                {
                  NAME: "NestedProperty",
                  VAL: /* [] */0
                }
              ]
            },
            tl: /* [] */0
          });
    }
    
  } else {
    nestedPropertyDiff = toNestedProperty !== undefined ? ({
          hd: {
            NAME: "AddPropertyValidation",
            VAL: [
              propertyId,
              toNestedProperty
            ]
          },
          tl: /* [] */0
        }) : /* [] */0;
  }
  return Belt_List.concatMany([
              minDiff,
              maxDiff,
              matchDiff,
              matchSourceToggleDiff,
              shapeDiff,
              nestedPropertyDiff
            ]);
}

function diffPropertyExcludedSources(fromExcludedSources, toExcludedSources, propertyId) {
  if (!fromExcludedSources && !toExcludedSources) {
    return /* [] */0;
  }
  if (fromExcludedSources === toExcludedSources) {
    return /* [] */0;
  } else if (toExcludedSources) {
    return Belt_List.map(diffList(fromExcludedSources, toExcludedSources, Caml_obj.caml_equal), (function (change) {
                  if (change.NAME === "Remove") {
                    return {
                            NAME: "TogglePropertyExcludedSource",
                            VAL: [
                              propertyId,
                              change.VAL,
                              true
                            ]
                          };
                  } else {
                    return {
                            NAME: "TogglePropertyExcludedSource",
                            VAL: [
                              propertyId,
                              change.VAL,
                              false
                            ]
                          };
                  }
                }));
  } else {
    return {
            hd: {
              NAME: "ClearPropertyExcludedSources",
              VAL: propertyId
            },
            tl: /* [] */0
          };
  }
}

function diffProperty(fromProperty, toProperty) {
  var propertyId = toProperty.id;
  var match = toProperty.absence;
  return Belt_List.concatMany([
              diffPropertyName(fromProperty.name, toProperty.name, propertyId),
              diffPropertyUniqueName(fromProperty.uniqueName, toProperty.uniqueName, propertyId),
              diffPropertyDescription(fromProperty.description, toProperty.description, propertyId),
              match !== undefined ? Belt_List.flatten({
                      hd: diffPropertyAbsence(fromProperty.absence, toProperty.absence, propertyId),
                      tl: {
                        hd: diffPropertyOptionalWhenInObject(fromProperty.optionalWhenInObject, toProperty.optionalWhenInObject, propertyId),
                        tl: /* [] */0
                      }
                    }) : Belt_List.flatten({
                      hd: diffPropertyOptional(fromProperty.optionalDeprecated, toProperty.optionalDeprecated, propertyId),
                      tl: {
                        hd: diffPropertyExcludedSources(fromProperty.excludedSourcesDeprecated, toProperty.excludedSourcesDeprecated, propertyId),
                        tl: /* [] */0
                      }
                    }),
              diffPropertyOperation(fromProperty.operation, toProperty.operation, propertyId),
              diffPropertyList(fromProperty.list, toProperty.list, propertyId),
              diffPropertyType(fromProperty.type_, toProperty.type_, propertyId),
              diffPropertyValidations(fromProperty.validations, toProperty.validations, propertyId)
            ]);
}

function diffProperties(fromProperties, toProperties, fromPropertiesArchive, toPropertiesArchive, onError) {
  var compareProperties = function (fromProperty, toProperty) {
    if (toProperty.TAG === /* PropertyRef */0 || fromProperty.TAG === /* PropertyRef */0) {
      return false;
    } else {
      return toProperty._0.id === fromProperty._0.id;
    }
  };
  var newAndUpdated = BeltListExtensions.flatMapU(toProperties, (function (toProperty) {
          var maybeFromProperty = Belt_List.getByU(fromProperties, (function (fromProperty) {
                  return compareProperties(fromProperty, toProperty);
                }));
          var maybeArchivedProperty = Belt_List.getByU(fromPropertiesArchive, (function (fromProperty) {
                  return compareProperties(fromProperty, toProperty);
                }));
          if (maybeFromProperty !== undefined) {
            if (maybeFromProperty.TAG === /* PropertyRef */0) {
              return /* [] */0;
            }
            if (toProperty.TAG !== /* PropertyRef */0) {
              return diffProperty(maybeFromProperty._0, toProperty._0);
            }
            
          } else if (maybeArchivedProperty !== undefined) {
            if (maybeArchivedProperty.TAG !== /* PropertyRef */0) {
              if (toProperty.TAG === /* PropertyRef */0) {
                return /* [] */0;
              }
              var toProperty$1 = toProperty._0;
              return Belt_List.concatMany([
                          {
                            hd: {
                              NAME: "Unarchive",
                              VAL: {
                                NAME: "Property",
                                VAL: toProperty$1.id
                              }
                            },
                            tl: /* [] */0
                          },
                          diffProperty(maybeArchivedProperty._0, toProperty$1)
                        ]);
            }
            
          } else {
            if (toProperty.TAG === /* PropertyRef */0) {
              return /* [] */0;
            }
            var toProperty$2 = toProperty._0;
            if (toProperty$2.sendAs === /* SystemProperty */0) {
              return Belt_List.concatMany([
                          {
                            hd: {
                              NAME: "AddSystemProperty",
                              VAL: [
                                toProperty$2.id,
                                toProperty$2.name
                              ]
                            },
                            tl: /* [] */0
                          },
                          diffProperty(TrackingPlanModel.emptyProperty(toProperty$2.id, toProperty$2.name, toProperty$2.sendAs, "string"), toProperty$2)
                        ]);
            } else {
              return Belt_List.concatMany([
                          {
                            hd: {
                              NAME: "CreateProperty",
                              VAL: [
                                toProperty$2.id,
                                toProperty$2.name,
                                toProperty$2.sendAs
                              ]
                            },
                            tl: /* [] */0
                          },
                          diffProperty(TrackingPlanModel.emptyProperty(toProperty$2.id, toProperty$2.name, toProperty$2.sendAs, "string"), toProperty$2)
                        ]);
            }
          }
          return /* [] */0;
        }));
  var removed = Belt_List.flatten(Belt_List.mapU(fromProperties, (function (fromProperty) {
              var propertyId = fromProperty._0.id;
              var maybeToProperty = Belt_List.getByU(toProperties, (function (property) {
                      return property._0.id === propertyId;
                    }));
              var maybeArchivedProperty = Belt_List.getByU(toPropertiesArchive, (function (toProperty) {
                      return compareProperties(fromProperty, toProperty);
                    }));
              var fromProperty$1;
              if (maybeToProperty !== undefined) {
                return /* [] */0;
              }
              if (fromProperty.TAG === /* PropertyRef */0) {
                Curry._1(onError, "Illegal state in ActionDiff. Couldn't find an archived property ref: (" + fromProperty._0.id + ")");
                return {
                        hd: {
                          NAME: "Archive",
                          VAL: {
                            NAME: "Property",
                            VAL: propertyId
                          }
                        },
                        tl: /* [] */0
                      };
              }
              var fromProperty$2 = fromProperty._0;
              if (maybeArchivedProperty !== undefined) {
                if (maybeArchivedProperty.TAG !== /* PropertyRef */0) {
                  return Belt_List.concatMany([
                              diffProperty(fromProperty$2, maybeArchivedProperty._0),
                              {
                                hd: {
                                  NAME: "Archive",
                                  VAL: {
                                    NAME: "Property",
                                    VAL: propertyId
                                  }
                                },
                                tl: /* [] */0
                              }
                            ]);
                }
                fromProperty$1 = fromProperty$2;
              } else {
                fromProperty$1 = fromProperty$2;
              }
              Curry._1(onError, "Illegal state in ActionDiff. Couldn't find an archived property: " + fromProperty$1.name + " (" + fromProperty$1.id + ")");
              return {
                      hd: {
                        NAME: "Archive",
                        VAL: {
                          NAME: "Property",
                          VAL: propertyId
                        }
                      },
                      tl: /* [] */0
                    };
            })));
  return Belt_List.concat(newAndUpdated, removed);
}

function diffSourceName(fromName, toName, sourceId) {
  return Belt_List.map(diffOption(fromName, toName), (function (change) {
                return {
                        NAME: "UpdateSourceName",
                        VAL: [
                          sourceId,
                          change.VAL
                        ]
                      };
              }));
}

function diffSourceLanguage(fromLanguage, toLanguage, sourceId) {
  var toLang;
  if (fromLanguage !== undefined) {
    if (toLanguage === undefined) {
      return /* [] */0;
    }
    var toLang$1 = Caml_option.valFromOption(toLanguage);
    if (Caml_obj.caml_equal(Caml_option.valFromOption(fromLanguage), toLang$1)) {
      return /* [] */0;
    }
    toLang = toLang$1;
  } else {
    if (toLanguage === undefined) {
      return /* [] */0;
    }
    toLang = Caml_option.valFromOption(toLanguage);
  }
  return {
          hd: {
            NAME: "UpdateProgrammingLanguage",
            VAL: [
              sourceId,
              toLang
            ]
          },
          tl: /* [] */0
        };
}

function diffSourcePlatform(fromPlatform, toPlatform, sourceId) {
  var toPlatform$1;
  if (fromPlatform !== undefined) {
    if (toPlatform === undefined) {
      return /* [] */0;
    }
    var toPlatform$2 = Caml_option.valFromOption(toPlatform);
    if (Caml_obj.caml_equal(Caml_option.valFromOption(fromPlatform), toPlatform$2)) {
      return /* [] */0;
    }
    toPlatform$1 = toPlatform$2;
  } else {
    if (toPlatform === undefined) {
      return /* [] */0;
    }
    toPlatform$1 = Caml_option.valFromOption(toPlatform);
  }
  return {
          hd: {
            NAME: "UpdateSourcePlatform",
            VAL: [
              sourceId,
              toPlatform$1
            ]
          },
          tl: /* [] */0
        };
}

function diffSourceDestinations(fromDestinations, toDestinations, sourceId) {
  var newAndUpdated = BeltListExtensions.flatMap(toDestinations, (function (toDestination) {
          var destinationId = toDestination.destinationId;
          var maybeFromSourceDestination = Belt_List.getBy(fromDestinations, (function (fromDestination) {
                  return fromDestination.destinationId === destinationId;
                }));
          if (maybeFromSourceDestination !== undefined) {
            if (maybeFromSourceDestination.destinationMode !== toDestination.destinationMode) {
              return {
                      hd: {
                        NAME: "UpdateSourceDestinationMode",
                        VAL: [
                          sourceId,
                          destinationId,
                          toDestination.destinationMode
                        ]
                      },
                      tl: /* [] */0
                    };
            } else {
              return /* [] */0;
            }
          }
          var destinationMode = toDestination.destinationMode;
          return Belt_List.concat({
                      hd: {
                        NAME: "IncludeDestinationInSourceV2",
                        VAL: [
                          sourceId,
                          destinationId,
                          /* [] */0
                        ]
                      },
                      tl: /* [] */0
                    }, destinationMode !== 1 ? ({
                          hd: {
                            NAME: "UpdateSourceDestinationMode",
                            VAL: [
                              sourceId,
                              destinationId,
                              destinationMode
                            ]
                          },
                          tl: /* [] */0
                        }) : /* [] */0);
        }));
  var removed = Belt_List.keepMap(fromDestinations, (function (fromDestination) {
          var destinationId = fromDestination.destinationId;
          var maybeToDestination = Belt_List.getBy(toDestinations, (function (destination) {
                  return destination.destinationId === destinationId;
                }));
          if (maybeToDestination !== undefined) {
            return ;
          } else {
            return {
                    NAME: "ExcludeDestinationFromSource",
                    VAL: [
                      sourceId,
                      destinationId
                    ]
                  };
          }
        }));
  return Belt_List.concat(newAndUpdated, removed);
}

function diffSourceFilename(fromFilename, toFilename, sourceId) {
  if (Caml_obj.caml_notequal(fromFilename, toFilename)) {
    return {
            hd: {
              NAME: "UpdateLibraryName",
              VAL: [
                sourceId,
                toFilename
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffSourcePath(fromPath, toPath, sourceId) {
  if (Caml_obj.caml_notequal(fromPath, toPath)) {
    return {
            hd: {
              NAME: "UpdateLibraryDestination",
              VAL: [
                sourceId,
                toPath
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffSourceSegmentIntegrations(fromIntegrations, toIntegrations, sourceId) {
  return Belt_List.map(diffStringList(fromIntegrations, toIntegrations), (function (change) {
                if (change.NAME === "Remove") {
                  return {
                          NAME: "RemoveSourceSegmentIntegration",
                          VAL: [
                            sourceId,
                            change.VAL[0]
                          ]
                        };
                }
                var match = change.VAL;
                return {
                        NAME: "AddSourceSegmentIntegration",
                        VAL: [
                          sourceId,
                          match[0],
                          match[1]
                        ]
                      };
              }));
}

function diffSourceConfig(fromSourceConfig, toSourceConfig, sourceId) {
  return Belt_List.map(diffStringList(fromSourceConfig, toSourceConfig), (function (change) {
                if (change.NAME === "Remove") {
                  return {
                          NAME: "RemoveSourceConfig",
                          VAL: [
                            sourceId,
                            change.VAL
                          ]
                        };
                } else {
                  return {
                          NAME: "AddSourceConfig",
                          VAL: [
                            sourceId,
                            change.VAL
                          ]
                        };
                }
              }));
}

function diffSource(fromSource, toSource) {
  var sourceId = toSource.id;
  return Belt_List.concatMany([
              diffSourceName(fromSource.name, toSource.name, sourceId),
              diffSourceLanguage(fromSource.language, toSource.language, sourceId),
              diffSourcePlatform(fromSource.platform, toSource.platform, sourceId),
              diffSourceDestinations(fromSource.destinations, toSource.destinations, sourceId),
              diffSourceFilename(fromSource.filename, toSource.filename, sourceId),
              diffSourcePath(fromSource.path, toSource.path, sourceId),
              diffSourceSegmentIntegrations(fromSource.segmentDestinationOptions, toSource.segmentDestinationOptions, sourceId),
              diffSourceConfig(fromSource.config, toSource.config, sourceId)
            ]);
}

function diffSources(fromSources, toSources, fromSourcesArchive, toSourcesArchive, onError) {
  var newAndUpdated = BeltListExtensions.flatMap(toSources, (function (toSource) {
          var sourceId = toSource.id;
          var maybeFromSource = Belt_List.getByU(fromSources, (function (fromSource) {
                  return fromSource.id === sourceId;
                }));
          var maybeArchivedSource = Belt_List.getByU(fromSourcesArchive, (function (fromSource) {
                  return fromSource.id === sourceId;
                }));
          if (maybeFromSource !== undefined) {
            return diffSource(maybeFromSource, toSource);
          } else if (maybeArchivedSource !== undefined) {
            return Belt_List.concat({
                        hd: {
                          NAME: "Unarchive",
                          VAL: {
                            NAME: "Source",
                            VAL: sourceId
                          }
                        },
                        tl: /* [] */0
                      }, diffSource(maybeArchivedSource, toSource));
          } else {
            return Belt_List.concat({
                        hd: {
                          NAME: "CreateSource",
                          VAL: sourceId
                        },
                        tl: /* [] */0
                      }, diffSource(TrackingPlanModel.emptySource(undefined, sourceId, AvoConfig.defaultFilename, AvoConfig.defaultPath), toSource));
          }
        }));
  var removed = Belt_List.flatten(Belt_List.map(fromSources, (function (fromSource) {
              var sourceId = fromSource.id;
              var maybeToSource = Belt_List.getBy(toSources, (function (source) {
                      return source.id === sourceId;
                    }));
              var maybeArchivedSource = Belt_List.getByU(toSourcesArchive, (function (source) {
                      return source.id === sourceId;
                    }));
              if (maybeToSource !== undefined) {
                return /* [] */0;
              } else if (maybeArchivedSource !== undefined) {
                return Belt_List.concat(diffSource(fromSource, maybeArchivedSource), {
                            hd: {
                              NAME: "Archive",
                              VAL: {
                                NAME: "Source",
                                VAL: sourceId
                              }
                            },
                            tl: /* [] */0
                          });
              } else {
                Curry._1(onError, "Illegal state in ActionDiff. Couldn't find an archived source: " + Belt_Option.getWithDefault(fromSource.name, "N/A") + " (" + fromSource.id + ")");
                return {
                        hd: {
                          NAME: "Archive",
                          VAL: {
                            NAME: "Source",
                            VAL: sourceId
                          }
                        },
                        tl: /* [] */0
                      };
              }
            })));
  return Belt_List.concat(newAndUpdated, removed);
}

function diffDestinationName(fromName, toName, destinationId) {
  return Belt_List.map(diffOption(fromName, toName), (function (change) {
                return {
                        NAME: "UpdateDestinationName",
                        VAL: [
                          destinationId,
                          change.VAL
                        ]
                      };
              }));
}

function diffDestinationType(fromType, toType, destinationId) {
  return Belt_List.map(diffOption(fromType, toType), (function (change) {
                return {
                        NAME: "UpdateDestinationAnalyticsTool",
                        VAL: [
                          destinationId,
                          change.VAL
                        ]
                      };
              }));
}

function diffDestinationApiKeys(fromKeys, toKeys, destinationId) {
  return Belt_List.concatMany([
              Belt_List.map(diffOption(fromKeys.dev, toKeys.dev), (function (change) {
                      return {
                              NAME: "UpdateDestinationDevApiKey",
                              VAL: [
                                destinationId,
                                change.VAL
                              ]
                            };
                    })),
              Belt_List.map(diffOption(fromKeys.prod, toKeys.prod), (function (change) {
                      return {
                              NAME: "UpdateDestinationProdApiKey",
                              VAL: [
                                destinationId,
                                change.VAL
                              ]
                            };
                    })),
              Belt_List.map(diffStringList(fromKeys.other, toKeys.other), (function (change) {
                      if (change.NAME === "Remove") {
                        return {
                                NAME: "RemoveDestinationApiKey",
                                VAL: [
                                  destinationId,
                                  change.VAL[0]
                                ]
                              };
                      }
                      var match = change.VAL;
                      return {
                              NAME: "UpdateDestinationApiKey",
                              VAL: [
                                destinationId,
                                match[0],
                                match[1]
                              ]
                            };
                    }))
            ]);
}

function diffDestinationIncludeUserPropsWithEventProps(fromInclude, toInclude, destinationId) {
  if (Caml_obj.caml_notequal(fromInclude, toInclude)) {
    return {
            hd: {
              NAME: "UpdateDestinationIncludeUserPropsWithEventProps",
              VAL: [
                destinationId,
                toInclude
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffDestinationDisabledByDefault(fromDisabledByDefault, toDisabledByDefault, destinationId) {
  if (Caml_obj.caml_notequal(fromDisabledByDefault, toDisabledByDefault)) {
    return {
            hd: {
              NAME: "UpdateDestinationDisabledByDefault",
              VAL: [
                destinationId,
                toDisabledByDefault
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffDestination(fromDestination, toDestination) {
  var destinationId = toDestination.id;
  return Belt_List.concatMany([
              diffDestinationName(fromDestination.name, toDestination.name, destinationId),
              diffDestinationType(fromDestination.type_, toDestination.type_, destinationId),
              diffDestinationApiKeys(fromDestination.apiKeys, toDestination.apiKeys, destinationId),
              diffDestinationIncludeUserPropsWithEventProps(fromDestination.includeUserPropsWithEventProps, toDestination.includeUserPropsWithEventProps, destinationId),
              diffDestinationDisabledByDefault(fromDestination.disabledByDefault, toDestination.disabledByDefault, destinationId)
            ]);
}

function diffDestinations(fromDestinations, toDestinations, fromDestinationsArchive, toDestinationsArchive, onError) {
  var newAndUpdated = BeltListExtensions.flatMap(toDestinations, (function (toDestination) {
          var destinationId = toDestination.id;
          var maybeFromDestination = Belt_List.getByU(fromDestinations, (function (fromDestination) {
                  return fromDestination.id === destinationId;
                }));
          var maybeArchivedDestination = Belt_List.getByU(fromDestinationsArchive, (function (fromDestination) {
                  return fromDestination.id === destinationId;
                }));
          if (maybeFromDestination !== undefined) {
            return diffDestination(maybeFromDestination, toDestination);
          } else if (maybeArchivedDestination !== undefined) {
            return Belt_List.concat({
                        hd: {
                          NAME: "Unarchive",
                          VAL: {
                            NAME: "Destination",
                            VAL: destinationId
                          }
                        },
                        tl: /* [] */0
                      }, diffDestination(maybeArchivedDestination, toDestination));
          } else {
            return Belt_List.concat({
                        hd: {
                          NAME: "CreateDestination",
                          VAL: destinationId
                        },
                        tl: /* [] */0
                      }, diffDestination(TrackingPlanModel.emptyDestination(destinationId), toDestination));
          }
        }));
  var removed = Belt_List.flatten(Belt_List.map(fromDestinations, (function (fromDestination) {
              var destinationId = fromDestination.id;
              var maybeToDestination = Belt_List.getBy(toDestinations, (function (destination) {
                      return destination.id === destinationId;
                    }));
              var maybeArchivedDestination = Belt_List.getByU(toDestinationsArchive, (function (destination) {
                      return destination.id === destinationId;
                    }));
              if (maybeToDestination !== undefined) {
                return /* [] */0;
              } else if (maybeArchivedDestination !== undefined) {
                return Belt_List.concat(diffDestination(fromDestination, maybeArchivedDestination), {
                            hd: {
                              NAME: "Archive",
                              VAL: {
                                NAME: "Destination",
                                VAL: destinationId
                              }
                            },
                            tl: /* [] */0
                          });
              } else {
                Curry._1(onError, "Illegal state in ActionDiff. Couldn't find an archived destination: " + Belt_Option.getWithDefault(fromDestination.name, "N/A") + " (" + fromDestination.id + ")");
                return {
                        hd: {
                          NAME: "Archive",
                          VAL: {
                            NAME: "Destination",
                            VAL: destinationId
                          }
                        },
                        tl: /* [] */0
                      };
              }
            })));
  return Belt_List.concat(newAndUpdated, removed);
}

function diffMetricName(fromName, toName, metricId) {
  if (Caml_obj.caml_notequal(fromName, toName)) {
    return {
            hd: {
              NAME: "UpdateMetricName",
              VAL: [
                metricId,
                toName
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffMetricType(fromType, toType, metricId) {
  if (Caml_obj.caml_notequal(fromType, toType)) {
    return {
            hd: {
              NAME: "UpdateMetricType",
              VAL: [
                metricId,
                toType
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffMetricDescription(fromDescription, toDescription, metricId) {
  if (Caml_obj.caml_notequal(fromDescription, toDescription)) {
    return {
            hd: {
              NAME: "UpdateMetricDescription",
              VAL: [
                metricId,
                toDescription
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffMetricItems(fromItems, toItems, metricId) {
  var listDiff = BeltListExtensions.flatMap(diffList(fromItems, toItems, (function (a, b) {
              return Caml_obj.caml_equal(a.VAL, b.VAL);
            })), (function (change) {
          if (change.NAME === "Remove") {
            var match = change.VAL.VAL;
            return {
                    hd: {
                      NAME: "RemoveEventFromMetric",
                      VAL: [
                        metricId,
                        match.id,
                        match.eventId
                      ]
                    },
                    tl: /* [] */0
                  };
          }
          var match$1 = change.VAL.VAL;
          var eventId = match$1.eventId;
          var id = match$1.id;
          return Belt_List.concatMany([
                      {
                        hd: {
                          NAME: "AddEventToMetric",
                          VAL: [
                            metricId,
                            id,
                            eventId
                          ]
                        },
                        tl: /* [] */0
                      },
                      Belt_List.map(match$1.where, (function (param) {
                              return {
                                      NAME: "SetMetricWhereV2",
                                      VAL: [
                                        metricId,
                                        id,
                                        eventId,
                                        param.id,
                                        param.propertyId,
                                        param.binOp,
                                        param.literals
                                      ]
                                    };
                            })),
                      Belt_List.map(match$1.groupBy, (function (param) {
                              return {
                                      NAME: "SetMetricGroupByV2",
                                      VAL: [
                                        metricId,
                                        id,
                                        eventId,
                                        param.id,
                                        param.propertyId
                                      ]
                                    };
                            }))
                    ]);
        }));
  var orderDiff = Belt_List.map(diffListOrder(fromItems, toItems, (function (list, item) {
              var eventItem = item.VAL;
              return Belt_List.get(Belt_List.keepMap(list, (function (param) {
                                if (param[1].VAL.id === eventItem.id) {
                                  return param[0];
                                }
                                
                              })), 0);
            })), (function (change) {
          var match = change.VAL;
          var match$1 = match[1];
          var id = match[0].VAL.id;
          if (match$1 !== undefined) {
            return {
                    NAME: "ReorderEventsInMetric",
                    VAL: [
                      metricId,
                      id,
                      match$1.VAL.id
                    ]
                  };
          } else {
            return {
                    NAME: "ReorderEventsInMetric",
                    VAL: [
                      metricId,
                      id,
                      undefined
                    ]
                  };
          }
        }));
  return Belt_List.concat(listDiff, orderDiff);
}

function diffMetric(fromMetric, toMetric) {
  var metricId = toMetric.id;
  return Belt_List.concatMany([
              diffMetricName(fromMetric.name, toMetric.name, metricId),
              diffMetricDescription(fromMetric.description, toMetric.description, metricId),
              diffMetricType(fromMetric.type_, toMetric.type_, metricId),
              diffMetricItems(fromMetric.items, toMetric.items, metricId)
            ]);
}

function diffMetrics(fromMetrics, toMetrics, fromMetricsArchive, toMetricsArchive, onError) {
  var newAndUpdated = BeltListExtensions.flatMap(toMetrics, (function (toMetric) {
          var metricId = toMetric.id;
          var maybeFromMetric = Belt_List.getByU(fromMetrics, (function (fromMetric) {
                  return fromMetric.id === metricId;
                }));
          var maybeArchivedMetric = Belt_List.getByU(fromMetricsArchive, (function (fromMetric) {
                  return fromMetric.id === metricId;
                }));
          if (maybeFromMetric !== undefined) {
            return diffMetric(maybeFromMetric, toMetric);
          } else if (maybeArchivedMetric !== undefined) {
            return Belt_List.concat({
                        hd: {
                          NAME: "Unarchive",
                          VAL: {
                            NAME: "Metric",
                            VAL: toMetric.id
                          }
                        },
                        tl: /* [] */0
                      }, diffMetric(maybeArchivedMetric, toMetric));
          } else {
            return Belt_List.concat({
                        hd: {
                          NAME: "AddMetric",
                          VAL: toMetric.id
                        },
                        tl: /* [] */0
                      }, diffMetric(TrackingPlanModel.emptyMetric(toMetric.id), toMetric));
          }
        }));
  var removed = Belt_List.flatten(Belt_List.map(fromMetrics, (function (fromMetric) {
              var metricId = fromMetric.id;
              var maybeToMetric = Belt_List.getBy(toMetrics, (function (metric) {
                      return metric.id === metricId;
                    }));
              var maybeArchivedMetric = Belt_List.getByU(toMetricsArchive, (function (metric) {
                      return metric.id === metricId;
                    }));
              if (maybeToMetric !== undefined) {
                return /* [] */0;
              } else if (maybeArchivedMetric !== undefined) {
                return Belt_List.concat(diffMetric(fromMetric, maybeArchivedMetric), {
                            hd: {
                              NAME: "Archive",
                              VAL: {
                                NAME: "Metric",
                                VAL: metricId
                              }
                            },
                            tl: /* [] */0
                          });
              } else {
                Curry._1(onError, "Illegal state in ActionDiff. Couldn't find an archived metric: " + fromMetric.name + " (" + fromMetric.id + ")");
                return {
                        hd: {
                          NAME: "Archive",
                          VAL: {
                            NAME: "Metric",
                            VAL: metricId
                          }
                        },
                        tl: /* [] */0
                      };
              }
            })));
  return Belt_List.concat(newAndUpdated, removed);
}

function diffGoalName(fromName, toName, goalId) {
  if (Caml_obj.caml_notequal(fromName, toName)) {
    return {
            hd: {
              NAME: "UpdateGoalName",
              VAL: [
                goalId,
                toName
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffGoalDescription(fromDescription, toDescription, goalId) {
  if (Caml_obj.caml_notequal(fromDescription, toDescription)) {
    return {
            hd: {
              NAME: "UpdateGoalDescription",
              VAL: [
                goalId,
                toDescription
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffGoalMetrics(fromMetrics, toMetrics, goalId) {
  var listDiff = Belt_List.map(diffStringList(fromMetrics, toMetrics), (function (change) {
          if (change.NAME === "Remove") {
            return {
                    NAME: "RemoveMetricFromGoal",
                    VAL: [
                      goalId,
                      change.VAL
                    ]
                  };
          } else {
            return {
                    NAME: "AddMetricToGoal",
                    VAL: [
                      goalId,
                      change.VAL
                    ]
                  };
          }
        }));
  var orderDiff = Belt_List.map(diffListOrder(fromMetrics, toMetrics, (function (list, metricId) {
              return Belt_List.get(Belt_List.keepMap(list, (function (param) {
                                if (Caml_obj.caml_equal(param[1], metricId)) {
                                  return param[0];
                                }
                                
                              })), 0);
            })), (function (change) {
          var match = change.VAL;
          return {
                  NAME: "ReorderMetricsInGoal",
                  VAL: [
                    goalId,
                    match[0],
                    match[1]
                  ]
                };
        }));
  return Belt_List.concat(listDiff, orderDiff);
}

function diffGoalEvents(fromEvents, toEvents, goalId) {
  var listDiff = Belt_List.map(diffStringList(fromEvents, toEvents), (function (change) {
          if (change.NAME === "Remove") {
            return {
                    NAME: "RemoveEventFromGoal",
                    VAL: [
                      goalId,
                      change.VAL
                    ]
                  };
          } else {
            return {
                    NAME: "AddEventToGoal",
                    VAL: [
                      goalId,
                      change.VAL
                    ]
                  };
          }
        }));
  var orderDiff = Belt_List.map(diffListOrder(fromEvents, toEvents, (function (list, eventId) {
              return Belt_List.get(Belt_List.keepMap(list, (function (param) {
                                if (Caml_obj.caml_equal(param[1], eventId)) {
                                  return param[0];
                                }
                                
                              })), 0);
            })), (function (change) {
          var match = change.VAL;
          return {
                  NAME: "ReorderEventsInGoal",
                  VAL: [
                    goalId,
                    match[0],
                    match[1]
                  ]
                };
        }));
  return Belt_List.concat(listDiff, orderDiff);
}

function diffGoal(fromGoal, toGoal) {
  var goalId = toGoal.id;
  return Belt_List.concatMany([
              diffGoalName(fromGoal.name, toGoal.name, goalId),
              diffGoalDescription(fromGoal.description, toGoal.description, goalId),
              diffGoalMetrics(fromGoal.metrics, toGoal.metrics, goalId),
              diffGoalEvents(fromGoal.events, toGoal.events, goalId)
            ]);
}

function diffGoals(fromGoals, toGoals, fromGoalsArchive, toGoalsArchive, onError) {
  var newAndUpdated = BeltListExtensions.flatMap(toGoals, (function (toGoal) {
          var goalId = toGoal.id;
          var maybeFromGoal = Belt_List.getByU(fromGoals, (function (fromGoal) {
                  return fromGoal.id === goalId;
                }));
          var maybeArchivedGoal = Belt_List.getByU(fromGoalsArchive, (function (fromGoal) {
                  return fromGoal.id === goalId;
                }));
          if (maybeFromGoal !== undefined) {
            return diffGoal(maybeFromGoal, toGoal);
          } else if (maybeArchivedGoal !== undefined) {
            return Belt_List.concat({
                        hd: {
                          NAME: "Unarchive",
                          VAL: {
                            NAME: "Goal",
                            VAL: goalId
                          }
                        },
                        tl: /* [] */0
                      }, diffGoal(maybeArchivedGoal, toGoal));
          } else {
            return Belt_List.concat({
                        hd: {
                          NAME: "AddGoal",
                          VAL: goalId
                        },
                        tl: /* [] */0
                      }, diffGoal(TrackingPlanModel.emptyGoal(goalId), toGoal));
          }
        }));
  var removed = Belt_List.flatten(Belt_List.map(fromGoals, (function (fromGoal) {
              var goalId = fromGoal.id;
              var maybeToGoal = Belt_List.getBy(toGoals, (function (goal) {
                      return goal.id === goalId;
                    }));
              var maybeArchivedGoal = Belt_List.getByU(toGoalsArchive, (function (goal) {
                      return goal.id === goalId;
                    }));
              if (maybeToGoal !== undefined) {
                return /* [] */0;
              } else if (maybeArchivedGoal !== undefined) {
                return Belt_List.concat(diffGoal(fromGoal, maybeArchivedGoal), {
                            hd: {
                              NAME: "Archive",
                              VAL: {
                                NAME: "Goal",
                                VAL: goalId
                              }
                            },
                            tl: /* [] */0
                          });
              } else {
                Curry._1(onError, "Illegal state in ActionDiff. Couldn't find an archived goal: " + fromGoal.name + " (" + fromGoal.id + ")");
                return {
                        hd: {
                          NAME: "Archive",
                          VAL: {
                            NAME: "Goal",
                            VAL: goalId
                          }
                        },
                        tl: /* [] */0
                      };
              }
            })));
  var reorders = Belt_List.map(diffListOrder(fromGoals, toGoals, (function (goals, goal) {
              return Belt_List.get(Belt_List.keepMap(goals, (function (param) {
                                if (param[1].id === goal.id) {
                                  return param[0];
                                }
                                
                              })), 0);
            })), (function (change) {
          var match = change.VAL;
          var match$1 = match[1];
          var goal = match[0];
          if (match$1 !== undefined) {
            return {
                    NAME: "ReorderGoals",
                    VAL: [
                      goal.id,
                      match$1.id
                    ]
                  };
          } else {
            return {
                    NAME: "ReorderGoals",
                    VAL: [
                      goal.id,
                      undefined
                    ]
                  };
          }
        }));
  return Belt_List.concatMany([
              newAndUpdated,
              reorders,
              removed
            ]);
}

function diffGroupName(fromName, toName, groupId) {
  if (Caml_obj.caml_notequal(fromName, toName)) {
    return {
            hd: {
              NAME: "UpdatePropertyGroupName",
              VAL: [
                groupId,
                toName
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffGroupDescription(fromDescription, toDescription, groupId) {
  if (Caml_obj.caml_notequal(fromDescription, toDescription)) {
    return {
            hd: {
              NAME: "UpdatePropertyGroupDescription",
              VAL: [
                groupId,
                toDescription
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffGroupProperties(fromProperties, toProperties, groupId) {
  return Belt_List.map(diffStringList(fromProperties, toProperties), (function (change) {
                if (change.NAME === "Remove") {
                  return {
                          NAME: "RemovePropertyFromGroup",
                          VAL: [
                            groupId,
                            change.VAL
                          ]
                        };
                } else {
                  return {
                          NAME: "AddPropertyToGroup",
                          VAL: [
                            groupId,
                            change.VAL
                          ]
                        };
                }
              }));
}

function diffGroup(fromGroup, toGroup) {
  var groupId = toGroup.id;
  return Belt_List.concatMany([
              diffGroupName(fromGroup.name, toGroup.name, groupId),
              diffGroupDescription(fromGroup.description, toGroup.description, groupId),
              diffGroupProperties(fromGroup.properties, toGroup.properties, groupId)
            ]);
}

function diffPropertyGroups(fromGroups, toGroups, fromGroupsArchive, toGroupsArchive) {
  var newAndUpdated = BeltListExtensions.flatMap(toGroups, (function (toGroup) {
          var groupId = toGroup.id;
          var maybeFromGroup = Belt_List.getByU(fromGroups, (function (fromGroup) {
                  return fromGroup.id === groupId;
                }));
          var maybeArchivedGroup = Belt_List.getByU(fromGroupsArchive, (function (fromGroup) {
                  return fromGroup.id === groupId;
                }));
          if (maybeFromGroup !== undefined) {
            return diffGroup(maybeFromGroup, toGroup);
          } else if (maybeArchivedGroup !== undefined) {
            return Belt_List.concat({
                        hd: {
                          NAME: "Unarchive",
                          VAL: {
                            NAME: "PropertyGroup",
                            VAL: groupId
                          }
                        },
                        tl: /* [] */0
                      }, diffGroup(maybeArchivedGroup, toGroup));
          } else {
            return Belt_List.concat({
                        hd: {
                          NAME: "CreatePropertyGroup",
                          VAL: [
                            groupId,
                            toGroup.name
                          ]
                        },
                        tl: /* [] */0
                      }, diffGroup(TrackingPlanModel.emptyPropertyBundle(groupId), toGroup));
          }
        }));
  var removed = Belt_List.flatten(Belt_List.map(fromGroups, (function (fromGroup) {
              var groupId = fromGroup.id;
              var maybeToGroup = Belt_List.getBy(toGroups, (function (group) {
                      return group.id === groupId;
                    }));
              var maybeArchivedGroup = Belt_List.getByU(toGroupsArchive, (function (group) {
                      return group.id === groupId;
                    }));
              if (maybeToGroup !== undefined) {
                return /* [] */0;
              } else if (maybeArchivedGroup !== undefined) {
                return Belt_List.concat(diffGroup(fromGroup, maybeArchivedGroup), {
                            hd: {
                              NAME: "Archive",
                              VAL: {
                                NAME: "PropertyGroup",
                                VAL: groupId
                              }
                            },
                            tl: /* [] */0
                          });
              } else {
                console.error("Illegal state in ActionDiff. Couldn't find an archived group: " + fromGroup.name + " (" + fromGroup.id + ")");
                return {
                        hd: {
                          NAME: "Archive",
                          VAL: {
                            NAME: "PropertyGroup",
                            VAL: groupId
                          }
                        },
                        tl: /* [] */0
                      };
              }
            })));
  return Belt_List.concat(newAndUpdated, removed);
}

function diffRule(fromRule, toRule) {
  if (Caml_obj.caml_notequal(fromRule, toRule)) {
    return {
            hd: {
              NAME: "UpdateRule",
              VAL: [
                toRule.id,
                toRule.item,
                toRule.definition
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function diffRules(fromRules, toRules, toModel) {
  var newAndUpdated = BeltListExtensions.flatMap(toRules, (function (toRule) {
          var ruleId = toRule.id;
          var maybeFromRule = Belt_List.getBy(fromRules, (function (fromRule) {
                  return fromRule.id === ruleId;
                }));
          if (maybeFromRule !== undefined) {
            return diffRule(maybeFromRule, toRule);
          } else {
            return {
                    hd: {
                      NAME: "AddRule",
                      VAL: [
                        toRule.id,
                        toRule.item,
                        toRule.definition
                      ]
                    },
                    tl: /* [] */0
                  };
          }
        }));
  var removed = Belt_List.keepMap(fromRules, (function (fromRule) {
          var ruleId = fromRule.id;
          var maybeToRule = Belt_List.getBy(toRules, (function (rule) {
                  return rule.id === ruleId;
                }));
          if (maybeToRule !== undefined) {
            return ;
          } else {
            return {
                    NAME: "RemoveRule",
                    VAL: [
                      fromRule.id,
                      fromRule.item
                    ]
                  };
          }
        }));
  var actions = Belt_List.concat(newAndUpdated, removed);
  return Belt_List.keepU(actions, (function (action) {
                var eventId;
                var propertyId;
                var nestedPropertyId;
                if (typeof action !== "object") {
                  return true;
                }
                var variant = action.NAME;
                if (variant === "AddRule") {
                  var match = action.VAL[1];
                  var variant$1 = match.NAME;
                  if (variant$1 === "PropertyRef") {
                    var match$1 = match.VAL;
                    return Belt_Option.isSome(StateUtils.getPropertyRefById(match$1[0], match$1[1], toModel));
                  }
                  if (variant$1 === "Property") {
                    return Belt_Option.isSome(StateUtils.getPropertyById(match.VAL, toModel));
                  }
                  if (variant$1 === "Event") {
                    return Belt_Option.isSome(StateUtils.getEventById(match.VAL, toModel));
                  }
                  var match$2 = match.VAL;
                  eventId = match$2[0];
                  propertyId = match$2[1];
                  nestedPropertyId = match$2[2];
                } else {
                  if (variant !== "UpdateRule") {
                    return true;
                  }
                  var match$3 = action.VAL[1];
                  var variant$2 = match$3.NAME;
                  if (variant$2 === "PropertyRef") {
                    var match$4 = match$3.VAL;
                    return Belt_Option.isSome(StateUtils.getPropertyRefById(match$4[0], match$4[1], toModel));
                  }
                  if (variant$2 === "Property") {
                    return Belt_Option.isSome(StateUtils.getPropertyById(match$3.VAL, toModel));
                  }
                  if (variant$2 === "Event") {
                    return Belt_Option.isSome(StateUtils.getEventById(match$3.VAL, toModel));
                  }
                  var match$5 = match$3.VAL;
                  eventId = match$5[0];
                  propertyId = match$5[1];
                  nestedPropertyId = match$5[2];
                }
                if (Belt_Option.isSome(StateUtils.getPropertyRefById(eventId, propertyId, toModel))) {
                  return Belt_Option.isSome(StateUtils.getNestedPropertyRefById(propertyId, nestedPropertyId, toModel));
                } else {
                  return false;
                }
              }));
}

function diffIntegration(fromIntegration, toIntegration) {
  if (!Caml_obj.caml_notequal(fromIntegration, toIntegration)) {
    return /* [] */0;
  }
  var match = fromIntegration.name;
  var match$1 = toIntegration.name;
  var match$2 = fromIntegration.config;
  var match$3 = toIntegration.config;
  return Belt_List.concatMany([
              match !== undefined ? (
                  match$1 !== undefined && match !== match$1 ? ({
                        hd: {
                          NAME: "UpdateIntegrationName",
                          VAL: [
                            toIntegration.id,
                            match$1
                          ]
                        },
                        tl: /* [] */0
                      }) : /* [] */0
                ) : (
                  match$1 !== undefined ? ({
                        hd: {
                          NAME: "UpdateIntegrationName",
                          VAL: [
                            toIntegration.id,
                            match$1
                          ]
                        },
                        tl: /* [] */0
                      }) : /* [] */0
                ),
              Caml_obj.caml_notequal(fromIntegration.filters, toIntegration.filters) ? ({
                    hd: {
                      NAME: "UpdateIntegrationFilters",
                      VAL: [
                        toIntegration.id,
                        toIntegration.filters
                      ]
                    },
                    tl: /* [] */0
                  }) : /* [] */0,
              match$2 !== undefined ? (
                  match$3 !== undefined && Caml_obj.caml_notequal(match$2, match$3) ? ({
                        hd: {
                          NAME: "UpdateIntegrationConfig",
                          VAL: [
                            toIntegration.id,
                            match$3
                          ]
                        },
                        tl: /* [] */0
                      }) : /* [] */0
                ) : (
                  match$3 !== undefined ? ({
                        hd: {
                          NAME: "UpdateIntegrationConfig",
                          VAL: [
                            toIntegration.id,
                            match$3
                          ]
                        },
                        tl: /* [] */0
                      }) : /* [] */0
                ),
              fromIntegration.autoPublish !== toIntegration.autoPublish ? ({
                    hd: {
                      NAME: "UpdateIntegrationAutoPublish",
                      VAL: [
                        toIntegration.id,
                        toIntegration.autoPublish
                      ]
                    },
                    tl: /* [] */0
                  }) : /* [] */0
            ]);
}

function diffIntegrations(fromIntegrations, toIntegrations) {
  var newAndUpdated = BeltListExtensions.flatMap(toIntegrations, (function (toIntegration) {
          var integrationId = toIntegration.id;
          var maybeFromIntegration = Belt_List.getBy(fromIntegrations, (function (fromIntegration) {
                  return fromIntegration.id === integrationId;
                }));
          if (maybeFromIntegration !== undefined) {
            return diffIntegration(maybeFromIntegration, toIntegration);
          } else {
            return Belt_List.concatMany([
                        {
                          hd: {
                            NAME: "CreateIntegration",
                            VAL: toIntegration.id
                          },
                          tl: {
                            hd: {
                              NAME: "UpdateIntegrationFilters",
                              VAL: [
                                toIntegration.id,
                                toIntegration.filters
                              ]
                            },
                            tl: {
                              hd: {
                                NAME: "UpdateIntegrationAutoPublish",
                                VAL: [
                                  toIntegration.id,
                                  toIntegration.autoPublish
                                ]
                              },
                              tl: /* [] */0
                            }
                          }
                        },
                        Belt_Option.mapWithDefault(toIntegration.name, /* [] */0, (function (name) {
                                return {
                                        hd: {
                                          NAME: "UpdateIntegrationName",
                                          VAL: [
                                            toIntegration.id,
                                            name
                                          ]
                                        },
                                        tl: /* [] */0
                                      };
                              })),
                        Belt_Option.mapWithDefault(toIntegration.config, /* [] */0, (function (config) {
                                return {
                                        hd: {
                                          NAME: "UpdateIntegrationConfig",
                                          VAL: [
                                            toIntegration.id,
                                            config
                                          ]
                                        },
                                        tl: /* [] */0
                                      };
                              }))
                      ]);
          }
        }));
  var removed = Belt_List.keepMap(fromIntegrations, (function (fromIntegration) {
          var integrationId = fromIntegration.id;
          var maybeToIntegration = Belt_List.getBy(toIntegrations, (function (integration) {
                  return integration.id === integrationId;
                }));
          if (maybeToIntegration !== undefined) {
            return ;
          } else {
            return {
                    NAME: "RemoveIntegration",
                    VAL: integrationId
                  };
          }
        }));
  return Belt_List.concat(newAndUpdated, removed);
}

function diffGroupType(fromGroupType, toGroupType) {
  if (Caml_obj.caml_notequal(fromGroupType, toGroupType)) {
    return Belt_List.concatMany([
                fromGroupType.name !== toGroupType.name ? ({
                      hd: {
                        NAME: "UpdateGroupTypeName",
                        VAL: [
                          toGroupType.id,
                          toGroupType.name
                        ]
                      },
                      tl: /* [] */0
                    }) : /* [] */0,
                /* [] */0
              ]);
  } else {
    return /* [] */0;
  }
}

function diffGroupTypes(fromGroupTypes, toGroupTypes, fromGroupTypesArchive, toGroupTypesArchive, onError) {
  var newAndUpdated = Belt_List.concatMany(Belt_Array.map(toGroupTypes, (function (toGroupType) {
              var groupTypeId = toGroupType.id;
              var maybeFromGroupType = Belt_Array.getBy(fromGroupTypes, (function (fromGroupType) {
                      return fromGroupType.id === groupTypeId;
                    }));
              var maybeArchivedGroupType = Belt_Array.getBy(fromGroupTypesArchive, (function (fromGroupType) {
                      return fromGroupType.id === groupTypeId;
                    }));
              if (maybeFromGroupType !== undefined) {
                return diffGroupType(maybeFromGroupType, toGroupType);
              } else if (maybeArchivedGroupType !== undefined) {
                return Belt_List.concat({
                            hd: {
                              NAME: "Unarchive",
                              VAL: {
                                NAME: "GroupType",
                                VAL: groupTypeId
                              }
                            },
                            tl: /* [] */0
                          }, diffGroupType(maybeArchivedGroupType, toGroupType));
              } else {
                return {
                        hd: {
                          NAME: "CreateGroupType",
                          VAL: [
                            groupTypeId,
                            toGroupType.name,
                            toGroupType.description
                          ]
                        },
                        tl: /* [] */0
                      };
              }
            })));
  var removed = Belt_List.flatten(Belt_List.fromArray(Belt_Array.map(fromGroupTypes, (function (fromGroupType) {
                  var groupTypeId = fromGroupType.id;
                  var maybeToGroupType = Belt_Array.getBy(toGroupTypes, (function (toGroupType) {
                          return toGroupType.id === groupTypeId;
                        }));
                  var maybeArchivedGroupType = Belt_Array.getByU(toGroupTypesArchive, (function (groupType) {
                          return groupType.id === groupTypeId;
                        }));
                  if (maybeToGroupType !== undefined) {
                    return /* [] */0;
                  } else if (maybeArchivedGroupType !== undefined) {
                    return Belt_List.concat(diffGroupType(fromGroupType, maybeArchivedGroupType), {
                                hd: {
                                  NAME: "Archive",
                                  VAL: {
                                    NAME: "GroupType",
                                    VAL: groupTypeId
                                  }
                                },
                                tl: /* [] */0
                              });
                  } else {
                    Curry._1(onError, "Illegal state in ActionDiff. Couldn't find an archived group type: " + fromGroupType.name + " (" + fromGroupType.id + ")");
                    return {
                            hd: {
                              NAME: "Archive",
                              VAL: {
                                NAME: "GroupType",
                                VAL: groupTypeId
                              }
                            },
                            tl: /* [] */0
                          };
                  }
                }))));
  return Belt_List.concat(newAndUpdated, removed);
}

function diff(onErrorOpt, fromModel, toModel, param) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var match = ActionsReducer.hasMigrated(fromModel, "PropertyOptionalAndExcludedSourcesToAbsence");
  var match$1 = ActionsReducer.hasMigrated(toModel, "PropertyOptionalAndExcludedSourcesToAbsence");
  var match$2 = match ? (
      match$1 ? [
          fromModel,
          toModel
        ] : [
          fromModel,
          ActionsReducer.reduce(toModel, "MigratePropertyOptionalAndExcludedSourcesToAbsence")
        ]
    ) : (
      match$1 ? [
          ActionsReducer.reduce(fromModel, "MigratePropertyOptionalAndExcludedSourcesToAbsence"),
          toModel
        ] : [
          fromModel,
          toModel
        ]
    );
  var toModel$1 = match$2[1];
  var fromModel$1 = match$2[0];
  var match$3 = ActionsReducer.hasMigrated(fromModel$1, "SkipWebFnTagToIncludeInCodegen");
  var match$4 = ActionsReducer.hasMigrated(toModel$1, "SkipWebFnTagToIncludeInCodegen");
  var match$5 = match$3 ? (
      match$4 ? [
          fromModel$1,
          toModel$1
        ] : [
          fromModel$1,
          ActionsReducer.reduce(toModel$1, "MigrateSkipWebFnTagToIncludeInCodegen")
        ]
    ) : (
      match$4 ? [
          ActionsReducer.reduce(fromModel$1, "MigrateSkipWebFnTagToIncludeInCodegen"),
          toModel$1
        ] : [
          fromModel$1,
          toModel$1
        ]
    );
  var toModel$2 = match$5[1];
  var fromModel$2 = match$5[0];
  var match$6 = ActionsReducer.hasMigrated(fromModel$2, "RemoveUnarchivedItemsFromArchive");
  var match$7 = ActionsReducer.hasMigrated(toModel$2, "RemoveUnarchivedItemsFromArchive");
  var match$8 = match$6 ? (
      match$7 ? [
          fromModel$2,
          toModel$2
        ] : [
          fromModel$2,
          ActionsReducer.reduce(toModel$2, "MigrateRemoveUnarchivedItemsFromArchive")
        ]
    ) : (
      match$7 ? [
          ActionsReducer.reduce(fromModel$2, "MigrateRemoveUnarchivedItemsFromArchive"),
          toModel$2
        ] : [
          fromModel$2,
          toModel$2
        ]
    );
  var toModel$3 = match$8[1];
  var fromModel$3 = match$8[0];
  return Belt_List.concatMany([
              diffProperties(fromModel$3.properties, toModel$3.properties, fromModel$3.archive.properties, toModel$3.archive.properties, onError),
              diffEvents(fromModel$3.events, toModel$3.events, fromModel$3.archive.events, toModel$3.archive.events, onError),
              diffSources(fromModel$3.sources, toModel$3.sources, fromModel$3.archive.sources, toModel$3.archive.sources, onError),
              diffDestinations(fromModel$3.destinations, toModel$3.destinations, fromModel$3.archive.destinations, toModel$3.archive.destinations, onError),
              Belt_List.keepU(diffMetrics(fromModel$3.metrics, toModel$3.metrics, fromModel$3.archive.metrics, toModel$3.archive.metrics, onError), (function (action) {
                      if (typeof action !== "object") {
                        return true;
                      }
                      if (action.NAME !== "AddEventToMetric") {
                        return true;
                      }
                      var eventId = action.VAL[2];
                      return Belt_List.someU(toModel$3.events, (function ($$event) {
                                    return $$event.id === eventId;
                                  }));
                    })),
              diffGoals(fromModel$3.goals, toModel$3.goals, fromModel$3.archive.goals, toModel$3.archive.goals, onError),
              diffPropertyGroups(fromModel$3.propertyBundles, toModel$3.propertyBundles, fromModel$3.archive.propertyBundles, toModel$3.archive.propertyBundles),
              diffRules(fromModel$3.rules, toModel$3.rules, toModel$3),
              diffIntegrations(fromModel$3.integrations, toModel$3.integrations),
              diffGroupTypes(fromModel$3.groupTypes, toModel$3.groupTypes, fromModel$3.archive.groupTypes, toModel$3.archive.groupTypes, onError)
            ]);
}

exports.diffList = diffList;
exports.diffArray = diffArray;
exports.diffStringArray = diffStringArray;
exports.diffListOrder = diffListOrder;
exports.diffStringList = diffStringList;
exports.diffOption = diffOption;
exports.diffEventProperties = diffEventProperties;
exports.diffEventPropertyGroups = diffEventPropertyGroups;
exports.diffEventPropertyWhitelist = diffEventPropertyWhitelist;
exports.diffEventGroupTypeIds = diffEventGroupTypeIds;
exports.diffUserGroupTypeIds = diffUserGroupTypeIds;
exports.diffEventTypes = diffEventTypes;
exports.diffEventTags = diffEventTags;
exports.diffEventSources = diffEventSources;
exports.diffEventDestinations = diffEventDestinations;
exports.diffEventName = diffEventName;
exports.diffEventUniqueName = diffEventUniqueName;
exports.diffEventDescription = diffEventDescription;
exports.diffEventTriggerDescription = diffEventTriggerDescription;
exports.diffEventTriggerSources = diffEventTriggerSources;
exports.diffEventTriggerContent = diffEventTriggerContent;
exports.diffEventTrigger = diffEventTrigger;
exports.diffEventTriggers = diffEventTriggers;
exports.diffEvent = diffEvent;
exports.diffEvents = diffEvents;
exports.diffPropertyName = diffPropertyName;
exports.diffPropertyUniqueName = diffPropertyUniqueName;
exports.diffPropertyDescription = diffPropertyDescription;
exports.diffPropertyOptional = diffPropertyOptional;
exports.diffPropertyOptionalWhenInObject = diffPropertyOptionalWhenInObject;
exports.diffPropertyAbsence = diffPropertyAbsence;
exports.diffPropertyOperation = diffPropertyOperation;
exports.diffPropertyList = diffPropertyList;
exports.diffPropertyType = diffPropertyType;
exports.diffPropertyValidations = diffPropertyValidations;
exports.diffPropertyExcludedSources = diffPropertyExcludedSources;
exports.diffProperty = diffProperty;
exports.diffProperties = diffProperties;
exports.diffSourceName = diffSourceName;
exports.diffSourceLanguage = diffSourceLanguage;
exports.diffSourcePlatform = diffSourcePlatform;
exports.diffSourceDestinations = diffSourceDestinations;
exports.diffSourceFilename = diffSourceFilename;
exports.diffSourcePath = diffSourcePath;
exports.diffSourceSegmentIntegrations = diffSourceSegmentIntegrations;
exports.diffSourceConfig = diffSourceConfig;
exports.diffSource = diffSource;
exports.diffSources = diffSources;
exports.diffDestinationName = diffDestinationName;
exports.diffDestinationType = diffDestinationType;
exports.diffDestinationApiKeys = diffDestinationApiKeys;
exports.diffDestinationIncludeUserPropsWithEventProps = diffDestinationIncludeUserPropsWithEventProps;
exports.diffDestinationDisabledByDefault = diffDestinationDisabledByDefault;
exports.diffDestination = diffDestination;
exports.diffDestinations = diffDestinations;
exports.diffMetricName = diffMetricName;
exports.diffMetricType = diffMetricType;
exports.diffMetricDescription = diffMetricDescription;
exports.diffMetricItems = diffMetricItems;
exports.diffMetric = diffMetric;
exports.diffMetrics = diffMetrics;
exports.diffGoalName = diffGoalName;
exports.diffGoalDescription = diffGoalDescription;
exports.diffGoalMetrics = diffGoalMetrics;
exports.diffGoalEvents = diffGoalEvents;
exports.diffGoal = diffGoal;
exports.diffGoals = diffGoals;
exports.diffGroupName = diffGroupName;
exports.diffGroupDescription = diffGroupDescription;
exports.diffGroupProperties = diffGroupProperties;
exports.diffGroup = diffGroup;
exports.diffPropertyGroups = diffPropertyGroups;
exports.diffRule = diffRule;
exports.diffRules = diffRules;
exports.diffIntegration = diffIntegration;
exports.diffIntegrations = diffIntegrations;
exports.diffGroupType = diffGroupType;
exports.diffGroupTypes = diffGroupTypes;
exports.diff = diff;
/* AvoConfig Not a pure module */
