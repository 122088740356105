// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function LoadingCircle(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 22;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.white;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css.width(Css.px(size)),
                      tl: {
                        hd: Css.margin(Css.px(0)),
                        tl: {
                          hd: Css.padding(Css.px(0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              version: "1.1",
              viewBox: "0 0 50 50",
              x: "0px",
              xmlns: "http://www.w3.org/2000/svg",
              xmlnsXlink: "http://www.w3.org/1999/xlink",
              xmlSpace: "preserve",
              y: "0px"
            }, React.createElement("path", {
                  d: "M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
                }, React.createElement("animateTransform", {
                      type: "rotate",
                      attributeName: "transform",
                      attributeType: "xml",
                      dur: "0.6s",
                      from: "0 25 25",
                      repeatCount: "indefinite",
                      to: "360 25 25"
                    })));
}

var make = LoadingCircle;

exports.make = make;
/* Css Not a pure module */
