// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var AvoModel = require("./avoModel.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var PropertyAbsenceViewHelpers = require("./PropertyAbsenceViewHelpers.bs.js");

function diffItemsArray(fromArray, toArray, getIdentifier, compare) {
  var fromMap = Belt_MapString.fromArray(Belt_Array.mapU(fromArray, (function (item) {
              return [
                      Curry._1(getIdentifier, item),
                      item
                    ];
            })));
  var toMap = Belt_MapString.fromArray(Belt_Array.mapU(toArray, (function (item) {
              return [
                      Curry._1(getIdentifier, item),
                      item
                    ];
            })));
  var addIds = Belt_SetString.diff(Belt_SetString.fromArray(Belt_MapString.keysToArray(toMap)), Belt_SetString.fromArray(Belt_MapString.keysToArray(fromMap)));
  var removeIds = Belt_SetString.diff(Belt_SetString.fromArray(Belt_MapString.keysToArray(fromMap)), Belt_SetString.fromArray(Belt_MapString.keysToArray(toMap)));
  var commonIds = Belt_SetString.intersect(Belt_SetString.fromArray(Belt_MapString.keysToArray(fromMap)), Belt_SetString.fromArray(Belt_MapString.keysToArray(toMap)));
  var oldCommonItems = Belt_MapString.keepU(fromMap, (function (itemId, param) {
          return Belt_SetString.has(commonIds, itemId);
        }));
  var newCommonItems = Belt_MapString.keepU(toMap, (function (itemId, param) {
          return Belt_SetString.has(commonIds, itemId);
        }));
  var commons = Belt_Array.reduceU(Belt_SetString.toArray(commonIds), {
        changed: /* [] */0,
        unchanged: /* [] */0
      }, (function (currentChangeSet, commonId) {
          var oldItem = Belt_MapString.getExn(oldCommonItems, commonId);
          var newItem = Belt_MapString.getExn(newCommonItems, commonId);
          var match = Curry._2(compare, oldItem, newItem);
          if (typeof match === "object") {
            return {
                    unchanged: currentChangeSet.unchanged,
                    changed: {
                      hd: [
                        commonId,
                        [
                          match.VAL,
                          {
                            newItem: newItem,
                            oldItem: oldItem
                          }
                        ]
                      ],
                      tl: currentChangeSet.changed
                    }
                  };
          } else {
            return {
                    unchanged: {
                      hd: newItem,
                      tl: currentChangeSet.unchanged
                    },
                    changed: currentChangeSet.changed
                  };
          }
        }));
  return {
          new: Belt_MapString.keepU(toMap, (function (id, param) {
                  return Belt_SetString.has(addIds, id);
                })),
          removed: Belt_MapString.keepU(fromMap, (function (id, param) {
                  return Belt_SetString.has(removeIds, id);
                })),
          unchanged: Belt_MapString.fromArray(Belt_Array.mapU(Belt_List.toArray(commons.unchanged), (function (item) {
                      return [
                              Curry._1(getIdentifier, item),
                              item
                            ];
                    }))),
          changed: Belt_MapString.fromArray(Belt_List.toArray(commons.changed))
        };
}

function hasSameName(fromName, toName) {
  return fromName === toName;
}

function propertyHasCompatibleType(fromType, toType, fromList, toList) {
  if (fromType === "int" && toType === "float") {
    return Caml_obj.caml_equal(fromList, toList);
  }
  if (fromType === toType) {
    return Caml_obj.caml_equal(fromList, toList);
  } else {
    return false;
  }
}

function getSimpleSourceAbsence(absence, eventId, sourceId) {
  if (absence === undefined) {
    return "always";
  }
  if (typeof absence === "number") {
    return "always";
  }
  if (absence.TAG === /* SometimesSent */0) {
    return "sometimes";
  }
  var match = PropertyAbsenceViewHelpers.getAbsenceByEventAndSource(absence._0, eventId, sourceId);
  if (match !== undefined) {
    if (match !== 2) {
      if (match >= 3) {
        return "always";
      } else {
        return "never";
      }
    } else {
      return "sometimes";
    }
  } else {
    return "always";
  }
}

function compareProperties(fromProperty, toProperty, eventId, sourceId) {
  if (fromProperty.name !== toProperty.name) {
    return {
            NAME: "change",
            VAL: "tier2"
          };
  }
  if (!propertyHasCompatibleType(fromProperty.type_, toProperty.type_, fromProperty.list, toProperty.list)) {
    return {
            NAME: "change",
            VAL: "tier2"
          };
  }
  var match = getSimpleSourceAbsence(fromProperty.absence, eventId, sourceId);
  var match$1 = getSimpleSourceAbsence(toProperty.absence, eventId, sourceId);
  if (match === "never") {
    if (match$1 === "never") {
      return "noChange";
    } else if (match$1 === "sometimes") {
      return {
              NAME: "change",
              VAL: "tier1"
            };
    } else {
      return {
              NAME: "change",
              VAL: "tier2"
            };
    }
  } else if (match === "sometimes") {
    if (match$1 === "sometimes") {
      return "noChange";
    } else {
      return {
              NAME: "change",
              VAL: "tier2"
            };
    }
  } else if (match$1 === "never") {
    return {
            NAME: "change",
            VAL: "tier2"
          };
  } else if (match$1 === "sometimes") {
    return {
            NAME: "change",
            VAL: "tier1"
          };
  } else {
    return "noChange";
  }
}

function pruneArchivedSourceIds(usedSourceIds, includedSources) {
  return Belt_List.keepU(includedSources, (function (param) {
                return Belt_SetString.has(usedSourceIds, param.id);
              }));
}

function compareEvents(fromEvent, toEvent, fromModelPropertiesMap, toModelPropertiesMap, fromModel, toModel, usedFromSourceIds, usedToSourceIds) {
  var fromEventSourceIds = Belt_SetString.fromArray(Belt_List.toArray(StateUtils.getIncludedSourceIds(pruneArchivedSourceIds(usedFromSourceIds, fromEvent.includeSources))));
  var toEventSourceIds = Belt_SetString.fromArray(Belt_List.toArray(StateUtils.getIncludedSourceIds(pruneArchivedSourceIds(usedToSourceIds, toEvent.includeSources))));
  var sourceIdsState = {
    new: Belt_SetString.diff(toEventSourceIds, fromEventSourceIds),
    removed: Belt_SetString.diff(fromEventSourceIds, toEventSourceIds),
    common: Belt_SetString.intersect(fromEventSourceIds, toEventSourceIds)
  };
  var changeSet = Belt_MapString.fromArray(Belt_Array.mapU(Belt_SetString.toArray(sourceIdsState.new), (function (sourceId) {
              return [
                      sourceId,
                      "tier1"
                    ];
            })));
  if (fromEvent.name !== toEvent.name) {
    var changeSet$1 = Belt_MapString.mergeMany(changeSet, Belt_Array.mapU(Belt_Array.concat(Belt_SetString.toArray(sourceIdsState.removed), Belt_SetString.toArray(sourceIdsState.common)), (function (sourceId) {
                return [
                        sourceId,
                        "tier2"
                      ];
              })));
    return {
            NAME: "change",
            VAL: changeSet$1
          };
  }
  var changeSet$2 = Belt_MapString.mergeMany(changeSet, Belt_Array.mapU(Belt_SetString.toArray(sourceIdsState.removed), (function (sourceId) {
              return [
                      sourceId,
                      "tier2"
                    ];
            })));
  var changeSet$3 = Belt_Array.reduceU(Belt_SetString.toArray(sourceIdsState.common), changeSet$2, (function (currentChangeSet, sourceId) {
          var fromEventProperties = AvoModel.getResolvedPropertiesForEventWithPropertyMap(fromModel.propertyBundles, fromModelPropertiesMap, fromEvent);
          var toEventProperties = AvoModel.getResolvedPropertiesForEventWithPropertyMap(toModel.propertyBundles, toModelPropertiesMap, toEvent);
          var match = diffItemsArray(fromEventProperties, toEventProperties, (function (param) {
                  return param.id;
                }), (function (fromProperty, toProperty) {
                  return compareProperties(fromProperty, toProperty, fromEvent.id, sourceId);
                }));
          var changed = match.changed;
          var eventSourceChange = {
            contents: Belt_MapString.isEmpty(changed) ? "noChange" : ({
                  NAME: "change",
                  VAL: "tier1"
                })
          };
          Belt_MapString.forEachU(match.new, (function (_propertyId, property) {
                  var absence = getSimpleSourceAbsence(property.absence, toEvent.id, sourceId);
                  if (absence === "never") {
                    return ;
                  } else {
                    if (absence === "sometimes") {
                      eventSourceChange.contents = {
                        NAME: "change",
                        VAL: "tier1"
                      };
                    } else {
                      eventSourceChange.contents = {
                        NAME: "change",
                        VAL: "tier2"
                      };
                    }
                    return ;
                  }
                }));
          if (Caml_obj.caml_equal(eventSourceChange.contents, {
                  NAME: "change",
                  VAL: "tier2"
                })) {
            return Belt_MapString.set(currentChangeSet, sourceId, "tier2");
          }
          Belt_MapString.forEachU(match.removed, (function (propertyId, _property) {
                  var oldProperty = Belt_MapString.getExn(fromModelPropertiesMap, propertyId);
                  var absence = getSimpleSourceAbsence(oldProperty.absence, fromEvent.id, sourceId);
                  if (absence === "never") {
                    return ;
                  } else {
                    eventSourceChange.contents = {
                      NAME: "change",
                      VAL: "tier2"
                    };
                    return ;
                  }
                }));
          if (Caml_obj.caml_equal(eventSourceChange.contents, {
                  NAME: "change",
                  VAL: "tier2"
                })) {
            return Belt_MapString.set(currentChangeSet, sourceId, "tier2");
          }
          Belt_MapString.forEachU(changed, (function (_key, param) {
                  var description = param[0];
                  var match = eventSourceChange.contents;
                  if (typeof match === "object") {
                    if (match.VAL === "tier2" || description !== "tier2") {
                      return ;
                    } else {
                      eventSourceChange.contents = {
                        NAME: "change",
                        VAL: "tier2"
                      };
                      return ;
                    }
                  } else {
                    eventSourceChange.contents = {
                      NAME: "change",
                      VAL: description
                    };
                    return ;
                  }
                }));
          var match$1 = eventSourceChange.contents;
          if (typeof match$1 === "object") {
            if (match$1.VAL === "tier2") {
              return Belt_MapString.set(currentChangeSet, sourceId, "tier2");
            } else {
              return Belt_MapString.set(currentChangeSet, sourceId, "tier1");
            }
          } else {
            return currentChangeSet;
          }
        }));
  if (Belt_MapString.isEmpty(changeSet$3)) {
    return "noChange";
  } else {
    return {
            NAME: "change",
            VAL: changeSet$3
          };
  }
}

function getChanges(fromModel, toModel) {
  var fromModelPropertiesMap = Belt_MapString.fromArray(Belt_Array.mapU(Belt_List.toArray(AvoModel.resolveProperties(fromModel, fromModel.properties)), (function (property) {
              return [
                      property.id,
                      property
                    ];
            })));
  var toModelPropertiesMap = Belt_MapString.fromArray(Belt_Array.mapU(Belt_List.toArray(AvoModel.resolveProperties(toModel, toModel.properties)), (function (property) {
              return [
                      property.id,
                      property
                    ];
            })));
  var usedFromSourceIds = Belt_SetString.fromArray(Belt_Array.mapU(Belt_List.toArray(fromModel.sources), (function (param) {
              return param.id;
            })));
  var usedToSourceIds = Belt_SetString.fromArray(Belt_Array.mapU(Belt_List.toArray(toModel.sources), (function (param) {
              return param.id;
            })));
  return diffItemsArray(Belt_List.toArray(fromModel.events), Belt_List.toArray(toModel.events), (function (e) {
                return e.id;
              }), (function (fromEvent, toEvent) {
                return compareEvents(fromEvent, toEvent, fromModelPropertiesMap, toModelPropertiesMap, fromModel, toModel, usedFromSourceIds, usedToSourceIds);
              }));
}

function getTierChanges(itemsDiff) {
  var changed = Belt_Array.map(Belt_MapString.toArray(itemsDiff.changed), (function (param) {
          return [
                  param[0],
                  Belt_MapString.toArray(param[1][0])
                ];
        }));
  var removed = Belt_Array.map(Belt_MapString.toArray(itemsDiff.removed), (function (param) {
          return [
                  param[0],
                  Belt_Array.map(Belt_List.toArray(param[1].includeSources), (function (param) {
                          return [
                                  param.id,
                                  "tier2"
                                ];
                        }))
                ];
        }));
  return Belt_Array.concat(changed, removed);
}

function getBreakingChangesReport(fromModel, toModel) {
  var changes = getChanges(fromModel, toModel);
  var tierChanges = getTierChanges(changes);
  var events = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.keepMapU(tierChanges, (function (param) {
                  if (Belt_Array.someU(param[1], (function (param) {
                            return param[1] === "tier2";
                          }))) {
                    return param[0];
                  }
                  
                }))));
  var breakingEventIdsBySource = Belt_Array.reduce(tierChanges, undefined, (function (currentMap, param) {
          var eventId = param[0];
          return Belt_Array.reduceU(param[1], currentMap, (function (currentMap, param) {
                        if (param[1] === "tier2") {
                          return Belt_MapString.update(currentMap, param[0], (function (maybeEvents) {
                                        if (maybeEvents !== undefined) {
                                          return Caml_option.some(Belt_SetString.add(Caml_option.valFromOption(maybeEvents), eventId));
                                        } else {
                                          return Caml_option.some(Belt_SetString.fromArray([eventId]));
                                        }
                                      }));
                        } else {
                          return currentMap;
                        }
                      }));
        }));
  return {
          breakingEvents: events,
          breakingSources: Belt_MapString.keysToArray(breakingEventIdsBySource),
          breakingEventIdsBySource: breakingEventIdsBySource
        };
}

function useBreakingChangesReport(fromModel, toModel) {
  var match = React.useState(function () {
        return getBreakingChangesReport(fromModel, toModel);
      });
  var setReport = match[1];
  Hooks.useDidUpdate2((function (param) {
          Curry._1(setReport, (function (param) {
                  return getBreakingChangesReport(fromModel, toModel);
                }));
          
        }), [
        fromModel,
        toModel
      ]);
  return match[0];
}

function getAnalyticsBreakingChangeInfo(changes) {
  return AnalyticsRe.Group.inspectorBreakingChangeInfo(changes.breakingEvents.length, changes.breakingEvents, changes.breakingSources, changes.breakingSources.length);
}

exports.diffItemsArray = diffItemsArray;
exports.hasSameName = hasSameName;
exports.propertyHasCompatibleType = propertyHasCompatibleType;
exports.getSimpleSourceAbsence = getSimpleSourceAbsence;
exports.compareProperties = compareProperties;
exports.pruneArchivedSourceIds = pruneArchivedSourceIds;
exports.compareEvents = compareEvents;
exports.getChanges = getChanges;
exports.getTierChanges = getTierChanges;
exports.getBreakingChangesReport = getBreakingChangesReport;
exports.useBreakingChangesReport = useBreakingChangesReport;
exports.getAnalyticsBreakingChangeInfo = getAnalyticsBreakingChangeInfo;
/* Hooks Not a pure module */
