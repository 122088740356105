// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Quill = require("./externals/quill.bs.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameUtils = require("../../shared/utils/NameUtils.bs.js");
var Timestamp = require("./Timestamp.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var IconBranch = require("./IconBranch.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ContextMenu = require("./ContextMenu.bs.js");
var NamedBranch = require("./NamedBranch.bs.js");
var RouterStore = require("./RouterStore.bs.js");
var ProfilePhoto = require("./ProfilePhoto.bs.js");
var PropertyUtils = require("./PropertyUtils.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var AvoConfigTypes = require("../../shared/models/AvoConfigTypes.bs.js");
var IntegrationIcon = require("./integration/IntegrationIcon.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var ActivityItemLoading = require("./ActivityItemLoading.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var BranchImplementationShareButton = require("./branchImplementation/BranchImplementationShareButton.bs.js");

function rootStyles(withHover) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("spaceBetween"),
                  tl: {
                    hd: Css.paddingTop(Css.px(16)),
                    tl: {
                      hd: Css.paddingRight(Css.px(16)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(16)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(16)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.hover(withHover ? ({
                                        hd: Css.backgroundColor(Styles.Color.grey10),
                                        tl: /* [] */0
                                      }) : /* [] */0),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$short
                                    }, undefined, undefined, "background-color"),
                                tl: {
                                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var actionContainerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: /* [] */0
    });

var iconStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.width(Css.px(24)),
              tl: {
                hd: Css.height(Css.px(24)),
                tl: {
                  hd: Css.marginTop(Css.px(3)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var picturePlaceholderStyles = Curry._1(Css.style, {
      hd: Css.width(Css.px(24)),
      tl: {
        hd: Css.height(Css.px(24)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.grey20),
          tl: {
            hd: Css.borderRadius(Css.px(17)),
            tl: /* [] */0
          }
        }
      }
    });

function ActivityItem$ActivityItemAction(Props) {
  var action = Props.action;
  var users = Props.users;
  var message = Props.message;
  var icon = Props.icon;
  var path = Props.path;
  var menuOptions = Props.menuOptions;
  var openBranches = Props.openBranches;
  var onClickOpt = Props.onClick;
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (param) {
        
      });
  var content = function (withHover) {
    var match = Belt_Array.get(users, 0);
    var maybeOpenBranch = Belt_List.getBy(openBranches, (function (param) {
            return param[0] === Belt_Option.getWithDefault(Caml_option.undefined_to_opt(action.branchId), "main");
          }));
    return React.createElement("div", {
                className: rootStyles(withHover)
              }, React.createElement("div", {
                    className: actionContainerStyles
                  }, React.createElement("div", {
                        className: iconStyles
                      }, icon !== undefined ? Caml_option.valFromOption(icon) : (
                          match !== undefined ? React.createElement(ProfilePhoto.make, {
                                  user: match,
                                  size: 24
                                }) : React.createElement("div", {
                                  className: picturePlaceholderStyles
                                })
                        )), React.createElement(Spacer.make, {
                        width: 12
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.unsafe("wordBreak", "break-word"),
                              tl: /* [] */0
                            })
                      }, React.createElement($$Text.make, {
                            element: "Div",
                            size: "Small",
                            color: Styles.Color.grey90,
                            children: null
                          }, Belt_Array.mapWithIndex(users, (function (index, user) {
                                  var match = users.length - index | 0;
                                  return React.createElement(React.Fragment, {
                                              children: null,
                                              key: user.id
                                            }, React.createElement("b", undefined, AvoConfig.getUserDisplayName(user)), match !== 1 ? (
                                                match !== 2 ? ", " : " and "
                                              ) : null);
                                })), " ", message), React.createElement(Spacer.make, {
                            height: 2
                          }), React.createElement($$Text.make, {
                            size: "Tiny",
                            color: Styles.Color.grey50,
                            children: null
                          }, React.createElement(Timestamp.make, {
                                date: action.createdAt
                              }), maybeOpenBranch !== undefined ? React.createElement(React.Fragment, undefined, " on ", React.createElement(IconBranch.make, {
                                      size: 8,
                                      color: Styles.Color.grey50
                                    }), " " + maybeOpenBranch[1]) : null))), Belt_Option.mapWithDefault(menuOptions, null, (function (options) {
                      if (options.length !== 0) {
                        return React.createElement(ContextMenu.make, {
                                    options: options
                                  });
                      } else {
                        return null;
                      }
                    })));
  };
  if (path !== undefined) {
    return React.createElement(Link.make, {
                path: path,
                onClick: onClick,
                children: content(true)
              });
  } else {
    return content(false);
  }
}

var ActivityItemAction = {
  rootStyles: rootStyles,
  actionContainerStyles: actionContainerStyles,
  iconStyles: iconStyles,
  picturePlaceholderStyles: picturePlaceholderStyles,
  make: ActivityItem$ActivityItemAction
};

function ActivityItem$ActivityItemComment(Props) {
  var schemaId = Props.schemaId;
  var threadId = Props.threadId;
  var commentId = Props.commentId;
  var link = Props.link;
  var itemTypeName = Props.itemTypeName;
  var itemName = Props.itemName;
  var action = Props.action;
  var users = Props.users;
  var events = Props.events;
  var openBranches = Props.openBranches;
  var comment = FirebaseFetcherHooks.useComment(schemaId, threadId, commentId);
  if (typeof comment !== "object") {
    return null;
  }
  var tmp = {
    action: action,
    users: users,
    message: React.createElement(React.Fragment, undefined, itemName !== undefined ? React.createElement(React.Fragment, undefined, "commented on the " + (itemTypeName + " "), React.createElement("b", undefined, itemName)) : "commented on a " + itemTypeName, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginTop(Css.px(-8)),
                    tl: {
                      hd: Css.marginBottom(Css.px(-6)),
                      tl: {
                        hd: Css.overflowWrap("breakWord"),
                        tl: {
                          hd: Css.selector(" img", {
                                hd: Css.maxWidth(Css.pct(75)),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              dangerouslySetInnerHTML: {
                __html: Quill.toHTML(JSON.parse(comment.VAL.content), events, undefined)
              }
            })),
    openBranches: openBranches
  };
  if (link !== undefined) {
    tmp.path = Caml_option.valFromOption(link);
  }
  return React.createElement(ActivityItem$ActivityItemAction, tmp);
}

var ActivityItemComment = {
  make: ActivityItem$ActivityItemComment
};

function getActionContext(item) {
  var variant = item.NAME;
  if (variant === "Destination") {
    var destinationId = item.VAL;
    return {
            destinationId: destinationId,
            destinationQuery: destinationId
          };
  }
  if (variant === "Integration") {
    var integrationId = item.VAL;
    return {
            integrationId: integrationId,
            integrationQuery: integrationId
          };
  }
  if (variant === "GroupType") {
    var groupTypeId = item.VAL;
    return {
            groupTypeId: groupTypeId,
            groupTypeQuery: groupTypeId
          };
  }
  if (variant === "Property") {
    var propertyId = item.VAL;
    return {
            propertyId: propertyId,
            propertyQuery: propertyId
          };
  }
  if (variant === "Event") {
    var eventId = item.VAL;
    return {
            eventId: eventId,
            eventQuery: eventId
          };
  }
  if (variant === "PropertyGroup") {
    var groupId = item.VAL;
    return {
            propertyGroupId: groupId,
            propertyGroupQuery: groupId
          };
  }
  if (variant === "Goal") {
    var goalId = item.VAL;
    return {
            goalId: goalId,
            goalQuery: goalId
          };
  }
  if (variant === "Metric") {
    var metricId = item.VAL;
    return {
            metricId: metricId,
            metricQuery: metricId
          };
  }
  var sourceId = item.VAL;
  return {
          sourceId: sourceId,
          sourceQuery: sourceId
        };
}

function unarchiveItemOptionBase(currentModel, sendActions, addToast, schemaGroup, globalSend, item, itemName) {
  var variant = item.NAME;
  var itemLabel = variant === "Destination" ? "Destination" : (
      variant === "Integration" ? "Integration" : (
          variant === "GroupType" ? "Group Type" : (
              variant === "Property" ? "Property" : (
                  variant === "Event" ? "Event" : (
                      variant === "PropertyGroup" ? "Property Bundle" : (
                          variant === "Goal" ? "Category" : (
                              variant === "Metric" ? "Metric" : "Source"
                            )
                        )
                    )
                )
            )
        )
    );
  var actionContext = getActionContext(item);
  var variant$1 = item.NAME;
  var drawerItem = variant$1 === "Destination" ? ({
        NAME: "destination",
        VAL: item.VAL
      }) : (
      variant$1 === "Integration" ? ({
            NAME: "integration",
            VAL: [
              item.VAL,
              undefined
            ]
          }) : (
          variant$1 === "GroupType" ? undefined : (
              variant$1 === "Property" ? ({
                    NAME: "property",
                    VAL: [
                      item.VAL,
                      undefined
                    ]
                  }) : (
                  variant$1 === "Event" ? ({
                        NAME: "event",
                        VAL: [
                          item.VAL,
                          undefined,
                          undefined,
                          false
                        ]
                      }) : (
                      variant$1 === "PropertyGroup" ? ({
                            NAME: "propertyGroup",
                            VAL: [
                              item.VAL,
                              undefined
                            ]
                          }) : (
                          variant$1 === "Goal" ? ({
                                NAME: "category",
                                VAL: [
                                  item.VAL,
                                  undefined
                                ]
                              }) : (
                              variant$1 === "Metric" ? ({
                                    NAME: "metric",
                                    VAL: [
                                      item.VAL,
                                      undefined
                                    ]
                                  }) : ({
                                    NAME: "source",
                                    VAL: [
                                      item.VAL,
                                      "overview"
                                    ]
                                  })
                            )
                        )
                    )
                )
            )
        )
    );
  var variant$2 = item.NAME;
  var match = variant$2 === "Destination" ? [
      "Destination",
      item.VAL
    ] : (
      variant$2 === "Integration" ? [
          "Integration",
          item.VAL
        ] : (
          variant$2 === "GroupType" ? [
              "GroupType",
              ""
            ] : (
              variant$2 === "Property" ? [
                  "Property",
                  item.VAL
                ] : (
                  variant$2 === "Event" ? [
                      "Event",
                      item.VAL
                    ] : (
                      variant$2 === "PropertyGroup" ? [
                          "PropertyGroup",
                          item.VAL
                        ] : (
                          variant$2 === "Goal" ? [
                              "Goal",
                              item.VAL
                            ] : (
                              variant$2 === "Metric" ? [
                                  "Metric",
                                  item.VAL
                                ] : [
                                  "Source",
                                  item.VAL
                                ]
                            )
                        )
                    )
                )
            )
        )
    );
  var analyticsItemId = match[1];
  var analyticsItemType = match[0];
  var variant$3 = item.NAME;
  var unarchiveAvailability;
  if (variant$3 === "Destination") {
    var destinationId = item.VAL;
    unarchiveAvailability = Belt_List.someU(currentModel.archive.destinations, (function (destination) {
            return destination.id === destinationId;
          }));
  } else if (variant$3 === "Integration") {
    unarchiveAvailability = false;
  } else if (variant$3 === "GroupType") {
    var groupTypeId = item.VAL;
    unarchiveAvailability = Belt_Array.someU(currentModel.archive.groupTypes, (function (groupType) {
            return groupType.id === groupTypeId;
          }));
  } else if (variant$3 === "Property") {
    var propertyId = item.VAL;
    unarchiveAvailability = Belt_List.someU(currentModel.archive.properties, (function (property) {
            if (property.TAG === /* PropertyRef */0) {
              return false;
            } else {
              return property._0.id === propertyId;
            }
          }));
  } else if (variant$3 === "Event") {
    var eventId = item.VAL;
    unarchiveAvailability = Belt_List.someU(currentModel.archive.events, (function ($$event) {
            return $$event.id === eventId;
          }));
  } else if (variant$3 === "PropertyGroup") {
    var groupId = item.VAL;
    unarchiveAvailability = Belt_List.someU(currentModel.archive.propertyBundles, (function (group) {
            return group.id === groupId;
          }));
  } else if (variant$3 === "Goal") {
    var goalId = item.VAL;
    unarchiveAvailability = Belt_List.someU(currentModel.archive.goals, (function (goal) {
            return goal.id === goalId;
          }));
  } else if (variant$3 === "Metric") {
    var metricId = item.VAL;
    unarchiveAvailability = Belt_List.someU(currentModel.archive.metrics, (function (metric) {
            return metric.id === metricId;
          }));
  } else {
    var sourceId = item.VAL;
    unarchiveAvailability = Belt_List.someU(currentModel.archive.sources, (function (source) {
            return source.id === sourceId;
          }));
  }
  var variant$4 = item.NAME;
  var unarchiveConflict = variant$4 === "GroupType" ? Belt_Option.flatMap(NameUtils.getConflictingNames(Belt_List.fromArray(StateUtils.getGroupTypeNames(currentModel)), itemName), (function (conflictingName) {
            return "Group Type with the name \"" + conflictingName + "\" already exists.";
          })) : (
      variant$4 === "Property" ? Belt_Option.flatMap(NameUtils.getConflictingNames(StateUtils.getPropertyNamespace(currentModel), itemName), (function (conflictingName) {
                return "Property with the name \"" + conflictingName + "\" already exists.";
              })) : (
          variant$4 === "Event" ? Belt_Option.flatMap(NameUtils.getConflictingNames(StateUtils.getEventNamespace(currentModel.events), itemName), (function (conflictingName) {
                    return "Event with the name \"" + conflictingName + "\" already exists.";
                  })) : (
              variant$4 === "PropertyGroup" ? Belt_Option.flatMap(NameUtils.getConflictingNames(StateUtils.getPropertyGroupNamespace(currentModel), itemName), (function (conflictingName) {
                        return "Property bundle with the name \"" + conflictingName + "\" already exists.";
                      })) : (
                  variant$4 === "Goal" ? Belt_Option.flatMap(NameUtils.getConflictingNames(StateUtils.getGoalNamespace(currentModel), itemName), (function (conflictingName) {
                            return "Category with the name \"" + conflictingName + "\" already exists.";
                          })) : undefined
                )
            )
        )
    );
  if (unarchiveAvailability) {
    return {
            NAME: "Option",
            VAL: {
              label: "Restore " + itemLabel,
              onClick: (function (param) {
                  if (unarchiveConflict !== undefined) {
                    return Curry._1(globalSend, {
                                TAG: /* OpenModal */4,
                                _0: {
                                  NAME: "AlertModal",
                                  VAL: [
                                    "Could not restore item",
                                    "The item could not be unarchived because of the following error: " + unarchiveConflict,
                                    "Ok",
                                    (function (param) {
                                        
                                      })
                                  ]
                                }
                              });
                  } else {
                    return Curry.app(sendActions, [
                                undefined,
                                false,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (branch) {
                                    Curry._1(addToast, {
                                          message: itemLabel + " Restored",
                                          toastType: /* Success */0
                                        });
                                    var arg = Router.Schema.pushDrawerItem;
                                    Belt_Option.forEach(drawerItem, (function (eta) {
                                            return Curry._2(arg, undefined, eta);
                                          }));
                                    return AnalyticsRe.itemUnarchived(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), analyticsItemId, itemName, analyticsItemType, "ActivityLog", schemaGroup.schemaId, NamedBranch.getId(branch));
                                  }),
                                (function (param) {
                                    return Curry._1(addToast, {
                                                message: "Item could not be restored because of an unexpected error",
                                                toastType: /* Error */1
                                              });
                                  }),
                                [[
                                    {
                                      NAME: "Unarchive",
                                      VAL: item
                                    },
                                    actionContext
                                  ]]
                              ]);
                  }
                })
            }
          };
  }
  
}

function ActivityItem(Props) {
  var schemaId = Props.schemaId;
  var modelBeforeAction = Props.modelBeforeAction;
  var currentModel = Props.currentModel;
  var action = Props.action;
  var userIds = Props.userIds;
  var openBranches = Props.openBranches;
  var globalSend = Props.globalSend;
  var baseRoute = RouterStore.Schema.useBaseRoute(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var users = FirebaseFetcherHooks.useUsers(userIds);
  var addToast = Toast.useAddToast(undefined);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var unarchiveItemOption = function (param, param$1) {
    return unarchiveItemOptionBase(currentModel, sendActions, addToast, schemaGroup, globalSend, param, param$1);
  };
  var itemName = "event";
  if (typeof users !== "object") {
    return React.createElement(ActivityItemLoading.make, {});
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var context = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(action.context), {});
  var linkToPropertyFromContext = Belt_Option.map(Caml_option.undefined_to_opt(context.propertyId), (function (propertyId) {
          return Router.Link.addDrawerItem(undefined, {
                      NAME: "property",
                      VAL: [
                        propertyId,
                        undefined
                      ]
                    });
        }));
  var match = action.contents;
  var eventId;
  var uniqueName;
  if (typeof match !== "object") {
    if (match === "ResetTrackingPlan") {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: "reset the Tracking Plan",
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  var variant = match.NAME;
  if (variant === "UpdateDestinationDisabledByDefault") {
    var match$1 = match.VAL;
    var destinationId = match$1[0];
    var destination = StateUtils.getDestinationByIdWithArchive(destinationId, modelBeforeAction);
    if (destination !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: match$1[1] ? React.createElement(React.Fragment, undefined, "set the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(destination)), " to be disabled by default") : React.createElement(React.Fragment, undefined, "set the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(destination)), " to be enabled by default"),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "destination",
                        VAL: destinationId
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "AddProperty") {
    var match$2 = match.VAL;
    var string = match$2[2];
    var propertyId = match$2[1];
    var $$event = StateUtils.getEventByIdWithArchive(match$2[0], modelBeforeAction);
    if ($$event !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "created the property ", React.createElement("b", undefined, string), " and added it to ", React.createElement("b", undefined, $$event.name)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId,
                          undefined
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "created the property ", React.createElement("b", undefined, string)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId,
                          undefined
                        ]
                      }),
                  openBranches: openBranches
                });
    }
  }
  if (variant === "MergeBranch") {
    var match$3 = match.VAL;
    var branchId = match$3[0];
    return React.createElement(ActivityItem$ActivityItemAction, {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "merged the branch ", React.createElement("b", undefined, match$3[1]), " into main"),
                path: Router.Link.getSchemaRouteLink({
                      NAME: "branch",
                      VAL: branchId
                    }, {
                      NAME: "diff",
                      VAL: "index"
                    }),
                openBranches: openBranches,
                onClick: (function (param) {
                    return AnalyticsRe.branchLinkClicked(schemaGroup, branchId, "BranchesOverviewActivityItem");
                  })
              });
  }
  if (variant === "AddEventType") {
    var match$4 = match.VAL;
    var eventId$1 = match$4[0];
    var $$event$1 = StateUtils.getEventByIdWithArchive(eventId$1, modelBeforeAction);
    if ($$event$1 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "added the event type ", React.createElement("b", undefined, TrackingPlanModel.eventTypeToJs(match$4[1])), " to ", React.createElement("b", undefined, $$event$1.name)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          eventId$1,
                          undefined,
                          undefined,
                          false
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdateTriggerContent") {
    var match$5 = match.VAL;
    var eventId$2 = match$5[0];
    var match$6 = StateUtils.getEventByIdWithArchive(eventId$2, modelBeforeAction);
    if (match$6 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "updated the content for a trigger in ", React.createElement("b", undefined, match$6.name)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          eventId$2,
                          undefined,
                          match$5[1],
                          false
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "RemovePropertyValidationMinOrMax") {
    var match$7 = StateUtils.getPropertyByIdWithArchive(match.VAL[0], modelBeforeAction);
    if (match$7 === undefined) {
      return null;
    }
    if (match$7.TAG === /* PropertyRef */0) {
      return null;
    }
    var tmp = {
      action: action,
      users: users$1,
      message: React.createElement(React.Fragment, undefined, "removed min or max from the property ", React.createElement("b", undefined, match$7._0.name)),
      openBranches: openBranches
    };
    if (linkToPropertyFromContext !== undefined) {
      tmp.path = Caml_option.valFromOption(linkToPropertyFromContext);
    }
    return React.createElement(ActivityItem$ActivityItemAction, tmp);
  }
  if (variant === "UpdateSourceDestinationMode") {
    var match$8 = match.VAL;
    var sourceId = match$8[0];
    var match$9 = StateUtils.getSourceByIdWithArchive(sourceId, modelBeforeAction);
    var match$10 = StateUtils.getDestinationByIdWithArchive(match$8[1], modelBeforeAction);
    if (match$9 === undefined) {
      return null;
    }
    if (match$10 === undefined) {
      return null;
    }
    var tmp$1;
    switch (match$8[2]) {
      case /* AvoManaged */0 :
          tmp$1 = "Avo managed";
          break;
      case /* DestinationInterface */1 :
          tmp$1 = "Destination Interface";
          break;
      case /* Legacy */2 :
          tmp$1 = "Legacy";
          break;
      
    }
    return React.createElement(ActivityItem$ActivityItemAction, {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "changed the mode of the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(match$10)), " on the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$9)), " to ", React.createElement("b", undefined, tmp$1)),
                path: Router.Link.addDrawerItem(undefined, {
                      NAME: "source",
                      VAL: [
                        sourceId,
                        "codegenSetup"
                      ]
                    }),
                openBranches: openBranches
              });
  }
  if (variant === "TogglePropertyValidationMatchSource") {
    var match$11 = match.VAL;
    var propertyId$1 = match$11[0];
    var match$12 = StateUtils.getPropertyById(propertyId$1, modelBeforeAction);
    var match$13 = StateUtils.getSourceById(match$11[2], modelBeforeAction);
    if (match$12 !== undefined && !(match$12.TAG === /* PropertyRef */0 || match$13 === undefined)) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, match$11[3] ? "enabled " : "disabled ", React.createElement("b", undefined, match$11[1]), " to be sent as the value of the property ", React.createElement("b", undefined, match$12._0.name), " from ", React.createElement("b", undefined, AvoConfig.getSourceName(match$13))),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId$1,
                          undefined
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdatePropertyUniqueName") {
    var match$14 = match.VAL;
    var match$15 = StateUtils.getPropertyByIdWithArchive(match$14[0], modelBeforeAction);
    if (match$15 === undefined) {
      return null;
    }
    if (match$15.TAG === /* PropertyRef */0) {
      return null;
    }
    var tmp$2 = {
      action: action,
      users: users$1,
      message: React.createElement(React.Fragment, undefined, "set the unique name of the property ", React.createElement("b", undefined, match$15._0.name), " to ", React.createElement("b", undefined, match$14[1])),
      openBranches: openBranches
    };
    if (linkToPropertyFromContext !== undefined) {
      tmp$2.path = Caml_option.valFromOption(linkToPropertyFromContext);
    }
    return React.createElement(ActivityItem$ActivityItemAction, tmp$2);
  }
  if (variant === "UpdateDestinationIncludeUserPropsWithEventProps") {
    var match$16 = match.VAL;
    var destinationId$1 = match$16[0];
    var destination$1 = StateUtils.getDestinationByIdWithArchive(destinationId$1, modelBeforeAction);
    if (destination$1 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: match$16[1] ? React.createElement(React.Fragment, undefined, "started including user props with event props on the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(destination$1))) : React.createElement(React.Fragment, undefined, "stopped including user props with event props on the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(destination$1))),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "destination",
                        VAL: destinationId$1
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "AddEvent") {
    var match$17 = match.VAL;
    return React.createElement(ActivityItem$ActivityItemAction, {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "created an event: ", React.createElement("b", undefined, match$17[1])),
                path: Router.Link.addDrawerItem(undefined, {
                      NAME: "event",
                      VAL: [
                        match$17[0],
                        undefined,
                        undefined,
                        false
                      ]
                    }),
                openBranches: openBranches
              });
  }
  if (variant === "RemovePropertyPermanently") {
    var match$18 = StateUtils.getPropertyByIdWithArchive(match.VAL, modelBeforeAction);
    if (match$18 === undefined) {
      return null;
    }
    if (match$18.TAG === /* PropertyRef */0) {
      return null;
    }
    var tmp$3 = {
      action: action,
      users: users$1,
      message: React.createElement(React.Fragment, undefined, "permanently removed the property ", React.createElement("b", undefined, match$18._0.name)),
      openBranches: openBranches
    };
    if (linkToPropertyFromContext !== undefined) {
      tmp$3.path = Caml_option.valFromOption(linkToPropertyFromContext);
    }
    return React.createElement(ActivityItem$ActivityItemAction, tmp$3);
  }
  if (variant === "RemoveMember" || variant === "UpdateIntegrationDestinationIds" || variant === "CollaboratorRemoved" || variant === "BranchInit" || variant === "ArchiveSavedView" || variant === "CodeGenerated" || variant === "ResendInvite" || variant === "SubscriptionStarted" || variant === "SubscriptionCancelled" || variant === "SubscriptionUpdated" || variant === "UpdateMemberRole" || variant === "UpdateSavedView" || variant === "MigrateExcludeEventsToIncludeEvents" || variant === "CollaboratorAdded" || variant === "RemoveInvite" || variant === "InviteMember" || variant === "MigrateSourcesAndDestinationsToModel" || variant === "CreateDemoBranch" || variant === "ImportDeprecated" || variant === "PaymentCompleted" || variant === "CreateSavedView") {
    return null;
  }
  if (variant === "RemovePropertyFromWhitelist") {
    var match$19 = match.VAL;
    var match$20 = StateUtils.getPropertyByIdWithArchive(match$19[1], modelBeforeAction);
    var match$21 = StateUtils.getEventByIdWithArchive(match$19[0], modelBeforeAction);
    if (match$20 !== undefined && !(match$20.TAG === /* PropertyRef */0 || match$21 === undefined)) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "stopped sending the property ", React.createElement("b", undefined, match$20._0.name), " with the event ", React.createElement("b", undefined, match$21.name), " to ", React.createElement("b", undefined, AvoConfigTypes.analyticsToolToJs(match$19[2]))),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "SetBranchStatus") {
    var match$22 = match.VAL;
    var branchStatus = match$22[2];
    var branchName = match$22[1];
    var branchId$1 = match$22[0];
    return React.createElement(ActivityItem$ActivityItemAction, {
                action: action,
                users: users$1,
                message: branchStatus === "ChangesRequested" ? React.createElement(React.Fragment, undefined, "requested changes to the branch ", React.createElement("b", undefined, branchName)) : (
                    branchStatus === "Approved" ? React.createElement(React.Fragment, undefined, "approved the branch ", React.createElement("b", undefined, branchName)) : (
                        branchStatus === "Draft" ? React.createElement(React.Fragment, undefined, "marked the branch ", React.createElement("b", undefined, branchName), " as a draft") : React.createElement(React.Fragment, undefined, "marked the branch ", React.createElement("b", undefined, branchName), " as ready for review")
                      )
                  ),
                path: Router.Link.getSchemaRouteLink({
                      NAME: "branch",
                      VAL: branchId$1
                    }, {
                      NAME: "diff",
                      VAL: "index"
                    }),
                openBranches: openBranches,
                onClick: (function (param) {
                    return AnalyticsRe.branchLinkClicked(schemaGroup, branchId$1, "BranchesOverviewActivityItem");
                  })
              });
  }
  if (variant === "UpdateLibraryDestination") {
    var match$23 = match.VAL;
    var sourceId$1 = match$23[0];
    var source = StateUtils.getSourceByIdWithArchive(sourceId$1, modelBeforeAction);
    if (source !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "updated the library destination of the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source)), " to ", React.createElement("b", undefined, match$23[1])),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "source",
                        VAL: [
                          sourceId$1,
                          "overview"
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "AddGoal") {
    return React.createElement(ActivityItem$ActivityItemAction, {
                action: action,
                users: users$1,
                message: "created a category",
                path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                openBranches: openBranches
              });
  }
  if (variant === "UpdateIntegrationAutoPublish") {
    var match$24 = match.VAL;
    var integration = StateUtils.getIntegrationById(match$24[0], modelBeforeAction);
    if (integration !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, (
                        match$24[1] ? "enabled" : "disabled"
                      ) + " auto publishing for the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration))),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "integration",
                        VAL: [
                          integration.id,
                          undefined
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdateGoalDescription") {
    var goal = StateUtils.getGoalByIdWithArchive(match.VAL[0], modelBeforeAction);
    if (goal !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "updated the description of the category ", React.createElement("b", undefined, goal.name)),
                  path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdatePropertyList") {
    var match$25 = match.VAL;
    var match$26 = StateUtils.getPropertyByIdWithArchive(match$25[0], modelBeforeAction);
    if (match$26 === undefined) {
      return null;
    }
    if (match$26.TAG === /* PropertyRef */0) {
      return null;
    }
    var tmp$4 = {
      action: action,
      users: users$1,
      message: React.createElement(React.Fragment, undefined, "changed the property ", React.createElement("b", undefined, match$26._0.name), match$25[1] ? " to be a list" : " to be a single item"),
      openBranches: openBranches
    };
    if (linkToPropertyFromContext !== undefined) {
      tmp$4.path = Caml_option.valFromOption(linkToPropertyFromContext);
    }
    return React.createElement(ActivityItem$ActivityItemAction, tmp$4);
  }
  if (variant === "RemoveMetricWhereV2" || variant === "RemoveMetricWhere" || variant === "SetMetricWhere" || variant === "SetMetricGroupByV2" || variant === "RemoveMetricGroupByV2" || variant === "SetMetricGroupBy" || variant === "RemoveMetricGroupBy" || variant === "SetMetricWhereV2") {
    var metricId = match.VAL[0];
    var metric = StateUtils.getMetricByIdWithArchive(metricId, modelBeforeAction);
    if (metric !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "updated the metric ", React.createElement("b", undefined, metric.name)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "metric",
                        VAL: [
                          metricId,
                          undefined
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdatePropertyName") {
    var match$27 = match.VAL;
    var match$28 = StateUtils.getPropertyByIdWithArchive(match$27[0], modelBeforeAction);
    if (match$28 === undefined) {
      return null;
    }
    if (match$28.TAG === /* PropertyRef */0) {
      return null;
    }
    var tmp$5 = {
      action: action,
      users: users$1,
      message: React.createElement(React.Fragment, undefined, "renamed the property ", React.createElement("b", undefined, match$28._0.name), " to ", React.createElement("b", undefined, match$27[1])),
      openBranches: openBranches
    };
    if (linkToPropertyFromContext !== undefined) {
      tmp$5.path = Caml_option.valFromOption(linkToPropertyFromContext);
    }
    return React.createElement(ActivityItem$ActivityItemAction, tmp$5);
  }
  if (variant === "UpdatePropertyDescription") {
    var match$29 = match.VAL;
    var match$30 = StateUtils.getPropertyByIdWithArchive(match$29[0], modelBeforeAction);
    if (match$30 === undefined) {
      return null;
    }
    if (match$30.TAG === /* PropertyRef */0) {
      return null;
    }
    var tmp$6 = {
      action: action,
      users: users$1,
      message: React.createElement(React.Fragment, undefined, "updated description of the property ", React.createElement("b", undefined, match$30._0.name), " to " + match$29[1]),
      openBranches: openBranches
    };
    if (linkToPropertyFromContext !== undefined) {
      tmp$6.path = Caml_option.valFromOption(linkToPropertyFromContext);
    }
    return React.createElement(ActivityItem$ActivityItemAction, tmp$6);
  }
  if (variant === "AddTag") {
    var match$31 = match.VAL;
    var eventId$3 = match$31[0];
    var $$event$2 = StateUtils.getEventByIdWithArchive(eventId$3, modelBeforeAction);
    if ($$event$2 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "added the tag ", React.createElement("b", undefined, match$31[1]), " to ", React.createElement("b", undefined, $$event$2.name)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          eventId$3,
                          undefined,
                          undefined,
                          false
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "ReorderMetricsInGoal") {
    var goalId = match.VAL[0];
    var goal$1 = StateUtils.getGoalByIdWithArchive(goalId, modelBeforeAction);
    if (goal$1 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "reordered the metrics in the category ", React.createElement("b", undefined, goal$1.name)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "category",
                        VAL: [
                          goalId,
                          undefined
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdatePropertySendAs") {
    var match$32 = match.VAL;
    var sendAs = match$32[1];
    var match$33 = StateUtils.getPropertyByIdWithArchive(match$32[0], modelBeforeAction);
    if (match$33 === undefined) {
      return null;
    }
    if (match$33.TAG === /* PropertyRef */0) {
      return null;
    }
    var tmp$7;
    if (typeof sendAs === "number") {
      switch (sendAs) {
        case /* SystemProperty */0 :
            tmp$7 = "system property";
            break;
        case /* EventProperty */1 :
            tmp$7 = "event property";
            break;
        case /* UserProperty */2 :
            tmp$7 = "user property";
            break;
        
      }
    } else {
      var groupTypeName = StateUtils.getGroupTypeName(sendAs._0, modelBeforeAction);
      tmp$7 = groupTypeName !== undefined ? groupTypeName + " group property" : "group property";
    }
    var tmp$8 = {
      action: action,
      users: users$1,
      message: React.createElement(React.Fragment, undefined, "changed the property ", React.createElement("b", undefined, match$33._0.name), " to " + tmp$7),
      openBranches: openBranches
    };
    if (linkToPropertyFromContext !== undefined) {
      tmp$8.path = Caml_option.valFromOption(linkToPropertyFromContext);
    }
    return React.createElement(ActivityItem$ActivityItemAction, tmp$8);
  }
  if (variant === "AddRule") {
    var match$34 = match.VAL;
    var match$35 = match$34[1];
    if (typeof match$35 !== "object") {
      return null;
    }
    if (match$35.NAME !== "Property") {
      return null;
    }
    var match$36 = match$34[2];
    if (typeof match$36 !== "object") {
      return null;
    }
    if (match$36.NAME !== "NameMapping") {
      return null;
    }
    var match$37 = StateUtils.getPropertyByIdWithArchive(match$35.VAL, modelBeforeAction);
    if (match$37 !== undefined && match$37.TAG !== /* PropertyRef */0) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "added a name mapping to the property ", React.createElement("b", undefined, match$37._0.name)),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdateSourceName") {
    var match$38 = match.VAL;
    var sourceId$2 = match$38[0];
    var source$1 = StateUtils.getSourceByIdWithArchive(sourceId$2, modelBeforeAction);
    if (source$1 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "renamed the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$1)), " to ", React.createElement("b", undefined, match$38[1])),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "source",
                        VAL: [
                          sourceId$2,
                          "overview"
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "BranchComment") {
    var match$39 = match.VAL;
    var commentId = match$39[1];
    var branchId$2 = match$39[0];
    var tmp$9 = {
      schemaId: schemaId,
      threadId: branchId$2,
      commentId: commentId,
      link: Router.Link.getSchemaRouteLink({
            NAME: "branch",
            VAL: branchId$2
          }, {
            NAME: "diff",
            VAL: {
              NAME: "comment",
              VAL: commentId
            }
          }),
      itemTypeName: "branch",
      action: action,
      users: users$1,
      events: modelBeforeAction.events,
      openBranches: openBranches
    };
    var tmp$10 = Belt_List.head(Belt_List.keepMap(openBranches, (function (param) {
                if (param[0] === branchId$2) {
                  return param[1];
                }
                
              })));
    if (tmp$10 !== undefined) {
      tmp$9.itemName = Caml_option.valFromOption(tmp$10);
    }
    return React.createElement(ActivityItem$ActivityItemComment, tmp$9);
  }
  if (variant === "UpdatePropertyType") {
    var match$40 = match.VAL;
    var match$41 = StateUtils.getPropertyByIdWithArchive(match$40[0], modelBeforeAction);
    if (match$41 === undefined) {
      return null;
    }
    if (match$41.TAG === /* PropertyRef */0) {
      return null;
    }
    var tmp$11 = {
      action: action,
      users: users$1,
      message: React.createElement(React.Fragment, undefined, "updated the type of the property ", React.createElement("b", undefined, match$41._0.name), " to ", match$40[1]),
      openBranches: openBranches
    };
    if (linkToPropertyFromContext !== undefined) {
      tmp$11.path = Caml_option.valFromOption(linkToPropertyFromContext);
    }
    return React.createElement(ActivityItem$ActivityItemAction, tmp$11);
  }
  if (variant === "IncludeDestinationInSourceV2" || variant === "LegacyIncludeDestinationInSource") {
    var match$42 = match.VAL;
    var sourceId$3 = match$42[0];
    var match$43 = StateUtils.getSourceByIdWithArchive(sourceId$3, modelBeforeAction);
    var match$44 = StateUtils.getDestinationByIdWithArchive(match$42[1], modelBeforeAction);
    if (match$43 !== undefined && match$44 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "added the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(match$44)), " to the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$43))),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "source",
                        VAL: [
                          sourceId$3,
                          "overview"
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "AddPropertyRef") {
    var match$45 = match.VAL;
    var propertyId$2 = match$45[1];
    var match$46 = StateUtils.getEventByIdWithArchive(match$45[0], modelBeforeAction);
    var match$47 = StateUtils.getPropertyByIdWithArchive(propertyId$2, modelBeforeAction);
    if (match$46 !== undefined && match$47 !== undefined && match$47.TAG !== /* PropertyRef */0) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "added the property ", React.createElement("b", undefined, match$47._0.name), " to ", React.createElement("b", undefined, match$46.name)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId$2,
                          undefined
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "OpenBranch") {
    var match$48 = match.VAL;
    var branchId$3 = match$48[0];
    return React.createElement(ActivityItem$ActivityItemAction, {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "opened the branch ", React.createElement("b", undefined, match$48[1])),
                path: Curry._4(Router.Link.get, baseRoute, {
                      NAME: "branch",
                      VAL: branchId$3
                    }, schemaRoute, /* [] */0),
                openBranches: openBranches,
                onClick: (function (param) {
                    return AnalyticsRe.branchLinkClicked(schemaGroup, branchId$3, "BranchesOverviewActivityItem");
                  })
              });
  }
  if (variant === "RemoveEvent") {
    var $$event$3 = StateUtils.getEventByIdWithArchive(match.VAL, modelBeforeAction);
    if ($$event$3 !== undefined) {
      if ($$event$3.name === "") {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: "removed an untitled event",
                    openBranches: openBranches
                  });
      } else {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "removed the event", React.createElement("b", undefined, $$event$3.name)),
                    openBranches: openBranches
                  });
      }
    } else {
      return null;
    }
  }
  if (variant === "AddPropertyValidationMinOrMax") {
    var match$49 = match.VAL;
    var match$50 = StateUtils.getPropertyByIdWithArchive(match$49[0], modelBeforeAction);
    if (match$50 === undefined) {
      return null;
    }
    if (match$50.TAG === /* PropertyRef */0) {
      return null;
    }
    var tmp$12 = {
      action: action,
      users: users$1,
      message: React.createElement(React.Fragment, undefined, "added " + TrackingPlanModel.constraint_ToJs(match$49[1]).toLowerCase() + " constraint to the property ", React.createElement("b", undefined, match$50._0.name)),
      openBranches: openBranches
    };
    if (linkToPropertyFromContext !== undefined) {
      tmp$12.path = Caml_option.valFromOption(linkToPropertyFromContext);
    }
    return React.createElement(ActivityItem$ActivityItemAction, tmp$12);
  }
  if (variant === "ReorderEventsInGoal") {
    var goalId$1 = match.VAL[0];
    var goal$2 = StateUtils.getGoalByIdWithArchive(goalId$1, modelBeforeAction);
    if (goal$2 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "reordered the events in the category ", React.createElement("b", undefined, goal$2.name)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "category",
                        VAL: [
                          goalId$1,
                          undefined
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "EventComment") {
    var match$51 = match.VAL;
    var commentId$1 = match$51[1];
    var eventId$4 = match$51[0];
    var tmp$13 = {
      schemaId: schemaId,
      threadId: eventId$4,
      commentId: commentId$1,
      link: Router.Link.addDrawerItem(undefined, {
            NAME: "event",
            VAL: [
              eventId$4,
              commentId$1,
              undefined,
              false
            ]
          }),
      itemTypeName: "event",
      action: action,
      users: users$1,
      events: modelBeforeAction.events,
      openBranches: openBranches
    };
    var tmp$14 = Belt_Option.map(StateUtils.getEventByIdWithArchive(eventId$4, modelBeforeAction), (function ($$event) {
            return $$event.name;
          }));
    if (tmp$14 !== undefined) {
      tmp$13.itemName = Caml_option.valFromOption(tmp$14);
    }
    return React.createElement(ActivityItem$ActivityItemComment, tmp$13);
  }
  if (variant === "PropertyGroupComment") {
    var match$52 = match.VAL;
    var commentId$2 = match$52[1];
    var groupId = match$52[0];
    var tmp$15 = {
      schemaId: schemaId,
      threadId: groupId,
      commentId: commentId$2,
      link: Router.Link.addDrawerItem(undefined, {
            NAME: "propertyGroup",
            VAL: [
              groupId,
              commentId$2
            ]
          }),
      itemTypeName: "property bundle",
      action: action,
      users: users$1,
      events: modelBeforeAction.events,
      openBranches: openBranches
    };
    var tmp$16 = Belt_Option.map(StateUtils.getPropertyGroupByIdWithArchive(groupId, modelBeforeAction), (function (group) {
            return group.name;
          }));
    if (tmp$16 !== undefined) {
      tmp$15.itemName = Caml_option.valFromOption(tmp$16);
    }
    return React.createElement(ActivityItem$ActivityItemComment, tmp$15);
  }
  if (variant === "ExcludeEventFromSource" || variant === "ExcludeEventFromSourceV2") {
    var match$53 = match.VAL;
    var eventId$5 = match$53[0];
    var match$54 = StateUtils.getEventByIdWithArchive(eventId$5, modelBeforeAction);
    var match$55 = StateUtils.getSourceByIdWithArchive(match$53[1], modelBeforeAction);
    if (match$54 !== undefined) {
      if (match$55 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "removed the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$55)), " from the event", " ", React.createElement("b", undefined, match$54.name)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "event",
                          VAL: [
                            eventId$5,
                            undefined,
                            undefined,
                            false
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "updated the sources ", React.createElement("b", undefined, match$54.name), " is sent from"),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "event",
                          VAL: [
                            eventId$5,
                            undefined,
                            undefined,
                            false
                          ]
                        }),
                    openBranches: openBranches
                  });
      }
    } else {
      return null;
    }
  }
  if (variant === "CreateGroupType") {
    return React.createElement(ActivityItem$ActivityItemAction, {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "created group type ", React.createElement("b", undefined, match.VAL[1])),
                openBranches: openBranches
              });
  }
  if (variant === "LegacyToggleDestination") {
    var match$56 = match.VAL;
    var sourceId$4 = match$56[0];
    var match$57 = StateUtils.getSourceByIdWithArchive(sourceId$4, modelBeforeAction);
    var match$58 = StateUtils.getDestinationByIdWithArchive(match$56[1], modelBeforeAction);
    if (match$57 !== undefined && match$58 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: match$56[2] ? React.createElement(React.Fragment, undefined, "added the the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(match$58)), " to the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$57))) : React.createElement(React.Fragment, undefined, "removed the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(match$58)), " from the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$57))),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "source",
                        VAL: [
                          sourceId$4,
                          "overview"
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "DeletePublicBranchImplementation") {
    return React.createElement(ActivityItem$ActivityItemAction, {
                action: action,
                users: users$1,
                message: "deleted a public branch implementation instructions link ",
                openBranches: openBranches
              });
  }
  if (variant === "DeleteSource") {
    var sourceId$5 = match.VAL;
    var source$2 = StateUtils.getSourceByIdWithArchive(sourceId$5, modelBeforeAction);
    if (source$2 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "deleted the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$2))),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "source",
                        VAL: [
                          sourceId$5,
                          "overview"
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "RemovePropertyRefV2" || variant === "LegacyRemovePropertyRef") {
    var match$59 = match.VAL;
    var eventId$6 = match$59[0];
    var match$60 = StateUtils.getEventByIdWithArchive(eventId$6, modelBeforeAction);
    var match$61 = StateUtils.getPropertyByIdWithArchive(match$59[1], modelBeforeAction);
    if (match$60 !== undefined && match$61 !== undefined && match$61.TAG !== /* PropertyRef */0) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "removed the property ", React.createElement("b", undefined, match$61._0.name), " from ", React.createElement("b", undefined, match$60.name)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          eventId$6,
                          undefined,
                          undefined,
                          false
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdateProgrammingLanguage") {
    var match$62 = match.VAL;
    var sourceId$6 = match$62[0];
    var source$3 = StateUtils.getSourceByIdWithArchive(sourceId$6, modelBeforeAction);
    if (source$3 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "set the programming language of the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$3)), " to ", React.createElement("b", undefined, AvoConfig.languageLabel(match$62[1]))),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "source",
                        VAL: [
                          sourceId$6,
                          "overview"
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "AddMetric") {
    var metric$1 = StateUtils.getMetricByIdWithArchive(match.VAL, modelBeforeAction);
    if (metric$1 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "created a metric ", React.createElement("b", undefined, metric$1.name)),
                  path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdateTriggerSources") {
    var match$63 = match.VAL;
    var eventId$7 = match$63[0];
    var match$64 = StateUtils.getEventByIdWithArchive(eventId$7, modelBeforeAction);
    if (match$64 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "updated sources for a trigger in ", React.createElement("b", undefined, match$64.name)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          eventId$7,
                          undefined,
                          match$63[1],
                          false
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "MetricComment") {
    var match$65 = match.VAL;
    var commentId$3 = match$65[1];
    var metricId$1 = match$65[0];
    var tmp$17 = {
      schemaId: schemaId,
      threadId: metricId$1,
      commentId: commentId$3,
      link: Router.Link.addDrawerItem(undefined, {
            NAME: "metric",
            VAL: [
              metricId$1,
              commentId$3
            ]
          }),
      itemTypeName: "metric",
      action: action,
      users: users$1,
      events: modelBeforeAction.events,
      openBranches: openBranches
    };
    var tmp$18 = Belt_Option.map(StateUtils.getMetricByIdWithArchive(metricId$1, modelBeforeAction), (function (metric) {
            return metric.name;
          }));
    if (tmp$18 !== undefined) {
      tmp$17.itemName = Caml_option.valFromOption(tmp$18);
    }
    return React.createElement(ActivityItem$ActivityItemComment, tmp$17);
  }
  if (variant === "UpdateSourcePlatform" || variant === "UpdateSourcePlatformV2") {
    var match$66 = match.VAL;
    var sourceId$7 = match$66[0];
    var source$4 = StateUtils.getSourceByIdWithArchive(sourceId$7, modelBeforeAction);
    if (source$4 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "set the development platform of the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$4)), " to ", React.createElement("b", undefined, AvoConfig.devPlatformLabel(match$66[1]))),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "source",
                        VAL: [
                          sourceId$7,
                          "overview"
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdateSourcePlatformV3") {
    var match$67 = match.VAL;
    var maybeDevPlatform = match$67[1];
    var sourceId$8 = match$67[0];
    var match$68 = StateUtils.getSourceByIdWithArchive(sourceId$8, modelBeforeAction);
    if (match$68 !== undefined && maybeDevPlatform !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "set the development platform of the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$68)), " to ", React.createElement("b", undefined, AvoConfig.devPlatformLabel(maybeDevPlatform))),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "source",
                        VAL: [
                          sourceId$8,
                          "overview"
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdateEventUniqueNameV2") {
    var match$69 = match.VAL;
    var uniqueName$1 = match$69[1];
    var eventId$8 = match$69[0];
    if (uniqueName$1 === "") {
      var $$event$4 = StateUtils.getEventByIdWithArchive(eventId$8, modelBeforeAction);
      if ($$event$4 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "removed the unique name of the event", " ", React.createElement("b", undefined, $$event$4.name)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "event",
                          VAL: [
                            eventId$8,
                            undefined,
                            undefined,
                            false
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    eventId = eventId$8;
    uniqueName = uniqueName$1;
  } else {
    if (variant === "CreateEventTrigger") {
      var match$70 = match.VAL;
      var eventId$9 = match$70[0];
      var match$71 = StateUtils.getEventByIdWithArchive(eventId$9, modelBeforeAction);
      if (match$71 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "created a trigger in ", React.createElement("b", undefined, match$71.name)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "event",
                          VAL: [
                            eventId$9,
                            undefined,
                            match$70[1],
                            false
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "UpdateGroupTypeName") {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "updated group type ", React.createElement("b", undefined, match.VAL[1])),
                  openBranches: openBranches
                });
    }
    if (variant === "RemovePropertyValidationMatch") {
      var match$72 = match.VAL;
      var match$73 = StateUtils.getPropertyByIdWithArchive(match$72[0], modelBeforeAction);
      if (match$73 === undefined) {
        return null;
      }
      if (match$73.TAG === /* PropertyRef */0) {
        return null;
      }
      var tmp$19 = {
        action: action,
        users: users$1,
        message: React.createElement(React.Fragment, undefined, "removed the match ", React.createElement("b", undefined, match$72[1]), " from the property ", React.createElement("b", undefined, match$73._0.name)),
        openBranches: openBranches
      };
      if (linkToPropertyFromContext !== undefined) {
        tmp$19.path = Caml_option.valFromOption(linkToPropertyFromContext);
      }
      return React.createElement(ActivityItem$ActivityItemAction, tmp$19);
    }
    if (variant === "RemoveDestinationApiKey") {
      var match$74 = match.VAL;
      var destinationId$2 = match$74[0];
      var destination$2 = StateUtils.getDestinationByIdWithArchive(destinationId$2, modelBeforeAction);
      if (destination$2 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "removed the " + match$74[1] + " api key from the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(destination$2))),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "destination",
                          VAL: destinationId$2
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "DeleteServiceAccount") {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "deleted the service account ", React.createElement("b", undefined, match.VAL)),
                  openBranches: openBranches,
                  onClick: (function (param) {
                      return Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "Settings",
                                    VAL: /* ServiceAccounts */4
                                  }
                                });
                    })
                });
    }
    if (variant === "Archive") {
      var item = match.VAL;
      var variant$1 = item.NAME;
      if (variant$1 === "Destination") {
        var destination$3 = StateUtils.getDestinationByIdWithArchive(item.VAL, modelBeforeAction);
        if (destination$3 === undefined) {
          return null;
        }
        var tmp$20 = {
          action: action,
          users: users$1,
          message: React.createElement(React.Fragment, undefined, "archived the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(destination$3))),
          openBranches: openBranches
        };
        var tmp$21 = Belt_Option.map(unarchiveItemOption(item, StateUtils.getDestinationName(destination$3)), (function (option) {
                return [option];
              }));
        if (tmp$21 !== undefined) {
          tmp$20.menuOptions = Caml_option.valFromOption(tmp$21);
        }
        return React.createElement(ActivityItem$ActivityItemAction, tmp$20);
      }
      if (variant$1 === "Integration") {
        return null;
      }
      if (variant$1 === "GroupType") {
        var groupTypeName$1 = StateUtils.getGroupTypeNameWithArchive(item.VAL, modelBeforeAction);
        if (groupTypeName$1 === undefined) {
          return null;
        }
        var tmp$22 = {
          action: action,
          users: users$1,
          message: React.createElement(React.Fragment, undefined, "archived the group type ", React.createElement("b", undefined, groupTypeName$1)),
          openBranches: openBranches
        };
        var tmp$23 = Belt_Option.map(unarchiveItemOption(item, groupTypeName$1), (function (option) {
                return [option];
              }));
        if (tmp$23 !== undefined) {
          tmp$22.menuOptions = Caml_option.valFromOption(tmp$23);
        }
        return React.createElement(ActivityItem$ActivityItemAction, tmp$22);
      }
      if (variant$1 === "Property") {
        var match$75 = StateUtils.getPropertyByIdWithArchive(item.VAL, modelBeforeAction);
        if (match$75 === undefined) {
          return null;
        }
        if (match$75.TAG === /* PropertyRef */0) {
          return null;
        }
        var property = match$75._0;
        var tmp$24 = {
          action: action,
          users: users$1,
          message: React.createElement(React.Fragment, undefined, "archived the property ", React.createElement("b", undefined, property.name)),
          openBranches: openBranches
        };
        var tmp$25 = Belt_Option.map(unarchiveItemOption(item, property.name), (function (option) {
                return [option];
              }));
        if (tmp$25 !== undefined) {
          tmp$24.menuOptions = Caml_option.valFromOption(tmp$25);
        }
        return React.createElement(ActivityItem$ActivityItemAction, tmp$24);
      }
      if (variant$1 === "Event") {
        var $$event$5 = StateUtils.getEventByIdWithArchive(item.VAL, modelBeforeAction);
        if ($$event$5 === undefined) {
          return null;
        }
        var tmp$26 = {
          action: action,
          users: users$1,
          message: React.createElement(React.Fragment, undefined, "archived the event ", React.createElement("b", undefined, $$event$5.name)),
          openBranches: openBranches
        };
        var tmp$27 = Belt_Option.map(unarchiveItemOption(item, itemName), (function (option) {
                return [option];
              }));
        if (tmp$27 !== undefined) {
          tmp$26.menuOptions = Caml_option.valFromOption(tmp$27);
        }
        return React.createElement(ActivityItem$ActivityItemAction, tmp$26);
      }
      if (variant$1 === "PropertyGroup") {
        var group = StateUtils.getPropertyGroupByIdWithArchive(item.VAL, modelBeforeAction);
        if (group === undefined) {
          return null;
        }
        var tmp$28 = {
          action: action,
          users: users$1,
          message: React.createElement(React.Fragment, undefined, "archived the property bundle ", React.createElement("b", undefined, group.name)),
          openBranches: openBranches
        };
        var tmp$29 = Belt_Option.map(unarchiveItemOption(item, group.name), (function (option) {
                return [option];
              }));
        if (tmp$29 !== undefined) {
          tmp$28.menuOptions = Caml_option.valFromOption(tmp$29);
        }
        return React.createElement(ActivityItem$ActivityItemAction, tmp$28);
      }
      if (variant$1 === "Goal") {
        var goal$3 = StateUtils.getGoalByIdWithArchive(item.VAL, modelBeforeAction);
        if (goal$3 === undefined) {
          return null;
        }
        var tmp$30 = {
          action: action,
          users: users$1,
          message: React.createElement(React.Fragment, undefined, "archived the category ", React.createElement("b", undefined, goal$3.name)),
          openBranches: openBranches
        };
        var tmp$31 = Belt_Option.map(unarchiveItemOption(item, goal$3.name), (function (option) {
                return [option];
              }));
        if (tmp$31 !== undefined) {
          tmp$30.menuOptions = Caml_option.valFromOption(tmp$31);
        }
        return React.createElement(ActivityItem$ActivityItemAction, tmp$30);
      }
      if (variant$1 === "Metric") {
        var metric$2 = StateUtils.getMetricByIdWithArchive(item.VAL, modelBeforeAction);
        if (metric$2 === undefined) {
          return null;
        }
        var tmp$32 = {
          action: action,
          users: users$1,
          message: React.createElement(React.Fragment, undefined, "archived the metric ", React.createElement("b", undefined, metric$2.name)),
          openBranches: openBranches
        };
        var tmp$33 = Belt_Option.map(unarchiveItemOption(item, metric$2.name), (function (option) {
                return [option];
              }));
        if (tmp$33 !== undefined) {
          tmp$32.menuOptions = Caml_option.valFromOption(tmp$33);
        }
        return React.createElement(ActivityItem$ActivityItemAction, tmp$32);
      }
      var source$5 = StateUtils.getSourceByIdWithArchive(item.VAL, modelBeforeAction);
      if (source$5 === undefined) {
        return null;
      }
      var tmp$34 = {
        action: action,
        users: users$1,
        message: React.createElement(React.Fragment, undefined, "archived the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$5))),
        openBranches: openBranches
      };
      var tmp$35 = Belt_Option.map(unarchiveItemOption(item, AvoConfig.getSourceName(source$5)), (function (option) {
              return [option];
            }));
      if (tmp$35 !== undefined) {
        tmp$34.menuOptions = Caml_option.valFromOption(tmp$35);
      }
      return React.createElement(ActivityItem$ActivityItemAction, tmp$34);
    }
    if (variant === "UpdateIntegrationConfig") {
      var integration$1 = StateUtils.getIntegrationById(match.VAL[0], modelBeforeAction);
      if (integration$1 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "updated the configuration of the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$1))),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "integration",
                          VAL: [
                            integration$1.id,
                            undefined
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "UpdatePropertyOperation") {
      var match$76 = match.VAL;
      var match$77 = StateUtils.getPropertyByIdWithArchive(match$76[0], modelBeforeAction);
      if (match$77 === undefined) {
        return null;
      }
      if (match$77.TAG === /* PropertyRef */0) {
        return null;
      }
      var tmp$36 = {
        action: action,
        users: users$1,
        message: React.createElement(React.Fragment, undefined, "made the property ", React.createElement("b", undefined, match$77._0.name), " ", TrackingPlanModel.operationToJs(match$76[1]).toLowerCase()),
        openBranches: openBranches
      };
      if (linkToPropertyFromContext !== undefined) {
        tmp$36.path = Caml_option.valFromOption(linkToPropertyFromContext);
      }
      return React.createElement(ActivityItem$ActivityItemAction, tmp$36);
    }
    if (variant === "TogglePropertyExcludedSource") {
      var match$78 = match.VAL;
      var propertyId$3 = match$78[0];
      var match$79 = StateUtils.getPropertyById(propertyId$3, modelBeforeAction);
      var match$80 = StateUtils.getSourceById(match$78[1], modelBeforeAction);
      if (match$79 !== undefined && !(match$79.TAG === /* PropertyRef */0 || match$80 === undefined)) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, (
                          match$78[2] ? "started " : "stopped "
                        ) + "sending the property ", React.createElement("b", undefined, match$79._0.name), " to ", React.createElement("b", undefined, AvoConfig.getSourceName(match$80))),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "property",
                          VAL: [
                            propertyId$3,
                            undefined
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "RemoveTag") {
      var match$81 = match.VAL;
      var eventId$10 = match$81[0];
      var $$event$6 = StateUtils.getEventByIdWithArchive(eventId$10, modelBeforeAction);
      if ($$event$6 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "removed the tag ", React.createElement("b", undefined, match$81[1]), " from ", React.createElement("b", undefined, $$event$6.name)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "event",
                          VAL: [
                            eventId$10,
                            undefined,
                            undefined,
                            false
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "AddUserToGroup") {
      var match$82 = match.VAL;
      var match$83 = StateUtils.getGroupTypeName(match$82[0], modelBeforeAction);
      var match$84 = StateUtils.getEventByIdWithArchive(match$82[1], modelBeforeAction);
      if (match$83 !== undefined && match$84 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "associated the group type ", React.createElement("b", undefined, match$83), " with the user in the event ", React.createElement("b", undefined, match$84.name)),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "RemoveIntegration") {
      var integration$2 = StateUtils.getIntegrationById(match.VAL, modelBeforeAction);
      if (integration$2 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "deleted the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$2))),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "IntegrationComment") {
      var match$85 = match.VAL;
      var commentId$4 = match$85[1];
      var integrationId = match$85[0];
      var tmp$37 = {
        schemaId: schemaId,
        threadId: integrationId,
        commentId: commentId$4,
        link: Router.Link.addDrawerItem(undefined, {
              NAME: "integration",
              VAL: [
                integrationId,
                commentId$4
              ]
            }),
        itemTypeName: "integration",
        action: action,
        users: users$1,
        events: modelBeforeAction.events,
        openBranches: openBranches
      };
      var tmp$38 = Belt_Option.map(StateUtils.getIntegrationById(integrationId, modelBeforeAction), AvoConfig.getIntegrationName);
      if (tmp$38 !== undefined) {
        tmp$37.itemName = Caml_option.valFromOption(tmp$38);
      }
      return React.createElement(ActivityItem$ActivityItemComment, tmp$37);
    }
    if (variant === "UpdateDestinationApiKey") {
      var match$86 = match.VAL;
      var destinationId$3 = match$86[0];
      var destination$4 = StateUtils.getDestinationByIdWithArchive(destinationId$3, modelBeforeAction);
      if (destination$4 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "updated the " + match$86[1] + " api key of the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(destination$4))),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "destination",
                          VAL: destinationId$3
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "UpdateDestinationName") {
      var match$87 = match.VAL;
      var destinationId$4 = match$87[0];
      var destination$5 = StateUtils.getDestinationByIdWithArchive(destinationId$4, modelBeforeAction);
      if (destination$5 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "renamed the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(destination$5)), " to ", React.createElement("b", undefined, match$87[1])),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "destination",
                          VAL: destinationId$4
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "ToggleIncludeEventInCodegenForSource") {
      var match$88 = match.VAL;
      var includeInCodegen = match$88[2];
      var eventId$11 = match$88[0];
      var match$89 = StateUtils.getEventByIdWithArchive(eventId$11, modelBeforeAction);
      var match$90 = StateUtils.getSourceByIdWithArchive(match$88[1], modelBeforeAction);
      if (match$89 !== undefined) {
        if (match$90 !== undefined) {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: React.createElement(React.Fragment, undefined, (
                            includeInCodegen ? "included" : "excluded"
                          ) + " the event ", React.createElement("b", undefined, match$89.name), " " + (
                            includeInCodegen ? "in" : "from"
                          ) + " Codegen for the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$90))),
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "event",
                            VAL: [
                              eventId$11,
                              undefined,
                              undefined,
                              false
                            ]
                          }),
                      openBranches: openBranches
                    });
        } else {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: React.createElement(React.Fragment, undefined, (
                            includeInCodegen ? "included" : "excluded"
                          ) + " the event ", React.createElement("b", undefined, match$89.name), " " + (
                            includeInCodegen ? "in" : "from"
                          ) + " Codegen for a source"),
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "event",
                            VAL: [
                              eventId$11,
                              undefined,
                              undefined,
                              false
                            ]
                          }),
                      openBranches: openBranches
                    });
        }
      } else {
        return null;
      }
    }
    if (variant === "UpdatePropertyOptional") {
      var match$91 = match.VAL;
      var match$92 = StateUtils.getPropertyByIdWithArchive(match$91[0], modelBeforeAction);
      if (match$92 === undefined) {
        return null;
      }
      if (match$92.TAG === /* PropertyRef */0) {
        return null;
      }
      var tmp$39 = {
        action: action,
        users: users$1,
        message: React.createElement(React.Fragment, undefined, "made the property ", React.createElement("b", undefined, match$92._0.name), match$91[1] ? " optional" : " required"),
        openBranches: openBranches
      };
      if (linkToPropertyFromContext !== undefined) {
        tmp$39.path = Caml_option.valFromOption(linkToPropertyFromContext);
      }
      return React.createElement(ActivityItem$ActivityItemAction, tmp$39);
    }
    if (variant === "IncludeEventInSource" || variant === "IncludeEventInSourceV2") {
      var match$93 = match.VAL;
      var eventId$12 = match$93[0];
      var match$94 = StateUtils.getEventByIdWithArchive(eventId$12, modelBeforeAction);
      var match$95 = StateUtils.getSourceByIdWithArchive(match$93[1], modelBeforeAction);
      if (match$94 !== undefined) {
        if (match$95 !== undefined) {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: React.createElement(React.Fragment, undefined, "added the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$95)), " to the event ", React.createElement("b", undefined, match$94.name)),
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "event",
                            VAL: [
                              eventId$12,
                              undefined,
                              undefined,
                              false
                            ]
                          }),
                      openBranches: openBranches
                    });
        } else {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: React.createElement(React.Fragment, undefined, "updated the sources ", React.createElement("b", undefined, match$94.name), " is sent from"),
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "event",
                            VAL: [
                              eventId$12,
                              undefined,
                              undefined,
                              false
                            ]
                          }),
                      openBranches: openBranches
                    });
        }
      } else {
        return null;
      }
    }
    if (variant === "AddSystemProperty") {
      var tmp$40 = {
        action: action,
        users: users$1,
        message: React.createElement(React.Fragment, undefined, "added the system property ", React.createElement("b", undefined, match.VAL[1])),
        openBranches: openBranches
      };
      if (linkToPropertyFromContext !== undefined) {
        tmp$40.path = Caml_option.valFromOption(linkToPropertyFromContext);
      }
      return React.createElement(ActivityItem$ActivityItemAction, tmp$40);
    }
    if (variant === "UpdateRule") {
      var item$1 = match.VAL[1];
      if (typeof item$1 !== "object") {
        return null;
      }
      if (item$1.NAME !== "Property") {
        return null;
      }
      var match$96 = StateUtils.getPropertyByIdWithArchive(item$1.VAL, modelBeforeAction);
      if (match$96 !== undefined && match$96.TAG !== /* PropertyRef */0) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "removed a name mapping from the property ", React.createElement("b", undefined, match$96._0.name)),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "GoalComment") {
      var match$97 = match.VAL;
      var commentId$5 = match$97[1];
      var goalId$2 = match$97[0];
      var tmp$41 = {
        schemaId: schemaId,
        threadId: goalId$2,
        commentId: commentId$5,
        link: Router.Link.addDrawerItem(undefined, {
              NAME: "category",
              VAL: [
                goalId$2,
                commentId$5
              ]
            }),
        itemTypeName: "category",
        action: action,
        users: users$1,
        events: modelBeforeAction.events,
        openBranches: openBranches
      };
      var tmp$42 = Belt_Option.map(StateUtils.getGoalByIdWithArchive(goalId$2, modelBeforeAction), (function (goal) {
              return goal.name;
            }));
      if (tmp$42 !== undefined) {
        tmp$41.itemName = Caml_option.valFromOption(tmp$42);
      }
      return React.createElement(ActivityItem$ActivityItemComment, tmp$41);
    }
    if (variant === "RemoveSourceSegmentIntegration") {
      var match$98 = match.VAL;
      var sourceId$9 = match$98[0];
      var source$6 = StateUtils.getSourceByIdWithArchive(sourceId$9, modelBeforeAction);
      if (source$6 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "removed ", React.createElement("b", undefined, match$98[1]), " from the ", React.createElement("b", undefined, AvoConfig.getSourceName(source$6)), " source"),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "source",
                          VAL: [
                            sourceId$9,
                            "overview"
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "UpdateEventDescription") {
      var match$99 = match.VAL;
      var eventId$13 = match$99[0];
      var $$event$7 = StateUtils.getEventByIdWithArchive(eventId$13, modelBeforeAction);
      if ($$event$7 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "updated the description of the event ", React.createElement("b", undefined, $$event$7.name), " to " + match$99[1]),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "event",
                          VAL: [
                            eventId$13,
                            undefined,
                            undefined,
                            false
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "AddEventToMetric") {
      var match$100 = match.VAL;
      var match$101 = StateUtils.getMetricByIdWithArchive(match$100[0], modelBeforeAction);
      var match$102 = StateUtils.getEventByIdWithArchive(match$100[2], modelBeforeAction);
      if (match$101 !== undefined && match$102 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "added the event ", React.createElement("b", undefined, match$102.name), " to the metric ", React.createElement("b", undefined, match$101.name)),
                    path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "ReorderEventsInMetric") {
      var match$103 = match.VAL;
      var metricId$2 = match$103[0];
      var match$104 = StateUtils.getMetricByIdWithArchive(metricId$2, modelBeforeAction);
      var match$105 = StateUtils.getEventByIdWithArchive(match$103[1], modelBeforeAction);
      if (match$104 !== undefined && match$105 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "moved the event ", React.createElement("b", undefined, match$105.name), " in the metric ", React.createElement("b", undefined, match$104.name)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "metric",
                          VAL: [
                            metricId$2,
                            undefined
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "CreateDestination") {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: "created a destination",
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "destination",
                        VAL: match.VAL
                      }),
                  openBranches: openBranches
                });
    }
    if (variant === "AddPropertyValidationMatch") {
      var match$106 = match.VAL;
      var match$107 = StateUtils.getPropertyByIdWithArchive(match$106[0], modelBeforeAction);
      if (match$107 === undefined) {
        return null;
      }
      if (match$107.TAG === /* PropertyRef */0) {
        return null;
      }
      var tmp$43 = {
        action: action,
        users: users$1,
        message: React.createElement(React.Fragment, undefined, "added the match ", React.createElement("b", undefined, match$106[1]), " to the property ", React.createElement("b", undefined, match$107._0.name)),
        openBranches: openBranches
      };
      if (linkToPropertyFromContext !== undefined) {
        tmp$43.path = Caml_option.valFromOption(linkToPropertyFromContext);
      }
      return React.createElement(ActivityItem$ActivityItemAction, tmp$43);
    }
    if (variant === "DeleteComment" || variant === "RemoveNestedPropertyPinnedValue" || variant === "UpdateNestedPropertyPinnedValue") {
      return null;
    }
    if (variant === "AddMetricToGoal") {
      var match$108 = match.VAL;
      var goal$4 = StateUtils.getGoalByIdWithArchive(match$108[0], modelBeforeAction);
      if (goal$4 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "added a metric to the category ", React.createElement("b", undefined, goal$4.name)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "metric",
                          VAL: [
                            match$108[1],
                            undefined
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "ExcludeDestinationFromEventSource") {
      var match$109 = match.VAL;
      var sourceId$10 = match$109[1];
      var match$110 = StateUtils.getEventByIdWithArchive(match$109[0], modelBeforeAction);
      var match$111 = StateUtils.getSourceByIdWithArchive(sourceId$10, modelBeforeAction);
      var match$112 = StateUtils.getDestinationByIdWithArchive(match$109[2], modelBeforeAction);
      if (match$110 !== undefined && match$111 !== undefined && match$112 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "stopped sending the event ", React.createElement("b", undefined, match$110.name), " to ", React.createElement("b", undefined, StateUtils.getDestinationName(match$112)), " from ", React.createElement("b", undefined, AvoConfig.getSourceName(match$111))),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "source",
                          VAL: [
                            sourceId$10,
                            "overview"
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "DeleteDestination") {
      var destination$6 = StateUtils.getDestinationByIdWithArchive(match.VAL, modelBeforeAction);
      if (destination$6 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "deleted the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(destination$6))),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "GenerateWebhookSecret") {
      var integration$3 = StateUtils.getIntegrationById(match.VAL, modelBeforeAction);
      if (integration$3 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "successfully generated new webhook secret for integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$3))),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "integration",
                          VAL: [
                            integration$3.id,
                            undefined
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "UpdatePropertyGroupDescription") {
      var group$1 = StateUtils.getPropertyGroupByIdWithArchive(match.VAL[0], modelBeforeAction);
      if (group$1 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "updated the description of the property bundle ", React.createElement("b", undefined, group$1.name)),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "UpdateTriggerDescription") {
      var match$113 = match.VAL;
      var eventId$14 = match$113[0];
      var match$114 = StateUtils.getEventByIdWithArchive(eventId$14, modelBeforeAction);
      if (match$114 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "updated the description for a trigger in ", React.createElement("b", undefined, match$114.name)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "event",
                          VAL: [
                            eventId$14,
                            undefined,
                            match$113[1],
                            false
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "RemovePropertyValidationMatches") {
      var match$115 = StateUtils.getPropertyByIdWithArchive(match.VAL, modelBeforeAction);
      if (match$115 === undefined) {
        return null;
      }
      if (match$115.TAG === /* PropertyRef */0) {
        return null;
      }
      var tmp$44 = {
        action: action,
        users: users$1,
        message: React.createElement(React.Fragment, undefined, "removed matches validation from the property ", React.createElement("b", undefined, match$115._0.name)),
        openBranches: openBranches
      };
      if (linkToPropertyFromContext !== undefined) {
        tmp$44.path = Caml_option.valFromOption(linkToPropertyFromContext);
      }
      return React.createElement(ActivityItem$ActivityItemAction, tmp$44);
    }
    if (variant === "AddPropertyToWhitelist") {
      var match$116 = match.VAL;
      var match$117 = StateUtils.getPropertyByIdWithArchive(match$116[1], modelBeforeAction);
      var match$118 = StateUtils.getEventByIdWithArchive(match$116[0], modelBeforeAction);
      if (match$117 !== undefined && !(match$117.TAG === /* PropertyRef */0 || match$118 === undefined)) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "started sending the property ", React.createElement("b", undefined, match$117._0.name), " with the event ", React.createElement("b", undefined, match$118.name), " to ", React.createElement("b", undefined, AvoConfigTypes.analyticsToolToJs(match$116[2]))),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "CreateIntegration") {
      var integration$4 = StateUtils.getIntegrationById(match.VAL, modelBeforeAction);
      if (integration$4 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "created the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$4))),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "integration",
                          VAL: [
                            integration$4.id,
                            undefined
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "UpdateDestinationAnalyticsTool") {
      var match$119 = match.VAL;
      var destinationId$5 = match$119[0];
      var destination$7 = StateUtils.getDestinationByIdWithArchive(destinationId$5, modelBeforeAction);
      if (destination$7 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "updated the analytics tool of ", React.createElement("b", undefined, StateUtils.getDestinationName(destination$7)), " to ", React.createElement("b", undefined, AvoConfig.analyticsToolsLabel(match$119[1]))),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "destination",
                          VAL: destinationId$5
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "RemovePropertyFromGroup") {
      var match$120 = match.VAL;
      var match$121 = StateUtils.getPropertyGroupByIdWithArchive(match$120[0], modelBeforeAction);
      var match$122 = StateUtils.getPropertyByIdWithArchive(match$120[1], modelBeforeAction);
      if (match$121 !== undefined && match$122 !== undefined && match$122.TAG !== /* PropertyRef */0) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "removed the property ", React.createElement("b", undefined, match$122._0.name), " from the property bundle ", React.createElement("b", undefined, match$121.name)),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "AddEventToGoal") {
      var match$123 = match.VAL;
      var goalId$3 = match$123[0];
      var match$124 = StateUtils.getGoalByIdWithArchive(goalId$3, modelBeforeAction);
      var match$125 = StateUtils.getEventByIdWithArchive(match$123[1], modelBeforeAction);
      if (match$124 !== undefined && match$125 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "added the event ", React.createElement("b", undefined, match$125.name), " to the category ", React.createElement("b", undefined, match$124.name)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "category",
                          VAL: [
                            goalId$3,
                            undefined
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "UpdatePropertyValidationMinOrMax") {
      var match$126 = match.VAL;
      var match$127 = StateUtils.getPropertyByIdWithArchive(match$126[0], modelBeforeAction);
      if (match$127 === undefined) {
        return null;
      }
      if (match$127.TAG === /* PropertyRef */0) {
        return null;
      }
      var tmp$45 = {
        action: action,
        users: users$1,
        message: React.createElement(React.Fragment, undefined, "updated the " + TrackingPlanModel.constraint_ToJs(match$126[2]).toLowerCase() + " constraint of the property ", React.createElement("b", undefined, match$127._0.name), " to be " + match$126[1]),
        openBranches: openBranches
      };
      if (linkToPropertyFromContext !== undefined) {
        tmp$45.path = Caml_option.valFromOption(linkToPropertyFromContext);
      }
      return React.createElement(ActivityItem$ActivityItemAction, tmp$45);
    }
    if (variant === "AddTriggerSource") {
      var match$128 = match.VAL;
      var eventId$15 = match$128[0];
      var match$129 = StateUtils.getEventByIdWithArchive(eventId$15, modelBeforeAction);
      var match$130 = StateUtils.getSourceById(match$128[2], modelBeforeAction);
      if (match$129 !== undefined && match$130 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "added ", React.createElement("b", undefined, AvoConfig.getSourceName(match$130)), " as a source for a trigger in ", React.createElement("b", undefined, match$129.name)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "event",
                          VAL: [
                            eventId$15,
                            undefined,
                            match$128[1],
                            false
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "IncludeDestinationInEventSource") {
      var match$131 = match.VAL;
      var sourceId$11 = match$131[1];
      var match$132 = StateUtils.getEventByIdWithArchive(match$131[0], modelBeforeAction);
      var match$133 = StateUtils.getSourceByIdWithArchive(sourceId$11, modelBeforeAction);
      var match$134 = StateUtils.getDestinationByIdWithArchive(match$131[2], modelBeforeAction);
      if (match$132 !== undefined && match$133 !== undefined && match$134 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "started sending the event ", React.createElement("b", undefined, match$132.name), " to ", React.createElement("b", undefined, StateUtils.getDestinationName(match$134)), " from ", React.createElement("b", undefined, AvoConfig.getSourceName(match$133))),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "source",
                          VAL: [
                            sourceId$11,
                            "overview"
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "AddGroupTypeToLogEvent") {
      var match$135 = match.VAL;
      var match$136 = StateUtils.getGroupTypeName(match$135[0], modelBeforeAction);
      var match$137 = StateUtils.getEventByIdWithArchive(match$135[1], modelBeforeAction);
      if (match$136 !== undefined && match$137 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "added the group type ", React.createElement("b", undefined, match$136), " to the event ", React.createElement("b", undefined, match$137.name)),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "UpdatePropertyPinnedValue") {
      var match$138 = match.VAL;
      var eventId$16 = match$138[0];
      var match$139 = StateUtils.getEventById(eventId$16, modelBeforeAction);
      var match$140 = StateUtils.getPropertyById(match$138[1], modelBeforeAction);
      if (match$139 !== undefined && match$140 !== undefined && match$140.TAG !== /* PropertyRef */0) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "pinned the value of ", React.createElement("b", undefined, match$140._0.name), " on ", React.createElement("b", undefined, match$139.name), " to ", React.createElement("b", undefined, PropertyUtils.printPinnedValue(match$138[2]))),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "event",
                          VAL: [
                            eventId$16,
                            undefined,
                            undefined,
                            false
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "PublishIntegration") {
      var match$141 = match.VAL;
      var integration$5 = StateUtils.getIntegrationById(match$141[0], modelBeforeAction);
      if (integration$5 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: match$141[1] ? React.createElement(React.Fragment, undefined, "successfully published the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$5))) : React.createElement(React.Fragment, undefined, "published the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$5)), " with errors"),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "integration",
                          VAL: [
                            integration$5.id,
                            undefined
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "RemoveUserFromGroup") {
      var match$142 = match.VAL;
      var match$143 = StateUtils.getGroupTypeName(match$142[0], modelBeforeAction);
      var match$144 = StateUtils.getEventByIdWithArchive(match$142[1], modelBeforeAction);
      if (match$143 !== undefined && match$144 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "removed association of the group type ", React.createElement("b", undefined, match$143), " with the user in the event ", React.createElement("b", undefined, match$144.name)),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "RemovePropertyValidation") {
      var match$145 = match.VAL;
      var validation = match$145[1];
      var propertyId$4 = match$145[0];
      var variant$2 = validation.NAME;
      if (variant$2 === "EndsWith" || variant$2 === "Shape" || variant$2 === "NestedProperty" || variant$2 === "Contains" || variant$2 === "StartsWith") {
        return null;
      }
      if (variant$2 === "Max") {
        var match$146 = validation.VAL;
        if (typeof match$146 !== "object") {
          return null;
        }
        var variant$3 = match$146.NAME;
        if (variant$3 === "IntLit") {
          var match$147 = StateUtils.getPropertyByIdWithArchive(propertyId$4, modelBeforeAction);
          if (match$147 === undefined) {
            return null;
          }
          if (match$147.TAG === /* PropertyRef */0) {
            return null;
          }
          var tmp$46 = {
            action: action,
            users: users$1,
            message: React.createElement(React.Fragment, undefined, "removed the max ", React.createElement("b", undefined, String(match$146.VAL)), " constraint from the property ", React.createElement("b", undefined, match$147._0.name)),
            openBranches: openBranches
          };
          if (linkToPropertyFromContext !== undefined) {
            tmp$46.path = Caml_option.valFromOption(linkToPropertyFromContext);
          }
          return React.createElement(ActivityItem$ActivityItemAction, tmp$46);
        }
        if (variant$3 !== "FloatLit") {
          return null;
        }
        var match$148 = StateUtils.getPropertyByIdWithArchive(propertyId$4, modelBeforeAction);
        if (match$148 === undefined) {
          return null;
        }
        if (match$148.TAG === /* PropertyRef */0) {
          return null;
        }
        var tmp$47 = {
          action: action,
          users: users$1,
          message: React.createElement(React.Fragment, undefined, "removed the max ", React.createElement("b", undefined, match$146.VAL.toString()), " constraint from the property ", React.createElement("b", undefined, match$148._0.name)),
          openBranches: openBranches
        };
        if (linkToPropertyFromContext !== undefined) {
          tmp$47.path = Caml_option.valFromOption(linkToPropertyFromContext);
        }
        return React.createElement(ActivityItem$ActivityItemAction, tmp$47);
      }
      if (variant$2 === "Min") {
        var match$149 = validation.VAL;
        if (typeof match$149 !== "object") {
          return null;
        }
        var variant$4 = match$149.NAME;
        if (variant$4 === "IntLit") {
          var match$150 = StateUtils.getPropertyByIdWithArchive(propertyId$4, modelBeforeAction);
          if (match$150 === undefined) {
            return null;
          }
          if (match$150.TAG === /* PropertyRef */0) {
            return null;
          }
          var tmp$48 = {
            action: action,
            users: users$1,
            message: React.createElement(React.Fragment, undefined, "removed the min ", React.createElement("b", undefined, String(match$149.VAL)), " constraint from the property ", React.createElement("b", undefined, match$150._0.name)),
            openBranches: openBranches
          };
          if (linkToPropertyFromContext !== undefined) {
            tmp$48.path = Caml_option.valFromOption(linkToPropertyFromContext);
          }
          return React.createElement(ActivityItem$ActivityItemAction, tmp$48);
        }
        if (variant$4 !== "FloatLit") {
          return null;
        }
        var match$151 = StateUtils.getPropertyByIdWithArchive(propertyId$4, modelBeforeAction);
        if (match$151 === undefined) {
          return null;
        }
        if (match$151.TAG === /* PropertyRef */0) {
          return null;
        }
        var tmp$49 = {
          action: action,
          users: users$1,
          message: React.createElement(React.Fragment, undefined, "removed the min ", React.createElement("b", undefined, match$149.VAL.toString()), " constraint from the property ", React.createElement("b", undefined, match$151._0.name)),
          openBranches: openBranches
        };
        if (linkToPropertyFromContext !== undefined) {
          tmp$49.path = Caml_option.valFromOption(linkToPropertyFromContext);
        }
        return React.createElement(ActivityItem$ActivityItemAction, tmp$49);
      }
      var match$152 = validation.VAL;
      var exit = 0;
      if (match$152) {
        var match$153 = match$152.hd[0];
        if (typeof match$153 === "object" && match$153.NAME === "Literal") {
          var match$154 = match$153.VAL;
          if (typeof match$154 === "object") {
            if (match$154.NAME === "StringLit" && !match$152.tl) {
              var match$155 = StateUtils.getPropertyByIdWithArchive(propertyId$4, modelBeforeAction);
              if (match$155 === undefined) {
                return null;
              }
              if (match$155.TAG === /* PropertyRef */0) {
                return null;
              }
              var tmp$50 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "removed the match constraint ", React.createElement("b", undefined, match$154.VAL), " from the property ", React.createElement("b", undefined, match$155._0.name)),
                openBranches: openBranches
              };
              if (linkToPropertyFromContext !== undefined) {
                tmp$50.path = Caml_option.valFromOption(linkToPropertyFromContext);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$50);
            }
            exit = 2;
          } else {
            exit = 2;
          }
        } else {
          exit = 2;
        }
      } else {
        var match$156 = StateUtils.getPropertyByIdWithArchive(propertyId$4, modelBeforeAction);
        if (match$156 === undefined) {
          return null;
        }
        if (match$156.TAG === /* PropertyRef */0) {
          return null;
        }
        var tmp$51 = {
          action: action,
          users: users$1,
          message: React.createElement(React.Fragment, undefined, "removed all match constraints from the property ", React.createElement("b", undefined, match$156._0.name)),
          openBranches: openBranches
        };
        if (linkToPropertyFromContext !== undefined) {
          tmp$51.path = Caml_option.valFromOption(linkToPropertyFromContext);
        }
        return React.createElement(ActivityItem$ActivityItemAction, tmp$51);
      }
      if (exit === 2) {
        var match$157 = StateUtils.getPropertyByIdWithArchive(propertyId$4, modelBeforeAction);
        if (match$157 === undefined) {
          return null;
        }
        if (match$157.TAG === /* PropertyRef */0) {
          return null;
        }
        var tmp$52 = {
          action: action,
          users: users$1,
          message: React.createElement(React.Fragment, undefined, "removed multiple match constraints from the property ", React.createElement("b", undefined, match$157._0.name)),
          openBranches: openBranches
        };
        if (linkToPropertyFromContext !== undefined) {
          tmp$52.path = Caml_option.valFromOption(linkToPropertyFromContext);
        }
        return React.createElement(ActivityItem$ActivityItemAction, tmp$52);
      }
      
    } else {
      if (variant === "UpdateGoalName") {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "renamed a category to ", React.createElement("b", undefined, match.VAL[1])),
                    path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                    openBranches: openBranches
                  });
      }
      if (variant === "DuplicateEvent") {
        var eventId$17 = match.VAL[0][1];
        var match$158 = StateUtils.getEventByIdWithArchive(eventId$17, modelBeforeAction);
        if (match$158 !== undefined) {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: React.createElement(React.Fragment, undefined, "duplicated an event: ", React.createElement("b", undefined, match$158.name)),
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "event",
                            VAL: [
                              eventId$17,
                              undefined,
                              undefined,
                              false
                            ]
                          }),
                      openBranches: openBranches
                    });
        } else {
          return null;
        }
      }
      if (variant === "AddPropertyValidation") {
        var match$159 = match.VAL;
        var validation$1 = match$159[1];
        var propertyId$5 = match$159[0];
        var variant$5 = validation$1.NAME;
        if (variant$5 === "EndsWith" || variant$5 === "Shape" || variant$5 === "NestedProperty" || variant$5 === "Contains" || variant$5 === "StartsWith") {
          return null;
        }
        if (variant$5 === "Max") {
          var match$160 = validation$1.VAL;
          if (typeof match$160 !== "object") {
            return null;
          }
          var variant$6 = match$160.NAME;
          if (variant$6 === "IntLit") {
            var match$161 = StateUtils.getPropertyByIdWithArchive(propertyId$5, modelBeforeAction);
            if (match$161 === undefined) {
              return null;
            }
            if (match$161.TAG === /* PropertyRef */0) {
              return null;
            }
            var tmp$53 = {
              action: action,
              users: users$1,
              message: React.createElement(React.Fragment, undefined, "added a max ", React.createElement("b", undefined, String(match$160.VAL)), " constraint to the property ", React.createElement("b", undefined, match$161._0.name)),
              openBranches: openBranches
            };
            if (linkToPropertyFromContext !== undefined) {
              tmp$53.path = Caml_option.valFromOption(linkToPropertyFromContext);
            }
            return React.createElement(ActivityItem$ActivityItemAction, tmp$53);
          }
          if (variant$6 !== "FloatLit") {
            return null;
          }
          var match$162 = StateUtils.getPropertyByIdWithArchive(propertyId$5, modelBeforeAction);
          if (match$162 === undefined) {
            return null;
          }
          if (match$162.TAG === /* PropertyRef */0) {
            return null;
          }
          var tmp$54 = {
            action: action,
            users: users$1,
            message: React.createElement(React.Fragment, undefined, "added a max ", React.createElement("b", undefined, match$160.VAL.toString()), " constraint to the property ", React.createElement("b", undefined, match$162._0.name)),
            openBranches: openBranches
          };
          if (linkToPropertyFromContext !== undefined) {
            tmp$54.path = Caml_option.valFromOption(linkToPropertyFromContext);
          }
          return React.createElement(ActivityItem$ActivityItemAction, tmp$54);
        }
        if (variant$5 === "Min") {
          var match$163 = validation$1.VAL;
          if (typeof match$163 !== "object") {
            return null;
          }
          var variant$7 = match$163.NAME;
          if (variant$7 === "IntLit") {
            var match$164 = StateUtils.getPropertyByIdWithArchive(propertyId$5, modelBeforeAction);
            if (match$164 === undefined) {
              return null;
            }
            if (match$164.TAG === /* PropertyRef */0) {
              return null;
            }
            var tmp$55 = {
              action: action,
              users: users$1,
              message: React.createElement(React.Fragment, undefined, "added a min ", React.createElement("b", undefined, String(match$163.VAL)), " constraint to the property ", React.createElement("b", undefined, match$164._0.name)),
              openBranches: openBranches
            };
            if (linkToPropertyFromContext !== undefined) {
              tmp$55.path = Caml_option.valFromOption(linkToPropertyFromContext);
            }
            return React.createElement(ActivityItem$ActivityItemAction, tmp$55);
          }
          if (variant$7 !== "FloatLit") {
            return null;
          }
          var match$165 = StateUtils.getPropertyByIdWithArchive(propertyId$5, modelBeforeAction);
          if (match$165 === undefined) {
            return null;
          }
          if (match$165.TAG === /* PropertyRef */0) {
            return null;
          }
          var tmp$56 = {
            action: action,
            users: users$1,
            message: React.createElement(React.Fragment, undefined, "added a min ", React.createElement("b", undefined, match$163.VAL.toString()), " constraint to the property ", React.createElement("b", undefined, match$165._0.name)),
            openBranches: openBranches
          };
          if (linkToPropertyFromContext !== undefined) {
            tmp$56.path = Caml_option.valFromOption(linkToPropertyFromContext);
          }
          return React.createElement(ActivityItem$ActivityItemAction, tmp$56);
        }
        var match$166 = validation$1.VAL;
        var exit$1 = 0;
        if (!match$166) {
          return null;
        }
        var match$167 = match$166.hd[0];
        if (typeof match$167 === "object" && match$167.NAME === "Literal") {
          var match$168 = match$167.VAL;
          if (typeof match$168 === "object") {
            if (match$168.NAME === "StringLit" && !match$166.tl) {
              var match$169 = StateUtils.getPropertyByIdWithArchive(propertyId$5, modelBeforeAction);
              if (match$169 === undefined) {
                return null;
              }
              if (match$169.TAG === /* PropertyRef */0) {
                return null;
              }
              var tmp$57 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "added the match constraint ", React.createElement("b", undefined, match$168.VAL), " to the property ", React.createElement("b", undefined, match$169._0.name)),
                openBranches: openBranches
              };
              if (linkToPropertyFromContext !== undefined) {
                tmp$57.path = Caml_option.valFromOption(linkToPropertyFromContext);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$57);
            }
            exit$1 = 2;
          } else {
            exit$1 = 2;
          }
        } else {
          exit$1 = 2;
        }
        if (exit$1 === 2) {
          var match$170 = StateUtils.getPropertyByIdWithArchive(propertyId$5, modelBeforeAction);
          if (match$170 === undefined) {
            return null;
          }
          if (match$170.TAG === /* PropertyRef */0) {
            return null;
          }
          var tmp$58 = {
            action: action,
            users: users$1,
            message: React.createElement(React.Fragment, undefined, "added multiple match constraints to the property ", React.createElement("b", undefined, match$170._0.name)),
            openBranches: openBranches
          };
          if (linkToPropertyFromContext !== undefined) {
            tmp$58.path = Caml_option.valFromOption(linkToPropertyFromContext);
          }
          return React.createElement(ActivityItem$ActivityItemAction, tmp$58);
        }
        
      } else {
        if (variant === "PullMaster") {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: "pulled main into branch",
                      openBranches: openBranches
                    });
        }
        if (variant === "UpdateMetricName") {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: React.createElement(React.Fragment, undefined, "renamed a metric to ", React.createElement("b", undefined, match.VAL[1])),
                      path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                      openBranches: openBranches
                    });
        }
        if (variant === "CreateSource") {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: "created a source",
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "source",
                            VAL: [
                              match.VAL,
                              "overview"
                            ]
                          }),
                      openBranches: openBranches
                    });
        }
        if (variant === "AddSourceSegmentIntegration" || variant === "UpdateSourceSegmentIntegration") {
          var match$171 = match.VAL;
          var sourceId$12 = match$171[0];
          var source$7 = StateUtils.getSourceByIdWithArchive(sourceId$12, modelBeforeAction);
          if (source$7 !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, match$171[2] ? "enabled " : "disabled ", React.createElement("b", undefined, match$171[1]), " in the ", React.createElement("b", undefined, AvoConfig.getSourceName(source$7)), " source"),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "source",
                              VAL: [
                                sourceId$12,
                                "overview"
                              ]
                            }),
                        openBranches: openBranches
                      });
          } else {
            return null;
          }
        }
        if (variant === "RemoveEventType") {
          var match$172 = match.VAL;
          var eventId$18 = match$172[0];
          var $$event$8 = StateUtils.getEventByIdWithArchive(eventId$18, modelBeforeAction);
          if ($$event$8 !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "removed the event type ", React.createElement("b", undefined, TrackingPlanModel.eventTypeToJs(match$172[1])), " to ", React.createElement("b", undefined, $$event$8.name)),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "event",
                              VAL: [
                                eventId$18,
                                undefined,
                                undefined,
                                false
                              ]
                            }),
                        openBranches: openBranches
                      });
          } else {
            return null;
          }
        }
        if (variant === "RemoveEventFromGoal") {
          var match$173 = match.VAL;
          var goalId$4 = match$173[0];
          var match$174 = StateUtils.getGoalByIdWithArchive(goalId$4, modelBeforeAction);
          var match$175 = StateUtils.getEventByIdWithArchive(match$173[1], modelBeforeAction);
          if (match$174 !== undefined && match$175 !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "removed the event ", React.createElement("b", undefined, match$175.name), " from the category ", React.createElement("b", undefined, match$174.name)),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "category",
                              VAL: [
                                goalId$4,
                                undefined
                              ]
                            }),
                        openBranches: openBranches
                      });
          } else {
            return null;
          }
        }
        if (variant === "ExcludeDestinationFromSource") {
          var match$176 = match.VAL;
          var sourceId$13 = match$176[0];
          var match$177 = StateUtils.getSourceByIdWithArchive(sourceId$13, modelBeforeAction);
          var match$178 = StateUtils.getDestinationByIdWithArchive(match$176[1], modelBeforeAction);
          if (match$177 !== undefined && match$178 !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "removed the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(match$178)), " from the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$177))),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "source",
                              VAL: [
                                sourceId$13,
                                "overview"
                              ]
                            }),
                        openBranches: openBranches
                      });
          } else {
            return null;
          }
        }
        if (variant === "UpdateMetricType") {
          var match$179 = match.VAL;
          var metricId$3 = match$179[0];
          var metric$3 = StateUtils.getMetricByIdWithArchive(metricId$3, modelBeforeAction);
          if (metric$3 !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "changed the type of the metric ", React.createElement("b", undefined, metric$3.name), " to ", React.createElement("b", undefined, AvoUtils.getMetricTypeLabel(match$179[1]))),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "metric",
                              VAL: [
                                metricId$3,
                                undefined
                              ]
                            }),
                        openBranches: openBranches
                      });
          } else {
            return null;
          }
        }
        if (variant === "ClearPropertyExcludedSources") {
          var propertyId$6 = match.VAL;
          var match$180 = StateUtils.getPropertyById(propertyId$6, modelBeforeAction);
          if (match$180 !== undefined && match$180.TAG !== /* PropertyRef */0) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "started sending the property ", React.createElement("b", undefined, match$180._0.name), " to ", React.createElement("b", undefined, "all sources")),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "property",
                              VAL: [
                                propertyId$6,
                                undefined
                              ]
                            }),
                        openBranches: openBranches
                      });
          } else {
            return null;
          }
        }
        if (variant === "UpdateIntegrationName") {
          var match$181 = match.VAL;
          var integration$6 = StateUtils.getIntegrationById(match$181[0], modelBeforeAction);
          if (integration$6 !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "updated the name of a integration to ", React.createElement("b", undefined, match$181[1])),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "integration",
                              VAL: [
                                integration$6.id,
                                undefined
                              ]
                            }),
                        openBranches: openBranches
                      });
          } else {
            return null;
          }
        }
        if (variant === "CreatePropertyGroup") {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: React.createElement(React.Fragment, undefined, "created the property bundle ", React.createElement("b", undefined, match.VAL[1])),
                      openBranches: openBranches
                    });
        }
        if (variant === "UpdatePropertyGroupName") {
          var match$182 = match.VAL;
          var group$2 = StateUtils.getPropertyGroupByIdWithArchive(match$182[0], modelBeforeAction);
          if (group$2 !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "renamed the property bundle ", React.createElement("b", undefined, group$2.name), " to ", React.createElement("b", undefined, match$182[1])),
                        openBranches: openBranches
                      });
          } else {
            return null;
          }
        }
        if (variant === "AddSourceConfig") {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: "added config to source",
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "source",
                            VAL: [
                              match.VAL[0],
                              "overview"
                            ]
                          }),
                      openBranches: openBranches
                    });
        }
        if (variant === "UpdateEventUniqueName") {
          var match$183 = match.VAL;
          eventId = match$183[0];
          uniqueName = match$183[1];
        } else {
          if (variant === "CreatePublicBranchImplementation") {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: "created a public branch implementation instructions link ",
                        path: BranchImplementationShareButton.getShareUrl(match.VAL),
                        openBranches: openBranches
                      });
          }
          if (variant === "UpdateIntegrationType") {
            var match$184 = match.VAL;
            var integration$7 = StateUtils.getIntegrationById(match$184[0], modelBeforeAction);
            if (integration$7 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "updated the type of the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$7)), " to ", React.createElement("b", undefined, TrackingPlanModel.integrationTypeToJs(match$184[1]))),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "integration",
                                VAL: [
                                  integration$7.id,
                                  undefined
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "PropertyComment") {
            var match$185 = match.VAL;
            var commentId$6 = match$185[1];
            var propertyId$7 = match$185[0];
            var tmp$59 = {
              schemaId: schemaId,
              threadId: propertyId$7,
              commentId: commentId$6,
              link: Router.Link.addDrawerItem(undefined, {
                    NAME: "property",
                    VAL: [
                      propertyId$7,
                      commentId$6
                    ]
                  }),
              itemTypeName: "property",
              action: action,
              users: users$1,
              events: modelBeforeAction.events,
              openBranches: openBranches
            };
            var tmp$60 = Belt_Option.flatMap(StateUtils.getPropertyByIdWithArchive(propertyId$7, modelBeforeAction), (function (property) {
                    if (property.TAG === /* PropertyRef */0) {
                      return ;
                    } else {
                      return property._0.name;
                    }
                  }));
            if (tmp$60 !== undefined) {
              tmp$59.itemName = Caml_option.valFromOption(tmp$60);
            }
            return React.createElement(ActivityItem$ActivityItemComment, tmp$59);
          }
          if (variant === "UpdateEventName") {
            var match$186 = match.VAL;
            var eventName = match$186[1];
            var eventId$19 = match$186[0];
            var $$event$9 = StateUtils.getEventByIdWithArchive(eventId$19, modelBeforeAction);
            if ($$event$9 !== undefined) {
              if ($$event$9.name === "") {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, "renamed an untitled event to ", React.createElement("b", undefined, eventName)),
                            path: Router.Link.addDrawerItem(undefined, {
                                  NAME: "event",
                                  VAL: [
                                    eventId$19,
                                    undefined,
                                    undefined,
                                    false
                                  ]
                                }),
                            openBranches: openBranches
                          });
              } else {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, "renamed the event", " ", React.createElement("b", undefined, $$event$9.name), " to ", React.createElement("b", undefined, eventName)),
                            path: Router.Link.addDrawerItem(undefined, {
                                  NAME: "event",
                                  VAL: [
                                    eventId$19,
                                    undefined,
                                    undefined,
                                    false
                                  ]
                                }),
                            openBranches: openBranches
                          });
              }
            } else {
              return null;
            }
          }
          if (variant === "UpdateIntegrationFilters") {
            var integration$8 = StateUtils.getIntegrationById(match.VAL[0], modelBeforeAction);
            if (integration$8 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "updated the destinations included in the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$8))),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "integration",
                                VAL: [
                                  integration$8.id,
                                  undefined
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "CreateProperty") {
            var match$187 = match.VAL;
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "created the property ", React.createElement("b", undefined, match$187[1])),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "property",
                              VAL: [
                                match$187[0],
                                undefined
                              ]
                            }),
                        openBranches: openBranches
                      });
          }
          if (variant === "UpdatePropertyOptionalWhenInObject") {
            var match$188 = match.VAL;
            var match$189 = StateUtils.getPropertyByIdWithArchive(match$188[0], modelBeforeAction);
            if (match$189 === undefined) {
              return null;
            }
            if (match$189.TAG === /* PropertyRef */0) {
              return null;
            }
            var tmp$61 = {
              action: action,
              users: users$1,
              message: React.createElement(React.Fragment, undefined, "made the property ", React.createElement("b", undefined, match$189._0.name), match$188[1] ? " optional" : " required", " when in object"),
              openBranches: openBranches
            };
            if (linkToPropertyFromContext !== undefined) {
              tmp$61.path = Caml_option.valFromOption(linkToPropertyFromContext);
            }
            return React.createElement(ActivityItem$ActivityItemAction, tmp$61);
          }
          if (variant === "RemoveGroupTypeFromLogEvent") {
            var match$190 = match.VAL;
            var match$191 = StateUtils.getGroupTypeName(match$190[0], modelBeforeAction);
            var match$192 = StateUtils.getEventByIdWithArchive(match$190[1], modelBeforeAction);
            if (match$191 !== undefined && match$192 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "removed the group type ", React.createElement("b", undefined, match$191), " from the event ", React.createElement("b", undefined, match$192.name)),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "RemovePropertyGroupFromEvent") {
            var match$193 = match.VAL;
            var match$194 = StateUtils.getPropertyGroupByIdWithArchive(match$193[1], modelBeforeAction);
            var match$195 = StateUtils.getEventByIdWithArchive(match$193[0], modelBeforeAction);
            if (match$194 !== undefined && match$195 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "removed the property bundle ", React.createElement("b", undefined, match$194.name), " from the event ", React.createElement("b", undefined, match$195.name)),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "UpdateMetricDescription") {
            var metric$4 = StateUtils.getMetricByIdWithArchive(match.VAL[0], modelBeforeAction);
            if (metric$4 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "updated the description of the metric ", React.createElement("b", undefined, metric$4.name)),
                          path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "RemoveMetricFromGoal") {
            var match$196 = match.VAL;
            var match$197 = StateUtils.getGoalByIdWithArchive(match$196[0], modelBeforeAction);
            var match$198 = StateUtils.getMetricByIdWithArchive(match$196[1], modelBeforeAction);
            if (match$197 !== undefined && match$198 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "removed the metric ", React.createElement("b", undefined, match$198.name), " from the category ", React.createElement("b", undefined, match$197.name)),
                          path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "ConfigureInspectorValidation") {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: "configured the Inspector validation",
                        openBranches: openBranches
                      });
          }
          if (variant === "AddPropertyToGroup") {
            var match$199 = match.VAL;
            var match$200 = StateUtils.getPropertyGroupByIdWithArchive(match$199[0], modelBeforeAction);
            var match$201 = StateUtils.getPropertyByIdWithArchive(match$199[1], modelBeforeAction);
            if (match$200 !== undefined && match$201 !== undefined && match$201.TAG !== /* PropertyRef */0) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "added the property ", React.createElement("b", undefined, match$201._0.name), " to the property bundle ", React.createElement("b", undefined, match$200.name)),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "UpdateDestinationDevApiKey") {
            var destinationId$6 = match.VAL[0];
            var destination$8 = StateUtils.getDestinationByIdWithArchive(destinationId$6, modelBeforeAction);
            if (destination$8 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "updated the dev api key of the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(destination$8))),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "destination",
                                VAL: destinationId$6
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "UpdateLibraryName") {
            var match$202 = match.VAL;
            var sourceId$14 = match$202[0];
            var source$8 = StateUtils.getSourceByIdWithArchive(sourceId$14, modelBeforeAction);
            if (source$8 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "updated the library name of the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$8)), " to ", React.createElement("b", undefined, match$202[1])),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "source",
                                VAL: [
                                  sourceId$14,
                                  "overview"
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "CloseBranch") {
            var match$203 = match.VAL;
            var branchId$4 = match$203[0];
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "deleted the branch ", React.createElement("b", undefined, match$203[1])),
                        path: Router.Link.getSchemaRouteLink({
                              NAME: "branch",
                              VAL: branchId$4
                            }, {
                              NAME: "diff",
                              VAL: "index"
                            }),
                        openBranches: openBranches,
                        onClick: (function (param) {
                            return AnalyticsRe.branchLinkClicked(schemaGroup, branchId$4, "BranchesOverviewActivityItem");
                          })
                      });
          }
          if (variant === "RemoveEventFromMetric") {
            var metric$5 = StateUtils.getMetricByIdWithArchive(match.VAL[0], modelBeforeAction);
            if (metric$5 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "removed the event from the metric ", React.createElement("b", undefined, metric$5.name)),
                          path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "RemovePropertyPinnedValue") {
            var match$204 = match.VAL;
            var eventId$20 = match$204[0];
            var match$205 = StateUtils.getEventById(eventId$20, modelBeforeAction);
            var match$206 = StateUtils.getPropertyById(match$204[1], modelBeforeAction);
            if (match$205 !== undefined && match$206 !== undefined && match$206.TAG !== /* PropertyRef */0) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "removed pinned value from ", React.createElement("b", undefined, match$206._0.name), " on ", React.createElement("b", undefined, match$205.name)),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "event",
                                VAL: [
                                  eventId$20,
                                  undefined,
                                  undefined,
                                  false
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "UpdateDestinationProdApiKey") {
            var destinationId$7 = match.VAL[0];
            var destination$9 = StateUtils.getDestinationByIdWithArchive(destinationId$7, modelBeforeAction);
            if (destination$9 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "updated the prod api key of the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(destination$9))),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "destination",
                                VAL: destinationId$7
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "AutoPublishIntegration") {
            var match$207 = match.VAL;
            var integrationId$1 = match$207[0];
            if (match$207[1]) {
              var integration$9 = StateUtils.getIntegrationById(integrationId$1, modelBeforeAction);
              if (integration$9 !== undefined) {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: [],
                            message: React.createElement(React.Fragment, undefined, "Auto publish for the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$9)), " succeeded"),
                            icon: React.createElement(IntegrationIcon.make, {
                                  config: integration$9.config,
                                  size: 22
                                }),
                            path: Router.Link.addDrawerItem(undefined, {
                                  NAME: "integration",
                                  VAL: [
                                    integration$9.id,
                                    undefined
                                  ]
                                }),
                            openBranches: openBranches
                          });
              } else {
                return null;
              }
            }
            var integration$10 = StateUtils.getIntegrationById(integrationId$1, modelBeforeAction);
            if (integration$10 === undefined) {
              return null;
            }
            var match$208 = integration$10.config;
            var exit$2 = 0;
            if (match$208 !== undefined) {
              if (match$208.TAG === /* Taxonomy */1) {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: [],
                            message: React.createElement(React.Fragment, undefined, "Auto publish for the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$10)), " completed with errors"),
                            icon: React.createElement(IntegrationIcon.make, {
                                  config: integration$10.config,
                                  size: 22
                                }),
                            path: Router.Link.addDrawerItem(undefined, {
                                  NAME: "integration",
                                  VAL: [
                                    integration$10.id,
                                    undefined
                                  ]
                                }),
                            openBranches: openBranches
                          });
              }
              exit$2 = 2;
            } else {
              exit$2 = 2;
            }
            if (exit$2 === 2) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: [],
                          message: React.createElement(React.Fragment, undefined, "Auto publish for the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$10)), " failed"),
                          icon: React.createElement(IntegrationIcon.make, {
                                config: integration$10.config,
                                size: 22
                              }),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "integration",
                                VAL: [
                                  integration$10.id,
                                  undefined
                                ]
                              }),
                          openBranches: openBranches
                        });
            }
            
          } else {
            if (variant === "RemoveEventTrigger") {
              var match$209 = StateUtils.getEventByIdWithArchive(match.VAL[0], modelBeforeAction);
              if (match$209 !== undefined) {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, "removed a trigger in ", React.createElement("b", undefined, match$209.name)),
                            openBranches: openBranches
                          });
              } else {
                return null;
              }
            }
            if (variant === "ReorderGoals") {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: "reordered the categories",
                          path: Router.Link.getSchemaRouteLink(undefined, "dashboard"),
                          openBranches: openBranches
                        });
            }
            if (variant === "CreateServiceAccount") {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "created the service account ", React.createElement("b", undefined, match.VAL)),
                          openBranches: openBranches,
                          onClick: (function (param) {
                              return Curry._1(globalSend, {
                                          TAG: /* OpenModal */4,
                                          _0: {
                                            NAME: "Settings",
                                            VAL: /* ServiceAccounts */4
                                          }
                                        });
                            })
                        });
            }
            if (variant === "RemoveGoal") {
              var goal$5 = StateUtils.getGoalByIdWithArchive(match.VAL, modelBeforeAction);
              if (goal$5 !== undefined) {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, "removed the category ", React.createElement("b", undefined, goal$5.name)),
                            path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                            openBranches: openBranches
                          });
              } else {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: "removed a category",
                            path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                            openBranches: openBranches
                          });
              }
            }
            if (variant === "RemoveTriggerSource") {
              var match$210 = match.VAL;
              var eventId$21 = match$210[0];
              var match$211 = StateUtils.getEventByIdWithArchive(eventId$21, modelBeforeAction);
              var match$212 = StateUtils.getSourceById(match$210[2], modelBeforeAction);
              if (match$211 !== undefined && match$212 !== undefined) {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, "removed ", React.createElement("b", undefined, AvoConfig.getSourceName(match$212)), " as a source from a trigger in ", React.createElement("b", undefined, match$211.name)),
                            path: Router.Link.addDrawerItem(undefined, {
                                  NAME: "event",
                                  VAL: [
                                    eventId$21,
                                    undefined,
                                    match$210[1],
                                    false
                                  ]
                                }),
                            openBranches: openBranches
                          });
              } else {
                return null;
              }
            }
            if (variant === "UpdatePropertyAbsence") {
              var match$213 = match.VAL;
              var maybeEventSourceAbsence = match$213[2];
              var propertyAbsenceUpdateType = match$213[1];
              var match$214 = StateUtils.getPropertyByIdWithArchive(match$213[0], modelBeforeAction);
              if (match$214 === undefined) {
                return null;
              }
              if (match$214.TAG === /* PropertyRef */0) {
                return null;
              }
              var tmp$62;
              if (typeof propertyAbsenceUpdateType === "number") {
                tmp$62 = React.createElement(React.Fragment, undefined, " on ", React.createElement("b", undefined, "all events"));
              } else {
                switch (propertyAbsenceUpdateType.TAG | 0) {
                  case /* Event */0 :
                      var $$event$10 = StateUtils.getEventByIdWithArchive(propertyAbsenceUpdateType._0, modelBeforeAction);
                      tmp$62 = $$event$10 !== undefined ? React.createElement(React.Fragment, undefined, " on the ", React.createElement("b", undefined, $$event$10.name), " event") : " on one event";
                      break;
                  case /* Source */1 :
                      var source$9 = StateUtils.getSourceByIdWithArchive(propertyAbsenceUpdateType._0, modelBeforeAction);
                      tmp$62 = source$9 !== undefined ? React.createElement(React.Fragment, undefined, " from the ", React.createElement("b", undefined, AvoConfig.getSourceName(source$9)), " source") : " from one source";
                      break;
                  case /* EventSource */2 :
                      var match$215 = StateUtils.getEventByIdWithArchive(propertyAbsenceUpdateType._0, modelBeforeAction);
                      var match$216 = StateUtils.getSourceByIdWithArchive(propertyAbsenceUpdateType._1, modelBeforeAction);
                      tmp$62 = match$215 !== undefined && match$216 !== undefined ? React.createElement(React.Fragment, undefined, " on the ", React.createElement("b", undefined, match$215.name), " event when sent from the ", React.createElement("b", undefined, AvoConfig.getSourceName(match$216)), " source") : " on one event and source";
                      break;
                  
                }
              }
              var tmp$63 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "set the property ", React.createElement("b", undefined, match$214._0.name), " to be ", React.createElement("b", undefined, maybeEventSourceAbsence !== undefined ? (
                            maybeEventSourceAbsence ? "sometimes sent" : "never sent"
                          ) : "always sent"), tmp$62),
                openBranches: openBranches
              };
              if (linkToPropertyFromContext !== undefined) {
                tmp$63.path = Caml_option.valFromOption(linkToPropertyFromContext);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$63);
            }
            if (variant === "Unarchive") {
              var match$217 = match.VAL;
              var variant$8 = match$217.NAME;
              if (variant$8 === "Destination") {
                var destinationId$8 = match$217.VAL;
                var destination$10 = StateUtils.getDestinationByIdWithArchive(destinationId$8, modelBeforeAction);
                if (destination$10 !== undefined) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "unarchived the destination ", React.createElement("b", undefined, StateUtils.getDestinationName(destination$10))),
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "destination",
                                    VAL: destinationId$8
                                  }),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              if (variant$8 === "Integration") {
                return null;
              }
              if (variant$8 === "GroupType") {
                var groupTypeName$2 = StateUtils.getGroupTypeNameWithArchive(match$217.VAL, modelBeforeAction);
                if (groupTypeName$2 !== undefined) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "unarchived the group type ", React.createElement("b", undefined, groupTypeName$2)),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              if (variant$8 === "Property") {
                var propertyId$8 = match$217.VAL;
                var match$218 = StateUtils.getPropertyByIdWithArchive(propertyId$8, modelBeforeAction);
                if (match$218 !== undefined && match$218.TAG !== /* PropertyRef */0) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "unarchived the property ", React.createElement("b", undefined, match$218._0.name)),
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "property",
                                    VAL: [
                                      propertyId$8,
                                      undefined
                                    ]
                                  }),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              if (variant$8 === "Event") {
                var eventId$22 = match$217.VAL;
                var $$event$11 = StateUtils.getEventByIdWithArchive(eventId$22, modelBeforeAction);
                if ($$event$11 !== undefined) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "unarchived the event ", React.createElement("b", undefined, $$event$11.name)),
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "event",
                                    VAL: [
                                      eventId$22,
                                      undefined,
                                      undefined,
                                      false
                                    ]
                                  }),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              if (variant$8 === "PropertyGroup") {
                var groupId$1 = match$217.VAL;
                var group$3 = StateUtils.getPropertyGroupByIdWithArchive(groupId$1, modelBeforeAction);
                if (group$3 !== undefined) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "unarchived the property bundle ", React.createElement("b", undefined, group$3.name)),
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "propertyGroup",
                                    VAL: [
                                      groupId$1,
                                      undefined
                                    ]
                                  }),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              if (variant$8 === "Goal") {
                var goalId$5 = match$217.VAL;
                var goal$6 = StateUtils.getGoalByIdWithArchive(goalId$5, modelBeforeAction);
                if (goal$6 !== undefined) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "unarchived the category ", React.createElement("b", undefined, goal$6.name)),
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "category",
                                    VAL: [
                                      goalId$5,
                                      undefined
                                    ]
                                  }),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              if (variant$8 === "Metric") {
                var metricId$4 = match$217.VAL;
                var metric$6 = StateUtils.getMetricByIdWithArchive(metricId$4, modelBeforeAction);
                if (metric$6 !== undefined) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "unarchived the metric ", React.createElement("b", undefined, metric$6.name)),
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "metric",
                                    VAL: [
                                      metricId$4,
                                      undefined
                                    ]
                                  }),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              var sourceId$15 = match$217.VAL;
              var source$10 = StateUtils.getSourceByIdWithArchive(sourceId$15, modelBeforeAction);
              if (source$10 !== undefined) {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, "unarchived the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$10))),
                            path: Router.Link.addDrawerItem(undefined, {
                                  NAME: "source",
                                  VAL: [
                                    sourceId$15,
                                    "overview"
                                  ]
                                }),
                            openBranches: openBranches
                          });
              } else {
                return null;
              }
            }
            if (variant === "CompletedImport") {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: "successfully imported a tracking plan",
                          openBranches: openBranches
                        });
            }
            if (variant === "RemoveSourceConfig") {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: "removed config from source",
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "source",
                                VAL: [
                                  match.VAL[0],
                                  "overview"
                                ]
                              }),
                          openBranches: openBranches
                        });
            }
            if (variant === "RemoveRule") {
              var item$2 = match.VAL[1];
              if (typeof item$2 !== "object") {
                return null;
              }
              if (item$2.NAME !== "Property") {
                return null;
              }
              var match$219 = StateUtils.getPropertyByIdWithArchive(item$2.VAL, modelBeforeAction);
              if (match$219 !== undefined && match$219.TAG !== /* PropertyRef */0) {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, "removed a name mapping from the property ", React.createElement("b", undefined, match$219._0.name)),
                            openBranches: openBranches
                          });
              } else {
                return null;
              }
            }
            if (variant === "AddPropertyGroupToEvent") {
              var match$220 = match.VAL;
              var match$221 = StateUtils.getPropertyGroupByIdWithArchive(match$220[1], modelBeforeAction);
              var match$222 = StateUtils.getEventByIdWithArchive(match$220[0], modelBeforeAction);
              if (match$221 !== undefined && match$222 !== undefined) {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, "added the property bundle ", React.createElement("b", undefined, match$221.name), " to the event ", React.createElement("b", undefined, match$222.name)),
                            openBranches: openBranches
                          });
              } else {
                return null;
              }
            }
            var metric$7 = StateUtils.getMetricByIdWithArchive(match.VAL, modelBeforeAction);
            if (metric$7 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "removed the metric ", React.createElement("b", undefined, metric$7.name)),
                          path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
        }
      }
    }
  }
  var $$event$12 = StateUtils.getEventByIdWithArchive(eventId, modelBeforeAction);
  if ($$event$12 !== undefined) {
    return React.createElement(ActivityItem$ActivityItemAction, {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "set unique name of the event", " ", React.createElement("b", undefined, $$event$12.name), " to ", React.createElement("b", undefined, uniqueName)),
                path: Router.Link.addDrawerItem(undefined, {
                      NAME: "event",
                      VAL: [
                        eventId,
                        undefined,
                        undefined,
                        false
                      ]
                    }),
                openBranches: openBranches
              });
  } else {
    return null;
  }
}

var make = ActivityItem;

exports.ActivityItemAction = ActivityItemAction;
exports.ActivityItemComment = ActivityItemComment;
exports.getActionContext = getActionContext;
exports.unarchiveItemOptionBase = unarchiveItemOptionBase;
exports.make = make;
/* actionContainerStyles Not a pure module */
