// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Caml = require("rescript/lib/js/caml.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("./Icons.bs.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var Debounce = require("lodash/debounce");

function getNumberRange(from, to_) {
  var range = [];
  for(var i = from; i <= to_; ++i){
    range.push(/* Number */{
          _0: i
        });
  }
  return range;
}

function getRange(total, page, boundaries, siblings) {
  var totalPageNumbers = ((siblings << 1) + 3 | 0) + (boundaries << 1) | 0;
  if (totalPageNumbers >= total) {
    return getNumberRange(1, total);
  }
  var leftSiblingIndex = Caml.caml_int_max(page - siblings | 0, boundaries);
  var rightSiblingIndex = Caml.caml_int_min(page + siblings | 0, total - boundaries | 0);
  var shouldShowLeftDots = leftSiblingIndex > (boundaries + 2 | 0);
  var shouldShowRightDots = rightSiblingIndex < (total - (boundaries + 1 | 0) | 0);
  if (!shouldShowLeftDots && shouldShowRightDots) {
    var leftItemCount = ((siblings << 1) + boundaries | 0) + 2 | 0;
    return Belt_Array.concatMany([
                getNumberRange(1, leftItemCount),
                [/* Dots */0],
                getNumberRange(total - (boundaries - 1 | 0) | 0, total)
              ]);
  }
  if (!(shouldShowLeftDots && !shouldShowRightDots)) {
    return Belt_Array.concatMany([
                getNumberRange(1, boundaries),
                [/* Dots */0],
                getNumberRange(leftSiblingIndex, rightSiblingIndex),
                [/* Dots */0],
                getNumberRange((total - boundaries | 0) + 1 | 0, total)
              ]);
  }
  var rightItemCount = (boundaries + 1 | 0) + (siblings << 1) | 0;
  return Belt_Array.concatMany([
              getNumberRange(1, boundaries),
              [/* Dots */0],
              getNumberRange(total - rightItemCount | 0, total)
            ]);
}

function getState(total, page, show, onPaginate, setPage, pageData, boundaries, siblings) {
  return {
          range: getRange(total, page, boundaries, siblings),
          page: page,
          total: total,
          show: show,
          setPage: (function (p) {
              Belt_Option.forEach(onPaginate, (function (cb) {
                      return Curry._1(cb, undefined);
                    }));
              return Curry._1(setPage, (function (param) {
                            return p;
                          }));
            }),
          setPrevious: (function (param) {
              Belt_Option.forEach(onPaginate, (function (cb) {
                      return Curry._1(cb, undefined);
                    }));
              return Curry._1(setPage, (function (p) {
                            return Caml.caml_int_max(1, p - 1 | 0);
                          }));
            }),
          setNext: (function (param) {
              Belt_Option.forEach(onPaginate, (function (cb) {
                      return Curry._1(cb, undefined);
                    }));
              return Curry._1(setPage, (function (p) {
                            return Caml.caml_int_min(total, p + 1 | 0);
                          }));
            }),
          pageData: pageData
        };
}

function use(show, enabled, onPaginate, siblingsOpt, boundariesOpt, data) {
  var siblings = siblingsOpt !== undefined ? siblingsOpt : 2;
  var boundaries = boundariesOpt !== undefined ? boundariesOpt : 1;
  var match = React.useState(function () {
        return 1;
      });
  var setPage = match[1];
  var page = match[0];
  var zeroBasedPage = React.useMemo((function () {
          return page - 1 | 0;
        }), [page]);
  var show$1 = React.useMemo((function () {
          if (enabled) {
            return show;
          } else {
            return data.length;
          }
        }), [
        data,
        show,
        enabled
      ]);
  var total = React.useMemo((function () {
          if (enabled) {
            return Math.ceil(data.length / show$1) | 0;
          } else {
            return 1;
          }
        }), [
        data,
        enabled
      ]);
  var pageData = React.useMemo((function () {
          return Belt_Array.slice(data, Math.imul(show$1, zeroBasedPage), show$1);
        }), [
        data,
        show$1,
        page
      ]);
  var match$1 = React.useState(function () {
        return getState(total, page, show$1, onPaginate, setPage, pageData, boundaries, siblings);
      });
  var setPagination = match$1[1];
  React.useEffect((function () {
          Curry._1(setPage, (function (param) {
                  return 1;
                }));
          
        }), [data]);
  React.useEffect((function () {
          Curry._1(setPagination, (function (param) {
                  return getState(total, page, show$1, onPaginate, setPage, pageData, boundaries, siblings);
                }));
          
        }), [
        total,
        page,
        pageData
      ]);
  return match$1[0];
}

var buttonStyle = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.borderRadius(Css.px(4)),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                tl: {
                  hd: Css.padding(Css.px(4)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(8)),
                    tl: {
                      hd: Css.paddingRight(Css.px(8)),
                      tl: {
                        hd: Css.marginRight(Css.px(4)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.white),
                          tl: {
                            hd: Css.selector(":disabled", {
                                  hd: Css.backgroundColor("transparent"),
                                  tl: {
                                    hd: Css.cursor("default"),
                                    tl: /* [] */0
                                  }
                                }),
                            tl: {
                              hd: Css.selector(":hover:not([disabled])", {
                                    hd: Css.backgroundColor(Styles.Color.grey10),
                                    tl: {
                                      hd: Css.borderColor(Styles.Color.grey40),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var dotsStyle = Curry._1(Css.style, {
      hd: Css.padding(Css.px(8)),
      tl: {
        hd: Css.marginTop(Css.px(-9)),
        tl: {
          hd: Css.marginRight(Css.px(3)),
          tl: /* [] */0
        }
      }
    });

function AvoTable$Pagination(Props) {
  var pagination = Props.pagination;
  if (pagination.total > 1) {
    return React.createElement(React.Fragment, undefined, React.createElement("button", {
                    className: buttonStyle,
                    disabled: pagination.page === 1,
                    onClick: (function (param) {
                        return Curry._1(pagination.setPrevious, undefined);
                      })
                  }, React.createElement(Icons.Arrow.make, {
                        size: 10,
                        direction: "Left",
                        color: pagination.page === 1 ? Styles.Color.grey50 : Styles.Color.grey70
                      })), Belt_Array.map(pagination.range, (function (rangeItem) {
                      if (!rangeItem) {
                        return React.createElement("div", {
                                    className: dotsStyle
                                  }, "...");
                      }
                      var pageNumber = rangeItem._0;
                      return React.createElement("button", {
                                  key: String(pageNumber),
                                  className: buttonStyle,
                                  disabled: pageNumber === pagination.page,
                                  onClick: (function (param) {
                                      return Curry._1(pagination.setPage, pageNumber);
                                    })
                                }, React.createElement($$Text.make, {
                                      weight: "Bold",
                                      color: Styles.Color.grey70,
                                      children: String(pageNumber)
                                    }));
                    })), React.createElement("button", {
                    className: buttonStyle,
                    disabled: pagination.page === pagination.total,
                    onClick: (function (param) {
                        return Curry._1(pagination.setNext, undefined);
                      })
                  }, React.createElement(Icons.Arrow.make, {
                        size: 10,
                        direction: "Right",
                        color: pagination.page === pagination.total ? Styles.Color.grey50 : Styles.Color.grey70
                      })));
  } else {
    return null;
  }
}

var Pagination = {
  getNumberRange: getNumberRange,
  getRange: getRange,
  getState: getState,
  use: use,
  buttonStyle: buttonStyle,
  dotsStyle: dotsStyle,
  make: AvoTable$Pagination
};

function Make(Table) {
  var container = Curry._1(Css.style, {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.border(Css.px(1), "solid", Styles.Color.grey40),
          tl: {
            hd: Css.borderRadius(Css.px(4)),
            tl: /* [] */0
          }
        }
      });
  var table = Curry._1(Css.style, {
        hd: Css.width(Css.pct(100.0)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.white),
          tl: {
            hd: Css.selector(" th, td", {
                  hd: Css.textAlign("left"),
                  tl: {
                    hd: Css.borderRight(Css.px(1), "solid", Styles.Color.grey30),
                    tl: {
                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                      tl: {
                        hd: Css.borderCollapse("collapse"),
                        tl: /* [] */0
                      }
                    }
                  }
                }),
            tl: {
              hd: Css.selector(" thead tr:first-child th", {
                    hd: Css.borderTopWidth(Css.px(0)),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.selector(" thead tr:first-child th:first-child", {
                      hd: Css.borderTopLeftRadius(Css.px(4)),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.selector(" thead tr:first-child th:last-child", {
                        hd: Css.borderTopRightRadius(Css.px(4)),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.selector(" thead tr th:first-child", {
                          hd: Css.after({
                                hd: Css.position("absolute"),
                                tl: {
                                  hd: Css.unsafe("content", "''"),
                                  tl: {
                                    hd: Css.right(Css.px(-1)),
                                    tl: {
                                      hd: Css.top(Css.px(0)),
                                      tl: {
                                        hd: Css.height(Css.pct(100.0)),
                                        tl: {
                                          hd: Css.width(Css.px(1)),
                                          tl: {
                                            hd: Css.backgroundColor(Styles.Color.grey30),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.selector(" thead th:first-child", {
                            hd: Css.borderLeftWidth(Css.px(0)),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.selector(" thead th:last-child", {
                              hd: Css.borderRightWidth(Css.px(0)),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.selector(" tbody td:first-child", {
                                hd: Css.borderLeftWidth(Css.px(0)),
                                tl: {
                                  hd: Css.after({
                                        hd: Css.position("absolute"),
                                        tl: {
                                          hd: Css.unsafe("content", "''"),
                                          tl: {
                                            hd: Css.right(Css.px(-1)),
                                            tl: {
                                              hd: Css.top(Css.px(0)),
                                              tl: {
                                                hd: Css.height(Css.pct(100.0)),
                                                tl: {
                                                  hd: Css.width(Css.px(1)),
                                                  tl: {
                                                    hd: Css.backgroundColor(Styles.Color.grey30),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }),
                          tl: {
                            hd: Css.selector(" td:last-child", {
                                  hd: Css.borderRightWidth(Css.px(0)),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.selector(" tbody:last-child tr:last-child td", {
                                    hd: Css.borderBottomWidth(Css.px(0)),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.selector(" tbody td", {
                                      hd: Css.backgroundColor(Styles.Color.white),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.selector(" tfoot th:first-child", {
                                        hd: Css.borderLeftWidth(Css.px(0)),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.selector(" tfoot th:last-child", {
                                          hd: Css.borderRightWidth(Css.px(0)),
                                          tl: /* [] */0
                                        }),
                                    tl: {
                                      hd: Css.selector(" tfoot tr:first-child th", {
                                            hd: Css.borderTopWidth(Css.px(1)),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.selector(" tfoot tr:last-child th", {
                                              hd: Css.borderBottomWidth(Css.px(0)),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.selector(" tbody tr", {
                                                hd: Css.cursor("pointer"),
                                                tl: {
                                                  hd: Css.hover({
                                                        hd: Css.selector(" th, td", {
                                                              hd: Css.backgroundColor(Styles.Color.grey10),
                                                              tl: /* [] */0
                                                            }),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
  var thead = Curry._1(Css.style, {
        hd: Css.position("relative"),
        tl: {
          hd: Css.zIndex(2),
          tl: {
            hd: Css.selector(" th", /* [] */0),
            tl: /* [] */0
          }
        }
      });
  var headerCell = function (firstColumnSticky) {
    return Curry._1(Css.style, {
                hd: Css.backgroundColor(Styles.Color.grey20),
                tl: {
                  hd: Css.firstChild(firstColumnSticky ? ({
                            hd: Css.zIndex(2),
                            tl: {
                              hd: Css.position("sticky"),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: /* [] */0
                              }
                            }
                          }) : /* [] */0),
                  tl: /* [] */0
                }
              });
  };
  var header = Curry._1(Css.style, {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.height(Css.px(20)),
              tl: {
                hd: Css.width("fitContent"),
                tl: {
                  hd: Css.textTransform("uppercase"),
                  tl: {
                    hd: Css.padding(Css.px(16)),
                    tl: {
                      hd: Css.whiteSpace("nowrap"),
                      tl: {
                        hd: Css.zIndex(2),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
  var tbody = function (firstColumnSticky) {
    return Curry._1(Css.style, {
                hd: Css.selector(" tr td", {
                      hd: Css.height(Css.px(56)),
                      tl: {
                        hd: Css.maxHeight(Css.px(56)),
                        tl: {
                          hd: Css.padding(Css.px(8)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(16)),
                            tl: {
                              hd: Css.paddingRight(Css.px(16)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }),
                tl: {
                  hd: Css.selector(" tr td:first-child", firstColumnSticky ? ({
                            hd: Css.zIndex(1),
                            tl: {
                              hd: Css.position("sticky"),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: /* [] */0
                              }
                            }
                          }) : /* [] */0),
                  tl: /* [] */0
                }
              });
  };
  var pagination = Curry._1(Css.style, {
        hd: Css.width(Css.pct(100.0)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: /* [] */0
          }
        }
      });
  var Style = {
    container: container,
    table: table,
    thead: thead,
    headerCell: headerCell,
    header: header,
    tbody: tbody,
    pagination: pagination
  };
  var debounce200 = Debounce((function (fn, param) {
          return Curry._4(fn, param[0], param[1], param[2], param[3]);
        }), 200, {
        leading: false,
        trailing: true
      });
  var debounce500 = Debounce((function (fn, param) {
          return Curry._4(fn, param[0], param[1], param[2], param[3]);
        }), 500, {
        leading: false,
        trailing: true
      });
  var getTablePlacement = function (tableRef) {
    return Belt_Option.map(Caml_option.nullable_to_opt(tableRef.current), (function (tableRef) {
                  return [
                          tableRef.getBoundingClientRect().top,
                          tableRef.getBoundingClientRect().bottom
                        ];
                }));
  };
  var goingDownFn = function (headerRef, tableRef, stickyRef, goingUp) {
    var headerHeight = Belt_Option.map(Caml_option.nullable_to_opt(headerRef.current), (function (headerRef) {
            return headerRef.getBoundingClientRect().height;
          }));
    var match = getTablePlacement(tableRef);
    if (match !== undefined && headerHeight !== undefined && match[0] < 0 && match[1] >= headerHeight && !goingUp.contents) {
      Belt_Option.forEach(Caml_option.nullable_to_opt(headerRef.current), (function (headerRef) {
              headerRef.style.transition = "transform " + Pervasives.string_of_float(Styles.Duration.$$long) + "ms";
              headerRef.style.transform = "translateY(" + Math.abs(match[0]).toString() + "px)";
              
            }));
      return Belt_Option.forEach(Caml_option.nullable_to_opt(stickyRef.current), (function (stickyRef) {
                    stickyRef.style.transition = "transform " + Pervasives.string_of_float(Styles.Duration.$$long) + "ms";
                    stickyRef.style.transform = "translateY(" + Math.abs(match[0]).toString() + "px)";
                    
                  }));
    }
    
  };
  var goingUpFn = function (headerRef, tableRef, stickyRef, goingUp) {
    var headerHeight = Belt_Option.map(Caml_option.nullable_to_opt(headerRef.current), (function (headerRef) {
            return headerRef.getBoundingClientRect().height;
          }));
    var match = getTablePlacement(tableRef);
    if (match !== undefined && headerHeight !== undefined && match[0] < 0 && match[1] >= headerHeight && goingUp.contents) {
      Belt_Option.forEach(Caml_option.nullable_to_opt(headerRef.current), (function (headerRef) {
              headerRef.style.transition = "transform " + Pervasives.string_of_float(Styles.Duration.$$long) + "ms";
              headerRef.style.transform = "translateY(" + Math.abs(match[0]).toString() + "px)";
              
            }));
      return Belt_Option.forEach(Caml_option.nullable_to_opt(stickyRef.current), (function (stickyRef) {
                    stickyRef.style.transition = "transform " + Pervasives.string_of_float(Styles.Duration.$$long) + "ms";
                    stickyRef.style.transform = "translateY(" + Math.abs(match[0]).toString() + "px)";
                    
                  }));
    }
    
  };
  var use$1 = function (tableRef, headerRef, stickyRef, enabled) {
    var prevPosition = {
      contents: Belt_Option.map(Caml_option.nullable_to_opt(tableRef.current), (function (tableRef) {
              return tableRef.getBoundingClientRect().top;
            }))
    };
    var goingUp = {
      contents: false
    };
    React.useEffect((function () {
            var scrollHandler = function (param) {
              var currentPosition = Belt_Option.map(Caml_option.nullable_to_opt(tableRef.current), (function (tableRef) {
                      return tableRef.getBoundingClientRect().top;
                    }));
              if (Caml_obj.caml_lessthan(currentPosition, prevPosition.contents)) {
                goingUp.contents = false;
              } else {
                goingUp.contents = true;
              }
              prevPosition.contents = currentPosition;
              if (goingUp.contents === true) {
                Belt_Option.forEach(Caml_option.nullable_to_opt(headerRef.current), (function (headerRef) {
                        headerRef.style.transition = "transform 0ms";
                        headerRef.style.transform = "translateY(0px)";
                        
                      }));
                Belt_Option.forEach(Caml_option.nullable_to_opt(stickyRef.current), (function (stickyRef) {
                        stickyRef.style.transition = "transform 0ms";
                        stickyRef.style.transform = "translateY(0px)";
                        
                      }));
              }
              debounce200(goingDownFn, [
                    headerRef,
                    tableRef,
                    stickyRef,
                    goingUp
                  ]);
              return debounce500(goingUpFn, [
                          headerRef,
                          tableRef,
                          stickyRef,
                          goingUp
                        ]);
            };
            if (enabled) {
              window.addEventListener("scroll", scrollHandler);
            }
            return (function (param) {
                      if (enabled) {
                        window.removeEventListener("scroll", scrollHandler);
                        return ;
                      }
                      
                    });
          }), [
          tableRef,
          headerRef
        ]);
    
  };
  var StickyHeader = {
    debounce200: debounce200,
    debounce500: debounce500,
    getTablePlacement: getTablePlacement,
    goingDownFn: goingDownFn,
    goingUpFn: goingUpFn,
    use: use$1
  };
  var AvoTable$Make$SafariStickyColumnHack = function (Props) {
    var header$1 = Props.header;
    var headerSorted = Props.headerSorted;
    var isSortable = Props.isSortable;
    var isSortedBy = Props.isSortedBy;
    var order = Props.order;
    var setHeaderSorted = Props.setHeaderSorted;
    var setOrder = Props.setOrder;
    var setSortBy = Props.setSortBy;
    var stickyRef = Props.stickyRef;
    var optionalHeader = Props.optionalHeader;
    var w = Props.width;
    var tmp = {
      className: Curry._1(Css.merge, {
            hd: header,
            tl: {
              hd: Curry._1(Css.style, {
                    hd: Css.height(Css.px(35)),
                    tl: {
                      hd: Css.padding(Css.px(16)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.grey20),
                        tl: {
                          hd: Css.selector(" span", {
                                hd: Css.textTransform("uppercase"),
                                tl: /* [] */0
                              }),
                          tl: {
                            hd: Css.borderTopLeftRadius(Css.px(4)),
                            tl: {
                              hd: Css.width(Css.px(w)),
                              tl: {
                                hd: Css.cursor(isSortable ? "pointer" : "default"),
                                tl: {
                                  hd: Css.borderRight(Css.px(1), "solid", Styles.Color.grey30),
                                  tl: {
                                    hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                                    tl: {
                                      hd: Css.hover(isSortable ? ({
                                                hd: Css.selector(" span", {
                                                      hd: Css.color(Styles.Color.grey80),
                                                      tl: /* [] */0
                                                    }),
                                                tl: {
                                                  hd: Css.selector(" path", {
                                                        hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey80),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }) : /* [] */0),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: /* [] */0
            }
          }),
      disabled: !isSortable
    };
    var tmp$1 = isSortable ? (function (param) {
          if (Caml_obj.caml_equal(headerSorted, header$1.name)) {
            return Curry._1(setOrder, (function (currentOrder) {
                          if (currentOrder === /* Asc */0) {
                            return /* Desc */1;
                          } else {
                            return /* Asc */0;
                          }
                        }));
          } else {
            Curry._1(setHeaderSorted, (function (param) {
                    return header$1.name;
                  }));
            Curry._1(setSortBy, (function (param) {
                    return Caml_option.undefined_to_opt(header$1.sortBy);
                  }));
            return Curry._1(setOrder, (function (param) {
                          return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(header$1.defaultOrder), /* Asc */0);
                        }));
          }
        }) : undefined;
    if (tmp$1 !== undefined) {
      tmp.onClick = Caml_option.valFromOption(tmp$1);
    }
    return React.createElement("div", {
                ref: stickyRef,
                className: Curry._1(Css.style, {
                      hd: Css.position("absolute"),
                      tl: {
                        hd: Css.zIndex(2),
                        tl: /* [] */0
                      }
                    })
              }, Belt_Option.mapWithDefault(optionalHeader, null, (function (headerAbove) {
                      return React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.borderBottom(Css.px(2), "solid", Styles.Color.grey30),
                                        tl: {
                                          hd: Css.selector(" tr", {
                                                hd: Css.display("flex"),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }
                                      })
                                }, headerAbove);
                    })), React.createElement("div", tmp, React.createElement($$Text.make, {
                        element: "Span",
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.grey70,
                        children: header$1.name
                      }), isSortedBy ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement(Icons.Arrow.make, {
                              size: 9,
                              direction: order === /* Asc */0 ? "Down" : "Up",
                              color: Styles.Color.grey70
                            })) : React.createElement(Spacer.make, {
                          width: 18
                        })));
  };
  var SafariStickyColumnHack = {
    make: AvoTable$Make$SafariStickyColumnHack
  };
  var AvoTable$Make$Cellspacing = function (Props) {
    var children = Props.children;
    return React.cloneElement(children, {
                cellSpacing: "0"
              });
  };
  var Cellspacing = {
    make: AvoTable$Make$Cellspacing
  };
  var AvoTable$Make = function (Props) {
    var paginate = Props.paginate;
    var paginationBoundariesOpt = Props.paginationBoundaries;
    var paginationSiblingsOpt = Props.paginationSiblings;
    var sortable = Props.sortable;
    var firstColumnSticky = Props.firstColumnSticky;
    var optionalHeader = Props.optionalHeader;
    var headerSticky = Props.headerSticky;
    var footer = Props.footer;
    var showOpt = Props.show;
    var data = Props.data;
    var children = Props.children;
    var paginationBoundaries = paginationBoundariesOpt !== undefined ? paginationBoundariesOpt : 1;
    var paginationSiblings = paginationSiblingsOpt !== undefined ? paginationSiblingsOpt : 2;
    var show = showOpt !== undefined ? showOpt : 10;
    var match = React.useState(function () {
          return Belt_Option.flatMap(Belt_Array.getBy(Table.headers, (function (header) {
                            return Belt_Option.isSome(Caml_option.undefined_to_opt(header.defaultSortedColumn));
                          })), (function (header) {
                        return Caml_option.undefined_to_opt(header.sortBy);
                      }));
        });
    var setSortBy = match[1];
    var sortBy = match[0];
    var match$1 = React.useState(function () {
          return Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.getBy(Table.headers, (function (header) {
                                return Belt_Option.isSome(Caml_option.undefined_to_opt(header.defaultSortedColumn));
                              })), (function (header) {
                            return Caml_option.undefined_to_opt(header.defaultOrder);
                          })), /* Asc */0);
        });
    var setOrder = match$1[1];
    var order = match$1[0];
    var match$2 = React.useState(function () {
          return Belt_Option.map(Belt_Array.getBy(Table.headers, (function (header) {
                            return Belt_Option.isSome(Caml_option.undefined_to_opt(header.defaultSortedColumn));
                          })), (function (header) {
                        return header.name;
                      }));
        });
    var setHeaderSorted = match$2[1];
    var headerSorted = match$2[0];
    var sortedData = React.useMemo((function () {
            if (sortBy !== undefined) {
              return Belt_List.toArray(Belt_List.sort(Belt_List.fromArray(data), (function (a, b) {
                                if (order === /* Asc */0) {
                                  return Caml.caml_string_compare(Curry._1(sortBy, a), Curry._1(sortBy, b));
                                } else {
                                  return Caml.caml_string_compare(Curry._1(sortBy, b), Curry._1(sortBy, a));
                                }
                              })));
            } else {
              return data;
            }
          }), [
          data,
          sortBy,
          order
        ]);
    var tableRef = React.useRef(null);
    var headerRef = React.useRef(null);
    var stickyRef = React.useRef(null);
    use$1(tableRef, headerRef, stickyRef, headerSticky);
    var isSafari = window.navigator.userAgent.indexOf("Safari") !== -1 && window.navigator.userAgent.indexOf("Chrome") === -1;
    var onPaginate = function (param) {
      return Belt_Option.forEach(Caml_option.nullable_to_opt(tableRef.current), (function (tableRef) {
                    var top = tableRef.getBoundingClientRect().top;
                    window.scrollBy(0, top - 50 | 0);
                    
                  }));
    };
    var pagination$1 = use(show, paginate, onPaginate, paginationSiblings, paginationBoundaries, sortedData);
    var header$1 = Belt_Array.get(Table.headers, 0);
    var tmp;
    if (header$1 !== undefined) {
      var header$2 = Caml_option.valFromOption(header$1);
      tmp = isSafari && firstColumnSticky && Belt_Option.isSome(Caml_option.undefined_to_opt(header$2.width)) ? React.createElement(AvoTable$Make$SafariStickyColumnHack, {
              header: header$2,
              headerSorted: headerSorted,
              isSortable: sortable && Belt_Option.isSome(Caml_option.undefined_to_opt(header$2.sortBy)),
              isSortedBy: Caml_obj.caml_equal(headerSorted, header$2.name),
              order: order,
              setHeaderSorted: setHeaderSorted,
              setOrder: setOrder,
              setSortBy: setSortBy,
              stickyRef: stickyRef,
              optionalHeader: optionalHeader,
              width: Belt_Option.getExn(Caml_option.undefined_to_opt(header$2.width))
            }) : null;
    } else {
      tmp = null;
    }
    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: container
                  }, tmp, React.createElement(AvoTable$Make$Cellspacing, {
                        children: React.createElement("table", {
                              ref: tableRef,
                              className: table
                            }, React.createElement("tbody", {
                                  className: tbody(firstColumnSticky)
                                }, Belt_Array.map(pagination$1.pageData, Curry.__1(children))), Belt_Option.mapWithDefault(footer, null, (function (footer) {
                                    return React.createElement("tfoot", undefined, footer);
                                  })), React.createElement("thead", {
                                  ref: headerRef,
                                  className: thead
                                }, Belt_Option.getWithDefault(optionalHeader, null), React.createElement("tr", undefined, Belt_Array.map(Table.headers, (function (header$3) {
                                            var isSortable = sortable && Belt_Option.isSome(Caml_option.undefined_to_opt(header$3.sortBy));
                                            var isSortedBy = Caml_obj.caml_equal(headerSorted, header$3.name);
                                            var tmp = {
                                              className: Curry._1(Css.merge, {
                                                    hd: header,
                                                    tl: {
                                                      hd: Curry._1(Css.style, {
                                                            hd: Css.cursor(isSortable ? "pointer" : "default"),
                                                            tl: {
                                                              hd: Css.hover(isSortable ? ({
                                                                        hd: Css.selector(" span", {
                                                                              hd: Css.color(Styles.Color.grey80),
                                                                              tl: /* [] */0
                                                                            }),
                                                                        tl: {
                                                                          hd: Css.selector(" path", {
                                                                                hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey80),
                                                                                tl: /* [] */0
                                                                              }),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }) : /* [] */0),
                                                              tl: /* [] */0
                                                            }
                                                          }),
                                                      tl: /* [] */0
                                                    }
                                                  }),
                                              disabled: !isSortable
                                            };
                                            var tmp$1 = isSortable ? (function (param) {
                                                  if (Caml_obj.caml_equal(headerSorted, header$3.name)) {
                                                    return Curry._1(setOrder, (function (currentOrder) {
                                                                  if (currentOrder === /* Asc */0) {
                                                                    return /* Desc */1;
                                                                  } else {
                                                                    return /* Asc */0;
                                                                  }
                                                                }));
                                                  } else {
                                                    Curry._1(setHeaderSorted, (function (param) {
                                                            return header$3.name;
                                                          }));
                                                    Curry._1(setSortBy, (function (param) {
                                                            return Caml_option.undefined_to_opt(header$3.sortBy);
                                                          }));
                                                    return Curry._1(setOrder, (function (param) {
                                                                  return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(header$3.defaultOrder), /* Asc */0);
                                                                }));
                                                  }
                                                }) : undefined;
                                            if (tmp$1 !== undefined) {
                                              tmp.onClick = Caml_option.valFromOption(tmp$1);
                                            }
                                            return React.createElement("th", {
                                                        key: header$3.name,
                                                        className: Curry._1(Css.merge, {
                                                              hd: headerCell(firstColumnSticky),
                                                              tl: {
                                                                hd: Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(header$3.width), Curry._1(Css.style, /* [] */0), (function (w) {
                                                                        return Curry._1(Css.style, {
                                                                                    hd: Css.minWidth(Css.px(w)),
                                                                                    tl: /* [] */0
                                                                                  });
                                                                      })),
                                                                tl: /* [] */0
                                                              }
                                                            })
                                                      }, React.createElement("div", tmp, React.createElement($$Text.make, {
                                                                element: "Span",
                                                                size: "Small",
                                                                weight: "Semi",
                                                                color: Styles.Color.grey70,
                                                                children: header$3.name
                                                              }), isSortedBy ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                                                      width: 8
                                                                    }), React.createElement("div", {
                                                                      className: Curry._1(Css.style, {
                                                                            hd: Css.display("flex"),
                                                                            tl: {
                                                                              hd: Css.transform({
                                                                                    NAME: "rotate",
                                                                                    VAL: {
                                                                                      NAME: "deg",
                                                                                      VAL: order === /* Asc */0 ? 270.0 : 90.0
                                                                                    }
                                                                                  }),
                                                                              tl: /* [] */0
                                                                            }
                                                                          })
                                                                    }, React.createElement(Icons.ArrowLeft.make, {
                                                                          size: 9,
                                                                          color: Styles.Color.grey70
                                                                        }))) : React.createElement(Spacer.make, {
                                                                  width: 18
                                                                })));
                                          })))))
                      })), React.createElement(Spacer.make, {
                    height: 32
                  }), paginate ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                          className: pagination
                        }, React.createElement(AvoTable$Pagination, {
                              pagination: pagination$1
                            })), React.createElement(Spacer.make, {
                          height: 32
                        })) : null);
  };
  return {
          Style: Style,
          StickyHeader: StickyHeader,
          SafariStickyColumnHack: SafariStickyColumnHack,
          Cellspacing: Cellspacing,
          make: AvoTable$Make
        };
}

exports.Pagination = Pagination;
exports.Make = Make;
/* buttonStyle Not a pure module */
