// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function IconErrorHexagon(Props) {
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 14;
  return React.createElement("svg", {
              height: (size * 12.0 / 14.0).toString(),
              width: String(size),
              fill: "none",
              viewBox: "0 0 14 12",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M10.8767 0.496129C10.6934 0.188911 10.3569 0 9.99306 0H4.00694C3.64307 0 3.30659 0.188911 3.12325 0.496129L0.139361 5.49613C-0.0464535 5.80749 -0.0464536 6.19251 0.139361 6.50387L3.12325 11.5039C3.30659 11.8111 3.64307 12 4.00694 12H9.99306C10.3569 12 10.6934 11.8111 10.8767 11.5039L13.8606 6.50387C14.0465 6.19251 14.0465 5.80749 13.8606 5.49613L10.8767 0.496129ZM4.18662 5C4.04536 5 3.93085 5.11193 3.93085 5.25V6.75C3.93085 6.88807 4.04536 7 4.18662 7H9.81338C9.95464 7 10.0691 6.88807 10.0691 6.75V5.25C10.0691 5.11193 9.95464 5 9.81338 5H4.18662Z",
                  fill: "#ED5565",
                  fillRule: "evenodd"
                }));
}

var make = IconErrorHexagon;

exports.make = make;
/* react Not a pure module */
