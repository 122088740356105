// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Styles = require("./styles.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");

var context = React.createContext(function (param) {
      
    });

var provider = context.Provider;

function use(param) {
  return React.useContext(context);
}

function ComposableModal$Context(Props) {
  var onClose = Props.onClose;
  var children = Props.children;
  return React.createElement(provider, {
              value: onClose,
              children: children
            });
}

var Context = {
  context: context,
  provider: provider,
  use: use,
  make: ComposableModal$Context
};

function ComposableModal$Title(Props) {
  var children = Props.children;
  if (children.NAME === "string") {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.paddingBottom(Css.px(8)),
                      tl: /* [] */0
                    })
              }, React.createElement($$Text.make, {
                    element: "H1",
                    size: "Huge",
                    color: Styles.Color.grey80,
                    children: children.VAL
                  }));
  } else {
    return children.VAL;
  }
}

var Title = {
  make: ComposableModal$Title
};

function ComposableModal$Body(Props) {
  var children = Props.children;
  if (children.NAME === "string") {
    return React.createElement($$Text.make, {
                color: Styles.Color.grey80,
                children: children.VAL
              });
  } else {
    return children.VAL;
  }
}

var Body = {
  make: ComposableModal$Body
};

function ComposableModal$Buttons(Props) {
  var children = Props.children;
  var onClose = React.useContext(context);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("row"),
                      tl: {
                        hd: Css.justifyContent("flexEnd"),
                        tl: {
                          hd: Css.gap(Css.px(16)),
                          tl: {
                            hd: Css.paddingTop(Css.px(32)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, Belt_Array.map(children, (function (button) {
                    if (button.NAME === "primary") {
                      var match = button.VAL;
                      var onClick = match.onClick;
                      var label = match.label;
                      return React.createElement(Button.make, {
                                  label: label,
                                  onClick: (function (param) {
                                      return Curry._1(onClick, onClose);
                                    }),
                                  size: "large",
                                  key: label
                                });
                    }
                    var match$1 = button.VAL;
                    var onClick$1 = match$1.onClick;
                    var label$1 = match$1.label;
                    return React.createElement(Button.make, {
                                label: label$1,
                                onClick: (function (param) {
                                    return Curry._1(onClick$1, onClose);
                                  }),
                                size: "large",
                                style: "outline",
                                key: label$1
                              });
                  })));
}

var Buttons = {
  make: ComposableModal$Buttons
};

function modalStyles(customPaddingTop, customWidth) {
  return Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("column"),
                  tl: {
                    hd: Css.gap(Css.px(8)),
                    tl: {
                      hd: Css.justifyContent("stretch"),
                      tl: {
                        hd: Css.maxHeight(Css.pct(90)),
                        tl: {
                          hd: Css.boxShadow(Styles.Shadow.standard),
                          tl: {
                            hd: Css.borderRadius(Css.px(10)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.borderWidth(Css.px(0)),
                                tl: {
                                  hd: Css.width(Css.px(customWidth)),
                                  tl: {
                                    hd: Css.padding(Css.px(48)),
                                    tl: {
                                      hd: Css.paddingTop(Css.px(customPaddingTop)),
                                      tl: {
                                        hd: Css.wordWrap("breakWord"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function closeButtonContainerStyles(customPaddingTop) {
  return Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.top(Css.px(customPaddingTop - 6 | 0)),
                tl: {
                  hd: Css.right(Css.px(32)),
                  tl: /* [] */0
                }
              }
            });
}

function ComposableModal(Props) {
  var children = Props.children;
  var withCloseButtonOpt = Props.withCloseButton;
  var paddingTopOpt = Props.paddingTop;
  var customWidthOpt = Props.width;
  var onClose = Props.onClose;
  var withCloseButton = withCloseButtonOpt !== undefined ? withCloseButtonOpt : false;
  var paddingTop = paddingTopOpt !== undefined ? paddingTopOpt : 54;
  var customWidth = customWidthOpt !== undefined ? customWidthOpt : 400;
  return React.createElement(ComposableModal$Context, {
              onClose: onClose,
              children: React.createElement("div", {
                    className: modalStyles(paddingTop, customWidth)
                  }, withCloseButton ? React.createElement("div", {
                          className: closeButtonContainerStyles(paddingTop)
                        }, React.createElement(Button.make, {
                              icon: "close",
                              onClick: (function (param) {
                                  return Curry._1(onClose, undefined);
                                }),
                              size: "large",
                              style: "ghost"
                            })) : null, children)
            });
}

var make = ComposableModal;

exports.Context = Context;
exports.Title = Title;
exports.Body = Body;
exports.Buttons = Buttons;
exports.modalStyles = modalStyles;
exports.closeButtonContainerStyles = closeButtonContainerStyles;
exports.make = make;
/* context Not a pure module */
