// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

var make = React.forwardRef(function (Props, ref_) {
      var children = Props.children;
      var nonRelativeOpt = Props.nonRelative;
      var nonRelative = nonRelativeOpt !== undefined ? nonRelativeOpt : false;
      var match = React.useState(function () {
            return false;
          });
      var setIsOpen = match[1];
      var isOpen = match[0];
      var previousIsOpen = Hooks.usePrevious1(isOpen);
      var buttonRef = React.useRef(null);
      var child = children(isOpen, (function (param) {
              return Curry._1(setIsOpen, (function (param) {
                            return true;
                          }));
            }), (function (param) {
              return Curry._1(setIsOpen, (function (param) {
                            return false;
                          }));
            }), buttonRef);
      React.useEffect((function () {
              var match = buttonRef.current;
              if (previousIsOpen && !(isOpen || (match == null))) {
                match.focus();
              }
              
            }), [
            previousIsOpen,
            isOpen
          ]);
      Hooks.useKeys1({
            hd: "Escape",
            tl: /* [] */0
          }, (function (_key, $$event) {
              if (isOpen) {
                Curry._1(setIsOpen, (function (param) {
                        return false;
                      }));
                $$event.stopPropagation();
                return ;
              }
              
            }), undefined, [isOpen]);
      var tmp = {
        onClick: (function (param) {
            return Curry._1(setIsOpen, (function (param) {
                          return true;
                        }));
          })
      };
      var tmp$1 = Belt_Option.map((ref_ == null) ? undefined : Caml_option.some(ref_), (function (prim) {
              return prim;
            }));
      if (tmp$1 !== undefined) {
        tmp.ref = Caml_option.valFromOption(tmp$1);
      }
      return React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position(nonRelative ? "static" : "relative"),
                        tl: {
                          hd: Css.display("flex"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", tmp, child[0]), isOpen ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.zIndex(Styles.ZIndex.aboveAll),
                              tl: /* [] */0
                            })
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.position("fixed"),
                                  tl: {
                                    hd: Css.top(Css.px(0)),
                                    tl: {
                                      hd: Css.right(Css.px(0)),
                                      tl: {
                                        hd: Css.bottom(Css.px(0)),
                                        tl: {
                                          hd: Css.left(Css.px(0)),
                                          tl: {
                                            hd: Css.backgroundColor(Css.transparent),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }),
                            onClick: (function (param) {
                                return Curry._1(setIsOpen, (function (param) {
                                              return false;
                                            }));
                              })
                          }), child[1]) : null);
    });

exports.make = make;
/* make Not a pure module */
