// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var AvoInspector = require("avo-inspector");

var _map = {"Dev":"dev","Staging":"staging","Prod":"prod"};

function make(apiKey, env, version, param) {
  return new AvoInspector.AvoInspector({
              apiKey: apiKey,
              env: _map[env],
              version: version
            });
}

function trackSchemaFromEvent(prim0, prim1, prim2) {
  prim0.trackSchemaFromEvent(prim1, prim2);
  
}

exports.make = make;
exports.trackSchemaFromEvent = trackSchemaFromEvent;
/* avo-inspector Not a pure module */
