// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var InspectorImplementationStatus = require("../InspectorImplementationStatus.bs.js");

function encodeStatus(t) {
  return Json_encode.object_({
              hd: [
                "lastUpdated",
                t.lastUpdated.toString()
              ],
              tl: {
                hd: [
                  "implementationStatus",
                  InspectorImplementationStatus.encode(t.implementationStatus)
                ],
                tl: /* [] */0
              }
            });
}

function decodeStatus(json) {
  return {
          lastUpdated: new Date(Json_decode.field("lastUpdated", Json_decode.string, json)),
          implementationStatus: Json_decode.field("implementationStatus", InspectorImplementationStatus.decode, json)
        };
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "env",
                t.env
              ],
              tl: {
                hd: [
                  "status",
                  Json_encode.nullable(encodeStatus, t.status)
                ],
                tl: /* [] */0
              }
            });
}

function decode(json) {
  return {
          env: Json_decode.field("env", Json_decode.string, json),
          status: Json_decode.optional((function (param) {
                  return Json_decode.field("status", decodeStatus, param);
                }), json)
        };
}

exports.encodeStatus = encodeStatus;
exports.decodeStatus = decodeStatus;
exports.encode = encode;
exports.decode = decode;
/* No side effect */
