// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("./Icons.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Intercom = require("./externals/intercom.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var CopyButton = require("./CopyButton.bs.js");
var TextButton = require("./TextButton.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var LoadingCircle = require("./LoadingCircle.bs.js");
var InspectorStats = require("./inspector/InspectorStats.bs.js");
var InspectorViewApi = require("./inspector/InspectorViewApi.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var InspectorViewInstallDocsGo = require("./inspector/InspectorViewInstallDocsGo.bs.js");
var InspectorViewInstallDocsIos = require("./inspector/InspectorViewInstallDocsIos.bs.js");
var InspectorViewInstallDocsJava = require("./inspector/InspectorViewInstallDocsJava.bs.js");
var InspectorViewInstallDocsNode = require("./inspector/InspectorViewInstallDocsNode.bs.js");
var InspectorViewInstallDocsAndroid = require("./inspector/InspectorViewInstallDocsAndroid.bs.js");
var InspectorViewInstallDocsFlutter = require("./inspector/InspectorViewInstallDocsFlutter.bs.js");
var InspectorViewInstallDocsPosthog = require("./inspector/InspectorViewInstallDocsPosthog.bs.js");
var InspectorViewInstallDocsSegment = require("./inspector/InspectorViewInstallDocsSegment.bs.js");
var InspectorViewInstallDocsJsFamily = require("./inspector/InspectorViewInstallDocsJsFamily.bs.js");
var InspectorViewInstallDocsScriptTag = require("./inspector/InspectorViewInstallDocsScriptTag.bs.js");
var InspectorViewInstallDocsRudderStack = require("./inspector/InspectorViewInstallDocsRudderStack.bs.js");
var InspectorViewInstallDocsGtmWebTemplate = require("./inspector/InspectorViewInstallDocsGtmWebTemplate.bs.js");

var root = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(40)),
      tl: {
        hd: Css.paddingRight(Css.px(40)),
        tl: {
          hd: Css.paddingBottom(Css.px(40)),
          tl: {
            hd: Css.paddingLeft(Css.px(60)),
            tl: /* [] */0
          }
        }
      }
    });

var link = Curry._1(Css.style, {
      hd: Css.fontWeight("semiBold"),
      tl: {
        hd: Css.color(Styles.Color.blue),
        tl: {
          hd: Css.hover({
                hd: Css.color(Styles.Color.blueSecondary),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.textDecoration("none"),
            tl: /* [] */0
          }
        }
      }
    });

var apiKeyLoading = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, {
            hd: Css.height(Css.px(42)),
            tl: {
              hd: Css.width(Css.px(250)),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.marginLeft(Css.px(-9)),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                    tl: /* [] */0
                  }
                }
              }
            }
          }),
      tl: {
        hd: Curry._1(Css.style, Styles.shimmer),
        tl: /* [] */0
      }
    });

var apiKeyBox = Curry._1(Css.style, {
      hd: Css.width("fitContent"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.backgroundColor(Styles.Color.grey20),
              tl: {
                hd: Css.paddingTop(Css.px(4)),
                tl: {
                  hd: Css.paddingRight(Css.px(4)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(4)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(8)),
                      tl: {
                        hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.marginLeft(Css.px(-9)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var apiKey = Curry._1(Css.style, {
      hd: Css.margin(Css.px(0)),
      tl: {
        hd: Css.fontFamily(Styles.monoFontFamily),
        tl: {
          hd: Css.fontSize(Styles.FontSize.small),
          tl: {
            hd: Css.fontWeight(Styles.FontWeight.medium),
            tl: {
              hd: Css.color(Styles.Color.grey90),
              tl: {
                hd: Css.marginRight(Css.px(24)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var statusLoading = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, {
            hd: Css.height(Css.px(28)),
            tl: {
              hd: Css.width(Css.px(190)),
              tl: {
                hd: Css.borderRadius(Css.px(99)),
                tl: {
                  hd: Css.marginLeft(Css.px(-14)),
                  tl: /* [] */0
                }
              }
            }
          }),
      tl: {
        hd: Curry._1(Css.style, Styles.shimmer),
        tl: /* [] */0
      }
    });

var initializedPill = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("baseline"),
          tl: {
            hd: Css.width("fitContent"),
            tl: {
              hd: Css.background(Styles.Color.mintGreen),
              tl: {
                hd: Css.borderRadius(Css.px(99)),
                tl: {
                  hd: Css.paddingTop(Css.px(4)),
                  tl: {
                    hd: Css.paddingRight(Css.px(12)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(5)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(8)),
                        tl: {
                          hd: Css.marginLeft(Css.px(-14)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var waitingPill = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.width("fitContent"),
            tl: {
              hd: Css.background(Styles.Color.grey70),
              tl: {
                hd: Css.borderRadius(Css.px(99)),
                tl: {
                  hd: Css.paddingTop(Css.px(4)),
                  tl: {
                    hd: Css.paddingRight(Css.px(12)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(5)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(6)),
                        tl: {
                          hd: Css.marginLeft(Css.px(-14)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var docs = Curry._1(Css.style, {
      hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.borderRadius(Css.px(4)),
          tl: {
            hd: Css.marginTop(Css.px(4)),
            tl: {
              hd: Css.marginLeft(Css.px(-9)),
              tl: {
                hd: Css.overflow("hidden"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var docsNav = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.overflowX("scroll"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.justifyContent("flexStart"),
            tl: {
              hd: Css.backgroundColor(Styles.Color.grey20),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.height(Css.px(52)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var docsNavItemBase_0 = Css.height(Css.pct(100));

var docsNavItemBase_1 = {
  hd: Css.display("flex"),
  tl: {
    hd: Css.alignItems("center"),
    tl: {
      hd: Css.justifyContent("center"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.semi),
          tl: {
            hd: Css.lineHeight(Styles.LineHeight.regular),
            tl: {
              hd: Css.paddingTop(Css.px(2)),
              tl: {
                hd: Css.paddingRight(Css.px(16)),
                tl: {
                  hd: Css.paddingBottom(Css.px(0)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(16)),
                    tl: {
                      hd: Css.borderTopWidth(Css.px(0)),
                      tl: {
                        hd: Css.borderRightWidth(Css.px(1)),
                        tl: {
                          hd: Css.borderBottomWidth(Css.px(1)),
                          tl: {
                            hd: Css.borderLeftWidth(Css.px(0)),
                            tl: {
                              hd: Css.borderStyle("solid"),
                              tl: {
                                hd: Css.borderRightColor(Styles.Color.grey30),
                                tl: {
                                  hd: Css.margin(Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var docsNavItemBase = {
  hd: docsNavItemBase_0,
  tl: docsNavItemBase_1
};

var docsNavItem = Curry._1(Css.style, Belt_List.concatMany([
          Styles.button,
          docsNavItemBase,
          {
            hd: Css.color(Styles.Color.grey80),
            tl: {
              hd: Css.fontSize(Styles.FontSize.small),
              tl: {
                hd: Css.borderBottomColor(Styles.Color.grey30),
                tl: /* [] */0
              }
            }
          }
        ]));

var docsNavItemSelected = Curry._1(Css.style, Belt_List.concatMany([
          Styles.button,
          docsNavItemBase,
          {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.fontSize(Styles.FontSize.small),
              tl: {
                hd: Css.color(Styles.Color.grey90),
                tl: {
                  hd: Css.borderBottomColor("transparent"),
                  tl: /* [] */0
                }
              }
            }
          }
        ]));

var docsNavItemFiller = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
        tl: {
          hd: Css.alignSelf("stretch"),
          tl: /* [] */0
        }
      }
    });

var docsContent = Curry._1(Css.style, {
      hd: Css.paddingRight(Css.px(24)),
      tl: {
        hd: Css.paddingBottom(Css.px(24)),
        tl: {
          hd: Css.paddingLeft(Css.px(24)),
          tl: /* [] */0
        }
      }
    });

var Style = {
  root: root,
  link: link,
  apiKeyLoading: apiKeyLoading,
  apiKeyBox: apiKeyBox,
  apiKey: apiKey,
  statusLoading: statusLoading,
  initializedPill: initializedPill,
  waitingPill: waitingPill,
  docs: docs,
  docsNav: docsNav,
  docsNavItemBase: docsNavItemBase,
  docsNavItem: docsNavItem,
  docsNavItemSelected: docsNavItemSelected,
  docsNavItemFiller: docsNavItemFiller,
  docsContent: docsContent
};

var integrationProviders = {
  hd: /* Segment */1,
  tl: {
    hd: /* Rudderstack */0,
    tl: {
      hd: /* Posthog */3,
      tl: /* [] */0
    }
  }
};

function SourceConfigInspectorSetup(Props) {
  var currentBranch = Props.currentBranch;
  var model = Props.model;
  var onClose = Props.onClose;
  var source = Props.source;
  var inspectorStats = Props.inspectorStats;
  var handleTabChange = Props.handleTabChange;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = React.useState(function () {
        return /* Loading */0;
      });
  var setApiKey = match[1];
  var apiKeyState = match[0];
  var inspectorStats$1 = InspectorStats.useStatsWithPolling(workspace.id, typeof inspectorStats === "number" ? undefined : inspectorStats._0, undefined);
  var match$1 = React.useState(function () {
        return InspectorStats.getSourceStatus(model, source, inspectorStats$1);
      });
  var setStatus = match$1[1];
  var status = match$1[0];
  var match$2 = source.platform;
  var integrationProviders$1 = match$2 === "Web" ? Belt_List.add(Belt_List.add(integrationProviders, /* Tag */2), /* GoogleTagManager */4) : integrationProviders;
  var match$3 = React.useState(function () {
        if (Caml_obj.caml_equal(source.platform, "Web")) {
          return {
                  TAG: /* Stream */1,
                  _0: /* GoogleTagManager */4
                };
        }
        if (AvoUtils.hasSegmentDestination(model, source)) {
          return {
                  TAG: /* Stream */1,
                  _0: /* Segment */1
                };
        }
        var platform = source.platform;
        if (platform !== undefined && Belt_List.has(AvoConfig.inspectorSupportedDevPlatforms, platform, (function (prim0, prim1) {
                  return prim0 === prim1;
                }))) {
          return {
                  TAG: /* SDK */0,
                  _0: source.language === undefined ? Belt_List.head(AvoConfig.inspectorSupportedLanguages(source.platform)) : source.language
                };
        } else {
          return {
                  TAG: /* Stream */1,
                  _0: /* Tag */2
                };
        }
      });
  var setTab = match$3[1];
  var currentTab = match$3[0];
  React.useEffect((function () {
          Curry._1(setStatus, (function (param) {
                  return InspectorStats.getSourceStatus(model, source, inspectorStats$1);
                }));
          
        }), [inspectorStats$1]);
  React.useEffect((function () {
          Curry._1(setApiKey, (function (param) {
                  return /* Loading */0;
                }));
          $$Promise.$$catch(InspectorViewApi.getApiOrGenerateKey(workspace.id, source.id, workspace.name, AvoConfig.getSourceName(source)).then(function (apiKey) {
                    Curry._1(setApiKey, (function (param) {
                            return /* Success */{
                                    _0: apiKey
                                  };
                          }));
                    return Promise.resolve(undefined);
                  }), (function (error) {
                  console.log(error);
                  Curry._1(setApiKey, (function (param) {
                          return /* Error */1;
                        }));
                  return Promise.resolve(undefined);
                }));
          
        }), [
        workspace.id,
        source.id
      ]);
  var apiKeyWithPlaceholder = typeof apiKeyState === "number" ? "MY-API-KEY" : apiKeyState._0;
  var tmp;
  if (source.platform === undefined) {
    tmp = React.createElement($$Text.make, {
          size: "Medium",
          weight: "Medium",
          color: Styles.Color.grey90,
          children: null
        }, "Select a Development Platform from the ", React.createElement(TextButton.make, {
              onClick: (function (param) {
                  return Curry._1(handleTabChange, "overview");
                }),
              style: "Blue",
              children: "Overview tab"
            }), " to get started.");
  } else {
    var tmp$1;
    if (typeof apiKeyState === "number") {
      tmp$1 = apiKeyState !== 0 ? React.createElement($$Text.make, {
              size: "Medium",
              weight: "Semi",
              color: Styles.Color.grapeError,
              children: "Something went wrong when loading the Inspector Api key."
            }) : React.createElement("div", {
              className: apiKeyLoading
            });
    } else {
      var apiKey$1 = apiKeyState._0;
      tmp$1 = React.createElement("div", {
            className: apiKeyBox
          }, React.createElement("p", {
                className: apiKey
              }, apiKey$1), React.createElement(CopyButton.make, {
                copyString: apiKey$1
              }));
    }
    var tmp$2;
    switch (status) {
      case /* Loading */0 :
          tmp$2 = React.createElement("div", {
                className: statusLoading
              });
          break;
      case /* Unavailable */1 :
          var platformName = Belt_Option.mapWithDefault(source.platform, "unknown", AvoConfig.devPlatformLabel);
          tmp$2 = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                    size: "Medium",
                    color: Styles.Color.grey90,
                    children: "Avo does not provide an SDK for " + platformName + " yet. You can still use Segment to stream data to it."
                  }), React.createElement(Spacer.make, {
                    height: 8
                  }), React.createElement(Button.make, {
                    label: "Reach out to us",
                    onClick: (function (param) {
                        return Intercom.showNewMessage("");
                      })
                  }));
          break;
      case /* Available */2 :
          tmp$2 = React.createElement("div", {
                className: waitingPill
              }, React.createElement(LoadingCircle.make, {
                    size: 18,
                    color: Styles.Color.grey50
                  }), React.createElement(Spacer.make, {
                    width: 6
                  }), React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    color: Styles.Color.white,
                    children: "Listening for the first event…"
                  }));
          break;
      case /* Receiving */3 :
          tmp$2 = React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    })
              }, React.createElement("div", {
                    className: initializedPill
                  }, React.createElement(Icons.CheckmarkRounded.make, {
                        size: 12,
                        color: Styles.Color.white
                      }), React.createElement(Spacer.make, {
                        width: 4
                      }), React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Semi",
                        color: Styles.Color.white,
                        children: "Receiving events"
                      })), React.createElement(Spacer.make, {
                    width: 8
                  }), React.createElement(Link.make, {
                    path: Router.Link.getSchemaRouteLink(undefined, {
                          NAME: "inspector",
                          VAL: "events"
                        }),
                    children: React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.magenta,
                          children: "View in Inspector"
                        })
                  }));
          break;
      
    }
    var match$4 = source.platform;
    var tmp$3;
    var exit = 0;
    if (match$4 !== undefined) {
      if (match$4 === "ReactNative") {
        if (currentTab.TAG === /* SDK */0) {
          var match$5 = currentTab._0;
          if (match$5 !== undefined && (match$5 === "ts" || match$5 === "re_v2" || match$5 === "js_v2")) {
            exit = 2;
          } else {
            tmp$3 = null;
          }
        } else {
          exit = 1;
        }
      } else if (match$4 === "Android") {
        if (currentTab.TAG === /* SDK */0) {
          tmp$3 = React.createElement(InspectorViewInstallDocsAndroid.make, {
                apiKey: apiKeyWithPlaceholder,
                language: currentTab._0,
                onClose: onClose
              });
        } else {
          exit = 1;
        }
      } else if (match$4 === "Go") {
        if (currentTab.TAG === /* SDK */0) {
          var match$6 = currentTab._0;
          tmp$3 = match$6 === "go" ? React.createElement(InspectorViewInstallDocsGo.make, {
                  apiKey: apiKeyWithPlaceholder,
                  language: "go",
                  onClose: onClose
                }) : null;
        } else {
          exit = 1;
        }
      } else if (match$4 === "IOS") {
        if (currentTab.TAG === /* SDK */0) {
          tmp$3 = React.createElement(InspectorViewInstallDocsIos.make, {
                apiKey: apiKeyWithPlaceholder,
                onClose: onClose,
                language: currentTab._0
              });
        } else {
          exit = 1;
        }
      } else if (match$4 === "Web") {
        if (currentTab.TAG === /* SDK */0) {
          var match$7 = currentTab._0;
          if (match$7 !== undefined && (match$7 === "ts" || match$7 === "re_v2" || match$7 === "js_v2")) {
            exit = 2;
          } else {
            tmp$3 = null;
          }
        } else {
          exit = 1;
        }
      } else if (match$4 === "Expo") {
        if (currentTab.TAG === /* SDK */0) {
          var match$8 = currentTab._0;
          if (match$8 !== undefined && (match$8 === "ts" || match$8 === "re_v2" || match$8 === "js_v2")) {
            exit = 2;
          } else {
            tmp$3 = null;
          }
        } else {
          exit = 1;
        }
      } else if (match$4 === "Java") {
        if (currentTab.TAG === /* SDK */0) {
          tmp$3 = React.createElement(InspectorViewInstallDocsJava.make, {
                apiKey: apiKeyWithPlaceholder,
                language: currentTab._0,
                onClose: onClose
              });
        } else {
          exit = 1;
        }
      } else if (match$4 === "Node") {
        if (currentTab.TAG === /* SDK */0) {
          var match$9 = currentTab._0;
          tmp$3 = match$9 !== undefined && (match$9 === "ts" || match$9 === "re_v2" || match$9 === "js_v2") && currentTab.TAG === /* SDK */0 ? React.createElement(InspectorViewInstallDocsNode.make, {
                  apiKey: apiKeyWithPlaceholder,
                  language: currentTab._0,
                  onClose: onClose
                }) : null;
        } else {
          exit = 1;
        }
      } else if (match$4 === "Flutter" && currentTab.TAG === /* SDK */0) {
        var match$10 = currentTab._0;
        tmp$3 = match$10 !== undefined && match$10 === "dart" && currentTab.TAG === /* SDK */0 ? React.createElement(InspectorViewInstallDocsFlutter.make, {
                apiKey: apiKeyWithPlaceholder,
                onClose: onClose,
                language: currentTab._0
              }) : null;
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
    switch (exit) {
      case 1 :
          if (currentTab.TAG === /* SDK */0) {
            tmp$3 = null;
          } else {
            switch (currentTab._0) {
              case /* Rudderstack */0 :
                  tmp$3 = React.createElement(InspectorViewInstallDocsRudderStack.make, {
                        apiKey: apiKeyWithPlaceholder,
                        sourceGroup: AnalyticsRe.Group.source(source.id, AvoConfig.getSourceName(source)),
                        hasSeenEvents: status === /* Receiving */3
                      });
                  break;
              case /* Segment */1 :
                  tmp$3 = React.createElement(InspectorViewInstallDocsSegment.make, {
                        apiKey: apiKeyWithPlaceholder,
                        sourceGroup: AnalyticsRe.Group.source(source.id, AvoConfig.getSourceName(source)),
                        hasSeenEvents: status === /* Receiving */3
                      });
                  break;
              case /* Tag */2 :
                  tmp$3 = React.createElement(InspectorViewInstallDocsScriptTag.make, {
                        apiKey: apiKeyWithPlaceholder,
                        sourceGroup: AnalyticsRe.Group.source(source.id, AvoConfig.getSourceName(source)),
                        hasSeenEvents: status === /* Receiving */3,
                        schemaId: workspace.id,
                        currentBranch: currentBranch,
                        onClose: onClose
                      });
                  break;
              case /* Posthog */3 :
                  tmp$3 = React.createElement(InspectorViewInstallDocsPosthog.make, {
                        hasSeenEvents: status === /* Receiving */3
                      });
                  break;
              case /* GoogleTagManager */4 :
                  tmp$3 = React.createElement(InspectorViewInstallDocsGtmWebTemplate.make, {
                        apiKey: apiKeyWithPlaceholder,
                        hasSeenEvents: status === /* Receiving */3
                      });
                  break;
              
            }
          }
          break;
      case 2 :
          tmp$3 = currentTab.TAG === /* SDK */0 ? React.createElement(InspectorViewInstallDocsJsFamily.make, {
                  apiKey: apiKeyWithPlaceholder,
                  language: currentTab._0,
                  platform: source.platform,
                  onClose: onClose
                }) : null;
          break;
      
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.grey70,
              children: "API key"
            }), React.createElement(Spacer.make, {
              height: 4
            }), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.height(Css.px(42)),
                    tl: /* [] */0
                  })
            }, tmp$1), React.createElement(Spacer.make, {
              height: 24
            }), React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.grey70,
              children: "Setup Status"
            }), React.createElement(Spacer.make, {
              height: 4
            }), tmp$2, React.createElement(Spacer.make, {
              height: 24
            }), React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.grey70,
              children: "Documentation"
            }), React.createElement(Spacer.make, {
              height: 2
            }), React.createElement("div", {
              className: docs
            }, React.createElement("div", {
                  className: docsNav
                }, Belt_List.toArray(Belt_List.map(integrationProviders$1, (function (provider) {
                            var tmp;
                            switch (provider) {
                              case /* Rudderstack */0 :
                                  tmp = "RudderStack";
                                  break;
                              case /* Segment */1 :
                                  tmp = "Segment";
                                  break;
                              case /* Tag */2 :
                                  tmp = "Script Tag";
                                  break;
                              case /* Posthog */3 :
                                  tmp = "PostHog";
                                  break;
                              case /* GoogleTagManager */4 :
                                  tmp = "Google Tag Manager";
                                  break;
                              
                            }
                            var tmp$1;
                            switch (provider) {
                              case /* Rudderstack */0 :
                                  tmp$1 = React.createElement(React.Fragment, undefined, "RudderStack", React.createElement($$Text.make, {
                                            element: "Span",
                                            size: "Small",
                                            weight: "Regular",
                                            color: Styles.Color.grey70,
                                            children: "Stream"
                                          }));
                                  break;
                              case /* Segment */1 :
                                  tmp$1 = React.createElement(React.Fragment, undefined, "Segment", React.createElement($$Text.make, {
                                            element: "Span",
                                            size: "Small",
                                            weight: "Regular",
                                            color: Styles.Color.grey70,
                                            children: "Stream"
                                          }));
                                  break;
                              case /* Tag */2 :
                                  tmp$1 = React.createElement(React.Fragment, undefined, "HTML", React.createElement($$Text.make, {
                                            element: "Span",
                                            size: "Small",
                                            weight: "Regular",
                                            color: Styles.Color.grey70,
                                            children: "Script"
                                          }));
                                  break;
                              case /* Posthog */3 :
                                  tmp$1 = React.createElement(React.Fragment, undefined, "PostHog", React.createElement($$Text.make, {
                                            element: "Span",
                                            size: "Small",
                                            weight: "Regular",
                                            color: Styles.Color.grey70,
                                            children: "Stream"
                                          }));
                                  break;
                              case /* GoogleTagManager */4 :
                                  tmp$1 = React.createElement(React.Fragment, undefined, "GTM", React.createElement($$Text.make, {
                                            element: "Span",
                                            size: "Small",
                                            weight: "Regular",
                                            color: Styles.Color.grey70,
                                            children: "Tag"
                                          }));
                                  break;
                              
                            }
                            return React.createElement("button", {
                                        key: tmp,
                                        className: Caml_obj.caml_equal({
                                              TAG: /* Stream */1,
                                              _0: provider
                                            }, currentTab) ? docsNavItemSelected : docsNavItem,
                                        onClick: (function (param) {
                                            return Curry._1(setTab, (function (param) {
                                                          return {
                                                                  TAG: /* Stream */1,
                                                                  _0: provider
                                                                };
                                                        }));
                                          })
                                      }, tmp$1);
                          }))), Belt_List.toArray(Belt_List.map(AvoConfig.inspectorSupportedLanguages(source.platform), (function (thisLanguage) {
                            var tmp;
                            if (currentTab.TAG === /* SDK */0) {
                              var currentTab$1 = currentTab._0;
                              tmp = currentTab$1 !== undefined && currentTab$1 === thisLanguage ? docsNavItemSelected : docsNavItem;
                            } else {
                              tmp = docsNavItem;
                            }
                            return React.createElement("button", {
                                        key: AvoConfig.languageLabel(thisLanguage),
                                        className: tmp,
                                        onClick: (function (param) {
                                            return Curry._1(setTab, (function (param) {
                                                          return {
                                                                  TAG: /* SDK */0,
                                                                  _0: thisLanguage
                                                                };
                                                        }));
                                          })
                                      }, AvoConfig.languageLabel(thisLanguage), React.createElement($$Text.make, {
                                            element: "Span",
                                            size: "Small",
                                            weight: "Regular",
                                            color: Styles.Color.grey70,
                                            children: "SDK"
                                          }));
                          }))), React.createElement("div", {
                      className: docsNavItemFiller
                    })), React.createElement("div", {
                  className: docsContent
                }, tmp$3)));
  }
  return React.createElement("div", {
              className: root
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.grey70,
                  maxWidth: Css.px(500),
                  children: null
                }, "Avo Inspector analyzes your current state of tracking and guides the way from your current messy taxonomy to more consistent and reliable tracking across teams, products, and platforms. See ", React.createElement("a", {
                      className: link,
                      href: "https://www.avo.app/docs/data-design/start-using-inspector",
                      rel: "noopener noreferrer",
                      target: "_blank"
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "the docs"
                        })), " for more info."), React.createElement(Spacer.make, {
                  height: 24
                }), tmp);
}

var make = SourceConfigInspectorSetup;

exports.Style = Style;
exports.integrationProviders = integrationProviders;
exports.make = make;
/* root Not a pure module */
