// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Icon = require("./Icon.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Select = require("./Select.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var PortalMenu = require("./PortalMenu.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var PortalMenuAnimator = require("./PortalMenuAnimator.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");

var _map = {"Always":"Always sent","Sometimes":"Sometimes sent","BySource":"Sent depending on source","Never":"Never sent"};

var _revMap = {"Always sent":"Always","Sometimes sent":"Sometimes","Sent depending on source":"BySource","Never sent":"Never"};

function absenceModeToJs(param) {
  return _map[param];
}

function absenceModeFromJs(param) {
  return _revMap[param];
}

var sourceStates = {
  hd: "Always",
  tl: {
    hd: "Sometimes",
    tl: {
      hd: "Never",
      tl: /* [] */0
    }
  }
};

function getSimplestAbsenceModeByEvent(absence, sources, $$event, propertySendAs) {
  var match = AvoConfig.determineSendingToSources(sources, $$event);
  var eventSources = typeof match === "object" ? match.VAL : (
      match === "None" ? /* [] */0 : sources
    );
  var eventId = propertySendAs === /* SystemProperty */0 ? TrackingPlanModel.propertyAbsenceAllEventsKey : $$event.id;
  if (typeof absence === "number") {
    return "Always";
  }
  if (absence.TAG === /* SometimesSent */0) {
    return "Sometimes";
  }
  var match$1 = Belt_MapString.get(absence._0, eventId);
  if (match$1 === undefined) {
    return "Always";
  }
  if (match$1.TAG === /* AllSources */0) {
    if (match$1._0) {
      return "Sometimes";
    } else {
      return "Never";
    }
  }
  var sourceAbsences = match$1._0;
  var states = Belt_List.map(eventSources, (function (source) {
          var match = Belt_MapString.get(sourceAbsences, source.id);
          if (match !== undefined) {
            if (match) {
              return "Sometimes";
            } else {
              return "Never";
            }
          } else {
            return "Always";
          }
        }));
  if (Belt_List.every(states, (function (state) {
            return state === "Always";
          }))) {
    return "Always";
  } else if (Belt_List.every(states, (function (state) {
            return state === "Sometimes";
          }))) {
    return "Sometimes";
  } else if (Belt_List.every(states, (function (state) {
            return state === "Never";
          }))) {
    return "Never";
  } else {
    return "BySource";
  }
}

function getEventAndSourceState(absence, eventId, sourceId) {
  if (typeof absence === "number") {
    return "Always";
  }
  if (absence.TAG === /* SometimesSent */0) {
    return "Sometimes";
  }
  var match = Belt_MapString.get(absence._0, eventId);
  if (match === undefined) {
    return "Always";
  }
  if (match.TAG === /* AllSources */0) {
    if (match._0) {
      return "Sometimes";
    } else {
      return "Never";
    }
  }
  var match$1 = Belt_MapString.get(match._0, sourceId);
  if (match$1 !== undefined) {
    if (match$1) {
      return "Sometimes";
    } else {
      return "Never";
    }
  } else {
    return "Always";
  }
}

var rootStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(4)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.minWidth(Css.px(300)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.white),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                                NAME: "num",
                                VAL: 0.10
                              }))),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.paddingLeft(Css.px(16)),
              tl: {
                hd: Css.paddingRight(Css.px(16)),
                tl: {
                  hd: Css.paddingTop(Css.px(16)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(16)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var sourcesStyles = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(16)),
      tl: {
        hd: Css.paddingRight(Css.px(16)),
        tl: {
          hd: Css.paddingTop(Css.px(12)),
          tl: {
            hd: Css.paddingBottom(Css.px(16)),
            tl: {
              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey20),
              tl: /* [] */0
            }
          }
        }
      }
    });

var rowStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.marginTop(Css.px(4)),
              tl: {
                hd: Css.marginBottom(Css.px(4)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function PropertyAbsenceConfig$PropertyAbsenceEditPopup(Props) {
  var model = Props.model;
  var absence = Props.absence;
  var $$event = Props.event;
  var onClose = Props.onClose;
  var property = Props.property;
  var sources = Props.sources;
  var sendActions = Props.sendActions;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var globalSend = GlobalSendContext.use(undefined);
  var match = AvoLimits.PropertyAbsenceByEventAndSource.computeAvailability(workspace);
  var absenceConfigAvailable = match === "AvailableDuringTrial" || match === "Available";
  var match$1 = React.useState(function () {
        var simplestMode = getSimplestAbsenceModeByEvent(absence, sources, $$event, property.sendAs);
        if (simplestMode === "Never") {
          return "BySource";
        } else {
          return simplestMode;
        }
      });
  var setMode = match$1[1];
  var mode = match$1[0];
  React.useEffect((function () {
          var simplestMode = getSimplestAbsenceModeByEvent(absence, sources, $$event, property.sendAs);
          var newMode = simplestMode === "Never" ? "BySource" : simplestMode;
          Curry._1(setMode, (function (mode) {
                  if (mode === "BySource") {
                    return "BySource";
                  } else {
                    return newMode;
                  }
                }));
          
        }), [absence]);
  var match$2 = AvoConfig.determineSendingToSources(sources, $$event);
  var eventSources = typeof match$2 === "object" ? match$2.VAL : (
      match$2 === "None" ? /* [] */0 : sources
    );
  return React.createElement("section", {
              className: rootStyles,
              onKeyDown: (function ($$event) {
                  if ($$event.key === "Escape") {
                    return Curry._1(onClose, undefined);
                  }
                  
                })
            }, React.createElement("header", {
                  className: headerStyles
                }, React.createElement($$Text.make, {
                      element: "H1",
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.grey90,
                      children: "For this event"
                    }), React.createElement(Spacer.make, {
                      width: 4
                    }), React.createElement(Select.make, {
                      onSelect: (function (modeString) {
                          var newMode = Belt_Option.getWithDefault(absenceModeFromJs(modeString), "Always");
                          if (!absenceConfigAvailable && newMode === "BySource") {
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "ConfigureEventsPerPlatform"
                                        }
                                      });
                          }
                          var confirmationCopy = "Switching to " + (absenceModeToJs(newMode) + " will cause your config to change. Are you sure you want to proceed?");
                          var match = getSimplestAbsenceModeByEvent(absence, sources, $$event, property.sendAs);
                          var showConfirm = match === "BySource" ? newMode === "Always" || newMode === "Sometimes" : false;
                          var shouldProceed = showConfirm ? window.confirm(confirmationCopy) : true;
                          if (!shouldProceed) {
                            return ;
                          }
                          var newMode$1;
                          var exit = 0;
                          var exit$1 = 0;
                          if (mode === "Always" || mode === "Sometimes") {
                            if (newMode === "BySource") {
                              return Curry._1(setMode, (function (param) {
                                            return "BySource";
                                          }));
                            }
                            exit$1 = 3;
                          } else {
                            exit$1 = 3;
                          }
                          if (exit$1 === 3) {
                            if ((mode === "Sometimes" || mode === "BySource") && newMode === "Always") {
                              newMode$1 = newMode;
                            } else {
                              exit = 2;
                            }
                          }
                          if (exit === 2) {
                            if (!(mode === "Always" || mode === "BySource")) {
                              return ;
                            }
                            if (newMode !== "Sometimes") {
                              return ;
                            }
                            newMode$1 = newMode;
                          }
                          Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (branch) {
                                    var match = property.absence;
                                    var tmp;
                                    tmp = match !== undefined && typeof match !== "number" ? (
                                        match.TAG === /* SometimesSent */0 ? "SometimesSent" : "DependingOnSource"
                                      ) : "AlwaysSent";
                                    return AnalyticsRe.propertyConfigured(AvoUtils.getAnalyticsPropertyGroup(model, property), AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.propertyConfiguration("Presence", "UpdatePropertyPresenceByEvent", undefined, tmp, undefined, newMode$1 === "Always" ? "AlwaysSent" : "SometimesSent", undefined, undefined, undefined, undefined, undefined), NamedBranch.getId(branch), schemaGroup.schemaId);
                                  }),
                                undefined,
                                [[
                                    {
                                      NAME: "UpdatePropertyAbsence",
                                      VAL: [
                                        property.id,
                                        {
                                          TAG: /* Event */0,
                                          _0: $$event.id
                                        },
                                        newMode$1 === "Always" ? undefined : /* SometimesSent */({
                                              _0: ""
                                            })
                                      ]
                                    },
                                    {
                                      eventId: $$event.id,
                                      eventQuery: $$event.id,
                                      propertyId: property.id,
                                      propertyQuery: property.id
                                    }
                                  ]]
                              ]);
                          return Curry._1(setMode, (function (param) {
                                        return newMode$1;
                                      }));
                        }),
                      options: Belt_List.map({
                            hd: "Always",
                            tl: {
                              hd: "Sometimes",
                              tl: property.sendAs === /* SystemProperty */0 ? /* [] */0 : ({
                                    hd: "BySource",
                                    tl: /* [] */0
                                  })
                            }
                          }, (function (mode) {
                              return [
                                      {
                                        NAME: "Label",
                                        VAL: absenceConfigAvailable || mode !== "BySource" ? absenceModeToJs(mode) : "⚡️ " + absenceModeToJs(mode)
                                      },
                                      absenceModeToJs(mode)
                                    ];
                            })),
                      value: absenceModeToJs(mode)
                    })), mode !== "BySource" ? null : React.createElement("div", {
                    className: sourcesStyles
                  }, Belt_List.toArray(Belt_List.map(Belt_List.sort(eventSources, (function (a, b) {
                                  return Caml_obj.caml_compare(a.name, b.name);
                                })), (function (source) {
                              return React.createElement("div", {
                                          key: source.id,
                                          className: rowStyles
                                        }, React.createElement($$Text.make, {
                                              size: "Small",
                                              weight: "Semi",
                                              singleLine: true,
                                              color: Styles.Color.grey70,
                                              children: AvoConfig.getSourceName(source)
                                            }), React.createElement(Spacer.make, {
                                              width: 4
                                            }), React.createElement(Select.make, {
                                              disabled: !absenceConfigAvailable,
                                              onSelect: (function (newState) {
                                                  var sourceId = source.id;
                                                  var state = Belt_Option.getWithDefault(absenceModeFromJs(newState), "Always");
                                                  return Curry.app(sendActions, [
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              (function (branch) {
                                                                  var match = property.absence;
                                                                  var tmp;
                                                                  tmp = match !== undefined && typeof match !== "number" ? (
                                                                      match.TAG === /* SometimesSent */0 ? "SometimesSent" : "DependingOnSource"
                                                                    ) : "AlwaysSent";
                                                                  return AnalyticsRe.propertyConfigured(AvoUtils.getAnalyticsPropertyGroup(model, property), AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.propertyConfiguration("Presence", "UpdatePropertyPresenceByEventAndSource", undefined, tmp, undefined, "DependingOnSource", undefined, undefined, undefined, undefined, undefined), NamedBranch.getId(branch), schemaGroup.schemaId);
                                                                }),
                                                              undefined,
                                                              [[
                                                                  {
                                                                    NAME: "UpdatePropertyAbsence",
                                                                    VAL: [
                                                                      property.id,
                                                                      {
                                                                        TAG: /* EventSource */2,
                                                                        _0: $$event.id,
                                                                        _1: sourceId
                                                                      },
                                                                      state === "Sometimes" ? /* SometimesSent */({
                                                                            _0: ""
                                                                          }) : (
                                                                          state === "Always" ? undefined : (
                                                                              state === "Never" ? /* NeverSent */0 : Pervasives.failwith("Cannot use by source here")
                                                                            )
                                                                        )
                                                                    ]
                                                                  },
                                                                  {
                                                                    eventId: $$event.id,
                                                                    eventQuery: $$event.id,
                                                                    propertyId: property.id,
                                                                    propertyQuery: property.id
                                                                  }
                                                                ]]
                                                            ]);
                                                }),
                                              options: Belt_List.map(sourceStates, (function (state) {
                                                      return [
                                                              {
                                                                NAME: "Label",
                                                                VAL: absenceModeToJs(state)
                                                              },
                                                              absenceModeToJs(state)
                                                            ];
                                                    })),
                                              value: absenceModeToJs(getEventAndSourceState(absence, $$event.id, source.id))
                                            }));
                            })))));
}

var PropertyAbsenceEditPopup = {
  rootStyles: rootStyles,
  headerStyles: headerStyles,
  sourcesStyles: sourcesStyles,
  rowStyles: rowStyles,
  make: PropertyAbsenceConfig$PropertyAbsenceEditPopup
};

var containerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("baseline"),
          tl: {
            hd: Css.flexShrink(1.0),
            tl: {
              hd: Css.maxWidth(Css.pct(100)),
              tl: {
                hd: Css.marginRight(Css.px(4)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var buttonStyle = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.flexShrink(0.0),
                    tl: {
                      hd: Css.marginLeft(Css.px(1)),
                      tl: {
                        hd: Css.paddingTop(Css.px(3)),
                        tl: {
                          hd: Css.paddingRight(Css.px(4)),
                          tl: {
                            hd: Css.paddingBottom(Css.px(3)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(6)),
                              tl: {
                                hd: Css.borderRadius(Css.px(4)),
                                tl: {
                                  hd: Css.color(Styles.Color.grey70),
                                  tl: {
                                    hd: Css.transition({
                                          NAME: "ms",
                                          VAL: Styles.Duration.$$short
                                        }, undefined, undefined, "all"),
                                    tl: {
                                      hd: Icon.styles({
                                            hd: Css_Legacy_Core.SVG.fill("transparent"),
                                            tl: {
                                              hd: Css.transition({
                                                    NAME: "ms",
                                                    VAL: Styles.Duration.$$short
                                                  }, undefined, undefined, "all"),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.backgroundColor(Styles.Color.grey20),
                                              tl: {
                                                hd: Css.color(Styles.Color.grey100),
                                                tl: {
                                                  hd: Icon.color(Styles.Color.grey90),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function PropertyAbsenceConfig(Props) {
  var model = Props.model;
  var property = Props.property;
  var $$event = Props.event;
  var sendActions = Props.sendActions;
  var sources = Props.sources;
  var viewerCanEdit = Props.viewerCanEdit;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = AvoLimits.PropertyAbsenceByEventAndSource.computeAvailability(workspace);
  var absenceConfigAvailable = match === "AvailableDuringTrial" || match === "Available";
  var editButtonRef = React.useRef(null);
  var match$1 = React.useState(function () {
        return false;
      });
  var setEditModalVisible = match$1[1];
  var canEditAbsence = absenceConfigAvailable && viewerCanEdit && property.sendAs !== /* SystemProperty */0;
  var match$2 = property.absence;
  return React.createElement("div", {
              className: containerStyles
            }, match$2 !== undefined ? (
                canEditAbsence ? React.createElement("button", {
                        ref: editButtonRef,
                        className: buttonStyle,
                        onClick: (function (param) {
                            return Curry._1(setEditModalVisible, (function (param) {
                                          return true;
                                        }));
                          })
                      }, React.createElement($$Text.make, {
                            element: "Span",
                            size: "Small",
                            weight: "Regular",
                            children: absenceModeToJs(getSimplestAbsenceModeByEvent(match$2, sources, $$event, property.sendAs))
                          }), React.createElement(Spacer.make, {
                            width: 2
                          }), React.createElement(Icon.make, {
                            type_: "chevronDown"
                          }), React.createElement(PortalMenu.make, {
                            distance: 4,
                            visible: match$1[0],
                            onClose: (function (param) {
                                return Curry._1(setEditModalVisible, (function (param) {
                                              return false;
                                            }));
                              }),
                            recalculateOnBoundingRectChanges: true,
                            referenceItemRef: editButtonRef,
                            children: React.createElement(PortalMenuAnimator.make, {
                                  key_: "edit-absence-" + property.id,
                                  children: React.createElement(PropertyAbsenceConfig$PropertyAbsenceEditPopup, {
                                        model: model,
                                        absence: match$2,
                                        event: $$event,
                                        onClose: (function (param) {
                                            return Curry._1(setEditModalVisible, (function (param) {
                                                          return false;
                                                        }));
                                          }),
                                        property: property,
                                        sources: sources,
                                        sendActions: sendActions
                                      })
                                })
                          })) : React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("inlineFlex"),
                              tl: {
                                hd: Css.flexShrink(0.0),
                                tl: {
                                  hd: Css.marginLeft(Css.px(4)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            element: "Span",
                            size: "Small",
                            weight: "Regular",
                            color: Styles.Color.grey70,
                            children: absenceModeToJs(getSimplestAbsenceModeByEvent(match$2, sources, $$event, property.sendAs))
                          }))
              ) : null);
}

var make = PropertyAbsenceConfig;

exports.absenceModeToJs = absenceModeToJs;
exports.absenceModeFromJs = absenceModeFromJs;
exports.sourceStates = sourceStates;
exports.getSimplestAbsenceModeByEvent = getSimplestAbsenceModeByEvent;
exports.getEventAndSourceState = getEventAndSourceState;
exports.PropertyAbsenceEditPopup = PropertyAbsenceEditPopup;
exports.containerStyles = containerStyles;
exports.buttonStyle = buttonStyle;
exports.make = make;
/* rootStyles Not a pure module */
