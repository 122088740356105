// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Select = require("./Select.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Shortid = require("shortid");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var CodegenRules = require("../../codegen/src/CodegenRules.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var PinPropertyValueModalTypeInput = require("./PinPropertyValueModalTypeInput.bs.js");

function PinPropertyValueModal(Props) {
  var model = Props.model;
  var eventId = Props.eventId;
  var propertyId = Props.propertyId;
  var onClose = Props.onClose;
  var sendActions = SendActionsContext.use(undefined);
  var match = StateUtils.getPropertyById(propertyId, model);
  var maybeProperty = match !== undefined && match.TAG !== /* PropertyRef */0 ? match._0 : undefined;
  var maybeEvent = StateUtils.getEventById(eventId, model);
  var match$1 = React.useState(function () {
        
      });
  var setSelectedChildProperty = match$1[1];
  var selectedChildProperty = match$1[0];
  var maybePinnedNestedPropertyRule = Belt_List.getByU(model.rules, (function (rule) {
          var match = rule.item;
          var match$1 = rule.definition;
          if (typeof match !== "object") {
            return false;
          }
          if (match.NAME !== "EventObjectField") {
            return false;
          }
          if (typeof match$1 !== "object") {
            return false;
          }
          if (match$1.NAME !== "PinnedValue") {
            return false;
          }
          var match$2 = match.VAL;
          if (match$2[0] === eventId && match$2[1] === propertyId) {
            return Caml_obj.caml_equal(match$2[2], selectedChildProperty);
          } else {
            return false;
          }
        }));
  var getInitialPinnedValue = function (param) {
    if (maybeProperty === undefined) {
      return ;
    }
    if (maybeProperty.type_ === "object") {
      if (maybeEvent !== undefined) {
        if (selectedChildProperty !== undefined) {
          return CodegenRules.getEventSpecificNestedPropertyPinnedValue(selectedChildProperty, maybeProperty.id, maybeEvent.id, model);
        }
        
      } else if (maybeEvent === undefined) {
        return ;
      }
      
    } else if (maybeEvent === undefined) {
      return ;
    }
    return Belt_Option.flatMap(StateUtils.getPropertyRefById(eventId, propertyId, model), (function (propRef) {
                  return propRef.pinnedValue;
                }));
  };
  var match$2 = React.useState(function () {
        return getInitialPinnedValue(undefined);
      });
  var setPinnedValue = match$2[1];
  var pinnedValue = match$2[0];
  React.useEffect((function () {
          Curry._1(setPinnedValue, (function (param) {
                  return getInitialPinnedValue(undefined);
                }));
          
        }), [selectedChildProperty]);
  return Belt_Option.mapWithDefault(maybeProperty, null, (function (property) {
                var match = StateUtils.getPropertyRefById(eventId, propertyId, model);
                var match$1 = StateUtils.getEventById(eventId, model);
                var tmp;
                if (match !== undefined) {
                  if (match$1 !== undefined) {
                    var type_ = property.type_;
                    var tmp$1;
                    var exit = 0;
                    switch (type_) {
                      case "object" :
                          var propertyRefs = Belt_Option.getWithDefault(Belt_List.head(Belt_List.keepMapU(property.validations, (function (validation) {
                                          if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                                            return validation.VAL;
                                          }
                                          
                                        }))), /* [] */0);
                          var selectedProperty = Belt_Option.flatMap(selectedChildProperty, (function (propertyId) {
                                  return StateUtils.getPropertyById(propertyId, model);
                                }));
                          if (propertyRefs) {
                            var childProperties = Belt_List.keepMap(propertyRefs, (function (propertyRef) {
                                    var property = StateUtils.getPropertyById(propertyRef.id, model);
                                    if (property !== undefined && property.TAG !== /* PropertyRef */0) {
                                      return property._0;
                                    }
                                    
                                  }));
                            var tmp$2;
                            tmp$2 = selectedProperty !== undefined && selectedProperty.TAG !== /* PropertyRef */0 ? React.createElement(PinPropertyValueModalTypeInput.make, {
                                    pinnedValue: pinnedValue,
                                    onChange: setPinnedValue,
                                    property: selectedProperty._0,
                                    model: model
                                  }) : null;
                            tmp$1 = React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement(Select.make, {
                                      onSelect: (function (propertyId) {
                                          return Curry._1(setSelectedChildProperty, (function (param) {
                                                        return propertyId;
                                                      }));
                                        }),
                                      options: Belt_List.add(Belt_List.map(childProperties, (function (property) {
                                                  return [
                                                          {
                                                            NAME: "Label",
                                                            VAL: property.name
                                                          },
                                                          property.id
                                                        ];
                                                })), [
                                            {
                                              NAME: "Label",
                                              VAL: "Pick a key to pin..."
                                            },
                                            "none"
                                          ]),
                                      value: Belt_Option.getWithDefault(selectedChildProperty, "none")
                                    }), React.createElement(Spacer.make, {
                                      width: 8
                                    }), tmp$2);
                          } else {
                            tmp$1 = "No child properties defined for this object";
                          }
                          break;
                      case "bool" :
                      case "float" :
                      case "int" :
                      case "string" :
                          exit = 1;
                          break;
                      default:
                        tmp$1 = "Cannot pin property of type " + type_;
                    }
                    if (exit === 1) {
                      tmp$1 = React.createElement(PinPropertyValueModalTypeInput.make, {
                            pinnedValue: pinnedValue,
                            onChange: setPinnedValue,
                            property: property,
                            model: model
                          });
                    }
                    tmp = React.createElement("div", undefined, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding2(Css.px(5), Css.px(0)),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.regular),
                                      tl: {
                                        hd: Css.lineHeight(Css.pct(150.0)),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, "When the value of the property", React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.margin2(Css.px(0), Css.px(3)),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: /* [] */0
                                        }
                                      })
                                }, property.name), "is pinned it will always be sent as the pinned value with the ", React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.margin2(Css.px(0), Css.px(3)),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: /* [] */0
                                        }
                                      })
                                }, match$1.name), "event. Developers will not be required to pass in value for the property when the event is being sent."), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding4(Css.px(15), Css.px(0), Css.px(10), Css.px(0)),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.regular),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.regular),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, "Always send", React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.margin2(Css.px(0), Css.px(3)),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: /* [] */0
                                        }
                                      })
                                }, property.name), "with", React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.margin2(Css.px(0), Css.px(3)),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: /* [] */0
                                        }
                                      })
                                }, match$1.name), "as:"), React.createElement("form", {
                              onSubmit: (function (prim) {
                                  prim.preventDefault();
                                  
                                })
                            }, tmp$1, React.createElement(Spacer.make, {
                                  display: "inlineBlock",
                                  width: 8,
                                  height: 8
                                }), Caml_obj.caml_notequal(getInitialPinnedValue(undefined), pinnedValue) ? React.createElement(Button.make, {
                                    label: Belt_Option.isNone(pinnedValue) ? "Remove Pinned Value" : "Set Pinned Value",
                                    onClick: (function (param) {
                                        var context = {
                                          eventId: eventId,
                                          eventQuery: eventId,
                                          propertyId: propertyId,
                                          propertyQuery: propertyId
                                        };
                                        if (pinnedValue !== undefined) {
                                          if (selectedChildProperty !== undefined) {
                                            if (maybePinnedNestedPropertyRule !== undefined) {
                                              Curry.app(sendActions, [
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [[
                                                        {
                                                          NAME: "UpdateRule",
                                                          VAL: [
                                                            maybePinnedNestedPropertyRule.id,
                                                            maybePinnedNestedPropertyRule.item,
                                                            {
                                                              NAME: "PinnedValue",
                                                              VAL: pinnedValue
                                                            }
                                                          ]
                                                        },
                                                        context
                                                      ]]
                                                  ]);
                                            } else {
                                              Curry.app(sendActions, [
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [[
                                                        {
                                                          NAME: "AddRule",
                                                          VAL: [
                                                            Shortid(),
                                                            {
                                                              NAME: "EventObjectField",
                                                              VAL: [
                                                                eventId,
                                                                propertyId,
                                                                selectedChildProperty
                                                              ]
                                                            },
                                                            {
                                                              NAME: "PinnedValue",
                                                              VAL: pinnedValue
                                                            }
                                                          ]
                                                        },
                                                        context
                                                      ]]
                                                  ]);
                                            }
                                          } else {
                                            Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  [[
                                                      {
                                                        NAME: "UpdatePropertyPinnedValue",
                                                        VAL: [
                                                          eventId,
                                                          propertyId,
                                                          pinnedValue
                                                        ]
                                                      },
                                                      context
                                                    ]]
                                                ]);
                                          }
                                        } else if (selectedChildProperty !== undefined) {
                                          if (maybePinnedNestedPropertyRule !== undefined) {
                                            Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  [[
                                                      {
                                                        NAME: "RemoveRule",
                                                        VAL: [
                                                          maybePinnedNestedPropertyRule.id,
                                                          maybePinnedNestedPropertyRule.item
                                                        ]
                                                      },
                                                      context
                                                    ]]
                                                ]);
                                          }
                                          
                                        } else {
                                          Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "RemovePropertyPinnedValue",
                                                      VAL: [
                                                        eventId,
                                                        propertyId
                                                      ]
                                                    },
                                                    context
                                                  ]]
                                              ]);
                                        }
                                        return Curry._1(onClose, undefined);
                                      })
                                  }) : null), React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: {
                                              hd: Css.fontSize(Styles.FontSize.small),
                                              tl: {
                                                hd: Css.color(Styles.Color.grey50),
                                                tl: {
                                                  hd: Css.paddingTop(Css.px(20)),
                                                  tl: {
                                                    hd: Css.textAlign("center"),
                                                    tl: {
                                                      hd: Css.hover({
                                                            hd: Css.color(Styles.Color.grey70),
                                                            tl: /* [] */0
                                                          }),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  return Curry._1(onClose, undefined);
                                })
                            }, "Cancel"));
                  } else {
                    tmp = React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.padding(Css.px(25)),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.color(Styles.Color.grapeError),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, "Event not found.");
                  }
                } else {
                  tmp = React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding(Css.px(25)),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.color(Styles.Color.grapeError),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, "Property not found on event.");
                }
                return React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.position("relative"),
                                  tl: {
                                    hd: Css.width(Css.px(500)),
                                    tl: {
                                      hd: Css.maxWidth(Css.pct(100)),
                                      tl: {
                                        hd: Css.padding4(Css.px(30), Css.px(30), Css.px(30), Css.px(30)),
                                        tl: {
                                          hd: Css.backgroundColor(Styles.Color.white),
                                          tl: {
                                            hd: Css.borderRadius(Styles.Border.radius),
                                            tl: {
                                              hd: Css.cursor("default"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement($$Text.make, {
                                size: "Large",
                                weight: "Semi",
                                textAlign: "center",
                                children: "Pin Property Value"
                              }), React.createElement(Spacer.make, {
                                height: 15
                              }), tmp);
              }));
}

var make = PinPropertyValueModal;

exports.make = make;
/* Css Not a pure module */
