// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Quill = require("./externals/quill.bs.js");
var React = require("react");
var JsDiff = require("./externals/JsDiff.bs.js");
var Router = require("./Router.bs.js");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var DiffEvent = require("./DiffEvent.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var QuillDelta = require("quill-delta");
var DiffComments = require("./DiffComments.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");

function DiffGoal(Props) {
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var goToCommentId = Props.goToCommentId;
  var withComments = Props.withComments;
  var events = Props.events;
  var match = Belt_Option.map(Belt_List.head(actions), (function (prim) {
          return prim[0];
        }));
  if (match === undefined) {
    return null;
  }
  if (typeof match !== "object") {
    return null;
  }
  if (match.NAME !== "Goal") {
    return null;
  }
  var goalId = match.VAL;
  var actions$1 = Belt_List.map(actions, (function (prim) {
          return prim[1];
        }));
  if (!actions$1) {
    return null;
  }
  var hasCreate = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "AddGoal";
          } else {
            return false;
          }
        }));
  var hasArchive = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          if (x.NAME !== "Archive") {
            return false;
          }
          var match = x.VAL;
          if (typeof match === "object") {
            return match.NAME === "Goal";
          } else {
            return false;
          }
        }));
  var changeType = hasCreate !== undefined ? "Create" : (
      hasArchive !== undefined ? "Archive" : "Update"
    );
  var goal = Belt_Option.getWithDefault(Belt_List.getBy(masterModel.goals, (function (goal) {
              return goal.id === goalId;
            })), TrackingPlanModel.emptyGoal(goalId));
  var nextGoal = Belt_Option.getWithDefault(Belt_List.getBy(branchModel.goals, (function (goal) {
              return goal.id === goalId;
            })), TrackingPlanModel.emptyGoal(goalId));
  var maybeAction = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "UpdateGoalDescription";
          } else {
            return false;
          }
        }));
  var addMetricActions = Belt_List.keepMap(actions$1, (function (x) {
          if (typeof x === "object" && x.NAME === "AddMetricToGoal") {
            return x;
          }
          
        }));
  var removeMetricActions = Belt_List.keepMap(actions$1, (function (x) {
          if (typeof x === "object" && x.NAME === "RemoveMetricFromGoal") {
            return x;
          }
          
        }));
  var addEventActions = Belt_List.keepMap(actions$1, (function (x) {
          if (typeof x === "object" && x.NAME === "AddEventToGoal") {
            return x;
          }
          
        }));
  var removeEventActions = Belt_List.keepMap(actions$1, (function (x) {
          if (typeof x === "object" && x.NAME === "RemoveEventFromGoal") {
            return x;
          }
          
        }));
  var metricActions = Belt_List.concat(addMetricActions, removeMetricActions);
  var eventActions = Belt_List.concat(addEventActions, removeEventActions);
  var renderMetric = function (metric, metricColor) {
    return React.createElement("div", {
                key: metric.id,
                className: Curry._1(Css.style, {
                      hd: Css.fontWeight(Styles.FontWeight.semi),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.small),
                        tl: {
                          hd: Css.padding2(Css.px(10), Css.px(15)),
                          tl: {
                            hd: Css.margin4(Css.px(5), Css.px(20), Css.px(5), Css.px(0)),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", metricColor),
                                tl: {
                                  hd: Css.color(metricColor),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    })
              }, metric.name);
  };
  var renderEvent = function ($$event, eventColor) {
    return React.createElement("div", {
                key: $$event.id,
                className: Curry._1(Css.style, {
                      hd: Css.fontWeight(Styles.FontWeight.semi),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.small),
                        tl: {
                          hd: Css.padding2(Css.px(10), Css.px(15)),
                          tl: {
                            hd: Css.margin4(Css.px(5), Css.px(20), Css.px(5), Css.px(0)),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", eventColor),
                                tl: {
                                  hd: Css.color(eventColor),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    })
              }, $$event.name);
  };
  var actions$2 = Belt_List.concat(metricActions, eventActions);
  var tmp;
  if (withComments) {
    var tmp$1 = {
      schemaId: schemaId,
      branchId: branchId,
      threadId: goal.id,
      itemType: "Goal",
      itemName: goal.name,
      events: events
    };
    if (goToCommentId !== undefined) {
      tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp = React.createElement(DiffComments.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              key: goalId,
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.alignSelf("flexStart"),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: {
                            hd: Css.maxWidth(DiffEvent.maxWidthPx),
                            tl: {
                              hd: Css.position("sticky"),
                              tl: {
                                hd: Css.top(Css.px(20)),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.padding(Css.px(15)),
                                  tl: {
                                    hd: Css.cursor("pointer"),
                                    tl: {
                                      hd: Css.hover({
                                            hd: Css.backgroundColor(Styles.Color.grey20),
                                            tl: /* [] */0
                                          }),
                                      tl: changeType === "Create" ? ({
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.mintGreen),
                                            tl: /* [] */0
                                          }) : (
                                          changeType === "Update" ? ({
                                                hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                tl: /* [] */0
                                              }) : ({
                                                hd: Css.border(Css.px(1), "solid", Styles.Color.grapeError),
                                                tl: /* [] */0
                                              })
                                        )
                                    }
                                  }
                                }
                              }
                            }
                          }),
                      onClick: (function (param) {
                          return Router.Schema.pushDrawerItem(undefined, {
                                      NAME: "category",
                                      VAL: [
                                        goal.id,
                                        undefined
                                      ]
                                    });
                        })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: /* [] */0
                              })
                        }, JsDiff.renderStringDiff(undefined, undefined, undefined, goal.name, nextGoal.name)), maybeAction !== undefined ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(5), Css.px(0)),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.small),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement("span", {
                                dangerouslySetInnerHTML: {
                                  __html: Quill.toHTMLWithDiff(new QuillDelta(JSON.parse(goal.description)), new QuillDelta(JSON.parse(nextGoal.description)), undefined, undefined)
                                }
                              })) : null, Belt_List.length(actions$2) > 0 ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(5), Css.px(0)),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.small),
                                    tl: /* [] */0
                                  }
                                })
                          }, metricActions === /* [] */0 ? null : React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.fontSize(Styles.FontSize.regular),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: {
                                            hd: Css.color(Styles.Color.grey90),
                                            tl: {
                                              hd: Css.marginTop(Css.px(10)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      })
                                }, "Metrics"), Belt_List.toArray(Belt_List.map(addMetricActions, (function (x) {
                                      if (typeof x !== "object") {
                                        return null;
                                      }
                                      if (x.NAME !== "AddMetricToGoal") {
                                        return null;
                                      }
                                      var metricId = x.VAL[1];
                                      return Belt_Option.mapWithDefault(Belt_List.getBy(branchModel.metrics, (function (metric) {
                                                        return metric.id === metricId;
                                                      })), null, (function (metric) {
                                                    return renderMetric(metric, Styles.Color.mintGreen);
                                                  }));
                                    }))), Belt_List.toArray(Belt_List.map(removeMetricActions, (function (x) {
                                      if (typeof x !== "object") {
                                        return null;
                                      }
                                      if (x.NAME !== "RemoveMetricFromGoal") {
                                        return null;
                                      }
                                      var metricId = x.VAL[1];
                                      return Belt_Option.mapWithDefault(Belt_List.getBy(branchModel.metrics, (function (metric) {
                                                        return metric.id === metricId;
                                                      })), null, (function (metric) {
                                                    return renderMetric(metric, Styles.Color.grapeError);
                                                  }));
                                    }))), eventActions === /* [] */0 ? null : React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.fontSize(Styles.FontSize.regular),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: {
                                            hd: Css.color(Styles.Color.grey90),
                                            tl: {
                                              hd: Css.marginTop(Css.px(10)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      })
                                }, "Events"), Belt_List.toArray(Belt_List.map(addEventActions, (function (x) {
                                      if (typeof x !== "object") {
                                        return null;
                                      }
                                      if (x.NAME !== "AddEventToGoal") {
                                        return null;
                                      }
                                      var eventId = x.VAL[1];
                                      return Belt_Option.mapWithDefault(Belt_List.getBy(branchModel.events, (function ($$event) {
                                                        return $$event.id === eventId;
                                                      })), null, (function ($$event) {
                                                    return renderEvent($$event, Styles.Color.mintGreen);
                                                  }));
                                    }))), Belt_List.toArray(Belt_List.map(removeEventActions, (function (x) {
                                      if (typeof x !== "object") {
                                        return null;
                                      }
                                      if (x.NAME !== "RemoveEventFromGoal") {
                                        return null;
                                      }
                                      var eventId = x.VAL[1];
                                      return Belt_Option.mapWithDefault(Belt_List.getBy(branchModel.events, (function ($$event) {
                                                        return $$event.id === eventId;
                                                      })), null, (function ($$event) {
                                                    return renderEvent($$event, Styles.Color.grapeError);
                                                  }));
                                    })))) : null)), tmp);
}

var make = DiffGoal;

exports.make = make;
/* Css Not a pure module */
