// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("../Button.bs.js");
var Router = require("../Router.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var DateFns = require("../DateFns.bs.js");
var Mantine = require("../Mantine.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var HoverTitle = require("./HoverTitle.bs.js");
var StateUtils = require("../stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var BranchHeader = require("../BranchHeader.bs.js");
var DiffComments = require("../DiffComments.bs.js");
var SnippetUtils = require("../detailviews/SnippetUtils.bs.js");
var Core = require("@mantine/core");
var BranchImplementationUtils = require("./BranchImplementationUtils.bs.js");
var EventImplementationSnippet = require("../detailviews/EventImplementationSnippet.bs.js");

function getEventModificationStatus(fromModel, $$event, sourceId) {
  return Belt_Option.mapWithDefault(StateUtils.getEventById($$event.id, fromModel), /* New */0, (function ($$event) {
                if (Belt_List.someU($$event.includeSources, (function (param) {
                          return param.id === sourceId;
                        }))) {
                  return /* Modified */2;
                } else {
                  return /* AddedToSource */1;
                }
              }));
}

var container = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
        tl: {
          hd: Css.borderRadius(Css.px(5)),
          tl: {
            hd: Css.padding(Css.px(16)),
            tl: {
              hd: Css.width(Css.pct(100.0)),
              tl: {
                hd: Css.unsafe("height", "fit-content"),
                tl: {
                  hd: Css.maxWidth(Css.px(934)),
                  tl: {
                    hd: Css.minWidth(Css.px(480)),
                    tl: {
                      hd: Css.position("sticky"),
                      tl: {
                        hd: Css.top(Css.px(BranchHeader.branchHeaderHeight + BranchImplementationUtils.codeChangesHeaderHeight | 0)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.gap(Css.px(8)),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

function modificationStatus(modificationStatus$1) {
  return Curry._1(Css.style, {
              hd: Css.borderRadius(Css.px(10)),
              tl: {
                hd: Css.padding2(Css.px(2), Css.px(8)),
                tl: {
                  hd: Css.backgroundColor(modificationStatus$1 >= 2 ? Styles.Color.purple10 : Styles.Color.green10),
                  tl: {
                    hd: Css.color(modificationStatus$1 >= 2 ? Styles.Color.purple120 : Styles.Color.green120),
                    tl: /* [] */0
                  }
                }
              }
            });
}

function status(status$1) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.gap(Css.px(4)),
                  tl: {
                    hd: Css.padding2(Css.px(1), Css.px(5)),
                    tl: {
                      hd: Css.borderRadius(Css.px(10)),
                      tl: {
                        hd: Css.whiteSpace("nowrap"),
                        tl: {
                          hd: Css.color(typeof status$1 === "number" ? Styles.Color.grey60 : Styles.Color.green100),
                          tl: {
                            hd: Css.border(Css.px(1), "solid", typeof status$1 === "number" ? Styles.Color.grey40 : Styles.Color.green70),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function statusBall(status) {
  return Curry._1(Css.style, {
              hd: Css.minWidth(Css.px(8)),
              tl: {
                hd: Css.minHeight(Css.px(8)),
                tl: {
                  hd: Css.width(Css.px(8)),
                  tl: {
                    hd: Css.height(Css.px(8)),
                    tl: {
                      hd: Css.borderRadius(Css.px(4)),
                      tl: {
                        hd: Css.backgroundColor(typeof status === "number" ? Styles.Color.grey40 : Styles.Color.green70),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var Style = {
  container: container,
  header: header,
  modificationStatus: modificationStatus,
  status: status,
  statusBall: statusBall
};

function ChangedEvent(Props) {
  var branchStatus = Props.branchStatus;
  var enrichedFromModel = Props.enrichedFromModel;
  var enrichedToModel = Props.enrichedToModel;
  var diffViewType = Props.diffViewType;
  var $$event = Props.event;
  var fromModel = Props.fromModel;
  var goToCommentId = Props.goToCommentId;
  var sourceId = Props.sourceId;
  var status$1 = Props.status;
  var sourceUpdatesLoadingState = Props.sourceUpdatesLoadingState;
  var toModel = Props.toModel;
  var containerRef = React.useRef(null);
  SnippetUtils.useScrollIntoView($$event.id, "BranchImplementation", (BranchHeader.branchHeaderHeight + BranchImplementationUtils.codeChangesHeaderHeight | 0) + 2 | 0, containerRef);
  var match = React.useState(function () {
        return false;
      });
  var setTimestampVisible = match[1];
  var modificationStatus$1 = getEventModificationStatus(fromModel, $$event, sourceId);
  var tmp;
  switch (modificationStatus$1) {
    case /* New */0 :
        tmp = "New";
        break;
    case /* AddedToSource */1 :
        tmp = "Added";
        break;
    case /* Modified */2 :
        tmp = "Updated";
        break;
    
  }
  var tmp$1;
  if (BranchImplementationUtils.hasCodegenForSource($$event, sourceId)) {
    if (sourceUpdatesLoadingState === "loading") {
      tmp$1 = React.createElement("span", {
            className: status(status$1)
          }, React.createElement("span", {
                className: statusBall(status$1)
              }), React.createElement($$Text.make, {
                size: "Small",
                children: "Loading..."
              }));
    } else if (typeof status$1 === "number") {
      if (status$1 !== 1) {
        var tmp$2;
        if (typeof status$1 === "number") {
          switch (status$1) {
            case /* NotSeen */0 :
                tmp$2 = "Never seen";
                break;
            case /* NotImplementedWithAvo */1 :
                tmp$2 = "";
                break;
            case /* Unknown */2 :
                tmp$2 = "Not supported";
                break;
            
          }
        } else {
          tmp$2 = "";
        }
        tmp$1 = React.createElement("span", {
              className: status(status$1)
            }, React.createElement("span", {
                  className: statusBall(status$1)
                }), React.createElement($$Text.make, {
                  size: "Small",
                  children: tmp$2
                }));
      } else {
        tmp$1 = null;
      }
    } else {
      var since = DateFns.distanceInWordsToNow(false, false, status$1._0);
      tmp$1 = React.createElement(Mantine.Popover.make, {
            opened: match[0],
            position: "top",
            children: null
          }, React.createElement(Core.Popover.Target, {
                children: React.createElement("span", {
                      className: status(status$1),
                      onMouseEnter: (function (param) {
                          return Curry._1(setTimestampVisible, (function (param) {
                                        return true;
                                      }));
                        }),
                      onMouseLeave: (function (param) {
                          return Curry._1(setTimestampVisible, (function (param) {
                                        return false;
                                      }));
                        })
                    }, React.createElement("span", {
                          className: statusBall(status$1)
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          children: "Implemented"
                        }))
              }), React.createElement(Mantine.Popover.Dropdown.make, {
                children: React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.width("auto"),
                            tl: {
                              hd: Css.whiteSpace("nowrap"),
                              tl: {
                                hd: Css.padding2(Css.px(3), Css.px(6)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.grey80),
                                  tl: {
                                    hd: Css.color(Styles.Color.white),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.small),
                                      tl: {
                                        hd: Css.borderRadius(Styles.Border.radius),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.bold),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, "Seen in development " + since + " ago")
              }));
    }
  } else {
    tmp$1 = null;
  }
  var tmp$3;
  switch (modificationStatus$1) {
    case /* New */0 :
        tmp$3 = "new";
        break;
    case /* AddedToSource */1 :
        tmp$3 = "added";
        break;
    case /* Modified */2 :
        tmp$3 = "updated";
        break;
    
  }
  var tmp$4 = {
    branchStatus: branchStatus,
    eventDiffState: tmp$3,
    enrichedToModel: enrichedToModel,
    event: $$event,
    fromModel: fromModel,
    toModel: toModel,
    pageName: "BranchImplementation",
    viewType: /* SingleSource */{
      _0: sourceId
    },
    diffViewType: diffViewType,
    key: sourceId
  };
  if (enrichedFromModel !== undefined) {
    tmp$4.enrichedFromModel = Caml_option.valFromOption(enrichedFromModel);
  }
  var tmp$5 = {
    schemaId: Router.Schema.getSchemaId(undefined),
    branchId: Router.Schema.getBranchId(undefined),
    threadId: $$event.id,
    itemType: "Event",
    itemName: $$event.name,
    stickyTop: (BranchHeader.branchHeaderHeight + BranchImplementationUtils.codeChangesHeaderHeight | 0) + 4 | 0,
    events: toModel.events
  };
  if (goToCommentId !== undefined) {
    tmp$5.goToCommentId = Caml_option.valFromOption(goToCommentId);
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: /* [] */0
                  })
            }, React.createElement("section", {
                  ref: containerRef,
                  className: container
                }, React.createElement("header", {
                      className: header
                    }, React.createElement("span", {
                          className: modificationStatus(modificationStatus$1)
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              children: tmp
                            })), React.createElement(HoverTitle.make, {
                          label: $$event.name
                        }), React.createElement("span", undefined, tmp$1), React.createElement(Spacer.make, {
                          width: 0,
                          grow: 1.0
                        }), React.createElement(Button.make, {
                          icon: "sidebar",
                          label: "View",
                          onClick: (function (param) {
                              return Router.Schema.pushDrawerItem(undefined, {
                                          NAME: "event",
                                          VAL: [
                                            $$event.id,
                                            undefined,
                                            undefined,
                                            false
                                          ]
                                        });
                            }),
                          style: "outline"
                        })), $$event.description.trim().length > 0 ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                            height: 8
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.grey60,
                            children: $$event.description
                          })) : null, React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement(EventImplementationSnippet.make, tmp$4)), React.createElement(DiffComments.make, tmp$5));
}

var make = ChangedEvent;

exports.getEventModificationStatus = getEventModificationStatus;
exports.Style = Style;
exports.make = make;
/* container Not a pure module */
