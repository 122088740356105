// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Case = require("../../../bs-case/src/case.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Prism = require("../externals/Prism.bs.js");
var React = require("react");
var JsDiff = require("../externals/JsDiff.bs.js");
var Router = require("../Router.bs.js");
var Styles = require("../styles.bs.js");
var $$Window = require("../externals/window.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("../../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var DiffLines = require("../externals/DiffLines.bs.js");
var StateUtils = require("../stateUtils.bs.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CodegenCommons = require("../../../codegen/src/CodegenCommons.bs.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var EventPseudocode = require("../EventPseudocode.bs.js");

var NoSnippetsProvidedException = /* @__PURE__ */Caml_exceptions.create("SnippetUtils.NoSnippetsProvidedException");

function getFirstSource($$event, model) {
  return Belt_List.head(Belt_List.keepMap(Belt_List.reverse($$event.includeSources), (function (param) {
                    return StateUtils.getSourceById(param.id, model);
                  })));
}

function hasCodegenForSource($$event, sourceId) {
  return Belt_List.some($$event.includeSources, (function (param) {
                if (param.id === sourceId) {
                  return param.includeInCodegen === true;
                } else {
                  return false;
                }
              }));
}

function getSnippetTypeWithSource($$event, source, model) {
  var validSource = AvoConfig.tryToConvertSourceToValidSourceMaybe(source, model);
  if (validSource !== undefined) {
    if (hasCodegenForSource($$event, source.id)) {
      return {
              NAME: "codegen",
              VAL: [
                source,
                validSource
              ]
            };
    } else {
      return {
              NAME: "pseudocode",
              VAL: source
            };
    }
  } else if (hasCodegenForSource($$event, source.id) && Caml_obj.caml_equal(source.platform, "Flutter")) {
    return {
            NAME: "invalid",
            VAL: [
              "noCodegenForFlutter",
              source
            ]
          };
  } else if (hasCodegenForSource($$event, source.id)) {
    return {
            NAME: "invalid",
            VAL: [
              "finishSettingUpSource",
              source
            ]
          };
  } else {
    return {
            NAME: "pseudocode",
            VAL: source
          };
  }
}

function getSnippet(enrichedModel, $$event, model, serverTrackingWithDeviceId, source) {
  var match = getSnippetTypeWithSource($$event, source, model);
  var variant = match.NAME;
  if (variant === "pseudocode") {
    var source$1 = match.VAL;
    return {
            NAME: "pseudocode",
            VAL: {
              snippet: EventPseudocode.generateEventPseudoCode(enrichedModel, $$event, model, source$1),
              source: source$1
            }
          };
  }
  if (variant === "invalid") {
    var match$1 = match.VAL;
    var match$2 = match$1[0];
    if (match$2 === "error") {
      return {
              NAME: "invalid",
              VAL: {
                validity: "error",
                source: match$1[1],
                snippet: undefined
              }
            };
    }
    var source$2 = match$1[1];
    return {
            NAME: "invalid",
            VAL: {
              validity: match$2,
              source: source$2,
              snippet: EventPseudocode.generateEventPseudoCode(enrichedModel, $$event, model, source$2)
            }
          };
  }
  var match$3 = match.VAL;
  var validSource = match$3[1];
  var source$3 = match$3[0];
  if (enrichedModel !== undefined) {
    return {
            NAME: "codegen",
            VAL: {
              snippet: CodegenCommons.snippetWithEnrichedModel(enrichedModel, validSource, $$event),
              source: source$3
            }
          };
  } else {
    return {
            NAME: "codegen",
            VAL: {
              snippet: CodegenCommons.snippet(serverTrackingWithDeviceId, model, validSource, $$event),
              source: source$3
            }
          };
  }
}

function makeDiff(diffViewType, oldSnippet, newSnippet) {
  if (oldSnippet.NAME === "invalid") {
    var match = oldSnippet.VAL;
    var maybeOldCode = match.snippet;
    var sourceLeft = match.source;
    var validityLeft = match.validity;
    if (newSnippet.NAME === "invalid") {
      var match$1 = newSnippet.VAL;
      var maybeNewCode = match$1.snippet;
      if (diffViewType === "split") {
        var oldCode = Belt_Option.getWithDefault(maybeOldCode, "");
        var newCode = Belt_Option.getWithDefault(maybeNewCode, "");
        var diff = DiffLines.partitionLines(DiffLines.getLines(DiffLines.computeLineInformation(oldCode, newCode)));
        return {
                NAME: "splitDiff",
                VAL: [
                  {
                    NAME: "invalid",
                    VAL: {
                      validity: validityLeft,
                      source: sourceLeft,
                      snippet: diff.left
                    }
                  },
                  {
                    NAME: "invalid",
                    VAL: {
                      validity: match$1.validity,
                      source: match$1.source,
                      snippet: diff.right
                    }
                  },
                  Belt_Option.getWithDefault(maybeNewCode, "")
                ]
              };
      }
      var oldCode$1 = Belt_Option.getWithDefault(maybeOldCode, "");
      var newCode$1 = Belt_Option.getWithDefault(maybeNewCode, "");
      var diff$1 = JsDiff.printLines(oldCode$1, newCode$1);
      var match$2 = newSnippet.VAL;
      return {
              NAME: "unifiedDiff",
              VAL: [
                {
                  NAME: "invalid",
                  VAL: {
                    validity: match$2.validity,
                    source: match$2.source,
                    snippet: diff$1
                  }
                },
                newCode$1
              ]
            };
    }
    var newCode$2 = newSnippet.VAL.snippet;
    if (diffViewType === "split") {
      var oldCode$2 = Belt_Option.getWithDefault(maybeOldCode, "");
      var diff$2 = DiffLines.partitionLines(DiffLines.getLines(DiffLines.computeLineInformation(oldCode$2, newCode$2)));
      return {
              NAME: "splitDiff",
              VAL: [
                {
                  NAME: "invalid",
                  VAL: {
                    validity: validityLeft,
                    source: sourceLeft,
                    snippet: diff$2.left
                  }
                },
                newSnippet.NAME === "pseudocode" ? ({
                      NAME: "pseudocode",
                      VAL: {
                        snippet: diff$2.right,
                        source: newSnippet.VAL.source
                      }
                    }) : ({
                      NAME: "codegen",
                      VAL: {
                        snippet: diff$2.right,
                        source: newSnippet.VAL.source
                      }
                    }),
                newCode$2
              ]
            };
    }
    var oldCode$3 = Belt_Option.getWithDefault(maybeOldCode, "");
    var diff$3 = JsDiff.printLines(oldCode$3, newCode$2);
    return {
            NAME: "unifiedDiff",
            VAL: [
              newSnippet.NAME === "pseudocode" ? ({
                    NAME: "pseudocode",
                    VAL: {
                      snippet: diff$3,
                      source: newSnippet.VAL.source
                    }
                  }) : ({
                    NAME: "codegen",
                    VAL: {
                      snippet: diff$3,
                      source: newSnippet.VAL.source
                    }
                  }),
              newCode$2
            ]
          };
  }
  var oldCode$4 = oldSnippet.VAL.snippet;
  if (typeof newSnippet === "object") {
    var variant = newSnippet.NAME;
    if (variant === "pseudocode" || variant === "codegen") {
      var newCode$3 = newSnippet.VAL.snippet;
      if (diffViewType === "split") {
        var diff$4 = DiffLines.partitionLines(DiffLines.getLines(DiffLines.computeLineInformation(oldCode$4, newCode$3)));
        return {
                NAME: "splitDiff",
                VAL: [
                  oldSnippet.NAME === "pseudocode" ? ({
                        NAME: "pseudocode",
                        VAL: {
                          snippet: diff$4.left,
                          source: oldSnippet.VAL.source
                        }
                      }) : ({
                        NAME: "codegen",
                        VAL: {
                          snippet: diff$4.left,
                          source: oldSnippet.VAL.source
                        }
                      }),
                  newSnippet.NAME === "pseudocode" ? ({
                        NAME: "pseudocode",
                        VAL: {
                          snippet: diff$4.right,
                          source: newSnippet.VAL.source
                        }
                      }) : ({
                        NAME: "codegen",
                        VAL: {
                          snippet: diff$4.right,
                          source: newSnippet.VAL.source
                        }
                      }),
                  newCode$3
                ]
              };
      }
      var diff$5 = JsDiff.printLines(oldCode$4, newCode$3);
      return {
              NAME: "unifiedDiff",
              VAL: [
                newSnippet.NAME === "pseudocode" ? ({
                      NAME: "pseudocode",
                      VAL: {
                        snippet: diff$5,
                        source: newSnippet.VAL.source
                      }
                    }) : ({
                      NAME: "codegen",
                      VAL: {
                        snippet: diff$5,
                        source: newSnippet.VAL.source
                      }
                    }),
                newCode$3
              ]
            };
    }
    
  }
  var oldCode$5 = oldSnippet.VAL.snippet;
  var match$3 = newSnippet.VAL;
  var maybeNewCode$1 = match$3.snippet;
  if (diffViewType === "split") {
    var diff$6 = DiffLines.partitionLines(DiffLines.getLines(DiffLines.computeLineInformation(oldCode$5, "")));
    return {
            NAME: "splitDiff",
            VAL: [
              oldSnippet.NAME === "pseudocode" ? ({
                    NAME: "pseudocode",
                    VAL: {
                      snippet: diff$6.left,
                      source: oldSnippet.VAL.source
                    }
                  }) : ({
                    NAME: "codegen",
                    VAL: {
                      snippet: diff$6.left,
                      source: oldSnippet.VAL.source
                    }
                  }),
              {
                NAME: "invalid",
                VAL: {
                  validity: match$3.validity,
                  source: match$3.source,
                  snippet: diff$6.right
                }
              },
              Belt_Option.getWithDefault(maybeNewCode$1, "")
            ]
          };
  }
  var newCode$4 = Belt_Option.getWithDefault(maybeNewCode$1, "");
  var diff$7 = JsDiff.printLines(oldCode$5, newCode$4);
  var match$4 = newSnippet.VAL;
  return {
          NAME: "unifiedDiff",
          VAL: [
            {
              NAME: "invalid",
              VAL: {
                validity: match$4.validity,
                source: match$4.source,
                snippet: diff$7
              }
            },
            newCode$4
          ]
        };
}

function getSnippetDiffWrapper(oldSnippet, newSnippet, diffViewType) {
  var exit = 0;
  var exit$1 = 0;
  var exit$2 = 0;
  if (diffViewType === "noDiff") {
    if (newSnippet !== undefined) {
      return {
              NAME: "noDiff",
              VAL: newSnippet
            };
    }
    if (oldSnippet !== undefined) {
      return {
              NAME: "noDiff",
              VAL: {
                NAME: "pseudocode",
                VAL: {
                  snippet: "",
                  source: oldSnippet.VAL.source
                }
              }
            };
    }
    exit$2 = 4;
  } else {
    exit$2 = 4;
  }
  if (exit$2 === 4) {
    if (oldSnippet !== undefined || newSnippet !== undefined) {
      exit$1 = 3;
    } else {
      throw {
            RE_EXN_ID: NoSnippetsProvidedException,
            Error: new Error()
          };
    }
  }
  if (exit$1 === 3) {
    if ((diffViewType === "unified" || diffViewType === "split") && oldSnippet !== undefined) {
      if (newSnippet !== undefined) {
        return makeDiff(diffViewType, oldSnippet, newSnippet);
      }
      
    } else {
      exit = 2;
    }
  }
  if (exit === 2 && (diffViewType === "unified" || diffViewType === "split") && oldSnippet === undefined) {
    var source = newSnippet.VAL.source;
    var variant = newSnippet.NAME;
    var oldSnippet$1 = variant === "pseudocode" ? ({
          NAME: "pseudocode",
          VAL: {
            snippet: "",
            source: source
          }
        }) : (
        variant === "invalid" ? ({
              NAME: "invalid",
              VAL: {
                validity: newSnippet.VAL.validity,
                source: source,
                snippet: undefined
              }
            }) : ({
              NAME: "codegen",
              VAL: {
                snippet: "",
                source: source
              }
            })
      );
    return makeDiff(diffViewType, oldSnippet$1, newSnippet);
  }
  var source$1 = oldSnippet.VAL.source;
  var variant$1 = oldSnippet.NAME;
  var newSnippet$1 = variant$1 === "pseudocode" ? ({
        NAME: "pseudocode",
        VAL: {
          snippet: "",
          source: source$1
        }
      }) : (
      variant$1 === "invalid" ? ({
            NAME: "invalid",
            VAL: {
              validity: oldSnippet.VAL.validity,
              source: source$1,
              snippet: undefined
            }
          }) : ({
            NAME: "codegen",
            VAL: {
              snippet: "",
              source: source$1
            }
          })
    );
  return makeDiff(diffViewType, oldSnippet, newSnippet$1);
}

function generateSnippetDiffWrapper(diffViewType, enrichedFromModel, enrichedToModel, eventDiffState, fromModel, serverTrackingWithDeviceId, source, toModel, $$event) {
  if (eventDiffState === "added" || eventDiffState === "new") {
    var newSnippet = getSnippet(enrichedToModel, $$event, toModel, serverTrackingWithDeviceId, source);
    return getSnippetDiffWrapper(undefined, newSnippet, diffViewType);
  }
  if (eventDiffState === "updated") {
    if (diffViewType !== "noDiff") {
      var oldSnippet = getSnippet(enrichedFromModel, $$event, fromModel, serverTrackingWithDeviceId, source);
      var newSnippet$1 = getSnippet(enrichedToModel, $$event, toModel, serverTrackingWithDeviceId, source);
      return getSnippetDiffWrapper(oldSnippet, newSnippet$1, diffViewType);
    }
    var newSnippet$2 = getSnippet(enrichedToModel, $$event, toModel, serverTrackingWithDeviceId, source);
    return getSnippetDiffWrapper(undefined, newSnippet$2, diffViewType);
  }
  var oldSnippet$1 = getSnippet(enrichedFromModel, $$event, fromModel, serverTrackingWithDeviceId, source);
  return getSnippetDiffWrapper(oldSnippet$1, undefined, diffViewType);
}

function sourceLangToString(language) {
  if (language !== undefined) {
    if (language === "java") {
      return "Java";
    } else if (language === "json") {
      return "JSON";
    } else if (language === "objc") {
      return "Objective-C";
    } else if (language === "js" || language === "js_v2") {
      return "Javascript";
    } else if (language === "re" || language === "re_v2") {
      return "Reason";
    } else if (language === "cs") {
      return "C#";
    } else if (language === "go") {
      return "Go";
    } else if (language === "kt") {
      return "Kotlin";
    } else if (language === "py3" || language === "py") {
      return "Python";
    } else if (language === "rb") {
      return "Ruby";
    } else if (language === "ts") {
      return "TypeScript";
    } else if (language === "php") {
      return "PHP";
    } else if (language === "swift") {
      return "Swift";
    } else {
      return "Dart";
    }
  } else {
    return "Pseudocode";
  }
}

function sourceLangToPrismLang(language) {
  if (language === "java") {
    return "java";
  } else if (language === "json") {
    return "json";
  } else if (language === "objc") {
    return "objectivec";
  } else if (language === "js" || language === "js_v2") {
    return "javascript";
  } else if (language === "re" || language === "re_v2") {
    return "rescript";
  } else if (language === "cs") {
    return "csharp";
  } else if (language === "go") {
    return "go";
  } else if (language === "kt") {
    return "kotlin";
  } else if (language === "py3" || language === "py") {
    return "python";
  } else if (language === "rb") {
    return "ruby";
  } else if (language === "ts") {
    return "typescript";
  } else if (language === "php") {
    return "php";
  } else if (language === "swift") {
    return "swift";
  } else {
    return "dart";
  }
}

function getHighlightLang(withDiff, snippet) {
  var variant = snippet.NAME;
  if (variant === "invalid" || variant === "pseudocode") {
    if (withDiff) {
      return "diff-javascript";
    } else {
      return "javascript";
    }
  }
  var language = Belt_Option.mapWithDefault(snippet.VAL.source.language, withDiff ? "diff-javascript" : "javascript", sourceLangToPrismLang);
  if (withDiff) {
    return Prism.getDiffLanguage(language);
  } else {
    return language;
  }
}

var queryParamKeys = {
  sourceId: "sourceId",
  eventId: "eventId",
  pageName: "p"
};

function pageNameValues(pageName) {
  return Case.camel(AnalyticsRe.pageNameToJs(pageName));
}

function getSnippetLink(eventId, sourceId, pageName) {
  var match = Router.getStaticRoute(undefined);
  var branch = match.branch;
  var baseRoute = match.baseRoute;
  var drawerItems = pageName === "BranchImplementation" ? /* [] */0 : match.drawerItems;
  var origin = Router.getOrigin(undefined);
  var path = pageName === "BranchImplementation" ? Curry._4(Router.Link.get, baseRoute, branch, {
          NAME: "diff",
          VAL: {
            NAME: "implementation",
            VAL: {
              NAME: "source",
              VAL: [
                sourceId,
                undefined
              ]
            }
          }
        }, /* [] */0) : Curry._4(Router.Link.get, baseRoute, branch, match.schemaRoute, Belt_Option.mapWithDefault(Belt_List.head(drawerItems), /* [] */0, (function (firstDrawerItem) {
                return {
                        hd: firstDrawerItem,
                        tl: /* [] */0
                      };
              })));
  var search = pageName === "BranchImplementation" ? "?" + queryParamKeys.eventId + "=" + eventId + "&" + queryParamKeys.pageName + "=" + Case.camel(AnalyticsRe.pageNameToJs(pageName)) : "?" + queryParamKeys.eventId + "=" + eventId + "&" + queryParamKeys.pageName + "=" + Case.camel(AnalyticsRe.pageNameToJs(pageName)) + "&sourceId=" + sourceId;
  return origin + path + search;
}

function useSnippetOpenedExternallyListener(pageName, eventId, eventName, schemaGroup) {
  var match = Router.QueryParams.useKey(queryParamKeys.eventId);
  var eventIdQueryParam = match.value;
  var match$1 = Router.QueryParams.useKey(queryParamKeys.pageName);
  var pageNameQueryParam = match$1.value;
  React.useEffect((function () {
          if (eventIdQueryParam !== undefined && pageNameQueryParam !== undefined && eventIdQueryParam === eventId && pageNameQueryParam === Case.camel(AnalyticsRe.pageNameToJs(pageName))) {
            AnalyticsRe.eventImplementationSnippetInteraction(schemaGroup, eventId, eventName, "LinkToSnippetOpened", pageName, "Authenticated", "Workspace", schemaGroup.branchId, schemaGroup.schemaId);
          }
          
        }), []);
  
}

function getScrollWidthOfChildWithOfType(el, type_) {
  return Belt_Option.map(Curry._2($$Window.Dom.$$Element.querySelector, el, type_), (function (el) {
                return el.scrollWidth;
              }));
}

function syncScroll(scrollRef, syncRef, isScrollingLeft, isScrollingRight, scrollingPane, param) {
  if (scrollingPane === "right") {
    isScrollingRight.current = true;
  } else {
    isScrollingLeft.current = true;
  }
  if (isScrollingLeft.current && scrollingPane === "left" || isScrollingRight.current && scrollingPane === "right") {
    syncRef.scrollLeft = scrollRef.scrollLeft;
  }
  if (scrollingPane === "right") {
    isScrollingRight.current = false;
  } else {
    isScrollingLeft.current = false;
  }
  
}

function usePaneScrollSync(param) {
  var leftRef = React.useRef(null);
  var rightRef = React.useRef(null);
  var isScrollingLeft = React.useRef(false);
  var isScrollingRight = React.useRef(false);
  React.useEffect((function () {
          var match = leftRef.current;
          var match$1 = rightRef.current;
          if (!(match == null) && !(match$1 == null)) {
            var leftWidth = getScrollWidthOfChildWithOfType(match, ".code-snippet");
            var rightWidth = getScrollWidthOfChildWithOfType(match$1, ".code-snippet");
            if (leftWidth !== undefined && rightWidth !== undefined) {
              var maxWidth = (
                leftWidth > rightWidth ? leftWidth : rightWidth
              ) + 16 | 0;
              Belt_Option.forEach(Curry._2($$Window.Dom.$$Element.querySelector, match, "div"), (function (el) {
                      el.setAttribute("style", "width: " + String(maxWidth) + "px;");
                      
                    }));
              Belt_Option.forEach(Curry._2($$Window.Dom.$$Element.querySelector, match$1, "div"), (function (el) {
                      el.setAttribute("style", "width: " + String(maxWidth) + "px;");
                      
                    }));
            }
            
          }
          
        }), []);
  React.useEffect((function () {
          var match = leftRef.current;
          var match$1 = rightRef.current;
          if (match == null) {
            return ;
          }
          if (match$1 == null) {
            return ;
          }
          var leftEventHandler = function (param) {
            return syncScroll(match, match$1, isScrollingLeft, isScrollingRight, "left", param);
          };
          var rightEventHandler = function (param) {
            return syncScroll(match$1, match, isScrollingLeft, isScrollingRight, "right", param);
          };
          match.addEventListener("scroll", leftEventHandler);
          match$1.addEventListener("scroll", rightEventHandler);
          return (function (param) {
                    match.removeEventListener("scroll", leftEventHandler);
                    match$1.removeEventListener("scroll", rightEventHandler);
                    
                  });
        }), []);
  return [
          leftRef,
          rightRef
        ];
}

function useScrollIntoView(eventId, pageName, offset, containerRef) {
  var match = Router.QueryParams.useKey(queryParamKeys.eventId);
  var removeEventIdQueryParam = match.removeByReplace;
  var eventIdQueryParam = match.value;
  var match$1 = Router.QueryParams.useKey(queryParamKeys.pageName);
  var removePageNameQueryParam = match$1.removeByReplace;
  var pageNameQueryParam = match$1.value;
  var match$2 = Router.QueryParams.useKey(queryParamKeys.sourceId);
  var removeSourceIdQueryParam = match$2.removeByReplace;
  var match$3 = React.useState(function () {
        return false;
      });
  var setIsHighlighted = match$3[1];
  var isHighlighted = match$3[0];
  var scrollTimeout = React.useRef(undefined);
  var highlightTimeout = React.useRef(undefined);
  React.useEffect((function () {
          highlightTimeout.current = Caml_option.some(setTimeout((function (param) {
                      return Curry._1(setIsHighlighted, (function (param) {
                                    if (Caml_obj.caml_equal(Case.camel(AnalyticsRe.pageNameToJs(pageName)), pageNameQueryParam)) {
                                      return Caml_obj.caml_equal(eventId, eventIdQueryParam);
                                    } else {
                                      return false;
                                    }
                                  }));
                    }), 0));
          return (function (param) {
                    return Belt_Option.forEach(highlightTimeout.current, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), [Belt_Option.getWithDefault(eventIdQueryParam, "")]);
  React.useEffect((function () {
          return (function (param) {
                    if (pageName === "Event") {
                      Curry._1(removeEventIdQueryParam, undefined);
                      Curry._1(removePageNameQueryParam, undefined);
                      return Curry._1(removeSourceIdQueryParam, undefined);
                    }
                    
                  });
        }), []);
  React.useEffect((function () {
          if (isHighlighted) {
            Belt_Option.forEach(Caml_option.nullable_to_opt(containerRef.current), (function (element) {
                    element.setAttribute("style", "outline: 2px solid " + Styles.Color.toString(Styles.Color.blue100));
                    scrollTimeout.current = Caml_option.some(setTimeout((function (param) {
                                var top = element.getBoundingClientRect().top;
                                if (pageName === "PublicBranchImplementation" || pageName === "BranchImplementation") {
                                  window.scrollBy({
                                        top: top - offset | 0,
                                        behavior: "smooth"
                                      });
                                } else if (pageName === "Event") {
                                  element.scrollIntoView({
                                        behavior: "auto",
                                        block: "start"
                                      });
                                } else {
                                  scrollTimeout.current = undefined;
                                }
                                
                              }), Caml_obj.caml_equal(pageNameQueryParam, Case.camel(AnalyticsRe.pageNameToJs("Event"))) ? 0 : 750));
                    
                  }));
          } else {
            Belt_Option.forEach(Caml_option.nullable_to_opt(containerRef.current), (function (element) {
                    element.removeAttribute("style");
                    
                  }));
          }
          return (function (param) {
                    return Belt_Option.forEach(scrollTimeout.current, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), [isHighlighted]);
  
}

exports.NoSnippetsProvidedException = NoSnippetsProvidedException;
exports.getFirstSource = getFirstSource;
exports.hasCodegenForSource = hasCodegenForSource;
exports.getSnippetTypeWithSource = getSnippetTypeWithSource;
exports.getSnippet = getSnippet;
exports.makeDiff = makeDiff;
exports.getSnippetDiffWrapper = getSnippetDiffWrapper;
exports.generateSnippetDiffWrapper = generateSnippetDiffWrapper;
exports.sourceLangToString = sourceLangToString;
exports.sourceLangToPrismLang = sourceLangToPrismLang;
exports.getHighlightLang = getHighlightLang;
exports.queryParamKeys = queryParamKeys;
exports.pageNameValues = pageNameValues;
exports.getSnippetLink = getSnippetLink;
exports.useSnippetOpenedExternallyListener = useSnippetOpenedExternallyListener;
exports.getScrollWidthOfChildWithOfType = getScrollWidthOfChildWithOfType;
exports.syncScroll = syncScroll;
exports.usePaneScrollSync = usePaneScrollSync;
exports.useScrollIntoView = useScrollIntoView;
/* Case Not a pure module */
