// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Models = require("./Models.bs.js");
var Select = require("./Select.bs.js");
var Sentry = require("./externals/Sentry.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Printer = require("../../model/src/Printer.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var TextInput = require("./TextInput.bs.js");
var Workspace = require("../../model/src/Workspace.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var TagsSelect = require("./TagsSelect.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var StripeUtils = require("./StripeUtils.bs.js");
var EmailRegex = require("email-regex");
var App = require("firebase/app");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var AppFeatureFlag = require("./AppFeatureFlag.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var CloudFunctionHooks = require("./CloudFunctionHooks.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var MembersInviteFormUtils = require("./MembersInviteFormUtils.bs.js");

function parseEmails(email) {
  var emails = email.replace(/[,]/g, "").match(EmailRegex());
  if (emails !== null) {
    return emails;
  } else {
    return [];
  }
}

function writeInvites(emails, role, schema, redirectUrl, limitAccess, filter, schemaGroup) {
  var batch = Firebase.app(undefined).firestore().batch();
  Belt_Array.forEach(emails, (function (email) {
          var inviteRef = Firebase.app(undefined).firestore().collection("invites").doc();
          var inviteId = inviteRef.id;
          var schemaInviteRef = Firebase.app(undefined).firestore().collection("schemas").doc(schema.id).collection("invites").doc(email.toLowerCase());
          var tmp = {
            id: inviteId,
            schemaId: schema.id,
            role: Models.Role.tToJs(role),
            externalId: email.toLowerCase(),
            createdAt: App.default.firestore.FieldValue.serverTimestamp(),
            createdBy: Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid
          };
          var tmp$1 = role === "CommentOnly" && limitAccess === true ? Caml_option.some(filter) : undefined;
          if (tmp$1 !== undefined) {
            tmp.filter = Caml_option.valFromOption(tmp$1);
          }
          var invite = tmp;
          var userId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
          var tmp$2 = {
            id: email.toLowerCase(),
            inviteId: inviteId,
            schemaId: schema.id,
            role: Models.Role.tToJs(role),
            createdAt: App.default.firestore.FieldValue.serverTimestamp(),
            createdBy: userId
          };
          var tmp$3 = role === "CommentOnly" && limitAccess === true ? Caml_option.some(filter) : undefined;
          if (tmp$3 !== undefined) {
            tmp$2.filter = Caml_option.valFromOption(tmp$3);
          }
          if (redirectUrl !== undefined) {
            tmp$2.redirectUrl = Caml_option.valFromOption(redirectUrl);
          }
          var schemaInvite = tmp$2;
          batch.set(inviteRef, invite);
          batch.set(schemaInviteRef, schemaInvite);
          var actionRef = Firebase.app(undefined).firestore().collection("schemas").doc(schema.id).collection("actions").doc();
          batch.set(actionRef, {
                id: actionRef.id,
                contentsJson: Printer.printAction({
                      NAME: "InviteMember",
                      VAL: [
                        email,
                        Models.Role.tToJs(role)
                      ]
                    }),
                createdAt: App.default.firestore.FieldValue.serverTimestamp(),
                createdBy: userId,
                branchId: "master",
                audit: true,
                order: 0
              });
          
        }));
  return batch.commit().then(function (param) {
              Belt_Array.forEach(emails, (function (email) {
                      AnalyticsRe.memberInvited(schemaGroup, AnalyticsUtils.roleToAnalyticsRole(role), email.toLowerCase(), schemaGroup.branchId, schemaGroup.schemaId);
                      
                    }));
              return Promise.resolve(undefined);
            });
}

function MembersInviteForm(Props) {
  var schema = Props.schema;
  var redirectUrlOpt = Props.redirectUrl;
  var model = Props.model;
  var redirectUrl = redirectUrlOpt !== undefined ? Caml_option.valFromOption(redirectUrlOpt) : undefined;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var schemaInvites = FirebaseFetcherHooks.useSchemaInvites(schema.id);
  var globalSend = GlobalSendContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var billingStats = CloudFunctionHooks.useBillingStats(schema.id, workspace.members);
  var match = React.useState(function () {
        return "";
      });
  var setEmail = match[1];
  var email = match[0];
  var match$1 = React.useState(function () {
        return /* Default */0;
      });
  var setStatus = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setLimitAccess = match$2[1];
  var limitAccess = match$2[0];
  var match$3 = React.useState(function () {
        return {
                tags: []
              };
      });
  var setFilter = match$3[1];
  var filter = match$3[0];
  var match$4 = AvoLimits.Editors.computeAvailability(workspace);
  var editorsAvailable = match$4 === "AvailableDuringTrial" || match$4 === "Available";
  var match$5 = React.useState(function () {
        if (editorsAvailable) {
          return "Editor";
        } else {
          return "Admin";
        }
      });
  var setRole = match$5[1];
  var role = match$5[0];
  var match$6 = Workspace.User.getUsedSlots(workspace);
  var userCap = match$6[1];
  var userCount = match$6[0];
  var hasUnlimitedViewers = !workspace.plan.countsViewersAsUsers;
  var adminAndEditorAvailability = AvoLimits.User.createActionStatus(AvoLimits.User.computeEntityNumericLimits(workspace, model));
  var addCommenterStatus = AvoLimits.Commenters.createActionStatus(AvoLimits.Commenters.computeEntityNumericLimits(workspace, model));
  var match$7 = workspace.plan.commentOnlyMembers;
  var addCommenterStatus$1 = addCommenterStatus === "Unavailable" && !(typeof match$7 === "number" || !(match$7.TAG === /* Limited */1 && adminAndEditorAvailability !== "Unavailable")) ? "Available" : addCommenterStatus;
  var handleAddFilterTag = function (tag) {
    return Curry._1(setFilter, (function (param) {
                  return {
                          tags: Belt_Array.concat(Belt_Array.keep(filter.tags, (function (item) {
                                      return item !== tag;
                                    })), [tag])
                        };
                }));
  };
  var handleRemoveFilterTag = function (tag) {
    return Curry._1(setFilter, (function (param) {
                  return {
                          tags: Belt_Array.keep(filter.tags, (function (item) {
                                  return item !== tag;
                                }))
                        };
                }));
  };
  var submitInvite = function (param) {
    var emails = parseEmails(email);
    var existingMemberEmails = Belt_Array.map(Belt_List.toArray(Belt_List.keepMap(workspace.members, (function (prim) {
                    return Caml_option.undefined_to_opt(prim.email);
                  }))), (function (prim) {
            return prim.toLowerCase();
          }));
    var existingInviteEmails = Belt_Array.map(Belt_List.toArray(Belt_List.map(schemaInvites, (function (prim) {
                    return prim.id;
                  }))), (function (prim) {
            return prim.toLowerCase();
          }));
    var match = Belt_Array.partitionU(emails, (function (email) {
            if (existingMemberEmails.includes(email.toLowerCase())) {
              return true;
            } else {
              return existingInviteEmails.includes(email.toLowerCase());
            }
          }));
    var newEmails = match[1];
    Belt_Array.forEachU(match[0], (function (existingInviteEmail) {
            return Curry._1(addToast, {
                        message: existingMemberEmails.includes(existingInviteEmail.toLowerCase()) ? existingInviteEmail + " is already a member" : existingInviteEmail + " has already been invited",
                        toastType: /* Info */2
                      });
          }));
    if (newEmails.length !== 0) {
      Curry._1(setStatus, (function (param) {
              return /* Loading */1;
            }));
      $$Promise.$$catch(writeInvites(newEmails, role, schema, redirectUrl, limitAccess, filter, schemaGroup).then(function (param) {
                var message = "Invite" + (
                  newEmails.length === 1 ? "" : "s"
                ) + " successfully sent!";
                Curry._1(addToast, {
                      message: message,
                      toastType: /* Success */0
                    });
                Curry._1(setStatus, (function (param) {
                        return /* Default */0;
                      }));
                Curry._1(setEmail, (function (param) {
                        return "";
                      }));
                Curry._1(setLimitAccess, (function (param) {
                        return false;
                      }));
                return Promise.resolve(undefined);
              }), (function (error) {
              console.log("Failed to send invite/s", error);
              Sentry.captureException(error, {
                    message: "Failed to send invite/s"
                  });
              var message = "Failed to send invite" + (
                newEmails.length === 1 ? "" : "s"
              );
              Curry._1(addToast, {
                    message: message,
                    toastType: /* Error */1
                  });
              Curry._1(setStatus, (function (param) {
                      return /* Default */0;
                    }));
              return Promise.resolve(undefined);
            }));
      return ;
    }
    
  };
  var membersCount = StripeUtils.countPayingAndAdditionalWorkspaceMembers(Belt_List.toArray(workspace.members), workspace.plan.usersIncluded, workspace.plan);
  var hasCodegenAccessRole = AppFeatureFlag.useFeatureFlag("CodegenAccessRole");
  var tmp;
  var exit = 0;
  if (role === "Admin" || role === "Editor") {
    exit = 1;
  } else if (role === "Viewer") {
    if ((adminAndEditorAvailability === "AvailableDuringTrial" || adminAndEditorAvailability === "Unavailable") && hasUnlimitedViewers) {
      tmp = undefined;
    } else {
      exit = 1;
    }
  } else {
    tmp = role === "CommentOnly" ? (
        addCommenterStatus$1 === "Available" ? undefined : "lightning"
      ) : (
        role === "NoAccess" ? "lightning" : undefined
      );
  }
  if (exit === 1) {
    tmp = adminAndEditorAvailability === "Available" || !(adminAndEditorAvailability === "AvailableDuringTrial" || adminAndEditorAvailability !== "Available") ? undefined : "lightning";
  }
  var tmp$1 = {
    disabled: parseEmails(email).length === 0,
    label: parseEmails(email).length > 1 ? "Send " + String(parseEmails(email).length) + " invites" : "Send invite",
    loading: match$1[0] === /* Loading */1
  };
  if (tmp !== undefined) {
    tmp$1.icon = Caml_option.valFromOption(tmp);
  }
  var bottomStyles = Curry._1(Css.style, {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey30),
            tl: {
              hd: Css.marginTop(Css.px(16)),
              tl: {
                hd: Css.paddingTop(Css.px(12)),
                tl: /* [] */0
              }
            }
          }
        }
      });
  var match$8 = workspace.trial;
  return React.createElement(React.Fragment, undefined, React.createElement("form", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("stretch"),
                          tl: {
                            hd: Css.minHeight(Css.px(30)),
                            tl: {
                              hd: Css.marginBottom(Css.px(4)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      var extraCommenterSeatCost = MembersInviteFormUtils.getInvitePrompt(workspace.plan, adminAndEditorAvailability, addCommenterStatus$1, userCount, role, billingStats);
                      if (typeof extraCommenterSeatCost !== "number") {
                        return Curry._1(globalSend, {
                                    TAG: /* OpenModal */4,
                                    _0: {
                                      NAME: "AddExtraSeat",
                                      VAL: {
                                        submitInvite: submitInvite,
                                        email: email,
                                        plan: workspace.plan,
                                        extraAdminEditorSeatCost: workspace.plan.costPerExtraSeat,
                                        existingAdminEditorExtraSeats: membersCount.adminsOverBaseline + membersCount.editorsOverBaseline | 0,
                                        extraCommenterSeatCost: extraCommenterSeatCost._0,
                                        existingCommenterExtraSeats: membersCount.commentersOverBaseline,
                                        role: role
                                      }
                                    }
                                  });
                      }
                      switch (extraCommenterSeatCost) {
                        case /* NoPrompt */0 :
                            return submitInvite(undefined);
                        case /* AddEditor */1 :
                            var extraCommenterSeatCost$1 = workspace.plan.commentOnlyMembers;
                            var tmp;
                            tmp = typeof extraCommenterSeatCost$1 === "number" ? 0 : (
                                extraCommenterSeatCost$1.TAG === /* AdditionalCost */0 ? extraCommenterSeatCost$1._0 : 0
                              );
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "AddExtraSeat",
                                          VAL: {
                                            submitInvite: submitInvite,
                                            email: email,
                                            plan: workspace.plan,
                                            extraAdminEditorSeatCost: workspace.plan.costPerExtraSeat,
                                            existingAdminEditorExtraSeats: (membersCount.adminsOverBaseline + membersCount.editorsOverBaseline | 0) + (
                                              workspace.plan.commentOnlyMembers === /* CountAsEditors */1 ? membersCount.commentersOverBaseline : 0
                                            ) | 0,
                                            extraCommenterSeatCost: tmp,
                                            existingCommenterExtraSeats: workspace.plan.commentOnlyMembers === /* CountAsEditors */1 ? 0 : membersCount.commentersOverBaseline,
                                            role: role
                                          }
                                        }
                                      });
                        case /* Upgrade */2 :
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "InviteMembers"
                                        }
                                      });
                        
                      }
                    })
                }, React.createElement(TextInput.make, {
                      onChange: (function (value) {
                          return Curry._1(setEmail, (function (param) {
                                        return value;
                                      }));
                        }),
                      placeholder: "name@email.com, …",
                      size: "Small",
                      value: email
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.px(12)),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: /* [] */0
                            }
                          })
                    }), React.createElement(Select.make, {
                      onSelect: (function (value) {
                          var role = Models.Role.tFromJs(value);
                          if (role === undefined) {
                            return Pervasives.failwith("invalid role option");
                          }
                          if (role === "Editor") {
                            if (!editorsAvailable) {
                              return Curry._1(globalSend, {
                                          TAG: /* OpenModal */4,
                                          _0: {
                                            NAME: "BillingPrompt",
                                            VAL: "InviteCommentOnly"
                                          }
                                        });
                            }
                            
                          } else if (role === "CommentOnly") {
                            if (addCommenterStatus$1 === "Unavailable") {
                              return Curry._1(globalSend, {
                                          TAG: /* OpenModal */4,
                                          _0: {
                                            NAME: "BillingPrompt",
                                            VAL: "InviteCommentOnly"
                                          }
                                        });
                            }
                            
                          } else if (role === "Admin" && adminAndEditorAvailability === "Unavailable") {
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "InviteMembers"
                                        }
                                      });
                          }
                          return Curry._1(setRole, (function (param) {
                                        return role;
                                      }));
                        }),
                      options: Belt_List.mapU(Models.roles(hasCodegenAccessRole), (function (role) {
                              return [
                                      {
                                        NAME: "Label",
                                        VAL: Models.Role.getLabel(role) + (
                                          role === "Editor" ? (
                                              editorsAvailable ? "" : " ⚡️"
                                            ) : (
                                              role === "CommentOnly" ? (
                                                  addCommenterStatus$1 === "Unavailable" ? " ⚡️" : ""
                                                ) : (
                                                  role === "Admin" && adminAndEditorAvailability === "Unavailable" ? " ⚡️" : ""
                                                )
                                            )
                                        )
                                      },
                                      Models.Role.tToJs(role)
                                    ];
                            })),
                      value: Models.Role.tToJs(role)
                    }), React.createElement(Spacer.make, {
                      width: 12
                    }), React.createElement(Button.make, tmp$1)), role === "CommentOnly" ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement("input", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding2(Css.px(0), Css.px(15)),
                              tl: {
                                hd: Css.marginRight(Css.px(10)),
                                tl: /* [] */0
                              }
                            }),
                        id: "all-events",
                        checked: limitAccess,
                        type: "checkbox",
                        onChange: (function (domEvent) {
                            var isChecked = domEvent.target.checked;
                            return Curry._1(setLimitAccess, (function (param) {
                                          return isChecked;
                                        }));
                          })
                      }), React.createElement("label", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.small),
                                tl: {
                                  hd: Css.color(Styles.Color.grey70),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        htmlFor: "all-events"
                      }, "Limit access to selected tags"), limitAccess ? React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginLeft(Css.px(15)),
                                tl: /* [] */0
                              })
                        }, React.createElement(TagsSelect.make, {
                              events: model.events,
                              selectedTags: Belt_List.fromArray(filter.tags),
                              onSelect: handleAddFilterTag,
                              onRemove: handleRemoveFilterTag
                            })) : null) : null, React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.grey70,
                  children: null
                }, "Learn more about roles in the ", React.createElement("a", {
                      className: Curry._1(Css.style, {
                            hd: Css.color(Styles.Color.blue),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.color(Styles.Color.blueSecondary),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }),
                      href: "https://www.avo.app/docs/workspace/members",
                      rel: "noopener",
                      target: "_blank"
                    }, "Avo Docs"), "."), match$8 !== undefined ? (
                typeof userCap === "object" && userCap.NAME === "Limited" && userCount > userCap.VAL ? React.createElement("div", {
                        className: bottomStyles
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.grey70,
                            children: React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.maxWidth(Css.px(400)),
                                        tl: /* [] */0
                                      })
                                }, "To continue using all your members after the trial expires, you'll need to upgrade your plan. ", React.createElement("a", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.color(Styles.Color.blue),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.textDecoration("none"),
                                                tl: {
                                                  hd: Css.transition({
                                                        NAME: "ms",
                                                        VAL: Styles.Duration.$$short
                                                      }, undefined, undefined, "color"),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.blueSecondary),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      href: "#",
                                      onClick: (function ($$event) {
                                          $$event.preventDefault();
                                          return Curry._1(globalSend, {
                                                      TAG: /* OpenModal */4,
                                                      _0: {
                                                        NAME: "BillingPrompt",
                                                        VAL: "InviteMembers"
                                                      }
                                                    });
                                        })
                                    }, "Learn more"))
                          })) : null
              ) : (
                typeof userCap === "object" && userCap.NAME === "Limited" && userCount >= userCap.VAL ? React.createElement("div", {
                        className: bottomStyles
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.grey70,
                            children: "To add more members you'll need to upgrade your plan."
                          }), React.createElement(Spacer.make, {
                            width: 16
                          }), React.createElement(Button.make, {
                            label: "Learn more",
                            onClick: (function (param) {
                                return Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "BillingPrompt",
                                              VAL: "InviteMembers"
                                            }
                                          });
                              })
                          })) : null
              ));
}

var make = MembersInviteForm;

exports.parseEmails = parseEmails;
exports.writeInvites = writeInvites;
exports.make = make;
/* Css Not a pure module */
