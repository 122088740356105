// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconRocket(Props) {
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 48;
  return React.createElement("svg", {
              width: String(size),
              viewBox: "0 0 48 48",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  className: "nc-icon-wrapper"
                }, React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey40),
                            tl: /* [] */0
                          }),
                      d: "M11.95508,23.2124c-0.26562,0-0.51953-0.10547-0.70703-0.29297l-6.85547-6.85547 c-0.32227-0.32227-0.38574-0.82227-0.1543-1.21484c0.75-1.27197,1.62891-2.41504,2.6123-3.39844 c4.95605-4.95605,12.31738-6.36621,18.75195-3.59424C25.90625,7.9873,26.125,8.25928,26.18848,8.5835 c0.06348,0.32373-0.03711,0.6582-0.26953,0.89355l-13.25195,13.4375c-0.18652,0.18994-0.44238,0.29688-0.70801,0.29785 C11.95703,23.2124,11.95605,23.2124,11.95508,23.2124z"
                    }), React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey40),
                            tl: /* [] */0
                          }),
                      d: "M32.64258,43.8999c-0.25879,0-0.51465-0.1001-0.70703-0.29297l-6.91602-6.91602 c-0.18848-0.18799-0.29395-0.44336-0.29297-0.70996c0.00098-0.26611,0.10742-0.521,0.29688-0.70801l13.44922-13.30322 c0.23438-0.23145,0.56738-0.33154,0.89062-0.27148c0.32324,0.06152,0.5957,0.27832,0.72754,0.57959 c2.84473,6.46729,1.4541,13.875-3.54199,18.87158c-0.98145,0.98193-2.125,1.86035-3.39844,2.61182 C32.99219,43.85449,32.81738,43.8999,32.64258,43.8999z"
                    }), React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey20),
                            tl: /* [] */0
                          }),
                      d: "M8.04395,27.34131c-0.14941,0.37207-0.0625,0.79688,0.2207,1.08008l11.31348,11.31348 c0.19141,0.19141,0.44727,0.29297,0.70703,0.29297c0.12793,0,0.25586-0.02441,0.37891-0.07422 c0.18848-0.07733,14.57507-6.15625,21.92511-21.36444L29.36237,5.36237C14.12976,12.69263,8.11975,27.1521,8.04395,27.34131z"
                    }), React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.magenta),
                            tl: /* [] */0
                          }),
                      d: "M46.4502,1.55273c-0.20117-0.20215-0.49414-0.31104-0.76074-0.29297 c-6.34113,0.33087-11.74622,1.89819-16.32709,4.1026l13.22681,13.22681c2.21027-4.5733,3.79181-9.96222,4.15009-16.27423 C46.75586,2.03125,46.65039,1.75439,46.4502,1.55273z"
                    }), React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.magenta),
                            tl: /* [] */0
                          }),
                      d: "M27.35547,25.64062c-1.28027,0-2.56055-0.4873-3.53516-1.46191c-1.94922-1.94922-1.94922-5.12158,0-7.0708 l0,0c1.9502-1.95117,5.12305-1.94922,7.07129,0c0.94434,0.94434,1.46484,2.19971,1.46484,3.53516 c0,1.33594-0.52051,2.59131-1.46484,3.53564C29.91699,25.15332,28.63672,25.64062,27.35547,25.64062z"
                    }), React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.orange),
                            tl: /* [] */0
                          }),
                      d: "M5.51074,45.05273c-0.73438,0-1.31543-0.03564-1.58496-0.05566 c-0.49414-0.03662-0.88672-0.4292-0.92285-0.92334c-0.07129-0.95557-0.33398-5.81396,1.46191-7.60938l0,0l0,0 C5.40918,35.52002,6.66406,35,8,35s2.59082,0.52002,3.53516,1.46436c1.94922,1.94971,1.94922,5.12158,0,7.07129 C10.24707,44.82422,7.37988,45.05273,5.51074,45.05273z"
                    })));
}

var make = IconRocket;

exports.make = make;
/* Css Not a pure module */
