// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css_AtomicTypes = require("./Css_AtomicTypes.bs.js");

var aliceblue = Css_AtomicTypes.Color.hex("F0F8FF");

var antiquewhite = Css_AtomicTypes.Color.hex("FAEBD7");

var aqua = Css_AtomicTypes.Color.hex("00FFFF");

var aquamarine = Css_AtomicTypes.Color.hex("7FFFD4");

var azure = Css_AtomicTypes.Color.hex("F0FFFF");

var beige = Css_AtomicTypes.Color.hex("F5F5DC");

var bisque = Css_AtomicTypes.Color.hex("FFE4C4");

var black = Css_AtomicTypes.Color.hex("000000");

var blanchedalmond = Css_AtomicTypes.Color.hex("FFEBCD");

var blue = Css_AtomicTypes.Color.hex("0000FF");

var blueviolet = Css_AtomicTypes.Color.hex("8A2BE2");

var brown = Css_AtomicTypes.Color.hex("A52A2A");

var burlywood = Css_AtomicTypes.Color.hex("DEB887");

var cadetblue = Css_AtomicTypes.Color.hex("5F9EA0");

var chartreuse = Css_AtomicTypes.Color.hex("7FFF00");

var chocolate = Css_AtomicTypes.Color.hex("D2691E");

var coral = Css_AtomicTypes.Color.hex("FF7F50");

var cornflowerblue = Css_AtomicTypes.Color.hex("6495ED");

var cornsilk = Css_AtomicTypes.Color.hex("FFF8DC");

var crimson = Css_AtomicTypes.Color.hex("DC143C");

var cyan = Css_AtomicTypes.Color.hex("00FFFF");

var darkblue = Css_AtomicTypes.Color.hex("00008B");

var darkcyan = Css_AtomicTypes.Color.hex("008B8B");

var darkgoldenrod = Css_AtomicTypes.Color.hex("B8860B");

var darkgray = Css_AtomicTypes.Color.hex("A9A9A9");

var darkgreen = Css_AtomicTypes.Color.hex("006400");

var darkgrey = Css_AtomicTypes.Color.hex("A9A9A9");

var darkkhaki = Css_AtomicTypes.Color.hex("BDB76B");

var darkmagenta = Css_AtomicTypes.Color.hex("8B008B");

var darkolivegreen = Css_AtomicTypes.Color.hex("556B2F");

var darkorange = Css_AtomicTypes.Color.hex("FF8C00");

var darkorchid = Css_AtomicTypes.Color.hex("9932CC");

var darkred = Css_AtomicTypes.Color.hex("8B0000");

var darksalmon = Css_AtomicTypes.Color.hex("E9967A");

var darkseagreen = Css_AtomicTypes.Color.hex("8FBC8F");

var darkslateblue = Css_AtomicTypes.Color.hex("483D8B");

var darkslategray = Css_AtomicTypes.Color.hex("2F4F4F");

var darkslategrey = Css_AtomicTypes.Color.hex("2F4F4F");

var darkturquoise = Css_AtomicTypes.Color.hex("00CED1");

var darkviolet = Css_AtomicTypes.Color.hex("9400D3");

var deeppink = Css_AtomicTypes.Color.hex("FF1493");

var deepskyblue = Css_AtomicTypes.Color.hex("00BFFF");

var dimgray = Css_AtomicTypes.Color.hex("696969");

var dimgrey = Css_AtomicTypes.Color.hex("696969");

var dodgerblue = Css_AtomicTypes.Color.hex("1E90FF");

var firebrick = Css_AtomicTypes.Color.hex("B22222");

var floralwhite = Css_AtomicTypes.Color.hex("FFFAF0");

var forestgreen = Css_AtomicTypes.Color.hex("228B22");

var fuchsia = Css_AtomicTypes.Color.hex("FF00FF");

var gainsboro = Css_AtomicTypes.Color.hex("DCDCDC");

var ghostwhite = Css_AtomicTypes.Color.hex("F8F8FF");

var gold = Css_AtomicTypes.Color.hex("FFD700");

var goldenrod = Css_AtomicTypes.Color.hex("DAA520");

var gray = Css_AtomicTypes.Color.hex("808080");

var green = Css_AtomicTypes.Color.hex("008000");

var greenyellow = Css_AtomicTypes.Color.hex("ADFF2F");

var grey = Css_AtomicTypes.Color.hex("808080");

var honeydew = Css_AtomicTypes.Color.hex("F0FFF0");

var hotpink = Css_AtomicTypes.Color.hex("FF69B4");

var indianred = Css_AtomicTypes.Color.hex("CD5C5C");

var indigo = Css_AtomicTypes.Color.hex("4B0082");

var ivory = Css_AtomicTypes.Color.hex("FFFFF0");

var khaki = Css_AtomicTypes.Color.hex("F0E68C");

var lavender = Css_AtomicTypes.Color.hex("E6E6FA");

var lavenderblush = Css_AtomicTypes.Color.hex("FFF0F5");

var lawngreen = Css_AtomicTypes.Color.hex("7CFC00");

var lemonchiffon = Css_AtomicTypes.Color.hex("FFFACD");

var lightblue = Css_AtomicTypes.Color.hex("ADD8E6");

var lightcoral = Css_AtomicTypes.Color.hex("F08080");

var lightcyan = Css_AtomicTypes.Color.hex("E0FFFF");

var lightgoldenrodyellow = Css_AtomicTypes.Color.hex("FAFAD2");

var lightgray = Css_AtomicTypes.Color.hex("D3D3D3");

var lightgreen = Css_AtomicTypes.Color.hex("90EE90");

var lightgrey = Css_AtomicTypes.Color.hex("D3D3D3");

var lightpink = Css_AtomicTypes.Color.hex("FFB6C1");

var lightsalmon = Css_AtomicTypes.Color.hex("FFA07A");

var lightseagreen = Css_AtomicTypes.Color.hex("20B2AA");

var lightskyblue = Css_AtomicTypes.Color.hex("87CEFA");

var lightslategray = Css_AtomicTypes.Color.hex("778899");

var lightslategrey = Css_AtomicTypes.Color.hex("778899");

var lightsteelblue = Css_AtomicTypes.Color.hex("B0C4DE");

var lightyellow = Css_AtomicTypes.Color.hex("FFFFE0");

var lime = Css_AtomicTypes.Color.hex("00FF00");

var limegreen = Css_AtomicTypes.Color.hex("32CD32");

var linen = Css_AtomicTypes.Color.hex("FAF0E6");

var magenta = Css_AtomicTypes.Color.hex("FF00FF");

var maroon = Css_AtomicTypes.Color.hex("800000");

var mediumaquamarine = Css_AtomicTypes.Color.hex("66CDAA");

var mediumblue = Css_AtomicTypes.Color.hex("0000CD");

var mediumorchid = Css_AtomicTypes.Color.hex("BA55D3");

var mediumpurple = Css_AtomicTypes.Color.hex("9370DB");

var mediumseagreen = Css_AtomicTypes.Color.hex("3CB371");

var mediumslateblue = Css_AtomicTypes.Color.hex("7B68EE");

var mediumspringgreen = Css_AtomicTypes.Color.hex("00FA9A");

var mediumturquoise = Css_AtomicTypes.Color.hex("48D1CC");

var mediumvioletred = Css_AtomicTypes.Color.hex("C71585");

var midnightblue = Css_AtomicTypes.Color.hex("191970");

var mintcream = Css_AtomicTypes.Color.hex("F5FFFA");

var mistyrose = Css_AtomicTypes.Color.hex("FFE4E1");

var moccasin = Css_AtomicTypes.Color.hex("FFE4B5");

var navajowhite = Css_AtomicTypes.Color.hex("FFDEAD");

var navy = Css_AtomicTypes.Color.hex("000080");

var oldlace = Css_AtomicTypes.Color.hex("FDF5E6");

var olive = Css_AtomicTypes.Color.hex("808000");

var olivedrab = Css_AtomicTypes.Color.hex("6B8E23");

var orange = Css_AtomicTypes.Color.hex("FFA500");

var orangered = Css_AtomicTypes.Color.hex("FF4500");

var orchid = Css_AtomicTypes.Color.hex("DA70D6");

var palegoldenrod = Css_AtomicTypes.Color.hex("EEE8AA");

var palegreen = Css_AtomicTypes.Color.hex("98FB98");

var paleturquoise = Css_AtomicTypes.Color.hex("AFEEEE");

var palevioletred = Css_AtomicTypes.Color.hex("DB7093");

var papayawhip = Css_AtomicTypes.Color.hex("FFEFD5");

var peachpuff = Css_AtomicTypes.Color.hex("FFDAB9");

var peru = Css_AtomicTypes.Color.hex("CD853F");

var pink = Css_AtomicTypes.Color.hex("FFC0CB");

var plum = Css_AtomicTypes.Color.hex("DDA0DD");

var powderblue = Css_AtomicTypes.Color.hex("B0E0E6");

var purple = Css_AtomicTypes.Color.hex("800080");

var rebeccapurple = Css_AtomicTypes.Color.hex("663399");

var red = Css_AtomicTypes.Color.hex("FF0000");

var rosybrown = Css_AtomicTypes.Color.hex("BC8F8F");

var royalblue = Css_AtomicTypes.Color.hex("4169E1");

var saddlebrown = Css_AtomicTypes.Color.hex("8B4513");

var salmon = Css_AtomicTypes.Color.hex("FA8072");

var sandybrown = Css_AtomicTypes.Color.hex("F4A460");

var seagreen = Css_AtomicTypes.Color.hex("2E8B57");

var seashell = Css_AtomicTypes.Color.hex("FFF5EE");

var sienna = Css_AtomicTypes.Color.hex("A0522D");

var silver = Css_AtomicTypes.Color.hex("C0C0C0");

var skyblue = Css_AtomicTypes.Color.hex("87CEEB");

var slateblue = Css_AtomicTypes.Color.hex("6A5ACD");

var slategray = Css_AtomicTypes.Color.hex("708090");

var slategrey = Css_AtomicTypes.Color.hex("708090");

var snow = Css_AtomicTypes.Color.hex("FFFAFA");

var springgreen = Css_AtomicTypes.Color.hex("00FF7F");

var steelblue = Css_AtomicTypes.Color.hex("4682B4");

var tan = Css_AtomicTypes.Color.hex("D2B48C");

var teal = Css_AtomicTypes.Color.hex("008080");

var thistle = Css_AtomicTypes.Color.hex("D8BFD8");

var tomato = Css_AtomicTypes.Color.hex("FF6347");

var turquoise = Css_AtomicTypes.Color.hex("40E0D0");

var violet = Css_AtomicTypes.Color.hex("EE82EE");

var wheat = Css_AtomicTypes.Color.hex("F5DEB3");

var white = Css_AtomicTypes.Color.hex("FFFFFF");

var whitesmoke = Css_AtomicTypes.Color.hex("F5F5F5");

var yellow = Css_AtomicTypes.Color.hex("FFFF00");

var yellowgreen = Css_AtomicTypes.Color.hex("9ACD32");

var transparent = Css_AtomicTypes.Color.transparent;

exports.aliceblue = aliceblue;
exports.antiquewhite = antiquewhite;
exports.aqua = aqua;
exports.aquamarine = aquamarine;
exports.azure = azure;
exports.beige = beige;
exports.bisque = bisque;
exports.black = black;
exports.blanchedalmond = blanchedalmond;
exports.blue = blue;
exports.blueviolet = blueviolet;
exports.brown = brown;
exports.burlywood = burlywood;
exports.cadetblue = cadetblue;
exports.chartreuse = chartreuse;
exports.chocolate = chocolate;
exports.coral = coral;
exports.cornflowerblue = cornflowerblue;
exports.cornsilk = cornsilk;
exports.crimson = crimson;
exports.cyan = cyan;
exports.darkblue = darkblue;
exports.darkcyan = darkcyan;
exports.darkgoldenrod = darkgoldenrod;
exports.darkgray = darkgray;
exports.darkgreen = darkgreen;
exports.darkgrey = darkgrey;
exports.darkkhaki = darkkhaki;
exports.darkmagenta = darkmagenta;
exports.darkolivegreen = darkolivegreen;
exports.darkorange = darkorange;
exports.darkorchid = darkorchid;
exports.darkred = darkred;
exports.darksalmon = darksalmon;
exports.darkseagreen = darkseagreen;
exports.darkslateblue = darkslateblue;
exports.darkslategray = darkslategray;
exports.darkslategrey = darkslategrey;
exports.darkturquoise = darkturquoise;
exports.darkviolet = darkviolet;
exports.deeppink = deeppink;
exports.deepskyblue = deepskyblue;
exports.dimgray = dimgray;
exports.dimgrey = dimgrey;
exports.dodgerblue = dodgerblue;
exports.firebrick = firebrick;
exports.floralwhite = floralwhite;
exports.forestgreen = forestgreen;
exports.fuchsia = fuchsia;
exports.gainsboro = gainsboro;
exports.ghostwhite = ghostwhite;
exports.gold = gold;
exports.goldenrod = goldenrod;
exports.gray = gray;
exports.green = green;
exports.greenyellow = greenyellow;
exports.grey = grey;
exports.honeydew = honeydew;
exports.hotpink = hotpink;
exports.indianred = indianred;
exports.indigo = indigo;
exports.ivory = ivory;
exports.khaki = khaki;
exports.lavender = lavender;
exports.lavenderblush = lavenderblush;
exports.lawngreen = lawngreen;
exports.lemonchiffon = lemonchiffon;
exports.lightblue = lightblue;
exports.lightcoral = lightcoral;
exports.lightcyan = lightcyan;
exports.lightgoldenrodyellow = lightgoldenrodyellow;
exports.lightgray = lightgray;
exports.lightgreen = lightgreen;
exports.lightgrey = lightgrey;
exports.lightpink = lightpink;
exports.lightsalmon = lightsalmon;
exports.lightseagreen = lightseagreen;
exports.lightskyblue = lightskyblue;
exports.lightslategray = lightslategray;
exports.lightslategrey = lightslategrey;
exports.lightsteelblue = lightsteelblue;
exports.lightyellow = lightyellow;
exports.lime = lime;
exports.limegreen = limegreen;
exports.linen = linen;
exports.magenta = magenta;
exports.maroon = maroon;
exports.mediumaquamarine = mediumaquamarine;
exports.mediumblue = mediumblue;
exports.mediumorchid = mediumorchid;
exports.mediumpurple = mediumpurple;
exports.mediumseagreen = mediumseagreen;
exports.mediumslateblue = mediumslateblue;
exports.mediumspringgreen = mediumspringgreen;
exports.mediumturquoise = mediumturquoise;
exports.mediumvioletred = mediumvioletred;
exports.midnightblue = midnightblue;
exports.mintcream = mintcream;
exports.mistyrose = mistyrose;
exports.moccasin = moccasin;
exports.navajowhite = navajowhite;
exports.navy = navy;
exports.oldlace = oldlace;
exports.olive = olive;
exports.olivedrab = olivedrab;
exports.orange = orange;
exports.orangered = orangered;
exports.orchid = orchid;
exports.palegoldenrod = palegoldenrod;
exports.palegreen = palegreen;
exports.paleturquoise = paleturquoise;
exports.palevioletred = palevioletred;
exports.papayawhip = papayawhip;
exports.peachpuff = peachpuff;
exports.peru = peru;
exports.pink = pink;
exports.plum = plum;
exports.powderblue = powderblue;
exports.purple = purple;
exports.rebeccapurple = rebeccapurple;
exports.red = red;
exports.rosybrown = rosybrown;
exports.royalblue = royalblue;
exports.saddlebrown = saddlebrown;
exports.salmon = salmon;
exports.sandybrown = sandybrown;
exports.seagreen = seagreen;
exports.seashell = seashell;
exports.sienna = sienna;
exports.silver = silver;
exports.skyblue = skyblue;
exports.slateblue = slateblue;
exports.slategray = slategray;
exports.slategrey = slategrey;
exports.snow = snow;
exports.springgreen = springgreen;
exports.steelblue = steelblue;
exports.tan = tan;
exports.teal = teal;
exports.thistle = thistle;
exports.tomato = tomato;
exports.turquoise = turquoise;
exports.violet = violet;
exports.wheat = wheat;
exports.white = white;
exports.whitesmoke = whitesmoke;
exports.yellow = yellow;
exports.yellowgreen = yellowgreen;
exports.transparent = transparent;
/* aliceblue Not a pure module */
