// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Styles = require("./styles.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var CopyTextToClipboard = require("copy-text-to-clipboard");

var defaultStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css_Legacy_Core.SVG.fill(Styles.Color.blue),
                tl: {
                  hd: Css_Legacy_Core.SVG.stroke(Styles.Color.blue),
                  tl: {
                    hd: Css.hover({
                          hd: Css_Legacy_Core.SVG.fill(Styles.Color.blueSecondary),
                          tl: {
                            hd: Css_Legacy_Core.SVG.stroke(Styles.Color.blueSecondary),
                            tl: /* [] */0
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function CopyButton$Icon(Props) {
  var copyString = Props.copyString;
  var customColorOpt = Props.color;
  var darkenOpt = Props.darken;
  var iconOpt = Props.icon;
  var onClick = Props.onClick;
  var customColor = customColorOpt !== undefined ? customColorOpt : Styles.Color.grey80;
  var darken = darkenOpt !== undefined ? darkenOpt : false;
  var icon = iconOpt !== undefined ? iconOpt : "copy";
  var match = React.useState(function () {
        return false;
      });
  var setShowInteracted = match[1];
  var showInteracted = match[0];
  var timeout = {
    contents: undefined
  };
  var handleCopy = function (param) {
    CopyTextToClipboard(copyString);
    Belt_Option.forEach(onClick, (function (cb) {
            return Curry._1(cb, undefined);
          }));
    Curry._1(setShowInteracted, (function (param) {
            return true;
          }));
    timeout.contents = Caml_option.some(setTimeout((function (param) {
                return Curry._1(setShowInteracted, (function (param) {
                              return false;
                            }));
              }), 2000));
    
  };
  React.useEffect((function () {
          return (function (param) {
                    return Belt_Option.forEach(timeout.contents, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), []);
  var modifier = darken ? Styles.Color.darken : Styles.Color.lighten;
  return React.createElement(Button.make, {
              icon: showInteracted ? "simpleCheckmark" : icon,
              onClick: handleCopy,
              size: "tiny",
              style: {
                NAME: "custom",
                VAL: [
                  showInteracted ? Styles.Color.green100 : customColor,
                  showInteracted ? Curry._2(modifier, Styles.Color.green100, 20) : Curry._2(modifier, customColor, 20),
                  false
                ]
              }
            });
}

var Icon = {
  make: CopyButton$Icon
};

function CopyButton(Props) {
  var copyString = Props.copyString;
  var stylesOpt = Props.styles;
  var icon = Props.icon;
  var children = Props.children;
  var onClick = Props.onClick;
  var styles = stylesOpt !== undefined ? stylesOpt : defaultStyles;
  var addToast = Toast.useAddToast(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setShowInteracted = match[1];
  var timeout = {
    contents: undefined
  };
  var handleCopy = function (param) {
    CopyTextToClipboard(copyString);
    Belt_Option.forEach(onClick, (function (cb) {
            return Curry._1(cb, undefined);
          }));
    Curry._1(setShowInteracted, (function (param) {
            return true;
          }));
    timeout.contents = Caml_option.some(setTimeout((function (param) {
                return Curry._1(setShowInteracted, (function (param) {
                              return false;
                            }));
              }), 2000));
    if (icon === undefined) {
      return Curry._1(addToast, {
                  message: "Copied!",
                  toastType: /* Success */0
                });
    }
    
  };
  React.useEffect((function () {
          return (function (param) {
                    return Belt_Option.forEach(timeout.contents, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), []);
  var tmp = {
    label: "Copy",
    onClick: handleCopy,
    size: "tiny"
  };
  var tmp$1 = match[0] ? Belt_Option.map(icon, (function (param) {
            return "simpleCheckmark";
          })) : icon;
  if (tmp$1 !== undefined) {
    tmp.icon = Caml_option.valFromOption(tmp$1);
  }
  return Belt_Option.mapWithDefault(children, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.selector(" button", {
                              hd: Css.padding(Css.px(5)),
                              tl: {
                                hd: Css.paddingRight(Css.px(6)),
                                tl: /* [] */0
                              }
                            }),
                        tl: /* [] */0
                      })
                }, React.createElement(Button.make, tmp)), (function (children) {
                return React.createElement("button", {
                            className: styles,
                            onClick: handleCopy
                          }, children);
              }));
}

var make = CopyButton;

exports.defaultStyles = defaultStyles;
exports.Icon = Icon;
exports.make = make;
/* defaultStyles Not a pure module */
