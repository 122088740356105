// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Case = require("../../bs-case/src/case.bs.js");
var $$Text = require("./Text.bs.js");
var Case$1 = require("case");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var JsDiff = require("./externals/JsDiff.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Printer = require("../../model/src/Printer.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var DiffEvent = require("./DiffEvent.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ContextMenu = require("./ContextMenu.bs.js");
var DiffComments = require("./DiffComments.bs.js");
var PropertyUtils = require("./PropertyUtils.bs.js");
var AppFeatureFlag = require("./AppFeatureFlag.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var IconDescription = require("./IconDescription.bs.js");
var IconArrowDownEast = require("./IconArrowDownEast.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var DiffItemAuditIssues = require("./DiffItemAuditIssues.bs.js");
var PropertyAbsenceViewHelpers = require("./PropertyAbsenceViewHelpers.bs.js");

function printEventAbsence(absence, eventId, eventName) {
  if (typeof absence === "number") {
    return "Always sent with " + eventName;
  }
  if (absence.TAG === /* SometimesSent */0) {
    var description = absence._0;
    return "Sometimes sent with " + (eventName + (
              description === "" ? "" : ": " + description
            ));
  }
  var match = Belt_MapString.get(absence._0, eventId);
  if (match === undefined) {
    return "Always sent with " + eventName;
  }
  if (match.TAG !== /* AllSources */0) {
    return ;
  }
  var description$1 = match._0;
  if (!description$1) {
    return "Never sent with " + eventName;
  }
  var description$2 = description$1._0;
  return "Sometimes sent with " + (eventName + (
            description$2 === "" ? "" : ": " + description$2
          ));
}

function renderAbsenceTextDiff(nextEvents, absence, nextAbsence) {
  var getStringForAbsence = function (absence) {
    if (typeof absence === "number") {
      return "Always sent for all events";
    }
    if (absence.TAG !== /* SometimesSent */0) {
      return "";
    }
    var description = absence._0;
    if (description === "") {
      return "Sometimes sent for all events";
    } else {
      return "Sometimes sent for all events: " + description;
    }
  };
  var nextAbsence$1;
  if (nextAbsence !== undefined && !(typeof nextAbsence === "number" || nextAbsence.TAG === /* SometimesSent */0)) {
    var absence$1 = nextAbsence._0;
    var absence$2 = Belt_List.map(nextEvents, (function ($$event) {
            return Belt_MapString.get(absence$1, $$event.id);
          }));
    nextAbsence$1 = Belt_List.everyU(absence$2, (function (absence) {
            return Caml_obj.caml_equal(absence, {
                        TAG: /* AllSources */0,
                        _0: /* SometimesSent */{
                          _0: ""
                        }
                      });
          })) ? ({
          TAG: /* SometimesSent */0,
          _0: ""
        }) : nextAbsence;
  } else {
    nextAbsence$1 = nextAbsence;
  }
  var exit = 0;
  var absence$3;
  var nextAbsence$2;
  if (absence !== undefined) {
    var exit$1 = 0;
    var absence$4;
    if (typeof absence === "number") {
      if (nextAbsence$1 !== undefined) {
        if (typeof nextAbsence$1 === "number") {
          return Caml_option.some(null);
        }
        absence$4 = absence;
        exit$1 = 3;
      } else {
        absence$4 = absence;
        exit$1 = 3;
      }
    } else {
      if (absence.TAG !== /* SometimesSent */0) {
        if (nextAbsence$1 !== undefined) {
          return ;
        } else {
          return Caml_option.some(null);
        }
      }
      var description = absence._0;
      if (nextAbsence$1 !== undefined) {
        if (typeof nextAbsence$1 === "number") {
          absence$3 = absence;
          nextAbsence$2 = nextAbsence$1;
          exit = 2;
        } else if (nextAbsence$1.TAG === /* SometimesSent */0) {
          absence$4 = absence;
          exit$1 = 3;
        } else {
          var absence$5 = nextAbsence$1._0;
          var eventDiffs = Belt_Array.map(Belt_Array.keepMap(Belt_MapString.toArray(absence$5), (function (param) {
                      var sourceAbsence = param[1];
                      var eventId = param[0];
                      return Belt_Option.map(Belt_List.getBy(nextEvents, (function ($$event) {
                                        return $$event.id === eventId;
                                      })), (function ($$event) {
                                    return [
                                            eventId,
                                            $$event.name,
                                            sourceAbsence
                                          ];
                                  }));
                    })), (function (param) {
                  var eventName = param[1];
                  var eventId = param[0];
                  var printedEventAbsence = printEventAbsence({
                        TAG: /* SometimesSent */0,
                        _0: description
                      }, eventId, eventName);
                  var nextPrintedEventAbsence = printEventAbsence({
                        TAG: /* Mixed */1,
                        _0: absence$5
                      }, eventId, eventName);
                  if (printedEventAbsence !== undefined && nextPrintedEventAbsence !== undefined) {
                    return Caml_option.some(JsDiff.renderStringDiff(undefined, undefined, undefined, printedEventAbsence, nextPrintedEventAbsence));
                  }
                  
                }));
          if (Caml_obj.caml_notequal(eventDiffs, []) && Belt_Array.every(eventDiffs, Belt_Option.isSome)) {
            return Caml_option.some(Belt_Array.map(Belt_Array.keepMap(eventDiffs, (function (diff) {
                                  return diff;
                                })), (function (diff) {
                              return React.createElement("div", undefined, diff);
                            })));
          } else {
            return ;
          }
        }
      } else {
        absence$4 = absence;
        exit$1 = 3;
      }
    }
    if (exit$1 === 3) {
      if (nextAbsence$1 === undefined) {
        return Caml_option.some(null);
      }
      if (typeof nextAbsence$1 === "number" || nextAbsence$1.TAG !== /* SometimesSent */0) {
        exit = 1;
      } else {
        absence$3 = absence$4;
        nextAbsence$2 = nextAbsence$1;
        exit = 2;
      }
    }
    
  } else {
    if (nextAbsence$1 === undefined) {
      return Caml_option.some(null);
    }
    var exit$2 = 0;
    if (typeof nextAbsence$1 === "number" || nextAbsence$1.TAG === /* SometimesSent */0) {
      exit$2 = 3;
    } else {
      exit = 1;
    }
    if (exit$2 === 3) {
      return Caml_option.some(JsDiff.renderStringDiff(undefined, undefined, undefined, "Always sent for all events", getStringForAbsence(nextAbsence$1)));
    }
    
  }
  switch (exit) {
    case 1 :
        var absence$6 = nextAbsence$1._0;
        var eventDiffs$1 = Belt_Array.map(Belt_Array.keepMap(Belt_MapString.toArray(absence$6), (function (param) {
                    var sourceAbsence = param[1];
                    var eventId = param[0];
                    return Belt_Option.map(Belt_List.getBy(nextEvents, (function ($$event) {
                                      return $$event.id === eventId;
                                    })), (function ($$event) {
                                  return [
                                          eventId,
                                          $$event.name,
                                          sourceAbsence
                                        ];
                                }));
                  })), (function (param) {
                return Belt_Option.map(printEventAbsence({
                                TAG: /* Mixed */1,
                                _0: absence$6
                              }, param[0], param[1]), (function (printedEventAbsence) {
                              return JsDiff.renderStringDiff(undefined, undefined, undefined, "", printedEventAbsence);
                            }));
              }));
        if (Caml_obj.caml_notequal(eventDiffs$1, []) && Belt_Array.every(eventDiffs$1, Belt_Option.isSome)) {
          return Caml_option.some(Belt_Array.map(Belt_Array.keepMap(eventDiffs$1, (function (diff) {
                                return diff;
                              })), (function (diff) {
                            return React.createElement("div", undefined, diff);
                          })));
        } else {
          return ;
        }
    case 2 :
        return Caml_option.some(JsDiff.renderStringDiff(undefined, undefined, undefined, getStringForAbsence(absence$3), getStringForAbsence(nextAbsence$2)));
    
  }
}

var tableBoxStyles = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey20),
          tl: {
            hd: Css.borderRight(Css.px(1), "solid", Styles.Color.grey20),
            tl: {
              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey20),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.marginTop(Css.px(4)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var tableStyles = Curry._1(Css.style, {
      hd: Css.borderSpacing(Css.px(0)),
      tl: {
        hd: Css.minWidth(Css.pct(100.0)),
        tl: /* [] */0
      }
    });

var tableHeaderCellStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(4), Css.px(16), Css.px(4), Css.px(16)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.grey20),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.grey20),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
            tl: {
              hd: Css.selector(":first-child", {
                    hd: Css.position("sticky"),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: /* [] */0
                    }
                  }),
              tl: {
                hd: Css.selector(":last-child", {
                      hd: Css.borderRightWidth(Css.px(0)),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.selector(":not(:first-child)", {
                        hd: Css.textAlign("center"),
                        tl: {
                          hd: Css.verticalAlign("middle"),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var tableNameCellStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(8), Css.px(16), Css.px(8), Css.px(16)),
      tl: {
        hd: Css.borderLeftWidth(Css.px(0)),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.grey20),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
            tl: {
              hd: Css.position("sticky"),
              tl: {
                hd: Css.left(Css.px(0)),
                tl: {
                  hd: Css.background(Styles.Color.white),
                  tl: {
                    hd: Css.zIndex(1),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var tableSelectCellStyles = Curry._1(Css.style, {
      hd: Css.selector(":last-child", {
            hd: Css.borderRightWidth(Css.px(0)),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.padding(Css.px(4)),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.grey20),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
            tl: {
              hd: Css.textAlign("center"),
              tl: {
                hd: Css.verticalAlign("middle"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function renderPropertyMatrixDiff(masterModel, branchModel, masterAbsence, nextProperty) {
  var matrix = PropertyAbsenceViewHelpers.getFullStateMatrix(StateUtils.eventsSendingProperty(masterModel, undefined, nextProperty.id), masterModel.sources, masterAbsence);
  var nextMatrix = PropertyAbsenceViewHelpers.getFullStateMatrix(StateUtils.eventsSendingProperty(branchModel, undefined, nextProperty.id), branchModel.sources, nextProperty.absence);
  var changedAndAddedEvents = Belt_MapString.keep(nextMatrix, (function (eventId, nextSources) {
          var sources = Belt_MapString.get(matrix, eventId);
          if (sources !== undefined) {
            return Caml_obj.caml_notequal(Caml_option.valFromOption(sources), nextSources);
          } else {
            return true;
          }
        }));
  var match = PropertyAbsenceViewHelpers.getSimplestModeForStateMatrix(matrix);
  var match$1 = PropertyAbsenceViewHelpers.getSimplestModeForStateMatrix(nextMatrix);
  if (match < 2 && match$1 < 2) {
    var printSourceByEvent = function (matrixEvent, eventName) {
      return Belt_Option.mapWithDefault(Belt_Option.flatMap(matrixEvent, (function (sourceAbsence) {
                        return Belt_MapString.findFirstBy(sourceAbsence, (function (_sourceId, absence) {
                                      return absence !== /* Unavailable */0;
                                    }));
                      })), "Unavailable for " + eventName, (function (param) {
                    return PropertyAbsenceViewHelpers.getEventAndSourceStateString(param[1]) + (" sent with " + eventName);
                  }));
    };
    return React.createElement($$Text.make, {
                element: "Div",
                size: "Small",
                color: Styles.Color.grey70,
                children: Belt_Array.map(Belt_MapString.toArray(changedAndAddedEvents), (function (param) {
                        var eventId = param[0];
                        var eventName = Belt_Option.mapWithDefault(StateUtils.getEventById(eventId, branchModel), "Untitled Event", (function ($$event) {
                                return $$event.name;
                              }));
                        return React.createElement("span", {
                                    key: eventId,
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("block"),
                                          tl: /* [] */0
                                        })
                                  }, JsDiff.renderStringDiff(undefined, true, false, printSourceByEvent(Belt_MapString.get(matrix, eventId), ""), printSourceByEvent(Caml_option.some(param[1]), "")), eventName);
                      }))
              });
  }
  var events = StateUtils.eventsSendingProperty(branchModel, undefined, nextProperty.id);
  var allEventSources = PropertyAbsenceViewHelpers.getAllEventSources(branchModel.sources, events);
  var n = Belt_MapString.size(changedAndAddedEvents);
  return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  children: null
                }, "Sent in:", React.createElement($$Text.make, {
                      element: "Span",
                      color: Styles.Color.grey50,
                      children: n !== 1 ? " (showing " + String(n) + " events" : " (showing one changed event)"
                    })), React.createElement("div", {
                  className: tableBoxStyles
                }, React.createElement("table", {
                      className: tableStyles
                    }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("td", {
                                  className: tableHeaderCellStyles
                                }, React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Semi",
                                      color: Styles.Color.grey80,
                                      children: "Event Name"
                                    })), Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Belt_List.head(events), (function ($$event) {
                                            return Belt_MapString.get(nextMatrix, $$event.id);
                                          })), (function (sourceIdMap) {
                                        return Belt_Array.map(Belt_Array.keepMap(Belt_MapString.keysToArray(sourceIdMap), (function (sourceId) {
                                                          return Belt_List.getBy(allEventSources, (function (source) {
                                                                        return source.id === sourceId;
                                                                      }));
                                                        })), (function (source) {
                                                      return React.createElement("td", {
                                                                  className: tableHeaderCellStyles
                                                                }, React.createElement($$Text.make, {
                                                                      size: "Small",
                                                                      weight: "Semi",
                                                                      children: Belt_Option.getWithDefault(source.name, "Untitled")
                                                                    }));
                                                    }));
                                      })), null))), React.createElement("tbody", undefined, Belt_MapString.valuesToArray(Belt_MapString.mapWithKey(nextMatrix, (function (eventId, sourceMap) {
                                    return React.createElement("tr", undefined, React.createElement("td", {
                                                    className: tableNameCellStyles
                                                  }, React.createElement($$Text.make, {
                                                        size: "Small",
                                                        weight: "Semi",
                                                        children: Belt_Option.mapWithDefault(Belt_List.getBy(events, (function ($$event) {
                                                                    return $$event.id === eventId;
                                                                  })), "Untitled", (function ($$event) {
                                                                return $$event.name;
                                                              }))
                                                      })), Belt_MapString.valuesToArray(Belt_MapString.mapWithKey(sourceMap, (function (sourceId, state) {
                                                          return React.createElement("td", {
                                                                      className: tableSelectCellStyles
                                                                    }, React.createElement($$Text.make, {
                                                                          size: "Small",
                                                                          color: Styles.Color.grey50,
                                                                          children: JsDiff.renderStringDiff("inlineBlock", true, undefined, PropertyAbsenceViewHelpers.getEventAndSourceStateString(Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_MapString.get(matrix, eventId), (function (sources) {
                                                                                              return Belt_MapString.get(sources, sourceId);
                                                                                            })), /* Unavailable */0)), PropertyAbsenceViewHelpers.getEventAndSourceStateString(state))
                                                                        }));
                                                        }))));
                                  })))))));
}

function renderSystemPropertyAbsenceDiff(masterModel, branchModel, masterAbsence, nextProperty) {
  var propertyAbsenceMap = PropertyAbsenceViewHelpers.getFullSysAbsenceMap(masterModel.sources, masterAbsence);
  var nextPropertyAbsenceMap = PropertyAbsenceViewHelpers.getFullSysAbsenceMap(branchModel.sources, nextProperty.absence);
  var match = PropertyAbsenceViewHelpers.getSimplestModeForAbsenceMap(propertyAbsenceMap);
  var match$1 = PropertyAbsenceViewHelpers.getSimplestModeForAbsenceMap(nextPropertyAbsenceMap);
  if (match < 2 && match$1 < 2) {
    var printSource = function (sourceAbsence) {
      return Belt_Option.getWithDefault(Belt_Option.map(sourceAbsence, (function (mode) {
                        return PropertyAbsenceViewHelpers.getEventAndSourceStateString(mode) + " sent with all events";
                      })), "Always sent with all events");
    };
    var sourceId = Belt_Option.getWithDefault(Belt_Option.map(Belt_List.head(masterModel.sources), (function (source) {
                return source.id;
              })), "untitled");
    return React.createElement($$Text.make, {
                element: "Div",
                size: "Small",
                color: Styles.Color.grey70,
                children: React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("block"),
                            tl: /* [] */0
                          })
                    }, JsDiff.renderStringDiff(undefined, true, false, printSource(Belt_MapString.get(propertyAbsenceMap, sourceId)), printSource(Belt_MapString.get(nextPropertyAbsenceMap, sourceId))), " on all sources")
              });
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  children: React.createElement($$Text.make, {
                        element: "Span",
                        color: Styles.Color.grey50,
                        children: "showing changes"
                      })
                }), React.createElement("div", {
                  className: tableBoxStyles
                }, React.createElement("table", {
                      className: tableStyles
                    }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("td", {
                                  className: tableHeaderCellStyles
                                }, React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Semi",
                                      color: Styles.Color.grey80,
                                      children: "Source Name"
                                    })), React.createElement("td", {
                                  className: tableHeaderCellStyles
                                }, React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Semi",
                                      children: "Presence"
                                    })))), React.createElement("tbody", undefined, Belt_MapString.valuesToArray(Belt_MapString.mapWithKey(nextPropertyAbsenceMap, (function (sourceId, mode) {
                                    return React.createElement("tr", undefined, React.createElement("td", {
                                                    className: tableNameCellStyles
                                                  }, React.createElement($$Text.make, {
                                                        size: "Small",
                                                        weight: "Semi",
                                                        children: Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_List.getBy(branchModel.sources, (function (source) {
                                                                        return source.id === sourceId;
                                                                      })), (function (source) {
                                                                    return source.name;
                                                                  })), "Untitled")
                                                      })), React.createElement("td", {
                                                    className: tableSelectCellStyles
                                                  }, React.createElement($$Text.make, {
                                                        size: "Small",
                                                        color: Styles.Color.grey50,
                                                        children: JsDiff.renderStringDiff("inlineBlock", true, undefined, PropertyAbsenceViewHelpers.getEventAndSourceStateString(Belt_Option.getWithDefault(Belt_MapString.get(propertyAbsenceMap, sourceId), /* Unavailable */0)), PropertyAbsenceViewHelpers.getEventAndSourceStateString(mode))
                                                      })));
                                  })))))));
}

function DiffProperty$DiffAbsence(Props) {
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var masterAbsence = Props.masterAbsence;
  var nextProperty = Props.nextProperty;
  var match = nextProperty.sendAs;
  return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                  height: 8
                }), Belt_Option.getWithDefault(Belt_Option.map(renderAbsenceTextDiff(branchModel.events, masterAbsence, nextProperty.absence), (function (diff) {
                        return React.createElement($$Text.make, {
                                    element: "Div",
                                    size: "Small",
                                    color: Styles.Color.grey70,
                                    children: diff
                                  });
                      })), match === 0 ? renderSystemPropertyAbsenceDiff(masterModel, branchModel, masterAbsence, nextProperty) : renderPropertyMatrixDiff(masterModel, branchModel, masterAbsence, nextProperty)));
}

var DiffAbsence = {
  tableBoxStyles: tableBoxStyles,
  tableStyles: tableStyles,
  tableHeaderCellStyles: tableHeaderCellStyles,
  tableNameCellStyles: tableNameCellStyles,
  tableSelectCellStyles: tableSelectCellStyles,
  renderPropertyMatrixDiff: renderPropertyMatrixDiff,
  renderSystemPropertyAbsenceDiff: renderSystemPropertyAbsenceDiff,
  make: DiffProperty$DiffAbsence
};

function getMappingDestinationName(mappingDestination, branchModel) {
  if (typeof mappingDestination === "object") {
    return Belt_Option.mapWithDefault(StateUtils.getDestinationById(mappingDestination.VAL, branchModel), "N/A", StateUtils.getDestinationName);
  } else {
    return "all destinations";
  }
}

function DiffProperty(Props) {
  var branchStatus = Props.branchStatus;
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var goToCommentId = Props.goToCommentId;
  var withComments = Props.withComments;
  var events = Props.events;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var hasAnyValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("AnyValues");
  var hasLongValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("LongValues");
  var match = Belt_Option.map(Belt_List.head(actions), (function (prim) {
          return prim[0];
        }));
  if (match === undefined) {
    return null;
  }
  if (typeof match !== "object") {
    return null;
  }
  if (match.NAME !== "Property") {
    return null;
  }
  var propertyId = match.VAL;
  var actions$1 = Belt_List.map(actions, (function (prim) {
          return prim[1];
        }));
  if (!actions$1) {
    return null;
  }
  var hasCreate = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          var variant = x.NAME;
          if (variant === "CreateProperty" || variant === "AddSystemProperty") {
            return true;
          } else {
            return variant === "AddProperty";
          }
        }));
  var hasArchive = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          if (x.NAME !== "Archive") {
            return false;
          }
          var match = x.VAL;
          if (typeof match === "object") {
            return match.NAME === "Property";
          } else {
            return false;
          }
        }));
  var changeType = hasCreate !== undefined ? "Create" : (
      hasArchive !== undefined ? "Archive" : "Update"
    );
  var property = Belt_List.getBy(masterModel.properties, (function (x) {
          if (x.TAG === /* PropertyRef */0) {
            return false;
          } else {
            return x._0.id === propertyId;
          }
        }));
  var property$1 = property !== undefined && property.TAG !== /* PropertyRef */0 ? property._0 : TrackingPlanModel.emptyProperty(propertyId, "", /* EventProperty */1, "");
  var nextProperty = Belt_List.getBy(branchModel.properties, (function (x) {
          if (x.TAG === /* PropertyRef */0) {
            return false;
          } else {
            return x._0.id === propertyId;
          }
        }));
  var nextProperty$1 = nextProperty !== undefined && nextProperty.TAG !== /* PropertyRef */0 ? nextProperty._0 : TrackingPlanModel.emptyProperty(propertyId, "", /* EventProperty */1, "");
  var excludedSourceActions = Belt_List.keep(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          var variant = x.NAME;
          if (variant === "ClearPropertyExcludedSources") {
            return true;
          } else {
            return variant === "TogglePropertyExcludedSource";
          }
        }));
  var ruleActions = Belt_List.keep(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          var variant = x.NAME;
          if (variant === "RemoveRule" || variant === "UpdateRule") {
            return true;
          } else {
            return variant === "AddRule";
          }
        }));
  var hasAbsenceAction = Belt_Option.isSome(Belt_List.getBy(actions$1, (function (action) {
              if (typeof action === "object") {
                return action.NAME === "UpdatePropertyAbsence";
              } else {
                return false;
              }
            })));
  var tmp;
  if (withComments) {
    var tmp$1 = {
      schemaId: schemaId,
      branchId: branchId,
      threadId: property$1.id,
      itemType: "Property",
      itemName: property$1.name,
      events: events
    };
    if (goToCommentId !== undefined) {
      tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp = React.createElement(DiffComments.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.alignSelf("flexStart"),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: {
                            hd: Css.maxWidth(DiffEvent.maxWidthPx),
                            tl: {
                              hd: Css.position("sticky"),
                              tl: {
                                hd: Css.top(Css.px(48)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.padding(Css.px(15)),
                                  tl: {
                                    hd: Css.paddingTop(Css.px(0)),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.empty({
                                              hd: Css.paddingBottom(Css.px(0)),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.backgroundColor(Styles.Color.grey20),
                                                tl: /* [] */0
                                              }),
                                          tl: changeType === "Create" ? ({
                                                hd: Css.border(Css.px(1), "solid", Styles.Color.mintGreen),
                                                tl: /* [] */0
                                              }) : (
                                              changeType === "Update" ? ({
                                                    hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                    tl: /* [] */0
                                                  }) : ({
                                                    hd: Css.border(Css.px(1), "solid", Styles.Color.grapeError),
                                                    tl: /* [] */0
                                                  })
                                            )
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }),
                      onClick: (function (param) {
                          return Router.Schema.pushDrawerItem(undefined, {
                                      NAME: "property",
                                      VAL: [
                                        property$1.id,
                                        undefined
                                      ]
                                    });
                        })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.borderTopLeftRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.borderTopRightRadius(Styles.Border.radius),
                                  tl: {
                                    hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                                    tl: {
                                      hd: Css.padding(Css.px(20)),
                                      tl: {
                                        hd: Css.marginBottom(Css.px(20)),
                                        tl: {
                                          hd: Css.marginLeft(Css.px(-15)),
                                          tl: {
                                            hd: Css.marginRight(Css.px(-15)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              })
                        }, React.createElement(DiffItemAuditIssues.make, {
                              branchStatus: branchStatus,
                              itemId: property$1.id,
                              children: React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.flexShrink(0),
                                              tl: /* [] */0
                                            })
                                      }, React.createElement($$Text.make, {
                                            size: "Medium",
                                            weight: "Semi",
                                            children: JsDiff.renderStringDiff(undefined, true, undefined, property$1.name, nextProperty$1.name)
                                          })), React.createElement(Spacer.make, {
                                        width: 8
                                      }), React.createElement($$Text.make, {
                                        size: "Small",
                                        children: JsDiff.renderStringDiff(undefined, true, undefined, PropertyUtils.printPropertyType(property$1, undefined, undefined, masterModel, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, undefined), PropertyUtils.printPropertyType(nextProperty$1, undefined, undefined, branchModel, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, undefined))
                                      }), React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.flexGrow(1.0),
                                                tl: {
                                                  hd: Css.justifyContent("flexEnd"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, changeType === "Archive" ? React.createElement(ContextMenu.make, {
                                              options: [{
                                                  NAME: "Option",
                                                  VAL: {
                                                    label: "Restore Property",
                                                    onClick: (function (param) {
                                                        var conflictingProperties = Belt_List.keepMapU(branchModel.properties, (function (branchProperty) {
                                                                if (branchProperty.TAG === /* PropertyRef */0) {
                                                                  return ;
                                                                }
                                                                var name = branchProperty._0.name;
                                                                if (property$1.name === name || property$1.name.toLowerCase() === name.toLowerCase() || Case.camel(property$1.name) === Case.camel(name) || Case$1.snake(property$1.name) === Case$1.snake(name)) {
                                                                  return name;
                                                                }
                                                                
                                                              }));
                                                        if (conflictingProperties) {
                                                          if (conflictingProperties.tl) {
                                                            window.alert("Can't unarchive property because properties with conflicting names already exist: " + Belt_List.toArray(conflictingProperties).join(", "));
                                                          } else {
                                                            window.alert("Can't unarchive property because a property with a conflicting name already exists: " + conflictingProperties.hd);
                                                          }
                                                          return ;
                                                        } else {
                                                          return Curry.app(sendActions, [
                                                                      undefined,
                                                                      false,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      (function (_branch) {
                                                                          Router.Schema.pushDrawerItem({
                                                                                NAME: "branch",
                                                                                VAL: branchId
                                                                              }, {
                                                                                NAME: "property",
                                                                                VAL: [
                                                                                  property$1.id,
                                                                                  undefined
                                                                                ]
                                                                              });
                                                                          return AnalyticsRe.itemUnarchived(schemaGroup, property$1.id, property$1.name, "Property", "BranchReview", schemaGroup.schemaId, schemaGroup.branchId);
                                                                        }),
                                                                      undefined,
                                                                      [[
                                                                          {
                                                                            NAME: "Unarchive",
                                                                            VAL: {
                                                                              NAME: "Property",
                                                                              VAL: property$1.id
                                                                            }
                                                                          },
                                                                          {
                                                                            propertyId: property$1.id,
                                                                            propertyQuery: property$1.id
                                                                          }
                                                                        ]]
                                                                    ]);
                                                        }
                                                      })
                                                  }
                                                }]
                                            }) : null))
                            })), JsDiff.hasDiff(property$1.description, nextProperty$1.description) ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.fontSize(Styles.FontSize.small),
                                  tl: {
                                    hd: Css.marginTop(Css.px(2)),
                                    tl: {
                                      hd: Css.paddingLeft(Css.px(6)),
                                      tl: {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.margin4(Css.px(2), Css.px(10), Css.px(0), Css.px(0)),
                                      tl: /* [] */0
                                    })
                              }, React.createElement(IconDescription.make, {
                                    size: 14
                                  })), React.createElement("div", undefined, JsDiff.renderStringDiff(undefined, undefined, undefined, property$1.description, nextProperty$1.description))) : null, Belt_List.toArray(Belt_List.map(excludedSourceActions, (function (action) {
                                var tmp;
                                if (typeof action === "object") {
                                  var variant = action.NAME;
                                  if (variant === "TogglePropertyExcludedSource") {
                                    var match = action.VAL;
                                    var sourceId = match[1];
                                    tmp = match[2] ? React.createElement("span", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.color(Styles.Color.mintGreenSecondary),
                                                  tl: /* [] */0
                                                })
                                          }, "Start sending property to " + Belt_Option.mapWithDefault(StateUtils.getSourceById(sourceId, branchModel), "-", AvoConfig.getSourceName)) : React.createElement("span", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.color(Styles.Color.grapeErrorSecondary),
                                                  tl: /* [] */0
                                                })
                                          }, "Stop sending property to " + Belt_Option.mapWithDefault(StateUtils.getSourceById(sourceId, branchModel), "-", AvoConfig.getSourceName));
                                  } else {
                                    tmp = variant === "ClearPropertyExcludedSources" ? React.createElement("span", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.color(Styles.Color.mintGreenSecondary),
                                                  tl: /* [] */0
                                                })
                                          }, "Send property to all sources") : null;
                                  }
                                } else {
                                  tmp = null;
                                }
                                return React.createElement("div", {
                                            key: JSON.stringify(Printer.printAction(action)),
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.alignItems("center"),
                                                    tl: {
                                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                                      tl: {
                                                        hd: Css.fontSize(Styles.FontSize.small),
                                                        tl: {
                                                          hd: Css.margin2(Css.px(5), Css.px(0)),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                })
                                          }, React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.marginRight(Css.px(10)),
                                                      tl: /* [] */0
                                                    })
                                              }, React.createElement(IconArrowDownEast.make, {
                                                    size: 12,
                                                    color: Styles.Color.grey40
                                                  })), tmp);
                              }))), Belt_List.toArray(Belt_List.map(ruleActions, (function (action) {
                                var ruleId;
                                if (typeof action === "object") {
                                  var variant = action.NAME;
                                  ruleId = variant === "RemoveRule" || variant === "UpdateRule" || variant === "AddRule" ? action.VAL[0] : Pervasives.failwith("should never happen");
                                } else {
                                  ruleId = Pervasives.failwith("should never happen");
                                }
                                var rule = StateUtils.getRuleById(ruleId, masterModel);
                                var nextRule = StateUtils.getRuleById(ruleId, branchModel);
                                var tmp;
                                if (rule !== undefined) {
                                  if (nextRule !== undefined) {
                                    var match = rule.definition;
                                    var match$1 = nextRule.definition;
                                    if (typeof match === "object" && match.NAME === "NameMapping" && typeof match$1 === "object" && match$1.NAME === "NameMapping") {
                                      var match$2 = match$1.VAL;
                                      var match$3 = match.VAL;
                                      var destinationName = Belt_Option.mapWithDefault(match$3.destination, "N/A", (function (param) {
                                              return getMappingDestinationName(param, branchModel);
                                            }));
                                      var nextDestinationName = Belt_Option.mapWithDefault(match$2.destination, "N/A", (function (param) {
                                              return getMappingDestinationName(param, branchModel);
                                            }));
                                      tmp = React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.alignItems("center"),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement(IconArrowDownEast.make, {
                                                size: 8,
                                                color: Styles.Color.grey90
                                              }), React.createElement(Spacer.make, {
                                                width: 4
                                              }), JsDiff.renderStringDiff(undefined, undefined, undefined, "Send as \"" + Belt_Option.getWithDefault(match$3.name, "N/A") + "\" to " + destinationName + " on all events", "Send as \"" + Belt_Option.getWithDefault(match$2.name, "N/A") + "\" to " + nextDestinationName + " on all events"));
                                    } else {
                                      tmp = null;
                                    }
                                  } else {
                                    var match$4 = rule.definition;
                                    if (typeof match$4 === "object" && match$4.NAME === "NameMapping") {
                                      var match$5 = match$4.VAL;
                                      var destinationName$1 = Belt_Option.mapWithDefault(match$5.destination, "N/A", (function (param) {
                                              return getMappingDestinationName(param, branchModel);
                                            }));
                                      tmp = React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.alignItems("center"),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement(IconArrowDownEast.make, {
                                                size: 8,
                                                color: Styles.Color.grapeError
                                              }), React.createElement(Spacer.make, {
                                                width: 4
                                              }), JsDiff.renderStringDiff(undefined, undefined, undefined, "Send as \"" + Belt_Option.getWithDefault(match$5.name, "N/A") + "\" to " + destinationName$1 + " on all events", ""));
                                    } else {
                                      tmp = null;
                                    }
                                  }
                                } else if (nextRule !== undefined) {
                                  var match$6 = nextRule.definition;
                                  if (typeof match$6 === "object" && match$6.NAME === "NameMapping") {
                                    var match$7 = match$6.VAL;
                                    var destinationName$2 = Belt_Option.mapWithDefault(match$7.destination, "N/A", (function (param) {
                                            return getMappingDestinationName(param, branchModel);
                                          }));
                                    tmp = React.createElement("span", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.alignItems("center"),
                                                  tl: /* [] */0
                                                }
                                              })
                                        }, React.createElement(IconArrowDownEast.make, {
                                              size: 8,
                                              color: Styles.Color.mintGreen
                                            }), React.createElement(Spacer.make, {
                                              width: 4
                                            }), JsDiff.renderStringDiff(undefined, undefined, undefined, "", "Send as \"" + Belt_Option.getWithDefault(match$7.name, "N/A") + "\" to " + destinationName$2 + " on all events"));
                                  } else {
                                    tmp = null;
                                  }
                                } else {
                                  tmp = null;
                                }
                                return React.createElement("div", {
                                            key: ruleId,
                                            className: Curry._1(Css.style, {
                                                  hd: Css.paddingLeft(Css.px(4)),
                                                  tl: {
                                                    hd: Css.display("flex"),
                                                    tl: {
                                                      hd: Css.alignItems("center"),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                })
                                          }, React.createElement($$Text.make, {
                                                size: "Small",
                                                weight: "Regular",
                                                children: tmp
                                              }));
                              }))), hasAbsenceAction ? React.createElement(DiffProperty$DiffAbsence, {
                            masterModel: masterModel,
                            branchModel: branchModel,
                            masterAbsence: property$1.absence,
                            nextProperty: nextProperty$1
                          }) : null, StateUtils.getPropertyParents(property$1.id, branchModel) !== /* [] */0 && property$1.optionalWhenInObject !== nextProperty$1.optionalWhenInObject ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.fontSize(Styles.FontSize.small),
                                  tl: {
                                    hd: Css.marginTop(Css.px(2)),
                                    tl: /* [] */0
                                  }
                                })
                          }, JsDiff.renderStringDiff(undefined, undefined, undefined, property$1.optionalWhenInObject ? "Optional" : "Required", nextProperty$1.optionalWhenInObject ? "Optional" : "Required"), " when sent as part of an object property") : null)), tmp);
}

var make = DiffProperty;

exports.printEventAbsence = printEventAbsence;
exports.renderAbsenceTextDiff = renderAbsenceTextDiff;
exports.DiffAbsence = DiffAbsence;
exports.getMappingDestinationName = getMappingDestinationName;
exports.make = make;
/* tableBoxStyles Not a pure module */
