// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Portal = require("./Portal.bs.js");
var Styles = require("./styles.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Hooks = require("@mantine/hooks");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

function isOutOfBounds(portalRef, originalPoint, transformedPoint, distance) {
  var match = portalRef.current;
  var portalElement;
  var x;
  var y;
  if (match == null) {
    return false;
  }
  if (transformedPoint !== undefined) {
    portalElement = match;
    x = transformedPoint.x;
    y = transformedPoint.y;
  } else {
    if (originalPoint === undefined) {
      return false;
    }
    portalElement = match;
    x = originalPoint.x;
    y = originalPoint.y;
  }
  var portalWidth = portalElement.getBoundingClientRect().width;
  var portalHeight = portalElement.getBoundingClientRect().height;
  var windowWidth = window.innerWidth;
  var windowHeight = window.innerHeight;
  var pageYOffset = window.pageYOffset;
  var pageXOffset = window.pageXOffset;
  if (((x + portalWidth | 0) - pageXOffset | 0) > (windowWidth - distance | 0)) {
    return true;
  } else {
    return ((y + portalHeight | 0) - pageYOffset | 0) > (windowHeight - distance | 0);
  }
}

function findFirstDimensions(el) {
  return Belt_Option.flatMap(Belt_Array.get(el.children, 0), (function (child) {
                var rect = child.getBoundingClientRect();
                var width = rect.width;
                var height = rect.height;
                if (Caml_obj.caml_notequal([
                        width,
                        height
                      ], [
                        0,
                        0
                      ])) {
                  return {
                          width: width,
                          height: height
                        };
                } else {
                  return findFirstDimensions(child);
                }
              }));
}

function calculatePosition(referenceItemRef, portalRef, visible, distance, portalPosition, placement, offset) {
  var match = referenceItemRef.current;
  var match$1 = portalRef.current;
  if (match == null) {
    return ;
  }
  if (match$1 == null) {
    return ;
  }
  if (!visible) {
    return ;
  }
  var referenceElementRect = match.getBoundingClientRect();
  var referenceElementVerticalMiddle = referenceElementRect.top + (referenceElementRect.height / 2 | 0) | 0;
  var referenceElementHorizontalMiddle = referenceElementRect.left + (referenceElementRect.width / 2 | 0) | 0;
  var referenceElementBottom = referenceElementRect.bottom;
  var referenceElementTop = referenceElementRect.top;
  var referenceElementLeft = referenceElementRect.left;
  var referenceElementRight = referenceElementRect.right;
  var pageYOffset = window.pageYOffset;
  var pageXOffset = window.pageXOffset;
  var match$2 = Belt_Option.mapWithDefault(findFirstDimensions(match$1), [
        0,
        0
      ], (function (dimensions) {
          return [
                  dimensions.width,
                  dimensions.height
                ];
        }));
  var portalHeight = match$2[1];
  var portalWidth = match$2[0];
  var topOffset = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(offset.top), 0);
  var rightOffset = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(offset.right), 0);
  var bottomOffset = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(offset.bottom), 0);
  var leftOffset = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(offset.left), 0);
  var hasSpaceLeftOfReferenceElement = ((distance + portalWidth | 0) + distance | 0) <= referenceElementLeft;
  var hasSpaceRightOfReferenceElement = (((window.innerWidth - distance | 0) - portalWidth | 0) - distance | 0) > (referenceElementRight + distance | 0);
  var hasSpaceAboveReferenceElement = ((distance + portalHeight | 0) + distance | 0) <= (referenceElementTop - distance | 0);
  var hasSpaceBelowReferenceElement = (((window.innerHeight - distance | 0) - portalHeight | 0) - distance | 0) > (referenceElementBottom + distance | 0);
  var correctedPosition = portalPosition === "Top" ? (
      hasSpaceAboveReferenceElement || !hasSpaceBelowReferenceElement ? "Top" : "Bottom"
    ) : (
      portalPosition === "Bottom" ? (
          hasSpaceBelowReferenceElement || !hasSpaceAboveReferenceElement ? "Bottom" : "Top"
        ) : (
          portalPosition === "Left" ? (
              hasSpaceLeftOfReferenceElement || !hasSpaceRightOfReferenceElement ? "Left" : "Right"
            ) : (
              hasSpaceRightOfReferenceElement || !hasSpaceLeftOfReferenceElement ? "Right" : "Left"
            )
        )
    );
  if (correctedPosition === "Top") {
    var idealPortalTop = ((referenceElementTop - distance | 0) - portalHeight | 0) + pageYOffset | 0;
    var furthestPortalTop = ((distance + portalHeight | 0) + distance | 0) + pageYOffset | 0;
    var top = idealPortalTop >= (distance + pageYOffset | 0) ? idealPortalTop : (
        idealPortalTop > furthestPortalTop ? furthestPortalTop : distance + pageYOffset | 0
      );
    var idealPortalLeft = placement === "Start" ? referenceElementLeft + pageXOffset | 0 : (
        placement === "Center" ? (referenceElementHorizontalMiddle - (portalWidth / 2 | 0) | 0) + pageXOffset | 0 : (referenceElementRight - portalWidth | 0) + pageXOffset | 0
      );
    var furthestPortalLeft = ((window.innerWidth - distance | 0) - portalWidth | 0) + pageXOffset | 0;
    var left = idealPortalLeft > furthestPortalLeft ? furthestPortalLeft : (
        idealPortalLeft >= (distance + pageXOffset | 0) ? idealPortalLeft : distance + pageXOffset | 0
      );
    return {
            x: (left + leftOffset | 0) + rightOffset | 0,
            y: (top + topOffset | 0) + bottomOffset | 0,
            correctedPosition: correctedPosition
          };
  }
  if (correctedPosition === "Bottom") {
    var idealPortalTop$1 = (referenceElementBottom + distance | 0) + pageYOffset | 0;
    var furthestPortalTop$1 = (((window.innerHeight - distance | 0) - portalHeight | 0) - distance | 0) + pageYOffset | 0;
    var top$1 = idealPortalTop$1 > furthestPortalTop$1 ? furthestPortalTop$1 : (
        idealPortalTop$1 >= (distance + pageYOffset | 0) ? idealPortalTop$1 : distance + pageYOffset | 0
      );
    var idealPortalLeft$1 = placement === "Start" ? referenceElementLeft + pageXOffset | 0 : (
        placement === "Center" ? (referenceElementHorizontalMiddle - (portalWidth / 2 | 0) | 0) + pageXOffset | 0 : (referenceElementRight - portalWidth | 0) + pageXOffset | 0
      );
    var furthestPortalLeft$1 = ((window.innerWidth - distance | 0) - portalWidth | 0) + pageXOffset | 0;
    var left$1 = idealPortalLeft$1 > furthestPortalLeft$1 ? furthestPortalLeft$1 : (
        idealPortalLeft$1 >= (distance + pageXOffset | 0) ? idealPortalLeft$1 : distance + pageXOffset | 0
      );
    return {
            x: (left$1 + leftOffset | 0) + rightOffset | 0,
            y: (top$1 + topOffset | 0) + bottomOffset | 0,
            correctedPosition: correctedPosition
          };
  }
  if (correctedPosition === "Left") {
    var idealPortalTop$2 = placement === "Start" ? referenceElementTop + pageYOffset | 0 : (
        placement === "Center" ? (referenceElementVerticalMiddle - (portalHeight / 2 | 0) | 0) + pageYOffset | 0 : (referenceElementBottom - portalHeight | 0) + pageYOffset | 0
      );
    var furthestPortalTop$2 = ((window.innerHeight - distance | 0) - portalHeight | 0) + pageYOffset | 0;
    var top$2 = idealPortalTop$2 > furthestPortalTop$2 ? furthestPortalTop$2 : (
        idealPortalTop$2 >= (distance + pageYOffset | 0) ? idealPortalTop$2 : distance + pageYOffset | 0
      );
    var idealPortalLeft$2 = ((referenceElementLeft - distance | 0) - portalWidth | 0) + pageXOffset | 0;
    var furthestPortalLeft$2 = referenceElementLeft + pageXOffset | 0;
    var left$2 = idealPortalLeft$2 > furthestPortalLeft$2 ? furthestPortalLeft$2 : (
        idealPortalLeft$2 >= (distance + pageXOffset | 0) ? idealPortalLeft$2 : distance + pageXOffset | 0
      );
    return {
            x: (left$2 + leftOffset | 0) + rightOffset | 0,
            y: (top$2 + topOffset | 0) + bottomOffset | 0,
            correctedPosition: correctedPosition
          };
  }
  var idealPortalTop$3 = placement === "Start" ? referenceElementTop + pageYOffset | 0 : (
      placement === "Center" ? (referenceElementVerticalMiddle - (portalHeight / 2 | 0) | 0) + pageYOffset | 0 : (referenceElementBottom - portalHeight | 0) + pageYOffset | 0
    );
  var furthestPortalTop$3 = ((window.innerHeight - distance | 0) - portalHeight | 0) + pageYOffset | 0;
  var top$3 = idealPortalTop$3 > furthestPortalTop$3 ? furthestPortalTop$3 : (
      idealPortalTop$3 >= (distance + pageYOffset | 0) ? idealPortalTop$3 : distance + pageYOffset | 0
    );
  var idealPortalLeft$3 = (referenceElementRight + distance | 0) + pageXOffset | 0;
  var furthestPortalLeft$3 = (((window.innerWidth - distance | 0) - portalWidth | 0) - distance | 0) + pageXOffset | 0;
  var left$3 = idealPortalLeft$3 > furthestPortalLeft$3 ? furthestPortalLeft$3 : (
      idealPortalLeft$3 >= (distance + pageXOffset | 0) ? idealPortalLeft$3 : distance + pageXOffset | 0
    );
  return {
          x: (left$3 + leftOffset | 0) + rightOffset | 0,
          y: (top$3 + topOffset | 0) + bottomOffset | 0,
          correctedPosition: correctedPosition
        };
}

var overlayStyles = Curry._1(Css.style, {
      hd: Css.zIndex(Styles.ZIndex.portalMenu),
      tl: {
        hd: Css.width(Css.vw(100)),
        tl: {
          hd: Css.height(Css.vh(100)),
          tl: {
            hd: Css.position("absolute"),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.top(Css.px(0)),
                tl: {
                  hd: Css.overflow("hidden"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function containerStyles(shouldShow, duration, originalPoint, transformPoint) {
  return Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.zIndex(Styles.ZIndex.portalMenu),
                tl: {
                  hd: Css.transforms({
                        hd: Css.translateX(Css.px(Belt_Option.mapWithDefault(transformPoint, 0, (function (param) {
                                        return param.x;
                                      })))),
                        tl: {
                          hd: Css.translateY(Css.px(Belt_Option.mapWithDefault(transformPoint, 0, (function (param) {
                                          return param.y;
                                        })))),
                          tl: /* [] */0
                        }
                      }),
                  tl: {
                    hd: Css.left(Css.px(Belt_Option.mapWithDefault(originalPoint, -9999, (function (param) {
                                    return param.x;
                                  })))),
                    tl: {
                      hd: Css.top(Css.px(Belt_Option.mapWithDefault(originalPoint, -9999, (function (param) {
                                      return param.y;
                                    })))),
                      tl: {
                        hd: Css.transitions({
                              hd: Css_Legacy_Core.Transition.shorthand(duration, undefined, undefined, "opacity"),
                              tl: {
                                hd: Css_Legacy_Core.Transition.shorthand(duration, undefined, undefined, "transform"),
                                tl: /* [] */0
                              }
                            }),
                        tl: {
                          hd: Css.opacity(shouldShow ? 1 : 0),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function PortalMenu(Props) {
  var portalPositionOpt = Props.position;
  var placementOpt = Props.placement;
  var offsetOpt = Props.offset;
  var distanceOpt = Props.distance;
  var clickOutsideToDismissOpt = Props.clickOutsideToDismiss;
  var visible = Props.visible;
  var onClose = Props.onClose;
  var onMouseOverOpt = Props.onMouseOver;
  var onMouseLeaveOpt = Props.onMouseLeave;
  var getCorrectPosition = Props.getCorrectPosition;
  var recalculateOnBoundingRectChangesOpt = Props.recalculateOnBoundingRectChanges;
  var referenceItemRef = Props.referenceItemRef;
  var children = Props.children;
  var portalPosition = portalPositionOpt !== undefined ? portalPositionOpt : "Bottom";
  var placement = placementOpt !== undefined ? placementOpt : "Center";
  var offset = offsetOpt !== undefined ? Caml_option.valFromOption(offsetOpt) : ({
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      });
  var distance = distanceOpt !== undefined ? distanceOpt : 16;
  var clickOutsideToDismiss = clickOutsideToDismissOpt !== undefined ? clickOutsideToDismissOpt : true;
  var onMouseOver = onMouseOverOpt !== undefined ? onMouseOverOpt : (function (param) {
        
      });
  var onMouseLeave = onMouseLeaveOpt !== undefined ? onMouseLeaveOpt : (function (param) {
        
      });
  var recalculateOnBoundingRectChanges = recalculateOnBoundingRectChangesOpt !== undefined ? recalculateOnBoundingRectChangesOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setPortalMounted = match[1];
  var portalMounted = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setContentRect = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setOriginalPoint = match$2[1];
  var originalPoint = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setTransformFromOriginalPoint = match$3[1];
  var transformFromOriginalPoint = match$3[0];
  var portalRef = React.useRef(null);
  React.useEffect((function () {
          try {
            var portalObserver = new ResizeObserver((function (entries) {
                    window.requestAnimationFrame(function (param) {
                          var willCauseLoopLimitExceeded = (!Array.isArray(entries) || !entries.length);
                          if (willCauseLoopLimitExceeded) {
                            return ;
                          } else {
                            return Belt_Option.forEach(Belt_Array.get(entries, 0), (function (entry) {
                                          return Curry._1(setContentRect, (function (param) {
                                                        return Caml_option.some(entry.contentRect);
                                                      }));
                                        }));
                          }
                        });
                    
                  }));
            Belt_Option.forEach(Caml_option.nullable_to_opt(portalRef.current), (function (portalRef) {
                    portalObserver.observe(portalRef);
                    
                  }));
            return (function (param) {
                      return Belt_Option.forEach(Caml_option.nullable_to_opt(portalRef.current), (function (portalRef) {
                                    portalObserver.unobserve(portalRef);
                                    
                                  }));
                    });
          }
          catch (raw_e){
            var e = Caml_js_exceptions.internalToOCamlException(raw_e);
            console.warn("ResizeOberserver failing", e);
            return ;
          }
        }), [portalMounted]);
  React.useEffect((function () {
          Curry._1(setPortalMounted, (function (param) {
                  return visible;
                }));
          
        }), [visible]);
  React.useEffect((function () {
          Curry._1(setOriginalPoint, (function (param) {
                  return calculatePosition(referenceItemRef, portalRef, visible, distance, portalPosition, placement, offset);
                }));
          if (!visible) {
            Curry._1(setTransformFromOriginalPoint, (function (param) {
                    
                  }));
          }
          
        }), [visible]);
  React.useEffect((function () {
          var newPoint = calculatePosition(referenceItemRef, portalRef, visible, distance, portalPosition, placement, offset);
          if (isOutOfBounds(portalRef, originalPoint, transformFromOriginalPoint, distance) && recalculateOnBoundingRectChanges && originalPoint !== undefined && newPoint !== undefined) {
            var correctedPosition = newPoint.correctedPosition;
            var newY = newPoint.y;
            var newX = newPoint.x;
            var originalY = originalPoint.y;
            var originalX = originalPoint.x;
            Curry._1(setTransformFromOriginalPoint, (function (param) {
                    return {
                            x: newX - originalX | 0,
                            y: newY - originalY | 0,
                            correctedPosition: correctedPosition
                          };
                  }));
          }
          
        }), [match$1[0]]);
  var fadeDuration = Styles.Duration.$$default;
  React.useEffect((function () {
          var exit = 0;
          var correctedPosition;
          if (transformFromOriginalPoint !== undefined) {
            correctedPosition = transformFromOriginalPoint.correctedPosition;
            exit = 1;
          } else if (originalPoint !== undefined) {
            correctedPosition = originalPoint.correctedPosition;
            exit = 1;
          }
          if (exit === 1) {
            Belt_Option.forEach(getCorrectPosition, (function (cb) {
                    return Curry._1(cb, correctedPosition);
                  }));
          }
          
        }), [
        originalPoint,
        transformFromOriginalPoint
      ]);
  var match$4 = Hooks.useScrollLock();
  var setScrollLock = match$4[1];
  React.useEffect((function () {
          if (visible) {
            Curry._1(setScrollLock, (function (param) {
                    return true;
                  }));
          } else {
            Curry._1(setScrollLock, (function (param) {
                    return false;
                  }));
          }
          
        }), [visible]);
  var container = React.createElement("div", {
        ref: portalRef,
        className: containerStyles(portalMounted, {
              NAME: "ms",
              VAL: fadeDuration
            }, originalPoint, transformFromOriginalPoint),
        onClick: (function (prim) {
            prim.stopPropagation();
            
          })
      }, children);
  if (visible) {
    return React.createElement(Portal.make, {
                children: clickOutsideToDismiss ? React.createElement("div", {
                        className: overlayStyles,
                        onClick: (function (e) {
                            setTimeout(onClose, fadeDuration | 0);
                            Curry._1(setPortalMounted, (function (param) {
                                    return false;
                                  }));
                            e.stopPropagation();
                            
                          }),
                        onMouseLeave: onMouseLeave,
                        onMouseOver: onMouseOver
                      }, container) : container
              });
  } else {
    return null;
  }
}

var make = PortalMenu;

exports.isOutOfBounds = isOutOfBounds;
exports.findFirstDimensions = findFirstDimensions;
exports.calculatePosition = calculatePosition;
exports.overlayStyles = overlayStyles;
exports.containerStyles = containerStyles;
exports.make = make;
/* overlayStyles Not a pure module */
