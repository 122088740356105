// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function encode(request) {
  return Json_encode.object_({
              hd: [
                "schemaId",
                request.schemaId
              ],
              tl: {
                hd: [
                  "integrationId",
                  request.integrationId
                ],
                tl: /* [] */0
              }
            });
}

function decode(json) {
  return {
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          integrationId: Json_decode.field("integrationId", Json_decode.string, json)
        };
}

var $$Request = {
  encode: encode,
  decode: decode
};

function encode$1(resp) {
  return Json_encode.object_({
              hd: [
                "key",
                resp.key
              ],
              tl: /* [] */0
            });
}

function decode$1(json) {
  return {
          key: Json_decode.field("key", Json_decode.string, json)
        };
}

var $$Response = {
  encode: encode$1,
  decode: decode$1
};

exports.$$Request = $$Request;
exports.$$Response = $$Response;
/* No side effect */
