// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var FramerMotion = require("framer-motion");

var slideOverStyles = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.maxWidth(Css.vw(70.0)),
              tl: {
                hd: Css.minWidth(Css.px(320)),
                tl: {
                  hd: Css.overflow("auto"),
                  tl: {
                    hd: Css.zIndex(Styles.ZIndex.aboveAll),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.boxShadow(Styles.Shadow.standard),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var backdropStyles = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.zIndex(Styles.ZIndex.aboveAll),
                tl: {
                  hd: Css.backgroundColor({
                        NAME: "rgb",
                        VAL: [
                          0,
                          0,
                          0
                        ]
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function lockBodyScroll(toY, width) {
  window.document.body.style.marginTop = "-" + (String(toY) + "px");
  window.document.body.style.position = "fixed";
  window.document.body.style.overflowY = "scroll";
  window.document.body.style.width = String(width) + "px";
  
}

function releaseBodyScroll(toY) {
  window.document.body.style.position = "";
  window.document.body.style.overflowY = "";
  window.document.body.style.marginTop = "";
  window.document.body.style.width = "";
  setTimeout((function (param) {
          window.scrollTo(window.pageXOffset, toY);
          
        }), 1);
  
}

function useLockBodyScroll(param) {
  var match = React.useState(function () {
        return [
                window.pageYOffset,
                window.document.body.offsetWidth
              ];
      });
  var setBody = match[1];
  var match$1 = match[0];
  var bodyWidth = match$1[1];
  var bodyYOffset = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setStoredYOffset = match$2[1];
  var storedYOffset = match$2[0];
  React.useEffect((function () {
          var handleScroll = function (param) {
            return Curry._1(setBody, (function (param) {
                          return [
                                  window.pageYOffset,
                                  window.document.body.offsetWidth
                                ];
                        }));
          };
          window.addEventListener("scroll", handleScroll);
          return (function (param) {
                    window.removeEventListener("scroll", handleScroll);
                    
                  });
        }), []);
  var lock = function (param) {
    Curry._1(setStoredYOffset, (function (param) {
            return bodyYOffset;
          }));
    return lockBodyScroll(bodyYOffset, bodyWidth);
  };
  var release = function (param) {
    releaseBodyScroll(Belt_Option.getWithDefault(storedYOffset, 0));
    return Curry._1(setStoredYOffset, (function (param) {
                  
                }));
  };
  return [
          lock,
          release
        ];
}

function SlideOverContainer(Props) {
  var onClose = Props.onClose;
  var slideOver = Props.slideOver;
  var hadSlideOver = Hooks.usePrevious1(Belt_Option.isSome(slideOver));
  var match = useLockBodyScroll(undefined);
  var releaseBodyScroll = match[1];
  var lockBodyScroll = match[0];
  React.useEffect((function () {
          var match = Belt_Option.isSome(slideOver);
          if (hadSlideOver) {
            if (match) {
              
            } else {
              Curry._1(releaseBodyScroll, undefined);
            }
          } else if (match) {
            Curry._1(lockBodyScroll, undefined);
          }
          
        }), [Belt_Option.isSome(slideOver)]);
  return React.createElement(FramerMotion.AnimatePresence, {
              exitBeforeEnter: true,
              children: slideOver !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(FramerMotion.motion.div, {
                          animate: {
                            opacity: 0.4
                          },
                          transition: {
                            duration: 0.3
                          },
                          initial: {
                            opacity: 0.0
                          },
                          exit: {
                            opacity: 0.0
                          },
                          className: backdropStyles,
                          key: "backdrop",
                          onClick: (function (param) {
                              return Curry._1(onClose, undefined);
                            })
                        }), React.createElement(FramerMotion.motion.div, {
                          animate: {
                            opacity: 1,
                            x: 0
                          },
                          transition: {
                            duration: 0.3
                          },
                          initial: {
                            opacity: 0,
                            x: 50
                          },
                          exit: {
                            opacity: 0,
                            x: 50
                          },
                          className: slideOverStyles,
                          key: slideOver[1],
                          children: slideOver[0]
                        })) : null
            });
}

var make = SlideOverContainer;

exports.slideOverStyles = slideOverStyles;
exports.backdropStyles = backdropStyles;
exports.lockBodyScroll = lockBodyScroll;
exports.releaseBodyScroll = releaseBodyScroll;
exports.useLockBodyScroll = useLockBodyScroll;
exports.make = make;
/* slideOverStyles Not a pure module */
