// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var toLocaleString = (x => x.toLocaleString());

function getRoughPercentageString(percentage) {
  if (percentage !== 0.0) {
    if (percentage < 0.001) {
      return "< 0.001";
    } else if (percentage < 0.01) {
      return percentage.toFixed(3);
    } else if (percentage < 0.1) {
      return percentage.toFixed(2);
    } else if (percentage !== 100.0) {
      return percentage.toFixed(1);
    } else {
      return "100";
    }
  } else {
    return "0";
  }
}

function getPercentageString(percentage) {
  if (percentage !== 0.0) {
    if (percentage < 0.0000001) {
      return percentage.toFixed(9);
    } else if (percentage < 0.000001) {
      return percentage.toFixed(8);
    } else if (percentage < 0.00001) {
      return percentage.toFixed(7);
    } else if (percentage < 0.0001) {
      return percentage.toFixed(6);
    } else if (percentage < 0.001) {
      return percentage.toFixed(5);
    } else if (percentage < 0.01) {
      return percentage.toFixed(4);
    } else if (percentage < 0.1) {
      return percentage.toFixed(2);
    } else if (percentage !== 100.0) {
      return percentage.toFixed(1);
    } else {
      return "100";
    }
  } else {
    return "0";
  }
}

exports.toLocaleString = toLocaleString;
exports.getRoughPercentageString = getRoughPercentageString;
exports.getPercentageString = getPercentageString;
/* No side effect */
