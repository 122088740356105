// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconEventType(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey40;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: /* [] */0
                    }
                  }),
              width: String(size),
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  className: "nc-icon-wrapper"
                }, React.createElement("rect", {
                      height: "11",
                      width: "11",
                      fill: "none",
                      strokeLinecap: "round",
                      x: "0.5",
                      y: "0.5"
                    }), React.createElement("polyline", {
                      fill: "none",
                      points: "13.5 4.5 15.5 4.5 15.5 15.5 4.5 15.5 4.5 13.5",
                      strokeLinecap: "round"
                    })));
}

var make = IconEventType;

exports.make = make;
/* Css Not a pure module */
