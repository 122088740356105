// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var FileView = require("../FileView.bs.js");
var AnalyticsRe = require("../analyticsRe.bs.js");

function scriptTag(apiKey) {
  return "<script>\n!function(){var t=window.inspector=window.inspector||[];t.methods=[\"trackSchemaFromEvent\",\"trackSchema\",\"setBatchSize\",\"setBatchFlushSeconds\"],t.factory=function(e){return function(){var r=Array.prototype.slice.call(arguments);return r.unshift(e),t.push(r),t}};for(var e=0;e<t.methods.length;e++){var r=t.methods[e];t[r]=t.factory(r)}t.load=function(){var t=document.createElement(\"script\");t.type=\"text/javascript\",t.async=!0,t.src=\"https://cdn.avo.app/inspector/inspector-v1.min.js\";var e=document.getElementsByTagName(\"script\")[0];e.parentNode.insertBefore(t,e)},t._scriptVersion=1}();\n\n// Update the following variables to match your project\ninspector.__API_KEY__ = \"" + (apiKey + "\";\ninspector.__ENV__ = \"dev\"; // or \"prod\"\ninspector.__VERSION__ = \"YOUR-APP-VERSION\";\n// inspector.__APP_NAME__ = \"YOUR-APP-NAME\"; // Optional\n\ninspector.load();\n</script>");
}

var codeSnippetBox = Curry._1(Css.style, {
      hd: Css.margin2(Css.px(4), Css.px(0)),
      tl: /* [] */0
    });

function code(apiKey, schemaGroup, sourceGroup, schemaId, branchId) {
  var trackInteraction = function (inspectorIntegrationInteractionType) {
    return AnalyticsRe.inspectorIntegrationInteraction(schemaGroup, sourceGroup, "ScriptTag", "Development", inspectorIntegrationInteractionType, branchId, schemaId);
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: codeSnippetBox
                }, React.createElement(FileView.make, {
                      filename: "HTML Script Tag",
                      code: scriptTag(apiKey),
                      startExpanded: true,
                      onExpand: (function (param) {
                          return trackInteraction("ExpandCodeSnippet");
                        }),
                      onCopy: (function (param) {
                          return trackInteraction("CopyCode");
                        }),
                      onDownload: (function (param) {
                          return trackInteraction("DownloadCode");
                        })
                    })));
}

exports.scriptTag = scriptTag;
exports.codeSnippetBox = codeSnippetBox;
exports.code = code;
/* codeSnippetBox Not a pure module */
