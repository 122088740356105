// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Workspace = require("../../model/src/Workspace.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var PlanLightning = require("./PlanLightning.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function EntityLightning(Props) {
  var availability = Props.availability;
  var planHint = Props.planHint;
  var tooltipDirectionOpt = Props.tooltipDirection;
  var strokeOpt = Props.stroke;
  var fillOpt = Props.fill;
  var tooltipDirection = tooltipDirectionOpt !== undefined ? tooltipDirectionOpt : "bottom";
  var stroke = strokeOpt !== undefined ? strokeOpt : Styles.Color.grey50;
  var fill = fillOpt !== undefined ? fillOpt : "transparent";
  if (availability === "Available") {
    return null;
  } else if (availability === "AvailableDuringTrial") {
    return React.createElement("span", {
                className: Curry._1(Css.style, {
                      hd: Css_Legacy_Core.SVG.fill(fill),
                      tl: {
                        hd: Css_Legacy_Core.SVG.stroke(stroke),
                        tl: /* [] */0
                      }
                    })
              }, React.createElement(PlanLightning.make, {
                    tooltipText: "Available during trial and on the " + (Belt_Option.mapWithDefault(planHint, "next", (function (plan) {
                              return Workspace.printPlanName(plan.name);
                            })) + " plan"),
                    tooltipDirection: tooltipDirection
                  }));
  } else {
    return React.createElement("span", {
                className: Curry._1(Css.style, {
                      hd: Css_Legacy_Core.SVG.fill(fill),
                      tl: {
                        hd: Css_Legacy_Core.SVG.stroke(stroke),
                        tl: /* [] */0
                      }
                    })
              }, React.createElement(PlanLightning.make, {
                    tooltipText: "Available on the " + (Belt_Option.mapWithDefault(planHint, "next", (function (plan) {
                              return Workspace.printPlanName(plan.name);
                            })) + " plan"),
                    tooltipDirection: tooltipDirection
                  }));
  }
}

var make = EntityLightning;

exports.make = make;
/* Css Not a pure module */
