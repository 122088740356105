// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var IconRocket = require("./IconRocket.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");

function UpdatePrompt(Props) {
  var toVersion = Props.toVersion;
  React.useEffect((function () {
          AnalyticsRe.versionUpdatePrompted(toVersion);
          
        }), []);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("fixed"),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.top(Css.px(0)),
                            tl: {
                              hd: Css.right(Css.px(0)),
                              tl: {
                                hd: Css.bottom(Css.px(0)),
                                tl: {
                                  hd: Css.left(Css.px(0)),
                                  tl: {
                                    hd: Css.backgroundColor({
                                          NAME: "rgba",
                                          VAL: [
                                            0,
                                            0,
                                            0,
                                            {
                                              NAME: "num",
                                              VAL: 0.6
                                            }
                                          ]
                                        }),
                                    tl: {
                                      hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.padding2(Css.px(30), Css.px(40)),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.boxShadow(Styles.Shadow.box),
                              tl: {
                                hd: Css.textAlign("center"),
                                tl: {
                                  hd: Css.maxWidth(Css.px(400)),
                                  tl: {
                                    hd: Css.maxHeight(Css.vh(100)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginTop(Css.px(-110)),
                            tl: {
                              hd: Css.padding(Css.px(15)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(IconRocket.make, {
                          size: 144
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: {
                              hd: Css.fontSize(Css.px(28)),
                              tl: {
                                hd: Css.color(Styles.Color.grey80),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, "Avo needs a reload"), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.regular),
                            tl: {
                              hd: Css.padding2(Css.px(15), Css.px(0)),
                              tl: /* [] */0
                            }
                          })
                    }, "Sometimes we make improvements that require you to reload Avo to make sure you're running the latest version. This is one of those times. Please reload Avo before you continue."), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement(Button.make, {
                      label: "Reload Avo",
                      onClick: (function (param) {
                          AnalyticsRe.versionUpdateAccepted(toVersion);
                          window.location.reload(true);
                          
                        })
                    })));
}

var make = UpdatePrompt;

exports.make = make;
/* Css Not a pure module */
