// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Diff = require("diff");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("../styles.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");

function getChangeKey(index, change) {
  return String(index) + (change.value + ((
              change.added ? "-added" : ""
            ) + (
              change.removed ? "-removed" : ""
            )));
}

var hasDiff = Caml_obj.caml_notequal;

function printLines(from, to_) {
  return Belt_Array.map(Diff.diffLines(from, to_), (function (lines) {
                  return Belt_Array.map(Belt_Array.keep(lines.value.split("\n"), (function (line) {
                                      return line.trim() !== "";
                                    })), (function (line) {
                                  return (
                                          lines.added ? "+" : (
                                              lines.removed ? "-" : " "
                                            )
                                        ) + " " + line;
                                })).join("\n");
                })).join("\n");
}

function renderStringDiff(customDisplayOpt, strikethroughOpt, wordDiffOpt, from, to_) {
  var customDisplay = customDisplayOpt !== undefined ? customDisplayOpt : "inline";
  var strikethrough = strikethroughOpt !== undefined ? strikethroughOpt : false;
  var wordDiff = wordDiffOpt !== undefined ? wordDiffOpt : true;
  if (from === "") {
    return React.createElement("span", {
                className: Curry._1(Css.style, {
                      hd: Css.display(customDisplay),
                      tl: {
                        hd: Css.color(Styles.Color.mintGreenSecondary),
                        tl: /* [] */0
                      }
                    })
              }, to_);
  }
  if (to_ === "") {
    return React.createElement("span", {
                className: Curry._1(Css.style, {
                      hd: Css.display(customDisplay),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.grapeErrorBg),
                        tl: {
                          hd: Css.color(Styles.Color.grapeError),
                          tl: {
                            hd: Css.textDecoration(strikethrough ? "lineThrough" : "none"),
                            tl: /* [] */0
                          }
                        }
                      }
                    })
              }, from);
  }
  var changes = wordDiff ? Diff.diffWordsWithSpace(from, to_) : Diff.diffLines(from, to_);
  return Belt_Array.mapWithIndexU(changes, (function (index, change) {
                var match = change.added;
                var match$1 = change.removed;
                return React.createElement("span", {
                            key: getChangeKey(index, change),
                            className: Curry._1(Css.style, match ? ({
                                      hd: Css.display(customDisplay),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.mintGreenBg),
                                        tl: {
                                          hd: Css.color(Styles.Color.mintGreenSecondary),
                                          tl: /* [] */0
                                        }
                                      }
                                    }) : (
                                    match$1 ? ({
                                          hd: Css.display(customDisplay),
                                          tl: {
                                            hd: Css.backgroundColor(Styles.Color.grapeErrorBg),
                                            tl: {
                                              hd: Css.color(Styles.Color.grapeError),
                                              tl: {
                                                hd: Css.textDecoration(strikethrough ? "lineThrough" : "none"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }) : /* [] */0
                                  ))
                          }, change.value);
              }));
}

exports.getChangeKey = getChangeKey;
exports.hasDiff = hasDiff;
exports.printLines = printLines;
exports.renderStringDiff = renderStringDiff;
/* Css Not a pure module */
