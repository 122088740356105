// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Case = require("../../bs-case/src/case.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Shortid = require("shortid");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameInput = require("./NameInput.bs.js");
var NameUtils = require("../../shared/utils/NameUtils.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");
var DiscrepancyContext = require("./DiscrepancyContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");

function isValid(name, expectedEventCase, events) {
  return NameInput.isValid(NameInput.validateInput(StateUtils.getEventNamespace(events), name, name, expectedEventCase));
}

function createEvent(eventId, name, namingConvention, correctCase, workspace, model, sendActions, addToast, currentFilters, onSuccessOpt, extraCreateActionsOpt, schemaGroup, eventOrigin, param) {
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (_namedBranch) {
        
      });
  var extraCreateActions = extraCreateActionsOpt !== undefined ? extraCreateActionsOpt : (function (_eventId) {
        return [];
      });
  var areEventSourcesAndDestinationsConfigurable = AvoLimits.ConfigEventSourcesAndDestinations.isAvailable(workspace);
  var codegenAvailability = Belt_List.head(AvoLimits.CodegenEvents.computeEntityNumericLimits(workspace, model));
  return Curry.app(sendActions, [
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              (function (branch) {
                  Curry._1(addToast, {
                        message: "Event \"" + name + "\" created 🎉",
                        toastType: /* Success */0,
                        action: {
                          message: "View",
                          toastCallback: (function (param) {
                              return Router.Schema.pushDrawerItem(undefined, {
                                          NAME: "event",
                                          VAL: [
                                            eventId,
                                            undefined,
                                            undefined,
                                            false
                                          ]
                                        });
                            })
                        }
                      });
                  var schema = AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch);
                  AnalyticsRe.eventCreated(currentFilters, schema, eventId, name, correctCase, namingConvention, eventOrigin, schema.branchId, schemaGroup.schemaId);
                  AnalyticsRe.eventNameUpdated(schema, currentFilters, eventId, name, namingConvention, undefined, name.length, Case.to_("Sentence", name).split(" ").length, correctCase, eventId, schema.branchId, schemaGroup.schemaId, eventId, name);
                  if (!areEventSourcesAndDestinationsConfigurable) {
                    AnalyticsRe.eventUpdated(schema, AnalyticsRe.Group.source("N/A", "N/A"), eventId, name, "AddSources", undefined, undefined, Belt_List.toArray(Belt_List.map(model.sources, (function (source) {
                                    return source.id;
                                  }))), [], undefined, Belt_List.length(BeltListExtensions.dedupeString(StateUtils.eventsWithNameMapping(model))), Belt_List.length(StateUtils.eventsWithNameMapping(model)), Belt_List.length(Belt_List.keep(StateUtils.eventsWithNameMapping(model), (function (id) {
                                    return id === eventId;
                                  }))), Belt_List.length(model.events), undefined, undefined, undefined, undefined, undefined, 0, correctCase, namingConvention, eventId, schema.schemaId, schema.branchId, eventId, undefined, name);
                  }
                  return Curry._1(onSuccess, branch);
                }),
              undefined,
              Belt_Array.concatMany([
                    [[
                        {
                          NAME: "AddEvent",
                          VAL: [
                            eventId,
                            name
                          ]
                        },
                        {
                          eventId: eventId,
                          eventQuery: eventId
                        }
                      ]],
                    areEventSourcesAndDestinationsConfigurable ? [] : Belt_Array.map(Belt_List.toArray(model.sources), (function (source) {
                              return [
                                      {
                                        NAME: "IncludeEventInSourceV2",
                                        VAL: [
                                          eventId,
                                          source.id,
                                          StateUtils.getSourceDestinationIds(source.destinations),
                                          codegenAvailability !== undefined ? codegenAvailability.NAME === "AvailableDuringTrial" : true
                                        ]
                                      },
                                      {
                                        eventId: eventId,
                                        eventQuery: eventId,
                                        sourceId: source.id,
                                        sourceQuery: source.id
                                      }
                                    ];
                            })),
                    Curry._1(extraCreateActions, eventId)
                  ])
            ]);
}

function NewEventModal(Props) {
  var currentFilters = Props.currentFilters;
  var events = Props.events;
  var model = Props.model;
  var name = Props.name;
  var onClose = Props.onClose;
  var onCreateOpt = Props.onCreate;
  var eventOrigin = Props.eventOrigin;
  var onCreate = onCreateOpt !== undefined ? onCreateOpt : (function (param, param$1) {
        
      });
  var schemaGroup = SchemaGroupContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var sendActions = SendActionsContext.use(undefined);
  var eventIdRef = React.useRef(Shortid());
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var eventId = eventIdRef.current;
  var correctCase = NameUtils.isCorrectCase(expectedEventCase, name);
  React.useEffect((function () {
          AnalyticsRe.eventInitiated(currentFilters, schemaGroup, eventId, "", correctCase, expectedEventCase, schemaGroup.branchId, schemaGroup.schemaId);
          
        }), []);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(600)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(75)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(NameInput.make, {
                  name: name,
                  existingNames: StateUtils.getEventNamespace(events),
                  expectedCase: expectedEventCase,
                  forceCase: match.config.forceEventCase,
                  autoFocus: true,
                  onChange: (function (name, namingConvention, correctCase) {
                      createEvent(eventId, name, namingConvention, correctCase, workspace, model, sendActions, addToast, currentFilters, undefined, undefined, schemaGroup, eventOrigin, undefined);
                      Curry._2(onCreate, eventId, name);
                      return Curry._1(onClose, undefined);
                    }),
                  submitLabel: "Create Event",
                  placeholder: "Event Name",
                  onClose: (function (param) {
                      return Curry._1(onClose, undefined);
                    }),
                  fullscreen: true,
                  dirty: name !== "",
                  itemType: "Event",
                  actionType: "Create",
                  getPossibleItemLink: (function (eventName) {
                      return Belt_Option.map(StateUtils.getEventByName(eventName, model), (function (param) {
                                    return Router.Link.addDrawerItem(undefined, {
                                                NAME: "event",
                                                VAL: [
                                                  param.id,
                                                  undefined,
                                                  undefined,
                                                  false
                                                ]
                                              });
                                  }));
                    })
                }));
}

var make = NewEventModal;

exports.isValid = isValid;
exports.createEvent = createEvent;
exports.make = make;
/* Css Not a pure module */
