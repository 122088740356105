// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var InspectorIssueType = require("./InspectorIssueType.bs.js");

var _map = {"eventNotInTrackingPlan":"Event Not In Tracking Plan","propertyNotInTrackingPlan":"Property not found in Tracking Plan","missingExpectedProperty":"Missing expected property","sometimesMissing":"Property sometimes missing","inconsistentType":"Inconsistent property value type","propertyTypeInconsistentWithTrackingPlan":"Property inconsistent with tracking plan","unexpectedProperty":"Unexpected property"};

var _revMap = {"Event Not In Tracking Plan":"eventNotInTrackingPlan","Property not found in Tracking Plan":"propertyNotInTrackingPlan","Missing expected property":"missingExpectedProperty","Property sometimes missing":"sometimesMissing","Inconsistent property value type":"inconsistentType","Property inconsistent with tracking plan":"propertyTypeInconsistentWithTrackingPlan","Unexpected property":"unexpectedProperty"};

function oldToJs(param) {
  return _map[param];
}

function oldFromJs(param) {
  return _revMap[param];
}

var _map$1 = {"eventNotInTrackingPlanV2":"Event Not In Tracking Plan V2","missingExpectedPropertyV2":"Missing Expected Property V2","propertyTypeInconsistentWithTrackingPlanV2":"Property Type Inconsistent With Tracking Plan V2","unexpectedPropertyV2":"Unexpected Property V2","unexpectedEvent":"Unexpected Event","inconsistentTypeV2":"Inconsistent Type V2"};

var _revMap$1 = {"Event Not In Tracking Plan V2":"eventNotInTrackingPlanV2","Missing Expected Property V2":"missingExpectedPropertyV2","Property Type Inconsistent With Tracking Plan V2":"propertyTypeInconsistentWithTrackingPlanV2","Unexpected Property V2":"unexpectedPropertyV2","Unexpected Event":"unexpectedEvent","Inconsistent Type V2":"inconsistentTypeV2"};

function newToJs(param) {
  return _map$1[param];
}

function newFromJs(param) {
  return _revMap$1[param];
}

function getFilterTypes(param) {
  return {
          old: [
            "missingExpectedProperty",
            "sometimesMissing",
            "inconsistentType",
            "propertyTypeInconsistentWithTrackingPlan",
            "unexpectedProperty"
          ],
          new: [
            "eventNotInTrackingPlanV2",
            "missingExpectedPropertyV2",
            "propertyTypeInconsistentWithTrackingPlanV2",
            "unexpectedPropertyV2",
            "unexpectedEvent",
            "inconsistentTypeV2"
          ]
        };
}

function toString(issueType) {
  if (issueType.NAME === "old") {
    return _map[issueType.VAL];
  } else {
    return _map$1[issueType.VAL];
  }
}

function fromString(issueTypeString) {
  var match = _revMap[issueTypeString];
  var match$1 = _revMap$1[issueTypeString];
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return ;
    } else {
      return {
              NAME: "old",
              VAL: match
            };
    }
  } else if (match$1 !== undefined) {
    return {
            NAME: "new",
            VAL: match$1
          };
  } else {
    return ;
  }
}

function toDisplayString(issueType) {
  if (issueType.NAME === "old") {
    return toString(issueType);
  }
  var $$new = issueType.VAL;
  return InspectorIssueType.getIssueTypeAsText($$new === "unexpectedPropertyV2" ? ({
                  TAG: /* UnexpectedProperty */2,
                  _0: InspectorIssueType.UnexpectedProperty.empty
                }) : (
                $$new === "propertyTypeInconsistentWithTrackingPlanV2" ? ({
                      TAG: /* PropertyTypeInconsistentWithTrackingPlan */1,
                      _0: InspectorIssueType.PropertyTypeInconsistentWithTrackingPlan.empty
                    }) : (
                    $$new === "inconsistentTypeV2" ? ({
                          TAG: /* InconsistentType */3,
                          _0: InspectorIssueType.InconsistentType.empty
                        }) : (
                        $$new === "eventNotInTrackingPlanV2" ? /* EventNotInTrackingPlan */0 : (
                            $$new === "unexpectedEvent" ? /* UnexpectedEvent */1 : ({
                                  TAG: /* MissingExpectedProperty */0,
                                  _0: InspectorIssueType.MissingExpectedProperty.empty
                                })
                          )
                      )
                  )
              ));
}

function fromNewIssue(issue) {
  var match = issue.issueType;
  if (typeof match === "number") {
    if (match === /* EventNotInTrackingPlan */0) {
      return {
              NAME: "new",
              VAL: "eventNotInTrackingPlanV2"
            };
    } else {
      return {
              NAME: "new",
              VAL: "unexpectedEvent"
            };
    }
  }
  switch (match.TAG | 0) {
    case /* MissingExpectedProperty */0 :
        return {
                NAME: "new",
                VAL: "missingExpectedPropertyV2"
              };
    case /* PropertyTypeInconsistentWithTrackingPlan */1 :
        return {
                NAME: "new",
                VAL: "propertyTypeInconsistentWithTrackingPlanV2"
              };
    case /* UnexpectedProperty */2 :
        return {
                NAME: "new",
                VAL: "unexpectedPropertyV2"
              };
    case /* InconsistentType */3 :
        return {
                NAME: "new",
                VAL: "inconsistentTypeV2"
              };
    
  }
}

var cmp = Caml_obj.caml_compare;

var Comparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

var FilterIssueType = {
  oldToJs: oldToJs,
  oldFromJs: oldFromJs,
  newToJs: newToJs,
  newFromJs: newFromJs,
  getFilterTypes: getFilterTypes,
  oldToString: oldToJs,
  oldFromString: oldFromJs,
  newToString: newToJs,
  newFromString: newFromJs,
  toString: toString,
  fromString: fromString,
  toDisplayString: toDisplayString,
  fromNewIssue: fromNewIssue,
  Comparator: Comparator
};

var _map$2 = {"issueType":"Issue Type","source":"Source","eventName":"Event Name","propertyName":"Property Name","category":"Category","tag":"Tag","release":"Release"};

var _revMap$2 = {"Issue Type":"issueType","Source":"source","Event Name":"eventName","Property Name":"propertyName","Category":"category","Tag":"tag","Release":"release"};

function tToJs(param) {
  return _map$2[param];
}

function tFromJs(param) {
  return _revMap$2[param];
}

var availableFilterTypes = [
  "issueType",
  "source",
  "eventName",
  "propertyName",
  "category",
  "tag",
  "release"
];

var cmp$1 = Caml_obj.caml_compare;

var Comparator$1 = Belt_Id.MakeComparable({
      cmp: cmp$1
    });

var FilterType = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  availableFilterTypes: availableFilterTypes,
  fromString: tFromJs,
  toString: tToJs,
  Comparator: Comparator$1
};

var _map$3 = {"latestRelease":"latestRelease","twoLatestReleases":"twoLatestReleases","threeLatestReleases":"threeLatestReleases"};

function tToJs$1(param) {
  return param;
}

function tFromJs$1(param) {
  return _map$3[param];
}

function toDisplayString$1(release) {
  if (release === "threeLatestReleases") {
    return "Three latest releases";
  } else if (release === "twoLatestReleases") {
    return "Two latest releases";
  } else {
    return "Latest release";
  }
}

var cmp$2 = Caml_obj.caml_compare;

var Comparator$2 = Belt_Id.MakeComparable({
      cmp: cmp$2
    });

var Release = {
  tToJs: tToJs$1,
  tFromJs: tFromJs$1,
  toString: tToJs$1,
  fromString: tFromJs$1,
  toDisplayString: toDisplayString$1,
  Comparator: Comparator$2
};

function cmpType(a, b) {
  var variant = a.NAME;
  if (variant === "eventName") {
    if (typeof b === "object") {
      return b.NAME === "eventName";
    } else {
      return false;
    }
  } else if (variant === "tag") {
    if (typeof b === "object") {
      return b.NAME === "tag";
    } else {
      return false;
    }
  } else if (variant === "issueType") {
    if (typeof b === "object") {
      return b.NAME === "issueType";
    } else {
      return false;
    }
  } else if (variant === "propertyName") {
    if (typeof b === "object") {
      return b.NAME === "propertyName";
    } else {
      return false;
    }
  } else if (variant === "category") {
    if (typeof b === "object") {
      return b.NAME === "category";
    } else {
      return false;
    }
  } else if (variant === "source") {
    if (typeof b === "object") {
      return b.NAME === "source";
    } else {
      return false;
    }
  } else if (typeof b === "object") {
    return b.NAME === "release";
  } else {
    return false;
  }
}

function toFilterType(filter) {
  var variant = filter.NAME;
  if (variant === "eventName") {
    return "eventName";
  } else if (variant === "tag") {
    return "tag";
  } else if (variant === "issueType") {
    return "issueType";
  } else if (variant === "propertyName") {
    return "propertyName";
  } else if (variant === "category") {
    return "category";
  } else if (variant === "source") {
    return "source";
  } else {
    return "release";
  }
}

function toValue(filter) {
  var variant = filter.NAME;
  if (variant === "source" || variant === "category" || variant === "propertyName" || variant === "tag" || variant === "eventName" || variant !== "issueType") {
    return filter.VAL;
  } else {
    return toString(filter.VAL);
  }
}

function toKeyValue(filter) {
  return _map$2[toFilterType(filter)] + ": " + toValue(filter);
}

function toFilterFromStrings(filterType, value) {
  var match = _revMap$2[filterType];
  if (match !== undefined) {
    if (match === "eventName") {
      return {
              NAME: "eventName",
              VAL: value
            };
    } else if (match === "tag") {
      return {
              NAME: "tag",
              VAL: value
            };
    } else if (match === "issueType") {
      return Belt_Option.map(fromString(value), (function (value) {
                    return {
                            NAME: "issueType",
                            VAL: value
                          };
                  }));
    } else if (match === "propertyName") {
      return {
              NAME: "propertyName",
              VAL: value
            };
    } else if (match === "category") {
      return {
              NAME: "category",
              VAL: value
            };
    } else if (match === "source") {
      return {
              NAME: "source",
              VAL: value
            };
    } else {
      return Belt_Option.map(_map$3[value], (function (release) {
                    return {
                            NAME: "release",
                            VAL: release
                          };
                  }));
    }
  }
  
}

function fromString$1(filter) {
  var re = new RegExp("(" + Belt_Array.map(availableFilterTypes, tToJs).join("|") + "): ");
  var parts = filter.split(re);
  if (parts.length !== 3) {
    return ;
  }
  var filterTypeStr = parts[1];
  if (filterTypeStr === undefined) {
    return ;
  }
  var valueStr = parts[2];
  if (valueStr !== undefined) {
    return toFilterFromStrings(filterTypeStr, valueStr);
  }
  
}

function cmp$3(a, b) {
  var variant = a.NAME;
  if (variant === "eventName") {
    if (typeof b === "object" && b.NAME === "eventName") {
      return Caml_obj.caml_compare(a.VAL, b.VAL);
    } else {
      return Caml_obj.caml_compare(a, b);
    }
  } else if (variant === "tag") {
    if (typeof b === "object" && b.NAME === "tag") {
      return Caml_obj.caml_compare(a.VAL, b.VAL);
    } else {
      return Caml_obj.caml_compare(a, b);
    }
  } else if (variant === "issueType") {
    if (typeof b === "object" && b.NAME === "issueType") {
      return Caml_obj.caml_compare(a.VAL, b.VAL);
    } else {
      return Caml_obj.caml_compare(a, b);
    }
  } else if (variant === "propertyName") {
    if (typeof b === "object" && b.NAME === "propertyName") {
      return Caml_obj.caml_compare(a.VAL, b.VAL);
    } else {
      return Caml_obj.caml_compare(a, b);
    }
  } else if (variant === "category") {
    if (typeof b === "object" && b.NAME === "category") {
      return Caml_obj.caml_compare(a.VAL, b.VAL);
    } else {
      return Caml_obj.caml_compare(a, b);
    }
  } else if (variant === "source") {
    if (typeof b === "object" && b.NAME === "source") {
      return Caml_obj.caml_compare(a.VAL, b.VAL);
    } else {
      return Caml_obj.caml_compare(a, b);
    }
  } else if (typeof b === "object" && b.NAME === "release") {
    return Caml_obj.caml_compare(a.VAL, b.VAL);
  } else {
    return Caml_obj.caml_compare(a, b);
  }
}

var Comparator$3 = Belt_Id.MakeComparable({
      cmp: cmp$3
    });

var Filter = {
  Release: Release,
  cmpType: cmpType,
  toFilterType: toFilterType,
  toValue: toValue,
  toKeyValue: toKeyValue,
  toFilterFromStrings: toFilterFromStrings,
  fromString: fromString$1,
  Comparator: Comparator$3
};

function fromFilterType(filterType) {
  if (filterType === "eventName") {
    return {
            NAME: "eventNames",
            VAL: []
          };
  } else if (filterType === "tag") {
    return {
            NAME: "tags",
            VAL: []
          };
  } else if (filterType === "issueType") {
    return {
            NAME: "issueTypes",
            VAL: []
          };
  } else if (filterType === "propertyName") {
    return {
            NAME: "propertyNames",
            VAL: []
          };
  } else if (filterType === "category") {
    return {
            NAME: "categories",
            VAL: []
          };
  } else if (filterType === "source") {
    return {
            NAME: "sources",
            VAL: []
          };
  } else {
    return {
            NAME: "releases",
            VAL: []
          };
  }
}

function toFilterType$1(groupedFilter) {
  var variant = groupedFilter.NAME;
  if (variant === "propertyNames") {
    return "propertyName";
  } else if (variant === "issueTypes") {
    return "issueType";
  } else if (variant === "releases") {
    return "release";
  } else if (variant === "categories") {
    return "category";
  } else if (variant === "eventNames") {
    return "eventName";
  } else if (variant === "sources") {
    return "source";
  } else {
    return "tag";
  }
}

function toValues(groupedFilter) {
  var variant = groupedFilter.NAME;
  if (variant === "issueTypes") {
    return Belt_Array.map(groupedFilter.VAL, toDisplayString);
  } else if (variant === "releases") {
    return Belt_Array.map(groupedFilter.VAL, toDisplayString$1);
  } else {
    return groupedFilter.VAL;
  }
}

function toString$1(groupedFilter) {
  var key = _map$2[toFilterType$1(groupedFilter)];
  var value = toValues(groupedFilter).join(", ");
  return key + ": " + value;
}

function typeToString(groupedFilter) {
  return _map$2[toFilterType$1(groupedFilter)];
}

function toGroupedFilter(counted) {
  var variant = counted.NAME;
  if (variant === "propertyNames") {
    return {
            NAME: "propertyNames",
            VAL: Belt_Array.map(counted.VAL, (function (param) {
                    return param[0];
                  }))
          };
  } else if (variant === "issueTypes") {
    return {
            NAME: "issueTypes",
            VAL: Belt_Array.map(counted.VAL, (function (param) {
                    return param[0];
                  }))
          };
  } else if (variant === "releases") {
    return {
            NAME: "releases",
            VAL: Belt_Array.map(counted.VAL, (function (param) {
                    return param[0];
                  }))
          };
  } else if (variant === "categories") {
    return {
            NAME: "categories",
            VAL: Belt_Array.map(counted.VAL, (function (param) {
                    return param[0];
                  }))
          };
  } else if (variant === "eventNames") {
    return {
            NAME: "eventNames",
            VAL: Belt_Array.map(counted.VAL, (function (param) {
                    return param[0];
                  }))
          };
  } else if (variant === "sources") {
    return {
            NAME: "sources",
            VAL: Belt_Array.map(counted.VAL, (function (param) {
                    return param[0];
                  }))
          };
  } else {
    return {
            NAME: "tags",
            VAL: Belt_Array.map(counted.VAL, (function (param) {
                    return param[0];
                  }))
          };
  }
}

function toKey(counted) {
  return _map$2[toFilterType$1(toGroupedFilter(counted))];
}

var Counted = {
  toGroupedFilter: toGroupedFilter,
  toKey: toKey
};

function fromFilter(filter) {
  var variant = filter.NAME;
  if (variant === "eventName") {
    return {
            NAME: "eventNames",
            VAL: [filter.VAL]
          };
  } else if (variant === "tag") {
    return {
            NAME: "tags",
            VAL: [filter.VAL]
          };
  } else if (variant === "issueType") {
    return {
            NAME: "issueTypes",
            VAL: [filter.VAL]
          };
  } else if (variant === "propertyName") {
    return {
            NAME: "propertyNames",
            VAL: [filter.VAL]
          };
  } else if (variant === "category") {
    return {
            NAME: "categories",
            VAL: [filter.VAL]
          };
  } else if (variant === "source") {
    return {
            NAME: "sources",
            VAL: [filter.VAL]
          };
  } else {
    return {
            NAME: "releases",
            VAL: [filter.VAL]
          };
  }
}

function toFilters(groupedFilter) {
  var variant = groupedFilter.NAME;
  if (variant === "propertyNames") {
    return Belt_Array.map(groupedFilter.VAL, (function (propertyName) {
                  return {
                          NAME: "propertyName",
                          VAL: propertyName
                        };
                }));
  } else if (variant === "issueTypes") {
    return Belt_Array.map(groupedFilter.VAL, (function (issueType) {
                  return {
                          NAME: "issueType",
                          VAL: issueType
                        };
                }));
  } else if (variant === "releases") {
    return Belt_Array.map(groupedFilter.VAL, (function (release) {
                  return {
                          NAME: "release",
                          VAL: release
                        };
                }));
  } else if (variant === "categories") {
    return Belt_Array.map(groupedFilter.VAL, (function (categoryId) {
                  return {
                          NAME: "category",
                          VAL: categoryId
                        };
                }));
  } else if (variant === "eventNames") {
    return Belt_Array.map(groupedFilter.VAL, (function (eventName) {
                  return {
                          NAME: "eventName",
                          VAL: eventName
                        };
                }));
  } else if (variant === "sources") {
    return Belt_Array.map(groupedFilter.VAL, (function (source) {
                  return {
                          NAME: "source",
                          VAL: source
                        };
                }));
  } else {
    return Belt_Array.map(groupedFilter.VAL, (function (tag) {
                  return {
                          NAME: "tag",
                          VAL: tag
                        };
                }));
  }
}

function eq(a, b) {
  var exit = 0;
  var a$1;
  var b$1;
  var a$2;
  var b$2;
  var variant = a.NAME;
  if (variant === "propertyNames") {
    if (typeof b === "object" && b.NAME === "propertyNames") {
      a$1 = a.VAL;
      b$1 = b.VAL;
      exit = 1;
    } else {
      a$2 = a;
      b$2 = b;
      exit = 2;
    }
  } else if (variant === "issueTypes") {
    if (typeof b === "object") {
      if (b.NAME === "issueTypes") {
        return Belt_Set.eq(Belt_Set.fromArray(a.VAL, Comparator), Belt_Set.fromArray(b.VAL, Comparator));
      }
      a$2 = a;
      b$2 = b;
      exit = 2;
    } else {
      a$2 = a;
      b$2 = b;
      exit = 2;
    }
  } else if (variant === "releases") {
    if (typeof b === "object") {
      if (b.NAME === "releases") {
        return Belt_Set.eq(Belt_Set.fromArray(a.VAL, Comparator$2), Belt_Set.fromArray(b.VAL, Comparator$2));
      }
      a$2 = a;
      b$2 = b;
      exit = 2;
    } else {
      a$2 = a;
      b$2 = b;
      exit = 2;
    }
  } else if (variant === "categories") {
    if (typeof b === "object" && b.NAME === "categories") {
      a$1 = a.VAL;
      b$1 = b.VAL;
      exit = 1;
    } else {
      a$2 = a;
      b$2 = b;
      exit = 2;
    }
  } else if (variant === "eventNames") {
    if (typeof b === "object" && b.NAME === "eventNames") {
      a$1 = a.VAL;
      b$1 = b.VAL;
      exit = 1;
    } else {
      a$2 = a;
      b$2 = b;
      exit = 2;
    }
  } else if (variant === "sources") {
    if (typeof b === "object" && b.NAME === "sources") {
      a$1 = a.VAL;
      b$1 = b.VAL;
      exit = 1;
    } else {
      a$2 = a;
      b$2 = b;
      exit = 2;
    }
  } else if (typeof b === "object" && b.NAME === "tags") {
    a$1 = a.VAL;
    b$1 = b.VAL;
    exit = 1;
  } else {
    a$2 = a;
    b$2 = b;
    exit = 2;
  }
  switch (exit) {
    case 1 :
        return Belt_SetString.eq(Belt_SetString.fromArray(a$1), Belt_SetString.fromArray(b$1));
    case 2 :
        return Caml_obj.caml_equal(a$2, b$2);
    
  }
}

function cmp$4(a, b) {
  var a$1;
  var b$1;
  var variant = a.NAME;
  if (variant === "propertyNames") {
    if (typeof b !== "object") {
      return Caml_obj.caml_compare(a, b);
    }
    if (b.NAME !== "propertyNames") {
      return Caml_obj.caml_compare(a, b);
    }
    a$1 = a.VAL;
    b$1 = b.VAL;
  } else {
    if (variant === "issueTypes") {
      if (typeof b === "object" && b.NAME === "issueTypes") {
        return Caml_obj.caml_compare(Belt_Set.toArray(Belt_Set.fromArray(a.VAL, Comparator)), Belt_Set.toArray(Belt_Set.fromArray(b.VAL, Comparator)));
      } else {
        return Caml_obj.caml_compare(a, b);
      }
    }
    if (variant === "releases") {
      if (typeof b === "object" && b.NAME === "releases") {
        return Caml_obj.caml_compare(Belt_Set.toArray(Belt_Set.fromArray(a.VAL, Comparator$2)), Belt_Set.toArray(Belt_Set.fromArray(b.VAL, Comparator$2)));
      } else {
        return Caml_obj.caml_compare(a, b);
      }
    }
    if (variant === "categories") {
      if (typeof b !== "object") {
        return Caml_obj.caml_compare(a, b);
      }
      if (b.NAME !== "categories") {
        return Caml_obj.caml_compare(a, b);
      }
      a$1 = a.VAL;
      b$1 = b.VAL;
    } else if (variant === "eventNames") {
      if (typeof b !== "object") {
        return Caml_obj.caml_compare(a, b);
      }
      if (b.NAME !== "eventNames") {
        return Caml_obj.caml_compare(a, b);
      }
      a$1 = a.VAL;
      b$1 = b.VAL;
    } else if (variant === "sources") {
      if (typeof b !== "object") {
        return Caml_obj.caml_compare(a, b);
      }
      if (b.NAME !== "sources") {
        return Caml_obj.caml_compare(a, b);
      }
      a$1 = a.VAL;
      b$1 = b.VAL;
    } else {
      if (typeof b !== "object") {
        return Caml_obj.caml_compare(a, b);
      }
      if (b.NAME !== "tags") {
        return Caml_obj.caml_compare(a, b);
      }
      a$1 = a.VAL;
      b$1 = b.VAL;
    }
  }
  return Caml_obj.caml_compare(Belt_SetString.toArray(Belt_SetString.fromArray(a$1)), Belt_SetString.toArray(Belt_SetString.fromArray(b$1)));
}

var Comparator$4 = {
  cmp: cmp$4
};

function sort(groupedFilters) {
  return Belt_List.toArray(Belt_List.sort(Belt_List.fromArray(groupedFilters), cmp$4));
}

function cmpMany(a, b) {
  var a$1 = Belt_List.fromArray(sort(a));
  var b$1 = Belt_List.fromArray(sort(b));
  var _a = a$1;
  var _b = b$1;
  while(true) {
    var b$2 = _b;
    var a$2 = _a;
    if (!a$2) {
      if (b$2) {
        return -1;
      } else {
        return 0;
      }
    }
    if (!b$2) {
      return 1;
    }
    var n = cmp$4(a$2.hd, b$2.hd);
    if (n !== 0) {
      return n;
    }
    _b = b$2.tl;
    _a = a$2.tl;
    continue ;
  };
}

var GroupedFilter = {
  fromFilterType: fromFilterType,
  toFilterType: toFilterType$1,
  toValues: toValues,
  toString: toString$1,
  typeToString: typeToString,
  Counted: Counted,
  fromFilter: fromFilter,
  toFilters: toFilters,
  eq: eq,
  Comparator: Comparator$4,
  sort: sort,
  cmpMany: cmpMany
};

exports.FilterIssueType = FilterIssueType;
exports.FilterType = FilterType;
exports.Filter = Filter;
exports.GroupedFilter = GroupedFilter;
/* Comparator Not a pure module */
