// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Case = require("../../bs-case/src/case.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var NameUtils = require("../../shared/utils/NameUtils.bs.js");
var TextButton = require("./TextButton.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");

function AutoFillCasingDetail(Props) {
  var expectedCase = Props.expectedCase;
  var name = Props.name;
  var kind = Props.kind;
  var globalSend = GlobalSendContext.use(undefined);
  var kindLabel = kind ? "event" : "property";
  return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.grey80,
                  children: "Auto-converting to " + NameUtils.getCorrectCase("Title", Case.tToJs(expectedCase)) + " Case"
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.red70,
                  children: React.createElement("span", {
                        className: Curry._1(Css.style, {
                              hd: Css.textDecoration("lineThrough"),
                              tl: {
                                hd: Css.textDecorationColor(Styles.Color.red100),
                                tl: /* [] */0
                              }
                            })
                      }, name)
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.green100,
                  children: NameUtils.getCorrectCase(expectedCase, name)
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.grey80,
                  children: "Avo checks that " + kindLabel + " names match your workspace's preferred casing."
                }), React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement(TextButton.make, {
                  onClick: (function (param) {
                      return Curry._1(globalSend, {
                                  TAG: /* OpenSlideOver */6,
                                  _0: "DiscrepanciesConfig"
                                });
                    }),
                  style: "Blue",
                  size: "Small",
                  children: "Configure casing rules"
                }));
}

var make = AutoFillCasingDetail;

exports.make = make;
/* Css Not a pure module */
