// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");

function newTextBuilder(param) {
  return [];
}

function addFormatAction(textBuilder, action) {
  textBuilder.push(action);
  
}

function addOffsetC(textBuilder, offset) {
  textBuilder.push({
        TAG: /* AddOffset */0,
        _0: offset
      });
  return textBuilder;
}

function addOffset(textBuilder, offset) {
  textBuilder.push({
        TAG: /* AddOffset */0,
        _0: offset
      });
  
}

function removeOffsetC(textBuilder, offset) {
  textBuilder.push({
        TAG: /* RemoveOffset */1,
        _0: offset
      });
  return textBuilder;
}

function removeOffset(textBuilder, offset) {
  textBuilder.push({
        TAG: /* RemoveOffset */1,
        _0: offset
      });
  
}

function resetOffsetC(textBuilder) {
  textBuilder.push(/* ResetOffset */0);
  return textBuilder;
}

function resetOffset(textBuilder) {
  textBuilder.push(/* ResetOffset */0);
  
}

function addTextC(textBuilder, line) {
  textBuilder.push({
        TAG: /* AddText */2,
        _0: line
      });
  return textBuilder;
}

function addText(textBuilder, line) {
  textBuilder.push({
        TAG: /* AddText */2,
        _0: line
      });
  
}

function newLineC(textBuilder) {
  textBuilder.push(/* NewLine */1);
  return textBuilder;
}

function newLine(textBuilder) {
  textBuilder.push(/* NewLine */1);
  
}

function buildText(textBuilder) {
  return Belt_Array.reduceU(textBuilder, [
                "",
                0
              ], (function (param, action) {
                  var offset = param[1];
                  var resultString = param[0];
                  if (typeof action === "number") {
                    if (action === /* ResetOffset */0) {
                      return [
                              resultString,
                              0
                            ];
                    }
                    var offsetString = "";
                    for(var _for = 1; _for <= offset; ++_for){
                      offsetString = offsetString + " ";
                    }
                    if (resultString === "") {
                      return [
                              "\n" + offsetString,
                              offset
                            ];
                    } else {
                      return [
                              resultString + "\n" + offsetString,
                              offset
                            ];
                    }
                  } else {
                    switch (action.TAG | 0) {
                      case /* AddOffset */0 :
                          return [
                                  resultString,
                                  offset + action._0 | 0
                                ];
                      case /* RemoveOffset */1 :
                          return [
                                  resultString,
                                  Caml.caml_int_max(0, offset - action._0 | 0)
                                ];
                      case /* AddText */2 :
                          return [
                                  resultString + action._0,
                                  offset
                                ];
                      
                    }
                  }
                }))[0];
}

exports.newTextBuilder = newTextBuilder;
exports.addFormatAction = addFormatAction;
exports.addOffsetC = addOffsetC;
exports.addOffset = addOffset;
exports.removeOffsetC = removeOffsetC;
exports.removeOffset = removeOffset;
exports.resetOffsetC = resetOffsetC;
exports.resetOffset = resetOffset;
exports.addTextC = addTextC;
exports.addText = addText;
exports.newLineC = newLineC;
exports.newLine = newLine;
exports.buildText = buildText;
/* No side effect */
