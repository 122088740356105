// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconAddMember(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey70;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("svg", {
                  width: String(size),
                  fill: "none",
                  viewBox: "0 0 22 23",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("path", {
                      d: "M10 13.3059C7.176 13.3059 4.671 13.9479 3.025 14.5064C1.81 14.9199 1 16.0721 1 17.3631V21.3558C1 21.3558 10.958 21.3558 11 21.3558"
                    }), React.createElement("path", {
                      clipRule: "evenodd",
                      d: "M10 13.3058V13.3058C7.239 13.3058 5 10.0466 5 7.26839V6.26215C5 3.48393 7.239 1.23096 10 1.23096V1.23096C12.761 1.23096 15 3.48393 15 6.26215V7.26839C15 10.0466 12.761 13.3058 10 13.3058Z",
                      fillRule: "evenodd",
                      strokeLinecap: "square"
                    }), React.createElement("path", {
                      d: "M18 16.3247V22.3621",
                      strokeLinecap: "square"
                    }), React.createElement("path", {
                      d: "M15 19.3433H21",
                      strokeLinecap: "square"
                    })));
}

var make = IconAddMember;

exports.make = make;
/* Css Not a pure module */
