// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function boot(options) {
  window.Intercom("boot", options);
  
}

function update(options) {
  window.Intercom("update", options);
  
}

function showNewMessage(message) {
  window.Intercom("showNewMessage", message);
  
}

function shutdown(param) {
  window.Intercom("shutdown", {});
  
}

function startTour(tourId) {
  window.Intercom("startTour", tourId);
  
}

function startSurvey(surveyId) {
  window.Intercom("startSurvey", surveyId);
  
}

function getVisitorId(param) {
  return window.Intercom("getVisitorId");
}

function isAvailable(param) {
  return Belt_Option.isSome(Caml_option.nullable_to_opt(window.Intercom("getVisitorId")));
}

function decodeAuthJson(json) {
  return {
          userId: Json_decode.field("userId", Json_decode.string, json),
          userHash: Json_decode.field("userHash", Json_decode.string, json)
        };
}

var appId = "q694k0ra";

exports.appId = appId;
exports.boot = boot;
exports.update = update;
exports.showNewMessage = showNewMessage;
exports.shutdown = shutdown;
exports.startTour = startTour;
exports.startSurvey = startSurvey;
exports.getVisitorId = getVisitorId;
exports.isAvailable = isAvailable;
exports.decodeAuthJson = decodeAuthJson;
/* No side effect */
