// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Caml = require("rescript/lib/js/caml.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Router = require("./Router.bs.js");
var $$Window = require("./externals/window.bs.js");
var Mantine = require("./Mantine.bs.js");
var Belt_Map = require("rescript/lib/js/belt_Map.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var ModelStore = require("./ModelStore.bs.js");
var React$1 = require("@dopt/react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ProductTour = require("./ProductTour.bs.js");
var RouterStore = require("./RouterStore.bs.js");
var Belt_SortArray = require("rescript/lib/js/belt_SortArray.js");
var InspectorStats = require("./inspector/InspectorStats.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var InspectorIssuesStore = require("./inspector/InspectorIssuesStore.bs.js");
var InspectorIssuesFilter = require("../../shared/models/InspectorIssuesFilter.bs.js");
var InspectorIssuesFilterHooks = require("./inspector/InspectorIssuesFilterHooks.bs.js");
var InspectorIssuesFilterUtils = require("../../shared/utils/InspectorIssuesFilterUtils.bs.js");

function InspectorIssuesOnboardingFlow$IssuesWrapper(Props) {
  var stepId = Props.stepId;
  var arrowOffset = Props.arrowOffset;
  var delay = Props.delay;
  var elementClickable = Props.elementClickable;
  var elementPadding = Props.elementPadding;
  var lockBackground = Props.lockBackground;
  var offset = Props.offset;
  var onClick = Props.onClick;
  var onStepActive = Props.onStepActive;
  var onTransition = Props.onTransition;
  var position = Props.position;
  var positionDependencies = Props.positionDependencies;
  var simulateClick = Props.simulateClick;
  var stepType = Props.stepType;
  var transition = Props.transition;
  var transitionDuration = Props.transitionDuration;
  var transitions = Props.transitions;
  var waitForId = Props.waitForId;
  var withOutline = Props.withOutline;
  var children = Props.children;
  var match = InspectorIssuesStore.useIssues(true, undefined);
  var issues = match.issues;
  var onTransition$1 = Belt_Option.map(onTransition, (function (onTransition) {
          return Curry._1(onTransition, issues);
        }));
  var onStepActive$1 = Belt_Option.map(onStepActive, (function (onStepActive, param) {
          return Curry._1(onStepActive, issues);
        }));
  var tmp = {
    stepId: stepId,
    stepType: stepType,
    transitions: Curry._1(transitions, issues),
    children: children
  };
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = Caml_option.valFromOption(arrowOffset);
  }
  if (delay !== undefined) {
    tmp.delay = Caml_option.valFromOption(delay);
  }
  if (elementClickable !== undefined) {
    tmp.elementClickable = Caml_option.valFromOption(elementClickable);
  }
  if (elementPadding !== undefined) {
    tmp.elementPadding = Caml_option.valFromOption(elementPadding);
  }
  if (lockBackground !== undefined) {
    tmp.lockBackground = Caml_option.valFromOption(lockBackground);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onTransition$1 !== undefined) {
    tmp.onTransition = Caml_option.valFromOption(onTransition$1);
  }
  if (onStepActive$1 !== undefined) {
    tmp.onStepActive = Caml_option.valFromOption(onStepActive$1);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (positionDependencies !== undefined) {
    tmp.positionDependencies = Caml_option.valFromOption(positionDependencies);
  }
  if (simulateClick !== undefined) {
    tmp.simulateClick = Caml_option.valFromOption(simulateClick);
  }
  if (transition !== undefined) {
    tmp.transition = Caml_option.valFromOption(transition);
  }
  if (transitionDuration !== undefined) {
    tmp.transitionDuration = Caml_option.valFromOption(transitionDuration);
  }
  if (waitForId !== undefined) {
    tmp.waitForId = Caml_option.valFromOption(waitForId);
  }
  if (withOutline !== undefined) {
    tmp.withOutline = Caml_option.valFromOption(withOutline);
  }
  return React.createElement(ProductTour.Step.make, tmp);
}

function InspectorIssuesOnboardingFlow$ConditionalProductStep(Props) {
  var tourId = Props.tourId;
  var stepId = Props.stepId;
  var arrowOffset = Props.arrowOffset;
  var delay = Props.delay;
  var elementClickable = Props.elementClickable;
  var elementPadding = Props.elementPadding;
  var lockBackground = Props.lockBackground;
  var offset = Props.offset;
  var onClick = Props.onClick;
  var onStepActive = Props.onStepActive;
  var onTransition = Props.onTransition;
  var position = Props.position;
  var positionDependencies = Props.positionDependencies;
  var simulateClick = Props.simulateClick;
  var stepType = Props.stepType;
  var transition = Props.transition;
  var transitionDuration = Props.transitionDuration;
  var transitions = Props.transitions;
  var waitForId = Props.waitForId;
  var withOutline = Props.withOutline;
  var children = Props.children;
  var match = React$1.useBlock(tourId + "." + stepId);
  var opened = match[0].state.active;
  if (!opened) {
    return null;
  }
  var tmp = {
    stepId: stepId,
    stepType: stepType,
    transitions: transitions,
    children: children
  };
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = arrowOffset;
  }
  if (delay !== undefined) {
    tmp.delay = delay;
  }
  if (elementClickable !== undefined) {
    tmp.elementClickable = elementClickable;
  }
  if (elementPadding !== undefined) {
    tmp.elementPadding = elementPadding;
  }
  if (lockBackground !== undefined) {
    tmp.lockBackground = lockBackground;
  }
  if (offset !== undefined) {
    tmp.offset = offset;
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onStepActive !== undefined) {
    tmp.onStepActive = Caml_option.valFromOption(onStepActive);
  }
  if (onTransition !== undefined) {
    tmp.onTransition = Caml_option.valFromOption(onTransition);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (positionDependencies !== undefined) {
    tmp.positionDependencies = Caml_option.valFromOption(positionDependencies);
  }
  if (simulateClick !== undefined) {
    tmp.simulateClick = Caml_option.valFromOption(simulateClick);
  }
  if (transition !== undefined) {
    tmp.transition = Caml_option.valFromOption(transition);
  }
  if (transitionDuration !== undefined) {
    tmp.transitionDuration = transitionDuration;
  }
  if (waitForId !== undefined) {
    tmp.waitForId = waitForId;
  }
  if (withOutline !== undefined) {
    tmp.withOutline = withOutline;
  }
  return React.createElement(InspectorIssuesOnboardingFlow$IssuesWrapper, tmp);
}

function InspectorIssuesOnboardingFlow(Props) {
  var match = WorkspaceContext.use(undefined);
  var inspectorStats = InspectorStats.useStatsWithCache(match.id);
  var model = ModelStore.useModel(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var match$1 = InspectorIssuesFilterHooks.useFilters(undefined);
  var groupedFilters = match$1.groupedFilters;
  var match$2 = React.useState(function () {
        return 0;
      });
  var setFilterElements = match$2[1];
  var setFilters = InspectorIssuesFilterHooks.useSetFilters(undefined);
  var match$3 = React$1.useFlow("inspector-issues-onboarding-flow");
  var flow = match$3[0];
  var match$4 = React$1.useBlock("inspector-issues-onboarding-flow.step-3");
  var filtersBlock = match$4[0];
  var match$5 = InspectorIssuesStore.useAppVersionsInSyncWithIssues(3, undefined);
  var appVersions = match$5[0];
  var match$6 = React.useState(function () {
        var el = $$Window.$$Document.getElementById("alerts-beta-disclaimer");
        if (el !== undefined) {
          return Caml_option.some(Caml_option.valFromOption(el));
        }
        
      });
  var setAlertsElement = match$6[1];
  var alertsElement = match$6[0];
  var setFiltersOrDoNothing = React.useCallback((function (param) {
          return function (param) {
            var inspectorSources = Belt_List.toArray(Belt_List.keep(model.sources, (function (source) {
                        var match = InspectorStats.getSourceStatus(model, source, inspectorStats);
                        return !(match === 2 || match === 1);
                      })));
            var countedAvailableFilters = InspectorIssuesFilterUtils.FilterCounter.get(inspectorSources, model, param);
            var mostCommonSource = Belt_Option.mapWithDefault(Belt_Map.get(countedAvailableFilters, "source"), [], (function (item) {
                    if (typeof item !== "object") {
                      return [];
                    }
                    if (item.NAME !== "sources") {
                      return [];
                    }
                    var countedSources = item.VAL;
                    if (countedSources.length !== 0) {
                      return Belt_Array.slice(Belt_Array.mapU(Belt_SortArray.stableSortByU(Belt_Array.keepU(countedSources, (function (param) {
                                                return param[1] > 0;
                                              })), (function (b, a) {
                                            return Caml.caml_int_compare(a[1], b[1]);
                                          })), (function (param) {
                                        return {
                                                NAME: "source",
                                                VAL: param[0]
                                              };
                                      })), 0, 1);
                    } else {
                      return [];
                    }
                  }));
            return Curry._1(setFilters, (function (currentFilters) {
                          if (Caml_obj.caml_equal(currentFilters, [])) {
                            return mostCommonSource;
                          }
                          var currentFilteredIssuesCount = InspectorIssuesFilterUtils.filterIssueViewModels(undefined, appVersions, param, currentFilters).length;
                          if (currentFilteredIssuesCount === 0) {
                            return mostCommonSource;
                          } else {
                            return currentFilters;
                          }
                        }));
          };
        }), [
        appVersions,
        inspectorStats,
        model,
        setFilters
      ]);
  React.useEffect(function () {
        var el = $$Window.$$Document.getElementById("alerts-beta-disclaimer");
        if (el !== undefined) {
          var el$1 = Caml_option.valFromOption(el);
          Curry._1(setAlertsElement, (function (param) {
                  return Caml_option.some(el$1);
                }));
        } else {
          Curry._1(setAlertsElement, (function (param) {
                  
                }));
        }
        var resizeHandler = function (param) {
          if (!(flow.state.started && !flow.state.finished && !flow.state.stopped)) {
            return ;
          }
          var el = $$Window.$$Document.getElementById("alerts-beta-disclaimer");
          if (el === undefined) {
            return Curry._1(setAlertsElement, (function (param) {
                          
                        }));
          }
          var el$1 = Caml_option.valFromOption(el);
          return Curry._1(setAlertsElement, (function (param) {
                        return Caml_option.some(el$1);
                      }));
        };
        if (flow.state.started && !flow.state.finished && !flow.state.stopped) {
          window.addEventListener("resize", resizeHandler);
        }
        return (function (param) {
                  window.removeEventListener("resize", resizeHandler);
                  
                });
      });
  React.useEffect((function () {
          var observer = new MutationObserver((function (mutations) {
                  return Belt_Array.forEach(mutations, (function (mutation) {
                                if (mutation.type !== "childList") {
                                  return ;
                                }
                                var el = $$Window.$$Document.getElementById("issues-filters-group");
                                if (el === undefined) {
                                  return ;
                                }
                                var el$1 = Caml_option.valFromOption(el);
                                return Curry._1(setFilterElements, (function (param) {
                                              return Belt_Array.reduce(el$1.children, 0, (function (acc, el) {
                                                            return acc + el.clientWidth | 0;
                                                          }));
                                            }));
                              }));
                }));
          var el = $$Window.$$Document.getElementById("issues-filters-group");
          if (el !== undefined && filtersBlock.state.active) {
            observer.observe(Caml_option.valFromOption(el), {
                  childList: true
                });
          }
          return (function (param) {
                    observer.disconnect();
                    
                  });
        }), [schemaRoute]);
  return React.createElement(ProductTour.make, {
              showStepCount: false,
              tourId: "inspector-issues-onboarding-flow",
              withConfetti: true,
              children: null
            }, React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, {
                  tourId: "inspector-issues-onboarding-flow",
                  stepId: "step-2",
                  offset: -80,
                  onStepActive: (function (param) {
                      if (Caml_obj.caml_notequal(schemaRoute, {
                              NAME: "inspector",
                              VAL: "issues"
                            })) {
                        return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                    NAME: "inspector",
                                    VAL: "issues"
                                  });
                      }
                      
                    }),
                  position: "left",
                  stepType: {
                    TAG: /* Window */1,
                    _0: {
                      left: Css.px(258),
                      top: Css.px(72),
                      width: {
                        NAME: "substract",
                        VAL: [
                          Css.vw(100.0),
                          Css.px(266)
                        ]
                      },
                      height: {
                        NAME: "substract",
                        VAL: [
                          Css.vh(100.0),
                          Css.px(80)
                        ]
                      }
                    }
                  },
                  transition: Mantine.Transition.subtleUpSlide,
                  transitionDuration: 400,
                  transitions: (function (issues) {
                      return [{
                                key: Caml_obj.caml_equal(issues, []) ? "next-no-issues" : "next",
                                label: undefined,
                                icon: "arrowRight"
                              }];
                    }),
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, {
                  tourId: "inspector-issues-onboarding-flow",
                  stepId: "step-3",
                  arrowOffset: 16,
                  onStepActive: (function (param) {
                      Curry._1(setFilters, (function (param) {
                              return [];
                            }));
                      Router.Schema.clearDrawerItems(undefined);
                      if (Caml_obj.caml_notequal(schemaRoute, {
                              NAME: "inspector",
                              VAL: "issues"
                            })) {
                        return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                    NAME: "inspector",
                                    VAL: "issues"
                                  });
                      }
                      
                    }),
                  onTransition: (function (issues, transitionName) {
                      if (transitionName === "next") {
                        return Curry._2(setFiltersOrDoNothing, undefined, issues);
                      }
                      
                    }),
                  position: "right-end",
                  positionDependencies: Belt_Array.concat([String(match$2[0])], Belt_Array.map(groupedFilters, InspectorIssuesFilter.GroupedFilter.toString)),
                  simulateClick: "center",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "add-filter-for-issues"
                  },
                  transition: Mantine.Transition.subtleUpSlide,
                  transitionDuration: 400,
                  transitions: (function (param) {
                      return [
                              {
                                key: "previous",
                                label: undefined,
                                icon: "arrowLeft"
                              },
                              {
                                key: "next",
                                label: undefined,
                                icon: "arrowRight"
                              }
                            ];
                    }),
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, {
                  tourId: "inspector-issues-onboarding-flow",
                  stepId: "step-3a",
                  arrowOffset: 40,
                  onStepActive: (function (param) {
                      Curry._1(setFilters, (function (param) {
                              return [];
                            }));
                      Router.Schema.clearDrawerItems(undefined);
                      if (Caml_obj.caml_notequal(schemaRoute, {
                              NAME: "inspector",
                              VAL: "issues"
                            })) {
                        return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                    NAME: "inspector",
                                    VAL: "issues"
                                  });
                      }
                      
                    }),
                  onTransition: (function (issues, transitionName) {
                      if (transitionName === "next") {
                        return Curry._2(setFiltersOrDoNothing, undefined, issues);
                      }
                      
                    }),
                  position: "left-end",
                  positionDependencies: Belt_Array.map(groupedFilters, InspectorIssuesFilter.GroupedFilter.toString),
                  simulateClick: "center",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "add-filter-for-issues"
                  },
                  transition: Mantine.Transition.subtleUpSlide,
                  transitionDuration: 400,
                  transitions: (function (param) {
                      return [
                              {
                                key: "previous",
                                label: undefined,
                                icon: "arrowLeft"
                              },
                              {
                                key: "next",
                                label: undefined,
                                icon: "arrowRight"
                              }
                            ];
                    }),
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, {
                  tourId: "inspector-issues-onboarding-flow",
                  stepId: "step-4",
                  onClick: (function (transition) {
                      return Curry._1(transition, "next");
                    }),
                  onStepActive: (function (issues) {
                      Curry._2(setFiltersOrDoNothing, undefined, issues);
                      return Router.Schema.clearDrawerItems(undefined);
                    }),
                  onTransition: (function (_issues, transitionName) {
                      if (transitionName === "previous") {
                        return Curry._1(setFilters, (function (param) {
                                      return [];
                                    }));
                      }
                      
                    }),
                  simulateClick: {
                    NAME: "left",
                    VAL: Css.px(68)
                  },
                  stepType: {
                    TAG: /* Window */1,
                    _0: {
                      left: Css.px(275),
                      top: Css.px(325 + (
                            alertsElement !== undefined ? Caml_option.valFromOption(alertsElement).getBoundingClientRect().height + 32 | 0 : 0
                          ) | 0),
                      width: {
                        NAME: "substract",
                        VAL: [
                          Css.vw(100.0),
                          Css.px(300)
                        ]
                      },
                      height: Css.px(56)
                    }
                  },
                  transition: Mantine.Transition.subtleUpSlide,
                  transitionDuration: 400,
                  transitions: (function (_issues) {
                      return [
                              {
                                key: "previous",
                                label: undefined,
                                icon: "arrowLeft"
                              },
                              {
                                key: "next",
                                label: undefined,
                                icon: "arrowRight"
                              }
                            ];
                    }),
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, {
                  tourId: "inspector-issues-onboarding-flow",
                  stepId: "step-5",
                  delay: 550,
                  onStepActive: (function (issues) {
                      Curry._2(setFiltersOrDoNothing, undefined, issues);
                      var match = Belt_Array.get(issues, 0);
                      if (match !== undefined) {
                        return Router.Schema.pushDrawerItem(undefined, {
                                    NAME: "inspectorIssue",
                                    VAL: match.id
                                  });
                      }
                      
                    }),
                  position: "left",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "drawer-container"
                  },
                  transition: Mantine.Transition.subtleRightSlide,
                  transitionDuration: 400,
                  transitions: (function (_issues) {
                      return [
                              {
                                key: "previous",
                                label: undefined,
                                icon: "arrowLeft"
                              },
                              {
                                key: "next",
                                label: undefined,
                                icon: "arrowRight"
                              }
                            ];
                    }),
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, {
                  tourId: "inspector-issues-onboarding-flow",
                  stepId: "step-6",
                  arrowOffset: 60,
                  elementClickable: false,
                  elementPadding: 4,
                  onStepActive: (function (issues) {
                      Router.Schema.clearDrawerItems(undefined);
                      if (Caml_obj.caml_notequal(schemaRoute, {
                              NAME: "inspector",
                              VAL: "issues"
                            })) {
                        Router.Schema.pushSchemaRoute(undefined, undefined, {
                              NAME: "inspector",
                              VAL: "issues"
                            });
                      }
                      return Curry._2(setFiltersOrDoNothing, undefined, issues);
                    }),
                  onTransition: (function (_issues, transitionName) {
                      if (transitionName === "previous-no-issues") {
                        return Curry._1(setFilters, (function (param) {
                                      return [];
                                    }));
                      }
                      
                    }),
                  position: "left-start",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "save-view-for-issues"
                  },
                  transition: Mantine.Transition.subtleUpSlide,
                  transitionDuration: 400,
                  transitions: (function (issues) {
                      return [
                              {
                                key: Caml_obj.caml_equal(issues, []) ? "previous-no-issues" : "previous",
                                label: undefined,
                                icon: "arrowLeft"
                              },
                              {
                                key: "next",
                                label: undefined,
                                icon: "arrowRight"
                              }
                            ];
                    }),
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(InspectorIssuesOnboardingFlow$ConditionalProductStep, {
                  tourId: "inspector-issues-onboarding-flow",
                  stepId: "step-6b-alerts",
                  arrowOffset: 60,
                  elementClickable: false,
                  elementPadding: 4,
                  onStepActive: (function (issues) {
                      Router.Schema.clearDrawerItems(undefined);
                      if (Caml_obj.caml_notequal(schemaRoute, {
                              NAME: "inspector",
                              VAL: "issues"
                            })) {
                        Router.Schema.pushSchemaRoute(undefined, undefined, {
                              NAME: "inspector",
                              VAL: "issues"
                            });
                      }
                      return Curry._2(setFiltersOrDoNothing, undefined, issues);
                    }),
                  position: "left-start",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "configure-alerts-button"
                  },
                  transition: Mantine.Transition.subtleUpSlide,
                  transitionDuration: 400,
                  transitions: (function (_issues) {
                      return [
                              {
                                key: "previous",
                                label: undefined,
                                icon: "arrowLeft"
                              },
                              {
                                key: "next",
                                label: undefined,
                                icon: "arrowRight"
                              }
                            ];
                    }),
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(ProductTour.Step.make, {
                  stepId: "step-7",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "intercom-sidebar-itemInspectorSavedViews"
                  },
                  onClick: (function (transition) {
                      return Curry._1(transition, "next");
                    }),
                  position: "right",
                  simulateClick: {
                    NAME: "left",
                    VAL: Css.px(78)
                  },
                  transition: Mantine.Transition.subtleLeftSlide,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  transitionDuration: 400,
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(ProductTour.Step.make, {
                  stepId: "step-8",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "saved-views-table"
                  },
                  elementClickable: false,
                  offset: -60,
                  onStepActive: (function (param) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                  NAME: "inspector",
                                  VAL: "savedViews"
                                });
                    }),
                  position: "left",
                  transition: Mantine.Transition.subtleUpSlide,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "complete",
                      label: "Finish!",
                      icon: undefined
                    }
                  ],
                  transitionDuration: 400,
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }));
}

var make = InspectorIssuesOnboardingFlow;

exports.make = make;
/* Css Not a pure module */
