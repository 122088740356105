// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var AvoModel = require("./avoModel.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");

function getSideEffectTableLabel(x) {
  if (typeof x === "object") {
    return "Updates user properties";
  } else if (x === "Revenue") {
    return "Logs revenue";
  } else if (x === "Identify") {
    return "Identifies user";
  } else if (x === "Alias") {
    return "Aliases user";
  } else if (x === "Page") {
    return "Logs page view";
  } else if (x === "Unidentify") {
    return "Unidentifies user";
  } else if (x === "LogEvent") {
    return "Logs event";
  } else {
    return "Updates group";
  }
}

function getSideEffectLabel(x) {
  if (typeof x === "object") {
    return "Update User Properties";
  } else if (x === "Revenue") {
    return "Log Revenue";
  } else if (x === "Identify") {
    return "Identify User";
  } else if (x === "Alias") {
    return "Alias";
  } else if (x === "Page") {
    return "Log Page View";
  } else if (x === "Unidentify") {
    return "Unidentify User";
  } else if (x === "LogEvent") {
    return "Log Event";
  } else {
    return "Update group";
  }
}

function getSideEffectDescription(sideEffect) {
  if (typeof sideEffect === "object") {
    return "Add one or more user properties that should be attached to the user’s profile in your analytics tool.";
  } else if (sideEffect === "Revenue") {
    return "Track revenue in your analytics tool to be able to use its revenue analysis.";
  } else if (sideEffect === "Alias" || sideEffect === "Identify") {
    return " Identify the user in your analytics tool such that they go from anonymous to a user with a user id.";
  } else if (sideEffect === "Page") {
    return "Track page view in your analytics tool to be able use their automatic page tracking capabilities.";
  } else if (sideEffect === "Unidentify") {
    return "Unidentify the user in your analytics tool such that they go from an identified user with a user id to an anonymous user";
  } else if (sideEffect === "LogEvent") {
    return "Send an event to your analytics tool by calling the corresponding tracking method in your analytics SDK or API.";
  } else {
    return "Associate users with groups and/or update group properties. All events sent after this event will tie the user to the identified group.";
  }
}

function eventTypeToSideEffect(x) {
  if (x === "Revenue") {
    return "Revenue";
  } else if (x === "SkipLogEvent") {
    return Pervasives.failwith("SkipLogEvent cannot be directly mapped to side effect");
  } else if (x === "Identify") {
    return "Identify";
  } else if (x === "Alias") {
    return "Alias";
  } else if (x === "Page") {
    return "Page";
  } else if (x === "Unidentify") {
    return "Unidentify";
  } else {
    return "UpdateGroups";
  }
}

function sideEffectToEventType(x) {
  if (typeof x === "object") {
    return Pervasives.failwith("User properties cannot be mapped to event type");
  } else if (x === "Revenue") {
    return "Revenue";
  } else if (x === "Identify") {
    return "Identify";
  } else if (x === "Alias") {
    return "Alias";
  } else if (x === "Page") {
    return "Page";
  } else if (x === "Unidentify") {
    return "Unidentify";
  } else if (x === "LogEvent") {
    return Pervasives.failwith("LogEvent cannot be mapped directly to event type");
  } else {
    return "UpdateGroups";
  }
}

function eventTypesToSideEffect(types) {
  return Belt_List.keepMap(types, (function (type_) {
                if (type_ === "Revenue") {
                  return "Revenue";
                } else if (type_ === "SkipLogEvent") {
                  return ;
                } else if (type_ === "Identify") {
                  return "Identify";
                } else if (type_ === "Alias") {
                  return "Alias";
                } else if (type_ === "Page") {
                  return "Page";
                } else if (type_ === "Unidentify") {
                  return "Unidentify";
                } else {
                  return "UpdateGroups";
                }
              }));
}

function getEventSideEffects(addingUserPropertiesOpt, $$event, model) {
  var addingUserProperties = addingUserPropertiesOpt !== undefined ? addingUserPropertiesOpt : false;
  var match = AvoModel.getProperties(model, $$event.properties);
  var userProperties = match.userProperties;
  var hasUserProperties = addingUserProperties || Belt_List.length(userProperties) > 0;
  var hasSkipEvent = Belt_List.some($$event.types, (function (type_) {
          return type_ === "SkipLogEvent";
        }));
  var additionalSideEffects = Belt_List.concatMany([
        hasSkipEvent ? /* [] */0 : ({
              hd: "LogEvent",
              tl: /* [] */0
            }),
        hasUserProperties ? ({
              hd: {
                NAME: "UserProperties",
                VAL: userProperties
              },
              tl: /* [] */0
            }) : /* [] */0
      ]);
  var eventTypes = Belt_List.reverse(eventTypesToSideEffect($$event.types));
  return Belt_List.concat(additionalSideEffects, eventTypes);
}

function mutuallyExclusiveTypes(t1, t2) {
  var check = function (x) {
    var match = x[0];
    if (match === "Alias" || match === "Identify") {
      return x[1] === "Unidentify";
    } else {
      return false;
    }
  };
  if (Caml_obj.caml_equal(t1, t2) || check([
          t1,
          t2
        ])) {
    return true;
  } else {
    return check([
                t2,
                t1
              ]);
  }
}

function getEventSideEffectOptions(addingUserPropertiesOpt, $$event, model) {
  var addingUserProperties = addingUserPropertiesOpt !== undefined ? addingUserPropertiesOpt : false;
  var hasSkipEvent = Belt_List.some($$event.types, (function (type_) {
          return type_ === "SkipLogEvent";
        }));
  var hasUpdateGroups = Belt_List.some($$event.types, (function (type_) {
          return type_ === "UpdateGroups";
        }));
  var match = AvoModel.getProperties(model, $$event.properties);
  var userProperties = match.userProperties;
  var hasUserProperties = addingUserProperties || Belt_List.length(userProperties) > 0;
  var additionalSideEffects = Belt_List.concatMany([
        hasSkipEvent ? ({
              hd: "LogEvent",
              tl: /* [] */0
            }) : /* [] */0,
        hasUserProperties ? /* [] */0 : ({
              hd: {
                NAME: "UserProperties",
                VAL: userProperties
              },
              tl: /* [] */0
            }),
        hasUpdateGroups ? /* [] */0 : ({
              hd: "UpdateGroups",
              tl: /* [] */0
            })
      ]);
  return Belt_List.concat(additionalSideEffects, eventTypesToSideEffect(Belt_List.keep(TrackingPlanModel.eventTypes, (function (type_) {
                        return !Belt_List.some($$event.types, (function (addedType) {
                                      return mutuallyExclusiveTypes(addedType, type_);
                                    }));
                      }))));
}

var sideEffectsOrder = [
  "Identify",
  "Alias",
  {
    NAME: "UserProperties",
    VAL: /* [] */0
  },
  "LogEvent",
  "Revenue",
  "Page",
  "Unidentify"
];

function sortSideEffects(effectGroups) {
  return Belt_List.sort(effectGroups, (function (a, b) {
                var a$1 = Belt_List.headExn(a);
                var b$1 = Belt_List.headExn(b);
                var getIndex = function (item) {
                  return Belt_Array.getIndexBy(sideEffectsOrder, (function (sideEffect) {
                                if (typeof sideEffect === "object") {
                                  if (sideEffect.NAME === "UserProperties" && !(sideEffect.VAL || typeof item !== "object")) {
                                    return item.NAME === "UserProperties";
                                  } else {
                                    return false;
                                  }
                                } else if (sideEffect === "Revenue") {
                                  return item === "Revenue";
                                } else if (sideEffect === "Identify") {
                                  return item === "Identify";
                                } else if (sideEffect === "Alias") {
                                  return item === "Alias";
                                } else if (sideEffect === "Page") {
                                  return item === "Page";
                                } else if (sideEffect === "Unidentify") {
                                  return item === "Unidentify";
                                } else if (sideEffect === "LogEvent") {
                                  return item === "LogEvent";
                                } else {
                                  return false;
                                }
                              }));
                };
                return Caml_obj.caml_compare(getIndex(a$1), getIndex(b$1));
              }));
}

exports.getSideEffectTableLabel = getSideEffectTableLabel;
exports.getSideEffectLabel = getSideEffectLabel;
exports.getSideEffectDescription = getSideEffectDescription;
exports.eventTypeToSideEffect = eventTypeToSideEffect;
exports.sideEffectToEventType = sideEffectToEventType;
exports.eventTypesToSideEffect = eventTypesToSideEffect;
exports.getEventSideEffects = getEventSideEffects;
exports.mutuallyExclusiveTypes = mutuallyExclusiveTypes;
exports.getEventSideEffectOptions = getEventSideEffectOptions;
exports.sideEffectsOrder = sideEffectsOrder;
exports.sortSideEffects = sortSideEffects;
/* AvoModel Not a pure module */
