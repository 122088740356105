// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var CopyButton = require("../CopyButton.bs.js");

function code(docsCodeBoxStyles, docsCodeStyles, docsCodeGrassGreenStyles, docsCodeMintGreenStyles, docsCodeOrangeStyles, copyButtonBoxStyles, language) {
  if (language !== undefined) {
    if (language === "objc") {
      return React.createElement("div", {
                  className: docsCodeBoxStyles
                }, React.createElement("pre", {
                      className: docsCodeStyles
                    }, "[avoInspector ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "trackSchemaFromEvent"), ":@", React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, "\"Event Name\""), React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, " eventParams"), ":@{@", React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, "\"id\""), ":@", React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, "\"…\""), ", @", React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, "\"number\""), ":@", React.createElement("span", {
                          className: docsCodeGrassGreenStyles
                        }, "41"), "}];"), React.createElement("div", {
                      className: copyButtonBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: "[avoInspector trackSchemaFromEvent:@\"Event Name\" eventParams:@{@\"id\": @\"...\", @\"number\": @41}];"
                        })));
    } else if (language === "swift") {
      return React.createElement("div", {
                  className: docsCodeBoxStyles
                }, React.createElement("pre", {
                      className: docsCodeStyles
                    }, "avoInspector.", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "trackSchema"), "(fromEvent: ", React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, "\"Event Name\""), ", eventParams: [", React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, "\"id\""), ": ", React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, "\"…\""), ", ", React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, "\"number\""), ": ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "41"), "])"), React.createElement("div", {
                      className: copyButtonBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: "avoInspector.trackSchema(fromEvent: \"Event Name\", eventParams: [\"id\": \"…\", \"number\": 41])"
                        })));
    } else {
      return null;
    }
  } else {
    return null;
  }
}

exports.code = code;
/* react Not a pure module */
