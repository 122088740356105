// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var Caml_splice_call = require("rescript/lib/js/caml_splice_call.js");

function reinsertIndex(items, index, newIndex) {
  var items$1 = items.slice(0);
  var itemToMove = items$1.splice(index, 1);
  Caml_splice_call.spliceObjApply(items$1, "splice", [
        newIndex,
        0,
        itemToMove
      ]);
  return items$1;
}

function splitIntoChunks(items, chunkSize) {
  if (Caml_obj.caml_equal(items, [])) {
    return [];
  }
  var count = Caml_int32.div(items.length - 1 | 0, chunkSize) + 1 | 0;
  return Belt_Array.map(Belt_Array.range(0, count - 1 | 0), (function (index) {
                return Belt_Array.slice(items, Math.imul(index, chunkSize), chunkSize);
              }));
}

function compareUnorderedStringArrays(a, b) {
  return Belt_SetString.eq(Belt_SetString.fromArray(a), Belt_SetString.fromArray(b));
}

function group(cmp, array) {
  var sorted = array.slice().sort(Curry.__2(cmp));
  var fold = function (first, rest) {
    return Belt_Array.reduceU(rest, [
                [],
                [first],
                first
              ], (function (param, elem) {
                  var agr = param[1];
                  var acc = param[0];
                  if (Curry._2(cmp, param[2], elem) === 0) {
                    return [
                            acc,
                            Belt_Array.concat([elem], agr),
                            elem
                          ];
                  } else {
                    return [
                            Belt_Array.concat([agr], acc),
                            [elem],
                            elem
                          ];
                  }
                }));
  };
  if (sorted.length === 0) {
    return [];
  }
  var tl = Belt_Array.sliceToEnd(sorted, 1);
  var hd = Belt_Array.getExn(sorted, 0);
  var match = fold(hd, tl);
  return Belt_Array.mapU(Belt_Array.reverse(Belt_Array.concat([match[1]], match[0])), Belt_Array.reverse);
}

function sliceToEndSafe(array, offset) {
  try {
    return Belt_Array.sliceToEnd(array, offset);
  }
  catch (exn){
    return [];
  }
}

function Dedupe(Config) {
  var ItemComparator = Belt_Id.MakeComparable({
        cmp: Config.compare
      });
  var dedupe = function (items) {
    return Belt_Set.toArray(Belt_Set.fromArray(items, ItemComparator));
  };
  var sort = function (items) {
    return items.slice().sort(function (param, param$1) {
                return Curry._2(Config.compare, param, param$1);
              });
  };
  return {
          ItemComparator: ItemComparator,
          dedupe: dedupe,
          sort: sort
        };
}

exports.reinsertIndex = reinsertIndex;
exports.splitIntoChunks = splitIntoChunks;
exports.compareUnorderedStringArrays = compareUnorderedStringArrays;
exports.group = group;
exports.sliceToEndSafe = sliceToEndSafe;
exports.Dedupe = Dedupe;
/* No side effect */
