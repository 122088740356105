// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");

function Make(Item) {
  var scrollResultIntoView = function (state) {
    var maybeElement = Belt_List.head(Belt_List.keepMap(state.resultRefs.contents, (function (param) {
                if (param[0] === state.focusIndex) {
                  return Caml_option.some(param[1]);
                }
                
              })));
    if (maybeElement !== undefined) {
      Caml_option.valFromOption(maybeElement).scrollIntoView({
            block: "nearest",
            inline: "nearest"
          });
      return ;
    }
    
  };
  var getAllOptions = function (options) {
    return Belt_List.keepU(BeltListExtensions.flatMapU(options, (function (param) {
                      return param[1];
                    })), (function (option) {
                  if (typeof option !== "object") {
                    return false;
                  }
                  var variant = option.NAME;
                  if (variant === "Item" || variant === "CreateNewWithGrouping" || variant === "CreateNew") {
                    return true;
                  } else {
                    return variant === "Message";
                  }
                }));
  };
  var getActionableOptions = function (options) {
    return Belt_List.keepU(BeltListExtensions.flatMapU(options, (function (param) {
                      return param[1];
                    })), (function (option) {
                  if (typeof option !== "object") {
                    return false;
                  }
                  var variant = option.NAME;
                  if (variant === "Item" || variant === "CreateNewWithGrouping") {
                    return true;
                  } else {
                    return variant === "CreateNew";
                  }
                }));
  };
  var countActionableOptions = function (options) {
    return Belt_List.length(getActionableOptions(options));
  };
  var popup = Curry._1(Css.style, {
        hd: Css.top(Css.px(30)),
        tl: {
          hd: Css.zIndex(Styles.ZIndex.aboveAll),
          tl: {
            hd: Css.position("absolute"),
            tl: {
              hd: Css.marginBottom(Css.px(15)),
              tl: {
                hd: Css.zIndex(Styles.ZIndex.aboveAll),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: {
                    hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.borderBottomRightRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.overflow("hidden"),
                        tl: {
                          hd: Css.width(Css.pct(100)),
                          tl: {
                            hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.minWidth(Css.px(400)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
  var searchInput = Curry._1(Css.merge, {
        hd: Curry._1(Css.style, Styles.input),
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.display("block"),
                tl: {
                  hd: Css.width(Css.pct(100)),
                  tl: {
                    hd: Css.padding4(Css.px(15), Css.px(0), Css.px(15), Css.px(15)),
                    tl: {
                      hd: Css.fontWeight(Styles.FontWeight.semi),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.regular),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      });
  var results = Curry._1(Css.style, {
        hd: Css.display("flex"),
        tl: {
          hd: Css.height(Css.px(250)),
          tl: {
            hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey30),
            tl: /* [] */0
          }
        }
      });
  var resultsList = Curry._1(Css.style, {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.height(Css.pct(100)),
          tl: {
            hd: Css.flexShrink(0.0),
            tl: {
              hd: Css.flex({
                    NAME: "num",
                    VAL: 1.0
                  }),
              tl: /* [] */0
            }
          }
        }
      });
  var resultsDetail = Curry._1(Css.style, {
        hd: Css.width(Css.pct(50)),
        tl: {
          hd: Css.flexShrink(0.0),
          tl: {
            hd: Css.flexGrow(0.0),
            tl: {
              hd: Css.flex({
                    NAME: "num",
                    VAL: 1.0
                  }),
              tl: {
                hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                tl: /* [] */0
              }
            }
          }
        }
      });
  var resultLabel = Curry._1(Css.style, {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.fontSize(Styles.FontSize.small),
          tl: {
            hd: Css.color(Styles.Color.grey70),
            tl: {
              hd: Css.backgroundColor(Styles.Color.grey20),
              tl: {
                hd: Css.padding2(Css.px(5), Css.px(10)),
                tl: /* [] */0
              }
            }
          }
        }
      });
  var result = function (clickable) {
    return Curry._1(Css.merge, {
                hd: Curry._1(Css.style, Styles.button),
                tl: {
                  hd: Curry._1(Css.style, {
                        hd: Css.display("block"),
                        tl: {
                          hd: Css.width(Css.pct(100)),
                          tl: {
                            hd: Css.textAlign("left"),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.regular),
                              tl: {
                                hd: Css.margin(Css.px(0)),
                                tl: {
                                  hd: Css.padding(Css.px(0)),
                                  tl: {
                                    hd: Css.cursor(clickable ? "pointer" : "default"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              });
  };
  var Style = {
    popup: popup,
    searchInput: searchInput,
    results: results,
    resultsList: resultsList,
    resultsDetail: resultsDetail,
    resultLabel: resultLabel,
    result: result
  };
  var AutofillInputNew$Make$Popup = function (Props) {
    var onSelect = Props.onSelect;
    var onCreate = Props.onCreate;
    var placeholder = Props.placeholder;
    var search = Props.search;
    var renderItem = Props.renderItem;
    var renderDetails = Props.renderDetails;
    var refreshKey = Props.refreshKey;
    var getIndexOfValue = Props.getIndexOfValue;
    var resultsContainerStylesOpt = Props.resultsContainerStyles;
    var onClose = Props.onClose;
    var resultsContainerStyles = resultsContainerStylesOpt !== undefined ? resultsContainerStylesOpt : /* [] */0;
    var results$1 = Curry._1(search, "");
    var initialIndex = Belt_Option.mapWithDefaultU(getIndexOfValue, 0, (function (getIndexOfValue) {
            return Curry._1(getIndexOfValue, getActionableOptions(results$1));
          }));
    var match = React.useReducer((function (state, action) {
            if (typeof action === "number") {
              if (action === /* IncrementFocus */0) {
                var nextFocusIndex = state.focusIndex < (Belt_List.length(getActionableOptions(state.results)) - 1 | 0) ? state.focusIndex + 1 | 0 : state.focusIndex;
                var newState_query = state.query;
                var newState_results = state.results;
                var newState_resultRefs = state.resultRefs;
                var newState = {
                  query: newState_query,
                  focusIndex: nextFocusIndex,
                  previewFocusIndex: nextFocusIndex,
                  results: newState_results,
                  resultRefs: newState_resultRefs
                };
                scrollResultIntoView(newState);
                return newState;
              }
              var nextFocusIndex$1 = state.focusIndex > 0 ? state.focusIndex - 1 | 0 : state.focusIndex;
              var newState_query$1 = state.query;
              var newState_results$1 = state.results;
              var newState_resultRefs$1 = state.resultRefs;
              var newState$1 = {
                query: newState_query$1,
                focusIndex: nextFocusIndex$1,
                previewFocusIndex: nextFocusIndex$1,
                results: newState_results$1,
                resultRefs: newState_resultRefs$1
              };
              scrollResultIntoView(newState$1);
              return newState$1;
            } else {
              switch (action.TAG | 0) {
                case /* UpdateQuery */0 :
                    var query = action._0;
                    var results = Curry._1(search, query);
                    var nextFocusIndex$2 = state.focusIndex >= Belt_List.length(getActionableOptions(results)) ? 0 : state.focusIndex;
                    return {
                            query: query,
                            focusIndex: nextFocusIndex$2,
                            previewFocusIndex: nextFocusIndex$2,
                            results: results,
                            resultRefs: {
                              contents: /* [] */0
                            }
                          };
                case /* SetPreviewFocus */1 :
                    return {
                            query: state.query,
                            focusIndex: state.focusIndex,
                            previewFocusIndex: action._0,
                            results: state.results,
                            resultRefs: state.resultRefs
                          };
                case /* SetState */2 :
                    return action._0;
                
              }
            }
          }), {
          query: "",
          focusIndex: initialIndex,
          previewFocusIndex: initialIndex,
          results: results$1,
          resultRefs: {
            contents: /* [] */0
          }
        });
    var send = match[1];
    var state = match[0];
    React.useEffect((function () {
            window.addEventListener("click", onClose);
            return (function (param) {
                      window.removeEventListener("click", onClose);
                      
                    });
          }), []);
    Hooks.useDidUpdate1((function (param) {
            var results = Curry._1(search, state.query);
            Curry._1(send, {
                  TAG: /* SetState */2,
                  _0: {
                    query: state.query,
                    focusIndex: state.focusIndex >= Belt_List.length(getActionableOptions(results)) ? 0 : state.focusIndex,
                    previewFocusIndex: state.previewFocusIndex,
                    results: results,
                    resultRefs: state.resultRefs
                  }
                });
            
          }), [refreshKey]);
    var handleKeyDown = function ($$event) {
      var match = $$event.key;
      var match$1 = $$event.shiftKey;
      switch (match) {
        case "ArrowDown" :
            $$event.preventDefault();
            return Curry._1(send, /* IncrementFocus */0);
        case "ArrowUp" :
            $$event.preventDefault();
            return Curry._1(send, /* DecrementFocus */1);
        case "Enter" :
            var match$2 = Belt_List.get(getActionableOptions(state.results), state.focusIndex);
            if (match$2 === undefined) {
              return ;
            }
            if (typeof match$2 !== "object") {
              return ;
            }
            var variant = match$2.NAME;
            if (variant === "CreateNew") {
              Curry._2(onCreate, match$2.VAL, undefined);
              return Curry._1(onClose, undefined);
            }
            if (variant !== "CreateNewWithGrouping") {
              if (variant === "Item") {
                Curry._3(onSelect, match$2.VAL, state.query, state.focusIndex);
                return Curry._1(onClose, undefined);
              } else {
                return ;
              }
            }
            var match$3 = match$2.VAL;
            Curry._2(onCreate, match$3[0], match$3[1]);
            return Curry._1(onClose, undefined);
        case "Escape" :
            $$event.stopPropagation();
            return Curry._1(onClose, undefined);
        case "Meta" :
        case "Shift" :
            return ;
        case "Tab" :
            if (match$1) {
              $$event.preventDefault();
              return Curry._1(send, /* DecrementFocus */1);
            } else {
              $$event.preventDefault();
              return Curry._1(send, /* IncrementFocus */0);
            }
        default:
          return ;
      }
    };
    var tmp;
    if (getAllOptions(state.results) === /* [] */0) {
      tmp = null;
    } else {
      var match$1 = Belt_List.get(getActionableOptions(state.results), state.previewFocusIndex);
      var match$2 = Belt_List.head(getAllOptions(state.results));
      tmp = React.createElement("div", {
            className: results
          }, React.createElement("div", {
                className: resultsList
              }, Belt_List.toArray(Belt_List.reduceU(state.results, [
                          /* [] */0,
                          0
                        ], (function (param, param$1) {
                            var options = param$1[1];
                            var maybeLabel = param$1[0];
                            var index = param[1];
                            return [
                                    Belt_List.concatMany([
                                          param[0],
                                          maybeLabel !== undefined && options ? ({
                                                hd: React.createElement("div", {
                                                      key: "label-" + maybeLabel,
                                                      className: resultLabel
                                                    }, maybeLabel),
                                                tl: /* [] */0
                                              }) : /* [] */0,
                                          Belt_List.mapWithIndexU(options, (function (subIndex, item) {
                                                  var index$1 = index + subIndex | 0;
                                                  var variant = item.NAME;
                                                  var tmp;
                                                  if (variant === "CreateNew") {
                                                    tmp = "createNew" + item.VAL;
                                                  } else if (variant === "CreateNewWithGrouping") {
                                                    var match = item.VAL;
                                                    tmp = "createNewWithGrouping" + match[0] + match[1];
                                                  } else {
                                                    tmp = variant === "Item" ? Curry._1(Item.getId, item.VAL) : "message" + Curry._1(Item.messageToString, item.VAL);
                                                  }
                                                  return React.createElement("button", {
                                                              key: tmp,
                                                              ref: (function (resultRef) {
                                                                  var refsWithoutResult = Belt_List.keep(state.resultRefs.contents, (function (x) {
                                                                          return x[0] !== index$1;
                                                                        }));
                                                                  state.resultRefs.contents = (resultRef == null) ? refsWithoutResult : Belt_List.concat(refsWithoutResult, {
                                                                          hd: [
                                                                            index$1,
                                                                            resultRef
                                                                          ],
                                                                          tl: /* [] */0
                                                                        });
                                                                  
                                                                }),
                                                              className: result(typeof item === "object" ? item.NAME !== "Message" : true),
                                                              onClick: (function (param) {
                                                                  var variant = item.NAME;
                                                                  if (variant === "CreateNew") {
                                                                    Curry._2(onCreate, item.VAL, undefined);
                                                                    return Curry._1(onClose, undefined);
                                                                  }
                                                                  if (variant !== "CreateNewWithGrouping") {
                                                                    if (variant === "Item") {
                                                                      Curry._3(onSelect, item.VAL, state.query, index$1);
                                                                      return Curry._1(onClose, undefined);
                                                                    } else {
                                                                      return ;
                                                                    }
                                                                  }
                                                                  var match = item.VAL;
                                                                  Curry._2(onCreate, match[0], match[1]);
                                                                  return Curry._1(onClose, undefined);
                                                                }),
                                                              onMouseEnter: (function (param) {
                                                                  return Curry._1(send, {
                                                                              TAG: /* SetPreviewFocus */1,
                                                                              _0: index$1
                                                                            });
                                                                })
                                                            }, Curry._2(renderItem, item, state.focusIndex === index$1));
                                                }))
                                        ]),
                                    index + Belt_List.length(options) | 0
                                  ];
                          }))[0])), React.createElement("div", {
                className: resultsDetail
              }, match$1 !== undefined ? Curry._1(renderDetails, match$1) : (
                  match$2 !== undefined && typeof match$2 === "object" && match$2.NAME === "Message" ? Curry._1(renderDetails, match$2) : null
                )));
    }
    return React.createElement("div", {
                className: Curry._1(Css.merge, {
                      hd: popup,
                      tl: {
                        hd: Curry._1(Css.style, resultsContainerStyles),
                        tl: /* [] */0
                      }
                    })
              }, React.createElement("input", {
                    className: searchInput,
                    autoFocus: true,
                    placeholder: placeholder,
                    value: state.query,
                    onKeyDown: handleKeyDown,
                    onChange: (function ($$event) {
                        return Curry._1(send, {
                                    TAG: /* UpdateQuery */0,
                                    _0: $$event.target.value
                                  });
                      })
                  }), tmp);
  };
  var Popup = {
    scrollResultIntoView: scrollResultIntoView,
    getAllOptions: getAllOptions,
    getActionableOptions: getActionableOptions,
    countActionableOptions: countActionableOptions,
    Style: Style,
    make: AutofillInputNew$Make$Popup
  };
  var AutofillInputNew$Make = function (Props) {
    var onSelect = Props.onSelect;
    var onCreate = Props.onCreate;
    var placeholder = Props.placeholder;
    var search = Props.search;
    var renderItem = Props.renderItem;
    var renderDetails = Props.renderDetails;
    var refreshKey = Props.refreshKey;
    var autoFocusOpt = Props.autoFocus;
    var getIndexOfValue = Props.getIndexOfValue;
    var renderButton = Props.renderButton;
    var resultsContainerStylesOpt = Props.resultsContainerStyles;
    var keepExpandedOpt = Props.keepExpanded;
    var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
    var resultsContainerStyles = resultsContainerStylesOpt !== undefined ? resultsContainerStylesOpt : /* [] */0;
    var keepExpanded = keepExpandedOpt !== undefined ? keepExpandedOpt : false;
    var match = React.useState(function () {
          return autoFocus;
        });
    var setIsOpen = match[1];
    var buttonRef = React.useRef(null);
    var handleOpen = React.useCallback((function (param) {
            return Curry._1(setIsOpen, (function (param) {
                          return true;
                        }));
          }), []);
    var handleClose = React.useCallback((function (param) {
            Curry._1(setIsOpen, (function (param) {
                    return false;
                  }));
            var domButton = buttonRef.current;
            if (!(domButton == null)) {
              domButton.focus();
              return ;
            }
            
          }), [buttonRef]);
    var tmp;
    if (match[0] || keepExpanded) {
      var tmp$1 = {
        onSelect: onSelect,
        onCreate: onCreate,
        placeholder: placeholder,
        search: search,
        renderItem: renderItem,
        renderDetails: renderDetails,
        refreshKey: refreshKey,
        resultsContainerStyles: resultsContainerStyles,
        onClose: handleClose
      };
      if (getIndexOfValue !== undefined) {
        tmp$1.getIndexOfValue = Caml_option.valFromOption(getIndexOfValue);
      }
      tmp = React.createElement(AutofillInputNew$Make$Popup, tmp$1);
    } else {
      tmp = null;
    }
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }),
                onClick: (function ($$event) {
                    $$event.stopPropagation();
                    
                  })
              }, renderButton !== undefined ? Curry._2(renderButton, handleOpen, buttonRef) : React.createElement(Button.make, {
                      label: placeholder,
                      onClick: handleOpen,
                      ref: buttonRef
                    }), tmp);
  };
  return {
          Popup: Popup,
          make: AutofillInputNew$Make
        };
}

exports.Make = Make;
/* Css Not a pure module */
