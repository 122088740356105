// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconErase(Props) {
  var colorOpt = Props.color;
  var hoverColorOpt = Props.hoverColor;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey40;
  var hoverColor = hoverColorOpt !== undefined ? hoverColorOpt : color;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css_Legacy_Core.SVG.fill(color),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$default
                            }, undefined, undefined, "fill"),
                        tl: {
                          hd: Css.hover({
                                hd: Css_Legacy_Core.SVG.fill(hoverColor),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement("svg", {
                  height: "16",
                  width: "23",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("path", {
                      clipRule: "evenodd",
                      d: "M6.90714.632118C7.28523.228817 7.81339 0 8.36621 0H20.9997c1.1046 0 2 .895431 2 2v12c0 1.1046-.8954 2-2 2H8.36621c-.55282 0-1.08098-.2288-1.45907-.6321l-5.625-6.00002c-.721237-.76931-.721238-1.96645 0-2.73576l5.625-6.000002zM10.4643 4.46443c.3905-.39052 1.0237-.39052 1.4142 0l2.1212 2.12126 2.1213-2.12128c.3906-.39053 1.0237-.39053 1.4142 0 .3906.39052.3906 1.02369 0 1.41421L15.414 7.99991l2.1213 2.12139c.3906.3905.3906 1.0237 0 1.4142-.3905.3905-1.0237.3905-1.4142 0l-2.1214-2.12138-2.1213 2.12138c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3905-.3905-1.0237 0-1.4142l2.1213-2.12139-2.1212-2.12127c-.3906-.39052-.3906-1.02368 0-1.41421z",
                      fillRule: "evenodd"
                    })));
}

var make = IconErase;

exports.make = make;
/* Css Not a pure module */
