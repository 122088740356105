// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Title = require("../Title.bs.js");
var React = require("react");
var Models = require("../Models.bs.js");
var Router = require("../Router.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var CreateButtonIcon = require("./CreateButtonIcon.bs.js");
var EmptyStatesStyles = require("./EmptyStatesStyles.bs.js");
var GlobalSendContext = require("../GlobalSendContext.bs.js");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");

function PropertiesEmpty(Props) {
  var role = Props.role;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  return React.createElement("main", {
              className: EmptyStatesStyles.rootStyles
            }, React.createElement(Title.make, {
                  children: "Enrich your events with Properties",
                  size: "Medium"
                }), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement($$Text.make, {
                  size: "Large",
                  color: Styles.Color.grey70,
                  maxWidth: Css.px(520),
                  children: null
                }, "In Avo you can create Events Properties, User Properties, and System Properties and they can be organized in Groups. Define them once, apply them everywhere. Read more about Properties in the ", React.createElement("a", {
                      className: EmptyStatesStyles.linkStyles,
                      href: "https://www.avo.app/docs/workspace/tracking-plan/properties",
                      rel: "noopener",
                      target: "_blank"
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "Avo docs"
                        })), "."), Models.Role.canEdit(role) ? React.createElement("div", {
                    className: EmptyStatesStyles.actionsStyles
                  }, React.createElement("button", {
                        className: EmptyStatesStyles.actionStyles,
                        onClick: (function (param) {
                            AnalyticsRe.emptyStateInteracted(schemaGroup, "Properties", "Setup", undefined);
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "NewProperty",
                                          VAL: [
                                            "",
                                            /* EventProperty */1,
                                            undefined,
                                            (function (propertyId, _propertyName) {
                                                return Router.Schema.pushDrawerItem(undefined, {
                                                            NAME: "property",
                                                            VAL: [
                                                              propertyId,
                                                              undefined
                                                            ]
                                                          });
                                              }),
                                            "PropertiesView"
                                          ]
                                        }
                                      });
                          })
                      }, React.createElement("div", {
                            className: EmptyStatesStyles.iconStyles
                          }, CreateButtonIcon.icon), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.grey80,
                            children: "Create an Event Property"
                          })), React.createElement("button", {
                        className: EmptyStatesStyles.actionStyles,
                        onClick: (function (param) {
                            AnalyticsRe.emptyStateInteracted(schemaGroup, "Properties", "Setup", undefined);
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "NewProperty",
                                          VAL: [
                                            "",
                                            /* UserProperty */2,
                                            undefined,
                                            (function (propertyId, _propertyName) {
                                                return Router.Schema.pushDrawerItem(undefined, {
                                                            NAME: "property",
                                                            VAL: [
                                                              propertyId,
                                                              undefined
                                                            ]
                                                          });
                                              }),
                                            "PropertiesView"
                                          ]
                                        }
                                      });
                          })
                      }, React.createElement("div", {
                            className: EmptyStatesStyles.iconStyles
                          }, CreateButtonIcon.icon), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.grey80,
                            children: "Create a User Property"
                          })), React.createElement("button", {
                        className: EmptyStatesStyles.actionStyles,
                        onClick: (function (param) {
                            AnalyticsRe.emptyStateInteracted(schemaGroup, "Properties", "Setup", undefined);
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "NewProperty",
                                          VAL: [
                                            "",
                                            /* SystemProperty */0,
                                            undefined,
                                            (function (propertyId, _propertyName) {
                                                return Router.Schema.pushDrawerItem(undefined, {
                                                            NAME: "property",
                                                            VAL: [
                                                              propertyId,
                                                              undefined
                                                            ]
                                                          });
                                              }),
                                            "PropertiesView"
                                          ]
                                        }
                                      });
                          })
                      }, React.createElement("div", {
                            className: EmptyStatesStyles.iconStyles
                          }, CreateButtonIcon.icon), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.grey80,
                            children: "Create a System Property"
                          }))) : null);
}

var make = PropertiesEmpty;

exports.make = make;
/* Css Not a pure module */
