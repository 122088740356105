// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function encode(t) {
  return Json_encode.object_({
              hd: [
                "shareId",
                t.shareId
              ],
              tl: {
                hd: [
                  "distinctId",
                  t.distinctId
                ],
                tl: {
                  hd: [
                    "eventName",
                    t.eventName
                  ],
                  tl: {
                    hd: [
                      "eventProperties",
                      t.eventProperties
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function decode(json) {
  return {
          shareId: Json_decode.field("shareId", Json_decode.string, json),
          distinctId: Json_decode.field("distinctId", Json_decode.string, json),
          eventName: Json_decode.field("eventName", Json_decode.string, json),
          eventProperties: Json_decode.field("eventProperties", (function (json) {
                  return json;
                }), json)
        };
}

var $$Request = {
  encode: encode,
  decode: decode
};

exports.$$Request = $$Request;
/* No side effect */
