// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var _map = {"Branch Implementation Public View Opened":"Branch Implementation Public View Opened","Branch Implementation Interaction":"Branch Implementation Interaction","Branch Implement View Configured":"Branch Implement View Configured","Event Implementation Snippet Interaction":"Event Implementation Snippet Interaction","Tooltip Displayed":"Tooltip Displayed","Tooltip Interacted":"Tooltip Interacted"};

function eventToJs(param) {
  return param;
}

function eventFromJs(param) {
  return _map[param];
}

exports.eventToJs = eventToJs;
exports.eventFromJs = eventFromJs;
/* No side effect */
