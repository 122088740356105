// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var TrialProgressSteps = require("./TrialProgressSteps.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

function useTrialProgress(schema) {
  var schemaId = schema.id;
  var createEventTriggerAction = FirebaseFetcherHooks.useActionWithLimit(schemaId, 1, "CreateEventTrigger");
  var createIntegrationActions = FirebaseFetcherHooks.useActionWithLimit(schemaId, 50, "CreateIntegration");
  var removeIntegrationActions = FirebaseFetcherHooks.useActionWithLimit(schemaId, 50, "RemoveIntegration");
  var setBranchStatusActions = FirebaseFetcherHooks.useActionWithLimit(schemaId, 1, "SetBranchStatus");
  var hasCommentCollaboration = Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_option.nullable_to_opt(schema.onboardingV2), (function (onboarding) {
              return Caml_option.nullable_to_opt(onboarding.hasCommentCollaboration);
            })), false);
  var hasObservedSevenDaysInspector = Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_option.nullable_to_opt(schema.onboardingV2), (function (onboarding) {
              return Caml_option.nullable_to_opt(onboarding.hasObservedSevenDaysInspector);
            })), false);
  var hasConfiguredDiscrepancies = Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_option.nullable_to_opt(schema.onboardingV2), (function (onboarding) {
              return Caml_option.nullable_to_opt(onboarding.hasConfiguredDiscrepancies);
            })), false);
  var actionFetchers = [
    createEventTriggerAction,
    createIntegrationActions,
    removeIntegrationActions,
    setBranchStatusActions
  ];
  if (Belt_Array.someU(actionFetchers, (function (status) {
            return status === "Initial";
          }))) {
    return "Initial";
  }
  if (Belt_Array.someU(actionFetchers, (function (status) {
            return status === "Error";
          }))) {
    return "Error";
  }
  if (actionFetchers.length !== 4) {
    return "Error";
  }
  var match = actionFetchers[0];
  if (typeof match !== "object") {
    return "Error";
  }
  if (match.NAME !== "Loaded") {
    return "Error";
  }
  var match$1 = actionFetchers[1];
  if (typeof match$1 !== "object") {
    return "Error";
  }
  if (match$1.NAME !== "Loaded") {
    return "Error";
  }
  var match$2 = actionFetchers[2];
  if (typeof match$2 !== "object") {
    return "Error";
  }
  if (match$2.NAME !== "Loaded") {
    return "Error";
  }
  var match$3 = actionFetchers[3];
  if (typeof match$3 !== "object") {
    return "Error";
  }
  if (match$3.NAME !== "Loaded") {
    return "Error";
  }
  var steps = TrialProgressSteps.steps(match.VAL, match$1.VAL, match$2.VAL, match$3.VAL, hasCommentCollaboration, hasObservedSevenDaysInspector, hasConfiguredDiscrepancies);
  var currentStep = Belt_Array.getBy(steps, (function (step) {
          return !step.completed;
        }));
  return {
          NAME: "Loaded",
          VAL: [
            steps,
            currentStep
          ]
        };
}

exports.useTrialProgress = useTrialProgress;
/* TrialProgressSteps Not a pure module */
