// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_int = require("rescript/lib/js/js_int.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var StateUtils = require("../../app/src/stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var CodegenRules = require("./CodegenRules.bs.js");
var CodegenCommons = require("./CodegenCommons.bs.js");
var BeltListExtensions = require("../../app/src/BeltListExtensions.bs.js");
var PropertyValidationUtils = require("../../app/src/PropertyValidationUtils.bs.js");

function getShape(validations) {
  return Belt_Option.getWithDefault(Belt_List.head(Belt_List.keepMap(validations, (function (validation) {
                        if (typeof validation === "object" && validation.NAME === "Shape") {
                          return validation.VAL;
                        }
                        
                      }))), /* [] */0);
}

function printRawType(integrationType, hasSnowplowIntegerTypes, property) {
  var type_ = property.type_;
  switch (type_) {
    case "any" :
        return Pervasives.failwith("Any is not supported as a type. Skip the type key instead.");
    case "bool" :
        return "boolean";
    case "int" :
        if (integrationType === /* Snowplow */0 && hasSnowplowIntegerTypes) {
          return "integer";
        } else {
          return "number";
        }
    case "float" :
    case "long" :
        return "number";
    case "object" :
        return "object";
    case "string" :
        return "string";
    default:
      return Pervasives.failwith("unsupported type " + type_);
  }
}

function printObjectValueType(integrationType, hasSnowplowIntegerTypes, value) {
  var type_ = value.VAL.type_;
  if (type_ === "Object") {
    return Pervasives.failwith("object type not supported deeply nested");
  } else if (type_ === "Any") {
    return Pervasives.failwith("any type not supported deeply nested");
  } else if (type_ === "Int") {
    if (integrationType === /* Snowplow */0 && hasSnowplowIntegerTypes) {
      return "integer";
    } else {
      return "number";
    }
  } else if (type_ === "Long" || type_ === "Float") {
    return "number";
  } else if (type_ === "Bool") {
    return "boolean";
  } else {
    return "string";
  }
}

function printObjectType(integrationType, hasSnowplowIntegerTypes, item) {
  var underlying = printObjectValueType(integrationType, hasSnowplowIntegerTypes, item.value);
  var match = item.value;
  var restrictions;
  if (integrationType === /* Snowplow */0 && hasSnowplowIntegerTypes) {
    restrictions = /* [] */0;
  } else {
    var match$1 = match.VAL.type_;
    restrictions = match$1 === "Long" || match$1 === "Int" ? ({
          hd: [
            "multipleOf",
            1
          ],
          tl: /* [] */0
        }) : /* [] */0;
  }
  return Json_encode.object_(Belt_List.concatMany([
                  {
                    hd: [
                      "type",
                      item.list ? "array" : underlying
                    ],
                    tl: /* [] */0
                  },
                  item.list ? ({
                        hd: [
                          "items",
                          Json_encode.object_(Belt_List.concatMany([
                                    {
                                      hd: [
                                        "type",
                                        underlying
                                      ],
                                      tl: /* [] */0
                                    },
                                    restrictions
                                  ]))
                        ],
                        tl: /* [] */0
                      }) : restrictions
                ]));
}

function printAdditionalPropertyValidationsForSnowplow(hasSnowplowIntegerTypes, property) {
  if (printRawType(/* Snowplow */0, hasSnowplowIntegerTypes, property) !== "number") {
    return /* [] */0;
  }
  var hasMaxValidation = Belt_List.someU(property.validations, (function (validation) {
          if (typeof validation !== "object") {
            return false;
          }
          if (validation.NAME !== "Max") {
            return false;
          }
          var match = validation.VAL;
          if (typeof match !== "object") {
            return false;
          }
          var variant = match.NAME;
          if (variant === "FloatLit") {
            return true;
          } else {
            return variant === "IntLit";
          }
        }));
  var hasMinValidation = Belt_List.someU(property.validations, (function (validation) {
          if (typeof validation !== "object") {
            return false;
          }
          if (validation.NAME !== "Min") {
            return false;
          }
          var match = validation.VAL;
          if (typeof match !== "object") {
            return false;
          }
          var variant = match.NAME;
          if (variant === "FloatLit") {
            return true;
          } else {
            return variant === "IntLit";
          }
        }));
  return Belt_List.concatMany([
              hasMinValidation ? /* [] */0 : ({
                    hd: [
                      "minimum",
                      Js_int.min
                    ],
                    tl: /* [] */0
                  }),
              hasMaxValidation ? /* [] */0 : ({
                    hd: [
                      "maximum",
                      Js_int.max
                    ],
                    tl: /* [] */0
                  })
            ]);
}

function printLiteral(literal) {
  return literal.VAL;
}

function matchesAtLeastOneDestination(model, id) {
  return Belt_List.some(model.destinations, (function (destination) {
                return destination.id === id;
              }));
}

function printPropertyUnderlyingType(integrationType, hasSnowplowIntegerTypes, property, optional) {
  var propertyType = printRawType(integrationType, hasSnowplowIntegerTypes, property);
  if (optional) {
    return Json_encode.list((function (prim) {
                  return prim;
                }), {
                hd: propertyType,
                tl: {
                  hd: "null",
                  tl: /* [] */0
                }
              });
  } else {
    return propertyType;
  }
}

function printPinnedPropertyType(optional, pinnedValue, integrationType, hasSnowplowIntegerTypes, property) {
  return Belt_List.concat(integrationType === /* AvoJsonSchema */2 ? ({
                  hd: [
                    "type",
                    printPropertyUnderlyingType(integrationType, hasSnowplowIntegerTypes, property, optional)
                  ],
                  tl: /* [] */0
                }) : /* [] */0, {
              hd: [
                "const",
                pinnedValue.VAL
              ],
              tl: /* [] */0
            });
}

function printPropertyValidation(model, integrationType, hasSnowplowIntegerTypes, eventId, property) {
  return BeltListExtensions.flatMap(property.validations, (function (validation) {
                var variant = validation.NAME;
                if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
                  return /* [] */0;
                }
                if (variant === "Max") {
                  var match = validation.VAL;
                  var variant$1 = match.NAME;
                  if (variant$1 === "StringLit" || variant$1 === "BooleanLit") {
                    return /* [] */0;
                  } else {
                    return {
                            hd: [
                              "maximum",
                              match.VAL
                            ],
                            tl: /* [] */0
                          };
                  }
                }
                if (variant === "Min") {
                  var match$1 = validation.VAL;
                  var variant$2 = match$1.NAME;
                  if (variant$2 === "StringLit" || variant$2 === "BooleanLit") {
                    return /* [] */0;
                  } else {
                    return {
                            hd: [
                              "minimum",
                              match$1.VAL
                            ],
                            tl: /* [] */0
                          };
                  }
                }
                if (variant === "NestedProperty") {
                  var nestedProperties = Belt_List.keepMapU(validation.VAL, (function (nestedPropertyRef) {
                          return Belt_Option.flatMap(StateUtils.resolvePropertyById(nestedPropertyRef.id, model), (function (nestedProperty) {
                                        if (integrationType === /* Snowplow */0 && nestedProperty.name === "schema") {
                                          return ;
                                        } else {
                                          return [
                                                  nestedPropertyRef,
                                                  nestedProperty
                                                ];
                                        }
                                      }));
                        }));
                  var requiredProps = Belt_List.toArray(Belt_List.keepMap(nestedProperties, (function (param) {
                              var property = param[1];
                              if (!property.optionalWhenInObject) {
                                return Caml_option.some(property.name);
                              }
                              
                            })));
                  return Belt_List.concat({
                              hd: [
                                "properties",
                                Json_encode.object_(Belt_List.mapWithIndex(nestedProperties, (function (index, param) {
                                            var nestedProperty = param[1];
                                            return [
                                                    nestedProperty.name,
                                                    printProperty(model, integrationType, hasSnowplowIntegerTypes, eventId, property.id, param[0], nestedProperty, nestedProperty.optionalWhenInObject, index, undefined)
                                                  ];
                                          })))
                              ],
                              tl: {
                                hd: [
                                  "additionalProperties",
                                  false
                                ],
                                tl: /* [] */0
                              }
                            }, Caml_obj.caml_notequal(requiredProps, []) ? ({
                                  hd: [
                                    "required",
                                    requiredProps
                                  ],
                                  tl: /* [] */0
                                }) : /* [] */0);
                }
                if (variant !== "Shape") {
                  if (validation.VAL) {
                    return {
                            hd: [
                              "enum",
                              Belt_List.toArray(Belt_List.map(CodegenCommons.getMatches(property), (function (match_) {
                                          return match_;
                                        })))
                            ],
                            tl: /* [] */0
                          };
                  } else {
                    return /* [] */0;
                  }
                }
                if (!validation.VAL) {
                  return /* [] */0;
                }
                var shape = getShape(property.validations);
                return {
                        hd: [
                          "properties",
                          Json_encode.object_(Belt_List.map(shape, (function (item) {
                                      return [
                                              item.key,
                                              printObjectType(integrationType, hasSnowplowIntegerTypes, item)
                                            ];
                                    })))
                        ],
                        tl: {
                          hd: [
                            "additionalProperties",
                            false
                          ],
                          tl: {
                            hd: [
                              "required",
                              Belt_List.toArray(Belt_List.keepMap(shape, (function (item) {
                                          if (!item.optional) {
                                            return Caml_option.some(item.key);
                                          }
                                          
                                        })))
                            ],
                            tl: /* [] */0
                          }
                        }
                      };
              }));
}

function printType(model, integrationType, hasSnowplowIntegerTypes, eventId, propertyRef, parentPropertyId, property, optional, param) {
  var exit = 0;
  switch (property.type_) {
    case "any" :
        return /* [] */0;
    case "object" :
        if (integrationType === 1) {
          return /* [] */0;
        }
        exit = 2;
        break;
    default:
      exit = 2;
  }
  if (exit === 2) {
    var exit$1 = 0;
    if (property.list) {
      if (integrationType === 1) {
        return /* [] */0;
      }
      exit$1 = 3;
    } else {
      exit$1 = 3;
    }
    if (exit$1 === 3 && propertyRef !== undefined) {
      var pinnedValue = propertyRef.pinnedValue;
      var exit$2 = 0;
      if (pinnedValue !== undefined) {
        if (PropertyValidationUtils.validatePinnedValue(pinnedValue, property) && integrationType !== /* MParticle */1) {
          return printPinnedPropertyType(optional, pinnedValue, integrationType, hasSnowplowIntegerTypes, property);
        }
        exit$2 = 4;
      } else {
        exit$2 = 4;
      }
      if (exit$2 === 4 && parentPropertyId !== undefined && Belt_Option.isSome(CodegenRules.getNestedPropertyPinnedValue(propertyRef, parentPropertyId, eventId, model)) && integrationType !== /* MParticle */1) {
        var match = Belt_Option.getExn(CodegenRules.getNestedPropertyPinnedValue(propertyRef, parentPropertyId, eventId, model));
        return printPinnedPropertyType(optional, match[0], integrationType, hasSnowplowIntegerTypes, property);
      }
      
    }
    
  }
  var underlying = printPropertyUnderlyingType(integrationType, hasSnowplowIntegerTypes, property, optional);
  var restrictions = Belt_List.concatMany([
        integrationType === /* Snowplow */0 && hasSnowplowIntegerTypes && property.type_ === "int" || !(property.type_ === "int" || property.type_ === "long") ? /* [] */0 : ({
              hd: [
                "multipleOf",
                1
              ],
              tl: /* [] */0
            }),
        printPropertyValidation(model, integrationType, hasSnowplowIntegerTypes, eventId, property),
        integrationType === /* Snowplow */0 ? printAdditionalPropertyValidationsForSnowplow(hasSnowplowIntegerTypes, property) : /* [] */0
      ]);
  return Belt_List.concatMany([
              {
                hd: [
                  "type",
                  property.list ? "array" : underlying
                ],
                tl: /* [] */0
              },
              property.list ? ({
                    hd: [
                      "items",
                      Json_encode.object_(Belt_List.concatMany([
                                {
                                  hd: [
                                    "type",
                                    underlying
                                  ],
                                  tl: /* [] */0
                                },
                                restrictions
                              ]))
                    ],
                    tl: /* [] */0
                  }) : restrictions
            ]);
}

function printProperty(model, integrationType, hasSnowplowIntegerTypes, eventId, parentPropertyId, propertyRef, property, optional, index, param) {
  var nameMappings = Belt_List.keepMapU(model.rules, (function (rule) {
          var match = rule.item;
          var match$1 = rule.definition;
          if (typeof match !== "object") {
            return ;
          }
          if (match.NAME !== "Property") {
            return ;
          }
          if (typeof match$1 !== "object") {
            return ;
          }
          if (match$1.NAME !== "NameMapping") {
            return ;
          }
          if (match.VAL !== property.id) {
            return ;
          }
          var match$2 = match$1.VAL;
          var name = match$2.name;
          var destination = match$2.destination;
          if (destination === undefined) {
            return ;
          }
          if (typeof destination !== "object") {
            return [
                    Belt_Option.getWithDefault(name, property.name),
                    "all"
                  ];
          }
          var id = destination.VAL;
          if (matchesAtLeastOneDestination(model, id)) {
            return [
                    Belt_Option.getWithDefault(name, property.name),
                    id
                  ];
          }
          
        }));
  return Json_encode.object_(Belt_List.concatMany([
                  integrationType === /* AvoJsonSchema */2 ? ({
                        hd: [
                          "id",
                          property.id
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  integrationType === /* AvoJsonSchema */2 ? Belt_Option.mapWithDefault(index, /* [] */0, (function (index) {
                            return {
                                    hd: [
                                      "index",
                                      index
                                    ],
                                    tl: /* [] */0
                                  };
                          })) : /* [] */0,
                  {
                    hd: [
                      "description",
                      CodegenCommons.escapeDoubleQuotes(property.description)
                    ],
                    tl: /* [] */0
                  },
                  integrationType === /* AvoJsonSchema */2 ? ({
                        hd: [
                          "nameMapping",
                          Belt_List.toArray(Belt_List.mapU(nameMappings, (function (param) {
                                      return Json_encode.object_({
                                                  hd: [
                                                    "name",
                                                    param[0]
                                                  ],
                                                  tl: {
                                                    hd: [
                                                      "destinationId",
                                                      param[1]
                                                    ],
                                                    tl: /* [] */0
                                                  }
                                                });
                                    })))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  integrationType === /* AvoJsonSchema */2 && property.operation === "SnowplowContext" ? ({
                        hd: [
                          "operations",
                          property.operation === "SnowplowContext" ? ["SnowplowContext"] : []
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  printType(model, integrationType, hasSnowplowIntegerTypes, eventId, propertyRef, parentPropertyId, property, optional, undefined)
                ]));
}

function requiredPropertiesArray(model, properties, $$event, includedSources) {
  return Belt_List.toArray(Belt_List.keepMap(properties, (function (property) {
                    var optional = StateUtils.isPropertyOptionalOnEventsAndSources({
                          hd: $$event.id,
                          tl: /* [] */0
                        }, includedSources, property, model);
                    if (!optional) {
                      return Caml_option.some(CodegenRules.remapPropertyName(model, undefined, $$event.id, property));
                    }
                    
                  })));
}

function printPropertiesJsonObject(model, properties, integrationType, hasSnowplowIntegerTypes, $$event, includedSources) {
  return Json_encode.object_(Belt_List.map(properties, (function (property) {
                    var optional = StateUtils.isPropertyOptionalOnEventsAndSources({
                          hd: $$event.id,
                          tl: /* [] */0
                        }, includedSources, property, model);
                    var propertyRef = StateUtils.getPropertyRefById($$event.id, property.id, model);
                    return [
                            CodegenRules.remapPropertyName(model, undefined, $$event.id, property),
                            printProperty(model, integrationType, hasSnowplowIntegerTypes, $$event.id, undefined, propertyRef, property, optional, undefined, undefined)
                          ];
                  })));
}

function printPropertiesJsonSchema(model, properties, integrationType, hasSnowplowIntegerTypes, $$event, includedSources) {
  var requiredProps = requiredPropertiesArray(model, properties, $$event, includedSources);
  return Json_encode.object_(Belt_List.concat({
                  hd: [
                    "type",
                    "object"
                  ],
                  tl: {
                    hd: [
                      "properties",
                      printPropertiesJsonObject(model, properties, integrationType, hasSnowplowIntegerTypes, $$event, includedSources)
                    ],
                    tl: {
                      hd: [
                        "additionalProperties",
                        false
                      ],
                      tl: /* [] */0
                    }
                  }
                }, Caml_obj.caml_notequal(requiredProps, []) ? ({
                      hd: [
                        "required",
                        requiredProps
                      ],
                      tl: /* [] */0
                    }) : /* [] */0));
}

var escapeQuotes = CodegenCommons.escapeDoubleQuotes;

exports.escapeQuotes = escapeQuotes;
exports.getShape = getShape;
exports.printRawType = printRawType;
exports.printObjectValueType = printObjectValueType;
exports.printObjectType = printObjectType;
exports.printAdditionalPropertyValidationsForSnowplow = printAdditionalPropertyValidationsForSnowplow;
exports.printLiteral = printLiteral;
exports.matchesAtLeastOneDestination = matchesAtLeastOneDestination;
exports.printPropertyUnderlyingType = printPropertyUnderlyingType;
exports.printPinnedPropertyType = printPinnedPropertyType;
exports.printPropertyValidation = printPropertyValidation;
exports.printType = printType;
exports.printProperty = printProperty;
exports.requiredPropertiesArray = requiredPropertiesArray;
exports.printPropertiesJsonObject = printPropertiesJsonObject;
exports.printPropertiesJsonSchema = printPropertiesJsonSchema;
/* StateUtils Not a pure module */
