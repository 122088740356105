// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Caml = require("rescript/lib/js/caml.js");
var Icon = require("../../Icon.bs.js");
var $$Text = require("../../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("../../Button.bs.js");
var Router = require("../../Router.bs.js");
var Spacer = require("../../Spacer.bs.js");
var $$String = require("rescript/lib/js/string.js");
var Styles = require("../../styles.bs.js");
var Tooltip = require("../../Tooltip.bs.js");
var Accordion = require("../../Accordion.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var TextButton = require("../../TextButton.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SnippetUtils = require("../../detailviews/SnippetUtils.bs.js");

function getMaxStringLength(s, l) {
  if (s.length > l) {
    return $$String.sub(s, 0, l - 7 | 0) + "...";
  } else {
    return s;
  }
}

function PublicCodeChangesOverview$PublicCodeChangesOverviewSourcesSection(Props) {
  var initiallyExpanded = Props.initiallyExpanded;
  var onSourceSelect = Props.onSourceSelect;
  var sourceId = Props.sourceId;
  var sourceName = Props.sourceName;
  var events = Props.events;
  var implementedWithCodegen = Belt_Array.keep(events, (function (param) {
          var status = param.status;
          if (param.includeInCodegen) {
            if (status !== /* Removed */3) {
              return true;
            } else {
              return status !== /* Deleted */4;
            }
          } else {
            return false;
          }
        })).length;
  var implementedManually = Belt_Array.keep(events, (function (param) {
          var status = param.status;
          if (param.includeInCodegen) {
            return false;
          } else if (status !== /* Removed */3) {
            return true;
          } else {
            return status !== /* Deleted */4;
          }
        })).length;
  var deletedEvents = Belt_Array.keep(events, (function (param) {
          var status = param.status;
          if (status === /* Removed */3) {
            return true;
          } else {
            return status === /* Deleted */4;
          }
        })).length;
  var codegenString = implementedWithCodegen !== 0 ? (
      implementedWithCodegen !== 1 ? String(implementedWithCodegen) + " events to be implemented using Codegen" : "1 event to be implemented using Codegen"
    ) : undefined;
  var manualString = implementedManually !== 0 ? (
      implementedManually !== 1 ? String(implementedManually) + " events not using Codegen need manual implementation and validation" : "1 event not using Codegen needs manual implementation and validation"
    ) : undefined;
  var deletedString = deletedEvents !== 0 ? (
      deletedEvents !== 1 ? String(deletedEvents) + " events removed" : "1 event removed"
    ) : undefined;
  var secondaryTitle = codegenString !== undefined ? codegenString : (
      manualString !== undefined ? manualString : (
          deletedString !== undefined ? deletedString : ""
        )
    );
  var title_1 = React.createElement("span", {
        className: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.gap(Css.px(4)),
                  tl: /* [] */0
                }
              }
            })
      }, React.createElement($$Text.make, {
            size: "Small",
            weight: "Semi",
            singleLine: true,
            color: Styles.Color.grey90,
            maxWidth: {
              NAME: "px",
              VAL: 150
            },
            children: sourceName
          }), React.createElement($$Text.make, {
            size: "Small",
            color: Styles.Color.grey70,
            children: secondaryTitle
          }));
  var title = {
    NAME: "Component",
    VAL: title_1
  };
  var subtitle = codegenString !== undefined ? (
      manualString !== undefined || deletedString !== undefined ? ({
            NAME: "Component",
            VAL: Belt_Array.map(Belt_Array.keepMap([
                      manualString,
                      deletedString
                    ], (function (s) {
                        return s;
                      })), (function (str) {
                    return React.createElement($$Text.make, {
                                size: "Small",
                                color: Styles.Color.grey70,
                                children: str,
                                key: str
                              });
                  }))
          }) : undefined
    ) : (
      deletedString !== undefined ? ({
            NAME: "Component",
            VAL: React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.grey70,
                  children: deletedString
                })
          }) : undefined
    );
  var tmp = {
    title: title,
    ariaLabel: sourceName,
    icon: React.createElement(Icon.make, {
          type_: "code",
          size: "small",
          color: Styles.Color.grey70
        }),
    theme: "white",
    extra: React.createElement(Button.make, {
          label: "View " + getMaxStringLength(sourceName, 20) + " Code Changes",
          onClick: (function (param) {
              return Curry._1(onSourceSelect, sourceId);
            }),
          size: "tiny",
          style: "outline"
        })
  };
  if (subtitle !== undefined) {
    tmp.subtitle = Caml_option.valFromOption(subtitle);
  }
  return React.createElement(Accordion.Item.make, {
              initiallyExpanded: initiallyExpanded,
              children: null
            }, React.createElement(Accordion.ItemHeader.make, tmp), React.createElement(Accordion.ItemCollapsible.make, {
                  children: React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.paddingLeft(Css.px(15)),
                              tl: {
                                hd: Css.paddingBottom(Css.px(15)),
                                tl: /* [] */0
                              }
                            })
                      }, Belt_Array.map(Belt_List.toArray(Belt_List.sort(Belt_List.fromArray(events), (function (a, b) {
                                      return Caml.caml_string_compare(a.name, b.name);
                                    }))), (function (param) {
                              var includeInCodegen = param.includeInCodegen;
                              var status = param.status;
                              var eventId = param.id;
                              return React.createElement("div", {
                                          key: eventId,
                                          className: status >= 3 ? Curry._1(Css.style, {
                                                  hd: Css.textDecoration("lineThrough"),
                                                  tl: {
                                                    hd: Css.textDecorationColor(Styles.Color.setAlpha(Styles.Color.grey100, 0.7)),
                                                    tl: /* [] */0
                                                  }
                                                }) : (
                                              includeInCodegen ? Curry._1(Css.style, {
                                                      hd: Icon.color(Styles.Color.blue),
                                                      tl: /* [] */0
                                                    }) : Curry._1(Css.style, {
                                                      hd: Icon.color(Styles.Color.grey70),
                                                      tl: /* [] */0
                                                    })
                                            )
                                        }, React.createElement(TextButton.make, {
                                              onClick: (function (param) {
                                                  return Router.push(undefined, SnippetUtils.getSnippetLink(eventId, sourceId, "PublicBranchImplementation"));
                                                }),
                                              style: "Black",
                                              size: "Small",
                                              children: null
                                            }, React.createElement(Tooltip.make, {
                                                  title: "",
                                                  tooltipPadding: 8,
                                                  withAskUsAnything: false,
                                                  withDismiss: false,
                                                  withTitle: false,
                                                  children: null
                                                }, React.createElement(Tooltip.Target.make, {
                                                      children: status >= 3 ? React.createElement("span", {
                                                              className: Curry._1(Css.style, {
                                                                    hd: Css.display("block"),
                                                                    tl: {
                                                                      hd: Css.width(Css.px(12)),
                                                                      tl: {
                                                                        hd: Css.height(Css.px(12)),
                                                                        tl: {
                                                                          hd: Css.borderRadius(Css.pct(50.0)),
                                                                          tl: {
                                                                            hd: Css.backgroundColor(Styles.Color.red70),
                                                                            tl: /* [] */0
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  })
                                                            }) : React.createElement("span", {
                                                              className: Curry._1(Css.style, {
                                                                    hd: Css.display("flex"),
                                                                    tl: {
                                                                      hd: Css.alignItems("center"),
                                                                      tl: {
                                                                        hd: Css.justifyContent("center"),
                                                                        tl: {
                                                                          hd: Css.width(Css.px(12)),
                                                                          tl: {
                                                                            hd: Css.height(Css.px(12)),
                                                                            tl: /* [] */0
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  })
                                                            }, React.createElement(Icon.make, {
                                                                  type_: "code",
                                                                  size: {
                                                                    NAME: "int",
                                                                    VAL: 12
                                                                  }
                                                                }))
                                                    }), React.createElement(Tooltip.Content.make, {
                                                      children: React.createElement($$Text.make, {
                                                            size: "Small",
                                                            children: status >= 3 ? "Event should be removed" : (
                                                                includeInCodegen ? "Event should be implemented using Codegen" : "Event should be implemented and validated manually"
                                                              )
                                                          })
                                                    })), React.createElement(Spacer.make, {
                                                  width: 12
                                                }), param.name));
                            })))
                }));
}

function PublicCodeChangesOverview(Props) {
  var data = Props.data;
  var onSourceSelect = Props.onSourceSelect;
  var initiallyExpanded = Belt_Array.every(data.sources, (function (param) {
          return param.events.length <= 30;
        })) && data.sources.length <= 3;
  return React.createElement("section", {
              className: Curry._1(Css.style, {
                    hd: Css.maxWidth(Css.px(956)),
                    tl: {
                      hd: Css.padding(Css.px(20)),
                      tl: {
                        hd: Css.paddingRight("zero"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement(Accordion.make, {
                  allowMultipleOpens: true,
                  size: "large",
                  chevronPosition: "right",
                  children: Belt_Array.map(data.sources, (function (param) {
                          var id = param.id;
                          return React.createElement(PublicCodeChangesOverview$PublicCodeChangesOverviewSourcesSection, {
                                      initiallyExpanded: initiallyExpanded,
                                      onSourceSelect: onSourceSelect,
                                      sourceId: id,
                                      sourceName: param.name,
                                      events: param.events,
                                      key: id
                                    });
                        }))
                }));
}

var make = PublicCodeChangesOverview;

exports.make = make;
/* Css Not a pure module */
