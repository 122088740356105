// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Caml = require("rescript/lib/js/caml.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Lodash = require("./externals/lodash.bs.js");
var Models = require("./Models.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var TextInput = require("./TextInput.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var PortalMenu = require("./PortalMenu.bs.js");
var TextButton = require("./TextButton.bs.js");
var Fuzzaldrin = require("fuzzaldrin");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var KeyListener = require("./KeyListener.bs.js");
var Belt_SetDict = require("rescript/lib/js/belt_SetDict.js");
var FramerMotion = require("framer-motion");

function MakeEditableCell(ListItem) {
  var getNextItemInCycle = function (currentIndex, cycleLength) {
    if (cycleLength > 0) {
      return Caml_int32.mod_(Caml_int32.mod_(currentIndex + 1 | 0, cycleLength) + cycleLength | 0, cycleLength);
    } else {
      return 0;
    }
  };
  var getPreviousItemInCycle = function (currentIndex, cycleLength) {
    if (cycleLength > 0) {
      return Caml_int32.mod_(Caml_int32.mod_(currentIndex - 1 | 0, cycleLength) + cycleLength | 0, cycleLength);
    } else {
      return 0;
    }
  };
  var focusChildAtIndex = function (childIndex, parentRef) {
    return Belt_Option.forEach(Belt_Option.flatMap(Caml_option.nullable_to_opt(parentRef.current), (function (el) {
                      return Belt_Array.get(el.children, childIndex);
                    })), (function (prim) {
                  prim.focus();
                  
                }));
  };
  var sortCellItems = function (items) {
    return items.slice().sort(function (a, b) {
                return Caml.caml_string_compare(Curry._1(ListItem.Cell.getName, a), Curry._1(ListItem.Cell.getName, b));
              });
  };
  var Utils = {
    getNextItemInCycle: getNextItemInCycle,
    getPreviousItemInCycle: getPreviousItemInCycle,
    focusChildAtIndex: focusChildAtIndex,
    sortCellItems: sortCellItems
  };
  var outerContainerStyles = function (isActivated, canClick, className) {
    return Curry._1(Css.style, {
                hd: Css.height(Css.pct(100)),
                tl: {
                  hd: Css.position("relative"),
                  tl: {
                    hd: Css.after(isActivated ? ({
                              hd: Css.display("block"),
                              tl: {
                                hd: Css.position("absolute"),
                                tl: {
                                  hd: Css.left(Css.px(0)),
                                  tl: {
                                    hd: Css.right(Css.px(0)),
                                    tl: {
                                      hd: Css.top(Css.px(0)),
                                      tl: {
                                        hd: Css.bottom(Css.px(0)),
                                        tl: {
                                          hd: Css.unsafe("content", "' '"),
                                          tl: {
                                            hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.blue, 0.1)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }) : /* [] */0),
                    tl: {
                      hd: Css.hover({
                            hd: Css.after(canClick ? ({
                                      hd: Css.unsafe("content", "'Click to edit'"),
                                      tl: {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.justifyContent("center"),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.8)),
                                              tl: {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: {
                                                  hd: Css.fontSize(Styles.FontSize.small),
                                                  tl: {
                                                    hd: Css.color(Styles.Color.blue),
                                                    tl: {
                                                      hd: Css.position("absolute"),
                                                      tl: {
                                                        hd: Css.left(Css.px(0)),
                                                        tl: {
                                                          hd: Css.right(Css.px(0)),
                                                          tl: {
                                                            hd: Css.top(Css.px(0)),
                                                            tl: {
                                                              hd: Css.bottom(Css.px(0)),
                                                              tl: {
                                                                hd: Css.border(Css.px(2), "solid", Styles.Color.blue),
                                                                tl: {
                                                                  hd: Css.borderRadius(Styles.Border.radius),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }) : /* [] */0),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }) + (" " + className);
  };
  var CellEditPopup$MakeEditableCell$PopupCell = function (Props) {
    var canEdit = Props.canEdit;
    var classNameOpt = Props.className;
    var item = Props.item;
    var disabledOpt = Props.disabled;
    var onHover = Props.onHover;
    var onClick = Props.onClick;
    var onActivation = Props.onActivation;
    var parentActivatedState = Props.activated;
    var initialStateOpt = Props.initialState;
    var children = Props.children;
    var className = classNameOpt !== undefined ? classNameOpt : "";
    var disabled = disabledOpt !== undefined ? disabledOpt : false;
    var initialState = initialStateOpt !== undefined ? initialStateOpt : false;
    var spanRef = React.useRef(null);
    var match = React.useState(function () {
          return false;
        });
    var setIsVisible = match[1];
    var isVisible = match[0];
    var match$1 = React.useState(function () {
          return initialState;
        });
    var setIsActivated = match$1[1];
    var isActivated = match$1[0];
    var onHoverTimeoutId = React.useRef(undefined);
    var canHover = !disabled;
    var canClick = canEdit && canHover;
    React.useEffect((function () {
            return (function (param) {
                      return Belt_Option.forEach(onHoverTimeoutId.current, (function (prim) {
                                    clearTimeout(prim);
                                    
                                  }));
                    });
          }), []);
    React.useEffect((function () {
            if (disabled) {
              Curry._1(setIsVisible, (function (param) {
                      return false;
                    }));
            }
            
          }), [disabled]);
    React.useEffect((function () {
            if (isVisible) {
              
            } else {
              Belt_Option.forEach(onHoverTimeoutId.current, (function (prim) {
                      clearTimeout(prim);
                      
                    }));
            }
            return (function (param) {
                      return Belt_Option.forEach(onHoverTimeoutId.current, (function (prim) {
                                    clearTimeout(prim);
                                    
                                  }));
                    });
          }), [isVisible]);
    React.useEffect((function () {
            Belt_Option.forEach(onActivation, (function (cb) {
                    return Curry._1(cb, isActivated);
                  }));
            
          }), [isActivated]);
    React.useEffect((function () {
            Belt_Option.forEach(parentActivatedState, (function (isActivated) {
                    return Curry._1(setIsActivated, (function (param) {
                                  return isActivated;
                                }));
                  }));
            
          }), [parentActivatedState]);
    var onClick$1 = React.useCallback((function (e) {
            if (canClick) {
              Curry._1(setIsActivated, (function (state) {
                      return !state;
                    }));
              e.stopPropagation();
              return Belt_Option.forEach(onClick, (function (cb) {
                            return Curry._1(cb, undefined);
                          }));
            }
            
          }), [
          canClick,
          onClick
        ]);
    var onMouseEnter = React.useCallback((function (_e) {
            if (canHover) {
              onHoverTimeoutId.current = Caml_option.some(setTimeout((function (param) {
                          Curry._1(setIsVisible, (function (param) {
                                  return true;
                                }));
                          return Belt_Option.forEach(onHover, (function (cb) {
                                        return Curry._1(cb, undefined);
                                      }));
                        }), 250));
              return ;
            }
            
          }), [
          canHover,
          onHover
        ]);
    var onMouseLeave = React.useCallback((function (param) {
            Belt_Option.forEach(onHoverTimeoutId.current, (function (prim) {
                    clearTimeout(prim);
                    
                  }));
            return Curry._1(setIsVisible, (function (param) {
                          return false;
                        }));
          }), []);
    var onClose = React.useCallback((function (param) {
            Curry._1(setIsActivated, (function (param) {
                    return false;
                  }));
            return Curry._1(setIsVisible, (function (param) {
                          return false;
                        }));
          }), []);
    return React.createElement("div", {
                ref: spanRef,
                className: outerContainerStyles(isActivated, canClick, className),
                onClick: onClick$1,
                onMouseEnter: onMouseEnter,
                onMouseLeave: onMouseLeave
              }, item, isVisible || isActivated ? React.createElement(FramerMotion.AnimatePresence, {
                      exitBeforeEnter: true,
                      initial: true,
                      children: React.createElement(PortalMenu.make, {
                            position: "Right",
                            placement: "Start",
                            distance: 0,
                            clickOutsideToDismiss: isActivated,
                            visible: isVisible || isActivated,
                            onClose: onClose,
                            recalculateOnBoundingRectChanges: true,
                            referenceItemRef: spanRef,
                            children: React.createElement(FramerMotion.motion.div, {
                                  animate: {
                                    opacity: 1,
                                    scale: 1
                                  },
                                  transition: {
                                    duration: 0.2
                                  },
                                  initial: {
                                    opacity: 0,
                                    scale: 0.95
                                  },
                                  exit: {
                                    opacity: 0,
                                    scale: 0.95
                                  },
                                  className: Curry._1(Css.style, {
                                        hd: Css.boxShadow(Styles.Shadow.subtle),
                                        tl: /* [] */0
                                      }),
                                  key: "implementation-status-popup",
                                  children: Curry._1(children, onClose)
                                })
                          })
                    }) : null);
  };
  var PopupCell = {
    make: CellEditPopup$MakeEditableCell$PopupCell
  };
  var container = function (elements, isEditing) {
    return Curry._1(Css.style, {
                hd: Css.width(Css.px(300)),
                tl: {
                  hd: Css.borderRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.maxHeight(Css.vh(50)),
                      tl: {
                        hd: Css.height(isEditing ? Css.vh(50) : Css.px(Math.imul(elements + 1 | 0, 48) + 1 | 0)),
                        tl: {
                          hd: Css.unsafe("overflow", "clip"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              });
  };
  var header = Curry._1(Css.style, {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: {
                hd: Css.padding2("zero", Css.px(16)),
                tl: {
                  hd: Css.height(Css.px(48)),
                  tl: {
                    hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.setAlpha(Css.white, 0.5)),
                      tl: {
                        hd: Css.backdropFilter({
                              hd: {
                                NAME: "blur",
                                VAL: Css.px(10)
                              },
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.zIndex(3),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
  var list = Curry._1(Css.style, {
        hd: Css.padding("zero"),
        tl: {
          hd: Css.margin("zero"),
          tl: {
            hd: Css.listStyleType("none"),
            tl: {
              hd: Css.textIndent("zero"),
              tl: {
                hd: Css.overflow("auto"),
                tl: {
                  hd: Css.height({
                        NAME: "substract",
                        VAL: [
                          Css.pct(100),
                          Css.px(49)
                        ]
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      });
  var listItem = function (isPointable) {
    return Curry._1(Css.style, {
                hd: Css.transition({
                      NAME: "ms",
                      VAL: Styles.Duration.$$default
                    }, undefined, undefined, "background-color"),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.userSelect("none"),
                      tl: {
                        hd: Css.paddingLeft(Css.px(16)),
                        tl: {
                          hd: Css.paddingRight(Css.px(16)),
                          tl: {
                            hd: Css.paddingTop(Css.px(8)),
                            tl: {
                              hd: Css.paddingBottom(Css.px(8)),
                              tl: {
                                hd: Css.cursor(isPointable ? "pointer" : "default"),
                                tl: {
                                  hd: Css.height(Css.px(48)),
                                  tl: {
                                    hd: Css.focus({
                                          hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.blue, 0.15)),
                                          tl: {
                                            hd: Css.outlineStyle("none"),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: {
                                      hd: Css.hover({
                                            hd: Css.backgroundColor(Styles.Color.grey30),
                                            tl: /* [] */0
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
  };
  var Style = {
    elementHeight: 48,
    container: container,
    header: header,
    list: list,
    listItem: listItem
  };
  var cmp = function (a, b) {
    return Caml.caml_string_compare(Curry._1(ListItem.Cell.getId, a), Curry._1(ListItem.Cell.getId, b));
  };
  var ListItemCompare = Belt_Id.MakeComparable({
        cmp: cmp
      });
  var CellEditPopup$MakeEditableCell = function (Props) {
    var allItems = Props.allItems;
    var children = Props.children;
    var className = Props.className;
    var onCreates = Props.onCreates;
    var disabled = Props.disabled;
    var onViewItem = Props.onViewItem;
    var onToggle = Props.onToggle;
    var role = Props.role;
    var selectedItems = Props.selectedItems;
    var onItemClicked = Props.onItemClicked;
    var onHover = Props.onHover;
    var onSearch = Props.onSearch;
    var onCreateInitiated = Props.onCreateInitiated;
    var match = React.useState(function () {
          return false;
        });
    var setIsEditing = match[1];
    var isEditing = match[0];
    var match$1 = React.useState(function () {
          return "";
        });
    var setSearchTerm = match$1[1];
    var searchTerm = match$1[0];
    var match$2 = React.useState(function () {
          
        });
    var setActiveIndex = match$2[1];
    var activeIndex = match$2[0];
    var match$3 = React.useState(function () {
          return sortCellItems(selectedItems);
        });
    var setItemsDuringSession = match$3[1];
    var itemsDuringSession = match$3[0];
    var match$4 = React.useState(function () {
          return itemsDuringSession;
        });
    var setItemsShown = match$4[1];
    var itemsShown = match$4[0];
    var searchTermRef = React.useRef(null);
    var listRef = React.useRef(null);
    var filteredItems = React.useMemo((function () {
            return Belt_Array.mapU(Belt_Array.keepU(Fuzzaldrin.filter(Belt_Array.mapU(allItems, (function (item) {
                                      return {
                                              name: Curry._1(ListItem.Cell.getName, item),
                                              item: item
                                            };
                                    })), searchTerm, {
                                key: "name",
                                maxResults: 30
                              }), (function (fuzzItem) {
                              return Belt_Array.someU(allItems, (function (item) {
                                            return Curry._1(ListItem.Cell.getId, fuzzItem.item) === Curry._1(ListItem.Cell.getId, item);
                                          }));
                            })), (function (fuzzItem) {
                          return fuzzItem.item;
                        }));
          }), [
          allItems,
          searchTerm
        ]);
    var focusSearchBox = React.useCallback((function (param) {
            return Belt_Option.forEach(Caml_option.nullable_to_opt(searchTermRef.current), (function (prim) {
                          prim.focus();
                          
                        }));
          }), [searchTermRef]);
    var blurSearchBox = React.useCallback((function (param) {
            return Belt_Option.forEach(Caml_option.nullable_to_opt(searchTermRef.current), (function (prim) {
                          prim.blur();
                          
                        }));
          }), [searchTermRef]);
    React.useEffect((function () {
            if (!isEditing) {
              Curry._1(setItemsDuringSession, (function (oldItems) {
                      return sortCellItems(Belt_SetDict.toArray(Belt_SetDict.mergeMany(Belt_SetDict.fromArray(oldItems, ListItemCompare.cmp), selectedItems, ListItemCompare.cmp)));
                    }));
            }
            
          }), [
          selectedItems,
          isEditing
        ]);
    React.useEffect((function () {
            if (activeIndex !== undefined) {
              Curry._1(blurSearchBox, undefined);
            }
            
          }), [activeIndex !== undefined]);
    React.useEffect((function () {
            if (isEditing) {
              Curry._1(setItemsShown, (function (param) {
                      if (searchTerm !== "") {
                        return filteredItems;
                      }
                      var sessionItemsSet = Belt_SetDict.fromArray(itemsDuringSession, ListItemCompare.cmp);
                      var filteredItemsSet = Belt_SetDict.fromArray(filteredItems, ListItemCompare.cmp);
                      var diff = Belt_SetDict.toArray(Belt_SetDict.diff(filteredItemsSet, sessionItemsSet, ListItemCompare.cmp));
                      return Belt_Array.concat(sortCellItems(Belt_SetDict.toArray(sessionItemsSet)), diff);
                    }));
            } else {
              Curry._1(setActiveIndex, (function (param) {
                      
                    }));
              Curry._1(setSearchTerm, (function (param) {
                      return "";
                    }));
              Curry._1(setItemsShown, (function (param) {
                      return sortCellItems(selectedItems);
                    }));
            }
            
          }), [
          isEditing,
          searchTerm,
          selectedItems
        ]);
    var onEscape = React.useCallback((function (e) {
            if (isEditing) {
              if (activeIndex !== undefined) {
                Curry._1(focusSearchBox, undefined);
              } else {
                Curry._1(setIsEditing, (function (param) {
                        return false;
                      }));
              }
            }
            e.preventDefault();
            e.stopPropagation();
            
          }), [
          activeIndex,
          isEditing
        ]);
    var onArrowDown = React.useCallback((function (e) {
            Curry._1(setActiveIndex, (function (index) {
                    var newIndex = Belt_Option.mapWithDefault(index, 0, (function (index) {
                            return getNextItemInCycle(index, itemsShown.length);
                          }));
                    focusChildAtIndex(newIndex, listRef);
                    return newIndex;
                  }));
            e.preventDefault();
            e.stopPropagation();
            
          }), [
          itemsShown.length,
          listRef
        ]);
    var onArrowUp = React.useCallback((function (e) {
            Curry._1(setActiveIndex, (function (index) {
                    var itemCount = itemsShown.length;
                    var newIndex = Belt_Option.mapWithDefault(index, itemCount - 1 | 0, (function (index) {
                            return getPreviousItemInCycle(index, itemCount);
                          }));
                    focusChildAtIndex(newIndex, listRef);
                    return newIndex;
                  }));
            e.preventDefault();
            e.stopPropagation();
            
          }), [
          itemsShown.length,
          listRef
        ]);
    var isChecked = React.useCallback((function (itemToCheck) {
            return Belt_Option.isSome(Belt_Array.getByU(selectedItems, (function (item) {
                              return Caml_obj.caml_equal(item, itemToCheck);
                            })));
          }), [selectedItems]);
    return React.createElement(CellEditPopup$MakeEditableCell$PopupCell, {
                canEdit: Models.Role.canEdit(role),
                className: className,
                item: children,
                disabled: disabled,
                onHover: onHover,
                onClick: (function (param) {
                    return Curry._1(onItemClicked, undefined);
                  }),
                onActivation: (function (isEditing) {
                    return Curry._1(setIsEditing, (function (param) {
                                  return isEditing;
                                }));
                  }),
                activated: isEditing,
                children: (function (hidePopup) {
                    var tmp;
                    var exit = 0;
                    if (onCreates !== undefined && itemsShown.length === 0 && isEditing) {
                      tmp = Belt_Array.map(Curry._1(onCreates, hidePopup), (function (param) {
                              var onCreate = param[0];
                              return React.createElement("li", {
                                          className: listItem(true),
                                          role: "button",
                                          onClick: (function (param) {
                                              Curry._1(onCreateInitiated, searchTerm);
                                              return Curry._1(onCreate, searchTerm);
                                            })
                                        }, React.createElement($$Text.make, {
                                              size: "Medium",
                                              weight: "Medium",
                                              color: Styles.Color.blue,
                                              children: Curry._1(param[1], searchTerm)
                                            }));
                            }));
                    } else {
                      exit = 1;
                    }
                    if (exit === 1) {
                      tmp = itemsShown.length !== 0 ? Belt_Array.mapWithIndexU(itemsShown, (function (index, item) {
                                return React.createElement("li", {
                                            key: Curry._1(ListItem.Cell.getId, item),
                                            className: listItem(isEditing || Belt_Option.isSome(onViewItem)),
                                            tabIndex: -1
                                          }, React.createElement(ListItem.make, {
                                                active: Belt_Option.mapWithDefault(activeIndex, false, (function (api) {
                                                        return api === index;
                                                      })),
                                                checked: Curry._1(isChecked, item),
                                                item: item,
                                                mode: isEditing ? /* Editing */1 : /* Viewing */0,
                                                onClick: (function (param) {
                                                    var viewingCallback = hidePopup;
                                                    var id = Curry._1(ListItem.Cell.getId, item);
                                                    return Belt_Option.forEach(onViewItem, (function (onViewItem) {
                                                                  Curry._1(onViewItem, id);
                                                                  Belt_Option.forEach(viewingCallback, (function (cb) {
                                                                          return Curry._1(cb, undefined);
                                                                        }));
                                                                  return Curry._1(onItemClicked, undefined);
                                                                }));
                                                  }),
                                                onToggle: (function (id, becameToggled) {
                                                    return Curry._3(onToggle, searchTerm, id, becameToggled);
                                                  })
                                              }));
                              })) : React.createElement("li", {
                              className: listItem(true),
                              role: "button",
                              onClick: (function (param) {
                                  return Curry._1(setIsEditing, (function (param) {
                                                return true;
                                              }));
                                })
                            }, React.createElement($$Text.make, {
                                  size: "Medium",
                                  weight: "Medium",
                                  color: Styles.Color.grey70,
                                  children: "+ Add " + ListItem.Cell.popupTitle
                                }));
                    }
                    return React.createElement("aside", {
                                className: container(Caml.caml_int_max(1, itemsShown.length), isEditing)
                              }, React.createElement("header", {
                                    className: header
                                  }, React.createElement(FramerMotion.AnimatePresence, {
                                        exitBeforeEnter: false,
                                        initial: false,
                                        children: React.createElement(FramerMotion.motion.div, {
                                              animate: {
                                                opacity: 1,
                                                width: "100%"
                                              },
                                              transition: {
                                                duration: 0.15
                                              },
                                              initial: {
                                                opacity: 0,
                                                width: "0px"
                                              },
                                              exit: {
                                                opacity: 0,
                                                width: "0px"
                                              },
                                              className: Curry._1(Css.style, {
                                                    hd: Css.selector(" input", {
                                                          hd: Css.width("inherit_"),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }),
                                              key: isEditing ? "input" : "title",
                                              children: isEditing ? React.createElement(TextInput.make, {
                                                      autoFocus: true,
                                                      onChange: (function (value) {
                                                          Lodash.debouncedFunction(2000)(function (param) {
                                                                return Curry._1(onSearch, value);
                                                              });
                                                          return Curry._1(setSearchTerm, (function (param) {
                                                                        return value;
                                                                      }));
                                                        }),
                                                      onFocus: (function (param) {
                                                          return Curry._1(setActiveIndex, (function (param) {
                                                                        
                                                                      }));
                                                        }),
                                                      noBorder: true,
                                                      placeholder: "Search " + (ListItem.Cell.popupTitle + "..."),
                                                      forwardRef: searchTermRef,
                                                      value: searchTerm
                                                    }) : React.createElement($$Text.make, {
                                                      element: "H1",
                                                      size: "Medium",
                                                      weight: "Medium",
                                                      singleLine: true,
                                                      children: ListItem.Cell.popupTitle
                                                    })
                                            })
                                      }), React.createElement(Spacer.make, {
                                        width: 8,
                                        grow: 1.0
                                      }), React.createElement(TextButton.make, {
                                        onClick: (function (param) {
                                            return Curry._1(setIsEditing, (function (isEditing) {
                                                          return !isEditing;
                                                        }));
                                          }),
                                        style: "Blue",
                                        size: "Small",
                                        children: isEditing ? "DONE" : "EDIT"
                                      })), isEditing ? React.createElement(React.Fragment, undefined, React.createElement(KeyListener.make, {
                                          keyName: "Escape",
                                          onPress: onEscape
                                        }), React.createElement(KeyListener.make, {
                                          keyName: "ArrowDown",
                                          onPress: onArrowDown
                                        }), React.createElement(KeyListener.make, {
                                          keyName: "ArrowUp",
                                          onPress: onArrowUp
                                        })) : null, React.createElement("ul", {
                                    ref: listRef,
                                    className: list
                                  }, tmp));
                  })
              });
  };
  return {
          Utils: Utils,
          outerContainerStyles: outerContainerStyles,
          PopupCell: PopupCell,
          Style: Style,
          ListItemCompare: ListItemCompare,
          make: CellEditPopup$MakeEditableCell
        };
}

exports.MakeEditableCell = MakeEditableCell;
/* Css Not a pure module */
