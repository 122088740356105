// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var FeatureFlag = require("../../shared/FeatureFlag.bs.js");

var initialValue_localOverrides = {};

var initialValue_globalFeatureFlags = [];

function initialValue_featureEnabled(param) {
  return false;
}

function initialValue_setLocalOverride(param) {
  
}

function initialValue_removeLocalOverride(param) {
  
}

function initialValue_removeAllLocalOverrides(param) {
  
}

var initialValue = {
  localOverrides: initialValue_localOverrides,
  globalFeatureFlags: initialValue_globalFeatureFlags,
  featureEnabled: initialValue_featureEnabled,
  setLocalOverride: initialValue_setLocalOverride,
  removeLocalOverride: initialValue_removeLocalOverride,
  removeAllLocalOverrides: initialValue_removeAllLocalOverrides
};

var context = React.createContext(initialValue);

var provider = context.Provider;

function getGlobalFeatureFlags(schema, featureFlags) {
  return Belt_Array.map(featureFlags.sort(function (a, b) {
                  return Caml.caml_string_compare(FeatureFlag.featureFlagToString(a), FeatureFlag.featureFlagToString(b));
                }), (function (featureFlag) {
                return {
                        featureFlag: featureFlag,
                        enabled: FeatureFlag.featureEnabled(schema, featureFlag)
                      };
              }));
}

function AppFeatureFlag$Provider(Props) {
  var schema = Props.schema;
  var children = Props.children;
  var match = React.useState(function () {
        return {};
      });
  var setLocalOverrides = match[1];
  var localOverrides = match[0];
  var globalFeatureFlags = getGlobalFeatureFlags(schema, FeatureFlag.featureFlags);
  var featureEnabled = function (featureFlag) {
    var isGloballyEnabled = FeatureFlag.featureEnabled(schema, featureFlag);
    var isLocallyEnabled = Js_dict.get(localOverrides, FeatureFlag.featureFlagToString(featureFlag));
    return Belt_Option.mapWithDefault(isLocallyEnabled, isGloballyEnabled, (function (param) {
                  return param.enabled;
                }));
  };
  var addLocalOverride = function (localOverride) {
    return Curry._1(setLocalOverrides, (function (localOverrides) {
                  var clonedDict = Js_dict.fromArray(Js_dict.entries(localOverrides));
                  clonedDict[FeatureFlag.featureFlagToString(localOverride.featureFlag)] = localOverride;
                  return clonedDict;
                }));
  };
  var removeLocalOverride = function (featureFlag) {
    return Curry._1(setLocalOverrides, (function (localOverrides) {
                  return Js_dict.fromArray(Belt_Array.keepU(Js_dict.entries(localOverrides), (function (param) {
                                    return param[0] !== FeatureFlag.featureFlagToString(featureFlag);
                                  })));
                }));
  };
  var removeAllLocalOverrides = function (param) {
    return Curry._1(setLocalOverrides, (function (param) {
                  return {};
                }));
  };
  return React.createElement(provider, {
              value: {
                localOverrides: localOverrides,
                globalFeatureFlags: globalFeatureFlags,
                featureEnabled: featureEnabled,
                setLocalOverride: addLocalOverride,
                removeLocalOverride: removeLocalOverride,
                removeAllLocalOverrides: removeAllLocalOverrides
              },
              children: children
            });
}

var Provider = {
  getGlobalFeatureFlags: getGlobalFeatureFlags,
  make: AppFeatureFlag$Provider
};

function use(param) {
  return React.useContext(context);
}

function useFeatureFlag(featureFlag) {
  var match = React.useContext(context);
  return Curry._1(match.featureEnabled, featureFlag);
}

exports.initialValue = initialValue;
exports.context = context;
exports.provider = provider;
exports.Provider = Provider;
exports.use = use;
exports.useFeatureFlag = useFeatureFlag;
/* context Not a pure module */
