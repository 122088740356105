// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var SetupMd = require("./setup.md");
var TermsMd = require("./terms.md");
var JsHowtoMd = require("./js-howto.md");
var ReHowtoMd = require("./re-howto.md");
var TsHowtoMd = require("./ts-howto.md");
var IosBrazeMd = require("./ios-braze.md");
var PhpHowtoMd = require("./php-howto.md");
var JavaHowtoMd = require("./java-howto.md");
var ObjcHowtoMd = require("./objc-howto.md");
var RubyHowtoMd = require("./ruby-howto.md");
var IosSegmentMd = require("./ios-segment.md");
var JsV2HowtoMd = require("./js-v2-howto.md");
var ReV2HowtoMd = require("./re-v2-howto.md");
var SwiftHowtoMd = require("./swift-howto.md");
var ExpoSegmentMd = require("./expo-segment.md");
var IosMixpanelMd = require("./ios-mixpanel.md");
var KotlinHowtoMd = require("./kotlin-howto.md");
var NodeSegmentMd = require("./node-segment.md");
var PythonHowtoMd = require("./python-howto.md");
var AndroidBrazeMd = require("./android-braze.md");
var IosAmplitudeMd = require("./ios-amplitude.md");
var NodeIntercomMd = require("./node-intercom.md");
var NodeMixpanelMd = require("./node-mixpanel.md");
var ExpoAmplitudeMd = require("./expo-amplitude.md");
var NodeAmplitudeMd = require("./node-amplitude.md");
var AndroidSegmentMd = require("./android-segment.md");
var GtmJsV2HowtoMd = require("./gtm-js-v2-howto.md");
var PythonMixpanelMd = require("./python-mixpanel.md");
var AndroidMixpanelMd = require("./android-mixpanel.md");
var AndroidAmplitudeMd = require("./android-amplitude.md");
var JavaAndroidHowtoMd = require("./java-android-howto.md");
var ReactNativeSegmentMd = require("./react-native-segment.md");
var IosFirebaseAnalyticsMd = require("./ios-firebase-analytics.md");
var ReactNativeAmplitudeMd = require("./react-native-amplitude.md");
var KotlinConstructorHowtoMd = require("./kotlin-constructor-howto.md");
var AndroidFirebaseAnalyticsMd = require("./android-firebase-analytics.md");
var ReactNativeFirebaseAnalyticsMd = require("./react-native-firebase-analytics.md");

var setup = SetupMd;

var reactNativeFirebase = ReactNativeFirebaseAnalyticsMd;

var jsHowTo = JsHowtoMd;

var reHowTo = ReHowtoMd;

var jsV2HowTo = JsV2HowtoMd;

var gtmJsV2HowTo = GtmJsV2HowtoMd;

var tsHowTo = TsHowtoMd;

var reV2HowTo = ReV2HowtoMd;

var swiftHowTo = SwiftHowtoMd;

var objcHowTo = ObjcHowtoMd;

var kotlinHowTo = KotlinHowtoMd;

var kotlinConstructorHowTo = KotlinConstructorHowtoMd;

var javaHowTo = JavaHowtoMd;

var javaAndroidHowTo = JavaAndroidHowtoMd;

var pythonHowTo = PythonHowtoMd;

var phpHowTo = PhpHowtoMd;

var rubyHowTo = RubyHowtoMd;

var reactNativeAmplitude = ReactNativeAmplitudeMd;

var nodeAmplitude = NodeAmplitudeMd;

var nodeMixpanel = NodeMixpanelMd;

var nodeSegment = NodeSegmentMd;

var nodeIntercom = NodeIntercomMd;

var terms = TermsMd;

var reactNativeSegment = ReactNativeSegmentMd;

var iosSegment = IosSegmentMd;

var iosMixpanel = IosMixpanelMd;

var iosAmplitude = IosAmplitudeMd;

var androidSegment = AndroidSegmentMd;

var androidAmplitude = AndroidAmplitudeMd;

var androidFirebaseAnalytics = AndroidFirebaseAnalyticsMd;

var iosFirebaseAnalytics = IosFirebaseAnalyticsMd;

var androidBraze = AndroidBrazeMd;

var androidMixpanel = AndroidMixpanelMd;

var iosBraze = IosBrazeMd;

var expoAmplitude = ExpoAmplitudeMd;

var expoSegment = ExpoSegmentMd;

var pythonMixpanel = PythonMixpanelMd;

exports.expoAmplitude = expoAmplitude;
exports.expoSegment = expoSegment;
exports.setup = setup;
exports.reactNativeFirebase = reactNativeFirebase;
exports.jsHowTo = jsHowTo;
exports.reHowTo = reHowTo;
exports.jsV2HowTo = jsV2HowTo;
exports.gtmJsV2HowTo = gtmJsV2HowTo;
exports.tsHowTo = tsHowTo;
exports.reV2HowTo = reV2HowTo;
exports.swiftHowTo = swiftHowTo;
exports.objcHowTo = objcHowTo;
exports.reactNativeAmplitude = reactNativeAmplitude;
exports.nodeAmplitude = nodeAmplitude;
exports.nodeMixpanel = nodeMixpanel;
exports.nodeSegment = nodeSegment;
exports.nodeIntercom = nodeIntercom;
exports.terms = terms;
exports.reactNativeSegment = reactNativeSegment;
exports.iosSegment = iosSegment;
exports.iosMixpanel = iosMixpanel;
exports.iosAmplitude = iosAmplitude;
exports.androidSegment = androidSegment;
exports.androidAmplitude = androidAmplitude;
exports.kotlinHowTo = kotlinHowTo;
exports.kotlinConstructorHowTo = kotlinConstructorHowTo;
exports.javaHowTo = javaHowTo;
exports.javaAndroidHowTo = javaAndroidHowTo;
exports.pythonHowTo = pythonHowTo;
exports.phpHowTo = phpHowTo;
exports.rubyHowTo = rubyHowTo;
exports.androidFirebaseAnalytics = androidFirebaseAnalytics;
exports.iosFirebaseAnalytics = iosFirebaseAnalytics;
exports.androidBraze = androidBraze;
exports.androidMixpanel = androidMixpanel;
exports.iosBraze = iosBraze;
exports.pythonMixpanel = pythonMixpanel;
/* setup Not a pure module */
