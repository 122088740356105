// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Case = require("../../bs-case/src/case.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var DiscrepancyContext = require("./DiscrepancyContext.bs.js");

function getDiscrepanciesDiff(mainDiscrepancies, branchDiscrepancies) {
  return Belt_Array.mapU(branchDiscrepancies, (function (branchDiscrepancy) {
                var variant = branchDiscrepancy.NAME;
                if (variant === "SameEventName") {
                  var match = branchDiscrepancy.VAL;
                  var branchL = match[1];
                  var match$1 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "SameEventName";
                          } else {
                            return false;
                          }
                        }));
                  var branchList;
                  if (match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "SameEventName") {
                    var mainDict = Js_dict.fromList(Belt_List.mapU(match$1.VAL[1], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList = Belt_List.keepU(branchL, (function (branchItem) {
                            var match = Js_dict.get(mainDict, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList = branchL;
                  }
                  return {
                          NAME: "SameEventName",
                          VAL: [
                            match[0],
                            branchList
                          ]
                        };
                }
                if (variant === "SamePropertyName") {
                  var match$2 = branchDiscrepancy.VAL;
                  var branchL$1 = match$2[1];
                  var match$3 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "SamePropertyName";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$1;
                  if (match$3 !== undefined && typeof match$3 === "object" && match$3.NAME === "SamePropertyName") {
                    var mainDict$1 = Js_dict.fromList(Belt_List.mapU(match$3.VAL[1], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$1 = Belt_List.keepU(branchL$1, (function (branchItem) {
                            var match = Js_dict.get(mainDict$1, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$1 = branchL$1;
                  }
                  return {
                          NAME: "SamePropertyName",
                          VAL: [
                            match$2[0],
                            branchList$1
                          ]
                        };
                }
                if (variant === "PropertiesWithAnyType") {
                  var match$4 = branchDiscrepancy.VAL;
                  var branchL$2 = match$4[1];
                  var match$5 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "PropertiesWithAnyType";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$2;
                  if (match$5 !== undefined && typeof match$5 === "object" && match$5.NAME === "PropertiesWithAnyType") {
                    var mainDict$2 = Js_dict.fromList(Belt_List.mapU(match$5.VAL[1], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$2 = Belt_List.keepU(branchL$2, (function (branchItem) {
                            var match = Js_dict.get(mainDict$2, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$2 = branchL$2;
                  }
                  return {
                          NAME: "PropertiesWithAnyType",
                          VAL: [
                            match$4[0],
                            branchList$2
                          ]
                        };
                }
                if (variant === "EventsWithoutDescription") {
                  var match$6 = branchDiscrepancy.VAL;
                  var branchL$3 = match$6[1];
                  var match$7 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "EventsWithoutDescription";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$3;
                  if (match$7 !== undefined && typeof match$7 === "object" && match$7.NAME === "EventsWithoutDescription") {
                    var mainDict$3 = Js_dict.fromList(Belt_List.mapU(match$7.VAL[1], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$3 = Belt_List.keepU(branchL$3, (function (branchItem) {
                            var match = Js_dict.get(mainDict$3, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$3 = branchL$3;
                  }
                  return {
                          NAME: "EventsWithoutDescription",
                          VAL: [
                            match$6[0],
                            branchList$3
                          ]
                        };
                }
                if (variant === "EventsWithUnexpectedCase") {
                  var match$8 = branchDiscrepancy.VAL;
                  var branchL$4 = match$8[1];
                  var match$9 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "EventsWithUnexpectedCase";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$4;
                  if (match$9 !== undefined && typeof match$9 === "object" && match$9.NAME === "EventsWithUnexpectedCase") {
                    var mainDict$4 = Js_dict.fromList(Belt_List.mapU(match$9.VAL[1], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$4 = Belt_List.keepU(branchL$4, (function (branchItem) {
                            var match = Js_dict.get(mainDict$4, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$4 = branchL$4;
                  }
                  return {
                          NAME: "EventsWithUnexpectedCase",
                          VAL: [
                            match$8[0],
                            branchList$4
                          ]
                        };
                }
                if (variant === "PropertyConflictOnEvent") {
                  var match$10 = branchDiscrepancy.VAL;
                  var branchL$5 = match$10[1];
                  var match$11 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "PropertyConflictOnEvent";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$5;
                  if (match$11 !== undefined && typeof match$11 === "object" && match$11.NAME === "PropertyConflictOnEvent") {
                    var mainDict$5 = Js_dict.fromList(Belt_List.mapU(match$11.VAL[1], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$5 = Belt_List.keepU(branchL$5, (function (branchItem) {
                            var match = Js_dict.get(mainDict$5, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$5 = branchL$5;
                  }
                  return {
                          NAME: "PropertyConflictOnEvent",
                          VAL: [
                            match$10[0],
                            branchList$5
                          ]
                        };
                }
                if (variant === "PropertiesWithoutDescription") {
                  var match$12 = branchDiscrepancy.VAL;
                  var branchL$6 = match$12[1];
                  var match$13 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "PropertiesWithoutDescription";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$6;
                  if (match$13 !== undefined && typeof match$13 === "object" && match$13.NAME === "PropertiesWithoutDescription") {
                    var mainDict$6 = Js_dict.fromList(Belt_List.mapU(match$13.VAL[1], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$6 = Belt_List.keepU(branchL$6, (function (branchItem) {
                            var match = Js_dict.get(mainDict$6, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$6 = branchL$6;
                  }
                  return {
                          NAME: "PropertiesWithoutDescription",
                          VAL: [
                            match$12[0],
                            branchList$6
                          ]
                        };
                }
                var match$14 = branchDiscrepancy.VAL;
                var branchL$7 = match$14[1];
                var match$15 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                        if (typeof mainDiscrepancy === "object") {
                          return mainDiscrepancy.NAME === "PropertiesWithUnexpectedCase";
                        } else {
                          return false;
                        }
                      }));
                var branchList$7;
                if (match$15 !== undefined && typeof match$15 === "object" && match$15.NAME === "PropertiesWithUnexpectedCase") {
                  var mainDict$7 = Js_dict.fromList(Belt_List.mapU(match$15.VAL[1], (function (param) {
                              return [
                                      param.id,
                                      "Present"
                                    ];
                            })));
                  branchList$7 = Belt_List.keepU(branchL$7, (function (branchItem) {
                          var match = Js_dict.get(mainDict$7, branchItem.id);
                          return match === undefined;
                        }));
                } else {
                  branchList$7 = branchL$7;
                }
                return {
                        NAME: "PropertiesWithUnexpectedCase",
                        VAL: [
                          match$14[0],
                          branchList$7
                        ]
                      };
              }));
}

function cmp(a, b) {
  var variant = a.issue.NAME;
  if (variant === "SameEventName") {
    var match = b.issue;
    if (typeof match === "object" && match.NAME === "SameEventName") {
      return 0;
    } else {
      return -1;
    }
  }
  if (variant === "SamePropertyName") {
    var match$1 = b.issue;
    if (typeof match$1 === "object" && match$1.NAME === "SamePropertyName") {
      return 0;
    } else {
      return -1;
    }
  }
  if (variant === "PropertiesWithAnyType") {
    var match$2 = b.issue;
    if (typeof match$2 === "object" && match$2.NAME === "PropertiesWithAnyType") {
      return 0;
    } else {
      return -1;
    }
  }
  if (variant === "EventsWithoutDescription") {
    var match$3 = b.issue;
    if (typeof match$3 === "object" && match$3.NAME === "EventsWithoutDescription") {
      return 0;
    } else {
      return -1;
    }
  }
  if (variant === "EventsWithUnexpectedCase") {
    var match$4 = b.issue;
    if (typeof match$4 === "object" && match$4.NAME === "EventsWithUnexpectedCase") {
      return 0;
    } else {
      return -1;
    }
  }
  if (variant === "PropertyConflictOnEvent") {
    var match$5 = b.issue;
    if (typeof match$5 === "object" && match$5.NAME === "PropertyConflictOnEvent") {
      return 0;
    } else {
      return -1;
    }
  }
  if (variant === "PropertiesWithoutDescription") {
    var match$6 = b.issue;
    if (typeof match$6 === "object" && match$6.NAME === "PropertiesWithoutDescription") {
      return 0;
    } else {
      return -1;
    }
  }
  var match$7 = b.issue;
  if (typeof match$7 === "object" && match$7.NAME === "PropertiesWithUnexpectedCase") {
    return 0;
  } else {
    return -1;
  }
}

var IssueItemComparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

function propertyNameAlreadyExists(existingPropertyNames, property) {
  var normalizedName = Case.to_("Camel", property.name);
  var match = Js_dict.get(existingPropertyNames, normalizedName);
  if (match !== undefined) {
    if (match && !match.tl) {
      return match.hd !== property.id;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function eventNameAlreadyExists(existingEventNames, $$event) {
  var normalizedName = Case.to_("Camel", $$event.name);
  var match = Js_dict.get(existingEventNames, normalizedName);
  if (match !== undefined) {
    if (match && !match.tl) {
      return match.hd !== $$event.id;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function getIssuesById(existingEventNames, expectedEventCase, existingPropertyNames, expectedPropertyCase, issueCategories) {
  var issuesById = {};
  Belt_Array.forEach(issueCategories, (function (issueCategory) {
          var variant = issueCategory.NAME;
          if (variant === "PropertyConflictOnEvent" || variant === "EventsWithoutDescription" || variant === "SameEventName") {
            var match = issueCategory.VAL;
            if (match[0]) {
              return Belt_Array.forEachU(Belt_List.toArray(match[1]), (function ($$event) {
                            var variant = issueCategory.NAME;
                            var newIssue_issue = variant === "EventsWithoutDescription" ? ({
                                  NAME: "EventsWithoutDescription",
                                  VAL: [
                                    true,
                                    /* [] */0
                                  ]
                                }) : (
                                variant === "PropertyConflictOnEvent" ? ({
                                      NAME: "PropertyConflictOnEvent",
                                      VAL: [
                                        true,
                                        /* [] */0
                                      ]
                                    }) : ({
                                      NAME: "SameEventName",
                                      VAL: [
                                        true,
                                        /* [] */0
                                      ]
                                    })
                              );
                            var newIssue_name = $$event.name;
                            var newIssue_id = $$event.id;
                            var newIssue = {
                              issue: newIssue_issue,
                              name: newIssue_name,
                              id: newIssue_id,
                              type_: "Event",
                              suggestion: undefined,
                              suggestionCallback: undefined
                            };
                            var issues = Js_dict.get(issuesById, $$event.id);
                            if (issues !== undefined) {
                              issuesById[$$event.id] = Belt_Set.toArray(Belt_Set.add(Belt_Set.fromArray(issues, IssueItemComparator), newIssue));
                            } else {
                              issuesById[$$event.id] = [newIssue];
                            }
                            
                          }));
            } else {
              return ;
            }
          }
          if (variant === "PropertiesWithoutDescription" || variant === "PropertiesWithAnyType" || variant === "SamePropertyName") {
            var match$1 = issueCategory.VAL;
            if (match$1[0]) {
              return Belt_Array.forEachU(Belt_List.toArray(match$1[1]), (function (property) {
                            var variant = issueCategory.NAME;
                            var newIssue_issue = variant === "PropertiesWithAnyType" ? ({
                                  NAME: "PropertiesWithAnyType",
                                  VAL: [
                                    true,
                                    /* [] */0
                                  ]
                                }) : (
                                variant === "PropertiesWithoutDescription" ? ({
                                      NAME: "PropertiesWithoutDescription",
                                      VAL: [
                                        true,
                                        /* [] */0
                                      ]
                                    }) : ({
                                      NAME: "SamePropertyName",
                                      VAL: [
                                        true,
                                        /* [] */0
                                      ]
                                    })
                              );
                            var newIssue_name = property.name;
                            var newIssue_id = property.id;
                            var newIssue = {
                              issue: newIssue_issue,
                              name: newIssue_name,
                              id: newIssue_id,
                              type_: "Property",
                              suggestion: undefined,
                              suggestionCallback: undefined
                            };
                            var issues = Js_dict.get(issuesById, property.id);
                            if (issues !== undefined) {
                              issuesById[property.id] = Belt_Set.toArray(Belt_Set.add(Belt_Set.fromArray(issues, IssueItemComparator), newIssue));
                            } else {
                              issuesById[property.id] = [newIssue];
                            }
                            
                          }));
            } else {
              return ;
            }
          }
          if (variant === "EventsWithUnexpectedCase") {
            var match$2 = issueCategory.VAL;
            if (match$2[0]) {
              return Belt_Array.forEachU(Belt_List.toArray(match$2[1]), (function ($$event) {
                            var mayUpdateNameAutomatically = !eventNameAlreadyExists(existingEventNames, $$event);
                            var newIssue_issue = {
                              NAME: "EventsWithUnexpectedCase",
                              VAL: [
                                true,
                                /* [] */0
                              ]
                            };
                            var newIssue_name = $$event.name;
                            var newIssue_id = $$event.id;
                            var newIssue_suggestion = mayUpdateNameAutomatically ? "Change to \"" + Case.to_(expectedEventCase, $$event.name) + "\"" : "An event with a name in the expected casing already exists";
                            var newIssue_suggestionCallback = mayUpdateNameAutomatically ? (function (sendActions, addToast) {
                                  var action_1 = [
                                    $$event.id,
                                    Case.to_(expectedEventCase, $$event.name)
                                  ];
                                  var action = {
                                    NAME: "UpdateEventName",
                                    VAL: action_1
                                  };
                                  var context = {
                                    eventId: $$event.id,
                                    eventQuery: $$event.id
                                  };
                                  return Curry.app(sendActions, [
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              (function (_branch) {
                                                  return Curry._1(addToast, {
                                                              message: "Event name changed to \"" + Case.to_(expectedEventCase, $$event.name) + "\"",
                                                              toastType: /* Success */0,
                                                              action: {
                                                                message: "Open",
                                                                toastCallback: (function (param) {
                                                                    return Router.Schema.pushDrawerItem(undefined, {
                                                                                NAME: "event",
                                                                                VAL: [
                                                                                  $$event.id,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  false
                                                                                ]
                                                                              });
                                                                  })
                                                              }
                                                            });
                                                }),
                                              (function (param) {
                                                  return Curry._1(addToast, {
                                                              message: "Unable to change name",
                                                              toastType: /* Error */1
                                                            });
                                                }),
                                              [[
                                                  action,
                                                  context
                                                ]]
                                            ]);
                                }) : undefined;
                            var newIssue = {
                              issue: newIssue_issue,
                              name: newIssue_name,
                              id: newIssue_id,
                              type_: "Event",
                              suggestion: newIssue_suggestion,
                              suggestionCallback: newIssue_suggestionCallback
                            };
                            var issues = Js_dict.get(issuesById, $$event.id);
                            if (issues !== undefined) {
                              issuesById[$$event.id] = Belt_Set.toArray(Belt_Set.add(Belt_Set.fromArray(issues, IssueItemComparator), newIssue));
                            } else {
                              issuesById[$$event.id] = [newIssue];
                            }
                            
                          }));
            } else {
              return ;
            }
          }
          var match$3 = issueCategory.VAL;
          if (match$3[0]) {
            return Belt_Array.forEachU(Belt_List.toArray(match$3[1]), (function (property) {
                          var mayUpdateNameAutomatically = !propertyNameAlreadyExists(existingPropertyNames, property);
                          var newIssue_issue = {
                            NAME: "PropertiesWithUnexpectedCase",
                            VAL: [
                              true,
                              /* [] */0
                            ]
                          };
                          var newIssue_name = property.name;
                          var newIssue_id = property.id;
                          var newIssue_suggestion = mayUpdateNameAutomatically ? "Change to \"" + Case.to_(expectedEventCase, property.name) + "\"" : "A property with a name in the expected casing already exists";
                          var newIssue_suggestionCallback = mayUpdateNameAutomatically ? (function (sendActions, addToast) {
                                var action_1 = [
                                  property.id,
                                  Case.to_(expectedPropertyCase, property.name)
                                ];
                                var action = {
                                  NAME: "UpdatePropertyName",
                                  VAL: action_1
                                };
                                var context = {
                                  propertyId: property.id,
                                  propertyQuery: property.id
                                };
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            (function (_branch) {
                                                return Curry._1(addToast, {
                                                            message: "Property name changed to \"" + Case.to_(expectedEventCase, property.name) + "\"",
                                                            toastType: /* Success */0,
                                                            action: {
                                                              message: "Open",
                                                              toastCallback: (function (param) {
                                                                  return Router.Schema.pushDrawerItem(undefined, {
                                                                              NAME: "property",
                                                                              VAL: [
                                                                                property.id,
                                                                                undefined
                                                                              ]
                                                                            });
                                                                })
                                                            }
                                                          });
                                              }),
                                            (function (param) {
                                                return Curry._1(addToast, {
                                                            message: "Unable to change name",
                                                            toastType: /* Error */1
                                                          });
                                              }),
                                            [[
                                                action,
                                                context
                                              ]]
                                          ]);
                              }) : undefined;
                          var newIssue = {
                            issue: newIssue_issue,
                            name: newIssue_name,
                            id: newIssue_id,
                            type_: "Property",
                            suggestion: newIssue_suggestion,
                            suggestionCallback: newIssue_suggestionCallback
                          };
                          var issues = Js_dict.get(issuesById, property.id);
                          if (issues !== undefined) {
                            issuesById[property.id] = Belt_Set.toArray(Belt_Set.add(Belt_Set.fromArray(issues, IssueItemComparator), newIssue));
                          } else {
                            issuesById[property.id] = [newIssue];
                          }
                          
                        }));
          }
          
        }));
  return issuesById;
}

var initialValue_branchAuditChecks = [];

var initialValue_issuesById = {};

var initialValue = {
  branchAuditChecks: initialValue_branchAuditChecks,
  branchAuditWarningCount: 0,
  issuesById: initialValue_issuesById
};

var context = React.createContext(initialValue);

var provider = context.Provider;

function BranchDiscrepancies$Provider(Props) {
  var mainModelToCompareTo = Props.mainModelToCompareTo;
  var branchModelToCompareTo = Props.branchModelToCompareTo;
  var children = Props.children;
  var match = React.useContext(DiscrepancyContext.context);
  var expectedPropertyCase = match.expectedPropertyCase;
  var expectedEventCase = match.expectedEventCase;
  var config = match.config;
  var match$1 = React.useMemo((function () {
          return DiscrepancyContext.getDiscrepancies(config, branchModelToCompareTo);
        }), [
        branchModelToCompareTo,
        config
      ]);
  var branchDiscrepancies = match$1.discrepancies;
  var match$2 = React.useMemo((function () {
          return DiscrepancyContext.getDiscrepancies(config, mainModelToCompareTo);
        }), [
        mainModelToCompareTo,
        config
      ]);
  var mainDiscrepancies = match$2.discrepancies;
  var branchAuditChecks = React.useMemo((function () {
          return getDiscrepanciesDiff(mainDiscrepancies, branchDiscrepancies);
        }), [
        mainDiscrepancies,
        branchDiscrepancies
      ]);
  var branchAuditChecksCount = React.useMemo((function () {
          return DiscrepancyContext.getTotalWarningsCount(branchAuditChecks);
        }), [branchAuditChecks]);
  var existingEventNames = React.useMemo((function () {
          var dict = {};
          Belt_List.forEach(StateUtils.getEventNamespaceWithIds(branchModelToCompareTo.events), (function (param) {
                  var id = param[1];
                  var normalizedName = Case.to_("Camel", param[0]);
                  var ids = Js_dict.get(dict, normalizedName);
                  if (ids !== undefined) {
                    dict[normalizedName] = Belt_List.concat(ids, {
                          hd: id,
                          tl: /* [] */0
                        });
                  } else {
                    dict[normalizedName] = {
                      hd: id,
                      tl: /* [] */0
                    };
                  }
                  
                }));
          return dict;
        }), [branchModelToCompareTo.events]);
  var existingPropertyNames = React.useMemo((function () {
          var dict = {};
          Belt_List.forEach(StateUtils.getPropertyNamespaceWithIds(branchModelToCompareTo), (function (param) {
                  var id = param[1];
                  var normalizedName = Case.to_("Camel", param[0]);
                  var ids = Js_dict.get(dict, normalizedName);
                  if (ids !== undefined) {
                    dict[normalizedName] = Belt_List.concat(ids, {
                          hd: id,
                          tl: /* [] */0
                        });
                  } else {
                    dict[normalizedName] = {
                      hd: id,
                      tl: /* [] */0
                    };
                  }
                  
                }));
          return dict;
        }), [branchModelToCompareTo]);
  var issuesById = React.useMemo((function () {
          return getIssuesById(existingEventNames, expectedEventCase, existingPropertyNames, expectedPropertyCase, branchAuditChecks);
        }), [branchAuditChecks]);
  return React.createElement(provider, {
              value: {
                branchAuditChecks: branchAuditChecks,
                branchAuditWarningCount: branchAuditChecksCount,
                issuesById: issuesById
              },
              children: children
            });
}

var Provider = {
  propertyNameAlreadyExists: propertyNameAlreadyExists,
  eventNameAlreadyExists: eventNameAlreadyExists,
  getIssuesById: getIssuesById,
  initialValue: initialValue,
  context: context,
  provider: provider,
  make: BranchDiscrepancies$Provider
};

function use(param) {
  return React.useContext(context);
}

exports.getDiscrepanciesDiff = getDiscrepanciesDiff;
exports.IssueItemComparator = IssueItemComparator;
exports.Provider = Provider;
exports.use = use;
/* IssueItemComparator Not a pure module */
