// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var DiffEvent = require("./DiffEvent.bs.js");
var BranchAudit = require("./BranchAudit.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var DiffDetails = require("./DiffDetails.bs.js");
var RouterStore = require("./RouterStore.bs.js");
var BranchHeader = require("./BranchHeader.bs.js");
var DiffComments = require("./DiffComments.bs.js");
var DiffViewUtils = require("./DiffViewUtils.bs.js");
var ViewerContext = require("./ViewerContext.bs.js");
var AppFeatureFlag = require("./AppFeatureFlag.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var DiffViewActionBox = require("./DiffViewActionBox.bs.js");
var ObjectActivityLog = require("./ObjectActivityLog.bs.js");
var PullMasterWarning = require("./PullMasterWarning.bs.js");

function DiffView(Props) {
  var branchStatus = Props.branchStatus;
  var branchName = Props.branchName;
  var mergeWarnings = Props.mergeWarnings;
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var schema = Props.schema;
  var branchId = Props.branchId;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var masterSinceOpenedDiff = Props.masterSinceOpenedDiff;
  var latestMasterAction = Props.latestMasterAction;
  var actions = Props.actions;
  var mergeConflicts = Props.mergeConflicts;
  var branchOpenAction = Props.branchOpenAction;
  var role = Props.role;
  var actionDiff = Props.actionDiff;
  var openBranches = Props.openBranches;
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var user = ViewerContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var hasDiffDetails = AppFeatureFlag.useFeatureFlag("DiffDetails");
  var goToCommentId;
  if (typeof schemaRoute === "object" && schemaRoute.NAME === "diff") {
    var match = schemaRoute.VAL;
    goToCommentId = typeof match === "object" && match.NAME === "comment" ? match.VAL : undefined;
  } else {
    goToCommentId = undefined;
  }
  var branchCreatorId = branchOpenAction.createdBy;
  var tmp;
  var exit = 0;
  if (mergeWarnings) {
    if (typeof branchStatus === "number") {
      exit = 1;
    } else {
      switch (branchStatus.TAG | 0) {
        case /* Merged */1 :
        case /* Closed */2 :
            tmp = null;
            break;
        default:
          exit = 1;
      }
    }
  } else {
    tmp = null;
  }
  if (exit === 1) {
    var tmp$1 = {
      branchStatus: branchStatus,
      fromModel: masterModel,
      toModel: branchModel,
      actions: mergeWarnings,
      schemaId: schema.id,
      branchId: branchId,
      events: branchModel.events
    };
    if (goToCommentId !== undefined) {
      tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.padding(Css.px(15)),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.orange),
                  tl: {
                    hd: Css.borderRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.width(Css.pct(100)),
                      tl: {
                        hd: Css.maxWidth({
                              NAME: "add",
                              VAL: [
                                DiffEvent.maxWidthPx,
                                Css.px((DiffComments.commentsWidth + 20 | 0) + 20 | 0)
                              ]
                            }),
                        tl: {
                          hd: Css.marginTop(Css.px(20)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              })
        }, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.color(Styles.Color.orangeSecondary),
                    tl: {
                      hd: Css.fontWeight(Styles.FontWeight.semi),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.large),
                        tl: /* [] */0
                      }
                    }
                  })
            }, "Discarded Changes"), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.fontSize(Styles.FontSize.regular),
                    tl: {
                      hd: Css.fontWeight(Styles.FontWeight.regular),
                      tl: {
                        hd: Css.color(Styles.Color.grey90),
                        tl: {
                          hd: Css.padding2(Css.px(10), Css.px(0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, "Items you made changes to on this branch have been archived on the main branch. These changes will be discarded unless you unarchive the items on main before merging this branch."), React.createElement(DiffViewUtils.RenderObjectsDiff.make, tmp$1));
  }
  var tmp$2;
  if (typeof branchStatus === "number") {
    tmp$2 = null;
  } else {
    switch (branchStatus.TAG | 0) {
      case /* Merged */1 :
      case /* Closed */2 :
          tmp$2 = null;
          break;
      default:
        tmp$2 = React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.maxWidth(DiffEvent.maxWidthPx),
                    tl: /* [] */0
                  })
            }, React.createElement(PullMasterWarning.make, {
                  schemaId: schema.id,
                  masterModel: masterModel,
                  masterModelBeforeBranch: masterModelBeforeBranch,
                  masterSinceOpenedDiff: masterSinceOpenedDiff,
                  latestMasterAction: latestMasterAction,
                  branchId: branchId,
                  branchStatus: branchStatus,
                  role: role,
                  events: branchModel.events
                }));
    }
  }
  var tmp$3;
  tmp$3 = typeof branchStatus === "number" || branchStatus.TAG !== /* Merged */1 ? masterModel : masterModelBeforeBranch;
  var tmp$4 = {
    branchStatus: branchStatus,
    fromModel: tmp$3,
    toModel: branchModel,
    actions: actions,
    schemaId: schema.id,
    branchId: branchId,
    events: branchModel.events
  };
  if (goToCommentId !== undefined) {
    tmp$4.goToCommentId = Caml_option.valFromOption(goToCommentId);
  }
  var tmp$5;
  if (actions) {
    var tmp$6;
    var exit$1 = 0;
    if (mergeConflicts && (typeof branchStatus === "number" || branchStatus.TAG !== /* Merged */1)) {
      exit$1 = 1;
    } else {
      tmp$6 = null;
    }
    if (exit$1 === 1) {
      var eventNames = Belt_List.keepMap(mergeConflicts, (function (action) {
              var match = action[0];
              if (typeof match !== "object") {
                return ;
              }
              if (match.NAME !== "Event") {
                return ;
              }
              var match$1 = action[1];
              if (typeof match$1 === "object" && match$1.NAME === "AddEvent") {
                return match$1.VAL[1];
              }
              
            }));
      var propertyNames = Belt_List.keepMap(mergeConflicts, (function (action) {
              var match = action[0];
              if (typeof match !== "object") {
                return ;
              }
              if (match.NAME !== "Property") {
                return ;
              }
              var match$1 = action[1];
              if (typeof match$1 === "object" && match$1.NAME === "CreateProperty") {
                return match$1.VAL[1];
              }
              
            }));
      var propertyGroupNames = Belt_List.keepMap(mergeConflicts, (function (action) {
              var match = action[0];
              if (typeof match !== "object") {
                return ;
              }
              if (match.NAME !== "PropertyGroup") {
                return ;
              }
              var match$1 = action[1];
              if (typeof match$1 === "object" && match$1.NAME === "CreatePropertyGroup") {
                return match$1.VAL[1];
              }
              
            }));
      tmp$6 = React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.padding(Css.px(20)),
                  tl: {
                    hd: Css.margin2(Css.px(15), Css.px(0)),
                    tl: {
                      hd: Css.width(Css.pct(100)),
                      tl: {
                        hd: Css.maxWidth(DiffEvent.maxWidthPx),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.border(Css.px(1), Css.solid, Styles.Color.grapeError),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.grapeErrorBg),
                              tl: {
                                hd: Css.color(Styles.Color.grapeError),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                })
          }, React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.fontSize(Styles.FontSize.medium),
                      tl: {
                        hd: Css.fontWeight(Styles.FontWeight.bold),
                        tl: {
                          hd: Css.paddingBottom(Css.px(10)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, "Merge Conflict"), React.createElement("div", undefined, eventNames ? (
                  eventNames.tl ? React.createElement("div", undefined, "Events with the names \"" + Belt_List.toArray(eventNames).join(", ") + "\" already exists on the main branch. Please rename or archive the events on this branch before merging.") : React.createElement("div", undefined, "An event with the name \"" + eventNames.hd + "\" already exists on the main branch. Please rename or archive the event on this branch before merging.")
                ) : null, propertyNames ? (
                  propertyNames.tl ? React.createElement("div", undefined, "Properties with the names \"" + Belt_List.toArray(propertyNames).join(", ") + "\" already exists on the main branch. Please rename or archive the properties on this branch before merging.") : React.createElement("div", undefined, "A property with the name \"" + propertyNames.hd + "\" already exists on the main branch. Please rename or archive the property on this branch before merging.")
                ) : null, propertyGroupNames ? (
                  propertyGroupNames.tl ? React.createElement("div", undefined, "Property bundles with the names \"" + Belt_List.toArray(propertyGroupNames).join(", ") + "\" already exists on the main branch. Please rename or archive the property bundles on this branch before merging.") : React.createElement("div", undefined, "A property bundle with the name \"" + propertyGroupNames.hd + "\" already exists on the main branch. Please rename or archive the property bundle on this branch before merging.")
                ) : null));
    }
    var tmp$7;
    tmp$7 = typeof branchStatus === "number" || branchStatus.TAG !== /* Merged */1 ? masterModel : masterModelBeforeBranch;
    var tmp$8 = {
      schemaId: schema.id,
      branchId: branchId,
      threadId: branchId,
      itemType: "Branch",
      itemName: branchName,
      events: branchModel.events
    };
    if (goToCommentId !== undefined) {
      tmp$8.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp$5 = React.createElement("div", undefined, tmp$6, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingTop(Css.px(16)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(32)),
                      tl: {
                        hd: Css.paddingRight(Css.px(8)),
                        tl: {
                          hd: Css.maxWidth(DiffEvent.maxWidthPx),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(BranchAudit.make, {
                  fromModel: tmp$7,
                  toModel: branchModel,
                  branchModelWithPulledMaster: branchModelWithPulledMaster,
                  actions: actions,
                  branchStatus: branchStatus,
                  forceBranchAudit: workspace.settings.forceBranchAudit,
                  user: user
                })), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.margin2(Css.px(20), Css.px(0)),
                        tl: {
                          hd: Css.zIndex(1),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(DiffEvent.maxWidthPx),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(DiffViewActionBox.make, {
                      actionDiff: actionDiff,
                      actions: actions,
                      branchId: branchId,
                      branchName: branchName,
                      branchStatus: branchStatus,
                      currentViewerOpenedBranch: user.id === branchCreatorId,
                      mergeConflicts: mergeConflicts,
                      role: role,
                      schema: schema,
                      branchModel: branchModel,
                      branchModelWithPulledMaster: branchModelWithPulledMaster,
                      masterModel: masterModel,
                      masterModelBeforeBranch: masterModelBeforeBranch
                    })), React.createElement(DiffComments.make, tmp$8)), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingTop(Css.px(16)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(32)),
                      tl: {
                        hd: Css.maxWidth(DiffEvent.maxWidthPx),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(ObjectActivityLog.make, {
                  object_: {
                    NAME: "Branch",
                    VAL: [
                      branchId,
                      branchName
                    ]
                  },
                  objectId: branchId,
                  model: branchModel,
                  schema: schema,
                  events: branchModel.events,
                  currentBranch: {
                    NAME: "Branch",
                    VAL: branchId
                  },
                  showComments: false,
                  openBranches: openBranches
                })));
  } else {
    tmp$5 = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.fontWeight(Styles.FontWeight.semi),
                tl: {
                  hd: Css.color(Styles.Color.grey70),
                  tl: {
                    hd: Css.padding(Css.px(25)),
                    tl: /* [] */0
                  }
                }
              })
        }, "No changes made.");
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.justifyContent("center"),
                      tl: {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.marginTop(Css.px(BranchHeader.branchHeaderHeight)),
                          tl: {
                            hd: Css.overflow("auto"),
                            tl: {
                              hd: Css.height({
                                    NAME: "substract",
                                    VAL: [
                                      Css.vh(100.0),
                                      Css.px(BranchHeader.branchHeaderHeight)
                                    ]
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexGrow(1.0),
                        tl: {
                          hd: Css.maxWidth(Css.pct(100.0)),
                          tl: {
                            hd: Css.paddingRight(Css.px(20)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(20)),
                              tl: {
                                hd: Css.margin4(Css.px(0), "auto", Css.px(0), "auto"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }),
                  id: "branch-tour-changes"
                }, hasDiffDetails ? React.createElement(DiffDetails.make, {
                        schemaId: schema.id,
                        branchModel: branchModel,
                        masterModel: masterModel,
                        actions: actions,
                        branchId: branchId
                      }) : null, tmp, tmp$2, React.createElement(DiffViewUtils.RenderObjectsDiff.make, tmp$4), tmp$5));
}

var make = DiffView;

exports.make = make;
/* Css Not a pure module */
