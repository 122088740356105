// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var $$Number = require("./Number.bs.js");
var Styles = require("./styles.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var ModelStore = require("./ModelStore.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Collapsible = require("./Collapsible.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var AppVersionsTypes = require("../../shared/models/AppVersionsTypes.bs.js");
var InspectorIssuesStore = require("./inspector/InspectorIssuesStore.bs.js");
var TrackingPlanMappedModel = require("../../model/src/TrackingPlanMappedModel.bs.js");

function InspectorIssuesSourceBreakdown$CollapsibleWrapper(Props) {
  var withWrapper = Props.withWrapper;
  var collapsed = Props.collapsed;
  var children = Props.children;
  if (withWrapper) {
    return React.createElement(Collapsible.make, {
                collapsed: collapsed,
                collapsedHeight: 104,
                withFade: false,
                children: children
              });
  } else {
    return children;
  }
}

var table = Curry._1(Css.style, {
      hd: Css.display(Css.grid),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: Css.px(124),
              tl: {
                hd: Css.px(150),
                tl: {
                  hd: Css.fr(1.0),
                  tl: {
                    hd: Css.px(100),
                    tl: {
                      hd: Css.px(60),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: {
          hd: Css.gap(Css.px(4)),
          tl: {
            hd: Css.padding2(Css.px(8), Css.px(16)),
            tl: {
              hd: Css.border(Css.px(1), Css.solid, Styles.Color.grey30),
              tl: /* [] */0
            }
          }
        }
      }
    });

var header = Curry._1(Css.merge, {
      hd: table,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.color(Styles.Color.grey70),
              tl: {
                hd: Css.borderTopLeftRadius(Css.px(6)),
                tl: {
                  hd: Css.borderTopRightRadius(Css.px(6)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.grey10),
                    tl: {
                      hd: Css.position("sticky"),
                      tl: {
                        hd: Css.top(Css.px(0)),
                        tl: {
                          hd: Css.zIndex(1),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var row = Curry._1(Css.merge, {
      hd: table,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.color(Styles.Color.grey80),
              tl: {
                hd: Css.borderTopWidth(Css.px(0)),
                tl: {
                  hd: Css.lastChild({
                        hd: Css.borderBottomLeftRadius(Css.px(6)),
                        tl: {
                          hd: Css.borderBottomRightRadius(Css.px(6)),
                          tl: /* [] */0
                        }
                      }),
                  tl: {
                    hd: Css.nthChild("odd", {
                          hd: Css.backgroundColor(Styles.Color.grey10),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var pill = Curry._1(Css.style, {
      hd: Css.width("fitContent"),
      tl: {
        hd: Css.height(Css.px(24)),
        tl: {
          hd: Css.padding2(Css.px(4), Css.px(6)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.border(Css.px(1), Css.solid, Styles.Color.grey40),
              tl: {
                hd: Css.borderRadius(Css.px(12)),
                tl: {
                  hd: Css.maxWidth(Css.px(110)),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.gap(Css.px(4)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function multiRow(align) {
  return Curry._1(Css.style, {
              hd: Css.justifySelf(align),
              tl: {
                hd: Css.display(Css.grid),
                tl: {
                  hd: Css.gridTemplateRows({
                        hd: {
                          NAME: "repeat",
                          VAL: [
                            "autoFill",
                            Css.fr(1.0)
                          ]
                        },
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.gap(Css.px(16)),
                    tl: /* [] */0
                  }
                }
              }
            });
}

function InspectorIssuesSourceBreakdown$Row(Props) {
  var appVersions = Props.appVersions;
  var sourceId = Props.sourceId;
  var sourceBreakdown = Props.sourceBreakdown;
  var mappedSources = ModelStore.useMappedSources(undefined);
  var n = sourceBreakdown.releases.length;
  var showMore = n > 3 ? n - 3 | 0 : undefined;
  var match = Hooks.useDisclosure(showMore === undefined);
  var actions = match[1];
  var opened = match[0];
  var sourceName = Belt_Option.getWithDefault(TrackingPlanMappedModel.Sources.getNameById(mappedSources, sourceId), "Unknown source");
  return React.createElement("div", {
              key: sourceId,
              className: row
            }, React.createElement("div", {
                  className: pill
                }, React.createElement($$Text.make, {
                      size: "Tiny",
                      lines: 1,
                      title: sourceName,
                      children: sourceName
                    })), React.createElement(InspectorIssuesSourceBreakdown$CollapsibleWrapper, {
                  withWrapper: Belt_Option.isSome(showMore),
                  collapsed: !opened,
                  children: React.createElement("div", {
                        className: multiRow("start")
                      }, Belt_Array.mapU(sourceBreakdown.releases, (function (param) {
                              var release = param[0];
                              return React.createElement("span", {
                                          key: release,
                                          className: pill,
                                          title: release
                                        }, React.createElement($$Text.make, {
                                              element: "Span",
                                              size: "Tiny",
                                              lines: 1,
                                              children: release
                                            }), Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Belt_MapString.get(appVersions, sourceId), (function (releases) {
                                                        return Belt_Array.get(releases, 0);
                                                      })), (function (param) {
                                                    if (AppVersionsTypes.print(param.appVersion) === release) {
                                                      return Caml_option.some(React.createElement("span", {
                                                                      className: Curry._1(Css.style, {
                                                                            hd: Css.width("minContent"),
                                                                            tl: /* [] */0
                                                                          })
                                                                    }, React.createElement($$Text.make, {
                                                                          size: "Tiny",
                                                                          lines: 1,
                                                                          children: "(latest)"
                                                                        })));
                                                    }
                                                    
                                                  })), null));
                            })))
                }), React.createElement(InspectorIssuesSourceBreakdown$CollapsibleWrapper, {
                  withWrapper: Belt_Option.isSome(showMore),
                  collapsed: !opened,
                  children: React.createElement("div", {
                        className: multiRow("start")
                      }, Belt_Array.mapU(sourceBreakdown.releases, (function (param) {
                              var release = param[0];
                              return React.createElement("div", {
                                          key: release,
                                          className: Curry._1(Css.style, {
                                                hd: Css.height(Css.px(24)),
                                                tl: {
                                                  hd: Css.padding2(Css.px(4), Css.px(0)),
                                                  tl: {
                                                    hd: Css.unsafe("textWrap", "nowrap"),
                                                    tl: {
                                                      hd: Css.overflowX("visible"),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              })
                                        }, React.createElement($$Text.make, {
                                              size: "Small",
                                              children: Belt_Option.mapWithDefault(Belt_Option.flatMapU(Belt_MapString.get(appVersions, sourceId), (function (versions) {
                                                          return Belt_Array.getBy(versions, (function (v) {
                                                                        return AppVersionsTypes.print(v.appVersion) === release;
                                                                      }));
                                                        })), "-", (function (v) {
                                                      return v.firstSeen.toLocaleString();
                                                    }))
                                            }));
                            })))
                }), React.createElement(InspectorIssuesSourceBreakdown$CollapsibleWrapper, {
                  withWrapper: Belt_Option.isSome(showMore),
                  collapsed: !opened,
                  children: React.createElement("div", {
                        className: multiRow("end_")
                      }, Belt_Array.mapU(sourceBreakdown.releases, (function (param) {
                              return React.createElement("div", {
                                          key: param[0],
                                          className: Curry._1(Css.style, {
                                                hd: Css.height(Css.px(24)),
                                                tl: {
                                                  hd: Css.padding2(Css.px(4), Css.px(0)),
                                                  tl: {
                                                    hd: Css.justifySelf("end_"),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              })
                                        }, React.createElement($$Text.make, {
                                              size: "Small",
                                              children: $$Number.toLocaleString(param[1])
                                            }));
                            })))
                }), React.createElement(InspectorIssuesSourceBreakdown$CollapsibleWrapper, {
                  withWrapper: Belt_Option.isSome(showMore),
                  collapsed: !opened,
                  children: React.createElement("div", {
                        className: multiRow("end_")
                      }, Belt_Array.mapU(sourceBreakdown.releases, (function (param) {
                              var eventCount = param[1];
                              var match = sourceBreakdown.totalEventCount;
                              return React.createElement("div", {
                                          key: param[0],
                                          className: Curry._1(Css.style, {
                                                hd: Css.height(Css.px(24)),
                                                tl: {
                                                  hd: Css.padding2(Css.px(4), Css.px(0)),
                                                  tl: {
                                                    hd: Css.justifySelf("end_"),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              })
                                        }, React.createElement($$Text.make, {
                                              size: "Small",
                                              children: eventCount !== 0 && match !== 0 ? $$Number.getRoughPercentageString(100.0 * eventCount / match) + "%" : "0%"
                                            }));
                            })))
                }), showMore !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("button", {
                        className: Curry._1(Css.merge, {
                              hd: Curry._1(Css.style, Styles.buttonReset),
                              tl: {
                                hd: Curry._1(Css.style, {
                                      hd: Css.unsafe("gridColumn", "2"),
                                      tl: {
                                        hd: Css.textAlign("left"),
                                        tl: {
                                          hd: Css.marginTop(Css.px(16)),
                                          tl: {
                                            hd: Css.width("fitContent"),
                                            tl: {
                                              hd: Css.color(Styles.Color.grey70),
                                              tl: {
                                                hd: Css.textDecoration("underline"),
                                                tl: {
                                                  hd: Css.unsafe("textUnderlineOffset", "4px"),
                                                  tl: {
                                                    hd: Css.unsafe("textDecorationColor", Styles.Color.toString(Styles.Color.grey50)),
                                                    tl: {
                                                      hd: Css.transition({
                                                            NAME: "ms",
                                                            VAL: Styles.Duration.$$short
                                                          }, undefined, undefined, "all"),
                                                      tl: {
                                                        hd: Css.hover({
                                                              hd: Css.color(Styles.Color.grey80),
                                                              tl: {
                                                                hd: Css.unsafe("textDecorationColor", Styles.Color.toString(Styles.Color.grey80)),
                                                                tl: /* [] */0
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        onClick: (function (param) {
                            return Curry._1(actions.toggle, undefined);
                          })
                      }, React.createElement($$Text.make, {
                            size: "Tiny",
                            weight: "Semi",
                            children: opened ? "Show less" : "Show " + $$Number.toLocaleString(showMore) + " more"
                          }))) : null);
}

function InspectorIssuesSourceBreakdown(Props) {
  var report = Props.report;
  var match = InspectorIssuesStore.useAppVersionsInSyncWithIssues(undefined, undefined);
  var appVersions = match[0];
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.color(Styles.Color.grey80),
                    tl: {
                      hd: Css.maxHeight({
                            NAME: "substract",
                            VAL: [
                              Css.vh(80.0),
                              Css.px(180)
                            ]
                          }),
                      tl: {
                        hd: Css.overflow("auto"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: header
                }, React.createElement("div", undefined, React.createElement($$Text.make, {
                          size: "Tiny",
                          weight: "Semi",
                          children: "Source"
                        })), React.createElement("div", undefined, React.createElement($$Text.make, {
                          size: "Tiny",
                          weight: "Semi",
                          children: "Release"
                        })), React.createElement("div", undefined, React.createElement($$Text.make, {
                          size: "Tiny",
                          weight: "Semi",
                          children: "First seen"
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.justifySelf("end_"),
                            tl: /* [] */0
                          })
                    }, React.createElement($$Text.make, {
                          size: "Tiny",
                          weight: "Semi",
                          children: "Event vol"
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.justifySelf("end_"),
                            tl: /* [] */0
                          })
                    }, React.createElement($$Text.make, {
                          size: "Tiny",
                          weight: "Semi",
                          children: "Total vol %"
                        }))), Belt_Array.mapU(Belt_MapString.toArray(report.breakdown), (function (param) {
                    var sourceId = param[0];
                    return React.createElement(InspectorIssuesSourceBreakdown$Row, {
                                appVersions: appVersions,
                                sourceId: sourceId,
                                sourceBreakdown: param[1],
                                key: sourceId
                              });
                  })));
}

var make = InspectorIssuesSourceBreakdown;

exports.make = make;
/* table Not a pure module */
