// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Icon = require("./Icon.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Models = require("./Models.bs.js");
var Parser = require("../../model/src/Parser.bs.js");
var Router = require("./Router.bs.js");
var Sentry = require("./externals/Sentry.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Actions = require("./actions.bs.js");
var Changes = require("./Changes.bs.js");
var Mantine = require("./Mantine.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var AvoModel = require("./avoModel.bs.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var DiffUtils = require("./DiffUtils.bs.js");
var Timestamp = require("./Timestamp.bs.js");
var ActionDiff = require("../../shared/ActionDiff.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var StateUtils = require("./stateUtils.bs.js");
var TextButton = require("./TextButton.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ContextMenu = require("./ContextMenu.bs.js");
var ProductTour = require("./ProductTour.bs.js");
var IconEllipsis = require("./IconEllipsis.bs.js");
var IconInfinity = require("./IconInfinity.bs.js");
var ProfilePhoto = require("./ProfilePhoto.bs.js");
var FirebaseUtils = require("./firebaseUtils.bs.js");
var IconCheckmark = require("./IconCheckmark.bs.js");
var IconLightning = require("./IconLightning.bs.js");
var ViewerContext = require("./ViewerContext.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var AppVersionsStore = require("./AppVersionsStore.bs.js");
var AppVersionsTypes = require("../../shared/models/AppVersionsTypes.bs.js");
var FirebaseFetchers = require("./FirebaseFetchers.bs.js");
var IntegrationUtils = require("../../api/functions/src/IntegrationUtils.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");
var DiscrepancyContext = require("./DiscrepancyContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var BranchDiscrepancies = require("./BranchDiscrepancies.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var ImplementationStatusContext = require("./ImplementationStatusContext.bs.js");
var FetchImplementationStatusHooks = require("./implementationStatus/FetchImplementationStatusHooks.bs.js");
var InspectorVersionValidationIntentHelpers = require("./InspectorVersionValidationIntentHelpers.bs.js");

function sendBranchMergedAnalytics(schemaId, branchId, schemaGroup, actions, actionDiff, autoPublishIntegrations, model, completedEventsCounter, totalEventsCounter, completedSourcesCounter, totalSourcesCounter, numSourcesUpdatedOnBranchConnectedToAvoFunctions, numSourcesUpdatedOnBranchConnectedToInspector, numSourcesUpdatedOnBranchConnectedToImplementationStatus, eventInstancesImplementedRatioPerSource, workspace, numApprovals, numNewSchemaIssuesOnBranch, numSchemaIssues, numSchemaIssuesDelta, workspaceNumIssues, role, mergeWithoutApproval) {
  var proportionOfUpdatedEventInstancesImplementedOnBranch = totalEventsCounter !== undefined && completedEventsCounter !== undefined && totalEventsCounter > 0 ? completedEventsCounter / totalEventsCounter : undefined;
  var proportionOfUpdatedSourcesImplementedOnBranch = totalSourcesCounter !== undefined && completedSourcesCounter !== undefined && totalSourcesCounter > 0 ? completedSourcesCounter / totalSourcesCounter : undefined;
  var newProperties = Belt_List.keepMapU(actionDiff, (function (action) {
          if (typeof action === "object" && action.NAME === "CreateProperty") {
            return AvoModel.getPropertyById(model, action.VAL[0]);
          }
          
        }));
  var numNewProperties = Belt_List.length(newProperties);
  var numUpdatedPropertiesIncludingNew = Belt_List.length(Belt_List.keepU(actions, (function (groupedActions) {
              return Belt_List.someU(groupedActions, (function (action) {
                            var match = action[0];
                            if (typeof match === "object") {
                              return match.NAME === "Property";
                            } else {
                              return false;
                            }
                          }));
            })));
  var numNewEvents = Belt_List.length(Belt_List.keepU(actionDiff, (function (action) {
              if (typeof action === "object") {
                return action.NAME === "AddEvent";
              } else {
                return false;
              }
            })));
  var numUpdatedEventsIncludingNew = Belt_List.length(Belt_List.keepU(actions, (function (groupedActions) {
              return Belt_List.someU(groupedActions, (function (action) {
                            var match = action[0];
                            if (typeof match === "object") {
                              return match.NAME === "Event";
                            } else {
                              return false;
                            }
                          }));
            })));
  var arg = Belt_List.length(autoPublishIntegrations);
  var arg$1 = Belt_List.toArray(Belt_List.keepMapU(autoPublishIntegrations, (function (integration) {
              return IntegrationUtils.configToAnalyticsType(integration.config);
            })));
  var arg$2 = Belt_List.length(Belt_List.keepU(newProperties, (function (property) {
              var match = property.absence;
              if (match !== undefined) {
                if (typeof match === "number" || match.TAG !== /* SometimesSent */0) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return property.optionalDeprecated;
              }
            })));
  var arg$3 = Belt_List.length(model.properties);
  var arg$4 = Belt_List.length(model.events);
  var arg$5 = Belt_List.length(Belt_List.keepU(model.properties, (function (property) {
              if (property.TAG === /* PropertyRef */0) {
                return false;
              }
              var property$1 = property._0;
              var match = property$1.absence;
              if (match !== undefined) {
                if (typeof match === "number" || match.TAG !== /* SometimesSent */0) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return property$1.optionalDeprecated;
              }
            })));
  var arg$6 = Belt_List.length(Belt_List.keepU(actionDiff, (function (action) {
              if (typeof action === "object" && action.NAME === "AddEvent") {
                return Belt_Option.mapWithDefault(StateUtils.getEventById(action.VAL[0], model), false, (function (param) {
                              return param.triggers.length !== 0;
                            }));
              } else {
                return false;
              }
            })));
  var arg$7 = Belt_List.length(Belt_List.keepU(Belt_List.keepMapU(actions, (function (groupedActions) {
                  return Belt_Option.flatMap(Belt_List.getByU(groupedActions, (function (action) {
                                    var match = action[0];
                                    if (typeof match === "object") {
                                      return match.NAME === "Event";
                                    } else {
                                      return false;
                                    }
                                  })), (function (action) {
                                var match = action[0];
                                if (typeof match === "object" && match.NAME === "Event") {
                                  return match.VAL;
                                }
                                
                              }));
                })), (function (eventId) {
              return Belt_Option.mapWithDefault(StateUtils.getEventById(eventId, model), false, (function (param) {
                            return param.triggers.length !== 0;
                          }));
            })));
  var arg$8 = Belt_List.length(Belt_List.keepU(model.sources, (function (source) {
              return Belt_List.someU(StateUtils.getSourceEvents(undefined, model, source.id), (function ($$event) {
                            return Belt_Array.someU($$event.triggers, (function (trigger) {
                                          var sourceIds = trigger.sources;
                                          if (sourceIds) {
                                            return Belt_Array.someU(sourceIds._0, (function (sourceId) {
                                                          return sourceId === source.id;
                                                        }));
                                          } else {
                                            return true;
                                          }
                                        }));
                          }));
            })));
  var arg$9 = workspace.settings.numRequiredApprovals;
  var arg$10 = workspace.settings.protectedMainBranch ? (
      workspace.settings.requireAdminApproval ? "Admin" : "Editor"
    ) : "None";
  var arg$11 = AnalyticsUtils.roleToAnalyticsRole(role);
  var arg$12 = mergeWithoutApproval ? "MergeWithoutApproval" : "None";
  var arg$13 = schemaGroup.branchId;
  var arg$14 = schemaGroup.schemaId;
  var arg$15 = schemaGroup.branchId;
  var arg$16 = schemaGroup.schemaId;
  var partialBranchMergedAnalytics = function (param) {
    return AnalyticsRe.branchMerged(schemaGroup, numUpdatedEventsIncludingNew, numNewEvents, param, arg, arg$1, numNewProperties, numUpdatedPropertiesIncludingNew, arg$2, arg$3, arg$4, arg$5, totalEventsCounter, completedEventsCounter, totalSourcesCounter, completedSourcesCounter, proportionOfUpdatedEventInstancesImplementedOnBranch, proportionOfUpdatedSourcesImplementedOnBranch, numSourcesUpdatedOnBranchConnectedToAvoFunctions, numSourcesUpdatedOnBranchConnectedToInspector, numSourcesUpdatedOnBranchConnectedToImplementationStatus, eventInstancesImplementedRatioPerSource, arg$6, arg$7, arg$8, arg$9, numApprovals, arg$10, arg$11, arg$12, numNewSchemaIssuesOnBranch, numSchemaIssues, numSchemaIssuesDelta, arg$13, arg$14, arg$15, numUpdatedEventsIncludingNew, numUpdatedPropertiesIncludingNew, arg$16, workspaceNumIssues);
  };
  $$Promise.$$catch(FirebaseFetchers.fetchAllCommentActionsOnBranch(schemaId, branchId).then(function (comments) {
            partialBranchMergedAnalytics(Belt_Array.concatMany(Belt_Array.map(comments, (function (prim) {
                            return prim.docs;
                          }))).length);
            return Promise.resolve(undefined);
          }), (function (error) {
          console.log(error);
          partialBranchMergedAnalytics(-1);
          return Promise.resolve(undefined);
        }));
  
}

var rootStyles = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100)),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.border(Css.px(1), Css.solid, Styles.Color.grey30),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: /* [] */0
          }
        }
      }
    });

var contentStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding(Css.px(20)),
            tl: /* [] */0
          }
        }
      }
    });

var columnContentStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.padding(Css.px(20)),
          tl: /* [] */0
        }
      }
    });

var buttonBoxStyles = Curry._1(Css.style, {
      hd: Css.label("review-cta"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.flexShrink(0.0),
            tl: {
              hd: Css.marginLeft(Css.px(8)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var infoBoxStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingTop(Css.px(12)),
            tl: {
              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey30),
              tl: {
                hd: Css.paddingLeft(Css.px(20)),
                tl: {
                  hd: Css.paddingRight(Css.px(20)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(12)),
                    tl: {
                      hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey70),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.grey10),
                        tl: {
                          hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.borderBottomRightRadius(Styles.Border.radius),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function DiffViewActionBox$StatusBox(Props) {
  var action = Props.action;
  var title = Props.title;
  var actionLabel = Props.actionLabel;
  var userId = action.createdBy;
  var user = FirebaseFetcherHooks.useUser(userId);
  return React.createElement("div", {
              className: columnContentStyles
            }, React.createElement($$Text.make, {
                  element: "Div",
                  size: "Large",
                  weight: "Semi",
                  color: Styles.Color.blue,
                  children: title
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Regular",
                  color: Styles.Color.grey70,
                  children: null
                }, actionLabel, " ", React.createElement("strong", undefined, React.createElement(Timestamp.make, {
                          date: action.createdAt
                        })), typeof user === "object" ? React.createElement(React.Fragment, undefined, " by ", React.createElement("strong", undefined, AvoConfig.getUserDisplayName(user.VAL))) : null));
}

var StatusBox = {
  make: DiffViewActionBox$StatusBox
};

function DiffViewActionBox$ApprovalWorkflowsTrialingInfoBox(Props) {
  var onUpgrade = Props.onUpgrade;
  return React.createElement("div", {
              className: infoBoxStyle
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexShrink(0.0),
                        tl: {
                          hd: Css.marginRight(Css.px(16)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(IconLightning.make, {})), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.grey70,
                  children: null
                }, "Branch approval workflows are available during your trial. Keep review requests and approvals by upgrading to the Team or Enterprise plans. ", React.createElement(TextButton.make, {
                      onClick: onUpgrade,
                      style: "Blue",
                      children: "Learn more"
                    })));
}

var ApprovalWorkflowsTrialingInfoBox = {
  make: DiffViewActionBox$ApprovalWorkflowsTrialingInfoBox
};

function DiffViewActionBox$ForceBranchAuditTrialingInfoBox(Props) {
  var onUpgrade = Props.onUpgrade;
  return React.createElement("div", {
              className: infoBoxStyle
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexShrink(0.0),
                        tl: {
                          hd: Css.marginRight(Css.px(16)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(IconLightning.make, {})), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.grey70,
                  children: null
                }, "Force branch audit is available during your trial. Keep review requests and approvals by upgrading to the Enterprise plan.", React.createElement(TextButton.make, {
                      onClick: onUpgrade,
                      style: "Blue",
                      children: "Learn more"
                    })));
}

var ForceBranchAuditTrialingInfoBox = {
  make: DiffViewActionBox$ForceBranchAuditTrialingInfoBox
};

function DiffViewActionBox$Approval(Props) {
  var userId = Props.userId;
  var last = Props.last;
  var userState = FirebaseFetcherHooks.useUser(userId);
  if (typeof userState !== "object") {
    return null;
  }
  var user = userState.VAL;
  var name = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), user.email);
  return React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement(ProfilePhoto.make, {
                  user: user,
                  size: 16
                }), React.createElement(Spacer.make, {
                  width: 4
                }), name + (
              last ? "" : ","
            ));
}

var Approval = {
  make: DiffViewActionBox$Approval
};

function DiffViewActionBox$ApprovedBy(Props) {
  var branchStatus = Props.branchStatus;
  var approvals;
  approvals = typeof branchStatus === "number" || branchStatus.TAG !== /* Approved */6 ? /* [] */0 : branchStatus._0;
  if (approvals !== /* [] */0) {
    return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                    height: 12
                  }), React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.grey90,
                    children: React.createElement("span", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement(IconCheckmark.make, {
                              size: 12,
                              color: Styles.Color.mintGreen
                            }), React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement("span", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, "Approved by ", Belt_List.toArray(Belt_List.mapWithIndex(approvals, (function (index, approval) {
                                        return React.createElement(DiffViewActionBox$Approval, {
                                                    userId: approval.createdBy,
                                                    last: index === (Belt_List.length(approvals) - 1 | 0),
                                                    key: approval.createdBy
                                                  });
                                      })))))
                  }));
  } else {
    return null;
  }
}

var ApprovedBy = {
  make: DiffViewActionBox$ApprovedBy
};

function DiffViewActionBox$WhoIsAdmin(Props) {
  var globalSend = GlobalSendContext.use(undefined);
  return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                  width: 4
                }), React.createElement("button", {
                  className: Curry._1(Css.style, Belt_List.concat(Styles.buttonReset, {
                            hd: Css.display("inlineBlock"),
                            tl: /* [] */0
                          })),
                  onClick: (function (param) {
                      return Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "Settings",
                                    VAL: undefined
                                  }
                                });
                    })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.blue,
                      children: "Who's admin?"
                    })));
}

var WhoIsAdmin = {
  make: DiffViewActionBox$WhoIsAdmin
};

function DiffViewActionBox$Options(Props) {
  var options = Props.options;
  var disabledOpt = Props.disabled;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  return React.createElement(ContextMenu.make, {
              button: React.createElement("button", {
                    className: Curry._1(Css.merge, {
                          hd: Curry._1(Css.style, Styles.button),
                          tl: {
                            hd: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: {
                                        hd: Css.width(Css.px(45)),
                                        tl: {
                                          hd: Css.height(Css.px(40)),
                                          tl: {
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.grey40),
                                            tl: {
                                              hd: Css.borderRadius(Styles.Border.radius),
                                              tl: {
                                                hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey70),
                                                tl: {
                                                  hd: Css.hover({
                                                        hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey80),
                                                        tl: {
                                                          hd: Css.border(Css.px(1), "solid", Styles.Color.grey70),
                                                          tl: /* [] */0
                                                        }
                                                      }),
                                                  tl: {
                                                    hd: Css.focus({
                                                          hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey80),
                                                          tl: {
                                                            hd: Css.border(Css.px(1), "solid", Styles.Color.grey70),
                                                            tl: /* [] */0
                                                          }
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }),
                    disabled: disabled
                  }, React.createElement(IconEllipsis.make, {
                        size: 20
                      })),
              options: options
            });
}

var Options = {
  make: DiffViewActionBox$Options
};

function DiffViewActionBox$MergeBranchModalBody(Props) {
  var breakingChanges = Props.breakingChanges;
  var message = Props.message;
  var hasSharingLink = Props.hasSharingLink;
  var setShouldDeleteSharingLink = Props.setShouldDeleteSharingLink;
  var toggleText = Props.toggleText;
  var toggleMessage = Props.toggleMessage;
  var validationIntent = Props.validationIntent;
  var match = Hooks.useDisclosure(false);
  var actions = match[1];
  var value = match[0];
  var onToggle = function (param) {
    Curry._1(setShouldDeleteSharingLink, !value);
    return Curry._1(actions.toggle, undefined);
  };
  var tmp;
  if (Belt_MapString.isEmpty(breakingChanges.breakingEventIdsBySource)) {
    tmp = null;
  } else {
    var tmp$1;
    if (InspectorVersionValidationIntentHelpers.noSourcesHaveIntent(Belt_SetString.fromArray(breakingChanges.breakingSources), validationIntent)) {
      tmp$1 = React.createElement(React.Fragment, undefined, "Inspector may surface irrelevant issues and alerts from older release versions. To prevent that, cancel merging and configure Inspector to temporarily ignore issues for ", React.createElement($$Text.make, {
                element: "Span",
                weight: "Semi",
                children: AvoUtils.plural(breakingChanges.breakingEvents.length, "event") + " from " + AvoUtils.plural(breakingChanges.breakingSources.length, "source")
              }), ".");
    } else if (InspectorVersionValidationIntentHelpers.allSourcesHaveIntent(Belt_SetString.fromArray(breakingChanges.breakingSources), validationIntent)) {
      tmp$1 = React.createElement(React.Fragment, undefined, "For those changes, Inspector has been configured to temporarily ignore issues for ", React.createElement($$Text.make, {
                element: "Span",
                weight: "Semi",
                children: AvoUtils.plural(breakingChanges.breakingEvents.length, "event") + " from " + AvoUtils.plural(breakingChanges.breakingSources.length, "source")
              }), ".");
    } else {
      var match$1 = InspectorVersionValidationIntentHelpers.getValidationReport(breakingChanges, validationIntent);
      tmp$1 = React.createElement(React.Fragment, undefined, "For those changes, Inspector has been configured to temporarily ignore issues for\n                          " + AvoUtils.plural(match$1.ignoredEvents.length, "event") + " from " + AvoUtils.plural(match$1.ignoredSources.length, "source") + " on this branch but ", React.createElement($$Text.make, {
                element: "Span",
                weight: "Semi",
                children: null
              }, AvoUtils.plural(match$1.passingEvents.length, "event") + " from " + AvoUtils.plural(match$1.passingSources.length, "source"), " remain to be configured."));
    }
    tmp = React.createElement(React.Fragment, undefined, tmp$1, React.createElement(Spacer.make, {
              height: 16
            }));
  }
  return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                  color: Styles.Color.grey70,
                  children: null
                }, "When this branch is merged, the changes made on this branch will be reflected in the main branch.", React.createElement(Spacer.make, {
                      height: 16
                    }), tmp), React.createElement($$Text.make, {
                  weight: "Semi",
                  color: Styles.Color.grey80,
                  children: message
                }), hasSharingLink ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                        height: 8
                      }), React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.grey70,
                        children: null
                      }, React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("inlineFlex"),
                                  tl: {
                                    hd: Css.transform({
                                          NAME: "translateY",
                                          VAL: Css.px(3)
                                        }),
                                    tl: {
                                      hd: Css.paddingRight(Css.px(4)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(Icon.make, {
                                type_: "information",
                                color: Styles.Color.grey60
                              })), toggleMessage), React.createElement(Spacer.make, {
                        height: 4
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.gap(Css.px(4)),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement("input", {
                            id: "delete-public-link",
                            name: "delete-public-link",
                            type: "checkbox",
                            onClick: onToggle
                          }), React.createElement("label", {
                            htmlFor: "delete-public-link"
                          }, React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.grey80,
                                children: toggleText
                              })))) : null);
}

var MergeBranchModalBody = {
  make: DiffViewActionBox$MergeBranchModalBody
};

function DiffViewActionBox$InspectorValidation$OnboardingFlow(Props) {
  var isIntersecting = Props.isIntersecting;
  return React.createElement(ProductTour.make, {
              showStepCount: false,
              tourId: "ignore-on-branch-merge",
              tourMayShow: isIntersecting,
              children: React.createElement(ProductTour.Step.make, {
                    stepId: "step-1",
                    stepType: {
                      TAG: /* OnElement */0,
                      _0: "inspector-validation-action-box"
                    },
                    delay: 500,
                    lockBackground: false,
                    position: "top",
                    transitions: [{
                        key: "complete",
                        label: "Got it!",
                        icon: undefined
                      }],
                    children: React.createElement(ProductTour.GenericStep.make, {})
                  })
            });
}

var OnboardingFlow = {
  make: DiffViewActionBox$InspectorValidation$OnboardingFlow
};

function DiffViewActionBox$InspectorValidation$Icon(Props) {
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css.flexShrink(0.0),
                    tl: /* [] */0
                  }),
              height: "16",
              width: "15",
              viewBox: "0 0 15 16"
            }, React.createElement("path", {
                  d: "M11.2694 10.8855L13.9461 13.5622L13.0622 14.4461L10.3855 11.7694C9.42306 12.5394 8.2025 13 6.875 13C3.77 13 1.25 10.48 1.25 7.375C1.25 4.27 3.77 1.75 6.875 1.75C9.98 1.75 12.5 4.27 12.5 7.375C12.5 8.7025 12.0394 9.92306 11.2694 10.8855ZM10.0154 10.4218C10.7797 9.63412 11.25 8.55975 11.25 7.375C11.25 4.95781 9.29219 3 6.875 3C4.45781 3 2.5 4.95781 2.5 7.375C2.5 9.79219 4.45781 11.75 6.875 11.75C8.05975 11.75 9.13412 11.2797 9.92175 10.5154L10.0154 10.4218ZM7.61119 4.98515C7.17712 5.18114 6.875 5.61779 6.875 6.125C6.875 6.81537 7.43463 7.375 8.125 7.375C8.63219 7.375 9.06887 7.07288 9.26488 6.63882C9.33644 6.87156 9.375 7.11875 9.375 7.375C9.375 8.75569 8.25569 9.875 6.875 9.875C5.49429 9.875 4.375 8.75569 4.375 7.375C4.375 5.99429 5.49429 4.875 6.875 4.875C7.13125 4.875 7.37844 4.91354 7.61119 4.98515Z",
                  fill: "#6D7783"
                }));
}

var Icon$1 = {
  make: DiffViewActionBox$InspectorValidation$Icon
};

function getActionDetails(contentsJson) {
  try {
    var match = Parser.parseAction(contentsJson);
    if (typeof match !== "object") {
      return ;
    }
    if (match.NAME !== "ConfigureInspectorValidation") {
      return ;
    }
    var match$1 = match.VAL;
    return [
            match$1[0],
            match$1[1]
          ];
  }
  catch (exn){
    return ;
  }
}

function getActionData(action) {
  if (typeof action !== "object") {
    return ;
  }
  if (action.NAME !== "Loaded") {
    return ;
  }
  var actionData = action.VAL;
  if (actionData !== undefined) {
    return getActionDetails(Caml_option.valFromOption(actionData).contentsJson);
  }
  
}

function DiffViewActionBox$InspectorValidation(Props) {
  var branchStatus = Props.branchStatus;
  var branchModel = Props.branchModel;
  var breakingChanges = Props.breakingChanges;
  var validationIntent = Props.validationIntent;
  var match = Curry._1(Mantine.Hooks.Intersection.useWithoutOptions, undefined);
  var entry = match.entry;
  var inspectorValidationPromptRef = match.ref;
  var match$1 = ViewerContext.use(undefined);
  var member = WorkspaceContext.useMember(match$1.id);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var configureInspectorValidationAction = FirebaseFetcherHooks.useAction(schemaGroup.schemaId, (function (q) {
          return q.where("branchId", "==", schemaGroup.branchId);
        }), "ConfigureInspectorValidation");
  var isIntersecting = React.useMemo((function () {
          if (entry !== undefined) {
            return entry.isIntersecting;
          } else {
            return false;
          }
        }), [entry]);
  React.useEffect((function () {
          var match = getActionData(configureInspectorValidationAction);
          if (entry !== undefined && entry.isIntersecting) {
            var exit = 0;
            if (typeof branchStatus === "number" || !(branchStatus.TAG === /* Merged */1 && match !== undefined)) {
              exit = 1;
            } else {
              var sourceValidation = match[1];
              var eventIds = match[0];
              AnalyticsRe.releaseVersionConfigurationPromptSeen(schemaGroup, AnalyticsRe.Group.inspectorBreakingChangeInfo(Belt_List.size(eventIds), Belt_List.toArray(eventIds), Belt_MapString.keysToArray(sourceValidation), Belt_MapString.size(sourceValidation)), schemaGroup.branchId, schemaGroup.schemaId);
            }
            if (exit === 1) {
              AnalyticsRe.releaseVersionConfigurationPromptSeen(schemaGroup, Changes.getAnalyticsBreakingChangeInfo(breakingChanges), schemaGroup.branchId, schemaGroup.schemaId);
            }
            
          }
          
        }), [isIntersecting]);
  var match$2 = getActionData(configureInspectorValidationAction);
  if (typeof branchStatus !== "number" && branchStatus.TAG === /* Merged */1) {
    if (match$2 === undefined) {
      return null;
    }
    var sourceValidation = match$2[1];
    var eventIds = match$2[0];
    return React.createElement("div", {
                ref: inspectorValidationPromptRef,
                className: infoBoxStyle
              }, React.createElement(DiffViewActionBox$InspectorValidation$Icon, {}), React.createElement(Spacer.make, {
                    width: 13
                  }), React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.grey70,
                    children: null
                  }, "Inspector temporarily ignored issues for ", React.createElement($$Text.make, {
                        element: "Span",
                        weight: "Semi",
                        children: AvoUtils.plural(Belt_List.size(eventIds), "event") + " from " + AvoUtils.plural(Belt_MapString.size(sourceValidation), "source")
                      }), ". View details to see when validation for those events will resume."), React.createElement(Spacer.make, {
                    width: 8,
                    grow: 1.0
                  }), React.createElement(Button.make, {
                    label: "View Details",
                    onClick: (function (param) {
                        return Curry._1(globalSend, {
                                    TAG: /* OpenModal */4,
                                    _0: {
                                      NAME: "InspectorVersionValidationDetails",
                                      VAL: [
                                        eventIds,
                                        sourceValidation,
                                        branchModel
                                      ]
                                    }
                                  });
                      }),
                    size: "small",
                    style: "outline"
                  }));
  }
  var breakingSourcesSet = Belt_SetString.fromArray(breakingChanges.breakingSources);
  var tmp;
  if (InspectorVersionValidationIntentHelpers.noSourcesHaveIntent(breakingSourcesSet, validationIntent)) {
    tmp = React.createElement(React.Fragment, undefined, "When merging this branch, Inspector may surface irrelevant issues and alerts from older release versions. To prevent that, configure Inspector to temporarily ignore new issues for ", React.createElement($$Text.make, {
              element: "Span",
              weight: "Semi",
              children: AvoUtils.plural(breakingChanges.breakingEvents.length, "event") + " from " + AvoUtils.plural(breakingChanges.breakingSources.length, "source")
            }), ".");
  } else if (InspectorVersionValidationIntentHelpers.allSourcesHaveIntent(breakingSourcesSet, validationIntent)) {
    tmp = React.createElement(React.Fragment, undefined, "Inspector has been configured to temporarily ignore new issues for ", React.createElement($$Text.make, {
              element: "Span",
              weight: "Semi",
              children: AvoUtils.plural(breakingChanges.breakingEvents.length, "event") + " from " + AvoUtils.plural(breakingChanges.breakingSources.length, "source")
            }), " when this branch is merged. To see how each source is configured and to make changes, click configure.");
  } else {
    var match$3 = InspectorVersionValidationIntentHelpers.getValidationReport(breakingChanges, validationIntent);
    tmp = React.createElement(React.Fragment, undefined, "When merging this branch, Inspector may surface irrelevant issues and alerts from older release versions. To prevent that, Inspector has been configured to temporarily ignore new issues for\n                          " + AvoUtils.plural(match$3.ignoredEvents.length, "event") + " from " + AvoUtils.plural(match$3.ignoredSources.length, "source") + " on this branch but ", React.createElement($$Text.make, {
              element: "Span",
              weight: "Semi",
              children: AvoUtils.plural(match$3.passingEvents.length, "event") + " from " + AvoUtils.plural(match$3.passingSources.length, "source")
            }), " remain to be configured.");
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: inspectorValidationPromptRef,
                  className: infoBoxStyle,
                  id: "inspector-validation-action-box"
                }, React.createElement(DiffViewActionBox$InspectorValidation$Icon, {}), React.createElement(Spacer.make, {
                      width: 13
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.grey70,
                      children: tmp
                    }), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), React.createElement(Button.make, {
                      label: Belt_Option.mapWithDefault(member, "View", (function (member) {
                              if (Models.Member.canEdit(member)) {
                                return "Configure";
                              } else {
                                return "View";
                              }
                            })),
                      onClick: (function (param) {
                          return Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "InspectorVersionValidation",
                                        VAL: breakingChanges
                                      }
                                    });
                        }),
                      size: "small",
                      style: "outline"
                    })), React.createElement(DiffViewActionBox$InspectorValidation$OnboardingFlow, {
                  isIntersecting: isIntersecting
                }));
}

var InspectorValidation = {
  OnboardingFlow: OnboardingFlow,
  Icon: Icon$1,
  getActionDetails: getActionDetails,
  getActionData: getActionData,
  make: DiffViewActionBox$InspectorValidation
};

function DiffViewActionBox(Props) {
  var actionDiff = Props.actionDiff;
  var actions = Props.actions;
  var branchId = Props.branchId;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var currentViewerOpenedBranch = Props.currentViewerOpenedBranch;
  var mergeConflicts = Props.mergeConflicts;
  var role = Props.role;
  var schema = Props.schema;
  var branchModel = Props.branchModel;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var masterModel = Props.masterModel;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var protectedMainBranch = workspace.settings.protectedMainBranch;
  var match = React.useContext(DiscrepancyContext.context);
  var discrepancyConfig = match.config;
  var match$1 = BranchDiscrepancies.use(undefined);
  var branchAuditWarningCount = match$1.branchAuditWarningCount;
  var branchAuditChecks = match$1.branchAuditChecks;
  var addToast = Toast.useAddToast(undefined);
  var invocationsContext = React.useContext(ImplementationStatusContext.context).codegen;
  var sourceInitStatus = invocationsContext[2];
  var invocationsPromise = FetchImplementationStatusHooks.getInvocationsWithPromise(invocationsContext);
  var approvalWorkflow = AvoLimits.ApprovalWorkflow.computeAvailability(workspace);
  var canForceBranchAudit = AvoLimits.ForceBranchAudit.computeAvailability(workspace);
  var autoPublishIntegrations = IntegrationUtils.toAutoPublish(branchModel, workspace);
  var hasNoMergeConflicts = mergeConflicts === /* [] */0;
  var blockMergeBecauseOfForceAudit = React.useMemo((function () {
          if (workspace.settings.forceBranchAudit) {
            return DiscrepancyContext.hasDiscrepancies(branchAuditChecks);
          } else {
            return false;
          }
        }), [
        branchModelWithPulledMaster,
        masterModel,
        discrepancyConfig
      ]);
  var maybeSharingLink = FirebaseFetcherHooks.useSharingLink(schemaGroup.schemaId, branchId);
  var match$2 = React.useState(function () {
        return false;
      });
  var setLoading = match$2[1];
  var isLoading = match$2[0];
  var shouldDeleteSharingLink = React.useRef(false);
  var breakingChanges = Changes.useBreakingChangesReport(masterModel, branchModel);
  var match$3 = FirebaseFetcherHooks.useValidationIntents(schemaGroup.schemaId, branchId);
  var validationIntent = match$3[0];
  var match$4 = AppVersionsStore.use(undefined, 2, undefined);
  var appVersionsFetchStatus = match$4[1];
  var appVersions = match$4[0];
  var match$5 = React.useState(function () {
        return false;
      });
  var setHasSourcesToConfig = match$5[1];
  React.useEffect((function () {
          if (appVersionsFetchStatus === "success") {
            var sourceIdsWithVersionsSet = Belt_SetString.fromArray(Belt_MapString.keysToArray(appVersions));
            var sourceIds = breakingChanges.breakingSources;
            Curry._1(setHasSourcesToConfig, (function (param) {
                    return Belt_Array.some(sourceIds, (function (sourceId) {
                                  return Belt_SetString.has(sourceIdsWithVersionsSet, sourceId);
                                }));
                  }));
          }
          
        }), [
        appVersions,
        appVersionsFetchStatus
      ]);
  var showVersionValidation = breakingChanges.breakingSources.length !== 0 && match$5[0];
  var handleMerge = function (adminMerge, numApprovals) {
    var title = adminMerge ? "Merge branch override" : "Merge branch";
    var message = adminMerge ? "Are you sure you want to merge this branch into the main branch without approval?" : "Ready to merge this branch into the main branch?";
    var setInspectorValidationIfNeeded = function (onDone) {
      var shouldSetInspectorValidation = breakingChanges.breakingSources.length !== 0 && breakingChanges.breakingEvents.length !== 0 && showVersionValidation;
      if (shouldSetInspectorValidation) {
        Curry._1(setLoading, (function (param) {
                return true;
              }));
        Promise.all([
                InspectorVersionValidationIntentHelpers.getIntents(undefined),
                AppVersionsStore.get(undefined, undefined)
              ]).then(function (param) {
              var appVersions = param[1];
              var intents = param[0];
              if (!Belt_MapString.isEmpty(intents) || Belt_Array.some(Belt_MapString.keysToArray(intents), (function (sourceId) {
                        return Belt_SetString.has(Belt_SetString.fromArray(breakingChanges.breakingSources), sourceId);
                      }))) {
                var now = new Date();
                var eventIds = Belt_List.fromArray(breakingChanges.breakingEvents);
                var sourceValidationMap = Belt_MapString.fromArray(Belt_Array.map(breakingChanges.breakingSources, (function (sourceId) {
                            return [
                                    sourceId,
                                    Belt_Option.flatMap(Belt_MapString.get(intents, sourceId), (function (intent) {
                                            var latestVersion = AppVersionsTypes.getLatestVersion(undefined, appVersions, sourceId);
                                            if (intent === "nextVersion") {
                                              if (latestVersion !== undefined && latestVersion.NAME !== "invalid") {
                                                return {
                                                        NAME: "nextVersion",
                                                        VAL: latestVersion.VAL
                                                      };
                                              } else {
                                                return ;
                                              }
                                            } else if (intent === "oneHourAfterMerge") {
                                              return {
                                                      NAME: "oneHourAfterMerge",
                                                      VAL: now
                                                    };
                                            } else if (intent === "currentVersion") {
                                              if (latestVersion !== undefined && latestVersion.NAME !== "invalid") {
                                                return {
                                                        NAME: "currentVersion",
                                                        VAL: latestVersion.VAL
                                                      };
                                              } else {
                                                return ;
                                              }
                                            } else if (intent === "twentyFourHoursAfterMerge") {
                                              return {
                                                      NAME: "twentyFourHoursAfterMerge",
                                                      VAL: now
                                                    };
                                            } else {
                                              return ;
                                            }
                                          }))
                                  ];
                          })));
                var action_1 = [
                  eventIds,
                  sourceValidationMap
                ];
                var action = {
                  NAME: "ConfigureInspectorValidation",
                  VAL: action_1
                };
                Curry.app(sendActions, [
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          var toModel = Actions.reduce(branchModel, action);
                          return Curry._1(onDone, ActionDiff.diff((function (error) {
                                            return Sentry.captureExceptionString("Illegal state in ActionDiff", {
                                                        errorMessage: error,
                                                        origin: "DiffViewActionBox.res"
                                                      });
                                          }), masterModelBeforeBranch, toModel, undefined));
                        }),
                      undefined,
                      [[
                          action,
                          {
                            branchId: branchId,
                            branchQuery: branchId
                          }
                        ]]
                    ]);
              } else {
                Curry._1(onDone, actionDiff);
              }
              return Promise.resolve(undefined);
            });
        return ;
      } else {
        return Curry._1(onDone, actionDiff);
      }
    };
    return Curry._1(globalSend, {
                TAG: /* OpenModal */4,
                _0: {
                  NAME: "ComposableModal",
                  VAL: [
                    {
                      NAME: "string",
                      VAL: title
                    },
                    {
                      NAME: "component",
                      VAL: React.createElement(DiffViewActionBox$MergeBranchModalBody, {
                            breakingChanges: breakingChanges,
                            message: message,
                            hasSharingLink: Belt_Option.isSome(maybeSharingLink),
                            setShouldDeleteSharingLink: (function (value) {
                                shouldDeleteSharingLink.current = value;
                                
                              }),
                            toggleText: "Delete Public Link",
                            toggleMessage: "A public link for the code changes exists. Do you wish to delete it when merging?",
                            validationIntent: validationIntent
                          })
                    },
                    [
                      {
                        NAME: "secondary",
                        VAL: {
                          label: "Cancel",
                          onClick: (function (closeModal) {
                              shouldDeleteSharingLink.current = false;
                              return Curry._1(closeModal, undefined);
                            })
                        }
                      },
                      {
                        NAME: "primary",
                        VAL: {
                          label: "Merge branch",
                          onClick: (function (closeModal) {
                              setInspectorValidationIfNeeded(function (actionDiff) {
                                    var deletePublicLink = shouldDeleteSharingLink.current;
                                    Curry._1(setLoading, (function (param) {
                                            return true;
                                          }));
                                    var mergeActionsAndContext = Belt_List.mapU(BeltListExtensions.splitIntoChunks(undefined, actionDiff, 3000, undefined), (function (chunk) {
                                            return [
                                                    {
                                                      NAME: "MergeBranch",
                                                      VAL: [
                                                        branchId,
                                                        branchName,
                                                        chunk
                                                      ]
                                                    },
                                                    {
                                                      branchId: branchId,
                                                      branchQuery: branchId
                                                    }
                                                  ];
                                          }));
                                    var writeActionsAndContextSequentially = function (actions) {
                                      return Curry.app(sendActions, [
                                                  "Master",
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  (function (param) {
                                                      Curry._1(addToast, {
                                                            message: "Branch " + branchName + " merged 🎉"
                                                          });
                                                      return Curry._1(setLoading, (function (param) {
                                                                    return false;
                                                                  }));
                                                    }),
                                                  undefined,
                                                  Belt_List.toArray(actions)
                                                ]);
                                    };
                                    var numSchemaIssuesOnBranchWithPulledMain = DiscrepancyContext.getTotalWarningsCount(DiscrepancyContext.getDiscrepancies(discrepancyConfig, branchModelWithPulledMaster).discrepancies);
                                    var numSchemaIssuesOnMain = DiscrepancyContext.getTotalWarningsCount(DiscrepancyContext.getDiscrepancies(discrepancyConfig, masterModel).discrepancies);
                                    $$Promise.$$catch(invocationsPromise.then(function (invocations) {
                                              var sourceUpdates = DiffUtils.getBranchImplementationStatus(actions, branchModel, masterModel, {
                                                    NAME: "Success",
                                                    VAL: invocations
                                                  }, sourceInitStatus, branchId);
                                              var completedEventsCounter = Belt_List.length(Belt_List.keep(Belt_List.flatten(Belt_List.mapU(sourceUpdates, (function (param) {
                                                                  return param[1];
                                                                }))), (function (x) {
                                                          if (typeof x === "object" && x.NAME === "Updated" && typeof x.VAL[1] !== "number") {
                                                            return true;
                                                          } else {
                                                            return false;
                                                          }
                                                        })));
                                              var totalEventsCounter = Belt_List.length(Belt_List.keep(Belt_List.flatten(Belt_List.mapU(sourceUpdates, (function (param) {
                                                                  return param[1];
                                                                }))), (function (x) {
                                                          if (typeof x === "object") {
                                                            return x.NAME !== "Removed";
                                                          } else {
                                                            return true;
                                                          }
                                                        })));
                                              var filteredSources = Belt_List.keepU(sourceUpdates, (function (param) {
                                                      return AvoConfig.supportsInvocations(param[0]);
                                                    }));
                                              var numSourcesRequiringImplementation = Belt_List.length(sourceUpdates);
                                              var numSourcesFullyImplemented = Belt_List.length(Belt_List.keepU(sourceUpdates, (function (param) {
                                                          var events = param[1];
                                                          var totalCompletedEvents = Belt_List.length(Belt_List.keep(events, (function (x) {
                                                                      if (typeof x.VAL[1] === "number") {
                                                                        return false;
                                                                      } else {
                                                                        return true;
                                                                      }
                                                                    })));
                                                          var totalEvents = Belt_List.length(events);
                                                          return totalCompletedEvents === totalEvents;
                                                        })));
                                              var numSourcesConnectedtoAvoFunctions = Belt_List.length(filteredSources);
                                              var numSourcesConnectedToImplementationStatus = numSourcesConnectedtoAvoFunctions + 0 | 0;
                                              var eventInstancesImplementedRatioPerSource = Belt_List.toArray(Belt_List.mapU(sourceUpdates, (function (param) {
                                                          var events = param[1];
                                                          var totalCompletedEvents = Belt_List.length(Belt_List.keep(events, (function (x) {
                                                                      if (typeof x.VAL[1] === "number") {
                                                                        return false;
                                                                      } else {
                                                                        return true;
                                                                      }
                                                                    })));
                                                          var totalEvents = Belt_List.length(events);
                                                          return totalCompletedEvents / totalEvents;
                                                        })));
                                              writeActionsAndContextSequentially(mergeActionsAndContext);
                                              Router.Schema.pushSchemaRoute("master", undefined, "dashboard");
                                              return sendBranchMergedAnalytics(schema.id, branchId, schemaGroup, actions, actionDiff, autoPublishIntegrations, branchModel, completedEventsCounter, totalEventsCounter, numSourcesFullyImplemented, numSourcesRequiringImplementation, numSourcesConnectedtoAvoFunctions, 0, numSourcesConnectedToImplementationStatus, eventInstancesImplementedRatioPerSource, workspace, numApprovals, branchAuditWarningCount, numSchemaIssuesOnBranchWithPulledMain, numSchemaIssuesOnBranchWithPulledMain - numSchemaIssuesOnMain | 0, numSchemaIssuesOnBranchWithPulledMain, role, adminMerge);
                                            }), (function (err) {
                                            console.log("Error", err);
                                            writeActionsAndContextSequentially(mergeActionsAndContext);
                                            sendBranchMergedAnalytics(schema.id, branchId, schemaGroup, actions, actionDiff, autoPublishIntegrations, branchModel, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, workspace, numApprovals, branchAuditWarningCount, numSchemaIssuesOnBranchWithPulledMain, numSchemaIssuesOnBranchWithPulledMain - numSchemaIssuesOnMain | 0, numSchemaIssuesOnBranchWithPulledMain, role, adminMerge);
                                            return Promise.resolve(undefined);
                                          }));
                                    if (maybeSharingLink === undefined) {
                                      return ;
                                    }
                                    if (!deletePublicLink) {
                                      return ;
                                    }
                                    var shareId = maybeSharingLink.shareId;
                                    FirebaseUtils.deletePublicLink(shareId);
                                    Curry._1(addToast, {
                                          message: "Public link deleted"
                                        });
                                    Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          [[
                                              {
                                                NAME: "DeletePublicBranchImplementation",
                                                VAL: shareId
                                              },
                                              {
                                                branchId: branchId,
                                                branchQuery: branchId
                                              }
                                            ]]
                                        ]);
                                    return AnalyticsRe.branchImplementationShareModalInteraction("PublicShareLinkDeleted", shareId, schemaGroup.branchId, schemaGroup.schemaId);
                                  });
                              shouldDeleteSharingLink.current = false;
                              return Curry._1(closeModal, undefined);
                            })
                        }
                      }
                    ]
                  ]
                }
              });
  };
  var handleSetBranchStatus = function (statusBefore, newStatus, numApprovals) {
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    var branchStatus = newStatus === "ChangesRequested" ? "ChangesRequested" : (
        newStatus === "Approved" ? "Approved" : (
            newStatus === "Draft" ? "Open" : "ReadyForReview"
          )
      );
    var tmp;
    if (typeof statusBefore === "number") {
      tmp = "Open";
    } else {
      switch (statusBefore.TAG | 0) {
        case /* Merged */1 :
        case /* Closed */2 :
            tmp = Pervasives.failwith("Cannot set branch status on closed or merged branch");
            break;
        case /* ReadyForReview */4 :
            tmp = "ReadyForReview";
            break;
        case /* ChangesRequested */5 :
            tmp = "ChangesRequested";
            break;
        case /* Approved */6 :
            tmp = "Approved";
            break;
        default:
          tmp = "Open";
      }
    }
    AnalyticsRe.branchStatusUpdated(schemaGroup, branchStatus, tmp, workspace.settings.numRequiredApprovals, numApprovals, workspace.settings.protectedMainBranch ? (
            workspace.settings.requireAdminApproval ? "Admin" : "Editor"
          ) : "None", schemaGroup.branchId, schemaGroup.schemaId, schemaGroup.branchId, branchStatus);
    return Curry.app(sendActions, [
                {
                  NAME: "Branch",
                  VAL: branchId
                },
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (param) {
                    return Curry._1(setLoading, (function (param) {
                                  return false;
                                }));
                  }),
                undefined,
                [[
                    {
                      NAME: "SetBranchStatus",
                      VAL: [
                        branchId,
                        branchName,
                        newStatus
                      ]
                    },
                    {
                      branchId: branchId,
                      branchQuery: branchId
                    }
                  ]]
              ]);
  };
  var handleUpgrade = function (param) {
    return Curry._1(globalSend, {
                TAG: /* OpenModal */4,
                _0: {
                  NAME: "BillingPrompt",
                  VAL: "BranchApprovalWorkflows"
                }
              });
  };
  var numApprovals;
  numApprovals = typeof branchStatus === "number" || branchStatus.TAG !== /* Approved */6 ? 0 : Belt_List.length(branchStatus._0);
  var options_markAsDraft = {
    NAME: "Option",
    VAL: {
      label: "Mark as draft",
      onClick: (function (param) {
          return handleSetBranchStatus(branchStatus, "Draft", numApprovals);
        })
    }
  };
  var options_requestChanges = {
    NAME: "Option",
    VAL: {
      label: "Request changes",
      onClick: (function (param) {
          return handleSetBranchStatus(branchStatus, "ChangesRequested", numApprovals);
        })
    }
  };
  var options_approve = {
    NAME: "Option",
    VAL: {
      label: "Approve",
      onClick: (function (param) {
          return handleSetBranchStatus(branchStatus, "Approved", numApprovals + 1 | 0);
        })
    }
  };
  var options_merge = {
    NAME: "Option",
    VAL: {
      label: "Merge branch",
      onClick: (function (param) {
          return handleMerge(false, numApprovals);
        })
    }
  };
  var options_adminMerge = {
    NAME: "Option",
    VAL: {
      label: "Merge without approval (admin)",
      onClick: (function (param) {
          return handleMerge(true, numApprovals);
        })
    }
  };
  var options_delete = {
    NAME: "WarningOption",
    VAL: {
      label: "Delete branch",
      onClick: (function (param) {
          return Curry._1(globalSend, {
                      TAG: /* OpenModal */4,
                      _0: {
                        NAME: "AreYouSureModal",
                        VAL: [
                          "Delete branch",
                          "Are you sure you want to delete this branch?",
                          "Delete",
                          (function (param) {
                              Curry.app(sendActions, [
                                    "Master",
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (_branch) {
                                        Curry._1(addToast, {
                                              message: "Branch " + branchName + " deleted"
                                            });
                                        return AnalyticsRe.branchDeleted(schemaGroup, schemaGroup.branchId, schemaGroup.schemaId);
                                      }),
                                    undefined,
                                    [[
                                        {
                                          NAME: "CloseBranch",
                                          VAL: [
                                            branchId,
                                            branchName
                                          ]
                                        },
                                        {
                                          branchId: branchId,
                                          branchQuery: branchId
                                        }
                                      ]]
                                  ]);
                              var match = Router.getStaticRoute(undefined);
                              var partial_arg = "master";
                              return Belt_Option.forEach(match.schemaRoute, (function (eta) {
                                            var param;
                                            return Router.Schema.pushSchemaRoute(partial_arg, param, eta);
                                          }));
                            }),
                          (function (param) {
                              
                            })
                        ]
                      }
                    });
        })
    }
  };
  var mergeAction = FirebaseFetcherHooks.useBranchMergeAction(schema.id, branchId, undefined, undefined);
  var closeAction = FirebaseFetcherHooks.useBranchMergeAction(schema.id, branchId, undefined, undefined);
  var currentViewerNotAdminOrEditor = !Models.Role.canEdit(role);
  var requireAdminApproval = workspace.settings.requireAdminApproval;
  var currentViewerNotAdminButAdminApprovalRequired = requireAdminApproval && role !== "Admin";
  var approveDisabled = currentViewerOpenedBranch || currentViewerNotAdminOrEditor || currentViewerNotAdminButAdminApprovalRequired;
  var pendingMultipleApprovals = function (numRequiredApprovals, approvals) {
    var numRequiredApprovals$1 = numRequiredApprovals - Belt_List.length(approvals) | 0;
    var approvalsString = (
      requireAdminApproval ? "admin " : ""
    ) + (
      numRequiredApprovals$1 === 1 ? "approval" : "approvals"
    );
    var currentUserId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
    var currentViewerAlreadyApproved = Belt_List.some(approvals, (function (approval) {
            return approval.createdBy === currentUserId;
          }));
    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: contentStyles
                  }, React.createElement("div", undefined, currentViewerOpenedBranch ? React.createElement("div", undefined, React.createElement($$Text.make, {
                                  size: "Medium",
                                  weight: "Semi",
                                  color: Styles.Color.grey90,
                                  children: "Waiting for " + numRequiredApprovals$1.toString() + " " + approvalsString
                                }), React.createElement($$Text.make, {
                                  size: "Medium",
                                  color: Styles.Color.grey70,
                                  children: null
                                }, "You can't approve changes on your own branch. " + (
                                  requireAdminApproval ? "Only admins can approve changes. Ask admins for a review." : "Ask admins or editors for a review."
                                ), requireAdminApproval ? React.createElement(DiffViewActionBox$WhoIsAdmin, {}) : null)) : (
                          currentViewerNotAdminButAdminApprovalRequired ? React.createElement("div", undefined, React.createElement($$Text.make, {
                                      size: "Medium",
                                      weight: "Semi",
                                      color: Styles.Color.grey90,
                                      children: "Waiting for " + numRequiredApprovals$1.toString() + " " + approvalsString + " to be merged"
                                    }), React.createElement($$Text.make, {
                                      size: "Medium",
                                      color: Styles.Color.grey70,
                                      children: null
                                    }, "Only admins can approve changes. Ask admins for a review.", requireAdminApproval ? React.createElement(DiffViewActionBox$WhoIsAdmin, {}) : null)) : (
                              currentViewerNotAdminOrEditor ? React.createElement("div", undefined, React.createElement($$Text.make, {
                                          size: "Medium",
                                          weight: "Semi",
                                          color: Styles.Color.grey90,
                                          children: "Waiting for " + numRequiredApprovals$1.toString() + " " + approvalsString
                                        }), React.createElement($$Text.make, {
                                          size: "Medium",
                                          color: Styles.Color.grey70,
                                          children: null
                                        }, "Only admins and editors can approve changes. Ask editors or admins for a review.", requireAdminApproval ? React.createElement(DiffViewActionBox$WhoIsAdmin, {}) : null)) : (
                                  currentViewerAlreadyApproved ? React.createElement("div", undefined, React.createElement($$Text.make, {
                                              size: "Medium",
                                              weight: "Semi",
                                              color: Styles.Color.grey90,
                                              children: "Thank you for reviewing!"
                                            }), React.createElement($$Text.make, {
                                              size: "Medium",
                                              color: Styles.Color.grey70,
                                              children: null
                                            }, "This branch can be merged after " + numRequiredApprovals$1.toString() + " more " + approvalsString + ".", requireAdminApproval ? React.createElement(DiffViewActionBox$WhoIsAdmin, {}) : null)) : React.createElement("div", undefined, React.createElement($$Text.make, {
                                              size: "Medium",
                                              weight: "Semi",
                                              color: Styles.Color.grey90,
                                              children: "Everything looking good? Approve the changes"
                                            }), React.createElement($$Text.make, {
                                              size: "Medium",
                                              color: Styles.Color.grey70,
                                              children: null
                                            }, "This branch requires " + numRequiredApprovals$1.toString() + " " + approvalsString + " to be merged.", requireAdminApproval ? React.createElement(DiffViewActionBox$WhoIsAdmin, {}) : null))
                                )
                            )
                        ), React.createElement(DiffViewActionBox$ApprovedBy, {
                            branchStatus: branchStatus
                          })), React.createElement("div", {
                        className: buttonBoxStyles
                      }, React.createElement(Button.make, {
                            disabled: approveDisabled || currentViewerAlreadyApproved,
                            label: "Approve changes",
                            loading: isLoading,
                            onClick: (function (param) {
                                return handleSetBranchStatus(branchStatus, "Approved", numApprovals + 1 | 0);
                              }),
                            size: "large"
                          }), React.createElement(Spacer.make, {
                            width: 16
                          }), React.createElement(DiffViewActionBox$Options, {
                            options: Belt_Array.concatMany([
                                  [
                                    options_requestChanges,
                                    options_markAsDraft
                                  ],
                                  protectedMainBranch && hasNoMergeConflicts && role === "Admin" ? [options_adminMerge] : (
                                      !protectedMainBranch && hasNoMergeConflicts ? [options_merge] : []
                                    ),
                                  [
                                    "Separator",
                                    options_delete
                                  ]
                                ])
                          }))), approvalWorkflow === "AvailableDuringTrial" ? React.createElement(DiffViewActionBox$ApprovalWorkflowsTrialingInfoBox, {
                      onUpgrade: handleUpgrade
                    }) : null);
  };
  var match$6 = workspace.settings.numRequiredApprovals;
  var tmp;
  var exit = 0;
  var approvalWorkflow$1;
  var branchStatus$1;
  if (typeof branchStatus === "number") {
    tmp = branchStatus === /* Master */0 ? null : "Loading...";
  } else {
    switch (branchStatus.TAG | 0) {
      case /* Merged */1 :
          tmp = mergeAction !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(DiffViewActionBox$StatusBox, {
                      action: Caml_option.valFromOption(mergeAction),
                      title: "Branch has been merged",
                      actionLabel: branchName + " was merged into main"
                    }), showVersionValidation ? React.createElement(DiffViewActionBox$InspectorValidation, {
                        branchStatus: branchStatus,
                        branchModel: branchModel,
                        breakingChanges: breakingChanges,
                        validationIntent: validationIntent
                      }) : null) : null;
          break;
      case /* Closed */2 :
          tmp = closeAction !== undefined ? React.createElement(DiffViewActionBox$StatusBox, {
                  action: Caml_option.valFromOption(closeAction),
                  title: "Branch has been deleted",
                  actionLabel: branchName + " was deleted"
                }) : null;
          break;
      default:
        if (approvalWorkflow === "AvailableDuringTrial" || approvalWorkflow === "Available") {
          var exit$1 = 0;
          if (typeof branchStatus !== "number") {
            switch (branchStatus.TAG | 0) {
              case /* Open */0 :
              case /* Draft */3 :
                  exit$1 = 2;
                  break;
              case /* ReadyForReview */4 :
                  tmp = match$6 !== 1 ? pendingMultipleApprovals(match$6, /* [] */0) : React.createElement(React.Fragment, undefined, React.createElement("div", {
                              className: contentStyles
                            }, currentViewerOpenedBranch ? React.createElement("div", undefined, React.createElement($$Text.make, {
                                        size: "Medium",
                                        weight: "Semi",
                                        color: Styles.Color.grey90,
                                        children: "You can't approve changes on your own branch"
                                      }), React.createElement($$Text.make, {
                                        size: "Medium",
                                        color: Styles.Color.grey70,
                                        children: null
                                      }, requireAdminApproval ? "Only admins can approve changes. Ask an admin for a review." : "Ask an admin or editor for a review.", requireAdminApproval ? React.createElement(DiffViewActionBox$WhoIsAdmin, {}) : null)) : (
                                currentViewerNotAdminButAdminApprovalRequired ? React.createElement("div", undefined, React.createElement($$Text.make, {
                                            size: "Medium",
                                            weight: "Semi",
                                            color: Styles.Color.grey90,
                                            children: "Waiting for admin approval"
                                          }), React.createElement($$Text.make, {
                                            size: "Medium",
                                            color: Styles.Color.grey70,
                                            children: null
                                          }, "Only admins can approve changes. Ask an admin for a review.", requireAdminApproval ? React.createElement(DiffViewActionBox$WhoIsAdmin, {}) : null)) : (
                                    currentViewerNotAdminOrEditor ? React.createElement("div", undefined, React.createElement($$Text.make, {
                                                size: "Medium",
                                                weight: "Semi",
                                                color: Styles.Color.grey90,
                                                children: "Waiting for approval from editor or admin"
                                              }), React.createElement($$Text.make, {
                                                size: "Medium",
                                                color: Styles.Color.grey70,
                                                children: "Only editors and admins can approve changes. Ask an editor or an admin for a review."
                                              })) : React.createElement($$Text.make, {
                                            size: "Medium",
                                            weight: "Semi",
                                            color: Styles.Color.grey90,
                                            children: "Everything looking good? Approve the changes before the branch gets merged"
                                          })
                                  )
                              ), React.createElement("div", {
                                  className: buttonBoxStyles
                                }, React.createElement(Button.make, {
                                      disabled: approveDisabled,
                                      label: "Approve changes",
                                      loading: isLoading,
                                      onClick: (function (param) {
                                          return handleSetBranchStatus(branchStatus, "Approved", numApprovals + 1 | 0);
                                        }),
                                      size: "large"
                                    }), React.createElement(Spacer.make, {
                                      width: 16
                                    }), React.createElement(DiffViewActionBox$Options, {
                                      options: Belt_Array.concatMany([
                                            [
                                              options_requestChanges,
                                              options_markAsDraft
                                            ],
                                            protectedMainBranch && hasNoMergeConflicts && role === "Admin" ? [options_adminMerge] : (
                                                !protectedMainBranch && hasNoMergeConflicts ? [options_merge] : []
                                              ),
                                            [
                                              "Separator",
                                              options_delete
                                            ]
                                          ])
                                    }))), approvalWorkflow === "AvailableDuringTrial" ? React.createElement(DiffViewActionBox$ApprovalWorkflowsTrialingInfoBox, {
                                onUpgrade: handleUpgrade
                              }) : null);
                  break;
              case /* ChangesRequested */5 :
                  tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
                            className: contentStyles
                          }, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                color: Styles.Color.grey90,
                                children: "Have the issues been fixed? Mark the branch as ready for review"
                              }), React.createElement("div", {
                                className: buttonBoxStyles
                              }, React.createElement(Button.make, {
                                    disabled: !Models.Role.canEdit(role),
                                    label: "Mark as ready for review",
                                    loading: isLoading,
                                    onClick: (function (param) {
                                        return handleSetBranchStatus(branchStatus, "ReadyForReview", numApprovals);
                                      }),
                                    size: "large"
                                  }), React.createElement(Spacer.make, {
                                    width: 16
                                  }), React.createElement(DiffViewActionBox$Options, {
                                    options: Belt_Array.concatMany([
                                          [options_markAsDraft],
                                          protectedMainBranch && hasNoMergeConflicts && role === "Admin" ? [options_adminMerge] : (
                                              !protectedMainBranch && hasNoMergeConflicts ? [options_merge] : []
                                            ),
                                          approveDisabled ? [] : [options_approve],
                                          [
                                            "Separator",
                                            options_delete
                                          ]
                                        ])
                                  }))), approvalWorkflow === "AvailableDuringTrial" ? React.createElement(DiffViewActionBox$ApprovalWorkflowsTrialingInfoBox, {
                              onUpgrade: handleUpgrade
                            }) : null);
                  break;
              case /* Approved */6 :
                  var approvals = branchStatus._0;
                  if (match$6 > Belt_List.length(approvals)) {
                    tmp = pendingMultipleApprovals(match$6, approvals);
                  } else {
                    approvalWorkflow$1 = approvalWorkflow;
                    branchStatus$1 = branchStatus;
                    exit = 1;
                  }
                  break;
              
            }
          }
          if (exit$1 === 2) {
            tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
                      className: contentStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.grey90,
                          children: "Done making changes for now? Get some feedback"
                        }), React.createElement("div", {
                          className: buttonBoxStyles
                        }, React.createElement(Button.make, {
                              disabled: !Models.Role.canEdit(role),
                              label: "Ready for review",
                              loading: isLoading,
                              onClick: (function (param) {
                                  return handleSetBranchStatus(branchStatus, "ReadyForReview", numApprovals);
                                }),
                              size: "large"
                            }), React.createElement(Spacer.make, {
                              width: 16
                            }), React.createElement(DiffViewActionBox$Options, {
                              options: Belt_Array.concatMany([
                                    [options_requestChanges],
                                    protectedMainBranch && hasNoMergeConflicts && role === "Admin" ? [options_adminMerge] : (
                                        !protectedMainBranch && hasNoMergeConflicts ? [options_merge] : []
                                      ),
                                    approveDisabled ? [] : [options_approve],
                                    [
                                      "Separator",
                                      options_delete
                                    ]
                                  ])
                            }))), approvalWorkflow === "AvailableDuringTrial" ? React.createElement(DiffViewActionBox$ApprovalWorkflowsTrialingInfoBox, {
                        onUpgrade: handleUpgrade
                      }) : null);
          }
          
        } else {
          approvalWorkflow$1 = approvalWorkflow;
          branchStatus$1 = branchStatus;
          exit = 1;
        }
    }
  }
  if (exit === 1) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: contentStyles
            }, React.createElement("div", undefined, blockMergeBecauseOfForceAudit ? React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Semi",
                        color: Styles.Color.grey90,
                        children: "Fix all issues to be able to merge the branch"
                      }) : React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Semi",
                        color: Styles.Color.grey90,
                        children: "Merge these changes into the main branch"
                      }), hasNoMergeConflicts ? null : React.createElement("div", {
                        className: buttonBoxStyles
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            textAlign: "right",
                            color: Styles.Color.grey50,
                            children: "Resolve the conflicts before merging"
                          })), React.createElement(DiffViewActionBox$ApprovedBy, {
                      branchStatus: branchStatus$1
                    })), blockMergeBecauseOfForceAudit ? React.createElement("div", {
                    className: buttonBoxStyles
                  }, React.createElement(Button.make, {
                        disabled: true,
                        id: "branch-tour-merge-button",
                        label: "Can't merge with issues",
                        onClick: (function (param) {
                            return handleMerge(false, numApprovals);
                          }),
                        size: "large"
                      }), React.createElement(Spacer.make, {
                        width: 16
                      }), React.createElement(DiffViewActionBox$Options, {
                        options: Belt_Array.concatMany([
                              approvalWorkflow$1 === "AvailableDuringTrial" || approvalWorkflow$1 === "Available" ? [
                                  options_requestChanges,
                                  options_markAsDraft,
                                  "Separator"
                                ] : [],
                              [options_delete]
                            ])
                      })) : React.createElement("div", {
                    className: buttonBoxStyles
                  }, React.createElement(Button.make, {
                        disabled: !Models.Role.canEdit(role) || !hasNoMergeConflicts,
                        id: "branch-tour-merge-button",
                        label: "Merge branch",
                        loading: isLoading,
                        onClick: (function (param) {
                            return handleMerge(false, numApprovals);
                          }),
                        size: "large"
                      }), React.createElement(Spacer.make, {
                        width: 16
                      }), React.createElement(DiffViewActionBox$Options, {
                        options: Belt_Array.concatMany([
                              approvalWorkflow$1 === "AvailableDuringTrial" || approvalWorkflow$1 === "Available" ? [
                                  options_requestChanges,
                                  options_markAsDraft,
                                  "Separator"
                                ] : [],
                              [options_delete]
                            ])
                      }))), autoPublishIntegrations === /* [] */0 ? null : React.createElement("div", {
                className: infoBoxStyle
              }, React.createElement(IconInfinity.make, {
                    size: 16
                  }), React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.width(Css.px(12)),
                          tl: /* [] */0
                        })
                  }), React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.grey70,
                    children: null
                  }, String(Belt_List.length(autoPublishIntegrations)), " of your ", React.createElement(Link.make, {
                        path: Router.Link.getSchemaRouteLink({
                              NAME: "branch",
                              VAL: branchId
                            }, "integrations"),
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.textDecoration("underline"),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        children: "Publishing Integrations"
                      }), " will be auto-published")), showVersionValidation ? React.createElement(DiffViewActionBox$InspectorValidation, {
                branchStatus: branchStatus$1,
                branchModel: branchModel,
                breakingChanges: breakingChanges,
                validationIntent: validationIntent
              }) : null, approvalWorkflow$1 === "Available" ? (
            canForceBranchAudit === "Available" ? null : (
                canForceBranchAudit === "AvailableDuringTrial" ? React.createElement(DiffViewActionBox$ForceBranchAuditTrialingInfoBox, {
                        onUpgrade: handleUpgrade
                      }) : React.createElement("div", {
                        className: infoBoxStyle
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.flexShrink(0.0),
                                  tl: {
                                    hd: Css.marginRight(Css.px(16)),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement(IconLightning.make, {})), React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.grey70,
                            children: null
                          }, "Lock merging branches with new audit issues on the Enterprise plan.", React.createElement(TextButton.make, {
                                onClick: handleUpgrade,
                                style: "Blue",
                                children: "Learn more"
                              })))
              )
          ) : (
            approvalWorkflow$1 === "AvailableDuringTrial" ? React.createElement(DiffViewActionBox$ApprovalWorkflowsTrialingInfoBox, {
                    onUpgrade: handleUpgrade
                  }) : React.createElement("div", {
                    className: infoBoxStyle
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.flexShrink(0.0),
                              tl: {
                                hd: Css.marginRight(Css.px(16)),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement(IconLightning.make, {})), React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.grey70,
                        children: null
                      }, "Want a better branch workflow? Unlock review requests and approvals by upgrading to Team or Enterprise. ", React.createElement(TextButton.make, {
                            onClick: handleUpgrade,
                            style: "Blue",
                            children: "Learn more"
                          })))
          ));
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: rootStyles,
                  id: "branch-tour-merge"
                }, tmp));
}

var make = DiffViewActionBox;

exports.sendBranchMergedAnalytics = sendBranchMergedAnalytics;
exports.rootStyles = rootStyles;
exports.contentStyles = contentStyles;
exports.columnContentStyles = columnContentStyles;
exports.buttonBoxStyles = buttonBoxStyles;
exports.infoBoxStyle = infoBoxStyle;
exports.StatusBox = StatusBox;
exports.ApprovalWorkflowsTrialingInfoBox = ApprovalWorkflowsTrialingInfoBox;
exports.ForceBranchAuditTrialingInfoBox = ForceBranchAuditTrialingInfoBox;
exports.Approval = Approval;
exports.ApprovedBy = ApprovedBy;
exports.WhoIsAdmin = WhoIsAdmin;
exports.Options = Options;
exports.MergeBranchModalBody = MergeBranchModalBody;
exports.InspectorValidation = InspectorValidation;
exports.make = make;
/* rootStyles Not a pure module */
