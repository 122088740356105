// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Styles = require("./styles.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var BillingPlans = require("./BillingPlans.bs.js");
var LoadingCircle = require("./LoadingCircle.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var Difference_in_calendar_days = require("date-fns/difference_in_calendar_days");

function Billing(Props) {
  var schema = Props.schema;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var paymentState = FirebaseFetcherHooks.usePayment(schema.id);
  var match = workspace.plan.name;
  if (typeof paymentState === "object") {
    var payment = paymentState.VAL;
    var exit = 0;
    var exit$1 = 0;
    switch (match) {
      case /* Free */1 :
      case /* FreeV2 */2 :
      case /* GrowthV2 */5 :
      case /* Enterprise */6 :
      case /* Starter */7 :
      case /* TeamV2 */8 :
          exit$1 = 3;
          break;
      case /* Empty */0 :
      case /* Scholarship */3 :
      case /* Growth */4 :
      case /* TeamV2Annual */9 :
          exit = 2;
          break;
      
    }
    if (exit$1 === 3) {
      if (payment.provider === "stripe") {
        return React.createElement(BillingPlans.make, {
                    currentPlanName: match,
                    payment: payment
                  });
      }
      exit = 2;
    }
    if (exit === 2 && payment.provider === "stripe" && payment.active === true) {
      return React.createElement(BillingPlans.make, {
                  currentPlanName: match,
                  payment: payment
                });
    }
    
  } else {
    if (paymentState !== "NotFound") {
      return React.createElement(LoadingCircle.make, {});
    }
    var exit$2 = 0;
    switch (match) {
      case /* Empty */0 :
      case /* Scholarship */3 :
      case /* Growth */4 :
          break;
      case /* Free */1 :
      case /* FreeV2 */2 :
      case /* GrowthV2 */5 :
      case /* Enterprise */6 :
      case /* Starter */7 :
      case /* TeamV2 */8 :
      case /* TeamV2Annual */9 :
          exit$2 = 2;
          break;
      
    }
    if (exit$2 === 2) {
      return React.createElement(BillingPlans.make, {
                  currentPlanName: match
                });
    }
    
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding4(Css.px(15), Css.px(15), Css.px(0), Css.px(15)),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.overflow("hidden"),
                          tl: {
                            hd: Css.margin4(Css.px(10), "auto", Css.px(10), "auto"),
                            tl: {
                              hd: Css.maxWidth(Css.px(500)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(25)),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.bold),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.huge),
                            tl: {
                              hd: Css.color(Styles.Color.grey90),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: {
                                  hd: Css.textAlign("center"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, "Billing"), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(25), Css.px(5)),
                        tl: {
                          hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey20),
                          tl: {
                            hd: Css.textAlign("center"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, "Get in touch so we can find the perfect plan for you!"), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.margin(Css.px(15)),
                        tl: /* [] */0
                      })
                }, React.createElement(Button.make, {
                      label: "Upgrade",
                      onClick: (function (param) {
                          window.open("https://avo.app/request-a-demo?email=" + Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).email, "_blank");
                          return AnalyticsRe.subscriptionInterestIndicated(schemaGroup, Difference_in_calendar_days(new Date(), Belt_Option.getWithDefault(workspace.createdAt, new Date())) | 0, 14, schemaGroup.branchId, schemaGroup.schemaId);
                        })
                    })));
}

var make = Billing;

exports.make = make;
/* Css Not a pure module */
