// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function getErrorMessageOptional(error) {
  if (error.RE_EXN_ID === $$Promise.JsError) {
    return error._1.message;
  } else if (error.RE_EXN_ID === Json_decode.DecodeError) {
    return error._1;
  } else {
    return ;
  }
}

function getErrorMessage(error) {
  if (error.RE_EXN_ID === $$Promise.JsError) {
    return Belt_Option.getWithDefault(error._1.message, "No message");
  } else if (error.RE_EXN_ID === Json_decode.DecodeError) {
    return error._1;
  } else {
    return "Internal Error";
  }
}

function resultFlatMap(promise, f) {
  return promise.then(function (result) {
              if (result.TAG === /* Ok */0) {
                return Curry._1(f, result._0);
              } else {
                return Promise.resolve(result);
              }
            });
}

function timeout(cb, timeout$1) {
  var timeoutPromise = new Promise((function (param, reject) {
          setTimeout((function (param) {
                  return reject(new Error("Promise timed out"));
                }), timeout$1);
          
        }));
  return Promise.race([
              cb,
              timeoutPromise
            ]);
}

exports.getErrorMessageOptional = getErrorMessageOptional;
exports.getErrorMessage = getErrorMessage;
exports.resultFlatMap = resultFlatMap;
exports.timeout = timeout;
/* No side effect */
