// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

function getFontWeight(x) {
  if (x === "medium") {
    return Styles.FontWeight.medium;
  } else if (x === "regular") {
    return Styles.FontWeight.regular;
  } else {
    return Styles.FontWeight.semi;
  }
}

function Monospace(Props) {
  var customColor = Props.color;
  var customWeight = Props.weight;
  var children = Props.children;
  return React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Belt_Option.mapWithDefault(customWeight, Styles.emptyStyle, (function (w) {
                            return Css.fontWeight(getFontWeight(w));
                          })),
                    tl: {
                      hd: Belt_Option.mapWithDefault(customColor, Styles.emptyStyle, Css.color),
                      tl: {
                        hd: Css.fontFamily(Styles.monoFontFamily),
                        tl: /* [] */0
                      }
                    }
                  })
            }, children);
}

var make = Monospace;

exports.getFontWeight = getFontWeight;
exports.make = make;
/* Css Not a pure module */
