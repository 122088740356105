// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Caml = require("rescript/lib/js/caml.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var DiffGoal = require("./DiffGoal.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var DiffEvent = require("./DiffEvent.bs.js");
var DiffMetric = require("./DiffMetric.bs.js");
var DiffSource = require("./DiffSource.bs.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var DiffComments = require("./DiffComments.bs.js");
var DiffProperty = require("./DiffProperty.bs.js");
var DiffGroupType = require("./DiffGroupType.bs.js");
var DiffDestination = require("./DiffDestination.bs.js");
var DiffIntegration = require("./DiffIntegration.bs.js");
var DiffPropertyGroup = require("./DiffPropertyGroup.bs.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");

function renderObjectDiff(branchStatus, fromModel, toModel, schemaId, branchId, goToCommentId, events, withCommentsOpt, actions) {
  var withComments = withCommentsOpt !== undefined ? withCommentsOpt : true;
  if (actions === /* [] */0) {
    return null;
  }
  var tmp;
  if (actions) {
    var match = actions.hd;
    if (match) {
      var match$1 = match.hd[0];
      var variant = match$1.NAME;
      tmp = variant === "Destination" ? "Destination changes" : (
          variant === "Integration" ? "Integration changes" : (
              variant === "GroupType" ? "Group Type changes" : (
                  variant === "Property" ? Belt_Option.mapWithDefault(StateUtils.getPropertyByIdWithArchive(match$1.VAL, toModel), "Property changes", (function (property) {
                            if (property.TAG === /* PropertyRef */0) {
                              return "Property changes";
                            }
                            var groupTypeId = property._0.sendAs;
                            if (typeof groupTypeId !== "number") {
                              return Belt_Option.getWithDefault(Belt_Option.map(StateUtils.getGroupTypeNameWithArchive(groupTypeId._0, toModel), (function (groupTypeName) {
                                                return groupTypeName + " Group Type Property changes";
                                              })), "Group Type Property changes");
                            }
                            switch (groupTypeId) {
                              case /* SystemProperty */0 :
                                  return "System Property changes";
                              case /* EventProperty */1 :
                                  return "Event Property changes";
                              case /* UserProperty */2 :
                                  return "User Property changes";
                              
                            }
                          })) : (
                      variant === "Event" ? "Event changes" : (
                          variant === "PropertyGroup" ? "Property Bundle changes" : (
                              variant === "Goal" ? "Category changes" : (
                                  variant === "Metric" ? "Metric changes" : "Source changes"
                                )
                            )
                        )
                    )
                )
            )
        );
    } else {
      tmp = Pervasives.failwith("Unknown diff type");
    }
  } else {
    tmp = Pervasives.failwith("Unknown diff type");
  }
  var match$2 = Belt_List.length(actions);
  var tmp$1;
  if (match$2 !== 1) {
    if (actions) {
      var match$3 = actions.hd;
      if (match$3) {
        var variant$1 = match$3.hd[0].NAME;
        tmp$1 = variant$1 === "Destination" ? "destinations" : (
            variant$1 === "Integration" ? "integrations" : (
                variant$1 === "GroupType" ? "group types" : (
                    variant$1 === "Property" ? "properties" : (
                        variant$1 === "Event" ? "events" : (
                            variant$1 === "PropertyGroup" ? "property bundles" : (
                                variant$1 === "Goal" ? "categories" : (
                                    variant$1 === "Metric" ? "metrics" : "sources"
                                  )
                              )
                          )
                      )
                  )
              )
          );
      } else {
        tmp$1 = Pervasives.failwith("Unknown diff type");
      }
    } else {
      tmp$1 = Pervasives.failwith("Unknown diff type");
    }
  } else if (actions) {
    var match$4 = actions.hd;
    if (match$4) {
      var variant$2 = match$4.hd[0].NAME;
      tmp$1 = variant$2 === "Destination" ? "destination" : (
          variant$2 === "Integration" ? "integration" : (
              variant$2 === "GroupType" ? "group type" : (
                  variant$2 === "Property" ? "property" : (
                      variant$2 === "Event" ? "event" : (
                          variant$2 === "PropertyGroup" ? "property bundle" : (
                              variant$2 === "Goal" ? "category" : (
                                  variant$2 === "Metric" ? "metric" : "source"
                                )
                            )
                        )
                    )
                )
            )
        );
    } else {
      tmp$1 = Pervasives.failwith("Unknown diff type");
    }
  } else {
    tmp$1 = Pervasives.failwith("Unknown diff type");
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.pct(100)),
                    tl: /* [] */0
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("baseline"),
                          tl: {
                            hd: Css.position("sticky"),
                            tl: {
                              hd: Css.top(Css.px(0)),
                              tl: {
                                hd: Css.marginTop(Css.px(20)),
                                tl: {
                                  hd: Css.marginRight(Css.px(withComments ? (DiffComments.commentsWidth + 20 | 0) - 1 | 0 : 0)),
                                  tl: {
                                    hd: Css.marginLeft(Css.px(-1)),
                                    tl: {
                                      hd: Css.marginBottom(Css.px(2)),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.large),
                                        tl: {
                                          hd: Css.color(Styles.Color.grey80),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.bold),
                                            tl: {
                                              hd: Css.padding2(Css.px(13), Css.px(20)),
                                              tl: {
                                                hd: Css.maxWidth(DiffEvent.maxWidthPx),
                                                tl: {
                                                  hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.grey20, 0.5)),
                                                  tl: {
                                                    hd: Css.zIndex(1),
                                                    tl: {
                                                      hd: Css.backdropFilter({
                                                            hd: {
                                                              NAME: "blur",
                                                              VAL: Css.px(10)
                                                            },
                                                            tl: /* [] */0
                                                          }),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Large",
                      weight: "Semi",
                      children: tmp
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: {
                                hd: Css.margin2(Css.px(2), Css.px(2)),
                                tl: {
                                  hd: Css.borderRadius(Css.px(100)),
                                  tl: {
                                    hd: Css.color(Styles.Color.white),
                                    tl: {
                                      hd: Css.backgroundColor(Styles.Color.mintGreen),
                                      tl: {
                                        hd: Css.marginLeft(Css.px(8)),
                                        tl: {
                                          hd: Css.padding2(Css.px(2), Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, String(Belt_List.length(actions)) + " " + tmp$1 + " changed")), Belt_List.toArray(Belt_List.map(actions, (function (objectActions) {
                        var tmp;
                        if (objectActions) {
                          var variant = objectActions.hd[0].NAME;
                          if (variant === "Destination") {
                            tmp = React.createElement(DiffDestination.make, {
                                  masterModel: fromModel,
                                  branchModel: toModel,
                                  actions: objectActions
                                });
                          } else if (variant === "Integration") {
                            var tmp$1 = {
                              masterModel: fromModel,
                              branchModel: toModel,
                              actions: objectActions,
                              schemaId: schemaId,
                              branchId: branchId,
                              withComments: withComments,
                              events: events
                            };
                            if (goToCommentId !== undefined) {
                              tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
                            }
                            tmp = React.createElement(DiffIntegration.make, tmp$1);
                          } else if (variant === "GroupType") {
                            tmp = React.createElement(DiffGroupType.make, {
                                  masterModel: fromModel,
                                  branchModel: toModel,
                                  actions: objectActions
                                });
                          } else if (variant === "Property") {
                            var tmp$2 = {
                              branchStatus: branchStatus,
                              masterModel: fromModel,
                              branchModel: toModel,
                              actions: objectActions,
                              schemaId: schemaId,
                              branchId: branchId,
                              withComments: withComments,
                              events: events
                            };
                            if (goToCommentId !== undefined) {
                              tmp$2.goToCommentId = Caml_option.valFromOption(goToCommentId);
                            }
                            tmp = React.createElement(DiffProperty.make, tmp$2);
                          } else if (variant === "Event") {
                            var tmp$3 = {
                              branchStatus: branchStatus,
                              masterModel: fromModel,
                              branchModel: toModel,
                              actions: objectActions,
                              schemaId: schemaId,
                              branchId: branchId,
                              events: events,
                              withComments: withComments
                            };
                            if (goToCommentId !== undefined) {
                              tmp$3.goToCommentId = Caml_option.valFromOption(goToCommentId);
                            }
                            tmp = React.createElement(DiffEvent.make, tmp$3);
                          } else if (variant === "PropertyGroup") {
                            var tmp$4 = {
                              masterModel: fromModel,
                              branchModel: toModel,
                              actions: objectActions,
                              schemaId: schemaId,
                              branchId: branchId,
                              withComments: withComments,
                              events: events
                            };
                            if (goToCommentId !== undefined) {
                              tmp$4.goToCommentId = Caml_option.valFromOption(goToCommentId);
                            }
                            tmp = React.createElement(DiffPropertyGroup.make, tmp$4);
                          } else if (variant === "Goal") {
                            var tmp$5 = {
                              masterModel: fromModel,
                              branchModel: toModel,
                              actions: objectActions,
                              schemaId: schemaId,
                              branchId: branchId,
                              withComments: withComments,
                              events: events
                            };
                            if (goToCommentId !== undefined) {
                              tmp$5.goToCommentId = Caml_option.valFromOption(goToCommentId);
                            }
                            tmp = React.createElement(DiffGoal.make, tmp$5);
                          } else if (variant === "Metric") {
                            var tmp$6 = {
                              masterModel: fromModel,
                              branchModel: toModel,
                              actions: objectActions,
                              schemaId: schemaId,
                              branchId: branchId,
                              withComments: withComments,
                              events: events
                            };
                            if (goToCommentId !== undefined) {
                              tmp$6.goToCommentId = Caml_option.valFromOption(goToCommentId);
                            }
                            tmp = React.createElement(DiffMetric.make, tmp$6);
                          } else {
                            tmp = React.createElement(DiffSource.make, {
                                  masterModel: fromModel,
                                  branchModel: toModel,
                                  actions: objectActions
                                });
                          }
                        } else {
                          tmp = Pervasives.failwith("Unknown diff type");
                        }
                        return React.createElement("div", {
                                    key: objectActions ? objectActions.hd[0].VAL : Pervasives.failwith("Unknown diff type"),
                                    className: Curry._1(Css.style, {
                                          hd: Css.marginBottom(Css.px(16)),
                                          tl: {
                                            hd: Css.width(Css.pct(100)),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, tmp);
                      }))));
}

function DiffViewUtils$RenderObjectsDiff(Props) {
  var branchStatus = Props.branchStatus;
  var fromModel = Props.fromModel;
  var toModel = Props.toModel;
  var actions = Props.actions;
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var goToCommentId = Props.goToCommentId;
  var withCommentsOpt = Props.withComments;
  var events = Props.events;
  var withComments = withCommentsOpt !== undefined ? withCommentsOpt : true;
  var eventActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Event";
          } else {
            return false;
          }
        }));
  var eventPropertyActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object" && match.NAME === "Property") {
            return Belt_Option.mapWithDefault(StateUtils.getPropertyByIdWithArchive(match.VAL, toModel), false, (function (property) {
                          if (property.TAG === /* PropertyRef */0) {
                            return false;
                          } else {
                            return property._0.sendAs === 1;
                          }
                        }));
          } else {
            return false;
          }
        }));
  var userPropertyActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object" && match.NAME === "Property") {
            return Belt_Option.mapWithDefault(StateUtils.getPropertyByIdWithArchive(match.VAL, toModel), false, (function (property) {
                          if (property.TAG === /* PropertyRef */0) {
                            return false;
                          }
                          var match = property._0.sendAs;
                          if (typeof match === "number") {
                            return match >= 2;
                          } else {
                            return false;
                          }
                        }));
          } else {
            return false;
          }
        }));
  var systemPropertyActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object" && match.NAME === "Property") {
            return Belt_Option.mapWithDefault(StateUtils.getPropertyByIdWithArchive(match.VAL, toModel), false, (function (property) {
                          if (property.TAG === /* PropertyRef */0) {
                            return false;
                          } else {
                            return property._0.sendAs === 0;
                          }
                        }));
          } else {
            return false;
          }
        }));
  var __x = Belt_List.keepMap(actions, (function (action) {
          if (!action) {
            return ;
          }
          var match = action.hd[0];
          if (typeof match === "object" && match.NAME === "Property") {
            return Belt_Option.mapWithDefault(StateUtils.getPropertyByIdWithArchive(match.VAL, toModel), undefined, (function (property) {
                          if (property.TAG === /* PropertyRef */0) {
                            return ;
                          }
                          var groupTypeId = property._0.sendAs;
                          if (typeof groupTypeId === "number") {
                            return ;
                          } else {
                            return [
                                    groupTypeId._0,
                                    action
                                  ];
                          }
                        }));
          }
          
        }));
  var groupedGroupTypePropertyActions = Belt_List.map(BeltListExtensions.group((function (a, b) {
              return Caml.caml_string_compare(a[0], b[0]);
            }), __x), (function (actions) {
          return Belt_List.map(actions, (function (param) {
                        return param[1];
                      }));
        }));
  var propertyGroupActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "PropertyGroup";
          } else {
            return false;
          }
        }));
  var sourceActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Source";
          } else {
            return false;
          }
        }));
  var destinationActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Destination";
          } else {
            return false;
          }
        }));
  var goalActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Goal";
          } else {
            return false;
          }
        }));
  var metricActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Metric";
          } else {
            return false;
          }
        }));
  var integrationActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Integration";
          } else {
            return false;
          }
        }));
  var groupTypeActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "GroupType";
          } else {
            return false;
          }
        }));
  var partial_arg = withComments;
  var renderObjectDiff$1 = function (param) {
    return renderObjectDiff(branchStatus, fromModel, toModel, schemaId, branchId, goToCommentId, events, partial_arg, param);
  };
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  })
            }, Curry._1(renderObjectDiff$1, goalActions), Curry._1(renderObjectDiff$1, metricActions), Curry._1(renderObjectDiff$1, groupTypeActions), Curry._1(renderObjectDiff$1, eventActions), Curry._1(renderObjectDiff$1, eventPropertyActions), Curry._1(renderObjectDiff$1, userPropertyActions), Curry._1(renderObjectDiff$1, systemPropertyActions), Belt_List.toArray(Belt_List.map(groupedGroupTypePropertyActions, Curry.__1(renderObjectDiff$1))), Curry._1(renderObjectDiff$1, propertyGroupActions), Curry._1(renderObjectDiff$1, sourceActions), Curry._1(renderObjectDiff$1, destinationActions), Curry._1(renderObjectDiff$1, integrationActions));
}

var RenderObjectsDiff = {
  make: DiffViewUtils$RenderObjectsDiff
};

exports.renderObjectDiff = renderObjectDiff;
exports.RenderObjectsDiff = RenderObjectsDiff;
/* Css Not a pure module */
