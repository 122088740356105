// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var CopyButton = require("../CopyButton.bs.js");

function code(docsCodeBoxStyles, docsCodeStyles, docsCodeLightBlueStyles, docsCodeMintGreenStyles, copyButtonBoxStyles, language) {
  if (language === "java") {
    return React.createElement("div", {
                className: docsCodeBoxStyles
              }, React.createElement("pre", {
                    className: docsCodeStyles
                  }, "avoInspector.trackSchemaFromEvent(", React.createElement("span", {
                        className: docsCodeMintGreenStyles
                      }, "\"Event name\""), React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, ", new"), " HashMap<String", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, ","), " Object>() {{", "\n   put(", React.createElement("span", {
                        className: docsCodeMintGreenStyles
                      }, "\"id\""), React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, ","), React.createElement("span", {
                        className: docsCodeMintGreenStyles
                      }, " \"...\""), ")", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, ";"), "\n   put(", React.createElement("span", {
                        className: docsCodeMintGreenStyles
                      }, "\"number\""), React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, ","), React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, " 41"), ")", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, ";"), "\n}})", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, ";")), React.createElement("div", {
                    className: copyButtonBoxStyles
                  }, React.createElement(CopyButton.make, {
                        copyString: "avoInspector.trackSchemaFromEvent(\"Event name\", new HashMap<String, Object>() {{\n            put(\"id\", \"...\");\n            put(\"number\", 41);\n        }});"
                      })));
  } else {
    return null;
  }
}

exports.code = code;
/* react Not a pure module */
