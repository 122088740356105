// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Icon = require("./Icon.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Sidebar = require("./Sidebar.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Timestamp = require("./Timestamp.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var ContextMenu = require("./ContextMenu.bs.js");
var NamedBranch = require("./NamedBranch.bs.js");
var RouterStore = require("./RouterStore.bs.js");
var AppFeatureFlag = require("./AppFeatureFlag.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var BranchStateWriter = require("./BranchStateWriter.bs.js");
var CollaboratorsView = require("./CollaboratorsView.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var BranchImplementationHooks = require("./branchImplementation/BranchImplementationHooks.bs.js");
var BranchImplementationUtils = require("./branchImplementation/BranchImplementationUtils.bs.js");

var containerStyle = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(Sidebar.sidebarWidth)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey20),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.height(Css.px(80)),
                      tl: {
                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(20), undefined, undefined, Css.rgba(0, 0, 0, {
                                      NAME: "num",
                                      VAL: 0.05
                                    }))),
                        tl: {
                          hd: Css.zIndex(1),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var mainInfoStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.flexGrow(1.0),
          tl: {
            hd: Css.paddingLeft(Css.px(20)),
            tl: {
              hd: Css.minWidth(Css.px(0)),
              tl: {
                hd: Css.flexShrink(10000.0),
                tl: {
                  hd: Css.marginTop(Css.px(20)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function statusPillStyles(customColor) {
  return Curry._1(Css.style, {
              hd: Css.marginRight(Css.px(4)),
              tl: {
                hd: Css.borderRadius(Css.px(32)),
                tl: {
                  hd: Css.paddingLeft(Css.px(6)),
                  tl: {
                    hd: Css.paddingRight(Css.px(6)),
                    tl: {
                      hd: Css.paddingTop(Css.px(2)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(1)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.setAlpha(customColor, 0.1)),
                          tl: {
                            hd: Css.selector(" p", {
                                  hd: Css.fontSize(Css.px(9)),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.color(customColor),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var tabs = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: /* [] */0
    });

function tab(isCurrent) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.paddingBottom(Css.px(5)),
                            tl: {
                              hd: Css.color(isCurrent ? Styles.Color.magenta : Styles.Color.grey70),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$short
                                    }, undefined, undefined, "colora"),
                                tl: {
                                  hd: Css.marginRight(Css.px(16)),
                                  tl: {
                                    hd: Css.lastChild({
                                          hd: Css.marginRight(Css.px(0)),
                                          tl: /* [] */0
                                        }),
                                    tl: {
                                      hd: Css.hover({
                                            hd: Css.color(Styles.Color.magenta),
                                            tl: {
                                              hd: Css.before({
                                                    hd: Css.color(Styles.Color.grey70),
                                                    tl: /* [] */0
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: {
                                        hd: Css.selector(":hover path", {
                                              hd: Css_Legacy_Core.SVG.fill(Styles.Color.magenta),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.backgroundColor("transparent"),
                                          tl: {
                                            hd: Css.after({
                                                  hd: Css.unsafe("content", "' '"),
                                                  tl: {
                                                    hd: Css.position("absolute"),
                                                    tl: {
                                                      hd: Css.bottom(Css.px(-1)),
                                                      tl: {
                                                        hd: Css.right(Css.px(0)),
                                                        tl: {
                                                          hd: Css.left(Css.px(0)),
                                                          tl: {
                                                            hd: Css.height(Css.px(2)),
                                                            tl: {
                                                              hd: Css.backgroundColor(isCurrent ? Styles.Color.magenta : "transparent"),
                                                              tl: {
                                                                hd: Css.transition({
                                                                      NAME: "ms",
                                                                      VAL: Styles.Duration.$$short
                                                                    }, undefined, undefined, "background-color"),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var contextMenu = Curry._1(Css.style, {
      hd: Css.height(Css.pct(100.0)),
      tl: {
        hd: Css.padding(Css.px(16)),
        tl: {
          hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey20),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.justifyContent("center"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function BranchHeader(Props) {
  var branchId = Props.branchId;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var schemaId = Props.schemaId;
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var actions = Props.actions;
  var branchOpenAction = Props.branchOpenAction;
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var hasRefreshBranchMenuFeatureFlag = AppFeatureFlag.useFeatureFlag("RefreshBranchStateMenuOption");
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var sendActions = SendActionsContext.use(undefined);
  var match = BranchImplementationHooks.useSourceUpdates(actions, branchModel, masterModel, false);
  var totalCodeChanges = Belt_List.length(Belt_List.flatten(Belt_List.map(match.sourceUpdates, (function (param) {
                  return BranchImplementationUtils.filterEvents("any", undefined, undefined, param[0].id, param[1]);
                }))));
  var lastLifecycleAction;
  if (typeof branchStatus === "number") {
    lastLifecycleAction = branchOpenAction;
  } else {
    switch (branchStatus.TAG | 0) {
      case /* Merged */1 :
      case /* Closed */2 :
          lastLifecycleAction = branchStatus._0;
          break;
      default:
        lastLifecycleAction = branchOpenAction;
    }
  }
  var schemaGroup = SchemaGroupContext.use(undefined);
  var userId = lastLifecycleAction.createdBy;
  var user = FirebaseFetcherHooks.useUser(userId);
  var match$1 = AvoLimits.ApprovalWorkflow.computeAvailability(workspace);
  var approvalWorkflow = match$1 === "AvailableDuringTrial" || match$1 === "Available";
  var handleDelete = function (param) {
    if (window.confirm("Are you sure you want to delete the " + (branchName + " branch?"))) {
      Router.Schema.pushSchemaRoute("master", undefined, "dashboard");
      return Curry.app(sendActions, [
                  "Master",
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      return AnalyticsRe.branchDeleted(schemaGroup, NamedBranch.getId(branch), schemaGroup.schemaId);
                    }),
                  undefined,
                  [[
                      {
                        NAME: "CloseBranch",
                        VAL: [
                          branchId,
                          branchName
                        ]
                      },
                      {
                        branchId: branchId,
                        branchQuery: branchId
                      }
                    ]]
                ]);
    }
    
  };
  var handleSyncBranchState = function (param) {
    BranchStateWriter.refreshFullBranchState(schemaId, branchId, branchModel, masterModel);
    
  };
  var branchStatus$1;
  if (typeof branchStatus === "number" || branchStatus.TAG !== /* Approved */6) {
    branchStatus$1 = branchStatus;
  } else {
    var approvals = branchStatus._0;
    branchStatus$1 = Belt_List.length(approvals) < workspace.settings.numRequiredApprovals ? ({
          TAG: /* ReadyForReview */4,
          _0: Belt_List.headExn(approvals)
        }) : branchStatus;
  }
  var tmp;
  if (typeof branchStatus$1 === "number") {
    tmp = approvalWorkflow ? Styles.Color.grey80 : Styles.Color.grey90;
  } else {
    switch (branchStatus$1.TAG | 0) {
      case /* Merged */1 :
          tmp = Styles.Color.blue;
          break;
      case /* Closed */2 :
          tmp = Styles.Color.grapeError;
          break;
      case /* ReadyForReview */4 :
      case /* ChangesRequested */5 :
          tmp = Styles.Color.orangeSecondary;
          break;
      case /* Approved */6 :
          tmp = Styles.Color.mintGreenSecondary;
          break;
      default:
        tmp = approvalWorkflow ? Styles.Color.grey80 : Styles.Color.grey90;
    }
  }
  var tmp$1;
  if (typeof branchStatus$1 === "number") {
    tmp$1 = branchStatus$1 === /* Master */0 ? null : "LOADING";
  } else {
    switch (branchStatus$1.TAG | 0) {
      case /* Merged */1 :
          tmp$1 = "MERGED";
          break;
      case /* Closed */2 :
          tmp$1 = "DELETED";
          break;
      case /* Open */0 :
      case /* Draft */3 :
          tmp$1 = approvalWorkflow ? "DRAFT" : "OPEN";
          break;
      case /* ReadyForReview */4 :
          tmp$1 = "READY FOR REVIEW";
          break;
      case /* ChangesRequested */5 :
          tmp$1 = "CHANGES REQUESTED";
          break;
      case /* Approved */6 :
          tmp$1 = "APPROVED";
          break;
      
    }
  }
  var tmp$2;
  if (typeof branchStatus$1 === "number") {
    tmp$2 = branchStatus$1 === /* Master */0 ? null : "Loading...";
  } else {
    switch (branchStatus$1.TAG | 0) {
      case /* Merged */1 :
          tmp$2 = "Merged by ";
          break;
      case /* Closed */2 :
          tmp$2 = "Deleted by ";
          break;
      case /* Open */0 :
      case /* Draft */3 :
      case /* ReadyForReview */4 :
      case /* ChangesRequested */5 :
      case /* Approved */6 :
          tmp$2 = "Opened by ";
          break;
      
    }
  }
  var tmp$3;
  if (typeof schemaRoute === "object" && schemaRoute.NAME === "diff") {
    var match$2 = schemaRoute.VAL;
    tmp$3 = typeof match$2 === "object" ? match$2.NAME === "implementation" : false;
  } else {
    tmp$3 = false;
  }
  var tmp$4 = false;
  if (totalCodeChanges > 0) {
    var tmp$5;
    if (typeof schemaRoute === "object" && schemaRoute.NAME === "diff") {
      var match$3 = schemaRoute.VAL;
      tmp$5 = typeof match$3 === "object" ? match$3.NAME === "implementation" : false;
    } else {
      tmp$5 = false;
    }
    tmp$4 = tmp$5;
  }
  var isClosedWithRefreshFlag;
  if (typeof branchStatus$1 === "number") {
    isClosedWithRefreshFlag = branchStatus$1 === /* Master */0 ? hasRefreshBranchMenuFeatureFlag : false;
  } else {
    switch (branchStatus$1.TAG | 0) {
      case /* Merged */1 :
      case /* Closed */2 :
          isClosedWithRefreshFlag = hasRefreshBranchMenuFeatureFlag;
          break;
      default:
        isClosedWithRefreshFlag = false;
    }
  }
  var isOpen;
  if (typeof branchStatus$1 === "number") {
    isOpen = false;
  } else {
    switch (branchStatus$1.TAG | 0) {
      case /* Merged */1 :
      case /* Closed */2 :
          isOpen = false;
          break;
      default:
        isOpen = true;
    }
  }
  return React.createElement("header", {
              className: containerStyle
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.flexGrow(1.0),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: mainInfoStyles
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.gap(Css.px(6)),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, React.createElement(Icon.make, {
                                  type_: "branch",
                                  size: "small",
                                  color: Styles.Color.grey60
                                }), React.createElement($$Text.make, {
                                  element: "Span",
                                  size: "Large",
                                  weight: "Semi",
                                  singleLine: true,
                                  color: Styles.Color.grey90,
                                  children: branchName
                                }), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.gap(Css.px(4)),
                                            tl: /* [] */0
                                          }
                                        }
                                      })
                                }, React.createElement("div", {
                                      className: statusPillStyles(tmp)
                                    }, React.createElement($$Text.make, {
                                          size: "Tiny",
                                          weight: "Semi",
                                          lines: 1,
                                          children: tmp$1
                                        })), React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.flexShrink(0.0),
                                            tl: /* [] */0
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Small",
                                          color: Styles.Color.grey70,
                                          children: null
                                        }, tmp$2, React.createElement($$Text.make, {
                                              element: "Span",
                                              size: "Small",
                                              weight: "Semi",
                                              children: typeof user === "object" && user.NAME === "Success" ? AvoConfig.getUserDisplayName(user.VAL) : "Member"
                                            }), " ", React.createElement(Timestamp.make, {
                                              date: lastLifecycleAction.createdAt
                                            }))))), React.createElement(Spacer.make, {
                              height: 12
                            }), React.createElement("div", {
                              className: tabs
                            }, React.createElement("button", {
                                  className: tab(Caml_obj.caml_equal(schemaRoute, {
                                            NAME: "diff",
                                            VAL: "index"
                                          })),
                                  onClick: (function (param) {
                                      return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                                  NAME: "diff",
                                                  VAL: "index"
                                                });
                                    })
                                }, React.createElement($$Text.make, {
                                      element: "Span",
                                      size: "Small",
                                      weight: "Semi",
                                      children: "Tracking Plan Changes"
                                    })), React.createElement("button", {
                                  className: tab(tmp$3),
                                  id: "code-changes-tab",
                                  onClick: (function (param) {
                                      return Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                                                      NAME: "diff",
                                                      VAL: {
                                                        NAME: "implementation",
                                                        VAL: "overview"
                                                      }
                                                    }));
                                    })
                                }, React.createElement($$Text.make, {
                                      element: "Span",
                                      size: "Small",
                                      weight: "Semi",
                                      children: "Code Changes"
                                    }), React.createElement("span", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.marginLeft(Css.px(4)),
                                            tl: {
                                              hd: Css.padding2(Css.px(1), Css.px(4)),
                                              tl: {
                                                hd: Css.backgroundColor(tmp$4 ? Styles.Color.magenta100 : Styles.Color.magenta70),
                                                tl: {
                                                  hd: Css.borderRadius(Css.px(16)),
                                                  tl: {
                                                    hd: Css.color(Styles.Color.white),
                                                    tl: {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.alignItems("center"),
                                                        tl: {
                                                          hd: Css.justifyContent("center"),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          })
                                    }, React.createElement($$Text.make, {
                                          element: "Span",
                                          size: "Tiny",
                                          weight: "Semi",
                                          singleLine: true,
                                          children: String(totalCodeChanges)
                                        }))))), React.createElement(Spacer.make, {
                          width: 8,
                          grow: 1.0
                        }), React.createElement(CollaboratorsView.make, {
                          schemaId: schemaId,
                          userId: userId,
                          branchId: branchId,
                          sendActions: sendActions
                        }), isOpen || isClosedWithRefreshFlag ? React.createElement("div", {
                            className: contextMenu
                          }, React.createElement(ContextMenu.V2.make, {
                                withBorder: true,
                                position: "bottom-end",
                                children: null
                              }, isClosedWithRefreshFlag ? React.createElement(ContextMenu.V2.Item.make, {
                                      label: "Sync Branch State",
                                      onClick: handleSyncBranchState
                                    }) : null, isOpen ? React.createElement(ContextMenu.V2.Item.make, {
                                      label: "Delete Branch",
                                      onClick: handleDelete,
                                      style: "Warning"
                                    }) : null)) : null)));
}

var branchHeaderHeight = 80;

var make = BranchHeader;

exports.branchHeaderHeight = branchHeaderHeight;
exports.containerStyle = containerStyle;
exports.mainInfoStyles = mainInfoStyles;
exports.statusPillStyles = statusPillStyles;
exports.tabs = tabs;
exports.tab = tab;
exports.contextMenu = contextMenu;
exports.make = make;
/* containerStyle Not a pure module */
