// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconLink(Props) {
  var sizeOpt = Props.size;
  var color = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  return React.createElement("svg", {
              width: String(size),
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  className: Curry._1(Css.style, {
                        hd: Css_Legacy_Core.SVG.fill(color),
                        tl: /* [] */0
                      })
                }, React.createElement("path", {
                      d: "M4.5,16c-1.2,0-2.3-0.5-3.2-1.3c-1.8-1.8-1.8-4.6,0-6.4L2,7.6L3.4,9L2.7,9.7 c-1,1-1,2.6,0,3.6c1,1,2.6,1,3.6,0l3-3c1-1,1-2.6,0-3.6L8.6,6L10,4.6l0.7,0.7c1.8,1.8,1.8,4.6,0,6.4l-3,3C6.9,15.5,5.7,16,4.5,16z"
                    }), React.createElement("path", {
                      d: "M6,11.4l-0.7-0.7c-1.8-1.8-1.8-4.6,0-6.4l3-3c0.9-0.9,2-1.3,3.2-1.3s2.3,0.5,3.2,1.3c1.8,1.8,1.8,4.6,0,6.4 L14,8.4L12.6,7l0.7-0.7c1-1,1-2.6,0-3.6c-1-1-2.6-1-3.6,0l-3,3c-1,1-1,2.6,0,3.6L7.4,10L6,11.4z"
                    })));
}

var make = IconLink;

exports.make = make;
/* Css Not a pure module */
