// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var EventType = require("./EventType.bs.js");
var IconEventType = require("./IconEventType.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");

function DiffEventActions(Props) {
  var actions = Props.actions;
  var addedTypes = Belt_List.keepMapU(actions, (function (action) {
          if (typeof action === "object" && action.NAME === "AddEventType") {
            return action.VAL[1];
          }
          
        }));
  var removedTypes = Belt_List.keepMapU(actions, (function (action) {
          if (typeof action === "object" && action.NAME === "RemoveEventType") {
            return action.VAL[1];
          }
          
        }));
  var updatedTypes = Belt_List.concat(addedTypes, removedTypes);
  if (updatedTypes) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("flexStart"),
                        tl: {
                          hd: Css.padding2(Css.px(10), Css.px(0)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.margin4(Css.px(0), Css.px(10), Css.px(0), Css.px(0)),
                          tl: /* [] */0
                        })
                  }, React.createElement(IconEventType.make, {
                        size: 14
                      })), React.createElement("div", undefined, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.grey80,
                        children: "Actions"
                      }), React.createElement(Spacer.make, {
                        height: 4
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: /* [] */0
                              }
                            })
                      }, Belt_List.toArray(Belt_List.concatMany([
                                Belt_List.mapU(removedTypes, (function (type_) {
                                        return React.createElement(EventType.make, {
                                                    type_: type_,
                                                    change: "Removed",
                                                    key: TrackingPlanModel.eventTypeToJs(type_) + "-removed"
                                                  });
                                      })),
                                Belt_List.mapU(addedTypes, (function (type_) {
                                        return React.createElement(EventType.make, {
                                                    type_: type_,
                                                    change: "Added",
                                                    key: TrackingPlanModel.eventTypeToJs(type_) + "-added"
                                                  });
                                      }))
                              ])))));
  } else {
    return null;
  }
}

var make = DiffEventActions;

exports.make = make;
/* Css Not a pure module */
