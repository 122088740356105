// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Router = require("./Router.bs.js");
var DateFns = require("./DateFns.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var FirebaseUtils = require("./firebaseUtils.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");

function toString(inspectorValidation) {
  if (inspectorValidation === undefined) {
    return "Don’t ignore";
  }
  var variant = inspectorValidation.NAME;
  if (variant === "oneHourAfterMerge") {
    return "1h after merge (" + DateFns.format("D MMM, YYYY \\at HH:mm", inspectorValidation.VAL) + ")";
  } else if (variant === "currentVersion") {
    return "Current version at merge (" + inspectorValidation.VAL + ")";
  } else if (variant === "twentyFourHoursAfterMerge") {
    return "24h after merge (" + DateFns.format("D MMM, YYYY \\at HH:mm", inspectorValidation.VAL) + ")";
  } else if (variant === "customVersion") {
    return "Custom version at merge (" + inspectorValidation.VAL + ")";
  } else if (variant === "customTime") {
    return "Custom time (" + DateFns.format("D MMM, YYYY \\at HH:mm", inspectorValidation.VAL) + ")";
  } else {
    return "Next version at merge (" + inspectorValidation.VAL + ")";
  }
}

function getIntents(param) {
  var schemaId = Router.Schema.getSchemaId(undefined);
  var branchId = Router.Schema.getBranchId(undefined);
  return Promise.all([
                FirebaseUtils.fetchInspectorValidationIntents(undefined, schemaId),
                FirebaseUtils.fetchInspectorValidationIntents(branchId, schemaId)
              ]).then(function (param) {
              return Promise.resolve(Belt_MapString.mergeU(param[0], param[1], (function (_sourceId, maybeDefaultIntent, maybeBranchIntent) {
                                if (maybeBranchIntent !== undefined) {
                                  return maybeBranchIntent;
                                } else if (maybeDefaultIntent !== undefined) {
                                  return maybeDefaultIntent;
                                } else {
                                  return ;
                                }
                              })));
            });
}

function getIgnoredSources(breakingSources, validation) {
  return Belt_MapString.keysToArray(Belt_MapString.keep(validation, (function (sourceId, validation) {
                    if (validation !== "dontIgnore") {
                      return Belt_SetString.has(breakingSources, sourceId);
                    } else {
                      return false;
                    }
                  })));
}

function allSourcesHaveIntent(breakingSources, validation) {
  return Belt_SetString.eq(breakingSources, Belt_SetString.fromArray(getIgnoredSources(breakingSources, validation)));
}

function noSourcesHaveIntent(breakingSources, validation) {
  return Belt_SetString.isEmpty(Belt_SetString.fromArray(getIgnoredSources(breakingSources, validation)));
}

function getValidationReport(changeReport, validation) {
  var ignoredSources = Belt_SetString.fromArray(getIgnoredSources(Belt_SetString.fromArray(changeReport.breakingSources), validation));
  var match = Belt_MapString.partition(changeReport.breakingEventIdsBySource, (function (sourceId, param) {
          return Belt_SetString.has(ignoredSources, sourceId);
        }));
  var passingEventsBySource = match[1];
  var ignoredEvents = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.map(Belt_MapString.valuesToArray(match[0]), Belt_SetString.toArray))));
  var passingEvents = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.map(Belt_MapString.valuesToArray(passingEventsBySource), Belt_SetString.toArray))));
  return {
          passingEvents: passingEvents,
          passingSources: Belt_MapString.keysToArray(passingEventsBySource),
          ignoredEvents: ignoredEvents,
          ignoredSources: Belt_SetString.toArray(ignoredSources)
        };
}

exports.toString = toString;
exports.getIntents = getIntents;
exports.getIgnoredSources = getIgnoredSources;
exports.allSourcesHaveIntent = allSourcesHaveIntent;
exports.noSourcesHaveIntent = noSourcesHaveIntent;
exports.getValidationReport = getValidationReport;
/* Router Not a pure module */
