// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var Icons = require("./Icons.bs.js");
var Title = require("./Title.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Actions = require("./actions.bs.js");
var AvoTable = require("./AvoTable.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var IconErase = require("./IconErase.bs.js");
var IconTrees = require("./IconTrees.bs.js");
var Timestamp = require("./Timestamp.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var ModelStore = require("./ModelStore.bs.js");
var PortalMenu = require("./PortalMenu.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var Fuzzaldrin = require("fuzzaldrin");
var ActivityLog = require("./ActivityLog.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var IconChevron = require("./IconChevron.bs.js");
var RouterStore = require("./RouterStore.bs.js");
var ProfilePhoto = require("./ProfilePhoto.bs.js");
var TrialRequest = require("./TrialRequest.bs.js");
var App = require("firebase/app");
var Collaborators = require("./Collaborators.bs.js");
var FetchActivity = require("./FetchActivity.bs.js");
var PortalTooltip = require("./PortalTooltip.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SortArray = require("rescript/lib/js/belt_SortArray.js");
var SegmentControl = require("./SegmentControl.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var DrawerContainer = require("./DrawerContainer.bs.js");
var EntityLightning = require("./EntityLightning.bs.js");
var BranchStatusPill = require("./BranchStatusPill.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var PortalMenuAnimator = require("./PortalMenuAnimator.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var Compare_desc = require("date-fns/compare_desc");
var TrackingPlanMappedModel = require("../../model/src/TrackingPlanMappedModel.bs.js");
var BranchStateFirebaseModel = require("../../shared/models/BranchStateFirebaseModel.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

function SchemaDashboard$Creator(Props) {
  var id = Props.id;
  var withImageOpt = Props.withImage;
  var withImage = withImageOpt !== undefined ? withImageOpt : true;
  var userState = FirebaseFetcherHooks.useUser(id);
  if (typeof userState !== "object") {
    return null;
  }
  var user = userState.VAL;
  return React.createElement("div", {
              className: rootStyles
            }, withImage ? React.createElement(React.Fragment, undefined, React.createElement(ProfilePhoto.make, {
                        user: user,
                        size: 24
                      }), React.createElement(Spacer.make, {
                        width: 8
                      })) : null, React.createElement($$Text.make, {
                  element: "Span",
                  size: "Small",
                  singleLine: true,
                  children: AvoConfig.getUserDisplayName(user)
                }));
}

var Creator = {
  rootStyles: rootStyles,
  make: SchemaDashboard$Creator
};

function SchemaDashboard$LastCodegens(Props) {
  var lastCodegenBySource = Props.lastCodegenBySource;
  var sources = ModelStore.useMappedSources(undefined);
  var codegens = Belt_SortArray.stableSortByU(Belt_Array.map(Belt_MapString.toArray(lastCodegenBySource), (function (param) {
              var match = param[1];
              var lastCodegenDate = match.lastCodegenDate;
              return {
                      userId: match.lastCodegenUserId,
                      sourceName: Belt_Option.getWithDefault(TrackingPlanMappedModel.Sources.getNameById(sources, param[0]), "Untitled Source"),
                      date: lastCodegenDate,
                      lastCodegen: Timestamp.renderTimestamp(App.default.firestore.Timestamp.fromDate(lastCodegenDate))
                    };
            })), (function (a, b) {
          return Compare_desc(a.date, b.date);
        }));
  var users = FirebaseFetcherHooks.useUsers(Belt_Array.mapU(codegens, (function (codegen) {
              return codegen.userId;
            })));
  if (typeof users !== "object") {
    return null;
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var codegenByUserAndSource = Belt_Array.keepMap(codegens, (function (info) {
          var user = Belt_Array.getByU(users$1, (function (param) {
                  return param.id === info.userId;
                }));
          if (user !== undefined) {
            return AvoConfig.getUserDisplayName(user) + " on " + info.sourceName + " " + info.lastCodegen;
          }
          
        }));
  return React.createElement($$Text.make, {
              size: "Small",
              lines: 3,
              color: Styles.Color.grey80,
              title: codegenByUserAndSource.join("\n"),
              children: codegenByUserAndSource.join(", ")
            });
}

var LastCodegens = {
  make: SchemaDashboard$LastCodegens
};

function SchemaDashboard$BranchRow$InformationMissing(Props) {
  return React.createElement(PortalTooltip.make, {
              renderTooltip: (function (param) {
                  return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                  height: 4
                                }), React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  color: Styles.Color.white,
                                  maxWidth: Css.px(250),
                                  children: "Value will be calculated on the next branch interaction (e.g. change or comment)"
                                }));
                }),
              position: "Bottom",
              children: React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.paddingRight(Css.px(16)),
                          tl: /* [] */0
                        })
                  }, React.createElement($$Text.make, {
                        element: "Em",
                        size: "Small",
                        color: Styles.Color.grey70,
                        children: "-"
                      }))
            });
}

var InformationMissing = {
  make: SchemaDashboard$BranchRow$InformationMissing
};

function rootStyle(selected) {
  return Curry._1(Css.style, {
              hd: Css.height(Css.px(56)),
              tl: {
                hd: Css.backgroundColor(selected ? Styles.Color.grey20 : "transparent"),
                tl: {
                  hd: Css.hover({
                        hd: Css.backgroundColor(Styles.Color.grey10),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.cursor("pointer"),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var nameCellStyle = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: {
        hd: Css.paddingRight(Css.px(4)),
        tl: /* [] */0
      }
    });

var statusCellStyle = Curry._1(Css.style, {
      hd: Css.padding(Css.px(8)),
      tl: /* [] */0
    });

var createdCellStyle = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(8)),
      tl: {
        hd: Css.paddingRight(Css.px(8)),
        tl: {
          hd: Css.minWidth(Css.px(88)),
          tl: /* [] */0
        }
      }
    });

var creatorCellStyle = Curry._1(Css.style, {
      hd: Css.padding(Css.px(8)),
      tl: /* [] */0
    });

var collaboratorsCellStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.padding(Css.px(8)),
        tl: /* [] */0
      }
    });

var collaboratorStyle = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(-4)),
      tl: /* [] */0
    });

function SchemaDashboard$BranchRow(Props) {
  var branchId = Props.branchId;
  var model = Props.model;
  var name = Props.name;
  var selected = Props.selected;
  var workspace = Props.workspace;
  var schemaGroup = Props.schemaGroup;
  var userId = Props.userId;
  var branchState = Props.branchState;
  var schemaId = workspace.id;
  var creationDate = App.default.firestore.Timestamp.fromDate(branchState.creationDate);
  var creatorUserId = branchState.creatorUserId;
  var branchStatus = branchState.branchStatus;
  var approvalsCount = Belt_Option.getWithDefault(branchState.approvalsCount, 0);
  var collaboratorIds = Collaborators.useCollaborators(schemaId, branchId);
  var collaboratorIds$1 = branchState.isShallow ? collaboratorIds : Belt_Option.mapWithDefault(branchState.collaboratorIds, /* [] */0, Belt_List.fromArray);
  var lastAction = FirebaseFetcherHooks.useActionLastBranchAction(schemaId, branchId);
  var match;
  if (branchState.isShallow) {
    var lastActionTimestamp;
    if (typeof lastAction === "object" && lastAction.NAME === "Loaded") {
      var lastAction$1 = lastAction.VAL;
      lastActionTimestamp = lastAction$1 !== undefined ? ({
            NAME: "Loaded",
            VAL: Caml_option.valFromOption(lastAction$1).createdAt
          }) : "Initial";
    } else {
      lastActionTimestamp = "Initial";
    }
    var lastActiveUserId;
    if (typeof lastAction === "object" && lastAction.NAME === "Loaded") {
      var lastAction$2 = lastAction.VAL;
      lastActiveUserId = lastAction$2 !== undefined ? ({
            NAME: "Loaded",
            VAL: Caml_option.valFromOption(lastAction$2).createdBy
          }) : "Initial";
    } else {
      lastActiveUserId = "Initial";
    }
    match = [
      lastActionTimestamp,
      lastActiveUserId
    ];
  } else {
    var lastActivityDate = branchState.lastActivityDate;
    var lastActionTimestamp$1 = lastActivityDate !== undefined ? ({
          NAME: "Loaded",
          VAL: App.default.firestore.Timestamp.fromDate(Caml_option.valFromOption(lastActivityDate))
        }) : "Initial";
    var lastActiveUserId$1 = branchState.lastActiveUserId;
    var lastActiveUserId$2 = lastActiveUserId$1 !== undefined ? ({
          NAME: "Loaded",
          VAL: lastActiveUserId$1
        }) : "Initial";
    match = [
      lastActionTimestamp$1,
      lastActiveUserId$2
    ];
  }
  var lastActiveUserId$3 = match[1];
  var lastActionTimestamp$2 = match[0];
  var excessCount = Belt_List.length(collaboratorIds$1) - 5 | 0;
  var match$1 = excessCount <= 0 ? [
      collaboratorIds$1,
      0
    ] : [
      Belt_Option.getWithDefault(Belt_List.take(collaboratorIds$1, 4), collaboratorIds$1),
      excessCount + 1 | 0
    ];
  var notDisplayedCount = match$1[1];
  var handleClick = function ($$event) {
    AnalyticsRe.branchLinkClicked(schemaGroup, branchId, "BranchesOverviewRow");
    var uri = Curry._4(Router.Link.get, {
          NAME: "schema",
          VAL: schemaId
        }, {
          NAME: "branch",
          VAL: branchId
        }, "metrics", /* [] */0);
    if ($$event.metaKey) {
      window.open(uri, "blank_");
      return ;
    } else {
      return Router.push(undefined, uri);
    }
  };
  var demoId = branchId === "demo-" + userId ? "new-workspace-demo-start" : undefined;
  var sourceNames = Belt_Option.map(branchState.impactedSourceIds, (function (sourceIds) {
          return Belt_Array.mapU(sourceIds, (function (sourceId) {
                          return Belt_Option.getWithDefault(Belt_Option.flatMapU(StateUtils.getSourceByIdWithArchive(sourceId, model), (function (source) {
                                            return source.name;
                                          })), "New Source");
                        })).join(", ");
        }));
  var sourceNamesCount = Belt_Option.flatMap(branchState.impactedSourceIds, (function (sourceIds) {
          if (sourceIds.length !== 0) {
            return sourceIds.length;
          }
          
        }));
  var tmp = {
    className: rootStyle(selected),
    onClick: handleClick
  };
  if (demoId !== undefined) {
    tmp.id = Caml_option.valFromOption(demoId);
  }
  var tmp$1 = {
    className: createdCellStyle
  };
  if (sourceNames !== undefined) {
    tmp$1.title = Caml_option.valFromOption(sourceNames);
  }
  var commentsCount = branchState.commentsCount;
  var lastCodegenBySource = branchState.lastCodegenBySource;
  var tmp$2;
  if (branchState.isShallow) {
    tmp$2 = React.createElement(SchemaDashboard$BranchRow$InformationMissing, {});
  } else if (lastCodegenBySource !== undefined) {
    var lastCodegenBySource$1 = Caml_option.valFromOption(lastCodegenBySource);
    tmp$2 = Belt_MapString.size(lastCodegenBySource$1) === 0 ? React.createElement(SchemaDashboard$BranchRow$InformationMissing, {}) : React.createElement(SchemaDashboard$LastCodegens, {
            lastCodegenBySource: lastCodegenBySource$1
          });
  } else {
    tmp$2 = React.createElement($$Text.make, {
          element: "Em",
          size: "Small",
          color: Styles.Color.grey70,
          children: "Never"
        });
  }
  var lastPublishedAt = branchState.lastPublishedAt;
  return React.createElement("tr", tmp, React.createElement("td", {
                  className: nameCellStyle
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      lines: 1,
                      color: Styles.Color.grey80,
                      children: name
                    })), React.createElement("td", {
                  className: statusCellStyle
                }, React.createElement(BranchStatusPill.make, {
                      status: branchStatus,
                      approvalsCount: approvalsCount,
                      workspace: workspace
                    })), React.createElement("td", {
                  className: Curry._1(Css.merge, {
                        hd: createdCellStyle,
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.whiteSpace("nowrap"),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.grey80,
                      children: React.createElement(Timestamp.make, {
                            date: creationDate
                          })
                    })), React.createElement("td", {
                  className: creatorCellStyle
                }, React.createElement(SchemaDashboard$Creator, {
                      id: creatorUserId
                    })), React.createElement("td", undefined, React.createElement("div", {
                      className: collaboratorsCellStyle
                    }, Belt_List.toArray(Belt_List.map(match$1[0], (function (id) {
                                return React.createElement("div", {
                                            key: id,
                                            className: collaboratorStyle
                                          }, React.createElement(ProfilePhoto.FromUserId.make, {
                                                id: id,
                                                size: 24
                                              }));
                              }))), notDisplayedCount !== 0 ? React.createElement("div", {
                            className: collaboratorStyle
                          }, React.createElement(ProfilePhoto.Base.make, {
                                email: "+" + String(notDisplayedCount),
                                size: 24
                              })) : null)), React.createElement("td", {
                  className: Curry._1(Css.merge, {
                        hd: createdCellStyle,
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.whiteSpace("nowrap"),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      })
                }, typeof lastActionTimestamp$2 === "object" && lastActionTimestamp$2.NAME === "Loaded" ? React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.grey80,
                        children: React.createElement(Timestamp.make, {
                              date: lastActionTimestamp$2.VAL
                            })
                      }) : null), React.createElement("td", {
                  className: creatorCellStyle
                }, typeof lastActiveUserId$3 === "object" ? React.createElement(SchemaDashboard$Creator, {
                        id: lastActiveUserId$3.VAL
                      }) : null), React.createElement("td", tmp$1, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("row"),
                              tl: {
                                hd: Css.flexWrap("wrap"),
                                tl: {
                                  hd: Css.gap(Css.px(2)),
                                  tl: {
                                    hd: Css.maxHeight(Css.pct(100.0)),
                                    tl: {
                                      hd: Css.overflow("hidden"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, branchState.isShallow ? React.createElement(SchemaDashboard$BranchRow$InformationMissing, {}) : (
                        sourceNames !== undefined ? React.createElement($$Text.make, {
                                size: "Small",
                                lines: 2,
                                color: Styles.Color.grey80,
                                children: null
                              }, Belt_Option.mapWithDefault(sourceNamesCount, React.createElement($$Text.make, {
                                        element: "Em",
                                        size: "Small",
                                        color: Styles.Color.grey70,
                                        children: "None"
                                      }), (function (sourceNamesCount) {
                                      return React.createElement($$Text.make, {
                                                  element: "Span",
                                                  weight: "Semi",
                                                  children: String(sourceNamesCount) + ": "
                                                });
                                    })), sourceNames) : null
                      ))), React.createElement("td", {
                  className: createdCellStyle
                }, branchState.isShallow ? React.createElement(SchemaDashboard$BranchRow$InformationMissing, {}) : (
                    commentsCount !== undefined ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("row"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(Icons.$$Comment.make, {}), React.createElement(Spacer.make, {
                                width: 4
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                color: Styles.Color.grey80,
                                children: String(commentsCount)
                              })) : React.createElement(SchemaDashboard$BranchRow$InformationMissing, {})
                  )), React.createElement("td", {
                  className: createdCellStyle
                }, tmp$2), React.createElement("td", {
                  className: createdCellStyle
                }, branchState.isShallow ? React.createElement(SchemaDashboard$BranchRow$InformationMissing, {}) : (
                    lastPublishedAt !== undefined ? React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.grey80,
                            children: React.createElement(Timestamp.make, {
                                  date: App.default.firestore.Timestamp.fromDate(Caml_option.valFromOption(lastPublishedAt))
                                })
                          }) : React.createElement($$Text.make, {
                            element: "Em",
                            size: "Small",
                            color: Styles.Color.grey70,
                            children: "Never"
                          })
                  )));
}

var BranchRow = {
  InformationMissing: InformationMissing,
  rootStyle: rootStyle,
  nameCellStyle: nameCellStyle,
  statusCellStyle: statusCellStyle,
  createdCellStyle: createdCellStyle,
  creatorCellStyle: creatorCellStyle,
  collaboratorsCellStyle: collaboratorsCellStyle,
  collaboratorStyle: collaboratorStyle,
  make: SchemaDashboard$BranchRow
};

var pageHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.marginTop(Css.px(32)),
          tl: {
            hd: Css.marginLeft(Css.px(48)),
            tl: /* [] */0
          }
        }
      }
    });

var branchesBox = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(32)),
      tl: {
        hd: Css.marginRight(Css.px(360)),
        tl: {
          hd: Css.marginBottom(Css.px(32)),
          tl: {
            hd: Css.marginLeft(Css.px(32)),
            tl: {
              hd: Css.border(Css.px(1), "solid", Styles.Color.grey40),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: {
                    hd: Css.overflowX("auto"),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var branchesMainRow = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding(Css.px(16)),
            tl: {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.grey10),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.cursor("pointer"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var noBranches = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.padding(Css.px(80)),
            tl: /* [] */0
          }
        }
      }
    });

var tableHeader = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top(Css.px(-2)),
        tl: {
          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
          tl: /* [] */0
        }
      }
    });

var headerItem = Curry._1(Css.style, {
      hd: Css.padding(Css.px(8)),
      tl: {
        hd: Css.textTransform("uppercase"),
        tl: {
          hd: Css.backgroundColor(Styles.Color.grey20),
          tl: {
            hd: Css.borderRight(Css.px(1), "solid", Styles.Color.grey30),
            tl: {
              hd: Css.textAlign("left"),
              tl: {
                hd: Css.firstChild({
                      hd: Css.paddingLeft(Css.px(16)),
                      tl: {
                        hd: Css.borderLeftWidth(Css.px(0)),
                        tl: {
                          hd: Css.width(Css.pct(100.0)),
                          tl: {
                            hd: Css.borderTopLeftRadius(Css.px(4)),
                            tl: {
                              hd: Css.borderTopRightRadius(Css.px(4)),
                              tl: {
                                hd: Css.position("sticky"),
                                tl: {
                                  hd: Css.left(Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: {
                  hd: Css.lastChild({
                        hd: Css.borderRightWidth(Css.px(0)),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var Style = {
  pageHeader: pageHeader,
  branchesBox: branchesBox,
  branchesMainRow: branchesMainRow,
  noBranches: noBranches,
  tableHeader: tableHeader,
  headerItem: headerItem
};

function SchemaDashboard$BranchName(Props) {
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var currentBranchOpenAction = FirebaseFetcherHooks.useBranchOpenAction(schemaId, branchId, "All", undefined, undefined);
  return Belt_Option.mapWithDefault(currentBranchOpenAction, "main", (function (action) {
                var match = action.contents;
                if (typeof match === "object" && match.NAME === "OpenBranch") {
                  return match.VAL[1];
                } else {
                  return "N/A";
                }
              }));
}

var BranchName = {
  make: SchemaDashboard$BranchName
};

var activityBoxStyles = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(32)),
        tl: {
          hd: Css.right(Css.px(32)),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.grey40),
            tl: {
              hd: Css.backgroundColor(Styles.Color.white),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.width(Css.px(296)),
                  tl: {
                    hd: Css.maxHeight({
                          NAME: "substract",
                          VAL: [
                            Css.vh(100.0),
                            Css.px(64)
                          ]
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var activityHeaderStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.padding(Css.px(10)),
          tl: {
            hd: Css.paddingLeft(Css.px(16)),
            tl: {
              hd: Css.paddingRight(Css.px(16)),
              tl: {
                hd: Css.height(Css.px(52)),
                tl: {
                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey40),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var activityListStyles = Curry._1(Css.style, {
      hd: Css.overflow("auto"),
      tl: {
        hd: Css.maxHeight({
              NAME: "substract",
              VAL: [
                Css.vh(100.0),
                Css.px(118)
              ]
            }),
        tl: {
          hd: Css.borderBottomLeftRadius(Css.px(4)),
          tl: {
            hd: Css.borderBottomRightRadius(Css.px(4)),
            tl: /* [] */0
          }
        }
      }
    });

function activityLogSwitcherButtonStyles(enabled) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.padding(Css.px(4)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(6)),
                        tl: {
                          hd: Css.paddingRight(Css.px(6)),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.flexDirection("row"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.textAlign("left"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: {
                  hd: enabled ? Curry._1(Css.style, {
                          hd: Css.transition({
                                NAME: "ms",
                                VAL: Styles.Duration.$$default
                              }, undefined, undefined, "background-color"),
                          tl: {
                            hd: Css.hover({
                                  hd: Css.backgroundColor(Styles.Color.grey20),
                                  tl: /* [] */0
                                }),
                            tl: /* [] */0
                          }
                        }) : Curry._1(Css.style, {
                          hd: Css.cursor("default"),
                          tl: /* [] */0
                        }),
                  tl: /* [] */0
                }
              }
            });
}

var activityLogSwitchMenuStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, {
            hd: Css.width(Css.px(300)),
            tl: {
              hd: Css.maxHeight(Css.px(450)),
              tl: {
                hd: Css.overflow("scroll"),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: /* [] */0
                }
              }
            }
          }),
      tl: {
        hd: PortalMenuAnimator.defaultStyles,
        tl: /* [] */0
      }
    });

var inputContainerStyles = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top("zero"),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("row"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var searchInputStyles = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "add",
            VAL: [
              Css.pct(100),
              Css.px(8)
            ]
          }),
      tl: {
        hd: Css.padding(Css.px(16)),
        tl: {
          hd: Css.paddingLeft(Css.px(18)),
          tl: {
            hd: Css.fontSize(Css.px(14)),
            tl: {
              hd: Css.fontWeight({
                    NAME: "num",
                    VAL: 500
                  }),
              tl: {
                hd: Css.borderWidth(Css.px(0)),
                tl: {
                  hd: Css.outlineStyle("none"),
                  tl: {
                    hd: Css.outlineWidth(Css.px(0)),
                    tl: {
                      hd: Css.color(Styles.Color.grey70),
                      tl: {
                        hd: Css.placeholder({
                              hd: Css.color(Styles.Color.grey50),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function eraseButtonStyles(disabled) {
  return Curry._1(Css.style, {
              hd: Css.height(Css.pct(100)),
              tl: {
                hd: Css.padding(Css.px(2)),
                tl: {
                  hd: Css.marginLeft(Css.px(6)),
                  tl: {
                    hd: Css.marginRight(Css.px(16)),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.borderRadius(Css.px(2)),
                        tl: {
                          hd: Css.background("transparent"),
                          tl: {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: Styles.Duration.$$default
                                }, undefined, undefined, "opacity"),
                            tl: {
                              hd: Css.opacity(disabled ? 0 : 1),
                              tl: {
                                hd: Css.unsafe("appearance", "none"),
                                tl: {
                                  hd: Css.outlineStyle("none"),
                                  tl: {
                                    hd: Css.cursor("pointer"),
                                    tl: {
                                      hd: Css.active({
                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.focus({
                                              hd: Css.outlineStyle("none"),
                                              tl: {
                                                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.blue)),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var activityLogSwitcherListStyles = Curry._1(Css.style, {
      hd: Css.margin("zero"),
      tl: {
        hd: Css.padding(Css.px(0)),
        tl: {
          hd: Css.paddingTop(Css.px(10)),
          tl: {
            hd: Css.selector(":empty::after", {
                  hd: Css.unsafe("content", "'No results...'"),
                  tl: {
                    hd: Css.display("block"),
                    tl: {
                      hd: Css.padding(Css.px(18)),
                      tl: {
                        hd: Css.paddingTop(Css.px(11)),
                        tl: {
                          hd: Css.color(Styles.Color.grey70),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

var activityLogSwitcherItemStyles = Curry._1(Css.style, {
      hd: Css.listStyleType("none"),
      tl: /* [] */0
    });

var activityLogSwitcherItemButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.textAlign("left"),
              tl: {
                hd: Css.width(Css.pct(100.0)),
                tl: {
                  hd: Css.padding(Css.px(10)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(18)),
                    tl: {
                      hd: Css.hover({
                            hd: Css.backgroundColor(Styles.Color.grey20),
                            tl: {
                              hd: Css.selector(" p", {
                                    hd: Css.color(Styles.Color.grey80),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function SchemaDashboard$ActivityBox(Props) {
  var schema = Props.schema;
  var globalSend = Props.globalSend;
  var model = Props.model;
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var activityLogAvailability = AvoLimits.ActivityLogs.computeAvailability(workspace);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var activityLogSwitcherRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setActivityLogSwitcherIsVisible = match[1];
  var activityLogSwitcherIsVisible = match[0];
  var match$1 = React.useState(function () {
        return currentBranch;
      });
  var setActivityLogBranch = match$1[1];
  var activityLogBranch = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setActivityLogSearchTerm = match$2[1];
  var activityLogSearchTerm = match$2[0];
  var debouncedActivityLogSearchTerm = Hooks.useDebounced((function (param) {
          return activityLogSearchTerm;
        }), 500);
  var openBranchesArray = React.useMemo((function () {
          return Belt_Array.concat([{
                        branchId: "master",
                        branchName: "main"
                      }], Belt_Array.mapU(Belt_Array.reverse(Belt_List.toArray(openBranches)), (function (param) {
                            return {
                                    branchId: param[0],
                                    branchName: param[1]
                                  };
                          })));
        }), [openBranches]);
  var filteredOpenBranches = React.useMemo((function () {
          return Fuzzaldrin.filter(openBranchesArray, activityLogSearchTerm, {
                      key: "branchName",
                      maxResults: 100
                    });
        }), [
        activityLogSearchTerm,
        openBranchesArray
      ]);
  var hasOpenBranches = openBranches !== /* [] */0;
  Hooks.useDidUpdate1((function (param) {
          if (!activityLogSwitcherIsVisible) {
            Curry._1(setActivityLogSearchTerm, (function (param) {
                    return "";
                  }));
          }
          AnalyticsRe.activityLogMenuInteracted(schemaGroup, undefined, activityLogSwitcherIsVisible ? "MenuOpened" : "MenuClosed");
          
        }), [activityLogSwitcherIsVisible]);
  Hooks.useDidUpdate1((function (param) {
          AnalyticsRe.activityLogMenuInteracted(schemaGroup, undefined, "SearchTermEntered");
          
        }), [debouncedActivityLogSearchTerm]);
  var onBranchChosen = React.useCallback((function (branch) {
          return function (_event) {
            Curry._1(setActivityLogSwitcherIsVisible, (function (param) {
                    return false;
                  }));
            Curry._1(setActivityLogBranch, (function (param) {
                    var branchId = branch.branchId;
                    if (branchId === "master") {
                      return "Master";
                    } else {
                      return {
                              NAME: "Branch",
                              VAL: branchId
                            };
                    }
                  }));
            AnalyticsRe.activityLogMenuInteracted(schemaGroup, branch.branchId, "BranchChosen");
            
          };
        }), [schemaGroup]);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: activityBoxStyles
                }, React.createElement("div", {
                      className: activityHeaderStyles
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.flexShrink(0.0),
                                tl: /* [] */0
                              })
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.grey90,
                              children: null
                            }, "Activity", React.createElement(Spacer.make, {
                                  display: "inlineBlock",
                                  width: 8
                                }), React.createElement(EntityLightning.make, {
                                  availability: activityLogAvailability,
                                  planHint: AvoLimits.ActivityLogs.availableOnPlan(workspace)
                                }))), React.createElement(Spacer.make, {
                          width: 8,
                          grow: 1.0
                        }), React.createElement("button", {
                          ref: activityLogSwitcherRef,
                          className: activityLogSwitcherButtonStyles(hasOpenBranches),
                          onClick: (function (param) {
                              if (hasOpenBranches) {
                                return Curry._1(setActivityLogSwitcherIsVisible, (function (state) {
                                              return !state;
                                            }));
                              }
                              
                            })
                        }, React.createElement(Icons.Branch.make, {
                              size: 12,
                              color: Styles.Color.grey50
                            }), React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement($$Text.make, {
                              element: "Span",
                              size: "Small",
                              lines: 1,
                              color: Styles.Color.grey80,
                              children: React.createElement(SchemaDashboard$BranchName, {
                                    schemaId: schema.id,
                                    branchId: Actions.branchToId(activityLogBranch),
                                    key: Actions.branchToId(activityLogBranch)
                                  })
                            }), hasOpenBranches ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    width: 5
                                  }), React.createElement(IconChevron.make, {
                                    size: 6,
                                    color: Styles.Color.grey80
                                  })) : null)), React.createElement("div", {
                      className: activityListStyles
                    }, React.createElement(FetchActivity.make, {
                          schema: schema,
                          currentBranch: activityLogBranch,
                          pageSize: 200,
                          children: (function (items, status, param, getMore) {
                              return React.createElement(ActivityLog.make, {
                                          availability: activityLogAvailability,
                                          schema: schema,
                                          items: items,
                                          status: status,
                                          getMore: getMore,
                                          openBranches: openBranches,
                                          globalSend: globalSend,
                                          currentBranch: activityLogBranch,
                                          model: model
                                        });
                            })
                        }))), React.createElement(PortalMenu.make, {
                  position: "Bottom",
                  placement: "End",
                  distance: 4,
                  clickOutsideToDismiss: true,
                  visible: activityLogSwitcherIsVisible,
                  onClose: (function (param) {
                      return Curry._1(setActivityLogSwitcherIsVisible, (function (param) {
                                    return false;
                                  }));
                    }),
                  referenceItemRef: activityLogSwitcherRef,
                  children: React.createElement(PortalMenuAnimator.make, {
                        key_: "activity-log-switcher",
                        className: activityLogSwitchMenuStyles,
                        children: React.createElement("section", undefined, React.createElement("span", {
                                  className: inputContainerStyles
                                }, React.createElement("input", {
                                      className: searchInputStyles,
                                      autoFocus: true,
                                      placeholder: "Search branches",
                                      type: "text",
                                      value: activityLogSearchTerm,
                                      onChange: (function (e) {
                                          var value = e.target.value;
                                          return Curry._1(setActivityLogSearchTerm, (function (param) {
                                                        return value;
                                                      }));
                                        })
                                    }), React.createElement("button", {
                                      className: eraseButtonStyles(activityLogSearchTerm === ""),
                                      disabled: activityLogSearchTerm === "",
                                      onClick: (function (param) {
                                          return Curry._1(setActivityLogSearchTerm, (function (param) {
                                                        return "";
                                                      }));
                                        })
                                    }, React.createElement(IconErase.make, {
                                          color: Styles.Color.grey40,
                                          hoverColor: Styles.Color.grapeError
                                        }))), React.createElement("ul", {
                                  className: activityLogSwitcherListStyles
                                }, Belt_Array.mapU(filteredOpenBranches, (function (branch) {
                                        return React.createElement("li", {
                                                    key: branch.branchId,
                                                    className: activityLogSwitcherItemStyles
                                                  }, React.createElement("button", {
                                                        className: activityLogSwitcherItemButtonStyles,
                                                        onClick: Curry._1(onBranchChosen, branch)
                                                      }, React.createElement($$Text.make, {
                                                            size: "Medium",
                                                            weight: "Medium",
                                                            lines: 1,
                                                            color: Styles.Color.grey70,
                                                            children: branch.branchName
                                                          })));
                                      }))))
                      })
                }));
}

var ActivityBox = {
  activityBoxStyles: activityBoxStyles,
  activityHeaderStyles: activityHeaderStyles,
  activityListStyles: activityListStyles,
  activityLogSwitcherButtonStyles: activityLogSwitcherButtonStyles,
  activityLogSwitchMenuStyles: activityLogSwitchMenuStyles,
  inputContainerStyles: inputContainerStyles,
  searchInputStyles: searchInputStyles,
  eraseButtonStyles: eraseButtonStyles,
  activityLogSwitcherListStyles: activityLogSwitcherListStyles,
  activityLogSwitcherItemStyles: activityLogSwitcherItemStyles,
  activityLogSwitcherItemButtonStyles: activityLogSwitcherItemButtonStyles,
  make: SchemaDashboard$ActivityBox
};

var branchesOverviewTableHeaders = [
  {
    name: "Name",
    width: 350,
    sortBy: (function (dataRow) {
        return dataRow.name;
      })
  },
  {
    name: "Status",
    width: 156,
    sortBy: (function (dataRow) {
        return BranchStateFirebaseModel.branchStatusToJs(dataRow.branchState.branchStatus);
      })
  },
  {
    name: "Created",
    sortBy: (function (dataRow) {
        return dataRow.branchState.creationDate.getTime().toString();
      }),
    defaultOrder: /* Desc */1,
    defaultSortedColumn: true
  },
  {
    name: "Creator"
  },
  {
    name: "Collaborators"
  },
  {
    name: "Last activity",
    sortBy: (function (dataRow) {
        return Belt_Option.mapWithDefault(dataRow.branchState.lastActivityDate, "", (function (date) {
                      return date.getTime().toString();
                    }));
      }),
    defaultOrder: /* Desc */1
  },
  {
    name: "Last Active User"
  },
  {
    name: "Impacted Sources"
  },
  {
    name: "Comments"
  },
  {
    name: "Latest Codegens"
  },
  {
    name: "Last Published",
    sortBy: (function (dataRow) {
        return Belt_Option.mapWithDefault(dataRow.branchState.lastPublishedAt, "", (function (date) {
                      return date.getTime().toString();
                    }));
      }),
    defaultOrder: /* Desc */1
  }
];

var BranchesOverviewTable = AvoTable.Make({
      headers: branchesOverviewTableHeaders
    });

function SchemaDashboard$Header(Props) {
  var view = Props.view;
  return React.createElement("tr", {
              className: Curry._1(Css.style, {
                    hd: Css.selector(" th", {
                          hd: Css.backgroundColor(Styles.Color.grey30),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.height(Css.px(50)),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("th", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(16)),
                        tl: {
                          hd: Css.position("sticky"),
                          tl: {
                            hd: Css.left(Css.px(0)),
                            tl: {
                              hd: Css.width(Css.pct(100.0)),
                              tl: {
                                hd: Css.textAlign("left"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }),
                  colSpan: 2
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.grey80,
                      children: view === /* ClosedBranches */1 ? "Closed Branches" : "Open Branches"
                    })), React.createElement("th", {
                  colSpan: 9
                }));
}

var Header = {
  make: SchemaDashboard$Header
};

var tr = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.after({
              hd: Css.position("absolute"),
              tl: {
                hd: Css.unsafe("content", "' '"),
                tl: {
                  hd: Css.top(Css.px(-101)),
                  tl: {
                    hd: Css.left(Css.px(0)),
                    tl: {
                      hd: Css.height(Css.px(100)),
                      tl: {
                        hd: Css.width(Css.pct(100.0)),
                        tl: {
                          hd: Css.zIndex(3),
                          tl: {
                            hd: Css.pointerEvents("none"),
                            tl: {
                              hd: Css.background({
                                    NAME: "linearGradient",
                                    VAL: [
                                      {
                                        NAME: "deg",
                                        VAL: 180.0
                                      },
                                      [
                                        [
                                          Css.pct(0.0),
                                          Styles.Color.setAlpha(Styles.Color.white, 0.0)
                                        ],
                                        [
                                          Css.pct(100.0),
                                          Styles.Color.setAlpha(Styles.Color.white, 0.9)
                                        ]
                                      ]
                                    ]
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var th = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.zIndex(3),
          tl: {
            hd: Css.padding(Css.px(16)),
            tl: {
              hd: Css.paddingTop(Css.px(32)),
              tl: {
                hd: Css.paddingBottom(Css.px(32)),
                tl: {
                  hd: Css.width(Css.pct(100.0)),
                  tl: {
                    hd: Css.margin(Css.px(0)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var Style$1 = {
  tr: tr,
  th: th
};

function SchemaDashboard$ClosedBranchesFooterForFree(Props) {
  var schemaId = Props.schemaId;
  var userId = Props.userId;
  var match = TrialRequest.useTrialRequest(schemaId);
  var requestTrial = match[1];
  var status = match[0];
  var workspace = WorkspaceContext.use(undefined);
  var trialAvailableOrPending = status === /* Available */2 || status === /* Pending */4;
  return React.createElement("tr", {
              className: tr
            }, React.createElement("th", {
                  className: th,
                  colSpan: 3
                }, React.createElement(Title.make, {
                      children: trialAvailableOrPending ? "Unlock full branch history" : "Upgrade for full branch history",
                      size: "Tiny"
                    }), React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Regular",
                      color: Styles.Color.grey70,
                      maxWidth: Css.px(500),
                      children: "Our Team plan allows you to gain oversight over all your closed branches in your workspace. " + (
                        trialAvailableOrPending ? "Start your free trial" : "Upgrade now"
                      ) + " to browse your history."
                    }), React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement(Button.make, {
                      label: trialAvailableOrPending ? "Start 14 day trial" : "Upgrade Now",
                      loading: status === /* Pending */4,
                      onClick: (function (param) {
                          if (status === /* Available */2) {
                            Curry._1(requestTrial, userId).then(function (param) {
                                  AnalyticsRe.workspaceTrialStarted(schemaId, Date.now() / 1000.0, workspace.name);
                                  return Promise.resolve(undefined);
                                });
                            return ;
                          } else {
                            return Router.Schema.pushSchemaRoute(undefined, undefined, "billing");
                          }
                        }),
                      size: "large"
                    })));
}

var ClosedBranchesFooterForFree = {
  Style: Style$1,
  make: SchemaDashboard$ClosedBranchesFooterForFree
};

function SchemaDashboard(Props) {
  var schema = Props.schema;
  var model = Props.model;
  var globalSend = Props.globalSend;
  var openBranches = Props.openBranches;
  var currentBranch = Props.currentBranch;
  var latestMasterAction = Props.latestMasterAction;
  var userId = Props.userId;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var hasEmptyState = model.events === /* [] */0 && openBranches === /* [] */0;
  React.useEffect((function () {
          if (hasEmptyState) {
            AnalyticsRe.emptyStateDisplayed(schemaGroup, "BranchesOverview", schema.id);
          }
          
        }), []);
  var drawerItems = RouterStore.Schema.useDrawerItems(undefined);
  var match = React.useState(function () {
        return /* OpenBranches */0;
      });
  var setView = match[1];
  var view = match[0];
  var branchStatesQuery = FirebaseFetcherHooks.useBranchStates(view ? /* Closed */0 : /* Active */1, schema.id);
  var branchStates = typeof branchStatesQuery === "object" ? branchStatesQuery.VAL : /* [] */0;
  var handleViewChange = function (newView) {
    Curry._1(setView, (function (param) {
            return newView;
          }));
    return AnalyticsRe.branchesViewConfigured(schemaGroup, newView === /* OpenBranches */0 ? "OpenBranches" : "ClosedBranches", schemaGroup.branchId, schemaGroup.schemaId);
  };
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var hasClosedBranches = AvoLimits.ClosedBranches.isAvailable(workspace);
  var branchStates$1 = view === /* ClosedBranches */1 && !hasClosedBranches ? Belt_Option.getWithDefault(Belt_List.take(branchStates, 3), branchStates) : branchStates;
  var branchesOverviewTableData = Belt_Array.map(Belt_List.toArray(branchStates$1), (function (branchState) {
          return {
                  branchState: branchState,
                  workspace: workspace,
                  key: branchState.branchId,
                  schemaGroup: schemaGroup,
                  branchId: branchState.branchId,
                  model: model,
                  name: branchState.branchName,
                  selected: typeof currentBranch === "object" ? currentBranch.VAL === branchState.branchId : false,
                  userId: userId
                };
        }));
  var hasShallowStates = Belt_Array.some(branchesOverviewTableData, (function (param) {
          return param.branchState.isShallow;
        }));
  var tmp;
  if (openBranches !== /* [] */0 || view !== /* OpenBranches */0) {
    var tmp$1 = {
      paginate: true,
      paginationBoundaries: 2,
      sortable: workspace.plan.name === /* TeamV2 */8 || workspace.plan.name === /* TeamV2Annual */9 || workspace.plan.name === /* Enterprise */6,
      firstColumnSticky: true,
      optionalHeader: React.createElement(SchemaDashboard$Header, {
            view: view
          }),
      headerSticky: true,
      show: 25,
      data: branchesOverviewTableData,
      children: (function (param) {
          return React.createElement(SchemaDashboard$BranchRow, {
                      branchId: param.branchId,
                      model: param.model,
                      name: param.name,
                      selected: param.selected,
                      workspace: param.workspace,
                      schemaGroup: param.schemaGroup,
                      userId: param.userId,
                      branchState: param.branchState,
                      key: param.key
                    });
        })
    };
    var tmp$2 = view === /* ClosedBranches */1 && !hasClosedBranches ? Caml_option.some(React.createElement(SchemaDashboard$ClosedBranchesFooterForFree, {
                schemaId: schema.id,
                userId: userId
              })) : undefined;
    if (tmp$2 !== undefined) {
      tmp$1.footer = Caml_option.valFromOption(tmp$2);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement("section", {
              className: Curry._1(Css.style, {
                    hd: Css.marginLeft(Css.px(32)),
                    tl: {
                      hd: Css.marginRight(Css.px(360)),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: 300.0
                            }, undefined, undefined, "width"),
                        tl: {
                          hd: Css.width({
                                NAME: "substract",
                                VAL: [
                                  Css.pct(100.0),
                                  Css.px(64 + (
                                        drawerItems === /* [] */0 ? 328 : DrawerContainer.drawerWidth
                                      ) | 0)
                                ]
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(BranchesOverviewTable.make, tmp$1)), hasShallowStates ? React.createElement("section", {
                className: Curry._1(Css.merge, {
                      hd: branchesBox,
                      tl: {
                        hd: Curry._1(Css.style, {
                              hd: Css.unsafe("backgroundColor", "unset"),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding(Css.px(16)),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, React.createElement($$Text.make, {
                        size: "Medium",
                        children: "💡"
                      }), React.createElement(Spacer.make, {
                        width: 8
                      }), React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.grey80,
                        children: "Values noted as \"-\" will be calculated on the next branch interaction (e.g. change or comment)"
                      }))) : null);
  } else {
    tmp = React.createElement("section", {
          className: branchesBox
        }, React.createElement("div", {
              className: noBranches
            }, React.createElement(IconTrees.make, {
                  size: 100
                }), React.createElement(Spacer.make, {
                  height: 20
                }), React.createElement(Title.make, {
                  children: "There are no open branches",
                  size: "Medium"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  textAlign: "center",
                  color: Styles.Color.grey70,
                  maxWidth: Css.px(440),
                  children: null
                }, "Draft and collaborate on changes to your tracking plan without messing up your source of truth. Read more in ", React.createElement("a", {
                      className: Curry._1(Css.style, {
                            hd: Css.textDecoration("none"),
                            tl: {
                              hd: Css.color(Styles.Color.blue),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.color(Styles.Color.blueSecondary),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }),
                      href: "https://www.avo.app/docs/workspace/branches",
                      rel: "noopener",
                      target: "_blank"
                    }, "our docs"), "."), React.createElement(Spacer.make, {
                  height: 20
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: /* [] */0
                      })
                }, currentBranch === "Master" ? React.createElement(Button.make, {
                        label: "Create branch",
                        onClick: (function (param) {
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "NewBranch",
                                          VAL: [
                                            undefined,
                                            ""
                                          ]
                                        }
                                      });
                          }),
                        size: "large"
                      }) : null)));
  }
  return React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: pageHeader
                }, React.createElement(Title.make, {
                      children: "Branches",
                      size: "Tiny"
                    }), React.createElement(Spacer.make, {
                      width: 4
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      color: Styles.Color.grey70,
                      children: "(" + String(Belt_List.length(openBranches)) + " open)"
                    }), React.createElement(Spacer.make, {
                      width: 12
                    }), currentBranch === "Master" ? React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                            label: "Create branch",
                            onClick: (function (param) {
                                return Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "NewBranch",
                                              VAL: [
                                                undefined,
                                                ""
                                              ]
                                            }
                                          });
                              })
                          }), React.createElement(Spacer.make, {
                            width: 12
                          })) : null, React.createElement(SegmentControl.make, {
                      options: [
                        [
                          "Open branches",
                          /* OpenBranches */0
                        ],
                        [
                          "Closed branches",
                          /* ClosedBranches */1
                        ]
                      ],
                      onSelect: handleViewChange,
                      selected: view
                    })), React.createElement("section", {
                  className: branchesBox
                }, React.createElement("header", {
                      className: tableHeader
                    }, React.createElement("div", {
                          className: headerItem
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.grey70,
                              children: "Default branch"
                            }))), React.createElement(Link.make, {
                      path: Curry._4(Router.Link.get, {
                            NAME: "schema",
                            VAL: schema.id
                          }, "master", "events", /* [] */0),
                      className: branchesMainRow,
                      children: null
                    }, React.createElement($$Text.make, {
                          element: "Label",
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.grey80,
                          children: "main"
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.grey80,
                          children: Belt_Option.mapWithDefault(latestMasterAction, null, (function (latestMasterAction) {
                                  return React.createElement(React.Fragment, undefined, "Latest activity ", React.createElement(Timestamp.make, {
                                                  date: latestMasterAction.createdAt
                                                }));
                                }))
                        }))), tmp, React.createElement(SchemaDashboard$ActivityBox, {
                  schema: schema,
                  globalSend: globalSend,
                  model: model,
                  currentBranch: currentBranch,
                  openBranches: openBranches
                }));
}

var activityLogWidth = 296;

var activityLogPadding = 32;

var activityHeaderHeight = 52;

var make = SchemaDashboard;

exports.Creator = Creator;
exports.LastCodegens = LastCodegens;
exports.BranchRow = BranchRow;
exports.activityLogWidth = activityLogWidth;
exports.activityLogPadding = activityLogPadding;
exports.activityHeaderHeight = activityHeaderHeight;
exports.Style = Style;
exports.BranchName = BranchName;
exports.ActivityBox = ActivityBox;
exports.branchesOverviewTableHeaders = branchesOverviewTableHeaders;
exports.BranchesOverviewTable = BranchesOverviewTable;
exports.Header = Header;
exports.ClosedBranchesFooterForFree = ClosedBranchesFooterForFree;
exports.make = make;
/* rootStyles Not a pure module */
