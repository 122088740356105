// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Sidebar = require("./Sidebar.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function root(rounded) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.minWidth({
                                  NAME: "substract",
                                  VAL: [
                                    Css.vw(100.0),
                                    Css.px(Sidebar.sidebarWidth + 16 | 0)
                                  ]
                                }),
                            tl: {
                              hd: Css.height(Css.pct(100.0)),
                              tl: {
                                hd: Css.borderTopLeftRadius(rounded ? Styles.Border.radius : Css.px(0)),
                                tl: {
                                  hd: Css.borderTopRightRadius(rounded ? Styles.Border.radius : Css.px(0)),
                                  tl: {
                                    hd: Css.textAlign("left"),
                                    tl: {
                                      hd: Css.cursor("default"),
                                      tl: {
                                        hd: Css.backgroundColor("transparent"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function infoCell(customBackgroundColor, customBorderColor, rounded) {
  return Curry._1(Css.style, {
              hd: Css.zIndex(1),
              tl: {
                hd: Css.position("sticky"),
                tl: {
                  hd: Css.top(Css.px(0)),
                  tl: {
                    hd: Css.left(Css.px(0)),
                    tl: {
                      hd: Css.width(Css.px(500)),
                      tl: {
                        hd: Css.minHeight(Css.pct(100.0)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexGrow(1.0),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: {
                                hd: Css.flexDirection("column"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.alignItems("flexStart"),
                                    tl: {
                                      hd: Css.paddingLeft(Css.px(16)),
                                      tl: {
                                        hd: Css.border(Css.px(1), "solid", customBorderColor),
                                        tl: {
                                          hd: Css.borderLeftWidth(Css.px(rounded ? 1 : 0)),
                                          tl: {
                                            hd: Css.borderRightWidth(Css.px(0)),
                                            tl: {
                                              hd: Css.borderTopLeftRadius(rounded ? Styles.Border.radius : Css.px(0)),
                                              tl: {
                                                hd: Styles.backgroundColorGuarantee(customBackgroundColor),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function pillStyles(customBackgroundColor) {
  return Curry._1(Css.style, {
              hd: Css.backgroundColor(customBackgroundColor),
              tl: {
                hd: Css.paddingTop(Css.px(2)),
                tl: {
                  hd: Css.paddingRight(Css.px(8)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(8)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(1)),
                      tl: {
                        hd: Css.borderRadius(Css.px(16)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function emptyCell(customWidth, customBackgroundColor, customBorderColor, rounded) {
  return Curry._1(Css.style, {
              hd: Css.lastChild({
                    hd: Css.borderTopRightRadius(rounded ? Styles.Border.radius : Css.px(0)),
                    tl: {
                      hd: Css.borderRightWidth(Css.px(rounded ? 1 : 0)),
                      tl: {
                        hd: Css.flexGrow(1.0),
                        tl: {
                          hd: Css.marginRight(Css.px(16)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              tl: {
                hd: Styles.backgroundColorGuarantee(customBackgroundColor),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", customBorderColor),
                    tl: {
                      hd: Css.borderLeftWidth(Css.px(0)),
                      tl: {
                        hd: Css.borderRightWidth(Css.px(0)),
                        tl: {
                          hd: Css.width(Css.px(customWidth)),
                          tl: {
                            hd: Css.flexShrink(0.0),
                            tl: {
                              hd: Css.height(Css.pct(100)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Style = {
  root: root,
  infoCell: infoCell,
  pillStyles: pillStyles,
  emptyCell: emptyCell
};

function getStylesForTint(tint) {
  if (tint === "Grey") {
    return [
            Styles.Color.grey50,
            Styles.Color.grey40,
            Styles.Color.grey30
          ];
  } else {
    return [
            Styles.Color.mintGreen,
            Styles.Color.mintGreen,
            Styles.Color.mintGreenBg
          ];
  }
}

function SheetSectionHeader(Props) {
  var columns = Props.columns;
  var label = Props.label;
  var pillText = Props.pillText;
  var viewOpt = Props.view;
  var tintOpt = Props.tint;
  var children = Props.children;
  var extraOpt = Props.extra;
  var view = viewOpt !== undefined ? viewOpt : /* ByCategory */0;
  var tint = tintOpt !== undefined ? tintOpt : "Grey";
  var extra = extraOpt !== undefined ? Caml_option.valFromOption(extraOpt) : null;
  var match = getStylesForTint(tint);
  var themeColor = match[2];
  var accentColor = match[1];
  var rounded = view === /* ByCategory */0;
  var totalColumnWidth = Belt_Array.reduceU(Belt_Array.mapU(Belt_Array.keepU(columns, (function (param) {
                  return param.visible;
                })), (function (param) {
              return param.width;
            })), 0, (function (acc, width) {
          return acc + width | 0;
        }));
  return React.createElement("button", {
              className: root(rounded)
            }, React.createElement("div", {
                  className: infoCell(themeColor, accentColor, rounded)
                }, React.createElement($$Text.make, {
                      size: "Tiny",
                      weight: "Semi",
                      color: Styles.Color.grey70,
                      children: Belt_Option.getWithDefault(label, "")
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.paddingRight(Css.px(8)),
                              tl: {
                                hd: Css.maxWidth(Css.pct(100.0)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, children, pillText !== undefined ? React.createElement("span", {
                            className: pillStyles(match[0])
                          }, React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                singleLine: true,
                                color: Styles.Color.white,
                                children: pillText
                              })) : null, React.createElement(Spacer.make, {
                          width: 8
                        }), extra)), React.createElement("div", {
                  className: emptyCell(totalColumnWidth - 500 | 0, themeColor, accentColor, rounded)
                }));
}

var stickyCellWidth = 500;

var make = SheetSectionHeader;

exports.stickyCellWidth = stickyCellWidth;
exports.Style = Style;
exports.getStylesForTint = getStylesForTint;
exports.make = make;
/* Css Not a pure module */
