// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconSendAs(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey40;
  return React.createElement("svg", {
              width: String(size),
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  className: Curry._1(Css.style, {
                        hd: Css_Legacy_Core.SVG.fill(color),
                        tl: {
                          hd: Css_Legacy_Core.SVG.stroke(color),
                          tl: /* [] */0
                        }
                      }),
                  strokeWidth: "1"
                }, React.createElement("rect", {
                      height: "6",
                      width: "6",
                      fill: "none",
                      strokeLinecap: "round",
                      x: "0.5",
                      y: "9.5"
                    }), React.createElement("polyline", {
                      fill: "none",
                      points: "15.5,6.5 15.5,0.5 9.5,0.5 ",
                      strokeLinecap: "round"
                    }), React.createElement("line", {
                      fill: "none",
                      strokeLinecap: "round",
                      x1: "15.5",
                      x2: "9.5",
                      y1: "0.5",
                      y2: "6.5"
                    })));
}

var make = IconSendAs;

exports.make = make;
/* Css Not a pure module */
