// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var Mantine = require("./Mantine.bs.js");
var React$1 = require("@dopt/react");
var ProductTour = require("./ProductTour.bs.js");

function HowToSaveViewAndGetAlertsFlow(Props) {
  var match = React$1.useFlow("how-to-save-a-view-and-get-alerts");
  var intent = match[1];
  var flow = match[0];
  React.useEffect((function () {
          intent.stop();
          return (function (param) {
                    intent.stop();
                    
                  });
        }), []);
  var match$1 = React.useState(function () {
        return 500;
      });
  var setDelay = match$1[1];
  var delay = match$1[0];
  Hooks.useDidUpdate1((function (param) {
          if (flow.state.started) {
            Curry._1(setDelay, (function (param) {
                    return 0;
                  }));
          }
          
        }), [flow.state.started]);
  return React.createElement(ProductTour.make, {
              showStepCount: true,
              tourId: "how-to-save-a-view-and-get-alerts",
              children: null
            }, React.createElement(ProductTour.Step.make, {
                  stepId: "step-1",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "save-view-for-issues"
                  },
                  arrowOffset: 60,
                  delay: delay,
                  elementClickable: false,
                  elementPadding: 4,
                  position: "left-start",
                  transition: Mantine.Transition.subtleUpSlide,
                  transitions: [{
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }],
                  transitionDuration: 400,
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(ProductTour.Step.make, {
                  stepId: "step-2",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "set-up-alerts"
                  },
                  arrowOffset: 60,
                  delay: delay,
                  elementClickable: false,
                  elementPadding: 4,
                  position: "left-start",
                  transition: Mantine.Transition.subtleUpSlide,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  transitionDuration: 400,
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(ProductTour.Step.make, {
                  stepId: "step-3",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "intercom-sidebar-itemInspectorSavedViews"
                  },
                  delay: delay,
                  elementClickable: false,
                  position: "right",
                  transition: Mantine.Transition.subtleLeftSlide,
                  transitions: [{
                      key: "complete",
                      label: "Finish!",
                      icon: undefined
                    }],
                  transitionDuration: 400,
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }));
}

var make = HowToSaveViewAndGetAlertsFlow;

exports.make = make;
/* Hooks Not a pure module */
