// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Shortid = require("shortid");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("../../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var StringExt = require("../StringExt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("../stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var ActionsReducer = require("../actionsReducer.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var PropertyValidationUtils = require("../PropertyValidationUtils.bs.js");

function buildActions(importModel, model) {
  var match = Belt_Array.reduceU(importModel.sources, [
        /* [] */0,
        undefined
      ], (function (param, sourceName) {
          var sourceIdMap = param[1];
          var sourceActions = param[0];
          var maybeModelSource = Belt_List.getByU(model.sources, (function (source) {
                  return AvoConfig.getSourceName(source) === sourceName;
                }));
          if (maybeModelSource !== undefined) {
            return [
                    sourceActions,
                    Belt_MapString.set(sourceIdMap, sourceName, [
                          maybeModelSource.id,
                          StateUtils.getDefaultDestinationsForSource(maybeModelSource, model)
                        ])
                  ];
          }
          var sourceId = Shortid();
          return [
                  Belt_List.concat(sourceActions, {
                        hd: {
                          NAME: "CreateSource",
                          VAL: sourceId
                        },
                        tl: {
                          hd: {
                            NAME: "UpdateSourceName",
                            VAL: [
                              sourceId,
                              sourceName
                            ]
                          },
                          tl: /* [] */0
                        }
                      }),
                  Belt_MapString.set(sourceIdMap, sourceName, [
                        sourceId,
                        /* [] */0
                      ])
                ];
        }));
  var sourceNameToIdMap = match[1];
  var match$1 = Belt_Array.reduceU(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.mapU(Belt_Array.concat(importModel.newEvents, importModel.updatedEvents), (function ($$event) {
                          return $$event.categories;
                        }))))), [
        /* [] */0,
        undefined
      ], (function (param, categoryName) {
          var categoryNameToIdMap = param[1];
          var categoryActions = param[0];
          var maybeModelCategory = Belt_List.getByU(model.goals, (function (goal) {
                  return goal.name === categoryName;
                }));
          if (maybeModelCategory !== undefined) {
            return [
                    categoryActions,
                    Belt_MapString.set(categoryNameToIdMap, maybeModelCategory.name, maybeModelCategory.id)
                  ];
          }
          var categoryId = Shortid();
          return [
                  Belt_List.concat(categoryActions, {
                        hd: {
                          NAME: "AddGoal",
                          VAL: categoryId
                        },
                        tl: {
                          hd: {
                            NAME: "UpdateGoalName",
                            VAL: [
                              categoryId,
                              categoryName
                            ]
                          },
                          tl: /* [] */0
                        }
                      }),
                  Belt_MapString.set(categoryNameToIdMap, categoryName, categoryId)
                ];
        }));
  var categoryNameToIdMap = match$1[1];
  var newPropertyActions = Belt_List.concatMany(Belt_Array.mapU(importModel.newProperties, (function (property) {
              var propertyId = property.id;
              var maybeConflictingNameIndex = property.uniqueNameIndex;
              var match = property.nameMapping;
              var tmp;
              if (match !== undefined) {
                var maybeModelDestination = Belt_Option.flatMap(match[1], (function (destinationName) {
                        return Belt_List.getBy(model.destinations, (function (destination) {
                                      return Caml_obj.caml_equal(destination.name, destinationName);
                                    }));
                      }));
                tmp = {
                  hd: {
                    NAME: "AddRule",
                    VAL: [
                      Shortid(),
                      {
                        NAME: "Property",
                        VAL: property.id
                      },
                      {
                        NAME: "NameMapping",
                        VAL: {
                          destination: Belt_Option.map(maybeModelDestination, (function (modelDestination) {
                                  return {
                                          NAME: "Destination",
                                          VAL: modelDestination.id
                                        };
                                })),
                          name: match[0]
                        }
                      }
                    ]
                  },
                  tl: /* [] */0
                };
              } else {
                tmp = /* [] */0;
              }
              return Belt_List.concatMany([
                          {
                            hd: {
                              NAME: "CreateProperty",
                              VAL: [
                                propertyId,
                                property.name,
                                property.sendAs
                              ]
                            },
                            tl: {
                              hd: {
                                NAME: "UpdatePropertyType",
                                VAL: [
                                  propertyId,
                                  property.type_
                                ]
                              },
                              tl: /* [] */0
                            }
                          },
                          maybeConflictingNameIndex !== undefined ? ({
                                hd: {
                                  NAME: "UpdatePropertyUniqueName",
                                  VAL: [
                                    propertyId,
                                    maybeConflictingNameIndex === 0 ? property.name : property.name + " (" + String(maybeConflictingNameIndex) + ")"
                                  ]
                                },
                                tl: /* [] */0
                              }) : /* [] */0,
                          property.description === "" ? /* [] */0 : ({
                                hd: {
                                  NAME: "UpdatePropertyDescription",
                                  VAL: [
                                    propertyId,
                                    property.description
                                  ]
                                },
                                tl: /* [] */0
                              }),
                          property.list ? ({
                                hd: {
                                  NAME: "UpdatePropertyList",
                                  VAL: [
                                    propertyId,
                                    property.list
                                  ]
                                },
                                tl: /* [] */0
                              }) : /* [] */0,
                          property.type_ === "string" ? Belt_List.fromArray(Belt_Array.mapU(property.matches, (function (match_) {
                                        return {
                                                NAME: "AddPropertyValidation",
                                                VAL: [
                                                  propertyId,
                                                  {
                                                    NAME: "Matches",
                                                    VAL: {
                                                      hd: [
                                                        {
                                                          NAME: "Literal",
                                                          VAL: {
                                                            NAME: "StringLit",
                                                            VAL: match_
                                                          }
                                                        },
                                                        /* [] */0
                                                      ],
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                ]
                                              };
                                      }))) : /* [] */0,
                          Belt_MapString.everyU(property.presence, (function (_eventId, presence) {
                                  return presence === /* AlwaysSent */0;
                                })) ? /* [] */0 : (
                              Belt_MapString.everyU(property.presence, (function (_eventId, presence) {
                                      return presence === /* SometimesSent */1;
                                    })) ? ({
                                    hd: {
                                      NAME: "UpdatePropertyAbsence",
                                      VAL: [
                                        propertyId,
                                        /* All */0,
                                        /* SometimesSent */{
                                          _0: ""
                                        }
                                      ]
                                    },
                                    tl: /* [] */0
                                  }) : Belt_List.keepMapU(Belt_MapString.toList(property.presence), (function (param) {
                                        if (param[1]) {
                                          return {
                                                  NAME: "UpdatePropertyAbsence",
                                                  VAL: [
                                                    propertyId,
                                                    {
                                                      TAG: /* Event */0,
                                                      _0: param[0]
                                                    },
                                                    /* SometimesSent */{
                                                      _0: ""
                                                    }
                                                  ]
                                                };
                                        }
                                        
                                      }))
                            ),
                          tmp
                        ]);
            })));
  var propertyGroupActions = Belt_List.concatMany(Belt_Array.mapU(importModel.propertyGroups, (function (propertyGroup) {
              var maybeExistingPropertyGroup = Belt_List.getBy(model.propertyBundles, (function (group) {
                      return group.name === propertyGroup.name;
                    }));
              var propertyGroupId = Belt_Option.mapWithDefault(maybeExistingPropertyGroup, propertyGroup.id, (function (group) {
                      return group.id;
                    }));
              var propertyGroupProperties = Belt_Option.mapWithDefault(maybeExistingPropertyGroup, /* [] */0, (function (group) {
                      return group.properties;
                    }));
              return Belt_List.concat(Belt_Option.isSome(maybeExistingPropertyGroup) ? /* [] */0 : ({
                              hd: {
                                NAME: "CreatePropertyGroup",
                                VAL: [
                                  propertyGroupId,
                                  propertyGroup.name
                                ]
                              },
                              tl: /* [] */0
                            }), Belt_List.fromArray(Belt_Array.keepMap(propertyGroup.properties, (function (property) {
                                    var maybeModelProperty = Belt_List.getBy(model.properties, (function (modelProperty) {
                                            if (modelProperty.TAG === /* PropertyRef */0) {
                                              return false;
                                            } else {
                                              return modelProperty._0.name === property.name;
                                            }
                                          }));
                                    var maybeImportModelProperty = Belt_Array.getBy(Belt_Array.concat(importModel.newProperties, importModel.updatedProperties), (function (importModelProperty) {
                                            return importModelProperty.id === property.id;
                                          }));
                                    var propertyId = maybeModelProperty !== undefined ? (
                                        maybeModelProperty.TAG === /* PropertyRef */0 ? (
                                            maybeImportModelProperty !== undefined ? maybeImportModelProperty.id : undefined
                                          ) : maybeModelProperty._0.id
                                      ) : (
                                        maybeImportModelProperty !== undefined ? maybeImportModelProperty.id : undefined
                                      );
                                    if (propertyId !== undefined && !Belt_List.some(propertyGroupProperties, (function (propertyIdInGroup) {
                                              return propertyIdInGroup === propertyId;
                                            }))) {
                                      return {
                                              NAME: "AddPropertyToGroup",
                                              VAL: [
                                                propertyGroupId,
                                                propertyId
                                              ]
                                            };
                                    }
                                    
                                  }))));
            })));
  var reduceModelWithActions = function (actions, model) {
    return Belt_List.reduceU(actions, model, ActionsReducer.reduce);
  };
  var buildActionsForEvent = function (updatedEventOpt, reducedModel, $$event) {
    var updatedEvent = updatedEventOpt !== undefined ? updatedEventOpt : false;
    var eventId = $$event.id;
    var maybeConflictingNameIndex = $$event.uniqueNameIndex;
    var propertiesValues = Belt_Array.keepMapU($$event.updatedPropertyIds, (function (updatedPropertyId) {
            return Belt_Option.flatMap(Belt_Array.getBy(importModel.updatedProperties, (function (param) {
                              return param.id === updatedPropertyId[0];
                            })), (function (updatedProperty) {
                          var modelEvent = StateUtils.getEventById($$event.id, reducedModel);
                          var modelProperty = StateUtils.resolvePropertyById(updatedProperty.id, reducedModel);
                          return Belt_Option.map(modelProperty, (function (modelProperty) {
                                        var matchesInModelProperty = Belt_List.toArray(PropertyValidationUtils.getPropertyMatches(modelProperty));
                                        var currentSourcesOnEvent = updatedEvent ? Belt_Option.mapWithDefault(modelEvent, [], (function (param) {
                                                  return Belt_List.toArray(Belt_List.map(param.includeSources, (function (param) {
                                                                    return param.id;
                                                                  })));
                                                })) : [];
                                        var sourcesForNewMatches = updatedEvent && Caml_obj.caml_equal($$event.sources, []) ? currentSourcesOnEvent : Belt_Array.keepMap($$event.sources, (function (sourceName) {
                                                  return Belt_Option.map(Belt_MapString.get(sourceNameToIdMap, sourceName), (function (prim) {
                                                                return prim[0];
                                                              }));
                                                }));
                                        var sourcesToExcludeForNewMatches = Belt_Array.keep(Belt_Array.map(Belt_List.toArray(reducedModel.sources), (function (param) {
                                                    return param.id;
                                                  })), (function (sourceId) {
                                                return !Belt_List.has(Belt_List.fromArray(sourcesForNewMatches), sourceId, StringExt.cmp);
                                              }));
                                        var updatedMatchSourceExclusions = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.keepMap(StateUtils.getMatchesFromProperty(modelProperty), (function (validation) {
                                                        var match = validation[0];
                                                        if (typeof match !== "object") {
                                                          return ;
                                                        }
                                                        if (match.NAME !== "Literal") {
                                                          return ;
                                                        }
                                                        var match$1 = match.VAL;
                                                        if (typeof match$1 === "object" && match$1.NAME === "StringLit") {
                                                          return [
                                                                  match$1.VAL,
                                                                  validation[1]
                                                                ];
                                                        }
                                                        
                                                      }))));
                                        var newMatches = Belt_SetString.toArray(Belt_SetString.diff(Belt_SetString.fromArray(updatedProperty.matches), Belt_SetString.fromArray(matchesInModelProperty)));
                                        var updatedMatches = Belt_SetString.toArray(Belt_SetString.intersect(Belt_SetString.fromArray(updatedProperty.matches), Belt_SetString.fromArray(matchesInModelProperty)));
                                        var updatedMatchMapForSourcesToToggleOn = Belt_MapString.map(Belt_MapString.keep(updatedMatchSourceExclusions, (function (match_, param) {
                                                    return Belt_List.has(Belt_List.fromArray(updatedMatches), match_, StringExt.cmp);
                                                  })), (function (exclusionList) {
                                                return Belt_List.toArray(Belt_List.keep(exclusionList, (function (sourceId) {
                                                                  return Belt_List.has(Belt_List.fromArray(sourcesForNewMatches), sourceId, StringExt.cmp);
                                                                })));
                                              }));
                                        return {
                                                propertyId: updatedProperty.id,
                                                newMatches: newMatches,
                                                sourcesToExcludeForNewMatches: sourcesToExcludeForNewMatches,
                                                updatedMatches: updatedMatches,
                                                updatedMatchMapForSourcesToToggleOn: updatedMatchMapForSourcesToToggleOn
                                              };
                                      }));
                        }));
          }));
    var updatedPropertiesActions = Belt_Array.concatMany(Belt_Array.map(propertiesValues, (function (propertyValues) {
                if (propertyValues.newMatches.length !== 0) {
                  return [{
                            NAME: "AddPropertyValidation",
                            VAL: [
                              propertyValues.propertyId,
                              {
                                NAME: "Matches",
                                VAL: Belt_List.map(Belt_List.fromArray(propertyValues.newMatches), (function (match_) {
                                        return [
                                                {
                                                  NAME: "Literal",
                                                  VAL: {
                                                    NAME: "StringLit",
                                                    VAL: match_
                                                  }
                                                },
                                                Belt_List.fromArray(propertyValues.sourcesToExcludeForNewMatches)
                                              ];
                                      }))
                              }
                            ]
                          }];
                } else {
                  return [];
                }
              })));
    var updatedMatchesToggledOnForNewSources = Belt_Array.concatMany(Belt_Array.map(propertiesValues, (function (propertyValues) {
                return Belt_Array.concatMany(Belt_Array.keepMap(propertyValues.updatedMatches, (function (match_) {
                                  var sourcesToToggleOn = Belt_MapString.get(propertyValues.updatedMatchMapForSourcesToToggleOn, match_);
                                  return Belt_Option.map(sourcesToToggleOn, (function (sourceIds) {
                                                return Belt_Array.map(sourceIds, (function (sourceId) {
                                                              return {
                                                                      NAME: "TogglePropertyValidationMatchSource",
                                                                      VAL: [
                                                                        propertyValues.propertyId,
                                                                        match_,
                                                                        sourceId,
                                                                        true
                                                                      ]
                                                                    };
                                                            }));
                                              }));
                                })));
              })));
    var updatedPropertiesActions$1 = Belt_List.fromArray(Belt_Array.concat(updatedPropertiesActions, updatedMatchesToggledOnForNewSources));
    return Belt_List.concatMany([
                updatedEvent ? /* [] */0 : ({
                      hd: {
                        NAME: "AddEvent",
                        VAL: [
                          eventId,
                          $$event.name
                        ]
                      },
                      tl: /* [] */0
                    }),
                updatedEvent || maybeConflictingNameIndex === undefined ? /* [] */0 : ({
                      hd: {
                        NAME: "UpdateEventUniqueNameV2",
                        VAL: [
                          eventId,
                          maybeConflictingNameIndex === 0 ? $$event.name : $$event.name + " (" + String(maybeConflictingNameIndex) + ")"
                        ]
                      },
                      tl: /* [] */0
                    }),
                updatedEvent || $$event.description === "" ? /* [] */0 : ({
                      hd: {
                        NAME: "UpdateEventDescription",
                        VAL: [
                          eventId,
                          $$event.description
                        ]
                      },
                      tl: /* [] */0
                    }),
                Belt_List.fromArray(Belt_Array.mapU($$event.categories, (function (categoryName) {
                            var goalId = Belt_MapString.getExn(categoryNameToIdMap, categoryName);
                            return {
                                    NAME: "AddEventToGoal",
                                    VAL: [
                                      goalId,
                                      eventId
                                    ]
                                  };
                          }))),
                Belt_List.fromArray(Belt_Array.mapU($$event.tags, (function (tag) {
                            return {
                                    NAME: "AddTag",
                                    VAL: [
                                      eventId,
                                      tag
                                    ]
                                  };
                          }))),
                Belt_List.fromArray(Belt_Array.concatMany(Belt_Array.mapU($$event.propertyIds, (function (param) {
                                var propertyId = param[0];
                                return Belt_Array.concat([{
                                              NAME: "AddPropertyRef",
                                              VAL: [
                                                eventId,
                                                propertyId
                                              ]
                                            }], Belt_Option.mapWithDefault(param[1], [], (function (pinnedValue) {
                                                  return [{
                                                            NAME: "UpdatePropertyPinnedValue",
                                                            VAL: [
                                                              eventId,
                                                              propertyId,
                                                              pinnedValue
                                                            ]
                                                          }];
                                                })));
                              })))),
                Belt_List.fromArray(Belt_Array.mapU($$event.propertyGroupIds, (function (propertyGroupId) {
                            return {
                                    NAME: "AddPropertyGroupToEvent",
                                    VAL: [
                                      eventId,
                                      propertyGroupId
                                    ]
                                  };
                          }))),
                Belt_List.fromArray(Belt_Array.mapU($$event.sources, (function (sourceName) {
                            var match = Belt_MapString.getExn(sourceNameToIdMap, sourceName);
                            return {
                                    NAME: "IncludeEventInSourceV2",
                                    VAL: [
                                      eventId,
                                      match[0],
                                      match[1],
                                      false
                                    ]
                                  };
                          }))),
                updatedPropertiesActions$1,
                updatedEvent ? /* [] */0 : Belt_List.fromArray(Belt_Array.mapU($$event.nameMapping, (function (param) {
                              var maybeModelDestination = Belt_Option.flatMap(param[1], (function (destinationName) {
                                      return Belt_List.getBy(reducedModel.destinations, (function (destination) {
                                                    return Caml_obj.caml_equal(destination.name, destinationName);
                                                  }));
                                    }));
                              return {
                                      NAME: "AddRule",
                                      VAL: [
                                        Shortid(),
                                        {
                                          NAME: "Event",
                                          VAL: $$event.id
                                        },
                                        {
                                          NAME: "NameMapping",
                                          VAL: {
                                            destination: Belt_Option.map(maybeModelDestination, (function (modeldestination) {
                                                    return {
                                                            NAME: "Destination",
                                                            VAL: modeldestination.id
                                                          };
                                                  })),
                                            name: param[0]
                                          }
                                        }
                                      ]
                                    };
                            })))
              ]);
  };
  var reducedModel = {
    contents: model
  };
  var actions = Belt_List.concatMany(Belt_Array.mapU(importModel.newEvents, (function ($$event) {
              var actions = buildActionsForEvent(undefined, reducedModel.contents, $$event);
              reducedModel.contents = reduceModelWithActions(actions, reducedModel.contents);
              return actions;
            })));
  var reducedModel$1 = reducedModel.contents;
  var reducedModel$2 = {
    contents: reducedModel$1
  };
  var updatedEventActions = Belt_List.concatMany(Belt_Array.mapU(importModel.updatedEvents, (function ($$event) {
              var actions = buildActionsForEvent(true, reducedModel$2.contents, $$event);
              reducedModel$2.contents = reduceModelWithActions(actions, reducedModel$2.contents);
              return actions;
            })));
  return Belt_List.concatMany([
              match[0],
              match$1[0],
              newPropertyActions,
              propertyGroupActions,
              actions,
              updatedEventActions
            ]);
}

exports.buildActions = buildActions;
/* shortid Not a pure module */
