// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Caml = require("rescript/lib/js/caml.js");
var CmdK = require("./CmdK.bs.js");
var $$Text = require("./Text.bs.js");
var Kbar = require("kbar");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Fuzzaldrin = require("fuzzaldrin");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var IconArrowDownWest = require("./IconArrowDownWest.bs.js");

var sectionContainer = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(12)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("flexEnd"),
          tl: {
            hd: Css.maxHeight(Css.px(15)),
            tl: /* [] */0
          }
        }
      }
    });

var actionContainer = Curry._1(Css.style, {
      hd: Css.padding(Css.px(12)),
      tl: {
        hd: Css.borderRadius(Css.px(2)),
        tl: /* [] */0
      }
    });

var actionContent = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.position("relative"),
          tl: {
            hd: Css.maxHeight(Css.px(45)),
            tl: /* [] */0
          }
        }
      }
    });

var actionContainerActive = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.blueBg),
      tl: /* [] */0
    });

var shortcut = Curry._1(Css.style, {
      hd: Css.padding(Css.px(4)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.grey90, 0.1)),
        tl: {
          hd: Css.borderRadius(Css.px(4)),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.setAlpha(Styles.Color.white, 0.5)),
            tl: {
              hd: Css.fontFamily(Styles.monoFontFamily),
              tl: {
                hd: Css.fontWeight(Styles.FontWeight.semi),
                tl: {
                  hd: Css.fontSize(Styles.FontSize.regular),
                  tl: {
                    hd: Css.color(Styles.Color.grey80),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var Style = {
  sectionContainer: sectionContainer,
  actionContainer: actionContainer,
  actionContent: actionContent,
  actionContainerActive: actionContainerActive,
  shortcut: shortcut
};

function CmdKResults$Subtitle(Props) {
  var subtitle = Props.subtitle;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginLeft(Css.px(8)),
                    tl: {
                      hd: Css.flexShrink(0.0),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  singleLine: true,
                  color: Styles.Color.grey70,
                  children: subtitle
                }));
}

var Subtitle = {
  make: CmdKResults$Subtitle
};

function score(action, searchQuery) {
  var multiplier = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(action.scoreMultiplier), 1.0);
  var item = action.item;
  var stringScore = Math.max(Fuzzaldrin.score(CmdK.itemToString(item), searchQuery), Fuzzaldrin.score(CmdK.itemToStringWithDescription(item), searchQuery) * 0.04);
  if (searchQuery === "") {
    return multiplier;
  } else {
    return stringScore * multiplier;
  }
}

function compare(a, b, searchQuery) {
  if (a.TAG === /* Section */0 || b.TAG === /* Section */0) {
    return 0;
  } else {
    return Caml.caml_float_compare(score(b._0, searchQuery), score(a._0, searchQuery));
  }
}

function scoreAction(result, searchQuery) {
  var action = Curry._1(CmdK.Result.convert, result);
  if (action.TAG === /* Section */0 || score(action._0, searchQuery) <= 0.0015) {
    return ;
  } else {
    return Curry._1(CmdK.Result.convert, result);
  }
}

function searchItemMatchesSearchCategory(action, searchCategory) {
  var exit = 0;
  switch (action.TAG | 0) {
    case /* Event */4 :
    case /* NameMappedEvent */5 :
        exit = 1;
        break;
    case /* Property */6 :
    case /* NameMappedProperty */7 :
        exit = 2;
        break;
    case /* Source */8 :
        if (searchCategory === "sources") {
          return true;
        } else {
          return false;
        }
    case /* Destination */9 :
        if (searchCategory === "destinations") {
          return true;
        } else {
          return false;
        }
    case /* Integration */10 :
        if (searchCategory === "integrations") {
          return true;
        } else {
          return false;
        }
    case /* Metric */11 :
        if (searchCategory === "metrics") {
          return true;
        } else {
          return false;
        }
    case /* Category */12 :
        if (searchCategory === "categories") {
          return true;
        } else {
          return false;
        }
    case /* PropertyGroup */13 :
        if (searchCategory === "propertyGroups") {
          return true;
        } else {
          return false;
        }
    case /* SavedView */15 :
        if (searchCategory === "saved-views") {
          return true;
        } else {
          return false;
        }
    default:
      return false;
  }
  switch (exit) {
    case 1 :
        if (searchCategory === "events") {
          return true;
        } else {
          return false;
        }
    case 2 :
        if (searchCategory === "properties") {
          return true;
        } else {
          return false;
        }
    
  }
}

function unarchiveItemMatchesUnarchiveCategory(action, searchCategory) {
  var exit = 0;
  switch (action.TAG | 0) {
    case /* Event */4 :
    case /* NameMappedEvent */5 :
        exit = 1;
        break;
    case /* Property */6 :
    case /* NameMappedProperty */7 :
        exit = 2;
        break;
    case /* Source */8 :
        if (searchCategory === "unarchiveSources") {
          return true;
        } else {
          return false;
        }
    case /* Destination */9 :
        if (searchCategory === "unarchiveDestinations") {
          return true;
        } else {
          return false;
        }
    case /* Metric */11 :
        if (searchCategory === "unarchiveMetrics") {
          return true;
        } else {
          return false;
        }
    case /* Category */12 :
        if (searchCategory === "unarchiveCategories") {
          return true;
        } else {
          return false;
        }
    case /* PropertyGroup */13 :
        if (searchCategory === "unarchivePropertyGroups") {
          return true;
        } else {
          return false;
        }
    case /* GroupType */14 :
        if (searchCategory === "unarchiveGroupTypes") {
          return true;
        } else {
          return false;
        }
    default:
      return false;
  }
  switch (exit) {
    case 1 :
        if (searchCategory === "unarchiveEvents") {
          return true;
        } else {
          return false;
        }
    case 2 :
        if (searchCategory === "unarchiveProperties") {
          return true;
        } else {
          return false;
        }
    
  }
}

function filterNestedActionBySearchQuery(action, result, rootActionId, searchQuery) {
  var match = action.item;
  var match$1 = action.parent;
  var exit = 0;
  if (match.TAG === /* Command */2) {
    if (match$1 === undefined) {
      return ;
    }
    switch (match$1) {
      case "search" :
          if (searchQuery === "") {
            if (rootActionId === "search") {
              return Curry._1(CmdK.Result.convert, result);
            }
            exit = 1;
          } else {
            exit = 1;
          }
          break;
      case "unarchive" :
          if (searchQuery === "") {
            if (rootActionId === "unarchive") {
              return Curry._1(CmdK.Result.convert, result);
            }
            exit = 1;
          } else {
            exit = 1;
          }
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (match$1 === undefined) {
      return ;
    }
    var exit$1 = 0;
    var exit$2 = 0;
    switch (match$1) {
      case "search" :
          if (searchQuery === "") {
            if (searchItemMatchesSearchCategory(match, rootActionId)) {
              return Curry._1(CmdK.Result.convert, result);
            }
            exit$2 = 3;
          } else {
            exit$2 = 3;
          }
          break;
      case "unarchive" :
          if (searchQuery === "") {
            if (unarchiveItemMatchesUnarchiveCategory(match, rootActionId)) {
              return Curry._1(CmdK.Result.convert, result);
            }
            exit$2 = 3;
          } else {
            exit$2 = 3;
          }
          break;
      default:
        if (searchQuery === "") {
          if (match$1 === rootActionId) {
            return Curry._1(CmdK.Result.convert, result);
          }
          exit$1 = 2;
        } else {
          exit$1 = 2;
        }
    }
    if (exit$2 === 3) {
      if (searchQuery === "") {
        return ;
      }
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      if (match$1 === rootActionId) {
        return scoreAction(result, searchQuery);
      }
      switch (match$1) {
        case "search" :
            if (searchItemMatchesSearchCategory(match, rootActionId)) {
              return scoreAction(result, searchQuery);
            } else {
              return ;
            }
        case "unarchive" :
            if (unarchiveItemMatchesUnarchiveCategory(match, rootActionId)) {
              return scoreAction(result, searchQuery);
            } else {
              return ;
            }
        default:
          return ;
      }
    }
    
  }
  
}

function filterRootActionBySearchQuery(action, result, searchQuery) {
  var match = action.item;
  var match$1 = action.parent;
  var exit = 0;
  if (searchQuery === "") {
    if ((
        match$1 === undefined ? undefined : Caml_option.some(match$1)
      ) === undefined) {
      return Curry._1(CmdK.Result.convert, result);
    } else {
      return ;
    }
  }
  if (match.TAG === /* Command */2) {
    if (searchQuery !== "") {
      return ;
    }
    exit = 1;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var exit$1 = 0;
    if (match$1 !== undefined) {
      if (match$1 !== "search") {
        return ;
      }
      exit$1 = 2;
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      if (score(action, searchQuery) > 0.0015) {
        return Curry._1(CmdK.Result.convert, result);
      } else {
        return ;
      }
    }
    
  }
  
}

function filterActionBySearchQuery(result, rootActionId, searchQuery) {
  var match = Curry._1(CmdK.Result.convert, result);
  var match$1 = searchQuery.trim();
  if (rootActionId !== undefined) {
    if (match.TAG === /* Section */0) {
      return ;
    } else {
      return filterNestedActionBySearchQuery(match._0, result, rootActionId, match$1);
    }
  } else if (match.TAG === /* Section */0) {
    return ;
  } else {
    return filterRootActionBySearchQuery(match._0, result, match$1);
  }
}

function filterAndSortActions(actions, rootActionId, searchQuery) {
  return Belt_Array.keepMap(actions, (function (param) {
                  return filterActionBySearchQuery(param[1], rootActionId, searchQuery);
                })).sort(function (param, param$1) {
              return compare(param, param$1, searchQuery);
            });
}

function CmdKResults(Props) {
  var match = Curry._1(CmdK.Matches.useMatches, undefined);
  var kBarState = Kbar.useKBar(function (state) {
        return {
                searchQuery: state.searchQuery,
                actions: state.actions
              };
      });
  var searchQuery = kBarState.searchQuery;
  var filteredResults = filterAndSortActions(Js_dict.entries(kBarState.actions), match.rootActionId, searchQuery);
  if (filteredResults.length !== 0) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.padding(Css.px(8)),
                      tl: /* [] */0
                    })
              }, React.createElement(CmdK.Results.make, {
                    items: filteredResults,
                    onRender: (function (param) {
                        var active = param.active;
                        var item = param.item;
                        if (item.TAG === /* Section */0) {
                          return React.createElement("div", {
                                      className: sectionContainer
                                    }, React.createElement($$Text.make, {
                                          size: "Small",
                                          color: Styles.Color.grey70,
                                          children: item._0.toUpperCase()
                                        }));
                        }
                        var action = item._0;
                        var match = action.item;
                        var tmp;
                        switch (match.TAG | 0) {
                          case /* Event */4 :
                              tmp = React.createElement(CmdKResults$Subtitle, {
                                    subtitle: "Event"
                                  });
                              break;
                          case /* NameMappedEvent */5 :
                              tmp = React.createElement(CmdKResults$Subtitle, {
                                    subtitle: "Event name mapping for: " + match._1
                                  });
                              break;
                          case /* Property */6 :
                              var maybeGroupTypeName = match._2;
                              var sendAs = match._1;
                              var tmp$1;
                              if (typeof sendAs === "number") {
                                switch (sendAs) {
                                  case /* SystemProperty */0 :
                                      tmp$1 = "System Property";
                                      break;
                                  case /* EventProperty */1 :
                                      tmp$1 = "Event Property";
                                      break;
                                  case /* UserProperty */2 :
                                      tmp$1 = "User Property";
                                      break;
                                  
                                }
                              } else {
                                tmp$1 = maybeGroupTypeName !== undefined ? maybeGroupTypeName + " Property" : "Untitled Group Property";
                              }
                              tmp = React.createElement(CmdKResults$Subtitle, {
                                    subtitle: tmp$1
                                  });
                              break;
                          case /* NameMappedProperty */7 :
                              tmp = React.createElement(CmdKResults$Subtitle, {
                                    subtitle: "Property name mapping for: " + match._1
                                  });
                              break;
                          case /* Source */8 :
                              tmp = React.createElement(CmdKResults$Subtitle, {
                                    subtitle: "Source"
                                  });
                              break;
                          case /* Destination */9 :
                              tmp = React.createElement(CmdKResults$Subtitle, {
                                    subtitle: "Destination"
                                  });
                              break;
                          case /* Integration */10 :
                              tmp = React.createElement(CmdKResults$Subtitle, {
                                    subtitle: "Integration"
                                  });
                              break;
                          case /* Metric */11 :
                              tmp = React.createElement(CmdKResults$Subtitle, {
                                    subtitle: "Metric"
                                  });
                              break;
                          case /* Category */12 :
                              tmp = React.createElement(CmdKResults$Subtitle, {
                                    subtitle: "Category"
                                  });
                              break;
                          case /* PropertyGroup */13 :
                              tmp = React.createElement(CmdKResults$Subtitle, {
                                    subtitle: "Property Bundle"
                                  });
                              break;
                          case /* GroupType */14 :
                              tmp = React.createElement(CmdKResults$Subtitle, {
                                    subtitle: "Group Type"
                                  });
                              break;
                          case /* SavedView */15 :
                              tmp = React.createElement(CmdKResults$Subtitle, {
                                    subtitle: "Saved View"
                                  });
                              break;
                          default:
                            tmp = null;
                        }
                        var description = CmdK.itemDescription(action.item);
                        return React.createElement("div", {
                                    className: Curry._1(Css.merge, {
                                          hd: actionContainer,
                                          tl: {
                                            hd: active ? actionContainerActive : Curry._1(Css.style, {
                                                    hd: Css.backgroundColor("transparent"),
                                                    tl: /* [] */0
                                                  }),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement("div", {
                                        className: actionContent
                                      }, Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(action.icon), null, (function (icon) {
                                              return React.createElement(React.Fragment, undefined, Curry._1(icon, undefined), React.createElement(Spacer.make, {
                                                              width: 12
                                                            }));
                                            })), React.createElement($$Text.make, {
                                            size: "Medium",
                                            weight: "Semi",
                                            singleLine: true,
                                            color: Styles.Color.grey90,
                                            children: CmdK.itemToString(action.item)
                                          }), tmp, React.createElement(Spacer.make, {
                                            grow: 1.0
                                          }), Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(action.shortcut), null, (function (shortcut$1) {
                                              return Belt_Array.map(shortcut$1, (function (key) {
                                                            var match = key.toLowerCase();
                                                            var tmp;
                                                            switch (match) {
                                                              case "arrowleft" :
                                                                  tmp = "←";
                                                                  break;
                                                              case "arrowright" :
                                                                  tmp = "→";
                                                                  break;
                                                              case "escape" :
                                                                  tmp = "Esc";
                                                                  break;
                                                              default:
                                                                tmp = key.toLocaleUpperCase();
                                                            }
                                                            return React.createElement("div", {
                                                                        key: key,
                                                                        className: shortcut
                                                                      }, tmp);
                                                          }));
                                            })), active ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                                  width: 4
                                                }), React.createElement(IconArrowDownWest.make, {
                                                  size: 12,
                                                  color: Styles.Color.blueSecondary
                                                })) : React.createElement(Spacer.make, {
                                              width: 16
                                            })), description !== undefined ? React.createElement($$Text.make, {
                                          size: "Small",
                                          singleLine: true,
                                          color: Styles.Color.grey60,
                                          children: description
                                        }) : null);
                      })
                  }));
  } else {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.padding(Css.px(16)),
                      tl: /* [] */0
                    })
              }, React.createElement($$Text.make, {
                    weight: "Semi",
                    color: Styles.Color.grey50,
                    children: "No results"
                  }));
  }
}

var threshold = 0.0015;

var make = CmdKResults;

exports.Style = Style;
exports.Subtitle = Subtitle;
exports.threshold = threshold;
exports.score = score;
exports.compare = compare;
exports.scoreAction = scoreAction;
exports.searchItemMatchesSearchCategory = searchItemMatchesSearchCategory;
exports.unarchiveItemMatchesUnarchiveCategory = unarchiveItemMatchesUnarchiveCategory;
exports.filterNestedActionBySearchQuery = filterNestedActionBySearchQuery;
exports.filterRootActionBySearchQuery = filterRootActionBySearchQuery;
exports.filterActionBySearchQuery = filterActionBySearchQuery;
exports.filterAndSortActions = filterAndSortActions;
exports.make = make;
/* sectionContainer Not a pure module */
