// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function encode(t) {
  return Json_encode.object_({
              hd: [
                "ok",
                t.ok
              ],
              tl: {
                hd: [
                  "currentEditorSeats",
                  t.currentEditorSeats
                ],
                tl: {
                  hd: [
                    "planEditorSeats",
                    t.planEditorSeats
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode(json) {
  return {
          ok: Json_decode.field("ok", Json_decode.bool, json),
          currentEditorSeats: Json_decode.field("currentEditorSeats", Json_decode.$$int, json),
          planEditorSeats: Json_decode.field("planEditorSeats", Json_decode.$$int, json)
        };
}

exports.encode = encode;
exports.decode = decode;
/* No side effect */
