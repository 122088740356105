// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Uuid = require("uuid");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("./Icons.bs.js");
var Input = require("./Input.bs.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Sentry = require("./externals/Sentry.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var DemoBranch = require("./DemoBranch.bs.js");
var InputLabel = require("./InputLabel.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dom_storage = require("rescript/lib/js/dom_storage.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var NamedBranch = require("./NamedBranch.bs.js");
var ProfileModal = require("./ProfileModal.bs.js");
var FirebaseUtils = require("./firebaseUtils.bs.js");
var ViewerContext = require("./ViewerContext.bs.js");
var FramerMotion = require("framer-motion");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var ModalWithArtwork = require("./ModalWithArtwork.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

var localStorageKey = "newUserPrompt";

function hasSeenForSchemaId(schemaId) {
  try {
    return Belt_Array.someU(Belt_Option.mapWithDefault(Dom_storage.getItem(localStorageKey, localStorage), [], (function (value) {
                      var __x = JSON.parse(value);
                      return Json_decode.array(Json_decode.string, __x);
                    })), (function (value) {
                  return value === schemaId;
                }));
  }
  catch (exn){
    console.warn("Error retrieving local storage for new user prompt [AvoRoutes.res]");
    return false;
  }
}

function setHasSeenForSchemaId(schemaId) {
  if (!FirebaseFetcherHooks.isSuperUser(undefined)) {
    return ;
  }
  try {
    var schemaIdsHaveSeenNewUserPrompt = Belt_SetString.fromArray(Belt_Option.mapWithDefault(Dom_storage.getItem(localStorageKey, localStorage), [], (function (value) {
                var __x = JSON.parse(value);
                return Json_decode.array(Json_decode.string, __x);
              })));
    var newSchemaIdsHaveSeenNewUserPrompt = Belt_SetString.toArray(Belt_SetString.add(schemaIdsHaveSeenNewUserPrompt, schemaId));
    return Dom_storage.setItem(localStorageKey, Belt_Option.getWithDefault(JSON.stringify(newSchemaIdsHaveSeenNewUserPrompt), "[]"), localStorage);
  }
  catch (exn){
    console.warn("Error setting local storage for new user prompt [AvoRoutes.res]");
    return ;
  }
}

var LocalStorage = {
  localStorageKey: localStorageKey,
  hasSeenForSchemaId: hasSeenForSchemaId,
  setHasSeenForSchemaId: setHasSeenForSchemaId
};

function setUserPreferencesForSkippedDemo(userId) {
  $$Promise.$$catch(FirebaseUtils.setSkipDemoPreference(userId, true), (function (error) {
          console.warn("Error setting user skip demo user preference", error);
          Sentry.captureException(error, {
                message: "Error setting user preferences"
              });
          return Promise.resolve(undefined);
        }));
  
}

function getCurrentStep(state) {
  if (state >= 2) {
    return 2;
  } else {
    return 1;
  }
}

function NewUserModal$Steps(Props) {
  var activeStep = Props.activeStep;
  var totalSteps = Props.totalSteps;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.gap(Css.px(4)),
                      tl: /* [] */0
                    }
                  })
            }, Belt_Array.map(Belt_Array.range(1, totalSteps), (function (step) {
                    return React.createElement("div", {
                                key: "step-" + String(step),
                                className: Curry._1(Css.style, {
                                      hd: Css.width(Css.px(10)),
                                      tl: {
                                        hd: Css.height(Css.px(10)),
                                        tl: {
                                          hd: Css.borderRadius(Css.pct(50.0)),
                                          tl: {
                                            hd: Css.backgroundColor(step === activeStep ? Styles.Color.magenta : Styles.Color.grey30),
                                            tl: {
                                              hd: Css.transition({
                                                    NAME: "ms",
                                                    VAL: Styles.Duration.$$default
                                                  }, {
                                                    NAME: "ms",
                                                    VAL: 250.0
                                                  }, undefined, "background-color"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    })
                              });
                  })));
}

var Steps = {
  make: NewUserModal$Steps
};

function NewUserModal(Props) {
  var masterModel = Props.masterModel;
  var onClose = Props.onClose;
  var workspace = WorkspaceContext.use(undefined);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var user = ViewerContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useState(function () {
        return /* Name */0;
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setHasUpdatedName = match$1[1];
  var match$2 = React.useState(function () {
        return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), "");
      });
  var setNameInput = match$2[1];
  var nameInput = match$2[0];
  var match$3 = React.useState(function () {
        return Uuid.v4();
      });
  var inputId = match$3[0];
  var hasCreatedDemoBranch = Belt_Option.isSome(FirebaseFetcherHooks.useBranchOpenAction(schemaGroup.schemaId, "demo-" + user.id, "Demo", undefined, undefined));
  React.useEffect((function () {
          if (state < 3) {
            switch (state) {
              case /* Name */0 :
                  AnalyticsRe.namePromptDisplayed(schemaGroup, workspace.id);
                  break;
              case /* NameLoading */1 :
                  break;
              case /* Demo */2 :
                  AnalyticsRe.demoBranchPromptDisplayed(schemaGroup, workspace.id);
                  break;
              
            }
          }
          
        }), [state]);
  var handleSkipName = function (param) {
    setHasSeenForSchemaId(schemaGroup.schemaId);
    AnalyticsRe.namePromptInteracted(schemaGroup, "Skip", workspace.id);
    return Curry._1(setState, (function (param) {
                  return /* Demo */2;
                }));
  };
  var handleNameContinue = function (param) {
    setHasSeenForSchemaId(schemaGroup.schemaId);
    Curry._1(setHasUpdatedName, (function (param) {
            return true;
          }));
    Curry._1(setState, (function (param) {
            return /* NameLoading */1;
          }));
    var trimmedNameInput = nameInput.trim();
    $$Promise.$$catch(ProfileModal.updateName(user.id, trimmedNameInput).then(function (param) {
              AnalyticsRe.namePromptInteracted(schemaGroup, "Complete", workspace.id);
              if (hasCreatedDemoBranch) {
                Curry._1(onClose, undefined);
              } else {
                Curry._1(setState, (function (param) {
                        return /* Demo */2;
                      }));
              }
              return Promise.resolve(undefined);
            }), (function (_error) {
            Curry._1(setState, (function (param) {
                    return /* Name */0;
                  }));
            Curry._1(addToast, {
                  message: "Failed to update name, please try again",
                  toastType: /* Error */1
                });
            return Promise.resolve(undefined);
          }));
    
  };
  var onExploreDemo = function (param) {
    Curry._1(setState, (function (param) {
            return /* DemoLoading */3;
          }));
    return DemoBranch.create(user, hasCreatedDemoBranch, masterModel.sources, masterModel.openBranches, schemaGroup, sendActions, (function (demoBranch) {
                  Router.Schema.pushSchemaRoute({
                        NAME: "branch",
                        VAL: NamedBranch.getId(demoBranch)
                      }, undefined, "events");
                  Curry._1(onClose, undefined);
                  AnalyticsRe.demoBranchCreated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, demoBranch), Belt_List.length(masterModel.events), NamedBranch.getId(demoBranch), workspace.id);
                  
                }), (function (error) {
                  console.warn("Error creating demo branch", error);
                  Sentry.captureException(error, {
                        message: "Error creating demo branch"
                      });
                  Curry._1(addToast, {
                        message: "Could not create demo branch",
                        toastType: /* Error */1
                      });
                  return Curry._1(onClose, undefined);
                }));
  };
  var handleSkipDemo = function (param) {
    setUserPreferencesForSkippedDemo(user.id);
    return Curry._1(onClose, undefined);
  };
  return React.createElement(ModalWithArtwork.make, {
              artwork: React.createElement(Icons.NewUserIllustration.make, {}),
              children: null,
              noDismiss: true,
              onClose: onClose,
              title: state >= 2 ? (
                  match$1[0] ? "Hi, " + nameInput.trim() + "!" : "Let's get started!"
                ) : "Welcome to your Avo workspace",
              fixedHeight: 385
            }, React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  initial: false,
                  children: React.createElement(FramerMotion.motion.div, {
                        animate: {
                          opacity: 1,
                          x: 0
                        },
                        transition: {
                          duration: 0.2
                        },
                        initial: {
                          opacity: 0,
                          x: 40
                        },
                        exit: {
                          opacity: 0,
                          x: -40
                        },
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.flexDirection("column"),
                                tl: {
                                  hd: Css.height(Css.pct(100.0)),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        key: state >= 2 ? "Demo" : "Name",
                        children: state >= 2 ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                    color: Styles.Color.grey80,
                                    children: "Would you like to create a demo branch to explore the basics of how events are planned, designed and implemented using Avo?"
                                  }), React.createElement(Spacer.make, {
                                    height: 16,
                                    grow: 1.0
                                  }), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(Button.make, {
                                        disabled: hasCreatedDemoBranch,
                                        label: "Explore demo",
                                        loading: state === /* DemoLoading */3,
                                        onClick: onExploreDemo,
                                        size: "large"
                                      }), React.createElement(Spacer.make, {
                                        width: 16
                                      }), React.createElement(Button.make, {
                                        label: "Skip demo",
                                        onClick: handleSkipDemo,
                                        size: "large",
                                        style: "outline"
                                      }))) : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                    color: Styles.Color.grey80,
                                    children: "Before we get started, what should we call you?"
                                  }), React.createElement(Spacer.make, {
                                    height: 28
                                  }), React.createElement(InputLabel.make, {
                                    htmlFor: inputId,
                                    children: "Your first name or nickname"
                                  }), React.createElement(Input.make, {
                                    value: nameInput,
                                    onChange: (function (name) {
                                        return Curry._1(setNameInput, (function (param) {
                                                      return name;
                                                    }));
                                      }),
                                    id: inputId,
                                    autoFocus: true,
                                    onEnter: (function (param) {
                                        if (nameInput !== "") {
                                          return handleNameContinue(undefined);
                                        }
                                        
                                      }),
                                    disabled: state === /* NameLoading */1,
                                    containerClassName: {
                                      hd: Css.margin("zero"),
                                      tl: /* [] */0
                                    }
                                  }), React.createElement(Spacer.make, {
                                    height: 16,
                                    grow: 1.0
                                  }), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(Button.make, {
                                        disabled: nameInput.trim() === "",
                                        label: "Continue",
                                        loading: state === /* NameLoading */1,
                                        onClick: handleNameContinue,
                                        size: "large"
                                      }), React.createElement(Spacer.make, {
                                        width: 16
                                      }), React.createElement(Button.make, {
                                        label: "Skip",
                                        onClick: handleSkipName,
                                        size: "large",
                                        style: "outline"
                                      })))
                      })
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, /* [] */0)
                }, React.createElement(NewUserModal$Steps, {
                      activeStep: getCurrentStep(state),
                      totalSteps: 2
                    })));
}

var steps = 2;

var make = NewUserModal;

exports.LocalStorage = LocalStorage;
exports.setUserPreferencesForSkippedDemo = setUserPreferencesForSkippedDemo;
exports.steps = steps;
exports.getCurrentStep = getCurrentStep;
exports.Steps = Steps;
exports.make = make;
/* Css Not a pure module */
