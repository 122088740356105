// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function input(size, noBorder, stretch) {
  return Curry._1(Css.style, {
              hd: Css.unsafe("appearance", "none"),
              tl: {
                hd: Css.display("block"),
                tl: {
                  hd: Css.fontSize(size === "Small" ? Styles.FontSize.small : Styles.FontSize.regular),
                  tl: {
                    hd: Css.color(Styles.Color.grey90),
                    tl: {
                      hd: Css.border(Css.px(noBorder ? 0 : 1), "solid", Styles.Color.grey30),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: {
                            hd: Css.flexShrink(1.0),
                            tl: {
                              hd: Css.paddingTop(Css.px(6)),
                              tl: {
                                hd: Css.paddingRight(Css.px(8)),
                                tl: {
                                  hd: Css.paddingBottom(Css.px(6)),
                                  tl: {
                                    hd: Css.paddingLeft(Css.px(8)),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.transition({
                                              NAME: "ms",
                                              VAL: Styles.Duration.$$short
                                            }, undefined, undefined, "all"),
                                        tl: {
                                          hd: Css.lineHeight(Css.pct(140.0)),
                                          tl: {
                                            hd: Css.margin(Css.px(0)),
                                            tl: {
                                              hd: Css.placeholder({
                                                    hd: Css.color(Styles.Color.grey50),
                                                    tl: {
                                                      hd: Css.fontWeight(Styles.FontWeight.medium),
                                                      tl: /* [] */0
                                                    }
                                                  }),
                                              tl: {
                                                hd: Css.disabled({
                                                      hd: Css.borderColor(Styles.Color.grey30),
                                                      tl: {
                                                        hd: Css.backgroundColor(Styles.Color.grey10),
                                                        tl: {
                                                          hd: Css.color(Styles.Color.grey50),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }),
                                                tl: {
                                                  hd: Css.focus({
                                                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.blue)),
                                                        tl: {
                                                          hd: Css.outlineStyle("none"),
                                                          tl: {
                                                            hd: Css.borderColor(Styles.Color.grey40),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.borderColor(Styles.Color.grey40),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: stretch ? ({
                                                          hd: Css.width(Css.pct(100)),
                                                          tl: /* [] */0
                                                        }) : /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function TextInput(Props) {
  var autoFocus = Props.autoFocus;
  var disabledOpt = Props.disabled;
  var id = Props.id;
  var onBlur = Props.onBlur;
  var onChange = Props.onChange;
  var onFocus = Props.onFocus;
  var noBorderOpt = Props.noBorder;
  var placeholderOpt = Props.placeholder;
  var sizeOpt = Props.size;
  var stretchOpt = Props.stretch;
  var forwardRef = Props.forwardRef;
  var onEnterOpt = Props.onEnter;
  var value = Props.value;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var noBorder = noBorderOpt !== undefined ? noBorderOpt : false;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  var size = sizeOpt !== undefined ? sizeOpt : "Regular";
  var stretch = stretchOpt !== undefined ? stretchOpt : false;
  var onEnter = onEnterOpt !== undefined ? onEnterOpt : (function (param) {
        
      });
  var inputRef = React.useRef(null);
  React.useEffect(function () {
        var timeout = setTimeout((function (param) {
                var match = inputRef.current;
                if (autoFocus !== undefined && autoFocus) {
                  if (forwardRef !== undefined) {
                    return Belt_Option.forEach(Caml_option.nullable_to_opt(forwardRef.current), (function (prim) {
                                  prim.focus();
                                  
                                }));
                  } else if (!(match == null)) {
                    match.focus();
                    return ;
                  } else {
                    return ;
                  }
                }
                
              }), 10);
        return (function (param) {
                  clearTimeout(timeout);
                  
                });
      });
  var tmp = {
    ref: forwardRef !== undefined ? forwardRef : inputRef,
    className: input(size, noBorder, stretch),
    disabled: disabled,
    placeholder: placeholder,
    type: "text",
    value: value,
    onKeyDown: (function ($$event) {
        if ($$event.key === "Enter") {
          return Curry._1(onEnter, undefined);
        }
        
      }),
    onFocus: (function ($$event) {
        if (onFocus !== undefined) {
          return Curry._1(onFocus, $$event.target.value);
        }
        
      }),
    onBlur: (function ($$event) {
        if (onBlur !== undefined) {
          return Curry._1(onBlur, $$event.target.value);
        }
        
      }),
    onChange: (function ($$event) {
        return Curry._1(onChange, $$event.target.value);
      })
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (autoFocus !== undefined) {
    tmp.autoFocus = Caml_option.valFromOption(autoFocus);
  }
  return React.createElement("input", tmp);
}

var make = TextInput;

exports.input = input;
exports.make = make;
/* Css Not a pure module */
