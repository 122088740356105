// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");

function logError(msg, l, lineNumber) {
  var currentOrigin = window.location.origin;
  if (currentOrigin.endsWith("localhost:1235")) {
    console.error("RouterTypes", lineNumber, msg, "\"" + Belt_List.toArray(l).join("/") + "\"");
    return ;
  }
  
}

function get(path) {
  if (!path) {
    return "index";
  }
  switch (path.hd) {
    case "auth" :
        var match = path.tl;
        if (!match) {
          return "notFound";
        }
        if (match.hd !== "cli") {
          return "notFound";
        }
        var match$1 = match.tl;
        if (!match$1) {
          return {
                  NAME: "auth",
                  VAL: "index"
                };
        }
        switch (match$1.hd) {
          case "error" :
              return {
                      NAME: "auth",
                      VAL: "error"
                    };
          case "success" :
              return {
                      NAME: "auth",
                      VAL: "success"
                    };
          default:
            return {
                    NAME: "auth",
                    VAL: "index"
                  };
        }
    case "invite" :
        return "invite";
    case "login" :
        return "login";
    case "onboarding" :
        var match$2 = path.tl;
        if (!match$2) {
          return {
                  NAME: "onboarding",
                  VAL: "job"
                };
        }
        switch (match$2.hd) {
          case "destinations" :
              return {
                      NAME: "onboarding",
                      VAL: "destinations"
                    };
          case "job" :
              return {
                      NAME: "onboarding",
                      VAL: "job"
                    };
          case "loading" :
              return {
                      NAME: "onboarding",
                      VAL: "loading"
                    };
          case "password" :
              return {
                      NAME: "onboarding",
                      VAL: "password"
                    };
          case "sources" :
              return {
                      NAME: "onboarding",
                      VAL: "sources"
                    };
          default:
            return {
                    NAME: "onboarding",
                    VAL: "job"
                  };
        }
    case "password-reset" :
    case "reset" :
        return "passwordReset";
    case "s" :
        var match$3 = path.tl;
        if (match$3) {
          return {
                  NAME: "share",
                  VAL: match$3.hd
                };
        } else {
          return "notFound";
        }
    case "schemas" :
        var match$4 = path.tl;
        if (match$4) {
          return {
                  NAME: "schema",
                  VAL: match$4.hd
                };
        } else {
          return "allSchemas";
        }
    case "signup" :
        return "signUp";
    case "welcome" :
        return "welcome";
    default:
      return "notFound";
  }
}

function toUrlFragment(baseRoute) {
  if (typeof baseRoute !== "object") {
    if (baseRoute === "passwordReset") {
      return "password-reset";
    } else if (baseRoute === "notFound") {
      return "login";
    } else if (baseRoute === "invite") {
      return "invite";
    } else if (baseRoute === "welcome") {
      return "welcome";
    } else if (baseRoute === "allSchemas") {
      return "schemas";
    } else if (baseRoute === "signUp") {
      return "signup";
    } else if (baseRoute === "index") {
      return "";
    } else {
      return "login";
    }
  }
  var variant = baseRoute.NAME;
  if (variant === "schema") {
    return "schemas/" + baseRoute.VAL;
  }
  if (variant === "share") {
    return "s/" + baseRoute.VAL;
  }
  if (variant === "onboarding") {
    var match = baseRoute.VAL;
    if (match === "job") {
      return "onboarding/job";
    } else if (match === "loading") {
      return "onboarding/loading";
    } else if (match === "destinations") {
      return "onboarding/destinations";
    } else if (match === "sources") {
      return "onboarding/sources";
    } else {
      return "onboarding/password";
    }
  }
  var match$1 = baseRoute.VAL;
  if (match$1 === "success") {
    return "auth/cli/success";
  } else if (match$1 === "index") {
    return "auth/cli";
  } else {
    return "auth/cli/error";
  }
}

var BaseRoute = {
  get: get,
  toUrlFragment: toUrlFragment
};

function get$1(path) {
  if (!path) {
    return "master";
  }
  if (path.hd !== "schemas") {
    return "master";
  }
  var match = path.tl;
  if (!match) {
    return "master";
  }
  var match$1 = match.tl;
  if (!match$1) {
    return "master";
  }
  if (match$1.hd !== "branches") {
    return "master";
  }
  var match$2 = match$1.tl;
  if (match$2) {
    return {
            NAME: "branch",
            VAL: match$2.hd
          };
  } else {
    return "master";
  }
}

function toUrlFragment$1(branch) {
  if (typeof branch !== "object") {
    return "";
  }
  var branchId = branch.VAL;
  if (branchId === "master") {
    return "";
  } else {
    return "branches/" + branchId;
  }
}

var Branch = {
  get: get$1,
  toUrlFragment: toUrlFragment$1
};

function get$2(_path) {
  while(true) {
    var path = _path;
    if (!path) {
      return "dashboard";
    }
    switch (path.hd) {
      case "auditlog" :
          return "auditLog";
      case "billing" :
          return "billing";
      case "datascope" :
          return {
                  NAME: "inspector",
                  VAL: "issues"
                };
      case "diff" :
          var match = path.tl;
          if (!match) {
            return {
                    NAME: "diff",
                    VAL: "index"
                  };
          }
          switch (match.hd) {
            case "activity" :
                var match$1 = match.tl;
                if (!match$1) {
                  return {
                          NAME: "diff",
                          VAL: "index"
                        };
                }
                if (match$1.hd !== "c") {
                  return {
                          NAME: "diff",
                          VAL: "index"
                        };
                }
                var match$2 = match$1.tl;
                if (match$2) {
                  return {
                          NAME: "diff",
                          VAL: {
                            NAME: "comment",
                            VAL: match$2.hd
                          }
                        };
                } else {
                  return {
                          NAME: "diff",
                          VAL: "index"
                        };
                }
            case "c" :
                var match$3 = match.tl;
                if (match$3) {
                  return {
                          NAME: "diff",
                          VAL: {
                            NAME: "comment",
                            VAL: match$3.hd
                          }
                        };
                } else {
                  return {
                          NAME: "diff",
                          VAL: "index"
                        };
                }
            case "implementation" :
                var match$4 = match.tl;
                if (!match$4) {
                  return {
                          NAME: "diff",
                          VAL: {
                            NAME: "implementation",
                            VAL: "overview"
                          }
                        };
                }
                var match$5 = match$4.tl;
                var sourceId = match$4.hd;
                var exit = 0;
                if (match$5 && match$5.hd === "c") {
                  var match$6 = match$5.tl;
                  if (match$6) {
                    return {
                            NAME: "diff",
                            VAL: {
                              NAME: "implementation",
                              VAL: {
                                NAME: "source",
                                VAL: [
                                  sourceId,
                                  match$6.hd
                                ]
                              }
                            }
                          };
                  }
                  exit = 2;
                } else {
                  exit = 2;
                }
                if (exit === 2) {
                  return {
                          NAME: "diff",
                          VAL: {
                            NAME: "implementation",
                            VAL: {
                              NAME: "source",
                              VAL: [
                                sourceId,
                                undefined
                              ]
                            }
                          }
                        };
                }
                break;
            default:
              return {
                      NAME: "diff",
                      VAL: "index"
                    };
          }
          break;
      case "events" :
          return "events";
      case "implement" :
          return "implement";
      case "import" :
          var match$7 = path.tl;
          if (!match$7) {
            return {
                    NAME: "import",
                    VAL: "index"
                  };
          }
          switch (match$7.hd) {
            case "review" :
                return {
                        NAME: "import",
                        VAL: "review"
                      };
            case "success" :
                return {
                        NAME: "import",
                        VAL: "success"
                      };
            default:
              return {
                      NAME: "import",
                      VAL: "index"
                    };
          }
      case "inspector" :
          var match$8 = path.tl;
          if (!match$8) {
            return {
                    NAME: "inspector",
                    VAL: "issues"
                  };
          }
          switch (match$8.hd) {
            case "events" :
                return {
                        NAME: "inspector",
                        VAL: "events"
                      };
            case "issues" :
                var match$9 = match$8.tl;
                if (!match$9) {
                  return {
                          NAME: "inspector",
                          VAL: "issues"
                        };
                }
                switch (match$9.hd) {
                  case "saved-views" :
                      return {
                              NAME: "inspector",
                              VAL: "savedViews"
                            };
                  case "view" :
                      var match$10 = match$9.tl;
                      if (match$10) {
                        return {
                                NAME: "inspector",
                                VAL: {
                                  NAME: "savedView",
                                  VAL: match$10.hd
                                }
                              };
                      } else {
                        return {
                                NAME: "inspector",
                                VAL: "issues"
                              };
                      }
                  default:
                    return {
                            NAME: "inspector",
                            VAL: "issues"
                          };
                }
            default:
              return {
                      NAME: "inspector",
                      VAL: "issues"
                    };
          }
      case "integrations" :
          return "integrations";
      case "goals" :
      case "metrics" :
          return "metrics";
      case "properties" :
          var match$11 = path.tl;
          if (!match$11) {
            return "properties";
          }
          switch (match$11.hd) {
            case "group" :
            case "groups" :
                return "propertyGroups";
            default:
              return "properties";
          }
      case "branches" :
      case "schemas" :
          break;
      case "settings" :
          return "settings";
      default:
        return "dashboard";
    }
    var match$12 = path.tl;
    if (!match$12) {
      return "dashboard";
    }
    _path = match$12.tl;
    continue ;
  };
}

function toUrlFragment$2(level) {
  if (typeof level !== "object") {
    if (level === "billing") {
      return "billing";
    } else if (level === "properties") {
      return "properties";
    } else if (level === "auditLog") {
      return "auditlog";
    } else if (level === "settings") {
      return "settings";
    } else if (level === "dashboard") {
      return "";
    } else if (level === "integrations") {
      return "integrations";
    } else if (level === "events") {
      return "events";
    } else if (level === "metrics") {
      return "metrics";
    } else if (level === "implement") {
      return "implement";
    } else {
      return "properties/groups";
    }
  }
  var variant = level.NAME;
  if (variant === "inspector") {
    var match = level.VAL;
    if (typeof match === "object") {
      return "inspector/issues/view/" + match.VAL;
    } else if (match === "issues") {
      return "inspector/issues";
    } else if (match === "events") {
      return "inspector/events";
    } else {
      return "inspector/issues/saved-views";
    }
  }
  if (variant === "import") {
    var match$1 = level.VAL;
    if (match$1 === "success") {
      return "import/success";
    } else if (match$1 === "index") {
      return "import";
    } else {
      return "import/review";
    }
  }
  var match$2 = level.VAL;
  if (typeof match$2 !== "object") {
    return "diff";
  }
  if (match$2.NAME === "comment") {
    return "diff/c/" + match$2.VAL;
  }
  var match$3 = match$2.VAL;
  if (typeof match$3 !== "object") {
    return "diff/implementation";
  }
  var match$4 = match$3.VAL;
  var commentId = match$4[1];
  var sourceId = match$4[0];
  if (commentId !== undefined) {
    return "diff/implementation/" + sourceId + "/c/" + commentId;
  } else {
    return "diff/implementation/" + sourceId;
  }
}

function isOnSameSchemaRoute(schemaRouteA, schemaRouteB) {
  if (typeof schemaRouteA !== "object") {
    return Caml_obj.caml_equal(schemaRouteA, schemaRouteB);
  }
  var variant = schemaRouteA.NAME;
  if (variant === "diff") {
    if (typeof schemaRouteB === "object" && schemaRouteB.NAME === "diff") {
      return true;
    } else {
      return Caml_obj.caml_equal(schemaRouteA, schemaRouteB);
    }
  } else if (variant === "import" && typeof schemaRouteB === "object" && schemaRouteB.NAME === "import") {
    return true;
  } else {
    return Caml_obj.caml_equal(schemaRouteA, schemaRouteB);
  }
}

var SchemaRoute = {
  get: get$2,
  toUrlFragment: toUrlFragment$2,
  isOnSameSchemaRoute: isOnSameSchemaRoute
};

var _map = {"event":"e","property":"p","propertyGroup":"pgroup","category":"cat","metric":"m","source":"s","destination":"d","integration":"i","codegenSource":"cs","inspectorIssue":"ii","functionsSource":"fs"};

var _revMap = {"e":"event","p":"property","pgroup":"propertyGroup","cat":"category","m":"metric","s":"source","d":"destination","i":"integration","cs":"codegenSource","ii":"inspectorIssue","fs":"functionsSource"};

function t_baseToJs(param) {
  return _map[param];
}

function t_baseFromJs(param) {
  return _revMap[param];
}

function toIdentifier(drawerItem) {
  var variant = drawerItem.NAME;
  return _map[variant === "codegenSource" ? "codegenSource" : (
                variant === "event" ? "event" : (
                    variant === "inspectorIssue" ? "inspectorIssue" : (
                        variant === "metric" ? "metric" : (
                            variant === "destination" ? "destination" : (
                                variant === "integration" ? "integration" : (
                                    variant === "property" ? "property" : (
                                        variant === "category" ? "category" : (
                                            variant === "source" ? "source" : "propertyGroup"
                                          )
                                      )
                                  )
                              )
                          )
                      )
                  )
              )];
}

function itemToUrlString(drawerItem) {
  var id;
  var variant = drawerItem.NAME;
  if (variant === "destination" || variant === "inspectorIssue" || variant === "codegenSource") {
    id = drawerItem.VAL;
  } else if (variant === "source") {
    var match = drawerItem.VAL;
    var match$1 = match[1];
    var id$1 = match[0];
    if (match$1 !== "overview") {
      if (match$1 === "codegenSetup") {
        return toIdentifier(drawerItem) + "/" + id$1 + "/codegen-setup";
      } else {
        return toIdentifier(drawerItem) + "/" + id$1 + "/inspector-setup";
      }
    }
    id = id$1;
  } else {
    id = drawerItem.VAL[0];
  }
  return toIdentifier(drawerItem) + "/" + id;
}

function commentToUrlString(drawerItem) {
  var variant = drawerItem.NAME;
  if (variant === "source" || variant === "destination" || variant === "inspectorIssue" || variant === "codegenSource") {
    return "";
  }
  var cid = drawerItem.VAL[1];
  if (cid !== undefined) {
    return "/c/" + cid;
  } else {
    return "";
  }
}

function triggerToUrlString(drawerItem) {
  if (typeof drawerItem !== "object") {
    return "";
  }
  if (drawerItem.NAME !== "event") {
    return "";
  }
  var tid = drawerItem.VAL[2];
  if (tid !== undefined) {
    return "/t/" + tid;
  } else {
    return "";
  }
}

function fullscreenToUrlString(drawerItem) {
  if (typeof drawerItem === "object" && drawerItem.NAME === "event" && drawerItem.VAL[3]) {
    return "/f";
  } else {
    return "";
  }
}

function toUrlFragment$3(drawerItem) {
  return itemToUrlString(drawerItem) + commentToUrlString(drawerItem) + triggerToUrlString(drawerItem) + fullscreenToUrlString(drawerItem);
}

function getParentSchemaRoute(drawerItem) {
  var variant = drawerItem.NAME;
  if (variant === "codegenSource") {
    return "implement";
  } else if (variant === "event") {
    return "events";
  } else if (variant === "inspectorIssue") {
    return {
            NAME: "inspector",
            VAL: "issues"
          };
  } else if (variant === "category" || variant === "metric") {
    return "metrics";
  } else if (variant === "source" || variant === "destination") {
    return "settings";
  } else if (variant === "integration") {
    return "integrations";
  } else if (variant === "property") {
    return "properties";
  } else {
    return "propertyGroups";
  }
}

var DrawerItem = {
  t_baseToJs: t_baseToJs,
  t_baseFromJs: t_baseFromJs,
  toIdentifier: toIdentifier,
  itemToUrlString: itemToUrlString,
  commentToUrlString: commentToUrlString,
  triggerToUrlString: triggerToUrlString,
  fullscreenToUrlString: fullscreenToUrlString,
  toUrlFragment: toUrlFragment$3,
  getParentSchemaRoute: getParentSchemaRoute
};

function toUrlFragment$4(drawerItems) {
  if (!drawerItems) {
    return "";
  }
  var tail = drawerItems.tl;
  var drawerItem = drawerItems.hd;
  if (tail) {
    return toUrlFragment$4(tail) + "/" + toUrlFragment$3(drawerItem);
  } else {
    return toUrlFragment$3(drawerItem);
  }
}

function handleEvents(path) {
  if (!path) {
    return /* [] */0;
  }
  var eventId = path.hd;
  var exit = 0;
  if (eventId === "list") {
    if (!path.tl) {
      return /* [] */0;
    }
    exit = 2;
  } else {
    exit = 2;
  }
  if (exit === 2) {
    var match = path.tl;
    if (!match) {
      return {
              hd: {
                NAME: "event",
                VAL: [
                  eventId,
                  undefined,
                  undefined,
                  false
                ]
              },
              tl: /* [] */0
            };
    }
    switch (match.hd) {
      case "c" :
          var match$1 = match.tl;
          if (match$1) {
            var match$2 = match$1.tl;
            var commentId = match$1.hd;
            if (!match$2) {
              return {
                      hd: {
                        NAME: "event",
                        VAL: [
                          eventId,
                          commentId,
                          undefined,
                          false
                        ]
                      },
                      tl: /* [] */0
                    };
            }
            if (match$2.hd === "trigger") {
              var match$3 = match$2.tl;
              if (match$3 && !match$3.tl) {
                return {
                        hd: {
                          NAME: "event",
                          VAL: [
                            eventId,
                            commentId,
                            match$3.hd,
                            false
                          ]
                        },
                        tl: /* [] */0
                      };
              }
              
            }
            
          }
          break;
      case "category" :
          var match$4 = match.tl;
          if (match$4) {
            var match$5 = match$4.tl;
            var goalId = match$4.hd;
            if (!match$5) {
              return {
                      hd: {
                        NAME: "category",
                        VAL: [
                          goalId,
                          undefined
                        ]
                      },
                      tl: {
                        hd: {
                          NAME: "event",
                          VAL: [
                            eventId,
                            undefined,
                            undefined,
                            false
                          ]
                        },
                        tl: /* [] */0
                      }
                    };
            }
            if (match$5.hd === "c") {
              var match$6 = match$5.tl;
              if (match$6 && !match$6.tl) {
                return {
                        hd: {
                          NAME: "category",
                          VAL: [
                            goalId,
                            match$6.hd
                          ]
                        },
                        tl: {
                          hd: {
                            NAME: "event",
                            VAL: [
                              eventId,
                              undefined,
                              undefined,
                              false
                            ]
                          },
                          tl: /* [] */0
                        }
                      };
              }
              
            }
            
          }
          break;
      case "f" :
          if (!match.tl) {
            return {
                    hd: {
                      NAME: "event",
                      VAL: [
                        eventId,
                        undefined,
                        undefined,
                        true
                      ]
                    },
                    tl: /* [] */0
                  };
          }
          break;
      case "g" :
          var match$7 = match.tl;
          if (match$7) {
            var match$8 = match$7.tl;
            var propertyGroupId = match$7.hd;
            if (!match$8) {
              return {
                      hd: {
                        NAME: "propertyGroup",
                        VAL: [
                          propertyGroupId,
                          undefined
                        ]
                      },
                      tl: {
                        hd: {
                          NAME: "event",
                          VAL: [
                            eventId,
                            undefined,
                            undefined,
                            false
                          ]
                        },
                        tl: /* [] */0
                      }
                    };
            }
            if (match$8.hd === "c") {
              var match$9 = match$8.tl;
              if (match$9 && !match$9.tl) {
                return {
                        hd: {
                          NAME: "propertyGroup",
                          VAL: [
                            propertyGroupId,
                            match$9.hd
                          ]
                        },
                        tl: {
                          hd: {
                            NAME: "event",
                            VAL: [
                              eventId,
                              undefined,
                              undefined,
                              false
                            ]
                          },
                          tl: /* [] */0
                        }
                      };
              }
              
            }
            
          }
          break;
      case "m" :
          var match$10 = match.tl;
          if (match$10 && !match$10.tl) {
            return {
                    hd: {
                      NAME: "metric",
                      VAL: [
                        match$10.hd,
                        undefined
                      ]
                    },
                    tl: {
                      hd: {
                        NAME: "event",
                        VAL: [
                          eventId,
                          undefined,
                          undefined,
                          false
                        ]
                      },
                      tl: /* [] */0
                    }
                  };
          }
          break;
      case "p" :
          var match$11 = match.tl;
          if (match$11) {
            var match$12 = match$11.tl;
            var propertyId = match$11.hd;
            if (!match$12) {
              return {
                      hd: {
                        NAME: "property",
                        VAL: [
                          propertyId,
                          undefined
                        ]
                      },
                      tl: {
                        hd: {
                          NAME: "event",
                          VAL: [
                            eventId,
                            undefined,
                            undefined,
                            false
                          ]
                        },
                        tl: /* [] */0
                      }
                    };
            }
            if (match$12.hd === "c") {
              var match$13 = match$12.tl;
              if (match$13 && !match$13.tl) {
                return {
                        hd: {
                          NAME: "property",
                          VAL: [
                            propertyId,
                            match$13.hd
                          ]
                        },
                        tl: {
                          hd: {
                            NAME: "event",
                            VAL: [
                              eventId,
                              undefined,
                              undefined,
                              false
                            ]
                          },
                          tl: /* [] */0
                        }
                      };
              }
              
            }
            
          }
          break;
      case "trigger" :
          var match$14 = match.tl;
          if (match$14 && !match$14.tl) {
            return {
                    hd: {
                      NAME: "event",
                      VAL: [
                        eventId,
                        undefined,
                        match$14.hd,
                        false
                      ]
                    },
                    tl: /* [] */0
                  };
          }
          break;
      default:
        
    }
  }
  logError("Legacy event not handled", path, 376);
  return /* [] */0;
}

function handlePropertyGroups(path) {
  if (!path) {
    return /* [] */0;
  }
  if (path.hd === "properties") {
    var match = path.tl;
    if (match) {
      switch (match.hd) {
        case "group" :
            var match$1 = match.tl;
            if (match$1) {
              var match$2 = match$1.tl;
              var propertyGroupId = match$1.hd;
              if (!match$2) {
                return {
                        hd: {
                          NAME: "propertyGroup",
                          VAL: [
                            propertyGroupId,
                            undefined
                          ]
                        },
                        tl: /* [] */0
                      };
              }
              if (match$2.hd === "c") {
                var match$3 = match$2.tl;
                if (match$3 && !match$3.tl) {
                  return {
                          hd: {
                            NAME: "propertyGroup",
                            VAL: [
                              propertyGroupId,
                              match$3.hd
                            ]
                          },
                          tl: /* [] */0
                        };
                }
                
              }
              
            }
            break;
        case "groups" :
            var match$4 = match.tl;
            if (match$4) {
              var match$5 = match$4.tl;
              var propertyId = match$4.hd;
              if (!match$5) {
                return {
                        hd: {
                          NAME: "property",
                          VAL: [
                            propertyId,
                            undefined
                          ]
                        },
                        tl: /* [] */0
                      };
              }
              if (match$5.hd === "c") {
                var match$6 = match$5.tl;
                if (match$6 && !match$6.tl) {
                  return {
                          hd: {
                            NAME: "property",
                            VAL: [
                              propertyId,
                              match$6.hd
                            ]
                          },
                          tl: /* [] */0
                        };
                }
                
              }
              
            }
            break;
        default:
          
      }
    }
    
  }
  logError("Legacy Property Bundle not handled", path, 393);
  return /* [] */0;
}

function handleProperties(path) {
  if (!path) {
    return /* [] */0;
  }
  var match = path.tl;
  var propertyId = path.hd;
  if (!match) {
    return {
            hd: {
              NAME: "property",
              VAL: [
                propertyId,
                undefined
              ]
            },
            tl: /* [] */0
          };
  }
  if (match.hd === "c") {
    var match$1 = match.tl;
    if (match$1 && !match$1.tl) {
      return {
              hd: {
                NAME: "property",
                VAL: [
                  propertyId,
                  match$1.hd
                ]
              },
              tl: /* [] */0
            };
    }
    
  }
  logError("Legacy Property not handled", path, 404);
  return /* [] */0;
}

function handleSettings(path) {
  if (!path) {
    return /* [] */0;
  }
  switch (path.hd) {
    case "destinations" :
        var match = path.tl;
        if (match && !match.tl) {
          return {
                  hd: {
                    NAME: "destination",
                    VAL: match.hd
                  },
                  tl: /* [] */0
                };
        }
        break;
    case "sources" :
        var match$1 = path.tl;
        if (match$1) {
          var match$2 = match$1.tl;
          var sourceId = match$1.hd;
          if (!match$2) {
            return {
                    hd: {
                      NAME: "source",
                      VAL: [
                        sourceId,
                        "overview"
                      ]
                    },
                    tl: /* [] */0
                  };
          }
          var exit = 0;
          switch (match$2.hd) {
            case "codegen-setup" :
            case "functions-setup" :
                exit = 2;
                break;
            case "inspector-setup" :
                if (!match$2.tl) {
                  return {
                          hd: {
                            NAME: "source",
                            VAL: [
                              sourceId,
                              "inspectorSetup"
                            ]
                          },
                          tl: /* [] */0
                        };
                }
                break;
            default:
              
          }
          if (exit === 2 && !match$2.tl) {
            return {
                    hd: {
                      NAME: "source",
                      VAL: [
                        sourceId,
                        "codegenSetup"
                      ]
                    },
                    tl: /* [] */0
                  };
          }
          
        }
        break;
    default:
      logError("Legacy Settings not handled", path, 419);
      return /* [] */0;
  }
  if (path.tl) {
    logError("Legacy Settings not handled", path, 419);
    return /* [] */0;
  } else {
    return /* [] */0;
  }
}

function handleGoals(path) {
  if (!path) {
    return /* [] */0;
  }
  var match = path.tl;
  if (match && match.hd === "metrics") {
    var match$1 = match.tl;
    if (match$1 && !match$1.tl) {
      return {
              hd: {
                NAME: "category",
                VAL: [
                  path.hd,
                  undefined
                ]
              },
              tl: {
                hd: {
                  NAME: "metric",
                  VAL: [
                    match$1.hd,
                    undefined
                  ]
                },
                tl: /* [] */0
              }
            };
    }
    
  }
  logError("Legacy Goals not handled", path, 430);
  return /* [] */0;
}

function handleMetrics(path) {
  if (!path) {
    return /* [] */0;
  }
  var metricId = path.hd;
  var exit = 0;
  if (metricId === "category") {
    var match = path.tl;
    if (match) {
      var match$1 = match.tl;
      var categoryId = match.hd;
      if (!match$1) {
        return {
                hd: {
                  NAME: "category",
                  VAL: [
                    categoryId,
                    undefined
                  ]
                },
                tl: /* [] */0
              };
      }
      if (match$1.hd === "c") {
        var match$2 = match$1.tl;
        if (match$2) {
          if (!match$2.tl) {
            return {
                    hd: {
                      NAME: "category",
                      VAL: [
                        categoryId,
                        match$2.hd
                      ]
                    },
                    tl: /* [] */0
                  };
          }
          exit = 2;
        } else {
          exit = 2;
        }
      } else {
        exit = 2;
      }
    } else {
      exit = 2;
    }
  } else {
    exit = 2;
  }
  if (exit === 2) {
    var match$3 = path.tl;
    if (!match$3) {
      return {
              hd: {
                NAME: "metric",
                VAL: [
                  metricId,
                  undefined
                ]
              },
              tl: /* [] */0
            };
    }
    switch (match$3.hd) {
      case "c" :
          var match$4 = match$3.tl;
          if (match$4 && !match$4.tl) {
            return {
                    hd: {
                      NAME: "metric",
                      VAL: [
                        metricId,
                        match$4.hd
                      ]
                    },
                    tl: /* [] */0
                  };
          }
          break;
      case "category" :
          var match$5 = match$3.tl;
          if (match$5) {
            var match$6 = match$5.tl;
            var categoryId$1 = match$5.hd;
            if (!match$6) {
              return {
                      hd: {
                        NAME: "category",
                        VAL: [
                          categoryId$1,
                          undefined
                        ]
                      },
                      tl: {
                        hd: {
                          NAME: "metric",
                          VAL: [
                            metricId,
                            undefined
                          ]
                        },
                        tl: /* [] */0
                      }
                    };
            }
            if (match$6.hd === "c") {
              var match$7 = match$6.tl;
              if (match$7 && !match$7.tl) {
                return {
                        hd: {
                          NAME: "category",
                          VAL: [
                            categoryId$1,
                            match$7.hd
                          ]
                        },
                        tl: {
                          hd: {
                            NAME: "metric",
                            VAL: [
                              metricId,
                              undefined
                            ]
                          },
                          tl: /* [] */0
                        }
                      };
              }
              
            }
            
          }
          break;
      case "e" :
          var match$8 = match$3.tl;
          if (match$8) {
            var match$9 = match$8.tl;
            var eventId = match$8.hd;
            if (!match$9) {
              return {
                      hd: {
                        NAME: "event",
                        VAL: [
                          eventId,
                          undefined,
                          undefined,
                          false
                        ]
                      },
                      tl: {
                        hd: {
                          NAME: "metric",
                          VAL: [
                            metricId,
                            undefined
                          ]
                        },
                        tl: /* [] */0
                      }
                    };
            }
            if (match$9.hd === "f" && !match$9.tl) {
              return {
                      hd: {
                        NAME: "event",
                        VAL: [
                          eventId,
                          undefined,
                          undefined,
                          true
                        ]
                      },
                      tl: {
                        hd: {
                          NAME: "metric",
                          VAL: [
                            metricId,
                            undefined
                          ]
                        },
                        tl: /* [] */0
                      }
                    };
            }
            
          }
          break;
      case "p" :
          var match$10 = match$3.tl;
          if (match$10 && !match$10.tl) {
            return {
                    hd: {
                      NAME: "property",
                      VAL: [
                        match$10.hd,
                        undefined
                      ]
                    },
                    tl: {
                      hd: {
                        NAME: "metric",
                        VAL: [
                          metricId,
                          undefined
                        ]
                      },
                      tl: /* [] */0
                    }
                  };
          }
          break;
      default:
        
    }
  }
  logError("Legacy Metrics not handled", path, 463);
  return /* [] */0;
}

function handleImplement(path) {
  if (!path) {
    return /* [] */0;
  }
  if (path.hd === "sources") {
    var match = path.tl;
    if (match && !match.tl) {
      return {
              hd: {
                NAME: "codegenSource",
                VAL: match.hd
              },
              tl: /* [] */0
            };
    }
    
  }
  logError("Legacy Implements not handled", path, 473);
  return /* [] */0;
}

function handleIntegrations(path) {
  if (!path) {
    return /* [] */0;
  }
  var match = path.tl;
  var integrationId = path.hd;
  if (!match) {
    return {
            hd: {
              NAME: "integration",
              VAL: [
                integrationId,
                undefined
              ]
            },
            tl: /* [] */0
          };
  }
  if (match.hd === "c") {
    var match$1 = match.tl;
    if (match$1 && !match$1.tl) {
      return {
              hd: {
                NAME: "integration",
                VAL: [
                  integrationId,
                  match$1.hd
                ]
              },
              tl: /* [] */0
            };
    }
    
  }
  logError("Legacy Integrations not handled", path, 484);
  return /* [] */0;
}

function handle(path) {
  if (!path) {
    return /* [] */0;
  }
  switch (path.hd) {
    case "events" :
        return handleEvents(path.tl);
    case "goals" :
        return handleGoals(path.tl);
    case "implement" :
        return handleImplement(path.tl);
    case "integrations" :
        return handleIntegrations(path.tl);
    case "metrics" :
        return handleMetrics(path.tl);
    case "properties" :
        var propertiesLegacyPaths = path.tl;
        if (!propertiesLegacyPaths) {
          return handleProperties(propertiesLegacyPaths);
        }
        switch (propertiesLegacyPaths.hd) {
          case "group" :
          case "groups" :
              return handlePropertyGroups(path);
          default:
            return handleProperties(propertiesLegacyPaths);
        }
    case "settings" :
        return handleSettings(path.tl);
    case "welcome" :
        if (path.tl) {
          logError("Legacy route not handled", path, 504);
          return /* [] */0;
        } else {
          return /* [] */0;
        }
    default:
      logError("Legacy route not handled", path, 504);
      return /* [] */0;
  }
}

var Legacy = {
  handleEvents: handleEvents,
  handlePropertyGroups: handlePropertyGroups,
  handleProperties: handleProperties,
  handleSettings: handleSettings,
  handleGoals: handleGoals,
  handleMetrics: handleMetrics,
  handleImplement: handleImplement,
  handleIntegrations: handleIntegrations,
  handle: handle
};

function getItemsReverse(drawerItemsPath) {
  if (!drawerItemsPath) {
    return /* [] */0;
  }
  var maybeDrawerItem = drawerItemsPath.hd;
  var exit = 0;
  switch (maybeDrawerItem) {
    case "e" :
        var match = drawerItemsPath.tl;
        if (match) {
          var match$1 = match.tl;
          if (match$1) {
            var id = match.hd;
            switch (match$1.hd) {
              case "c" :
                  var match$2 = match$1.tl;
                  if (match$2) {
                    var match$3 = match$2.tl;
                    if (match$3 && match$3.hd === "t") {
                      var match$4 = match$3.tl;
                      if (match$4) {
                        return {
                                hd: {
                                  NAME: "event",
                                  VAL: [
                                    id,
                                    match$2.hd,
                                    match$4.hd,
                                    false
                                  ]
                                },
                                tl: getItemsReverse(match$4.tl)
                              };
                      }
                      exit = 2;
                    } else {
                      exit = 2;
                    }
                  } else {
                    exit = 2;
                  }
                  break;
              case "f" :
                  return {
                          hd: {
                            NAME: "event",
                            VAL: [
                              id,
                              undefined,
                              undefined,
                              true
                            ]
                          },
                          tl: getItemsReverse(match$1.tl)
                        };
              case "t" :
                  var match$5 = match$1.tl;
                  if (match$5) {
                    return {
                            hd: {
                              NAME: "event",
                              VAL: [
                                id,
                                undefined,
                                match$5.hd,
                                false
                              ]
                            },
                            tl: getItemsReverse(match$5.tl)
                          };
                  }
                  exit = 2;
                  break;
              default:
                exit = 2;
            }
          } else {
            exit = 2;
          }
        }
        break;
    case "s" :
        var match$6 = drawerItemsPath.tl;
        if (match$6) {
          var match$7 = match$6.tl;
          if (match$7) {
            var sourceId = match$6.hd;
            var exit$1 = 0;
            switch (match$7.hd) {
              case "codegen-setup" :
              case "functions-setup" :
                  exit$1 = 3;
                  break;
              case "inspector-setup" :
                  return {
                          hd: {
                            NAME: "source",
                            VAL: [
                              sourceId,
                              "inspectorSetup"
                            ]
                          },
                          tl: getItemsReverse(match$7.tl)
                        };
              default:
                exit = 2;
            }
            if (exit$1 === 3) {
              return {
                      hd: {
                        NAME: "source",
                        VAL: [
                          sourceId,
                          "codegenSetup"
                        ]
                      },
                      tl: getItemsReverse(match$7.tl)
                    };
            }
            
          } else {
            exit = 2;
          }
        }
        break;
    default:
      exit = 2;
  }
  if (exit === 2) {
    var match$8 = drawerItemsPath.tl;
    if (match$8) {
      var tail = match$8.tl;
      var id$1 = match$8.hd;
      var exit$2 = 0;
      if (tail && tail.hd === "c") {
        var match$9 = tail.tl;
        if (match$9) {
          var tail$1 = match$9.tl;
          var commentId = match$9.hd;
          if (_revMap[maybeDrawerItem] !== undefined) {
            var match$10 = _revMap[maybeDrawerItem];
            if (match$10 !== undefined) {
              if (match$10 === "functionsSource" || match$10 === "codegenSource") {
                return {
                        hd: {
                          NAME: "codegenSource",
                          VAL: id$1
                        },
                        tl: getItemsReverse(tail$1)
                      };
              } else if (match$10 === "event") {
                return {
                        hd: {
                          NAME: "event",
                          VAL: [
                            id$1,
                            commentId,
                            undefined,
                            false
                          ]
                        },
                        tl: getItemsReverse(tail$1)
                      };
              } else if (match$10 === "inspectorIssue") {
                return {
                        hd: {
                          NAME: "inspectorIssue",
                          VAL: id$1
                        },
                        tl: getItemsReverse(tail$1)
                      };
              } else if (match$10 === "metric") {
                return {
                        hd: {
                          NAME: "metric",
                          VAL: [
                            id$1,
                            commentId
                          ]
                        },
                        tl: getItemsReverse(tail$1)
                      };
              } else if (match$10 === "destination") {
                return {
                        hd: {
                          NAME: "destination",
                          VAL: id$1
                        },
                        tl: getItemsReverse(tail$1)
                      };
              } else if (match$10 === "integration") {
                return {
                        hd: {
                          NAME: "integration",
                          VAL: [
                            id$1,
                            commentId
                          ]
                        },
                        tl: getItemsReverse(tail$1)
                      };
              } else if (match$10 === "property") {
                return {
                        hd: {
                          NAME: "property",
                          VAL: [
                            id$1,
                            commentId
                          ]
                        },
                        tl: getItemsReverse(tail$1)
                      };
              } else if (match$10 === "category") {
                return {
                        hd: {
                          NAME: "category",
                          VAL: [
                            id$1,
                            commentId
                          ]
                        },
                        tl: getItemsReverse(tail$1)
                      };
              } else if (match$10 === "source") {
                return {
                        hd: {
                          NAME: "source",
                          VAL: [
                            id$1,
                            "overview"
                          ]
                        },
                        tl: getItemsReverse(tail$1)
                      };
              } else {
                return {
                        hd: {
                          NAME: "propertyGroup",
                          VAL: [
                            id$1,
                            commentId
                          ]
                        },
                        tl: getItemsReverse(tail$1)
                      };
              }
            } else {
              logError("Legacy path should be dealt with in drawerItems.get switch.", drawerItemsPath, 541);
              return handle(drawerItemsPath);
            }
          }
          exit$2 = 3;
        } else {
          exit$2 = 3;
        }
      } else {
        exit$2 = 3;
      }
      if (exit$2 === 3 && _revMap[maybeDrawerItem] !== undefined) {
        var match$11 = _revMap[maybeDrawerItem];
        if (match$11 !== undefined) {
          if (match$11 === "functionsSource" || match$11 === "codegenSource") {
            return {
                    hd: {
                      NAME: "codegenSource",
                      VAL: id$1
                    },
                    tl: getItemsReverse(tail)
                  };
          } else if (match$11 === "event") {
            return {
                    hd: {
                      NAME: "event",
                      VAL: [
                        id$1,
                        undefined,
                        undefined,
                        false
                      ]
                    },
                    tl: getItemsReverse(tail)
                  };
          } else if (match$11 === "inspectorIssue") {
            return {
                    hd: {
                      NAME: "inspectorIssue",
                      VAL: id$1
                    },
                    tl: getItemsReverse(tail)
                  };
          } else if (match$11 === "metric") {
            return {
                    hd: {
                      NAME: "metric",
                      VAL: [
                        id$1,
                        undefined
                      ]
                    },
                    tl: getItemsReverse(tail)
                  };
          } else if (match$11 === "destination") {
            return {
                    hd: {
                      NAME: "destination",
                      VAL: id$1
                    },
                    tl: getItemsReverse(tail)
                  };
          } else if (match$11 === "integration") {
            return {
                    hd: {
                      NAME: "integration",
                      VAL: [
                        id$1,
                        undefined
                      ]
                    },
                    tl: getItemsReverse(tail)
                  };
          } else if (match$11 === "property") {
            return {
                    hd: {
                      NAME: "property",
                      VAL: [
                        id$1,
                        undefined
                      ]
                    },
                    tl: getItemsReverse(tail)
                  };
          } else if (match$11 === "category") {
            return {
                    hd: {
                      NAME: "category",
                      VAL: [
                        id$1,
                        undefined
                      ]
                    },
                    tl: getItemsReverse(tail)
                  };
          } else if (match$11 === "source") {
            return {
                    hd: {
                      NAME: "source",
                      VAL: [
                        id$1,
                        "overview"
                      ]
                    },
                    tl: getItemsReverse(tail)
                  };
          } else {
            return {
                    hd: {
                      NAME: "propertyGroup",
                      VAL: [
                        id$1,
                        undefined
                      ]
                    },
                    tl: getItemsReverse(tail)
                  };
          }
        } else {
          logError("Legacy path should be dealt with in drawerItems.get switch.", drawerItemsPath, 570);
          return /* [] */0;
        }
      }
      
    }
    
  }
  logError("Legacy path should be dealt with in drawerItems.get switch.", drawerItemsPath, 575);
  return /* [] */0;
}

function get$3(_path) {
  while(true) {
    var path = _path;
    var tail;
    var l;
    if (!path) {
      return /* [] */0;
    }
    var maybeDrawerItem = path.hd;
    var exit = 0;
    switch (maybeDrawerItem) {
      case "auditlog" :
      case "billing" :
          _path = path.tl;
          continue ;
      case "diff" :
          var tail$1 = path.tl;
          if (tail$1) {
            switch (tail$1.hd) {
              case "c" :
                  var match = tail$1.tl;
                  if (match) {
                    _path = match.tl;
                    continue ;
                  }
                  _path = tail$1;
                  continue ;
              case "implementation" :
                  var tail$2 = tail$1.tl;
                  if (tail$2) {
                    var tail$3 = tail$2.tl;
                    if (tail$3) {
                      if (tail$3.hd === "c") {
                        var match$1 = tail$3.tl;
                        if (match$1) {
                          _path = match$1.tl;
                          continue ;
                        }
                        _path = tail$3;
                        continue ;
                      }
                      _path = tail$3;
                      continue ;
                    }
                    _path = tail$3;
                    continue ;
                  }
                  _path = tail$2;
                  continue ;
              default:
                _path = tail$1;
                continue ;
            }
          } else {
            _path = tail$1;
            continue ;
          }
      case "events" :
          var tail$4 = path.tl;
          if (tail$4) {
            if (_revMap[tail$4.hd] === undefined) {
              return handle(path);
            }
            _path = tail$4;
            continue ;
          }
          _path = tail$4;
          continue ;
      case "goals" :
          var tail$5 = path.tl;
          if (tail$5) {
            if (_revMap[tail$5.hd] === undefined) {
              return handle(path);
            }
            _path = tail$5;
            continue ;
          }
          _path = tail$5;
          continue ;
      case "implement" :
          var tail$6 = path.tl;
          if (tail$6) {
            if (tail$6.hd === "sources") {
              var match$2 = tail$6.tl;
              if (match$2) {
                if (!match$2.tl) {
                  return handle(path);
                }
                _path = tail$6;
                continue ;
              }
              _path = tail$6;
              continue ;
            }
            _path = tail$6;
            continue ;
          }
          _path = tail$6;
          continue ;
      case "import" :
          var tail$7 = path.tl;
          if (tail$7) {
            switch (tail$7.hd) {
              case "review" :
              case "success" :
                  tail = tail$7.tl;
                  l = path;
                  break;
              default:
                tail = tail$7;
                l = path;
            }
          } else {
            tail = tail$7;
            l = path;
          }
          break;
      case "inspector" :
          var tail$8 = path.tl;
          if (tail$8) {
            switch (tail$8.hd) {
              case "events" :
                  var tail$9 = tail$8.tl;
                  if (tail$9) {
                    if (_revMap[tail$9.hd] === undefined) {
                      return handle(path);
                    }
                    _path = tail$9;
                    continue ;
                  }
                  _path = tail$9;
                  continue ;
              case "issues" :
                  var tail$10 = tail$8.tl;
                  if (tail$10) {
                    switch (tail$10.hd) {
                      case "saved-views" :
                          _path = tail$10.tl;
                          continue ;
                      case "view" :
                          var match$3 = tail$10.tl;
                          if (match$3) {
                            _path = match$3.tl;
                            continue ;
                          }
                          _path = tail$10;
                          continue ;
                      default:
                        _path = tail$10;
                        continue ;
                    }
                  } else {
                    _path = tail$10;
                    continue ;
                  }
              case "sources" :
                  var tail$11 = tail$8.tl;
                  if (tail$11) {
                    if (_revMap[tail$11.hd] === undefined) {
                      logError("Legacy Inspector route not handled", path, 601);
                      return handle(path);
                    }
                    tail = tail$11;
                    l = path;
                  } else {
                    tail = tail$11;
                    l = path;
                  }
                  break;
              default:
                tail = tail$8;
                l = path;
            }
          } else {
            tail = tail$8;
            l = path;
          }
          break;
      case "integrations" :
          var tail$12 = path.tl;
          if (tail$12) {
            if (_revMap[tail$12.hd] === undefined) {
              return handle(path);
            }
            _path = tail$12;
            continue ;
          }
          _path = tail$12;
          continue ;
      case "metrics" :
          var tail$13 = path.tl;
          if (tail$13) {
            if (_revMap[tail$13.hd] === undefined) {
              return handle(path);
            }
            _path = tail$13;
            continue ;
          }
          _path = tail$13;
          continue ;
      case "properties" :
          var tail$14 = path.tl;
          if (tail$14) {
            var id = tail$14.hd;
            var exit$1 = 0;
            var exit$2 = 0;
            switch (id) {
              case "group" :
              case "groups" :
                  exit$2 = 4;
                  break;
              default:
                exit$1 = 3;
            }
            if (exit$2 === 4) {
              var match$4 = tail$14.tl;
              if (match$4) {
                if (_revMap[match$4.hd] === undefined) {
                  return handle(path);
                }
                exit$1 = 3;
              } else {
                exit$1 = 3;
              }
            }
            if (exit$1 === 3) {
              if (_revMap[id] === undefined && id !== "groups" && id !== "group") {
                return handle(path);
              }
              if (id === "groups") {
                _path = tail$14.tl;
                continue ;
              }
              _path = tail$14;
              continue ;
            }
            
          } else {
            _path = tail$14;
            continue ;
          }
          break;
      case "schemas" :
          var match$5 = path.tl;
          if (match$5) {
            var tail$15 = match$5.tl;
            if (tail$15) {
              if (tail$15.hd === "branches") {
                var match$6 = tail$15.tl;
                if (match$6) {
                  _path = match$6.tl;
                  continue ;
                }
                _path = tail$15;
                continue ;
              }
              _path = tail$15;
              continue ;
            }
            _path = tail$15;
            continue ;
          }
          exit = 2;
          break;
      case "settings" :
          var tail$16 = path.tl;
          if (tail$16) {
            switch (tail$16.hd) {
              case "destinations" :
              case "sources" :
                  return handle(path);
              default:
                _path = tail$16;
                continue ;
            }
          } else {
            _path = tail$16;
            continue ;
          }
      default:
        exit = 2;
    }
    if (exit === 2) {
      if (_revMap[maybeDrawerItem] !== undefined) {
        return Belt_List.reverse(getItemsReverse(path));
      } else {
        return handle(path);
      }
    }
    logError("Unexpected route", l, 634);
    _path = tail;
    continue ;
  };
}

var DrawerItems = {
  toUrlFragment: toUrlFragment$4,
  Legacy: Legacy,
  getItemsReverse: getItemsReverse,
  get: get$3
};

function getLevel(route) {
  if (route === "hash") {
    return 5;
  } else if (route === "search") {
    return 4;
  } else if (route === "baseRoute") {
    return 0;
  } else if (route === "schemaRoute") {
    return 2;
  } else if (route === "branch") {
    return 1;
  } else {
    return 3;
  }
}

function routingMeaningfully(decidingLevel, comparedTo) {
  return getLevel(decidingLevel) >= getLevel(comparedTo);
}

var Route = {
  getLevel: getLevel,
  routingMeaningfully: routingMeaningfully
};

exports.logError = logError;
exports.BaseRoute = BaseRoute;
exports.Branch = Branch;
exports.SchemaRoute = SchemaRoute;
exports.DrawerItem = DrawerItem;
exports.DrawerItems = DrawerItems;
exports.Route = Route;
/* No side effect */
