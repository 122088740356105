// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var BeltListExtensions = require("../app/src/BeltListExtensions.bs.js");

function MakeMapModule(C) {
  var get = Belt_MapString.get;
  var toArray = Belt_MapString.valuesToArray;
  var fromArray = function (items) {
    return Belt_MapString.fromArray(Belt_Array.mapU(items, (function (item) {
                      return [
                              Curry._1(C.getId, item),
                              item
                            ];
                    })));
  };
  var fromList = function (items) {
    return fromArray(Belt_List.toArray(items));
  };
  var keys = Belt_MapString.keysToArray;
  var add = function (items, item) {
    return Belt_MapString.set(items, Curry._1(C.getId, item), item);
  };
  var update = function (items, id, f) {
    return Belt_MapString.updateU(items, id, (function (maybeItem) {
                  if (maybeItem !== undefined) {
                    return Caml_option.some(Curry._1(f, Caml_option.valFromOption(maybeItem)));
                  }
                  
                }));
  };
  var updateMany = function (items, ids, f) {
    var idsMap = Belt_MapString.fromArray(Belt_Array.map(ids, (function (id) {
                return [
                        id,
                        1
                      ];
              })));
    return Belt_MapString.mapU(items, (function (item) {
                  if (Belt_MapString.has(idsMap, Curry._1(C.getId, item))) {
                    return Curry._1(f, item);
                  } else {
                    return item;
                  }
                }));
  };
  var remove = Belt_MapString.remove;
  var reduce = function (items, init, f) {
    return Belt_MapString.reduceU(items, init, (function (acc, _key, item) {
                  return Curry._2(f, acc, item);
                }));
  };
  var map = function (items, f) {
    return Belt_MapString.mapU(items, Curry.__1(f));
  };
  var keep = function (items, f) {
    return Belt_MapString.keepU(items, (function (param, item) {
                  return Curry._1(f, item);
                }));
  };
  var keepMap = function (items, f) {
    return Belt_MapString.mapU(Belt_MapString.keepU(Belt_MapString.mapU(items, Curry.__1(f)), (function (param, item) {
                      return Belt_Option.isSome(item);
                    })), Belt_Option.getExn);
  };
  var has = Belt_MapString.has;
  var size = Belt_MapString.size;
  var eq = function (a, b) {
    return Belt_MapString.eqU(a, b, C.eq);
  };
  return {
          get: get,
          toArray: toArray,
          fromArray: fromArray,
          fromList: fromList,
          keys: keys,
          empty: undefined,
          add: add,
          update: update,
          updateMany: updateMany,
          remove: remove,
          reduce: reduce,
          map: map,
          keep: keep,
          keepMap: keepMap,
          has: has,
          size: size,
          eq: eq
        };
}

function MakeOrderedMapModule(C) {
  var getId = C.getId;
  var eq = C.eq;
  var get = Belt_MapString.get;
  var toArray = Belt_MapString.valuesToArray;
  var fromArray = function (items) {
    return Belt_MapString.fromArray(Belt_Array.mapU(items, (function (item) {
                      return [
                              Curry._1(getId, item),
                              item
                            ];
                    })));
  };
  var fromList = function (items) {
    return fromArray(Belt_List.toArray(items));
  };
  var keys = Belt_MapString.keysToArray;
  var add = function (items, item) {
    return Belt_MapString.set(items, Curry._1(getId, item), item);
  };
  var update = function (items, id, f) {
    return Belt_MapString.updateU(items, id, (function (maybeItem) {
                  if (maybeItem !== undefined) {
                    return Caml_option.some(Curry._1(f, Caml_option.valFromOption(maybeItem)));
                  }
                  
                }));
  };
  var updateMany = function (items, ids, f) {
    var idsMap = Belt_MapString.fromArray(Belt_Array.map(ids, (function (id) {
                return [
                        id,
                        1
                      ];
              })));
    return Belt_MapString.mapU(items, (function (item) {
                  if (Belt_MapString.has(idsMap, Curry._1(getId, item))) {
                    return Curry._1(f, item);
                  } else {
                    return item;
                  }
                }));
  };
  var remove = Belt_MapString.remove;
  var reduce = function (items, init, f) {
    return Belt_MapString.reduceU(items, init, (function (acc, _key, item) {
                  return Curry._2(f, acc, item);
                }));
  };
  var map = function (items, f) {
    return Belt_MapString.mapU(items, Curry.__1(f));
  };
  var keep = function (items, f) {
    return Belt_MapString.keepU(items, (function (param, item) {
                  return Curry._1(f, item);
                }));
  };
  var keepMap = function (items, f) {
    return Belt_MapString.mapU(Belt_MapString.keepU(Belt_MapString.mapU(items, Curry.__1(f)), (function (param, item) {
                      return Belt_Option.isSome(item);
                    })), Belt_Option.getExn);
  };
  var has = Belt_MapString.has;
  var size = Belt_MapString.size;
  var eq$1 = function (a, b) {
    return Belt_MapString.eqU(a, b, eq);
  };
  var MapModule = {
    get: get,
    toArray: toArray,
    fromArray: fromArray,
    fromList: fromList,
    keys: keys,
    empty: undefined,
    add: add,
    update: update,
    updateMany: updateMany,
    remove: remove,
    reduce: reduce,
    map: map,
    keep: keep,
    keepMap: keepMap,
    has: has,
    size: size,
    eq: eq$1
  };
  var get$1 = function (items, id) {
    return Belt_MapString.get(items.items, id);
  };
  var toArray$1 = function (items) {
    return Belt_Array.mapU(items.orderedItems, (function (id) {
                  return Belt_Option.getExn(Belt_MapString.get(items.items, id));
                }));
  };
  var fromArray$1 = function (orderedItems) {
    return {
            orderedItems: Belt_Array.mapU(orderedItems, (function (item) {
                    return Curry._1(C.getId, item);
                  })),
            items: fromArray(orderedItems)
          };
  };
  var fromList$1 = function (orderedItems) {
    return {
            orderedItems: Belt_Array.mapU(Belt_List.toArray(orderedItems), (function (item) {
                    return Curry._1(C.getId, item);
                  })),
            items: fromArray(Belt_List.toArray(orderedItems))
          };
  };
  var keys$1 = function (items) {
    return items.orderedItems;
  };
  var empty_orderedItems = [];
  var empty = {
    orderedItems: empty_orderedItems,
    items: undefined
  };
  var prepend = function (items, item) {
    return {
            orderedItems: Belt_Array.concat([Curry._1(C.getId, item)], items.orderedItems),
            items: add(items.items, item)
          };
  };
  var append = function (items, item) {
    return {
            orderedItems: Belt_Array.concat(items.orderedItems, [Curry._1(C.getId, item)]),
            items: add(items.items, item)
          };
  };
  var update$1 = function (items, id, f) {
    return {
            orderedItems: items.orderedItems,
            items: update(items.items, id, f)
          };
  };
  var updateMany$1 = function (items, ids, f) {
    return {
            orderedItems: items.orderedItems,
            items: updateMany(items.items, ids, f)
          };
  };
  var remove$1 = function (items, id) {
    return {
            orderedItems: Belt_Array.keepU(items.orderedItems, (function (itemId) {
                    return itemId !== id;
                  })),
            items: Belt_MapString.remove(items.items, id)
          };
  };
  var reduce$1 = function (items, init, f) {
    return Belt_Array.reduceU(items.orderedItems, init, (function (acc, id) {
                  var item = Belt_MapString.get(items.items, id);
                  if (item !== undefined) {
                    return Curry._2(f, acc, Caml_option.valFromOption(item));
                  } else {
                    return acc;
                  }
                }));
  };
  var mapToArray = function (items, f) {
    return Belt_Array.keepMapU(items.orderedItems, (function (id) {
                  return Belt_Option.map(Belt_MapString.get(items.items, id), f);
                }));
  };
  var mapToMap = function (items, f) {
    return Belt_MapString.mapU(items.items, Curry.__1(f));
  };
  var mapToT = function (items, f) {
    return {
            orderedItems: items.orderedItems,
            items: Belt_MapString.mapU(items.items, Curry.__1(f))
          };
  };
  var keep$1 = function (items, f) {
    var keptItems = Belt_MapString.keepU(items.items, (function (param, item) {
            return Curry._1(f, item);
          }));
    return {
            orderedItems: Belt_Array.keepU(items.orderedItems, (function (id) {
                    return Belt_MapString.has(keptItems, id);
                  })),
            items: keptItems
          };
  };
  var keepMap$1 = function (items, f) {
    return Belt_Array.keepMapU(items.orderedItems, (function (id) {
                  return Belt_Option.flatMap(Belt_MapString.get(items.items, id), f);
                }));
  };
  var has$1 = function (items, id) {
    return Belt_MapString.has(items.items, id);
  };
  var size$1 = function (items) {
    return items.orderedItems.length;
  };
  var reorder = function (items, moveItemId, beforeItemId) {
    var orderedItems = Belt_List.fromArray(items.orderedItems);
    var movedItemId = Belt_List.getByU(orderedItems, (function (itemId) {
            return itemId === moveItemId;
          }));
    var listWithoutItemId = Belt_List.keepU(orderedItems, (function (itemId) {
            return itemId !== moveItemId;
          }));
    var reorderedItems = movedItemId !== undefined ? (
        beforeItemId !== undefined ? Belt_List.toArray(BeltListExtensions.flatMapU(listWithoutItemId, (function (itemId) {
                      if (itemId === beforeItemId) {
                        return {
                                hd: itemId,
                                tl: {
                                  hd: movedItemId,
                                  tl: /* [] */0
                                }
                              };
                      } else {
                        return {
                                hd: itemId,
                                tl: /* [] */0
                              };
                      }
                    }))) : Belt_Array.concat([movedItemId], Belt_List.toArray(listWithoutItemId))
      ) : Belt_List.toArray(orderedItems);
    return {
            orderedItems: reorderedItems,
            items: items.items
          };
  };
  var eq$2 = function (a, b) {
    if (Belt_Array.eq(a.orderedItems, b.orderedItems, (function (prim0, prim1) {
              return prim0 === prim1;
            }))) {
      return Belt_MapString.eqU(a.items, b.items, eq);
    } else {
      return false;
    }
  };
  return {
          MapModule: MapModule,
          get: get$1,
          toArray: toArray$1,
          fromArray: fromArray$1,
          fromList: fromList$1,
          keys: keys$1,
          empty: empty,
          prepend: prepend,
          append: append,
          update: update$1,
          updateMany: updateMany$1,
          remove: remove$1,
          reduce: reduce$1,
          mapToArray: mapToArray,
          mapToMap: mapToMap,
          mapToT: mapToT,
          keep: keep$1,
          keepMap: keepMap$1,
          has: has$1,
          size: size$1,
          reorder: reorder,
          eq: eq$2
        };
}

exports.MakeMapModule = MakeMapModule;
exports.MakeOrderedMapModule = MakeOrderedMapModule;
/* No side effect */
