// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Case = require("../../bs-case/src/case.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");
var FetchImplementationStatusUtils = require("../../shared/utils/FetchImplementationStatusUtils.bs.js");

function toString(t) {
  if (typeof t === "number") {
    switch (t) {
      case /* NotSeen */0 :
          return "NotSeen";
      case /* NotImplementedWithAvo */1 :
          return "NotImplementedWithAvo";
      case /* Unknown */2 :
          return "Unknown";
      
    }
  } else {
    var dateString = t._0.toString();
    return "Seen( " + (dateString + " )");
  }
}

var EventStatus = {
  toString: toString
};

function compare(a, b) {
  return Caml.caml_string_compare(a.VAL[0].id, b.VAL[0].id);
}

var DedupeDiffEventStatus = BeltListExtensions.Dedupe({
      compare: compare
    });

function compare$1(a, b) {
  return Caml.caml_string_compare(a.id, b.id);
}

var DedupeEvent = BeltListExtensions.Dedupe({
      compare: compare$1
    });

function getBranchImplementationStatus(actions, branchModel, masterModel, eventInvocations, sourceInitStatus, branchId) {
  var propertyBundleMap = Belt_MapString.fromArray(Belt_Array.map(Belt_List.toArray(branchModel.propertyBundles), (function (bundle) {
              return [
                      bundle.id,
                      Belt_List.toArray(bundle.properties)
                    ];
            })));
  var propertyEventsMap = Belt_Array.reduce(Belt_List.toArray(branchModel.events), undefined, (function (currentMap, $$event) {
          var eventPropertyMap = Belt_Array.reduce(Belt_List.toArray($$event.properties), currentMap, (function (currentMap, property) {
                  var propertyId;
                  propertyId = property._0.id;
                  return Belt_MapString.updateU(currentMap, propertyId, (function (events) {
                                var eventsArray = Belt_Option.getWithDefault(events, []);
                                eventsArray.push($$event);
                                return eventsArray;
                              }));
                }));
          return Belt_Array.reduce(Belt_List.toArray($$event.propertyBundles), eventPropertyMap, (function (currentMap, bundle) {
                        var bundleProperties = Belt_Option.getWithDefault(Belt_MapString.get(propertyBundleMap, bundle.id), []);
                        return Belt_Array.reduce(bundleProperties, currentMap, (function (currentMap, propertyId) {
                                      return Belt_MapString.updateU(currentMap, propertyId, (function (events) {
                                                    var eventsArray = Belt_Option.getWithDefault(events, []);
                                                    eventsArray.push($$event);
                                                    return eventsArray;
                                                  }));
                                    }));
                      }));
        }));
  return Belt_List.keepMapU(branchModel.sources, (function (source) {
                var supportsInvocations = AvoConfig.supportsInvocations(source);
                var actions$1 = Belt_List.flatten(actions);
                var removedEvents = Belt_List.mapU(BeltListExtensions.dedupeOrdered(Belt_List.keepU(Belt_List.keepMapU(Belt_List.keepMapU(actions$1, (function (x) {
                                        var match = x[0];
                                        if (typeof match !== "object") {
                                          return ;
                                        }
                                        if (match.NAME !== "Event") {
                                          return ;
                                        }
                                        var match$1 = x[1];
                                        var id = match.VAL;
                                        if (typeof match$1 !== "object") {
                                          return ;
                                        }
                                        var variant = match$1.NAME;
                                        if (variant === "ExcludeEventFromSource" || variant === "ExcludeEventFromSourceV2") {
                                          if (match$1.VAL[1] === source.id) {
                                            return id;
                                          } else {
                                            return ;
                                          }
                                        }
                                        if (variant !== "Archive") {
                                          return ;
                                        }
                                        var match$2 = match$1.VAL;
                                        if (typeof match$2 === "object" && match$2.NAME === "Event") {
                                          return id;
                                        }
                                        
                                      })), (function (id) {
                                    return StateUtils.getEventById(id, masterModel);
                                  })), (function ($$event) {
                                return Belt_List.someU($$event.includeSources, (function (includedSource) {
                                              return includedSource.id === source.id;
                                            }));
                              })), (function ($$event) {
                            return $$event.id;
                          })), (function ($$event) {
                        return {
                                NAME: "Removed",
                                VAL: [
                                  $$event,
                                  Belt_Option.mapWithDefaultU(Js_dict.get(sourceInitStatus, source.id), /* NotSeen */0, (function (sourceStatus) {
                                          return Belt_Option.mapWithDefaultU(Belt_MapString.get(sourceStatus, branchId), /* NotSeen */0, (function (lastSeen) {
                                                        return /* Seen */{
                                                                _0: lastSeen
                                                              };
                                                      }));
                                        }))
                                ]
                              };
                      }));
                var updatedPropertyEvents = BeltListExtensions.flatMapU(BeltListExtensions.dedupeString(Belt_List.keepMapU(actions$1, (function (x) {
                                var match = x[0];
                                if (typeof match !== "object") {
                                  return ;
                                }
                                if (match.NAME !== "Property") {
                                  return ;
                                }
                                var match$1 = x[1];
                                var id = match.VAL;
                                if (typeof match$1 === "object" && match$1.NAME === "UpdatePropertyDescription") {
                                  return ;
                                } else {
                                  return id;
                                }
                              }))), (function (propertyId) {
                        return Belt_List.fromArray(Belt_Option.getWithDefault(Belt_MapString.get(propertyEventsMap, propertyId), []));
                      }));
                var updatedPropertyGroupEvents = BeltListExtensions.flatMapU(BeltListExtensions.dedupeString(Belt_List.keepMapU(actions$1, (function (action) {
                                var match = action[0];
                                if (typeof match !== "object") {
                                  return ;
                                }
                                if (match.NAME !== "PropertyGroup") {
                                  return ;
                                }
                                var match$1 = action[1];
                                if (typeof match$1 !== "object") {
                                  return ;
                                }
                                var variant = match$1.NAME;
                                if (variant === "UpdatePropertyGroupName" || variant === "UpdatePropertyGroupDescription" || !(variant === "AddPropertyToGroup" || variant === "RemovePropertyFromGroup")) {
                                  return ;
                                } else {
                                  return match$1.VAL[1];
                                }
                              }))), (function (propertyId) {
                        return Belt_List.fromArray(Belt_Option.getWithDefault(Belt_MapString.get(propertyEventsMap, propertyId), []));
                      }));
                var updatedSystemPropertyEventIds = Belt_List.length(BeltListExtensions.dedupeString(Belt_List.keepMapU(Belt_List.keepMapU(Belt_List.keepMapU(actions$1, (function (x) {
                                        var match = x[0];
                                        if (typeof match !== "object") {
                                          return ;
                                        }
                                        if (match.NAME !== "Property") {
                                          return ;
                                        }
                                        var match$1 = x[1];
                                        var id = match.VAL;
                                        if (typeof match$1 === "object" && match$1.NAME === "UpdatePropertyDescription") {
                                          return ;
                                        } else {
                                          return id;
                                        }
                                      })), (function (propertyId) {
                                    return StateUtils.getPropertyByIdWithArchive(propertyId, branchModel);
                                  })), (function (x) {
                                if (x.TAG === /* PropertyRef */0) {
                                  return ;
                                }
                                var match = x._0;
                                if (match.sendAs === /* SystemProperty */0) {
                                  return match.id;
                                }
                                
                              })))) > 0 ? Belt_List.mapU(StateUtils.getSourceEvents(undefined, branchModel, source.id), (function ($$event) {
                          return {
                                  NAME: "Updated",
                                  VAL: [
                                    $$event,
                                    Belt_Option.mapWithDefaultU(Belt_Option.flatMapU(Js_dict.get(sourceInitStatus, source.id), (function (sourceStatus) {
                                                return Belt_MapString.get(sourceStatus, branchId);
                                              })), /* NotSeen */0, (function (lastSeen) {
                                            return /* Seen */{
                                                    _0: lastSeen
                                                  };
                                          }))
                                  ]
                                };
                        })) : /* [] */0;
                var updatedEvents = Belt_List.keepMapU(Belt_List.keepMapU(actions$1, (function (x) {
                            var match = x[0];
                            if (typeof match === "object" && match.NAME === "Event") {
                              var match$1 = x[1];
                              if (typeof match$1 === "object") {
                                var variant = match$1.NAME;
                                if (variant === "AddEventToMetric" || variant === "UpdateEventDescription" || variant === "Archive" || variant === "AddTag") {
                                  return ;
                                }
                                if (variant === "ExcludeEventFromSource" || variant === "ExcludeEventFromSourceV2") {
                                  return ;
                                }
                                if ((variant === "IncludeDestinationInEventSource" || variant === "IncludeEventInSourceV2" || variant === "ToggleIncludeEventInCodegenForSource") && match$1.VAL[1] !== source.id) {
                                  return ;
                                }
                                
                              }
                              
                            }
                            var match$2 = x[0];
                            if (typeof match$2 === "object" && match$2.NAME === "Event") {
                              return match$2.VAL;
                            }
                            
                          })), (function (eventId) {
                        return StateUtils.getEventById(eventId, branchModel);
                      }));
                var updatedEvents$1 = Curry._1(DedupeDiffEventStatus.dedupe, Belt_List.concat(Belt_List.mapU(Curry._1(DedupeEvent.dedupe, Belt_List.keepU(Belt_List.concatMany([
                                          updatedEvents,
                                          updatedPropertyEvents,
                                          updatedPropertyGroupEvents
                                        ]), (function ($$event) {
                                        return Belt_List.someU($$event.includeSources, (function (includedSource) {
                                                      return includedSource.id === source.id;
                                                    }));
                                      }))), (function ($$event) {
                                var eventIncludedInCodegen = Belt_List.someU($$event.includeSources, (function (includedSource) {
                                        if (includedSource.id === source.id) {
                                          return includedSource.includeInCodegen;
                                        } else {
                                          return false;
                                        }
                                      }));
                                var tmp;
                                if (eventIncludedInCodegen) {
                                  if (supportsInvocations) {
                                    if (typeof eventInvocations === "object" && eventInvocations.NAME === "Success") {
                                      var invocations = FetchImplementationStatusUtils.getInvocations($$event.id, source.id, true, undefined, undefined, eventInvocations.VAL);
                                      if (invocations) {
                                        var summary = FetchImplementationStatusUtils.reduceInvocations(invocations);
                                        tmp = Belt_Option.mapWithDefaultU(summary, /* NotSeen */0, (function (summary) {
                                                return /* Seen */{
                                                        _0: new Date(summary.lastSeen.value)
                                                      };
                                              }));
                                      } else {
                                        tmp = /* NotSeen */0;
                                      }
                                    } else {
                                      tmp = /* NotSeen */0;
                                    }
                                  } else {
                                    tmp = /* Unknown */2;
                                  }
                                } else {
                                  tmp = /* NotImplementedWithAvo */1;
                                }
                                return {
                                        NAME: "Updated",
                                        VAL: [
                                          $$event,
                                          tmp
                                        ]
                                      };
                              })), updatedSystemPropertyEventIds));
                var modifiedEvents = Belt_List.concat(updatedEvents$1, removedEvents);
                if (Belt_List.length(modifiedEvents) > 0) {
                  return [
                          source,
                          modifiedEvents
                        ];
                }
                
              }));
}

function getMergeWarnings(onBranchActions, masterModel) {
  return Belt_List.keep(onBranchActions, (function (x) {
                if (!x) {
                  return false;
                }
                var match = x.hd[0];
                if (typeof match !== "object") {
                  return false;
                }
                var variant = match.NAME;
                if (variant === "Source") {
                  var id = match.VAL;
                  if (Belt_Option.isNone(Belt_List.getBy(masterModel.sources, (function (source) {
                                return source.id === id;
                              })))) {
                    return Belt_Option.isNone(Belt_List.getBy(x, (function (x) {
                                      var match = x[1];
                                      if (typeof match !== "object") {
                                        return false;
                                      }
                                      var variant = match.NAME;
                                      if (variant === "Unarchive" || variant === "CreateSource") {
                                        return true;
                                      } else {
                                        return variant === "Archive";
                                      }
                                    })));
                  } else {
                    return false;
                  }
                }
                if (variant === "Destination") {
                  var id$1 = match.VAL;
                  if (Belt_Option.isNone(Belt_List.getBy(masterModel.destinations, (function (destination) {
                                return destination.id === id$1;
                              })))) {
                    return Belt_Option.isNone(Belt_List.getBy(x, (function (x) {
                                      var match = x[1];
                                      if (typeof match !== "object") {
                                        return false;
                                      }
                                      var variant = match.NAME;
                                      if (variant === "Unarchive" || variant === "CreateDestination") {
                                        return true;
                                      } else {
                                        return variant === "Archive";
                                      }
                                    })));
                  } else {
                    return false;
                  }
                }
                if (variant === "Property") {
                  var id$2 = match.VAL;
                  if (Belt_Option.isNone(Belt_List.getBy(masterModel.properties, (function (x) {
                                return x.TAG === /* PropertyRef */0 ? false : x._0.id === id$2;
                              })))) {
                    return Belt_Option.isNone(Belt_List.getBy(x, (function (x) {
                                      var match = x[1];
                                      if (typeof match !== "object") {
                                        return false;
                                      }
                                      var variant = match.NAME;
                                      if (variant === "Unarchive" || variant === "CreateProperty" || variant === "AddSystemProperty" || variant === "Archive") {
                                        return true;
                                      } else {
                                        return variant === "AddProperty";
                                      }
                                    })));
                  } else {
                    return false;
                  }
                }
                if (variant === "Event") {
                  var id$3 = match.VAL;
                  if (Belt_Option.isNone(Belt_List.getBy(masterModel.events, (function ($$event) {
                                return $$event.id === id$3;
                              })))) {
                    return Belt_Option.isNone(Belt_List.getBy(x, (function (x) {
                                      var match = x[1];
                                      if (typeof match !== "object") {
                                        return false;
                                      }
                                      var variant = match.NAME;
                                      if (variant === "Unarchive" || variant === "Archive") {
                                        return true;
                                      } else {
                                        return variant === "AddEvent";
                                      }
                                    })));
                  } else {
                    return false;
                  }
                }
                if (variant === "PropertyGroup") {
                  var id$4 = match.VAL;
                  if (Belt_Option.isNone(Belt_List.getBy(masterModel.propertyBundles, (function (group) {
                                return group.id === id$4;
                              })))) {
                    return Belt_Option.isNone(Belt_List.getBy(x, (function (x) {
                                      var match = x[1];
                                      if (typeof match !== "object") {
                                        return false;
                                      }
                                      var variant = match.NAME;
                                      if (variant === "Unarchive" || variant === "CreatePropertyGroup") {
                                        return true;
                                      } else {
                                        return variant === "Archive";
                                      }
                                    })));
                  } else {
                    return false;
                  }
                }
                if (variant === "Goal") {
                  var id$5 = match.VAL;
                  if (Belt_Option.isNone(Belt_List.getBy(masterModel.goals, (function (goal) {
                                return goal.id === id$5;
                              })))) {
                    return Belt_Option.isNone(Belt_List.getBy(x, (function (x) {
                                      var match = x[1];
                                      if (typeof match !== "object") {
                                        return false;
                                      }
                                      var variant = match.NAME;
                                      if (variant === "Unarchive" || variant === "Archive") {
                                        return true;
                                      } else {
                                        return variant === "AddGoal";
                                      }
                                    })));
                  } else {
                    return false;
                  }
                }
                if (variant !== "Metric") {
                  return false;
                }
                var id$6 = match.VAL;
                if (Belt_Option.isNone(Belt_List.getBy(masterModel.metrics, (function (metric) {
                              return metric.id === id$6;
                            })))) {
                  return Belt_Option.isNone(Belt_List.getBy(x, (function (x) {
                                    var match = x[1];
                                    if (typeof match !== "object") {
                                      return false;
                                    }
                                    var variant = match.NAME;
                                    if (variant === "Unarchive" || variant === "Archive") {
                                      return true;
                                    } else {
                                      return variant === "AddMetric";
                                    }
                                  })));
                } else {
                  return false;
                }
              }));
}

function getMergeConflicts(branchActions, branchModel, masterModel) {
  return Belt_List.keepU(branchActions, (function (action) {
                var match = action[0];
                if (typeof match !== "object") {
                  return false;
                }
                var variant = match.NAME;
                if (variant === "Property") {
                  var match$1 = action[1];
                  if (typeof match$1 !== "object") {
                    return false;
                  }
                  if (match$1.NAME !== "CreateProperty") {
                    return false;
                  }
                  var newProperty = StateUtils.getPropertyById(match$1.VAL[0], branchModel);
                  if (newProperty !== undefined) {
                    return Belt_List.someU(masterModel.properties, (function (property) {
                                  if (property.TAG === /* PropertyRef */0) {
                                    return false;
                                  }
                                  var property$1 = property._0;
                                  if (newProperty.TAG === /* PropertyRef */0) {
                                    return false;
                                  }
                                  var newProperty$1 = newProperty._0;
                                  var isIdentical = Case.to_("Camel", Belt_Option.getWithDefault(newProperty$1.uniqueName, newProperty$1.name)) === Case.to_("Camel", Belt_Option.getWithDefault(property$1.uniqueName, property$1.name));
                                  if (isIdentical) {
                                    return Belt_Option.isNone(Belt_List.getByU(branchActions, (function (a) {
                                                      var match = a[1];
                                                      if (typeof match !== "object") {
                                                        return false;
                                                      }
                                                      if (match.NAME !== "Archive") {
                                                        return false;
                                                      }
                                                      var match$1 = match.VAL;
                                                      if (typeof match$1 === "object" && match$1.NAME === "Property") {
                                                        return match$1.VAL === property$1.id;
                                                      } else {
                                                        return false;
                                                      }
                                                    })));
                                  } else {
                                    return isIdentical;
                                  }
                                }));
                  } else {
                    return false;
                  }
                }
                if (variant === "Event") {
                  var match$2 = action[1];
                  if (typeof match$2 !== "object") {
                    return false;
                  }
                  if (match$2.NAME !== "AddEvent") {
                    return false;
                  }
                  var eventId = match$2.VAL[0];
                  var newEvent = Belt_List.getByU(branchModel.events, (function ($$event) {
                          return $$event.id === eventId;
                        }));
                  if (newEvent !== undefined) {
                    return Belt_List.someU(masterModel.events, (function ($$event) {
                                  var isIdentical = Case.to_("Camel", Belt_Option.getWithDefault(newEvent.uniqueName, newEvent.name)) === Case.to_("Camel", Belt_Option.getWithDefault($$event.uniqueName, $$event.name));
                                  if (isIdentical) {
                                    return Belt_Option.isNone(Belt_List.getByU(branchActions, (function (a) {
                                                      var match = a[1];
                                                      if (typeof match !== "object") {
                                                        return false;
                                                      }
                                                      if (match.NAME !== "Archive") {
                                                        return false;
                                                      }
                                                      var match$1 = match.VAL;
                                                      if (typeof match$1 === "object" && match$1.NAME === "Event") {
                                                        return match$1.VAL === $$event.id;
                                                      } else {
                                                        return false;
                                                      }
                                                    })));
                                  } else {
                                    return isIdentical;
                                  }
                                }));
                  } else {
                    return false;
                  }
                }
                if (variant !== "PropertyGroup") {
                  return false;
                }
                var match$3 = action[1];
                if (typeof match$3 !== "object") {
                  return false;
                }
                if (match$3.NAME !== "CreatePropertyGroup") {
                  return false;
                }
                var bundleName = match$3.VAL[1];
                return Belt_List.someU(masterModel.propertyBundles, (function (bundle) {
                              return Case.to_("Camel", bundle.name) === Case.to_("Camel", bundleName);
                            }));
              }));
}

exports.EventStatus = EventStatus;
exports.DedupeDiffEventStatus = DedupeDiffEventStatus;
exports.DedupeEvent = DedupeEvent;
exports.getBranchImplementationStatus = getBranchImplementationStatus;
exports.getMergeWarnings = getMergeWarnings;
exports.getMergeConflicts = getMergeConflicts;
/* DedupeDiffEventStatus Not a pure module */
