// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconDestinations(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 24;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey40;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.stroke(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.fill(color),
                      tl: /* [] */0
                    }
                  }),
              width: String(size),
              fill: "none",
              viewBox: "0 0 24 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M6.5 0.5H23.5V6.5H6.5V0.5Z",
                  fill: "white",
                  strokeLinecap: "round",
                  strokeLinejoin: "round"
                }), React.createElement("path", {
                  d: "M14 3.5H10",
                  strokeLinecap: "round",
                  strokeLinejoin: "round"
                }), React.createElement("path", {
                  d: "M20.5 4C20.7761 4 21 3.77614 21 3.5C21 3.22386 20.7761 3 20.5 3C20.2239 3 20 3.22386 20 3.5C20 3.77614 20.2239 4 20.5 4Z"
                }), React.createElement("path", {
                  d: "M6.5 9.5H23.5V15.5H6.5V9.5Z",
                  fill: "white",
                  strokeLinecap: "round",
                  strokeLinejoin: "round"
                }), React.createElement("path", {
                  d: "M13.6 12H10",
                  strokeLinecap: "round",
                  strokeLinejoin: "round"
                }), React.createElement("path", {
                  d: "M20.5 13C20.7761 13 21 12.7761 21 12.5C21 12.2239 20.7761 12 20.5 12C20.2239 12 20 12.2239 20 12.5C20 12.7761 20.2239 13 20.5 13Z"
                }), React.createElement("path", {
                  d: "M13.894 10.0612C13.894 10.2112 13.9613 10.3533 14.0775 10.4482C14.1936 10.5432 14.3462 10.581 14.4932 10.5513C14.6576 10.518 14.8271 10.5 15 10.5C16.3811 10.5 17.5 11.6189 17.5 13C17.5 14.3811 16.3811 15.5 15 15.5H3C1.61894 15.5 0.5 14.3811 0.5 13C0.5 11.7901 1.36141 10.7802 2.50464 10.5502C2.73808 10.5032 2.906 10.2981 2.906 10.06C2.906 10.0307 2.90385 10.006 2.90191 9.98885C2.90132 9.98356 2.90067 9.97846 2.90006 9.97387C2.91414 6.94883 5.37166 4.5 8.4 4.5C11.4285 4.5 13.886 6.94901 13.8999 9.97421C13.8993 9.97931 13.8985 9.9851 13.8979 9.99117C13.896 10.0087 13.894 10.0328 13.894 10.0612Z",
                  fill: "white",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeMiterlimit: "10"
                }));
}

var make = IconDestinations;

exports.make = make;
/* Css Not a pure module */
