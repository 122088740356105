// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Caml = require("rescript/lib/js/caml.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var IconX = require("./IconX.bs.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Styles = require("./styles.bs.js");
var Shortid = require("shortid");
var Belt_List = require("rescript/lib/js/belt_List.js");
var IconErase = require("./IconErase.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var ContextBox = require("./ContextBox.bs.js");
var TextButton = require("./TextButton.bs.js");
var Fuzzaldrin = require("fuzzaldrin");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.marginLeft(Css.px(10)),
          tl: {
            hd: Css.flexWrap("wrap"),
            tl: {
              hd: Css.gap(Css.px(4)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var categoryStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.padding2(Css.px(5), Css.px(10)),
          tl: {
            hd: Css.borderRadius(Styles.Border.radius),
            tl: {
              hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
              tl: {
                hd: Css.color(Styles.Color.grey90),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var categoryNameStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.grey90),
      tl: {
        hd: Css.hover({
              hd: Css.color(Styles.Color.blueSecondary),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var categoryCloseStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.width(Css.px(16)),
                    tl: {
                      hd: Css.height(Css.px(16)),
                      tl: {
                        hd: Css.padding(Css.px(0)),
                        tl: {
                          hd: Css.borderRadius(Css.pct(100)),
                          tl: {
                            hd: Css.border(Css.px(1), "solid", Styles.Color.grey20),
                            tl: {
                              hd: Css.selector(" svg", {
                                    hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey40),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.borderColor(Styles.Color.grapeError),
                                      tl: {
                                        hd: Css.selector(" svg", {
                                              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grapeError),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: {
                                  hd: Css.marginLeft(Css.px(8)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var addCategoryStyles = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(5), Css.px(10)),
      tl: /* [] */0
    });

var optionsContainerStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.bottom(Css.px(0)),
          tl: {
            hd: Css.transform(Css.translateY(Css.pct(100))),
            tl: {
              hd: Css.borderRadius(Styles.Border.radius),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                  tl: {
                    hd: Css.width(Css.pct(200)),
                    tl: {
                      hd: Css.maxHeight(Css.px(300)),
                      tl: {
                        hd: Css.overflow("auto"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var optionsStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(4)),
      tl: {
        hd: Css.selector(":empty::after", {
              hd: Css.unsafe("content", "'No results...'"),
              tl: {
                hd: Css.display("block"),
                tl: {
                  hd: Css.padding(Css.px(11)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(16)),
                    tl: {
                      hd: Css.color(Styles.Color.grey70),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.regular),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var optionStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.padding2(Css.px(10), Css.px(15)),
              tl: {
                hd: Css.width(Css.pct(100)),
                tl: {
                  hd: Css.textAlign("left"),
                  tl: {
                    hd: Css.borderRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.hover({
                            hd: Css.backgroundColor(Styles.Color.blueBg),
                            tl: {
                              hd: Css.color(Styles.Color.blue),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var inputContainerStyles = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top("zero"),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("row"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var searchInputStyles = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "add",
            VAL: [
              Css.pct(100),
              Css.px(8)
            ]
          }),
      tl: {
        hd: Css.padding(Css.px(16)),
        tl: {
          hd: Css.paddingLeft(Css.px(18)),
          tl: {
            hd: Css.fontSize(Css.px(14)),
            tl: {
              hd: Css.fontWeight({
                    NAME: "num",
                    VAL: 500
                  }),
              tl: {
                hd: Css.borderWidth(Css.px(0)),
                tl: {
                  hd: Css.outlineStyle("none"),
                  tl: {
                    hd: Css.outlineWidth(Css.px(0)),
                    tl: {
                      hd: Css.color(Styles.Color.grey70),
                      tl: {
                        hd: Css.placeholder({
                              hd: Css.color(Styles.Color.grey50),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function eraseButtonStyles(disabled) {
  return Curry._1(Css.style, {
              hd: Css.height(Css.pct(100)),
              tl: {
                hd: Css.padding(Css.px(2)),
                tl: {
                  hd: Css.marginLeft(Css.px(6)),
                  tl: {
                    hd: Css.marginRight(Css.px(6)),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.borderRadius(Css.px(2)),
                        tl: {
                          hd: Css.background("transparent"),
                          tl: {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: Styles.Duration.$$default
                                }, undefined, undefined, "opacity"),
                            tl: {
                              hd: Css.opacity(disabled ? 0 : 1),
                              tl: {
                                hd: Css.unsafe("appearance", "none"),
                                tl: {
                                  hd: Css.outlineStyle("none"),
                                  tl: {
                                    hd: Css.cursor("pointer"),
                                    tl: {
                                      hd: Css.active({
                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.focus({
                                              hd: Css.outlineStyle("none"),
                                              tl: {
                                                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.blue)),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function CategoryInput$InputBody(Props) {
  var options = Props.options;
  var onAdd = Props.onAdd;
  var close = Props.close;
  var isOpen = Props.isOpen;
  var onCreateActions = Props.onCreateActions;
  var onAddAnalytics = Props.onAddAnalytics;
  var sendActions = Props.sendActions;
  var match = React.useState(function () {
        return "";
      });
  var setSearchString = match[1];
  var searchString = match[0];
  var schemaGroup = SchemaGroupContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var filteredOptions = Fuzzaldrin.filter(Belt_List.toArray(options), searchString, {
        key: "name",
        maxResults: 100
      });
  React.useEffect((function () {
          if (!isOpen) {
            Curry._1(setSearchString, (function (param) {
                    return "";
                  }));
          }
          
        }), [isOpen]);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: optionsContainerStyles
                }, React.createElement("span", {
                      className: inputContainerStyles
                    }, React.createElement("input", {
                          className: searchInputStyles,
                          autoFocus: true,
                          placeholder: "Filter by...",
                          type: "text",
                          value: searchString,
                          onChange: (function (e) {
                              var value = e.target.value;
                              return Curry._1(setSearchString, (function (param) {
                                            return value;
                                          }));
                            })
                        }), React.createElement("button", {
                          className: eraseButtonStyles(searchString === ""),
                          disabled: searchString === "",
                          onClick: (function (param) {
                              return Curry._1(setSearchString, (function (param) {
                                            return "";
                                          }));
                            })
                        }, React.createElement(IconErase.make, {
                              color: Styles.Color.grey40,
                              hoverColor: Styles.Color.grapeError
                            }))), React.createElement("div", {
                      className: optionsStyles
                    }, Belt_Array.map(filteredOptions, (function (category) {
                            return React.createElement("button", {
                                        key: category.id,
                                        className: optionStyles,
                                        onClick: (function (param) {
                                            Curry._1(onAdd, category);
                                            Curry._2(onAddAnalytics, category.id, category.name);
                                            return Curry._1(close, undefined);
                                          })
                                      }, React.createElement($$Text.make, {
                                            size: "Medium",
                                            weight: "Semi",
                                            children: category.name === "" ? "Untitled Category" : category.name
                                          }));
                          })), filteredOptions.length === 0 && searchString !== "" ? React.createElement("button", {
                            className: optionStyles,
                            onClick: (function (param) {
                                var id = Shortid();
                                var context = {
                                  goalId: id,
                                  goalQuery: id
                                };
                                var actions = Belt_Array.concat([
                                      [
                                        {
                                          NAME: "AddGoal",
                                          VAL: id
                                        },
                                        context
                                      ],
                                      [
                                        {
                                          NAME: "UpdateGoalName",
                                          VAL: [
                                            id,
                                            searchString
                                          ]
                                        },
                                        context
                                      ]
                                    ], Belt_Option.mapWithDefault(onCreateActions, [], (function (actions) {
                                            return Curry._1(actions, id);
                                          })));
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          Curry._1(addToast, {
                                                message: "New category created",
                                                toastType: /* Success */0,
                                                action: {
                                                  message: "Open",
                                                  toastCallback: (function (param) {
                                                      return Router.Schema.pushDrawerItem(undefined, {
                                                                  NAME: "category",
                                                                  VAL: [
                                                                    id,
                                                                    undefined
                                                                  ]
                                                                });
                                                    })
                                                }
                                              });
                                          AnalyticsRe.categoryCreated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), id, searchString, NamedBranch.getId(branch), schemaGroup.schemaId);
                                          return Curry._2(onAddAnalytics, id, searchString);
                                        }),
                                      undefined,
                                      actions
                                    ]);
                                return Curry._1(close, undefined);
                              })
                          }, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                lines: 1,
                                children: "+ Add Category \"" + (searchString + "\"")
                              })) : null)));
}

var InputBody = {
  make: CategoryInput$InputBody
};

function CategoryInput(Props) {
  var categories = Props.categories;
  var currentCategories = Props.currentCategories;
  var role = Props.role;
  var onRemove = Props.onRemove;
  var onAdd = Props.onAdd;
  var sendActions = Props.sendActions;
  var onCreateActions = Props.onCreateActions;
  var onAddAnalytics = Props.onAddAnalytics;
  var onRemoveAnalytics = Props.onRemoveAnalytics;
  var options = Belt_List.sort(Belt_List.keep(categories, (function (category) {
              return !Belt_Array.some(currentCategories, (function (param) {
                            return param.id === category.id;
                          }));
            })), (function (a, b) {
          return Caml.caml_string_compare(a.name, b.name);
        }));
  return React.createElement("div", {
              className: rootStyles
            }, currentCategories.length !== 0 ? Belt_Array.map(currentCategories, (function (category) {
                      return React.createElement("div", {
                                  key: category.id,
                                  className: categoryStyles
                                }, React.createElement(Link.make, {
                                      path: Router.Link.addDrawerItem(undefined, {
                                            NAME: "category",
                                            VAL: [
                                              category.id,
                                              undefined
                                            ]
                                          }),
                                      className: categoryNameStyles,
                                      children: React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Semi",
                                            children: category.name === "" ? "Untitled Category" : category.name
                                          })
                                    }), React.createElement("button", {
                                      className: categoryCloseStyles,
                                      onClick: (function (param) {
                                          Curry._1(onRemove, category);
                                          return Curry._1(onRemoveAnalytics, category);
                                        })
                                    }, React.createElement(IconX.make, {
                                          size: 8
                                        })));
                    })) : React.createElement("div", {
                    className: categoryStyles
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.grey50,
                        children: "Uncategorized"
                      })), Models.Role.canEdit(role) ? React.createElement(ContextBox.make, {
                    children: (function (isOpen, param, close, buttonRef) {
                        var tmp = {
                          options: options,
                          onAdd: onAdd,
                          close: close,
                          isOpen: isOpen,
                          onAddAnalytics: onAddAnalytics,
                          sendActions: sendActions
                        };
                        if (onCreateActions !== undefined) {
                          tmp.onCreateActions = Caml_option.valFromOption(onCreateActions);
                        }
                        return [
                                React.createElement(TextButton.make, {
                                      style: "Blue",
                                      size: "Small",
                                      children: React.createElement("span", {
                                            className: addCategoryStyles
                                          }, options === /* [] */0 ? "+ Add to Category" : "+ Add Category"),
                                      ref: buttonRef
                                    }),
                                React.createElement(CategoryInput$InputBody, tmp)
                              ];
                      })
                  }) : null);
}

var make = CategoryInput;

exports.rootStyles = rootStyles;
exports.categoryStyles = categoryStyles;
exports.categoryNameStyles = categoryNameStyles;
exports.categoryCloseStyles = categoryCloseStyles;
exports.addCategoryStyles = addCategoryStyles;
exports.optionsContainerStyles = optionsContainerStyles;
exports.optionsStyles = optionsStyles;
exports.optionStyles = optionStyles;
exports.inputContainerStyles = inputContainerStyles;
exports.searchInputStyles = searchInputStyles;
exports.eraseButtonStyles = eraseButtonStyles;
exports.InputBody = InputBody;
exports.make = make;
/* rootStyles Not a pure module */
