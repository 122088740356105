// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var JsDiff = require("./externals/JsDiff.bs.js");
var Router = require("./Router.bs.js");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var DiffEvent = require("./DiffEvent.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var DiffComments = require("./DiffComments.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");

function DiffPropertyGroup(Props) {
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var goToCommentId = Props.goToCommentId;
  var withComments = Props.withComments;
  var events = Props.events;
  var match = Belt_Option.map(Belt_List.head(actions), (function (prim) {
          return prim[0];
        }));
  if (match === undefined) {
    return null;
  }
  if (typeof match !== "object") {
    return null;
  }
  if (match.NAME !== "PropertyGroup") {
    return null;
  }
  var groupId = match.VAL;
  var actions$1 = Belt_List.map(actions, (function (prim) {
          return prim[1];
        }));
  if (!actions$1) {
    return null;
  }
  var hasCreate = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "CreatePropertyGroup";
          } else {
            return false;
          }
        }));
  var hasArchive = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          if (x.NAME !== "Archive") {
            return false;
          }
          var match = x.VAL;
          if (typeof match === "object") {
            return match.NAME === "PropertyGroup";
          } else {
            return false;
          }
        }));
  var changeType = hasCreate !== undefined ? "Create" : (
      hasArchive !== undefined ? "Archive" : "Update"
    );
  var group = Belt_Option.getWithDefault(Belt_List.getBy(masterModel.propertyBundles, (function (group) {
              return group.id === groupId;
            })), TrackingPlanModel.emptyPropertyBundle(groupId));
  var nextGroup = Belt_Option.getWithDefault(Belt_List.getBy(branchModel.propertyBundles, (function (group) {
              return group.id === groupId;
            })), TrackingPlanModel.emptyPropertyBundle(groupId));
  var maybeAction = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "UpdatePropertyGroupDescription";
          } else {
            return false;
          }
        }));
  var addActions = Belt_List.keepMap(actions$1, (function (x) {
          if (typeof x === "object" && x.NAME === "AddPropertyToGroup") {
            return x;
          }
          
        }));
  var removeActions = Belt_List.keepMap(actions$1, (function (x) {
          if (typeof x === "object" && x.NAME === "RemovePropertyFromGroup") {
            return x;
          }
          
        }));
  var actions$2 = Belt_List.concat(addActions, removeActions);
  var renderProperty = function (property, metricColor) {
    if (property.TAG === /* PropertyRef */0) {
      return null;
    } else {
      return React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontWeight(Styles.FontWeight.semi),
                        tl: {
                          hd: Css.fontSize(Styles.FontSize.small),
                          tl: {
                            hd: Css.padding2(Css.px(10), Css.px(15)),
                            tl: {
                              hd: Css.margin4(Css.px(5), Css.px(20), Css.px(5), Css.px(0)),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", metricColor),
                                  tl: {
                                    hd: Css.color(metricColor),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, property._0.name);
    }
  };
  var tmp;
  if (withComments) {
    var tmp$1 = {
      schemaId: schemaId,
      branchId: branchId,
      threadId: group.id,
      itemType: "PropertyGroup",
      itemName: group.name,
      events: events
    };
    if (goToCommentId !== undefined) {
      tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp = React.createElement(DiffComments.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.alignSelf("flexStart"),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: {
                            hd: Css.maxWidth(DiffEvent.maxWidthPx),
                            tl: {
                              hd: Css.position("sticky"),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.regular),
                                tl: {
                                  hd: Css.top(Css.px(20)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.padding(Css.px(15)),
                                  tl: {
                                    hd: Css.cursor("pointer"),
                                    tl: {
                                      hd: Css.hover({
                                            hd: Css.backgroundColor(Styles.Color.grey20),
                                            tl: /* [] */0
                                          }),
                                      tl: changeType === "Create" ? ({
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.mintGreen),
                                            tl: /* [] */0
                                          }) : (
                                          changeType === "Update" ? ({
                                                hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                tl: /* [] */0
                                              }) : ({
                                                hd: Css.border(Css.px(1), "solid", Styles.Color.grapeError),
                                                tl: /* [] */0
                                              })
                                        )
                                    }
                                  }
                                }
                              }
                            }
                          }),
                      onClick: (function (param) {
                          return Router.Schema.pushDrawerItem(undefined, {
                                      NAME: "propertyGroup",
                                      VAL: [
                                        group.id,
                                        undefined
                                      ]
                                    });
                        })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: /* [] */0
                              })
                        }, JsDiff.renderStringDiff(undefined, undefined, undefined, group.name, nextGroup.name)), maybeAction !== undefined ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(5), Css.px(0)),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.small),
                                    tl: /* [] */0
                                  }
                                })
                          }, JsDiff.renderStringDiff(undefined, undefined, undefined, group.description, nextGroup.description)) : null, Belt_List.length(actions$2) > 0 ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(5), Css.px(0)),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.small),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontSize(Styles.FontSize.regular),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.color(Styles.Color.grey90),
                                          tl: {
                                            hd: Css.marginTop(Css.px(10)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    })
                              }, "Properties"), Belt_List.toArray(Belt_List.map(addActions, (function (x) {
                                      if (typeof x === "object" && x.NAME === "AddPropertyToGroup") {
                                        return Belt_Option.mapWithDefault(StateUtils.getPropertyById(x.VAL[1], branchModel), null, (function (property) {
                                                      return renderProperty(property, Styles.Color.mintGreen);
                                                    }));
                                      } else {
                                        return null;
                                      }
                                    }))), Belt_List.toArray(Belt_List.map(removeActions, (function (x) {
                                      if (typeof x === "object" && x.NAME === "RemovePropertyFromGroup") {
                                        return Belt_Option.mapWithDefault(StateUtils.getPropertyById(x.VAL[1], branchModel), null, (function (property) {
                                                      return renderProperty(property, Styles.Color.grapeError);
                                                    }));
                                      } else {
                                        return null;
                                      }
                                    })))) : null)), tmp);
}

var make = DiffPropertyGroup;

exports.make = make;
/* Css Not a pure module */
