// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Metric = require("./Metric.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function MetricsRow(Props) {
  var metric = Props.metric;
  var maybeOpenMetric = Props.maybeOpenMetric;
  var match = Router.getStaticRoute(undefined).branch;
  var demoId = match !== undefined && typeof match === "object" && match.NAME === "branch" && match.VAL.startsWith("demo-") && metric.name === "Detailed Onboarding funnel (Demo)" ? "new-workspace-demo-metrics-row" : undefined;
  var metricIsOpen = maybeOpenMetric !== undefined && typeof maybeOpenMetric === "object" && maybeOpenMetric.NAME === "metric" ? maybeOpenMetric.VAL[0] === metric.id : false;
  var drawerItem_1 = [
    metric.id,
    undefined
  ];
  var drawerItem = {
    NAME: "metric",
    VAL: drawerItem_1
  };
  var tmp = {
    path: Router.Link.addDrawerItem(undefined, drawerItem),
    className: Curry._1(Css.style, {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.padding2(Css.px(15), Css.px(25)),
              tl: {
                hd: Css.backgroundColor(metricIsOpen ? Styles.Color.grey20 : Styles.Color.white),
                tl: {
                  hd: Css.color(metricIsOpen ? Styles.Color.blueSecondary : Styles.Color.grey90),
                  tl: {
                    hd: Css.hover({
                          hd: Css.backgroundColor(Styles.Color.grey20),
                          tl: {
                            hd: Css.color(Styles.Color.blue),
                            tl: /* [] */0
                          }
                        }),
                    tl: {
                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }),
    children: null
  };
  if (demoId !== undefined) {
    tmp.id = Caml_option.valFromOption(demoId);
  }
  return React.createElement(Link.make, tmp, React.createElement(Metric.FunnelTypeIcon.make, {
                  type_: metric.type_
                }), React.createElement(Spacer.make, {
                  width: 12
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  children: metric.name === "" ? "Untitled Metric" : metric.name
                }));
}

var make = MetricsRow;

exports.make = make;
/* Css Not a pure module */
