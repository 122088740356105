// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var CopyButton = require("../CopyButton.bs.js");

function code(docsCodeBoxStyles, docsCodeStyles, docsCodeLightBlueStyles, docsCodeMintGreenStyles, copyButtonBoxStyles, apiKey, language) {
  if (language === "go") {
    return React.createElement("div", {
                className: docsCodeBoxStyles
              }, React.createElement("pre", {
                    className: docsCodeStyles
                  }, React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, "avoInspector"), ",", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, " err"), " := avoinspector.", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, "NewAvoInspector"), "(", React.createElement("span", {
                        className: docsCodeMintGreenStyles
                      }, "\"" + apiKey + "\""), ",", " AvoInspectorEnv.", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, "Dev"), ",", React.createElement("span", {
                        className: docsCodeMintGreenStyles
                      }, " \"1.0.0\""), ",", React.createElement("span", {
                        className: docsCodeMintGreenStyles
                      }, " \"My App Name\""), ")"), React.createElement("div", {
                    className: copyButtonBoxStyles
                  }, React.createElement(CopyButton.make, {
                        copyString: "avoInspector, err := avoinspector.NewAvoInspector(\"" + apiKey + "\", avoinspector.Dev, \"1.0\", \"my app\")"
                      })));
  } else {
    return null;
  }
}

exports.code = code;
/* react Not a pure module */
