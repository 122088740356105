// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var IconX = require("./IconX.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var App = require("firebase/app");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

function Invite$InvitedBy(Props) {
  var userId = Props.userId;
  var userState = FirebaseFetcherHooks.useUser(userId);
  if (typeof userState === "object") {
    return React.createElement($$Text.make, {
                size: "Small",
                weight: "Medium",
                color: Styles.Color.grey50,
                children: "Invited by " + userState.VAL.email
              });
  } else {
    return null;
  }
}

var InvitedBy = {
  make: Invite$InvitedBy
};

function createInviteDeleteBatch(invite, schemaId) {
  var inviteRef = Firebase.app(undefined).firestore().collection("invites").doc(invite.id);
  var schemaRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId);
  var schemaInviteRef = schemaRef.collection("invites").doc(invite.externalId);
  var batch = Firebase.app(undefined).firestore().batch();
  batch.delete(inviteRef);
  batch.delete(schemaInviteRef);
  return batch;
}

function removeInvite(invite, schemaGroup) {
  var schemaId = invite.schemaId;
  return createInviteDeleteBatch(invite, schemaId).commit().then(function (param) {
              Router.Schema.pushSchemaRoute(undefined, undefined, "dashboard");
              AnalyticsRe.inviteRemoved(schemaGroup, invite.createdBy, schemaGroup.branchId, schemaGroup.schemaId);
              return Promise.resolve(undefined);
            });
}

function acceptInvite(invite, userId, schemaGroup) {
  var schemaId = invite.schemaId;
  var schemaAclRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("acls").doc(userId);
  var newUserSchemaRef = Firebase.app(undefined).firestore().collection("users").doc(userId).collection("schemas").doc(schemaId);
  var batchWithInviteDeletes = createInviteDeleteBatch(invite, schemaId);
  var tmp = {
    id: userId,
    role: invite.role,
    type_: "user",
    createdAt: App.default.firestore.FieldValue.serverTimestamp()
  };
  var tmp$1 = Caml_option.undefined_to_opt(invite.filter);
  if (tmp$1 !== undefined) {
    tmp.filter = Caml_option.valFromOption(tmp$1);
  }
  batchWithInviteDeletes.set(schemaAclRef, tmp);
  batchWithInviteDeletes.set(newUserSchemaRef, {
        id: schemaId,
        lastUsedAt: App.default.firestore.FieldValue.serverTimestamp()
      });
  return batchWithInviteDeletes.commit().then(function (param) {
              Router.push(undefined, Curry._4(Router.Link.get, {
                        NAME: "schema",
                        VAL: schemaId
                      }, "master", "dashboard", /* [] */0));
              AnalyticsRe.inviteAccepted(schemaGroup, userId, invite.createdBy, Belt_Option.getWithDefault(AnalyticsRe.userRoleFromJs(invite.role), "Viewer"), schemaGroup.branchId, schemaGroup.schemaId);
              return Promise.resolve(undefined);
            });
}

var headerClose = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(6)),
        tl: {
          hd: Css.right(Css.px(6)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.justifyContent("center"),
                tl: {
                  hd: Css.width(Css.px(32)),
                  tl: {
                    hd: Css.height(Css.px(32)),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.backgroundColor("transparent"),
                        tl: {
                          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey50),
                          tl: {
                            hd: Css.padding(Css.px(0)),
                            tl: {
                              hd: Css.cursor("pointer"),
                              tl: {
                                hd: Css.borderRadius(Css.px(100)),
                                tl: {
                                  hd: Css.unsafe("appearance", "none"),
                                  tl: {
                                    hd: Css.transition({
                                          NAME: "ms",
                                          VAL: Styles.Duration.$$short
                                        }, undefined, undefined, "all"),
                                    tl: {
                                      hd: Css.focus({
                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.grapeError)),
                                            tl: {
                                              hd: Css.outlineStyle("none"),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.backgroundColor(Styles.Color.grapeErrorBg),
                                              tl: {
                                                hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grapeError),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function Invite$InviteContent(Props) {
  var schema = Props.schema;
  var invite = Props.invite;
  var userId = Props.userId;
  var workspace = WorkspaceContext.use(undefined);
  var schemaGroup = AnalyticsUtils.makeSchemaGroup(workspace, "master", "main");
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.margin(Css.px(0)),
                            tl: {
                              hd: Css.marginBottom(Css.px(10)),
                              tl: {
                                hd: Css.marginRight(Css.px(10)),
                                tl: {
                                  hd: Css.width(Css.px(250)),
                                  tl: {
                                    hd: Css.height(Css.px(150)),
                                    tl: {
                                      hd: Css.padding(Css.px(0)),
                                      tl: {
                                        hd: Css.padding(Css.px(8)),
                                        tl: {
                                          hd: Css.paddingTop(Css.px(32)),
                                          tl: {
                                            hd: Css.borderRadius(Styles.Border.radius),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.white),
                                              tl: {
                                                hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                tl: {
                                                  hd: Css.position("relative"),
                                                  tl: {
                                                    hd: Css.overflow("hidden"),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("button", {
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.buttonReset),
                        tl: {
                          hd: headerClose,
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function (e) {
                      e.preventDefault();
                      e.stopPropagation();
                      if (window.confirm("Are you sure you want to remove this invite?")) {
                        removeInvite(invite, schemaGroup);
                        return ;
                      }
                      
                    })
                }, React.createElement(IconX.make, {})), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  textAlign: "center",
                  lines: 1,
                  color: Styles.Color.grey80,
                  children: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(schema.name), "Untitled")
                }), React.createElement(Invite$InvitedBy, {
                  userId: invite.createdBy
                }), React.createElement(Spacer.make, {
                  height: 18
                }), React.createElement(Button.make, {
                  label: "Join Workspace",
                  onClick: (function (param) {
                      acceptInvite(invite, userId, schemaGroup);
                      
                    }),
                  size: "large"
                }));
}

var InviteContent = {
  make: Invite$InviteContent
};

function Invite(Props) {
  var invite = Props.invite;
  var userId = Props.userId;
  var fb = Firebase.app(undefined);
  var schemaState = FirebaseFetcherHooks.useSchema(fb, invite.schemaId, false);
  if (typeof schemaState === "object" && schemaState.NAME !== "Errored") {
    return React.createElement(Invite$InviteContent, {
                schema: schemaState.VAL,
                invite: invite,
                userId: userId
              });
  } else {
    return null;
  }
}

var make = Invite;

exports.InvitedBy = InvitedBy;
exports.createInviteDeleteBatch = createInviteDeleteBatch;
exports.removeInvite = removeInvite;
exports.acceptInvite = acceptInvite;
exports.headerClose = headerClose;
exports.InviteContent = InviteContent;
exports.make = make;
/* headerClose Not a pure module */
