// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Invite = require("./Invite.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var IconAvo = require("./IconAvo.bs.js");
var DidMount = require("./DidMount.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Intercom = require("./externals/intercom.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var LoadingFullscreen = require("./LoadingFullscreen.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

function NoAccess$NoInvites(Props) {
  var schemaId = Props.schemaId;
  React.useEffect((function () {
          AnalyticsRe.schemaNotFound(false, schemaId);
          
        }), []);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("spaceBetween"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.minHeight(Css.vh(100)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(50)),
                        tl: {
                          hd: Css.flex({
                                NAME: "num",
                                VAL: 1.0
                              }),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("a", {
                      href: "/"
                    }, React.createElement(IconAvo.make, {
                          size: 100,
                          gray: true
                        }))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flex({
                              NAME: "num",
                              VAL: 5.0
                            }),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: {
                              hd: Css.textAlign("center"),
                              tl: {
                                hd: Css.maxWidth(Css.px(250)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.bold),
                            tl: {
                              hd: Css.color(Styles.Color.grey30),
                              tl: {
                                hd: Css.fontSize(Css.px(100)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, "404"), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.flex({
                                  NAME: "num",
                                  VAL: 5.0
                                }),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.medium),
                                tl: {
                                  hd: Css.color(Styles.Color.grey70),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, "We could not find the project you requested 🤔"), React.createElement(Spacer.make, {
                      height: 24
                    }), React.createElement(Button.make, {
                      label: "View My Projects",
                      onClick: (function (param) {
                          return Router.push(undefined, "/");
                        })
                    })), React.createElement("button", {
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.button),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.flex({
                                      NAME: "num",
                                      VAL: 1.0
                                    }),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.color(Styles.Color.grey40),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.medium),
                                      tl: {
                                        hd: Css.padding(Css.px(15)),
                                        tl: {
                                          hd: Css.textDecoration("underline"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function (param) {
                      return Intercom.showNewMessage(undefined);
                    })
                }, "Does something look wrong? Contact us!"));
}

var NoInvites = {
  make: NoAccess$NoInvites
};

function NoAccess(Props) {
  var schemaId = Props.schemaId;
  var userId = Props.userId;
  var auth = Firebase.getAuth(undefined);
  var currentUserEmail = Belt_Option.getExn(Caml_option.nullable_to_opt(auth.currentUser)).email;
  var userSchemaInvitesState = FirebaseFetcherHooks.useUserSchemaInvites(currentUserEmail);
  if (typeof userSchemaInvitesState === "object") {
    return Belt_Option.mapWithDefault(Belt_List.head(Belt_List.keep(userSchemaInvitesState.VAL, (function (invite) {
                          return invite.schemaId === schemaId;
                        }))), React.createElement(NoAccess$NoInvites, {
                    schemaId: schemaId
                  }), (function (invite) {
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.justifyContent("spaceBetween"),
                                        tl: {
                                          hd: Css.flexDirection("column"),
                                          tl: {
                                            hd: Css.minHeight(Css.vh(100)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement(DidMount.make, {
                                  didMount: (function (param) {
                                      return AnalyticsRe.schemaNotFound(true, schemaId);
                                    })
                                }), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.padding(Css.px(50)),
                                        tl: {
                                          hd: Css.flex({
                                                NAME: "num",
                                                VAL: 1.0
                                              }),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement("a", {
                                      href: "/"
                                    }, React.createElement(IconAvo.make, {
                                          size: 100,
                                          gray: true
                                        }))), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.flex({
                                              NAME: "num",
                                              VAL: 1.0
                                            }),
                                        tl: /* [] */0
                                      })
                                }, React.createElement(Invite.make, {
                                      invite: invite,
                                      userId: userId
                                    })), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.flex({
                                              NAME: "num",
                                              VAL: 1.0
                                            }),
                                        tl: /* [] */0
                                      })
                                }));
                }));
  } else if (userSchemaInvitesState === "Initial") {
    return React.createElement(LoadingFullscreen.make, {
                message: "Loading workspace..."
              });
  } else {
    return React.createElement(NoAccess$NoInvites, {
                schemaId: schemaId
              });
  }
}

function NoAccess$NoBranches(Props) {
  var schemaId = Props.schemaId;
  var schemaGroup = SchemaGroupContext.use(undefined);
  React.useEffect((function () {
          AnalyticsRe.branchNotFound(schemaGroup, schemaId);
          
        }), []);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("spaceBetween"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.minHeight(Css.vh(100)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(50)),
                        tl: {
                          hd: Css.flex({
                                NAME: "num",
                                VAL: 1.0
                              }),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("a", {
                      href: "/"
                    }, React.createElement(IconAvo.make, {
                          size: 100,
                          gray: true
                        }))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flex({
                              NAME: "num",
                              VAL: 5.0
                            }),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: {
                              hd: Css.textAlign("center"),
                              tl: {
                                hd: Css.maxWidth(Css.px(250)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.bold),
                            tl: {
                              hd: Css.color(Styles.Color.grey30),
                              tl: {
                                hd: Css.fontSize(Css.px(100)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, "404"), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.flex({
                                  NAME: "num",
                                  VAL: 5.0
                                }),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.medium),
                                tl: {
                                  hd: Css.color(Styles.Color.grey70),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, "We could not find the branch you requested 🤔"), React.createElement(Spacer.make, {
                      height: 24
                    }), React.createElement(Button.make, {
                      label: "View all branches",
                      onClick: (function (param) {
                          return Router.push(undefined, Curry._4(Router.Link.get, {
                                          NAME: "schema",
                                          VAL: schemaId
                                        }, undefined, undefined, /* [] */0));
                        })
                    })), React.createElement("button", {
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.button),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.flex({
                                      NAME: "num",
                                      VAL: 1.0
                                    }),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.color(Styles.Color.grey40),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.medium),
                                      tl: {
                                        hd: Css.padding(Css.px(15)),
                                        tl: {
                                          hd: Css.textDecoration("underline"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function (param) {
                      return Intercom.showNewMessage(undefined);
                    })
                }, "Does something look wrong? Contact us!"));
}

var NoBranches = {
  make: NoAccess$NoBranches
};

var make = NoAccess;

exports.NoInvites = NoInvites;
exports.make = make;
exports.NoBranches = NoBranches;
/* Css Not a pure module */
