// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var EventTypeIcon = require("./EventTypeIcon.bs.js");
var SideEffectUtils = require("./SideEffectUtils.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");

function renderEventTypeLabel(type_) {
  if (type_ === "UpdateGroups") {
    return "Update groups";
  } else if (type_ === "Page") {
    return "Navigate";
  } else {
    return TrackingPlanModel.eventTypeToJs(type_);
  }
}

function EventType(Props) {
  var type_ = Props.type_;
  var change = Props.change;
  try {
    var sideEffect = SideEffectUtils.eventTypeToSideEffect(type_);
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.padding4(Css.px(5), Css.px(10), Css.px(5), Css.px(8)),
                          tl: {
                            hd: Css.margin(Css.px(2)),
                            tl: {
                              hd: Css.backgroundColor(change === "Added" ? Styles.Color.mintGreen : Styles.Color.grapeError),
                              tl: {
                                hd: Css.color(Styles.Color.white),
                                tl: {
                                  hd: Css.borderRadius(Styles.Border.radius),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    })
              }, React.createElement(EventTypeIcon.make, {
                    sideEffect: sideEffect,
                    size: 12,
                    color: Styles.Color.white
                  }), React.createElement(Spacer.make, {
                    width: 5
                  }), React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    children: renderEventTypeLabel(type_)
                  }));
  }
  catch (exn){
    return null;
  }
}

var make = EventType;

exports.renderEventTypeLabel = renderEventTypeLabel;
exports.make = make;
/* Css Not a pure module */
