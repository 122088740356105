// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Debounce = require("lodash/debounce");

function debouncedFunction(timeout) {
  return Debounce((function (cb) {
                return Curry._1(cb, undefined);
              }), timeout, {
              leading: false,
              trailing: true
            });
}

exports.debouncedFunction = debouncedFunction;
/* lodash/debounce Not a pure module */
