// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Sentry = require("./externals/Sentry.bs.js");
var ActionDiff = require("../../shared/ActionDiff.bs.js");

function diff(fromModel, toModel) {
  return React.useMemo((function () {
                return ActionDiff.diff((function (error) {
                              return Sentry.captureExceptionString("Illegal state in ActionDiff", {
                                          errorMessage: error,
                                          origin: "ActionDiffMemo.res"
                                        });
                            }), fromModel, toModel, undefined);
              }), [
              fromModel,
              toModel
            ]);
}

exports.diff = diff;
/* react Not a pure module */
