// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Portal = require("./Portal.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var AlertModal = require("./AlertModal.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var KeyListener = require("./KeyListener.bs.js");
var ConfirmModal = require("./ConfirmModal.bs.js");
var GenericModal = require("./GenericModal.bs.js");
var NewUserModal = require("./NewUserModal.bs.js");
var ProfileModal = require("./ProfileModal.bs.js");
var NewEventModal = require("./NewEventModal.bs.js");
var SettingsModal = require("./SettingsModal.bs.js");
var FramerMotion = require("framer-motion");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var NewBranchModal = require("./NewBranchModal.bs.js");
var ComposableModal = require("./ComposableModal.bs.js");
var AccessTokenModal = require("./AccessTokenModal.bs.js");
var NewPropertyModal = require("./NewPropertyModal.bs.js");
var SourceSetupModal = require("./SourceSetupModal.bs.js");
var StateFilterUtils = require("./StateFilterUtils.bs.js");
var AddExtraSeatModal = require("./AddExtraSeatModal.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var LockedBranchModal = require("./LockedBranchModal.bs.js");
var NewGroupTypeModal = require("./NewGroupTypeModal.bs.js");
var SourceEventsModal = require("./SourceEventsModal.bs.js");
var BillingPromptModal = require("./BillingPromptModal.bs.js");
var GlobalStateContext = require("./GlobalStateContext.bs.js");
var MasterEditingModal = require("./MasterEditingModal.bs.js");
var WebhookSecretModal = require("./WebhookSecretModal.bs.js");
var CreateCategoryModal = require("./CreateCategoryModal.bs.js");
var MergePropertiesModal = require("./MergePropertiesModal.bs.js");
var SettingsDestinations = require("./SettingsDestinations.bs.js");
var NewPropertyGroupModal = require("./NewPropertyGroupModal.bs.js");
var PinPropertyValueModal = require("./PinPropertyValueModal.bs.js");
var CreateIntegrationModal = require("./CreateIntegrationModal.bs.js");
var ServiceAccountSecretModal = require("./ServiceAccountSecretModal.bs.js");
var PinNestedPropertyValueModal = require("./PinNestedPropertyValueModal.bs.js");
var ConnectionsSourceCreateModal = require("./ConnectionsSourceCreateModal.bs.js");
var InspectorIssueAlertsSetupModal = require("./InspectorIssueAlertsSetupModal.bs.js");
var InspectorIssuesSourceBreakdown = require("./InspectorIssuesSourceBreakdown.bs.js");
var EnableDestinationForSourceModal = require("./EnableDestinationForSourceModal.bs.js");
var InspectorVersionValidationModal = require("./InspectorVersionValidationModal.bs.js");

function backdropStyles(isDismissable) {
  return Curry._1(Css.style, {
              hd: Css.position("fixed"),
              tl: {
                hd: Css.top(Css.px(0)),
                tl: {
                  hd: Css.right(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(0)),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.grey70Bg),
                        tl: {
                          hd: Css.cursor(isDismissable ? "pointer" : "default"),
                          tl: {
                            hd: Css.zIndex(Styles.ZIndex.aboveAll),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function containerStyles(alignment) {
  return Curry._1(Css.style, Belt_List.concatMany([
                  {
                    hd: Css.position("fixed"),
                    tl: {
                      hd: Css.top(Css.px(0)),
                      tl: {
                        hd: Css.right(Css.px(0)),
                        tl: {
                          hd: Css.bottom(Css.px(0)),
                          tl: {
                            hd: Css.left(Css.px(0)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  },
                  alignment ? ({
                        hd: Css.paddingTop(Css.vh(10)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: /* [] */0
                          }
                        }
                      }) : ({
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: /* [] */0
                          }
                        }
                      })
                ]));
}

function isDismissableFromOutside(modals) {
  return Belt_Option.mapWithDefault(Belt_List.head(Belt_List.reverse(modals)), true, (function (modal) {
                var match = modal.modal;
                if (typeof match !== "object") {
                  return true;
                }
                var variant = match.NAME;
                return !(variant === "InspectorVersionValidation" || variant === "ConfirmModal" || variant === "NewUser");
              }));
}

function ModalsContainer(Props) {
  var currentBranch = Props.currentBranch;
  var events = Props.events;
  var masterModel = Props.masterModel;
  var model = Props.model;
  var role = Props.role;
  var schema = Props.schema;
  var globalSend = GlobalSendContext.use(undefined);
  var match = GlobalStateContext.use(undefined);
  var modals = match.modals;
  var globalStateFilters = match.filters;
  var currentFilters = AnalyticsUtils.currentFilters(model, globalStateFilters, match.eventSort);
  var filters = Belt_List.fromArray(Belt_Array.keepMapU(globalStateFilters, StateFilterUtils.toModel));
  var onClose = React.useCallback((function (modalId) {
          return function (param) {
            return Curry._1(globalSend, {
                        TAG: /* CloseModal */5,
                        _0: modalId
                      });
          };
        }), [globalSend]);
  var backdropRef = React.useRef(null);
  var containerRef = React.useRef(null);
  return React.createElement(FramerMotion.AnimatePresence, {
              children: React.createElement(Portal.make, {
                    children: Belt_List.toArray(Belt_List.map(Belt_List.reverse(modals), (function (modal) {
                                var match = modal.modal;
                                var match$1 = modal.modal;
                                var tmp;
                                if (typeof match$1 === "object") {
                                  var variant = match$1.NAME;
                                  if (variant === "Profile") {
                                    tmp = React.createElement(ProfileModal.make, {
                                          userId: match$1.VAL
                                        });
                                  } else if (variant === "PinPropertyValue") {
                                    var match$2 = match$1.VAL;
                                    tmp = React.createElement(PinPropertyValueModal.make, {
                                          model: model,
                                          eventId: match$2[0],
                                          propertyId: match$2[1],
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                  } else if (variant === "IssueSourcesBreakdown") {
                                    tmp = React.createElement(ComposableModal.make, {
                                          children: null,
                                          withCloseButton: true,
                                          paddingTop: 32,
                                          width: 700,
                                          onClose: Curry._1(onClose, modal.id)
                                        }, React.createElement(ComposableModal.Title.make, {
                                              children: {
                                                NAME: "component",
                                                VAL: React.createElement($$Text.make, {
                                                      size: "Large",
                                                      weight: "Semi",
                                                      children: "Releases seen in the past 24 hours"
                                                    })
                                              }
                                            }), React.createElement(Spacer.make, {
                                              height: 16
                                            }), React.createElement(ComposableModal.Body.make, {
                                              children: {
                                                NAME: "component",
                                                VAL: React.createElement(InspectorIssuesSourceBreakdown.make, {
                                                      report: match$1.VAL
                                                    })
                                              }
                                            }));
                                  } else if (variant === "NewUser") {
                                    tmp = React.createElement(NewUserModal.make, {
                                          masterModel: masterModel,
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                  } else if (variant === "AddExtraSeat") {
                                    var match$3 = match$1.VAL;
                                    tmp = React.createElement(AddExtraSeatModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          submitInvite: match$3.submitInvite,
                                          email: match$3.email,
                                          plan: match$3.plan,
                                          extraAdminEditorSeatCost: match$3.extraAdminEditorSeatCost,
                                          existingAdminEditorExtraSeats: match$3.existingAdminEditorExtraSeats,
                                          extraCommenterSeatCost: match$3.extraCommenterSeatCost,
                                          existingCommenterExtraSeats: match$3.existingCommenterExtraSeats,
                                          role: match$3.role
                                        });
                                  } else if (variant === "MergeProperties") {
                                    tmp = React.createElement(MergePropertiesModal.make, {
                                          model: model,
                                          propertyId: match$1.VAL,
                                          onClose: Curry._1(onClose, modal.id),
                                          currentFilters: currentFilters,
                                          filters: filters
                                        });
                                  } else if (variant === "SourceEvents") {
                                    tmp = React.createElement(SourceEventsModal.make, {
                                          sourceId: match$1.VAL,
                                          model: model,
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                  } else if (variant === "Settings") {
                                    var tmp$1 = {
                                      schema: schema,
                                      onClose: Curry._1(onClose, modal.id),
                                      role: role,
                                      model: model,
                                      openBranches: masterModel.openBranches
                                    };
                                    if (match$1.VAL !== undefined) {
                                      tmp$1.openOnTab = Caml_option.valFromOption(match$1.VAL);
                                    }
                                    tmp = React.createElement(SettingsModal.make, tmp$1);
                                  } else if (variant === "AlertModal") {
                                    var match$4 = match$1.VAL;
                                    tmp = React.createElement(AlertModal.make, {
                                          title: match$4[0],
                                          message: match$4[1],
                                          ctaText: match$4[2],
                                          onConfirm: match$4[3],
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                  } else if (variant === "IssueAlertsSetup") {
                                    tmp = React.createElement(InspectorIssueAlertsSetupModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          savedView: match$1.VAL
                                        });
                                  } else if (variant === "ConfirmModal") {
                                    var match$5 = match$1.VAL;
                                    tmp = React.createElement(ConfirmModal.make, {
                                          title: match$5[0],
                                          message: match$5[1],
                                          ctaText: match$5[2],
                                          onConfirm: match$5[3],
                                          onCancel: match$5[4],
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                  } else if (variant === "NewBranch") {
                                    var match$6 = match$1.VAL;
                                    var tmp$2 = {
                                      openBranches: masterModel.openBranches,
                                      intialName: match$6[1]
                                    };
                                    var tmp$3 = match$6[0];
                                    if (tmp$3 !== undefined) {
                                      tmp$2.onCreate = Caml_option.valFromOption(tmp$3);
                                    }
                                    tmp = React.createElement(NewBranchModal.make, tmp$2);
                                  } else if (variant === "NewEvent") {
                                    var match$7 = match$1.VAL;
                                    var tmp$4 = {
                                      currentFilters: currentFilters,
                                      events: events,
                                      model: model,
                                      name: match$7[0],
                                      onClose: Curry._1(onClose, modal.id),
                                      eventOrigin: match$7[2]
                                    };
                                    var tmp$5 = match$7[1];
                                    if (tmp$5 !== undefined) {
                                      tmp$4.onCreate = Caml_option.valFromOption(tmp$5);
                                    }
                                    tmp = React.createElement(NewEventModal.make, tmp$4);
                                  } else if (variant === "SetAccessToken") {
                                    var match$8 = match$1.VAL;
                                    tmp = React.createElement(AccessTokenModal.make, {
                                          model: model,
                                          integrationId: match$8[0],
                                          schemaId: schema.id,
                                          onClose: Curry._1(onClose, modal.id),
                                          onEncryptedToken: match$8[1]
                                        });
                                  } else if (variant === "ComposableModal") {
                                    var match$9 = match$1.VAL;
                                    tmp = React.createElement(ComposableModal.make, {
                                          children: null,
                                          onClose: Curry._1(onClose, modal.id)
                                        }, React.createElement(ComposableModal.Title.make, {
                                              children: match$9[0]
                                            }), React.createElement(ComposableModal.Body.make, {
                                              children: match$9[1]
                                            }), React.createElement(ComposableModal.Buttons.make, {
                                              children: match$9[2]
                                            }));
                                  } else if (variant === "UpdateWebhookSecret") {
                                    var match$10 = match$1.VAL;
                                    tmp = React.createElement(WebhookSecretModal.make, {
                                          schemaId: schema.id,
                                          integrationId: match$10[0],
                                          integrationConfig: match$10[1],
                                          onClose: Curry._1(onClose, modal.id),
                                          onEncryptedToken: match$10[2],
                                          hasSecret: match$10[3],
                                          userIsEditor: role === "Admin" || role === "Editor"
                                        });
                                  } else if (variant === "ServiceAccountSecretCreated") {
                                    tmp = React.createElement(ServiceAccountSecretModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          serviceAccount: match$1.VAL
                                        });
                                  } else if (variant === "LockedBranch") {
                                    tmp = React.createElement(LockedBranchModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          branchStatus: match$1.VAL
                                        });
                                  } else if (variant === "EnableDestinationForSource") {
                                    var match$11 = match$1.VAL;
                                    tmp = React.createElement(EnableDestinationForSourceModal.make, {
                                          sourceId: match$11[0],
                                          destinationId: match$11[1],
                                          model: model,
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                  } else if (variant === "InspectorVersionValidationDetails") {
                                    var match$12 = match$1.VAL;
                                    tmp = React.createElement(InspectorVersionValidationModal.MergedDetails.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          eventIds: match$12[0],
                                          sourceValidation: match$12[1],
                                          branchModel: match$12[2]
                                        });
                                  } else if (variant === "SourceSetup") {
                                    var match$13 = match$1.VAL;
                                    tmp = React.createElement(SourceSetupModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          model: model,
                                          tabAfterChoosing: match$13[0],
                                          fromSchemaRoute: match$13[1]
                                        });
                                  } else if (variant === "MasterEditing") {
                                    var match$14 = match$1.VAL;
                                    tmp = React.createElement(MasterEditingModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          onContinue: match$14[0],
                                          onGoToBranch: match$14[1],
                                          openBranches: masterModel.openBranches,
                                          role: role
                                        });
                                  } else if (variant === "InspectorVersionValidation") {
                                    tmp = React.createElement(InspectorVersionValidationModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          model: model,
                                          changes: match$1.VAL
                                        });
                                  } else if (variant === "CreateSource") {
                                    tmp = React.createElement(ConnectionsSourceCreateModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          tabAfterCreate: match$1.VAL
                                        });
                                  } else if (variant === "NewPropertyGroup") {
                                    var match$15 = match$1.VAL;
                                    tmp = React.createElement(NewPropertyGroupModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          name: match$15[0],
                                          onCreate: match$15[1],
                                          model: model
                                        });
                                  } else if (variant === "GenericModal") {
                                    tmp = React.createElement(GenericModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          children: match$1.VAL
                                        });
                                  } else if (variant === "CreateCategory") {
                                    var match$16 = match$1.VAL;
                                    var tmp$6 = {
                                      categories: model.goals,
                                      onClose: Curry._1(onClose, modal.id)
                                    };
                                    var tmp$7 = match$16[1];
                                    if (tmp$7 !== undefined) {
                                      tmp$6.actionsOnCreate = Caml_option.valFromOption(tmp$7);
                                    }
                                    var tmp$8 = match$16[0];
                                    if (tmp$8 !== undefined) {
                                      tmp$6.categoryName = Caml_option.valFromOption(tmp$8);
                                    }
                                    tmp = React.createElement(CreateCategoryModal.make, tmp$6);
                                  } else if (variant === "BillingPrompt") {
                                    tmp = React.createElement(BillingPromptModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          schemaId: schema.id,
                                          onCloseAll: (function (param) {
                                              return Belt_List.forEach(modals, (function (modal) {
                                                            return Curry._1(globalSend, {
                                                                        TAG: /* CloseModal */5,
                                                                        _0: modal.id
                                                                      });
                                                          }));
                                            }),
                                          paywallName: match$1.VAL,
                                          viewerRole: role
                                        });
                                  } else if (variant === "PinNestedPropertyValue") {
                                    var match$17 = match$1.VAL;
                                    tmp = React.createElement(PinNestedPropertyValueModal.make, {
                                          model: model,
                                          propertyId: match$17[0],
                                          nestedPropertyId: match$17[1],
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                  } else if (variant === "NewProperty") {
                                    var match$18 = match$1.VAL;
                                    var tmp$9 = {
                                      currentFilters: currentFilters,
                                      filters: filters,
                                      model: model,
                                      name: match$18[0],
                                      onClose: Curry._1(onClose, modal.id),
                                      parent: match$18[2],
                                      propertyLocation: match$18[4],
                                      sendAs: match$18[1]
                                    };
                                    var tmp$10 = match$18[3];
                                    if (tmp$10 !== undefined) {
                                      tmp$9.onCreate = Caml_option.valFromOption(tmp$10);
                                    }
                                    tmp = React.createElement(NewPropertyModal.make, tmp$9);
                                  } else if (variant === "AreYouSureModal") {
                                    var match$19 = match$1.VAL;
                                    tmp = React.createElement(ConfirmModal.AreYouSure.make, {
                                          title: match$19[0],
                                          message: match$19[1],
                                          ctaText: match$19[2],
                                          onConfirm: match$19[3],
                                          onCancel: match$19[4],
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                  } else {
                                    var match$20 = match$1.VAL;
                                    var tmp$11 = {
                                      onClose: Curry._1(onClose, modal.id),
                                      currentBranch: currentBranch,
                                      model: model
                                    };
                                    var tmp$12 = match$20[1];
                                    if (tmp$12 !== undefined) {
                                      tmp$11.afterCreateCallback = Caml_option.valFromOption(tmp$12);
                                    }
                                    var tmp$13 = match$20[0];
                                    if (tmp$13 !== undefined) {
                                      tmp$11.groupTypeName = Caml_option.valFromOption(tmp$13);
                                    }
                                    tmp = React.createElement(NewGroupTypeModal.make, tmp$11);
                                  }
                                } else {
                                  tmp = match$1 === "CreateIntegration" ? React.createElement(CreateIntegrationModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          integrations: model.integrations
                                        }) : React.createElement(SettingsDestinations.CreateDestinationModal.make, {
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                }
                                return React.createElement(FramerMotion.motion.div, {
                                            animate: {
                                              opacity: 1
                                            },
                                            transition: {
                                              duration: 0.2
                                            },
                                            initial: {
                                              opacity: 0
                                            },
                                            exit: {
                                              opacity: 0
                                            },
                                            className: backdropStyles(isDismissableFromOutside(modals)),
                                            key: modal.id,
                                            onClick: (function ($$event) {
                                                var targetIsSelf = Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(backdropRef.current), false, (function (ref) {
                                                        return Caml_obj.caml_equal($$event.target, ref);
                                                      })) || Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(containerRef.current), false, (function (ref) {
                                                        return Caml_obj.caml_equal($$event.target, ref);
                                                      }));
                                                if (isDismissableFromOutside(modals) && targetIsSelf) {
                                                  return Curry._2(onClose, modal.id, undefined);
                                                }
                                                
                                              }),
                                            children: null,
                                            ref: backdropRef
                                          }, React.createElement(KeyListener.make, {
                                                keyName: "Escape",
                                                onPress: (function (param) {
                                                    return Belt_Option.forEach(Belt_List.head(Belt_List.reverse(modals)), (function (lastModal) {
                                                                  if (lastModal.id === modal.id && isDismissableFromOutside(modals)) {
                                                                    return Curry._2(onClose, modal.id, undefined);
                                                                  }
                                                                  
                                                                }));
                                                  })
                                              }), React.createElement(FramerMotion.motion.div, {
                                                animate: {
                                                  scale: 1.0
                                                },
                                                transition: {
                                                  duration: 0.2
                                                },
                                                initial: {
                                                  scale: 0.95
                                                },
                                                exit: {
                                                  scale: 0.95
                                                },
                                                className: containerStyles(typeof match === "object" && match.NAME === "Settings" ? /* Top */1 : /* Center */0),
                                                key: modal.id,
                                                children: React.createElement("div", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.cursor("default"),
                                                            tl: /* [] */0
                                                          }),
                                                      onClick: (function (prim) {
                                                          prim.stopPropagation();
                                                          
                                                        })
                                                    }, tmp),
                                                ref: containerRef
                                              }));
                              })))
                  })
            });
}

var make = ModalsContainer;

exports.backdropStyles = backdropStyles;
exports.containerStyles = containerStyles;
exports.isDismissableFromOutside = isDismissableFromOutside;
exports.make = make;
/* Css Not a pure module */
