// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

function Redirect(Props) {
  var path = Props.path;
  var onRedirect = Props.onRedirect;
  React.useEffect((function () {
          Belt_Option.forEach(onRedirect, (function (onRedirect) {
                  return Curry._1(onRedirect, undefined);
                }));
          Router.replace(path);
          
        }), [path]);
  return null;
}

var make = Redirect;

exports.make = make;
/* react Not a pure module */
