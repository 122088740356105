// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hashcode = require("hashcode");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Tinycolor2 = require("tinycolor2");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function toString(color) {
  if (typeof color !== "object") {
    if (color === "transparent") {
      return "transparent";
    } else {
      return "currentColor";
    }
  }
  var variant = color.NAME;
  if (variant === "rgba") {
    var match = color.VAL;
    return "rgba(" + String(match[0]) + ", " + String(match[1]) + ", " + String(match[2]) + ", " + String(match[3].VAL) + ")";
  }
  if (variant === "hex") {
    return "#" + color.VAL;
  }
  if (variant !== "rgb") {
    return Pervasives.failwith("Oops, fix hsl or hsla");
  }
  var match$1 = color.VAL;
  return "rgb(" + String(match$1[0]) + ", " + String(match$1[1]) + ", " + String(match$1[2]) + ")";
}

function setAlpha(color, alpha) {
  var tinyRgba = Tinycolor2(toString(color)).setAlpha(alpha).toRgb();
  return Css.rgba(tinyRgba.r, tinyRgba.g, tinyRgba.b, {
              NAME: "num",
              VAL: tinyRgba.a
            });
}

function lighten(color, steps) {
  var tinyRgba = Tinycolor2(toString(color)).lighten(steps).toRgb();
  return Css.rgba(tinyRgba.r, tinyRgba.g, tinyRgba.b, {
              NAME: "num",
              VAL: tinyRgba.a
            });
}

function darken(color, steps) {
  var tinyRgba = Tinycolor2(toString(color)).darken(steps).toRgb();
  return Css.rgba(tinyRgba.r, tinyRgba.g, tinyRgba.b, {
              NAME: "num",
              VAL: tinyRgba.a
            });
}

var white = Css.hex("FFFFFF");

var grey100 = Css.hex("1B1C1D");

var grey90 = Css.hex("232628");

var grey80 = Css.hex("434A54");

var grey70 = Css.hex("6D7783");

var grey60 = Css.hex("8B95A0");

var grey50 = Css.hex("AAB2BD");

var grey40 = Css.hex("CCD1D9");

var grey30 = Css.hex("E6E9ED");

var grey20 = Css.hex("F5F7FA");

var grey10 = Css.hex("FAFBFD");

var magenta120 = Css.hex("C90077");

var magenta100 = Css.hex("EC008C");

var magenta70 = Css.hex("F87CBC");

var magenta20 = Css.hex("FEEBF2");

var magenta10 = Css.hex("FEF1F6");

var magenta5 = Css.hex("FFFAFC");

var blue120 = Css.hex("2F70C6");

var blue100 = Css.hex("5D9CEC");

var blue70 = Css.hex("8EBAF2");

var blue20 = Css.hex("E7F1FC");

var blue10 = Css.hex("EFF5FD");

var blue5 = Css.hex("F7FAFE");

var purple120 = Css.hex("7B5EC5");

var purple100 = Css.hex("AC92EC");

var purple70 = Css.hex("C1ADF1");

var purple20 = Css.hex("F2EEFC");

var purple10 = Css.hex("F7F4FD");

var purple5 = Css.hex("FBFAFE");

var green120 = Css.hex("2D7C6F");

var green100 = Css.hex("48CFAD");

var green70 = Css.hex("7FDDC5");

var green20 = Css.hex("DCF5EF");

var green10 = Css.hex("EDFAF7");

var green5 = Css.hex("F6FDFB");

var yellow120 = Css.hex("996700");

var yellow100 = Css.hex("FFCE54");

var yellow70 = Css.hex("FFDD87");

var yellow20 = Css.hex("FFF5DE");

var yellow10 = Css.hex("FFFAEE");

var yellow5 = Css.hex("FFFDF7");

var red120 = Css.hex("DA2538");

var red100 = Css.hex("ED5565");

var red70 = Css.hex("F2888A");

var red20 = Css.hex("FDECEC");

var red10 = Css.hex("FEF5F5");

var red5 = Css.hex("FEFAFA");

var orange100 = Css.hex("ff9c54");

var magenta = Css.hex("EC008C");

var lightMagenta = Css.hex("FF1FA5");

var magentaSecondary = Css.hex("D10086");

var blue = Css.hex("5D9CEC");

var blueSecondary = Css.hex("4A89DC");

var purple = Css.hex("AC92EC");

var purpleSecondary = Css.hex("F967ADC");

var mintGreen = Css.hex("48CFAD");

var mintGreenSecondary = Css.hex("37BC9B");

var grassGreen = Css.hex("A0D468");

var grassGreenSecondary = Css.hex("8CC152");

var orange = Css.hex("FFCE54");

var orangeSecondary = Css.hex("F6BB42");

var grapeError = Css.hex("ED5565");

var grapeErrorSecondary = Css.hex("DA4453");

function magentaAlpha(alpha) {
  return setAlpha(magenta, alpha);
}

var magentaBg = setAlpha(magenta, 0.1);

var blueBg = setAlpha(blue, 0.1);

var grassGreenBg = setAlpha(grassGreen, 0.1);

var mintGreenBg = setAlpha(mintGreen, 0.1);

var mintGreenBgSecondary = setAlpha(mintGreen, 0.2);

var grapeErrorBg = setAlpha(grapeError, 0.1);

var orangeBg = setAlpha(orange, 0.1);

var grey70Bg = setAlpha(grey70, 0.2);

var grey40Bg = setAlpha(grey40, 0.2);

var primeColors_1 = {
  hd: blue,
  tl: {
    hd: purple,
    tl: {
      hd: mintGreen,
      tl: {
        hd: grassGreen,
        tl: {
          hd: orange,
          tl: {
            hd: grapeError,
            tl: /* [] */0
          }
        }
      }
    }
  }
};

var primeColors = {
  hd: magenta,
  tl: primeColors_1
};

function getColorFromString(string) {
  return Belt_List.getExn(primeColors, Caml_int32.mod_(Math.abs(Hashcode.hashCode().value(string)), Belt_List.length(primeColors)));
}

var primeColors_1$1 = {
  hd: blue70,
  tl: {
    hd: purple70,
    tl: {
      hd: mintGreenBgSecondary,
      tl: {
        hd: green70,
        tl: {
          hd: yellow70,
          tl: {
            hd: red70,
            tl: /* [] */0
          }
        }
      }
    }
  }
};

var primeColors$1 = {
  hd: magenta70,
  tl: primeColors_1$1
};

function getLightColorFromString(string) {
  return Belt_List.getExn(primeColors$1, Caml_int32.mod_(Math.abs(Hashcode.hashCode().value(string)), Belt_List.length(primeColors$1)));
}

var Color = {
  toString: toString,
  setAlpha: setAlpha,
  lighten: lighten,
  darken: darken,
  white: white,
  grey100: grey100,
  grey90: grey90,
  grey80: grey80,
  grey70: grey70,
  grey60: grey60,
  grey50: grey50,
  grey40: grey40,
  grey30: grey30,
  grey20: grey20,
  grey10: grey10,
  magenta120: magenta120,
  magenta100: magenta100,
  magenta70: magenta70,
  magenta20: magenta20,
  magenta10: magenta10,
  magenta5: magenta5,
  blue120: blue120,
  blue100: blue100,
  blue70: blue70,
  blue20: blue20,
  blue10: blue10,
  blue5: blue5,
  purple120: purple120,
  purple100: purple100,
  purple70: purple70,
  purple20: purple20,
  purple10: purple10,
  purple5: purple5,
  green120: green120,
  green100: green100,
  green70: green70,
  green20: green20,
  green10: green10,
  green5: green5,
  yellow120: yellow120,
  yellow100: yellow100,
  yellow70: yellow70,
  yellow20: yellow20,
  yellow10: yellow10,
  yellow5: yellow5,
  red120: red120,
  red100: red100,
  red70: red70,
  red20: red20,
  red10: red10,
  red5: red5,
  orange100: orange100,
  magenta: magenta,
  lightMagenta: lightMagenta,
  magentaSecondary: magentaSecondary,
  blue: blue,
  blueSecondary: blueSecondary,
  purple: purple,
  purpleSecondary: purpleSecondary,
  mintGreen: mintGreen,
  mintGreenSecondary: mintGreenSecondary,
  grassGreen: grassGreen,
  grassGreenSecondary: grassGreenSecondary,
  orange: orange,
  orangeSecondary: orangeSecondary,
  grapeError: grapeError,
  grapeErrorSecondary: grapeErrorSecondary,
  magentaAlpha: magentaAlpha,
  magentaBg: magentaBg,
  blueBg: blueBg,
  grassGreenBg: grassGreenBg,
  mintGreenBg: mintGreenBg,
  mintGreenBgSecondary: mintGreenBgSecondary,
  grapeErrorBg: grapeErrorBg,
  orangeBg: orangeBg,
  grey70Bg: grey70Bg,
  grey40Bg: grey40Bg,
  getColorFromString: getColorFromString,
  primeColors: primeColors$1,
  getLightColorFromString: getLightColorFromString
};

var semi = {
  NAME: "num",
  VAL: 600
};

var FontWeight_regular = {
  NAME: "num",
  VAL: 400
};

var FontWeight_medium = {
  NAME: "num",
  VAL: 500
};

var FontWeight_bold = {
  NAME: "num",
  VAL: 700
};

var FontWeight = {
  regular: FontWeight_regular,
  medium: FontWeight_medium,
  semi: semi,
  bold: FontWeight_bold
};

var tiny = Css.px(10);

var small = Css.px(12);

var regular = Css.px(14);

var medium = Css.px(16);

var large = Css.px(18);

var huge = Css.px(24);

var FontSize = {
  tiny: tiny,
  small: small,
  regular: regular,
  medium: medium,
  large: large,
  huge: huge
};

var regular$1 = Css.pct(140.0);

var LineHeight = {
  regular: regular$1
};

var radius = Css.px(5);

var $$default = Css.border(Css.px(1), "solid", grey20);

var Border = {
  radius: radius,
  $$default: $$default
};

var box = Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(2), Css.px(10), Css.px(0), undefined, Css.rgba(0, 0, 0, {
          NAME: "num",
          VAL: 0.1
        }));

var standard = Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(4), Css.px(16), Css.px(0), undefined, Css.rgba(0, 0, 0, {
          NAME: "num",
          VAL: 0.1
        }));

var standardHover = Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(8), Css.px(32), Css.px(0), undefined, Css.rgba(0, 0, 0, {
          NAME: "num",
          VAL: 0.16
        }));

var subtle = Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(12), undefined, undefined, Css.rgba(0, 0, 0, {
          NAME: "num",
          VAL: 0.1
        }));

function glow(color) {
  return Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(0), Css.px(16), Css.px(8), undefined, color);
}

var Shadow = {
  box: box,
  standard: standard,
  standardHover: standardHover,
  subtle: subtle,
  glow: glow
};

var ZIndex = {
  aboveAll: 1000,
  eventDetailsFullScreen: 950,
  eventTriggerModal: 980,
  sidebar: 900,
  portalMenu: 1000
};

var Duration = {
  $$long: 500.0,
  $$default: 200.0,
  $$short: 100.0
};

var input_0 = Css.width(Css.pct(100));

var input_1 = {
  hd: Css.outlineWidth(Css.px(0)),
  tl: {
    hd: Css.borderWidth(Css.px(0)),
    tl: {
      hd: Css.margin(Css.px(0)),
      tl: {
        hd: Css.padding(Css.px(0)),
        tl: {
          hd: Css.fontSize(Css.px(14)),
          tl: /* [] */0
        }
      }
    }
  }
};

var input = {
  hd: input_0,
  tl: input_1
};

var button_0 = Css.backgroundColor(Css.transparent);

var button_1 = {
  hd: Css.borderWidth(Css.px(0)),
  tl: {
    hd: Css.cursor("pointer"),
    tl: {
      hd: Css.active({
            hd: Css.outlineColor(blue),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.outlineStyle("none"),
        tl: /* [] */0
      }
    }
  }
};

var button = {
  hd: button_0,
  tl: button_1
};

var buttonReset_0 = Css.unsafe("appearance", "none");

var buttonReset_1 = {
  hd: Css.borderWidth(Css.px(0)),
  tl: {
    hd: Css.borderColor("transparent"),
    tl: {
      hd: Css.borderStyle("solid"),
      tl: {
        hd: Css.padding(Css.px(0)),
        tl: {
          hd: Css.cursor("pointer"),
          tl: {
            hd: Css.backgroundColor("transparent"),
            tl: {
              hd: Css.margin(Css.px(0)),
              tl: /* [] */0
            }
          }
        }
      }
    }
  }
};

var buttonReset = {
  hd: buttonReset_0,
  tl: buttonReset_1
};

var linkReset_0 = Css.textDecoration("none");

var linkReset = {
  hd: linkReset_0,
  tl: /* [] */0
};

var select_0 = Css.display("block");

var select_1 = {
  hd: Css.width(Css.pct(100)),
  tl: {
    hd: Css.borderRadius(Css.px(0)),
    tl: {
      hd: Css.backgroundColor("transparent"),
      tl: {
        hd: Css.padding2(Css.px(10), Css.px(15)),
        tl: {
          hd: Css.borderRadius(radius),
          tl: {
            hd: Css.color(grey80),
            tl: {
              hd: Css.borderColor(grey80),
              tl: {
                hd: Css.fontWeight(semi),
                tl: {
                  hd: Css.fontSize(small),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    }
  }
};

var select = {
  hd: select_0,
  tl: select_1
};

var shimmer_0 = Css.display("block");

var shimmer_1 = {
  hd: Css.backgroundSize({
        NAME: "size",
        VAL: [
          Css.pct(200),
          Css.px(100)
        ]
      }),
  tl: {
    hd: Css.backgroundRepeat("noRepeat"),
    tl: {
      hd: Css.backgroundImage({
            NAME: "linearGradient",
            VAL: [
              {
                NAME: "deg",
                VAL: 90.0
              },
              [
                [
                  Css.pct(0.0),
                  grey20
                ],
                [
                  Css.pct(20.0),
                  grey30
                ],
                [
                  Css.pct(40.0),
                  grey20
                ],
                [
                  Css.pct(100.0),
                  grey20
                ]
              ]
            ]
          }),
      tl: {
        hd: Css.backgroundColor(grey20),
        tl: {
          hd: Css.animation({
                NAME: "ms",
                VAL: 1500.0
              }, undefined, undefined, "linear", "forwards", undefined, "infinite", Curry._1(Css.keyframes, {
                    hd: [
                      0,
                      {
                        hd: Css.backgroundPosition({
                              NAME: "hv",
                              VAL: [
                                Css.px(-468),
                                Css.px(0)
                              ]
                            }),
                        tl: /* [] */0
                      }
                    ],
                    tl: {
                      hd: [
                        100,
                        {
                          hd: Css.backgroundPosition({
                                NAME: "hv",
                                VAL: [
                                  Css.px(468),
                                  Css.px(0)
                                ]
                              }),
                          tl: /* [] */0
                        }
                      ],
                      tl: /* [] */0
                    }
                  })),
          tl: /* [] */0
        }
      }
    }
  }
};

var shimmer = {
  hd: shimmer_0,
  tl: shimmer_1
};

function backgroundColorGuarantee(c) {
  var colorString = toString(c);
  return Css.unsafe("backgroundImage", "linear-gradient(" + colorString + ", " + colorString + "), linear-gradient(white, white)");
}

function backgroundColorWithOverlayGuarantee(color, overlay) {
  var colorString = toString(color);
  var overlayString = toString(overlay);
  return Css.unsafe("backgroundImage", "linear-gradient(" + overlayString + ", " + overlayString + "), linear-gradient(" + colorString + ", " + colorString + "), linear-gradient(white, white)");
}

function maxLines(lines) {
  return {
          hd: Css.unsafe("display", "-webkit-box"),
          tl: {
            hd: Css.unsafe("WebkitLineClamp", "calc(" + String(lines) + ")"),
            tl: {
              hd: Css.unsafe("WebkitBoxOrient", "vertical"),
              tl: {
                hd: Css.overflow("hidden"),
                tl: /* [] */0
              }
            }
          }
        };
}

var emptyStyle = Css.empty(/* [] */0);

var fontFamily = {
  NAME: "custom",
  VAL: "Inter, Helvetica, Arial, sans-serif"
};

var monoFontFamily = {
  NAME: "custom",
  VAL: "'IBM Plex Mono', monospace"
};

exports.Color = Color;
exports.fontFamily = fontFamily;
exports.monoFontFamily = monoFontFamily;
exports.FontWeight = FontWeight;
exports.FontSize = FontSize;
exports.LineHeight = LineHeight;
exports.Border = Border;
exports.Shadow = Shadow;
exports.ZIndex = ZIndex;
exports.Duration = Duration;
exports.input = input;
exports.button = button;
exports.buttonReset = buttonReset;
exports.linkReset = linkReset;
exports.select = select;
exports.shimmer = shimmer;
exports.backgroundColorGuarantee = backgroundColorGuarantee;
exports.backgroundColorWithOverlayGuarantee = backgroundColorWithOverlayGuarantee;
exports.maxLines = maxLines;
exports.emptyStyle = emptyStyle;
/* white Not a pure module */
