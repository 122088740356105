// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Router = require("./Router.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ProfileModal = require("./ProfileModal.bs.js");
var App = require("firebase/app");
var QueryString = require("query-string");
var React = require("@sentry/react");
var Cookie$JustgageReasonCookie = require("@justgage/reason-cookie/src/Cookie.bs.js");

function redirectToWelcome(param) {
  var maybeRedirect = QueryString.parse(Router.getSearch(undefined).replace("?", "")).redirect;
  return Router.replace(Belt_Option.getWithDefault(Belt_Option.map((maybeRedirect == null) ? undefined : Caml_option.some(maybeRedirect), (function (redirect) {
                        if (redirect.startsWith("/welcome")) {
                          return "/welcome" + Belt_Option.mapWithDefault(Belt_Array.get(redirect.split("?"), 1), "", (function (query) {
                                        return "?" + query;
                                      }));
                        } else {
                          return "/welcome?redirect=" + encodeURIComponent(redirect);
                        }
                      })), "/welcome?redirect=" + Router.buildRedirectPath(undefined)));
}

function viaGoogle(auth, emailHint) {
  var provider = new (App.default.auth.GoogleAuthProvider)();
  Belt_List.forEach(Firebase.Auth.googleOauthScopes, (function (scope) {
          provider.addScope(scope);
          
        }));
  provider.setCustomParameters({
        login_hint: emailHint
      });
  return auth.signInWithPopup(provider).then(function (userCredential) {
              redirectToWelcome(undefined);
              var user = userCredential.user;
              var initialReferrer = Cookie$JustgageReasonCookie.getAsString("initialReferrer");
              React.setUser({
                    id: user.uid
                  });
              if (userCredential.additionalUserInfo.isNewUser) {
                var username = user.displayName;
                if (!(username == null)) {
                  ProfileModal.updateName(user.uid, username);
                }
                AnalyticsRe.signedUp(user.uid, user.email, "Google", initialReferrer, Caml_option.nullable_to_opt(user.displayName));
                AnalyticsRe.signedUpIntercom(user.uid, "Google", user.email, Caml_option.nullable_to_opt(user.displayName));
              } else {
                AnalyticsRe.signedIn(user.uid, user.email, "Google");
              }
              return Promise.resolve(undefined);
            });
}

function viaSaml(auth, authProviderId) {
  var provider = new (App.default.auth.SAMLAuthProvider)(authProviderId);
  return auth.signInWithPopup(provider).then(function (userCredential) {
              redirectToWelcome(undefined);
              var user = userCredential.user;
              var email = user.email;
              React.setUser({
                    id: user.uid
                  });
              var initialReferrer = Cookie$JustgageReasonCookie.getAsString("initialReferrer");
              return Promise.resolve((userCredential.additionalUserInfo.isNewUser ? (AnalyticsRe.signedUp(user.uid, email, "Sso", initialReferrer, Caml_option.nullable_to_opt(user.displayName)), AnalyticsRe.signedUpIntercom(user.uid, "Sso", email, Caml_option.nullable_to_opt(user.displayName))) : AnalyticsRe.signedIn(user.uid, user.email, "Sso"), undefined));
            });
}

exports.redirectToWelcome = redirectToWelcome;
exports.viaGoogle = viaGoogle;
exports.viaSaml = viaSaml;
/* Router Not a pure module */
