// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var SideEffectUtils = require("./SideEffectUtils.bs.js");

function toModel(stateFilter) {
  if (typeof stateFilter !== "object") {
    return ;
  }
  var variant = stateFilter.NAME;
  if (variant === "Source") {
    return {
            NAME: "Source",
            VAL: stateFilter.VAL
          };
  } else if (variant === "Destination") {
    return {
            NAME: "Destination",
            VAL: stateFilter.VAL
          };
  } else if (variant === "Tag") {
    return {
            NAME: "Tag",
            VAL: stateFilter.VAL
          };
  } else {
    return ;
  }
}

function getSourceFilters(filters) {
  return Belt_Array.keepMapU(filters, (function (item) {
                if (typeof item === "object" && item.NAME === "Source") {
                  return item.VAL;
                }
                
              }));
}

function getTagFilters(filters) {
  return Belt_Array.keepMapU(filters, (function (item) {
                if (typeof item === "object" && item.NAME === "Tag") {
                  return item.VAL;
                }
                
              }));
}

function getDestinationFilters(filters) {
  return Belt_Array.keepMapU(filters, (function (item) {
                if (typeof item === "object" && item.NAME === "Destination") {
                  return item.VAL;
                }
                
              }));
}

function getPropertyGroupFilters(filters) {
  return Belt_Array.keepMapU(filters, (function (item) {
                if (typeof item === "object" && item.NAME === "PropertyGroup") {
                  return item.VAL;
                }
                
              }));
}

function getPropertyFilters(filters) {
  return Belt_Array.keepMapU(filters, (function (item) {
                if (typeof item === "object" && item.NAME === "Property") {
                  return item.VAL;
                }
                
              }));
}

function getActionFilters(filters) {
  return Belt_Array.keepMapU(filters, (function (item) {
                if (typeof item === "object" && item.NAME === "Action") {
                  return item.VAL;
                }
                
              }));
}

function getCategoryFilters(filters) {
  return Belt_Array.keepMapU(filters, (function (item) {
                if (typeof item === "object" && item.NAME === "Category") {
                  return item.VAL;
                }
                
              }));
}

function filterExcludedEvents(events, model, filters) {
  if (filters.length === 0) {
    return events;
  }
  var sourceFilters = Belt_SetString.fromArray(getSourceFilters(filters));
  var tagFilters = Belt_SetString.fromArray(getTagFilters(filters));
  var destinationFilters = Belt_SetString.fromArray(getDestinationFilters(filters));
  var propertyGroupFilters = Belt_SetString.fromArray(getPropertyGroupFilters(filters));
  var propertyFilters = Belt_SetString.fromArray(getPropertyFilters(filters));
  var actionFilters = Belt_SetString.fromArray(getActionFilters(filters));
  var categoryFilters = getCategoryFilters(filters);
  var modelSources = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(model.sources, (function (source) {
                  return source.id;
                }))));
  var filterBySources = function ($$event) {
    if (Belt_SetString.isEmpty(sourceFilters)) {
      return true;
    } else {
      return Belt_List.someU($$event.includeSources, (function (param) {
                    return Belt_SetString.has(sourceFilters, param.id);
                  }));
    }
  };
  var filterByTags = function ($$event) {
    if (Belt_SetString.isEmpty(tagFilters)) {
      return true;
    } else {
      return Belt_List.some($$event.tags, (function (param) {
                    return Belt_SetString.has(tagFilters, param);
                  }));
    }
  };
  var filterByDestinations = function ($$event) {
    if (Belt_SetString.isEmpty(destinationFilters)) {
      return true;
    } else {
      return Belt_List.some($$event.includeDestinations, (function (includeDestination) {
                    var match = includeDestination.VAL;
                    var sourceId = match[0];
                    if (Belt_List.someU($$event.includeSources, (function (includedSource) {
                              return includedSource.id === sourceId;
                            })) && Belt_SetString.has(modelSources, sourceId)) {
                      return Belt_List.some(match[1], (function (param) {
                                    return Belt_SetString.has(destinationFilters, param);
                                  }));
                    } else {
                      return false;
                    }
                  }));
    }
  };
  var filterByPropertyGroups = function ($$event) {
    if (Belt_SetString.isEmpty(propertyGroupFilters)) {
      return true;
    } else {
      return Belt_List.someU($$event.propertyBundles, (function (propertyGroup) {
                    return Belt_SetString.has(propertyGroupFilters, propertyGroup.id);
                  }));
    }
  };
  var filterByProperties = function ($$event) {
    if (Belt_SetString.isEmpty(propertyFilters)) {
      return true;
    } else {
      return Belt_List.someU($$event.properties, (function (property) {
                    var tmp;
                    tmp = property._0.id;
                    return Belt_SetString.has(propertyFilters, tmp);
                  }));
    }
  };
  var filterByActions = function ($$event) {
    if (Belt_SetString.isEmpty(actionFilters)) {
      return true;
    } else {
      return Belt_List.someU(SideEffectUtils.getEventSideEffects(undefined, $$event, model), (function (action) {
                    return Belt_SetString.has(actionFilters, SideEffectUtils.getSideEffectLabel(action));
                  }));
    }
  };
  return Belt_List.keepU(events, (function ($$event) {
                if (filterBySources($$event) && filterByTags($$event) && filterByDestinations($$event) && filterByPropertyGroups($$event) && filterByProperties($$event) && filterByActions($$event)) {
                  if (categoryFilters.length === 0) {
                    return true;
                  } else {
                    return Belt_SetString.has(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.keepMapU(categoryFilters, (function (goalId) {
                                              return Belt_Option.mapU(StateUtils.getGoalById(goalId, model), (function (param) {
                                                            return Belt_List.toArray(param.events);
                                                          }));
                                            })))), $$event.id);
                  }
                } else {
                  return false;
                }
              }));
}

function getSourceFilterNames(globalStateFilters, model) {
  return Belt_Array.map(Belt_Array.keepMapU(Belt_Array.keepMapU(globalStateFilters, (function (filter) {
                        if (filter.NAME === "Source") {
                          return filter.VAL;
                        }
                        
                      })), (function (sourceId) {
                    return Belt_List.head(Belt_List.keepU(model.sources, (function (source) {
                                      return source.id === sourceId;
                                    })));
                  })), AvoConfig.getSourceName);
}

exports.toModel = toModel;
exports.getSourceFilters = getSourceFilters;
exports.getTagFilters = getTagFilters;
exports.getDestinationFilters = getDestinationFilters;
exports.getPropertyGroupFilters = getPropertyGroupFilters;
exports.getPropertyFilters = getPropertyFilters;
exports.getActionFilters = getActionFilters;
exports.getCategoryFilters = getCategoryFilters;
exports.filterExcludedEvents = filterExcludedEvents;
exports.getSourceFilterNames = getSourceFilterNames;
/* AvoConfig Not a pure module */
