// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Case = require("../../bs-case/src/case.bs.js");
var Case$1 = require("case");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");

function getCommonCase(names) {
  var caseCountMap = Belt_List.reduceU(names, undefined, (function (currentCaseMap, name) {
          var caseKey = Case.tToJs(Case.getCasingWithWorkspaceCase(name, "None"));
          return Belt_MapString.updateU(currentCaseMap, caseKey, (function (count) {
                        return Belt_Option.getWithDefault(count, 0) + 1 | 0;
                      }));
        }));
  return Belt_Option.getWithDefault(Belt_Option.flatMapU(Belt_List.head(Belt_List.sortU(Belt_MapString.toList(caseCountMap), (function (param, param$1) {
                            return Caml.caml_int_compare(param$1[1], param[1]);
                          }))), (function (param) {
                    return Case.tFromJs(param[0]);
                  })), "None");
}

function isCorrectCase(commonCase, name) {
  if (Case.to_(commonCase, name.trim()) === name.trim()) {
    return true;
  } else {
    return Belt_Array.someU(Case.commonStringSeparators, (function (separator) {
                  return Belt_Array.everyU(name.split(separator), (function (substring) {
                                return substring.trim() === Case.to_(commonCase, substring.trim());
                              }));
                }));
  }
}

function getCorrectCase(commonCase, name) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.getByU(Case.commonStringSeparators, (function (separator) {
                        return name.indexOf(separator) > -1;
                      })), (function (separator) {
                    return Belt_Array.joinWith(name.split(separator), separator, (function (subString) {
                                  return Case.to_(commonCase, subString);
                                }));
                  })), Case.to_(commonCase, name));
}

function areConflictingNames(name, existingName) {
  if (name === existingName || name.toLowerCase() === existingName.toLowerCase() || Case.camel(name) === Case.camel(existingName)) {
    return true;
  } else {
    return Case$1.snake(name) === Case$1.snake(existingName);
  }
}

function getConflictingNames(existingNames, name) {
  return Belt_List.getByU(existingNames, (function (existingName) {
                return areConflictingNames(name, existingName);
              }));
}

function replaceNewlinesWithWhitespace(string) {
  return string.replace(/(\r\n|\n|\r)/gm, " ");
}

exports.getCommonCase = getCommonCase;
exports.isCorrectCase = isCorrectCase;
exports.getCorrectCase = getCorrectCase;
exports.areConflictingNames = areConflictingNames;
exports.getConflictingNames = getConflictingNames;
exports.replaceNewlinesWithWhitespace = replaceNewlinesWithWhitespace;
/* Case Not a pure module */
