// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

var baseStyles_0 = Css.fontWeight(Styles.FontWeight.semi);

var baseStyles_1 = {
  hd: Css.lineHeight(Css.pct(140.0)),
  tl: {
    hd: Css.margin("zero"),
    tl: /* [] */0
  }
};

var baseStyles = {
  hd: baseStyles_0,
  tl: baseStyles_1
};

function sizeStyles(size) {
  if (size === "Small") {
    return {
            hd: Css.fontSize(Css.px(24)),
            tl: {
              hd: Css.letterSpacing(Css.em(-0.019)),
              tl: /* [] */0
            }
          };
  } else if (size === "Huge") {
    return {
            hd: Css.fontSize(Css.px(44)),
            tl: {
              hd: Css.letterSpacing(Css.em(-0.022)),
              tl: /* [] */0
            }
          };
  } else if (size === "Medium") {
    return {
            hd: Css.fontSize(Css.px(28)),
            tl: {
              hd: Css.letterSpacing(Css.em(-0.02)),
              tl: /* [] */0
            }
          };
  } else if (size === "Tiny") {
    return {
            hd: Css.fontSize(Css.px(20)),
            tl: {
              hd: Css.letterSpacing(Css.em(-0.017)),
              tl: /* [] */0
            }
          };
  } else {
    return {
            hd: Css.fontSize(Css.px(32)),
            tl: {
              hd: Css.letterSpacing(Css.em(-0.021)),
              tl: /* [] */0
            }
          };
  }
}

function Title(Props) {
  var children = Props.children;
  var customColorOpt = Props.color;
  var elementOpt = Props.element;
  var shrink = Props.shrink;
  var size = Props.size;
  var customColor = customColorOpt !== undefined ? customColorOpt : Styles.Color.grey90;
  var element = elementOpt !== undefined ? elementOpt : "H1";
  var styles = Belt_List.concatMany([
        sizeStyles(size),
        baseStyles,
        {
          hd: Css.color(customColor),
          tl: /* [] */0
        },
        Belt_Option.mapWithDefault(shrink, /* [] */0, (function (shrink) {
                return {
                        hd: Css.flexShrink(shrink),
                        tl: /* [] */0
                      };
              }))
      ]);
  if (element === "H1") {
    return React.createElement("h1", {
                className: Curry._1(Css.style, styles)
              }, children);
  } else if (element === "H2") {
    return React.createElement("h2", {
                className: Curry._1(Css.style, styles)
              }, children);
  } else {
    return React.createElement("p", {
                className: Curry._1(Css.style, styles)
              }, children);
  }
}

var make = Title;

exports.baseStyles = baseStyles;
exports.sizeStyles = sizeStyles;
exports.make = make;
/* baseStyles Not a pure module */
