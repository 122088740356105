// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");

var ParseError = /* @__PURE__ */Caml_exceptions.create("ImportParserTypes.ParseError");

var ParseFormatError = /* @__PURE__ */Caml_exceptions.create("ImportParserTypes.ParseFormatError");

var ParseWarning = /* @__PURE__ */Caml_exceptions.create("ImportParserTypes.ParseWarning");

var getColumnValue = Belt_Array.getExn;

function getOptionalColumnValue(row, columnIndex) {
  return Belt_Option.flatMap(columnIndex, (function (param) {
                return Belt_Array.get(row, param);
              }));
}

var getColumnIndex = Belt_MapString.getExn;

var getOptionalColumnIndex = Belt_MapString.get;

function getColumnPresence(t) {
  if (t.TAG === /* Map */0) {
    return {
            NAME: "requiredColumnNames",
            VAL: [t._0]
          };
  } else {
    return "skip";
  }
}

function parse(row, _rowIndex, columnIndexes, operation) {
  if (operation.TAG === /* Map */0) {
    return Curry._1(operation._1, Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, operation._0)));
  } else {
    return [
            operation._0,
            undefined
          ];
  }
}

var StringTupleOp = {
  getColumnPresence: getColumnPresence,
  parse: parse
};

function getColumnPresence$1(t) {
  switch (t.TAG | 0) {
    case /* ReadFromOptionalColumn */1 :
        return {
                NAME: "optionalColumnName",
                VAL: t._0
              };
    case /* ReadAndConcat */2 :
        return {
                NAME: "requiredColumnNames",
                VAL: t._0
              };
    case /* SkipWithDefault */5 :
        return "skip";
    case /* ReadRequiredWithAnotherColumnConditional */6 :
        return {
                NAME: "requiredColumnNames",
                VAL: [
                  t._0,
                  t._1
                ]
              };
    default:
      return {
              NAME: "requiredColumnNames",
              VAL: [t._0]
            };
  }
}

function parse$1(row, rowIndex, columnIndexes, operation) {
  switch (operation.TAG | 0) {
    case /* Read */0 :
        return Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, operation._0));
    case /* ReadFromOptionalColumn */1 :
        var columnIndex = Belt_MapString.get(columnIndexes, operation._0);
        return Belt_Option.getWithDefault(Belt_Option.flatMap(columnIndex, (function (param) {
                          return Belt_Array.get(row, param);
                        })), "");
    case /* ReadAndConcat */2 :
        return Belt_Array.keepMap(operation._0, (function (columnName) {
                        var value = Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, columnName));
                        if (value.trim() !== "") {
                          return columnName + (": " + (Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, columnName)) + "\n"));
                        }
                        
                      })).join("\n");
    case /* ReadRequired */3 :
        var columnName = operation._0;
        var value = Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, columnName));
        if (value === "") {
          throw {
                RE_EXN_ID: ParseWarning,
                _1: columnName + " is required but is not provided in row " + String(rowIndex),
                Error: new Error()
              };
        }
        return value;
    case /* Map */4 :
        return Curry._1(operation._1, Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, operation._0)));
    case /* SkipWithDefault */5 :
        return operation._0;
    case /* ReadRequiredWithAnotherColumnConditional */6 :
        var columnName$1 = operation._0;
        var conditionalValue = Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, operation._1));
        if (Curry._1(operation._2, conditionalValue)) {
          var value$1 = Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, columnName$1));
          if (value$1 === "") {
            throw {
                  RE_EXN_ID: ParseWarning,
                  _1: columnName$1 + " is required but is not provided in row " + String(rowIndex),
                  Error: new Error()
                };
          }
          return value$1;
        }
        throw {
              RE_EXN_ID: ParseWarning,
              _1: conditionalValue + " is not correct in row " + String(rowIndex),
              Error: new Error()
            };
    
  }
}

var StringOp = {
  getColumnPresence: getColumnPresence$1,
  parse: parse$1
};

function getColumnPresence$2(t) {
  if (t.TAG === /* MapBoolean */0) {
    return {
            NAME: "requiredColumnNames",
            VAL: [t._0]
          };
  } else {
    return "skip";
  }
}

function parse$2(row, _rowIndex, columnIndexes, operation) {
  if (operation.TAG === /* MapBoolean */0) {
    return Curry._1(operation._1, Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, operation._0)));
  } else {
    return operation._0;
  }
}

var BoolOp = {
  getColumnPresence: getColumnPresence$2,
  parse: parse$2
};

function getColumnPresence$3(t) {
  switch (t.TAG | 0) {
    case /* MapStringArray */0 :
        return {
                NAME: "requiredColumnNames",
                VAL: [t._0]
              };
    case /* MapStringArrayFromOptionalColumn */1 :
        return {
                NAME: "optionalColumnName",
                VAL: t._0
              };
    case /* SkipStringArray */2 :
        return "skip";
    
  }
}

function parse$3(row, _rowIndex, columnIndexes, operation) {
  switch (operation.TAG | 0) {
    case /* MapStringArray */0 :
        return Curry._1(operation._1, Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, operation._0)));
    case /* MapStringArrayFromOptionalColumn */1 :
        var columnIndex = Belt_MapString.get(columnIndexes, operation._0);
        return Belt_Option.mapWithDefault(Belt_Option.flatMap(columnIndex, (function (param) {
                          return Belt_Array.get(row, param);
                        })), [], operation._1);
    case /* SkipStringArray */2 :
        return operation._0;
    
  }
}

var StringArrayOp = {
  getColumnPresence: getColumnPresence$3,
  parse: parse$3
};

function getColumnPresence$4(t) {
  if (t.TAG === /* MapSendAs */0) {
    return {
            NAME: "requiredColumnNames",
            VAL: [t._0]
          };
  } else {
    return "skip";
  }
}

function parse$4(row, _rowIndex, columnIndexes, operation) {
  if (operation.TAG === /* MapSendAs */0) {
    return Curry._1(operation._1, Belt_Array.getExn(row, Belt_MapString.getExn(columnIndexes, operation._0)));
  } else {
    return operation._0;
  }
}

var SendAsOp = {
  getColumnPresence: getColumnPresence$4,
  parse: parse$4
};

exports.ParseError = ParseError;
exports.ParseFormatError = ParseFormatError;
exports.ParseWarning = ParseWarning;
exports.getColumnValue = getColumnValue;
exports.getOptionalColumnValue = getOptionalColumnValue;
exports.getColumnIndex = getColumnIndex;
exports.getOptionalColumnIndex = getOptionalColumnIndex;
exports.StringTupleOp = StringTupleOp;
exports.StringOp = StringOp;
exports.BoolOp = BoolOp;
exports.StringArrayOp = StringArrayOp;
exports.SendAsOp = SendAsOp;
/* No side effect */
