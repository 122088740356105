// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Styles = require("./styles.bs.js");

function IconDescription(Props) {
  var sizeOpt = Props.size;
  var fillOpt = Props.fill;
  var secondaryFillOpt = Props.secondaryFill;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var fill = fillOpt !== undefined ? fillOpt : Styles.Color.grey40;
  var secondaryFill = secondaryFillOpt !== undefined ? secondaryFillOpt : Styles.Color.grey40;
  return React.createElement("svg", {
              width: String(size),
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  className: "nc-icon-wrapper",
                  fill: Styles.Color.toString(fill)
                }, React.createElement("line", {
                      fill: "none",
                      stroke: Styles.Color.toString(fill),
                      strokeLinecap: "round",
                      x1: "0.5",
                      x2: "15.5",
                      y1: "3.5",
                      y2: "3.5"
                    }), React.createElement("line", {
                      fill: "none",
                      stroke: Styles.Color.toString(secondaryFill),
                      strokeLinecap: "round",
                      x1: "0.5",
                      x2: "15.5",
                      y1: "8.5",
                      y2: "8.5"
                    }), React.createElement("line", {
                      fill: "none",
                      stroke: Styles.Color.toString(fill),
                      strokeLinecap: "round",
                      x1: "0.5",
                      x2: "8.5",
                      y1: "13.5",
                      y2: "13.5"
                    })));
}

var make = IconDescription;

exports.make = make;
/* react Not a pure module */
