// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var StateUtils = require("./stateUtils.bs.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");

var contentContainerStyle = Curry._1(Css.style, {
      hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: /* [] */0
      }
    });

var allSourcesRowStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding2(Css.px(10), Css.px(20)),
            tl: {
              hd: Css.fontSize(Styles.FontSize.small),
              tl: {
                hd: Css.fontWeight(Styles.FontWeight.semi),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var sourceRowStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding2(Css.px(10), Css.px(20)),
            tl: {
              hd: Css.fontSize(Styles.FontSize.small),
              tl: {
                hd: Css.fontWeight(Styles.FontWeight.regular),
                tl: {
                  hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey20),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function PropertyModalSources(Props) {
  var model = Props.model;
  var property = Props.property;
  var sendActions = Props.sendActions;
  var match = React.useState(function () {
        return StateUtils.propertyExcludedSources(property, model) !== /* [] */0;
      });
  var setIsListExpanded = match[1];
  var isListExpanded = match[0];
  React.useEffect((function () {
          if (StateUtils.propertyExcludedSources(property, model) === /* [] */0) {
            Curry._1(setIsListExpanded, (function (param) {
                    return false;
                  }));
          }
          
        }), [StateUtils.propertyExcludedSources(property, model)]);
  var propertySources = Belt_List.keepMap(Belt_List.reverse(BeltListExtensions.dedupeString(StateUtils.getIncludedSourceIds(BeltListExtensions.flatMapU(StateUtils.eventsSendingProperty(model, undefined, property.id), (function ($$event) {
                          return $$event.includeSources;
                        }))))), (function (sourceId) {
          return StateUtils.getSourceById(sourceId, model);
        }));
  var context = {
    propertyId: property.id,
    propertyQuery: property.id
  };
  var match$1 = StateUtils.propertyExcludedSources(property, model);
  var tmp;
  var exit = 0;
  if (isListExpanded || match$1) {
    exit = 1;
  } else {
    tmp = null;
  }
  if (exit === 1) {
    tmp = Belt_List.toArray(Belt_List.mapU(propertySources, (function (source) {
                return React.createElement("div", {
                            className: sourceRowStyle
                          }, React.createElement("div", undefined, AvoConfig.getSourceName(source)), React.createElement("input", {
                                checked: !Belt_List.someU(match$1, (function (sourceId) {
                                        return sourceId === source.id;
                                      })),
                                type: "checkbox",
                                onChange: (function ($$event) {
                                    var isActive = $$event.target.checked;
                                    return Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "TogglePropertyExcludedSource",
                                                      VAL: [
                                                        property.id,
                                                        source.id,
                                                        isActive
                                                      ]
                                                    },
                                                    context
                                                  ]]
                                              ]);
                                  })
                              }));
              })));
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: contentContainerStyle
                }, React.createElement("div", {
                      className: allSourcesRowStyle
                    }, React.createElement("div", undefined, "Property sent to all sources"), React.createElement("input", {
                          checked: !isListExpanded,
                          type: "checkbox",
                          onChange: (function ($$event) {
                              var isActive = $$event.target.checked;
                              var match = StateUtils.propertyExcludedSources(property, model);
                              if (isActive && match) {
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [[
                                          {
                                            NAME: "ClearPropertyExcludedSources",
                                            VAL: property.id
                                          },
                                          context
                                        ]]
                                    ]);
                              }
                              return Curry._1(setIsListExpanded, (function (param) {
                                            return !isActive;
                                          }));
                            })
                        })), tmp));
}

var make = PropertyModalSources;

exports.contentContainerStyle = contentContainerStyle;
exports.allSourcesRowStyle = allSourcesRowStyle;
exports.sourceRowStyle = sourceRowStyle;
exports.make = make;
/* contentContainerStyle Not a pure module */
