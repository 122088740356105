// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");

var styles = Curry._1(Css.style, {
      hd: Css.textOverflow("ellipsis"),
      tl: {
        hd: Css.whiteSpace("nowrap"),
        tl: {
          hd: Css.overflow("hidden"),
          tl: {
            hd: Css.fontFamily(Styles.monoFontFamily),
            tl: {
              hd: Css.flexShrink(1.0),
              tl: {
                hd: Css.fontWeight(Styles.FontWeight.regular),
                tl: {
                  hd: Css.fontSize(Css.px(11)),
                  tl: {
                    hd: Css.marginTop(Css.px(-3)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function getMatches(validations) {
  return Belt_List.mapU(validations, (function (validation) {
                if (typeof validation !== "object") {
                  return "";
                }
                var variant = validation.NAME;
                if (variant === "Matches") {
                  var allMatches = validation.VAL;
                  if (!allMatches) {
                    return "";
                  }
                  var match = allMatches.hd[0];
                  if (typeof match !== "object") {
                    return "";
                  }
                  if (match.NAME !== "Literal") {
                    return "";
                  }
                  var match$1 = match.VAL;
                  if (typeof match$1 !== "object") {
                    return "";
                  }
                  if (match$1.NAME !== "StringLit") {
                    return "";
                  }
                  var _matches = allMatches.tl;
                  var match_ = match$1.VAL;
                  if (!_matches) {
                    return "matches " + match_;
                  }
                  var match$2 = _matches.hd[0];
                  if (typeof match$2 === "object" && match$2.NAME === "Literal") {
                    var match$3 = match$2.VAL;
                    if (typeof match$3 === "object" && match$3.NAME === "StringLit" && !_matches.tl) {
                      return "matches " + match_ + " or " + match$3.VAL;
                    }
                    
                  }
                  return "matches " + Belt_List.toArray(Belt_List.keepMap(allMatches, (function (match_) {
                                      var match = match_[0];
                                      if (typeof match !== "object") {
                                        return ;
                                      }
                                      if (match.NAME !== "Literal") {
                                        return ;
                                      }
                                      var match$1 = match.VAL;
                                      if (typeof match$1 === "object" && match$1.NAME === "StringLit") {
                                        return match$1.VAL;
                                      }
                                      
                                    }))).join(", ");
                }
                if (variant === "Max") {
                  var match$4 = validation.VAL;
                  if (typeof match$4 !== "object") {
                    return "";
                  }
                  var variant$1 = match$4.NAME;
                  if (variant$1 === "IntLit") {
                    return "max " + match$4.VAL.toString();
                  } else if (variant$1 === "FloatLit") {
                    return "max " + match$4.VAL.toString();
                  } else {
                    return "";
                  }
                }
                if (variant !== "Min") {
                  return "";
                }
                var match$5 = validation.VAL;
                if (typeof match$5 !== "object") {
                  return "";
                }
                var variant$2 = match$5.NAME;
                if (variant$2 === "IntLit") {
                  return "min " + match$5.VAL.toString();
                } else if (variant$2 === "FloatLit") {
                  return "min " + match$5.VAL.toString();
                } else {
                  return "";
                }
              }));
}

function PropertyValidations(Props) {
  var validations = Props.validations;
  return Belt_List.toArray(Belt_List.mapWithIndexU(getMatches(validations), (function (index, match_) {
                    return React.createElement("span", {
                                key: "match-" + index.toString(),
                                className: styles
                              }, match_);
                  })));
}

var make = PropertyValidations;

exports.styles = styles;
exports.getMatches = getMatches;
exports.make = make;
/* styles Not a pure module */
