// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var IconArrowUp = require("./IconArrowUp.bs.js");
var SourceSetup = require("./SourceSetup.bs.js");
var IconArrowDown = require("./IconArrowDown.bs.js");
var IconCheckmark = require("./IconCheckmark.bs.js");
var LoadingCircle = require("./LoadingCircle.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var ImplementationStatusContext = require("./ImplementationStatusContext.bs.js");

function ImplementInit(Props) {
  var schema = Props.schema;
  var model = Props.model;
  var source = Props.source;
  var match = React.useContext(ImplementationStatusContext.context).codegen;
  var match$1 = AvoConfig.supportsInvocations(source);
  var isInitialized = match$1 && match[0] === "Ready" ? Belt_Option.mapWithDefault(Js_dict.get(match[2], source.id), false, (function (invocations) {
            return !Belt_MapString.isEmpty(invocations);
          })) : false;
  var match$2 = React.useState(function () {
        return isInitialized;
      });
  var setCollapsed = match$2[1];
  var collapsed = match$2[0];
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("block"),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", isInitialized ? Styles.Color.mintGreenSecondary : Styles.Color.grey30),
                          tl: {
                            hd: Css.marginTop(Css.px(10)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("button", {
                  className: Curry._1(Css.style, Belt_List.concatMany([
                            Styles.button,
                            isInitialized ? ({
                                  hd: Css.backgroundColor(Styles.Color.mintGreen),
                                  tl: {
                                    hd: Css.color(Styles.Color.white),
                                    tl: /* [] */0
                                  }
                                }) : /* [] */0,
                            {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.width(Css.pct(100)),
                                tl: {
                                  hd: Css.padding(Css.px(12)),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.textAlign("left"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          ])),
                  onClick: (function (param) {
                      return Curry._1(setCollapsed, (function (collapsed) {
                                    return !collapsed;
                                  }));
                    })
                }, isInitialized ? React.createElement(IconCheckmark.make, {
                        color: Styles.Color.white
                      }) : null, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.margin(Css.px(0)),
                                tl: {
                                  hd: Css.width(Css.pct(100)),
                                  tl: {
                                    hd: Css.paddingLeft(isInitialized ? Css.px(8) : Css.px(0)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          children: isInitialized ? "Avo successfully initialized!" : "Setup instructions"
                        })), collapsed ? React.createElement(IconArrowDown.make, {
                        size: 12,
                        color: Styles.Color.grey70
                      }) : React.createElement(IconArrowUp.make, {
                        size: 12,
                        color: Styles.Color.grey70
                      })), collapsed ? null : React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.fontSize(Styles.FontSize.regular),
                          tl: /* [] */0
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding2(Css.px(4), Css.px(12)),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.regular),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement(SourceSetup.make, {
                            schema: schema,
                            model: model,
                            source: source
                          })), AvoConfig.supportsInvocations(source) ? (
                      isInitialized ? React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.padding(Css.px(15)),
                                        tl: {
                                          hd: Css.marginBottom(Css.px(15)),
                                          tl: {
                                            hd: Css.backgroundColor(Styles.Color.mintGreen),
                                            tl: {
                                              hd: Css.color(Styles.Color.white),
                                              tl: {
                                                hd: Css.borderRadius(Styles.Border.radius),
                                                tl: {
                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement(IconCheckmark.make, {
                                  color: Styles.Color.white
                                }), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.marginLeft(Css.px(15)),
                                        tl: /* [] */0
                                      })
                                }, "Analytics Wrapper Successfully Initialized!")) : React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.padding(Css.px(15)),
                                        tl: {
                                          hd: Css.margin(Css.px(15)),
                                          tl: {
                                            hd: Css.backgroundColor(Styles.Color.blue),
                                            tl: {
                                              hd: Css.color(Styles.Color.white),
                                              tl: {
                                                hd: Css.borderRadius(Styles.Border.radius),
                                                tl: {
                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement(LoadingCircle.make, {
                                  color: Styles.Color.white
                                }), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.marginLeft(Css.px(15)),
                                        tl: /* [] */0
                                      })
                                }, "Waiting for your first initialization..."))
                    ) : null));
}

var make = ImplementInit;

exports.make = make;
/* Css Not a pure module */
