// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Icon = require("../Icon.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var Mantine = require("../Mantine.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

var dropdown_0 = Css.borderWidth(Css.px(0));

var dropdown_1 = {
  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                NAME: "num",
                VAL: 0.10
              }))),
  tl: {
    hd: Css.marginTop(Css.px(5)),
    tl: {
      hd: Css.overflow("hidden"),
      tl: /* [] */0
    }
  }
};

var dropdown = {
  hd: dropdown_0,
  tl: dropdown_1
};

var input_0 = Css.fontFamily(Styles.fontFamily);

var input_1 = {
  hd: Css.fontWeight(Styles.FontWeight.semi),
  tl: {
    hd: Css.fontSize(Styles.FontSize.small),
    tl: {
      hd: Css.width(Css.px(124)),
      tl: {
        hd: Css.borderColor(Styles.Color.grey30),
        tl: {
          hd: Css.backgroundColor(Styles.Color.white),
          tl: {
            hd: Css.textOverflow("ellipsis"),
            tl: {
              hd: Css.transition({
                    NAME: "ms",
                    VAL: Styles.Duration.$$short
                  }, undefined, undefined, "background-color"),
              tl: {
                hd: Css.selector(" + div", {
                      hd: Icon.color(Styles.Color.grey60),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.color(Styles.Color.grey80),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.grey90),
                          tl: {
                            hd: Css.borderColor(Styles.Color.grey40),
                            tl: {
                              hd: Css.selector(" + div", {
                                    hd: Icon.color(Styles.Color.grey80),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }),
                    tl: {
                      hd: Css.active({
                            hd: Css.borderColor(Styles.Color.blue100),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var input = {
  hd: input_0,
  tl: input_1
};

var itemsWrapper_0 = Css.padding("zero");

var itemsWrapper_1 = {
  hd: Css.margin("zero"),
  tl: /* [] */0
};

var itemsWrapper = {
  hd: itemsWrapper_0,
  tl: itemsWrapper_1
};

var item_0 = Css.display("flex");

var item_1 = {
  hd: Css.alignItems("center"),
  tl: {
    hd: Css.margin("zero"),
    tl: {
      hd: Css.marginBottom(Css.px(1)),
      tl: {
        hd: Css.borderRadius("zero"),
        tl: {
          hd: Css.fontSize(Styles.FontSize.small),
          tl: {
            hd: Css.transitions({
                  hd: Css_Legacy_Core.Transition.shorthand({
                        NAME: "ms",
                        VAL: Styles.Duration.$$short
                      }, undefined, undefined, "background-color"),
                  tl: {
                    hd: Css_Legacy_Core.Transition.shorthand({
                          NAME: "ms",
                          VAL: Styles.Duration.$$default
                        }, undefined, undefined, "color"),
                    tl: /* [] */0
                  }
                }),
            tl: {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.grey20),
                    tl: {
                      hd: Css.selector(" .avo-select-item-icon", {
                            hd: Icon.color(Styles.Color.grey70),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    }
  }
};

var item = {
  hd: item_0,
  tl: item_1
};

var itemSelected_0 = Css.backgroundColor(Styles.Color.blue10);

var itemSelected_1 = {
  hd: Css.selector(" .avo-select-item-icon", {
        hd: Icon.color(Styles.Color.grey70),
        tl: /* [] */0
      }),
  tl: {
    hd: Css.color(Styles.Color.grey90),
    tl: {
      hd: Css.fontWeight(Styles.FontWeight.semi),
      tl: {
        hd: Css.hover({
              hd: Css.backgroundColor(Styles.Color.grey20),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    }
  }
};

var itemSelected = {
  hd: itemSelected_0,
  tl: itemSelected_1
};

var iconWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: /* [] */0
        }
      }
    });

var icon = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

function DiffViewTypeDropdown$ItemComponent(Props) {
  var item = Props.item;
  var el = item.icon;
  var description = item.description;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: /* [] */0
                  })
            }, el !== undefined ? React.createElement("div", {
                    className: iconWrapper
                  }, React.createElement("div", {
                        className: icon + " avo-select-item-icon"
                      }, el), React.createElement(Spacer.make, {
                        width: 10
                      })) : null, React.createElement("div", undefined, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.grey90,
                      maxWidth: Css.pct(100.0),
                      children: item.label
                    }), description !== undefined ? React.createElement("div", {
                        className: "avo-select-item-description"
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Medium",
                            color: Styles.Color.grey50,
                            withWordBreak: true,
                            children: description
                          })) : null));
}

var ItemComponent = Mantine.Select.Item({
      make: DiffViewTypeDropdown$ItemComponent
    });

function DiffViewTypeDropdown(Props) {
  var data = Props.data;
  var icon = Props.icon;
  var value = Props.value;
  var onChange = Props.onChange;
  var tmp = {
    data: data,
    dropdownStyles: dropdown,
    inputStyles: input,
    itemComponent: ItemComponent.make,
    itemStyles: item,
    itemSelectedStyles: itemSelected,
    itemsWrapperStyles: itemsWrapper,
    onChange: onChange,
    rightSection: React.createElement(Icon.make, {
          type_: "chevronDown",
          size: "small"
        }),
    rightSectionWidth: 36,
    value: value,
    withinPortal: true,
    zIndex: 1
  };
  if (icon !== undefined) {
    tmp.icon = Caml_option.valFromOption(icon);
  }
  return React.createElement(Mantine.Select.make, tmp);
}

var make = DiffViewTypeDropdown;

exports.make = make;
/* dropdown Not a pure module */
