// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconNavigate(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey70;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("svg", {
                  width: String(size),
                  viewBox: "0 0 16 16",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("g", {
                      className: "nc-icon-wrapper",
                      strokeWidth: "1"
                    }, React.createElement("polyline", {
                          fill: "none",
                          points: "8.5 3.5 8.5 0.5 5.5 0.5 5.5 3.5",
                          strokeLinecap: "round"
                        }), React.createElement("polygon", {
                          fill: "none",
                          points: "0.5 8.5 12.5 8.5 15.5 6 12.5 3.5 0.5 3.5 0.5 8.5",
                          strokeLinecap: "round"
                        }), React.createElement("polyline", {
                          fill: "none",
                          points: "5.5 10.5 5.5 15.5 8.5 15.5 8.5 10.5",
                          strokeLinecap: "round"
                        }))));
}

var make = IconNavigate;

exports.make = make;
/* Css Not a pure module */
