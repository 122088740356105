// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("../Hooks.bs.js");
var React = require("react");
var Router = require("../Router.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var DiffUtils = require("../DiffUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Dom_storage = require("rescript/lib/js/dom_storage.js");
var SnippetTypes = require("../detailviews/SnippetTypes.bs.js");
var SnippetUtils = require("../detailviews/SnippetUtils.bs.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var ImplementationStatusContext = require("../ImplementationStatusContext.bs.js");
var FetchImplementationStatusUtils = require("../../../shared/utils/FetchImplementationStatusUtils.bs.js");

function useSourceUpdates(actions, toModel, fromModel, withImplementationStatus) {
  var invocationsContext = React.useContext(ImplementationStatusContext.context).codegen;
  var sourceInitStatus = invocationsContext[2];
  var invocations = withImplementationStatus ? FetchImplementationStatusUtils.getInvocationsWithoutStatus(invocationsContext) : "Loading";
  var branchId = Router.Schema.getBranchId(undefined);
  var sourceUpdates = React.useMemo((function () {
          return DiffUtils.getBranchImplementationStatus(actions, toModel, fromModel, invocations, sourceInitStatus, branchId);
        }), [
        toModel,
        fromModel,
        invocations
      ]);
  return {
          loadingState: invocationsContext[0] === "Ready" ? "ready" : "loading",
          sourceUpdates: sourceUpdates
        };
}

var usingManageDiffViewType_useOnce = {
  contents: false
};

var UsingManageDiffViewType_useOnceHookMoreThanOnceException = /* @__PURE__ */Caml_exceptions.create("BranchImplementationHooks.UsingManageDiffViewType_useOnceHookMoreThanOnceException");

function useManageDiffViewType_useOnce(diffViewType, setDiffViewType, onTrigger) {
  var diffViewTypeLocalStorageKey = "branchImplementationDiffViewType";
  React.useEffect((function () {
          if (usingManageDiffViewType_useOnce.contents) {
            throw {
                  RE_EXN_ID: UsingManageDiffViewType_useOnceHookMoreThanOnceException,
                  Error: new Error()
                };
          }
          usingManageDiffViewType_useOnce.contents = true;
          Curry._1(setDiffViewType, Belt_Option.getWithDefault(Belt_Option.flatMap(Dom_storage.getItem(diffViewTypeLocalStorageKey, localStorage), SnippetTypes.DiffViewType.tFromJs), "unified"));
          return (function (param) {
                    usingManageDiffViewType_useOnce.contents = false;
                    
                  });
        }), []);
  return Hooks.useDidUpdate1((function (param) {
                Dom_storage.setItem(diffViewTypeLocalStorageKey, SnippetTypes.DiffViewType.tToJs(diffViewType), localStorage);
                Curry._1(onTrigger, undefined);
                
              }), [diffViewType]);
}

var usingManageSelectedSourceId_useOnce = {
  contents: false
};

var UsingManageSelectedSourceId_useOnceHookMoreThanOnceException = /* @__PURE__ */Caml_exceptions.create("BranchImplementationHooks.UsingManageSelectedSourceId_useOnceHookMoreThanOnceException");

function useManageSelectedSourceId_useOnce(selectedSourceId, setSelectedSourceId, onTrigger) {
  var previousSelectedSourceId = Hooks.usePrevious1(selectedSourceId);
  var match = Router.QueryParams.useKey(SnippetUtils.queryParamKeys.sourceId);
  var removeSelectedSourceIdQueryParamByReplace = match.removeByReplace;
  var setSelectedSourceIdQueryParam = match.set;
  var selectedSourceIdQueryParam = match.value;
  var match$1 = Router.QueryParams.useKey(SnippetUtils.queryParamKeys.eventId);
  var removeEventIdQueryParamByReplace = match$1.removeByReplace;
  var match$2 = Router.QueryParams.useKey(SnippetUtils.queryParamKeys.pageName);
  var removePageNameQueryParamByReplace = match$2.removeByReplace;
  React.useEffect((function () {
          if (usingManageSelectedSourceId_useOnce.contents) {
            throw {
                  RE_EXN_ID: UsingManageSelectedSourceId_useOnceHookMoreThanOnceException,
                  Error: new Error()
                };
          }
          usingManageSelectedSourceId_useOnce.contents = true;
          return (function (param) {
                    Curry._1(removeSelectedSourceIdQueryParamByReplace, undefined);
                    usingManageSelectedSourceId_useOnce.contents = false;
                    
                  });
        }), []);
  Hooks.useDidUpdate1((function (param) {
          var routingBetweenSources = selectedSourceId !== undefined && previousSelectedSourceId !== undefined;
          var routingToOverview = selectedSourceId === undefined;
          var isSameSource = Caml_obj.caml_equal(previousSelectedSourceId, selectedSourceId);
          if ((routingBetweenSources || routingToOverview) && !isSameSource) {
            Curry._1(removeEventIdQueryParamByReplace, undefined);
            Curry._1(removePageNameQueryParamByReplace, undefined);
          }
          
        }), [selectedSourceId]);
  React.useEffect((function () {
          if (selectedSourceIdQueryParam !== undefined) {
            if (selectedSourceId !== undefined && selectedSourceIdQueryParam === selectedSourceId) {
              
            } else {
              Curry._1(setSelectedSourceId, selectedSourceIdQueryParam);
            }
          } else if (selectedSourceId !== undefined) {
            Curry._1(setSelectedSourceId, undefined);
          }
          
        }), [selectedSourceIdQueryParam]);
  React.useEffect((function () {
          return (function (param) {
                    Curry._1(setSelectedSourceId, undefined);
                    return Curry._1(removeSelectedSourceIdQueryParamByReplace, undefined);
                  });
        }), []);
  return function (value) {
    Curry._1(setSelectedSourceId, value !== "" && value !== "Overview" ? value : undefined);
    Curry._1(onTrigger, value);
    if (value !== "" && value !== "Overview") {
      return Curry._1(setSelectedSourceIdQueryParam, value);
    } else {
      return Curry._1(removeSelectedSourceIdQueryParamByReplace, undefined);
    }
  };
}

exports.useSourceUpdates = useSourceUpdates;
exports.usingManageDiffViewType_useOnce = usingManageDiffViewType_useOnce;
exports.UsingManageDiffViewType_useOnceHookMoreThanOnceException = UsingManageDiffViewType_useOnceHookMoreThanOnceException;
exports.useManageDiffViewType_useOnce = useManageDiffViewType_useOnce;
exports.usingManageSelectedSourceId_useOnce = usingManageSelectedSourceId_useOnce;
exports.UsingManageSelectedSourceId_useOnceHookMoreThanOnceException = UsingManageSelectedSourceId_useOnceHookMoreThanOnceException;
exports.useManageSelectedSourceId_useOnce = useManageSelectedSourceId_useOnce;
/* Hooks Not a pure module */
