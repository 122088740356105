// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Sentry = require("./externals/Sentry.bs.js");
var $$String = require("rescript/lib/js/string.js");
var Styles = require("./styles.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Redirect = require("./Redirect.bs.js");
var SsoUtils = require("./SsoUtils.bs.js");
var SsoChecks = require("./SsoChecks.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var LoadingCircle = require("./LoadingCircle.bs.js");
var SignInWithPopup = require("./SignInWithPopup.bs.js");
var ContinueWithGoogleButton = require("./ContinueWithGoogleButton.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.marginLeft("auto"),
              tl: {
                hd: Css.marginRight("auto"),
                tl: {
                  hd: Css.marginTop(Css.vh(40)),
                  tl: {
                    hd: Css.width(Css.px(350)),
                    tl: {
                      hd: Css.maxWidth(Css.pct(100)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var welcomeToAvoLabelStyles = Curry._1(Css.style, {
      hd: Css.marginBottom(Css.px(8)),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.fontSize(Styles.FontSize.huge),
          tl: {
            hd: Css.color(Styles.Color.grey80),
            tl: {
              hd: Css.textAlign("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var loginToWorkspaceSublabelStyles = Curry._1(Css.style, {
      hd: Css.fontSize(Styles.FontSize.regular),
      tl: {
        hd: Css.textAlign("center"),
        tl: {
          hd: Css.width(Css.pct(100)),
          tl: {
            hd: Css.marginBottom(Css.px(48)),
            tl: /* [] */0
          }
        }
      }
    });

var avoLogoStyles = Curry._1(Css.style, {
      hd: Css.display("block"),
      tl: {
        hd: Css.height(Css.px(75)),
        tl: {
          hd: Css.margin2(Css.px(32), "auto"),
          tl: /* [] */0
        }
      }
    });

var centerLoadingStyles = Curry._1(Css.style, {
      hd: Css.margin("auto"),
      tl: {
        hd: Css.width(Css.px(22)),
        tl: /* [] */0
      }
    });

var errorMessageStyles = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100)),
      tl: {
        hd: Css.padding2(Css.px(8), Css.px(10)),
        tl: {
          hd: Css.margin2(Css.px(24), Css.px(0)),
          tl: {
            hd: Css.lineHeight(Css.pct(120.0)),
            tl: {
              hd: Css.color(Styles.Color.grapeError),
              tl: {
                hd: Css.backgroundColor(Styles.Color.grapeErrorBg),
                tl: {
                  hd: Css.borderRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.grapeError),
                    tl: {
                      hd: Css.fontWeight(Styles.FontWeight.semi),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function UnauthenticatedSchemaAccessWall(Props) {
  var schemaId = Props.schemaId;
  var auth = Firebase.getAuth(undefined);
  var match = React.useState(function () {
        return /* InitialLoading */0;
      });
  var setStats = match[1];
  var stats = match[0];
  React.useEffect((function () {
          SsoChecks.getSsoProviderForSchemaFromFirestore(schemaId).then(function (ssoProvider) {
                if (ssoProvider !== undefined) {
                  var provider = Caml_option.valFromOption(ssoProvider);
                  if ($$String.lowercase_ascii(provider.authMethod) === SsoUtils.samlAuthMethodLowercaseString) {
                    return Promise.resolve(Curry._1(setStats, (function (param) {
                                      return {
                                              TAG: /* SamlSso */1,
                                              _0: provider.companyName,
                                              _1: provider.authProviderId,
                                              _2: undefined
                                            };
                                    })));
                  }
                  if ($$String.lowercase_ascii(provider.authMethod) === SsoUtils.googleAuthMethodLowercaseString) {
                    return Promise.resolve(Curry._1(setStats, (function (param) {
                                      return {
                                              TAG: /* GoogleSso */0,
                                              _0: provider.companyName,
                                              _1: undefined
                                            };
                                    })));
                  }
                  
                }
                return Promise.resolve(Curry._1(setStats, (function (param) {
                                  return /* NoSso */1;
                                })));
              });
          
        }), [schemaId]);
  var parseFirebaseAuthSigninError = function (error) {
    var match = error.code;
    switch (match) {
      case "auth/user-disabled" :
          return "This user has been disabled, please contact support ☎️";
      case "auth/user-not-found" :
          return "Hmm, we couldn't find a user with those credentials 🕵️‍. Try another account or sign up.";
      case "auth/wrong-password" :
          return "Whoops, incorrect password. If you forgot your password please contact support ☎️";
      default:
        Sentry.captureException(error, {});
        return "Hmm, this is awkward, something unexpected happened while trying to sign in 😟";
    }
  };
  var tmp;
  if (typeof stats === "number") {
    tmp = stats === /* InitialLoading */0 ? React.createElement("div", {
            className: centerLoadingStyles
          }, React.createElement(LoadingCircle.make, {
                color: Styles.Color.grey40
              })) : React.createElement(Redirect.make, {
            path: "/login?redirect=" + Router.buildRedirectPath(undefined)
          });
  } else if (stats.TAG === /* GoogleSso */0) {
    var error = stats._1;
    var companyName = stats._0;
    tmp = React.createElement("div", undefined, React.createElement("img", {
              className: avoLogoStyles,
              src: (require('./svg/avo_thumbnail.svg'))
            }), React.createElement("div", {
              className: welcomeToAvoLabelStyles
            }, "Welcome to " + companyName + " on Avo"), React.createElement("div", {
              className: loginToWorkspaceSublabelStyles
            }, "Login with Google to access the " + companyName + " workspace"), React.createElement(ContinueWithGoogleButton.make, {
              onClick: (function ($$event) {
                  $$event.preventDefault();
                  $$Promise.$$catch(SignInWithPopup.viaGoogle(auth, undefined), (function (error) {
                          return Promise.resolve(Curry._1(setStats, (function (param) {
                                            return {
                                                    TAG: /* GoogleSso */0,
                                                    _0: companyName,
                                                    _1: parseFirebaseAuthSigninError(error)
                                                  };
                                          })));
                        }));
                  
                }),
              colors: [
                Styles.Color.blue,
                Styles.Color.blueSecondary
              ]
            }), error !== undefined ? React.createElement("div", {
                className: errorMessageStyles
              }, error) : null);
  } else {
    var error$1 = stats._2;
    var authProviderId = stats._1;
    var companyName$1 = stats._0;
    tmp = React.createElement(React.Fragment, undefined, React.createElement("img", {
              className: avoLogoStyles,
              src: (require('./svg/avo_thumbnail.svg'))
            }), React.createElement("div", {
              className: welcomeToAvoLabelStyles
            }, "Welcome to " + companyName$1 + " on Avo"), React.createElement("div", {
              className: loginToWorkspaceSublabelStyles
            }, "Login with SSO to access the " + companyName$1 + " workspace"), React.createElement(Button.make, {
              label: "Continue with SSO",
              onClick: (function ($$event) {
                  $$event.preventDefault();
                  $$Promise.$$catch(SignInWithPopup.viaSaml(auth, authProviderId), (function (error) {
                          return Promise.resolve(Curry._1(setStats, (function (param) {
                                            return {
                                                    TAG: /* SamlSso */1,
                                                    _0: companyName$1,
                                                    _1: authProviderId,
                                                    _2: parseFirebaseAuthSigninError(error)
                                                  };
                                          })));
                        }));
                  
                }),
              size: "large"
            }), error$1 !== undefined ? React.createElement("div", {
                className: errorMessageStyles
              }, error$1) : null);
  }
  return React.createElement("div", {
              className: rootStyles
            }, tmp);
}

var make = UnauthenticatedSchemaAccessWall;

exports.rootStyles = rootStyles;
exports.welcomeToAvoLabelStyles = welcomeToAvoLabelStyles;
exports.loginToWorkspaceSublabelStyles = loginToWorkspaceSublabelStyles;
exports.avoLogoStyles = avoLogoStyles;
exports.centerLoadingStyles = centerLoadingStyles;
exports.errorMessageStyles = errorMessageStyles;
exports.make = make;
/* rootStyles Not a pure module */
