// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Title = require("../Title.bs.js");
var React = require("react");
var Models = require("../Models.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var IconLightning = require("../IconLightning.bs.js");
var CreateButtonIcon = require("./CreateButtonIcon.bs.js");
var EmptyStatesStyles = require("./EmptyStatesStyles.bs.js");
var PublishingEmptyIllustration = require("./PublishingEmptyIllustration.bs.js");

function PublishingEmpty(Props) {
  var onCreate = Props.onCreate;
  var createAvailability = Props.createAvailability;
  var role = Props.role;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingLeft(Css.px(15)),
                    tl: {
                      hd: Css.paddingTop(Css.px(15)),
                      tl: {
                        hd: Css.paddingRight(Css.px(15)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("main", {
                  className: EmptyStatesStyles.rootStyles
                }, React.createElement(PublishingEmptyIllustration.make, {}), React.createElement(Spacer.make, {
                      height: 32
                    }), React.createElement(Title.make, {
                      children: "Integrate your Dev Toolchain with Avo",
                      size: "Medium"
                    }), React.createElement(Spacer.make, {
                      height: 12
                    }), React.createElement($$Text.make, {
                      size: "Large",
                      color: Styles.Color.grey70,
                      maxWidth: Css.px(520),
                      children: null
                    }, "Integrations enable you to pipe your data into existing workflows and apps and unlock powerful connections and value for your team. Read the ", React.createElement("a", {
                          className: EmptyStatesStyles.linkStyles,
                          href: "https://www.avo.app/docs/workspace/tracking-plan/publishing",
                          rel: "noopener",
                          target: "_blank"
                        }, React.createElement($$Text.make, {
                              element: "Span",
                              weight: "Semi",
                              children: "Avo docs"
                            })), "."), Belt_Option.mapWithDefault(onCreate, null, (function (onClick) {
                        if (Models.Role.canEdit(role)) {
                          return React.createElement("div", {
                                      className: EmptyStatesStyles.actionsStyles
                                    }, React.createElement("button", {
                                          className: EmptyStatesStyles.actionStyles,
                                          onClick: onClick
                                        }, React.createElement("div", {
                                              className: EmptyStatesStyles.iconStyles
                                            }, createAvailability !== undefined && createAvailability !== "Available" ? React.createElement(IconLightning.make, {}) : CreateButtonIcon.icon), React.createElement($$Text.make, {
                                              element: "Span",
                                              size: "Medium",
                                              weight: "Semi",
                                              color: Styles.Color.grey80,
                                              children: "Create Your First Integration"
                                            })));
                        } else {
                          return null;
                        }
                      }))));
}

var make = PublishingEmpty;

exports.make = make;
/* Css Not a pure module */
