// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var CmdK = require("./CmdK.bs.js");
var $$Text = require("./Text.bs.js");
var Kbar = require("kbar");
var Curry = require("rescript/lib/js/curry.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Styles = require("./styles.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CmdKActions = require("./CmdKActions.bs.js");
var KeyListener = require("./KeyListener.bs.js");
var NavigatorOs = require("./navigatorOs.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");

var positioner = Curry._1(Css.style, {
      hd: Css.zIndex(Styles.ZIndex.aboveAll),
      tl: /* [] */0
    });

var searchContainer = Curry._1(Css.style, {
      hd: Css.boxShadow(Styles.Shadow.standardHover),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.borderRadius(Css.px(4)),
          tl: /* [] */0
        }
      }
    });

var inputContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.padding(Css.px(16)),
            tl: {
              hd: Css.height(Css.px(64)),
              tl: {
                hd: Css.width(Css.px(580)),
                tl: {
                  hd: Css.maxWidth(Css.px(580)),
                  tl: {
                    hd: Css.borderRadius(Css.px(4)),
                    tl: {
                      hd: Css.color(Styles.Color.grey70),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var input = Curry._1(Css.style, {
      hd: Css.fontSize(Css.px(16)),
      tl: {
        hd: Css.padding(Css.px(0)),
        tl: {
          hd: Css.width(Css.pct(100.0)),
          tl: {
            hd: Css.borderWidth(Css.px(0)),
            tl: {
              hd: Css.borderRadius(Css.px(4)),
              tl: {
                hd: Css.outlineStyle("none"),
                tl: {
                  hd: Css.color(Styles.Color.grey80),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var Style = {
  positioner: positioner,
  searchContainer: searchContainer,
  inputContainer: inputContainer,
  input: input
};

function getPrefix(actionId) {
  switch (actionId) {
    case "branch" :
        return "Branch/";
    case "categories" :
        return "Search/Categories/";
    case "create" :
        return "Create/";
    case "destinations" :
        return "Search/Destinations/";
    case "events" :
        return "Search/Events/";
    case "integrations" :
        return "Search/Integrations/";
    case "metrics" :
        return "Search/Metrics/";
    case "properties" :
        return "Search/Properties/";
    case "propertyGroups" :
        return "Search/Property Bundles/";
    case "search" :
        return "Search/";
    case "sources" :
        return "Search/Sources/";
    case "unarchive" :
        return "Unarchive/";
    case "unarchiveCategories" :
        return "Unarchive/Categories/";
    case "unarchiveDestinations" :
        return "Unarchive/Destinations/";
    case "unarchiveEvents" :
        return "Unarchive/Events/";
    case "unarchiveGroupTypes" :
        return "Unarchive/Group Types/";
    case "unarchiveMetrics" :
        return "Unarchive/Metrics/";
    case "unarchiveProperties" :
        return "Unarchive/Properties/";
    case "unarchivePropertyGroups" :
        return "Unarchive/Property Bundles/";
    case "unarchiveSources" :
        return "Unarchive/Sources/";
    default:
      return "";
  }
}

function CmdKRoot(Props) {
  var model = Props.model;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var children = Props.children;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var searchRef = React.useRef(null);
  var match = Curry._1(CmdK.Matches.useMatches, undefined);
  var rootActionId = match.rootActionId;
  var addToast = Toast.useAddToast(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  Kbar.useRegisterActions(CmdKActions.getActions(globalSend, model, openBranches, schemaGroup, sendActions, role), [
        model,
        openBranches,
        schemaGroup
      ]);
  KeyListener.use(true, undefined, (function (param) {
          var match = Router.getStaticRoute(undefined);
          var branch = match.branch;
          AnalyticsRe.searchInputActivated(schemaGroup, Router.schemaRouteToAnalyticsLocation(match.schemaRoute, match.drawerItems), branch !== undefined && typeof branch === "object" && branch.NAME === "branch" ? branch.VAL : "master", schemaGroup.schemaId);
          return Curry._1(addToast, {
                      message: "Search has moved to " + (
                        NavigatorOs.os === "MacOS" ? "cmd" : "ctrl"
                      ) + "+k"
                    });
        }), "f");
  var match$1 = React.useState(function () {
        return false;
      });
  var setInputIsFocused = match$1[1];
  var tmp;
  tmp = rootActionId !== undefined ? (
      rootActionId === "search" ? "Type to search or choose item type…" : ""
    ) : "Type a command or search…";
  return React.createElement(Kbar.KBarPortal, {
              children: React.createElement(Kbar.KBarPositioner, {
                    className: positioner,
                    children: React.createElement(Kbar.KBarAnimator, {
                          children: React.createElement("div", {
                                className: searchContainer
                              }, React.createElement("div", {
                                    ref: searchRef,
                                    className: Curry._1(Css.merge, {
                                          hd: inputContainer,
                                          tl: {
                                            hd: Curry._1(Css.style, {
                                                  hd: Css.border(Css.px(3), "solid", match$1[0] ? Styles.Color.blue : "transparent"),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }),
                                    onClick: (function (param) {
                                        return Belt_Option.forEach(Caml_option.nullable_to_opt(searchRef.current), (function (el) {
                                                      return Belt_Option.forEach(Belt_Array.get(el.children, 0), (function (prim) {
                                                                    prim.focus();
                                                                    
                                                                  }));
                                                    }));
                                      })
                                  }, Belt_Option.mapWithDefault(rootActionId, null, (function (actionId) {
                                          return React.createElement("span", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.flexShrink(0.0),
                                                            tl: /* [] */0
                                                          })
                                                    }, React.createElement($$Text.make, {
                                                          singleLine: true,
                                                          color: Styles.Color.grey70,
                                                          children: getPrefix(actionId)
                                                        }));
                                        })), React.createElement(Kbar.KBarSearch, {
                                        className: input,
                                        placeholder: tmp,
                                        onFocus: (function (param) {
                                            return Curry._1(setInputIsFocused, (function (param) {
                                                          return true;
                                                        }));
                                          }),
                                        onBlur: (function (param) {
                                            return Curry._1(setInputIsFocused, (function (param) {
                                                          return false;
                                                        }));
                                          })
                                      })), children)
                        })
                  })
            });
}

var make = CmdKRoot;

exports.Style = Style;
exports.getPrefix = getPrefix;
exports.make = make;
/* positioner Not a pure module */
