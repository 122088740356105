// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconProportion(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey70;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: /* [] */0
                  }),
              width: String(size),
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", undefined, React.createElement("path", {
                      d: "M4,6C2.3,6,1,4.7,1,3s1.3-3,3-3s3,1.3,3,3S5.7,6,4,6z M4,2C3.4,2,3,2.4,3,3s0.4,1,1,1 s1-0.4,1-1S4.6,2,4,2z"
                    }), React.createElement("path", {
                      d: "M12,16c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S13.7,16,12,16z M12,12c-0.6,0-1,0.4-1,1 s0.4,1,1,1s1-0.4,1-1S12.6,12,12,12z"
                    }), React.createElement("path", {
                      d: "M1,16c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l14-14c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-14,14 C1.5,15.9,1.3,16,1,16z"
                    })));
}

var make = IconProportion;

exports.make = make;
/* Css Not a pure module */
