// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Styles = require("./styles.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Workspace = require("../../model/src/Workspace.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var QuillEditor = require("./QuillEditor.bs.js");
var QuillDelta = require("quill-delta");
var ProfilePhoto = require("./ProfilePhoto.bs.js");
var App = require("firebase/app");
var LoadingCircle = require("./LoadingCircle.bs.js");
var PlanLightning = require("./PlanLightning.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

function CommentInput(Props) {
  var userId = Props.userId;
  var userExternalId = Props.userExternalId;
  var schemaId = Props.schemaId;
  var eventId = Props.eventId;
  var threadId = Props.threadId;
  var events = Props.events;
  var itemType = Props.itemType;
  var itemName = Props.itemName;
  var miniOpt = Props.mini;
  var currentBranch = Props.currentBranch;
  var role = Props.role;
  var schemaGroup = Props.schemaGroup;
  var autoFocus = Props.autoFocus;
  var onChange = Props.onChange;
  var $$location = Props.location;
  var mini = miniOpt !== undefined ? miniOpt : false;
  var match = React.useState(function () {
        return new QuillDelta();
      });
  var setInput = match[1];
  var input = match[0];
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var users = FirebaseFetcherHooks.useUsers(Belt_List.toArray(Belt_List.map(workspace.members, (function (member) {
                  return member.id;
                }))));
  var availability = AvoLimits.Collaboration.computeAvailability(workspace);
  var availableOnPlan = AvoLimits.Collaboration.availableOnPlan(workspace);
  var onNeedsUpgrade = function (param) {
    return Curry._1(globalSend, {
                TAG: /* OpenModal */4,
                _0: {
                  NAME: "BillingPrompt",
                  VAL: "AddComment"
                }
              });
  };
  var handleComment = function (input) {
    var commentBatch = Firebase.app(undefined).firestore().batch();
    var threadRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("comments").doc(threadId);
    commentBatch.set(threadRef, {
          id: threadId
        });
    var commentRef = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("comments").doc(threadId).collection("items").doc();
    var commentId = commentRef.id;
    var tmp = {
      id: commentId,
      createdAt: App.default.firestore.FieldValue.serverTimestamp(),
      authorId: userId,
      content: JSON.stringify(input)
    };
    if (eventId !== undefined) {
      tmp.eventId = Caml_option.valFromOption(eventId);
    }
    var tmp$1 = itemType === "Property" ? threadId : undefined;
    if (tmp$1 !== undefined) {
      tmp.propertyId = Caml_option.valFromOption(tmp$1);
    }
    var tmp$2 = itemType === "PropertyGroup" ? threadId : undefined;
    if (tmp$2 !== undefined) {
      tmp.propertyGroupId = Caml_option.valFromOption(tmp$2);
    }
    var tmp$3 = typeof currentBranch === "object" ? currentBranch.VAL : undefined;
    if (tmp$3 !== undefined) {
      tmp.branchId = Caml_option.valFromOption(tmp$3);
    }
    var tmp$4 = itemType === "Metric" ? threadId : undefined;
    if (tmp$4 !== undefined) {
      tmp.metricId = Caml_option.valFromOption(tmp$4);
    }
    var tmp$5 = itemType === "Goal" ? threadId : undefined;
    if (tmp$5 !== undefined) {
      tmp.goalId = Caml_option.valFromOption(tmp$5);
    }
    var tmp$6 = itemType === "Integration" ? threadId : undefined;
    if (tmp$6 !== undefined) {
      tmp.integrationId = Caml_option.valFromOption(tmp$6);
    }
    commentBatch.set(commentRef, tmp);
    var action = itemType === "Integration" ? ({
          NAME: "IntegrationComment",
          VAL: [
            threadId,
            commentId
          ]
        }) : (
        itemType === "Property" ? ({
              NAME: "PropertyComment",
              VAL: [
                threadId,
                commentId
              ]
            }) : (
            itemType === "Event" ? (
                eventId !== undefined ? ({
                      NAME: "EventComment",
                      VAL: [
                        eventId,
                        commentId
                      ]
                    }) : ({
                      NAME: "EventComment",
                      VAL: [
                        threadId,
                        commentId
                      ]
                    })
              ) : (
                itemType === "PropertyGroup" ? ({
                      NAME: "PropertyGroupComment",
                      VAL: [
                        threadId,
                        commentId
                      ]
                    }) : (
                    itemType === "Goal" ? ({
                          NAME: "GoalComment",
                          VAL: [
                            threadId,
                            commentId
                          ]
                        }) : (
                        itemType === "Metric" ? ({
                              NAME: "MetricComment",
                              VAL: [
                                threadId,
                                commentId
                              ]
                            }) : ({
                              NAME: "BranchComment",
                              VAL: [
                                threadId,
                                commentId
                              ]
                            })
                      )
                  )
              )
          )
      );
    var actionContext;
    if (itemType === "Integration") {
      actionContext = {
        integrationId: threadId,
        integrationQuery: threadId
      };
    } else if (itemType === "Property") {
      var tmp$7 = {
        propertyId: threadId,
        propertyQuery: threadId
      };
      if (eventId !== undefined) {
        tmp$7.eventId = Caml_option.valFromOption(eventId);
      }
      actionContext = tmp$7;
    } else if (itemType === "Event") {
      actionContext = {
        eventId: Belt_Option.getWithDefault(eventId, threadId),
        eventQuery: Belt_Option.getWithDefault(eventId, threadId)
      };
    } else if (itemType === "PropertyGroup") {
      var tmp$8 = {
        propertyGroupId: threadId,
        propertyGroupQuery: threadId
      };
      if (eventId !== undefined) {
        tmp$8.eventId = Caml_option.valFromOption(eventId);
      }
      actionContext = tmp$8;
    } else {
      actionContext = itemType === "Goal" ? ({
            goalId: threadId,
            goalQuery: threadId
          }) : (
          itemType === "Metric" ? ({
                metricId: threadId,
                metricQuery: threadId
              }) : ({
                branchId: threadId,
                branchQuery: threadId
              })
        );
    }
    return Curry.app(sendActions, [
                undefined,
                false,
                Caml_option.some(commentBatch),
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    var userMentions = Belt_Array.keepMap(input.ops, (function (op) {
                            return Belt_Option.flatMap(Caml_option.undefined_to_opt(op.insert), (function (prim) {
                                          return Caml_option.undefined_to_opt(prim["user-mention"]);
                                        }));
                          }));
                    var eventMentions = Belt_Array.keepMap(input.ops, (function (op) {
                            return Belt_Option.flatMap(Caml_option.undefined_to_opt(op.insert), (function (prim) {
                                          return Caml_option.undefined_to_opt(prim["event-mention"]);
                                        }));
                          }));
                    AnalyticsRe.commentAdded(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), commentRef.id, itemType === "Event" ? threadId : Belt_Option.getWithDefault(eventId, "N/A"), itemType === "Property" ? threadId : undefined, itemName, userId, itemType === "Integration" ? "Integration" : (
                            itemType === "Property" ? "Property" : (
                                itemType === "Event" ? "Event" : (
                                    itemType === "PropertyGroup" ? "PropertyGroup" : (
                                        itemType === "Goal" ? "Goal" : (
                                            itemType === "Metric" ? "Metric" : "Branch"
                                          )
                                      )
                                  )
                              )
                          ), userMentions.length, eventMentions.length, Belt_Array.map(userMentions, (function (prim) {
                                return prim.id;
                              })), Belt_Array.map(eventMentions, (function (prim) {
                                return prim.id;
                              })), Belt_Array.map(userMentions, (function (prim) {
                                return prim.value;
                              })), Belt_Array.map(eventMentions, (function (prim) {
                                return prim.value;
                              })), userExternalId, itemType === "Metric" ? threadId : undefined, threadId, $$location, itemType === "Integration" ? threadId : undefined, NamedBranch.getId(branch), schemaGroup.schemaId);
                    threadRef.collection("items").get().then(function (querySnapshot) {
                          var data = querySnapshot.docs;
                          var authorIds = Belt_SetString.fromArray(Belt_Array.map(data, (function (snapshot) {
                                      return snapshot.data().authorId;
                                    })));
                          if (Belt_SetString.size(authorIds) > 1) {
                            return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).set({
                                        onboardingV2: {
                                          hasCommentCollaboration: true
                                        }
                                      }, {"merge": true});
                          } else {
                            return Promise.resolve(undefined);
                          }
                        });
                    
                  }),
                undefined,
                [[
                    action,
                    actionContext
                  ]]
              ]);
  };
  if (!Models.Role.canComment(role)) {
    return null;
  }
  var tmp;
  if (typeof users === "object") {
    if (users.NAME === "Errored") {
      tmp = "Could not load schema members.";
    } else {
      var tmp$1 = {
        value: input,
        onChange: (function (value) {
            Curry._1(setInput, (function (param) {
                    return value;
                  }));
            return Belt_Option.forEach(onChange, (function (onChange) {
                          return Curry._1(onChange, value);
                        }));
          }),
        onSubmit: (function (param) {
            handleComment(input);
            Curry._1(setInput, (function (param) {
                    return new QuillDelta();
                  }));
            return Belt_Option.forEach(onChange, (function (onChange) {
                          return Curry._1(onChange, new QuillDelta());
                        }));
          }),
        users: users.VAL,
        events: events,
        schemaId: schemaId,
        availability: availability,
        onNeedsUpgrade: onNeedsUpgrade,
        mini: mini,
        id: threadId,
        placeholder: "Write a comment on this " + ((
            itemType === "Integration" ? "integration" : (
                itemType === "Property" ? "property" : (
                    itemType === "Event" ? "event" : (
                        itemType === "PropertyGroup" ? "property bundle" : (
                            itemType === "Goal" ? "category" : (
                                itemType === "Metric" ? "metric" : "branch"
                              )
                          )
                      )
                  )
              )
          ) + "...")
      };
      if (autoFocus !== undefined) {
        tmp$1.autoFocus = Caml_option.valFromOption(autoFocus);
      }
      tmp = React.createElement(QuillEditor.make, tmp$1);
    }
  } else {
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.padding2(Css.px(8), Css.px(12)),
                tl: /* [] */0
              })
        }, React.createElement(LoadingCircle.make, {
              color: Styles.Color.grey40
            }));
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("flexStart"),
                      tl: {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.backgroundColor(mini ? "transparent" : Styles.Color.grey20),
                          tl: {
                            hd: Css.padding2(Css.px(5), Css.px(mini ? 6 : 18)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: {
                              hd: Css.height(Css.px(44)),
                              tl: {
                                hd: Css.padding2(Css.px(0), Css.px(5)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, typeof users === "object" ? (
                    users.NAME === "Errored" ? Pervasives.failwith("User not found") : Belt_Option.mapWithDefault(Belt_Array.getBy(users.VAL, (function (user) {
                                  return user.id === userId;
                                })), "User not found", (function (user) {
                              return React.createElement(ProfilePhoto.make, {
                                          user: user
                                        });
                            }))
                  ) : React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.width(Css.px(28)),
                              tl: {
                                hd: Css.height(Css.px(28)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.grey40),
                                  tl: {
                                    hd: Css.borderRadius(Css.pct(100)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      })), availability === "Available" ? null : React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginTop(Css.px(12)),
                          tl: {
                            hd: Css.marginLeft(Css.px(8)),
                            tl: {
                              hd: Css.marginRight(Css.px(12)),
                              tl: {
                                hd: Css_Legacy_Core.SVG.fill("transparent"),
                                tl: {
                                  hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey70),
                                  tl: {
                                    hd: Css.zIndex(1),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }),
                    onClick: (function (param) {
                        return Curry._1(globalSend, {
                                    TAG: /* OpenModal */4,
                                    _0: {
                                      NAME: "BillingPrompt",
                                      VAL: "AddComment"
                                    }
                                  });
                      })
                  }, React.createElement(PlanLightning.make, {
                        tooltipText: availability === "AvailableDuringTrial" ? "Collaboration is available during trial and on the " + (Workspace.printPlanName(availableOnPlan.name) + " plan") : "Collaboration is available on the " + (Workspace.printPlanName(availableOnPlan.name) + " plan. "),
                        tooltipDirection: "right"
                      })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.width(Css.pct(100)),
                            tl: {
                              hd: Css.maxWidth(Css_Legacy_Core.Calc.$neg(Css.pct(100), Css.px(42))),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, tmp));
}

var make = CommentInput;

exports.make = make;
/* Css Not a pure module */
