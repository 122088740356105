// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Title = require("../Title.bs.js");
var React = require("react");
var Models = require("../Models.bs.js");
var Router = require("../Router.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var Shortid = require("shortid");
var AnalyticsRe = require("../analyticsRe.bs.js");
var NamedBranch = require("../NamedBranch.bs.js");
var AnalyticsUtils = require("../analyticsUtils.bs.js");
var CreateButtonIcon = require("./CreateButtonIcon.bs.js");
var EmptyStatesStyles = require("./EmptyStatesStyles.bs.js");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");
var MetricsEmptyIllustration = require("./MetricsEmptyIllustration.bs.js");

function MetricsEmpty(Props) {
  var sendActions = Props.sendActions;
  var role = Props.role;
  var schemaGroup = SchemaGroupContext.use(undefined);
  React.useEffect((function () {
          AnalyticsRe.emptyStateDisplayed(schemaGroup, "Metrics", schemaGroup.schemaId);
          
        }), []);
  return React.createElement("main", {
              className: EmptyStatesStyles.rootStyles
            }, React.createElement("div", {
                  className: EmptyStatesStyles.illustrationStyles
                }, React.createElement(MetricsEmptyIllustration.make, {})), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement(Title.make, {
                  children: "Use Metrics to map your tracking journey",
                  size: "Medium"
                }), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement($$Text.make, {
                  size: "Large",
                  color: Styles.Color.grey70,
                  maxWidth: Css.px(520),
                  children: null
                }, "Whether it's a Funnel, Segmentation, Retention, or Proportion - Avo enables you to map out exactly what you want to track. Read more about Metrics in the ", React.createElement("a", {
                      className: EmptyStatesStyles.linkStyles,
                      href: "https://www.avo.app/docs/workspace/tracking-plan/metrics",
                      rel: "noopener",
                      target: "_blank"
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "Avo docs"
                        })), "."), Models.Role.canEdit(role) ? React.createElement("div", {
                    className: EmptyStatesStyles.actionsStyles
                  }, React.createElement("button", {
                        className: EmptyStatesStyles.actionStyles,
                        onClick: (function (param) {
                            AnalyticsRe.emptyStateInteracted(schemaGroup, "Metrics", "Setup", undefined);
                            var metricId = Shortid();
                            return Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        (function (branch) {
                                            Router.Schema.pushDrawerItem(undefined, {
                                                  NAME: "metric",
                                                  VAL: [
                                                    metricId,
                                                    undefined
                                                  ]
                                                });
                                            return AnalyticsRe.metricInitiated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), metricId, "", NamedBranch.getId(branch), schemaGroup.schemaId);
                                          }),
                                        undefined,
                                        [[
                                            {
                                              NAME: "AddMetric",
                                              VAL: metricId
                                            },
                                            {
                                              metricId: metricId,
                                              metricQuery: metricId
                                            }
                                          ]]
                                      ]);
                          })
                      }, React.createElement("div", {
                            className: EmptyStatesStyles.iconStyles
                          }, CreateButtonIcon.icon), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.grey80,
                            children: "Create a Metric"
                          }))) : null);
}

var make = MetricsEmpty;

exports.make = make;
/* Css Not a pure module */
