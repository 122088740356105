// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var _map = {"OldestFirst":"Oldest First","NewestFirst":"Newest First","Alphabetical":"Alphabetical","NoSorting":"No Sorting"};

var _revMap = {"Oldest First":"OldestFirst","Newest First":"NewestFirst","Alphabetical":"Alphabetical","No Sorting":"NoSorting"};

function eventSortToJs(param) {
  return _map[param];
}

function eventSortFromJs(param) {
  return _revMap[param];
}

var _map$1 = {"NoSorting":"No Sorting","Alphabetical":"Alphabetical"};

var _revMap$1 = {"No Sorting":"NoSorting","Alphabetical":"Alphabetical"};

function metricsSortToJs(param) {
  return _map$1[param];
}

function metricsSortFromJs(param) {
  return _revMap$1[param];
}

var metricsSortOptions = {
  hd: "NoSorting",
  tl: {
    hd: "Alphabetical",
    tl: /* [] */0
  }
};

exports.eventSortToJs = eventSortToJs;
exports.eventSortFromJs = eventSortFromJs;
exports.metricsSortToJs = metricsSortToJs;
exports.metricsSortFromJs = metricsSortFromJs;
exports.metricsSortOptions = metricsSortOptions;
/* No side effect */
