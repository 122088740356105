// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var Hooks = require("../Hooks.bs.js");
var React = require("react");
var Select = require("../Select.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var Firebase = require("../../../bs-firestore/src/Firebase.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var LoadingCircle = require("../LoadingCircle.bs.js");
var GetMParticleDataPlanModel = require("../../../shared/models/GetMParticleDataPlanModel.bs.js");

function IntegrationHeaderPlanVersionPicker(Props) {
  var integration = Props.integration;
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var integrationId = Props.integrationId;
  var mParticlePlanVersion = Props.mParticlePlanVersion;
  var handleChange = Props.handleChange;
  var debouncedIntegration = Hooks.useDebounced((function (param) {
          return integration;
        }), 1000);
  var match = React.useState(function () {
        return "Loading";
      });
  var setVersions = match[1];
  var versions = match[0];
  React.useEffect((function () {
          Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).getIdToken().then(function (token) {
                    return fetch(Firebase.apiUrl + "/integration/mParticle/versions", Fetch.RequestInit.make(/* Post */2, [
                                      [
                                        "Accept",
                                        "application/json"
                                      ],
                                      [
                                        "Content-Type",
                                        "application/json"
                                      ],
                                      [
                                        "Authorization",
                                        "Bearer " + token
                                      ]
                                    ], Caml_option.some(JSON.stringify(GetMParticleDataPlanModel.encodeRequestBody({
                                                  schemaId: schemaId,
                                                  branchId: branchId,
                                                  integrationId: integrationId
                                                }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                  }).then(function (prim) {
                  return prim.json();
                }).then(function (response) {
                var response$1 = GetMParticleDataPlanModel.decodeResponseBody(response);
                return Promise.resolve(Curry._1(setVersions, (function (param) {
                                  var error = response$1.error;
                                  if (error !== undefined) {
                                    return {
                                            NAME: "Error",
                                            VAL: error
                                          };
                                  } else {
                                    return {
                                            NAME: "Loaded",
                                            VAL: Belt_Option.getWithDefault(response$1.versions, [])
                                          };
                                  }
                                })));
              });
          
        }), [debouncedIntegration]);
  var match$1 = integration.config;
  if (match$1 === undefined) {
    return null;
  }
  if (match$1.TAG !== /* MParticleDataMaster */5) {
    return null;
  }
  var integrationConfig = match$1._0;
  var isEnabled = integrationConfig.clientId !== undefined && integrationConfig.clientSecret !== undefined && integrationConfig.workspaceId !== undefined ? integrationConfig.planId !== undefined : false;
  return React.createElement(React.Fragment, undefined, typeof versions === "object" ? (
                versions.NAME === "Loaded" ? null : React.createElement($$Text.make, {
                        color: Styles.Color.grapeError,
                        children: "Could not load existing plan versions"
                      })
              ) : React.createElement(LoadingCircle.make, {
                    color: Styles.Color.grey40
                  }), React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement(Select.make, {
                  disabled: !isEnabled,
                  onSelect: (function (version) {
                      switch (version) {
                        case "loading" :
                        case "new" :
                            return Curry._1(handleChange, undefined);
                        default:
                          return Curry._1(handleChange, Caml_format.caml_int_of_string(version));
                      }
                    }),
                  options: Belt_List.concat({
                        hd: [
                          {
                            NAME: "Label",
                            VAL: "Publish to a new plan version"
                          },
                          "new"
                        ],
                        tl: /* [] */0
                      }, typeof versions === "object" ? (
                          versions.NAME === "Loaded" ? Belt_List.fromArray(Belt_Array.reverse(Belt_Array.map(versions.VAL, (function (version) {
                                            return [
                                                    {
                                                      NAME: "Label",
                                                      VAL: "Publish to v. " + String(version)
                                                    },
                                                    String(version)
                                                  ];
                                          })))) : ({
                                hd: [
                                  {
                                    NAME: "Disabled",
                                    VAL: "Could not load existing plan versions"
                                  },
                                  "loading"
                                ],
                                tl: /* [] */0
                              })
                        ) : ({
                            hd: [
                              {
                                NAME: "Disabled",
                                VAL: "Loading existing plan versions..."
                              },
                              "loading"
                            ],
                            tl: /* [] */0
                          })),
                  value: mParticlePlanVersion !== undefined ? String(mParticlePlanVersion) : "new"
                }));
}

var make = IntegrationHeaderPlanVersionPicker;

exports.make = make;
/* Text Not a pure module */
