// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var JsDiff = require("./externals/JsDiff.bs.js");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var IconDescription = require("./IconDescription.bs.js");

function DiffEventDescription(Props) {
  var $$event = Props.event;
  var actions = Props.actions;
  var maybeAction = Belt_List.getByU(actions, (function (action) {
          if (typeof action === "object") {
            return action.NAME === "UpdateEventDescription";
          } else {
            return false;
          }
        }));
  if (maybeAction !== undefined && typeof maybeAction === "object" && maybeAction.NAME === "UpdateEventDescription") {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.regular),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.padding2(Css.px(10), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.margin4(Css.px(2), Css.px(10), Css.px(0), Css.px(0)),
                          tl: /* [] */0
                        })
                  }, React.createElement(IconDescription.make, {
                        size: 14
                      })), React.createElement("div", undefined, JsDiff.renderStringDiff(undefined, undefined, undefined, $$event.description, maybeAction.VAL[1])));
  } else {
    return null;
  }
}

var make = DiffEventDescription;

exports.make = make;
/* Css Not a pure module */
