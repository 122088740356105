// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameUtils = require("../../shared/utils/NameUtils.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var ContextMenu = require("./ContextMenu.bs.js");
var IconLightning = require("./IconLightning.bs.js");
var PlanLightning = require("./PlanLightning.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var IconQuestionMark = require("./IconQuestionMark.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var DiscrepancyContext = require("./DiscrepancyContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.padding(Css.px(0)),
            tl: {
              hd: Css.marginBottom(Css.px(12)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function SourceSettingsEvents$Header(Props) {
  var title = Props.title;
  var eventSourcesAndDestinationsConfigAvailability = Props.eventSourcesAndDestinationsConfigAvailability;
  var source = Props.source;
  var globalSend = GlobalSendContext.use(undefined);
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement(Spacer.make, {
                  width: 12
                }), React.createElement($$Text.make, {
                  element: "H1",
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.grey80,
                  children: title
                }), React.createElement("button", {
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.buttonReset),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.hover({
                                      hd: Css.selector(" p", {
                                            hd: Css.color(Styles.Color.blueSecondary),
                                            tl: /* [] */0
                                          }),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.marginLeft(Css.px(8)),
                                  tl: /* [] */0
                                }
                              }),
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function (param) {
                      return Curry._1(globalSend, eventSourcesAndDestinationsConfigAvailability === "AvailableDuringTrial" || eventSourcesAndDestinationsConfigAvailability === "Available" ? ({
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "SourceEvents",
                                        VAL: source.id
                                      }
                                    }) : ({
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "BillingPrompt",
                                        VAL: "ConfigureEventsPerPlatform"
                                      }
                                    }));
                    })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.blue,
                      children: "Edit"
                    }), eventSourcesAndDestinationsConfigAvailability === "Available" ? null : React.createElement(IconLightning.make, {
                        size: 12
                      })));
}

var Header = {
  rootStyles: rootStyles,
  make: SourceSettingsEvents$Header
};

var containerStyles = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
          tl: {
            hd: Css.maxHeight(Css.px(300)),
            tl: {
              hd: Css.overflow("auto"),
              tl: {
                hd: Css.position("relative"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var emptyStateStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(50)),
      tl: {
        hd: Css.textAlign("center"),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.semi),
          tl: {
            hd: Css.fontSize(Styles.FontSize.regular),
            tl: /* [] */0
          }
        }
      }
    });

var eventRowStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding2(Css.px(16), Css.px(12)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
              tl: /* [] */0
            }
          }
        }
      }
    });

var stickyRowStyles = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(16), Css.px(12)),
      tl: {
        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
        tl: {
          hd: Css.backgroundColor(Styles.Color.grey20),
          tl: {
            hd: Css.top(Css.px(0)),
            tl: {
              hd: Css.position("sticky"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("row"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var includeInCodegenInputStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(0)),
      tl: {
        hd: Css.marginRight(Css.px(8)),
        tl: {
          hd: Css.marginBottom(Css.px(0)),
          tl: {
            hd: Css.marginLeft(Css.px(8)),
            tl: {
              hd: Css.cursor("pointer"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var planLightningStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css_Legacy_Core.SVG.fill("transparent"),
          tl: {
            hd: Css_Legacy_Core.SVG.stroke(Styles.Color.blue),
            tl: {
              hd: Css.cursor("pointer"),
              tl: /* [] */0
            }
          }
        }
      }
    });

function SourceSettingsEvents(Props) {
  var schemaGroup = Props.schemaGroup;
  var model = Props.model;
  var schemaId = Props.schemaId;
  var source = Props.source;
  var addToast = Toast.useAddToast(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var eventSourcesAndDestinationsConfigAvailability = AvoLimits.ConfigEventSourcesAndDestinations.computeAvailability(workspace);
  var workspaceLimits = AvoLimits.computeLimits(workspace, model);
  var sourceEvents = Belt_List.sortU(StateUtils.getSourceEvents(undefined, model, source.id), (function (a, b) {
          var a$1 = a.name;
          var b$1 = b.name;
          if (a$1 > b$1) {
            return 1;
          } else if (a$1 < b$1) {
            return -1;
          } else {
            return 0;
          }
        }));
  var codegenSourceEvents = Belt_List.sortU(StateUtils.getSourceEvents(true, model, source.id), (function (a, b) {
          var a$1 = a.name;
          var b$1 = b.name;
          if (a$1 > b$1) {
            return 1;
          } else if (a$1 < b$1) {
            return -1;
          } else {
            return 0;
          }
        }));
  var hasEventsWithCodegenChecked = Belt_List.length(codegenSourceEvents) > 0;
  var allEventsWithCodegenChecked = Belt_List.length(codegenSourceEvents) === Belt_List.length(sourceEvents);
  var toggleCodegenForEvents = function (codegenEnabled) {
    var codegenSourceEventIds = Belt_SetString.fromArray(Belt_Array.map(Belt_List.toArray(codegenSourceEvents), (function (param) {
                return param.id;
              })));
    var eventsToUpdate = Belt_List.toArray(Belt_List.keep(sourceEvents, (function (param) {
                return codegenEnabled !== Belt_SetString.has(codegenSourceEventIds, param.id);
              })));
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (param) {
                    var numSourceEventsInSchemaImplementedWithAvo = codegenEnabled ? Belt_List.length(sourceEvents) : 0;
                    var numSourceEventsInSchemaNotImplementedWithAvo = !codegenEnabled ? Belt_List.length(sourceEvents) : 0;
                    Belt_Array.forEach(eventsToUpdate, (function (param) {
                            var eventName = param.name;
                            var eventId = param.id;
                            var correctCase = NameUtils.isCorrectCase(expectedEventCase, eventName);
                            return AnalyticsRe.eventUpdated(schemaGroup, AnalyticsRe.Group.source(source.id, AvoConfig.getSourceName(source)), eventId, eventName, "ToggleImplementSourceWithAvo", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Belt_List.length(model.events), undefined, codegenEnabled, numSourceEventsInSchemaNotImplementedWithAvo, numSourceEventsInSchemaImplementedWithAvo, "SourceSettings", param.triggers.length, correctCase, expectedEventCase, eventId, schemaId, schemaGroup.branchId, eventId, undefined, eventName);
                          }));
                    return Curry._1(addToast, {
                                message: "Codegen " + (
                                  codegenEnabled ? "enabled" : "disabled"
                                ) + " on " + eventsToUpdate.length.toString() + " event" + (
                                  eventsToUpdate.length === 1 ? "" : "s"
                                ),
                                toastType: /* Success */0
                              });
                  }),
                undefined,
                Belt_Array.map(eventsToUpdate, (function (param) {
                        var eventId = param.id;
                        return [
                                {
                                  NAME: "ToggleIncludeEventInCodegenForSource",
                                  VAL: [
                                    eventId,
                                    source.id,
                                    codegenEnabled
                                  ]
                                },
                                {
                                  eventId: eventId,
                                  eventQuery: eventId
                                }
                              ];
                      }))
              ]);
  };
  var eventCountString = Belt_List.length(sourceEvents) === Belt_List.length(codegenSourceEvents) ? "all" : Belt_List.length(codegenSourceEvents).toString();
  var multiple = Belt_List.length(sourceEvents) === Belt_List.length(codegenSourceEvents) ? true : Belt_List.length(codegenSourceEvents) > 1;
  var menuOptions = [
    allEventsWithCodegenChecked ? ({
          NAME: "DisabledOption",
          VAL: {
            label: "Enable Codegen for all events",
            onClick: (function (param) {
                
              })
          }
        }) : ({
          NAME: "Option",
          VAL: {
            label: "Enable Codegen for all events",
            onClick: (function (param) {
                return toggleCodegenForEvents(true);
              })
          }
        }),
    hasEventsWithCodegenChecked ? ({
          NAME: "Option",
          VAL: {
            label: "Remove Codegen for " + eventCountString + " event" + (
              multiple ? "s" : ""
            ),
            onClick: (function (param) {
                return toggleCodegenForEvents(false);
              })
          }
        }) : ({
          NAME: "DisabledOption",
          VAL: {
            label: "Remove Codegen for all events",
            onClick: (function (param) {
                
              })
          }
        })
  ];
  return React.createElement("div", undefined, React.createElement(SourceSettingsEvents$Header, {
                  title: "Events",
                  eventSourcesAndDestinationsConfigAvailability: eventSourcesAndDestinationsConfigAvailability,
                  source: source
                }), React.createElement("div", {
                  className: containerStyles
                }, sourceEvents ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                            className: stickyRowStyles
                          }, React.createElement("div", undefined, React.createElement($$Text.make, {
                                    size: "Medium",
                                    weight: "Semi",
                                    color: Styles.Color.grey90,
                                    children: "Sent from " + AvoConfig.getSourceName(source)
                                  }), React.createElement($$Text.make, {
                                    size: "Small",
                                    color: Styles.Color.grey70,
                                    children: String(Belt_List.length(sourceEvents)) + " events documented, " + String(Belt_List.length(codegenSourceEvents)) + " events included in Codegen"
                                  })), React.createElement(Spacer.make, {
                                width: 8,
                                grow: 1.0
                              }), React.createElement(ContextMenu.make, {
                                options: menuOptions
                              })), Belt_List.toArray(Belt_List.mapU(sourceEvents, (function ($$event) {
                                  var match = AvoLimits.CodegenEvents.entityStatus(workspaceLimits, $$event.id);
                                  var codegenAvailability = match[0];
                                  return React.createElement("div", {
                                              key: $$event.id,
                                              className: eventRowStyles
                                            }, React.createElement(Link.make, {
                                                  path: Router.Link.addDrawerItem(undefined, {
                                                        NAME: "event",
                                                        VAL: [
                                                          $$event.id,
                                                          undefined,
                                                          undefined,
                                                          false
                                                        ]
                                                      }),
                                                  onClick: (function (param) {
                                                      return Curry._1(globalSend, /* CloseSlideOver */0);
                                                    }),
                                                  children: React.createElement("div", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.color(Styles.Color.grey70),
                                                              tl: {
                                                                hd: Css.hover({
                                                                      hd: Css.color(Styles.Color.grey100),
                                                                      tl: /* [] */0
                                                                    }),
                                                                tl: /* [] */0
                                                              }
                                                            })
                                                      }, React.createElement($$Text.make, {
                                                            size: "Medium",
                                                            weight: "Semi",
                                                            children: $$event.name
                                                          }))
                                                }), React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.display("flex"),
                                                        tl: {
                                                          hd: Css.alignItems("center"),
                                                          tl: /* [] */0
                                                        }
                                                      })
                                                }, codegenAvailability === "Available" ? null : (
                                                    codegenAvailability === "AvailableDuringTrial" ? React.createElement("a", {
                                                            className: planLightningStyles,
                                                            onClick: (function (param) {
                                                                return Curry._1(globalSend, {
                                                                            TAG: /* OpenModal */4,
                                                                            _0: {
                                                                              NAME: "BillingPrompt",
                                                                              VAL: "ImplementWithAvo"
                                                                            }
                                                                          });
                                                              })
                                                          }, React.createElement(PlanLightning.make, {
                                                                tooltipText: "Available During Trial"
                                                              }), React.createElement(Spacer.make, {
                                                                width: 4
                                                              })) : React.createElement("a", {
                                                            className: planLightningStyles,
                                                            onClick: (function (param) {
                                                                return Curry._1(globalSend, {
                                                                            TAG: /* OpenModal */4,
                                                                            _0: {
                                                                              NAME: "BillingPrompt",
                                                                              VAL: "ImplementWithAvo"
                                                                            }
                                                                          });
                                                              })
                                                          }, React.createElement(PlanLightning.make, {
                                                                tooltipText: "Requires Plan Upgrade"
                                                              }), React.createElement(Spacer.make, {
                                                                width: 4
                                                              }))
                                                  ), React.createElement("label", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.cursor("pointer"),
                                                            tl: /* [] */0
                                                          }),
                                                      htmlFor: "include-in-codegen-" + $$event.id + "-" + source.id
                                                    }, React.createElement($$Text.make, {
                                                          size: "Small",
                                                          weight: "Regular",
                                                          children: "Implement with Codegen"
                                                        })), React.createElement("input", {
                                                      className: includeInCodegenInputStyles,
                                                      id: "include-in-codegen-" + $$event.id + "-" + source.id,
                                                      checked: Belt_List.someU($$event.includeSources, (function (includedSource) {
                                                              if (includedSource.id === source.id) {
                                                                return includedSource.includeInCodegen;
                                                              } else {
                                                                return false;
                                                              }
                                                            })),
                                                      type: "checkbox",
                                                      onChange: (function (domEvent) {
                                                          var includeInCodegen = domEvent.target.checked;
                                                          if (includeInCodegen && codegenAvailability === "Unavailable") {
                                                            Curry._1(globalSend, {
                                                                  TAG: /* OpenModal */4,
                                                                  _0: {
                                                                    NAME: "BillingPrompt",
                                                                    VAL: "ImplementWithAvo"
                                                                  }
                                                                });
                                                          } else {
                                                            Curry.app(sendActions, [
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  [[
                                                                      {
                                                                        NAME: "ToggleIncludeEventInCodegenForSource",
                                                                        VAL: [
                                                                          $$event.id,
                                                                          source.id,
                                                                          includeInCodegen
                                                                        ]
                                                                      },
                                                                      {
                                                                        eventId: $$event.id,
                                                                        eventQuery: $$event.id,
                                                                        sourceId: source.id,
                                                                        sourceQuery: source.id
                                                                      }
                                                                    ]]
                                                                ]);
                                                          }
                                                          var match = AnalyticsUtils.countSourceEventsImplementWithAvoAfterCurrentChange(model, source.id, includeInCodegen);
                                                          var correctCase = NameUtils.isCorrectCase(expectedEventCase, $$event.name);
                                                          AnalyticsRe.eventUpdated(schemaGroup, AnalyticsRe.Group.source(source.id, AvoConfig.getSourceName(source)), $$event.id, $$event.name, "ToggleImplementSourceWithAvo", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Belt_List.length(model.events), undefined, includeInCodegen, match[1], match[0], "SourceSettings", $$event.triggers.length, correctCase, expectedEventCase, $$event.id, schemaId, schemaGroup.branchId, $$event.id, undefined, $$event.name);
                                                          
                                                        })
                                                    }), React.createElement("a", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.height(Css.px(16)),
                                                            tl: {
                                                              hd: Css.marginTop(Css.px(-1)),
                                                              tl: /* [] */0
                                                            }
                                                          }),
                                                      href: "https://www.avo.app/docs/implementation/devs-101#a-nameavo-generated-filea-avo-generated-file-containing-avo-functions",
                                                      rel: "noopener",
                                                      target: "_blank"
                                                    }, React.createElement(IconQuestionMark.make, {
                                                          color: Styles.Color.grey70
                                                        }))));
                                })))) : React.createElement("div", {
                        className: emptyStateStyles
                      }, "To start sending events from this source you can add them here. You can also configure from which sources an event is sent in the Tracking Plan tab.")));
}

var make = SourceSettingsEvents;

exports.Header = Header;
exports.containerStyles = containerStyles;
exports.emptyStateStyles = emptyStateStyles;
exports.eventRowStyles = eventRowStyles;
exports.stickyRowStyles = stickyRowStyles;
exports.includeInCodegenInputStyles = includeInCodegenInputStyles;
exports.planLightningStyles = planLightningStyles;
exports.make = make;
/* rootStyles Not a pure module */
