// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Icon = require("../Icon.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var Mantine = require("../Mantine.bs.js");
var Tooltip = require("../Tooltip.bs.js");
var Markdown = require("../Markdown.bs.js");
var Tooltips = require("../markdown/tooltips/tooltips.bs.js");
var SnippetUtils = require("./SnippetUtils.bs.js");
var EventImplementationSnippet = require("./EventImplementationSnippet.bs.js");

function EventImplementation(Props) {
  var branchStatus = Props.branchStatus;
  var $$event = Props.event;
  var viewTypeOpt = Props.viewType;
  var model = Props.model;
  var viewType = viewTypeOpt !== undefined ? viewTypeOpt : /* AllSources */0;
  var match = Curry._1(Mantine.Hooks.Intersection.useWithoutOptions, undefined);
  var entry = match.entry;
  var containerRef = React.useRef(null);
  SnippetUtils.useScrollIntoView($$event.id, "Event", 100, containerRef);
  var isIntersecting = React.useMemo((function () {
          if (entry !== undefined) {
            return entry.isIntersecting;
          } else {
            return false;
          }
        }), [entry]);
  return React.createElement("span", {
              ref: containerRef
            }, React.createElement("section", {
                  ref: match.ref,
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.maxWidth(Css.px(700)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.paddingLeft(Css.px(32)),
                            tl: {
                              hd: Css.paddingRight(Css.px(16)),
                              tl: {
                                hd: Css.paddingTop(Css.px(2)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement(Icon.make, {
                          type_: "code",
                          color: Styles.Color.grey40
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100.0)),
                            tl: {
                              hd: Css.marginRight(Css.px(41)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("header", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              children: "Tracking Code"
                            }), React.createElement(Tooltip.WithAnalytics.make, {
                              fromItem: {
                                itemId: $$event.id,
                                itemName: $$event.name,
                                itemType: "Event"
                              },
                              title: "Tracking Code",
                              children: React.createElement(Tooltip.Content.make, {
                                    children: React.createElement(Markdown.make, {
                                          source: Tooltips.trackingCode
                                        })
                                  })
                            })), React.createElement(Spacer.make, {
                          height: 16
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.maxWidth(Css.px(595)),
                                tl: /* [] */0
                              })
                        }, isIntersecting ? React.createElement(EventImplementationSnippet.make, {
                                branchStatus: branchStatus,
                                eventDiffState: "updated",
                                event: $$event,
                                toModel: model,
                                pageName: "Event",
                                viewType: viewType,
                                diffViewType: "noDiff"
                              }) : React.createElement($$Text.make, {
                                size: "Medium",
                                color: Styles.Color.grey70,
                                children: "Loading..."
                              })))));
}

var make = EventImplementation;

exports.make = make;
/* Css Not a pure module */
