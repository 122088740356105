// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Caml = require("rescript/lib/js/caml.js");
var Dopt = require("./externals/Dopt.bs.js");
var Icon = require("./Icon.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var $$Window = require("./externals/window.bs.js");
var Mantine = require("./Mantine.bs.js");
var Belt_Map = require("rescript/lib/js/belt_Map.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Intercom = require("./externals/intercom.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var ModelStore = require("./ModelStore.bs.js");
var TextButton = require("./TextButton.bs.js");
var React$1 = require("@dopt/react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ProductTour = require("./ProductTour.bs.js");
var RouterStore = require("./RouterStore.bs.js");
var Core = require("@mantine/core");
var Hooks$1 = require("@mantine/hooks");
var Belt_SortArray = require("rescript/lib/js/belt_SortArray.js");
var InspectorStats = require("./inspector/InspectorStats.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var InspectorIssuesStore = require("./inspector/InspectorIssuesStore.bs.js");
var InspectorIssuesFilter = require("../../shared/models/InspectorIssuesFilter.bs.js");
var InspectorIssuesFilterHooks = require("./inspector/InspectorIssuesFilterHooks.bs.js");
var InspectorIssuesFilterUtils = require("../../shared/utils/InspectorIssuesFilterUtils.bs.js");

function SetupIssuesAlertsFlow$WhichTourStep(Props) {
  var continueTour = Props.continueTour;
  var onClose = Props.onClose;
  var takeIssuesTour = Props.takeIssuesTour;
  var match = ProductTour.TourProvider.use(undefined);
  var thisBlock = Belt_Array.getBy(match.blocks, (function (block) {
          return block.sid.endsWith("pre-step-2");
        }));
  if (thisBlock !== undefined) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.backgroundColor(Styles.Color.grey80),
                      tl: {
                        hd: Css.padding(Css.px(32)),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.maxWidth(Css.px(360)),
                            tl: {
                              hd: Css.position("relative"),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    })
              }, React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.top(Css.px(16)),
                            tl: {
                              hd: Css.right(Css.px(16)),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, React.createElement(Button.make, {
                        icon: "close",
                        onClick: (function (param) {
                            return Curry._1(onClose, undefined);
                          }),
                        size: "small",
                        style: {
                          NAME: "custom",
                          VAL: [
                            Styles.Color.grey40,
                            Styles.Color.grey10,
                            false
                          ]
                        }
                      })), React.createElement($$Text.make, {
                    size: "Large",
                    weight: "Semi",
                    color: Styles.Color.grey20,
                    children: ProductTour.extractField(thisBlock, "title")
                  }), React.createElement(Spacer.make, {
                    height: 16
                  }), React.createElement($$Text.make, {
                    element: "Div",
                    size: "Medium",
                    color: Styles.Color.grey20,
                    children: ProductTour.extractField(thisBlock, "body-1")
                  }), React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: {
                                  hd: Css.gap(Css.px(16)),
                                  tl: {
                                    hd: Css.paddingTop(Css.px(32)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(Button.make, {
                        icon: "presentation",
                        label: Dopt.Block.fieldWithDefault(thisBlock, "cta", "Show me how to set up alerts"),
                        onClick: (function (param) {
                            return Curry._1(continueTour, undefined);
                          }),
                        size: "small"
                      }), React.createElement(Button.make, {
                        icon: "presentation",
                        label: "Tell me more about Inspector Issues",
                        onClick: (function (param) {
                            return Curry._1(takeIssuesTour, undefined);
                          }),
                        size: "small",
                        style: {
                          NAME: "custom",
                          VAL: [
                            Styles.Color.grey40,
                            Styles.Color.grey10,
                            true
                          ]
                        }
                      })));
  } else {
    return null;
  }
}

function SetupIssuesAlertsFlow$PreSteps(Props) {
  var match = React$1.useFlow("inspector-issues-onboarding-flow");
  var issuesTourIntent = match[1];
  var match$1 = ProductTour.StepProvider.use(undefined);
  var transition = match$1.transition;
  var intent = match$1.intent;
  var block = match$1.block;
  var match$2 = React.useState(function () {
        return false;
      });
  var setMounted = match$2[1];
  var match$3 = Hooks.useDisclosure(false);
  var secondPrestepActions = match$3[1];
  var secondPrestepOpened = match$3[0];
  var match$4 = React.useState(function () {
        return null;
      });
  var setCardRef = match$4[1];
  var match$5 = React.useState(function () {
        return null;
      });
  var setDropdownRef = match$5[1];
  Hooks$1.useClickOutside((function (param) {
          return Curry._1(secondPrestepActions.close, undefined);
        }), null, [
        match$4[0],
        match$5[0]
      ]);
  React.useEffect((function () {
          Curry._1(setMounted, (function (param) {
                  return block.state.active;
                }));
          
        }), [block.state.active]);
  return React.createElement(Mantine.Transition.make, {
              transition: "slide-right",
              mounted: match$2[0],
              children: (function (transitionStyle) {
                  return React.createElement(Mantine.Popover.make, {
                              arrowSize: 12,
                              arrowOffset: 36,
                              arrowStyles: {
                                hd: Css.backgroundColor(Styles.Color.grey80),
                                tl: {
                                  hd: Css.boxSizing("unset"),
                                  tl: {
                                    hd: Css.borderColor("transparent"),
                                    tl: {
                                      hd: Css.zIndex(-1),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              },
                              dropdownStyles: {
                                hd: Css.borderRadius(Css.px(6)),
                                tl: {
                                  hd: Css.backgroundColor("transparent"),
                                  tl: {
                                    hd: Css.boxShadow(Styles.Shadow.box),
                                    tl: /* [] */0
                                  }
                                }
                              },
                              opened: secondPrestepOpened,
                              position: "right-end",
                              withArrow: true,
                              zIndex: Styles.ZIndex.aboveAll + 1 | 0,
                              children: null
                            }, React.createElement(Core.Popover.Target, {
                                  children: React.createElement("section", {
                                        ref: (function (ref) {
                                            return Curry._1(setCardRef, (function (param) {
                                                          return ref;
                                                        }));
                                          }),
                                        className: Curry._1(Css.style, {
                                              hd: Css.border(Css.px(1), "solid", Styles.Color.magenta100),
                                              tl: {
                                                hd: Css.borderRadius(Styles.Border.radius),
                                                tl: {
                                                  hd: Css.padding(Css.px(16)),
                                                  tl: {
                                                    hd: Css.paddingRight(Css.px(8)),
                                                    tl: {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.justifyContent("spaceBetween"),
                                                        tl: {
                                                          hd: Css.gap(Css.px(4)),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }),
                                        style: transitionStyle
                                      }, React.createElement("div", undefined, React.createElement($$Text.make, {
                                                size: "Small",
                                                color: Styles.Color.grey20,
                                                children: ProductTour.extractField(block, "title")
                                              }), React.createElement(TextButton.make, {
                                                onClick: (function (param) {
                                                    return Curry._1(secondPrestepActions.toggle, undefined);
                                                  }),
                                                style: "Magenta",
                                                size: "Small",
                                                children: React.createElement("div", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.display("flex"),
                                                            tl: {
                                                              hd: Css.alignItems("center"),
                                                              tl: {
                                                                hd: Icon.styles({
                                                                      hd: Css_Legacy_Core.SVG.fill(Styles.Color.magenta100),
                                                                      tl: {
                                                                        hd: Css.transition({
                                                                              NAME: "ms",
                                                                              VAL: 300.0
                                                                            }, undefined, undefined, "all"),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }),
                                                                tl: {
                                                                  hd: Css.hover({
                                                                        hd: Icon.color(Styles.Color.magentaSecondary),
                                                                        tl: /* [] */0
                                                                      }),
                                                                  tl: {
                                                                    hd: Css.transition({
                                                                          NAME: "ms",
                                                                          VAL: 300.0
                                                                        }, undefined, undefined, "all"),
                                                                    tl: {
                                                                      hd: Css.selector(" .MarkdownBody p", {
                                                                            hd: Css.important(Css.margin(Css.px(0))),
                                                                            tl: /* [] */0
                                                                          }),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          })
                                                    }, React.createElement($$Text.make, {
                                                          element: "Div",
                                                          weight: "Semi",
                                                          children: ProductTour.extractField(block, "cta")
                                                        }), React.createElement(Spacer.make, {
                                                          width: 4
                                                        }), React.createElement(Icon.make, {
                                                          type_: "arrowRight",
                                                          size: "small"
                                                        }))
                                              })), React.createElement(Button.make, {
                                            icon: "close",
                                            onClick: (function (param) {
                                                return Belt_Option.forEach(intent, (function (intent) {
                                                              intent.reset();
                                                              intent.finish();
                                                              
                                                            }));
                                              }),
                                            size: "tiny",
                                            style: {
                                              NAME: "custom",
                                              VAL: [
                                                Styles.Color.grey40,
                                                Styles.Color.grey20,
                                                false
                                              ]
                                            }
                                          }))
                                }), React.createElement(Mantine.Popover.Dropdown.make, {
                                  children: React.createElement("span", {
                                        ref: (function (ref) {
                                            return Curry._1(setDropdownRef, (function (param) {
                                                          return ref;
                                                        }));
                                          })
                                      }, React.createElement(SetupIssuesAlertsFlow$WhichTourStep, {
                                            continueTour: (function (param) {
                                                Curry._1(secondPrestepActions.close, undefined);
                                                return Curry._1(transition, "next");
                                              }),
                                            onClose: secondPrestepActions.close,
                                            takeIssuesTour: (function (param) {
                                                Curry._1(secondPrestepActions.close, undefined);
                                                issuesTourIntent.reset({force: true});
                                                issuesTourIntent.start({force: true});
                                                
                                              })
                                          }))
                                }));
                })
            });
}

function SetupIssuesAlertsFlow$IssuesWrapper(Props) {
  var stepId = Props.stepId;
  var arrowOffset = Props.arrowOffset;
  var delay = Props.delay;
  var elementClickable = Props.elementClickable;
  var elementPadding = Props.elementPadding;
  var lockBackground = Props.lockBackground;
  var offset = Props.offset;
  var onClick = Props.onClick;
  var onStepActive = Props.onStepActive;
  var onTransition = Props.onTransition;
  var position = Props.position;
  var positionDependencies = Props.positionDependencies;
  var simulateClick = Props.simulateClick;
  var stepType = Props.stepType;
  var transition = Props.transition;
  var transitionDuration = Props.transitionDuration;
  var transitions = Props.transitions;
  var waitForId = Props.waitForId;
  var withOutline = Props.withOutline;
  var children = Props.children;
  var match = InspectorIssuesStore.useFilteredIssues(true, undefined);
  var issues = match.issues;
  var loadingState = match.loadingState;
  var onTransition$1 = Belt_Option.map(onTransition, (function (onTransition) {
          return Curry._1(onTransition, issues);
        }));
  var positionDependencies$1 = Belt_Option.map(positionDependencies, (function (positionDependencies) {
          return Curry._1(positionDependencies, loadingState);
        }));
  var tmp = {
    stepId: stepId,
    stepType: stepType,
    transitions: Curry._2(transitions, match.filteredIssues, loadingState),
    children: children
  };
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = Caml_option.valFromOption(arrowOffset);
  }
  if (delay !== undefined) {
    tmp.delay = Caml_option.valFromOption(delay);
  }
  if (elementClickable !== undefined) {
    tmp.elementClickable = Caml_option.valFromOption(elementClickable);
  }
  if (elementPadding !== undefined) {
    tmp.elementPadding = Caml_option.valFromOption(elementPadding);
  }
  if (lockBackground !== undefined) {
    tmp.lockBackground = Caml_option.valFromOption(lockBackground);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onTransition$1 !== undefined) {
    tmp.onTransition = Caml_option.valFromOption(onTransition$1);
  }
  if (onStepActive !== undefined) {
    tmp.onStepActive = Caml_option.valFromOption(onStepActive);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (positionDependencies$1 !== undefined) {
    tmp.positionDependencies = Caml_option.valFromOption(positionDependencies$1);
  }
  if (simulateClick !== undefined) {
    tmp.simulateClick = Caml_option.valFromOption(simulateClick);
  }
  if (transition !== undefined) {
    tmp.transition = Caml_option.valFromOption(transition);
  }
  if (transitionDuration !== undefined) {
    tmp.transitionDuration = Caml_option.valFromOption(transitionDuration);
  }
  if (waitForId !== undefined) {
    tmp.waitForId = Caml_option.valFromOption(waitForId);
  }
  if (withOutline !== undefined) {
    tmp.withOutline = Caml_option.valFromOption(withOutline);
  }
  return React.createElement(ProductTour.Step.make, tmp);
}

function SetupIssuesAlertsFlow$ConditionalProductStepWithIssues(Props) {
  var tourId = Props.tourId;
  var stepId = Props.stepId;
  var arrowOffset = Props.arrowOffset;
  var delay = Props.delay;
  var elementClickable = Props.elementClickable;
  var elementPadding = Props.elementPadding;
  var lockBackground = Props.lockBackground;
  var offset = Props.offset;
  var onClick = Props.onClick;
  var onStepActive = Props.onStepActive;
  var onTransition = Props.onTransition;
  var position = Props.position;
  var positionDependencies = Props.positionDependencies;
  var simulateClick = Props.simulateClick;
  var stepType = Props.stepType;
  var transition = Props.transition;
  var transitionDuration = Props.transitionDuration;
  var transitions = Props.transitions;
  var waitForId = Props.waitForId;
  var withOutline = Props.withOutline;
  var children = Props.children;
  var match = React$1.useBlock(tourId + "." + stepId);
  var opened = match[0].state.active;
  if (!opened) {
    return null;
  }
  var tmp = {
    stepId: stepId,
    stepType: stepType,
    transitions: transitions,
    children: children
  };
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = arrowOffset;
  }
  if (delay !== undefined) {
    tmp.delay = delay;
  }
  if (elementClickable !== undefined) {
    tmp.elementClickable = elementClickable;
  }
  if (elementPadding !== undefined) {
    tmp.elementPadding = elementPadding;
  }
  if (lockBackground !== undefined) {
    tmp.lockBackground = lockBackground;
  }
  if (offset !== undefined) {
    tmp.offset = offset;
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onStepActive !== undefined) {
    tmp.onStepActive = Caml_option.valFromOption(onStepActive);
  }
  if (onTransition !== undefined) {
    tmp.onTransition = Caml_option.valFromOption(onTransition);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (positionDependencies !== undefined) {
    tmp.positionDependencies = Caml_option.valFromOption(positionDependencies);
  }
  if (simulateClick !== undefined) {
    tmp.simulateClick = Caml_option.valFromOption(simulateClick);
  }
  if (transition !== undefined) {
    tmp.transition = Caml_option.valFromOption(transition);
  }
  if (transitionDuration !== undefined) {
    tmp.transitionDuration = transitionDuration;
  }
  if (waitForId !== undefined) {
    tmp.waitForId = waitForId;
  }
  if (withOutline !== undefined) {
    tmp.withOutline = withOutline;
  }
  return React.createElement(SetupIssuesAlertsFlow$IssuesWrapper, tmp);
}

function SetupIssuesAlertsFlow$CustomStep(Props) {
  var id = Props.id;
  var match = ProductTour.StepProvider.use(undefined);
  var transition = match.transition;
  var onComplete = match.onComplete;
  var intent = match.intent;
  var block = match.block;
  var advanceByCTA = match.advanceByCTA;
  var match$1 = ProductTour.TourProvider.use(undefined);
  var resetOnExit = match$1.resetOnExit;
  var match$2 = InspectorIssuesStore.useFilteredIssues(undefined, undefined);
  var loadingState = match$2.loadingState;
  var match$3 = React.useReducer((function (_state, action) {
          return loadingState !== "success";
        }), false);
  var send = match$3[1];
  var displayLoadingStateMessage = match$3[0];
  var displayLoadingStateMessage$1 = React.useMemo((function () {
          if (typeof loadingState === "object") {
            if (loadingState.NAME === "error") {
              return true;
            } else {
              return displayLoadingStateMessage;
            }
          } else if (loadingState === "success") {
            return false;
          } else {
            return displayLoadingStateMessage;
          }
        }), [
        displayLoadingStateMessage,
        loadingState
      ]);
  React.useEffect((function () {
          var timeoutId = setTimeout((function (param) {
                  return Curry._1(send, "checkForDelayedLoading");
                }), 4000);
          return (function (param) {
                    clearTimeout(timeoutId);
                    
                  });
        }), []);
  var tmp = {
    className: Curry._1(Css.style, {
          hd: Css.padding(Css.px(32)),
          tl: {
            hd: Css.paddingRight(Css.px(24)),
            tl: {
              hd: Css.paddingBottom(Css.px(24)),
              tl: {
                hd: Css.maxWidth(Css.px(320)),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: 300.0
                      }, undefined, undefined, "height"),
                  tl: /* [] */0
                }
              }
            }
          }
        })
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  var tmp$1;
  var exit = 0;
  if (typeof loadingState === "object" && loadingState.NAME === "error") {
    tmp$1 = React.createElement(React.Fragment, undefined, "Unfortunately we encountered an error while loading your issues. If this persists upon refresh please ", React.createElement(TextButton.make, {
              onClick: (function (param) {
                  return Intercom.showNewMessage(undefined);
                }),
              style: "Magenta",
              children: "reach out"
            }), " to us.");
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$1 = React.createElement($$Text.make, {
          element: "Em",
          children: "While we load the issues for your workspace feel free to start playing around with the filters."
        });
  }
  return React.createElement("div", tmp, React.createElement($$Text.make, {
                  size: "Large",
                  weight: "Semi",
                  children: ProductTour.extractField(block, "title")
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginRight(Css.px(4)),
                        tl: /* [] */0
                      })
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  children: ProductTour.extractField(block, "body")
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  key: "info",
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.alignItems("flexEnd"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: {
                                hd: Css.transitions({
                                      hd: Css_Legacy_Core.Transition.shorthand({
                                            NAME: "ms",
                                            VAL: 700.0
                                          }, {
                                            NAME: "ms",
                                            VAL: displayLoadingStateMessage$1 ? 0.0 : 300.0
                                          }, undefined, "max-height"),
                                      tl: {
                                        hd: Css_Legacy_Core.Transition.shorthand({
                                              NAME: "ms",
                                              VAL: 300.0
                                            }, {
                                              NAME: "ms",
                                              VAL: displayLoadingStateMessage$1 ? 300.0 : 0.0
                                            }, undefined, "opacity"),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: {
                                  hd: Css.opacity(displayLoadingStateMessage$1 ? 1.0 : 0.0),
                                  tl: {
                                    hd: Css.maxHeight(displayLoadingStateMessage$1 ? Css.px(70) : Css.px(0)),
                                    tl: {
                                      hd: displayLoadingStateMessage$1 ? Styles.emptyStyle : Css.pointerEvents("none"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      element: "Div",
                      size: "Small",
                      color: Styles.Color.grey60,
                      children: tmp$1
                    }), React.createElement(Spacer.make, {
                      height: 16
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.gap(Css.px(8)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, match$1.showStepCount ? React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.grey50,
                            children: String(match.stepNumber) + "/" + String(match$1.totalSteps)
                          }) : null, React.createElement(TextButton.make, {
                          onClick: (function (param) {
                              return Belt_Option.forEach(intent, (function (intent) {
                                            if (resetOnExit) {
                                              intent.reset();
                                            } else {
                                              intent.stop();
                                            }
                                            
                                          }));
                            }),
                          children: React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Regular",
                                children: "Exit Tour"
                              })
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.gap(Css.px(4)),
                              tl: /* [] */0
                            }
                          })
                    }, Belt_Array.map(match.transitions, (function (param) {
                            var key = param.key;
                            var tmp = {
                              disabled: loadingState !== "success",
                              onClick: (function (param) {
                                  if (key === "complete") {
                                    Curry._1(onComplete, undefined);
                                  }
                                  return Curry._1(transition, key);
                                }),
                              style: key === "prev" || key === "previous" || !advanceByCTA && key === "next" ? "ghost" : "primary",
                              key: key
                            };
                            if (param.icon !== undefined) {
                              tmp.icon = Caml_option.valFromOption(param.icon);
                            }
                            if (param.label !== undefined) {
                              tmp.label = param.label;
                            }
                            return React.createElement(Button.make, tmp);
                          })))));
}

function SetupIssuesAlertsFlow(Props) {
  var match = React$1.useFlow("issues-flow-v2");
  var intent = match[1];
  var flow = match[0];
  var match$1 = React$1.useBlock("issues-flow-v2.step-1");
  var filtersBlock = match$1[0];
  var match$2 = InspectorIssuesFilterHooks.useFilters(undefined);
  var groupedFilters = match$2.groupedFilters;
  var match$3 = React.useState(function () {
        return 0;
      });
  var setFilterElements = match$3[1];
  var filterElements = match$3[0];
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var match$4 = WorkspaceContext.use(undefined);
  var inspectorStats = InspectorStats.useStatsWithCache(match$4.id);
  var model = ModelStore.useModel(undefined);
  var setFilters = InspectorIssuesFilterHooks.useSetFilters(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  React.useEffect((function () {
          if (Caml_obj.caml_notequal(schemaRoute, {
                  NAME: "inspector",
                  VAL: "issues"
                }) && flow.state.started && !flow.state.finished && !flow.state.stopped) {
            intent.reset();
          }
          var observer = new MutationObserver((function (mutations) {
                  return Belt_Array.forEach(mutations, (function (mutation) {
                                if (mutation.type !== "childList") {
                                  return ;
                                }
                                var el = $$Window.$$Document.getElementById("issues-filters-group");
                                if (el === undefined) {
                                  return ;
                                }
                                var el$1 = Caml_option.valFromOption(el);
                                return Curry._1(setFilterElements, (function (param) {
                                              return Belt_Array.reduce(el$1.children, 0, (function (acc, el) {
                                                            return acc + el.clientWidth | 0;
                                                          }));
                                            }));
                              }));
                }));
          var el = $$Window.$$Document.getElementById("issues-filters-group");
          if (el !== undefined && filtersBlock.state.active) {
            observer.observe(Caml_option.valFromOption(el), {
                  childList: true
                });
          }
          return (function (param) {
                    observer.disconnect();
                    
                  });
        }), [schemaRoute]);
  return React.createElement(ProductTour.make, {
              resetOnExit: true,
              showStepCount: false,
              tourId: "issues-flow-v2",
              children: null
            }, React.createElement(ProductTour.Step.make, {
                  stepId: "pre-step-1",
                  stepType: /* Custom */1,
                  transitions: [{
                      key: "next",
                      label: undefined,
                      icon: undefined
                    }],
                  children: React.createElement(SetupIssuesAlertsFlow$PreSteps, {})
                }), React.createElement(SetupIssuesAlertsFlow$ConditionalProductStepWithIssues, {
                  tourId: "issues-flow-v2",
                  stepId: "step-1",
                  arrowOffset: 40,
                  lockBackground: false,
                  onStepActive: (function (param) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                  NAME: "inspector",
                                  VAL: "issues"
                                });
                    }),
                  onTransition: (function (issues, _transitionName) {
                      var inspectorSources = Belt_List.toArray(Belt_List.keep(model.sources, (function (source) {
                                  var match = InspectorStats.getSourceStatus(model, source, inspectorStats);
                                  return !(match === 2 || match === 1);
                                })));
                      var countedAvailableFilters = InspectorIssuesFilterUtils.FilterCounter.get(inspectorSources, model, issues);
                      var mostCommonSource = Belt_Option.mapWithDefault(Belt_Map.get(countedAvailableFilters, "source"), [], (function (item) {
                              if (typeof item !== "object") {
                                return [];
                              }
                              if (item.NAME !== "sources") {
                                return [];
                              }
                              var countedSources = item.VAL;
                              if (countedSources.length !== 0) {
                                return Belt_Array.slice(Belt_Array.mapU(Belt_SortArray.stableSortByU(Belt_Array.keepU(countedSources, (function (param) {
                                                          return param[1] > 0;
                                                        })), (function (b, a) {
                                                      return Caml.caml_int_compare(a[1], b[1]);
                                                    })), (function (param) {
                                                  return {
                                                          NAME: "source",
                                                          VAL: param[0]
                                                        };
                                                })), 0, 1);
                              } else {
                                return [];
                              }
                            }));
                      return Curry._1(setFilters, (function (currentFilters) {
                                    if (Caml_obj.caml_equal(currentFilters, [])) {
                                      return mostCommonSource;
                                    } else {
                                      return currentFilters;
                                    }
                                  }));
                    }),
                  position: "right-end",
                  positionDependencies: (function (loadingState) {
                      return Belt_Array.concatMany([
                                  [typeof loadingState === "object" ? "error" : (
                                        loadingState === "loading" ? "loading" : (
                                            loadingState === "success" ? "success" : "initial"
                                          )
                                      )],
                                  [String(filterElements)],
                                  Belt_Array.map(groupedFilters, InspectorIssuesFilter.GroupedFilter.toString)
                                ]);
                    }),
                  simulateClick: "center",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "add-filter-for-issues"
                  },
                  transition: Mantine.Transition.subtleUpSlide,
                  transitionDuration: 400,
                  transitions: (function (filteredIssues, _loadingState) {
                      return [{
                                key: filteredIssues.length !== 0 ? "with-issues" : "no-issues",
                                label: undefined,
                                icon: "arrowRight"
                              }];
                    }),
                  children: React.createElement(SetupIssuesAlertsFlow$CustomStep, {})
                }), React.createElement(ProductTour.Step.make, {
                  stepId: "step-2-with-issues",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "set-up-alerts"
                  },
                  elementClickable: false,
                  elementPadding: 8,
                  onClick: (function (transition) {
                      return Curry._1(transition, "complete");
                    }),
                  onTransition: (function (transitionName) {
                      if (transitionName === "complete") {
                        return Curry._1(globalSend, {
                                    TAG: /* OpenModal */4,
                                    _0: {
                                      NAME: "IssueAlertsSetup",
                                      VAL: undefined
                                    }
                                  });
                      }
                      
                    }),
                  simulateClick: "center",
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "complete",
                      label: "Finish!",
                      icon: undefined
                    }
                  ],
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(ProductTour.Step.make, {
                  stepId: "step-2-no-issues",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "set-up-alerts"
                  },
                  elementClickable: false,
                  elementPadding: 8,
                  onClick: (function (transition) {
                      return Curry._1(transition, "complete");
                    }),
                  onTransition: (function (transitionName) {
                      if (transitionName === "complete") {
                        return Curry._1(globalSend, {
                                    TAG: /* OpenModal */4,
                                    _0: {
                                      NAME: "IssueAlertsSetup",
                                      VAL: undefined
                                    }
                                  });
                      }
                      
                    }),
                  simulateClick: "center",
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "complete",
                      label: "Finish!",
                      icon: undefined
                    }
                  ],
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }));
}

var make = SetupIssuesAlertsFlow;

exports.make = make;
/* Css Not a pure module */
