// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("../Hooks.bs.js");
var React = require("react");
var Styles = require("../styles.bs.js");
var Mantine = require("../Mantine.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core = require("@mantine/core");

function HoverTitle(Props) {
  var label = Props.label;
  var noSpaces = label.split(" ").length === 1;
  var match = Hooks.useDisclosure(false);
  var action = match[1];
  var tmp = {
    onMouseEnter: (function (param) {
        return Curry._1(action.open_, undefined);
      }),
    onMouseLeave: (function (param) {
        return Curry._1(action.close, undefined);
      })
  };
  var tmp$1 = noSpaces ? Curry._1(Css.style, {
          hd: Css.maxWidth({
                NAME: "substract",
                VAL: [
                  Css.pct(100.0),
                  Css.px(300)
                ]
              }),
          tl: /* [] */0
        }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Mantine.Popover.make, {
              opened: match[0],
              position: "top-start",
              children: null
            }, React.createElement(Core.Popover.Target, {
                  children: React.createElement("span", tmp, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            lines: 1,
                            children: label
                          }))
                }), React.createElement(Mantine.Popover.Dropdown.make, {
                  children: React.createElement("span", {
                        className: Curry._1(Css.style, {
                              hd: Css.width("auto"),
                              tl: {
                                hd: Css.whiteSpace("nowrap"),
                                tl: {
                                  hd: Css.padding2(Css.px(3), Css.px(6)),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.grey80),
                                    tl: {
                                      hd: Css.color(Styles.Color.white),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.small),
                                        tl: {
                                          hd: Css.borderRadius(Styles.Border.radius),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.bold),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            element: "Span",
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.grey10,
                            children: label
                          }))
                }));
}

var make = HoverTitle;

exports.make = make;
/* Css Not a pure module */
