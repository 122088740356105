// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Router = require("./Router.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Zustand = require("./Zustand.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var AppVersionsTypes = require("../../shared/models/AppVersionsTypes.bs.js");

function get(limitVersionsPerSource, param) {
  var schemaId = Router.Schema.getSchemaId(undefined);
  var url = "/api/workspaces/" + schemaId + "/inspector/appVersions/latest/v2" + Belt_Option.mapWithDefault(limitVersionsPerSource, "", (function (l) {
          return "?limitVersionsPerSource=" + String(l);
        }));
  return Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
              return fetch(url, Fetch.RequestInit.make(/* Get */0, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + token
                                    ]
                                  ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
                            return prim.json();
                          }).then(AppVersionsTypes.Decode.response);
            });
}

var StoreConfig = {};

var ZustandStore = Zustand.MakeEqualityFnStore(StoreConfig, Zustand.Compare.Default);

var store = Curry._1(ZustandStore.create, (function (set, getStore) {
        return {
                appVersions: undefined,
                latestTimestamp: undefined,
                fetchStatus: "idle",
                fetch: (function (param) {
                    var match = Curry._1(getStore, undefined);
                    if (match.fetchStatus !== "fetching") {
                      set(function (state) {
                            return {
                                    appVersions: state.appVersions,
                                    latestTimestamp: state.latestTimestamp,
                                    fetchStatus: "fetching",
                                    fetch: state.fetch
                                  };
                          });
                      return $$Promise.$$catch(get(undefined, undefined).then(function (appVersions) {
                                      return set(function (state) {
                                                  return {
                                                          appVersions: appVersions,
                                                          latestTimestamp: Date.now(),
                                                          fetchStatus: "success",
                                                          fetch: state.fetch
                                                        };
                                                });
                                    }), (function (param) {
                                    set(function (state) {
                                          return {
                                                  appVersions: state.appVersions,
                                                  latestTimestamp: undefined,
                                                  fetchStatus: "error",
                                                  fetch: state.fetch
                                                };
                                        });
                                    return Promise.resolve(undefined);
                                  }));
                    } else {
                      return Promise.resolve(undefined);
                    }
                  })
              };
      }));

function use(newerThan, takeLast, param) {
  var $$fetch = Curry._2(ZustandStore.use, store, (function (state) {
          return state.fetch;
        }));
  var appVersions = Curry._2(ZustandStore.use, store, (function (state) {
          return state.appVersions;
        }));
  var fetchStatus = Curry._2(ZustandStore.use, store, (function (state) {
          return state.fetchStatus;
        }));
  var latestTimestamp = Curry._2(ZustandStore.use, store, (function (state) {
          return state.latestTimestamp;
        }));
  var newerThan$1 = React.useMemo((function () {
          return Belt_Option.getWithDefault(newerThan, Date.now());
        }), [newerThan]);
  React.useEffect((function () {
          if (latestTimestamp !== undefined && newerThan$1 <= latestTimestamp) {
            
          } else {
            Curry._1($$fetch, undefined);
          }
          
        }), [newerThan$1]);
  return React.useMemo((function () {
                return [
                        takeLast !== undefined ? Belt_MapString.mapU(appVersions, (function (appVersions) {
                                  return Belt_Array.slice(appVersions, 0, takeLast);
                                })) : appVersions,
                        fetchStatus
                      ];
              }), [
              appVersions,
              takeLast,
              fetchStatus
            ]);
}

exports.get = get;
exports.StoreConfig = StoreConfig;
exports.ZustandStore = ZustandStore;
exports.store = store;
exports.use = use;
/* ZustandStore Not a pure module */
