// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var Title = require("./Title.bs.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Sentry = require("./externals/Sentry.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var TextButton = require("./TextButton.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var IconCheckmark = require("./IconCheckmark.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var Debounce = require("lodash/debounce");
var OnboardingConfig = require("./OnboardingConfig.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SlideOverContainer = require("./SlideOverContainer.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var Difference_in_hours = require("date-fns/difference_in_hours");

function writeUserRole(schemaId, jobFunction) {
  var viewerId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("acls").doc(viewerId).set({
              jobFunction: jobFunction
            }, {"merge": true});
}

function wait(delayInMs) {
  return new Promise((function (resolve, param) {
                setTimeout((function (param) {
                        return resolve(undefined);
                      }), delayInMs);
                
              }));
}

var root = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.height(Css.vh(100.0)),
          tl: {
            hd: Css.width(Css.px(380)),
            tl: /* [] */0
          }
        }
      }
    });

var content = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.flexGrow(1.0),
          tl: {
            hd: Css.overflowY("auto"),
            tl: {
              hd: Css.padding(Css.px(40)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function button(selected) {
  return Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("row"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.width(Css.px(230)),
                      tl: {
                        hd: Css.marginTop(Css.px(12)),
                        tl: {
                          hd: Css.padding(Css.px(8)),
                          tl: {
                            hd: Css.cursor("pointer"),
                            tl: {
                              hd: Css.backgroundColor(selected ? Styles.Color.magenta : Styles.Color.white),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", selected ? Styles.Color.magenta : Styles.Color.grey40),
                                tl: {
                                  hd: Css.borderRadius(Css.px(100)),
                                  tl: {
                                    hd: Css.color(selected ? Styles.Color.white : Styles.Color.grey70),
                                    tl: {
                                      hd: Css.transition({
                                            NAME: "ms",
                                            VAL: Styles.Duration.$$short
                                          }, undefined, undefined, "all"),
                                      tl: {
                                        hd: Css.hover(selected ? ({
                                                  hd: Css.backgroundColor(Styles.Color.magentaSecondary),
                                                  tl: {
                                                    hd: Css.borderColor(Styles.Color.magentaSecondary),
                                                    tl: /* [] */0
                                                  }
                                                }) : ({
                                                  hd: Css.borderColor(Styles.Color.magenta),
                                                  tl: {
                                                    hd: Css.color(Styles.Color.magenta),
                                                    tl: /* [] */0
                                                  }
                                                })),
                                        tl: {
                                          hd: Css.focus({
                                                hd: Css.outlineStyle("none"),
                                                tl: {
                                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.setAlpha(Styles.Color.magenta, 0.2))),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function buttonCheck(selected) {
  return Curry._1(Css.style, {
              hd: Css.flexShrink(0.0),
              tl: {
                hd: Css.width(Css.px(24)),
                tl: {
                  hd: Css.height(Css.px(24)),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.marginRight(Css.px(8)),
                          tl: {
                            hd: Css.border(Css.px(1), "solid", selected ? Styles.Color.white : Styles.Color.grey40),
                            tl: {
                              hd: Css.borderRadius(Css.px(100)),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var footer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("spaceBetween"),
              tl: {
                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey30),
                tl: {
                  hd: Css.paddingTop(Css.px(24)),
                  tl: {
                    hd: Css.paddingRight(Css.px(40)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(24)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(40)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var input = Curry._1(Css.merge, {
      hd: $$Text.sizeStyles("Medium"),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.unsafe("appearance", "none"),
              tl: {
                hd: Css.top(Css.px(-1)),
                tl: {
                  hd: Css.right(Css.px(-1)),
                  tl: {
                    hd: Css.position("absolute"),
                    tl: {
                      hd: Css.placeholder({
                            hd: Css.color(Styles.Color.setAlpha(Styles.Color.white, 0.5)),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.padding(Css.px(0)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(39)),
                          tl: {
                            hd: Css.left(Css.px(-1)),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.focus({
                                      hd: Css.outlineStyle("none"),
                                      tl: {
                                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.setAlpha(Styles.Color.magenta, 0.2))),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: {
                                  hd: Css.flexShrink(1.0),
                                  tl: {
                                    hd: Css.flexGrow(1.0),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.color(Styles.Color.white),
                                        tl: {
                                          hd: Css.bottom(Css.px(0)),
                                          tl: {
                                            hd: Css.borderRadius(Css.px(100)),
                                            tl: {
                                              hd: Css.border(Css.px(0), "solid", "transparent"),
                                              tl: {
                                                hd: Css.backgroundColor("transparent"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var Style = {
  root: root,
  content: content,
  button: button,
  buttonCheck: buttonCheck,
  footer: footer,
  input: input
};

function trackOtherUpdate(schemaGroup, jobFunction) {
  return AnalyticsRe.jobFunctionPromptInteraction(schemaGroup, "JobFunctionCustomised", jobFunction, schemaGroup.branchId, schemaGroup.schemaId);
}

var debouncedTrackOtherUpdate = Debounce(trackOtherUpdate, 1000, {
      leading: false,
      trailing: true
    });

function RolePrompt$Content(Props) {
  var schemaGroup = Props.schemaGroup;
  var onDismiss = Props.onDismiss;
  var onFinish = Props.onFinish;
  var selected = Props.selected;
  var setSelected = Props.setSelected;
  var custom = Props.custom;
  var setCustom = Props.setCustom;
  var match = React.useState(function () {
        return false;
      });
  var setFinished = match[1];
  var finished = match[0];
  React.useEffect((function () {
          AnalyticsRe.jobFunctionPromptDisplayed(schemaGroup, schemaGroup.branchId, schemaGroup.schemaId);
          
        }), []);
  var handleFinish = function (param) {
    return Belt_Option.mapWithDefault(selected, undefined, (function (job) {
                  Curry._1(setFinished, (function (param) {
                          return true;
                        }));
                  var jobFunction = job === /* Other */7 ? custom : OnboardingConfig.getJobFunctionLabel(job);
                  $$Promise.$$catch(Promise.all([
                              writeUserRole(schemaGroup.schemaId, jobFunction),
                              wait(500)
                            ]).then(function (param) {
                            Curry._1(onFinish, undefined);
                            AnalyticsRe.jobFunctionPromptCompleted(schemaGroup, Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid, jobFunction, jobFunction, schemaGroup.branchId, schemaGroup.schemaId);
                            return Promise.resolve(undefined);
                          }), (function (error) {
                          window.alert("Hmm, it looks like something went wrong. Please try again!");
                          Sentry.captureException(error, {});
                          Curry._1(setFinished, (function (param) {
                                  return false;
                                }));
                          return Promise.resolve(undefined);
                        }));
                  
                }));
  };
  return React.createElement("article", {
              className: root
            }, React.createElement("div", {
                  className: content
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.grey70,
                      children: "Hi! We’re trying to better understand how different roles are using Avo."
                    }), React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement(Title.make, {
                      children: "What best describes your role?",
                      size: "Medium"
                    }), React.createElement(Spacer.make, {
                      height: 20
                    }), Belt_Array.map(OnboardingConfig.jobFunctions, (function (job) {
                        var selected$1 = Caml_obj.caml_equal(selected, job);
                        var tmp;
                        var exit = 0;
                        if (job >= 7 && selected$1) {
                          tmp = React.createElement("input", {
                                className: input,
                                autoFocus: true,
                                placeholder: OnboardingConfig.getJobFunctionLabel(/* Other */7),
                                value: custom,
                                onChange: (function ($$event) {
                                    var value = $$event.target.value;
                                    Curry._1(setCustom, (function (param) {
                                            return value;
                                          }));
                                    return debouncedTrackOtherUpdate(schemaGroup, value);
                                  })
                              });
                        } else {
                          exit = 1;
                        }
                        if (exit === 1) {
                          tmp = React.createElement($$Text.make, {
                                element: "Span",
                                size: "Medium",
                                weight: "Semi",
                                lineHeight: Css.pct(160.0),
                                children: OnboardingConfig.getJobFunctionLabel(job)
                              });
                        }
                        return React.createElement("div", {
                                    key: OnboardingConfig.getJobFunctionLabel(job),
                                    className: button(selected$1),
                                    role: "button",
                                    tabIndex: -1,
                                    onClick: (function (param) {
                                        Curry._1(setSelected, (function (param) {
                                                return job;
                                              }));
                                        return AnalyticsRe.jobFunctionPromptInteraction(schemaGroup, "JobFunctionSelected", OnboardingConfig.getJobFunctionLabel(job), schemaGroup.branchId, schemaGroup.schemaId);
                                      })
                                  }, React.createElement("div", {
                                        className: buttonCheck(selected$1)
                                      }, React.createElement(IconCheckmark.make, {
                                            size: 14,
                                            color: selected$1 ? Styles.Color.magenta : "transparent"
                                          })), tmp);
                      }))), React.createElement("footer", {
                  className: footer
                }, React.createElement(TextButton.make, {
                      onClick: onDismiss,
                      size: "Medium",
                      children: "Answer later"
                    }), React.createElement(TextButton.make, {
                      onClick: handleFinish,
                      style: "Magenta",
                      size: "Large",
                      disabled: finished || (
                        selected !== undefined ? (
                            selected >= 7 ? custom === "" : false
                          ) : true
                      ),
                      children: finished ? "Thanks!" : "Finish"
                    })));
}

var Content = {
  writeUserRole: writeUserRole,
  wait: wait,
  Style: Style,
  trackOtherUpdate: trackOtherUpdate,
  debouncedTrackOtherUpdate: debouncedTrackOtherUpdate,
  make: RolePrompt$Content
};

function setLastDismissedDate(param) {
  var viewerId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  Firebase.app(undefined).firestore().collection("users").doc(viewerId).set({
        lastRoleDismissedDate: new Date()
      }, {"merge": true});
  
}

function useRoleDialog(schemaId) {
  var match = React.useState(function () {
        return false;
      });
  var setShowDialog = match[1];
  var viewerId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  var user = FirebaseFetcherHooks.useUser(viewerId);
  var member = FirebaseFetcherHooks.useMember(schemaId, viewerId, true);
  React.useEffect((function () {
          if (typeof member === "object" && member.NAME === "Success" && typeof user === "object" && user.NAME === "Success" && Belt_Option.isNone(Caml_option.undefined_to_opt(member.VAL.jobFunction))) {
            var lastDismissedDate = Models.toDateOption(user.VAL.lastRoleDismissedDate);
            if (lastDismissedDate !== undefined) {
              if (Difference_in_hours(new Date(), Caml_option.valFromOption(lastDismissedDate)) > 24.0) {
                Curry._1(setShowDialog, (function (param) {
                        return true;
                      }));
              }
              
            } else {
              Curry._1(setShowDialog, (function (param) {
                      return true;
                    }));
            }
          }
          
        }), [
        member,
        user
      ]);
  var onDismiss = function (param) {
    return Curry._1(setShowDialog, (function (param) {
                  return false;
                }));
  };
  return [
          match[0],
          onDismiss
        ];
}

function RolePrompt(Props) {
  var showDialog = Props.showDialog;
  var closeDialog = Props.closeDialog;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var match = React.useState(function () {
        
      });
  var selected = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var custom = match$1[0];
  Hooks.useIntercomVisibility(!showDialog);
  var onDismiss = function (param) {
    setLastDismissedDate(undefined);
    Curry._1(closeDialog, undefined);
    return AnalyticsRe.jobFunctionPromptInteraction(schemaGroup, "Dismissed", Belt_Option.map(selected, (function (selected) {
                      if (selected >= 7 && custom !== "") {
                        return custom;
                      } else {
                        return OnboardingConfig.getJobFunctionLabel(selected);
                      }
                    })), schemaGroup.branchId, schemaGroup.schemaId);
  };
  return React.createElement(SlideOverContainer.make, {
              onClose: onDismiss,
              slideOver: showDialog ? [
                  React.createElement(RolePrompt$Content, {
                        schemaGroup: schemaGroup,
                        onDismiss: onDismiss,
                        onFinish: closeDialog,
                        selected: selected,
                        setSelected: match[1],
                        custom: custom,
                        setCustom: match$1[1]
                      }),
                  "rolePrompt"
                ] : undefined
            });
}

var make = RolePrompt;

exports.Content = Content;
exports.setLastDismissedDate = setLastDismissedDate;
exports.useRoleDialog = useRoleDialog;
exports.make = make;
/* root Not a pure module */
