// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Canny = require("./externals/canny.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Intercom = require("./externals/intercom.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var QueryString = require("query-string");
var React$1 = require("@sentry/react");

function setFirestoreUser(user) {
  var uid = user.uid;
  $$Promise.$$catch(Firebase.app(undefined).firestore().collection("users").doc(uid).set({
            id: uid,
            email: user.email,
            createdAt: new Date(user.metadata.creationTime),
            lastSignIn: new Date(user.metadata.lastSignInTime)
          }, {"merge": true}), (function (error) {
          console.error("Could not update user", error);
          return Promise.resolve(undefined);
        }));
  var emailDomain = Belt_Option.getWithDefault(Belt_Array.get(user.email.split("@"), 1), "");
  return $$Promise.$$catch(Firebase.app(undefined).firestore().collection("userEmailDomains").doc(emailDomain).set({
                  id: emailDomain
                }, {"merge": true}), (function (error) {
                console.error("Could not update email domains", error);
                return Promise.resolve(undefined);
              }));
}

function useUser(auth) {
  var match = React.useState(function () {
        
      });
  var setUser = match[1];
  var user = match[0];
  var reloadInterval = React.useRef(undefined);
  var updateUser = function (user) {
    return Curry._1(setUser, (function (param) {
                  if (user === undefined) {
                    return "Unauthenticated";
                  }
                  var user$1 = Caml_option.valFromOption(user);
                  if (user$1.emailVerified) {
                    return {
                            NAME: "Authenticated",
                            VAL: user$1
                          };
                  } else {
                    return {
                            NAME: "Unverified",
                            VAL: user$1
                          };
                  }
                }));
  };
  React.useEffect((function () {
          var unsubscribe = Firebase.Auth.onAuthStateChanged(auth, updateUser);
          return (function (param) {
                    return unsubscribe();
                  });
        }), []);
  var prevUser = Hooks.useRetained(user);
  React.useEffect((function () {
          var exit = 0;
          if (user !== undefined) {
            if (typeof user === "object") {
              var variant = user.NAME;
              if (variant === "Unverified") {
                setFirestoreUser(user.VAL);
              } else if (variant === "Authenticated") {
                var user$1 = user.VAL;
                setFirestoreUser(user$1).then(function (param) {
                      return $$Promise.$$catch(Firebase.app(undefined).firestore().collection("users").doc(user$1.uid).get().then(function (snap) {
                                      var userData = snap.data();
                                      var userData$1 = (userData == null) ? undefined : Caml_option.some(userData);
                                      var maybeName = Belt_Option.flatMap(userData$1, (function (userData) {
                                              return Caml_option.nullable_to_opt(userData.name);
                                            }));
                                      React$1.setUser({
                                            id: user$1.uid
                                          });
                                      AnalyticsRe.authenticated(user$1.uid, user$1.email, maybeName !== undefined ? maybeName : Caml_option.nullable_to_opt(user$1.displayName));
                                      var tmp = {
                                        email: user$1.email,
                                        name: Belt_Option.getWithDefault(maybeName, user$1.email),
                                        id: user$1.uid
                                      };
                                      var tmp$1 = Belt_Option.flatMap(userData$1, (function (userData) {
                                              return Belt_Option.map(Caml_option.nullable_to_opt(userData.picture), (function (prim) {
                                                            return prim.url;
                                                          }));
                                            }));
                                      if (tmp$1 !== undefined) {
                                        tmp.avatarURL = Caml_option.valFromOption(tmp$1);
                                      }
                                      var tmp$2 = Belt_Option.flatMap(userData$1, (function (userData) {
                                              return Belt_Option.map(Models.toDateOption(userData.createdAt), (function (prim) {
                                                            return prim.toISOString();
                                                          }));
                                            }));
                                      if (tmp$2 !== undefined) {
                                        tmp.created = Caml_option.valFromOption(tmp$2);
                                      }
                                      Canny.identify({
                                            appID: Canny.appID,
                                            user: tmp
                                          });
                                      return Promise.resolve(undefined);
                                    }), (function (error) {
                                    console.log("Canny error", error);
                                    return Promise.resolve(undefined);
                                  }));
                    });
                user$1.getIdToken().then(function (token) {
                            return fetch(Firebase.apiUrl + "/authenticateIntercom" + (
                                        process.env.NODE_ENV === "development" ? "?dev=true" : ""
                                      ), Fetch.RequestInit.make(/* Post */2, [
                                              [
                                                "Accept",
                                                "application/json"
                                              ],
                                              [
                                                "Content-Type",
                                                "application/json"
                                              ],
                                              [
                                                "Authorization",
                                                "Bearer " + token
                                              ]
                                            ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                          }).then(function (prim) {
                          return prim.json();
                        }).then(Intercom.decodeAuthJson).then(function (intercomAuth) {
                      return Intercom.update({
                                  email: user$1.email,
                                  user_id: intercomAuth.userId,
                                  user_hash: intercomAuth.userHash
                                });
                    });
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
          }
          if (exit === 1 && prevUser !== undefined && typeof prevUser === "object") {
            var variant$1 = prevUser.NAME;
            if ((variant$1 === "Authenticated" || variant$1 === "Unverified") && user === "Unauthenticated") {
              Intercom.shutdown(undefined);
            }
            
          }
          var query = window.location.search.replace("?", "");
          var redirect = QueryString.parse(query).redirect;
          var exit$1 = 0;
          var redirect$1;
          var exit$2 = 0;
          if (prevUser !== undefined) {
            if (typeof prevUser === "object") {
              var variant$2 = prevUser.NAME;
              if (variant$2 === "Unverified") {
                if (user !== undefined) {
                  if (user === "Unauthenticated") {
                    Router.push(undefined, "/");
                  } else {
                    exit$2 = 2;
                  }
                }
                
              } else if (variant$2 === "Authenticated") {
                if (user === "Unauthenticated") {
                  Router.push(undefined, "/");
                }
                
              } else {
                exit$2 = 2;
              }
            } else {
              exit$2 = 2;
            }
          }
          if (exit$2 === 2 && !(redirect == null)) {
            if (typeof prevUser === "object") {
              if (prevUser.NAME === "Unverified" && user !== undefined && typeof user === "object" && user.NAME === "Authenticated") {
                redirect$1 = redirect;
                exit$1 = 1;
              }
              
            } else if (prevUser === "Unauthenticated" && user !== undefined && typeof user === "object" && user.NAME === "Authenticated") {
              redirect$1 = redirect;
              exit$1 = 1;
            }
            
          }
          if (exit$1 === 1) {
            if (redirect$1.startsWith("/")) {
              Router.push(undefined, decodeURIComponent(redirect$1));
            }
            
          }
          var match = reloadInterval.current;
          var exit$3 = 0;
          var user$2;
          if (prevUser !== undefined) {
            if (typeof prevUser === "object") {
              if (prevUser.NAME === "Unverified" && user !== undefined) {
                if (typeof user === "object") {
                  if (user.NAME === "Authenticated" && match !== undefined) {
                    AnalyticsRe.signUpVerifyCompleted(undefined);
                    clearInterval(Caml_option.valFromOption(match));
                  }
                  
                } else if (user === "Unauthenticated" && match !== undefined) {
                  clearInterval(Caml_option.valFromOption(match));
                }
                
              }
              
            } else if (prevUser === "Unauthenticated" && user !== undefined && typeof user === "object" && user.NAME === "Unverified") {
              user$2 = user.VAL;
              exit$3 = 1;
            }
            
          } else if (user !== undefined && typeof user === "object" && user.NAME === "Unverified") {
            user$2 = user.VAL;
            exit$3 = 1;
          }
          if (exit$3 === 1) {
            reloadInterval.current = Caml_option.some(setInterval((function (param) {
                        user$2.reload().then(function (param) {
                              return updateUser(Caml_option.nullable_to_opt(auth.currentUser));
                            });
                        
                      }), 2000));
          }
          
        }), [user]);
  return user;
}

exports.setFirestoreUser = setFirestoreUser;
exports.useUser = useUser;
/* Hooks Not a pure module */
