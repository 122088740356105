// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("../stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var IconChevron = require("../IconChevron.bs.js");
var PropertyUtils = require("../PropertyUtils.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var IconCheckmarkCircle = require("../IconCheckmarkCircle.bs.js");
var PropertyValidationUtils = require("../PropertyValidationUtils.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.cursor("pointer"),
      tl: {
        hd: Css.hover({
              hd: Css.backgroundColor(Styles.Color.grey10),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.transition({
                NAME: "ms",
                VAL: Styles.Duration.$$short
              }, undefined, undefined, "background-color"),
          tl: {
            hd: Css.selector(" td", {
                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var checkboxStyles = Curry._1(Css.style, {
      hd: Css.margin(Css.px(0)),
      tl: {
        hd: Css.cursor("pointer"),
        tl: {
          hd: Css.display("block"),
          tl: /* [] */0
        }
      }
    });

var propertyInfoStyles = Curry._1(Css.style, {
      hd: Css.minWidth(Css.px(0)),
      tl: /* [] */0
    });

var propertyTopStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.marginBottom(Css.px(2)),
          tl: /* [] */0
        }
      }
    });

var propertyTypeStyles = Curry._1(Css.style, {
      hd: Css.margin(Css.px(0)),
      tl: {
        hd: Css.fontFamily(Styles.monoFontFamily),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.medium),
          tl: {
            hd: Css.color(Styles.Color.grey70),
            tl: {
              hd: Css.fontSize(Styles.FontSize.tiny),
              tl: {
                hd: Css.backgroundColor(Styles.Color.grey20),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                  tl: {
                    hd: Css.borderRadius(Css.px(5)),
                    tl: {
                      hd: Css.padding4(Css.px(2), Css.px(4), Css.px(2), Css.px(4)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var propertyMatchesStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignSelf("stretch"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.flexShrink(10000.0),
              tl: {
                hd: Css.minWidth(Css.px(1)),
                tl: {
                  hd: Css.maxWidth(Css.px(500)),
                  tl: {
                    hd: Css.marginTop(Css.px(8)),
                    tl: {
                      hd: Css.marginBottom(Css.px(8)),
                      tl: {
                        hd: Css.marginLeft(Css.px(12)),
                        tl: {
                          hd: Css.marginRight(Css.px(8)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(12)),
                            tl: {
                              hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var propertyMatchStyles = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.monoFontFamily),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.medium),
        tl: /* [] */0
      }
    });

function ImportReviewRow$NoProperties(Props) {
  return React.createElement("tr", {
              className: Curry._1(Css.style, {
                    hd: Css.backgroundColor(Styles.Color.grey20),
                    tl: {
                      hd: Css.selector(" td", {
                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("td", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(12)),
                        tl: /* [] */0
                      })
                }, React.createElement("input", {
                      className: Curry._1(Css.style, {
                            hd: Css.margin(Css.px(0)),
                            tl: {
                              hd: Css.cursor("pointer"),
                              tl: {
                                hd: Css.display("block"),
                                tl: /* [] */0
                              }
                            }
                          }),
                      checked: false,
                      disabled: true,
                      type: "checkbox"
                    })), React.createElement("td", {
                  className: Curry._1(Css.style, {
                        hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                        tl: /* [] */0
                      })
                }), React.createElement("td", {
                  colSpan: 2
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: propertyInfoStyles
                        }, React.createElement("div", {
                              className: propertyTopStyles
                            }, React.createElement($$Text.make, {
                                  element: "Em",
                                  size: "Small",
                                  weight: "Regular",
                                  singleLine: true,
                                  color: Styles.Color.grey70,
                                  children: "No properties"
                                }))))), React.createElement("td", {
                  className: Curry._1(Css.style, {
                        hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                        tl: {
                          hd: Css.paddingLeft(Css.px(12)),
                          tl: {
                            hd: Css.paddingRight(Css.px(12)),
                            tl: {
                              hd: Css.width(Css.pct(100.0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }));
}

var NoProperties = {
  make: ImportReviewRow$NoProperties
};

function ImportReviewRow$Property(Props) {
  var eventId = Props.eventId;
  var property = Props.property;
  var propertyIncluded = Props.propertyIncluded;
  var disabled = Props.disabled;
  var onToggle = Props.onToggle;
  var hasCategories = Props.hasCategories;
  var hasTags = Props.hasTags;
  var hasNameMapping = Props.hasNameMapping;
  var sources = Props.sources;
  var existingPropertyOpt = Props.existingProperty;
  var propertyGroup = Props.propertyGroup;
  var pinnedValueOpt = Props.pinnedValue;
  var existingProperty = existingPropertyOpt !== undefined ? existingPropertyOpt : false;
  var pinnedValue = pinnedValueOpt !== undefined ? Caml_option.valFromOption(pinnedValueOpt) : undefined;
  var match = Belt_MapString.get(property.presence, eventId);
  var matches = property.matches;
  return React.createElement("tr", {
              className: Curry._1(Css.style, {
                    hd: Css.backgroundColor(Styles.Color.grey20),
                    tl: {
                      hd: Css.selector(" td", {
                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("td", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(12)),
                        tl: /* [] */0
                      })
                }, React.createElement("input", {
                      className: Curry._1(Css.style, {
                            hd: Css.margin(Css.px(0)),
                            tl: {
                              hd: Css.cursor("pointer"),
                              tl: {
                                hd: Css.display("block"),
                                tl: /* [] */0
                              }
                            }
                          }),
                      checked: propertyIncluded,
                      disabled: disabled || Belt_Option.isSome(propertyGroup),
                      type: "checkbox",
                      onChange: onToggle
                    })), React.createElement("td", {
                  className: Curry._1(Css.style, {
                        hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                        tl: /* [] */0
                      })
                }), React.createElement("td", {
                  colSpan: (((2 + (
                          hasNameMapping ? 1 : 0
                        ) | 0) + (
                        hasCategories ? 1 : 0
                      ) | 0) + (
                      hasTags ? 1 : 0
                    ) | 0) + sources.length | 0
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: propertyInfoStyles
                        }, React.createElement("div", {
                              className: propertyTopStyles
                            }, propertyGroup !== undefined ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                        size: "Small",
                                        weight: "Semi",
                                        singleLine: true,
                                        color: Styles.Color.grey70,
                                        children: "[" + (propertyGroup.name + "]")
                                      }), React.createElement(Spacer.make, {
                                        width: 8
                                      })) : null, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  singleLine: true,
                                  color: Styles.Color.grey70,
                                  children: property.name
                                }), React.createElement(Spacer.make, {
                                  width: 8
                                }), React.createElement("p", {
                                  className: propertyTypeStyles
                                }, property.list ? "list of " : null, property.type_), React.createElement(Spacer.make, {
                                  width: 4
                                }), React.createElement($$Text.make, {
                                  size: "Tiny",
                                  color: Styles.Color.grey70,
                                  children: match !== undefined && match ? "Sometimes sent" : "Always sent"
                                }), React.createElement(Spacer.make, {
                                  width: 4
                                })), React.createElement($$Text.make, {
                              size: "Tiny",
                              singleLine: true,
                              color: Styles.Color.grey70,
                              children: property.description
                            })), pinnedValue !== undefined ? React.createElement("div", {
                            className: propertyMatchesStyles
                          }, React.createElement($$Text.make, {
                                size: "Tiny",
                                singleLine: true,
                                color: Styles.Color.grey70,
                                children: null
                              }, "pinned to ", React.createElement("span", {
                                    className: propertyMatchStyles
                                  }, PropertyUtils.printPinnedValue(pinnedValue)))) : null, matches.length !== 0 ? React.createElement("div", {
                            className: propertyMatchesStyles
                          }, React.createElement($$Text.make, {
                                size: "Tiny",
                                singleLine: true,
                                color: Styles.Color.grey70,
                                children: null
                              }, "matches ", Belt_Array.mapWithIndexU(matches, (function (index, match_) {
                                      var match = (matches.length - index | 0) - 1 | 0;
                                      return React.createElement(React.Fragment, {
                                                  children: null,
                                                  key: match_
                                                }, React.createElement("span", {
                                                      className: propertyMatchStyles
                                                    }, match_), match !== 0 ? (
                                                    match !== 1 ? ", " : " or "
                                                  ) : null);
                                    })))) : null, existingProperty ? React.createElement("div", {
                            className: propertyMatchesStyles
                          }, React.createElement($$Text.make, {
                                size: "Tiny",
                                weight: "Medium",
                                singleLine: true,
                                color: Styles.Color.mintGreen,
                                children: "Reused from Tracking Plan "
                              })) : null)), React.createElement("td", {
                  className: Curry._1(Css.style, {
                        hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                        tl: {
                          hd: Css.paddingLeft(Css.px(12)),
                          tl: {
                            hd: Css.paddingRight(Css.px(12)),
                            tl: {
                              hd: Css.width(Css.pct(100.0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, Belt_Array.mapU(property.warnings, (function (warning) {
                        return React.createElement($$Text.make, {
                                    size: "Tiny",
                                    weight: "Semi",
                                    color: Styles.Color.orangeSecondary,
                                    children: warning,
                                    key: warning
                                  });
                      }))));
}

var Property = {
  make: ImportReviewRow$Property
};

function ImportReviewRow$Event(Props) {
  var $$event = Props.event;
  var included = Props.included;
  var properties = Props.properties;
  var propertyGroups = Props.propertyGroups;
  var propertyGroupProperties = Props.propertyGroupProperties;
  var onToggle = Props.onToggle;
  var excludedProperties = Props.excludedProperties;
  var sources = Props.sources;
  var hasCategories = Props.hasCategories;
  var hasTags = Props.hasTags;
  var hasNameMapping = Props.hasNameMapping;
  var updatedEventOpt = Props.updatedEvent;
  var onPropertyToggle = Props.onPropertyToggle;
  var model = Props.model;
  var updatedEvent = updatedEventOpt !== undefined ? updatedEventOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setExpanded = match[1];
  var expanded = match[0];
  var propertiesWarningCount = Belt_Array.reduceU(properties, 0, (function (sum, property) {
          return sum + (
                  property.warnings.length !== 0 ? 1 : 0
                ) | 0;
        }));
  return React.createElement(React.Fragment, undefined, React.createElement("tr", {
                  className: rootStyles,
                  onClick: (function (param) {
                      return Curry._1(setExpanded, (function (expanded) {
                                    return !expanded;
                                  }));
                    })
                }, React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding(Css.px(12)),
                            tl: /* [] */0
                          })
                    }, React.createElement("input", {
                          className: checkboxStyles,
                          checked: included,
                          type: "checkbox",
                          onChange: Curry._1(onToggle, $$event.id),
                          onClick: (function (prim) {
                              prim.stopPropagation();
                              
                            })
                        })), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                            tl: {
                              hd: Css.paddingLeft(Css.px(12)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.transform(Css.rotate(Css.deg(expanded ? 0.0 : -90.0))),
                                tl: /* [] */0
                              })
                        }, React.createElement(IconChevron.make, {
                              color: Styles.Color.grey50
                            }))), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.maxWidth(Css.px(200)),
                            tl: {
                              hd: Css.width(Css.pct(20.0)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              singleLine: true,
                              color: included ? Styles.Color.grey90 : Styles.Color.grey50,
                              children: $$event.name
                            }), updatedEvent ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    width: 8
                                  }), React.createElement($$Text.make, {
                                    size: "Tiny",
                                    weight: "Semi",
                                    singleLine: true,
                                    color: included ? Styles.Color.orange : Styles.Color.grey50,
                                    children: "UPDATED"
                                  })) : null)), hasNameMapping ? React.createElement("td", {
                        className: Curry._1(Css.style, {
                              hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: {
                                  hd: Css.width(Css.pct(30.0)),
                                  tl: {
                                    hd: Css.paddingLeft(Css.px(12)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Tiny",
                            color: Styles.Color.grey80,
                            children: Belt_Array.map($$event.nameMapping, (function (param) {
                                      return "Send as " + param[0] + " to " + Belt_Option.getWithDefault(param[1], "Unnamed Destination");
                                    })).join(", ")
                          })) : null, React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                            tl: {
                              hd: Css.maxWidth(Css.px(300)),
                              tl: {
                                hd: Css.width(Css.pct(30.0)),
                                tl: {
                                  hd: Css.paddingRight(Css.px(12)),
                                  tl: {
                                    hd: Css.paddingLeft(Css.px(12)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Tiny",
                          singleLine: true,
                          color: Styles.Color.grey80,
                          children: $$event.description
                        })), hasCategories ? React.createElement("td", {
                        className: Curry._1(Css.style, {
                              hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: {
                                  hd: Css.paddingLeft(Css.px(12)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Tiny",
                            color: Styles.Color.grey80,
                            children: $$event.categories.join(",")
                          })) : null, hasTags ? React.createElement("td", {
                        className: Curry._1(Css.style, {
                              hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: {
                                  hd: Css.paddingLeft(Css.px(12)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Tiny",
                            color: Styles.Color.grey80,
                            children: $$event.tags.join(",")
                          })) : null, Belt_Array.map(sources, (function (source) {
                        return React.createElement("td", {
                                    key: source,
                                    className: Curry._1(Css.style, {
                                          hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                                          tl: {
                                            hd: Css.padding(Css.px(12)),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, Belt_Array.some($$event.sources, (function (eventSource) {
                                          return eventSource === source;
                                        })) ? React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.alignItems("center"),
                                                  tl: {
                                                    hd: Css.justifyContent("center"),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              })
                                        }, React.createElement(IconCheckmarkCircle.make, {
                                              size: 12,
                                              color: Styles.Color.grassGreen
                                            })) : null);
                      })), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                            tl: {
                              hd: Css.paddingLeft(Css.px(12)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: {
                                  hd: Css.width(Css.pct(100)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, propertiesWarningCount > 0 ? React.createElement($$Text.make, {
                            size: "Tiny",
                            weight: "Semi",
                            color: Styles.Color.orangeSecondary,
                            children: propertiesWarningCount !== 1 ? propertiesWarningCount.toString() + " properties with warnings" : "1 property with warnings",
                            key: "propertyWarningCount"
                          }) : null, Belt_Array.mapU($$event.warnings, (function (warning) {
                            return React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        color: Styles.Color.orangeSecondary,
                                        children: warning,
                                        key: warning
                                      });
                          })))), expanded ? (
                $$event.propertyIds.length !== 0 || $$event.propertyGroupIds.length !== 0 || $$event.updatedPropertyIds.length !== 0 ? React.createElement(React.Fragment, undefined, Belt_Array.concatMany(Belt_Array.mapU(propertyGroups, (function (group) {
                                  return Belt_Array.mapU(Belt_Array.keepMapU(group.properties, (function (property) {
                                                    return Belt_Array.getByU(propertyGroupProperties, (function (groupProperty) {
                                                                  return groupProperty.id === property.id;
                                                                }));
                                                  })), (function (property) {
                                                return React.createElement(ImportReviewRow$Property, {
                                                            eventId: $$event.id,
                                                            property: property,
                                                            propertyIncluded: !Belt_Set.has(excludedProperties, [
                                                                  $$event.id,
                                                                  property.id
                                                                ]),
                                                            disabled: !included,
                                                            onToggle: (function (inputEvent) {
                                                                return Curry._3(onPropertyToggle, $$event.id, property.id, inputEvent);
                                                              }),
                                                            hasCategories: hasCategories,
                                                            hasTags: hasTags,
                                                            hasNameMapping: hasNameMapping,
                                                            sources: sources,
                                                            propertyGroup: group,
                                                            key: property.id
                                                          });
                                              }));
                                }))), Belt_Array.keepMapU(Belt_Array.concat($$event.propertyIds, $$event.updatedPropertyIds), (function (param) {
                              var propertyId = param[0];
                              var maybeImportedProperty = Belt_Array.getByU(properties, (function (property) {
                                      return property.id === propertyId;
                                    }));
                              if (maybeImportedProperty !== undefined) {
                                return Caml_option.some(React.createElement(ImportReviewRow$Property, {
                                                eventId: $$event.id,
                                                property: maybeImportedProperty,
                                                propertyIncluded: !Belt_Set.has(excludedProperties, [
                                                      $$event.id,
                                                      maybeImportedProperty.id
                                                    ]),
                                                disabled: !included,
                                                onToggle: (function (inputEvent) {
                                                    return Curry._3(onPropertyToggle, $$event.id, maybeImportedProperty.id, inputEvent);
                                                  }),
                                                hasCategories: hasCategories,
                                                hasTags: hasTags,
                                                hasNameMapping: hasNameMapping,
                                                sources: sources,
                                                pinnedValue: param[1],
                                                key: maybeImportedProperty.id
                                              }));
                              } else {
                                return Belt_Option.flatMapU(StateUtils.getPropertyById(propertyId, model), (function (property) {
                                              if (property.TAG === /* PropertyRef */0) {
                                                return ;
                                              }
                                              var property$1 = property._0;
                                              return Caml_option.some(React.createElement(ImportReviewRow$Property, {
                                                              eventId: $$event.id,
                                                              property: {
                                                                id: property$1.id,
                                                                name: property$1.name,
                                                                uniqueNameIndex: undefined,
                                                                description: property$1.description,
                                                                type_: property$1.type_,
                                                                list: property$1.list,
                                                                matches: Belt_List.toArray(PropertyValidationUtils.getPropertyMatches(property$1)),
                                                                warnings: [],
                                                                presence: undefined,
                                                                sendAs: property$1.sendAs,
                                                                nameMapping: undefined
                                                              },
                                                              propertyIncluded: !Belt_Set.has(excludedProperties, [
                                                                    $$event.id,
                                                                    property$1.id
                                                                  ]),
                                                              disabled: !included,
                                                              onToggle: (function (inputEvent) {
                                                                  return Curry._3(onPropertyToggle, $$event.id, property$1.id, inputEvent);
                                                                }),
                                                              hasCategories: hasCategories,
                                                              hasTags: hasTags,
                                                              hasNameMapping: hasNameMapping,
                                                              sources: sources,
                                                              existingProperty: true,
                                                              key: property$1.id
                                                            }));
                                            }));
                              }
                            }))) : React.createElement(ImportReviewRow$NoProperties, {})
              ) : null);
}

var $$Event = {
  make: ImportReviewRow$Event
};

function ImportReviewRow$UserProperties(Props) {
  var properties = Props.properties;
  var excludedProperties = Props.excludedProperties;
  var onPropertyToggle = Props.onPropertyToggle;
  var onUserPropertiesIncludedToggle = Props.onUserPropertiesIncludedToggle;
  var userPropertiesIncluded = Props.userPropertiesIncluded;
  var match = React.useState(function () {
        return false;
      });
  var setExpanded = match[1];
  var expanded = match[0];
  var propertiesWarningCount = Belt_Array.reduceU(properties, 0, (function (sum, property) {
          return sum + (
                  property.warnings.length !== 0 ? 1 : 0
                ) | 0;
        }));
  var numSelectedUserProperties = userPropertiesIncluded ? Belt_Array.keep(properties, (function (property) {
            return !Belt_SetString.has(excludedProperties, property.id);
          })).length : 0;
  return React.createElement(React.Fragment, undefined, React.createElement("tr", {
                  className: rootStyles,
                  onClick: (function (param) {
                      return Curry._1(setExpanded, (function (expanded) {
                                    return !expanded;
                                  }));
                    })
                }, React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding(Css.px(12)),
                            tl: /* [] */0
                          })
                    }, React.createElement("input", {
                          className: checkboxStyles,
                          checked: userPropertiesIncluded,
                          type: "checkbox",
                          onChange: onUserPropertiesIncludedToggle,
                          onClick: (function (prim) {
                              prim.stopPropagation();
                              
                            })
                        })), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                            tl: {
                              hd: Css.paddingLeft(Css.px(12)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.transform(Css.rotate(Css.deg(expanded ? 0.0 : -90.0))),
                                tl: /* [] */0
                              })
                        }, React.createElement(IconChevron.make, {
                              color: Styles.Color.grey50
                            }))), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.maxWidth(Css.px(200)),
                            tl: {
                              hd: Css.width(Css.pct(20.0)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          singleLine: true,
                          color: userPropertiesIncluded ? Styles.Color.grey90 : Styles.Color.grey50,
                          children: "User Properties (" + numSelectedUserProperties.toString() + ")"
                        })), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.maxWidth(Css.px(300)),
                            tl: {
                              hd: Css.width(Css.pct(30.0)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Tiny",
                          singleLine: true,
                          color: Styles.Color.grey80,
                          children: "The following user properties were found in the import."
                        })), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                            tl: {
                              hd: Css.paddingLeft(Css.px(12)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: {
                                  hd: Css.width(Css.pct(100)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, propertiesWarningCount > 0 ? React.createElement($$Text.make, {
                            size: "Tiny",
                            weight: "Semi",
                            color: Styles.Color.orangeSecondary,
                            children: propertiesWarningCount !== 1 ? propertiesWarningCount.toString() + " properties with warnings" : "1 property with warnings",
                            key: "propertyWarningCount"
                          }) : null)), expanded ? Belt_Array.mapU(properties, (function (property) {
                      return React.createElement(ImportReviewRow$Property, {
                                  eventId: "",
                                  property: property,
                                  propertyIncluded: !Belt_SetString.has(excludedProperties, property.id),
                                  disabled: !userPropertiesIncluded,
                                  onToggle: (function (inputEvent) {
                                      return Curry._2(onPropertyToggle, property.id, inputEvent);
                                    }),
                                  hasCategories: false,
                                  hasTags: false,
                                  hasNameMapping: false,
                                  sources: [],
                                  key: property.id
                                });
                    })) : null);
}

var UserProperties = {
  make: ImportReviewRow$UserProperties
};

function ImportReviewRow$EventPropertiesWithoutEvents(Props) {
  var properties = Props.properties;
  var match = React.useState(function () {
        return false;
      });
  var setExpanded = match[1];
  var expanded = match[0];
  var propertiesWarningCount = Belt_Array.reduceU(properties, 0, (function (sum, property) {
          return sum + (
                  property.warnings.length !== 0 ? 1 : 0
                ) | 0;
        }));
  var numSelectedUserProperties = properties.length;
  return React.createElement(React.Fragment, undefined, React.createElement("tr", {
                  className: rootStyles,
                  onClick: (function (param) {
                      return Curry._1(setExpanded, (function (expanded) {
                                    return !expanded;
                                  }));
                    })
                }, React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding(Css.px(12)),
                            tl: /* [] */0
                          })
                    }, React.createElement("input", {
                          className: checkboxStyles,
                          checked: true,
                          disabled: true,
                          type: "checkbox",
                          onClick: (function (prim) {
                              prim.stopPropagation();
                              
                            })
                        })), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                            tl: {
                              hd: Css.paddingLeft(Css.px(12)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.transform(Css.rotate(Css.deg(expanded ? 0.0 : -90.0))),
                                tl: /* [] */0
                              })
                        }, React.createElement(IconChevron.make, {
                              color: Styles.Color.grey50
                            }))), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.maxWidth(Css.px(200)),
                            tl: {
                              hd: Css.width(Css.pct(20.0)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          singleLine: true,
                          color: Styles.Color.grey90,
                          children: "Event Properties (" + numSelectedUserProperties.toString() + ")"
                        })), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.maxWidth(Css.px(300)),
                            tl: {
                              hd: Css.width(Css.pct(30.0)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Tiny",
                          singleLine: true,
                          color: Styles.Color.grey80,
                          children: "The following event properties were found in the import but are not attached to any events."
                        })), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                            tl: {
                              hd: Css.paddingLeft(Css.px(12)),
                              tl: {
                                hd: Css.paddingRight(Css.px(12)),
                                tl: {
                                  hd: Css.width(Css.pct(100)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, propertiesWarningCount > 0 ? React.createElement($$Text.make, {
                            size: "Tiny",
                            weight: "Semi",
                            color: Styles.Color.orangeSecondary,
                            children: propertiesWarningCount !== 1 ? propertiesWarningCount.toString() + " properties with warnings" : "1 property with warnings",
                            key: "propertyWarningCount"
                          }) : null)), expanded ? Belt_Array.mapU(properties, (function (property) {
                      return React.createElement(ImportReviewRow$Property, {
                                  eventId: "",
                                  property: property,
                                  propertyIncluded: true,
                                  disabled: true,
                                  onToggle: (function (_inputEvent) {
                                      
                                    }),
                                  hasCategories: false,
                                  hasTags: false,
                                  hasNameMapping: false,
                                  sources: [],
                                  key: property.id
                                });
                    })) : null);
}

var EventPropertiesWithoutEvents = {
  make: ImportReviewRow$EventPropertiesWithoutEvents
};

exports.rootStyles = rootStyles;
exports.checkboxStyles = checkboxStyles;
exports.propertyInfoStyles = propertyInfoStyles;
exports.propertyTopStyles = propertyTopStyles;
exports.propertyTypeStyles = propertyTypeStyles;
exports.propertyMatchesStyles = propertyMatchesStyles;
exports.propertyMatchStyles = propertyMatchStyles;
exports.NoProperties = NoProperties;
exports.Property = Property;
exports.$$Event = $$Event;
exports.UserProperties = UserProperties;
exports.EventPropertiesWithoutEvents = EventPropertiesWithoutEvents;
/* rootStyles Not a pure module */
