// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");

var $$Location = {};

var $$Event = {};

var $$DOMRect = {};

function getElementById(id) {
  return Caml_option.nullable_to_opt(document.getElementById(id));
}

var Body = {};

var BodyStyle = {};

function collapse(prim0, prim1) {
  prim0.collapse(prim1);
  
}

var $$Range = {
  collapse: collapse
};

var $$Document = {
  getElementById: getElementById,
  Body: Body,
  BodyStyle: BodyStyle,
  $$Range: $$Range
};

function get(param) {
  var selection = window.getSelection();
  return {
          focusNode: Caml_option.nullable_to_opt(selection.focusNode),
          t: selection
        };
}

var $$Selection = {
  get: get
};

var Style = {};

function replaceStateSilent(path) {
  var history = window.history;
  history.replaceState(history.state, "avo", path);
  
}

function replaceState(path) {
  var history = window.history;
  history.replaceState(history.state, "avo", path);
  var $$event = document.createEvent("Event");
  $$event.initEvent("popstate", true, true);
  window.dispatchEvent($$event);
  
}

var $$History = {
  replaceStateSilent: replaceStateSilent,
  replaceState: replaceState
};

var $$File = {};

var $$FileReader = {};

var $$JSON = {};

var $$Node = {};

function querySelector(el, identifier) {
  return Caml_option.nullable_to_opt(el.querySelector(identifier));
}

var $$Element = {
  querySelector: querySelector
};

var Dom = {
  $$Element: $$Element
};

var $$Image = {};

var $$URL = {};

var $$BeforeUnloadEvent = {};

var $$TextEncoder = {};

var $$TextDecoder = {};

function make(param) {
  return document.createElement("canvas");
}

var Context = {};

var Canvas = {
  make: make,
  Context: Context
};

exports.$$Location = $$Location;
exports.$$Event = $$Event;
exports.$$DOMRect = $$DOMRect;
exports.$$Document = $$Document;
exports.$$Selection = $$Selection;
exports.Style = Style;
exports.$$History = $$History;
exports.$$File = $$File;
exports.$$FileReader = $$FileReader;
exports.$$JSON = $$JSON;
exports.$$Node = $$Node;
exports.Dom = Dom;
exports.$$Image = $$Image;
exports.$$URL = $$URL;
exports.$$BeforeUnloadEvent = $$BeforeUnloadEvent;
exports.$$TextEncoder = $$TextEncoder;
exports.$$TextDecoder = $$TextDecoder;
exports.Canvas = Canvas;
/* No side effect */
