// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function IconSlackEmblem(Props) {
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 20;
  return React.createElement("svg", {
              height: String(size),
              width: String(size),
              fill: "none",
              viewBox: "0 0 20 20",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M4.20196 12.6385C4.20196 13.7948 3.25733 14.7394 2.10098 14.7394C0.944626 14.7394 0 13.7948 0 12.6385C0 11.4821 0.944626 10.5375 2.10098 10.5375H4.20196V12.6385Z",
                  fill: "#E01E5A"
                }), React.createElement("path", {
                  d: "M5.26059 12.6385C5.26059 11.4821 6.20521 10.5375 7.36157 10.5375C8.51792 10.5375 9.46254 11.4821 9.46254 12.6385V17.899C9.46254 19.0554 8.51792 20 7.36157 20C6.20521 20 5.26059 19.0554 5.26059 17.899V12.6385Z",
                  fill: "#E01E5A"
                }), React.createElement("path", {
                  d: "M7.36157 4.20195C6.20521 4.20195 5.26059 3.25733 5.26059 2.10098C5.26059 0.944625 6.20521 0 7.36157 0C8.51792 0 9.46254 0.944625 9.46254 2.10098V4.20195H7.36157Z",
                  fill: "#36C5F0"
                }), React.createElement("path", {
                  d: "M7.36156 5.26056C8.51791 5.26056 9.46254 6.20519 9.46254 7.36154C9.46254 8.51789 8.51791 9.46252 7.36156 9.46252H2.10098C0.944625 9.46252 0 8.51789 0 7.36154C0 6.20519 0.944625 5.26056 2.10098 5.26056H7.36156Z",
                  fill: "#36C5F0"
                }), React.createElement("path", {
                  d: "M15.798 7.36154C15.798 6.20519 16.7427 5.26056 17.899 5.26056C19.0554 5.26056 20 6.20519 20 7.36154C20 8.51789 19.0554 9.46252 17.899 9.46252H15.798V7.36154Z",
                  fill: "#2EB67D"
                }), React.createElement("path", {
                  d: "M14.7394 7.36157C14.7394 8.51792 13.7948 9.46254 12.6384 9.46254C11.4821 9.46254 10.5374 8.51792 10.5374 7.36157V2.10098C10.5374 0.944626 11.4821 0 12.6384 0C13.7948 0 14.7394 0.944626 14.7394 2.10098V7.36157Z",
                  fill: "#2EB67D"
                }), React.createElement("path", {
                  d: "M12.6384 15.7981C13.7948 15.7981 14.7394 16.7427 14.7394 17.8991C14.7394 19.0554 13.7948 20 12.6384 20C11.4821 20 10.5374 19.0554 10.5374 17.8991V15.7981H12.6384Z",
                  fill: "#ECB22E"
                }), React.createElement("path", {
                  d: "M12.6384 14.7394C11.4821 14.7394 10.5374 13.7948 10.5374 12.6385C10.5374 11.4821 11.4821 10.5375 12.6384 10.5375H17.899C19.0554 10.5375 20 11.4821 20 12.6385C20 13.7948 19.0554 14.7394 17.899 14.7394H12.6384Z",
                  fill: "#ECB22E"
                }));
}

var make = IconSlackEmblem;

exports.make = make;
/* react Not a pure module */
