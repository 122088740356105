// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Title = require("./Title.bs.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var SheetUtils = require("./SheetUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RouterStore = require("./RouterStore.bs.js");
var SheetHeader = require("./SheetHeader.bs.js");
var ReactWindow = require("react-window");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var SegmentControl = require("./SegmentControl.bs.js");
var StateFilterUtils = require("./StateFilterUtils.bs.js");
var GlobalStateContext = require("./GlobalStateContext.bs.js");
var PropertiesSheetRow = require("./PropertiesSheetRow.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var ReactVirtualizedAutoSizer = require("react-virtualized-auto-sizer").default;

var headerStyles = Curry._1(Css.style, {
      hd: Css.height(Css.px(64)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingLeft(Css.px(30)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var sheet = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.height({
              NAME: "substract",
              VAL: [
                Css.vh(100),
                Css.px(104)
              ]
            }),
        tl: {
          hd: Css.paddingLeft(Css.px(16)),
          tl: {
            hd: Css.position("relative"),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  headerHeight: 64,
  columnsHeaderHeight: 40,
  itemHeight: 50,
  headerStyles: headerStyles,
  sheet: sheet
};

function printSegmentQueryParam(segmentation, groupTypes) {
  if (segmentation === undefined) {
    return ;
  }
  if (typeof segmentation === "number") {
    switch (segmentation) {
      case /* SystemProperty */0 :
          return "system";
      case /* EventProperty */1 :
          return "event";
      case /* UserProperty */2 :
          return "user";
      
    }
  } else {
    var groupTypeId = segmentation._0;
    return Belt_Option.mapU(Belt_Array.getByU(groupTypes, (function (param) {
                      return param.id === groupTypeId;
                    })), (function (param) {
                  return param.name;
                }));
  }
}

function parseSegmentQueryParam(value, groupTypes) {
  if (value === undefined) {
    return ;
  }
  switch (value) {
    case "event" :
        return /* EventProperty */1;
    case "system" :
        return /* SystemProperty */0;
    case "user" :
        return /* UserProperty */2;
    default:
      return Belt_Option.mapU(Belt_Array.getByU(groupTypes, (function (param) {
                        return param.name === value;
                      })), (function (param) {
                    return /* GroupProperty */{
                            _0: param.id
                          };
                  }));
  }
}

function PropertiesSheet(Props) {
  var currentBranch = Props.currentBranch;
  var globalSend = Props.globalSend;
  var model = Props.model;
  var schema = Props.schema;
  var user = Props.user;
  var viewerRole = Props.viewerRole;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = GlobalStateContext.use(undefined);
  var globalStateFilters = match.filters;
  var currentFilters = AnalyticsUtils.currentFilters(model, globalStateFilters, match.eventSort);
  var filters = Belt_List.fromArray(Belt_Array.keepMapU(globalStateFilters, StateFilterUtils.toModel));
  var sourceFilterNames = StateFilterUtils.getSourceFilterNames(globalStateFilters, model);
  var drawerItems = RouterStore.Schema.useDrawerItems(undefined);
  var match$1 = SheetUtils.Properties.usePropertySheetColumns(user);
  var columns = match$1[0];
  var match$2 = Router.QueryParams.useKey("segment");
  var removeSegmentQueryParam = match$2.remove;
  var setSegmentQueryParam = match$2.set;
  var segmentQueryParam = match$2.value;
  var match$3 = React.useState(function () {
        return parseSegmentQueryParam(segmentQueryParam, model.groupTypes);
      });
  var setSegmentation = match$3[1];
  var segmentation = match$3[0];
  React.useEffect((function () {
          var value = printSegmentQueryParam(segmentation, model.groupTypes);
          if (value !== undefined) {
            Curry._1(setSegmentQueryParam, value);
          } else {
            Curry._1(removeSegmentQueryParam, undefined);
          }
          return (function (param) {
                    return Curry._1(removeSegmentQueryParam, undefined);
                  });
        }), [
        segmentation,
        drawerItems
      ]);
  var match$4 = SheetUtils.Properties.useScrollSync(undefined);
  var sheetRef = match$4.sheetRef;
  var handleReactWindowRef = match$4.handleReactWindowRef;
  var handleOuterRef = match$4.handleOuterRef;
  var items = SheetUtils.Properties.getPropertiesRows(model.properties, model.groupTypes, segmentation);
  var handleSegmentation = React.useCallback((function (newSegmentation) {
          AnalyticsRe.propertiesViewConfigured(schemaGroup, "PropertyTypeSegmentation", SheetUtils.Properties.sendAsToAnalyticsSegmentation(segmentation), SheetUtils.Properties.sendAsToAnalyticsSegmentation(newSegmentation), undefined, schemaGroup.branchId, schemaGroup.schemaId);
          return Curry._1(setSegmentation, (function (param) {
                        return newSegmentation;
                      }));
        }), []);
  var hasEmptyState = model.properties === /* [] */0;
  React.useEffect((function () {
          if (hasEmptyState) {
            AnalyticsRe.emptyStateDisplayed(schemaGroup, "Properties", schema.id);
          }
          
        }), []);
  var makeItem = function (item) {
    return {
            item: item,
            columns: columns,
            schema: schema,
            currentBranch: currentBranch,
            model: model,
            globalSend: globalSend,
            sendActions: sendActions,
            schemaGroup: schemaGroup,
            currentFilters: currentFilters,
            viewerRole: viewerRole,
            filters: filters,
            sourceFilterNames: sourceFilterNames
          };
  };
  var itemData = React.useMemo((function () {
          if (hasEmptyState) {
            return [makeItem(/* EmptyState */1)];
          } else {
            return Belt_Array.map(items, makeItem);
          }
        }), [
        columns,
        currentBranch,
        currentFilters,
        items,
        model,
        schema,
        viewerRole
      ]);
  React.useEffect((function () {
          Belt_Option.forEach(Caml_option.nullable_to_opt(sheetRef.current), (function (r) {
                  r.resetAfterIndex(0, false);
                  
                }));
          
        }), [itemData]);
  return React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: headerStyles
                }, React.createElement(Title.make, {
                      children: null,
                      shrink: 0.0,
                      size: "Tiny"
                    }, "Properties ", React.createElement("span", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("inlineBlock"),
                                tl: {
                                  hd: Css.minWidth(Css.px(48)),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              element: "Span",
                              size: "Medium",
                              color: Styles.Color.grey70,
                              children: "(" + Belt_List.length(model.properties).toString() + ")"
                            }))), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement(SegmentControl.make, {
                      options: Belt_Array.concat([
                            [
                              "View All Properties",
                              undefined
                            ],
                            [
                              "Event",
                              /* EventProperty */1
                            ],
                            [
                              "User",
                              /* UserProperty */2
                            ],
                            [
                              "System",
                              /* SystemProperty */0
                            ]
                          ], Belt_Array.mapU(model.groupTypes, (function (param) {
                                  return [
                                          param.name,
                                          /* GroupProperty */{
                                            _0: param.id
                                          }
                                        ];
                                }))),
                      onSelect: handleSegmentation,
                      selected: segmentation,
                      disabled: model.properties === /* [] */0
                    }), React.createElement(Spacer.make, {
                      width: 16,
                      grow: 1
                    }), React.createElement(Link.make, {
                      path: Router.Link.getSchemaRouteLink(undefined, "propertyGroups"),
                      children: React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Medium",
                            color: Styles.Color.blue,
                            children: "View Event Property Bundles (" + Belt_List.length(model.propertyBundles).toString() + ")"
                          })
                    }), React.createElement(Spacer.make, {
                      width: 16
                    })), React.createElement(SheetHeader.make, {
                  columns: columns,
                  setColumns: match$1[1],
                  view: /* ByCategory */0,
                  onWidthChange: (function (direction, param, columnType) {
                      return AnalyticsRe.propertiesViewConfigured(schemaGroup, direction === "Increase" ? "IncreaseColumnWidth" : "DecreaseColumnWidth", undefined, undefined, AnalyticsUtils.columnTypeToPropertyColumnsName(columnType), schemaGroup.branchId, schemaGroup.schemaId);
                    }),
                  ref: match$4.handleSheetHeaderRef
                }), React.createElement("div", {
                  className: sheet
                }, React.createElement(ReactVirtualizedAutoSizer, {
                      children: (function (size) {
                          return React.createElement(ReactWindow.VariableSizeList, {
                                      estimatedItemSize: 50,
                                      height: size.height,
                                      itemCount: hasEmptyState ? itemData.length : items.length,
                                      itemData: itemData,
                                      itemKey: (function (index) {
                                          return index.toString();
                                        }),
                                      itemSize: (function (index) {
                                          return Belt_Option.mapWithDefault(Belt_Array.get(itemData, index), 50, (function (param) {
                                                        var item = param.item;
                                                        if (typeof item === "number") {
                                                          return 50;
                                                        }
                                                        switch (item.TAG | 0) {
                                                          case /* Empty */4 :
                                                              return item._0;
                                                          case /* Info */5 :
                                                              return item._1;
                                                          default:
                                                            return 50;
                                                        }
                                                      }));
                                        }),
                                      outerRef: handleOuterRef,
                                      overscanCount: 30,
                                      ref: handleReactWindowRef,
                                      width: size.width,
                                      children: PropertiesSheetRow.make
                                    });
                        })
                    })));
}

var make = PropertiesSheet;

exports.Style = Style;
exports.printSegmentQueryParam = printSegmentQueryParam;
exports.parseSegmentQueryParam = parseSegmentQueryParam;
exports.make = make;
/* headerStyles Not a pure module */
