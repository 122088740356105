// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var $$Number = require("../Number.bs.js");
var Semver = require("../../../shared/bindings/semver.bs.js");
var Semver$1 = require("semver");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SortArray = require("rescript/lib/js/belt_SortArray.js");

function compareRelease(a, b) {
  if (a === b) {
    return 0;
  }
  var match = Semver.coerceAndValidate(a);
  var match$1 = Semver.coerceAndValidate(b);
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return Semver$1.compare(match, match$1);
    } else {
      return 1;
    }
  } else if (match$1 !== undefined) {
    return -1;
  } else {
    return Caml.caml_string_compare(a, b);
  }
}

function compare(a, b) {
  var a$1 = a.release;
  var b$1 = b.release;
  return compareRelease(a$1, b$1);
}

var compareU = compare;

var cmp = compare;

var Comparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

var Version = {
  compareRelease: compareRelease,
  compare: compare,
  compareU: compareU,
  Comparator: Comparator
};

function getIssuePercentage(eventCount, issueCount) {
  if (issueCount !== 0) {
    if (eventCount !== 0) {
      return issueCount / eventCount;
    } else {
      return 0.0;
    }
  } else {
    return 0.0;
  }
}

function getIssueDisplayPercentage(eventCount, issueCount) {
  return getIssuePercentage(eventCount, issueCount) * 100.0;
}

function getDisplayPercentageString(eventCount, issueCount) {
  return $$Number.getRoughPercentageString(getIssueDisplayPercentage(eventCount, issueCount));
}

function fromIssue(categoryIdsByEventIdMap, eventsByNameMap, sourceNamesByIdMap, issue) {
  var versions = Belt_SortArray.stableSortByU(Belt_Array.map(Js_dict.values(issue.appVersions), (function (appVersion) {
              return {
                      release: appVersion.appVersion,
                      lastSeen: appVersion.lastSeen,
                      issueCount: appVersion.issueCount | 0,
                      eventCount: appVersion.eventCount | 0
                    };
            })), compareU);
  var match = Belt_MapString.get(eventsByNameMap, issue.eventName);
  var match$1 = Belt_MapString.get(eventsByNameMap, issue.eventName);
  return {
          id: issue.issueId,
          sharedId: issue.sharedIssueId,
          issueType: issue.issueType,
          eventName: issue.eventName,
          propertyName: issue.propertyName,
          source: {
            id: issue.sourceId,
            name: Belt_MapString.getWithDefault(sourceNamesByIdMap, issue.sourceId, "Unknown source"),
            versions: versions,
            impactedVersions: Belt_Array.keepU(versions, (function (param) {
                    return param.issueCount > 0;
                  }))
          },
          categories: match !== undefined ? Belt_MapString.getWithDefault(categoryIdsByEventIdMap, match.id, []) : [],
          tags: match$1 !== undefined ? Belt_List.toArray(match$1.tags) : [],
          firstSeen: issue.firstSeen,
          lastSeen: issue.lastSeen,
          eventCount: issue.eventCount | 0,
          issueCount: issue.issueCount | 0,
          newestAppVersion: issue.newestAppVersion,
          oldestAppVersion: issue.oldestAppVersion
        };
}

function fromIssueMaybe(categoryIdsByEventIdMap, eventsByNameMap, sourceNamesByIdMap) {
  return function (issue) {
    if (issue.issueCount > 0.0) {
      return fromIssue(categoryIdsByEventIdMap, eventsByNameMap, sourceNamesByIdMap, issue);
    }
    
  };
}

function fromIssues(model, issues) {
  var eventsByNameMap = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(model.events, (function ($$event) {
                  return [
                          $$event.name,
                          $$event
                        ];
                }))));
  var categoryIdsByEventIdMap = Belt_List.reduce(model.goals, undefined, (function (map, param) {
          var categoryId = param.id;
          return Belt_List.reduce(param.events, map, (function (map, eventId) {
                        return Belt_MapString.update(map, eventId, (function (maybeItem) {
                                      if (maybeItem !== undefined) {
                                        return Belt_Array.concat([categoryId], maybeItem);
                                      } else {
                                        return [categoryId];
                                      }
                                    }));
                      }));
        }));
  var archivedSourceNamesById = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(model.archive.sources, (function (param) {
                  return [
                          param.id,
                          Belt_Option.mapWithDefault(param.name, "Untitled source (archived)", (function (name) {
                                  return name + " (archived)";
                                }))
                        ];
                }))));
  var sourceNamesByIdMap = Belt_MapString.mergeMany(archivedSourceNamesById, Belt_List.toArray(Belt_List.map(model.sources, (function (param) {
                  return [
                          param.id,
                          Belt_Option.getWithDefault(param.name, "Untitled source")
                        ];
                }))));
  return Belt_Array.keepMapU(issues, fromIssueMaybe(categoryIdsByEventIdMap, eventsByNameMap, sourceNamesByIdMap));
}

function getSourceReport(issues) {
  var sources = {};
  var sourceTotalEventCount = {};
  Belt_Array.forEachU(issues, (function (issue) {
          var existingEventCount = Js_dict.get(sourceTotalEventCount, issue.source.id);
          if (existingEventCount !== undefined) {
            sourceTotalEventCount[issue.source.id] = existingEventCount + issue.eventCount | 0;
          } else {
            sourceTotalEventCount[issue.source.id] = issue.eventCount;
          }
          var releases = Js_dict.get(sources, issue.source.id);
          if (releases !== undefined) {
            var releases$1 = Caml_option.valFromOption(releases);
            Belt_Array.forEachU(Belt_Array.mapU(issue.source.versions, (function (param) {
                        return [
                                param.release,
                                param.eventCount
                              ];
                      })), (function (param) {
                    var eventCount = param[1];
                    var release = param[0];
                    var existingEventCount = Js_dict.get(releases$1, release);
                    if (existingEventCount !== undefined) {
                      releases$1[release] = existingEventCount + eventCount | 0;
                    } else {
                      releases$1[release] = eventCount;
                    }
                    
                  }));
            sources[issue.source.id] = releases$1;
            return ;
          }
          var versions = Js_dict.fromArray(Belt_Array.mapU(issue.source.versions, (function (param) {
                      return [
                              param.release,
                              param.eventCount
                            ];
                    })));
          sources[issue.source.id] = versions;
          
        }));
  return {
          sourcesCount: Object.keys(sources).length,
          releasesCount: Belt_Array.concatMany(Belt_Array.mapU(Js_dict.values(sources), Js_dict.entries)).length,
          breakdown: Belt_MapString.fromArray(Belt_Array.mapU(Js_dict.entries(sources), (function (param) {
                      var sourceId = param[0];
                      return [
                              sourceId,
                              {
                                releases: Belt_SortArray.stableSortByU(Js_dict.entries(param[1]), (function (a, b) {
                                        return compareRelease(a[0], b[0]);
                                      })),
                                totalEventCount: sourceTotalEventCount[sourceId]
                              }
                            ];
                    })))
        };
}

var Utils = {
  getIssuePercentage: getIssuePercentage,
  getIssueDisplayPercentage: getIssueDisplayPercentage,
  getDisplayPercentageString: getDisplayPercentageString,
  fromIssue: fromIssue,
  fromIssueMaybe: fromIssueMaybe,
  fromIssues: fromIssues,
  getSourceReport: getSourceReport
};

exports.Version = Version;
exports.Utils = Utils;
/* Comparator Not a pure module */
