// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var DocsLink = require("../DocsLink.bs.js");
var IconCheckmark = require("../IconCheckmark.bs.js");
var LoadingCircle = require("../LoadingCircle.bs.js");

var docsContentStyles = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(24), Css.px(32)),
      tl: /* [] */0
    });

var docsDescriptionStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(16)),
      tl: {
        hd: Css.marginBottom(Css.px(8)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("row"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.color(Styles.Color.grey90),
                tl: {
                  hd: Css.fontSize(Styles.FontSize.medium),
                  tl: {
                    hd: Css.fontWeight(Styles.FontWeight.regular),
                    tl: {
                      hd: Css.lineHeight(Css.pct(140.0)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var listNumberStyle = Curry._1(Css.style, {
      hd: Css.fontWeight(Styles.FontWeight.bold),
      tl: /* [] */0
    });

function InspectorViewInstallDocsPosthog(Props) {
  var hasSeenEvents = Props.hasSeenEvents;
  return React.createElement("ol", {
              className: docsContentStyles
            }, React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, "You've created this source to stream data to, the first step is done!  ", "🎉")), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, "Enable Avo Inspector Plugin in your PostHog workspace", React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.width(Css.px(4)),
                                tl: /* [] */0
                              })
                        }), React.createElement(DocsLink.make, {
                          path: "workspace/connect-inspector-to-posthog#step-2-enable-avo-inspector-plugin-in-posthog",
                          label: "See step 2 in this guide"
                        }))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, "Test the PostHog Integration", React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.width(Css.px(4)),
                                tl: /* [] */0
                              })
                        }), React.createElement(DocsLink.make, {
                          path: "workspace/connect-inspector-to-posthog#step-3-test-your-posthog-plugin-integration-on-dev",
                          label: "See step 3 in this guide"
                        })), React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement(Spacer.make, {
                          width: 4
                        }), hasSeenEvents ? React.createElement(React.Fragment, undefined, React.createElement(IconCheckmark.make, {
                                size: 12,
                                color: Styles.Color.mintGreen
                              }), React.createElement(Spacer.make, {
                                width: 8
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.mintGreen,
                                children: "Seen events"
                              })) : React.createElement(React.Fragment, undefined, React.createElement(LoadingCircle.make, {
                                size: 16,
                                color: Styles.Color.grey50
                              }), React.createElement(Spacer.make, {
                                width: 8
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.grey70,
                                children: "Waiting for events"
                              })))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, "Set env key in PostHog plugin to prod and you are ready!", React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.width(Css.px(4)),
                                tl: /* [] */0
                              })
                        }))));
}

var make = InspectorViewInstallDocsPosthog;

exports.docsContentStyles = docsContentStyles;
exports.docsDescriptionStyles = docsDescriptionStyles;
exports.listNumberStyle = listNumberStyle;
exports.make = make;
/* docsContentStyles Not a pure module */
