// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

var $$Date = {};

var InvocationGroup = {};

var SourceEventVersion = {};

var SourceEventVersionRes = {};

function parse(json) {
  return {
          sourceId: Json_decode.field("sourceId", Json_decode.string, json),
          branchId: Json_decode.field("branchId", Json_decode.string, json),
          type_: Json_decode.field("type_", Json_decode.string, json),
          firstSeen: Json_decode.field("firstSeen", (function (json) {
                  return new Date(Json_decode.field("value", Json_decode.string, json));
                }), json),
          lastSeen: Json_decode.field("lastSeen", (function (json) {
                  return new Date(Json_decode.field("value", Json_decode.string, json));
                }), json),
          count: Json_decode.field("count", Json_decode.$$int, json)
        };
}

var SourceInitRes = {
  parse: parse
};

function parse$1(json) {
  return {
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          branchId: Json_decode.field("branchId", Json_decode.string, json),
          actionId: Json_decode.optional((function (param) {
                  return Json_decode.field("actionId", Json_decode.string, param);
                }), json),
          invocations: Json_decode.field("invocations", (function (param) {
                  return Json_decode.array((function (prim) {
                                return prim;
                              }), param);
                }), json),
          sources: Json_decode.field("sources", (function (param) {
                  return Json_decode.array(parse, param);
                }), json),
          timestamp: new Date(Json_decode.field("timestamp", Json_decode.string, json))
        };
}

var Res = {
  parse: parse$1
};

function parse$2(json) {
  return {
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          branchId: Json_decode.field("branchId", Json_decode.string, json),
          invocations: Json_decode.field("invocations", (function (param) {
                  return Json_decode.array((function (prim) {
                                return prim;
                              }), param);
                }), json),
          sources: Json_decode.field("sources", (function (param) {
                  return Json_decode.array(parse, param);
                }), json),
          timestamp: new Date(Json_decode.field("timestamp", Json_decode.string, json))
        };
}

var PollRes = {
  parse: parse$2
};

exports.$$Date = $$Date;
exports.InvocationGroup = InvocationGroup;
exports.SourceEventVersion = SourceEventVersion;
exports.SourceEventVersionRes = SourceEventVersionRes;
exports.SourceInitRes = SourceInitRes;
exports.Res = Res;
exports.PollRes = PollRes;
/* No side effect */
