// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Auth = require("./Auth.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Share = require("./Share.bs.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Sentry = require("./externals/Sentry.bs.js");
var Mantine = require("./Mantine.bs.js");
var Version = require("./version.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Redirect = require("./Redirect.bs.js");
var LoggedOut = require("./LoggedOut.bs.js");
var FetchModel = require("./FetchModel.bs.js");
var Onboarding = require("./Onboarding.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RouterStore = require("./RouterStore.bs.js");
var VerifyEmail = require("./VerifyEmail.bs.js");
var AuthorizeCli = require("./AuthorizeCli.bs.js");
var Core = require("@mantine/core");
var React$1 = require("@sentry/react");
var LoadingFullscreen = require("./LoadingFullscreen.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var LoggedInForceUpdateWrapper = require("./LoggedInForceUpdateWrapper.bs.js");
var UnauthenticatedSchemaAccessWall = require("./UnauthenticatedSchemaAccessWall.bs.js");

function App$DefaultSchema(Props) {
  var userId = Props.userId;
  var userSchemasState = FirebaseFetcherHooks.useUserSchemas(userId);
  if (typeof userSchemasState === "object") {
    var match = userSchemasState.VAL;
    if (match) {
      var route = Router.getStaticRoute(undefined);
      var search = Router.getSearch(undefined);
      var schemaId = match.hd.id;
      var path = Curry._4(Router.Link.get, {
            NAME: "schema",
            VAL: schemaId
          }, route.branch, route.schemaRoute, /* [] */0) + search;
      return React.createElement(Redirect.make, {
                  path: path
                });
    }
    
  } else if (userSchemasState === "Initial") {
    return React.createElement(LoadingFullscreen.make, {
                message: "Finding default workspace..."
              });
  }
  return React.createElement(FetchModel.$$Error.make, {
              message: "Could not fetch your workspace"
            });
}

var DefaultSchema = {
  make: App$DefaultSchema
};

function App$App(Props) {
  React.useEffect((function () {
          AnalyticsRe.appOpened(undefined);
          var fromVersion = Belt_Option.getWithDefault(Caml_option.null_to_opt(localStorage.getItem("avo.version")), "None");
          if (fromVersion !== Version.current) {
            AnalyticsRe.versionUpdated(fromVersion);
          }
          localStorage.setItem("avo.version", Version.current);
          
        }), []);
  var auth = Firebase.app(undefined).auth();
  var baseRoute = Router.useBaseRoute(undefined);
  var settingsQuery = Router.QueryParams.useKey("settings");
  var user = Auth.useUser(auth);
  var tmp;
  var exit = 0;
  var onboardingRoute;
  if (user !== undefined) {
    var exit$1 = 0;
    if (typeof baseRoute === "object" && baseRoute.NAME === "share") {
      tmp = React.createElement(Share.make, {
            id: baseRoute.VAL,
            authState: user
          });
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      if (typeof user === "object") {
        if (user.NAME === "Authenticated") {
          var user$1 = user.VAL;
          var exit$2 = 0;
          if (typeof baseRoute === "object") {
            var variant = baseRoute.NAME;
            if (variant === "auth") {
              tmp = React.createElement(AuthorizeCli.make, {
                    user: user$1,
                    cliRoute: baseRoute.VAL
                  });
            } else if (variant === "schema") {
              if (baseRoute.VAL === "default") {
                tmp = React.createElement(App$DefaultSchema, {
                      userId: user$1.uid
                    });
              } else {
                exit$2 = 3;
              }
            } else if (variant === "onboarding") {
              onboardingRoute = baseRoute.VAL;
              exit = 1;
            } else {
              exit$2 = 3;
            }
          } else {
            exit$2 = 3;
          }
          if (exit$2 === 3) {
            tmp = React.createElement(LoggedInForceUpdateWrapper.make, {
                  userId: user$1.uid,
                  authState: user,
                  baseRoute: baseRoute,
                  settingsQuery: settingsQuery
                });
          }
          
        } else {
          var user$2 = user.VAL;
          var exit$3 = 0;
          if (typeof baseRoute === "object") {
            var variant$1 = baseRoute.NAME;
            if (variant$1 === "schema") {
              tmp = React.createElement(VerifyEmail.make, {
                    user: user$2,
                    schemaId: baseRoute.VAL
                  });
            } else if (variant$1 === "onboarding") {
              onboardingRoute = baseRoute.VAL;
              exit = 1;
            } else {
              exit$3 = 3;
            }
          } else {
            exit$3 = 3;
          }
          if (exit$3 === 3) {
            tmp = React.createElement(VerifyEmail.make, {
                  user: user$2
                });
          }
          
        }
      } else {
        var exit$4 = 0;
        if (typeof baseRoute === "object") {
          var variant$2 = baseRoute.NAME;
          if (variant$2 === "schema") {
            tmp = React.createElement(UnauthenticatedSchemaAccessWall.make, {
                  schemaId: baseRoute.VAL
                });
          } else if (variant$2 === "onboarding") {
            onboardingRoute = baseRoute.VAL;
            exit = 1;
          } else {
            exit$4 = 3;
          }
        } else if (baseRoute === "signUp" || baseRoute === "invite" || baseRoute === "passwordReset" || baseRoute === "login") {
          tmp = React.createElement(LoggedOut.make, {});
        } else if (baseRoute === "index" || baseRoute === "notFound") {
          tmp = React.createElement(Redirect.make, {
                path: "/login"
              });
        } else {
          exit$4 = 3;
        }
        if (exit$4 === 3) {
          tmp = React.createElement(Redirect.make, {
                path: "/login?redirect=" + Router.buildRedirectPath(undefined)
              });
        }
        
      }
    }
    
  } else {
    tmp = null;
  }
  if (exit === 1) {
    tmp = React.createElement(Onboarding.make, {
          onboardingRoute: onboardingRoute
        });
  }
  return React.createElement(Core.MantineProvider, {
              theme: Mantine.Utils.getTheme(undefined),
              children: null
            }, React.createElement(RouterStore.Watcher.make, {}), tmp);
}

var App = {
  make: App$App
};

function App$1(Props) {
  React.useEffect((function () {
          var match = process.env.VERCEL_ENV;
          var match$1 = process.env.NODE_ENV;
          var sentryEnv = match === "preview" ? "Preview" : (
              match$1 === "development" ? "Development" : "Production"
            );
          Sentry.init(sentryEnv);
          
        }), []);
  return React.createElement(React$1.withProfiler(App$App), {});
}

var make = App$1;

exports.DefaultSchema = DefaultSchema;
exports.App = App;
exports.make = make;
/* Auth Not a pure module */
