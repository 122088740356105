// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("../Icons.bs.js");
var Title = require("../Title.bs.js");
var React = require("react");
var Button = require("../Button.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var PortalMenu = require("../PortalMenu.bs.js");
var TextButton = require("../TextButton.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var IconWarning = require("../IconWarning.bs.js");
var IconCheckmark = require("../IconCheckmark.bs.js");
var AppFeatureFlag = require("../AppFeatureFlag.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var ImportReviewRow = require("./ImportReviewRow.bs.js");
var CollapsedContent = require("../CollapsedContent.bs.js");
var IconErrorHexagon = require("../IconErrorHexagon.bs.js");
var WorkspaceContext = require("../WorkspaceContext.bs.js");
var GlobalSendContext = require("../GlobalSendContext.bs.js");
var SelectBranchPopup = require("../SelectBranchPopup.bs.js");
var IconCheckmarkCircle = require("../IconCheckmarkCircle.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(40)),
      tl: {
        hd: Css.maxWidth(Css.pct(100.0)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.alignItems("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.zIndex(1),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.left(Css.px(0)),
            tl: {
              hd: Css.right(Css.px(0)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("column"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.width(Css.vw(100.0)),
                      tl: {
                        hd: Css.paddingTop(Css.px(48)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.grey20),
                          tl: {
                            hd: Css.paddingBottom(Css.px(12)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var topStyles = Curry._1(Css.style, {
      hd: Css.width(Css.px(580)),
      tl: {
        hd: Css.position("relative"),
        tl: /* [] */0
      }
    });

var backlinkStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.pct(100.0)),
        tl: {
          hd: Css.top(Css.px(10)),
          tl: {
            hd: Css.marginRight(Css.px(20)),
            tl: /* [] */0
          }
        }
      }
    });

var countsStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(12)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.boxShadow(Styles.Shadow.subtle),
          tl: {
            hd: Css.padding(Css.px(12)),
            tl: {
              hd: Css.marginBottom(Css.px(32)),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var countStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var tableIntroStyles = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top(Css.px(99)),
        tl: {
          hd: Css.zIndex(1),
          tl: {
            hd: Css.width(Css.pct(100.0)),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.paddingTop(Css.px(8)),
                    tl: {
                      hd: Css.paddingRight(Css.px(8)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(8)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(80)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey30),
                              tl: {
                                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var tableIntroLeftStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.flexGrow(1.0),
          tl: {
            hd: Css.marginRight(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

function cmp(param, param$1) {
  var c = Caml_obj.caml_compare(param[0], param$1[0]);
  if (c !== 0) {
    return c;
  } else {
    return Caml_obj.caml_compare(param[1], param$1[1]);
  }
}

var StringTupleComparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

function rootStyles$1(hasSelected) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("row"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.border(Css.px(1), "dashed", Styles.Color.grey30),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.paddingTop(Css.px(4)),
                                tl: {
                                  hd: Css.paddingRight(Css.px(8)),
                                  tl: {
                                    hd: Css.paddingBottom(Css.px(4)),
                                    tl: {
                                      hd: Css.paddingLeft(Css.px(8)),
                                      tl: {
                                        hd: Css.color(hasSelected ? Styles.Color.mintGreen : Styles.Color.blue),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.borderColor(Styles.Color.grey40),
                                                tl: {
                                                  hd: Css.color(hasSelected ? Styles.Color.mintGreenSecondary : Styles.Color.blueSecondary),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: {
                                            hd: Css.focus({
                                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.grey40)),
                                                  tl: /* [] */0
                                                }),
                                            tl: {
                                              hd: Css.outlineStyle("none"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function ImportReview$ImportBranchPicker(Props) {
  var selectedEventCount = Props.selectedEventCount;
  var selectedPropertiesCount = Props.selectedPropertiesCount;
  var openBranches = Props.openBranches;
  var setSelectedBranch = Props.setSelectedBranch;
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var selectedBranch = Props.selectedBranch;
  var handleFinishImport = Props.handleFinishImport;
  var canSelectMain = Props.canSelectMain;
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var isLoading = match[0];
  var selectBranchButtonRef = React.useRef(null);
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowBranchPopup = match$1[1];
  return React.createElement(React.Fragment, undefined, React.createElement("button", {
                  ref: selectBranchButtonRef,
                  className: rootStyles$1(Belt_Option.isSome(selectedBranch)),
                  onClick: (function (param) {
                      return Curry._1(setShowBranchPopup, (function (state) {
                                    return !state;
                                  }));
                    })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Medium",
                      color: Styles.Color.grey50,
                      children: "Import " + selectedEventCount.toString() + " Events & " + selectedPropertiesCount.toString() + " Properties to…"
                    }), Belt_Option.isSome(selectedBranch) ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                            width: 2
                          }), React.createElement(IconCheckmark.make, {
                            size: 12,
                            color: Styles.Color.mintGreen
                          })) : null, React.createElement(Spacer.make, {
                      width: 2
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      children: selectedBranch !== undefined ? (
                          typeof selectedBranch === "object" ? (
                              selectedBranch.NAME === "Create" ? selectedBranch.VAL : selectedBranch.VAL.name
                            ) : "main"
                        ) : "Choose branch"
                    })), React.createElement(PortalMenu.make, {
                  visible: match$1[0],
                  onClose: (function (param) {
                      return Curry._1(setShowBranchPopup, (function (param) {
                                    return false;
                                  }));
                    }),
                  referenceItemRef: selectBranchButtonRef,
                  children: React.createElement(SelectBranchPopup.make, {
                        openBranches: Belt_List.keep(openBranches, (function (param) {
                                return param[0] === branchId;
                              })),
                        onClose: (function (param) {
                            return Curry._1(setShowBranchPopup, (function (param) {
                                          return false;
                                        }));
                          }),
                        onSelect: (function (selectedBranch) {
                            return Curry._1(setSelectedBranch, (function (param) {
                                          return selectedBranch;
                                        }));
                          }),
                        schemaId: schemaId,
                        canSelectMain: canSelectMain
                      })
                }), React.createElement(Spacer.make, {
                  width: 8
                }), selectedBranch !== undefined ? React.createElement(Button.make, {
                    disabled: isLoading,
                    label: isLoading ? "Importing…" : "Import",
                    onClick: (function (param) {
                        Curry._1(setLoading, (function (param) {
                                return true;
                              }));
                        return Curry._1(handleFinishImport, selectedBranch);
                      })
                  }) : React.createElement(Button.make, {
                    disabled: true,
                    label: isLoading ? "Importing…" : "Import"
                  }));
}

var ImportBranchPicker = {
  rootStyles: rootStyles$1,
  make: ImportReview$ImportBranchPicker
};

function ImportReview(Props) {
  var parsedTrackingPlan = Props.parsedTrackingPlan;
  var onContinue = Props.onContinue;
  var hasEventsOnModel = Props.hasEventsOnModel;
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var actionsCount = Props.actionsCount;
  var model = Props.model;
  var viewerRole = Props.viewerRole;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var hasAllowLargeImportOnBranchFeatureFlag = AppFeatureFlag.useFeatureFlag("AllowLargeImportOnBranch");
  var globalSend = GlobalSendContext.use(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var isLoading = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setExcludedEventIds = match$1[1];
  var excludedEventIds = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setSelectedBranch = match$2[1];
  var selectedBranch = match$2[0];
  var match$3 = React.useState(function () {
        return Belt_Set.make(StringTupleComparator);
      });
  var setExcludedEventPropertyIds = match$3[1];
  var excludedEventPropertyIds = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setExcludedUserPropertyIds = match$4[1];
  var excludedUserPropertyIds = match$4[0];
  var match$5 = React.useState(function () {
        return false;
      });
  var setExcludeAllUserProperties = match$5[1];
  var excludeAllUserProperties = match$5[0];
  React.useEffect((function () {
          if (isLoading || Belt_SetString.isEmpty(excludedEventIds) && Belt_Set.isEmpty(excludedEventPropertyIds) && Belt_SetString.isEmpty(excludedUserPropertyIds) && excludeAllUserProperties) {
            return ;
          }
          var warnBeforeLosingChanges = function ($$event) {
            $$event.preventDefault();
            $$event.returnValue = "";
            
          };
          window.addEventListener("beforeunload", warnBeforeLosingChanges);
          return (function (param) {
                    window.removeEventListener("beforeunload", warnBeforeLosingChanges);
                    
                  });
        }), [
        isLoading,
        excludedEventIds,
        excludedEventPropertyIds,
        excludedUserPropertyIds,
        excludeAllUserProperties
      ]);
  var handleIncludeToggle = function (eventId, reactEvent) {
    var checked = reactEvent.target.checked;
    return Curry._1(setExcludedEventIds, (function (excluded) {
                  if (checked) {
                    return Belt_SetString.remove(excluded, eventId);
                  } else {
                    return Belt_SetString.add(excluded, eventId);
                  }
                }));
  };
  var handleIncludePropertyToggle = function (eventId, propertyId, reactEvent) {
    var checked = reactEvent.target.checked;
    return Curry._1(setExcludedEventPropertyIds, (function (excluded) {
                  if (checked) {
                    return Belt_Set.remove(excluded, [
                                eventId,
                                propertyId
                              ]);
                  } else {
                    return Belt_Set.add(excluded, [
                                eventId,
                                propertyId
                              ]);
                  }
                }));
  };
  var handleIncludeUserPropertyToggle = function (propertyId, reactEvent) {
    var checked = reactEvent.target.checked;
    return Curry._1(setExcludedUserPropertyIds, (function (excluded) {
                  if (checked) {
                    return Belt_SetString.remove(excluded, propertyId);
                  } else {
                    return Belt_SetString.add(excluded, propertyId);
                  }
                }));
  };
  var handleExcludeAllUserPropertiesToggle = function (reactEvent) {
    var checked = reactEvent.target.checked;
    return Curry._1(setExcludeAllUserProperties, (function (param) {
                  return !checked;
                }));
  };
  var filterPropertiesBeforeImport = function (properties) {
    var includedEvents = Belt_Array.keepU(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
            return !Belt_SetString.has(excludedEventIds, $$event.id);
          }));
    return Belt_Array.keepU(properties, (function (property) {
                  var match = property.sendAs;
                  if (typeof match === "number" && match >= 2) {
                    if (excludeAllUserProperties) {
                      return false;
                    } else {
                      return !Belt_SetString.has(excludedUserPropertyIds, property.id);
                    }
                  }
                  return !Belt_MapString.some(property.presence, (function (eventId, param) {
                                if (Belt_SetString.has(excludedEventIds, eventId) && Belt_Array.every(includedEvents, (function ($$event) {
                                          return $$event.id !== eventId;
                                        }))) {
                                  return true;
                                } else {
                                  return Belt_Set.has(excludedEventPropertyIds, [
                                              eventId,
                                              property.id
                                            ]);
                                }
                              }));
                }));
  };
  var newAndUpdatedProperties = Belt_Array.concat(parsedTrackingPlan.newProperties, parsedTrackingPlan.updatedProperties);
  var handleFinishImport = function (selectedBranch) {
    var branchName = typeof selectedBranch === "object" ? (
        selectedBranch.NAME === "Create" ? selectedBranch.VAL : selectedBranch.VAL.name
      ) : "main";
    var includedNewEvents = Belt_Array.keepU(parsedTrackingPlan.newEvents, (function ($$event) {
            return !Belt_SetString.has(excludedEventIds, $$event.id);
          }));
    var includedUpdatedEvents = Belt_Array.keepU(parsedTrackingPlan.updatedEvents, (function ($$event) {
            return !Belt_SetString.has(excludedEventIds, $$event.id);
          }));
    var numEvents = includedNewEvents.length + includedUpdatedEvents.length | 0;
    var numProperties = filterPropertiesBeforeImport(newAndUpdatedProperties).length;
    var confirmationText = "You are about to import " + String(numEvents) + " event" + (
      numEvents === 1 ? "" : "s"
    ) + " and " + String(numProperties) + " propert" + (
      numProperties === 1 ? "y" : "ies"
    ) + " to your workspace" + (
      selectedBranch === "Master" ? "" : " on the \"" + branchName + "\" branch"
    ) + ". Do you want to run the import?";
    return Curry._1(globalSend, {
                TAG: /* OpenModal */4,
                _0: {
                  NAME: "ConfirmModal",
                  VAL: [
                    "Finish import",
                    confirmationText,
                    "Import",
                    (function (param) {
                        Curry._1(setLoading, (function (param) {
                                return true;
                              }));
                        return Curry._2(onContinue, selectedBranch, {
                                    formatName: parsedTrackingPlan.formatName,
                                    newEvents: Belt_Array.mapU(includedNewEvents, (function ($$event) {
                                            return {
                                                    id: $$event.id,
                                                    name: $$event.name,
                                                    uniqueNameIndex: $$event.uniqueNameIndex,
                                                    description: $$event.description,
                                                    categories: $$event.categories,
                                                    tags: $$event.tags,
                                                    propertyIds: Belt_Array.keepU($$event.propertyIds, (function (param) {
                                                            return !Belt_Set.has(excludedEventPropertyIds, [
                                                                        $$event.id,
                                                                        param[0]
                                                                      ]);
                                                          })),
                                                    updatedPropertyIds: $$event.updatedPropertyIds,
                                                    propertyGroupIds: $$event.propertyGroupIds,
                                                    sources: $$event.sources,
                                                    warnings: $$event.warnings,
                                                    nameMapping: $$event.nameMapping
                                                  };
                                          })),
                                    updatedEvents: Belt_Array.mapU(includedUpdatedEvents, (function ($$event) {
                                            return {
                                                    id: $$event.id,
                                                    name: $$event.name,
                                                    uniqueNameIndex: $$event.uniqueNameIndex,
                                                    description: $$event.description,
                                                    categories: $$event.categories,
                                                    tags: $$event.tags,
                                                    propertyIds: Belt_Array.keepU($$event.propertyIds, (function (param) {
                                                            return !Belt_Set.has(excludedEventPropertyIds, [
                                                                        $$event.id,
                                                                        param[0]
                                                                      ]);
                                                          })),
                                                    updatedPropertyIds: $$event.updatedPropertyIds,
                                                    propertyGroupIds: $$event.propertyGroupIds,
                                                    sources: $$event.sources,
                                                    warnings: $$event.warnings,
                                                    nameMapping: $$event.nameMapping
                                                  };
                                          })),
                                    warnings: parsedTrackingPlan.warnings,
                                    newProperties: filterPropertiesBeforeImport(parsedTrackingPlan.newProperties),
                                    updatedProperties: filterPropertiesBeforeImport(parsedTrackingPlan.updatedProperties),
                                    sources: parsedTrackingPlan.sources,
                                    propertyGroups: parsedTrackingPlan.propertyGroups
                                  });
                      }),
                    (function (param) {
                        return Curry._1(setLoading, (function (param) {
                                      return false;
                                    }));
                      })
                  ]
                }
              });
  };
  var hasCategories = Belt_Array.someU(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
          return $$event.categories.length !== 0;
        }));
  var hasTags = Belt_Array.someU(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
          return $$event.tags.length !== 0;
        }));
  var hasNameMapping = Belt_Array.someU(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
          return $$event.nameMapping.length !== 0;
        }));
  var selectedEvents = Belt_Array.keepU(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
          return !Belt_SetString.has(excludedEventIds, $$event.id);
        }));
  var selectedEventCount = selectedEvents.length;
  var selectedPropertiesCount = filterPropertiesBeforeImport(newAndUpdatedProperties).length;
  var currentBranchName;
  if (typeof currentBranch === "object") {
    var branchId$1 = currentBranch.VAL;
    currentBranchName = Belt_Option.mapWithDefault(Belt_List.getByU(openBranches, (function (param) {
                return param[0] === branchId$1;
              })), "main", (function (param) {
            return param[1];
          }));
  } else {
    currentBranchName = "main";
  }
  var canSelectMain = !workspace.settings.protectedMainBranch || viewerRole === "Admin";
  var match$6 = Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents).length;
  var match$7 = newAndUpdatedProperties.length;
  var match$8 = parsedTrackingPlan.sources.length;
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  if (match$6 !== 0) {
    exit$1 = 2;
  } else if (match$7 !== 0) {
    if (match$8 !== 0) {
      exit$1 = 2;
    } else {
      tmp = match$7.toString() + " properties found";
    }
  } else {
    tmp = match$8 !== 0 ? match$8.toString() + " sources found" : "Found no data";
  }
  if (exit$1 === 2) {
    if (match$7 !== 0 || match$8 !== 0) {
      exit = 1;
    } else {
      tmp = match$6.toString() + " events found";
    }
  }
  if (exit === 1) {
    tmp = match$6 !== 0 ? (
        match$7 !== 0 ? (
            match$8 !== 0 ? match$6.toString() + " events, " + match$7.toString() + " properties and " + match$8.toString() + " sources found" : match$6.toString() + " events and " + match$7.toString() + " properties found"
          ) : match$6.toString() + " events and " + match$8.toString() + " sources found"
      ) : match$7.toString() + " properties and " + match$8.toString() + " sources found";
  }
  var userProperties = Belt_Array.keepU(newAndUpdatedProperties, (function (property) {
          return property.sendAs === /* UserProperty */2;
        }));
  var propertyIdsOnEvents = Belt_Array.concatMany(Belt_Array.map(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
              return Belt_Array.concat($$event.propertyIds, $$event.updatedPropertyIds);
            })));
  var eventPropertiesWithoutEvents = Belt_Array.keepU(newAndUpdatedProperties, (function (property) {
          if (property.sendAs === /* EventProperty */1) {
            return Belt_Array.everyU(propertyIdsOnEvents, (function (param) {
                          return param[0] !== property.id;
                        }));
          } else {
            return false;
          }
        }));
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("header", {
                  className: headerStyles
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.px(580)),
                            tl: {
                              hd: Css.position("relative"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: backlinkStyles
                        }, React.createElement(TextButton.make, {
                              onClick: (function (param) {
                                  if (Belt_SetString.isEmpty(excludedEventIds) && Belt_Set.isEmpty(excludedEventPropertyIds) || window.confirm("You have an unfinished import draft. Are you sure you want to discard it?")) {
                                    window.history.back();
                                    return ;
                                  }
                                  
                                }),
                              style: "Blue",
                              size: "Medium",
                              children: null
                            }, React.createElement(Icons.ArrowLeft.make, {
                                  size: 11,
                                  color: Styles.Color.blue
                                }), React.createElement(Spacer.make, {
                                  display: "inlineBlock",
                                  width: 4
                                }), " Back")), React.createElement(Title.make, {
                          children: "Review Uploaded Data",
                          size: "Medium"
                        }))), React.createElement("div", {
                  className: topStyles
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.grey70,
                      children: "Please review the information below before completing the import process."
                    }), React.createElement("div", {
                      className: countsStyles
                    }, React.createElement("div", {
                          className: countStyles
                        }, React.createElement(IconCheckmarkCircle.make, {
                              size: 16,
                              color: Styles.Color.grassGreen
                            }), React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              children: tmp
                            })), React.createElement(Spacer.make, {
                          height: 12
                        }), React.createElement(CollapsedContent.make, {
                          button: (function (onClick, isExpanded) {
                              return React.createElement("div", {
                                          className: countStyles
                                        }, React.createElement(IconErrorHexagon.make, {
                                              size: 16
                                            }), React.createElement(Spacer.make, {
                                              width: 8
                                            }), React.createElement($$Text.make, {
                                              size: "Small",
                                              weight: "Semi",
                                              children: null
                                            }, String(parsedTrackingPlan.warnings.parseWarnings.length), parsedTrackingPlan.warnings.parseWarnings.length === 1 ? " error" : " errors"), React.createElement(Spacer.make, {
                                              width: 8
                                            }), Caml_obj.caml_equal(parsedTrackingPlan.warnings.parseWarnings, []) ? null : React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.flexGrow(1.0),
                                                        tl: {
                                                          hd: Css.justifyContent("flexEnd"),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    })
                                              }, React.createElement(Button.make, {
                                                    label: isExpanded ? "Show Errors" : "Hide Errors",
                                                    onClick: (function (param) {
                                                        return Curry._1(onClick, undefined);
                                                      }),
                                                    style: "outline"
                                                  })));
                            }),
                          children: (function (param) {
                              return React.createElement("p", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.fontSize(Css.px(11)),
                                                tl: {
                                                  hd: Css.margin2(Css.px(4), Css.px(0)),
                                                  tl: {
                                                    hd: Css.fontFamily(Styles.monoFontFamily),
                                                    tl: {
                                                      hd: Css.color(Styles.Color.grapeError),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              })
                                        }, Belt_Array.mapWithIndexU(parsedTrackingPlan.warnings.parseWarnings, (function (index, errorLine) {
                                                return React.createElement(React.Fragment, {
                                                            children: null,
                                                            key: errorLine
                                                          }, index === 0 ? null : React.createElement(Spacer.make, {
                                                                  height: 4
                                                                }), errorLine);
                                              })));
                            })
                        }), React.createElement(Spacer.make, {
                          height: 12
                        }), React.createElement("div", {
                          className: countStyles
                        }, React.createElement(IconWarning.make, {
                              color: Styles.Color.orange,
                              size: 16
                            }), React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              children: null
                            }, String(Belt_Array.reduceU(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), 0, (function (sum, $$event) {
                                        return sum + $$event.warnings.length | 0;
                                      })) + Belt_Array.reduceU(newAndUpdatedProperties, 0, (function (sum, property) {
                                        return sum + property.warnings.length | 0;
                                      })) | 0), " warnings", React.createElement($$Text.make, {
                                  element: "Span",
                                  weight: "Regular",
                                  children: " (see details in the table below)"
                                }))))), React.createElement("div", {
                  className: tableIntroStyles
                }, React.createElement("div", {
                      className: tableIntroLeftStyles
                    }, React.createElement($$Text.make, {
                          size: "Large",
                          weight: "Semi",
                          color: Styles.Color.grey90,
                          children: null
                        }, "Import ", React.createElement($$Text.make, {
                              element: "Span",
                              size: "Medium",
                              weight: "Medium",
                              color: Styles.Color.grey70,
                              children: "(" + selectedEventCount.toString() + " events)"
                            }))), !hasAllowLargeImportOnBranchFeatureFlag && actionsCount > 5000 ? React.createElement(Button.make, {
                        disabled: isLoading,
                        label: isLoading ? "Importing..." : "Import " + String(selectedEventCount) + " Events & " + String(selectedPropertiesCount) + " Properties to main",
                        onClick: (function (param) {
                            return handleFinishImport("Master");
                          })
                      }) : (
                    typeof currentBranch === "object" ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Medium",
                                color: Styles.Color.grey50,
                                children: "Import " + selectedEventCount.toString() + " Events & " + selectedPropertiesCount.toString() + " Properties to…"
                              }), React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    width: 2
                                  }), React.createElement(IconCheckmark.make, {
                                    size: 12,
                                    color: Styles.Color.mintGreen
                                  })), React.createElement(Spacer.make, {
                                width: 2
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.mintGreen,
                                children: currentBranchName
                              }), React.createElement(Spacer.make, {
                                width: 8
                              }), React.createElement(Button.make, {
                                disabled: isLoading,
                                label: isLoading ? "Importing…" : "Import",
                                onClick: (function (param) {
                                    Curry._1(setLoading, (function (param) {
                                            return true;
                                          }));
                                    return handleFinishImport({
                                                NAME: "Branch",
                                                VAL: {
                                                  id: branchId,
                                                  name: currentBranchName
                                                }
                                              });
                                  })
                              })) : (
                        hasEventsOnModel ? (
                            selectedBranch !== undefined ? React.createElement(ImportReview$ImportBranchPicker, {
                                    selectedEventCount: selectedEventCount,
                                    selectedPropertiesCount: selectedPropertiesCount,
                                    openBranches: openBranches,
                                    setSelectedBranch: setSelectedBranch,
                                    schemaId: schemaId,
                                    branchId: branchId,
                                    selectedBranch: selectedBranch,
                                    handleFinishImport: handleFinishImport,
                                    canSelectMain: canSelectMain
                                  }) : React.createElement(ImportReview$ImportBranchPicker, {
                                    selectedEventCount: selectedEventCount,
                                    selectedPropertiesCount: selectedPropertiesCount,
                                    openBranches: openBranches,
                                    setSelectedBranch: setSelectedBranch,
                                    schemaId: schemaId,
                                    branchId: branchId,
                                    selectedBranch: undefined,
                                    handleFinishImport: handleFinishImport,
                                    canSelectMain: canSelectMain
                                  })
                          ) : React.createElement(Button.make, {
                                disabled: isLoading,
                                label: isLoading ? "Importing..." : "Import " + String(selectedEventCount) + " Events & " + String(selectedPropertiesCount) + " Properties to main",
                                onClick: (function (param) {
                                    return handleFinishImport(currentBranch);
                                  })
                              })
                      )
                  )), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.overflowX("auto"),
                        tl: {
                          hd: Css.width(Css.pct(100.0)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("table", {
                      className: Curry._1(Css.style, {
                            hd: Css.borderCollapse("separate"),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.minWidth(Css.vw(100.0)),
                                tl: {
                                  hd: Css.position("relative"),
                                  tl: {
                                    hd: Css.borderSpacing(Css.px(0)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents).length === 0 ? null : React.createElement("thead", {
                            className: Curry._1(Css.style, {
                                  hd: Css.position("sticky"),
                                  tl: {
                                    hd: Css.top(Css.px(0)),
                                    tl: {
                                      hd: Css.selector(" th", {
                                            hd: Css.textAlign("left"),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.white),
                                              tl: {
                                                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                                                tl: /* [] */0
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement("tr", undefined, React.createElement("th", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.padding(Css.px(12)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement("input", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.margin(Css.px(0)),
                                              tl: {
                                                hd: Css.cursor("pointer"),
                                                tl: {
                                                  hd: Css.display("block"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }),
                                        checked: Belt_SetString.isEmpty(excludedEventIds),
                                        type: "checkbox",
                                        onChange: (function (param) {
                                            return Curry._1(setExcludedEventIds, (function (excluded) {
                                                          if (Belt_SetString.isEmpty(excluded)) {
                                                            return Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
                                                                              return $$event.id;
                                                                            })));
                                                          }
                                                          
                                                        }));
                                          })
                                      })), React.createElement("th", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                                          tl: /* [] */0
                                        })
                                  }), React.createElement("th", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.paddingRight(Css.px(12)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        color: Styles.Color.grey70,
                                        children: "EVENT NAME"
                                      })), hasNameMapping ? React.createElement("th", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.paddingRight(Css.px(12)),
                                            tl: {
                                              hd: Css.paddingLeft(Css.px(12)),
                                              tl: {
                                                hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Tiny",
                                          weight: "Semi",
                                          color: Styles.Color.grey70,
                                          children: "NAME MAPPING"
                                        })) : null, React.createElement("th", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.paddingRight(Css.px(12)),
                                          tl: {
                                            hd: Css.paddingLeft(Css.px(12)),
                                            tl: {
                                              hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                                              tl: /* [] */0
                                            }
                                          }
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        color: Styles.Color.grey70,
                                        children: "DESCRIPTION"
                                      })), hasCategories ? React.createElement("th", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.paddingRight(Css.px(12)),
                                            tl: {
                                              hd: Css.paddingLeft(Css.px(12)),
                                              tl: {
                                                hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Tiny",
                                          weight: "Semi",
                                          color: Styles.Color.grey70,
                                          children: "CATEGORIES"
                                        })) : null, hasTags ? React.createElement("th", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.paddingRight(Css.px(48)),
                                            tl: {
                                              hd: Css.paddingLeft(Css.px(12)),
                                              tl: {
                                                hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Tiny",
                                          weight: "Semi",
                                          color: Styles.Color.grey70,
                                          children: "TAGS"
                                        })) : null, Belt_Array.map(parsedTrackingPlan.sources, (function (source) {
                                      return React.createElement("th", {
                                                  key: source,
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                                                        tl: {
                                                          hd: Css.paddingLeft(Css.px(12)),
                                                          tl: {
                                                            hd: Css.paddingRight(Css.px(12)),
                                                            tl: {
                                                              hd: Css.maxWidth(Css.px(80)),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      })
                                                }, React.createElement($$Text.make, {
                                                      size: "Small",
                                                      weight: "Semi",
                                                      singleLine: true,
                                                      color: Styles.Color.grey70,
                                                      children: source
                                                    }), React.createElement($$Text.make, {
                                                      size: "Tiny",
                                                      weight: "Semi",
                                                      color: Styles.Color.grey50,
                                                      children: "SOURCE"
                                                    }));
                                    })), React.createElement("th", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.paddingRight(Css.px(12)),
                                          tl: {
                                            hd: Css.paddingLeft(Css.px(12)),
                                            tl: {
                                              hd: Css.width(Css.pct(100.0)),
                                              tl: {
                                                hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.grey30),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        color: Styles.Color.grey70,
                                        children: "WARNINGS"
                                      })))), React.createElement("tbody", undefined, Belt_Array.concatMany([
                              userProperties.length !== 0 ? [React.createElement(ImportReviewRow.UserProperties.make, {
                                        properties: userProperties,
                                        excludedProperties: excludedUserPropertyIds,
                                        onPropertyToggle: handleIncludeUserPropertyToggle,
                                        onUserPropertiesIncludedToggle: handleExcludeAllUserPropertiesToggle,
                                        userPropertiesIncluded: !excludeAllUserProperties,
                                        key: "user-properties"
                                      })] : [],
                              eventPropertiesWithoutEvents.length !== 0 ? [React.createElement(ImportReviewRow.EventPropertiesWithoutEvents.make, {
                                        properties: eventPropertiesWithoutEvents,
                                        key: "event-properties"
                                      })] : [],
                              Belt_Array.map(parsedTrackingPlan.newEvents, (function ($$event) {
                                      return React.createElement(ImportReviewRow.$$Event.make, {
                                                  event: $$event,
                                                  included: !Belt_SetString.has(excludedEventIds, $$event.id),
                                                  properties: Belt_Array.keepMapU($$event.propertyIds, (function (param) {
                                                          var id = param[0];
                                                          return Belt_Array.getByU(newAndUpdatedProperties, (function (property) {
                                                                        return property.id === id;
                                                                      }));
                                                        })),
                                                  propertyGroups: Belt_Array.keepMapU($$event.propertyGroupIds, (function (id) {
                                                          return Belt_Array.getByU(parsedTrackingPlan.propertyGroups, (function (group) {
                                                                        return group.id === id;
                                                                      }));
                                                        })),
                                                  propertyGroupProperties: Belt_Array.concatMany(Belt_Array.map(Belt_Array.keepMapU($$event.propertyGroupIds, (function (id) {
                                                                  return Belt_Array.getByU(parsedTrackingPlan.propertyGroups, (function (group) {
                                                                                return group.id === id;
                                                                              }));
                                                                })), (function (group) {
                                                              return Belt_Array.keepMapU(group.properties, (function (param) {
                                                                            var id = param.id;
                                                                            return Belt_Array.getByU(newAndUpdatedProperties, (function (property) {
                                                                                          return property.id === id;
                                                                                        }));
                                                                          }));
                                                            }))),
                                                  onToggle: handleIncludeToggle,
                                                  excludedProperties: excludedEventPropertyIds,
                                                  sources: parsedTrackingPlan.sources,
                                                  hasCategories: hasCategories,
                                                  hasTags: hasTags,
                                                  hasNameMapping: hasNameMapping,
                                                  onPropertyToggle: handleIncludePropertyToggle,
                                                  model: model,
                                                  key: $$event.id
                                                });
                                    })),
                              Belt_Array.map(parsedTrackingPlan.updatedEvents, (function ($$event) {
                                      return React.createElement(ImportReviewRow.$$Event.make, {
                                                  event: $$event,
                                                  included: !Belt_SetString.has(excludedEventIds, $$event.id),
                                                  properties: Belt_Array.keepMapU(Belt_Array.concat($$event.propertyIds, $$event.updatedPropertyIds), (function (param) {
                                                          var id = param[0];
                                                          return Belt_Array.getByU(newAndUpdatedProperties, (function (property) {
                                                                        return property.id === id;
                                                                      }));
                                                        })),
                                                  propertyGroups: Belt_Array.keepMapU($$event.propertyGroupIds, (function (id) {
                                                          return Belt_Array.getByU(parsedTrackingPlan.propertyGroups, (function (group) {
                                                                        return group.id === id;
                                                                      }));
                                                        })),
                                                  propertyGroupProperties: Belt_Array.concatMany(Belt_Array.map(Belt_Array.keepMapU($$event.propertyGroupIds, (function (id) {
                                                                  return Belt_Array.getByU(parsedTrackingPlan.propertyGroups, (function (group) {
                                                                                return group.id === id;
                                                                              }));
                                                                })), (function (group) {
                                                              return Belt_Array.keepMapU(group.properties, (function (param) {
                                                                            var id = param.id;
                                                                            return Belt_Array.getByU(newAndUpdatedProperties, (function (property) {
                                                                                          return property.id === id;
                                                                                        }));
                                                                          }));
                                                            }))),
                                                  onToggle: handleIncludeToggle,
                                                  excludedProperties: excludedEventPropertyIds,
                                                  sources: parsedTrackingPlan.sources,
                                                  hasCategories: hasCategories,
                                                  hasTags: hasTags,
                                                  hasNameMapping: hasNameMapping,
                                                  updatedEvent: true,
                                                  onPropertyToggle: handleIncludePropertyToggle,
                                                  model: model,
                                                  key: $$event.id
                                                });
                                    }))
                            ])))));
}

var make = ImportReview;

exports.rootStyles = rootStyles;
exports.headerStyles = headerStyles;
exports.topStyles = topStyles;
exports.backlinkStyles = backlinkStyles;
exports.countsStyles = countsStyles;
exports.countStyles = countStyles;
exports.tableIntroStyles = tableIntroStyles;
exports.tableIntroLeftStyles = tableIntroLeftStyles;
exports.StringTupleComparator = StringTupleComparator;
exports.ImportBranchPicker = ImportBranchPicker;
exports.make = make;
/* rootStyles Not a pure module */
