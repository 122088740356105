// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var IconCopy = require("./IconCopy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var CopyTextToClipboard = require("copy-text-to-clipboard");

var animationTime = Styles.Duration.$$default;

var defaultStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css_Legacy_Core.SVG.fill(Styles.Color.blue),
                tl: {
                  hd: Css_Legacy_Core.SVG.stroke(Styles.Color.blue),
                  tl: {
                    hd: Css.hover({
                          hd: Css_Legacy_Core.SVG.fill(Styles.Color.blueSecondary),
                          tl: {
                            hd: Css_Legacy_Core.SVG.stroke(Styles.Color.blueSecondary),
                            tl: /* [] */0
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var popupStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(0)),
        tl: {
          hd: Css.left(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.pct(95)),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.justifyContent("center"),
                tl: {
                  hd: Css.pointerEvents("none"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function popupTextStyles(justCopied) {
  return Curry._1(Css.style, {
              hd: Css.padding2(Css.px(4), Css.px(8)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.grey80),
                tl: {
                  hd: Css.borderRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.color(Styles.Color.white),
                    tl: {
                      hd: Css.fontWeight(Styles.FontWeight.semi),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.tiny),
                        tl: {
                          hd: Css.transform({
                                NAME: "translateY",
                                VAL: Css.px(justCopied ? 0 : 5)
                              }),
                          tl: {
                            hd: Css.opacity(justCopied ? 1 : 0),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: animationTime
                                  }, undefined, undefined, "all"),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function wait(delayInMs) {
  return new Promise((function (resolve, param) {
                setTimeout((function (param) {
                        return resolve(undefined);
                      }), delayInMs);
                
              }));
}

function CopyButtonLazy(Props) {
  var loadingOpt = Props.loading;
  var generateCopyString = Props.generateCopyString;
  var stylesOpt = Props.styles;
  var titleOpt = Props.title;
  var onCopyMessageOpt = Props.onCopyMessage;
  var onClickOpt = Props.onClick;
  var children = Props.children;
  var loading = loadingOpt !== undefined ? loadingOpt : false;
  var styles = stylesOpt !== undefined ? stylesOpt : defaultStyles;
  var title = titleOpt !== undefined ? titleOpt : "";
  var onCopyMessage = onCopyMessageOpt !== undefined ? onCopyMessageOpt : "Copied!";
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (param) {
        
      });
  var match = React.useState(function () {
        return "Initial";
      });
  var setCopyState = match[1];
  var copyState = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setTimeoutId = match$1[1];
  var timeoutId = match$1[0];
  React.useEffect((function () {
          return (function (param) {
                    return Belt_Option.forEach(timeoutId, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), []);
  var handleCopy = function (param) {
    Curry._1(onClick, undefined);
    Curry._1(setCopyState, (function (param) {
            return "Generating";
          }));
    wait(animationTime | 0).then(function (param) {
          var copyString = Curry._1(generateCopyString, undefined);
          CopyTextToClipboard(copyString);
          Curry._1(setCopyState, (function (param) {
                  return "Generated";
                }));
          var timeoutId = setTimeout((function (param) {
                  return Curry._1(setCopyState, (function (param) {
                                return "Initial";
                              }));
                }), 1000);
          Curry._1(setTimeoutId, (function (param) {
                  return Caml_option.some(timeoutId);
                }));
          return Promise.resolve(undefined);
        });
    
  };
  return React.createElement("button", {
              className: styles,
              title: title,
              disabled: loading,
              onClick: handleCopy
            }, React.createElement("div", {
                  className: popupStyles
                }, React.createElement("div", {
                      className: popupTextStyles(copyState !== "Initial")
                    }, copyState === "Generating" ? "Generating..." : (
                        copyState === "Generated" ? onCopyMessage : null
                      ))), Belt_Option.mapWithDefault(children, React.createElement(IconCopy.make, {
                      size: 12
                    }), (function (children) {
                    return children;
                  })));
}

var make = CopyButtonLazy;

exports.animationTime = animationTime;
exports.defaultStyles = defaultStyles;
exports.popupStyles = popupStyles;
exports.popupTextStyles = popupTextStyles;
exports.wait = wait;
exports.make = make;
/* defaultStyles Not a pure module */
