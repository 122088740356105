// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var DestinationSource = require("./DestinationSource.bs.js");

function isDestinationSupported(destination, source) {
  var destinationType = destination.type_;
  if (destinationType !== undefined) {
    return Belt_List.has(AvoConfig.getSupportedDestinations(source.platform, source.language), destinationType, (function (a, b) {
                  return a === b;
                }));
  } else {
    return false;
  }
}

var rootStyles = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100)),
      tl: {
        hd: Css.borderRadius(Css.px(6)),
        tl: {
          hd: Css.border(Css.px(1), "solid", Styles.Color.grey40),
          tl: /* [] */0
        }
      }
    });

var emptyStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(30)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.flexDirection("column"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var footerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.padding(Css.px(20)),
              tl: {
                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey20),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function DestinationSources(Props) {
  var destination = Props.destination;
  var sources = Props.sources;
  var onEnableAll = Props.onEnableAll;
  var onAddSource = Props.onAddSource;
  var globalSend = Props.globalSend;
  var onToggleSource = Props.onToggleSource;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var availability = AvoLimits.ConfigEventSourcesAndDestinations.computeAvailability(workspace);
  var allSupportedSourcesAreSelected = Belt_List.every(sources, (function (source) {
          if (isDestinationSupported(destination, source)) {
            return Belt_List.someU(source.destinations, (function (param) {
                          return param.destinationId === destination.id;
                        }));
          } else {
            return true;
          }
        }));
  var match = destination.type_;
  return React.createElement("section", undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginLeft(Css.px(20)),
                            tl: /* [] */0
                          })
                    }, React.createElement($$Text.make, {
                          element: "H1",
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.grey80,
                          children: "Sources"
                        })), availability === "Unavailable" && !allSupportedSourcesAreSelected ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(Button.make, {
                            label: "Enable all",
                            onClick: (function (param) {
                                var name = destination.name;
                                if (window.confirm(name !== undefined ? "Enabling all sources will add " + name + " as a destination to all sources. This cannot be undone unless you upgrade your plan. Are you sure that you want to enable all sources for " + name + "?" : "You are about to add this destination to all sources. This cannot be undone unless you upgrade your plan. Are you sure that you want to enable all sources?")) {
                                  return Curry._1(onEnableAll, undefined);
                                }
                                
                              }),
                            style: "outline"
                          })) : null), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement("div", {
                  className: rootStyles
                }, match !== undefined ? (
                    sources ? Belt_List.toArray(Belt_List.map(sources, (function (source) {
                                  return React.createElement(DestinationSource.make, {
                                              source: source,
                                              destination: destination,
                                              globalSend: globalSend,
                                              onChange: (function (isSelected) {
                                                  return Curry._2(onToggleSource, source, isSelected);
                                                }),
                                              availability: availability,
                                              key: source.path
                                            });
                                }))) : React.createElement("div", {
                            className: emptyStyles
                          }, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                color: Styles.Color.grey70,
                                children: "No sources defined"
                              }), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.maxWidth(Css.px(600)),
                                      tl: {
                                        hd: Css.marginTop(Css.px(10)),
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.createElement(Button.make, {
                                    label: "Add Source",
                                    onClick: onAddSource
                                  })))
                  ) : React.createElement("div", {
                        className: emptyStyles
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.grey70,
                            children: "You must select analytics tool before configuring sources."
                          }))), availability === "Available" ? null : React.createElement("footer", {
                    className: footerStyles
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.grey70,
                        children: availability === "Unavailable" ? "Upgrade plan to make changes" : "Upgrade plan to keep destinations editable"
                      }), React.createElement(Button.make, {
                        label: "Learn more",
                        onClick: (function (param) {
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "ConfigureEventsPerPlatform"
                                        }
                                      });
                          }),
                        style: availability === "Unavailable" ? "primary" : "outline"
                      })));
}

var make = DestinationSources;

exports.isDestinationSupported = isDestinationSupported;
exports.rootStyles = rootStyles;
exports.emptyStyles = emptyStyles;
exports.footerStyles = footerStyles;
exports.make = make;
/* rootStyles Not a pure module */
