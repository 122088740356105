// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Case = require("../../bs-case/src/case.bs.js");
var Icon = require("./Icon.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var NameUtils = require("../../shared/utils/NameUtils.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function AutoFillInputCreateItem(Props) {
  var name = Props.name;
  var correctCasing = Props.correctCasing;
  var isActive = Props.isActive;
  var nameWithCorrectCasing = NameUtils.getCorrectCase(correctCasing, name);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("row"),
                      tl: {
                        hd: Css.alignItems("flexStart"),
                        tl: {
                          hd: Css.paddingTop(Css.px(16)),
                          tl: {
                            hd: Css.paddingRight(Css.px(10)),
                            tl: {
                              hd: Css.paddingBottom(Css.px(16)),
                              tl: {
                                hd: Css.paddingLeft(Css.px(10)),
                                tl: {
                                  hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey10),
                                  tl: {
                                    hd: Css.backgroundColor(isActive ? Styles.Color.blue10 : Styles.Color.white),
                                    tl: {
                                      hd: Css.selector("path", {
                                            hd: Css_Legacy_Core.SVG.fill(isActive ? Styles.Color.blue120 : Styles.Color.blue100),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.color(isActive ? Styles.Color.blue120 : Styles.Color.blue100),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.backgroundColor(Styles.Color.blue10),
                                                tl: {
                                                  hd: Css.color(Styles.Color.blue120),
                                                  tl: {
                                                    hd: Css.selector("path", {
                                                          hd: Css_Legacy_Core.SVG.fill(Styles.Color.blue120),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(1)),
                        tl: /* [] */0
                      })
                }, React.createElement(Icon.make, {
                      type_: "plus"
                    })), React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement("div", undefined, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      children: "Create \"" + nameWithCorrectCasing + "\""
                    }), name === nameWithCorrectCasing ? null : React.createElement($$Text.make, {
                        size: "Small",
                        children: "Converted to " + Case.getLabel(correctCasing) + " Case"
                      })));
}

var make = AutoFillInputCreateItem;

exports.make = make;
/* Css Not a pure module */
