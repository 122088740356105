// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/src/Json.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var AvoConfigTypes = require("../../shared/models/AvoConfigTypes.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var TrackingPlanModel = require("./TrackingPlanModel.bs.js");
var WebhookPayloadFormat = require("../../shared/models/WebhookPayloadFormat.bs.js");

function printLiteral(lit) {
  var variant = lit.NAME;
  if (variant === "BooleanLit") {
    return Json_encode.object_({
                hd: [
                  "bool",
                  lit.VAL
                ],
                tl: /* [] */0
              });
  } else if (variant === "StringLit") {
    return Json_encode.object_({
                hd: [
                  "string",
                  lit.VAL
                ],
                tl: /* [] */0
              });
  } else if (variant === "FloatLit") {
    return Json_encode.object_({
                hd: [
                  "float",
                  lit.VAL
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "int",
                  lit.VAL
                ],
                tl: /* [] */0
              });
  }
}

function printBinOp(binOp) {
  if (binOp === "GT") {
    return "gt";
  } else if (binOp === "LE") {
    return "le";
  } else if (binOp === "GTE") {
    return "gte";
  } else if (binOp === "LEQ") {
    return "leq";
  } else if (binOp === "NEQ") {
    return "neq";
  } else {
    return "eq";
  }
}

function printExp(match_) {
  var variant = match_.NAME;
  if (variant === "BinOp") {
    return Pervasives.failwith("binop not implemented");
  } else if (variant === "Literal") {
    return Json_encode.object_({
                hd: [
                  "literal",
                  printLiteral(match_.VAL)
                ],
                tl: /* [] */0
              });
  } else if (variant === "PrefOp") {
    return Pervasives.failwith("prefop not implemented");
  } else if (variant === "Predicate") {
    return Pervasives.failwith("predicate not implemented");
  } else {
    return Json_encode.object_({
                hd: [
                  "identifier",
                  match_.VAL
                ],
                tl: /* [] */0
              });
  }
}

function printPropertyRef(param) {
  return Json_encode.object_(Belt_List.concat({
                  hd: [
                    "id",
                    param.id
                  ],
                  tl: {
                    hd: [
                      "description",
                      param.description
                    ],
                    tl: /* [] */0
                  }
                }, Belt_Option.mapWithDefault(param.pinnedValue, /* [] */0, (function (pinnedValue) {
                        return {
                                hd: [
                                  "pinnedValue",
                                  printLiteral(pinnedValue)
                                ],
                                tl: /* [] */0
                              };
                      }))));
}

function printCoreType(type_) {
  if (type_ === "Object") {
    return "object";
  } else if (type_ === "Any") {
    return "any";
  } else if (type_ === "Int") {
    return "int";
  } else if (type_ === "Float") {
    return "float";
  } else if (type_ === "Bool") {
    return "bool";
  } else if (type_ === "Long") {
    return "long";
  } else {
    return "string";
  }
}

function printValueType(valueType) {
  return Json_encode.object_({
              hd: [
                "type_",
                printCoreType(valueType.type_)
              ],
              tl: {
                hd: [
                  "validations",
                  Json_encode.list(printValidation, valueType.validations)
                ],
                tl: /* [] */0
              }
            });
}

function printValue(value) {
  return Json_encode.object_({
              hd: [
                "value",
                printValueType(value.VAL)
              ],
              tl: /* [] */0
            });
}

function printObjectItem(item) {
  return Json_encode.object_({
              hd: [
                "key",
                item.key
              ],
              tl: {
                hd: [
                  "list",
                  item.list
                ],
                tl: {
                  hd: [
                    "optional",
                    item.optional
                  ],
                  tl: {
                    hd: [
                      "value",
                      printValue(item.value)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function printValidation(validation) {
  var variant = validation.NAME;
  if (variant === "StartsWith") {
    return Json_encode.object_({
                hd: [
                  "startsWith",
                  printLiteral(validation.VAL)
                ],
                tl: /* [] */0
              });
  } else if (variant === "Max") {
    return Json_encode.object_({
                hd: [
                  "max",
                  printLiteral(validation.VAL)
                ],
                tl: /* [] */0
              });
  } else if (variant === "Min") {
    return Json_encode.object_({
                hd: [
                  "min",
                  printLiteral(validation.VAL)
                ],
                tl: /* [] */0
              });
  } else if (variant === "Contains") {
    return Json_encode.object_({
                hd: [
                  "contains",
                  printLiteral(validation.VAL)
                ],
                tl: /* [] */0
              });
  } else if (variant === "NestedProperty") {
    return Json_encode.object_({
                hd: [
                  "nestedProperty",
                  Json_encode.list(printPropertyRef, validation.VAL)
                ],
                tl: /* [] */0
              });
  } else if (variant === "Shape") {
    return Json_encode.object_({
                hd: [
                  "shape",
                  Json.stringify(Json_encode.list(printObjectItem, validation.VAL))
                ],
                tl: /* [] */0
              });
  } else if (variant === "EndsWith") {
    return Json_encode.object_({
                hd: [
                  "endsWith",
                  printLiteral(validation.VAL)
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "matches",
                  Belt_List.toArray(Belt_List.map(validation.VAL, (function (param) {
                              return Json_encode.object_({
                                          hd: [
                                            "exp",
                                            printExp(param[0])
                                          ],
                                          tl: {
                                            hd: [
                                              "excludedSources",
                                              Json_encode.list((function (prim) {
                                                      return prim;
                                                    }), param[1])
                                            ],
                                            tl: /* [] */0
                                          }
                                        });
                            })))
                ],
                tl: /* [] */0
              });
  }
}

function printSendAs(sendAs) {
  if (typeof sendAs !== "number") {
    return Json_encode.object_({
                hd: [
                  "group",
                  sendAs._0
                ],
                tl: /* [] */0
              });
  }
  switch (sendAs) {
    case /* SystemProperty */0 :
        return "system";
    case /* EventProperty */1 :
        return "event";
    case /* UserProperty */2 :
        return "user";
    
  }
}

function printItem(item) {
  var variant = item.NAME;
  if (variant === "Destination") {
    return Json_encode.object_({
                hd: [
                  "destination",
                  item.VAL
                ],
                tl: /* [] */0
              });
  } else if (variant === "Integration") {
    return Pervasives.failwith("unexpected item type");
  } else if (variant === "GroupType") {
    return Json_encode.object_({
                hd: [
                  "groupType",
                  item.VAL
                ],
                tl: /* [] */0
              });
  } else if (variant === "Property") {
    return Json_encode.object_({
                hd: [
                  "property",
                  item.VAL
                ],
                tl: /* [] */0
              });
  } else if (variant === "Event") {
    return Json_encode.object_({
                hd: [
                  "event",
                  item.VAL
                ],
                tl: /* [] */0
              });
  } else if (variant === "PropertyGroup") {
    return Json_encode.object_({
                hd: [
                  "propertyGroup",
                  item.VAL
                ],
                tl: /* [] */0
              });
  } else if (variant === "Goal") {
    return Json_encode.object_({
                hd: [
                  "goal",
                  item.VAL
                ],
                tl: /* [] */0
              });
  } else if (variant === "Metric") {
    return Json_encode.object_({
                hd: [
                  "metric",
                  item.VAL
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "source",
                  item.VAL
                ],
                tl: /* [] */0
              });
  }
}

function printCollaborationItem(collaborationItem) {
  if (collaborationItem.NAME === "CollaborationItemEvent") {
    return Json_encode.object_({
                hd: [
                  "event",
                  collaborationItem.VAL
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "branch",
                  collaborationItem.VAL
                ],
                tl: /* [] */0
              });
  }
}

function printRuleItem(item) {
  var variant = item.NAME;
  if (variant === "PropertyRef") {
    var match = item.VAL;
    return Json_encode.object_({
                hd: [
                  "propertyRef",
                  Json_encode.object_({
                        hd: [
                          "eventId",
                          match[0]
                        ],
                        tl: {
                          hd: [
                            "propertyId",
                            match[1]
                          ],
                          tl: /* [] */0
                        }
                      })
                ],
                tl: /* [] */0
              });
  }
  if (variant === "Property") {
    return Json_encode.object_({
                hd: [
                  "property",
                  item.VAL
                ],
                tl: /* [] */0
              });
  }
  if (variant === "Event") {
    return Json_encode.object_({
                hd: [
                  "event",
                  item.VAL
                ],
                tl: /* [] */0
              });
  }
  var match$1 = item.VAL;
  return Json_encode.object_({
              hd: [
                "eventObjectField",
                Json_encode.object_({
                      hd: [
                        "eventId",
                        match$1[0]
                      ],
                      tl: {
                        hd: [
                          "propertyId",
                          match$1[1]
                        ],
                        tl: {
                          hd: [
                            "nestedPropertyId",
                            match$1[2]
                          ],
                          tl: /* [] */0
                        }
                      }
                    })
              ],
              tl: /* [] */0
            });
}

function printMappingDestination(mappingDestination) {
  if (typeof mappingDestination === "object") {
    return Json_encode.object_({
                hd: [
                  "destinationId",
                  mappingDestination.VAL
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "allDestinations",
                  true
                ],
                tl: /* [] */0
              });
  }
}

function printNameMapping(param) {
  return Json_encode.object_({
              hd: [
                "nameMapping",
                Json_encode.object_({
                      hd: [
                        "destination",
                        Json_encode.nullable(printMappingDestination, param.destination)
                      ],
                      tl: {
                        hd: [
                          "name",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), param.name)
                        ],
                        tl: /* [] */0
                      }
                    })
              ],
              tl: /* [] */0
            });
}

function printSegmentIntegrationOption(param) {
  return Json_encode.object_({
              hd: [
                "key",
                param[0]
              ],
              tl: {
                hd: [
                  "enabled",
                  param[1]
                ],
                tl: /* [] */0
              }
            });
}

function printSegmentIntegrationOptionRule(param) {
  return Json_encode.object_({
              hd: [
                "segmentIntegrationOption",
                printSegmentIntegrationOption([
                      param[0],
                      param[1]
                    ])
              ],
              tl: /* [] */0
            });
}

function printRuleDefinition(definition) {
  var variant = definition.NAME;
  if (variant === "SegmentIntegrationOption") {
    return printSegmentIntegrationOptionRule(definition.VAL);
  } else if (variant === "NameMapping") {
    return printNameMapping(definition.VAL);
  } else {
    return Json_encode.object_({
                hd: [
                  "pinnedValue",
                  printLiteral(definition.VAL)
                ],
                tl: /* [] */0
              });
  }
}

function printRule(param) {
  return Json_encode.object_({
              hd: [
                "id",
                param.id
              ],
              tl: {
                hd: [
                  "item",
                  printRuleItem(param.item)
                ],
                tl: {
                  hd: [
                    "definition",
                    printRuleDefinition(param.definition)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function printRules(rules) {
  return Json_encode.list(printRule, rules);
}

function printRuleIdTuple(ruleIdTuple) {
  return Json_encode.object_({
              hd: [
                "fromRuleId",
                ruleIdTuple[0]
              ],
              tl: {
                hd: [
                  "toRuleId",
                  ruleIdTuple[1]
                ],
                tl: /* [] */0
              }
            });
}

function printPropertyAbsenceUpdateType(propertyAbsenceUpdateType) {
  if (typeof propertyAbsenceUpdateType === "number") {
    return Json_encode.object_({
                hd: [
                  "all",
                  true
                ],
                tl: /* [] */0
              });
  }
  switch (propertyAbsenceUpdateType.TAG | 0) {
    case /* Event */0 :
        return Json_encode.object_({
                    hd: [
                      "eventId",
                      propertyAbsenceUpdateType._0
                    ],
                    tl: /* [] */0
                  });
    case /* Source */1 :
        return Json_encode.object_({
                    hd: [
                      "sourceId",
                      propertyAbsenceUpdateType._0
                    ],
                    tl: /* [] */0
                  });
    case /* EventSource */2 :
        return Json_encode.object_({
                    hd: [
                      "eventId",
                      propertyAbsenceUpdateType._0
                    ],
                    tl: {
                      hd: [
                        "sourceId",
                        propertyAbsenceUpdateType._1
                      ],
                      tl: /* [] */0
                    }
                  });
    
  }
}

function printEventSourceAbsence(eventSourceAbsence) {
  if (eventSourceAbsence) {
    return Json_encode.object_({
                hd: [
                  "sometimesSent",
                  eventSourceAbsence._0
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "neverSent",
                  true
                ],
                tl: /* [] */0
              });
  }
}

function printPropertyAbsenceEventSourceMap(sourceMap) {
  if (sourceMap.TAG === /* AllSources */0) {
    return Json_encode.object_({
                hd: [
                  "allSources",
                  printEventSourceAbsence(sourceMap._0)
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "mixedSources",
                  Json_encode.object_(Belt_List.mapU(Belt_MapString.toList(sourceMap._0), (function (param) {
                              return [
                                      param[0],
                                      printEventSourceAbsence(param[1])
                                    ];
                            })))
                ],
                tl: /* [] */0
              });
  }
}

function printPropertyAbsenceEventMap(eventMap) {
  return Json_encode.object_(Belt_List.mapU(Belt_MapString.toList(eventMap), (function (param) {
                    return [
                            param[0],
                            printPropertyAbsenceEventSourceMap(param[1])
                          ];
                  })));
}

function printPropertyAbsence(absence) {
  var tmp;
  tmp = typeof absence === "number" ? [
      "alwaysSent",
      true
    ] : (
      absence.TAG === /* SometimesSent */0 ? [
          "sometimesSent",
          absence._0
        ] : [
          "mixed",
          printPropertyAbsenceEventMap(absence._0)
        ]
    );
  return Json_encode.object_({
              hd: tmp,
              tl: /* [] */0
            });
}

function printProperty(prop) {
  if (prop.TAG === /* PropertyRef */0) {
    return printPropertyRef(prop._0);
  }
  var match = prop._0;
  var absence = match.absence;
  var uniqueName = match.uniqueName;
  return Json_encode.object_(Belt_List.concatMany([
                  {
                    hd: [
                      "id",
                      match.id
                    ],
                    tl: {
                      hd: [
                        "name",
                        match.name
                      ],
                      tl: {
                        hd: [
                          "description",
                          match.description
                        ],
                        tl: {
                          hd: [
                            "type",
                            match.type_
                          ],
                          tl: {
                            hd: [
                              "sendAs",
                              printSendAs(match.sendAs)
                            ],
                            tl: {
                              hd: [
                                "validations",
                                Belt_List.toArray(Belt_List.map(match.validations, printValidation))
                              ],
                              tl: {
                                hd: [
                                  "analyticsTools",
                                  Belt_List.toArray(Belt_List.map(match.analyticsTools, AvoConfigTypes.analyticsToolToJs))
                                ],
                                tl: {
                                  hd: [
                                    "devPlatforms",
                                    Belt_List.toArray(Belt_List.map(match.devPlatforms, AvoConfigTypes.devPlatformToJs))
                                  ],
                                  tl: {
                                    hd: [
                                      "optional",
                                      match.optionalDeprecated
                                    ],
                                    tl: {
                                      hd: [
                                        "optionalWhenInObject",
                                        match.optionalWhenInObject
                                      ],
                                      tl: {
                                        hd: [
                                          "auto",
                                          match.auto
                                        ],
                                        tl: {
                                          hd: [
                                            "builtIn",
                                            TrackingPlanModel.builtInTypesToJs(match.builtIn)
                                          ],
                                          tl: {
                                            hd: [
                                              "list",
                                              match.list
                                            ],
                                            tl: {
                                              hd: [
                                                "operation",
                                                TrackingPlanModel.operationToJs(match.operation)
                                              ],
                                              tl: {
                                                hd: [
                                                  "excludedSources",
                                                  Belt_List.toArray(Belt_List.map(match.excludedSourcesDeprecated, (function (prim) {
                                                              return prim;
                                                            })))
                                                ],
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  },
                  absence !== undefined ? ({
                        hd: [
                          "absence",
                          printPropertyAbsence(absence)
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  uniqueName !== undefined ? ({
                        hd: [
                          "uniqueName",
                          uniqueName
                        ],
                        tl: /* [] */0
                      }) : /* [] */0
                ]));
}

function printProperties(properties) {
  return Json_encode.object_(Belt_List.map(properties, (function (x) {
                    return [
                            x._0.id,
                            printProperty(x)
                          ];
                  })));
}

function printPropertyBundle(propertyBundle) {
  return Json_encode.object_({
              hd: [
                "id",
                propertyBundle.id
              ],
              tl: {
                hd: [
                  "name",
                  propertyBundle.name
                ],
                tl: {
                  hd: [
                    "description",
                    propertyBundle.description
                  ],
                  tl: {
                    hd: [
                      "properties",
                      Json_encode.list((function (prim) {
                              return prim;
                            }), propertyBundle.properties)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function printPropertyBundles(propertyBundles) {
  return Json_encode.object_(Belt_List.map(propertyBundles, (function (propertyBundle) {
                    return [
                            propertyBundle.id,
                            printPropertyBundle(propertyBundle)
                          ];
                  })));
}

function printPropertyBundleRef(propertyBundleRef) {
  return Json_encode.object_({
              hd: [
                "id",
                propertyBundleRef.id
              ],
              tl: {
                hd: [
                  "description",
                  propertyBundleRef.description
                ],
                tl: /* [] */0
              }
            });
}

function printPropertyBundleRefs(propertyBundleRefs) {
  return Json_encode.object_(Belt_List.map(propertyBundleRefs, (function (propertyBundleRef) {
                    return [
                            propertyBundleRef.id,
                            printPropertyBundleRef(propertyBundleRef)
                          ];
                  })));
}

function printSourceDestinationMap(sourceDestinationMap) {
  var match = sourceDestinationMap.VAL;
  return Json_encode.object_({
              hd: [
                "sourceId",
                match[0]
              ],
              tl: {
                hd: [
                  "destinationIds",
                  Json_encode.list((function (prim) {
                          return prim;
                        }), match[1])
                ],
                tl: /* [] */0
              }
            });
}

function printPropertyWhitelist(param) {
  return Json_encode.object_({
              hd: [
                "analyticsPlatform",
                AvoConfigTypes.analyticsToolToJs(param[0])
              ],
              tl: {
                hd: [
                  "propertyId",
                  param[1]
                ],
                tl: /* [] */0
              }
            });
}

function printSourceValidation(validation) {
  var variant = validation.NAME;
  if (variant === "oneHourAfterMerge") {
    return "oneHourAfterMerge-" + validation.VAL.toString();
  } else if (variant === "currentVersion") {
    return "currentVersion-" + validation.VAL;
  } else if (variant === "twentyFourHoursAfterMerge") {
    return "twentyFourHoursAfterMerge-" + validation.VAL.toString();
  } else if (variant === "customVersion") {
    return "customVersion-" + validation.VAL;
  } else if (variant === "customTime") {
    return "customTime-" + validation.VAL.toString();
  } else {
    return "nextVersion-" + validation.VAL;
  }
}

function printSourceValidationMap(sourceValidationMap) {
  return Json_encode.array(Json_encode.object_, Belt_Array.map(Belt_MapString.toArray(sourceValidationMap), (function (param) {
                    return {
                            hd: [
                              "sourceId",
                              param[0]
                            ],
                            tl: {
                              hd: [
                                "validation",
                                Json_encode.nullable(printSourceValidation, param[1])
                              ],
                              tl: /* [] */0
                            }
                          };
                  })));
}

function printIncludedSource(param) {
  return Json_encode.object_({
              hd: [
                "sourceId",
                param.id
              ],
              tl: {
                hd: [
                  "includeInCodegen",
                  param.includeInCodegen
                ],
                tl: {
                  hd: [
                    "inspectorValidation",
                    Json_encode.nullable(printSourceValidation, param.inspectorValidation)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function printImageMetadata(imageMetadata) {
  return Json_encode.object_({
              hd: [
                "url",
                imageMetadata.url
              ],
              tl: {
                hd: [
                  "storagePath",
                  imageMetadata.storagePath
                ],
                tl: {
                  hd: [
                    "width",
                    imageMetadata.width
                  ],
                  tl: {
                    hd: [
                      "height",
                      imageMetadata.height
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function printFigmaMetadata(figmaMetadata) {
  return Json_encode.object_({
              hd: [
                "url",
                figmaMetadata.url
              ],
              tl: /* [] */0
            });
}

function printTriggerContent(triggerContent) {
  if (triggerContent.TAG === /* Image */0) {
    return Json_encode.object_({
                hd: [
                  "Image",
                  printImageMetadata(triggerContent._0)
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "Figma",
                  printFigmaMetadata(triggerContent._0)
                ],
                tl: /* [] */0
              });
  }
}

function printTriggerSources(triggerSources) {
  if (triggerSources) {
    return Json_encode.object_({
                hd: [
                  "SourceIds",
                  Json_encode.array((function (prim) {
                          return prim;
                        }), triggerSources._0)
                ],
                tl: /* [] */0
              });
  } else {
    return "SourceIndependent";
  }
}

function printEventTrigger(eventTrigger) {
  return Json_encode.object_({
              hd: [
                "id",
                eventTrigger.id
              ],
              tl: {
                hd: [
                  "sources",
                  printTriggerSources(eventTrigger.sources)
                ],
                tl: {
                  hd: [
                    "description",
                    eventTrigger.description
                  ],
                  tl: {
                    hd: [
                      "content",
                      Json_encode.nullable(printTriggerContent, eventTrigger.content)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function printEventTriggers(eventTriggers) {
  return Json_encode.array(printEventTrigger, eventTriggers);
}

function printTriggerIdTuple(triggerIdTuple) {
  return Json_encode.object_({
              hd: [
                "fromTriggerId",
                triggerIdTuple[0]
              ],
              tl: {
                hd: [
                  "toTriggerId",
                  triggerIdTuple[1]
                ],
                tl: /* [] */0
              }
            });
}

function printEvent($$event) {
  var triggers = $$event.triggers;
  var userGroupTypeIdsWithArchive = $$event.userGroupTypeIdsWithArchive;
  var eventGroupTypeIdsWithArchive = $$event.eventGroupTypeIdsWithArchive;
  var propertyWhitelist = $$event.propertyWhitelist;
  var includeDestinations = $$event.includeDestinations;
  var includeSources = $$event.includeSources;
  var excludeSourcesDeprecated = $$event.excludeSourcesDeprecated;
  var tags = $$event.tags;
  var types = $$event.types;
  var propertyBundles = $$event.propertyBundles;
  var uniqueName = $$event.uniqueName;
  return Json_encode.object_(Belt_List.concatMany([
                  {
                    hd: [
                      "id",
                      $$event.id
                    ],
                    tl: {
                      hd: [
                        "name",
                        $$event.name
                      ],
                      tl: {
                        hd: [
                          "description",
                          $$event.description
                        ],
                        tl: {
                          hd: [
                            "properties",
                            printProperties($$event.properties)
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  },
                  Belt_List.length(types) > 0 ? ({
                        hd: [
                          "types",
                          Belt_List.toArray(Belt_List.map(Belt_List.map(types, TrackingPlanModel.eventTypeToJs), (function (prim) {
                                      return prim;
                                    })))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  Belt_List.length(excludeSourcesDeprecated) > 0 ? ({
                        hd: [
                          "excludeSources",
                          Belt_List.toArray(Belt_List.map(excludeSourcesDeprecated, (function (prim) {
                                      return prim;
                                    })))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  Belt_List.length(includeSources) > 0 ? ({
                        hd: [
                          "includeSources",
                          Belt_List.toArray(Belt_List.map(includeSources, printIncludedSource))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  Belt_List.length(includeDestinations) > 0 ? ({
                        hd: [
                          "includeDestinations",
                          Belt_List.toArray(Belt_List.map(includeDestinations, printSourceDestinationMap))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  Belt_List.length(tags) > 0 ? ({
                        hd: [
                          "tags",
                          Belt_List.toArray(Belt_List.map(tags, (function (prim) {
                                      return prim;
                                    })))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  Belt_List.length(propertyBundles) > 0 ? ({
                        hd: [
                          "propertyGroups",
                          printPropertyBundleRefs(propertyBundles)
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  Belt_List.length(propertyWhitelist) > 0 ? ({
                        hd: [
                          "propertyWhitelist",
                          Belt_List.toArray(Belt_List.map(propertyWhitelist, printPropertyWhitelist))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  eventGroupTypeIdsWithArchive.length !== 0 ? ({
                        hd: [
                          "eventGroupTypeIds",
                          Belt_Array.map(eventGroupTypeIdsWithArchive, (function (prim) {
                                  return prim;
                                }))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  userGroupTypeIdsWithArchive.length !== 0 ? ({
                        hd: [
                          "userGroupTypeIds",
                          Belt_Array.map(userGroupTypeIdsWithArchive, (function (prim) {
                                  return prim;
                                }))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  uniqueName !== undefined ? ({
                        hd: [
                          "uniqueName",
                          uniqueName
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  triggers.length !== 0 ? ({
                        hd: [
                          "triggers",
                          Json_encode.array(printEventTrigger, triggers)
                        ],
                        tl: /* [] */0
                      }) : /* [] */0
                ]));
}

function printType(type_) {
  switch (type_.TAG | 0) {
    case /* BuiltInType */0 :
        var match = type_._0;
        if (match === "Object") {
          return Json_encode.object_({
                      hd: [
                        "type",
                        "object"
                      ],
                      tl: /* [] */0
                    });
        } else if (match === "Any") {
          return Json_encode.object_({
                      hd: [
                        "type",
                        "any"
                      ],
                      tl: /* [] */0
                    });
        } else if (match === "Int") {
          return Json_encode.object_({
                      hd: [
                        "type",
                        "int"
                      ],
                      tl: /* [] */0
                    });
        } else if (match === "Float") {
          return Json_encode.object_({
                      hd: [
                        "type",
                        "float"
                      ],
                      tl: /* [] */0
                    });
        } else if (match === "Bool") {
          return Json_encode.object_({
                      hd: [
                        "type",
                        "bool"
                      ],
                      tl: /* [] */0
                    });
        } else if (match === "Long") {
          return Json_encode.object_({
                      hd: [
                        "type",
                        "long"
                      ],
                      tl: /* [] */0
                    });
        } else {
          return Json_encode.object_({
                      hd: [
                        "type",
                        "string"
                      ],
                      tl: /* [] */0
                    });
        }
    case /* ExtendedType */1 :
        return Pervasives.failwith("extendedtype not supported");
    case /* TypeRef */2 :
        return Pervasives.failwith("typeref not supported");
    
  }
}

function printEvents(events) {
  return Json_encode.object_(Belt_List.map(events, (function (x) {
                    return [
                            x.id,
                            printEvent(x)
                          ];
                  })));
}

function printMigrations(migrations) {
  return Belt_List.toArray(Belt_List.map(migrations, TrackingPlanModel.migrationToJs));
}

function printSourceConfig(config) {
  if (config === "IosSwiftUseAmplitudeOver5") {
    return Json_encode.object_({
                hd: [
                  "IosSwiftUseAmplitudeOver5",
                  true
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "ValidateAvoEventFunction",
                  true
                ],
                tl: /* [] */0
              });
  }
}

function printSourceDestinationMode(mode) {
  switch (mode) {
    case /* AvoManaged */0 :
        return "AvoManaged";
    case /* DestinationInterface */1 :
        return "DestinationInterface";
    case /* Legacy */2 :
        return "Legacy";
    
  }
}

function printSourceDestinationRef(sourceDestination) {
  var match = sourceDestination.destinationMode;
  return Json_encode.object_(Belt_List.concat({
                  hd: [
                    "destinationId",
                    sourceDestination.destinationId
                  ],
                  tl: /* [] */0
                }, match >= 2 ? /* [] */0 : ({
                      hd: [
                        "destinationMode",
                        printSourceDestinationMode(match)
                      ],
                      tl: /* [] */0
                    })));
}

function printSource(source) {
  return Json_encode.object_(Belt_List.concatMany([{
                    hd: [
                      "id",
                      source.id
                    ],
                    tl: {
                      hd: [
                        "name",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), source.name)
                      ],
                      tl: {
                        hd: [
                          "language",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), Belt_Option.map(source.language, AvoConfigTypes.languageToJs))
                        ],
                        tl: {
                          hd: [
                            "platform",
                            Json_encode.nullable((function (prim) {
                                    return prim;
                                  }), Belt_Option.map(source.platform, AvoConfigTypes.devPlatformToJs))
                          ],
                          tl: {
                            hd: [
                              "destinations",
                              Json_encode.list(printSourceDestinationRef, source.destinations)
                            ],
                            tl: {
                              hd: [
                                "filename",
                                source.filename
                              ],
                              tl: {
                                hd: [
                                  "path",
                                  source.path
                                ],
                                tl: {
                                  hd: [
                                    "segmentDestinationOptions",
                                    Json_encode.list(printSegmentIntegrationOption, source.segmentDestinationOptions)
                                  ],
                                  tl: {
                                    hd: [
                                      "config",
                                      Json_encode.list(printSourceConfig, source.config)
                                    ],
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }]));
}

function printSources(sources) {
  return Json_encode.list(printSource, sources);
}

function printOtherApiKey(param) {
  return Json_encode.object_({
              hd: [
                "envName",
                param[0]
              ],
              tl: {
                hd: [
                  "apiKey",
                  param[1]
                ],
                tl: /* [] */0
              }
            });
}

function printDestination(destination) {
  return Json_encode.object_(Belt_List.concatMany([{
                    hd: [
                      "id",
                      destination.id
                    ],
                    tl: {
                      hd: [
                        "name",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), destination.name)
                      ],
                      tl: {
                        hd: [
                          "type_",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), Belt_Option.map(destination.type_, AvoConfigTypes.analyticsToolToJs))
                        ],
                        tl: {
                          hd: [
                            "apiKeys",
                            Json_encode.object_({
                                  hd: [
                                    "dev",
                                    Json_encode.nullable((function (prim) {
                                            return prim;
                                          }), destination.apiKeys.dev)
                                  ],
                                  tl: {
                                    hd: [
                                      "prod",
                                      Json_encode.nullable((function (prim) {
                                              return prim;
                                            }), destination.apiKeys.prod)
                                    ],
                                    tl: {
                                      hd: [
                                        "other",
                                        Json_encode.list(printOtherApiKey, destination.apiKeys.other)
                                      ],
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          ],
                          tl: {
                            hd: [
                              "includeUserPropsWithEventProps",
                              destination.includeUserPropsWithEventProps
                            ],
                            tl: {
                              hd: [
                                "disabledByDefault",
                                destination.disabledByDefault
                              ],
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }]));
}

function printGroupType(groupType) {
  return Json_encode.object_(Belt_List.concatMany([{
                    hd: [
                      "groupTypeId",
                      groupType.id
                    ],
                    tl: {
                      hd: [
                        "groupTypeName",
                        groupType.name
                      ],
                      tl: {
                        hd: [
                          "groupTypeDescription",
                          groupType.description
                        ],
                        tl: /* [] */0
                      }
                    }
                  }]));
}

function printDestinations(destinations) {
  return Json_encode.list(printDestination, destinations);
}

function printGroupTypes(groupTypes) {
  return Json_encode.array(printGroupType, groupTypes);
}

function printGoal(goal) {
  return Json_encode.object_(Belt_List.concatMany([{
                    hd: [
                      "id",
                      goal.id
                    ],
                    tl: {
                      hd: [
                        "name",
                        goal.name
                      ],
                      tl: {
                        hd: [
                          "description",
                          goal.description
                        ],
                        tl: {
                          hd: [
                            "metrics",
                            Json_encode.list((function (prim) {
                                    return prim;
                                  }), goal.metrics)
                          ],
                          tl: {
                            hd: [
                              "events",
                              Json_encode.list((function (prim) {
                                      return prim;
                                    }), goal.events)
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }]));
}

function printGoals(goals) {
  return Json_encode.list(printGoal, goals);
}

function printMetricItem(x) {
  var match = x.VAL;
  return Json_encode.object_({
              hd: [
                "event",
                Json_encode.object_(Belt_List.concatMany([{
                            hd: [
                              "id",
                              match.id
                            ],
                            tl: {
                              hd: [
                                "eventId",
                                match.eventId
                              ],
                              tl: {
                                hd: [
                                  "where",
                                  Json_encode.list((function (param) {
                                          return Json_encode.object_(Belt_List.concatMany([{
                                                            hd: [
                                                              "id",
                                                              param.id
                                                            ],
                                                            tl: {
                                                              hd: [
                                                                "propertyId",
                                                                param.propertyId
                                                              ],
                                                              tl: {
                                                                hd: [
                                                                  "binOp",
                                                                  printBinOp(param.binOp)
                                                                ],
                                                                tl: {
                                                                  hd: [
                                                                    "literals",
                                                                    Json_encode.list(printLiteral, param.literals)
                                                                  ],
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }
                                                          }]));
                                        }), match.where)
                                ],
                                tl: {
                                  hd: [
                                    "groupBy",
                                    Json_encode.list((function (param) {
                                            return Json_encode.object_(Belt_List.concatMany([{
                                                              hd: [
                                                                "id",
                                                                param.id
                                                              ],
                                                              tl: {
                                                                hd: [
                                                                  "propertyId",
                                                                  param.propertyId
                                                                ],
                                                                tl: /* [] */0
                                                              }
                                                            }]));
                                          }), match.groupBy)
                                  ],
                                  tl: /* [] */0
                                }
                              }
                            }
                          }]))
              ],
              tl: /* [] */0
            });
}

function printMetric(metric) {
  return Json_encode.object_(Belt_List.concatMany([{
                    hd: [
                      "id",
                      metric.id
                    ],
                    tl: {
                      hd: [
                        "name",
                        metric.name
                      ],
                      tl: {
                        hd: [
                          "description",
                          metric.description
                        ],
                        tl: {
                          hd: [
                            "type",
                            TrackingPlanModel.metricTypeToJs(metric.type_)
                          ],
                          tl: {
                            hd: [
                              "items",
                              Json_encode.list(printMetricItem, metric.items)
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }]));
}

function printMetrics(metrics) {
  return Json_encode.list(printMetric, metrics);
}

function printIntegrationConfig(x) {
  switch (x.TAG | 0) {
    case /* Protocols */0 :
        var match = x._0;
        return Json_encode.object_({
                    hd: [
                      "protocols",
                      Json_encode.object_({
                            hd: [
                              "accessKey",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match.accessKey)
                            ],
                            tl: {
                              hd: [
                                "trackingPlanId",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), match.trackingPlanId)
                              ],
                              tl: {
                                hd: [
                                  "workspace",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), match.workspace)
                                ],
                                tl: {
                                  hd: [
                                    "usePublicApi",
                                    match.usePublicApi
                                  ],
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    case /* Taxonomy */1 :
        var match$1 = x._0;
        return Json_encode.object_({
                    hd: [
                      "taxonomy",
                      Json_encode.object_({
                            hd: [
                              "apiKey",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match$1.apiKey)
                            ],
                            tl: {
                              hd: [
                                "secretKey",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), match$1.secretKey)
                              ],
                              tl: {
                                hd: [
                                  "euDataResidency",
                                  match$1.euDataResidency
                                ],
                                tl: /* [] */0
                              }
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    case /* Lexicon */2 :
        var match$2 = x._0;
        return Json_encode.object_({
                    hd: [
                      "lexicon",
                      Json_encode.object_({
                            hd: [
                              "userName",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match$2.userName)
                            ],
                            tl: {
                              hd: [
                                "secretKey",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), match$2.secretKey)
                              ],
                              tl: {
                                hd: [
                                  "projectId",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), match$2.projectId)
                                ],
                                tl: {
                                  hd: [
                                    "euDataResidency",
                                    match$2.euDataResidency
                                  ],
                                  tl: {
                                    hd: [
                                      "categoriesAsTags",
                                      match$2.categoriesAsTags
                                    ],
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    case /* Webhook */3 :
        var match$3 = x._0;
        return Json_encode.object_({
                    hd: [
                      "webhook",
                      Json_encode.object_({
                            hd: [
                              "webhookUrl",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match$3.webhookUrl)
                            ],
                            tl: {
                              hd: [
                                "payloadFormat",
                                WebhookPayloadFormat.encode(match$3.payloadFormat)
                              ],
                              tl: /* [] */0
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    case /* SnowplowDataStructures */4 :
        var match$4 = x._0;
        return Json_encode.object_({
                    hd: [
                      "snowplowDataStructures",
                      Json_encode.object_({
                            hd: [
                              "accessToken",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match$4.accessToken)
                            ],
                            tl: {
                              hd: [
                                "organizationId",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), match$4.organizationId)
                              ],
                              tl: {
                                hd: [
                                  "vendor",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), match$4.vendor)
                                ],
                                tl: /* [] */0
                              }
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    case /* MParticleDataMaster */5 :
        var match$5 = x._0;
        return Json_encode.object_({
                    hd: [
                      "mParticleDataMaster",
                      Json_encode.object_({
                            hd: [
                              "clientId",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match$5.clientId)
                            ],
                            tl: {
                              hd: [
                                "clientSecret",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), match$5.clientSecret)
                              ],
                              tl: {
                                hd: [
                                  "workspaceId",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), match$5.workspaceId)
                                ],
                                tl: {
                                  hd: [
                                    "planId",
                                    Json_encode.nullable((function (prim) {
                                            return prim;
                                          }), match$5.planId)
                                  ],
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    case /* RudderstackTrackingPlans */6 :
        var match$6 = x._0;
        return Json_encode.object_({
                    hd: [
                      "rudderstackTrackingPlans",
                      Json_encode.object_({
                            hd: [
                              "accessToken",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match$6.accessToken)
                            ],
                            tl: {
                              hd: [
                                "trackingPlanDisplayName",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), match$6.trackingPlanDisplayName)
                              ],
                              tl: {
                                hd: [
                                  "emailAddress",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), match$6.emailAddress)
                                ],
                                tl: /* [] */0
                              }
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    
  }
}

function printIntegrationFilters(filters) {
  return Json_encode.list((function (filterBy) {
                var variant = filterBy.NAME;
                if (variant === "Destination") {
                  return Json_encode.object_({
                              hd: [
                                "destination",
                                filterBy.VAL
                              ],
                              tl: /* [] */0
                            });
                } else if (variant === "Tag") {
                  return Json_encode.object_({
                              hd: [
                                "tag",
                                filterBy.VAL
                              ],
                              tl: /* [] */0
                            });
                } else {
                  return Json_encode.object_({
                              hd: [
                                "source",
                                filterBy.VAL
                              ],
                              tl: /* [] */0
                            });
                }
              }), filters);
}

function printIntegration(param) {
  return Json_encode.object_({
              hd: [
                "id",
                param.id
              ],
              tl: {
                hd: [
                  "name",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), param.name)
                ],
                tl: {
                  hd: [
                    "filters",
                    printIntegrationFilters(param.filters)
                  ],
                  tl: {
                    hd: [
                      "config",
                      Json_encode.nullable(printIntegrationConfig, param.config)
                    ],
                    tl: {
                      hd: [
                        "autoPublish",
                        param.autoPublish
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function printIntegrations(integrations) {
  return Json_encode.list(printIntegration, integrations);
}

function printArchive(param) {
  return Json_encode.object_({
              hd: [
                "properties",
                printProperties(param.properties)
              ],
              tl: {
                hd: [
                  "events",
                  printEvents(param.events)
                ],
                tl: {
                  hd: [
                    "sources",
                    Json_encode.list(printSource, param.sources)
                  ],
                  tl: {
                    hd: [
                      "destinations",
                      Json_encode.list(printDestination, param.destinations)
                    ],
                    tl: {
                      hd: [
                        "goals",
                        Json_encode.list(printGoal, param.goals)
                      ],
                      tl: {
                        hd: [
                          "metrics",
                          Json_encode.list(printMetric, param.metrics)
                        ],
                        tl: {
                          hd: [
                            "groupTypes",
                            Json_encode.array(printGroupType, param.groupTypes)
                          ],
                          tl: {
                            hd: [
                              "propertyGroups",
                              printPropertyBundles(param.propertyBundles)
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function printBranch(param) {
  return Json_encode.object_(Belt_List.concatMany([{
                    hd: [
                      "branchId",
                      param[0]
                    ],
                    tl: {
                      hd: [
                        "branchName",
                        param[1]
                      ],
                      tl: /* [] */0
                    }
                  }]));
}

function printBranches(branches) {
  return Json_encode.list(printBranch, branches);
}

function printBranchPointer(param) {
  return Json_encode.object_(Belt_List.concatMany([{
                    hd: [
                      "actionId",
                      param[0]
                    ],
                    tl: {
                      hd: [
                        "actionTimestamp",
                        param[1]
                      ],
                      tl: /* [] */0
                    }
                  }]));
}

function printPublishingSuccessStats(successStats) {
  return Json_encode.object_({
              hd: [
                "version",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), successStats.version)
              ],
              tl: {
                hd: [
                  "numSuccessfulEvents",
                  successStats.numSuccessfulEvents
                ],
                tl: {
                  hd: [
                    "numSuccessfulProperties",
                    successStats.numSuccessfulProperties
                  ],
                  tl: {
                    hd: [
                      "numSuccessfulCategories",
                      successStats.numSuccessfulCategories
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function printModel(param) {
  return Json_encode.object_(Belt_List.concat({
                  hd: [
                    "types",
                    Belt_List.toArray(Belt_List.map(param.types, printType))
                  ],
                  tl: {
                    hd: [
                      "properties",
                      printProperties(param.properties)
                    ],
                    tl: {
                      hd: [
                        "propertyGroups",
                        printPropertyBundles(param.propertyBundles)
                      ],
                      tl: {
                        hd: [
                          "events",
                          printEvents(param.events)
                        ],
                        tl: {
                          hd: [
                            "migrations",
                            printMigrations(param.migrations)
                          ],
                          tl: {
                            hd: [
                              "sources",
                              Json_encode.list(printSource, param.sources)
                            ],
                            tl: {
                              hd: [
                                "destinations",
                                Json_encode.list(printDestination, param.destinations)
                              ],
                              tl: {
                                hd: [
                                  "groupTypes",
                                  Json_encode.array(printGroupType, param.groupTypes)
                                ],
                                tl: {
                                  hd: [
                                    "goals",
                                    Json_encode.list(printGoal, param.goals)
                                  ],
                                  tl: {
                                    hd: [
                                      "metrics",
                                      Json_encode.list(printMetric, param.metrics)
                                    ],
                                    tl: {
                                      hd: [
                                        "archive",
                                        printArchive(param.archive)
                                      ],
                                      tl: {
                                        hd: [
                                          "openBranches",
                                          Json_encode.list(printBranch, param.openBranches)
                                        ],
                                        tl: {
                                          hd: [
                                            "rules",
                                            Json_encode.list(printRule, param.rules)
                                          ],
                                          tl: {
                                            hd: [
                                              "integrations",
                                              Json_encode.list(printIntegration, param.integrations)
                                            ],
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }, Belt_Option.mapWithDefault(param.branchPointer, /* [] */0, (function (branchPointer) {
                        return {
                                hd: [
                                  "branchPointer",
                                  printBranchPointer(branchPointer)
                                ],
                                tl: /* [] */0
                              };
                      }))));
}

function printAvoJson(param) {
  return Json_encode.object_({
              hd: [
                "types",
                Belt_List.toArray(Belt_List.map(param.types, printType))
              ],
              tl: {
                hd: [
                  "properties",
                  printProperties(param.properties)
                ],
                tl: {
                  hd: [
                    "events",
                    printEvents(param.events)
                  ],
                  tl: {
                    hd: [
                      "migrations",
                      printMigrations(param.migrations)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function printModelString(model) {
  return JSON.stringify(printModel(model));
}

function printAction(action) {
  var tmp;
  if (typeof action === "object") {
    var variant = action.NAME;
    if (variant === "UpdateDestinationDisabledByDefault") {
      var match = action.VAL;
      tmp = {
        hd: [
          "UpdateDestinationDisabledByDefault",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match[0]
                ],
                tl: {
                  hd: [
                    "disabledByDefault",
                    match[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddProperty") {
      var match$1 = action.VAL;
      tmp = {
        hd: [
          "AddProperty",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$1[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$1[1]
                  ],
                  tl: {
                    hd: [
                      "name",
                      match$1[2]
                    ],
                    tl: {
                      hd: [
                        "sendAs",
                        printSendAs(match$1[3])
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "MergeBranch") {
      var match$2 = action.VAL;
      tmp = {
        hd: [
          "MergeBranch",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$2[0]
                ],
                tl: {
                  hd: [
                    "branchName",
                    match$2[1]
                  ],
                  tl: {
                    hd: [
                      "actions",
                      Json_encode.list(printAction, match$2[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddEventType") {
      var match$3 = action.VAL;
      tmp = {
        hd: [
          "AddEventType",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$3[0]
                ],
                tl: {
                  hd: [
                    "type",
                    TrackingPlanModel.eventTypeToJs(match$3[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateTriggerContent") {
      var match$4 = action.VAL;
      tmp = {
        hd: [
          "UpdateTriggerContent",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$4[0]
                ],
                tl: {
                  hd: [
                    "triggerId",
                    match$4[1]
                  ],
                  tl: {
                    hd: [
                      "content",
                      Json_encode.nullable(printTriggerContent, match$4[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyValidationMinOrMax") {
      var match$5 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyValidationMinOrMax",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$5[0]
                ],
                tl: {
                  hd: [
                    "validationIndex",
                    match$5[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateSourceDestinationMode") {
      var match$6 = action.VAL;
      tmp = {
        hd: [
          "UpdateSourceDestinationMode",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$6[0]
                ],
                tl: {
                  hd: [
                    "destinationId",
                    match$6[1]
                  ],
                  tl: {
                    hd: [
                      "destinationMode",
                      printSourceDestinationMode(match$6[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "TogglePropertyValidationMatchSource") {
      var match$7 = action.VAL;
      tmp = {
        hd: [
          "TogglePropertyValidationMatchSource",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$7[0]
                ],
                tl: {
                  hd: [
                    "match",
                    match$7[1]
                  ],
                  tl: {
                    hd: [
                      "sourceId",
                      match$7[2]
                    ],
                    tl: {
                      hd: [
                        "isActive",
                        match$7[3]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyUniqueName") {
      var match$8 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyUniqueName",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$8[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$8[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateDestinationIncludeUserPropsWithEventProps") {
      var match$9 = action.VAL;
      tmp = {
        hd: [
          "UpdateDestinationIncludeUserPropsWithEventProps",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match$9[0]
                ],
                tl: {
                  hd: [
                    "includeUserPropsWithEventProps",
                    match$9[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddEvent") {
      var match$10 = action.VAL;
      tmp = {
        hd: [
          "AddEvent",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$10[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$10[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyPermanently") {
      tmp = {
        hd: [
          "RemovePropertyPermanently",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateSavedView") {
      var match$11 = action.VAL;
      tmp = {
        hd: [
          "CreateSavedView",
          Json_encode.object_({
                hd: [
                  "savedViewId",
                  match$11[0]
                ],
                tl: {
                  hd: [
                    "savedViewName",
                    match$11[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyFromWhitelist") {
      var match$12 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyFromWhitelist",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$12[0]
                ],
                tl: {
                  hd: [
                    "groupId",
                    match$12[1]
                  ],
                  tl: {
                    hd: [
                      "analyticsTool",
                      AvoConfigTypes.analyticsToolToJs(match$12[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SetBranchStatus") {
      var match$13 = action.VAL;
      tmp = {
        hd: [
          "SetBranchStatus",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$13[0]
                ],
                tl: {
                  hd: [
                    "branchName",
                    match$13[1]
                  ],
                  tl: {
                    hd: [
                      "branchStatus",
                      TrackingPlanModel.branchStatusToJs(match$13[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateLibraryDestination") {
      var match$14 = action.VAL;
      tmp = {
        hd: [
          "UpdateLibraryDestination",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$14[0]
                ],
                tl: {
                  hd: [
                    "path",
                    match$14[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "PaymentCompleted") {
      var match$15 = action.VAL;
      tmp = {
        hd: [
          "PaymentCompleted",
          Json_encode.object_({
                hd: [
                  "paymentSource",
                  match$15[0]
                ],
                tl: {
                  hd: [
                    "subscription",
                    match$15[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ImportDeprecated") {
      tmp = {
        hd: [
          "Import",
          Json_encode.object_({
                hd: [
                  "actions",
                  Json_encode.list(printAction, action.VAL)
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddGoal") {
      tmp = {
        hd: [
          "AddGoal",
          Json_encode.object_({
                hd: [
                  "goalId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateIntegrationAutoPublish") {
      var match$16 = action.VAL;
      tmp = {
        hd: [
          "UpdateIntegrationAutoPublish",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  match$16[0]
                ],
                tl: {
                  hd: [
                    "autoPublish",
                    match$16[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateDemoBranch") {
      tmp = {
        hd: [
          "CreateDemoBranch",
          Json_encode.object_({
                hd: [
                  "actions",
                  Json_encode.list(printAction, action.VAL)
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateGoalDescription") {
      var match$17 = action.VAL;
      tmp = {
        hd: [
          "UpdateGoalDescription",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$17[0]
                ],
                tl: {
                  hd: [
                    "description",
                    match$17[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "MigrateSourcesAndDestinationsToModel") {
      tmp = {
        hd: [
          "MigrateSourcesAndDestinationsToModel",
          Json_encode.object_({
                hd: [
                  "sources",
                  []
                ],
                tl: {
                  hd: [
                    "destinitions",
                    []
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyList") {
      var match$18 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyList",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$18[0]
                ],
                tl: {
                  hd: [
                    "list",
                    match$18[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SetMetricWhereV2") {
      var match$19 = action.VAL;
      tmp = {
        hd: [
          "SetMetricWhereV2",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$19[0]
                ],
                tl: {
                  hd: [
                    "metricItemId",
                    match$19[1]
                  ],
                  tl: {
                    hd: [
                      "eventId",
                      match$19[2]
                    ],
                    tl: {
                      hd: [
                        "id",
                        match$19[3]
                      ],
                      tl: {
                        hd: [
                          "propertyId",
                          match$19[4]
                        ],
                        tl: {
                          hd: [
                            "binOp",
                            printBinOp(match$19[5])
                          ],
                          tl: {
                            hd: [
                              "literals",
                              Json_encode.list(printLiteral, match$19[6])
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyName") {
      var match$20 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyName",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$20[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$20[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "InviteMember") {
      var match$21 = action.VAL;
      tmp = {
        hd: [
          "InviteMember",
          Json_encode.object_({
                hd: [
                  "email",
                  match$21[0]
                ],
                tl: {
                  hd: [
                    "role",
                    match$21[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyDescription") {
      var match$22 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyDescription",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$22[0]
                ],
                tl: {
                  hd: [
                    "description",
                    match$22[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddTag") {
      var match$23 = action.VAL;
      tmp = {
        hd: [
          "AddTag",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$23[0]
                ],
                tl: {
                  hd: [
                    "tag",
                    match$23[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ReorderMetricsInGoal") {
      var match$24 = action.VAL;
      tmp = {
        hd: [
          "ReorderMetricsInGoal",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$24[0]
                ],
                tl: {
                  hd: [
                    "metricId",
                    match$24[1]
                  ],
                  tl: {
                    hd: [
                      "maybePredecessorId",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), match$24[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertySendAs") {
      var match$25 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertySendAs",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$25[0]
                ],
                tl: {
                  hd: [
                    "sendAs",
                    printSendAs(match$25[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddRule") {
      var match$26 = action.VAL;
      tmp = {
        hd: [
          "AddRule",
          Json_encode.object_({
                hd: [
                  "ruleId",
                  match$26[0]
                ],
                tl: {
                  hd: [
                    "item",
                    printRuleItem(match$26[1])
                  ],
                  tl: {
                    hd: [
                      "definition",
                      printRuleDefinition(match$26[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateSourceName") {
      var match$27 = action.VAL;
      tmp = {
        hd: [
          "UpdateSourceName",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$27[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$27[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "BranchComment") {
      var match$28 = action.VAL;
      tmp = {
        hd: [
          "BranchComment",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$28[0]
                ],
                tl: {
                  hd: [
                    "commentId",
                    match$28[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyType") {
      var match$29 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyType",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$29[0]
                ],
                tl: {
                  hd: [
                    "type",
                    match$29[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "LegacyIncludeDestinationInSource") {
      var match$30 = action.VAL;
      tmp = {
        hd: [
          "IncludeDestinationInSource",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$30[0]
                ],
                tl: {
                  hd: [
                    "destinationId",
                    match$30[1]
                  ],
                  tl: {
                    hd: [
                      "eventIds",
                      Json_encode.list((function (prim) {
                              return prim;
                            }), match$30[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddPropertyRef") {
      var match$31 = action.VAL;
      tmp = {
        hd: [
          "AddPropertyRef",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$31[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$31[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveInvite") {
      var match$32 = action.VAL;
      tmp = {
        hd: [
          "RemoveInvite",
          Json_encode.object_({
                hd: [
                  "email",
                  match$32[0]
                ],
                tl: {
                  hd: [
                    "role",
                    match$32[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "OpenBranch") {
      var match$33 = action.VAL;
      tmp = {
        hd: [
          "OpenBranch",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$33[0]
                ],
                tl: {
                  hd: [
                    "branchName",
                    match$33[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveEvent") {
      tmp = {
        hd: [
          "RemoveEvent",
          Json_encode.object_({
                hd: [
                  "eventId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddPropertyValidationMinOrMax") {
      var match$34 = action.VAL;
      tmp = {
        hd: [
          "AddPropertyValidationMinOrMax",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$34[0]
                ],
                tl: {
                  hd: [
                    "validationType",
                    TrackingPlanModel.constraint_ToJs(match$34[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ReorderEventsInGoal") {
      var match$35 = action.VAL;
      tmp = {
        hd: [
          "ReorderEventsInGoal",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$35[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$35[1]
                  ],
                  tl: {
                    hd: [
                      "maybePredecessorId",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), match$35[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "EventComment") {
      var match$36 = action.VAL;
      tmp = {
        hd: [
          "EventComment",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$36[0]
                ],
                tl: {
                  hd: [
                    "commentId",
                    match$36[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "PropertyGroupComment") {
      var match$37 = action.VAL;
      tmp = {
        hd: [
          "PropertyGroupComment",
          Json_encode.object_({
                hd: [
                  "propertyGroupId",
                  match$37[0]
                ],
                tl: {
                  hd: [
                    "commentId",
                    match$37[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ExcludeEventFromSourceV2") {
      var match$38 = action.VAL;
      tmp = {
        hd: [
          "ExcludeEventFromSourceV2",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$38[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$38[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateGroupType") {
      var match$39 = action.VAL;
      tmp = {
        hd: [
          "CreateGroupType",
          Json_encode.object_({
                hd: [
                  "groupTypeId",
                  match$39[0]
                ],
                tl: {
                  hd: [
                    "groupTypeName",
                    match$39[1]
                  ],
                  tl: {
                    hd: [
                      "groupTypeDescription",
                      match$39[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CollaboratorAdded") {
      var match$40 = action.VAL;
      tmp = {
        hd: [
          "CollaboratorAdded",
          Json_encode.object_({
                hd: [
                  "collaborationItem",
                  printCollaborationItem(match$40[0])
                ],
                tl: {
                  hd: [
                    "addedByUserId",
                    match$40[1]
                  ],
                  tl: {
                    hd: [
                      "userId",
                      match$40[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "LegacyToggleDestination") {
      var match$41 = action.VAL;
      tmp = {
        hd: [
          "ToggleDestination",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$41[0]
                ],
                tl: {
                  hd: [
                    "destinationId",
                    match$41[1]
                  ],
                  tl: {
                    hd: [
                      "isActive",
                      match$41[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeletePublicBranchImplementation") {
      tmp = {
        hd: [
          "DeletePublicBranchImplementation",
          Json_encode.object_({
                hd: [
                  "shareId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeleteSource") {
      tmp = {
        hd: [
          "DeleteSource",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "LegacyRemovePropertyRef") {
      var match$42 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyRef",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$42[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$42[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveMetricGroupBy") {
      var match$43 = action.VAL;
      tmp = {
        hd: [
          "RemoveMetricGroupBy",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$43[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$43[1]
                  ],
                  tl: {
                    hd: [
                      "id",
                      match$43[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateProgrammingLanguage") {
      var match$44 = action.VAL;
      tmp = {
        hd: [
          "UpdateProgrammingLanguage",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$44[0]
                ],
                tl: {
                  hd: [
                    "language",
                    AvoConfigTypes.languageToJs(match$44[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddMetric") {
      tmp = {
        hd: [
          "AddMetric",
          Json_encode.object_({
                hd: [
                  "metricId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateTriggerSources") {
      var match$45 = action.VAL;
      tmp = {
        hd: [
          "UpdateTriggerSources",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$45[0]
                ],
                tl: {
                  hd: [
                    "triggerId",
                    match$45[1]
                  ],
                  tl: {
                    hd: [
                      "sources",
                      printTriggerSources(match$45[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "MetricComment") {
      var match$46 = action.VAL;
      tmp = {
        hd: [
          "MetricComment",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$46[0]
                ],
                tl: {
                  hd: [
                    "commentId",
                    match$46[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateSourcePlatformV2") {
      var match$47 = action.VAL;
      tmp = {
        hd: [
          "UpdateSourcePlatformV2",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$47[0]
                ],
                tl: {
                  hd: [
                    "platform",
                    AvoConfigTypes.devPlatformToJs(match$47[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateSourcePlatformV3") {
      var match$48 = action.VAL;
      tmp = {
        hd: [
          "UpdateSourcePlatformV3",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$48[0]
                ],
                tl: {
                  hd: [
                    "platform",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), Belt_Option.map(match$48[1], AvoConfigTypes.devPlatformToJs))
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateEventUniqueNameV2") {
      var match$49 = action.VAL;
      tmp = {
        hd: [
          "UpdateEventUniqueNameV2",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$49[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$49[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateEventTrigger") {
      var match$50 = action.VAL;
      tmp = {
        hd: [
          "CreateEventTrigger",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$50[0]
                ],
                tl: {
                  hd: [
                    "triggerId",
                    match$50[1]
                  ],
                  tl: {
                    hd: [
                      "sources",
                      printTriggerSources(match$50[2])
                    ],
                    tl: {
                      hd: [
                        "description",
                        match$50[3]
                      ],
                      tl: {
                        hd: [
                          "content",
                          Json_encode.nullable(printTriggerContent, match$50[4])
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateGroupTypeName") {
      var match$51 = action.VAL;
      tmp = {
        hd: [
          "UpdateGroupTypeName",
          Json_encode.object_({
                hd: [
                  "groupTypeId",
                  match$51[0]
                ],
                tl: {
                  hd: [
                    "groupTypeName",
                    match$51[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyValidationMatch") {
      var match$52 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyValidationMatch",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$52[0]
                ],
                tl: {
                  hd: [
                    "match",
                    match$52[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveDestinationApiKey") {
      var match$53 = action.VAL;
      tmp = {
        hd: [
          "RemoveDestinationApiKey",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match$53[0]
                ],
                tl: {
                  hd: [
                    "envName",
                    match$53[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeleteServiceAccount") {
      tmp = {
        hd: [
          "DeleteServiceAccount",
          Json_encode.object_({
                hd: [
                  "serviceAccountName",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "Archive") {
      tmp = {
        hd: [
          "Archive",
          printItem(action.VAL)
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateIntegrationConfig") {
      var match$54 = action.VAL;
      tmp = {
        hd: [
          "UpdateIntegrationConfig",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  match$54[0]
                ],
                tl: {
                  hd: [
                    "config",
                    printIntegrationConfig(match$54[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyOperation") {
      var match$55 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyOperation",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$55[0]
                ],
                tl: {
                  hd: [
                    "operation",
                    TrackingPlanModel.operationToJs(match$55[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "TogglePropertyExcludedSource") {
      var match$56 = action.VAL;
      tmp = {
        hd: [
          "TogglePropertyExcludedSource",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$56[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$56[1]
                  ],
                  tl: {
                    hd: [
                      "isActive",
                      match$56[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveTag") {
      var match$57 = action.VAL;
      tmp = {
        hd: [
          "RemoveTag",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$57[0]
                ],
                tl: {
                  hd: [
                    "tag",
                    match$57[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddUserToGroup") {
      var match$58 = action.VAL;
      tmp = {
        hd: [
          "AddUserToGroup",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$58[0]
                ],
                tl: {
                  hd: [
                    "groupTypeId",
                    match$58[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "MigrateExcludeEventsToIncludeEvents") {
      tmp = {
        hd: [
          "MigrateExcludeEventsToIncludeEvents",
          Json_encode.object_({
                hd: [
                  "sourceIds",
                  []
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyRefV2") {
      var match$59 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyRefV2",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$59[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$59[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveIntegration") {
      tmp = {
        hd: [
          "RemoveIntegration",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "IntegrationComment") {
      var match$60 = action.VAL;
      tmp = {
        hd: [
          "IntegrationComment",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  match$60[0]
                ],
                tl: {
                  hd: [
                    "commentId",
                    match$60[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateDestinationApiKey") {
      var match$61 = action.VAL;
      tmp = {
        hd: [
          "UpdateDestinationApiKey",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match$61[0]
                ],
                tl: {
                  hd: [
                    "envName",
                    match$61[1]
                  ],
                  tl: {
                    hd: [
                      "key",
                      match$61[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateDestinationName") {
      var match$62 = action.VAL;
      tmp = {
        hd: [
          "UpdateDestinationName",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match$62[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$62[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ToggleIncludeEventInCodegenForSource") {
      var match$63 = action.VAL;
      tmp = {
        hd: [
          "ToggleIncludeEventInCodegenForSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$63[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$63[1]
                  ],
                  tl: {
                    hd: [
                      "includeInCodegen",
                      match$63[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyOptional") {
      var match$64 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyOptional",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$64[0]
                ],
                tl: {
                  hd: [
                    "optional",
                    match$64[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "IncludeEventInSourceV2") {
      var match$65 = action.VAL;
      tmp = {
        hd: [
          "IncludeEventInSourceV2",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$65[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$65[1]
                  ],
                  tl: {
                    hd: [
                      "destinationIds",
                      Json_encode.list((function (prim) {
                              return prim;
                            }), match$65[2])
                    ],
                    tl: {
                      hd: [
                        "includeInCodegen",
                        match$65[3]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateSavedView") {
      var match$66 = action.VAL;
      tmp = {
        hd: [
          "UpdateSavedView",
          Json_encode.object_({
                hd: [
                  "savedViewId",
                  match$66[0]
                ],
                tl: {
                  hd: [
                    "savedViewName",
                    match$66[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddSystemProperty") {
      var match$67 = action.VAL;
      tmp = {
        hd: [
          "AddSystemProperty",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$67[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$67[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateRule") {
      var match$68 = action.VAL;
      tmp = {
        hd: [
          "UpdateRule",
          Json_encode.object_({
                hd: [
                  "ruleId",
                  match$68[0]
                ],
                tl: {
                  hd: [
                    "item",
                    printRuleItem(match$68[1])
                  ],
                  tl: {
                    hd: [
                      "definition",
                      printRuleDefinition(match$68[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "GoalComment") {
      var match$69 = action.VAL;
      tmp = {
        hd: [
          "GoalComment",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$69[0]
                ],
                tl: {
                  hd: [
                    "commentId",
                    match$69[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SetMetricGroupBy") {
      var match$70 = action.VAL;
      tmp = {
        hd: [
          "SetMetricGroupBy",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$70[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$70[1]
                  ],
                  tl: {
                    hd: [
                      "propertyId",
                      match$70[3]
                    ],
                    tl: {
                      hd: [
                        "id",
                        match$70[2]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateMemberRole") {
      var match$71 = action.VAL;
      tmp = {
        hd: [
          "UpdateMemberRole",
          Json_encode.object_({
                hd: [
                  "userId",
                  match$71[0]
                ],
                tl: {
                  hd: [
                    "email",
                    match$71[1]
                  ],
                  tl: {
                    hd: [
                      "fromRole",
                      match$71[2]
                    ],
                    tl: {
                      hd: [
                        "toRole",
                        match$71[3]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveSourceSegmentIntegration") {
      var match$72 = action.VAL;
      tmp = {
        hd: [
          "RemoveSourceSegmentIntegration",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$72[0]
                ],
                tl: {
                  hd: [
                    "segmentIntegration",
                    match$72[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "IncludeEventInSource") {
      var match$73 = action.VAL;
      tmp = {
        hd: [
          "IncludeEventInSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$73[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$73[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateEventDescription") {
      var match$74 = action.VAL;
      tmp = {
        hd: [
          "UpdateEventDescription",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$74[0]
                ],
                tl: {
                  hd: [
                    "description",
                    match$74[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddEventToMetric") {
      var match$75 = action.VAL;
      tmp = {
        hd: [
          "AddEventToMetric",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$75[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$75[2]
                  ],
                  tl: {
                    hd: [
                      "id",
                      match$75[1]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ReorderEventsInMetric") {
      var match$76 = action.VAL;
      tmp = {
        hd: [
          "ReorderEventsInMetric",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$76[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$76[1]
                  ],
                  tl: {
                    hd: [
                      "maybePredecessorId",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), match$76[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateDestination") {
      tmp = {
        hd: [
          "CreateDestination",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddPropertyValidationMatch") {
      var match$77 = action.VAL;
      tmp = {
        hd: [
          "AddPropertyValidationMatch",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$77[0]
                ],
                tl: {
                  hd: [
                    "match",
                    match$77[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateNestedPropertyPinnedValue") {
      var match$78 = action.VAL;
      tmp = {
        hd: [
          "UpdateNestedPropertyPinnedValue",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$78[0]
                ],
                tl: {
                  hd: [
                    "nestedPropertyId",
                    match$78[1]
                  ],
                  tl: {
                    hd: [
                      "pinnedValue",
                      printLiteral(match$78[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddMetricToGoal") {
      var match$79 = action.VAL;
      tmp = {
        hd: [
          "AddMetricToGoal",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$79[1]
                ],
                tl: {
                  hd: [
                    "goalId",
                    match$79[0]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ExcludeDestinationFromEventSource") {
      var match$80 = action.VAL;
      tmp = {
        hd: [
          "ExcludeDestinationFromEventSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$80[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$80[1]
                  ],
                  tl: {
                    hd: [
                      "destinationId",
                      match$80[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeleteDestination") {
      tmp = {
        hd: [
          "DeleteDestination",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "GenerateWebhookSecret") {
      tmp = {
        hd: [
          "GenerateWebhookSecret",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyGroupDescription") {
      var match$81 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyGroupDescription",
          Json_encode.object_({
                hd: [
                  "groupId",
                  match$81[0]
                ],
                tl: {
                  hd: [
                    "description",
                    match$81[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ExcludeEventFromSource") {
      var match$82 = action.VAL;
      tmp = {
        hd: [
          "ExcludeEventFromSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$82[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$82[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveMetricGroupByV2") {
      var match$83 = action.VAL;
      tmp = {
        hd: [
          "RemoveMetricGroupByV2",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$83[0]
                ],
                tl: {
                  hd: [
                    "metricItemId",
                    match$83[1]
                  ],
                  tl: {
                    hd: [
                      "eventId",
                      match$83[2]
                    ],
                    tl: {
                      hd: [
                        "id",
                        match$83[3]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateTriggerDescription") {
      var match$84 = action.VAL;
      tmp = {
        hd: [
          "UpdateTriggerDescription",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$84[0]
                ],
                tl: {
                  hd: [
                    "triggerId",
                    match$84[1]
                  ],
                  tl: {
                    hd: [
                      "description",
                      match$84[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyValidationMatches") {
      tmp = {
        hd: [
          "RemovePropertyValidationMatches",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddPropertyToWhitelist") {
      var match$85 = action.VAL;
      tmp = {
        hd: [
          "AddPropertyToWhitelist",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$85[0]
                ],
                tl: {
                  hd: [
                    "groupId",
                    match$85[1]
                  ],
                  tl: {
                    hd: [
                      "analyticsTool",
                      AvoConfigTypes.analyticsToolToJs(match$85[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "IncludeDestinationInSourceV2") {
      var match$86 = action.VAL;
      tmp = {
        hd: [
          "IncludeDestinationInSourceV2",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$86[0]
                ],
                tl: {
                  hd: [
                    "destinationId",
                    match$86[1]
                  ],
                  tl: {
                    hd: [
                      "eventIds",
                      Json_encode.list((function (prim) {
                              return prim;
                            }), match$86[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateIntegration") {
      tmp = {
        hd: [
          "CreateIntegration",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateDestinationAnalyticsTool") {
      var match$87 = action.VAL;
      tmp = {
        hd: [
          "UpdateDestinationAnalyticsTool",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match$87[0]
                ],
                tl: {
                  hd: [
                    "analyticsTool",
                    AvoConfigTypes.analyticsToolToJs(match$87[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SetMetricGroupByV2") {
      var match$88 = action.VAL;
      tmp = {
        hd: [
          "SetMetricGroupByV2",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$88[0]
                ],
                tl: {
                  hd: [
                    "metricItemId",
                    match$88[1]
                  ],
                  tl: {
                    hd: [
                      "eventId",
                      match$88[2]
                    ],
                    tl: {
                      hd: [
                        "propertyId",
                        match$88[4]
                      ],
                      tl: {
                        hd: [
                          "id",
                          match$88[3]
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyFromGroup") {
      var match$89 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyFromGroup",
          Json_encode.object_({
                hd: [
                  "groupId",
                  match$89[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$89[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddEventToGoal") {
      var match$90 = action.VAL;
      tmp = {
        hd: [
          "AddEventToGoal",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$90[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$90[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SubscriptionUpdated") {
      var match$91 = action.VAL;
      tmp = {
        hd: [
          "SubscriptionUpdated",
          Json_encode.object_({
                hd: [
                  "paymentSource",
                  match$91[0]
                ],
                tl: {
                  hd: [
                    "subscription",
                    match$91[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SubscriptionCancelled") {
      var match$92 = action.VAL;
      tmp = {
        hd: [
          "SubscriptionCancelled",
          Json_encode.object_({
                hd: [
                  "paymentSource",
                  match$92[0]
                ],
                tl: {
                  hd: [
                    "subscription",
                    match$92[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyValidationMinOrMax") {
      var match$93 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyValidationMinOrMax",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$93[0]
                ],
                tl: {
                  hd: [
                    "newValue",
                    match$93[1]
                  ],
                  tl: {
                    hd: [
                      "validationType",
                      TrackingPlanModel.constraint_ToJs(match$93[2])
                    ],
                    tl: {
                      hd: [
                        "validationIndex",
                        match$93[3]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddTriggerSource") {
      var match$94 = action.VAL;
      tmp = {
        hd: [
          "AddTriggerSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$94[0]
                ],
                tl: {
                  hd: [
                    "triggerId",
                    match$94[1]
                  ],
                  tl: {
                    hd: [
                      "sourceId",
                      match$94[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "IncludeDestinationInEventSource") {
      var match$95 = action.VAL;
      tmp = {
        hd: [
          "IncludeDestinationInEventSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$95[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$95[1]
                  ],
                  tl: {
                    hd: [
                      "destinationId",
                      match$95[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddGroupTypeToLogEvent") {
      var match$96 = action.VAL;
      tmp = {
        hd: [
          "AddGroupTypeToLogEvent",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$96[0]
                ],
                tl: {
                  hd: [
                    "groupTypeId",
                    match$96[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyPinnedValue") {
      var match$97 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyPinnedValue",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$97[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$97[1]
                  ],
                  tl: {
                    hd: [
                      "pinnedValue",
                      printLiteral(match$97[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "PublishIntegration") {
      var match$98 = action.VAL;
      tmp = {
        hd: [
          "PublishIntegration",
          Json_encode.object_(Belt_List.concat({
                    hd: [
                      "integrationId",
                      match$98[0]
                    ],
                    tl: {
                      hd: [
                        "success",
                        match$98[1]
                      ],
                      tl: {
                        hd: [
                          "warnings",
                          Json_encode.array((function (prim) {
                                  return prim;
                                }), match$98[2])
                        ],
                        tl: {
                          hd: [
                            "messages",
                            Json_encode.array((function (prim) {
                                    return prim;
                                  }), match$98[3])
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }, Belt_Option.mapWithDefault(match$98[4], /* [] */0, (function (successStats) {
                          return {
                                  hd: [
                                    "successStats",
                                    printPublishingSuccessStats(successStats)
                                  ],
                                  tl: /* [] */0
                                };
                        }))))
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveUserFromGroup") {
      var match$99 = action.VAL;
      tmp = {
        hd: [
          "RemoveUserFromGroup",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$99[0]
                ],
                tl: {
                  hd: [
                    "groupTypeId",
                    match$99[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyValidation") {
      var match$100 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyValidation",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$100[0]
                ],
                tl: {
                  hd: [
                    "validation",
                    printValidation(match$100[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateGoalName") {
      var match$101 = action.VAL;
      tmp = {
        hd: [
          "UpdateGoalName",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$101[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$101[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DuplicateEvent") {
      var match$102 = action.VAL;
      var match$103 = match$102[0];
      tmp = {
        hd: [
          "DuplicateEvent",
          Json_encode.object_({
                hd: [
                  "eventIdArray",
                  Json_encode.object_({
                        hd: [
                          "fromEventId",
                          match$103[0]
                        ],
                        tl: {
                          hd: [
                            "toEventId",
                            match$103[1]
                          ],
                          tl: /* [] */0
                        }
                      })
                ],
                tl: {
                  hd: [
                    "triggerIdArrays",
                    Json_encode.array(printTriggerIdTuple, match$102[1])
                  ],
                  tl: {
                    hd: [
                      "ruleIdArrays",
                      Json_encode.array(printRuleIdTuple, match$102[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddPropertyValidation") {
      var match$104 = action.VAL;
      tmp = {
        hd: [
          "AddPropertyValidation",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$104[0]
                ],
                tl: {
                  hd: [
                    "validation",
                    printValidation(match$104[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "PullMaster") {
      var match$105 = action.VAL;
      tmp = {
        hd: [
          "PullMaster",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$105[0]
                ],
                tl: {
                  hd: [
                    "masterActionId",
                    match$105[1]
                  ],
                  tl: {
                    hd: [
                      "masterActionTimestamp",
                      match$105[2]
                    ],
                    tl: {
                      hd: [
                        "actions",
                        Json_encode.list(printAction, match$105[3])
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateMetricName") {
      var match$106 = action.VAL;
      tmp = {
        hd: [
          "UpdateMetricName",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$106[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$106[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveNestedPropertyPinnedValue") {
      var match$107 = action.VAL;
      tmp = {
        hd: [
          "RemoveNestedPropertyPinnedValue",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$107[0]
                ],
                tl: {
                  hd: [
                    "nestedPropertyId",
                    match$107[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SetMetricWhere") {
      var match$108 = action.VAL;
      tmp = {
        hd: [
          "SetMetricWhere",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$108[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$108[1]
                  ],
                  tl: {
                    hd: [
                      "id",
                      match$108[2]
                    ],
                    tl: {
                      hd: [
                        "propertyId",
                        match$108[3]
                      ],
                      tl: {
                        hd: [
                          "binOp",
                          printBinOp(match$108[4])
                        ],
                        tl: {
                          hd: [
                            "literals",
                            Json_encode.list(printLiteral, match$108[5])
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateSource") {
      tmp = {
        hd: [
          "CreateSource",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateSourceSegmentIntegration") {
      var match$109 = action.VAL;
      tmp = {
        hd: [
          "UpdateSourceSegmentIntegration",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$109[0]
                ],
                tl: {
                  hd: [
                    "segmentIntegration",
                    match$109[1]
                  ],
                  tl: {
                    hd: [
                      "isActive",
                      match$109[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveEventType") {
      var match$110 = action.VAL;
      tmp = {
        hd: [
          "RemoveEventType",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$110[0]
                ],
                tl: {
                  hd: [
                    "type",
                    TrackingPlanModel.eventTypeToJs(match$110[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveEventFromGoal") {
      var match$111 = action.VAL;
      tmp = {
        hd: [
          "RemoveEventFromGoal",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$111[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$111[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ExcludeDestinationFromSource") {
      var match$112 = action.VAL;
      tmp = {
        hd: [
          "ExcludeDestinationFromSource",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$112[0]
                ],
                tl: {
                  hd: [
                    "destinationId",
                    match$112[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateMetricType") {
      var match$113 = action.VAL;
      tmp = {
        hd: [
          "UpdateMetricType",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$113[0]
                ],
                tl: {
                  hd: [
                    "type",
                    TrackingPlanModel.metricTypeToJs(match$113[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ClearPropertyExcludedSources") {
      tmp = {
        hd: [
          "ClearPropertyExcludedSources",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateIntegrationName") {
      var match$114 = action.VAL;
      tmp = {
        hd: [
          "UpdateIntegrationName",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  match$114[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$114[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreatePropertyGroup") {
      var match$115 = action.VAL;
      tmp = {
        hd: [
          "CreatePropertyGroup",
          Json_encode.object_({
                hd: [
                  "groupId",
                  match$115[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$115[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyGroupName") {
      var match$116 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyGroupName",
          Json_encode.object_({
                hd: [
                  "groupId",
                  match$116[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$116[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddSourceConfig") {
      var match$117 = action.VAL;
      tmp = {
        hd: [
          "AddSourceConfig",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$117[0]
                ],
                tl: {
                  hd: [
                    "sourceConfig",
                    printSourceConfig(match$117[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SubscriptionStarted") {
      var match$118 = action.VAL;
      tmp = {
        hd: [
          "SubscriptionStarted",
          Json_encode.object_({
                hd: [
                  "paymentSource",
                  match$118[0]
                ],
                tl: {
                  hd: [
                    "subscription",
                    match$118[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateEventUniqueName") {
      var match$119 = action.VAL;
      tmp = {
        hd: [
          "UpdateEventUniqueName",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$119[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$119[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreatePublicBranchImplementation") {
      tmp = {
        hd: [
          "CreatePublicBranchImplementation",
          Json_encode.object_({
                hd: [
                  "shareId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateIntegrationType") {
      var match$120 = action.VAL;
      tmp = {
        hd: [
          "UpdateIntegrationType",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  match$120[0]
                ],
                tl: {
                  hd: [
                    "type",
                    TrackingPlanModel.integrationTypeToJs(match$120[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveMetricWhere") {
      var match$121 = action.VAL;
      tmp = {
        hd: [
          "RemoveMetricWhere",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$121[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$121[1]
                  ],
                  tl: {
                    hd: [
                      "id",
                      match$121[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeleteComment") {
      var match$122 = action.VAL;
      tmp = {
        hd: [
          "DeleteComment",
          Json_encode.object_({
                hd: [
                  "commentId",
                  match$122[1]
                ],
                tl: {
                  hd: [
                    "threadId",
                    match$122[0]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "PropertyComment") {
      var match$123 = action.VAL;
      tmp = {
        hd: [
          "PropertyComment",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$123[0]
                ],
                tl: {
                  hd: [
                    "commentId",
                    match$123[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateEventName") {
      var match$124 = action.VAL;
      tmp = {
        hd: [
          "UpdateEventName",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$124[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$124[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ResendInvite") {
      var match$125 = action.VAL;
      tmp = {
        hd: [
          "ResendInvite",
          Json_encode.object_({
                hd: [
                  "email",
                  match$125[0]
                ],
                tl: {
                  hd: [
                    "role",
                    match$125[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateIntegrationFilters") {
      var match$126 = action.VAL;
      tmp = {
        hd: [
          "UpdateIntegrationFilters",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  match$126[0]
                ],
                tl: {
                  hd: [
                    "filters",
                    printIntegrationFilters(match$126[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateProperty") {
      var match$127 = action.VAL;
      tmp = {
        hd: [
          "CreateProperty",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$127[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$127[1]
                  ],
                  tl: {
                    hd: [
                      "sendAs",
                      printSendAs(match$127[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyOptionalWhenInObject") {
      var match$128 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyOptionalWhenInObject",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$128[0]
                ],
                tl: {
                  hd: [
                    "optional",
                    match$128[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveGroupTypeFromLogEvent") {
      var match$129 = action.VAL;
      tmp = {
        hd: [
          "RemoveGroupTypeFromLogEvent",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$129[0]
                ],
                tl: {
                  hd: [
                    "groupTypeId",
                    match$129[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyGroupFromEvent") {
      var match$130 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyGroupFromEvent",
          Json_encode.object_({
                hd: [
                  "groupId",
                  match$130[1]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$130[0]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateMetricDescription") {
      var match$131 = action.VAL;
      tmp = {
        hd: [
          "UpdateMetricDescription",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$131[0]
                ],
                tl: {
                  hd: [
                    "description",
                    match$131[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveMetricFromGoal") {
      var match$132 = action.VAL;
      tmp = {
        hd: [
          "RemoveMetricFromGoal",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$132[0]
                ],
                tl: {
                  hd: [
                    "metricId",
                    match$132[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ConfigureInspectorValidation") {
      var match$133 = action.VAL;
      tmp = {
        hd: [
          "ConfigureInspectorValidation",
          Json_encode.object_({
                hd: [
                  "eventIds",
                  Json_encode.list((function (prim) {
                          return prim;
                        }), match$133[0])
                ],
                tl: {
                  hd: [
                    "sourceValidationMap",
                    printSourceValidationMap(match$133[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CodeGenerated") {
      var match$134 = action.VAL;
      tmp = {
        hd: [
          "CodeGenerated",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$134[0]
                ],
                tl: {
                  hd: [
                    "sourceIds",
                    Json_encode.list((function (prim) {
                            return prim;
                          }), match$134[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddPropertyToGroup") {
      var match$135 = action.VAL;
      tmp = {
        hd: [
          "AddPropertyToGroup",
          Json_encode.object_({
                hd: [
                  "groupId",
                  match$135[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$135[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateSourcePlatform") {
      var match$136 = action.VAL;
      tmp = {
        hd: [
          "UpdateSourcePlatform",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$136[0]
                ],
                tl: {
                  hd: [
                    "platform",
                    AvoConfigTypes.devPlatformToJs(match$136[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateDestinationDevApiKey") {
      var match$137 = action.VAL;
      tmp = {
        hd: [
          "UpdateDestinationDevApiKey",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match$137[0]
                ],
                tl: {
                  hd: [
                    "key",
                    match$137[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ArchiveSavedView") {
      var match$138 = action.VAL;
      tmp = {
        hd: [
          "ArchiveSavedView",
          Json_encode.object_({
                hd: [
                  "savedViewId",
                  match$138[0]
                ],
                tl: {
                  hd: [
                    "savedViewName",
                    match$138[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateLibraryName") {
      var match$139 = action.VAL;
      tmp = {
        hd: [
          "UpdateLibraryName",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$139[0]
                ],
                tl: {
                  hd: [
                    "filename",
                    match$139[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CloseBranch") {
      var match$140 = action.VAL;
      tmp = {
        hd: [
          "CloseBranch",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$140[0]
                ],
                tl: {
                  hd: [
                    "branchName",
                    match$140[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveEventFromMetric") {
      var match$141 = action.VAL;
      tmp = {
        hd: [
          "RemoveEventFromMetric",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$141[0]
                ],
                tl: {
                  hd: [
                    "id",
                    match$141[1]
                  ],
                  tl: {
                    hd: [
                      "eventId",
                      match$141[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyPinnedValue") {
      var match$142 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyPinnedValue",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$142[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$142[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateDestinationProdApiKey") {
      var match$143 = action.VAL;
      tmp = {
        hd: [
          "UpdateDestinationProdApiKey",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match$143[0]
                ],
                tl: {
                  hd: [
                    "key",
                    match$143[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "BranchInit") {
      var match$144 = action.VAL;
      tmp = {
        hd: [
          "BranchInit",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$144[0]
                ],
                tl: {
                  hd: [
                    "branchName",
                    match$144[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AutoPublishIntegration") {
      var match$145 = action.VAL;
      tmp = {
        hd: [
          "AutoPublishIntegration",
          Json_encode.object_(Belt_List.concat({
                    hd: [
                      "integrationId",
                      match$145[0]
                    ],
                    tl: {
                      hd: [
                        "success",
                        match$145[1]
                      ],
                      tl: {
                        hd: [
                          "warnings",
                          Json_encode.array((function (prim) {
                                  return prim;
                                }), match$145[2])
                        ],
                        tl: {
                          hd: [
                            "messages",
                            Json_encode.array((function (prim) {
                                    return prim;
                                  }), match$145[3])
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }, Belt_Option.mapWithDefault(match$145[4], /* [] */0, (function (successStats) {
                          return {
                                  hd: [
                                    "successStats",
                                    printPublishingSuccessStats(successStats)
                                  ],
                                  tl: /* [] */0
                                };
                        }))))
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveEventTrigger") {
      var match$146 = action.VAL;
      tmp = {
        hd: [
          "RemoveEventTrigger",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$146[0]
                ],
                tl: {
                  hd: [
                    "triggerId",
                    match$146[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ReorderGoals") {
      var match$147 = action.VAL;
      tmp = {
        hd: [
          "ReorderGoals",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$147[0]
                ],
                tl: {
                  hd: [
                    "maybePredecessorId",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), match$147[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateServiceAccount") {
      tmp = {
        hd: [
          "CreateServiceAccount",
          Json_encode.object_({
                hd: [
                  "serviceAccountName",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CollaboratorRemoved") {
      var match$148 = action.VAL;
      tmp = {
        hd: [
          "CollaboratorRemoved",
          Json_encode.object_({
                hd: [
                  "collaborationItem",
                  printCollaborationItem(match$148[0])
                ],
                tl: {
                  hd: [
                    "removedByUserId",
                    match$148[1]
                  ],
                  tl: {
                    hd: [
                      "userId",
                      match$148[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveGoal") {
      tmp = {
        hd: [
          "RemoveGoal",
          Json_encode.object_({
                hd: [
                  "goalId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveMetricWhereV2") {
      var match$149 = action.VAL;
      tmp = {
        hd: [
          "RemoveMetricWhereV2",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$149[0]
                ],
                tl: {
                  hd: [
                    "metricItemId",
                    match$149[1]
                  ],
                  tl: {
                    hd: [
                      "eventId",
                      match$149[2]
                    ],
                    tl: {
                      hd: [
                        "id",
                        match$149[3]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveTriggerSource") {
      var match$150 = action.VAL;
      tmp = {
        hd: [
          "RemoveTriggerSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$150[0]
                ],
                tl: {
                  hd: [
                    "triggerId",
                    match$150[1]
                  ],
                  tl: {
                    hd: [
                      "sourceId",
                      match$150[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyAbsence") {
      var match$151 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyAbsence",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$151[0]
                ],
                tl: {
                  hd: [
                    "propertyAbsenceUpdateType",
                    printPropertyAbsenceUpdateType(match$151[1])
                  ],
                  tl: {
                    hd: [
                      "maybeEventSourceAbsence",
                      Json_encode.nullable(printEventSourceAbsence, match$151[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "Unarchive") {
      tmp = {
        hd: [
          "Unarchive",
          printItem(action.VAL)
        ],
        tl: /* [] */0
      };
    } else if (variant === "CompletedImport") {
      var match$152 = action.VAL;
      tmp = {
        hd: [
          "CompletedImport",
          Json_encode.object_({
                hd: [
                  "eventCount",
                  match$152[0]
                ],
                tl: {
                  hd: [
                    "propertyCount",
                    match$152[1]
                  ],
                  tl: {
                    hd: [
                      "sourceCount",
                      match$152[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateIntegrationDestinationIds") {
      var match$153 = action.VAL;
      tmp = {
        hd: [
          "UpdateIntegrationDestinationIds",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  match$153[0]
                ],
                tl: {
                  hd: [
                    "destinationIds",
                    Json_encode.list((function (prim) {
                            return prim;
                          }), match$153[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveMember") {
      var match$154 = action.VAL;
      tmp = {
        hd: [
          "RemoveMember",
          Json_encode.object_({
                hd: [
                  "userId",
                  match$154[0]
                ],
                tl: {
                  hd: [
                    "email",
                    match$154[1]
                  ],
                  tl: {
                    hd: [
                      "role",
                      match$154[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveSourceConfig") {
      var match$155 = action.VAL;
      tmp = {
        hd: [
          "RemoveSourceConfig",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$155[0]
                ],
                tl: {
                  hd: [
                    "sourceConfig",
                    printSourceConfig(match$155[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddSourceSegmentIntegration") {
      var match$156 = action.VAL;
      tmp = {
        hd: [
          "AddSourceSegmentIntegration",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$156[0]
                ],
                tl: {
                  hd: [
                    "segmentIntegration",
                    match$156[1]
                  ],
                  tl: {
                    hd: [
                      "isActive",
                      match$156[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveRule") {
      var match$157 = action.VAL;
      tmp = {
        hd: [
          "RemoveRule",
          Json_encode.object_({
                hd: [
                  "ruleId",
                  match$157[0]
                ],
                tl: {
                  hd: [
                    "item",
                    printRuleItem(match$157[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddPropertyGroupToEvent") {
      var match$158 = action.VAL;
      tmp = {
        hd: [
          "AddPropertyGroupToEvent",
          Json_encode.object_({
                hd: [
                  "groupId",
                  match$158[1]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$158[0]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else {
      tmp = {
        hd: [
          "RemoveMetric",
          Json_encode.object_({
                hd: [
                  "metricId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    }
  } else {
    tmp = action === "StartedImport" ? ({
          hd: [
            "StartedImport",
            Json_encode.object_(/* [] */0)
          ],
          tl: /* [] */0
        }) : (
        action === "MigratePropertyOptionalAndExcludedSourcesToAbsence" ? ({
              hd: [
                "MigratePropertyOptionalAndExcludedSourcesToAbsence",
                Json_encode.object_(/* [] */0)
              ],
              tl: /* [] */0
            }) : (
            action === "SchemaAccessed" ? ({
                  hd: [
                    "SchemaAccessed",
                    Json_encode.object_(/* [] */0)
                  ],
                  tl: /* [] */0
                }) : (
                action === "MigrateSkipWebFnTagToIncludeInCodegen" ? ({
                      hd: [
                        "MigrateSkipWebFnTagToIncludeInCodegen",
                        Json_encode.object_(/* [] */0)
                      ],
                      tl: /* [] */0
                    }) : (
                    action === "MigrateSourceDestinationMapping" ? ({
                          hd: [
                            "MigrateSourceDestinationMapping",
                            Json_encode.object_(/* [] */0)
                          ],
                          tl: /* [] */0
                        }) : (
                        action === "ResetTrackingPlan" ? ({
                              hd: [
                                "ResetTrackingPlan",
                                Json_encode.object_(/* [] */0)
                              ],
                              tl: /* [] */0
                            }) : ({
                              hd: [
                                "MigrateRemoveUnarchivedItemsFromArchive",
                                Json_encode.object_(/* [] */0)
                              ],
                              tl: /* [] */0
                            })
                      )
                  )
              )
          )
      );
  }
  return Json_encode.object_(tmp);
}

var printMetricType = TrackingPlanModel.metricTypeToJs;

exports.printLiteral = printLiteral;
exports.printBinOp = printBinOp;
exports.printExp = printExp;
exports.printPropertyRef = printPropertyRef;
exports.printCoreType = printCoreType;
exports.printValueType = printValueType;
exports.printValue = printValue;
exports.printObjectItem = printObjectItem;
exports.printValidation = printValidation;
exports.printSendAs = printSendAs;
exports.printItem = printItem;
exports.printCollaborationItem = printCollaborationItem;
exports.printRuleItem = printRuleItem;
exports.printMappingDestination = printMappingDestination;
exports.printNameMapping = printNameMapping;
exports.printSegmentIntegrationOption = printSegmentIntegrationOption;
exports.printSegmentIntegrationOptionRule = printSegmentIntegrationOptionRule;
exports.printRuleDefinition = printRuleDefinition;
exports.printRule = printRule;
exports.printRules = printRules;
exports.printRuleIdTuple = printRuleIdTuple;
exports.printPropertyAbsenceUpdateType = printPropertyAbsenceUpdateType;
exports.printEventSourceAbsence = printEventSourceAbsence;
exports.printPropertyAbsenceEventSourceMap = printPropertyAbsenceEventSourceMap;
exports.printPropertyAbsenceEventMap = printPropertyAbsenceEventMap;
exports.printPropertyAbsence = printPropertyAbsence;
exports.printProperty = printProperty;
exports.printProperties = printProperties;
exports.printPropertyBundle = printPropertyBundle;
exports.printPropertyBundles = printPropertyBundles;
exports.printPropertyBundleRef = printPropertyBundleRef;
exports.printPropertyBundleRefs = printPropertyBundleRefs;
exports.printSourceDestinationMap = printSourceDestinationMap;
exports.printPropertyWhitelist = printPropertyWhitelist;
exports.printSourceValidation = printSourceValidation;
exports.printSourceValidationMap = printSourceValidationMap;
exports.printIncludedSource = printIncludedSource;
exports.printImageMetadata = printImageMetadata;
exports.printFigmaMetadata = printFigmaMetadata;
exports.printTriggerContent = printTriggerContent;
exports.printTriggerSources = printTriggerSources;
exports.printEventTrigger = printEventTrigger;
exports.printEventTriggers = printEventTriggers;
exports.printTriggerIdTuple = printTriggerIdTuple;
exports.printEvent = printEvent;
exports.printType = printType;
exports.printEvents = printEvents;
exports.printMigrations = printMigrations;
exports.printSourceConfig = printSourceConfig;
exports.printSourceDestinationMode = printSourceDestinationMode;
exports.printSourceDestinationRef = printSourceDestinationRef;
exports.printSource = printSource;
exports.printSources = printSources;
exports.printOtherApiKey = printOtherApiKey;
exports.printDestination = printDestination;
exports.printGroupType = printGroupType;
exports.printDestinations = printDestinations;
exports.printGroupTypes = printGroupTypes;
exports.printGoal = printGoal;
exports.printGoals = printGoals;
exports.printMetricType = printMetricType;
exports.printMetricItem = printMetricItem;
exports.printMetric = printMetric;
exports.printMetrics = printMetrics;
exports.printIntegrationConfig = printIntegrationConfig;
exports.printIntegrationFilters = printIntegrationFilters;
exports.printIntegration = printIntegration;
exports.printIntegrations = printIntegrations;
exports.printArchive = printArchive;
exports.printBranch = printBranch;
exports.printBranches = printBranches;
exports.printBranchPointer = printBranchPointer;
exports.printPublishingSuccessStats = printPublishingSuccessStats;
exports.printModel = printModel;
exports.printAvoJson = printAvoJson;
exports.printModelString = printModelString;
exports.printAction = printAction;
/* TrackingPlanModel Not a pure module */
