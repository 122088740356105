// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("./Link.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Mantine = require("./Mantine.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Timestamp = require("./Timestamp.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var IconBranch = require("./IconBranch.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core = require("@mantine/core");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

function ObjectActivityLogAction$User(Props) {
  var user = Props.user;
  return React.createElement("b", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(0), Css.px(4)),
                    tl: /* [] */0
                  })
            }, AvoConfig.getUserDisplayName(user));
}

var User = {
  make: ObjectActivityLogAction$User
};

function ObjectActivityLogAction$Content(Props) {
  var action = Props.action;
  var users = Props.users;
  var openBranches = Props.openBranches;
  var children = Props.children;
  var match = WorkspaceContext.use(undefined);
  var branchId = action.branchId;
  var maybeOpenBranchName = Belt_List.getByU(openBranches, (function (param) {
          return Caml_obj.caml_equal(param[0], Caml_option.undefined_to_opt(action.branchId));
        }));
  var tmp;
  tmp = maybeOpenBranchName !== undefined || !(branchId !== undefined && branchId !== "master") ? "Skip" : "Fetch";
  var branchState = FirebaseFetcherHooks.useBranchState(tmp, match.id, Belt_Option.getWithDefault(branchId === undefined ? undefined : Caml_option.some(branchId), "NA"));
  var branchName = maybeOpenBranchName !== undefined ? maybeOpenBranchName[1] : (
      branchState !== undefined ? branchState.branchName : undefined
    );
  var match$1 = React.useState(function () {
        return false;
      });
  var setContentPopoverVisible = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setBranchNamePopoverVisible = match$2[1];
  var actionUsers = users.length !== 0 ? React.createElement("span", {
          className: Curry._1(Css.style, {
                hd: Css.marginLeft(Css.px(-4)),
                tl: /* [] */0
              })
        }, Belt_Array.mapWithIndexU(users, (function (index, user) {
                if (index === (users.length - 2 | 0)) {
                  return React.createElement(React.Fragment, {
                              children: null,
                              key: user.id
                            }, React.createElement(ObjectActivityLogAction$User, {
                                  user: user
                                }), "and");
                } else if (index === (users.length - 1 | 0)) {
                  return React.createElement(ObjectActivityLogAction$User, {
                              user: user,
                              key: user.id
                            });
                } else {
                  return React.createElement(React.Fragment, {
                              children: null,
                              key: user.id
                            }, React.createElement(ObjectActivityLogAction$User, {
                                  user: user
                                }), ",");
                }
              }))) : null;
  var tmp$1;
  if (branchId !== undefined) {
    if (branchId === "master") {
      tmp$1 = React.createElement("span", {
            className: Curry._1(Css.style, {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.marginLeft(Css.px(5)),
                    tl: /* [] */0
                  }
                })
          }, "on", React.createElement(Spacer.make, {
                width: 3
              }), React.createElement(IconBranch.make, {
                size: 8,
                color: Styles.Color.grey40
              }), React.createElement(Spacer.make, {
                width: 4
              }), "main");
    } else if (branchName !== undefined) {
      var tmp$2 = branchId === "master" ? "master" : ({
            NAME: "branch",
            VAL: branchId
          });
      tmp$1 = React.createElement(Link.make, {
            path: Router.Link.getSchemaRouteLink(tmp$2, {
                  NAME: "diff",
                  VAL: "index"
                }),
            children: React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.marginLeft(Css.px(5)),
                          tl: /* [] */0
                        }
                      })
                }, "on", React.createElement(Spacer.make, {
                      width: 3
                    }), React.createElement(IconBranch.make, {
                      size: 8,
                      color: Styles.Color.grey40
                    }), React.createElement(Spacer.make, {
                      width: 4
                    }), React.createElement(Mantine.Popover.make, {
                      opened: match$2[0],
                      position: "bottom",
                      children: null
                    }, React.createElement(Core.Popover.Target, {
                          children: React.createElement("span", {
                                className: Curry._1(Css.style, {
                                      hd: Css.textOverflow("ellipsis"),
                                      tl: {
                                        hd: Css.whiteSpace("nowrap"),
                                        tl: {
                                          hd: Css.overflow("hidden"),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.textDecoration("underline"),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }),
                                onMouseEnter: (function (param) {
                                    return Curry._1(setBranchNamePopoverVisible, (function (param) {
                                                  return true;
                                                }));
                                  }),
                                onMouseLeave: (function (param) {
                                    return Curry._1(setBranchNamePopoverVisible, (function (param) {
                                                  return false;
                                                }));
                                  })
                              }, branchName)
                        }), React.createElement(Mantine.Popover.Dropdown.make, {
                          children: React.createElement("span", {
                                className: Curry._1(Css.style, {
                                      hd: Css.width("auto"),
                                      tl: {
                                        hd: Css.padding2(Css.px(3), Css.px(6)),
                                        tl: {
                                          hd: Css.backgroundColor(Styles.Color.grey80),
                                          tl: {
                                            hd: Css.color(Styles.Color.white),
                                            tl: {
                                              hd: Css.fontSize(Styles.FontSize.small),
                                              tl: {
                                                hd: Css.borderRadius(Styles.Border.radius),
                                                tl: {
                                                  hd: Css.zIndex(3),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    })
                              }, branchName)
                        })))
          });
    } else {
      tmp$1 = null;
    }
  } else {
    tmp$1 = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(2), Css.px(45)),
                    tl: {
                      hd: Css.fontSize(Css.px(11)),
                      tl: {
                        hd: Css.color(Styles.Color.grey70),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.maxWidth(Css.vw(40)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(Mantine.Popover.make, {
                  opened: match$1[0],
                  position: "bottom",
                  children: null
                }, React.createElement(Core.Popover.Target, {
                      children: React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.textOverflow("ellipsis"),
                                  tl: {
                                    hd: Css.whiteSpace("nowrap"),
                                    tl: {
                                      hd: Css.overflow("hidden"),
                                      tl: {
                                        hd: Css.flexShrink(1.0),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }),
                            onMouseEnter: (function (param) {
                                return Curry._1(setContentPopoverVisible, (function (param) {
                                              return true;
                                            }));
                              }),
                            onMouseLeave: (function (param) {
                                return Curry._1(setContentPopoverVisible, (function (param) {
                                              return false;
                                            }));
                              })
                          }, actionUsers, React.createElement("span", {
                                className: Curry._1(Css.style, {
                                      hd: Css.selector(" b", {
                                            hd: Css.padding2(Css.px(0), Css.px(3)),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.selector(" i", {
                                              hd: Css.padding2(Css.px(0), Css.px(3)),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }
                                    })
                              }, children))
                    }), React.createElement(Mantine.Popover.Dropdown.make, {
                      children: React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.maxWidth({
                                        NAME: "px",
                                        VAL: 500
                                      }),
                                  tl: {
                                    hd: Css.overflowWrap("breakWord"),
                                    tl: {
                                      hd: Css.padding2(Css.px(3), Css.px(6)),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.grey80),
                                        tl: {
                                          hd: Css.color(Styles.Color.white),
                                          tl: {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: {
                                              hd: Css.borderRadius(Styles.Border.radius),
                                              tl: {
                                                hd: Css.zIndex(3),
                                                tl: {
                                                  hd: Css.selector(" b", {
                                                        hd: Css.padding2(Css.px(0), Css.px(3)),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: {
                                                    hd: Css.selector(" i", {
                                                          hd: Css.padding2(Css.px(0), Css.px(3)),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                          }, actionUsers, children)
                    })), React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.regular),
                          tl: {
                            hd: Css.marginLeft(Css.px(5)),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: {
                                hd: Css.whiteSpace("nowrap"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(Timestamp.make, {
                      date: action.createdAt
                    })), React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexShrink(2.0),
                        tl: {
                          hd: Css.overflow("hidden"),
                          tl: {
                            hd: Css.color(Styles.Color.grey50),
                            tl: {
                              hd: Css.minWidth({
                                    NAME: "px",
                                    VAL: 100
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, tmp$1));
}

var Content = {
  make: ObjectActivityLogAction$Content
};

function ObjectActivityLogAction(Props) {
  var action = Props.action;
  var users = Props.users;
  var path = Props.path;
  var openBranches = Props.openBranches;
  var children = Props.children;
  return React.createElement(Link.make, {
              path: path,
              children: React.createElement(ObjectActivityLogAction$Content, {
                    action: action,
                    users: users,
                    openBranches: openBranches,
                    children: children
                  })
            });
}

var make = ObjectActivityLogAction;

exports.User = User;
exports.Content = Content;
exports.make = make;
/* Css Not a pure module */
