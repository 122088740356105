// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var IconMetric = require("./IconMetric.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

function DiffEventMetrics(Props) {
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var actions = Props.actions;
  var addedItems = Belt_List.keep(Belt_List.keepMapU(actions, (function (action) {
              if (typeof action === "object" && action.NAME === "AddEventToMetric") {
                return action.VAL[0];
              }
              
            })), (function (metricId) {
          return Belt_List.has(branchModel.goals, metricId, (function (goal, metricId) {
                        return Belt_List.has(goal.metrics, metricId, (function (prim0, prim1) {
                                      return prim0 === prim1;
                                    }));
                      }));
        }));
  var removedItems = Belt_List.keep(Belt_List.keepMapU(actions, (function (action) {
              if (typeof action === "object" && action.NAME === "RemoveEventFromMetric") {
                return action.VAL[0];
              }
              
            })), (function (metricId) {
          return Belt_List.has(masterModel.goals, metricId, (function (goal, metricId) {
                        return Belt_List.has(goal.metrics, metricId, (function (prim0, prim1) {
                                      return prim0 === prim1;
                                    }));
                      }));
        }));
  var updatedItems = Belt_List.concat(addedItems, removedItems);
  if (Belt_List.length(updatedItems) === 0) {
    return null;
  } else {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("flexStart"),
                        tl: {
                          hd: Css.padding2(Css.px(10), Css.px(0)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.margin4(Css.px(15), Css.px(10), Css.px(0), Css.px(0)),
                          tl: /* [] */0
                        })
                  }, React.createElement(IconMetric.make, {
                        size: 14,
                        color: Styles.Color.grey40
                      })), React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.width(Css.pct(100)),
                          tl: /* [] */0
                        })
                  }, Belt_List.toArray(Belt_List.concatMany([
                            Belt_List.mapU(removedItems, (function (metricId) {
                                    return Belt_Option.mapWithDefault(Belt_List.getByU(branchModel.metrics, (function (metric) {
                                                      return metric.id === metricId;
                                                    })), null, (function (metric) {
                                                  return React.createElement("div", {
                                                              key: metric.id + "-removed",
                                                              className: Curry._1(Css.style, {
                                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                    tl: {
                                                                      hd: Css.fontSize(Styles.FontSize.small),
                                                                      tl: {
                                                                        hd: Css.padding2(Css.px(10), Css.px(15)),
                                                                        tl: {
                                                                          hd: Css.margin2(Css.px(5), Css.px(0)),
                                                                          tl: {
                                                                            hd: Css.borderRadius(Styles.Border.radius),
                                                                            tl: {
                                                                              hd: Css.border(Css.px(1), "solid", Styles.Color.grapeError),
                                                                              tl: {
                                                                                hd: Css.color(Styles.Color.grapeError),
                                                                                tl: /* [] */0
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  })
                                                            }, metric.name);
                                                }));
                                  })),
                            Belt_List.mapU(addedItems, (function (metricId) {
                                    return Belt_Option.mapWithDefault(Belt_List.getByU(branchModel.metrics, (function (metric) {
                                                      return metric.id === metricId;
                                                    })), null, (function (metric) {
                                                  return React.createElement("div", {
                                                              key: metric.id + "-added",
                                                              className: Curry._1(Css.style, {
                                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                    tl: {
                                                                      hd: Css.fontSize(Styles.FontSize.small),
                                                                      tl: {
                                                                        hd: Css.padding2(Css.px(10), Css.px(15)),
                                                                        tl: {
                                                                          hd: Css.margin2(Css.px(5), Css.px(0)),
                                                                          tl: {
                                                                            hd: Css.borderRadius(Styles.Border.radius),
                                                                            tl: {
                                                                              hd: Css.border(Css.px(1), "solid", Styles.Color.mintGreen),
                                                                              tl: {
                                                                                hd: Css.color(Styles.Color.mintGreen),
                                                                                tl: /* [] */0
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  })
                                                            }, metric.name);
                                                }));
                                  }))
                          ]))));
  }
}

var make = DiffEventMetrics;

exports.make = make;
/* Css Not a pure module */
