// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconArrowTopRight(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 14;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey70;
  return React.createElement("svg", {
              height: String(size),
              width: String(size),
              fill: "none",
              viewBox: "0 0 12 12",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  className: Curry._1(Css.style, {
                        hd: Css_Legacy_Core.SVG.fill(color),
                        tl: /* [] */0
                      }),
                  d: "M1.62308 12L9.76923 3.85138L9.75385 10.0243H12V0H1.97692L1.96154 2.22934H8.14615L0 10.378L1.62308 12Z"
                }));
}

var make = IconArrowTopRight;

exports.make = make;
/* Css Not a pure module */
