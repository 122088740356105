// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Icon = require("../Icon.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Input = require("../Input.bs.js");
var Title = require("../Title.bs.js");
var React = require("react");
var Button = require("../Button.bs.js");
var Portal = require("../Portal.bs.js");
var Select = require("../Select.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var Sidebar = require("../Sidebar.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var LoadingCircle = require("../LoadingCircle.bs.js");
var OnChangeDebounce = require("../OnChangeDebounce.bs.js");
var GlobalSendContext = require("../GlobalSendContext.bs.js");
var InspectorViewModel = require("./InspectorViewModel.bs.js");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");
var InspectorViewSelectors = require("./InspectorViewSelectors.bs.js");
var InspectorViewWindowPicker = require("./InspectorViewWindowPicker.bs.js");

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.paddingLeft(Css.px(40)),
            tl: {
              hd: Css.paddingTop(Css.px(8)),
              tl: {
                hd: Css.paddingBottom(Css.px(8)),
                tl: {
                  hd: Css.minHeight(Css.px(63)),
                  tl: {
                    hd: Css.position("fixed"),
                    tl: {
                      hd: Css.width({
                            NAME: "substract",
                            VAL: [
                              {
                                NAME: "vw",
                                VAL: 100.0
                              },
                              Css.px(Sidebar.sidebarWidth)
                            ]
                          }),
                      tl: {
                        hd: Css.left(Css.px(Sidebar.sidebarWidth)),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var environmentSelection_0 = Css.height(Css.px(32));

var environmentSelection_1 = {
  hd: Css.paddingLeft(Css.px(16)),
  tl: {
    hd: Css.paddingRight(Css.px(24)),
    tl: {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.color(Styles.Color.grey80),
          tl: /* [] */0
        }
      }
    }
  }
};

var environmentSelection = {
  hd: environmentSelection_0,
  tl: environmentSelection_1
};

var searchInput_0 = Css.fontSize(Styles.FontSize.small);

var searchInput_1 = {
  hd: Css.fontWeight(Styles.FontWeight.medium),
  tl: {
    hd: Css.color(Styles.Color.grey70),
    tl: {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.lineHeight(Css.pct(140.0)),
        tl: {
          hd: Css.borderRadius(Css.px(30)),
          tl: {
            hd: Css.height(Css.px(32)),
            tl: {
              hd: Css.paddingLeft(Css.px(16)),
              tl: {
                hd: Css.paddingRight(Css.px(16)),
                tl: {
                  hd: Css.disabled({
                        hd: Css.placeholder({
                              hd: Css.color(Styles.Color.grey50),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    }
  }
};

var searchInput = {
  hd: searchInput_0,
  tl: searchInput_1
};

var inputContainer = Curry._1(Css.style, {
      hd: Css.width(Css.px(180)),
      tl: /* [] */0
    });

var Style = {
  headerStyles: headerStyles,
  environmentSelection: environmentSelection,
  searchInput: searchInput,
  inputContainer: inputContainer
};

function InspectorViewHeader$Header(Props) {
  var hasSourcesWithoutInspector = Props.hasSourcesWithoutInspector;
  var headerProps = Props.headerProps;
  var inspectorView = Props.inspectorView;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var environment = Belt_Option.map(headerProps, (function (param) {
          return param.environment;
        }));
  var events = Belt_Option.mapWithDefault(headerProps, [], (function (param) {
          return param.events;
        }));
  var fetchEvents = Belt_Option.map(headerProps, (function (param) {
          return param.fetchEvents;
        }));
  var filterByEventName = Belt_Option.mapWithDefault(headerProps, "", (function (param) {
          return param.filterByEventName;
        }));
  var loadingStatus = Belt_Option.map(headerProps, (function (param) {
          return param.loadingStatus;
        }));
  var setEnvironment = Belt_Option.map(headerProps, (function (param) {
          return param.setEnvironment;
        }));
  var setFiltering = Belt_Option.map(headerProps, (function (param) {
          return param.setFiltering;
        }));
  var setTimeWindow = Belt_Option.map(headerProps, (function (param) {
          return param.setTimeWindow;
        }));
  var timeWindow = Belt_Option.map(headerProps, (function (param) {
          return param.timeWindow;
        }));
  var eventFilterInputRef = React.useRef(null);
  var tmp;
  if (inspectorView === "events") {
    var tmp$1;
    if (Caml_obj.caml_notequal(environment, "Production") && Belt_Option.isSome(environment)) {
      var tmp$2 = {
        disabled: Belt_Option.mapWithDefault(loadingStatus, true, (function (loadingStatus) {
                if (typeof loadingStatus !== "object") {
                  return true;
                }
                var variant = loadingStatus.NAME;
                return !(variant === "success" || variant === "error");
              })),
        label: "Reload",
        onClick: (function (param) {
            return Belt_Option.forEach(fetchEvents, (function (fetchEvents) {
                          return Curry._1(fetchEvents, undefined);
                        }));
          }),
        style: "ghost"
      };
      var tmp$3 = Belt_Option.map(loadingStatus, (function (loadingStatus) {
              if (typeof loadingStatus === "object" && loadingStatus.NAME === "success") {
                return "Last updated " + new Date(loadingStatus.VAL[1]).toLocaleTimeString();
              } else {
                return "";
              }
            }));
      if (tmp$3 !== undefined) {
        tmp$2.title = Caml_option.valFromOption(tmp$3);
      }
      tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Button.make, tmp$2), React.createElement(Spacer.make, {
                width: 8
              }));
    } else {
      tmp$1 = null;
    }
    var tmp$4;
    var exit = 0;
    if (environment !== undefined && loadingStatus !== undefined && timeWindow !== undefined && setTimeWindow !== undefined) {
      var date = InspectorViewSelectors.getLatestTimestamp(events);
      tmp$4 = React.createElement(InspectorViewWindowPicker.make, {
            timeWindow: timeWindow,
            setTimeWindow: setTimeWindow,
            environment: environment,
            lastUpdated: date !== undefined ? Caml_option.some(Caml_option.valFromOption(date)) : (
                typeof loadingStatus === "object" && loadingStatus.NAME === "success" ? Caml_option.some(new Date(loadingStatus.VAL[1])) : undefined
              ),
            globalSend: globalSend
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp$4 = React.createElement(InspectorViewWindowPicker.MainButton.make, {
            disabled: true,
            label: "Last 24 hours"
          });
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
              width: 16,
              grow: 1.0
            }), Belt_Option.mapWithDefault(loadingStatus, null, (function (loadingStatus) {
                if (typeof loadingStatus === "object") {
                  var variant = loadingStatus.NAME;
                  if (variant === "success" || variant === "error") {
                    return null;
                  }
                  
                }
                return React.createElement(LoadingCircle.make, {
                            color: Styles.Color.grey30
                          });
              })), tmp$1, tmp$4, React.createElement(Spacer.make, {
              width: 8
            }), React.createElement(Select.make, {
              additionalStyles: environmentSelection,
              disabled: Belt_Option.isNone(environment),
              onSelect: (function (env) {
                  return Belt_Option.forEach(setEnvironment, (function (setEnvironment) {
                                return Curry._1(setEnvironment, (function (param) {
                                              return Belt_Option.getWithDefault(InspectorViewModel.environmentFromJs(env), "Development");
                                            }));
                              }));
                }),
              options: {
                hd: [
                  {
                    NAME: "Label",
                    VAL: "Development"
                  },
                  InspectorViewModel.environmentToJs("Development")
                ],
                tl: {
                  hd: [
                    {
                      NAME: "Label",
                      VAL: "Staging"
                    },
                    InspectorViewModel.environmentToJs("Staging")
                  ],
                  tl: {
                    hd: [
                      {
                        NAME: "Label",
                        VAL: "Production"
                      },
                      InspectorViewModel.environmentToJs("Production")
                    ],
                    tl: /* [] */0
                  }
                }
              },
              value: Belt_Option.mapWithDefault(environment, "Production", InspectorViewModel.environmentToJs)
            }), React.createElement(Spacer.make, {
              width: 8
            }), React.createElement("div", {
              className: inputContainer
            }, React.createElement(OnChangeDebounce.make, {
                  initialValue: filterByEventName,
                  onChange: (function (value) {
                      return Belt_Option.forEach(setFiltering, (function (setFiltering) {
                                    return Curry._1(setFiltering, (function (param) {
                                                  return value;
                                                }));
                                  }));
                    }),
                  children: (function (value, onChange) {
                      return React.createElement(Input.make, {
                                  value: value,
                                  onChange: onChange,
                                  id: "filter",
                                  placeholder: "Search for event...",
                                  className: searchInput,
                                  disabled: Belt_Option.isNone(environment),
                                  inputRef: eventFilterInputRef,
                                  icon: React.createElement(Icon.make, {
                                        type_: "search",
                                        color: Belt_Option.isSome(environment) ? Styles.Color.grey80 : Styles.Color.grey40
                                      })
                                });
                    })
                })));
  } else {
    tmp = null;
  }
  return React.createElement(Portal.make, {
              children: React.createElement("div", {
                    className: headerStyles
                  }, React.createElement(Title.make, {
                        children: inspectorView === "issues" ? "Inspector Issues" : (
                            inspectorView === "events" ? "Inspector Events" : "Saved Views"
                          ),
                        size: "Tiny"
                      }), React.createElement(Spacer.make, {
                        width: 16
                      }), React.createElement(Button.make, {
                        icon: "plus",
                        label: "Connect Source",
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: hasSourcesWithoutInspector ? ({
                                        NAME: "SourceSetup",
                                        VAL: [
                                          "inspectorSetup",
                                          {
                                            NAME: "inspector",
                                            VAL: "events"
                                          }
                                        ]
                                      }) : ({
                                        NAME: "CreateSource",
                                        VAL: "inspectorSetup"
                                      })
                                });
                            return AnalyticsRe.emptyStateInteracted(schemaGroup, inspectorView === "events" ? "InspectorEvents" : "InspectorIssues", "Setup", undefined);
                          }),
                        style: "outline"
                      }), tmp)
            });
}

var Header = {
  make: InspectorViewHeader$Header
};

function InspectorViewHeader$Disabled(Props) {
  var hasSourcesWithoutInspector = Props.hasSourcesWithoutInspector;
  var inspectorView = Props.inspectorView;
  return React.createElement(InspectorViewHeader$Header, {
              hasSourcesWithoutInspector: hasSourcesWithoutInspector,
              inspectorView: inspectorView
            });
}

var Disabled = {
  make: InspectorViewHeader$Disabled
};

function InspectorViewHeader(Props) {
  var environment = Props.environment;
  var events = Props.events;
  var fetchEvents = Props.fetchEvents;
  var filterByEventName = Props.filterByEventName;
  var loadingStatus = Props.loadingStatus;
  var setEnvironment = Props.setEnvironment;
  var setFiltering = Props.setFiltering;
  var setTimeWindow = Props.setTimeWindow;
  var timeWindow = Props.timeWindow;
  var hasSourcesWithoutInspector = Props.hasSourcesWithoutInspector;
  var inspectorView = Props.inspectorView;
  return React.createElement(InspectorViewHeader$Header, {
              hasSourcesWithoutInspector: hasSourcesWithoutInspector,
              headerProps: {
                environment: environment,
                events: events,
                fetchEvents: fetchEvents,
                filterByEventName: filterByEventName,
                loadingStatus: loadingStatus,
                setEnvironment: setEnvironment,
                setFiltering: setFiltering,
                setTimeWindow: setTimeWindow,
                timeWindow: timeWindow
              },
              inspectorView: inspectorView
            });
}

var headerHeight = 63;

var make = InspectorViewHeader;

exports.headerHeight = headerHeight;
exports.Style = Style;
exports.Header = Header;
exports.Disabled = Disabled;
exports.make = make;
/* headerStyles Not a pure module */
