// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Kbar = require("kbar");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function Make(KBar) {
  var ActionImpl = {};
  var t = function (id, item, scoreMultiplier, shortcut, keywords, icon, perform, parent, param) {
    var tmp = {
      item: item,
      id: id,
      name: Curry._1(KBar.itemToString, item)
    };
    if (scoreMultiplier !== undefined) {
      tmp.scoreMultiplier = Caml_option.valFromOption(scoreMultiplier);
    }
    if (shortcut !== undefined) {
      tmp.shortcut = Caml_option.valFromOption(shortcut);
    }
    if (keywords !== undefined) {
      tmp.keywords = Caml_option.valFromOption(keywords);
    }
    var tmp$1 = Curry._1(KBar.itemToSectionString, item);
    if (tmp$1 !== undefined) {
      tmp.section = Caml_option.valFromOption(tmp$1);
    }
    if (icon !== undefined) {
      tmp.icon = Caml_option.valFromOption(icon);
    }
    if (perform !== undefined) {
      tmp.perform = Caml_option.valFromOption(perform);
    }
    if (parent !== undefined) {
      tmp.parent = Caml_option.valFromOption(parent);
    }
    return tmp;
  };
  var Action = {
    t: t
  };
  var convert = function (t) {
    if ((typeof t == 'string')) {
      return {
              TAG: /* Section */0,
              _0: t
            };
    } else {
      return {
              TAG: /* Action */1,
              _0: t
            };
    }
  };
  var Result = {
    convert: convert
  };
  var Provider = {};
  var Portal = {};
  var Positioner = {};
  var Animator = {};
  var Search = {};
  var convert$1 = function (t) {
    return {
            results: Belt_Array.map(t.results, convert),
            rootActionId: Caml_option.nullable_to_opt(t.rootActionId)
          };
  };
  var useMatches = function (param) {
    return convert$1(Kbar.useMatches());
  };
  var Matches = {
    convert: convert$1,
    useMatches: useMatches
  };
  var Internal = {};
  var KBar$Make$Results = function (Props) {
    var items = Props.items;
    var onRender = Props.onRender;
    var items$1 = Belt_Array.map(items, (function (item) {
            return item._0;
          }));
    var onRender$1 = function (item) {
      return Curry._1(onRender, {
                  item: convert(item.item),
                  active: item.active
                });
    };
    return React.createElement(Kbar.KBarResults, {
                items: items$1,
                onRender: onRender$1
              });
  };
  var Results = {
    Internal: Internal,
    make: KBar$Make$Results
  };
  var State = {};
  var Query = {};
  var HookState = {};
  return {
          ActionImpl: ActionImpl,
          Action: Action,
          Result: Result,
          Provider: Provider,
          Portal: Portal,
          Positioner: Positioner,
          Animator: Animator,
          Search: Search,
          Matches: Matches,
          Results: Results,
          State: State,
          Query: Query,
          HookState: HookState
        };
}

exports.Make = Make;
/* kbar Not a pure module */
