// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");

function ActivityItemLoading(Props) {
  var shimmerOpt = Props.shimmer;
  var shimmer = shimmerOpt !== undefined ? shimmerOpt : false;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("flexStart"),
                      tl: {
                        hd: Css.padding(Css.px(16)),
                        tl: {
                          hd: Css.height(Css.px(76)),
                          tl: {
                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(1)),
                        tl: {
                          hd: Css.width(Css.px(24)),
                          tl: {
                            hd: Css.height(Css.px(24)),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: {
                                hd: Css.borderRadius(Css.pct(100)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.grey20),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }), React.createElement(Spacer.make, {
                  width: 12
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.merge, {
                            hd: Curry._1(Css.style, shimmer ? Styles.shimmer : ({
                                      hd: Css.backgroundColor(Styles.Color.grey20),
                                      tl: /* [] */0
                                    })),
                            tl: {
                              hd: Curry._1(Css.style, {
                                    hd: Css.width(Css.pct(100)),
                                    tl: {
                                      hd: Css.height(Css.px(8)),
                                      tl: {
                                        hd: Css.marginTop(Css.px(2)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          })
                    }), React.createElement("div", {
                      className: Curry._1(Css.merge, {
                            hd: Curry._1(Css.style, shimmer ? Styles.shimmer : ({
                                      hd: Css.backgroundColor(Styles.Color.grey20),
                                      tl: /* [] */0
                                    })),
                            tl: {
                              hd: Curry._1(Css.style, {
                                    hd: Css.width(Css.pct(50)),
                                    tl: {
                                      hd: Css.height(Css.px(8)),
                                      tl: {
                                        hd: Css.marginTop(Css.px(8)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          })
                    }), React.createElement("div", {
                      className: Curry._1(Css.merge, {
                            hd: Curry._1(Css.style, shimmer ? Styles.shimmer : ({
                                      hd: Css.backgroundColor(Styles.Color.grey20),
                                      tl: /* [] */0
                                    })),
                            tl: {
                              hd: Curry._1(Css.style, {
                                    hd: Css.width(Css.pct(70)),
                                    tl: {
                                      hd: Css.height(Css.px(6)),
                                      tl: {
                                        hd: Css.marginTop(Css.px(8)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          })
                    })));
}

var make = ActivityItemLoading;

exports.make = make;
/* Css Not a pure module */
