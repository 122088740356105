// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var CopyButton = require("../CopyButton.bs.js");

function code(docsCodeBoxStyles, docsCodeStyles, docsCodeMintGreenStyles, docsCodeOrangeStyles, copyButtonBoxStyles, language) {
  if (language === "dart") {
    return React.createElement("div", {
                className: docsCodeBoxStyles
              }, React.createElement("pre", {
                    className: docsCodeStyles
                  }, "avoInspector.", React.createElement("span", {
                        className: docsCodeMintGreenStyles
                      }, "trackSchemaFromEvent"), "(\n    eventName: ", React.createElement("span", {
                        className: docsCodeOrangeStyles
                      }, "\"Event Name\""), ",\n    eventProperties: {", React.createElement("span", {
                        className: docsCodeOrangeStyles
                      }, "\"id\""), ": ", React.createElement("span", {
                        className: docsCodeOrangeStyles
                      }, "\"…\""), ", ", React.createElement("span", {
                        className: docsCodeOrangeStyles
                      }, "\"number\""), ": ", React.createElement("span", {
                        className: docsCodeMintGreenStyles
                      }, "41"), "}\n);"), React.createElement("div", {
                    className: copyButtonBoxStyles
                  }, React.createElement(CopyButton.make, {
                        copyString: "avoInspector.trackSchemaFromEvent(eventName: \"Event name\", eventProperties: {\"id\": \"…\", \"number\": 41});"
                      })));
  } else {
    return null;
  }
}

exports.code = code;
/* react Not a pure module */
