// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var Prism = require("./externals/Prism.bs.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var IconCopy = require("./IconCopy.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var CopyTextToClipboard = require("copy-text-to-clipboard");

function getHighlightLang(language) {
  if (language === "html") {
    return "html";
  } else if (language === "java") {
    return "java";
  } else if (language === "json") {
    return "json";
  } else if (language === "objc") {
    return "objectivec";
  } else if (language === "js" || language === "js_v2") {
    return "javascript";
  } else if (language === "re" || language === "re_v2") {
    return "rescript";
  } else if (language === "cs") {
    return "csharp";
  } else if (language === "go") {
    return "go";
  } else if (language === "kt") {
    return "kotlin";
  } else if (language === "py3" || language === "py") {
    return "python";
  } else if (language === "rb") {
    return "ruby";
  } else if (language === "ts") {
    return "typescript";
  } else if (language === "php") {
    return "php";
  } else if (language === "swift") {
    return "swift";
  } else {
    return "dart";
  }
}

function Snippet(Props) {
  var wrapOpt = Props.wrap;
  var onClick = Props.onClick;
  var language = Props.language;
  var snippet = Props.snippet;
  var wrap = wrapOpt !== undefined ? wrapOpt : false;
  var match = Hooks.useAutoOffSwitch(1000);
  var showCopied = match[1];
  var shouldShowCopied = match[0];
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.padding2(Css.px(10), Css.px(15)),
                      tl: {
                        hd: Css.fontWeight(Styles.FontWeight.regular),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.grey90),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.color(Styles.Color.grey90),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.regular),
                                tl: {
                                  hd: Css.fontFamily(Styles.monoFontFamily),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("button", {
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.button),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.position("absolute"),
                                tl: {
                                  hd: Css.top(Css.px(0)),
                                  tl: {
                                    hd: Css.right(Css.px(0)),
                                    tl: {
                                      hd: Css.padding2(Css.px(10), Css.px(12)),
                                      tl: {
                                        hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey40),
                                        tl: {
                                          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey40),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css_Legacy_Core.SVG.fill(Styles.Color.blue),
                                                  tl: {
                                                    hd: Css_Legacy_Core.SVG.stroke(Styles.Color.blue),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function (param) {
                      CopyTextToClipboard(snippet);
                      Curry._1(showCopied, undefined);
                      if (onClick !== undefined) {
                        Curry._1(onClick, undefined);
                      }
                      
                    })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.position("absolute"),
                            tl: {
                              hd: Css.right(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.bottom(Css.pct(95)),
                                  tl: {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.padding2(Css.px(4), Css.px(8)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.grey80),
                                  tl: {
                                    hd: Css.borderRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.color(Styles.Color.white),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.tiny),
                                          tl: {
                                            hd: Css.transform({
                                                  NAME: "translateY",
                                                  VAL: Css.px(shouldShowCopied ? 0 : 5)
                                                }),
                                            tl: {
                                              hd: Css.opacity(shouldShowCopied ? 1 : 0),
                                              tl: {
                                                hd: Css.transition({
                                                      NAME: "ms",
                                                      VAL: Styles.Duration.$$default
                                                    }, undefined, undefined, "all"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              })
                        }, "Copied!")), React.createElement(IconCopy.make, {})), React.createElement(Prism.make, {
                  snippet: snippet,
                  language: getHighlightLang(language),
                  className: Curry._1(Css.style, Belt_List.concat({
                            hd: Css.margin(Css.px(0)),
                            tl: /* [] */0
                          }, wrap ? ({
                                hd: Css.wordBreak("breakAll"),
                                tl: /* [] */0
                              }) : /* [] */0))
                }));
}

var make = Snippet;

exports.getHighlightLang = getHighlightLang;
exports.make = make;
/* Css Not a pure module */
