// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Icon = require("./Icon.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Styles = require("./styles.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ContextMenu = require("./ContextMenu.bs.js");
var ExportModal = require("./ExportModal.bs.js");
var IconSettings = require("./IconSettings.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");

function openMembersModal(schemaGroup, globalSend) {
  AnalyticsRe.membersClicked(schemaGroup, schemaGroup.branchId, schemaGroup.schemaId);
  return Curry._1(globalSend, {
              TAG: /* OpenModal */4,
              _0: {
                NAME: "Settings",
                VAL: undefined
              }
            });
}

var buttonStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(0)),
      tl: {
        hd: Css.marginTop(Css.px(2)),
        tl: {
          hd: Css.margin(Css.px(0)),
          tl: {
            hd: Css.backgroundColor(Css.transparent),
            tl: {
              hd: Css.borderWidth(Css.px(0)),
              tl: {
                hd: Css.cursor("pointer"),
                tl: {
                  hd: Css.active({
                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.focus({
                          hd: Css.outlineStyle("none"),
                          tl: {
                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.blue)),
                            tl: /* [] */0
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function SidebarSettingsCog(Props) {
  var globalSend = Props.globalSend;
  var model = Props.model;
  var schema = Props.schema;
  var role = Props.role;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var tmp;
  if (role === "Admin") {
    var tmp$1 = {
      label: "Audit log",
      onClick: (function (param) {
          if (AvoLimits.AuditLog.computeAvailability(workspace) === "Unavailable") {
            return Curry._1(globalSend, {
                        TAG: /* OpenModal */4,
                        _0: {
                          NAME: "BillingPrompt",
                          VAL: "OpenAuditLog"
                        }
                      });
          } else {
            return Router.Schema.pushSchemaRoute(undefined, undefined, "auditLog");
          }
        })
    };
    var tmp$2 = AvoLimits.AuditLog.computeAvailability(workspace) === "Available" ? undefined : Caml_option.some(React.createElement(Icon.make, {
                type_: "lightning",
                size: "small",
                color: Styles.Color.orange
              }));
    if (tmp$2 !== undefined) {
      tmp$1.rightSection = Caml_option.valFromOption(tmp$2);
    }
    tmp = React.createElement(ContextMenu.V2.Item.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement(ContextMenu.V2.make, {
              position: "right-start",
              offset: {
                NAME: "y",
                VAL: -14
              },
              button: React.createElement("button", {
                    className: buttonStyles,
                    id: "sidebar-tour-settings"
                  }, React.createElement(IconSettings.make, {
                        hoverColor: Styles.Color.white
                      })),
              children: null
            }, React.createElement(ContextMenu.V2.Item.make, {
                  label: "Workspace settings",
                  onClick: (function (param) {
                      return openMembersModal(schemaGroup, globalSend);
                    })
                }), role === "Admin" || role === "BillingOnly" ? React.createElement(ContextMenu.V2.Item.make, {
                    label: "Billing",
                    onClick: (function (param) {
                        return Router.Schema.pushSchemaRoute(undefined, undefined, "billing");
                      })
                  }) : null, Models.Role.canEdit(role) ? React.createElement(ContextMenu.V2.Item.make, {
                    label: "Import",
                    onClick: (function (param) {
                        return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                    NAME: "import",
                                    VAL: "index"
                                  });
                      })
                  }) : null, React.createElement(ContextMenu.V2.Item.make, {
                  label: "Export",
                  onClick: (function (param) {
                      return Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "GenericModal",
                                    VAL: (function (onClose) {
                                        return React.createElement(ExportModal.make, {
                                                    onClose: onClose,
                                                    model: model,
                                                    schema: schema,
                                                    schemaGroup: schemaGroup
                                                  });
                                      })
                                  }
                                });
                    })
                }), tmp, React.createElement(ContextMenu.V2.Divider.make, {}), React.createElement(ContextMenu.V2.Item.make, {
                  label: "Switch workspace",
                  onClick: (function (param) {
                      return Router.push(undefined, Router.Link.getBaseRoute(undefined, "allSchemas"));
                    })
                }), React.createElement(ContextMenu.V2.Divider.make, {}), React.createElement(ContextMenu.V2.Item.make, {
                  label: "Submit a feature request",
                  onClick: (function (param) {
                      window.open("https://feedback.avo.app/feature-requests", "_blank");
                      
                    })
                }), React.createElement(ContextMenu.V2.Item.make, {
                  label: "Report a bug",
                  onClick: (function (param) {
                      window.open("https://feedback.avo.app/bug-reports", "_blank");
                      
                    })
                }));
}

var make = SidebarSettingsCog;

exports.openMembersModal = openMembersModal;
exports.buttonStyles = buttonStyles;
exports.make = make;
/* buttonStyles Not a pure module */
