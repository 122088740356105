// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var JsDiff = require("./externals/JsDiff.bs.js");
var Styles = require("./styles.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var SourceIcon = require("./SourceIcon.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var ContextMenu = require("./ContextMenu.bs.js");

function SourceRowDiff(Props) {
  var source = Props.source;
  var onRemove = Props.onRemove;
  var colorsOpt = Props.colors;
  var actionsOpt = Props.actions;
  var branchModel = Props.branchModel;
  var colors = colorsOpt !== undefined ? colorsOpt : [
      Styles.Color.grey70,
      Styles.Color.grey20
    ];
  var actions = actionsOpt !== undefined ? actionsOpt : /* [] */0;
  var name = AvoConfig.getSourceName(source);
  var primary = colors[0];
  var addedDestinations = Belt_List.keepMap(actions, (function (x) {
          if (typeof x !== "object") {
            return ;
          }
          if (x.NAME !== "IncludeDestinationInEventSource") {
            return ;
          }
          var match = x.VAL;
          if (source.id === match[1]) {
            return match[2];
          }
          
        }));
  var removedDestinations = Belt_List.keepMap(actions, (function (x) {
          if (typeof x !== "object") {
            return ;
          }
          if (x.NAME !== "ExcludeDestinationFromEventSource") {
            return ;
          }
          var match = x.VAL;
          if (source.id === match[1]) {
            return match[2];
          }
          
        }));
  var toggledIncludeInCodegen = Belt_List.keepMap(actions, (function (x) {
          if (typeof x !== "object") {
            return ;
          }
          var variant = x.NAME;
          if (variant === "ToggleIncludeEventInCodegenForSource") {
            var match = x.VAL;
            if (source.id !== match[1]) {
              return ;
            }
            var includedInCodegen = match[2];
            return [
                    !includedInCodegen,
                    includedInCodegen
                  ];
          }
          if (variant !== "IncludeEventInSourceV2") {
            return ;
          }
          var match$1 = x.VAL;
          if (source.id !== match$1[1]) {
            return ;
          }
          var includedInCodegen$1 = match$1[3];
          return [
                  includedInCodegen$1,
                  includedInCodegen$1
                ];
        }));
  var match = Belt_List.head(toggledIncludeInCodegen);
  var tmp;
  if (match !== undefined) {
    var from = match[0] ? "Included in Codegen" : "Not included in Codegen";
    var to_ = match[1] ? "Included in Codegen" : "Not included in Codegen";
    tmp = React.createElement($$Text.make, {
          size: "Small",
          weight: "Semi",
          color: Styles.Color.green100,
          children: JsDiff.renderStringDiff(undefined, true, false, from, to_)
        });
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.justifyContent("spaceBetween"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.padding4(Css.px(6), Css.px(5), Css.px(6), Css.px(15)),
                          tl: {
                            hd: Css.margin2(Css.px(5), Css.px(0)),
                            tl: {
                              hd: Css.border(Css.px(1), "solid", colors[1]),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("spaceBetween"),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(SourceIcon.make, {
                          source: source,
                          color: primary
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.bold),
                                tl: {
                                  hd: Css.borderRadius(Styles.Border.radius),
                                  tl: {
                                    hd: Css.textAlign("left"),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.regular),
                                      tl: {
                                        hd: Css.paddingLeft(Css.px(10)),
                                        tl: {
                                          hd: Css.color(primary),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              })
                        }, name), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.flexWrap("wrap"),
                                  tl: {
                                    hd: Css.marginLeft(Css.px(10)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, Belt_List.toArray(Belt_List.map(removedDestinations, (function (removedDestination) {
                                    return React.createElement("div", {
                                                key: removedDestination,
                                                className: Curry._1(Css.style, {
                                                      hd: Css.padding2(Css.px(2), Css.px(8)),
                                                      tl: {
                                                        hd: Css.backgroundColor(Styles.Color.grapeError),
                                                        tl: {
                                                          hd: Css.color(Styles.Color.white),
                                                          tl: {
                                                            hd: Css.borderRadius(Css.px(100)),
                                                            tl: {
                                                              hd: Css.margin2(Css.px(2), Css.px(2)),
                                                              tl: {
                                                                hd: Css.fontSize(Styles.FontSize.small),
                                                                tl: {
                                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    })
                                              }, Belt_Option.getWithDefault(Belt_Option.map(StateUtils.getDestinationById(removedDestination, branchModel), StateUtils.getDestinationName), ""));
                                  }))), Belt_List.toArray(Belt_List.map(addedDestinations, (function (addedDestination) {
                                    return React.createElement("div", {
                                                key: addedDestination,
                                                className: Curry._1(Css.style, {
                                                      hd: Css.padding2(Css.px(2), Css.px(8)),
                                                      tl: {
                                                        hd: Css.backgroundColor(Styles.Color.mintGreen),
                                                        tl: {
                                                          hd: Css.color(Styles.Color.white),
                                                          tl: {
                                                            hd: Css.borderRadius(Css.px(100)),
                                                            tl: {
                                                              hd: Css.margin2(Css.px(2), Css.px(2)),
                                                              tl: {
                                                                hd: Css.fontSize(Styles.FontSize.small),
                                                                tl: {
                                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    })
                                              }, Belt_Option.getWithDefault(Belt_Option.map(StateUtils.getDestinationById(addedDestination, branchModel), StateUtils.getDestinationName), ""));
                                  }))))), React.createElement("div", undefined, tmp)), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      })
                }, onRemove !== undefined ? React.createElement(ContextMenu.make, {
                        options: [{
                            NAME: "Option",
                            VAL: {
                              label: "Remove Source",
                              onClick: (function (param) {
                                  return Curry._1(onRemove, undefined);
                                })
                            }
                          }]
                      }) : null));
}

var make = SourceRowDiff;

exports.make = make;
/* Css Not a pure module */
