// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Import = require("./import/Import.bs.js");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Styles = require("./styles.bs.js");
var Actions = require("./actions.bs.js");
var Billing = require("./Billing.bs.js");
var DateFns = require("./DateFns.bs.js");
var Metrics = require("./Metrics.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Sidebar = require("./Sidebar.bs.js");
var AuditLog = require("./AuditLog.bs.js");
var CmdKRoot = require("./CmdKRoot.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Intercom = require("./externals/intercom.bs.js");
var NoAccess = require("./NoAccess.bs.js");
var Redirect = require("./Redirect.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Implement = require("./Implement.bs.js");
var BranchView = require("./BranchView.bs.js");
var RolePrompt = require("./RolePrompt.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CmdKResults = require("./CmdKResults.bs.js");
var Connections = require("./Connections.bs.js");
var EventsSheet = require("./EventsSheet.bs.js");
var RouterStore = require("./RouterStore.bs.js");
var Announcement = require("./Announcement.bs.js");
var BranchStatus = require("./externals/BranchStatus.bs.js");
var Integrations = require("./integration/Integrations.bs.js");
var NewUserModal = require("./NewUserModal.bs.js");
var App = require("firebase/app");
var QueryString = require("query-string");
var ViewerContext = require("./ViewerContext.bs.js");
var ModalsContainer = require("./ModalsContainer.bs.js");
var PropertiesSheet = require("./PropertiesSheet.bs.js");
var SchemaDashboard = require("./SchemaDashboard.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var GlobalStateContext = require("./GlobalStateContext.bs.js");
var ImportSuccessModal = require("./import/ImportSuccessModal.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SlideOverContainer = require("./SlideOverContainer.bs.js");
var DiscrepanciesConfig = require("./DiscrepanciesConfig.bs.js");
var InspectorEventsView = require("./inspector/InspectorEventsView.bs.js");
var InspectorIssuesView = require("./InspectorIssuesView.bs.js");
var PropertyGroupsSheet = require("./PropertyGroupsSheet.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");
var InspectorIssuesSavedViews = require("./inspector/InspectorIssuesSavedViews.bs.js");
var InspectorIssuesFilterHooks = require("./inspector/InspectorIssuesFilterHooks.bs.js");
var OnboardingAndTrialProgressSlideover = require("./OnboardingAndTrialProgressSlideover.bs.js");

function getCurrentBranchName(currentBranch, masterModel) {
  if (typeof currentBranch !== "object") {
    return "main";
  }
  var branchId = currentBranch.VAL;
  return Belt_Option.mapWithDefault(Belt_List.getBy(masterModel.openBranches, (function (param) {
                    return branchId === param[0];
                  })), "N/A", (function (param) {
                return param[1];
              }));
}

function useUpdateSchemaLastUsedAt(userId, schemaId) {
  React.useEffect((function () {
          $$Promise.$$catch(Firebase.app(undefined).firestore().collection("users").doc(userId).collection("schemas").doc(schemaId).update({
                    lastUsedAt: App.default.firestore.FieldValue.serverTimestamp()
                  }), (function (e) {
                  return Promise.resolve((console.error("could not update schemaLastUsedAt", e), undefined));
                }));
          
        }), []);
  
}

function useBranchOpenedAnalytics(branchStatus, integrations, currentBranch, model) {
  var schemaGroup = SchemaGroupContext.use(undefined);
  React.useEffect((function () {
          var maybeDotNewRedirect = QueryString.parse(Router.getSearch(undefined).replace("?", "")).new;
          var tmp;
          if (maybeDotNewRedirect == null) {
            tmp = undefined;
          } else {
            switch (maybeDotNewRedirect) {
              case "branch" :
                  tmp = "NewBranch";
                  break;
              case "event" :
                  tmp = "NewEvent";
                  break;
              case "metric" :
                  tmp = "NewMetric";
                  break;
              case "property" :
                  tmp = "NewProperty";
                  break;
              default:
                tmp = undefined;
            }
          }
          AnalyticsRe.branchOpened(schemaGroup, Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid, BranchStatus.toAnalytics(branchStatus), Belt_List.length(integrations), tmp, Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).email, schemaGroup.branchId, schemaGroup.schemaId, schemaGroup.branchId, BranchStatus.toAnalytics(branchStatus), schemaGroup.schemaId, currentBranch === "Master" ? Belt_List.length(model.events) : undefined, currentBranch === "Master" ? Belt_List.length(model.properties) : undefined, schemaGroup.schemaName, schemaGroup.schemaId, schemaGroup.schemaTrialPlan, schemaGroup.schemaBillingStatus, schemaGroup.schemaSubscriptionPlan, currentBranch === "Master" ? Belt_List.length(model.destinations) : undefined, currentBranch === "Master" ? Belt_List.length(model.sources) : undefined);
          Intercom.update({
                alignment: "right"
              });
          
        }), []);
  
}

function useIntercomAlignment(alignment) {
  React.useEffect((function () {
          Intercom.update({
                alignment: alignment
              });
          
        }), []);
  
}

function useDemoBranchPrompt(globalSend, schemaId, isOnMaster, hasOpenDialog, viewerRole) {
  var schemaGroup = SchemaGroupContext.use(undefined);
  var user = ViewerContext.use(undefined);
  var match = WorkspaceContext.use(undefined);
  var members = match.members;
  var hasCreatedDemoBranch = Belt_Option.isSome(FirebaseFetcherHooks.useBranchOpenAction(schemaGroup.schemaId, "demo-" + user.id, "Demo", undefined, undefined));
  var hasSkippedDemoBranch = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.demoBranchSkipped), false);
  var demoBranchOpenAction = FirebaseFetcherHooks.useBranchOpenActionExists(schemaId, undefined, "Demo", "demo-" + user.id);
  var hasShownMessageThisSession = React.useRef(false);
  var demoBranchLaunchDate = new Date(2022.0, 5.0, 10.0);
  var userCreatedAfterDemoBranchLaunch = Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(user.createdAt), false, (function (firebaseTimestamp) {
          return DateFns.isAfter(demoBranchLaunchDate, firebaseTimestamp.toDate());
        }));
  var isMemberOfWorkspace = React.useMemo((function () {
          return Belt_List.someU(members, (function (member) {
                        return member.id === user.id;
                      }));
        }), [
        members,
        user.id
      ]);
  React.useEffect((function () {
          var modalTimeoutId = {
            contents: undefined
          };
          var shouldOfferDemoBranchForSuperUserAlways = FirebaseFetcherHooks.isSuperUser(undefined) && isMemberOfWorkspace && isOnMaster && !hasShownMessageThisSession.current && demoBranchOpenAction === "NotFound" && !NewUserModal.LocalStorage.hasSeenForSchemaId(schemaId);
          var shouldOfferDemoBranch = !hasCreatedDemoBranch && !hasSkippedDemoBranch && isOnMaster && demoBranchOpenAction === "NotFound" && !hasShownMessageThisSession.current && (userCreatedAfterDemoBranchLaunch || FirebaseFetcherHooks.isSuperUser(undefined)) && Models.Role.canEdit(viewerRole) || shouldOfferDemoBranchForSuperUserAlways;
          if (shouldOfferDemoBranch && !hasOpenDialog) {
            modalTimeoutId.contents = Caml_option.some(setTimeout((function (param) {
                        Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: {
                                NAME: "NewUser",
                                VAL: user.email
                              }
                            });
                        hasShownMessageThisSession.current = true;
                        
                      }), 1000));
          }
          return (function (param) {
                    return Belt_Option.forEach(modalTimeoutId.contents, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), [
        demoBranchOpenAction,
        hasOpenDialog
      ]);
  
}

function AvoRoutes$IntercomVisibility(Props) {
  var drawerItems = RouterStore.Schema.useDrawerItems(undefined);
  Hooks.useIntercomVisibility(Belt_Option.mapWithDefault(Belt_List.head(drawerItems), true, (function (item) {
              if (typeof item === "object" && item.NAME === "event") {
                return item.VAL[2] === undefined;
              } else {
                return true;
              }
            })));
  return null;
}

var IntercomVisibility = {
  make: AvoRoutes$IntercomVisibility
};

function AvoRoutes$EnrichedSidebar(Props) {
  var branchStatus = Props.branchStatus;
  var currentBranch = Props.currentBranch;
  var globalSend = Props.globalSend;
  var model = Props.model;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var schema = Props.schema;
  var match = RouterStore.Schema.useSlices(function (param) {
        return [
                param.schemaRoute,
                param.drawerItems
              ];
      });
  var schemaRoute = match[0];
  var currentDrawerItem = Belt_List.head(match[1]);
  var tmp;
  var exit = 0;
  if (typeof schemaRoute === "object" && schemaRoute.NAME === "import") {
    var match$1 = schemaRoute.VAL;
    if (match$1 === "index" || match$1 === "review") {
      tmp = true;
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = currentDrawerItem !== undefined && typeof currentDrawerItem === "object" && currentDrawerItem.NAME === "event" && currentDrawerItem.VAL[3] ? true : false;
  }
  return React.createElement(Sidebar.make, {
              currentBranch: currentBranch,
              globalSend: globalSend,
              hidden: tmp,
              openBranches: openBranches,
              role: role,
              schema: schema,
              model: model,
              branchStatus: branchStatus
            });
}

var EnrichedSidebar = {
  make: AvoRoutes$EnrichedSidebar
};

function AvoRoutes$EnrichedSlideOverContainer(Props) {
  var schema = Props.schema;
  var model = Props.model;
  var masterModel = Props.masterModel;
  var renderModel = Props.renderModel;
  var role = Props.role;
  var match = RouterStore.Schema.useSlices(function (param) {
        return [
                Belt_Option.getExn(param.schemaId),
                param.schemaRoute,
                param.drawerItems
              ];
      });
  var drawerItems = match[2];
  var schemaRoute = match[1];
  var globalSend = GlobalSendContext.use(undefined);
  var globalState = GlobalStateContext.use(undefined);
  var match$1 = globalState.slideOver;
  var tmp;
  var exit = 0;
  if (schemaRoute === "settings" && drawerItems) {
    var match$2 = drawerItems.hd;
    if (typeof match$2 === "object") {
      var variant = match$2.NAME;
      if (variant === "source" || variant === "destination") {
        tmp = undefined;
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = match$1 !== undefined ? (
        match$1 === "OnboardingProgress" ? [
            React.createElement(OnboardingAndTrialProgressSlideover.make, {
                  schema: schema,
                  onClose: (function (param) {
                      return Curry._1(globalSend, /* CloseSlideOver */0);
                    }),
                  globalSend: globalSend,
                  integrations: model.integrations,
                  openBranches: masterModel.openBranches
                }),
            "onboarding"
          ] : [
            React.createElement(DiscrepanciesConfig.make, {
                  onClose: (function (param) {
                      return Curry._1(globalSend, /* CloseSlideOver */0);
                    }),
                  schemaId: match[0],
                  model: renderModel,
                  viewerRole: role,
                  globalSend: globalSend
                }),
            "discrepancies"
          ]
      ) : undefined;
  }
  return React.createElement(SlideOverContainer.make, {
              onClose: (function (param) {
                  if (schemaRoute !== "settings") {
                    return Curry._1(globalSend, /* CloseSlideOver */0);
                  }
                  if (!drawerItems) {
                    return Curry._1(globalSend, /* CloseSlideOver */0);
                  }
                  var match = drawerItems.hd;
                  if (typeof match !== "object") {
                    return Curry._1(globalSend, /* CloseSlideOver */0);
                  }
                  var variant = match.NAME;
                  if (variant === "source" || variant === "destination") {
                    return Router.Schema.pushSchemaRoute(undefined, undefined, "settings");
                  } else {
                    return Curry._1(globalSend, /* CloseSlideOver */0);
                  }
                }),
              slideOver: tmp
            });
}

var EnrichedSlideOverContainer = {
  make: AvoRoutes$EnrichedSlideOverContainer
};

function AvoRoutes$EnrichedImportCompleteModal(Props) {
  var latestAction = Props.latestAction;
  var match = RouterStore.Schema.useSlices(function (param) {
        return [
                param.schemaRoute,
                param.drawerItems
              ];
      });
  var schemaRoute = match[0];
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.zIndex(Styles.ZIndex.eventDetailsFullScreen),
                    tl: /* [] */0
                  })
            }, typeof schemaRoute === "object" && schemaRoute.NAME === "import" && schemaRoute.VAL === "success" ? Belt_Option.getWithDefault(Belt_Option.map(latestAction, (function (latestAction) {
                          var match = latestAction.contents;
                          if (typeof match !== "object") {
                            return null;
                          }
                          if (match.NAME !== "CompletedImport") {
                            return null;
                          }
                          var match$1 = match.VAL;
                          return React.createElement(ImportSuccessModal.make, {
                                      eventCount: match$1[0],
                                      propertyCount: match$1[1],
                                      sourceCount: match$1[2]
                                    });
                        })), null) : null);
}

var EnrichedImportCompleteModal = {
  make: AvoRoutes$EnrichedImportCompleteModal
};

function AvoRoutes(Props) {
  var branchStatus = Props.branchStatus;
  var latestAction = Props.latestAction;
  var latestMasterAction = Props.latestMasterAction;
  var masterModel = Props.masterModel;
  var model = Props.model;
  var renderModel = Props.renderModel;
  var role = Props.role;
  var schema = Props.schema;
  var match = RouterStore.Schema.useSlices(function (param) {
        return [
                Belt_Option.getExn(param.schemaId),
                param.baseRoute,
                param.branch,
                param.schemaRoute
              ];
      });
  var schemaRoute = match[3];
  var branch = match[2];
  var baseRoute = match[1];
  var schemaId = match[0];
  var globalSend = GlobalSendContext.use(undefined);
  var user = ViewerContext.use(undefined);
  var match$1 = React.useState(function () {
        return true;
      });
  var setUserJustOpeningApp = match$1[1];
  var userJustOpeningApp = match$1[0];
  React.useEffect(function () {
        if (userJustOpeningApp === true) {
          Curry._1(setUserJustOpeningApp, (function (param) {
                  return false;
                }));
        }
        
      });
  var match$2 = RolePrompt.useRoleDialog(schemaId);
  var showRoleDialog = match$2[0];
  var currentBranch = React.useMemo((function () {
          if (typeof branch === "object") {
            return {
                    NAME: "Branch",
                    VAL: branch.VAL
                  };
          } else {
            return "Master";
          }
        }), [branch]);
  useUpdateSchemaLastUsedAt(user.id, schemaId);
  useBranchOpenedAnalytics(branchStatus, model.integrations, currentBranch, model);
  useIntercomAlignment("right");
  var currentBranchId = Actions.branchToId(currentBranch);
  var currentBranchName = getCurrentBranchName(currentBranch, masterModel);
  var branchOpenActionExists = FirebaseFetcherHooks.useBranchOpenActionExists(schemaId, undefined, "All", currentBranchId);
  var isUnknownBranch = branchOpenActionExists === "NotFound" && currentBranchId !== "master";
  var branchModel = typeof currentBranch === "object" ? ({
        TAG: /* Branch */1,
        _0: renderModel,
        _1: masterModel
      }) : ({
        TAG: /* Main */0,
        _0: renderModel
      });
  useDemoBranchPrompt(globalSend, schema.id, branch === "master", showRoleDialog, role);
  InspectorIssuesFilterHooks.SavedViewStore.useSavedViewManager(undefined);
  var tmp;
  if (isUnknownBranch) {
    tmp = React.createElement(NoAccess.NoBranches.make, {
          schemaId: schemaId
        });
  } else {
    var tmp$1;
    var exit = 0;
    if (typeof schemaRoute === "object") {
      var variant = schemaRoute.NAME;
      if (variant === "import" || variant === "inspector" || variant === "diff") {
        tmp$1 = /* [] */0;
      } else {
        exit = 1;
      }
    } else if (schemaRoute === "events" || schemaRoute === "integrations" || schemaRoute === "dashboard" || schemaRoute === "properties" || schemaRoute === "billing" || schemaRoute === "propertyGroups") {
      tmp$1 = /* [] */0;
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp$1 = {
        hd: Css.padding4(Css.px(15), Css.px(15), Css.px(0), Css.px(15)),
        tl: /* [] */0
      };
    }
    var tmp$2;
    var exit$1 = 0;
    var exit$2 = 0;
    var exit$3 = 0;
    if (role === "BillingOnly") {
      tmp$2 = schemaRoute === "billing" ? React.createElement(Billing.make, {
              schema: schema
            }) : React.createElement(Redirect.make, {
              path: Curry._4(Router.Link.get, {
                    NAME: "schema",
                    VAL: schema.id
                  }, {
                    NAME: "branch",
                    VAL: currentBranchId
                  }, "billing", /* [] */0)
            });
    } else if (role === "Admin") {
      if (schemaRoute === "billing") {
        tmp$2 = React.createElement(Billing.make, {
              schema: schema
            });
      } else if (schemaRoute === "auditLog") {
        tmp$2 = React.createElement(AuditLog.make, {
              schemaId: schema.id
            });
      } else {
        exit$3 = 3;
      }
    } else {
      exit$3 = 3;
    }
    if (exit$3 === 3) {
      var exit$4 = 0;
      if (typeof schemaRoute === "object") {
        var variant$1 = schemaRoute.NAME;
        if (variant$1 === "diff") {
          tmp$2 = typeof branch === "object" ? React.createElement(BranchView.make, {
                  masterModel: masterModel,
                  masterModelLatestAction: latestMasterAction,
                  branchModel: model,
                  schema: schema,
                  branchId: branch.VAL,
                  role: role,
                  openBranches: model.openBranches
                }) : React.createElement(Redirect.make, {
                  path: Curry._4(Router.Link.get, baseRoute, branch, "dashboard", /* [] */0)
                });
        } else if (variant$1 === "inspector") {
          var match$3 = schemaRoute.VAL;
          tmp$2 = typeof match$3 === "object" ? React.createElement(InspectorIssuesView.make, {
                  model: model
                }) : (
              match$3 === "issues" ? React.createElement(InspectorIssuesView.make, {
                      model: model
                    }) : (
                  match$3 === "events" ? React.createElement(InspectorEventsView.make, {
                          schema: schema,
                          model: model
                        }) : React.createElement(InspectorIssuesSavedViews.make, {})
                )
            );
        } else if (variant$1 === "import" && schemaRoute.VAL === "success") {
          exit$4 = 4;
        } else {
          exit$2 = 2;
        }
      } else if (schemaRoute === "propertyGroups") {
        tmp$2 = React.createElement(PropertyGroupsSheet.make, {
              currentBranch: currentBranch,
              globalSend: globalSend,
              model: model,
              schema: schema,
              user: user,
              viewerRole: role
            });
      } else if (schemaRoute === "properties") {
        tmp$2 = React.createElement(PropertiesSheet.make, {
              currentBranch: currentBranch,
              globalSend: globalSend,
              model: model,
              schema: schema,
              user: user,
              viewerRole: role
            });
      } else if (schemaRoute === "settings") {
        tmp$2 = React.createElement(Connections.make, {
              schema: schema,
              model: renderModel,
              globalSend: globalSend
            });
      } else if (schemaRoute === "dashboard") {
        tmp$2 = model.events === /* [] */0 && model.openBranches === /* [] */0 && userJustOpeningApp ? React.createElement(Redirect.make, {
                path: Router.Link.getSchemaRouteLink(undefined, "events")
              }) : React.createElement(SchemaDashboard.make, {
                schema: schema,
                model: renderModel,
                globalSend: globalSend,
                openBranches: masterModel.openBranches,
                currentBranch: currentBranch,
                latestMasterAction: latestMasterAction,
                userId: user.id
              });
      } else if (schemaRoute === "integrations") {
        tmp$2 = React.createElement(Integrations.make, {
              integrations: renderModel.integrations,
              schema: schema,
              model: model,
              role: role
            });
      } else if (schemaRoute === "events") {
        exit$4 = 4;
      } else if (schemaRoute === "metrics") {
        tmp$2 = React.createElement(Metrics.make, {
              model: renderModel,
              role: role
            });
      } else if (schemaRoute === "implement") {
        tmp$2 = React.createElement(Implement.make, {
              model: renderModel
            });
      } else {
        exit$2 = 2;
      }
      if (exit$4 === 4) {
        tmp$2 = React.createElement(EventsSheet.make, {
              currentBranch: currentBranch,
              globalSend: globalSend,
              branchName: currentBranchName,
              branchModel: branchModel,
              role: role,
              user: user
            });
      }
      
    }
    if (exit$2 === 2) {
      if ((role === "Admin" || role === "Editor") && typeof schemaRoute === "object" && schemaRoute.NAME === "import") {
        tmp$2 = React.createElement(Import.make, {
              schemaId: schemaId,
              currentBranch: currentBranch,
              openBranches: masterModel.openBranches,
              model: model,
              viewerRole: role
            });
      } else {
        exit$1 = 1;
      }
    }
    if (exit$1 === 1) {
      tmp$2 = React.createElement(Redirect.make, {
            path: Curry._4(Router.Link.get, baseRoute, branch, "dashboard", /* [] */0)
          });
    }
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, tmp$1)
        }, tmp$2);
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement(AvoRoutes$IntercomVisibility, {}), React.createElement(RolePrompt.make, {
                  showDialog: showRoleDialog,
                  closeDialog: match$2[1]
                }), React.createElement(CmdKRoot.make, {
                  model: model,
                  openBranches: masterModel.openBranches,
                  role: role,
                  children: React.createElement(CmdKResults.make, {})
                }), React.createElement(Announcement.make, {}), React.createElement(AvoRoutes$EnrichedSidebar, {
                  branchStatus: branchStatus,
                  currentBranch: currentBranch,
                  globalSend: globalSend,
                  model: model,
                  openBranches: masterModel.openBranches,
                  role: role,
                  schema: schema
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingLeft(Css.px(250)),
                        tl: /* [] */0
                      })
                }, tmp), React.createElement(AvoRoutes$EnrichedImportCompleteModal, {
                  latestAction: latestAction
                }), React.createElement(AvoRoutes$EnrichedSlideOverContainer, {
                  schema: schema,
                  model: model,
                  masterModel: masterModel,
                  renderModel: renderModel,
                  role: role
                }), React.createElement(ModalsContainer.make, {
                  currentBranch: currentBranch,
                  events: model.events,
                  masterModel: masterModel,
                  model: renderModel,
                  role: role,
                  schema: schema
                }), React.createElement(Toast.Manager.make, {}));
}

var make = React.memo(AvoRoutes);

exports.getCurrentBranchName = getCurrentBranchName;
exports.useUpdateSchemaLastUsedAt = useUpdateSchemaLastUsedAt;
exports.useBranchOpenedAnalytics = useBranchOpenedAnalytics;
exports.useIntercomAlignment = useIntercomAlignment;
exports.useDemoBranchPrompt = useDemoBranchPrompt;
exports.IntercomVisibility = IntercomVisibility;
exports.EnrichedSidebar = EnrichedSidebar;
exports.EnrichedSlideOverContainer = EnrichedSlideOverContainer;
exports.EnrichedImportCompleteModal = EnrichedImportCompleteModal;
exports.make = make;
/* make Not a pure module */
