// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Case = require("../../bs-case/src/case.bs.js");
var List = require("rescript/lib/js/list.js");
var Case$1 = require("case");
var Curry = require("rescript/lib/js/curry.js");
var $$Buffer = require("rescript/lib/js/buffer.js");
var Format = require("rescript/lib/js/format.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Printf = require("rescript/lib/js/printf.js");
var AvoModel = require("../../app/src/avoModel.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var LinkUrls = require("../../shared/utils/LinkUrls.bs.js");
var Printing = require("./Printing.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var StateUtils = require("../../app/src/stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var CodegenRules = require("./CodegenRules.bs.js");
var PropertyUtils = require("../../app/src/PropertyUtils.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var QuillToPlainText = require("../../shared/utils/QuillToPlainText.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var BeltListExtensions = require("../../app/src/BeltListExtensions.bs.js");
var PropertyValidationUtils = require("../../app/src/PropertyValidationUtils.bs.js");

function hasShapeValidation(property) {
  return Belt_List.some(property.validations, (function (validation) {
                if (typeof validation === "object" && validation.NAME === "Shape" && validation.VAL) {
                  return true;
                } else {
                  return false;
                }
              }));
}

function hasMatchValidation(property) {
  return Belt_List.some(property.validations, (function (validation) {
                if (typeof validation === "object" && validation.NAME === "Matches" && validation.VAL) {
                  return true;
                } else {
                  return false;
                }
              }));
}

function hasNestedPropertyValidation(property) {
  return Belt_List.some(property.validations, (function (validation) {
                if (typeof validation === "object" && validation.NAME === "NestedProperty" && validation.VAL) {
                  return true;
                } else {
                  return false;
                }
              }));
}

function nameMappingString(nameMaps) {
  if (nameMaps.length !== 0) {
    return "// Sent as [" + (Belt_Array.map(nameMaps, (function (param) {
                      return "\"" + (param[1] + ("\" to " + Case$1.pascal(param[0])));
                    })).join(", ") + "]");
  } else {
    return "";
  }
}

function shouldSendEventToSourceDestination($$event, source, destinationId) {
  return Belt_List.some($$event.includeDestinations, (function (sourceDestinationMap) {
                var match = sourceDestinationMap.VAL;
                if (match[0] === source.id) {
                  return Belt_List.some(match[1], (function (id) {
                                return id === destinationId;
                              }));
                } else {
                  return false;
                }
              }));
}

function shouldSendEventToDestinationType(model, $$event, destinationType) {
  var destinations = AvoModel.resolveDestinations(model, BeltListExtensions.dedupeString(BeltListExtensions.flatMap($$event.includeDestinations, (function (x) {
                  var match = x.VAL;
                  var sourceId = match[0];
                  if (Belt_List.some($$event.includeSources, (function (includedSource) {
                            return includedSource.id === sourceId;
                          }))) {
                    return match[1];
                  } else {
                    return /* [] */0;
                  }
                }))));
  var destinations$1 = AvoConfig.getValidDestinations(model.sources, destinations);
  return Belt_List.some(destinations$1, (function (destination) {
                return destination.type_ === destinationType;
              }));
}

function propertyCodegenName(property) {
  var name = property.uniqueName;
  var name$1 = name !== undefined && name !== "" ? name : property.name;
  return name$1.replace(/[^\w\s\-]/ig, "");
}

function prefixWithWordIfFirstNumber(s) {
  var match = s.charAt(0);
  var prefix;
  switch (match) {
    case "0" :
        prefix = "Zero";
        break;
    case "1" :
        prefix = "One";
        break;
    case "2" :
        prefix = "Two";
        break;
    case "3" :
        prefix = "Three";
        break;
    case "4" :
        prefix = "Four";
        break;
    case "5" :
        prefix = "Five";
        break;
    case "6" :
        prefix = "Six";
        break;
    case "7" :
        prefix = "Seven";
        break;
    case "8" :
        prefix = "Eight";
        break;
    case "9" :
        prefix = "Nine";
        break;
    default:
      prefix = "";
  }
  if (prefix === "") {
    return s;
  } else {
    return prefix + s.substr(1);
  }
}

function printApiKey(apiKey, language) {
  if (apiKey !== "") {
    if (language === "objc") {
      return "@\"" + (apiKey + "\"");
    } else if (language === "re" || language === "re_v2") {
      return "Some(\"" + (apiKey + "\")");
    } else if (language === "php") {
      return "'" + (apiKey + "'");
    } else {
      return "\"" + (apiKey + "\"");
    }
  } else if (language === "swift" || language === "rb" || language === "go" || language === "objc") {
    return "nil";
  } else if (language === "py3" || language === "re" || language === "py" || language === "re_v2") {
    return "None";
  } else {
    return "null";
  }
}

function prefixWithUnderscoreIfFirstNumber(s) {
  if (Caml_option.null_to_opt(s.match(/^\d/)) !== undefined) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* Char_literal */12,
                      _0: /* '_' */95,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "_%s"
                  }), s);
  } else {
    return s;
  }
}

function eventCodegenName($$event) {
  var name = $$event.uniqueName;
  var name$1 = name !== undefined && name !== "" ? name : $$event.name;
  return name$1.replace(/[^\w\s\-]/ig, "");
}

function propertyDocsFormat(property) {
  return property.name;
}

function eventDocsFormat($$event) {
  return $$event.name;
}

function eventLink(schemaId, branchId, eventId) {
  if (branchId === "master") {
    return Curry._2(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "https://www.avo.app/schemas/",
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* String_literal */11,
                          _0: "/events/",
                          _1: {
                            TAG: /* String */2,
                            _0: /* No_padding */0,
                            _1: /* End_of_format */0
                          }
                        }
                      }
                    },
                    _1: "https://www.avo.app/schemas/%s/events/%s"
                  }), schemaId, eventId);
  } else {
    return Curry._3(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "https://www.avo.app/schemas/",
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* String_literal */11,
                          _0: "/branches/",
                          _1: {
                            TAG: /* String */2,
                            _0: /* No_padding */0,
                            _1: {
                              TAG: /* String_literal */11,
                              _0: "/events/",
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: /* End_of_format */0
                              }
                            }
                          }
                        }
                      }
                    },
                    _1: "https://www.avo.app/schemas/%s/branches/%s/events/%s"
                  }), schemaId, branchId, eventId);
  }
}

function propertyGroupLink(schemaId, branchId, propertyGroupId) {
  if (branchId === "master") {
    return Curry._2(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "https://www.avo.app/schemas/",
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* String_literal */11,
                          _0: "/properties/group/",
                          _1: {
                            TAG: /* String */2,
                            _0: /* No_padding */0,
                            _1: /* End_of_format */0
                          }
                        }
                      }
                    },
                    _1: "https://www.avo.app/schemas/%s/properties/group/%s"
                  }), schemaId, propertyGroupId);
  } else {
    return Curry._3(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "https://www.avo.app/schemas/",
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* String_literal */11,
                          _0: "/branches/",
                          _1: {
                            TAG: /* String */2,
                            _0: /* No_padding */0,
                            _1: {
                              TAG: /* String_literal */11,
                              _0: "/properties/group/",
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: /* End_of_format */0
                              }
                            }
                          }
                        }
                      }
                    },
                    _1: "https://www.avo.app/schemas/%s/branches/%s/properties/group/%s"
                  }), schemaId, branchId, propertyGroupId);
  }
}

function stringReplaceByRe(str, re, replacement) {
  return str.replace(re, replacement);
}

function stripTrailingWhiteSpace(str) {
  return str.replace(/[ \t]+$/gm, "");
}

function escapeSingleQuotes(str) {
  return str.replace(/'/g, "\\'");
}

function escapeDoubleQuotes(str) {
  return str.replace(/"/g, "\\\"");
}

function commentWithSpace(comment) {
  if (comment === "") {
    return "";
  } else {
    return " " + comment;
  }
}

function printModuleMap(moduleName) {
  return Curry._1(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String_literal */11,
                    _0: "AVOMODULEMAP:",
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "AVOMODULEMAP:%s"
                }), JSON.stringify(moduleName));
}

function printEventMap(format, events) {
  return Curry._1(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String_literal */11,
                    _0: "AVOEVENTMAP:[",
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* ']' */93,
                        _1: /* End_of_format */0
                      }
                    }
                  },
                  _1: "AVOEVENTMAP:[%s]"
                }), Belt_List.toArray(Belt_List.map(events, (function ($$event) {
                          return Curry._1(Printf.sprintf(/* Format */{
                                          _0: {
                                            TAG: /* Char_literal */12,
                                            _0: /* '"' */34,
                                            _1: {
                                              TAG: /* String */2,
                                              _0: /* No_padding */0,
                                              _1: {
                                                TAG: /* Char_literal */12,
                                                _0: /* '"' */34,
                                                _1: /* End_of_format */0
                                              }
                                            }
                                          },
                                          _1: "\"%s\""
                                        }), Curry._1(format, eventCodegenName($$event)));
                        }))).join(","));
}

function classifyProperties(properties) {
  var eventProperties = Belt_List.keepU(properties, (function (prop) {
          return prop.sendAs === /* EventProperty */1;
        }));
  var userProperties = Belt_List.keepU(properties, (function (prop) {
          if (prop.sendAs === /* UserProperty */2) {
            return prop.operation === "Set";
          } else {
            return false;
          }
        }));
  var userPropertiesAlternateOps = Belt_List.keepU(properties, (function (prop) {
          if (prop.sendAs === /* UserProperty */2) {
            return prop.operation !== "Set";
          } else {
            return false;
          }
        }));
  var groupProperties = Belt_List.keepU(properties, (function (prop) {
          var match = prop.sendAs;
          if (typeof match === "number") {
            return false;
          } else {
            return true;
          }
        }));
  return [
          eventProperties,
          userProperties,
          userPropertiesAlternateOps,
          groupProperties
        ];
}

function filterOutGroupProperties(properties) {
  return Belt_List.keepU(properties, (function (prop) {
                var match = prop.sendAs;
                if (typeof match === "number") {
                  return true;
                } else {
                  return false;
                }
              }));
}

function classifyGroupPropertiesByGroupType(groupProperties) {
  return Belt_MapString.keepU(Belt_List.reduceU(groupProperties, undefined, (function (acc, groupProperty) {
                    var groupTypeId = groupProperty.sendAs;
                    if (typeof groupTypeId === "number") {
                      return acc;
                    } else {
                      return Belt_MapString.update(acc, groupTypeId._0, (function (maybeGroupProperties) {
                                    if (maybeGroupProperties !== undefined) {
                                      return Belt_Array.concat(maybeGroupProperties, [groupProperty]);
                                    } else {
                                      return [groupProperty];
                                    }
                                  }));
                    }
                  })), (function (_key, value) {
                return Caml_obj.caml_notequal(value, []);
              }));
}

function filterPinnedProperties(propertyRefs, globalProperties) {
  return Belt_List.keepU(propertyRefs, (function (propertyRef) {
                if (propertyRef.TAG !== /* PropertyRef */0) {
                  return true;
                }
                var match = propertyRef._0;
                var pinnedValue = match.pinnedValue;
                if (pinnedValue === undefined) {
                  return true;
                }
                var id = match.id;
                return Belt_Option.mapWithDefault(Belt_List.head(Belt_List.keepMapU(globalProperties, (function (property) {
                                      if (property.TAG === /* PropertyRef */0) {
                                        return ;
                                      }
                                      var property$1 = property._0;
                                      if (property$1.id === id) {
                                        return property$1;
                                      }
                                      
                                    }))), true, (function (property) {
                              return !PropertyValidationUtils.validatePinnedValue(pinnedValue, property);
                            }));
              }));
}

function getPinnedProperties(propertyRefs, globalProperties) {
  return Belt_List.partitionU(Belt_List.keepMapU(propertyRefs, (function (propertyRef) {
                    if (propertyRef.TAG !== /* PropertyRef */0) {
                      return ;
                    }
                    var propertyRef$1 = propertyRef._0;
                    var pinnedValue = propertyRef$1.pinnedValue;
                    if (pinnedValue === undefined) {
                      return ;
                    }
                    var id = propertyRef$1.id;
                    return Belt_Option.flatMap(Belt_List.head(Belt_List.keepMapU(globalProperties, (function (property) {
                                          if (property.TAG === /* PropertyRef */0) {
                                            return ;
                                          }
                                          var property$1 = property._0;
                                          if (property$1.id === id) {
                                            return property$1;
                                          }
                                          
                                        }))), (function (property) {
                                  if (PropertyValidationUtils.validatePinnedValue(pinnedValue, property)) {
                                    return [
                                            propertyRef$1,
                                            property
                                          ];
                                  }
                                  
                                }));
                  })), (function (param) {
                return param[1].sendAs !== /* UserProperty */2;
              }));
}

function printPinnedValueOption(pinnedValue) {
  if (pinnedValue === undefined) {
    return ;
  }
  var variant = pinnedValue.NAME;
  if (variant === "BooleanLit") {
    return Pervasives.string_of_bool(pinnedValue.VAL);
  } else if (variant === "StringLit") {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* Char_literal */12,
                      _0: /* '"' */34,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* Char_literal */12,
                          _0: /* '"' */34,
                          _1: /* End_of_format */0
                        }
                      }
                    },
                    _1: "\"%s\""
                  }), pinnedValue.VAL);
  } else if (variant === "FloatLit") {
    return pinnedValue.VAL.toString();
  } else {
    return String(pinnedValue.VAL);
  }
}

function printPinnedValueExn(pinnedValue) {
  var value = printPinnedValueOption(pinnedValue);
  if (value !== undefined) {
    return value;
  } else {
    return Pervasives.failwith("Invalid pinned value");
  }
}

function enrichWithRef(properties, $$event) {
  return Belt_List.keepMap(properties, (function (property) {
                return Belt_Option.flatMap(Belt_List.getBy($$event.properties, (function (x) {
                                  if (x.TAG === /* PropertyRef */0) {
                                    return property.id === x._0.id;
                                  } else {
                                    return false;
                                  }
                                })), (function (x) {
                              if (x.TAG === /* PropertyRef */0) {
                                return [
                                        x._0,
                                        property
                                      ];
                              }
                              
                            }));
              }));
}

function getSnowplowSchemaPropertyRef(propsWithRefs) {
  var schemaProperty = Belt_List.getByU(propsWithRefs, (function (param) {
          if (param[1].name === "schema") {
            return param[0].pinnedValue !== undefined;
          } else {
            return false;
          }
        }));
  if (schemaProperty !== undefined) {
    return schemaProperty[0];
  }
  
}

function unsafeGetSnowplowSchemaPropertyRef(eventName, propsWithRefs) {
  var propRef = getSnowplowSchemaPropertyRef(propsWithRefs);
  if (propRef !== undefined) {
    return propRef;
  } else {
    return Pervasives.failwith("Snowplow requires a pinned 'schema' property at the root of '" + (eventName + "'"));
  }
}

function printingFunctionToString(func) {
  var buf = $$Buffer.create(128);
  var testFormatter = Format.formatter_of_buffer(buf);
  Curry._1(func, testFormatter);
  return stripTrailingWhiteSpace(Printing.flush_str_formatter(buf, testFormatter));
}

function enrich(serverTrackingWithDeviceIdOpt, omitUserIdInSetUserPropertiesOnCustomDestinationOpt, skipImplementWithAvoFilteringOpt, model, source, destinations) {
  var serverTrackingWithDeviceId = serverTrackingWithDeviceIdOpt !== undefined ? serverTrackingWithDeviceIdOpt : false;
  var omitUserIdInSetUserPropertiesOnCustomDestination = omitUserIdInSetUserPropertiesOnCustomDestinationOpt !== undefined ? omitUserIdInSetUserPropertiesOnCustomDestinationOpt : false;
  var skipImplementWithAvoFiltering = skipImplementWithAvoFilteringOpt !== undefined ? skipImplementWithAvoFilteringOpt : false;
  var match = source.language;
  var match$1;
  if (match === "java") {
    var destinations$1 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$1 = AvoConfig.convertDestinationInterfacesToCustomInModel(model, source);
    var model$2 = AvoModel.filterExcludedEventsFromModel(model$1, source);
    var model$3 = AvoModel.filterUnusedPropertyBundles(model$2);
    var model$4 = AvoModel.enrichModel(model$3, {
          hd: source,
          tl: /* [] */0
        });
    var model$5 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$4, source);
    var model$6 = AvoModel.enrichPropertyBundlesForCodegen(model$5, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$6,
      destinations$1
    ];
  } else if (match === "objc") {
    var destinations$2 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$7 = AvoConfig.convertDestinationInterfacesToCustomInModel(model, source);
    var model$8 = AvoModel.filterExcludedEventsFromModel(model$7, source);
    var model$9 = AvoModel.filterUnusedPropertyBundles(model$8);
    var model$10 = AvoModel.enrichModel(model$9, {
          hd: source,
          tl: /* [] */0
        });
    var model$11 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$10, source);
    var model$12 = AvoModel.enrichPropertyBundlesForCodegen(model$11, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$12,
      destinations$2
    ];
  } else if (match === "js_v2") {
    var destinations$3 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$13 = AvoConfig.convertDestinationInterfacesToCustomInModel(model, source);
    var model$14 = AvoModel.filterExcludedEventsFromModel(model$13, source);
    var model$15 = AvoModel.filterUnusedPropertyBundles(model$14);
    var model$16 = AvoModel.enrichModel(model$15, {
          hd: source,
          tl: /* [] */0
        });
    var model$17 = AvoModel.enrichModelForCodegen(serverTrackingWithDeviceId, omitUserIdInSetUserPropertiesOnCustomDestination, skipImplementWithAvoFiltering, model$16, source);
    var model$18 = AvoModel.enrichPropertyBundlesForCodegen(model$17, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$18,
      destinations$3
    ];
  } else if (match === "re_v2") {
    var destinations$4 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$19 = AvoConfig.convertDestinationInterfacesToCustomInModel(model, source);
    var model$20 = AvoModel.filterExcludedEventsFromModel(model$19, source);
    var model$21 = AvoModel.filterUnusedPropertyBundles(model$20);
    var model$22 = AvoModel.enrichModel(model$21, {
          hd: source,
          tl: /* [] */0
        });
    var model$23 = AvoModel.enrichModelForCodegen(undefined, omitUserIdInSetUserPropertiesOnCustomDestination, skipImplementWithAvoFiltering, model$22, source);
    var model$24 = AvoModel.enrichPropertyBundlesForCodegen(model$23, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$24,
      destinations$4
    ];
  } else if (match === "cs") {
    var destinations$5 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$25 = AvoConfig.convertDestinationInterfacesToCustomInModel(model, source);
    var model$26 = AvoModel.filterExcludedEventsFromModel(model$25, source);
    var model$27 = AvoModel.filterUnusedPropertyBundles(model$26);
    var model$28 = AvoModel.enrichModel(model$27, {
          hd: source,
          tl: /* [] */0
        });
    var model$29 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$28, source);
    var model$30 = AvoModel.enrichPropertyBundlesForCodegen(model$29, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$30,
      destinations$5
    ];
  } else if (match === "js") {
    var destinations$6 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$31 = AvoConfig.convertDestinationInterfacesToCustomInModel(model, source);
    var model$32 = AvoModel.filterExcludedEventsFromModel(model$31, source);
    var model$33 = AvoModel.filterUnusedPropertyBundles(model$32);
    var model$34 = AvoModel.enrichModel(model$33, {
          hd: source,
          tl: /* [] */0
        });
    var model$35 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$34, source);
    var model$36 = AvoModel.enrichPropertyBundlesForCodegen(model$35, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$36,
      destinations$6
    ];
  } else if (match === "kt") {
    var destinations$7 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$37 = AvoConfig.convertDestinationInterfacesToCustomInModel(model, source);
    var model$38 = AvoModel.filterExcludedEventsFromModel(model$37, source);
    var model$39 = AvoModel.filterUnusedPropertyBundles(model$38);
    var model$40 = AvoModel.enrichModel(model$39, {
          hd: source,
          tl: /* [] */0
        });
    var model$41 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$40, source);
    var model$42 = AvoModel.enrichPropertyBundlesForCodegen(model$41, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$42,
      destinations$7
    ];
  } else if (match === "py3" || match === "py") {
    var destinations$8 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$43 = AvoConfig.convertDestinationInterfacesToCustomInModel(model, source);
    var model$44 = AvoModel.filterExcludedEventsFromModel(model$43, source);
    var model$45 = AvoModel.filterUnusedPropertyBundles(model$44);
    var model$46 = AvoModel.enrichModel(model$45, {
          hd: source,
          tl: /* [] */0
        });
    var model$47 = AvoModel.enrichModelForCodegen(serverTrackingWithDeviceId, undefined, skipImplementWithAvoFiltering, model$46, source);
    var model$48 = AvoModel.enrichPropertyBundlesForCodegen(model$47, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$48,
      destinations$8
    ];
  } else if (match === "rb") {
    var destinations$9 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$49 = AvoConfig.convertDestinationInterfacesToCustomInModel(model, source);
    var model$50 = AvoModel.filterExcludedEventsFromModel(model$49, source);
    var model$51 = AvoModel.filterUnusedPropertyBundles(model$50);
    var model$52 = AvoModel.enrichModel(model$51, {
          hd: source,
          tl: /* [] */0
        });
    var model$53 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$52, source);
    var model$54 = AvoModel.enrichPropertyBundlesForCodegen(model$53, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$54,
      destinations$9
    ];
  } else if (match === "re") {
    var destinations$10 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$55 = AvoConfig.convertDestinationInterfacesToCustomInModel(model, source);
    var model$56 = AvoModel.filterExcludedEventsFromModel(model$55, source);
    var model$57 = AvoModel.filterUnusedPropertyBundles(model$56);
    var model$58 = AvoModel.enrichModel(model$57, {
          hd: source,
          tl: /* [] */0
        });
    var model$59 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$58, source);
    var model$60 = AvoModel.enrichPropertyBundlesForCodegen(model$59, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$60,
      destinations$10
    ];
  } else if (match === "ts") {
    var destinations$11 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$61 = AvoConfig.convertDestinationInterfacesToCustomInModel(model, source);
    var model$62 = AvoModel.filterExcludedEventsFromModel(model$61, source);
    var model$63 = AvoModel.filterUnusedPropertyBundles(model$62);
    var model$64 = AvoModel.enrichModel(model$63, {
          hd: source,
          tl: /* [] */0
        });
    var model$65 = AvoModel.enrichModelForCodegen(serverTrackingWithDeviceId, undefined, skipImplementWithAvoFiltering, model$64, source);
    var model$66 = AvoModel.enrichPropertyBundlesForCodegen(model$65, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$66,
      destinations$11
    ];
  } else if (match === "php") {
    var destinations$12 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$67 = AvoConfig.convertDestinationInterfacesToCustomInModel(model, source);
    var model$68 = AvoModel.filterExcludedEventsFromModel(model$67, source);
    var model$69 = AvoModel.filterUnusedPropertyBundles(model$68);
    var model$70 = AvoModel.enrichModel(model$69, {
          hd: source,
          tl: /* [] */0
        });
    var model$71 = AvoModel.enrichModelForCodegen(serverTrackingWithDeviceId, undefined, skipImplementWithAvoFiltering, model$70, source);
    var model$72 = AvoModel.enrichPropertyBundlesForCodegen(model$71, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$72,
      destinations$12
    ];
  } else if (match === "swift") {
    var destinations$13 = AvoConfig.setDestinationModeForCodegen(source, destinations);
    var model$73 = AvoConfig.convertDestinationInterfacesToCustomInModel(model, source);
    var model$74 = AvoModel.filterExcludedEventsFromModel(model$73, source);
    var model$75 = AvoModel.filterUnusedPropertyBundles(model$74);
    var model$76 = AvoModel.enrichModel(model$75, {
          hd: source,
          tl: /* [] */0
        });
    var model$77 = AvoModel.enrichModelForCodegen(undefined, undefined, skipImplementWithAvoFiltering, model$76, source);
    var model$78 = AvoModel.enrichPropertyBundlesForCodegen(model$77, undefined, {
          hd: source.id,
          tl: /* [] */0
        });
    match$1 = [
      model$78,
      destinations$13
    ];
  } else {
    match$1 = [
      model,
      destinations
    ];
  }
  var destinations$14 = match$1[1];
  var model$79 = match$1[0];
  var model$80 = AvoConfig.areGroupPropertiesSupported(source, destinations$14) ? model$79 : ({
        types: model$79.types,
        properties: Belt_List.mapU(filterOutGroupProperties(AvoModel.resolveProperties(model$79, model$79.properties)), (function (prop) {
                return {
                        TAG: /* Property */1,
                        _0: prop
                      };
              })),
        propertyBundles: model$79.propertyBundles,
        events: Belt_List.mapU(model$79.events, (function ($$event) {
                return {
                        id: $$event.id,
                        name: $$event.name,
                        uniqueName: $$event.uniqueName,
                        description: $$event.description,
                        properties: $$event.properties,
                        propertyBundles: $$event.propertyBundles,
                        variants: $$event.variants,
                        types: $$event.types,
                        tags: $$event.tags,
                        excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                        includeSources: $$event.includeSources,
                        includeDestinations: $$event.includeDestinations,
                        hashes: $$event.hashes,
                        propertyWhitelist: $$event.propertyWhitelist,
                        eventGroupTypeIdsWithArchive: [],
                        userGroupTypeIdsWithArchive: [],
                        triggers: $$event.triggers
                      };
              })),
        migrations: model$79.migrations,
        sources: model$79.sources,
        destinations: model$79.destinations,
        groupTypes: model$79.groupTypes,
        goals: model$79.goals,
        metrics: model$79.metrics,
        archive: model$79.archive,
        openBranches: model$79.openBranches,
        branchPointer: model$79.branchPointer,
        rules: model$79.rules,
        integrations: model$79.integrations
      });
  return [
          model$80,
          destinations$14
        ];
}

function printPropertyGroupArguments(ppf, params, commentOpen, commentClose) {
  if (params) {
    return Belt_List.forEach(params, (function (param) {
                  var comments = param[1];
                  Curry._2(Format.fprintf(ppf, /* Format */{
                            _0: {
                              TAG: /* Formatting_lit */17,
                              _0: {
                                TAG: /* Break */0,
                                _0: "@,",
                                _1: 0,
                                _2: 0
                              },
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: {
                                  TAG: /* String_literal */11,
                                  _0: ", ",
                                  _1: {
                                    TAG: /* String */2,
                                    _0: /* No_padding */0,
                                    _1: /* End_of_format */0
                                  }
                                }
                              }
                            },
                            _1: "@,%s, %s"
                          }), param[0], commentOpen);
                  Belt_List.forEachWithIndex(comments, (function (index, comment) {
                          if (index < (Belt_List.length(comments) - 1 | 0)) {
                            return Curry._1(Format.fprintf(ppf, /* Format */{
                                            _0: {
                                              TAG: /* String */2,
                                              _0: /* No_padding */0,
                                              _1: {
                                                TAG: /* Formatting_lit */17,
                                                _0: {
                                                  TAG: /* Break */0,
                                                  _0: "@,",
                                                  _1: 0,
                                                  _2: 0
                                                },
                                                _1: /* End_of_format */0
                                              }
                                            },
                                            _1: "%s@,"
                                          }), comment);
                          } else {
                            return Curry._1(Format.fprintf(ppf, /* Format */{
                                            _0: {
                                              TAG: /* String */2,
                                              _0: /* No_padding */0,
                                              _1: /* End_of_format */0
                                            },
                                            _1: "%s"
                                          }), comment);
                          }
                        }));
                  return Curry._1(Format.fprintf(ppf, /* Format */{
                                  _0: {
                                    TAG: /* Char_literal */12,
                                    _0: /* ' ' */32,
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: /* End_of_format */0
                                    }
                                  },
                                  _1: " %s"
                                }), commentClose);
                }));
  }
  
}

function resolveFunctionArguments(model, inputProperties, $$event) {
  var inputPropertiesWithoutGroupProperties = filterOutGroupProperties(inputProperties);
  var match = classifyProperties(inputProperties);
  var groupPropertiesByGroupType = classifyGroupPropertiesByGroupType(match[3]);
  return Belt_Array.concatMany([
              Belt_Array.mapU(Belt_List.toArray(inputPropertiesWithoutGroupProperties), (function (prop) {
                      return {
                              TAG: /* Property */0,
                              _0: prop
                            };
                    })),
              Belt_Array.mapU(AvoModel.resolveGroupTypes($$event.userGroupTypeIdsWithArchive, model.groupTypes), (function (userGroupType) {
                      return {
                              TAG: /* AssociateUserGroup */2,
                              _0: userGroupType
                            };
                    })),
              Belt_Array.mapU(AvoModel.resolveGroupTypes($$event.eventGroupTypeIdsWithArchive, model.groupTypes), (function (eventGroupType) {
                      return {
                              TAG: /* AssociateEventGroup */3,
                              _0: eventGroupType
                            };
                    })),
              Belt_Array.concatMany(Belt_Array.mapU(Belt_MapString.toArray(groupPropertiesByGroupType), (function (param) {
                          var groupType = StateUtils.getGroupTypeById(param[0], model);
                          if (groupType !== undefined) {
                            return Belt_Array.concat([{
                                          TAG: /* UpdateGroup */4,
                                          _0: groupType
                                        }], Belt_Array.mapU(param[1], (function (property) {
                                              return {
                                                      TAG: /* GroupProperty */1,
                                                      _0: property,
                                                      _1: groupType
                                                    };
                                            })));
                          } else {
                            return [];
                          }
                        })))
            ]);
}

function printPropertyTypeInPseudocodeForCodegen(property, optionalOverwrite, model, $$event, propertyNameMaps, source, param) {
  var validations = property.validations;
  var validations$1 = validations ? Belt_List.toArray(Belt_List.map(validations, (function (x) {
                  if (typeof x !== "object") {
                    return "";
                  }
                  var variant = x.NAME;
                  if (variant === "Matches") {
                    var matches = x.VAL;
                    if (Belt_List.length(matches) <= 0) {
                      return "";
                    }
                    var matchesStr = Belt_List.toArray(Belt_List.map(matches, (function (param) {
                                  var exp = param[0];
                                  if (typeof exp !== "object") {
                                    return "";
                                  }
                                  if (exp.NAME !== "Literal") {
                                    return "";
                                  }
                                  var match = exp.VAL;
                                  if (typeof match === "object" && match.NAME === "StringLit") {
                                    return "\"" + (match.VAL + "\"");
                                  } else {
                                    return "";
                                  }
                                }))).join(", ");
                    return "restricted to : " + matchesStr;
                  }
                  if (variant === "Max") {
                    var match = x.VAL;
                    if (typeof match !== "object") {
                      return "";
                    }
                    var variant$1 = match.NAME;
                    if (variant$1 === "IntLit") {
                      return "max " + String(match.VAL);
                    } else if (variant$1 === "FloatLit") {
                      return "max " + String(match.VAL);
                    } else {
                      return "";
                    }
                  }
                  if (variant === "Min") {
                    var match$1 = x.VAL;
                    if (typeof match$1 !== "object") {
                      return "";
                    }
                    var variant$2 = match$1.NAME;
                    if (variant$2 === "IntLit") {
                      return "min " + String(match$1.VAL);
                    } else if (variant$2 === "FloatLit") {
                      return "min " + String(match$1.VAL);
                    } else {
                      return "";
                    }
                  }
                  if (variant === "NestedProperty") {
                    var nestedPropertyRefs = x.VAL;
                    if (nestedPropertyRefs) {
                      return "{\n" + (Belt_List.toArray(Belt_List.keepMap(nestedPropertyRefs, (function (nestedPropertyRef) {
                                            var maybeNestedProperty = StateUtils.resolvePropertyById(nestedPropertyRef.id, model);
                                            if (maybeNestedProperty === undefined) {
                                              return ;
                                            }
                                            var value = Belt_Option.getWithDefault(undefined, printPropertyTypeInPseudocodeForCodegen(maybeNestedProperty, maybeNestedProperty.optionalWhenInObject, model, $$event, propertyNameMaps, source, undefined));
                                            var propertyNameMap = Belt_MapString.getWithDefault(propertyNameMaps, maybeNestedProperty.id, /* NoMapping */0);
                                            if (typeof propertyNameMap === "number") {
                                              return "\"" + (maybeNestedProperty.name + ("\": " + (value + ",")));
                                            }
                                            if (propertyNameMap.TAG === /* AllDestinations */0) {
                                              return "\"" + (propertyNameMap._0 + ("\": " + (value + ",")));
                                            }
                                            var propertyNameMapString = nameMappingString(propertyNameMap._0);
                                            return "\"" + (maybeNestedProperty.name + ("\": " + (value + (", " + propertyNameMapString))));
                                          }))).join("\n") + "\n}");
                    } else {
                      return "";
                    }
                  }
                  if (variant !== "Shape") {
                    return "";
                  }
                  var items = x.VAL;
                  if (items) {
                    return "{" + (Belt_List.toArray(Belt_List.map(items, (function (item) {
                                          return item.key + PropertyUtils.printValue(item);
                                        }))).join(", ") + "}");
                  } else {
                    return "";
                  }
                }))).join(" ") : "";
  var optionality = optionalOverwrite !== undefined ? (
      optionalOverwrite ? "nullable" : ""
    ) : (
      StateUtils.isPropertyOptionalOnEventsAndSources({
            hd: $$event.id,
            tl: /* [] */0
          }, {
            hd: source.id,
            tl: /* [] */0
          }, property, model) ? "nullable" : ""
    );
  if (validations$1 === "") {
    return (
            optionality === "" ? "" : optionality + " "
          ) + ((
              property.list ? "list of " : ""
            ) + property.type_);
  } else {
    return (
            optionality === "" ? "" : optionality + " "
          ) + ((
              property.list ? "list of " : ""
            ) + ("(" + (validations$1 + ")")));
  }
}

function printPropertyGroupTypeInPseudocode(properties, propertyVarName, model, $$event, propertyNameMaps, source) {
  var begin = Printf.sprintf(/* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: " {",
          _1: /* End_of_format */0
        },
        _1: " {"
      });
  var params = Belt_List.keepMap(properties, (function (propertyId) {
          var property = StateUtils.resolvePropertyById(propertyId, model);
          return Belt_Option.map(property, (function (property) {
                        var typecomment = printPropertyTypeInPseudocodeForCodegen(property, undefined, model, $$event, propertyNameMaps, source, undefined);
                        return Curry._2(Printf.sprintf(/* Format */{
                                        _0: {
                                          TAG: /* String */2,
                                          _0: /* No_padding */0,
                                          _1: {
                                            TAG: /* String_literal */11,
                                            _0: ": ",
                                            _1: {
                                              TAG: /* String */2,
                                              _0: /* No_padding */0,
                                              _1: /* End_of_format */0
                                            }
                                          }
                                        },
                                        _1: "%s: %s"
                                      }), Curry._1(propertyVarName, property), typecomment);
                      }));
        }));
  var end = Printf.sprintf(/* Format */{
        _0: {
          TAG: /* Char_literal */12,
          _0: /* '}' */125,
          _1: /* End_of_format */0
        },
        _1: "}"
      });
  return Belt_List.concatMany([
              {
                hd: begin,
                tl: /* [] */0
              },
              params,
              {
                hd: end,
                tl: /* [] */0
              }
            ]);
}

function getPropertyNameMaps($$event, source, model) {
  var destinationsSendingEventFromSource = Belt_List.keep(StateUtils.getSourceDestinationIds(source.destinations), (function (destinationId) {
          return shouldSendEventToSourceDestination($$event, source, destinationId);
        }));
  return CodegenRules.getPropertyNameMaps(Belt_List.toArray(PropertyUtils.getAllPropertiesFromEvents((function (e) {
                        return e.id === $$event.id;
                      }), model)), $$event.id, destinationsSendingEventFromSource, model);
}

function snippetArguments(inputProperties, propertyVarName, $$event, model, groupTypeVarName, identifier, snippetFormat, commentFormat, source) {
  var functionArguments = Belt_List.fromArray(resolveFunctionArguments(model, inputProperties, $$event));
  var propertyNameMaps = getPropertyNameMaps($$event, source, model);
  return Belt_List.mapU(functionArguments, (function (argument) {
                switch (argument.TAG | 0) {
                  case /* Property */0 :
                  case /* GroupProperty */1 :
                      break;
                  case /* AssociateUserGroup */2 :
                      var userGroupType = argument._0;
                      return [
                              Curry._2(Printf.sprintf(snippetFormat), Curry._3(groupTypeVarName, "associateUserWith", userGroupType.id, model), Curry._1(identifier, StateUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(userGroupType.id, model) + "Id")),
                              Curry._1(Printf.sprintf(commentFormat), "string")
                            ];
                  case /* AssociateEventGroup */3 :
                      var eventGroupType = argument._0;
                      return [
                              Curry._2(Printf.sprintf(snippetFormat), Curry._3(groupTypeVarName, "associateEventWith", eventGroupType.id, model), Curry._1(identifier, StateUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(eventGroupType.id, model) + "Id")),
                              Curry._1(Printf.sprintf(commentFormat), "string")
                            ];
                  case /* UpdateGroup */4 :
                      var groupType = argument._0;
                      return [
                              Curry._2(Printf.sprintf(snippetFormat), Curry._3(groupTypeVarName, "updateGroupPropertiesFor", groupType.id, model), Curry._1(identifier, StateUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupType.id, model) + "Id")),
                              Curry._1(Printf.sprintf(commentFormat), "string")
                            ];
                  
                }
                var property = argument._0;
                var typeComment = printPropertyTypeInPseudocodeForCodegen(property, undefined, model, $$event, propertyNameMaps, source, undefined);
                return [
                        Curry._2(Printf.sprintf(snippetFormat), Curry._1(propertyVarName, property), Curry._1(propertyVarName, property)),
                        Curry._1(Printf.sprintf(commentFormat), typeComment)
                      ];
              }));
}

var reservedKeywords = {
  hd: "abstract",
  tl: {
    hd: "await",
    tl: {
      hd: "boolean",
      tl: {
        hd: "break",
        tl: {
          hd: "byte",
          tl: {
            hd: "case",
            tl: {
              hd: "catch",
              tl: {
                hd: "char",
                tl: {
                  hd: "class",
                  tl: {
                    hd: "const",
                    tl: {
                      hd: "continue",
                      tl: {
                        hd: "debugger",
                        tl: {
                          hd: "default",
                          tl: {
                            hd: "delete",
                            tl: {
                              hd: "do",
                              tl: {
                                hd: "double",
                                tl: {
                                  hd: "else",
                                  tl: {
                                    hd: "enum",
                                    tl: {
                                      hd: "export",
                                      tl: {
                                        hd: "extends",
                                        tl: {
                                          hd: "false",
                                          tl: {
                                            hd: "final",
                                            tl: {
                                              hd: "finally",
                                              tl: {
                                                hd: "float",
                                                tl: {
                                                  hd: "for",
                                                  tl: {
                                                    hd: "function",
                                                    tl: {
                                                      hd: "goto",
                                                      tl: {
                                                        hd: "if",
                                                        tl: {
                                                          hd: "implements",
                                                          tl: {
                                                            hd: "import",
                                                            tl: {
                                                              hd: "in",
                                                              tl: {
                                                                hd: "instanceof",
                                                                tl: {
                                                                  hd: "int",
                                                                  tl: {
                                                                    hd: "interface",
                                                                    tl: {
                                                                      hd: "let",
                                                                      tl: {
                                                                        hd: "long",
                                                                        tl: {
                                                                          hd: "native",
                                                                          tl: {
                                                                            hd: "new",
                                                                            tl: {
                                                                              hd: "null",
                                                                              tl: {
                                                                                hd: "package",
                                                                                tl: {
                                                                                  hd: "private",
                                                                                  tl: {
                                                                                    hd: "protected",
                                                                                    tl: {
                                                                                      hd: "public",
                                                                                      tl: {
                                                                                        hd: "return",
                                                                                        tl: {
                                                                                          hd: "short",
                                                                                          tl: {
                                                                                            hd: "static",
                                                                                            tl: {
                                                                                              hd: "super",
                                                                                              tl: {
                                                                                                hd: "switch",
                                                                                                tl: {
                                                                                                  hd: "synchronized",
                                                                                                  tl: {
                                                                                                    hd: "this",
                                                                                                    tl: {
                                                                                                      hd: "throw",
                                                                                                      tl: {
                                                                                                        hd: "throws",
                                                                                                        tl: {
                                                                                                          hd: "transient",
                                                                                                          tl: {
                                                                                                            hd: "true",
                                                                                                            tl: {
                                                                                                              hd: "try",
                                                                                                              tl: {
                                                                                                                hd: "typeof",
                                                                                                                tl: {
                                                                                                                  hd: "undefined",
                                                                                                                  tl: {
                                                                                                                    hd: "var",
                                                                                                                    tl: {
                                                                                                                      hd: "void",
                                                                                                                      tl: {
                                                                                                                        hd: "volatile",
                                                                                                                        tl: {
                                                                                                                          hd: "while",
                                                                                                                          tl: {
                                                                                                                            hd: "with",
                                                                                                                            tl: {
                                                                                                                              hd: "yield",
                                                                                                                              tl: /* [] */0
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var name$1 = Curry._1($$case, prefixWithWordIfFirstNumber(name));
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords) ? "_" : "");
}

function groupTypeVarName(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier((function (prim) {
                    return Case$1.pascal(prim);
                  }), StateUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier($$case, propertyCodegenName(property)));
  } else {
    return identifier($$case, propertyCodegenName(property));
  }
}

function generateSnippetFromEnrichedModel($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(StateUtils.getEventById($$event.id, model));
  var inputProperties = AvoModel.resolveProperties(model, $$event$1.properties);
  var partial_arg = Case.camel;
  var $$arguments = snippetArguments(inputProperties, (function (eta) {
          return propertyVarName(undefined, eta);
        }), $$event$1, model, groupTypeVarName, (function (param) {
          return identifier(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* String_literal */11,
            _0: ": ",
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s: %s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "/* ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: {
              TAG: /* String_literal */11,
              _0: " */",
              _1: /* End_of_format */0
            }
          }
        },
        _1: "/* %s */"
      }, source);
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEach(params, (function (param) {
                    return Curry._2(Format.fprintf(ppf, /* Format */{
                                    _0: {
                                      TAG: /* Formatting_lit */17,
                                      _0: {
                                        TAG: /* Break */0,
                                        _0: "@,",
                                        _1: 0,
                                        _2: 0
                                      },
                                      _1: {
                                        TAG: /* String */2,
                                        _0: /* No_padding */0,
                                        _1: {
                                          TAG: /* Char_literal */12,
                                          _0: /* ',' */44,
                                          _1: {
                                            TAG: /* String */2,
                                            _0: /* No_padding */0,
                                            _1: /* End_of_format */0
                                          }
                                        }
                                      }
                                    },
                                    _1: "@,%s,%s"
                                  }), param[0], commentWithSpace(param[1]));
                  }));
    }
    var match = params.hd;
    return Curry._2(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s%s"
                  }), match[0], commentWithSpace(match[1]));
  };
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v>",
                _1: /* End_of_format */0
              },
              _1: "<v>"
            }
          },
          _1: /* End_of_format */0
        },
        _1: "@[<v>"
      });
  if ($$arguments) {
    if ($$arguments.tl) {
      Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
                _0: {
                  TAG: /* Formatting_gen */18,
                  _0: {
                    TAG: /* Open_box */1,
                    _0: /* Format */{
                      _0: {
                        TAG: /* String_literal */11,
                        _0: "<v 2>",
                        _1: /* End_of_format */0
                      },
                      _1: "<v 2>"
                    }
                  },
                  _1: {
                    TAG: /* String_literal */11,
                    _0: "Avo.",
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: "({",
                        _1: {
                          TAG: /* Alpha */15,
                          _0: {
                            TAG: /* Formatting_lit */17,
                            _0: /* Close_box */0,
                            _1: {
                              TAG: /* Formatting_lit */17,
                              _0: {
                                TAG: /* Break */0,
                                _0: "@,",
                                _1: 0,
                                _2: 0
                              },
                              _1: {
                                TAG: /* String_literal */11,
                                _0: "});",
                                _1: /* End_of_format */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                },
                _1: "@[<v 2>Avo.%s({%a@]@,});"
              }), identifier(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
    } else {
      Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
                _0: {
                  TAG: /* String_literal */11,
                  _0: "Avo.",
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "({",
                      _1: {
                        TAG: /* Alpha */15,
                        _0: {
                          TAG: /* String_literal */11,
                          _0: "});",
                          _1: /* End_of_format */0
                        }
                      }
                    }
                  }
                },
                _1: "Avo.%s({%a});"
              }), identifier(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
    }
  } else {
    Curry._1(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "Avo.",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* String_literal */11,
                    _0: "();",
                    _1: /* End_of_format */0
                  }
                }
              },
              _1: "Avo.%s();"
            }), identifier(undefined, eventCodegenName($$event$1)));
  }
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_lit */17,
          _0: /* Close_box */0,
          _1: /* End_of_format */0
        },
        _1: "@]"
      });
  return Format.flush_str_formatter(undefined);
}

function generateSnippetGtmFromEnrichedModel($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(StateUtils.getEventById($$event.id, model));
  var inputProperties = filterPinnedProperties($$event$1.properties, model.properties);
  var inputProperties$1 = AvoModel.resolveProperties(model, inputProperties);
  var partial_arg = (function (prim) {
      return Case$1.title(prim);
    });
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName(undefined, eta);
        }), $$event$1, model, groupTypeVarName, (function (param) {
          return identifier(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* String_literal */11,
            _0: ": {{",
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: {
                TAG: /* String_literal */11,
                _0: "}}",
                _1: /* End_of_format */0
              }
            }
          }
        },
        _1: "%s: {{%s}}"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "/* ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: {
              TAG: /* String_literal */11,
              _0: " */",
              _1: /* End_of_format */0
            }
          }
        },
        _1: "/* %s */"
      }, source);
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEach(params, (function (param) {
                    return Curry._2(Format.fprintf(ppf, /* Format */{
                                    _0: {
                                      TAG: /* Formatting_lit */17,
                                      _0: {
                                        TAG: /* Break */0,
                                        _0: "@,",
                                        _1: 0,
                                        _2: 0
                                      },
                                      _1: {
                                        TAG: /* String */2,
                                        _0: /* No_padding */0,
                                        _1: {
                                          TAG: /* Char_literal */12,
                                          _0: /* ',' */44,
                                          _1: {
                                            TAG: /* String */2,
                                            _0: /* No_padding */0,
                                            _1: /* End_of_format */0
                                          }
                                        }
                                      }
                                    },
                                    _1: "@,%s,%s"
                                  }), param[0], commentWithSpace(param[1]));
                  }));
    }
    var match = params.hd;
    return Curry._2(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s%s"
                  }), match[0], commentWithSpace(match[1]));
  };
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v>",
                _1: /* End_of_format */0
              },
              _1: "<v>"
            }
          },
          _1: /* End_of_format */0
        },
        _1: "@[<v>"
      });
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v 2>",
                _1: /* End_of_format */0
              },
              _1: "<v 2>"
            }
          },
          _1: {
            TAG: /* String_literal */11,
            _0: "<script>",
            _1: /* End_of_format */0
          }
        },
        _1: "@[<v 2><script>"
      });
  if ($$arguments) {
    if ($$arguments.tl) {
      Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
                _0: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* Formatting_gen */18,
                    _0: {
                      TAG: /* Open_box */1,
                      _0: /* Format */{
                        _0: {
                          TAG: /* String_literal */11,
                          _0: "<v 2>",
                          _1: /* End_of_format */0
                        },
                        _1: "<v 2>"
                      }
                    },
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "Avo.",
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* String_literal */11,
                          _0: "({",
                          _1: {
                            TAG: /* Alpha */15,
                            _0: {
                              TAG: /* Formatting_lit */17,
                              _0: /* Close_box */0,
                              _1: {
                                TAG: /* Formatting_lit */17,
                                _0: {
                                  TAG: /* Break */0,
                                  _0: "@,",
                                  _1: 0,
                                  _2: 0
                                },
                                _1: {
                                  TAG: /* String_literal */11,
                                  _0: "});",
                                  _1: /* End_of_format */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                },
                _1: "@,@[<v 2>Avo.%s({%a@]@,});"
              }), identifier(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
    } else {
      Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
                _0: {
                  TAG: /* Formatting_lit */17,
                  _0: {
                    TAG: /* Break */0,
                    _0: "@,",
                    _1: 0,
                    _2: 0
                  },
                  _1: {
                    TAG: /* String_literal */11,
                    _0: "Avo.",
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: "({",
                        _1: {
                          TAG: /* Alpha */15,
                          _0: {
                            TAG: /* String_literal */11,
                            _0: "});",
                            _1: /* End_of_format */0
                          }
                        }
                      }
                    }
                  }
                },
                _1: "@,Avo.%s({%a});"
              }), identifier(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
    }
  } else {
    Curry._1(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* String_literal */11,
                  _0: "Avo.",
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "();",
                      _1: /* End_of_format */0
                    }
                  }
                }
              },
              _1: "@,Avo.%s();"
            }), identifier(undefined, eventCodegenName($$event$1)));
  }
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_lit */17,
          _0: /* Close_box */0,
          _1: {
            TAG: /* Formatting_lit */17,
            _0: {
              TAG: /* Break */0,
              _0: "@,",
              _1: 0,
              _2: 0
            },
            _1: {
              TAG: /* String_literal */11,
              _0: "</script>",
              _1: /* End_of_format */0
            }
          }
        },
        _1: "@]@,</script>"
      });
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_lit */17,
          _0: /* Close_box */0,
          _1: /* End_of_format */0
        },
        _1: "@]"
      });
  return Format.flush_str_formatter(undefined);
}

var JavaScript = {
  reservedKeywords: reservedKeywords,
  identifier: identifier,
  groupTypeVarName: groupTypeVarName,
  propertyVarName: propertyVarName,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel,
  generateSnippetGtmFromEnrichedModel: generateSnippetGtmFromEnrichedModel
};

var reservedKeywords$1 = {
  hd: "and",
  tl: {
    hd: "as",
    tl: {
      hd: "assert",
      tl: {
        hd: "begin",
        tl: {
          hd: "class",
          tl: {
            hd: "constraint",
            tl: {
              hd: "do",
              tl: {
                hd: "done",
                tl: {
                  hd: "downto",
                  tl: {
                    hd: "else",
                    tl: {
                      hd: "end",
                      tl: {
                        hd: "exception",
                        tl: {
                          hd: "external",
                          tl: {
                            hd: "false",
                            tl: {
                              hd: "for",
                              tl: {
                                hd: "fun",
                                tl: {
                                  hd: "function",
                                  tl: {
                                    hd: "functor",
                                    tl: {
                                      hd: "if",
                                      tl: {
                                        hd: "in",
                                        tl: {
                                          hd: "include",
                                          tl: {
                                            hd: "inherit",
                                            tl: {
                                              hd: "initializer",
                                              tl: {
                                                hd: "lazy",
                                                tl: {
                                                  hd: "let",
                                                  tl: {
                                                    hd: "match",
                                                    tl: {
                                                      hd: "method",
                                                      tl: {
                                                        hd: "module",
                                                        tl: {
                                                          hd: "mutable",
                                                          tl: {
                                                            hd: "new",
                                                            tl: {
                                                              hd: "object",
                                                              tl: {
                                                                hd: "of",
                                                                tl: {
                                                                  hd: "open",
                                                                  tl: {
                                                                    hd: "or",
                                                                    tl: {
                                                                      hd: "private",
                                                                      tl: {
                                                                        hd: "pub",
                                                                        tl: {
                                                                          hd: "rec",
                                                                          tl: {
                                                                            hd: "sig",
                                                                            tl: {
                                                                              hd: "struct",
                                                                              tl: {
                                                                                hd: "switch",
                                                                                tl: {
                                                                                  hd: "then",
                                                                                  tl: {
                                                                                    hd: "to",
                                                                                    tl: {
                                                                                      hd: "true",
                                                                                      tl: {
                                                                                        hd: "try",
                                                                                        tl: {
                                                                                          hd: "type",
                                                                                          tl: {
                                                                                            hd: "val",
                                                                                            tl: {
                                                                                              hd: "virtual",
                                                                                              tl: {
                                                                                                hd: "when",
                                                                                                tl: {
                                                                                                  hd: "while",
                                                                                                  tl: {
                                                                                                    hd: "with",
                                                                                                    tl: /* [] */0
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$1(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var name$1 = Curry._1($$case, name);
  var name$2 = name$1.replace(/[^\w\s\-]/ig, "");
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$2, List.exists((function (param) {
                    return name$2 === param;
                  }), reservedKeywords$1) ? "_" : "");
}

function groupTypeVarName$1(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$1((function (prim) {
                    return Case$1.pascal(prim);
                  }), StateUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$1($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$1($$case, propertyCodegenName(property)));
  } else {
    return identifier$1($$case, propertyCodegenName(property));
  }
}

function namedPropertyVarName($$case, property) {
  return Curry._1(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* Char_literal */12,
                    _0: /* '~' */126,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "~%s"
                }), propertyVarName$1($$case, property));
}

function generateSnippetFromEnrichedModel$1($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(StateUtils.getEventById($$event.id, model));
  var inputGroups = AvoModel.resolvePropertyBundleRefs(model.propertyBundles, $$event$1.propertyBundles);
  var inputProperties = filterPinnedProperties($$event$1.properties, model.properties);
  var inputProperties$1 = Belt_List.keep(AvoModel.resolveProperties(model, inputProperties), (function (property) {
          return !Belt_List.some(inputGroups, (function (group) {
                        return Belt_List.some(group.properties, (function (propertyId) {
                                      return property.id === propertyId;
                                    }));
                      }));
        }));
  var propertyNameMaps = getPropertyNameMaps($$event$1, source, model);
  var propertyGroupsArgs = Belt_List.map(inputGroups, (function (group) {
          var comment = printPropertyGroupTypeInPseudocode(group.properties, (function (eta) {
                  return propertyVarName$1(undefined, eta);
                }), model, $$event$1, propertyNameMaps, source);
          return [
                  Curry._2(Printf.sprintf(/* Format */{
                            _0: {
                              TAG: /* Char_literal */12,
                              _0: /* '~' */126,
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: {
                                  TAG: /* Char_literal */12,
                                  _0: /* '=' */61,
                                  _1: {
                                    TAG: /* String */2,
                                    _0: /* No_padding */0,
                                    _1: /* End_of_format */0
                                  }
                                }
                              }
                            },
                            _1: "~%s=%s"
                          }), identifier$1(undefined, group.name), identifier$1(undefined, group.name)),
                  comment
                ];
        }));
  var partial_arg = Case.camel;
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$1(undefined, eta);
        }), $$event$1, model, groupTypeVarName$1, (function (param) {
          return identifier$1(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* Char_literal */12,
          _0: /* '~' */126,
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: {
              TAG: /* Char_literal */12,
              _0: /* '=' */61,
              _1: {
                TAG: /* String */2,
                _0: /* No_padding */0,
                _1: /* End_of_format */0
              }
            }
          }
        },
        _1: "~%s=%s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "/* ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: {
              TAG: /* String_literal */11,
              _0: " */",
              _1: /* End_of_format */0
            }
          }
        },
        _1: "/* %s */"
      }, source);
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEach(params, (function (param) {
                    return Curry._2(Format.fprintf(ppf, /* Format */{
                                    _0: {
                                      TAG: /* Formatting_lit */17,
                                      _0: {
                                        TAG: /* Break */0,
                                        _0: "@,",
                                        _1: 0,
                                        _2: 0
                                      },
                                      _1: {
                                        TAG: /* String */2,
                                        _0: /* No_padding */0,
                                        _1: {
                                          TAG: /* Char_literal */12,
                                          _0: /* ',' */44,
                                          _1: {
                                            TAG: /* String */2,
                                            _0: /* No_padding */0,
                                            _1: /* End_of_format */0
                                          }
                                        }
                                      }
                                    },
                                    _1: "@,%s,%s"
                                  }), param[0], commentWithSpace(param[1]));
                  }));
    }
    var match = params.hd;
    return Curry._2(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s%s"
                  }), match[0], commentWithSpace(match[1]));
  };
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v>",
                _1: /* End_of_format */0
              },
              _1: "<v>"
            }
          },
          _1: /* End_of_format */0
        },
        _1: "@[<v>"
      });
  var argsLength = Belt_List.length($$arguments) + Belt_List.length(propertyGroupsArgs) | 0;
  if (argsLength > 1) {
    Curry._5(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* Formatting_gen */18,
                _0: {
                  TAG: /* Open_box */1,
                  _0: /* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "<v 2>",
                      _1: /* End_of_format */0
                    },
                    _1: "<v 2>"
                  }
                },
                _1: {
                  TAG: /* String_literal */11,
                  _0: "let () = Avo.",
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* Char_literal */12,
                      _0: /* '(' */40,
                      _1: {
                        TAG: /* Alpha */15,
                        _0: {
                          TAG: /* Alpha */15,
                          _0: {
                            TAG: /* Formatting_lit */17,
                            _0: /* Close_box */0,
                            _1: {
                              TAG: /* Formatting_lit */17,
                              _0: {
                                TAG: /* Break */0,
                                _0: "@,",
                                _1: 0,
                                _2: 0
                              },
                              _1: {
                                TAG: /* String_literal */11,
                                _0: ");",
                                _1: /* End_of_format */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              },
              _1: "@[<v 2>let () = Avo.%s(%a%a@]@,);"
            }), identifier$1(undefined, eventCodegenName($$event$1)), (function (param) {
            return function (param$1) {
              var param$2 = "/*";
              var param$3 = "*/";
              return printPropertyGroupArguments(param, param$1, param$2, param$3);
            };
          }), propertyGroupsArgs, printArguments, $$arguments);
  } else {
    Curry._5(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "let () = Avo.",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* Char_literal */12,
                    _0: /* '(' */40,
                    _1: {
                      TAG: /* Alpha */15,
                      _0: {
                        TAG: /* Alpha */15,
                        _0: {
                          TAG: /* String_literal */11,
                          _0: ");",
                          _1: /* End_of_format */0
                        }
                      }
                    }
                  }
                }
              },
              _1: "let () = Avo.%s(%a%a);"
            }), identifier$1(undefined, eventCodegenName($$event$1)), (function (param) {
            return function (param$1) {
              var param$2 = "/*";
              var param$3 = "*/";
              return printPropertyGroupArguments(param, param$1, param$2, param$3);
            };
          }), propertyGroupsArgs, printArguments, $$arguments);
  }
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_lit */17,
          _0: /* Close_box */0,
          _1: /* End_of_format */0
        },
        _1: "@]"
      });
  return Format.flush_str_formatter(undefined);
}

var Reason = {
  reservedKeywords: reservedKeywords$1,
  identifier: identifier$1,
  groupTypeVarName: groupTypeVarName$1,
  propertyVarName: propertyVarName$1,
  namedPropertyVarName: namedPropertyVarName,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$1
};

var reservedKeywords$2 = {
  hd: "abstract",
  tl: {
    hd: "await",
    tl: {
      hd: "boolean",
      tl: {
        hd: "break",
        tl: {
          hd: "byte",
          tl: {
            hd: "case",
            tl: {
              hd: "catch",
              tl: {
                hd: "char",
                tl: {
                  hd: "class",
                  tl: {
                    hd: "const",
                    tl: {
                      hd: "continue",
                      tl: {
                        hd: "debugger",
                        tl: {
                          hd: "default",
                          tl: {
                            hd: "delete",
                            tl: {
                              hd: "do",
                              tl: {
                                hd: "double",
                                tl: {
                                  hd: "else",
                                  tl: {
                                    hd: "enum",
                                    tl: {
                                      hd: "export",
                                      tl: {
                                        hd: "extends",
                                        tl: {
                                          hd: "false",
                                          tl: {
                                            hd: "final",
                                            tl: {
                                              hd: "finally",
                                              tl: {
                                                hd: "float",
                                                tl: {
                                                  hd: "for",
                                                  tl: {
                                                    hd: "function",
                                                    tl: {
                                                      hd: "goto",
                                                      tl: {
                                                        hd: "if",
                                                        tl: {
                                                          hd: "implements",
                                                          tl: {
                                                            hd: "import",
                                                            tl: {
                                                              hd: "in",
                                                              tl: {
                                                                hd: "instanceof",
                                                                tl: {
                                                                  hd: "int",
                                                                  tl: {
                                                                    hd: "interface",
                                                                    tl: {
                                                                      hd: "let",
                                                                      tl: {
                                                                        hd: "long",
                                                                        tl: {
                                                                          hd: "native",
                                                                          tl: {
                                                                            hd: "new",
                                                                            tl: {
                                                                              hd: "null",
                                                                              tl: {
                                                                                hd: "package",
                                                                                tl: {
                                                                                  hd: "private",
                                                                                  tl: {
                                                                                    hd: "protected",
                                                                                    tl: {
                                                                                      hd: "public",
                                                                                      tl: {
                                                                                        hd: "return",
                                                                                        tl: {
                                                                                          hd: "short",
                                                                                          tl: {
                                                                                            hd: "static",
                                                                                            tl: {
                                                                                              hd: "super",
                                                                                              tl: {
                                                                                                hd: "switch",
                                                                                                tl: {
                                                                                                  hd: "synchronized",
                                                                                                  tl: {
                                                                                                    hd: "this",
                                                                                                    tl: {
                                                                                                      hd: "throw",
                                                                                                      tl: {
                                                                                                        hd: "throws",
                                                                                                        tl: {
                                                                                                          hd: "transient",
                                                                                                          tl: {
                                                                                                            hd: "true",
                                                                                                            tl: {
                                                                                                              hd: "try",
                                                                                                              tl: {
                                                                                                                hd: "typeof",
                                                                                                                tl: {
                                                                                                                  hd: "undefined",
                                                                                                                  tl: {
                                                                                                                    hd: "var",
                                                                                                                    tl: {
                                                                                                                      hd: "void",
                                                                                                                      tl: {
                                                                                                                        hd: "volatile",
                                                                                                                        tl: {
                                                                                                                          hd: "while",
                                                                                                                          tl: {
                                                                                                                            hd: "with",
                                                                                                                            tl: {
                                                                                                                              hd: "yield",
                                                                                                                              tl: /* [] */0
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$2(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var name$1 = Curry._1($$case, prefixWithWordIfFirstNumber(name));
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$2) ? "_" : "");
}

function groupTypeVarName$2(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$2((function (prim) {
                    return Case$1.pascal(prim);
                  }), StateUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$2($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$2($$case, propertyCodegenName(property)));
  } else {
    return identifier$2($$case, propertyCodegenName(property));
  }
}

function generateSnippetFromEnrichedModel$2($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(StateUtils.getEventById($$event.id, model));
  var inputProperties = filterPinnedProperties($$event$1.properties, model.properties);
  var inputProperties$1 = AvoModel.resolveProperties(model, inputProperties);
  var partial_arg = Case.camel;
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$2(undefined, eta);
        }), $$event$1, model, groupTypeVarName$2, (function (param) {
          return identifier$2(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* String_literal */11,
            _0: ": ",
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s: %s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "/* ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: {
              TAG: /* String_literal */11,
              _0: " */",
              _1: /* End_of_format */0
            }
          }
        },
        _1: "/* %s */"
      }, source);
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEach(params, (function (param) {
                    return Curry._2(Format.fprintf(ppf, /* Format */{
                                    _0: {
                                      TAG: /* Formatting_lit */17,
                                      _0: {
                                        TAG: /* Break */0,
                                        _0: "@,",
                                        _1: 0,
                                        _2: 0
                                      },
                                      _1: {
                                        TAG: /* String */2,
                                        _0: /* No_padding */0,
                                        _1: {
                                          TAG: /* Char_literal */12,
                                          _0: /* ',' */44,
                                          _1: {
                                            TAG: /* String */2,
                                            _0: /* No_padding */0,
                                            _1: /* End_of_format */0
                                          }
                                        }
                                      }
                                    },
                                    _1: "@,%s,%s"
                                  }), param[0], commentWithSpace(param[1]));
                  }));
    }
    var match = params.hd;
    return Curry._2(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s%s"
                  }), match[0], commentWithSpace(match[1]));
  };
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v>",
                _1: /* End_of_format */0
              },
              _1: "<v>"
            }
          },
          _1: /* End_of_format */0
        },
        _1: "@[<v>"
      });
  if ($$arguments) {
    if ($$arguments.tl) {
      Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
                _0: {
                  TAG: /* Formatting_gen */18,
                  _0: {
                    TAG: /* Open_box */1,
                    _0: /* Format */{
                      _0: {
                        TAG: /* String_literal */11,
                        _0: "<v 2>",
                        _1: /* End_of_format */0
                      },
                      _1: "<v 2>"
                    }
                  },
                  _1: {
                    TAG: /* String_literal */11,
                    _0: "Avo.",
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: "({",
                        _1: {
                          TAG: /* Alpha */15,
                          _0: {
                            TAG: /* Formatting_lit */17,
                            _0: /* Close_box */0,
                            _1: {
                              TAG: /* Formatting_lit */17,
                              _0: {
                                TAG: /* Break */0,
                                _0: "@,",
                                _1: 0,
                                _2: 0
                              },
                              _1: {
                                TAG: /* String_literal */11,
                                _0: "});",
                                _1: /* End_of_format */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                },
                _1: "@[<v 2>Avo.%s({%a@]@,});"
              }), identifier$2(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
    } else {
      Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
                _0: {
                  TAG: /* String_literal */11,
                  _0: "Avo.",
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "({",
                      _1: {
                        TAG: /* Alpha */15,
                        _0: {
                          TAG: /* String_literal */11,
                          _0: "});",
                          _1: /* End_of_format */0
                        }
                      }
                    }
                  }
                },
                _1: "Avo.%s({%a});"
              }), identifier$2(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
    }
  } else {
    Curry._1(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "Avo.",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* String_literal */11,
                    _0: "();",
                    _1: /* End_of_format */0
                  }
                }
              },
              _1: "Avo.%s();"
            }), identifier$2(undefined, eventCodegenName($$event$1)));
  }
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_lit */17,
          _0: /* Close_box */0,
          _1: /* End_of_format */0
        },
        _1: "@]"
      });
  return Format.flush_str_formatter(undefined);
}

var TypeScript = {
  reservedKeywords: reservedKeywords$2,
  identifier: identifier$2,
  groupTypeVarName: groupTypeVarName$2,
  propertyVarName: propertyVarName$2,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$2
};

var reservedKeywords$3 = {
  hd: "__ENCODING__",
  tl: {
    hd: "__LINE__",
    tl: {
      hd: "__FILE__",
      tl: {
        hd: "BEGIN",
        tl: {
          hd: "END",
          tl: {
            hd: "alias",
            tl: {
              hd: "and",
              tl: {
                hd: "begin",
                tl: {
                  hd: "break",
                  tl: {
                    hd: "case",
                    tl: {
                      hd: "class",
                      tl: {
                        hd: "def",
                        tl: {
                          hd: "defined?",
                          tl: {
                            hd: "do",
                            tl: {
                              hd: "else",
                              tl: {
                                hd: "elsif",
                                tl: {
                                  hd: "end",
                                  tl: {
                                    hd: "ensure",
                                    tl: {
                                      hd: "false",
                                      tl: {
                                        hd: "for",
                                        tl: {
                                          hd: "if",
                                          tl: {
                                            hd: "in",
                                            tl: {
                                              hd: "module",
                                              tl: {
                                                hd: "next",
                                                tl: {
                                                  hd: "nil",
                                                  tl: {
                                                    hd: "not",
                                                    tl: {
                                                      hd: "or",
                                                      tl: {
                                                        hd: "redo",
                                                        tl: {
                                                          hd: "rescue",
                                                          tl: {
                                                            hd: "retry",
                                                            tl: {
                                                              hd: "return",
                                                              tl: {
                                                                hd: "self",
                                                                tl: {
                                                                  hd: "super",
                                                                  tl: {
                                                                    hd: "then",
                                                                    tl: {
                                                                      hd: "true",
                                                                      tl: {
                                                                        hd: "undef",
                                                                        tl: {
                                                                          hd: "unless",
                                                                          tl: {
                                                                            hd: "until",
                                                                            tl: {
                                                                              hd: "when",
                                                                              tl: {
                                                                                hd: "while",
                                                                                tl: {
                                                                                  hd: "yield",
                                                                                  tl: /* [] */0
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$3(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : (function (prim) {
        return Case$1.snake(prim);
      });
  var name$1 = Curry._1($$case, name);
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$3) ? "_" : "");
}

function groupTypeVarName$3(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$3((function (prim) {
                    return Case$1.snake(prim);
                  }), StateUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$3($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    },
                    _1: "%s"
                  }), identifier$3($$case, propertyCodegenName(property)));
  } else {
    return identifier$3($$case, propertyCodegenName(property));
  }
}

function generateSnippetFromEnrichedModel$3($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(StateUtils.getEventById($$event.id, model));
  var inputProperties = filterPinnedProperties($$event$1.properties, model.properties);
  var inputProperties$1 = AvoModel.resolveProperties(model, inputProperties);
  var partial_arg = (function (prim) {
      return Case$1.snake(prim);
    });
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$3(undefined, eta);
        }), $$event$1, model, groupTypeVarName$3, (function (param) {
          return identifier$3(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* String_literal */11,
            _0: ": ",
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s: %s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "=begin ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: {
              TAG: /* String_literal */11,
              _0: " =end",
              _1: /* End_of_format */0
            }
          }
        },
        _1: "=begin %s =end"
      }, source);
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEachWithIndex(params, (function (index, param) {
                    return Curry._3(Format.fprintf(ppf, /* Format */{
                                    _0: {
                                      TAG: /* Formatting_lit */17,
                                      _0: {
                                        TAG: /* Break */0,
                                        _0: "@,",
                                        _1: 0,
                                        _2: 0
                                      },
                                      _1: {
                                        TAG: /* String */2,
                                        _0: /* No_padding */0,
                                        _1: {
                                          TAG: /* String */2,
                                          _0: /* No_padding */0,
                                          _1: {
                                            TAG: /* String */2,
                                            _0: /* No_padding */0,
                                            _1: /* End_of_format */0
                                          }
                                        }
                                      }
                                    },
                                    _1: "@,%s%s%s"
                                  }), param[0], index === (Belt_List.length(params) - 1 | 0) ? "" : ",", commentWithSpace(param[1]));
                  }));
    }
    var match = params.hd;
    return Curry._2(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s%s"
                  }), match[0], commentWithSpace(match[1]));
  };
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v>",
                _1: /* End_of_format */0
              },
              _1: "<v>"
            }
          },
          _1: /* End_of_format */0
        },
        _1: "@[<v>"
      });
  if (Belt_List.length($$arguments) > 1) {
    Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* Formatting_gen */18,
                _0: {
                  TAG: /* Open_box */1,
                  _0: /* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "<v 2>",
                      _1: /* End_of_format */0
                    },
                    _1: "<v 2>"
                  }
                },
                _1: {
                  TAG: /* String_literal */11,
                  _0: "Avo.",
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* Char_literal */12,
                      _0: /* '(' */40,
                      _1: {
                        TAG: /* Alpha */15,
                        _0: {
                          TAG: /* Formatting_lit */17,
                          _0: /* Close_box */0,
                          _1: {
                            TAG: /* Formatting_lit */17,
                            _0: {
                              TAG: /* Break */0,
                              _0: "@,",
                              _1: 0,
                              _2: 0
                            },
                            _1: {
                              TAG: /* Char_literal */12,
                              _0: /* ')' */41,
                              _1: /* End_of_format */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              },
              _1: "@[<v 2>Avo.%s(%a@]@,)"
            }), identifier$3(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
  } else {
    Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "Avo.",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* Char_literal */12,
                    _0: /* '(' */40,
                    _1: {
                      TAG: /* Alpha */15,
                      _0: {
                        TAG: /* Char_literal */12,
                        _0: /* ')' */41,
                        _1: /* End_of_format */0
                      }
                    }
                  }
                }
              },
              _1: "Avo.%s(%a)"
            }), identifier$3(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
  }
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_lit */17,
          _0: /* Close_box */0,
          _1: /* End_of_format */0
        },
        _1: "@]"
      });
  return Format.flush_str_formatter(undefined);
}

var Ruby = {
  reservedKeywords: reservedKeywords$3,
  identifier: identifier$3,
  groupTypeVarName: groupTypeVarName$3,
  propertyVarName: propertyVarName$3,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$3
};

var reservedKeywords$4 = {
  hd: "and",
  tl: {
    hd: "del",
    tl: {
      hd: "from",
      tl: {
        hd: "not",
        tl: {
          hd: "while",
          tl: {
            hd: "as",
            tl: {
              hd: "elif",
              tl: {
                hd: "global",
                tl: {
                  hd: "or",
                  tl: {
                    hd: "with",
                    tl: {
                      hd: "assert",
                      tl: {
                        hd: "else",
                        tl: {
                          hd: "if",
                          tl: {
                            hd: "pass",
                            tl: {
                              hd: "yield",
                              tl: {
                                hd: "break",
                                tl: {
                                  hd: "except",
                                  tl: {
                                    hd: "import",
                                    tl: {
                                      hd: "print",
                                      tl: {
                                        hd: "class",
                                        tl: {
                                          hd: "exec",
                                          tl: {
                                            hd: "in",
                                            tl: {
                                              hd: "raise",
                                              tl: {
                                                hd: "continue",
                                                tl: {
                                                  hd: "finally",
                                                  tl: {
                                                    hd: "is",
                                                    tl: {
                                                      hd: "return",
                                                      tl: {
                                                        hd: "def",
                                                        tl: {
                                                          hd: "for",
                                                          tl: {
                                                            hd: "lambda",
                                                            tl: {
                                                              hd: "try",
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$4(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : (function (prim) {
        return Case$1.snake(prim);
      });
  var name$1 = Curry._1($$case, name);
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$4) ? "_" : "");
}

function groupTypeVarName$4(prefixOpt, $$case, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$4($$case, StateUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$4($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$4($$case, propertyCodegenName(property)));
  } else {
    return identifier$4($$case, propertyCodegenName(property));
  }
}

function generateSnippetFromEnrichedModel$4($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(StateUtils.getEventById($$event.id, model));
  var inputProperties = filterPinnedProperties($$event$1.properties, model.properties);
  var inputProperties$1 = AvoModel.resolveProperties(model, inputProperties);
  var partial_arg = (function (prim) {
      return Case$1.snake(prim);
    });
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$4(undefined, eta);
        }), $$event$1, model, groupTypeVarName$4, (function (param) {
          return identifier$4(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* Char_literal */12,
            _0: /* '=' */61,
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s=%s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "# ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: /* End_of_format */0
          }
        },
        _1: "# %s"
      }, source);
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEachWithIndex(params, (function (index, param) {
                    return Curry._3(Format.fprintf(ppf, /* Format */{
                                    _0: {
                                      TAG: /* Formatting_lit */17,
                                      _0: {
                                        TAG: /* Break */0,
                                        _0: "@,",
                                        _1: 0,
                                        _2: 0
                                      },
                                      _1: {
                                        TAG: /* String */2,
                                        _0: /* No_padding */0,
                                        _1: {
                                          TAG: /* String */2,
                                          _0: /* No_padding */0,
                                          _1: {
                                            TAG: /* String */2,
                                            _0: /* No_padding */0,
                                            _1: /* End_of_format */0
                                          }
                                        }
                                      }
                                    },
                                    _1: "@,%s%s%s"
                                  }), param[0], index === (Belt_List.length(params) - 1 | 0) ? "" : ",", commentWithSpace(param[1]));
                  }));
    }
    var match = params.hd;
    return Curry._2(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s%s"
                  }), match[0], commentWithSpace(match[1]));
  };
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v>",
                _1: /* End_of_format */0
              },
              _1: "<v>"
            }
          },
          _1: /* End_of_format */0
        },
        _1: "@[<v>"
      });
  if (Belt_List.length($$arguments) > 1) {
    Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* Formatting_gen */18,
                _0: {
                  TAG: /* Open_box */1,
                  _0: /* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "<v 2>",
                      _1: /* End_of_format */0
                    },
                    _1: "<v 2>"
                  }
                },
                _1: {
                  TAG: /* String_literal */11,
                  _0: "avo.",
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* Char_literal */12,
                      _0: /* '(' */40,
                      _1: {
                        TAG: /* Alpha */15,
                        _0: {
                          TAG: /* Formatting_lit */17,
                          _0: /* Close_box */0,
                          _1: {
                            TAG: /* Formatting_lit */17,
                            _0: {
                              TAG: /* Break */0,
                              _0: "@,",
                              _1: 0,
                              _2: 0
                            },
                            _1: {
                              TAG: /* Char_literal */12,
                              _0: /* ')' */41,
                              _1: /* End_of_format */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              },
              _1: "@[<v 2>avo.%s(%a@]@,)"
            }), identifier$4(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
  } else {
    Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "avo.",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* Char_literal */12,
                    _0: /* '(' */40,
                    _1: {
                      TAG: /* Alpha */15,
                      _0: {
                        TAG: /* Char_literal */12,
                        _0: /* ')' */41,
                        _1: /* End_of_format */0
                      }
                    }
                  }
                }
              },
              _1: "avo.%s(%a)"
            }), identifier$4(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
  }
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_lit */17,
          _0: /* Close_box */0,
          _1: /* End_of_format */0
        },
        _1: "@]"
      });
  return Format.flush_str_formatter(undefined);
}

function printPinnedValue(pinnedValue) {
  if (pinnedValue === undefined) {
    return Pervasives.failwith("Invalid pinned value");
  }
  var variant = pinnedValue.NAME;
  if (variant === "BooleanLit") {
    if (pinnedValue.VAL) {
      return "True";
    } else {
      return "False";
    }
  } else if (variant === "StringLit") {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* Char_literal */12,
                      _0: /* '"' */34,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* Char_literal */12,
                          _0: /* '"' */34,
                          _1: /* End_of_format */0
                        }
                      }
                    },
                    _1: "\"%s\""
                  }), pinnedValue.VAL);
  } else if (variant === "FloatLit") {
    return pinnedValue.VAL.toString();
  } else {
    return String(pinnedValue.VAL);
  }
}

var Python = {
  reservedKeywords: reservedKeywords$4,
  identifier: identifier$4,
  groupTypeVarName: groupTypeVarName$4,
  propertyVarName: propertyVarName$4,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$4,
  printPinnedValue: printPinnedValue
};

var reservedKeywords$5 = {
  hd: "__halt_compiler",
  tl: {
    hd: "abstract",
    tl: {
      hd: "and",
      tl: {
        hd: "array",
        tl: {
          hd: "as",
          tl: {
            hd: "break",
            tl: {
              hd: "callable",
              tl: {
                hd: "case",
                tl: {
                  hd: "catch",
                  tl: {
                    hd: "class",
                    tl: {
                      hd: "clone",
                      tl: {
                        hd: "const",
                        tl: {
                          hd: "continue",
                          tl: {
                            hd: "declare",
                            tl: {
                              hd: "default",
                              tl: {
                                hd: "die",
                                tl: {
                                  hd: "do",
                                  tl: {
                                    hd: "echo",
                                    tl: {
                                      hd: "else",
                                      tl: {
                                        hd: "elseif",
                                        tl: {
                                          hd: "empty",
                                          tl: {
                                            hd: "enddeclare",
                                            tl: {
                                              hd: "endfor",
                                              tl: {
                                                hd: "endforeach",
                                                tl: {
                                                  hd: "endif",
                                                  tl: {
                                                    hd: "endswitch",
                                                    tl: {
                                                      hd: "endwhile",
                                                      tl: {
                                                        hd: "eval",
                                                        tl: {
                                                          hd: "exit",
                                                          tl: {
                                                            hd: "extends",
                                                            tl: {
                                                              hd: "final",
                                                              tl: {
                                                                hd: "finally",
                                                                tl: {
                                                                  hd: "for",
                                                                  tl: {
                                                                    hd: "foreach",
                                                                    tl: {
                                                                      hd: "function",
                                                                      tl: {
                                                                        hd: "global",
                                                                        tl: {
                                                                          hd: "goto",
                                                                          tl: {
                                                                            hd: "if",
                                                                            tl: {
                                                                              hd: "implements",
                                                                              tl: {
                                                                                hd: "include",
                                                                                tl: {
                                                                                  hd: "include_once",
                                                                                  tl: {
                                                                                    hd: "instanceof",
                                                                                    tl: {
                                                                                      hd: "insteadof",
                                                                                      tl: {
                                                                                        hd: "interface",
                                                                                        tl: {
                                                                                          hd: "isset",
                                                                                          tl: {
                                                                                            hd: "list",
                                                                                            tl: {
                                                                                              hd: "namespace",
                                                                                              tl: {
                                                                                                hd: "new",
                                                                                                tl: {
                                                                                                  hd: "or",
                                                                                                  tl: {
                                                                                                    hd: "print",
                                                                                                    tl: {
                                                                                                      hd: "private",
                                                                                                      tl: {
                                                                                                        hd: "protected",
                                                                                                        tl: {
                                                                                                          hd: "public",
                                                                                                          tl: {
                                                                                                            hd: "require",
                                                                                                            tl: {
                                                                                                              hd: "require_once",
                                                                                                              tl: {
                                                                                                                hd: "return",
                                                                                                                tl: {
                                                                                                                  hd: "static",
                                                                                                                  tl: {
                                                                                                                    hd: "switch",
                                                                                                                    tl: {
                                                                                                                      hd: "throw",
                                                                                                                      tl: {
                                                                                                                        hd: "trait",
                                                                                                                        tl: {
                                                                                                                          hd: "try",
                                                                                                                          tl: {
                                                                                                                            hd: "unset",
                                                                                                                            tl: {
                                                                                                                              hd: "use",
                                                                                                                              tl: {
                                                                                                                                hd: "var",
                                                                                                                                tl: {
                                                                                                                                  hd: "while",
                                                                                                                                  tl: {
                                                                                                                                    hd: "xor",
                                                                                                                                    tl: {
                                                                                                                                      hd: "yield",
                                                                                                                                      tl: {
                                                                                                                                        hd: "yield from",
                                                                                                                                        tl: /* [] */0
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$5(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : (function (prim) {
        return Case$1.snake(prim);
      });
  var name$1 = Curry._1($$case, name);
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$5) ? "_" : "");
}

function groupTypeVarName$5(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$5((function (prim) {
                    return Case$1.pascal(prim);
                  }), StateUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$5($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$5($$case, propertyCodegenName(property)));
  } else {
    return identifier$5($$case, propertyCodegenName(property));
  }
}

function generateSnippetFromEnrichedModel$5($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(StateUtils.getEventById($$event.id, model));
  var inputProperties = filterPinnedProperties($$event$1.properties, model.properties);
  var inputProperties$1 = AvoModel.resolveProperties(model, inputProperties);
  var partial_arg = Case.camel;
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$5(undefined, eta);
        }), $$event$1, model, groupTypeVarName$5, (function (param) {
          return identifier$5(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* Char_literal */12,
            _0: /* '=' */61,
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s=%s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "/* ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: {
              TAG: /* String_literal */11,
              _0: " */",
              _1: /* End_of_format */0
            }
          }
        },
        _1: "/* %s */"
      }, source);
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEachWithIndex(params, (function (index, param) {
                    return Curry._3(Format.fprintf(ppf, /* Format */{
                                    _0: {
                                      TAG: /* Formatting_lit */17,
                                      _0: {
                                        TAG: /* Break */0,
                                        _0: "@,",
                                        _1: 0,
                                        _2: 0
                                      },
                                      _1: {
                                        TAG: /* String */2,
                                        _0: /* No_padding */0,
                                        _1: {
                                          TAG: /* String */2,
                                          _0: /* No_padding */0,
                                          _1: {
                                            TAG: /* String */2,
                                            _0: /* No_padding */0,
                                            _1: /* End_of_format */0
                                          }
                                        }
                                      }
                                    },
                                    _1: "@,%s%s%s"
                                  }), param[0], index === (Belt_List.length(params) - 1 | 0) ? "" : ",", commentWithSpace(param[1]));
                  }));
    }
    var match = params.hd;
    return Curry._2(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s%s"
                  }), match[0], commentWithSpace(match[1]));
  };
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v>",
                _1: /* End_of_format */0
              },
              _1: "<v>"
            }
          },
          _1: /* End_of_format */0
        },
        _1: "@[<v>"
      });
  if (Belt_List.length($$arguments) > 1) {
    Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* Formatting_gen */18,
                _0: {
                  TAG: /* Open_box */1,
                  _0: /* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "<v 2>",
                      _1: /* End_of_format */0
                    },
                    _1: "<v 2>"
                  }
                },
                _1: {
                  TAG: /* String_literal */11,
                  _0: "Avo::",
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* Char_literal */12,
                      _0: /* '(' */40,
                      _1: {
                        TAG: /* Alpha */15,
                        _0: {
                          TAG: /* Formatting_lit */17,
                          _0: /* Close_box */0,
                          _1: {
                            TAG: /* Formatting_lit */17,
                            _0: {
                              TAG: /* Break */0,
                              _0: "@,",
                              _1: 0,
                              _2: 0
                            },
                            _1: {
                              TAG: /* Char_literal */12,
                              _0: /* ')' */41,
                              _1: /* End_of_format */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              },
              _1: "@[<v 2>Avo::%s(%a@]@,)"
            }), identifier$5(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
  } else {
    Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "Avo::",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* Char_literal */12,
                    _0: /* '(' */40,
                    _1: {
                      TAG: /* Alpha */15,
                      _0: {
                        TAG: /* Char_literal */12,
                        _0: /* ')' */41,
                        _1: /* End_of_format */0
                      }
                    }
                  }
                }
              },
              _1: "Avo::%s(%a)"
            }), identifier$5(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
  }
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_lit */17,
          _0: /* Close_box */0,
          _1: /* End_of_format */0
        },
        _1: "@]"
      });
  return Format.flush_str_formatter(undefined);
}

function printPinnedValue$1(pinnedValue) {
  if (pinnedValue === undefined) {
    return Pervasives.failwith("Invalid pinned value");
  }
  var variant = pinnedValue.NAME;
  if (variant === "BooleanLit") {
    if (pinnedValue.VAL) {
      return "TRUE";
    } else {
      return "FALSE";
    }
  } else if (variant === "StringLit") {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* Char_literal */12,
                      _0: /* '\'' */39,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* Char_literal */12,
                          _0: /* '\'' */39,
                          _1: /* End_of_format */0
                        }
                      }
                    },
                    _1: "'%s'"
                  }), pinnedValue.VAL);
  } else if (variant === "FloatLit") {
    return pinnedValue.VAL.toString();
  } else {
    return String(pinnedValue.VAL);
  }
}

var PHP = {
  reservedKeywords: reservedKeywords$5,
  identifier: identifier$5,
  groupTypeVarName: groupTypeVarName$5,
  propertyVarName: propertyVarName$5,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$5,
  printPinnedValue: printPinnedValue$1
};

var reservedKeywords$6 = {
  hd: "abstract",
  tl: {
    hd: "continue",
    tl: {
      hd: "for",
      tl: {
        hd: "new",
        tl: {
          hd: "switch",
          tl: {
            hd: "assert",
            tl: {
              hd: "default",
              tl: {
                hd: "goto",
                tl: {
                  hd: "package",
                  tl: {
                    hd: "synchronized",
                    tl: {
                      hd: "boolean",
                      tl: {
                        hd: "do",
                        tl: {
                          hd: "if",
                          tl: {
                            hd: "private",
                            tl: {
                              hd: "this",
                              tl: {
                                hd: "break",
                                tl: {
                                  hd: "double",
                                  tl: {
                                    hd: "implements",
                                    tl: {
                                      hd: "protected",
                                      tl: {
                                        hd: "throw",
                                        tl: {
                                          hd: "byte",
                                          tl: {
                                            hd: "else",
                                            tl: {
                                              hd: "import",
                                              tl: {
                                                hd: "public",
                                                tl: {
                                                  hd: "throws",
                                                  tl: {
                                                    hd: "case",
                                                    tl: {
                                                      hd: "enum",
                                                      tl: {
                                                        hd: "instanceof",
                                                        tl: {
                                                          hd: "return",
                                                          tl: {
                                                            hd: "transient",
                                                            tl: {
                                                              hd: "catch",
                                                              tl: {
                                                                hd: "extends",
                                                                tl: {
                                                                  hd: "int",
                                                                  tl: {
                                                                    hd: "short",
                                                                    tl: {
                                                                      hd: "try",
                                                                      tl: {
                                                                        hd: "char",
                                                                        tl: {
                                                                          hd: "final",
                                                                          tl: {
                                                                            hd: "interface",
                                                                            tl: {
                                                                              hd: "static",
                                                                              tl: {
                                                                                hd: "void",
                                                                                tl: {
                                                                                  hd: "class",
                                                                                  tl: {
                                                                                    hd: "finally",
                                                                                    tl: {
                                                                                      hd: "long",
                                                                                      tl: {
                                                                                        hd: "strictfp",
                                                                                        tl: {
                                                                                          hd: "volatile",
                                                                                          tl: {
                                                                                            hd: "const",
                                                                                            tl: {
                                                                                              hd: "float",
                                                                                              tl: {
                                                                                                hd: "native",
                                                                                                tl: {
                                                                                                  hd: "super",
                                                                                                  tl: {
                                                                                                    hd: "while",
                                                                                                    tl: /* [] */0
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$6(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var name$1 = Curry._1($$case, name);
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$6) ? "_" : "");
}

function groupTypeVarName$6(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$6((function (prim) {
                    return Case$1.pascal(prim);
                  }), StateUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$6($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$6($$case, propertyCodegenName(property)));
  } else {
    return identifier$6($$case, propertyCodegenName(property));
  }
}

function generateSnippetFromEnrichedModel$6($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(StateUtils.getEventById($$event.id, model));
  var inputProperties = filterPinnedProperties($$event$1.properties, model.properties);
  var inputProperties$1 = AvoModel.resolveProperties(model, inputProperties);
  var partial_arg = Case.camel;
  var $$arguments = Belt_List.mapU(snippetArguments(inputProperties$1, (function (eta) {
              return propertyVarName$6(undefined, eta);
            }), $$event$1, model, groupTypeVarName$6, (function (param) {
              return identifier$6(partial_arg, param);
            }), /* Format */{
            _0: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: {
                TAG: /* Char_literal */12,
                _0: /* ':' */58,
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: /* End_of_format */0
                }
              }
            },
            _1: "%s:%s"
          }, /* Format */{
            _0: {
              TAG: /* String_literal */11,
              _0: "/* ",
              _1: {
                TAG: /* String */2,
                _0: /* No_padding */0,
                _1: {
                  TAG: /* String_literal */11,
                  _0: " */",
                  _1: /* End_of_format */0
                }
              }
            },
            _1: "/* %s */"
          }, source), (function (param) {
          return [
                  Caml_array.get(param[0].split(":"), 1),
                  param[1]
                ];
        }));
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEachWithIndex(params, (function (index, param) {
                    return Curry._3(Format.fprintf(ppf, /* Format */{
                                    _0: {
                                      TAG: /* Formatting_lit */17,
                                      _0: {
                                        TAG: /* Break */0,
                                        _0: "@,",
                                        _1: 0,
                                        _2: 0
                                      },
                                      _1: {
                                        TAG: /* String */2,
                                        _0: /* No_padding */0,
                                        _1: {
                                          TAG: /* String */2,
                                          _0: /* No_padding */0,
                                          _1: {
                                            TAG: /* String */2,
                                            _0: /* No_padding */0,
                                            _1: /* End_of_format */0
                                          }
                                        }
                                      }
                                    },
                                    _1: "@,%s%s%s"
                                  }), param[0], index === (Belt_List.length(params) - 1 | 0) ? "" : ",", commentWithSpace(param[1]));
                  }));
    }
    var match = params.hd;
    return Curry._2(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s%s"
                  }), match[0], commentWithSpace(match[1]));
  };
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v>",
                _1: /* End_of_format */0
              },
              _1: "<v>"
            }
          },
          _1: /* End_of_format */0
        },
        _1: "@[<v>"
      });
  if (Belt_List.length($$arguments) > 1) {
    Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* Formatting_gen */18,
                _0: {
                  TAG: /* Open_box */1,
                  _0: /* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "<v 2>",
                      _1: /* End_of_format */0
                    },
                    _1: "<v 2>"
                  }
                },
                _1: {
                  TAG: /* String_literal */11,
                  _0: "Avo.",
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* Char_literal */12,
                      _0: /* '(' */40,
                      _1: {
                        TAG: /* Alpha */15,
                        _0: {
                          TAG: /* Formatting_lit */17,
                          _0: /* Close_box */0,
                          _1: {
                            TAG: /* Formatting_lit */17,
                            _0: {
                              TAG: /* Break */0,
                              _0: "@,",
                              _1: 0,
                              _2: 0
                            },
                            _1: {
                              TAG: /* String_literal */11,
                              _0: ");",
                              _1: /* End_of_format */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              },
              _1: "@[<v 2>Avo.%s(%a@]@,);"
            }), identifier$6(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
  } else {
    Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "Avo.",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* Char_literal */12,
                    _0: /* '(' */40,
                    _1: {
                      TAG: /* Alpha */15,
                      _0: {
                        TAG: /* String_literal */11,
                        _0: ");",
                        _1: /* End_of_format */0
                      }
                    }
                  }
                }
              },
              _1: "Avo.%s(%a);"
            }), identifier$6(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
  }
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_lit */17,
          _0: /* Close_box */0,
          _1: /* End_of_format */0
        },
        _1: "@]"
      });
  return Format.flush_str_formatter(undefined);
}

var Java = {
  reservedKeywords: reservedKeywords$6,
  identifier: identifier$6,
  groupTypeVarName: groupTypeVarName$6,
  propertyVarName: propertyVarName$6,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$6
};

var reservedKeywords$7 = {
  hd: "package",
  tl: {
    hd: "as",
    tl: {
      hd: "typealias",
      tl: {
        hd: "class",
        tl: {
          hd: "this",
          tl: {
            hd: "super",
            tl: {
              hd: "val",
              tl: {
                hd: "var",
                tl: {
                  hd: "fun",
                  tl: {
                    hd: "for",
                    tl: {
                      hd: "null",
                      tl: {
                        hd: "true",
                        tl: {
                          hd: "false",
                          tl: {
                            hd: "is",
                            tl: {
                              hd: "in",
                              tl: {
                                hd: "throw",
                                tl: {
                                  hd: "return",
                                  tl: {
                                    hd: "break",
                                    tl: {
                                      hd: "continue",
                                      tl: {
                                        hd: "object",
                                        tl: {
                                          hd: "if",
                                          tl: {
                                            hd: "try",
                                            tl: {
                                              hd: "else",
                                              tl: {
                                                hd: "while",
                                                tl: {
                                                  hd: "do",
                                                  tl: {
                                                    hd: "when",
                                                    tl: {
                                                      hd: "interface",
                                                      tl: {
                                                        hd: "typeof",
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$7(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var allKindsOfDashes = /–|—|—|―|‒/g;
  var name$1 = Curry._1($$case, name).replace(allKindsOfDashes, "Dash");
  var containsDollarSign = name$1.indexOf("$") !== -1;
  var name$2 = containsDollarSign ? "`" + (name$1 + "`") : name$1;
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$2, List.exists((function (param) {
                    return name$2 === param;
                  }), reservedKeywords$7) ? "_" : "");
}

function groupTypeVarName$7(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$7((function (prim) {
                    return Case$1.pascal(prim);
                  }), StateUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$7($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$7($$case, propertyCodegenName(property)));
  } else {
    return identifier$7($$case, propertyCodegenName(property));
  }
}

function generateSnippetFromEnrichedModel$7($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(StateUtils.getEventById($$event.id, model));
  var inputGroups = AvoModel.resolvePropertyBundleRefs(model.propertyBundles, $$event$1.propertyBundles);
  var inputProperties = filterPinnedProperties($$event$1.properties, model.properties);
  var inputProperties$1 = Belt_List.keep(AvoModel.resolveProperties(model, inputProperties), (function (property) {
          return !Belt_List.some(inputGroups, (function (group) {
                        return Belt_List.some(group.properties, (function (propertyId) {
                                      return property.id === propertyId;
                                    }));
                      }));
        }));
  var propertyNameMaps = getPropertyNameMaps($$event$1, source, model);
  var propertyGroupsArgs = Belt_List.map(inputGroups, (function (group) {
          var comment = printPropertyGroupTypeInPseudocode(group.properties, (function (eta) {
                  return propertyVarName$7(undefined, eta);
                }), model, $$event$1, propertyNameMaps, source);
          return [
                  Curry._2(Printf.sprintf(/* Format */{
                            _0: {
                              TAG: /* String */2,
                              _0: /* No_padding */0,
                              _1: {
                                TAG: /* Char_literal */12,
                                _0: /* '=' */61,
                                _1: {
                                  TAG: /* String */2,
                                  _0: /* No_padding */0,
                                  _1: /* End_of_format */0
                                }
                              }
                            },
                            _1: "%s=%s"
                          }), identifier$7(undefined, group.name), identifier$7(undefined, group.name)),
                  comment
                ];
        }));
  var partial_arg = Case.camel;
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$7(undefined, eta);
        }), $$event$1, model, groupTypeVarName$7, (function (param) {
          return identifier$7(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* Char_literal */12,
            _0: /* '=' */61,
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s=%s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "/* ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: {
              TAG: /* String_literal */11,
              _0: " */",
              _1: /* End_of_format */0
            }
          }
        },
        _1: "/* %s */"
      }, source);
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEachWithIndex(params, (function (index, param) {
                    return Curry._3(Format.fprintf(ppf, /* Format */{
                                    _0: {
                                      TAG: /* Formatting_lit */17,
                                      _0: {
                                        TAG: /* Break */0,
                                        _0: "@,",
                                        _1: 0,
                                        _2: 0
                                      },
                                      _1: {
                                        TAG: /* String */2,
                                        _0: /* No_padding */0,
                                        _1: {
                                          TAG: /* String */2,
                                          _0: /* No_padding */0,
                                          _1: {
                                            TAG: /* String */2,
                                            _0: /* No_padding */0,
                                            _1: /* End_of_format */0
                                          }
                                        }
                                      }
                                    },
                                    _1: "@,%s%s%s"
                                  }), param[0], index === (Belt_List.length(params) - 1 | 0) ? "" : ",", commentWithSpace(param[1]));
                  }));
    }
    var match = params.hd;
    return Curry._2(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s%s"
                  }), match[0], commentWithSpace(match[1]));
  };
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v>",
                _1: /* End_of_format */0
              },
              _1: "<v>"
            }
          },
          _1: /* End_of_format */0
        },
        _1: "@[<v>"
      });
  var argsLength = Belt_List.length($$arguments) + Belt_List.length(propertyGroupsArgs) | 0;
  if (argsLength > 1) {
    Curry._5(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* Formatting_gen */18,
                _0: {
                  TAG: /* Open_box */1,
                  _0: /* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "<v 2>",
                      _1: /* End_of_format */0
                    },
                    _1: "<v 2>"
                  }
                },
                _1: {
                  TAG: /* String_literal */11,
                  _0: "Avo.",
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* Char_literal */12,
                      _0: /* '(' */40,
                      _1: {
                        TAG: /* Alpha */15,
                        _0: {
                          TAG: /* Alpha */15,
                          _0: {
                            TAG: /* Formatting_lit */17,
                            _0: /* Close_box */0,
                            _1: {
                              TAG: /* Formatting_lit */17,
                              _0: {
                                TAG: /* Break */0,
                                _0: "@,",
                                _1: 0,
                                _2: 0
                              },
                              _1: {
                                TAG: /* Char_literal */12,
                                _0: /* ')' */41,
                                _1: /* End_of_format */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              },
              _1: "@[<v 2>Avo.%s(%a%a@]@,)"
            }), identifier$7(undefined, eventCodegenName($$event$1)), (function (param) {
            return function (param$1) {
              var param$2 = "/*";
              var param$3 = "*/";
              return printPropertyGroupArguments(param, param$1, param$2, param$3);
            };
          }), propertyGroupsArgs, printArguments, $$arguments);
  } else {
    Curry._5(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "Avo.",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* Char_literal */12,
                    _0: /* '(' */40,
                    _1: {
                      TAG: /* Alpha */15,
                      _0: {
                        TAG: /* Alpha */15,
                        _0: {
                          TAG: /* Char_literal */12,
                          _0: /* ')' */41,
                          _1: /* End_of_format */0
                        }
                      }
                    }
                  }
                }
              },
              _1: "Avo.%s(%a%a)"
            }), identifier$7(undefined, eventCodegenName($$event$1)), (function (param) {
            return function (param$1) {
              var param$2 = "/*";
              var param$3 = "*/";
              return printPropertyGroupArguments(param, param$1, param$2, param$3);
            };
          }), propertyGroupsArgs, printArguments, $$arguments);
  }
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_lit */17,
          _0: /* Close_box */0,
          _1: /* End_of_format */0
        },
        _1: "@]"
      });
  return Format.flush_str_formatter(undefined);
}

function pinnedPropertyToString(propertyRef, property, hasMatchValidation, optional) {
  var propertyValue = printPinnedValueExn(propertyRef.pinnedValue);
  if (optional || !(property.type_ === "string" && !Curry._1(hasMatchValidation, property))) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: ".toString()",
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s.toString()"
                  }), propertyValue);
  } else {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    },
                    _1: "%s"
                  }), propertyValue);
  }
}

var Kotlin = {
  reservedKeywords: reservedKeywords$7,
  identifier: identifier$7,
  groupTypeVarName: groupTypeVarName$7,
  propertyVarName: propertyVarName$7,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$7,
  pinnedPropertyToString: pinnedPropertyToString
};

var reservedKeywords$8 = {
  hd: "auto",
  tl: {
    hd: "break",
    tl: {
      hd: "case",
      tl: {
        hd: "char",
        tl: {
          hd: "const",
          tl: {
            hd: "continue",
            tl: {
              hd: "default",
              tl: {
                hd: "do",
                tl: {
                  hd: "double",
                  tl: {
                    hd: "else",
                    tl: {
                      hd: "enum",
                      tl: {
                        hd: "extern",
                        tl: {
                          hd: "float",
                          tl: {
                            hd: "for",
                            tl: {
                              hd: "goto",
                              tl: {
                                hd: "if",
                                tl: {
                                  hd: "inline",
                                  tl: {
                                    hd: "int",
                                    tl: {
                                      hd: "long",
                                      tl: {
                                        hd: "register",
                                        tl: {
                                          hd: "restrict",
                                          tl: {
                                            hd: "return",
                                            tl: {
                                              hd: "short",
                                              tl: {
                                                hd: "signed",
                                                tl: {
                                                  hd: "sizeof",
                                                  tl: {
                                                    hd: "static",
                                                    tl: {
                                                      hd: "struct",
                                                      tl: {
                                                        hd: "switch",
                                                        tl: {
                                                          hd: "typedef",
                                                          tl: {
                                                            hd: "union",
                                                            tl: {
                                                              hd: "unsigned",
                                                              tl: {
                                                                hd: "void",
                                                                tl: {
                                                                  hd: "volatile",
                                                                  tl: {
                                                                    hd: "while",
                                                                    tl: {
                                                                      hd: "_Bool",
                                                                      tl: {
                                                                        hd: "_Complex",
                                                                        tl: {
                                                                          hd: "_Imaginary",
                                                                          tl: {
                                                                            hd: "BOOL",
                                                                            tl: {
                                                                              hd: "Class",
                                                                              tl: {
                                                                                hd: "bycopy",
                                                                                tl: {
                                                                                  hd: "byref",
                                                                                  tl: {
                                                                                    hd: "id",
                                                                                    tl: {
                                                                                      hd: "IMP",
                                                                                      tl: {
                                                                                        hd: "in",
                                                                                        tl: {
                                                                                          hd: "inout",
                                                                                          tl: {
                                                                                            hd: "nil",
                                                                                            tl: {
                                                                                              hd: "NO",
                                                                                              tl: {
                                                                                                hd: "NULL",
                                                                                                tl: {
                                                                                                  hd: "oneway",
                                                                                                  tl: {
                                                                                                    hd: "out",
                                                                                                    tl: {
                                                                                                      hd: "Protocol",
                                                                                                      tl: {
                                                                                                        hd: "SEL",
                                                                                                        tl: {
                                                                                                          hd: "self",
                                                                                                          tl: {
                                                                                                            hd: "Super",
                                                                                                            tl: {
                                                                                                              hd: "YES",
                                                                                                              tl: {
                                                                                                                hd: "@interface",
                                                                                                                tl: {
                                                                                                                  hd: "@end",
                                                                                                                  tl: {
                                                                                                                    hd: "@implementation",
                                                                                                                    tl: {
                                                                                                                      hd: "@protocol",
                                                                                                                      tl: {
                                                                                                                        hd: "@class",
                                                                                                                        tl: {
                                                                                                                          hd: "@public",
                                                                                                                          tl: {
                                                                                                                            hd: "@protected",
                                                                                                                            tl: {
                                                                                                                              hd: "@private",
                                                                                                                              tl: {
                                                                                                                                hd: "@property",
                                                                                                                                tl: {
                                                                                                                                  hd: "@try",
                                                                                                                                  tl: {
                                                                                                                                    hd: "@throw",
                                                                                                                                    tl: {
                                                                                                                                      hd: "@catch",
                                                                                                                                      tl: {
                                                                                                                                        hd: "@finally",
                                                                                                                                        tl: {
                                                                                                                                          hd: "@synthesize",
                                                                                                                                          tl: {
                                                                                                                                            hd: "@dynamic",
                                                                                                                                            tl: {
                                                                                                                                              hd: "@selector",
                                                                                                                                              tl: {
                                                                                                                                                hd: "atomic",
                                                                                                                                                tl: {
                                                                                                                                                  hd: "nonatomic",
                                                                                                                                                  tl: {
                                                                                                                                                    hd: "retain",
                                                                                                                                                    tl: /* [] */0
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$8(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var name$1 = Curry._1($$case, name);
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$8) ? "_" : "");
}

function groupTypeVarName$8(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$8((function (prim) {
                    return Case$1.pascal(prim);
                  }), StateUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$8($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$8($$case, propertyCodegenName(property)));
  } else {
    return identifier$8($$case, propertyCodegenName(property));
  }
}

function printApplyParams(ppf, params) {
  if (!params) {
    return Format.fprintf(ppf, /* Format */{
                _0: /* End_of_format */0,
                _1: ""
              });
  }
  var rest = params.tl;
  var param = params.hd;
  if (rest) {
    return Curry._4(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* ':' */58,
                        _1: {
                          TAG: /* String */2,
                          _0: /* No_padding */0,
                          _1: {
                            TAG: /* Formatting_lit */17,
                            _0: {
                              TAG: /* Break */0,
                              _0: "@ ",
                              _1: 1,
                              _2: 0
                            },
                            _1: {
                              TAG: /* Alpha */15,
                              _0: /* End_of_format */0
                            }
                          }
                        }
                      }
                    },
                    _1: "%s:%s@ %a"
                  }), param, param, printApplyParams, rest);
  } else {
    return Curry._2(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* ':' */58,
                        _1: {
                          TAG: /* String */2,
                          _0: /* No_padding */0,
                          _1: /* End_of_format */0
                        }
                      }
                    },
                    _1: "%s:%s"
                  }), param, param);
  }
}

function printApplyParams$1(ppf, params) {
  if (!params) {
    return Format.fprintf(ppf, /* Format */{
                _0: /* End_of_format */0,
                _1: ""
              });
  }
  var rest = params.tl;
  var param = params.hd;
  if (rest) {
    return Curry._3(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Formatting_lit */17,
                        _0: {
                          TAG: /* Break */0,
                          _0: "@ ",
                          _1: 1,
                          _2: 0
                        },
                        _1: {
                          TAG: /* Alpha */15,
                          _0: /* End_of_format */0
                        }
                      }
                    },
                    _1: "%s@ %a"
                  }), param, printApplyParams, rest);
  } else {
    return Curry._1(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    },
                    _1: "%s"
                  }), param);
  }
}

function generateSnippetFromEnrichedModel$8($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(StateUtils.getEventById($$event.id, model));
  var inputGroups = AvoModel.resolvePropertyBundleRefs(model.propertyBundles, $$event$1.propertyBundles);
  var inputProperties = filterPinnedProperties($$event$1.properties, model.properties);
  var inputProperties$1 = Belt_List.keep(AvoModel.resolveProperties(model, inputProperties), (function (property) {
          return !Belt_List.some(inputGroups, (function (group) {
                        return Belt_List.some(group.properties, (function (propertyId) {
                                      return property.id === propertyId;
                                    }));
                      }));
        }));
  var propertyNameMaps = getPropertyNameMaps($$event$1, source, model);
  var propertyGroupsArgs = Belt_List.map(inputGroups, (function (group) {
          var comment = printPropertyGroupTypeInPseudocode(group.properties, (function (eta) {
                  return propertyVarName$8(undefined, eta);
                }), model, $$event$1, propertyNameMaps, source);
          return [
                  Curry._2(Printf.sprintf(/* Format */{
                            _0: {
                              TAG: /* String */2,
                              _0: /* No_padding */0,
                              _1: {
                                TAG: /* Char_literal */12,
                                _0: /* ':' */58,
                                _1: {
                                  TAG: /* String */2,
                                  _0: /* No_padding */0,
                                  _1: /* End_of_format */0
                                }
                              }
                            },
                            _1: "%s:%s"
                          }), identifier$8(undefined, group.name), identifier$8(undefined, group.name)),
                  comment
                ];
        }));
  var partial_arg = (function (prim) {
      return Case$1.pascal(prim);
    });
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$8(undefined, eta);
        }), $$event$1, model, groupTypeVarName$8, (function (param) {
          return identifier$8(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* Char_literal */12,
            _0: /* ':' */58,
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s:%s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "/* ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: {
              TAG: /* String_literal */11,
              _0: " */",
              _1: /* End_of_format */0
            }
          }
        },
        _1: "/* %s */"
      }, source);
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEach(params, (function (param) {
                    return Curry._2(Format.fprintf(ppf, /* Format */{
                                    _0: {
                                      TAG: /* Formatting_lit */17,
                                      _0: {
                                        TAG: /* Break */0,
                                        _0: "@,",
                                        _1: 0,
                                        _2: 0
                                      },
                                      _1: {
                                        TAG: /* String */2,
                                        _0: /* No_padding */0,
                                        _1: {
                                          TAG: /* Char_literal */12,
                                          _0: /* ',' */44,
                                          _1: {
                                            TAG: /* String */2,
                                            _0: /* No_padding */0,
                                            _1: /* End_of_format */0
                                          }
                                        }
                                      }
                                    },
                                    _1: "@,%s,%s"
                                  }), param[0], commentWithSpace(param[1]));
                  }));
    }
    var match = params.hd;
    return Curry._2(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s%s"
                  }), match[0], commentWithSpace(match[1]));
  };
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v>",
                _1: /* End_of_format */0
              },
              _1: "<v>"
            }
          },
          _1: /* End_of_format */0
        },
        _1: "@[<v>"
      });
  var argsLength = Belt_List.length($$arguments) + Belt_List.length(propertyGroupsArgs) | 0;
  if (argsLength > 0) {
    Curry._5(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* Formatting_gen */18,
                _0: {
                  TAG: /* Open_box */1,
                  _0: /* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "<v 2>",
                      _1: /* End_of_format */0
                    },
                    _1: "<v 2>"
                  }
                },
                _1: {
                  TAG: /* String_literal */11,
                  _0: "[Avo ",
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "With",
                      _1: {
                        TAG: /* Alpha */15,
                        _0: {
                          TAG: /* Alpha */15,
                          _0: {
                            TAG: /* Formatting_lit */17,
                            _0: /* Close_box */0,
                            _1: {
                              TAG: /* String_literal */11,
                              _0: "];",
                              _1: /* End_of_format */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              },
              _1: "@[<v 2>[Avo %sWith%a%a@]];"
            }), identifier$8(undefined, eventCodegenName($$event$1)), (function (param) {
            return function (param$1) {
              var param$2 = "/*";
              var param$3 = "*/";
              return printPropertyGroupArguments(param, param$1, param$2, param$3);
            };
          }), propertyGroupsArgs, printArguments, $$arguments);
  } else {
    Curry._1(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "[Avo ",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* String_literal */11,
                    _0: "];",
                    _1: /* End_of_format */0
                  }
                }
              },
              _1: "[Avo %s];"
            }), identifier$8(undefined, eventCodegenName($$event$1)));
  }
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_lit */17,
          _0: /* Close_box */0,
          _1: /* End_of_format */0
        },
        _1: "@]"
      });
  return Format.flush_str_formatter(undefined);
}

var ObjectiveC = {
  reservedKeywords: reservedKeywords$8,
  identifier: identifier$8,
  groupTypeVarName: groupTypeVarName$8,
  propertyVarName: propertyVarName$8,
  printApplyParams: printApplyParams$1,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$8
};

var reservedKeywords$9 = {
  hd: "Protocol",
  tl: {
    hd: "Self",
    tl: {
      hd: "Type",
      tl: {
        hd: "__COLUMN__",
        tl: {
          hd: "__FILE__",
          tl: {
            hd: "__FUNCTION__",
            tl: {
              hd: "__LINE__",
              tl: {
                hd: "as",
                tl: {
                  hd: "associativity",
                  tl: {
                    hd: "break",
                    tl: {
                      hd: "case",
                      tl: {
                        hd: "class",
                        tl: {
                          hd: "continue",
                          tl: {
                            hd: "convenience",
                            tl: {
                              hd: "default",
                              tl: {
                                hd: "deinit",
                                tl: {
                                  hd: "didSet",
                                  tl: {
                                    hd: "do",
                                    tl: {
                                      hd: "dynamic",
                                      tl: {
                                        hd: "dynamicType",
                                        tl: {
                                          hd: "else",
                                          tl: {
                                            hd: "enum",
                                            tl: {
                                              hd: "extension",
                                              tl: {
                                                hd: "fallthrough",
                                                tl: {
                                                  hd: "false",
                                                  tl: {
                                                    hd: "final",
                                                    tl: {
                                                      hd: "for",
                                                      tl: {
                                                        hd: "func ",
                                                        tl: {
                                                          hd: "get",
                                                          tl: {
                                                            hd: "if",
                                                            tl: {
                                                              hd: "import",
                                                              tl: {
                                                                hd: "in",
                                                                tl: {
                                                                  hd: "infix",
                                                                  tl: {
                                                                    hd: "init",
                                                                    tl: {
                                                                      hd: "inout",
                                                                      tl: {
                                                                        hd: "internal",
                                                                        tl: {
                                                                          hd: "is",
                                                                          tl: {
                                                                            hd: "lazy",
                                                                            tl: {
                                                                              hd: "left",
                                                                              tl: {
                                                                                hd: "let",
                                                                                tl: {
                                                                                  hd: "mutating",
                                                                                  tl: {
                                                                                    hd: "nil",
                                                                                    tl: {
                                                                                      hd: "none",
                                                                                      tl: {
                                                                                        hd: "nonmutating",
                                                                                        tl: {
                                                                                          hd: "operator",
                                                                                          tl: {
                                                                                            hd: "optional",
                                                                                            tl: {
                                                                                              hd: "override",
                                                                                              tl: {
                                                                                                hd: "postfix",
                                                                                                tl: {
                                                                                                  hd: "precedence",
                                                                                                  tl: {
                                                                                                    hd: "prefix",
                                                                                                    tl: {
                                                                                                      hd: "private",
                                                                                                      tl: {
                                                                                                        hd: "protocol",
                                                                                                        tl: {
                                                                                                          hd: "public",
                                                                                                          tl: {
                                                                                                            hd: "required",
                                                                                                            tl: {
                                                                                                              hd: "return",
                                                                                                              tl: {
                                                                                                                hd: "right",
                                                                                                                tl: {
                                                                                                                  hd: "self",
                                                                                                                  tl: {
                                                                                                                    hd: "set",
                                                                                                                    tl: {
                                                                                                                      hd: "static",
                                                                                                                      tl: {
                                                                                                                        hd: "struct",
                                                                                                                        tl: {
                                                                                                                          hd: "subscript",
                                                                                                                          tl: {
                                                                                                                            hd: "super",
                                                                                                                            tl: {
                                                                                                                              hd: "switch",
                                                                                                                              tl: {
                                                                                                                                hd: "true",
                                                                                                                                tl: {
                                                                                                                                  hd: "try",
                                                                                                                                  tl: {
                                                                                                                                    hd: "typealias",
                                                                                                                                    tl: {
                                                                                                                                      hd: "unowned",
                                                                                                                                      tl: {
                                                                                                                                        hd: "var",
                                                                                                                                        tl: {
                                                                                                                                          hd: "weak",
                                                                                                                                          tl: {
                                                                                                                                            hd: "where",
                                                                                                                                            tl: {
                                                                                                                                              hd: "while",
                                                                                                                                              tl: {
                                                                                                                                                hd: "repeat",
                                                                                                                                                tl: /* [] */0
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$9(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var name$1 = Curry._1($$case, name);
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$9) ? "_" : "");
}

function groupTypeVarName$9(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$9((function (prim) {
                    return Case$1.pascal(prim);
                  }), StateUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$9($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$9($$case, propertyCodegenName(property)));
  } else {
    return identifier$9($$case, propertyCodegenName(property));
  }
}

function generateSnippetFromEnrichedModel$9($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(StateUtils.getEventById($$event.id, model));
  var inputGroups = AvoModel.resolvePropertyBundleRefs(model.propertyBundles, $$event$1.propertyBundles);
  var inputProperties = filterPinnedProperties($$event$1.properties, model.properties);
  var inputProperties$1 = Belt_List.keep(AvoModel.resolveProperties(model, inputProperties), (function (property) {
          return !Belt_List.some(inputGroups, (function (group) {
                        return Belt_List.some(group.properties, (function (propertyId) {
                                      return property.id === propertyId;
                                    }));
                      }));
        }));
  var propertyNameMaps = getPropertyNameMaps($$event$1, source, model);
  var propertyGroupsArgs = Belt_List.mapU(Belt_List.map(inputGroups, (function (group) {
              var comment = printPropertyGroupTypeInPseudocode(group.properties, (function (eta) {
                      return propertyVarName$9(undefined, eta);
                    }), model, $$event$1, propertyNameMaps, source);
              return [
                      Curry._2(Printf.sprintf(/* Format */{
                                _0: {
                                  TAG: /* String */2,
                                  _0: /* No_padding */0,
                                  _1: {
                                    TAG: /* String_literal */11,
                                    _0: ": ",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: /* End_of_format */0
                                    }
                                  }
                                },
                                _1: "%s: %s"
                              }), identifier$9(undefined, group.name), identifier$9(undefined, group.name)),
                      comment
                    ];
            })), (function (param) {
          return [
                  Caml_array.get(param[0].split(":"), 1),
                  param[1]
                ];
        }));
  var partial_arg = Case.camel;
  var $$arguments = Belt_List.mapU(snippetArguments(inputProperties$1, (function (eta) {
              return propertyVarName$9(undefined, eta);
            }), $$event$1, model, groupTypeVarName$9, (function (param) {
              return identifier$9(partial_arg, param);
            }), /* Format */{
            _0: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: {
                TAG: /* String_literal */11,
                _0: ": ",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: /* End_of_format */0
                }
              }
            },
            _1: "%s: %s"
          }, /* Format */{
            _0: {
              TAG: /* String_literal */11,
              _0: "/* ",
              _1: {
                TAG: /* String */2,
                _0: /* No_padding */0,
                _1: {
                  TAG: /* String_literal */11,
                  _0: " */",
                  _1: /* End_of_format */0
                }
              }
            },
            _1: "/* %s */"
          }, source), (function (param) {
          return [
                  Caml_array.get(param[0].split(":"), 1),
                  param[1]
                ];
        }));
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEachWithIndex(params, (function (index, param) {
                    var param$1 = param[0];
                    return Curry._4(Format.fprintf(ppf, /* Format */{
                                    _0: {
                                      TAG: /* Formatting_lit */17,
                                      _0: {
                                        TAG: /* Break */0,
                                        _0: "@,",
                                        _1: 0,
                                        _2: 0
                                      },
                                      _1: {
                                        TAG: /* String */2,
                                        _0: /* No_padding */0,
                                        _1: {
                                          TAG: /* String_literal */11,
                                          _0: ": ",
                                          _1: {
                                            TAG: /* String */2,
                                            _0: /* No_padding */0,
                                            _1: {
                                              TAG: /* String */2,
                                              _0: /* No_padding */0,
                                              _1: {
                                                TAG: /* String */2,
                                                _0: /* No_padding */0,
                                                _1: /* End_of_format */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    },
                                    _1: "@,%s: %s%s%s"
                                  }), param$1, param$1, index === (Belt_List.length(params) - 1 | 0) ? "" : ",", commentWithSpace(param[1]));
                  }));
    }
    var match = params.hd;
    var param = match[0];
    return Curry._3(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: ": ",
                        _1: {
                          TAG: /* String */2,
                          _0: /* No_padding */0,
                          _1: {
                            TAG: /* String */2,
                            _0: /* No_padding */0,
                            _1: /* End_of_format */0
                          }
                        }
                      }
                    },
                    _1: "%s: %s%s"
                  }), param, param, commentWithSpace(match[1]));
  };
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v>",
                _1: /* End_of_format */0
              },
              _1: "<v>"
            }
          },
          _1: /* End_of_format */0
        },
        _1: "@[<v>"
      });
  var argsLength = Belt_List.length($$arguments) + Belt_List.length(propertyGroupsArgs) | 0;
  if (argsLength > 1) {
    Curry._5(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* Formatting_gen */18,
                _0: {
                  TAG: /* Open_box */1,
                  _0: /* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "<v 2>",
                      _1: /* End_of_format */0
                    },
                    _1: "<v 2>"
                  }
                },
                _1: {
                  TAG: /* String_literal */11,
                  _0: "Avo.",
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* Char_literal */12,
                      _0: /* '(' */40,
                      _1: {
                        TAG: /* Alpha */15,
                        _0: {
                          TAG: /* Alpha */15,
                          _0: {
                            TAG: /* Formatting_lit */17,
                            _0: /* Close_box */0,
                            _1: {
                              TAG: /* Formatting_lit */17,
                              _0: {
                                TAG: /* Break */0,
                                _0: "@,",
                                _1: 0,
                                _2: 0
                              },
                              _1: {
                                TAG: /* Char_literal */12,
                                _0: /* ')' */41,
                                _1: /* End_of_format */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              },
              _1: "@[<v 2>Avo.%s(%a%a@]@,)"
            }), identifier$9(undefined, eventCodegenName($$event$1)), (function (param) {
            return function (param$1) {
              var param$2 = "/*";
              var param$3 = "*/";
              return printPropertyGroupArguments(param, param$1, param$2, param$3);
            };
          }), propertyGroupsArgs, printArguments, $$arguments);
  } else {
    Curry._5(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "Avo.",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* Char_literal */12,
                    _0: /* '(' */40,
                    _1: {
                      TAG: /* Alpha */15,
                      _0: {
                        TAG: /* Alpha */15,
                        _0: {
                          TAG: /* Char_literal */12,
                          _0: /* ')' */41,
                          _1: /* End_of_format */0
                        }
                      }
                    }
                  }
                }
              },
              _1: "Avo.%s(%a%a)"
            }), identifier$9(undefined, eventCodegenName($$event$1)), (function (param) {
            return function (param$1) {
              var param$2 = "/*";
              var param$3 = "*/";
              return printPropertyGroupArguments(param, param$1, param$2, param$3);
            };
          }), propertyGroupsArgs, printArguments, $$arguments);
  }
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_lit */17,
          _0: /* Close_box */0,
          _1: /* End_of_format */0
        },
        _1: "@]"
      });
  return Format.flush_str_formatter(undefined);
}

var Swift = {
  reservedKeywords: reservedKeywords$9,
  identifier: identifier$9,
  groupTypeVarName: groupTypeVarName$9,
  propertyVarName: propertyVarName$9,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$9
};

var reservedKeywords$10 = {
  hd: "abstract",
  tl: {
    hd: "as",
    tl: {
      hd: "base",
      tl: {
        hd: "bool",
        tl: {
          hd: "break",
          tl: {
            hd: "byte",
            tl: {
              hd: "case",
              tl: {
                hd: "catch",
                tl: {
                  hd: "char",
                  tl: {
                    hd: "checked",
                    tl: {
                      hd: "class",
                      tl: {
                        hd: "const",
                        tl: {
                          hd: "continue",
                          tl: {
                            hd: "decimal",
                            tl: {
                              hd: "default",
                              tl: {
                                hd: "delegate",
                                tl: {
                                  hd: "do ",
                                  tl: {
                                    hd: "double",
                                    tl: {
                                      hd: "else",
                                      tl: {
                                        hd: "enum",
                                        tl: {
                                          hd: "event",
                                          tl: {
                                            hd: "explicit",
                                            tl: {
                                              hd: "extern",
                                              tl: {
                                                hd: "false",
                                                tl: {
                                                  hd: "finally",
                                                  tl: {
                                                    hd: "fixed",
                                                    tl: {
                                                      hd: "float",
                                                      tl: {
                                                        hd: "for",
                                                        tl: {
                                                          hd: "forech",
                                                          tl: {
                                                            hd: "goto",
                                                            tl: {
                                                              hd: "if",
                                                              tl: {
                                                                hd: "implicit",
                                                                tl: {
                                                                  hd: "in",
                                                                  tl: {
                                                                    hd: "int",
                                                                    tl: {
                                                                      hd: "interface",
                                                                      tl: {
                                                                        hd: "internal",
                                                                        tl: {
                                                                          hd: "is ",
                                                                          tl: {
                                                                            hd: "lock",
                                                                            tl: {
                                                                              hd: "long",
                                                                              tl: {
                                                                                hd: "namespace",
                                                                                tl: {
                                                                                  hd: "new",
                                                                                  tl: {
                                                                                    hd: "null",
                                                                                    tl: {
                                                                                      hd: "object",
                                                                                      tl: {
                                                                                        hd: "operator",
                                                                                        tl: {
                                                                                          hd: "out",
                                                                                          tl: {
                                                                                            hd: "override",
                                                                                            tl: {
                                                                                              hd: "params",
                                                                                              tl: {
                                                                                                hd: "private",
                                                                                                tl: {
                                                                                                  hd: "protected",
                                                                                                  tl: {
                                                                                                    hd: "public",
                                                                                                    tl: {
                                                                                                      hd: "readonly",
                                                                                                      tl: {
                                                                                                        hd: "ref",
                                                                                                        tl: {
                                                                                                          hd: "return",
                                                                                                          tl: {
                                                                                                            hd: "sbyte",
                                                                                                            tl: {
                                                                                                              hd: "sealed",
                                                                                                              tl: {
                                                                                                                hd: "short",
                                                                                                                tl: {
                                                                                                                  hd: "sizeof",
                                                                                                                  tl: {
                                                                                                                    hd: "stackalloc",
                                                                                                                    tl: {
                                                                                                                      hd: "static",
                                                                                                                      tl: {
                                                                                                                        hd: "string",
                                                                                                                        tl: {
                                                                                                                          hd: "struct",
                                                                                                                          tl: {
                                                                                                                            hd: "switch",
                                                                                                                            tl: {
                                                                                                                              hd: "this",
                                                                                                                              tl: {
                                                                                                                                hd: "throw",
                                                                                                                                tl: {
                                                                                                                                  hd: "true",
                                                                                                                                  tl: {
                                                                                                                                    hd: "try",
                                                                                                                                    tl: {
                                                                                                                                      hd: "typeof",
                                                                                                                                      tl: {
                                                                                                                                        hd: "uint",
                                                                                                                                        tl: {
                                                                                                                                          hd: "ulong",
                                                                                                                                          tl: {
                                                                                                                                            hd: "unchecked",
                                                                                                                                            tl: {
                                                                                                                                              hd: "unsafe",
                                                                                                                                              tl: {
                                                                                                                                                hd: "ushort",
                                                                                                                                                tl: {
                                                                                                                                                  hd: "using",
                                                                                                                                                  tl: {
                                                                                                                                                    hd: "virtual",
                                                                                                                                                    tl: {
                                                                                                                                                      hd: "volatile",
                                                                                                                                                      tl: {
                                                                                                                                                        hd: "void",
                                                                                                                                                        tl: {
                                                                                                                                                          hd: "while",
                                                                                                                                                          tl: /* [] */0
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function identifier$10(caseOpt, name) {
  var $$case = caseOpt !== undefined ? caseOpt : Case.camel;
  var name$1 = Curry._1($$case, name);
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), name$1, List.exists((function (param) {
                    return name$1 === param;
                  }), reservedKeywords$10) ? "_" : "");
}

function groupTypeVarName$10(prefixOpt, groupTypeId, model) {
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return Curry._2(Printf.sprintf(/* Format */{
                  _0: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "%s%s"
                }), prefix, identifier$10((function (prim) {
                    return Case$1.pascal(prim);
                  }), StateUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupTypeId, model)));
}

function propertyVarName$10($$case, property) {
  var enrichedProperty = property.builtIn !== "NotBuiltIn";
  if (enrichedProperty) {
    return Curry._1(Printf.sprintf(/* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* Char_literal */12,
                        _0: /* '_' */95,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s_"
                  }), identifier$10($$case, propertyCodegenName(property)));
  } else {
    return identifier$10($$case, propertyCodegenName(property));
  }
}

function generateSnippetFromEnrichedModel$10($$event, model, source) {
  var $$event$1 = Belt_Option.getExn(StateUtils.getEventById($$event.id, model));
  var inputProperties = filterPinnedProperties($$event$1.properties, model.properties);
  var inputProperties$1 = AvoModel.resolveProperties(model, inputProperties);
  var partial_arg = Case.camel;
  var $$arguments = snippetArguments(inputProperties$1, (function (eta) {
          return propertyVarName$10(undefined, eta);
        }), $$event$1, model, groupTypeVarName$10, (function (param) {
          return identifier$10(partial_arg, param);
        }), /* Format */{
        _0: {
          TAG: /* String */2,
          _0: /* No_padding */0,
          _1: {
            TAG: /* String_literal */11,
            _0: ": ",
            _1: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: /* End_of_format */0
            }
          }
        },
        _1: "%s: %s"
      }, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "/* ",
          _1: {
            TAG: /* String */2,
            _0: /* No_padding */0,
            _1: {
              TAG: /* String_literal */11,
              _0: " */",
              _1: /* End_of_format */0
            }
          }
        },
        _1: "/* %s */"
      }, source);
  var printArguments = function (ppf, params) {
    if (!params) {
      return ;
    }
    if (params.tl) {
      return Belt_List.forEach(params, (function (param) {
                    return Curry._2(Format.fprintf(ppf, /* Format */{
                                    _0: {
                                      TAG: /* Formatting_lit */17,
                                      _0: {
                                        TAG: /* Break */0,
                                        _0: "@,",
                                        _1: 0,
                                        _2: 0
                                      },
                                      _1: {
                                        TAG: /* String */2,
                                        _0: /* No_padding */0,
                                        _1: {
                                          TAG: /* Char_literal */12,
                                          _0: /* ',' */44,
                                          _1: {
                                            TAG: /* String */2,
                                            _0: /* No_padding */0,
                                            _1: /* End_of_format */0
                                          }
                                        }
                                      }
                                    },
                                    _1: "@,%s,%s"
                                  }), param[0], commentWithSpace(param[1]));
                  }));
    }
    var match = params.hd;
    return Curry._2(Format.fprintf(ppf, /* Format */{
                    _0: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: /* End_of_format */0
                      }
                    },
                    _1: "%s%s"
                  }), match[0], commentWithSpace(match[1]));
  };
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v>",
                _1: /* End_of_format */0
              },
              _1: "<v>"
            }
          },
          _1: /* End_of_format */0
        },
        _1: "@[<v>"
      });
  if (Belt_List.length($$arguments) > 1) {
    Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* Formatting_gen */18,
                _0: {
                  TAG: /* Open_box */1,
                  _0: /* Format */{
                    _0: {
                      TAG: /* String_literal */11,
                      _0: "<v 2>",
                      _1: /* End_of_format */0
                    },
                    _1: "<v 2>"
                  }
                },
                _1: {
                  TAG: /* String_literal */11,
                  _0: "Avo.",
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* Char_literal */12,
                      _0: /* '(' */40,
                      _1: {
                        TAG: /* Alpha */15,
                        _0: {
                          TAG: /* Formatting_lit */17,
                          _0: /* Close_box */0,
                          _1: {
                            TAG: /* Formatting_lit */17,
                            _0: {
                              TAG: /* Break */0,
                              _0: "@,",
                              _1: 0,
                              _2: 0
                            },
                            _1: {
                              TAG: /* String_literal */11,
                              _0: ");",
                              _1: /* End_of_format */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              },
              _1: "@[<v 2>Avo.%s(%a@]@,);"
            }), identifier$10(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
  } else {
    Curry._3(Format.fprintf(Format.str_formatter, /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "Avo.",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* Char_literal */12,
                    _0: /* '(' */40,
                    _1: {
                      TAG: /* Alpha */15,
                      _0: {
                        TAG: /* String_literal */11,
                        _0: ");",
                        _1: /* End_of_format */0
                      }
                    }
                  }
                }
              },
              _1: "Avo.%s(%a);"
            }), identifier$10(undefined, eventCodegenName($$event$1)), printArguments, $$arguments);
  }
  Format.fprintf(Format.str_formatter, /* Format */{
        _0: {
          TAG: /* Formatting_lit */17,
          _0: /* Close_box */0,
          _1: /* End_of_format */0
        },
        _1: "@]"
      });
  return Format.flush_str_formatter(undefined);
}

var CSharp = {
  reservedKeywords: reservedKeywords$10,
  identifier: identifier$10,
  groupTypeVarName: groupTypeVarName$10,
  propertyVarName: propertyVarName$10,
  generateSnippetFromEnrichedModel: generateSnippetFromEnrichedModel$10
};

function className(language, name) {
  if (language === "java") {
    return identifier$6(undefined, name);
  } else if (language === "objc") {
    return identifier$8(undefined, name);
  } else if (language === "js" || language === "js_v2") {
    return identifier(undefined, name);
  } else if (language === "re" || language === "re_v2") {
    return identifier$1(undefined, name);
  } else if (language === "cs") {
    return identifier$10(undefined, name);
  } else if (language === "kt") {
    return identifier$7(undefined, name);
  } else if (language === "py3" || language === "py") {
    return identifier$4(undefined, name);
  } else if (language === "rb") {
    return identifier$3(undefined, name);
  } else if (language === "ts") {
    return identifier$2(undefined, name);
  } else if (language === "php") {
    return identifier$5(undefined, name);
  } else if (language === "swift") {
    return identifier$9(undefined, name);
  } else {
    return name;
  }
}

function eventName(language, $$event) {
  if (language === "swift" || language === "php" || language === "ts" || language === "rb" || language === "kt" || language === "cs" || language === "objc" || language === "java" || language === "js" || language === "js_v2" || language === "re" || language === "re_v2" || language === "py3" || language === "py") {
    return className(language, eventCodegenName($$event));
  } else {
    return className(language, $$event.name);
  }
}

function groupTypeVarName$11(language, prefix, groupType, enrichedModel) {
  if (language === "java") {
    return groupTypeVarName$6(prefix, groupType.id, enrichedModel);
  } else if (language === "php" || language === "rb" || language === "objc") {
    return groupType.name;
  } else if (language === "js" || language === "js_v2") {
    return groupTypeVarName(prefix, groupType.id, enrichedModel);
  } else if (language === "re" || language === "re_v2") {
    return groupTypeVarName$1(prefix, groupType.id, enrichedModel);
  } else if (language === "cs") {
    return groupTypeVarName$10(prefix, groupType.id, enrichedModel);
  } else if (language === "kt") {
    return groupTypeVarName$7(prefix, groupType.id, enrichedModel);
  } else if (language === "py3" || language === "py") {
    return groupTypeVarName$4(prefix, (function (prim) {
                  return Case$1.snake(prim);
                }), groupType.id, enrichedModel);
  } else if (language === "ts") {
    return groupTypeVarName$2(prefix, groupType.id, enrichedModel);
  } else if (language === "swift") {
    return groupTypeVarName$9(prefix, groupType.id, enrichedModel);
  } else {
    return groupType.name;
  }
}

function propertyName(language, property) {
  if (language === "java") {
    return propertyVarName$6(undefined, property);
  } else if (language === "objc") {
    return propertyVarName$8(undefined, property);
  } else if (language === "js" || language === "js_v2") {
    return propertyVarName(undefined, property);
  } else if (language === "re" || language === "re_v2") {
    return propertyVarName$1(undefined, property);
  } else if (language === "cs") {
    return propertyVarName$10(undefined, property);
  } else if (language === "kt") {
    return propertyVarName$7(undefined, property);
  } else if (language === "py3" || language === "py") {
    return propertyVarName$4(undefined, property);
  } else if (language === "rb") {
    return propertyVarName$3(undefined, property);
  } else if (language === "ts") {
    return propertyVarName$2(undefined, property);
  } else if (language === "php") {
    return propertyVarName$5(undefined, property);
  } else if (language === "swift") {
    return propertyVarName$9(undefined, property);
  } else {
    return property.name;
  }
}

function objectPropertyTypeSignature(parentPropertyId, nestedPropertyRefs, formatPropertyNameForLanguageOpt, model, language, eventIdOpt, param) {
  var formatPropertyNameForLanguage = formatPropertyNameForLanguageOpt !== undefined ? formatPropertyNameForLanguageOpt : true;
  var eventId = eventIdOpt !== undefined ? Caml_option.valFromOption(eventIdOpt) : undefined;
  return Belt_List.keepMap(nestedPropertyRefs, (function (nestedPropertyRef) {
                var maybeNestedProperty = StateUtils.resolvePropertyById(nestedPropertyRef.id, model);
                if (maybeNestedProperty === undefined) {
                  return ;
                }
                var isPinnedValue = eventId !== undefined ? Belt_Option.isSome(CodegenRules.getNestedPropertyPinnedValue(nestedPropertyRef, parentPropertyId, eventId, model)) : Belt_Option.isSome(nestedPropertyRef.pinnedValue);
                if (isPinnedValue) {
                  return ;
                }
                var displayedPropertyName = formatPropertyNameForLanguage ? propertyName(language, maybeNestedProperty) : maybeNestedProperty.name;
                return displayedPropertyName + ": " + maybeNestedProperty.type_;
              }));
}

function enrichModelForSnippet(serverTrackingWithDeviceIdOpt, skipImplementWithAvoFilteringOpt, model, source) {
  var serverTrackingWithDeviceId = serverTrackingWithDeviceIdOpt !== undefined ? serverTrackingWithDeviceIdOpt : false;
  var skipImplementWithAvoFiltering = skipImplementWithAvoFilteringOpt !== undefined ? skipImplementWithAvoFilteringOpt : false;
  var destinations = AvoModel.resolveDestinations(model, StateUtils.getSourceDestinationIds(source.destinations));
  var destinations$1 = AvoConfig.getValidDestinations({
        hd: TrackingPlanModel.Source.makeT(source),
        tl: /* [] */0
      }, destinations);
  return enrich(serverTrackingWithDeviceId, undefined, skipImplementWithAvoFiltering, model, source, destinations$1)[0];
}

function snippetWithEnrichedModel(enrichedModel, source, $$event) {
  var match = source.platform;
  var match$1 = source.language;
  if (match === "Gtm" && match$1 === "js_v2") {
    return generateSnippetGtmFromEnrichedModel($$event, enrichedModel, source);
  }
  if (match$1 === "java") {
    return generateSnippetFromEnrichedModel$6($$event, enrichedModel, source);
  } else if (match$1 === "objc") {
    return generateSnippetFromEnrichedModel$8($$event, enrichedModel, source);
  } else if (match$1 === "js_v2") {
    return generateSnippetFromEnrichedModel($$event, enrichedModel, source);
  } else if (match$1 === "re_v2") {
    return generateSnippetFromEnrichedModel$1($$event, enrichedModel, source);
  } else if (match$1 === "cs") {
    return generateSnippetFromEnrichedModel$10($$event, enrichedModel, source);
  } else if (match$1 === "kt") {
    return generateSnippetFromEnrichedModel$7($$event, enrichedModel, source);
  } else if (match$1 === "py3" || match$1 === "py") {
    return generateSnippetFromEnrichedModel$4($$event, enrichedModel, source);
  } else if (match$1 === "rb") {
    return generateSnippetFromEnrichedModel$3($$event, enrichedModel, source);
  } else if (match$1 === "ts") {
    return generateSnippetFromEnrichedModel$2($$event, enrichedModel, source);
  } else if (match$1 === "php") {
    return generateSnippetFromEnrichedModel$5($$event, enrichedModel, source);
  } else if (match$1 === "swift") {
    return generateSnippetFromEnrichedModel$9($$event, enrichedModel, source);
  } else {
    return "";
  }
}

function snippet(serverTrackingWithDeviceIdOpt, model, source, $$event) {
  var serverTrackingWithDeviceId = serverTrackingWithDeviceIdOpt !== undefined ? serverTrackingWithDeviceIdOpt : false;
  var enrichedModel = enrichModelForSnippet(serverTrackingWithDeviceId, undefined, model, source);
  return snippetWithEnrichedModel(enrichedModel, source, $$event);
}

function getMatches(property) {
  return Belt_List.headExn(Belt_List.keepMap(property.validations, (function (validation) {
                    if (typeof validation === "object" && validation.NAME === "Matches") {
                      return Belt_List.map(validation.VAL, (function (x) {
                                    var match = x[0];
                                    if (typeof match !== "object") {
                                      return Pervasives.failwith("unsupported literal");
                                    }
                                    if (match.NAME !== "Literal") {
                                      return Pervasives.failwith("unsupported literal");
                                    }
                                    var match$1 = match.VAL;
                                    if (typeof match$1 === "object" && match$1.NAME === "StringLit") {
                                      return match$1.VAL;
                                    } else {
                                      return Pervasives.failwith("unsupported literal");
                                    }
                                  }));
                    }
                    
                  })));
}

function headerComment(ppf, startCommentSymbol, version) {
  Curry._2(Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* String */2,
              _0: /* No_padding */0,
              _1: {
                TAG: /* String_literal */11,
                _0: " Generated by Avo VERSION ",
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* String_literal */11,
                    _0: ". You should never have to make changes to this file.",
                    _1: /* End_of_format */0
                  }
                }
              }
            },
            _1: "%s Generated by Avo VERSION %s. You should never have to make changes to this file."
          }), startCommentSymbol, version);
  Curry._1(Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* Formatting_lit */17,
              _0: {
                TAG: /* Break */0,
                _0: "@,",
                _1: 0,
                _2: 0
              },
              _1: {
                TAG: /* String */2,
                _0: /* No_padding */0,
                _1: {
                  TAG: /* String_literal */11,
                  _0: " If you find yourself in the situation where you have to edit the file please contact us at hi",
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Scan_indic */2,
                      _0: /* 'a' */97
                    },
                    _1: {
                      TAG: /* String_literal */11,
                      _0: "vo.app.",
                      _1: /* End_of_format */0
                    }
                  }
                }
              }
            },
            _1: "@,%s If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app."
          }), startCommentSymbol);
  return Curry._1(Format.fprintf(ppf, /* Format */{
                  _0: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: " If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.",
                        _1: /* End_of_format */0
                      }
                    }
                  },
                  _1: "@,%s If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically."
                }), startCommentSymbol);
}

function printEventTriggersDoc(startCommentSymbol, model, eventId, sourceId, schemaId, branchId, ppf) {
  var eventTriggers = StateUtils.getTriggersByEventId(eventId, model);
  var sourceTriggers = Belt_Array.keepU(eventTriggers, (function (trigger) {
          var sourceIds = trigger.sources;
          if (!sourceIds) {
            return true;
          }
          var sourceIds$1 = sourceIds._0;
          if (sourceIds$1.length !== 0) {
            return Belt_Array.someU(sourceIds$1, (function (triggerSourceId) {
                          return triggerSourceId === sourceId;
                        }));
          } else {
            return false;
          }
        }));
  if (Caml_obj.caml_notequal(sourceTriggers, [])) {
    Curry._2(Format.fprintf(ppf, /* Format */{
              _0: {
                TAG: /* Formatting_lit */17,
                _0: {
                  TAG: /* Break */0,
                  _0: "@,",
                  _1: 0,
                  _2: 0
                },
                _1: {
                  TAG: /* String */2,
                  _0: /* No_padding */0,
                  _1: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: {
                        TAG: /* String_literal */11,
                        _0: "When to trigger this event:",
                        _1: /* End_of_format */0
                      }
                    }
                  }
                }
              },
              _1: "@,%s@,%sWhen to trigger this event:"
            }), startCommentSymbol, startCommentSymbol);
    return Belt_Array.forEachWithIndexU(sourceTriggers, (function (index, trigger) {
                  var descriptionString = QuillToPlainText.quillToPlainText(trigger.description);
                  Curry._3(Format.fprintf(ppf, /* Format */{
                            _0: {
                              TAG: /* Formatting_lit */17,
                              _0: {
                                TAG: /* Break */0,
                                _0: "@,",
                                _1: 0,
                                _2: 0
                              },
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: {
                                  TAG: /* Int */4,
                                  _0: /* Int_d */0,
                                  _1: /* No_padding */0,
                                  _2: /* No_precision */0,
                                  _3: {
                                    TAG: /* String_literal */11,
                                    _0: ". ",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: /* End_of_format */0
                                    }
                                  }
                                }
                              }
                            },
                            _1: "@,%s%d. %s"
                          }), startCommentSymbol, index + 1 | 0, descriptionString);
                  return Curry._2(Format.fprintf(ppf, /* Format */{
                                  _0: {
                                    TAG: /* Formatting_lit */17,
                                    _0: {
                                      TAG: /* Break */0,
                                      _0: "@,",
                                      _1: 0,
                                      _2: 0
                                    },
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: {
                                        TAG: /* String_literal */11,
                                        _0: "View in Avo: ",
                                        _1: {
                                          TAG: /* String */2,
                                          _0: /* No_padding */0,
                                          _1: /* End_of_format */0
                                        }
                                      }
                                    }
                                  },
                                  _1: "@,%sView in Avo: %s"
                                }), startCommentSymbol, LinkUrls.triggerLink(schemaId, branchId, eventId, trigger.id));
                }));
  }
  
}

function eventTriggersWithIncludedSourcesIds(model, eventId, filterSourceIds) {
  var eventTriggers = StateUtils.getTriggersByEventId(eventId, model);
  return Belt_Array.keepU(Belt_Array.mapU(eventTriggers, (function (trigger) {
                    var triggerSourceIds = trigger.sources;
                    var tmp;
                    if (triggerSourceIds) {
                      var triggerSourceIds$1 = triggerSourceIds._0;
                      tmp = Belt_List.length(filterSourceIds) === 0 ? triggerSourceIds$1 : Belt_Array.keepMapU(triggerSourceIds$1, (function (triggerSourceId) {
                                if (Belt_List.someU(filterSourceIds, (function (sourceId) {
                                          return sourceId === triggerSourceId;
                                        }))) {
                                  return triggerSourceId;
                                }
                                
                              }));
                    } else {
                      tmp = Belt_List.toArray(Belt_List.mapU(model.sources, (function (source) {
                                  return source.id;
                                })));
                    }
                    return [
                            trigger,
                            tmp
                          ];
                  })), (function (param) {
                return param[1].length !== 0;
              }));
}

function eventTriggersWithIncludedSourcesNames(model, eventId, sourceIds) {
  var sourceName = function (sourceId) {
    var sourceName$1 = Belt_Option.getWithDefault(Belt_Option.flatMapU(StateUtils.getSourceById(sourceId, model), (function (source) {
                return source.name;
              })), "Unnamed source");
    if (sourceName$1 === "") {
      return "Unnamed source";
    } else {
      return sourceName$1;
    }
  };
  var eventTriggers = StateUtils.getTriggersByEventId(eventId, model);
  return Belt_Array.keepU(Belt_Array.mapU(eventTriggers, (function (trigger) {
                    var triggerSourceIds = trigger.sources;
                    return [
                            trigger,
                            triggerSourceIds ? Belt_Array.keepMapU(triggerSourceIds._0, (function (triggerSourceId) {
                                      if (Belt_List.someU(sourceIds, (function (sourceId) {
                                                return sourceId === triggerSourceId;
                                              }))) {
                                        return sourceName(triggerSourceId);
                                      }
                                      
                                    })) : ["all sources"]
                          ];
                  })), (function (param) {
                return param[1].length !== 0;
              }));
}

function eventTriggersForSourcesJson(model, eventId, filterSourceIds, schemaId, branchId) {
  var eventTriggersWithIncludedSourceIds = eventTriggersWithIncludedSourcesIds(model, eventId, filterSourceIds);
  return Belt_Array.mapU(eventTriggersWithIncludedSourceIds, (function (param) {
                var trigger = param[0];
                var imageUrl = Belt_Option.mapU(trigger.content, (function (content) {
                        return content._0.url;
                      }));
                var descriptionString = QuillToPlainText.quillToPlainText(trigger.description);
                return Json_encode.object_(Belt_List.concat({
                                hd: [
                                  "id",
                                  trigger.id
                                ],
                                tl: {
                                  hd: [
                                    "description",
                                    descriptionString
                                  ],
                                  tl: {
                                    hd: [
                                      "sourceIds",
                                      Json_encode.array((function (prim) {
                                              return prim;
                                            }), param[1])
                                    ],
                                    tl: {
                                      hd: [
                                        "link",
                                        LinkUrls.triggerLink(schemaId, branchId, eventId, trigger.id)
                                      ],
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }, imageUrl !== undefined ? ({
                                    hd: [
                                      "imageUrl",
                                      imageUrl
                                    ],
                                    tl: /* [] */0
                                  }) : /* [] */0));
              }));
}

function eventTriggersForSources(model, eventId, sourceIds, withNumber, schemaId, branchId) {
  var eventTriggersWithIncludedSourcesNames$1 = eventTriggersWithIncludedSourcesNames(model, eventId, sourceIds);
  return Belt_Array.mapWithIndexU(eventTriggersWithIncludedSourcesNames$1, (function (index, param) {
                var trigger = param[0];
                var descriptionString = QuillToPlainText.quillToPlainText(trigger.description);
                return (
                        withNumber ? Curry._2(Format.sprintf(/* Format */{
                                    _0: {
                                      TAG: /* Int */4,
                                      _0: /* Int_d */0,
                                      _1: /* No_padding */0,
                                      _2: /* No_precision */0,
                                      _3: {
                                        TAG: /* String_literal */11,
                                        _0: ". ",
                                        _1: {
                                          TAG: /* String */2,
                                          _0: /* No_padding */0,
                                          _1: /* End_of_format */0
                                        }
                                      }
                                    },
                                    _1: "%d. %s"
                                  }), index + 1 | 0, descriptionString) : Curry._1(Format.sprintf(/* Format */{
                                    _0: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: /* End_of_format */0
                                    },
                                    _1: "%s"
                                  }), descriptionString)
                      ) + (Curry._1(Format.sprintf(/* Format */{
                                  _0: {
                                    TAG: /* String_literal */11,
                                    _0: ". Trigger on ",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: /* End_of_format */0
                                    }
                                  },
                                  _1: ". Trigger on %s"
                                }), param[1].join(", ")) + Curry._1(Format.sprintf(/* Format */{
                                  _0: {
                                    TAG: /* String_literal */11,
                                    _0: ". View in Avo: ",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: /* End_of_format */0
                                    }
                                  },
                                  _1: ". View in Avo: %s"
                                }), LinkUrls.triggerLink(schemaId, branchId, eventId, trigger.id)));
              }));
}

function eventDescriptionWithTriggers(eventDescription, includedSources, model, eventId, schemaId, branchId) {
  var triggers = eventTriggersForSources(model, eventId, includedSources, true, schemaId, branchId);
  var triggersString = triggers.length !== 0 ? "Event triggers: " + triggers.join(". ") : "";
  var match = eventDescription.trim();
  var match$1 = triggersString.trim();
  if (match === "" && match$1 === "") {
    return "";
  }
  if (match$1 === "") {
    return match;
  } else if (match === "") {
    return match$1;
  } else if (match.endsWith(".")) {
    return match + (" " + match$1);
  } else {
    return match + (". " + match$1);
  }
}

function getPropertyVarInstance(msgOpt, properties, predicate) {
  var msg = msgOpt !== undefined ? msgOpt : "Property not found";
  var maybeProperty = Belt_List.getBy(properties, Curry.__1(predicate));
  if (maybeProperty !== undefined) {
    return Caml_option.valFromOption(maybeProperty);
  } else {
    return Js_exn.raiseError(msg);
  }
}

function getPropertyVarName(msgOpt, propertyVarName, properties, predicate) {
  var msg = msgOpt !== undefined ? msgOpt : "Property not found";
  var maybeProperty = Belt_List.getBy(properties, Curry.__1(predicate));
  if (maybeProperty !== undefined) {
    return Curry._1(propertyVarName, Caml_option.valFromOption(maybeProperty));
  } else {
    return Js_exn.raiseError(msg);
  }
}

function eventTriggersMarkdownForSources(model, eventId, sourceIds, withNumber, schemaId, branchId) {
  var eventTriggersWithIncludedSourcesNames$1 = eventTriggersWithIncludedSourcesNames(model, eventId, sourceIds);
  return Belt_Array.mapWithIndexU(eventTriggersWithIncludedSourcesNames$1, (function (index, param) {
                var triggerSources = param[1];
                var trigger = param[0];
                var descriptionString = QuillToPlainText.quillToPlainText(trigger.description);
                var match = descriptionString.trim();
                var tmp;
                tmp = withNumber ? (
                    match === "" ? Curry._2(Format.sprintf(/* Format */{
                                _0: {
                                  TAG: /* Int */4,
                                  _0: /* Int_d */0,
                                  _1: /* No_padding */0,
                                  _2: /* No_precision */0,
                                  _3: {
                                    TAG: /* String_literal */11,
                                    _0: ". _Triggered on ",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: {
                                        TAG: /* Char_literal */12,
                                        _0: /* '_' */95,
                                        _1: /* End_of_format */0
                                      }
                                    }
                                  }
                                },
                                _1: "%d. _Triggered on %s_"
                              }), index + 1 | 0, triggerSources.join(", ")) : Curry._3(Format.sprintf(/* Format */{
                                _0: {
                                  TAG: /* Int */4,
                                  _0: /* Int_d */0,
                                  _1: /* No_padding */0,
                                  _2: /* No_precision */0,
                                  _3: {
                                    TAG: /* String_literal */11,
                                    _0: ". ",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: {
                                        TAG: /* String_literal */11,
                                        _0: " \n  _Triggered on ",
                                        _1: {
                                          TAG: /* String */2,
                                          _0: /* No_padding */0,
                                          _1: {
                                            TAG: /* Char_literal */12,
                                            _0: /* '_' */95,
                                            _1: /* End_of_format */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                },
                                _1: "%d. %s \n  _Triggered on %s_"
                              }), index + 1 | 0, descriptionString, triggerSources.join(", "))
                  ) : (
                    match === "" ? Curry._1(Format.sprintf(/* Format */{
                                _0: {
                                  TAG: /* String_literal */11,
                                  _0: "_Triggered on ",
                                  _1: {
                                    TAG: /* String */2,
                                    _0: /* No_padding */0,
                                    _1: {
                                      TAG: /* Char_literal */12,
                                      _0: /* '_' */95,
                                      _1: /* End_of_format */0
                                    }
                                  }
                                },
                                _1: "_Triggered on %s_"
                              }), triggerSources.join(", ")) : Curry._2(Format.sprintf(/* Format */{
                                _0: {
                                  TAG: /* String */2,
                                  _0: /* No_padding */0,
                                  _1: {
                                    TAG: /* String_literal */11,
                                    _0: "  \n  _Triggered on ",
                                    _1: {
                                      TAG: /* String */2,
                                      _0: /* No_padding */0,
                                      _1: {
                                        TAG: /* Char_literal */12,
                                        _0: /* '_' */95,
                                        _1: /* End_of_format */0
                                      }
                                    }
                                  }
                                },
                                _1: "%s  \n  _Triggered on %s_"
                              }), descriptionString, triggerSources.join(", "))
                  );
                var match$1 = trigger.content;
                var tmp$1;
                var exit = 0;
                if (match$1 !== undefined && match$1.TAG === /* Image */0) {
                  tmp$1 = Curry._2(Format.sprintf(/* Format */{
                            _0: {
                              TAG: /* String_literal */11,
                              _0: "\n  ![](",
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: {
                                  TAG: /* String_literal */11,
                                  _0: "=w700)  \n  [View trigger in Avo](",
                                  _1: {
                                    TAG: /* String */2,
                                    _0: /* No_padding */0,
                                    _1: {
                                      TAG: /* Char_literal */12,
                                      _0: /* ')' */41,
                                      _1: /* End_of_format */0
                                    }
                                  }
                                }
                              }
                            },
                            _1: "\n  ![](%s=w700)  \n  [View trigger in Avo](%s)"
                          }), match$1._0.url, LinkUrls.triggerLink(schemaId, branchId, eventId, trigger.id));
                } else {
                  exit = 1;
                }
                if (exit === 1) {
                  tmp$1 = Curry._1(Format.sprintf(/* Format */{
                            _0: {
                              TAG: /* String_literal */11,
                              _0: "\n  View in Avo: ",
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: /* End_of_format */0
                              }
                            },
                            _1: "\n  View in Avo: %s"
                          }), LinkUrls.triggerLink(schemaId, branchId, eventId, trigger.id));
                }
                return tmp + tmp$1;
              }));
}

function eventDescriptionWithMarkdownTriggers(eventDescription, includedSources, model, eventId, schemaId, branchId) {
  var triggers = eventTriggersMarkdownForSources(model, eventId, includedSources, true, schemaId, branchId);
  var triggersString = triggers.length !== 0 ? "Triggers:\n" + triggers.join("\n") : "";
  var match = eventDescription.trim();
  var match$1 = triggersString.trim();
  var description;
  var exit = 0;
  if (match === "" && match$1 === "") {
    description = "";
  } else {
    exit = 1;
  }
  if (exit === 1) {
    description = match$1 === "" ? match : (
        match === "" ? match$1 : match + ("\n\n" + match$1)
      );
  }
  if (description === "") {
    return description;
  } else {
    return description + ("  \n\n_This description was published from [Avo](https://avo.app/schemas/" + schemaId + ")_");
  }
}

exports.hasShapeValidation = hasShapeValidation;
exports.hasMatchValidation = hasMatchValidation;
exports.hasNestedPropertyValidation = hasNestedPropertyValidation;
exports.nameMappingString = nameMappingString;
exports.shouldSendEventToSourceDestination = shouldSendEventToSourceDestination;
exports.shouldSendEventToDestinationType = shouldSendEventToDestinationType;
exports.propertyCodegenName = propertyCodegenName;
exports.prefixWithWordIfFirstNumber = prefixWithWordIfFirstNumber;
exports.printApiKey = printApiKey;
exports.prefixWithUnderscoreIfFirstNumber = prefixWithUnderscoreIfFirstNumber;
exports.eventCodegenName = eventCodegenName;
exports.propertyDocsFormat = propertyDocsFormat;
exports.eventDocsFormat = eventDocsFormat;
exports.eventLink = eventLink;
exports.propertyGroupLink = propertyGroupLink;
exports.stringReplaceByRe = stringReplaceByRe;
exports.stripTrailingWhiteSpace = stripTrailingWhiteSpace;
exports.escapeSingleQuotes = escapeSingleQuotes;
exports.escapeDoubleQuotes = escapeDoubleQuotes;
exports.commentWithSpace = commentWithSpace;
exports.printModuleMap = printModuleMap;
exports.printEventMap = printEventMap;
exports.classifyProperties = classifyProperties;
exports.filterOutGroupProperties = filterOutGroupProperties;
exports.classifyGroupPropertiesByGroupType = classifyGroupPropertiesByGroupType;
exports.filterPinnedProperties = filterPinnedProperties;
exports.getPinnedProperties = getPinnedProperties;
exports.printPinnedValueOption = printPinnedValueOption;
exports.printPinnedValueExn = printPinnedValueExn;
exports.enrichWithRef = enrichWithRef;
exports.getSnowplowSchemaPropertyRef = getSnowplowSchemaPropertyRef;
exports.unsafeGetSnowplowSchemaPropertyRef = unsafeGetSnowplowSchemaPropertyRef;
exports.printingFunctionToString = printingFunctionToString;
exports.enrich = enrich;
exports.printPropertyGroupArguments = printPropertyGroupArguments;
exports.resolveFunctionArguments = resolveFunctionArguments;
exports.printPropertyTypeInPseudocodeForCodegen = printPropertyTypeInPseudocodeForCodegen;
exports.printPropertyGroupTypeInPseudocode = printPropertyGroupTypeInPseudocode;
exports.getPropertyNameMaps = getPropertyNameMaps;
exports.snippetArguments = snippetArguments;
exports.JavaScript = JavaScript;
exports.Reason = Reason;
exports.TypeScript = TypeScript;
exports.Ruby = Ruby;
exports.Python = Python;
exports.PHP = PHP;
exports.Java = Java;
exports.Kotlin = Kotlin;
exports.ObjectiveC = ObjectiveC;
exports.Swift = Swift;
exports.CSharp = CSharp;
exports.className = className;
exports.eventName = eventName;
exports.groupTypeVarName = groupTypeVarName$11;
exports.propertyName = propertyName;
exports.objectPropertyTypeSignature = objectPropertyTypeSignature;
exports.enrichModelForSnippet = enrichModelForSnippet;
exports.snippetWithEnrichedModel = snippetWithEnrichedModel;
exports.snippet = snippet;
exports.getMatches = getMatches;
exports.headerComment = headerComment;
exports.printEventTriggersDoc = printEventTriggersDoc;
exports.eventTriggersWithIncludedSourcesIds = eventTriggersWithIncludedSourcesIds;
exports.eventTriggersWithIncludedSourcesNames = eventTriggersWithIncludedSourcesNames;
exports.eventTriggersForSourcesJson = eventTriggersForSourcesJson;
exports.eventTriggersForSources = eventTriggersForSources;
exports.eventDescriptionWithTriggers = eventDescriptionWithTriggers;
exports.getPropertyVarInstance = getPropertyVarInstance;
exports.getPropertyVarName = getPropertyVarName;
exports.eventTriggersMarkdownForSources = eventTriggersMarkdownForSources;
exports.eventDescriptionWithMarkdownTriggers = eventDescriptionWithMarkdownTriggers;
/* Case Not a pure module */
