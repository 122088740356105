// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Mantine = require("./Mantine.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var ModelStore = require("./ModelStore.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ProductTour = require("./ProductTour.bs.js");
var RouterStore = require("./RouterStore.bs.js");

function DemoBranchProductTour(Props) {
  var branch = RouterStore.Schema.useBranch(undefined);
  var tourMayShow;
  if (typeof branch === "object" && branch.NAME === "branch") {
    var branchId = branch.VAL;
    if (branchId.startsWith("demo-")) {
      var userId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
      tourMayShow = branchId === "demo-" + userId;
    } else {
      tourMayShow = false;
    }
  } else {
    tourMayShow = false;
  }
  var events = ModelStore.useEvents(undefined);
  var maybeDemoEvent = React.useMemo((function () {
          return Belt_List.getBy(events, (function (param) {
                        return param.name === "Onboarding Started (Demo)";
                      }));
        }), [events]);
  var drawerItems = RouterStore.Schema.useDrawerItems(undefined);
  return React.createElement(ProductTour.make, {
              onComplete: (function (param) {
                  return Router.Schema.pushSchemaRoute(undefined, undefined, "dashboard");
                }),
              tourId: "demo-branch-product-tour",
              tourMayShow: tourMayShow,
              withConfetti: true,
              children: null
            }, React.createElement(ProductTour.Step.make, {
                  stepId: "step-1",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "sidebar-tour-branches"
                  },
                  arrowOffset: 80,
                  delay: 500,
                  elementClickable: false,
                  onStepActive: (function (param) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, "events");
                    }),
                  position: "right-start",
                  transition: Mantine.Transition.subtleLeftSlide,
                  transitions: [{
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }],
                  transitionDuration: 400,
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(ProductTour.Step.make, {
                  stepId: "step-2",
                  stepType: {
                    TAG: /* Window */1,
                    _0: {
                      left: Css.px(260),
                      top: Css.px(113),
                      width: {
                        NAME: "substract",
                        VAL: [
                          Css.vw(100.0),
                          Css.px(270)
                        ]
                      },
                      height: Css.px(580)
                    }
                  },
                  offset: -180,
                  position: "bottom",
                  transition: Mantine.Transition.subtleUpSlide,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  transitionDuration: 400,
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(ProductTour.Step.make, {
                  stepId: "step-3",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "events-sheet-row-onboarding-started-demo"
                  },
                  arrowOffset: 80,
                  onClick: (function (transition) {
                      return Curry._1(transition, "next");
                    }),
                  onStepActive: (function (param) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, "events");
                    }),
                  position: "bottom-start",
                  simulateClick: {
                    NAME: "left",
                    VAL: Css.px(85)
                  },
                  transition: Mantine.Transition.subtleUpSlide,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  transitionDuration: 400,
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(ProductTour.Step.make, {
                  stepId: "step-4",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "drawer-container"
                  },
                  arrowOffset: 80,
                  delay: 550,
                  onStepActive: (function (param) {
                      if (drawerItems) {
                        var match = drawerItems.hd;
                        if (typeof match === "object" && match.NAME === "event") {
                          if (maybeDemoEvent === undefined) {
                            return ;
                          }
                          if (match.VAL[0] === maybeDemoEvent.id) {
                            return ;
                          }
                          
                        }
                        
                      }
                      if (maybeDemoEvent !== undefined) {
                        return Router.Schema.pushDrawerItem(undefined, {
                                    NAME: "event",
                                    VAL: [
                                      maybeDemoEvent.id,
                                      undefined,
                                      undefined,
                                      false
                                    ]
                                  });
                      }
                      
                    }),
                  position: "left",
                  transition: Mantine.Transition.subtleRightSlide,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  transitionDuration: 400,
                  children: React.createElement(ProductTour.GenericStep.make, {}),
                  key: Belt_Option.mapWithDefault(maybeDemoEvent, "not-found", (function (param) {
                          return param.id;
                        }))
                }), React.createElement(ProductTour.Step.make, {
                  stepId: "step-5",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "branch-tour-review"
                  },
                  arrowOffset: 80,
                  elementPadding: 6,
                  onClick: (function (transition) {
                      return Curry._1(transition, "next");
                    }),
                  onStepActive: (function (param) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, "events");
                    }),
                  position: "right-start",
                  simulateClick: {
                    NAME: "left",
                    VAL: Css.pct(40.0)
                  },
                  transition: Mantine.Transition.subtleLeftSlide,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  transitionDuration: 400,
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(ProductTour.Step.make, {
                  stepId: "step-6",
                  stepType: {
                    TAG: /* Window */1,
                    _0: {
                      left: Css.px(260),
                      top: Css.px(100),
                      width: Css.px(826),
                      height: {
                        NAME: "substract",
                        VAL: [
                          Css.vh(100.0),
                          Css.px(108)
                        ]
                      }
                    }
                  },
                  offset: -70,
                  onStepActive: (function (param) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                  NAME: "diff",
                                  VAL: "index"
                                });
                    }),
                  position: "right",
                  transition: Mantine.Transition.subtleLeftSlide,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  transitionDuration: 400,
                  waitForId: "branch-tour-changes",
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(ProductTour.Step.make, {
                  stepId: "step-7",
                  stepType: {
                    TAG: /* OnElement */0,
                    _0: "code-changes-tab"
                  },
                  elementPadding: 4,
                  onClick: (function (transition) {
                      return Curry._1(transition, "next");
                    }),
                  onStepActive: (function (param) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                  NAME: "diff",
                                  VAL: "index"
                                });
                    }),
                  position: "bottom",
                  simulateClick: "center",
                  transition: Mantine.Transition.subtleUpSlide,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  transitionDuration: 400,
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(ProductTour.Step.make, {
                  stepId: "step-8",
                  stepType: {
                    TAG: /* Window */1,
                    _0: {
                      left: Css.px(260),
                      top: Css.px(92),
                      width: {
                        NAME: "substract",
                        VAL: [
                          Css.vw(100.0),
                          Css.px(268)
                        ]
                      },
                      height: Css.px(336)
                    }
                  },
                  elementClickable: false,
                  onStepActive: (function (param) {
                      return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                  NAME: "diff",
                                  VAL: {
                                    NAME: "implementation",
                                    VAL: "overview"
                                  }
                                });
                    }),
                  position: "bottom",
                  transition: Mantine.Transition.subtleUpSlide,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "next",
                      label: undefined,
                      icon: "arrowRight"
                    }
                  ],
                  transitionDuration: 400,
                  waitForId: "branch-implementation-view",
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(ProductTour.Step.make, {
                  stepId: "step-9",
                  stepType: {
                    TAG: /* Window */1,
                    _0: {
                      left: Css.px(16),
                      top: Css.px(14),
                      width: Css.px(68),
                      height: Css.px(34)
                    }
                  },
                  onClick: (function (transition) {
                      return Curry._1(transition, "complete");
                    }),
                  position: "right",
                  simulateClick: "center",
                  transition: Mantine.Transition.subtleLeftSlide,
                  transitions: [
                    {
                      key: "previous",
                      label: undefined,
                      icon: "arrowLeft"
                    },
                    {
                      key: "complete",
                      label: "Finish!",
                      icon: undefined
                    }
                  ],
                  transitionDuration: 400,
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }));
}

var make = DemoBranchProductTour;

exports.make = make;
/* Css Not a pure module */
