// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var IconAvo = require("./IconAvo.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Intercom = require("./externals/intercom.bs.js");
var IconEmail = require("./IconEmail.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var FirebaseUtils = require("./firebaseUtils.bs.js");

function getRedirectUrl(schemaId) {
  return "https://avo.app/" + (
          schemaId !== undefined ? "schemas/" + schemaId + "/events/list" : "welcome"
        );
}

function actionCodeSettings(schemaId) {
  return {
          url: getRedirectUrl(schemaId)
        };
}

function setVerificationTrigger(user, schemaId) {
  var now = new Date();
  var dateKey = String(now.getFullYear()) + String(now.getMonth()) + String(now.getDate());
  var verifyTriggerRef = Firebase.app(undefined).firestore().collection("users").doc(user.uid).collection("verificationTriggers").doc(dateKey);
  return verifyTriggerRef.set({
              redirectUrl: getRedirectUrl(schemaId)
            });
}

function VerifyEmail(Props) {
  var user = Props.user;
  var schemaId = Props.schemaId;
  var auth = Firebase.getAuth(undefined);
  var match = React.useState(function () {
        return /* Loading */0;
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          $$Promise.$$catch(setVerificationTrigger(user, schemaId).then(function (param) {
                    AnalyticsRe.signUpVerifyRequested(undefined);
                    Curry._1(setState, (function (param) {
                            return /* Success */1;
                          }));
                    return Promise.resolve(undefined);
                  }), (function (_error) {
                  Curry._1(setState, (function (param) {
                          return /* Error */{
                                  _0: /* Unknown */0
                                };
                        }));
                  return Promise.resolve(undefined);
                }));
          
        }), []);
  var handleEmailVerification = function (param) {
    Curry._1(setState, (function (param) {
            return /* Loading */0;
          }));
    $$Promise.$$catch(user.sendEmailVerification(actionCodeSettings(schemaId)).then(function (param) {
              AnalyticsRe.signUpVerifySent(undefined);
              Curry._1(setState, (function (param) {
                      return /* Success */1;
                    }));
              return Promise.resolve(undefined);
            }), (function (error) {
            var match = error.code;
            if (match === "auth/too-many-requests") {
              Curry._1(setState, (function (param) {
                      return /* Error */{
                              _0: /* TooSoon */1
                            };
                    }));
            } else {
              Curry._1(setState, (function (param) {
                      return /* Error */{
                              _0: /* Unknown */0
                            };
                    }));
            }
            return Promise.resolve(undefined);
          }));
    
  };
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("spaceBetween"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.minHeight(Css.vh(100)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingTop(Css.px(50)),
                        tl: /* [] */0
                      })
                }, React.createElement(IconAvo.make, {
                      size: 100,
                      gray: true
                    })), React.createElement("div", undefined, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding2(Css.px(35), Css.px(50)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.maxWidth(Css.px(500)),
                                tl: {
                                  hd: Css.textAlign("center"),
                                  tl: {
                                    hd: Css.marginTop(Css.px(80)),
                                    tl: {
                                      hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                      tl: {
                                        hd: Css.borderRadius(Styles.Border.radius),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginTop(Css.px(-110)),
                                tl: /* [] */0
                              })
                        }, React.createElement(IconEmail.make, {})), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.padding2(Css.px(15), Css.px(0)),
                                tl: /* [] */0
                              })
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.huge),
                                      tl: {
                                        hd: Css.paddingBottom(Css.px(10)),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, "Please verify your email address"), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontWeight(Styles.FontWeight.regular),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.medium),
                                      tl: {
                                        hd: Css.padding2(Css.px(5), Css.px(0)),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, "We need to verify that ", React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: /* [] */0
                                      })
                                }, user.email), " is your email address before continuing.")), typeof state === "number" ? null : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                color: Styles.Color.grapeError,
                                children: state._0 ? "It looks like an email might already be on the way. Try again in 1 minute if you haven't received it." : "Hmm... we could not send the verification email."
                              }), React.createElement(Spacer.make, {
                                height: 8
                              }), React.createElement(Button.make, {
                                label: "Retry",
                                onClick: handleEmailVerification
                              }))), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.textAlign("center"),
                            tl: {
                              hd: Css.marginTop(Css.px(25)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.regular),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: {
                                    hd: Css.color(Styles.Color.grey70),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement("span", undefined, "Did not receive the email? "), React.createElement("a", {
                              href: "#",
                              onClick: (function ($$event) {
                                  $$event.preventDefault();
                                  return handleEmailVerification(undefined);
                                })
                            }, "Resend")), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.regular),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: {
                                    hd: Css.color(Styles.Color.grey70),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, user.email + " not your email address? ", React.createElement("a", {
                              href: "#",
                              onClick: (function ($$event) {
                                  $$event.preventDefault();
                                  return FirebaseUtils.signOut(auth);
                                })
                            }, "Log out"), " or ", React.createElement("a", {
                              href: "#",
                              onClick: (function ($$event) {
                                  $$event.preventDefault();
                                  return Intercom.showNewMessage(undefined);
                                })
                            }, "contact us"), "."))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.px(100)),
                        tl: /* [] */0
                      })
                }));
}

var make = VerifyEmail;

exports.getRedirectUrl = getRedirectUrl;
exports.actionCodeSettings = actionCodeSettings;
exports.setVerificationTrigger = setVerificationTrigger;
exports.make = make;
/* Css Not a pure module */
