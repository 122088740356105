// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Select = require("./Select.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Switch = require("./Switch.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var TextInput = require("./TextInput.bs.js");
var Workspace = require("../../model/src/Workspace.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var FirebaseUtils = require("./firebaseUtils.bs.js");
var PlanLightning = require("./PlanLightning.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var Debounce = require("lodash/debounce");
var AuthMethodPicker = require("./AuthMethodPicker.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: {
        hd: Css.overflow("auto"),
        tl: /* [] */0
      }
    });

function rowStyles(disabled) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("spaceBetween"),
                  tl: {
                    hd: Css.opacity(disabled ? 0.5 : 1),
                    tl: /* [] */0
                  }
                }
              }
            });
}

function subRowStyles(disabled) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("spaceBetween"),
                  tl: {
                    hd: Css.marginLeft(Css.px(8)),
                    tl: {
                      hd: Css.marginTop(Css.px(16)),
                      tl: {
                        hd: Css.paddingTop(Css.px(16)),
                        tl: {
                          hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey20),
                          tl: {
                            hd: Css.opacity(disabled ? 0.5 : 1),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var separatorStyles = Curry._1(Css.style, {
      hd: Css.height(Css.px(1)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.grey20),
        tl: {
          hd: Css.marginTop(Css.px(16)),
          tl: {
            hd: Css.marginBottom(Css.px(16)),
            tl: /* [] */0
          }
        }
      }
    });

function updateName(schemaId, newName, schemaGroup, addToast) {
  FirebaseUtils.setWorkspaceName(schemaId, newName).then(function (param) {
        AnalyticsRe.workspaceNameUpdated(schemaGroup, schemaId, newName);
        Curry._1(addToast, {
              message: "Workspace name successfully updated!",
              action: {
                message: "Undo",
                toastCallback: (function (param) {
                    FirebaseUtils.setWorkspaceName(schemaId, Belt_Option.getWithDefault(schemaGroup.schemaName, "Untitled Workspace"));
                    
                  })
              }
            });
        return Promise.resolve(undefined);
      });
  
}

var debouncedUpdateName = Debounce(updateName, 1000, {
      leading: false,
      trailing: true
    });

function WorkspacePermissions(Props) {
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var canForceBranchAudit = AvoLimits.ForceBranchAudit.computeAvailability(workspace);
  var canProtectMainBranch = AvoLimits.CanProtectMainBranch.computeAvailability(workspace);
  var hasAdvancedProtectedMainBranch = AvoLimits.AdvancedProtectedMainBranch.computeAvailability(workspace);
  var match = React.useState(function () {
        return workspace.name;
      });
  var setWorkspaceName = match[1];
  var workspaceName = match[0];
  React.useEffect((function () {
          if (workspace.name !== workspaceName) {
            Curry._1(setWorkspaceName, (function (param) {
                    return workspace.name;
                  }));
          }
          
        }), [workspace.name]);
  var isDisabled = canProtectMainBranch === "Unavailable" || hasAdvancedProtectedMainBranch === "Unavailable" || !workspace.settings.protectedMainBranch;
  var isDisabled$1 = canForceBranchAudit === "Unavailable";
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("div", {
                  className: rowStyles(false)
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.grey80,
                      children: "Workspace Name"
                    }), React.createElement(Spacer.make, {
                      width: 16,
                      grow: 1.0
                    }), React.createElement(TextInput.make, {
                      onChange: (function (newName) {
                          Curry._1(setWorkspaceName, (function (param) {
                                  return newName;
                                }));
                          return debouncedUpdateName(workspace.id, newName, schemaGroup, addToast);
                        }),
                      value: workspaceName
                    })), React.createElement("div", {
                  className: separatorStyles
                }), React.createElement("div", {
                  className: rowStyles(false)
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.grey80,
                      children: "Authentication method"
                    }), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), React.createElement(AuthMethodPicker.make, {
                      workspace: workspace
                    })), React.createElement("div", {
                  className: separatorStyles
                }), React.createElement("div", {
                  className: rowStyles(false)
                }, React.createElement("div", undefined, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.grey80,
                          children: "Protected main branch"
                        }), React.createElement(Spacer.make, {
                          height: 2
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Regular",
                          color: Styles.Color.grey70,
                          maxWidth: Css.px(280),
                          children: "Only admins can make changes directly on the main branch. Branches require approval to be merged."
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: /* [] */0
                            }
                          })
                    }, canProtectMainBranch === "Available" ? null : React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css_Legacy_Core.SVG.fill("transparent"),
                                  tl: {
                                    hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey50),
                                    tl: {
                                      hd: Css.marginRight(Css.px(8)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(PlanLightning.make, {
                                tooltipText: (
                                  canProtectMainBranch === "Unavailable" ? "Available " : "Available during trial and "
                                ) + ("on the " + (Workspace.printPlanName(AvoLimits.CanProtectMainBranch.availableOnPlan(workspace).name) + " plan"))
                              })), React.createElement(Switch.make, {
                          value: workspace.settings.protectedMainBranch,
                          onChange: (function (param) {
                              if (canProtectMainBranch === "Unavailable") {
                                return Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "BillingPrompt",
                                              VAL: "BranchApprovalWorkflows"
                                            }
                                          });
                              } else {
                                return Workspace.toggleSetting(workspace, Firebase.app(undefined), /* ProtectedMainBranch */0, schemaGroup);
                              }
                            }),
                          faded: canProtectMainBranch === "Unavailable"
                        }))), React.createElement(React.Fragment, undefined, React.createElement("div", {
                      className: subRowStyles(isDisabled)
                    }, React.createElement("div", undefined, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.grey80,
                              children: "Require admin approval"
                            }), React.createElement(Spacer.make, {
                              height: 2
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Regular",
                              color: Styles.Color.grey70,
                              maxWidth: Css.px(280),
                              children: "Only admins can approve branches."
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, hasAdvancedProtectedMainBranch === "Available" ? null : React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css_Legacy_Core.SVG.fill("transparent"),
                                      tl: {
                                        hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey50),
                                        tl: {
                                          hd: Css.marginRight(Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement(PlanLightning.make, {
                                    tooltipText: (
                                      hasAdvancedProtectedMainBranch === "Unavailable" ? "Available " : "Available during trial and "
                                    ) + ("on the " + (Workspace.printPlanName(AvoLimits.AdvancedProtectedMainBranch.availableOnPlan(workspace).name) + " plan"))
                                  })), React.createElement(Switch.make, {
                              value: workspace.settings.requireAdminApproval,
                              onChange: (function (param) {
                                  return Workspace.toggleSetting(workspace, Firebase.app(undefined), /* RequireAdminApproval */1, schemaGroup);
                                }),
                              disabled: isDisabled
                            }))), React.createElement("div", {
                      className: subRowStyles(isDisabled)
                    }, React.createElement("div", undefined, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.grey80,
                              children: "Number of required approvals"
                            }), React.createElement(Spacer.make, {
                              height: 2
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Regular",
                              color: Styles.Color.grey70,
                              maxWidth: Css.px(280),
                              children: "Number of approvals required to merge a branch."
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, hasAdvancedProtectedMainBranch === "Available" ? null : React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css_Legacy_Core.SVG.fill("transparent"),
                                      tl: {
                                        hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey50),
                                        tl: {
                                          hd: Css.marginRight(Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement(PlanLightning.make, {
                                    tooltipText: (
                                      hasAdvancedProtectedMainBranch === "Unavailable" ? "Available " : "Available during trial and "
                                    ) + ("on the " + (Workspace.printPlanName(AvoLimits.AdvancedProtectedMainBranch.availableOnPlan(workspace).name) + " plan"))
                                  })), React.createElement(Select.make, {
                              disabled: isDisabled,
                              onSelect: (function (value) {
                                  return Workspace.updateNumRequiredApprovals(workspace, Firebase.app(undefined), Caml_format.caml_int_of_string(value), schemaGroup);
                                }),
                              options: Belt_List.map(Belt_List.makeBy(Workspace.maxRequiredApprovals, (function (index) {
                                          return index + 1 | 0;
                                        })), (function (value) {
                                      return [
                                              {
                                                NAME: "Label",
                                                VAL: String(value)
                                              },
                                              String(value)
                                            ];
                                    })),
                              value: String(workspace.settings.numRequiredApprovals)
                            })))), React.createElement("div", {
                  className: separatorStyles
                }), React.createElement(React.Fragment, undefined, React.createElement("div", {
                      className: rowStyles(isDisabled$1)
                    }, React.createElement("div", undefined, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.grey80,
                              children: "Enforce branch audit pass"
                            }), React.createElement(Spacer.make, {
                              height: 2
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Regular",
                              color: Styles.Color.grey70,
                              maxWidth: Css.px(280),
                              children: "You won't be able to merge branches that introduce new audit issues"
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, canForceBranchAudit === "Available" ? null : React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css_Legacy_Core.SVG.fill("transparent"),
                                      tl: {
                                        hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey50),
                                        tl: {
                                          hd: Css.marginRight(Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement(PlanLightning.make, {
                                    tooltipText: (
                                      canProtectMainBranch === "Unavailable" ? "Available " : "Available during trial and "
                                    ) + ("on the " + (Workspace.printPlanName(AvoLimits.ForceBranchAudit.availableOnPlan(workspace).name) + " plan"))
                                  })), React.createElement(Switch.make, {
                              value: workspace.settings.forceBranchAudit,
                              onChange: (function (param) {
                                  if (canForceBranchAudit === "Unavailable") {
                                    return Curry._1(globalSend, {
                                                TAG: /* OpenModal */4,
                                                _0: {
                                                  NAME: "BillingPrompt",
                                                  VAL: "ForceBranchAudit"
                                                }
                                              });
                                  } else {
                                    return Workspace.toggleSetting(workspace, Firebase.app(undefined), /* ForceBranchAudit */3, schemaGroup);
                                  }
                                }),
                              faded: canForceBranchAudit === "Unavailable"
                            })))));
}

var make = WorkspacePermissions;

exports.rootStyles = rootStyles;
exports.rowStyles = rowStyles;
exports.subRowStyles = subRowStyles;
exports.separatorStyles = separatorStyles;
exports.updateName = updateName;
exports.debouncedUpdateName = debouncedUpdateName;
exports.make = make;
/* rootStyles Not a pure module */
