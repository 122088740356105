// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var eventRowHeight = 50;

var propertyRowHeight = 50;

var fixedColumns = 3;

exports.eventRowHeight = eventRowHeight;
exports.propertyRowHeight = propertyRowHeight;
exports.fixedColumns = fixedColumns;
/* No side effect */
