// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("rescript/lib/js/belt_List.js");
var StateUtils = require("./stateUtils.bs.js");

function getPropertyMatches(property) {
  return Belt_List.keepMap(StateUtils.getMatchesFromProperty(property), (function (param) {
                var item = param[0];
                if (typeof item !== "object") {
                  return ;
                }
                if (item.NAME !== "Literal") {
                  return ;
                }
                var match = item.VAL;
                if (typeof match === "object" && match.NAME === "StringLit") {
                  return match.VAL;
                }
                
              }));
}

function getMinInt(property) {
  return Belt_List.head(Belt_List.keepMap(property.validations, (function (validation) {
                    if (typeof validation !== "object") {
                      return ;
                    }
                    if (validation.NAME !== "Min") {
                      return ;
                    }
                    var match = validation.VAL;
                    if (typeof match === "object" && match.NAME === "IntLit") {
                      return match.VAL;
                    }
                    
                  })));
}

function getMaxInt(property) {
  return Belt_List.head(Belt_List.keepMap(property.validations, (function (validation) {
                    if (typeof validation !== "object") {
                      return ;
                    }
                    if (validation.NAME !== "Max") {
                      return ;
                    }
                    var match = validation.VAL;
                    if (typeof match === "object" && match.NAME === "IntLit") {
                      return match.VAL;
                    }
                    
                  })));
}

function getMinFloat(property) {
  return Belt_List.head(Belt_List.keepMap(property.validations, (function (validation) {
                    if (typeof validation !== "object") {
                      return ;
                    }
                    if (validation.NAME !== "Min") {
                      return ;
                    }
                    var match = validation.VAL;
                    if (typeof match === "object" && match.NAME === "FloatLit") {
                      return match.VAL;
                    }
                    
                  })));
}

function getMaxFloat(property) {
  return Belt_List.head(Belt_List.keepMap(property.validations, (function (validation) {
                    if (typeof validation !== "object") {
                      return ;
                    }
                    if (validation.NAME !== "Max") {
                      return ;
                    }
                    var match = validation.VAL;
                    if (typeof match === "object" && match.NAME === "FloatLit") {
                      return match.VAL;
                    }
                    
                  })));
}

function validatePinnedValue(pinnedValue, property) {
  var match = property.type_;
  switch (match) {
    case "bool" :
        if (typeof pinnedValue === "object") {
          return pinnedValue.NAME === "BooleanLit";
        } else {
          return false;
        }
    case "float" :
        if (typeof pinnedValue !== "object") {
          return false;
        }
        if (pinnedValue.NAME !== "FloatLit") {
          return false;
        }
        var value = pinnedValue.VAL;
        var min = getMinFloat(property);
        if (!(
            min !== undefined ? value >= min : true
          )) {
          return false;
        }
        var max = getMaxFloat(property);
        if (max !== undefined) {
          return value <= max;
        } else {
          return true;
        }
    case "int" :
        if (typeof pinnedValue !== "object") {
          return false;
        }
        if (pinnedValue.NAME !== "IntLit") {
          return false;
        }
        var value$1 = pinnedValue.VAL;
        var min$1 = getMinInt(property);
        if (!(
            min$1 !== undefined ? value$1 >= min$1 : true
          )) {
          return false;
        }
        var max$1 = getMaxInt(property);
        if (max$1 !== undefined) {
          return value$1 <= max$1;
        } else {
          return true;
        }
    case "string" :
        if (typeof pinnedValue !== "object") {
          return false;
        }
        if (pinnedValue.NAME !== "StringLit") {
          return false;
        }
        var value$2 = pinnedValue.VAL;
        var matches = getPropertyMatches(property);
        if (matches) {
          return Belt_List.some(matches, (function (match_) {
                        return match_ === value$2;
                      }));
        } else {
          return true;
        }
    default:
      return false;
  }
}

exports.getPropertyMatches = getPropertyMatches;
exports.getMinInt = getMinInt;
exports.getMaxInt = getMaxInt;
exports.getMinFloat = getMinFloat;
exports.getMaxFloat = getMaxFloat;
exports.validatePinnedValue = validatePinnedValue;
/* StateUtils Not a pure module */
