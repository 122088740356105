// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");

function Component(Item) {
  var scrollResultIntoView = function (resultRefs, focusIndex) {
    var maybeElement = Belt_List.getByU(resultRefs.contents, (function (param) {
            return Caml_obj.caml_equal(param[0], focusIndex);
          }));
    if (maybeElement !== undefined) {
      maybeElement[1].scrollIntoView({
            block: "nearest",
            inline: "nearest"
          });
      return ;
    }
    
  };
  var AutofillInput$Component = function (Props) {
    var onSelect = Props.onSelect;
    var onCreate = Props.onCreate;
    var placeholder = Props.placeholder;
    var search = Props.search;
    var renderItem = Props.renderItem;
    var refreshKey = Props.refreshKey;
    var autoFocusOpt = Props.autoFocus;
    var inputStylesOpt = Props.inputStyles;
    var resultsContainerStylesOpt = Props.resultsContainerStyles;
    var onEscapeOpt = Props.onEscape;
    var onUpdateOpt = Props.onUpdate;
    var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
    var inputStyles = inputStylesOpt !== undefined ? inputStylesOpt : /* [] */0;
    var resultsContainerStyles = resultsContainerStylesOpt !== undefined ? resultsContainerStylesOpt : /* [] */0;
    var onEscape = onEscapeOpt !== undefined ? onEscapeOpt : (function (param) {
          
        });
    var onUpdate = onUpdateOpt !== undefined ? onUpdateOpt : (function (param, param$1) {
          
        });
    var match = React.useState(function () {
          return "";
        });
    var setQuery = match[1];
    var query = match[0];
    var match$1 = React.useState(function () {
          return autoFocus;
        });
    var setHasFocus = match$1[1];
    var hasFocus = match$1[0];
    var match$2 = React.useState(function () {
          return 0;
        });
    var setFocusIndex = match$2[1];
    var focusIndex = match$2[0];
    var match$3 = React.useState(function () {
          return {
                  contents: /* [] */0
                };
        });
    var setResultRefs = match$3[1];
    var resultRefs = match$3[0];
    var results = Curry._1(search, query);
    var setFocusIndexTrimmed = function (newIndex, results) {
      var actionableResults = Belt_List.keepU(results, (function (result) {
              if (typeof result !== "object") {
                return false;
              }
              var variant = result.NAME;
              if (variant === "Item") {
                return true;
              } else {
                return variant === "CreateNew";
              }
            }));
      var newIndex$1 = Math.min(Math.max(0, newIndex), Belt_List.length(actionableResults) - 1 | 0);
      scrollResultIntoView(resultRefs, newIndex$1);
      return Curry._1(setFocusIndex, (function (param) {
                    return newIndex$1;
                  }));
    };
    React.useEffect((function () {
            var results = Curry._1(search, query);
            setFocusIndexTrimmed(focusIndex, results);
            Curry._2(onUpdate, query, results);
            Curry._1(setResultRefs, (function (param) {
                    return {
                            contents: /* [] */0
                          };
                  }));
            
          }), [
          query,
          refreshKey
        ]);
    React.useEffect((function () {
            var handleClick = function (param) {
              return Curry._1(setHasFocus, (function (param) {
                            return false;
                          }));
            };
            window.addEventListener("click", handleClick);
            return (function (param) {
                      window.removeEventListener("click", handleClick);
                      
                    });
          }), []);
    var handleInputKeyDow = function ($$event) {
      var match = $$event.key;
      var match$1 = $$event.shiftKey;
      var exit = 0;
      switch (match) {
        case "ArrowDown" :
            exit = 2;
            break;
        case "ArrowUp" :
            exit = 3;
            break;
        case "Enter" :
            if (hasFocus) {
              var match$2 = Belt_List.get(results, focusIndex);
              if (match$2 === undefined) {
                return ;
              }
              if (typeof match$2 !== "object") {
                return ;
              }
              var variant = match$2.NAME;
              if (variant === "CreateNew") {
                Curry._1(onCreate, match$2.VAL);
                return Curry._1(setQuery, (function (param) {
                              return "";
                            }));
              } else if (variant === "Item") {
                Curry._3(onSelect, match$2.VAL, query, focusIndex);
                return Curry._1(setQuery, (function (param) {
                              return "";
                            }));
              } else {
                return ;
              }
            }
            exit = 1;
            break;
        case "Escape" :
            Curry._1(setQuery, (function (param) {
                    return "";
                  }));
            return Curry._1(onEscape, undefined);
        case "Meta" :
        case "Shift" :
            return ;
        case "Tab" :
            if (match$1) {
              if (!hasFocus) {
                return ;
              }
              exit = 3;
            } else {
              if (!hasFocus) {
                return ;
              }
              exit = 2;
            }
            break;
        default:
          exit = 1;
      }
      switch (exit) {
        case 1 :
            return Curry._1(setHasFocus, (function (param) {
                          return true;
                        }));
        case 2 :
            $$event.preventDefault();
            return setFocusIndexTrimmed(focusIndex + 1 | 0, results);
        case 3 :
            $$event.preventDefault();
            return setFocusIndexTrimmed(focusIndex - 1 | 0, results);
        
      }
    };
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }),
                onClick: (function (prim) {
                    prim.stopPropagation();
                    
                  })
              }, React.createElement("input", {
                    className: Curry._1(Css.style, Belt_List.concatMany([
                              Styles.input,
                              {
                                hd: Css.display("block"),
                                tl: {
                                  hd: Css.width(Css.pct(100)),
                                  tl: {
                                    hd: Css.padding4(Css.px(10), Css.px(0), Css.px(10), Css.px(5)),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.regular),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.regular),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              },
                              inputStyles
                            ])),
                    autoFocus: autoFocus,
                    placeholder: placeholder,
                    value: query,
                    onKeyDown: handleInputKeyDow,
                    onChange: (function ($$event) {
                        var value = $$event.target.value;
                        return Curry._1(setQuery, (function (param) {
                                      return value;
                                    }));
                      }),
                    onClick: (function (param) {
                        return Curry._1(setHasFocus, (function (param) {
                                      return true;
                                    }));
                      })
                  }), hasFocus ? React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.zIndex(Styles.ZIndex.aboveAll),
                            tl: /* [] */0
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.merge, {
                                hd: Curry._1(Css.style, {
                                      hd: Css.position("absolute"),
                                      tl: {
                                        hd: Css.marginTop(Css.px(-1)),
                                        tl: {
                                          hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                          tl: {
                                            hd: Css.backgroundColor(Styles.Color.white),
                                            tl: {
                                              hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                                              tl: {
                                                hd: Css.borderBottomRightRadius(Styles.Border.radius),
                                                tl: {
                                                  hd: Css.overflow("hidden"),
                                                  tl: {
                                                    hd: Css.boxShadow(Styles.Shadow.box),
                                                    tl: {
                                                      hd: Css.width(Css.pct(100)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }),
                                tl: {
                                  hd: Curry._1(Css.style, resultsContainerStyles),
                                  tl: /* [] */0
                                }
                              }),
                          onClick: (function (prim) {
                              prim.stopPropagation();
                              
                            })
                        }, Belt_List.toArray(Belt_List.mapWithIndexU(results, (function (index, item) {
                                    return React.createElement("button", {
                                                ref: (function (resultRef) {
                                                    var refsWithoutResult = Belt_List.keepU(resultRefs.contents, (function (resultRef) {
                                                            return resultRef[0] !== index;
                                                          }));
                                                    resultRefs.contents = (resultRef == null) ? refsWithoutResult : Belt_List.concat(refsWithoutResult, {
                                                            hd: [
                                                              index,
                                                              resultRef
                                                            ],
                                                            tl: /* [] */0
                                                          });
                                                    
                                                  }),
                                                className: Curry._1(Css.merge, {
                                                      hd: Curry._1(Css.style, Styles.button),
                                                      tl: {
                                                        hd: Curry._1(Css.style, {
                                                              hd: Css.display("block"),
                                                              tl: {
                                                                hd: Css.width(Css.pct(100)),
                                                                tl: {
                                                                  hd: Css.textAlign("left"),
                                                                  tl: {
                                                                    hd: Css.fontSize(Styles.FontSize.regular),
                                                                    tl: {
                                                                      hd: Css.margin(Css.px(0)),
                                                                      tl: {
                                                                        hd: Css.padding(Css.px(0)),
                                                                        tl: {
                                                                          hd: Css.cursor(typeof item === "object" && item.NAME === "Message" ? "default" : "pointer"),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                onClick: (function (param) {
                                                    var variant = item.NAME;
                                                    if (variant === "CreateNew") {
                                                      Curry._1(onCreate, item.VAL);
                                                      return Curry._1(setQuery, (function (param) {
                                                                    return "";
                                                                  }));
                                                    } else if (variant === "Item") {
                                                      Curry._3(onSelect, item.VAL, query, index);
                                                      return Curry._1(setQuery, (function (param) {
                                                                    return "";
                                                                  }));
                                                    } else {
                                                      return ;
                                                    }
                                                  })
                                              }, Curry._2(renderItem, item, focusIndex === index));
                                  }))))) : null);
  };
  return {
          scrollResultIntoView: scrollResultIntoView,
          make: AutofillInput$Component
        };
}

exports.Component = Component;
/* Css Not a pure module */
