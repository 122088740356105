// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var JsDiff = require("./externals/JsDiff.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var IconSendAs = require("./IconSendAs.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

function getNameMappingNameAndDestination(rule, model) {
  return Belt_Option.getWithDefault(Belt_Option.map(rule, (function (rule) {
                    var match = rule.definition;
                    var name;
                    if (match.NAME === "NameMapping") {
                      var name$1 = match.VAL.name;
                      name = name$1 !== undefined ? name$1 : "";
                    } else {
                      name = "";
                    }
                    var match$1 = rule.definition;
                    var destination;
                    if (match$1.NAME === "NameMapping") {
                      var match$2 = match$1.VAL.destination;
                      destination = match$2 !== undefined ? (
                          typeof match$2 === "object" ? StateUtils.getDestinationNameById(model, match$2.VAL) : "all destinations"
                        ) : "";
                    } else {
                      destination = "";
                    }
                    return [
                            name,
                            destination
                          ];
                  })), [
              "",
              ""
            ]);
}

function DiffEventNameMapping(Props) {
  var actions = Props.actions;
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var removedNameMappings = Belt_List.keepMap(Belt_List.keepMapU(actions, (function (action) {
              if (typeof action !== "object") {
                return ;
              }
              if (action.NAME !== "RemoveRule") {
                return ;
              }
              var match = action.VAL;
              var match$1 = match[1];
              if (typeof match$1 === "object" && match$1.NAME === "Event") {
                return match[0];
              }
              
            })), (function (ruleId) {
          return Belt_Option.map(StateUtils.getRuleById(ruleId, masterModel), (function (rule) {
                        return {
                                change: /* Remove */1,
                                before: rule,
                                after: undefined
                              };
                      }));
        }));
  var addedNameMappings = Belt_List.keepMap(Belt_List.keepMapU(actions, (function (action) {
              if (typeof action !== "object") {
                return ;
              }
              if (action.NAME !== "AddRule") {
                return ;
              }
              var match = action.VAL;
              var match$1 = match[1];
              if (typeof match$1 !== "object") {
                return ;
              }
              if (match$1.NAME !== "Event") {
                return ;
              }
              var match$2 = match[2];
              if (typeof match$2 === "object" && match$2.NAME === "NameMapping") {
                return match[0];
              }
              
            })), (function (ruleId) {
          return Belt_Option.map(StateUtils.getRuleById(ruleId, branchModel), (function (rule) {
                        return {
                                change: /* Add */0,
                                before: undefined,
                                after: rule
                              };
                      }));
        }));
  var updatedNameMappings = Belt_List.keepMap(Belt_List.keepMapU(actions, (function (action) {
              if (typeof action !== "object") {
                return ;
              }
              if (action.NAME !== "UpdateRule") {
                return ;
              }
              var match = action.VAL;
              var match$1 = match[1];
              if (typeof match$1 !== "object") {
                return ;
              }
              if (match$1.NAME !== "Event") {
                return ;
              }
              var match$2 = match[2];
              if (typeof match$2 === "object" && match$2.NAME === "NameMapping") {
                return match[0];
              }
              
            })), (function (ruleId) {
          return Belt_Option.map(StateUtils.getRuleById(ruleId, branchModel), (function (rule) {
                        return {
                                change: /* Update */2,
                                before: StateUtils.getRuleById(rule.id, masterModel),
                                after: rule
                              };
                      }));
        }));
  var changes = Belt_List.concatMany([
        removedNameMappings,
        addedNameMappings,
        updatedNameMappings
      ]);
  if (changes === /* [] */0) {
    return null;
  } else {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("flexStart"),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.regular),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.padding2(Css.px(10), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.margin4(Css.px(2), Css.px(10), Css.px(0), Css.px(0)),
                          tl: /* [] */0
                        })
                  }, React.createElement(IconSendAs.make, {
                        size: 14
                      })), React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.paddingTop(Css.px(2)),
                          tl: /* [] */0
                        })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.grey80,
                        children: "Event name mapping"
                      }), React.createElement(Spacer.make, {
                        height: 4
                      }), Belt_List.toArray(Belt_List.map(changes, (function (param) {
                              var after = param.after;
                              var before = param.before;
                              var change = param.change;
                              var match = getNameMappingNameAndDestination(before, masterModel);
                              var destinationBefore = match[1];
                              var nameBefore = match[0];
                              var match$1 = getNameMappingNameAndDestination(after, branchModel);
                              var destinationAfter = match$1[1];
                              var nameAfter = match$1[0];
                              var tmp;
                              switch (change) {
                                case /* Add */0 :
                                    tmp = Styles.Color.mintGreenSecondary;
                                    break;
                                case /* Remove */1 :
                                    tmp = Styles.Color.grapeErrorSecondary;
                                    break;
                                case /* Update */2 :
                                    tmp = Styles.Color.grey90;
                                    break;
                                
                              }
                              var tmp$1;
                              switch (change) {
                                case /* Add */0 :
                                    tmp$1 = "Send as \"" + nameAfter + "\" to " + destinationAfter;
                                    break;
                                case /* Remove */1 :
                                    tmp$1 = "Send as \"" + nameBefore + "\" to " + destinationBefore;
                                    break;
                                case /* Update */2 :
                                    tmp$1 = JsDiff.renderStringDiff(undefined, true, true, "Send as \"" + nameBefore + "\" to " + destinationBefore, "Send as \"" + nameAfter + "\" to " + destinationAfter);
                                    break;
                                
                              }
                              return React.createElement("div", {
                                          key: before !== undefined ? before.id : (
                                              after !== undefined ? after.id : "none-none"
                                            ),
                                          className: Curry._1(Css.style, {
                                                hd: Css.padding2(Css.px(2), Css.px(0)),
                                                tl: /* [] */0
                                              })
                                        }, React.createElement($$Text.make, {
                                              size: "Small",
                                              weight: "Semi",
                                              color: tmp,
                                              children: tmp$1
                                            }));
                            })))));
  }
}

var make = DiffEventNameMapping;

exports.getNameMappingNameAndDestination = getNameMappingNameAndDestination;
exports.make = make;
/* Css Not a pure module */
