// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var $$String = require("rescript/lib/js/string.js");
var Styles = require("./styles.bs.js");
var Picture = require("./Picture.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

function rootStyles(withBorder, size) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.flexShrink(0.0),
                    tl: {
                      hd: Css.width(Css.px(size)),
                      tl: {
                        hd: Css.height(Css.px(size)),
                        tl: {
                          hd: Css.borderRadius(Css.px(size / 2 | 0)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.overflow("hidden"),
                              tl: {
                                hd: Css.position("relative"),
                                tl: withBorder ? ({
                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(1), undefined, Styles.Color.grey20)),
                                      tl: /* [] */0
                                    }) : /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function fallbackBackgroundStyles(userColor) {
  return Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.top(Css.px(0)),
                tl: {
                  hd: Css.right(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(0)),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.backgroundColor(userColor),
                        tl: {
                          hd: Css.opacity(0.1),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function fallbackStyles(size, userColor) {
  return Curry._1(Css.style, {
              hd: Css.fontSize(Css.px(size > 32 ? 14 : 12)),
              tl: {
                hd: Css.fontWeight(Styles.FontWeight.semi),
                tl: {
                  hd: Css.color(userColor),
                  tl: {
                    hd: Css.textTransform("uppercase"),
                    tl: /* [] */0
                  }
                }
              }
            });
}

function ProfilePhoto$Base(Props) {
  var withBorderOpt = Props.withBorder;
  var pictureOpt = Props.picture;
  var nameOpt = Props.name;
  var email = Props.email;
  var sizeOpt = Props.size;
  var withBorder = withBorderOpt !== undefined ? withBorderOpt : true;
  var picture = pictureOpt !== undefined ? Caml_option.valFromOption(pictureOpt) : undefined;
  var name = nameOpt !== undefined ? Caml_option.valFromOption(nameOpt) : undefined;
  var size = sizeOpt !== undefined ? sizeOpt : 28;
  var userColor = Styles.Color.getColorFromString(email);
  var tmp;
  if (picture !== undefined) {
    tmp = React.createElement(Picture.make, {
          picture: Caml_option.valFromOption(picture),
          width: size,
          height: size
        });
  } else {
    var title = Belt_Option.getWithDefault(name, email);
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: fallbackBackgroundStyles(userColor)
            }), React.createElement("span", {
              className: fallbackStyles(size, userColor)
            }, title.length > 2 ? $$String.sub(title, 0, 2) : title));
  }
  return React.createElement("div", {
              className: rootStyles(withBorder, size),
              title: email
            }, tmp);
}

var Base = {
  make: ProfilePhoto$Base
};

function ProfilePhoto(Props) {
  var withBorderOpt = Props.withBorder;
  var user = Props.user;
  var sizeOpt = Props.size;
  var withBorder = withBorderOpt !== undefined ? withBorderOpt : true;
  var size = sizeOpt !== undefined ? sizeOpt : 28;
  return React.createElement(ProfilePhoto$Base, {
              withBorder: withBorder,
              picture: Caml_option.nullable_to_opt(user.picture),
              name: Caml_option.nullable_to_opt(user.name),
              email: user.email,
              size: size
            });
}

function ProfilePhoto$FromUserId(Props) {
  var id = Props.id;
  var size = Props.size;
  var withBorder = Props.withBorder;
  var user = FirebaseFetcherHooks.useUser(id);
  if (typeof user !== "object") {
    if (user === "NotFound") {
      return null;
    } else {
      return React.createElement(ProfilePhoto$Base, {
                  email: "",
                  size: size
                });
    }
  }
  var user$1 = user.VAL;
  var tmp = {
    picture: Caml_option.nullable_to_opt(user$1.picture),
    name: Caml_option.nullable_to_opt(user$1.name),
    email: user$1.email,
    size: size
  };
  if (withBorder !== undefined) {
    tmp.withBorder = Caml_option.valFromOption(withBorder);
  }
  return React.createElement(ProfilePhoto$Base, tmp);
}

var FromUserId = {
  make: ProfilePhoto$FromUserId
};

var make = ProfilePhoto;

exports.rootStyles = rootStyles;
exports.fallbackBackgroundStyles = fallbackBackgroundStyles;
exports.fallbackStyles = fallbackStyles;
exports.Base = Base;
exports.make = make;
exports.FromUserId = FromUserId;
/* Css Not a pure module */
