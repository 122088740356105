// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Printer = require("./Printer.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var MappedModules = require("../../shared/MappedModules.bs.js");
var TrackingPlanModel = require("./TrackingPlanModel.bs.js");

function getId(property) {
  return property.id;
}

function eq(a, b) {
  return JSON.stringify(Printer.printProperty({
                  TAG: /* Property */1,
                  _0: a
                })) === JSON.stringify(Printer.printProperty({
                  TAG: /* Property */1,
                  _0: b
                }));
}

var Properties = MappedModules.MakeOrderedMapModule({
      getId: getId,
      eq: eq
    });

function getId$1(propertyBundle) {
  return propertyBundle.id;
}

function eq$1(a, b) {
  return JSON.stringify(Printer.printPropertyBundle(a)) === JSON.stringify(Printer.printPropertyBundle(b));
}

var PropertyBundles = MappedModules.MakeOrderedMapModule({
      getId: getId$1,
      eq: eq$1
    });

function getId$2($$event) {
  return $$event.id;
}

function eq$2(a, b) {
  return JSON.stringify(Printer.printEvent(a)) === JSON.stringify(Printer.printEvent(b));
}

var Events = MappedModules.MakeOrderedMapModule({
      getId: getId$2,
      eq: eq$2
    });

function getId$3(source) {
  return source.id;
}

function eq$3(a, b) {
  return JSON.stringify(Printer.printSource(a)) === JSON.stringify(Printer.printSource(b));
}

var OrderedMapModule = MappedModules.MakeOrderedMapModule({
      getId: getId$3,
      eq: eq$3
    });

function getName(source) {
  return Belt_Option.mapWithDefault(source.name, "Untitled Source", (function (name) {
                if (name === "") {
                  return "Untitled Source";
                } else {
                  return name;
                }
              }));
}

function getNameById(items, id) {
  return Belt_Option.map(Curry._2(OrderedMapModule.get, items, id), getName);
}

var toArray = OrderedMapModule.toArray;

var fromList = OrderedMapModule.fromList;

var empty = OrderedMapModule.empty;

var eq$4 = OrderedMapModule.eq;

var Sources_MapModule = OrderedMapModule.MapModule;

var Sources_get = OrderedMapModule.get;

var Sources_fromArray = OrderedMapModule.fromArray;

var Sources_keys = OrderedMapModule.keys;

var Sources_prepend = OrderedMapModule.prepend;

var Sources_append = OrderedMapModule.append;

var Sources_update = OrderedMapModule.update;

var Sources_updateMany = OrderedMapModule.updateMany;

var Sources_remove = OrderedMapModule.remove;

var Sources_reduce = OrderedMapModule.reduce;

var Sources_mapToArray = OrderedMapModule.mapToArray;

var Sources_mapToMap = OrderedMapModule.mapToMap;

var Sources_mapToT = OrderedMapModule.mapToT;

var Sources_keep = OrderedMapModule.keep;

var Sources_keepMap = OrderedMapModule.keepMap;

var Sources_has = OrderedMapModule.has;

var Sources_size = OrderedMapModule.size;

var Sources_reorder = OrderedMapModule.reorder;

var Sources = {
  OrderedMapModule: OrderedMapModule,
  getName: getName,
  getNameById: getNameById,
  MapModule: Sources_MapModule,
  get: Sources_get,
  toArray: toArray,
  fromArray: Sources_fromArray,
  fromList: fromList,
  keys: Sources_keys,
  empty: empty,
  prepend: Sources_prepend,
  append: Sources_append,
  update: Sources_update,
  updateMany: Sources_updateMany,
  remove: Sources_remove,
  reduce: Sources_reduce,
  mapToArray: Sources_mapToArray,
  mapToMap: Sources_mapToMap,
  mapToT: Sources_mapToT,
  keep: Sources_keep,
  keepMap: Sources_keepMap,
  has: Sources_has,
  size: Sources_size,
  reorder: Sources_reorder,
  eq: eq$4
};

function getId$4(destination) {
  return destination.id;
}

function eq$5(a, b) {
  return JSON.stringify(Printer.printDestination(a)) === JSON.stringify(Printer.printDestination(b));
}

var Destinations = MappedModules.MakeOrderedMapModule({
      getId: getId$4,
      eq: eq$5
    });

function getId$5(groupType) {
  return groupType.id;
}

function eq$6(a, b) {
  return JSON.stringify(Printer.printGroupType(a)) === JSON.stringify(Printer.printGroupType(b));
}

var GroupTypes = MappedModules.MakeOrderedMapModule({
      getId: getId$5,
      eq: eq$6
    });

function getId$6(item) {
  return item.id;
}

function eq$7(a, b) {
  return JSON.stringify(Printer.printGoal(a)) === JSON.stringify(Printer.printGoal(b));
}

var Categories = MappedModules.MakeOrderedMapModule({
      getId: getId$6,
      eq: eq$7
    });

function getId$7(metric) {
  return metric.id;
}

function eq$8(a, b) {
  return JSON.stringify(Printer.printMetric(a)) === JSON.stringify(Printer.printMetric(b));
}

var Metrics = MappedModules.MakeOrderedMapModule({
      getId: getId$7,
      eq: eq$8
    });

function getId$8(prim) {
  return prim[0];
}

function eq$9(a, b) {
  if (Caml_obj.caml_equal(a[0], b[0])) {
    return Caml_obj.caml_equal(a[1], b[1]);
  } else {
    return false;
  }
}

var OpenBranches = MappedModules.MakeOrderedMapModule({
      getId: getId$8,
      eq: eq$9
    });

function getId$9(integration) {
  return integration.id;
}

function eq$10(a, b) {
  return JSON.stringify(Printer.printIntegration(a)) === JSON.stringify(Printer.printIntegration(b));
}

var Integrations = MappedModules.MakeOrderedMapModule({
      getId: getId$9,
      eq: eq$10
    });

var empty_categories = Categories.empty;

var empty_destinations = Destinations.empty;

var empty_events = Events.empty;

var empty_groupTypes = GroupTypes.empty;

var empty_metrics = Metrics.empty;

var empty_properties = Properties.empty;

var empty_propertyBundles = PropertyBundles.empty;

var empty$1 = {
  categories: empty_categories,
  destinations: empty_destinations,
  events: empty_events,
  groupTypes: empty_groupTypes,
  metrics: empty_metrics,
  properties: empty_properties,
  propertyBundles: empty_propertyBundles,
  sources: empty
};

function fromOld(old) {
  return {
          categories: Curry._1(Categories.fromList, old.goals),
          destinations: Curry._1(Destinations.fromList, old.destinations),
          events: Curry._1(Events.fromList, old.events),
          groupTypes: Curry._1(GroupTypes.fromArray, old.groupTypes),
          metrics: Curry._1(Metrics.fromList, old.metrics),
          properties: Curry._1(Properties.fromList, Belt_List.keepMapU(old.properties, (function (property) {
                      if (property.TAG === /* PropertyRef */0) {
                        return ;
                      } else {
                        return property._0;
                      }
                    }))),
          propertyBundles: Curry._1(PropertyBundles.fromList, old.propertyBundles),
          sources: Curry._1(fromList, old.sources)
        };
}

function toOld(model) {
  return {
          events: Belt_List.fromArray(Curry._1(Events.toArray, model.events)),
          properties: Belt_List.fromArray(Belt_Array.mapU(Curry._1(Properties.toArray, model.properties), (function (property) {
                      return {
                              TAG: /* Property */1,
                              _0: property
                            };
                    }))),
          propertyBundles: Belt_List.fromArray(Curry._1(PropertyBundles.toArray, model.propertyBundles)),
          sources: Belt_List.fromArray(Curry._1(toArray, model.sources)),
          destinations: Belt_List.fromArray(Curry._1(Destinations.toArray, model.destinations)),
          goals: Belt_List.fromArray(Curry._1(Categories.toArray, model.categories)),
          metrics: Belt_List.fromArray(Curry._1(Metrics.toArray, model.metrics)),
          groupTypes: Curry._1(GroupTypes.toArray, model.groupTypes)
        };
}

var Archive = {
  empty: empty$1,
  fromOld: fromOld,
  toOld: toOld
};

var empty_migrations = [
  "ExplicitIncludeSource",
  "SourceDestinationMigration",
  "PropertyOptionalAndExcludedSourcesToAbsence"
];

var empty_rules = [];

var empty_types = [
  {
    TAG: /* BuiltInType */0,
    _0: "String"
  },
  {
    TAG: /* BuiltInType */0,
    _0: "Int"
  },
  {
    TAG: /* BuiltInType */0,
    _0: "Float"
  },
  {
    TAG: /* BuiltInType */0,
    _0: "Bool"
  }
];

var empty_categories$1 = Categories.empty;

var empty_destinations$1 = Destinations.empty;

var empty_events$1 = Events.empty;

var empty_groupTypes$1 = GroupTypes.empty;

var empty_integrations = Integrations.empty;

var empty_metrics$1 = Metrics.empty;

var empty_openBranches = OpenBranches.empty;

var empty_properties$1 = Properties.empty;

var empty_propertyBundles$1 = PropertyBundles.empty;

var empty$2 = {
  archive: empty$1,
  branchPointer: undefined,
  migrations: empty_migrations,
  rules: empty_rules,
  types: empty_types,
  categories: empty_categories$1,
  destinations: empty_destinations$1,
  events: empty_events$1,
  groupTypes: empty_groupTypes$1,
  integrations: empty_integrations,
  metrics: empty_metrics$1,
  openBranches: empty_openBranches,
  properties: empty_properties$1,
  propertyBundles: empty_propertyBundles$1,
  sources: empty
};

function fromOld$1(oldModel) {
  return {
          archive: fromOld(oldModel.archive),
          branchPointer: oldModel.branchPointer,
          migrations: Belt_List.toArray(oldModel.migrations),
          rules: Belt_List.toArray(oldModel.rules),
          types: Belt_List.toArray(oldModel.types),
          categories: Curry._1(Categories.fromList, oldModel.goals),
          destinations: Curry._1(Destinations.fromList, oldModel.destinations),
          events: Curry._1(Events.fromList, oldModel.events),
          groupTypes: Curry._1(GroupTypes.fromArray, oldModel.groupTypes),
          integrations: Curry._1(Integrations.fromList, oldModel.integrations),
          metrics: Curry._1(Metrics.fromList, oldModel.metrics),
          openBranches: Curry._1(OpenBranches.fromList, oldModel.openBranches),
          properties: Curry._1(Properties.fromList, Belt_List.keepMapU(oldModel.properties, (function (property) {
                      if (property.TAG === /* PropertyRef */0) {
                        return ;
                      } else {
                        return property._0;
                      }
                    }))),
          propertyBundles: Curry._1(PropertyBundles.fromList, oldModel.propertyBundles),
          sources: Curry._1(fromList, oldModel.sources)
        };
}

function toOld$1(model) {
  return {
          types: Belt_List.fromArray(model.types),
          properties: Belt_List.fromArray(Belt_Array.mapU(Curry._1(Properties.toArray, model.properties), (function (property) {
                      return {
                              TAG: /* Property */1,
                              _0: property
                            };
                    }))),
          propertyBundles: Belt_List.fromArray(Curry._1(PropertyBundles.toArray, model.propertyBundles)),
          events: Belt_List.fromArray(Curry._1(Events.toArray, model.events)),
          migrations: Belt_List.fromArray(model.migrations),
          sources: Belt_List.fromArray(Curry._1(toArray, model.sources)),
          destinations: Belt_List.fromArray(Curry._1(Destinations.toArray, model.destinations)),
          groupTypes: Curry._1(GroupTypes.toArray, model.groupTypes),
          goals: Belt_List.fromArray(Curry._1(Categories.toArray, model.categories)),
          metrics: Belt_List.fromArray(Curry._1(Metrics.toArray, model.metrics)),
          archive: toOld(model.archive),
          openBranches: Belt_List.fromArray(Curry._1(OpenBranches.toArray, model.openBranches)),
          branchPointer: model.branchPointer,
          rules: Belt_List.fromArray(model.rules),
          integrations: Belt_List.fromArray(Curry._1(Integrations.toArray, model.integrations))
        };
}

function unboxModel(modelType) {
  return modelType._0;
}

function eq$11(a, b) {
  if (Curry._2(Events.eq, a.events, b.events) && Curry._2(Properties.eq, a.properties, b.properties) && Curry._2(PropertyBundles.eq, a.propertyBundles, b.propertyBundles) && Curry._2(eq$4, a.sources, b.sources) && Curry._2(Destinations.eq, a.destinations, b.destinations) && Curry._2(Categories.eq, a.categories, b.categories) && Curry._2(Metrics.eq, a.metrics, b.metrics) && Curry._2(GroupTypes.eq, a.groupTypes, b.groupTypes) && Curry._2(Integrations.eq, a.integrations, b.integrations) && Curry._2(OpenBranches.eq, a.openBranches, b.openBranches) && Caml_obj.caml_equal(a.branchPointer, b.branchPointer) && Belt_Array.eqU(a.migrations, b.migrations, (function (a, b) {
            return TrackingPlanModel.migrationToJs(a) === TrackingPlanModel.migrationToJs(b);
          })) && Belt_Array.eqU(a.rules, b.rules, (function (a, b) {
            return Caml_obj.caml_equal(Printer.printRule(a), Printer.printRule(b));
          }))) {
    return Belt_Array.eqU(a.types, b.types, (function (a, b) {
                  return Caml_obj.caml_equal(Printer.printType(a), Printer.printType(b));
                }));
  } else {
    return false;
  }
}

exports.Properties = Properties;
exports.PropertyBundles = PropertyBundles;
exports.Events = Events;
exports.Sources = Sources;
exports.Destinations = Destinations;
exports.GroupTypes = GroupTypes;
exports.Categories = Categories;
exports.Metrics = Metrics;
exports.OpenBranches = OpenBranches;
exports.Integrations = Integrations;
exports.Archive = Archive;
exports.empty = empty$2;
exports.fromOld = fromOld$1;
exports.toOld = toOld$1;
exports.unboxModel = unboxModel;
exports.eq = eq$11;
/* Properties Not a pure module */
