// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Mantine = require("./Mantine.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ProfilePhoto = require("./ProfilePhoto.bs.js");
var Core = require("@mantine/core");
var Collaborators = require("./Collaborators.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var EditCollaboratorsPopup = require("./EditCollaboratorsPopup.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.paddingTop(Css.px(8)),
              tl: {
                hd: Css.paddingRight(Css.px(20)),
                tl: {
                  hd: Css.paddingBottom(Css.px(8)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(24)),
                    tl: {
                      hd: Css.minHeight(Css.px(62)),
                      tl: {
                        hd: Css.flexShrink(0.0),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var photosStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.marginLeft(Css.px(4)),
          tl: /* [] */0
        }
      }
    });

var photoStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(-4)),
      tl: /* [] */0
    });

function dropdownStyles(offset) {
  var variant = offset.NAME;
  var tmp;
  if (variant === "y") {
    tmp = {
      hd: Css.translateY(Css.px(offset.VAL)),
      tl: /* [] */0
    };
  } else if (variant === "xy") {
    var match = offset.VAL;
    tmp = {
      hd: Css.translateX(Css.px(match[0])),
      tl: {
        hd: Css.translateY(Css.px(match[1])),
        tl: /* [] */0
      }
    };
  } else {
    tmp = {
      hd: Css.translateX(Css.px(offset.VAL)),
      tl: /* [] */0
    };
  }
  return {
          hd: Css.borderRadius(Css.px(5)),
          tl: {
            hd: Css.padding2(Css.px(4), Css.px(0)),
            tl: {
              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                            NAME: "num",
                            VAL: 0.10
                          }))),
              tl: {
                hd: Css.transforms(tmp),
                tl: /* [] */0
              }
            }
          }
        };
}

function CollaboratorsView(Props) {
  var schemaId = Props.schemaId;
  var userId = Props.userId;
  var branchId = Props.branchId;
  var sendActions = Props.sendActions;
  var globalSend = GlobalSendContext.use(undefined);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var collaboratorIds = Collaborators.useCollaborators(schemaId, branchId);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = React.useState(function () {
        return false;
      });
  var setPopupOpen = match[1];
  var availability = AvoLimits.Collaboration.computeAvailability(workspace);
  var excessCount = Belt_List.length(collaboratorIds) - 8 | 0;
  var match$1 = excessCount <= 0 ? [
      collaboratorIds,
      0
    ] : [
      Belt_Option.getWithDefault(Belt_List.take(collaboratorIds, 7), collaboratorIds),
      excessCount + 1 | 0
    ];
  var notDisplayedCount = match$1[1];
  var collaboratorIdsToDisplay = match$1[0];
  var handleToggleCollaborator = function (collaborator, checked) {
    if (checked) {
      Collaborators.addCollaborator(schemaId, schemaGroup, userId, {
            NAME: "Branch",
            VAL: branchId
          }, "Branch", branchId, collaborator.id, collaborator.email, "AddCollaborator", sendActions);
    } else {
      Collaborators.removeCollaborator(schemaId, schemaGroup, userId, {
            NAME: "Branch",
            VAL: branchId
          }, "Branch", branchId, collaborator.id, sendActions);
    }
    
  };
  var tmp = {
    label: (
      collaboratorIds === /* [] */0 ? "Add" : "Edit"
    ) + " Collaborators",
    onClick: (function (param) {
        if (availability === "AvailableDuringTrial" || availability === "Available") {
          return Curry._1(setPopupOpen, (function (popupOpen) {
                        return !popupOpen;
                      }));
        } else {
          return Curry._1(globalSend, {
                      TAG: /* OpenModal */4,
                      _0: {
                        NAME: "BillingPrompt",
                        VAL: "AddBranchCollaborator"
                      }
                    });
        }
      }),
    style: "outline"
  };
  var tmp$1 = availability === "Available" ? undefined : "lightning";
  if (tmp$1 !== undefined) {
    tmp.icon = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("div", {
              className: rootStyles
            }, collaboratorIdsToDisplay ? React.createElement("div", {
                    className: photosStyles
                  }, Belt_List.toArray(Belt_List.map(collaboratorIdsToDisplay, (function (id) {
                              return React.createElement("div", {
                                          key: id,
                                          className: photoStyles
                                        }, React.createElement(ProfilePhoto.FromUserId.make, {
                                              id: id,
                                              size: 28
                                            }));
                            }))), notDisplayedCount !== 0 ? React.createElement("div", {
                          className: photoStyles
                        }, React.createElement(ProfilePhoto.Base.make, {
                              email: "+" + String(notDisplayedCount),
                              size: 28
                            })) : null) : React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.grey50,
                    children: "No Collaborators"
                  }), React.createElement(Spacer.make, {
                  width: 16
                }), React.createElement(Mantine.Popover.make, {
                  onChange: (function (popupOpen) {
                      return Curry._1(setPopupOpen, (function (param) {
                                    return popupOpen;
                                  }));
                    }),
                  opened: match[0],
                  position: "bottom-end",
                  children: null
                }, React.createElement(Core.Popover.Target, {
                      children: React.createElement(Button.make, tmp)
                    }), React.createElement(Mantine.Popover.Dropdown.make, {
                      additionalStyles: dropdownStyles({
                            NAME: "xy",
                            VAL: [
                              0,
                              0
                            ]
                          }),
                      children: React.createElement(EditCollaboratorsPopup.make, {
                            collaboratorIds: collaboratorIds,
                            onToggle: handleToggleCollaborator
                          })
                    })));
}

var make = CollaboratorsView;

exports.rootStyles = rootStyles;
exports.photosStyles = photosStyles;
exports.photoStyles = photoStyles;
exports.dropdownStyles = dropdownStyles;
exports.make = make;
/* rootStyles Not a pure module */
