// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var Input = require("./Input.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Styles = require("./styles.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var StripeModels = require("../../shared/models/StripeModels.bs.js");

function fetchCoupon(schemaId, value) {
  return Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).getIdToken().then(function (userToken) {
                  return fetch(Firebase.apiUrl + "/coupon" + (
                              process.env.NODE_ENV === "development" ? "?dev=true" : ""
                            ), Fetch.RequestInit.make(/* Post */2, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + userToken
                                    ]
                                  ], Caml_option.some(JSON.stringify(Js_dict.fromArray([
                                                [
                                                  "coupon",
                                                  value
                                                ],
                                                [
                                                  "schemaId",
                                                  schemaId
                                                ]
                                              ]))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                }).then(function (prim) {
                return prim.json();
              }).then(function (json) {
              return Promise.resolve(Json_decode.oneOf({
                              hd: (function (json) {
                                  return {
                                          TAG: /* Ok */0,
                                          _0: StripeModels.PromotionCode.decode(json)
                                        };
                                }),
                              tl: {
                                hd: (function (json) {
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              valid: Json_decode.field("valid", Json_decode.bool, json),
                                              error: Json_decode.field("error", Json_decode.string, json)
                                            }
                                          };
                                  }),
                                tl: /* [] */0
                              }
                            }, json));
            });
}

function StripeCouponInput(Props) {
  var schemaId = Props.schemaId;
  var onValidPromoCode = Props.onValidPromoCode;
  var match = React.useState(function () {
        return /* Collapsed */0;
      });
  var setStatus = match[1];
  var status = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setValue = match$1[1];
  var value = match$1[0];
  var handleApplyCoupon = function (param) {
    Curry._1(setStatus, (function (param) {
            return /* Loading */2;
          }));
    $$Promise.$$catch(fetchCoupon(schemaId, value).then(function (promoCodeResult) {
              if (promoCodeResult.TAG === /* Ok */0) {
                var match = promoCodeResult._0;
                var coupon = match.coupon;
                var amount = coupon.amount_off;
                var id = match.id;
                var percent = coupon.percent_off;
                if (percent !== undefined) {
                  Curry._1(setStatus, (function (param) {
                          return /* Valid */{
                                  _0: coupon.name,
                                  _1: {
                                    NAME: "Percent",
                                    VAL: percent
                                  }
                                };
                        }));
                  Curry._1(onValidPromoCode, id);
                } else if (amount !== undefined) {
                  Curry._1(setStatus, (function (param) {
                          return /* Valid */{
                                  _0: coupon.name,
                                  _1: {
                                    NAME: "Amount",
                                    VAL: amount
                                  }
                                };
                        }));
                  Curry._1(onValidPromoCode, id);
                } else {
                  Curry._1(setStatus, (function (param) {
                          return /* Invalid */3;
                        }));
                }
              } else {
                Curry._1(setStatus, (function (param) {
                        return /* Invalid */3;
                      }));
                console.error("coupon error:", promoCodeResult._0.error);
              }
              return Promise.resolve(undefined);
            }), (function (error) {
            Curry._1(setStatus, (function (param) {
                    return /* Invalid */3;
                  }));
            console.error("unexpected coupon error:", error);
            return Promise.resolve(undefined);
          }));
    
  };
  if (status === 0) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("flexEnd"),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement("button", {
                    className: Curry._1(Css.merge, {
                          hd: Curry._1(Css.style, Styles.button),
                          tl: {
                            hd: Curry._1(Css.style, {
                                  hd: Css.color(Styles.Color.blue),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.small),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.textAlign("right"),
                                        tl: {
                                          hd: Css.padding4(Css.px(2), Css.px(0), Css.px(8), Css.px(0)),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.color(Styles.Color.blueSecondary),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }),
                    onClick: (function (param) {
                        return Curry._1(setStatus, (function (param) {
                                      return /* Expanded */1;
                                    }));
                      })
                  }, "+ add coupon"));
  }
  var tmp;
  if (typeof status === "number") {
    tmp = status >= 3 ? React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.fontWeight(Styles.FontWeight.semi),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.small),
                    tl: {
                      hd: Css.color(Styles.Color.grapeError),
                      tl: {
                        hd: Css.textAlign("right"),
                        tl: /* [] */0
                      }
                    }
                  }
                })
          }, "Invalid coupon") : null;
  } else {
    var discount = status._1;
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.backgroundColor(Styles.Color.mintGreenBg),
                tl: {
                  hd: Css.color(Styles.Color.mintGreen),
                  tl: {
                    hd: Css.fontWeight(Styles.FontWeight.semi),
                    tl: {
                      hd: Css.fontSize(Styles.FontSize.regular),
                      tl: {
                        hd: Css.padding2(Css.px(15), Css.px(20)),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.textAlign("center"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              })
        }, status._0 + ": " + (
          discount.NAME === "Amount" ? "$" + String(discount.VAL) + " OFF" : String(discount.VAL) + "% OFF"
        ));
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("flexEnd"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginRight(Css.px(5)),
                            tl: {
                              hd: Css.maxWidth(Css.px(250)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(Input.make, {
                          value: value,
                          onChange: (function (value) {
                              return Curry._1(setValue, (function (param) {
                                            return value;
                                          }));
                            }),
                          autoFocus: true,
                          onEnter: (function ($$event) {
                              $$event.preventDefault();
                              return handleApplyCoupon(undefined);
                            }),
                          placeholder: "Coupon code",
                          className: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: /* [] */0
                          },
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              
                            })
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.height(Css.pct(100)),
                            tl: {
                              hd: Css.maxWidth(Css.px(100)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(Button.make, {
                          label: "Apply",
                          loading: status === /* Loading */2,
                          onClick: (function ($$event) {
                              $$event.preventDefault();
                              return handleApplyCoupon(undefined);
                            })
                        }))), tmp);
}

var make = StripeCouponInput;

exports.fetchCoupon = fetchCoupon;
exports.make = make;
/* Css Not a pure module */
