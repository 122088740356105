// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function Link(Props) {
  var path = Props.path;
  var className = Props.className;
  var onClick = Props.onClick;
  var disabledOpt = Props.disabled;
  var id = Props.id;
  var children = Props.children;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var newPath = Router.reParsePath(path);
  var tmp = {
    className: Belt_Option.mapWithDefault(className, Curry._1(Css.style, {
              hd: Css.textDecoration("none"),
              tl: /* [] */0
            }), (function (styles) {
            return styles + (" " + Curry._1(Css.style, {
                          hd: Css.textDecoration("none"),
                          tl: /* [] */0
                        }));
          })),
    href: newPath,
    onClick: (function ($$event) {
        var match = $$event.metaKey || $$event.shiftKey;
        if (disabled || match) {
          
        } else {
          $$event.preventDefault();
          Router.push(undefined, newPath);
        }
        return Belt_Option.mapWithDefault(onClick, undefined, (function (onClick) {
                      return Curry._1(onClick, $$event);
                    }));
      })
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement("a", tmp, children);
}

var make = Link;

exports.make = make;
/* Css Not a pure module */
