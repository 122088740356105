// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Parser = require("../../model/src/Parser.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Sharing = require("../../shared/models/Sharing.bs.js");
var Shortid = require("shortid");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var SsoChecks = require("./SsoChecks.bs.js");
var Workspace = require("../../model/src/Workspace.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var SavedViews = require("../../shared/models/SavedViews.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var ServiceAccount = require("../../shared/models/ServiceAccount.bs.js");
var Caml_splice_call = require("rescript/lib/js/caml_splice_call.js");
var BranchStateFirebaseModel = require("../../shared/models/BranchStateFirebaseModel.bs.js");
var InspectorVersionValidationIntent = require("./InspectorVersionValidationIntent.bs.js");

function transformAction(action) {
  action.contents = Parser.parseAction(action.contentsJson);
  return action;
}

function MakePagedStreamHook(Collection) {
  var sortItems = function (items) {
    return Belt_List.sort(items, (function (a, b) {
                  var match = Models.toDateOption(Curry._1(Collection.getCreatedAt, a));
                  var match$1 = Models.toDateOption(Curry._1(Collection.getCreatedAt, b));
                  if (match !== undefined && !(match$1 !== undefined && Caml_option.valFromOption(match).getTime() > Caml_option.valFromOption(match$1).getTime())) {
                    return -1;
                  } else {
                    return 1;
                  }
                }));
  };
  var dedupeItems = function (items) {
    return Belt_List.reduce(items, /* [] */0, (function (nextItems, item) {
                  if (Belt_List.has(nextItems, item, (function (a, b) {
                            return Curry._1(Collection.getId, a) === Curry._1(Collection.getId, b);
                          }))) {
                    return nextItems;
                  } else {
                    return Belt_List.concat(nextItems, {
                                hd: item,
                                tl: /* [] */0
                              });
                  }
                }));
  };
  var setup = function (state, send, goToId, collection, query, initialPageSize, disabled) {
    var now = new Date();
    if (disabled) {
      Promise.resolve(undefined);
    } else {
      $$Promise.$$catch(Belt_Option.mapWithDefault(goToId, Promise.resolve(undefined), (function (id) {
                    return $$Promise.$$catch(collection.doc(id).get().then(function (snapshot) {
                                    return Promise.resolve(snapshot.exists ? Caml_option.some(snapshot) : undefined);
                                  }), (function (error) {
                                  console.log("error", error);
                                  return Promise.resolve(undefined);
                                }));
                  })).then(function (maybeGoToSnapshot) {
                var initialPageSize$1 = Belt_Option.isSome(maybeGoToSnapshot) ? 1 : initialPageSize;
                var q = query !== undefined ? Curry._1(query, collection) : collection;
                var q$1 = maybeGoToSnapshot !== undefined ? q.startAfter(Caml_option.valFromOption(maybeGoToSnapshot)) : q.where("createdAt", "<", now);
                var q$2 = q$1.limit(initialPageSize$1 + 1 | 0);
                $$Promise.$$catch(q$2.get().then(function (snapshot) {
                          if (state.isMounted.contents) {
                            return Promise.resolve(Curry._1(send, {
                                            NAME: "PageSuccess",
                                            VAL: [
                                              snapshot,
                                              initialPageSize$1,
                                              "Initial"
                                            ]
                                          }));
                          } else {
                            return Promise.resolve(undefined);
                          }
                        }), (function (error) {
                        return Promise.resolve((console.log("error", error), undefined));
                      }));
                var q$3 = query !== undefined ? Curry._1(query, collection) : collection;
                var q$4 = maybeGoToSnapshot !== undefined ? q$3.endAt(Caml_option.valFromOption(maybeGoToSnapshot)) : q$3.where("createdAt", ">", now);
                var stream = q$4.onSnapshot((function (snapshot) {
                        if (state.isMounted.contents) {
                          return Curry._1(send, {
                                      NAME: "Stream",
                                      VAL: snapshot
                                    });
                        }
                        
                      }), (function (error) {
                        console.log("error", error);
                        
                      }));
                return Promise.resolve((state.subscriptions.contents = Belt_List.concat(state.subscriptions.contents, {
                                  hd: stream,
                                  tl: /* [] */0
                                }), undefined));
              }), (function (error) {
              return Promise.resolve((console.log("error", error), undefined));
            }));
    }
    
  };
  var usePagedStream = function (pageSizeOpt, initialPageSizeOpt, goToId, query, transform, checkPendingWrites, disabledOpt, collection) {
    var pageSize = pageSizeOpt !== undefined ? pageSizeOpt : 10;
    var initialPageSize = initialPageSizeOpt !== undefined ? initialPageSizeOpt : 3;
    var disabled = disabledOpt !== undefined ? disabledOpt : false;
    var match = React.useReducer((function (state, action) {
            if (typeof action !== "object") {
              if (action === "Page") {
                return {
                        items: state.items,
                        status: Belt_Option.isSome(state.nextCursor) ? "Loading" : state.status,
                        subscriptions: state.subscriptions,
                        lastPageStartPosition: state.lastPageStartPosition,
                        nextCursor: state.nextCursor,
                        isMounted: state.isMounted,
                        lastAction: action
                      };
              } else {
                return {
                        items: /* [] */0,
                        status: "Loading",
                        subscriptions: state.subscriptions,
                        lastPageStartPosition: undefined,
                        nextCursor: undefined,
                        isMounted: state.isMounted,
                        lastAction: undefined
                      };
              }
            }
            if (action.NAME === "PageSuccess") {
              var match = action.VAL;
              var pageSize = match[1];
              var docChanges = match[0].docChanges();
              var additions = Belt_Array.keep(docChanges, (function (change) {
                      return change.type === "added";
                    }));
              var hasMore = additions.length === (pageSize + 1 | 0);
              var match$1 = hasMore ? [
                  Belt_Array.slice(docChanges, 0, pageSize),
                  Belt_Option.map(Belt_Array.get(docChanges, pageSize), (function (change) {
                          return change.doc.data();
                        }))
                ] : [
                  docChanges,
                  undefined
                ];
              var nextCursor = match$1[1];
              var newItems = Belt_List.fromArray(Belt_Array.map(match$1[0], (function (change) {
                          return change.doc.data();
                        })));
              var newItems$1 = transform !== undefined ? Belt_List.map(newItems, transform) : newItems;
              var nextItems = sortItems(dedupeItems(Belt_List.concat(state.items, newItems$1)));
              return {
                      items: nextItems,
                      status: Belt_Option.isSome(nextCursor) ? "HasMore" : "Done",
                      subscriptions: state.subscriptions,
                      lastPageStartPosition: match[2] === "Initial" ? undefined : Belt_Option.mapWithDefault(Belt_List.head(newItems$1), undefined, (function (head) {
                                return Curry._1(Collection.getId, head);
                              })),
                      nextCursor: nextCursor,
                      isMounted: state.isMounted,
                      lastAction: action
                    };
            }
            var docs = Belt_Array.reduceU(action.VAL.docChanges(), state.items, (function (docs, change) {
                    var change$1 = change.type;
                    switch (change$1) {
                      case "added" :
                          var doc = change.doc.data();
                          var doc$1 = transform !== undefined ? Curry._1(transform, doc) : doc;
                          var doc$2;
                          if (checkPendingWrites !== undefined) {
                            var hasPendingWrites = change.doc.metadata.hasPendingWrites;
                            doc$2 = Curry._2(checkPendingWrites, doc$1, hasPendingWrites);
                          } else {
                            doc$2 = doc$1;
                          }
                          return {
                                  hd: doc$2,
                                  tl: docs
                                };
                      case "modified" :
                          var doc$3 = change.doc.data();
                          var id = Curry._1(Collection.getId, doc$3);
                          return Belt_List.mapU(docs, (function (d) {
                                        if (Curry._1(Collection.getId, d) === id) {
                                          if (transform !== undefined) {
                                            return Curry._1(transform, doc$3);
                                          } else {
                                            return doc$3;
                                          }
                                        } else {
                                          return d;
                                        }
                                      }));
                      case "removed" :
                          var id$1 = Curry._1(Collection.getId, change.doc.data());
                          return Belt_List.keepU(docs, (function (d) {
                                        return Curry._1(Collection.getId, d) !== id$1;
                                      }));
                      default:
                        console.log("unexpected change type", change$1);
                        return Pervasives.failwith("Invalid change type");
                    }
                  }));
            var nextItems$1 = sortItems(dedupeItems(docs));
            return {
                    items: nextItems$1,
                    status: state.status,
                    subscriptions: state.subscriptions,
                    lastPageStartPosition: state.lastPageStartPosition,
                    nextCursor: state.nextCursor,
                    isMounted: state.isMounted,
                    lastAction: action
                  };
          }), {
          items: /* [] */0,
          status: "Loading",
          subscriptions: {
            contents: /* [] */0
          },
          lastPageStartPosition: undefined,
          nextCursor: undefined,
          isMounted: {
            contents: true
          },
          lastAction: undefined
        });
    var send = match[1];
    var state = match[0];
    React.useEffect((function () {
            var match = state.lastAction;
            if (match !== undefined) {
              if (typeof match === "object") {
                if (match.NAME === "PageSuccess") {
                  var match$1 = match.VAL;
                  var docChanges = match$1[0].docChanges();
                  var newItems = Belt_List.fromArray(Belt_Array.map(docChanges, (function (change) {
                              return change.doc.data();
                            })));
                  var newItems$1 = transform !== undefined ? Belt_List.map(newItems, transform) : newItems;
                  var match$2 = Belt_List.head(newItems$1);
                  var match$3 = Belt_List.head(Belt_List.reverse(newItems$1));
                  if (match$2 !== undefined && match$3 !== undefined) {
                    var q = query !== undefined ? Curry._1(query, collection) : collection;
                    var q$1 = q.where("createdAt", "<=", Belt_Option.getExn(Models.toDateOption(Curry._1(Collection.getCreatedAt, Caml_option.valFromOption(match$2)))));
                    var q$2 = q$1.where("createdAt", ">=", Belt_Option.getExn(Models.toDateOption(Curry._1(Collection.getCreatedAt, Caml_option.valFromOption(match$3)))));
                    var q$3 = q$2.limit(match$1[1]);
                    state.subscriptions.contents = Belt_List.concat(state.subscriptions.contents, {
                          hd: q$3.onSnapshot((function (snapshot) {
                                  return Curry._1(send, {
                                              NAME: "Stream",
                                              VAL: snapshot
                                            });
                                }), (function (error) {
                                  console.log("error", error);
                                  
                                })),
                          tl: /* [] */0
                        });
                  }
                  
                }
                
              } else if (match === "Page") {
                var cursor = state.nextCursor;
                if (cursor !== undefined) {
                  var q$4 = collection.where("createdAt", "<=", Belt_Option.getExn(Models.toDateOption(Curry._1(Collection.getCreatedAt, Caml_option.valFromOption(cursor)))));
                  var q$5 = q$4.limit(pageSize + 1 | 0);
                  var q$6 = query !== undefined ? Curry._1(query, q$5) : q$5;
                  q$6.get().then(function (snapshot) {
                        return Curry._1(send, {
                                    NAME: "PageSuccess",
                                    VAL: [
                                      snapshot,
                                      pageSize,
                                      "Page"
                                    ]
                                  });
                      });
                }
                
              }
              
            }
            
          }), [state.lastAction]);
    React.useEffect((function () {
            setup(state, send, goToId, collection, query, initialPageSize, disabled);
            return (function (param) {
                      state.isMounted.contents = false;
                      return Belt_List.forEach(state.subscriptions.contents, (function (unsubscribe) {
                                    return unsubscribe();
                                  }));
                    });
          }), []);
    React.useEffect((function () {
            Belt_Option.forEach(goToId, (function (param) {
                    Belt_List.forEach(state.subscriptions.contents, (function (unsubscribe) {
                            return unsubscribe();
                          }));
                    setup(state, send, goToId, collection, query, initialPageSize, disabled);
                    return Curry._1(send, "ResetGoToId");
                  }));
            
          }), [
          goToId,
          disabled
        ]);
    return {
            items: state.items,
            status: state.status,
            lastPageStartPosition: state.lastPageStartPosition,
            getMore: (function (param) {
                return Curry._1(send, "Page");
              })
          };
  };
  return {
          sortItems: sortItems,
          dedupeItems: dedupeItems,
          setup: setup,
          usePagedStream: usePagedStream
        };
}

function useStreamCollection(collection, query, modeOpt, param) {
  var mode = modeOpt !== undefined ? modeOpt : "Fetch";
  var match = React.useState(function () {
        return /* [] */0;
      });
  var setDocs = match[1];
  React.useEffect((function () {
          if (mode === "Wait" || mode === "Skip") {
            return ;
          }
          var unsubscribe = Curry._1(query, collection).onSnapshot((function (snapshot) {
                  return Curry._1(setDocs, (function (param) {
                                return Belt_List.fromArray(Belt_Array.map(snapshot.docs, (function (prim) {
                                                  return prim.data();
                                                })));
                              }));
                }), (function (error) {
                  console.log("Failed to load", error);
                  
                }));
          return (function (param) {
                    return unsubscribe();
                  });
        }), [mode]);
  return match[0];
}

function useStreamCollectionArr(collection, query, modeOpt, decoder, param) {
  var mode = modeOpt !== undefined ? modeOpt : "Fetch";
  var match = React.useState(function () {
        return [];
      });
  var setDocs = match[1];
  React.useEffect((function () {
          if (mode === "Wait" || mode === "Skip") {
            return ;
          }
          var unsubscribe = Curry._1(query, collection).onSnapshot((function (snapshot) {
                  return Curry._1(setDocs, (function (param) {
                                return Belt_Array.map(snapshot.docs, (function (snap) {
                                              return Curry._1(decoder, snap.data());
                                            }));
                              }));
                }), (function (error) {
                  console.log("Failed to load", error);
                  
                }));
          return (function (param) {
                    return unsubscribe();
                  });
        }), [mode]);
  return match[0];
}

function useStreamCollectionWithProgress(collection, query, modeOpt, remountKey, param) {
  var mode = modeOpt !== undefined ? modeOpt : "Fetch";
  var match = React.useState(function () {
        return "Initial";
      });
  var setDocs = match[1];
  var match$1 = React.useState(function () {
        return remountKey;
      });
  var setLastRemountKey = match$1[1];
  React.useEffect((function () {
          Curry._1(setLastRemountKey, (function (param) {
                  return remountKey;
                }));
          
        }), [remountKey]);
  var buildSubscription = function (collection, query) {
    Curry._1(setDocs, (function (param) {
            return "Initial";
          }));
    return Curry._1(query, collection).onSnapshot((function (snapshot) {
                  return Curry._1(setDocs, (function (param) {
                                return {
                                        NAME: "Loaded",
                                        VAL: Belt_List.fromArray(Belt_Array.map(snapshot.docs, (function (prim) {
                                                    return prim.data();
                                                  })))
                                      };
                              }));
                }), (function (error) {
                  console.error("useStreamCollectionWithProgress: Failed to load:", error);
                  return Curry._1(setDocs, (function (param) {
                                return "Error";
                              }));
                }));
  };
  React.useEffect((function () {
          if (mode === "Wait" || mode === "Skip") {
            Curry._1(setDocs, (function (param) {
                    return "Initial";
                  }));
            return ;
          }
          var unsubscribe = buildSubscription(collection, query);
          return (function (param) {
                    return unsubscribe();
                  });
        }), [
        remountKey,
        mode
      ]);
  if (Caml_obj.caml_equal(remountKey, match$1[0])) {
    return match[0];
  } else {
    return "Initial";
  }
}

function useMultiQueryWithProgress(collection, queries) {
  var match = React.useState(function () {
        return "Initial";
      });
  var setDocs = match[1];
  var retainedQueries = React.useRef(queries);
  var compareQueries = React.useCallback((function (param) {
          return function (param$1) {
            if (param[0] === param$1[0] && param[1] === param$1[1]) {
              return param[2] === param$1[2];
            } else {
              return false;
            }
          };
        }), []);
  var $$fetch = function (removedQueriesOpt, queries, param) {
    var removedQueries = removedQueriesOpt !== undefined ? removedQueriesOpt : [];
    $$Promise.$$catch(Promise.all(Belt_Array.mapU(queries, (function (param) {
                      var hash = param[2];
                      var sourceId = param[1];
                      var eventId = param[0];
                      return Curry._1(param[3], collection).get().then(function (snapshot) {
                                  return [
                                          eventId,
                                          sourceId,
                                          hash,
                                          snapshot
                                        ];
                                });
                    }))).then(function (snapshots) {
              return Curry._1(setDocs, (function (state) {
                            var newItems = Caml_splice_call.spliceObjApply([], "concat", [Belt_Array.mapU(Belt_Array.keepU(snapshots, (function (param) {
                                              return Belt_Option.mapWithDefault(Belt_Array.get(param[3].docs, 0), false, (function (prim) {
                                                            return prim.exists;
                                                          }));
                                            })), (function (param) {
                                          var hash = param[2];
                                          var sourceId = param[1];
                                          var eventId = param[0];
                                          return Belt_Array.mapU(param[3].docs, (function (snap) {
                                                        return [
                                                                eventId,
                                                                sourceId,
                                                                hash,
                                                                snap.data()
                                                              ];
                                                      }));
                                        }))]);
                            if (typeof state === "object" && state.NAME === "Loaded") {
                              return {
                                      NAME: "Loaded",
                                      VAL: Belt_Array.keepU(state.VAL.concat(newItems), (function (param) {
                                              var hash = param[2];
                                              var sourceId = param[1];
                                              var eventId = param[0];
                                              return !Belt_Array.someU(removedQueries, (function (param) {
                                                            if (eventId === param[0] && sourceId === param[1]) {
                                                              return hash === param[2];
                                                            } else {
                                                              return false;
                                                            }
                                                          }));
                                            }))
                                    };
                            } else {
                              return {
                                      NAME: "Loaded",
                                      VAL: newItems
                                    };
                            }
                          }));
            }), (function (_error) {
            Curry._1(setDocs, (function (param) {
                    return "Error";
                  }));
            return Promise.resolve(undefined);
          }));
    
  };
  React.useEffect((function () {
          $$fetch(undefined, queries, undefined);
          
        }), []);
  React.useEffect((function () {
          if (!Belt_Array.eqU(queries, retainedQueries.current, Curry.__2(compareQueries))) {
            var fromQueries = retainedQueries.current;
            var newQueries = Belt_Array.keepU(queries, (function (toItem) {
                    return Belt_Array.everyU(fromQueries, (function (item) {
                                  return !Curry._2(compareQueries, item, toItem);
                                }));
                  }));
            var removedQueries = Belt_Array.keepU(fromQueries, (function (fromItem) {
                    return Belt_Array.everyU(queries, (function (item) {
                                  return !Curry._2(compareQueries, item, fromItem);
                                }));
                  }));
            $$fetch(removedQueries, newQueries, undefined);
          }
          
        }), [queries]);
  return match[0];
}

function usePagedQueryWithProgress(remountKey, transform, pageSizeOpt, collection, query, param) {
  var pageSize = pageSizeOpt !== undefined ? pageSizeOpt : 10;
  var match = React.useState(function () {
        return "Initial";
      });
  var setDocs = match[1];
  var docs = match[0];
  var $$fetch = function (clear) {
    var q = Curry._1(query, collection).limit(pageSize);
    var match;
    if (clear) {
      match = [
        q,
        []
      ];
    } else if (typeof docs === "object") {
      var match$1 = docs.VAL;
      var snap = match$1[0];
      match = snap !== undefined ? [
          q.startAfter(Caml_option.valFromOption(snap)),
          match$1[1]
        ] : [
          q,
          match$1[1]
        ];
    } else {
      match = [
        q,
        []
      ];
    }
    var result = match[0].get();
    var current = match[1];
    $$Promise.$$catch(result.then(function (snap) {
              var snaps = snap.docs;
              var items = Belt_Array.map(snaps, (function (snapshot) {
                      var doc = snapshot.data();
                      if (transform !== undefined) {
                        return Curry._1(transform, doc);
                      } else {
                        return doc;
                      }
                    }));
              var oldAndNew = Belt_Array.concat(current, items);
              Curry._1(setDocs, (function (param) {
                      if (items.length === pageSize) {
                        return {
                                NAME: "Loaded",
                                VAL: [
                                  Belt_Array.get(snaps, snaps.length - 1 | 0),
                                  oldAndNew
                                ]
                              };
                      } else {
                        return {
                                NAME: "Loaded",
                                VAL: [
                                  undefined,
                                  oldAndNew
                                ]
                              };
                      }
                    }));
              return Promise.resolve(undefined);
            }), (function (err) {
            console.log("Error: ", err);
            Curry._1(setDocs, (function (param) {
                    return "Error";
                  }));
            return Promise.resolve(undefined);
          }));
    
  };
  React.useEffect((function () {
          $$fetch(true);
          
        }), [remountKey]);
  return [
          docs,
          (function (param) {
              return $$fetch(false);
            })
        ];
}

function useDoc(collection, docId, listen) {
  var match = React.useState(function () {
        return "Loading";
      });
  var setDoc = match[1];
  React.useEffect((function () {
          $$Promise.$$catch(collection.doc(docId).get().then(function (snapshot) {
                    Curry._1(setDoc, (function (param) {
                            if (snapshot.exists) {
                              return {
                                      NAME: "Success",
                                      VAL: snapshot.data()
                                    };
                            } else {
                              return "NotFound";
                            }
                          }));
                    return Promise.resolve(undefined);
                  }), (function (_error) {
                  Curry._1(setDoc, (function (param) {
                          return "NotFound";
                        }));
                  return Promise.resolve(undefined);
                }));
          
        }), [docId]);
  React.useEffect((function () {
          if (!listen) {
            return ;
          }
          var unsubscribe = collection.doc(docId).onSnapshot(function (snap) {
                return Curry._1(setDoc, (function (param) {
                              if (snap.exists) {
                                return {
                                        NAME: "Success",
                                        VAL: snap.data()
                                      };
                              } else {
                                return "NotFound";
                              }
                            }));
              });
          return (function (param) {
                    return unsubscribe();
                  });
        }), []);
  return match[0];
}

function useDocs(key, ids, collection) {
  var match = React.useState(function () {
        return "Loading";
      });
  var setDocs = match[1];
  React.useEffect((function () {
          $$Promise.$$catch(Promise.all(Belt_Array.map(ids, (function (id) {
                            return collection.doc(id).get();
                          }))).then(function (snapshots) {
                    return Promise.resolve(Curry._1(setDocs, (function (param) {
                                      return {
                                              NAME: "Loaded",
                                              VAL: Belt_Array.keepMap(snapshots, (function (snap) {
                                                      if (snap.exists) {
                                                        return Caml_option.some(snap.data());
                                                      }
                                                      
                                                    }))
                                            };
                                    })));
                  }), (function (err) {
                  Curry._1(setDocs, (function (param) {
                          return {
                                  NAME: "Errored",
                                  VAL: err
                                };
                        }));
                  return Promise.resolve(undefined);
                }));
          
        }), [
        ids.join(""),
        Belt_Option.getWithDefault(key, "")
      ]);
  return match[0];
}

function usePoll(collection, query, delay, param) {
  var match = React.useState(function () {
        return /* [] */0;
      });
  var setDocs = match[1];
  var currentRequest = React.useRef([
        undefined,
        undefined
      ]);
  Hooks.useInterval(delay, (function (param) {
          var match = currentRequest.current;
          var currentHead = match[1];
          var requestId = Shortid();
          var q = Curry._1(query, collection);
          (
                currentHead !== undefined ? q.endBefore(Caml_option.valFromOption(currentHead)) : q
              ).get().then(function (snapshot) {
                var docsSnapshots = snapshot.docs;
                var docs = Belt_List.mapU(Belt_List.fromArray(docsSnapshots), (function (doc) {
                        return doc.data();
                      }));
                var match = currentRequest.current;
                var currentRequestId = match[0];
                var match$1 = Belt_Array.get(docsSnapshots, 0);
                return Promise.resolve(currentRequestId !== undefined ? (
                              match$1 !== undefined && currentRequestId === requestId ? (currentRequest.current = [
                                    currentRequestId,
                                    Caml_option.some(Caml_option.valFromOption(match$1))
                                  ], Curry._1(setDocs, (function (param) {
                                          return docs;
                                        }))) : undefined
                            ) : (
                              match$1 !== undefined ? (currentRequest.current = [
                                    undefined,
                                    Caml_option.some(Caml_option.valFromOption(match$1))
                                  ], Curry._1(setDocs, (function (param) {
                                          return docs;
                                        }))) : undefined
                            ));
              });
          currentRequest.current = [
            requestId,
            currentHead
          ];
          
        }));
  return match[0];
}

function useUser(userId) {
  return useDoc(Firebase.app(undefined).firestore().collection("users"), userId, true);
}

function useBranchOpenAction(schemaId, branchId, includeActions, mode, param) {
  var baseQuery = function (query) {
    return query.where("contentsJson.OpenBranch.branchId", "==", branchId);
  };
  return Belt_List.head(Belt_List.map(useStreamCollection(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions"), (function (query) {
                        if (includeActions === "NonDemo") {
                          return baseQuery(query).where("isCreateDemoBranchAutoAction", "==", false);
                        } else if (includeActions === "Demo") {
                          return baseQuery(query).where("isCreateDemoBranchAutoAction", "==", true);
                        } else {
                          return baseQuery(query);
                        }
                      }), mode, undefined), transformAction));
}

function useBranchStates(statusGroup, schemaId) {
  var statusGroupQuery = function (query) {
    if (statusGroup !== undefined) {
      if (statusGroup) {
        return query.where("branchStatus", "not-in", Belt_Array.map(BranchStateFirebaseModel.closedStateBranchStatuses, BranchStateFirebaseModel.branchStatusToJs)).orderBy("branchStatus", "asc");
      } else {
        return query.where("branchStatus", "in", Belt_Array.map(BranchStateFirebaseModel.closedStateBranchStatuses, BranchStateFirebaseModel.branchStatusToJs));
      }
    } else {
      return query;
    }
  };
  var branchStates = useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("branchStates"), (function (query) {
          return statusGroupQuery(query).orderBy("creationDate", "desc");
        }), undefined, statusGroup !== undefined ? (
          statusGroup ? "active" : "closed"
        ) : "all", undefined);
  if (typeof branchStates === "object") {
    return {
            NAME: "Loaded",
            VAL: Belt_List.map(branchStates.VAL, BranchStateFirebaseModel.parseFirebaseType)
          };
  } else {
    return branchStates;
  }
}

function useBranchState(mode, schemaId, branchId) {
  var branchStates = useStreamCollection(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("branchStates"), (function (query) {
          return query.where("branchId", "==", branchId);
        }), mode, undefined);
  return Belt_Option.map(Belt_List.head(branchStates), BranchStateFirebaseModel.parseFirebaseType);
}

function useBranchOpenActionExists(schemaId, mode, includeActions, branchId) {
  var baseQuery = function (query) {
    return query.where("contentsJson.OpenBranch.branchId", "==", branchId);
  };
  var branchOpenAction = useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions"), (function (query) {
          if (includeActions === "NonDemo") {
            return baseQuery(query).where("isCreateDemoBranchAutoAction", "==", false);
          } else if (includeActions === "Demo") {
            return baseQuery(query).where("isCreateDemoBranchAutoAction", "==", true);
          } else {
            return baseQuery(query);
          }
        }), mode, undefined, undefined);
  if (typeof branchOpenAction !== "object") {
    if (branchOpenAction === "Initial") {
      return "Initial";
    } else {
      return "Error";
    }
  }
  var _action = Belt_List.head(Belt_List.map(branchOpenAction.VAL, transformAction));
  if (_action !== undefined) {
    return "Exists";
  } else {
    return "NotFound";
  }
}

function useBranchCloseAction(schemaId, branchId, mode, param) {
  return Belt_List.head(Belt_List.map(useStreamCollection(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions"), (function (query) {
                        return query.where("contentsJson.CloseBranch.branchId", "==", branchId);
                      }), mode, undefined), transformAction));
}

function useBranchMergeAction(schemaId, branchId, mode, param) {
  return Belt_List.head(Belt_List.map(useStreamCollection(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions"), (function (query) {
                        return query.where("contentsJson.MergeBranch.branchId", "==", branchId);
                      }), mode, undefined), transformAction));
}

function useSetBranchStatusActions(schemaId, branchId, mode, param) {
  return Belt_List.map(useStreamCollection(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions"), (function (query) {
                    return query.where("contentsJson.SetBranchStatus.branchId", "==", branchId).orderBy("createdAt", "desc").limit(Workspace.maxRequiredApprovals);
                  }), mode, undefined), transformAction);
}

function useBranchLifecycleActions(schemaId, branchId, mode, param) {
  return [
          useBranchOpenAction(schemaId, branchId, "All", mode, undefined),
          useBranchMergeAction(schemaId, branchId, mode, undefined),
          useBranchCloseAction(schemaId, branchId, mode, undefined),
          useSetBranchStatusActions(schemaId, branchId, mode, undefined)
        ];
}

function useHasExistingTrial(schemaId) {
  return Belt_List.length(useStreamCollection(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("trials"), (function (query) {
                    return query.limit(1);
                  }), undefined, undefined)) > 0;
}

function useTrial(schemaId) {
  return Belt_List.head(useStreamCollection(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("trials"), (function (query) {
                    return query.orderBy("endAt", "desc").where("endAt", ">", new Date()).limit(1);
                  }), undefined, undefined));
}

function useUsers(ids) {
  return useDocs(undefined, ids, Firebase.app(undefined).firestore().collection("users"));
}

function useUsersWithKey(ids, key) {
  return useDocs(key, ids, Firebase.app(undefined).firestore().collection("users"));
}

function useMembers(schemaId) {
  var acls = useStreamCollection(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("acls"), (function (query) {
          return query.orderBy("createdAt", "asc");
        }), undefined, undefined);
  var users = useUsersWithKey(Belt_List.toArray(Belt_List.map(acls, (function (prim) {
                  return prim.id;
                }))), Belt_List.toArray(Belt_List.map(acls, (function (prim) {
                    return prim.id;
                  }))).join(","));
  if (typeof users === "object") {
    if (users.NAME === "Errored") {
      return Pervasives.failwith("Error getting user");
    } else {
      return Models.Member.enrichMembersWithEmail(acls, users.VAL);
    }
  } else {
    return acls;
  }
}

function useMember(schemaId, userId, listen) {
  var match = React.useState(function () {
        return [];
      });
  var setIds = match[1];
  var member = useDoc(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("acls"), userId, listen);
  var users = useUsers(match[0]);
  React.useEffect((function () {
          if (typeof member === "object") {
            var member$1 = member.VAL;
            Curry._1(setIds, (function (param) {
                    return [member$1.id];
                  }));
          }
          
        }), [member]);
  if (typeof users !== "object") {
    return member;
  }
  if (users.NAME === "Errored") {
    return Pervasives.failwith("Error getting user");
  }
  var users$1 = users.VAL;
  if (users$1.length === 0) {
    return member;
  }
  if (typeof member !== "object") {
    if (member === "NotFound") {
      return "NotFound";
    } else {
      return "Loading";
    }
  }
  var acl = member.VAL;
  var email = Belt_Option.map(Belt_Array.getBy(users$1, (function (user) {
              return user.id === acl.id;
            })), (function (user) {
          return user.email;
        }));
  return {
          NAME: "Success",
          VAL: Models.Member.enrichMemberWithEmail(email, acl)
        };
}

function isSuperUser(param) {
  var user = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser));
  if (user.email.endsWith("@avo.sh")) {
    return user.emailVerified;
  } else {
    return false;
  }
}

function useViewerRole(schemaId) {
  var viewerId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  var member = useMember(schemaId, viewerId, false);
  if (typeof member === "object") {
    return {
            NAME: "Success",
            VAL: Belt_Option.getWithDefault(Models.Role.tFromJs(member.VAL.role), "NoAccess")
          };
  } else if (member === "NotFound") {
    if (isSuperUser(undefined)) {
      return {
              NAME: "Success",
              VAL: "Admin"
            };
    } else {
      return "NotFound";
    }
  } else {
    return "Loading";
  }
}

function useSchemaInvites(schemaId) {
  return useStreamCollection(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("invites"), (function (param) {
                return param.orderBy("createdAt", "asc");
              }), undefined, undefined);
}

function useSsoSchemasForDomain(domain) {
  var match = React.useState(function () {
        return "Initial";
      });
  var setState = match[1];
  React.useEffect((function () {
          Belt_Option.mapWithDefault(domain, undefined, (function (domain) {
                  $$Promise.$$catch(SsoChecks.getSsoSchemasForDomainFromFirestore(domain).then(function (ssoConfig) {
                            return Promise.resolve(Curry._1(setState, (function (param) {
                                              return {
                                                      NAME: "Loaded",
                                                      VAL: Belt_Option.mapWithDefault(ssoConfig, /* [] */0, (function (ssoConfig) {
                                                              return Belt_List.fromArray(ssoConfig.schemaIds);
                                                            }))
                                                    };
                                            })));
                          }), (function (error) {
                          console.error("Error in useSsoSchemasForDomain: ", error);
                          return Promise.resolve(Curry._1(setState, (function (param) {
                                            return {
                                                    NAME: "Errored",
                                                    VAL: error
                                                  };
                                          })));
                        }));
                  
                }));
          
        }), [domain]);
  return match[0];
}

function useCurrentSsoConfig(schemaId) {
  var match = React.useState(function () {
        return "Initial";
      });
  var setState = match[1];
  React.useEffect((function () {
          SsoChecks.ssoSchemaCheck(schemaId).then(function (ssoConfig) {
                return Promise.resolve(Curry._1(setState, (function (param) {
                                  return {
                                          NAME: "Loaded",
                                          VAL: ssoConfig
                                        };
                                })));
              });
          
        }), [schemaId]);
  return match[0];
}

function useComments(schemaId, branchId, threadId) {
  return useStreamCollection(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("comments").doc(threadId).collection("items"), (function (query) {
                return query.orderBy("createdAt", "asc").where("branchId", "==", branchId);
              }), undefined, undefined);
}

function useComment(schemaId, threadId, commentId) {
  return useDoc(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("comments").doc(threadId).collection("items"), commentId, false);
}

function useAllNotificationChannels(schemaId) {
  return useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("notificationChannels"), (function (q) {
                return q.where("service", "==", "slack");
              }), undefined, undefined, undefined);
}

function useNotificationChannels(modeOpt, schemaId, eventType) {
  var mode = modeOpt !== undefined ? modeOpt : "Fetch";
  return useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("notificationChannels"), (function (q) {
                return q.where("service", "==", "slack").where("events", "array-contains", Models.NotificationChannel.printEventType(eventType));
              }), mode, undefined, undefined);
}

function useActionFromCommentId(schemaId, commentId, objectType) {
  var getPath = function (type_) {
    if (type_ === "Integration") {
      return "contentsJson.IntegrationComment.commentId";
    } else if (type_ === "Property") {
      return "contentsJson.PropertyComment.commentId";
    } else if (type_ === "Event") {
      return "contentsJson.EventComment.commentId";
    } else if (type_ === "PropertyGroup") {
      return "contentsJson.PropertyGroupComment.commentId";
    } else if (type_ === "Goal") {
      return "contentsJson.GoalComment.commentId";
    } else if (type_ === "Metric") {
      return "contentsJson.MetricComment.commentId";
    } else {
      return "contentsJson.BranchComment.commentId";
    }
  };
  return useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions"), (function (q) {
                return q.where(getPath(objectType), "==", commentId).limit(1);
              }), undefined, undefined, undefined);
}

function useLastIntegrationAutoPublishAction(schemaId, integrationId) {
  var autoPublishActionsProgress = useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions"), (function (query) {
          return query.where("contentsJson.AutoPublishIntegration.integrationId", "==", integrationId).orderBy("createdAt", "desc").limit(1);
        }), undefined, undefined, undefined);
  if (typeof autoPublishActionsProgress === "object") {
    return {
            NAME: "Loaded",
            VAL: Belt_Option.map(Belt_List.head(autoPublishActionsProgress.VAL), transformAction)
          };
  } else if (autoPublishActionsProgress === "Initial") {
    return "Initial";
  } else {
    return "Error";
  }
}

function useLastIntegrationManualPublishAction(schemaId, integrationId) {
  var manualPublishActionsProgress = useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions"), (function (query) {
          return query.where("contentsJson.PublishIntegration.integrationId", "==", integrationId).orderBy("createdAt", "desc").limit(1);
        }), undefined, undefined, undefined);
  if (typeof manualPublishActionsProgress === "object") {
    return {
            NAME: "Loaded",
            VAL: Belt_Option.map(Belt_List.head(manualPublishActionsProgress.VAL), transformAction)
          };
  } else if (manualPublishActionsProgress === "Initial") {
    return "Initial";
  } else {
    return "Error";
  }
}

function useLastIntegrationPublishAction(schemaId, integrationId) {
  var auto = useLastIntegrationAutoPublishAction(schemaId, integrationId);
  var manual = useLastIntegrationManualPublishAction(schemaId, integrationId);
  if (typeof auto === "object") {
    if (auto.NAME === "Loaded") {
      var auto$1 = auto.VAL;
      if (auto$1 !== undefined) {
        if (typeof manual === "object" && manual.NAME === "Loaded") {
          var manual$1 = manual.VAL;
          var auto$2 = Caml_option.valFromOption(auto$1);
          if (manual$1 === undefined) {
            return {
                    NAME: "Loaded",
                    VAL: Caml_option.some(auto$2)
                  };
          }
          var manual$2 = Caml_option.valFromOption(manual$1);
          if (Belt_Option.mapWithDefault(Models.toDateOption(auto$2.createdAt), 0.0, (function (prim) {
                    return prim.valueOf();
                  })) > Belt_Option.mapWithDefault(Models.toDateOption(manual$2.createdAt), 0.0, (function (prim) {
                    return prim.valueOf();
                  }))) {
            return {
                    NAME: "Loaded",
                    VAL: Caml_option.some(auto$2)
                  };
          } else {
            return {
                    NAME: "Loaded",
                    VAL: Caml_option.some(manual$2)
                  };
          }
        }
        
      } else if (typeof manual === "object" && manual.NAME === "Loaded") {
        var manual$3 = manual.VAL;
        if (manual$3 !== undefined) {
          return {
                  NAME: "Loaded",
                  VAL: Caml_option.some(Caml_option.valFromOption(manual$3))
                };
        } else {
          return {
                  NAME: "Loaded",
                  VAL: undefined
                };
        }
      }
      
    }
    
  } else if (auto === "Initial") {
    return "Initial";
  }
  if (manual === "Error" || manual !== "Initial") {
    return "Error";
  } else {
    return "Initial";
  }
}

function usePayment(schemaId) {
  return useDoc(Firebase.app(undefined).firestore().collection("payments"), schemaId, true);
}

function useLatestVersion(schemaId, beforeDate, beforeAndEqualsDate, branchIdOpt, modeOpt, param) {
  var branchId = branchIdOpt !== undefined ? branchIdOpt : "master";
  var mode = modeOpt !== undefined ? modeOpt : "Fetch";
  var match = React.useState(function () {
        return "Initial";
      });
  var setVersionState = match[1];
  var remountKey = schemaId + "-" + branchId + "-" + Belt_Option.mapWithDefault(beforeDate, "", (function (prim) {
          return prim.toISOString();
        })) + "-" + Belt_Option.mapWithDefault(beforeAndEqualsDate, "", (function (prim) {
          return prim.toISOString();
        }));
  React.useEffect((function () {
          Curry._1(setVersionState, (function (param) {
                  return "Initial";
                }));
          
        }), [remountKey]);
  var result = useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("versions"), (function (query) {
          var q = query.orderBy("createdAt", "desc").limit(1).where("branchId", "==", branchId);
          if (beforeDate !== undefined) {
            if (beforeAndEqualsDate !== undefined) {
              return Pervasives.failwith("beforeDate and beforeAndEqualsDate are mutually exclusive, you can't pass both to useLatestVersion");
            } else {
              return q.where("createdAt", "<", Caml_option.valFromOption(beforeDate));
            }
          } else if (beforeAndEqualsDate !== undefined) {
            return q.where("createdAt", "<=", Caml_option.valFromOption(beforeAndEqualsDate));
          } else {
            return q;
          }
        }), mode, remountKey, undefined);
  var chunksFetchMode;
  if (typeof result === "object" && result.NAME === "Loaded") {
    var match$1 = result.VAL;
    if (match$1 && !match$1.tl) {
      var version = match$1.hd;
      chunksFetchMode = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(version.chunks), false) ? "Fetch" : (
          Belt_Option.getWithDefault(Caml_option.undefined_to_opt(version.chunks), false) ? "Wait" : "Skip"
        );
    } else {
      chunksFetchMode = "Wait";
    }
  } else {
    chunksFetchMode = "Wait";
  }
  var currentVersionId;
  if (typeof result === "object" && result.NAME === "Loaded") {
    var match$2 = result.VAL;
    currentVersionId = match$2 && !match$2.tl ? match$2.hd.id : "NA";
  } else {
    currentVersionId = "NA";
  }
  var chunksResult = useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("versions").doc(currentVersionId).collection("chunks"), (function (query) {
          return query.orderBy("index", "asc");
        }), chunksFetchMode, remountKey + currentVersionId, undefined);
  React.useEffect((function () {
          var exit = 0;
          var other;
          if (typeof result === "object" && result.NAME === "Loaded") {
            var match = result.VAL;
            var exit$1 = 0;
            var exit$2 = 0;
            if (match && !match.tl) {
              var version = match.hd;
              if (chunksFetchMode === "Fetch") {
                if (typeof chunksResult === "object" && chunksResult.NAME === "Loaded") {
                  var chunks = chunksResult.VAL;
                  var chunksCount = version.chunksCount;
                  if (chunksCount !== undefined && Belt_List.length(chunks) !== chunksCount) {
                    Pervasives.failwith("Error in FirebaseFetcherHooks.useLatestVersion: Expected " + String(chunksCount) + " chunks but received " + String(Belt_List.length(chunks)));
                  }
                  var contentsStr = Belt_List.toArray(Belt_List.mapU(Belt_List.sortU(chunks, (function (a, b) {
                                    return Caml.caml_int_compare(a.index, b.index);
                                  })), (function (chunk) {
                                return chunk.contentsStr;
                              }))).join("");
                  version.contents = JSON.parse(contentsStr);
                  Curry._1(setVersionState, (function (param) {
                          return {
                                  NAME: "Loaded",
                                  VAL: {
                                    hd: version,
                                    tl: /* [] */0
                                  }
                                };
                        }));
                } else {
                  exit$2 = 3;
                }
              } else if (chunksFetchMode === "Skip") {
                var str = version.contentsStr;
                if (str !== undefined) {
                  version.contents = JSON.parse(str);
                } else {
                  version.enrichedContentsStr = Belt_Option.getExn(JSON.stringify(version.contents));
                }
                Curry._1(setVersionState, (function (param) {
                        return {
                                NAME: "Loaded",
                                VAL: {
                                  hd: version,
                                  tl: /* [] */0
                                }
                              };
                      }));
              } else {
                exit$2 = 3;
              }
            } else {
              exit$2 = 3;
            }
            if (exit$2 === 3 && !(chunksFetchMode === "Fetch" && chunksResult === "Initial")) {
              exit$1 = 2;
            }
            if (exit$1 === 2) {
              if (match && !(match.tl || chunksFetchMode !== "Fetch")) {
                other = chunksResult;
                exit = 1;
              } else {
                other = result;
                exit = 1;
              }
            }
            
          } else {
            other = result;
            exit = 1;
          }
          if (exit === 1) {
            Curry._1(setVersionState, (function (currentState) {
                    if (typeof currentState === "object" && currentState.NAME === "Loaded") {
                      return currentState;
                    } else {
                      return other;
                    }
                  }));
          }
          
        }), [
        result,
        chunksFetchMode,
        chunksResult
      ]);
  return match[0];
}

function transformActions(docs) {
  return Belt_List.mapU(Belt_List.fromArray(docs), (function (doc) {
                return transformAction(doc.data());
              }));
}

function getInitialState(query) {
  return query.get().then(function (initialSnapshot) {
              var initialDocs = initialSnapshot.docs;
              var tail = Belt_Array.get(Belt_Array.sliceToEnd(Belt_Array.keepU(initialDocs, (function (doc) {
                              return doc.metadata.hasPendingWrites === false;
                            })), -1), 0);
              var query$1 = tail !== undefined ? query.startAfter(Caml_option.valFromOption(tail)) : query;
              var initialActions = transformActions(initialDocs);
              return Promise.resolve([
                          initialActions,
                          query$1
                        ]);
            });
}

function useActions(version, schemaId, branchId, fetchToDate, fetchToEqualDate, auditOpt, modeOpt, param) {
  var audit = auditOpt !== undefined ? auditOpt : false;
  var mode = modeOpt !== undefined ? modeOpt : "Fetch";
  var match = React.useState(function () {
        return [
                version,
                "Initial"
              ];
      });
  var setState = match[1];
  var match$1 = match[0];
  var match$2 = React.useState(function () {
        
      });
  var setSubscription = match$2[1];
  var subscription = match$2[0];
  var remountKey = Belt_Option.mapWithDefault(version, "", (function (prim) {
          return prim.id;
        })) + "-" + schemaId + "-" + branchId + "-" + Belt_Option.mapWithDefault(fetchToDate, "", (function (prim) {
          return prim.toISOString();
        })) + "-" + Belt_Option.mapWithDefault(fetchToEqualDate, "", (function (prim) {
          return prim.toISOString();
        })) + "-" + Pervasives.string_of_bool(audit);
  React.useEffect((function () {
          return Belt_Option.map(subscription, (function (unsubscribe, param) {
                        return unsubscribe();
                      }));
        }), [subscription]);
  React.useEffect((function () {
          if (mode === "Wait" || mode === "Skip") {
            Curry._1(setState, (function (param) {
                    return [
                            version,
                            "Initial"
                          ];
                  }));
            Curry._1(setSubscription, (function (param) {
                    
                  }));
            return ;
          }
          var buildQuery = function (fetchFromDate, fetchToDate, fetchToEqualDate, queryBy, branchId, audit, query) {
            var query$1 = query.orderBy("createdAt", "asc").orderBy("order", "asc");
            var query$2 = fetchFromDate !== undefined ? query$1.where("createdAt", ">", Caml_option.valFromOption(fetchFromDate)) : query$1;
            var query$3 = fetchToDate !== undefined ? query$2.where("createdAt", "<", Caml_option.valFromOption(fetchToDate)) : query$2;
            var query$4 = fetchToEqualDate !== undefined ? query$3.where("createdAt", "<=", Caml_option.valFromOption(fetchToEqualDate)) : query$3;
            var query$5 = queryBy !== undefined ? query$4.where(queryBy[0], "==", queryBy[1]) : query$4;
            var query$6 = branchId !== undefined ? query$5.where("branchId", "==", branchId) : query$5.where("branchId", "==", "master");
            if (audit !== undefined) {
              return query$6.where("audit", "==", audit);
            } else {
              return query$6;
            }
          };
          var __x = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions");
          var actionsQuery = buildQuery(Belt_Option.map(Belt_Option.flatMap(version, (function (version) {
                          return Caml_option.undefined_to_opt(version.lastActionTimestamp);
                        })), (function (prim) {
                      return prim.toDate();
                    })), fetchToDate, fetchToEqualDate, undefined, branchId, audit, __x);
          $$Promise.$$catch(getInitialState(actionsQuery).then(function (param) {
                    var initialActions = param[0];
                    Curry._1(setState, (function (param) {
                            return [
                                    version,
                                    {
                                      NAME: "Success",
                                      VAL: initialActions
                                    }
                                  ];
                          }));
                    var unsubscribe = param[1].onSnapshot((function (snapshot) {
                            var newDocs = snapshot.docs;
                            var newActions = transformActions(newDocs);
                            var newActionsSet = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(newActions, (function (prim) {
                                            return prim.id;
                                          }))));
                            var dedupedInitialActions = Belt_List.keepU(initialActions, (function (initialAction) {
                                    return !Belt_SetString.has(newActionsSet, Models.Action.getId(initialAction));
                                  }));
                            var actions = Belt_List.concat(dedupedInitialActions, newActions);
                            return Curry._1(setState, (function (param) {
                                          return [
                                                  param[0],
                                                  {
                                                    NAME: "Success",
                                                    VAL: actions
                                                  }
                                                ];
                                        }));
                          }), (function (error) {
                            console.log("FetchActions: Actions streamer error", error);
                            return Curry._1(setState, (function (param) {
                                          return [
                                                  param[0],
                                                  "Error"
                                                ];
                                        }));
                          }));
                    Curry._1(setSubscription, (function (param) {
                            return unsubscribe;
                          }));
                    return Promise.resolve(undefined);
                  }), (function (error) {
                  console.log("FetchActions: Initial actions fetch error", error);
                  return Promise.resolve(Curry._1(setState, (function (param) {
                                    return [
                                            param[0],
                                            "Error"
                                          ];
                                  })));
                }));
          
        }), [
        version,
        mode,
        remountKey
      ]);
  return [
          match$1[0],
          match$1[1]
        ];
}

function usePagedActions(queryBy, schemaId, param) {
  return usePagedQueryWithProgress(undefined, transformAction, 150, Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions"), (function (query) {
                var query$1 = query.orderBy("createdAt", "desc");
                return Belt_Option.mapWithDefault(queryBy, query$1, (function (param) {
                              return query$1.where(param[0], "==", param[1]);
                            }));
              }), undefined);
}

function useAction(schemaId, extraQuery, actionNameString) {
  var status = useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions"), (function (query) {
          var query$1 = query.where("contentsJson." + actionNameString, ">", {});
          if (extraQuery !== undefined) {
            return Curry._1(extraQuery, query$1).limit(1);
          } else {
            return query$1.limit(1);
          }
        }), undefined, undefined, undefined);
  if (typeof status === "object") {
    return {
            NAME: "Loaded",
            VAL: Belt_List.head(status.VAL)
          };
  } else if (status === "Initial") {
    return "Initial";
  } else {
    return "Error";
  }
}

function useActionLastBranchAction(schemaId, branchId) {
  var status = useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions"), (function (query) {
          return query.where("branchId", "==", branchId).orderBy("createdAt", "desc").limit(1);
        }), undefined, undefined, undefined);
  if (typeof status === "object") {
    return {
            NAME: "Loaded",
            VAL: Belt_List.head(status.VAL)
          };
  } else if (status === "Initial") {
    return "Initial";
  } else {
    return "Error";
  }
}

function useActionWithLimit(schemaId, limitOpt, actionNameString) {
  var limit = limitOpt !== undefined ? limitOpt : 1;
  return useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions"), (function (query) {
                return query.where("contentsJson." + actionNameString, ">", {}).limit(limit);
              }), undefined, undefined, undefined);
}

function useUserSchemas(userId) {
  return useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("users").doc(userId).collection("schemas"), (function (param) {
                return param.orderBy("lastUsedAt", "desc");
              }), undefined, undefined, undefined);
}

function useUserSchemaInvites(externalId) {
  return useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("invites"), (function (query) {
                return query.where("externalId", "==", externalId).orderBy("createdAt", "asc");
              }), undefined, undefined, undefined);
}

function useSchema(fb, schemaId, listen) {
  return useDoc(fb.firestore().collection("schemas"), schemaId, listen);
}

function useAvoConfig(param) {
  return useDoc(Firebase.app(undefined).firestore().collection("avo"), "config", true);
}

function useAnyOpenBranchAction(schemaId) {
  return useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions"), (function (query) {
                return query.where("contentsJson.OpenBranch", ">", {}).limit(1);
              }), undefined, undefined, undefined);
}

function useMergedBranchIds(fetchToDate, schemaId) {
  var state = useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions"), (function (query) {
          var query$1 = query.where("contentsJson.MergeBranch", ">", {});
          if (fetchToDate !== undefined) {
            return query$1.where("createdAt", "<", Caml_option.valFromOption(fetchToDate));
          } else {
            return query$1;
          }
        }), undefined, undefined, undefined);
  if (typeof state !== "object") {
    if (state === "Initial") {
      return "Initial";
    } else {
      return "Error";
    }
  }
  var actions = Belt_List.mapU(state.VAL, (function (action) {
          var actionJson = action.contentsJson;
          return Json_decode.field("MergeBranch", (function (mergeBranchJson) {
                        return Json_decode.field("branchId", Json_decode.string, mergeBranchJson);
                      }), actionJson);
        }));
  return {
          NAME: "Loaded",
          VAL: Belt_SetString.fromArray(Belt_List.toArray(actions))
        };
}

function useWebhookSecret(schemaId, integrationId) {
  return useDoc(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("webhookSecrets"), integrationId, true);
}

function useServiceAccounts(schemaId) {
  return useStreamCollectionArr(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("serviceAccounts"), (function (param) {
                return param.orderBy("createdAt", "desc");
              }), undefined, ServiceAccount.decode, undefined);
}

function useSharingLink(schemaId, branchId) {
  return Belt_Array.get(useStreamCollectionArr(Firebase.app(undefined).firestore().collection("sharing"), (function (query) {
                    return query.where("schemaId", "==", schemaId).where("branchId", "==", branchId);
                  }), undefined, Sharing.decode, undefined), 0);
}

function useSavedViews(schemaId) {
  var state = useStreamCollectionWithProgress(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("savedViews"), (function (query) {
          return query.where("isArchived", "==", false);
        }), undefined, undefined, undefined);
  if (typeof state === "object") {
    return {
            NAME: "Loaded",
            VAL: Belt_List.map(state.VAL, SavedViews.decode)
          };
  } else if (state === "Initial") {
    return "Initial";
  } else {
    return "Error";
  }
}

function useValidationIntents(schemaId, branchId) {
  var collection = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection(InspectorVersionValidationIntent.collection);
  var defaultState = useDoc(collection, "default", true);
  var branchState = useDoc(collection, branchId, true);
  var getIntentsAndFetchState = function (state) {
    if (typeof state === "object") {
      return [
              InspectorVersionValidationIntent.Decode.intents(state.VAL),
              "success"
            ];
    } else if (state === "NotFound") {
      return [
              undefined,
              "notFound"
            ];
    } else {
      return [
              undefined,
              "fetching"
            ];
    }
  };
  var mergeIntentsAndFetchState = function (defaultIntentsAndFetchState, branchIntentsAndFetchState, currentIntentsAndFetchState) {
    if (defaultIntentsAndFetchState[1] === "fetching" || branchIntentsAndFetchState[1] === "fetching") {
      return currentIntentsAndFetchState;
    } else {
      return [
              Belt_MapString.mergeU(defaultIntentsAndFetchState[0], branchIntentsAndFetchState[0], (function (_sourceId, maybeDefaultIntent, maybeBranchIntent) {
                      if (maybeBranchIntent !== undefined) {
                        return Caml_option.some(Caml_option.valFromOption(maybeBranchIntent));
                      } else if (maybeDefaultIntent !== undefined) {
                        return Caml_option.some(Caml_option.valFromOption(maybeDefaultIntent));
                      } else {
                        return ;
                      }
                    })),
              "success"
            ];
    }
  };
  var match = React.useState(function () {
        return mergeIntentsAndFetchState(getIntentsAndFetchState(defaultState), getIntentsAndFetchState(branchState), [
                    undefined,
                    "fetching"
                  ]);
      });
  var setIntentsAndFetchState = match[1];
  Hooks.useDidUpdate2((function (param) {
          Curry._1(setIntentsAndFetchState, (function (currentIntentsAndFetchState) {
                  return mergeIntentsAndFetchState(getIntentsAndFetchState(defaultState), getIntentsAndFetchState(branchState), currentIntentsAndFetchState);
                }));
          
        }), [
        defaultState,
        branchState
      ]);
  return match[0];
}

var Q;

exports.Q = Q;
exports.transformAction = transformAction;
exports.MakePagedStreamHook = MakePagedStreamHook;
exports.useStreamCollection = useStreamCollection;
exports.useStreamCollectionArr = useStreamCollectionArr;
exports.useStreamCollectionWithProgress = useStreamCollectionWithProgress;
exports.useMultiQueryWithProgress = useMultiQueryWithProgress;
exports.usePagedQueryWithProgress = usePagedQueryWithProgress;
exports.useDoc = useDoc;
exports.useDocs = useDocs;
exports.usePoll = usePoll;
exports.useUser = useUser;
exports.useBranchOpenAction = useBranchOpenAction;
exports.useBranchStates = useBranchStates;
exports.useBranchState = useBranchState;
exports.useBranchOpenActionExists = useBranchOpenActionExists;
exports.useBranchCloseAction = useBranchCloseAction;
exports.useBranchMergeAction = useBranchMergeAction;
exports.useSetBranchStatusActions = useSetBranchStatusActions;
exports.useBranchLifecycleActions = useBranchLifecycleActions;
exports.useHasExistingTrial = useHasExistingTrial;
exports.useTrial = useTrial;
exports.useUsers = useUsers;
exports.useUsersWithKey = useUsersWithKey;
exports.useMembers = useMembers;
exports.useMember = useMember;
exports.isSuperUser = isSuperUser;
exports.useViewerRole = useViewerRole;
exports.useSchemaInvites = useSchemaInvites;
exports.useSsoSchemasForDomain = useSsoSchemasForDomain;
exports.useCurrentSsoConfig = useCurrentSsoConfig;
exports.useComments = useComments;
exports.useComment = useComment;
exports.useAllNotificationChannels = useAllNotificationChannels;
exports.useNotificationChannels = useNotificationChannels;
exports.useActionFromCommentId = useActionFromCommentId;
exports.useLastIntegrationAutoPublishAction = useLastIntegrationAutoPublishAction;
exports.useLastIntegrationManualPublishAction = useLastIntegrationManualPublishAction;
exports.useLastIntegrationPublishAction = useLastIntegrationPublishAction;
exports.usePayment = usePayment;
exports.useLatestVersion = useLatestVersion;
exports.transformActions = transformActions;
exports.getInitialState = getInitialState;
exports.useActions = useActions;
exports.usePagedActions = usePagedActions;
exports.useAction = useAction;
exports.useActionLastBranchAction = useActionLastBranchAction;
exports.useActionWithLimit = useActionWithLimit;
exports.useUserSchemas = useUserSchemas;
exports.useUserSchemaInvites = useUserSchemaInvites;
exports.useSchema = useSchema;
exports.useAvoConfig = useAvoConfig;
exports.useAnyOpenBranchAction = useAnyOpenBranchAction;
exports.useMergedBranchIds = useMergedBranchIds;
exports.useWebhookSecret = useWebhookSecret;
exports.useServiceAccounts = useServiceAccounts;
exports.useSharingLink = useSharingLink;
exports.useSavedViews = useSavedViews;
exports.useValidationIntents = useValidationIntents;
/* Hooks Not a pure module */
