// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Mantine = require("./Mantine.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core = require("@mantine/core");

var tooltip = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.grey80),
      tl: {
        hd: Css.padding2(Css.px(8), Css.px(16)),
        tl: {
          hd: Css.borderRadius(Css.px(5)),
          tl: /* [] */0
        }
      }
    });

var Style = {
  tooltip: tooltip
};

function SimpleTooltip$Basic(Props) {
  var arrowSizeOpt = Props.arrowSize;
  var arrowOffset = Props.arrowOffset;
  var delayInOpt = Props.delayIn;
  var disabledOpt = Props.disabled;
  var customMaxWidthOpt = Props.maxWidth;
  var offset = Props.offset;
  var onHide = Props.onHide;
  var onShow = Props.onShow;
  var customPositionOpt = Props.position;
  var preventDelayOutOpt = Props.preventDelayOut;
  var tooltip$1 = Props.tooltip;
  var children = Props.children;
  var arrowSize = arrowSizeOpt !== undefined ? arrowSizeOpt : 12;
  var delayIn = delayInOpt !== undefined ? delayInOpt : 500;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var customMaxWidth = customMaxWidthOpt !== undefined ? customMaxWidthOpt : 350;
  var customPosition = customPositionOpt !== undefined ? customPositionOpt : "bottom";
  var preventDelayOut = preventDelayOutOpt !== undefined ? preventDelayOutOpt : false;
  var match = Hooks.useDisclosure(false);
  var action = match[1];
  var opened = match[0];
  var tooltipOpenTimeout = React.useRef(undefined);
  var tooltipCloseTimeout = React.useRef(undefined);
  var tooltipSendOpenEventTimeout = React.useRef(undefined);
  Hooks.useDidUpdate1((function (param) {
          if (opened && !disabled) {
            tooltipSendOpenEventTimeout.current = Caml_option.some(setTimeout((function (param) {
                        Belt_Option.forEach(onShow, (function (cb) {
                                return Curry._1(cb, undefined);
                              }));
                        tooltipSendOpenEventTimeout.current = undefined;
                        
                      }), 600));
          }
          if (!opened && !disabled) {
            Belt_Option.forEach(tooltipSendOpenEventTimeout.current, (function (prim) {
                    clearTimeout(prim);
                    
                  }));
            if (tooltipSendOpenEventTimeout.current === undefined) {
              Belt_Option.forEach(onHide, (function (cb) {
                      return Curry._1(cb, undefined);
                    }));
            }
            
          }
          return (function (param) {
                    Belt_Option.forEach(tooltipSendOpenEventTimeout.current, (function (prim) {
                            clearTimeout(prim);
                            
                          }));
                    Belt_Option.forEach(tooltipCloseTimeout.current, (function (prim) {
                            clearTimeout(prim);
                            
                          }));
                    return Belt_Option.forEach(tooltipOpenTimeout.current, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), [opened]);
  var onMouseEnter = function (param) {
    Belt_Option.forEach(tooltipCloseTimeout.current, (function (prim) {
            clearTimeout(prim);
            
          }));
    if (!disabled) {
      tooltipOpenTimeout.current = Caml_option.some(setTimeout((function (param) {
                  return Curry._1(action.open_, undefined);
                }), delayIn));
      return ;
    }
    
  };
  var onMouseLeave = function (param) {
    Belt_Option.forEach(tooltipOpenTimeout.current, (function (prim) {
            clearTimeout(prim);
            
          }));
    if (preventDelayOut) {
      if (!disabled && opened) {
        return Curry._1(action.close, undefined);
      } else {
        return ;
      }
    } else if (!disabled && opened) {
      tooltipCloseTimeout.current = Caml_option.some(setTimeout((function (param) {
                  return Curry._1(action.close, undefined);
                }), 250));
      return ;
    } else {
      return ;
    }
  };
  Hooks.useDidUpdate1((function (param) {
          if (disabled) {
            Belt_Option.forEach(tooltipOpenTimeout.current, (function (prim) {
                    clearTimeout(prim);
                    
                  }));
            Curry._1(action.close, undefined);
          }
          
        }), [disabled]);
  var tmp = {
    arrowSize: arrowSize,
    arrowStyles: {
      hd: Css.backgroundColor(Styles.Color.grey80),
      tl: {
        hd: Css.boxSizing("unset"),
        tl: {
          hd: Css.borderColor("transparent"),
          tl: {
            hd: Css.zIndex(-1),
            tl: /* [] */0
          }
        }
      }
    },
    dropdownStyles: {
      hd: Css.borderRadius(Css.px(6)),
      tl: {
        hd: Css.backgroundColor("transparent"),
        tl: {
          hd: Css.boxShadow(Styles.Shadow.box),
          tl: /* [] */0
        }
      }
    },
    opened: opened && !disabled,
    position: customPosition,
    withArrow: true,
    zIndex: Styles.ZIndex.aboveAll + 1 | 0,
    children: null
  };
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = Caml_option.valFromOption(arrowOffset);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  return React.createElement(Mantine.Popover.make, tmp, React.createElement(Core.Popover.Target, {
                  children: React.createElement("span", {
                        onClick: onMouseLeave,
                        onMouseEnter: onMouseEnter,
                        onMouseLeave: onMouseLeave
                      }, children)
                }), React.createElement(Mantine.Popover.Dropdown.make, {
                  children: React.createElement("div", {
                        className: Curry._1(Css.merge, {
                              hd: tooltip,
                              tl: {
                                hd: Curry._1(Css.style, {
                                      hd: Css.color(Styles.Color.grey10),
                                      tl: {
                                        hd: Css.maxWidth(Css.px(customMaxWidth)),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        onMouseEnter: (function (param) {
                            if (preventDelayOut) {
                              return ;
                            } else {
                              return onMouseEnter(undefined);
                            }
                          }),
                        onMouseLeave: (function (param) {
                            if (preventDelayOut) {
                              return ;
                            } else {
                              return onMouseLeave(undefined);
                            }
                          })
                      }, tooltip$1.NAME === "string" ? React.createElement($$Text.make, {
                              size: "Small",
                              children: tooltip$1.VAL
                            }) : tooltip$1.VAL)
                }));
}

var Basic = {
  make: SimpleTooltip$Basic
};

function SimpleTooltip$WithComponent(Props) {
  var arrowSize = Props.arrowSize;
  var arrowOffset = Props.arrowOffset;
  var delayIn = Props.delayIn;
  var disabled = Props.disabled;
  var maxWidth = Props.maxWidth;
  var offset = Props.offset;
  var onHide = Props.onHide;
  var onShow = Props.onShow;
  var position = Props.position;
  var preventDelayOut = Props.preventDelayOut;
  var tooltip = Props.tooltip;
  var children = Props.children;
  var tmp = {
    tooltip: {
      NAME: "component",
      VAL: tooltip
    },
    children: children
  };
  if (arrowSize !== undefined) {
    tmp.arrowSize = Caml_option.valFromOption(arrowSize);
  }
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = Caml_option.valFromOption(arrowOffset);
  }
  if (delayIn !== undefined) {
    tmp.delayIn = Caml_option.valFromOption(delayIn);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (maxWidth !== undefined) {
    tmp.maxWidth = Caml_option.valFromOption(maxWidth);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  if (onHide !== undefined) {
    tmp.onHide = Caml_option.valFromOption(onHide);
  }
  if (onShow !== undefined) {
    tmp.onShow = Caml_option.valFromOption(onShow);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (preventDelayOut !== undefined) {
    tmp.preventDelayOut = Caml_option.valFromOption(preventDelayOut);
  }
  return React.createElement(SimpleTooltip$Basic, tmp);
}

var WithComponent = {
  make: SimpleTooltip$WithComponent
};

function SimpleTooltip(Props) {
  var arrowSize = Props.arrowSize;
  var arrowOffset = Props.arrowOffset;
  var delayIn = Props.delayIn;
  var disabled = Props.disabled;
  var maxWidth = Props.maxWidth;
  var offset = Props.offset;
  var onHide = Props.onHide;
  var onShow = Props.onShow;
  var position = Props.position;
  var preventDelayOut = Props.preventDelayOut;
  var tooltip = Props.tooltip;
  var children = Props.children;
  var tmp = {
    tooltip: {
      NAME: "string",
      VAL: tooltip
    },
    children: children
  };
  if (arrowSize !== undefined) {
    tmp.arrowSize = Caml_option.valFromOption(arrowSize);
  }
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = Caml_option.valFromOption(arrowOffset);
  }
  if (delayIn !== undefined) {
    tmp.delayIn = Caml_option.valFromOption(delayIn);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (maxWidth !== undefined) {
    tmp.maxWidth = Caml_option.valFromOption(maxWidth);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  if (onHide !== undefined) {
    tmp.onHide = Caml_option.valFromOption(onHide);
  }
  if (onShow !== undefined) {
    tmp.onShow = Caml_option.valFromOption(onShow);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (preventDelayOut !== undefined) {
    tmp.preventDelayOut = Caml_option.valFromOption(preventDelayOut);
  }
  return React.createElement(SimpleTooltip$Basic, tmp);
}

var make = SimpleTooltip;

exports.Style = Style;
exports.Basic = Basic;
exports.WithComponent = WithComponent;
exports.make = make;
/* tooltip Not a pure module */
