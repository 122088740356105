// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var DocsLink = require("./DocsLink.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Workspace = require("../../model/src/Workspace.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var PlatformIcon = require("./PlatformIcon.bs.js");
var PlanLightning = require("./PlanLightning.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var InspectorStats = require("./inspector/InspectorStats.bs.js");
var ListLimitsInfo = require("./ListLimitsInfo.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var IconCheckmarkCircle = require("./IconCheckmarkCircle.bs.js");
var ImplementationStatusContext = require("./ImplementationStatusContext.bs.js");

var root = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.borderRadius(Styles.Border.radius),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
            tl: /* [] */0
          }
        }
      }
    });

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.width(Css.pct(100)),
            tl: {
              hd: Css.padding2(Css.px(10), Css.px(15)),
              tl: {
                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var empty = Curry._1(Css.style, {
      hd: Css.padding(Css.px(20)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.flexDirection("column"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var status = Curry._1(Css.style, {
      hd: Css.height(Css.px(15)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

var statusLoading = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.shimmer),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.height(Css.px(9)),
              tl: {
                hd: Css.width(Css.px(160)),
                tl: {
                  hd: Css.borderRadius(Css.px(99)),
                  tl: {
                    hd: Css.marginTop(Css.px(4)),
                    tl: {
                      hd: Css.marginBottom(Css.px(2)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var statusCheckmark = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.marginTop(Css.px(-1)),
        tl: /* [] */0
      }
    });

function source(last, disabled) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.padding2(Css.px(0), Css.px(15)),
                          tl: {
                            hd: Css.borderBottom(Css.px(last ? 0 : 1), "solid", Styles.Color.grey20),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.regular),
                              tl: {
                                hd: Css.width(Css.pct(100)),
                                tl: {
                                  hd: Css.height(Css.px(70)),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.backgroundColor(Styles.Color.grey20),
                                          tl: /* [] */0
                                        }),
                                    tl: {
                                      hd: Css.cursor(disabled ? "default" : "pointer"),
                                      tl: {
                                        hd: Css.textAlign("left"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var sendingTo = Curry._1(Css.style, {
      hd: Css.flexShrink(0.0),
      tl: {
        hd: Css.flexGrow(1.0),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.justifyContent("flexEnd"),
            tl: {
              hd: Css.marginLeft(Css.px(8)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var Style = {
  root: root,
  header: header,
  empty: empty,
  status: status,
  statusLoading: statusLoading,
  statusCheckmark: statusCheckmark,
  source: source,
  sendingTo: sendingTo
};

function SettingsSources(Props) {
  var schema = Props.schema;
  var model = Props.model;
  var onHover = Props.onHover;
  var onLeave = Props.onLeave;
  var globalSend = Props.globalSend;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var workspaceLimits = AvoLimits.computeLimits(workspace, model);
  var availability = AvoLimits.Source.createActionStatus(workspaceLimits);
  var inspectorStats = InspectorStats.useStatsWithCache(schema.id);
  var match = React.useContext(ImplementationStatusContext.context).codegen;
  var implementationSources = match[2];
  var implementationStatus = match[0];
  var handleAddSource = function (param) {
    if (availability === "AvailableDuringTrial" || availability === "Available") {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "CreateSource",
                    VAL: undefined
                  }
                });
    } else {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "BillingPrompt",
                    VAL: "AddSources"
                  }
                });
    }
  };
  var sources = model.sources;
  var match$1 = Workspace.Source.getUsedSlots(workspace, model);
  var match$2 = workspace.trial;
  var match$3 = match$1[1];
  var usedCount = match$1[0];
  var tmp;
  if (typeof match$3 === "object" && match$3.NAME === "Limited") {
    var availableCount = match$3.VAL;
    var exit = 0;
    if (match$2 !== undefined) {
      if (usedCount > availableCount) {
        tmp = React.createElement(ListLimitsInfo.make, {
              title: "You are using " + String(usedCount) + " sources with a limit of " + String(availableCount) + " on the " + Workspace.printPlanName(workspace.plan.name) + " plan.",
              description: "To continue using all your sources after the trial expires, you'll need to upgrade your plan.",
              globalSend: globalSend,
              paywallName: "AddSources"
            });
      } else {
        exit = 1;
      }
    } else if (usedCount > availableCount) {
      tmp = React.createElement(ListLimitsInfo.make, {
            title: "You are using " + String(usedCount) + " sources with a limit of " + String(availableCount) + " on the " + Workspace.printPlanName(workspace.plan.name) + " plan.",
            description: "A plan upgrade is needed to keep using all your sources.",
            globalSend: globalSend,
            paywallName: "AddSources"
          });
    } else if (usedCount === availableCount) {
      tmp = React.createElement(ListLimitsInfo.make, {
            title: "You are using " + String(usedCount) + " out of " + String(availableCount) + " sources on the " + Workspace.printPlanName(workspace.plan.name) + " plan.",
            description: "To add more sources, you'll need to upgrade your plan.",
            globalSend: globalSend,
            paywallName: "AddSources"
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = usedCount > 0 ? React.createElement(ListLimitsInfo.make, {
              title: "You are using " + String(usedCount) + " out of " + String(availableCount) + " sources on the " + Workspace.printPlanName(workspace.plan.name) + " plan.",
              globalSend: globalSend,
              paywallName: "AddSources"
            }) : null;
    }
    
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: root
            }, React.createElement("div", {
                  className: header
                }, React.createElement($$Text.make, {
                      size: "Large",
                      weight: "Semi",
                      children: "Sources"
                    }), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement(DocsLink.make, {
                      path: "workspace/connections#sources"
                    }), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), React.createElement(Button.make, {
                      icon: availability === "Available" ? "plus" : "lightning",
                      label: "New Source",
                      onClick: handleAddSource
                    })), sources ? Belt_List.toArray(Belt_List.mapWithIndex(sources, (function (index, source$1) {
                          var match = AvoLimits.Source.entityStatus(workspaceLimits, source$1.id);
                          var sourceAvailableOnPlan = match[1];
                          var sourceAvailability = match[0];
                          var disabled = sourceAvailability === "Unavailable";
                          var inspectorStatus = InspectorStats.getSourceStatus(model, source$1, inspectorStats);
                          var codegenConnected = Belt_Option.mapWithDefault(Js_dict.get(implementationSources, source$1.id), false, (function (invocations) {
                                  return !Belt_MapString.isEmpty(invocations);
                                }));
                          var match$1 = source$1.platform;
                          var tmp;
                          var exit = 0;
                          var exit$1 = 0;
                          if (match$1 !== undefined) {
                            if (implementationStatus === "Ready") {
                              if (codegenConnected) {
                                exit$1 = 2;
                              } else {
                                switch (inspectorStatus) {
                                  case /* Loading */0 :
                                      exit$1 = 2;
                                      break;
                                  case /* Unavailable */1 :
                                      tmp = React.createElement("div", {
                                            className: status
                                          }, React.createElement($$Text.make, {
                                                element: "Span",
                                                size: "Small",
                                                weight: "Semi",
                                                color: Styles.Color.grey50,
                                                children: "Codegen available"
                                              }));
                                      break;
                                  case /* Available */2 :
                                      tmp = React.createElement("div", {
                                            className: status
                                          }, React.createElement($$Text.make, {
                                                element: "Span",
                                                size: "Small",
                                                weight: "Semi",
                                                singleLine: true,
                                                color: Styles.Color.grey50,
                                                children: "Codegen and Inspector available"
                                              }));
                                      break;
                                  case /* Receiving */3 :
                                      tmp = React.createElement("div", {
                                            className: status
                                          }, React.createElement("div", {
                                                className: statusCheckmark
                                              }, React.createElement(IconCheckmarkCircle.make, {
                                                    size: 12,
                                                    color: Styles.Color.mintGreen
                                                  })), React.createElement(Spacer.make, {
                                                width: 4
                                              }), React.createElement($$Text.make, {
                                                element: "Span",
                                                size: "Small",
                                                weight: "Semi",
                                                color: Styles.Color.mintGreen,
                                                children: "Inspector"
                                              }), React.createElement(Spacer.make, {
                                                width: 8
                                              }), React.createElement($$Text.make, {
                                                element: "Span",
                                                size: "Small",
                                                weight: "Semi",
                                                color: Styles.Color.grey50,
                                                children: "Codegen available"
                                              }));
                                      break;
                                  
                                }
                              }
                            } else {
                              exit = 1;
                            }
                          } else {
                            tmp = null;
                          }
                          if (exit$1 === 2) {
                            if (inspectorStatus !== 0) {
                              tmp = React.createElement("div", {
                                    className: status
                                  }, React.createElement("div", {
                                        className: statusCheckmark
                                      }, React.createElement(IconCheckmarkCircle.make, {
                                            size: 12,
                                            color: Styles.Color.mintGreen
                                          })), React.createElement(Spacer.make, {
                                        width: 4
                                      }), React.createElement($$Text.make, {
                                        element: "Span",
                                        size: "Small",
                                        weight: "Semi",
                                        color: Styles.Color.mintGreen,
                                        children: "Codegen"
                                      }), React.createElement(Spacer.make, {
                                        width: 8
                                      }), inspectorStatus !== 2 ? (
                                      inspectorStatus >= 3 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                  className: statusCheckmark
                                                }, React.createElement(IconCheckmarkCircle.make, {
                                                      size: 12,
                                                      color: Styles.Color.mintGreen
                                                    })), React.createElement(Spacer.make, {
                                                  width: 4
                                                }), React.createElement($$Text.make, {
                                                  element: "Span",
                                                  size: "Small",
                                                  weight: "Semi",
                                                  color: Styles.Color.mintGreen,
                                                  children: "Inspector"
                                                })) : null
                                    ) : React.createElement($$Text.make, {
                                          element: "Span",
                                          size: "Small",
                                          weight: "Semi",
                                          color: Styles.Color.grey50,
                                          children: "Inspector available"
                                        }));
                            } else {
                              exit = 1;
                            }
                          }
                          if (exit === 1) {
                            tmp = React.createElement("div", {
                                  className: statusLoading
                                });
                          }
                          var match$2 = Belt_List.length(AvoUtils.filterInvalidDestinations(source$1.platform, source$1.language, StateUtils.getSourceDestinationIds(source$1.destinations), model.destinations));
                          return React.createElement("button", {
                                      key: source$1.id,
                                      className: source(Belt_List.length(sources) === (index + 1 | 0), disabled),
                                      disabled: disabled,
                                      onClick: (function (param) {
                                          return Router.Schema.pushDrawerItem(undefined, {
                                                      NAME: "source",
                                                      VAL: [
                                                        source$1.id,
                                                        "overview"
                                                      ]
                                                    });
                                        }),
                                      onMouseEnter: (function (param) {
                                          return Curry._1(onHover, source$1.id);
                                        }),
                                      onMouseLeave: (function (param) {
                                          return Curry._1(onLeave, source$1.id);
                                        })
                                    }, React.createElement(PlatformIcon.make, {
                                          platform: source$1.platform,
                                          sizeWithBorder: 34
                                        }), React.createElement(Spacer.make, {
                                          width: 12
                                        }), React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.minWidth(Css.px(0)),
                                                tl: /* [] */0
                                              })
                                        }, React.createElement($$Text.make, {
                                              element: "Div",
                                              size: "Medium",
                                              weight: "Semi",
                                              singleLine: true,
                                              color: Styles.Color.grey90,
                                              children: AvoConfig.getSourceName(source$1)
                                            }), tmp), sourceAvailability === "Available" ? null : (
                                        sourceAvailability === "AvailableDuringTrial" ? React.createElement("span", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.margin2(Css.px(0), Css.px(10)),
                                                      tl: {
                                                        hd: Css_Legacy_Core.SVG.fill("transparent"),
                                                        tl: {
                                                          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey50),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    })
                                              }, React.createElement(PlanLightning.make, {
                                                    tooltipText: "Available during trial and on the " + (Belt_Option.mapWithDefault(sourceAvailableOnPlan, "", (function (plan) {
                                                              return Workspace.printPlanName(plan.name);
                                                            })) + " plan"),
                                                    tooltipDirection: "right"
                                                  })) : React.createElement("span", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.margin2(Css.px(0), Css.px(10)),
                                                      tl: {
                                                        hd: Css_Legacy_Core.SVG.fill("transparent"),
                                                        tl: {
                                                          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey50),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    })
                                              }, React.createElement(PlanLightning.make, {
                                                    tooltipText: "Available on the " + (Belt_Option.mapWithDefault(sourceAvailableOnPlan, "", (function (plan) {
                                                              return Workspace.printPlanName(plan.name);
                                                            })) + " plan"),
                                                    tooltipDirection: "right"
                                                  }))
                                      ), React.createElement("div", {
                                          className: sendingTo
                                        }, React.createElement($$Text.make, {
                                              element: "Span",
                                              size: "Small",
                                              color: Styles.Color.grey70,
                                              children: sourceAvailability === "Unavailable" ? null : (
                                                  match$2 !== 0 ? (
                                                      match$2 !== 1 ? "Sending to " + String(match$2) + " destinations" : "Sending to 1 destination"
                                                    ) : React.createElement($$Text.make, {
                                                          element: "Span",
                                                          color: Styles.Color.grapeError,
                                                          children: "Not sending to any destinations"
                                                        })
                                                )
                                            })));
                        }))) : React.createElement("div", {
                    className: empty
                  }, React.createElement($$Text.make, {
                        size: "Large",
                        weight: "Semi",
                        textAlign: "center",
                        color: Styles.Color.grey50,
                        children: "No sources"
                      }), React.createElement(Spacer.make, {
                        height: 12
                      }), React.createElement($$Text.make, {
                        size: "Medium",
                        textAlign: "center",
                        color: Styles.Color.grey50,
                        children: "Sources represent where you send your data from. These could be mobile apps, websites, or backends."
                      })), tmp);
}

var make = SettingsSources;

exports.Style = Style;
exports.make = make;
/* root Not a pure module */
