// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Caml = require("rescript/lib/js/caml.js");
var Case = require("../../bs-case/src/case.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Case$1 = require("case");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("./Icons.bs.js");
var Title = require("./Title.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Switch = require("./Switch.bs.js");
var Actions = require("./actions.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var AvoModel = require("./avoModel.bs.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameUtils = require("../../shared/utils/NameUtils.bs.js");
var ActionDiff = require("../../shared/ActionDiff.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var PortalMenu = require("./PortalMenu.bs.js");
var SheetTypes = require("./SheetTypes.bs.js");
var SheetUtils = require("./SheetUtils.bs.js");
var SourcePill = require("./SourcePill.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var TextButton = require("./TextButton.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var EventsEmpty = require("./emptystates/EventsEmpty.bs.js");
var NamedBranch = require("./NamedBranch.bs.js");
var RouterStore = require("./RouterStore.bs.js");
var SheetHeader = require("./SheetHeader.bs.js");
var EditableCell = require("./EditableCell.bs.js");
var ReactWindow = require("react-window");
var CellEditPopup = require("./CellEditPopup.bs.js");
var FirebaseUtils = require("./firebaseUtils.bs.js");
var PortalTooltip = require("./PortalTooltip.bs.js");
var FramerMotion = require("framer-motion");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var SegmentControl = require("./SegmentControl.bs.js");
var SheetContainer = require("./SheetContainer.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var SideEffectUtils = require("./SideEffectUtils.bs.js");
var ReactDraggable = require("react-draggable");
var StateFilterUtils = require("./StateFilterUtils.bs.js");
var FilterQueryParams = require("./FilterQueryParams.bs.js");
var Js_null_undefined = require("rescript/lib/js/js_null_undefined.js");
var DiscrepancyContext = require("./DiscrepancyContext.bs.js");
var EventsSheetFilters = require("./EventsSheetFilters.bs.js");
var GlobalStateContext = require("./GlobalStateContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var SheetSectionFooter = require("./SheetSectionFooter.bs.js");
var SheetSectionHeader = require("./SheetSectionHeader.bs.js");
var BeltArrayExtensions = require("./BeltArrayExtensions.bs.js");
var EditableCellListItem = require("./EditableCellListItem.bs.js");
var ImplementationStatusContext = require("./ImplementationStatusContext.bs.js");
var ReactVirtualizedAutoSizer = require("react-virtualized-auto-sizer").default;

function renderModelFromBranchModel(branchModel) {
  return branchModel._0;
}

var defaultColumns = [
  {
    columnType: "EventName",
    width: 320,
    order: 0,
    visible: true
  },
  {
    columnType: "Category",
    width: 200,
    order: 1,
    visible: true
  },
  {
    columnType: "PropertyGroups",
    width: 200,
    order: 2,
    visible: true
  },
  {
    columnType: "EventProperties",
    width: 200,
    order: 3,
    visible: true
  },
  {
    columnType: "GroupProperties",
    width: 200,
    order: 4,
    visible: true
  },
  {
    columnType: "Sources",
    width: 200,
    order: 5,
    visible: true
  },
  {
    columnType: "Actions",
    width: 200,
    order: 6,
    visible: true
  },
  {
    columnType: "Tags",
    width: 200,
    order: 7,
    visible: true
  },
  {
    columnType: "Destinations",
    width: 200,
    order: 8,
    visible: false
  },
  {
    columnType: "Metrics",
    width: 200,
    order: 9,
    visible: false
  }
];

function getGetNewAndChangedRowCounts(rows) {
  return Belt_Array.reduceU(rows, [
              0,
              0
            ], (function (param, row) {
                var changedCount = param[1];
                var newCount = param[0];
                if (typeof row === "number") {
                  return [
                          newCount,
                          changedCount
                        ];
                }
                if (row.TAG !== /* WorkbenchEvent */1) {
                  return [
                          newCount,
                          changedCount
                        ];
                }
                switch (row._2) {
                  case /* Unmodified */0 :
                      return [
                              newCount,
                              changedCount
                            ];
                  case /* Modified */1 :
                      return [
                              newCount,
                              changedCount + 1 | 0
                            ];
                  case /* New */2 :
                      return [
                              newCount + 1 | 0,
                              changedCount
                            ];
                  
                }
              }));
}

function rootStyles(selected, withDetails, groupedByCategory, outerBorderColor, customBackgroundColor, disabled) {
  var customBackgroundColor$1 = groupedByCategory ? Css.transparent : customBackgroundColor;
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.borderRight(Css.px(groupedByCategory ? 1 : 0), "solid", outerBorderColor),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.height(Css.pct(100)),
                          tl: {
                            hd: Css.textAlign("left"),
                            tl: {
                              hd: selected ? Styles.backgroundColorWithOverlayGuarantee(customBackgroundColor$1, Styles.Color.setAlpha(Styles.Color.grey90, 0.05)) : Styles.backgroundColorGuarantee(customBackgroundColor$1),
                              tl: {
                                hd: Css.minWidth(groupedByCategory && !withDetails ? ({
                                          NAME: "substract",
                                          VAL: [
                                            Css.pct(100),
                                            Css.px(16)
                                          ]
                                        }) : Css.pct(100.0)),
                                tl: {
                                  hd: disabled ? Css.cursor("default") : Styles.emptyStyle,
                                  tl: {
                                    hd: Css.hover({
                                          hd: disabled ? Styles.emptyStyle : Css.selector(" > div", {
                                                  hd: Styles.backgroundColorWithOverlayGuarantee(customBackgroundColor$1, Styles.Color.setAlpha(Styles.Color.grey100, 0.02)),
                                                  tl: /* [] */0
                                                }),
                                          tl: /* [] */0
                                        }),
                                    tl: {
                                      hd: Css.position("relative"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function stickyCellStyles(customWidth, groupedByCategory, selected, innerBorderColor, outerBorderColor, customBackgroundColor) {
  var customBackgroundColor$1 = groupedByCategory ? Css.transparent : customBackgroundColor;
  return Curry._1(Css.style, {
              hd: selected ? Styles.backgroundColorWithOverlayGuarantee(customBackgroundColor$1, Styles.Color.setAlpha(Styles.Color.grey90, 0.06)) : Styles.backgroundColorGuarantee(customBackgroundColor$1),
              tl: {
                hd: Css.justifyContent("center"),
                tl: {
                  hd: Css.alignItems("flexStart"),
                  tl: {
                    hd: Css.flexDirection("column"),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.position("sticky"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.left(Css.px(0)),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: {
                                hd: Css.width(Css.px(customWidth)),
                                tl: {
                                  hd: Css.height(Css.pct(100)),
                                  tl: {
                                    hd: Css.paddingLeft(Css.px(16)),
                                    tl: {
                                      hd: Css.paddingRight(Css.px(16)),
                                      tl: {
                                        hd: Css.borderRight(Css.px(1), "solid", innerBorderColor),
                                        tl: {
                                          hd: Css.borderBottom(Css.px(1), "solid", innerBorderColor),
                                          tl: {
                                            hd: Css.borderLeft(Css.px(groupedByCategory ? 1 : 0), "solid", outerBorderColor),
                                            tl: {
                                              hd: Css.marginRight(Css.px(-1)),
                                              tl: {
                                                hd: Css.zIndex(1),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function cellStylesForGroupedByCategory(withDetails, editable, customWidth, innerBorderColor, outerBorderColor) {
  return Curry._1(Css.style, {
              hd: Css.hover(editable ? ({
                        hd: Css.important(Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.blue, 0.1))),
                        tl: /* [] */0
                      }) : /* [] */0),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("flexStart"),
                  tl: {
                    hd: Css.height(Css.pct(100)),
                    tl: {
                      hd: Css.width(Css.px(customWidth)),
                      tl: {
                        hd: Css.backgroundColor("transparent"),
                        tl: {
                          hd: Css.flexShrink(0.0),
                          tl: {
                            hd: Css.overflow("hidden"),
                            tl: {
                              hd: Css.borderLeft(Css.px(1), "solid", innerBorderColor),
                              tl: {
                                hd: Css.borderBottom(Css.px(1), "solid", innerBorderColor),
                                tl: {
                                  hd: Css.paddingTop(Css.px(2)),
                                  tl: {
                                    hd: Css.paddingRight(Css.px(2)),
                                    tl: {
                                      hd: Css.paddingBottom(Css.px(2)),
                                      tl: {
                                        hd: Css.paddingLeft(Css.px(12)),
                                        tl: {
                                          hd: Css.lastChild(withDetails ? ({
                                                    hd: Css.flexGrow(1.0),
                                                    tl: {
                                                      hd: Css.width(Css.px(customWidth + 1 | 0)),
                                                      tl: {
                                                        hd: Css.marginRight(Css.px(716)),
                                                        tl: {
                                                          hd: Css.borderRight(Css.px(1), "solid", outerBorderColor),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }) : ({
                                                    hd: Css.flexGrow(1.0),
                                                    tl: /* [] */0
                                                  })),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function cellStyles(customWidth, withDetails, editable, groupedByCategory, innerBorderColor, outerBorderColor) {
  if (groupedByCategory) {
    return cellStylesForGroupedByCategory(withDetails, editable, customWidth, innerBorderColor, outerBorderColor);
  } else {
    return Curry._1(Css.style, {
                hd: Css.hover(editable ? ({
                          hd: Css.important(Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.blue, 0.1))),
                          tl: /* [] */0
                        }) : /* [] */0),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.alignItems("flexStart"),
                    tl: {
                      hd: Css.height(Css.pct(100)),
                      tl: {
                        hd: Css.width(Css.px(customWidth)),
                        tl: {
                          hd: Css.maxWidth(Css.px(customWidth)),
                          tl: {
                            hd: Css.backgroundColor("transparent"),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: {
                                hd: Css.overflow("hidden"),
                                tl: {
                                  hd: Css.borderLeft(Css.px(1), "solid", innerBorderColor),
                                  tl: {
                                    hd: Css.borderBottom(Css.px(1), "solid", innerBorderColor),
                                    tl: {
                                      hd: Css.paddingTop(Css.px(2)),
                                      tl: {
                                        hd: Css.paddingRight(Css.px(2)),
                                        tl: {
                                          hd: Css.paddingBottom(Css.px(2)),
                                          tl: {
                                            hd: Css.paddingLeft(Css.px(12)),
                                            tl: {
                                              hd: Css.lastChild(withDetails ? ({
                                                        hd: Css.width(Css.px(customWidth + 600 | 0)),
                                                        tl: {
                                                          hd: Css.paddingRight(Css.px(customWidth + 600 | 0)),
                                                          tl: /* [] */0
                                                        }
                                                      }) : ({
                                                        hd: Css.minWidth(Css.px(customWidth)),
                                                        tl: {
                                                          hd: Css.width("unset"),
                                                          tl: {
                                                            hd: Css.flexGrow(1.0),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      })),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
  }
}

function cellContentStyles(wrapOpt, param) {
  var wrap = wrapOpt !== undefined ? wrapOpt : "wrap";
  return Curry._1(Css.style, {
              hd: Css.flexWrap(wrap),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.minHeight(Css.pct(100.0)),
                    tl: /* [] */0
                  }
                }
              }
            });
}

function getColumnWidthByType(columns, columnType) {
  return Belt_Option.mapWithDefault(Belt_Array.getByU(columns, (function (column) {
                    return column.columnType === columnType;
                  })), 200, (function (param) {
                return param.width;
              }));
}

function isColumnVisibleByType(columns, columnType) {
  return Belt_Option.mapWithDefault(Belt_Array.getByU(columns, (function (column) {
                    return column.columnType === columnType;
                  })), true, (function (param) {
                return param.visible;
              }));
}

function indicatorStyles(modificationState) {
  return Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.top(Css.px(0)),
                tl: {
                  hd: Css.left(Css.px(0)),
                  tl: {
                    hd: Css.height(Css.pct(100)),
                    tl: {
                      hd: Css.paddingTop(Css.px(2)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(2)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(3)),
                          tl: {
                            hd: Css.paddingRight(Css.px(4)),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: {
                                  hd: Css.zIndex(5),
                                  tl: {
                                    hd: Css.after({
                                          hd: Css.display("block"),
                                          tl: {
                                            hd: Css.unsafe("content", "' '"),
                                            tl: {
                                              hd: Css.backgroundColor(modificationState === /* New */2 ? Styles.Color.mintGreen : Styles.Color.purple),
                                              tl: {
                                                hd: Css.width(Css.px(2)),
                                                tl: {
                                                  hd: Css.height(Css.pct(100)),
                                                  tl: {
                                                    hd: Css.borderRadius(Css.px(2)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function EventsSheet$EventRow$ModifiedIndicator(Props) {
  var modificationState = Props.modificationState;
  return React.createElement(PortalTooltip.make, {
              renderTooltip: (function (param) {
                  var tmp;
                  switch (modificationState) {
                    case /* Unmodified */0 :
                        tmp = "";
                        break;
                    case /* Modified */1 :
                        tmp = "This event has changes on this branch";
                        break;
                    case /* New */2 :
                        tmp = "This event is new on this branch";
                        break;
                    
                  }
                  return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                  height: 4
                                }), React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.white,
                                  children: tmp
                                }));
                }),
              position: "Right",
              children: React.createElement("div", {
                    className: indicatorStyles(modificationState)
                  })
            });
}

var ModifiedIndicator = {
  indicatorStyles: indicatorStyles,
  make: EventsSheet$EventRow$ModifiedIndicator
};

function EventsSheet$EventRow$NameCell(Props) {
  var columns = Props.columns;
  var eventDescription = Props.eventDescription;
  var eventName = Props.eventName;
  var groupedByCategory = Props.groupedByCategory;
  var modificationState = Props.modificationState;
  var selected = Props.selected;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var backgroundColor = Props.backgroundColor;
  return React.createElement("div", {
              className: stickyCellStyles(getColumnWidthByType(columns, "EventName"), groupedByCategory, selected, innerBorderColor, outerBorderColor, backgroundColor)
            }, modificationState !== /* Unmodified */0 ? React.createElement(EventsSheet$EventRow$ModifiedIndicator, {
                    modificationState: modificationState
                  }) : null, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Medium",
                  singleLine: true,
                  color: Styles.Color.grey90,
                  maxWidth: Css.pct(100),
                  children: eventName
                }), eventDescription !== "" ? React.createElement($$Text.make, {
                    size: "Small",
                    singleLine: true,
                    color: Styles.Color.grey80,
                    maxWidth: Css.pct(100),
                    children: eventDescription
                  }) : React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.orangeSecondary,
                    children: "No description"
                  }));
}

var NameCell = {
  make: EventsSheet$EventRow$NameCell
};

var EditableCategoriesCell = CellEditPopup.MakeEditableCell({
      Cell: EditableCell.Categories,
      make: EditableCellListItem.Category.make
    });

function EventsSheet$EventRow$CategoryCell(Props) {
  var allCategories = Props.allCategories;
  var columns = Props.columns;
  var eventId = Props.eventId;
  var eventLocation = Props.eventLocation;
  var eventName = Props.eventName;
  var globalSend = Props.globalSend;
  var groupedByCategory = Props.groupedByCategory;
  var role = Props.role;
  var withDetails = Props.withDetails;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var selectedItems = Belt_Array.keepU(allCategories, (function (category) {
          return Belt_List.has(category.events, eventId, (function (prim0, prim1) {
                        return prim0 === prim1;
                      }));
        }));
  var onToggle = function (param, categoryId, toggled) {
    AnalyticsRe.eventsViewPopupInteraction(schemaGroup, toggled ? "ItemAdded" : "ItemRemoved", "Category", "Category", eventLocation, schemaGroup.branchId, schemaGroup.schemaId);
    var context = {
      eventId: eventId,
      eventQuery: eventId,
      goalId: categoryId,
      goalQuery: categoryId
    };
    var categoryName = Belt_Option.mapWithDefault(Belt_Array.getByU(allCategories, (function (category) {
                return category.id === categoryId;
              })), "", (function (param) {
            return param.name;
          }));
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    return AnalyticsRe.categoryUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), categoryName, categoryId, toggled ? "EventAdded" : "EventRemoved", "N/A", undefined, eventName, eventId, NamedBranch.getId(branch), schemaGroup.schemaId);
                  }),
                undefined,
                toggled ? [[
                      {
                        NAME: "AddEventToGoal",
                        VAL: [
                          categoryId,
                          eventId
                        ]
                      },
                      context
                    ]] : [[
                      {
                        NAME: "RemoveEventFromGoal",
                        VAL: [
                          categoryId,
                          eventId
                        ]
                      },
                      context
                    ]]
              ]);
  };
  var onInteraction = React.useCallback((function (action) {
          return AnalyticsRe.eventsViewPopupInteraction(schemaGroup, action, "Category", "Category", eventLocation, schemaGroup.branchId, schemaGroup.schemaId);
        }), [schemaGroup]);
  return React.createElement(EditableCategoriesCell.make, {
              allItems: allCategories,
              children: React.createElement("div", {
                    className: cellContentStyles(undefined, undefined)
                  }, React.createElement($$Text.make, {
                        size: "Tiny",
                        color: Styles.Color.grey80,
                        children: Belt_Array.mapU(Belt_Array.keepU(allCategories, (function (category) {
                                      return Belt_List.has(category.events, eventId, (function (prim0, prim1) {
                                                    return prim0 === prim1;
                                                  }));
                                    })), (function (category) {
                                  return category.name;
                                })).join(", ")
                      })),
              className: cellStyles(getColumnWidthByType(columns, "Category"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor),
              onCreates: (function (hidePopup) {
                  return [[
                            (function (searchTerm) {
                                Curry._1(hidePopup, undefined);
                                return Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "CreateCategory",
                                              VAL: [
                                                searchTerm,
                                                (function (categoryId, categoryName) {
                                                    AnalyticsRe.categoryUpdated(schemaGroup, categoryName, categoryId, "EventAdded", "N/A", undefined, eventName, eventId, schemaGroup.branchId, schemaGroup.schemaId);
                                                    return [[
                                                              {
                                                                NAME: "AddEventToGoal",
                                                                VAL: [
                                                                  categoryId,
                                                                  eventId
                                                                ]
                                                              },
                                                              {
                                                                eventId: eventId,
                                                                eventQuery: eventId,
                                                                goalId: categoryId,
                                                                goalQuery: categoryId
                                                              }
                                                            ]];
                                                  })
                                              ]
                                            }
                                          });
                              }),
                            (function (searchTerm) {
                                return "+ Create " + searchTerm;
                              })
                          ]];
                }),
              disabled: withDetails,
              onToggle: onToggle,
              role: role,
              selectedItems: selectedItems,
              onItemClicked: (function (param) {
                  return Curry._1(onInteraction, "ItemClicked");
                }),
              onHover: (function (param) {
                  return AnalyticsRe.eventsViewInteraction(schemaGroup, "Category", eventLocation, schemaGroup.branchId, schemaGroup.schemaId);
                }),
              onSearch: (function (_value) {
                  return Curry._1(onInteraction, "SearchedForItem");
                }),
              onCreateInitiated: (function (_searchTerm) {
                  return Curry._1(onInteraction, "ItemCreationInitiated");
                })
            });
}

var CategoryCell = {
  make: EventsSheet$EventRow$CategoryCell
};

var EditablePropertyGroupsCell = CellEditPopup.MakeEditableCell({
      Cell: EditableCell.PropertyGroups,
      make: EditableCellListItem.PropertyGroup.make
    });

function EventsSheet$EventRow$PropertyGroupsCell(Props) {
  var allPropertyGroups = Props.allPropertyGroups;
  var columns = Props.columns;
  var eventId = Props.eventId;
  var eventLocation = Props.eventLocation;
  var eventName = Props.eventName;
  var eventIncludeSources = Props.eventIncludeSources;
  var globalSend = Props.globalSend;
  var groupedByCategory = Props.groupedByCategory;
  var modelEvents = Props.modelEvents;
  var numTriggersOnEvent = Props.numTriggersOnEvent;
  var role = Props.role;
  var selectedItems = Props.selectedItems;
  var withDetails = Props.withDetails;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var correctCase = NameUtils.isCorrectCase(expectedEventCase, eventName);
  var onToggle = React.useCallback((function (_searchTerm) {
          return function (propertyGroupId, toggled) {
            AnalyticsRe.eventsViewPopupInteraction(schemaGroup, toggled ? "ItemAdded" : "ItemRemoved", "PropertyGroups", "PropertyGroup", eventLocation, schemaGroup.branchId, schemaGroup.schemaId);
            var context = {
              eventId: eventId,
              eventQuery: eventId,
              propertyGroupId: propertyGroupId,
              propertyGroupQuery: propertyGroupId
            };
            return Curry.app(sendActions, [
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        (function (branch) {
                            return AnalyticsRe.eventUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.source("N/A", "N/A"), eventId, eventName, toggled ? "AddPropertyGroup" : "RemovePropertyGroup", propertyGroupId, Belt_Option.map(Belt_Array.getByU(allPropertyGroups, (function (param) {
                                                  return propertyGroupId === param.id;
                                                })), (function (param) {
                                              return param.name;
                                            })), Belt_List.toArray(StateUtils.getIncludedSourceIds(eventIncludeSources)), Belt_List.toArray(StateUtils.getIncludedSourceIds(eventIncludeSources)), undefined, undefined, undefined, undefined, Belt_List.length(modelEvents), undefined, undefined, undefined, undefined, "Events", numTriggersOnEvent, correctCase, expectedEventCase, eventId, schemaGroup.schemaId, NamedBranch.getId(branch), eventId, undefined, eventName);
                          }),
                        undefined,
                        toggled ? [[
                              {
                                NAME: "AddPropertyGroupToEvent",
                                VAL: [
                                  eventId,
                                  propertyGroupId
                                ]
                              },
                              context
                            ]] : [[
                              {
                                NAME: "RemovePropertyGroupFromEvent",
                                VAL: [
                                  eventId,
                                  propertyGroupId
                                ]
                              },
                              context
                            ]]
                      ]);
          };
        }), [
        allPropertyGroups,
        eventIncludeSources,
        schemaGroup
      ]);
  var onInteraction = React.useCallback((function (action) {
          return AnalyticsRe.eventsViewPopupInteraction(schemaGroup, action, "PropertyGroups", "PropertyGroup", eventLocation, schemaGroup.branchId, schemaGroup.schemaId);
        }), [schemaGroup]);
  return React.createElement(EditablePropertyGroupsCell.make, {
              allItems: allPropertyGroups,
              children: React.createElement("div", {
                    className: cellContentStyles(undefined, undefined)
                  }, React.createElement($$Text.make, {
                        size: "Tiny",
                        lines: 3,
                        color: Styles.Color.grey80,
                        children: Belt_Array.mapU(selectedItems, (function (group) {
                                  return group.name;
                                })).join(", ")
                      })),
              className: cellStyles(getColumnWidthByType(columns, "PropertyGroups"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor),
              onCreates: (function (hidePopup) {
                  return [[
                            (function (searchTerm) {
                                return Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "NewPropertyGroup",
                                              VAL: [
                                                searchTerm,
                                                (function (propertyGroupId) {
                                                    Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [[
                                                              {
                                                                NAME: "AddPropertyGroupToEvent",
                                                                VAL: [
                                                                  eventId,
                                                                  propertyGroupId
                                                                ]
                                                              },
                                                              {
                                                                eventId: eventId,
                                                                eventQuery: eventId,
                                                                propertyGroupId: propertyGroupId,
                                                                propertyGroupQuery: propertyGroupId
                                                              }
                                                            ]]
                                                        ]);
                                                    return Curry._1(hidePopup, undefined);
                                                  })
                                              ]
                                            }
                                          });
                              }),
                            (function (searchTerm) {
                                return "+ Create " + searchTerm;
                              })
                          ]];
                }),
              disabled: withDetails,
              onToggle: onToggle,
              role: role,
              selectedItems: selectedItems,
              onItemClicked: (function (param) {
                  return Curry._1(onInteraction, "ItemClicked");
                }),
              onHover: (function (param) {
                  return AnalyticsRe.eventsViewInteraction(schemaGroup, "PropertyGroups", eventLocation, schemaGroup.branchId, schemaGroup.schemaId);
                }),
              onSearch: (function (_value) {
                  return Curry._1(onInteraction, "SearchedForItem");
                }),
              onCreateInitiated: (function (_searchTerm) {
                  return Curry._1(onInteraction, "ItemCreationInitiated");
                })
            });
}

var PropertyGroupsCell = {
  make: EventsSheet$EventRow$PropertyGroupsCell
};

var EditableEventPropertiesCell = CellEditPopup.MakeEditableCell({
      Cell: EditableCell.EventProperties,
      make: EditableCellListItem.EventProperty.make
    });

function EventsSheet$EventRow$EventPropertiesCell(Props) {
  var allEventProperties = Props.allEventProperties;
  var columns = Props.columns;
  var currentFilters = Props.currentFilters;
  var eventId = Props.eventId;
  var eventLocation = Props.eventLocation;
  var eventName = Props.eventName;
  var eventProperties = Props.eventProperties;
  var globalSend = Props.globalSend;
  var groupedByCategory = Props.groupedByCategory;
  var model = Props.model;
  var propertyGroups = Props.propertyGroups;
  var role = Props.role;
  var withDetails = Props.withDetails;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var properties = React.useMemo((function () {
          var match = AvoModel.getProperties(model, eventProperties);
          return Belt_List.toArray(match.eventProperties);
        }), [
        model.properties,
        eventProperties
      ]);
  var onToggle = function (searchTerm, propertyId, toggled) {
    AnalyticsRe.eventsViewPopupInteraction(schemaGroup, toggled ? "ItemAdded" : "ItemRemoved", "EventProperties", "EventProperty", eventLocation, schemaGroup.branchId, schemaGroup.schemaId);
    var match = StateUtils.getPropertyById(propertyId, model);
    if (match === undefined) {
      return ;
    }
    if (match.TAG === /* PropertyRef */0) {
      return ;
    }
    var property = match._0;
    var propertyGroup = AvoUtils.getAnalyticsPropertyGroupFromCommonCase(NameUtils.getCommonCase(StateUtils.getPropertyNames(model.properties)), property);
    var nameWordCount = Case.to_("Sentence", property.name).split(" ").length;
    var context = {
      eventId: eventId,
      eventQuery: eventId,
      propertyId: propertyId,
      propertyQuery: propertyId
    };
    if (toggled) {
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      return AnalyticsRe.propertyAdded(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), propertyGroup, currentFilters, eventId, eventName, property.name.length, "Existing", nameWordCount, searchTerm.length, "EventsView", NamedBranch.getId(branch), schemaGroup.schemaId);
                    }),
                  undefined,
                  [[
                      {
                        NAME: "AddPropertyRef",
                        VAL: [
                          eventId,
                          propertyId
                        ]
                      },
                      context
                    ]]
                ]);
    } else {
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      return AnalyticsRe.propertyRemoved(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), propertyGroup, eventId, eventName, "EventsView", NamedBranch.getId(branch), schemaGroup.schemaId);
                    }),
                  undefined,
                  [[
                      {
                        NAME: "RemovePropertyRefV2",
                        VAL: [
                          eventId,
                          propertyId
                        ]
                      },
                      context
                    ]]
                ]);
    }
  };
  var onInteraction = React.useCallback((function (action) {
          return AnalyticsRe.eventsViewPopupInteraction(schemaGroup, action, "EventProperties", "EventProperty", eventLocation, schemaGroup.branchId, schemaGroup.schemaId);
        }), [schemaGroup]);
  var tmp;
  var exit = 0;
  if (propertyGroups.length !== 0 || properties.length !== 0) {
    exit = 1;
  } else {
    tmp = React.createElement($$Text.make, {
          size: "Tiny",
          weight: "Semi",
          color: Styles.Color.orangeSecondary,
          children: "No event properties"
        });
  }
  if (exit === 1) {
    tmp = properties.length !== 0 ? React.createElement($$Text.make, {
            size: "Tiny",
            lines: 3,
            color: Styles.Color.grey80,
            children: null
          }, React.createElement("span", {
                className: Curry._1(Css.style, {
                      hd: Css.fontWeight(Styles.FontWeight.semi),
                      tl: /* [] */0
                    })
              }, String(properties.length)), ": " + Belt_Array.mapU(properties, (function (param) {
                    return param.name;
                  })).join(", ")) : null;
  }
  return React.createElement(EditableEventPropertiesCell.make, {
              allItems: allEventProperties,
              children: React.createElement("div", {
                    className: cellContentStyles(undefined, undefined)
                  }, tmp),
              className: cellStyles(getColumnWidthByType(columns, "EventProperties"), withDetails, !withDetails, groupedByCategory, innerBorderColor, outerBorderColor),
              onCreates: (function (hidePopup) {
                  return [[
                            (function (searchTerm) {
                                return Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "NewProperty",
                                              VAL: [
                                                searchTerm,
                                                /* EventProperty */1,
                                                {
                                                  NAME: "Event",
                                                  VAL: eventId
                                                },
                                                (function (propertyId, _propertyName) {
                                                    Curry._1(hidePopup, undefined);
                                                    return Router.Schema.pushDrawerItem(undefined, {
                                                                NAME: "property",
                                                                VAL: [
                                                                  propertyId,
                                                                  undefined
                                                                ]
                                                              });
                                                  }),
                                                "EventsView"
                                              ]
                                            }
                                          });
                              }),
                            (function (searchTerm) {
                                return "+ Create " + searchTerm;
                              })
                          ]];
                }),
              disabled: withDetails,
              onViewItem: (function (propertyId) {
                  return Router.Schema.pushDrawerItem(undefined, {
                              NAME: "property",
                              VAL: [
                                propertyId,
                                undefined
                              ]
                            });
                }),
              onToggle: onToggle,
              role: role,
              selectedItems: properties,
              onItemClicked: (function (param) {
                  return Curry._1(onInteraction, "ItemClicked");
                }),
              onHover: (function (param) {
                  return AnalyticsRe.eventsViewInteraction(schemaGroup, "EventProperties", eventLocation, schemaGroup.branchId, schemaGroup.schemaId);
                }),
              onSearch: (function (_value) {
                  return Curry._1(onInteraction, "SearchedForItem");
                }),
              onCreateInitiated: (function (_searchTerm) {
                  return Curry._1(onInteraction, "ItemCreationInitiated");
                })
            });
}

var EventPropertiesCell = {
  make: EventsSheet$EventRow$EventPropertiesCell
};

var EditableGroupPropertiesCell = CellEditPopup.MakeEditableCell({
      Cell: EditableCell.GroupProperties,
      make: EditableCellListItem.GroupProperty.make
    });

function EventsSheet$EventRow$GroupPropertiesCell(Props) {
  var allGroupPropertiesWithGroupTypeNames = Props.allGroupPropertiesWithGroupTypeNames;
  var columns = Props.columns;
  var currentFilters = Props.currentFilters;
  var eventId = Props.eventId;
  var eventLocation = Props.eventLocation;
  var eventName = Props.eventName;
  var eventProperties = Props.eventProperties;
  var globalSend = Props.globalSend;
  var groupedByCategory = Props.groupedByCategory;
  var model = Props.model;
  var role = Props.role;
  var withDetails = Props.withDetails;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var propertiesWithGroupTypeNames = React.useMemo((function () {
          var match = AvoModel.getProperties(model, eventProperties);
          return Belt_Array.map(Belt_List.toArray(match.groupProperties), (function (groupProperty) {
                        var groupTypeId = groupProperty.sendAs;
                        return [
                                groupProperty,
                                typeof groupTypeId === "number" ? undefined : StateUtils.getGroupTypeName(groupTypeId._0, model)
                              ];
                      }));
        }), [
        model.properties,
        eventProperties
      ]);
  var onToggle = function (searchTerm, propertyId, toggled) {
    AnalyticsRe.eventsViewPopupInteraction(schemaGroup, toggled ? "ItemAdded" : "ItemRemoved", "GroupProperties", "GroupProperty", eventLocation, schemaGroup.branchId, schemaGroup.schemaId);
    var match = StateUtils.getPropertyById(propertyId, model);
    if (match === undefined) {
      return ;
    }
    if (match.TAG === /* PropertyRef */0) {
      return ;
    }
    var property = match._0;
    var propertyGroup = AvoUtils.getAnalyticsPropertyGroupFromCommonCase(NameUtils.getCommonCase(StateUtils.getPropertyNames(model.properties)), property);
    var nameWordCount = Case.to_("Sentence", property.name).split(" ").length;
    var context = {
      eventId: eventId,
      eventQuery: eventId,
      propertyId: propertyId,
      propertyQuery: propertyId
    };
    if (toggled) {
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      return AnalyticsRe.propertyAdded(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), propertyGroup, currentFilters, eventId, eventName, property.name.length, "Existing", nameWordCount, searchTerm.length, "EventsView", NamedBranch.getId(branch), schemaGroup.schemaId);
                    }),
                  undefined,
                  [[
                      {
                        NAME: "AddPropertyRef",
                        VAL: [
                          eventId,
                          propertyId
                        ]
                      },
                      context
                    ]]
                ]);
    } else {
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      return AnalyticsRe.propertyRemoved(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), propertyGroup, eventId, eventName, "EventsView", NamedBranch.getId(branch), schemaGroup.schemaId);
                    }),
                  undefined,
                  [[
                      {
                        NAME: "RemovePropertyRefV2",
                        VAL: [
                          eventId,
                          propertyId
                        ]
                      },
                      context
                    ]]
                ]);
    }
  };
  var onInteraction = React.useCallback((function (action) {
          return AnalyticsRe.eventsViewPopupInteraction(schemaGroup, action, "GroupProperties", "GroupProperty", eventLocation, schemaGroup.branchId, schemaGroup.schemaId);
        }), [schemaGroup]);
  return React.createElement(EditableGroupPropertiesCell.make, {
              allItems: allGroupPropertiesWithGroupTypeNames,
              children: React.createElement("div", {
                    className: cellContentStyles(undefined, undefined)
                  }, propertiesWithGroupTypeNames.length !== 0 ? React.createElement($$Text.make, {
                          size: "Tiny",
                          lines: 3,
                          color: Styles.Color.grey80,
                          children: null
                        }, React.createElement("span", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: /* [] */0
                                  })
                            }, String(propertiesWithGroupTypeNames.length)), ": " + Belt_Array.mapU(propertiesWithGroupTypeNames, (function (param) {
                                  return Belt_Option.getWithDefault(param[1], "Unknown Group Type") + ("/" + param[0].name);
                                })).join(", ")) : null),
              className: cellStyles(getColumnWidthByType(columns, "GroupProperties"), withDetails, !withDetails, groupedByCategory, innerBorderColor, outerBorderColor),
              onCreates: (function (param) {
                  return Belt_Array.map(model.groupTypes, (function (param) {
                                var groupTypeName = param.name;
                                var groupTypeId = param.id;
                                return [
                                        (function (param) {
                                            return Curry._1(globalSend, {
                                                        TAG: /* OpenModal */4,
                                                        _0: {
                                                          NAME: "NewProperty",
                                                          VAL: [
                                                            param,
                                                            /* GroupProperty */{
                                                              _0: groupTypeId
                                                            },
                                                            {
                                                              NAME: "Event",
                                                              VAL: eventId
                                                            },
                                                            (function (propertyId, _propertyName) {
                                                                return Router.Schema.pushDrawerItem(undefined, {
                                                                            NAME: "property",
                                                                            VAL: [
                                                                              propertyId,
                                                                              undefined
                                                                            ]
                                                                          });
                                                              }),
                                                            "EventsView"
                                                          ]
                                                        }
                                                      });
                                          }),
                                        (function (param) {
                                            return "+ Create " + param + " in " + groupTypeName;
                                          })
                                      ];
                              }));
                }),
              disabled: withDetails,
              onViewItem: (function (propertyId) {
                  return Router.Schema.pushDrawerItem(undefined, {
                              NAME: "property",
                              VAL: [
                                propertyId,
                                undefined
                              ]
                            });
                }),
              onToggle: onToggle,
              role: role,
              selectedItems: propertiesWithGroupTypeNames,
              onItemClicked: (function (param) {
                  return Curry._1(onInteraction, "ItemClicked");
                }),
              onHover: (function (param) {
                  return AnalyticsRe.eventsViewInteraction(schemaGroup, "GroupProperties", eventLocation, schemaGroup.branchId, schemaGroup.schemaId);
                }),
              onSearch: (function (_value) {
                  return Curry._1(onInteraction, "SearchedForItem");
                }),
              onCreateInitiated: (function (_searchTerm) {
                  return Curry._1(onInteraction, "ItemCreationInitiated");
                })
            });
}

var GroupPropertiesCell = {
  make: EventsSheet$EventRow$GroupPropertiesCell
};

function EventsSheet$EventRow$SourcesCell(Props) {
  var columns = Props.columns;
  var withDetails = Props.withDetails;
  var groupedByCategory = Props.groupedByCategory;
  var $$event = Props.event;
  var isEventModified = Props.isEventModified;
  var sources = Props.sources;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var match = AvoConfig.determineSendingToSources(sources, $$event);
  var sourcesSendingEvent = typeof match === "object" ? match.VAL : (
      match === "None" ? /* [] */0 : sources
    );
  var match$1 = ImplementationStatusContext.useEventWithRefetchStatusSubscription($$event.id);
  var eventInvocations = match$1[1];
  var codegenInvocationsFetcherState = match$1[0];
  return React.createElement("div", {
              className: cellStyles(getColumnWidthByType(columns, "Sources"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor)
            }, React.createElement("div", {
                  className: cellContentStyles(undefined, undefined)
                }, sourcesSendingEvent === /* [] */0 ? React.createElement($$Text.make, {
                        size: "Tiny",
                        weight: "Semi",
                        color: Styles.Color.orangeSecondary,
                        children: "No sources"
                      }) : Belt_List.toArray(Belt_List.mapWithIndexU(sourcesSendingEvent, (function (index, source) {
                              if (index === 0) {
                                return React.createElement("div", {
                                            key: $$event.id + source.id,
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.alignItems("center"),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.paddingRight(Css.px(2)),
                                                      tl: /* [] */0
                                                    })
                                              }, React.createElement($$Text.make, {
                                                    size: "Tiny",
                                                    weight: "Semi",
                                                    color: Styles.Color.grey80,
                                                    children: null
                                                  }, React.createElement("span", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                                              tl: /* [] */0
                                                            })
                                                      }, String(Belt_List.size(sourcesSendingEvent))), ":")), React.createElement(SourcePill.make, {
                                                event: $$event,
                                                isEventModified: isEventModified,
                                                eventInvocations: eventInvocations,
                                                codegenInvocationsFetcherState: codegenInvocationsFetcherState,
                                                source: source,
                                                _timeWindowStart: undefined
                                              }));
                              } else {
                                return React.createElement(SourcePill.make, {
                                            event: $$event,
                                            isEventModified: isEventModified,
                                            eventInvocations: eventInvocations,
                                            codegenInvocationsFetcherState: codegenInvocationsFetcherState,
                                            source: source,
                                            _timeWindowStart: undefined,
                                            key: $$event.id + source.id
                                          });
                              }
                            })))));
}

var SourcesCell = {
  make: EventsSheet$EventRow$SourcesCell
};

function EventsSheet$EventRow$ActionsCell(Props) {
  var columns = Props.columns;
  var withDetails = Props.withDetails;
  var groupedByCategory = Props.groupedByCategory;
  var $$event = Props.event;
  var model = Props.model;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var actions = SideEffectUtils.getEventSideEffects(false, $$event, model);
  return React.createElement("div", {
              className: cellStyles(getColumnWidthByType(columns, "Actions"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor),
              title: Belt_List.toArray(Belt_List.keepMapU(actions, (function (action) {
                            if (typeof action === "object" && action.NAME === "UserProperties") {
                              return "User properties:\n" + Belt_List.toArray(Belt_List.mapU(action.VAL, (function (property) {
                                                  return "- " + property.name;
                                                }))).join("\n");
                            }
                            
                          }))).join("\n")
            }, React.createElement("div", {
                  className: cellContentStyles(undefined, undefined)
                }, actions === /* [] */0 ? React.createElement($$Text.make, {
                        size: "Tiny",
                        weight: "Semi",
                        color: Styles.Color.grapeError,
                        children: "No actions"
                      }) : React.createElement($$Text.make, {
                        size: "Tiny",
                        lines: 3,
                        color: Styles.Color.grey80,
                        children: Belt_Array.map(Belt_List.toArray(actions), SideEffectUtils.getSideEffectTableLabel).join(", ")
                      })));
}

var ActionsCell = {
  make: EventsSheet$EventRow$ActionsCell
};

var EditableTagsCell = CellEditPopup.MakeEditableCell({
      Cell: EditableCell.Tags,
      make: EditableCellListItem.Tag.make
    });

function EventsSheet$EventRow$TagsCell(Props) {
  var allTags = Props.allTags;
  var columns = Props.columns;
  var eventId = Props.eventId;
  var eventLocation = Props.eventLocation;
  var eventName = Props.eventName;
  var groupedByCategory = Props.groupedByCategory;
  var role = Props.role;
  var tags = Props.tags;
  var withDetails = Props.withDetails;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var onToggle = function (_searchTerm, tag, toggled) {
    AnalyticsRe.eventsViewPopupInteraction(schemaGroup, toggled ? "ItemAdded" : "ItemRemoved", "Tags", "Tag", eventLocation, schemaGroup.branchId, schemaGroup.schemaId);
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    if (toggled) {
                      return AnalyticsRe.eventTagsUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "Add", tag, Belt_Array.concat(Belt_List.toArray(tags), [tag]), eventId, eventName, NamedBranch.getId(branch), schemaGroup.schemaId);
                    } else {
                      return AnalyticsRe.eventTagsUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "Remove", tag, Belt_Array.keep(Belt_List.toArray(tags), (function (existingTag) {
                                        return existingTag !== tag;
                                      })), eventId, eventName, NamedBranch.getId(branch), schemaGroup.schemaId);
                    }
                  }),
                undefined,
                [[
                    toggled ? ({
                          NAME: "AddTag",
                          VAL: [
                            eventId,
                            tag
                          ]
                        }) : ({
                          NAME: "RemoveTag",
                          VAL: [
                            eventId,
                            tag
                          ]
                        }),
                    {
                      eventId: eventId,
                      eventQuery: eventId
                    }
                  ]]
              ]);
  };
  var onInteraction = React.useCallback((function (action) {
          return AnalyticsRe.eventsViewPopupInteraction(schemaGroup, action, "Tags", "Tag", eventLocation, schemaGroup.branchId, schemaGroup.schemaId);
        }), [schemaGroup]);
  return React.createElement(EditableTagsCell.make, {
              allItems: allTags,
              children: React.createElement("div", {
                    className: cellContentStyles(undefined, undefined)
                  }, tags === /* [] */0 ? null : React.createElement($$Text.make, {
                          size: "Tiny",
                          lines: 3,
                          color: Styles.Color.grey80,
                          children: Belt_List.toArray(tags).join(", ")
                        })),
              className: cellStyles(getColumnWidthByType(columns, "Tags"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor),
              onCreates: (function (param) {
                  return [[
                            (function (newTag) {
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            (function (branch) {
                                                return AnalyticsRe.eventTagsUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "Create", newTag, Belt_Array.concat(allTags, [newTag]), eventId, eventName, NamedBranch.getId(branch), schemaGroup.schemaId);
                                              }),
                                            undefined,
                                            [[
                                                {
                                                  NAME: "AddTag",
                                                  VAL: [
                                                    eventId,
                                                    newTag
                                                  ]
                                                },
                                                {
                                                  eventId: eventId,
                                                  eventQuery: eventId
                                                }
                                              ]]
                                          ]);
                              }),
                            (function (searchTerm) {
                                return "+ Create " + searchTerm;
                              })
                          ]];
                }),
              disabled: withDetails,
              onToggle: onToggle,
              role: role,
              selectedItems: Belt_List.toArray(tags),
              onItemClicked: (function (param) {
                  return Curry._1(onInteraction, "ItemClicked");
                }),
              onHover: (function (param) {
                  return AnalyticsRe.eventsViewInteraction(schemaGroup, "Tags", eventLocation, schemaGroup.branchId, schemaGroup.schemaId);
                }),
              onSearch: (function (_value) {
                  return Curry._1(onInteraction, "SearchedForItem");
                }),
              onCreateInitiated: (function (_searchTerm) {
                  return Curry._1(onInteraction, "ItemCreationInitiated");
                })
            });
}

var TagsCell = {
  make: EventsSheet$EventRow$TagsCell
};

var iconStyles = Curry._1(Css.style, {
      hd: Css.marginRight(Css.px(6)),
      tl: /* [] */0
    });

function overflowedDestinationsContainerStyles(customWidth) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.px(customWidth)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: /* [] */0
                }
              }
            });
}

var overflowedStyles = Curry._1(Css.style, {
      hd: Css.borderBottom(Css.px(1), "dotted", Styles.Color.grey50),
      tl: /* [] */0
    });

function EventsSheet$EventRow$DestinationsCell(Props) {
  var columns = Props.columns;
  var withDetails = Props.withDetails;
  var groupedByCategory = Props.groupedByCategory;
  var sources = Props.sources;
  var $$event = Props.event;
  var modelDestinations = Props.modelDestinations;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var match = AvoConfig.determineSendingToSources(sources, $$event);
  var sourcesSendingEvent = typeof match === "object" ? match.VAL : (
      match === "None" ? /* [] */0 : sources
    );
  var sourceIdsSendingEvent = Belt_List.mapU(sourcesSendingEvent, (function (source) {
          return source.id;
        }));
  var eventDestinations = Belt_Array.keepMapU(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.mapU($$event.includeDestinations, (function (destination) {
                              var match = destination.VAL;
                              if (Belt_List.has(sourceIdsSendingEvent, match[0], (function (prim0, prim1) {
                                        return prim0 === prim1;
                                      }))) {
                                return match[1];
                              } else {
                                return /* [] */0;
                              }
                            })))))), (function (destinationId) {
          return Belt_List.getByU(modelDestinations, (function (destination) {
                        return destination.id === destinationId;
                      }));
        }));
  var iconsFitInWidth = getColumnWidthByType(columns, "Destinations") / 32 | 0;
  var destinations = Belt_Array.keepMapU(eventDestinations, (function (destination) {
          var type_ = destination.type_;
          if (type_ !== undefined) {
            return [
                    type_,
                    StateUtils.getDestinationName(destination),
                    destination.id
                  ];
          }
          
        }));
  var overflow = destinations.length > iconsFitInWidth;
  var shownDestinations = overflow ? Belt_Array.slice(destinations, 0, iconsFitInWidth - 1 | 0) : destinations;
  var truncatedDestinations = overflow ? Belt_Array.sliceToEnd(destinations, iconsFitInWidth - 1 | 0) : [];
  return React.createElement("div", {
              className: cellStyles(getColumnWidthByType(columns, "Destinations"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor)
            }, React.createElement("div", {
                  className: cellContentStyles("nowrap", undefined)
                }, Caml_obj.caml_equal(destinations, []) ? React.createElement($$Text.make, {
                        size: "Tiny",
                        weight: "Semi",
                        color: Styles.Color.orangeSecondary,
                        children: "No destinations"
                      }) : React.createElement(React.Fragment, undefined, Belt_Array.mapU(shownDestinations, (function (param) {
                              return React.createElement("div", {
                                          key: param[2],
                                          className: iconStyles,
                                          title: param[1]
                                        }, React.createElement(Icons.Destinations.make, {
                                              destination: param[0],
                                              size: 24
                                            }));
                            })), Caml_obj.caml_equal(truncatedDestinations, []) ? null : React.createElement("div", {
                              className: overflowedDestinationsContainerStyles(24),
                              title: Belt_Array.mapU(truncatedDestinations, (function (param) {
                                        return param[1];
                                      })).join(", ")
                            }, React.createElement("span", {
                                  className: overflowedStyles
                                }, "+" + String(truncatedDestinations.length))))));
}

var DestinationsCell = {
  iconStyles: iconStyles,
  overflowedDestinationsContainerStyles: overflowedDestinationsContainerStyles,
  overflowedStyles: overflowedStyles,
  make: EventsSheet$EventRow$DestinationsCell
};

function EventsSheet$EventRow$MetricsCell(Props) {
  var columns = Props.columns;
  var eventId = Props.eventId;
  var groupedByCategory = Props.groupedByCategory;
  var modelMetrics = Props.modelMetrics;
  var withDetails = Props.withDetails;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var metricNames = Belt_List.toArray(Belt_List.mapU(Belt_List.keepU(modelMetrics, (function (param) {
                  return Belt_List.someU(param.items, (function (metricItem) {
                                return metricItem.VAL.eventId === eventId;
                              }));
                })), (function (metric) {
              if (metric.name === "") {
                return "Untitled Metric";
              } else {
                return metric.name;
              }
            })));
  return React.createElement("div", {
              className: cellStyles(getColumnWidthByType(columns, "Metrics"), withDetails, false, groupedByCategory, innerBorderColor, outerBorderColor),
              title: metricNames.join("\n")
            }, React.createElement("div", {
                  className: cellContentStyles(undefined, undefined)
                }, metricNames.length !== 0 ? React.createElement($$Text.make, {
                        size: "Tiny",
                        lines: 3,
                        color: Styles.Color.grey80,
                        children: null
                      }, React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: /* [] */0
                                })
                          }, String(metricNames.length)), ": " + metricNames.join(", ")) : null));
}

var MetricsCell = {
  make: EventsSheet$EventRow$MetricsCell
};

function EventsSheet$EventRow$Empty(Props) {
  var eventNameOpt = Props.eventName;
  var groupedByCategory = Props.groupedByCategory;
  var columns = Props.columns;
  var innerBorderColorOpt = Props.innerBorderColor;
  var outerBorderColorOpt = Props.outerBorderColor;
  var backgroundColorOpt = Props.backgroundColor;
  var eventName = eventNameOpt !== undefined ? eventNameOpt : "Empty Event";
  var innerBorderColor = innerBorderColorOpt !== undefined ? innerBorderColorOpt : Styles.Color.grey30;
  var outerBorderColor = outerBorderColorOpt !== undefined ? outerBorderColorOpt : Styles.Color.grey40;
  var backgroundColor = backgroundColorOpt !== undefined ? backgroundColorOpt : Styles.Color.white;
  return React.createElement("button", {
              className: rootStyles(false, false, groupedByCategory, outerBorderColor, backgroundColor, true)
            }, Belt_Array.mapU(columns, (function (column) {
                    var columnType = column.columnType;
                    if (columnType === "EventName") {
                      return React.createElement("div", {
                                  key: "EventName",
                                  className: stickyCellStyles(getColumnWidthByType(columns, "EventName"), groupedByCategory, false, innerBorderColor, outerBorderColor, backgroundColor)
                                }, React.createElement($$Text.make, {
                                      size: "Medium",
                                      weight: "Medium",
                                      singleLine: true,
                                      color: Styles.Color.grey40,
                                      maxWidth: Css.pct(100),
                                      children: eventName
                                    }));
                    } else {
                      return React.createElement("div", {
                                  key: SheetTypes.columnTypeToJs(columnType),
                                  className: cellStyles(getColumnWidthByType(columns, columnType), false, false, groupedByCategory, innerBorderColor, outerBorderColor)
                                });
                    }
                  })));
}

var Empty = {
  make: EventsSheet$EventRow$Empty
};

function EventsSheet$EventRow(Props) {
  var allCategories = Props.allCategories;
  var allEventProperties = Props.allEventProperties;
  var allGroupPropertiesWithGroupTypeNames = Props.allGroupPropertiesWithGroupTypeNames;
  var allPropertyGroups = Props.allPropertyGroups;
  var allResolvedPropertyGroups = Props.allResolvedPropertyGroups;
  var allTags = Props.allTags;
  var columns = Props.columns;
  var currentFilters = Props.currentFilters;
  var $$event = Props.event;
  var isEventModified = Props.isEventModified;
  var eventLocation = Props.eventLocation;
  var globalSend = Props.globalSend;
  var groupedByCategory = Props.groupedByCategory;
  var model = Props.model;
  var modificationState = Props.modificationState;
  var onClick = Props.onClick;
  var role = Props.role;
  var selected = Props.selected;
  var withDetails = Props.withDetails;
  var innerBorderColorOpt = Props.innerBorderColor;
  var outerBorderColorOpt = Props.outerBorderColor;
  var backgroundColorOpt = Props.backgroundColor;
  var innerBorderColor = innerBorderColorOpt !== undefined ? innerBorderColorOpt : Styles.Color.grey30;
  var outerBorderColor = outerBorderColorOpt !== undefined ? outerBorderColorOpt : Styles.Color.grey40;
  var backgroundColor = backgroundColorOpt !== undefined ? backgroundColorOpt : Styles.Color.white;
  var selectedPropertyGroups = React.useMemo((function () {
          return Belt_List.toArray(Belt_List.keepMapU($$event.propertyBundles, (function (group) {
                            return Belt_Array.getByU(allResolvedPropertyGroups, (function (modelGroup) {
                                          return modelGroup.id === group.id;
                                        }));
                          })));
        }), [
        $$event.propertyBundles,
        allPropertyGroups
      ]);
  return React.createElement("button", {
              className: rootStyles(selected, withDetails, groupedByCategory, outerBorderColor, backgroundColor, false),
              id: "events-sheet-row-" + Case$1.kebab($$event.name),
              onClick: onClick
            }, Belt_Array.mapU(columns, (function (column) {
                    var columnType = column.columnType;
                    if (columnType === "EventProperties") {
                      if (isColumnVisibleByType(columns, columnType)) {
                        return React.createElement(EventsSheet$EventRow$EventPropertiesCell, {
                                    allEventProperties: allEventProperties,
                                    columns: columns,
                                    currentFilters: currentFilters,
                                    eventId: $$event.id,
                                    eventLocation: eventLocation,
                                    eventName: $$event.name,
                                    eventProperties: $$event.properties,
                                    globalSend: globalSend,
                                    groupedByCategory: groupedByCategory,
                                    model: model,
                                    propertyGroups: selectedPropertyGroups,
                                    role: role,
                                    withDetails: withDetails,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else {
                        return null;
                      }
                    } else if (columnType === "Destinations") {
                      if (isColumnVisibleByType(columns, columnType)) {
                        return React.createElement(EventsSheet$EventRow$DestinationsCell, {
                                    columns: columns,
                                    withDetails: withDetails,
                                    groupedByCategory: groupedByCategory,
                                    sources: model.sources,
                                    event: $$event,
                                    modelDestinations: model.destinations,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else {
                        return null;
                      }
                    } else if (columnType === "PropertyGroups") {
                      if (isColumnVisibleByType(columns, columnType)) {
                        return React.createElement(EventsSheet$EventRow$PropertyGroupsCell, {
                                    allPropertyGroups: allResolvedPropertyGroups,
                                    columns: columns,
                                    eventId: $$event.id,
                                    eventLocation: eventLocation,
                                    eventName: $$event.name,
                                    eventIncludeSources: $$event.includeSources,
                                    globalSend: globalSend,
                                    groupedByCategory: groupedByCategory,
                                    modelEvents: model.events,
                                    numTriggersOnEvent: $$event.triggers.length,
                                    role: role,
                                    selectedItems: selectedPropertyGroups,
                                    withDetails: withDetails,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else {
                        return null;
                      }
                    } else if (columnType === "Metrics") {
                      if (isColumnVisibleByType(columns, columnType)) {
                        return React.createElement(EventsSheet$EventRow$MetricsCell, {
                                    columns: columns,
                                    eventId: $$event.id,
                                    groupedByCategory: groupedByCategory,
                                    modelMetrics: model.metrics,
                                    withDetails: withDetails,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else {
                        return null;
                      }
                    } else if (columnType === "Sources") {
                      if (isColumnVisibleByType(columns, columnType)) {
                        return React.createElement(EventsSheet$EventRow$SourcesCell, {
                                    columns: columns,
                                    withDetails: withDetails,
                                    groupedByCategory: groupedByCategory,
                                    event: $$event,
                                    isEventModified: isEventModified,
                                    sources: model.sources,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else {
                        return null;
                      }
                    } else if (columnType === "GroupProperties") {
                      if (isColumnVisibleByType(columns, columnType)) {
                        return React.createElement(EventsSheet$EventRow$GroupPropertiesCell, {
                                    allGroupPropertiesWithGroupTypeNames: allGroupPropertiesWithGroupTypeNames,
                                    columns: columns,
                                    currentFilters: currentFilters,
                                    eventId: $$event.id,
                                    eventLocation: eventLocation,
                                    eventName: $$event.name,
                                    eventProperties: $$event.properties,
                                    globalSend: globalSend,
                                    groupedByCategory: groupedByCategory,
                                    model: model,
                                    role: role,
                                    withDetails: withDetails,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else {
                        return null;
                      }
                    } else if (columnType === "Category") {
                      if (isColumnVisibleByType(columns, columnType)) {
                        return React.createElement(EventsSheet$EventRow$CategoryCell, {
                                    allCategories: allCategories,
                                    columns: columns,
                                    eventId: $$event.id,
                                    eventLocation: eventLocation,
                                    eventName: $$event.name,
                                    globalSend: globalSend,
                                    groupedByCategory: groupedByCategory,
                                    role: role,
                                    withDetails: withDetails,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else {
                        return null;
                      }
                    } else if (columnType === "EventName") {
                      return React.createElement(EventsSheet$EventRow$NameCell, {
                                  columns: columns,
                                  eventDescription: $$event.description,
                                  eventName: $$event.name,
                                  groupedByCategory: groupedByCategory,
                                  modificationState: modificationState,
                                  selected: selected,
                                  innerBorderColor: innerBorderColor,
                                  outerBorderColor: outerBorderColor,
                                  backgroundColor: backgroundColor,
                                  key: SheetTypes.columnTypeToJs(column.columnType)
                                });
                    } else if (columnType === "Actions") {
                      if (isColumnVisibleByType(columns, columnType)) {
                        return React.createElement(EventsSheet$EventRow$ActionsCell, {
                                    columns: columns,
                                    withDetails: withDetails,
                                    groupedByCategory: groupedByCategory,
                                    event: $$event,
                                    model: model,
                                    innerBorderColor: innerBorderColor,
                                    outerBorderColor: outerBorderColor,
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  });
                      } else {
                        return null;
                      }
                    } else if (columnType === "Tags" && isColumnVisibleByType(columns, columnType)) {
                      return React.createElement(EventsSheet$EventRow$TagsCell, {
                                  allTags: allTags,
                                  columns: columns,
                                  eventId: $$event.id,
                                  eventLocation: eventLocation,
                                  eventName: $$event.name,
                                  groupedByCategory: groupedByCategory,
                                  role: role,
                                  tags: $$event.tags,
                                  withDetails: withDetails,
                                  innerBorderColor: innerBorderColor,
                                  outerBorderColor: outerBorderColor,
                                  key: SheetTypes.columnTypeToJs(column.columnType)
                                });
                    } else {
                      return null;
                    }
                  })));
}

var EventRow = {
  rootStyles: rootStyles,
  stickyCellStyles: stickyCellStyles,
  cellStylesForGroupedByCategory: cellStylesForGroupedByCategory,
  cellStyles: cellStyles,
  cellContentStyles: cellContentStyles,
  getColumnWidthByType: getColumnWidthByType,
  isColumnVisibleByType: isColumnVisibleByType,
  ModifiedIndicator: ModifiedIndicator,
  NameCell: NameCell,
  EditableCategoriesCell: EditableCategoriesCell,
  CategoryCell: CategoryCell,
  EditablePropertyGroupsCell: EditablePropertyGroupsCell,
  PropertyGroupsCell: PropertyGroupsCell,
  EditableEventPropertiesCell: EditableEventPropertiesCell,
  EventPropertiesCell: EventPropertiesCell,
  EditableGroupPropertiesCell: EditableGroupPropertiesCell,
  GroupPropertiesCell: GroupPropertiesCell,
  SourcesCell: SourcesCell,
  ActionsCell: ActionsCell,
  EditableTagsCell: EditableTagsCell,
  TagsCell: TagsCell,
  DestinationsCell: DestinationsCell,
  MetricsCell: MetricsCell,
  Empty: Empty,
  make: EventsSheet$EventRow
};

var categoryLinkStyles = Curry._1(Css.style, {
      hd: Css.minWidth("zero"),
      tl: {
        hd: Css.color(Styles.Color.grey80),
        tl: {
          hd: Css.transition({
                NAME: "ms",
                VAL: Styles.Duration.$$short
              }, undefined, undefined, "color"),
          tl: {
            hd: Css.hover({
                  hd: Css.color(Styles.Color.blue),
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.paddingRight(Css.px(8)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function EventsSheet$CategoryHeader(Props) {
  var category = Props.category;
  var columns = Props.columns;
  var sectionCount = Props.sectionCount;
  var name = category.name.trim();
  var categoryName = name === "" ? undefined : name;
  return React.createElement(SheetSectionHeader.make, {
              columns: columns,
              label: "CATEGORY",
              pillText: String(sectionCount) + (
                sectionCount > 1 ? " events" : " event"
              ),
              children: React.createElement(Link.make, {
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "category",
                          VAL: [
                            category.id,
                            undefined
                          ]
                        }),
                    className: categoryLinkStyles,
                    children: React.createElement($$Text.make, {
                          element: Belt_Option.isSome(categoryName) ? "P" : "Em",
                          size: "Medium",
                          weight: "Semi",
                          singleLine: true,
                          color: Belt_Option.isSome(categoryName) ? Styles.Color.grey80 : Styles.Color.grey60,
                          children: Belt_Option.getWithDefault(categoryName, "Untitled")
                        })
                  })
            });
}

var CategoryHeader = {
  categoryLinkStyles: categoryLinkStyles,
  make: EventsSheet$CategoryHeader
};

var containerStyles = Curry._1(Css.style, {
      hd: Css.width(Css.px(270)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.borderRadius(Styles.Border.radius),
          tl: {
            hd: Css.boxShadow(Styles.Shadow.subtle),
            tl: /* [] */0
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.padding(Css.px(16)),
          tl: {
            hd: Css.paddingLeft(Css.px(32)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
              tl: /* [] */0
            }
          }
        }
      }
    });

var headerWrapperStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.marginBottom(Css.px(8)),
          tl: /* [] */0
        }
      }
    });

function listStyles(itemsCount) {
  return Curry._1(Css.style, {
              hd: Css.padding("zero"),
              tl: {
                hd: Css.paddingTop(Css.px(16)),
                tl: {
                  hd: Css.margin("zero"),
                  tl: {
                    hd: Css.listStyleType("none"),
                    tl: {
                      hd: Css.textIndent("zero"),
                      tl: {
                        hd: Css.height(Css.px((16 + Math.imul(itemsCount, 28) | 0) + 16 | 0)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function listItemStyles(index, itemsCount, drag) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.height(Css.px(28)),
                  tl: {
                    hd: Css.userSelect("none"),
                    tl: {
                      hd: Css.paddingLeft(Css.px(14)),
                      tl: {
                        hd: Css.paddingRight(Css.px(16)),
                        tl: {
                          hd: Css.transitions({
                                hd: Css_Legacy_Core.Transition.shorthand({
                                      NAME: "ms",
                                      VAL: Belt_Option.isSome(drag) ? Styles.Duration.$$short : 0.0
                                    }, undefined, undefined, "transform"),
                                tl: {
                                  hd: Css_Legacy_Core.Transition.shorthand({
                                        NAME: "ms",
                                        VAL: Styles.Duration.$$short
                                      }, undefined, undefined, "background-color"),
                                  tl: {
                                    hd: Css_Legacy_Core.Transition.shorthand({
                                          NAME: "ms",
                                          VAL: Belt_Option.isSome(drag) ? Styles.Duration.$$short : 0.0
                                        }, undefined, undefined, "margin-top"),
                                    tl: {
                                      hd: Css_Legacy_Core.Transition.shorthand({
                                            NAME: "ms",
                                            VAL: Belt_Option.isSome(drag) ? Styles.Duration.$$short : 0.0
                                          }, undefined, undefined, "margin-bottom"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }),
                          tl: {
                            hd: Css.selector(" path", {
                                  hd: Css.transition({
                                        NAME: "ms",
                                        VAL: Styles.Duration.$$short
                                      }, undefined, undefined, "fill"),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.selector(" svg", {
                                    hd: Css.opacity(index === 0 ? 0.2 : 1.0),
                                    tl: {
                                      hd: Css.cursor(index === 0 ? "notAllowed" : "grab"),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: {
                                hd: Css.backgroundColor(Belt_Option.mapWithDefault(drag, "transparent", (function (drag) {
                                            if (drag.index === index) {
                                              return Styles.Color.grey20;
                                            } else {
                                              return "transparent";
                                            }
                                          }))),
                                tl: {
                                  hd: Css.active({
                                        hd: Css.backgroundColor(Styles.Color.grey20),
                                        tl: {
                                          hd: Css.cursor(index === 0 ? "notAllowed" : "grabbing"),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: {
                                    hd: Css.marginTop(Css.px(Belt_Option.mapWithDefault(drag, 0, (function (drag) {
                                                    var match = drag.direction;
                                                    if (index === 0 || match !== 1) {
                                                      return 0;
                                                    } else if (index === (drag.index + 1 | 0) && drag.toPos >= index) {
                                                      return -28;
                                                    } else if (index !== drag.index && index === (drag.toPos + 1 | 0)) {
                                                      return 28;
                                                    } else {
                                                      return 0;
                                                    }
                                                  })))),
                                    tl: {
                                      hd: Css.hover({
                                            hd: Css.selector(" path", index === 0 ? /* [] */0 : ({
                                                      hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey50),
                                                      tl: /* [] */0
                                                    })),
                                            tl: {
                                              hd: Css.cursor(index === 0 ? "notAllowed" : "grab"),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: {
                                        hd: Css.marginBottom(Css.px(Belt_Option.mapWithDefault(drag, 0, (function (drag) {
                                                        var match = drag.direction;
                                                        switch (match) {
                                                          case /* Up */0 :
                                                              if (index === (drag.index - 1 | 0) && drag.toPos <= index) {
                                                                return -28;
                                                              } else if (index !== drag.index && index === (drag.toPos - 1 | 0)) {
                                                                return 28;
                                                              } else {
                                                                return 0;
                                                              }
                                                          case /* Down */1 :
                                                              if (drag.toPos === (itemsCount - 1 | 0) && index === (itemsCount - 1 | 0)) {
                                                                return 28;
                                                              } else {
                                                                return 0;
                                                              }
                                                          case /* Same */2 :
                                                              return 0;
                                                          
                                                        }
                                                      })))),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var resetButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("inlineFlex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.color(Styles.Color.blue),
                    tl: {
                      hd: Css.fontSize(Css.px(10)),
                      tl: {
                        hd: Css.letterSpacing(Css.em(0.01)),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.color(Styles.Color.blueSecondary),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.active({
                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.cursor("pointer"),
                                  tl: {
                                    hd: Css.focus({
                                          hd: Css.outlineStyle("none"),
                                          tl: {
                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.blue)),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: {
                                      hd: Css.backgroundColor("transparent"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function EventsSheet$CustomizeButtonWithMenu(Props) {
  var columns = Props.columns;
  var setColumns = Props.setColumns;
  var resetColumns = Props.resetColumns;
  var schemaGroup = Props.schemaGroup;
  var buttonRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setShowMenu = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setDrag = match$1[1];
  var drag = match$1[0];
  var onVisibilitySwitch = React.useCallback((function (column) {
          return function (value) {
            return Curry._1(setColumns, (function (columns) {
                          var newColumns = Belt_Array.map(columns, (function (c) {
                                  if (c.columnType === column.columnType) {
                                    return {
                                            columnType: c.columnType,
                                            width: c.width,
                                            order: c.order,
                                            visible: value
                                          };
                                  } else {
                                    return c;
                                  }
                                }));
                          AnalyticsRe.eventsViewConfigured(schemaGroup, "ColumnVisibility", column.visible ? "ShowColumn" : "HideColumn", AnalyticsUtils.columnTypeToEventColumnsName(column.columnType), Belt_Array.keepMapU(Belt_Array.keepU(newColumns, (function (column) {
                                          return column.visible;
                                        })), (function (column) {
                                      return AnalyticsUtils.columnTypeToEventColumnsName(column.columnType);
                                    })), Belt_Array.keepU(newColumns, (function (column) {
                                      return column.visible;
                                    })).length, schemaGroup.branchId, schemaGroup.schemaId);
                          return newColumns;
                        }));
          };
        }), [columns]);
  var onStop = function (param, param$1) {
    var node = param$1.node;
    Belt_Option.forEach(drag, (function (drag) {
            var match = drag.direction;
            var reorderedColumns = match >= 2 ? columns : Belt_Array.mapWithIndexU(BeltArrayExtensions.reinsertIndex(columns, drag.index, drag.toPos), (function (index, c) {
                      return {
                              columnType: c.columnType,
                              width: c.width,
                              order: index,
                              visible: c.visible
                            };
                    }));
            node.style.transform = "translate(0px, 0px)";
            AnalyticsRe.eventsViewConfigured(schemaGroup, "ColumnVisibility", "Reorder", Belt_Option.flatMap(Belt_Array.get(columns, drag.index), (function (column) {
                        return AnalyticsUtils.columnTypeToEventColumnsName(column.columnType);
                      })), Belt_Array.keepMapU(Belt_Array.keepU(reorderedColumns, (function (column) {
                            return column.visible;
                          })), (function (column) {
                        return AnalyticsUtils.columnTypeToEventColumnsName(column.columnType);
                      })), Belt_Array.keepU(reorderedColumns, (function (column) {
                        return column.visible;
                      })).length, schemaGroup.branchId, schemaGroup.schemaId);
            return Curry._1(setColumns, (function (param) {
                          return reorderedColumns;
                        }));
          }));
    return Curry._1(setDrag, (function (param) {
                  
                }));
  };
  return React.createElement("span", {
              id: "sheetview-tour-customize"
            }, React.createElement(Button.make, {
                  icon: "table",
                  label: "Customize",
                  onClick: (function (param) {
                      return Curry._1(setShowMenu, (function (state) {
                                    return !state;
                                  }));
                    }),
                  style: "ghost",
                  ref: buttonRef
                }), React.createElement(PortalMenu.make, {
                  position: "Bottom",
                  visible: match[0],
                  onClose: (function (param) {
                      return Curry._1(setShowMenu, (function (param) {
                                    return false;
                                  }));
                    }),
                  referenceItemRef: buttonRef,
                  children: React.createElement("aside", {
                        className: containerStyles
                      }, React.createElement("header", {
                            className: headerStyles
                          }, React.createElement("span", {
                                className: headerWrapperStyles
                              }, React.createElement($$Text.make, {
                                    element: "H1",
                                    size: "Medium",
                                    weight: "Medium",
                                    color: Styles.Color.grey90,
                                    children: "Customize Columns"
                                  }), React.createElement(FramerMotion.AnimatePresence, {
                                    children: Caml_obj.caml_notequal(defaultColumns, columns) ? React.createElement(FramerMotion.motion.div, {
                                            animate: {
                                              opacity: 1
                                            },
                                            transition: {
                                              duration: 0.2
                                            },
                                            initial: {
                                              opacity: 0
                                            },
                                            exit: {
                                              opacity: 0
                                            },
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: /* [] */0
                                                }),
                                            key: "reset",
                                            children: React.createElement("button", {
                                                  className: resetButtonStyles,
                                                  onClick: resetColumns
                                                }, "RESET")
                                          }) : null
                                  })), React.createElement($$Text.make, {
                                size: "Small",
                                color: Styles.Color.grey70,
                                children: "Personalize the columns configuration to highlight what you think is most important"
                              })), React.createElement("ul", {
                            className: listStyles(columns.length)
                          }, Belt_Array.mapWithIndexU(columns, (function (index, column) {
                                  var tmp = {
                                    axis: "y",
                                    onDrag: (function (param, param$1) {
                                        var toPos = index + (param$1.y / 28 | 0) | 0;
                                        var direction = index < toPos ? /* Down */1 : (
                                            index > toPos ? /* Up */0 : /* Same */2
                                          );
                                        var newDrag = {
                                          index: index,
                                          toPos: toPos,
                                          direction: direction
                                        };
                                        if (Caml_obj.caml_notequal(drag, newDrag)) {
                                          return Curry._1(setDrag, (function (param) {
                                                        return newDrag;
                                                      }));
                                        }
                                        
                                      }),
                                    onStop: onStop,
                                    defaultClassNameDragging: Curry._1(Css.style, {
                                          hd: Css.hover({
                                                hd: Css.cursor("grabbing"),
                                                tl: /* [] */0
                                              }),
                                          tl: {
                                            hd: Css.active({
                                                  hd: Css.cursor("grabbing"),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }),
                                    bounds: {
                                      left: 0,
                                      top: Math.imul(Math.max(0, index - 1 | 0), -28),
                                      right: 0,
                                      bottom: Math.imul((columns.length - 1 | 0) - index | 0, 28)
                                    },
                                    grid: [
                                      28,
                                      28
                                    ],
                                    disabled: column.columnType === "EventName",
                                    children: React.createElement("li", {
                                          className: listItemStyles(index, columns.length, drag)
                                        }, React.createElement(Icons.Dragger.make, {}), React.createElement(Spacer.make, {
                                              width: 11
                                            }), React.createElement($$Text.make, {
                                              size: "Medium",
                                              weight: "Medium",
                                              singleLine: true,
                                              color: Styles.Color.grey70,
                                              children: SheetTypes.columnTypeToJs(column.columnType)
                                            }), React.createElement(Spacer.make, {
                                              width: 8,
                                              grow: 1.0
                                            }), React.createElement(Switch.make, {
                                              value: column.visible,
                                              onChange: Curry._1(onVisibilitySwitch, column),
                                              size: "Small",
                                              disabled: column.columnType === "EventName"
                                            })),
                                    key: SheetTypes.columnTypeToJs(column.columnType)
                                  };
                                  var tmp$1 = Belt_Option.isSome(drag) ? undefined : ({
                                        x: undefined,
                                        y: 0
                                      });
                                  if (tmp$1 !== undefined) {
                                    tmp.position = Caml_option.valFromOption(tmp$1);
                                  }
                                  return React.createElement(ReactDraggable, tmp);
                                }))))
                }));
}

var CustomizeButtonWithMenu = {
  listItemHeight: 28,
  containerStyles: containerStyles,
  headerStyles: headerStyles,
  headerWrapperStyles: headerWrapperStyles,
  listStyles: listStyles,
  listItemStyles: listItemStyles,
  resetButtonStyles: resetButtonStyles,
  make: EventsSheet$CustomizeButtonWithMenu
};

var headerStyles$1 = Curry._1(Css.style, {
      hd: Css.height(Css.px(64)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingLeft(Css.px(30)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var emptyFilteredEvents = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.left(Css.px(64)),
        tl: {
          hd: Css.top(Css.pct(40.0)),
          tl: {
            hd: Css.zIndex(1),
            tl: /* [] */0
          }
        }
      }
    });

function stringCompare(a, b) {
  var x = a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
  if (x > 0.0) {
    return 1;
  } else if (x < 0.0) {
    return -1;
  } else {
    return 0;
  }
}

function getEventModificationStatus(branchModel, $$event) {
  if (branchModel.TAG === /* Main */0) {
    return /* Unmodified */0;
  } else {
    return Belt_Option.mapWithDefault(StateUtils.getEventById($$event.id, branchModel._1), /* New */2, (function (unmodifiedEvent) {
                  if (ActionDiff.diffEvent($$event, unmodifiedEvent) === /* [] */0) {
                    return /* Unmodified */0;
                  } else {
                    return /* Modified */1;
                  }
                }));
  }
}

function isEventModified(branchModel, $$event) {
  var match = getEventModificationStatus(branchModel, $$event);
  return match !== 0;
}

function organizeEventsAsList(branchModel, events) {
  var allEventRows = Belt_Array.mapWithIndexU(events, (function (index, $$event) {
          return {
                  TAG: /* Event */2,
                  _0: index,
                  _1: undefined,
                  _2: $$event,
                  _3: getEventModificationStatus(branchModel, $$event)
                };
        }));
  var workbenchSection;
  if (branchModel.TAG === /* Main */0) {
    workbenchSection = undefined;
  } else {
    var modifiedEventRows = Belt_Array.keepMapU(allEventRows, (function (row) {
            if (typeof row === "number") {
              return ;
            }
            if (row.TAG !== /* Event */2) {
              return ;
            }
            var match = row._3;
            if (match !== 0) {
              return {
                      TAG: /* WorkbenchEvent */1,
                      _0: row._0,
                      _1: row._2,
                      _2: match
                    };
            }
            
          }));
    var match = getGetNewAndChangedRowCounts(modifiedEventRows);
    workbenchSection = Belt_Array.concatMany([
          [{
              TAG: /* WorkbenchHeader */0,
              _0: match[0],
              _1: match[1]
            }],
          modifiedEventRows
        ]);
  }
  return Belt_Array.concat(Belt_Option.getWithDefault(workbenchSection, []), allEventRows);
}

function organizeEventsByCategory(model, branchModel, events, filters) {
  var processedEvents = Belt_Array.mapWithIndexU(events, (function (index, $$event) {
          return [
                  $$event.id,
                  [
                    index,
                    $$event,
                    getEventModificationStatus(branchModel, $$event)
                  ]
                ];
        }));
  var workbenchSection;
  if (branchModel.TAG === /* Main */0) {
    workbenchSection = undefined;
  } else {
    var modifiedEventRows = Belt_Array.keepMapU(processedEvents, (function (param) {
            var match = param[1];
            var modificationState = match[2];
            if (modificationState !== 0) {
              return {
                      TAG: /* WorkbenchEvent */1,
                      _0: match[0],
                      _1: match[1],
                      _2: modificationState
                    };
            }
            
          }));
    var match = getGetNewAndChangedRowCounts(modifiedEventRows);
    workbenchSection = Belt_Array.concatMany([
          [{
              TAG: /* WorkbenchHeader */0,
              _0: match[0],
              _1: match[1]
            }],
          modifiedEventRows,
          [/* WorkbenchFooter */0]
        ]);
  }
  var eventsDict = Js_dict.fromArray(processedEvents);
  var eventsByCategory = Js_dict.fromArray(Belt_Array.mapWithIndex(Belt_List.toArray(model.goals), (function (index, category) {
              return [
                      category.name + ("-" + String(index)),
                      {
                        category: category,
                        events: Belt_Array.keepMap(Belt_List.toArray(category.events), (function (eventId) {
                                  return Belt_Option.map(Js_dict.get(eventsDict, eventId), (function (param) {
                                                return param[1];
                                              }));
                                })).sort(function (a, b) {
                              return stringCompare(a.name, b.name);
                            })
                      }
                    ];
            })));
  var categorizedEventIds = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.mapU(model.goals, (function (category) {
                      return category.events;
                    })))));
  var allEventIds = Belt_SetString.fromArray(Object.keys(eventsDict));
  var uncategorizedEventIds = Belt_SetString.diff(allEventIds, categorizedEventIds);
  var uncategorizedEvents = Belt_Array.keepU(Js_dict.entries(eventsDict), (function (param) {
          return Belt_SetString.has(uncategorizedEventIds, param[0]);
        }));
  var uncategorizedSection = uncategorizedEvents.length === 0 ? undefined : Belt_Array.concatMany([
          [{
              TAG: /* UncategorizedHeader */3,
              _0: uncategorizedEvents.length
            }],
          Belt_Array.map(uncategorizedEvents, (function (param) {
                  var match = param[1];
                  return {
                          TAG: /* Event */2,
                          _0: match[0],
                          _1: undefined,
                          _2: match[1],
                          _3: match[2]
                        };
                })),
          [/* CategoryFooter */1]
        ]);
  var categoryKeysSorted = Object.keys(eventsByCategory).sort(function (a, b) {
        return stringCompare(a, b);
      });
  var match$1 = Belt_Array.partition(categoryKeysSorted, (function (categoryKey) {
          var category = eventsByCategory[categoryKey];
          return category.events.length !== 0;
        }));
  var categoryNamesWithEvents = match$1[0];
  var categoryKeysSorted$1 = filters.length === 0 ? Belt_Array.concat(categoryNamesWithEvents, match$1[1]) : categoryNamesWithEvents;
  var eventRows = [];
  Belt_Array.forEach(categoryKeysSorted$1, (function (categoryKey) {
          var organizedCategory = eventsByCategory[categoryKey];
          eventRows.push({
                TAG: /* CategoryHeader */4,
                _0: organizedCategory.category,
                _1: organizedCategory.events.length
              });
          Belt_Array.forEachU(organizedCategory.events, (function (categoryEvent) {
                  var match = Js_dict.get(eventsDict, categoryEvent.id);
                  if (match !== undefined) {
                    eventRows.push({
                          TAG: /* Event */2,
                          _0: match[0],
                          _1: organizedCategory.category.id,
                          _2: match[1],
                          _3: match[2]
                        });
                    return ;
                  }
                  
                }));
          eventRows.push(/* CategoryFooter */1);
          
        }));
  return Belt_Array.concatMany([
              Belt_Option.getWithDefault(workbenchSection, []),
              Belt_Option.getWithDefault(uncategorizedSection, []),
              eventRows
            ]);
}

function deriveColumnsFromServer(fromServer) {
  if (fromServer === undefined) {
    return defaultColumns;
  }
  var columnSettingsFromServer = Caml_option.valFromOption(fromServer);
  return Belt_List.toArray(Belt_List.sort(Belt_List.map(Belt_List.fromArray(defaultColumns), (function (defaultColumn) {
                        return Belt_Option.mapWithDefault(Js_dict.get(columnSettingsFromServer, SheetTypes.columnTypeToJs(defaultColumn.columnType)), defaultColumn, (function (columnFromSettingsFromServer) {
                                      return {
                                              columnType: defaultColumn.columnType,
                                              width: columnFromSettingsFromServer.width,
                                              order: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(columnFromSettingsFromServer.order), defaultColumn.order),
                                              visible: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(columnFromSettingsFromServer.visible), true)
                                            };
                                    }));
                      })), (function (a, b) {
                    return Caml.caml_int_compare(a.order, b.order);
                  })));
}

function updateColumnSettingsOnServer(viewerId, columns) {
  var columnSettings = Js_dict.fromArray(Belt_Array.mapU(columns, (function (column) {
              return [
                      SheetTypes.columnTypeToJs(column.columnType),
                      {
                        width: column.width,
                        visible: Js_null_undefined.fromOption(column.visible),
                        order: Js_null_undefined.fromOption(column.order)
                      }
                    ];
            })));
  return FirebaseUtils.setColumnSettings(viewerId, columnSettings);
}

function EventsSheet$ReactWindowRow(Props) {
  var index = Props.index;
  var style = Props.style;
  var data = Props.data;
  var id = index === 0 ? "sheetview-tour-row" : undefined;
  var match = Belt_Array.getExn(data, index);
  var view = match.view;
  var role = match.role;
  var branchModel = match.branchModel;
  var globalSend = match.globalSend;
  var details = match.details;
  var currentFilters = match.currentFilters;
  var columns = match.columns;
  var item = match.item;
  var allTags = match.allTags;
  var allResolvedPropertyGroups = match.allResolvedPropertyGroups;
  var allPropertyGroups = match.allPropertyGroups;
  var allGroupPropertiesWithGroupTypeNames = match.allGroupPropertiesWithGroupTypeNames;
  var allEventProperties = match.allEventProperties;
  var allCategories = match.allCategories;
  var tmp = {
    style: {
      height: style.height,
      left: style.left,
      position: style.position,
      top: (style.top + (
            view === /* ByCategory */0 ? 16 : 0
          ) | 0).toString() + "px",
      width: style.width
    }
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  var tmp$1;
  if (typeof item === "number") {
    switch (item) {
      case /* WorkbenchFooter */0 :
          tmp$1 = React.createElement(SheetSectionFooter.make, {
                columns: columns,
                tints: [
                  Styles.Color.mintGreen,
                  Styles.Color.mintGreenBg
                ]
              });
          break;
      case /* CategoryFooter */1 :
          tmp$1 = React.createElement(SheetSectionFooter.make, {
                columns: columns
              });
          break;
      case /* EmptyState */2 :
          tmp$1 = React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.overflow("hidden"),
                      tl: /* [] */0
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("relative"),
                          tl: /* [] */0
                        })
                  }, view === /* ByCategory */0 ? React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.height(Css.px(50)),
                                tl: /* [] */0
                              })
                        }, React.createElement(SheetSectionHeader.make, {
                              columns: columns,
                              children: React.createElement("span", {
                                    className: categoryLinkStyles
                                  }, React.createElement($$Text.make, {
                                        size: "Medium",
                                        weight: "Semi",
                                        singleLine: true,
                                        color: Styles.Color.grey50,
                                        children: "Category"
                                      }))
                            })) : null, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(50)),
                              tl: /* [] */0
                            })
                      }, React.createElement(EventsSheet$EventRow$Empty, {
                            eventName: "Event No. 1",
                            groupedByCategory: view === /* ByCategory */0,
                            columns: columns
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(50)),
                              tl: /* [] */0
                            })
                      }, React.createElement(EventsSheet$EventRow$Empty, {
                            eventName: "Event No. 2",
                            groupedByCategory: view === /* ByCategory */0,
                            columns: columns
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(50)),
                              tl: /* [] */0
                            })
                      }, React.createElement(EventsSheet$EventRow$Empty, {
                            eventName: "Event No. 3",
                            groupedByCategory: view === /* ByCategory */0,
                            columns: columns
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("absolute"),
                              tl: {
                                hd: Css.background({
                                      NAME: "linearGradient",
                                      VAL: [
                                        {
                                          NAME: "deg",
                                          VAL: 180.0
                                        },
                                        [
                                          [
                                            Css.pct(0.0),
                                            Styles.Color.setAlpha(Styles.Color.grey20, 0.0)
                                          ],
                                          [
                                            Css.pct(98.0),
                                            Styles.Color.setAlpha(Styles.Color.grey20, 1.0)
                                          ],
                                          [
                                            Css.pct(100.0),
                                            Styles.Color.setAlpha(Styles.Color.grey20, 1.0)
                                          ]
                                        ]
                                      ]
                                    }),
                                tl: {
                                  hd: Css.bottom(Css.px(0)),
                                  tl: {
                                    hd: Css.width(Css.pct(100.0)),
                                    tl: {
                                      hd: Css.height(Css.px(70)),
                                      tl: {
                                        hd: Css.zIndex(2),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      })), React.createElement(EventsEmpty.make, {
                    globalSend: globalSend,
                    role: role,
                    schemaGroup: match.schemaGroup,
                    propertiesCount: 0
                  }));
          break;
      
    }
  } else {
    switch (item.TAG | 0) {
      case /* WorkbenchHeader */0 :
          var modifiedCount = item._1;
          var newCount = item._0;
          var pillText;
          var exit = 0;
          if (newCount !== 0) {
            if (newCount !== 1 || modifiedCount !== 0) {
              exit = 1;
            } else {
              pillText = "1 new event";
            }
          } else if (modifiedCount !== 0) {
            if (modifiedCount !== 1) {
              exit = 1;
            } else {
              pillText = "1 changed event";
            }
          } else {
            pillText = "0 new or changed events";
          }
          if (exit === 1) {
            pillText = modifiedCount !== 0 ? (
                newCount !== 0 ? (
                    modifiedCount !== 1 ? String(newCount) + " new and " + String(modifiedCount) + " changed events" : String(newCount) + " new and 1 changed event"
                  ) : String(modifiedCount) + " changed events"
              ) : String(newCount) + " new events";
          }
          tmp$1 = React.createElement(SheetSectionHeader.make, {
                columns: columns,
                label: match.branchName,
                pillText: pillText,
                view: view,
                tint: "Green",
                children: React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.paddingRight(Css.px(8)),
                            tl: /* [] */0
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          singleLine: true,
                          color: Styles.Color.mintGreenSecondary,
                          children: "Workbench"
                        }))
              });
          break;
      case /* WorkbenchEvent */1 :
          var modificationState = item._2;
          var $$event = item._1;
          var onClick = function (param) {
            if (details !== undefined && details === $$event.id) {
              return Router.Schema.popDrawerItem(undefined);
            }
            return Router.Schema.pushDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          $$event.id,
                          undefined,
                          undefined,
                          false
                        ]
                      });
          };
          tmp$1 = React.createElement(EventsSheet$EventRow, {
                allCategories: allCategories,
                allEventProperties: allEventProperties,
                allGroupPropertiesWithGroupTypeNames: allGroupPropertiesWithGroupTypeNames,
                allPropertyGroups: allPropertyGroups,
                allResolvedPropertyGroups: allResolvedPropertyGroups,
                allTags: allTags,
                columns: columns,
                currentFilters: currentFilters,
                event: $$event,
                isEventModified: modificationState !== /* Unmodified */0,
                eventLocation: "Workbench",
                globalSend: globalSend,
                groupedByCategory: view === /* ByCategory */0,
                model: branchModel._0,
                modificationState: modificationState,
                onClick: onClick,
                role: role,
                selected: Belt_Option.mapWithDefaultU(details, false, (function (detailsId) {
                        return detailsId === $$event.id;
                      })),
                withDetails: Belt_Option.isSome(details),
                innerBorderColor: Styles.Color.setAlpha(Styles.Color.mintGreen, 0.3),
                outerBorderColor: Styles.Color.mintGreenSecondary,
                backgroundColor: Styles.Color.mintGreenBg
              });
          break;
      case /* Event */2 :
          var modificationState$1 = item._3;
          var $$event$1 = item._2;
          var onClick$1 = function (param) {
            if (details !== undefined && details === $$event$1.id) {
              return Router.Schema.popDrawerItem(undefined);
            }
            return Router.Schema.pushDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          $$event$1.id,
                          undefined,
                          undefined,
                          false
                        ]
                      });
          };
          tmp$1 = React.createElement(EventsSheet$EventRow, {
                allCategories: allCategories,
                allEventProperties: allEventProperties,
                allGroupPropertiesWithGroupTypeNames: allGroupPropertiesWithGroupTypeNames,
                allPropertyGroups: allPropertyGroups,
                allResolvedPropertyGroups: allResolvedPropertyGroups,
                allTags: allTags,
                columns: columns,
                currentFilters: currentFilters,
                event: $$event$1,
                isEventModified: modificationState$1 !== /* Unmodified */0,
                eventLocation: "Catalog",
                globalSend: globalSend,
                groupedByCategory: view === /* ByCategory */0,
                model: branchModel._0,
                modificationState: modificationState$1,
                onClick: onClick$1,
                role: role,
                selected: Belt_Option.mapWithDefaultU(details, false, (function (detailsId) {
                        return detailsId === $$event$1.id;
                      })),
                withDetails: Belt_Option.isSome(details)
              });
          break;
      case /* UncategorizedHeader */3 :
          var sectionCount = item._0;
          tmp$1 = React.createElement(SheetSectionHeader.make, {
                columns: columns,
                label: "CATEGORY",
                pillText: String(sectionCount) + " event" + (
                  sectionCount > 1 ? "s" : ""
                ),
                children: React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.paddingRight(Css.px(8)),
                            tl: /* [] */0
                          })
                    }, React.createElement($$Text.make, {
                          element: "Em",
                          size: "Medium",
                          weight: "Semi",
                          singleLine: true,
                          color: Styles.Color.grey50,
                          children: "Uncategorized"
                        }))
              });
          break;
      case /* CategoryHeader */4 :
          tmp$1 = React.createElement(EventsSheet$CategoryHeader, {
                category: item._0,
                columns: columns,
                sectionCount: item._1
              });
          break;
      
    }
  }
  return React.createElement("div", tmp, tmp$1);
}

var ReactWindowRow = {
  make: EventsSheet$ReactWindowRow
};

function EventsSheet(Props) {
  var currentBranch = Props.currentBranch;
  var globalSend = Props.globalSend;
  var branchName = Props.branchName;
  var branchModel = Props.branchModel;
  var role = Props.role;
  var user = Props.user;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = GlobalStateContext.use(undefined);
  var sorting = match.eventSort;
  var filters = match.filters;
  var drawerItems = RouterStore.Schema.useDrawerItems(undefined);
  FilterQueryParams.useSetFilterQueryParams(filters, sorting);
  var model = branchModel._0;
  var currentFilters = AnalyticsUtils.currentFilters(model, filters, sorting);
  var storedView = Belt_Option.flatMap(Caml_option.nullable_to_opt(user.eventsView), (function (viewString) {
          switch (viewString) {
            case "ByCategory" :
                return /* ByCategory */0;
            case "List" :
                return /* List */1;
            default:
              return ;
          }
        }));
  var match$1 = React.useState(function () {
        return Belt_Option.getWithDefault(storedView, /* ByCategory */0);
      });
  var setView = match$1[1];
  var view = match$1[0];
  var events = React.useMemo((function () {
          return Belt_List.toArray(StateFilterUtils.filterExcludedEvents(Belt_List.sortU(model.events, (function (a, b) {
                                return stringCompare(a.name, b.name);
                              })), model, filters));
        }), [
        model.events,
        model.properties,
        model.goals,
        filters
      ]);
  var eventsLookup = React.useMemo((function () {
          if (view) {
            return organizeEventsAsList(branchModel, events);
          } else {
            return organizeEventsByCategory(model, branchModel, events, filters);
          }
        }), [
        events,
        view,
        Belt_List.fromArray(filters)
      ]);
  var match$2 = React.useState(function () {
        return false;
      });
  var setMounted = match$2[1];
  var mounted = match$2[0];
  var details = Belt_List.head(Belt_List.keepMap(drawerItems, (function (item) {
              if (typeof item === "object" && item.NAME === "event") {
                return item.VAL[0];
              }
              
            })));
  var match$3 = SheetUtils.Events.useScrollSync(undefined);
  var sheetRef = match$3.sheetRef;
  var handleReactWindowRef = match$3.handleReactWindowRef;
  var handleOuterRef = match$3.handleOuterRef;
  React.useEffect((function () {
          if (mounted) {
            var match = sheetRef.current;
            if (details !== undefined && !(match == null)) {
              var eventsIndex = Belt_Array.getIndexByU(events, (function ($$event) {
                      return $$event.id === details;
                    }));
              var indexOfFirstInstanceInLookup = Belt_Array.getIndexByU(eventsLookup, (function (row) {
                      if (typeof row === "number" || !(row.TAG === /* Event */2 && eventsIndex !== undefined)) {
                        return false;
                      } else {
                        return row._0 === eventsIndex;
                      }
                    }));
              Belt_Option.forEach(indexOfFirstInstanceInLookup, (function (index) {
                      match.scrollToItem(index, "center");
                      
                    }));
            }
            
          } else {
            Curry._1(setMounted, (function (param) {
                    return true;
                  }));
          }
          
        }), [
        mounted,
        view
      ]);
  var itemSize = React.useCallback((function (_index) {
          return 50;
        }), []);
  var itemKey = React.useCallback((function (index) {
          var match = Belt_Array.get(eventsLookup, index);
          if (match === undefined) {
            return String(index);
          }
          if (typeof match === "number") {
            switch (match) {
              case /* WorkbenchFooter */0 :
                  return "WorkbenchFooter";
              case /* CategoryFooter */1 :
                  return String(index);
              case /* EmptyState */2 :
                  return "EmptyState";
              
            }
          } else {
            switch (match.TAG | 0) {
              case /* WorkbenchHeader */0 :
                  return "WorkbenchHeader";
              case /* WorkbenchEvent */1 :
                  return "Modified" + match._1.id;
              case /* Event */2 :
                  return Belt_Option.getWithDefault(match._1, "") + match._2.id;
              case /* UncategorizedHeader */3 :
                  return "UncategorizedHeader";
              case /* CategoryHeader */4 :
                  return match._0.id;
              
            }
          }
        }), [eventsLookup]);
  var match$4 = React.useState(function () {
        return deriveColumnsFromServer(Caml_option.nullable_to_opt(user.columnSettings));
      });
  var setColumns = match$4[1];
  var columns = match$4[0];
  React.useEffect((function () {
          var shouldUpdate = Caml_obj.caml_notequal(deriveColumnsFromServer(Caml_option.nullable_to_opt(user.columnSettings)), columns);
          if (shouldUpdate) {
            updateColumnSettingsOnServer(user.id, columns);
          }
          
        }), [columns]);
  var handleViewChange = function (newView) {
    FirebaseUtils.setEventsViewPreference(user.id, newView ? "List" : "ByCategory");
    Curry._1(setView, (function (param) {
            return newView;
          }));
    return AnalyticsRe.eventsViewConfigured(schemaGroup, "View", newView === /* ByCategory */0 ? "SetViewAsGroupedByCategory" : "SetViewAsList", undefined, Belt_Array.keepMapU(Belt_Array.keepU(columns, (function (column) {
                          return column.visible;
                        })), (function (column) {
                      return AnalyticsUtils.columnTypeToEventColumnsName(column.columnType);
                    })), Belt_Array.keepU(columns, (function (column) {
                      return column.visible;
                    })).length, schemaGroup.branchId, schemaGroup.schemaId);
  };
  var allCategories = React.useMemo((function () {
          return Belt_List.toArray(model.goals);
        }), [model.goals]);
  var allTags = React.useMemo((function () {
          return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_List.toArray(Belt_List.mapU(model.events, (function (param) {
                                        return Belt_List.toArray(param.tags);
                                      }))))));
        }), [model.events]);
  var allProperties = React.useMemo((function () {
          return Belt_List.toArray(Belt_List.keepMapU(model.properties, (function (p) {
                            var tmp;
                            tmp = p._0.id;
                            return StateUtils.resolvePropertyById(tmp, model);
                          })));
        }), [model.properties]);
  var allPropertyGroups = React.useMemo((function () {
          return Belt_List.toArray(model.propertyBundles);
        }), [model.propertyBundles]);
  var allResolvedPropertyGroups = React.useMemo((function () {
          return Belt_Array.mapU(allPropertyGroups, (function (modelPropertyGroup) {
                        return {
                                id: modelPropertyGroup.id,
                                name: modelPropertyGroup.name,
                                properties: Belt_List.toArray(Belt_List.keepMapU(modelPropertyGroup.properties, (function (propertyId) {
                                            return Belt_Array.getByU(allProperties, (function (param) {
                                                          return param.id === propertyId;
                                                        }));
                                          })))
                              };
                      }));
        }), [allPropertyGroups]);
  var allGroupPropertiesWithGroupTypeNames = Belt_List.toArray(Belt_List.keepMapU(model.properties, (function (property) {
              if (property.TAG === /* PropertyRef */0) {
                return ;
              }
              var property$1 = property._0;
              var groupTypeId = property$1.sendAs;
              if (typeof groupTypeId === "number") {
                return ;
              } else {
                return Belt_Option.map(StateUtils.getGroupType(groupTypeId._0, model), (function (param) {
                              return [
                                      property$1,
                                      param.name
                                    ];
                            }));
              }
            })));
  var allEventProperties = Belt_List.toArray(Belt_List.keepMapU(model.properties, (function (property) {
              if (property.TAG === /* PropertyRef */0) {
                return ;
              }
              var property$1 = property._0;
              if (property$1.sendAs === /* EventProperty */1) {
                return property$1;
              }
              
            })));
  var hasEmptyState = model.events === /* [] */0;
  React.useEffect((function () {
          if (hasEmptyState) {
            AnalyticsRe.emptyStateDisplayed(schemaGroup, "Events", schemaGroup.schemaId);
          }
          
        }), []);
  var emptyItem = function (item) {
    return {
            allCategories: [],
            allEventProperties: [],
            allGroupPropertiesWithGroupTypeNames: [],
            allPropertyGroups: [],
            allResolvedPropertyGroups: [],
            allTags: [],
            item: item,
            branchId: Actions.branchToId(currentBranch),
            branchName: branchName,
            columns: columns,
            currentFilters: currentFilters,
            details: details,
            globalSend: globalSend,
            branchModel: branchModel,
            role: role,
            schemaGroup: schemaGroup,
            sendActions: sendActions,
            view: view
          };
  };
  var itemData = React.useMemo((function () {
          if (hasEmptyState) {
            return [emptyItem(/* EmptyState */2)];
          } else {
            return Belt_Array.map(eventsLookup, (function (item) {
                          return {
                                  allCategories: allCategories,
                                  allEventProperties: allEventProperties,
                                  allGroupPropertiesWithGroupTypeNames: allGroupPropertiesWithGroupTypeNames,
                                  allPropertyGroups: allPropertyGroups,
                                  allResolvedPropertyGroups: allResolvedPropertyGroups,
                                  allTags: allTags,
                                  item: item,
                                  branchId: Actions.branchToId(currentBranch),
                                  branchName: branchName,
                                  columns: columns,
                                  currentFilters: currentFilters,
                                  details: details,
                                  globalSend: globalSend,
                                  branchModel: branchModel,
                                  role: role,
                                  schemaGroup: schemaGroup,
                                  sendActions: sendActions,
                                  view: view
                                };
                        }));
          }
        }), [
        events,
        details,
        columns,
        view,
        model.goals
      ]);
  return React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: headerStyles$1
                }, React.createElement(Title.make, {
                      children: null,
                      shrink: 0.0,
                      size: "Tiny"
                    }, "Events ", React.createElement("span", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("inlineBlock"),
                                tl: {
                                  hd: Css.minWidth(Css.px(48)),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              element: "Span",
                              size: "Medium",
                              color: Styles.Color.grey70,
                              children: "(" + (String(events.length) + ")")
                            }))), Models.Role.canEdit(role) ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                            width: 4
                          }), React.createElement(Button.make, {
                            icon: "plus",
                            label: "New Event",
                            onClick: (function (param) {
                                if (Belt_List.length(model.events) === 0) {
                                  AnalyticsRe.emptyStateInteracted(schemaGroup, "Events", "Setup", undefined);
                                }
                                return Curry._1(globalSend, {
                                            TAG: /* OpenModal */4,
                                            _0: {
                                              NAME: "NewEvent",
                                              VAL: [
                                                "",
                                                (function (eventId, _name) {
                                                    return Router.Schema.pushDrawerItem(undefined, {
                                                                NAME: "event",
                                                                VAL: [
                                                                  eventId,
                                                                  undefined,
                                                                  undefined,
                                                                  false
                                                                ]
                                                              });
                                                  }),
                                                "EventsView"
                                              ]
                                            }
                                          });
                              })
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(Button.make, {
                            icon: "plus",
                            label: "New Category",
                            onClick: (function (param) {
                                Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "CreateCategory",
                                        VAL: [
                                          undefined,
                                          undefined
                                        ]
                                      }
                                    });
                                return AnalyticsRe.categoryInitiated(schemaGroup, schemaGroup.branchId, schemaGroup.schemaId);
                              }),
                            style: "outline"
                          })) : null, React.createElement(Spacer.make, {
                      width: 16
                    }), React.createElement(SegmentControl.make, {
                      options: [
                        [
                          "Category",
                          /* ByCategory */0
                        ],
                        [
                          "List",
                          /* List */1
                        ]
                      ],
                      onSelect: handleViewChange,
                      selected: view
                    }), React.createElement(Spacer.make, {
                      width: 16
                    }), React.createElement(EventsSheet$CustomizeButtonWithMenu, {
                      columns: columns,
                      setColumns: setColumns,
                      resetColumns: (function (param) {
                          return Curry._1(setColumns, (function (param) {
                                        AnalyticsRe.eventsViewConfigured(schemaGroup, "ColumnWidth", "Reset", undefined, Belt_Array.keepMapU(Belt_Array.keepU(defaultColumns, (function (column) {
                                                        return column.visible;
                                                      })), (function (column) {
                                                    return AnalyticsUtils.columnTypeToEventColumnsName(column.columnType);
                                                  })), Belt_Array.keepU(defaultColumns, (function (column) {
                                                    return column.visible;
                                                  })).length, schemaGroup.branchId, schemaGroup.schemaId);
                                        return defaultColumns;
                                      }));
                        }),
                      schemaGroup: schemaGroup
                    }), React.createElement(EventsSheetFilters.make, {
                      model: model,
                      globalSend: globalSend,
                      schemaGroup: schemaGroup,
                      filters: filters
                    })), React.createElement(SheetHeader.make, {
                  columns: columns,
                  setColumns: setColumns,
                  view: view,
                  onWidthChange: (function (direction, newColumns, columnType) {
                      return AnalyticsRe.eventsViewConfigured(schemaGroup, "ColumnWidth", direction === "Increase" ? "IncreaseColumnWidth" : "DecreaseColumnWidth", AnalyticsUtils.columnTypeToEventColumnsName(columnType), Belt_Array.keepMapU(Belt_Array.keepU(newColumns, (function (param) {
                                            return param.visible;
                                          })), (function (param) {
                                        return AnalyticsUtils.columnTypeToEventColumnsName(param.columnType);
                                      })), Belt_Array.keepU(newColumns, (function (param) {
                                        return param.visible;
                                      })).length, schemaGroup.branchId, schemaGroup.schemaId);
                    }),
                  ref: match$3.handleSheetHeaderRef
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.height({
                                NAME: "substract",
                                VAL: [
                                  Css.vh(100),
                                  Css.px(64 + SheetHeader.columnsHeaderHeight | 0)
                                ]
                              }),
                          tl: {
                            hd: view === /* ByCategory */0 ? Css.paddingLeft(Css.px(16)) : Css.padding("zero"),
                            tl: {
                              hd: Css.position("relative"),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  id: "sheetview-tour-table"
                }, !hasEmptyState && events.length === 0 ? React.createElement("div", {
                        className: emptyFilteredEvents
                      }, React.createElement(Title.make, {
                            children: "Found no matching events",
                            size: "Medium"
                          }), React.createElement(Spacer.make, {
                            height: 12
                          }), React.createElement($$Text.make, {
                            size: "Large",
                            color: Styles.Color.grey70,
                            maxWidth: Css.px(360),
                            children: null
                          }, "It looks like none of your events matched your filters. Try ", React.createElement(TextButton.make, {
                                onClick: (function (param) {
                                    return Curry._1(globalSend, {
                                                TAG: /* UpdateFilters */0,
                                                _0: /* Reset */7,
                                                _1: "Events"
                                              });
                                  }),
                                style: "Blue",
                                children: "resetting them"
                              }), ".")) : null, React.createElement(ReactVirtualizedAutoSizer, {
                      children: (function (size) {
                          return React.createElement(ReactWindow.VariableSizeList, {
                                      estimatedItemSize: 50,
                                      height: size.height,
                                      innerElementType: SheetContainer.make,
                                      itemCount: hasEmptyState ? itemData.length : eventsLookup.length,
                                      itemData: itemData,
                                      itemKey: itemKey,
                                      itemSize: itemSize,
                                      outerRef: handleOuterRef,
                                      overscanCount: 30,
                                      ref: handleReactWindowRef,
                                      width: size.width,
                                      children: EventsSheet$ReactWindowRow
                                    });
                        })
                    })));
}

var itemHeight = 50;

var headerHeight = 64;

var make = EventsSheet;

exports.renderModelFromBranchModel = renderModelFromBranchModel;
exports.defaultColumns = defaultColumns;
exports.getGetNewAndChangedRowCounts = getGetNewAndChangedRowCounts;
exports.itemHeight = itemHeight;
exports.EventRow = EventRow;
exports.CategoryHeader = CategoryHeader;
exports.CustomizeButtonWithMenu = CustomizeButtonWithMenu;
exports.headerHeight = headerHeight;
exports.headerStyles = headerStyles$1;
exports.emptyFilteredEvents = emptyFilteredEvents;
exports.stringCompare = stringCompare;
exports.getEventModificationStatus = getEventModificationStatus;
exports.isEventModified = isEventModified;
exports.organizeEventsAsList = organizeEventsAsList;
exports.organizeEventsByCategory = organizeEventsByCategory;
exports.deriveColumnsFromServer = deriveColumnsFromServer;
exports.updateColumnSettingsOnServer = updateColumnSettingsOnServer;
exports.ReactWindowRow = ReactWindowRow;
exports.make = make;
/* EditableCategoriesCell Not a pure module */
