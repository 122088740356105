// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Zustand = require("../Zustand.bs.js");
var Zustand$1 = require("zustand");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Float = require("rescript/lib/js/belt_Float.js");
var ModelStore = require("../ModelStore.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var InspectorViewApi = require("./InspectorViewApi.bs.js");
var WorkspaceContext = require("../WorkspaceContext.bs.js");
var BeltListExtensions = require("../BeltListExtensions.bs.js");
var InspectorViewModel = require("./InspectorViewModel.bs.js");
var BeltArrayExtensions = require("../BeltArrayExtensions.bs.js");
var InspectorViewTrackingPlanUtils = require("./InspectorViewTrackingPlanUtils.bs.js");
var Difference_in_seconds = require("date-fns/difference_in_seconds");

function initialPageSize(schemaId) {
  if (schemaId === "0W1AhQECqKT8Mns7rnbu") {
    return 100;
  } else {
    return 500;
  }
}

function pageSize(schemaId) {
  if (schemaId === "0W1AhQECqKT8Mns7rnbu") {
    return 100;
  } else {
    return 500;
  }
}

var StoreConfig = {};

function compare(a, b) {
  return Caml.caml_string_compare(a.eventName, b.eventName);
}

var EventComparator = BeltListExtensions.Dedupe({
      compare: compare
    });

var requestIdDivider = "--#--";

function destructureRequestId(requestId) {
  var bits = requestId.split(requestIdDivider);
  var schemaId = Belt_Array.get(bits, 0);
  var environment = Belt_Option.flatMap(Belt_Array.get(bits, 1), InspectorViewModel.environmentFromJs);
  var timeWindow = Belt_Option.flatMap(Belt_Array.get(bits, 2), InspectorViewModel.timeWindowFromJs);
  var timestamp = Belt_Option.flatMap(Belt_Array.get(bits, 3), Belt_Float.fromString);
  if (schemaId !== undefined && environment !== undefined && timeWindow !== undefined && timestamp !== undefined) {
    return {
            schemaId: schemaId,
            environment: environment,
            timeWindow: timeWindow,
            timestamp: timestamp
          };
  }
  
}

function makeRequestId(schemaId, environment, timeWindow) {
  return schemaId + requestIdDivider + InspectorViewModel.environmentToJs(environment) + requestIdDivider + InspectorViewModel.timeWindowToJs(timeWindow) + requestIdDivider + Date.now().toString();
}

function isRequestEqual(requestIdA, requestIdB) {
  if (requestIdA.schemaId === requestIdB.schemaId && requestIdA.environment === requestIdB.environment) {
    return requestIdA.timeWindow === requestIdB.timeWindow;
  } else {
    return false;
  }
}

function getRequestIdsWithSameParameters(schemaId, environment, timeWindow, m) {
  return Belt_Array.keep(Belt_MapString.keysToArray(m), (function (requestId) {
                var requestConfig = destructureRequestId(requestId);
                if (requestConfig !== undefined && requestConfig.schemaId === schemaId && requestConfig.timeWindow === timeWindow) {
                  return requestConfig.environment === environment;
                } else {
                  return false;
                }
              }));
}

function getLatestRequestId(schemaId, environment, timeWindow, m) {
  return Belt_Array.get(Belt_Array.reverse(Belt_List.toArray(Belt_List.sort(Belt_List.fromArray(getRequestIdsWithSameParameters(schemaId, environment, timeWindow, m)), Caml.caml_string_compare))), 0);
}

function getLatestRequest(schemaId, environment, timeWindow, m) {
  var requestId = getLatestRequestId(schemaId, environment, timeWindow, m);
  if (requestId !== undefined) {
    return Belt_MapString.get(m, requestId);
  }
  
}

function getLatestSuccesfulRequest(schemaId, environment, timeWindow, m) {
  return getLatestRequest(schemaId, environment, timeWindow, Belt_MapString.keep(m, (function (param, request) {
                    var match = request.loadingState;
                    if (typeof match === "object") {
                      return match.NAME === "success";
                    } else {
                      return false;
                    }
                  })));
}

var ZustandStore = Zustand.MakeStore(StoreConfig);

function setRequest(state, requestId, loadingState, events) {
  var match = Curry._1(ModelStore.ZustandStore.getState, ModelStore.store);
  var events$1 = Belt_List.toArray(Curry._1(EventComparator.dedupe, Curry._1(EventComparator.sort, Belt_List.fromArray(events))));
  var enrichedEvents = InspectorViewTrackingPlanUtils.enrichInspectorDashboardEventsWithTrackingPlan(events$1, match.model);
  var requests = Belt_MapString.set(state.requests, requestId, {
        events: events$1,
        enrichedEvents: enrichedEvents,
        enrichedWithActionId: match.latestActionId,
        loadingState: loadingState
      });
  return {
          requests: requests,
          updateOnModelChange: state.updateOnModelChange,
          currentIssueRequestConfig: state.currentIssueRequestConfig,
          loadBatchWithRequest: state.loadBatchWithRequest,
          fetchWithRequest: state.fetchWithRequest
        };
}

function updateRequest(state, requestId, loadingState, events) {
  var requests = Belt_MapString.update(state.requests, requestId, (function (maybeRequest) {
          var concatenatedEvents = Belt_List.toArray(Curry._1(EventComparator.dedupe, Curry._1(EventComparator.sort, Belt_List.concat(Belt_Option.mapWithDefault(maybeRequest, /* [] */0, (function (param) {
                                  return Belt_List.fromArray(param.events);
                                })), Belt_List.fromArray(events)))));
          var match = Curry._1(ModelStore.ZustandStore.getState, ModelStore.store);
          var latestActionId = match.latestActionId;
          var model = match.model;
          var eventsNeedToBeReEnriched = Caml_obj.caml_notequal(latestActionId, Belt_Option.flatMap(maybeRequest, (function (r) {
                      return r.enrichedWithActionId;
                    })));
          var enrichedEvents = eventsNeedToBeReEnriched ? InspectorViewTrackingPlanUtils.enrichInspectorDashboardEventsWithTrackingPlan(concatenatedEvents, model) : Belt_List.toArray(Curry._1(EventComparator.dedupe, Curry._1(EventComparator.sort, Belt_List.concat(Belt_Option.mapWithDefault(maybeRequest, /* [] */0, (function (param) {
                                    return Belt_List.fromArray(param.enrichedEvents);
                                  })), Belt_List.fromArray(InspectorViewTrackingPlanUtils.enrichInspectorDashboardEventsWithTrackingPlan(events, model))))));
          return {
                  events: concatenatedEvents,
                  enrichedEvents: enrichedEvents,
                  enrichedWithActionId: latestActionId,
                  loadingState: loadingState
                };
        }));
  return {
          requests: requests,
          updateOnModelChange: state.updateOnModelChange,
          currentIssueRequestConfig: state.currentIssueRequestConfig,
          loadBatchWithRequest: state.loadBatchWithRequest,
          fetchWithRequest: state.fetchWithRequest
        };
}

function updateLoadingState(state, requestId, loadingState) {
  return {
          requests: Belt_MapString.update(state.requests, requestId, (function (maybeRequest) {
                  return {
                          events: Belt_Option.mapWithDefault(maybeRequest, [], (function (param) {
                                  return param.events;
                                })),
                          enrichedEvents: Belt_Option.mapWithDefault(maybeRequest, [], (function (param) {
                                  return param.enrichedEvents;
                                })),
                          enrichedWithActionId: Belt_Option.mapWithDefault(maybeRequest, undefined, (function (param) {
                                  return param.enrichedWithActionId;
                                })),
                          loadingState: loadingState
                        };
                })),
          updateOnModelChange: state.updateOnModelChange,
          currentIssueRequestConfig: state.currentIssueRequestConfig,
          loadBatchWithRequest: state.loadBatchWithRequest,
          fetchWithRequest: state.fetchWithRequest
        };
}

var store = Zustand$1.create(function (set, get) {
      return {
              requests: undefined,
              updateOnModelChange: (function (requestId, model, latestActionId) {
                  return set(function (state) {
                              return {
                                      requests: Belt_MapString.update(state.requests, requestId, (function (maybeRequest) {
                                              if (maybeRequest === undefined) {
                                                return ;
                                              }
                                              if (Caml_obj.caml_equal(maybeRequest.enrichedWithActionId, latestActionId)) {
                                                return maybeRequest;
                                              }
                                              var enrichedEvents = InspectorViewTrackingPlanUtils.enrichInspectorDashboardEventsWithTrackingPlan(maybeRequest.events, model);
                                              return {
                                                      events: maybeRequest.events,
                                                      enrichedEvents: enrichedEvents,
                                                      enrichedWithActionId: latestActionId,
                                                      loadingState: maybeRequest.loadingState
                                                    };
                                            })),
                                      updateOnModelChange: state.updateOnModelChange,
                                      currentIssueRequestConfig: state.currentIssueRequestConfig,
                                      loadBatchWithRequest: state.loadBatchWithRequest,
                                      fetchWithRequest: state.fetchWithRequest
                                    };
                            });
                }),
              currentIssueRequestConfig: {
                environment: "Production",
                timeWindow: "Last24Hours"
              },
              loadBatchWithRequest: (function (schemaId, environment, timeWindow, currentRequestId) {
                  var match = Belt_MapString.get(Curry._1(get, undefined).requests, currentRequestId);
                  if (match === undefined) {
                    return Promise.resolve(undefined);
                  }
                  var loadingState = match.loadingState;
                  if (typeof loadingState !== "object") {
                    return Promise.resolve(undefined);
                  }
                  if (loadingState.NAME !== "inProgress") {
                    return Promise.resolve(undefined);
                  }
                  var match$1 = loadingState.VAL;
                  var batches = Belt_Array.keepU(Belt_Array.reduceU(match$1[1], [[]], (function (batches, eventName) {
                              var currentBatch = Belt_Option.getWithDefault(Belt_Array.get(batches, 0), []);
                              var rest = BeltArrayExtensions.sliceToEndSafe(batches, 1);
                              if (currentBatch.length === pageSize(schemaId)) {
                                return Belt_Array.concat(batches, [[eventName]]);
                              }
                              var currentBatch$1 = Belt_Array.concat(currentBatch, [eventName]);
                              return Belt_Array.concat(rest, [currentBatch$1]);
                            })), (function (batch) {
                          return batch.length !== 0;
                        }));
                  if (batches.length !== 0) {
                    var batch = Belt_Array.getExn(batches, 0);
                    var rest = BeltArrayExtensions.sliceToEndSafe(batches, 1);
                    var fromEventName = Belt_Array.get(batch, 0);
                    var toEventName = Belt_Array.get(batch, batch.length - 1 | 0);
                    return InspectorViewApi.getEvents(schemaId, environment, timeWindow, false, Caml_option.some(fromEventName), Caml_option.some(toEventName), undefined, undefined).then(function (response) {
                                var eventsFromBatch = response.NAME === "Error" ? [] : response.VAL.results.events;
                                var hasError = Caml_obj.caml_equal(response, {
                                      NAME: "Error",
                                      VAL: /* Internal */0
                                    }) || Caml_obj.caml_equal(response, {
                                      NAME: "Error",
                                      VAL: /* NotFound */1
                                    });
                                var errorType = typeof response === "object" && response.NAME === "Error" ? response.VAL : undefined;
                                set(function (state) {
                                      if (hasError && typeof loadingState === "object" && loadingState.NAME === "inProgress") {
                                        var match = loadingState.VAL;
                                        var requestId = match[0];
                                        if (requestId === currentRequestId) {
                                          return updateRequest(state, requestId, {
                                                      NAME: "error",
                                                      VAL: [
                                                        requestId,
                                                        errorType,
                                                        match[1]
                                                      ]
                                                    }, eventsFromBatch);
                                        }
                                        
                                      }
                                      if (typeof loadingState !== "object") {
                                        return state;
                                      }
                                      if (loadingState.NAME !== "inProgress") {
                                        return state;
                                      }
                                      var requestId$1 = loadingState.VAL[0];
                                      if (requestId$1 !== currentRequestId) {
                                        return state;
                                      }
                                      var remainingEventNames = Belt_Array.concatMany(rest);
                                      return updateRequest(state, requestId$1, {
                                                  NAME: "inProgress",
                                                  VAL: [
                                                    currentRequestId,
                                                    remainingEventNames
                                                  ]
                                                }, eventsFromBatch);
                                    });
                                var match = Belt_MapString.get(Curry._1(get, undefined).requests, currentRequestId);
                                if (match === undefined) {
                                  return Promise.resolve(undefined);
                                }
                                var match$1 = match.loadingState;
                                if (typeof match$1 === "object" && match$1.NAME === "inProgress") {
                                  return Curry._4(Curry._1(get, undefined).loadBatchWithRequest, schemaId, environment, timeWindow, currentRequestId);
                                } else {
                                  return Promise.resolve(undefined);
                                }
                              });
                  }
                  var loadingState_1 = [
                    match$1[0],
                    Date.now()
                  ];
                  var loadingState$1 = {
                    NAME: "success",
                    VAL: loadingState_1
                  };
                  set(function (state) {
                        return updateLoadingState(state, currentRequestId, loadingState$1);
                      });
                  return Promise.resolve(undefined);
                }),
              fetchWithRequest: (function (schemaId, environment, timeWindow) {
                  var match = getLatestRequest(schemaId, environment, timeWindow, Curry._1(get, undefined).requests);
                  if (match !== undefined) {
                    var match$1 = match.loadingState;
                    var exit = 0;
                    var requestId;
                    if (typeof match$1 === "object") {
                      var variant = match$1.NAME;
                      if (variant === "error" || variant === "inProgress") {
                        requestId = match$1.VAL[0];
                        exit = 2;
                      } else if (variant === "loading") {
                        requestId = match$1.VAL;
                        exit = 2;
                      }
                      
                    }
                    if (exit === 2 && Belt_Option.mapWithDefault(destructureRequestId(requestId), false, (function (requestConfig) {
                              return isRequestEqual(requestConfig, {
                                          schemaId: schemaId,
                                          environment: environment,
                                          timeWindow: timeWindow,
                                          timestamp: Date.now()
                                        });
                            }))) {
                      return Promise.resolve(undefined);
                    }
                    
                  }
                  var currentRequestId = makeRequestId(schemaId, environment, timeWindow);
                  set(function (state) {
                        return updateLoadingState(state, currentRequestId, {
                                    NAME: "loading",
                                    VAL: currentRequestId
                                  });
                      });
                  return InspectorViewApi.getEvents(schemaId, environment, timeWindow, true, undefined, undefined, Caml_option.some(initialPageSize(schemaId)), undefined).then(function (response) {
                              if (response.NAME === "Error") {
                                var errorType = response.VAL;
                                set(function (state) {
                                      return updateLoadingState(state, currentRequestId, {
                                                  NAME: "error",
                                                  VAL: [
                                                    currentRequestId,
                                                    errorType,
                                                    undefined
                                                  ]
                                                });
                                    });
                                return Promise.resolve(undefined);
                              }
                              var payload = response.VAL;
                              var remainingEventNames = Belt_Option.getWithDefault(Belt_Option.map(payload.results.overview, (function (overview) {
                                          return BeltArrayExtensions.sliceToEndSafe(overview, initialPageSize(schemaId));
                                        })), []);
                              set(function (state) {
                                    var match = Belt_MapString.get(state.requests, currentRequestId);
                                    if (match === undefined) {
                                      return state;
                                    }
                                    var match$1 = match.loadingState;
                                    if (typeof match$1 !== "object") {
                                      return state;
                                    }
                                    if (match$1.NAME !== "loading") {
                                      return state;
                                    }
                                    var requestId = match$1.VAL;
                                    if (Caml_obj.caml_equal(remainingEventNames, [])) {
                                      return setRequest(state, requestId, {
                                                  NAME: "success",
                                                  VAL: [
                                                    currentRequestId,
                                                    Belt_Option.mapWithDefault(payload.results.timeWindowEnd, Date.now(), (function (timeWindowEnd) {
                                                            return new Date(timeWindowEnd).getTime();
                                                          }))
                                                  ]
                                                }, payload.results.events);
                                    } else {
                                      return setRequest(state, requestId, {
                                                  NAME: "inProgress",
                                                  VAL: [
                                                    requestId,
                                                    remainingEventNames
                                                  ]
                                                }, payload.results.events);
                                    }
                                  });
                              var match = Belt_MapString.get(Curry._1(get, undefined).requests, currentRequestId);
                              if (match === undefined) {
                                return Promise.resolve(undefined);
                              }
                              var match$1 = match.loadingState;
                              if (typeof match$1 === "object" && match$1.NAME === "inProgress") {
                                return Curry._4(Curry._1(get, undefined).loadBatchWithRequest, schemaId, environment, timeWindow, currentRequestId);
                              } else {
                                return Promise.resolve(undefined);
                              }
                            });
                })
            };
    });

function useCurrentIssuesRequestConfig(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.currentIssueRequestConfig;
              }));
}

function useUpdateOnModelChange(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.updateOnModelChange;
              }));
}

function useStore(schemaId, environment, timeWindow) {
  var match = Curry._2(ZustandStore.use, store, (function (state) {
          return [
                  state.requests,
                  state.fetchWithRequest
                ];
        }));
  var fetchWithRequest = match[1];
  var requests = match[0];
  var latestRequest = React.useMemo((function () {
          return getLatestRequest(schemaId, environment, timeWindow, requests);
        }), [
        schemaId,
        environment,
        timeWindow,
        requests
      ]);
  var match$1 = React.useMemo((function () {
          if (latestRequest === undefined) {
            return [
                    "initial",
                    [],
                    []
                  ];
          }
          var loadingState = latestRequest.loadingState;
          var enrichedEvents = latestRequest.enrichedEvents;
          var events = latestRequest.events;
          if (typeof loadingState !== "object") {
            return [
                    loadingState,
                    events,
                    enrichedEvents
                  ];
          }
          var variant = loadingState.NAME;
          if (!(variant === "loading" || variant === "inProgress")) {
            return [
                    loadingState,
                    events,
                    enrichedEvents
                  ];
          }
          var match = getLatestSuccesfulRequest(schemaId, environment, timeWindow, requests);
          if (match !== undefined) {
            return [
                    loadingState,
                    match.events,
                    match.enrichedEvents
                  ];
          } else {
            return [
                    loadingState,
                    [],
                    []
                  ];
          }
        }), [latestRequest]);
  var $$fetch = React.useCallback((function (param) {
          Curry._3(fetchWithRequest, schemaId, environment, timeWindow);
          
        }), [
        schemaId,
        environment,
        timeWindow
      ]);
  React.useEffect((function () {
          if (latestRequest !== undefined) {
            var loadingState = latestRequest.loadingState;
            if (typeof loadingState === "object") {
              var variant = loadingState.NAME;
              if (variant === "error") {
                Curry._1($$fetch, undefined);
              } else if (variant === "success") {
                var match = destructureRequestId(loadingState.VAL[0]);
                if (match !== undefined && Difference_in_seconds(new Date(), new Date(match.timestamp)) < (
                    match.environment === "Production" ? 600.0 : 60.0
                  )) {
                  
                } else {
                  Curry._1($$fetch, undefined);
                }
              }
              
            }
            
          } else {
            Curry._1($$fetch, undefined);
          }
          
        }), [
        schemaId,
        environment,
        timeWindow
      ]);
  var model = ModelStore.useModel(undefined);
  var latestActionId = ModelStore.useLatestActionId(undefined);
  var updateOnModelChange = Curry._2(ZustandStore.use, store, (function (state) {
          return state.updateOnModelChange;
        }));
  React.useEffect((function () {
          if (latestRequest !== undefined) {
            var loadingState = latestRequest.loadingState;
            if (typeof loadingState === "object" && loadingState.NAME !== "loading") {
              Curry._3(updateOnModelChange, loadingState.VAL[0], model, latestActionId);
            }
            
          }
          
        }), [model]);
  return {
          loadingState: match$1[0],
          events: match$1[1],
          enrichedEvents: match$1[2],
          fetch: $$fetch
        };
}

function useEvents(param) {
  var workspace = WorkspaceContext.use(undefined);
  var match = Curry._2(ZustandStore.use, store, (function (state) {
          return state.currentIssueRequestConfig;
        }));
  var match$1 = useStore(workspace.id, match.environment, match.timeWindow);
  return {
          loadingState: match$1.loadingState,
          events: match$1.events,
          enrichedEvents: match$1.enrichedEvents,
          fetch: match$1.fetch
        };
}

var IssuesMap;

exports.initialPageSize = initialPageSize;
exports.pageSize = pageSize;
exports.IssuesMap = IssuesMap;
exports.StoreConfig = StoreConfig;
exports.EventComparator = EventComparator;
exports.requestIdDivider = requestIdDivider;
exports.destructureRequestId = destructureRequestId;
exports.makeRequestId = makeRequestId;
exports.isRequestEqual = isRequestEqual;
exports.getRequestIdsWithSameParameters = getRequestIdsWithSameParameters;
exports.getLatestRequestId = getLatestRequestId;
exports.getLatestRequest = getLatestRequest;
exports.getLatestSuccesfulRequest = getLatestSuccesfulRequest;
exports.ZustandStore = ZustandStore;
exports.setRequest = setRequest;
exports.updateRequest = updateRequest;
exports.updateLoadingState = updateLoadingState;
exports.store = store;
exports.useCurrentIssuesRequestConfig = useCurrentIssuesRequestConfig;
exports.useUpdateOnModelChange = useUpdateOnModelChange;
exports.useStore = useStore;
exports.useEvents = useEvents;
/* EventComparator Not a pure module */
