// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var CmdK = require("../CmdK.bs.js");
var Kbar = require("kbar");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("../Hooks.bs.js");
var React = require("react");
var Router = require("../Router.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Zustand = require("../Zustand.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var CmdKActions = require("../CmdKActions.bs.js");
var FilterOrder = require("../../../shared/models/FilterOrder.bs.js");
var RouterStore = require("../RouterStore.bs.js");
var QueryString = require("query-string");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var Shallow = require("zustand/shallow");
var WorkspaceContext = require("../WorkspaceContext.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FirebaseFetcherHooks = require("../FirebaseFetcherHooks.bs.js");
var InspectorIssuesTypes = require("../InspectorIssuesTypes.bs.js");
var InspectorIssuesFilter = require("../../../shared/models/InspectorIssuesFilter.bs.js");
var InspectorIssuesFilterUtils = require("../../../shared/utils/InspectorIssuesFilterUtils.bs.js");

function removeSavedViewFromPathRec(_path, _newPath) {
  while(true) {
    var newPath = _newPath;
    var path = _path;
    if (!path) {
      return Belt_List.reverse(newPath);
    }
    var a = path.hd;
    if (a === "view") {
      var match = path.tl;
      if (match) {
        _path = match.tl;
        continue ;
      }
      _newPath = {
        hd: a,
        tl: newPath
      };
      _path = path.tl;
      continue ;
    }
    _newPath = {
      hd: a,
      tl: newPath
    };
    _path = path.tl;
    continue ;
  };
}

function removeSavedViewFromPath(path) {
  return removeSavedViewFromPathRec(path, /* [] */0);
}

var StoreConfig = {};

var ZustandStore = Zustand.MakeEqualityFnStore(StoreConfig, Zustand.Compare.Default);

var store = Curry._1(ZustandStore.create, (function (set, _get) {
        var match = RouterStore.Schema.getState(undefined).schemaRoute;
        var tmp;
        if (typeof match === "object" && match.NAME === "inspector") {
          var match$1 = match.VAL;
          tmp = typeof match$1 === "object" && match$1.NAME === "savedView" ? match$1.VAL : undefined;
        } else {
          tmp = undefined;
        }
        return {
                savedViewId: tmp,
                savedViews: "Initial",
                setSavedViews: (function (savedViews) {
                    return set(function (state) {
                                return {
                                        savedViewId: state.savedViewId,
                                        savedViews: savedViews,
                                        setSavedViews: state.setSavedViews,
                                        setSavedViewId: state.setSavedViewId
                                      };
                              });
                  }),
                setSavedViewId: (function (savedViewId) {
                    return set(function (state) {
                                return {
                                        savedViewId: savedViewId,
                                        savedViews: state.savedViews,
                                        setSavedViews: state.setSavedViews,
                                        setSavedViewId: state.setSavedViewId
                                      };
                              });
                  })
              };
      }));

function getState(param) {
  return Curry._1(ZustandStore.getState, store);
}

function useSetSavedViewId(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.setSavedViewId;
              }));
}

function useSetSavedViews(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.setSavedViews;
              }));
}

function useSavedViewState(param) {
  return Curry._3(ZustandStore.useWithCustomEqualityFn, store, (function (state) {
                var match = state.savedViews;
                var match$1 = state.savedViewId;
                if (typeof match !== "object") {
                  if (match === "Initial") {
                    if (match$1 !== undefined) {
                      return {
                              savedViewStatus: "loading",
                              savedView: undefined
                            };
                    } else {
                      return {
                              savedViewStatus: "noSelection",
                              savedView: undefined
                            };
                    }
                  } else if (match$1 !== undefined) {
                    return {
                            savedViewStatus: "notFound",
                            savedView: undefined
                          };
                  } else {
                    return {
                            savedViewStatus: "noSelection",
                            savedView: undefined
                          };
                  }
                }
                if (match$1 === undefined) {
                  return {
                          savedViewStatus: "noSelection",
                          savedView: undefined
                        };
                }
                var savedView = Belt_List.getBy(match.VAL, (function (param) {
                        return param.id === match$1;
                      }));
                if (savedView !== undefined) {
                  return {
                          savedViewStatus: "success",
                          savedView: savedView
                        };
                } else {
                  return {
                          savedViewStatus: "notFound",
                          savedView: undefined
                        };
                }
              }), Shallow.shallow);
}

function useSavedViews(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.savedViews;
              }));
}

function useSavedViewManager(param) {
  var match = WorkspaceContext.use(undefined);
  var maybeId = RouterStore.Schema.useSavedViewId(undefined);
  var savedViews = FirebaseFetcherHooks.useSavedViews(match.id);
  var setSavedViewId = Curry._2(ZustandStore.use, store, (function (state) {
          return state.setSavedViewId;
        }));
  var setSavedViews = Curry._2(ZustandStore.use, store, (function (state) {
          return state.setSavedViews;
        }));
  var tmp;
  if (typeof savedViews === "object") {
    if (savedViews.NAME === "Loaded") {
      var savedViews$1 = savedViews.VAL;
      tmp = Belt_List.length(savedViews$1) > 0 ? Belt_Array.concat([Curry.app(CmdK.Action.t, [
                    "saved-views",
                    {
                      TAG: /* Command */2,
                      _0: "Saved Views..."
                    },
                    1.2,
                    undefined,
                    undefined,
                    (function (param) {
                        return React.createElement(CmdKActions.CmdKIcon.make, {
                                    type_: "search",
                                    size: "small"
                                  });
                      }),
                    undefined,
                    "search",
                    undefined
                  ])], Belt_List.toArray(Belt_List.map(savedViews$1, (function (savedView) {
                        return Curry.app(CmdK.Action.t, [
                                    savedView.id,
                                    {
                                      TAG: /* SavedView */15,
                                      _0: savedView.name
                                    },
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (param) {
                                        return React.createElement(CmdKActions.CmdKIcon.make, {
                                                    type_: "filter",
                                                    size: "small"
                                                  });
                                      }),
                                    (function (param) {
                                        return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                                    NAME: "inspector",
                                                    VAL: {
                                                      NAME: "savedView",
                                                      VAL: savedView.id
                                                    }
                                                  });
                                      }),
                                    "search",
                                    undefined
                                  ]);
                      })))) : [];
    } else {
      tmp = [];
    }
  } else {
    tmp = [];
  }
  Kbar.useRegisterActions(tmp, [savedViews]);
  React.useEffect((function () {
          Curry._1(setSavedViews, savedViews);
          
        }), [savedViews]);
  React.useEffect((function () {
          Curry._1(setSavedViewId, maybeId);
          
        }), [maybeId]);
  
}

var SavedViewStore = {
  StoreConfig: StoreConfig,
  ZustandStore: ZustandStore,
  store: store,
  getState: getState,
  useSetSavedViewId: useSetSavedViewId,
  useSetSavedViews: useSetSavedViews,
  useSavedViewState: useSavedViewState,
  useSavedViews: useSavedViews,
  useSavedViewManager: useSavedViewManager
};

function getStringValues(values) {
  return Belt_Array.keepMap(values.split(","), (function (item) {
                var item$1 = item.trim();
                if (item$1 === "") {
                  return ;
                } else {
                  return decodeURIComponent(item$1);
                }
              }));
}

var getValueStringForKey = Js_dict.get;

function getValuesForKey(d, key) {
  return Belt_Option.map(Js_dict.get(d, key), getStringValues);
}

function getOrderedByFromSearchDict(d) {
  var order = getValuesForKey(d, "orderedBy");
  if (order === undefined) {
    return ;
  }
  if (order.length !== 2) {
    return ;
  }
  var columnId = order[0];
  var match = order[1];
  switch (match) {
    case "asc" :
        return [
                columnId,
                "asc"
              ];
    case "desc" :
        return [
                columnId,
                "desc"
              ];
    default:
      return ;
  }
}

function orderToQueryParam(order) {
  return Belt_Option.map(order, (function (order) {
                return order[0] + "," + encodeURIComponent(FilterOrder.sortDirectionToJs(order[1]));
              }));
}

function stringifySavedView(savedView) {
  if (savedView !== undefined) {
    return savedView.id + ":" + Belt_Array.map(savedView.filters, InspectorIssuesFilter.Filter.toKeyValue).join(",") + ":" + Belt_Option.getWithDefault(orderToQueryParam(savedView.order), "");
  } else {
    return "";
  }
}

var _map = {"sourceIds":"sourceIds","issueTypes":"issueTypes","categoryIds":"categoryIds","tags":"tags","eventName":"eventName","propertyName":"propertyName","releases":"releases"};

function queryFilterKeyToJs(param) {
  return param;
}

function queryFilterKeyFromJs(param) {
  return _map[param];
}

function filterTypeToQueryFilterKey(filterType) {
  if (filterType === "eventName") {
    return "eventName";
  } else if (filterType === "tag") {
    return "tags";
  } else if (filterType === "issueType") {
    return "issueTypes";
  } else if (filterType === "propertyName") {
    return "propertyName";
  } else if (filterType === "category") {
    return "categoryIds";
  } else if (filterType === "source") {
    return "sourceIds";
  } else {
    return "releases";
  }
}

function useFilters(param) {
  var match = RescriptReactRouter.useUrl(undefined, undefined);
  var search = match.search;
  var searchDict = React.useMemo((function () {
          return QueryString.parse(search);
        }), [search]);
  var match$1 = useSavedViewState(undefined);
  var savedView = match$1.savedView;
  var savedViewStatus = match$1.savedViewStatus;
  var orderedBy = React.useMemo((function () {
          if (savedView === undefined) {
            return getOrderedByFromSearchDict(searchDict);
          }
          var order = savedView.order;
          if (getOrderedByFromSearchDict(searchDict) === undefined && Caml_obj.caml_equal(order, InspectorIssuesTypes.defaultOrder)) {
            return ;
          } else if (getOrderedByFromSearchDict(searchDict) === undefined) {
            return order;
          } else {
            return getOrderedByFromSearchDict(searchDict);
          }
        }), [
        Js_dict.get(searchDict, "orderedBy"),
        stringifySavedView(savedView)
      ]);
  var groupedFilters = React.useMemo((function () {
          if (search === "" && savedViewStatus === "success") {
            if (savedView !== undefined) {
              return InspectorIssuesFilterUtils.groupFilters(savedView.filters);
            } else {
              return [];
            }
          } else {
            return Belt_Array.keepMap(Belt_Array.keep((
                              search.startsWith("?") ? search.slice(1) : search
                            ).split("&"), (function (keyVal) {
                              return keyVal !== "";
                            })), (function (keyVal) {
                          var match = keyVal.split("=");
                          if (match.length !== 2) {
                            return ;
                          }
                          var key = match[0];
                          var value = match[1];
                          if (value.trim() === "") {
                            return ;
                          }
                          var match$1 = queryFilterKeyFromJs(key);
                          if (match$1 !== undefined) {
                            if (match$1 === "issueTypes") {
                              return {
                                      NAME: "issueTypes",
                                      VAL: Belt_Array.keepMap(Belt_Option.getWithDefault(getValuesForKey(searchDict, key), []), InspectorIssuesFilter.FilterIssueType.fromString)
                                    };
                            } else if (match$1 === "sourceIds") {
                              return {
                                      NAME: "sources",
                                      VAL: Belt_Option.getWithDefault(getValuesForKey(searchDict, key), [])
                                    };
                            } else if (match$1 === "categoryIds") {
                              return {
                                      NAME: "categories",
                                      VAL: Belt_Option.getWithDefault(getValuesForKey(searchDict, key), [])
                                    };
                            } else if (match$1 === "releases") {
                              return {
                                      NAME: "releases",
                                      VAL: Belt_Array.slice(Belt_Array.keepMap(Belt_Option.getWithDefault(getValuesForKey(searchDict, key), []), InspectorIssuesFilter.Filter.Release.fromString), 0, 1)
                                    };
                            } else if (match$1 === "eventName") {
                              return {
                                      NAME: "eventNames",
                                      VAL: Belt_Option.getWithDefault(getValuesForKey(searchDict, key), [])
                                    };
                            } else if (match$1 === "propertyName") {
                              return {
                                      NAME: "propertyNames",
                                      VAL: Belt_Option.getWithDefault(getValuesForKey(searchDict, key), [])
                                    };
                            } else {
                              return {
                                      NAME: "tags",
                                      VAL: Belt_Option.getWithDefault(getValuesForKey(searchDict, key), [])
                                    };
                            }
                          }
                          
                        }));
          }
        }), [
        search,
        stringifySavedView(savedView)
      ]);
  return React.useMemo((function () {
                return {
                        filters: Belt_Array.concatMany(Belt_Array.map(groupedFilters, InspectorIssuesFilter.GroupedFilter.toFilters)),
                        orderedBy: orderedBy,
                        groupedFilters: groupedFilters
                      };
              }), [
              groupedFilters,
              orderedBy,
              stringifySavedView(savedView)
            ]);
}

function useDraftState(param) {
  var match = RescriptReactRouter.useUrl(undefined, undefined);
  var search = match.search;
  var searchDict = React.useMemo((function () {
          return QueryString.parse(search);
        }), [search]);
  var isEmpty = React.useMemo((function () {
          if (Belt_Array.some(InspectorIssuesFilter.FilterType.availableFilterTypes, (function (filterType) {
                    return Js_dict.get(searchDict, filterTypeToQueryFilterKey(filterType)) !== undefined;
                  })) || Js_dict.get(searchDict, "orderedBy") !== undefined) {
            return true;
          } else {
            return Js_dict.get(searchDict, "noFilters") !== undefined;
          }
        }), [searchDict]);
  var isDraft = React.useMemo((function () {
          if (isEmpty) {
            return true;
          } else {
            return Js_dict.get(searchDict, "draft") !== undefined;
          }
        }), [searchDict]);
  return {
          isDraft: isDraft,
          isEmpty: isEmpty
        };
}

function useSourceIds(param) {
  var match = useFilters(undefined);
  var groupedFilters = match.groupedFilters;
  var getSourceIds = function (groupedFilters) {
    return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.keepMap(groupedFilters, (function (groupedFilter) {
                              if (typeof groupedFilter === "object" && groupedFilter.NAME === "sources") {
                                return groupedFilter.VAL;
                              }
                              
                            })))));
  };
  var match$1 = React.useState(function () {
        return getSourceIds(groupedFilters);
      });
  var setFilteredSourceIds = match$1[1];
  Hooks.useDidUpdate1((function (param) {
          Curry._1(setFilteredSourceIds, (function (param) {
                  return getSourceIds(groupedFilters);
                }));
          
        }), [getSourceIds(groupedFilters).join(",")]);
  return match$1[0];
}

function getQueryString(groupedFilters, orderedBy) {
  var filtersQueryParams = Belt_Array.keepMap(groupedFilters, (function (groupedFilter) {
          var variant = groupedFilter.NAME;
          if (variant === "propertyNames") {
            return "propertyName" + "=" + encodeURIComponent(groupedFilter.VAL.join(","));
          } else if (variant === "issueTypes") {
            return "issueTypes" + "=" + encodeURIComponent(Belt_Array.map(groupedFilter.VAL, (function (issueType) {
                                return InspectorIssuesFilter.FilterIssueType.toString(issueType);
                              })).join(","));
          } else if (variant === "releases") {
            return "releases" + "=" + encodeURIComponent(groupedFilter.VAL.join(","));
          } else if (variant === "categories") {
            return "categoryIds" + "=" + encodeURIComponent(groupedFilter.VAL.join(","));
          } else if (variant === "eventNames") {
            return "eventName" + "=" + encodeURIComponent(groupedFilter.VAL.join(","));
          } else if (variant === "sources") {
            return "sourceIds" + "=" + encodeURIComponent(groupedFilter.VAL.join(","));
          } else {
            return "tags" + "=" + encodeURIComponent(groupedFilter.VAL.join(","));
          }
        }));
  var orderedByQueryParams = Belt_Option.map(orderToQueryParam(orderedBy), (function (orderedByQueryValue) {
          return "orderedBy=" + encodeURIComponent(orderedByQueryValue);
        }));
  var queryParams = Belt_Array.concat(filtersQueryParams, Belt_Option.mapWithDefault(orderedByQueryParams, [], (function (o) {
                return [o];
              }))).join("&");
  if (queryParams === "") {
    return "";
  } else {
    return "?" + queryParams;
  }
}

function getUpdatedGroupFilters(oldGroupedFilters, newGroupedFilters) {
  var introducedGroups = Belt_Array.keepMap(newGroupedFilters, (function (newGroupedFilter) {
          var oldGroup = Belt_Array.getBy(oldGroupedFilters, (function (groupedFilter) {
                  return InspectorIssuesFilter.GroupedFilter.toFilterType(newGroupedFilter) === InspectorIssuesFilter.GroupedFilter.toFilterType(groupedFilter);
                }));
          if (Belt_Option.isSome(oldGroup)) {
            return ;
          } else {
            return newGroupedFilter;
          }
        }));
  return Belt_Array.concat(Belt_Array.keepMap(oldGroupedFilters, (function (groupedFilter) {
                    return Belt_Array.getBy(newGroupedFilters, (function (newGroupedFilter) {
                                  return InspectorIssuesFilter.GroupedFilter.toFilterType(newGroupedFilter) === InspectorIssuesFilter.GroupedFilter.toFilterType(groupedFilter);
                                }));
                  })), introducedGroups);
}

function resetFilters(param) {
  return RescriptReactRouter.push("/" + Belt_List.toArray(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).path).join("/"));
}

function useSetFilters(param) {
  var filterState = useFilters(undefined);
  var match = useSavedViewState(undefined);
  var savedView = match.savedView;
  var savedViewStatus = match.savedViewStatus;
  var setNewQueryString = function (oldGroupedFilters, newGroupedFilters, orderedBy) {
    var consolidatedGroupedFilters = getUpdatedGroupFilters(oldGroupedFilters, newGroupedFilters);
    var newPathString = Belt_List.toArray(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).path).join("/");
    var newQueryString = getQueryString(consolidatedGroupedFilters, orderedBy);
    return RescriptReactRouter.push("/" + newPathString + newQueryString + (
                newQueryString === "" && savedView !== undefined ? "?noFilters=true" : ""
              ));
  };
  return function (setCb) {
    var searchDict = QueryString.parse(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).search);
    var newGroupedFilters = InspectorIssuesFilterUtils.groupFilters(Curry._1(setCb, filterState.filters));
    if (savedView === undefined) {
      if (savedViewStatus === "loading") {
        return ;
      } else {
        return setNewQueryString(filterState.groupedFilters, newGroupedFilters, filterState.orderedBy);
      }
    }
    var filters = savedView.filters;
    if (!(InspectorIssuesFilter.GroupedFilter.cmpMany(InspectorIssuesFilterUtils.groupFilters(filters), newGroupedFilters) === 0 && RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).search !== "")) {
      if (InspectorIssuesFilter.GroupedFilter.cmpMany(InspectorIssuesFilterUtils.groupFilters(filters), newGroupedFilters) === 0) {
        return ;
      } else {
        return setNewQueryString(filterState.groupedFilters, newGroupedFilters, filterState.orderedBy);
      }
    }
    var orderedBy = getOrderedByFromSearchDict(searchDict);
    if (orderedBy !== undefined && !Caml_obj.caml_equal(orderedBy, savedView.order)) {
      return setNewQueryString(filterState.groupedFilters, newGroupedFilters, orderedBy);
    } else {
      return resetFilters(undefined);
    }
  };
}

function useOrderedBy(param) {
  return useFilters(undefined).orderedBy;
}

function getOrderedBy(param) {
  return getOrderedByFromSearchDict(QueryString.parse(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).search));
}

function useSetOrderedBy(param) {
  var match = useFilters(undefined);
  var groupedFilters = match.groupedFilters;
  var match$1 = useSavedViewState(undefined);
  var savedView = match$1.savedView;
  var savedViewStatus = match$1.savedViewStatus;
  return function (newOrderedBy) {
    var useFilterQueryString = savedView !== undefined ? InspectorIssuesFilter.GroupedFilter.cmpMany(InspectorIssuesFilterUtils.groupFilters(savedView.filters), groupedFilters) !== 0 : savedViewStatus !== "loading";
    if (savedView !== undefined) {
      if (Caml_obj.caml_equal(savedView.order, newOrderedBy)) {
        return RescriptReactRouter.push("/" + Belt_List.toArray(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).path).join("/") + getQueryString(useFilterQueryString ? groupedFilters : [], undefined));
      }
      
    } else if (savedViewStatus === "loading") {
      return ;
    }
    if (Caml_obj.caml_equal(newOrderedBy, InspectorIssuesTypes.defaultOrder)) {
      return RescriptReactRouter.push("/" + Belt_List.toArray(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).path).join("/") + getQueryString(useFilterQueryString ? groupedFilters : [], undefined));
    } else {
      return RescriptReactRouter.push("/" + Belt_List.toArray(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).path).join("/") + getQueryString(groupedFilters, newOrderedBy));
    }
  };
}

exports.removeSavedViewFromPathRec = removeSavedViewFromPathRec;
exports.removeSavedViewFromPath = removeSavedViewFromPath;
exports.SavedViewStore = SavedViewStore;
exports.getStringValues = getStringValues;
exports.getValueStringForKey = getValueStringForKey;
exports.getValuesForKey = getValuesForKey;
exports.getOrderedByFromSearchDict = getOrderedByFromSearchDict;
exports.orderToQueryParam = orderToQueryParam;
exports.stringifySavedView = stringifySavedView;
exports.queryFilterKeyToJs = queryFilterKeyToJs;
exports.queryFilterKeyFromJs = queryFilterKeyFromJs;
exports.filterTypeToQueryFilterKey = filterTypeToQueryFilterKey;
exports.useFilters = useFilters;
exports.useDraftState = useDraftState;
exports.useSourceIds = useSourceIds;
exports.getQueryString = getQueryString;
exports.getUpdatedGroupFilters = getUpdatedGroupFilters;
exports.resetFilters = resetFilters;
exports.useSetFilters = useSetFilters;
exports.useOrderedBy = useOrderedBy;
exports.getOrderedBy = getOrderedBy;
exports.useSetOrderedBy = useSetOrderedBy;
/* ZustandStore Not a pure module */
