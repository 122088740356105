// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Format = require("rescript/lib/js/format.js");
var AvoModel = require("../../app/src/avoModel.bs.js");
var Printing = require("./Printing.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("../../app/src/stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var PropertyUtils = require("../../app/src/PropertyUtils.bs.js");
var CodegenCommons = require("./CodegenCommons.bs.js");

function stringToQuotedEscapedString(str) {
  if (str === "") {
    return "";
  } else {
    return "\"" + str.replace(/"/g, "\"\"") + "\"";
  }
}

function arrayToCsvString(arr) {
  if (arr.length !== 0) {
    return JSON.stringify(arr.join(", "));
  } else {
    return "";
  }
}

function allPropertiesWithEvent(model, $$event, systemProperties) {
  var inputProperties = AvoModel.resolveProperties(model, $$event.properties);
  var match = CodegenCommons.classifyProperties(inputProperties);
  var match$1 = Belt_List.partition(match[0], (function (property) {
          return property.builtIn === "NotBuiltIn";
        }));
  return Belt_List.concatMany([
              match$1[0],
              match[1],
              match[2],
              match[3],
              systemProperties
            ]);
}

function printValueType(property) {
  if (CodegenCommons.hasMatchValidation(property)) {
    return "string";
  }
  var match = property.type_;
  switch (match) {
    case "any" :
        return "any";
    case "bool" :
        return "boolean";
    case "float" :
        return "float";
    case "int" :
        return "int";
    case "long" :
        return "long";
    case "object" :
        return "object";
    case "string" :
        return "string";
    default:
      return "any";
  }
}

function printPropertyType(property, model) {
  var groupTypeId = property.sendAs;
  if (typeof groupTypeId === "number") {
    switch (groupTypeId) {
      case /* SystemProperty */0 :
          return "System Property";
      case /* EventProperty */1 :
          return "Event Property";
      case /* UserProperty */2 :
          return "User Property";
      
    }
  } else {
    var groupName = Belt_Option.map(StateUtils.getGroupType(groupTypeId._0, model), (function (groupType) {
            return groupType.name;
          }));
    if (groupName !== undefined) {
      return "" + groupName + " Group Property";
    } else {
      return "Group Property";
    }
  }
}

var printDescription = stringToQuotedEscapedString;

var printTriggers = stringToQuotedEscapedString;

function printProperty(ppf, property, optional, model, eventId) {
  var matches = CodegenCommons.hasMatchValidation(property) ? arrayToCsvString(Belt_List.toArray(CodegenCommons.getMatches(property))) : "";
  var pinnedValues = stringToQuotedEscapedString(Belt_List.toArray(PropertyUtils.getPinnedValues(undefined, property.id, eventId, model)).join("; "));
  return Curry._8(Format.fprintf(ppf, /* Format */{
                  _0: {
                    TAG: /* Formatting_lit */17,
                    _0: {
                      TAG: /* Break */0,
                      _0: "@,",
                      _1: 0,
                      _2: 0
                    },
                    _1: {
                      TAG: /* String_literal */11,
                      _0: ",,,,,,",
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* Char_literal */12,
                          _0: /* ',' */44,
                          _1: {
                            TAG: /* String */2,
                            _0: /* No_padding */0,
                            _1: {
                              TAG: /* Char_literal */12,
                              _0: /* ',' */44,
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: {
                                  TAG: /* Char_literal */12,
                                  _0: /* ',' */44,
                                  _1: {
                                    TAG: /* String */2,
                                    _0: /* No_padding */0,
                                    _1: {
                                      TAG: /* Char_literal */12,
                                      _0: /* ',' */44,
                                      _1: {
                                        TAG: /* String */2,
                                        _0: /* No_padding */0,
                                        _1: {
                                          TAG: /* Char_literal */12,
                                          _0: /* ',' */44,
                                          _1: {
                                            TAG: /* String */2,
                                            _0: /* No_padding */0,
                                            _1: {
                                              TAG: /* Char_literal */12,
                                              _0: /* ',' */44,
                                              _1: {
                                                TAG: /* String */2,
                                                _0: /* No_padding */0,
                                                _1: {
                                                  TAG: /* String_literal */11,
                                                  _0: ",,",
                                                  _1: {
                                                    TAG: /* String */2,
                                                    _0: /* No_padding */0,
                                                    _1: /* End_of_format */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  },
                  _1: "@,,,,,,,%s,%s,%s,%s,%s,%s,%s,,%s"
                }), property.name, stringToQuotedEscapedString(property.description), printValueType(property), printPropertyType(property, model), optional ? "false" : "true", property.list ? "true" : "false", matches, pinnedValues);
}

function printEvent(ppf, model, systemProperties, $$event, schemaId, branchId, includedSources) {
  var includedSources$1 = Belt_List.keepU(includedSources, (function (includedSource) {
          return Belt_List.someU($$event.includeSources, (function (eventIncludedSource) {
                        return eventIncludedSource.id === includedSource.id;
                      }));
        }));
  var includedSourceIds = Belt_List.map(includedSources$1, (function (source) {
          return source.id;
        }));
  var kpis = arrayToCsvString(Belt_Array.map(StateUtils.getMetricsWithEvent($$event, model), (function (metric) {
              return metric.name;
            })));
  var categories = arrayToCsvString(Belt_Array.map(StateUtils.getGoalsWithEvent($$event, model), (function (goal) {
              return goal.name;
            })));
  var sources = arrayToCsvString(Belt_List.toArray(Belt_List.map(includedSources$1, (function (source) {
                  return source.name;
                }))));
  var allProperties = allPropertiesWithEvent(model, $$event, systemProperties);
  var triggers = CodegenCommons.eventTriggersForSources(model, $$event.id, Belt_List.mapU(includedSources$1, (function (validSource) {
              return validSource.id;
            })), true, schemaId, branchId);
  Curry._7(Format.fprintf(ppf, /* Format */{
            _0: {
              TAG: /* Formatting_lit */17,
              _0: {
                TAG: /* Break */0,
                _0: "@,",
                _1: 0,
                _2: 0
              },
              _1: {
                TAG: /* String */2,
                _0: /* No_padding */0,
                _1: {
                  TAG: /* Char_literal */12,
                  _0: /* ',' */44,
                  _1: {
                    TAG: /* String */2,
                    _0: /* No_padding */0,
                    _1: {
                      TAG: /* Char_literal */12,
                      _0: /* ',' */44,
                      _1: {
                        TAG: /* String */2,
                        _0: /* No_padding */0,
                        _1: {
                          TAG: /* Char_literal */12,
                          _0: /* ',' */44,
                          _1: {
                            TAG: /* String */2,
                            _0: /* No_padding */0,
                            _1: {
                              TAG: /* Char_literal */12,
                              _0: /* ',' */44,
                              _1: {
                                TAG: /* String */2,
                                _0: /* No_padding */0,
                                _1: {
                                  TAG: /* Char_literal */12,
                                  _0: /* ',' */44,
                                  _1: {
                                    TAG: /* String */2,
                                    _0: /* No_padding */0,
                                    _1: {
                                      TAG: /* String_literal */11,
                                      _0: ",,,,,,,,",
                                      _1: {
                                        TAG: /* String */2,
                                        _0: /* No_padding */0,
                                        _1: {
                                          TAG: /* Char_literal */12,
                                          _0: /* ',' */44,
                                          _1: /* End_of_format */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            },
            _1: "@,%s,%s,%s,%s,%s,%s,,,,,,,,%s,"
          }), kpis, categories, $$event.name, stringToQuotedEscapedString($$event.description), stringToQuotedEscapedString(triggers.join(". ")), arrayToCsvString(Belt_List.toArray($$event.tags)), sources);
  return Belt_List.forEachU(allProperties, (function (property) {
                var optional = StateUtils.isPropertyOptionalOnEventsAndSources({
                      hd: $$event.id,
                      tl: /* [] */0
                    }, includedSourceIds, property, model);
                return printProperty(ppf, property, optional, model, $$event.id);
              }));
}

function generateCsv(ppf, model, systemProperties, includedSources, schemaId, branchId) {
  Format.fprintf(ppf, /* Format */{
        _0: {
          TAG: /* String_literal */11,
          _0: "KPIs,Event Category,Event Name,Event Description,Event Triggers,Event Tags,Property Name,Property Description,Property Value Type,Property Type,Is Property Required?,Is Property Array?,Property Enumeration Options,Sources,Pinned Property Value",
          _1: /* End_of_format */0
        },
        _1: "KPIs,Event Category,Event Name,Event Description,Event Triggers,Event Tags,Property Name,Property Description,Property Value Type,Property Type,Is Property Required?,Is Property Array?,Property Enumeration Options,Sources,Pinned Property Value"
      });
  return Belt_List.forEachU(model.events, (function ($$event) {
                return printEvent(ppf, model, systemProperties, $$event, schemaId, branchId, includedSources);
              }));
}

function generateCsvSchema(sources, schemaId, branchId, model) {
  var model$1 = AvoModel.enrichModel(AvoModel.filterUnusedPropertyBundles(model), sources);
  var sourceIds = Belt_List.map(sources, (function (source) {
          return source.id;
        }));
  var model$2 = AvoModel.enrichPropertyBundlesForCodegen(model$1, undefined, sourceIds);
  var allSystemProperties = AvoModel.getSystemProperties(model$2.properties);
  var match = Belt_List.partition(allSystemProperties, (function (property) {
          return property.auto;
        }));
  var match$1 = Printing.str_formatter(undefined);
  var ppf = match$1[1];
  Format.fprintf(ppf, /* Format */{
        _0: {
          TAG: /* Formatting_gen */18,
          _0: {
            TAG: /* Open_box */1,
            _0: /* Format */{
              _0: {
                TAG: /* String_literal */11,
                _0: "<v>",
                _1: /* End_of_format */0
              },
              _1: "<v>"
            }
          },
          _1: /* End_of_format */0
        },
        _1: "@[<v>"
      });
  generateCsv(ppf, model$2, match[1], sources, schemaId, branchId);
  Format.fprintf(ppf, /* Format */{
        _0: {
          TAG: /* Formatting_lit */17,
          _0: /* Close_box */0,
          _1: /* End_of_format */0
        },
        _1: "@]"
      });
  return Printing.flush_str_formatter(match$1[0], ppf);
}

exports.stringToQuotedEscapedString = stringToQuotedEscapedString;
exports.arrayToCsvString = arrayToCsvString;
exports.allPropertiesWithEvent = allPropertiesWithEvent;
exports.printValueType = printValueType;
exports.printPropertyType = printPropertyType;
exports.printDescription = printDescription;
exports.printTriggers = printTriggers;
exports.printProperty = printProperty;
exports.printEvent = printEvent;
exports.generateCsv = generateCsv;
exports.generateCsvSchema = generateCsvSchema;
/* Format Not a pure module */
