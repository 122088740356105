// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

var _map = {"CanCreateAdditionalWorkspaces":"CanCreateAdditionalWorkspaces"};

function userFlagToJs(param) {
  return param;
}

function userFlagFromJs(param) {
  return _map[param];
}

function flagEnabledForUser(user, flag) {
  var maybeFlags = user.userFlags;
  if (maybeFlags == null) {
    return false;
  } else {
    return Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field(flag, Json_decode.bool, param);
                    }), maybeFlags), false);
  }
}

function flagEnabledForUserId(userId, flag) {
  var userState = FirebaseFetcherHooks.useUser(userId);
  if (typeof userState === "object") {
    return flagEnabledForUser(userState.VAL, flag);
  } else {
    return false;
  }
}

exports.userFlagToJs = userFlagToJs;
exports.userFlagFromJs = userFlagFromJs;
exports.flagEnabledForUser = flagEnabledForUser;
exports.flagEnabledForUserId = flagEnabledForUserId;
/* FirebaseFetcherHooks Not a pure module */
