// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Shortid = require("shortid");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var ContextBox = require("./ContextBox.bs.js");
var IconFunnel = require("./IconFunnel.bs.js");
var MetricItem = require("./MetricItem.bs.js");
var TextButton = require("./TextButton.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var QuillDelta = require("quill-delta");
var CommentInput = require("./CommentInput.bs.js");
var DrawerHeader = require("./detailviews/DrawerHeader.bs.js");
var IconCategory = require("./IconCategory.bs.js");
var OpsWithState = require("./OpsWithState.bs.js");
var CategoryInput = require("./CategoryInput.bs.js");
var EventAutofill = require("./EventAutofill.bs.js");
var FirebaseUtils = require("./firebaseUtils.bs.js");
var IconArrowDown = require("./IconArrowDown.bs.js");
var IconRetention = require("./IconRetention.bs.js");
var ViewerContext = require("./ViewerContext.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var IconProportion = require("./IconProportion.bs.js");
var IconDescription = require("./IconDescription.bs.js");
var QuillWithToolbar = require("./QuillWithToolbar.bs.js");
var ObjectActivityLog = require("./ObjectActivityLog.bs.js");
var OpsWithDeltaState = require("./OpsWithDeltaState.bs.js");
var ReactBeautifulDnd = require("./ReactBeautifulDnd.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var BeltArrayExtensions = require("./BeltArrayExtensions.bs.js");
var ReactBeautifulDnd$1 = require("react-beautiful-dnd");
var IconEventSegmentation = require("./IconEventSegmentation.bs.js");

var funnelTypes = {
  hd: "Funnel",
  tl: {
    hd: "EventSegmentation",
    tl: {
      hd: "Retention",
      tl: {
        hd: "Proportion",
        tl: /* [] */0
      }
    }
  }
};

function Metric$FunnelTypeIcon(Props) {
  var type_ = Props.type_;
  if (type_ === "EventSegmentation") {
    return React.createElement(IconEventSegmentation.make, {
                size: 12,
                color: Styles.Color.grey30
              });
  } else if (type_ === "Retention") {
    return React.createElement(IconRetention.make, {
                size: 12,
                color: Styles.Color.grey30
              });
  } else if (type_ === "Funnel") {
    return React.createElement(IconFunnel.make, {
                size: 12,
                color: Styles.Color.grey30
              });
  } else {
    return React.createElement(IconProportion.make, {
                size: 12,
                color: Styles.Color.grey30
              });
  }
}

var FunnelTypeIcon = {
  make: Metric$FunnelTypeIcon
};

function Metric$DraggableMetricItems(Props) {
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var events = Props.events;
  var goalId = Props.goalId;
  var metric = Props.metric;
  var model = Props.model;
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useState(function () {
        return Belt_List.keepMapU(metric.items, (function (item) {
                      var match = item.VAL;
                      var eventId = match.eventId;
                      var id = match.id;
                      return Belt_Option.mapU(Belt_List.getBy(events, (function ($$event) {
                                        return $$event.id === eventId;
                                      })), (function ($$event) {
                                    return [
                                            item,
                                            id,
                                            $$event
                                          ];
                                  }));
                    }));
      });
  var setItems = match[1];
  var items = match[0];
  React.useEffect((function () {
          var newItems = Belt_List.keepMapU(metric.items, (function (item) {
                  var match = item.VAL;
                  var eventId = match.eventId;
                  var id = match.id;
                  return Belt_Option.mapU(Belt_List.getBy(events, (function ($$event) {
                                    return $$event.id === eventId;
                                  })), (function ($$event) {
                                return [
                                        item,
                                        id,
                                        $$event
                                      ];
                              }));
                }));
          if (Caml_obj.caml_notequal(items, newItems)) {
            Curry._1(setItems, (function (param) {
                    return newItems;
                  }));
          }
          
        }), [metric.items]);
  var onDragEnd = function (result) {
    return Belt_Option.forEach(Caml_option.nullable_to_opt(result.destination), (function (destination) {
                  var predecessor = Belt_Option.map(Belt_List.get(items, destination.index < result.source.index ? destination.index - 1 | 0 : destination.index), (function (param) {
                          return param[1];
                        }));
                  var itemMoved = Belt_Option.map(Belt_List.get(items, result.source.index), (function (param) {
                          return param[1];
                        }));
                  var mayProceed = itemMoved !== undefined ? (
                      predecessor !== undefined ? itemMoved !== predecessor : true
                    ) : false;
                  if (mayProceed && itemMoved !== undefined) {
                    Curry._1(setItems, (function (items) {
                            return Belt_List.fromArray(BeltArrayExtensions.reinsertIndex(Belt_List.toArray(items), result.source.index, destination.index));
                          }));
                    setTimeout((function (param) {
                            var tmp = {
                              metricId: metric.id,
                              metricQuery: metric.id
                            };
                            if (goalId !== undefined) {
                              tmp.goalId = Caml_option.valFromOption(goalId);
                            }
                            return Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [[
                                            {
                                              NAME: "ReorderEventsInMetric",
                                              VAL: [
                                                metric.id,
                                                itemMoved,
                                                predecessor
                                              ]
                                            },
                                            tmp
                                          ]]
                                      ]);
                          }), 0);
                    return ;
                  }
                  
                }));
  };
  return React.createElement(ReactBeautifulDnd$1.DragDropContext, {
              onDragEnd: onDragEnd,
              children: React.createElement(ReactBeautifulDnd$1.Droppable, {
                    droppableId: "metric",
                    children: (function (provided, _snapshot) {
                        return React.createElement(ReactBeautifulDnd.DraggableWrapper.make, {
                                    droppableProps: provided.droppableProps,
                                    children: React.createElement(React.Fragment, undefined, Belt_List.toArray(Belt_List.mapWithIndexU(items, (function (index, param) {
                                                    var $$event = param[2];
                                                    var metricItemId = param[1];
                                                    var metricItem = param[0];
                                                    return React.createElement(ReactBeautifulDnd$1.Draggable, {
                                                                key: metricItemId,
                                                                draggableId: metricItemId,
                                                                index: index,
                                                                children: (function (provided, _snapshot) {
                                                                    var tmp = {
                                                                      metric: metric,
                                                                      metricItem: metricItem,
                                                                      event: $$event,
                                                                      index: index,
                                                                      sendActions: sendActions,
                                                                      model: model,
                                                                      currentFilters: currentFilters,
                                                                      filters: filters
                                                                    };
                                                                    if (goalId !== undefined) {
                                                                      tmp.goalId = Caml_option.valFromOption(goalId);
                                                                    }
                                                                    return React.createElement("div", undefined, React.createElement(ReactBeautifulDnd.ItemWrapper.make, {
                                                                                    dragHandleProps: provided.dragHandleProps,
                                                                                    draggableProps: provided.draggableProps,
                                                                                    children: React.createElement(MetricItem.make, tmp),
                                                                                    ref: provided.innerRef
                                                                                  }), provided.placeholder);
                                                                  })
                                                              });
                                                  }))), provided.placeholder),
                                    ref: provided.innerRef
                                  });
                      })
                  })
            });
}

var DraggableMetricItems = {
  make: Metric$DraggableMetricItems
};

function Metric(Props) {
  var currentBranch = Props.currentBranch;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var goalId = Props.goalId;
  var goToCommentId = Props.goToCommentId;
  var metric = Props.metric;
  var model = Props.model;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var schema = Props.schema;
  var match = ViewerContext.use(undefined);
  var userId = match.id;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var tmp = {
    metricId: metric.id,
    metricQuery: metric.id
  };
  if (goalId !== undefined) {
    tmp.goalId = Caml_option.valFromOption(goalId);
  }
  var context = tmp;
  var renderEventInput = function (param) {
    return React.createElement(EventAutofill.make, {
                events: model.events,
                onSelectSuccess: (function (eventId, eventName, branch) {
                    return AnalyticsRe.metricUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), metric.id, metric.name, eventId, eventName, "EventAdded", metric.type_, NamedBranch.getId(branch), schemaGroup.schemaId);
                  }),
                model: model,
                renderButton: (function (onClick, buttonRef) {
                    var index = Belt_List.length(metric.items);
                    var match = metric.type_;
                    return React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.width(Css.pct(100)),
                                          tl: {
                                            hd: Css.marginTop(Css.px(2)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    })
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.justifyContent("center"),
                                              tl: {
                                                hd: Css.flexShrink(0.0),
                                                tl: {
                                                  hd: Css.width(Css.px(24)),
                                                  tl: {
                                                    hd: Css.height(Css.px(24)),
                                                    tl: {
                                                      hd: Css.borderRadius(Css.pct(100)),
                                                      tl: {
                                                        hd: Css.marginRight(Css.px(10)),
                                                        tl: {
                                                          hd: Css.fontSize(Styles.FontSize.small),
                                                          tl: {
                                                            hd: Css.fontWeight(Styles.FontWeight.bold),
                                                            tl: {
                                                              hd: Css.color(Styles.Color.white),
                                                              tl: {
                                                                hd: Css.backgroundColor(Styles.Color.magentaBg),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, match === "Funnel" ? String(index + 1 | 0) : React.createElement("span", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.textTransform("uppercase"),
                                                tl: /* [] */0
                                              })
                                        }, String.fromCharCode(index + 97 | 0))), React.createElement(Spacer.make, {
                                    height: 10
                                  }), React.createElement(TextButton.make, {
                                    onClick: onClick,
                                    style: "Blue",
                                    size: "Small",
                                    children: "+ Add Event",
                                    ref: buttonRef
                                  }));
                  }),
                sendActions: sendActions,
                currentFilters: currentFilters,
                eventOrigin: "MetricDetails",
                selectActions: (function (eventId) {
                    var tmp = {
                      eventId: eventId,
                      eventQuery: eventId,
                      metricId: metric.id,
                      metricQuery: metric.id
                    };
                    if (goalId !== undefined) {
                      tmp.goalId = Caml_option.valFromOption(goalId);
                    }
                    return [[
                              {
                                NAME: "AddEventToMetric",
                                VAL: [
                                  metric.id,
                                  Shortid(),
                                  eventId
                                ]
                              },
                              tmp
                            ]];
                  })
              });
  };
  var match$1 = Router.getStaticRoute(undefined).branch;
  var demoId = match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "branch" && match$1.VAL.startsWith("demo-") ? "new-workspace-demo-metric" : undefined;
  var tmp$1 = {
    className: Curry._1(Css.style, {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.height(Css.pct(100.0)),
              tl: {
                hd: Css.overflowY("auto"),
                tl: /* [] */0
              }
            }
          }
        })
  };
  if (demoId !== undefined) {
    tmp$1.id = Caml_option.valFromOption(demoId);
  }
  var template = schema.onboardingTemplate;
  var goals = model.goals;
  var match$2 = metric.type_;
  var match$3 = metric.type_;
  var tmp$2;
  if (match$3 === "Funnel" || match$3 === "EventSegmentation") {
    var tmp$3 = {
      currentFilters: currentFilters,
      filters: filters,
      events: model.events,
      metric: metric,
      model: model
    };
    if (goalId !== undefined) {
      tmp$3.goalId = Caml_option.valFromOption(goalId);
    }
    tmp$2 = React.createElement(Metric$DraggableMetricItems, tmp$3);
  } else if (match$3 === "Retention") {
    var a = Belt_Option.flatMap(Belt_List.get(metric.items, 0), (function (x) {
            var eventId = x.VAL.eventId;
            return Belt_Option.map(Belt_List.getBy(model.events, (function ($$event) {
                              return $$event.id === eventId;
                            })), (function ($$event) {
                          return [
                                  x,
                                  $$event
                                ];
                        }));
          }));
    var b = Belt_Option.flatMap(Belt_List.get(metric.items, 1), (function (x) {
            var eventId = x.VAL.eventId;
            return Belt_Option.map(Belt_List.getBy(model.events, (function ($$event) {
                              return $$event.id === eventId;
                            })), (function ($$event) {
                          return [
                                  x,
                                  $$event
                                ];
                        }));
          }));
    var tmp$4;
    if (a !== undefined) {
      var tmp$5 = {
        metric: metric,
        metricItem: a[0],
        event: a[1],
        index: 0,
        sendActions: sendActions,
        model: model,
        currentFilters: currentFilters,
        filters: filters,
        draggable: false
      };
      if (goalId !== undefined) {
        tmp$5.goalId = Caml_option.valFromOption(goalId);
      }
      tmp$4 = React.createElement(MetricItem.make, tmp$5);
    } else {
      tmp$4 = renderEventInput(undefined);
    }
    var tmp$6;
    if (a !== undefined) {
      if (b !== undefined) {
        var tmp$7 = {
          metric: metric,
          metricItem: b[0],
          event: b[1],
          index: 1,
          sendActions: sendActions,
          model: model,
          currentFilters: currentFilters,
          filters: filters,
          draggable: false
        };
        if (goalId !== undefined) {
          tmp$7.goalId = Caml_option.valFromOption(goalId);
        }
        tmp$6 = React.createElement(MetricItem.make, tmp$7);
      } else {
        tmp$6 = renderEventInput(undefined);
      }
    } else {
      tmp$6 = null;
    }
    tmp$2 = React.createElement("div", undefined, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontWeight(Styles.FontWeight.bold),
                        tl: {
                          hd: Css.marginRight(Css.px(10)),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.width(Css.px(50)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, "Born"), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexGrow(1.0),
                        tl: /* [] */0
                      })
                }, tmp$4)), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontWeight(Styles.FontWeight.bold),
                        tl: {
                          hd: Css.marginRight(Css.px(10)),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.width(Css.px(50)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, "Return"), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexGrow(1.0),
                        tl: /* [] */0
                      })
                }, tmp$6)));
  } else {
    var a$1 = Belt_Option.flatMap(Belt_List.get(metric.items, 0), (function (x) {
            var eventId = x.VAL.eventId;
            return Belt_Option.map(Belt_List.getBy(model.events, (function ($$event) {
                              return $$event.id === eventId;
                            })), (function ($$event) {
                          return [
                                  x,
                                  $$event
                                ];
                        }));
          }));
    var b$1 = Belt_Option.flatMap(Belt_List.get(metric.items, 1), (function (x) {
            var eventId = x.VAL.eventId;
            return Belt_Option.map(Belt_List.getBy(model.events, (function ($$event) {
                              return $$event.id === eventId;
                            })), (function ($$event) {
                          return [
                                  x,
                                  $$event
                                ];
                        }));
          }));
    var tmp$8;
    if (a$1 !== undefined) {
      var tmp$9 = {
        metric: metric,
        metricItem: a$1[0],
        event: a$1[1],
        index: 0,
        sendActions: sendActions,
        model: model,
        currentFilters: currentFilters,
        filters: filters,
        draggable: false
      };
      if (goalId !== undefined) {
        tmp$9.goalId = Caml_option.valFromOption(goalId);
      }
      tmp$8 = React.createElement(MetricItem.make, tmp$9);
    } else {
      tmp$8 = renderEventInput(undefined);
    }
    var tmp$10;
    if (a$1 !== undefined) {
      if (b$1 !== undefined) {
        var tmp$11 = {
          metric: metric,
          metricItem: b$1[0],
          event: b$1[1],
          index: 1,
          sendActions: sendActions,
          model: model,
          currentFilters: currentFilters,
          filters: filters,
          draggable: false
        };
        if (goalId !== undefined) {
          tmp$11.goalId = Caml_option.valFromOption(goalId);
        }
        tmp$10 = React.createElement(MetricItem.make, tmp$11);
      } else {
        tmp$10 = renderEventInput(undefined);
      }
    } else {
      tmp$10 = null;
    }
    tmp$2 = React.createElement("div", undefined, tmp$8, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.color(Styles.Color.grey50),
                        tl: {
                          hd: Css.padding2(Css.px(5), Css.px(30)),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.bold),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexGrow(1.0),
                        tl: {
                          hd: Css.height(Css.px(2)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.grey20),
                            tl: /* [] */0
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(0), Css.px(50)),
                        tl: /* [] */0
                      })
                }, "DIVIDED BY"), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexGrow(1.0),
                        tl: {
                          hd: Css.height(Css.px(2)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.grey20),
                            tl: /* [] */0
                          }
                        }
                      })
                })), tmp$10);
  }
  var match$4 = metric.type_;
  var tmp$12 = {
    object_: {
      NAME: "Metric",
      VAL: [
        metric.id,
        metric.name
      ]
    },
    objectId: metric.id,
    model: model,
    schema: schema,
    events: model.events,
    currentBranch: currentBranch,
    openBranches: openBranches
  };
  if (goToCommentId !== undefined) {
    tmp$12.goToCommentId = Caml_option.valFromOption(goToCommentId);
  }
  return React.createElement("div", tmp$1, React.createElement(DrawerHeader.make, {
                  kind: "Metric",
                  options: [{
                      NAME: "WarningOption",
                      VAL: {
                        label: "Archive Metric",
                        onClick: (function (param) {
                            if (window.confirm("Are you sure you would like to archive the metric \"" + metric.name + "\"")) {
                              var match = Router.getStaticRoute(undefined);
                              var newDrawerItems = Belt_List.keep(match.drawerItems, (function (item) {
                                      if (typeof item === "object" && item.NAME === "metric") {
                                        return item.VAL[0] !== metric.id;
                                      } else {
                                        return true;
                                      }
                                    }));
                              Router.Schema.replaceDrawerItems(newDrawerItems);
                              Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (branch) {
                                        return AnalyticsRe.metricArchived(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), metric.id, metric.name, NamedBranch.getId(branch), schemaGroup.schemaId);
                                      }),
                                    undefined,
                                    [[
                                        {
                                          NAME: "Archive",
                                          VAL: {
                                            NAME: "Metric",
                                            VAL: metric.id
                                          }
                                        },
                                        context
                                      ]]
                                  ]);
                              return Curry._1(addToast, {
                                          message: "The metric has been archived"
                                        });
                            }
                            
                          })
                      }
                    }],
                  children: React.createElement(OpsWithState.make, {
                        value: metric.name,
                        onFlush: (function (name) {
                            return Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [[
                                            {
                                              NAME: "UpdateMetricName",
                                              VAL: [
                                                metric.id,
                                                name
                                              ]
                                            },
                                            context
                                          ]]
                                      ]);
                          }),
                        onChangeCompleted: (function (param, param$1) {
                            AnalyticsRe.metricUpdated(schemaGroup, metric.id, metric.name, "N/A", "N/A", "NameUpdated", metric.type_, schemaGroup.branchId, schemaGroup.schemaId);
                            
                          }),
                        children: (function (value, onChange, onBlur, onFocus) {
                            return React.createElement("input", {
                                        className: Curry._1(Css.merge, {
                                              hd: Curry._1(Css.style, Styles.input),
                                              tl: {
                                                hd: Curry._1(Css.style, {
                                                      hd: Css.color(Styles.Color.grey90),
                                                      tl: {
                                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                                        tl: {
                                                          hd: Css.fontSize(Styles.FontSize.medium),
                                                          tl: {
                                                            hd: Css.border(Css.px(1), "solid", "transparent"),
                                                            tl: {
                                                              hd: Css.borderRadius(Styles.Border.radius),
                                                              tl: {
                                                                hd: Css.backgroundColor("transparent"),
                                                                tl: {
                                                                  hd: Css.paddingLeft(Css.px(4)),
                                                                  tl: {
                                                                    hd: Css.marginLeft(Css.px(-4)),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }),
                                        placeholder: "Untitled Metric...",
                                        value: value,
                                        onFocus: (function (domEvent) {
                                            return Curry._1(onFocus, domEvent.target.value);
                                          }),
                                        onBlur: (function (domEvent) {
                                            return Curry._1(onBlur, domEvent.target.value);
                                          }),
                                        onChange: (function (domEvent) {
                                            return Curry._1(onChange, domEvent.target.value);
                                          })
                                      });
                          })
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.position("relative"),
                              tl: {
                                hd: Css.overflowY("auto"),
                                tl: {
                                  hd: Css.zIndex(0),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", undefined, !(template == null) && Belt_Array.some(template.metrics, (function (id) {
                            return id === metric.id;
                          })) ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(10), Css.px(35)),
                                  tl: {
                                    hd: Css.margin2(Css.px(10), Css.px(-50)),
                                    tl: {
                                      hd: Css.backgroundColor(Styles.Color.grey20),
                                      tl: {
                                        hd: Css.color(Styles.Color.grey70),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: {
                                            hd: Css.fontSize(Styles.FontSize.regular),
                                            tl: {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: {
                                                  hd: Css.justifyContent("spaceBetween"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                          }, "This is an example metric", React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: /* [] */0
                                    })
                              }, React.createElement("button", {
                                    className: Curry._1(Css.merge, {
                                          hd: Curry._1(Css.style, Styles.button),
                                          tl: {
                                            hd: Curry._1(Css.style, {
                                                  hd: Css.fontSize(Styles.FontSize.small),
                                                  tl: {
                                                    hd: Css.fontWeight(Styles.FontWeight.bold),
                                                    tl: {
                                                      hd: Css.color(Styles.Color.grey70),
                                                      tl: {
                                                        hd: Css.margin2(Css.px(0), Css.px(2)),
                                                        tl: {
                                                          hd: Css.hover({
                                                                hd: Css.color(Styles.Color.mintGreenSecondary),
                                                                tl: /* [] */0
                                                              }),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }),
                                    onClick: (function (param) {
                                        var schemaRef = Firebase.app(undefined).firestore().collection("schemas").doc(schema.id);
                                        schemaRef.set({
                                              onboardingTemplate: {
                                                events: template.events,
                                                properties: template.properties,
                                                propertyGroups: template.propertyGroups,
                                                goals: template.goals,
                                                metrics: Belt_Array.keep(template.metrics, (function (id) {
                                                        return id !== metric.id;
                                                      }))
                                              }
                                            }, {"merge": true});
                                        return AnalyticsRe.exampleInteraction(schemaGroup, "KeepCurrentExample", "Metric", schemaGroup.branchId, schemaGroup.schemaId);
                                      })
                                  }, "Keep Metric"), React.createElement("button", {
                                    className: Curry._1(Css.merge, {
                                          hd: Curry._1(Css.style, Styles.button),
                                          tl: {
                                            hd: Curry._1(Css.style, {
                                                  hd: Css.fontSize(Styles.FontSize.small),
                                                  tl: {
                                                    hd: Css.fontWeight(Styles.FontWeight.bold),
                                                    tl: {
                                                      hd: Css.color(Styles.Color.grey70),
                                                      tl: {
                                                        hd: Css.margin2(Css.px(0), Css.px(2)),
                                                        tl: {
                                                          hd: Css.hover({
                                                                hd: Css.color(Styles.Color.grapeErrorSecondary),
                                                                tl: /* [] */0
                                                              }),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }),
                                    onClick: (function (param) {
                                        var match = Router.getStaticRoute(undefined);
                                        var newDrawerItems = Belt_List.keep(match.drawerItems, (function (item) {
                                                if (typeof item === "object" && item.NAME === "metric") {
                                                  return item.VAL[0] !== metric.id;
                                                } else {
                                                  return true;
                                                }
                                              }));
                                        Router.Schema.replaceDrawerItems(newDrawerItems);
                                        return Curry.app(sendActions, [
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    (function (branch) {
                                                        AnalyticsRe.exampleInteraction(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "RemoveCurrentExample", "Metric", NamedBranch.getId(branch), schemaGroup.schemaId);
                                                        return Curry._1(addToast, {
                                                                    message: "The metric has been archived"
                                                                  });
                                                      }),
                                                    undefined,
                                                    [[
                                                        {
                                                          NAME: "Archive",
                                                          VAL: {
                                                            NAME: "Metric",
                                                            VAL: metric.id
                                                          }
                                                        },
                                                        {
                                                          metricId: metric.id,
                                                          metricQuery: metric.id
                                                        }
                                                      ]]
                                                  ]);
                                      })
                                  }, "Remove Metric"), React.createElement("button", {
                                    className: Curry._1(Css.merge, {
                                          hd: Curry._1(Css.style, Styles.button),
                                          tl: {
                                            hd: Curry._1(Css.style, {
                                                  hd: Css.fontSize(Styles.FontSize.small),
                                                  tl: {
                                                    hd: Css.fontWeight(Styles.FontWeight.bold),
                                                    tl: {
                                                      hd: Css.backgroundColor(Styles.Color.grapeError),
                                                      tl: {
                                                        hd: Css.color(Styles.Color.white),
                                                        tl: {
                                                          hd: Css.margin2(Css.px(0), Css.px(5)),
                                                          tl: {
                                                            hd: Css.padding2(Css.px(5), Css.px(10)),
                                                            tl: {
                                                              hd: Css.borderRadius(Styles.Border.radius),
                                                              tl: {
                                                                hd: Css.hover({
                                                                      hd: Css.backgroundColor(Styles.Color.grapeErrorSecondary),
                                                                      tl: /* [] */0
                                                                    }),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }),
                                    onClick: (function (param) {
                                        FirebaseUtils.archiveTemplate(template, (function (eta) {
                                                return Curry.app(sendActions, [
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            eta
                                                          ]);
                                              }));
                                        return AnalyticsRe.exampleInteraction(schemaGroup, "RemoveAllExamples", "Metric", schemaGroup.branchId, schemaGroup.schemaId);
                                      })
                                  }, "Remove All Examples"))) : null, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("flexStart"),
                                  tl: {
                                    hd: Css.padding4(Css.px(15), Css.px(30), Css.px(0), Css.px(18)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.marginTop(Css.px(13)),
                                    tl: /* [] */0
                                  })
                            }, React.createElement(IconDescription.make, {})), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.border(Css.px(1), "solid", "transparent"),
                                    tl: {
                                      hd: Css.hover({
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.borderRadius(Styles.Border.radius),
                                        tl: {
                                          hd: Css.marginLeft(Css.px(5)),
                                          tl: {
                                            hd: Css.width(Css.pct(100)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement(OpsWithDeltaState.make, {
                                  value: new QuillDelta(JSON.parse(metric.description)),
                                  onFlush: (function (description) {
                                      return Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  [[
                                                      {
                                                        NAME: "UpdateMetricDescription",
                                                        VAL: [
                                                          metric.id,
                                                          JSON.stringify(description)
                                                        ]
                                                      },
                                                      context
                                                    ]]
                                                ]);
                                    }),
                                  onChangeCompleted: (function (param, param$1) {
                                      AnalyticsRe.metricUpdated(schemaGroup, metric.id, metric.name, "N/A", "N/A", "DescriptionUpdated", metric.type_, schemaGroup.branchId, schemaGroup.schemaId);
                                      
                                    }),
                                  children: (function (value, onChange, onBlur, param) {
                                      return React.createElement(QuillWithToolbar.make, {
                                                  value: value,
                                                  onChange: onChange,
                                                  id: "metric-" + metric.id,
                                                  placeholder: "Metric description...",
                                                  onBlur: (function (param) {
                                                      return Curry._1(onBlur, value);
                                                    })
                                                });
                                    })
                                }))), goals ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("flexStart"),
                                    tl: {
                                      hd: Css.padding4(Css.px(5), Css.px(30), Css.px(0), Css.px(18)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.marginTop(Css.px(5)),
                                      tl: /* [] */0
                                    })
                              }, React.createElement(IconCategory.make, {})), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.width(Css.pct(100)),
                                      tl: /* [] */0
                                    })
                              }, React.createElement(CategoryInput.make, {
                                    categories: goals,
                                    currentCategories: Belt_List.toArray(Belt_List.keep(goals, (function (goal) {
                                                return Belt_List.some(goal.metrics, (function (id) {
                                                              return id === metric.id;
                                                            }));
                                              }))),
                                    role: role,
                                    onRemove: (function (category) {
                                        return Curry.app(sendActions, [
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [[
                                                        {
                                                          NAME: "RemoveMetricFromGoal",
                                                          VAL: [
                                                            category.id,
                                                            metric.id
                                                          ]
                                                        },
                                                        {
                                                          goalId: category.id,
                                                          goalQuery: category.id,
                                                          metricId: metric.id,
                                                          metricQuery: metric.id
                                                        }
                                                      ]]
                                                  ]);
                                      }),
                                    onAdd: (function (category) {
                                        return Curry.app(sendActions, [
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [[
                                                        {
                                                          NAME: "AddMetricToGoal",
                                                          VAL: [
                                                            category.id,
                                                            metric.id
                                                          ]
                                                        },
                                                        {
                                                          goalId: category.id,
                                                          goalQuery: category.id,
                                                          metricId: metric.id,
                                                          metricQuery: metric.id
                                                        }
                                                      ]]
                                                  ]);
                                      }),
                                    sendActions: sendActions,
                                    onCreateActions: (function (goalId) {
                                        return [[
                                                  {
                                                    NAME: "AddMetricToGoal",
                                                    VAL: [
                                                      goalId,
                                                      metric.id
                                                    ]
                                                  },
                                                  {
                                                    goalId: goalId,
                                                    goalQuery: goalId,
                                                    metricId: metric.id,
                                                    metricQuery: metric.id
                                                  }
                                                ]];
                                      }),
                                    onAddAnalytics: (function (categoryId, categoryName) {
                                        return AnalyticsRe.categoryUpdated(schemaGroup, categoryName, categoryId, "MetricAdded", metric.name, metric.id, "N/A", "N/A", schemaGroup.branchId, schemaGroup.schemaId);
                                      }),
                                    onRemoveAnalytics: (function (category) {
                                        return AnalyticsRe.categoryUpdated(schemaGroup, category.name, category.id, "MetricRemoved", metric.name, metric.id, "N/A", "N/A", schemaGroup.branchId, schemaGroup.schemaId);
                                      })
                                  }))) : null, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.padding2(Css.px(0), Css.px(15)),
                                tl: /* [] */0
                              })
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.color(Styles.Color.grey90),
                                        tl: {
                                          hd: Css.width(Css.pct(100)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.fontSize(Styles.FontSize.regular),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: {
                                            hd: Css.color(Styles.Color.grey90),
                                            tl: {
                                              hd: Css.marginTop(Css.px(15)),
                                              tl: {
                                                hd: Css.marginBottom(Css.px(10)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      })
                                }, "Metric Type"), React.createElement(ContextBox.make, {
                                  children: (function (param, param$1, close, buttonRef) {
                                      return [
                                              React.createElement("button", {
                                                    ref: buttonRef,
                                                    className: Curry._1(Css.merge, {
                                                          hd: Curry._1(Css.style, Styles.button),
                                                          tl: {
                                                            hd: Curry._1(Css.style, {
                                                                  hd: Css.display("flex"),
                                                                  tl: {
                                                                    hd: Css.alignItems("center"),
                                                                    tl: {
                                                                      hd: Css.color(Styles.Color.blue),
                                                                      tl: {
                                                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                        tl: {
                                                                          hd: Css.borderRadius(Styles.Border.radius),
                                                                          tl: {
                                                                            hd: Css.padding4(Css.px(6), Css.px(16), Css.px(6), Css.px(16)),
                                                                            tl: {
                                                                              hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                                              tl: {
                                                                                hd: Css.transition({
                                                                                      NAME: "ms",
                                                                                      VAL: Styles.Duration.$$default
                                                                                    }, undefined, undefined, "all"),
                                                                                tl: {
                                                                                  hd: Css.hover({
                                                                                        hd: Css.color(Styles.Color.blueSecondary),
                                                                                        tl: {
                                                                                          hd: Css.borderColor(Styles.Color.blueSecondary),
                                                                                          tl: /* [] */0
                                                                                        }
                                                                                      }),
                                                                                  tl: {
                                                                                    hd: Css.focus({
                                                                                          hd: Css.color(Styles.Color.blueSecondary),
                                                                                          tl: {
                                                                                            hd: Css.borderColor(Styles.Color.blueSecondary),
                                                                                            tl: /* [] */0
                                                                                          }
                                                                                        }),
                                                                                    tl: {
                                                                                      hd: Css.width(Css.pct(100)),
                                                                                      tl: /* [] */0
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }),
                                                            tl: /* [] */0
                                                          }
                                                        })
                                                  }, React.createElement("div", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.display("flex"),
                                                              tl: {
                                                                hd: Css.alignItems("center"),
                                                                tl: {
                                                                  hd: Css.justifyContent("spaceBetween"),
                                                                  tl: {
                                                                    hd: Css.width(Css.pct(100)),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            })
                                                      }, React.createElement("div", {
                                                            className: Curry._1(Css.style, {
                                                                  hd: Css.display("flex"),
                                                                  tl: {
                                                                    hd: Css.alignItems("center"),
                                                                    tl: /* [] */0
                                                                  }
                                                                })
                                                          }, React.createElement("div", {
                                                                className: Curry._1(Css.style, {
                                                                      hd: Css.display("flex"),
                                                                      tl: {
                                                                        hd: Css.alignItems("center"),
                                                                        tl: {
                                                                          hd: Css.marginRight(Css.px(10)),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    })
                                                              }, React.createElement(Metric$FunnelTypeIcon, {
                                                                    type_: metric.type_
                                                                  })), React.createElement("div", {
                                                                className: Curry._1(Css.style, {
                                                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                      tl: {
                                                                        hd: Css.fontSize(Styles.FontSize.regular),
                                                                        tl: /* [] */0
                                                                      }
                                                                    })
                                                              }, AvoUtils.getMetricTypeLabel(metric.type_))), React.createElement("div", {
                                                            className: Curry._1(Css.style, {
                                                                  hd: Css.margin(Css.px(5)),
                                                                  tl: /* [] */0
                                                                })
                                                          }, React.createElement(IconArrowDown.make, {
                                                                size: 12,
                                                                color: Styles.Color.blue
                                                              })))),
                                              React.createElement("div", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.position("absolute"),
                                                          tl: {
                                                            hd: Css.left(Css.px(0)),
                                                            tl: {
                                                              hd: Css.bottom(Css.px(0)),
                                                              tl: {
                                                                hd: Css.transform(Css.translateY(Css.pct(100))),
                                                                tl: {
                                                                  hd: Css.padding(Css.px(4)),
                                                                  tl: {
                                                                    hd: Css.borderRadius(Styles.Border.radius),
                                                                    tl: {
                                                                      hd: Css.backgroundColor(Styles.Color.white),
                                                                      tl: {
                                                                        hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                                                        tl: {
                                                                          hd: Css.width(Css.pct(100)),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        })
                                                  }, Belt_List.toArray(Belt_List.map(funnelTypes, (function (type_) {
                                                              return React.createElement("button", {
                                                                          key: TrackingPlanModel.metricTypeToJs(type_),
                                                                          className: Curry._1(Css.merge, {
                                                                                hd: Curry._1(Css.style, Styles.button),
                                                                                tl: {
                                                                                  hd: Curry._1(Css.style, {
                                                                                        hd: Css.display("flex"),
                                                                                        tl: {
                                                                                          hd: Css.alignItems("center"),
                                                                                          tl: {
                                                                                            hd: Css.textAlign("left"),
                                                                                            tl: {
                                                                                              hd: Css.padding2(Css.px(10), Css.px(15)),
                                                                                              tl: {
                                                                                                hd: Css.width(Css.pct(100)),
                                                                                                tl: {
                                                                                                  hd: Css.hover({
                                                                                                        hd: Css.backgroundColor(Styles.Color.blueBg),
                                                                                                        tl: {
                                                                                                          hd: Css.color(Styles.Color.blue),
                                                                                                          tl: /* [] */0
                                                                                                        }
                                                                                                      }),
                                                                                                  tl: /* [] */0
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }),
                                                                                  tl: /* [] */0
                                                                                }
                                                                              }),
                                                                          onClick: (function (param) {
                                                                              if (type_ !== metric.type_) {
                                                                                Curry.app(sendActions, [
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      (function (branch) {
                                                                                          return AnalyticsRe.metricUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), metric.id, metric.name, "N/A", "N/A", "MetricTypeChanged", metric.type_, NamedBranch.getId(branch), schemaGroup.schemaId);
                                                                                        }),
                                                                                      undefined,
                                                                                      [[
                                                                                          {
                                                                                            NAME: "UpdateMetricType",
                                                                                            VAL: [
                                                                                              metric.id,
                                                                                              type_
                                                                                            ]
                                                                                          },
                                                                                          context
                                                                                        ]]
                                                                                    ]);
                                                                                return Curry._1(close, undefined);
                                                                              } else {
                                                                                return Curry._1(close, undefined);
                                                                              }
                                                                            })
                                                                        }, React.createElement("div", {
                                                                              className: Curry._1(Css.style, {
                                                                                    hd: Css.display("flex"),
                                                                                    tl: {
                                                                                      hd: Css.alignItems("center"),
                                                                                      tl: {
                                                                                        hd: Css.marginRight(Css.px(10)),
                                                                                        tl: /* [] */0
                                                                                      }
                                                                                    }
                                                                                  })
                                                                            }, React.createElement(Metric$FunnelTypeIcon, {
                                                                                  type_: type_
                                                                                })), React.createElement("div", {
                                                                              className: Curry._1(Css.style, {
                                                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                                    tl: {
                                                                                      hd: Css.fontSize(Styles.FontSize.regular),
                                                                                      tl: /* [] */0
                                                                                    }
                                                                                  })
                                                                            }, AvoUtils.getMetricTypeLabel(type_)));
                                                            }))))
                                            ];
                                    })
                                })), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.margin2(Css.px(15), Css.px(0)),
                                    tl: /* [] */0
                                  })
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.fontSize(Styles.FontSize.regular),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: {
                                            hd: Css.color(Styles.Color.grey90),
                                            tl: /* [] */0
                                          }
                                        }
                                      })
                                }, "Events"), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.marginTop(Css.px(10)),
                                        tl: {
                                          hd: Css.position("relative"),
                                          tl: /* [] */0
                                        }
                                      })
                                }, match$2 === "Funnel" ? React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.position("absolute"),
                                              tl: {
                                                hd: Css.top(Css.px(15)),
                                                tl: {
                                                  hd: Css.bottom(Css.px(15)),
                                                  tl: {
                                                    hd: Css.left(Css.px(10)),
                                                    tl: {
                                                      hd: Css.width(Css.px(4)),
                                                      tl: {
                                                        hd: Css.backgroundColor(Styles.Color.magentaBg),
                                                        tl: {
                                                          hd: Css.borderRadius(Css.px(10)),
                                                          tl: {
                                                            hd: Css.zIndex(0),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            })
                                      }) : null, React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.position("relative"),
                                            tl: {
                                              hd: Css.zIndex(100),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, tmp$2)), match$4 === "Retention" || match$4 === "Proportion" ? null : renderEventInput(undefined)))), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginTop(Css.px(35)),
                            tl: /* [] */0
                          })
                    }, React.createElement("div", {
                          key: metric.id,
                          className: Curry._1(Css.style, {
                                hd: Css.padding4(Css.px(15), Css.px(0), Css.px(15), Css.px(25)),
                                tl: /* [] */0
                              })
                        }, React.createElement(ObjectActivityLog.make, tmp$12)), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.backgroundColor(Styles.Color.grey20),
                                tl: /* [] */0
                              })
                        }, React.createElement(CommentInput.make, {
                              userId: userId,
                              userExternalId: userId,
                              schemaId: schema.id,
                              threadId: metric.id,
                              events: model.events,
                              itemType: "Metric",
                              itemName: metric.name,
                              mini: true,
                              currentBranch: currentBranch,
                              role: role,
                              schemaGroup: schemaGroup,
                              location: "Metric"
                            })))));
}

var make = Metric;

exports.funnelTypes = funnelTypes;
exports.FunnelTypeIcon = FunnelTypeIcon;
exports.DraggableMetricItems = DraggableMetricItems;
exports.make = make;
/* Css Not a pure module */
