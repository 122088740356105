// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var AvoConfig = require("../../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var CodegenCommons = require("../../../codegen/src/CodegenCommons.bs.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var TrackingPlanModel = require("../../../model/src/TrackingPlanModel.bs.js");
var BeltListExtensions = require("../BeltListExtensions.bs.js");

var NoSourceIdProvidedException = /* @__PURE__ */Caml_exceptions.create("BranchImplementationUtils.NoSourceIdProvidedException");

function compare(a, b) {
  return Caml.caml_string_compare(a.VAL[0].name.toLowerCase(), b.VAL[0].name.toLowerCase());
}

var EventComparator = BeltListExtensions.Dedupe({
      compare: compare
    });

function hasCodegenForSource($$event, sourceId) {
  return Belt_List.some($$event.includeSources, (function (param) {
                if (param.id === sourceId) {
                  return param.includeInCodegen === true;
                } else {
                  return false;
                }
              }));
}

function getEventForChangeType(changeType, events) {
  return Belt_List.keep(events, (function ($$event) {
                if ($$event.NAME === "Updated") {
                  return changeType === "updated";
                } else {
                  return changeType !== "updated";
                }
              }));
}

function filterEvents(implementationTypeOpt, changeTypeOpt, codegenCompletedOpt, sourceId, events) {
  var implementationType = implementationTypeOpt !== undefined ? implementationTypeOpt : "any";
  var changeType = changeTypeOpt !== undefined ? changeTypeOpt : "any";
  var codegenCompleted = codegenCompletedOpt !== undefined ? codegenCompletedOpt : "any";
  var filteredEvents = changeType === "any" ? events : (
      changeType === "updated" ? getEventForChangeType("updated", events) : getEventForChangeType("removed", events)
    );
  return Belt_List.keep(filteredEvents, (function ($$event) {
                if (implementationType === "any") {
                  return true;
                }
                if (sourceId !== undefined) {
                  var match = $$event.VAL;
                  var seenState = match[1];
                  var $$event$1 = match[0];
                  if (implementationType !== "implementedWithCodegen") {
                    return !hasCodegenForSource($$event.VAL[0], sourceId);
                  }
                  if (codegenCompleted === "any" && hasCodegenForSource($$event$1, sourceId)) {
                    return true;
                  }
                  if (typeof seenState === "number") {
                    if (seenState !== 0 || !(codegenCompleted === "incompleted" && hasCodegenForSource($$event$1, sourceId))) {
                      return false;
                    } else {
                      return true;
                    }
                  } else if (codegenCompleted === "completed" && hasCodegenForSource($$event$1, sourceId)) {
                    return true;
                  } else {
                    return false;
                  }
                }
                throw {
                      RE_EXN_ID: NoSourceIdProvidedException,
                      Error: new Error()
                    };
              }));
}

function getPreGeneratedModelWithValidSource(memoizedValue, source, serverTrackingWithDeviceId, skipImplementWithAvoFiltering, model) {
  var preGeneratedEnrichedModel = memoizedValue.contents;
  if (preGeneratedEnrichedModel !== undefined) {
    return preGeneratedEnrichedModel;
  }
  var enrichedModel = CodegenCommons.enrichModelForSnippet(serverTrackingWithDeviceId, skipImplementWithAvoFiltering, model, source);
  memoizedValue.contents = enrichedModel;
  return enrichedModel;
}

function getPreGeneratedModelWithoutValidSource(memoizedValue, model, serverTrackingWithDeviceId, skipImplementWithAvoFiltering, source) {
  var preGeneratedEnrichedModel = memoizedValue.contents;
  if (preGeneratedEnrichedModel !== undefined) {
    return preGeneratedEnrichedModel;
  }
  var validSource = TrackingPlanModel.Source.makeValidWithDefaultValues(source, "Unknown Name", "js_v2", "Web");
  var enrichedModel = getPreGeneratedModelWithValidSource(memoizedValue, validSource, serverTrackingWithDeviceId, skipImplementWithAvoFiltering, model);
  memoizedValue.contents = enrichedModel;
  return enrichedModel;
}

function getEnrichedModelForSnippet(source, $$event, forModel, memoizedModel, memoizedModelWithoutValidSourceRef, serverTrackingWithDeviceId) {
  var match = source.platform;
  var match$1 = AvoConfig.tryToConvertSourceToValidSourceMaybe(source, forModel);
  if (match !== undefined && match === "Flutter" && hasCodegenForSource($$event, source.id)) {
    return getPreGeneratedModelWithoutValidSource(memoizedModelWithoutValidSourceRef, forModel, serverTrackingWithDeviceId, true, source);
  }
  if (match$1 !== undefined && hasCodegenForSource($$event, match$1.id)) {
    return getPreGeneratedModelWithValidSource(memoizedModel, match$1, serverTrackingWithDeviceId, false, forModel);
  } else {
    return getPreGeneratedModelWithoutValidSource(memoizedModelWithoutValidSourceRef, forModel, serverTrackingWithDeviceId, true, source);
  }
}

var commentMarginWidth = 414;

var codeChangesHeaderHeight = 54;

var codeChangesMaximizedHeaderHeight = 80;

exports.NoSourceIdProvidedException = NoSourceIdProvidedException;
exports.EventComparator = EventComparator;
exports.hasCodegenForSource = hasCodegenForSource;
exports.getEventForChangeType = getEventForChangeType;
exports.filterEvents = filterEvents;
exports.getPreGeneratedModelWithValidSource = getPreGeneratedModelWithValidSource;
exports.getPreGeneratedModelWithoutValidSource = getPreGeneratedModelWithoutValidSource;
exports.getEnrichedModelForSnippet = getEnrichedModelForSnippet;
exports.commentMarginWidth = commentMarginWidth;
exports.codeChangesHeaderHeight = codeChangesHeaderHeight;
exports.codeChangesMaximizedHeaderHeight = codeChangesMaximizedHeaderHeight;
/* EventComparator Not a pure module */
