// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var InspectorIssuesFilterUtils = require("../../../shared/utils/InspectorIssuesFilterUtils.bs.js");
var Difference_in_hours = require("date-fns/difference_in_hours");

function issuesToMap(issues) {
  return Belt_MapString.fromArray(Belt_Array.mapU(issues, (function (issue) {
                    return [
                            issue.id,
                            issue
                          ];
                  })));
}

function createInspectorIssuesDetailsGroup(inspectorIssueDetailsInteractionType, issue) {
  var match = issue.issueCount;
  var match$1 = issue.eventCount;
  var match$2 = issue.issueType;
  var tmp;
  if (typeof match$2 === "number") {
    tmp = match$2 === /* EventNotInTrackingPlan */0 ? "EventNotFoundInTrackingPlan" : "UnexpectedEvent";
  } else {
    switch (match$2.TAG | 0) {
      case /* MissingExpectedProperty */0 :
          tmp = "MissingExpectedProperty";
          break;
      case /* PropertyTypeInconsistentWithTrackingPlan */1 :
          tmp = "PropertyInconsistentWithTrackingPlan";
          break;
      case /* UnexpectedProperty */2 :
          tmp = "UnexpectedProperty";
          break;
      case /* InconsistentType */3 :
          tmp = "InconsistentPropertyValueType";
          break;
      
    }
  }
  return AnalyticsRe.Group.inspectorIssueDetails(match !== 0 ? (
                match$1 !== 0 ? match / match$1 : 0.0
              ) : 0.0, issue.issueCount, issue.eventCount, 1, Difference_in_hours(new Date(), issue.firstSeen) | 0, Difference_in_hours(new Date(), issue.lastSeen) | 0, tmp, issue.id, issue.sharedId, inspectorIssueDetailsInteractionType);
}

function createInspectorIssuesViewFiltersAndOrdering(groupedFilters, orderedBy, inspectorDisplayedIssueCount) {
  return AnalyticsRe.Group.inspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.savedViewOrderToAnalytics(orderedBy), orderedBy !== undefined && orderedBy[1] === "asc" ? "Ascending" : "Decending", Belt_Array.map(groupedFilters, (function (groupedFilter) {
                    var variant = groupedFilter.NAME;
                    if (variant === "propertyNames") {
                      return "PropertyName";
                    } else if (variant === "issueTypes") {
                      return "IssueType";
                    } else if (variant === "releases") {
                      return "Release";
                    } else if (variant === "categories") {
                      return "Category";
                    } else if (variant === "eventNames") {
                      return "EventName";
                    } else if (variant === "sources") {
                      return "Source";
                    } else {
                      return "Tag";
                    }
                  })), inspectorDisplayedIssueCount);
}

exports.issuesToMap = issuesToMap;
exports.createInspectorIssuesDetailsGroup = createInspectorIssuesDetailsGroup;
exports.createInspectorIssuesViewFiltersAndOrdering = createInspectorIssuesViewFiltersAndOrdering;
/* AnalyticsRe Not a pure module */
