// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Styles = require("./styles.bs.js");
var Switch = require("./Switch.bs.js");
var Tooltip = require("./Tooltip.bs.js");
var Markdown = require("./Markdown.bs.js");
var Tooltips = require("./markdown/tooltips/tooltips.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var ContextMenu = require("./ContextMenu.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");

function DestinationSegmentConfig(Props) {
  var source = Props.source;
  var context = Props.context;
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useState(function () {
        
      });
  var setIntegration = match[1];
  var integration = match[0];
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                        tl: {
                          hd: Css.width(Css.px(700)),
                          tl: {
                            hd: Css.maxWidth(Css.pct(100)),
                            tl: {
                              hd: Css.marginTop(Css.px(25)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.padding(Css.px(20)),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.medium),
                                tl: {
                                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, "Segment Integration Object", React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginLeft(Css.px(10)),
                            tl: /* [] */0
                          })
                    }, React.createElement(Tooltip.WithAnalytics.make, {
                          fromItem: {
                            itemId: source.id,
                            itemName: AvoConfig.getSourceName(source),
                            itemType: "Source"
                          },
                          title: "Segment Integration Object",
                          children: React.createElement(Tooltip.Content.make, {
                                children: React.createElement(Markdown.make, {
                                      source: Tooltips.segmentConfig
                                    })
                              })
                        }))), Belt_List.toArray(Belt_List.map(source.segmentDestinationOptions, (function (param) {
                        var integration = param[0];
                        return React.createElement("div", {
                                    key: integration,
                                    className: Curry._1(Css.style, {
                                          hd: Css.position("relative"),
                                          tl: {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.justifyContent("spaceBetween"),
                                                tl: {
                                                  hd: Css.padding2(Css.px(20), Css.px(20)),
                                                  tl: {
                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                    tl: {
                                                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey20),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, React.createElement("div", undefined, integration), React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: /* [] */0
                                              }
                                            })
                                      }, React.createElement(Switch.make, {
                                            value: param[1],
                                            onChange: (function (isActive) {
                                                return Curry.app(sendActions, [
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            [[
                                                                {
                                                                  NAME: "UpdateSourceSegmentIntegration",
                                                                  VAL: [
                                                                    source.id,
                                                                    integration,
                                                                    isActive
                                                                  ]
                                                                },
                                                                context
                                                              ]]
                                                          ]);
                                              })
                                          }), React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.marginLeft(Css.px(5)),
                                                  tl: /* [] */0
                                                })
                                          }, React.createElement(ContextMenu.make, {
                                                options: [{
                                                    NAME: "Option",
                                                    VAL: {
                                                      label: "Remove",
                                                      onClick: (function (param) {
                                                          return Curry.app(sendActions, [
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      [[
                                                                          {
                                                                            NAME: "RemoveSourceSegmentIntegration",
                                                                            VAL: [
                                                                              source.id,
                                                                              integration
                                                                            ]
                                                                          },
                                                                          context
                                                                        ]]
                                                                    ]);
                                                        })
                                                    }
                                                  }]
                                              }))));
                      }))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("flexEnd"),
                            tl: {
                              hd: Css.padding(Css.px(15)),
                              tl: {
                                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey30),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.grey20),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("select", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginRight(Css.px(15)),
                            tl: /* [] */0
                          }),
                      value: integration !== undefined ? integration : "none",
                      onChange: (function ($$event) {
                          var integration = $$event.target.value;
                          if (!(integration == null)) {
                            return Curry._1(setIntegration, (function (param) {
                                          return integration;
                                        }));
                          }
                          
                        })
                    }, React.createElement("option", {
                          value: "none"
                        }, "Select integration..."), Belt_List.toArray(Belt_List.map(Belt_List.sort(Belt_List.keep(AvoConfig.segmentIntegrations, (function (integration) {
                                        return !Belt_List.some(source.segmentDestinationOptions, (function (param) {
                                                      return param[0] === integration;
                                                    }));
                                      })), (function (a, b) {
                                    if (a > b) {
                                      return 1;
                                    } else if (a < b) {
                                      return -1;
                                    } else {
                                      return 0;
                                    }
                                  })), (function (integration) {
                                return React.createElement("option", {
                                            key: integration,
                                            value: integration
                                          }, integration);
                              })))), React.createElement(Button.make, {
                      disabled: Belt_Option.isNone(integration),
                      icon: "plus",
                      label: "Add",
                      onClick: (function (param) {
                          if (integration !== undefined) {
                            return Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [[
                                            {
                                              NAME: "AddSourceSegmentIntegration",
                                              VAL: [
                                                source.id,
                                                integration,
                                                true
                                              ]
                                            },
                                            context
                                          ]]
                                      ]);
                          }
                          
                        })
                    })));
}

var make = DestinationSegmentConfig;

exports.make = make;
/* Css Not a pure module */
