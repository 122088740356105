// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Intercom = require("./externals/intercom.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var TextInput = require("./TextInput.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var InputLabel = require("./InputLabel.bs.js");
var TextButton = require("./TextButton.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var FirebaseUtils = require("./firebaseUtils.bs.js");
var ModalWithArtwork = require("./ModalWithArtwork.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var InspectorIssueType = require("../../shared/models/InspectorIssueType.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var ConnectToSlackButton = require("./inspector/ConnectToSlackButton.bs.js");
var InspectorIssuesStore = require("./inspector/InspectorIssuesStore.bs.js");
var InspectorIssuesHelpers = require("./inspector/InspectorIssuesHelpers.bs.js");
var InspectorIssuesFilterHooks = require("./inspector/InspectorIssuesFilterHooks.bs.js");
var InspectorIssuesFilterUtils = require("../../shared/utils/InspectorIssuesFilterUtils.bs.js");

var container = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: {
        hd: Css.paddingRight(Css.px(30)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var box = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("row"),
            tl: {
              hd: Css.boxShadow(Styles.Shadow.box),
              tl: {
                hd: Css.gap(Css.px(8)),
                tl: {
                  hd: Css.padding(Css.px(10)),
                  tl: {
                    hd: Css.maxWidth(Css.px(220)),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var avoLogo = Curry._1(Css.style, {
      hd: Css.flexShrink(0.0),
      tl: /* [] */0
    });

var slackLogo = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.position("absolute"),
        tl: {
          hd: Css.backgroundColor(Styles.Color.white),
          tl: {
            hd: Css.top({
                  NAME: "substract",
                  VAL: [
                    Css.pct(100.0),
                    Css.px(44)
                  ]
                }),
            tl: {
              hd: Css.left({
                    NAME: "substract",
                    VAL: [
                      Css.pct(100.0),
                      Css.px(44)
                    ]
                  }),
              tl: {
                hd: Css.borderRadius(Css.px(12)),
                tl: {
                  hd: Css.padding(Css.px(8)),
                  tl: {
                    hd: Css.boxShadow(Styles.Shadow.box),
                    tl: {
                      hd: Css.width(Css.px(60)),
                      tl: {
                        hd: Css.height(Css.px(60)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function InspectorIssueAlertsSetupModal$InteractiveSlackAlertArt(Props) {
  var name = Props.name;
  var exampleIssue = Props.exampleIssue;
  var tmp;
  if (exampleIssue !== undefined) {
    var propertyName = exampleIssue.propertyName;
    tmp = propertyName !== undefined ? React.createElement($$Text.make, {
            size: "Tiny",
            weight: "Semi",
            color: Styles.Color.grey80,
            children: null
          }, "Property: ", React.createElement($$Text.make, {
                element: "Span",
                weight: "Regular",
                children: propertyName
              })) : null;
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: container
            }, React.createElement("div", {
                  className: box
                }, React.createElement("svg", {
                      className: avoLogo,
                      height: "24",
                      width: "24",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, React.createElement("mask", {
                          id: "a",
                          height: "24",
                          width: "24",
                          maskUnits: "userSpaceOnUse",
                          x: "0",
                          y: "0"
                        }, React.createElement("path", {
                              d: "M.2 3.7C.2 2.1 1.5.8 3.1.8h17.2c1.6 0 2.9 1.3 2.9 3V21c0 1.6-1.3 2.8-2.9 2.8H3.1A2.9 2.9 0 0 1 .2 21V3.7Z",
                              fill: "#C4C4C4"
                            })), React.createElement("g", {
                          mask: "url(#a)"
                        }, React.createElement("path", {
                              d: "M.2.8h23v23H.2z",
                              fill: "#232628"
                            }), React.createElement("path", {
                              clipRule: "evenodd",
                              d: "M11.6 7.2a5.1 5.1 0 1 0 0 10.2 5.1 5.1 0 0 0 0-10.2Zm0 11.6a6.4 6.4 0 1 1 0-13 6.4 6.4 0 0 1 0 13Z",
                              fill: "#fff",
                              fillRule: "evenodd"
                            }), React.createElement("path", {
                              clipRule: "evenodd",
                              d: "M16.3 18.8h2V5.9h-2v12.9Z",
                              fill: "#EC008C",
                              fillRule: "evenodd"
                            }))), React.createElement("div", undefined, React.createElement($$Text.make, {
                          size: "Tiny",
                          weight: "Semi",
                          color: Styles.Color.grey90,
                          children: null
                        }, "Avo Inspector found a new issue in ", React.createElement($$Text.make, {
                              element: "Span",
                              color: Styles.Color.blue120,
                              children: name === "" ? "My saved view" : name
                            })), React.createElement(Spacer.make, {
                          height: 4
                        }), React.createElement($$Text.make, {
                          size: "Tiny",
                          weight: "Semi",
                          color: Styles.Color.grey80,
                          children: null
                        }, "Event: ", React.createElement($$Text.make, {
                              element: "Span",
                              weight: "Regular",
                              children: Belt_Option.mapWithDefault(exampleIssue, "Checkout Started", (function (param) {
                                      return param.eventName;
                                    }))
                            })), tmp, React.createElement($$Text.make, {
                          size: "Tiny",
                          weight: "Semi",
                          color: Styles.Color.grey80,
                          maxWidth: Css.pct(80.0),
                          children: null
                        }, "Issue type: ", React.createElement($$Text.make, {
                              element: "Span",
                              weight: "Regular",
                              children: Belt_Option.mapWithDefault(exampleIssue, "Event unexpected on source", (function (param) {
                                      return InspectorIssueType.getIssueTypeAsText(param.issueType);
                                    }))
                            }))), React.createElement("div", {
                      className: slackLogo
                    }, React.createElement("svg", {
                          fill: "none",
                          viewBox: "0 0 51 51",
                          xmlns: "http://www.w3.org/2000/svg"
                        }, React.createElement("path", {
                              d: "M11 32.1A5.2 5.2 0 1 1 5.8 27H11V32Zm2.7 0a5.2 5.2 0 1 1 10.5 0v13.1a5.2 5.2 0 1 1-10.5 0v-13Z",
                              fill: "#E01E5A"
                            }), React.createElement("path", {
                              d: "M19 11a5.2 5.2 0 1 1 5.2-5.2v5.3h-5.3Zm0 2.7a5.2 5.2 0 1 1 0 10.5H5.7a5.2 5.2 0 1 1 0-10.5h13.1Z",
                              fill: "#36C5F0"
                            }), React.createElement("path", {
                              d: "M40 19a5.2 5.2 0 1 1 5.2 5.2h-5.3V19Zm-2.7 0a5.2 5.2 0 1 1-10.5 0V5.8a5.2 5.2 0 1 1 10.5 0V19Z",
                              fill: "#2EB67D"
                            }), React.createElement("path", {
                              d: "M32 40a5.2 5.2 0 1 1-5.2 5.2V40h5.3Zm0-2.6a5.2 5.2 0 1 1 0-10.5h13.2a5.2 5.2 0 1 1 0 10.5H32.1Z",
                              fill: "#ECB22E"
                            })))));
}

function InspectorIssueAlertsSetupModal(Props) {
  var onClose = Props.onClose;
  var savedView = Props.savedView;
  var sendActions = SendActionsContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var orderedBy = match.orderedBy;
  var filters = match.filters;
  var match$1 = React.useState(function () {
        return false;
      });
  var setHasClickedOtherSources = match$1[1];
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var match$2 = InspectorIssuesFilterHooks.useDraftState(undefined);
  var isDraft = match$2.isDraft;
  var match$3 = React.useState(function () {
        return Belt_Option.mapWithDefault(savedView, "", (function (param) {
                      return param.name;
                    }));
      });
  var setNameInput = match$3[1];
  var nameInput = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setIsSaving = match$4[1];
  var isSaving = match$4[0];
  var match$5 = InspectorIssuesStore.useFilteredIssues(undefined, undefined);
  var filteredIssues = match$5.filteredIssues;
  var addToast = Toast.useAddToast(undefined);
  var match$6 = InspectorIssuesStore.useIssues(undefined, undefined);
  var viewerId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  React.useEffect((function () {
          AnalyticsRe.inspectorAlertsInitiated(schemaGroup, AnalyticsRe.Group.inspectorSavedViews(Belt_Option.mapWithDefault(savedView, "All issues", (function (param) {
                          return param.name;
                        })), Belt_Option.mapWithDefault(savedView, "all-issues", (function (param) {
                          return param.id;
                        })), savedView !== undefined && viewerId === savedView.createdBy ? "Creator" : "Visitor", typeof savedViews === "object" ? Belt_List.size(savedViews.VAL) : -1), InspectorIssuesHelpers.createInspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.groupFilters(filters), orderedBy, filteredIssues.length), schemaGroup.schemaId);
          
        }), []);
  var handleOtherSourcesClick = function (param) {
    AnalyticsRe.inspectorAlertsTypeSelected(schemaGroup, AnalyticsRe.Group.inspectorSavedViews(Belt_Option.mapWithDefault(savedView, "All issues", (function (param) {
                    return param.name;
                  })), Belt_Option.mapWithDefault(savedView, "all-issues", (function (param) {
                    return param.id;
                  })), savedView !== undefined && viewerId === savedView.createdBy ? "Creator" : "Visitor", typeof savedViews === "object" ? Belt_List.size(savedViews.VAL) : -1), InspectorIssuesHelpers.createInspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.groupFilters(filters), orderedBy, filteredIssues.length), "Other", schemaGroup.schemaId);
    return Curry._1(setHasClickedOtherSources, (function (param) {
                  return true;
                }));
  };
  var handleConnect = function (savedView) {
    AnalyticsRe.inspectorAlertsTypeSelected(schemaGroup, AnalyticsRe.Group.inspectorSavedViews(Belt_Option.mapWithDefault(savedView, "All issues", (function (param) {
                    return param.name;
                  })), Belt_Option.mapWithDefault(savedView, "all-issues", (function (param) {
                    return param.id;
                  })), savedView !== undefined && viewerId === savedView.createdBy ? "Creator" : "Visitor", typeof savedViews === "object" ? Belt_List.size(savedViews.VAL) : -1), InspectorIssuesHelpers.createInspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.groupFilters(filters), orderedBy, filteredIssues.length), "Slack", schemaGroup.schemaId);
    return ConnectToSlackButton.ConnectToSlack.connectChannel(workspace.id, "", Belt_Option.mapWithDefault(savedView, /* InspectorIssues */0, (function (param) {
                        return /* SavedView */{
                                _0: param.id
                              };
                      }))).then(function (param) {
                return Curry._1(onClose, undefined);
              });
  };
  var handleSave = function (param) {
    Curry._1(setIsSaving, (function (param) {
            return true;
          }));
    if (savedView !== undefined) {
      return FirebaseUtils.updateSavedView(sendActions, workspace.id, {
                  id: savedView.id,
                  name: savedView.name,
                  createdBy: savedView.createdBy,
                  createdAt: savedView.createdAt,
                  lastModifiedBy: savedView.lastModifiedBy,
                  lastModifiedAt: savedView.lastModifiedAt,
                  filters: filters,
                  order: orderedBy,
                  isArchived: savedView.isArchived
                }, viewerId, (function (param) {
                    Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                              NAME: "inspector",
                              VAL: {
                                NAME: "savedView",
                                VAL: savedView.id
                              }
                            }));
                    handleConnect(savedView);
                    return AnalyticsRe.inspectorSavedViewInteraction(schemaGroup, InspectorIssuesHelpers.createInspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.groupFilters(filters), orderedBy, filteredIssues.length), AnalyticsRe.Group.inspectorSavedViews(savedView.name, savedView.id, viewerId === savedView.createdBy ? "Creator" : "Visitor", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) : -1), "Save", schemaGroup.schemaId);
                  }), (function (param) {
                    Curry._1(addToast, {
                          message: "Failed to save view...",
                          toastType: /* Error */1
                        });
                    return Curry._1(setIsSaving, (function (param) {
                                  return false;
                                }));
                  }));
    } else {
      return FirebaseUtils.createSavedView(sendActions, workspace.id, nameInput, orderedBy, filters, (function (id) {
                    handleConnect({
                            id: id,
                            name: nameInput,
                            createdBy: viewerId,
                            createdAt: new Date(),
                            lastModifiedBy: viewerId,
                            lastModifiedAt: Caml_option.some(new Date()),
                            filters: filters,
                            order: orderedBy,
                            isArchived: false
                          }).finally(function (param) {
                          return Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                                          NAME: "inspector",
                                          VAL: {
                                            NAME: "savedView",
                                            VAL: id
                                          }
                                        }));
                        });
                    AnalyticsRe.inspectorSavedViewCreated(InspectorIssuesHelpers.createInspectorIssuesViewFiltersAndOrdering(InspectorIssuesFilterUtils.groupFilters(filters), orderedBy, filteredIssues.length), schemaGroup, AnalyticsRe.Group.inspectorSavedViews(nameInput, id, "Creator", typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) + 1 | 0 : -1), "SavedView", schemaGroup.schemaId, schemaGroup.schemaId, schemaGroup.schemaId, typeof savedViews === "object" && savedViews.NAME === "Loaded" ? Belt_List.length(savedViews.VAL) + 1 | 0 : -1);
                    return Curry._1(setIsSaving, (function (param) {
                                  return false;
                                }));
                  }), (function (param) {
                    Curry._1(addToast, {
                          message: "Failed to save view...",
                          toastType: /* Error */1
                        });
                    return Curry._1(setIsSaving, (function (param) {
                                  return false;
                                }));
                  }));
    }
  };
  var saveDisabled = isSaving || nameInput === "";
  return React.createElement(ModalWithArtwork.make, {
              artwork: React.createElement(InspectorIssueAlertsSetupModal$InteractiveSlackAlertArt, {
                    name: nameInput,
                    exampleIssue: Belt_Option.mapWithDefault(Belt_Array.get(filteredIssues, 0), Belt_Array.get(match$6.issues, 0), (function (i) {
                            return i;
                          }))
                  }),
              children: null,
              onClose: onClose,
              title: isDraft ? "Get alerts for new issues matching your filters" : "Get alerts for " + Belt_Option.mapWithDefault(savedView, "all new issues", (function (savedView) {
                        return "new issues on \"" + savedView.name + "\"";
                      }))
            }, isDraft ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.grey70,
                        children: "Save this view and connect it with a Slack channel to get alerted on all issues Avo detects for your filter criteria."
                      }), savedView === undefined ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                              height: 20
                            }), React.createElement(InputLabel.make, {
                              htmlFor: "name",
                              children: "Saved view name"
                            }), React.createElement(Spacer.make, {
                              height: 4
                            }), React.createElement(TextInput.make, {
                              autoFocus: true,
                              disabled: isSaving,
                              id: "name",
                              onChange: (function (name) {
                                  return Curry._1(setNameInput, (function (param) {
                                                return name;
                                              }));
                                }),
                              placeholder: "My saved view",
                              stretch: true,
                              onEnter: (function (param) {
                                  if (saveDisabled) {
                                    return ;
                                  } else {
                                    return handleSave(undefined);
                                  }
                                }),
                              value: nameInput
                            })) : null, React.createElement(Spacer.make, {
                        height: 24
                      }), React.createElement(Button.make, {
                        disabled: saveDisabled,
                        label: "Save and connect Slack",
                        onClick: (function (param) {
                            return handleSave(undefined);
                          }),
                        size: "large"
                      })) : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.grey70,
                        children: "Connect Inspector with a Slack channel to get alerted on all issues Avo detects for this view."
                      }), React.createElement(Spacer.make, {
                        height: 24
                      }), React.createElement(Button.make, {
                        label: "Connect Slack",
                        onClick: (function (param) {
                            handleConnect(savedView);
                            
                          }),
                        size: "large"
                      })), React.createElement(Spacer.make, {
                  height: 24
                }), match$1[0] ? React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.grey70,
                    children: null
                  }, "Sorry, we don't have other option available at this time but we'd love to build it for with you! ", React.createElement(TextButton.make, {
                        onClick: (function (param) {
                            return Intercom.showNewMessage(undefined);
                          }),
                        style: "Magenta",
                        children: "Let us know where you want to get notified"
                      })) : React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.grey70,
                    children: null
                  }, "Not using Slack? ", React.createElement(TextButton.make, {
                        onClick: handleOtherSourcesClick,
                        style: "Magenta",
                        children: "Other options"
                      })));
}

var make = InspectorIssueAlertsSetupModal;

exports.make = make;
/* container Not a pure module */
