// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Title = require("./Title.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var DocsLink = require("./DocsLink.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var NamedBranch = require("./NamedBranch.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: /* [] */0
    });

var textBoxStyles = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.px(420)),
      tl: {
        hd: Css.marginTop(Css.px(12)),
        tl: {
          hd: Css.marginBottom(Css.px(16)),
          tl: /* [] */0
        }
      }
    });

function WorkspaceDangerZone(Props) {
  var openBranches = Props.openBranches;
  var onClose = Props.onClose;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: /* [] */0
                      })
                }, React.createElement(Title.make, {
                      children: "Reset Tracking Plan",
                      size: "Tiny"
                    }), React.createElement(Spacer.make, {
                      width: 16
                    }), React.createElement(DocsLink.make, {
                      path: "workspace/reset-tracking-plan"
                    })), React.createElement("div", {
                  className: textBoxStyles
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.grey70,
                      children: "Tracking Plan reset removes all events, properties, metrics, tags, categories and publishing integrations from your workspace. The reset will also close all currently open branches."
                    }), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.grey70,
                      children: "Sources, destinations, workspace members and permissions, Slack integrations and the Inspector will not be affected by the reset."
                    })), React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement(Button.make, {
                  label: "Reset Tracking Plan",
                  loading: match[0],
                  onClick: (function (param) {
                      if (!window.confirm("Are you sure you want to reset your tracking plan?")) {
                        return ;
                      }
                      Curry._1(setLoading, (function (param) {
                              return true;
                            }));
                      var actionsToCloseOpenBranches = Belt_Array.mapU(Belt_List.toArray(openBranches), (function (param) {
                              var branchId = param[0];
                              return [
                                      {
                                        NAME: "CloseBranch",
                                        VAL: [
                                          branchId,
                                          param[1]
                                        ]
                                      },
                                      {
                                        branchId: branchId,
                                        branchQuery: branchId
                                      }
                                    ];
                            }));
                      Curry._1(setLoading, (function (param) {
                              return false;
                            }));
                      return Curry.app(sendActions, [
                                  "Master",
                                  undefined,
                                  undefined,
                                  undefined,
                                  true,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      Router.Schema.pushSchemaRoute("master", undefined, "events");
                                      Curry._1(onClose, undefined);
                                      return AnalyticsRe.workspaceSettingUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), undefined, "ResetTrackingPlan", undefined, NamedBranch.getId(branch), schemaGroup.schemaId, workspace.id, undefined, undefined, undefined, undefined);
                                    }),
                                  undefined,
                                  Belt_Array.concat([[
                                          "ResetTrackingPlan",
                                          {
                                            branchId: "master",
                                            branchQuery: "master"
                                          }
                                        ]], actionsToCloseOpenBranches)
                                ]);
                    })
                }));
}

var make = WorkspaceDangerZone;

exports.rootStyles = rootStyles;
exports.textBoxStyles = textBoxStyles;
exports.make = make;
/* rootStyles Not a pure module */
