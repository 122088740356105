// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("./Icons.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var StateUtils = require("./stateUtils.bs.js");
var TextButton = require("./TextButton.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var DrawerHeader = require("./detailviews/DrawerHeader.bs.js");
var LoadingCircle = require("./LoadingCircle.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var CodegenCommons = require("../../codegen/src/CodegenCommons.bs.js");
var ImplementEvent = require("./ImplementEvent.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var MembersInviteForm = require("./MembersInviteForm.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ImplementationStatusContext = require("./ImplementationStatusContext.bs.js");
var FetchImplementationStatusUtils = require("../../shared/utils/FetchImplementationStatusUtils.bs.js");

function CodegenSourceDetails$InviteDev(Props) {
  var source = Props.source;
  var schema = Props.schema;
  var model = Props.model;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("block"),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                          tl: {
                            hd: Css.padding2(Css.px(12), Css.px(12)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginBottom(Css.px(8)),
                        tl: /* [] */0
                      })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      children: "Send these instructions to a developer"
                    })), React.createElement(MembersInviteForm.make, {
                  schema: schema,
                  redirectUrl: Router.Link.addDrawerItem(undefined, {
                        NAME: "codegenSource",
                        VAL: source.id
                      }),
                  model: model
                }));
}

var InviteDev = {
  make: CodegenSourceDetails$InviteDev
};

var setupStatusBox = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(24)),
      tl: {
        hd: Css.marginLeft(Css.px(12)),
        tl: /* [] */0
      }
    });

var statusLoading = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, {
            hd: Css.height(Css.px(28)),
            tl: {
              hd: Css.width(Css.px(190)),
              tl: {
                hd: Css.borderRadius(Css.px(99)),
                tl: {
                  hd: Css.marginLeft(Css.px(-14)),
                  tl: /* [] */0
                }
              }
            }
          }),
      tl: {
        hd: Curry._1(Css.style, Styles.shimmer),
        tl: /* [] */0
      }
    });

var initializedPill = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("baseline"),
          tl: {
            hd: Css.width("fitContent"),
            tl: {
              hd: Css.background(Styles.Color.mintGreen),
              tl: {
                hd: Css.borderRadius(Css.px(99)),
                tl: {
                  hd: Css.paddingTop(Css.px(4)),
                  tl: {
                    hd: Css.paddingRight(Css.px(12)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(5)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(8)),
                        tl: {
                          hd: Css.marginLeft(Css.px(-14)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var waitingPill = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.width("fitContent"),
            tl: {
              hd: Css.background(Styles.Color.grey70),
              tl: {
                hd: Css.borderRadius(Css.px(99)),
                tl: {
                  hd: Css.paddingTop(Css.px(4)),
                  tl: {
                    hd: Css.paddingRight(Css.px(12)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(5)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(6)),
                        tl: {
                          hd: Css.marginLeft(Css.px(-14)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var Style = {
  setupStatusBox: setupStatusBox,
  statusLoading: statusLoading,
  initializedPill: initializedPill,
  waitingPill: waitingPill
};

function CodegenSourceDetails(Props) {
  var model = Props.model;
  var sourceId = Props.sourceId;
  var schema = Props.schema;
  var rawModel = Props.rawModel;
  var role = Props.role;
  var hash = React.useRef(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).hash);
  var match = React.useContext(ImplementationStatusContext.context).codegen;
  var source = Belt_List.getBy(model.sources, (function (source) {
          return source.id === sourceId;
        }));
  var tmp;
  if (source !== undefined) {
    var sourceEvents = Belt_List.sort(StateUtils.getSourceEvents(true, model, sourceId), (function (a, b) {
            if (a.name > b.name) {
              return 1;
            } else if (a.name < b.name) {
              return -1;
            } else {
              return 0;
            }
          }));
    var errors = AvoConfig.validateSource(source, model);
    if (errors.TAG === /* Ok */0) {
      var validSource = errors._0;
      var match$1 = CodegenCommons.enrich(undefined, undefined, undefined, rawModel, validSource, AvoConfig.getValidDestinations(rawModel.sources, rawModel.destinations));
      var enrichedModel = match$1[0];
      var tmp$1;
      if (AvoConfig.supportsInvocations(source)) {
        var tmp$2;
        if (match[0] === "Ready") {
          var initialized = Belt_Option.mapWithDefault(Js_dict.get(match[2], source.id), false, (function (invocations) {
                  return !Belt_MapString.isEmpty(invocations);
                }));
          tmp$2 = initialized ? React.createElement("div", {
                  className: initializedPill
                }, React.createElement(Icons.CheckmarkRounded.make, {
                      size: 12,
                      color: Styles.Color.white
                    }), React.createElement(Spacer.make, {
                      width: 4
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.white,
                      children: "Successfully Initialized"
                    })) : React.createElement("div", {
                  className: waitingPill
                }, React.createElement(LoadingCircle.make, {
                      size: 18,
                      color: Styles.Color.grey50
                    }), React.createElement(Spacer.make, {
                      width: 6
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.white,
                      children: "Listening for the first initialization…"
                    }));
        } else {
          tmp$2 = React.createElement("div", {
                className: statusLoading
              });
        }
        tmp$1 = React.createElement("div", {
              className: setupStatusBox
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.grey70,
                  children: "Setup Status"
                }), React.createElement(Spacer.make, {
                  height: 8
                }), tmp$2, React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement(TextButton.make, {
                  onClick: (function (param) {
                      return Router.Schema.pushDrawerItem(undefined, {
                                  NAME: "source",
                                  VAL: [
                                    source.id,
                                    "codegenSetup"
                                  ]
                                });
                    }),
                  style: "Magenta",
                  size: "Medium",
                  children: "View Setup Instructions"
                }));
      } else {
        tmp$1 = null;
      }
      tmp = React.createElement(React.Fragment, {
            children: null,
            key: source.id
          }, React.createElement(DrawerHeader.make, {
                kind: "Implementation Instructions",
                children: React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      textAlign: "left",
                      children: AvoConfig.getSourceName(source)
                    })
              }), React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.padding(Css.px(15)),
                      tl: {
                        hd: Css.overflowY("scroll"),
                        tl: {
                          hd: Css.marginBottom(Css.px(15)),
                          tl: {
                            hd: Css.maxHeight(Css_Legacy_Core.Calc.$neg(Css.vh(100), DrawerHeader.heightInPixels)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }),
                id: ImplementEvent.scrollContainerId
              }, role === "Admin" ? React.createElement(CodegenSourceDetails$InviteDev, {
                      source: source,
                      schema: schema,
                      model: model
                    }) : null, tmp$1, Caml_obj.caml_notequal(source.platform, "JsonSchema") ? React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.margin(Css.px(0)),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.large),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.padding4(Css.px(24), Css.px(12), Css.px(0), Css.px(12)),
                                tl: /* [] */0
                              })
                        }, React.createElement($$Text.make, {
                              size: "Large",
                              weight: "Semi",
                              color: Styles.Color.grey90,
                              children: "Event documentation"
                            })), Belt_List.toArray(Belt_List.mapU(sourceEvents, (function ($$event) {
                                return React.createElement("div", {
                                            key: $$event.id,
                                            id: $$event.id
                                          }, React.createElement(ImplementationStatusContext.EventSubscription.make, {
                                                eventId: $$event.id,
                                                children: (function (invocations) {
                                                    var invocation = typeof invocations === "object" ? FetchImplementationStatusUtils.reduceInvocations(FetchImplementationStatusUtils.getInvocationsFromList(source.id, undefined, undefined, undefined, invocations.VAL)) : undefined;
                                                    return React.createElement(ImplementEvent.make, {
                                                                event: $$event,
                                                                source: source,
                                                                model: model,
                                                                enrichedModel: enrichedModel,
                                                                completed: Belt_Option.isSome(invocation),
                                                                lastSeen: Belt_Option.map(invocation, (function (invocation) {
                                                                        return new Date(invocation.lastSeen.value);
                                                                      })),
                                                                validSource: validSource,
                                                                isActive: hash.current === $$event.id,
                                                                key: $$event.id
                                                              });
                                                  })
                                              }));
                              })))) : null, role === "Admin" ? React.createElement(CodegenSourceDetails$InviteDev, {
                      source: source,
                      schema: schema,
                      model: model
                    }) : null));
    } else {
      tmp = React.createElement("div", {
            key: source.id
          }, React.createElement(DrawerHeader.make, {
                kind: "Implementation Instructions",
                children: React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      textAlign: "left",
                      children: AvoConfig.getSourceName(source)
                    })
              }), React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.padding(Css.px(60)),
                      tl: /* [] */0
                    })
              }, React.createElement($$Text.make, {
                    size: "Medium",
                    color: Styles.Color.grey80,
                    children: null
                  }, "It looks like this source has not been fully set up: ", React.createElement($$Text.make, {
                        element: "Span",
                        weight: "Semi",
                        children: Belt_List.toArray(Belt_List.map(errors._0, (function (error) {
                                      if (typeof error === "object") {
                                        return "missing api key";
                                      } else if (error === "AnalyticsPlatformMissing") {
                                        return "analytics platform hasn't been set";
                                      } else if (error === "NameMissing") {
                                        return "missing name";
                                      } else if (error === "LanguageMissing") {
                                        return "programming language hasn't been set";
                                      } else if (error === "DestinationsMissing") {
                                        return "not sending to any destinations";
                                      } else {
                                        return "development platform has't been set";
                                      }
                                    }))).join(", ")
                      })), React.createElement(Spacer.make, {
                    height: 12
                  }), React.createElement(Button.make, {
                    label: "Go to Source Settings",
                    onClick: (function (param) {
                        return Router.Schema.pushDrawerItem(undefined, {
                                    NAME: "source",
                                    VAL: [
                                      source.id,
                                      "overview"
                                    ]
                                  });
                      }),
                    size: "large"
                  })));
    }
  } else {
    tmp = "source not found";
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.flexGrow(1.0),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.minWidth(Css.px(0)),
                        tl: {
                          hd: Css.height(Css.pct(100.0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, tmp);
}

var make = CodegenSourceDetails;

exports.InviteDev = InviteDev;
exports.Style = Style;
exports.make = make;
/* setupStatusBox Not a pure module */
