// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var JsCookie = require("js-cookie");
var Caml_option = require("rescript/lib/js/caml_option.js");

function setJson(prim0, prim1) {
  JsCookie.set(prim0, prim1);
  
}

function makeConfig(prim0, prim1, prim2) {
  var tmp = {};
  if (prim0 !== undefined) {
    tmp.expires = prim0;
  }
  if (prim1 !== undefined) {
    tmp.path = prim1;
  }
  return tmp;
}

function remove(prim) {
  JsCookie.remove(prim);
  
}

function setJsonConfig(prim0, prim1, prim2) {
  JsCookie.set(prim0, prim1, prim2);
  
}

function setString(prim0, prim1) {
  JsCookie.set(prim0, prim1);
  
}

function getAsString(prim) {
  return Caml_option.nullable_to_opt(JsCookie.get(prim));
}

function getAsJson(prim) {
  return Caml_option.nullable_to_opt(JsCookie.getJSON(prim));
}

exports.setJson = setJson;
exports.makeConfig = makeConfig;
exports.remove = remove;
exports.setJsonConfig = setJsonConfig;
exports.setString = setString;
exports.getAsString = getAsString;
exports.getAsJson = getAsJson;
/* js-cookie Not a pure module */
