// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Icon = require("./Icon.bs.js");
var Link = require("./Link.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Actions = require("./actions.bs.js");
var IconList = require("./IconList.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var PortalMenu = require("./PortalMenu.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var IconListAdd = require("./IconListAdd.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var SidebarBranchPopup = require("./SidebarBranchPopup.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

function branchStatusStyles(customColor) {
  return Curry._1(Css.style, {
              hd: Css.backgroundColor(Styles.Color.setAlpha(customColor, 0.1)),
              tl: {
                hd: Css.paddingTop(Css.px(5)),
                tl: {
                  hd: Css.paddingRight(Css.px(8)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(5)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(8)),
                      tl: {
                        hd: Css.borderRadius(Css.px(4)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var branchNameContainerStyle = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.padding(Css.px(8)),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.width(Css.pct(100.0)),
                    tl: {
                      hd: Css.marginRight(Css.px(-1)),
                      tl: {
                        hd: Css.selector(" path, p", {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "all"),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.hover({
                                hd: Css.selector(" path, p", {
                                      hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey30),
                                      tl: {
                                        hd: Css.color(Styles.Color.grey30),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function linkStyles(statusColor) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.marginTop(Css.px(-2)),
                    tl: {
                      hd: Css.marginRight(Css.px(6)),
                      tl: {
                        hd: Css.marginBottom(Css.px(6)),
                        tl: {
                          hd: Css.marginLeft(Css.px(6)),
                          tl: {
                            hd: Css.color(Styles.Color.grey50),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$short
                                  }, undefined, undefined, "all"),
                              tl: {
                                hd: Css.active({
                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.focus({
                                        hd: Css.outlineStyle("none"),
                                        tl: {
                                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.setAlpha(statusColor, 0.5))),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.color(Styles.Color.grey30),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function SidebarBranchControl(Props) {
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var schema = Props.schema;
  var globalSend = Props.globalSend;
  var role = Props.role;
  var branchStatus = Props.branchStatus;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var buttonRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setShowBranchPopup = match[1];
  var openBranch = Belt_List.getByU(openBranches, (function (param) {
          return param[0] === Actions.branchToId(currentBranch);
        }));
  var match$1 = FirebaseFetcherHooks.useBranchLifecycleActions(schema.id, Actions.branchToId(currentBranch), undefined, undefined);
  var branchOpenAction = match$1[0];
  var match$2 = AvoLimits.ApprovalWorkflow.computeAvailability(workspace);
  var approvalWorkflow = match$2 === "AvailableDuringTrial" || match$2 === "Available";
  var branchStatus$1;
  var exit = 0;
  if (approvalWorkflow) {
    if (typeof branchStatus === "number") {
      branchStatus$1 = branchStatus;
    } else {
      switch (branchStatus.TAG | 0) {
        case /* Draft */3 :
        case /* ReadyForReview */4 :
        case /* ChangesRequested */5 :
            branchStatus$1 = branchStatus;
            break;
        case /* Approved */6 :
            var approvals = branchStatus._0;
            branchStatus$1 = Belt_List.length(approvals) < workspace.settings.numRequiredApprovals ? ({
                  TAG: /* ReadyForReview */4,
                  _0: Belt_List.headExn(approvals)
                }) : branchStatus;
            break;
        default:
          branchStatus$1 = branchStatus;
      }
    }
  } else if (typeof branchStatus === "number") {
    branchStatus$1 = branchStatus;
  } else {
    switch (branchStatus.TAG | 0) {
      case /* Draft */3 :
      case /* ReadyForReview */4 :
      case /* ChangesRequested */5 :
      case /* Approved */6 :
          exit = 1;
          break;
      default:
        branchStatus$1 = branchStatus;
    }
  }
  if (exit === 1) {
    branchStatus$1 = Belt_Option.mapWithDefault(branchOpenAction, /* Loading */1, (function (openAction) {
            return {
                    TAG: /* Open */0,
                    _0: openAction
                  };
          }));
  }
  var currentBranchName;
  if (currentBranch === "Master") {
    currentBranchName = "main";
  } else if (openBranch !== undefined) {
    currentBranchName = openBranch[1];
  } else if (branchOpenAction !== undefined) {
    var match$3 = Caml_option.valFromOption(branchOpenAction).contents;
    currentBranchName = typeof match$3 === "object" && match$3.NAME === "OpenBranch" ? match$3.VAL[1] : Actions.branchToId(currentBranch);
  } else {
    currentBranchName = "-";
  }
  var statusColor;
  if (typeof branchStatus$1 === "number") {
    statusColor = Styles.Color.grey40;
  } else {
    switch (branchStatus$1.TAG | 0) {
      case /* Open */0 :
          statusColor = Styles.Color.grey50;
          break;
      case /* Merged */1 :
          statusColor = Styles.Color.blue;
          break;
      case /* Closed */2 :
          statusColor = Styles.Color.grapeError;
          break;
      case /* Draft */3 :
          statusColor = Styles.Color.grey30;
          break;
      case /* ReadyForReview */4 :
      case /* ChangesRequested */5 :
          statusColor = Styles.Color.orangeSecondary;
          break;
      case /* Approved */6 :
          statusColor = Styles.Color.mintGreen;
          break;
      
    }
  }
  var tmp;
  if (currentBranch === "Master") {
    tmp = null;
  } else {
    var tmp$1;
    if (typeof branchStatus$1 === "number") {
      tmp$1 = branchStatus$1 === /* Master */0 ? "" : "LOADING...";
    } else {
      switch (branchStatus$1.TAG | 0) {
        case /* Open */0 :
            tmp$1 = "OPEN";
            break;
        case /* Merged */1 :
            tmp$1 = "MERGED";
            break;
        case /* Closed */2 :
            tmp$1 = "DELETED";
            break;
        case /* Draft */3 :
            tmp$1 = "DRAFT";
            break;
        case /* ReadyForReview */4 :
            tmp$1 = "READY FOR REVIEW";
            break;
        case /* ChangesRequested */5 :
            tmp$1 = "CHANGES REQUESTED";
            break;
        case /* Approved */6 :
            tmp$1 = "APPROVED";
            break;
        
      }
    }
    tmp = React.createElement(Link.make, {
          path: Router.Link.getSchemaRouteLink(undefined, {
                NAME: "diff",
                VAL: "index"
              }),
          className: linkStyles(statusColor),
          id: "branch-tour-review",
          children: null
        }, React.createElement("span", {
              className: branchStatusStyles(statusColor)
            }, React.createElement($$Text.make, {
                  size: "Tiny",
                  weight: "Semi",
                  color: statusColor,
                  children: tmp$1
                })), React.createElement(Spacer.make, {
              width: 8
            }), React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              children: "View changes"
            }));
  }
  return React.createElement("div", {
              id: "product-tour-branches"
            }, React.createElement("button", {
                  ref: buttonRef,
                  className: branchNameContainerStyle,
                  id: "sidebar-tour-branches",
                  onClick: (function (param) {
                      return Curry._1(setShowBranchPopup, (function (state) {
                                    return !state;
                                  }));
                    })
                }, React.createElement(Icon.make, {
                      type_: "branch",
                      size: {
                        NAME: "int",
                        VAL: 12
                      },
                      color: Styles.Color.grey50
                    }), React.createElement(Spacer.make, {
                      width: 4
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      singleLine: true,
                      color: Styles.Color.grey50,
                      children: currentBranchName
                    }), React.createElement(Spacer.make, {
                      width: 4,
                      grow: 1.0
                    }), Models.Role.canEdit(role) && currentBranch === "Master" ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                            height: 4
                          }), React.createElement(IconListAdd.make, {
                            color: Styles.Color.grey50,
                            hoverColor: Styles.Color.white
                          })) : React.createElement(IconList.make, {
                        color: Styles.Color.grey50,
                        hoverColor: Styles.Color.white
                      })), React.createElement(PortalMenu.make, {
                  position: "Right",
                  placement: "Start",
                  offset: {
                    top: -8
                  },
                  distance: 4,
                  visible: match[0],
                  onClose: (function (param) {
                      return Curry._1(setShowBranchPopup, (function (param) {
                                    return false;
                                  }));
                    }),
                  referenceItemRef: buttonRef,
                  children: React.createElement(SidebarBranchPopup.make, {
                        currentBranch: currentBranch,
                        openBranches: openBranches,
                        schema: schema,
                        onClose: (function (param) {
                            return Curry._1(setShowBranchPopup, (function (param) {
                                          return false;
                                        }));
                          }),
                        globalSend: globalSend,
                        role: role
                      })
                }), tmp);
}

var make = SidebarBranchControl;

exports.make = make;
/* branchNameContainerStyle Not a pure module */
