// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Styles = require("../styles.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(60)),
      tl: {
        hd: Css.marginLeft(Css.px(48)),
        tl: /* [] */0
      }
    });

var linkStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.blue),
      tl: {
        hd: Css.hover({
              hd: Css.color(Styles.Color.blueSecondary),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.transition({
                NAME: "ms",
                VAL: Styles.Duration.$$short
              }, undefined, undefined, "color"),
          tl: /* [] */0
        }
      }
    });

var actionsStyles = Curry._1(Css.style, {
      hd: Css.display("inlineFlex"),
      tl: {
        hd: Css.flexWrap("wrap"),
        tl: {
          hd: Css.paddingTop(Css.px(24)),
          tl: {
            hd: Css.paddingBottom(Css.px(48)),
            tl: {
              hd: Css.paddingLeft(Css.px(32)),
              tl: {
                hd: Css.marginLeft(Css.px(-32)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var actionStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.width(Css.px(160)),
                    tl: {
                      hd: Css.height(Css.px(160)),
                      tl: {
                        hd: Css.padding4(Css.px(28), Css.px(24), Css.px(16), Css.px(24)),
                        tl: {
                          hd: Css.marginRight(Css.px(32)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Css.px(10)),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$default
                                    }, undefined, undefined, "box-shadow"),
                                tl: {
                                  hd: Css.boxShadow(Styles.Shadow.standard),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.boxShadow(Styles.Shadow.standardHover),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var illustrationStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.width(Css.px(400)),
            tl: {
              hd: Css.height(Css.px(150)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var iconStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.width(Css.px(64)),
            tl: {
              hd: Css.height(Css.px(64)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.grey20),
                tl: {
                  hd: Css.borderRadius(Css.px(32)),
                  tl: {
                    hd: Css.marginBottom(Css.px(8)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

exports.rootStyles = rootStyles;
exports.linkStyles = linkStyles;
exports.actionsStyles = actionsStyles;
exports.actionStyles = actionStyles;
exports.illustrationStyles = illustrationStyles;
exports.iconStyles = iconStyles;
/* rootStyles Not a pure module */
