// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var CmdK = require("./CmdK.bs.js");
var Kbar = require("kbar");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("./Hooks.bs.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");

function CmdKProvider$ShortcutOpeningAndPathHelper(Props) {
  var setPath = Props.setPath;
  var children = Props.children;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var match = Curry._1(CmdK.Matches.useMatches, undefined);
  var rootActionId = match.rootActionId;
  var kBarState = Kbar.useKBar(function (state) {
        return {
                searchTerm: state.searchQuery,
                visualState: state.visualState
              };
      });
  var opening = React.useRef(false);
  var wantsToGoUp = React.useRef(false);
  React.useEffect((function () {
          var match = kBarState.visualState;
          if (match !== undefined) {
            if (match === "showing") {
              if (opening.current) {
                var tmp;
                if (rootActionId !== undefined) {
                  switch (rootActionId) {
                    case "branch" :
                        tmp = "Branches";
                        break;
                    case "create" :
                        tmp = "Create";
                        break;
                    case "search" :
                        tmp = "Search";
                        break;
                    case "unarchive" :
                        tmp = "Unarchive";
                        break;
                    default:
                      tmp = Pervasives.failwith("Fix new paths in Command Palette: " + rootActionId);
                  }
                } else {
                  tmp = "CmdKRoot";
                }
                AnalyticsRe.cmdPaletteOpened(schemaGroup, tmp, schemaGroup.branchId, schemaGroup.schemaId);
              }
              opening.current = false;
            } else if (match === "animating-in") {
              opening.current = true;
            }
            
          }
          
        }), [kBarState.visualState]);
  React.useEffect((function () {
          var onKeyStroke = function ($$event) {
            if ($$event.key === "Backspace" && kBarState.searchTerm === "") {
              wantsToGoUp.current = true;
            } else {
              wantsToGoUp.current = false;
            }
            
          };
          window.addEventListener("keydown", onKeyStroke);
          return (function (param) {
                    window.removeEventListener("keydown", onKeyStroke);
                    
                  });
        }), []);
  Hooks.useDidUpdate1((function (param) {
          Curry._1(setPath, (function (path) {
                  var l = Belt_List.fromArray(Belt_Array.reverse(path));
                  var tmp;
                  if (rootActionId === undefined) {
                    tmp = /* [] */0;
                  } else if (l) {
                    var l$1 = l.tl;
                    var exit = 0;
                    if (l$1 || !wantsToGoUp.current) {
                      exit = 1;
                    } else {
                      tmp = /* [] */0;
                    }
                    if (exit === 1) {
                      tmp = wantsToGoUp.current ? l$1 : ({
                            hd: Belt_Option.getExn(rootActionId),
                            tl: l
                          });
                    }
                    
                  } else {
                    tmp = wantsToGoUp.current ? /* [] */0 : ({
                          hd: Belt_Option.getExn(rootActionId),
                          tl: /* [] */0
                        });
                  }
                  return Belt_Array.reverse(Belt_List.toArray(tmp));
                }));
          
        }), [rootActionId]);
  return children;
}

var ShortcutOpeningAndPathHelper = {
  make: CmdKProvider$ShortcutOpeningAndPathHelper
};

function CmdKProvider(Props) {
  var children = Props.children;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var match = React.useState(function () {
        return [];
      });
  var path = match[0];
  var searchTerm = React.useRef("");
  return React.createElement(Kbar.KBarProvider, {
              actions: [],
              options: {
                callbacks: {
                  onClose: (function (param) {
                      return AnalyticsRe.cmdPaletteExited(AnalyticsRe.Group.cmdPalette(path.join("/"), path.length, searchTerm.current), schemaGroup, schemaGroup.branchId, schemaGroup.schemaId);
                    }),
                  onQueryChange: (function (query) {
                      searchTerm.current = query;
                      
                    }),
                  onSelectAction: (function (action) {
                      var action$1 = Curry._1(CmdK.Result.convert, action);
                      if (action$1.TAG === /* Section */0) {
                        return ;
                      }
                      var action$2 = action$1._0;
                      var match = action$2.perform;
                      return AnalyticsRe.cmdPaletteOptionSelected(schemaGroup, AnalyticsRe.Group.cmdPalette(path.join("/"), path.length, searchTerm.current), match !== undefined ? "ActionPerformed" : "PathDeeper", action$2.name, schemaGroup.branchId, schemaGroup.schemaId);
                    })
                }
              },
              children: React.createElement(CmdKProvider$ShortcutOpeningAndPathHelper, {
                    setPath: match[1],
                    children: children
                  })
            });
}

var make = CmdKProvider;

exports.ShortcutOpeningAndPathHelper = ShortcutOpeningAndPathHelper;
exports.make = make;
/* CmdK Not a pure module */
