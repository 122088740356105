// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var SettingsDestinations = require("./SettingsDestinations.bs.js");
var SourceSettingsDestination = require("./SourceSettingsDestination.bs.js");

var rootStyles = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
          tl: {
            hd: Css.overflow("hidden"),
            tl: {
              hd: Css.marginBottom(Css.px(20)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var emptyStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(30)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.flexDirection("column"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.padding(Css.px(0)),
              tl: {
                hd: Css.marginBottom(Css.px(12)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var footerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.padding(Css.px(20)),
              tl: {
                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey20),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function SourceSettingsDestinations(Props) {
  var model = Props.model;
  var source = Props.source;
  var schemaGroup = Props.schemaGroup;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var availability = AvoLimits.ConfigEventSourcesAndDestinations.computeAvailability(workspace);
  var globalSend = GlobalSendContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var allSupportedModelDestinations = AvoUtils.getDestinationsSupport(source.platform, source.language, model.destinations);
  var allSupportedDestinationsAreSelected = Belt_List.every(allSupportedModelDestinations, (function (param) {
          var destination = param[1];
          if (param[2]) {
            return Belt_List.someU(source.destinations, (function (param) {
                          return param.destinationId === destination.id;
                        }));
          } else {
            return true;
          }
        }));
  var match = source.platform;
  var match$1 = model.destinations;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: headerStyles
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginLeft(Css.px(12)),
                            tl: /* [] */0
                          })
                    }, React.createElement($$Text.make, {
                          element: "H1",
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.grey80,
                          children: "Destinations"
                        })), availability === "Unavailable" && !allSupportedDestinationsAreSelected ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(Button.make, {
                            label: "Enable all",
                            onClick: (function (param) {
                                if (!window.confirm("Enabling all destinations will add all events to all sources. This cannot be undone unless you upgrade your plan. Are you sure that you want to enable all destinations?")) {
                                  return ;
                                }
                                var eventIds = Belt_List.map(model.events, (function ($$event) {
                                        return $$event.id;
                                      }));
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            Belt_Array.map(Belt_Array.keep(Belt_List.toArray(allSupportedModelDestinations), (function (param) {
                                                        var destination = param[1];
                                                        if (param[2]) {
                                                          return !Belt_List.someU(source.destinations, (function (param) {
                                                                        return param.destinationId === destination.id;
                                                                      }));
                                                        } else {
                                                          return false;
                                                        }
                                                      })), (function (param) {
                                                    var destination = param[1];
                                                    return [
                                                            {
                                                              NAME: "IncludeDestinationInSourceV2",
                                                              VAL: [
                                                                source.id,
                                                                destination.id,
                                                                eventIds
                                                              ]
                                                            },
                                                            {
                                                              eventIds: Belt_List.toArray(eventIds),
                                                              eventsQuery: Belt_List.toArray(eventIds),
                                                              sourceId: source.id,
                                                              sourceQuery: source.id,
                                                              destinationId: destination.id,
                                                              destinationQuery: destination.id
                                                            }
                                                          ];
                                                  }))
                                          ]);
                              }),
                            style: "outline"
                          })) : null), React.createElement("div", {
                  className: rootStyles
                }, match !== undefined ? (
                    match$1 ? Belt_List.toArray(Belt_List.map(match$1, (function (destination) {
                                  var type_ = destination.type_;
                                  return React.createElement(SourceSettingsDestination.make, {
                                              destination: destination,
                                              source: source,
                                              availability: availability,
                                              isSupported: type_ !== undefined ? Belt_List.has(AvoConfig.getSupportedDestinations(source.platform, source.language), type_, (function (prim0, prim1) {
                                                        return prim0 === prim1;
                                                      })) : false,
                                              onChange: (function (isActive) {
                                                  var match = StateUtils.getSourceEvents(undefined, model, source.id);
                                                  if (isActive) {
                                                    if (match) {
                                                      return Curry._1(globalSend, {
                                                                  TAG: /* OpenModal */4,
                                                                  _0: {
                                                                    NAME: "EnableDestinationForSource",
                                                                    VAL: [
                                                                      source.id,
                                                                      destination.id
                                                                    ]
                                                                  }
                                                                });
                                                    } else {
                                                      return Curry.app(sendActions, [
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  (function (branch) {
                                                                      return AnalyticsRe.dataPathUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), Belt_Option.getExn(source.platform), destination.type_, "Enable", Belt_List.length(StateUtils.getSourceEvents(undefined, model, source.id)), Belt_List.length(StateUtils.getSourceEvents(undefined, model, source.id)), NamedBranch.getId(branch), schemaGroup.schemaId);
                                                                    }),
                                                                  undefined,
                                                                  [[
                                                                      {
                                                                        NAME: "IncludeDestinationInSourceV2",
                                                                        VAL: [
                                                                          source.id,
                                                                          destination.id,
                                                                          /* [] */0
                                                                        ]
                                                                      },
                                                                      {
                                                                        eventIds: [],
                                                                        eventsQuery: [],
                                                                        sourceId: source.id,
                                                                        sourceQuery: source.id,
                                                                        destinationId: destination.id,
                                                                        destinationQuery: destination.id
                                                                      }
                                                                    ]]
                                                                ]);
                                                    }
                                                  } else {
                                                    return Curry.app(sendActions, [
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                (function (branch) {
                                                                    return AnalyticsRe.dataPathUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), Belt_Option.getExn(source.platform), destination.type_, "Disable", Belt_List.length(StateUtils.getSourceEvents(undefined, model, source.id)), Belt_List.length(StateUtils.getSourceEvents(undefined, model, source.id)), NamedBranch.getId(branch), schemaGroup.schemaId);
                                                                  }),
                                                                undefined,
                                                                [[
                                                                    {
                                                                      NAME: "ExcludeDestinationFromSource",
                                                                      VAL: [
                                                                        source.id,
                                                                        destination.id
                                                                      ]
                                                                    },
                                                                    {
                                                                      sourceId: source.id,
                                                                      sourceQuery: source.id
                                                                    }
                                                                  ]]
                                                              ]);
                                                  }
                                                })
                                            });
                                }))) : React.createElement("div", {
                            className: emptyStyles
                          }, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                color: Styles.Color.grey50,
                                children: "No destinations defined"
                              }), React.createElement(Spacer.make, {
                                height: 10
                              }), React.createElement(Button.make, {
                                icon: "plus",
                                label: "New Destination",
                                onClick: (function (param) {
                                    return SettingsDestinations.onAddDestination(globalSend);
                                  })
                              }))
                  ) : React.createElement("div", {
                        className: emptyStyles
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            textAlign: "center",
                            children: "Please select development platform in Source Settings before configuring destinations."
                          }))), availability === "Available" ? null : React.createElement("div", {
                    className: footerStyles
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.grey70,
                        children: availability === "Unavailable" ? "Upgrade plan to make changes" : "Upgrade plan to keep destinations editable"
                      }), React.createElement(Button.make, {
                        label: "Learn more",
                        onClick: (function (param) {
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "ConfigureEventsPerPlatform"
                                        }
                                      });
                          }),
                        style: availability === "Unavailable" ? "primary" : "outline"
                      })));
}

var make = SourceSettingsDestinations;

exports.rootStyles = rootStyles;
exports.emptyStyles = emptyStyles;
exports.headerStyles = headerStyles;
exports.footerStyles = footerStyles;
exports.make = make;
/* rootStyles Not a pure module */
