// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Modal = require("./Modal.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Shortid = require("shortid");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var TextInput = require("./TextInput.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var TextButton = require("./TextButton.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var IntegrationIcon = require("./integration/IntegrationIcon.bs.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");

function getIntegrationLabel(integrationType) {
  if (integrationType === "Protocols") {
    return "Segment Protocols";
  } else if (integrationType === "SnowplowDataStructures") {
    return "Snowplow Iglu";
  } else if (integrationType === "Lexicon") {
    return "Mixpanel Lexicon";
  } else if (integrationType === "MParticleDataMaster") {
    return "mParticle Data Master";
  } else if (integrationType === "Taxonomy") {
    return "Amplitude Data";
  } else if (integrationType === "Webhook") {
    return "Webhook";
  } else {
    return "RudderStack Tracking Plans";
  }
}

var content = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("stretch"),
          tl: {
            hd: Css.paddingTop(Css.px(20)),
            tl: {
              hd: Css.paddingRight(Css.px(16)),
              tl: {
                hd: Css.paddingBottom(Css.px(20)),
                tl: {
                  hd: Css.paddingLeft(Css.px(16)),
                  tl: {
                    hd: Css.minWidth(Css.px(480)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var label = Curry._1(Css.style, {
      hd: Css.display("block"),
      tl: {
        hd: Css.fontSize(Css.px(11)),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.semi),
          tl: {
            hd: Css.lineHeight(Css.pct(140.0)),
            tl: {
              hd: Css.color(Styles.Color.grey70),
              tl: {
                hd: Css.marginBottom(Css.px(4)),
                tl: {
                  hd: Css.marginLeft(Css.px(8)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var options = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexWrap("wrap"),
        tl: {
          hd: Css.paddingBottom(Css.px(20)),
          tl: /* [] */0
        }
      }
    });

function integrationButton(selected) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.width(Css.px(88)),
                    tl: {
                      hd: Css.minHeight(Css.px(80)),
                      tl: {
                        hd: Css.borderWidth("zero"),
                        tl: {
                          hd: Css.backgroundColor(selected ? Styles.Color.white : "transparent"),
                          tl: {
                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(10), Css.px(20), undefined, undefined, selected ? Css.rgba(0, 0, 0, {
                                            NAME: "num",
                                            VAL: 0.05
                                          }) : "transparent")),
                            tl: {
                              hd: Css.marginTop(Css.px(8)),
                              tl: {
                                hd: Css.marginRight(Css.px(8)),
                                tl: {
                                  hd: Css.paddingTop(Css.px(16)),
                                  tl: {
                                    hd: Css.paddingRight(Css.px(8)),
                                    tl: {
                                      hd: Css.paddingBottom(Css.px(8)),
                                      tl: {
                                        hd: Css.paddingLeft(Css.px(8)),
                                        tl: {
                                          hd: Css.borderRadius(Css.px(10)),
                                          tl: {
                                            hd: Css.cursor("pointer"),
                                            tl: {
                                              hd: Css.color(selected ? Styles.Color.grey90 : Styles.Color.grey70),
                                              tl: {
                                                hd: Css.transition({
                                                      NAME: "ms",
                                                      VAL: Styles.Duration.$$default
                                                    }, undefined, undefined, "all"),
                                                tl: {
                                                  hd: Css.focus({
                                                        hd: Css.outlineStyle("none"),
                                                        tl: {
                                                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(0), Css.px(0), Css.px(2), true, Styles.Color.blueSecondary)),
                                                          tl: /* [] */0
                                                        }
                                                      }),
                                                  tl: {
                                                    hd: Css.hover(selected ? ({
                                                              hd: Css.focus({
                                                                    hd: Css.boxShadows({
                                                                          hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(4), Css.px(16), undefined, undefined, {
                                                                                NAME: "rgba",
                                                                                VAL: [
                                                                                  0,
                                                                                  0,
                                                                                  0,
                                                                                  {
                                                                                    NAME: "num",
                                                                                    VAL: 0.05
                                                                                  }
                                                                                ]
                                                                              }),
                                                                          tl: {
                                                                            hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(0), Css.px(0), Css.px(2), true, Styles.Color.blueSecondary),
                                                                            tl: /* [] */0
                                                                          }
                                                                        }),
                                                                    tl: /* [] */0
                                                                  }),
                                                              tl: /* [] */0
                                                            }) : ({
                                                              hd: Css.color(Styles.Color.grey90),
                                                              tl: /* [] */0
                                                            })),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var footer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("flexEnd"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.grey30),
                tl: {
                  hd: Css.padding(Css.px(12)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function CreateIntegrationModal(Props) {
  var onClose = Props.onClose;
  var integrations = Props.integrations;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useState(function () {
        return "";
      });
  var setName = match[1];
  var name = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setSelectedType = match$1[1];
  var selectedType = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setNameEdited = match$2[1];
  var nameEdited = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setLoading = match$3[1];
  var isLoading = match$3[0];
  var handleCreate = function (param) {
    return Belt_Option.forEach(selectedType, (function (integrationType) {
                  Curry._1(setLoading, (function (param) {
                          return true;
                        }));
                  var integrationId = Shortid();
                  var context = {
                    integrationId: integrationId,
                    integrationQuery: integrationId
                  };
                  Curry.app(sendActions, [
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        (function (branch) {
                            AnalyticsRe.integrationCreated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.integrationConfig(integrationType === "Protocols" ? "SegmentProtocols" : (
                                        integrationType === "SnowplowDataStructures" ? "SnowplowDataStructures" : (
                                            integrationType === "Lexicon" ? "MixpanelLexicon" : (
                                                integrationType === "MParticleDataMaster" ? "MParticleDataMaster" : (
                                                    integrationType === "Taxonomy" ? "AmplitudeGovern" : (
                                                        integrationType === "Webhook" ? "Webhook" : "RudderstackTrackingPlans"
                                                      )
                                                  )
                                              )
                                          )
                                      ), [], name, integrationId, integrationType === "Webhook" ? "JsonSchema" : undefined), Belt_List.length(integrations) + 1 | 0, NamedBranch.getId(branch), schemaGroup.schemaId);
                            return Router.Schema.pushDrawerItem(undefined, {
                                        NAME: "integration",
                                        VAL: [
                                          integrationId,
                                          undefined
                                        ]
                                      });
                          }),
                        undefined,
                        Belt_Array.concat([
                              [
                                {
                                  NAME: "CreateIntegration",
                                  VAL: integrationId
                                },
                                context
                              ],
                              [
                                {
                                  NAME: "UpdateIntegrationName",
                                  VAL: [
                                    integrationId,
                                    name
                                  ]
                                },
                                context
                              ],
                              [
                                {
                                  NAME: "UpdateIntegrationType",
                                  VAL: [
                                    integrationId,
                                    integrationType
                                  ]
                                },
                                context
                              ]
                            ], integrationType === "Protocols" ? [[
                                  {
                                    NAME: "UpdateIntegrationConfig",
                                    VAL: [
                                      integrationId,
                                      {
                                        TAG: /* Protocols */0,
                                        _0: {
                                          accessKey: undefined,
                                          trackingPlanId: undefined,
                                          workspace: undefined,
                                          usePublicApi: true
                                        }
                                      }
                                    ]
                                  },
                                  context
                                ]] : [])
                      ]);
                  Curry._1(setLoading, (function (param) {
                          return false;
                        }));
                  return Curry._1(onClose, undefined);
                }));
  };
  return React.createElement(Modal.make, {
              title: "Add Integration",
              onClose: onClose,
              children: null
            }, React.createElement("div", {
                  className: content
                }, React.createElement("div", {
                      className: label
                    }, "Integration Type"), React.createElement("div", {
                      className: options
                    }, Belt_Array.mapU(TrackingPlanModel.integrationTypes, (function (integrationType) {
                            return React.createElement("button", {
                                        key: getIntegrationLabel(integrationType),
                                        className: integrationButton(Caml_obj.caml_equal(selectedType, integrationType)),
                                        onClick: (function (param) {
                                            Curry._1(setSelectedType, (function (param) {
                                                    return integrationType;
                                                  }));
                                            if (!nameEdited) {
                                              return Curry._1(setName, (function (param) {
                                                            return getIntegrationLabel(integrationType);
                                                          }));
                                            }
                                            
                                          })
                                      }, React.createElement(IntegrationIcon.ByType.make, {
                                            integration: integrationType,
                                            size: 40
                                          }), React.createElement(Spacer.make, {
                                            height: 8
                                          }), React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Semi",
                                            children: getIntegrationLabel(integrationType)
                                          }));
                          }))), React.createElement("label", {
                      className: label,
                      htmlFor: "name"
                    }, "Name"), React.createElement(TextInput.make, {
                      id: "name",
                      onChange: (function (value) {
                          Curry._1(setNameEdited, (function (param) {
                                  return value !== "";
                                }));
                          return Curry._1(setName, (function (param) {
                                        return value;
                                      }));
                        }),
                      value: name
                    })), React.createElement("footer", {
                  className: footer
                }, React.createElement(TextButton.make, {
                      onClick: (function (param) {
                          return Curry._1(onClose, undefined);
                        }),
                      size: "Small",
                      disabled: isLoading,
                      children: "Cancel"
                    }), React.createElement(Spacer.make, {
                      width: 12
                    }), React.createElement(Button.make, {
                      disabled: !Belt_Option.isSome(selectedType) || name === "" || isLoading,
                      label: isLoading ? "Creating..." : "Create Integration",
                      onClick: handleCreate
                    })));
}

var make = CreateIntegrationModal;

exports.getIntegrationLabel = getIntegrationLabel;
exports.content = content;
exports.label = label;
exports.options = options;
exports.integrationButton = integrationButton;
exports.footer = footer;
exports.make = make;
/* content Not a pure module */
