// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var IconX = require("./IconX.bs.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var PortalTooltip = require("./PortalTooltip.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function Tag$TooltipWrapper(Props) {
  var tooltip = Props.tooltip;
  var children = Props.children;
  if (tooltip !== undefined) {
    return React.createElement(PortalTooltip.make, {
                renderTooltip: (function (param) {
                    return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    height: 4
                                  }), React.createElement($$Text.make, {
                                    size: "Small",
                                    color: Styles.Color.white,
                                    children: tooltip
                                  }));
                  }),
                position: "Bottom",
                children: children
              });
  } else {
    return children;
  }
}

var TooltipWrapper = {
  make: Tag$TooltipWrapper
};

function Tag(Props) {
  var tag = Props.tag;
  var onRemove = Props.onRemove;
  var tagColor = Props.tagColor;
  var tooltip = Props.tooltip;
  var tagColor$1 = Belt_Option.getWithDefault(tagColor, Styles.Color.getColorFromString(tag));
  var tmp = {
    children: React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.padding2(Css.px(5), Css.px(10)),
                    tl: {
                      hd: Css.backgroundColor(tagColor$1),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.margin(Css.px(2)),
                          tl: {
                            hd: Css.color(Styles.Color.white),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.small),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              })
        }, tag, onRemove !== undefined ? React.createElement("button", {
                className: Curry._1(Css.merge, {
                      hd: Curry._1(Css.style, Styles.button),
                      tl: {
                        hd: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.width(Css.px(16)),
                                    tl: {
                                      hd: Css.height(Css.px(16)),
                                      tl: {
                                        hd: Css.padding(Css.px(0)),
                                        tl: {
                                          hd: Css.margin4(Css.px(0), Css.px(-2), Css.px(0), Css.px(4)),
                                          tl: {
                                            hd: Css.borderRadius(Css.pct(50)),
                                            tl: {
                                              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.white),
                                              tl: {
                                                hd: Css.hover({
                                                      hd: Css.backgroundColor(Styles.Color.white),
                                                      tl: {
                                                        hd: Css_Legacy_Core.SVG.stroke(tagColor$1),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }),
                        tl: /* [] */0
                      }
                    }),
                onClick: (function (param) {
                    return Curry._1(onRemove, undefined);
                  })
              }, React.createElement(IconX.make, {
                    size: 8
                  })) : null)
  };
  if (tooltip !== undefined) {
    tmp.tooltip = Caml_option.valFromOption(tooltip);
  }
  return React.createElement(Tag$TooltipWrapper, tmp);
}

var make = Tag;

exports.TooltipWrapper = TooltipWrapper;
exports.make = make;
/* Css Not a pure module */
