// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var React$1 = require("@dopt/react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ProductTour = require("./ProductTour.bs.js");
var RouterStore = require("./RouterStore.bs.js");
var DemoBranchProductTour = require("./DemoBranchProductTour.bs.js");
var HowToSaveViewAndGetAlertsFlow = require("./HowToSaveViewAndGetAlertsFlow.bs.js");
var InspectorIssuesOnboardingFlow = require("./InspectorIssuesOnboardingFlow.bs.js");

function ProductTours$TestFlow(Props) {
  return React.createElement(ProductTour.make, {
              tourId: "test-flow",
              tourMayShow: true,
              children: null
            }, React.createElement(ProductTour.Step.make, {
                  stepId: "step-1",
                  stepType: {
                    TAG: /* Modal */2,
                    _0: {
                      NAME: "num",
                      VAL: 320
                    }
                  },
                  delay: 0,
                  transitions: [{
                      key: "next",
                      label: "Next",
                      icon: undefined
                    }],
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }), React.createElement(ProductTour.Step.make, {
                  stepId: "step-2",
                  stepType: {
                    TAG: /* Modal */2,
                    _0: {
                      NAME: "num",
                      VAL: 320
                    }
                  },
                  delay: 0,
                  transitions: [{
                      key: "complete",
                      label: "Complete",
                      icon: undefined
                    }],
                  children: React.createElement(ProductTour.GenericStep.make, {})
                }));
}

var TestFlow = {
  make: ProductTours$TestFlow
};

function ProductTours$DemoBranchWarning(Props) {
  var match = React$1.useFlow("demo-branch-product-tour");
  var demoBranchProductFlow = match[0];
  var branch = RouterStore.Schema.useBranch(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var showModal;
  if (typeof branch === "object" && branch.NAME === "branch") {
    var userId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
    showModal = branch.VAL === "demo-" + userId && schemaRoute !== "dashboard" && (demoBranchProductFlow.state.finished || demoBranchProductFlow.state.stopped);
  } else {
    showModal = false;
  }
  return React.createElement(ProductTour.make, {
              tourId: "demo-branch-warning",
              tourMayShow: showModal,
              children: React.createElement(ProductTour.Step.make, {
                    stepId: "warning-modal",
                    stepType: {
                      TAG: /* Modal */2,
                      _0: {
                        NAME: "num",
                        VAL: 550
                      }
                    },
                    delay: 750,
                    transitions: [{
                        key: "complete",
                        label: "Got it!",
                        icon: undefined
                      }],
                    children: React.createElement(ProductTour.GenericModal.make, {})
                  })
            });
}

var DemoBranchWarning = {
  make: ProductTours$DemoBranchWarning
};

function ProductTours(Props) {
  return React.createElement(React.Fragment, undefined, React.createElement(ProductTours$TestFlow, {}), React.createElement(DemoBranchProductTour.make, {}), React.createElement(ProductTours$DemoBranchWarning, {}), React.createElement(InspectorIssuesOnboardingFlow.make, {}), React.createElement(HowToSaveViewAndGetAlertsFlow.make, {}));
}

var make = ProductTours;

exports.TestFlow = TestFlow;
exports.DemoBranchWarning = DemoBranchWarning;
exports.make = make;
/* react Not a pure module */
