// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function cmp(a, b) {
  return a === b;
}

function widowless(str) {
  return str.replace(/(\s|^)(\S+)$/, String.fromCharCode(160) + "$2");
}

exports.cmp = cmp;
exports.widowless = widowless;
/* No side effect */
