// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var IconArrowUp = require("./IconArrowUp.bs.js");
var IconArrowDown = require("./IconArrowDown.bs.js");
var CodegenCommons = require("../../codegen/src/CodegenCommons.bs.js");
var PropertyValidations = require("./PropertyValidations.bs.js");
var PropertyAbsenceConfig = require("./PropertyAbsenceConfig.bs.js");
var PropertyAbsenceViewHelpers = require("./PropertyAbsenceViewHelpers.bs.js");

var typeStyles = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.monoFontFamily),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.regular),
        tl: {
          hd: Css.fontSize(Css.px(11)),
          tl: {
            hd: Css.padding2(Css.px(4), Css.px(8)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.grey20),
              tl: {
                hd: Css.borderRadius(Css.px(4)),
                tl: {
                  hd: Css.display("inlineBlock"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function PropertyType$TypePill(Props) {
  var children = Props.children;
  return React.createElement("span", {
              className: typeStyles
            }, children);
}

var TypePill = {
  typeStyles: typeStyles,
  make: PropertyType$TypePill
};

var offsetCodeLine = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(8)),
      tl: {
        hd: Css.fontFamily(Styles.monoFontFamily),
        tl: /* [] */0
      }
    });

var monoFont = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.monoFontFamily),
      tl: /* [] */0
    });

var expandButton = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(4)),
      tl: {
        hd: Css.backgroundColor(Css.transparent),
        tl: {
          hd: Css.borderWidth(Css.px(0)),
          tl: {
            hd: Css.cursor("pointer"),
            tl: {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.grey30),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var typeStyles$1 = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.monoFontFamily),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.regular),
        tl: {
          hd: Css.fontSize(Css.px(11)),
          tl: {
            hd: Css.padding2(Css.px(4), Css.px(8)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.grey20),
              tl: {
                hd: Css.borderRadius(Css.px(4)),
                tl: {
                  hd: Css.display("block"),
                  tl: {
                    hd: Css.width(Css.pct(100)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function PropertyType$ObjectPill(Props) {
  var children = Props.children;
  var isList = Props.isList;
  var match = React.useState(function () {
        return false;
      });
  var setExpanded = match[1];
  if (match[0]) {
    return React.createElement("div", {
                className: typeStyles$1,
                onClick: (function (param) {
                    return Curry._1(setExpanded, (function (expanded) {
                                  return !expanded;
                                }));
                  })
              }, Belt_Array.concatMany([
                    [
                      React.createElement("div", {
                            className: monoFont
                          }, isList ? "list of objects" : "object", React.createElement("button", {
                                className: expandButton
                              }, React.createElement(IconArrowUp.make, {
                                    size: 12,
                                    color: Styles.Color.grey40
                                  }))),
                      React.createElement("div", {
                            className: monoFont
                          }, "{")
                    ],
                    Belt_Array.mapU(children, (function (child) {
                            return React.createElement("div", {
                                        className: offsetCodeLine
                                      }, child);
                          })),
                    [React.createElement("div", {
                            className: monoFont
                          }, isList ? "}[]" : "}")]
                  ]));
  } else {
    return React.createElement("span", {
                className: typeStyles,
                onClick: (function (param) {
                    return Curry._1(setExpanded, (function (expanded) {
                                  return !expanded;
                                }));
                  })
              }, isList ? "list of objects" : "object", React.createElement("button", {
                    className: expandButton
                  }, React.createElement(IconArrowDown.make, {
                        size: 12,
                        color: Styles.Color.grey40
                      })));
  }
}

var ObjectPill = {
  offsetCodeLine: offsetCodeLine,
  monoFont: monoFont,
  expandButton: expandButton,
  typeStyles: typeStyles$1,
  make: PropertyType$ObjectPill
};

function PropertyType$TypeAnnotation(Props) {
  var property = Props.property;
  var model = Props.model;
  var language = Props.language;
  var $$event = Props.event;
  var isObject = property.type_ === "object";
  var validations = property.validations;
  var objectFields = validations ? Belt_List.keepMap(validations, (function (validation) {
            if (typeof validation === "object" && validation.NAME === "NestedProperty") {
              return CodegenCommons.objectPropertyTypeSignature(property.id, validation.VAL, false, model, language, Caml_option.some(Belt_Option.mapU($$event, (function ($$event) {
                                    return $$event.id;
                                  }))), undefined);
            }
            
          })) : /* [] */0;
  if (isObject) {
    return React.createElement(PropertyType$ObjectPill, {
                children: Belt_List.toArray(Belt_List.flatten(objectFields)),
                isList: property.list
              });
  } else {
    return React.createElement(PropertyType$TypePill, {
                children: (
                  property.list ? "list of " : ""
                ) + property.type_
              });
  }
}

var TypeAnnotation = {
  make: PropertyType$TypeAnnotation
};

function PropertyType(Props) {
  var model = Props.model;
  var property = Props.property;
  var language = Props.language;
  var $$event = Props.event;
  var skipMatchesOpt = Props.skipMatches;
  var skipMatches = skipMatchesOpt !== undefined ? skipMatchesOpt : false;
  return React.createElement(React.Fragment, undefined, React.createElement(PropertyType$TypeAnnotation, {
                  property: property,
                  model: model,
                  language: Belt_Option.getWithDefault(language, "js"),
                  event: $$event
                }), skipMatches || property.validations === /* [] */0 ? null : React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginLeft(Css.px(4)),
                          tl: {
                            hd: Css.marginRight(Css.px(4)),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(PropertyValidations.make, {
                        validations: property.validations
                      })), Belt_Option.mapWithDefault(property.absence, null, (function (absence) {
                    return React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("inlineFlex"),
                                      tl: {
                                        hd: Css.flexShrink(0.0),
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.createElement($$Text.make, {
                                    element: "Span",
                                    size: "Small",
                                    weight: "Regular",
                                    color: Styles.Color.grey70,
                                    children: $$event !== undefined ? PropertyAbsenceConfig.absenceModeToJs(PropertyAbsenceConfig.getSimplestAbsenceModeByEvent(absence, model.sources, $$event, property.sendAs)) : Belt_Option.getWithDefault(PropertyAbsenceViewHelpers.getPropertyAbsenceString(model, undefined, property, undefined), "Always sent")
                                  }));
                  })));
}

var make = PropertyType;

exports.TypePill = TypePill;
exports.ObjectPill = ObjectPill;
exports.TypeAnnotation = TypeAnnotation;
exports.make = make;
/* typeStyles Not a pure module */
