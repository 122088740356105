// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Case = require("../../../bs-case/src/case.bs.js");
var React = require("react");
var Router = require("../Router.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Js_types = require("rescript/lib/js/js_types.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var React$1 = require("@dopt/react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var UsersJavascriptBrowserClient = require("@dopt/users-javascript-browser-client");

var Configuration = {};

var IdentifyUserRequestBody = {};

var IdentifyGroupRequestBody = {};

var AssociateUserWithGroupRequestBody = {};

var Client = {};

var client = {
  contents: undefined
};

var retainedUserId = {
  contents: undefined
};

function init(apiKey) {
  client.contents = Caml_option.some(new UsersJavascriptBrowserClient.DoptApiClient({
            apiKey: apiKey
          }));
  
}

function transformKeys(json, $$case) {
  var jsons = Js_json.classify(json);
  if (typeof jsons === "number") {
    return json;
  }
  switch (jsons.TAG | 0) {
    case /* JSONObject */2 :
        var dict = jsons._0;
        if (typeof dict === "undefined") {
          return null;
        } else {
          return Belt_Array.reduceU(Object.keys(dict), {}, (function (newDict, key) {
                        newDict[Case.to_($$case, key)] = Belt_Option.mapWithDefault(Js_dict.get(dict, key), null, (function (json) {
                                return transformKeys(json, $$case);
                              }));
                        return newDict;
                      }));
        }
    case /* JSONArray */3 :
        var jsons$1 = jsons._0;
        if (typeof jsons$1 === "undefined") {
          return null;
        } else {
          return Belt_Array.mapU(jsons$1, (function (json) {
                        return transformKeys(json, $$case);
                      }));
        }
    default:
      return json;
  }
}

function identifyUser(userId) {
  var client$1 = client.contents;
  if (client$1 !== undefined) {
    retainedUserId.contents = userId;
    Caml_option.valFromOption(client$1).users.identifyUser({
          identifier: userId,
          properties: {}
        });
    return ;
  }
  
}

function associateUserWithGroup(groupId, groupProperties) {
  var match = client.contents;
  var match$1 = retainedUserId.contents;
  if (match === undefined) {
    return ;
  }
  if (match$1 === undefined) {
    return ;
  }
  var client$1 = Caml_option.valFromOption(match);
  try {
    client$1.users.identifyUser({
          identifier: match$1,
          properties: {},
          groups: [{
              identifier: groupId,
              properties: transformKeys(groupProperties, "Camel")
            }]
        });
    return ;
  }
  catch (exn){
    client$1.users.identifyUser({
          identifier: match$1,
          properties: {},
          groups: [{
              identifier: groupId,
              properties: groupProperties
            }]
        });
    return ;
  }
}

function setUserProperties(userId, userProperties) {
  var client$1 = client.contents;
  if (client$1 === undefined) {
    return ;
  }
  var client$2 = Caml_option.valFromOption(client$1);
  try {
    client$2.users.identifyUser({
          identifier: userId,
          properties: transformKeys(userProperties, "Camel")
        });
    return ;
  }
  catch (exn){
    client$2.users.identifyUser({
          identifier: userId,
          properties: userProperties
        });
    return ;
  }
}

var UsersClient = {
  Configuration: Configuration,
  IdentifyUserRequestBody: IdentifyUserRequestBody,
  IdentifyGroupRequestBody: IdentifyGroupRequestBody,
  AssociateUserWithGroupRequestBody: AssociateUserWithGroupRequestBody,
  Client: Client,
  client: client,
  retainedUserId: retainedUserId,
  init: init,
  transformKeys: transformKeys,
  identifyUser: identifyUser,
  associateUserWithGroup: associateUserWithGroup,
  setUserProperties: setUserProperties,
  setGroupProperties: associateUserWithGroup
};

var Provider = {};

function Dopt$CustomProvider(Props) {
  var apiKey = Props.apiKey;
  var flowVersions = Props.flowVersions;
  var userId = Props.userId;
  var groupId = Props.groupId;
  var children = Props.children;
  var match = Router.QueryParams.useKey("dopt");
  var value = match.value;
  var customFlows = React.useMemo((function () {
          return Belt_Option.mapWithDefault(value, {}, (function (value) {
                        return Js_dict.fromArray(Belt_Array.keepMap(value.split(","), (function (f) {
                                          var match = f.split("__");
                                          if (match.length !== 2) {
                                            return ;
                                          }
                                          var flowId = match[0];
                                          var version = match[1];
                                          return Belt_Option.map(Belt_Int.fromString(version), (function (version) {
                                                        return [
                                                                flowId,
                                                                version
                                                              ];
                                                      }));
                                        })));
                      }));
        }), []);
  var flowVersions$1 = React.useMemo((function () {
          return Object.assign(flowVersions, customFlows);
        }), [customFlows]);
  var tmp = {
    apiKey: apiKey,
    flowVersions: flowVersions$1,
    userId: userId,
    children: children
  };
  if (groupId !== undefined) {
    tmp.groupId = groupId;
  }
  return React.createElement(React$1.DoptProvider, tmp);
}

var CustomProvider = {
  make: Dopt$CustomProvider
};

function fromField(str) {
  return str;
}

var RichText = {
  fromField: fromField
};

function fieldWithDefault(t, str, defaultValue) {
  return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(t.field(str)), defaultValue);
}

function classifyField(field) {
  var _rt = Js_types.classify(field);
  if (typeof _rt === "number") {
    switch (_rt) {
      case /* JSFalse */0 :
          return {
                  NAME: "boolean",
                  VAL: false
                };
      case /* JSTrue */1 :
          return {
                  NAME: "boolean",
                  VAL: true
                };
      default:
        return "undefined";
    }
  } else {
    switch (_rt.TAG | 0) {
      case /* JSNumber */0 :
          return {
                  NAME: "number",
                  VAL: _rt._0
                };
      case /* JSString */1 :
          return {
                  NAME: "string",
                  VAL: _rt._0
                };
      case /* JSObject */3 :
          return {
                  NAME: "richText",
                  VAL: field
                };
      default:
        return "undefined";
    }
  }
}

function field(t, str) {
  return Belt_Option.map(Caml_option.nullable_to_opt(t.field(str)), classifyField);
}

var emptyBlock_state = {
  active: false,
  entered: false,
  exited: false
};

var emptyBlock_fields = [];

var emptyBlock_transitioned = {};

var emptyBlock = {
  state: emptyBlock_state,
  fields: emptyBlock_fields,
  transitioned: emptyBlock_transitioned,
  kind: "block",
  uid: "",
  sid: "",
  version: 0,
  containerUid: undefined,
  type: "custom"
};

var Block = {
  fieldWithDefault: fieldWithDefault,
  classifyField: classifyField,
  field: field,
  emptyBlock: emptyBlock
};

var Flow = {};

function getBlocksApiKey(env) {
  if (env === "development") {
    return "blocks-17e5aae0a713e50677e4759ba6b29ebd8922b4dac20293adef263f9f4fe81e22_MjQz";
  } else {
    return "blocks-eccc77a321f041097414d34fb0c361f2e5362b3a19bbaa68c4e7da9aa28fee10_MjYz";
  }
}

exports.UsersClient = UsersClient;
exports.Provider = Provider;
exports.CustomProvider = CustomProvider;
exports.RichText = RichText;
exports.Block = Block;
exports.Flow = Flow;
exports.getBlocksApiKey = getBlocksApiKey;
/* Case Not a pure module */
