// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var SimpleTable = require("../SimpleTable.bs.js");
var IssuesTableConfig = require("./IssuesTableConfig.bs.js");

var IssuesTable = SimpleTable.Make({
      tableName: IssuesTableConfig.tableName,
      getRowId: IssuesTableConfig.getRowId,
      defaultOrder: IssuesTableConfig.defaultOrder
    });

var getColumnName = IssuesTable.getColumnName;

var getColumnId = IssuesTable.getColumnId;

var getColumnWidth = IssuesTable.getColumnWidth;

var getColumnCompare = IssuesTable.getColumnCompare;

var getColumnDefaultOrder = IssuesTable.getColumnDefaultOrder;

var getColumnAlignment = IssuesTable.getColumnAlignment;

var getColumnCompareFromId = IssuesTable.getColumnCompareFromId;

var getDefaultOrder = IssuesTable.getDefaultOrder;

var isOrderedBy = IssuesTable.isOrderedBy;

var getStyles = IssuesTable.getStyles;

var addCentered = IssuesTable.addCentered;

var addHoverable = IssuesTable.addHoverable;

var TableContext = IssuesTable.TableContext;

var ActiveRowIdContext = IssuesTable.ActiveRowIdContext;

var VirtualizedTable = IssuesTable.VirtualizedTable;

var ColumnHeader = IssuesTable.ColumnHeader;

var debounceFunction = IssuesTable.debounceFunction;

var make = IssuesTable.make;

exports.IssuesTable = IssuesTable;
exports.getColumnName = getColumnName;
exports.getColumnId = getColumnId;
exports.getColumnWidth = getColumnWidth;
exports.getColumnCompare = getColumnCompare;
exports.getColumnDefaultOrder = getColumnDefaultOrder;
exports.getColumnAlignment = getColumnAlignment;
exports.getColumnCompareFromId = getColumnCompareFromId;
exports.getDefaultOrder = getDefaultOrder;
exports.isOrderedBy = isOrderedBy;
exports.getStyles = getStyles;
exports.addCentered = addCentered;
exports.addHoverable = addHoverable;
exports.TableContext = TableContext;
exports.ActiveRowIdContext = ActiveRowIdContext;
exports.VirtualizedTable = VirtualizedTable;
exports.ColumnHeader = ColumnHeader;
exports.debounceFunction = debounceFunction;
exports.make = make;
/* IssuesTable Not a pure module */
