// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function IconAddComment(Props) {
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  return React.createElement("svg", {
              width: String(size),
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  strokeLinecap: "square",
                  strokeLinejoin: "miter",
                  strokeWidth: "2"
                }, React.createElement("polyline", {
                      fill: "none",
                      points: "4 11 4 21 10 16 23 16 23 4 11 4",
                      strokeMiterlimit: "10"
                    }), React.createElement("line", {
                      fill: "none",
                      strokeMiterlimit: "10",
                      x1: "4",
                      x2: "4",
                      y1: "1",
                      y2: "7"
                    }), React.createElement("line", {
                      fill: "none",
                      strokeMiterlimit: "10",
                      x1: "1",
                      x2: "7",
                      y1: "4",
                      y2: "4"
                    })));
}

var make = IconAddComment;

exports.make = make;
/* react Not a pure module */
