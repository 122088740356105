// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("../../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("../stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var BeltArrayExtensions = require("../BeltArrayExtensions.bs.js");
var PropertyValidationUtils = require("../PropertyValidationUtils.bs.js");

function filterOutEventsThatAreAlreadyInTheModelAndDontContainAnythingNew(model, parsedModel) {
  var getSameEventInModel = function ($$event) {
    return Belt_List.getByU(model.events, (function (modelEvent) {
                  return modelEvent.name === $$event.name;
                }));
  };
  var isPropertyStrictlyEqual = function (importProperty, modelProperty) {
    if (importProperty.name === modelProperty.name && importProperty.description === modelProperty.description && importProperty.type_ === modelProperty.type_ && importProperty.list === modelProperty.list && Caml_obj.caml_equal(importProperty.sendAs, modelProperty.sendAs)) {
      return BeltArrayExtensions.compareUnorderedStringArrays(importProperty.matches, Belt_List.toArray(PropertyValidationUtils.getPropertyMatches(modelProperty)));
    } else {
      return false;
    }
  };
  return Belt_Array.reduceU(parsedModel.events, [
              [],
              [],
              []
            ], (function (param, $$event) {
                var filteredOutEvents = param[2];
                var updatedEvents = param[1];
                var newEvents = param[0];
                var modelEvent = getSameEventInModel($$event);
                if (modelEvent !== undefined) {
                  var importedProperties = Belt_Array.keepU(parsedModel.properties, (function (property) {
                          return Belt_Array.someU($$event.propertyIds, (function (param) {
                                        return param[0] === property.id;
                                      }));
                        }));
                  var existingPropertiesInTrackingPlan = Belt_List.keepMapU(modelEvent.properties, (function (property) {
                          return StateUtils.resolveProperty(model, property);
                        }));
                  var existingPropertyNamesInTrackingPlan = Belt_List.map(existingPropertiesInTrackingPlan, (function (param) {
                          return param.name;
                        }));
                  var newPropertiesOnEvent = Belt_Array.keepU(importedProperties, (function (property) {
                          return Belt_List.everyU(existingPropertyNamesInTrackingPlan, (function (propertyName) {
                                        return propertyName !== property.name;
                                      }));
                        }));
                  var updatedPropertiesOnEvent = Belt_Array.keepMapU(importedProperties, (function (importProperty) {
                          return Belt_Option.flatMap(Belt_List.getBy(existingPropertiesInTrackingPlan, (function (modelProperty) {
                                            if (modelProperty.name === importProperty.name) {
                                              return !isPropertyStrictlyEqual(importProperty, modelProperty);
                                            } else {
                                              return false;
                                            }
                                          })), (function (modelProperty) {
                                        return {
                                                id: modelProperty.id,
                                                name: importProperty.name,
                                                uniqueNameIndex: importProperty.uniqueNameIndex,
                                                description: importProperty.description,
                                                type_: importProperty.type_,
                                                list: importProperty.list,
                                                matches: importProperty.matches,
                                                warnings: importProperty.warnings,
                                                presence: importProperty.presence,
                                                sendAs: importProperty.sendAs,
                                                nameMapping: importProperty.nameMapping
                                              };
                                      }));
                        }));
                  var existingCategoriesOnEventInTrackingPlan = Belt_Array.map(StateUtils.getGoalsWithEvent(modelEvent, model), (function (goal) {
                          return goal.name;
                        }));
                  var newCategoriesOnEvent = Belt_Array.keepU($$event.categories, (function (category) {
                          return Belt_Array.everyU(existingCategoriesOnEventInTrackingPlan, (function (existingCategory) {
                                        return existingCategory !== category;
                                      }));
                        }));
                  var newTagsOnEvent = Belt_Array.keepU($$event.tags, (function (tag) {
                          return Belt_List.everyU(modelEvent.tags, (function (existingTag) {
                                        return existingTag !== tag;
                                      }));
                        }));
                  var newSourcesOnEvent = Belt_Array.keepU($$event.sources, (function (importedSourceName) {
                          return Belt_List.everyU(modelEvent.includeSources, (function (param) {
                                        return AvoConfig.getSourceNameById(model, param.id) !== importedSourceName;
                                      }));
                        }));
                  if (newPropertiesOnEvent.length !== 0 || updatedPropertiesOnEvent.length !== 0 || newCategoriesOnEvent.length !== 0 || newTagsOnEvent.length !== 0 || newSourcesOnEvent.length !== 0) {
                    return [
                            newEvents,
                            Belt_Array.concat(updatedEvents, [{
                                    id: modelEvent.id,
                                    name: $$event.name,
                                    uniqueNameIndex: $$event.uniqueNameIndex,
                                    description: $$event.description,
                                    categories: newCategoriesOnEvent,
                                    tags: newTagsOnEvent,
                                    propertyIds: Belt_Array.map(newPropertiesOnEvent, (function (property) {
                                            return [
                                                    property.id,
                                                    undefined
                                                  ];
                                          })),
                                    updatedPropertyIds: Belt_Array.map(updatedPropertiesOnEvent, (function (param) {
                                            return [
                                                    param.id,
                                                    undefined
                                                  ];
                                          })),
                                    propertyGroupIds: $$event.propertyGroupIds,
                                    sources: newSourcesOnEvent,
                                    warnings: $$event.warnings,
                                    nameMapping: $$event.nameMapping
                                  }]),
                            filteredOutEvents
                          ];
                  } else {
                    return [
                            newEvents,
                            updatedEvents,
                            Belt_Array.concat(filteredOutEvents, [/* EventAlreadyInTrackingPlan */{
                                    _0: $$event,
                                    _1: modelEvent
                                  }])
                          ];
                  }
                }
                var importedProperties$1 = Belt_Array.keepU(parsedModel.properties, (function (property) {
                        return Belt_Array.someU($$event.propertyIds, (function (param) {
                                      return param[0] === property.id;
                                    }));
                      }));
                var updatedPropertiesOnEvent$1 = Belt_Array.keepMapU(importedProperties$1, (function (importProperty) {
                        return Belt_Option.flatMap(Belt_List.getBy(Belt_List.keepMap(Belt_List.map(model.properties, (function (p) {
                                                  return StateUtils.resolveProperty(model, p);
                                                })), (function (mp) {
                                              return mp;
                                            })), (function (modelProperty) {
                                          if (modelProperty.name === importProperty.name) {
                                            return !isPropertyStrictlyEqual(importProperty, modelProperty);
                                          } else {
                                            return false;
                                          }
                                        })), (function (modelProperty) {
                                      return {
                                              id: modelProperty.id,
                                              name: importProperty.name,
                                              uniqueNameIndex: importProperty.uniqueNameIndex,
                                              description: importProperty.description,
                                              type_: importProperty.type_,
                                              list: importProperty.list,
                                              matches: importProperty.matches,
                                              warnings: importProperty.warnings,
                                              presence: importProperty.presence,
                                              sendAs: importProperty.sendAs,
                                              nameMapping: importProperty.nameMapping
                                            };
                                    }));
                      }));
                return [
                        Belt_Array.concat(newEvents, [{
                                id: $$event.id,
                                name: $$event.name,
                                uniqueNameIndex: $$event.uniqueNameIndex,
                                description: $$event.description,
                                categories: $$event.categories,
                                tags: $$event.tags,
                                propertyIds: $$event.propertyIds,
                                updatedPropertyIds: Belt_Array.map(updatedPropertiesOnEvent$1, (function (param) {
                                        return [
                                                param.id,
                                                undefined
                                              ];
                                      })),
                                propertyGroupIds: $$event.propertyGroupIds,
                                sources: $$event.sources,
                                warnings: $$event.warnings,
                                nameMapping: $$event.nameMapping
                              }]),
                        updatedEvents,
                        filteredOutEvents
                      ];
              }));
}

function filterOutPropertieThatAreOnlyOnFilteredOutEvents(parsedProperties, parsedEvents, filteredOutEvents) {
  return Belt_Array.reduceU(parsedProperties, [
              [],
              []
            ], (function (param, property) {
                var filteredOutProperties = param[1];
                var newProperties = param[0];
                var isPropertyOnSomeNewEvent = property.sendAs === /* UserProperty */2 || Belt_Array.someU(parsedEvents, (function (newEvent) {
                        return Belt_Array.someU(newEvent.propertyIds, (function (param) {
                                      return param[0] === property.id;
                                    }));
                      }));
                var isPropertyOnFilteredOutEvent = Belt_Array.some(filteredOutEvents, (function (filteredEvent) {
                        return Belt_Array.some(filteredEvent._0.propertyIds, (function (param) {
                                      return param[0] === property.id;
                                    }));
                      }));
                if (!isPropertyOnFilteredOutEvent || isPropertyOnSomeNewEvent) {
                  return [
                          Belt_Array.concat(newProperties, [property]),
                          filteredOutProperties
                        ];
                }
                var filteredOutEventsWithThisProperty = Belt_Array.keepMapU(filteredOutEvents, (function (filteredOutEventInfo) {
                        var filteredOutEvent = filteredOutEventInfo._0;
                        var isPropertyOnEvent = Belt_Array.someU(filteredOutEvent.propertyIds, (function (param) {
                                return param[0] === property.id;
                              }));
                        if (isPropertyOnEvent) {
                          return [
                                  filteredOutEvent,
                                  filteredOutEventInfo._1
                                ];
                        }
                        
                      }));
                return [
                        newProperties,
                        Belt_Array.concat(filteredOutProperties, [{
                                TAG: /* AllParentEventsWereFilteredOut */1,
                                _0: property,
                                _1: filteredOutEventsWithThisProperty
                              }])
                      ];
              }));
}

function getSamePropertyInModel(model, property) {
  return Belt_List.getByU(model.properties, (function (modelPropertyRef) {
                return Belt_Option.getWithDefault(Belt_Option.mapU(StateUtils.resolveProperty(model, modelPropertyRef), (function (modelProperty) {
                                  if (modelProperty.name === property.name && modelProperty.type_ === property.type_) {
                                    return modelProperty.list === property.list;
                                  } else {
                                    return false;
                                  }
                                })), false);
              }));
}

function filterOutPropertiesThatAreAlreadyInTheModel(model, newProperties, allParsedProperties) {
  var parsedPropertiesNotNew = Belt_Array.keepU(allParsedProperties, (function (property) {
          return Belt_Option.isNone(Belt_Array.getByU(newProperties, (function (parsedProperty) {
                            if (parsedProperty.id === property.id) {
                              return !BeltArrayExtensions.compareUnorderedStringArrays(parsedProperty.matches, property.matches);
                            } else {
                              return false;
                            }
                          })));
        }));
  var updatedProperties = Belt_Array.reduceU(parsedPropertiesNotNew, [], (function (updatedProperties, property) {
          var match = getSamePropertyInModel(model, property);
          if (match === undefined) {
            return updatedProperties;
          }
          if (match.TAG === /* PropertyRef */0) {
            return updatedProperties;
          }
          var modelProperty = match._0;
          if (BeltArrayExtensions.compareUnorderedStringArrays(Belt_List.toArray(PropertyValidationUtils.getPropertyMatches(modelProperty)), property.matches)) {
            return updatedProperties;
          } else {
            return Belt_Array.concat(updatedProperties, [{
                          id: modelProperty.id,
                          name: property.name,
                          uniqueNameIndex: property.uniqueNameIndex,
                          description: property.description,
                          type_: property.type_,
                          list: property.list,
                          matches: property.matches,
                          warnings: property.warnings,
                          presence: property.presence,
                          sendAs: property.sendAs,
                          nameMapping: property.nameMapping
                        }]);
          }
        }));
  var match = Belt_Array.reduceU(newProperties, [
        [],
        []
      ], (function (param, property) {
          var filteredOutProperties = param[1];
          var newProperties = param[0];
          var modelProperty = getSamePropertyInModel(model, property);
          if (modelProperty !== undefined) {
            return [
                    newProperties,
                    Belt_Array.concat(filteredOutProperties, [{
                            TAG: /* PropertyAlreadyInTrackingPlan */0,
                            _0: property,
                            _1: modelProperty
                          }])
                  ];
          } else {
            return [
                    Belt_Array.concat(newProperties, [property]),
                    filteredOutProperties
                  ];
          }
        }));
  return [
          match[0],
          updatedProperties,
          match[1]
        ];
}

function updatePropertyIdsOnEventsAfterFiltering(model, filteredOutParsedProperties, parsedEvents) {
  var getFilteredModelProperty = function (parsedPropertyId) {
    return Belt_Array.get(Belt_Array.keepMapU(filteredOutParsedProperties, (function (filteredOutPropertyInfo) {
                      if (filteredOutPropertyInfo.TAG === /* PropertyAlreadyInTrackingPlan */0 && filteredOutPropertyInfo._0.id === parsedPropertyId) {
                        return StateUtils.resolveProperty(model, filteredOutPropertyInfo._1);
                      }
                      
                    })), 0);
  };
  return Belt_Array.mapU(parsedEvents, (function ($$event) {
                return {
                        id: $$event.id,
                        name: $$event.name,
                        uniqueNameIndex: $$event.uniqueNameIndex,
                        description: $$event.description,
                        categories: $$event.categories,
                        tags: $$event.tags,
                        propertyIds: Belt_Array.mapU($$event.propertyIds, (function (param) {
                                var pinnedValue = param[1];
                                var oldPropId = param[0];
                                var modelProperty = getFilteredModelProperty(oldPropId);
                                if (modelProperty !== undefined) {
                                  return [
                                          modelProperty.id,
                                          pinnedValue
                                        ];
                                } else {
                                  return [
                                          oldPropId,
                                          pinnedValue
                                        ];
                                }
                              })),
                        updatedPropertyIds: $$event.updatedPropertyIds,
                        propertyGroupIds: $$event.propertyGroupIds,
                        sources: $$event.sources,
                        warnings: $$event.warnings,
                        nameMapping: $$event.nameMapping
                      };
              }));
}

function replacePropertyRefsWithPropertyGroupRefs(model, parsedEvents, parsedPropertyGroups) {
  return Belt_Array.map(parsedEvents, (function ($$event) {
                var modelPropertyGroupsForEvent = Belt_Array.keepU(Belt_List.toArray(model.propertyBundles), (function (propertyGroup) {
                        return Belt_Array.everyU(Belt_List.toArray(propertyGroup.properties), (function (propertyId) {
                                      return Belt_Array.someU($$event.propertyIds, (function (param) {
                                                    return param[0] === propertyId;
                                                  }));
                                    }));
                      }));
                var parsedPropertyGroupsForEvent = Belt_Array.keepU(parsedPropertyGroups, (function (propertyGroup) {
                        return Belt_Array.everyU(propertyGroup.properties, (function (propertyGroupProperty) {
                                      return Belt_Array.someU($$event.propertyIds, (function (param) {
                                                    return param[0] === propertyGroupProperty.id;
                                                  }));
                                    }));
                      }));
                var modelPropertyIdsInPropertyGroupsForEvent = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.mapU(modelPropertyGroupsForEvent, (function (propertyGroup) {
                                    return Belt_List.toArray(propertyGroup.properties);
                                  })))));
                var parsedPropertyIdsInPropertyGroupsForEvent = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.mapU(parsedPropertyGroupsForEvent, (function (propertyGroup) {
                                    return Belt_Array.map(propertyGroup.properties, (function (property) {
                                                  return property.id;
                                                }));
                                  })))));
                var propertyIdsInPropertyGroupsForEvent = Belt_Array.concat(modelPropertyIdsInPropertyGroupsForEvent, parsedPropertyIdsInPropertyGroupsForEvent);
                var propertyGroupIdsForEvent = Belt_Array.concat(Belt_Array.map(modelPropertyGroupsForEvent, (function (group) {
                            return group.id;
                          })), Belt_Array.map(parsedPropertyGroupsForEvent, (function (group) {
                            return group.id;
                          })));
                return {
                        id: $$event.id,
                        name: $$event.name,
                        uniqueNameIndex: $$event.uniqueNameIndex,
                        description: $$event.description,
                        categories: $$event.categories,
                        tags: $$event.tags,
                        propertyIds: Belt_Array.keepU($$event.propertyIds, (function (param) {
                                var propertyId = param[0];
                                return Belt_Array.everyU(propertyIdsInPropertyGroupsForEvent, (function (propertyIdInGroup) {
                                              return propertyIdInGroup !== propertyId;
                                            }));
                              })),
                        updatedPropertyIds: $$event.updatedPropertyIds,
                        propertyGroupIds: propertyGroupIdsForEvent,
                        sources: $$event.sources,
                        warnings: $$event.warnings,
                        nameMapping: $$event.nameMapping
                      };
              }));
}

var Filtering = {
  filterOutEventsThatAreAlreadyInTheModelAndDontContainAnythingNew: filterOutEventsThatAreAlreadyInTheModelAndDontContainAnythingNew,
  filterOutPropertieThatAreOnlyOnFilteredOutEvents: filterOutPropertieThatAreOnlyOnFilteredOutEvents,
  getSamePropertyInModel: getSamePropertyInModel,
  filterOutPropertiesThatAreAlreadyInTheModel: filterOutPropertiesThatAreAlreadyInTheModel,
  updatePropertyIdsOnEventsAfterFiltering: updatePropertyIdsOnEventsAfterFiltering,
  replacePropertyRefsWithPropertyGroupRefs: replacePropertyRefsWithPropertyGroupRefs
};

exports.Filtering = Filtering;
/* AvoConfig Not a pure module */
