// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Case = require("../../bs-case/src/case.bs.js");
var $$Number = require("../../app/src/Number.bs.js");
var AvoModel = require("../../app/src/avoModel.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("./AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameUtils = require("./NameUtils.bs.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var StateUtils = require("../../app/src/stateUtils.bs.js");
var AnalyticsRe = require("../../app/src/analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var AnalyticsUtils = require("../../app/src/analyticsUtils.bs.js");
var BeltListExtensions = require("../../app/src/BeltListExtensions.bs.js");

function renderLanguageFileExtension(language) {
  if (language !== undefined) {
    if (language === "java") {
      return "java";
    } else if (language === "json") {
      return "json";
    } else if (language === "objc") {
      return "m";
    } else if (language === "js_v2") {
      return "js";
    } else if (language === "re_v2") {
      return "re";
    } else if (language === "cs") {
      return "cs";
    } else if (language === "go") {
      return "go";
    } else if (language === "re" || language === "js") {
      return "js";
    } else if (language === "kt") {
      return "kt";
    } else if (language === "py3" || language === "py") {
      return "py";
    } else if (language === "rb") {
      return "rb";
    } else if (language === "ts") {
      return "ts";
    } else if (language === "php") {
      return "php";
    } else if (language === "swift") {
      return "swift";
    } else {
      return "dart";
    }
  } else {
    return "js";
  }
}

function renderFilename(name, language) {
  return name + "." + renderLanguageFileExtension(language);
}

function renderLibraryDestination(path, name, language) {
  return path + "/" + renderFilename(name, language);
}

function getDestinationsSupport(platform, language, destinations) {
  return Belt_List.map(Belt_List.keepMap(destinations, (function (destination) {
                    var type_ = destination.type_;
                    if (type_ !== undefined) {
                      return [
                              type_,
                              destination
                            ];
                    }
                    
                  })), (function (param) {
                var type_ = param[0];
                return [
                        type_,
                        param[1],
                        Belt_List.has(AvoConfig.getSupportedDestinations(platform, language), type_, (function (prim0, prim1) {
                                return prim0 === prim1;
                              }))
                      ];
              }));
}

function isDestinationSupportedForSource(sourcePlatform, sourceLanguage, destinationType) {
  return Belt_List.has(AvoConfig.getSupportedDestinations(sourcePlatform, sourceLanguage), destinationType, (function (prim0, prim1) {
                return prim0 === prim1;
              }));
}

function filterInvalidSources(destination, sources) {
  return Belt_List.keep(sources, (function (source) {
                return Belt_List.has(AvoConfig.getSupportedDestinations(source.platform, source.language), destination, (function (prim0, prim1) {
                              return prim0 === prim1;
                            }));
              }));
}

function filterInvalidDestinations(sourcePlatform, sourceLanguage, sourceDestinations, allDestinations) {
  return Belt_List.keep(Belt_List.keepMap(sourceDestinations, (function (id) {
                    return Belt_List.head(Belt_List.keep(allDestinations, (function (destination) {
                                      return destination.id === id;
                                    })));
                  })), (function (destination) {
                var destinationType = destination.type_;
                if (destinationType !== undefined) {
                  return isDestinationSupportedForSource(sourcePlatform, sourceLanguage, destinationType);
                } else {
                  return false;
                }
              }));
}

function getAnalyticsPropertyGroupFromCommonCase(commonCase, property) {
  var correctCase = NameUtils.isCorrectCase(commonCase, property.name);
  return AnalyticsRe.Group.property(property.id, property.name, Case.getCasingWithWorkspaceCase(property.name, commonCase), AnalyticsUtils.sendAsToPropertyType(property.sendAs), correctCase);
}

function getAnalyticsPropertyGroup(model, property) {
  var commonCase = NameUtils.getCommonCase(Belt_List.keepMapU(model.properties, (function (property) {
              if (property.TAG === /* PropertyRef */0) {
                return ;
              } else {
                return property._0.name;
              }
            })));
  return getAnalyticsPropertyGroupFromCommonCase(commonCase, property);
}

function actionByObject(actions) {
  return BeltListExtensions.flatMap(actions, (function (action) {
                var exit = 0;
                var eventId;
                var propertyId;
                var sourceId;
                var destinationId;
                var goalId;
                var metricId;
                var metricId$1;
                var eventId$1;
                var id;
                var id$1;
                var id$2;
                var id$3;
                var id$4;
                var id$5;
                var id$6;
                var id$7;
                var id$8;
                if (typeof action !== "object") {
                  if (action === "StartedImport") {
                    return Pervasives.failwith("We should never check which object an Import belongs to");
                  } else {
                    return /* [] */0;
                  }
                }
                var variant = action.NAME;
                if (variant === "UpdateDestinationProdApiKey" || variant === "UpdateDestinationDevApiKey" || variant === "UpdateDestinationAnalyticsTool" || variant === "UpdateDestinationName" || variant === "UpdateDestinationApiKey" || variant === "RemoveDestinationApiKey" || variant === "UpdateDestinationIncludeUserPropsWithEventProps" || variant === "UpdateDestinationDisabledByDefault") {
                  destinationId = action.VAL[0];
                  exit = 4;
                } else if (variant === "AddProperty") {
                  propertyId = action.VAL[1];
                  exit = 2;
                } else {
                  if (variant === "RemoveMember" || variant === "UpdateIntegrationDestinationIds" || variant === "CollaboratorRemoved" || variant === "CreateServiceAccount" || variant === "ReorderGoals" || variant === "AutoPublishIntegration" || variant === "BranchInit" || variant === "CloseBranch" || variant === "ArchiveSavedView" || variant === "CodeGenerated" || variant === "ResendInvite" || variant === "PropertyComment" || variant === "DeleteComment" || variant === "CreatePublicBranchImplementation" || variant === "SubscriptionStarted" || variant === "PullMaster" || variant === "PublishIntegration" || variant === "SubscriptionCancelled" || variant === "SubscriptionUpdated" || variant === "UpdateMemberRole" || variant === "GoalComment" || variant === "UpdateSavedView" || variant === "IntegrationComment" || variant === "MigrateExcludeEventsToIncludeEvents" || variant === "DeleteServiceAccount" || variant === "MetricComment" || variant === "DeletePublicBranchImplementation" || variant === "CollaboratorAdded" || variant === "PropertyGroupComment" || variant === "EventComment" || variant === "OpenBranch" || variant === "RemoveInvite" || variant === "BranchComment" || variant === "InviteMember" || variant === "MigrateSourcesAndDestinationsToModel" || variant === "PaymentCompleted" || variant === "SetBranchStatus" || variant === "CreateSavedView" || variant === "MergeBranch") {
                    return /* [] */0;
                  }
                  if (variant === "AddPropertyGroupToEvent" || variant === "RemoveTriggerSource" || variant === "RemoveEventTrigger" || variant === "RemovePropertyPinnedValue" || variant === "RemovePropertyGroupFromEvent" || variant === "RemoveGroupTypeFromLogEvent" || variant === "UpdateEventName" || variant === "UpdateEventUniqueName" || variant === "RemoveEventType" || variant === "RemoveUserFromGroup" || variant === "UpdatePropertyPinnedValue" || variant === "AddGroupTypeToLogEvent" || variant === "IncludeDestinationInEventSource" || variant === "AddTriggerSource" || variant === "AddPropertyToWhitelist" || variant === "UpdateTriggerDescription" || variant === "ExcludeEventFromSource" || variant === "ExcludeDestinationFromEventSource" || variant === "UpdateEventDescription" || variant === "IncludeEventInSource" || variant === "IncludeEventInSourceV2" || variant === "ToggleIncludeEventInCodegenForSource" || variant === "RemovePropertyRefV2" || variant === "AddUserToGroup" || variant === "RemoveTag" || variant === "CreateEventTrigger" || variant === "UpdateEventUniqueNameV2" || variant === "UpdateTriggerSources" || variant === "LegacyRemovePropertyRef" || variant === "ExcludeEventFromSourceV2" || variant === "AddPropertyRef" || variant === "AddTag" || variant === "RemovePropertyFromWhitelist" || variant === "AddEvent" || variant === "UpdateTriggerContent" || variant === "AddEventType") {
                    eventId = action.VAL[0];
                    exit = 1;
                  } else if (variant === "UpdatePropertyAbsence" || variant === "UpdatePropertyOptionalWhenInObject" || variant === "CreateProperty" || variant === "RemoveNestedPropertyPinnedValue" || variant === "AddPropertyValidation" || variant === "RemovePropertyValidation" || variant === "UpdatePropertyValidationMinOrMax" || variant === "UpdateNestedPropertyPinnedValue" || variant === "AddPropertyValidationMatch" || variant === "AddSystemProperty" || variant === "UpdatePropertyOptional" || variant === "TogglePropertyExcludedSource" || variant === "UpdatePropertyOperation" || variant === "RemovePropertyValidationMatch" || variant === "AddPropertyValidationMinOrMax" || variant === "UpdatePropertyType" || variant === "UpdatePropertySendAs" || variant === "UpdatePropertyDescription" || variant === "UpdatePropertyName" || variant === "UpdatePropertyList" || variant === "UpdatePropertyUniqueName" || variant === "TogglePropertyValidationMatchSource" || variant === "RemovePropertyValidationMinOrMax") {
                    propertyId = action.VAL[0];
                    exit = 2;
                  } else {
                    if (variant === "ExcludeDestinationFromSource" || variant === "IncludeDestinationInSourceV2" || variant === "LegacyToggleDestination" || variant === "LegacyIncludeDestinationInSource" || variant === "UpdateSourceDestinationMode") {
                      var match = action.VAL;
                      return {
                              hd: [
                                {
                                  NAME: "Source",
                                  VAL: match[0]
                                },
                                action
                              ],
                              tl: {
                                hd: [
                                  {
                                    NAME: "Destination",
                                    VAL: match[1]
                                  },
                                  action
                                ],
                                tl: /* [] */0
                              }
                            };
                    }
                    if (variant === "ClearPropertyExcludedSources" || variant === "RemovePropertyValidationMatches" || variant === "RemovePropertyPermanently") {
                      propertyId = action.VAL;
                      exit = 2;
                    } else if (variant === "AddSourceSegmentIntegration" || variant === "RemoveSourceConfig" || variant === "UpdateLibraryName" || variant === "UpdateSourcePlatform" || variant === "AddSourceConfig" || variant === "UpdateSourceSegmentIntegration" || variant === "RemoveSourceSegmentIntegration" || variant === "UpdateSourcePlatformV3" || variant === "UpdateSourcePlatformV2" || variant === "UpdateProgrammingLanguage" || variant === "UpdateSourceName" || variant === "UpdateLibraryDestination") {
                      sourceId = action.VAL[0];
                      exit = 3;
                    } else {
                      if (variant === "CompletedImport" || variant === "ImportDeprecated") {
                        return Pervasives.failwith("We should never check which object an Import belongs to");
                      }
                      if (variant === "RemoveGoal" || variant === "AddGoal") {
                        goalId = action.VAL;
                        exit = 5;
                      } else if (variant === "UpdateIntegrationFilters" || variant === "UpdateIntegrationType" || variant === "UpdateIntegrationName" || variant === "UpdateIntegrationConfig" || variant === "UpdateIntegrationAutoPublish") {
                        id$7 = action.VAL[0];
                        exit = 15;
                      } else {
                        if (variant === "CreateDemoBranch") {
                          return actionByObject(action.VAL);
                        }
                        if (variant === "RemoveMetricFromGoal" || variant === "RemoveEventFromGoal" || variant === "UpdateGoalName" || variant === "AddEventToGoal" || variant === "AddMetricToGoal" || variant === "ReorderEventsInGoal" || variant === "ReorderMetricsInGoal" || variant === "UpdateGoalDescription") {
                          goalId = action.VAL[0];
                          exit = 5;
                        } else if (variant === "RemoveMetricWhereV2" || variant === "RemoveEventFromMetric" || variant === "SetMetricGroupByV2" || variant === "RemoveMetricGroupByV2" || variant === "AddEventToMetric" || variant === "SetMetricWhereV2") {
                          var match$1 = action.VAL;
                          metricId$1 = match$1[0];
                          eventId$1 = match$1[2];
                          exit = 7;
                        } else if (variant === "AddRule") {
                          var match$2 = action.VAL[1];
                          var variant$1 = match$2.NAME;
                          if (variant$1 === "Property") {
                            propertyId = match$2.VAL;
                            exit = 2;
                          } else if (variant$1 === "Event") {
                            eventId = match$2.VAL;
                            exit = 1;
                          } else {
                            eventId = match$2.VAL[0];
                            exit = 1;
                          }
                        } else if (variant === "RemoveEvent") {
                          eventId = action.VAL;
                          exit = 1;
                        } else if (variant === "UpdateGroupTypeName" || variant === "CreateGroupType") {
                          id$8 = action.VAL[0];
                          exit = 16;
                        } else if (variant === "CreateSource" || variant === "DeleteSource") {
                          sourceId = action.VAL;
                          exit = 3;
                        } else if (variant === "RemoveMetricWhere" || variant === "SetMetricWhere" || variant === "SetMetricGroupBy" || variant === "RemoveMetricGroupBy") {
                          var match$3 = action.VAL;
                          metricId$1 = match$3[0];
                          eventId$1 = match$3[1];
                          exit = 7;
                        } else if (variant === "Archive") {
                          var match$4 = action.VAL;
                          var variant$2 = match$4.NAME;
                          if (variant$2 === "Destination") {
                            id$3 = match$4.VAL;
                            exit = 11;
                          } else {
                            if (variant$2 === "Integration") {
                              return /* [] */0;
                            }
                            if (variant$2 === "GroupType") {
                              id$8 = match$4.VAL;
                              exit = 16;
                            } else if (variant$2 === "Property") {
                              id$1 = match$4.VAL;
                              exit = 9;
                            } else if (variant$2 === "Event") {
                              id = match$4.VAL;
                              exit = 8;
                            } else if (variant$2 === "PropertyGroup") {
                              id$6 = match$4.VAL;
                              exit = 14;
                            } else if (variant$2 === "Goal") {
                              id$4 = match$4.VAL;
                              exit = 12;
                            } else if (variant$2 === "Metric") {
                              id$5 = match$4.VAL;
                              exit = 13;
                            } else {
                              id$2 = match$4.VAL;
                              exit = 10;
                            }
                          }
                        } else if (variant === "CreateIntegration" || variant === "GenerateWebhookSecret" || variant === "RemoveIntegration") {
                          id$7 = action.VAL;
                          exit = 15;
                        } else if (variant === "UpdateRule") {
                          var match$5 = action.VAL[1];
                          var variant$3 = match$5.NAME;
                          if (variant$3 === "Property") {
                            propertyId = match$5.VAL;
                            exit = 2;
                          } else if (variant$3 === "Event") {
                            eventId = match$5.VAL;
                            exit = 1;
                          } else {
                            eventId = match$5.VAL[0];
                            exit = 1;
                          }
                        } else if (variant === "UpdateMetricDescription" || variant === "UpdateMetricType" || variant === "UpdateMetricName" || variant === "ReorderEventsInMetric") {
                          metricId = action.VAL[0];
                          exit = 6;
                        } else if (variant === "DeleteDestination" || variant === "CreateDestination") {
                          destinationId = action.VAL;
                          exit = 4;
                        } else if (variant === "AddPropertyToGroup" || variant === "UpdatePropertyGroupName" || variant === "CreatePropertyGroup" || variant === "RemovePropertyFromGroup" || variant === "UpdatePropertyGroupDescription") {
                          id$6 = action.VAL[0];
                          exit = 14;
                        } else if (variant === "DuplicateEvent") {
                          eventId = action.VAL[0][1];
                          exit = 1;
                        } else {
                          if (variant === "ConfigureInspectorValidation") {
                            return Belt_List.map(action.VAL[0], (function (eventId) {
                                          return [
                                                  {
                                                    NAME: "Event",
                                                    VAL: eventId
                                                  },
                                                  action
                                                ];
                                        }));
                          }
                          if (variant === "Unarchive") {
                            var match$6 = action.VAL;
                            var variant$4 = match$6.NAME;
                            if (variant$4 === "Destination") {
                              id$3 = match$6.VAL;
                              exit = 11;
                            } else {
                              if (variant$4 === "Integration") {
                                return /* [] */0;
                              }
                              if (variant$4 === "GroupType") {
                                id$8 = match$6.VAL;
                                exit = 16;
                              } else if (variant$4 === "Property") {
                                id$1 = match$6.VAL;
                                exit = 9;
                              } else if (variant$4 === "Event") {
                                id = match$6.VAL;
                                exit = 8;
                              } else if (variant$4 === "PropertyGroup") {
                                id$6 = match$6.VAL;
                                exit = 14;
                              } else if (variant$4 === "Goal") {
                                id$4 = match$6.VAL;
                                exit = 12;
                              } else if (variant$4 === "Metric") {
                                id$5 = match$6.VAL;
                                exit = 13;
                              } else {
                                id$2 = match$6.VAL;
                                exit = 10;
                              }
                            }
                          } else if (variant === "RemoveRule") {
                            var match$7 = action.VAL[1];
                            var variant$5 = match$7.NAME;
                            if (variant$5 === "Property") {
                              propertyId = match$7.VAL;
                              exit = 2;
                            } else if (variant$5 === "Event") {
                              eventId = match$7.VAL;
                              exit = 1;
                            } else {
                              eventId = match$7.VAL[0];
                              exit = 1;
                            }
                          } else {
                            metricId = action.VAL;
                            exit = 6;
                          }
                        }
                      }
                    }
                  }
                }
                switch (exit) {
                  case 1 :
                      return {
                              hd: [
                                {
                                  NAME: "Event",
                                  VAL: eventId
                                },
                                action
                              ],
                              tl: /* [] */0
                            };
                  case 2 :
                      return {
                              hd: [
                                {
                                  NAME: "Property",
                                  VAL: propertyId
                                },
                                action
                              ],
                              tl: /* [] */0
                            };
                  case 3 :
                      return {
                              hd: [
                                {
                                  NAME: "Source",
                                  VAL: sourceId
                                },
                                action
                              ],
                              tl: /* [] */0
                            };
                  case 4 :
                      return {
                              hd: [
                                {
                                  NAME: "Destination",
                                  VAL: destinationId
                                },
                                action
                              ],
                              tl: /* [] */0
                            };
                  case 5 :
                      return {
                              hd: [
                                {
                                  NAME: "Goal",
                                  VAL: goalId
                                },
                                action
                              ],
                              tl: /* [] */0
                            };
                  case 6 :
                      return {
                              hd: [
                                {
                                  NAME: "Metric",
                                  VAL: metricId
                                },
                                action
                              ],
                              tl: /* [] */0
                            };
                  case 7 :
                      return {
                              hd: [
                                {
                                  NAME: "Metric",
                                  VAL: metricId$1
                                },
                                action
                              ],
                              tl: {
                                hd: [
                                  {
                                    NAME: "Event",
                                    VAL: eventId$1
                                  },
                                  action
                                ],
                                tl: /* [] */0
                              }
                            };
                  case 8 :
                      return {
                              hd: [
                                {
                                  NAME: "Event",
                                  VAL: id
                                },
                                action
                              ],
                              tl: /* [] */0
                            };
                  case 9 :
                      return {
                              hd: [
                                {
                                  NAME: "Property",
                                  VAL: id$1
                                },
                                action
                              ],
                              tl: /* [] */0
                            };
                  case 10 :
                      return {
                              hd: [
                                {
                                  NAME: "Source",
                                  VAL: id$2
                                },
                                action
                              ],
                              tl: /* [] */0
                            };
                  case 11 :
                      return {
                              hd: [
                                {
                                  NAME: "Destination",
                                  VAL: id$3
                                },
                                action
                              ],
                              tl: /* [] */0
                            };
                  case 12 :
                      return {
                              hd: [
                                {
                                  NAME: "Goal",
                                  VAL: id$4
                                },
                                action
                              ],
                              tl: /* [] */0
                            };
                  case 13 :
                      return {
                              hd: [
                                {
                                  NAME: "Metric",
                                  VAL: id$5
                                },
                                action
                              ],
                              tl: /* [] */0
                            };
                  case 14 :
                      return {
                              hd: [
                                {
                                  NAME: "PropertyGroup",
                                  VAL: id$6
                                },
                                action
                              ],
                              tl: /* [] */0
                            };
                  case 15 :
                      return {
                              hd: [
                                {
                                  NAME: "Integration",
                                  VAL: id$7
                                },
                                action
                              ],
                              tl: /* [] */0
                            };
                  case 16 :
                      return {
                              hd: [
                                {
                                  NAME: "GroupType",
                                  VAL: id$8
                                },
                                action
                              ],
                              tl: /* [] */0
                            };
                  
                }
              }));
}

function groupActionsByObjectId(actions) {
  return BeltListExtensions.group((function (a, b) {
                var match = a[0];
                var match$1 = b[0];
                if (Caml_obj.caml_greaterthan(match, match$1)) {
                  return 1;
                } else if (Caml_obj.caml_equal(match, match$1)) {
                  return 0;
                } else {
                  return -1;
                }
              }), actions);
}

function getMetricTypeLabel(metricType) {
  if (metricType === "EventSegmentation") {
    return "Segmentation";
  } else if (metricType === "Retention") {
    return "Retention";
  } else if (metricType === "Funnel") {
    return "Funnel";
  } else {
    return "Proportion";
  }
}

function getPropertyOptions(model, $$event) {
  var availableProperties = AvoModel.getAllPropertiesAvailableOnEventForMetricView(model, $$event);
  var match = AvoModel.classifyProperties(AvoModel.resolveProperties(model, Belt_List.keep(model.properties, (function (x) {
                  var id;
                  id = x._0.id;
                  return !Belt_List.some(availableProperties, (function (prop) {
                                return prop.id === id;
                              }));
                }))));
  return {
          hd: [
            "Properties on " + $$event.name,
            Belt_List.map(availableProperties, (function (item) {
                    return {
                            NAME: "Property",
                            VAL: item
                          };
                  }))
          ],
          tl: {
            hd: [
              "Other Properties",
              Belt_List.map(Belt_List.concat(match[0], match[1]), (function (item) {
                      return {
                              NAME: "Property",
                              VAL: item
                            };
                    }))
            ],
            tl: /* [] */0
          }
        };
}

function hasSegmentDestination(model, source) {
  return Belt_List.someU(source.destinations, (function (param) {
                var destination = StateUtils.getDestinationById(param.destinationId, model);
                return Belt_Option.mapWithDefaultU(destination, false, (function (destination) {
                              return Caml_obj.caml_equal(destination.type_, "Segment");
                            }));
              }));
}

function hasInspectorSupport(model, source) {
  var match = hasSegmentDestination(model, source);
  var match$1 = source.platform;
  if (match) {
    return true;
  } else if (match$1 !== undefined) {
    if (match$1 === "Node" || match$1 === "Java" || match$1 === "Expo" || match$1 === "Web" || match$1 === "IOS" || match$1 === "Android") {
      return true;
    } else {
      return match$1 === "ReactNative";
    }
  } else {
    return false;
  }
}

function plural(n, word) {
  if (n === 1) {
    return "1 " + word;
  } else {
    return $$Number.toLocaleString(n) + " " + word + "s";
  }
}

exports.renderLanguageFileExtension = renderLanguageFileExtension;
exports.renderFilename = renderFilename;
exports.renderLibraryDestination = renderLibraryDestination;
exports.getDestinationsSupport = getDestinationsSupport;
exports.isDestinationSupportedForSource = isDestinationSupportedForSource;
exports.filterInvalidSources = filterInvalidSources;
exports.filterInvalidDestinations = filterInvalidDestinations;
exports.getAnalyticsPropertyGroupFromCommonCase = getAnalyticsPropertyGroupFromCommonCase;
exports.getAnalyticsPropertyGroup = getAnalyticsPropertyGroup;
exports.actionByObject = actionByObject;
exports.groupActionsByObjectId = groupActionsByObjectId;
exports.getMetricTypeLabel = getMetricTypeLabel;
exports.getPropertyOptions = getPropertyOptions;
exports.hasSegmentDestination = hasSegmentDestination;
exports.hasInspectorSupport = hasInspectorSupport;
exports.plural = plural;
/* Case Not a pure module */
