// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconPassword(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey40;
  return React.createElement("svg", {
              width: String(size),
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  className: Curry._1(Css.style, {
                        hd: Css_Legacy_Core.SVG.fill(color),
                        tl: {
                          hd: Css_Legacy_Core.SVG.stroke(color),
                          tl: /* [] */0
                        }
                      }),
                  strokeWidth: "1"
                }, React.createElement("path", {
                      d: "M13.5,15.5h-11 c-0.552,0-1-0.448-1-1v-7c0-0.552,0.448-1,1-1h11c0.552,0,1,0.448,1,1v7C14.5,15.052,14.052,15.5,13.5,15.5z",
                      fill: "none",
                      strokeLinecap: "round"
                    }), React.createElement("path", {
                      d: "M4.5,6.5V4 c0-1.933,1.567-3.5,3.5-3.5h0c1.933,0,3.5,1.567,3.5,3.5v2.5",
                      fill: "none",
                      strokeLinecap: "round"
                    }), React.createElement("circle", {
                      cx: "8",
                      cy: "11",
                      fill: "none",
                      r: "1.5",
                      strokeLinecap: "round"
                    })));
}

var make = IconPassword;

exports.make = make;
/* Css Not a pure module */
