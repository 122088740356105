// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Case = require("../../../bs-case/src/case.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Shortid = require("shortid");
var AvoLimits = require("../AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameUtils = require("../../../shared/utils/NameUtils.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("../stateUtils.bs.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var NamedBranch = require("../NamedBranch.bs.js");
var AnalyticsUtils = require("../analyticsUtils.bs.js");
var BeltListExtensions = require("../BeltListExtensions.bs.js");

var defaultType_count = {
  comparison: {
    numVersions: 0,
    total: 0,
    versions: []
  },
  current: {
    numVersions: 0,
    total: 0,
    versions: []
  },
  history: {
    numVersions: 0,
    total: 0,
    versions: []
  }
};

var defaultType_firstSeen = {
  current: {
    firstSeen: undefined,
    lastSeen: undefined,
    versions: [],
    numVersions: 0
  },
  comparison: {
    firstSeen: undefined,
    lastSeen: undefined,
    versions: [],
    numVersions: 0
  },
  history: {
    firstSeen: undefined,
    lastSeen: undefined,
    versions: [],
    numVersions: 0
  }
};

var defaultType_lastSeen = {
  current: {
    firstSeen: undefined,
    lastSeen: undefined,
    versions: [],
    numVersions: 0
  },
  comparison: {
    firstSeen: undefined,
    lastSeen: undefined,
    versions: [],
    numVersions: 0
  },
  history: {
    firstSeen: undefined,
    lastSeen: undefined,
    versions: [],
    numVersions: 0
  }
};

var defaultType = {
  type_: "string",
  count: defaultType_count,
  firstSeen: defaultType_firstSeen,
  lastSeen: defaultType_lastSeen
};

function propertyAddedToEventAnalytics(schemaGroup, branch, eventId, eventName, propertyName, propertyGroup, propertyLocation) {
  AnalyticsRe.propertyAdded(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), propertyGroup, AnalyticsRe.Group.currentFilters([], [], [], "NoSorting", [], [], [], []), eventId, eventName, propertyName.length, "New", Case.to_("Sentence", propertyName).split(" ").length, propertyName.length, propertyLocation, schemaGroup.branchId, schemaGroup.schemaId);
  
}

function newPropertyAnalytics(eventId, eventName, expectedPropertyCase, schemaGroup, propertyName, propertyId, propertyType, branch, propertyLocation) {
  var correctPropertyCase = NameUtils.isCorrectCase(expectedPropertyCase, propertyName);
  AnalyticsRe.propertyCreated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), AnalyticsRe.Group.property(propertyId, propertyName, expectedPropertyCase, "EventProperty", correctPropertyCase), [], [], eventId, propertyLocation, AnalyticsUtils.propertyLocationToOrigin(propertyLocation), schemaGroup.branchId, schemaGroup.schemaId);
  var propertyGroup = AnalyticsRe.Group.property(propertyId, propertyName, expectedPropertyCase, "EventProperty", correctPropertyCase);
  propertyAddedToEventAnalytics(schemaGroup, branch, eventId, eventName, propertyName, propertyGroup, propertyLocation);
  if (propertyType !== "string") {
    return AnalyticsRe.typeChanged(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), "N/A", Belt_Option.getExn(AnalyticsRe.to_FromJs(propertyType)), Belt_Option.getExn(AnalyticsRe.fromFromJs("string")), propertyName, NamedBranch.getId(branch), schemaGroup.schemaId);
  }
  
}

function addPropertyToEventInTrackingPlan(eventId, eventName, inspectorProperty, model, context, expectedPropertyCase, schemaGroup, propertyLocation, isConflict, forceCreateOnConflict, shouldCreateAsSometimesSent) {
  var maybeProperty = StateUtils.getPropertyByName(inspectorProperty.propertyName, model);
  var propertyAsOptional = function (propertyId) {
    if (shouldCreateAsSometimesSent) {
      return [[
                {
                  NAME: "UpdatePropertyAbsence",
                  VAL: [
                    propertyId,
                    {
                      TAG: /* Event */0,
                      _0: eventId
                    },
                    /* SometimesSent */{
                      _0: ""
                    }
                  ]
                },
                context
              ]];
    } else {
      return [];
    }
  };
  if (maybeProperty !== undefined) {
    return [
            Belt_Array.concat([[
                    {
                      NAME: "AddPropertyRef",
                      VAL: [
                        eventId,
                        maybeProperty.id
                      ]
                    },
                    context
                  ]], propertyAsOptional(maybeProperty.id)),
            undefined,
            (function (branch) {
                var correctPropertyCase = NameUtils.isCorrectCase(expectedPropertyCase, maybeProperty.name);
                var propertyGroup = AnalyticsRe.Group.property(maybeProperty.id, maybeProperty.name, expectedPropertyCase, AnalyticsUtils.sendAsToPropertyType(maybeProperty.sendAs), correctPropertyCase);
                return propertyAddedToEventAnalytics(schemaGroup, branch, eventId, eventName, maybeProperty.name, propertyGroup, propertyLocation);
              })
          ];
  }
  var propertyId = Shortid();
  var match = Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.get(inspectorProperty.sources, 0), (function (source) {
              var mostCommonType = Belt_Array.reduceU(source.propertyTypes, defaultType, (function (propertyType, currentType) {
                      if (propertyType.count.current.total > currentType.count.current.total) {
                        return propertyType;
                      } else {
                        return currentType;
                      }
                    }));
              var match = mostCommonType.type_;
              var propertyType;
              switch (match) {
                case "any" :
                    propertyType = "any";
                    break;
                case "bool" :
                case "boolean" :
                    propertyType = "bool";
                    break;
                case "float" :
                    propertyType = "float";
                    break;
                case "int" :
                    propertyType = "int";
                    break;
                case "long" :
                    propertyType = "long";
                    break;
                case "object" :
                    propertyType = "object";
                    break;
                case "string" :
                    propertyType = "string";
                    break;
                default:
                  propertyType = "string";
              }
              var updatePropertyAction = propertyType === "string" ? [] : [[
                    {
                      NAME: "UpdatePropertyType",
                      VAL: [
                        propertyId,
                        propertyType
                      ]
                    },
                    context
                  ]];
              return [
                      Belt_Array.concatMany([
                            [[
                                {
                                  NAME: "AddProperty",
                                  VAL: [
                                    eventId,
                                    propertyId,
                                    inspectorProperty.propertyName,
                                    /* EventProperty */1
                                  ]
                                },
                                context
                              ]],
                            updatePropertyAction,
                            propertyAsOptional(propertyId)
                          ]),
                      propertyId,
                      (function (branch) {
                          return newPropertyAnalytics(eventId, eventName, expectedPropertyCase, schemaGroup, inspectorProperty.propertyName, propertyId, mostCommonType.type_, branch, propertyLocation);
                        })
                    ];
            })), [
        [],
        undefined,
        (function (_branch) {
            
          })
      ]);
  var onSuccess = match[2];
  var newPropertyId = match[1];
  var actions = match[0];
  if (isConflict) {
    if (forceCreateOnConflict) {
      return [
              Belt_Array.concat(actions, [[
                      {
                        NAME: "UpdatePropertyUniqueName",
                        VAL: [
                          propertyId,
                          inspectorProperty.propertyName + " 1"
                        ]
                      },
                      context
                    ]]),
              newPropertyId,
              onSuccess
            ];
    } else {
      return [
              [],
              undefined,
              (function (_branch) {
                  
                })
            ];
    }
  } else {
    return [
            actions,
            newPropertyId,
            onSuccess
          ];
  }
}

function onCreateProperty(inspectorEvent, inspectorProperty, model, sendActions, schemaGroup, expectedPropertyCase, addToast, globalSend, propertyLocation, shouldCreateAsSometimesSent) {
  var addProperty = function ($$event, inspectorProperty, isConflict) {
    var context = {
      eventId: $$event.id,
      eventQuery: $$event.id
    };
    var match = addPropertyToEventInTrackingPlan($$event.id, $$event.name, inspectorProperty, model, context, expectedPropertyCase, schemaGroup, propertyLocation, isConflict, true, shouldCreateAsSometimesSent);
    var onSuccess = match[2];
    var maybePropertyId = match[1];
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    Curry._1(onSuccess, branch);
                    var text = maybePropertyId !== undefined ? "Property Created 🎉" : "Property Added to Event 🎉";
                    return Curry._1(addToast, {
                                message: text,
                                toastType: /* Success */0
                              });
                  }),
                undefined,
                match[0]
              ]);
  };
  var $$event = StateUtils.getEventByName(inspectorEvent.eventName, model);
  var existingNames = StateUtils.getPropertyNamespace(model);
  var conflictingName = NameUtils.getConflictingNames(existingNames, inspectorProperty.propertyName);
  if ($$event !== undefined) {
    if (conflictingName !== undefined && conflictingName !== inspectorProperty.propertyName) {
      AnalyticsRe.warningPromptDisplayed(schemaGroup, "InspectorAddPropertyConflictingPropertyFound", schemaGroup.schemaId);
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "ConfirmModal",
                    VAL: [
                      "This property already exists as " + conflictingName + ".",
                      "This is most likely a data bug. Are you sure you want to continue?",
                      "Continue",
                      (function (param) {
                          AnalyticsRe.warningPromptInteracted(schemaGroup, "Confirm", "InspectorAddPropertyConflictingPropertyFound", schemaGroup.schemaId);
                          return addProperty($$event, inspectorProperty, true);
                        }),
                      (function (param) {
                          return AnalyticsRe.warningPromptInteracted(schemaGroup, "Cancel", "InspectorAddPropertyConflictingPropertyFound", schemaGroup.schemaId);
                        })
                    ]
                  }
                });
    } else {
      return addProperty($$event, inspectorProperty, false);
    }
  }
  
}

var Property = {
  defaultType: defaultType,
  propertyAddedToEventAnalytics: propertyAddedToEventAnalytics,
  newPropertyAnalytics: newPropertyAnalytics,
  addPropertyToEventInTrackingPlan: addPropertyToEventInTrackingPlan,
  onCreateProperty: onCreateProperty
};

function updateEventSourceAnalytics(eventId, inspectorEvent, sources, schemaGroup, model, correctCase, namingConvention, branch) {
  Belt_Array.forEachWithIndexU(sources, (function (index, source) {
          AnalyticsRe.eventUpdated(schemaGroup, AnalyticsRe.Group.source(source.id, Belt_Option.getWithDefault(source.name, "N/A")), eventId, inspectorEvent.eventName, "AddSources", undefined, undefined, Belt_List.toArray(Belt_List.map(Belt_List.keepWithIndex(model.sources, (function (param, i) {
                              return i < index;
                            })), (function (source) {
                          return source.id;
                        }))), Belt_List.toArray(Belt_List.map(Belt_List.keepWithIndex(model.sources, (function (param, i) {
                              return i < (index - 1 | 0);
                            })), (function (source) {
                          return source.id;
                        }))), undefined, Belt_List.length(BeltListExtensions.dedupeString(StateUtils.eventsWithNameMapping(model))), Belt_List.length(StateUtils.eventsWithNameMapping(model)), Belt_List.length(Belt_List.keep(StateUtils.eventsWithNameMapping(model), (function (id) {
                          return id === eventId;
                        }))), Belt_List.length(model.events), undefined, undefined, undefined, undefined, "InspectorEvents", 0, correctCase, namingConvention, eventId, schemaGroup.schemaId, NamedBranch.getId(branch), eventId, undefined, inspectorEvent.eventName);
          
        }));
  if (sources.length !== 0) {
    return ;
  } else {
    AnalyticsRe.eventUpdated(schemaGroup, AnalyticsRe.Group.source("N/A", "N/A"), eventId, inspectorEvent.eventName, "AddSources", undefined, undefined, Belt_List.toArray(Belt_List.map(model.sources, (function (source) {
                    return source.id;
                  }))), [], undefined, Belt_List.length(BeltListExtensions.dedupeString(StateUtils.eventsWithNameMapping(model))), Belt_List.length(StateUtils.eventsWithNameMapping(model)), Belt_List.length(Belt_List.keep(StateUtils.eventsWithNameMapping(model), (function (id) {
                    return id === eventId;
                  }))), Belt_List.length(model.events), undefined, undefined, undefined, undefined, "InspectorEvents", 0, correctCase, namingConvention, eventId, schemaGroup.schemaId, NamedBranch.getId(branch), eventId, undefined, inspectorEvent.eventName);
    return ;
  }
}

function addEventToTrackingPlan(workspace, inspectorEvent, model, sendActions, schemaGroup, expectedEventCase, expectedPropertyCase, addToast, eventOrigin, isConflict, shouldCreateAsSometimesSent) {
  var eventId = Shortid();
  var eventName = inspectorEvent.eventName;
  var propertyLocation = eventOrigin === "InspectorSidebar" || eventOrigin !== "InspectorTable" ? "InspectorSidebar" : "InspectorTable";
  var correctEventCase = NameUtils.isCorrectCase(expectedEventCase, eventName);
  var areEventSourcesAndDestinationsConfigurable = AvoLimits.ConfigEventSourcesAndDestinations.isAvailable(workspace);
  var context = {
    eventId: eventId,
    eventQuery: eventId
  };
  var sources = Belt_Array.keepMapU(inspectorEvent.sources, (function (eventSource) {
          return StateUtils.getSourceById(eventSource.sourceId, model);
        }));
  var sourcesActions = Belt_Array.mapU(sources, (function (source) {
          var destinationIds = Belt_List.mapU(source.destinations, (function (d) {
                  return d.destinationId;
                }));
          return [
                  {
                    NAME: "IncludeEventInSourceV2",
                    VAL: [
                      eventId,
                      source.id,
                      destinationIds,
                      false
                    ]
                  },
                  context
                ];
        }));
  var props = Belt_Array.mapU(inspectorEvent.properties, (function (inspectorProperty) {
          var existingNames = StateUtils.getPropertyNamespace(model);
          var conflictingName = NameUtils.getConflictingNames(existingNames, inspectorProperty.propertyName);
          var isConflict = conflictingName !== undefined ? conflictingName !== inspectorProperty.propertyName : false;
          return addPropertyToEventInTrackingPlan(eventId, eventName, inspectorProperty, model, context, expectedPropertyCase, schemaGroup, propertyLocation, isConflict, false, shouldCreateAsSometimesSent);
        }));
  var eventActions = isConflict ? [
      [
        {
          NAME: "AddEvent",
          VAL: [
            eventId,
            inspectorEvent.eventName
          ]
        },
        context
      ],
      [
        {
          NAME: "UpdateEventUniqueNameV2",
          VAL: [
            eventId,
            inspectorEvent.eventName + " 1"
          ]
        },
        context
      ]
    ] : [[
        {
          NAME: "AddEvent",
          VAL: [
            eventId,
            inspectorEvent.eventName
          ]
        },
        context
      ]];
  var propertyActions = Belt_Array.concatMany(Belt_Array.mapU(props, (function (param) {
              return param[0];
            })));
  var propertiesCreated = Belt_Array.keepMapU(props, (function (param) {
          return param[1];
        }));
  var propertiesOnSuccess = Belt_Array.mapU(props, (function (param) {
          return param[2];
        }));
  return Curry.app(sendActions, [
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              (function (branch) {
                  AnalyticsRe.eventCreated(AnalyticsRe.Group.currentFilters([], [], [], "NoSorting", [], [], [], []), AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), eventId, inspectorEvent.eventName, correctEventCase, expectedEventCase, eventOrigin, NamedBranch.getId(branch), schemaGroup.schemaId);
                  updateEventSourceAnalytics(eventId, inspectorEvent, sources, schemaGroup, model, correctEventCase, expectedEventCase, branch);
                  Belt_Array.forEach(propertiesOnSuccess, (function (onSuccess) {
                          return Curry._1(onSuccess, branch);
                        }));
                  var num = propertiesCreated.length;
                  var text = num !== 0 ? (
                      num !== 1 ? "Event and " + String(num) + " properties created 🎉" : "Event and 1 property created 🎉"
                    ) : "Event Created 🎉";
                  return Curry._1(addToast, {
                              message: text,
                              toastType: /* Success */0
                            });
                }),
              undefined,
              Belt_Array.concatMany([
                    eventActions,
                    sourcesActions,
                    propertyActions,
                    areEventSourcesAndDestinationsConfigurable ? [] : Belt_Array.mapU(Belt_List.toArray(model.sources), (function (source) {
                              return [
                                      {
                                        NAME: "IncludeEventInSourceV2",
                                        VAL: [
                                          eventId,
                                          source.id,
                                          StateUtils.getSourceDestinationIds(source.destinations),
                                          true
                                        ]
                                      },
                                      {
                                        eventId: eventId,
                                        eventQuery: eventId,
                                        sourceId: source.id,
                                        sourceQuery: source.id
                                      }
                                    ];
                            }))
                  ])
            ]);
}

function onCreateEvent(workspace, inspectorEvent, model, sendActions, schemaGroup, expectedEventCase, expectedPropertyCase, addToast, globalSend, eventOrigin, shouldCreateAsSometimesSent) {
  var addEvent = function ($$event, isConflict) {
    return addEventToTrackingPlan(workspace, $$event, model, sendActions, schemaGroup, expectedEventCase, expectedPropertyCase, addToast, eventOrigin, isConflict, shouldCreateAsSometimesSent);
  };
  var existingNames = StateUtils.getEventNamespace(model.events);
  var conflictingName = NameUtils.getConflictingNames(existingNames, inspectorEvent.eventName);
  if (conflictingName !== undefined) {
    AnalyticsRe.warningPromptDisplayed(schemaGroup, "InspectorAddEventConflictingEventFound", workspace.id);
    return Curry._1(globalSend, {
                TAG: /* OpenModal */4,
                _0: {
                  NAME: "ConfirmModal",
                  VAL: [
                    "This event already exists as " + conflictingName + ".",
                    "This is most likely a data bug. Are you sure you want to continue?",
                    "Continue",
                    (function (param) {
                        AnalyticsRe.warningPromptInteracted(schemaGroup, "Confirm", "InspectorAddEventConflictingEventFound", workspace.id);
                        return addEvent(inspectorEvent, true);
                      }),
                    (function (param) {
                        return AnalyticsRe.warningPromptInteracted(schemaGroup, "Cancel", "InspectorAddEventConflictingEventFound", workspace.id);
                      })
                  ]
                }
              });
  } else {
    return addEvent(inspectorEvent, false);
  }
}

var $$Event = {
  updateEventSourceAnalytics: updateEventSourceAnalytics,
  addEventToTrackingPlan: addEventToTrackingPlan,
  onCreateEvent: onCreateEvent
};

exports.Property = Property;
exports.$$Event = $$Event;
/* Case Not a pure module */
