// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("../Link.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Router = require("../Router.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var Actions = require("../actions.bs.js");
var DocsLink = require("../DocsLink.bs.js");
var IconCheckmark = require("../IconCheckmark.bs.js");
var LoadingCircle = require("../LoadingCircle.bs.js");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");
var InspectorViewInstallDocsJsFamily = require("./InspectorViewInstallDocsJsFamily.bs.js");
var InspectorViewInstallDocsScriptTagCodeSnippet = require("./InspectorViewInstallDocsScriptTagCodeSnippet.bs.js");
var InspectorViewInstallDocsJsFamilyTrackEventCodeSnippet = require("./InspectorViewInstallDocsJsFamilyTrackEventCodeSnippet.bs.js");

var docsContentStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(24), Css.px(32), Css.px(0), Css.px(32)),
      tl: /* [] */0
    });

var docsDescriptionStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(16)),
      tl: {
        hd: Css.marginBottom(Css.px(8)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("row"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.color(Styles.Color.grey100),
                tl: {
                  hd: Css.lineHeight(Css.pct(140.0)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var listNumberStyle = Curry._1(Css.style, {
      hd: Css.fontWeight(Styles.FontWeight.semi),
      tl: {
        hd: Css.fontSize(Styles.FontSize.medium),
        tl: /* [] */0
      }
    });

var docsLinkStyles = Curry._1(Css.style, {
      hd: Css.display("inlineBlock"),
      tl: {
        hd: Css.color(Styles.Color.blue),
        tl: {
          hd: Css.textDecoration("none"),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: 200.0
                }, undefined, undefined, "color"),
            tl: {
              hd: Css.hover({
                    hd: Css.color(Styles.Color.blueSecondary),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

function InspectorViewInstallDocsScriptTag(Props) {
  var apiKey = Props.apiKey;
  var sourceGroup = Props.sourceGroup;
  var hasSeenEvents = Props.hasSeenEvents;
  var schemaId = Props.schemaId;
  var currentBranch = Props.currentBranch;
  var onClose = Props.onClose;
  var schemaGroup = SchemaGroupContext.use(undefined);
  return React.createElement("ol", {
              className: docsContentStyles
            }, React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: "Paste the HTML script tag snippet within the <head> tag of your page or add it via Tag Manager."
                        })), React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: "Make sure to update __ENV__, __VERSION__ and __APP_NAME__ based on your project."
                        })), InspectorViewInstallDocsScriptTagCodeSnippet.code(apiKey, schemaGroup, sourceGroup, schemaId, Actions.branchToId(currentBranch))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: "Send event schemas to Avo"
                        })), InspectorViewInstallDocsJsFamilyTrackEventCodeSnippet.code(InspectorViewInstallDocsJsFamily.docsCodeBoxStyles, InspectorViewInstallDocsJsFamily.docsCodeStyles, InspectorViewInstallDocsJsFamily.docsCodeLightBlueStyles, InspectorViewInstallDocsJsFamily.docsCodeMintGreenStyles, InspectorViewInstallDocsJsFamily.docsCodeOrangeStyles, InspectorViewInstallDocsJsFamily.copyButtonBoxStyles, "js_v2"), React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement(Spacer.make, {
                          width: 4
                        }), hasSeenEvents ? React.createElement(React.Fragment, undefined, React.createElement(IconCheckmark.make, {
                                size: 12,
                                color: Styles.Color.mintGreen
                              }), React.createElement(Spacer.make, {
                                width: 8
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.mintGreen,
                                children: "Seen events"
                              })) : React.createElement(React.Fragment, undefined, React.createElement(LoadingCircle.make, {
                                size: 16,
                                color: Styles.Color.grey50
                              }), React.createElement(Spacer.make, {
                                width: 8
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.grey80,
                                children: "Waiting for your first events"
                              })))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: "View your first events on the"
                        }), React.createElement(Spacer.make, {
                          width: 4
                        }), React.createElement(Link.make, {
                          path: Router.Link.getSchemaRouteLink(undefined, {
                                NAME: "inspector",
                                VAL: "events"
                              }),
                          className: docsLinkStyles,
                          onClick: (function (param) {
                              return Curry._1(onClose, undefined);
                            }),
                          children: React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                children: "events page"
                              })
                        }))), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement(DocsLink.make, {
                  path: "implementation/inspector/sdk/web",
                  label: "Learn more in the docs"
                }));
}

var make = InspectorViewInstallDocsScriptTag;

exports.docsContentStyles = docsContentStyles;
exports.docsDescriptionStyles = docsDescriptionStyles;
exports.listNumberStyle = listNumberStyle;
exports.docsLinkStyles = docsLinkStyles;
exports.make = make;
/* docsContentStyles Not a pure module */
