// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var IconSend = require("./IconSend.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var SourceRowDiff = require("./SourceRowDiff.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");

function DiffEventSources(Props) {
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var actions = Props.actions;
  var addedItems = Belt_List.keepMapU(actions, (function (action) {
          if (typeof action !== "object") {
            return ;
          }
          var variant = action.NAME;
          if (variant === "IncludeEventInSource" || variant === "IncludeEventInSourceV2") {
            return action.VAL[1];
          }
          
        }));
  var removedItems = Belt_List.keepMapU(actions, (function (action) {
          if (typeof action !== "object") {
            return ;
          }
          var variant = action.NAME;
          if (variant === "ExcludeEventFromSource" || variant === "ExcludeEventFromSourceV2") {
            return action.VAL[1];
          }
          
        }));
  var addedOrRemovedItems = Belt_List.concat(addedItems, removedItems);
  var changedItems = Belt_List.keepMap(BeltListExtensions.dedupeString(Belt_List.flatten(Belt_List.keepMapU(actions, (function (action) {
                      if (typeof action !== "object") {
                        return ;
                      }
                      var variant = action.NAME;
                      if (variant === "IncludeDestinationInEventSource" || variant === "ExcludeDestinationFromEventSource" || variant === "ToggleIncludeEventInCodegenForSource") {
                        return {
                                hd: action.VAL[1],
                                tl: /* [] */0
                              };
                      } else if (variant === "ConfigureInspectorValidation") {
                        return Belt_List.fromArray(Belt_MapString.keysToArray(action.VAL[1]));
                      } else {
                        return ;
                      }
                    })))), (function (sourceId) {
          if (Belt_List.some(addedOrRemovedItems, (function (id) {
                    return id === sourceId;
                  }))) {
            return ;
          } else {
            return sourceId;
          }
        }));
  var updatedItems = BeltListExtensions.dedupeString(Belt_List.concatMany([
            addedItems,
            removedItems,
            changedItems
          ]));
  if (updatedItems) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("flexStart"),
                        tl: {
                          hd: Css.padding2(Css.px(10), Css.px(0)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.margin4(Css.px(14), Css.px(10), Css.px(0), Css.px(0)),
                          tl: {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.grey40),
                            tl: {
                              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.grey40),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, React.createElement(IconSend.make, {})), React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.width(Css.pct(100)),
                          tl: /* [] */0
                        })
                  }, Belt_List.toArray(Belt_List.concatMany([
                            Belt_List.mapU(removedItems, (function (sourceId) {
                                    return Belt_Option.mapWithDefault(Belt_List.getByU(masterModel.sources, (function (source) {
                                                      return source.id === sourceId;
                                                    })), null, (function (source) {
                                                  return React.createElement(SourceRowDiff.make, {
                                                              source: source,
                                                              colors: [
                                                                Styles.Color.grapeErrorSecondary,
                                                                Styles.Color.grapeError
                                                              ],
                                                              actions: actions,
                                                              branchModel: branchModel,
                                                              key: source.id + "-removed"
                                                            });
                                                }));
                                  })),
                            Belt_List.mapU(addedItems, (function (sourceId) {
                                    return Belt_Option.mapWithDefault(Belt_List.getByU(branchModel.sources, (function (source) {
                                                      return source.id === sourceId;
                                                    })), null, (function (source) {
                                                  return React.createElement(SourceRowDiff.make, {
                                                              source: source,
                                                              colors: [
                                                                Styles.Color.mintGreenSecondary,
                                                                Styles.Color.mintGreen
                                                              ],
                                                              actions: actions,
                                                              branchModel: branchModel,
                                                              key: source.id + "-added"
                                                            });
                                                }));
                                  })),
                            Belt_List.mapU(changedItems, (function (sourceId) {
                                    return Belt_Option.mapWithDefault(Belt_List.getByU(branchModel.sources, (function (source) {
                                                      return source.id === sourceId;
                                                    })), null, (function (source) {
                                                  return React.createElement(SourceRowDiff.make, {
                                                              source: source,
                                                              colors: [
                                                                Styles.Color.grey70,
                                                                Styles.Color.grey30
                                                              ],
                                                              actions: actions,
                                                              branchModel: branchModel,
                                                              key: source.id + "-changed"
                                                            });
                                                }));
                                  }))
                          ]))));
  } else {
    return null;
  }
}

var make = DiffEventSources;

exports.make = make;
/* Css Not a pure module */
