// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var Title = require("./Title.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var IconLock = require("./IconLock.bs.js");
var Intercom = require("./externals/intercom.bs.js");
var TextButton = require("./TextButton.bs.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var IconTerminal = require("./IconTerminal.bs.js");
var QueryString = require("query-string");

function AuthorizeCli(Props) {
  var user = Props.user;
  var cliRoute = Props.cliRoute;
  var match = React.useState(function () {
        return false;
      });
  var setIsLoading = match[1];
  var match$1 = React.useState(function () {
        return "Initial";
      });
  var setStatus = match$1[1];
  var status = match$1[0];
  var query = window.location.search.replace("?", "");
  var uriState = QueryString.parse(query).state;
  var redirectUrl = QueryString.parse(query).redirect_uri;
  var tmp;
  var exit = 0;
  if (cliRoute === "success") {
    tmp = React.createElement(Title.make, {
          children: "The Avo CLI was successfully authenticated!",
          color: Styles.Color.mintGreen,
          size: "Small"
        });
  } else if (cliRoute === "index") {
    if ((uriState == null) || (redirectUrl == null)) {
      exit = 1;
    } else if (typeof status === "object") {
      if (status.NAME === "Success") {
        var redirectUrl$1 = redirectUrl + "/?state=" + uriState + "&code=" + status.VAL;
        tmp = React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                  children: "Success!",
                  color: Styles.Color.mintGreen,
                  size: "Small"
                }), React.createElement(Spacer.make, {
                  height: 10
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.grey80,
                  children: "You should be redirected within few seconds. If not you can also click the link below:"
                }), React.createElement(Spacer.make, {
                  height: 10
                }), React.createElement("a", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("block"),
                        tl: {
                          hd: Css.maxWidth(Css.pct(100)),
                          tl: {
                            hd: Css.width(Css.px(400)),
                            tl: {
                              hd: Css.overflow("hidden"),
                              tl: {
                                hd: Css.textAlign("left"),
                                tl: {
                                  hd: Css.color(Styles.Color.blue),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.color(Styles.Color.blueSecondary),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  href: redirectUrl$1
                }, React.createElement($$Text.make, {
                      size: "Tiny",
                      weight: "Semi",
                      children: redirectUrl$1
                    })));
      } else {
        exit = 1;
      }
    } else if (status === "Error") {
      exit = 2;
    } else if (status === "Initial") {
      tmp = React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                children: "Avo CLI wants to access your Avo account",
                size: "Small"
              }), React.createElement(Spacer.make, {
                height: 20
              }), React.createElement($$Text.make, {
                size: "Large",
                color: Styles.Color.grey80,
                children: null
              }, "This will allow ", React.createElement($$Text.make, {
                    element: "Span",
                    weight: "Semi",
                    children: "Avo CLI"
                  }), " to:"), React.createElement("ul", undefined, React.createElement("li", undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.grey80,
                        children: "View and manage your Avo workspaces"
                      })), React.createElement("li", undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.grey80,
                        children: "Generate analytics libraries into your project folders"
                      }))), React.createElement(Spacer.make, {
                height: 12
              }), React.createElement(Button.make, {
                label: "Continue as " + user.email,
                loading: match[0],
                onClick: (function (param) {
                    Curry._1(setIsLoading, (function (param) {
                            return true;
                          }));
                    $$Promise.$$catch(user.getIdToken().then(function (token) {
                                  return fetch(Firebase.apiUrl + "/auth/auth", Fetch.RequestInit.make(/* Post */2, [
                                                    [
                                                      "Accept",
                                                      "application/json"
                                                    ],
                                                    [
                                                      "Content-Type",
                                                      "application/json"
                                                    ],
                                                    [
                                                      "Authorization",
                                                      "Bearer " + token
                                                    ]
                                                  ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                                }).then(function (prim) {
                                return prim.json();
                              }).then(function (response) {
                              console.log("auth success");
                              var responseStr = Json_decode.string(response);
                              Curry._1(setStatus, (function (param) {
                                      return {
                                              NAME: "Success",
                                              VAL: responseStr
                                            };
                                    }));
                              window.location.href = redirectUrl + "/?state=" + uriState + "&code=" + responseStr;
                              return Promise.resolve(undefined);
                            }), (function (error) {
                            console.log("auth error", error);
                            Curry._1(setStatus, (function (param) {
                                    return "Error";
                                  }));
                            return Promise.resolve(undefined);
                          }));
                    
                  })
              }));
    } else {
      exit = 1;
    }
  } else {
    exit = 2;
  }
  switch (exit) {
    case 1 :
        tmp = React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                  children: "Invalid Auth URL",
                  color: Styles.Color.red100,
                  size: "Small"
                }), React.createElement(Spacer.make, {
                  height: 20
                }), React.createElement(Button.make, {
                  label: "Go Home",
                  onClick: (function (param) {
                      return Router.push(undefined, "/");
                    })
                }));
        break;
    case 2 :
        tmp = React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                  children: "Could not authenticate the Avo CLI",
                  color: Styles.Color.red100,
                  size: "Small"
                }), React.createElement(Spacer.make, {
                  height: 20
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.grey80,
                  children: "Try logging in again with"
                }), React.createElement("pre", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(12)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.grey80),
                          tl: {
                            hd: Css.color(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.fontFamily(Styles.monoFontFamily),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, "avo login"), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.grey80,
                  children: null
                }, "or ", React.createElement(TextButton.make, {
                      onClick: (function ($$event) {
                          $$event.preventDefault();
                          return Intercom.showNewMessage(undefined);
                        }),
                      style: "Blue",
                      children: "contact us"
                    }), "."));
        break;
    
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.height(Css.vh(100)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("flexStart"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.paddingRight(Css.px(40)),
                                tl: {
                                  hd: Css.paddingBottom(Css.px(40)),
                                  tl: {
                                    hd: Css.paddingLeft(Css.px(40)),
                                    tl: {
                                      hd: Css.borderRadius(Styles.Border.radius),
                                      tl: {
                                        hd: Css.width(Css.px(500)),
                                        tl: {
                                          hd: Css.maxWidth(Css.pct(100)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.position("relative"),
                            tl: {
                              hd: Css.marginTop(Css.px(-45)),
                              tl: {
                                hd: Css.marginLeft(Css.px(150)),
                                tl: {
                                  hd: Css.marginBottom(Css.px(24)),
                                  tl: {
                                    hd: Css.width(Css.px(96)),
                                    tl: {
                                      hd: Css.height(Css.px(96)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.position("absolute"),
                                tl: /* [] */0
                              })
                        }, React.createElement(IconTerminal.make, {
                              size: 96
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.position("absolute"),
                                tl: {
                                  hd: Css.bottom(Css.px(-5)),
                                  tl: {
                                    hd: Css.right(Css.px(-6)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement(IconLock.make, {
                              size: 48
                            }))), tmp));
}

var make = AuthorizeCli;

exports.make = make;
/* Css Not a pure module */
