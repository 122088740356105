// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");

function EmptyState$EmptyStateArtwork(Props) {
  var artwork = Props.artwork;
  if (artwork === "publishing") {
    return React.createElement("img", {
                className: Curry._1(Css.style, {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.maxWidth(Css.px(411)),
                        tl: {
                          hd: Css.objectFit("cover"),
                          tl: /* [] */0
                        }
                      }
                    }),
                src: (require('./assets/PublishingIntegrationsArtwork.png'))
              });
  } else if (artwork === "inspector") {
    return React.createElement("img", {
                className: Curry._1(Css.style, {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.maxWidth(Css.px(410)),
                        tl: {
                          hd: Css.objectFit("cover"),
                          tl: /* [] */0
                        }
                      }
                    }),
                src: (require('./assets/InspectorArtwork.png'))
              });
  } else if (artwork === "inspectorIssues") {
    return React.createElement("img", {
                className: Curry._1(Css.style, {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.maxWidth(Css.px(441)),
                        tl: {
                          hd: Css.objectFit("cover"),
                          tl: /* [] */0
                        }
                      }
                    }),
                src: (require('./assets/InspectorIssuesArtwork.png'))
              });
  } else {
    return React.createElement("img", {
                className: Curry._1(Css.style, {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.maxWidth(Css.px(441)),
                        tl: {
                          hd: Css.objectFit("cover"),
                          tl: /* [] */0
                        }
                      }
                    }),
                src: (require('./assets/CodegenArtwork.png'))
              });
  }
}

var EmptyStateArtwork = {
  make: EmptyState$EmptyStateArtwork
};

function EmptyState$LinkButtonWithIcon(Props) {
  var url = Props.url;
  var icon = Props.icon;
  var label = Props.label;
  var emptyStateLocation = Props.emptyStateLocation;
  var schemaGroup = SchemaGroupContext.use(undefined);
  return React.createElement("a", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.padding(Css.px(4)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(8)),
                          tl: {
                            hd: Css.paddingRight(Css.px(8)),
                            tl: {
                              hd: Css.background(Styles.Color.white),
                              tl: {
                                hd: Css.borderRadius(Css.px(5)),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                  tl: {
                                    hd: Css.textDecoration("none"),
                                    tl: {
                                      hd: Css.color(Styles.Color.grey70),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.borderColor(Styles.Color.grey40),
                                              tl: {
                                                hd: Css.color(Styles.Color.grey90),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: {
                                          hd: Css.transition({
                                                NAME: "ms",
                                                VAL: Styles.Duration.$$short
                                              }, undefined, undefined, "border-color"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              href: url,
              rel: "noopener",
              target: "_blank",
              onClick: (function (param) {
                  return AnalyticsRe.emptyStateInteracted(schemaGroup, emptyStateLocation, "OpenDocs", url);
                })
            }, icon, React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement($$Text.make, {
                  size: "Tiny",
                  weight: "Semi",
                  children: label
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginLeft(Css.px(2)),
                        tl: /* [] */0
                      })
                }, React.createElement("svg", {
                      height: "15",
                      width: "11",
                      fill: Styles.Color.toString(Styles.Color.grey50),
                      xmlns: "http://www.w3.org/2000/svg"
                    }, React.createElement("path", {
                          d: "M2.95 10.1l4.14-4.14-.01 3.13h1.14V4h-5.1v1.13h3.14L2.13 9.27l.82.83z"
                        }))));
}

var LinkButtonWithIcon = {
  make: EmptyState$LinkButtonWithIcon
};

function EmptyState$Container(Props) {
  var artwork = Props.artwork;
  var children = Props.children;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width({
                          NAME: "substract",
                          VAL: [
                            Css.pct(100.0),
                            Css.px(96)
                          ]
                        }),
                    tl: {
                      hd: Css.maxWidth(Css.px(1200)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                          tl: {
                            hd: Css.borderRadius(Css.px(5)),
                            tl: {
                              hd: Css.padding(Css.px(48)),
                              tl: {
                                hd: Css.paddingBottom(Css.px(60)),
                                tl: {
                                  hd: Css.display("grid"),
                                  tl: {
                                    hd: Css.columnGap(Css.px(32)),
                                    tl: {
                                      hd: Css.gridTemplateColumns({
                                            hd: {
                                              NAME: "minmax",
                                              VAL: [
                                                Css.px(440),
                                                {
                                                  NAME: "fr",
                                                  VAL: 1.0
                                                }
                                              ]
                                            },
                                            tl: {
                                              hd: {
                                                NAME: "minmax",
                                                VAL: [
                                                  Css.px(164),
                                                  {
                                                    NAME: "fr",
                                                    VAL: 1.0
                                                  }
                                                ]
                                              },
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(440)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: {
                                hd: Css.alignItems("flexStart"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, children), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(EmptyState$EmptyStateArtwork, {
                      artwork: artwork
                    })));
}

var Container = {
  make: EmptyState$Container
};

exports.EmptyStateArtwork = EmptyStateArtwork;
exports.LinkButtonWithIcon = LinkButtonWithIcon;
exports.Container = Container;
/* Css Not a pure module */
