// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Zustand = require("../Zustand.bs.js");
var Zustand$1 = require("zustand");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Dom_storage = require("rescript/lib/js/dom_storage.js");
var SnippetTypes = require("../detailviews/SnippetTypes.bs.js");

var diffViewTypeKey = "branchImplementationDiffViewType";

var StoreConfig = {};

var ZustandStore = Zustand.MakeStore(StoreConfig);

var store = Zustand$1.create(function (set, _get) {
      return {
              diffViewType: Belt_Option.getWithDefault(Belt_Option.flatMap(Dom_storage.getItem(diffViewTypeKey, localStorage), SnippetTypes.DiffViewType.tFromJs), "unified"),
              setDiffViewType: (function (diffViewType) {
                  return set(function (state) {
                              return {
                                      diffViewType: diffViewType,
                                      setDiffViewType: state.setDiffViewType
                                    };
                            });
                })
            };
    });

function useSetDiffViewType(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.setDiffViewType;
              }));
}

function useDiffViewType(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.diffViewType;
              }));
}

exports.diffViewTypeKey = diffViewTypeKey;
exports.StoreConfig = StoreConfig;
exports.ZustandStore = ZustandStore;
exports.store = store;
exports.useSetDiffViewType = useSetDiffViewType;
exports.useDiffViewType = useDiffViewType;
/* ZustandStore Not a pure module */
