// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function getVercelEnv(param) {
  var match = process.env.VERCEL_ENV;
  switch (match) {
    case "development" :
        return "development";
    case "preview" :
        return "preview";
    case "production" :
        return "production";
    default:
      return "unknown";
  }
}

function getEnv(param) {
  var match = process.env.NODE_ENV;
  if (match === "development") {
    return "development";
  } else {
    return "production";
  }
}

exports.getVercelEnv = getVercelEnv;
exports.getEnv = getEnv;
/* No side effect */
