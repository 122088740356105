// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Buffer = require("rescript/lib/js/buffer.js");
var Format = require("rescript/lib/js/format.js");

function pp_make_buffer(param) {
  return $$Buffer.create(512);
}

function str_formatter(param) {
  var buf = $$Buffer.create(512);
  return [
          buf,
          Format.formatter_of_buffer(buf)
        ];
}

function flush_buffer_formatter(buf, ppf) {
  Format.pp_print_flush(ppf, undefined);
  var s = $$Buffer.contents(buf);
  $$Buffer.reset(buf);
  return s;
}

var flush_str_formatter = flush_buffer_formatter;

var pp_buffer_size = 512;

exports.pp_buffer_size = pp_buffer_size;
exports.pp_make_buffer = pp_make_buffer;
exports.str_formatter = str_formatter;
exports.flush_buffer_formatter = flush_buffer_formatter;
exports.flush_str_formatter = flush_str_formatter;
/* Format Not a pure module */
