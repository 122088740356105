// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");

function DidMount(Props) {
  var didMount = Props.didMount;
  var didUnmount = Props.didUnmount;
  React.useEffect((function () {
          Curry._1(didMount, undefined);
          return didUnmount;
        }), []);
  return null;
}

var make = DidMount;

exports.make = make;
/* react Not a pure module */
