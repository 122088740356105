// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

var make = React.forwardRef(function (Props, ref_) {
      var style = Props.style;
      var className = Props.className;
      var onScroll = Props.onScroll;
      var children = Props.children;
      var tmp = {
        style: style,
        onScroll: onScroll
      };
      var tmp$1 = Belt_Option.map((ref_ == null) ? undefined : Caml_option.some(ref_), (function (prim) {
              return prim;
            }));
      if (tmp$1 !== undefined) {
        tmp.ref = Caml_option.valFromOption(tmp$1);
      }
      if (className !== undefined) {
        tmp.className = Caml_option.valFromOption(className);
      }
      return React.createElement("div", tmp, children);
    });

exports.make = make;
/* make Not a pure module */
