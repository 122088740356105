// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Styles = require("./styles.bs.js");

function IconCheckmarkCircle(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey50;
  return React.createElement("svg", {
              height: String(size),
              width: String(size),
              fill: "none",
              viewBox: "0 0 12 12",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM8.82322 3.82323C8.62796 3.62796 8.31137 3.62796 8.11611 3.82323L5 6.93934L3.88388 5.82323C3.68862 5.62796 3.37203 5.62796 3.17677 5.82323L2.82322 6.17678C2.62796 6.37204 2.62796 6.68862 2.82322 6.88389L4.82322 8.88389C4.92085 8.98152 5.07914 8.98152 5.17677 8.88389L9.17677 4.88389C9.37204 4.68862 9.37204 4.37204 9.17677 4.17678L8.82322 3.82323Z",
                  fill: Styles.Color.toString(color),
                  fillRule: "evenodd"
                }));
}

var make = IconCheckmarkCircle;

exports.make = make;
/* react Not a pure module */
