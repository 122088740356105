// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Case = require("../../bs-case/src/case.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("./Icons.bs.js");
var Toast = require("./Toast.bs.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var TextButton = require("./TextButton.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var FramerMotion = require("framer-motion");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var DiscrepancyContext = require("./DiscrepancyContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");
var BranchDiscrepancies = require("./BranchDiscrepancies.bs.js");

function getIsIssueCategoryEnabled(issue) {
  return issue.VAL[0];
}

function issueCategoryToDescription(expectedEventCase, expectedPropertyCase, issueCategory) {
  var variant = issueCategory.NAME;
  if (variant === "SameEventName") {
    return "Another event has the same or similar name";
  } else if (variant === "SamePropertyName") {
    return "Another property has the same or similar name";
  } else if (variant === "PropertiesWithAnyType") {
    return "The property does not have a defined type";
  } else if (variant === "EventsWithoutDescription") {
    return "The event does not have a description";
  } else if (variant === "EventsWithUnexpectedCase") {
    return "Event name is not in " + Case.tToJs(expectedEventCase) + " case";
  } else if (variant === "PropertyConflictOnEvent") {
    return "Conflicting properties";
  } else if (variant === "PropertiesWithoutDescription") {
    return "The property does not have a description";
  } else {
    return "Property name is not in " + Case.tToJs(expectedPropertyCase) + " case";
  }
}

var title = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: /* [] */0
      }
    });

var expandButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.top(Css.px(12)),
                tl: {
                  hd: Css.right("zero"),
                  tl: {
                    hd: Css.padding(Css.px(6)),
                    tl: {
                      hd: Css.hover({
                            hd: Css.selector(" p", {
                                  hd: Css.color(Styles.Color.blue),
                                  tl: /* [] */0
                                }),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var issueButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.padding2(Css.px(2), Css.px(6)),
              tl: {
                hd: Css.margin(Css.px(0)),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.orangeSecondary),
                  tl: {
                    hd: Css.borderRadius(Css.px(16)),
                    tl: {
                      hd: Css.active({
                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.focus({
                              hd: Css.outlineStyle("none"),
                              tl: {
                                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.blue)),
                                tl: /* [] */0
                              }
                            }),
                        tl: {
                          hd: Css.hover({
                                hd: Css.backgroundColor(Styles.Color.orange),
                                tl: /* [] */0
                              }),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("row"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.height(Css.px(20)),
                                    tl: {
                                      hd: Css.selector(" p", {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.justifyContent("center"),
                                                tl: {
                                                  hd: Css.gap(Css.px(4)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var issues = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.overflowX("hidden"),
        tl: /* [] */0
      }
    });

var issue = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.marginTop(Css.px(8)),
            tl: {
              hd: Css.firstOfType({
                    hd: Css.marginTop(Css.px(16)),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var issueHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.gap(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

var icon = Curry._1(Css.style, {
      hd: Css.width(Css.px(16)),
      tl: {
        hd: Css.height(Css.px(16)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.orange),
          tl: {
            hd: Css.borderRadius(Css.pct(100.0)),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.justifyContent("center"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.flexDirection("column"),
                    tl: {
                      hd: Css.paddingBottom(Css.px(1)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var suggestionButton = Curry._1(Css.style, {
      hd: Css.width("fitContent"),
      tl: {
        hd: Css.marginLeft(Css.px(24)),
        tl: {
          hd: Css.marginTop(Css.px(0)),
          tl: /* [] */0
        }
      }
    });

var Style = {
  title: title,
  expandButton: expandButton,
  issueButton: issueButton,
  issues: issues,
  issue: issue,
  issueHeader: issueHeader,
  icon: icon,
  suggestionButton: suggestionButton
};

function DiffItemAuditIssues(Props) {
  var branchStatus = Props.branchStatus;
  var itemId = Props.itemId;
  var children = Props.children;
  var addToast = Toast.useAddToast(undefined);
  var schemaGroup = SchemaGroupContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = BranchDiscrepancies.use(undefined);
  var issuesById = match.issuesById;
  var branchAuditWarningCount = match.branchAuditWarningCount;
  var match$1 = React.useContext(DiscrepancyContext.context);
  var expectedPropertyCase = match$1.expectedPropertyCase;
  var expectedEventCase = match$1.expectedEventCase;
  var numSchemaIssues = match$1.warningCount;
  var branchIsMerged;
  branchIsMerged = typeof branchStatus === "number" || branchStatus.TAG !== /* Merged */1 ? false : true;
  var itemIssues = React.useMemo((function () {
          return Js_dict.get(issuesById, itemId);
        }), [
        issuesById,
        itemId
      ]);
  var enabledItemIssues = React.useMemo((function () {
          return Belt_Option.map(itemIssues, (function (itemIssues) {
                        return Belt_Array.keepMap(itemIssues, (function (issueItem) {
                                      if (issueItem.issue.VAL[0]) {
                                        return issueItem;
                                      }
                                      
                                    }));
                      }));
        }), [itemIssues]);
  var match$2 = React.useState(function () {
        return false;
      });
  var setIssuesExpanded = match$2[1];
  var issuesExpanded = match$2[0];
  var match$3 = AvoLimits.ExpandedBranchAudit.computeAvailability(workspace);
  var tmp;
  if ((match$3 === "AvailableDuringTrial" || match$3 === "Available") && typeof branchStatus !== "number") {
    switch (branchStatus.TAG | 0) {
      case /* Merged */1 :
      case /* Closed */2 :
          tmp = null;
          break;
      default:
        tmp = enabledItemIssues !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                    width: 16
                  }), React.createElement("button", {
                    className: issueButton,
                    onClick: (function (e) {
                        e.stopPropagation();
                        Curry._1(setIssuesExpanded, (function (state) {
                                return !state;
                              }));
                        return AnalyticsRe.issueExpanded(schemaGroup, numSchemaIssues, undefined, "BranchReview", branchAuditWarningCount, false, schemaGroup.branchId, schemaGroup.schemaId);
                      })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.white,
                        children: null
                      }, React.createElement(Icons.Warning.make, {
                            size: 14,
                            color: Styles.Color.white
                          }), String(enabledItemIssues.length)))) : null;
    }
  } else {
    tmp = null;
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: title
                }, React.createElement("div", undefined, children), React.createElement(Spacer.make, {
                      grow: 1.0
                    }), tmp), React.createElement(FramerMotion.AnimatePresence, {
                  children: enabledItemIssues !== undefined && issuesExpanded ? React.createElement(FramerMotion.motion.div, {
                          animate: {
                            height: "auto",
                            opacity: 1.0,
                            "margin-bottom": "-8px"
                          },
                          transition: {
                            duration: 0.2
                          },
                          initial: {
                            height: "0px",
                            opacity: 0.0,
                            "margin-bottom": "0px"
                          },
                          exit: {
                            height: "0px",
                            opacity: 0.0,
                            "margin-bottom": "0px"
                          },
                          className: issues,
                          key: itemId,
                          children: null
                        }, React.createElement(FramerMotion.motion.span, {
                              animate: {
                                opacity: 1.0
                              },
                              transition: {
                                duration: 0.5,
                                delay: 0.3
                              },
                              initial: {
                                opacity: 0.0
                              },
                              exit: {
                                opacity: 0.0
                              },
                              key: itemId + "button",
                              children: React.createElement("button", {
                                    className: expandButton,
                                    onClick: (function (e) {
                                        e.stopPropagation();
                                        return Curry._1(setIssuesExpanded, (function (state) {
                                                      return !state;
                                                    }));
                                      })
                                  }, React.createElement($$Text.make, {
                                        size: "Small",
                                        weight: "Semi",
                                        color: Styles.Color.grey90,
                                        children: issuesExpanded ? "Hide audit" : "Show audit"
                                      }))
                            }), Belt_Array.map(enabledItemIssues, (function (item) {
                                var suggestionCallback = item.suggestionCallback;
                                var suggestion = item.suggestion;
                                var issueCategory = item.issue;
                                return React.createElement("div", {
                                            key: issueCategoryToDescription(expectedEventCase, expectedPropertyCase, issueCategory),
                                            className: issue
                                          }, React.createElement("div", {
                                                className: issueHeader
                                              }, React.createElement("span", {
                                                    className: icon
                                                  }, React.createElement(Icons.Warning.make, {
                                                        size: 9,
                                                        color: Styles.Color.white
                                                      })), React.createElement($$Text.make, {
                                                    size: "Small",
                                                    weight: "Semi",
                                                    color: Styles.Color.grey90,
                                                    children: issueCategoryToDescription(expectedEventCase, expectedPropertyCase, issueCategory)
                                                  })), suggestion !== undefined ? (
                                              suggestionCallback !== undefined ? React.createElement("div", {
                                                      className: suggestionButton
                                                    }, React.createElement(TextButton.make, {
                                                          onClick: (function (e) {
                                                              e.stopPropagation();
                                                              Curry._2(suggestionCallback, sendActions, addToast);
                                                              var variant = issueCategory.NAME;
                                                              return AnalyticsRe.auditIssueInteracted(schemaGroup, item.id, item.name, item.type_, "InlineReview", "FixIssue", variant === "SameEventName" ? "SameNameEvents" : (
                                                                            variant === "SamePropertyName" ? "SameNameProperty" : (
                                                                                variant === "PropertiesWithAnyType" ? "PropertyTypeNotDefined" : (
                                                                                    variant === "EventsWithoutDescription" ? "EventMissingDescription" : (
                                                                                        variant === "EventsWithUnexpectedCase" ? "EventCommonCasingDiscrepancy" : (
                                                                                            variant === "PropertyConflictOnEvent" ? "PropertyConflicts" : (
                                                                                                variant === "PropertiesWithoutDescription" ? "PropertyMissingDescription" : "PropertyCommonCasingDiscrepancy"
                                                                                              )
                                                                                          )
                                                                                      )
                                                                                  )
                                                                              )
                                                                          ), schemaGroup.schemaId, schemaGroup.branchId);
                                                            }),
                                                          style: "Blue",
                                                          size: "Small",
                                                          disabled: branchIsMerged,
                                                          children: suggestion
                                                        })) : React.createElement($$Text.make, {
                                                      size: "Small",
                                                      color: Styles.Color.grey80,
                                                      children: suggestion
                                                    })
                                            ) : null);
                              }))) : null
                }));
}

var make = DiffItemAuditIssues;

exports.getIsIssueCategoryEnabled = getIsIssueCategoryEnabled;
exports.issueCategoryToDescription = issueCategoryToDescription;
exports.Style = Style;
exports.make = make;
/* title Not a pure module */
