// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Guides = require("./markdown/guides.bs.js");
var Styles = require("./styles.bs.js");
var Markdown = require("./Markdown.bs.js");
var IconArrowUp = require("./IconArrowUp.bs.js");
var IconArrowDown = require("./IconArrowDown.bs.js");

function ImplementCLI(Props) {
  var match = React.useState(function () {
        return true;
      });
  var setCollapsed = match[1];
  var collapsed = match[0];
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("block"),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                          tl: {
                            hd: Css.marginTop(Css.px(12)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("button", {
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.button),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.width(Css.pct(100)),
                                  tl: {
                                    hd: Css.padding(Css.px(12)),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.textAlign("left"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function (param) {
                      return Curry._1(setCollapsed, (function (collapsed) {
                                    return !collapsed;
                                  }));
                    })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.margin(Css.px(0)),
                                tl: {
                                  hd: Css.width(Css.pct(100)),
                                  tl: {
                                    hd: Css.color(Styles.Color.grey90),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          children: "Pull the Avo file with the Avo CLI"
                        })), collapsed ? React.createElement(IconArrowDown.make, {
                        size: 12,
                        color: Styles.Color.grey70
                      }) : React.createElement(IconArrowUp.make, {
                        size: 12,
                        color: Styles.Color.grey70
                      })), collapsed ? null : React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.fontSize(Styles.FontSize.regular),
                          tl: {
                            hd: Css.padding2(Css.px(0), Css.px(15)),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(Markdown.make, {
                        source: Guides.setup
                      })));
}

var make = ImplementCLI;

exports.make = make;
/* Css Not a pure module */
