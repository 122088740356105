// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Case = require("../../bs-case/src/case.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Title = require("./Title.bs.js");
var React = require("react");
var Select = require("./Select.bs.js");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Switch = require("./Switch.bs.js");
var AvoLimits = require("./AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var IconError = require("./IconError.bs.js");
var NameUtils = require("../../shared/utils/NameUtils.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Discrepancies = require("./Discrepancies.bs.js");
var FirebaseUtils = require("./firebaseUtils.bs.js");
var IconCheckmark = require("./IconCheckmark.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var EntityLightning = require("./EntityLightning.bs.js");
var ModalCloseButton = require("./ModalCloseButton.bs.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var DiscrepancyContext = require("./DiscrepancyContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");

var casingOptions = [
  "None",
  "Sentence",
  "Title",
  "Pascal",
  "Header",
  "Capital",
  "Upper",
  "Kebab",
  "Camel",
  "Constant",
  "Snake",
  "Lower"
];

function caseToLabel($$case) {
  if ($$case === "Capital") {
    return "Capital Case";
  } else if ($$case === "Kebab") {
    return "kebab-case";
  } else if ($$case === "Sentence") {
    return "Sentence case";
  } else if ($$case === "Constant") {
    return "CONSTANT_CASE";
  } else if ($$case === "Lower") {
    return "lower case";
  } else if ($$case === "Snake") {
    return "snake_case";
  } else if ($$case === "Pascal") {
    return "PascalCase";
  } else if ($$case === "Title") {
    return "Title Case";
  } else if ($$case === "Header") {
    return "Header-Case";
  } else if ($$case === "None") {
    return "Inherit";
  } else if ($$case === "Upper") {
    return "UPPER CASE";
  } else {
    return "camelCase";
  }
}

function caseToAnalytics($$case) {
  if ($$case === "Capital") {
    return "CapitalCase";
  } else if ($$case === "Kebab") {
    return "KebabCase";
  } else if ($$case === "Sentence") {
    return "SentenceCase";
  } else if ($$case === "Constant") {
    return "ConstantCase";
  } else if ($$case === "Lower") {
    return "LowerCase";
  } else if ($$case === "Snake") {
    return "SnakeCase";
  } else if ($$case === "Pascal") {
    return "PascalCase";
  } else if ($$case === "Title") {
    return "TitleCase";
  } else if ($$case === "Header") {
    return "HeaderCase";
  } else if ($$case === "None") {
    return "Inherited";
  } else if ($$case === "Upper") {
    return "UpperCase";
  } else {
    return "CamelCase";
  }
}

function getOptionForCasing($$case, commonCase) {
  return [
          {
            NAME: "Label",
            VAL: $$case === "None" ? "Inherit (" + caseToLabel(commonCase) + ")" : caseToLabel($$case)
          },
          Case.tToJs($$case)
        ];
}

var configUpgradePrompt = {
  TAG: /* OpenModal */4,
  _0: {
    NAME: "BillingPrompt",
    VAL: "IssueReportConfig"
  }
};

var forceCasingUpgradePrompt = {
  TAG: /* OpenModal */4,
  _0: {
    NAME: "BillingPrompt",
    VAL: "IssueReportConfigForceCasing"
  }
};

var container = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.overflow("auto"),
            tl: {
              hd: Css.maxWidth(Css.px(800)),
              tl: {
                hd: Css.height(Css.pct(100)),
                tl: {
                  hd: Css.paddingTop(Css.px(48)),
                  tl: {
                    hd: Css.paddingRight(Css.px(60)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(48)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(60)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.grey10),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var docsLink = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.blue),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.textDecoration("underline"),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: Styles.Duration.$$short
                }, undefined, undefined, "color"),
            tl: {
              hd: Css.hover({
                    hd: Css.color(Styles.Color.blueSecondary),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var content = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.paddingTop(Css.px(40)),
        tl: /* [] */0
      }
    });

var config = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(14)),
      tl: /* [] */0
    });

var row = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var popupBox = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.alignSelf("flexStart"),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.marginLeft(Css.px(60)),
            tl: {
              hd: Css.border(Css.px(2), "solid", Styles.Color.grey30),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var Style = {
  container: container,
  docsLink: docsLink,
  content: content,
  config: config,
  row: row,
  popupBox: popupBox
};

function DiscrepanciesConfig(Props) {
  var onClose = Props.onClose;
  var schemaId = Props.schemaId;
  var model = Props.model;
  var viewerRole = Props.viewerRole;
  var globalSend = Props.globalSend;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var warningCount = match.warningCount;
  var config$1 = match.config;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var configAvailability = AvoLimits.DiscrepancyConfig.computeAvailability(workspace);
  var configPlanHint = AvoLimits.DiscrepancyConfig.availableOnPlan(workspace);
  var forceCasingAvailability = AvoLimits.ForcePreferredCasing.computeAvailability(workspace);
  var forceCasingPlanHint = AvoLimits.ForcePreferredCasing.availableOnPlan(workspace);
  var viewerCanEdit = viewerRole === "Admin";
  React.useEffect((function () {
          AnalyticsRe.issueReportConfigOpened(schemaGroup, warningCount, AnalyticsUtils.configToConfigsOnAnalytics(config$1), caseToAnalytics(config$1.preferredEventCase), caseToAnalytics(config$1.preferredPropertyCase), AnalyticsUtils.roleToAnalyticsRole(viewerRole), schemaGroup.branchId, schemaGroup.schemaId);
          
        }), []);
  var handleChange = function (item, action, update) {
    FirebaseUtils.setValidationConfig(schemaId, update);
    return AnalyticsRe.issueReportConfigured(schemaGroup, AnalyticsRe.Group.issueReportConfig(caseToAnalytics(config$1.preferredEventCase), caseToAnalytics(config$1.preferredPropertyCase), warningCount, AnalyticsUtils.configToConfigsOnAnalytics(config$1), AnalyticsUtils.configToConfigsOnAnalytics(config$1).length), item, action, AnalyticsUtils.roleToAnalyticsRole(viewerRole), schemaId, schemaGroup.branchId, schemaId, AnalyticsUtils.configToConfigsOnAnalytics(config$1), caseToAnalytics(config$1.preferredEventCase), caseToAnalytics(config$1.preferredPropertyCase));
  };
  var eventNames = StateUtils.getEventNames(model.events);
  var commonEventNameCase = NameUtils.getCommonCase(eventNames);
  var propertyNames = StateUtils.getPropertyNames(model.properties);
  var commonPropertyNameCase = NameUtils.getCommonCase(propertyNames);
  var eventsInCommonCaseCount = Belt_List.length(Belt_List.keepU(eventNames, (function (name) {
              return Case.to_(commonEventNameCase, name) === name;
            })));
  var commonCasePercentage = eventsInCommonCaseCount / Belt_List.length(eventNames) * 100.0;
  var propertiesInCommonCaseCount = Belt_List.length(Belt_List.keepU(propertyNames, (function (name) {
              return Case.to_(commonPropertyNameCase, name) === name;
            })));
  var commonCasePercentage$1 = propertiesInCommonCaseCount / Belt_List.length(propertyNames) * 100.0;
  return React.createElement("div", {
              className: container,
              id: "audit-tour-config"
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.px(16)),
                          tl: {
                            hd: Css.right(Css.px(16)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(ModalCloseButton.make, {
                      onClick: (function (param) {
                          return Curry._1(onClose, undefined);
                        })
                    })), React.createElement(Title.make, {
                  children: "Tracking Plan Audit Config",
                  size: "Medium"
                }), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement($$Text.make, {
                  size: "Large",
                  color: Styles.Color.grey70,
                  maxWidth: Css.px(480),
                  children: null
                }, "Avo validates your tracking plan in real-time to make help you progress towards a well defined tracking plan. Configure which aspects of you want to validate and define default naming conventions to ensure clean and consistent data. Read more in the ", React.createElement("a", {
                      className: docsLink,
                      href: "https://www.avo.app/docs/audit/rules"
                    }, "docs"), "."), viewerCanEdit ? null : React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                        height: 24
                      }), React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Semi",
                        color: Styles.Color.orangeSecondary,
                        children: "Only members with admin access can edit these workspace settings."
                      })), React.createElement("div", {
                  className: content
                }, React.createElement("div", {
                      className: config
                    }, React.createElement("div", {
                          className: row
                        }, React.createElement($$Text.make, {
                              size: "Large",
                              weight: "Semi",
                              color: Styles.Color.grey90,
                              children: "No conflicting properties"
                            }), React.createElement(Spacer.make, {
                              width: 8,
                              grow: 1.0
                            }), React.createElement(EntityLightning.make, {
                              availability: configAvailability,
                              planHint: configPlanHint,
                              fill: Styles.Color.grey50
                            }), React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement(Switch.make, {
                              value: config$1.propertyConflictOnEvent,
                              onChange: (function (toggled) {
                                  if (configAvailability === "Unavailable") {
                                    return Curry._1(globalSend, configUpgradePrompt);
                                  } else {
                                    return handleChange("NoConflictingProperties", toggled ? "On" : "Off", {
                                                propertyConflictOnEvent: toggled
                                              });
                                  }
                                }),
                              faded: configAvailability === "Unavailable",
                              disabled: !viewerCanEdit
                            })), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.grey70,
                          children: "Checks that all properties with unique type definitions have a unique name."
                        }), React.createElement(Spacer.make, {
                          height: 32
                        }), React.createElement("div", {
                          className: row
                        }, React.createElement($$Text.make, {
                              size: "Large",
                              weight: "Semi",
                              color: Styles.Color.grey90,
                              children: "Unique event names"
                            }), React.createElement(Spacer.make, {
                              width: 8,
                              grow: 1.0
                            }), React.createElement(EntityLightning.make, {
                              availability: configAvailability,
                              planHint: configPlanHint,
                              fill: Styles.Color.grey50
                            }), React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement(Switch.make, {
                              value: config$1.sameEventName,
                              onChange: (function (toggled) {
                                  if (configAvailability === "Unavailable") {
                                    return Curry._1(globalSend, configUpgradePrompt);
                                  } else {
                                    return handleChange("UniqueEventNames", toggled ? "On" : "Off", {
                                                sameEventName: toggled
                                              });
                                  }
                                }),
                              faded: configAvailability === "Unavailable",
                              disabled: !viewerCanEdit
                            })), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.grey70,
                          children: "Checks that there is only one name being used for each event to avoid confusion and conflicts in downstream tools."
                        }), React.createElement(Spacer.make, {
                          height: 32
                        }), React.createElement("div", {
                          className: row
                        }, React.createElement($$Text.make, {
                              size: "Large",
                              weight: "Semi",
                              color: Styles.Color.grey90,
                              children: "Consistent event name casing"
                            }), React.createElement(Spacer.make, {
                              width: 8,
                              grow: 1.0
                            }), React.createElement(EntityLightning.make, {
                              availability: configAvailability,
                              planHint: configPlanHint,
                              fill: Styles.Color.grey50
                            }), React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement(Switch.make, {
                              value: config$1.eventsWithUnexpectedCase,
                              onChange: (function (toggled) {
                                  if (configAvailability === "Unavailable") {
                                    return Curry._1(globalSend, configUpgradePrompt);
                                  } else {
                                    return handleChange("ConsistentEventNameCasing", toggled ? "On" : "Off", {
                                                eventsWithUnexpectedCase: toggled
                                              });
                                  }
                                }),
                              faded: configAvailability === "Unavailable",
                              disabled: !viewerCanEdit
                            })), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.grey70,
                          children: "Checks that event name casing is consistent across all events to maximize data accessibility."
                        }), React.createElement(Spacer.make, {
                          height: 16
                        }), React.createElement("div", {
                          className: row
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.grey80,
                              children: "Preferred casing"
                            }), React.createElement(Spacer.make, {
                              width: 12
                            }), React.createElement(EntityLightning.make, {
                              availability: configAvailability,
                              planHint: configPlanHint,
                              fill: Styles.Color.grey50
                            }), configAvailability === "Available" ? null : React.createElement(Spacer.make, {
                                width: 8
                              }), React.createElement(Select.make, {
                              disabled: !config$1.eventsWithUnexpectedCase || !viewerCanEdit,
                              onSelect: (function (selected) {
                                  if (configAvailability === "Unavailable") {
                                    return Curry._1(globalSend, configUpgradePrompt);
                                  } else {
                                    return handleChange("PreferredEventNameCasing", caseToAnalytics(Belt_Option.getWithDefault(Case.tFromJs(selected), "None")), {
                                                preferredEventCase: selected
                                              });
                                  }
                                }),
                              options: Belt_List.fromArray(Belt_Array.map(casingOptions, (function ($$case) {
                                          return getOptionForCasing($$case, commonEventNameCase);
                                        }))),
                              value: Case.tToJs(config$1.preferredEventCase)
                            })), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.grey70,
                          children: null
                        }, "When set to ", React.createElement("em", undefined, "Inherit"), ", Avo will use your workspaces’ most used event name casing. ", commonCasePercentage.toFixed() + "% of your event names are currently in " + Case.tToJs(commonEventNameCase) + " case."), React.createElement(Spacer.make, {
                          height: 16
                        }), React.createElement("div", {
                          className: row
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.grey80,
                              children: "Force casing"
                            }), React.createElement(Spacer.make, {
                              width: 12
                            }), React.createElement(EntityLightning.make, {
                              availability: forceCasingAvailability,
                              planHint: forceCasingPlanHint,
                              fill: Styles.Color.grey50
                            }), forceCasingAvailability === "Available" ? null : React.createElement(Spacer.make, {
                                width: 8
                              }), React.createElement(Switch.make, {
                              value: config$1.forceEventCase,
                              onChange: (function (toggled) {
                                  if (forceCasingAvailability === "Unavailable") {
                                    return Curry._1(globalSend, forceCasingUpgradePrompt);
                                  } else {
                                    return handleChange("ForceEventCasing", toggled ? "On" : "Off", {
                                                forceEventCase: toggled
                                              });
                                  }
                                }),
                              faded: forceCasingAvailability === "Unavailable",
                              size: "Small",
                              disabled: !config$1.eventsWithUnexpectedCase || !viewerCanEdit
                            })), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.grey70,
                          children: "User will get a warning if their event name doesn’t match the current format. Force casing to only allow creating and renaming events that match the preferred casing."
                        }), React.createElement(Spacer.make, {
                          height: 32
                        }), React.createElement("div", {
                          className: row
                        }, React.createElement($$Text.make, {
                              size: "Large",
                              weight: "Semi",
                              color: Styles.Color.grey90,
                              children: "Unique property names"
                            }), React.createElement(Spacer.make, {
                              width: 8,
                              grow: 1.0
                            }), React.createElement(EntityLightning.make, {
                              availability: configAvailability,
                              planHint: configPlanHint,
                              fill: Styles.Color.grey50
                            }), React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement(Switch.make, {
                              value: config$1.samePropertyName,
                              onChange: (function (toggled) {
                                  if (configAvailability === "Unavailable") {
                                    return Curry._1(globalSend, configUpgradePrompt);
                                  } else {
                                    return handleChange("UniquePropertyNames", toggled ? "On" : "Off", {
                                                samePropertyName: toggled
                                              });
                                  }
                                }),
                              faded: configAvailability === "Unavailable",
                              disabled: !viewerCanEdit
                            })), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.grey70,
                          children: "Checks that there is only one name being used for each property to avoid confusion and conflicts in downstream tools."
                        }), React.createElement(Spacer.make, {
                          height: 32
                        }), React.createElement("div", {
                          className: row
                        }, React.createElement($$Text.make, {
                              size: "Large",
                              weight: "Semi",
                              color: Styles.Color.grey90,
                              children: "Consistent property name casing"
                            }), React.createElement(Spacer.make, {
                              width: 8,
                              grow: 1.0
                            }), React.createElement(EntityLightning.make, {
                              availability: configAvailability,
                              planHint: configPlanHint,
                              fill: Styles.Color.grey50
                            }), React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement(Switch.make, {
                              value: config$1.propertiesWithUnexpectedCase,
                              onChange: (function (toggled) {
                                  if (configAvailability === "Unavailable") {
                                    return Curry._1(globalSend, configUpgradePrompt);
                                  } else {
                                    return handleChange("ConsistentPropertyNameCasing", toggled ? "On" : "Off", {
                                                propertiesWithUnexpectedCase: toggled
                                              });
                                  }
                                }),
                              faded: configAvailability === "Unavailable",
                              disabled: !viewerCanEdit
                            })), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.grey70,
                          children: "Checks that property name casing is consistent across all properties to maximize data accessibility."
                        }), React.createElement(Spacer.make, {
                          height: 16
                        }), React.createElement("div", {
                          className: row
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.grey80,
                              children: "Preferred casing"
                            }), React.createElement(Spacer.make, {
                              width: 12
                            }), React.createElement(EntityLightning.make, {
                              availability: configAvailability,
                              planHint: configPlanHint,
                              fill: Styles.Color.grey50
                            }), configAvailability === "Available" ? null : React.createElement(Spacer.make, {
                                width: 8
                              }), React.createElement(Select.make, {
                              disabled: !config$1.propertiesWithUnexpectedCase || !viewerCanEdit,
                              onSelect: (function (selected) {
                                  if (configAvailability === "Unavailable") {
                                    return Curry._1(globalSend, configUpgradePrompt);
                                  } else {
                                    return handleChange("PreferredPropertyNameCasing", caseToAnalytics(Belt_Option.getWithDefault(Case.tFromJs(selected), "None")), {
                                                preferredPropertyCase: selected
                                              });
                                  }
                                }),
                              options: Belt_List.fromArray(Belt_Array.map(casingOptions, (function ($$case) {
                                          return getOptionForCasing($$case, commonPropertyNameCase);
                                        }))),
                              value: Case.tToJs(config$1.preferredPropertyCase)
                            })), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.grey70,
                          children: null
                        }, "When set to ", React.createElement("em", undefined, "Inherit"), ", Avo will use your workspaces’ most used property name casing. ", commonCasePercentage$1.toFixed() + "% of your property names are currently in " + Case.tToJs(commonPropertyNameCase) + " case."), React.createElement(Spacer.make, {
                          height: 16
                        }), React.createElement("div", {
                          className: row
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.grey80,
                              children: "Force casing"
                            }), React.createElement(Spacer.make, {
                              width: 12
                            }), React.createElement(EntityLightning.make, {
                              availability: forceCasingAvailability,
                              planHint: forceCasingPlanHint,
                              fill: Styles.Color.grey50
                            }), forceCasingAvailability === "Available" ? null : React.createElement(Spacer.make, {
                                width: 8
                              }), React.createElement(Switch.make, {
                              value: config$1.forcePropertyCase,
                              onChange: (function (toggled) {
                                  if (forceCasingAvailability === "Unavailable") {
                                    return Curry._1(globalSend, forceCasingUpgradePrompt);
                                  } else {
                                    return handleChange("ForcePropertyCasing", toggled ? "On" : "Off", {
                                                forcePropertyCase: toggled
                                              });
                                  }
                                }),
                              faded: forceCasingAvailability === "Unavailable",
                              size: "Small",
                              disabled: !config$1.propertiesWithUnexpectedCase || !viewerCanEdit
                            })), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.grey70,
                          children: "User will get a warning if their property doesn’t match the current format. Force casing to only allow creating and renaming property that match the preferred casing."
                        }), React.createElement(Spacer.make, {
                          height: 32
                        }), React.createElement("div", {
                          className: row
                        }, React.createElement($$Text.make, {
                              size: "Large",
                              weight: "Semi",
                              color: Styles.Color.grey90,
                              children: "All properties have types"
                            }), React.createElement(Spacer.make, {
                              width: 8,
                              grow: 1.0
                            }), React.createElement(EntityLightning.make, {
                              availability: configAvailability,
                              planHint: configPlanHint,
                              fill: Styles.Color.grey50
                            }), React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement(Switch.make, {
                              value: config$1.propertiesWithAnyType,
                              onChange: (function (toggled) {
                                  if (configAvailability === "Unavailable") {
                                    return Curry._1(globalSend, configUpgradePrompt);
                                  } else {
                                    return handleChange("AllPropertiesHaveTypes", toggled ? "On" : "Off", {
                                                propertiesWithAnyType: toggled
                                              });
                                  }
                                }),
                              faded: configAvailability === "Unavailable",
                              disabled: !viewerCanEdit
                            })), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.grey70,
                          children: "Checks that each property has at least it’s base type defined. Well defined types for all property values are a major time-saver when querying data and help avoid unexpected problems."
                        }), React.createElement(Spacer.make, {
                          height: 32
                        }), React.createElement("div", {
                          className: row
                        }, React.createElement($$Text.make, {
                              size: "Large",
                              weight: "Semi",
                              color: Styles.Color.grey90,
                              children: "All events have descriptions"
                            }), React.createElement(Spacer.make, {
                              width: 8,
                              grow: 1.0
                            }), React.createElement(EntityLightning.make, {
                              availability: configAvailability,
                              planHint: configPlanHint,
                              fill: Styles.Color.grey50
                            }), React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement(Switch.make, {
                              value: config$1.eventsWithoutDescription,
                              onChange: (function (toggled) {
                                  if (configAvailability === "Unavailable") {
                                    return Curry._1(globalSend, configUpgradePrompt);
                                  } else {
                                    return handleChange("AllEventsHaveDescriptions", toggled ? "On" : "Off", {
                                                eventsWithoutDescription: toggled
                                              });
                                  }
                                }),
                              faded: configAvailability === "Unavailable",
                              disabled: !viewerCanEdit
                            })), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.grey70,
                          children: "A description helps the whole team understand the purpose or context of an event."
                        }), React.createElement(Spacer.make, {
                          height: 32
                        }), React.createElement("div", {
                          className: row
                        }, React.createElement($$Text.make, {
                              size: "Large",
                              weight: "Semi",
                              color: Styles.Color.grey90,
                              children: "All properties have descriptions"
                            }), React.createElement(Spacer.make, {
                              width: 8,
                              grow: 1.0
                            }), React.createElement(EntityLightning.make, {
                              availability: configAvailability,
                              planHint: configPlanHint,
                              fill: Styles.Color.grey50
                            }), React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement(Switch.make, {
                              value: config$1.propertiesWithoutDescription,
                              onChange: (function (toggled) {
                                  if (configAvailability === "Unavailable") {
                                    return Curry._1(globalSend, configUpgradePrompt);
                                  } else {
                                    return handleChange("AllPropertiesHaveDescriptions", toggled ? "On" : "Off", {
                                                propertiesWithoutDescription: toggled
                                              });
                                  }
                                }),
                              faded: configAvailability === "Unavailable",
                              disabled: !viewerCanEdit
                            })), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.grey70,
                          children: "A description helps the whole team understand what the property is describing, and how it's value should be fetched."
                        })), React.createElement("div", {
                      className: popupBox
                    }, React.createElement(Discrepancies.Popup.make, {
                          discrepancies: match.discrepancies,
                          schemaGroup: schemaGroup,
                          warningCount: warningCount,
                          headerExtra: warningCount === 0 ? React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.alignSelf("flexEnd"),
                                        tl: {
                                          hd: Css.paddingTop(Css.px(4)),
                                          tl: {
                                            hd: Css.paddingRight(Css.px(6)),
                                            tl: {
                                              hd: Css.paddingBottom(Css.px(4)),
                                              tl: {
                                                hd: Css.paddingLeft(Css.px(6)),
                                                tl: {
                                                  hd: Css.borderRadius(Css.px(16)),
                                                  tl: {
                                                    hd: Css.backgroundColor(Styles.Color.mintGreen),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement(IconCheckmark.make, {
                                      size: 10,
                                      color: Styles.Color.white
                                    })) : React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignSelf("flexEnd"),
                                          tl: {
                                            hd: Css.borderRadius(Css.px(16)),
                                            tl: {
                                              hd: Css.paddingTop(Css.px(2)),
                                              tl: {
                                                hd: Css.paddingRight(Css.px(6)),
                                                tl: {
                                                  hd: Css.paddingBottom(Css.px(2)),
                                                  tl: {
                                                    hd: Css.paddingLeft(Css.px(6)),
                                                    tl: {
                                                      hd: Css.backgroundColor(Styles.Color.orange),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement(IconError.make, {
                                      size: 11,
                                      color: Styles.Color.white
                                    }), React.createElement(Spacer.make, {
                                      width: 4
                                    }), React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Semi",
                                      color: Styles.Color.white,
                                      children: String(warningCount)
                                    })),
                          onNavigate: (function (item, discrepancy) {
                              var variant = discrepancy.NAME;
                              AnalyticsRe.auditIssueInteracted(schemaGroup, item.id, item.name, item.type_, "Sidebar", "OpenIssueItem", variant === "SameEventName" ? "SameNameEvents" : (
                                      variant === "SamePropertyName" ? "SameNameProperty" : (
                                          variant === "PropertiesWithAnyType" ? "PropertyTypeNotDefined" : (
                                              variant === "EventsWithoutDescription" ? "EventMissingDescription" : (
                                                  variant === "EventsWithUnexpectedCase" ? "EventCommonCasingDiscrepancy" : (
                                                      variant === "PropertyConflictOnEvent" ? "PropertyConflicts" : (
                                                          variant === "PropertiesWithoutDescription" ? "PropertyMissingDescription" : "PropertyCommonCasingDiscrepancy"
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    ), schemaGroup.schemaId, schemaGroup.branchId);
                              return Curry._1(onClose, undefined);
                            }),
                          expectedEventCase: match.expectedEventCase,
                          expectedPropertyCase: match.expectedPropertyCase
                        }))));
}

var make = DiscrepanciesConfig;

exports.casingOptions = casingOptions;
exports.caseToLabel = caseToLabel;
exports.caseToAnalytics = caseToAnalytics;
exports.getOptionForCasing = getOptionForCasing;
exports.configUpgradePrompt = configUpgradePrompt;
exports.forceCasingUpgradePrompt = forceCasingUpgradePrompt;
exports.Style = Style;
exports.make = make;
/* container Not a pure module */
