// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var InspectorIssueType = require("./InspectorIssueType.bs.js");

function encode(t) {
  return Json_encode.object_({
              hd: [
                "appVersion",
                t.appVersion
              ],
              tl: {
                hd: [
                  "issueCount",
                  t.issueCount
                ],
                tl: {
                  hd: [
                    "eventCount",
                    t.eventCount
                  ],
                  tl: {
                    hd: [
                      "lastSeen",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), Belt_Option.map(t.lastSeen, (function (prim) {
                                  return prim.toISOString();
                                })))
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function decode(json) {
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("lastSeen", Json_decode.string, param);
        }), json);
  return {
          appVersion: Json_decode.field("appVersion", Json_decode.string, json),
          issueCount: Json_decode.field("issueCount", Json_decode.$$float, json),
          eventCount: Json_decode.field("eventCount", Json_decode.$$float, json),
          lastSeen: Belt_Option.map(__x, (function (prim) {
                  return new Date(prim);
                }))
        };
}

var AppVersionStats = {
  encode: encode,
  decode: decode
};

function encode$1(t) {
  return Json_encode.object_({
              hd: [
                "issueId",
                t.issueId
              ],
              tl: {
                hd: [
                  "sharedIssueId",
                  t.sharedIssueId
                ],
                tl: {
                  hd: [
                    "schemaId",
                    t.schemaId
                  ],
                  tl: {
                    hd: [
                      "sourceId",
                      t.sourceId
                    ],
                    tl: {
                      hd: [
                        "eventName",
                        t.eventName
                      ],
                      tl: {
                        hd: [
                          "propertyName",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), t.propertyName)
                        ],
                        tl: {
                          hd: [
                            "issueType",
                            InspectorIssueType.encode(t.issueType)
                          ],
                          tl: {
                            hd: [
                              "oldestAppVersion",
                              t.oldestAppVersion
                            ],
                            tl: {
                              hd: [
                                "newestAppVersion",
                                t.newestAppVersion
                              ],
                              tl: {
                                hd: [
                                  "firstSeen",
                                  t.firstSeen.toISOString()
                                ],
                                tl: {
                                  hd: [
                                    "lastSeen",
                                    t.lastSeen.toISOString()
                                  ],
                                  tl: {
                                    hd: [
                                      "issueCount",
                                      t.issueCount
                                    ],
                                    tl: {
                                      hd: [
                                        "eventCount",
                                        t.eventCount
                                      ],
                                      tl: {
                                        hd: [
                                          "appVersions",
                                          Json_encode.array((function (prim) {
                                                  return prim;
                                                }), t.appVersions)
                                        ],
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function decode$1(json) {
  return {
          issueId: Json_decode.field("issueId", Json_decode.string, json),
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          eventName: Json_decode.field("eventName", Json_decode.string, json),
          propertyName: Json_decode.optional((function (param) {
                  return Json_decode.field("propertyName", Json_decode.string, param);
                }), json),
          issueType: Json_decode.field("issueType", InspectorIssueType.decode, json),
          sharedIssueId: Json_decode.field("sharedIssueId", Json_decode.string, json),
          sourceId: Json_decode.field("sourceId", Json_decode.string, json),
          appVersions: Json_decode.field("appVersions", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          newestAppVersion: Json_decode.field("newestAppVersion", Json_decode.string, json),
          oldestAppVersion: Json_decode.field("oldestAppVersion", Json_decode.string, json),
          issueCount: Json_decode.field("issueCount", Json_decode.$$float, json),
          eventCount: Json_decode.field("eventCount", Json_decode.$$float, json),
          lastSeen: new Date(Json_decode.field("lastSeen", Json_decode.string, json)),
          firstSeen: new Date(Json_decode.field("firstSeen", Json_decode.string, json))
        };
}

var IssueGroupedBySource = {
  encode: encode$1,
  decode: decode$1
};

function encode$2(t) {
  return Json_encode.object_({
              hd: [
                "issueId",
                t.issueId
              ],
              tl: {
                hd: [
                  "sharedIssueId",
                  t.sharedIssueId
                ],
                tl: {
                  hd: [
                    "schemaId",
                    t.schemaId
                  ],
                  tl: {
                    hd: [
                      "sourceId",
                      t.sourceId
                    ],
                    tl: {
                      hd: [
                        "eventName",
                        t.eventName
                      ],
                      tl: {
                        hd: [
                          "propertyName",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), t.propertyName)
                        ],
                        tl: {
                          hd: [
                            "issueType",
                            InspectorIssueType.encode(t.issueType)
                          ],
                          tl: {
                            hd: [
                              "oldestAppVersion",
                              t.oldestAppVersion
                            ],
                            tl: {
                              hd: [
                                "newestAppVersion",
                                t.newestAppVersion
                              ],
                              tl: {
                                hd: [
                                  "firstSeen",
                                  t.firstSeen.toISOString()
                                ],
                                tl: {
                                  hd: [
                                    "lastSeen",
                                    t.lastSeen.toISOString()
                                  ],
                                  tl: {
                                    hd: [
                                      "issueCount",
                                      t.issueCount
                                    ],
                                    tl: {
                                      hd: [
                                        "eventCount",
                                        t.eventCount
                                      ],
                                      tl: {
                                        hd: [
                                          "appVersions",
                                          Json_encode.dict(encode, t.appVersions)
                                        ],
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function decode$2(json) {
  return {
          issueId: Json_decode.field("issueId", Json_decode.string, json),
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          eventName: Json_decode.field("eventName", Json_decode.string, json),
          propertyName: Json_decode.optional((function (param) {
                  return Json_decode.field("propertyName", Json_decode.string, param);
                }), json),
          issueType: Json_decode.field("issueType", InspectorIssueType.decode, json),
          sharedIssueId: Json_decode.field("sharedIssueId", Json_decode.string, json),
          sourceId: Json_decode.field("sourceId", Json_decode.string, json),
          appVersions: Json_decode.field("appVersions", (function (param) {
                  return Json_decode.dict(decode, param);
                }), json),
          newestAppVersion: Json_decode.field("newestAppVersion", Json_decode.string, json),
          oldestAppVersion: Json_decode.field("oldestAppVersion", Json_decode.string, json),
          issueCount: Json_decode.field("issueCount", Json_decode.$$float, json),
          eventCount: Json_decode.field("eventCount", Json_decode.$$float, json),
          lastSeen: new Date(Json_decode.field("lastSeen", Json_decode.string, json)),
          firstSeen: new Date(Json_decode.field("firstSeen", Json_decode.string, json))
        };
}

var IssueWithAppVersionStats = {
  encode: encode$2,
  decode: decode$2
};

exports.AppVersionStats = AppVersionStats;
exports.IssueGroupedBySource = IssueGroupedBySource;
exports.IssueWithAppVersionStats = IssueWithAppVersionStats;
/* No side effect */
