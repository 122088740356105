// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function rootStyles(size, on, customDisabled, faded) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.flexShrink(0.0),
                      tl: {
                        hd: Css.padding(Css.px(2)),
                        tl: {
                          hd: Css.backgroundColor(on ? Styles.Color.grassGreen : Styles.Color.grey40),
                          tl: {
                            hd: Css.width(Css.px(size === "Small" ? 30 : (
                                        size === "Medium" ? 34 : 36
                                      ))),
                            tl: {
                              hd: Css.height(Css.px(size === "Small" ? 18 : (
                                          size === "Medium" ? 20 : 22
                                        ))),
                              tl: {
                                hd: Css.borderRadius(Css.px(100)),
                                tl: {
                                  hd: Css.opacity(customDisabled || faded ? 0.5 : 1.0),
                                  tl: {
                                    hd: Css.disabled({
                                          hd: Css.cursor("default"),
                                          tl: {
                                            hd: Css.backgroundColor(on ? Styles.Color.grassGreen : Styles.Color.grey30),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: {
                                      hd: Css.focus({
                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.blue)),
                                            tl: {
                                              hd: Css.outlineStyle("none"),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.backgroundColor(on ? Styles.Color.grassGreenSecondary : Styles.Color.grey50),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.transition({
                                                NAME: "ms",
                                                VAL: Styles.Duration.$$default
                                              }, undefined, undefined, "background-color"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function knobStyles(size, on, disabled, faded) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.px(size === "Small" ? 14 : (
                          size === "Medium" ? 16 : 18
                        ))),
              tl: {
                hd: Css.height(Css.px(size === "Small" ? 14 : (
                            size === "Medium" ? 16 : 18
                          ))),
                tl: {
                  hd: Css.borderRadius(Css.px(100)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.opacity(disabled || faded ? 0.5 : 1.0),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$default
                            }, undefined, undefined, "transform"),
                        tl: {
                          hd: Css.transform({
                                NAME: "translateX",
                                VAL: Css.px(on ? (
                                        size === "Small" ? 12 : 14
                                      ) : 0)
                              }),
                          tl: {
                            hd: Css.boxShadow(Styles.Shadow.box),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function Switch(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var fadedOpt = Props.faded;
  var sizeOpt = Props.size;
  var disabledOpt = Props.disabled;
  var faded = fadedOpt !== undefined ? fadedOpt : false;
  var size = sizeOpt !== undefined ? sizeOpt : "Large";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  return React.createElement("button", {
              className: rootStyles(size, value, disabled, faded),
              disabled: disabled,
              onClick: (function (param) {
                  return Curry._1(onChange, !value);
                })
            }, React.createElement("div", {
                  className: knobStyles(size, value, disabled, faded)
                }));
}

var make = Switch;

exports.rootStyles = rootStyles;
exports.knobStyles = knobStyles;
exports.make = make;
/* Css Not a pure module */
