// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconServer(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey70;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("svg", {
                  width: String(size),
                  viewBox: "0 0 16 16",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("g", {
                      strokeWidth: "1"
                    }, React.createElement("rect", {
                          height: "6",
                          width: "15",
                          fill: "none",
                          strokeLinecap: "round",
                          x: "0.5",
                          y: "0.5"
                        }), React.createElement("line", {
                          fill: "none",
                          strokeLinecap: "round",
                          x1: "6.5",
                          x2: "3.5",
                          y1: "3.5",
                          y2: "3.5"
                        }), React.createElement("circle", {
                          cx: "12.5",
                          cy: "3.5",
                          r: "0.5",
                          stroke: "none"
                        }), React.createElement("rect", {
                          height: "6",
                          width: "15",
                          fill: "none",
                          strokeLinecap: "round",
                          x: "0.5",
                          y: "9.5"
                        }), React.createElement("line", {
                          fill: "none",
                          strokeLinecap: "round",
                          x1: "6.5",
                          x2: "3.5",
                          y1: "12.5",
                          y2: "12.5"
                        }), React.createElement("circle", {
                          cx: "12.5",
                          cy: "12.5",
                          r: "0.5",
                          stroke: "none"
                        }))));
}

var make = IconServer;

exports.make = make;
/* Css Not a pure module */
