// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");

function ContextBoxLazy(Props) {
  var onOpen = Props.onOpen;
  var onClose = Props.onClose;
  var button = Props.button;
  var children = Props.children;
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  var isOpen = match[0];
  React.useEffect((function () {
          if (isOpen) {
            if (onOpen !== undefined) {
              Curry._1(onOpen, undefined);
            }
            
          } else if (onClose !== undefined) {
            Curry._1(onClose, undefined);
          }
          
        }), [isOpen]);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.display("flex"),
                      tl: /* [] */0
                    }
                  }),
              onKeyDown: (function ($$event) {
                  if ($$event.key === "Escape") {
                    return Curry._1(setIsOpen, (function (param) {
                                  return false;
                                }));
                  }
                  
                })
            }, React.cloneElement(button, {
                  onClick: (function (param) {
                      return Curry._1(setIsOpen, (function (param) {
                                    return true;
                                  }));
                    })
                }), isOpen ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.zIndex(Styles.ZIndex.aboveAll),
                          tl: /* [] */0
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("fixed"),
                              tl: {
                                hd: Css.top(Css.px(0)),
                                tl: {
                                  hd: Css.right(Css.px(0)),
                                  tl: {
                                    hd: Css.bottom(Css.px(0)),
                                    tl: {
                                      hd: Css.left(Css.px(0)),
                                      tl: {
                                        hd: Css.backgroundColor(Css.transparent),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }),
                        onClick: (function (param) {
                            return Curry._1(setIsOpen, (function (param) {
                                          return false;
                                        }));
                          })
                      }), children(function (param) {
                        return Curry._1(setIsOpen, (function (param) {
                                      return false;
                                    }));
                      })) : null);
}

var make = ContextBoxLazy;

exports.make = make;
/* Css Not a pure module */
