// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Styles = require("./styles.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");

function IconMobile(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.grey70;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("svg", {
                  width: String(size),
                  viewBox: "0 0 16 16",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("g", {
                      className: "nc-icon-wrapper",
                      strokeWidth: "1"
                    }, React.createElement("rect", {
                          height: "15",
                          width: "11",
                          fill: "none",
                          rx: "1",
                          ry: "1",
                          strokeLinecap: "round",
                          x: "2.5",
                          y: "0.5"
                        }), React.createElement("circle", {
                          cx: "8",
                          cy: "12",
                          r: "1",
                          stroke: "none"
                        }))));
}

var make = IconMobile;

exports.make = make;
/* Css Not a pure module */
