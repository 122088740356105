// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("../Hooks.bs.js");
var React = require("react");
var Styles = require("../styles.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoLimits = require("../AvoLimits.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var LoadingCircle = require("../LoadingCircle.bs.js");
var InspectorStats = require("./InspectorStats.bs.js");
var WorkspaceContext = require("../WorkspaceContext.bs.js");
var InspectorViewModel = require("./InspectorViewModel.bs.js");
var InspectorEmptyState = require("./InspectorEmptyState.bs.js");
var InspectorViewHeader = require("./InspectorViewHeader.bs.js");
var InspectorEventsStore = require("./InspectorEventsStore.bs.js");
var InspectorViewEventsList = require("./InspectorViewEventsList.bs.js");

function getHighestEnvironmentWithData(stats) {
  var envsSeen = Belt_Array.concatMany(Belt_Array.mapU(stats, (function (sourceStat) {
              return Belt_Array.map(sourceStat.envs, (function (env) {
                            return env.env;
                          }));
            })));
  if (Belt_Array.some(envsSeen, (function (env) {
            return env === "prod";
          }))) {
    return "Production";
  } else if (Belt_Array.some(envsSeen, (function (env) {
            return env === "dev";
          })) || !Belt_Array.some(envsSeen, (function (env) {
            return env === "staging";
          }))) {
    return "Development";
  } else {
    return "Staging";
  }
}

var content = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.minHeight({
                  NAME: "substract",
                  VAL: [
                    Css.vh(100.0),
                    Css.px(InspectorViewHeader.headerHeight)
                  ]
                }),
            tl: {
              hd: Css.marginTop(Css.px(InspectorViewHeader.headerHeight)),
              tl: {
                hd: Css.padding(Css.px(40)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var loading = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: /* [] */0
        }
      }
    });

var Style = {
  content: content,
  loading: loading
};

function useQueryEnvironment(highestEnvironmentWithData) {
  var getEnvironmentFromQuery = function (maybeEnvironment) {
    return Belt_Option.getWithDefault(Belt_Option.flatMap(maybeEnvironment, InspectorViewModel.environmentFromJs), highestEnvironmentWithData);
  };
  return Hooks.useUrlQueryParam("environment", getEnvironmentFromQuery, (function (environment) {
                return InspectorViewModel.environmentToJs(environment);
              }));
}

function useQueryTimeWindow(param) {
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var getTimeWindowFromQuery = function (maybeTimeWindow) {
    var maybeTimeWindowFromQuery = Belt_Option.flatMap(maybeTimeWindow, InspectorViewModel.timeWindowFromJs);
    var timeWindowAvailable = function (timeWindow) {
      return AvoLimits.InspectorLookback.computeAvailability(workspace, timeWindow) !== "Unavailable";
    };
    if (maybeTimeWindowFromQuery !== undefined) {
      if (timeWindowAvailable(maybeTimeWindowFromQuery)) {
        return maybeTimeWindowFromQuery;
      } else {
        return "Last1Hour";
      }
    } else if (timeWindowAvailable("Last24Hours")) {
      return "Last24Hours";
    } else {
      return "Last1Hour";
    }
  };
  return Hooks.useUrlQueryParam("timeWindow", getTimeWindowFromQuery, (function (timeWindow) {
                return InspectorViewModel.timeWindowToJs(timeWindow);
              }));
}

function InspectorEventsView$InspectorViewWithStats(Props) {
  var hasSourcesWithoutInspector = Props.hasSourcesWithoutInspector;
  var model = Props.model;
  var schema = Props.schema;
  var stats = Props.stats;
  var match = useQueryTimeWindow(undefined);
  var timeWindow = match[0];
  var match$1 = useQueryEnvironment(getHighestEnvironmentWithData(stats));
  var environment = match$1[0];
  var match$2 = InspectorEventsStore.useStore(schema.id, environment, timeWindow);
  var enrichedEvents = match$2.enrichedEvents;
  var loadingState = match$2.loadingState;
  var match$3 = Hooks.useUrlQueryParam("filterByEventName", (function (name) {
          return Belt_Option.getWithDefault(name, "");
        }), (function (name) {
          return name;
        }));
  var filterByEventName = match$3[0];
  return React.createElement(React.Fragment, undefined, React.createElement(InspectorViewHeader.make, {
                  environment: environment,
                  events: enrichedEvents,
                  fetchEvents: match$2.fetch,
                  filterByEventName: filterByEventName,
                  loadingStatus: loadingState,
                  setEnvironment: match$1[1],
                  setFiltering: match$3[1],
                  setTimeWindow: match[1],
                  timeWindow: timeWindow,
                  hasSourcesWithoutInspector: hasSourcesWithoutInspector,
                  inspectorView: "events"
                }), React.createElement(InspectorViewEventsList.make, {
                  model: model,
                  timeWindow: timeWindow,
                  environment: environment,
                  enrichedEvents: enrichedEvents,
                  loadingStatus: loadingState,
                  filterByEventName: filterByEventName
                }));
}

var InspectorViewWithStats = {
  make: InspectorEventsView$InspectorViewWithStats
};

function InspectorEventsView(Props) {
  var schema = Props.schema;
  var model = Props.model;
  var stats = InspectorStats.useStatsWithCache(schema.id);
  var hasSourcesWithoutInspector = Belt_List.some(model.sources, (function (source) {
          var match = InspectorStats.getSourceStatus(model, source, stats);
          return match === 2 || match === 1;
        }));
  var tmp;
  if (typeof stats === "number") {
    tmp = stats >= 2 ? React.createElement("div", {
            className: content
          }, React.createElement(InspectorViewHeader.Disabled.make, {
                hasSourcesWithoutInspector: hasSourcesWithoutInspector,
                inspectorView: "events"
              }), React.createElement($$Text.make, {
                size: "Medium",
                weight: "Semi",
                color: Styles.Color.grey70,
                children: "Something went wrong"
              })) : React.createElement("div", {
            className: content
          }, React.createElement(InspectorViewHeader.Disabled.make, {
                hasSourcesWithoutInspector: hasSourcesWithoutInspector,
                inspectorView: "events"
              }), React.createElement("div", {
                className: loading
              }, React.createElement(LoadingCircle.make, {
                    color: Styles.Color.grey40
                  })));
  } else {
    var stats$1 = stats._0;
    tmp = Caml_obj.caml_notequal(stats$1, []) ? React.createElement(InspectorEventsView$InspectorViewWithStats, {
            hasSourcesWithoutInspector: hasSourcesWithoutInspector,
            model: model,
            schema: schema,
            stats: stats$1
          }) : React.createElement(InspectorEmptyState.make, {
            hasSourcesWithoutInspector: hasSourcesWithoutInspector,
            inspectorView: "events"
          });
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: /* [] */0
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexGrow(1.0),
                        tl: /* [] */0
                      })
                }, tmp));
}

var make = InspectorEventsView;

exports.getHighestEnvironmentWithData = getHighestEnvironmentWithData;
exports.Style = Style;
exports.useQueryEnvironment = useQueryEnvironment;
exports.useQueryTimeWindow = useQueryTimeWindow;
exports.InspectorViewWithStats = InspectorViewWithStats;
exports.make = make;
/* content Not a pure module */
