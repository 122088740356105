// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Js_option = require("rescript/lib/js/js_option.js");
var Parse = require("date-fns/parse");
var Format = require("date-fns/format");
var Set_day = require("date-fns/set_day");
var Js_null_undefined = require("rescript/lib/js/js_null_undefined.js");
var Add_days = require("date-fns/add_days");
var Is_after = require("date-fns/is_after");
var Set_date = require("date-fns/set_date");
var Set_year = require("date-fns/set_year");
var Sub_days = require("date-fns/sub_days");
var Add_hours = require("date-fns/add_hours");
var Add_weeks = require("date-fns/add_weeks");
var Add_years = require("date-fns/add_years");
var Is_before = require("date-fns/is_before");
var Set_hours = require("date-fns/set_hours");
var Set_month = require("date-fns/set_month");
var Sub_hours = require("date-fns/sub_hours");
var Sub_weeks = require("date-fns/sub_weeks");
var Sub_years = require("date-fns/sub_years");
var Add_months = require("date-fns/add_months");
var Closest_to = require("date-fns/closest_to");
var Sub_months = require("date-fns/sub_months");
var Add_minutes = require("date-fns/add_minutes");
var Add_seconds = require("date-fns/add_seconds");
var Set_iso_day = require("date-fns/set_iso_day");
var Set_minutes = require("date-fns/set_minutes");
var Set_quarter = require("date-fns/set_quarter");
var Set_seconds = require("date-fns/set_seconds");
var Sub_minutes = require("date-fns/sub_minutes");
var Sub_seconds = require("date-fns/sub_seconds");
var Add_quarters = require("date-fns/add_quarters");
var Set_iso_week = require("date-fns/set_iso_week");
var Set_iso_year = require("date-fns/set_iso_year");
var Sub_quarters = require("date-fns/sub_quarters");
var Add_iso_years = require("date-fns/add_iso_years");
var Sub_iso_years = require("date-fns/sub_iso_years");
var Is_within_range = require("date-fns/is_within_range");
var Set_day_of_year = require("date-fns/set_day_of_year");
var Add_milliseconds = require("date-fns/add_milliseconds");
var Closest_index_to = require("date-fns/closest_index_to");
var Set_milliseconds = require("date-fns/set_milliseconds");
var Sub_milliseconds = require("date-fns/sub_milliseconds");
var Distance_in_words = require("date-fns/distance_in_words");
var Distance_in_words_strict = require("date-fns/distance_in_words_strict");
var Distance_in_words_to_now = require("date-fns/distance_in_words_to_now");

function flip(f, a, b) {
  return Curry._2(f, b, a);
}

var handleOptBool = Js_null_undefined.fromOption;

function timeUnitToString(x) {
  switch (x) {
    case /* Second */0 :
        return "s";
    case /* Minute */1 :
        return "m";
    case /* Hour */2 :
        return "h";
    case /* Day */3 :
        return "d";
    case /* Month */4 :
        return "M";
    case /* Year */5 :
        return "Y";
    
  }
}

function partialMethodToString(x) {
  switch (x) {
    case /* Floor */0 :
        return "floor";
    case /* Ceil */1 :
        return "ceil";
    case /* Round */2 :
        return "round";
    
  }
}

function closestIndexTo(param, param$1) {
  return Closest_index_to(param$1, param);
}

function closestTo(param, param$1) {
  return Closest_to(param$1, param);
}

function distanceInWords(includeSeconds, addSuffix, dateToCompare, date) {
  return Distance_in_words(dateToCompare, date, {
              includeSeconds: Js_null_undefined.fromOption(includeSeconds),
              addSuffix: Js_null_undefined.fromOption(addSuffix)
            });
}

function distanceInWordsStrict(addSuffix, unit, partialMethod, dateToCompare, date) {
  return Distance_in_words_strict(dateToCompare, date, {
              addSuffix: Js_null_undefined.fromOption(addSuffix),
              unit: Js_null_undefined.fromOption(Js_option.map(timeUnitToString, unit)),
              partialMethod: Js_null_undefined.fromOption(Js_option.map(partialMethodToString, partialMethod))
            });
}

function distanceInWordsToNow(includeSeconds, addSuffix, date) {
  return Distance_in_words_to_now(date, {
              includeSeconds: Js_null_undefined.fromOption(includeSeconds),
              addSuffix: Js_null_undefined.fromOption(addSuffix)
            });
}

function format(param, param$1) {
  return Format(param$1, param);
}

function isAfter(base, comparator) {
  return Is_after(comparator, base);
}

function isBefore(param, param$1) {
  return Is_before(param$1, param);
}

function $$parseFloat(additionalDigits, $$float) {
  return Parse($$float, {
              additionalDigits: Js_null_undefined.fromOption(additionalDigits)
            });
}

function parseString(additionalDigits, string) {
  return Parse(string, {
              additionalDigits: Js_null_undefined.fromOption(additionalDigits)
            });
}

function isWithinRange(startDate, endDate, date) {
  return Is_within_range(date, startDate, endDate);
}

function setMilliseconds(param, param$1) {
  return Set_milliseconds(param$1, param);
}

function setSeconds(param, param$1) {
  return Set_seconds(param$1, param);
}

function setMinutes(param, param$1) {
  return Set_minutes(param$1, param);
}

function setHours(param, param$1) {
  return Set_hours(param$1, param);
}

function setDay(param, param$1) {
  return Set_day(param$1, param);
}

function setISODay(param, param$1) {
  return Set_iso_day(param$1, param);
}

function setDate(param, param$1) {
  return Set_date(param$1, param);
}

function setDayOfYear(param, param$1) {
  return Set_day_of_year(param$1, param);
}

function setISOWeek(param, param$1) {
  return Set_iso_week(param$1, param);
}

function setMonth(param, param$1) {
  return Set_month(param$1, param);
}

function setQuarter(param, param$1) {
  return Set_quarter(param$1, param);
}

function setYear(param, param$1) {
  return Set_year(param$1, param);
}

function setISOYear(param, param$1) {
  return Set_iso_year(param$1, param);
}

function subMilliseconds(param, param$1) {
  return Sub_milliseconds(param$1, param);
}

function subSeconds(param, param$1) {
  return Sub_seconds(param$1, param);
}

function subMinutes(param, param$1) {
  return Sub_minutes(param$1, param);
}

function subHours(param, param$1) {
  return Sub_hours(param$1, param);
}

function subDays(param, param$1) {
  return Sub_days(param$1, param);
}

function subWeeks(param, param$1) {
  return Sub_weeks(param$1, param);
}

function subMonths(param, param$1) {
  return Sub_months(param$1, param);
}

function subQuarters(param, param$1) {
  return Sub_quarters(param$1, param);
}

function subYears(param, param$1) {
  return Sub_years(param$1, param);
}

function subISOYears(param, param$1) {
  return Sub_iso_years(param$1, param);
}

function addMilliseconds(param, param$1) {
  return Add_milliseconds(param$1, param);
}

function addSeconds(param, param$1) {
  return Add_seconds(param$1, param);
}

function addMinutes(param, param$1) {
  return Add_minutes(param$1, param);
}

function addHours(param, param$1) {
  return Add_hours(param$1, param);
}

function addDays(param, param$1) {
  return Add_days(param$1, param);
}

function addWeeks(param, param$1) {
  return Add_weeks(param$1, param);
}

function addMonths(param, param$1) {
  return Add_months(param$1, param);
}

function addQuarters(param, param$1) {
  return Add_quarters(param$1, param);
}

function addYears(param, param$1) {
  return Add_years(param$1, param);
}

function addISOYears(param, param$1) {
  return Add_iso_years(param$1, param);
}

exports.flip = flip;
exports.handleOptBool = handleOptBool;
exports.timeUnitToString = timeUnitToString;
exports.partialMethodToString = partialMethodToString;
exports.closestIndexTo = closestIndexTo;
exports.closestTo = closestTo;
exports.distanceInWords = distanceInWords;
exports.distanceInWordsStrict = distanceInWordsStrict;
exports.distanceInWordsToNow = distanceInWordsToNow;
exports.format = format;
exports.isAfter = isAfter;
exports.isBefore = isBefore;
exports.$$parseFloat = $$parseFloat;
exports.parseString = parseString;
exports.isWithinRange = isWithinRange;
exports.setMilliseconds = setMilliseconds;
exports.setSeconds = setSeconds;
exports.setMinutes = setMinutes;
exports.setHours = setHours;
exports.setDay = setDay;
exports.setISODay = setISODay;
exports.setDate = setDate;
exports.setDayOfYear = setDayOfYear;
exports.setISOWeek = setISOWeek;
exports.setMonth = setMonth;
exports.setQuarter = setQuarter;
exports.setYear = setYear;
exports.setISOYear = setISOYear;
exports.subMilliseconds = subMilliseconds;
exports.subSeconds = subSeconds;
exports.subMinutes = subMinutes;
exports.subHours = subHours;
exports.subDays = subDays;
exports.subWeeks = subWeeks;
exports.subMonths = subMonths;
exports.subQuarters = subQuarters;
exports.subYears = subYears;
exports.subISOYears = subISOYears;
exports.addMilliseconds = addMilliseconds;
exports.addSeconds = addSeconds;
exports.addMinutes = addMinutes;
exports.addHours = addHours;
exports.addDays = addDays;
exports.addWeeks = addWeeks;
exports.addMonths = addMonths;
exports.addQuarters = addQuarters;
exports.addYears = addYears;
exports.addISOYears = addISOYears;
/* date-fns/parse Not a pure module */
