// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var TrackingPlanModel = require("../../model/src/TrackingPlanModel.bs.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");
var TrackingPlanMappedModel = require("../../model/src/TrackingPlanMappedModel.bs.js");

function hasMigrated(model, maybeMigration) {
  return Belt_List.some(model.migrations, (function (migration) {
                return migration === maybeMigration;
              }));
}

function hasMigrated_boxed(modelType, maybeMigration) {
  if (modelType.TAG === /* Old */0) {
    return hasMigrated(modelType._0, maybeMigration);
  } else {
    return Belt_Array.some(modelType._0.migrations, (function (migration) {
                  return migration === maybeMigration;
                }));
  }
}

function createEverythingSometimesSentMatrix(modelType, propertyId, sentWhenDescription) {
  var isSystemProp;
  isSystemProp = modelType.TAG === /* Old */0 ? Belt_Option.getWithDefault(Belt_Option.map(StateUtils.getModelPropertyById(propertyId, modelType._0), (function (property) {
                return property.sendAs === /* SystemProperty */0;
              })), false) : Belt_Option.getWithDefault(Belt_Option.map(Curry._2(TrackingPlanMappedModel.Properties.get, modelType._0.properties, propertyId), (function (property) {
                return property.sendAs === /* SystemProperty */0;
              })), false);
  if (!isSystemProp) {
    if (modelType.TAG === /* Old */0) {
      return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.mapU(StateUtils.eventsSendingProperty(modelType._0, undefined, propertyId), (function ($$event) {
                            var sourceAbsences = {
                              TAG: /* AllSources */0,
                              _0: /* SometimesSent */{
                                _0: sentWhenDescription
                              }
                            };
                            return [
                                    $$event.id,
                                    sourceAbsences
                                  ];
                          }))));
    } else {
      return Curry._2(TrackingPlanMappedModel.Events.mapToMap, StateUtils.eventsSendingProperty_mappedModel(modelType._0, undefined, propertyId), (function (_event) {
                    return {
                            TAG: /* AllSources */0,
                            _0: /* SometimesSent */{
                              _0: sentWhenDescription
                            }
                          };
                  }));
    }
  }
  var sourceAbsences = {
    TAG: /* AllSources */0,
    _0: /* SometimesSent */{
      _0: sentWhenDescription
    }
  };
  return Belt_MapString.fromArray([[
                TrackingPlanModel.propertyAbsenceAllEventsKey,
                sourceAbsences
              ]]);
}

function extractMixedSourceAbsenceFromAnySourceAbsence(modelType, eventId, sourceAbsence) {
  if (sourceAbsence.TAG !== /* AllSources */0) {
    return sourceAbsence._0;
  }
  var sourceAbsence$1 = sourceAbsence._0;
  if (modelType.TAG === /* Old */0) {
    var model = modelType._0;
    var sourceIds = eventId === TrackingPlanModel.propertyAbsenceAllEventsKey ? Belt_List.map(model.sources, (function (source) {
              return source.id;
            })) : StateUtils.getEventSourceIdsByEventId(model, eventId);
    return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.mapU(sourceIds, (function (sourceId) {
                          return [
                                  sourceId,
                                  sourceAbsence$1
                                ];
                        }))));
  }
  var model$1 = modelType._0;
  var sourceIds$1 = eventId === TrackingPlanModel.propertyAbsenceAllEventsKey ? Belt_List.fromArray(Curry._1(TrackingPlanMappedModel.Sources.keys, model$1.sources)) : StateUtils.getEventSourceIdsByEventId_mappedModel(model$1, eventId);
  return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.mapU(sourceIds$1, (function (sourceId) {
                        return [
                                sourceId,
                                sourceAbsence$1
                              ];
                      }))));
}

function computeCurrentAbsenceMatrix(modelType, property) {
  var match = property.absence;
  if (match !== undefined) {
    if (typeof match === "number") {
      return ;
    } else if (match.TAG === /* SometimesSent */0) {
      return createEverythingSometimesSentMatrix(modelType, property.id, match._0);
    } else {
      return match._0;
    }
  } else {
    return Pervasives.failwith("Workspace has not been migrated to use absence");
  }
}

function updatePropertyAbsence(modelType, property, propertyAbsenceUpdateType, maybeEventSourceAbsence) {
  if (property.absence === undefined) {
    Pervasives.failwith("This method should not be called before optional->absence migration.");
  }
  var currentMatrix = computeCurrentAbsenceMatrix(modelType, property);
  var absence;
  if (typeof propertyAbsenceUpdateType === "number") {
    absence = maybeEventSourceAbsence !== undefined ? (
        maybeEventSourceAbsence ? ({
              TAG: /* SometimesSent */0,
              _0: maybeEventSourceAbsence._0
            }) : Pervasives.failwith("Invalid state: Property should never be NeverSent from all events and sources.")
      ) : /* AlwaysSent */0;
  } else {
    switch (propertyAbsenceUpdateType.TAG | 0) {
      case /* Event */0 :
          var eventId = propertyAbsenceUpdateType._0;
          if (maybeEventSourceAbsence !== undefined) {
            var newMatrix = Belt_MapString.set(currentMatrix, eventId, {
                  TAG: /* AllSources */0,
                  _0: maybeEventSourceAbsence
                });
            absence = {
              TAG: /* Mixed */1,
              _0: newMatrix
            };
          } else {
            var newMatrix$1 = Belt_MapString.remove(currentMatrix, eventId);
            absence = Belt_MapString.isEmpty(newMatrix$1) ? /* AlwaysSent */0 : ({
                  TAG: /* Mixed */1,
                  _0: newMatrix$1
                });
          }
          break;
      case /* Source */1 :
          var sourceId = propertyAbsenceUpdateType._0;
          if (maybeEventSourceAbsence !== undefined) {
            var currentMatrixWithUpdatedAbsences = Belt_MapString.mapWithKeyU(currentMatrix, (function (eventId, sourceAbsences) {
                    var eventSourceIds;
                    eventSourceIds = modelType.TAG === /* Old */0 ? StateUtils.getEventSourceIdsByEventId(modelType._0, eventId) : StateUtils.getEventSourceIdsByEventId_mappedModel(modelType._0, eventId);
                    if (!Belt_List.has(eventSourceIds, sourceId, (function (prim0, prim1) {
                              return prim0 === prim1;
                            }))) {
                      return sourceAbsences;
                    }
                    var currentSourceAbsences = extractMixedSourceAbsenceFromAnySourceAbsence(modelType, eventId, sourceAbsences);
                    return {
                            TAG: /* MixedSources */1,
                            _0: Belt_MapString.set(currentSourceAbsences, sourceId, maybeEventSourceAbsence)
                          };
                  }));
            var tmp;
            tmp = modelType.TAG === /* Old */0 ? StateUtils.eventsSendingProperty(modelType._0, undefined, property.id) : Belt_List.fromArray(Curry._1(TrackingPlanMappedModel.Events.toArray, StateUtils.eventsSendingProperty_mappedModel(modelType._0, undefined, property.id)));
            var newEventsToAddToTheMatrix = Belt_List.keepU(tmp, (function ($$event) {
                    return !Belt_Array.someU(Belt_MapString.keysToArray(currentMatrix), (function (keyEventId) {
                                  return keyEventId === $$event.id;
                                }));
                  }));
            var newMatrix$2 = Belt_List.reduceU(newEventsToAddToTheMatrix, currentMatrixWithUpdatedAbsences, (function (newMatrixAccumulator, newEvent) {
                    return Belt_MapString.set(newMatrixAccumulator, newEvent.id, {
                                TAG: /* MixedSources */1,
                                _0: Belt_MapString.fromArray([[
                                        sourceId,
                                        maybeEventSourceAbsence
                                      ]])
                              });
                  }));
            absence = {
              TAG: /* Mixed */1,
              _0: newMatrix$2
            };
          } else {
            var newMatrix$3 = Belt_MapString.mapU(Belt_MapString.keepU(Belt_MapString.mapWithKeyU(currentMatrix, (function (eventId, sourceAbsences) {
                            var currentSourceAbsences = extractMixedSourceAbsenceFromAnySourceAbsence(modelType, eventId, sourceAbsences);
                            return Belt_MapString.remove(currentSourceAbsences, sourceId);
                          })), (function (_eventId, absence) {
                        return !Belt_MapString.isEmpty(absence);
                      })), (function (item) {
                    return {
                            TAG: /* MixedSources */1,
                            _0: item
                          };
                  }));
            absence = {
              TAG: /* Mixed */1,
              _0: newMatrix$3
            };
          }
          break;
      case /* EventSource */2 :
          var sourceId$1 = propertyAbsenceUpdateType._1;
          var eventId$1 = propertyAbsenceUpdateType._0;
          if (maybeEventSourceAbsence !== undefined) {
            var newMatrix$4 = Belt_MapString.updateU(currentMatrix, eventId$1, (function (maybeSourcesAbsence) {
                    return {
                            TAG: /* MixedSources */1,
                            _0: Belt_MapString.set(Belt_Option.mapWithDefault(maybeSourcesAbsence, undefined, (function (sourcesAbsence) {
                                        return extractMixedSourceAbsenceFromAnySourceAbsence(modelType, eventId$1, sourcesAbsence);
                                      })), sourceId$1, maybeEventSourceAbsence)
                          };
                  }));
            absence = {
              TAG: /* Mixed */1,
              _0: newMatrix$4
            };
          } else {
            var newMatrix$5 = Belt_MapString.updateU(currentMatrix, eventId$1, (function (maybeSourcesAbsence) {
                    return Belt_Option.flatMap(maybeSourcesAbsence, (function (sourcesAbsence) {
                                  var sourcesAbsence$1 = extractMixedSourceAbsenceFromAnySourceAbsence(modelType, eventId$1, sourcesAbsence);
                                  var nextSourceAbsencesMap = Belt_MapString.remove(sourcesAbsence$1, sourceId$1);
                                  if (Belt_MapString.isEmpty(nextSourceAbsencesMap)) {
                                    return ;
                                  } else {
                                    return {
                                            TAG: /* MixedSources */1,
                                            _0: nextSourceAbsencesMap
                                          };
                                  }
                                }));
                  }));
            absence = {
              TAG: /* Mixed */1,
              _0: newMatrix$5
            };
          }
          break;
      
    }
  }
  return {
          id: property.id,
          name: property.name,
          uniqueName: property.uniqueName,
          description: property.description,
          type_: property.type_,
          sendAs: property.sendAs,
          validations: property.validations,
          auto: property.auto,
          builtIn: property.builtIn,
          analyticsTools: property.analyticsTools,
          devPlatforms: property.devPlatforms,
          list: property.list,
          operation: property.operation,
          optionalDeprecated: property.optionalDeprecated,
          excludedSourcesDeprecated: property.excludedSourcesDeprecated,
          optionalWhenInObject: property.optionalWhenInObject,
          absence: absence
        };
}

function reduceFunction(model, action) {
  if (typeof action === "object") {
    var variant = action.NAME;
    if (variant === "UpdateDestinationDisabledByDefault") {
      var match = action.VAL;
      var disabledByDefault = match[1];
      var destinationId = match[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateDestination(model._0, destinationId, (function (destination) {
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: disabledByDefault
                              };
                      }))
              };
      }
      var model$1 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$1.archive,
                branchPointer: model$1.branchPointer,
                migrations: model$1.migrations,
                rules: model$1.rules,
                types: model$1.types,
                categories: model$1.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.update, model$1.destinations, destinationId, (function (destination) {
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: disabledByDefault
                              };
                      })),
                events: model$1.events,
                groupTypes: model$1.groupTypes,
                integrations: model$1.integrations,
                metrics: model$1.metrics,
                openBranches: model$1.openBranches,
                properties: model$1.properties,
                propertyBundles: model$1.propertyBundles,
                sources: model$1.sources
              }
            };
    }
    if (variant === "AddProperty") {
      var match$1 = action.VAL;
      var sendAs = match$1[3];
      var propertyId = match$1[1];
      var eventId = match$1[0];
      var newProperty_name = match$1[2];
      var newProperty_absence = hasMigrated_boxed(model, "PropertyOptionalAndExcludedSourcesToAbsence") ? /* AlwaysSent */0 : undefined;
      var newProperty = {
        id: propertyId,
        name: newProperty_name,
        uniqueName: undefined,
        description: "",
        type_: "string",
        sendAs: sendAs,
        validations: /* [] */0,
        auto: false,
        builtIn: "NotBuiltIn",
        analyticsTools: /* [] */0,
        devPlatforms: /* [] */0,
        list: false,
        operation: "Set",
        optionalDeprecated: false,
        excludedSourcesDeprecated: /* [] */0,
        optionalWhenInObject: false,
        absence: newProperty_absence
      };
      if (model.TAG === /* Old */0) {
        var model$2 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$2.types,
                  properties: Belt_List.concat(model$2.properties, {
                        hd: {
                          TAG: /* Property */1,
                          _0: newProperty
                        },
                        tl: /* [] */0
                      }),
                  propertyBundles: model$2.propertyBundles,
                  events: sendAs === /* SystemProperty */0 ? model$2.events : StateUtils.updateEvents(model$2.events, eventId, (function ($$event) {
                            return {
                                    id: $$event.id,
                                    name: $$event.name,
                                    uniqueName: $$event.uniqueName,
                                    description: $$event.description,
                                    properties: Belt_List.concat($$event.properties, {
                                          hd: {
                                            TAG: /* PropertyRef */0,
                                            _0: {
                                              id: propertyId,
                                              description: "",
                                              pinnedValue: undefined
                                            }
                                          },
                                          tl: /* [] */0
                                        }),
                                    propertyBundles: $$event.propertyBundles,
                                    variants: $$event.variants,
                                    types: typeof sendAs === "number" ? $$event.types : BeltListExtensions.dedupeSlow({
                                            hd: "UpdateGroups",
                                            tl: $$event.types
                                          }),
                                    tags: $$event.tags,
                                    excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                    includeSources: $$event.includeSources,
                                    includeDestinations: $$event.includeDestinations,
                                    hashes: $$event.hashes,
                                    propertyWhitelist: $$event.propertyWhitelist,
                                    eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                    userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                    triggers: $$event.triggers
                                  };
                          })),
                  migrations: model$2.migrations,
                  sources: model$2.sources,
                  destinations: model$2.destinations,
                  groupTypes: model$2.groupTypes,
                  goals: model$2.goals,
                  metrics: model$2.metrics,
                  archive: model$2.archive,
                  openBranches: model$2.openBranches,
                  branchPointer: model$2.branchPointer,
                  rules: model$2.rules,
                  integrations: model$2.integrations
                }
              };
      }
      var model$3 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$3.archive,
                branchPointer: model$3.branchPointer,
                migrations: model$3.migrations,
                rules: model$3.rules,
                types: model$3.types,
                categories: model$3.categories,
                destinations: model$3.destinations,
                events: sendAs === /* SystemProperty */0 ? model$3.events : Curry._3(TrackingPlanMappedModel.Events.update, model$3.events, eventId, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: Belt_List.concat($$event.properties, {
                                        hd: {
                                          TAG: /* PropertyRef */0,
                                          _0: {
                                            id: propertyId,
                                            description: "",
                                            pinnedValue: undefined
                                          }
                                        },
                                        tl: /* [] */0
                                      }),
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: typeof sendAs === "number" ? $$event.types : BeltListExtensions.dedupeSlow({
                                          hd: "UpdateGroups",
                                          tl: $$event.types
                                        }),
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        })),
                groupTypes: model$3.groupTypes,
                integrations: model$3.integrations,
                metrics: model$3.metrics,
                openBranches: model$3.openBranches,
                properties: Curry._2(TrackingPlanMappedModel.Properties.append, model$3.properties, newProperty),
                propertyBundles: model$3.propertyBundles,
                sources: model$3.sources
              }
            };
    }
    if (variant === "MergeBranch") {
      var match$2 = action.VAL;
      var actions = match$2[2];
      var branchId = match$2[0];
      if (model.TAG === /* Old */0) {
        var nextModel = TrackingPlanMappedModel.unboxModel(Belt_List.reduceU(actions, model, reduceFunction));
        return {
                TAG: /* Old */0,
                _0: {
                  types: nextModel.types,
                  properties: nextModel.properties,
                  propertyBundles: nextModel.propertyBundles,
                  events: nextModel.events,
                  migrations: nextModel.migrations,
                  sources: nextModel.sources,
                  destinations: nextModel.destinations,
                  groupTypes: nextModel.groupTypes,
                  goals: nextModel.goals,
                  metrics: nextModel.metrics,
                  archive: nextModel.archive,
                  openBranches: Belt_List.keepU(model._0.openBranches, (function (param) {
                          return param[0] !== branchId;
                        })),
                  branchPointer: nextModel.branchPointer,
                  rules: nextModel.rules,
                  integrations: nextModel.integrations
                }
              };
      }
      var nextModel$1 = TrackingPlanMappedModel.unboxModel(Belt_List.reduceU(actions, model, reduceFunction));
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: nextModel$1.archive,
                branchPointer: nextModel$1.branchPointer,
                migrations: nextModel$1.migrations,
                rules: nextModel$1.rules,
                types: nextModel$1.types,
                categories: nextModel$1.categories,
                destinations: nextModel$1.destinations,
                events: nextModel$1.events,
                groupTypes: nextModel$1.groupTypes,
                integrations: nextModel$1.integrations,
                metrics: nextModel$1.metrics,
                openBranches: Curry._2(TrackingPlanMappedModel.OpenBranches.remove, model._0.openBranches, branchId),
                properties: nextModel$1.properties,
                propertyBundles: nextModel$1.propertyBundles,
                sources: nextModel$1.sources
              }
            };
    }
    if (variant === "AddEventType") {
      var match$3 = action.VAL;
      var type_ = match$3[1];
      var eventId$1 = match$3[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$1, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: {
                                  hd: type_,
                                  tl: $$event.types
                                },
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: type_ === "SkipLogEvent" ? [] : $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$4 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$4.archive,
                branchPointer: model$4.branchPointer,
                migrations: model$4.migrations,
                rules: model$4.rules,
                types: model$4.types,
                categories: model$4.categories,
                destinations: model$4.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$4.events, eventId$1, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: {
                                  hd: type_,
                                  tl: $$event.types
                                },
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: type_ === "SkipLogEvent" ? [] : $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$4.groupTypes,
                integrations: model$4.integrations,
                metrics: model$4.metrics,
                openBranches: model$4.openBranches,
                properties: model$4.properties,
                propertyBundles: model$4.propertyBundles,
                sources: model$4.sources
              }
            };
    }
    if (variant === "UpdateTriggerContent") {
      var match$4 = action.VAL;
      var content = match$4[2];
      var triggerId = match$4[1];
      var eventId$2 = match$4[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEventTrigger(model._0, eventId$2, triggerId, (function (trigger) {
                        return {
                                id: trigger.id,
                                sources: trigger.sources,
                                description: trigger.description,
                                content: content
                              };
                      }))
              };
      }
      var model$5 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$5.archive,
                branchPointer: model$5.branchPointer,
                migrations: model$5.migrations,
                rules: model$5.rules,
                types: model$5.types,
                categories: model$5.categories,
                destinations: model$5.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$5.events, eventId$2, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.mapU($$event.triggers, (function (trigger) {
                                        if (trigger.id === triggerId) {
                                          return {
                                                  id: trigger.id,
                                                  sources: trigger.sources,
                                                  description: trigger.description,
                                                  content: content
                                                };
                                        } else {
                                          return trigger;
                                        }
                                      }))
                              };
                      })),
                groupTypes: model$5.groupTypes,
                integrations: model$5.integrations,
                metrics: model$5.metrics,
                openBranches: model$5.openBranches,
                properties: model$5.properties,
                propertyBundles: model$5.propertyBundles,
                sources: model$5.sources
              }
            };
    }
    if (variant === "RemovePropertyValidationMinOrMax") {
      var match$5 = action.VAL;
      var validationIndex = match$5[1];
      var propertyId$1 = match$5[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updatePropertyValidations(model._0, propertyId$1, (function (validations, param) {
                        var match = Belt_List.splitAt(validations, validationIndex);
                        if (match !== undefined) {
                          return Belt_List.concat(match[0], Belt_Option.getWithDefault(Belt_List.tail(match[1]), /* [] */0));
                        } else {
                          return validations;
                        }
                      }))
              };
      }
      var model$6 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$6.archive,
                branchPointer: model$6.branchPointer,
                migrations: model$6.migrations,
                rules: model$6.rules,
                types: model$6.types,
                categories: model$6.categories,
                destinations: model$6.destinations,
                events: model$6.events,
                groupTypes: model$6.groupTypes,
                integrations: model$6.integrations,
                metrics: model$6.metrics,
                openBranches: model$6.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$6.properties, propertyId$1, (function (property) {
                        var match = Belt_List.splitAt(property.validations, validationIndex);
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: match !== undefined ? Belt_List.concat(match[0], Belt_Option.getWithDefault(Belt_List.tail(match[1]), /* [] */0)) : property.validations,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$6.propertyBundles,
                sources: model$6.sources
              }
            };
    }
    if (variant === "UpdateSourceDestinationMode") {
      var match$6 = action.VAL;
      var destinationMode = match$6[2];
      var destinationId$1 = match$6[1];
      var sourceId = match$6[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateSource(model._0, sourceId, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: Belt_List.mapU(source.destinations, (function (destinationRef) {
                                        if (destinationRef.destinationId === destinationId$1) {
                                          return {
                                                  destinationId: destinationRef.destinationId,
                                                  destinationMode: destinationMode
                                                };
                                        } else {
                                          return destinationRef;
                                        }
                                      })),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$7 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$7.archive,
                branchPointer: model$7.branchPointer,
                migrations: model$7.migrations,
                rules: model$7.rules,
                types: model$7.types,
                categories: model$7.categories,
                destinations: model$7.destinations,
                events: model$7.events,
                groupTypes: model$7.groupTypes,
                integrations: model$7.integrations,
                metrics: model$7.metrics,
                openBranches: model$7.openBranches,
                properties: model$7.properties,
                propertyBundles: model$7.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$7.sources, sourceId, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: Belt_List.mapU(source.destinations, (function (destinationRef) {
                                        if (destinationRef.destinationId === destinationId$1) {
                                          return {
                                                  destinationId: destinationRef.destinationId,
                                                  destinationMode: destinationMode
                                                };
                                        } else {
                                          return destinationRef;
                                        }
                                      })),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "TogglePropertyValidationMatchSource") {
      var match$7 = action.VAL;
      var isActive = match$7[3];
      var sourceId$1 = match$7[2];
      var match_ = match$7[1];
      var propertyId$2 = match$7[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updatePropertyValidations(model._0, propertyId$2, (function (validations, _property) {
                        return Belt_List.mapU(validations, (function (validation) {
                                      if (typeof validation === "object" && validation.NAME === "Matches") {
                                        return {
                                                NAME: "Matches",
                                                VAL: Belt_List.mapU(validation.VAL, (function (param) {
                                                        var excludeSources = param[1];
                                                        var currentMatch = param[0];
                                                        if (typeof currentMatch !== "object") {
                                                          return [
                                                                  currentMatch,
                                                                  excludeSources
                                                                ];
                                                        }
                                                        if (currentMatch.NAME !== "Literal") {
                                                          return [
                                                                  currentMatch,
                                                                  excludeSources
                                                                ];
                                                        }
                                                        var match = currentMatch.VAL;
                                                        if (typeof match === "object" && match.NAME === "StringLit" && match.VAL === match_) {
                                                          return [
                                                                  currentMatch,
                                                                  isActive ? Belt_List.keep(excludeSources, (function (id) {
                                                                            return id !== sourceId$1;
                                                                          })) : Belt_List.add(Belt_List.keep(excludeSources, (function (id) {
                                                                                return id !== sourceId$1;
                                                                              })), sourceId$1)
                                                                ];
                                                        } else {
                                                          return [
                                                                  currentMatch,
                                                                  excludeSources
                                                                ];
                                                        }
                                                      }))
                                              };
                                      } else {
                                        return validation;
                                      }
                                    }));
                      }))
              };
      }
      var model$8 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$8.archive,
                branchPointer: model$8.branchPointer,
                migrations: model$8.migrations,
                rules: model$8.rules,
                types: model$8.types,
                categories: model$8.categories,
                destinations: model$8.destinations,
                events: model$8.events,
                groupTypes: model$8.groupTypes,
                integrations: model$8.integrations,
                metrics: model$8.metrics,
                openBranches: model$8.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$8.properties, propertyId$2, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: Belt_List.mapU(property.validations, (function (validation) {
                                        if (typeof validation === "object" && validation.NAME === "Matches") {
                                          return {
                                                  NAME: "Matches",
                                                  VAL: Belt_List.mapU(validation.VAL, (function (param) {
                                                          var excludeSources = param[1];
                                                          var currentMatch = param[0];
                                                          if (typeof currentMatch !== "object") {
                                                            return [
                                                                    currentMatch,
                                                                    excludeSources
                                                                  ];
                                                          }
                                                          if (currentMatch.NAME !== "Literal") {
                                                            return [
                                                                    currentMatch,
                                                                    excludeSources
                                                                  ];
                                                          }
                                                          var match = currentMatch.VAL;
                                                          if (typeof match === "object" && match.NAME === "StringLit" && match.VAL === match_) {
                                                            return [
                                                                    currentMatch,
                                                                    isActive ? Belt_List.keep(excludeSources, (function (id) {
                                                                              return id !== sourceId$1;
                                                                            })) : Belt_List.add(Belt_List.keep(excludeSources, (function (id) {
                                                                                  return id !== sourceId$1;
                                                                                })), sourceId$1)
                                                                  ];
                                                          } else {
                                                            return [
                                                                    currentMatch,
                                                                    excludeSources
                                                                  ];
                                                          }
                                                        }))
                                                };
                                        } else {
                                          return validation;
                                        }
                                      })),
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$8.propertyBundles,
                sources: model$8.sources
              }
            };
    }
    if (variant === "UpdatePropertyUniqueName") {
      var match$8 = action.VAL;
      var name = match$8[1];
      var propertyId$3 = match$8[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateProperty(model._0, propertyId$3, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: name === "" ? undefined : name,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      }))
              };
      }
      var model$9 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$9.archive,
                branchPointer: model$9.branchPointer,
                migrations: model$9.migrations,
                rules: model$9.rules,
                types: model$9.types,
                categories: model$9.categories,
                destinations: model$9.destinations,
                events: model$9.events,
                groupTypes: model$9.groupTypes,
                integrations: model$9.integrations,
                metrics: model$9.metrics,
                openBranches: model$9.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$9.properties, propertyId$3, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: name === "" ? undefined : name,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$9.propertyBundles,
                sources: model$9.sources
              }
            };
    }
    if (variant === "UpdateDestinationIncludeUserPropsWithEventProps") {
      var match$9 = action.VAL;
      var includeUserPropsWithEventProps = match$9[1];
      var destinationId$2 = match$9[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateDestination(model._0, destinationId$2, (function (destination) {
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      }))
              };
      }
      var model$10 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$10.archive,
                branchPointer: model$10.branchPointer,
                migrations: model$10.migrations,
                rules: model$10.rules,
                types: model$10.types,
                categories: model$10.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.update, model$10.destinations, destinationId$2, (function (destination) {
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      })),
                events: model$10.events,
                groupTypes: model$10.groupTypes,
                integrations: model$10.integrations,
                metrics: model$10.metrics,
                openBranches: model$10.openBranches,
                properties: model$10.properties,
                propertyBundles: model$10.propertyBundles,
                sources: model$10.sources
              }
            };
    }
    if (variant === "AddEvent") {
      var match$10 = action.VAL;
      var name$1 = match$10[1];
      var eventId$3 = match$10[0];
      if (model.TAG === /* Old */0) {
        var model$11 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$11.types,
                  properties: model$11.properties,
                  propertyBundles: model$11.propertyBundles,
                  events: Belt_List.concat(model$11.events, {
                        hd: TrackingPlanModel.emptyEvent(eventId$3, name$1),
                        tl: /* [] */0
                      }),
                  migrations: model$11.migrations,
                  sources: model$11.sources,
                  destinations: model$11.destinations,
                  groupTypes: model$11.groupTypes,
                  goals: model$11.goals,
                  metrics: model$11.metrics,
                  archive: model$11.archive,
                  openBranches: model$11.openBranches,
                  branchPointer: model$11.branchPointer,
                  rules: model$11.rules,
                  integrations: model$11.integrations
                }
              };
      }
      var model$12 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$12.archive,
                branchPointer: model$12.branchPointer,
                migrations: model$12.migrations,
                rules: model$12.rules,
                types: model$12.types,
                categories: model$12.categories,
                destinations: model$12.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.append, model$12.events, TrackingPlanModel.emptyEvent(eventId$3, name$1)),
                groupTypes: model$12.groupTypes,
                integrations: model$12.integrations,
                metrics: model$12.metrics,
                openBranches: model$12.openBranches,
                properties: model$12.properties,
                propertyBundles: model$12.propertyBundles,
                sources: model$12.sources
              }
            };
    }
    if (variant === "RemovePropertyPermanently") {
      var propertyId$4 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$13 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$13.types,
                  properties: Belt_List.keepU(model$13.properties, (function (property) {
                          if (property.TAG === /* PropertyRef */0) {
                            return true;
                          } else {
                            return property._0.id !== propertyId$4;
                          }
                        })),
                  propertyBundles: Belt_List.mapU(model$13.propertyBundles, (function (group) {
                          return {
                                  id: group.id,
                                  name: group.name,
                                  description: group.description,
                                  properties: Belt_List.keepU(group.properties, (function (id) {
                                          return id !== propertyId$4;
                                        }))
                                };
                        })),
                  events: model$13.events,
                  migrations: model$13.migrations,
                  sources: model$13.sources,
                  destinations: model$13.destinations,
                  groupTypes: model$13.groupTypes,
                  goals: model$13.goals,
                  metrics: model$13.metrics,
                  archive: model$13.archive,
                  openBranches: model$13.openBranches,
                  branchPointer: model$13.branchPointer,
                  rules: model$13.rules,
                  integrations: model$13.integrations
                }
              };
      }
      var model$14 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$14.archive,
                branchPointer: model$14.branchPointer,
                migrations: model$14.migrations,
                rules: model$14.rules,
                types: model$14.types,
                categories: model$14.categories,
                destinations: model$14.destinations,
                events: model$14.events,
                groupTypes: model$14.groupTypes,
                integrations: model$14.integrations,
                metrics: model$14.metrics,
                openBranches: model$14.openBranches,
                properties: Curry._2(TrackingPlanMappedModel.Properties.remove, model$14.properties, propertyId$4),
                propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.mapToT, model$14.propertyBundles, (function (propertyBundle) {
                        return {
                                id: propertyBundle.id,
                                name: propertyBundle.name,
                                description: propertyBundle.description,
                                properties: Belt_List.keepU(propertyBundle.properties, (function (id) {
                                        return id !== propertyId$4;
                                      }))
                              };
                      })),
                sources: model$14.sources
              }
            };
    }
    if (variant === "RemoveMember" || variant === "CompletedImport" || variant === "CreateServiceAccount" || variant === "AutoPublishIntegration" || variant === "ArchiveSavedView" || variant === "ResendInvite" || variant === "CreatePublicBranchImplementation" || variant === "SubscriptionStarted" || variant === "PublishIntegration" || variant === "SubscriptionCancelled" || variant === "SubscriptionUpdated" || variant === "GenerateWebhookSecret" || variant === "UpdateMemberRole" || variant === "UpdateSavedView" || variant === "DeleteServiceAccount" || variant === "DeletePublicBranchImplementation" || variant === "RemoveInvite" || variant === "InviteMember" || variant === "PaymentCompleted" || variant === "CreateSavedView") {
      return model;
    }
    if (variant === "RemovePropertyFromWhitelist") {
      var match$11 = action.VAL;
      var analyticsTool = match$11[2];
      var propertyId$5 = match$11[1];
      var eventId$4 = match$11[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$4, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                        return !(param[0] === analyticsTool && param[1] === propertyId$5);
                                      })),
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$15 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$15.archive,
                branchPointer: model$15.branchPointer,
                migrations: model$15.migrations,
                rules: model$15.rules,
                types: model$15.types,
                categories: model$15.categories,
                destinations: model$15.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$15.events, eventId$4, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                        return !(param[0] === analyticsTool && param[1] === propertyId$5);
                                      })),
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$15.groupTypes,
                integrations: model$15.integrations,
                metrics: model$15.metrics,
                openBranches: model$15.openBranches,
                properties: model$15.properties,
                propertyBundles: model$15.propertyBundles,
                sources: model$15.sources
              }
            };
    }
    if (variant === "UpdateIntegrationDestinationIds" || variant === "BranchInit" || variant === "SetBranchStatus") {
      return model;
    }
    if (variant === "UpdateLibraryDestination") {
      var match$12 = action.VAL;
      var destination = match$12[1];
      var sourceId$2 = match$12[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateSource(model._0, sourceId$2, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: destination,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$16 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$16.archive,
                branchPointer: model$16.branchPointer,
                migrations: model$16.migrations,
                rules: model$16.rules,
                types: model$16.types,
                categories: model$16.categories,
                destinations: model$16.destinations,
                events: model$16.events,
                groupTypes: model$16.groupTypes,
                integrations: model$16.integrations,
                metrics: model$16.metrics,
                openBranches: model$16.openBranches,
                properties: model$16.properties,
                propertyBundles: model$16.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$16.sources, sourceId$2, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: destination,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "ImportDeprecated") {
      var actions$1 = action.VAL;
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: TrackingPlanMappedModel.unboxModel(Belt_List.reduceU(actions$1, model, reduceFunction))
              };
      }
      var nextModel$2 = TrackingPlanMappedModel.unboxModel(Belt_List.reduceU(actions$1, model, reduceFunction));
      return {
              TAG: /* Mapped */1,
              _0: nextModel$2
            };
    }
    if (variant === "AddGoal") {
      var goalId = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$17 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$17.types,
                  properties: model$17.properties,
                  propertyBundles: model$17.propertyBundles,
                  events: model$17.events,
                  migrations: model$17.migrations,
                  sources: model$17.sources,
                  destinations: model$17.destinations,
                  groupTypes: model$17.groupTypes,
                  goals: Belt_List.concat({
                        hd: TrackingPlanModel.emptyGoal(goalId),
                        tl: /* [] */0
                      }, model$17.goals),
                  metrics: model$17.metrics,
                  archive: model$17.archive,
                  openBranches: model$17.openBranches,
                  branchPointer: model$17.branchPointer,
                  rules: model$17.rules,
                  integrations: model$17.integrations
                }
              };
      }
      var model$18 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$18.archive,
                branchPointer: model$18.branchPointer,
                migrations: model$18.migrations,
                rules: model$18.rules,
                types: model$18.types,
                categories: Curry._2(TrackingPlanMappedModel.Categories.prepend, model$18.categories, TrackingPlanModel.emptyGoal(goalId)),
                destinations: model$18.destinations,
                events: model$18.events,
                groupTypes: model$18.groupTypes,
                integrations: model$18.integrations,
                metrics: model$18.metrics,
                openBranches: model$18.openBranches,
                properties: model$18.properties,
                propertyBundles: model$18.propertyBundles,
                sources: model$18.sources
              }
            };
    }
    if (variant === "UpdateIntegrationAutoPublish") {
      var match$13 = action.VAL;
      var autoPublish = match$13[1];
      var integrationId = match$13[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateIntegration(model._0, integrationId, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: integration.config,
                                filters: integration.filters,
                                autoPublish: autoPublish
                              };
                      }))
              };
      }
      var model$19 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$19.archive,
                branchPointer: model$19.branchPointer,
                migrations: model$19.migrations,
                rules: model$19.rules,
                types: model$19.types,
                categories: model$19.categories,
                destinations: model$19.destinations,
                events: model$19.events,
                groupTypes: model$19.groupTypes,
                integrations: Curry._3(TrackingPlanMappedModel.Integrations.update, model$19.integrations, integrationId, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: integration.config,
                                filters: integration.filters,
                                autoPublish: autoPublish
                              };
                      })),
                metrics: model$19.metrics,
                openBranches: model$19.openBranches,
                properties: model$19.properties,
                propertyBundles: model$19.propertyBundles,
                sources: model$19.sources
              }
            };
    }
    if (variant === "CreateDemoBranch") {
      return Belt_List.reduceU(action.VAL, model, reduceFunction);
    }
    if (variant === "UpdateGoalDescription") {
      var match$14 = action.VAL;
      var description = match$14[1];
      var goalId$1 = match$14[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateGoal(model._0, goalId$1, (function (goal) {
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: description,
                                metrics: goal.metrics,
                                events: goal.events
                              };
                      }))
              };
      }
      var model$20 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$20.archive,
                branchPointer: model$20.branchPointer,
                migrations: model$20.migrations,
                rules: model$20.rules,
                types: model$20.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.update, model$20.categories, goalId$1, (function (category) {
                        return {
                                id: category.id,
                                name: category.name,
                                description: description,
                                metrics: category.metrics,
                                events: category.events
                              };
                      })),
                destinations: model$20.destinations,
                events: model$20.events,
                groupTypes: model$20.groupTypes,
                integrations: model$20.integrations,
                metrics: model$20.metrics,
                openBranches: model$20.openBranches,
                properties: model$20.properties,
                propertyBundles: model$20.propertyBundles,
                sources: model$20.sources
              }
            };
    }
    if (variant === "MigrateSourcesAndDestinationsToModel") {
      var match$15 = action.VAL;
      var destinations = match$15[1];
      var sources = match$15[0];
      if (model.TAG === /* Old */0) {
        var model$21 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$21.types,
                  properties: model$21.properties,
                  propertyBundles: model$21.propertyBundles,
                  events: model$21.events,
                  migrations: model$21.migrations,
                  sources: sources,
                  destinations: destinations,
                  groupTypes: model$21.groupTypes,
                  goals: model$21.goals,
                  metrics: model$21.metrics,
                  archive: model$21.archive,
                  openBranches: model$21.openBranches,
                  branchPointer: model$21.branchPointer,
                  rules: model$21.rules,
                  integrations: model$21.integrations
                }
              };
      }
      var model$22 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$22.archive,
                branchPointer: model$22.branchPointer,
                migrations: model$22.migrations,
                rules: model$22.rules,
                types: model$22.types,
                categories: model$22.categories,
                destinations: Curry._1(TrackingPlanMappedModel.Destinations.fromList, destinations),
                events: model$22.events,
                groupTypes: model$22.groupTypes,
                integrations: model$22.integrations,
                metrics: model$22.metrics,
                openBranches: model$22.openBranches,
                properties: model$22.properties,
                propertyBundles: model$22.propertyBundles,
                sources: Curry._1(TrackingPlanMappedModel.Sources.fromList, sources)
              }
            };
    }
    if (variant === "UpdatePropertyList") {
      var match$16 = action.VAL;
      var list = match$16[1];
      var propertyId$6 = match$16[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateProperty(model._0, propertyId$6, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      }))
              };
      }
      var model$23 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$23.archive,
                branchPointer: model$23.branchPointer,
                migrations: model$23.migrations,
                rules: model$23.rules,
                types: model$23.types,
                categories: model$23.categories,
                destinations: model$23.destinations,
                events: model$23.events,
                groupTypes: model$23.groupTypes,
                integrations: model$23.integrations,
                metrics: model$23.metrics,
                openBranches: model$23.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$23.properties, propertyId$6, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$23.propertyBundles,
                sources: model$23.sources
              }
            };
    }
    if (variant === "SetMetricWhereV2") {
      var match$17 = action.VAL;
      var literals = match$17[6];
      var binOp = match$17[5];
      var propertyId$7 = match$17[4];
      var id = match$17[3];
      var metricItemId = match$17[1];
      var metricId = match$17[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateMetric(model._0, metricId, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId !== $$event.id) {
                                          return metricItem;
                                        }
                                        var where = $$event.where;
                                        var match = Belt_List.keepU(where, (function (where) {
                                                return where.id === id;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: match ? Belt_List.mapU(where, (function (where) {
                                                            if (where.id === id) {
                                                              return {
                                                                      id: id,
                                                                      propertyId: propertyId$7,
                                                                      binOp: binOp,
                                                                      literals: literals
                                                                    };
                                                            } else {
                                                              return where;
                                                            }
                                                          })) : Belt_List.concat(where, {
                                                          hd: {
                                                            id: id,
                                                            propertyId: propertyId$7,
                                                            binOp: binOp,
                                                            literals: literals
                                                          },
                                                          tl: /* [] */0
                                                        }),
                                                  groupBy: $$event.groupBy
                                                }
                                              };
                                      }))
                              };
                      }))
              };
      }
      var model$24 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$24.archive,
                branchPointer: model$24.branchPointer,
                migrations: model$24.migrations,
                rules: model$24.rules,
                types: model$24.types,
                categories: model$24.categories,
                destinations: model$24.destinations,
                events: model$24.events,
                groupTypes: model$24.groupTypes,
                integrations: model$24.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.update, model$24.metrics, metricId, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId !== $$event.id) {
                                          return metricItem;
                                        }
                                        var where = $$event.where;
                                        var match = Belt_List.keepU(where, (function (where) {
                                                return where.id === id;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: match ? Belt_List.mapU(where, (function (where) {
                                                            if (where.id === id) {
                                                              return {
                                                                      id: id,
                                                                      propertyId: propertyId$7,
                                                                      binOp: binOp,
                                                                      literals: literals
                                                                    };
                                                            } else {
                                                              return where;
                                                            }
                                                          })) : Belt_List.concat(where, {
                                                          hd: {
                                                            id: id,
                                                            propertyId: propertyId$7,
                                                            binOp: binOp,
                                                            literals: literals
                                                          },
                                                          tl: /* [] */0
                                                        }),
                                                  groupBy: $$event.groupBy
                                                }
                                              };
                                      }))
                              };
                      })),
                openBranches: model$24.openBranches,
                properties: model$24.properties,
                propertyBundles: model$24.propertyBundles,
                sources: model$24.sources
              }
            };
    }
    if (variant === "UpdatePropertyName") {
      var match$18 = action.VAL;
      var name$2 = match$18[1];
      var propertyId$8 = match$18[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateProperty(model._0, propertyId$8, (function (property) {
                        return {
                                id: property.id,
                                name: name$2,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      }))
              };
      }
      var model$25 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$25.archive,
                branchPointer: model$25.branchPointer,
                migrations: model$25.migrations,
                rules: model$25.rules,
                types: model$25.types,
                categories: model$25.categories,
                destinations: model$25.destinations,
                events: model$25.events,
                groupTypes: model$25.groupTypes,
                integrations: model$25.integrations,
                metrics: model$25.metrics,
                openBranches: model$25.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$25.properties, propertyId$8, (function (property) {
                        return {
                                id: property.id,
                                name: name$2,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$25.propertyBundles,
                sources: model$25.sources
              }
            };
    }
    if (variant === "UpdatePropertyDescription") {
      var match$19 = action.VAL;
      var description$1 = match$19[1];
      var propertyId$9 = match$19[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateProperty(model._0, propertyId$9, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: description$1,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      }))
              };
      }
      var model$26 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$26.archive,
                branchPointer: model$26.branchPointer,
                migrations: model$26.migrations,
                rules: model$26.rules,
                types: model$26.types,
                categories: model$26.categories,
                destinations: model$26.destinations,
                events: model$26.events,
                groupTypes: model$26.groupTypes,
                integrations: model$26.integrations,
                metrics: model$26.metrics,
                openBranches: model$26.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$26.properties, propertyId$9, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: description$1,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$26.propertyBundles,
                sources: model$26.sources
              }
            };
    }
    if (variant === "AddTag") {
      var match$20 = action.VAL;
      var tag = match$20[1];
      var eventId$5 = match$20[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$5, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: Belt_List.hasU($$event.tags, tag, (function (a, b) {
                                        return a === b;
                                      })) ? $$event.tags : Belt_List.concat($$event.tags, {
                                        hd: tag,
                                        tl: /* [] */0
                                      }),
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$27 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$27.archive,
                branchPointer: model$27.branchPointer,
                migrations: model$27.migrations,
                rules: model$27.rules,
                types: model$27.types,
                categories: model$27.categories,
                destinations: model$27.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$27.events, eventId$5, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: Belt_List.hasU($$event.tags, tag, (function (a, b) {
                                        return a === b;
                                      })) ? $$event.tags : Belt_List.concat($$event.tags, {
                                        hd: tag,
                                        tl: /* [] */0
                                      }),
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$27.groupTypes,
                integrations: model$27.integrations,
                metrics: model$27.metrics,
                openBranches: model$27.openBranches,
                properties: model$27.properties,
                propertyBundles: model$27.propertyBundles,
                sources: model$27.sources
              }
            };
    }
    if (variant === "ReorderMetricsInGoal") {
      var match$21 = action.VAL;
      var maybePredecessorId = match$21[2];
      var metricId$1 = match$21[1];
      var goalId$2 = match$21[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateGoal(model._0, goalId$2, (function (goal) {
                        var movedItem = Belt_List.getByU(goal.metrics, (function (id) {
                                return id === metricId$1;
                              }));
                        var listWithoutItem = Belt_List.keepU(goal.metrics, (function (id) {
                                return id !== metricId$1;
                              }));
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: movedItem !== undefined ? (
                                    maybePredecessorId !== undefined ? BeltListExtensions.flatMap(listWithoutItem, (function (id) {
                                              if (id === maybePredecessorId) {
                                                return {
                                                        hd: id,
                                                        tl: {
                                                          hd: metricId$1,
                                                          tl: /* [] */0
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        hd: id,
                                                        tl: /* [] */0
                                                      };
                                              }
                                            })) : Belt_List.concat({
                                            hd: metricId$1,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : goal.metrics,
                                events: goal.events
                              };
                      }))
              };
      }
      var model$28 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$28.archive,
                branchPointer: model$28.branchPointer,
                migrations: model$28.migrations,
                rules: model$28.rules,
                types: model$28.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.update, model$28.categories, goalId$2, (function (category) {
                        var movedItem = Belt_List.getByU(category.metrics, (function (id) {
                                return id === metricId$1;
                              }));
                        var listWithoutItem = Belt_List.keepU(category.metrics, (function (id) {
                                return id !== metricId$1;
                              }));
                        return {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                metrics: movedItem !== undefined ? (
                                    maybePredecessorId !== undefined ? BeltListExtensions.flatMap(listWithoutItem, (function (id) {
                                              if (id === maybePredecessorId) {
                                                return {
                                                        hd: id,
                                                        tl: {
                                                          hd: metricId$1,
                                                          tl: /* [] */0
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        hd: id,
                                                        tl: /* [] */0
                                                      };
                                              }
                                            })) : Belt_List.concat({
                                            hd: metricId$1,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : category.metrics,
                                events: category.events
                              };
                      })),
                destinations: model$28.destinations,
                events: model$28.events,
                groupTypes: model$28.groupTypes,
                integrations: model$28.integrations,
                metrics: model$28.metrics,
                openBranches: model$28.openBranches,
                properties: model$28.properties,
                propertyBundles: model$28.propertyBundles,
                sources: model$28.sources
              }
            };
    }
    if (variant === "UpdatePropertySendAs") {
      var match$22 = action.VAL;
      var sendAs$1 = match$22[1];
      var propertyId$10 = match$22[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateProperty(model._0, propertyId$10, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: sendAs$1,
                                validations: property.validations,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      }))
              };
      }
      var model$29 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$29.archive,
                branchPointer: model$29.branchPointer,
                migrations: model$29.migrations,
                rules: model$29.rules,
                types: model$29.types,
                categories: model$29.categories,
                destinations: model$29.destinations,
                events: model$29.events,
                groupTypes: model$29.groupTypes,
                integrations: model$29.integrations,
                metrics: model$29.metrics,
                openBranches: model$29.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$29.properties, propertyId$10, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: sendAs$1,
                                validations: property.validations,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$29.propertyBundles,
                sources: model$29.sources
              }
            };
    }
    if (variant === "AddRule") {
      var match$23 = action.VAL;
      var definition = match$23[2];
      var item = match$23[1];
      var ruleId = match$23[0];
      if (model.TAG === /* Old */0) {
        var model$30 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$30.types,
                  properties: model$30.properties,
                  propertyBundles: model$30.propertyBundles,
                  events: model$30.events,
                  migrations: model$30.migrations,
                  sources: model$30.sources,
                  destinations: model$30.destinations,
                  groupTypes: model$30.groupTypes,
                  goals: model$30.goals,
                  metrics: model$30.metrics,
                  archive: model$30.archive,
                  openBranches: model$30.openBranches,
                  branchPointer: model$30.branchPointer,
                  rules: Belt_List.concat(Belt_List.keep(model$30.rules, (function (rule) {
                              var match = rule.item;
                              if (typeof match !== "object") {
                                return true;
                              }
                              if (match.NAME !== "EventObjectField") {
                                return true;
                              }
                              if (typeof item !== "object") {
                                return true;
                              }
                              if (item.NAME !== "EventObjectField") {
                                return true;
                              }
                              var match$1 = item.VAL;
                              var match$2 = match.VAL;
                              return !(match$2[0] === match$1[0] && match$2[1] === match$1[1] && match$2[2] === match$1[2]);
                            })), {
                        hd: {
                          id: ruleId,
                          item: item,
                          definition: definition
                        },
                        tl: /* [] */0
                      }),
                  integrations: model$30.integrations
                }
              };
      }
      var model$31 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$31.archive,
                branchPointer: model$31.branchPointer,
                migrations: model$31.migrations,
                rules: Belt_Array.concat(Belt_Array.keep(model$31.rules, (function (rule) {
                            var match = rule.item;
                            if (typeof match !== "object") {
                              return true;
                            }
                            if (match.NAME !== "EventObjectField") {
                              return true;
                            }
                            if (typeof item !== "object") {
                              return true;
                            }
                            if (item.NAME !== "EventObjectField") {
                              return true;
                            }
                            var match$1 = item.VAL;
                            var match$2 = match.VAL;
                            return !(match$2[0] === match$1[0] && match$2[1] === match$1[1] && match$2[2] === match$1[2]);
                          })), [{
                        id: ruleId,
                        item: item,
                        definition: definition
                      }]),
                types: model$31.types,
                categories: model$31.categories,
                destinations: model$31.destinations,
                events: model$31.events,
                groupTypes: model$31.groupTypes,
                integrations: model$31.integrations,
                metrics: model$31.metrics,
                openBranches: model$31.openBranches,
                properties: model$31.properties,
                propertyBundles: model$31.propertyBundles,
                sources: model$31.sources
              }
            };
    }
    if (variant === "UpdateSourceName") {
      var match$24 = action.VAL;
      var name$3 = match$24[1];
      var sourceId$3 = match$24[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateSource(model._0, sourceId$3, (function (source) {
                        return {
                                id: source.id,
                                name: name$3,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$32 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$32.archive,
                branchPointer: model$32.branchPointer,
                migrations: model$32.migrations,
                rules: model$32.rules,
                types: model$32.types,
                categories: model$32.categories,
                destinations: model$32.destinations,
                events: model$32.events,
                groupTypes: model$32.groupTypes,
                integrations: model$32.integrations,
                metrics: model$32.metrics,
                openBranches: model$32.openBranches,
                properties: model$32.properties,
                propertyBundles: model$32.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$32.sources, sourceId$3, (function (source) {
                        return {
                                id: source.id,
                                name: name$3,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "CollaboratorRemoved" || variant === "CodeGenerated" || variant === "PropertyComment" || variant === "DeleteComment" || variant === "GoalComment" || variant === "IntegrationComment" || variant === "MetricComment" || variant === "CollaboratorAdded" || variant === "PropertyGroupComment" || variant === "EventComment" || variant === "BranchComment") {
      return model;
    }
    if (variant === "UpdatePropertyType") {
      var match$25 = action.VAL;
      var type_$1 = match$25[1];
      var propertyId$11 = match$25[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateProperty(model._0, propertyId$11, (function (property) {
                        var tmp = type_$1 === "object" ? ({
                              hd: {
                                NAME: "NestedProperty",
                                VAL: /* [] */0
                              },
                              tl: /* [] */0
                            }) : Belt_List.keepU(property.validations, (function (validation) {
                                  switch (type_$1) {
                                    case "bool" :
                                        return false;
                                    case "float" :
                                        if (typeof validation !== "object") {
                                          return false;
                                        }
                                        var variant = validation.NAME;
                                        if (!(variant === "Min" || variant === "Max")) {
                                          return false;
                                        }
                                        var match = validation.VAL;
                                        if (typeof match === "object") {
                                          return match.NAME === "FloatLit";
                                        } else {
                                          return false;
                                        }
                                    case "int" :
                                        if (typeof validation !== "object") {
                                          return false;
                                        }
                                        var variant$1 = validation.NAME;
                                        if (!(variant$1 === "Min" || variant$1 === "Max")) {
                                          return false;
                                        }
                                        var match$1 = validation.VAL;
                                        if (typeof match$1 === "object") {
                                          return match$1.NAME === "IntLit";
                                        } else {
                                          return false;
                                        }
                                    case "string" :
                                        if (typeof validation === "object") {
                                          return validation.NAME === "Matches";
                                        } else {
                                          return false;
                                        }
                                    default:
                                      return false;
                                  }
                                }));
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: type_$1,
                                sendAs: property.sendAs,
                                validations: tmp,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.type_ === "object" && type_$1 !== "object" ? "Set" : property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      }))
              };
      }
      var model$33 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$33.archive,
                branchPointer: model$33.branchPointer,
                migrations: model$33.migrations,
                rules: model$33.rules,
                types: model$33.types,
                categories: model$33.categories,
                destinations: model$33.destinations,
                events: model$33.events,
                groupTypes: model$33.groupTypes,
                integrations: model$33.integrations,
                metrics: model$33.metrics,
                openBranches: model$33.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$33.properties, propertyId$11, (function (property) {
                        var tmp = type_$1 === "object" ? ({
                              hd: {
                                NAME: "NestedProperty",
                                VAL: /* [] */0
                              },
                              tl: /* [] */0
                            }) : Belt_List.keepU(property.validations, (function (validation) {
                                  switch (type_$1) {
                                    case "bool" :
                                        return false;
                                    case "float" :
                                        if (typeof validation !== "object") {
                                          return false;
                                        }
                                        var variant = validation.NAME;
                                        if (!(variant === "Min" || variant === "Max")) {
                                          return false;
                                        }
                                        var match = validation.VAL;
                                        if (typeof match === "object") {
                                          return match.NAME === "FloatLit";
                                        } else {
                                          return false;
                                        }
                                    case "int" :
                                        if (typeof validation !== "object") {
                                          return false;
                                        }
                                        var variant$1 = validation.NAME;
                                        if (!(variant$1 === "Min" || variant$1 === "Max")) {
                                          return false;
                                        }
                                        var match$1 = validation.VAL;
                                        if (typeof match$1 === "object") {
                                          return match$1.NAME === "IntLit";
                                        } else {
                                          return false;
                                        }
                                    case "string" :
                                        if (typeof validation === "object") {
                                          return validation.NAME === "Matches";
                                        } else {
                                          return false;
                                        }
                                    default:
                                      return false;
                                  }
                                }));
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: type_$1,
                                sendAs: property.sendAs,
                                validations: tmp,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.type_ === "object" && type_$1 !== "object" ? "Set" : property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$33.propertyBundles,
                sources: model$33.sources
              }
            };
    }
    if (variant === "LegacyIncludeDestinationInSource") {
      var match$26 = action.VAL;
      var eventIds = match$26[2];
      var destinationId$3 = match$26[1];
      var sourceId$4 = match$26[0];
      if (model.TAG === /* Old */0) {
        var model$34 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$34.types,
                  properties: model$34.properties,
                  propertyBundles: model$34.propertyBundles,
                  events: Belt_List.mapU(model$34.events, (function ($$event) {
                          if (!Belt_List.someU(eventIds, (function (id) {
                                    return id === $$event.id;
                                  }))) {
                            return $$event;
                          }
                          var includedDestinations = Belt_List.flatten(Belt_List.keepMapU($$event.includeDestinations, (function (includeDestination) {
                                      var match = includeDestination.VAL;
                                      if (match[0] === sourceId$4) {
                                        return match[1];
                                      }
                                      
                                    })));
                          var otherIncludedDestinations = Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                  return includeDestination.VAL[0] !== sourceId$4;
                                }));
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: $$event.includeSources,
                                  includeDestinations: Belt_List.concat(otherIncludedDestinations, {
                                        hd: {
                                          NAME: "IncludeSourceDestination",
                                          VAL: [
                                            sourceId$4,
                                            Belt_List.concat(includedDestinations, {
                                                  hd: destinationId$3,
                                                  tl: /* [] */0
                                                })
                                          ]
                                        },
                                        tl: /* [] */0
                                      }),
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        })),
                  migrations: model$34.migrations,
                  sources: Belt_List.mapU(model$34.sources, (function (source) {
                          if (source.id === sourceId$4) {
                            return {
                                    id: source.id,
                                    name: source.name,
                                    language: source.language,
                                    platform: source.platform,
                                    destinations: Belt_List.concat(source.destinations, {
                                          hd: {
                                            destinationId: destinationId$3,
                                            destinationMode: /* Legacy */2
                                          },
                                          tl: /* [] */0
                                        }),
                                    filename: source.filename,
                                    path: source.path,
                                    segmentDestinationOptions: source.segmentDestinationOptions,
                                    config: source.config
                                  };
                          } else {
                            return source;
                          }
                        })),
                  destinations: model$34.destinations,
                  groupTypes: model$34.groupTypes,
                  goals: model$34.goals,
                  metrics: model$34.metrics,
                  archive: model$34.archive,
                  openBranches: model$34.openBranches,
                  branchPointer: model$34.branchPointer,
                  rules: model$34.rules,
                  integrations: model$34.integrations
                }
              };
      }
      var model$35 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$35.archive,
                branchPointer: model$35.branchPointer,
                migrations: model$35.migrations,
                rules: model$35.rules,
                types: model$35.types,
                categories: model$35.categories,
                destinations: model$35.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateMany, model$35.events, Belt_List.toArray(eventIds), (function ($$event) {
                        var includedDestinations = Belt_List.flatten(Belt_List.keepMapU($$event.includeDestinations, (function (includeDestination) {
                                    var match = includeDestination.VAL;
                                    if (match[0] === sourceId$4) {
                                      return match[1];
                                    }
                                    
                                  })));
                        var otherIncludedDestinations = Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                return includeDestination.VAL[0] !== sourceId$4;
                              }));
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.concat(otherIncludedDestinations, {
                                      hd: {
                                        NAME: "IncludeSourceDestination",
                                        VAL: [
                                          sourceId$4,
                                          Belt_List.concat(includedDestinations, {
                                                hd: destinationId$3,
                                                tl: /* [] */0
                                              })
                                        ]
                                      },
                                      tl: /* [] */0
                                    }),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$35.groupTypes,
                integrations: model$35.integrations,
                metrics: model$35.metrics,
                openBranches: model$35.openBranches,
                properties: model$35.properties,
                propertyBundles: model$35.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$35.sources, sourceId$4, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: Belt_List.concat(source.destinations, {
                                      hd: {
                                        destinationId: destinationId$3,
                                        destinationMode: /* Legacy */2
                                      },
                                      tl: /* [] */0
                                    }),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "AddPropertyRef") {
      var match$27 = action.VAL;
      var propertyId$12 = match$27[1];
      var eventId$6 = match$27[0];
      if (model.TAG === /* Old */0) {
        var model$36 = model._0;
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model$36, eventId$6, (function ($$event) {
                        var match = StateUtils.resolvePropertyById(propertyId$12, model$36);
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.concat($$event.properties, {
                                      hd: {
                                        TAG: /* PropertyRef */0,
                                        _0: {
                                          id: propertyId$12,
                                          description: "",
                                          pinnedValue: undefined
                                        }
                                      },
                                      tl: /* [] */0
                                    }),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: match !== undefined && typeof match.sendAs !== "number" ? BeltListExtensions.dedupeSlow({
                                        hd: "UpdateGroups",
                                        tl: $$event.types
                                      }) : $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$37 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$37.archive,
                branchPointer: model$37.branchPointer,
                migrations: model$37.migrations,
                rules: model$37.rules,
                types: model$37.types,
                categories: model$37.categories,
                destinations: model$37.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$37.events, eventId$6, (function ($$event) {
                        var match = Curry._2(TrackingPlanMappedModel.Properties.get, model$37.properties, propertyId$12);
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.concat($$event.properties, {
                                      hd: {
                                        TAG: /* PropertyRef */0,
                                        _0: {
                                          id: propertyId$12,
                                          description: "",
                                          pinnedValue: undefined
                                        }
                                      },
                                      tl: /* [] */0
                                    }),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: match !== undefined && typeof match.sendAs !== "number" ? BeltListExtensions.dedupeSlow({
                                        hd: "UpdateGroups",
                                        tl: $$event.types
                                      }) : $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$37.groupTypes,
                integrations: model$37.integrations,
                metrics: model$37.metrics,
                openBranches: model$37.openBranches,
                properties: model$37.properties,
                propertyBundles: model$37.propertyBundles,
                sources: model$37.sources
              }
            };
    }
    if (variant === "OpenBranch") {
      var match$28 = action.VAL;
      var branchName = match$28[1];
      var branchId$1 = match$28[0];
      if (model.TAG === /* Old */0) {
        var model$38 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$38.types,
                  properties: model$38.properties,
                  propertyBundles: model$38.propertyBundles,
                  events: model$38.events,
                  migrations: model$38.migrations,
                  sources: model$38.sources,
                  destinations: model$38.destinations,
                  groupTypes: model$38.groupTypes,
                  goals: model$38.goals,
                  metrics: model$38.metrics,
                  archive: model$38.archive,
                  openBranches: Belt_List.concat(model$38.openBranches, {
                        hd: [
                          branchId$1,
                          branchName
                        ],
                        tl: /* [] */0
                      }),
                  branchPointer: model$38.branchPointer,
                  rules: model$38.rules,
                  integrations: model$38.integrations
                }
              };
      }
      var model$39 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$39.archive,
                branchPointer: model$39.branchPointer,
                migrations: model$39.migrations,
                rules: model$39.rules,
                types: model$39.types,
                categories: model$39.categories,
                destinations: model$39.destinations,
                events: model$39.events,
                groupTypes: model$39.groupTypes,
                integrations: model$39.integrations,
                metrics: model$39.metrics,
                openBranches: Curry._2(TrackingPlanMappedModel.OpenBranches.append, model$39.openBranches, [
                      branchId$1,
                      branchName
                    ]),
                properties: model$39.properties,
                propertyBundles: model$39.propertyBundles,
                sources: model$39.sources
              }
            };
    }
    if (variant === "RemoveEvent") {
      var eventId$7 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$40 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$40.types,
                  properties: model$40.properties,
                  propertyBundles: model$40.propertyBundles,
                  events: Belt_List.keepU(model$40.events, (function ($$event) {
                          return $$event.id !== eventId$7;
                        })),
                  migrations: model$40.migrations,
                  sources: model$40.sources,
                  destinations: model$40.destinations,
                  groupTypes: model$40.groupTypes,
                  goals: model$40.goals,
                  metrics: model$40.metrics,
                  archive: model$40.archive,
                  openBranches: model$40.openBranches,
                  branchPointer: model$40.branchPointer,
                  rules: model$40.rules,
                  integrations: model$40.integrations
                }
              };
      }
      var model$41 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$41.archive,
                branchPointer: model$41.branchPointer,
                migrations: model$41.migrations,
                rules: model$41.rules,
                types: model$41.types,
                categories: model$41.categories,
                destinations: model$41.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.remove, model$41.events, eventId$7),
                groupTypes: model$41.groupTypes,
                integrations: model$41.integrations,
                metrics: model$41.metrics,
                openBranches: model$41.openBranches,
                properties: model$41.properties,
                propertyBundles: model$41.propertyBundles,
                sources: model$41.sources
              }
            };
    }
    if (variant === "AddPropertyValidationMinOrMax") {
      var match$29 = action.VAL;
      var validationType = match$29[1];
      var propertyId$13 = match$29[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updatePropertyValidations(model._0, propertyId$13, (function (validations, property) {
                        var match = TrackingPlanModel.decodeType(property.type_);
                        var validationValue = match === "Int" ? ({
                              NAME: "IntLit",
                              VAL: 0
                            }) : (
                            match === "Float" ? ({
                                  NAME: "FloatLit",
                                  VAL: 0.0
                                }) : Pervasives.failwith("Unsupported type for Min constraint")
                          );
                        return Belt_List.reverse({
                                    hd: validationType === "Max" ? ({
                                          NAME: "Max",
                                          VAL: validationValue
                                        }) : (
                                        validationType === "Min" ? ({
                                              NAME: "Min",
                                              VAL: validationValue
                                            }) : Pervasives.failwith("Cannot convert to anything else than Min and Max")
                                      ),
                                    tl: Belt_List.reverse(validations)
                                  });
                      }))
              };
      }
      var model$42 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$42.archive,
                branchPointer: model$42.branchPointer,
                migrations: model$42.migrations,
                rules: model$42.rules,
                types: model$42.types,
                categories: model$42.categories,
                destinations: model$42.destinations,
                events: model$42.events,
                groupTypes: model$42.groupTypes,
                integrations: model$42.integrations,
                metrics: model$42.metrics,
                openBranches: model$42.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$42.properties, propertyId$13, (function (property) {
                        var match = TrackingPlanModel.decodeType(property.type_);
                        var validationValue = match === "Int" ? ({
                              NAME: "IntLit",
                              VAL: 0
                            }) : (
                            match === "Float" ? ({
                                  NAME: "FloatLit",
                                  VAL: 0.0
                                }) : Pervasives.failwith("Unsupported type for Min constraint")
                          );
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: Belt_List.reverse({
                                      hd: validationType === "Max" ? ({
                                            NAME: "Max",
                                            VAL: validationValue
                                          }) : (
                                          validationType === "Min" ? ({
                                                NAME: "Min",
                                                VAL: validationValue
                                              }) : Pervasives.failwith("Cannot convert to anything else than Min and Max")
                                        ),
                                      tl: Belt_List.reverse(property.validations)
                                    }),
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$42.propertyBundles,
                sources: model$42.sources
              }
            };
    }
    if (variant === "ReorderEventsInGoal") {
      var match$30 = action.VAL;
      var maybePredecessorId$1 = match$30[2];
      var eventId$8 = match$30[1];
      var goalId$3 = match$30[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateGoal(model._0, goalId$3, (function (goal) {
                        var movedItem = Belt_List.getByU(goal.events, (function (id) {
                                return id === eventId$8;
                              }));
                        var listWithoutItem = Belt_List.keepU(goal.events, (function (id) {
                                return id !== eventId$8;
                              }));
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: goal.metrics,
                                events: movedItem !== undefined ? (
                                    maybePredecessorId$1 !== undefined ? BeltListExtensions.flatMap(listWithoutItem, (function (id) {
                                              if (id === maybePredecessorId$1) {
                                                return {
                                                        hd: id,
                                                        tl: {
                                                          hd: eventId$8,
                                                          tl: /* [] */0
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        hd: id,
                                                        tl: /* [] */0
                                                      };
                                              }
                                            })) : Belt_List.concat({
                                            hd: eventId$8,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : goal.events
                              };
                      }))
              };
      }
      var model$43 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$43.archive,
                branchPointer: model$43.branchPointer,
                migrations: model$43.migrations,
                rules: model$43.rules,
                types: model$43.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.update, model$43.categories, goalId$3, (function (category) {
                        var movedItem = Belt_List.getByU(category.events, (function (id) {
                                return id === eventId$8;
                              }));
                        var listWithoutItem = Belt_List.keepU(category.events, (function (id) {
                                return id !== eventId$8;
                              }));
                        return {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                metrics: category.metrics,
                                events: movedItem !== undefined ? (
                                    maybePredecessorId$1 !== undefined ? BeltListExtensions.flatMap(listWithoutItem, (function (id) {
                                              if (id === maybePredecessorId$1) {
                                                return {
                                                        hd: id,
                                                        tl: {
                                                          hd: eventId$8,
                                                          tl: /* [] */0
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        hd: id,
                                                        tl: /* [] */0
                                                      };
                                              }
                                            })) : Belt_List.concat({
                                            hd: eventId$8,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : category.events
                              };
                      })),
                destinations: model$43.destinations,
                events: model$43.events,
                groupTypes: model$43.groupTypes,
                integrations: model$43.integrations,
                metrics: model$43.metrics,
                openBranches: model$43.openBranches,
                properties: model$43.properties,
                propertyBundles: model$43.propertyBundles,
                sources: model$43.sources
              }
            };
    }
    if (variant === "ExcludeEventFromSourceV2") {
      var match$31 = action.VAL;
      var sourceId$5 = match$31[1];
      var eventId$9 = match$31[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$9, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: Belt_List.keepU($$event.includeSources, (function (includedSource) {
                                        return includedSource.id !== sourceId$5;
                                      })),
                                includeDestinations: Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                        return includeDestination.VAL[0] !== sourceId$5;
                                      })),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$44 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$44.archive,
                branchPointer: model$44.branchPointer,
                migrations: model$44.migrations,
                rules: model$44.rules,
                types: model$44.types,
                categories: model$44.categories,
                destinations: model$44.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$44.events, eventId$9, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: Belt_List.keepU($$event.includeSources, (function (includedSource) {
                                        return includedSource.id !== sourceId$5;
                                      })),
                                includeDestinations: Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                        return includeDestination.VAL[0] !== sourceId$5;
                                      })),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$44.groupTypes,
                integrations: model$44.integrations,
                metrics: model$44.metrics,
                openBranches: model$44.openBranches,
                properties: model$44.properties,
                propertyBundles: model$44.propertyBundles,
                sources: model$44.sources
              }
            };
    }
    if (variant === "CreateGroupType") {
      var match$32 = action.VAL;
      var newGroupType_id = match$32[0];
      var newGroupType_name = match$32[1];
      var newGroupType_description = match$32[2];
      var newGroupType = {
        id: newGroupType_id,
        name: newGroupType_name,
        description: newGroupType_description
      };
      if (model.TAG === /* Old */0) {
        var model$45 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$45.types,
                  properties: model$45.properties,
                  propertyBundles: model$45.propertyBundles,
                  events: model$45.events,
                  migrations: model$45.migrations,
                  sources: model$45.sources,
                  destinations: model$45.destinations,
                  groupTypes: Belt_Array.concat(model$45.groupTypes, [newGroupType]),
                  goals: model$45.goals,
                  metrics: model$45.metrics,
                  archive: model$45.archive,
                  openBranches: model$45.openBranches,
                  branchPointer: model$45.branchPointer,
                  rules: model$45.rules,
                  integrations: model$45.integrations
                }
              };
      }
      var model$46 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$46.archive,
                branchPointer: model$46.branchPointer,
                migrations: model$46.migrations,
                rules: model$46.rules,
                types: model$46.types,
                categories: model$46.categories,
                destinations: model$46.destinations,
                events: model$46.events,
                groupTypes: Curry._2(TrackingPlanMappedModel.GroupTypes.append, model$46.groupTypes, newGroupType),
                integrations: model$46.integrations,
                metrics: model$46.metrics,
                openBranches: model$46.openBranches,
                properties: model$46.properties,
                propertyBundles: model$46.propertyBundles,
                sources: model$46.sources
              }
            };
    }
    if (variant === "LegacyToggleDestination") {
      var match$33 = action.VAL;
      var isActive$1 = match$33[2];
      var destinationId$4 = match$33[1];
      var sourceId$6 = match$33[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateSource(model._0, sourceId$6, (function (source) {
                        var hasDestination = Belt_List.someU(source.destinations, (function (sourceDestination) {
                                return sourceDestination.destinationId === destinationId$4;
                              }));
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: hasDestination ? (
                                    isActive$1 ? source.destinations : Belt_List.keepU(source.destinations, (function (sourceDestination) {
                                              return sourceDestination.destinationId !== destinationId$4;
                                            }))
                                  ) : (
                                    isActive$1 ? Belt_List.concat(source.destinations, {
                                            hd: {
                                              destinationId: destinationId$4,
                                              destinationMode: /* Legacy */2
                                            },
                                            tl: /* [] */0
                                          }) : source.destinations
                                  ),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$47 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$47.archive,
                branchPointer: model$47.branchPointer,
                migrations: model$47.migrations,
                rules: model$47.rules,
                types: model$47.types,
                categories: model$47.categories,
                destinations: model$47.destinations,
                events: model$47.events,
                groupTypes: model$47.groupTypes,
                integrations: model$47.integrations,
                metrics: model$47.metrics,
                openBranches: model$47.openBranches,
                properties: model$47.properties,
                propertyBundles: model$47.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$47.sources, sourceId$6, (function (source) {
                        var hasDestination = Belt_List.someU(source.destinations, (function (sourceDestination) {
                                return sourceDestination.destinationId === destinationId$4;
                              }));
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: hasDestination ? (
                                    isActive$1 ? source.destinations : Belt_List.keepU(source.destinations, (function (sourceDestination) {
                                              return sourceDestination.destinationId !== destinationId$4;
                                            }))
                                  ) : (
                                    isActive$1 ? Belt_List.concat(source.destinations, {
                                            hd: {
                                              destinationId: destinationId$4,
                                              destinationMode: /* Legacy */2
                                            },
                                            tl: /* [] */0
                                          }) : source.destinations
                                  ),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "DeleteSource") {
      var sourceId$7 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$48 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$48.types,
                  properties: model$48.properties,
                  propertyBundles: model$48.propertyBundles,
                  events: Belt_List.mapU(model$48.events, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: Belt_List.keepU($$event.includeSources, (function (includedSource) {
                                          return sourceId$7 !== includedSource.id;
                                        })),
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        })),
                  migrations: model$48.migrations,
                  sources: Belt_List.keepU(model$48.sources, (function (source) {
                          return source.id !== sourceId$7;
                        })),
                  destinations: model$48.destinations,
                  groupTypes: model$48.groupTypes,
                  goals: model$48.goals,
                  metrics: model$48.metrics,
                  archive: model$48.archive,
                  openBranches: model$48.openBranches,
                  branchPointer: model$48.branchPointer,
                  rules: model$48.rules,
                  integrations: model$48.integrations
                }
              };
      }
      var model$49 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$49.archive,
                branchPointer: model$49.branchPointer,
                migrations: model$49.migrations,
                rules: model$49.rules,
                types: model$49.types,
                categories: model$49.categories,
                destinations: model$49.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$49.events, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: Belt_List.keepU($$event.includeSources, (function (includedSource) {
                                        return sourceId$7 !== includedSource.id;
                                      })),
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$49.groupTypes,
                integrations: model$49.integrations,
                metrics: model$49.metrics,
                openBranches: model$49.openBranches,
                properties: model$49.properties,
                propertyBundles: model$49.propertyBundles,
                sources: Curry._2(TrackingPlanMappedModel.Sources.remove, model$49.sources, sourceId$7)
              }
            };
    }
    if (variant === "LegacyRemovePropertyRef") {
      var match$34 = action.VAL;
      var propertyId$14 = match$34[1];
      var eventId$10 = match$34[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$10, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.keepU($$event.properties, (function (item) {
                                        return item._0.id !== propertyId$14;
                                      })),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                        return param[1] !== propertyId$14;
                                      })),
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$50 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$50.archive,
                branchPointer: model$50.branchPointer,
                migrations: model$50.migrations,
                rules: model$50.rules,
                types: model$50.types,
                categories: model$50.categories,
                destinations: model$50.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$50.events, eventId$10, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.keepU($$event.properties, (function (item) {
                                        return item._0.id !== propertyId$14;
                                      })),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                        return param[1] !== propertyId$14;
                                      })),
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$50.groupTypes,
                integrations: model$50.integrations,
                metrics: model$50.metrics,
                openBranches: model$50.openBranches,
                properties: model$50.properties,
                propertyBundles: model$50.propertyBundles,
                sources: model$50.sources
              }
            };
    }
    if (variant === "RemoveMetricGroupBy") {
      var match$35 = action.VAL;
      var id$1 = match$35[2];
      var eventId$11 = match$35[1];
      var metricId$2 = match$35[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateMetric(model._0, metricId$2, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$11 === $$event.eventId) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: $$event.where,
                                                    groupBy: Belt_List.keepU($$event.groupBy, (function (groupBy) {
                                                            return groupBy.id !== id$1;
                                                          }))
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      }))
              };
      }
      var model$51 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$51.archive,
                branchPointer: model$51.branchPointer,
                migrations: model$51.migrations,
                rules: model$51.rules,
                types: model$51.types,
                categories: model$51.categories,
                destinations: model$51.destinations,
                events: model$51.events,
                groupTypes: model$51.groupTypes,
                integrations: model$51.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.update, model$51.metrics, metricId$2, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$11 === $$event.eventId) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: $$event.where,
                                                    groupBy: Belt_List.keepU($$event.groupBy, (function (groupBy) {
                                                            return groupBy.id !== id$1;
                                                          }))
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      })),
                openBranches: model$51.openBranches,
                properties: model$51.properties,
                propertyBundles: model$51.propertyBundles,
                sources: model$51.sources
              }
            };
    }
    if (variant === "UpdateProgrammingLanguage") {
      var match$36 = action.VAL;
      var language = match$36[1];
      var sourceId$8 = match$36[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateSource(model._0, sourceId$8, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$52 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$52.archive,
                branchPointer: model$52.branchPointer,
                migrations: model$52.migrations,
                rules: model$52.rules,
                types: model$52.types,
                categories: model$52.categories,
                destinations: model$52.destinations,
                events: model$52.events,
                groupTypes: model$52.groupTypes,
                integrations: model$52.integrations,
                metrics: model$52.metrics,
                openBranches: model$52.openBranches,
                properties: model$52.properties,
                propertyBundles: model$52.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$52.sources, sourceId$8, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "AddMetric") {
      var metricId$3 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$53 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$53.types,
                  properties: model$53.properties,
                  propertyBundles: model$53.propertyBundles,
                  events: model$53.events,
                  migrations: model$53.migrations,
                  sources: model$53.sources,
                  destinations: model$53.destinations,
                  groupTypes: model$53.groupTypes,
                  goals: model$53.goals,
                  metrics: Belt_List.concat(model$53.metrics, {
                        hd: TrackingPlanModel.emptyMetric(metricId$3),
                        tl: /* [] */0
                      }),
                  archive: model$53.archive,
                  openBranches: model$53.openBranches,
                  branchPointer: model$53.branchPointer,
                  rules: model$53.rules,
                  integrations: model$53.integrations
                }
              };
      }
      var model$54 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$54.archive,
                branchPointer: model$54.branchPointer,
                migrations: model$54.migrations,
                rules: model$54.rules,
                types: model$54.types,
                categories: model$54.categories,
                destinations: model$54.destinations,
                events: model$54.events,
                groupTypes: model$54.groupTypes,
                integrations: model$54.integrations,
                metrics: Curry._2(TrackingPlanMappedModel.Metrics.append, model$54.metrics, TrackingPlanModel.emptyMetric(metricId$3)),
                openBranches: model$54.openBranches,
                properties: model$54.properties,
                propertyBundles: model$54.propertyBundles,
                sources: model$54.sources
              }
            };
    }
    if (variant === "UpdateTriggerSources") {
      var match$37 = action.VAL;
      var sources$1 = match$37[2];
      var triggerId$1 = match$37[1];
      var eventId$12 = match$37[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEventTrigger(model._0, eventId$12, triggerId$1, (function (trigger) {
                        return {
                                id: trigger.id,
                                sources: sources$1,
                                description: trigger.description,
                                content: trigger.content
                              };
                      }))
              };
      }
      var model$55 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$55.archive,
                branchPointer: model$55.branchPointer,
                migrations: model$55.migrations,
                rules: model$55.rules,
                types: model$55.types,
                categories: model$55.categories,
                destinations: model$55.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$55.events, eventId$12, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.mapU($$event.triggers, (function (trigger) {
                                        if (trigger.id === triggerId$1) {
                                          return {
                                                  id: trigger.id,
                                                  sources: sources$1,
                                                  description: trigger.description,
                                                  content: trigger.content
                                                };
                                        } else {
                                          return trigger;
                                        }
                                      }))
                              };
                      })),
                groupTypes: model$55.groupTypes,
                integrations: model$55.integrations,
                metrics: model$55.metrics,
                openBranches: model$55.openBranches,
                properties: model$55.properties,
                propertyBundles: model$55.propertyBundles,
                sources: model$55.sources
              }
            };
    }
    if (variant === "UpdateSourcePlatformV2") {
      var match$38 = action.VAL;
      var platform = match$38[1];
      var sourceId$9 = match$38[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateSource(model._0, sourceId$9, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: undefined,
                                platform: platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$56 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$56.archive,
                branchPointer: model$56.branchPointer,
                migrations: model$56.migrations,
                rules: model$56.rules,
                types: model$56.types,
                categories: model$56.categories,
                destinations: model$56.destinations,
                events: model$56.events,
                groupTypes: model$56.groupTypes,
                integrations: model$56.integrations,
                metrics: model$56.metrics,
                openBranches: model$56.openBranches,
                properties: model$56.properties,
                propertyBundles: model$56.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$56.sources, sourceId$9, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: undefined,
                                platform: platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "UpdateSourcePlatformV3") {
      var match$39 = action.VAL;
      var maybePlatform = match$39[1];
      var sourceId$10 = match$39[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateSource(model._0, sourceId$10, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: undefined,
                                platform: maybePlatform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$57 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$57.archive,
                branchPointer: model$57.branchPointer,
                migrations: model$57.migrations,
                rules: model$57.rules,
                types: model$57.types,
                categories: model$57.categories,
                destinations: model$57.destinations,
                events: model$57.events,
                groupTypes: model$57.groupTypes,
                integrations: model$57.integrations,
                metrics: model$57.metrics,
                openBranches: model$57.openBranches,
                properties: model$57.properties,
                propertyBundles: model$57.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$57.sources, sourceId$10, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: undefined,
                                platform: maybePlatform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "UpdateEventUniqueNameV2") {
      var match$40 = action.VAL;
      var name$4 = match$40[1];
      var eventId$13 = match$40[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$13, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: name$4 === "" ? undefined : name$4,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$58 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$58.archive,
                branchPointer: model$58.branchPointer,
                migrations: model$58.migrations,
                rules: model$58.rules,
                types: model$58.types,
                categories: model$58.categories,
                destinations: model$58.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$58.events, eventId$13, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: name$4 === "" ? undefined : name$4,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$58.groupTypes,
                integrations: model$58.integrations,
                metrics: model$58.metrics,
                openBranches: model$58.openBranches,
                properties: model$58.properties,
                propertyBundles: model$58.propertyBundles,
                sources: model$58.sources
              }
            };
    }
    if (variant === "CreateEventTrigger") {
      var match$41 = action.VAL;
      var content$1 = match$41[4];
      var description$2 = match$41[3];
      var sources$2 = match$41[2];
      var id$2 = match$41[1];
      var eventId$14 = match$41[0];
      if (model.TAG === /* Old */0) {
        var newTrigger = {
          id: id$2,
          sources: sources$2,
          description: description$2,
          content: content$1
        };
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$14, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.concat($$event.triggers, [newTrigger])
                              };
                      }))
              };
      }
      var model$59 = model._0;
      var newTrigger$1 = {
        id: id$2,
        sources: sources$2,
        description: description$2,
        content: content$1
      };
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$59.archive,
                branchPointer: model$59.branchPointer,
                migrations: model$59.migrations,
                rules: model$59.rules,
                types: model$59.types,
                categories: model$59.categories,
                destinations: model$59.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$59.events, eventId$14, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.concat($$event.triggers, [newTrigger$1])
                              };
                      })),
                groupTypes: model$59.groupTypes,
                integrations: model$59.integrations,
                metrics: model$59.metrics,
                openBranches: model$59.openBranches,
                properties: model$59.properties,
                propertyBundles: model$59.propertyBundles,
                sources: model$59.sources
              }
            };
    }
    if (variant === "UpdateGroupTypeName") {
      var match$42 = action.VAL;
      var groupTypeName = match$42[1];
      var groupTypeId = match$42[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateGroupType(model._0, groupTypeId, (function (groupType) {
                        return {
                                id: groupType.id,
                                name: groupTypeName,
                                description: groupType.description
                              };
                      }))
              };
      }
      var model$60 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$60.archive,
                branchPointer: model$60.branchPointer,
                migrations: model$60.migrations,
                rules: model$60.rules,
                types: model$60.types,
                categories: model$60.categories,
                destinations: model$60.destinations,
                events: model$60.events,
                groupTypes: Curry._3(TrackingPlanMappedModel.GroupTypes.update, model$60.groupTypes, groupTypeId, (function (groupType) {
                        return {
                                id: groupType.id,
                                name: groupTypeName,
                                description: groupType.description
                              };
                      })),
                integrations: model$60.integrations,
                metrics: model$60.metrics,
                openBranches: model$60.openBranches,
                properties: model$60.properties,
                propertyBundles: model$60.propertyBundles,
                sources: model$60.sources
              }
            };
    }
    if (variant === "RemovePropertyValidationMatch") {
      var match$43 = action.VAL;
      var match_$1 = match$43[1];
      var propertyId$15 = match$43[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateProperty(model._0, propertyId$15, (function (property) {
                        var propertyMatches = StateUtils.getMatchesFromProperty(property);
                        var nextMatchList = Belt_List.keepU(propertyMatches, (function (matchItem) {
                                var match = matchItem[0];
                                if (typeof match !== "object") {
                                  return true;
                                }
                                if (match.NAME !== "Literal") {
                                  return true;
                                }
                                var match$1 = match.VAL;
                                if (typeof match$1 === "object" && match$1.NAME === "StringLit") {
                                  return match$1.VAL !== match_$1;
                                } else {
                                  return true;
                                }
                              }));
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: Belt_List.length(nextMatchList) === 0 ? /* [] */0 : ({
                                      hd: {
                                        NAME: "Matches",
                                        VAL: nextMatchList
                                      },
                                      tl: /* [] */0
                                    }),
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      }))
              };
      }
      var model$61 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$61.archive,
                branchPointer: model$61.branchPointer,
                migrations: model$61.migrations,
                rules: model$61.rules,
                types: model$61.types,
                categories: model$61.categories,
                destinations: model$61.destinations,
                events: model$61.events,
                groupTypes: model$61.groupTypes,
                integrations: model$61.integrations,
                metrics: model$61.metrics,
                openBranches: model$61.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$61.properties, propertyId$15, (function (property) {
                        var propertyMatches = StateUtils.getMatchesFromProperty(property);
                        var nextMatchList = Belt_List.keepU(propertyMatches, (function (matchItem) {
                                var match = matchItem[0];
                                if (typeof match !== "object") {
                                  return true;
                                }
                                if (match.NAME !== "Literal") {
                                  return true;
                                }
                                var match$1 = match.VAL;
                                if (typeof match$1 === "object" && match$1.NAME === "StringLit") {
                                  return match$1.VAL !== match_$1;
                                } else {
                                  return true;
                                }
                              }));
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: Belt_List.length(nextMatchList) === 0 ? /* [] */0 : ({
                                      hd: {
                                        NAME: "Matches",
                                        VAL: nextMatchList
                                      },
                                      tl: /* [] */0
                                    }),
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$61.propertyBundles,
                sources: model$61.sources
              }
            };
    }
    if (variant === "RemoveDestinationApiKey") {
      var match$44 = action.VAL;
      var envName = match$44[1];
      var destinationId$5 = match$44[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateDestination(model._0, destinationId$5, (function (destination) {
                        var init = destination.apiKeys;
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: init.dev,
                                  prod: init.prod,
                                  other: Belt_List.keepU(destination.apiKeys.other, (function (param) {
                                          return param[0] !== envName;
                                        }))
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      }))
              };
      }
      var model$62 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$62.archive,
                branchPointer: model$62.branchPointer,
                migrations: model$62.migrations,
                rules: model$62.rules,
                types: model$62.types,
                categories: model$62.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.update, model$62.destinations, destinationId$5, (function (destination) {
                        var init = destination.apiKeys;
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: init.dev,
                                  prod: init.prod,
                                  other: Belt_List.keepU(destination.apiKeys.other, (function (param) {
                                          return param[0] !== envName;
                                        }))
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      })),
                events: model$62.events,
                groupTypes: model$62.groupTypes,
                integrations: model$62.integrations,
                metrics: model$62.metrics,
                openBranches: model$62.openBranches,
                properties: model$62.properties,
                propertyBundles: model$62.propertyBundles,
                sources: model$62.sources
              }
            };
    }
    if (variant === "Archive") {
      var item$1 = action.VAL;
      var variant$1 = item$1.NAME;
      if (variant$1 === "Destination") {
        var destinationId$6 = item$1.VAL;
        if (model.TAG === /* Old */0) {
          var model$63 = model._0;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$63.types,
                    properties: model$63.properties,
                    propertyBundles: model$63.propertyBundles,
                    events: model$63.events,
                    migrations: model$63.migrations,
                    sources: model$63.sources,
                    destinations: Belt_List.keepU(model$63.destinations, (function (destination) {
                            return destination.id !== destinationId$6;
                          })),
                    groupTypes: model$63.groupTypes,
                    goals: model$63.goals,
                    metrics: model$63.metrics,
                    archive: Belt_Option.mapWithDefault(Belt_List.getByU(model$63.destinations, (function (destination) {
                                return destination.id === destinationId$6;
                              })), model$63.archive, (function (destination) {
                            var init = model$63.archive;
                            return {
                                    events: init.events,
                                    properties: init.properties,
                                    propertyBundles: init.propertyBundles,
                                    sources: init.sources,
                                    destinations: Belt_List.concat(model$63.archive.destinations, {
                                          hd: destination,
                                          tl: /* [] */0
                                        }),
                                    goals: init.goals,
                                    metrics: init.metrics,
                                    groupTypes: init.groupTypes
                                  };
                          })),
                    openBranches: model$63.openBranches,
                    branchPointer: model$63.branchPointer,
                    rules: model$63.rules,
                    integrations: model$63.integrations
                  }
                };
        }
        var model$64 = model._0;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Destinations.get, model$64.destinations, destinationId$6), model$64.archive, (function (destination) {
                          var init = model$64.archive;
                          return {
                                  categories: init.categories,
                                  destinations: Curry._2(TrackingPlanMappedModel.Destinations.append, model$64.archive.destinations, destination),
                                  events: init.events,
                                  groupTypes: init.groupTypes,
                                  metrics: init.metrics,
                                  properties: init.properties,
                                  propertyBundles: init.propertyBundles,
                                  sources: init.sources
                                };
                        })),
                  branchPointer: model$64.branchPointer,
                  migrations: model$64.migrations,
                  rules: model$64.rules,
                  types: model$64.types,
                  categories: model$64.categories,
                  destinations: Curry._2(TrackingPlanMappedModel.Destinations.remove, model$64.destinations, destinationId$6),
                  events: model$64.events,
                  groupTypes: model$64.groupTypes,
                  integrations: model$64.integrations,
                  metrics: model$64.metrics,
                  openBranches: model$64.openBranches,
                  properties: model$64.properties,
                  propertyBundles: model$64.propertyBundles,
                  sources: model$64.sources
                }
              };
      }
      if (variant$1 === "Integration") {
        return model;
      }
      if (variant$1 === "GroupType") {
        var groupTypeId$1 = item$1.VAL;
        if (model.TAG === /* Old */0) {
          var model$65 = model._0;
          var groupTypeProperties = Belt_List.keepU(model$65.properties, (function (modelProperty) {
                  var match = Belt_Option.mapU(StateUtils.resolveProperty(model$65, modelProperty), (function (prop) {
                          return prop.sendAs;
                        }));
                  if (match !== undefined && typeof match !== "number") {
                    return match._0 === groupTypeId$1;
                  } else {
                    return false;
                  }
                }));
          var model$66 = TrackingPlanMappedModel.unboxModel(Belt_List.reduceU(groupTypeProperties, model, (function (nextModel, property) {
                      return reduceFunction(nextModel, {
                                  NAME: "Archive",
                                  VAL: {
                                    NAME: "Property",
                                    VAL: StateUtils.getPropertyId(property)
                                  }
                                });
                    })));
          var init = model$66.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$66.types,
                    properties: model$66.properties,
                    propertyBundles: model$66.propertyBundles,
                    events: model$66.events,
                    migrations: model$66.migrations,
                    sources: model$66.sources,
                    destinations: model$66.destinations,
                    groupTypes: Belt_Array.keepU(model$66.groupTypes, (function (groupType) {
                            return groupType.id !== groupTypeId$1;
                          })),
                    goals: model$66.goals,
                    metrics: model$66.metrics,
                    archive: {
                      events: init.events,
                      properties: init.properties,
                      propertyBundles: init.propertyBundles,
                      sources: init.sources,
                      destinations: init.destinations,
                      goals: init.goals,
                      metrics: init.metrics,
                      groupTypes: Belt_Option.mapWithDefault(Belt_Array.getByU(model$66.groupTypes, (function (groupType) {
                                  return groupType.id === groupTypeId$1;
                                })), model$66.archive.groupTypes, (function (groupType) {
                              return Belt_Array.concat(model$66.archive.groupTypes, [groupType]);
                            }))
                    },
                    openBranches: model$66.openBranches,
                    branchPointer: model$66.branchPointer,
                    rules: model$66.rules,
                    integrations: model$66.integrations
                  }
                };
        }
        var groupTypeProperties$1 = Curry._2(TrackingPlanMappedModel.Properties.keepMap, model._0.properties, (function (property) {
                var inGroupTypeId = property.sendAs;
                if (typeof inGroupTypeId === "number" || inGroupTypeId._0 !== groupTypeId$1) {
                  return ;
                } else {
                  return property;
                }
              }));
        var model$67 = TrackingPlanMappedModel.unboxModel(Belt_Array.reduceU(groupTypeProperties$1, model, (function (nextModel, property) {
                    return reduceFunction(nextModel, {
                                NAME: "Archive",
                                VAL: {
                                  NAME: "Property",
                                  VAL: property.id
                                }
                              });
                  })));
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.GroupTypes.get, model$67.groupTypes, groupTypeId$1), model$67.archive, (function (groupType) {
                          var init = model$67.archive;
                          return {
                                  categories: init.categories,
                                  destinations: init.destinations,
                                  events: init.events,
                                  groupTypes: Curry._2(TrackingPlanMappedModel.GroupTypes.append, model$67.archive.groupTypes, groupType),
                                  metrics: init.metrics,
                                  properties: init.properties,
                                  propertyBundles: init.propertyBundles,
                                  sources: init.sources
                                };
                        })),
                  branchPointer: model$67.branchPointer,
                  migrations: model$67.migrations,
                  rules: model$67.rules,
                  types: model$67.types,
                  categories: model$67.categories,
                  destinations: model$67.destinations,
                  events: model$67.events,
                  groupTypes: Curry._2(TrackingPlanMappedModel.GroupTypes.remove, model$67.groupTypes, groupTypeId$1),
                  integrations: model$67.integrations,
                  metrics: model$67.metrics,
                  openBranches: model$67.openBranches,
                  properties: model$67.properties,
                  propertyBundles: model$67.propertyBundles,
                  sources: model$67.sources
                }
              };
      }
      if (variant$1 === "Property") {
        var propertyId$16 = item$1.VAL;
        if (model.TAG === /* Old */0) {
          var model$68 = model._0;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$68.types,
                    properties: Belt_List.keepMapU(model$68.properties, (function (property) {
                            if (property.TAG === /* PropertyRef */0) {
                              return property;
                            }
                            var p = property._0;
                            if (p.id === propertyId$16) {
                              return ;
                            }
                            var newP_id = p.id;
                            var newP_name = p.name;
                            var newP_uniqueName = p.uniqueName;
                            var newP_description = p.description;
                            var newP_type_ = p.type_;
                            var newP_sendAs = p.sendAs;
                            var newP_validations = Belt_List.map(p.validations, (function (x) {
                                    if (typeof x === "object" && x.NAME === "NestedProperty") {
                                      return {
                                              NAME: "NestedProperty",
                                              VAL: Belt_List.keep(x.VAL, (function (propertyRef) {
                                                      return propertyRef.id !== propertyId$16;
                                                    }))
                                            };
                                    } else {
                                      return x;
                                    }
                                  }));
                            var newP_auto = p.auto;
                            var newP_builtIn = p.builtIn;
                            var newP_analyticsTools = p.analyticsTools;
                            var newP_devPlatforms = p.devPlatforms;
                            var newP_list = p.list;
                            var newP_operation = p.operation;
                            var newP_optionalDeprecated = p.optionalDeprecated;
                            var newP_excludedSourcesDeprecated = p.excludedSourcesDeprecated;
                            var newP_optionalWhenInObject = p.optionalWhenInObject;
                            var newP_absence = p.absence;
                            var newP = {
                              id: newP_id,
                              name: newP_name,
                              uniqueName: newP_uniqueName,
                              description: newP_description,
                              type_: newP_type_,
                              sendAs: newP_sendAs,
                              validations: newP_validations,
                              auto: newP_auto,
                              builtIn: newP_builtIn,
                              analyticsTools: newP_analyticsTools,
                              devPlatforms: newP_devPlatforms,
                              list: newP_list,
                              operation: newP_operation,
                              optionalDeprecated: newP_optionalDeprecated,
                              excludedSourcesDeprecated: newP_excludedSourcesDeprecated,
                              optionalWhenInObject: newP_optionalWhenInObject,
                              absence: newP_absence
                            };
                            return {
                                    TAG: /* Property */1,
                                    _0: newP
                                  };
                          })),
                    propertyBundles: Belt_List.mapU(model$68.propertyBundles, (function (group) {
                            return {
                                    id: group.id,
                                    name: group.name,
                                    description: group.description,
                                    properties: Belt_List.keepU(group.properties, (function (id) {
                                            return id !== propertyId$16;
                                          }))
                                  };
                          })),
                    events: Belt_List.mapU(model$68.events, (function ($$event) {
                            return {
                                    id: $$event.id,
                                    name: $$event.name,
                                    uniqueName: $$event.uniqueName,
                                    description: $$event.description,
                                    properties: Belt_List.keepU($$event.properties, (function (property) {
                                            if (property.TAG === /* PropertyRef */0) {
                                              return property._0.id !== propertyId$16;
                                            } else {
                                              return true;
                                            }
                                          })),
                                    propertyBundles: $$event.propertyBundles,
                                    variants: $$event.variants,
                                    types: $$event.types,
                                    tags: $$event.tags,
                                    excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                    includeSources: $$event.includeSources,
                                    includeDestinations: $$event.includeDestinations,
                                    hashes: $$event.hashes,
                                    propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                            return param[1] !== propertyId$16;
                                          })),
                                    eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                    userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                    triggers: $$event.triggers
                                  };
                          })),
                    migrations: model$68.migrations,
                    sources: model$68.sources,
                    destinations: model$68.destinations,
                    groupTypes: model$68.groupTypes,
                    goals: model$68.goals,
                    metrics: model$68.metrics,
                    archive: Belt_Option.mapWithDefault(Belt_List.getByU(model$68.properties, (function (property) {
                                if (property.TAG === /* PropertyRef */0) {
                                  return false;
                                } else {
                                  return property._0.id === propertyId$16;
                                }
                              })), model$68.archive, (function (property) {
                            var init = model$68.archive;
                            return {
                                    events: init.events,
                                    properties: Belt_List.concat(model$68.archive.properties, {
                                          hd: property,
                                          tl: /* [] */0
                                        }),
                                    propertyBundles: init.propertyBundles,
                                    sources: init.sources,
                                    destinations: init.destinations,
                                    goals: init.goals,
                                    metrics: init.metrics,
                                    groupTypes: init.groupTypes
                                  };
                          })),
                    openBranches: model$68.openBranches,
                    branchPointer: model$68.branchPointer,
                    rules: model$68.rules,
                    integrations: model$68.integrations
                  }
                };
        }
        var model$69 = model._0;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Properties.get, model$69.properties, propertyId$16), model$69.archive, (function (property) {
                          var init = model$69.archive;
                          return {
                                  categories: init.categories,
                                  destinations: init.destinations,
                                  events: init.events,
                                  groupTypes: init.groupTypes,
                                  metrics: init.metrics,
                                  properties: Curry._2(TrackingPlanMappedModel.Properties.append, model$69.archive.properties, property),
                                  propertyBundles: init.propertyBundles,
                                  sources: init.sources
                                };
                        })),
                  branchPointer: model$69.branchPointer,
                  migrations: model$69.migrations,
                  rules: model$69.rules,
                  types: model$69.types,
                  categories: model$69.categories,
                  destinations: model$69.destinations,
                  events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$69.events, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: Belt_List.keepU($$event.properties, (function (property) {
                                          if (property.TAG === /* PropertyRef */0) {
                                            return property._0.id !== propertyId$16;
                                          } else {
                                            return true;
                                          }
                                        })),
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                          return param[1] !== propertyId$16;
                                        })),
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        })),
                  groupTypes: model$69.groupTypes,
                  integrations: model$69.integrations,
                  metrics: model$69.metrics,
                  openBranches: model$69.openBranches,
                  properties: Curry._2(TrackingPlanMappedModel.Properties.mapToT, Curry._2(TrackingPlanMappedModel.Properties.remove, model$69.properties, propertyId$16), (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: Belt_List.map(property.validations, (function (x) {
                                          if (typeof x === "object" && x.NAME === "NestedProperty") {
                                            return {
                                                    NAME: "NestedProperty",
                                                    VAL: Belt_List.keep(x.VAL, (function (propertyRef) {
                                                            return propertyRef.id !== propertyId$16;
                                                          }))
                                                  };
                                          } else {
                                            return x;
                                          }
                                        })),
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: property.optionalDeprecated,
                                  excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                  optionalWhenInObject: property.optionalWhenInObject,
                                  absence: property.absence
                                };
                        })),
                  propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.mapToT, model$69.propertyBundles, (function (propertyBundle) {
                          return {
                                  id: propertyBundle.id,
                                  name: propertyBundle.name,
                                  description: propertyBundle.description,
                                  properties: Belt_List.keepU(propertyBundle.properties, (function (id) {
                                          return id !== propertyId$16;
                                        }))
                                };
                        })),
                  sources: model$69.sources
                }
              };
      }
      if (variant$1 === "Event") {
        var eventId$15 = item$1.VAL;
        if (model.TAG === /* Old */0) {
          var model$70 = model._0;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$70.types,
                    properties: model$70.properties,
                    propertyBundles: model$70.propertyBundles,
                    events: Belt_List.keepU(model$70.events, (function ($$event) {
                            return $$event.id !== eventId$15;
                          })),
                    migrations: model$70.migrations,
                    sources: model$70.sources,
                    destinations: model$70.destinations,
                    groupTypes: model$70.groupTypes,
                    goals: model$70.goals,
                    metrics: model$70.metrics,
                    archive: Belt_Option.mapWithDefault(Belt_List.getByU(model$70.events, (function ($$event) {
                                return $$event.id === eventId$15;
                              })), model$70.archive, (function ($$event) {
                            var init = model$70.archive;
                            return {
                                    events: Belt_List.concat(model$70.archive.events, {
                                          hd: $$event,
                                          tl: /* [] */0
                                        }),
                                    properties: init.properties,
                                    propertyBundles: init.propertyBundles,
                                    sources: init.sources,
                                    destinations: init.destinations,
                                    goals: init.goals,
                                    metrics: init.metrics,
                                    groupTypes: init.groupTypes
                                  };
                          })),
                    openBranches: model$70.openBranches,
                    branchPointer: model$70.branchPointer,
                    rules: model$70.rules,
                    integrations: model$70.integrations
                  }
                };
        }
        var model$71 = model._0;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Events.get, model$71.events, eventId$15), model$71.archive, (function ($$event) {
                          var init = model$71.archive;
                          return {
                                  categories: init.categories,
                                  destinations: init.destinations,
                                  events: Curry._2(TrackingPlanMappedModel.Events.append, model$71.archive.events, $$event),
                                  groupTypes: init.groupTypes,
                                  metrics: init.metrics,
                                  properties: init.properties,
                                  propertyBundles: init.propertyBundles,
                                  sources: init.sources
                                };
                        })),
                  branchPointer: model$71.branchPointer,
                  migrations: model$71.migrations,
                  rules: model$71.rules,
                  types: model$71.types,
                  categories: model$71.categories,
                  destinations: model$71.destinations,
                  events: Curry._2(TrackingPlanMappedModel.Events.remove, model$71.events, eventId$15),
                  groupTypes: model$71.groupTypes,
                  integrations: model$71.integrations,
                  metrics: model$71.metrics,
                  openBranches: model$71.openBranches,
                  properties: model$71.properties,
                  propertyBundles: model$71.propertyBundles,
                  sources: model$71.sources
                }
              };
      }
      if (variant$1 === "PropertyGroup") {
        var propertyBundleId = item$1.VAL;
        if (model.TAG === /* Old */0) {
          var model$72 = model._0;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$72.types,
                    properties: model$72.properties,
                    propertyBundles: Belt_List.keepU(model$72.propertyBundles, (function (group) {
                            return group.id !== propertyBundleId;
                          })),
                    events: Belt_List.mapU(model$72.events, (function ($$event) {
                            return {
                                    id: $$event.id,
                                    name: $$event.name,
                                    uniqueName: $$event.uniqueName,
                                    description: $$event.description,
                                    properties: $$event.properties,
                                    propertyBundles: Belt_List.keepU($$event.propertyBundles, (function (group) {
                                            return group.id !== propertyBundleId;
                                          })),
                                    variants: $$event.variants,
                                    types: $$event.types,
                                    tags: $$event.tags,
                                    excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                    includeSources: $$event.includeSources,
                                    includeDestinations: $$event.includeDestinations,
                                    hashes: $$event.hashes,
                                    propertyWhitelist: $$event.propertyWhitelist,
                                    eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                    userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                    triggers: $$event.triggers
                                  };
                          })),
                    migrations: model$72.migrations,
                    sources: model$72.sources,
                    destinations: model$72.destinations,
                    groupTypes: model$72.groupTypes,
                    goals: model$72.goals,
                    metrics: model$72.metrics,
                    archive: Belt_Option.mapWithDefault(Belt_List.getByU(model$72.propertyBundles, (function (group) {
                                return group.id === propertyBundleId;
                              })), model$72.archive, (function (group) {
                            var init = model$72.archive;
                            return {
                                    events: init.events,
                                    properties: init.properties,
                                    propertyBundles: Belt_List.concat(model$72.archive.propertyBundles, {
                                          hd: group,
                                          tl: /* [] */0
                                        }),
                                    sources: init.sources,
                                    destinations: init.destinations,
                                    goals: init.goals,
                                    metrics: init.metrics,
                                    groupTypes: init.groupTypes
                                  };
                          })),
                    openBranches: model$72.openBranches,
                    branchPointer: model$72.branchPointer,
                    rules: model$72.rules,
                    integrations: model$72.integrations
                  }
                };
        }
        var model$73 = model._0;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.PropertyBundles.get, model$73.propertyBundles, propertyBundleId), model$73.archive, (function (propertyBundle) {
                          var init = model$73.archive;
                          return {
                                  categories: init.categories,
                                  destinations: init.destinations,
                                  events: init.events,
                                  groupTypes: init.groupTypes,
                                  metrics: init.metrics,
                                  properties: init.properties,
                                  propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.append, model$73.archive.propertyBundles, propertyBundle),
                                  sources: init.sources
                                };
                        })),
                  branchPointer: model$73.branchPointer,
                  migrations: model$73.migrations,
                  rules: model$73.rules,
                  types: model$73.types,
                  categories: model$73.categories,
                  destinations: model$73.destinations,
                  events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$73.events, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: Belt_List.keepU($$event.propertyBundles, (function (propertyBundle) {
                                          return propertyBundle.id !== propertyBundleId;
                                        })),
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        })),
                  groupTypes: model$73.groupTypes,
                  integrations: model$73.integrations,
                  metrics: model$73.metrics,
                  openBranches: model$73.openBranches,
                  properties: model$73.properties,
                  propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.remove, model$73.propertyBundles, propertyBundleId),
                  sources: model$73.sources
                }
              };
      }
      if (variant$1 === "Goal") {
        var categoryId = item$1.VAL;
        if (model.TAG === /* Old */0) {
          var model$74 = model._0;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$74.types,
                    properties: model$74.properties,
                    propertyBundles: model$74.propertyBundles,
                    events: model$74.events,
                    migrations: model$74.migrations,
                    sources: model$74.sources,
                    destinations: model$74.destinations,
                    groupTypes: model$74.groupTypes,
                    goals: Belt_List.keepU(model$74.goals, (function (goal) {
                            return goal.id !== categoryId;
                          })),
                    metrics: model$74.metrics,
                    archive: Belt_Option.mapWithDefault(Belt_List.getByU(model$74.goals, (function (goal) {
                                return goal.id === categoryId;
                              })), model$74.archive, (function (goal) {
                            var init = model$74.archive;
                            return {
                                    events: init.events,
                                    properties: init.properties,
                                    propertyBundles: init.propertyBundles,
                                    sources: init.sources,
                                    destinations: init.destinations,
                                    goals: Belt_List.concat(model$74.archive.goals, {
                                          hd: goal,
                                          tl: /* [] */0
                                        }),
                                    metrics: init.metrics,
                                    groupTypes: init.groupTypes
                                  };
                          })),
                    openBranches: model$74.openBranches,
                    branchPointer: model$74.branchPointer,
                    rules: model$74.rules,
                    integrations: model$74.integrations
                  }
                };
        }
        var model$75 = model._0;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Categories.get, model$75.categories, categoryId), model$75.archive, (function (category) {
                          var init = model$75.archive;
                          return {
                                  categories: Curry._2(TrackingPlanMappedModel.Categories.append, model$75.archive.categories, category),
                                  destinations: init.destinations,
                                  events: init.events,
                                  groupTypes: init.groupTypes,
                                  metrics: init.metrics,
                                  properties: init.properties,
                                  propertyBundles: init.propertyBundles,
                                  sources: init.sources
                                };
                        })),
                  branchPointer: model$75.branchPointer,
                  migrations: model$75.migrations,
                  rules: model$75.rules,
                  types: model$75.types,
                  categories: Curry._2(TrackingPlanMappedModel.Categories.remove, model$75.categories, categoryId),
                  destinations: model$75.destinations,
                  events: model$75.events,
                  groupTypes: model$75.groupTypes,
                  integrations: model$75.integrations,
                  metrics: model$75.metrics,
                  openBranches: model$75.openBranches,
                  properties: model$75.properties,
                  propertyBundles: model$75.propertyBundles,
                  sources: model$75.sources
                }
              };
      }
      if (variant$1 === "Metric") {
        var metricId$4 = item$1.VAL;
        if (model.TAG === /* Old */0) {
          var model$76 = model._0;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$76.types,
                    properties: model$76.properties,
                    propertyBundles: model$76.propertyBundles,
                    events: model$76.events,
                    migrations: model$76.migrations,
                    sources: model$76.sources,
                    destinations: model$76.destinations,
                    groupTypes: model$76.groupTypes,
                    goals: Belt_List.mapU(model$76.goals, (function (goal) {
                            return {
                                    id: goal.id,
                                    name: goal.name,
                                    description: goal.description,
                                    metrics: Belt_List.keepU(goal.metrics, (function (id) {
                                            return id !== metricId$4;
                                          })),
                                    events: goal.events
                                  };
                          })),
                    metrics: Belt_List.keepU(model$76.metrics, (function (metric) {
                            return metric.id !== metricId$4;
                          })),
                    archive: Belt_Option.mapWithDefault(Belt_List.getByU(model$76.metrics, (function (metric) {
                                return metric.id === metricId$4;
                              })), model$76.archive, (function (metric) {
                            var init = model$76.archive;
                            return {
                                    events: init.events,
                                    properties: init.properties,
                                    propertyBundles: init.propertyBundles,
                                    sources: init.sources,
                                    destinations: init.destinations,
                                    goals: init.goals,
                                    metrics: Belt_List.concat(model$76.archive.metrics, {
                                          hd: metric,
                                          tl: /* [] */0
                                        }),
                                    groupTypes: init.groupTypes
                                  };
                          })),
                    openBranches: model$76.openBranches,
                    branchPointer: model$76.branchPointer,
                    rules: model$76.rules,
                    integrations: model$76.integrations
                  }
                };
        }
        var model$77 = model._0;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Metrics.get, model$77.metrics, metricId$4), model$77.archive, (function (metric) {
                          var init = model$77.archive;
                          return {
                                  categories: init.categories,
                                  destinations: init.destinations,
                                  events: init.events,
                                  groupTypes: init.groupTypes,
                                  metrics: Curry._2(TrackingPlanMappedModel.Metrics.append, model$77.archive.metrics, metric),
                                  properties: init.properties,
                                  propertyBundles: init.propertyBundles,
                                  sources: init.sources
                                };
                        })),
                  branchPointer: model$77.branchPointer,
                  migrations: model$77.migrations,
                  rules: model$77.rules,
                  types: model$77.types,
                  categories: Curry._2(TrackingPlanMappedModel.Categories.mapToT, model$77.categories, (function (category) {
                          return {
                                  id: category.id,
                                  name: category.name,
                                  description: category.description,
                                  metrics: Belt_List.keepU(category.metrics, (function (id) {
                                          return id !== metricId$4;
                                        })),
                                  events: category.events
                                };
                        })),
                  destinations: model$77.destinations,
                  events: model$77.events,
                  groupTypes: model$77.groupTypes,
                  integrations: model$77.integrations,
                  metrics: Curry._2(TrackingPlanMappedModel.Metrics.remove, model$77.metrics, metricId$4),
                  openBranches: model$77.openBranches,
                  properties: model$77.properties,
                  propertyBundles: model$77.propertyBundles,
                  sources: model$77.sources
                }
              };
      }
      var sourceId$11 = item$1.VAL;
      if (model.TAG === /* Old */0) {
        var model$78 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$78.types,
                  properties: model$78.properties,
                  propertyBundles: model$78.propertyBundles,
                  events: model$78.events,
                  migrations: model$78.migrations,
                  sources: Belt_List.keepU(model$78.sources, (function (source) {
                          return source.id !== sourceId$11;
                        })),
                  destinations: model$78.destinations,
                  groupTypes: model$78.groupTypes,
                  goals: model$78.goals,
                  metrics: model$78.metrics,
                  archive: Belt_Option.mapWithDefault(Belt_List.getByU(model$78.sources, (function (source) {
                              return source.id === sourceId$11;
                            })), model$78.archive, (function (source) {
                          var init = model$78.archive;
                          return {
                                  events: init.events,
                                  properties: init.properties,
                                  propertyBundles: init.propertyBundles,
                                  sources: Belt_List.concat(model$78.archive.sources, {
                                        hd: source,
                                        tl: /* [] */0
                                      }),
                                  destinations: init.destinations,
                                  goals: init.goals,
                                  metrics: init.metrics,
                                  groupTypes: init.groupTypes
                                };
                        })),
                  openBranches: model$78.openBranches,
                  branchPointer: model$78.branchPointer,
                  rules: model$78.rules,
                  integrations: model$78.integrations
                }
              };
      }
      var model$79 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Sources.get, model$79.sources, sourceId$11), model$79.archive, (function (source) {
                        var init = model$79.archive;
                        return {
                                categories: init.categories,
                                destinations: init.destinations,
                                events: init.events,
                                groupTypes: init.groupTypes,
                                metrics: init.metrics,
                                properties: init.properties,
                                propertyBundles: init.propertyBundles,
                                sources: Curry._2(TrackingPlanMappedModel.Sources.append, model$79.archive.sources, source)
                              };
                      })),
                branchPointer: model$79.branchPointer,
                migrations: model$79.migrations,
                rules: model$79.rules,
                types: model$79.types,
                categories: model$79.categories,
                destinations: model$79.destinations,
                events: model$79.events,
                groupTypes: model$79.groupTypes,
                integrations: model$79.integrations,
                metrics: model$79.metrics,
                openBranches: model$79.openBranches,
                properties: model$79.properties,
                propertyBundles: model$79.propertyBundles,
                sources: Curry._2(TrackingPlanMappedModel.Sources.remove, model$79.sources, sourceId$11)
              }
            };
    }
    if (variant === "UpdateIntegrationConfig") {
      var match$45 = action.VAL;
      var config = match$45[1];
      var integrationId$1 = match$45[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateIntegration(model._0, integrationId$1, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: config,
                                filters: integration.filters,
                                autoPublish: integration.autoPublish
                              };
                      }))
              };
      }
      var model$80 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$80.archive,
                branchPointer: model$80.branchPointer,
                migrations: model$80.migrations,
                rules: model$80.rules,
                types: model$80.types,
                categories: model$80.categories,
                destinations: model$80.destinations,
                events: model$80.events,
                groupTypes: model$80.groupTypes,
                integrations: Curry._3(TrackingPlanMappedModel.Integrations.update, model$80.integrations, integrationId$1, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: config,
                                filters: integration.filters,
                                autoPublish: integration.autoPublish
                              };
                      })),
                metrics: model$80.metrics,
                openBranches: model$80.openBranches,
                properties: model$80.properties,
                propertyBundles: model$80.propertyBundles,
                sources: model$80.sources
              }
            };
    }
    if (variant === "UpdatePropertyOperation") {
      var match$46 = action.VAL;
      var operation = match$46[1];
      var propertyId$17 = match$46[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateProperty(model._0, propertyId$17, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      }))
              };
      }
      var model$81 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$81.archive,
                branchPointer: model$81.branchPointer,
                migrations: model$81.migrations,
                rules: model$81.rules,
                types: model$81.types,
                categories: model$81.categories,
                destinations: model$81.destinations,
                events: model$81.events,
                groupTypes: model$81.groupTypes,
                integrations: model$81.integrations,
                metrics: model$81.metrics,
                openBranches: model$81.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$81.properties, propertyId$17, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: property.validations,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$81.propertyBundles,
                sources: model$81.sources
              }
            };
    }
    if (variant === "TogglePropertyExcludedSource") {
      var match$47 = action.VAL;
      var isActive$2 = match$47[2];
      var sourceId$12 = match$47[1];
      var propertyId$18 = match$47[0];
      if (model.TAG === /* Old */0) {
        var model$82 = model._0;
        return {
                TAG: /* Old */0,
                _0: hasMigrated(model$82, "PropertyOptionalAndExcludedSourcesToAbsence") ? Belt_List.reduceU(StateUtils.eventsSendingProperty(model$82, undefined, propertyId$18), model$82, (function (innerModel, $$event) {
                          if (Belt_List.someU($$event.includeSources, (function (includedSource) {
                                    return includedSource.id === sourceId$12;
                                  }))) {
                            return StateUtils.updateProperty(model$82, propertyId$18, (function (property) {
                                          return updatePropertyAbsence({
                                                      TAG: /* Old */0,
                                                      _0: innerModel
                                                    }, property, {
                                                      TAG: /* EventSource */2,
                                                      _0: $$event.id,
                                                      _1: sourceId$12
                                                    }, isActive$2 ? /* NeverSent */0 : (
                                                        property.optionalDeprecated ? /* SometimesSent */({
                                                              _0: ""
                                                            }) : undefined
                                                      ));
                                        }));
                          } else {
                            return innerModel;
                          }
                        })) : StateUtils.updateProperty(model$82, propertyId$18, (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: property.optionalDeprecated,
                                  excludedSourcesDeprecated: isActive$2 ? Belt_List.keepU(property.excludedSourcesDeprecated, (function (excludedSourceId) {
                                            return excludedSourceId !== sourceId$12;
                                          })) : Belt_List.concat(Belt_List.keepU(property.excludedSourcesDeprecated, (function (excludedSourceId) {
                                                return excludedSourceId !== sourceId$12;
                                              })), {
                                          hd: sourceId$12,
                                          tl: /* [] */0
                                        }),
                                  optionalWhenInObject: property.optionalWhenInObject,
                                  absence: property.absence
                                };
                        }))
              };
      }
      var model$83 = model._0;
      if (hasMigrated_boxed({
              TAG: /* Mapped */1,
              _0: model$83
            }, "PropertyOptionalAndExcludedSourcesToAbsence")) {
        return {
                TAG: /* Mapped */1,
                _0: Curry._3(TrackingPlanMappedModel.Events.reduce, StateUtils.eventsSendingProperty_mappedModel(model$83, undefined, propertyId$18), model$83, (function (innerModel, $$event) {
                        if (Belt_List.someU($$event.includeSources, (function (includedSource) {
                                  return includedSource.id === sourceId$12;
                                }))) {
                          return {
                                  archive: model$83.archive,
                                  branchPointer: model$83.branchPointer,
                                  migrations: model$83.migrations,
                                  rules: model$83.rules,
                                  types: model$83.types,
                                  categories: model$83.categories,
                                  destinations: model$83.destinations,
                                  events: model$83.events,
                                  groupTypes: model$83.groupTypes,
                                  integrations: model$83.integrations,
                                  metrics: model$83.metrics,
                                  openBranches: model$83.openBranches,
                                  properties: Curry._3(TrackingPlanMappedModel.Properties.update, innerModel.properties, propertyId$18, (function (property) {
                                          return updatePropertyAbsence({
                                                      TAG: /* Mapped */1,
                                                      _0: innerModel
                                                    }, property, {
                                                      TAG: /* EventSource */2,
                                                      _0: $$event.id,
                                                      _1: sourceId$12
                                                    }, isActive$2 ? /* NeverSent */0 : (
                                                        property.optionalDeprecated ? /* SometimesSent */({
                                                              _0: ""
                                                            }) : undefined
                                                      ));
                                        })),
                                  propertyBundles: model$83.propertyBundles,
                                  sources: model$83.sources
                                };
                        } else {
                          return innerModel;
                        }
                      }))
              };
      } else {
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: model$83.archive,
                  branchPointer: model$83.branchPointer,
                  migrations: model$83.migrations,
                  rules: model$83.rules,
                  types: model$83.types,
                  categories: model$83.categories,
                  destinations: model$83.destinations,
                  events: model$83.events,
                  groupTypes: model$83.groupTypes,
                  integrations: model$83.integrations,
                  metrics: model$83.metrics,
                  openBranches: model$83.openBranches,
                  properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$83.properties, propertyId$18, (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: property.optionalDeprecated,
                                  excludedSourcesDeprecated: isActive$2 ? Belt_List.keepU(property.excludedSourcesDeprecated, (function (excludedSourceId) {
                                            return excludedSourceId !== sourceId$12;
                                          })) : Belt_List.concat(Belt_List.keepU(property.excludedSourcesDeprecated, (function (excludedSourceId) {
                                                return excludedSourceId !== sourceId$12;
                                              })), {
                                          hd: sourceId$12,
                                          tl: /* [] */0
                                        }),
                                  optionalWhenInObject: property.optionalWhenInObject,
                                  absence: property.absence
                                };
                        })),
                  propertyBundles: model$83.propertyBundles,
                  sources: model$83.sources
                }
              };
      }
    }
    if (variant === "RemoveTag") {
      var match$48 = action.VAL;
      var tag$1 = match$48[1];
      var eventId$16 = match$48[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$16, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: Belt_List.keepU($$event.tags, (function (eventTag) {
                                        return eventTag !== tag$1;
                                      })),
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$84 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$84.archive,
                branchPointer: model$84.branchPointer,
                migrations: model$84.migrations,
                rules: model$84.rules,
                types: model$84.types,
                categories: model$84.categories,
                destinations: model$84.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$84.events, eventId$16, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: Belt_List.keepU($$event.tags, (function (eventTag) {
                                        return eventTag !== tag$1;
                                      })),
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$84.groupTypes,
                integrations: model$84.integrations,
                metrics: model$84.metrics,
                openBranches: model$84.openBranches,
                properties: model$84.properties,
                propertyBundles: model$84.propertyBundles,
                sources: model$84.sources
              }
            };
    }
    if (variant === "AddUserToGroup") {
      var match$49 = action.VAL;
      var groupTypeId$2 = match$49[1];
      var eventId$17 = match$49[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$17, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: Belt_Array.concat($$event.userGroupTypeIdsWithArchive, [groupTypeId$2]),
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$85 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$85.archive,
                branchPointer: model$85.branchPointer,
                migrations: model$85.migrations,
                rules: model$85.rules,
                types: model$85.types,
                categories: model$85.categories,
                destinations: model$85.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$85.events, eventId$17, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: Belt_Array.concat($$event.userGroupTypeIdsWithArchive, [groupTypeId$2]),
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$85.groupTypes,
                integrations: model$85.integrations,
                metrics: model$85.metrics,
                openBranches: model$85.openBranches,
                properties: model$85.properties,
                propertyBundles: model$85.propertyBundles,
                sources: model$85.sources
              }
            };
    }
    if (variant === "MigrateExcludeEventsToIncludeEvents") {
      var sourceIds = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$86 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$86.types,
                  properties: model$86.properties,
                  propertyBundles: model$86.propertyBundles,
                  events: Belt_List.mapU(model$86.events, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: Belt_List.map(Belt_List.keepU(sourceIds, (function (sourceId) {
                                              return Belt_Option.mapWithDefault(Belt_List.getByU($$event.excludeSourcesDeprecated, (function (excludedSourceId) {
                                                                return excludedSourceId === sourceId;
                                                              })), true, (function (param) {
                                                            return false;
                                                          }));
                                            })), (function (sourceId) {
                                          return {
                                                  id: sourceId,
                                                  includeInCodegen: true,
                                                  inspectorValidation: undefined
                                                };
                                        })),
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        })),
                  migrations: {
                    hd: "ExplicitIncludeSource",
                    tl: model$86.migrations
                  },
                  sources: model$86.sources,
                  destinations: model$86.destinations,
                  groupTypes: model$86.groupTypes,
                  goals: model$86.goals,
                  metrics: model$86.metrics,
                  archive: model$86.archive,
                  openBranches: model$86.openBranches,
                  branchPointer: model$86.branchPointer,
                  rules: model$86.rules,
                  integrations: model$86.integrations
                }
              };
      }
      var model$87 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$87.archive,
                branchPointer: model$87.branchPointer,
                migrations: Belt_Array.concat(["ExplicitIncludeSource"], model$87.migrations),
                rules: model$87.rules,
                types: model$87.types,
                categories: model$87.categories,
                destinations: model$87.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$87.events, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: Belt_List.map(Belt_List.keepU(sourceIds, (function (sourceId) {
                                            return Belt_Option.mapWithDefault(Belt_List.getByU($$event.excludeSourcesDeprecated, (function (excludedSourceId) {
                                                              return excludedSourceId === sourceId;
                                                            })), true, (function (param) {
                                                          return false;
                                                        }));
                                          })), (function (sourceId) {
                                        return {
                                                id: sourceId,
                                                includeInCodegen: true,
                                                inspectorValidation: undefined
                                              };
                                      })),
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$87.groupTypes,
                integrations: model$87.integrations,
                metrics: model$87.metrics,
                openBranches: model$87.openBranches,
                properties: model$87.properties,
                propertyBundles: model$87.propertyBundles,
                sources: model$87.sources
              }
            };
    }
    if (variant === "RemovePropertyRefV2") {
      var match$50 = action.VAL;
      var propertyId$19 = match$50[1];
      var eventId$18 = match$50[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateProperty(StateUtils.updateEvent(model._0, eventId$18, (function ($$event) {
                            return {
                                    id: $$event.id,
                                    name: $$event.name,
                                    uniqueName: $$event.uniqueName,
                                    description: $$event.description,
                                    properties: Belt_List.keepU($$event.properties, (function (item) {
                                            return item._0.id !== propertyId$19;
                                          })),
                                    propertyBundles: $$event.propertyBundles,
                                    variants: $$event.variants,
                                    types: $$event.types,
                                    tags: $$event.tags,
                                    excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                    includeSources: $$event.includeSources,
                                    includeDestinations: $$event.includeDestinations,
                                    hashes: $$event.hashes,
                                    propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                            return param[1] !== propertyId$19;
                                          })),
                                    eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                    userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                    triggers: $$event.triggers
                                  };
                          })), propertyId$19, (function (property) {
                        return updatePropertyAbsence(model, property, {
                                    TAG: /* Event */0,
                                    _0: eventId$18
                                  }, undefined);
                      }))
              };
      }
      var model$88 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$88.archive,
                branchPointer: model$88.branchPointer,
                migrations: model$88.migrations,
                rules: model$88.rules,
                types: model$88.types,
                categories: model$88.categories,
                destinations: model$88.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$88.events, eventId$18, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.keepU($$event.properties, (function (item) {
                                        return item._0.id !== propertyId$19;
                                      })),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                        return param[1] !== propertyId$19;
                                      })),
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$88.groupTypes,
                integrations: model$88.integrations,
                metrics: model$88.metrics,
                openBranches: model$88.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$88.properties, propertyId$19, (function (property) {
                        return updatePropertyAbsence(model, property, {
                                    TAG: /* Event */0,
                                    _0: eventId$18
                                  }, undefined);
                      })),
                propertyBundles: model$88.propertyBundles,
                sources: model$88.sources
              }
            };
    }
    if (variant === "RemoveIntegration") {
      var integrationId$2 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$89 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$89.types,
                  properties: model$89.properties,
                  propertyBundles: model$89.propertyBundles,
                  events: model$89.events,
                  migrations: model$89.migrations,
                  sources: model$89.sources,
                  destinations: model$89.destinations,
                  groupTypes: model$89.groupTypes,
                  goals: model$89.goals,
                  metrics: model$89.metrics,
                  archive: model$89.archive,
                  openBranches: model$89.openBranches,
                  branchPointer: model$89.branchPointer,
                  rules: model$89.rules,
                  integrations: Belt_List.keepU(model$89.integrations, (function (integration) {
                          return integration.id !== integrationId$2;
                        }))
                }
              };
      }
      var model$90 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$90.archive,
                branchPointer: model$90.branchPointer,
                migrations: model$90.migrations,
                rules: model$90.rules,
                types: model$90.types,
                categories: model$90.categories,
                destinations: model$90.destinations,
                events: model$90.events,
                groupTypes: model$90.groupTypes,
                integrations: Curry._2(TrackingPlanMappedModel.Integrations.remove, model$90.integrations, integrationId$2),
                metrics: model$90.metrics,
                openBranches: model$90.openBranches,
                properties: model$90.properties,
                propertyBundles: model$90.propertyBundles,
                sources: model$90.sources
              }
            };
    }
    if (variant === "UpdateDestinationApiKey") {
      var match$51 = action.VAL;
      var apiKey = match$51[2];
      var envName$1 = match$51[1];
      var destinationId$7 = match$51[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateDestination(model._0, destinationId$7, (function (destination) {
                        var init = destination.apiKeys;
                        var maybeKeyPair = Belt_List.getByU(destination.apiKeys.other, (function (param) {
                                return param[0] === envName$1;
                              }));
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: init.dev,
                                  prod: init.prod,
                                  other: maybeKeyPair !== undefined ? Belt_List.mapU(destination.apiKeys.other, (function (keyPair) {
                                            if (keyPair[0] === envName$1) {
                                              return [
                                                      envName$1,
                                                      apiKey
                                                    ];
                                            } else {
                                              return keyPair;
                                            }
                                          })) : Belt_List.concat(destination.apiKeys.other, {
                                          hd: [
                                            envName$1,
                                            apiKey
                                          ],
                                          tl: /* [] */0
                                        })
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      }))
              };
      }
      var model$91 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$91.archive,
                branchPointer: model$91.branchPointer,
                migrations: model$91.migrations,
                rules: model$91.rules,
                types: model$91.types,
                categories: model$91.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.update, model$91.destinations, destinationId$7, (function (destination) {
                        var init = destination.apiKeys;
                        var maybeKeyPair = Belt_List.getByU(destination.apiKeys.other, (function (param) {
                                return param[0] === envName$1;
                              }));
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: init.dev,
                                  prod: init.prod,
                                  other: maybeKeyPair !== undefined ? Belt_List.mapU(destination.apiKeys.other, (function (keyPair) {
                                            if (keyPair[0] === envName$1) {
                                              return [
                                                      envName$1,
                                                      apiKey
                                                    ];
                                            } else {
                                              return keyPair;
                                            }
                                          })) : Belt_List.concat(destination.apiKeys.other, {
                                          hd: [
                                            envName$1,
                                            apiKey
                                          ],
                                          tl: /* [] */0
                                        })
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      })),
                events: model$91.events,
                groupTypes: model$91.groupTypes,
                integrations: model$91.integrations,
                metrics: model$91.metrics,
                openBranches: model$91.openBranches,
                properties: model$91.properties,
                propertyBundles: model$91.propertyBundles,
                sources: model$91.sources
              }
            };
    }
    if (variant === "UpdateDestinationName") {
      var match$52 = action.VAL;
      var name$5 = match$52[1];
      var destinationId$8 = match$52[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateDestination(model._0, destinationId$8, (function (destination) {
                        return {
                                id: destination.id,
                                name: name$5,
                                type_: destination.type_,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      }))
              };
      }
      var model$92 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$92.archive,
                branchPointer: model$92.branchPointer,
                migrations: model$92.migrations,
                rules: model$92.rules,
                types: model$92.types,
                categories: model$92.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.update, model$92.destinations, destinationId$8, (function (destination) {
                        return {
                                id: destination.id,
                                name: name$5,
                                type_: destination.type_,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      })),
                events: model$92.events,
                groupTypes: model$92.groupTypes,
                integrations: model$92.integrations,
                metrics: model$92.metrics,
                openBranches: model$92.openBranches,
                properties: model$92.properties,
                propertyBundles: model$92.propertyBundles,
                sources: model$92.sources
              }
            };
    }
    if (variant === "ToggleIncludeEventInCodegenForSource") {
      var match$53 = action.VAL;
      var includeInCodegen = match$53[2];
      var sourceId$13 = match$53[1];
      var eventId$19 = match$53[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$19, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: Belt_List.mapU($$event.includeSources, (function (includeInSource) {
                                        if (includeInSource.id === sourceId$13) {
                                          return {
                                                  id: includeInSource.id,
                                                  includeInCodegen: includeInCodegen,
                                                  inspectorValidation: includeInSource.inspectorValidation
                                                };
                                        } else {
                                          return includeInSource;
                                        }
                                      })),
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$93 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$93.archive,
                branchPointer: model$93.branchPointer,
                migrations: model$93.migrations,
                rules: model$93.rules,
                types: model$93.types,
                categories: model$93.categories,
                destinations: model$93.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$93.events, eventId$19, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: Belt_List.mapU($$event.includeSources, (function (includeInSource) {
                                        if (includeInSource.id === sourceId$13) {
                                          return {
                                                  id: includeInSource.id,
                                                  includeInCodegen: includeInCodegen,
                                                  inspectorValidation: includeInSource.inspectorValidation
                                                };
                                        } else {
                                          return includeInSource;
                                        }
                                      })),
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$93.groupTypes,
                integrations: model$93.integrations,
                metrics: model$93.metrics,
                openBranches: model$93.openBranches,
                properties: model$93.properties,
                propertyBundles: model$93.propertyBundles,
                sources: model$93.sources
              }
            };
    }
    if (variant === "UpdatePropertyOptional") {
      var match$54 = action.VAL;
      var optional = match$54[1];
      var propertyId$20 = match$54[0];
      if (model.TAG === /* Old */0) {
        var model$94 = model._0;
        return {
                TAG: /* Old */0,
                _0: hasMigrated(model$94, "PropertyOptionalAndExcludedSourcesToAbsence") ? StateUtils.updateProperty(model$94, propertyId$20, (function (property) {
                          var excludedSources = property.excludedSourcesDeprecated;
                          if (!excludedSources) {
                            return updatePropertyAbsence(model, property, /* All */0, optional ? /* SometimesSent */({
                                            _0: ""
                                          }) : undefined);
                          }
                          var relevantEventSourcePairs = Belt_List.flatten(Belt_List.mapU(StateUtils.eventsSendingProperty(model$94, undefined, propertyId$20), (function ($$event) {
                                      return Belt_List.keepMapU($$event.includeSources, (function (includedSource) {
                                                    if (!Belt_List.has(excludedSources, includedSource.id, (function (prim0, prim1) {
                                                              return prim0 === prim1;
                                                            }))) {
                                                      return [
                                                              $$event.id,
                                                              includedSource.id
                                                            ];
                                                    }
                                                    
                                                  }));
                                    })));
                          return Belt_List.reduceU(relevantEventSourcePairs, property, (function (property, param) {
                                        return updatePropertyAbsence(model, property, {
                                                    TAG: /* EventSource */2,
                                                    _0: param[0],
                                                    _1: param[1]
                                                  }, optional ? /* SometimesSent */({
                                                        _0: ""
                                                      }) : undefined);
                                      }));
                        })) : StateUtils.updateProperty(model$94, propertyId$20, (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: optional,
                                  excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                  optionalWhenInObject: property.optionalWhenInObject,
                                  absence: property.absence
                                };
                        }))
              };
      }
      var model$95 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$95.archive,
                branchPointer: model$95.branchPointer,
                migrations: model$95.migrations,
                rules: model$95.rules,
                types: model$95.types,
                categories: model$95.categories,
                destinations: model$95.destinations,
                events: model$95.events,
                groupTypes: model$95.groupTypes,
                integrations: model$95.integrations,
                metrics: model$95.metrics,
                openBranches: model$95.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$95.properties, propertyId$20, (function (property) {
                        if (!hasMigrated_boxed(model, "PropertyOptionalAndExcludedSourcesToAbsence")) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: optional,
                                  excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                  optionalWhenInObject: property.optionalWhenInObject,
                                  absence: property.absence
                                };
                        }
                        var excludedSources = property.excludedSourcesDeprecated;
                        if (!excludedSources) {
                          return updatePropertyAbsence(model, property, /* All */0, optional ? /* SometimesSent */({
                                          _0: ""
                                        }) : undefined);
                        }
                        var relevantEventSourcePairs = Belt_List.flatten(Belt_List.fromArray(Curry._2(TrackingPlanMappedModel.Events.mapToArray, StateUtils.eventsSendingProperty_mappedModel(model$95, undefined, propertyId$20), (function ($$event) {
                                        return Belt_List.keepMapU($$event.includeSources, (function (includedSource) {
                                                      if (!Belt_List.has(excludedSources, includedSource.id, (function (prim0, prim1) {
                                                                return prim0 === prim1;
                                                              }))) {
                                                        return [
                                                                $$event.id,
                                                                includedSource.id
                                                              ];
                                                      }
                                                      
                                                    }));
                                      }))));
                        return Belt_List.reduceU(relevantEventSourcePairs, property, (function (property, param) {
                                      return updatePropertyAbsence(model, property, {
                                                  TAG: /* EventSource */2,
                                                  _0: param[0],
                                                  _1: param[1]
                                                }, optional ? /* SometimesSent */({
                                                      _0: ""
                                                    }) : undefined);
                                    }));
                      })),
                propertyBundles: model$95.propertyBundles,
                sources: model$95.sources
              }
            };
    }
    if (variant === "IncludeEventInSourceV2") {
      var match$55 = action.VAL;
      var includeInCodegen$1 = match$55[3];
      var destinationIds = match$55[2];
      var sourceId$14 = match$55[1];
      var eventId$20 = match$55[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$20, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: {
                                  hd: {
                                    id: sourceId$14,
                                    includeInCodegen: includeInCodegen$1,
                                    inspectorValidation: undefined
                                  },
                                  tl: $$event.includeSources
                                },
                                includeDestinations: Belt_List.concat(Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                            return includeDestination.VAL[0] !== sourceId$14;
                                          })), {
                                      hd: {
                                        NAME: "IncludeSourceDestination",
                                        VAL: [
                                          sourceId$14,
                                          destinationIds
                                        ]
                                      },
                                      tl: /* [] */0
                                    }),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$96 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$96.archive,
                branchPointer: model$96.branchPointer,
                migrations: model$96.migrations,
                rules: model$96.rules,
                types: model$96.types,
                categories: model$96.categories,
                destinations: model$96.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$96.events, eventId$20, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: {
                                  hd: {
                                    id: sourceId$14,
                                    includeInCodegen: includeInCodegen$1,
                                    inspectorValidation: undefined
                                  },
                                  tl: $$event.includeSources
                                },
                                includeDestinations: Belt_List.concat(Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                            return includeDestination.VAL[0] !== sourceId$14;
                                          })), {
                                      hd: {
                                        NAME: "IncludeSourceDestination",
                                        VAL: [
                                          sourceId$14,
                                          destinationIds
                                        ]
                                      },
                                      tl: /* [] */0
                                    }),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$96.groupTypes,
                integrations: model$96.integrations,
                metrics: model$96.metrics,
                openBranches: model$96.openBranches,
                properties: model$96.properties,
                propertyBundles: model$96.propertyBundles,
                sources: model$96.sources
              }
            };
    }
    if (variant === "AddSystemProperty") {
      var match$56 = action.VAL;
      var newSystemProperty_id = match$56[0];
      var newSystemProperty_name = match$56[1];
      var newSystemProperty_absence = hasMigrated_boxed(model, "PropertyOptionalAndExcludedSourcesToAbsence") ? /* AlwaysSent */0 : undefined;
      var newSystemProperty = {
        id: newSystemProperty_id,
        name: newSystemProperty_name,
        uniqueName: undefined,
        description: "",
        type_: "string",
        sendAs: /* SystemProperty */0,
        validations: /* [] */0,
        auto: false,
        builtIn: "NotBuiltIn",
        analyticsTools: /* [] */0,
        devPlatforms: /* [] */0,
        list: false,
        operation: "Set",
        optionalDeprecated: false,
        excludedSourcesDeprecated: /* [] */0,
        optionalWhenInObject: false,
        absence: newSystemProperty_absence
      };
      if (model.TAG === /* Old */0) {
        var model$97 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$97.types,
                  properties: Belt_List.concat(model$97.properties, {
                        hd: {
                          TAG: /* Property */1,
                          _0: newSystemProperty
                        },
                        tl: /* [] */0
                      }),
                  propertyBundles: model$97.propertyBundles,
                  events: model$97.events,
                  migrations: model$97.migrations,
                  sources: model$97.sources,
                  destinations: model$97.destinations,
                  groupTypes: model$97.groupTypes,
                  goals: model$97.goals,
                  metrics: model$97.metrics,
                  archive: model$97.archive,
                  openBranches: model$97.openBranches,
                  branchPointer: model$97.branchPointer,
                  rules: model$97.rules,
                  integrations: model$97.integrations
                }
              };
      }
      var model$98 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$98.archive,
                branchPointer: model$98.branchPointer,
                migrations: model$98.migrations,
                rules: model$98.rules,
                types: model$98.types,
                categories: model$98.categories,
                destinations: model$98.destinations,
                events: model$98.events,
                groupTypes: model$98.groupTypes,
                integrations: model$98.integrations,
                metrics: model$98.metrics,
                openBranches: model$98.openBranches,
                properties: Curry._2(TrackingPlanMappedModel.Properties.append, model$98.properties, newSystemProperty),
                propertyBundles: model$98.propertyBundles,
                sources: model$98.sources
              }
            };
    }
    if (variant === "UpdateRule") {
      var match$57 = action.VAL;
      var definition$1 = match$57[2];
      var ruleId$1 = match$57[0];
      if (model.TAG === /* Old */0) {
        var model$99 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$99.types,
                  properties: model$99.properties,
                  propertyBundles: model$99.propertyBundles,
                  events: model$99.events,
                  migrations: model$99.migrations,
                  sources: model$99.sources,
                  destinations: model$99.destinations,
                  groupTypes: model$99.groupTypes,
                  goals: model$99.goals,
                  metrics: model$99.metrics,
                  archive: model$99.archive,
                  openBranches: model$99.openBranches,
                  branchPointer: model$99.branchPointer,
                  rules: Belt_List.mapU(model$99.rules, (function (rule) {
                          if (rule.id === ruleId$1) {
                            return {
                                    id: rule.id,
                                    item: rule.item,
                                    definition: definition$1
                                  };
                          } else {
                            return rule;
                          }
                        })),
                  integrations: model$99.integrations
                }
              };
      }
      var model$100 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$100.archive,
                branchPointer: model$100.branchPointer,
                migrations: model$100.migrations,
                rules: Belt_Array.mapU(model$100.rules, (function (rule) {
                        if (rule.id === ruleId$1) {
                          return {
                                  id: rule.id,
                                  item: rule.item,
                                  definition: definition$1
                                };
                        } else {
                          return rule;
                        }
                      })),
                types: model$100.types,
                categories: model$100.categories,
                destinations: model$100.destinations,
                events: model$100.events,
                groupTypes: model$100.groupTypes,
                integrations: model$100.integrations,
                metrics: model$100.metrics,
                openBranches: model$100.openBranches,
                properties: model$100.properties,
                propertyBundles: model$100.propertyBundles,
                sources: model$100.sources
              }
            };
    }
    if (variant === "SetMetricGroupBy") {
      var match$58 = action.VAL;
      var propertyId$21 = match$58[3];
      var id$3 = match$58[2];
      var eventId$21 = match$58[1];
      var metricId$5 = match$58[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateMetric(model._0, metricId$5, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$21 !== $$event.eventId) {
                                          return metricItem;
                                        }
                                        var groupBy = $$event.groupBy;
                                        var match = Belt_List.keepU(groupBy, (function (groupBy) {
                                                return groupBy.id === id$3;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: $$event.where,
                                                  groupBy: match ? Belt_List.mapU(groupBy, (function (groupBy) {
                                                            if (groupBy.id === id$3) {
                                                              return {
                                                                      id: id$3,
                                                                      propertyId: propertyId$21
                                                                    };
                                                            } else {
                                                              return groupBy;
                                                            }
                                                          })) : Belt_List.concat(groupBy, {
                                                          hd: {
                                                            id: id$3,
                                                            propertyId: propertyId$21
                                                          },
                                                          tl: /* [] */0
                                                        })
                                                }
                                              };
                                      }))
                              };
                      }))
              };
      }
      var model$101 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$101.archive,
                branchPointer: model$101.branchPointer,
                migrations: model$101.migrations,
                rules: model$101.rules,
                types: model$101.types,
                categories: model$101.categories,
                destinations: model$101.destinations,
                events: model$101.events,
                groupTypes: model$101.groupTypes,
                integrations: model$101.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.update, model$101.metrics, metricId$5, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$21 !== $$event.eventId) {
                                          return metricItem;
                                        }
                                        var groupBy = $$event.groupBy;
                                        var match = Belt_List.keepU(groupBy, (function (groupBy) {
                                                return groupBy.id === id$3;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: $$event.where,
                                                  groupBy: match ? Belt_List.mapU(groupBy, (function (groupBy) {
                                                            if (groupBy.id === id$3) {
                                                              return {
                                                                      id: id$3,
                                                                      propertyId: propertyId$21
                                                                    };
                                                            } else {
                                                              return groupBy;
                                                            }
                                                          })) : Belt_List.concat(groupBy, {
                                                          hd: {
                                                            id: id$3,
                                                            propertyId: propertyId$21
                                                          },
                                                          tl: /* [] */0
                                                        })
                                                }
                                              };
                                      }))
                              };
                      })),
                openBranches: model$101.openBranches,
                properties: model$101.properties,
                propertyBundles: model$101.propertyBundles,
                sources: model$101.sources
              }
            };
    }
    if (variant === "RemoveSourceSegmentIntegration") {
      var match$59 = action.VAL;
      var segmentIntegration = match$59[1];
      var sourceId$15 = match$59[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateSource(model._0, sourceId$15, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: Belt_List.keepU(source.segmentDestinationOptions, (function (param) {
                                        return param[0] !== segmentIntegration;
                                      })),
                                config: source.config
                              };
                      }))
              };
      }
      var model$102 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$102.archive,
                branchPointer: model$102.branchPointer,
                migrations: model$102.migrations,
                rules: model$102.rules,
                types: model$102.types,
                categories: model$102.categories,
                destinations: model$102.destinations,
                events: model$102.events,
                groupTypes: model$102.groupTypes,
                integrations: model$102.integrations,
                metrics: model$102.metrics,
                openBranches: model$102.openBranches,
                properties: model$102.properties,
                propertyBundles: model$102.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$102.sources, sourceId$15, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: Belt_List.keepU(source.segmentDestinationOptions, (function (param) {
                                        return param[0] !== segmentIntegration;
                                      })),
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "IncludeEventInSource") {
      var match$60 = action.VAL;
      var sourceId$16 = match$60[1];
      var eventId$22 = match$60[0];
      if (model.TAG === /* Old */0) {
        var model$103 = model._0;
        return {
                TAG: /* Old */0,
                _0: hasMigrated(model$103, "ExplicitIncludeSource") ? StateUtils.updateEvent(model$103, eventId$22, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: {
                                    hd: {
                                      id: sourceId$16,
                                      includeInCodegen: true,
                                      inspectorValidation: undefined
                                    },
                                    tl: $$event.includeSources
                                  },
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        })) : StateUtils.updateEvent(model$103, eventId$22, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: Belt_List.keepU($$event.excludeSourcesDeprecated, (function (excludeSourceId) {
                                          return excludeSourceId !== sourceId$16;
                                        })),
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        }))
              };
      }
      var model$104 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$104.archive,
                branchPointer: model$104.branchPointer,
                migrations: model$104.migrations,
                rules: model$104.rules,
                types: model$104.types,
                categories: model$104.categories,
                destinations: model$104.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$104.events, eventId$22, (function ($$event) {
                        if (hasMigrated_boxed(model, "ExplicitIncludeSource")) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: {
                                    hd: {
                                      id: sourceId$16,
                                      includeInCodegen: true,
                                      inspectorValidation: undefined
                                    },
                                    tl: $$event.includeSources
                                  },
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        } else {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: Belt_List.keepU($$event.excludeSourcesDeprecated, (function (excludeSourceId) {
                                          return excludeSourceId !== sourceId$16;
                                        })),
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        }
                      })),
                groupTypes: model$104.groupTypes,
                integrations: model$104.integrations,
                metrics: model$104.metrics,
                openBranches: model$104.openBranches,
                properties: model$104.properties,
                propertyBundles: model$104.propertyBundles,
                sources: model$104.sources
              }
            };
    }
    if (variant === "UpdateEventDescription") {
      var match$61 = action.VAL;
      var description$3 = match$61[1];
      var eventId$23 = match$61[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$23, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: description$3,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$105 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$105.archive,
                branchPointer: model$105.branchPointer,
                migrations: model$105.migrations,
                rules: model$105.rules,
                types: model$105.types,
                categories: model$105.categories,
                destinations: model$105.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$105.events, eventId$23, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: description$3,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$105.groupTypes,
                integrations: model$105.integrations,
                metrics: model$105.metrics,
                openBranches: model$105.openBranches,
                properties: model$105.properties,
                propertyBundles: model$105.propertyBundles,
                sources: model$105.sources
              }
            };
    }
    if (variant === "AddEventToMetric") {
      var match$62 = action.VAL;
      var eventId$24 = match$62[2];
      var metricItemId$1 = match$62[1];
      var metricId$6 = match$62[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateMetric(model._0, metricId$6, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.concat(metric.items, {
                                      hd: {
                                        NAME: "Event",
                                        VAL: {
                                          id: metricItemId$1,
                                          eventId: eventId$24,
                                          where: /* [] */0,
                                          groupBy: /* [] */0
                                        }
                                      },
                                      tl: /* [] */0
                                    })
                              };
                      }))
              };
      }
      var model$106 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$106.archive,
                branchPointer: model$106.branchPointer,
                migrations: model$106.migrations,
                rules: model$106.rules,
                types: model$106.types,
                categories: model$106.categories,
                destinations: model$106.destinations,
                events: model$106.events,
                groupTypes: model$106.groupTypes,
                integrations: model$106.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.update, model$106.metrics, metricId$6, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.concat(metric.items, {
                                      hd: {
                                        NAME: "Event",
                                        VAL: {
                                          id: metricItemId$1,
                                          eventId: eventId$24,
                                          where: /* [] */0,
                                          groupBy: /* [] */0
                                        }
                                      },
                                      tl: /* [] */0
                                    })
                              };
                      })),
                openBranches: model$106.openBranches,
                properties: model$106.properties,
                propertyBundles: model$106.propertyBundles,
                sources: model$106.sources
              }
            };
    }
    if (variant === "ReorderEventsInMetric") {
      var match$63 = action.VAL;
      var maybePredecessorId$2 = match$63[2];
      var metricItemId$2 = match$63[1];
      var metricId$7 = match$63[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateMetric(model._0, metricId$7, (function (metric) {
                        var movedItem = Belt_List.getByU(metric.items, (function (metricItem) {
                                return metricItem.VAL.id === metricItemId$2;
                              }));
                        var listWithoutItem = Belt_List.keepU(metric.items, (function (metricItem) {
                                return metricItem.VAL.id !== metricItemId$2;
                              }));
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: movedItem !== undefined ? (
                                    maybePredecessorId$2 !== undefined ? BeltListExtensions.flatMap(listWithoutItem, (function (x) {
                                              if (x.VAL.id === maybePredecessorId$2) {
                                                return {
                                                        hd: x,
                                                        tl: {
                                                          hd: movedItem,
                                                          tl: /* [] */0
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        hd: x,
                                                        tl: /* [] */0
                                                      };
                                              }
                                            })) : Belt_List.concat({
                                            hd: movedItem,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : metric.items
                              };
                      }))
              };
      }
      var model$107 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$107.archive,
                branchPointer: model$107.branchPointer,
                migrations: model$107.migrations,
                rules: model$107.rules,
                types: model$107.types,
                categories: model$107.categories,
                destinations: model$107.destinations,
                events: model$107.events,
                groupTypes: model$107.groupTypes,
                integrations: model$107.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.update, model$107.metrics, metricId$7, (function (metric) {
                        var movedItem = Belt_List.getByU(metric.items, (function (metricItem) {
                                return metricItem.VAL.id === metricItemId$2;
                              }));
                        var listWithoutItem = Belt_List.keepU(metric.items, (function (metricItem) {
                                return metricItem.VAL.id !== metricItemId$2;
                              }));
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: movedItem !== undefined ? (
                                    maybePredecessorId$2 !== undefined ? BeltListExtensions.flatMap(listWithoutItem, (function (x) {
                                              if (x.VAL.id === maybePredecessorId$2) {
                                                return {
                                                        hd: x,
                                                        tl: {
                                                          hd: movedItem,
                                                          tl: /* [] */0
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        hd: x,
                                                        tl: /* [] */0
                                                      };
                                              }
                                            })) : Belt_List.concat({
                                            hd: movedItem,
                                            tl: /* [] */0
                                          }, listWithoutItem)
                                  ) : metric.items
                              };
                      })),
                openBranches: model$107.openBranches,
                properties: model$107.properties,
                propertyBundles: model$107.propertyBundles,
                sources: model$107.sources
              }
            };
    }
    if (variant === "CreateDestination") {
      var destinationId$9 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$108 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$108.types,
                  properties: model$108.properties,
                  propertyBundles: model$108.propertyBundles,
                  events: model$108.events,
                  migrations: model$108.migrations,
                  sources: model$108.sources,
                  destinations: Belt_List.concat(model$108.destinations, {
                        hd: TrackingPlanModel.emptyDestination(destinationId$9),
                        tl: /* [] */0
                      }),
                  groupTypes: model$108.groupTypes,
                  goals: model$108.goals,
                  metrics: model$108.metrics,
                  archive: model$108.archive,
                  openBranches: model$108.openBranches,
                  branchPointer: model$108.branchPointer,
                  rules: model$108.rules,
                  integrations: model$108.integrations
                }
              };
      }
      var model$109 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$109.archive,
                branchPointer: model$109.branchPointer,
                migrations: model$109.migrations,
                rules: model$109.rules,
                types: model$109.types,
                categories: model$109.categories,
                destinations: Curry._2(TrackingPlanMappedModel.Destinations.append, model$109.destinations, TrackingPlanModel.emptyDestination(destinationId$9)),
                events: model$109.events,
                groupTypes: model$109.groupTypes,
                integrations: model$109.integrations,
                metrics: model$109.metrics,
                openBranches: model$109.openBranches,
                properties: model$109.properties,
                propertyBundles: model$109.propertyBundles,
                sources: model$109.sources
              }
            };
    }
    if (variant === "AddPropertyValidationMatch") {
      var match$64 = action.VAL;
      var match_$2 = match$64[1];
      var propertyId$22 = match$64[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateProperty(model._0, propertyId$22, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: {
                                  hd: {
                                    NAME: "Matches",
                                    VAL: {
                                      hd: [
                                        {
                                          NAME: "Literal",
                                          VAL: {
                                            NAME: "StringLit",
                                            VAL: match_$2
                                          }
                                        },
                                        /* [] */0
                                      ],
                                      tl: StateUtils.getMatchesFromProperty(property)
                                    }
                                  },
                                  tl: /* [] */0
                                },
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      }))
              };
      }
      var model$110 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$110.archive,
                branchPointer: model$110.branchPointer,
                migrations: model$110.migrations,
                rules: model$110.rules,
                types: model$110.types,
                categories: model$110.categories,
                destinations: model$110.destinations,
                events: model$110.events,
                groupTypes: model$110.groupTypes,
                integrations: model$110.integrations,
                metrics: model$110.metrics,
                openBranches: model$110.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$110.properties, propertyId$22, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: {
                                  hd: {
                                    NAME: "Matches",
                                    VAL: {
                                      hd: [
                                        {
                                          NAME: "Literal",
                                          VAL: {
                                            NAME: "StringLit",
                                            VAL: match_$2
                                          }
                                        },
                                        /* [] */0
                                      ],
                                      tl: StateUtils.getMatchesFromProperty(property)
                                    }
                                  },
                                  tl: /* [] */0
                                },
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$110.propertyBundles,
                sources: model$110.sources
              }
            };
    }
    if (variant === "UpdateNestedPropertyPinnedValue") {
      var match$65 = action.VAL;
      var pinnedValue = match$65[2];
      var nestedPropertyId = match$65[1];
      var propertyId$23 = match$65[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateProperty(model._0, propertyId$23, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: Belt_List.map(property.validations, (function (x) {
                                        if (typeof x === "object" && x.NAME === "NestedProperty") {
                                          return {
                                                  NAME: "NestedProperty",
                                                  VAL: Belt_List.map(x.VAL, (function (propertyRef) {
                                                          if (propertyRef.id === nestedPropertyId) {
                                                            return {
                                                                    id: propertyRef.id,
                                                                    description: propertyRef.description,
                                                                    pinnedValue: pinnedValue
                                                                  };
                                                          } else {
                                                            return propertyRef;
                                                          }
                                                        }))
                                                };
                                        } else {
                                          return x;
                                        }
                                      })),
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      }))
              };
      }
      var model$111 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$111.archive,
                branchPointer: model$111.branchPointer,
                migrations: model$111.migrations,
                rules: model$111.rules,
                types: model$111.types,
                categories: model$111.categories,
                destinations: model$111.destinations,
                events: model$111.events,
                groupTypes: model$111.groupTypes,
                integrations: model$111.integrations,
                metrics: model$111.metrics,
                openBranches: model$111.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$111.properties, propertyId$23, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: Belt_List.map(property.validations, (function (x) {
                                        if (typeof x === "object" && x.NAME === "NestedProperty") {
                                          return {
                                                  NAME: "NestedProperty",
                                                  VAL: Belt_List.map(x.VAL, (function (propertyRef) {
                                                          if (propertyRef.id === nestedPropertyId) {
                                                            return {
                                                                    id: propertyRef.id,
                                                                    description: propertyRef.description,
                                                                    pinnedValue: pinnedValue
                                                                  };
                                                          } else {
                                                            return propertyRef;
                                                          }
                                                        }))
                                                };
                                        } else {
                                          return x;
                                        }
                                      })),
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$111.propertyBundles,
                sources: model$111.sources
              }
            };
    }
    if (variant === "AddMetricToGoal") {
      var match$66 = action.VAL;
      var metricId$8 = match$66[1];
      var goalId$4 = match$66[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateGoal(model._0, goalId$4, (function (goal) {
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: Belt_List.someU(goal.metrics, (function (id) {
                                        return id === metricId$8;
                                      })) ? goal.metrics : Belt_List.concat(goal.metrics, {
                                        hd: metricId$8,
                                        tl: /* [] */0
                                      }),
                                events: goal.events
                              };
                      }))
              };
      }
      var model$112 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$112.archive,
                branchPointer: model$112.branchPointer,
                migrations: model$112.migrations,
                rules: model$112.rules,
                types: model$112.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.update, model$112.categories, goalId$4, (function (category) {
                        return {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                metrics: Belt_List.someU(category.metrics, (function (id) {
                                        return id === metricId$8;
                                      })) ? category.metrics : Belt_List.concat(category.metrics, {
                                        hd: metricId$8,
                                        tl: /* [] */0
                                      }),
                                events: category.events
                              };
                      })),
                destinations: model$112.destinations,
                events: model$112.events,
                groupTypes: model$112.groupTypes,
                integrations: model$112.integrations,
                metrics: model$112.metrics,
                openBranches: model$112.openBranches,
                properties: model$112.properties,
                propertyBundles: model$112.propertyBundles,
                sources: model$112.sources
              }
            };
    }
    if (variant === "ExcludeDestinationFromEventSource") {
      var match$67 = action.VAL;
      var destinationId$10 = match$67[2];
      var sourceId$17 = match$67[1];
      var eventId$25 = match$67[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$25, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.mapU($$event.includeDestinations, (function (includeDestination) {
                                        var match = includeDestination.VAL;
                                        var id = match[0];
                                        if (id === sourceId$17) {
                                          return {
                                                  NAME: "IncludeSourceDestination",
                                                  VAL: [
                                                    id,
                                                    Belt_List.keepU(match[1], (function (id) {
                                                            return id !== destinationId$10;
                                                          }))
                                                  ]
                                                };
                                        } else {
                                          return includeDestination;
                                        }
                                      })),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$113 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$113.archive,
                branchPointer: model$113.branchPointer,
                migrations: model$113.migrations,
                rules: model$113.rules,
                types: model$113.types,
                categories: model$113.categories,
                destinations: model$113.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$113.events, eventId$25, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.mapU($$event.includeDestinations, (function (includeDestination) {
                                        var match = includeDestination.VAL;
                                        var id = match[0];
                                        if (id === sourceId$17) {
                                          return {
                                                  NAME: "IncludeSourceDestination",
                                                  VAL: [
                                                    id,
                                                    Belt_List.keepU(match[1], (function (id) {
                                                            return id !== destinationId$10;
                                                          }))
                                                  ]
                                                };
                                        } else {
                                          return includeDestination;
                                        }
                                      })),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$113.groupTypes,
                integrations: model$113.integrations,
                metrics: model$113.metrics,
                openBranches: model$113.openBranches,
                properties: model$113.properties,
                propertyBundles: model$113.propertyBundles,
                sources: model$113.sources
              }
            };
    }
    if (variant === "DeleteDestination") {
      var destinationId$11 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$114 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$114.types,
                  properties: model$114.properties,
                  propertyBundles: model$114.propertyBundles,
                  events: model$114.events,
                  migrations: model$114.migrations,
                  sources: Belt_List.mapU(model$114.sources, (function (source) {
                          return {
                                  id: source.id,
                                  name: source.name,
                                  language: source.language,
                                  platform: source.platform,
                                  destinations: Belt_List.keepU(source.destinations, (function (sourceDestination) {
                                          return sourceDestination.destinationId !== destinationId$11;
                                        })),
                                  filename: source.filename,
                                  path: source.path,
                                  segmentDestinationOptions: source.segmentDestinationOptions,
                                  config: source.config
                                };
                        })),
                  destinations: Belt_List.keepU(model$114.destinations, (function (destination) {
                          return destination.id !== destinationId$11;
                        })),
                  groupTypes: model$114.groupTypes,
                  goals: model$114.goals,
                  metrics: model$114.metrics,
                  archive: model$114.archive,
                  openBranches: model$114.openBranches,
                  branchPointer: model$114.branchPointer,
                  rules: model$114.rules,
                  integrations: model$114.integrations
                }
              };
      }
      var model$115 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$115.archive,
                branchPointer: model$115.branchPointer,
                migrations: model$115.migrations,
                rules: model$115.rules,
                types: model$115.types,
                categories: model$115.categories,
                destinations: Curry._2(TrackingPlanMappedModel.Destinations.remove, model$115.destinations, destinationId$11),
                events: model$115.events,
                groupTypes: model$115.groupTypes,
                integrations: model$115.integrations,
                metrics: model$115.metrics,
                openBranches: model$115.openBranches,
                properties: model$115.properties,
                propertyBundles: model$115.propertyBundles,
                sources: Curry._2(TrackingPlanMappedModel.Sources.mapToT, model$115.sources, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: Belt_List.keepU(source.destinations, (function (sourceDestination) {
                                        return sourceDestination.destinationId !== destinationId$11;
                                      })),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "UpdatePropertyGroupDescription") {
      var match$68 = action.VAL;
      var description$4 = match$68[1];
      var groupId = match$68[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updatePropertyGroup(model._0, groupId, (function (group) {
                        return {
                                id: group.id,
                                name: group.name,
                                description: description$4,
                                properties: group.properties
                              };
                      }))
              };
      }
      var model$116 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$116.archive,
                branchPointer: model$116.branchPointer,
                migrations: model$116.migrations,
                rules: model$116.rules,
                types: model$116.types,
                categories: model$116.categories,
                destinations: model$116.destinations,
                events: model$116.events,
                groupTypes: model$116.groupTypes,
                integrations: model$116.integrations,
                metrics: model$116.metrics,
                openBranches: model$116.openBranches,
                properties: model$116.properties,
                propertyBundles: Curry._3(TrackingPlanMappedModel.PropertyBundles.update, model$116.propertyBundles, groupId, (function (propertyBundle) {
                        return {
                                id: propertyBundle.id,
                                name: propertyBundle.name,
                                description: description$4,
                                properties: propertyBundle.properties
                              };
                      })),
                sources: model$116.sources
              }
            };
    }
    if (variant === "ExcludeEventFromSource") {
      var match$69 = action.VAL;
      var sourceId$18 = match$69[1];
      var eventId$26 = match$69[0];
      if (model.TAG === /* Old */0) {
        var model$117 = model._0;
        return {
                TAG: /* Old */0,
                _0: hasMigrated(model$117, "ExplicitIncludeSource") ? StateUtils.updateEvent(model$117, eventId$26, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: Belt_List.keepU($$event.includeSources, (function (includedSource) {
                                          return includedSource.id !== sourceId$18;
                                        })),
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        })) : StateUtils.updateEvent(model$117, eventId$26, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: {
                                    hd: sourceId$18,
                                    tl: $$event.excludeSourcesDeprecated
                                  },
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        }))
              };
      }
      var model$118 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$118.archive,
                branchPointer: model$118.branchPointer,
                migrations: model$118.migrations,
                rules: model$118.rules,
                types: model$118.types,
                categories: model$118.categories,
                destinations: model$118.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$118.events, eventId$26, (function ($$event) {
                        if (hasMigrated_boxed(model, "ExplicitIncludeSource")) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: Belt_List.keepU($$event.includeSources, (function (includedSource) {
                                          return includedSource.id !== sourceId$18;
                                        })),
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        } else {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: {
                                    hd: sourceId$18,
                                    tl: $$event.excludeSourcesDeprecated
                                  },
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        }
                      })),
                groupTypes: model$118.groupTypes,
                integrations: model$118.integrations,
                metrics: model$118.metrics,
                openBranches: model$118.openBranches,
                properties: model$118.properties,
                propertyBundles: model$118.propertyBundles,
                sources: model$118.sources
              }
            };
    }
    if (variant === "RemoveMetricGroupByV2") {
      var match$70 = action.VAL;
      var id$4 = match$70[3];
      var metricItemId$3 = match$70[1];
      var metricId$9 = match$70[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateMetric(model._0, metricId$9, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId$3 === $$event.id) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: $$event.where,
                                                    groupBy: Belt_List.keepU($$event.groupBy, (function (groupBy) {
                                                            return groupBy.id !== id$4;
                                                          }))
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      }))
              };
      }
      var model$119 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$119.archive,
                branchPointer: model$119.branchPointer,
                migrations: model$119.migrations,
                rules: model$119.rules,
                types: model$119.types,
                categories: model$119.categories,
                destinations: model$119.destinations,
                events: model$119.events,
                groupTypes: model$119.groupTypes,
                integrations: model$119.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.update, model$119.metrics, metricId$9, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId$3 === $$event.id) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: $$event.where,
                                                    groupBy: Belt_List.keepU($$event.groupBy, (function (groupBy) {
                                                            return groupBy.id !== id$4;
                                                          }))
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      })),
                openBranches: model$119.openBranches,
                properties: model$119.properties,
                propertyBundles: model$119.propertyBundles,
                sources: model$119.sources
              }
            };
    }
    if (variant === "UpdateTriggerDescription") {
      var match$71 = action.VAL;
      var description$5 = match$71[2];
      var triggerId$2 = match$71[1];
      var eventId$27 = match$71[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEventTrigger(model._0, eventId$27, triggerId$2, (function (trigger) {
                        return {
                                id: trigger.id,
                                sources: trigger.sources,
                                description: description$5,
                                content: trigger.content
                              };
                      }))
              };
      }
      var model$120 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$120.archive,
                branchPointer: model$120.branchPointer,
                migrations: model$120.migrations,
                rules: model$120.rules,
                types: model$120.types,
                categories: model$120.categories,
                destinations: model$120.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$120.events, eventId$27, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.mapU($$event.triggers, (function (trigger) {
                                        if (trigger.id === triggerId$2) {
                                          return {
                                                  id: trigger.id,
                                                  sources: trigger.sources,
                                                  description: description$5,
                                                  content: trigger.content
                                                };
                                        } else {
                                          return trigger;
                                        }
                                      }))
                              };
                      })),
                groupTypes: model$120.groupTypes,
                integrations: model$120.integrations,
                metrics: model$120.metrics,
                openBranches: model$120.openBranches,
                properties: model$120.properties,
                propertyBundles: model$120.propertyBundles,
                sources: model$120.sources
              }
            };
    }
    if (variant === "RemovePropertyValidationMatches") {
      var propertyId$24 = action.VAL;
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updatePropertyValidations(model._0, propertyId$24, (function (param, param$1) {
                        return /* [] */0;
                      }))
              };
      }
      var model$121 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$121.archive,
                branchPointer: model$121.branchPointer,
                migrations: model$121.migrations,
                rules: model$121.rules,
                types: model$121.types,
                categories: model$121.categories,
                destinations: model$121.destinations,
                events: model$121.events,
                groupTypes: model$121.groupTypes,
                integrations: model$121.integrations,
                metrics: model$121.metrics,
                openBranches: model$121.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$121.properties, propertyId$24, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: /* [] */0,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$121.propertyBundles,
                sources: model$121.sources
              }
            };
    }
    if (variant === "AddPropertyToWhitelist") {
      var match$72 = action.VAL;
      var analyticsTool$1 = match$72[2];
      var propertyId$25 = match$72[1];
      var eventId$28 = match$72[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$28, (function ($$event) {
                        var alreadyAdded = Belt_List.someU($$event.propertyWhitelist, (function (param) {
                                if (param[0] === analyticsTool$1) {
                                  return param[1] === propertyId$25;
                                } else {
                                  return false;
                                }
                              }));
                        var exceedsIntercomLimit = Belt_List.length(Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                    return param[0] === "Intercom";
                                  }))) >= AvoConfig.intercomPropertyLimit;
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: alreadyAdded || exceedsIntercomLimit ? $$event.propertyWhitelist : Belt_List.concat($$event.propertyWhitelist, {
                                        hd: [
                                          analyticsTool$1,
                                          propertyId$25
                                        ],
                                        tl: /* [] */0
                                      }),
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$122 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$122.archive,
                branchPointer: model$122.branchPointer,
                migrations: model$122.migrations,
                rules: model$122.rules,
                types: model$122.types,
                categories: model$122.categories,
                destinations: model$122.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$122.events, eventId$28, (function ($$event) {
                        var alreadyAdded = Belt_List.someU($$event.propertyWhitelist, (function (param) {
                                if (param[0] === analyticsTool$1) {
                                  return param[1] === propertyId$25;
                                } else {
                                  return false;
                                }
                              }));
                        var exceedsIntercomLimit = Belt_List.length(Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                    return param[0] === "Intercom";
                                  }))) >= AvoConfig.intercomPropertyLimit;
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: alreadyAdded || exceedsIntercomLimit ? $$event.propertyWhitelist : Belt_List.concat($$event.propertyWhitelist, {
                                        hd: [
                                          analyticsTool$1,
                                          propertyId$25
                                        ],
                                        tl: /* [] */0
                                      }),
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$122.groupTypes,
                integrations: model$122.integrations,
                metrics: model$122.metrics,
                openBranches: model$122.openBranches,
                properties: model$122.properties,
                propertyBundles: model$122.propertyBundles,
                sources: model$122.sources
              }
            };
    }
    if (variant === "IncludeDestinationInSourceV2") {
      var match$73 = action.VAL;
      var eventIds$1 = match$73[2];
      var destinationId$12 = match$73[1];
      var sourceId$19 = match$73[0];
      if (model.TAG === /* Old */0) {
        var model$123 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$123.types,
                  properties: model$123.properties,
                  propertyBundles: model$123.propertyBundles,
                  events: Belt_List.mapU(model$123.events, (function ($$event) {
                          if (!Belt_List.someU(eventIds$1, (function (id) {
                                    return id === $$event.id;
                                  }))) {
                            return $$event;
                          }
                          var includedDestinations = Belt_List.flatten(Belt_List.keepMapU($$event.includeDestinations, (function (includeDestination) {
                                      var match = includeDestination.VAL;
                                      if (match[0] === sourceId$19) {
                                        return match[1];
                                      }
                                      
                                    })));
                          var otherIncludedDestinations = Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                  return includeDestination.VAL[0] !== sourceId$19;
                                }));
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: $$event.includeSources,
                                  includeDestinations: Belt_List.concat(otherIncludedDestinations, {
                                        hd: {
                                          NAME: "IncludeSourceDestination",
                                          VAL: [
                                            sourceId$19,
                                            Belt_List.concat(includedDestinations, {
                                                  hd: destinationId$12,
                                                  tl: /* [] */0
                                                })
                                          ]
                                        },
                                        tl: /* [] */0
                                      }),
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        })),
                  migrations: model$123.migrations,
                  sources: Belt_List.mapU(model$123.sources, (function (source) {
                          if (source.id === sourceId$19) {
                            return {
                                    id: source.id,
                                    name: source.name,
                                    language: source.language,
                                    platform: source.platform,
                                    destinations: Belt_List.concat(source.destinations, {
                                          hd: {
                                            destinationId: destinationId$12,
                                            destinationMode: /* DestinationInterface */1
                                          },
                                          tl: /* [] */0
                                        }),
                                    filename: source.filename,
                                    path: source.path,
                                    segmentDestinationOptions: source.segmentDestinationOptions,
                                    config: source.config
                                  };
                          } else {
                            return source;
                          }
                        })),
                  destinations: model$123.destinations,
                  groupTypes: model$123.groupTypes,
                  goals: model$123.goals,
                  metrics: model$123.metrics,
                  archive: model$123.archive,
                  openBranches: model$123.openBranches,
                  branchPointer: model$123.branchPointer,
                  rules: model$123.rules,
                  integrations: model$123.integrations
                }
              };
      }
      var model$124 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$124.archive,
                branchPointer: model$124.branchPointer,
                migrations: model$124.migrations,
                rules: model$124.rules,
                types: model$124.types,
                categories: model$124.categories,
                destinations: model$124.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.updateMany, model$124.events, Belt_List.toArray(eventIds$1), (function ($$event) {
                        var includedDestinations = Belt_List.flatten(Belt_List.keepMapU($$event.includeDestinations, (function (includeDestination) {
                                    var match = includeDestination.VAL;
                                    if (match[0] === sourceId$19) {
                                      return match[1];
                                    }
                                    
                                  })));
                        var otherIncludedDestinations = Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                return includeDestination.VAL[0] !== sourceId$19;
                              }));
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.concat(otherIncludedDestinations, {
                                      hd: {
                                        NAME: "IncludeSourceDestination",
                                        VAL: [
                                          sourceId$19,
                                          Belt_List.concat(includedDestinations, {
                                                hd: destinationId$12,
                                                tl: /* [] */0
                                              })
                                        ]
                                      },
                                      tl: /* [] */0
                                    }),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$124.groupTypes,
                integrations: model$124.integrations,
                metrics: model$124.metrics,
                openBranches: model$124.openBranches,
                properties: model$124.properties,
                propertyBundles: model$124.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$124.sources, sourceId$19, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: Belt_List.concat(source.destinations, {
                                      hd: {
                                        destinationId: destinationId$12,
                                        destinationMode: /* DestinationInterface */1
                                      },
                                      tl: /* [] */0
                                    }),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "CreateIntegration") {
      var integrationId$3 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$125 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$125.types,
                  properties: model$125.properties,
                  propertyBundles: model$125.propertyBundles,
                  events: model$125.events,
                  migrations: model$125.migrations,
                  sources: model$125.sources,
                  destinations: model$125.destinations,
                  groupTypes: model$125.groupTypes,
                  goals: model$125.goals,
                  metrics: model$125.metrics,
                  archive: model$125.archive,
                  openBranches: model$125.openBranches,
                  branchPointer: model$125.branchPointer,
                  rules: model$125.rules,
                  integrations: Belt_List.concat(model$125.integrations, {
                        hd: TrackingPlanModel.emptyIntegration(integrationId$3),
                        tl: /* [] */0
                      })
                }
              };
      }
      var model$126 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$126.archive,
                branchPointer: model$126.branchPointer,
                migrations: model$126.migrations,
                rules: model$126.rules,
                types: model$126.types,
                categories: model$126.categories,
                destinations: model$126.destinations,
                events: model$126.events,
                groupTypes: model$126.groupTypes,
                integrations: Curry._2(TrackingPlanMappedModel.Integrations.append, model$126.integrations, TrackingPlanModel.emptyIntegration(integrationId$3)),
                metrics: model$126.metrics,
                openBranches: model$126.openBranches,
                properties: model$126.properties,
                propertyBundles: model$126.propertyBundles,
                sources: model$126.sources
              }
            };
    }
    if (variant === "UpdateDestinationAnalyticsTool") {
      var match$74 = action.VAL;
      var analyticsTool$2 = match$74[1];
      var destinationId$13 = match$74[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateDestination(model._0, destinationId$13, (function (destination) {
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: analyticsTool$2,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      }))
              };
      }
      var model$127 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$127.archive,
                branchPointer: model$127.branchPointer,
                migrations: model$127.migrations,
                rules: model$127.rules,
                types: model$127.types,
                categories: model$127.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.update, model$127.destinations, destinationId$13, (function (destination) {
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: analyticsTool$2,
                                apiKeys: destination.apiKeys,
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      })),
                events: model$127.events,
                groupTypes: model$127.groupTypes,
                integrations: model$127.integrations,
                metrics: model$127.metrics,
                openBranches: model$127.openBranches,
                properties: model$127.properties,
                propertyBundles: model$127.propertyBundles,
                sources: model$127.sources
              }
            };
    }
    if (variant === "SetMetricGroupByV2") {
      var match$75 = action.VAL;
      var propertyId$26 = match$75[4];
      var id$5 = match$75[3];
      var metricItemId$4 = match$75[1];
      var metricId$10 = match$75[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateMetric(model._0, metricId$10, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId$4 !== $$event.id) {
                                          return metricItem;
                                        }
                                        var groupBy = $$event.groupBy;
                                        var match = Belt_List.keepU(groupBy, (function (groupBy) {
                                                return groupBy.id === id$5;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: $$event.where,
                                                  groupBy: match ? Belt_List.mapU(groupBy, (function (groupBy) {
                                                            if (groupBy.id === id$5) {
                                                              return {
                                                                      id: id$5,
                                                                      propertyId: propertyId$26
                                                                    };
                                                            } else {
                                                              return groupBy;
                                                            }
                                                          })) : Belt_List.concat(groupBy, {
                                                          hd: {
                                                            id: id$5,
                                                            propertyId: propertyId$26
                                                          },
                                                          tl: /* [] */0
                                                        })
                                                }
                                              };
                                      }))
                              };
                      }))
              };
      }
      var model$128 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$128.archive,
                branchPointer: model$128.branchPointer,
                migrations: model$128.migrations,
                rules: model$128.rules,
                types: model$128.types,
                categories: model$128.categories,
                destinations: model$128.destinations,
                events: model$128.events,
                groupTypes: model$128.groupTypes,
                integrations: model$128.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.update, model$128.metrics, metricId$10, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId$4 !== $$event.id) {
                                          return metricItem;
                                        }
                                        var groupBy = $$event.groupBy;
                                        var match = Belt_List.keepU(groupBy, (function (groupBy) {
                                                return groupBy.id === id$5;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: $$event.where,
                                                  groupBy: match ? Belt_List.mapU(groupBy, (function (groupBy) {
                                                            if (groupBy.id === id$5) {
                                                              return {
                                                                      id: id$5,
                                                                      propertyId: propertyId$26
                                                                    };
                                                            } else {
                                                              return groupBy;
                                                            }
                                                          })) : Belt_List.concat(groupBy, {
                                                          hd: {
                                                            id: id$5,
                                                            propertyId: propertyId$26
                                                          },
                                                          tl: /* [] */0
                                                        })
                                                }
                                              };
                                      }))
                              };
                      })),
                openBranches: model$128.openBranches,
                properties: model$128.properties,
                propertyBundles: model$128.propertyBundles,
                sources: model$128.sources
              }
            };
    }
    if (variant === "RemovePropertyFromGroup") {
      var match$76 = action.VAL;
      var propertyId$27 = match$76[1];
      var groupId$1 = match$76[0];
      if (model.TAG === /* Old */0) {
        var model$129 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$129.types,
                  properties: model$129.properties,
                  propertyBundles: Belt_List.mapU(model$129.propertyBundles, (function (group) {
                          if (group.id === groupId$1) {
                            return {
                                    id: group.id,
                                    name: group.name,
                                    description: group.description,
                                    properties: Belt_List.keepU(group.properties, (function (id) {
                                            return id !== propertyId$27;
                                          }))
                                  };
                          } else {
                            return group;
                          }
                        })),
                  events: Belt_List.mapU(model$129.events, (function ($$event) {
                          var hasGroup = Belt_List.someU($$event.propertyBundles, (function (groupRef) {
                                  return groupRef.id === groupId$1;
                                }));
                          var hasProperty = Belt_List.someU($$event.properties, (function (property) {
                                  return property._0.id === propertyId$27;
                                }));
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: $$event.includeSources,
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: hasGroup && !hasProperty ? Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                            return param[1] !== propertyId$27;
                                          })) : $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        })),
                  migrations: model$129.migrations,
                  sources: model$129.sources,
                  destinations: model$129.destinations,
                  groupTypes: model$129.groupTypes,
                  goals: model$129.goals,
                  metrics: model$129.metrics,
                  archive: model$129.archive,
                  openBranches: model$129.openBranches,
                  branchPointer: model$129.branchPointer,
                  rules: model$129.rules,
                  integrations: model$129.integrations
                }
              };
      }
      var model$130 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$130.archive,
                branchPointer: model$130.branchPointer,
                migrations: model$130.migrations,
                rules: model$130.rules,
                types: model$130.types,
                categories: model$130.categories,
                destinations: model$130.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$130.events, (function ($$event) {
                        var hasGroup = Belt_List.someU($$event.propertyBundles, (function (groupRef) {
                                return groupRef.id === groupId$1;
                              }));
                        var hasProperty = Belt_List.someU($$event.properties, (function (property) {
                                return property._0.id === propertyId$27;
                              }));
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: hasGroup && !hasProperty ? Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                          return param[1] !== propertyId$27;
                                        })) : $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$130.groupTypes,
                integrations: model$130.integrations,
                metrics: model$130.metrics,
                openBranches: model$130.openBranches,
                properties: model$130.properties,
                propertyBundles: Curry._3(TrackingPlanMappedModel.PropertyBundles.update, model$130.propertyBundles, groupId$1, (function (propertyBundle) {
                        return {
                                id: propertyBundle.id,
                                name: propertyBundle.name,
                                description: propertyBundle.description,
                                properties: Belt_List.keepU(propertyBundle.properties, (function (id) {
                                        return id !== propertyId$27;
                                      }))
                              };
                      })),
                sources: model$130.sources
              }
            };
    }
    if (variant === "AddEventToGoal") {
      var match$77 = action.VAL;
      var eventId$29 = match$77[1];
      var goalId$5 = match$77[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateGoal(model._0, goalId$5, (function (goal) {
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: goal.metrics,
                                events: Belt_List.someU(goal.events, (function (id) {
                                        return id === eventId$29;
                                      })) ? goal.events : Belt_List.concat(goal.events, {
                                        hd: eventId$29,
                                        tl: /* [] */0
                                      })
                              };
                      }))
              };
      }
      var model$131 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$131.archive,
                branchPointer: model$131.branchPointer,
                migrations: model$131.migrations,
                rules: model$131.rules,
                types: model$131.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.update, model$131.categories, goalId$5, (function (category) {
                        return {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                metrics: category.metrics,
                                events: Belt_List.someU(category.events, (function (id) {
                                        return id === eventId$29;
                                      })) ? category.events : Belt_List.concat(category.events, {
                                        hd: eventId$29,
                                        tl: /* [] */0
                                      })
                              };
                      })),
                destinations: model$131.destinations,
                events: model$131.events,
                groupTypes: model$131.groupTypes,
                integrations: model$131.integrations,
                metrics: model$131.metrics,
                openBranches: model$131.openBranches,
                properties: model$131.properties,
                propertyBundles: model$131.propertyBundles,
                sources: model$131.sources
              }
            };
    }
    if (variant === "UpdatePropertyValidationMinOrMax") {
      var match$78 = action.VAL;
      var validationIndex$1 = match$78[3];
      var validationType$1 = match$78[2];
      var newValue = match$78[1];
      var propertyId$28 = match$78[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updatePropertyValidations(model._0, propertyId$28, (function (validations, param) {
                        var value = Belt_List.get(validations, validationIndex$1);
                        var updatedValidation;
                        if (value !== undefined) {
                          if (typeof value === "object") {
                            var variant = value.NAME;
                            if (variant === "Min" || variant === "Max") {
                              var match = value.VAL;
                              if (typeof match === "object") {
                                var variant$1 = match.NAME;
                                if (variant$1 === "IntLit") {
                                  var validationValue_1 = Caml_format.caml_int_of_string(newValue);
                                  var validationValue = {
                                    NAME: "IntLit",
                                    VAL: validationValue_1
                                  };
                                  updatedValidation = validationType$1 === "Max" ? ({
                                        NAME: "Max",
                                        VAL: validationValue
                                      }) : (
                                      validationType$1 === "Min" ? ({
                                            NAME: "Min",
                                            VAL: validationValue
                                          }) : Pervasives.failwith("Cannot convert to anything else than Min and Max")
                                    );
                                } else if (variant$1 === "FloatLit") {
                                  var validationValue_1$1 = Caml_format.caml_float_of_string(newValue);
                                  var validationValue$1 = {
                                    NAME: "FloatLit",
                                    VAL: validationValue_1$1
                                  };
                                  updatedValidation = validationType$1 === "Max" ? ({
                                        NAME: "Max",
                                        VAL: validationValue$1
                                      }) : (
                                      validationType$1 === "Min" ? ({
                                            NAME: "Min",
                                            VAL: validationValue$1
                                          }) : Pervasives.failwith("Cannot convert to anything else than Min and Max")
                                    );
                                } else {
                                  updatedValidation = value;
                                }
                              } else {
                                updatedValidation = value;
                              }
                            } else {
                              updatedValidation = value;
                            }
                          } else {
                            updatedValidation = value;
                          }
                        } else {
                          updatedValidation = Pervasives.failwith("Invalid index");
                        }
                        return BeltListExtensions.setValueAtIndex(validationIndex$1, updatedValidation, validations);
                      }))
              };
      }
      var model$132 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$132.archive,
                branchPointer: model$132.branchPointer,
                migrations: model$132.migrations,
                rules: model$132.rules,
                types: model$132.types,
                categories: model$132.categories,
                destinations: model$132.destinations,
                events: model$132.events,
                groupTypes: model$132.groupTypes,
                integrations: model$132.integrations,
                metrics: model$132.metrics,
                openBranches: model$132.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$132.properties, propertyId$28, (function (property) {
                        var value = Belt_List.get(property.validations, validationIndex$1);
                        var updatedValidation;
                        if (value !== undefined) {
                          if (typeof value === "object") {
                            var variant = value.NAME;
                            if (variant === "Min" || variant === "Max") {
                              var match = value.VAL;
                              if (typeof match === "object") {
                                var variant$1 = match.NAME;
                                if (variant$1 === "IntLit") {
                                  var validationValue_1 = Caml_format.caml_int_of_string(newValue);
                                  var validationValue = {
                                    NAME: "IntLit",
                                    VAL: validationValue_1
                                  };
                                  updatedValidation = validationType$1 === "Max" ? ({
                                        NAME: "Max",
                                        VAL: validationValue
                                      }) : (
                                      validationType$1 === "Min" ? ({
                                            NAME: "Min",
                                            VAL: validationValue
                                          }) : Pervasives.failwith("Cannot convert to anything else than Min and Max")
                                    );
                                } else if (variant$1 === "FloatLit") {
                                  var validationValue_1$1 = Caml_format.caml_float_of_string(newValue);
                                  var validationValue$1 = {
                                    NAME: "FloatLit",
                                    VAL: validationValue_1$1
                                  };
                                  updatedValidation = validationType$1 === "Max" ? ({
                                        NAME: "Max",
                                        VAL: validationValue$1
                                      }) : (
                                      validationType$1 === "Min" ? ({
                                            NAME: "Min",
                                            VAL: validationValue$1
                                          }) : Pervasives.failwith("Cannot convert to anything else than Min and Max")
                                    );
                                } else {
                                  updatedValidation = value;
                                }
                              } else {
                                updatedValidation = value;
                              }
                            } else {
                              updatedValidation = value;
                            }
                          } else {
                            updatedValidation = value;
                          }
                        } else {
                          updatedValidation = Pervasives.failwith("Invalid index");
                        }
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: BeltListExtensions.setValueAtIndex(validationIndex$1, updatedValidation, property.validations),
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$132.propertyBundles,
                sources: model$132.sources
              }
            };
    }
    if (variant === "AddTriggerSource") {
      var match$79 = action.VAL;
      var sourceId$20 = match$79[2];
      var triggerId$3 = match$79[1];
      var eventId$30 = match$79[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEventTrigger(model._0, eventId$30, triggerId$3, (function (trigger) {
                        var sourceIds = trigger.sources;
                        var tmp;
                        if (sourceIds) {
                          var sourceIds$1 = sourceIds._0;
                          tmp = sourceIds$1.includes(sourceId$20) ? /* SourceIds */({
                                _0: sourceIds$1
                              }) : /* SourceIds */({
                                _0: Belt_Array.concat(sourceIds$1, [sourceId$20])
                              });
                        } else {
                          tmp = /* SourceIds */{
                            _0: [sourceId$20]
                          };
                        }
                        return {
                                id: trigger.id,
                                sources: tmp,
                                description: trigger.description,
                                content: trigger.content
                              };
                      }))
              };
      }
      var model$133 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$133.archive,
                branchPointer: model$133.branchPointer,
                migrations: model$133.migrations,
                rules: model$133.rules,
                types: model$133.types,
                categories: model$133.categories,
                destinations: model$133.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$133.events, eventId$30, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.mapU($$event.triggers, (function (trigger) {
                                        if (trigger.id !== triggerId$3) {
                                          return trigger;
                                        }
                                        var sourceIds = trigger.sources;
                                        var tmp;
                                        if (sourceIds) {
                                          var sourceIds$1 = sourceIds._0;
                                          tmp = sourceIds$1.includes(sourceId$20) ? /* SourceIds */({
                                                _0: sourceIds$1
                                              }) : /* SourceIds */({
                                                _0: Belt_Array.concat(sourceIds$1, [sourceId$20])
                                              });
                                        } else {
                                          tmp = /* SourceIds */{
                                            _0: [sourceId$20]
                                          };
                                        }
                                        return {
                                                id: trigger.id,
                                                sources: tmp,
                                                description: trigger.description,
                                                content: trigger.content
                                              };
                                      }))
                              };
                      })),
                groupTypes: model$133.groupTypes,
                integrations: model$133.integrations,
                metrics: model$133.metrics,
                openBranches: model$133.openBranches,
                properties: model$133.properties,
                propertyBundles: model$133.propertyBundles,
                sources: model$133.sources
              }
            };
    }
    if (variant === "IncludeDestinationInEventSource") {
      var match$80 = action.VAL;
      var destinationId$14 = match$80[2];
      var sourceId$21 = match$80[1];
      var eventId$31 = match$80[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$31, (function ($$event) {
                        var includedDestinations = Belt_List.flatten(Belt_List.keepMapU($$event.includeDestinations, (function (includeDestination) {
                                    var match = includeDestination.VAL;
                                    if (match[0] === sourceId$21) {
                                      return match[1];
                                    }
                                    
                                  })));
                        var otherIncludedDestinations = Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                return includeDestination.VAL[0] !== sourceId$21;
                              }));
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.concat(otherIncludedDestinations, {
                                      hd: {
                                        NAME: "IncludeSourceDestination",
                                        VAL: [
                                          sourceId$21,
                                          Belt_List.has(includedDestinations, destinationId$14, (function (prim0, prim1) {
                                                  return prim0 === prim1;
                                                })) ? includedDestinations : Belt_List.concat(includedDestinations, {
                                                  hd: destinationId$14,
                                                  tl: /* [] */0
                                                })
                                        ]
                                      },
                                      tl: /* [] */0
                                    }),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$134 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$134.archive,
                branchPointer: model$134.branchPointer,
                migrations: model$134.migrations,
                rules: model$134.rules,
                types: model$134.types,
                categories: model$134.categories,
                destinations: model$134.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$134.events, eventId$31, (function ($$event) {
                        var includedDestinations = Belt_List.flatten(Belt_List.keepMapU($$event.includeDestinations, (function (includeDestination) {
                                    var match = includeDestination.VAL;
                                    if (match[0] === sourceId$21) {
                                      return match[1];
                                    }
                                    
                                  })));
                        var otherIncludedDestinations = Belt_List.keepU($$event.includeDestinations, (function (includeDestination) {
                                return includeDestination.VAL[0] !== sourceId$21;
                              }));
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.concat(otherIncludedDestinations, {
                                      hd: {
                                        NAME: "IncludeSourceDestination",
                                        VAL: [
                                          sourceId$21,
                                          Belt_List.has(includedDestinations, destinationId$14, (function (prim0, prim1) {
                                                  return prim0 === prim1;
                                                })) ? includedDestinations : Belt_List.concat(includedDestinations, {
                                                  hd: destinationId$14,
                                                  tl: /* [] */0
                                                })
                                        ]
                                      },
                                      tl: /* [] */0
                                    }),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$134.groupTypes,
                integrations: model$134.integrations,
                metrics: model$134.metrics,
                openBranches: model$134.openBranches,
                properties: model$134.properties,
                propertyBundles: model$134.propertyBundles,
                sources: model$134.sources
              }
            };
    }
    if (variant === "AddGroupTypeToLogEvent") {
      var match$81 = action.VAL;
      var groupTypeId$3 = match$81[1];
      var eventId$32 = match$81[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$32, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: Belt_Array.concat($$event.eventGroupTypeIdsWithArchive, [groupTypeId$3]),
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$135 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$135.archive,
                branchPointer: model$135.branchPointer,
                migrations: model$135.migrations,
                rules: model$135.rules,
                types: model$135.types,
                categories: model$135.categories,
                destinations: model$135.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$135.events, eventId$32, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: Belt_Array.concat($$event.eventGroupTypeIdsWithArchive, [groupTypeId$3]),
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$135.groupTypes,
                integrations: model$135.integrations,
                metrics: model$135.metrics,
                openBranches: model$135.openBranches,
                properties: model$135.properties,
                propertyBundles: model$135.propertyBundles,
                sources: model$135.sources
              }
            };
    }
    if (variant === "UpdatePropertyPinnedValue") {
      var match$82 = action.VAL;
      var pinnedValue$1 = match$82[2];
      var propertyId$29 = match$82[1];
      var eventId$33 = match$82[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$33, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.map($$event.properties, (function (property) {
                                        if (property.TAG !== /* PropertyRef */0) {
                                          return property;
                                        }
                                        var propRef = property._0;
                                        if (propRef.id === propertyId$29) {
                                          return {
                                                  TAG: /* PropertyRef */0,
                                                  _0: {
                                                    id: propRef.id,
                                                    description: propRef.description,
                                                    pinnedValue: pinnedValue$1
                                                  }
                                                };
                                        } else {
                                          return property;
                                        }
                                      })),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$136 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$136.archive,
                branchPointer: model$136.branchPointer,
                migrations: model$136.migrations,
                rules: model$136.rules,
                types: model$136.types,
                categories: model$136.categories,
                destinations: model$136.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$136.events, eventId$33, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.map($$event.properties, (function (property) {
                                        if (property.TAG !== /* PropertyRef */0) {
                                          return property;
                                        }
                                        var propRef = property._0;
                                        if (propRef.id === propertyId$29) {
                                          return {
                                                  TAG: /* PropertyRef */0,
                                                  _0: {
                                                    id: propRef.id,
                                                    description: propRef.description,
                                                    pinnedValue: pinnedValue$1
                                                  }
                                                };
                                        } else {
                                          return property;
                                        }
                                      })),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$136.groupTypes,
                integrations: model$136.integrations,
                metrics: model$136.metrics,
                openBranches: model$136.openBranches,
                properties: model$136.properties,
                propertyBundles: model$136.propertyBundles,
                sources: model$136.sources
              }
            };
    }
    if (variant === "RemoveUserFromGroup") {
      var match$83 = action.VAL;
      var groupTypeIdToRemove = match$83[1];
      var eventId$34 = match$83[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$34, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: Belt_Array.keep($$event.userGroupTypeIdsWithArchive, (function (groupTypeId) {
                                        return groupTypeId !== groupTypeIdToRemove;
                                      })),
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$137 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$137.archive,
                branchPointer: model$137.branchPointer,
                migrations: model$137.migrations,
                rules: model$137.rules,
                types: model$137.types,
                categories: model$137.categories,
                destinations: model$137.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$137.events, eventId$34, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: Belt_Array.keep($$event.userGroupTypeIdsWithArchive, (function (groupTypeId) {
                                        return groupTypeId !== groupTypeIdToRemove;
                                      })),
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$137.groupTypes,
                integrations: model$137.integrations,
                metrics: model$137.metrics,
                openBranches: model$137.openBranches,
                properties: model$137.properties,
                propertyBundles: model$137.propertyBundles,
                sources: model$137.sources
              }
            };
    }
    if (variant === "RemovePropertyValidation") {
      var match$84 = action.VAL;
      var validation = match$84[1];
      var propertyId$30 = match$84[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateProperty(model._0, propertyId$30, (function (property) {
                        var variant = validation.NAME;
                        var tmp;
                        if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
                          tmp = Pervasives.failwith("Unsupported validation type.");
                        } else if (variant === "Max") {
                          tmp = Belt_List.keepU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME !== "Max";
                                  } else {
                                    return true;
                                  }
                                }));
                        } else if (variant === "Min") {
                          tmp = Belt_List.keepU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME !== "Min";
                                  } else {
                                    return true;
                                  }
                                }));
                        } else if (variant === "NestedProperty") {
                          var removePropertyRefs = validation.VAL;
                          tmp = Belt_List.keepMapU(property.validations, (function (validation) {
                                  if (typeof validation !== "object") {
                                    return validation;
                                  }
                                  if (validation.NAME !== "NestedProperty") {
                                    return validation;
                                  }
                                  var nextPropertyRefs = Belt_List.keepU(validation.VAL, (function (propertyRef) {
                                          return Belt_List.someU(removePropertyRefs, (function (removePropertyRef) {
                                                        return removePropertyRef.id !== propertyRef.id;
                                                      }));
                                        }));
                                  return {
                                          NAME: "NestedProperty",
                                          VAL: nextPropertyRefs
                                        };
                                }));
                        } else if (variant === "Shape") {
                          var removeItems = validation.VAL;
                          tmp = Belt_List.keepMapU(property.validations, (function (validation) {
                                  if (typeof validation !== "object") {
                                    return validation;
                                  }
                                  if (validation.NAME !== "Shape") {
                                    return validation;
                                  }
                                  var nextItems = Belt_List.keepU(validation.VAL, (function (item) {
                                          return Belt_List.someU(removeItems, (function (removeItem) {
                                                        return removeItem.key !== item.key;
                                                      }));
                                        }));
                                  if (Belt_List.length(nextItems) === 0) {
                                    return ;
                                  } else {
                                    return {
                                            NAME: "Shape",
                                            VAL: nextItems
                                          };
                                  }
                                }));
                        } else {
                          var removeMatches = validation.VAL;
                          tmp = removeMatches ? Belt_List.keepMapU(property.validations, (function (validation) {
                                    if (typeof validation !== "object") {
                                      return validation;
                                    }
                                    if (validation.NAME !== "Matches") {
                                      return validation;
                                    }
                                    var nextMatches = Belt_List.keepU(validation.VAL, (function (match_) {
                                            return Belt_List.has(removeMatches, match_, Caml_obj.caml_notequal);
                                          }));
                                    if (Belt_List.length(nextMatches) === 0) {
                                      return ;
                                    } else {
                                      return {
                                              NAME: "Matches",
                                              VAL: nextMatches
                                            };
                                    }
                                  })) : Belt_List.keepU(property.validations, (function (item) {
                                    if (typeof item === "object") {
                                      return item.NAME !== "Matches";
                                    } else {
                                      return true;
                                    }
                                  }));
                        }
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: tmp,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      }))
              };
      }
      var model$138 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$138.archive,
                branchPointer: model$138.branchPointer,
                migrations: model$138.migrations,
                rules: model$138.rules,
                types: model$138.types,
                categories: model$138.categories,
                destinations: model$138.destinations,
                events: model$138.events,
                groupTypes: model$138.groupTypes,
                integrations: model$138.integrations,
                metrics: model$138.metrics,
                openBranches: model$138.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$138.properties, propertyId$30, (function (property) {
                        var variant = validation.NAME;
                        var tmp;
                        if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
                          tmp = Pervasives.failwith("Unsupported validation type.");
                        } else if (variant === "Max") {
                          tmp = Belt_List.keepU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME !== "Max";
                                  } else {
                                    return true;
                                  }
                                }));
                        } else if (variant === "Min") {
                          tmp = Belt_List.keepU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME !== "Min";
                                  } else {
                                    return true;
                                  }
                                }));
                        } else if (variant === "NestedProperty") {
                          var removePropertyRefs = validation.VAL;
                          tmp = Belt_List.keepMapU(property.validations, (function (validation) {
                                  if (typeof validation !== "object") {
                                    return validation;
                                  }
                                  if (validation.NAME !== "NestedProperty") {
                                    return validation;
                                  }
                                  var nextPropertyRefs = Belt_List.keepU(validation.VAL, (function (propertyRef) {
                                          return Belt_List.someU(removePropertyRefs, (function (removePropertyRef) {
                                                        return removePropertyRef.id !== propertyRef.id;
                                                      }));
                                        }));
                                  return {
                                          NAME: "NestedProperty",
                                          VAL: nextPropertyRefs
                                        };
                                }));
                        } else if (variant === "Shape") {
                          var removeItems = validation.VAL;
                          tmp = Belt_List.keepMapU(property.validations, (function (validation) {
                                  if (typeof validation !== "object") {
                                    return validation;
                                  }
                                  if (validation.NAME !== "Shape") {
                                    return validation;
                                  }
                                  var nextItems = Belt_List.keepU(validation.VAL, (function (item) {
                                          return Belt_List.someU(removeItems, (function (removeItem) {
                                                        return removeItem.key !== item.key;
                                                      }));
                                        }));
                                  if (Belt_List.length(nextItems) === 0) {
                                    return ;
                                  } else {
                                    return {
                                            NAME: "Shape",
                                            VAL: nextItems
                                          };
                                  }
                                }));
                        } else {
                          var removeMatches = validation.VAL;
                          tmp = removeMatches ? Belt_List.keepMapU(property.validations, (function (validation) {
                                    if (typeof validation !== "object") {
                                      return validation;
                                    }
                                    if (validation.NAME !== "Matches") {
                                      return validation;
                                    }
                                    var nextMatches = Belt_List.keepU(validation.VAL, (function (match_) {
                                            return Belt_List.has(removeMatches, match_, Caml_obj.caml_notequal);
                                          }));
                                    if (Belt_List.length(nextMatches) === 0) {
                                      return ;
                                    } else {
                                      return {
                                              NAME: "Matches",
                                              VAL: nextMatches
                                            };
                                    }
                                  })) : Belt_List.keepU(property.validations, (function (item) {
                                    if (typeof item === "object") {
                                      return item.NAME !== "Matches";
                                    } else {
                                      return true;
                                    }
                                  }));
                        }
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: tmp,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$138.propertyBundles,
                sources: model$138.sources
              }
            };
    }
    if (variant === "UpdateGoalName") {
      var match$85 = action.VAL;
      var name$6 = match$85[1];
      var goalId$6 = match$85[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateGoal(model._0, goalId$6, (function (goal) {
                        return {
                                id: goal.id,
                                name: name$6,
                                description: goal.description,
                                metrics: goal.metrics,
                                events: goal.events
                              };
                      }))
              };
      }
      var model$139 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$139.archive,
                branchPointer: model$139.branchPointer,
                migrations: model$139.migrations,
                rules: model$139.rules,
                types: model$139.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.update, model$139.categories, goalId$6, (function (category) {
                        return {
                                id: category.id,
                                name: name$6,
                                description: category.description,
                                metrics: category.metrics,
                                events: category.events
                              };
                      })),
                destinations: model$139.destinations,
                events: model$139.events,
                groupTypes: model$139.groupTypes,
                integrations: model$139.integrations,
                metrics: model$139.metrics,
                openBranches: model$139.openBranches,
                properties: model$139.properties,
                propertyBundles: model$139.propertyBundles,
                sources: model$139.sources
              }
            };
    }
    if (variant === "DuplicateEvent") {
      var match$86 = action.VAL;
      var ruleIdTuples = match$86[2];
      var triggerIdTuples = match$86[1];
      var match$87 = match$86[0];
      var toEventId = match$87[1];
      var fromEventId = match$87[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.duplicateEventAndEnrichModel(fromEventId, toEventId, triggerIdTuples, ruleIdTuples, model._0)
              };
      }
      var model$140 = model._0;
      var eventToDuplicate = StateUtils.getEventByIdFromArchive_mappedModel(model$140, fromEventId);
      if (eventToDuplicate === undefined) {
        return model;
      }
      var duplicatedEvent = TrackingPlanModel.duplicateEvent(eventToDuplicate, toEventId, triggerIdTuples, Belt_List.fromArray(Curry._1(TrackingPlanMappedModel.Events.toArray, model$140.events)));
      var rulesForDuplicatedEvent = Belt_Array.keepMap(ruleIdTuples, (function (param) {
              var toRuleId = param[1];
              var fromRuleId = param[0];
              return Belt_Option.flatMap(Belt_Array.getBy(model$140.rules, (function (param) {
                                return param.id === fromRuleId;
                              })), (function (rule) {
                            var item = rule.item;
                            var variant = item.NAME;
                            if (variant === "PropertyRef") {
                              var match = item.VAL;
                              if (match[0] === fromEventId) {
                                return {
                                        id: toRuleId,
                                        item: {
                                          NAME: "PropertyRef",
                                          VAL: [
                                            duplicatedEvent.id,
                                            match[1]
                                          ]
                                        },
                                        definition: rule.definition
                                      };
                              } else {
                                return ;
                              }
                            }
                            if (variant === "Property") {
                              return ;
                            }
                            if (variant === "Event") {
                              if (item.VAL === fromEventId) {
                                return {
                                        id: toRuleId,
                                        item: {
                                          NAME: "Event",
                                          VAL: duplicatedEvent.id
                                        },
                                        definition: rule.definition
                                      };
                              } else {
                                return ;
                              }
                            }
                            var match$1 = item.VAL;
                            if (match$1[0] === fromEventId) {
                              return {
                                      id: toRuleId,
                                      item: {
                                        NAME: "EventObjectField",
                                        VAL: [
                                          duplicatedEvent.id,
                                          match$1[1],
                                          match$1[2]
                                        ]
                                      },
                                      definition: rule.definition
                                    };
                            }
                            
                          }));
            }));
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$140.archive,
                branchPointer: model$140.branchPointer,
                migrations: model$140.migrations,
                rules: Belt_Array.concat(model$140.rules, rulesForDuplicatedEvent),
                types: model$140.types,
                categories: Curry._2(TrackingPlanMappedModel.Categories.mapToT, model$140.categories, (function (category) {
                        if (Belt_List.has(category.events, fromEventId, (function (prim0, prim1) {
                                  return prim0 === prim1;
                                }))) {
                          return {
                                  id: category.id,
                                  name: category.name,
                                  description: category.description,
                                  metrics: category.metrics,
                                  events: Belt_List.concat(category.events, {
                                        hd: duplicatedEvent.id,
                                        tl: /* [] */0
                                      })
                                };
                        } else {
                          return category;
                        }
                      })),
                destinations: model$140.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.append, model$140.events, duplicatedEvent),
                groupTypes: model$140.groupTypes,
                integrations: model$140.integrations,
                metrics: model$140.metrics,
                openBranches: model$140.openBranches,
                properties: model$140.properties,
                propertyBundles: model$140.propertyBundles,
                sources: model$140.sources
              }
            };
    }
    if (variant === "AddPropertyValidation") {
      var match$88 = action.VAL;
      var validation$1 = match$88[1];
      var propertyId$31 = match$88[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateProperty(model._0, propertyId$31, (function (property) {
                        var variant = validation$1.NAME;
                        var tmp;
                        if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
                          tmp = Pervasives.failwith("Unsupported validation type.");
                        } else if (variant === "Max") {
                          var maybeMax = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Max";
                                  } else {
                                    return false;
                                  }
                                }));
                          tmp = maybeMax !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item === "object" && item.NAME === "Max") {
                                      return validation$1;
                                    } else {
                                      return item;
                                    }
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else if (variant === "Min") {
                          var maybeMin = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Min";
                                  } else {
                                    return false;
                                  }
                                }));
                          tmp = maybeMin !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item === "object" && item.NAME === "Min") {
                                      return validation$1;
                                    } else {
                                      return item;
                                    }
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else if (variant === "NestedProperty") {
                          var newPropertyRefs = validation$1.VAL;
                          var maybeShape = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "NestedProperty";
                                  } else {
                                    return false;
                                  }
                                }));
                          tmp = maybeShape !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item !== "object") {
                                      return item;
                                    }
                                    if (item.NAME !== "NestedProperty") {
                                      return item;
                                    }
                                    var currentPropertyRefs = item.VAL;
                                    var addingPropertyRefs = Belt_List.keepU(newPropertyRefs, (function (newPropertyRef) {
                                            return !Belt_List.someU(currentPropertyRefs, (function (currentPropertyRef) {
                                                          return currentPropertyRef.id === newPropertyRef.id;
                                                        }));
                                          }));
                                    var currentPropertyRefs$1 = Belt_List.mapU(currentPropertyRefs, (function (currentPropertyRef) {
                                            var updatedPropertyRef = Belt_List.getByU(newPropertyRefs, (function (newPropertyRef) {
                                                    return currentPropertyRef.id === newPropertyRef.id;
                                                  }));
                                            return Belt_Option.getWithDefault(updatedPropertyRef, currentPropertyRef);
                                          }));
                                    var nextPropertyRefs = Belt_List.concat(currentPropertyRefs$1, addingPropertyRefs);
                                    return {
                                            NAME: "NestedProperty",
                                            VAL: nextPropertyRefs
                                          };
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else if (variant === "Shape") {
                          var newItemTypes = validation$1.VAL;
                          var maybeShape$1 = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Shape";
                                  } else {
                                    return false;
                                  }
                                }));
                          tmp = maybeShape$1 !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item !== "object") {
                                      return item;
                                    }
                                    if (item.NAME !== "Shape") {
                                      return item;
                                    }
                                    var currentItemTypes = item.VAL;
                                    var addingItemTypes = Belt_List.keepU(newItemTypes, (function (newItemType) {
                                            return !Belt_List.someU(currentItemTypes, (function (currentItemType) {
                                                          return currentItemType.key === newItemType.key;
                                                        }));
                                          }));
                                    var currentItemTypes$1 = Belt_List.mapU(currentItemTypes, (function (currentItemType) {
                                            var updatedItemType = Belt_List.getByU(newItemTypes, (function (newItemType) {
                                                    return currentItemType.key === newItemType.key;
                                                  }));
                                            return Belt_Option.getWithDefault(updatedItemType, currentItemType);
                                          }));
                                    var nextItemTypes = Belt_List.concat(currentItemTypes$1, addingItemTypes);
                                    return {
                                            NAME: "Shape",
                                            VAL: nextItemTypes
                                          };
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else {
                          var newMatches = validation$1.VAL;
                          var maybeMatches = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Matches";
                                  } else {
                                    return false;
                                  }
                                }));
                          tmp = maybeMatches !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item !== "object") {
                                      return item;
                                    }
                                    if (item.NAME !== "Matches") {
                                      return item;
                                    }
                                    var currentMatches = item.VAL;
                                    var newMatches$1 = Belt_List.keepU(newMatches, (function (match_) {
                                            return !Belt_List.has(currentMatches, match_, Caml_obj.caml_equal);
                                          }));
                                    var nextMatches = Belt_List.concat(currentMatches, newMatches$1);
                                    return {
                                            NAME: "Matches",
                                            VAL: nextMatches
                                          };
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        }
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: tmp,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      }))
              };
      }
      var model$141 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$141.archive,
                branchPointer: model$141.branchPointer,
                migrations: model$141.migrations,
                rules: model$141.rules,
                types: model$141.types,
                categories: model$141.categories,
                destinations: model$141.destinations,
                events: model$141.events,
                groupTypes: model$141.groupTypes,
                integrations: model$141.integrations,
                metrics: model$141.metrics,
                openBranches: model$141.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$141.properties, propertyId$31, (function (property) {
                        var variant = validation$1.NAME;
                        var tmp;
                        if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
                          tmp = Pervasives.failwith("Unsupported validation type.");
                        } else if (variant === "Max") {
                          var maybeMax = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Max";
                                  } else {
                                    return false;
                                  }
                                }));
                          tmp = maybeMax !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item === "object" && item.NAME === "Max") {
                                      return validation$1;
                                    } else {
                                      return item;
                                    }
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else if (variant === "Min") {
                          var maybeMin = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Min";
                                  } else {
                                    return false;
                                  }
                                }));
                          tmp = maybeMin !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item === "object" && item.NAME === "Min") {
                                      return validation$1;
                                    } else {
                                      return item;
                                    }
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else if (variant === "NestedProperty") {
                          var newPropertyRefs = validation$1.VAL;
                          var maybeShape = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "NestedProperty";
                                  } else {
                                    return false;
                                  }
                                }));
                          tmp = maybeShape !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item !== "object") {
                                      return item;
                                    }
                                    if (item.NAME !== "NestedProperty") {
                                      return item;
                                    }
                                    var currentPropertyRefs = item.VAL;
                                    var addingPropertyRefs = Belt_List.keepU(newPropertyRefs, (function (newPropertyRef) {
                                            return !Belt_List.someU(currentPropertyRefs, (function (currentPropertyRef) {
                                                          return currentPropertyRef.id === newPropertyRef.id;
                                                        }));
                                          }));
                                    var currentPropertyRefs$1 = Belt_List.mapU(currentPropertyRefs, (function (currentPropertyRef) {
                                            var updatedPropertyRef = Belt_List.getByU(newPropertyRefs, (function (newPropertyRef) {
                                                    return currentPropertyRef.id === newPropertyRef.id;
                                                  }));
                                            return Belt_Option.getWithDefault(updatedPropertyRef, currentPropertyRef);
                                          }));
                                    var nextPropertyRefs = Belt_List.concat(currentPropertyRefs$1, addingPropertyRefs);
                                    return {
                                            NAME: "NestedProperty",
                                            VAL: nextPropertyRefs
                                          };
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else if (variant === "Shape") {
                          var newItemTypes = validation$1.VAL;
                          var maybeShape$1 = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Shape";
                                  } else {
                                    return false;
                                  }
                                }));
                          tmp = maybeShape$1 !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item !== "object") {
                                      return item;
                                    }
                                    if (item.NAME !== "Shape") {
                                      return item;
                                    }
                                    var currentItemTypes = item.VAL;
                                    var addingItemTypes = Belt_List.keepU(newItemTypes, (function (newItemType) {
                                            return !Belt_List.someU(currentItemTypes, (function (currentItemType) {
                                                          return currentItemType.key === newItemType.key;
                                                        }));
                                          }));
                                    var currentItemTypes$1 = Belt_List.mapU(currentItemTypes, (function (currentItemType) {
                                            var updatedItemType = Belt_List.getByU(newItemTypes, (function (newItemType) {
                                                    return currentItemType.key === newItemType.key;
                                                  }));
                                            return Belt_Option.getWithDefault(updatedItemType, currentItemType);
                                          }));
                                    var nextItemTypes = Belt_List.concat(currentItemTypes$1, addingItemTypes);
                                    return {
                                            NAME: "Shape",
                                            VAL: nextItemTypes
                                          };
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        } else {
                          var newMatches = validation$1.VAL;
                          var maybeMatches = Belt_List.getByU(property.validations, (function (item) {
                                  if (typeof item === "object") {
                                    return item.NAME === "Matches";
                                  } else {
                                    return false;
                                  }
                                }));
                          tmp = maybeMatches !== undefined ? Belt_List.mapU(property.validations, (function (item) {
                                    if (typeof item !== "object") {
                                      return item;
                                    }
                                    if (item.NAME !== "Matches") {
                                      return item;
                                    }
                                    var currentMatches = item.VAL;
                                    var newMatches$1 = Belt_List.keepU(newMatches, (function (match_) {
                                            return !Belt_List.has(currentMatches, match_, Caml_obj.caml_equal);
                                          }));
                                    var nextMatches = Belt_List.concat(currentMatches, newMatches$1);
                                    return {
                                            NAME: "Matches",
                                            VAL: nextMatches
                                          };
                                  })) : Belt_List.concat(property.validations, {
                                  hd: validation$1,
                                  tl: /* [] */0
                                });
                        }
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: tmp,
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$141.propertyBundles,
                sources: model$141.sources
              }
            };
    }
    if (variant === "PullMaster") {
      var match$89 = action.VAL;
      var actions$2 = match$89[3];
      var masterActionTimestamp = match$89[2];
      var masterActionId = match$89[1];
      if (model.TAG === /* Old */0) {
        var nextModel$3 = TrackingPlanMappedModel.unboxModel(Belt_List.reduceU(actions$2, model, reduceFunction));
        return {
                TAG: /* Old */0,
                _0: {
                  types: nextModel$3.types,
                  properties: nextModel$3.properties,
                  propertyBundles: nextModel$3.propertyBundles,
                  events: nextModel$3.events,
                  migrations: nextModel$3.migrations,
                  sources: nextModel$3.sources,
                  destinations: nextModel$3.destinations,
                  groupTypes: nextModel$3.groupTypes,
                  goals: nextModel$3.goals,
                  metrics: nextModel$3.metrics,
                  archive: nextModel$3.archive,
                  openBranches: nextModel$3.openBranches,
                  branchPointer: [
                    masterActionId,
                    masterActionTimestamp
                  ],
                  rules: nextModel$3.rules,
                  integrations: nextModel$3.integrations
                }
              };
      }
      var nextModel$4 = TrackingPlanMappedModel.unboxModel(Belt_List.reduceU(actions$2, model, reduceFunction));
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: nextModel$4.archive,
                branchPointer: [
                  masterActionId,
                  masterActionTimestamp
                ],
                migrations: nextModel$4.migrations,
                rules: nextModel$4.rules,
                types: nextModel$4.types,
                categories: nextModel$4.categories,
                destinations: nextModel$4.destinations,
                events: nextModel$4.events,
                groupTypes: nextModel$4.groupTypes,
                integrations: nextModel$4.integrations,
                metrics: nextModel$4.metrics,
                openBranches: nextModel$4.openBranches,
                properties: nextModel$4.properties,
                propertyBundles: nextModel$4.propertyBundles,
                sources: nextModel$4.sources
              }
            };
    }
    if (variant === "UpdateMetricName") {
      var match$90 = action.VAL;
      var name$7 = match$90[1];
      var metricId$11 = match$90[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateMetric(model._0, metricId$11, (function (metric) {
                        return {
                                id: metric.id,
                                name: name$7,
                                description: metric.description,
                                type_: metric.type_,
                                items: metric.items
                              };
                      }))
              };
      }
      var model$142 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$142.archive,
                branchPointer: model$142.branchPointer,
                migrations: model$142.migrations,
                rules: model$142.rules,
                types: model$142.types,
                categories: model$142.categories,
                destinations: model$142.destinations,
                events: model$142.events,
                groupTypes: model$142.groupTypes,
                integrations: model$142.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.update, model$142.metrics, metricId$11, (function (metric) {
                        return {
                                id: metric.id,
                                name: name$7,
                                description: metric.description,
                                type_: metric.type_,
                                items: metric.items
                              };
                      })),
                openBranches: model$142.openBranches,
                properties: model$142.properties,
                propertyBundles: model$142.propertyBundles,
                sources: model$142.sources
              }
            };
    }
    if (variant === "RemoveNestedPropertyPinnedValue") {
      var match$91 = action.VAL;
      var nestedPropertyId$1 = match$91[1];
      var propertyId$32 = match$91[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateProperty(model._0, propertyId$32, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: Belt_List.map(property.validations, (function (x) {
                                        if (typeof x === "object" && x.NAME === "NestedProperty") {
                                          return {
                                                  NAME: "NestedProperty",
                                                  VAL: Belt_List.map(x.VAL, (function (propertyRef) {
                                                          if (propertyRef.id === nestedPropertyId$1) {
                                                            return {
                                                                    id: propertyRef.id,
                                                                    description: propertyRef.description,
                                                                    pinnedValue: undefined
                                                                  };
                                                          } else {
                                                            return propertyRef;
                                                          }
                                                        }))
                                                };
                                        } else {
                                          return x;
                                        }
                                      })),
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      }))
              };
      }
      var model$143 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$143.archive,
                branchPointer: model$143.branchPointer,
                migrations: model$143.migrations,
                rules: model$143.rules,
                types: model$143.types,
                categories: model$143.categories,
                destinations: model$143.destinations,
                events: model$143.events,
                groupTypes: model$143.groupTypes,
                integrations: model$143.integrations,
                metrics: model$143.metrics,
                openBranches: model$143.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$143.properties, propertyId$32, (function (property) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueName: property.uniqueName,
                                description: property.description,
                                type_: property.type_,
                                sendAs: property.sendAs,
                                validations: Belt_List.map(property.validations, (function (x) {
                                        if (typeof x === "object" && x.NAME === "NestedProperty") {
                                          return {
                                                  NAME: "NestedProperty",
                                                  VAL: Belt_List.map(x.VAL, (function (propertyRef) {
                                                          if (propertyRef.id === nestedPropertyId$1) {
                                                            return {
                                                                    id: propertyRef.id,
                                                                    description: propertyRef.description,
                                                                    pinnedValue: undefined
                                                                  };
                                                          } else {
                                                            return propertyRef;
                                                          }
                                                        }))
                                                };
                                        } else {
                                          return x;
                                        }
                                      })),
                                auto: property.auto,
                                builtIn: property.builtIn,
                                analyticsTools: property.analyticsTools,
                                devPlatforms: property.devPlatforms,
                                list: property.list,
                                operation: property.operation,
                                optionalDeprecated: property.optionalDeprecated,
                                excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                optionalWhenInObject: property.optionalWhenInObject,
                                absence: property.absence
                              };
                      })),
                propertyBundles: model$143.propertyBundles,
                sources: model$143.sources
              }
            };
    }
    if (variant === "SetMetricWhere") {
      var match$92 = action.VAL;
      var literals$1 = match$92[5];
      var binOp$1 = match$92[4];
      var propertyId$33 = match$92[3];
      var id$6 = match$92[2];
      var eventId$35 = match$92[1];
      var metricId$12 = match$92[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateMetric(model._0, metricId$12, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$35 !== $$event.eventId) {
                                          return metricItem;
                                        }
                                        var where = $$event.where;
                                        var match = Belt_List.keepU(where, (function (where) {
                                                return where.id === id$6;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: match ? Belt_List.mapU(where, (function (where) {
                                                            if (where.id === id$6) {
                                                              return {
                                                                      id: id$6,
                                                                      propertyId: propertyId$33,
                                                                      binOp: binOp$1,
                                                                      literals: literals$1
                                                                    };
                                                            } else {
                                                              return where;
                                                            }
                                                          })) : Belt_List.concat(where, {
                                                          hd: {
                                                            id: id$6,
                                                            propertyId: propertyId$33,
                                                            binOp: binOp$1,
                                                            literals: literals$1
                                                          },
                                                          tl: /* [] */0
                                                        }),
                                                  groupBy: $$event.groupBy
                                                }
                                              };
                                      }))
                              };
                      }))
              };
      }
      var model$144 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$144.archive,
                branchPointer: model$144.branchPointer,
                migrations: model$144.migrations,
                rules: model$144.rules,
                types: model$144.types,
                categories: model$144.categories,
                destinations: model$144.destinations,
                events: model$144.events,
                groupTypes: model$144.groupTypes,
                integrations: model$144.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.update, model$144.metrics, metricId$12, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$35 !== $$event.eventId) {
                                          return metricItem;
                                        }
                                        var where = $$event.where;
                                        var match = Belt_List.keepU(where, (function (where) {
                                                return where.id === id$6;
                                              }));
                                        return {
                                                NAME: "Event",
                                                VAL: {
                                                  id: $$event.id,
                                                  eventId: $$event.eventId,
                                                  where: match ? Belt_List.mapU(where, (function (where) {
                                                            if (where.id === id$6) {
                                                              return {
                                                                      id: id$6,
                                                                      propertyId: propertyId$33,
                                                                      binOp: binOp$1,
                                                                      literals: literals$1
                                                                    };
                                                            } else {
                                                              return where;
                                                            }
                                                          })) : Belt_List.concat(where, {
                                                          hd: {
                                                            id: id$6,
                                                            propertyId: propertyId$33,
                                                            binOp: binOp$1,
                                                            literals: literals$1
                                                          },
                                                          tl: /* [] */0
                                                        }),
                                                  groupBy: $$event.groupBy
                                                }
                                              };
                                      }))
                              };
                      })),
                openBranches: model$144.openBranches,
                properties: model$144.properties,
                propertyBundles: model$144.propertyBundles,
                sources: model$144.sources
              }
            };
    }
    if (variant === "CreateSource") {
      var sourceId$22 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$145 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$145.types,
                  properties: model$145.properties,
                  propertyBundles: model$145.propertyBundles,
                  events: model$145.events,
                  migrations: model$145.migrations,
                  sources: Belt_List.concat(model$145.sources, {
                        hd: TrackingPlanModel.emptySource(undefined, sourceId$22, AvoConfig.defaultFilename, AvoConfig.defaultPath),
                        tl: /* [] */0
                      }),
                  destinations: model$145.destinations,
                  groupTypes: model$145.groupTypes,
                  goals: model$145.goals,
                  metrics: model$145.metrics,
                  archive: model$145.archive,
                  openBranches: model$145.openBranches,
                  branchPointer: model$145.branchPointer,
                  rules: model$145.rules,
                  integrations: model$145.integrations
                }
              };
      }
      var model$146 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$146.archive,
                branchPointer: model$146.branchPointer,
                migrations: model$146.migrations,
                rules: model$146.rules,
                types: model$146.types,
                categories: model$146.categories,
                destinations: model$146.destinations,
                events: model$146.events,
                groupTypes: model$146.groupTypes,
                integrations: model$146.integrations,
                metrics: model$146.metrics,
                openBranches: model$146.openBranches,
                properties: model$146.properties,
                propertyBundles: model$146.propertyBundles,
                sources: Curry._2(TrackingPlanMappedModel.Sources.append, model$146.sources, TrackingPlanModel.emptySource(undefined, sourceId$22, AvoConfig.defaultFilename, AvoConfig.defaultPath))
              }
            };
    }
    if (variant === "UpdateSourceSegmentIntegration") {
      var match$93 = action.VAL;
      var isActive$3 = match$93[2];
      var segmentIntegration$1 = match$93[1];
      var sourceId$23 = match$93[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateSource(model._0, sourceId$23, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: Belt_List.mapU(source.segmentDestinationOptions, (function (param) {
                                        var integration = param[0];
                                        if (integration === segmentIntegration$1) {
                                          return [
                                                  integration,
                                                  isActive$3
                                                ];
                                        } else {
                                          return [
                                                  integration,
                                                  param[1]
                                                ];
                                        }
                                      })),
                                config: source.config
                              };
                      }))
              };
      }
      var model$147 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$147.archive,
                branchPointer: model$147.branchPointer,
                migrations: model$147.migrations,
                rules: model$147.rules,
                types: model$147.types,
                categories: model$147.categories,
                destinations: model$147.destinations,
                events: model$147.events,
                groupTypes: model$147.groupTypes,
                integrations: model$147.integrations,
                metrics: model$147.metrics,
                openBranches: model$147.openBranches,
                properties: model$147.properties,
                propertyBundles: model$147.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$147.sources, sourceId$23, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: Belt_List.mapU(source.segmentDestinationOptions, (function (param) {
                                        var integration = param[0];
                                        if (integration === segmentIntegration$1) {
                                          return [
                                                  integration,
                                                  isActive$3
                                                ];
                                        } else {
                                          return [
                                                  integration,
                                                  param[1]
                                                ];
                                        }
                                      })),
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "RemoveEventType") {
      var match$94 = action.VAL;
      var type_$2 = match$94[1];
      var eventId$36 = match$94[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$36, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: Belt_List.keepU($$event.types, (function (t) {
                                        return t !== type_$2;
                                      })),
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: type_$2 === "UpdateGroups" ? [] : $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$148 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$148.archive,
                branchPointer: model$148.branchPointer,
                migrations: model$148.migrations,
                rules: model$148.rules,
                types: model$148.types,
                categories: model$148.categories,
                destinations: model$148.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$148.events, eventId$36, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: Belt_List.keepU($$event.types, (function (t) {
                                        return t !== type_$2;
                                      })),
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: type_$2 === "UpdateGroups" ? [] : $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$148.groupTypes,
                integrations: model$148.integrations,
                metrics: model$148.metrics,
                openBranches: model$148.openBranches,
                properties: model$148.properties,
                propertyBundles: model$148.propertyBundles,
                sources: model$148.sources
              }
            };
    }
    if (variant === "RemoveEventFromGoal") {
      var match$95 = action.VAL;
      var eventId$37 = match$95[1];
      var goalId$7 = match$95[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateGoal(model._0, goalId$7, (function (goal) {
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: goal.metrics,
                                events: Belt_List.keepU(goal.events, (function (id) {
                                        return id !== eventId$37;
                                      }))
                              };
                      }))
              };
      }
      var model$149 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$149.archive,
                branchPointer: model$149.branchPointer,
                migrations: model$149.migrations,
                rules: model$149.rules,
                types: model$149.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.update, model$149.categories, goalId$7, (function (category) {
                        return {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                metrics: category.metrics,
                                events: Belt_List.keepU(category.events, (function (id) {
                                        return id !== eventId$37;
                                      }))
                              };
                      })),
                destinations: model$149.destinations,
                events: model$149.events,
                groupTypes: model$149.groupTypes,
                integrations: model$149.integrations,
                metrics: model$149.metrics,
                openBranches: model$149.openBranches,
                properties: model$149.properties,
                propertyBundles: model$149.propertyBundles,
                sources: model$149.sources
              }
            };
    }
    if (variant === "ExcludeDestinationFromSource") {
      var match$96 = action.VAL;
      var destinationId$15 = match$96[1];
      var sourceId$24 = match$96[0];
      if (model.TAG === /* Old */0) {
        var model$150 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$150.types,
                  properties: model$150.properties,
                  propertyBundles: model$150.propertyBundles,
                  events: Belt_List.mapU(model$150.events, (function ($$event) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: $$event.tags,
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: $$event.includeSources,
                                  includeDestinations: Belt_List.mapU($$event.includeDestinations, (function (includeDestination) {
                                          var match = includeDestination.VAL;
                                          var id = match[0];
                                          if (id === sourceId$24) {
                                            return {
                                                    NAME: "IncludeSourceDestination",
                                                    VAL: [
                                                      id,
                                                      Belt_List.keepU(match[1], (function (id) {
                                                              return id !== destinationId$15;
                                                            }))
                                                    ]
                                                  };
                                          } else {
                                            return includeDestination;
                                          }
                                        })),
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        })),
                  migrations: model$150.migrations,
                  sources: Belt_List.mapU(model$150.sources, (function (source) {
                          if (source.id === sourceId$24) {
                            return {
                                    id: source.id,
                                    name: source.name,
                                    language: source.language,
                                    platform: source.platform,
                                    destinations: Belt_List.keepU(source.destinations, (function (sourceDestination) {
                                            return sourceDestination.destinationId !== destinationId$15;
                                          })),
                                    filename: source.filename,
                                    path: source.path,
                                    segmentDestinationOptions: source.segmentDestinationOptions,
                                    config: source.config
                                  };
                          } else {
                            return source;
                          }
                        })),
                  destinations: model$150.destinations,
                  groupTypes: model$150.groupTypes,
                  goals: model$150.goals,
                  metrics: model$150.metrics,
                  archive: model$150.archive,
                  openBranches: model$150.openBranches,
                  branchPointer: model$150.branchPointer,
                  rules: model$150.rules,
                  integrations: model$150.integrations
                }
              };
      }
      var model$151 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$151.archive,
                branchPointer: model$151.branchPointer,
                migrations: model$151.migrations,
                rules: model$151.rules,
                types: model$151.types,
                categories: model$151.categories,
                destinations: model$151.destinations,
                events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$151.events, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.mapU($$event.includeDestinations, (function (includeDestination) {
                                        var match = includeDestination.VAL;
                                        var id = match[0];
                                        if (id === sourceId$24) {
                                          return {
                                                  NAME: "IncludeSourceDestination",
                                                  VAL: [
                                                    id,
                                                    Belt_List.keepU(match[1], (function (id) {
                                                            return id !== destinationId$15;
                                                          }))
                                                  ]
                                                };
                                        } else {
                                          return includeDestination;
                                        }
                                      })),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$151.groupTypes,
                integrations: model$151.integrations,
                metrics: model$151.metrics,
                openBranches: model$151.openBranches,
                properties: model$151.properties,
                propertyBundles: model$151.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$151.sources, sourceId$24, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: Belt_List.keepU(source.destinations, (function (sourceDestination) {
                                        return sourceDestination.destinationId !== destinationId$15;
                                      })),
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "UpdateMetricType") {
      var match$97 = action.VAL;
      var metricType = match$97[1];
      var metricId$13 = match$97[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateMetric(model._0, metricId$13, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metricType,
                                items: metric.items
                              };
                      }))
              };
      }
      var model$152 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$152.archive,
                branchPointer: model$152.branchPointer,
                migrations: model$152.migrations,
                rules: model$152.rules,
                types: model$152.types,
                categories: model$152.categories,
                destinations: model$152.destinations,
                events: model$152.events,
                groupTypes: model$152.groupTypes,
                integrations: model$152.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.update, model$152.metrics, metricId$13, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metricType,
                                items: metric.items
                              };
                      })),
                openBranches: model$152.openBranches,
                properties: model$152.properties,
                propertyBundles: model$152.propertyBundles,
                sources: model$152.sources
              }
            };
    }
    if (variant === "ClearPropertyExcludedSources") {
      var propertyId$34 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$153 = model._0;
        var tmp;
        if (hasMigrated(model$153, "PropertyOptionalAndExcludedSourcesToAbsence")) {
          var propertyOptional = Belt_Option.map(StateUtils.resolvePropertyById(propertyId$34, model$153), (function (prop) {
                  return prop.optionalDeprecated;
                }));
          if (propertyOptional !== undefined) {
            var relevantEventSourceIds = Belt_List.flatten(Belt_List.mapU(StateUtils.eventsSendingProperty(model$153, undefined, propertyId$34), (function ($$event) {
                        return Belt_List.mapU($$event.includeSources, (function (includedSource) {
                                      return [
                                              $$event.id,
                                              includedSource.id
                                            ];
                                    }));
                      })));
            tmp = StateUtils.updateProperty(model$153, propertyId$34, (function (property) {
                    return Belt_List.reduceU(relevantEventSourceIds, property, (function (property, param) {
                                  return updatePropertyAbsence({
                                              TAG: /* Old */0,
                                              _0: model$153
                                            }, property, {
                                              TAG: /* EventSource */2,
                                              _0: param[0],
                                              _1: param[1]
                                            }, propertyOptional ? /* SometimesSent */({
                                                  _0: ""
                                                }) : undefined);
                                }));
                  }));
          } else {
            tmp = model$153;
          }
        } else {
          tmp = StateUtils.updateProperty(model$153, propertyId$34, (function (property) {
                  return {
                          id: property.id,
                          name: property.name,
                          uniqueName: property.uniqueName,
                          description: property.description,
                          type_: property.type_,
                          sendAs: property.sendAs,
                          validations: property.validations,
                          auto: property.auto,
                          builtIn: property.builtIn,
                          analyticsTools: property.analyticsTools,
                          devPlatforms: property.devPlatforms,
                          list: property.list,
                          operation: property.operation,
                          optionalDeprecated: property.optionalDeprecated,
                          excludedSourcesDeprecated: /* [] */0,
                          optionalWhenInObject: property.optionalWhenInObject,
                          absence: property.absence
                        };
                }));
        }
        return {
                TAG: /* Old */0,
                _0: tmp
              };
      }
      var model$154 = model._0;
      if (!hasMigrated_boxed({
              TAG: /* Mapped */1,
              _0: model$154
            }, "PropertyOptionalAndExcludedSourcesToAbsence")) {
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: model$154.archive,
                  branchPointer: model$154.branchPointer,
                  migrations: model$154.migrations,
                  rules: model$154.rules,
                  types: model$154.types,
                  categories: model$154.categories,
                  destinations: model$154.destinations,
                  events: model$154.events,
                  groupTypes: model$154.groupTypes,
                  integrations: model$154.integrations,
                  metrics: model$154.metrics,
                  openBranches: model$154.openBranches,
                  properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$154.properties, propertyId$34, (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: property.optionalDeprecated,
                                  excludedSourcesDeprecated: /* [] */0,
                                  optionalWhenInObject: property.optionalWhenInObject,
                                  absence: property.absence
                                };
                        })),
                  propertyBundles: model$154.propertyBundles,
                  sources: model$154.sources
                }
              };
      }
      var propertyOptional$1 = Belt_Option.map(Curry._2(TrackingPlanMappedModel.Properties.get, model$154.properties, propertyId$34), (function (prop) {
              return prop.optionalDeprecated;
            }));
      if (propertyOptional$1 === undefined) {
        return model;
      }
      var relevantEventSourceIds$1 = Belt_Array.concatMany(Curry._2(TrackingPlanMappedModel.Events.mapToArray, StateUtils.eventsSendingProperty_mappedModel(model$154, undefined, propertyId$34), (function ($$event) {
                  return Belt_List.toArray(Belt_List.mapU($$event.includeSources, (function (includedSource) {
                                    return [
                                            $$event.id,
                                            includedSource.id
                                          ];
                                  })));
                })));
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$154.archive,
                branchPointer: model$154.branchPointer,
                migrations: model$154.migrations,
                rules: model$154.rules,
                types: model$154.types,
                categories: model$154.categories,
                destinations: model$154.destinations,
                events: model$154.events,
                groupTypes: model$154.groupTypes,
                integrations: model$154.integrations,
                metrics: model$154.metrics,
                openBranches: model$154.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$154.properties, propertyId$34, (function (property) {
                        return Belt_Array.reduceU(relevantEventSourceIds$1, property, (function (property, param) {
                                      return updatePropertyAbsence({
                                                  TAG: /* Mapped */1,
                                                  _0: model$154
                                                }, property, {
                                                  TAG: /* EventSource */2,
                                                  _0: param[0],
                                                  _1: param[1]
                                                }, propertyOptional$1 ? /* SometimesSent */({
                                                      _0: ""
                                                    }) : undefined);
                                    }));
                      })),
                propertyBundles: model$154.propertyBundles,
                sources: model$154.sources
              }
            };
    }
    if (variant === "UpdateIntegrationName") {
      var match$98 = action.VAL;
      var name$8 = match$98[1];
      var integrationId$4 = match$98[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateIntegration(model._0, integrationId$4, (function (integration) {
                        return {
                                id: integration.id,
                                name: name$8,
                                config: integration.config,
                                filters: integration.filters,
                                autoPublish: integration.autoPublish
                              };
                      }))
              };
      }
      var model$155 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$155.archive,
                branchPointer: model$155.branchPointer,
                migrations: model$155.migrations,
                rules: model$155.rules,
                types: model$155.types,
                categories: model$155.categories,
                destinations: model$155.destinations,
                events: model$155.events,
                groupTypes: model$155.groupTypes,
                integrations: Curry._3(TrackingPlanMappedModel.Integrations.update, model$155.integrations, integrationId$4, (function (integration) {
                        return {
                                id: integration.id,
                                name: name$8,
                                config: integration.config,
                                filters: integration.filters,
                                autoPublish: integration.autoPublish
                              };
                      })),
                metrics: model$155.metrics,
                openBranches: model$155.openBranches,
                properties: model$155.properties,
                propertyBundles: model$155.propertyBundles,
                sources: model$155.sources
              }
            };
    }
    if (variant === "CreatePropertyGroup") {
      var match$99 = action.VAL;
      var name$9 = match$99[1];
      var groupId$2 = match$99[0];
      if (model.TAG === /* Old */0) {
        var model$156 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$156.types,
                  properties: model$156.properties,
                  propertyBundles: Belt_List.concat(model$156.propertyBundles, {
                        hd: {
                          id: groupId$2,
                          name: name$9,
                          description: "",
                          properties: /* [] */0
                        },
                        tl: /* [] */0
                      }),
                  events: model$156.events,
                  migrations: model$156.migrations,
                  sources: model$156.sources,
                  destinations: model$156.destinations,
                  groupTypes: model$156.groupTypes,
                  goals: model$156.goals,
                  metrics: model$156.metrics,
                  archive: model$156.archive,
                  openBranches: model$156.openBranches,
                  branchPointer: model$156.branchPointer,
                  rules: model$156.rules,
                  integrations: model$156.integrations
                }
              };
      }
      var model$157 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$157.archive,
                branchPointer: model$157.branchPointer,
                migrations: model$157.migrations,
                rules: model$157.rules,
                types: model$157.types,
                categories: model$157.categories,
                destinations: model$157.destinations,
                events: model$157.events,
                groupTypes: model$157.groupTypes,
                integrations: model$157.integrations,
                metrics: model$157.metrics,
                openBranches: model$157.openBranches,
                properties: model$157.properties,
                propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.append, model$157.propertyBundles, {
                      id: groupId$2,
                      name: name$9,
                      description: "",
                      properties: /* [] */0
                    }),
                sources: model$157.sources
              }
            };
    }
    if (variant === "UpdatePropertyGroupName") {
      var match$100 = action.VAL;
      var name$10 = match$100[1];
      var groupId$3 = match$100[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updatePropertyGroup(model._0, groupId$3, (function (group) {
                        return {
                                id: group.id,
                                name: name$10,
                                description: group.description,
                                properties: group.properties
                              };
                      }))
              };
      }
      var model$158 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$158.archive,
                branchPointer: model$158.branchPointer,
                migrations: model$158.migrations,
                rules: model$158.rules,
                types: model$158.types,
                categories: model$158.categories,
                destinations: model$158.destinations,
                events: model$158.events,
                groupTypes: model$158.groupTypes,
                integrations: model$158.integrations,
                metrics: model$158.metrics,
                openBranches: model$158.openBranches,
                properties: model$158.properties,
                propertyBundles: Curry._3(TrackingPlanMappedModel.PropertyBundles.update, model$158.propertyBundles, groupId$3, (function (propertyBundle) {
                        return {
                                id: propertyBundle.id,
                                name: name$10,
                                description: propertyBundle.description,
                                properties: propertyBundle.properties
                              };
                      })),
                sources: model$158.sources
              }
            };
    }
    if (variant === "AddSourceConfig") {
      var match$101 = action.VAL;
      var sourceConfig = match$101[1];
      var sourceId$25 = match$101[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateSource(model._0, sourceId$25, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: Belt_List.some(source.config, (function (config) {
                                        return config === sourceConfig;
                                      })) ? source.config : ({
                                      hd: sourceConfig,
                                      tl: source.config
                                    })
                              };
                      }))
              };
      }
      var model$159 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$159.archive,
                branchPointer: model$159.branchPointer,
                migrations: model$159.migrations,
                rules: model$159.rules,
                types: model$159.types,
                categories: model$159.categories,
                destinations: model$159.destinations,
                events: model$159.events,
                groupTypes: model$159.groupTypes,
                integrations: model$159.integrations,
                metrics: model$159.metrics,
                openBranches: model$159.openBranches,
                properties: model$159.properties,
                propertyBundles: model$159.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$159.sources, sourceId$25, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: Belt_List.some(source.config, (function (config) {
                                        return config === sourceConfig;
                                      })) ? source.config : ({
                                      hd: sourceConfig,
                                      tl: source.config
                                    })
                              };
                      }))
              }
            };
    }
    if (variant === "UpdateEventUniqueName") {
      var match$102 = action.VAL;
      var name$11 = match$102[1];
      var eventId$38 = match$102[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$38, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: name$11,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$160 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$160.archive,
                branchPointer: model$160.branchPointer,
                migrations: model$160.migrations,
                rules: model$160.rules,
                types: model$160.types,
                categories: model$160.categories,
                destinations: model$160.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$160.events, eventId$38, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: name$11,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$160.groupTypes,
                integrations: model$160.integrations,
                metrics: model$160.metrics,
                openBranches: model$160.openBranches,
                properties: model$160.properties,
                propertyBundles: model$160.propertyBundles,
                sources: model$160.sources
              }
            };
    }
    if (variant === "UpdateIntegrationType") {
      var match$103 = action.VAL;
      var integrationType = match$103[1];
      var integrationId$5 = match$103[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateIntegration(model._0, integrationId$5, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: integrationType === "Protocols" ? ({
                                      TAG: /* Protocols */0,
                                      _0: {
                                        accessKey: undefined,
                                        trackingPlanId: undefined,
                                        workspace: undefined,
                                        usePublicApi: false
                                      }
                                    }) : (
                                    integrationType === "SnowplowDataStructures" ? ({
                                          TAG: /* SnowplowDataStructures */4,
                                          _0: {
                                            accessToken: undefined,
                                            organizationId: undefined,
                                            vendor: undefined
                                          }
                                        }) : (
                                        integrationType === "Lexicon" ? ({
                                              TAG: /* Lexicon */2,
                                              _0: {
                                                userName: undefined,
                                                secretKey: undefined,
                                                projectId: undefined,
                                                euDataResidency: false,
                                                categoriesAsTags: false
                                              }
                                            }) : (
                                            integrationType === "MParticleDataMaster" ? ({
                                                  TAG: /* MParticleDataMaster */5,
                                                  _0: {
                                                    clientId: undefined,
                                                    clientSecret: undefined,
                                                    workspaceId: undefined,
                                                    planId: undefined
                                                  }
                                                }) : (
                                                integrationType === "Taxonomy" ? ({
                                                      TAG: /* Taxonomy */1,
                                                      _0: {
                                                        apiKey: undefined,
                                                        secretKey: undefined,
                                                        euDataResidency: false
                                                      }
                                                    }) : (
                                                    integrationType === "Webhook" ? ({
                                                          TAG: /* Webhook */3,
                                                          _0: {
                                                            webhookUrl: undefined,
                                                            payloadFormat: /* JsonSchema */0
                                                          }
                                                        }) : ({
                                                          TAG: /* RudderstackTrackingPlans */6,
                                                          _0: {
                                                            accessToken: undefined,
                                                            trackingPlanDisplayName: undefined,
                                                            emailAddress: undefined
                                                          }
                                                        })
                                                  )
                                              )
                                          )
                                      )
                                  ),
                                filters: integration.filters,
                                autoPublish: integration.autoPublish
                              };
                      }))
              };
      }
      var model$161 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$161.archive,
                branchPointer: model$161.branchPointer,
                migrations: model$161.migrations,
                rules: model$161.rules,
                types: model$161.types,
                categories: model$161.categories,
                destinations: model$161.destinations,
                events: model$161.events,
                groupTypes: model$161.groupTypes,
                integrations: Curry._3(TrackingPlanMappedModel.Integrations.update, model$161.integrations, integrationId$5, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: integrationType === "Protocols" ? ({
                                      TAG: /* Protocols */0,
                                      _0: {
                                        accessKey: undefined,
                                        trackingPlanId: undefined,
                                        workspace: undefined,
                                        usePublicApi: false
                                      }
                                    }) : (
                                    integrationType === "SnowplowDataStructures" ? ({
                                          TAG: /* SnowplowDataStructures */4,
                                          _0: {
                                            accessToken: undefined,
                                            organizationId: undefined,
                                            vendor: undefined
                                          }
                                        }) : (
                                        integrationType === "Lexicon" ? ({
                                              TAG: /* Lexicon */2,
                                              _0: {
                                                userName: undefined,
                                                secretKey: undefined,
                                                projectId: undefined,
                                                euDataResidency: false,
                                                categoriesAsTags: false
                                              }
                                            }) : (
                                            integrationType === "MParticleDataMaster" ? ({
                                                  TAG: /* MParticleDataMaster */5,
                                                  _0: {
                                                    clientId: undefined,
                                                    clientSecret: undefined,
                                                    workspaceId: undefined,
                                                    planId: undefined
                                                  }
                                                }) : (
                                                integrationType === "Taxonomy" ? ({
                                                      TAG: /* Taxonomy */1,
                                                      _0: {
                                                        apiKey: undefined,
                                                        secretKey: undefined,
                                                        euDataResidency: false
                                                      }
                                                    }) : (
                                                    integrationType === "Webhook" ? ({
                                                          TAG: /* Webhook */3,
                                                          _0: {
                                                            webhookUrl: undefined,
                                                            payloadFormat: /* JsonSchema */0
                                                          }
                                                        }) : ({
                                                          TAG: /* RudderstackTrackingPlans */6,
                                                          _0: {
                                                            accessToken: undefined,
                                                            trackingPlanDisplayName: undefined,
                                                            emailAddress: undefined
                                                          }
                                                        })
                                                  )
                                              )
                                          )
                                      )
                                  ),
                                filters: integration.filters,
                                autoPublish: integration.autoPublish
                              };
                      })),
                metrics: model$161.metrics,
                openBranches: model$161.openBranches,
                properties: model$161.properties,
                propertyBundles: model$161.propertyBundles,
                sources: model$161.sources
              }
            };
    }
    if (variant === "RemoveMetricWhere") {
      var match$104 = action.VAL;
      var id$7 = match$104[2];
      var eventId$39 = match$104[1];
      var metricId$14 = match$104[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateMetric(model._0, metricId$14, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$39 === $$event.eventId) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: Belt_List.keepU($$event.where, (function (where) {
                                                            return where.id !== id$7;
                                                          })),
                                                    groupBy: $$event.groupBy
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      }))
              };
      }
      var model$162 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$162.archive,
                branchPointer: model$162.branchPointer,
                migrations: model$162.migrations,
                rules: model$162.rules,
                types: model$162.types,
                categories: model$162.categories,
                destinations: model$162.destinations,
                events: model$162.events,
                groupTypes: model$162.groupTypes,
                integrations: model$162.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.update, model$162.metrics, metricId$14, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (eventId$39 === $$event.eventId) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: Belt_List.keepU($$event.where, (function (where) {
                                                            return where.id !== id$7;
                                                          })),
                                                    groupBy: $$event.groupBy
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      })),
                openBranches: model$162.openBranches,
                properties: model$162.properties,
                propertyBundles: model$162.propertyBundles,
                sources: model$162.sources
              }
            };
    }
    if (variant === "UpdateEventName") {
      var match$105 = action.VAL;
      var name$12 = match$105[1];
      var eventId$40 = match$105[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$40, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: name$12,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$163 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$163.archive,
                branchPointer: model$163.branchPointer,
                migrations: model$163.migrations,
                rules: model$163.rules,
                types: model$163.types,
                categories: model$163.categories,
                destinations: model$163.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$163.events, eventId$40, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: name$12,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$163.groupTypes,
                integrations: model$163.integrations,
                metrics: model$163.metrics,
                openBranches: model$163.openBranches,
                properties: model$163.properties,
                propertyBundles: model$163.propertyBundles,
                sources: model$163.sources
              }
            };
    }
    if (variant === "UpdateIntegrationFilters") {
      var match$106 = action.VAL;
      var filters = match$106[1];
      var integrationId$6 = match$106[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateIntegration(model._0, integrationId$6, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: integration.config,
                                filters: filters,
                                autoPublish: integration.autoPublish
                              };
                      }))
              };
      }
      var model$164 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$164.archive,
                branchPointer: model$164.branchPointer,
                migrations: model$164.migrations,
                rules: model$164.rules,
                types: model$164.types,
                categories: model$164.categories,
                destinations: model$164.destinations,
                events: model$164.events,
                groupTypes: model$164.groupTypes,
                integrations: Curry._3(TrackingPlanMappedModel.Integrations.update, model$164.integrations, integrationId$6, (function (integration) {
                        return {
                                id: integration.id,
                                name: integration.name,
                                config: integration.config,
                                filters: filters,
                                autoPublish: integration.autoPublish
                              };
                      })),
                metrics: model$164.metrics,
                openBranches: model$164.openBranches,
                properties: model$164.properties,
                propertyBundles: model$164.propertyBundles,
                sources: model$164.sources
              }
            };
    }
    if (variant === "CreateProperty") {
      var match$107 = action.VAL;
      var createdProperty_id = match$107[0];
      var createdProperty_name = match$107[1];
      var createdProperty_sendAs = match$107[2];
      var createdProperty_absence = hasMigrated_boxed(model, "PropertyOptionalAndExcludedSourcesToAbsence") ? /* AlwaysSent */0 : undefined;
      var createdProperty = {
        id: createdProperty_id,
        name: createdProperty_name,
        uniqueName: undefined,
        description: "",
        type_: "string",
        sendAs: createdProperty_sendAs,
        validations: /* [] */0,
        auto: false,
        builtIn: "NotBuiltIn",
        analyticsTools: /* [] */0,
        devPlatforms: /* [] */0,
        list: false,
        operation: "Set",
        optionalDeprecated: false,
        excludedSourcesDeprecated: /* [] */0,
        optionalWhenInObject: false,
        absence: createdProperty_absence
      };
      if (model.TAG === /* Old */0) {
        var model$165 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$165.types,
                  properties: Belt_List.concat(model$165.properties, {
                        hd: {
                          TAG: /* Property */1,
                          _0: createdProperty
                        },
                        tl: /* [] */0
                      }),
                  propertyBundles: model$165.propertyBundles,
                  events: model$165.events,
                  migrations: model$165.migrations,
                  sources: model$165.sources,
                  destinations: model$165.destinations,
                  groupTypes: model$165.groupTypes,
                  goals: model$165.goals,
                  metrics: model$165.metrics,
                  archive: model$165.archive,
                  openBranches: model$165.openBranches,
                  branchPointer: model$165.branchPointer,
                  rules: model$165.rules,
                  integrations: model$165.integrations
                }
              };
      }
      var model$166 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$166.archive,
                branchPointer: model$166.branchPointer,
                migrations: model$166.migrations,
                rules: model$166.rules,
                types: model$166.types,
                categories: model$166.categories,
                destinations: model$166.destinations,
                events: model$166.events,
                groupTypes: model$166.groupTypes,
                integrations: model$166.integrations,
                metrics: model$166.metrics,
                openBranches: model$166.openBranches,
                properties: Curry._2(TrackingPlanMappedModel.Properties.append, model$166.properties, createdProperty),
                propertyBundles: model$166.propertyBundles,
                sources: model$166.sources
              }
            };
    }
    if (variant === "UpdatePropertyOptionalWhenInObject") {
      var match$108 = action.VAL;
      var optionalWhenInObject = match$108[1];
      var propertyId$35 = match$108[0];
      if (model.TAG === /* Old */0) {
        var model$167 = model._0;
        return {
                TAG: /* Old */0,
                _0: hasMigrated(model$167, "PropertyOptionalAndExcludedSourcesToAbsence") ? StateUtils.updateProperty(model$167, propertyId$35, (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: property.optionalDeprecated,
                                  excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                  optionalWhenInObject: optionalWhenInObject,
                                  absence: property.absence
                                };
                        })) : model$167
              };
      }
      var model$168 = model._0;
      if (hasMigrated_boxed(model, "PropertyOptionalAndExcludedSourcesToAbsence")) {
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: model$168.archive,
                  branchPointer: model$168.branchPointer,
                  migrations: model$168.migrations,
                  rules: model$168.rules,
                  types: model$168.types,
                  categories: model$168.categories,
                  destinations: model$168.destinations,
                  events: model$168.events,
                  groupTypes: model$168.groupTypes,
                  integrations: model$168.integrations,
                  metrics: model$168.metrics,
                  openBranches: model$168.openBranches,
                  properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$168.properties, propertyId$35, (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: property.optionalDeprecated,
                                  excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                  optionalWhenInObject: optionalWhenInObject,
                                  absence: property.absence
                                };
                        })),
                  propertyBundles: model$168.propertyBundles,
                  sources: model$168.sources
                }
              };
      } else {
        return model;
      }
    }
    if (variant === "RemoveGroupTypeFromLogEvent") {
      var match$109 = action.VAL;
      var groupTypeIdToRemove$1 = match$109[1];
      var eventId$41 = match$109[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$41, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: Belt_Array.keep($$event.eventGroupTypeIdsWithArchive, (function (groupTypeId) {
                                        return groupTypeId !== groupTypeIdToRemove$1;
                                      })),
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$169 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$169.archive,
                branchPointer: model$169.branchPointer,
                migrations: model$169.migrations,
                rules: model$169.rules,
                types: model$169.types,
                categories: model$169.categories,
                destinations: model$169.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$169.events, eventId$41, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: Belt_Array.keep($$event.eventGroupTypeIdsWithArchive, (function (groupTypeId) {
                                        return groupTypeId !== groupTypeIdToRemove$1;
                                      })),
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$169.groupTypes,
                integrations: model$169.integrations,
                metrics: model$169.metrics,
                openBranches: model$169.openBranches,
                properties: model$169.properties,
                propertyBundles: model$169.propertyBundles,
                sources: model$169.sources
              }
            };
    }
    if (variant === "RemovePropertyGroupFromEvent") {
      var match$110 = action.VAL;
      var groupId$4 = match$110[1];
      var eventId$42 = match$110[0];
      if (model.TAG === /* Old */0) {
        var model$170 = model._0;
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model$170, eventId$42, (function ($$event) {
                        var propertyGroup = StateUtils.getPropertyGroupById(groupId$4, model$170);
                        var tmp;
                        if (propertyGroup !== undefined) {
                          var groupProperties = propertyGroup.properties;
                          var propertiesBeingRemovedFromEvent = Belt_List.keepU(groupProperties, (function (propertyId) {
                                  return !Belt_List.some($$event.properties, (function (x) {
                                                return x._0.id === propertyId;
                                              }));
                                }));
                          tmp = Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                  var propId = param[1];
                                  return !Belt_List.someU(propertiesBeingRemovedFromEvent, (function (removedId) {
                                                return removedId === propId;
                                              }));
                                }));
                        } else {
                          tmp = $$event.propertyWhitelist;
                        }
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: Belt_List.keepU($$event.propertyBundles, (function (group) {
                                        return group.id !== groupId$4;
                                      })),
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: tmp,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$171 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$171.archive,
                branchPointer: model$171.branchPointer,
                migrations: model$171.migrations,
                rules: model$171.rules,
                types: model$171.types,
                categories: model$171.categories,
                destinations: model$171.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$171.events, eventId$42, (function ($$event) {
                        var propertyGroup = Curry._2(TrackingPlanMappedModel.PropertyBundles.get, model$171.propertyBundles, groupId$4);
                        var tmp;
                        if (propertyGroup !== undefined) {
                          var groupProperties = propertyGroup.properties;
                          var propertiesBeingRemovedFromEvent = Belt_List.keepU(groupProperties, (function (propertyId) {
                                  return !Belt_List.some($$event.properties, (function (x) {
                                                return x._0.id === propertyId;
                                              }));
                                }));
                          tmp = Belt_List.keepU($$event.propertyWhitelist, (function (param) {
                                  var propId = param[1];
                                  return !Belt_List.someU(propertiesBeingRemovedFromEvent, (function (removedId) {
                                                return removedId === propId;
                                              }));
                                }));
                        } else {
                          tmp = $$event.propertyWhitelist;
                        }
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: Belt_List.keepU($$event.propertyBundles, (function (group) {
                                        return group.id !== groupId$4;
                                      })),
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: tmp,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$171.groupTypes,
                integrations: model$171.integrations,
                metrics: model$171.metrics,
                openBranches: model$171.openBranches,
                properties: model$171.properties,
                propertyBundles: model$171.propertyBundles,
                sources: model$171.sources
              }
            };
    }
    if (variant === "UpdateMetricDescription") {
      var match$111 = action.VAL;
      var description$6 = match$111[1];
      var metricId$15 = match$111[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateMetric(model._0, metricId$15, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: description$6,
                                type_: metric.type_,
                                items: metric.items
                              };
                      }))
              };
      }
      var model$172 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$172.archive,
                branchPointer: model$172.branchPointer,
                migrations: model$172.migrations,
                rules: model$172.rules,
                types: model$172.types,
                categories: model$172.categories,
                destinations: model$172.destinations,
                events: model$172.events,
                groupTypes: model$172.groupTypes,
                integrations: model$172.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.update, model$172.metrics, metricId$15, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: description$6,
                                type_: metric.type_,
                                items: metric.items
                              };
                      })),
                openBranches: model$172.openBranches,
                properties: model$172.properties,
                propertyBundles: model$172.propertyBundles,
                sources: model$172.sources
              }
            };
    }
    if (variant === "RemoveMetricFromGoal") {
      var match$112 = action.VAL;
      var metricId$16 = match$112[1];
      var goalId$8 = match$112[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateGoal(model._0, goalId$8, (function (goal) {
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: Belt_List.keepU(goal.metrics, (function (id) {
                                        return id !== metricId$16;
                                      })),
                                events: goal.events
                              };
                      }))
              };
      }
      var model$173 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$173.archive,
                branchPointer: model$173.branchPointer,
                migrations: model$173.migrations,
                rules: model$173.rules,
                types: model$173.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.update, model$173.categories, goalId$8, (function (category) {
                        return {
                                id: category.id,
                                name: category.name,
                                description: category.description,
                                metrics: Belt_List.keepU(category.metrics, (function (id) {
                                        return id !== metricId$16;
                                      })),
                                events: category.events
                              };
                      })),
                destinations: model$173.destinations,
                events: model$173.events,
                groupTypes: model$173.groupTypes,
                integrations: model$173.integrations,
                metrics: model$173.metrics,
                openBranches: model$173.openBranches,
                properties: model$173.properties,
                propertyBundles: model$173.propertyBundles,
                sources: model$173.sources
              }
            };
    }
    if (variant === "ConfigureInspectorValidation") {
      var match$113 = action.VAL;
      var sourceValidationMap = match$113[1];
      var eventIds$2 = match$113[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: Belt_List.reduceU(eventIds$2, model._0, (function (nextModel, eventId) {
                        return StateUtils.updateEvent(nextModel, eventId, (function ($$event) {
                                      return {
                                              id: $$event.id,
                                              name: $$event.name,
                                              uniqueName: $$event.uniqueName,
                                              description: $$event.description,
                                              properties: $$event.properties,
                                              propertyBundles: $$event.propertyBundles,
                                              variants: $$event.variants,
                                              types: $$event.types,
                                              tags: $$event.tags,
                                              excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                              includeSources: Belt_List.map($$event.includeSources, (function (source) {
                                                      var validation = Belt_MapString.get(sourceValidationMap, source.id);
                                                      if (validation !== undefined) {
                                                        return {
                                                                id: source.id,
                                                                includeInCodegen: source.includeInCodegen,
                                                                inspectorValidation: Caml_option.valFromOption(validation)
                                                              };
                                                      } else {
                                                        return source;
                                                      }
                                                    })),
                                              includeDestinations: $$event.includeDestinations,
                                              hashes: $$event.hashes,
                                              propertyWhitelist: $$event.propertyWhitelist,
                                              eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                              userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                              triggers: $$event.triggers
                                            };
                                    }));
                      }))
              };
      } else {
        return {
                TAG: /* Mapped */1,
                _0: Belt_List.reduceU(eventIds$2, model._0, (function (nextModel, eventId) {
                        return {
                                archive: nextModel.archive,
                                branchPointer: nextModel.branchPointer,
                                migrations: nextModel.migrations,
                                rules: nextModel.rules,
                                types: nextModel.types,
                                categories: nextModel.categories,
                                destinations: nextModel.destinations,
                                events: Curry._3(TrackingPlanMappedModel.Events.update, nextModel.events, eventId, (function ($$event) {
                                        return {
                                                id: $$event.id,
                                                name: $$event.name,
                                                uniqueName: $$event.uniqueName,
                                                description: $$event.description,
                                                properties: $$event.properties,
                                                propertyBundles: $$event.propertyBundles,
                                                variants: $$event.variants,
                                                types: $$event.types,
                                                tags: $$event.tags,
                                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                                includeSources: Belt_List.map($$event.includeSources, (function (source) {
                                                        var validation = Belt_MapString.get(sourceValidationMap, source.id);
                                                        if (validation !== undefined) {
                                                          return {
                                                                  id: source.id,
                                                                  includeInCodegen: source.includeInCodegen,
                                                                  inspectorValidation: Caml_option.valFromOption(validation)
                                                                };
                                                        } else {
                                                          return source;
                                                        }
                                                      })),
                                                includeDestinations: $$event.includeDestinations,
                                                hashes: $$event.hashes,
                                                propertyWhitelist: $$event.propertyWhitelist,
                                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                                triggers: $$event.triggers
                                              };
                                      })),
                                groupTypes: nextModel.groupTypes,
                                integrations: nextModel.integrations,
                                metrics: nextModel.metrics,
                                openBranches: nextModel.openBranches,
                                properties: nextModel.properties,
                                propertyBundles: nextModel.propertyBundles,
                                sources: nextModel.sources
                              };
                      }))
              };
      }
    }
    if (variant === "AddPropertyToGroup") {
      var match$114 = action.VAL;
      var propertyId$36 = match$114[1];
      var groupId$5 = match$114[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updatePropertyGroup(model._0, groupId$5, (function (group) {
                        return {
                                id: group.id,
                                name: group.name,
                                description: group.description,
                                properties: Belt_List.someU(group.properties, (function (id) {
                                        return id === propertyId$36;
                                      })) ? group.properties : Belt_List.concat(group.properties, {
                                        hd: propertyId$36,
                                        tl: /* [] */0
                                      })
                              };
                      }))
              };
      }
      var model$174 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$174.archive,
                branchPointer: model$174.branchPointer,
                migrations: model$174.migrations,
                rules: model$174.rules,
                types: model$174.types,
                categories: model$174.categories,
                destinations: model$174.destinations,
                events: model$174.events,
                groupTypes: model$174.groupTypes,
                integrations: model$174.integrations,
                metrics: model$174.metrics,
                openBranches: model$174.openBranches,
                properties: model$174.properties,
                propertyBundles: Curry._3(TrackingPlanMappedModel.PropertyBundles.update, model$174.propertyBundles, groupId$5, (function (group) {
                        return {
                                id: group.id,
                                name: group.name,
                                description: group.description,
                                properties: Belt_List.someU(group.properties, (function (id) {
                                        return id === propertyId$36;
                                      })) ? group.properties : Belt_List.concat(group.properties, {
                                        hd: propertyId$36,
                                        tl: /* [] */0
                                      })
                              };
                      })),
                sources: model$174.sources
              }
            };
    }
    if (variant === "UpdateSourcePlatform") {
      var match$115 = action.VAL;
      var platform$1 = match$115[1];
      var sourceId$26 = match$115[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateSource(model._0, sourceId$26, (function (source) {
                        var language = source.language;
                        return {
                                id: source.id,
                                name: source.name,
                                language: language !== undefined && Belt_List.hasU(AvoConfig.getSupportedLanguages(platform$1), language, (function (supportedLang, lang) {
                                        return supportedLang === lang;
                                      })) ? language : Belt_List.head(AvoConfig.getSupportedLanguages(platform$1)),
                                platform: platform$1,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$175 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$175.archive,
                branchPointer: model$175.branchPointer,
                migrations: model$175.migrations,
                rules: model$175.rules,
                types: model$175.types,
                categories: model$175.categories,
                destinations: model$175.destinations,
                events: model$175.events,
                groupTypes: model$175.groupTypes,
                integrations: model$175.integrations,
                metrics: model$175.metrics,
                openBranches: model$175.openBranches,
                properties: model$175.properties,
                propertyBundles: model$175.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$175.sources, sourceId$26, (function (source) {
                        var language = source.language;
                        return {
                                id: source.id,
                                name: source.name,
                                language: language !== undefined && Belt_List.hasU(AvoConfig.getSupportedLanguages(platform$1), language, (function (supportedLang, lang) {
                                        return supportedLang === lang;
                                      })) ? language : Belt_List.head(AvoConfig.getSupportedLanguages(platform$1)),
                                platform: platform$1,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "UpdateDestinationDevApiKey") {
      var match$116 = action.VAL;
      var devApiKey = match$116[1];
      var destinationId$16 = match$116[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateDestination(model._0, destinationId$16, (function (destination) {
                        var init = destination.apiKeys;
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: devApiKey,
                                  prod: init.prod,
                                  other: init.other
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      }))
              };
      }
      var model$176 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$176.archive,
                branchPointer: model$176.branchPointer,
                migrations: model$176.migrations,
                rules: model$176.rules,
                types: model$176.types,
                categories: model$176.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.update, model$176.destinations, destinationId$16, (function (destination) {
                        var init = destination.apiKeys;
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: devApiKey,
                                  prod: init.prod,
                                  other: init.other
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      })),
                events: model$176.events,
                groupTypes: model$176.groupTypes,
                integrations: model$176.integrations,
                metrics: model$176.metrics,
                openBranches: model$176.openBranches,
                properties: model$176.properties,
                propertyBundles: model$176.propertyBundles,
                sources: model$176.sources
              }
            };
    }
    if (variant === "UpdateLibraryName") {
      var match$117 = action.VAL;
      var name$13 = match$117[1];
      var sourceId$27 = match$117[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateSource(model._0, sourceId$27, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: name$13,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              };
      }
      var model$177 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$177.archive,
                branchPointer: model$177.branchPointer,
                migrations: model$177.migrations,
                rules: model$177.rules,
                types: model$177.types,
                categories: model$177.categories,
                destinations: model$177.destinations,
                events: model$177.events,
                groupTypes: model$177.groupTypes,
                integrations: model$177.integrations,
                metrics: model$177.metrics,
                openBranches: model$177.openBranches,
                properties: model$177.properties,
                propertyBundles: model$177.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$177.sources, sourceId$27, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: name$13,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "CloseBranch") {
      var branchId$2 = action.VAL[0];
      if (model.TAG === /* Old */0) {
        var model$178 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$178.types,
                  properties: model$178.properties,
                  propertyBundles: model$178.propertyBundles,
                  events: model$178.events,
                  migrations: model$178.migrations,
                  sources: model$178.sources,
                  destinations: model$178.destinations,
                  groupTypes: model$178.groupTypes,
                  goals: model$178.goals,
                  metrics: model$178.metrics,
                  archive: model$178.archive,
                  openBranches: Belt_List.keepU(model$178.openBranches, (function (param) {
                          return param[0] !== branchId$2;
                        })),
                  branchPointer: model$178.branchPointer,
                  rules: model$178.rules,
                  integrations: model$178.integrations
                }
              };
      }
      var model$179 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$179.archive,
                branchPointer: model$179.branchPointer,
                migrations: model$179.migrations,
                rules: model$179.rules,
                types: model$179.types,
                categories: model$179.categories,
                destinations: model$179.destinations,
                events: model$179.events,
                groupTypes: model$179.groupTypes,
                integrations: model$179.integrations,
                metrics: model$179.metrics,
                openBranches: Curry._2(TrackingPlanMappedModel.OpenBranches.remove, model$179.openBranches, branchId$2),
                properties: model$179.properties,
                propertyBundles: model$179.propertyBundles,
                sources: model$179.sources
              }
            };
    }
    if (variant === "RemoveEventFromMetric") {
      var match$118 = action.VAL;
      var metricItemId$5 = match$118[1];
      var metricId$17 = match$118[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateMetric(model._0, metricId$17, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.keepU(metric.items, (function (item) {
                                        return item.VAL.id !== metricItemId$5;
                                      }))
                              };
                      }))
              };
      }
      var model$180 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$180.archive,
                branchPointer: model$180.branchPointer,
                migrations: model$180.migrations,
                rules: model$180.rules,
                types: model$180.types,
                categories: model$180.categories,
                destinations: model$180.destinations,
                events: model$180.events,
                groupTypes: model$180.groupTypes,
                integrations: model$180.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.update, model$180.metrics, metricId$17, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.keepU(metric.items, (function (item) {
                                        return item.VAL.id !== metricItemId$5;
                                      }))
                              };
                      })),
                openBranches: model$180.openBranches,
                properties: model$180.properties,
                propertyBundles: model$180.propertyBundles,
                sources: model$180.sources
              }
            };
    }
    if (variant === "RemovePropertyPinnedValue") {
      var match$119 = action.VAL;
      var propertyId$37 = match$119[1];
      var eventId$43 = match$119[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$43, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.map($$event.properties, (function (property) {
                                        if (property.TAG !== /* PropertyRef */0) {
                                          return property;
                                        }
                                        var propRef = property._0;
                                        if (propRef.id === propertyId$37) {
                                          return {
                                                  TAG: /* PropertyRef */0,
                                                  _0: {
                                                    id: propRef.id,
                                                    description: propRef.description,
                                                    pinnedValue: undefined
                                                  }
                                                };
                                        } else {
                                          return property;
                                        }
                                      })),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$181 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$181.archive,
                branchPointer: model$181.branchPointer,
                migrations: model$181.migrations,
                rules: model$181.rules,
                types: model$181.types,
                categories: model$181.categories,
                destinations: model$181.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$181.events, eventId$43, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: Belt_List.map($$event.properties, (function (property) {
                                        if (property.TAG !== /* PropertyRef */0) {
                                          return property;
                                        }
                                        var propRef = property._0;
                                        if (propRef.id === propertyId$37) {
                                          return {
                                                  TAG: /* PropertyRef */0,
                                                  _0: {
                                                    id: propRef.id,
                                                    description: propRef.description,
                                                    pinnedValue: undefined
                                                  }
                                                };
                                        } else {
                                          return property;
                                        }
                                      })),
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$181.groupTypes,
                integrations: model$181.integrations,
                metrics: model$181.metrics,
                openBranches: model$181.openBranches,
                properties: model$181.properties,
                propertyBundles: model$181.propertyBundles,
                sources: model$181.sources
              }
            };
    }
    if (variant === "UpdateDestinationProdApiKey") {
      var match$120 = action.VAL;
      var prodApiKey = match$120[1];
      var destinationId$17 = match$120[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateDestination(model._0, destinationId$17, (function (destination) {
                        var init = destination.apiKeys;
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: init.dev,
                                  prod: prodApiKey,
                                  other: init.other
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      }))
              };
      }
      var model$182 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$182.archive,
                branchPointer: model$182.branchPointer,
                migrations: model$182.migrations,
                rules: model$182.rules,
                types: model$182.types,
                categories: model$182.categories,
                destinations: Curry._3(TrackingPlanMappedModel.Destinations.update, model$182.destinations, destinationId$17, (function (destination) {
                        var init = destination.apiKeys;
                        return {
                                id: destination.id,
                                name: destination.name,
                                type_: destination.type_,
                                apiKeys: {
                                  dev: init.dev,
                                  prod: prodApiKey,
                                  other: init.other
                                },
                                includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                                disabledByDefault: destination.disabledByDefault
                              };
                      })),
                events: model$182.events,
                groupTypes: model$182.groupTypes,
                integrations: model$182.integrations,
                metrics: model$182.metrics,
                openBranches: model$182.openBranches,
                properties: model$182.properties,
                propertyBundles: model$182.propertyBundles,
                sources: model$182.sources
              }
            };
    }
    if (variant === "RemoveEventTrigger") {
      var match$121 = action.VAL;
      var triggerId$4 = match$121[1];
      var eventId$44 = match$121[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$44, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.keep($$event.triggers, (function (param) {
                                        return param.id !== triggerId$4;
                                      }))
                              };
                      }))
              };
      }
      var model$183 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$183.archive,
                branchPointer: model$183.branchPointer,
                migrations: model$183.migrations,
                rules: model$183.rules,
                types: model$183.types,
                categories: model$183.categories,
                destinations: model$183.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$183.events, eventId$44, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.keep($$event.triggers, (function (param) {
                                        return param.id !== triggerId$4;
                                      }))
                              };
                      })),
                groupTypes: model$183.groupTypes,
                integrations: model$183.integrations,
                metrics: model$183.metrics,
                openBranches: model$183.openBranches,
                properties: model$183.properties,
                propertyBundles: model$183.propertyBundles,
                sources: model$183.sources
              }
            };
    }
    if (variant === "ReorderGoals") {
      var match$122 = action.VAL;
      var maybePredecessorId$3 = match$122[1];
      var goalId$9 = match$122[0];
      if (model.TAG === /* Old */0) {
        var model$184 = model._0;
        var movedItem = Belt_List.getByU(model$184.goals, (function (goal) {
                return goal.id === goalId$9;
              }));
        var listWithoutItem = Belt_List.keepU(model$184.goals, (function (goal) {
                return goal.id !== goalId$9;
              }));
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$184.types,
                  properties: model$184.properties,
                  propertyBundles: model$184.propertyBundles,
                  events: model$184.events,
                  migrations: model$184.migrations,
                  sources: model$184.sources,
                  destinations: model$184.destinations,
                  groupTypes: model$184.groupTypes,
                  goals: movedItem !== undefined ? (
                      maybePredecessorId$3 !== undefined ? BeltListExtensions.flatMap(listWithoutItem, (function (goal) {
                                if (goal.id === maybePredecessorId$3) {
                                  return {
                                          hd: goal,
                                          tl: {
                                            hd: movedItem,
                                            tl: /* [] */0
                                          }
                                        };
                                } else {
                                  return {
                                          hd: goal,
                                          tl: /* [] */0
                                        };
                                }
                              })) : Belt_List.concat({
                              hd: movedItem,
                              tl: /* [] */0
                            }, listWithoutItem)
                    ) : model$184.goals,
                  metrics: model$184.metrics,
                  archive: model$184.archive,
                  openBranches: model$184.openBranches,
                  branchPointer: model$184.branchPointer,
                  rules: model$184.rules,
                  integrations: model$184.integrations
                }
              };
      }
      var model$185 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$185.archive,
                branchPointer: model$185.branchPointer,
                migrations: model$185.migrations,
                rules: model$185.rules,
                types: model$185.types,
                categories: Curry._3(TrackingPlanMappedModel.Categories.reorder, model$185.categories, goalId$9, maybePredecessorId$3),
                destinations: model$185.destinations,
                events: model$185.events,
                groupTypes: model$185.groupTypes,
                integrations: model$185.integrations,
                metrics: model$185.metrics,
                openBranches: model$185.openBranches,
                properties: model$185.properties,
                propertyBundles: model$185.propertyBundles,
                sources: model$185.sources
              }
            };
    }
    if (variant === "RemoveGoal") {
      var goalId$10 = action.VAL;
      if (model.TAG === /* Old */0) {
        var model$186 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$186.types,
                  properties: model$186.properties,
                  propertyBundles: model$186.propertyBundles,
                  events: model$186.events,
                  migrations: model$186.migrations,
                  sources: model$186.sources,
                  destinations: model$186.destinations,
                  groupTypes: model$186.groupTypes,
                  goals: Belt_List.keepU(model$186.goals, (function (goal) {
                          return goal.id !== goalId$10;
                        })),
                  metrics: model$186.metrics,
                  archive: model$186.archive,
                  openBranches: model$186.openBranches,
                  branchPointer: model$186.branchPointer,
                  rules: model$186.rules,
                  integrations: model$186.integrations
                }
              };
      }
      var model$187 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$187.archive,
                branchPointer: model$187.branchPointer,
                migrations: model$187.migrations,
                rules: model$187.rules,
                types: model$187.types,
                categories: Curry._2(TrackingPlanMappedModel.Categories.remove, model$187.categories, goalId$10),
                destinations: model$187.destinations,
                events: model$187.events,
                groupTypes: model$187.groupTypes,
                integrations: model$187.integrations,
                metrics: model$187.metrics,
                openBranches: model$187.openBranches,
                properties: model$187.properties,
                propertyBundles: model$187.propertyBundles,
                sources: model$187.sources
              }
            };
    }
    if (variant === "RemoveMetricWhereV2") {
      var match$123 = action.VAL;
      var id$8 = match$123[3];
      var metricItemId$6 = match$123[1];
      var metricId$18 = match$123[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateMetric(model._0, metricId$18, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId$6 === $$event.id) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: Belt_List.keepU($$event.where, (function (where) {
                                                            return where.id !== id$8;
                                                          })),
                                                    groupBy: $$event.groupBy
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      }))
              };
      }
      var model$188 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$188.archive,
                branchPointer: model$188.branchPointer,
                migrations: model$188.migrations,
                rules: model$188.rules,
                types: model$188.types,
                categories: model$188.categories,
                destinations: model$188.destinations,
                events: model$188.events,
                groupTypes: model$188.groupTypes,
                integrations: model$188.integrations,
                metrics: Curry._3(TrackingPlanMappedModel.Metrics.update, model$188.metrics, metricId$18, (function (metric) {
                        return {
                                id: metric.id,
                                name: metric.name,
                                description: metric.description,
                                type_: metric.type_,
                                items: Belt_List.mapU(metric.items, (function (metricItem) {
                                        var $$event = metricItem.VAL;
                                        if (metricItemId$6 === $$event.id) {
                                          return {
                                                  NAME: "Event",
                                                  VAL: {
                                                    id: $$event.id,
                                                    eventId: $$event.eventId,
                                                    where: Belt_List.keepU($$event.where, (function (where) {
                                                            return where.id !== id$8;
                                                          })),
                                                    groupBy: $$event.groupBy
                                                  }
                                                };
                                        } else {
                                          return metricItem;
                                        }
                                      }))
                              };
                      })),
                openBranches: model$188.openBranches,
                properties: model$188.properties,
                propertyBundles: model$188.propertyBundles,
                sources: model$188.sources
              }
            };
    }
    if (variant === "RemoveTriggerSource") {
      var match$124 = action.VAL;
      var sourceId$28 = match$124[2];
      var triggerId$5 = match$124[1];
      var eventId$45 = match$124[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEventTrigger(model._0, eventId$45, triggerId$5, (function (trigger) {
                        var sourceIds = trigger.sources;
                        var tmp;
                        if (sourceIds) {
                          var nextSourceIds = Belt_Array.keep(sourceIds._0, (function (id) {
                                  return id !== sourceId$28;
                                }));
                          tmp = nextSourceIds.length === 0 ? /* SourceIndependent */0 : /* SourceIds */({
                                _0: nextSourceIds
                              });
                        } else {
                          tmp = /* SourceIndependent */0;
                        }
                        return {
                                id: trigger.id,
                                sources: tmp,
                                description: trigger.description,
                                content: trigger.content
                              };
                      }))
              };
      }
      var model$189 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$189.archive,
                branchPointer: model$189.branchPointer,
                migrations: model$189.migrations,
                rules: model$189.rules,
                types: model$189.types,
                categories: model$189.categories,
                destinations: model$189.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$189.events, eventId$45, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: Belt_Array.mapU($$event.triggers, (function (trigger) {
                                        if (trigger.id !== triggerId$5) {
                                          return trigger;
                                        }
                                        var sourceIds = trigger.sources;
                                        var tmp;
                                        if (sourceIds) {
                                          var nextSourceIds = Belt_Array.keep(sourceIds._0, (function (id) {
                                                  return id !== sourceId$28;
                                                }));
                                          tmp = nextSourceIds.length === 0 ? /* SourceIndependent */0 : /* SourceIds */({
                                                _0: nextSourceIds
                                              });
                                        } else {
                                          tmp = /* SourceIndependent */0;
                                        }
                                        return {
                                                id: trigger.id,
                                                sources: tmp,
                                                description: trigger.description,
                                                content: trigger.content
                                              };
                                      }))
                              };
                      })),
                groupTypes: model$189.groupTypes,
                integrations: model$189.integrations,
                metrics: model$189.metrics,
                openBranches: model$189.openBranches,
                properties: model$189.properties,
                propertyBundles: model$189.propertyBundles,
                sources: model$189.sources
              }
            };
    }
    if (variant === "UpdatePropertyAbsence") {
      var match$125 = action.VAL;
      var maybeEventSourceAbsence = match$125[2];
      var propertyAbsenceUpdateType = match$125[1];
      var propertyId$38 = match$125[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateProperty(model._0, propertyId$38, (function (property) {
                        return updatePropertyAbsence(model, property, propertyAbsenceUpdateType, maybeEventSourceAbsence);
                      }))
              };
      }
      var model$190 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$190.archive,
                branchPointer: model$190.branchPointer,
                migrations: model$190.migrations,
                rules: model$190.rules,
                types: model$190.types,
                categories: model$190.categories,
                destinations: model$190.destinations,
                events: model$190.events,
                groupTypes: model$190.groupTypes,
                integrations: model$190.integrations,
                metrics: model$190.metrics,
                openBranches: model$190.openBranches,
                properties: Curry._3(TrackingPlanMappedModel.Properties.update, model$190.properties, propertyId$38, (function (property) {
                        return updatePropertyAbsence(model, property, propertyAbsenceUpdateType, maybeEventSourceAbsence);
                      })),
                propertyBundles: model$190.propertyBundles,
                sources: model$190.sources
              }
            };
    }
    if (variant === "Unarchive") {
      var item$2 = action.VAL;
      var variant$2 = item$2.NAME;
      if (variant$2 === "Destination") {
        var destinationId$18 = item$2.VAL;
        if (model.TAG === /* Old */0) {
          var model$191 = model._0;
          var init$1 = model$191.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$191.types,
                    properties: model$191.properties,
                    propertyBundles: model$191.propertyBundles,
                    events: model$191.events,
                    migrations: model$191.migrations,
                    sources: model$191.sources,
                    destinations: Belt_Option.mapWithDefault(Belt_List.getByU(model$191.archive.destinations, (function (destination) {
                                return destination.id === destinationId$18;
                              })), model$191.destinations, (function (destination) {
                            return Belt_List.concat(model$191.destinations, {
                                        hd: destination,
                                        tl: /* [] */0
                                      });
                          })),
                    groupTypes: model$191.groupTypes,
                    goals: model$191.goals,
                    metrics: model$191.metrics,
                    archive: {
                      events: init$1.events,
                      properties: init$1.properties,
                      propertyBundles: init$1.propertyBundles,
                      sources: init$1.sources,
                      destinations: Belt_List.keepU(model$191.archive.destinations, (function (destination) {
                              return destination.id !== destinationId$18;
                            })),
                      goals: init$1.goals,
                      metrics: init$1.metrics,
                      groupTypes: init$1.groupTypes
                    },
                    openBranches: model$191.openBranches,
                    branchPointer: model$191.branchPointer,
                    rules: model$191.rules,
                    integrations: model$191.integrations
                  }
                };
        }
        var model$192 = model._0;
        var init$2 = model$192.archive;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: {
                    categories: init$2.categories,
                    destinations: Curry._2(TrackingPlanMappedModel.Destinations.remove, model$192.archive.destinations, destinationId$18),
                    events: init$2.events,
                    groupTypes: init$2.groupTypes,
                    metrics: init$2.metrics,
                    properties: init$2.properties,
                    propertyBundles: init$2.propertyBundles,
                    sources: init$2.sources
                  },
                  branchPointer: model$192.branchPointer,
                  migrations: model$192.migrations,
                  rules: model$192.rules,
                  types: model$192.types,
                  categories: model$192.categories,
                  destinations: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Destinations.get, model$192.archive.destinations, destinationId$18), model$192.destinations, Curry._1(TrackingPlanMappedModel.Destinations.append, model$192.destinations)),
                  events: model$192.events,
                  groupTypes: model$192.groupTypes,
                  integrations: model$192.integrations,
                  metrics: model$192.metrics,
                  openBranches: model$192.openBranches,
                  properties: model$192.properties,
                  propertyBundles: model$192.propertyBundles,
                  sources: model$192.sources
                }
              };
      }
      if (variant$2 === "Integration") {
        return model;
      }
      if (variant$2 === "GroupType") {
        var groupTypeId$4 = item$2.VAL;
        if (model.TAG === /* Old */0) {
          var model$193 = model._0;
          var init$3 = model$193.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$193.types,
                    properties: model$193.properties,
                    propertyBundles: model$193.propertyBundles,
                    events: model$193.events,
                    migrations: model$193.migrations,
                    sources: model$193.sources,
                    destinations: model$193.destinations,
                    groupTypes: Belt_Option.mapWithDefault(Belt_Array.getByU(model$193.archive.groupTypes, (function (groupType) {
                                return groupType.id === groupTypeId$4;
                              })), model$193.groupTypes, (function (groupType) {
                            return Belt_Array.concat(model$193.groupTypes, [groupType]);
                          })),
                    goals: model$193.goals,
                    metrics: model$193.metrics,
                    archive: {
                      events: init$3.events,
                      properties: init$3.properties,
                      propertyBundles: init$3.propertyBundles,
                      sources: init$3.sources,
                      destinations: init$3.destinations,
                      goals: init$3.goals,
                      metrics: init$3.metrics,
                      groupTypes: Belt_Array.keepU(model$193.archive.groupTypes, (function (groupType) {
                              return groupType.id !== groupTypeId$4;
                            }))
                    },
                    openBranches: model$193.openBranches,
                    branchPointer: model$193.branchPointer,
                    rules: model$193.rules,
                    integrations: model$193.integrations
                  }
                };
        }
        var model$194 = model._0;
        var init$4 = model$194.archive;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: {
                    categories: init$4.categories,
                    destinations: init$4.destinations,
                    events: init$4.events,
                    groupTypes: Curry._2(TrackingPlanMappedModel.GroupTypes.remove, model$194.archive.groupTypes, groupTypeId$4),
                    metrics: init$4.metrics,
                    properties: init$4.properties,
                    propertyBundles: init$4.propertyBundles,
                    sources: init$4.sources
                  },
                  branchPointer: model$194.branchPointer,
                  migrations: model$194.migrations,
                  rules: model$194.rules,
                  types: model$194.types,
                  categories: model$194.categories,
                  destinations: model$194.destinations,
                  events: model$194.events,
                  groupTypes: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.GroupTypes.get, model$194.archive.groupTypes, groupTypeId$4), model$194.groupTypes, Curry._1(TrackingPlanMappedModel.GroupTypes.append, model$194.groupTypes)),
                  integrations: model$194.integrations,
                  metrics: model$194.metrics,
                  openBranches: model$194.openBranches,
                  properties: model$194.properties,
                  propertyBundles: model$194.propertyBundles,
                  sources: model$194.sources
                }
              };
      }
      if (variant$2 === "Property") {
        var propertyId$39 = item$2.VAL;
        if (model.TAG === /* Old */0) {
          var model$195 = model._0;
          var init$5 = model$195.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$195.types,
                    properties: Belt_Option.mapWithDefault(Belt_List.getByU(model$195.archive.properties, (function (property) {
                                if (property.TAG === /* PropertyRef */0) {
                                  return false;
                                } else {
                                  return property._0.id === propertyId$39;
                                }
                              })), model$195.properties, (function (property) {
                            return Belt_List.concat(model$195.properties, {
                                        hd: property,
                                        tl: /* [] */0
                                      });
                          })),
                    propertyBundles: model$195.propertyBundles,
                    events: model$195.events,
                    migrations: model$195.migrations,
                    sources: model$195.sources,
                    destinations: model$195.destinations,
                    groupTypes: model$195.groupTypes,
                    goals: model$195.goals,
                    metrics: model$195.metrics,
                    archive: {
                      events: init$5.events,
                      properties: Belt_List.keepU(model$195.archive.properties, (function (property) {
                              if (property.TAG === /* PropertyRef */0) {
                                return true;
                              } else {
                                return property._0.id !== propertyId$39;
                              }
                            })),
                      propertyBundles: init$5.propertyBundles,
                      sources: init$5.sources,
                      destinations: init$5.destinations,
                      goals: init$5.goals,
                      metrics: init$5.metrics,
                      groupTypes: init$5.groupTypes
                    },
                    openBranches: model$195.openBranches,
                    branchPointer: model$195.branchPointer,
                    rules: model$195.rules,
                    integrations: model$195.integrations
                  }
                };
        }
        var model$196 = model._0;
        var init$6 = model$196.archive;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: {
                    categories: init$6.categories,
                    destinations: init$6.destinations,
                    events: init$6.events,
                    groupTypes: init$6.groupTypes,
                    metrics: init$6.metrics,
                    properties: Curry._2(TrackingPlanMappedModel.Properties.remove, model$196.archive.properties, propertyId$39),
                    propertyBundles: init$6.propertyBundles,
                    sources: init$6.sources
                  },
                  branchPointer: model$196.branchPointer,
                  migrations: model$196.migrations,
                  rules: model$196.rules,
                  types: model$196.types,
                  categories: model$196.categories,
                  destinations: model$196.destinations,
                  events: model$196.events,
                  groupTypes: model$196.groupTypes,
                  integrations: model$196.integrations,
                  metrics: model$196.metrics,
                  openBranches: model$196.openBranches,
                  properties: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Properties.get, model$196.archive.properties, propertyId$39), model$196.properties, (function (property) {
                          return Curry._2(TrackingPlanMappedModel.Properties.append, model$196.properties, property);
                        })),
                  propertyBundles: model$196.propertyBundles,
                  sources: model$196.sources
                }
              };
      }
      if (variant$2 === "Event") {
        var eventId$46 = item$2.VAL;
        if (model.TAG === /* Old */0) {
          var model$197 = model._0;
          var init$7 = model$197.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$197.types,
                    properties: model$197.properties,
                    propertyBundles: model$197.propertyBundles,
                    events: Belt_Option.mapWithDefault(Belt_List.getByU(model$197.archive.events, (function ($$event) {
                                return $$event.id === eventId$46;
                              })), model$197.events, (function ($$event) {
                            return Belt_List.concat(model$197.events, {
                                        hd: $$event,
                                        tl: /* [] */0
                                      });
                          })),
                    migrations: model$197.migrations,
                    sources: model$197.sources,
                    destinations: model$197.destinations,
                    groupTypes: model$197.groupTypes,
                    goals: model$197.goals,
                    metrics: model$197.metrics,
                    archive: {
                      events: Belt_List.keepU(model$197.archive.events, (function ($$event) {
                              return $$event.id !== eventId$46;
                            })),
                      properties: init$7.properties,
                      propertyBundles: init$7.propertyBundles,
                      sources: init$7.sources,
                      destinations: init$7.destinations,
                      goals: init$7.goals,
                      metrics: init$7.metrics,
                      groupTypes: init$7.groupTypes
                    },
                    openBranches: model$197.openBranches,
                    branchPointer: model$197.branchPointer,
                    rules: model$197.rules,
                    integrations: model$197.integrations
                  }
                };
        }
        var model$198 = model._0;
        var init$8 = model$198.archive;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: {
                    categories: init$8.categories,
                    destinations: init$8.destinations,
                    events: Curry._2(TrackingPlanMappedModel.Events.remove, model$198.archive.events, eventId$46),
                    groupTypes: init$8.groupTypes,
                    metrics: init$8.metrics,
                    properties: init$8.properties,
                    propertyBundles: init$8.propertyBundles,
                    sources: init$8.sources
                  },
                  branchPointer: model$198.branchPointer,
                  migrations: model$198.migrations,
                  rules: model$198.rules,
                  types: model$198.types,
                  categories: model$198.categories,
                  destinations: model$198.destinations,
                  events: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Events.get, model$198.archive.events, eventId$46), model$198.events, Curry._1(TrackingPlanMappedModel.Events.append, model$198.events)),
                  groupTypes: model$198.groupTypes,
                  integrations: model$198.integrations,
                  metrics: model$198.metrics,
                  openBranches: model$198.openBranches,
                  properties: model$198.properties,
                  propertyBundles: model$198.propertyBundles,
                  sources: model$198.sources
                }
              };
      }
      if (variant$2 === "PropertyGroup") {
        var propertyBundleId$1 = item$2.VAL;
        if (model.TAG === /* Old */0) {
          var model$199 = model._0;
          var init$9 = model$199.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$199.types,
                    properties: model$199.properties,
                    propertyBundles: Belt_Option.mapWithDefault(Belt_List.getByU(model$199.archive.propertyBundles, (function (group) {
                                return group.id === propertyBundleId$1;
                              })), model$199.propertyBundles, (function (group) {
                            return Belt_List.concat(model$199.propertyBundles, {
                                        hd: group,
                                        tl: /* [] */0
                                      });
                          })),
                    events: model$199.events,
                    migrations: model$199.migrations,
                    sources: model$199.sources,
                    destinations: model$199.destinations,
                    groupTypes: model$199.groupTypes,
                    goals: model$199.goals,
                    metrics: model$199.metrics,
                    archive: {
                      events: init$9.events,
                      properties: init$9.properties,
                      propertyBundles: Belt_List.keepU(model$199.archive.propertyBundles, (function (group) {
                              return group.id !== propertyBundleId$1;
                            })),
                      sources: init$9.sources,
                      destinations: init$9.destinations,
                      goals: init$9.goals,
                      metrics: init$9.metrics,
                      groupTypes: init$9.groupTypes
                    },
                    openBranches: model$199.openBranches,
                    branchPointer: model$199.branchPointer,
                    rules: model$199.rules,
                    integrations: model$199.integrations
                  }
                };
        }
        var model$200 = model._0;
        var init$10 = model$200.archive;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: {
                    categories: init$10.categories,
                    destinations: init$10.destinations,
                    events: init$10.events,
                    groupTypes: init$10.groupTypes,
                    metrics: init$10.metrics,
                    properties: init$10.properties,
                    propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.remove, model$200.archive.propertyBundles, propertyBundleId$1),
                    sources: init$10.sources
                  },
                  branchPointer: model$200.branchPointer,
                  migrations: model$200.migrations,
                  rules: model$200.rules,
                  types: model$200.types,
                  categories: model$200.categories,
                  destinations: model$200.destinations,
                  events: model$200.events,
                  groupTypes: model$200.groupTypes,
                  integrations: model$200.integrations,
                  metrics: model$200.metrics,
                  openBranches: model$200.openBranches,
                  properties: model$200.properties,
                  propertyBundles: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.PropertyBundles.get, model$200.archive.propertyBundles, propertyBundleId$1), model$200.propertyBundles, Curry._1(TrackingPlanMappedModel.PropertyBundles.append, model$200.propertyBundles)),
                  sources: model$200.sources
                }
              };
      }
      if (variant$2 === "Goal") {
        var categoryId$1 = item$2.VAL;
        if (model.TAG === /* Old */0) {
          var model$201 = model._0;
          var init$11 = model$201.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$201.types,
                    properties: model$201.properties,
                    propertyBundles: model$201.propertyBundles,
                    events: model$201.events,
                    migrations: model$201.migrations,
                    sources: model$201.sources,
                    destinations: model$201.destinations,
                    groupTypes: model$201.groupTypes,
                    goals: Belt_Option.mapWithDefault(Belt_List.getByU(model$201.archive.goals, (function (goal) {
                                return goal.id === categoryId$1;
                              })), model$201.goals, (function (goal) {
                            return Belt_List.concat(model$201.goals, {
                                        hd: goal,
                                        tl: /* [] */0
                                      });
                          })),
                    metrics: model$201.metrics,
                    archive: {
                      events: init$11.events,
                      properties: init$11.properties,
                      propertyBundles: init$11.propertyBundles,
                      sources: init$11.sources,
                      destinations: init$11.destinations,
                      goals: Belt_List.keepU(model$201.archive.goals, (function (goal) {
                              return goal.id !== categoryId$1;
                            })),
                      metrics: init$11.metrics,
                      groupTypes: init$11.groupTypes
                    },
                    openBranches: model$201.openBranches,
                    branchPointer: model$201.branchPointer,
                    rules: model$201.rules,
                    integrations: model$201.integrations
                  }
                };
        }
        var model$202 = model._0;
        var init$12 = model$202.archive;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: {
                    categories: Curry._2(TrackingPlanMappedModel.Categories.remove, model$202.archive.categories, categoryId$1),
                    destinations: init$12.destinations,
                    events: init$12.events,
                    groupTypes: init$12.groupTypes,
                    metrics: init$12.metrics,
                    properties: init$12.properties,
                    propertyBundles: init$12.propertyBundles,
                    sources: init$12.sources
                  },
                  branchPointer: model$202.branchPointer,
                  migrations: model$202.migrations,
                  rules: model$202.rules,
                  types: model$202.types,
                  categories: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Categories.get, model$202.archive.categories, categoryId$1), model$202.categories, Curry._1(TrackingPlanMappedModel.Categories.append, model$202.categories)),
                  destinations: model$202.destinations,
                  events: model$202.events,
                  groupTypes: model$202.groupTypes,
                  integrations: model$202.integrations,
                  metrics: model$202.metrics,
                  openBranches: model$202.openBranches,
                  properties: model$202.properties,
                  propertyBundles: model$202.propertyBundles,
                  sources: model$202.sources
                }
              };
      }
      if (variant$2 === "Metric") {
        var metricId$19 = item$2.VAL;
        if (model.TAG === /* Old */0) {
          var model$203 = model._0;
          var init$13 = model$203.archive;
          return {
                  TAG: /* Old */0,
                  _0: {
                    types: model$203.types,
                    properties: model$203.properties,
                    propertyBundles: model$203.propertyBundles,
                    events: model$203.events,
                    migrations: model$203.migrations,
                    sources: model$203.sources,
                    destinations: model$203.destinations,
                    groupTypes: model$203.groupTypes,
                    goals: model$203.goals,
                    metrics: Belt_Option.mapWithDefault(Belt_List.getByU(model$203.archive.metrics, (function (metric) {
                                return metric.id === metricId$19;
                              })), model$203.metrics, (function (metric) {
                            return Belt_List.concat(model$203.metrics, {
                                        hd: metric,
                                        tl: /* [] */0
                                      });
                          })),
                    archive: {
                      events: init$13.events,
                      properties: init$13.properties,
                      propertyBundles: init$13.propertyBundles,
                      sources: init$13.sources,
                      destinations: init$13.destinations,
                      goals: init$13.goals,
                      metrics: Belt_List.keepU(model$203.archive.metrics, (function (metric) {
                              return metric.id !== metricId$19;
                            })),
                      groupTypes: init$13.groupTypes
                    },
                    openBranches: model$203.openBranches,
                    branchPointer: model$203.branchPointer,
                    rules: model$203.rules,
                    integrations: model$203.integrations
                  }
                };
        }
        var model$204 = model._0;
        var init$14 = model$204.archive;
        return {
                TAG: /* Mapped */1,
                _0: {
                  archive: {
                    categories: init$14.categories,
                    destinations: init$14.destinations,
                    events: init$14.events,
                    groupTypes: init$14.groupTypes,
                    metrics: Curry._2(TrackingPlanMappedModel.Metrics.remove, model$204.archive.metrics, metricId$19),
                    properties: init$14.properties,
                    propertyBundles: init$14.propertyBundles,
                    sources: init$14.sources
                  },
                  branchPointer: model$204.branchPointer,
                  migrations: model$204.migrations,
                  rules: model$204.rules,
                  types: model$204.types,
                  categories: model$204.categories,
                  destinations: model$204.destinations,
                  events: model$204.events,
                  groupTypes: model$204.groupTypes,
                  integrations: model$204.integrations,
                  metrics: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Metrics.get, model$204.archive.metrics, metricId$19), model$204.metrics, Curry._1(TrackingPlanMappedModel.Metrics.append, model$204.metrics)),
                  openBranches: model$204.openBranches,
                  properties: model$204.properties,
                  propertyBundles: model$204.propertyBundles,
                  sources: model$204.sources
                }
              };
      }
      var sourceId$29 = item$2.VAL;
      if (model.TAG === /* Old */0) {
        var model$205 = model._0;
        var init$15 = model$205.archive;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$205.types,
                  properties: model$205.properties,
                  propertyBundles: model$205.propertyBundles,
                  events: model$205.events,
                  migrations: model$205.migrations,
                  sources: Belt_Option.mapWithDefault(Belt_List.getByU(model$205.archive.sources, (function (source) {
                              return source.id === sourceId$29;
                            })), model$205.sources, (function (source) {
                          return Belt_List.concat(model$205.sources, {
                                      hd: source,
                                      tl: /* [] */0
                                    });
                        })),
                  destinations: model$205.destinations,
                  groupTypes: model$205.groupTypes,
                  goals: model$205.goals,
                  metrics: model$205.metrics,
                  archive: {
                    events: init$15.events,
                    properties: init$15.properties,
                    propertyBundles: init$15.propertyBundles,
                    sources: Belt_List.keepU(model$205.archive.sources, (function (source) {
                            return source.id !== sourceId$29;
                          })),
                    destinations: init$15.destinations,
                    goals: init$15.goals,
                    metrics: init$15.metrics,
                    groupTypes: init$15.groupTypes
                  },
                  openBranches: model$205.openBranches,
                  branchPointer: model$205.branchPointer,
                  rules: model$205.rules,
                  integrations: model$205.integrations
                }
              };
      }
      var model$206 = model._0;
      var init$16 = model$206.archive;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: {
                  categories: init$16.categories,
                  destinations: init$16.destinations,
                  events: init$16.events,
                  groupTypes: init$16.groupTypes,
                  metrics: init$16.metrics,
                  properties: init$16.properties,
                  propertyBundles: init$16.propertyBundles,
                  sources: Curry._2(TrackingPlanMappedModel.Sources.remove, model$206.archive.sources, sourceId$29)
                },
                branchPointer: model$206.branchPointer,
                migrations: model$206.migrations,
                rules: model$206.rules,
                types: model$206.types,
                categories: model$206.categories,
                destinations: model$206.destinations,
                events: model$206.events,
                groupTypes: model$206.groupTypes,
                integrations: model$206.integrations,
                metrics: model$206.metrics,
                openBranches: model$206.openBranches,
                properties: model$206.properties,
                propertyBundles: model$206.propertyBundles,
                sources: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Sources.get, model$206.archive.sources, sourceId$29), model$206.sources, Curry._1(TrackingPlanMappedModel.Sources.append, model$206.sources))
              }
            };
    }
    if (variant === "RemoveSourceConfig") {
      var match$126 = action.VAL;
      var sourceConfig$1 = match$126[1];
      var sourceId$30 = match$126[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateSource(model._0, sourceId$30, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: Belt_List.keep(source.config, (function (config) {
                                        return config !== sourceConfig$1;
                                      }))
                              };
                      }))
              };
      }
      var model$207 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$207.archive,
                branchPointer: model$207.branchPointer,
                migrations: model$207.migrations,
                rules: model$207.rules,
                types: model$207.types,
                categories: model$207.categories,
                destinations: model$207.destinations,
                events: model$207.events,
                groupTypes: model$207.groupTypes,
                integrations: model$207.integrations,
                metrics: model$207.metrics,
                openBranches: model$207.openBranches,
                properties: model$207.properties,
                propertyBundles: model$207.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$207.sources, sourceId$30, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: source.segmentDestinationOptions,
                                config: Belt_List.keep(source.config, (function (config) {
                                        return config !== sourceConfig$1;
                                      }))
                              };
                      }))
              }
            };
    }
    if (variant === "AddSourceSegmentIntegration") {
      var match$127 = action.VAL;
      var isActive$4 = match$127[2];
      var segmentIntegration$2 = match$127[1];
      var sourceId$31 = match$127[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateSource(model._0, sourceId$31, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: Belt_List.some(source.segmentDestinationOptions, (function (param) {
                                        return param[0] === segmentIntegration$2;
                                      })) ? source.segmentDestinationOptions : Belt_List.concat(source.segmentDestinationOptions, {
                                        hd: [
                                          segmentIntegration$2,
                                          isActive$4
                                        ],
                                        tl: /* [] */0
                                      }),
                                config: source.config
                              };
                      }))
              };
      }
      var model$208 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$208.archive,
                branchPointer: model$208.branchPointer,
                migrations: model$208.migrations,
                rules: model$208.rules,
                types: model$208.types,
                categories: model$208.categories,
                destinations: model$208.destinations,
                events: model$208.events,
                groupTypes: model$208.groupTypes,
                integrations: model$208.integrations,
                metrics: model$208.metrics,
                openBranches: model$208.openBranches,
                properties: model$208.properties,
                propertyBundles: model$208.propertyBundles,
                sources: Curry._3(TrackingPlanMappedModel.Sources.update, model$208.sources, sourceId$31, (function (source) {
                        return {
                                id: source.id,
                                name: source.name,
                                language: source.language,
                                platform: source.platform,
                                destinations: source.destinations,
                                filename: source.filename,
                                path: source.path,
                                segmentDestinationOptions: Belt_List.some(source.segmentDestinationOptions, (function (param) {
                                        return param[0] === segmentIntegration$2;
                                      })) ? source.segmentDestinationOptions : Belt_List.concat(source.segmentDestinationOptions, {
                                        hd: [
                                          segmentIntegration$2,
                                          isActive$4
                                        ],
                                        tl: /* [] */0
                                      }),
                                config: source.config
                              };
                      }))
              }
            };
    }
    if (variant === "RemoveRule") {
      var ruleId$2 = action.VAL[0];
      if (model.TAG === /* Old */0) {
        var model$209 = model._0;
        return {
                TAG: /* Old */0,
                _0: {
                  types: model$209.types,
                  properties: model$209.properties,
                  propertyBundles: model$209.propertyBundles,
                  events: model$209.events,
                  migrations: model$209.migrations,
                  sources: model$209.sources,
                  destinations: model$209.destinations,
                  groupTypes: model$209.groupTypes,
                  goals: model$209.goals,
                  metrics: model$209.metrics,
                  archive: model$209.archive,
                  openBranches: model$209.openBranches,
                  branchPointer: model$209.branchPointer,
                  rules: Belt_List.keepU(model$209.rules, (function (rule) {
                          return rule.id !== ruleId$2;
                        })),
                  integrations: model$209.integrations
                }
              };
      }
      var model$210 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$210.archive,
                branchPointer: model$210.branchPointer,
                migrations: model$210.migrations,
                rules: Belt_Array.keepU(model$210.rules, (function (rule) {
                        return rule.id !== ruleId$2;
                      })),
                types: model$210.types,
                categories: model$210.categories,
                destinations: model$210.destinations,
                events: model$210.events,
                groupTypes: model$210.groupTypes,
                integrations: model$210.integrations,
                metrics: model$210.metrics,
                openBranches: model$210.openBranches,
                properties: model$210.properties,
                propertyBundles: model$210.propertyBundles,
                sources: model$210.sources
              }
            };
    }
    if (variant === "AddPropertyGroupToEvent") {
      var match$128 = action.VAL;
      var groupId$6 = match$128[1];
      var eventId$47 = match$128[0];
      if (model.TAG === /* Old */0) {
        return {
                TAG: /* Old */0,
                _0: StateUtils.updateEvent(model._0, eventId$47, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: Belt_List.someU($$event.propertyBundles, (function (group) {
                                        return group.id === groupId$6;
                                      })) ? $$event.propertyBundles : Belt_List.concat($$event.propertyBundles, {
                                        hd: {
                                          id: groupId$6,
                                          description: ""
                                        },
                                        tl: /* [] */0
                                      }),
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      }))
              };
      }
      var model$211 = model._0;
      return {
              TAG: /* Mapped */1,
              _0: {
                archive: model$211.archive,
                branchPointer: model$211.branchPointer,
                migrations: model$211.migrations,
                rules: model$211.rules,
                types: model$211.types,
                categories: model$211.categories,
                destinations: model$211.destinations,
                events: Curry._3(TrackingPlanMappedModel.Events.update, model$211.events, eventId$47, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: Belt_List.someU($$event.propertyBundles, (function (group) {
                                        return group.id === groupId$6;
                                      })) ? $$event.propertyBundles : Belt_List.concat($$event.propertyBundles, {
                                        hd: {
                                          id: groupId$6,
                                          description: ""
                                        },
                                        tl: /* [] */0
                                      }),
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                groupTypes: model$211.groupTypes,
                integrations: model$211.integrations,
                metrics: model$211.metrics,
                openBranches: model$211.openBranches,
                properties: model$211.properties,
                propertyBundles: model$211.propertyBundles,
                sources: model$211.sources
              }
            };
    }
    var metricId$20 = action.VAL;
    if (model.TAG === /* Old */0) {
      var model$212 = model._0;
      return {
              TAG: /* Old */0,
              _0: {
                types: model$212.types,
                properties: model$212.properties,
                propertyBundles: model$212.propertyBundles,
                events: model$212.events,
                migrations: model$212.migrations,
                sources: model$212.sources,
                destinations: model$212.destinations,
                groupTypes: model$212.groupTypes,
                goals: Belt_List.mapU(model$212.goals, (function (goal) {
                        return {
                                id: goal.id,
                                name: goal.name,
                                description: goal.description,
                                metrics: Belt_List.keepU(goal.metrics, (function (id) {
                                        return id !== metricId$20;
                                      })),
                                events: goal.events
                              };
                      })),
                metrics: Belt_List.keepU(model$212.metrics, (function (metric) {
                        return metric.id !== metricId$20;
                      })),
                archive: model$212.archive,
                openBranches: model$212.openBranches,
                branchPointer: model$212.branchPointer,
                rules: model$212.rules,
                integrations: model$212.integrations
              }
            };
    }
    var model$213 = model._0;
    return {
            TAG: /* Mapped */1,
            _0: {
              archive: model$213.archive,
              branchPointer: model$213.branchPointer,
              migrations: model$213.migrations,
              rules: model$213.rules,
              types: model$213.types,
              categories: Curry._2(TrackingPlanMappedModel.Categories.mapToT, model$213.categories, (function (category) {
                      return {
                              id: category.id,
                              name: category.name,
                              description: category.description,
                              metrics: Belt_List.keepU(category.metrics, (function (id) {
                                      return id !== metricId$20;
                                    })),
                              events: category.events
                            };
                    })),
              destinations: model$213.destinations,
              events: model$213.events,
              groupTypes: model$213.groupTypes,
              integrations: model$213.integrations,
              metrics: Curry._2(TrackingPlanMappedModel.Metrics.keep, model$213.metrics, (function (metric) {
                      return metric.id !== metricId$20;
                    })),
              openBranches: model$213.openBranches,
              properties: model$213.properties,
              propertyBundles: model$213.propertyBundles,
              sources: model$213.sources
            }
          };
  }
  if (action === "SchemaAccessed" || action === "StartedImport") {
    return model;
  }
  if (action === "MigratePropertyOptionalAndExcludedSourcesToAbsence") {
    if (model.TAG === /* Old */0) {
      var model$214 = model._0;
      return {
              TAG: /* Old */0,
              _0: hasMigrated(model$214, "PropertyOptionalAndExcludedSourcesToAbsence") ? model$214 : ({
                    types: model$214.types,
                    properties: Belt_List.mapU(model$214.properties, (function (property) {
                            if (property.TAG === /* PropertyRef */0) {
                              return property;
                            }
                            var property$1 = property._0;
                            return {
                                    TAG: /* Property */1,
                                    _0: {
                                      id: property$1.id,
                                      name: property$1.name,
                                      uniqueName: property$1.uniqueName,
                                      description: property$1.description,
                                      type_: property$1.type_,
                                      sendAs: property$1.sendAs,
                                      validations: property$1.validations,
                                      auto: property$1.auto,
                                      builtIn: property$1.builtIn,
                                      analyticsTools: property$1.analyticsTools,
                                      devPlatforms: property$1.devPlatforms,
                                      list: property$1.list,
                                      operation: property$1.operation,
                                      optionalDeprecated: property$1.optionalDeprecated,
                                      excludedSourcesDeprecated: property$1.excludedSourcesDeprecated,
                                      optionalWhenInObject: property$1.optionalDeprecated,
                                      absence: StateUtils.makePropertyAbsenceFromOptionalAndExcludedSources(property$1, model$214)
                                    }
                                  };
                          })),
                    propertyBundles: model$214.propertyBundles,
                    events: model$214.events,
                    migrations: Belt_List.add(model$214.migrations, "PropertyOptionalAndExcludedSourcesToAbsence"),
                    sources: model$214.sources,
                    destinations: model$214.destinations,
                    groupTypes: model$214.groupTypes,
                    goals: model$214.goals,
                    metrics: model$214.metrics,
                    archive: model$214.archive,
                    openBranches: model$214.openBranches,
                    branchPointer: model$214.branchPointer,
                    rules: model$214.rules,
                    integrations: model$214.integrations
                  })
            };
    }
    var model$215 = model._0;
    return {
            TAG: /* Mapped */1,
            _0: hasMigrated_boxed(model, "PropertyOptionalAndExcludedSourcesToAbsence") ? model$215 : ({
                  archive: model$215.archive,
                  branchPointer: model$215.branchPointer,
                  migrations: Belt_Array.concat(["PropertyOptionalAndExcludedSourcesToAbsence"], model$215.migrations),
                  rules: model$215.rules,
                  types: model$215.types,
                  categories: model$215.categories,
                  destinations: model$215.destinations,
                  events: model$215.events,
                  groupTypes: model$215.groupTypes,
                  integrations: model$215.integrations,
                  metrics: model$215.metrics,
                  openBranches: model$215.openBranches,
                  properties: Curry._2(TrackingPlanMappedModel.Properties.mapToT, model$215.properties, (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name,
                                  uniqueName: property.uniqueName,
                                  description: property.description,
                                  type_: property.type_,
                                  sendAs: property.sendAs,
                                  validations: property.validations,
                                  auto: property.auto,
                                  builtIn: property.builtIn,
                                  analyticsTools: property.analyticsTools,
                                  devPlatforms: property.devPlatforms,
                                  list: property.list,
                                  operation: property.operation,
                                  optionalDeprecated: property.optionalDeprecated,
                                  excludedSourcesDeprecated: property.excludedSourcesDeprecated,
                                  optionalWhenInObject: property.optionalDeprecated,
                                  absence: StateUtils.makePropertyAbsenceFromOptionalAndExcludedSources_mappedModel(property, model$215)
                                };
                        })),
                  propertyBundles: model$215.propertyBundles,
                  sources: model$215.sources
                })
          };
  }
  if (action === "MigrateSkipWebFnTagToIncludeInCodegen") {
    if (model.TAG === /* Old */0) {
      var model$216 = model._0;
      var webSourcesInWorkspace = Belt_List.keepMapU(model$216.sources, (function (source) {
              if (Caml_obj.caml_equal(source.platform, "Web")) {
                return source.id;
              }
              
            }));
      return {
              TAG: /* Old */0,
              _0: {
                types: model$216.types,
                properties: model$216.properties,
                propertyBundles: model$216.propertyBundles,
                events: Belt_List.mapU(model$216.events, (function ($$event) {
                        var skipWebFnTag = "skip-web-fn";
                        var hasSkipWebFnTag = Belt_List.someU($$event.tags, (function (tag) {
                                return tag === skipWebFnTag;
                              }));
                        if (hasSkipWebFnTag) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  uniqueName: $$event.uniqueName,
                                  description: $$event.description,
                                  properties: $$event.properties,
                                  propertyBundles: $$event.propertyBundles,
                                  variants: $$event.variants,
                                  types: $$event.types,
                                  tags: Belt_List.keepU($$event.tags, (function (tag) {
                                          return tag !== skipWebFnTag;
                                        })),
                                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                  includeSources: Belt_List.mapU($$event.includeSources, (function (includedSource) {
                                          if (Belt_List.has(webSourcesInWorkspace, includedSource.id, (function (prim0, prim1) {
                                                    return prim0 === prim1;
                                                  }))) {
                                            return {
                                                    id: includedSource.id,
                                                    includeInCodegen: false,
                                                    inspectorValidation: includedSource.inspectorValidation
                                                  };
                                          } else {
                                            return includedSource;
                                          }
                                        })),
                                  includeDestinations: $$event.includeDestinations,
                                  hashes: $$event.hashes,
                                  propertyWhitelist: $$event.propertyWhitelist,
                                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                  triggers: $$event.triggers
                                };
                        } else {
                          return $$event;
                        }
                      })),
                migrations: {
                  hd: "SkipWebFnTagToIncludeInCodegen",
                  tl: model$216.migrations
                },
                sources: model$216.sources,
                destinations: model$216.destinations,
                groupTypes: model$216.groupTypes,
                goals: model$216.goals,
                metrics: model$216.metrics,
                archive: model$216.archive,
                openBranches: model$216.openBranches,
                branchPointer: model$216.branchPointer,
                rules: model$216.rules,
                integrations: model$216.integrations
              }
            };
    }
    var model$217 = model._0;
    var webSourcesInWorkspace$1 = Curry._2(TrackingPlanMappedModel.Sources.keep, model$217.sources, (function (source) {
            return Caml_obj.caml_equal(source.platform, "Web");
          }));
    return {
            TAG: /* Mapped */1,
            _0: {
              archive: model$217.archive,
              branchPointer: model$217.branchPointer,
              migrations: Belt_Array.concat(["SkipWebFnTagToIncludeInCodegen"], model$217.migrations),
              rules: model$217.rules,
              types: model$217.types,
              categories: model$217.categories,
              destinations: model$217.destinations,
              events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$217.events, (function ($$event) {
                      var skipWebFnTag = "skip-web-fn";
                      var hasSkipWebFnTag = Belt_List.someU($$event.tags, (function (tag) {
                              return tag === skipWebFnTag;
                            }));
                      if (hasSkipWebFnTag) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: Belt_List.keepU($$event.tags, (function (tag) {
                                        return tag !== skipWebFnTag;
                                      })),
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: Belt_List.mapU($$event.includeSources, (function (includedSource) {
                                        if (Curry._2(TrackingPlanMappedModel.Sources.has, webSourcesInWorkspace$1, includedSource.id)) {
                                          return {
                                                  id: includedSource.id,
                                                  includeInCodegen: false,
                                                  inspectorValidation: includedSource.inspectorValidation
                                                };
                                        } else {
                                          return includedSource;
                                        }
                                      })),
                                includeDestinations: $$event.includeDestinations,
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      } else {
                        return $$event;
                      }
                    })),
              groupTypes: model$217.groupTypes,
              integrations: model$217.integrations,
              metrics: model$217.metrics,
              openBranches: model$217.openBranches,
              properties: model$217.properties,
              propertyBundles: model$217.propertyBundles,
              sources: model$217.sources
            }
          };
  }
  if (action === "MigrateSourceDestinationMapping") {
    if (model.TAG === /* Old */0) {
      var model$218 = model._0;
      return {
              TAG: /* Old */0,
              _0: {
                types: model$218.types,
                properties: model$218.properties,
                propertyBundles: model$218.propertyBundles,
                events: Belt_List.mapU(model$218.events, (function ($$event) {
                        return {
                                id: $$event.id,
                                name: $$event.name,
                                uniqueName: $$event.uniqueName,
                                description: $$event.description,
                                properties: $$event.properties,
                                propertyBundles: $$event.propertyBundles,
                                variants: $$event.variants,
                                types: $$event.types,
                                tags: $$event.tags,
                                excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                includeSources: $$event.includeSources,
                                includeDestinations: Belt_List.keepMapU($$event.includeSources, (function (includedSource) {
                                        return Belt_Option.map(StateUtils.getSourceById(includedSource.id, model$218), (function (source) {
                                                      return {
                                                              NAME: "IncludeSourceDestination",
                                                              VAL: [
                                                                includedSource.id,
                                                                StateUtils.getSourceDestinationIds(source.destinations)
                                                              ]
                                                            };
                                                    }));
                                      })),
                                hashes: $$event.hashes,
                                propertyWhitelist: $$event.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                triggers: $$event.triggers
                              };
                      })),
                migrations: {
                  hd: "SourceDestinationMigration",
                  tl: model$218.migrations
                },
                sources: model$218.sources,
                destinations: model$218.destinations,
                groupTypes: model$218.groupTypes,
                goals: model$218.goals,
                metrics: model$218.metrics,
                archive: model$218.archive,
                openBranches: model$218.openBranches,
                branchPointer: model$218.branchPointer,
                rules: model$218.rules,
                integrations: model$218.integrations
              }
            };
    }
    var model$219 = model._0;
    return {
            TAG: /* Mapped */1,
            _0: {
              archive: model$219.archive,
              branchPointer: model$219.branchPointer,
              migrations: Belt_Array.concat(["SourceDestinationMigration"], model$219.migrations),
              rules: model$219.rules,
              types: model$219.types,
              categories: model$219.categories,
              destinations: model$219.destinations,
              events: Curry._2(TrackingPlanMappedModel.Events.mapToT, model$219.events, (function ($$event) {
                      return {
                              id: $$event.id,
                              name: $$event.name,
                              uniqueName: $$event.uniqueName,
                              description: $$event.description,
                              properties: $$event.properties,
                              propertyBundles: $$event.propertyBundles,
                              variants: $$event.variants,
                              types: $$event.types,
                              tags: $$event.tags,
                              excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                              includeSources: $$event.includeSources,
                              includeDestinations: Belt_List.keepMapU($$event.includeSources, (function (includedSource) {
                                      return Belt_Option.map(Curry._2(TrackingPlanMappedModel.Sources.get, model$219.sources, includedSource.id), (function (source) {
                                                    return {
                                                            NAME: "IncludeSourceDestination",
                                                            VAL: [
                                                              includedSource.id,
                                                              StateUtils.getSourceDestinationIds(source.destinations)
                                                            ]
                                                          };
                                                  }));
                                    })),
                              hashes: $$event.hashes,
                              propertyWhitelist: $$event.propertyWhitelist,
                              eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                              userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                              triggers: $$event.triggers
                            };
                    })),
              groupTypes: model$219.groupTypes,
              integrations: model$219.integrations,
              metrics: model$219.metrics,
              openBranches: model$219.openBranches,
              properties: model$219.properties,
              propertyBundles: model$219.propertyBundles,
              sources: model$219.sources
            }
          };
  }
  if (action === "ResetTrackingPlan") {
    if (model.TAG === /* Old */0) {
      var model$220 = model._0;
      return {
              TAG: /* Old */0,
              _0: {
                types: TrackingPlanModel.empty.types,
                properties: TrackingPlanModel.empty.properties,
                propertyBundles: TrackingPlanModel.empty.propertyBundles,
                events: TrackingPlanModel.empty.events,
                migrations: model$220.migrations,
                sources: model$220.sources,
                destinations: model$220.destinations,
                groupTypes: TrackingPlanModel.empty.groupTypes,
                goals: TrackingPlanModel.empty.goals,
                metrics: TrackingPlanModel.empty.metrics,
                archive: TrackingPlanModel.empty.archive,
                openBranches: TrackingPlanModel.empty.openBranches,
                branchPointer: TrackingPlanModel.empty.branchPointer,
                rules: TrackingPlanModel.empty.rules,
                integrations: TrackingPlanModel.empty.integrations
              }
            };
    }
    var model$221 = model._0;
    return {
            TAG: /* Mapped */1,
            _0: {
              archive: TrackingPlanMappedModel.empty.archive,
              branchPointer: TrackingPlanMappedModel.empty.branchPointer,
              migrations: model$221.migrations,
              rules: TrackingPlanMappedModel.empty.rules,
              types: TrackingPlanMappedModel.empty.types,
              categories: TrackingPlanMappedModel.empty.categories,
              destinations: model$221.destinations,
              events: TrackingPlanMappedModel.empty.events,
              groupTypes: TrackingPlanMappedModel.empty.groupTypes,
              integrations: TrackingPlanMappedModel.empty.integrations,
              metrics: TrackingPlanMappedModel.empty.metrics,
              openBranches: TrackingPlanMappedModel.empty.openBranches,
              properties: TrackingPlanMappedModel.empty.properties,
              propertyBundles: TrackingPlanMappedModel.empty.propertyBundles,
              sources: model$221.sources
            }
          };
  }
  if (model.TAG === /* Old */0) {
    var model$222 = model._0;
    return {
            TAG: /* Old */0,
            _0: {
              types: model$222.types,
              properties: model$222.properties,
              propertyBundles: model$222.propertyBundles,
              events: model$222.events,
              migrations: {
                hd: "RemoveUnarchivedItemsFromArchive",
                tl: model$222.migrations
              },
              sources: model$222.sources,
              destinations: model$222.destinations,
              groupTypes: model$222.groupTypes,
              goals: model$222.goals,
              metrics: model$222.metrics,
              archive: {
                events: Belt_List.keepU(model$222.archive.events, (function (archivedEvent) {
                        return !Belt_List.someU(model$222.events, (function ($$event) {
                                      return $$event.id === archivedEvent.id;
                                    }));
                      })),
                properties: Belt_List.keepU(model$222.archive.properties, (function (archivedProperty) {
                        return !Belt_List.someU(model$222.properties, (function (property) {
                                      if (property.TAG === /* PropertyRef */0 || archivedProperty.TAG === /* PropertyRef */0) {
                                        return false;
                                      } else {
                                        return property._0.id === archivedProperty._0.id;
                                      }
                                    }));
                      })),
                propertyBundles: Belt_List.keepU(model$222.archive.propertyBundles, (function (archivedGroup) {
                        return !Belt_List.someU(model$222.propertyBundles, (function (group) {
                                      return group.id === archivedGroup.id;
                                    }));
                      })),
                sources: Belt_List.keepU(model$222.archive.sources, (function (archivedSource) {
                        return !Belt_List.someU(model$222.sources, (function (source) {
                                      return source.id === archivedSource.id;
                                    }));
                      })),
                destinations: Belt_List.keepU(model$222.archive.destinations, (function (archivedDestination) {
                        return !Belt_List.someU(model$222.destinations, (function (destination) {
                                      return destination.id === archivedDestination.id;
                                    }));
                      })),
                goals: Belt_List.keepU(model$222.archive.goals, (function (archivedGoal) {
                        return !Belt_List.someU(model$222.goals, (function (goal) {
                                      return goal.id === archivedGoal.id;
                                    }));
                      })),
                metrics: Belt_List.keepU(model$222.archive.metrics, (function (archivedMetric) {
                        return !Belt_List.someU(model$222.metrics, (function (metric) {
                                      return metric.id === archivedMetric.id;
                                    }));
                      })),
                groupTypes: Belt_Array.keepU(model$222.archive.groupTypes, (function (archivedGroupType) {
                        return !Belt_Array.someU(model$222.groupTypes, (function (groupType) {
                                      return groupType.id === archivedGroupType.id;
                                    }));
                      }))
              },
              openBranches: model$222.openBranches,
              branchPointer: model$222.branchPointer,
              rules: model$222.rules,
              integrations: model$222.integrations
            }
          };
  }
  var model$223 = model._0;
  return {
          TAG: /* Mapped */1,
          _0: {
            archive: {
              categories: Curry._2(TrackingPlanMappedModel.Categories.keep, model$223.archive.categories, (function (param) {
                      return !Curry._2(TrackingPlanMappedModel.Categories.has, model$223.categories, param.id);
                    })),
              destinations: Curry._2(TrackingPlanMappedModel.Destinations.keep, model$223.archive.destinations, (function (param) {
                      return !Curry._2(TrackingPlanMappedModel.Destinations.has, model$223.destinations, param.id);
                    })),
              events: Curry._2(TrackingPlanMappedModel.Events.keep, model$223.archive.events, (function (param) {
                      return !Curry._2(TrackingPlanMappedModel.Events.has, model$223.events, param.id);
                    })),
              groupTypes: Curry._2(TrackingPlanMappedModel.GroupTypes.keep, model$223.archive.groupTypes, (function (param) {
                      return !Curry._2(TrackingPlanMappedModel.GroupTypes.has, model$223.groupTypes, param.id);
                    })),
              metrics: Curry._2(TrackingPlanMappedModel.Metrics.keep, model$223.archive.metrics, (function (param) {
                      return !Curry._2(TrackingPlanMappedModel.Metrics.has, model$223.metrics, param.id);
                    })),
              properties: Curry._2(TrackingPlanMappedModel.Properties.keep, model$223.archive.properties, (function (property) {
                      return !Curry._2(TrackingPlanMappedModel.Properties.has, model$223.properties, property.id);
                    })),
              propertyBundles: Curry._2(TrackingPlanMappedModel.PropertyBundles.keep, model$223.archive.propertyBundles, (function (param) {
                      return !Curry._2(TrackingPlanMappedModel.PropertyBundles.has, model$223.propertyBundles, param.id);
                    })),
              sources: Curry._2(TrackingPlanMappedModel.Sources.keep, model$223.archive.sources, (function (param) {
                      return !Curry._2(TrackingPlanMappedModel.Sources.has, model$223.sources, param.id);
                    }))
            },
            branchPointer: model$223.branchPointer,
            migrations: Belt_Array.concat(["RemoveUnarchivedItemsFromArchive"], model$223.migrations),
            rules: model$223.rules,
            types: model$223.types,
            categories: model$223.categories,
            destinations: model$223.destinations,
            events: model$223.events,
            groupTypes: model$223.groupTypes,
            integrations: model$223.integrations,
            metrics: model$223.metrics,
            openBranches: model$223.openBranches,
            properties: model$223.properties,
            propertyBundles: model$223.propertyBundles,
            sources: model$223.sources
          }
        };
}

function reduce(model, action) {
  return TrackingPlanMappedModel.unboxModel(reduceFunction({
                  TAG: /* Old */0,
                  _0: model
                }, action));
}

function reduceMapped(model, action) {
  return TrackingPlanMappedModel.unboxModel(reduceFunction({
                  TAG: /* Mapped */1,
                  _0: model
                }, action));
}

var Model;

exports.Model = Model;
exports.hasMigrated = hasMigrated;
exports.hasMigrated_boxed = hasMigrated_boxed;
exports.createEverythingSometimesSentMatrix = createEverythingSometimesSentMatrix;
exports.extractMixedSourceAbsenceFromAnySourceAbsence = extractMixedSourceAbsenceFromAnySourceAbsence;
exports.computeCurrentAbsenceMatrix = computeCurrentAbsenceMatrix;
exports.updatePropertyAbsence = updatePropertyAbsence;
exports.reduceFunction = reduceFunction;
exports.reduce = reduce;
exports.reduceMapped = reduceMapped;
/* AvoConfig Not a pure module */
