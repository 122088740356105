// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function encode(t) {
  return Json_encode.object_({
              hd: [
                "type",
                "Viewer"
              ],
              tl: /* [] */0
            });
}

function decode(json) {
  Json_decode.field("type", Json_decode.string, json);
  return /* Viewer */0;
}

var Permission = {
  encode: encode,
  decode: decode
};

function encode$1(t) {
  return Json_encode.object_({
              hd: [
                "type",
                "PublicApi"
              ],
              tl: /* [] */0
            });
}

function decode$1(json) {
  Json_decode.field("type", Json_decode.string, json);
  return /* PublicApi */0;
}

var AccountType = {
  encode: encode$1,
  decode: decode$1
};

function encode$2(t) {
  return Json_encode.object_({
              hd: [
                "name",
                t.name
              ],
              tl: {
                hd: [
                  "accountType",
                  encode$1(t.accountType)
                ],
                tl: {
                  hd: [
                    "permission",
                    encode(t.permission)
                  ],
                  tl: {
                    hd: [
                      "createdAt",
                      t.createdAt.valueOf()
                    ],
                    tl: {
                      hd: [
                        "createdBy",
                        t.createdBy
                      ],
                      tl: {
                        hd: [
                          "lastUsedAt",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), Belt_Option.map(t.lastUsedAt, (function (prim) {
                                      return prim.valueOf();
                                    })))
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function decode$2(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          accountType: Json_decode.field("accountType", decode$1, json),
          permission: Json_decode.field("permission", decode, json),
          createdAt: new Date(Json_decode.field("createdAt", Json_decode.$$float, json)),
          createdBy: Json_decode.field("createdBy", Json_decode.string, json),
          lastUsedAt: Belt_Option.map(Json_decode.optional((function (param) {
                      return Json_decode.field("lastUsedAt", Json_decode.$$float, param);
                    }), json), (function (prim) {
                  return new Date(prim);
                }))
        };
}

exports.Permission = Permission;
exports.AccountType = AccountType;
exports.encode = encode$2;
exports.decode = decode$2;
/* No side effect */
