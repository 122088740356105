// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("../Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var StateUtils = require("../stateUtils.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ChangedEvent = require("./ChangedEvent.bs.js");
var DeletedEvent = require("./DeletedEvent.bs.js");
var AppFeatureFlag = require("../AppFeatureFlag.bs.js");
var BranchImplementationUtils = require("./BranchImplementationUtils.bs.js");

function ChangedEvents(Props) {
  var branchStatus = Props.branchStatus;
  var diffViewType = Props.diffViewType;
  var events = Props.events;
  var fromModel = Props.fromModel;
  var goToCommentId = Props.goToCommentId;
  var source = Props.source;
  var sourceUpdatesLoadingState = Props.sourceUpdatesLoadingState;
  var toModel = Props.toModel;
  var serverTrackingWithDeviceId = AppFeatureFlag.useFeatureFlag("ServerTrackingWithDeviceId");
  var enrichedModelRef = {
    contents: undefined
  };
  var enrichedFromModelRef = {
    contents: undefined
  };
  var enrichedModelWithoutValidSourceRef = {
    contents: undefined
  };
  var enrichedFromModelWithoutValidSourceRef = {
    contents: undefined
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.gap(Css.px(16)),
                            tl: {
                              hd: Css.empty({
                                    hd: Css.selector(" + section", {
                                          hd: Css.display("block"),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, Belt_List.toArray(Belt_List.map(Curry._1(BranchImplementationUtils.EventComparator.sort, events), (function ($$event) {
                            if ($$event.NAME === "Updated") {
                              var match = $$event.VAL;
                              var $$event$1 = match[0];
                              var enrichedToModel = BranchImplementationUtils.getEnrichedModelForSnippet(source, $$event$1, toModel, enrichedModelRef, enrichedModelWithoutValidSourceRef, serverTrackingWithDeviceId);
                              var enrichedFromModel = diffViewType === "noDiff" ? undefined : BranchImplementationUtils.getEnrichedModelForSnippet(source, $$event$1, fromModel, enrichedFromModelRef, enrichedFromModelWithoutValidSourceRef, serverTrackingWithDeviceId);
                              var tmp = {
                                branchStatus: branchStatus,
                                enrichedToModel: enrichedToModel,
                                diffViewType: diffViewType,
                                event: $$event$1,
                                fromModel: fromModel,
                                sourceId: source.id,
                                status: match[1],
                                sourceUpdatesLoadingState: sourceUpdatesLoadingState,
                                toModel: toModel,
                                key: $$event$1.id
                              };
                              if (enrichedFromModel !== undefined) {
                                tmp.enrichedFromModel = Caml_option.valFromOption(enrichedFromModel);
                              }
                              if (goToCommentId !== undefined) {
                                tmp.goToCommentId = goToCommentId;
                              }
                              return React.createElement(ChangedEvent.make, tmp);
                            }
                            var $$event$2 = $$event.VAL[0];
                            var enrichedModel = BranchImplementationUtils.getEnrichedModelForSnippet(source, $$event$2, fromModel, enrichedFromModelRef, enrichedFromModelWithoutValidSourceRef, serverTrackingWithDeviceId);
                            var tmp$1 = {
                              branchStatus: branchStatus,
                              event: $$event$2,
                              toModel: toModel,
                              sourceId: source.id,
                              enrichedModel: enrichedModel,
                              fromModel: fromModel,
                              diffViewType: diffViewType,
                              key: $$event$2.id
                            };
                            if (goToCommentId !== undefined) {
                              tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
                            }
                            return React.createElement(DeletedEvent.make, tmp$1);
                          })))), React.createElement("section", {
                  className: Curry._1(Css.style, {
                        hd: Css.label("empty-events"),
                        tl: {
                          hd: Css.padding(Css.px(32)),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", Styles.Color.grey20),
                                tl: {
                                  hd: Css.maxWidth(Css.px(600)),
                                  tl: {
                                    hd: Css.display("none"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      weight: "Semi",
                      color: Styles.Color.grey80,
                      children: "Could not codegen, source incomplete"
                    }), React.createElement(Spacer.make, {
                      height: 32
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.grey70,
                      children: "Hold tight, we are still working out all the kinks for the Implement feature."
                    }), Belt_List.map(source.destinations, (function (param) {
                        return StateUtils.getDestinationById(param.destinationId, toModel);
                      })) === /* [] */0 ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                            height: 16
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.grey70,
                            children: "💡 It looks like your source doesn’t have any destinations. To create snippets you need to add a destination."
                          })) : null));
}

var make = ChangedEvents;

exports.make = make;
/* Css Not a pure module */
