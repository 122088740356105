// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Curry = require("rescript/lib/js/curry.js");
var Shortid = require("shortid");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameUtils = require("../../../shared/utils/NameUtils.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var ImportFormats = require("./ImportFormats.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var ImportParserTypes = require("./ImportParserTypes.bs.js");
var BeltListExtensions = require("../BeltListExtensions.bs.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");
var Sync = require("csv-parse/lib/sync");
var BeltArrayExtensions = require("../BeltArrayExtensions.bs.js");
var ImportParserFiltering = require("./ImportParserFiltering.bs.js");
var ImportParserUniqueIndexes = require("./ImportParserUniqueIndexes.bs.js");

function getColumnConfig(param) {
  return Belt_Array.reduce([
              ImportParserTypes.StringOp.getColumnPresence(param.eventNameParser),
              ImportParserTypes.StringOp.getColumnPresence(param.eventDescriptionParser),
              ImportParserTypes.StringArrayOp.getColumnPresence(param.eventCategoriesParser),
              ImportParserTypes.StringArrayOp.getColumnPresence(param.eventTagsParser),
              ImportParserTypes.StringArrayOp.getColumnPresence(param.eventSourcesParser),
              ImportParserTypes.StringTupleOp.getColumnPresence(param.eventNameMappingParser),
              ImportParserTypes.StringOp.getColumnPresence(param.propertyNameParser),
              ImportParserTypes.StringOp.getColumnPresence(param.propertyDescriptionParser),
              ImportParserTypes.StringTupleOp.getColumnPresence(param.propertyTypeParser),
              ImportParserTypes.BoolOp.getColumnPresence(param.propertyRequiredParser),
              ImportParserTypes.BoolOp.getColumnPresence(param.propertyListParser),
              ImportParserTypes.StringArrayOp.getColumnPresence(param.propertyMatchesParser),
              ImportParserTypes.SendAsOp.getColumnPresence(param.propertySendAsParser),
              ImportParserTypes.StringOp.getColumnPresence(param.propertyGroupParser),
              ImportParserTypes.StringArrayOp.getColumnPresence(param.propertyEventsParser),
              ImportParserTypes.StringTupleOp.getColumnPresence(param.propertyNameMappingParser),
              ImportParserTypes.StringOp.getColumnPresence(param.propertyPinnedValueParser)
            ], {
              requiredColumnNames: [],
              optionalColumnNames: [],
              allColumnNames: []
            }, (function (columnConfig, columnPresence) {
                if (typeof columnPresence !== "object") {
                  return columnConfig;
                }
                if (columnPresence.NAME === "requiredColumnNames") {
                  var columnNames = columnPresence.VAL;
                  return {
                          requiredColumnNames: Belt_Array.concat(columnConfig.requiredColumnNames, columnNames),
                          optionalColumnNames: columnConfig.optionalColumnNames,
                          allColumnNames: Belt_Array.concat(columnConfig.allColumnNames, columnNames)
                        };
                }
                var columnName = columnPresence.VAL;
                return {
                        requiredColumnNames: columnConfig.requiredColumnNames,
                        optionalColumnNames: Belt_Array.concat(columnConfig.optionalColumnNames, [columnName]),
                        allColumnNames: Belt_Array.concat(columnConfig.allColumnNames, [columnName])
                      };
              }));
}

function parseFormat(format, csv) {
  var customParser = format.customParser;
  var propertyPinnedValueParser = format.propertyPinnedValueParser;
  var propertyNameMappingParser = format.propertyNameMappingParser;
  var propertyEventsParser = format.propertyEventsParser;
  var propertyGroupParser = format.propertyGroupParser;
  var propertySendAsParser = format.propertySendAsParser;
  var propertyMatchesParser = format.propertyMatchesParser;
  var propertyListParser = format.propertyListParser;
  var propertyRequiredParser = format.propertyRequiredParser;
  var propertyTypeParser = format.propertyTypeParser;
  var propertyDescriptionParser = format.propertyDescriptionParser;
  var propertyNameParser = format.propertyNameParser;
  var eventNameMappingParser = format.eventNameMappingParser;
  var eventSourcesParser = format.eventSourcesParser;
  var eventTagsParser = format.eventTagsParser;
  var eventCategoriesParser = format.eventCategoriesParser;
  var eventDescriptionParser = format.eventDescriptionParser;
  var eventNameParser = format.eventNameParser;
  var formatName = format.formatName;
  var match = getColumnConfig(format);
  var allColumnNames = match.allColumnNames;
  var requiredColumnNames = match.requiredColumnNames;
  var headerRowIndex = Belt_Array.getIndexByU(csv, (function (row) {
          return Belt_Array.everyU(requiredColumnNames, (function (columnName) {
                        return Belt_Array.someU(row, (function (cell) {
                                      return cell.toLowerCase() === columnName.toLowerCase();
                                    }));
                      }));
        }));
  if (headerRowIndex !== undefined) {
    var headerRow = Belt_Array.getExn(csv, headerRowIndex);
    var columnIndexes = Belt_MapString.fromArray(Belt_Array.keepMapU(allColumnNames, (function (columnName) {
                var maybeColumnIndex = Belt_Array.getIndexByU(headerRow, (function (cell) {
                        return cell.toLowerCase() === columnName.toLowerCase();
                      }));
                if (maybeColumnIndex !== undefined) {
                  return [
                          columnName,
                          maybeColumnIndex
                        ];
                }
                
              })));
    var parseCategory = function (row, rowIndex) {
      return ImportParserTypes.StringArrayOp.parse(row, rowIndex, columnIndexes, eventCategoriesParser);
    };
    var parseEvent = function (row, rowIndex) {
      var match = ImportParserTypes.StringTupleOp.parse(row, rowIndex, columnIndexes, eventNameMappingParser);
      var sendAsName = match[0];
      return {
              id: Shortid(),
              name: NameUtils.replaceNewlinesWithWhitespace(ImportParserTypes.StringOp.parse(row, rowIndex, columnIndexes, eventNameParser)),
              description: ImportParserTypes.StringOp.parse(row, rowIndex, columnIndexes, eventDescriptionParser),
              categories: ImportParserTypes.StringArrayOp.parse(row, rowIndex, columnIndexes, eventCategoriesParser),
              tags: ImportParserTypes.StringArrayOp.parse(row, rowIndex, columnIndexes, eventTagsParser),
              properties: [],
              sources: ImportParserTypes.StringArrayOp.parse(row, rowIndex, columnIndexes, eventSourcesParser),
              nameMapping: sendAsName !== "" ? [[
                    NameUtils.replaceNewlinesWithWhitespace(sendAsName),
                    match[1]
                  ]] : []
            };
    };
    var parseProperty = function (row, rowIndex) {
      var match = ImportParserTypes.StringTupleOp.parse(row, rowIndex, columnIndexes, propertyTypeParser);
      var type_ = match[0];
      var match$1 = ImportParserTypes.StringTupleOp.parse(row, rowIndex, columnIndexes, propertyNameMappingParser);
      var sendAsName = match$1[0];
      var rawPinnedValue = ImportParserTypes.StringOp.parse(row, rowIndex, columnIndexes, propertyPinnedValueParser);
      var pinnedValue;
      if (rawPinnedValue === "") {
        pinnedValue = undefined;
      } else {
        switch (type_) {
          case "bool" :
              pinnedValue = Belt_Option.map(Pervasives.bool_of_string_opt(rawPinnedValue), (function (bool) {
                      return {
                              NAME: "BooleanLit",
                              VAL: bool
                            };
                    }));
              break;
          case "float" :
              pinnedValue = Belt_Option.map(Pervasives.float_of_string_opt(rawPinnedValue), (function ($$float) {
                      return {
                              NAME: "FloatLit",
                              VAL: $$float
                            };
                    }));
              break;
          case "int" :
              pinnedValue = Belt_Option.map(Pervasives.int_of_string_opt(rawPinnedValue), (function ($$int) {
                      return {
                              NAME: "IntLit",
                              VAL: $$int
                            };
                    }));
              break;
          case "any" :
          case "string" :
              pinnedValue = {
                NAME: "StringLit",
                VAL: rawPinnedValue
              };
              break;
          default:
            pinnedValue = undefined;
        }
      }
      return {
              name: NameUtils.replaceNewlinesWithWhitespace(ImportParserTypes.StringOp.parse(row, rowIndex, columnIndexes, propertyNameParser)),
              description: ImportParserTypes.StringOp.parse(row, rowIndex, columnIndexes, propertyDescriptionParser),
              type_: type_,
              typeWarning: match[1],
              required: ImportParserTypes.BoolOp.parse(row, rowIndex, columnIndexes, propertyRequiredParser),
              list: ImportParserTypes.BoolOp.parse(row, rowIndex, columnIndexes, propertyListParser),
              matches: ImportParserTypes.StringArrayOp.parse(row, rowIndex, columnIndexes, propertyMatchesParser),
              sendAs: ImportParserTypes.SendAsOp.parse(row, rowIndex, columnIndexes, propertySendAsParser),
              propertyGroup: ImportParserTypes.StringOp.parse(row, rowIndex, columnIndexes, propertyGroupParser),
              propertyEvents: ImportParserTypes.StringArrayOp.parse(row, rowIndex, columnIndexes, propertyEventsParser),
              nameMapping: sendAsName !== "" ? [
                  NameUtils.replaceNewlinesWithWhitespace(sendAsName),
                  match$1[1]
                ] : undefined,
              pinnedValue: pinnedValue
            };
    };
    var match$1 = Belt_Array.reduceWithIndex(Belt_Array.sliceToEnd(csv, headerRowIndex + 1 | 0), [
          [],
          [],
          [],
          undefined,
          undefined
        ], (function (param, row, rowIndex) {
            var currentEventName = param[4];
            var currentCategories = param[3];
            var warnings = param[2];
            var propertiesWithoutEvent = param[1];
            var events = param[0];
            var rowIndex$1 = (headerRowIndex + rowIndex | 0) + 2 | 0;
            var rowString = row.join(", ");
            var maybeParsedCategory;
            try {
              var parsedCategory = parseCategory(row, rowIndex$1);
              maybeParsedCategory = Caml_obj.caml_equal(parsedCategory, []) ? ({
                    TAG: /* Error */1,
                    _0: "Categories not found in row " + String(rowIndex$1)
                  }) : ({
                    TAG: /* Ok */0,
                    _0: parsedCategory
                  });
            }
            catch (raw_error){
              var error = Caml_js_exceptions.internalToOCamlException(raw_error);
              maybeParsedCategory = error.RE_EXN_ID === ImportParserTypes.ParseWarning ? ({
                    TAG: /* Error */1,
                    _0: error._1
                  }) : ({
                    TAG: /* Error */1,
                    _0: error
                  });
            }
            var maybeParsedEvent;
            try {
              var parsedEvent = parseEvent(row, rowIndex$1);
              maybeParsedEvent = {
                TAG: /* Ok */0,
                _0: parsedEvent
              };
            }
            catch (raw_error$1){
              var error$1 = Caml_js_exceptions.internalToOCamlException(raw_error$1);
              maybeParsedEvent = error$1.RE_EXN_ID === ImportParserTypes.ParseWarning ? ({
                    TAG: /* Error */1,
                    _0: error$1._1
                  }) : ({
                    TAG: /* Error */1,
                    _0: error$1
                  });
            }
            var maybeParsedProperty;
            try {
              var parsedProperty = parseProperty(row, rowIndex$1);
              maybeParsedProperty = {
                TAG: /* Ok */0,
                _0: parsedProperty
              };
            }
            catch (raw_error$2){
              var error$2 = Caml_js_exceptions.internalToOCamlException(raw_error$2);
              maybeParsedProperty = error$2.RE_EXN_ID === ImportParserTypes.ParseWarning ? ({
                    TAG: /* Error */1,
                    _0: error$2._1
                  }) : ({
                    TAG: /* Error */1,
                    _0: error$2
                  });
            }
            var match;
            var exit = 0;
            if (maybeParsedCategory.TAG === /* Ok */0 || maybeParsedEvent.TAG === /* Ok */0 || maybeParsedProperty.TAG === /* Ok */0) {
              exit = 1;
            } else {
              var nextWarnings = Belt_Array.concat(warnings, [String(rowIndex$1) + ": Could not parse row: " + rowString]);
              match = [
                events,
                propertiesWithoutEvent,
                nextWarnings,
                currentCategories,
                currentEventName
              ];
            }
            if (exit === 1) {
              var currentCategories$1;
              currentCategories$1 = maybeParsedCategory.TAG === /* Ok */0 ? maybeParsedCategory._0 : currentCategories;
              var match$1;
              if (maybeParsedEvent.TAG === /* Ok */0) {
                var parsedEvent$1 = maybeParsedEvent._0;
                var existingOutputEvent = Belt_Array.getByU(events, (function (existingOutputEvent) {
                        return existingOutputEvent.name === parsedEvent$1.name;
                      }));
                match$1 = existingOutputEvent !== undefined ? [
                    events,
                    existingOutputEvent.name
                  ] : [
                    Belt_Array.concat(events, [{
                            id: parsedEvent$1.id,
                            name: parsedEvent$1.name,
                            description: parsedEvent$1.description,
                            categories: Belt_Option.getWithDefault(currentCategories$1, []),
                            tags: parsedEvent$1.tags,
                            properties: parsedEvent$1.properties,
                            sources: parsedEvent$1.sources,
                            nameMapping: parsedEvent$1.nameMapping
                          }]),
                    parsedEvent$1.name
                  ];
              } else {
                match$1 = [
                  events,
                  currentEventName
                ];
              }
              var currentEventName$1 = match$1[1];
              var events$1 = match$1[0];
              var match$2;
              var exit$1 = 0;
              if (currentEventName$1 !== undefined) {
                if (maybeParsedProperty.TAG === /* Ok */0) {
                  exit$1 = 2;
                } else {
                  match$2 = maybeParsedCategory.TAG === /* Ok */0 || maybeParsedEvent.TAG === /* Ok */0 ? [
                      events$1,
                      propertiesWithoutEvent,
                      warnings
                    ] : Pervasives.failwith("we should never end up in this property case?");
                }
              } else if (maybeParsedProperty.TAG === /* Ok */0) {
                exit$1 = 2;
              } else {
                match$2 = [
                  events$1,
                  propertiesWithoutEvent,
                  warnings
                ];
              }
              if (exit$1 === 2) {
                if (maybeParsedProperty._0.name === "") {
                  match$2 = [
                    events$1,
                    propertiesWithoutEvent,
                    warnings
                  ];
                } else if (currentEventName$1 !== undefined) {
                  var parsedProperty$1 = maybeParsedProperty._0;
                  match$2 = [
                    Belt_Array.mapU(events$1, (function ($$event) {
                            if ($$event.name === currentEventName$1) {
                              return {
                                      id: $$event.id,
                                      name: $$event.name,
                                      description: $$event.description,
                                      categories: $$event.categories,
                                      tags: $$event.tags,
                                      properties: Belt_Array.concat($$event.properties, [parsedProperty$1]),
                                      sources: $$event.sources,
                                      nameMapping: $$event.nameMapping
                                    };
                            } else {
                              return $$event;
                            }
                          })),
                    propertiesWithoutEvent,
                    warnings
                  ];
                } else {
                  var propertiesWithoutEvent$1 = Belt_Array.concat(propertiesWithoutEvent, [maybeParsedProperty._0]);
                  match$2 = [
                    events$1,
                    propertiesWithoutEvent$1,
                    warnings
                  ];
                }
              }
              match = [
                match$2[0],
                match$2[1],
                match$2[2],
                currentCategories$1,
                currentEventName$1
              ];
            }
            return [
                    match[0],
                    match[1],
                    match[2],
                    match[3],
                    match[4]
                  ];
          }));
    var output_events = match$1[0];
    var output_propertiesWithoutEvent = match$1[1];
    var output_warnings = match$1[2];
    var output = {
      formatName: formatName,
      events: output_events,
      propertiesWithoutEvent: output_propertiesWithoutEvent,
      warnings: output_warnings
    };
    if (customParser !== undefined) {
      return Curry._2(customParser, output, csv);
    } else {
      return output;
    }
  }
  var maybeFoundColumns = Belt_Option.flatMap(Belt_Array.get(Belt_Array.mapU(csv, (function (row) {
                    return Belt_Array.keepU(row, (function (cell) {
                                  return Belt_Array.someU(requiredColumnNames, (function (columnName) {
                                                return cell.toLowerCase() === columnName.toLowerCase();
                                              }));
                                }));
                  })).sort(function (a, b) {
                return Caml.caml_int_compare(b.length, a.length);
              }), 0), (function (foundColumns) {
          if (Caml_obj.caml_equal(foundColumns, [])) {
            return ;
          } else {
            return foundColumns;
          }
        }));
  var missingColumns = Belt_Option.mapWithDefault(maybeFoundColumns, requiredColumnNames, (function (foundColumns) {
          return Belt_Array.keepU(allColumnNames, (function (columnName) {
                        return !Belt_Array.someU(foundColumns, (function (foundColumn) {
                                      return foundColumn.toLowerCase() === columnName.toLowerCase();
                                    }));
                      }));
        }));
  throw {
        RE_EXN_ID: ImportParserTypes.ParseFormatError,
        _1: {
          foundColumns: Belt_Option.mapWithDefault(maybeFoundColumns, 0, (function (prim) {
                  return prim.length;
                })),
          missingColumns: missingColumns.length,
          message: "Missing expected columns: " + missingColumns.join(", ") + Belt_Option.mapWithDefault(maybeFoundColumns, "", (function (foundColumns) {
                  return ".\nExpected columns found in file: " + (foundColumns.join(", ") + ".");
                })),
          name: formatName
        },
        Error: new Error()
      };
}

function oneOf(formats, csv) {
  var _formats = formats;
  var _errors = /* [] */0;
  while(true) {
    var errors = _errors;
    var formats$1 = _formats;
    if (formats$1) {
      try {
        return parseFormat(formats$1.hd, csv);
      }
      catch (raw_e){
        var e = Caml_js_exceptions.internalToOCamlException(raw_e);
        if (e.RE_EXN_ID === ImportParserTypes.ParseFormatError) {
          _errors = {
            hd: e._1,
            tl: errors
          };
          _formats = formats$1.tl;
          continue ;
        }
        throw e;
      }
    } else {
      var errors$1 = Belt_List.sortU(errors, (function (a, b) {
              return Caml.caml_int_compare(b.foundColumns, a.foundColumns);
            }));
      throw {
            RE_EXN_ID: ImportParserTypes.ParseError,
            _1: Belt_Array.map(Belt_List.toArray(errors$1), (function (error) {
                    return [
                            error.name,
                            error.message
                          ];
                  })),
            Error: new Error()
          };
    }
  };
}

function processRawOutput(model, rawParserOutput) {
  var events = rawParserOutput.events;
  var isPropertyEqual = function (a, b) {
    if (a.name === b.name && a.description === b.description && a.type_ === b.type_ && a.list === b.list) {
      return Caml_obj.caml_equal(a.sendAs, b.sendAs);
    } else {
      return false;
    }
  };
  var rawToModelProperty = function (rawProperty, rawEvent) {
    var warning = rawProperty.typeWarning;
    return {
            id: Shortid(),
            name: rawProperty.name,
            uniqueNameIndex: undefined,
            description: rawProperty.description,
            type_: rawProperty.type_,
            list: rawProperty.list,
            matches: rawProperty.matches,
            warnings: warning !== undefined ? [warning] : [],
            presence: Belt_Option.getWithDefault(Belt_Option.map(rawEvent, (function ($$event) {
                        return Belt_MapString.fromArray([[
                                      $$event.id,
                                      rawProperty.required ? /* AlwaysSent */0 : /* SometimesSent */1
                                    ]]);
                      })), undefined),
            sendAs: rawParserOutput.formatName === "Mixpanel CSV Export" && Belt_Option.mapWithDefault(rawEvent, false, (function ($$event) {
                    return $$event.name === "$user";
                  })) ? /* UserProperty */2 : rawProperty.sendAs,
            nameMapping: rawProperty.nameMapping
          };
  };
  var dedupeProperties = function (globalPropertiesOpt, properties) {
    var globalProperties = globalPropertiesOpt !== undefined ? globalPropertiesOpt : [];
    return Belt_Array.reduceU(properties, globalProperties, (function (globalProperties, param) {
                  var prop = param[1];
                  var rawEvent = param[0];
                  if (Belt_Array.someU(globalProperties, (function (globalProp) {
                            return isPropertyEqual(globalProp, prop);
                          }))) {
                    return Belt_Array.map(globalProperties, (function (globalProperty) {
                                  if (!isPropertyEqual(globalProperty, prop)) {
                                    return globalProperty;
                                  }
                                  var warning = prop.typeWarning;
                                  return {
                                          id: globalProperty.id,
                                          name: globalProperty.name,
                                          uniqueNameIndex: globalProperty.uniqueNameIndex,
                                          description: globalProperty.description,
                                          type_: globalProperty.type_,
                                          list: globalProperty.list,
                                          matches: Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concat(globalProperty.matches, prop.matches))).sort(),
                                          warnings: Belt_Array.concat(globalProperty.warnings, warning !== undefined ? [warning] : []),
                                          presence: Belt_Option.mapWithDefault(rawEvent, globalProperty.presence, (function ($$event) {
                                                  return Belt_MapString.set(globalProperty.presence, $$event.id, prop.required ? /* AlwaysSent */0 : /* SometimesSent */1);
                                                })),
                                          sendAs: globalProperty.sendAs,
                                          nameMapping: globalProperty.nameMapping
                                        };
                                }));
                  } else {
                    return Belt_Array.concat(globalProperties, [rawToModelProperty(prop, rawEvent)]);
                  }
                }));
  };
  var dedupedPropertiesWithoutEvents = dedupeProperties([], Belt_Array.map(rawParserOutput.propertiesWithoutEvent, (function (property) {
              return [
                      undefined,
                      property
                    ];
            })));
  var globalProperties = dedupeProperties(dedupedPropertiesWithoutEvents, Belt_Array.concatMany(Belt_Array.map(events, (function ($$event) {
                  return Belt_Array.map($$event.properties, (function (property) {
                                return [
                                        $$event,
                                        property
                                      ];
                              }));
                }))));
  var globalPropertyGroups = Belt_Array.mapU(Belt_MapString.toArray(Belt_Array.reduce(Belt_Array.concatMany(Belt_Array.map(events, (function ($$event) {
                          return Belt_Array.keepMap($$event.properties, (function (property) {
                                        var maybeGlobalProperty = Belt_Array.getBy(globalProperties, (function (globalProperty) {
                                                return globalProperty.name === property.name;
                                              }));
                                        var match = property.propertyGroup.trim();
                                        if (match === "" || maybeGlobalProperty === undefined) {
                                          return ;
                                        } else {
                                          return [
                                                  match,
                                                  maybeGlobalProperty
                                                ];
                                        }
                                      }));
                        }))), undefined, (function (map, param) {
                  var propertyGroupName = param[0];
                  var nextProperties = Belt_MapString.getWithDefault(map, propertyGroupName, []);
                  nextProperties.push(param[1]);
                  return Belt_MapString.set(map, propertyGroupName, nextProperties);
                }))), (function (param) {
          var properties = param[1];
          var propertyGroupName = param[0];
          var existingGroupType = Belt_Array.getBy(model.groupTypes, (function (groupType) {
                  return groupType.name === propertyGroupName;
                }));
          if (existingGroupType !== undefined) {
            return {
                    id: existingGroupType.id,
                    name: existingGroupType.name,
                    properties: Belt_Array.map(properties, (function (property) {
                            return {
                                    id: property.id,
                                    name: property.name
                                  };
                          }))
                  };
          } else {
            return {
                    id: Shortid(),
                    name: propertyGroupName,
                    properties: Belt_Array.map(properties, (function (property) {
                            return {
                                    id: property.id,
                                    name: property.name
                                  };
                          }))
                  };
          }
        }));
  var globalProperties$1 = Belt_Array.map(globalProperties, (function (property) {
          var groupName = property.sendAs;
          if (typeof groupName === "number") {
            return property;
          }
          var groupName$1 = groupName._0.trim();
          var maybeGroupId = Belt_Array.get(Belt_Array.keepMap(globalPropertyGroups, (function (group) {
                      if (group.name === groupName$1) {
                        return group.id;
                      }
                      
                    })), 0);
          return Belt_Option.mapWithDefault(maybeGroupId, property, (function (groupId) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueNameIndex: property.uniqueNameIndex,
                                description: property.description,
                                type_: property.type_,
                                list: property.list,
                                matches: property.matches,
                                warnings: property.warnings,
                                presence: property.presence,
                                sendAs: /* GroupProperty */{
                                  _0: groupId
                                },
                                nameMapping: property.nameMapping
                              };
                      }));
        }));
  var globalSources = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.map(events, (function ($$event) {
                      return $$event.sources;
                    })))));
  var events$1 = Belt_Array.keepMapU(events, (function ($$event) {
          var amplitudeUserPropertiesEvent = rawParserOutput.formatName === "Amplitude User Properties CSV Export" && $$event.name === "" && Belt_Array.everyU($$event.properties, (function (rawProperty) {
                  return rawProperty.sendAs === /* UserProperty */2;
                }));
          var mixpanelUserPropertiesEvent = rawParserOutput.formatName === "Mixpanel CSV Export" && $$event.name === "$user";
          var mixpanelCustomEvent = rawParserOutput.formatName === "Mixpanel CSV Export" && $$event.name.startsWith("$custom_event:");
          var skipEvent = $$event.name === "skip-event";
          if (amplitudeUserPropertiesEvent || mixpanelUserPropertiesEvent || mixpanelCustomEvent || skipEvent) {
            return ;
          } else {
            return {
                    id: $$event.id,
                    name: $$event.name,
                    uniqueNameIndex: undefined,
                    description: $$event.description,
                    categories: Belt_Array.keepU($$event.categories, (function (category) {
                            return category !== "Uncategorized";
                          })),
                    tags: $$event.tags,
                    propertyIds: Belt_Array.mapU($$event.properties, (function (rawProperty) {
                            return Belt_Option.getExn(Belt_Option.map(Belt_Array.getByU(globalProperties$1, (function (globalProperty) {
                                                  return isPropertyEqual(globalProperty, rawProperty);
                                                })), (function (globalProperty) {
                                              return [
                                                      globalProperty.id,
                                                      rawProperty.pinnedValue
                                                    ];
                                            })));
                          })),
                    updatedPropertyIds: Belt_Array.keepMapU($$event.properties, (function (rawProperty) {
                            return Belt_Option.map(Belt_Array.getByU(globalProperties$1, (function (globalProperty) {
                                              if (isPropertyEqual(globalProperty, rawProperty)) {
                                                return !BeltArrayExtensions.compareUnorderedStringArrays(globalProperty.matches, rawProperty.matches);
                                              } else {
                                                return false;
                                              }
                                            })), (function (globalProperty) {
                                          return [
                                                  globalProperty.id,
                                                  rawProperty.pinnedValue
                                                ];
                                        }));
                          })),
                    propertyGroupIds: [],
                    sources: $$event.sources,
                    warnings: [],
                    nameMapping: $$event.nameMapping
                  };
          }
        }));
  var eventNameToPropertyIdsMap = Belt_Array.reduce(Belt_Array.concatMany(Belt_Array.keepMap(Belt_Array.concatMany(Belt_Array.concat(Belt_Array.map(rawParserOutput.events, (function ($$event) {
                              return Belt_Array.map($$event.properties, (function (property) {
                                            return property;
                                          }));
                            })), [rawParserOutput.propertiesWithoutEvent])), (function (property) {
                  if (property.propertyEvents.length === 0) {
                    return ;
                  }
                  var maybePropertyId = Belt_Array.get(Belt_Array.keepMap(globalProperties$1, (function (globalProperty) {
                              if (globalProperty.name === property.name) {
                                return globalProperty.id;
                              }
                              
                            })), 0);
                  return Belt_Option.flatMap(maybePropertyId, (function (propertyId) {
                                return Belt_Array.map(property.propertyEvents, (function (eventName) {
                                              return [
                                                      eventName,
                                                      propertyId
                                                    ];
                                            }));
                              }));
                }))), undefined, (function (eventMap, param) {
          var eventName = param[0];
          var nextProperties = Belt_MapString.getWithDefault(eventMap, eventName, []);
          nextProperties.push(param[1]);
          return Belt_MapString.set(eventMap, eventName, nextProperties);
        }));
  var events$2 = Belt_Array.map(events$1, (function ($$event) {
          var maybePropertyIdsToAdd = Belt_MapString.get(eventNameToPropertyIdsMap, $$event.name);
          if (maybePropertyIdsToAdd !== undefined) {
            return {
                    id: $$event.id,
                    name: $$event.name,
                    uniqueNameIndex: $$event.uniqueNameIndex,
                    description: $$event.description,
                    categories: $$event.categories,
                    tags: $$event.tags,
                    propertyIds: Belt_List.toArray(BeltListExtensions.dedupeOrdered(Belt_List.fromArray(Belt_Array.concat($$event.propertyIds, Belt_Array.map(maybePropertyIdsToAdd, (function (propertyId) {
                                            return [
                                                    propertyId,
                                                    undefined
                                                  ];
                                          })))), (function (param) {
                                return param[0];
                              }))),
                    updatedPropertyIds: $$event.updatedPropertyIds,
                    propertyGroupIds: $$event.propertyGroupIds,
                    sources: $$event.sources,
                    warnings: $$event.warnings,
                    nameMapping: $$event.nameMapping
                  };
          } else {
            return $$event;
          }
        }));
  var events$3 = Belt_Array.concat(events$2, Belt_Array.keepMap(Belt_MapString.toArray(eventNameToPropertyIdsMap), (function (param) {
              var eventName = param[0];
              var maybeEvent = Belt_Array.getBy(events$2, (function ($$event) {
                      return $$event.name === eventName;
                    }));
              if (maybeEvent !== undefined) {
                return ;
              } else {
                return {
                        id: Shortid(),
                        name: eventName,
                        uniqueNameIndex: undefined,
                        description: "",
                        categories: [],
                        tags: [],
                        propertyIds: Belt_Array.map(param[1], (function (propertyId) {
                                return [
                                        propertyId,
                                        undefined
                                      ];
                              })),
                        updatedPropertyIds: [],
                        propertyGroupIds: [],
                        sources: [],
                        warnings: [],
                        nameMapping: []
                      };
              }
            })));
  return {
          formatName: rawParserOutput.formatName,
          events: events$3,
          warnings: {
            parseWarnings: rawParserOutput.warnings,
            filteredOutEventInfo: [],
            filteredOutPropertyInfo: []
          },
          properties: globalProperties$1,
          sources: globalSources,
          propertyGroups: globalPropertyGroups
        };
}

function filterParsedModelBasedOnAvoModel(model, parseOutputWithFlattenedProperties) {
  var match = ImportParserFiltering.Filtering.filterOutEventsThatAreAlreadyInTheModelAndDontContainAnythingNew(model, parseOutputWithFlattenedProperties);
  var filteredOutEventsInfo = match[2];
  var updatedEvents = match[1];
  var newEvents = match[0];
  var parsedEvents = Belt_Array.concat(newEvents, updatedEvents);
  var match$1 = ImportParserFiltering.Filtering.filterOutPropertieThatAreOnlyOnFilteredOutEvents(parseOutputWithFlattenedProperties.properties, parsedEvents, filteredOutEventsInfo);
  var match$2 = ImportParserFiltering.Filtering.filterOutPropertiesThatAreAlreadyInTheModel(model, match$1[0], parseOutputWithFlattenedProperties.properties);
  var filteredOutPropertiesInfo = Belt_Array.concat(match$1[1], match$2[2]);
  var newEvents$1 = ImportParserFiltering.Filtering.updatePropertyIdsOnEventsAfterFiltering(model, filteredOutPropertiesInfo, newEvents);
  var updatedEvents$1 = ImportParserFiltering.Filtering.updatePropertyIdsOnEventsAfterFiltering(model, filteredOutPropertiesInfo, updatedEvents);
  var parsedProperties = ImportParserUniqueIndexes.Properties.assignUniqieIndexesToPropertiesWithSameName(match$2[0], model);
  var newEvents$2 = ImportParserUniqueIndexes.Events.assignUniqieIndexesToEventsWithSameName(newEvents$1, model);
  var newEvents$3 = ImportParserFiltering.Filtering.replacePropertyRefsWithPropertyGroupRefs(model, newEvents$2, parseOutputWithFlattenedProperties.propertyGroups);
  var updatedEvents$2 = ImportParserFiltering.Filtering.replacePropertyRefsWithPropertyGroupRefs(model, updatedEvents$1, parseOutputWithFlattenedProperties.propertyGroups);
  return {
          formatName: parseOutputWithFlattenedProperties.formatName,
          newEvents: newEvents$3,
          updatedEvents: updatedEvents$2,
          warnings: {
            parseWarnings: parseOutputWithFlattenedProperties.warnings.parseWarnings,
            filteredOutEventInfo: filteredOutEventsInfo,
            filteredOutPropertyInfo: filteredOutPropertiesInfo
          },
          newProperties: parsedProperties,
          updatedProperties: match$2[1],
          sources: parseOutputWithFlattenedProperties.sources,
          propertyGroups: parseOutputWithFlattenedProperties.propertyGroups
        };
}

function parseRaw(csvFile) {
  var csv = Sync(csvFile, {
        relax_column_count: true,
        relax_quotes: true
      });
  var trimmedCsv = Belt_Array.mapU(csv, (function (row) {
          return Belt_Array.mapU(row, (function (value) {
                        return value.trim();
                      }));
        }));
  return oneOf(ImportFormats.formats, trimmedCsv);
}

function parse(model, csvFile) {
  var rawOutput = parseRaw(csvFile);
  var parseOutputWithFlattenedProperties = processRawOutput(model, rawOutput);
  return filterParsedModelBasedOnAvoModel(model, parseOutputWithFlattenedProperties);
}

var ParseError = ImportParserTypes.ParseError;

var ParseFormatError = ImportParserTypes.ParseFormatError;

var ParseWarning = ImportParserTypes.ParseWarning;

var getColumnValue = ImportParserTypes.getColumnValue;

var getOptionalColumnValue = ImportParserTypes.getOptionalColumnValue;

var getColumnIndex = ImportParserTypes.getColumnIndex;

var getOptionalColumnIndex = ImportParserTypes.getOptionalColumnIndex;

var StringTupleOp = ImportParserTypes.StringTupleOp;

var StringOp = ImportParserTypes.StringOp;

var BoolOp = ImportParserTypes.BoolOp;

var StringArrayOp = ImportParserTypes.StringArrayOp;

var SendAsOp = ImportParserTypes.SendAsOp;

var Filtering = ImportParserFiltering.Filtering;

exports.ParseError = ParseError;
exports.ParseFormatError = ParseFormatError;
exports.ParseWarning = ParseWarning;
exports.getColumnValue = getColumnValue;
exports.getOptionalColumnValue = getOptionalColumnValue;
exports.getColumnIndex = getColumnIndex;
exports.getOptionalColumnIndex = getOptionalColumnIndex;
exports.StringTupleOp = StringTupleOp;
exports.StringOp = StringOp;
exports.BoolOp = BoolOp;
exports.StringArrayOp = StringArrayOp;
exports.SendAsOp = SendAsOp;
exports.Filtering = Filtering;
exports.getColumnConfig = getColumnConfig;
exports.parseFormat = parseFormat;
exports.oneOf = oneOf;
exports.processRawOutput = processRawOutput;
exports.filterParsedModelBasedOnAvoModel = filterParsedModelBasedOnAvoModel;
exports.parseRaw = parseRaw;
exports.parse = parse;
/* shortid Not a pure module */
