// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Sentry = require("./externals/Sentry.bs.js");
var $$Promise = require("@ryyppy/rescript-promise/src/Promise.bs.js");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var App = require("firebase/app");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

var Unavailable = /* @__PURE__ */Caml_exceptions.create("TrialRequest.Unavailable");

function useTrialRequest(schemaId) {
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = React.useState(function () {
        return false;
      });
  var setHasRequested = match[1];
  var hasRequested = match[0];
  var hasExistingTrial = FirebaseFetcherHooks.useHasExistingTrial(schemaId);
  var match$1 = workspace.plan.name;
  var match$2 = workspace.trial;
  var status = match$2 !== undefined ? (
      hasRequested ? /* Success */5 : /* Ongoing */1
    ) : (
      hasExistingTrial ? /* Expired */0 : (
          hasRequested ? /* Pending */4 : (
              !(match$1 === 2 || match$1 === 1) || match$2 !== undefined || hasRequested || hasExistingTrial ? /* Unavailable */3 : /* Available */2
            )
        )
    );
  var requestTrial = function (viewerId) {
    if (status === /* Available */2) {
      Curry._1(setHasRequested, (function (param) {
              return true;
            }));
      return $$Promise.$$catch(Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("trialRequests").doc().set({
                      requesterId: viewerId,
                      createdAt: App.default.firestore.FieldValue.serverTimestamp()
                    }), (function (error) {
                    Curry._1(setHasRequested, (function (param) {
                            return false;
                          }));
                    Sentry.captureException(error, {});
                    return Promise.reject(error);
                  }));
    } else {
      return Promise.reject({
                  RE_EXN_ID: Unavailable,
                  _1: "Can't request trial if it's not available"
                });
    }
  };
  return [
          status,
          requestTrial
        ];
}

exports.Unavailable = Unavailable;
exports.useTrialRequest = useTrialRequest;
/* react Not a pure module */
