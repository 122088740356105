// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Case = require("../../bs-case/src/case.bs.js");
var $$String = require("rescript/lib/js/string.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Js_null_undefined = require("rescript/lib/js/js_null_undefined.js");

function toDate(serverTimestamp) {
  return Js_null_undefined.bind(serverTimestamp, (function (ts) {
                return ts.toDate();
              }));
}

function toDateOption(serverTimestamp) {
  return Caml_option.nullable_to_opt(Js_null_undefined.bind(serverTimestamp, (function (ts) {
                    return ts.toDate();
                  })));
}

var _map = {"Admin":"Admin","Editor":"Editor","Viewer":"Viewer","NoAccess":"NoAccess","CommentOnly":"Comment Only","BillingOnly":"Billing Only","CodegenAccess":"CodegenAccess"};

var _revMap = {"Admin":"Admin","Editor":"Editor","Viewer":"Viewer","NoAccess":"NoAccess","Comment Only":"CommentOnly","Billing Only":"BillingOnly","CodegenAccess":"CodegenAccess"};

function tToJs(param) {
  return _map[param];
}

function tFromJs(param) {
  return _revMap[param];
}

function getLabel(role) {
  if (role === "Editor") {
    return "Editor";
  } else if (role === "CodegenAccess") {
    return "Codegen Access";
  } else if (role === "Viewer") {
    return "View Only";
  } else if (role === "CommentOnly") {
    return "Comment Only";
  } else if (role === "Admin") {
    return "Admin";
  } else if (role === "NoAccess") {
    return "No Access";
  } else {
    return "Billing Only";
  }
}

function canEdit(role) {
  if (role === "Admin") {
    return true;
  } else {
    return role === "Editor";
  }
}

function canComment(role) {
  if (role === "Admin" || role === "CommentOnly" || role === "CodegenAccess") {
    return true;
  } else {
    return role === "Editor";
  }
}

function canCodegen(role) {
  if (role === "Admin" || role === "CodegenAccess") {
    return true;
  } else {
    return role === "Editor";
  }
}

function getRoleRank(role) {
  if (role !== undefined) {
    if (role === "Editor") {
      return 2;
    } else if (role === "CodegenAccess") {
      return 3;
    } else if (role === "Viewer") {
      return 5;
    } else if (role === "CommentOnly") {
      return 4;
    } else if (role === "Admin") {
      return 1;
    } else if (role === "NoAccess") {
      return 7;
    } else {
      return 6;
    }
  } else {
    return 8;
  }
}

var Role = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  getLabel: getLabel,
  canEdit: canEdit,
  canComment: canComment,
  canCodegen: canCodegen,
  getRoleRank: getRoleRank
};

function roles(hasCodegenAccessRole) {
  if (hasCodegenAccessRole) {
    return {
            hd: "Admin",
            tl: {
              hd: "Editor",
              tl: {
                hd: "CodegenAccess",
                tl: {
                  hd: "CommentOnly",
                  tl: {
                    hd: "BillingOnly",
                    tl: {
                      hd: "Viewer",
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          };
  } else {
    return {
            hd: "Admin",
            tl: {
              hd: "Editor",
              tl: {
                hd: "CommentOnly",
                tl: {
                  hd: "BillingOnly",
                  tl: {
                    hd: "Viewer",
                    tl: /* [] */0
                  }
                }
              }
            }
          };
  }
}

var Context = {};

var InspectorKey = {};

function getId(prim) {
  return prim.id;
}

function getCreatedAt(prim) {
  return prim.createdAt;
}

var Action = {
  name: "action",
  getId: getId,
  getCreatedAt: getCreatedAt
};

var OnboardingTemplate = {};

function parse(json) {
  return {
          propertyConflictOnEvent: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("propertyConflictOnEvent", Json_decode.bool, param);
                    }), json), true),
          sameEventName: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("sameEventName", Json_decode.bool, param);
                    }), json), true),
          samePropertyName: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("samePropertyName", Json_decode.bool, param);
                    }), json), true),
          eventsWithUnexpectedCase: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("eventsWithUnexpectedCase", Json_decode.bool, param);
                    }), json), true),
          preferredEventCase: Belt_Option.getWithDefault(Belt_Option.flatMap(Json_decode.optional((function (param) {
                          return Json_decode.field("preferredEventCase", Json_decode.string, param);
                        }), json), Case.tFromJs), "None"),
          forceEventCase: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("forceEventCase", Json_decode.bool, param);
                    }), json), false),
          propertiesWithUnexpectedCase: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("propertiesWithUnexpectedCase", Json_decode.bool, param);
                    }), json), true),
          forcePropertyCase: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("forcePropertyCase", Json_decode.bool, param);
                    }), json), false),
          preferredPropertyCase: Belt_Option.getWithDefault(Belt_Option.flatMap(Json_decode.optional((function (param) {
                          return Json_decode.field("preferredPropertyCase", Json_decode.string, param);
                        }), json), Case.tFromJs), "None"),
          propertiesWithAnyType: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("propertiesWithAnyType", Json_decode.bool, param);
                    }), json), true),
          eventsWithoutDescription: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("eventsWithoutDescription", Json_decode.bool, param);
                    }), json), true),
          propertiesWithoutDescription: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("propertiesWithoutDescription", Json_decode.bool, param);
                    }), json), true)
        };
}

var ValidationConfig = {
  parse: parse
};

var OnboardingV2 = {};

function getId$1(schema) {
  return schema.id;
}

var Schema = {
  name: "schemas",
  getId: getId$1
};

function getId$2(trial) {
  return trial.id;
}

var Trial = {
  name: "trials",
  getId: getId$2
};

function getId$3(prim) {
  return prim.id;
}

var Version = {
  name: "version",
  getId: getId$3
};

var Chunk = {};

var Picture = {};

var ColumnSettings = {};

var $$default = {
  showIssuesOnly: false
};

var BranchAuditSettings = {
  $$default: $$default
};

var Domain = {};

var SsoProviderBySchema = {};

var Filter = {};

function getId$4(prim) {
  return prim.id;
}

function enrichMemberWithEmail(email, member) {
  var tmp = {
    id: member.id,
    role: member.role,
    type_: member.type_,
    createdAt: member.createdAt
  };
  var tmp$1 = Caml_option.undefined_to_opt(member.jobFunction);
  if (tmp$1 !== undefined) {
    tmp.jobFunction = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Caml_option.undefined_to_opt(member.filter);
  if (tmp$2 !== undefined) {
    tmp.filter = Caml_option.valFromOption(tmp$2);
  }
  if (email !== undefined) {
    tmp.email = Caml_option.valFromOption(email);
  }
  return tmp;
}

function enrichMembersWithEmail(members, users) {
  return Belt_List.map(members, (function (member) {
                var email = Belt_Option.map(Belt_Array.getBy(users, (function (user) {
                            return user.id === member.id;
                          })), (function (user) {
                        return user.email;
                      }));
                return enrichMemberWithEmail(email, member);
              }));
}

function canEdit$1(member) {
  return canEdit(Belt_Option.getExn(_revMap[member.role]));
}

function canComment$1(member) {
  return canComment(Belt_Option.getExn(_revMap[member.role]));
}

var Member = {
  name: "member",
  getId: getId$4,
  enrichMemberWithEmail: enrichMemberWithEmail,
  enrichMembersWithEmail: enrichMembersWithEmail,
  canEdit: canEdit$1,
  canComment: canComment$1
};

function getId$5(prim) {
  return prim.id;
}

var Invite = {
  name: "invite",
  getId: getId$5
};

function getId$6(prim) {
  return prim.id;
}

var SchemaInvite = {
  name: "schemaInvite",
  getId: getId$6
};

function getId$7(prim) {
  return prim.id;
}

var UserSchema = {
  name: "schemas",
  getId: getId$7
};

var Payment = {
  name: "payment"
};

var Config = {
  name: "config"
};

function getId$8(prim) {
  return prim.id;
}

function getCreatedAt$1(prim) {
  return prim.createdAt;
}

var $$Comment = {
  name: "comment",
  getId: getId$8,
  getCreatedAt: getCreatedAt$1
};

function getId$9(prim) {
  return prim.id;
}

function getCreatedAt$2(prim) {
  return prim.createdAt;
}

var Activity = {
  name: "activity",
  getId: getId$9,
  getCreatedAt: getCreatedAt$2
};

var _map$1 = {"prod":"prod","dev":"dev"};

var _revMap$1 = {"prod":"prod","dev":"dev"};

function envToJs(param) {
  return _map$1[param];
}

function envFromJs(param) {
  return _revMap$1[param];
}

function getId$10(invocation) {
  return invocation.eventId + (invocation.sourceId + invocation.hash);
}

var Invocation = {
  getId: getId$10,
  name: "invocation"
};

function getId$11(invocation) {
  return invocation.type_ + invocation.sourceId;
}

var InvocationMeta = {
  getId: getId$11,
  name: "meta"
};

var EmailVerificationTrigger = {};

var CreateContact = {};

var PullResponse = {};

var ExportResponse = {};

var CollaborationItem = {};

var _map$2 = {"Comment":"Comment","AddCollaborator":"AddCollaborator","AtMention":"AtMention","NewBranch":"NewBranch","CloseBranch":"CloseBranch","MergeBranch":"MergeBranch","SetBranchStatusToDraft":"SetBranchStatusToDraft","SetBranchStatusToReadyForReview":"SetBranchStatusToReadyForReview","SetBranchStatusToApproved":"SetBranchStatusToApproved","SetBranchStatusToChangesRequested":"SetBranchStatusToChangesRequested"};

function tToJs$1(param) {
  return param;
}

function tFromJs$1(param) {
  return _map$2[param];
}

var CollaborationTrigger = {
  tToJs: tToJs$1,
  tFromJs: tFromJs$1
};

function getId$12(prim) {
  return prim.id;
}

var Collaborator = {
  getId: getId$12
};

function getId$13(prim) {
  return prim.id;
}

var OAuthRequest = {
  getId: getId$13
};

function printEventType(_type) {
  if (typeof _type === "number") {
    if (_type !== 0) {
      return "workspace-notifications";
    } else {
      return "inspector-issues";
    }
  } else {
    return "saved-view:" + _type._0;
  }
}

function parseEventType(_type) {
  switch (_type) {
    case "inspector-issues" :
        return /* InspectorIssues */0;
    case "workspace-notifications" :
        return /* WorkspaceNotifications */1;
    default:
      return /* SavedView */{
              _0: $$String.sub(_type, 11, _type.length - 11 | 0)
            };
  }
}

var NotificationChannel = {
  printEventType: printEventType,
  parseEventType: parseEventType
};

function getId$14(prim) {
  return prim.id;
}

var NotificationChannelAuth = {
  getId: getId$14
};

exports.toDate = toDate;
exports.toDateOption = toDateOption;
exports.Role = Role;
exports.roles = roles;
exports.Context = Context;
exports.InspectorKey = InspectorKey;
exports.Action = Action;
exports.OnboardingTemplate = OnboardingTemplate;
exports.ValidationConfig = ValidationConfig;
exports.OnboardingV2 = OnboardingV2;
exports.Schema = Schema;
exports.Trial = Trial;
exports.Version = Version;
exports.Chunk = Chunk;
exports.Picture = Picture;
exports.ColumnSettings = ColumnSettings;
exports.BranchAuditSettings = BranchAuditSettings;
exports.Domain = Domain;
exports.SsoProviderBySchema = SsoProviderBySchema;
exports.Filter = Filter;
exports.Member = Member;
exports.Invite = Invite;
exports.SchemaInvite = SchemaInvite;
exports.UserSchema = UserSchema;
exports.Payment = Payment;
exports.Config = Config;
exports.$$Comment = $$Comment;
exports.Activity = Activity;
exports.envToJs = envToJs;
exports.envFromJs = envFromJs;
exports.Invocation = Invocation;
exports.InvocationMeta = InvocationMeta;
exports.EmailVerificationTrigger = EmailVerificationTrigger;
exports.CreateContact = CreateContact;
exports.PullResponse = PullResponse;
exports.ExportResponse = ExportResponse;
exports.CollaborationItem = CollaborationItem;
exports.CollaborationTrigger = CollaborationTrigger;
exports.Collaborator = Collaborator;
exports.OAuthRequest = OAuthRequest;
exports.NotificationChannel = NotificationChannel;
exports.NotificationChannelAuth = NotificationChannelAuth;
/* Case Not a pure module */
