// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$String = require("rescript/lib/js/string.js");
var Styles = require("./styles.bs.js");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var RouterTypes = require("./RouterTypes.bs.js");

function getJobFunctionLabel(jobFunction) {
  switch (jobFunction) {
    case /* Analyst */0 :
        return "Analyst";
    case /* AnalyticsEngineer */1 :
        return "Analytics Engineer";
    case /* DataEngineer */2 :
        return "Data Engineer";
    case /* DataProductManager */3 :
        return "Data Product Manager";
    case /* ProductManager */4 :
        return "Product Manager";
    case /* ProductEngineer */5 :
        return "Product Engineer";
    case /* Marketing */6 :
        return "Marketing";
    case /* Other */7 :
        return "Other";
    
  }
}

var jobFunctions = [
  /* Analyst */0,
  /* AnalyticsEngineer */1,
  /* DataEngineer */2,
  /* DataProductManager */3,
  /* ProductManager */4,
  /* ProductEngineer */5,
  /* Marketing */6,
  /* Other */7
];

var _map = {"Web":"Web","iOS":"iOS","Android":"Android","Desktop":"Desktop","Game Engine":"Game Engine","Cross-platform":"Cross-platform","Backend":"Backend","Other":"Other"};

function sourceGroupToJs(param) {
  return param;
}

function sourceGroupFromJs(param) {
  return _map[param];
}

var cmp = Caml_obj.caml_compare;

var SourceGroupCmp = Belt_Id.MakeComparable({
      cmp: cmp
    });

function stepToJs(step) {
  if (step === "job") {
    return "JobFunction";
  } else if (step === "loading") {
    return "CreatingSchema";
  } else if (step === "destinations") {
    return "Destinations";
  } else if (step === "sources") {
    return "Sources";
  } else {
    return "Password";
  }
}

var funnel = {
  hd: "job",
  tl: {
    hd: "sources",
    tl: {
      hd: "destinations",
      tl: {
        hd: "password",
        tl: {
          hd: "loading",
          tl: /* [] */0
        }
      }
    }
  }
};

function getColor(x) {
  if (x === "job") {
    return Styles.Color.mintGreen;
  } else if (x === "destinations") {
    return Styles.Color.purple;
  } else if (x === "sources") {
    return Styles.Color.blue;
  } else {
    return Styles.Color.magenta;
  }
}

function getUrlFragmentFromStep(step) {
  return "/" + RouterTypes.BaseRoute.toUrlFragment({
              NAME: "onboarding",
              VAL: step
            });
}

function getBack(step) {
  var stepIndex = Belt_List.toArray(funnel).indexOf(step);
  return Belt_Option.map(Belt_List.get(funnel, stepIndex - 1 | 0), getUrlFragmentFromStep);
}

function getNext(step) {
  var stepIndex = Belt_List.toArray(funnel).indexOf(step);
  if (stepIndex < Belt_List.length(funnel)) {
    return Belt_Option.map(Belt_List.get(funnel, stepIndex + 1 | 0), getUrlFragmentFromStep);
  }
  
}

function validateStep(state, step) {
  if (step !== "job") {
    if (step === "loading") {
      return "Valid";
    } else if (step === "destinations") {
      if (state.destinations === /* [] */0) {
        return "ValidEmptyState";
      } else {
        return "Valid";
      }
    } else if (step === "sources") {
      if (Belt_Set.isEmpty(state.sourceGroups)) {
        return "ValidEmptyState";
      } else {
        return "Valid";
      }
    } else if ($$String.contains(state.email, /* '@' */64) && $$String.contains(state.email, /* '.' */46) && state.email.length > 5) {
      if (state.password.length > 5) {
        return "Valid";
      } else {
        return {
                NAME: "Invalid",
                VAL: "Invalid password"
              };
      }
    } else {
      return {
              NAME: "Invalid",
              VAL: "Invalid email address"
            };
    }
  }
  var match = state.jobFunction;
  if (match !== undefined) {
    if (match >= 7 && state.customJobFunction === "") {
      return {
              NAME: "Invalid",
              VAL: "Enter your role"
            };
    } else {
      return "Valid";
    }
  } else {
    return {
            NAME: "Invalid",
            VAL: "Select a role"
          };
  }
}

function getDirection(fromStep, toStep) {
  var fromStepIndex = Belt_List.toArray(funnel).indexOf(fromStep);
  var toStepIndex = Belt_List.toArray(funnel).indexOf(toStep);
  if (fromStepIndex === toStepIndex) {
    return "NA";
  } else if (fromStepIndex < toStepIndex) {
    return "Forward";
  } else {
    return "Back";
  }
}

var sourceGroups = [
  "Web",
  "Desktop",
  "Backend",
  "iOS",
  "Android",
  "Cross-platform",
  "Game Engine",
  "Other"
];

exports.getJobFunctionLabel = getJobFunctionLabel;
exports.jobFunctions = jobFunctions;
exports.sourceGroupToJs = sourceGroupToJs;
exports.sourceGroupFromJs = sourceGroupFromJs;
exports.SourceGroupCmp = SourceGroupCmp;
exports.stepToJs = stepToJs;
exports.funnel = funnel;
exports.getColor = getColor;
exports.getUrlFragmentFromStep = getUrlFragmentFromStep;
exports.getBack = getBack;
exports.getNext = getNext;
exports.validateStep = validateStep;
exports.getDirection = getDirection;
exports.sourceGroups = sourceGroups;
/* SourceGroupCmp Not a pure module */
