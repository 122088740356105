// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Tooltip = require("./Tooltip.bs.js");
var IconLightning = require("./IconLightning.bs.js");

var lightningTooltipStyles = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.px(180)),
      tl: /* [] */0
    });

function PlanLightning(Props) {
  var tooltipText = Props.tooltipText;
  var tooltipDirectionOpt = Props.tooltipDirection;
  var tooltipDirection = tooltipDirectionOpt !== undefined ? tooltipDirectionOpt : "bottom";
  return React.createElement(Tooltip.make, {
              position: tooltipDirection,
              title: "Plan",
              tooltipPadding: 16,
              withAskUsAnything: false,
              withDismiss: false,
              withTitle: false,
              children: null
            }, React.createElement(Tooltip.Target.make, {
                  children: React.createElement(IconLightning.make, {
                        size: 12
                      })
                }), React.createElement(Tooltip.Content.make, {
                  children: React.createElement("span", {
                        className: lightningTooltipStyles
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            children: tooltipText
                          }))
                }));
}

var make = PlanLightning;

exports.lightningTooltipStyles = lightningTooltipStyles;
exports.make = make;
/* lightningTooltipStyles Not a pure module */
