// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Input = require("./Input.bs.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Styles = require("./styles.bs.js");
var Shortid = require("shortid");
var Firebase = require("../../bs-firestore/src/Firebase.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var IconTrees = require("./IconTrees.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var RouterStore = require("./RouterStore.bs.js");
var Collaborators = require("./Collaborators.bs.js");
var UrlSafeString = require("url-safe-string");
var WorkspaceContext = require("./WorkspaceContext.bs.js");
var GlobalSendContext = require("./GlobalSendContext.bs.js");
var SchemaGroupContext = require("./SchemaGroupContext.bs.js");
var SendActionsContext = require("./SendActionsContext.bs.js");

var safeBranchName = new UrlSafeString({
      trimWhitespace: false
    });

function createBranch(branchName, branchOrigin, sendActions, schemaGroup, schemaId, onError, onSuccess, param) {
  var branchId = Shortid();
  var user = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser));
  var userId = user.uid;
  var email = user.email;
  Curry.app(sendActions, [
        {
          NAME: "Branch",
          VAL: branchId
        },
        false,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        (function (branch) {
            Curry._1(onSuccess, branchId);
            AnalyticsRe.branchCreated({
                  schemaId: schemaGroup.schemaId,
                  schemaName: schemaGroup.schemaName,
                  schemaBillingStatus: schemaGroup.schemaBillingStatus,
                  branchId: branchId,
                  branchName: branchName,
                  schemaSubscriptionPlan: schemaGroup.schemaSubscriptionPlan,
                  schemaTrialPlan: schemaGroup.schemaTrialPlan,
                  authenticationConfig: schemaGroup.authenticationConfig,
                  schemaSubscriptionPlanSlug: schemaGroup.schemaSubscriptionPlanSlug,
                  schemaTrialPlanSlug: schemaGroup.schemaTrialPlanSlug
                }, branchId, branchName, branchOrigin, schemaGroup.branchId, schemaGroup.schemaId);
            return Collaborators.addCollaborator(schemaId, schemaGroup, userId, NamedBranch.toUnnamed(branch), "Branch", branchId, userId, email, "NewBranch", sendActions);
          }),
        onError,
        [[
            {
              NAME: "BranchInit",
              VAL: [
                branchId,
                branchName
              ]
            },
            {
              branchId: branchId,
              branchQuery: branchId
            }
          ]]
      ]);
  return Curry.app(sendActions, [
              "Master",
              false,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              [[
                  {
                    NAME: "OpenBranch",
                    VAL: [
                      branchId,
                      branchName
                    ]
                  },
                  {
                    branchId: branchId,
                    branchQuery: branchId
                  }
                ]]
            ]);
}

function NewBranchModal(Props) {
  var openBranches = Props.openBranches;
  var onCreateOpt = Props.onCreate;
  var intialName = Props.intialName;
  var onCreate = onCreateOpt !== undefined ? onCreateOpt : (function (param, param$1) {
        
      });
  var schemaGroup = SchemaGroupContext.use(undefined);
  var schemaId = WorkspaceContext.use(undefined).id;
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var match = React.useState(function () {
        return safeBranchName.generate(intialName);
      });
  var setName = match[1];
  var name = match[0];
  var match$1 = React.useState(function () {
        return /* Idle */0;
      });
  var setStatus = match$1[1];
  var status = match$1[0];
  var branchNameExists = Belt_List.has(openBranches, name, (function (param, name) {
          return param[1] === name;
        })) || name === "master" || name === "main";
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.padding2(Css.px(15), Css.px(15)),
                        tl: {
                          hd: Css.maxWidth(Css.pct(90)),
                          tl: {
                            hd: Css.width(Css.px(250)),
                            tl: {
                              hd: Css.maxHeight(Css.pct(100)),
                              tl: {
                                hd: Css.boxShadow(Styles.Shadow.box),
                                tl: {
                                  hd: Css.borderRadius(Styles.Border.radius),
                                  tl: {
                                    hd: Css.cursor("default"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: {
                              hd: Css.marginTop(Css.px(-110)),
                              tl: {
                                hd: Css.width(Css.pct(100)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(IconTrees.make, {
                      size: 144
                    })), React.createElement("form", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(15)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.alignItems("stretch"),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      if (!branchNameExists && status !== /* Loading */1) {
                        Curry._1(setStatus, (function (param) {
                                return /* Loading */1;
                              }));
                        return createBranch(name, "NewBranchModal", sendActions, schemaGroup, schemaId, undefined, (function (branchId) {
                                      Curry._1(globalSend, {
                                            TAG: /* CloseModal */5,
                                            _0: undefined
                                          });
                                      var link = Router.Link.getSchemaRouteLink({
                                            NAME: "branch",
                                            VAL: branchId
                                          }, schemaRoute);
                                      Router.push(undefined, link);
                                      return Curry._2(onCreate, branchId, name);
                                    }), undefined);
                      }
                      
                    })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginBottom(Css.px(10)),
                            tl: /* [] */0
                          })
                    }, React.createElement(Input.make, {
                          label: "Branch Name",
                          value: name,
                          onChange: (function (newName) {
                              return Curry._1(setName, (function (param) {
                                            return safeBranchName.generate(newName);
                                          }));
                            }),
                          autoFocus: true,
                          placeholder: "update-signup-event",
                          className: {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: /* [] */0
                          },
                          required: true
                        })), branchNameExists && status !== /* Loading */1 ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.color(Styles.Color.orange),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: {
                                    hd: Css.paddingBottom(Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, "Branch name already exists.") : null, React.createElement(Button.make, {
                      disabled: branchNameExists || status === /* Loading */1,
                      label: "Create",
                      loading: status === /* Loading */1,
                      size: "large"
                    })));
}

var make = NewBranchModal;

exports.safeBranchName = safeBranchName;
exports.createBranch = createBranch;
exports.make = make;
/* safeBranchName Not a pure module */
