// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Select = require("./Select.bs.js");
var Styles = require("./styles.bs.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var NameInput = require("./NameInput.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var AnalyticsRe = require("./analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var NamedBranch = require("./NamedBranch.bs.js");
var AnalyticsUtils = require("./analyticsUtils.bs.js");
var BeltListExtensions = require("./BeltListExtensions.bs.js");

function PropertyRule(Props) {
  var rule = Props.rule;
  var model = Props.model;
  var sendActions = Props.sendActions;
  var property = Props.property;
  var context = Props.context;
  var schemaGroup = Props.schemaGroup;
  var role = Props.role;
  var match = React.useState(function () {
        return "Closed";
      });
  var setStatus = match[1];
  var propertyGroup = AvoUtils.getAnalyticsPropertyGroup(model, property);
  var match$1 = rule.definition;
  if (match$1.NAME !== "NameMapping") {
    return null;
  }
  var match$2 = match$1.VAL;
  var name = match$2.name;
  var destination = match$2.destination;
  var destinationId = destination !== undefined && typeof destination === "object" && destination.NAME === "Destination" ? destination.VAL : undefined;
  var match$3 = rule.item;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.margin2(Css.px(6), Css.px(0)),
                    tl: {
                      hd: Css.fontSize(Styles.FontSize.small),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.width(Css.pct(100)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, "Send as", React.createElement("button", {
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.button),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.margin2(Css.px(0), Css.px(5)),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.borderRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.padding2(Css.px(4), Css.px(8)),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.blueBg),
                                        tl: {
                                          hd: Css.color(Styles.Color.blue),
                                          tl: {
                                            hd: Css.fontSize(Styles.FontSize.regular),
                                            tl: {
                                              hd: Css.textAlign("center"),
                                              tl: {
                                                hd: Css.position("relative"),
                                                tl: {
                                                  hd: Css.hover({
                                                        hd: Css.color(Styles.Color.blueSecondary),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: {
                                                    hd: Css.cursor("text"),
                                                    tl: {
                                                      hd: Css.minHeight(Css.px(25)),
                                                      tl: {
                                                        hd: Css.minWidth(Css.px(150)),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      }),
                  disabled: !Models.Role.canEdit(role),
                  onClick: (function (param) {
                      return Curry._1(setStatus, (function (param) {
                                    return "EditingName";
                                  }));
                    })
                }, Belt_Option.getWithDefault(name, "")), "to", React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.px(4)),
                        tl: /* [] */0
                      })
                }), React.createElement(Select.make, {
                  disabled: !Models.Role.canEdit(role),
                  maxWidth: Css.px(200),
                  onSelect: (function (nextDestinationId) {
                      var nextDestinationId$1;
                      switch (nextDestinationId) {
                        case "None" :
                            nextDestinationId$1 = undefined;
                            break;
                        case "all-destinations" :
                            nextDestinationId$1 = "AllDestinations";
                            break;
                        default:
                          nextDestinationId$1 = {
                            NAME: "Destination",
                            VAL: nextDestinationId
                          };
                      }
                      return Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      var propertyNameMappingsInSchema = Belt_List.keepMap(model.rules, (function (item) {
                                              var match = item.item;
                                              var match$1 = item.definition;
                                              if (typeof match === "object" && match.NAME === "Property" && typeof match$1 === "object" && match$1.NAME === "NameMapping") {
                                                return match.VAL;
                                              }
                                              
                                            }));
                                      return AnalyticsRe.propertyUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), propertyGroup, "UpdateRule", undefined, "NameMapping", Belt_List.length(BeltListExtensions.dedupeString(propertyNameMappingsInSchema)), Belt_List.length(propertyNameMappingsInSchema), Belt_List.length(Belt_List.keep(model.rules, (function (rule) {
                                                            var match = rule.item;
                                                            var match$1 = rule.definition;
                                                            if (typeof match === "object" && match.NAME === "Property" && typeof match$1 === "object" && match$1.NAME === "NameMapping") {
                                                              return match.VAL === property.id;
                                                            } else {
                                                              return false;
                                                            }
                                                          }))), Belt_List.length(model.properties), NamedBranch.getId(branch), schemaGroup.schemaId);
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdateRule",
                                        VAL: [
                                          rule.id,
                                          rule.item,
                                          {
                                            NAME: "NameMapping",
                                            VAL: {
                                              destination: nextDestinationId$1,
                                              name: name
                                            }
                                          }
                                        ]
                                      },
                                      context
                                    ]]
                                ]);
                    }),
                  options: Belt_List.concatMany([
                        Belt_Option.isNone(destinationId) ? ({
                              hd: [
                                {
                                  NAME: "Label",
                                  VAL: "Select Destination"
                                },
                                "None"
                              ],
                              tl: /* [] */0
                            }) : /* [] */0,
                        Belt_List.map(Belt_List.keep(model.destinations, (function (destination) {
                                    return !Belt_List.some(model.rules, (function (rule) {
                                                  var match = rule.item;
                                                  var match$1 = rule.definition;
                                                  if (typeof match !== "object") {
                                                    return false;
                                                  }
                                                  if (match.NAME !== "Property") {
                                                    return false;
                                                  }
                                                  if (typeof match$1 !== "object") {
                                                    return false;
                                                  }
                                                  if (match$1.NAME !== "NameMapping") {
                                                    return false;
                                                  }
                                                  var match$2 = match$1.VAL.destination;
                                                  if (match$2 === undefined) {
                                                    return false;
                                                  }
                                                  if (typeof match$2 !== "object") {
                                                    return false;
                                                  }
                                                  if (match$2.NAME !== "Destination") {
                                                    return false;
                                                  }
                                                  var id = match$2.VAL;
                                                  var isCurrentlySelected = Caml_obj.caml_equal(id, destinationId);
                                                  var isSameDestination = id === destination.id;
                                                  var isSameProperty = match.VAL === property.id;
                                                  if (!isCurrentlySelected && isSameDestination) {
                                                    return isSameProperty;
                                                  } else {
                                                    return false;
                                                  }
                                                }));
                                  })), (function (destination) {
                                return [
                                        {
                                          NAME: "Label",
                                          VAL: StateUtils.getDestinationName(destination) + (
                                            StateUtils.isPropertySentToDestination(model, property.id, destination.id) ? "" : " (property never sent here)"
                                          ) + "\n              )"
                                        },
                                        destination.id
                                      ];
                              })),
                        !Belt_List.some(model.rules, (function (item) {
                                var match = rule.item;
                                var match$1 = item.item;
                                var match$2 = item.definition;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                if (match.NAME !== "Property") {
                                  return false;
                                }
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "Property") {
                                  return false;
                                }
                                if (typeof match$2 !== "object") {
                                  return false;
                                }
                                if (match$2.NAME !== "NameMapping") {
                                  return false;
                                }
                                var match$3 = match$2.VAL.destination;
                                if (match$3 !== undefined && match$3 === "AllDestinations" && match$1.VAL === property.id) {
                                  return Caml_obj.caml_notequal(destination, "AllDestinations");
                                } else {
                                  return false;
                                }
                              })) ? ({
                              hd: [
                                {
                                  NAME: "Label",
                                  VAL: "All destinations"
                                },
                                "all-destinations"
                              ],
                              tl: /* [] */0
                            }) : /* [] */0
                      ]),
                  value: destination !== undefined ? (
                      typeof destination === "object" ? destination.VAL : "all-destinations"
                    ) : "none"
                }), typeof match$3 === "object" && match$3.NAME === "PropertyRef" ? "on this event" : null, Models.Role.canEdit(role) ? React.createElement("button", {
                    className: Curry._1(Css.merge, {
                          hd: Curry._1(Css.style, Styles.button),
                          tl: {
                            hd: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(5), Css.px(10)),
                                  tl: {
                                    hd: Css.marginLeft(Css.px(5)),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.small),
                                        tl: {
                                          hd: Css.color(Styles.Color.grey50),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.color(Styles.Color.grapeError),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }),
                    onClick: (function (param) {
                        return Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (branch) {
                                        var propertyNameMappingsInSchema = Belt_List.keepMap(model.rules, (function (item) {
                                                var match = item.item;
                                                var match$1 = item.definition;
                                                if (typeof match === "object" && match.NAME === "Property" && typeof match$1 === "object" && match$1.NAME === "NameMapping" && item.id !== rule.id) {
                                                  return match.VAL;
                                                }
                                                
                                              }));
                                        AnalyticsRe.propertyUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), propertyGroup, "RemoveRule", undefined, "NameMapping", Belt_List.length(BeltListExtensions.dedupeString(propertyNameMappingsInSchema)), Belt_List.length(propertyNameMappingsInSchema), Belt_List.length(Belt_List.keep(model.rules, (function (rule) {
                                                        var match = rule.item;
                                                        var match$1 = rule.definition;
                                                        if (typeof match === "object" && match.NAME === "Property" && typeof match$1 === "object" && match$1.NAME === "NameMapping") {
                                                          return match.VAL === property.id;
                                                        } else {
                                                          return false;
                                                        }
                                                      }))) - 1 | 0, Belt_List.length(model.properties), NamedBranch.getId(branch), schemaGroup.schemaId);
                                        
                                      }),
                                    undefined,
                                    [[
                                        {
                                          NAME: "RemoveRule",
                                          VAL: [
                                            rule.id,
                                            rule.item
                                          ]
                                        },
                                        context
                                      ]]
                                  ]);
                      })
                  }, "Remove") : null, match[0] === "EditingName" ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("fixed"),
                          tl: {
                            hd: Css.top(Css.px(0)),
                            tl: {
                              hd: Css.right(Css.px(0)),
                              tl: {
                                hd: Css.bottom(Css.px(0)),
                                tl: {
                                  hd: Css.left(Css.px(0)),
                                  tl: {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.justifyContent("center"),
                                        tl: {
                                          hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.width(Css.px(500)),
                              tl: {
                                hd: Css.maxWidth(Css.pct(100)),
                                tl: {
                                  hd: Css.height(Css.px(70)),
                                  tl: {
                                    hd: Css.position("relative"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement(NameInput.make, {
                            name: Belt_Option.getWithDefault(name, ""),
                            autoFocus: true,
                            onChange: (function (nextName, _namingConvention, _correctCase) {
                                if (Caml_obj.caml_notequal(name, nextName)) {
                                  Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        (function (branch) {
                                            var propertyNameMappingsInSchema = Belt_List.keepMap(model.rules, (function (item) {
                                                    var match = item.item;
                                                    var match$1 = item.definition;
                                                    if (typeof match === "object" && match.NAME === "Property" && typeof match$1 === "object" && match$1.NAME === "NameMapping") {
                                                      return match.VAL;
                                                    }
                                                    
                                                  }));
                                            return AnalyticsRe.propertyUpdated(AnalyticsUtils.enrichSchemaGroupWithNamedBranch(schemaGroup, branch), propertyGroup, "UpdateRule", undefined, "NameMapping", Belt_List.length(BeltListExtensions.dedupeString(propertyNameMappingsInSchema)), Belt_List.length(propertyNameMappingsInSchema), Belt_List.length(Belt_List.keep(model.rules, (function (rule) {
                                                                  var match = rule.item;
                                                                  var match$1 = rule.definition;
                                                                  if (typeof match === "object" && match.NAME === "Property" && typeof match$1 === "object" && match$1.NAME === "NameMapping") {
                                                                    return match.VAL === property.id;
                                                                  } else {
                                                                    return false;
                                                                  }
                                                                }))), Belt_List.length(model.properties), NamedBranch.getId(branch), schemaGroup.schemaId);
                                          }),
                                        undefined,
                                        [[
                                            {
                                              NAME: "UpdateRule",
                                              VAL: [
                                                rule.id,
                                                rule.item,
                                                {
                                                  NAME: "NameMapping",
                                                  VAL: {
                                                    destination: Belt_Option.map(destinationId, (function (id) {
                                                            return {
                                                                    NAME: "Destination",
                                                                    VAL: id
                                                                  };
                                                          })),
                                                    name: nextName
                                                  }
                                                }
                                              ]
                                            },
                                            context
                                          ]]
                                      ]);
                                }
                                return Curry._1(setStatus, (function (param) {
                                              return "Closed";
                                            }));
                              }),
                            submitLabel: "Update Mapping",
                            placeholder: "Property Name",
                            onClose: (function (param) {
                                return Curry._1(setStatus, (function (param) {
                                              return "Closed";
                                            }));
                              }),
                            fullscreen: true,
                            dirty: false,
                            itemType: "PropertyNameMapping",
                            actionType: Belt_Option.isSome(name) ? "Rename" : "Create",
                            getPossibleItemLink: (function (propertyName) {
                                return Belt_Option.map(StateUtils.getPropertyByName(propertyName, model), (function (param) {
                                              return Router.Link.addDrawerItem(undefined, {
                                                          NAME: "property",
                                                          VAL: [
                                                            param.id,
                                                            undefined
                                                          ]
                                                        });
                                            }));
                              })
                          }))) : null);
}

var make = PropertyRule;

exports.make = make;
/* Css Not a pure module */
