// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var CopyButton = require("../CopyButton.bs.js");

function code(docsCodeBoxStyles, docsCodeStyles, docsCodeLightBlueStyles, docsCodeMintGreenStyles, docsCodeOrangeStyles, copyButtonBoxStyles, language) {
  if (language !== undefined) {
    if (language === "ts" || language === "js" || language === "js_v2") {
      return React.createElement("div", {
                  className: docsCodeBoxStyles
                }, React.createElement("pre", {
                      className: docsCodeStyles
                    }, React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, "inspector"), ".trackSchemaFromEvent(", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"Event Name\""), ", {", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"String property\""), ": ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"some string\""), ",\n    ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"Boolean property\""), ": ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "true"), ",\n    ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"Integer property\""), ": ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "10"), "\n});"), React.createElement("div", {
                      className: copyButtonBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: "inspector.trackSchemaFromEvent(\"Event Name\", {\"prop name\": \"prop value\"});"
                        })));
    } else if (language === "re" || language === "re_v2") {
      return React.createElement("div", {
                  className: docsCodeBoxStyles
                }, React.createElement("pre", {
                      className: docsCodeStyles
                    }, "let () = ", React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, "inspector"), React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, "->"), React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, "AvoInspector"), ".trackSchemaFromEvent(", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"Event Name\""), ", Obj.magic({\n    ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"String property\""), ": ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"some string\""), ",\n    ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"Boolean property\""), ": ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "true"), ",\n    ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"Integer property\""), ": ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "10"), "\n}))"), React.createElement("div", {
                      className: copyButtonBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: "inspector->AvoInspector.trackSchemaFromEvent(\"Event Name\", Obj.magic({\"Property Name\": \"Property Value\"}))"
                        })));
    } else {
      return null;
    }
  } else {
    return null;
  }
}

exports.code = code;
/* react Not a pure module */
