// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Case = require("../../bs-case/src/case.bs.js");
var React = require("react");
var Models = require("./Models.bs.js");
var Router = require("./Router.bs.js");
var Actions = require("./actions.bs.js");
var AvoUtils = require("../../shared/utils/AvoUtils.bs.js");
var DiffView = require("./DiffView.bs.js");
var Redirect = require("./Redirect.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var DiffUtils = require("./DiffUtils.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var FetchModel = require("./FetchModel.bs.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RouterStore = require("./RouterStore.bs.js");
var BranchHeader = require("./BranchHeader.bs.js");
var LoadingCircle = require("./LoadingCircle.bs.js");
var ActionDiffMemo = require("./ActionDiffMemo.bs.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var BranchStatusModel = require("../../shared/models/BranchStatusModel.bs.js");
var LoadingFullscreen = require("./LoadingFullscreen.bs.js");
var BranchDiscrepancies = require("./BranchDiscrepancies.bs.js");
var BranchImplementation = require("./branchImplementation/BranchImplementation.bs.js");
var FirebaseFetcherHooks = require("./FirebaseFetcherHooks.bs.js");

function BranchView$BranchViewTab(Props) {
  var actions = Props.actions;
  var branchStatus = Props.branchStatus;
  var fromModel = Props.fromModel;
  var toModel = Props.toModel;
  var branchName = Props.branchName;
  var mergeWarnings = Props.mergeWarnings;
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var schema = Props.schema;
  var branchId = Props.branchId;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var masterSinceOpenedDiff = Props.masterSinceOpenedDiff;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var mergeConflicts = Props.mergeConflicts;
  var branchOpenAction = Props.branchOpenAction;
  var role = Props.role;
  var actionDiff = Props.actionDiff;
  var openBranches = Props.openBranches;
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var exit = 0;
  if (typeof schemaRoute === "object" && schemaRoute.NAME === "diff") {
    var match = schemaRoute.VAL;
    if (typeof match === "object") {
      if (match.NAME !== "comment") {
        return React.createElement(BranchImplementation.make, {
                    actions: actions,
                    branchStatus: branchStatus,
                    fromModel: fromModel,
                    subRoute: match.VAL,
                    toModel: toModel
                  });
      }
      exit = 2;
    } else {
      exit = 2;
    }
  } else {
    exit = 1;
  }
  switch (exit) {
    case 1 :
        return React.createElement(Redirect.make, {
                    path: Router.Link.getSchemaRouteLink(undefined, "dashboard")
                  });
    case 2 :
        return React.createElement(DiffView.make, {
                    branchStatus: branchStatus,
                    branchName: branchName,
                    mergeWarnings: mergeWarnings,
                    masterModel: masterModel,
                    branchModel: branchModel,
                    branchModelWithPulledMaster: branchModelWithPulledMaster,
                    schema: schema,
                    branchId: branchId,
                    masterModelBeforeBranch: masterModelBeforeBranch,
                    masterSinceOpenedDiff: masterSinceOpenedDiff,
                    latestMasterAction: masterModelLatestAction,
                    actions: actions,
                    mergeConflicts: mergeConflicts,
                    branchOpenAction: branchOpenAction,
                    role: role,
                    actionDiff: actionDiff,
                    openBranches: openBranches
                  });
    
  }
}

var BranchViewTab = {
  make: BranchView$BranchViewTab
};

function BranchView$WithCurrentMasterOrMasterAtBranchMergeResult(Props) {
  var schema = Props.schema;
  var role = Props.role;
  var openBranches = Props.openBranches;
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchStatus = Props.branchStatus;
  var branchOpenAction = Props.branchOpenAction;
  var masterModel = Props.masterModel;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var currentMasterOrMasterAtBranchMerge = Props.currentMasterOrMasterAtBranchMerge;
  var masterSinceOpenedDiff = ActionDiffMemo.diff(masterModelBeforeBranch, currentMasterOrMasterAtBranchMerge);
  var branchModelWithPulledMaster = Belt_List.reduceU(masterSinceOpenedDiff, branchModel, Actions.reduce);
  var match;
  if (typeof branchStatus === "number") {
    match = [
      masterModel,
      branchModelWithPulledMaster
    ];
  } else {
    switch (branchStatus.TAG | 0) {
      case /* Merged */1 :
      case /* Closed */2 :
          match = [
            masterModelBeforeBranch,
            branchModel
          ];
          break;
      default:
        match = [
          masterModel,
          branchModelWithPulledMaster
        ];
    }
  }
  var toModel = match[1];
  var fromModel = match[0];
  var actionDiff = ActionDiffMemo.diff(fromModel, toModel);
  var actions = AvoUtils.actionByObject(actionDiff);
  var actions$1 = AvoUtils.groupActionsByObjectId(actions);
  var onBranchActions = AvoUtils.groupActionsByObjectId(AvoUtils.actionByObject(ActionDiffMemo.diff(masterModelBeforeBranch, branchModel)));
  var mergeWarnings = DiffUtils.getMergeWarnings(onBranchActions, masterModel);
  var masterModelEventNamespace = Belt_SetString.fromArray(Belt_Array.mapU(Belt_List.toArray(masterModel.events), (function ($$event) {
              return Case.to_("Camel", Belt_Option.getWithDefault($$event.uniqueName, $$event.name));
            })));
  var masterModelPropertyNamespace = Belt_SetString.fromArray(Belt_Array.keepMapU(Belt_List.toArray(masterModel.properties), (function (property) {
              if (property.TAG === /* PropertyRef */0) {
                return ;
              }
              var property$1 = property._0;
              return Case.to_("Camel", Belt_Option.getWithDefault(property$1.uniqueName, property$1.name));
            })));
  var masterModelPropertyBundleNamespace = Belt_SetString.fromArray(Belt_Array.mapU(Belt_List.toArray(masterModel.propertyBundles), (function (bundle) {
              return Case.to_("Camel", bundle.name);
            })));
  var mergeConflicts = Belt_List.keepU(Belt_List.flatten(onBranchActions), (function (action) {
          var match = action[0];
          if (typeof match !== "object") {
            return false;
          }
          var variant = match.NAME;
          if (variant === "Property") {
            var match$1 = action[1];
            if (typeof match$1 !== "object") {
              return false;
            }
            if (match$1.NAME !== "CreateProperty") {
              return false;
            }
            var match$2 = StateUtils.getPropertyById(match$1.VAL[0], branchModel);
            if (match$2 === undefined) {
              return false;
            }
            if (match$2.TAG === /* PropertyRef */0) {
              return false;
            }
            var newProperty = match$2._0;
            var newPropertyNamespaceKey = Case.to_("Camel", Belt_Option.getWithDefault(newProperty.uniqueName, newProperty.name));
            return Belt_SetString.has(masterModelPropertyNamespace, newPropertyNamespaceKey);
          }
          if (variant === "Event") {
            var match$3 = action[1];
            if (typeof match$3 !== "object") {
              return false;
            }
            if (match$3.NAME !== "AddEvent") {
              return false;
            }
            var eventId = match$3.VAL[0];
            var newEvent = Belt_List.getByU(branchModel.events, (function ($$event) {
                    return $$event.id === eventId;
                  }));
            if (newEvent === undefined) {
              return false;
            }
            var newEventNamespaceKey = Case.to_("Camel", Belt_Option.getWithDefault(newEvent.uniqueName, newEvent.name));
            return Belt_SetString.has(masterModelEventNamespace, newEventNamespaceKey);
          }
          if (variant !== "PropertyGroup") {
            return false;
          }
          var match$4 = action[1];
          if (typeof match$4 !== "object") {
            return false;
          }
          if (match$4.NAME !== "CreatePropertyGroup") {
            return false;
          }
          var newBundleNamespaceKey = Case.to_("Camel", match$4.VAL[1]);
          return Belt_SetString.has(masterModelPropertyBundleNamespace, newBundleNamespaceKey);
        }));
  var match$1 = branchOpenAction.contents;
  var branchName = typeof match$1 === "object" && match$1.NAME === "OpenBranch" ? match$1.VAL[1] : "N/A";
  return React.createElement(BranchDiscrepancies.Provider.make, {
              mainModelToCompareTo: fromModel,
              branchModelToCompareTo: toModel,
              children: null
            }, React.createElement(BranchView$BranchViewTab, {
                  actions: actions$1,
                  branchStatus: branchStatus,
                  fromModel: fromModel,
                  toModel: toModel,
                  branchName: branchName,
                  mergeWarnings: mergeWarnings,
                  masterModel: masterModel,
                  branchModel: branchModel,
                  branchModelWithPulledMaster: branchModelWithPulledMaster,
                  schema: schema,
                  branchId: branchId,
                  masterModelBeforeBranch: masterModelBeforeBranch,
                  masterSinceOpenedDiff: masterSinceOpenedDiff,
                  masterModelLatestAction: masterModelLatestAction,
                  mergeConflicts: mergeConflicts,
                  branchOpenAction: branchOpenAction,
                  role: role,
                  actionDiff: actionDiff,
                  openBranches: openBranches
                }), React.createElement(BranchHeader.make, {
                  branchId: branchId,
                  branchName: branchName,
                  branchStatus: branchStatus,
                  schemaId: schema.id,
                  branchModel: branchModel,
                  masterModel: masterModel,
                  actions: actions$1,
                  branchOpenAction: branchOpenAction
                }));
}

var WithCurrentMasterOrMasterAtBranchMergeResult = {
  make: BranchView$WithCurrentMasterOrMasterAtBranchMergeResult
};

function BranchView$WithCurrentMasterOrMasterAtBranchMerge(Props) {
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchOpenAction = Props.branchOpenAction;
  var branchStatus = Props.branchStatus;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var masterModel = Props.masterModel;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var currentMasterOrMasterAtBranchMergeOrClose = Props.currentMasterOrMasterAtBranchMergeOrClose;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var schema = Props.schema;
  var masterSinceOpenedDiff = ActionDiffMemo.diff(masterModelBeforeBranch, currentMasterOrMasterAtBranchMergeOrClose);
  var branchModelWithPulledMaster = Belt_List.reduceU(masterSinceOpenedDiff, branchModel, Actions.reduce);
  var match;
  match = typeof branchStatus === "number" || branchStatus.TAG !== /* Merged */1 ? [
      masterModel,
      branchModelWithPulledMaster
    ] : [
      masterModelBeforeBranch,
      branchModel
    ];
  var toModel = match[1];
  var fromModel = match[0];
  var actionDiff = ActionDiffMemo.diff(fromModel, toModel);
  var actions = AvoUtils.actionByObject(actionDiff);
  var actions$1 = AvoUtils.groupActionsByObjectId(actions);
  var onBranchActions = AvoUtils.groupActionsByObjectId(AvoUtils.actionByObject(ActionDiffMemo.diff(masterModelBeforeBranch, branchModel)));
  var mergeWarnings = DiffUtils.getMergeWarnings(onBranchActions, masterModel);
  var branchActions = Belt_List.flatten(onBranchActions);
  var mergeConflicts = React.useMemo((function () {
          return DiffUtils.getMergeConflicts(branchActions, branchModel, masterModel);
        }), [
        branchModel,
        masterModel
      ]);
  var match$1 = branchOpenAction.contents;
  var branchName = typeof match$1 === "object" && match$1.NAME === "OpenBranch" ? match$1.VAL[1] : "N/A";
  return React.createElement(BranchDiscrepancies.Provider.make, {
              mainModelToCompareTo: fromModel,
              branchModelToCompareTo: toModel,
              children: null
            }, React.createElement(BranchView$BranchViewTab, {
                  actions: actions$1,
                  branchStatus: branchStatus,
                  fromModel: fromModel,
                  toModel: toModel,
                  branchName: branchName,
                  mergeWarnings: mergeWarnings,
                  masterModel: masterModel,
                  branchModel: branchModel,
                  branchModelWithPulledMaster: branchModelWithPulledMaster,
                  schema: schema,
                  branchId: branchId,
                  masterModelBeforeBranch: masterModelBeforeBranch,
                  masterSinceOpenedDiff: masterSinceOpenedDiff,
                  masterModelLatestAction: masterModelLatestAction,
                  mergeConflicts: mergeConflicts,
                  branchOpenAction: branchOpenAction,
                  role: role,
                  actionDiff: actionDiff,
                  openBranches: openBranches
                }), React.createElement(BranchHeader.make, {
                  branchId: branchId,
                  branchName: branchName,
                  branchStatus: branchStatus,
                  schemaId: schema.id,
                  branchModel: branchModel,
                  masterModel: masterModel,
                  actions: actions$1,
                  branchOpenAction: branchOpenAction
                }));
}

var WithCurrentMasterOrMasterAtBranchMerge = {
  make: BranchView$WithCurrentMasterOrMasterAtBranchMerge
};

function BranchView$WithMasterModel(Props) {
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchOpenAction = Props.branchOpenAction;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var masterModel = Props.masterModel;
  var maybeCloseAction = Props.maybeCloseAction;
  var maybeMergeAction = Props.maybeMergeAction;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var schema = Props.schema;
  var setStateActions = Props.setStateActions;
  var branchStatus = BranchStatusModel.get(branchOpenAction, maybeMergeAction, maybeCloseAction, setStateActions);
  var match = branchModel.branchPointer;
  var match$1 = branchModel.branchPointer;
  var fetchStateMainBeforeBranch = FetchModel.use(match !== undefined ? undefined : Models.toDateOption(Models.Action.getCreatedAt(branchOpenAction)), match$1 !== undefined ? Caml_option.some(new Date(match$1[1])) : undefined, "master");
  var branchMergeOrCloseTimestamp = maybeMergeAction !== undefined ? Models.toDateOption(Models.Action.getCreatedAt(Caml_option.valFromOption(maybeMergeAction))) : (
      maybeCloseAction !== undefined ? Models.toDateOption(Models.Action.getCreatedAt(Caml_option.valFromOption(maybeCloseAction))) : undefined
    );
  var fetchStateMainAtMergeOrClose = FetchModel.use(undefined, branchMergeOrCloseTimestamp, "master");
  var message;
  var exit = 0;
  switch (fetchStateMainBeforeBranch.TAG | 0) {
    case /* Loading */0 :
        return React.createElement(LoadingFullscreen.make, {
                    message: fetchStateMainBeforeBranch._0
                  });
    case /* Error */1 :
        exit = 2;
        break;
    case /* Result */2 :
        var masterModelBeforeBranch = fetchStateMainBeforeBranch._0;
        if (branchMergeOrCloseTimestamp === undefined) {
          return React.createElement(BranchView$WithCurrentMasterOrMasterAtBranchMerge, {
                      branchId: branchId,
                      branchModel: branchModel,
                      branchOpenAction: branchOpenAction,
                      branchStatus: branchStatus,
                      masterModelLatestAction: masterModelLatestAction,
                      masterModel: masterModel,
                      masterModelBeforeBranch: masterModelBeforeBranch,
                      currentMasterOrMasterAtBranchMergeOrClose: masterModel,
                      openBranches: openBranches,
                      role: role,
                      schema: schema
                    });
        }
        switch (fetchStateMainAtMergeOrClose.TAG | 0) {
          case /* Loading */0 :
              exit = 2;
              break;
          case /* Error */1 :
              message = fetchStateMainAtMergeOrClose._0;
              break;
          case /* Result */2 :
              return React.createElement(BranchView$WithCurrentMasterOrMasterAtBranchMerge, {
                          branchId: branchId,
                          branchModel: branchModel,
                          branchOpenAction: branchOpenAction,
                          branchStatus: branchStatus,
                          masterModelLatestAction: masterModelLatestAction,
                          masterModel: masterModel,
                          masterModelBeforeBranch: masterModelBeforeBranch,
                          currentMasterOrMasterAtBranchMergeOrClose: fetchStateMainAtMergeOrClose._0,
                          openBranches: openBranches,
                          role: role,
                          schema: schema
                        });
          
        }
        break;
    
  }
  if (exit === 2) {
    if (branchMergeOrCloseTimestamp !== undefined) {
      switch (fetchStateMainAtMergeOrClose.TAG | 0) {
        case /* Loading */0 :
            return React.createElement(LoadingFullscreen.make, {
                        message: fetchStateMainAtMergeOrClose._0
                      });
        case /* Error */1 :
        case /* Result */2 :
            message = fetchStateMainBeforeBranch._0;
            break;
        
      }
    } else {
      message = fetchStateMainBeforeBranch._0;
    }
  }
  return React.createElement(FetchModel.$$Error.make, {
              message: message
            });
}

var WithMasterModel = {
  make: BranchView$WithMasterModel
};

function BranchView(Props) {
  var masterModel = Props.masterModel;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var branchModel = Props.branchModel;
  var schema = Props.schema;
  var branchId = Props.branchId;
  var role = Props.role;
  var openBranches = Props.openBranches;
  var branchLifecycleState = FirebaseFetcherHooks.useBranchLifecycleActions(schema.id, branchId, undefined, undefined);
  var branchOpenAction = branchLifecycleState[0];
  if (branchOpenAction === undefined) {
    return React.createElement(LoadingCircle.make, {});
  }
  var branchOpenAction$1 = Caml_option.valFromOption(branchOpenAction);
  var match = branchModel.branchPointer;
  return React.createElement(BranchView$WithMasterModel, {
              branchId: branchId,
              branchModel: branchModel,
              branchOpenAction: branchOpenAction$1,
              masterModelLatestAction: masterModelLatestAction,
              masterModel: masterModel,
              maybeCloseAction: branchLifecycleState[2],
              maybeMergeAction: branchLifecycleState[1],
              openBranches: openBranches,
              role: role,
              schema: schema,
              setStateActions: branchLifecycleState[3],
              key: "masterModelRefetchKey-" + (
                match !== undefined ? match[1] : Belt_Option.getWithDefault(Belt_Option.map(Caml_option.nullable_to_opt(Models.Action.getCreatedAt(branchOpenAction$1)), (function (date) {
                              return date.toDate().toString();
                            })), "")
              )
            });
}

var make = BranchView;

exports.BranchViewTab = BranchViewTab;
exports.WithCurrentMasterOrMasterAtBranchMergeResult = WithCurrentMasterOrMasterAtBranchMergeResult;
exports.WithCurrentMasterOrMasterAtBranchMerge = WithCurrentMasterOrMasterAtBranchMerge;
exports.WithMasterModel = WithMasterModel;
exports.make = make;
/* Case Not a pure module */
