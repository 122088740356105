// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Caml = require("rescript/lib/js/caml.js");
var $$Text = require("../Text.bs.js");
var Uuid = require("uuid");
var Curry = require("rescript/lib/js/curry.js");
var Title = require("../Title.bs.js");
var React = require("react");
var Button = require("../Button.bs.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Router = require("../Router.bs.js");
var Spacer = require("../Spacer.bs.js");
var Styles = require("../styles.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var ModelStore = require("../ModelStore.bs.js");
var AnalyticsRe = require("../analyticsRe.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SimpleTable = require("../SimpleTable.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var InspectorStats = require("./InspectorStats.bs.js");
var WorkspaceContext = require("../WorkspaceContext.bs.js");
var GlobalSendContext = require("../GlobalSendContext.bs.js");
var SchemaGroupContext = require("../SchemaGroupContext.bs.js");
var InspectorEmptyState = require("./InspectorEmptyState.bs.js");
var InspectorIssuesStore = require("./InspectorIssuesStore.bs.js");
var InspectorViewsTableConfig = require("./InspectorViewsTableConfig.bs.js");
var InspectorIssuesFilterHooks = require("./InspectorIssuesFilterHooks.bs.js");
var InspectorIssuesFilterUtils = require("../../../shared/utils/InspectorIssuesFilterUtils.bs.js");

var container = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.gap(Css.px(8)),
            tl: {
              hd: Css.justifyContent("stretch"),
              tl: {
                hd: Css.backgroundColor(Styles.Color.grey20),
                tl: {
                  hd: Css.borderWidth(Css.px(0)),
                  tl: {
                    hd: Css.padding2(Css.px(40), Css.px(24)),
                    tl: {
                      hd: Css.paddingBottom("zero"),
                      tl: {
                        hd: Css.wordWrap("breakWord"),
                        tl: {
                          hd: Css.height({
                                NAME: "substract",
                                VAL: [
                                  Css.vh(100),
                                  Css.px(64)
                                ]
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingRight(Css.px(24)),
          tl: {
            hd: Css.paddingLeft(Css.px(40)),
            tl: {
              hd: Css.height(Css.px(64)),
              tl: {
                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.grey30),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function InspectorIssuesSavedViews$CustomViewsEmptyState$Imagery$NoSavedViews(Props) {
  return React.createElement("svg", {
              height: "102",
              width: "118",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M45.88 19.1C30.34 26.23 12.37 37.21 4.7 54.4a40.17 40.17 0 0 0-2.85 9.23C.67 71.52.24 81.12 6.43 87.7c5.61 5.84 14.06 4.05 18.16-1.19 10.17-12.89 24.58-17.26 40.83-15.89 4.62-9.17-6.92-43.65-19.54-51.54Z",
                  fill: "#E6E9ED"
                }), React.createElement("path", {
                  d: "M65.54 69.61c3.54-1.44 1.99-13.53-3.48-27.02C56.6 29.1 49.3 19.33 45.76 20.77c-3.55 1.44-2 13.53 3.46 27.02C54.7 61.28 62 71.05 65.54 69.61Z",
                  fill: "#CCD1D9",
                  stroke: "#6D7783",
                  strokeMiterlimit: "10",
                  strokeWidth: "4.66"
                }), React.createElement("path", {
                  d: "M72.22 91.9a2.33 2.33 0 1 0 4.33-1.74l-4.33 1.75Zm4.33-1.74-8.76-21.61-4.32 1.75 8.75 21.6 4.33-1.74Z",
                  fill: "#6D7783"
                }), React.createElement("path", {
                  d: "M69.45 60.47a7.59 7.59 0 1 0 0-15.17 7.59 7.59 0 0 0 0 15.17Z",
                  fill: "#EC008C"
                }), React.createElement("path", {
                  d: "M108.1 14.2a3.1 3.1 0 1 0 0-6.2 3.1 3.1 0 0 0 0 6.2Z",
                  fill: "#C90077"
                }), React.createElement("path", {
                  d: "M66.1 20.2a3.1 3.1 0 1 0 0-6.2 3.1 3.1 0 0 0 0 6.2ZM109.5 52a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z",
                  fill: "#EC008C"
                }), React.createElement("path", {
                  d: "M90.1 49.2a3.1 3.1 0 1 0 0-6.2 3.1 3.1 0 0 0 0 6.2Z",
                  fill: "#FEEBF2"
                }), React.createElement("path", {
                  d: "M73.8 36.59a3.8 3.8 0 1 0 0-7.59 3.8 3.8 0 0 0 0 7.59Z",
                  fill: "#EC008C"
                }), React.createElement("path", {
                  d: "M55.42 38.57a3.93 3.93 0 1 0 0-7.86 3.93 3.93 0 0 0 0 7.86ZM87.2 26.22a6.14 6.14 0 1 0 0-12.28 6.14 6.14 0 0 0 0 12.28Z",
                  fill: "#F87CBC"
                }), React.createElement("path", {
                  d: "M105.14 37.28a6.14 6.14 0 1 0 0-12.28 6.14 6.14 0 0 0 0 12.28Z",
                  fill: "#EC008C"
                }));
}

function InspectorIssuesSavedViews$CustomViewsEmptyState$Imagery$Loading(Props) {
  var match = React.useState(function () {
        return Uuid.v4();
      });
  var id = match[0];
  return React.createElement("svg", {
              height: "119",
              width: "119",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("circle", {
                  cx: "59.5",
                  cy: "59.5",
                  fill: "url(#" + id + "-a)",
                  r: "59.5"
                }), React.createElement("path", {
                  d: "M82.8 26.2V39c0 9.5-5.8 17.4-14 21a23 23 0 0 1 14 21v12.7H37V81c0-9.4 5.8-17.4 14-21A23 23 0 0 1 37 39V26.2h45.7Z",
                  fill: "url(#" + id + "-b)"
                }), React.createElement("path", {
                  d: "M59.9 60s-13.6-5.8-13.6-12.8h27.3c-.2 7-13.7 12.8-13.7 12.8ZM44.7 75.4c-1.8 3.9-3 8.7-3 13.6h36.4c0-5.1-1-9.7-3-13.6H44.8Z",
                  fill: "#EC008C"
                }), React.createElement("path", {
                  d: "M84.9 93.7H35c-2 0-3.7.8-3.7 2v1.7c0 1 1.7 2 3.7 2H85c2 0 3.7-1 3.7-2v-1.8c0-1-1.7-2-3.7-2ZM84.9 20.5H35c-2 0-3.7 1-3.7 2v1.8c0 1 1.7 2 3.7 2H85c2 0 3.7-1 3.7-2v-1.8c0-1-1.7-2-3.7-2Z",
                  fill: "#AAB2BD"
                }), React.createElement("path", {
                  d: "M82.8 26.2H37V29h45.7v-2.8Z",
                  fill: "#CCD1D9"
                }), React.createElement("circle", {
                  cx: "60",
                  cy: "64.5",
                  fill: "#EC008C",
                  r: "1.8"
                }), React.createElement("circle", {
                  cx: "60",
                  cy: "70.9",
                  fill: "#EC008C",
                  r: "1.8"
                }), React.createElement("defs", undefined, React.createElement("linearGradient", {
                      id: id + "-a",
                      gradientUnits: "userSpaceOnUse",
                      x1: "59.5",
                      x2: "59.5",
                      y1: "0",
                      y2: "119"
                    }, React.createElement("stop", {
                          stopColor: "#FAFBFD"
                        }), React.createElement("stop", {
                          offset: "1",
                          stopColor: "#E6E9ED"
                        })), React.createElement("linearGradient", {
                      id: id + "-b",
                      gradientUnits: "userSpaceOnUse",
                      x1: "60",
                      x2: "60",
                      y1: "26.2",
                      y2: "93.7"
                    }, React.createElement("stop", {
                          stopColor: "#fff"
                        }), React.createElement("stop", {
                          offset: "1",
                          stopColor: "#F5F7FA"
                        }))));
}

function InspectorIssuesSavedViews$CustomViewsEmptyState$SavedViewsLoading(Props) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(80)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                tl: {
                                  hd: Css.borderTopWidth("zero"),
                                  tl: {
                                    hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.borderBottomRightRadius(Styles.Border.radius),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(InspectorIssuesSavedViews$CustomViewsEmptyState$Imagery$Loading, {}), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement($$Text.make, {
                  size: "Huge",
                  weight: "Semi",
                  color: Styles.Color.grey80,
                  children: "Loading Saved Views..."
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.grey70,
                  children: "This might take a few seconds"
                }));
}

function InspectorIssuesSavedViews$CustomViewsEmptyState$NoSavedViewsEmptyState(Props) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(80)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", Styles.Color.grey30),
                                tl: {
                                  hd: Css.borderTopWidth("zero"),
                                  tl: {
                                    hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.borderBottomRightRadius(Styles.Border.radius),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(InspectorIssuesSavedViews$CustomViewsEmptyState$Imagery$NoSavedViews, {}), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement($$Text.make, {
                  size: "Huge",
                  weight: "Semi",
                  color: Styles.Color.grey80,
                  children: "Set up your first saved view"
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(280)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.gap(Css.px(16)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      textAlign: "center",
                      color: Styles.Color.grey70,
                      children: "Save a filtered inspector view for faster access and custom alerts to never miss new issues for your critical events"
                    }), React.createElement(Button.make, {
                      label: "Show me how",
                      onClick: (function (param) {
                          return Router.Schema.pushSchemaRoute(undefined, "start_tour=saved_views", {
                                      NAME: "inspector",
                                      VAL: "issues"
                                    });
                        })
                    })));
}

function InspectorIssuesSavedViews$CustomViewsEmptyState(Props) {
  var loadingStatus = Props.loadingStatus;
  if (loadingStatus === "error") {
    return null;
  } else if (loadingStatus === "loading") {
    return React.createElement(InspectorIssuesSavedViews$CustomViewsEmptyState$SavedViewsLoading, {});
  } else {
    return React.createElement(InspectorIssuesSavedViews$CustomViewsEmptyState$NoSavedViewsEmptyState, {});
  }
}

var avoViews = [{
    id: "all-issues",
    name: "All Issues",
    createdBy: "avo@avo.app",
    createdAt: new Date(),
    lastModifiedBy: undefined,
    lastModifiedAt: undefined,
    filters: [],
    order: undefined,
    isArchived: false
  }];

var Table = SimpleTable.Make({
      tableName: InspectorViewsTableConfig.AvoViews.tableName,
      getRowId: InspectorViewsTableConfig.AvoViews.getRowId,
      defaultOrder: InspectorViewsTableConfig.AvoViews.defaultOrder
    });

function InspectorIssuesSavedViews$AvoViews(Props) {
  var globalSend = Props.globalSend;
  var issuesCountMap = Props.issuesCountMap;
  var match = WorkspaceContext.use(undefined);
  var emailsById = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(match.members, (function (member) {
                  return [
                          member.id,
                          Caml_option.undefined_to_opt(member.email)
                        ];
                }))));
  var columns = [
    {
      NAME: "component",
      VAL: [
        {
          id: "view",
          name: "View",
          width: "grow",
          compare: (function (a, b) {
              return Caml.caml_string_compare(a.name, b.name);
            }),
          defaultOrder: "asc",
          alignment: "left"
        },
        (function (view) {
            return React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Medium",
                        children: view.name
                      });
          })
      ]
    },
    {
      NAME: "component",
      VAL: [
        {
          id: "issues",
          name: "Issues",
          width: {
            NAME: "fixed",
            VAL: Css.px(80)
          },
          compare: (function (a, b) {
              return Caml_obj.caml_compare(Belt_MapString.get(issuesCountMap, a.id), Belt_MapString.get(issuesCountMap, b.id));
            }),
          defaultOrder: "desc",
          alignment: "left"
        },
        (function (view) {
            var issuesCount = Belt_MapString.get(issuesCountMap, view.id);
            if (issuesCount !== undefined) {
              return React.createElement(InspectorViewsTableConfig.IssueCount.make, {
                          issuesCount: issuesCount
                        });
            } else {
              return null;
            }
          })
      ]
    },
    {
      NAME: "component",
      VAL: [
        {
          id: "createdBy",
          name: "Created By",
          width: {
            NAME: "fixed",
            VAL: Css.px(120)
          },
          compare: (function (a, b) {
              return Caml_obj.caml_compare(Belt_MapString.get(emailsById, a.createdBy), Belt_MapString.get(emailsById, b.createdBy));
            }),
          defaultOrder: "asc",
          alignment: "left"
        },
        (function (param) {
            return React.createElement(InspectorViewsTableConfig.CreatedBy.make, {
                        id: param.createdBy
                      });
          })
      ]
    },
    {
      NAME: "component",
      VAL: [
        {
          id: "lastModified",
          name: "",
          width: {
            NAME: "fixed",
            VAL: Css.px(200)
          },
          compare: undefined,
          defaultOrder: "desc",
          alignment: "left"
        },
        (function (param) {
            return null;
          })
      ]
    },
    {
      NAME: "component",
      VAL: [
        {
          id: "alerts",
          name: "Alerts",
          width: {
            NAME: "fixed",
            VAL: Css.px(200)
          },
          compare: undefined,
          defaultOrder: "desc",
          alignment: "left"
        },
        (function (_view) {
            return React.createElement(InspectorViewsTableConfig.Alerts.make, {
                        globalSend: globalSend,
                        savedView: undefined
                      });
          })
      ]
    },
    {
      NAME: "component",
      VAL: [
        {
          id: "contextMenu",
          name: "",
          width: {
            NAME: "fixed",
            VAL: Css.px(40)
          },
          compare: undefined,
          defaultOrder: "desc",
          alignment: "right"
        },
        (function (view) {
            return React.createElement(InspectorViewsTableConfig.Menu.make, {
                        view: view,
                        withArchive: false,
                        globalSend: globalSend
                      });
          })
      ]
    }
  ];
  return React.createElement(Table.make, {
              columns: columns,
              maxHeight: Css.px(336),
              onRowClick: (function (id) {
                  if (id === "all-issues") {
                    return Router.Schema.pushSchemaRoute(undefined, undefined, {
                                NAME: "inspector",
                                VAL: "issues"
                              });
                  } else {
                    return Js_exn.raiseError("Unknown Avo View");
                  }
                }),
              rowSize: 56,
              data: avoViews,
              scrollLock: avoViews.length <= 6
            });
}

var Table$1 = SimpleTable.Make({
      tableName: InspectorViewsTableConfig.CustomViews.tableName,
      getRowId: InspectorViewsTableConfig.CustomViews.getRowId,
      defaultOrder: InspectorViewsTableConfig.CustomViews.defaultOrder
    });

function InspectorIssuesSavedViews$CustomViews(Props) {
  var globalSend = Props.globalSend;
  var issuesCountMap = Props.issuesCountMap;
  var data = Props.data;
  var loadingStatus = Props.loadingStatus;
  var match = WorkspaceContext.use(undefined);
  var emailsById = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(match.members, (function (member) {
                  return [
                          member.id,
                          Caml_option.undefined_to_opt(member.email)
                        ];
                }))));
  var columns = [
    {
      NAME: "component",
      VAL: [
        {
          id: "view",
          name: "View",
          width: "grow",
          compare: (function (a, b) {
              return Caml.caml_string_compare(a.name, b.name);
            }),
          defaultOrder: "asc",
          alignment: "left"
        },
        (function (view) {
            return React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Medium",
                        children: view.name
                      });
          })
      ]
    },
    {
      NAME: "component",
      VAL: [
        {
          id: "issues",
          name: "Issues",
          width: {
            NAME: "fixed",
            VAL: Css.px(80)
          },
          compare: (function (a, b) {
              return Caml_obj.caml_compare(Belt_MapString.get(issuesCountMap, a.id), Belt_MapString.get(issuesCountMap, b.id));
            }),
          defaultOrder: "desc",
          alignment: "left"
        },
        (function (view) {
            var issuesCount = Belt_MapString.get(issuesCountMap, view.id);
            if (issuesCount !== undefined) {
              return React.createElement(InspectorViewsTableConfig.IssueCount.make, {
                          issuesCount: issuesCount
                        });
            } else {
              return null;
            }
          })
      ]
    },
    {
      NAME: "component",
      VAL: [
        {
          id: "createdBy",
          name: "Created By",
          width: {
            NAME: "fixed",
            VAL: Css.px(120)
          },
          compare: (function (a, b) {
              return Caml_obj.caml_compare(Belt_MapString.get(emailsById, a.createdBy), Belt_MapString.get(emailsById, b.createdBy));
            }),
          defaultOrder: "asc",
          alignment: "left"
        },
        (function (param) {
            return React.createElement(InspectorViewsTableConfig.CreatedBy.make, {
                        id: param.createdBy
                      });
          })
      ]
    },
    {
      NAME: "component",
      VAL: [
        {
          id: "lastModified",
          name: "Last Modified",
          width: {
            NAME: "fixed",
            VAL: Css.px(200)
          },
          compare: (function (a, b) {
              var getLastModified = function (view) {
                return Belt_Option.getWithDefault(view.lastModifiedAt, view.createdAt);
              };
              return Caml_obj.caml_compare(getLastModified(a), getLastModified(b));
            }),
          defaultOrder: "desc",
          alignment: "left"
        },
        (function (view) {
            return React.createElement(InspectorViewsTableConfig.LastModified.make, {
                        view: view
                      });
          })
      ]
    },
    {
      NAME: "component",
      VAL: [
        {
          id: "alerts",
          name: "Alerts",
          width: {
            NAME: "fixed",
            VAL: Css.px(200)
          },
          compare: undefined,
          defaultOrder: "desc",
          alignment: "left"
        },
        (function (view) {
            return React.createElement(InspectorViewsTableConfig.Alerts.make, {
                        globalSend: globalSend,
                        savedView: view
                      });
          })
      ]
    },
    {
      NAME: "component",
      VAL: [
        {
          id: "contextMenu",
          name: "",
          width: {
            NAME: "fixed",
            VAL: Css.px(40)
          },
          compare: undefined,
          defaultOrder: "desc",
          alignment: "right"
        },
        (function (view) {
            return React.createElement(InspectorViewsTableConfig.Menu.make, {
                        view: view,
                        globalSend: globalSend
                      });
          })
      ]
    }
  ];
  return React.createElement(Table$1.make, {
              columns: columns,
              id: "saved-views-table",
              maxHeight: {
                NAME: "substract",
                VAL: [
                  Css.vh(100),
                  Css.px(323)
                ]
              },
              onRowClick: (function (id) {
                  return Router.Schema.pushSchemaRoute(undefined, undefined, {
                              NAME: "inspector",
                              VAL: {
                                NAME: "savedView",
                                VAL: id
                              }
                            });
                }),
              rowSize: 56,
              data: data,
              emptyState: React.createElement(InspectorIssuesSavedViews$CustomViewsEmptyState, {
                    loadingStatus: loadingStatus
                  })
            });
}

function InspectorIssuesSavedViews$Views(Props) {
  var hasSourcesWithoutInspector = Props.hasSourcesWithoutInspector;
  var schemaGroup = SchemaGroupContext.use(undefined);
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var match = InspectorIssuesStore.useAppVersionsInSyncWithIssues(3, undefined);
  var appVersions = match[0];
  var data = React.useMemo((function () {
          if (typeof savedViews === "object") {
            return Belt_List.toArray(savedViews.VAL);
          } else {
            return [];
          }
        }), [savedViews]);
  var match$1 = InspectorIssuesStore.useIssues(undefined, undefined);
  var issues = match$1.issues;
  var issuesCountMap = React.useMemo((function () {
          if (typeof savedViews === "object" && savedViews.NAME === "Loaded") {
            return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(Belt_List.concat(Belt_List.fromArray(avoViews), savedViews.VAL), (function (param) {
                                  return [
                                          param.id,
                                          InspectorIssuesFilterUtils.filterIssueViewModels(undefined, appVersions, issues, param.filters).length
                                        ];
                                }))));
          }
          
        }), [
        issues,
        savedViews,
        appVersions
      ]);
  return React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: header
                }, React.createElement(Title.make, {
                      children: "Saved Inspector Views",
                      size: "Tiny"
                    }), React.createElement(Spacer.make, {
                      width: 16
                    }), React.createElement(Button.make, {
                      icon: "plus",
                      label: "Connect Source",
                      onClick: (function (param) {
                          return Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: hasSourcesWithoutInspector ? ({
                                            NAME: "SourceSetup",
                                            VAL: [
                                              "inspectorSetup",
                                              {
                                                NAME: "inspector",
                                                VAL: "events"
                                              }
                                            ]
                                          }) : ({
                                            NAME: "CreateSource",
                                            VAL: "inspectorSetup"
                                          })
                                    });
                        }),
                      style: "outline"
                    })), React.createElement("div", {
                  className: container
                }, React.createElement(InspectorIssuesSavedViews$AvoViews, {
                      globalSend: globalSend,
                      issuesCountMap: issuesCountMap
                    }), React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.gap(Css.px(16)),
                                tl: {
                                  hd: Css.paddingLeft(Css.px(16)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Large",
                          weight: "Semi",
                          children: "Custom Views"
                        }), React.createElement(Button.make, {
                          icon: "plus",
                          label: "Create View",
                          onClick: (function (param) {
                              AnalyticsRe.inspectorSavedViewDraftInitiated(schemaGroup, schemaGroup.schemaId);
                              return Router.Schema.pushSchemaRoute(undefined, "draft", {
                                          NAME: "inspector",
                                          VAL: "issues"
                                        });
                            })
                        })), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement(InspectorIssuesSavedViews$CustomViews, {
                      globalSend: globalSend,
                      issuesCountMap: issuesCountMap,
                      data: data,
                      loadingStatus: typeof savedViews === "object" ? "loaded" : (
                          savedViews === "Initial" ? "loading" : "error"
                        )
                    })));
}

function InspectorIssuesSavedViews(Props) {
  var match = WorkspaceContext.use(undefined);
  var model = ModelStore.useModel(undefined);
  var inspectorStats = InspectorStats.useStatsWithCache(match.id);
  var hasSourcesWithoutInspector = Belt_List.some(model.sources, (function (source) {
          var match = InspectorStats.getSourceStatus(model, source, inspectorStats);
          return match === 2 || match === 1;
        }));
  if (typeof inspectorStats === "number") {
    if (inspectorStats < 2) {
      return null;
    }
    
  } else if (inspectorStats._0.length !== 0) {
    return React.createElement(InspectorIssuesSavedViews$Views, {
                hasSourcesWithoutInspector: hasSourcesWithoutInspector
              });
  }
  return React.createElement(InspectorEmptyState.make, {
              hasSourcesWithoutInspector: hasSourcesWithoutInspector,
              inspectorView: "savedViews"
            });
}

var make = InspectorIssuesSavedViews;

exports.make = make;
/* container Not a pure module */
