// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var $$String = require("rescript/lib/js/string.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Js_option = require("rescript/lib/js/js_option.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Css_AtomicTypes = require("./Css_AtomicTypes.bs.js");

function ruleToDict(dict, rule) {
  switch (rule.TAG | 0) {
    case /* D */0 :
        var value = rule._1;
        var name = rule._0;
        if (name === "content") {
          dict[name] = value === "" ? "\"\"" : value;
        } else {
          dict[name] = value;
        }
        break;
    case /* S */1 :
        dict[rule._0] = toJson(rule._1);
        break;
    case /* PseudoClass */2 :
        dict[":" + rule._0] = toJson(rule._1);
        break;
    case /* PseudoClassParam */3 :
        dict[":" + (rule._0 + ("(" + (rule._1 + ")")))] = toJson(rule._2);
        break;
    
  }
  return dict;
}

function toJson(rules) {
  return Belt_List.reduce(rules, {}, ruleToDict);
}

function addStop(dict, param) {
  dict[param[0].toString() + "%"] = toJson(param[1]);
  return dict;
}

function Make(CssImpl) {
  var insertRule = function (css) {
    return CssImpl.injectRaw(css);
  };
  var renderRule = function (renderer, css) {
    return CssImpl.renderRaw(renderer, css);
  };
  var $$global = function (selector, rules) {
    return CssImpl.injectRules(selector, toJson(rules));
  };
  var renderGlobal = function (renderer, selector, rules) {
    return CssImpl.renderRules(renderer, selector, toJson(rules));
  };
  var style = function (rules) {
    return CssImpl.make(toJson(rules));
  };
  var merge = function (styles) {
    return CssImpl.mergeStyles(Belt_List.toArray(styles));
  };
  var merge2 = function (s, s2) {
    return CssImpl.mergeStyles(Belt_List.toArray({
                    hd: s,
                    tl: {
                      hd: s2,
                      tl: /* [] */0
                    }
                  }));
  };
  var merge3 = function (s, s2, s3) {
    return CssImpl.mergeStyles(Belt_List.toArray({
                    hd: s,
                    tl: {
                      hd: s2,
                      tl: {
                        hd: s3,
                        tl: /* [] */0
                      }
                    }
                  }));
  };
  var merge4 = function (s, s2, s3, s4) {
    return CssImpl.mergeStyles(Belt_List.toArray({
                    hd: s,
                    tl: {
                      hd: s2,
                      tl: {
                        hd: s3,
                        tl: {
                          hd: s4,
                          tl: /* [] */0
                        }
                      }
                    }
                  }));
  };
  var keyframes = function (frames) {
    return CssImpl.makeKeyframes(List.fold_left(addStop, {}, frames));
  };
  var renderKeyframes = function (renderer, frames) {
    return CssImpl.renderKeyframes(renderer, List.fold_left(addStop, {}, frames));
  };
  return {
          insertRule: insertRule,
          renderRule: renderRule,
          $$global: $$global,
          renderGlobal: renderGlobal,
          style: style,
          merge: merge,
          merge2: merge2,
          merge3: merge3,
          merge4: merge4,
          keyframes: keyframes,
          renderKeyframes: renderKeyframes
        };
}

function $neg(a, b) {
  return {
          NAME: "substract",
          VAL: [
            a,
            b
          ]
        };
}

function $plus(a, b) {
  return {
          NAME: "add",
          VAL: [
            a,
            b
          ]
        };
}

function $star(a, b) {
  return {
          NAME: "mul",
          VAL: [
            a,
            b
          ]
        };
}

function $slash(a, b) {
  return {
          NAME: "div",
          VAL: [
            a,
            b
          ]
        };
}

var Calc = {
  $neg: $neg,
  $plus: $plus,
  $star: $star,
  $slash: $slash
};

function join(strings, separator) {
  var _strings = strings;
  var _acc = "";
  while(true) {
    var acc = _acc;
    var strings$1 = _strings;
    if (!strings$1) {
      return acc;
    }
    var xs = strings$1.tl;
    var x = strings$1.hd;
    if (!xs) {
      return acc + x;
    }
    _acc = acc + (x + separator);
    _strings = xs;
    continue ;
  };
}

function string_of_content(x) {
  if (typeof x !== "object") {
    if (x === "unset" || x === "inherit_" || x === "initial") {
      return Css_AtomicTypes.Cascading.toString(x);
    } else {
      return Css_AtomicTypes.Content.toString(x);
    }
  }
  var variant = x.NAME;
  if (variant === "linearGradient" || variant === "radialGradient" || variant === "repeatingLinearGradient" || variant === "repeatingRadialGradient" || variant === "conicGradient") {
    return Css_AtomicTypes.Gradient.toString(x);
  } else if (variant === "var" || variant === "varDefault") {
    return Css_AtomicTypes.Var.toString(x);
  } else if (variant === "url") {
    return Css_AtomicTypes.Url.toString(x);
  } else if (variant === "counters") {
    return Css_AtomicTypes.Counters.toString(x);
  } else if (variant === "counter") {
    return Css_AtomicTypes.Counter.toString(x);
  } else {
    return Css_AtomicTypes.Content.toString(x);
  }
}

function string_of_counter_increment(x) {
  if (typeof x === "object") {
    if (x.NAME === "increment") {
      return Css_AtomicTypes.CounterIncrement.toString(x);
    } else {
      return Css_AtomicTypes.Var.toString(x);
    }
  } else if (x === "unset" || x === "inherit_" || x === "initial") {
    return Css_AtomicTypes.Cascading.toString(x);
  } else {
    return Css_AtomicTypes.CounterIncrement.toString(x);
  }
}

function string_of_counter_reset(x) {
  if (typeof x !== "object") {
    if (x === "unset" || x === "inherit_" || x === "initial") {
      return Css_AtomicTypes.Cascading.toString(x);
    } else {
      return Css_AtomicTypes.CounterReset.toString(x);
    }
  }
  var variant = x.NAME;
  if (variant === "var" || variant === "varDefault") {
    return Css_AtomicTypes.Var.toString(x);
  } else {
    return Css_AtomicTypes.CounterReset.toString(x);
  }
}

function string_of_counter_set(x) {
  if (typeof x === "object") {
    if (x.NAME === "set") {
      return Css_AtomicTypes.CounterSet.toString(x);
    } else {
      return Css_AtomicTypes.Var.toString(x);
    }
  } else if (x === "unset" || x === "inherit_" || x === "initial") {
    return Css_AtomicTypes.Cascading.toString(x);
  } else {
    return Css_AtomicTypes.CounterSet.toString(x);
  }
}

function string_of_gap(x) {
  if (typeof x !== "object") {
    if (x === "zero") {
      return Css_AtomicTypes.PercentageLengthCalc.toString(x);
    } else if (x === "normal") {
      return Css_AtomicTypes.Gap.toString(x);
    } else {
      return Css_AtomicTypes.Cascading.toString(x);
    }
  }
  var variant = x.NAME;
  if (variant === "var" || variant === "varDefault") {
    return Css_AtomicTypes.Var.toString(x);
  } else {
    return Css_AtomicTypes.PercentageLengthCalc.toString(x);
  }
}

function string_of_position(x) {
  if (typeof x !== "object") {
    if (x === "unset" || x === "inherit_" || x === "initial") {
      return Css_AtomicTypes.Cascading.toString(x);
    } else if (x === "zero") {
      return Css_AtomicTypes.PercentageLengthCalc.toString(x);
    } else {
      return "auto";
    }
  }
  var variant = x.NAME;
  if (variant === "var" || variant === "varDefault") {
    return Css_AtomicTypes.Var.toString(x);
  } else {
    return Css_AtomicTypes.PercentageLengthCalc.toString(x);
  }
}

function string_of_color(x) {
  if (typeof x !== "object") {
    return Css_AtomicTypes.Color.toString(x);
  }
  var variant = x.NAME;
  if (variant === "var" || variant === "varDefault") {
    return Css_AtomicTypes.Var.toString(x);
  } else {
    return Css_AtomicTypes.Color.toString(x);
  }
}

function string_of_dasharray(x) {
  return Css_AtomicTypes.PercentageLengthCalc.toString(x);
}

function important(v) {
  if (v.TAG === /* D */0) {
    return {
            TAG: /* D */0,
            _0: v._0,
            _1: v._1 + " !important"
          };
  } else {
    return v;
  }
}

function label(label$1) {
  return {
          TAG: /* D */0,
          _0: "label",
          _1: label$1
        };
}

function alignContent(x) {
  return {
          TAG: /* D */0,
          _0: "alignContent",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "spaceBetween" || x === "stretch" || x === "spaceAround" || x === "spaceEvenly" ? Css_AtomicTypes.DistributedAlignment.toString(x) : (
                  x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
                      x === "baseline" || x === "firstBaseline" || x === "lastBaseline" ? Css_AtomicTypes.BaselineAlignment.toString(x) : (
                          x === "normal" ? Css_AtomicTypes.NormalAlignment.toString(x) : Css_AtomicTypes.AlignContent.toString(x)
                        )
                    )
                )
            )
        };
}

function alignItems(x) {
  return {
          TAG: /* D */0,
          _0: "alignItems",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
                  x === "baseline" || x === "firstBaseline" || x === "lastBaseline" ? Css_AtomicTypes.BaselineAlignment.toString(x) : (
                      x === "normal" || x === "stretch" ? Css_AtomicTypes.AlignItems.toString(x) : Css_AtomicTypes.PositionalAlignment.toString(x)
                    )
                )
            )
        };
}

function alignSelf(x) {
  return {
          TAG: /* D */0,
          _0: "alignSelf",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "selfStart" || x === "flexEnd" || x === "flexStart" || x === "center" || x === "start" || x === "selfEnd" || x === "right" || x === "left" || x === "end_" ? Css_AtomicTypes.PositionalAlignment.toString(x) : (
                  x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
                      x === "baseline" || x === "firstBaseline" || x === "lastBaseline" ? Css_AtomicTypes.BaselineAlignment.toString(x) : Css_AtomicTypes.AlignSelf.toString(x)
                    )
                )
            )
        };
}

function animationDelay(x) {
  return {
          TAG: /* D */0,
          _0: "animationDelay",
          _1: Css_AtomicTypes.Time.toString(x)
        };
}

function animationDirection(x) {
  return {
          TAG: /* D */0,
          _0: "animationDirection",
          _1: Css_AtomicTypes.AnimationDirection.toString(x)
        };
}

function animationDuration(x) {
  return {
          TAG: /* D */0,
          _0: "animationDuration",
          _1: Css_AtomicTypes.Time.toString(x)
        };
}

function animationFillMode(x) {
  return {
          TAG: /* D */0,
          _0: "animationFillMode",
          _1: Css_AtomicTypes.AnimationFillMode.toString(x)
        };
}

function animationIterationCount(x) {
  return {
          TAG: /* D */0,
          _0: "animationIterationCount",
          _1: Css_AtomicTypes.AnimationIterationCount.toString(x)
        };
}

function animationPlayState(x) {
  return {
          TAG: /* D */0,
          _0: "animationPlayState",
          _1: Css_AtomicTypes.AnimationPlayState.toString(x)
        };
}

function animationTimingFunction(x) {
  return {
          TAG: /* D */0,
          _0: "animationTimingFunction",
          _1: Css_AtomicTypes.TimingFunction.toString(x)
        };
}

function backfaceVisibility(x) {
  return {
          TAG: /* D */0,
          _0: "backfaceVisibility",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "visible" || x === "hidden" ? Css_AtomicTypes.BackfaceVisibility.toString(x) : Css_AtomicTypes.Cascading.toString(x)
            )
        };
}

function backdropFilter(x) {
  return {
          TAG: /* D */0,
          _0: "backdropFilter",
          _1: join(Belt_List.map(x, Css_AtomicTypes.BackdropFilter.toString), ", ")
        };
}

function backgroundAttachment(x) {
  return {
          TAG: /* D */0,
          _0: "backgroundAttachment",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BackgroundAttachment.toString(x)
            )
        };
}

function backgroundColor(x) {
  return {
          TAG: /* D */0,
          _0: "backgroundColor",
          _1: string_of_color(x)
        };
}

function backgroundClip(x) {
  return {
          TAG: /* D */0,
          _0: "backgroundClip",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BackgroundClip.toString(x)
            )
        };
}

function backgroundImage(x) {
  return {
          TAG: /* D */0,
          _0: "backgroundImage",
          _1: typeof x === "object" ? (
              x.NAME === "url" ? Css_AtomicTypes.Url.toString(x) : Css_AtomicTypes.Gradient.toString(x)
            ) : Css_AtomicTypes.BackgroundImage.toString(x)
        };
}

function maskImage(x) {
  return {
          TAG: /* D */0,
          _0: "maskImage",
          _1: typeof x === "object" ? (
              x.NAME === "url" ? Css_AtomicTypes.Url.toString(x) : Css_AtomicTypes.Gradient.toString(x)
            ) : Css_AtomicTypes.MaskImage.toString(x)
        };
}

function backgroundOrigin(x) {
  return {
          TAG: /* D */0,
          _0: "backgroundOrigin",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BackgroundOrigin.toString(x)
            )
        };
}

function string_of_backgroundposition(x) {
  if (typeof x !== "object") {
    if (x === "unset" || x === "inherit_" || x === "initial") {
      return Css_AtomicTypes.Cascading.toString(x);
    } else if (x === "zero") {
      return Css_AtomicTypes.Length.toString(x);
    } else {
      return Css_AtomicTypes.BackgroundPosition.toString(x);
    }
  }
  var variant = x.NAME;
  if (variant === "var" || variant === "varDefault") {
    return Css_AtomicTypes.Var.toString(x);
  }
  if (variant !== "hv") {
    return Css_AtomicTypes.Length.toString(x);
  }
  var match = x.VAL;
  var v = match[1];
  var h = match[0];
  return (
          typeof h === "object" || h === "zero" ? Css_AtomicTypes.Length.toString(h) : Curry._1(Css_AtomicTypes.BackgroundPosition.X.toString, h)
        ) + (" " + (
            typeof v === "object" || !(v === "center" || v === "top" || v === "bottom") ? Css_AtomicTypes.Length.toString(v) : Curry._1(Css_AtomicTypes.BackgroundPosition.Y.toString, v)
          ));
}

function backgroundPosition(x) {
  return {
          TAG: /* D */0,
          _0: "backgroundPosition",
          _1: string_of_backgroundposition(x)
        };
}

function backgroundPositions(bp) {
  return {
          TAG: /* D */0,
          _0: "backgroundPosition",
          _1: join(Belt_List.map(bp, string_of_backgroundposition), ", ")
        };
}

function backgroundPosition4(x, offsetX, y, offsetY) {
  return {
          TAG: /* D */0,
          _0: "backgroundPosition",
          _1: Curry._1(Css_AtomicTypes.BackgroundPosition.X.toString, x) + (" " + (Css_AtomicTypes.Length.toString(offsetX) + (" " + (Curry._1(Css_AtomicTypes.BackgroundPosition.Y.toString, y) + (" " + Css_AtomicTypes.Length.toString(offsetY))))))
        };
}

function backgroundRepeat(x) {
  var tmp;
  if (typeof x === "object") {
    if (x.NAME === "hv") {
      var match = x.VAL;
      tmp = Css_AtomicTypes.BackgroundRepeat.toString(match[0]) + (" " + Css_AtomicTypes.BackgroundRepeat.toString(match[1]));
    } else {
      tmp = Css_AtomicTypes.Var.toString(x);
    }
  } else {
    tmp = x === "repeatY" || x === "repeatX" || x === "repeat" || x === "space" || x === "round" || x === "noRepeat" ? Css_AtomicTypes.BackgroundRepeat.toString(x) : Css_AtomicTypes.Cascading.toString(x);
  }
  return {
          TAG: /* D */0,
          _0: "backgroundRepeat",
          _1: tmp
        };
}

function string_of_maskposition(x) {
  if (typeof x !== "object") {
    if (x === "unset" || x === "inherit_" || x === "initial") {
      return Css_AtomicTypes.Cascading.toString(x);
    } else if (x === "zero") {
      return Css_AtomicTypes.Length.toString(x);
    } else {
      return Css_AtomicTypes.MaskPosition.toString(x);
    }
  }
  var variant = x.NAME;
  if (variant === "var" || variant === "varDefault") {
    return Css_AtomicTypes.Var.toString(x);
  }
  if (variant !== "hv") {
    return Css_AtomicTypes.Length.toString(x);
  }
  var match = x.VAL;
  var v = match[1];
  var h = match[0];
  return (
          typeof h === "object" || h === "zero" ? Css_AtomicTypes.Length.toString(h) : Curry._1(Css_AtomicTypes.MaskPosition.X.toString, h)
        ) + (" " + (
            typeof v === "object" || !(v === "center" || v === "top" || v === "bottom") ? Css_AtomicTypes.Length.toString(v) : Curry._1(Css_AtomicTypes.MaskPosition.Y.toString, v)
          ));
}

function maskPosition(x) {
  return {
          TAG: /* D */0,
          _0: "maskPosition",
          _1: string_of_maskposition(x)
        };
}

function maskPositions(mp) {
  return {
          TAG: /* D */0,
          _0: "maskPosition",
          _1: join(Belt_List.map(mp, string_of_maskposition), ", ")
        };
}

function borderBottomColor(x) {
  return {
          TAG: /* D */0,
          _0: "borderBottomColor",
          _1: string_of_color(x)
        };
}

function borderBottomLeftRadius(x) {
  return {
          TAG: /* D */0,
          _0: "borderBottomLeftRadius",
          _1: Css_AtomicTypes.Length.toString(x)
        };
}

function borderBottomRightRadius(x) {
  return {
          TAG: /* D */0,
          _0: "borderBottomRightRadius",
          _1: Css_AtomicTypes.Length.toString(x)
        };
}

function borderBottomWidth(x) {
  return {
          TAG: /* D */0,
          _0: "borderBottomWidth",
          _1: Css_AtomicTypes.Length.toString(x)
        };
}

function borderCollapse(x) {
  return {
          TAG: /* D */0,
          _0: "borderCollapse",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BorderCollapse.toString(x)
            )
        };
}

function borderColor(x) {
  return {
          TAG: /* D */0,
          _0: "borderColor",
          _1: string_of_color(x)
        };
}

function borderLeftColor(x) {
  return {
          TAG: /* D */0,
          _0: "borderLeftColor",
          _1: string_of_color(x)
        };
}

function borderLeftWidth(x) {
  return {
          TAG: /* D */0,
          _0: "borderLeftWidth",
          _1: Css_AtomicTypes.Length.toString(x)
        };
}

function borderSpacing(x) {
  return {
          TAG: /* D */0,
          _0: "borderSpacing",
          _1: Css_AtomicTypes.Length.toString(x)
        };
}

function borderRadius(x) {
  return {
          TAG: /* D */0,
          _0: "borderRadius",
          _1: Css_AtomicTypes.Length.toString(x)
        };
}

function borderRightColor(x) {
  return {
          TAG: /* D */0,
          _0: "borderRightColor",
          _1: string_of_color(x)
        };
}

function borderRightWidth(x) {
  return {
          TAG: /* D */0,
          _0: "borderRightWidth",
          _1: Css_AtomicTypes.Length.toString(x)
        };
}

function borderTopColor(x) {
  return {
          TAG: /* D */0,
          _0: "borderTopColor",
          _1: string_of_color(x)
        };
}

function borderTopLeftRadius(x) {
  return {
          TAG: /* D */0,
          _0: "borderTopLeftRadius",
          _1: Css_AtomicTypes.Length.toString(x)
        };
}

function borderTopRightRadius(x) {
  return {
          TAG: /* D */0,
          _0: "borderTopRightRadius",
          _1: Css_AtomicTypes.Length.toString(x)
        };
}

function borderTopWidth(x) {
  return {
          TAG: /* D */0,
          _0: "borderTopWidth",
          _1: Css_AtomicTypes.Length.toString(x)
        };
}

function borderWidth(x) {
  return {
          TAG: /* D */0,
          _0: "borderWidth",
          _1: Css_AtomicTypes.Length.toString(x)
        };
}

function bottom(x) {
  return {
          TAG: /* D */0,
          _0: "bottom",
          _1: string_of_position(x)
        };
}

function boxSizing(x) {
  return {
          TAG: /* D */0,
          _0: "boxSizing",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.BoxSizing.toString(x)
            )
        };
}

function clear(x) {
  return {
          TAG: /* D */0,
          _0: "clear",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Clear.toString(x)
            )
        };
}

function clipPath(x) {
  return {
          TAG: /* D */0,
          _0: "clipPath",
          _1: typeof x === "object" ? (
              x.NAME === "url" ? Css_AtomicTypes.Url.toString(x) : Css_AtomicTypes.Var.toString(x)
            ) : (
              x === "none" ? Css_AtomicTypes.ClipPath.toString(x) : (
                  x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.GeometryBox.toString(x)
                )
            )
        };
}

function color(x) {
  return {
          TAG: /* D */0,
          _0: "color",
          _1: string_of_color(x)
        };
}

function columnCount(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.ColumnCount.toString(x);
  } else {
    tmp = x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.ColumnCount.toString(x);
  }
  return {
          TAG: /* D */0,
          _0: "columnCount",
          _1: tmp
        };
}

function contentRule(x) {
  return {
          TAG: /* D */0,
          _0: "content",
          _1: string_of_content(x)
        };
}

function contentRules(xs) {
  return {
          TAG: /* D */0,
          _0: "content",
          _1: join(Belt_List.map(xs, string_of_content), " ")
        };
}

function counterIncrement(x) {
  return {
          TAG: /* D */0,
          _0: "counterIncrement",
          _1: string_of_counter_increment(x)
        };
}

function countersIncrement(xs) {
  return {
          TAG: /* D */0,
          _0: "counterIncrement",
          _1: join(Belt_List.map(xs, string_of_counter_increment), " ")
        };
}

function counterReset(x) {
  return {
          TAG: /* D */0,
          _0: "counterReset",
          _1: string_of_counter_reset(x)
        };
}

function countersReset(xs) {
  return {
          TAG: /* D */0,
          _0: "counterReset",
          _1: join(Belt_List.map(xs, string_of_counter_reset), " ")
        };
}

function counterSet(x) {
  return {
          TAG: /* D */0,
          _0: "counterSet",
          _1: string_of_counter_set(x)
        };
}

function countersSet(xs) {
  return {
          TAG: /* D */0,
          _0: "counterSet",
          _1: join(Belt_List.map(xs, string_of_counter_set), " ")
        };
}

function cursor(x) {
  return {
          TAG: /* D */0,
          _0: "cursor",
          _1: Css_AtomicTypes.Cursor.toString(x)
        };
}

function direction(x) {
  return {
          TAG: /* D */0,
          _0: "direction",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "rtl" || x === "ltr" ? Css_AtomicTypes.Direction.toString(x) : Css_AtomicTypes.Cascading.toString(x)
            )
        };
}

function display(x) {
  return {
          TAG: /* D */0,
          _0: "display",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "table" || x === "grid" || x === "flex" ? Css_AtomicTypes.DisplayInside.toString(x) : (
                  x === "contents" || x === "none" ? Css_AtomicTypes.DisplayBox.toString(x) : (
                      x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
                          x === "inlineGrid" || x === "inlineFlex" || x === "inlineBlock" || x === "inlineTable" ? Css_AtomicTypes.DisplayLegacy.toString(x) : (
                              x === "block" || x === "inline" || x === "runIn" ? Css_AtomicTypes.DisplayOutside.toString(x) : (
                                  x === "listItem" ? Css_AtomicTypes.DisplayListItem.toString(x) : Css_AtomicTypes.DisplayInternal.toString(x)
                                )
                            )
                        )
                    )
                )
            )
        };
}

function flex(x) {
  return {
          TAG: /* D */0,
          _0: "flex",
          _1: typeof x === "object" ? x.VAL.toString() : Css_AtomicTypes.Flex.toString(x)
        };
}

function flexDirection(x) {
  return {
          TAG: /* D */0,
          _0: "flexDirection",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.FlexDirection.toString(x)
            )
        };
}

function flexGrow(x) {
  return {
          TAG: /* D */0,
          _0: "flexGrow",
          _1: x.toString()
        };
}

function flexShrink(x) {
  return {
          TAG: /* D */0,
          _0: "flexShrink",
          _1: x.toString()
        };
}

function flexWrap(x) {
  return {
          TAG: /* D */0,
          _0: "flexWrap",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "nowrap" || x === "wrapReverse" || x === "wrap" ? Css_AtomicTypes.FlexWrap.toString(x) : Css_AtomicTypes.Cascading.toString(x)
            )
        };
}

function $$float(x) {
  return {
          TAG: /* D */0,
          _0: "float",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Float.toString(x)
            )
        };
}

function fontFamily(x) {
  return {
          TAG: /* D */0,
          _0: "fontFamily",
          _1: typeof x === "object" ? (
              x.NAME === "custom" ? Css_AtomicTypes.FontFamilyName.toString(x) : Css_AtomicTypes.Var.toString(x)
            ) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.FontFamilyName.toString(x)
            )
        };
}

function fontFamilies(xs) {
  return {
          TAG: /* D */0,
          _0: "fontFamily",
          _1: join(Belt_List.map(xs, Css_AtomicTypes.FontFamilyName.toString), ", ")
        };
}

function fontSize(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.Length.toString(x);
  } else {
    tmp = x === "zero" ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.Cascading.toString(x);
  }
  return {
          TAG: /* D */0,
          _0: "fontSize",
          _1: tmp
        };
}

function fontStyle(x) {
  return {
          TAG: /* D */0,
          _0: "fontStyle",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "normal" || x === "oblique" || x === "italic" ? Css_AtomicTypes.FontStyle.toString(x) : Css_AtomicTypes.Cascading.toString(x)
            )
        };
}

function fontVariant(x) {
  return {
          TAG: /* D */0,
          _0: "fontVariant",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "normal" || x === "smallCaps" ? Css_AtomicTypes.FontVariant.toString(x) : Css_AtomicTypes.Cascading.toString(x)
            )
        };
}

function fontWeight(x) {
  return {
          TAG: /* D */0,
          _0: "fontWeight",
          _1: typeof x === "object" ? (
              x.NAME === "num" ? Css_AtomicTypes.FontWeight.toString(x) : Css_AtomicTypes.Var.toString(x)
            ) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.FontWeight.toString(x)
            )
        };
}

function gridAutoFlow(x) {
  return {
          TAG: /* D */0,
          _0: "gridAutoFlow",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.GridAutoFlow.toString(x)
            )
        };
}

function gridColumn(start, end$p) {
  return {
          TAG: /* D */0,
          _0: "gridColumn",
          _1: start.toString() + (" / " + end$p.toString())
        };
}

function gridColumnStart(n) {
  return {
          TAG: /* D */0,
          _0: "gridColumnStart",
          _1: n.toString()
        };
}

function gridColumnEnd(n) {
  return {
          TAG: /* D */0,
          _0: "gridColumnEnd",
          _1: n.toString()
        };
}

function gridRow(start, end$p) {
  return {
          TAG: /* D */0,
          _0: "gridRow",
          _1: start.toString() + (" / " + end$p.toString())
        };
}

function gap(x) {
  return {
          TAG: /* D */0,
          _0: "gap",
          _1: string_of_gap(x)
        };
}

function columnGap(x) {
  return {
          TAG: /* D */0,
          _0: "columnGap",
          _1: string_of_gap(x)
        };
}

function rowGap(x) {
  return {
          TAG: /* D */0,
          _0: "rowGap",
          _1: string_of_gap(x)
        };
}

function gridGap(x) {
  return {
          TAG: /* D */0,
          _0: "gridGap",
          _1: string_of_gap(x)
        };
}

function gridColumnGap(x) {
  return {
          TAG: /* D */0,
          _0: "gridColumnGap",
          _1: string_of_gap(x)
        };
}

function gridRowGap(x) {
  return {
          TAG: /* D */0,
          _0: "gridRowGap",
          _1: string_of_gap(x)
        };
}

function gap2(rowGap, columnGap) {
  return {
          TAG: /* D */0,
          _0: "gap",
          _1: string_of_gap(rowGap) + (" " + string_of_gap(columnGap))
        };
}

function gridRowEnd(n) {
  return {
          TAG: /* D */0,
          _0: "gridRowEnd",
          _1: n.toString()
        };
}

function gridRowStart(n) {
  return {
          TAG: /* D */0,
          _0: "gridRowStart",
          _1: n.toString()
        };
}

function height(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.PercentageLengthCalc.toString(x);
  } else {
    tmp = x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
        x === "zero" ? Css_AtomicTypes.PercentageLengthCalc.toString(x) : Css_AtomicTypes.Height.toString(x)
      );
  }
  return {
          TAG: /* D */0,
          _0: "height",
          _1: tmp
        };
}

function justifyContent(x) {
  return {
          TAG: /* D */0,
          _0: "justifyContent",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "spaceBetween" || x === "stretch" || x === "spaceAround" || x === "spaceEvenly" ? Css_AtomicTypes.DistributedAlignment.toString(x) : (
                  x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
                      x === "normal" ? Css_AtomicTypes.NormalAlignment.toString(x) : Css_AtomicTypes.PositionalAlignment.toString(x)
                    )
                )
            )
        };
}

function justifyItems(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.OverflowAlignment.toString(x);
  } else {
    tmp = x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
        x === "legacyRight" || x === "legacy" || x === "legacyLeft" || x === "legacyCenter" ? Css_AtomicTypes.LegacyAlignment.toString(x) : (
            x === "baseline" || x === "firstBaseline" || x === "lastBaseline" ? Css_AtomicTypes.BaselineAlignment.toString(x) : (
                x === "normal" ? Css_AtomicTypes.NormalAlignment.toString(x) : Css_AtomicTypes.PositionalAlignment.toString(x)
              )
          )
      );
  }
  return {
          TAG: /* D */0,
          _0: "justifyItems",
          _1: tmp
        };
}

function left(x) {
  return {
          TAG: /* D */0,
          _0: "left",
          _1: string_of_position(x)
        };
}

function letterSpacing(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.Length.toString(x);
  } else {
    tmp = x === "zero" ? Css_AtomicTypes.Length.toString(x) : (
        x === "normal" ? Css_AtomicTypes.LetterSpacing.toString(x) : Css_AtomicTypes.Cascading.toString(x)
      );
  }
  return {
          TAG: /* D */0,
          _0: "letterSpacing",
          _1: tmp
        };
}

function lineHeight(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : (
        variant === "abs" ? Css_AtomicTypes.LineHeight.toString(x) : Css_AtomicTypes.PercentageLengthCalc.toString(x)
      );
  } else {
    tmp = x === "zero" ? Css_AtomicTypes.PercentageLengthCalc.toString(x) : (
        x === "normal" ? Css_AtomicTypes.LineHeight.toString(x) : Css_AtomicTypes.Cascading.toString(x)
      );
  }
  return {
          TAG: /* D */0,
          _0: "lineHeight",
          _1: tmp
        };
}

function listStyle(style, position, image) {
  return {
          TAG: /* D */0,
          _0: "listStyle",
          _1: Css_AtomicTypes.ListStyleType.toString(style) + (" " + (Css_AtomicTypes.ListStylePosition.toString(position) + (" " + (
                  typeof image === "object" ? Css_AtomicTypes.Url.toString(image) : Css_AtomicTypes.ListStyleImage.toString(image)
                ))))
        };
}

function listStyleImage(x) {
  return {
          TAG: /* D */0,
          _0: "listStyleImage",
          _1: typeof x === "object" ? (
              x.NAME === "url" ? Css_AtomicTypes.Url.toString(x) : Css_AtomicTypes.Var.toString(x)
            ) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.ListStyleImage.toString(x)
            )
        };
}

function listStyleType(x) {
  return {
          TAG: /* D */0,
          _0: "listStyleType",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.ListStyleType.toString(x)
            )
        };
}

function listStylePosition(x) {
  return {
          TAG: /* D */0,
          _0: "listStylePosition",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "inside" || x === "outside" ? Css_AtomicTypes.ListStylePosition.toString(x) : Css_AtomicTypes.Cascading.toString(x)
            )
        };
}

function string_of_margin(x) {
  if (typeof x !== "object") {
    if (x === "unset" || x === "inherit_" || x === "initial") {
      return Css_AtomicTypes.Cascading.toString(x);
    } else if (x === "zero") {
      return Css_AtomicTypes.PercentageLengthCalc.toString(x);
    } else {
      return Css_AtomicTypes.Margin.toString(x);
    }
  }
  var variant = x.NAME;
  if (variant === "var" || variant === "varDefault") {
    return Css_AtomicTypes.Var.toString(x);
  } else {
    return Css_AtomicTypes.PercentageLengthCalc.toString(x);
  }
}

function margin(x) {
  return {
          TAG: /* D */0,
          _0: "margin",
          _1: string_of_margin(x)
        };
}

function margin2(v, h) {
  return {
          TAG: /* D */0,
          _0: "margin",
          _1: string_of_margin(v) + (" " + string_of_margin(h))
        };
}

function margin3(top, h, bottom) {
  return {
          TAG: /* D */0,
          _0: "margin",
          _1: string_of_margin(top) + (" " + (string_of_margin(h) + (" " + string_of_margin(bottom))))
        };
}

function margin4(top, right, bottom, left) {
  return {
          TAG: /* D */0,
          _0: "margin",
          _1: string_of_margin(top) + (" " + (string_of_margin(right) + (" " + (string_of_margin(bottom) + (" " + string_of_margin(left))))))
        };
}

function marginLeft(x) {
  return {
          TAG: /* D */0,
          _0: "marginLeft",
          _1: string_of_margin(x)
        };
}

function marginRight(x) {
  return {
          TAG: /* D */0,
          _0: "marginRight",
          _1: string_of_margin(x)
        };
}

function marginTop(x) {
  return {
          TAG: /* D */0,
          _0: "marginTop",
          _1: string_of_margin(x)
        };
}

function marginBottom(x) {
  return {
          TAG: /* D */0,
          _0: "marginBottom",
          _1: string_of_margin(x)
        };
}

function maxHeight(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.PercentageLengthCalc.toString(x);
  } else {
    tmp = x === "none" ? Css_AtomicTypes.MaxHeight.toString(x) : (
        x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
            x === "zero" ? Css_AtomicTypes.PercentageLengthCalc.toString(x) : Css_AtomicTypes.Height.toString(x)
          )
      );
  }
  return {
          TAG: /* D */0,
          _0: "maxHeight",
          _1: tmp
        };
}

function maxWidth(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.PercentageLengthCalc.toString(x);
  } else {
    tmp = x === "none" ? Css_AtomicTypes.MaxWidth.toString(x) : (
        x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
            x === "zero" ? Css_AtomicTypes.PercentageLengthCalc.toString(x) : Css_AtomicTypes.Width.toString(x)
          )
      );
  }
  return {
          TAG: /* D */0,
          _0: "maxWidth",
          _1: tmp
        };
}

function minHeight(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.PercentageLengthCalc.toString(x);
  } else {
    tmp = x === "none" ? Css_AtomicTypes.MinHeight.toString(x) : (
        x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
            x === "zero" ? Css_AtomicTypes.PercentageLengthCalc.toString(x) : Css_AtomicTypes.Height.toString(x)
          )
      );
  }
  return {
          TAG: /* D */0,
          _0: "minHeight",
          _1: tmp
        };
}

function minWidth(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.PercentageLengthCalc.toString(x);
  } else {
    tmp = x === "none" ? Css_AtomicTypes.MinWidth.toString(x) : (
        x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
            x === "zero" ? Css_AtomicTypes.PercentageLengthCalc.toString(x) : Css_AtomicTypes.Width.toString(x)
          )
      );
  }
  return {
          TAG: /* D */0,
          _0: "minWidth",
          _1: tmp
        };
}

function objectFit(x) {
  return {
          TAG: /* D */0,
          _0: "objectFit",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.ObjectFit.toString(x)
            )
        };
}

function objectPosition(x) {
  return {
          TAG: /* D */0,
          _0: "objectPosition",
          _1: string_of_backgroundposition(x)
        };
}

function opacity(x) {
  return {
          TAG: /* D */0,
          _0: "opacity",
          _1: x.toString()
        };
}

function outline(size, style, color) {
  return {
          TAG: /* D */0,
          _0: "outline",
          _1: Css_AtomicTypes.Length.toString(size) + (" " + (Css_AtomicTypes.OutlineStyle.toString(style) + (" " + string_of_color(color))))
        };
}

function outlineColor(x) {
  return {
          TAG: /* D */0,
          _0: "outlineColor",
          _1: string_of_color(x)
        };
}

function outlineOffset(x) {
  return {
          TAG: /* D */0,
          _0: "outlineOffset",
          _1: Css_AtomicTypes.Length.toString(x)
        };
}

function outlineStyle(x) {
  return {
          TAG: /* D */0,
          _0: "outlineStyle",
          _1: Css_AtomicTypes.OutlineStyle.toString(x)
        };
}

function outlineWidth(x) {
  return {
          TAG: /* D */0,
          _0: "outlineWidth",
          _1: Css_AtomicTypes.Length.toString(x)
        };
}

function overflow(x) {
  return {
          TAG: /* D */0,
          _0: "overflow",
          _1: Css_AtomicTypes.Overflow.toString(x)
        };
}

function overflowX(x) {
  return {
          TAG: /* D */0,
          _0: "overflowX",
          _1: Css_AtomicTypes.Overflow.toString(x)
        };
}

function overflowY(x) {
  return {
          TAG: /* D */0,
          _0: "overflowY",
          _1: Css_AtomicTypes.Overflow.toString(x)
        };
}

function overflowWrap(x) {
  return {
          TAG: /* D */0,
          _0: "overflowWrap",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "breakWord" || x === "anywhere" || x === "normal" ? Css_AtomicTypes.OverflowWrap.toString(x) : Css_AtomicTypes.Cascading.toString(x)
            )
        };
}

function padding(x) {
  return {
          TAG: /* D */0,
          _0: "padding",
          _1: Css_AtomicTypes.PercentageLengthCalc.toString(x)
        };
}

function padding2(v, h) {
  return {
          TAG: /* D */0,
          _0: "padding",
          _1: Css_AtomicTypes.PercentageLengthCalc.toString(v) + (" " + Css_AtomicTypes.PercentageLengthCalc.toString(h))
        };
}

function padding3(top, h, bottom) {
  return {
          TAG: /* D */0,
          _0: "padding",
          _1: Css_AtomicTypes.PercentageLengthCalc.toString(top) + (" " + (Css_AtomicTypes.PercentageLengthCalc.toString(h) + (" " + Css_AtomicTypes.PercentageLengthCalc.toString(bottom))))
        };
}

function padding4(top, right, bottom, left) {
  return {
          TAG: /* D */0,
          _0: "padding",
          _1: Css_AtomicTypes.PercentageLengthCalc.toString(top) + (" " + (Css_AtomicTypes.PercentageLengthCalc.toString(right) + (" " + (Css_AtomicTypes.PercentageLengthCalc.toString(bottom) + (" " + Css_AtomicTypes.PercentageLengthCalc.toString(left))))))
        };
}

function paddingBottom(x) {
  return {
          TAG: /* D */0,
          _0: "paddingBottom",
          _1: Css_AtomicTypes.PercentageLengthCalc.toString(x)
        };
}

function paddingLeft(x) {
  return {
          TAG: /* D */0,
          _0: "paddingLeft",
          _1: Css_AtomicTypes.PercentageLengthCalc.toString(x)
        };
}

function paddingRight(x) {
  return {
          TAG: /* D */0,
          _0: "paddingRight",
          _1: Css_AtomicTypes.PercentageLengthCalc.toString(x)
        };
}

function paddingTop(x) {
  return {
          TAG: /* D */0,
          _0: "paddingTop",
          _1: Css_AtomicTypes.PercentageLengthCalc.toString(x)
        };
}

function perspective(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.Length.toString(x);
  } else {
    tmp = x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
        x === "zero" ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.Perspective.toString(x)
      );
  }
  return {
          TAG: /* D */0,
          _0: "perspective",
          _1: tmp
        };
}

function perspectiveOrigin(x, y) {
  return {
          TAG: /* D */0,
          _0: "perspectiveOrigin",
          _1: (
            typeof x === "object" || x === "zero" ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.Perspective.toString(x)
          ) + (" " + (
              typeof y === "object" || y === "zero" ? Css_AtomicTypes.Length.toString(y) : Css_AtomicTypes.Perspective.toString(y)
            ))
        };
}

function pointerEvents(x) {
  return {
          TAG: /* D */0,
          _0: "pointerEvents",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.PointerEvents.toString(x)
            )
        };
}

function position(x) {
  return {
          TAG: /* D */0,
          _0: "position",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Position.toString(x)
            )
        };
}

function isolation(x) {
  return {
          TAG: /* D */0,
          _0: "isolation",
          _1: x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Isolation.toString(x)
        };
}

function justifySelf(x) {
  return {
          TAG: /* D */0,
          _0: "justifySelf",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "selfStart" || x === "flexEnd" || x === "flexStart" || x === "center" || x === "start" || x === "selfEnd" || x === "right" || x === "left" || x === "end_" ? Css_AtomicTypes.PositionalAlignment.toString(x) : (
                  x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
                      x === "baseline" || x === "firstBaseline" || x === "lastBaseline" ? Css_AtomicTypes.BaselineAlignment.toString(x) : Css_AtomicTypes.JustifySelf.toString(x)
                    )
                )
            )
        };
}

function resize(x) {
  return {
          TAG: /* D */0,
          _0: "resize",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Resize.toString(x)
            )
        };
}

function right(x) {
  return {
          TAG: /* D */0,
          _0: "right",
          _1: string_of_position(x)
        };
}

function tableLayout(x) {
  return {
          TAG: /* D */0,
          _0: "tableLayout",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.TableLayout.toString(x)
            )
        };
}

function textAlign(x) {
  return {
          TAG: /* D */0,
          _0: "textAlign",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.TextAlign.toString(x)
            )
        };
}

function textDecorationColor(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.Color.toString(x);
  } else {
    tmp = x === "transparent" || x === "currentColor" ? Css_AtomicTypes.Color.toString(x) : Css_AtomicTypes.Cascading.toString(x);
  }
  return {
          TAG: /* D */0,
          _0: "textDecorationColor",
          _1: tmp
        };
}

function textDecorationLine(x) {
  return {
          TAG: /* D */0,
          _0: "textDecorationLine",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.TextDecorationLine.toString(x)
            )
        };
}

function textDecorationStyle(x) {
  return {
          TAG: /* D */0,
          _0: "textDecorationStyle",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "double" || x === "dotted" || x === "dashed" || x === "solid" || x === "wavy" ? Css_AtomicTypes.TextDecorationStyle.toString(x) : Css_AtomicTypes.Cascading.toString(x)
            )
        };
}

function textIndent(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.PercentageLengthCalc.toString(x);
  } else {
    tmp = x === "zero" ? Css_AtomicTypes.PercentageLengthCalc.toString(x) : Css_AtomicTypes.Cascading.toString(x);
  }
  return {
          TAG: /* D */0,
          _0: "textIndent",
          _1: tmp
        };
}

function textOverflow(x) {
  return {
          TAG: /* D */0,
          _0: "textOverflow",
          _1: typeof x === "object" ? (
              x.NAME === "string" ? Css_AtomicTypes.TextOverflow.toString(x) : Css_AtomicTypes.Var.toString(x)
            ) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.TextOverflow.toString(x)
            )
        };
}

function textTransform(x) {
  return {
          TAG: /* D */0,
          _0: "textTransform",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.TextTransform.toString(x)
            )
        };
}

function top(x) {
  return {
          TAG: /* D */0,
          _0: "top",
          _1: string_of_position(x)
        };
}

function transform(x) {
  return {
          TAG: /* D */0,
          _0: "transform",
          _1: typeof x === "object" ? Css_AtomicTypes.Transform.toString(x) : "none"
        };
}

function transforms(x) {
  return {
          TAG: /* D */0,
          _0: "transform",
          _1: join(Belt_List.map(x, Css_AtomicTypes.Transform.toString), " ")
        };
}

function transformOrigin(x, y) {
  return {
          TAG: /* D */0,
          _0: "transformOrigin",
          _1: Css_AtomicTypes.Length.toString(x) + (" " + Css_AtomicTypes.Length.toString(y))
        };
}

function transformOrigin3d(x, y, z) {
  return {
          TAG: /* D */0,
          _0: "transformOrigin",
          _1: Css_AtomicTypes.Length.toString(x) + (" " + (Css_AtomicTypes.Length.toString(y) + (" " + (Css_AtomicTypes.Length.toString(z) + " "))))
        };
}

function unsafe(property, value) {
  return {
          TAG: /* D */0,
          _0: property,
          _1: value
        };
}

function userSelect(x) {
  return {
          TAG: /* D */0,
          _0: "userSelect",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.UserSelect.toString(x)
            )
        };
}

function verticalAlign(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.Length.toString(x);
  } else {
    tmp = x === "textTop" || x === "textBottom" || x === "baseline" || x === "super" || x === "top" || x === "sub" || x === "bottom" || x === "middle" ? Css_AtomicTypes.VerticalAlign.toString(x) : (
        x === "zero" ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.Cascading.toString(x)
      );
  }
  return {
          TAG: /* D */0,
          _0: "verticalAlign",
          _1: tmp
        };
}

function visibility(x) {
  return {
          TAG: /* D */0,
          _0: "visibility",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.Visibility.toString(x)
            )
        };
}

function scrollBehavior(x) {
  return {
          TAG: /* D */0,
          _0: "scrollBehavior",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.ScrollBehavior.toString(x)
            )
        };
}

function overscrollBehavior(x) {
  return {
          TAG: /* D */0,
          _0: "overscrollBehavior",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.OverscrollBehavior.toString(x)
            )
        };
}

function overflowAnchor(x) {
  return {
          TAG: /* D */0,
          _0: "overflowAnchor",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.OverflowAnchor.toString(x)
            )
        };
}

function columnWidth(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.Length.toString(x);
  } else {
    tmp = x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
        x === "zero" ? Css_AtomicTypes.Length.toString(x) : Css_AtomicTypes.ColumnWidth.toString(x)
      );
  }
  return {
          TAG: /* D */0,
          _0: "columnWidth",
          _1: tmp
        };
}

function caretColor(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.Color.toString(x);
  } else {
    tmp = x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
        x === "transparent" || x === "currentColor" ? Css_AtomicTypes.Color.toString(x) : Css_AtomicTypes.CaretColor.toString(x)
      );
  }
  return {
          TAG: /* D */0,
          _0: "caretColor",
          _1: tmp
        };
}

function width(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.PercentageLengthCalc.toString(x);
  } else {
    tmp = x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : (
        x === "zero" ? Css_AtomicTypes.PercentageLengthCalc.toString(x) : Css_AtomicTypes.Width.toString(x)
      );
  }
  return {
          TAG: /* D */0,
          _0: "width",
          _1: tmp
        };
}

function whiteSpace(x) {
  return {
          TAG: /* D */0,
          _0: "whiteSpace",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "nowrap" || x === "normal" || x === "breakSpaces" || x === "preWrap" || x === "preLine" || x === "pre" ? Css_AtomicTypes.WhiteSpace.toString(x) : Css_AtomicTypes.Cascading.toString(x)
            )
        };
}

function wordBreak(x) {
  return {
          TAG: /* D */0,
          _0: "wordBreak",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "keepAll" || x === "normal" || x === "breakAll" ? Css_AtomicTypes.WordBreak.toString(x) : Css_AtomicTypes.Cascading.toString(x)
            )
        };
}

function wordSpacing(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : Css_AtomicTypes.PercentageLengthCalc.toString(x);
  } else {
    tmp = x === "zero" ? Css_AtomicTypes.PercentageLengthCalc.toString(x) : (
        x === "normal" ? Css_AtomicTypes.WordSpacing.toString(x) : Css_AtomicTypes.Cascading.toString(x)
      );
  }
  return {
          TAG: /* D */0,
          _0: "wordSpacing",
          _1: tmp
        };
}

function zIndex(x) {
  return {
          TAG: /* D */0,
          _0: "zIndex",
          _1: x.toString()
        };
}

function media(query, rules) {
  return {
          TAG: /* S */1,
          _0: "@media " + query,
          _1: rules
        };
}

function selector(selector$1, rules) {
  return {
          TAG: /* S */1,
          _0: selector$1,
          _1: rules
        };
}

function active(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "active",
          _1: rules
        };
}

function checked(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "checked",
          _1: rules
        };
}

function $$default(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "default",
          _1: rules
        };
}

function defined(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "defined",
          _1: rules
        };
}

function disabled(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "disabled",
          _1: rules
        };
}

function empty(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "empty",
          _1: rules
        };
}

function enabled(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "enabled",
          _1: rules
        };
}

function first(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "first",
          _1: rules
        };
}

function firstChild(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "first-child",
          _1: rules
        };
}

function firstOfType(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "first-of-type",
          _1: rules
        };
}

function focus(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "focus",
          _1: rules
        };
}

function focusVisible(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "focus-visible",
          _1: rules
        };
}

function focusWithin(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "focus-within",
          _1: rules
        };
}

function host(selector, rules) {
  if (selector !== undefined) {
    return {
            TAG: /* PseudoClassParam */3,
            _0: "host",
            _1: selector,
            _2: rules
          };
  } else {
    return {
            TAG: /* PseudoClass */2,
            _0: "host",
            _1: rules
          };
  }
}

function hover(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "hover",
          _1: rules
        };
}

function indeterminate(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "indeterminate",
          _1: rules
        };
}

function inRange(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "in-range",
          _1: rules
        };
}

function invalid(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "invalid",
          _1: rules
        };
}

function lang(code, rules) {
  return {
          TAG: /* PseudoClassParam */3,
          _0: "lang",
          _1: code,
          _2: rules
        };
}

function lastChild(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "last-child",
          _1: rules
        };
}

function lastOfType(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "last-of-type",
          _1: rules
        };
}

function link(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "link",
          _1: rules
        };
}

function not_(selector, rules) {
  return {
          TAG: /* PseudoClassParam */3,
          _0: "not",
          _1: selector,
          _2: rules
        };
}

function toString(x) {
  if (typeof x !== "object") {
    if (x === "odd") {
      return "odd";
    } else {
      return "even";
    }
  }
  if (x.NAME !== "add") {
    return x.VAL.toString() + "n";
  }
  var match = x.VAL;
  return match[0].toString() + ("n+" + match[1].toString());
}

var Nth = {
  toString: toString
};

function nthChild(x, rules) {
  return {
          TAG: /* PseudoClassParam */3,
          _0: "nth-child",
          _1: toString(x),
          _2: rules
        };
}

function nthLastChild(x, rules) {
  return {
          TAG: /* PseudoClassParam */3,
          _0: "nth-last-child",
          _1: toString(x),
          _2: rules
        };
}

function nthLastOfType(x, rules) {
  return {
          TAG: /* PseudoClassParam */3,
          _0: "nth-last-of-type",
          _1: toString(x),
          _2: rules
        };
}

function nthOfType(x, rules) {
  return {
          TAG: /* PseudoClassParam */3,
          _0: "nth-of-type",
          _1: toString(x),
          _2: rules
        };
}

function onlyChild(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "only-child",
          _1: rules
        };
}

function onlyOfType(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "only-of-type",
          _1: rules
        };
}

function optional(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "optional",
          _1: rules
        };
}

function outOfRange(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "out-of-range",
          _1: rules
        };
}

function readOnly(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "read-only",
          _1: rules
        };
}

function readWrite(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "read-write",
          _1: rules
        };
}

function required(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "required",
          _1: rules
        };
}

function root(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "root",
          _1: rules
        };
}

function scope(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "scope",
          _1: rules
        };
}

function target(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "target",
          _1: rules
        };
}

function valid(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "valid",
          _1: rules
        };
}

function visited(rules) {
  return {
          TAG: /* PseudoClass */2,
          _0: "visited",
          _1: rules
        };
}

function after(rules) {
  return {
          TAG: /* S */1,
          _0: "::after",
          _1: rules
        };
}

function before(rules) {
  return {
          TAG: /* S */1,
          _0: "::before",
          _1: rules
        };
}

function firstLetter(rules) {
  return {
          TAG: /* S */1,
          _0: "::first-letter",
          _1: rules
        };
}

function firstLine(rules) {
  return {
          TAG: /* S */1,
          _0: "::first-line",
          _1: rules
        };
}

function selection(rules) {
  return {
          TAG: /* S */1,
          _0: "::selection",
          _1: rules
        };
}

function child(x, rules) {
  return {
          TAG: /* S */1,
          _0: " > " + x,
          _1: rules
        };
}

function children(rules) {
  return {
          TAG: /* S */1,
          _0: " > *",
          _1: rules
        };
}

function directSibling(rules) {
  return {
          TAG: /* S */1,
          _0: " + ",
          _1: rules
        };
}

function placeholder(rules) {
  return {
          TAG: /* S */1,
          _0: "::placeholder",
          _1: rules
        };
}

function siblings(rules) {
  return {
          TAG: /* S */1,
          _0: " ~ ",
          _1: rules
        };
}

function anyLink(rules) {
  return {
          TAG: /* S */1,
          _0: ":any-link",
          _1: rules
        };
}

var initial = Css_AtomicTypes.Cascading.initial;

var inherit_ = Css_AtomicTypes.Cascading.inherit_;

var unset = Css_AtomicTypes.Cascading.unset;

var $$var = Css_AtomicTypes.Var.$$var;

var varDefault = Css_AtomicTypes.Var.varDefault;

var pct = Css_AtomicTypes.Percentage.pct;

var ch = Css_AtomicTypes.Length.ch;

var cm = Css_AtomicTypes.Length.cm;

var em = Css_AtomicTypes.Length.em;

var ex = Css_AtomicTypes.Length.ex;

var mm = Css_AtomicTypes.Length.mm;

var pt = Css_AtomicTypes.Length.pt;

var px = Css_AtomicTypes.Length.px;

var pxFloat = Css_AtomicTypes.Length.pxFloat;

var rem = Css_AtomicTypes.Length.rem;

var vh = Css_AtomicTypes.Length.vh;

var vmin = Css_AtomicTypes.Length.vmin;

var vmax = Css_AtomicTypes.Length.vmax;

var zero = Css_AtomicTypes.Length.zero;

var deg = Css_AtomicTypes.Angle.deg;

var rad = Css_AtomicTypes.Angle.rad;

var grad = Css_AtomicTypes.Angle.grad;

var turn = Css_AtomicTypes.Angle.turn;

var ltr = Css_AtomicTypes.Direction.ltr;

var rtl = Css_AtomicTypes.Direction.rtl;

var absolute = Css_AtomicTypes.Position.absolute;

var relative = Css_AtomicTypes.Position.relative;

var $$static = Css_AtomicTypes.Position.$$static;

var sticky = Css_AtomicTypes.Position.sticky;

var horizontal = Css_AtomicTypes.Resize.horizontal;

var vertical = Css_AtomicTypes.Resize.vertical;

var smallCaps = Css_AtomicTypes.FontVariant.smallCaps;

var italic = Css_AtomicTypes.FontStyle.italic;

var oblique = Css_AtomicTypes.FontStyle.oblique;

var rgb = Css_AtomicTypes.Color.rgb;

var rgba = Css_AtomicTypes.Color.rgba;

var hsl = Css_AtomicTypes.Color.hsl;

var hsla = Css_AtomicTypes.Color.hsla;

var hex = Css_AtomicTypes.Color.hex;

var currentColor = Css_AtomicTypes.Color.currentColor;

var transparent = Css_AtomicTypes.Color.transparent;

var linear = Css_AtomicTypes.TimingFunction.linear;

var ease = Css_AtomicTypes.TimingFunction.ease;

var easeIn = Css_AtomicTypes.TimingFunction.easeIn;

var easeInOut = Css_AtomicTypes.TimingFunction.easeInOut;

var easeOut = Css_AtomicTypes.TimingFunction.easeOut;

var stepStart = Css_AtomicTypes.TimingFunction.stepStart;

var stepEnd = Css_AtomicTypes.TimingFunction.stepEnd;

var steps = Css_AtomicTypes.TimingFunction.steps;

var cubicBezier = Css_AtomicTypes.TimingFunction.cubicBezier;

var marginBox = Css_AtomicTypes.GeometryBox.marginBox;

var fillBox = Css_AtomicTypes.GeometryBox.fillBox;

var strokeBox = Css_AtomicTypes.GeometryBox.strokeBox;

var viewBox = Css_AtomicTypes.GeometryBox.viewBox;

var translate = Css_AtomicTypes.Transform.translate;

var translate3d = Css_AtomicTypes.Transform.translate3d;

var translateX = Css_AtomicTypes.Transform.translateX;

var translateY = Css_AtomicTypes.Transform.translateY;

var translateZ = Css_AtomicTypes.Transform.translateZ;

var scaleX = Css_AtomicTypes.Transform.scaleX;

var scaleY = Css_AtomicTypes.Transform.scaleY;

var scaleZ = Css_AtomicTypes.Transform.scaleZ;

var rotateX = Css_AtomicTypes.Transform.rotateX;

var rotateY = Css_AtomicTypes.Transform.rotateY;

var rotateZ = Css_AtomicTypes.Transform.rotateZ;

var scale = Css_AtomicTypes.Transform.scale;

var scale3d = Css_AtomicTypes.Transform.scale3d;

var skew = Css_AtomicTypes.Transform.skew;

var skewX = Css_AtomicTypes.Transform.skewX;

var skewY = Css_AtomicTypes.Transform.skewY;

var thin = Css_AtomicTypes.FontWeight.thin;

var extraLight = Css_AtomicTypes.FontWeight.extraLight;

var light = Css_AtomicTypes.FontWeight.light;

var medium = Css_AtomicTypes.FontWeight.medium;

var semiBold = Css_AtomicTypes.FontWeight.semiBold;

var bold = Css_AtomicTypes.FontWeight.bold;

var extraBold = Css_AtomicTypes.FontWeight.extraBold;

var lighter = Css_AtomicTypes.FontWeight.lighter;

var bolder = Css_AtomicTypes.FontWeight.bolder;

var linearGradient = Css_AtomicTypes.Gradient.linearGradient;

var repeatingLinearGradient = Css_AtomicTypes.Gradient.repeatingLinearGradient;

var radialGradient = Css_AtomicTypes.Gradient.radialGradient;

var repeatingRadialGradient = Css_AtomicTypes.Gradient.repeatingRadialGradient;

var conicGradient = Css_AtomicTypes.Gradient.conicGradient;

function areas(items) {
  return Css_AtomicTypes.GridTemplateAreas.areas(Belt_List.toArray(items));
}

var ident = Css_AtomicTypes.GridArea.ident;

var numIdent = Css_AtomicTypes.GridArea.numIdent;

var contextMenu = Css_AtomicTypes.Cursor.contextMenu;

var help = Css_AtomicTypes.Cursor.help;

var pointer = Css_AtomicTypes.Cursor.pointer;

var progress = Css_AtomicTypes.Cursor.progress;

var wait = Css_AtomicTypes.Cursor.wait;

var cell = Css_AtomicTypes.Cursor.cell;

var crosshair = Css_AtomicTypes.Cursor.crosshair;

var verticalText = Css_AtomicTypes.Cursor.verticalText;

var alias = Css_AtomicTypes.Cursor.alias;

var copy = Css_AtomicTypes.Cursor.copy;

var move = Css_AtomicTypes.Cursor.move;

var noDrop = Css_AtomicTypes.Cursor.noDrop;

var notAllowed = Css_AtomicTypes.Cursor.notAllowed;

var grab = Css_AtomicTypes.Cursor.grab;

var grabbing = Css_AtomicTypes.Cursor.grabbing;

var allScroll = Css_AtomicTypes.Cursor.allScroll;

var colResize = Css_AtomicTypes.Cursor.colResize;

var rowResize = Css_AtomicTypes.Cursor.rowResize;

var nResize = Css_AtomicTypes.Cursor.nResize;

var eResize = Css_AtomicTypes.Cursor.eResize;

var sResize = Css_AtomicTypes.Cursor.sResize;

var wResize = Css_AtomicTypes.Cursor.wResize;

var neResize = Css_AtomicTypes.Cursor.neResize;

var nwResize = Css_AtomicTypes.Cursor.nwResize;

var seResize = Css_AtomicTypes.Cursor.seResize;

var swResize = Css_AtomicTypes.Cursor.swResize;

var ewResize = Css_AtomicTypes.Cursor.ewResize;

var nsResize = Css_AtomicTypes.Cursor.nsResize;

var neswResize = Css_AtomicTypes.Cursor.neswResize;

var nwseResize = Css_AtomicTypes.Cursor.nwseResize;

var zoomIn = Css_AtomicTypes.Cursor.zoomIn;

var zoomOut = Css_AtomicTypes.Cursor.zoomOut;

function vw(x) {
  return {
          NAME: "vw",
          VAL: x
        };
}

function fr(x) {
  return {
          NAME: "fr",
          VAL: x
        };
}

function size(x, y) {
  return {
          NAME: "size",
          VAL: [
            x,
            y
          ]
        };
}

function count(x) {
  return {
          NAME: "count",
          VAL: x
        };
}

function localUrl(x) {
  return {
          NAME: "localUrl",
          VAL: x
        };
}

function rotate(a) {
  return {
          NAME: "rotate",
          VAL: a
        };
}

function rotate3d(x, y, z, a) {
  return {
          NAME: "rotate3d",
          VAL: [
            x,
            y,
            z,
            a
          ]
        };
}

function url(x) {
  return {
          NAME: "url",
          VAL: x
        };
}

function flex3(grow, shrink, basis) {
  return {
          TAG: /* D */0,
          _0: "flex",
          _1: grow.toString() + (" " + (shrink.toString() + (" " + (
                  typeof basis === "object" || basis === "zero" ? Css_AtomicTypes.Length.toString(basis) : Css_AtomicTypes.FlexBasis.toString(basis)
                ))))
        };
}

function flexBasis(x) {
  return {
          TAG: /* D */0,
          _0: "flexBasis",
          _1: typeof x === "object" || x === "zero" ? Css_AtomicTypes.PercentageLengthCalc.toString(x) : Css_AtomicTypes.FlexBasis.toString(x)
        };
}

function order(x) {
  return {
          TAG: /* D */0,
          _0: "order",
          _1: x.toString()
        };
}

function minmaxToJs(x) {
  if (typeof x === "object") {
    if (x.NAME === "fr") {
      return x.VAL.toString() + "fr";
    } else {
      return Css_AtomicTypes.PercentageLengthCalc.toString(x);
    }
  } else if (x === "zero") {
    return Css_AtomicTypes.PercentageLengthCalc.toString(x);
  } else if (x === "minContent") {
    return "min-content";
  } else if (x === "maxContent") {
    return "max-content";
  } else {
    return "auto";
  }
}

function trackLengthToJs(x) {
  if (typeof x !== "object") {
    if (x === "zero") {
      return Css_AtomicTypes.PercentageLengthCalc.toString(x);
    } else if (x === "minContent") {
      return "min-content";
    } else if (x === "maxContent") {
      return "max-content";
    } else {
      return "auto";
    }
  }
  var variant = x.NAME;
  if (variant !== "minmax") {
    if (variant === "fr") {
      return x.VAL.toString() + "fr";
    } else {
      return Css_AtomicTypes.PercentageLengthCalc.toString(x);
    }
  }
  var match = x.VAL;
  return "minmax(" + minmaxToJs(match[0]) + "," + minmaxToJs(match[1]) + ")";
}

function gridLengthToJs(x) {
  if (typeof x !== "object") {
    if (x === "zero") {
      return Css_AtomicTypes.PercentageLengthCalc.toString(x);
    } else if (x === "minContent") {
      return "min-content";
    } else if (x === "maxContent") {
      return "max-content";
    } else {
      return "auto";
    }
  }
  var variant = x.NAME;
  if (variant === "minmax") {
    var match = x.VAL;
    return "minmax(" + minmaxToJs(match[0]) + "," + minmaxToJs(match[1]) + ")";
  }
  if (variant === "fr") {
    return x.VAL.toString() + "fr";
  }
  if (variant !== "repeat") {
    return Css_AtomicTypes.PercentageLengthCalc.toString(x);
  }
  var match$1 = x.VAL;
  return "repeat(" + Css_AtomicTypes.RepeatValue.toString(match$1[0]) + ", " + trackLengthToJs(match$1[1]) + ")";
}

function gridTemplateColumns(dimensions) {
  return {
          TAG: /* D */0,
          _0: "gridTemplateColumns",
          _1: $$String.concat(" ", List.map(gridLengthToJs, dimensions))
        };
}

function gridTemplateRows(dimensions) {
  return {
          TAG: /* D */0,
          _0: "gridTemplateRows",
          _1: $$String.concat(" ", List.map(gridLengthToJs, dimensions))
        };
}

function gridAutoColumns(dimensions) {
  return {
          TAG: /* D */0,
          _0: "gridAutoColumns",
          _1: trackLengthToJs(dimensions)
        };
}

function gridAutoRows(dimensions) {
  return {
          TAG: /* D */0,
          _0: "gridAutoRows",
          _1: trackLengthToJs(dimensions)
        };
}

function gridArea(s) {
  var tmp;
  if (typeof s === "object") {
    var variant = s.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(s) : Css_AtomicTypes.GridArea.toString(s);
  } else {
    tmp = s === "unset" || s === "inherit_" || s === "initial" ? Css_AtomicTypes.Cascading.toString(s) : Css_AtomicTypes.GridArea.toString(s);
  }
  return {
          TAG: /* D */0,
          _0: "gridArea",
          _1: tmp
        };
}

function gridArea2(s, s2) {
  return {
          TAG: /* D */0,
          _0: "gridArea",
          _1: Css_AtomicTypes.GridArea.toString(s) + (" / " + Css_AtomicTypes.GridArea.toString(s2))
        };
}

function gridArea3(s, s2, s3) {
  return {
          TAG: /* D */0,
          _0: "gridArea",
          _1: Css_AtomicTypes.GridArea.toString(s) + (" / " + (Css_AtomicTypes.GridArea.toString(s2) + (" / " + Css_AtomicTypes.GridArea.toString(s3))))
        };
}

function gridArea4(s, s2, s3, s4) {
  return {
          TAG: /* D */0,
          _0: "gridArea",
          _1: Css_AtomicTypes.GridArea.toString(s) + (" / " + (Css_AtomicTypes.GridArea.toString(s2) + (" / " + (Css_AtomicTypes.GridArea.toString(s3) + (" / " + Css_AtomicTypes.GridArea.toString(s4))))))
        };
}

function gridTemplateAreas(l) {
  return {
          TAG: /* D */0,
          _0: "gridTemplateAreas",
          _1: typeof l === "object" ? (
              l.NAME === "areas" ? Css_AtomicTypes.GridTemplateAreas.toString(l) : Css_AtomicTypes.Var.toString(l)
            ) : (
              l === "unset" || l === "inherit_" || l === "initial" ? Css_AtomicTypes.Cascading.toString(l) : Css_AtomicTypes.GridTemplateAreas.toString(l)
            )
        };
}

function string_of_filter(x) {
  if (typeof x !== "object") {
    if (x === "unset" || x === "inherit_" || x === "initial") {
      return Css_AtomicTypes.Cascading.toString(x);
    } else {
      return "none";
    }
  }
  var variant = x.NAME;
  if (variant !== "dropShadow") {
    if (variant === "brightness") {
      return "brightness(" + (x.VAL.toString() + "%)");
    } else if (variant === "opacity") {
      return "opacity(" + (x.VAL.toString() + "%)");
    } else if (variant === "var" || variant === "varDefault") {
      return Css_AtomicTypes.Var.toString(x);
    } else if (variant === "grayscale") {
      return "grayscale(" + (x.VAL.toString() + "%)");
    } else if (variant === "sepia") {
      return "sepia(" + (x.VAL.toString() + "%)");
    } else if (variant === "saturate") {
      return "saturate(" + (x.VAL.toString() + "%)");
    } else if (variant === "url") {
      return Css_AtomicTypes.Url.toString(x);
    } else if (variant === "invert") {
      return "invert(" + (x.VAL.toString() + "%)");
    } else if (variant === "contrast") {
      return "contrast(" + (x.VAL.toString() + "%)");
    } else if (variant === "hueRotate") {
      return "hue-rotate(" + (Css_AtomicTypes.Angle.toString(x.VAL) + ")");
    } else {
      return "blur(" + (Css_AtomicTypes.Length.toString(x.VAL) + ")");
    }
  }
  var match = x.VAL;
  return "drop-shadow(" + (Css_AtomicTypes.Length.toString(match[0]) + (" " + (Css_AtomicTypes.Length.toString(match[1]) + (" " + (Css_AtomicTypes.Length.toString(match[2]) + (" " + (Css_AtomicTypes.Color.toString(match[3]) + ")")))))));
}

function filter(x) {
  return {
          TAG: /* D */0,
          _0: "filter",
          _1: join(Belt_List.map(x, string_of_filter), " ")
        };
}

function box(xOpt, yOpt, blurOpt, spreadOpt, insetOpt, color) {
  var x = xOpt !== undefined ? xOpt : zero;
  var y = yOpt !== undefined ? yOpt : zero;
  var blur = blurOpt !== undefined ? blurOpt : zero;
  var spread = spreadOpt !== undefined ? spreadOpt : zero;
  var inset = insetOpt !== undefined ? insetOpt : false;
  return {
          NAME: "shadow",
          VAL: Css_AtomicTypes.Length.toString(x) + (" " + (Css_AtomicTypes.Length.toString(y) + (" " + (Css_AtomicTypes.Length.toString(blur) + (" " + (Css_AtomicTypes.Length.toString(spread) + (" " + (string_of_color(color) + (
                            inset ? " inset" : ""
                          )))))))))
        };
}

function text(xOpt, yOpt, blurOpt, color) {
  var x = xOpt !== undefined ? xOpt : zero;
  var y = yOpt !== undefined ? yOpt : zero;
  var blur = blurOpt !== undefined ? blurOpt : zero;
  return {
          NAME: "shadow",
          VAL: Css_AtomicTypes.Length.toString(x) + (" " + (Css_AtomicTypes.Length.toString(y) + (" " + (Css_AtomicTypes.Length.toString(blur) + (" " + string_of_color(color))))))
        };
}

function toString$1(x) {
  if (typeof x === "object") {
    return x.VAL;
  } else {
    return "none";
  }
}

var Shadow = {
  box: box,
  text: text,
  toString: toString$1
};

function boxShadow(x) {
  return {
          TAG: /* D */0,
          _0: "boxShadow",
          _1: typeof x === "object" ? (
              x.NAME === "shadow" ? toString$1(x) : Css_AtomicTypes.Var.toString(x)
            ) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : toString$1(x)
            )
        };
}

function boxShadows(x) {
  return {
          TAG: /* D */0,
          _0: "boxShadow",
          _1: join(Belt_List.map(x, toString$1), ", ")
        };
}

function string_of_borderstyle(x) {
  if (typeof x === "object") {
    return Css_AtomicTypes.Var.toString(x);
  } else if (x === "unset" || x === "inherit_" || x === "initial") {
    return Css_AtomicTypes.Cascading.toString(x);
  } else {
    return Css_AtomicTypes.BorderStyle.toString(x);
  }
}

function border(px, style, color) {
  return {
          TAG: /* D */0,
          _0: "border",
          _1: Css_AtomicTypes.Length.toString(px) + (" " + (string_of_borderstyle(style) + (" " + string_of_color(color))))
        };
}

function borderStyle(x) {
  return {
          TAG: /* D */0,
          _0: "borderStyle",
          _1: string_of_borderstyle(x)
        };
}

function borderLeft(px, style, color) {
  return {
          TAG: /* D */0,
          _0: "borderLeft",
          _1: Css_AtomicTypes.Length.toString(px) + (" " + (string_of_borderstyle(style) + (" " + string_of_color(color))))
        };
}

function borderLeftStyle(x) {
  return {
          TAG: /* D */0,
          _0: "borderLeftStyle",
          _1: string_of_borderstyle(x)
        };
}

function borderRight(px, style, color) {
  return {
          TAG: /* D */0,
          _0: "borderRight",
          _1: Css_AtomicTypes.Length.toString(px) + (" " + (string_of_borderstyle(style) + (" " + string_of_color(color))))
        };
}

function borderRightStyle(x) {
  return {
          TAG: /* D */0,
          _0: "borderRightStyle",
          _1: string_of_borderstyle(x)
        };
}

function borderTop(px, style, color) {
  return {
          TAG: /* D */0,
          _0: "borderTop",
          _1: Css_AtomicTypes.Length.toString(px) + (" " + (string_of_borderstyle(style) + (" " + string_of_color(color))))
        };
}

function borderTopStyle(x) {
  return {
          TAG: /* D */0,
          _0: " ",
          _1: string_of_borderstyle(x)
        };
}

function borderBottom(px, style, color) {
  return {
          TAG: /* D */0,
          _0: "borderBottom",
          _1: Css_AtomicTypes.Length.toString(px) + (" " + (string_of_borderstyle(style) + (" " + string_of_color(color))))
        };
}

function borderBottomStyle(x) {
  return {
          TAG: /* D */0,
          _0: "borderBottomStyle",
          _1: string_of_borderstyle(x)
        };
}

function background(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "linearGradient" || variant === "radialGradient" || variant === "repeatingLinearGradient" || variant === "repeatingRadialGradient" || variant === "conicGradient" ? Css_AtomicTypes.Gradient.toString(x) : (
        variant === "url" ? Css_AtomicTypes.Url.toString(x) : Css_AtomicTypes.Color.toString(x)
      );
  } else {
    tmp = x === "transparent" || x === "currentColor" ? Css_AtomicTypes.Color.toString(x) : "none";
  }
  return {
          TAG: /* D */0,
          _0: "background",
          _1: tmp
        };
}

function backgrounds(x) {
  return {
          TAG: /* D */0,
          _0: "background",
          _1: join(Belt_List.map(x, (function (item) {
                      if (typeof item !== "object") {
                        if (item === "transparent" || item === "currentColor") {
                          return Css_AtomicTypes.Color.toString(item);
                        } else {
                          return "none";
                        }
                      }
                      var variant = item.NAME;
                      if (variant === "linearGradient" || variant === "radialGradient" || variant === "repeatingLinearGradient" || variant === "repeatingRadialGradient" || variant === "conicGradient") {
                        return Css_AtomicTypes.Gradient.toString(item);
                      } else if (variant === "url") {
                        return Css_AtomicTypes.Url.toString(item);
                      } else {
                        return Css_AtomicTypes.Color.toString(item);
                      }
                    })), ", ")
        };
}

function backgroundSize(x) {
  var tmp;
  if (typeof x === "object") {
    var match = x.VAL;
    tmp = Css_AtomicTypes.Length.toString(match[0]) + (" " + Css_AtomicTypes.Length.toString(match[1]));
  } else {
    tmp = x === "cover" ? "cover" : (
        x === "contain" ? "contain" : "auto"
      );
  }
  return {
          TAG: /* D */0,
          _0: "backgroundSize",
          _1: tmp
        };
}

function fontFace(fontFamily, src, fontStyle, fontWeight, fontDisplay, sizeAdjust, param) {
  var fontStyle$1 = Js_option.map((function (value) {
          return Css_AtomicTypes.FontStyle.toString(value);
        }), fontStyle);
  var src$1 = $$String.concat(", ", List.map((function (x) {
              if (x.NAME === "url") {
                return "url(\"" + x.VAL + "\")";
              } else {
                return "local(\"" + x.VAL + "\")";
              }
            }), src));
  var fontStyle$2 = Belt_Option.mapWithDefault(fontStyle$1, "", (function (s) {
          return "font-style: " + (s + ";");
        }));
  var fontWeight$1 = Belt_Option.mapWithDefault(fontWeight, "", (function (w) {
          return "font-weight: " + ((
                    typeof w === "object" ? (
                        w.NAME === "num" ? Css_AtomicTypes.FontWeight.toString(w) : Css_AtomicTypes.Var.toString(w)
                      ) : (
                        w === "unset" || w === "inherit_" || w === "initial" ? Css_AtomicTypes.Cascading.toString(w) : Css_AtomicTypes.FontWeight.toString(w)
                      )
                  ) + ";");
        }));
  var fontDisplay$1 = Belt_Option.mapWithDefault(fontDisplay, "", (function (f) {
          return "font-display: " + (Css_AtomicTypes.FontDisplay.toString(f) + ";");
        }));
  var sizeAdjust$1 = Belt_Option.mapWithDefault(sizeAdjust, "", (function (s) {
          return "size-adjust: " + Css_AtomicTypes.Percentage.toString(s) + ";";
        }));
  return "@font-face {\r\n     font-family: " + fontFamily + ";\r\n     src: " + src$1 + ";\r\n     " + fontStyle$2 + "\r\n     " + fontWeight$1 + "\r\n     " + fontDisplay$1 + "\r\n     " + sizeAdjust$1 + "\r\n   }";
}

function textDecoration(x) {
  return {
          TAG: /* D */0,
          _0: "textDecoration",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "none" ? "none" : (
                  x === "initial" ? "initial" : (
                      x === "inherit_" ? "inherit" : (
                          x === "underline" ? "underline" : (
                              x === "lineThrough" ? "line-through" : (
                                  x === "unset" ? "unset" : "overline"
                                )
                            )
                        )
                    )
                )
            )
        };
}

function textShadow(x) {
  return {
          TAG: /* D */0,
          _0: "textShadow",
          _1: typeof x === "object" ? (
              x.NAME === "shadow" ? toString$1(x) : Css_AtomicTypes.Var.toString(x)
            ) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : toString$1(x)
            )
        };
}

function textShadows(x) {
  return {
          TAG: /* D */0,
          _0: "textShadow",
          _1: join(Belt_List.map(x, toString$1), ", ")
        };
}

function transformStyle(x) {
  return {
          TAG: /* D */0,
          _0: "transformStyle",
          _1: typeof x === "object" ? Css_AtomicTypes.Var.toString(x) : (
              x === "unset" || x === "inherit_" || x === "initial" ? Css_AtomicTypes.Cascading.toString(x) : Css_AtomicTypes.TransformStyle.toString(x)
            )
        };
}

function shorthand(durationOpt, delayOpt, timingFunctionOpt, property) {
  var duration = durationOpt !== undefined ? durationOpt : ({
        NAME: "ms",
        VAL: 0
      });
  var delay = delayOpt !== undefined ? delayOpt : ({
        NAME: "ms",
        VAL: 0
      });
  var timingFunction = timingFunctionOpt !== undefined ? timingFunctionOpt : "ease";
  return {
          NAME: "value",
          VAL: Css_AtomicTypes.Time.toString(duration) + (" " + (Css_AtomicTypes.TimingFunction.toString(timingFunction) + (" " + (Css_AtomicTypes.Time.toString(delay) + (" " + property)))))
        };
}

function toString$2(x) {
  return x.VAL;
}

var Transition = {
  shorthand: shorthand,
  toString: toString$2
};

function transitionValue(x) {
  return {
          TAG: /* D */0,
          _0: "transition",
          _1: x.VAL
        };
}

function transitionList(x) {
  return {
          TAG: /* D */0,
          _0: "transition",
          _1: join(Belt_List.map(x, toString$2), ", ")
        };
}

function transition(duration, delay, timingFunction, property) {
  var x = shorthand(duration, delay, timingFunction, property);
  return {
          TAG: /* D */0,
          _0: "transition",
          _1: x.VAL
        };
}

function transitionDelay(i) {
  return {
          TAG: /* D */0,
          _0: "transitionDelay",
          _1: Css_AtomicTypes.Time.toString(i)
        };
}

function transitionDuration(i) {
  return {
          TAG: /* D */0,
          _0: "transitionDuration",
          _1: Css_AtomicTypes.Time.toString(i)
        };
}

function transitionTimingFunction(x) {
  return {
          TAG: /* D */0,
          _0: "transitionTimingFunction",
          _1: Css_AtomicTypes.TimingFunction.toString(x)
        };
}

function transitionProperty(x) {
  return {
          TAG: /* D */0,
          _0: "transitionProperty",
          _1: x
        };
}

function shorthand$1(durationOpt, delayOpt, directionOpt, timingFunctionOpt, fillModeOpt, playStateOpt, iterationCountOpt, name) {
  var duration = durationOpt !== undefined ? durationOpt : ({
        NAME: "ms",
        VAL: 0
      });
  var delay = delayOpt !== undefined ? delayOpt : ({
        NAME: "ms",
        VAL: 0
      });
  var direction = directionOpt !== undefined ? directionOpt : "normal";
  var timingFunction = timingFunctionOpt !== undefined ? timingFunctionOpt : "ease";
  var fillMode = fillModeOpt !== undefined ? fillModeOpt : "none";
  var playState = playStateOpt !== undefined ? playStateOpt : "running";
  var iterationCount = iterationCountOpt !== undefined ? iterationCountOpt : ({
        NAME: "count",
        VAL: 1
      });
  return {
          NAME: "value",
          VAL: name + (" " + (Css_AtomicTypes.Time.toString(duration) + (" " + (Css_AtomicTypes.TimingFunction.toString(timingFunction) + (" " + (Css_AtomicTypes.Time.toString(delay) + (" " + (Css_AtomicTypes.AnimationIterationCount.toString(iterationCount) + (" " + (Css_AtomicTypes.AnimationDirection.toString(direction) + (" " + (Css_AtomicTypes.AnimationFillMode.toString(fillMode) + (" " + Css_AtomicTypes.AnimationPlayState.toString(playState))))))))))))))
        };
}

function toString$3(x) {
  return x.VAL;
}

var Animation = {
  shorthand: shorthand$1,
  toString: toString$3
};

function animationValue(x) {
  return {
          TAG: /* D */0,
          _0: "animation",
          _1: x.VAL
        };
}

function animation(duration, delay, direction, timingFunction, fillMode, playState, iterationCount, name) {
  var x = shorthand$1(duration, delay, direction, timingFunction, fillMode, playState, iterationCount, name);
  return {
          TAG: /* D */0,
          _0: "animation",
          _1: x.VAL
        };
}

function animations(x) {
  return {
          TAG: /* D */0,
          _0: "animation",
          _1: join(Belt_List.map(x, toString$3), ", ")
        };
}

function animationName(x) {
  return {
          TAG: /* D */0,
          _0: "animationName",
          _1: x
        };
}

function fill(x) {
  var tmp;
  if (typeof x === "object") {
    var variant = x.NAME;
    tmp = variant === "var" || variant === "varDefault" ? Css_AtomicTypes.Var.toString(x) : (
        variant === "url" ? Css_AtomicTypes.Url.toString(x) : Css_AtomicTypes.Color.toString(x)
      );
  } else {
    tmp = x === "transparent" || x === "currentColor" ? Css_AtomicTypes.Color.toString(x) : Curry._1(Css_AtomicTypes.SVG.Fill.toString, x);
  }
  return {
          TAG: /* D */0,
          _0: "fill",
          _1: tmp
        };
}

function fillOpacity(opacity) {
  return {
          TAG: /* D */0,
          _0: "fillOpacity",
          _1: opacity.toString()
        };
}

function fillRule(x) {
  return {
          TAG: /* D */0,
          _0: "fillRule",
          _1: x === "evenodd" ? "evenodd" : "nonzero"
        };
}

function stroke(x) {
  return {
          TAG: /* D */0,
          _0: "stroke",
          _1: string_of_color(x)
        };
}

function strokeDasharray(x) {
  return {
          TAG: /* D */0,
          _0: "strokeDasharray",
          _1: typeof x === "object" ? join(Belt_List.map(x.VAL, string_of_dasharray), " ") : "none"
        };
}

function strokeWidth(x) {
  return {
          TAG: /* D */0,
          _0: "strokeWidth",
          _1: Css_AtomicTypes.Length.toString(x)
        };
}

function strokeOpacity(opacity) {
  return {
          TAG: /* D */0,
          _0: "strokeOpacity",
          _1: opacity.toString()
        };
}

function strokeMiterlimit(x) {
  return {
          TAG: /* D */0,
          _0: "strokeMiterlimit",
          _1: x.toString()
        };
}

function strokeLinecap(x) {
  return {
          TAG: /* D */0,
          _0: "strokeLinecap",
          _1: x === "square" ? "square" : (
              x === "round" ? "round" : "butt"
            )
        };
}

function strokeLinejoin(x) {
  return {
          TAG: /* D */0,
          _0: "strokeLinejoin",
          _1: x === "miter" ? "miter" : (
              x === "bevel" ? "bevel" : "round"
            )
        };
}

function stopColor(x) {
  return {
          TAG: /* D */0,
          _0: "stopColor",
          _1: string_of_color(x)
        };
}

function stopOpacity(x) {
  return {
          TAG: /* D */0,
          _0: "stopOpacity",
          _1: x.toString()
        };
}

function touchAction(x) {
  return {
          TAG: /* D */0,
          _0: "touchAction",
          _1: Css_AtomicTypes.TouchAction.toString(x)
        };
}

var Types;

var wordWrap = overflowWrap;

var auto = "auto";

var none = "none";

var text$1 = "text";

var fixed = "fixed";

var isolate = "isolate";

var hidden = "hidden";

var visible = "visible";

var scroll = "scroll";

var local = "local";

var paddingBox = "paddingBox";

var borderBox = "borderBox";

var contentBox = "contentBox";

var noRepeat = "noRepeat";

var space = "space";

var repeat = "repeat";

var minmax = "minmax";

var repeatX = "repeatX";

var repeatY = "repeatY";

var contain = "contain";

var cover = "cover";

var row = "row";

var rowReverse = "rowReverse";

var column = "column";

var columnReverse = "columnReverse";

var wrap = "wrap";

var nowrap = "nowrap";

var wrapReverse = "wrapReverse";

var inline = "inline";

var block = "block";

var contents = "contents";

var flexBox = "flex";

var grid = "grid";

var inlineBlock = "inlineBlock";

var inlineFlex = "inlineFlex";

var inlineGrid = "inlineGrid";

var inlineTable = "inlineTable";

var listItem = "listItem";

var runIn = "runIn";

var table = "table";

var tableCaption = "tableCaption";

var tableColumnGroup = "tableColumnGroup";

var tableHeaderGroup = "tableHeaderGroup";

var tableFooterGroup = "tableFooterGroup";

var tableRowGroup = "tableRowGroup";

var tableCell = "tableCell";

var tableColumn = "tableColumn";

var tableRow = "tableRow";

var flexStart = "flexStart";

var flexEnd = "flexEnd";

var center = "center";

var stretch = "stretch";

var spaceBetween = "spaceBetween";

var spaceAround = "spaceAround";

var spaceEvenly = "spaceEvenly";

var baseline = "baseline";

var forwards = "forwards";

var backwards = "backwards";

var both = "both";

var infinite = "infinite";

var paused = "paused";

var running = "running";

var inside = "inside";

var outside = "outside";

var solid = "solid";

var dotted = "dotted";

var dashed = "dashed";

var underline = "underline";

var overline = "overline";

var lineThrough = "lineThrough";

var clip = "clip";

var ellipsis = "ellipsis";

var wavy = "wavy";

var $$double = "double";

var uppercase = "uppercase";

var lowercase = "lowercase";

var capitalize = "capitalize";

var sub = "sub";

var $$super = "super";

var textTop = "textTop";

var textBottom = "textBottom";

var middle = "middle";

var normal = "normal";

var breakAll = "breakAll";

var keepAll = "keepAll";

var breakWord = "breakWord";

var reverse = "reverse";

var alternate = "alternate";

var alternateReverse = "alternateReverse";

var fill$1 = "fill";

var content = "content";

var maxContent = "maxContent";

var minContent = "minContent";

var fitContent = "fitContent";

var all = "all";

var round = "round";

var miter = "miter";

var bevel = "bevel";

var butt = "butt";

var square = "square";

var panX = "panX";

var panY = "panY";

var panLeft = "panLeft";

var panRight = "panRight";

var panUp = "panUp";

var panDown = "panDown";

var pinchZoom = "pinchZoom";

var manipulation = "manipulation";

var transitions = transitionList;

var SVG = {
  fill: fill,
  fillRule: fillRule,
  fillOpacity: fillOpacity,
  stroke: stroke,
  strokeDasharray: strokeDasharray,
  strokeLinecap: strokeLinecap,
  strokeLinejoin: strokeLinejoin,
  strokeMiterlimit: strokeMiterlimit,
  strokeWidth: strokeWidth,
  strokeOpacity: strokeOpacity,
  stopColor: stopColor,
  stopOpacity: stopOpacity
};

exports.Types = Types;
exports.Make = Make;
exports.toJson = toJson;
exports.important = important;
exports.label = label;
exports.Shadow = Shadow;
exports.Calc = Calc;
exports.unsafe = unsafe;
exports.alignContent = alignContent;
exports.alignItems = alignItems;
exports.alignSelf = alignSelf;
exports.backdropFilter = backdropFilter;
exports.backfaceVisibility = backfaceVisibility;
exports.backgroundAttachment = backgroundAttachment;
exports.backgroundColor = backgroundColor;
exports.backgroundClip = backgroundClip;
exports.backgroundImage = backgroundImage;
exports.maskImage = maskImage;
exports.backgroundOrigin = backgroundOrigin;
exports.backgroundPosition = backgroundPosition;
exports.backgroundPositions = backgroundPositions;
exports.backgroundPosition4 = backgroundPosition4;
exports.backgroundRepeat = backgroundRepeat;
exports.maskPosition = maskPosition;
exports.maskPositions = maskPositions;
exports.borderBottom = borderBottom;
exports.borderBottomColor = borderBottomColor;
exports.borderBottomLeftRadius = borderBottomLeftRadius;
exports.borderBottomRightRadius = borderBottomRightRadius;
exports.borderBottomStyle = borderBottomStyle;
exports.borderBottomWidth = borderBottomWidth;
exports.borderCollapse = borderCollapse;
exports.borderColor = borderColor;
exports.borderLeft = borderLeft;
exports.borderLeftColor = borderLeftColor;
exports.borderLeftStyle = borderLeftStyle;
exports.borderLeftWidth = borderLeftWidth;
exports.borderRight = borderRight;
exports.borderRightColor = borderRightColor;
exports.borderRightStyle = borderRightStyle;
exports.borderRightWidth = borderRightWidth;
exports.borderRadius = borderRadius;
exports.borderSpacing = borderSpacing;
exports.borderStyle = borderStyle;
exports.borderTopColor = borderTopColor;
exports.borderTopLeftRadius = borderTopLeftRadius;
exports.borderTopRightRadius = borderTopRightRadius;
exports.borderTopStyle = borderTopStyle;
exports.borderTopWidth = borderTopWidth;
exports.borderWidth = borderWidth;
exports.bottom = bottom;
exports.boxSizing = boxSizing;
exports.boxShadow = boxShadow;
exports.boxShadows = boxShadows;
exports.clear = clear;
exports.clipPath = clipPath;
exports.color = color;
exports.columnCount = columnCount;
exports.contentRule = contentRule;
exports.contentRules = contentRules;
exports.counterIncrement = counterIncrement;
exports.countersIncrement = countersIncrement;
exports.counterReset = counterReset;
exports.countersReset = countersReset;
exports.counterSet = counterSet;
exports.countersSet = countersSet;
exports.cursor = cursor;
exports.direction = direction;
exports.display = display;
exports.flex = flex;
exports.flexBasis = flexBasis;
exports.flexDirection = flexDirection;
exports.flexGrow = flexGrow;
exports.flexShrink = flexShrink;
exports.flexWrap = flexWrap;
exports.$$float = $$float;
exports.fontFamily = fontFamily;
exports.fontFamilies = fontFamilies;
exports.fontSize = fontSize;
exports.fontStyle = fontStyle;
exports.fontVariant = fontVariant;
exports.fontWeight = fontWeight;
exports.gridArea = gridArea;
exports.gridArea2 = gridArea2;
exports.gridArea3 = gridArea3;
exports.gridArea4 = gridArea4;
exports.gridAutoFlow = gridAutoFlow;
exports.gridColumn = gridColumn;
exports.gridColumnEnd = gridColumnEnd;
exports.columnGap = columnGap;
exports.scrollBehavior = scrollBehavior;
exports.overscrollBehavior = overscrollBehavior;
exports.overflowAnchor = overflowAnchor;
exports.columnWidth = columnWidth;
exports.caretColor = caretColor;
exports.rowGap = rowGap;
exports.gridColumnGap = gridColumnGap;
exports.gridColumnStart = gridColumnStart;
exports.gap = gap;
exports.gap2 = gap2;
exports.gridGap = gridGap;
exports.gridRow = gridRow;
exports.gridRowEnd = gridRowEnd;
exports.gridRowGap = gridRowGap;
exports.gridRowStart = gridRowStart;
exports.gridTemplateAreas = gridTemplateAreas;
exports.height = height;
exports.justifyContent = justifyContent;
exports.justifyItems = justifyItems;
exports.justifySelf = justifySelf;
exports.left = left;
exports.letterSpacing = letterSpacing;
exports.lineHeight = lineHeight;
exports.listStyle = listStyle;
exports.listStyleImage = listStyleImage;
exports.listStyleType = listStyleType;
exports.listStylePosition = listStylePosition;
exports.margin = margin;
exports.margin2 = margin2;
exports.margin3 = margin3;
exports.margin4 = margin4;
exports.marginLeft = marginLeft;
exports.marginRight = marginRight;
exports.marginTop = marginTop;
exports.marginBottom = marginBottom;
exports.maxHeight = maxHeight;
exports.maxWidth = maxWidth;
exports.minHeight = minHeight;
exports.minWidth = minWidth;
exports.objectFit = objectFit;
exports.objectPosition = objectPosition;
exports.opacity = opacity;
exports.order = order;
exports.outline = outline;
exports.outlineColor = outlineColor;
exports.outlineOffset = outlineOffset;
exports.outlineStyle = outlineStyle;
exports.outlineWidth = outlineWidth;
exports.overflow = overflow;
exports.overflowX = overflowX;
exports.overflowY = overflowY;
exports.overflowWrap = overflowWrap;
exports.padding = padding;
exports.padding2 = padding2;
exports.padding3 = padding3;
exports.padding4 = padding4;
exports.paddingLeft = paddingLeft;
exports.paddingRight = paddingRight;
exports.paddingTop = paddingTop;
exports.paddingBottom = paddingBottom;
exports.perspective = perspective;
exports.perspectiveOrigin = perspectiveOrigin;
exports.pointerEvents = pointerEvents;
exports.position = position;
exports.isolation = isolation;
exports.resize = resize;
exports.right = right;
exports.tableLayout = tableLayout;
exports.textAlign = textAlign;
exports.textDecorationColor = textDecorationColor;
exports.textDecorationLine = textDecorationLine;
exports.textDecorationStyle = textDecorationStyle;
exports.textIndent = textIndent;
exports.textOverflow = textOverflow;
exports.textShadow = textShadow;
exports.textShadows = textShadows;
exports.textTransform = textTransform;
exports.top = top;
exports.transform = transform;
exports.transforms = transforms;
exports.transformOrigin = transformOrigin;
exports.transformOrigin3d = transformOrigin3d;
exports.transitionDelay = transitionDelay;
exports.transitionDuration = transitionDuration;
exports.transitionProperty = transitionProperty;
exports.transformStyle = transformStyle;
exports.transitionTimingFunction = transitionTimingFunction;
exports.userSelect = userSelect;
exports.verticalAlign = verticalAlign;
exports.visibility = visibility;
exports.width = width;
exports.whiteSpace = whiteSpace;
exports.wordBreak = wordBreak;
exports.wordSpacing = wordSpacing;
exports.wordWrap = wordWrap;
exports.zIndex = zIndex;
exports.selector = selector;
exports.media = media;
exports.active = active;
exports.checked = checked;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
exports.defined = defined;
exports.disabled = disabled;
exports.empty = empty;
exports.enabled = enabled;
exports.first = first;
exports.firstChild = firstChild;
exports.firstOfType = firstOfType;
exports.focus = focus;
exports.focusVisible = focusVisible;
exports.focusWithin = focusWithin;
exports.host = host;
exports.hover = hover;
exports.indeterminate = indeterminate;
exports.inRange = inRange;
exports.invalid = invalid;
exports.lang = lang;
exports.lastChild = lastChild;
exports.lastOfType = lastOfType;
exports.link = link;
exports.not_ = not_;
exports.Nth = Nth;
exports.nthChild = nthChild;
exports.nthLastChild = nthLastChild;
exports.nthLastOfType = nthLastOfType;
exports.nthOfType = nthOfType;
exports.onlyChild = onlyChild;
exports.onlyOfType = onlyOfType;
exports.optional = optional;
exports.outOfRange = outOfRange;
exports.readOnly = readOnly;
exports.readWrite = readWrite;
exports.required = required;
exports.root = root;
exports.scope = scope;
exports.target = target;
exports.valid = valid;
exports.visited = visited;
exports.after = after;
exports.before = before;
exports.firstLetter = firstLetter;
exports.firstLine = firstLine;
exports.placeholder = placeholder;
exports.selection = selection;
exports.child = child;
exports.children = children;
exports.directSibling = directSibling;
exports.siblings = siblings;
exports.anyLink = anyLink;
exports.initial = initial;
exports.inherit_ = inherit_;
exports.unset = unset;
exports.$$var = $$var;
exports.varDefault = varDefault;
exports.auto = auto;
exports.none = none;
exports.text = text$1;
exports.pct = pct;
exports.ch = ch;
exports.cm = cm;
exports.em = em;
exports.ex = ex;
exports.mm = mm;
exports.pt = pt;
exports.px = px;
exports.pxFloat = pxFloat;
exports.rem = rem;
exports.vh = vh;
exports.vmin = vmin;
exports.vmax = vmax;
exports.zero = zero;
exports.deg = deg;
exports.rad = rad;
exports.grad = grad;
exports.turn = turn;
exports.ltr = ltr;
exports.rtl = rtl;
exports.absolute = absolute;
exports.relative = relative;
exports.$$static = $$static;
exports.fixed = fixed;
exports.sticky = sticky;
exports.isolate = isolate;
exports.horizontal = horizontal;
exports.vertical = vertical;
exports.smallCaps = smallCaps;
exports.italic = italic;
exports.oblique = oblique;
exports.hidden = hidden;
exports.visible = visible;
exports.scroll = scroll;
exports.rgb = rgb;
exports.rgba = rgba;
exports.hsl = hsl;
exports.hsla = hsla;
exports.hex = hex;
exports.transparent = transparent;
exports.currentColor = currentColor;
exports.local = local;
exports.paddingBox = paddingBox;
exports.borderBox = borderBox;
exports.contentBox = contentBox;
exports.noRepeat = noRepeat;
exports.space = space;
exports.repeat = repeat;
exports.minmax = minmax;
exports.repeatX = repeatX;
exports.repeatY = repeatY;
exports.contain = contain;
exports.cover = cover;
exports.row = row;
exports.rowReverse = rowReverse;
exports.column = column;
exports.columnReverse = columnReverse;
exports.wrap = wrap;
exports.nowrap = nowrap;
exports.wrapReverse = wrapReverse;
exports.inline = inline;
exports.block = block;
exports.contents = contents;
exports.flexBox = flexBox;
exports.grid = grid;
exports.inlineBlock = inlineBlock;
exports.inlineFlex = inlineFlex;
exports.inlineGrid = inlineGrid;
exports.inlineTable = inlineTable;
exports.listItem = listItem;
exports.runIn = runIn;
exports.table = table;
exports.tableCaption = tableCaption;
exports.tableColumnGroup = tableColumnGroup;
exports.tableHeaderGroup = tableHeaderGroup;
exports.tableFooterGroup = tableFooterGroup;
exports.tableRowGroup = tableRowGroup;
exports.tableCell = tableCell;
exports.tableColumn = tableColumn;
exports.tableRow = tableRow;
exports.flexStart = flexStart;
exports.flexEnd = flexEnd;
exports.center = center;
exports.stretch = stretch;
exports.spaceBetween = spaceBetween;
exports.spaceAround = spaceAround;
exports.spaceEvenly = spaceEvenly;
exports.baseline = baseline;
exports.forwards = forwards;
exports.backwards = backwards;
exports.both = both;
exports.infinite = infinite;
exports.count = count;
exports.paused = paused;
exports.running = running;
exports.inside = inside;
exports.outside = outside;
exports.solid = solid;
exports.dotted = dotted;
exports.dashed = dashed;
exports.underline = underline;
exports.overline = overline;
exports.lineThrough = lineThrough;
exports.clip = clip;
exports.ellipsis = ellipsis;
exports.wavy = wavy;
exports.$$double = $$double;
exports.uppercase = uppercase;
exports.lowercase = lowercase;
exports.capitalize = capitalize;
exports.sub = sub;
exports.$$super = $$super;
exports.textTop = textTop;
exports.textBottom = textBottom;
exports.middle = middle;
exports.normal = normal;
exports.breakAll = breakAll;
exports.keepAll = keepAll;
exports.breakWord = breakWord;
exports.reverse = reverse;
exports.alternate = alternate;
exports.alternateReverse = alternateReverse;
exports.fill = fill$1;
exports.content = content;
exports.maxContent = maxContent;
exports.minContent = minContent;
exports.fitContent = fitContent;
exports.all = all;
exports.round = round;
exports.miter = miter;
exports.bevel = bevel;
exports.butt = butt;
exports.square = square;
exports.panX = panX;
exports.panY = panY;
exports.panLeft = panLeft;
exports.panRight = panRight;
exports.panUp = panUp;
exports.panDown = panDown;
exports.pinchZoom = pinchZoom;
exports.manipulation = manipulation;
exports.thin = thin;
exports.extraLight = extraLight;
exports.light = light;
exports.medium = medium;
exports.semiBold = semiBold;
exports.bold = bold;
exports.extraBold = extraBold;
exports.lighter = lighter;
exports.bolder = bolder;
exports.fr = fr;
exports.vw = vw;
exports.localUrl = localUrl;
exports.url = url;
exports.linear = linear;
exports.ease = ease;
exports.easeIn = easeIn;
exports.easeOut = easeOut;
exports.easeInOut = easeInOut;
exports.stepStart = stepStart;
exports.stepEnd = stepEnd;
exports.steps = steps;
exports.cubicBezier = cubicBezier;
exports.marginBox = marginBox;
exports.fillBox = fillBox;
exports.strokeBox = strokeBox;
exports.viewBox = viewBox;
exports.translate = translate;
exports.translate3d = translate3d;
exports.translateX = translateX;
exports.translateY = translateY;
exports.translateZ = translateZ;
exports.scale = scale;
exports.scale3d = scale3d;
exports.scaleX = scaleX;
exports.scaleY = scaleY;
exports.scaleZ = scaleZ;
exports.rotate = rotate;
exports.rotate3d = rotate3d;
exports.rotateX = rotateX;
exports.rotateY = rotateY;
exports.rotateZ = rotateZ;
exports.skew = skew;
exports.skewX = skewX;
exports.skewY = skewY;
exports.linearGradient = linearGradient;
exports.repeatingLinearGradient = repeatingLinearGradient;
exports.radialGradient = radialGradient;
exports.repeatingRadialGradient = repeatingRadialGradient;
exports.conicGradient = conicGradient;
exports.areas = areas;
exports.ident = ident;
exports.numIdent = numIdent;
exports.contextMenu = contextMenu;
exports.help = help;
exports.pointer = pointer;
exports.progress = progress;
exports.wait = wait;
exports.cell = cell;
exports.crosshair = crosshair;
exports.verticalText = verticalText;
exports.alias = alias;
exports.copy = copy;
exports.move = move;
exports.noDrop = noDrop;
exports.notAllowed = notAllowed;
exports.grab = grab;
exports.grabbing = grabbing;
exports.allScroll = allScroll;
exports.colResize = colResize;
exports.rowResize = rowResize;
exports.nResize = nResize;
exports.eResize = eResize;
exports.sResize = sResize;
exports.wResize = wResize;
exports.neResize = neResize;
exports.nwResize = nwResize;
exports.seResize = seResize;
exports.swResize = swResize;
exports.ewResize = ewResize;
exports.nsResize = nsResize;
exports.neswResize = neswResize;
exports.nwseResize = nwseResize;
exports.zoomIn = zoomIn;
exports.zoomOut = zoomOut;
exports.flex3 = flex3;
exports.border = border;
exports.borderTop = borderTop;
exports.backgroundSize = backgroundSize;
exports.textDecoration = textDecoration;
exports.background = background;
exports.backgrounds = backgrounds;
exports.gridAutoColumns = gridAutoColumns;
exports.gridAutoRows = gridAutoRows;
exports.gridTemplateColumns = gridTemplateColumns;
exports.gridTemplateRows = gridTemplateRows;
exports.size = size;
exports.filter = filter;
exports.fontFace = fontFace;
exports.Transition = Transition;
exports.transitionValue = transitionValue;
exports.transitionList = transitionList;
exports.transition = transition;
exports.transitions = transitions;
exports.Animation = Animation;
exports.animationValue = animationValue;
exports.animation = animation;
exports.animations = animations;
exports.animationName = animationName;
exports.animationDelay = animationDelay;
exports.animationDirection = animationDirection;
exports.animationDuration = animationDuration;
exports.animationFillMode = animationFillMode;
exports.animationIterationCount = animationIterationCount;
exports.animationPlayState = animationPlayState;
exports.animationTimingFunction = animationTimingFunction;
exports.SVG = SVG;
exports.touchAction = touchAction;
/* No side effect */
