// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Icon = require("./Icon.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./Button.bs.js");
var Router = require("./Router.bs.js");
var Spacer = require("./Spacer.bs.js");
var $$String = require("rescript/lib/js/string.js");
var Styles = require("./styles.bs.js");
var Js_math = require("rescript/lib/js/js_math.js");
var Mantine = require("./Mantine.bs.js");
var Tooltip = require("./Tooltip.bs.js");
var Accordion = require("./Accordion.bs.js");
var AvoConfig = require("../../shared/utils/AvoConfig.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Timestamp = require("./Timestamp.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var TextButton = require("./TextButton.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RingProgress = require("./branchImplementation/RingProgress.bs.js");
var SnippetUtils = require("./detailviews/SnippetUtils.bs.js");
var IconCheckmark = require("./IconCheckmark.bs.js");
var IconCheckmarkCircle = require("./IconCheckmarkCircle.bs.js");
var BranchImplementationHooks = require("./branchImplementation/BranchImplementationHooks.bs.js");
var BranchImplementationUtils = require("./branchImplementation/BranchImplementationUtils.bs.js");

function getEventId($$event) {
  return $$event.VAL[0].id;
}

function containerStyle(customSize, eventStatus, removed) {
  var tmp;
  if (removed) {
    tmp = Styles.Color.setAlpha(Styles.Color.red100, 0.4);
  } else if (typeof eventStatus === "number") {
    switch (eventStatus) {
      case /* NotSeen */0 :
      case /* NotImplementedWithAvo */1 :
          tmp = "transparent";
          break;
      case /* Unknown */2 :
          tmp = Styles.Color.red100;
          break;
      
    }
  } else {
    tmp = "transparent";
  }
  return Curry._1(Css.style, {
              hd: Css.width(Css.px(customSize)),
              tl: {
                hd: Css.height(Css.px(customSize)),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.borderRadius(Css.px(customSize / 2 | 0)),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.backgroundColor(tmp),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function CodeChangesAuditAccordionItem$SeenState(Props) {
  var eventStatus = Props.eventStatus;
  var removedOpt = Props.removed;
  var customSizeOpt = Props.size;
  var removed = removedOpt !== undefined ? removedOpt : false;
  var customSize = customSizeOpt !== undefined ? customSizeOpt : 12;
  var tmp;
  if (typeof eventStatus === "number") {
    switch (eventStatus) {
      case /* NotSeen */0 :
          tmp = removed ? null : React.createElement(IconCheckmarkCircle.make, {
                  size: customSize,
                  color: Styles.Color.grey30
                });
          break;
      case /* NotImplementedWithAvo */1 :
          tmp = React.createElement(Icon.make, {
                type_: "code",
                size: {
                  NAME: "int",
                  VAL: customSize
                },
                color: Styles.Color.grey60
              });
          break;
      case /* Unknown */2 :
          tmp = null;
          break;
      
    }
  } else {
    tmp = removed ? null : React.createElement(IconCheckmarkCircle.make, {
            size: customSize,
            color: Styles.Color.green100
          });
  }
  var tmp$1;
  if (removed) {
    tmp$1 = "Event should be removed";
  } else if (typeof eventStatus === "number") {
    switch (eventStatus) {
      case /* NotSeen */0 :
          tmp$1 = "Event not seen";
          break;
      case /* NotImplementedWithAvo */1 :
          tmp$1 = "Event needs to be manually implemented and validated";
          break;
      case /* Unknown */2 :
          tmp$1 = "Unknown status";
          break;
      
    }
  } else {
    tmp$1 = "Event last seen " + Timestamp.renderJsTimestamp(eventStatus._0);
  }
  return React.createElement(Tooltip.make, {
              title: "Event State Information",
              tooltipPadding: 8,
              withAskUsAnything: false,
              withDismiss: false,
              withTitle: false,
              children: null
            }, React.createElement(Tooltip.Target.make, {
                  children: React.createElement("div", {
                        className: containerStyle(customSize, eventStatus, removed)
                      }, tmp)
                }), React.createElement(Tooltip.Content.make, {
                  children: React.createElement($$Text.make, {
                        size: "Small",
                        children: tmp$1
                      })
                }));
}

var SeenState = {
  containerStyle: containerStyle,
  make: CodeChangesAuditAccordionItem$SeenState
};

function getGridPosition(index, totalCount) {
  if (totalCount <= 12) {
    return [
            1,
            index + 1 | 0
          ];
  }
  var itemsPerCol = Js_math.ceil_int(totalCount / 3);
  var colIndex = Js_math.floor_int(index / itemsPerCol) + 1 | 0;
  var rowIndex = Caml_int32.mod_(index, itemsPerCol) + 1 | 0;
  return [
          colIndex,
          rowIndex
        ];
}

function $$event(index, total) {
  var match = getGridPosition(index, total);
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.selector(" button", {
                        hd: Css.textAlign("left"),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.unsafe("gridColumn", String(match[0])),
                    tl: {
                      hd: Css.unsafe("gridRow", String(match[1])),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function removedEvent(index, total) {
  return Curry._1(Css.merge, {
              hd: $$event(index, total),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.textDecoration("lineThrough"),
                      tl: {
                        hd: Css.textDecorationColor(Styles.Color.setAlpha(Styles.Color.grey100, 0.7)),
                        tl: /* [] */0
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var Style = {
  $$event: $$event,
  removedEvent: removedEvent
};

function CodeChangesAuditAccordionItem$ChangedEvent(Props) {
  var $$event$1 = Props.event;
  var index = Props.index;
  var total = Props.total;
  var onInteracted = Props.onInteracted;
  var source = Props.source;
  if ($$event$1.NAME === "Updated") {
    var match = $$event$1.VAL;
    var $$event$2 = match[0];
    return React.createElement("div", {
                key: $$event$2.id,
                className: $$event(index, total) + " code-change-event"
              }, React.createElement(TextButton.make, {
                    onClick: (function (param) {
                        Curry._1(onInteracted, $$event$2);
                        return Router.push(undefined, SnippetUtils.getSnippetLink($$event$2.id, source.id, "BranchImplementation"));
                      }),
                    style: "Black",
                    size: "Small",
                    children: null
                  }, React.createElement(CodeChangesAuditAccordionItem$SeenState, {
                        eventStatus: match[1]
                      }), React.createElement(Spacer.make, {
                        width: 16
                      }), React.createElement($$Text.make, {
                        element: "Span",
                        lines: 1,
                        title: $$event$2.name,
                        children: $$event$2.name
                      })));
  }
  var match$1 = $$event$1.VAL;
  var $$event$3 = match$1[0];
  return React.createElement("div", {
              key: $$event$3.id,
              className: removedEvent(index, total) + " code-change-event"
            }, React.createElement(TextButton.make, {
                  onClick: (function (param) {
                      Curry._1(onInteracted, $$event$3);
                      return Router.push(undefined, SnippetUtils.getSnippetLink($$event$3.id, source.id, "BranchImplementation"));
                    }),
                  style: "Black",
                  size: "Small",
                  children: null
                }, React.createElement(CodeChangesAuditAccordionItem$SeenState, {
                      eventStatus: match$1[1],
                      removed: true
                    }), React.createElement(Spacer.make, {
                      width: 17
                    }), React.createElement($$Text.make, {
                      element: "Span",
                      lines: 1,
                      title: $$event$3.name,
                      children: $$event$3.name
                    })));
}

var ChangedEvent = {
  getGridPosition: getGridPosition,
  Style: Style,
  make: CodeChangesAuditAccordionItem$ChangedEvent
};

var title = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.gap(Css.px(4)),
          tl: /* [] */0
        }
      }
    });

var ringProgressContainer = Curry._1(Css.style, {
      hd: Css.marginRight(Css.px(11)),
      tl: {
        hd: Css.maxWidth(Css.px(12)),
        tl: {
          hd: Css.maxHeight(Css.px(16)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.justifyContent("center"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var loadingContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.maxHeight(Css.px(16)),
            tl: {
              hd: Css.marginLeft(Css.px(3)),
              tl: {
                hd: Css.marginRight(Css.px(2)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var issueIconContainer = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(6)),
      tl: {
        hd: Css.marginRight(Css.px(6)),
        tl: /* [] */0
      }
    });

var codeChangesIcon = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(3)),
      tl: {
        hd: Css.marginRight(Css.px(3)),
        tl: {
          hd: Css.height(Css.px(20)),
          tl: /* [] */0
        }
      }
    });

var eventsContainer = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(0)),
      tl: {
        hd: Css.paddingLeft(Css.px(18)),
        tl: {
          hd: Css.paddingBottom(Css.px(12)),
          tl: /* [] */0
        }
      }
    });

var eventsColumnContainer = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: {
                NAME: "repeat",
                VAL: [
                  {
                    NAME: "num",
                    VAL: 3
                  },
                  Css.fr(1.0)
                ]
              },
              tl: /* [] */0
            }),
        tl: {
          hd: Css.gap(Css.px(0)),
          tl: /* [] */0
        }
      }
    });

var Style$1 = {
  title: title,
  ringProgressContainer: ringProgressContainer,
  loadingContainer: loadingContainer,
  issueIconContainer: issueIconContainer,
  codeChangesIcon: codeChangesIcon,
  eventsContainer: eventsContainer,
  eventsColumnContainer: eventsColumnContainer
};

function CodeChangesAuditAccordionItem$NestedCodeChangesSourceAccordion(Props) {
  var source = Props.source;
  var eventsList = Props.eventsList;
  var branchStatus = Props.branchStatus;
  var initiallyExpandedOpt = Props.initiallyExpanded;
  var updateColorsWithBranchStatusOpt = Props.updateColorsWithBranchStatus;
  var sourceUpdatesLoadingState = Props.sourceUpdatesLoadingState;
  var onCodeChangesAuditSourceToggled = Props.onCodeChangesAuditSourceToggled;
  var onCodeChangesEventLinkClicked = Props.onCodeChangesEventLinkClicked;
  var onCodeChangesAuditSourceLinkClicked = Props.onCodeChangesAuditSourceLinkClicked;
  var initiallyExpanded = initiallyExpandedOpt !== undefined ? initiallyExpandedOpt : false;
  var updateColorsWithBranchStatus = updateColorsWithBranchStatusOpt !== undefined ? updateColorsWithBranchStatusOpt : true;
  var seenEvents = Belt_List.length(BranchImplementationUtils.filterEvents("implementedWithCodegen", "updated", "completed", source.id, eventsList));
  var implementedWithCodegen = Belt_List.length(BranchImplementationUtils.filterEvents("implementedWithCodegen", "updated", undefined, source.id, eventsList));
  var implementedManually = Belt_List.length(BranchImplementationUtils.filterEvents("implementedManually", "updated", undefined, source.id, eventsList));
  var deletedEvents = Belt_List.length(BranchImplementationUtils.filterEvents(undefined, "removed", undefined, source.id, eventsList));
  var codegenString = implementedWithCodegen !== 0 ? (
      implementedWithCodegen !== 1 ? String(seenEvents) + " of " + String(implementedWithCodegen) + " events " + (
          seenEvents === 1 ? "has" : "have"
        ) + " been implemented using Codegen" : String(seenEvents) + " of 1 event " + (
          seenEvents === 1 ? "has" : "have"
        ) + " been implemented using Codegen"
    ) : undefined;
  var manualString = implementedWithCodegen > 0 || implementedManually === 0 ? undefined : (
      implementedManually !== 1 ? String(implementedManually) + " events not using Codegen need manual implementation and validation" : "1 event not using Codegen needs manual implementation and validation"
    );
  var deletedString = deletedEvents !== 0 && !(implementedManually > 0 || implementedWithCodegen > 0) ? (
      deletedEvents !== 1 ? String(deletedEvents) + " events removed" : "1 event removed"
    ) : undefined;
  var secondaryTitle = Belt_Array.keepMap([
          codegenString,
          manualString,
          deletedString
        ], (function (s) {
            return s;
          })).join(", ");
  var sourceAccordionTitle_1 = React.createElement("span", {
        className: title
      }, React.createElement($$Text.make, {
            size: "Small",
            weight: "Semi",
            singleLine: true,
            color: Styles.Color.grey90,
            maxWidth: {
              NAME: "px",
              VAL: 150
            },
            children: AvoConfig.getSourceName(source)
          }), React.createElement($$Text.make, {
            size: "Small",
            color: Styles.Color.grey70,
            children: secondaryTitle
          }));
  var sourceAccordionTitle = {
    NAME: "Component",
    VAL: sourceAccordionTitle_1
  };
  var manualString$1 = implementedWithCodegen === 0 || implementedManually === 0 ? undefined : (
      implementedManually !== 1 ? String(implementedManually) + " events not using Codegen need manual implementation and validation" : "1 event not using Codegen needs manual implementation and validation"
    );
  var deletedString$1 = deletedEvents !== 0 && !(implementedManually === 0 && implementedWithCodegen === 0) ? (
      deletedEvents !== 1 ? String(deletedEvents) + " events removed" : "1 event removed"
    ) : undefined;
  var arr = Belt_Array.keepMap([
        manualString$1,
        deletedString$1
      ], (function (s) {
          return s;
        }));
  var sourceAccordionSubtitle = arr.length !== 0 ? ({
        NAME: "Component",
        VAL: Belt_Array.map(arr, (function (str) {
                return React.createElement($$Text.make, {
                            size: "Tiny",
                            color: Styles.Color.grey70,
                            children: str,
                            key: str
                          });
              }))
      }) : undefined;
  var color;
  if (updateColorsWithBranchStatus && typeof branchStatus !== "number") {
    switch (branchStatus.TAG | 0) {
      case /* Merged */1 :
      case /* Approved */6 :
          color = Styles.Color.orange;
          break;
      default:
        color = Styles.Color.blue;
    }
  } else {
    color = Styles.Color.blue;
  }
  var value = seenEvents / implementedWithCodegen * 100.0 | 0;
  var ringProgressSections = [{
      value: value,
      color: color
    }];
  var getMaxStringLength = function (s, l) {
    if (s.length > l) {
      return $$String.sub(s, 0, l - 7 | 0) + "...";
    } else {
      return s;
    }
  };
  var eventsCount = React.useMemo((function () {
          return Belt_List.size(eventsList);
        }), [eventsList]);
  var tmp;
  if (sourceUpdatesLoadingState === "loading") {
    tmp = React.createElement("div", {
          className: loadingContainer
        }, React.createElement(Mantine.Loader.make, {
              size: 18,
              color: Styles.Color.blue
            }));
  } else if (implementedWithCodegen > 0 && seenEvents === implementedWithCodegen) {
    tmp = React.createElement("div", {
          className: issueIconContainer
        }, React.createElement(IconCheckmark.make, {
              size: 12,
              color: Styles.Color.mintGreen
            }));
  } else if (implementedWithCodegen > 0) {
    var tmp$1;
    if (updateColorsWithBranchStatus && typeof branchStatus !== "number") {
      switch (branchStatus.TAG | 0) {
        case /* Merged */1 :
        case /* Approved */6 :
            tmp$1 = Styles.Color.setAlpha(Styles.Color.orange, 0.2);
            break;
        default:
          tmp$1 = Styles.Color.blue20;
      }
    } else {
      tmp$1 = Styles.Color.blue20;
    }
    tmp = React.createElement("div", {
          className: ringProgressContainer
        }, React.createElement(RingProgress.make, {
              size: 25,
              thickness: 3,
              rootColor: tmp$1,
              sections: ringProgressSections,
              tooltip: String(seenEvents) + " of " + String(implementedWithCodegen) + " event" + (
                implementedWithCodegen === 1 ? " has" : "s have"
              ) + " been implemented using Codegen"
            }));
  } else {
    tmp = React.createElement("div", {
          className: codeChangesIcon
        }, React.createElement(Icon.make, {
              type_: "code",
              size: {
                NAME: "int",
                VAL: 18
              },
              color: Styles.Color.grey70
            }));
  }
  var tmp$2 = {
    title: sourceAccordionTitle,
    ariaLabel: AvoConfig.getSourceName(source),
    icon: tmp,
    theme: "white",
    extra: React.createElement(Button.make, {
          label: "View " + getMaxStringLength(AvoConfig.getSourceName(source), 20) + " Code Changes",
          onClick: (function (param) {
              var tmp;
              var exit = 0;
              if (implementedManually !== 0 || implementedWithCodegen <= 0) {
                exit = 1;
              } else {
                tmp = "Codegen";
              }
              if (exit === 1) {
                tmp = implementedWithCodegen !== 0 || implementedManually <= 0 ? "All" : "Manual";
              }
              Curry._3(onCodeChangesAuditSourceLinkClicked, source, Belt_List.length(eventsList), tmp);
              return Router.push(undefined, Router.Link.getSchemaRouteLink(undefined, {
                              NAME: "diff",
                              VAL: {
                                NAME: "implementation",
                                VAL: {
                                  NAME: "source",
                                  VAL: [
                                    source.id,
                                    undefined
                                  ]
                                }
                              }
                            }));
            }),
          size: "tiny",
          style: "outline"
        })
  };
  if (sourceAccordionSubtitle !== undefined) {
    tmp$2.subtitle = Caml_option.valFromOption(sourceAccordionSubtitle);
  }
  return React.createElement(Accordion.Item.make, {
              initiallyExpanded: initiallyExpanded,
              children: null
            }, React.createElement(Accordion.ItemHeader.make, tmp$2), React.createElement(Accordion.ItemCollapsible.make, {
                  onChange: (function (expanded) {
                      var tmp;
                      var exit = 0;
                      if (implementedManually !== 0 || implementedWithCodegen <= 0) {
                        exit = 1;
                      } else {
                        tmp = "Codegen";
                      }
                      if (exit === 1) {
                        tmp = implementedWithCodegen !== 0 || implementedManually <= 0 ? "All" : "Manual";
                      }
                      return Curry._4(onCodeChangesAuditSourceToggled, source, Belt_List.length(eventsList), tmp, expanded);
                    }),
                  children: React.createElement("div", {
                        className: Curry._1(Css.merge, {
                              hd: eventsContainer,
                              tl: {
                                hd: Belt_List.size(eventsList) > 10 ? eventsColumnContainer : Curry._1(Css.style, /* [] */0),
                                tl: /* [] */0
                              }
                            })
                      }, Belt_List.toArray(Belt_List.mapWithIndexU(Curry._1(BranchImplementationUtils.EventComparator.sort, eventsList), (function (index, $$event) {
                                  var onInteracted = function ($$event) {
                                    return Curry._3(onCodeChangesEventLinkClicked, $$event, source.id, Belt_List.length(eventsList));
                                  };
                                  return React.createElement(CodeChangesAuditAccordionItem$ChangedEvent, {
                                              event: $$event,
                                              index: index,
                                              total: eventsCount,
                                              onInteracted: onInteracted,
                                              source: source,
                                              key: getEventId($$event)
                                            });
                                }))))
                }));
}

var NestedCodeChangesSourceAccordion = {
  Style: Style$1,
  make: CodeChangesAuditAccordionItem$NestedCodeChangesSourceAccordion
};

function getImplementationRingProgress(color, done, total) {
  var value = total / done * 100.0 | 0;
  return [{
            value: value,
            color: color
          }];
}

var codeChangesIcon$1 = Curry._1(Css.style, {
      hd: Css.margin2("zero", Css.px(3)),
      tl: {
        hd: Css.height(Css.px(20)),
        tl: /* [] */0
      }
    });

var ringProgressContainer$1 = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.px(24)),
      tl: {
        hd: Css.maxHeight(Css.px(24)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var Style$2 = {
  codeChangesIcon: codeChangesIcon$1,
  ringProgressContainer: ringProgressContainer$1
};

function CodeChangesAuditAccordionItem(Props) {
  var actions = Props.actions;
  var branchStatus = Props.branchStatus;
  var fromModel = Props.fromModel;
  var toModel = Props.toModel;
  var onCodeChangesAuditToggled = Props.onCodeChangesAuditToggled;
  var onCodeChangesAuditLinkClicked = Props.onCodeChangesAuditLinkClicked;
  var onCodeChangesEventLinkClicked = Props.onCodeChangesEventLinkClicked;
  var onCodeChangesAuditSourceToggled = Props.onCodeChangesAuditSourceToggled;
  var onCodeChangesAuditSourceLinkClicked = Props.onCodeChangesAuditSourceLinkClicked;
  var match = BranchImplementationHooks.useSourceUpdates(actions, toModel, fromModel, true);
  var sourceUpdates = match.sourceUpdates;
  var sourceUpdatesLoadingState = match.loadingState;
  var seenEvents = Belt_List.length(Belt_List.flatten(Belt_List.map(sourceUpdates, (function (param) {
                  return BranchImplementationUtils.filterEvents("implementedWithCodegen", "updated", "completed", param[0].id, param[1]);
                }))));
  var implementedWithCodegen = Belt_List.length(Belt_List.flatten(Belt_List.map(sourceUpdates, (function (param) {
                  return BranchImplementationUtils.filterEvents("implementedWithCodegen", "updated", undefined, param[0].id, param[1]);
                }))));
  var implementedManually = Belt_List.length(Belt_List.flatten(Belt_List.map(sourceUpdates, (function (param) {
                  return BranchImplementationUtils.filterEvents("implementedManually", "updated", undefined, param[0].id, param[1]);
                }))));
  var deletedEvents = Belt_List.length(Belt_List.flatten(Belt_List.map(sourceUpdates, (function (param) {
                  return BranchImplementationUtils.filterEvents("any", "removed", undefined, param[0].id, param[1]);
                }))));
  var sourcesUsingCodegen = Belt_List.length(Belt_List.keep(sourceUpdates, (function (param) {
              var sourceId = param[0].id;
              return Belt_List.some(param[1], (function ($$event) {
                            if (typeof $$event === "object" && $$event.NAME === "Updated") {
                              return BranchImplementationUtils.hasCodegenForSource($$event.VAL[0], sourceId);
                            } else {
                              return false;
                            }
                          }));
            })));
  var codeChangesAccordionTitle = implementedWithCodegen > 0 ? ({
        NAME: "String",
        VAL: sourceUpdatesLoadingState === "loading" ? "Loading " + String(implementedWithCodegen) + " Codegen event" + (
            implementedWithCodegen !== 1 ? "s" : ""
          ) + " across " + String(sourcesUsingCodegen) + " source" + (
            sourcesUsingCodegen !== 1 ? "s" : ""
          ) + "..." : String(seenEvents) + " of " + String(implementedWithCodegen) + " Codegen event" + (
            implementedWithCodegen !== 1 ? "s" : ""
          ) + " implemented across " + String(sourcesUsingCodegen) + " source" + (
            sourcesUsingCodegen !== 1 ? "s" : ""
          )
      }) : (
      implementedManually > 0 ? ({
            NAME: "String",
            VAL: "Branch Implementation"
          }) : (
          deletedEvents > 0 ? ({
                NAME: "String",
                VAL: deletedEvents !== 1 ? String(deletedEvents) + " events removed" : "1 event removed"
              }) : ({
                NAME: "String",
                VAL: "No code changes needed"
              })
        )
    );
  var tmp;
  if (sourceUpdatesLoadingState === "loading" && implementedWithCodegen > 0) {
    tmp = React.createElement("div", {
          className: ringProgressContainer$1
        }, React.createElement(Mantine.Loader.make, {
              variant: "oval",
              size: 24,
              color: Styles.Color.blue
            }));
  } else if (implementedWithCodegen > 0 && seenEvents === implementedWithCodegen) {
    tmp = React.createElement(IconCheckmark.make, {
          size: 13,
          color: Styles.Color.mintGreen
        });
  } else if (implementedWithCodegen > 0) {
    var tmp$1;
    if (typeof branchStatus === "number") {
      tmp$1 = Styles.Color.blue20;
    } else {
      switch (branchStatus.TAG | 0) {
        case /* Merged */1 :
        case /* Approved */6 :
            tmp$1 = Styles.Color.setAlpha(Styles.Color.orange, 0.2);
            break;
        default:
          tmp$1 = Styles.Color.blue20;
      }
    }
    var tmp$2;
    if (typeof branchStatus === "number") {
      tmp$2 = Styles.Color.blue;
    } else {
      switch (branchStatus.TAG | 0) {
        case /* Merged */1 :
        case /* Approved */6 :
            tmp$2 = Styles.Color.orange;
            break;
        default:
          tmp$2 = Styles.Color.blue;
      }
    }
    tmp = React.createElement("div", {
          className: ringProgressContainer$1
        }, React.createElement(RingProgress.make, {
              size: 30,
              thickness: 4,
              rootColor: tmp$1,
              sections: getImplementationRingProgress(tmp$2, implementedWithCodegen, seenEvents),
              tooltip: String(seenEvents) + " of " + String(implementedWithCodegen) + " event" + (
                implementedWithCodegen === 1 ? " has" : "s have"
              ) + " been implemented using Codegen"
            }));
  } else {
    tmp = React.createElement("div", {
          className: codeChangesIcon$1
        }, React.createElement(Icon.make, {
              type_: "code",
              size: {
                NAME: "int",
                VAL: 20
              },
              color: Styles.Color.grey70
            }));
  }
  var tmp$3;
  var exit = 0;
  if (implementedWithCodegen > 0 && seenEvents === implementedWithCodegen) {
    tmp$3 = "green";
  } else if (typeof branchStatus === "number") {
    tmp$3 = "white";
  } else {
    switch (branchStatus.TAG | 0) {
      case /* Merged */1 :
      case /* Approved */6 :
          exit = 1;
          break;
      default:
        tmp$3 = "white";
    }
  }
  if (exit === 1) {
    tmp$3 = implementedWithCodegen > 0 ? (
        seenEvents === implementedWithCodegen ? "green" : "yellow"
      ) : "white";
  }
  var tmp$4 = {
    title: codeChangesAccordionTitle,
    icon: tmp,
    theme: tmp$3
  };
  var tmp$5 = implementedManually === 0 && implementedWithCodegen === 0 && deletedEvents === 0 ? ({
        NAME: "String",
        VAL: "It looks like there are no code changes required to implement this branch"
      }) : (
      implementedManually === 0 && implementedWithCodegen === 0 && deletedEvents > 0 ? undefined : (
          implementedManually > 0 && deletedEvents > 0 ? ({
                NAME: "Component",
                VAL: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.grey70,
                          children: String(implementedManually) + " event" + (
                            implementedManually !== 1 ? "s" : ""
                          ) + " not using Codegen need" + (
                            implementedManually !== 1 ? "" : "s"
                          ) + " manual implementation and validation"
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.grey70,
                          children: deletedEvents !== 1 ? String(deletedEvents) + " events removed" : "1 event removed"
                        }))
              }) : (
              implementedManually > 0 ? ({
                    NAME: "String",
                    VAL: String(implementedManually) + " event" + (
                      implementedManually !== 1 ? "s" : ""
                    ) + " not using Codegen need" + (
                      implementedManually !== 1 ? "" : "s"
                    ) + " manual implementation and validation"
                  }) : undefined
            )
        )
    );
  if (tmp$5 !== undefined) {
    tmp$4.subtitle = Caml_option.valFromOption(tmp$5);
  }
  var tmp$6 = implementedManually > 0 || implementedWithCodegen > 0 || deletedEvents > 0 ? Caml_option.some(React.createElement(Button.make, {
              label: "View Code Changes",
              onClick: (function (param) {
                  Curry._1(onCodeChangesAuditLinkClicked, implementedManually + implementedWithCodegen | 0);
                  return Router.Schema.pushSchemaRoute(undefined, undefined, {
                              NAME: "diff",
                              VAL: {
                                NAME: "implementation",
                                VAL: "overview"
                              }
                            });
                }),
              style: "outline"
            })) : undefined;
  if (tmp$6 !== undefined) {
    tmp$4.extra = Caml_option.valFromOption(tmp$6);
  }
  return React.createElement(Accordion.Item.make, {
              children: null
            }, React.createElement(Accordion.ItemHeader.make, tmp$4), implementedManually > 0 || implementedWithCodegen > 0 ? React.createElement(Accordion.ItemCollapsible.make, {
                    onChange: (function (expanded) {
                        return Curry._2(onCodeChangesAuditToggled, implementedManually + implementedWithCodegen | 0, expanded);
                      }),
                    children: Belt_List.toArray(Belt_List.map(sourceUpdates, (function (param) {
                                var source = param[0];
                                return React.createElement(Accordion.Nested.make, {
                                            chevronPosition: "right",
                                            children: React.createElement(CodeChangesAuditAccordionItem$NestedCodeChangesSourceAccordion, {
                                                  source: source,
                                                  eventsList: param[1],
                                                  branchStatus: branchStatus,
                                                  sourceUpdatesLoadingState: sourceUpdatesLoadingState,
                                                  onCodeChangesAuditSourceToggled: onCodeChangesAuditSourceToggled,
                                                  onCodeChangesEventLinkClicked: onCodeChangesEventLinkClicked,
                                                  onCodeChangesAuditSourceLinkClicked: onCodeChangesAuditSourceLinkClicked
                                                }),
                                            key: source.id
                                          });
                              })))
                  }) : null);
}

var make = CodeChangesAuditAccordionItem;

exports.getEventId = getEventId;
exports.SeenState = SeenState;
exports.ChangedEvent = ChangedEvent;
exports.NestedCodeChangesSourceAccordion = NestedCodeChangesSourceAccordion;
exports.getImplementationRingProgress = getImplementationRingProgress;
exports.Style = Style$2;
exports.make = make;
/* title Not a pure module */
