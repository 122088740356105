// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Tag = require("./Tag.bs.js");
var $$Text = require("./Text.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Icons = require("./Icons.bs.js");
var React = require("react");
var Spacer = require("./Spacer.bs.js");
var Styles = require("./styles.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var StateUtils = require("./stateUtils.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

function DiffEventGroups(Props) {
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var maybeAddGroupTypeToLogEventAction = Belt_List.getByU(actions, (function (action) {
          if (typeof action === "object") {
            return action.NAME === "AddGroupTypeToLogEvent";
          } else {
            return false;
          }
        }));
  var maybeRemoveGroupTypeFromLogEvent = Belt_List.getByU(actions, (function (action) {
          if (typeof action === "object") {
            return action.NAME === "RemoveGroupTypeFromLogEvent";
          } else {
            return false;
          }
        }));
  var maybeAddUserToGroup = Belt_List.getByU(actions, (function (action) {
          if (typeof action === "object") {
            return action.NAME === "AddUserToGroup";
          } else {
            return false;
          }
        }));
  var maybeRemoveUserFromGroup = Belt_List.getByU(actions, (function (action) {
          if (typeof action === "object") {
            return action.NAME === "RemoveUserFromGroup";
          } else {
            return false;
          }
        }));
  var eventAssociationChanges = Belt_Option.isSome(maybeAddGroupTypeToLogEventAction) || Belt_Option.isSome(maybeRemoveGroupTypeFromLogEvent);
  var userAssociationChanges = Belt_Option.isSome(maybeAddUserToGroup) || Belt_Option.isSome(maybeRemoveUserFromGroup);
  var groupAssociationChanges = eventAssociationChanges || userAssociationChanges;
  if (groupAssociationChanges) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("flexStart"),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.regular),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.padding2(Css.px(10), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.margin4(Css.px(2), Css.px(10), Css.px(0), Css.px(0)),
                          tl: /* [] */0
                        })
                  }, React.createElement(Icons.UpdateGroup.make, {
                        size: 16,
                        color: Styles.Color.grey40
                      })), React.createElement("div", undefined, eventAssociationChanges ? React.createElement("div", undefined, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.grey80,
                              children: "Groups event is associated with"
                            }), React.createElement(Spacer.make, {
                              height: 4
                            }), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, Belt_List.toArray(Belt_List.mapU(Belt_List.keepMapU(actions, (function (action) {
                                            if (typeof action === "object" && action.NAME === "RemoveGroupTypeFromLogEvent") {
                                              return StateUtils.getGroupTypeName(action.VAL[1], branchModel);
                                            }
                                            
                                          })), (function (groupTypeName) {
                                        return React.createElement(Tag.make, {
                                                    tag: "- " + groupTypeName.toUpperCase(),
                                                    tagColor: Styles.Color.grapeError,
                                                    tooltip: "This event will no longer be associated with this group after this change",
                                                    key: groupTypeName
                                                  });
                                      }))), Belt_List.toArray(Belt_List.mapU(Belt_List.keepMapU(actions, (function (action) {
                                            if (typeof action === "object" && action.NAME === "AddGroupTypeToLogEvent") {
                                              return StateUtils.getGroupTypeName(action.VAL[1], branchModel);
                                            }
                                            
                                          })), (function (groupTypeName) {
                                        return React.createElement(Tag.make, {
                                                    tag: "+ " + groupTypeName.toUpperCase(),
                                                    tagColor: Styles.Color.mintGreen,
                                                    tooltip: "This event will be associated with this group after this change",
                                                    key: groupTypeName
                                                  });
                                      })))), React.createElement(Spacer.make, {
                              height: 8
                            })) : null, userAssociationChanges ? React.createElement("div", undefined, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.grey80,
                              children: "Groups user is added to"
                            }), React.createElement(Spacer.make, {
                              height: 4
                            }), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, Belt_List.toArray(Belt_List.mapU(Belt_List.keepMapU(actions, (function (action) {
                                            if (typeof action === "object" && action.NAME === "RemoveUserFromGroup") {
                                              return StateUtils.getGroupTypeName(action.VAL[1], branchModel);
                                            }
                                            
                                          })), (function (groupTypeName) {
                                        return React.createElement(Tag.make, {
                                                    tag: "- " + groupTypeName.toUpperCase(),
                                                    tagColor: Styles.Color.grapeError,
                                                    tooltip: "This event will no longer add the user to this group after this change",
                                                    key: groupTypeName
                                                  });
                                      }))), Belt_List.toArray(Belt_List.mapU(Belt_List.keepMapU(actions, (function (action) {
                                            if (typeof action === "object" && action.NAME === "AddUserToGroup") {
                                              return StateUtils.getGroupTypeName(action.VAL[1], branchModel);
                                            }
                                            
                                          })), (function (groupTypeName) {
                                        return React.createElement(Tag.make, {
                                                    tag: "+ " + groupTypeName.toUpperCase(),
                                                    tagColor: Styles.Color.mintGreen,
                                                    tooltip: "This event will add the user to this group after this change",
                                                    key: groupTypeName
                                                  });
                                      }))))) : null));
  } else {
    return null;
  }
}

var make = DiffEventGroups;

exports.make = make;
/* Css Not a pure module */
